import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContaBancariaService, PagamentoExtraService, PagamentoRestoService, PagamentoService } from 'administrativo-lib';
import { ContaBancaria, EddyAutoComplete, Exercicio, ExercicioService, Favorecido, FavorecidoService, FuncaoService, GlobalService, Login } from 'eddydata-lib';
import { Observable } from 'rxjs';
import * as toastr from 'toastr';

// declare var $: any;

@Component({
  selector: 'lib-pagamento-lote-dlg',
  templateUrl: './pagamento-lote-dlg.component.html'
})
export class PagamentoLoteDlgComponent implements OnInit {

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Input() public login: Login;
  @Input() public tipo: 'P' | 'PE' | 'PR' = 'P';

  public contaAutoComplete: EddyAutoComplete<ContaBancaria>;
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;

  public lista_exercicios: Exercicio[] = [];
  public lista: { numero: number, parcela: number, erro?: string }[] = [];
  public parcelas: { numero: number, parcela: number, saldo_pagar: number }[] = [];

  public visualizarResultado: boolean = false;
  public visualizarParcela: boolean = false;
  public ptBR: any;

  public numero_inicial: number;
  public numero_final: number;
  public exercicio: Exercicio;
  public data_pagamento: Date = new Date();
  public codigo_conta: number;
  public conta: ContaBancaria;
  public favorecido: Favorecido;
  public documento: string;
  public totalPagamento: number = 0;

  constructor(
    private funcaoService: FuncaoService,
    private exercicioService: ExercicioService,
    protected contaService: ContaBancariaService,
    private pagamentoService: PagamentoService,
    private favorecidoService: FavorecidoService,
    private pagamentoExtraService: PagamentoExtraService,
    private pagamentoRestoService: PagamentoRestoService) { }

  ngOnInit() {
    this.ptBR = new GlobalService().obterDataBR();
    this.carregarAutoComplete();
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id)
      .subscribe(dados => this.lista_exercicios = dados.content);
  }

  public prePagar() {
    if (!this.validar())
      return;

    this.validarParcelas()
      .subscribe((data) => {
        if (data && data.length > 0) {
          this.totalPagamento = 0;
          this.parcelas = data.sort((a, b) => {
            if (a.numero !== b.numero)
              return +a.numero - +b.numero;
            return +a.parcela - +b.parcela;
          });
          this.visualizarParcela = true;
        } else {
          if (this.favorecido?.id) {
            toastr.warning(`Não existem parcelas em aberto do fornecedor ${this.favorecido.nome}`);
          } else {
            toastr.warning(`Não foi encontrado parcelas em aberto`);
          }
        }
      });
  }

  public pagarIgnorar() {
    this.totalPagamento = 0;
    this.pagar(this.parcelas.filter((p) => !p['selecionado']));
  }

  public pagar(ignorar?: { numero: number, parcela: number, saldo_pagar: number }[]) {
    this.visualizarParcela = false;
    this.requisicao(ignorar)
      .subscribe((data) => {
        this.lista = data;
        this.visualizarResultado = true;
      }, (error) => toastr.error(error.error && error.error.payload ? error.error.payload : error));
  }

  private requisicao(ignorar?: { numero: number, parcela: number }[]): Observable<{ numero: number, parcela: number, erro?: string }[]> {
    if (this.tipo === 'P')
      return this.pagamentoService.pagamentoEmLote({
        numero_inicial: this.numero_inicial, numero_final: this.numero_final, conta: this.conta.id,
        data_pagamento: this.funcaoService.converteDataSQL(this.data_pagamento), documento: this.documento, exercicio:
          this.login.exercicio.id, orgao: this.login.orgao.id, favorecido: this.favorecido?.id, ignorar: ignorar
      });
    if (this.tipo === 'PE')
      return this.pagamentoExtraService.pagamentoEmLote({
        numero_inicial: this.numero_inicial, numero_final: this.numero_final, conta: this.conta.id,
        data_pagamento: this.funcaoService.converteDataSQL(this.data_pagamento), documento: this.documento, exercicio:
          this.login.exercicio.id, orgao: this.login.orgao.id, favorecido: this.favorecido?.id, ignorar: ignorar
      });
    if (this.tipo == 'PR')
      return this.pagamentoRestoService.pagamentoEmLote({
        numero_inicial: this.numero_inicial, numero_final: this.numero_final, ano: this.exercicio.ano, conta: this.conta.id,
        data_pagamento: this.funcaoService.converteDataSQL(this.data_pagamento), documento: this.documento, exercicio:
          this.login.exercicio.id, orgao: this.login.orgao.id, favorecido: this.favorecido?.id, ignorar: ignorar
      });
  }

  private validarParcelas(): Observable<{ numero: number, parcela: number, saldo_pagar: number }[]> {
    if (this.tipo === 'P')
      return this.pagamentoService.validarParcelas(this.numero_inicial, this.numero_final, this.login.exercicio.id, this.login.orgao.id, this.favorecido?.id);
    if (this.tipo === 'PE')
      return this.pagamentoExtraService.validarParcelas(this.numero_inicial, this.numero_final, this.login.exercicio.id, this.login.orgao.id, this.favorecido?.id);
    if (this.tipo == 'PR')
      return this.pagamentoRestoService.validarParcelas(this.numero_inicial, this.numero_final, this.exercicio.ano, this.login.exercicio.id, this.login.orgao.id, this.favorecido?.id);
  }

  private validar(): boolean {
    if ((!this.numero_inicial || !this.numero_final) && !this.favorecido?.id) {
      toastr.warning('Fornecedor ou número de empenho inicial e final são obrigatorios!');
      return false;
    }
    if (this.tipo === 'PR' && !this.exercicio?.id) {
      toastr.warning(`Campo ano obrigatorio!`);
      return false;
    }
    if (!this.conta?.id) {
      toastr.warning('Campo conta obrigatorio!');
      return false;
    }
    if (!this.data_pagamento) {
      toastr.warning('Campo data de pagamento obrigatorio!');
      return false;
    }
    if (!this.documento) {
      toastr.warning('Campo documento obrigatorio!');
      return false;
    }
    return true;
  }

  public calcularPagamento(parcela: { numero: number, parcela: number, saldo_pagar: number }, select: boolean) {
    if (select) {
      this.totalPagamento += this.funcaoService.arredondar(+parcela.saldo_pagar, 2);
    } else {
      this.totalPagamento -= this.funcaoService.arredondar(+parcela.saldo_pagar, 2);
    }
  }

  public buscarConta() {
    if (this.codigo_conta)
      this.contaService.obter({ codigo: this.codigo_conta, 'orgao.id': this.login.orgao.id, relations: 'banco' })
        .subscribe((data) => {
          if (data)
            this.conta = data;
          else {
            this.codigo_conta = null;
            toastr.warning('Conta não encontrada!');
          }
        });
  }

  public changeConta() {
    this.codigo_conta = this.conta.codigo;
  }

  public titulo(): string {
    if (this.tipo === 'P')
      return 'PAGAMENTO POR LOTE';
    if (this.tipo === 'PE')
      return 'PAGAMENTO DE EMPENHO EXTRA POR LOTE';
    if (this.tipo === 'PR')
      return 'PAGAMENTO DE RESTOS A PAGAR POR LOTE';
    return null;
  }

  public numeroEmpenhoStr(empenho: { numero: number, parcela: number }): string {
    return empenho.parcela && empenho.parcela > 0 ? `${empenho.numero}/${empenho.parcela}` : `${empenho.numero}`;
  }

  public msgErro(msg: { erro?: string }) {
    if (!msg?.erro)
      return `Pagamento realizado com sucesso.`;
    return msg.erro;
  }

  private carregarAutoComplete() {
    this.contaAutoComplete = new EddyAutoComplete(null, this.contaService, 'id', ['numero_conta', 'banco.nome', 'nome'],
      { 'orgao.id': this.login.orgao.id, relations: 'banco', orderBy: 'nome' }, { number: ['numero_conta'], text: ['nome'] }
    );

    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['cpf_cnpj', 'nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['cpf_cnpj'], text: ['nome'] }
    );
  }

  public atualizarMascara() {
    new GlobalService().calendarMascara();
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }
}
