// tslint:disable: variable-name
import { ContratoAditamento, ReservaDotacaoItem, Veiculo } from 'eddydata-lib';
import { PrioridadeRCMS } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Estoque } from '../almoxarifado/estoque.model';
import { Setor } from '../almoxarifado/setor.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { Pessoa } from '../comum/pessoa.model';
import { Usuario } from '../comum/usuario.model';
import { Licitacao } from '../licitacao/licitacao.model';
import { Modalidade } from '../licitacao/modalidade.model';
import { Despesa } from '../planejamento/despesa.model';
import { FichaDespesa } from '../planejamento/ficha-despesa.model';
import { Compra } from './compra.model';
import { Contrato } from './contrato.model';
import { Convenio } from './convenio.model';
import { Prazo } from './prazo.model';
import { RcmsFavorecido } from './rcms-favorecido.model';
import { RcmsItem } from './rcms-item.model';
import { RcmsJustificativaDesbloqueioAutomatico } from './rcms-justificativa-desbloqueio-automatico.model';
import { TabelaDescontoCompra } from './tabela-desconto-compra.model';

export class Rcms extends BaseResourceModel {
  constructor(
    public id?: number,
    public prioridade?: PrioridadeRCMS,
    public numero?: number,
    public tipo_rcms?: string,
    public justificativa?: string,
    public local_entrega?: string,
    public observacao?: string,
    public data_rcms?: Date,
    public data_vencimento?: Date,
    public data_entrega?: Date,
    public data_pagamento?: Date,
    public adiantamento?: boolean,
    public autorizado?: boolean,
    public bloqueado?: boolean,
    public data_desbloqueio?: string,
    public justificativa_desbloqueio?: Date,
    public usuario_desbloqueio?: Usuario,
    public em_cotacao?: boolean,
    public bloqueado_compra?: boolean,
    public compra_direta?: boolean,
    public encaminhado_gabinete?: boolean,
    public processo_licitatorio?: boolean,
    public autorizado_compra?: boolean,
    public subelemento?: Despesa,
    public processo?: string,
    public documento?: string,
    public cotacao_processo?: boolean,
    public excluido?: boolean,
    public data_exclusao?: Date,
    public motivo_exclusao?: String,
    public usuario_exclusao?: Usuario,
    public operador?: Usuario,
    public requerente?: Pessoa,
    public contrato?: Contrato,
    public convenio?: Convenio,
    public licitacao?: Licitacao,
    public modalidade?: Modalidade,
    public ficha?: FichaDespesa,
    public orgao?: Orgao,
    public exercicio?: Exercicio,
    public setor?: Setor,
    public estoque?: Estoque,
    public itens?: RcmsItem[],
    public favorecidos?: RcmsFavorecido[],
    public tabelas_desconto?: TabelaDescontoCompra[],
    public compras?: Compra[],
    public somente_produto?: boolean,
    public somente_servico?: boolean,
    public responsavel_cotacao?: Pessoa,
    public responsavel_fiscalizacao?: Pessoa,
    public prazo_execucao?: Date,
    public justificativa_contratacao?: string,
    public prazo_entrega?: Prazo,
    public prazo_pagamento?: Prazo,
    public prazo?: Prazo,
    public numero_lote?: number,
    public reserva_dotacao?: ReservaDotacaoItem,
    public finalidade_fundeb?: number,
    public contrato_aditamento?: ContratoAditamento,
    public rcmsJustificativa?: RcmsJustificativaDesbloqueioAutomatico,
    public veiculo?: Veiculo,
  ) {
    super();
  }
  static converteJson(json: any): Rcms {
    return Object.assign(new Rcms(), json);
  }
}
