import { Component, Input, OnInit } from '@angular/core';
import { Audesp4LoteObra, Audesp4ObrasServicosEngenharia } from 'eddydata-lib';
import { LicitacaoAudespService } from '../../service/licitacao-audesp.service';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-lote-eng',
  templateUrl: './licitacao-audesp-lote-eng.component.html'
})
export class LicitacaoAudespLoteEngComponent extends BaseLicitacaoAudesp implements OnInit {

  @Input() public objeto: Audesp4ObrasServicosEngenharia;

  public itemAtual: Audesp4LoteObra;
  public itemAnterior: Audesp4LoteObra;

  public opcoesTipoExecucao: { id: number, nome: string }[];
  public listaReceita: { id: number, nome: string }[];
  public listaDespesa: { id: number, nome: string }[];

  public opcoesReplicar = {
    codigo_classificacao: false,
    localizacao_obra: false,
    tipo_obra: false
  }

  constructor(private audespService: LicitacaoAudespService) {
    super()
  }

  ngOnInit(): void {
    this.opcoesTipoExecucao = [
      { id: 4, nome: 'Tarefa' },
      { id: 5, nome: 'Empreitada Integral' },
      { id: 9, nome: 'Contratação semi-integrada' },
      { id: 1, nome: 'Execução Direta' },
      { id: 2, nome: 'Empreitada por Preço Global' },
      { id: 3, nome: 'Empreitada por Preço Unitário' },
      { id: 6, nome: 'Contratação Integrada (RDC)' },
      { id: 7, nome: 'Concessão Pública com obra de engenharia' },
    ];
    this.listaReceita = this.audespService.listarTipoReceita();
    this.listaDespesa = this.audespService.listarTipoDespesa();
  }

  public adicionar() {
    if (!this.objeto.Lote || this.objeto.Lote.length === 0) this.objeto.Lote = [];
    this.itemAtual = {
      NumeroLote: this.objeto.Lote.length + 1,
      DescricaoLote: '',
      TipoExecucao: this.opcoesTipoExecucao[0].id,
      ClassificacaoEconomica: {
        TipoClassificacaoEconomica: this.itemAtual.ClassificacaoEconomica.TipoClassificacaoEconomica,
        CodigoClassificacaoEconomica: this.itemAtual.ClassificacaoEconomica.CodigoClassificacaoEconomica,
        ClassificacaoEconomicaOutros: undefined
      },
      TipoObraServicoEng: undefined,
      TipoObraServicoEngOpcoes: {
        AssessoriasConsultoriasTecnicas: false,
        AuditoriaObrasServicosEngenharia: false,
        ConsertoInstalacaoManutencaoEm: {
          elevadores10: false, instalacaoEletrica9: false, paisagismo13: false, sinalizacao3: false, alarmes5: false, climatizacao11: false,
          incendio21: false, controleAcesso14: false, protecaoDescargas16: false, supervisao2: false, telefonia6: false, ventilacao7: false,
        },
        ConservacaoReparacaoManutencaoDe: {
          adultoras18: false, barragens17: false, edificacoes20: false, obrasSaneamento8: false, pontes4: false,
          rodovias12: false, tratamento19: false, trilho1: false, tuneis22: false, vias15: false,
        },
        ConstrucaoReformaAmpliacaoDe: {
          adultoras18: false, barragens17: false, edificacoes20: false, obrasSaneamento8: false, pontes4: false,
          rodovias12: false, tratamento19: false, trilho1: false, tuneis22: false, vias15: false,
        },
        ElaboracaoProjetoBasicoProjetoExecutivo: false,
        EnsaiosTecnologicos: false,
        EstudosImpactoAmbiental: false,
        EstudosViabilidadeTecnicaEconomica: false,
        LevantamentoAerofotogrametrico: false,
        LevantamentosTopograficosBatimetricosGeodesicos: false,
        Outros: false,
        PericiasAvaliacoes: false,
        SondagensOutrosProcedimentosInvestigacaoGeotecnica: false,
      },
      LocalizacaoObra: [],
      OrcamentoLoteSim: [],
      OrcamentoLoteNao: 'N',
    }
    this.objeto.Lote.push(this.itemAtual);
    console.log(this.objeto);
  }

  public visualizar(item: Audesp4LoteObra) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual['edicao'] = true;
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      let itm = this.objeto.Lote.find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.objeto.Lote.splice(this.objeto.Lote.indexOf(this.itemAtual), 1, itm)
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4LoteObra) {
    this.itemAtual = undefined;
    this.objeto.Lote.splice(this.objeto.Lote.indexOf(item), 1)
  }

  public salvar() {
    this.itemAtual = undefined;
  }

  public aplicarClassificacao() {
    
    if (this.opcoesReplicar?.codigo_classificacao) {
      this.opcoesReplicar.codigo_classificacao = false;
      if (this.itemAtual?.ClassificacaoEconomica) {
        if (!this.itemAtual?.ClassificacaoEconomica?.CodigoClassificacaoEconomica || !this.itemAtual?.ClassificacaoEconomica?.TipoClassificacaoEconomica) {
          toastr.warning('Informe a classificação econômica antes de aplicar a outros itens');
          return;
        }
        this.objeto.Lote.forEach(l => l.ClassificacaoEconomica = Object.assign({}, this.itemAtual.ClassificacaoEconomica))
      }
    }

    if (this.opcoesReplicar.localizacao_obra) {
      this.opcoesReplicar.localizacao_obra = false;
      if (!this.itemAtual?.LocalizacaoObra || this.itemAtual?.LocalizacaoObra.length === 0) {
        toastr.warning(`Informe ao menos uma localização para ser aplicada a outros itens!`)
        return;
      }

      const item = Object.assign({}, this.itemAtual)
      this.objeto.Lote.forEach((lote) => {
        lote.LocalizacaoObra = [];
        item.LocalizacaoObra.forEach((loc) => {
          lote.LocalizacaoObra.push(Object.assign({}, loc))
        })
      })
    }

    if (this.opcoesReplicar?.tipo_obra) {
      this.opcoesReplicar.tipo_obra = false;
      if (!this.itemAtual.TipoObraServicoEngOpcoes) {
        toastr.warning(`Preencha o tipo de obra do item atual para ser replicada para os outros itens!`)
      };

      this.objeto.Lote.forEach((lote) => {
        lote.TipoObraServicoEngOpcoes = JSON.parse(JSON.stringify(this.itemAtual.TipoObraServicoEngOpcoes));
      })
    }
  }

}
