import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ReservaDotacaoItem } from "eddydata-lib";

@Component({
  selector: 'app-reserva-dotacao-consulta-dlg',
  templateUrl: './reserva-dotacao-consulta-dlg.component.html'
})
export class ReservaDotacaoConsultaDlgComponent {

  /**
   * Declaração de variáveis
   */
  @Input() public lista: ReservaDotacaoItem[];
  @Output() callback: EventEmitter<ReservaDotacaoItem> = new EventEmitter();

  public visualizar: boolean = false;


  /**
   * Construtor com as injeções de dependencias
   */
  constructor() { }

  public selecionarReserva(reserva: ReservaDotacaoItem): void {
    this.callback.emit(reserva);
  }
}