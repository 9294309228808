// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Cidade } from '../geo/cidade.model';
import { Partido } from './partido.model';
import { Pessoa } from '../comum/pessoa.model';

export class Vereador extends BaseResourceModel {
  constructor(
    public id?: number,
    public especie?: string,
    public pessoa?: Pessoa,
    public descricao?: string,
    public partido?: Partido,
    public cidade?: Cidade,
  ) {
    super();
  }
  static converteJson(json: any): Vereador {
    return Object.assign(new Vereador(), json);
  }
}
