import { Component, Injector, ElementRef } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BaseResourceFormComponent,
  FichaDespesa, LoginContabil, Despesa, Compra, Rcms, Empenho, FuncaoService, Relatorio
} from 'eddydata-lib';
import { DespesaService, EmpenhoService } from 'administrativo-lib';
import { CompraService, RcmsService } from 'compra-lib';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { FichaDespesaService } from '../service/ficha-despesa.service';

@Component({
  selector: 'app-ficha-despesa-view',
  templateUrl: './ficha-despesa-view.component.html'
})
export class FichaDespesaViewComponent extends BaseResourceFormComponent<FichaDespesa, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public arvDespesa = {
    n1: "",
    n2: "",
    n3: "",
    n4: "",
    n1Nome: "",
    n2Nome: "",
    n3Nome: "",
    n4Nome: ""
  };
  public data: string;
  public tab: number = 0;

  public compras: Compra[];
  public requisicoes: Rcms[];
  public empenhos: Empenho[];

  public colunaOrdenacao: string;
  public ascendente = true;

  public vlSuplementado: number = 0;
  public vlReservado: number = 0;
  public vlAnulado: number = 0;
  public vlDotado: number = 0;
  public vlEmpenhado: number = 0;
  public vlPago: number = 0;
  public vlCompraAberto: number = 0;
  public vlRcmsAberto: number = 0;
  public vlCota: number = 0

  public paginaCorrenteEmpenho: number = 1;
  public paginaTotalEmpenho: number = 0;
  public limiteEmpenho: number = 20;
  public paginaCorrenteCompra: number = 1;
  public paginaTotalCompra: number = 0;
  public limiteCompra: number = 20;
  public paginaCorrenteRequisicao: number = 1;
  public paginaTotalRequisicao: number = 0;
  public limiteRequisicao: number = 20;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected funcaoService: FuncaoService,
    protected fichaService: FichaDespesaService,
    protected despesaService: DespesaService,
    protected compraService: CompraService,
    protected rcmsService: RcmsService,
    protected empenhoService: EmpenhoService,
    private route: ActivatedRoute
  ) {
    super(new FichaDespesa(), injector, FichaDespesa.converteJson, fichaService);
    this.route.params.subscribe(params => this.data = params['data']);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      valor_orcado: 0,
      recurso: this.fb.group({
        codigo: [null, [Validators.required]],
        nome: [null, [Validators.required]]
      }),
      aplicacao: this.fb.group({
        codigo: [null, [Validators.required]],
        nome: [null, [Validators.required]]
      }),
      despesa: this.fb.group({
        codigo: [null, [Validators.required]],
        nome: [null, [Validators.required]]
      }),
      acao: this.fb.group({
        codigo: [null, [Validators.required]],
        nome: [null, [Validators.required]]
      }),
      programa: this.fb.group({
        codigo: [null, [Validators.required]],
        nome: [null, [Validators.required]]
      }),
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'despesa,programa,acao,recurso,aplicacao,executora,funcao,subfuncao,orgao' };
  }

  public reordenar(coluna: string, toggle?: boolean, tipo?: string) {
    if (!coluna) return;

    if (!toggle) this.ascendente = true;
    else this.ascendente = !this.ascendente;

    this.colunaOrdenacao = coluna;
    this.carregarGrid(tipo, `${this.colunaOrdenacao}$${this.ascendente ? "ASC" : "DESC"}`);
  }

  protected setCurrentActionRoute(): void {
    this.currentActionRoute = 'editar';
  }

  protected afterLoad() {
    this.arvDespesa.n1 = this.entidade.despesa.n1.padEnd(8, '0');
    this.arvDespesa.n2 = (this.entidade.despesa.n1 + this.entidade.despesa.n2).padEnd(8, '0');
    this.arvDespesa.n3 = (this.entidade.despesa.n1 + this.entidade.despesa.n2 + this.entidade.despesa.n3).padEnd(8, '0');
    this.arvDespesa.n4 = (this.entidade.despesa.n1 + this.entidade.despesa.n2 + this.entidade.despesa.n3 + this.entidade.despesa.n4).padEnd(8, '0');

    if (!this.data) {
      this.data = this.funcaoService.converteDataSQL(new Date());
    }

    this.despesaService.filtrar(1, -1, {
      'exercicio.id': this.login.exercicio.id,
      'OR': `codigo=${this.arvDespesa.n1};!;!;codigo=${this.arvDespesa.n2};!;!;codigo=${this.arvDespesa.n3};!;!;codigo=${this.arvDespesa.n4}`
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        res.content.forEach((despesa: Despesa) => {
          switch (despesa.codigo) {
            case this.arvDespesa.n1:
              this.arvDespesa.n1Nome = despesa.nome;
              break;
            case this.arvDespesa.n2:
              this.arvDespesa.n2Nome = despesa.nome;
              break;
            case this.arvDespesa.n3:
              this.arvDespesa.n3Nome = despesa.nome;
              break;
            case this.arvDespesa.n4:
              this.arvDespesa.n4Nome = despesa.nome;
              break;
          }
        });
      })

    this.carregarGrid('of')

    this.carregarGrid('requisicao');

    this.carregarGrid('empenho')

    this.carregarTotalizadores();
  }

  public carregarGrid(tipo: string, orderBy?: string,) {

    if (tipo === 'requisicao') {

      if (!!this.requisicoes && (orderBy?.includes('menor_preco_global'))) {
        this.requisicoes.sort((a, b) => {
          if (orderBy?.includes('ASC')) {
            return +a['menor_preco_global'] - +b['menor_preco_global']
          } else {
            return +b['menor_preco_global'] - +a['menor_preco_global']
          }
        })

      } else if (!!this.requisicoes && (orderBy?.includes('situacao'))) {

        this.requisicoes.sort((a, b) => {
          if (orderBy?.includes('ASC')) {
            return a['situacao'] === b['situacao'] ? 0 : a['situacao'] > b['situacao'] ? 1 : -1
          } else {
            return b['situacao'] === a['situacao'] ? 0 : b['situacao'] > a['situacao'] ? 1 : -1
          }
        })

      } else {

        this.rcmsService.extendido(this.paginaCorrenteRequisicao, this.limiteRequisicao, {
          'ficha.id': this.entidade.id,
          'orgao.id': this.login.orgao.id,
          'data_rcms$le': this.data,
          'exercicio.id': this.login.exercicio.id,
          ['relations']: 'ficha.despesa,setor,requerente',
          orderBy: orderBy ? orderBy : 'data_rcms$DESC'
        }).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            this.requisicoes = res.content;
            this.inserirTituloNalista(this.requisicoes, 'requisicao');
            this.limiteRequisicao = res.totalPages;
          })
      }
    }

    if (tipo === 'of') {
      if (!!this.compras && (orderBy?.includes('total_compra'))) {

        this.compras.sort((a, b) => {
          if (orderBy?.includes('ASC')) {
            return +a['total_compra'] - +b['total_compra'];
          } else {
            return +b['total_compra'] - +a['total_compra'];
          }

        })

      } else if (!!this.compras && (orderBy?.includes('situacao'))) {

        this.compras.sort((a, b) => {
          if (orderBy?.includes('ASC')) {
            return a['situacao'] === b['situacao'] ? 0 : a['situacao'] > b['situacao'] ? 1 : -1
          } else {
            return b['situacao'] === a['situacao'] ? 0 : b['situacao'] > a['situacao'] ? 1 : -1
          }
        })
      } else {

        this.compraService.extendido(this.paginaCorrenteCompra, this.limiteCompra, {
          'ficha.id': this.entidade.id,
          'data_compra$le': this.data,
          'orgao.id': this.login.orgao.id,
          'exercicio.id': this.login.exercicio.id,
          ['relations']: 'rcms,favorecido,subelemento,requerente',
          orderBy: orderBy ? orderBy : 'data_compra$DESC'
        }).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            this.compras = res.content;
            this.inserirTituloNalista(this.compras, 'of');
            this.paginaTotalCompra = res.totalPages;
          })
      }
    }

    if (tipo === 'empenho') {
      this.empenhoService.extendido(this.paginaCorrenteEmpenho, this.limiteEmpenho, {
        'ficha.id': this.entidade.id,
        'data_empenho$le': this.data,
        'orgao.id': this.login.orgao.id,
        'exercicio.id': this.login.exercicio.id,
        ['relations']: 'favorecido,subelemento,ficha.recurso,ficha.aplicacao',
        orderBy: orderBy ? orderBy : 'data_empenho$DESC'
      }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.empenhos = res.content;
          this.paginaTotalEmpenho = res.totalPages;
        })
    }

  }

  public selecionarTab(tab: number) {
    this.tab = tab;
  }

  public corStatus(item: Compra) {
    let cor = 'success';
    if (item.excluido)
      cor = 'danger';
    else if (item['situacao'] === 'Empenhado')
      cor = 'warning';
    else if (item['situacao'] === 'Parcialmente Liquidado')
      cor = 'info';
    else if (item['situacao'] === 'Liquidado')
      cor = 'primary';
    let ngCor = {};
    ngCor[`badge-${cor}`] = true;
    return ngCor;
  }

  public tituloStatus(item: Compra) {
    if (item.excluido)
      return 'Excluido';
    if (item['situacao'] === 'Empenhado')
      return 'Empenhado';
    if (item['situacao'] === 'Parcialmente Liquidado')
      return 'Parcialmente Liquidado';
    if (item['situacao'] === 'Liquidado')
      return 'Liquidado';
    return 'Aberto';
  }

  public corEspecieRcms(rcms: Rcms) {
    if (rcms['compras_view'] && rcms['compras_view'].length > 0) {
      return 'dark';
    } else {
      if (rcms.autorizado_compra) {
        if (rcms.autorizado) {
          return 'primary';
        } else {
          return 'warning';
        }
      } else {
        return 'danger';
      }
    }
  }

  public inserirTituloNalista(lista, tipo: string) {

    if (tipo === 'requisicao') {

      for (const item of lista) {

        if (item['compras_view'] && item['compras_view'].length > 0) {
          item['situacao'] = 'Possui OF'
        } else {
          if (item.autorizado_compra) {
            if (item.autorizado) {
              item['situacao'] = 'Autorizado'
            } else {
              item['situacao'] = 'Não autorizada'
            }
          } else {
            item['situacao'] = 'Não concluída'
          }
        }
      }

    } else if (tipo === 'of') {
      for (const item of lista) {
        if (item.excluido)
          item['situacao'] = 'Excluido';
        else if (item['situacao'] === 'EMPENHADO')
          item['situacao'] = 'Empenhado';
        else if (item['situacao'] === 'LIQUIDADO_PARCIAL')
          item['situacao'] = 'Parcialmente Liquidado';
        else if (item['situacao'] === 'LIQUIDADO')
          item['situacao'] = 'Liquidado';
        else item['situacao'] = 'Aberto';
      }
    }
  }

  public tituloEspecieRcms(rcms: Rcms) {
    if (rcms['compras_view'] && rcms['compras_view'].length > 0) {
      return 'Possui OF';
    } else {
      if (rcms.autorizado_compra) {
        if (rcms.autorizado) {
          return 'Autorizado';
        } else {
          return 'Não autorizada';
        }
      } else {
        return 'Não concluída';
      }
    }
  }

  protected async carregarTotalizadores(): Promise<void> {
    this.fichaService.totalizacaoFicha(this.entidade.id, this.login.orgao.id, this.login.exercicio.id, this.data)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.vlCota = res.cota_reservada
        this.vlDotado = (+res.total_dotacao + +res.total_creditado) - +res.total_reservado - +res.cota_reservada;
        this.vlEmpenhado = +res.total_empenhado;
        this.vlPago = +res.total_pago;
        this.vlReservado = +res.total_reservado;
        this.vlAnulado = +res.total_empenho_anulado < 0 ? +res.total_empenho_anulado * - 1 : +res.total_empenho_anulado;
        this.vlCompraAberto = +res.total_compra - +res.total_compra_empenhada;
        this.vlRcmsAberto = +res.total_rcms_pendente;
        this.vlSuplementado = +res.valor_creditado;
        this.vlAnulado = +res.valor_anulado < 0 ? +res.valor_anulado * -1 : +res.valor_anulado;
      });
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected afterInit(): void {
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: FichaDespesa): void {
  }

  public imprimirPDF() {
    const lista: FichaDespesa[] = [];
    lista.push(this.entidade);

    Relatorio.imprimirPersonalizado('FICHA DE DESPESA ORÇAMENTÁRIA',
      this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
      this.montarConteudo(lista), 'portrait', 'FICHA DE DESPESA ORÇAMENTÁRIA',
      {
        linhas: {
          hLineWidth() {
            return 1;
          },
          vLineWidth() {
            return 1;
          },
          hLineColor() {
            return 'black';
          },
          paddingLeft() {
            return 3;
          },
          paddingRight() {
            return 3;
          }
        }
      }, false);
  }

  private montarConteudo(lista: any[]) {
    let retorno: {}[] = [];

    const entidade = lista[0]
    const cabecalho = [[
      {
        text: 'FICHA Nº ' + this.funcaoService.strZero(entidade.numero, 4),
        rowSpan: 2, fontSize: 11, alignment: 'center', bold: true, margin: [0, 10, 0, 10]
      },
      { text: 'DATA', fontSize: 7, border: [true, true, false, false] },
      { text: 'ORGÃO', fontSize: 7, border: [true, true, false, false] },
      { text: 'UNIDADE', fontSize: 7, border: [true, true, true, false] }
    ],
    [
      { text: '' },
      { text: `${this.funcaoService.converteDataBR(this.data)}`, bold: true, border: [false, false, false, true], alignment: 'center' },
      { text: `${this.entidade.orgao.codigo}-${entidade.orgao.nome}`, bold: true, border: [true, false, false, true], alignment: 'center' },
      { text: `${entidade.executora.codigo}-${entidade.executora.nome}`, bold: true, border: [true, false, true, true], alignment: 'center' }
    ]]
    retorno.push({
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [100, 55, '*', '*'],
        body: cabecalho
      }
    });

    const linha2 = []
    linha2.push([
      { text: `ITEM DESPESA:                          ${this.entidade.despesa?.codigo}-${entidade.despesa?.nome}`, border: [true, false, true, false] }
    ])
    linha2.push([
      { text: `PROGRAMA DO GOVERNO:      ${this.entidade.programa?.codigo}-${entidade.programa?.nome}`, border: [true, false, true, false] }
    ])
    linha2.push([
      { text: `AÇÃO GOVERNAMENTAL:        ${this.entidade.acao?.codigo}-${entidade.acao?.nome}`, border: [true, false, true, false] }
    ])
    linha2.push([
      { text: `FUNÇÃO:                                      ${entidade.funcao?.codigo}-${entidade.funcao?.nome}`, border: [true, false, true, false] }
    ])
    linha2.push([
      { text: `SUB-FUNÇÃO:                             ${entidade.subfuncao?.codigo}-${entidade.subfuncao?.nome}`, border: [true, false, true, false] }
    ])
    linha2.push([
      { text: `FONTE DE RECURSO:                ${entidade.recurso?.codigo}-${entidade.recurso?.nome}`, border: [true, false, true, false] }
    ])
    linha2.push([
      { text: `APLICAÇÃO:                               ${entidade.aplicacao?.codigo}-${entidade.aplicacao?.nome}`, border: [true, false, true, true] }
    ])

    retorno.push({
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*'],
        body: linha2
      }
    });

    const quadro2 = []

    quadro2.push([
      { text: `CLASSIFICAÇÃO DA DESPESA `, bold: true, border: [true, false, true, false] }
    ])
    quadro2.push([
      { text: `Categoria:                                   ${this.arvDespesa?.n1}-${this.arvDespesa?.n1Nome}`, border: [true, false, true, false] }
    ])
    quadro2.push([
      { text: `Grupo:                                          ${this.arvDespesa?.n2}-${this.arvDespesa?.n2Nome}`, border: [true, false, true, false] }
    ])
    quadro2.push([
      { text: `MODALIDADE:                            ${this.arvDespesa?.n3}-${this.arvDespesa?.n3Nome}`, border: [true, false, true, false] }
    ])
    quadro2.push([
      { text: `ELEMENTO:                                ${this.arvDespesa?.n4}-${this.arvDespesa?.n4Nome}`, border: [true, false, true, true] }
    ])
    quadro2.push([
      { text: '', fontSize: 8, alignment: 'center', bold: true, border: [false, false, false, false], margin: [0, 5, 0, 5] }
    ])

    retorno.push({
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*'],
        body: quadro2
      }
    });

    const quadro3 = [];

    quadro3.push([
      { text: `POSIÇÃO DA FICHA NA CONTABILIDADE`, colSpan: 2 },
      { text: ' ', border: [false, false, false, false] },
      { text: ' ', border: [false, false, false, false] },
      { text: `POSIÇÃO DA FICHA NO COMPRAS`, colSpan: 2 },
      { text: ' ', border: [false, false, false, false] },
      { text: ' ', border: [false, false, false, false] },
      { text: `POSIÇÃO DA FICHA NO REQUISIÇÃO`, colSpan: 2 },
      { text: ' ', border: [false, false, false, false] },
    ])

    quadro3.push([
      { text: `Orçada`, border: [true, false, false, false] },
      { text: `${this.funcaoService.convertToBrNumber(entidade.valor_orcado)}`, border: [false, false, true, false], alignment: 'right' },
      { text: ' ', border: [false, false, false, false] },
      { text: `Em aberto`, border: [true, false, false, false] },
      { text: `${this.funcaoService.convertToBrNumber(this.vlCompraAberto)}`, border: [false, false, true, false], alignment: 'right' },
      { text: ' ', border: [false, false, false, false] },
      { text: `Em aberto`, border: [true, false, false, false] },
      { text: `${this.funcaoService.convertToBrNumber(this.vlRcmsAberto)}`, border: [false, false, true, false], alignment: 'right' }
    ])

    quadro3.push([
      { text: `Suplementação`, border: [true, false, false, false] },
      { text: `${this.funcaoService.convertToBrNumber(this.vlSuplementado)}`, border: [false, false, true, false], alignment: 'right' },
      { text: ' ', border: [false, false, false, false] },
      { text: `Empenhado`, border: [true, false, false, false] },
      { text: `${this.funcaoService.convertToBrNumber(this.vlEmpenhado)}`, border: [false, false, true, false], alignment: 'right' },
      { text: ' ', border: [false, false, false, false] },
      { text: `Empenhado`, border: [true, false, false, false] },
      { text: `${this.funcaoService.convertToBrNumber(this.vlEmpenhado)}`, border: [false, false, true, false], alignment: 'right' }
    ])

    quadro3.push([
      { text: `Reservado`, border: [true, false, false, false] },
      { text: `${this.funcaoService.convertToBrNumber(this.vlReservado)}`, border: [false, false, true, false], alignment: 'right' },
      { text: '', border: [false, false, false, false] },
      { text: `Reservado`, border: [true, false, false, false] },
      { text: `${this.funcaoService.convertToBrNumber(this.vlReservado)}`, border: [false, false, true, false], alignment: 'right' },
      { text: '', border: [false, false, false, false] },
      { text: `Reservado`, border: [true, false, false, false] },
      { text: `${this.funcaoService.convertToBrNumber(this.vlReservado)}`, border: [false, false, true, false], alignment: 'right' }
    ])

    quadro3.push([
      { text: `Anulado`, border: [true, false, false, false] },
      { text: `${this.funcaoService.convertToBrNumber(this.vlAnulado)}`, border: [false, false, true, false], alignment: 'right' },
      { text: '', border: [false, false, false, false] },
      { text: `Dotação`, border: [true, false, false, false] },
      { text: `${this.funcaoService.convertToBrNumber(this.vlDotado)}`, border: [false, false, true, false], alignment: 'right' },
      { text: '', border: [false, false, false, false] },
      { text: `Dotação`, border: [true, false, false, false] },
      { text: `${this.funcaoService.convertToBrNumber(this.vlDotado)}`, border: [false, false, true, false], alignment: 'right' },
    ])

    quadro3.push([
      { text: `Dotação:`, border: [true, false, false, false] },
      { text: `${this.funcaoService.convertToBrNumber(this.vlDotado)}`, border: [false, false, true, false], alignment: 'right' },
      { text: '', border: [false, false, true, false] },
      { text: '', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
      { text: '', border: [true, false, false, false] },
      { text: '', border: [true, false, false, false] },
      { text: '', border: [false, false, true, false] }
    ])

    quadro3.push([
      { text: `Empenhado`, border: [true, false, false, false] },
      { text: `${this.funcaoService.convertToBrNumber(this.vlEmpenhado)}`, border: [false, false, true, false], alignment: 'right' },
      { text: '', border: [false, false, false, false] },
      { text: '', border: [true, false, false, false] },
      { text: '', border: [false, false, false, false] },
      { text: '', border: [true, false, true, false] },
      { text: '', border: [false, false, false, false] },
      { text: '', border: [false, false, true, false] }
    ])

    quadro3.push([
      { text: `Pago`, border: [true, false, false, false] },
      { text: `${this.funcaoService.convertToBrNumber(this.vlPago)}`, border: [false, false, true, false], alignment: 'right' },
      { text: '', border: [false, false, false, false] },
      { text: '', border: [true, false, false, false] },
      { text: '', border: [false, false, false, false] },
      { text: '', border: [true, false, true, false] },
      { text: '', border: [false, false, false, false] },
      { text: '', border: [false, false, true, false] },
    ])

    quadro3.push([
      { text: `Saldo Atual`, border: [true, true, false, true] },
      { text: `${this.funcaoService.convertToBrNumber(this.vlDotado - this.vlEmpenhado)}`, border: [false, true, true, true], alignment: 'right' },
      { text: '', border: [false, false, false, false] },
      { text: `Saldo Atual`, border: [true, true, false, true] },
      { text: `${this.funcaoService.convertToBrNumber(this.vlDotado - this.vlEmpenhado - this.vlCompraAberto - this.vlReservado)}`, border: [false, true, true, true], alignment: 'right' },
      { text: '', border: [false, false, false, false] },
      { text: `Saldo Atuals`, border: [true, true, false, true] },
      { text: `${this.funcaoService.convertToBrNumber(this.vlDotado - this.vlEmpenhado - this.vlRcmsAberto - this.vlReservado)}`, border: [false, true, true, true], alignment: 'right' }
    ])

    retorno.push({
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: [92, 60, 11.1, 92, 60, 11.1, 92, 60],
        body: quadro3
      }
    });

    return retorno
  }

  public paginaAnteriorEmpenho() {
    this.paginaCorrenteEmpenho =
      this.paginaCorrenteEmpenho === 1 ? 1 : this.paginaCorrenteEmpenho - 1;
    this.carregarGrid('empenho');
    window.scrollTo(0, 0);
  }

  public paginaDigitadaEmpenho(pagina: number) {
    this.paginaCorrenteEmpenho =
      +pagina >= this.paginaTotalEmpenho
        ? this.paginaTotalEmpenho
        : +pagina;
    this.carregarGrid('empenho');
    window.scrollTo(0, 0);
  }

  public proximaPaginaEmpenho() {
    this.paginaCorrenteEmpenho =
      this.paginaCorrenteEmpenho === this.paginaTotalEmpenho
        ? this.paginaTotalEmpenho
        : this.paginaCorrenteEmpenho + 1;
    this.carregarGrid('empenho');
    window.scrollTo(0, 0);
  }

  public mudarLimiteEmpenho(limite: number) {
    this.limiteEmpenho = limite;
    this.paginaDigitadaEmpenho(1);
  }

  public paginaAnteriorCompra() {
    this.paginaCorrenteCompra =
      this.paginaCorrenteCompra === 1 ? 1 : this.paginaCorrenteCompra - 1;
    this.carregarGrid('of');
    window.scrollTo(0, 0);
  }

  public paginaDigitadaCompra(pagina: number) {
    this.paginaCorrenteCompra =
      +pagina >= this.paginaTotalCompra
        ? this.paginaTotalCompra
        : +pagina;
    this.carregarGrid('of');
    window.scrollTo(0, 0);
  }

  public proximaPaginaCompra() {
    this.paginaCorrenteCompra =
      this.paginaCorrenteCompra === this.paginaTotalCompra
        ? this.paginaTotalCompra
        : this.paginaCorrenteCompra + 1;
    this.carregarGrid('of');
    window.scrollTo(0, 0);
  }

  public mudarLimiteCompra(limite: number) {
    this.limiteCompra = limite;
    this.paginaDigitadaCompra(1);
  }

  public paginaAnteriorRequisicao() {
    this.paginaCorrenteRequisicao =
      this.paginaCorrenteRequisicao === 1 ? 1 : this.paginaCorrenteRequisicao - 1;
    this.carregarGrid('requisicao');
    window.scrollTo(0, 0);
  }

  public paginaDigitadaRequisicao(pagina: number) {
    this.paginaCorrenteRequisicao =
      +pagina >= this.paginaTotalRequisicao
        ? this.paginaTotalRequisicao
        : +pagina;
    this.carregarGrid('requisicao');
    window.scrollTo(0, 0);
  }

  public proximaPaginaRequisicao() {
    this.paginaCorrenteRequisicao =
      this.paginaCorrenteRequisicao === this.paginaTotalRequisicao
        ? this.paginaTotalRequisicao
        : this.paginaCorrenteRequisicao + 1;
    this.carregarGrid('requisicao');
    window.scrollTo(0, 0);
  }

  public mudarLimiteRequisicao(limite: number) {
    this.limiteRequisicao = limite;
    this.paginaDigitadaRequisicao(1);
  }
}
