import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, Proponente, Page } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ProponenteService extends BaseResourceService<Proponente> {

  constructor(
    protected injector: Injector
  ) {
    super(`proponentes`, injector);
  }

  /**
 * @deprecated Método deverá ser substituído por `obterPorLicitacao`
 */
  public obterPorLicitacaoTransparencia(id: number): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?relations=favorecido&licitacao_id=${id}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }


  public obterPorLicitacaoClassificacao(id: number, incluirPropostas?: boolean): Observable<Page> {
    const parametros = {
      'licitacao.id': id,
      'relations': 'propostas.memorial,favorecido',
      'orderBy': 'propostas.memorial.ordem'
    }
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar${this.converterParametrosStr(parametros)}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorLicitacao(id: number, incluirPropostas?: boolean): Observable<Page> {
    let uri = `relations=favorecido.tipo,propostas.proponente.favorecido.tipo&licitacao_id=${id}&orderBy=ordem,id${incluirPropostas ? ',propostas.memorial.ordem' : ''}`;
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?${uri}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
