import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { SharedModule } from '../util/shared.module';
import { PncpAtasCadastroComponent } from './pncp-atas/pncp-atas-cadastro/pncp-atas-cadastro.component';
import { PncpAtasComponent } from './pncp-atas/pncp-atas.component';
import { PncpContratoCadastroComponent } from './pncp-contrato/pncp-contrato-cadastro/pncp-contrato-cadastro.component';
import { PncpContratoComponent } from './pncp-contrato/pncp-contrato.component';
import { PncpLicitacaoCadastroComponent } from './pncp-licitacao/pncp-licitacao-cadastro/pncp-licitacao-cadastro.component';
import { PncpLicitacaoComponent } from './pncp-licitacao/pncp-licitacao.component';
import { PncpListComponent } from './pncp-list/pncp.component';
import { PncpPlanoComponent } from './pncp-planos/pncp-plano.component';
import { PncpPlanoCadastroComponent } from './pncp-planos/pncp-planos-cadastro/pncp-plano-cadastro.component';
import { PncpRoutingModule } from './pncp-routing.module';
import { PncpEmpenhoComponent } from './pncp-empenho/pncp-empenhos.component';
import { PncpEmpenhosCadastroComponent } from './pncp-empenho/pncp-empenhos-cadastro/pncp-empenho-cadastro.component';

@NgModule({
  declarations: [
    PncpListComponent, PncpLicitacaoComponent, PncpLicitacaoCadastroComponent, PncpContratoComponent,
    PncpContratoCadastroComponent, PncpAtasComponent, PncpAtasCadastroComponent, PncpPlanoComponent,
    PncpPlanoCadastroComponent, PncpEmpenhoComponent, PncpEmpenhosCadastroComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    PncpRoutingModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    TabViewModule,
    SelectButtonModule,
    RadioButtonModule,
    FieldsetModule,
    ToastModule,
    CheckboxModule,
    ConfirmDialogModule,
  ],
  exports: [
    PncpListComponent,
  ],
  providers: [MessageService, ConfirmationService]
})
export class PncpModule { }
