import { Banco } from '../banco';
import { FuncaoService } from '../../util/funcao.service';

export class Santander extends Banco {
  public D1 = '0';
  public D2 = '0';

  /**
   * Metodo responsavel por resgatar o numero do banco, coloque no return o codigo do seu banco
   */

  public getNumero() {
    return '033';
  }


  /**
   * Metodo particular do santander
   */
  public getDacCampoLivre(campo: string) {

    this.D1 = this.boleto.getModulo10(campo);
    this.D2 = this.boleto.getModulo11(campo + this.D1, 7);

    return this.D1 + this.D2;
  }

  private getCampoLivre() {
    let campo = (new FuncaoService()).strZero(
      Number.parseInt(Number.parseInt(this.boleto.getAgencia().trim())
        + '' + Number.parseInt(this.boleto.getContaCorrente().replace(' ', '').trim())
        + this.boleto.getDvContaCorrente().trim()), 8) + this.boleto.getNossoNumero()
      + '00' + this.getNumero();
    campo += this.getDacCampoLivre(campo);

    return campo;
  }

  /**
   * Metodo que monta o primeiro campo do codigo de barras
   * Este campo como os demais e feito a partir do da documentacao do banco
   * A documentacao do banco tem a estrutura de como monta cada campo, depois disso
   * ÃÂ© sÃÂ³ concatenar os campos como no exemplo abaixo.
   */
  private getCampo1() {
    let campo = this.getNumero() + this.boleto.getMoeda().toString() + this.getCampoLivre().substring(0, 5);

    return campo + this.boleto.getModulo10(campo);
  }

  /**
   * ver documentacao do banco para saber qual a ordem deste campo
   */
  private getCampo2() {
    let campo = this.getCampoLivre().substring(5, 15);

    return campo + this.boleto.getModulo10(campo);
  }

  /**
   * ver documentacao do banco para saber qual a ordem deste campo
   */
  private getCampo3() {
    const campo = this.getCampoLivre().substring(15, 25);

    return campo + this.boleto.getModulo10(campo);
  }

  /**
   * Calculo do Digito de autoconferencia D3 para this.boleto BANCARIO
   * Obrigatorio no codigo de Barras - IPTU
   * 4º caracter do codigo de barras com 44 digitos
   * Segundo apostila no SantanderBanespa-Banespa
   *
   * @param Barras - Codigo de Barras ja montado com 43 digitos
   *
   * @return int
   */
  private static D3(Barras: string) {
    let juncao = Barras;
    let campo1 = juncao.substring(0, 1);
    let campo2 = juncao.substring(1, 2);
    let campo3 = juncao.substring(2, 3);
    let campo4 = juncao.substring(3, 4);
    let campo5 = juncao.substring(4, 5);
    let campo6 = juncao.substring(5, 6);
    let campo7 = juncao.substring(6, 7);
    let campo8 = juncao.substring(7, 8);
    let campo9 = juncao.substring(8, 9);
    let campo10 = juncao.substring(9, 10);
    let campo11 = juncao.substring(10, 11);
    let campo12 = juncao.substring(11, 12);
    let campo13 = juncao.substring(12, 13);
    let campo14 = juncao.substring(13, 14);
    let campo15 = juncao.substring(14, 15);
    let campo16 = juncao.substring(15, 16);
    let campo17 = juncao.substring(16, 17);
    let campo18 = juncao.substring(17, 18);
    let campo19 = juncao.substring(18, 19);
    let campo20 = juncao.substring(19, 20);
    let campo21 = juncao.substring(20, 21);
    let campo22 = juncao.substring(21, 22);
    let campo23 = juncao.substring(22, 23);
    let campo24 = juncao.substring(23, 24);
    let campo25 = juncao.substring(24, 25);
    let campo26 = juncao.substring(25, 26);
    let campo27 = juncao.substring(26, 27);
    let campo28 = juncao.substring(27, 28);
    let campo29 = juncao.substring(28, 29);
    let campo30 = juncao.substring(29, 30);
    let campo31 = juncao.substring(30, 31);
    let campo32 = juncao.substring(31, 32);
    let campo33 = juncao.substring(32, 33);
    let campo34 = juncao.substring(33, 34);
    let campo35 = juncao.substring(34, 35);
    let campo36 = juncao.substring(35, 36);
    let campo37 = juncao.substring(36, 37);
    let campo38 = juncao.substring(37, 38);
    let campo39 = juncao.substring(38, 39);
    let campo40 = juncao.substring(39, 40);
    let campo41 = juncao.substring(40, 41);
    let campo42 = juncao.substring(41, 42);
    let campo43 = juncao.substring(42, 43);
    let /*Peso1*/ p1 = Number.parseInt(campo1) * 4;
    let p2 = Number.parseInt(campo2) * 3;
    let p3 = Number.parseInt(campo3) * 2;
    let p4 = Number.parseInt(campo4) * 9;
    let p5 = Number.parseInt(campo5) * 8;
    let p6 = Number.parseInt(campo6) * 7;
    let p7 = Number.parseInt(campo7) * 6;
    let p8 = Number.parseInt(campo8) * 5;
    let p9 = Number.parseInt(campo9) * 4;
    let p10 = Number.parseInt(campo10) * 3;
    let p11 = Number.parseInt(campo11) * 2;
    let p12 = Number.parseInt(campo12) * 9;
    let p13 = Number.parseInt(campo13) * 8;
    let p14 = Number.parseInt(campo14) * 7;
    let p15 = Number.parseInt(campo15) * 6;
    let p16 = Number.parseInt(campo16) * 5;
    let p17 = Number.parseInt(campo17) * 4;
    let p18 = Number.parseInt(campo18) * 3;
    let p19 = Number.parseInt(campo19) * 2;
    let p20 = Number.parseInt(campo20) * 9;
    let p21 = Number.parseInt(campo21) * 8;
    let p22 = Number.parseInt(campo22) * 7;
    let p23 = Number.parseInt(campo23) * 6;
    let p24 = Number.parseInt(campo24) * 5;
    let p25 = Number.parseInt(campo25) * 4;
    let p26 = Number.parseInt(campo26) * 3;
    let p27 = Number.parseInt(campo27) * 2;
    let p28 = Number.parseInt(campo28) * 9;
    let p29 = Number.parseInt(campo29) * 8;
    let p30 = Number.parseInt(campo30) * 7;
    let p31 = Number.parseInt(campo31) * 6;
    let p32 = Number.parseInt(campo32) * 5;
    let p33 = Number.parseInt(campo33) * 4;
    let p34 = Number.parseInt(campo34) * 3;
    let p35 = Number.parseInt(campo35) * 2;
    let p36 = Number.parseInt(campo36) * 9;
    let p37 = Number.parseInt(campo37) * 8;
    let p38 = Number.parseInt(campo38) * 7;
    let p39 = Number.parseInt(campo39) * 6;
    let p40 = Number.parseInt(campo40) * 5;
    let p41 = Number.parseInt(campo41) * 4;
    let p42 = Number.parseInt(campo42) * 3;
    let p43 = Number.parseInt(campo43) * 2;

    let k = p1 + p2 + p3 + p4 + p5 + p6 + p7 + p8 + p9 + p10 +
      p11 + p12 + p13 + p14 + p15 + p16 + p17 + p18 +
      p19 + p20 + p21 + p22 + p23 + p24 + p25 + p26 +
      p27 + p28 + p29 + p30 + p31 + p32 + p33 + p34 +
      p35 + p36 + p37 + p38 + p39 + p40 + p41 + p42 + p43;
    let resto = k % 11;
    if ((resto == 0) || (resto == 1) || (resto == 10)) {
      return 1;
    } else {
      return 11 - resto;
    }
  }

  /**
   * ver documentacao do banco para saber qual a ordem deste campo
   */
  private getCampo4() {
    let campo = this.getNumero() + this.boleto.getMoeda().toString() +
      this.boleto.getFatorVencimento() + this.boleto.getValorTitulo() + this.getCampoLivre();

    return Santander.D3(campo).toString();
  }

  /**
   * ver documentacao do banco para saber qual a ordem deste campo
   */
  private getCampo5() {
    let campo = this.boleto.getFatorVencimento() + this.boleto.getValorTitulo();
    return campo;
  }

  /**
   * Metodo para monta o desenho do codigo de barras
   * A ordem destes campos tambem variam de banco para banco, entao e so olhar na documentacao do seu banco
   * qual a ordem correta
   */

  public getCodigoBarras() {

    return this.getNumero() + this.boleto.getMoeda().toString() + this.getCampo4() + this.getCampo5() + this.getCampoLivre();

  }

  /**
   * Metodo que concatena os campo para formar a linha digitavel
   * E necessario tambem olhar a documentacao do banco para saber a ordem correta a linha digitavel
   */

  public getLinhaDigitavel() {
    return this.getCampo1().substring(0, 5) + '.' + this.getCampo1().substring(5) + '  ' +
      this.getCampo2().substring(0, 5) + '.' + this.getCampo2().substring(5) + '  ' +
      this.getCampo3().substring(0, 5) + '.' + this.getCampo3().substring(5) + '  ' +
      this.getCampo4() + '  ' + this.getCampo5();
  }


  public getCarteiraFormatted() {
    return this.boleto.getCarteira();
  }


  public getAgenciaCodCedenteFormatted() {
    return Number.parseInt(this.boleto.getAgencia().trim()) + ' / ' + Number.parseInt(this.boleto.getContaCorrente().trim()) + ' ' + this.boleto.getDvContaCorrente();
  }
  /*
  function DigitoNN(Valor: String) : string;
  var
  Contador, Digito, Soma, Multiplo10 : Integer;
  Auxiliar : String;
  Peso : Array[1..10] of Integer;
  begin
  Auxiliar := '';
  Peso[1]  := 7;
  Peso[2]  := 3;
  Peso[3]  := 1;
  Peso[4]  := 9;
  Peso[5]  := 7;
  Peso[6]  := 3;
  Peso[7]  := 1;
  Peso[8]  := 9;
  Peso[9]  := 7;
  Peso[10] := 3;

  Soma := 0;

  for Contador := Length(Valor) downto 1 do
  begin
  Auxiliar := IntToStr((StrToInt(Valor[Contador]) * Peso[Contador]));
  Soma := Soma + StrToInt(Copy(Auxiliar, Length(Auxiliar), 1));
  end;

  Multiplo10 := Soma mod 10;

  if Multiplo10 = 0 then
  Digito := 0
  else
  Digito := 10 - StrToInt((Copy(IntToStr(Soma), Length(Auxiliar), 1)));

  Result := IntToStr(Digito);
  end;*/

  private getDvNossoNumero() {
    let s = Number.parseInt(this.boleto.getAgencia()) + this.boleto.getNossoNumero();
    let c = '7319731973';

    let val = 0;
    let aux = null;
    for (let i = s.length - 1; i >= 0; i--) {
      aux = (Number.parseInt(s.substring(i, i + 1)) * Number.parseInt(c.substring(i, i + 1))).toString();

      val += Number.parseInt(aux.substring(aux.length() - 1));
    }

    let resto = val % 10;
    if (resto == 0) {
      return '0';
    } else {
      return (10 - Number.parseInt(val.toString().substring(aux.length() - 1, aux.length()))).toString();
    }
  }


  public getNossoNumeroFormatted() {
    return Number.parseInt(this.boleto.getAgencia()) + ' ' + this.boleto.getNossoNumero() + ' ' + this.getDvNossoNumero();
  }
}
