import { Injectable, Injector } from '@angular/core';
import { SetorAlmoxarifado } from '../../../entidade/almoxarifado/setor-almoxarifado.model';
import { BaseResourceService } from '../../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class SetorAlmoxarifadoService extends BaseResourceService<SetorAlmoxarifado> {

  constructor(
    protected injector: Injector
  ) {
    super(`setores-almoxarifados`, injector);
  }

}
