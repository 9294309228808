
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseResourceRptComponent, Coluna, LicitacaoPipe } from "eddydata-lib";
import { CompraService } from "../../compra/service/compra.service";

@Component({
  selector: 'lib-compra-contrato-rpt',
  templateUrl: './compra-contrato-rpt.component.html'
})
export class CompraContratoRptComponent extends BaseResourceRptComponent {

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public parametros: { session?: any, filtros?: any } = {};

  constructor(
    private compraService: CompraService
  ) {
    super();
  }

  protected afterInit(): void {
    this.formato;
    this.orientacao = 'landscape';
  }

  protected obterColunasRelatorio(): Coluna[] {
    let retorno: Coluna[] = [];
    retorno.push({ titulo: 'Contrato', coluna: 'contrato.numero', alignment: 'left', tipo: 'Number', agrupar: true, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Nº OF', coluna: 'numero', alignment: 'center', tipo: 'Number', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'DATA', coluna: 'data_compra', tipo: 'Date', alignment: 'center', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'FICHA', coluna: 'ficha.numero', alignment: 'center', tipo: 'Number', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'DESPESA', coluna: 'ficha.despesa.codigo', tipo: 'Number', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'RECURSO', coluna: 'ficha.recurso.codigo', alignment: 'center', tipo: 'Number', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'RAZÃO SOCIAL', coluna: 'favorecido.nome', alignment: 'left', tipo: 'String', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'SUB-ELEMENTO', coluna: 'subelemento.nome', alignment: 'left', tipo: 'String', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'VALOR', coluna: 'total_compra', alignment: 'right', tipo: 'Number', decimais: 2, adicionaisBody: { border: [false, false, false, false] } });
    retorno = retorno.map((r) => {
      r.adicionaisHeader = { margin: 3, fontSize: 8 };
      return r;
    })
    return retorno;
  }

  public obterColunasfiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Nº Contrato', coluna: 'contrato.numero', tipo: 'String', mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }], padrao: true, cols: 6 });
    retorno.push({ titulo: 'Período', coluna: 'data_compra', tipo: 'Date', padrao: true, filtro2: new Date(), cols: 12 });

    return retorno;
  }

  public validar(): boolean {
    return true;
  }

  protected tituloRelatorio(): string {
    return `LISTAGEM GERAL DE ORDENS DE FORNECIMENTO POR CONTRATO`;
  }

  protected larguraColunas(): (string | number)[] {
    return [40, 'auto', 'auto', 'auto', 'auto', '*', '*', 50];
  }

  protected totalizarColunas(): (string | {})[] {
    return [{ nome: 'total_compra', abaixo: true }];
  }

  protected mensagemVazio(): string {
    return `Não foi encontrado ordens de fornecimento para o filtro especificado`;
  }

  public imprimir(): void {
    if (!this.validar())
      return null;
    return super.imprimir();
  }

  protected carregarLista(): Promise<any[]> {
    return new Promise<any[]>((resolve) => {
      let parametros = {
        'orgao.id': this.login.orgao.id, ignoreCondObrig: true,
        'contrato.id$not_null': true, relations: 'ficha.despesa,ficha.recurso,subelemento,favorecido,itens',
        orderBy: 'contrato.numero$ASC,data_compra$ASC'
      };

      if (this.parametros?.filtros)
        parametros = Object.assign(parametros, this.parametros.filtros);

      this.compraService.filtrar(0, -1, parametros)
        .subscribe((data) => {
          if (!data?.content)
            resolve(null);
          else
            resolve(data.content.map((c) => {
              c.contrato.numero = new LicitacaoPipe().transform(c.contrato.numero);
              c.total_compra = c.itens?.length ? c.itens.reduce((acc, i) => acc += +(+i.valor_unitario * +i.quantidade) + +i.valor_icmsipi - +i.valor_desconto, 0) : 0;
              if (c.subelemento?.nome)
                c.subelemento.nome = `${c.subelemento.codigo} - ${c.subelemento.nome}`;
              return c;
            }));
        });
    });
  }

  protected layout(): {} {
    return {
      hLineWidth() {
        return 1;
      },
      vLineWidth(i, node) {
        return 1;
      },
      hLineColor(i) {
        return i === 1 || i === 0 ? 'black' : '0aaa';
      },
      paddingLeft(i) {
        return 2;
      },
      paddingRight(i, node) {
        return 2;
      }
    }
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }
}