import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Material } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MaterialService extends BaseResourceService<Material> {

  constructor(
    protected injector: Injector
  ) {
    super(`materiais`, injector);
  }

  public proximoCodigo(sub_grupo: number, bec: boolean, orgao: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-codigo/${sub_grupo}/${bec}/${orgao}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
