import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Compra, Convenio, FuncaoService, Login, Rcms } from 'eddydata-lib';
import { CompraService, RcmsService } from 'compra-lib';
import { ConvenioService } from '../service/convenio.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-convenio-saldo',
  templateUrl: './convenio-saldo.component.html',
})
export class ConvenioSaldoComponent implements OnInit, OnChanges, OnDestroy {

  @Input() login: Login;
  @Input() entidade: Convenio;

  protected unsubscribe: Subject<void> = new Subject();

  public convenio: any;
  public valorConcedente = 0;
  public valorAditamentos = 0;
  public valorReajuste = 0;
  public valorEstorno = 0;
  public valorCompra = 0;
  public valorRequisicao = 0;
  public valorEmpenho = 0;
  public totalConcedente = 0;
  public valorContrapartida = 0;
  public valorContraAditamentos = 0;
  public valorContraReajuste = 0;
  public valorContraEstorno = 0;
  public valorContraCompra = 0;
  public valorContraRequisicao = 0;
  public valorContraEmpenho = 0;
  public totalContrapartida = 0;

  constructor(
    protected convenioService: ConvenioService,
    protected compraService: CompraService,
    protected rcmsService: RcmsService,
    protected funcaoService: FuncaoService,
  ) {
  }

  ngOnInit() {
    this.convenioService.saldoConvenio(this.entidade.id, this.login.orgao.id)
      .pipe(takeUntil(this.unsubscribe)).subscribe(async (convenio) => {
        this.convenio = convenio;
        await this.calcularValores(convenio);
      }, error => this.funcaoService.acaoErro(error));
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  private async calcularValores(convenio: Convenio) {
    this.valorConcedente = +convenio.valor_convenio - +convenio.valor_contrapartida;
    this.valorContrapartida = +convenio.valor_contrapartida;

    // Aditamentos
    if (convenio.aditamento?.length) {
      for (const aditamento of convenio.aditamento) {
        this.valorAditamentos += +aditamento.valor_transferencia - +aditamento.valor_contrapartida;
        this.valorContraAditamentos += +aditamento.valor_contrapartida;
      }
    }

    // Reajustes e estornos
    if (convenio.reajuste?.length) {
      for (const reajuste of convenio.reajuste) {
        if (reajuste.especie === 'R') {
          if (reajuste.tipo_lancamento === 'CONCEDENTE') {
            if (reajuste.ajuste_saldo === true) {
              this.valorReajuste += +reajuste.valor_reajuste;
            }
          } else if (reajuste.tipo_lancamento === 'CONTRAPARTIDA') {
            if (reajuste.ajuste_saldo === true) {
              this.valorContraReajuste += +reajuste.valor_reajuste;
            }
          }
        } else if (reajuste.especie === 'E') {
          if (reajuste.tipo_lancamento === 'CONCEDENTE') {
            if (reajuste.ajuste_saldo === true) {
              this.valorEstorno += +reajuste.valor_reajuste;
            }
          } else if (reajuste.tipo_lancamento === 'CONTRAPARTIDA') {
            if (reajuste.ajuste_saldo === true) {
              this.valorContraEstorno += +reajuste.valor_reajuste;
            }
          }
        }
      }
    }

    // Empenhados
    if (convenio.empenhos?.length) {
      for (const e of convenio.empenhos) {
        const empRecurso = e.ficha?.recurso?.codigo;
        const empAplicacao = e.ficha?.aplicacao?.codigo;
        const empVariavel = e.ficha?.aplicacao_variavel?.codigo;

        if (convenio.recurso_contra && convenio.aplicacao_contra && convenio.aplicacao_variavel_contra && convenio.recurso_contra?.codigo === empRecurso && convenio.aplicacao_contra?.codigo === empAplicacao && convenio.aplicacao_variavel_contra?.codigo === empVariavel) {
          this.valorContraEmpenho += +e.valor_empenho;
        } else {
          this.valorEmpenho += +e.valor_empenho;
        }
      }
    }

    // Rcms
    const rcms = await (await this.rcmsService.extendido(0, -1, {
      relations: 'setor,itens,licitacao,contrato,orgao,ficha.despesa,ficha.recurso,ficha.aplicacao,ficha.aplicacao_variavel,subelemento,requerente,modalidade,prazo,compras',
      'convenio.id': this.entidade.id,
      'orgao.id': this.login.orgao.id,
      'count_compra': 0,
      'excluido': false,
      'ignoreCondObrig': true,
    }).toPromise()).content as Rcms[];

    if (rcms?.length) {
      for (const r of rcms) {
        const rcmsRecurso = r.ficha?.recurso?.codigo;
        const rcmsAplicacao = r.ficha?.aplicacao?.codigo;
        const rcmsVariavel = r.ficha?.aplicacao_variavel?.codigo;

        if (convenio.recurso_contra && convenio.aplicacao_contra && convenio.aplicacao_variavel_contra && convenio.recurso_contra?.codigo === rcmsRecurso && convenio.aplicacao_contra?.codigo === rcmsAplicacao && convenio.aplicacao_variavel_contra?.codigo === rcmsVariavel) {
          this.valorContraRequisicao += +r.itens.reduce((acc, i) => acc += +i.valor_unitario * +i.quantidade, 0);
        } else {
          this.valorRequisicao += +r.itens.reduce((acc, i) => acc += +i.valor_unitario * +i.quantidade, 0);
        }
      }
    }

    // Compras
    const compras = await (await this.compraService.extendido(0, -1, {
      relations: 'rcms.setor,licitacao,contrato,orgao,ficha.despesa,ficha.recurso,ficha.aplicacao,ficha.aplicacao_variavel,subelemento,empenho',
      'convenio.id': this.entidade.id,
      'orgao.id': this.login.orgao.id,
      'exercicio.id': this.login.exercicio.id,
      'empenho.id$null': true,
      'excluido': false,
      'ignoreCondObrig': true,
    }).toPromise()).content as Compra[];

    if (compras?.length) {
      for (const c of compras) {
        const compraRecurso = c.ficha?.recurso?.codigo;
        const compraAplicacao = c.ficha?.aplicacao?.codigo;
        const compraVariavel = c.ficha?.aplicacao_variavel?.codigo;

        if (convenio.recurso_contra && convenio.aplicacao_contra && convenio.aplicacao_variavel_contra && convenio.recurso_contra?.codigo === compraRecurso && convenio.aplicacao_contra?.codigo === compraAplicacao && convenio.aplicacao_variavel_contra?.codigo === compraVariavel) {
          this.valorContraRequisicao += +c.total_compra;
        } else {
          this.valorCompra += +c.total_compra;
        }
      }
    }

    this.totalConcedente = this.valorConcedente + this.valorAditamentos + this.valorReajuste - this.valorEstorno - this.valorRequisicao - this.valorCompra - this.valorEmpenho;
    this.totalContrapartida = this.valorContrapartida + this.valorContraAditamentos + this.valorContraReajuste - this.valorContraEstorno - this.valorContraRequisicao - this.valorContraCompra - this.valorContraEmpenho;
  }

}
