import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, FavorecidoInsencao } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class FavorecidoInsencaoService extends BaseResourceService<FavorecidoInsencao> {

  constructor(
    protected injector: Injector
  ) {
    super(`favorecido-insencao`, injector);
  }

}
