import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { BaseResourceService } from '../../models/services/base-resource.service';
import { Usuario } from '../../entidade/comum/usuario.model';
import { Sistemas } from '../../components/types';

@Injectable({
  providedIn: 'root'
})
export class RPUsuarioService extends BaseResourceService<Usuario> {

  constructor(
    protected injector: Injector
  ) {

    super(`usuarios`, injector);
  }

  public obterPorCPF(cpf: string, orgaoId: number): Observable<Usuario> {
    return this.http.get<Usuario>(`${this.login.cidade.id}/${this.api}/filtrar?cpf=${cpf}&orgao_id=${orgaoId}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public registrar(entidade: Usuario): Observable<Usuario> {

    entidade.senha = Math.random().toString(36).slice(-10);
    entidade.ativo = false;
    entidade.solicitacao = true;

    return this.http.post(`${this.api}/registro`, entidade).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public registrarSic(entidade: Usuario): Observable<Usuario> {

    entidade.senha = Math.random().toString(36).slice(-10);
    entidade.ativo = true;
    entidade.solicitacao = false;

    return this.http.post(`${this.api}/registro`, entidade).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public autorizar(entidade: Usuario): Observable<string> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/autorizar`, entidade).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public trocarSenha(entidade: Usuario): Observable<Usuario> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/trocar-senha`, entidade).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public autenticar(email: string, senha: string, sistema: Sistemas): Observable<any> {
    const login = { email, senha, sistema };
    return this.http.post<any>(`${this.api}/autenticar`, JSON.stringify(login), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    });
  }

  public gerarTokenPublico(): Observable<any> {
    return this.http.post<any>(`${this.api}/token-publico`, null, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    });
  }

  public recuperarSenha(email: string): Observable<any> {
    return this.http.post<any>(`${this.api}/recuperar-senha`, JSON.stringify({ email }), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    });
  }

}
