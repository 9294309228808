import { BaseResourceModel } from '../../models/base-resource.model';

export class Perfil extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public descricao?: string
  ) {
    super();
  }
}
