import { TipoArquivoFase4 } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Compra } from '../compra/compra.model';
import { LicitacaoAudespPacote } from './licitacao-audesp-pacote.model';
import { Licitacao } from './licitacao.model';

export class LicitacaoAudesp extends BaseResourceModel {
  constructor(
    public id?: number,
    public tipo?: TipoArquivoFase4,
    public arquivo?: any,
    public codigo?: string,
    public identificacao?: string,
    public exportado?: boolean,
    public enviado?: boolean,
    public armazenado?: boolean,
    public parente?: LicitacaoAudesp,
    public pacote?: LicitacaoAudespPacote,
    public licitacao?: Licitacao,
    public compra?: Compra,
    public valido?: boolean,
    public mensagemXML?: string[]
  ) {
    super();
  }

  static converteJson(json: any): LicitacaoAudesp {
    return Object.assign(new LicitacaoAudesp(), json);
  }
}
