import { Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Coluna, FormatoExportacao } from '../../components/types';
import { ScheduleLog } from '../../entidade/comum/schedule-log.model';
import { Schedule } from '../../entidade/comum/schedule.model';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { BaseResourceListComponent, Filtro } from '../../models/base-resource-list';
import { ScheduleLogService } from '../service/schedule-log.service';

declare var $: any;

@Component({
  selector: 'lib-schedule-log-dlg',
  templateUrl: './schedule-log-dlg.component.html'
})
export class ScheduleLogDlgComponent extends BaseResourceListComponent<ScheduleLog, LoginContabil> implements OnChanges {

  /**
   * Declaração de variáveis
   */
  @Input() public schedule: Schedule;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private logService: ScheduleLogService) {
    super(logService, injector);
    this.limite = 10
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['schedule'] && this.schedule) {
      this.preencherGrid();
      $('#dialogScheduleLog').modal('show');
    }
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return '';
  }

  protected condicoesGrid(): {} {
    return {
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['data_cadastro$DESC']; //proxima_exec
  }

  protected filtrosGrid(): Filtro {
    return {
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: ScheduleLog): Observable<ScheduleLog> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [];
  }

  public exportarListagem(formato: FormatoExportacao) {
  }

  public preencherGrid() {
    if (!this.schedule) return;
    super.preencherGrid();
  }
  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
