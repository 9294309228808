// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { Liquidacao } from '../contabil/liquidacao.model';
import { LicitacaoAudesp } from '../licitacao/licitacao-audesp.model';
import { Recurso } from '../planejamento/recurso.model';
import { ContaBancariaRecurso } from './conta-bancaria-recurso.model';
import { ContaBancaria } from './conta-bancaria.model';
import { ConvenioPrestacao } from '../compra/convenio-prestacao.model';

export class Pagamento extends BaseResourceModel {
  constructor(
    public id?: number,
    public mes?: number,
    public data_pagamento?: Date,
    public data_referencia?: Date,
    public especie?: string,
    public documento?: string,
    public historico?: string,
    public valor_pago?: number,
    public valor_retido?: number,
    public aux?: number,
    public impresso?: boolean,
    public anulacao?: boolean,
    public retiddo?: boolean,
    public conta?: ContaBancaria,
    public conta_recurso?: ContaBancariaRecurso,
    public exercicio?: Exercicio,
    public orgao?: Orgao,
    public liquidacao?: Liquidacao,
    public recurso?: Recurso,
    public aplicacao?: Recurso,
    public aplicacao_variavel?: Recurso,
    public usuario_cadastro?: Usuario,
    public licitacao_audesp?: LicitacaoAudesp,
    public anulado_total?: boolean,
    public pagamento_anulado?: Pagamento,
    public prestacoes?: ConvenioPrestacao[],
    public situacao?: string,
    public pagamento_S13?: boolean,
    public valor_tributavel?: number,
    public valor_RRA?: number,
    public juridico_RRA?: boolean,
    public indice_fci_scp?: number,
    public cnpj_fci_scp?: string,
    public porcentagem_scp?: number,
    public pais_pagamento?: number,
    public nif_tributo?: number
  ) {
    super();
  }

  static converteJson(json: any): Pagamento {
    return Object.assign(new Pagamento(), json);
  }
}
