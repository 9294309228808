import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BaseResourceFormComponent, FuncaoService, GlobalService,
  LoginContabil, Modalidade, TipoContratacao, TipoContratacaoCondicaoDespesa, TipoContratacaoEvento, TipoContratacaoModalidade
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { ModalidadeService } from '../../modalidade/service/modalidade.service';
import { TipoContratacaoCondicaoDespesaService } from '../service/tipo-contratacao-condicao-despesa.service';
import { TipoContratacaoEventoService } from '../service/tipo-contratacao-evento.service';
import { TipoContratacaoService } from '../service/tipo-contratacao.service';

@Component({
  selector: 'lib-tipo-contratacao-view',
  templateUrl: './tipo-contratacao-view.component.html'
})
export class TipoContratacaoViewComponent extends BaseResourceFormComponent<TipoContratacao, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @ViewChild('nome') inputField: ElementRef;
  public totalTipoContratacao: number;
  public listaTipoContratacaoEventos: TipoContratacaoEvento[];
  public listaCondices: TipoContratacaoCondicaoDespesa[] = [];
  public listaModalidades: { modalidade: Modalidade, marcado: boolean }[];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected tipoContratacaoEventoService: TipoContratacaoEventoService,
    protected tipoContratacaoCondicoDespesaService: TipoContratacaoCondicaoDespesaService,
    protected modalidadeService: ModalidadeService,
    protected tipoService: TipoContratacaoService) {
    super(new TipoContratacao(), injector, TipoContratacao.converteJson, tipoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      nome: [null, [Validators.required]],
      tce: [null, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      tipoContratacaoEventos: [null],
      modalidades: [null],
      condicoes_despsa: [null],
      exige_cno: [false, [Validators.required]]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'modalidades.modalidade' };
  }

  protected afterLoad() {
    this.obterListaModalidades();
    this.tipoContratacaoEventoService.filtrar(0, -1, {
      'tipo_contratacao.id': this.entidade.id,
      'tipo_contratacao.tce': this.entidade.tce
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data: any) => {
          this.listaTipoContratacaoEventos = data ? data.content : new Array<TipoContratacaoEvento>();
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));

    this.tipoContratacaoCondicoDespesaService.filtrar(0, -1, {
      'tipo_contratacao.id': this.entidade.id
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (data?.content && data.content.length > 0)
          this.listaCondices = data.content;
      }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));
  }

  protected afterInit(): void {
    this.obterListaModalidades();
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected beforeSubmit() {
    try {
      if (this.entidadeForm.get('nome').value === '') {
        throw new Error('Informe o nome!');
      }
      this.entidadeForm.get('tipoContratacaoEventos').setValue(this.listaTipoContratacaoEventos);
      this.entidadeForm.get('condicoes_despsa').setValue(this.listaCondices);
      this.entidadeForm.get('orgao').setValue(this.login.orgao);

      const itens: TipoContratacaoModalidade[] = [];
      this.listaModalidades.filter(m => m.marcado).forEach(m => {
        itens.push({ modalidade: m.modalidade });
      })
      if (itens.length === 0) {
        throw new Error('Informe a(s) modalidade(s) que o tipo de contratação poderá usar!');
      }
      this.entidadeForm.get('modalidades').setValue(itens);

    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(ent: TipoContratacao) {
    if (!this.limparTela)
      this.loadResource();
    window.scrollTo(0, 0);
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private obterListaModalidades() {
    this.modalidadeService.filtrar(1, -1, { cidade_id: this.login.cidade.id, orderBy: 'codigo' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        const lista: Modalidade[] = res.content;
        this.listaModalidades = [];
        lista.forEach(m =>
          this.listaModalidades.push({
            marcado: Boolean(this.entidade.modalidades?.find(ent => ent.modalidade.id === m.id)).valueOf(),
            modalidade: m
          })
        );
      }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));

  }
}
