import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { TransferenciaEspecieFormComponent } from './transferencia-especie-form/transferencia-especie-form.component';
import { TransferenciaEspecieListComponent } from './transferencia-especie-list/transferencia-especie-list.component';
import { TransferenciaEspecieRoutingModule } from './transferencia-especie-routing.module';
import { TransferenciaEspecieViewComponent } from './transferencia-especie-view/transferencia-especie-view.component';

@NgModule({
  declarations: [TransferenciaEspecieListComponent, TransferenciaEspecieFormComponent, TransferenciaEspecieViewComponent],
  imports: [
    CommonModule,
    TransferenciaEspecieRoutingModule,
    SharedModule,
    FormsModule,
    ToastModule,
    IMaskModule,
    AutoCompleteModule
  ]
})
export class TransferenciaEspecieModule { }
