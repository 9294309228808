import { Audesp4ExigenciaGarantiaSim, Audesp4GestorDoContratoSim, Audesp4VigenciaPorData } from './types/ajuste-type';
import { Audesp4ClassificacaoEconomica, Audesp4Empenho, Audesp4LicitacaoProprioOrgaoNao, Audesp4Publicacao, Audesp4TermoCiencia } from './types/comum-type';

export class Ajuste {
    CodigoLicitacao: number;
    CodigoAjuste: number;
    lotes: {
        marcado: boolean;
        NumeroLote: number;
        DescricaoLote: string;
        Quantidade: number;
        UnidadeMedida: string;
        OrcamentoLoteNao?: string;
    }[];
    contratoId: number;
    NumContrato: string;
    AnoContrato: number;
    ValorContrato: number;
    ObjetoContrato: string;
    Quantidade: number;
    UnidadeMedida: string;
    tipoDoc: 'CPF' | 'CNPJ' | 'OUTRO';
    CNPJ?: string;
    CPF?: string;
    OutroDoc?: string;
    NomeRazaoSocial: string;
    ClausulasFinanceira?: string;
    ClassificacaoEconomica: Audesp4ClassificacaoEconomica;
    NotaEmpenho?: Audesp4Empenho[];
    ExigenciaGarantiaSim?: Audesp4ExigenciaGarantiaSim;
    ExigenciaGarantiaNao?: 'S' | 'N';
    ClausulasPenaisSim?: string;
    ClausulasPenaisNao?: 'S' | 'N';
    GestorDoContratoSim?: Audesp4GestorDoContratoSim;
    GestorDoContratoNao?: 'S' | 'N';
    vigencia?: 'DATA' | 'ORDEM_SERVICO';
    ValidadeMeses?: number;
    VigenciaPorData?: Audesp4VigenciaPorData;
    AssinaturaDt: string;
    PublicacaoExtratoContratoNao?: 'S' | 'N';
    PublicacaoExtratoContrato?: Audesp4Publicacao[];
    TermoCienciaContratantes: Audesp4TermoCiencia[];
    TermoCienciaContratados: Audesp4TermoCiencia[];

    constructor() {
        this.tipoDoc = 'CPF';
        this.ExigenciaGarantiaNao = 'N';
        this.ClausulasPenaisNao = 'N';
        this.GestorDoContratoNao = 'N';
        this.PublicacaoExtratoContratoNao = 'N';
        this.ClassificacaoEconomica = {
            CodigoClassificacaoEconomica: undefined,
            TipoClassificacaoEconomica: 2,
            ClassificacaoEconomicaOutros: {
                CodigoClassificacaoEconomicaOutros: undefined,
                DescricaoClassificacaoEconomicaOutros: undefined
            }
        }
        this.ExigenciaGarantiaSim = {
            ClausulasGarantia: undefined, ValorGarantia: undefined, ValorGarantiaPercentualContrato: undefined
        }
        this.GestorDoContratoSim = { GestorCPF: undefined, GestorCargo: undefined }
        this.vigencia = 'DATA';
        this.VigenciaPorData = { FimVigenciaDt: undefined, InicioVigenciaDt: undefined }
        this.NotaEmpenho = [{ NumNotaEmpenho: undefined, NotaEmpenhoDt: undefined, NotaEmpenhoValor: undefined, FonteRecursos: undefined }];
    }
}

export class AjusteArray {
    LicitacaoProprioOrgaoSim?: 'S';
    LicitacaoProprioOrgaoNao?: Audesp4LicitacaoProprioOrgaoNao;
    CodigoLicitacao: number;
    Contrato?: AjusteContrato[];
    NotaEmpenhoDocSimilar?: AjusteNotaEmpenho[];
}

export class AjusteContrato {
    CodigoAjuste: number;
    NumeroLotes: number[];
    NumContrato: string;
    AnoContrato: number;
    ValorContrato: number;
    ObjetoContrato: string;
    Quantidade: number;
    UnidadeMedida: string;
    CNPJ_ns3?: string;
    CPF_ns3?: string;
    OutroDoc_ns3?: string;
    NomeRazaoSocial: string;
    ClausulasFinanceira?: string;
    ClassificacaoEconomica: Audesp4ClassificacaoEconomica;
    NotaEmpenho?: Audesp4Empenho[];
    ExigenciaGarantiaSim?: Audesp4ExigenciaGarantiaSim;
    ExigenciaGarantiaNao?: 'N';
    ClausulasPenaisSim?: string;
    ClausulasPenaisNao?: 'N';
    GestorDoContratoSim?: Audesp4GestorDoContratoSim;
    GestorDoContratoNao?: 'N';
    ValidadeMeses?: number;
    VigenciaPorData?: Audesp4VigenciaPorData;
    AssinaturaDt: string;
    PublicacaoExtratoContratoNao?: 'N';
    PublicacaoExtratoContrato?: Audesp4Publicacao[];
    TermoCienciaContratantes: Audesp4TermoCiencia[];
    TermoCienciaContratados: Audesp4TermoCiencia[];
}

export class AjusteNotaEmpenho {
    CodigoAjuste: number;
    NumeroDosLotes: number[];
    CNPJ_ns3?: string;
    CPF_ns3?: string;
    OutroDoc_ns3?: string;
    NomeRazaoSocial: string;
    NotaEmpenho: Audesp4Empenho;
    TermoCienciaContratantes: Audesp4TermoCiencia[];
    TermoCienciaContratados: Audesp4TermoCiencia[];
}