import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { Veiculo } from './veiculo.model';
import { ViagemPassageiro } from './viagem-passageiro.model';

export class Viagem extends BaseResourceModel {
  constructor(
    public id?: number,
    public destino?: string,
    public cidade?: string,
    public justificativa?: string,
    public data_saida_prev?: Date,
    public data_saida?: Date,
    public data_chegada?: Date,
    public data_aceite?: Date,
    public data_recusa?: Date,
    public justificativa_recusa?: string,
    public km_viagem?: number,
    public km_saida?: number,
    public km_chegada?: number,
    public veiculo?: Veiculo,
    public usuario_aceite?: Usuario,
    public usuario_recusa?: Usuario,
    public solicitante?: Usuario,
    public orgao?: Orgao,
    public passageiros?: ViagemPassageiro[],
  ) {
    super();
  }

  static converteJson(json: any): Viagem {
    return Object.assign(new Viagem(), json);
  }
}
