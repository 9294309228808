// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Setor } from '../almoxarifado/setor.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { Pessoa } from '../comum/pessoa.model';
import { Usuario } from '../comum/usuario.model';
import { RplFavorecido } from './rpl-favorecido.model';
import { RplItem } from './rpl-item.model';
import { TipoContratacao } from './tipo-contratacao.model';

export class Rpl extends BaseResourceModel {
  constructor(
    public id?: number,
    public numero?: number,
    public formato_contratacao?: 'REGISTRO_PRECO' | 'COMPRA_NORMAL',
    public local_entrega?: string,
    public objeto?: string,
    public justificativa?: string,
    public obs?: string,
    public data_exclusao?: Date,
    public motivo_exclusao?: String,
    public usuario_exclusao?: Usuario,
    public orgao?: Orgao,
    public exercicio?: Exercicio,
    public setor?: Setor,
    public tipo_contratacao?: TipoContratacao,
    public requerente?: Pessoa,
    public responsavel?: Pessoa,
    public itens?: RplItem[],
    public favorecidos?: RplFavorecido[],
  ) {
    super();
  }

  static converteJson(json: any): Rpl {
    return Object.assign(new Rpl(), json);
  }
}
