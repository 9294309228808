import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import {
  BaseResourceListComponent, Coluna, DateFormatPipe, Filtro,
  FormatoExportacao, GlobalService, LoginContabil, PreLiquidacao
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PreLiquidacaoService } from '../service/pre-liquidacao.service';

declare var $: any;

@Component({
  selector: 'lib-pre-liquidacao-list',
  templateUrl: './pre-liquidacao-list.component.html'
})
export class PreLiquidacaoListComponent extends BaseResourceListComponent<PreLiquidacao, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public especieSelect = 'PLO';
  public listaEspecies: Array<any>;
  public liquidacaoAnular: PreLiquidacao;
  public ptBR: any;
  public datepipe: DatePipe;
  public data1: Date;
  public data2: Date;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private globalService: GlobalService,
    private preliquidacaoService: PreLiquidacaoService) {
    super(preliquidacaoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(item: PreLiquidacao): boolean {
    return !item.anulacao
  }
  
  protected relations(): string {
    return 'empenho,empenho.ficha,empenho.favorecido,empenho.subelemento,liquidacoes';
  }

  protected condicoesGrid(): {} {
    const parametros = {};
    this.datepipe = new DatePipe('pt');
    parametros['data_liquidacao$ge'] = this.datepipe.transform(this.data1, 'yyyy-MM-dd');
    parametros['data_liquidacao$le'] = this.datepipe.transform(this.data2, 'yyyy-MM-dd');
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['exercicio.id'] = this.login.exercicio.id

    sessionStorage.removeItem('/preliquidacoes_data1');
    sessionStorage.removeItem('/preliquidacoes_data2');

    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['data_liquidacao$DESC', 'id$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['id', 'empenho.numero', 'valor_liquidado', 'empenho.subelemento.codigo', 'empenho.favorecido.cpf_cnpj'],
      date: ['data_liquidacao'],
      text: ['empenho.favorecido.nome', 'empenho.subelemento.nome'],
    };
  }

  public beforeInit(): void {
    this.usarFiltroPersonalizado = true;
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    const dt1: string = sessionStorage.getItem('/preliquidacoes_data1');
    const dt2: string = sessionStorage.getItem('/preliquidacoes_data2');
    if (!dt1) {
      this.data1 = new DateFormatPipe().transform(this.datepipe.transform(new Date(`01/01/${this.login.exercicio.ano}`), 'yyyy-MM-dd'), []);
    } else {
      this.data1 = new DateFormatPipe().transform(dt1, []);
    }
    if (!dt2) {
      this.data2 = new DateFormatPipe().transform(this.datepipe.transform(new Date(), 'yyyy-MM-dd'), []);
    } else {
      this.data2 = new DateFormatPipe().transform(dt2, []);
    }
  }

  protected afterInit(): void {
    this.listaEspecies = [
      { id: 'LEO', nome: 'ORÇAMENTÁRIA' },
      { id: 'LRP', nome: 'RESTOS A PAGAR' }
    ];
  }

  public anular(item) {
    this.liquidacaoAnular = item;

    $('#dialogAnular').modal('show');
  }

  protected acaoRemover(model: PreLiquidacao): Observable<PreLiquidacao> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Data', coluna: 'data_liquidacao', agrupar: true, bold: true },
      { titulo: 'Número', coluna: 'empenho.numero' },
      { titulo: 'Parcela', coluna: 'parcela' },
      { titulo: 'Beneficiario', coluna: 'empenho.favorecido.nome' },
      { titulo: 'Ficha', coluna: 'empenho.ficha.numero' },
      { titulo: 'Historico', coluna: 'historico' },
      { titulo: 'Vencimento', coluna: 'data_vencimento' },
      { titulo: 'Valor', coluna: 'valor_liquidado', alignment: 'right', decimais: 2 }
    ];
  }

  public obterColunasfiltroPersonalizado(): {}[] {
    const retorno: Coluna[] = [];

    retorno.push({ titulo: 'Data', coluna: 'data_liquidacao', tipo: 'Date', alignment: 'center', padrao: true, filtro2: new Date() });
    retorno.push({ titulo: 'N° Empenho', coluna: 'empenho.numero', tipo: 'Number', padrao: true });
    retorno.push({ titulo: 'Favorecido Nome', coluna: 'empenho.favorecido.nome', tipo: 'String', alignment: 'center', cols: 4 });
    retorno.push({ titulo: 'Favorecido CPF/CPNJ', coluna: 'empenho.favorecido.cpf_cnpj', tipo: 'String', alignment: 'center', cols: 3 });
    retorno.push({ titulo: 'Descrição Despesa', coluna: 'empenho.subelemento.nome', tipo: 'String', alignment: 'center', cols: 4 });
    retorno.push({ titulo: 'N° Descrição Despesa', coluna: 'empenho.subelemento.codigo', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Anulado', coluna: 'anulacao', tipo: 'Boolean' });
    retorno.push({ titulo: 'Liquidado', coluna: 'anulado_total$ne,liquidacoes.id$not_null', tipo: 'Boolean' });

    return retorno
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'empenho,empenho.favorecido,empenho.ficha,empenho.subelemento,empenho.orgao,empenho.exercicio';
    this.preliquidacaoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE LIQUIDAÇÕES ORÇAMENTÁRIAS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem liquidações', ['auto', 'auto', '*', 'auto', '*', 'auto', 'auto'], lista.content, ['valor_liquidado']);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public onChangeLiquidacao(value: any, especie: any) {
    this.router.navigate(['pre-liquidacoes-restos']);
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }
}
