// tslint:disable: variable-name
import { Orgao } from '../comum/orgao.model';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { LegislacaoRelacionado } from './legislacao-relacionado.model';
import { LegislacaoHistorico } from './legislacao-historico.model';
import { Rito } from './rito.model';
import { Quorum } from './quorum.model';
import { VotacaoTurno } from './votacao-turno.model';
import { VotacaoResultado } from './votacao-resultado.model';
import { VotacaoTipo } from './votacao-tipo.model';
import { LegislacaoTipo } from './legislacao-tipo.model';
import { Autoria } from './autoria.model';

export class Legislacao extends BaseResourceModel {
  constructor(
    public id?: number,
    public protocolo?: string,
    public processo?: string,
    public estado?: string,
    public lei_resultante?: string,
    public nao_publicar?: boolean,
    public palavra_chave?: string,
    public numero?: number,
    public ano?: number,
    public data_publicacao?: Date,
    public data_vigencia?: Date,
    public data_resultado?: Date,
    public data_adiamento?: Date,
    public data_cadastro?: Date,
    public data_prazo?: Date,
    public data_arquivamento?: Date,
    public armario?: string,
    public prateleira?: string,
    public corredor?: string,
    public descricao?: string,
    public numero_caixa?: string,
    public tipo_anexo?: number,
    public usuario?: Usuario,
    public destino?: Usuario,
    public legislacao_tipo?: LegislacaoTipo,
    public rito?: Rito,
    public quorum?: Quorum,
    public votacao_turno?: VotacaoTurno,
    public votacao_resultado?: VotacaoResultado,
    public votacao_tipo?: VotacaoTipo,
    public orgao?: Orgao,
    public relacionados?: Array<LegislacaoRelacionado>,
    public autorias?: Array<Autoria>,
    public historico?: LegislacaoHistorico,
    public historicos?: Array<LegislacaoHistorico>,
    public selecionado?: boolean
  ) {
    super();
  }
  static converteJson(json: any): Legislacao {
    return Object.assign(new Legislacao(), json);
  }
}
