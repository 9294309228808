import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ImovelOcorrencia } from 'eddydata-lib';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BemImovelOcorrenciaService extends BaseResourceService<ImovelOcorrencia> {

  constructor(
    protected injector: Injector
  ) {
    super(`imovel-ocorrencias`, injector);
  }

  public obterPorImovel(imovel: number): Observable<ImovelOcorrencia[]> {
    return this.http.get<ImovelOcorrencia>(
      `${this.login.cidade.id}/${this.api}/filtrar?orderBy=data_inclusao$DESC&imovel.id=${imovel}&relations=tipo,imovel,usuario`,
      this.httpOptions()).pipe(
        map(res => res['content']),
        catchError(err => this.handleError(err))
      );
  }

}
