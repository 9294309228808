import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EmpenhoService } from 'administrativo-lib';
import { Ajuste, Audesp4Empenho, Empenho, EmpenhoArray, FuncaoService, GlobalService, LicitacaoAudesp, TermoAditivo } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-empenho',
  templateUrl: './licitacao-audesp-empenho.component.html'
})
export class LicitacaoAudespEmpenhoComponent extends BaseLicitacaoAudesp implements OnChanges {

  @Input() public entidade: EmpenhoArray | TermoAditivo | Ajuste;
  @Input() public ajuste: LicitacaoAudesp;
  @Input() public campo: string;
  @Input() public contrato: number;

  public itemAtual: Audesp4Empenho;
  public itemAnterior: Audesp4Empenho;
  public listaEmpenho: Empenho[];

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    public funcaoService: FuncaoService,
    public empenhoService: EmpenhoService,
  ) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.ajuste) this.carregarEmpenhos();
      if (changes['contrato'])
        this.carregarPorContrato(this.contrato)
    }
  }

  public adicionar() {
    if (!this.entidade[this.campo] || this.entidade[this.campo].length === 0) this.entidade[this.campo] = [];

    this.itemAtual = {
      NumNotaEmpenho: undefined, NotaEmpenhoDt: undefined, NotaEmpenhoValor: undefined, FonteRecursos: undefined, editavel: true
    }
    this.entidade[this.campo].push(this.itemAtual);
  }

  public visualizar(item: Audesp4Empenho) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      let itm = this.entidade[this.campo].find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.entidade[this.campo].splice(this.entidade[this.campo].indexOf(this.itemAtual), 1, itm)
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4Empenho) {
    this.itemAtual = undefined;
    this.entidade[this.campo].splice(this.entidade[this.campo].indexOf(item), 1)
  }

  public salvar() {
    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }

  private carregarEmpenhos() {
    if (!this.ajuste) return
    let ajusteObj: Ajuste;
    if (typeof this.ajuste.arquivo === 'string')
      ajusteObj = JSON.parse(this.ajuste.arquivo) as Ajuste;
    else
      ajusteObj = this.ajuste.arquivo as Ajuste;

    if (!ajusteObj) return

    if (this.ajuste.tipo === 'AJUSTE_EMPENHO') {
      if (ajusteObj?.NotaEmpenho?.length > 0 && ajusteObj.NotaEmpenho[0].empenhoId) {
        this.empenhoService.obterId(ajusteObj.NotaEmpenho[0].empenhoId).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            this.listaEmpenho = [res]
            if (this.listaEmpenho?.length > 0)
              this.atribuirEmpenho(this.listaEmpenho[0]);
          })
      }
    } else {
      this.carregarPorContrato(ajusteObj.contratoId)
    }
  }

  public atribuirEmpenho(empenho?: Empenho) {
    if (!this.itemAtual) return
    if (!empenho) {
      empenho = this.listaEmpenho.find(e => e.id === this.itemAtual.empenhoId)
      if (!empenho) return;
    }

    this.itemAtual.NumNotaEmpenho = `${empenho.numero}/${empenho.exercicio.ano}`;
    this.itemAtual.NotaEmpenhoDt = this.funcaoService.converteDataBR(empenho.data_empenho);
    this.itemAtual.NotaEmpenhoValor = empenho.valor_empenho;
    this.itemAtual.FonteRecursos = this.converterFonteRecurso(empenho.ficha.recurso.codigo)
  }

  private converterFonteRecurso(codigo: string): 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 {
    if (!codigo) return 6; // Outras Fontes de Recursos

    if (codigo === '010000000') return 1; // Tesouro
    else if (codigo === '02' || codigo === '02000000') return 2; // Transferências e convênios estaduais-vinculados
    else if (codigo === '03' || codigo === '03000000') return 3; // Recursos Próprios de Fundos Especiais de Despesa-Vinculados
    else if (codigo === '04' || codigo === '04000000') return 4; // Recursos Próprios da Administração Indireta
    else if (codigo === '05' || codigo === '05000000') return 5; // Transferências e convênios Federais-Vinculados
    else if (codigo === '06' || codigo === '06000000') return 6; // Outras Fontes de Recursos
    else if (codigo === '07' || codigo === '07000000') return 7; // Operações de Crédito
    else if (codigo === '08' || codigo === '08000000') return 8; // Emendas Parlamentares Individuais
    else return 6; // Outras Fontes de Recursos
  }

  private carregarPorContrato(contratoId: number) {
    this.login = GlobalService.obterSessaoLogin();
    if (!contratoId || !this.login) return
    this.empenhoService.obterPorContrato(contratoId, this.login.orgao.id, true).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaEmpenho = res.content

        if (this.listaEmpenho?.length === 1)
          this.atribuirEmpenho(this.listaEmpenho[0]);
      });
  }
}
