import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../../assinaturas/assinaturas';
import { AnexoLrfFederalService } from '../../../service/anexo-lrf-federal.service';

@Directive()
export class Anexo6RreoLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoFederalServico: AnexoLrfFederalService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected assinaturaControleInterno: boolean) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
    this.anexoFederalServico.obterAnexo6(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(null, this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            await this.conteudo(dados),
            'portrait', 'ANEXO6 - DEMONSTRATIVO DOS RESULTADOS PRIMÁRIO E NOMINAL',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 2;
                },
                paddingRight() {
                  return 2;
                }
              }
            }, true, false);
        } else {
          this.funcaoService.exportar(formato, this.normalizar(dados), 'ANEXO6 - DEMONSTRATIVO DOS RESULTADOS PRIMÁRIO E NOMINAL', this.colunas());
        }
      });
  }

  private async conteudo(dados: any[]): Promise<{}[]> {
    // monta o cabecalho

    const bimestre = this.mes;

    const registros: {}[] = [
      [
        { text: this.login.orgao.nome, bold: true, border: [false, false, false, false], colSpan: 3, fontSize: 10 },
        '', ''
      ],

      [
        { text: 'RELATÓRIO RESUMIDO DA EXECUÇÃO ORÇAMENTÁRIA', bold: true, border: [false, false, false, false], colSpan: 3 },
        '', ''],
      [
        { text: 'DEMONSTRATIVO DOS RESULTADOS PRIMÁRIO E NOMINAL', bold: true, border: [false, false, false, false], colSpan: 3 },
        '', ''],
      [
        { text: 'ORÇAMENTOS FISCAL E DA SEGURIDADE SOCIAL', bold: true, border: [false, false, false, false], colSpan: 3 },
        '', ''],
      [
        { text: `REFERÊNCIA: ${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano}`, bold: true, border: [false, false, false, false], colSpan: 3 },
        '', ''],
      [
        { text: 'RREO - ANEXO 6 (LRF, art 53, inciso III)', bold: true, border: [false, false, false, false], colSpan: 3 },
        '', ''
      ],
      [
        { text: 'RECEITAS PRIMÁRIAS', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'PREVISÃO ATUALIZADA', alignment: 'center', bold: true, fontSize: 8 },
        { text: `ATÉ O BIMESTRE ${bimestre} RECEITAS REALIZADAS (a)`, alignment: 'center', bold: true, fontSize: 8 }
      ]

    ];

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);

    // monta o agrupamento do relatório
    const titulos = this.funcaoService.agrupar(dados, 'titulo', ['previsto', 'arrecadado']);
    let totalPrevisto = 0;
    let somaPrevisto = 0;
    let totalArrecadado = 0;
    let somaArrecadado = 0;
    let totalIVPrevisto = 0;
    let totalIVArrecadado = 0;
    let totalXIPrevisto = 0;
    let totalXIArrecadado = 0;
    for (const titulo of titulos) {
      registros.push([
        { text: titulo.grupo, fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['previsto']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['arrecadado']), alignment: 'right', fontSize: 8, bold: true }
      ]);
      totalPrevisto += +titulo.totalizadores['previsto'];
      totalArrecadado += +titulo.totalizadores['arrecadado'];

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['previsto', 'arrecadado']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          registros.push([
            { text: grupo.grupo, fontSize: 8, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsto']), alignment: 'right', fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']), alignment: 'right', fontSize: 8 }
          ]);

          for (const item of grupo.registros) {
            if (item.somador) {
              somaPrevisto += +item.previsto;
              somaArrecadado += +item.arrecadado;
            }
            if (item.nome != '') {
              registros.push([
                {
                  text: item.nome, fontSize: 8, margin: [5, 0, 0, 0]
                },
                { text: this.funcaoService.convertToBrNumber(item.previsto), alignment: 'right', fontSize: 8 },
                { text: this.funcaoService.convertToBrNumber(item.arrecadado), alignment: 'right', fontSize: 8 }
              ]);
            }
          }
        }
      }
      if (titulo.grupo === 'RECEITAS CORRENTES (I)') {
        totalIVPrevisto = +totalPrevisto - +somaPrevisto;
        totalIVArrecadado = +totalArrecadado - +somaArrecadado;
        registros.push([
          {
            text: 'RECEITAS PRIMÁRIAS CORRENTES (EXCETO FONTES RPPS) (IV) = [I - (II + III)]', fontSize: 8, bold: true
          },
          { text: this.funcaoService.convertToBrNumber(totalIVPrevisto), alignment: 'right', fontSize: 8, bold: true },
          { text: this.funcaoService.convertToBrNumber(totalIVArrecadado), alignment: 'right', fontSize: 8, bold: true }
        ]);
        totalPrevisto = 0;
        somaPrevisto = 0;
        totalArrecadado = 0;
        somaArrecadado = 0;
      } else if (titulo.grupo === 'RECEITAS DE CAPITAL (EXCETO FONTES RPPS) (VII)') {
        totalXIPrevisto = +totalPrevisto - +somaPrevisto;
        totalXIArrecadado = +totalArrecadado - +somaArrecadado;
        registros.push([
          {
            text: 'RECEITAS PRIMÁRIAS DE CAPITAL (EXCETO FONTES RPPS) (XIII) = [VII - (VIII + IX + X + XI + XII)]', fontSize: 8, bold: true
          },
          { text: this.funcaoService.convertToBrNumber(totalXIPrevisto), alignment: 'right', fontSize: 8, bold: true },
          { text: this.funcaoService.convertToBrNumber(totalXIArrecadado), alignment: 'right', fontSize: 8, bold: true }
        ]);
      }

    }

    registros.push([
      {
        text: 'RECEITA PRIMÁRIA TOTAL (XVI) = (IV + V + XIII + XIV)', fontSize: 8, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(+totalIVPrevisto + +totalXIPrevisto), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+totalIVArrecadado + +totalXIArrecadado), alignment: 'right', fontSize: 8, bold: true }
    ]);

    registros.push([
      {
        text: 'RECEITA PRIMÁRIA TOTAL (EXCETO FONTES RPPS) (XVII) = (IV + XIII)', fontSize: 8, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(+totalIVPrevisto + +totalXIPrevisto), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+totalIVArrecadado + +totalXIArrecadado), alignment: 'right', fontSize: 8, bold: true }
    ]);

    return [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 100, 100],
          body: registros
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*'],
          body: assinaturas
        }
      }
    ];
  }

  public normalizar(lista: any[]) {
    const listaExportar = []

    const titulos = this.funcaoService.agrupar(lista, 'titulo', ['previsto', 'arrecadado']);
    let totalPrevisto = 0;
    let somaPrevisto = 0;
    let totalArrecadado = 0;
    let somaArrecadado = 0;
    let totalIVPrevisto = 0;
    let totalIVArrecadado = 0;
    let totalXIPrevisto = 0;
    let totalXIArrecadado = 0;

    for (const titulo of titulos) {
      const linha_titulo = {
        a: titulo.grupo,
        b: this.funcaoService.convertToBrNumber(titulo.totalizadores['previsto']),
        c: this.funcaoService.convertToBrNumber(titulo.totalizadores['arrecadado'])
      }
      listaExportar.push(linha_titulo)

      totalPrevisto += +titulo.totalizadores['previsto'];
      totalArrecadado += +titulo.totalizadores['arrecadado'];

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['previsto', 'arrecadado']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          const linha_grupo = {
            a: grupo.grupo,
            b: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsto']),
            c: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado'])
          }
          listaExportar.push(linha_grupo)
        }

        for (const item of grupo.registros) {
          if (item.somador) {
            somaPrevisto += +item.previsto;
            somaArrecadado += +item.arrecadado;
          }
          if (item.nome != '') {
            const linha_item = {
              a: item.nome,
              b: this.funcaoService.convertToBrNumber(item.previsto),
              c: this.funcaoService.convertToBrNumber(item.arrecadado)
            }
            listaExportar.push(linha_item)
          }
        }
      }

      if (titulo.grupo === 'RECEITAS CORRENTES (I)') {
        totalIVPrevisto = +totalPrevisto - +somaPrevisto;
        totalIVArrecadado = +totalArrecadado - +somaArrecadado;
        const linha_receita_corrente = {
          a: 'RECEITAS PRIMÁRIAS CORRENTES (IV) = (I - II - III)',
          b: this.funcaoService.convertToBrNumber(totalIVPrevisto),
          c: this.funcaoService.convertToBrNumber(totalIVArrecadado)
        }
        listaExportar.push(linha_receita_corrente)
        totalPrevisto = 0;
        somaPrevisto = 0;
        totalArrecadado = 0;
        somaArrecadado = 0;
      } else {
        totalXIPrevisto = +totalPrevisto - +somaPrevisto;
        totalXIArrecadado = +totalArrecadado - +somaArrecadado;
        const linha_receita_capital = {
          a: 'RECEITAS PRIMÁRIAS DE CAPITAL (XI) = (V - VI - VII - VIII - IX - X)',
          b: this.funcaoService.convertToBrNumber(totalXIPrevisto),
          c: this.funcaoService.convertToBrNumber(totalXIArrecadado)
        }
        listaExportar.push(linha_receita_capital)
      }
    }

    const linha_receita_total = {
      a: 'RECEITA PRIMÁRIA TOTAL (XII) = (IV + XI)',
      b: this.funcaoService.convertToBrNumber(+totalIVPrevisto + +totalXIPrevisto),
      c: this.funcaoService.convertToBrNumber(+totalIVArrecadado + +totalXIArrecadado)
    }
    listaExportar.push(linha_receita_total)

    return listaExportar
  }

  colunas(): Coluna[] {
    const bimestre = this.mes;

    const colunasDefault: Coluna[] = [
      { titulo: `RECEITAS PRIMÁRIAS`, coluna: 'a' },
      { titulo: `PREVISÃO ATUALIZADA`, coluna: 'b' },
      { titulo: `ATÉ O BIMESTRE ${bimestre} RECEITAS REALIZADAS (a)`, coluna: 'c' },
    ];
    return colunasDefault;
  }

}
