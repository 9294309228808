import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from './usuario.model';

export class Auditoria extends BaseResourceModel {

  constructor(
    public alvo?: string,
    public alvo_id?: number,
    public operacao?: 'I' | 'A' | 'R',
    public antes?: string,
    public depois?:string,
    public usuario?: Usuario,
    public data_alteracao?: Date,
    public data_cadastro?: Date
  ) {
    super();
  }

  static converteJson(json: any): Auditoria {
    console.log(json);
    return Object.assign(new Auditoria(), json);
  }
}
