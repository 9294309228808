import { Component } from "@angular/core";

declare var $: any;

@Component({
    selector: 'app-lgpd',
    templateUrl: './lgpd.component.html',
    styleUrls: ['./lgpd.component.css']
})
export class LGPDComponent {

    aceitar() {
        $('#cookie-banner-lgpd').hide();
    }
}