import { Component } from '@angular/core';
import { FavorecidoProdutoService } from 'administrativo-lib';
import {
  BaseResourceItemsComponent, EddyAutoComplete, Favorecido, FavorecidoProduto, FavorecidoService, FuncaoService, GlobalService
} from 'eddydata-lib';
import * as toastr from 'toastr';


declare var $: any;

@Component({
  selector: 'lib-produto-favorecido',
  templateUrl: './produto-favorecido.component.html'
})
export class ProdutoFavorecidoComponent extends BaseResourceItemsComponent<FavorecidoProduto> {

  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;

  constructor(
    public funcaoService: FuncaoService,
    public globalService: GlobalService,
    private favorecidoService: FavorecidoService,
    favorecidoTerceiroSetorService: FavorecidoProdutoService) {
    super(new FavorecidoProduto(), favorecidoTerceiroSetorService);
  }

  protected afterInit(): void {
    this.carregarAutoComplete();
  }

  protected validSave(item: FavorecidoProduto): boolean {
    if (!item.favorecido) {
      toastr.warning('Informe o favorecido do catalogo!');
      return false;
    }
    return true;
  }

  protected beforeSave(item: FavorecidoProduto): void {
  }


  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public carregarAutoComplete() {
    // autocomplete para favorecido
    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['cpf_cnpj', 'nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );
  }
}
