import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ContratoReajuste } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ContratoReajusteService extends BaseResourceService<ContratoReajuste> {

  constructor(
    protected injector: Injector
  ) {
    super(`contrato-reajustes`, injector);
  }
}
