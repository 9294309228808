// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Contrato } from '../compra/contrato.model';
import { Favorecido } from '../compra/favorecido.model';
import { Orgao } from '../comum/orgao.model';
import { DocumentoFiscalInfo } from './documento-fiscal-info.model';
import { DocumentoFiscalProcesso } from './documento-fiscal-processo.model';

export class DocumentoFiscalServicos extends BaseResourceModel {
  constructor(
    public id?: number,
    public serie?: string,
    public especie?: string,
    public numero_documento?: string,
    public data_emissao?: Date,
    public observacao?: string,
    public orgao?: Orgao,
    public favorecido?: Favorecido,
    public contrato?: Contrato,
    /**
    * N - Não Enviado
    * E - Enviado
    * R - Para retificação
    * X - Para excluir
    * A - Aguardando Validação
    * I - Não enviar
    */
    public situacao?: 'N' | 'E' | 'R' | 'X' | 'A' | 'I',
    public informacoes?: DocumentoFiscalInfo[],
    public processos?: DocumentoFiscalProcesso[]
  ) {
    super();
  }

  static converteJson(json: any): DocumentoFiscalServicos {
    return Object.assign(new DocumentoFiscalServicos(), json);
  }
}
