import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MemorialService, PropostaService } from 'administrativo-lib';
import { FichaDespesaService } from 'contabil-lib';
import { DropdownValue, EddyAutoComplete, FichaDespesa, FuncaoService, GlobalService, Licitacao, Login, Memorial, Rpl, RplItem, RplItemFichaDespesa } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import * as toastr from 'toastr';
import { RplItemFichaDespesaService } from '../rpl/service/rpl-item-ficha-despesa.service';
import { takeUntil } from 'rxjs/operators';

declare var $: any;

interface ListaReservaMemorial {
  item: string;
  registros: {
    editando: boolean,
    ficha_despesa: FichaDespesa,
    memorial: Memorial,
    valor_reservado: number,
    quantidade: number
  }[];
  memorial?: Memorial;
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'lib-licitacao-pendencia-reserva',
  templateUrl: './licitacao-pendencia-reserva.component.html',
  styleUrls: ['./licitacao-pendencia-reserva.component.css']
})
export class LicitacaoPendenciaReservaComponent implements OnInit, OnDestroy, OnChanges {

  public lista: { ficha_despesa: FichaDespesa, valorReservado: number, rpl: string, tipo_reserva: 'TOTAL' | 'PARCIAL' | 'NENHUMA', mes: string, valor_reservado: number, data: Date }[] = [];
  public listaItens: { ficha_despesa: FichaDespesa, valorReservado: number, memorial: Memorial, quantidade: number }[] = [];
  public listaAgrupada: ListaReservaMemorial[] = [];
  public tipoReserva: { id: string, nome: string }[] = [];
  public tipoReservaSelecionado: boolean = false;
  public fichaAutoComplete: EddyAutoComplete<FichaDespesa>;
  public editando = false;
  public rplSelecionda: Rpl = null;
  public dropdownRpl: DropdownValue<Rpl>[] = [];

  public imaskMes = {
    mask: /^[1-9]$|^1[0-2]$/,
    lazy: false
  }

  @Input() edicao: boolean = true; // variavel para definir se opções de edições estarão disponiveis
  @Input() credenciamento: false;
  @Input() entidade: Licitacao;
  @Input() login: Login;
  @Input() dataAtualizacao: Date;
  @Input() chamarPendencia: boolean = false;
  @Input() listaRpls: Rpl[];

  @Output() recarregarPagina: EventEmitter<void> = new EventEmitter();

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected confirmationService: ConfirmationService,
    public funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected memorialService: MemorialService,
    protected propostaService: PropostaService,
    protected fichaService: FichaDespesaService,
    protected rplFichaDespesaService: RplItemFichaDespesaService
  ) { }

  ngOnInit() {
    this.tipoReserva = [
      { id: 'NENHUMA', nome: 'Sem Reserva' },
      { id: 'TOTAL', nome: 'Reserva Total' },
      { id: 'PARCIAL', nome: 'Reserva Parcial' },
    ]
    const parametros = {
      exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id,
      relations: 'despesa,recurso,aplicacao,aplicacao_variavel,exercicio,orgao',
      orderBy: 'despesa.nome'
    };

    if (this.entidade?.setor?.unidade) {
      parametros['executora.unidade.id'] = this.entidade?.setor?.unidade?.id
    };
    // autocomplete para ficha despesa
    this.fichaAutoComplete = new EddyAutoComplete(null, this.fichaService,
      'numero', ['ficha.numero', 'ficha.despesa.nome'],
      parametros,
      { number: ['numero'], text: ['despesa.codigo', 'despesa.nome', 'numero_nome'] }, null, 20);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chamarPendencia']) {
      this.buscarFichaDespesa();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public async buscarFichaDespesa() {
    const itens = await this.memorialService.filtrar(1, -1, {
      'relations': 'licitacao,produto_unidade.produto.material,produto_unidade.unidade,rpl_itens.produto_unidade,rpl_itens.lista_ficha_despesa.ficha_despesa.despesa,rpl_itens.lista_ficha_despesa.ficha_despesa.orgao,rpl_itens.lista_ficha_despesa.ficha_despesa.exercicio,rpl_itens.cotacoes,rpl_itens.rpl.exercicio', licitacao_id: this.entidade?.id, 'orderBy': 'ordem,id,itens.produto.nome,propostas.proponente.ordem'
    }).toPromise();
    this.lista = [];
    if (itens.content?.length && this.entidade?.itens && this.entidade?.id) {
      for (const memorial of itens.content as Memorial[]) {
        for (const item of memorial?.rpl_itens as RplItem[]) {
          if (item.lista_ficha_despesa && item.lista_ficha_despesa?.length && item.cotacoes && item.cotacoes?.length) {
            const totalCotacoesItem = item.cotacoes.reduce((prev, cur) => prev + +cur.valor_unitario, 0)
            const valMedioCotacao = totalCotacoesItem / item.cotacoes.length
            for (const fichaItem of item.lista_ficha_despesa) {
              const valorReservaItem = +fichaItem.quantidade * +valMedioCotacao
              fichaItem.data_reserva = new Date(fichaItem.data_reserva ?? new Date(Date.now())) ?? new Date(Date.now());
              fichaItem.tipo_reserva = fichaItem.tipo_reserva ?? 'NENHUMA';
              if (this.lista.length) {
                let f = this.lista.find(e => e.ficha_despesa.id === fichaItem.ficha_despesa.id);
                if (!f) {
                  this.lista.push({ ficha_despesa: fichaItem.ficha_despesa, valorReservado: +valorReservaItem, rpl: `${item?.rpl?.numero}/${item.rpl?.exercicio?.ano}`, tipo_reserva: fichaItem.tipo_reserva, mes: String(fichaItem.mes_reservado), valor_reservado: +fichaItem.valor_reservado, data: fichaItem.data_reserva })
                } else {
                  this.lista.map((m) => {
                    if (m.ficha_despesa.id === fichaItem.ficha_despesa.id) {
                      m.valorReservado += +valorReservaItem;
                      m.rpl = m.rpl.length ? `${m.rpl}, ${item.rpl.numero}/${item.rpl?.exercicio?.ano}` : `${item.rpl.numero}/${item.rpl?.exercicio?.ano}`
                      const elementos = m.rpl.split(', ');
                      const elementosUnicos = new Set();
                      const elementosNaoRepetidos = [];
                      elementos.forEach(elemento => {
                        if (!elementosUnicos.has(elemento)) {
                          elementosNaoRepetidos.push(elemento);
                          elementosUnicos.add(elemento);
                        }
                      }); m.rpl = elementosNaoRepetidos.join(', ');
                    }
                  })
                }
              } else {
                this.lista.push({ ficha_despesa: fichaItem.ficha_despesa, valorReservado: +valorReservaItem, rpl: `${item.rpl.numero}/${item.rpl?.exercicio?.ano}`, tipo_reserva: fichaItem.tipo_reserva, mes: String(fichaItem.mes_reservado), valor_reservado: +fichaItem.valor_reservado, data: fichaItem.data_reserva })
              }
              if (!this.tipoReservaSelecionado) {
                this.tipoReservaSelecionado = fichaItem.tipo_reserva === 'PARCIAL' ? true : false;
              }
            }
          }
        }
      }

      for (const memorial of itens.content as Memorial[]) {
        for (const item of memorial?.rpl_itens as RplItem[]) {
          if (item.lista_ficha_despesa && item.lista_ficha_despesa?.length && item.cotacoes && item.cotacoes?.length) {
            const totalCotacoesItem = item.cotacoes.reduce((prev, cur) => prev + +cur.valor_unitario, 0)
            const valMedioCotacao = totalCotacoesItem / item.cotacoes.length;
            for (const fichaItem of item.lista_ficha_despesa) {
              const valorReservaItem = fichaItem.quantidade * valMedioCotacao
              if (this.listaItens.length) {
                let f = this.listaItens.find(e => e.ficha_despesa.id === fichaItem.ficha_despesa.id && e.memorial.id === memorial?.id);
                if (!f) {
                  this.listaItens.push({ ficha_despesa: fichaItem.ficha_despesa, valorReservado: +valorReservaItem, memorial: memorial, quantidade: fichaItem.quantidade })
                } else {
                  this.listaItens.map((m) => {
                    if (m.ficha_despesa.id === fichaItem.ficha_despesa.id && m.memorial.id === item?.id) {
                      m.valorReservado += valorReservaItem;
                    }
                  })
                }
              } else {
                this.listaItens.push({ ficha_despesa: fichaItem.ficha_despesa, valorReservado: +valorReservaItem, memorial: memorial, quantidade: fichaItem.quantidade })
              }
            }
          }
        }
      }
      this.listaAgrupada = [];
      this.listaItens.sort((a, b) => {
        if (+a.memorial.ordem > +b.memorial.ordem) return 1;
        if (+a.memorial.ordem < +b.memorial.ordem) return -1;
        return 0;
      });
      const agrupamento = this.funcaoService.agrupar(this.listaItens, ['memorial.ordem', 'memorial.descricao', 'memorial.unidade']);
      for (const lista of agrupamento) {
        let item: ListaReservaMemorial = {} as ListaReservaMemorial;
        item.item = `${lista.grupo['memorial.ordem']} - ${lista.grupo['memorial.descricao']} (${lista.grupo['memorial.unidade']})`;
        item.registros = lista.registros;
        item.registros.forEach(i => {
          i['editando'] = false;
        });
        item.memorial = lista.registros[0]?.memorial;
        this.listaAgrupada.push(item);
      }
    }
  }

  public async verificarTipoReserva(item) {
    if (this.lista.find(l => l.tipo_reserva === 'PARCIAL')) {
      // if (item.tipo_reserva === 'PARCIAL') {
      //   if (!item.mes) {
      //     item.mes = '12';
      //   }
      //   item.valor_reservado = (+item.valorReservado / 12) * +item.mes;
      //   await this.validarSaldoFicha(item);
      // } else
      if (item.tipo_reserva === 'TOTAL') {
        item.valor_reservado = +item.valorReservado;
      }
      if (item.tipo_reserva === 'NENHUMA') {
        item.valor_reservado = 0;
        item.mes = 0;
      }
      this.tipoReservaSelecionado = true;
    } else {
      if (!item.valor_reservado && item.tipo_reserva === 'TOTAL') {
        item.valor_reservado = +item.valorReservado;
      }
      if (item.tipo_reserva === 'NENHUMA') {
        item.valor_reservado = 0;
        item.mes = 0;
      }
      this.tipoReservaSelecionado = false;
    }
    await this.validarSaldoFicha(item);
  }

  public async calculoMes(item) {
    item.valor_reservado = (+item.valorReservado / 12) * +item.mes;
    await this.validarSaldoFicha(item)
  }

  public async validarSaldoFicha(item) {
    const saldoFicha = await this.fichaService.saldoAtualFicha(item?.ficha_despesa?.id, this.login?.orgao?.id, this.login?.exercicio?.id, String(
      this.funcaoService.converteDataSQL(item.data)
    )).toPromise();
    if ((saldoFicha.saldo_atual - item.valor_reservado) < 0) {
      item.mes = 0;
      item.valor_reservado = 0;
      item.tipo_reserva = 'NENHUMA'
      toastr.error(`Valor reservado ultrapassa o saldo atual de dotação de ${this.funcaoService.convertToBrNumber(saldoFicha.saldo_atual)} para ficha de '${saldoFicha.nome_ficha}'`);
      return
    }
  }

  public alterarFicha(item) {
    item['alterar_ficha'] = true;
    item['ficha'] = item?.ficha_despesa;
  }

  public async salvarTrocaFicha(item) {
    if (item) {
      if (!item.ficha) {
        toastr.error('Informe uma ficha de despesa!');
        return;
      } else {
        item.alterar_ficha = false;

        const elementos = item.rpl.split(', ');
        const elementosUnicos = new Set();
        const elementosNaoRepetidos = [];
        elementos.forEach(elemento => {
          if (!elementosUnicos.has(elemento)) {
            elementosNaoRepetidos.push(elemento);
            elementosUnicos.add(elemento);
          }
        });
        const element = elementosNaoRepetidos.map(str => str.split('/'));
        for (let i = 0; i <= element?.length; i++) {
          this.rplFichaDespesaService.atualizarFichaDespesa(+element[i][0], this.login?.orgao?.id, +element[i][1], +item?.ficha?.id, item?.ficha_despesa?.id).pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
            }, error => {
              toastr.error(error.error.payload);
              throw error;
            });
        }
        item.ficha_despesa = item.ficha;
        toastr.success('Ficha atualizada com sucesso!');
        this.buscarFichaDespesa()
      }
    }
  }

  public async gerarPendenciaReserva(lista: []) {
    if (lista?.length) {
      await this.verificaReservas(lista);
      this.rplFichaDespesaService.gerarPendenciaReserva(lista, this.login?.orgao?.id, this.login?.exercicio?.id, this.entidade?.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          toastr.success('Pendência de reserva salvo com sucesso')
        }, error => {
          toastr.error(error.error.payload);
          throw error;
        });


    } else {
      toastr.warning('Não há pendências de reserva!');
      return;
    }
  }

  public async verificaReservas(lista: []) {
    const aoMenosUmaReserva = Object.values(
      this.lista
    ).some((item) => this.obterRespostaTipo(item.tipo_reserva));

    const reservaParcial = Object.values(
      this.lista
    ).some((item) => this.obterRespostaParcial(item.tipo_reserva, item.mes));
    if (!aoMenosUmaReserva) {
      toastr.error("Selecione ao menos uma (1) ficha");
      throw new Error("Selecione ao menos uma (1) ficha");
    }

    if (reservaParcial) {
      toastr.error("Informe quantidade de mês(es) para reservar na ficha");
      throw new Error("Informe quantidade de mês(es) para reservar na ficha");
    }
  }

  private obterRespostaTipo = (resposta: string) => resposta === 'TOTAL' || resposta === 'PARCIAL';

  private obterRespostaParcial = (tipo_reserva: string, mes: string) => tipo_reserva === 'PARCIAL' && mes == null;

  public adicionarFicha(item: ListaReservaMemorial): void {
    this.dropdownRpl = this.listaRpls.map<DropdownValue<Rpl>>(rpl => ({ label: `${rpl.numero} - ${rpl.exercicio?.ano}`, value: rpl }));
    this.editando = true;
    item.registros.push({
      editando: true,
      ficha_despesa: null,
      memorial: item.memorial,
      valor_reservado: 0,
      quantidade: 0
    });
  }

  public cancelarFicha(item: ListaReservaMemorial, index: number): void {
    if (!this.editando) return;
    // this.itemAtual = Object.assign({}, this.itemAnterior);
    console.log(item, index)
    item.registros.splice(index, 1);
    item.registros.splice(index, 1);
    this.editando = false;
  }

  public salvarItemFicha(item: {
    editando: boolean;
    ficha_despesa: FichaDespesa;
    memorial: Memorial;
    valor_reservado: number;
    quantidade: number;
  }): void {

    try {
      if (!item.ficha_despesa) {
        throw new Error('Selecione a ficha de despesa');
      } else if (!this.rplSelecionda) {
        throw new Error('Selecione a RPL');
      } else if (!item.memorial.rpl_itens.find(rplI => rplI.rpl.id === this.rplSelecionda.id)) {
        throw new Error('Item não vinculado à RPL');
      }

      const rplItem = item.memorial.rpl_itens.find(rplI => rplI.rpl.id === this.rplSelecionda.id);
      const somaQtde = rplItem.lista_ficha_despesa.reduce((acc, val) => acc + +val.quantidade, 0);

      if (somaQtde >= +rplItem.quantidade) {
        throw new Error('RPL sem saldo');
      } else if (item.quantidade > (+rplItem.quantidade - somaQtde)) {
        throw new Error(`Saldo da RPL: ${(+rplItem.quantidade - somaQtde)}`);
      }

      const rplItemFIchaDespesa: RplItemFichaDespesa = {
        editavel: false,
        quantidade: item.quantidade,
        ordem: rplItem.lista_ficha_despesa.length + 1,
        rpl_item: rplItem,
        ficha_despesa: item.ficha_despesa,
      }

      this.rplFichaDespesaService.atualizar(rplItemFIchaDespesa)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.editando = false;
          item.editando = false;
          this.rplSelecionda = null;

          toastr.success('Ficha cadastrada com sucesso');

          this.buscarFichaDespesa();

          this.recarregarPagina.emit();
        });

    } catch (err) {
      toastr.warning(err.message);
      console.error(err.message);
      throw err;
    }
  }
}
