import { Component, Injector, OnInit } from '@angular/core';
import {
  BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, LoginContabil, TransferenciaAutorizacao
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TransferenciaAutorizacaoService } from '../service/transferencia-autorizacao.service';
import * as toastr from "toastr";

@Component({
  selector: 'app-transferencia-autorizacao-list',
  templateUrl: './transferencia-autorizacao-list.component.html'
})
export class TransferenciaAutorizacaoListComponent extends BaseResourceListComponent<TransferenciaAutorizacao, LoginContabil> implements OnInit {

  /**
   * Declaração de variáveis
   */
  public tipoArquivo = 'FEBRABAN';

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private transferenciaService: TransferenciaAutorizacaoService) {
    super(transferenciaService, injector);
    this.usarExtendido = true;
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(item: TransferenciaAutorizacao): boolean {
    const sistemasAdmin = this.login.usuario.sistemas_administrador.split(',')

    // Se for admin e estiver dentro dos prazos, pode editar
    if (sistemasAdmin.includes('contabil') && this.podeAlterarAudesp(item.data_transfere)) return true
    // Se não for admin e não tiver dentro do prazo, não pode editar
    else if (!this.podeAlterarAudesp(item.data_transfere)) return false
    // Se não for admim, estiver dentro do prazo, mas já foi exportado ou baixado, não pode editar
    if (item.exportado_febraban || item.exportado_obn) return false
    // Se não é admin, está no prazo e não foi exportado ou baixado, pode editar
    else return true
  }
  
  protected relations(): string {
    return 'conta_pagadora,conta_pagadora.banco,conta_pagadora.orgao,conta_recebedora,conta_recebedora.banco,conta_recebedora.orgao,exercicio,orgao';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id,
      ['exercicio.id']: this.login.exercicio.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['data_transfere$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      date: ['data_transfere'],
      number: ['valor_transferido', 'id'],
      text: ['conta_pagadora.nome', 'conta_recebedora.nome', 'conta_pagadora.numero_conta', 'conta_recebedora.numero_conta', 'conta_pagadora.banco.nome'],
    };
  }

  protected afterInit(): void {
    // Depois de iniciar
    this.usarFiltroPersonalizado = true;
  }

  protected acaoRemover(model: TransferenciaAutorizacao): Observable<TransferenciaAutorizacao> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Data', coluna: 'data_transfere', agrupar: true },
      { titulo: 'Conta Pagadora', coluna: 'conta_pagadora.nome' },
      { titulo: 'Número conta pagadora', coluna: 'conta_pagadora.numero_conta' },
      { titulo: 'Conta Recebedora', coluna: 'conta_recebedora.nome' },
      { titulo: 'Número conta recebedora', coluna: 'conta_recebedora.numero_conta' },
      { titulo: 'Valor', coluna: 'valor_transferido', decimais: 2, alignment: 'right' }
    ];
  }

  public obterColunasfiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];

    retorno.push({ titulo: 'Número', coluna: 'id', tipo: 'Number', padrao: true });
    retorno.push({ titulo: 'Data', coluna: 'data_transfere', tipo: 'Date', padrao: true, filtro2: new Date() });
    retorno.push({ titulo: 'Código Pagador', coluna: 'conta_pagadora.numero_conta', tipo: 'String', padrao: true, cols: 3 });
    retorno.push({ titulo: 'Descrição Pagador', coluna: 'descricao_pagadora', tipo: 'String' });
    retorno.push({ titulo: 'Código Recebedor', coluna: 'conta_recebedora.numero_conta', tipo: 'String', padrao: true, cols: 3 });
    retorno.push({ titulo: 'Descrição Recebedor', coluna: 'descricao_recebedora', tipo: 'String' });
    retorno.push({ titulo: 'Valor', coluna: 'valor_transferido', tipo: 'Number', padrao: true });

    return retorno;
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'conta_pagadora,conta_recebedora,exercicio,orgao';
    this.transferenciaService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE TRANSFERÊNCIAS BANCÁRIAS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem transferencia', ['*', 'auto', '*', 'auto', 'auto'], lista.content, ['valor_transferido']);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public voltar() {
    if (this.login.sistema === 'contabil') {
      this.router.navigate(['/tesouraria']);
    } else {
      this.sair();
    }
  }

  public baixar(item: TransferenciaAutorizacao) {
    this.transferenciaService.baixarAutorizacao(item)
      .subscribe((data) => {
        toastr.success(`Restituição baixada com sucesso`);
        this.preencherGrid();
      }, error => { toastr.error(error.error.payload); });
  }

}
