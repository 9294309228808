import { Directive, OnDestroy } from '@angular/core';
import { FuncaoService, GlobalService, LoginContabil, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { LdoService } from '../../../../planejamento/ldo/service/ldo.service';
import { SelectItem } from 'primeng/api/selectitem';

@Directive()
export class Demo4Ldo implements OnDestroy {
  public ano1: number;
  public ano2: number;
  public ano3: number;
  private login: LoginContabil = new LoginContabil();
  private dados: any;
  protected unsubscribe: Subject<void> = new Subject();
  protected funcaoService: FuncaoService;
  listaOrgaos: SelectItem[];

  constructor(
    protected ano: number,
    protected ldoServico: LdoService,
    protected orgaos: number[] = [],
    dados?: any,
    listaOrgaos?: SelectItem[]) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
    if (dados) this.dados = dados;
    if (listaOrgaos) this.listaOrgaos = listaOrgaos;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async montarRelatorio() {
    if (!this.dados) {
      this.dados = await this.ldoServico.filtrar({
        tipo$in: 'D40,D41',
        'ppa.id': this.login.ppa.id,
        'ano': this.ano,
        'orgao.id$in': this.orgaos,
        orderBy: 'tipo,linha',
      }).toPromise();

      if (this.dados.content.length) this.dados = this.dados.content;
    }

    this.ano1 = +this.ano - 2;
    this.ano2 = +this.ano - 3;
    this.ano3 = +this.ano - 4;

    const lista40 = [];
    const lista41 = [];
    const lista = [];

    if (this.dados) {
      for (const item of this.dados) {
        item.tipo === 'D40' ? lista40.push(item) : lista41.push(item);
      }

      if (lista40.length > 0 && lista41.length > 0) {
        lista.push(this.conteudo40(lista40).concat(this.conteudo41(lista41)))
      } else if (lista40.length > 0 && lista41.length == 0) {
        lista.push(this.conteudo40(lista40))
      } else if (lista40.length == 0 && lista41.length > 0) {
        lista.push(this.conteudo41(lista41))
      }
    }

    Relatorio.imprimirPersonalizado(
      'DEMONSTRATIVO IV - EVOLUÇÃO DO PATRIMÔNIO LÍQUIDO',
      this.login.usuario.nome, this.login.usuario.sobrenome,
      this.login.orgao.nome, this.login.brasao, lista,
      'landscape', 'DEMONSTRATIVO IV - LDO',
      {
        linhas: {
          hLineWidth() {
            return 1;
          },
          vLineWidth() {
            return 1;
          },
          hLineColor() {
            return 'black';
          },
          paddingLeft() {
            return 3;
          },
          paddingRight() {
            return 3;
          }
        }
      });
  }

  private conteudo40(dados1: any[]): {}[] {
    if (dados1.length === 0) return;

    let listaAgrupada = this.funcaoService.agrupar(dados1, ['descricao1'],
      ['valor1', 'valor2', 'valor3', 'valor4', 'valor5', 'valor6'])

    let lista1 = this.soma(listaAgrupada);

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `PPA: ${this.login.ppa.ppaperiodo.ano1} - ${this.login.ppa.ppaperiodo.ano4}      EXERCÍCIO: ${this.ano}      Lei nº 4.320/64, Art. 2º, §1º, II`,
        fontSize: 11, alignment: 'center', colSpan: 7,
        bold: true, border: [false, false, false, false]
      }, '', '', '', '', '', ''],
      [{
        text: 'PATRIMÔNIO LÍQUIDO',
        alignment: 'center', fillColor: '#f5f5f5',
        bold: true, fontSize: 8,
      }, {
        text: `${this.ano1}`,
        alignment: 'center', fillColor: '#f5f5f5',
        bold: true, fontSize: 8,
      }, {
        text: '%',
        alignment: 'center', fillColor: '#f5f5f5',
        bold: true, fontSize: 8
      }, {
        text: `${this.ano2}`,
        alignment: 'center', fillColor: '#f5f5f5',
        bold: true, fontSize: 8
      }, {
        text: '%',
        alignment: 'center', fillColor: '#f5f5f5',
        bold: true, fontSize: 8
      }, {
        text: `${this.ano3}`,
        alignment: 'center', fillColor: '#f5f5f5',
        bold: true, fontSize: 8
      }, {
        text: '%',
        alignment: 'center', fillColor: '#f5f5f5',
        bold: true, fontSize: 8
      }]
    ];

    // monta relatório
    for (const item of lista1) {
      registros.push([
        { text: item.registros[0].descricao1, fontSize: 8, border: [true, false, false, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor1']), alignment: 'right',
          fontSize: 8, border: [true, false, false, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor2']), alignment: 'right',
          fontSize: 8, border: [true, false, false, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor3']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor4']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor5']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor6']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        }
      ]);
    }

    registros.push([
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] }
    ]);


    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 80, 55, 80, 55, 80, 55],
        body: registros
      }
    }];
  }

  private conteudo41(dados1: any[]): {}[] {
    if (dados1.length === 0) return;

    let listaAgrupada = this.funcaoService.agrupar(dados1, ['descricao1'],
      ['valor1', 'valor2', 'valor3', 'valor4', 'valor5', 'valor6'])

    listaAgrupada.shift()

    let lista1 = this.soma(listaAgrupada);

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: 'REGIME PREVIDENCIÁRIO', alignment: 'center', colSpan: 7,
        border: [true, true, true, false], fillColor: '#f5f5f5',
      }, '', '', '', '', '', ''],
      [{
        text: 'PATRIMÔNIO LÍQUIDO',
        alignment: 'center', fillColor: '#f5f5f5',
        bold: true, fontSize: 8,
      }, {
        text: `${this.ano1}`,
        alignment: 'center', fillColor: '#f5f5f5',
        bold: true, fontSize: 8,
      }, {
        text: '%',
        alignment: 'center', fillColor: '#f5f5f5',
        bold: true, fontSize: 8
      }, {
        text: `${this.ano2}`,
        alignment: 'center', fillColor: '#f5f5f5',
        bold: true, fontSize: 8
      }, {
        text: '%',
        alignment: 'center', fillColor: '#f5f5f5',
        bold: true, fontSize: 8
      }, {
        text: `${this.ano3}`,
        alignment: 'center', fillColor: '#f5f5f5',
        bold: true, fontSize: 8
      }, {
        text: '%',
        alignment: 'center', fillColor: '#f5f5f5',
        bold: true, fontSize: 8
      }]
    ];

    // monta relatório
    for (const item of lista1) {
      registros.push([
        { text: item.registros[0].descricao1, fontSize: 8, border: [true, false, false, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor1']), alignment: 'right',
          fontSize: 8, border: [true, false, false, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor2']), alignment: 'right',
          fontSize: 8, border: [true, false, false, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor3']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor4']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor5']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor6']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        }
      ]);
    }

    registros.push([
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] }
    ]);

    if (this.listaOrgaos?.length > 0) {
      registros.push([{ text: '\n\n', colSpan: 7, border: [false, false, false, false] }, '', '', '', '', '', '']);
      registros.push([{ text: this.listaOrgaos.filter(o => this.orgaos.some(org => org === o.value)).map(o => o.label).join('\n'), fontSize: 8, colSpan: 7, border: [false, false, false, false] }, '', '', '', '', '', '']);
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 80, 55, 80, 55, 80, 55],
        body: registros
      }
    }];
  }

  private soma(lista) {
    //calcular as porcentagens 
    // (campo ÷ total) ×100

    for (const it of lista) {
      if (it.registros[0].linha < 3) {
        it.totalizadores['valor2'] = lista[3].valor1 != 0 ? this.funcaoService.arredondar((+it.totalizadores['valor1'] / lista[3].totalizadores['valor1']) * 100, 2) : 0;
        it.totalizadores['valor4'] = lista[3].valor3 != 0 ? this.funcaoService.arredondar((+it.totalizadores['valor3'] / lista[3].totalizadores['valor3']) * 100, 2) : 0;
        it.totalizadores['valor6'] = lista[3].valor5 != 0 ? this.funcaoService.arredondar((+it.totalizadores['valor5'] / lista[3].totalizadores['valor5']) * 100, 2) : 0;
      }

      if (it.registros[0].linha == 3) {
        it.totalizadores['valor2'] = this.funcaoService.arredondar(100, 2);
        it.totalizadores['valor4'] = this.funcaoService.arredondar(100, 2);
        it.totalizadores['valor6'] = this.funcaoService.arredondar(100, 2);
      }

      if (it.registros[0].linha > 4 && it.registros[0].linha < 8) {
        it.totalizadores['valor2'] = lista[3].valor1 != 0 ? this.funcaoService.arredondar((+it.totalizadores['valor1'] / lista[3].totalizadores['valor1']) * 100, 2) : 0;
        it.totalizadores['valor4'] = lista[3].valor3 != 0 ? this.funcaoService.arredondar((+it.totalizadores['valor3'] / lista[3].totalizadores['valor3']) * 100, 2) : 0;
        it.totalizadores['valor6'] = lista[3].valor5 != 0 ? this.funcaoService.arredondar((+it.totalizadores['valor5'] / lista[3].totalizadores['valor5']) * 100, 2) : 0;
      }

      if (it.registros[0].linha == 8) {
        it.totalizadores['valor2'] = this.funcaoService.arredondar(100, 2);
        it.totalizadores['valor4'] = this.funcaoService.arredondar(100, 2);
        it.totalizadores['valor6'] = this.funcaoService.arredondar(100, 2);
      }
    }
    return lista;
  }
}