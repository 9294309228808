import { Component, OnInit, Input, AfterViewInit, OnDestroy, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { Empenho, Login, GlobalService, FuncaoService } from 'eddydata-lib';
import { EmpenhoService } from 'administrativo-lib';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-atualizar-data-anulacao',
  templateUrl: './atualizar-data-anulacao.component.html'
})
export class AtualizarDataAnulacaoComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() visualizar: boolean = false;
  @Input() login: Login;
  @Input() numero: string;
  @Input() valor: number;
  @Input() id: number;
  @Input() data_original: string;
  @Input() service: any;

  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();
  @Output() onFinalizar: EventEmitter<void> = new EventEmitter();


  public unsubscribe: Subject<void> = new Subject();
  public datepipe: DatePipe;
  public ptBR: any;
  public data: Date;


  constructor(
    private globalService: GlobalService,
    private funcaoService: FuncaoService
  ) { }

  public ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.data = new Date
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public ngAfterViewInit() {
    this.globalService.calendarMascara();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.visualizar) {
      this.data = new Date(this.data_original)

      if (this.podeAlterarAnulacao()) this.show()
      else this.hide()
    }
  }

  public focusInput(input: any) {
    setTimeout(() => {
      input.select();
    }, 100);
  }

  public alterarData() {
    if (!this.podeAlterarAnulacao(this.data)) {
      toastr.warning(`A data informada não respeita o parâmetro de dias contábeis para alteração ou a Audesp já foi enviada!`)
      return
    } else {
      this.service.atualizarDataAnulacao(this.id, this.funcaoService.converteDataSQL(this.data), this.login.orgao.id).pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          toastr.success('Data de anulação alterada com sucesso')
          this.hide()
          this.onFinalizar.emit()
        }, erro => {
          this.funcaoService.acaoErro(erro)
          console.error(erro)
        })
    }
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
    $('#dlgAtualizarAnulacao').modal('hide');
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
    $('#dlgAtualizarAnulacao').modal('show');
  }

  public podeAlterarAnulacao(dataAlteracao?: Date) {
    const data = new Date(dataAlteracao ? dataAlteracao : this.data_original)

    if (!this.login.usuario.sistemas_administrador?.split(',').includes('contabil')) return false

    if (this.login['ultimoAudesp']) {
      const mes = +this.login['ultimoAudesp'].split('-')[1];
      const ano = +this.login['ultimoAudesp'].split('-')[0];
      if ((data.getFullYear() < ano || (data.getFullYear() === ano && (+data.getMonth() + 1) <= mes)) && mes < 12)
        return false;
    }
    if (this.login['dias_bloquear_alteracoes']) {
      return new FuncaoService().diferencaEmDias(new Date(), new Date(data)) < this.login['dias_bloquear_alteracoes'];
    } else {
      return true;
    }
  }

}
