import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GrupoEstoqueService, MaterialService, SubGrupoEstoqueService } from 'almoxarifado-lib';
import { EddyAutoComplete, GrupoEstoque, Login, Material, SubGrupoEstoque, UnidadeFornecimento } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-grupo-material-cadastro-dlg',
  templateUrl: './grupo-material-cadastro-dlg.component.html'
})
export class MaterialCadastroDlgComponent implements OnInit, OnChanges {

  public listaGrupo: GrupoEstoque[];
  public listaGrupoServico: GrupoEstoque[];
  public grupoAtual: number;
  public listaSubGrupo: SubGrupoEstoque[];
  public subGrupoAtual: number;
  public listaMaterial: Material[];
  public unidade: UnidadeFornecimento;
  public subGrupoAutoComplete: EddyAutoComplete<SubGrupoEstoque>;

  @Input() public nomeDlg: string = 'dialogProdutoCadastro';
  @Input() public login: Login;
  @Input() public subelemento: number;
  @Input() public produtoNovo: string;
  @Output() callback: EventEmitter<any> = new EventEmitter();
  @Output() cancelarImportacoesA: EventEmitter<boolean> = new EventEmitter();

  public entidadeForm: FormGroup;
  protected router: Router;
  protected activatedRoute: ActivatedRoute;
  protected fb: FormBuilder;
  protected unsubscribe: Subject<void> = new Subject();


  constructor(
    protected injector: Injector,
    protected grupoService: GrupoEstoqueService,
    protected subGrupoService: SubGrupoEstoqueService,
    protected materialService: MaterialService,
  ) {
  }

  ngOnInit(): void {
    this.router = this.injector.get(Router);
    this.fb = this.injector.get(FormBuilder);
    this.activatedRoute = this.injector.get(ActivatedRoute);
    this.criarCamposForm();
    this.carregarAutoCompletes();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.grupoService.filtrar(1, -1, { orgao_id: this.login.orgao.id, orderBy: 'codigo' }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaGrupo = res ? res.content : []
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async submitForm() {
    if (this.entidadeForm.invalid) {
      toastr.error('Há campos obrigatórios não informados!');
      return;
    }

    this.criarEntidade();
  }

  private criarEntidade() {
    const entidade: Material = Material.converteJson(this.entidadeForm.value);
    this.materialService
      .inserir(entidade)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.acaoSucesso(res);
      },
        error => this.acaoErro(error));
  }

  private acaoSucesso(entidade: Material) {
    toastr.success('Registro salvo com sucesso!');
    this.callback.emit(entidade);
    this.fechar(entidade);
  }

  public fechar(entidade?) {
    this.criarCamposForm();
    // precisa verificar se o serviço foi criado.
    // caso falso, chama a função this.cancelarImportacoes.emit(true)
    // caso verdadeiro, deverá pular essa função
    if (!entidade) {
      this.cancelarImportacoesA.emit(true)
    }
    $(`#${this.nomeDlg}`).modal('hide');
  }

  private acaoErro(error: any) {
    toastr.options.timeOut = 10000;
    toastr.options.closeButton = true;
    toastr.options.tapToDismiss = false;
    if (error.error && error.error.payload) {
      toastr.error(error.error.payload);
    } else {
      toastr.error('Ocorreu um erro ao processar a sua solicitação');
    }
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      orgao: [this.login.orgao, [Validators.required]],
      codigo: [null, [Validators.required]],
      nome: [null, [Validators.required]],
      sub_grupo: [null, [Validators.required]],
      servico: [false, [Validators.required]],
      contabilizar: [false, [Validators.required]],
      bec: [false, [Validators.required]]
    });
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public carregarSubGrupo() {
    if (this.grupoAtual) {
      this.subGrupoService.filtrar(1, -1, { grupo_id: this.grupoAtual, orderBy: 'codigo' }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaSubGrupo = res ? res.content : [];
          if (this.listaSubGrupo.length === 1) {
            this.subGrupoAtual = this.listaSubGrupo[0].id;
            this.carregarMaterial();
          }
        });
    }
  }

  private carregarAutoCompletes() {
    this.subGrupoAutoComplete = new EddyAutoComplete(null, this.subGrupoService,
      'id', ['codigo', 'nome'], { relations: 'grupo', orderBy: 'nome', orgao_id: this.login.orgao.id }, { number: ['codigo'], text: ['nome'] }
    );
  }

  public carregarMaterial() {
    if (this.subGrupoAtual) {
      this.materialService.filtrar(1, -1, {
        orgao_id: this.login.orgao.id, sub_grupo_id: this.subGrupoAtual, orderBy: 'codigo'
      }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaMaterial = res ? res.content : [];
          if (this.listaMaterial.length === 1) {
            this.entidadeForm.get('material').patchValue(this.listaMaterial[0]);
          }
        });
    }
  }

  public selectSubGrupo() {
    if (this.produtoNovo === 'novo') {
      this.materialService.proximoCodigo(this.entidadeForm.get('sub_grupo').value.id, false, this.login.orgao.id)
        .subscribe((data) => {
          this.entidadeForm.get('codigo').setValue(data);
        });
    }
  }


}
