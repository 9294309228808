
// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { EmpenhoResto } from './empenho-resto.model';
import { LiquidacaoResto } from './liquidacao-resto.model';

export class PreLiquidacaoResto extends BaseResourceModel {
    constructor(
        public id?: number,
        public data_liquidacao?: Date,
        public mes?: number,
        public data_vencimento?: Date,
        public parcela?: number,
        public data_referencia?: Date,
        public documento?: string,
        public historico?: string,
        public valor_liquidado?: number,
        public impresso?: boolean,
        public anulacao?: boolean,
        public empenho?: EmpenhoResto,
        public exercicio?: Exercicio,
        public orgao?: Orgao,
        public usuario_cadastro?: Usuario,
        public serie?: string,
        public data_emissao?: Date,
        public liquidacoes?: LiquidacaoResto[],
        public preliquidacao_anulada?: PreLiquidacaoResto,
        public anulado_total?: boolean,
        public tipo_documento?: string,
    ) {
        super();
    }

    static converteJson(json: any): PreLiquidacaoResto {
        return Object.assign(new PreLiquidacaoResto(), json);
    }
}
