import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PagamentoResto, PaisesService } from 'eddydata-lib';
import * as toastr from 'toastr';

@Component({
  selector: 'lib-pagamento-resto-reinf',
  templateUrl: './pagamento-resto-reinf.component.html'
})
export class PagamentoRestoReinfComponent implements OnInit {

  @Input() pagamento: PagamentoResto;
  @Output() callback: EventEmitter<PagamentoResto> = new EventEmitter();

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  paises: { codigo: number, nome: string }[] = [];

  constructor(protected paisesService: PaisesService) { }


  ngOnInit(): void {
    this.paises = this.paisesService.listaPaisesReinf();
  }

  salvar() {
    this.callback.emit(this.pagamento);
  }
}
