import { Component, Input } from "@angular/core";
import { ContratoService, LicitacaoService } from "administrativo-lib";
import { ContratoAditamentoService } from "compra-lib";
import { PageNumber } from "docx";
import { AssinaturaPessoa, AssinaturaService, BaseResourceRptPersonalizadoComponent, Coluna, Contrato, ContratoAditamento, CpfPipe, EddyAutoComplete, GlobalService, Licitacao, LicitacaoPipe, ModeloRelatorio, OrgaoAssinaturaService, Proposta, TelefonePipe } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";
import * as toastr from 'toastr';
import { ContratoStorageService } from "../../contrato/service/contrato-storage.service";
import { LicitacaoStorageService } from "../../licitacao/service/licitacao-storage.service";

@Component({
  selector: 'lib-modelo-relatorio-rpt-dlg',
  templateUrl: './modelo-relatorio-rpt-dlg.component.html'
})
export class ModeloRelatorioRptDlgComponent extends BaseResourceRptPersonalizadoComponent {

  @Input() public modelo: ModeloRelatorio;

  public licitacaoAutoComplete: EddyAutoComplete<Licitacao>;
  public licitacao: Licitacao;
  public contrato: Contrato;
  public aditamento: ContratoAditamento;
  public marcado: boolean = true;
  public parametros: { session?: any, filtros?: any } = {};
  public globalService: GlobalService;
  public testemunha: boolean = false;
  public representanteFavorecido: boolean = false;
  public representanteComissao: boolean = false;
  public testemunhaQtde: 1 | 2 | 3 = 1;

  constructor(
    private licitacaoService: LicitacaoService,
    private contratoService: ContratoService,
    private contratoAditamentoService: ContratoAditamentoService,
    private storageLicitacaoService: LicitacaoStorageService,
    private orgaoAssinaturaService: OrgaoAssinaturaService,
    private storageContratoService: ContratoStorageService,
    protected assinaturaService: AssinaturaService,
  ) {
    super(assinaturaService);
    this.assinaturaObrigatoria = false;
  }

  protected afterInit(): void {
    // this.licitacaoAutoComplete = new EddyAutoComplete(null, this.licitacaoService,
    //   'numero', ['numero', 'modalidade.nome'], { orgao_id: this.login.orgao.id, orderBy: 'numero', relations: 'modalidade' }, { text: ['numero'] }, () => {
    //     if (this.licitacao) {
    //       this.licitacaoService.obter({
    //         id: this.licitacao.id, relations: `modalidade,setor,tipo_contratacao,comissao.membros.pessoa${this.modelo.tipo === 'CONTRATO' ? ',contratos.favorecido,contratos.prazo' : ''}`
    //       }).pipe(takeUntil(this.unsubscribe))
    //         .subscribe((res) => {
    //           this.licitacao = res
    //           if (this.licitacao.contratos)
    //             this.licitacao.contratos.forEach(c => c.selecionado = true)
    //         }, error => this.funcaoService.acaoErro(error));
    //     }
    //   });
  }

  protected modalRelatorio(): string {
    return 'dlgModeloRelatorioRpt';
  }

  protected tituloRelatorio(): string {
    return `Assessoria de Licitações e Compras`;
  }

  protected layoutRelatorio(): {} {
    return {
      table_eddy: {
        hLineWidth: function (i, node) {
          return i === 0 ? 0 : 1;
        },
        vLineWidth: function (i) {
          return 0;
        },
        hLineColor: function (i, node) {
          return i === node.table.headerRows ? 'black' : '#eee';
        },
        paddingLeft: function (i) {
          return i === 0 ? 0 : 8;
        },
        paddingRight: function (i, node) {
          return (i === node.table.widths.length - 1) ? 0 : 8;
        },
        fillColor: function (i, node) {
          if (i === 0 || i === node.table.headerRows) {
            return null;
          }
          return (i % 2 === 0) ? '#f5f5f5' : null;
        }
      }
    };
  }

  protected async montarConteudo(): Promise<{}[]> {
    const conteudo = [];
    let assinaturaFavorecido: AssinaturaPessoa[] = [];
    let assinaturaComissao: AssinaturaPessoa[] = [];
    let assinaturaTestemunha: AssinaturaPessoa[] = [];
    const assinaturas = this.assinatura?.pessoas?.filter(p => p.selecionado);
    if (this.testemunha) {

    }
    if (this.representanteFavorecido && this.contrato?.id) {
      assinaturaFavorecido.push({
        cargo: this.contrato.favorecido['funcao_cargo'] ? this.contrato.favorecido['funcao_cargo'] + ' - Fornecedor' : 'Fornecedor',
        pessoa: {
          nome: this.contrato.favorecido.responsavel
        }
      });
    }

    if (this.representanteComissao && this.licitacao?.comissao_membros?.length > 0) {
      this.licitacao?.comissao_membros.filter(comissao => {
        assinaturaComissao.push({
          cargo: comissao.comissao_membro?.atribuicao,
          pessoa: {
            nome: comissao.comissao_membro.pessoa?.nome
          }
        });
      });
    }
    if (this.testemunha) {
      for (let i = 1; i <= +this.testemunhaQtde; i++) {
        assinaturaTestemunha.push({
          cargo: ' ',
          pessoa: {
            nome: 'TESTEMUNHA ' + i
          }
        });
      }
    }

    if (this.modelo.tipo === 'LICITACAO') {
      let modelo = await this.substituirTagsConteudo(this.modelo.modelo);
      return await new Promise(async (resolve) => {
        conteudo.push({
          text: `${this.licitacao.modalidade.nome} Nº ${new LicitacaoPipe().transform(this.licitacao.numero)}`, alignment: 'center',
          bold: true, fontSize: 14, decoration: 'underline', margin: [0, 0, 0, 10]
        });

        for (let i = 0; i < modelo.split('\n').length; i++) {
          const paragrafo = modelo.split('\n')[i];

          conteudo.push({ text: paragrafo, fontSize: 8, alignment: 'justify', margin: [0, 0, 0, 8] })
        }

        resolve([conteudo]
          .concat([{ text: '', margin: [0, 50] }, this.imprimirAssinaturas(assinaturas), this.representanteFavorecido ? this.imprimirAssinaturas(assinaturaFavorecido) : '', this.testemunha ? this.imprimirAssinaturas(assinaturaTestemunha) : '', this.representanteComissao ? this.imprimirAssinaturas(assinaturaComissao) : '']));
      });
    } else {
      if (this.modelo.tipo === 'CONTRATO') {
        return await new Promise(async (resolve) => {
          if (this.licitacao?.contratos && !this.contrato?.id)
            this.contrato = this.licitacao.contratos?.find(c => c.selecionado);
          if (!this.contrato) resolve([])
          this.contrato.selecionado = false;

          let modelo = await this.substituirTagsConteudo(this.modelo.modelo, this.contrato);

          const cabecalho = {
            text: `Contrato Nº ${new LicitacaoPipe().transform(this.contrato.numero)}`, alignment: 'center',
            bold: true, fontSize: 14, decoration: 'underline', margin: [0, 0, 0, 10]
          };
          // inclui quebra de pagina após primeira passagem
          if (conteudo.length > 0) cabecalho['pageBreak'] = 'before'
          conteudo.push(cabecalho)

          for (let i = 0; i < modelo.split('\n').length; i++) {
            const paragrafo = modelo.split('\n')[i];
            if (paragrafo.includes('<ASSINATURA_FAVORECIDO>')) {

            }
            if (paragrafo.includes('<TABELA_ITENS_FORNECEDOR>')) {
              const separado = paragrafo.split('<TABELA_ITENS_FORNECEDOR>');
              if (separado[0].length > 1)
                conteudo.push({ text: separado[0], fontSize: 8, alignment: 'justify', margin: [0, 0, 0, 8] })
              const tabela = this.montarTabelaItensFavorecido(this.contrato);
              conteudo.push(tabela)
              conteudo.push({ text: separado[1], fontSize: 8, alignment: 'justify', margin: [0, 8, 0, 0] })
            } else {
              // if (paragrafo.includes('<TESTEMUNHA>')) {
              //   conteudo.push(this.imprimirAssinaturaTestemunha());
              // } else {
              conteudo.push({ text: paragrafo, fontSize: 8, alignment: 'justify', margin: [0, 0, 0, 8] })
              // }
            }
          }

          resolve([conteudo]
            .concat([{ text: '', margin: [0, 50] }, this.imprimirAssinaturas(assinaturas), this.representanteFavorecido ? this.imprimirAssinaturas(assinaturaFavorecido) : '', this.testemunha ? this.imprimirAssinaturas(assinaturaTestemunha) : '', this.representanteComissao ? this.imprimirAssinaturas(assinaturaComissao) : '']));
        });
      }
      if (this.modelo.tipo === 'ADITAMENTO') {
        return await new Promise(async (resolve) => {
          if (this.licitacao?.contratos && !this.contrato?.id)
            this.contrato = this.licitacao.contratos?.find(c => c.selecionado);
          if (!this.contrato) resolve([])
          this.contrato.selecionado = false;
          if (this.licitacao?.contratos && !this.contrato?.id) {

          }

          let modelo = await this.substituirTagsConteudo(this.modelo.modelo, this.contrato, this.aditamento);

          const cabecalho = {
            text: `Contrato Nº ${new LicitacaoPipe().transform(this.contrato.numero)}  - Aditivo Nº ${this.aditamento.numero}`, alignment: 'center',
            bold: true, fontSize: 14, decoration: 'underline', margin: [0, 0, 0, 10]
          }
          // inclui quebra de pagina após primeira passagem
          if (conteudo.length > 0) cabecalho['pageBreak'] = 'before'
          conteudo.push(cabecalho)

          for (let i = 0; i < modelo.split('\n').length; i++) {
            const paragrafo = modelo.split('\n')[i];
            if (paragrafo.includes('<TABELA_ITENS_FORNECEDOR>')) {
              const separado = paragrafo.split('<TABELA_ITENS_FORNECEDOR>');
              if (separado[0].length > 1)
                conteudo.push({ text: separado[0], fontSize: 8, alignment: 'justify', margin: [0, 0, 0, 8] })
              const tabela = this.montarTabelaItensFavorecido(this.contrato);
              conteudo.push(tabela)
              conteudo.push({ text: separado[1], fontSize: 8, alignment: 'justify', margin: [0, 8, 0, 0] })
            } else {
              conteudo.push({ text: paragrafo, fontSize: 8, alignment: 'justify', margin: [0, 0, 0, 8] })
            }
          }

          resolve([conteudo]
            .concat([{ text: '', margin: [0, 50] }, this.imprimirAssinaturas(assinaturas), this.representanteFavorecido ? this.imprimirAssinaturas(assinaturaFavorecido) : '', this.testemunha ? this.imprimirAssinaturas(assinaturaTestemunha) : '', this.representanteComissao ? this.imprimirAssinaturas(assinaturaComissao) : '']));
        });
      }
    }
  }

  protected montarCabecalho(): {}[] {
    return [{
      columns: [
        this.login.brasao ? {
          image: this.login.brasao,
          width: 60, margin: [40, 20, 0, 0]
        } : { text: '', width: 60, margin: [40, 20, 0, 0] },
        {
          width: '*', margin: [0, 35, 0, 0],
          stack: [
            { text: this.login.orgao.nome, fontSize: 13, alignment: 'center' },
            { text: `Assessoria de Licitações e Compras`, fontSize: 10, alignment: 'center' }
          ]
        },
        this.login.bandeira ? {
          image: this.login.bandeira,
          width: 70, alignment: 'center', margin: [0, 25, 40, 0]
        } : { text: '', width: 70, margin: [0, 25, 40, 0] }
      ]
    }];
  }

  protected montarFooter(): any {
    return (currentPage, pageCount, pageSize) => {
      return [
        { canvas: [{ type: 'line', x1: 30, y1: 10, x2: pageSize.width - 30, y2: 10, lineWidth: 0.5 }], margin: [0, 0, 0, 5] },
        { text: `${this.login.orgao.endereco} - CEP: ${this.login.orgao.cep} - FONE: ${this.login.orgao.telefone} -  ${this.login.cidade.nome} -  ${this.login.cidade.estado.uf}`, fontSize: 10, alignment: 'center' },
        {
          columns: [{
            text: '', fontSize: 10,
            alignment: 'left', alignmentDocx: 'left', margin: [30, 5, 0, 5], style: 'footer'
          },
          {
            text: 'Página ' + currentPage.toString() + ' de ' + pageCount, alignment: 'right', alignmentDocx: 'right', fontSize: 10,
            margin: [30, 5, 30, 5], style: 'footer', children: ['Página ', currentPage, ' de ', pageCount]
          }]
        }
      ];
    };
  }

  protected montarFooterDocx() {
    return this.montarFooter()(PageNumber.CURRENT, PageNumber.TOTAL_PAGES, 0)
      .filter((row) => !row.canvas);
  }

  protected margemRelatorio(): number[] {
    return [40, 100, 40, 60];
  }

  protected async fazerDownloadArquivo(arquivo: Blob) {
    const file = new File([arquivo], `${this.tituloRelatorio()}.${this.formato}`, {
      type: arquivo.type
    });

    if (this.modelo.tipo === 'CONTRATO' || this.modelo.tipo === 'ADITAMENTO')
      this.storageContratoService
        .upload([file], this.login.orgao.id, this.contrato.id, '').pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          toastr.success('Arquivo armazenado com sucesso!')
        }, (err) => this.funcaoService.acaoErro(err));
    else
      this.storageLicitacaoService
        .upload([file], this.login.orgao.id, this.licitacao.id, '').pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          toastr.success('Arquivo armazenado com sucesso!')
        }, (err) => this.funcaoService.acaoErro(err));

  }

  protected aposEmitir(): void {
    if (this.modelo.tipo === 'CONTRATO') {
      if (this.licitacao.contratos?.find(c => c.selecionado))
        this.imprimir();
    }
  }

  public obterColunasfiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];

    retorno.push({
      titulo: 'Licitação', coluna: 'numero', tipo: 'AutoComplete',
      autocomplete: new EddyAutoComplete(null, this.licitacaoService,
        'numero', ['numero', 'modalidade.nome'], {
        orgao_id: this.login.orgao.id, orderBy: `numero${this.modelo.tipo === 'CONTRATO' ? ',contratos.itens.memorial.ordem' : ''}`,
        relations: `orgao,modalidade,setor,tipo_contratacao,comissao.membros.pessoa,itens.rpl_itens.lista_ficha_despesa.ficha_despesa.despesa,testemunhas.pessoa,fiscais.pessoa${this.modelo.tipo === 'CONTRATO' ? ',contratos.favorecido,contratos.prazo,contratos.itens.memorial.propostas.proponente.favorecido,contratos.itens.produto_unidade.produto' : ''}`
      }, { text: ['numero'] }), cols: 12, padrao: this.modelo.tipo === 'LICITACAO'
    });
    retorno.push({
      titulo: 'Contrato', coluna: 'contratos.numero', tipo: 'AutoComplete', cols: 12,
      autocomplete: new EddyAutoComplete(null, this.contratoService,
        'numero', ['numero', 'favorecido.nome'], {
        orgao_id: this.login.orgao.id,
        relations: `licitacao.tipo_contratacao,favorecido.cidade.estado,aditamentos.tipo,licitacao.setor.unidade,orgao.cidade.estado,prazo,licitacao.modalidade,itens.memorial.propostas.proponente.favorecido,itens.produto_unidade.produto.material,responsaveis.responsavel,testemunhas.pessoa,fiscais.pessoa,licitacao.testemunhas.pessoa,licitacao.fiscais.pessoa${this.modelo.tipo === 'LICITACAO' ? ',licitacao.modalidade,licitacao.setor,licitacao.tipo_contratacao,licitacao.comissao.membros.pessoa' : ',licitacao.comissao.membros.pessoa,licitacao.comissao_membros.comissao_membro.pessoa'}`,
        orderBy: 'favorecido.nome,itens.memorial.ordem'
      }, { text: ['numero', 'favorecido.nome'] }), padrao: this.modelo.tipo === 'CONTRATO'
    });
    retorno.push({
      titulo: 'Contrato/Aditivo', coluna: 'contratos.aditamentos.numero', tipo: 'AutoComplete',
      autocomplete: new EddyAutoComplete(null, this.contratoAditamentoService,
        'numero', ['contrato.numero', 'numero'], {
        relations: 'contrato.favorecido.cidade.estado,contrato.prazo,contrato.orgao.cidade.estado,contrato.itens.memorial.propostas.proponente.favorecido,contrato.itens.produto_unidade.produto.material,contrato.responsaveis.responsavel,contrato.testemunhas.pessoa,contrato.fiscais.pessoa,tipo',
        orderBy: 'contrato.favorecido.nome,contrato.itens.memorial.ordem'
      }, { text: ['aux', 'contrato.numero', 'numero'] }), cols: 12, padrao: this.modelo.tipo === 'ADITAMENTO'
    });

    return retorno;
  }

  // +
  public async atualizarParametros() {
    const licitacao: Licitacao = this.parametros?.session['numero']?.filtro1;
    const contrato: Contrato = this.parametros?.session['contratos.numero']?.filtro1;
    const contratoAditivo: ContratoAditamento = this.parametros?.session['contratos.aditamentos.numero']?.filtro1;
    if (licitacao && !contrato && !contratoAditivo) {
      this.licitacao = licitacao;
      if (this.licitacao?.contratos)
        this.licitacao.contratos.forEach(c => c.selecionado = true);
    } else if (contrato && !licitacao && !contratoAditivo) {
      this.contrato = contrato;
      this.licitacao = contrato.licitacao;
    } else if (contratoAditivo && !licitacao && !contrato) {
      if (!this?.contrato?.id) {
        let c = await this.contratoService.obter({
          id: contratoAditivo.contrato.id, relations: `licitacao.tipo_contratacao,licitacao.fiscais.pessoa,licitacao.testemunhas.pessoa,licitacao.setor.unidade,licitacao.modalidade,licitacao.comissao.membros.pessoa,licitacao.comissao_membros.comissao_membro.pessoa`,
          orderBy: 'favorecido.nome,itens.memorial.ordem'
        }).toPromise();
        this.licitacao = c.licitacao;
      }
      this.contrato = contratoAditivo.contrato;
      this.aditamento = contratoAditivo;
    } else {
      this.contrato = {};
      this.licitacao = new Licitacao();
      this.aditamento = {};
      if (!licitacao && !contrato && !contratoAditivo) return;
      toastr.warning('Selecione apenas um filtro por vez para gerar o relatório.');
    }
  }
  // -

  public verificaSelecao() {
    if (this.modelo.tipo === 'LICITACAO')
      return !this.licitacao?.id;
    if (this.licitacao?.contratos && this.licitacao?.contratos?.length > 0) {
      const res = this.licitacao.contratos?.find(item => item.selecionado === true)
      return !res
    }
    return !this.contrato?.id;
  }

  private async substituirTagsConteudo(modelo: string, contrato?: Contrato, aditivo?: ContratoAditamento): Promise<string> {
    const comissaoMembros = this.licitacao.comissao?.membros ? this.licitacao.comissao.membros : [];

    const assinaturaOrgao = await this.orgaoAssinaturaService.obter({ orgao_id: contrato?.orgao?.id ? contrato?.orgao?.id : this.licitacao?.orgao?.id }).toPromise();

    modelo = modelo
      .split('<COMISSAO_INICIO>').join(this.licitacao.comissao?.vigencia_inicio ? this.funcaoService.converteDataBR(this.licitacao.comissao.vigencia_inicio) : '')
      .split('<COMISSAO_MEMBROS>').join(comissaoMembros.filter(m => m.atribuicao !== '1 - PRESIDENTE' && m.atribuicao !== '3 - PREGOEIRO' && m.atribuicao !== '7 - SECRETÁRIO').map(m => m.pessoa.nome).join(','))
      .split('<COMISSAO_NOME>').join(this.licitacao.comissao?.nome)
      .split('<COMISSAO_PORTARIA>').join(this.licitacao.comissao?.portaria)
      .split('<COMISSAO_PREGOEIRO>').join(comissaoMembros.filter(m => m.atribuicao === '3 - PREGOEIRO').map(m => m.pessoa.nome).join(','))
      .split('<COMISSAO_PRESIDENTE>').join(comissaoMembros.filter(m => m.atribuicao === '1 - PRESIDENTE').map(m => m.pessoa.nome).join(','))
      .split('<COMISSAO_PUBLICACAO>').join(this.licitacao.comissao?.publicacao ? this.funcaoService.converteDataBR(this.licitacao.comissao.publicacao) : '')
      .split('<COMISSAO_SECRETARIO>').join(comissaoMembros.filter(m => m.atribuicao === '7 - SECRETÁRIO').map(m => m.pessoa.nome).join(','))
      .split('<COMISSAO_TERMINO>').join(this.licitacao.comissao?.vigencia_termino ? this.funcaoService.converteDataBR(this.licitacao.comissao.vigencia_termino) : '')
      .split('<DATA_ABERTURA>').join(this.funcaoService.converteDataBR(this.licitacao.data_abertura))
      .split('<DATA_ASSINATURA>').join(this.funcaoService.converteDataBR(this.licitacao.data_assinatura))
      .split('<DATA_EDITAL>').join(this.funcaoService.converteDataBR(this.licitacao.data_edital))
      .split('<DATA_HOMOLOGACAO>').join(this.funcaoService.converteDataBR(this.licitacao.data_homologacao))
      .split('<DATA_INICIO>').join(this.funcaoService.converteDataBR(this.licitacao.data_inicio))
      .split('<DATA_JULGAMENTO>').join(this.funcaoService.converteDataBR(this.licitacao.data_julgamento))
      .split('<DATA_PARECER>').join(this.funcaoService.converteDataBR(this.licitacao.data_parecer))
      .split('<DATA_PUBLICACAO>').join(this.funcaoService.converteDataBR(this.licitacao.data_publicacao))
      .split('<DATA_TERMINO>').join(this.funcaoService.converteDataBR(this.licitacao.data_termino))
      .split('<INICIO_PROPOSTA>').join(this.funcaoService.converteDataBR(this.licitacao.data_inicio_proposta))
      .split('<TERMINO_PROPOSTA>').join(this.funcaoService.converteDataBR(this.licitacao.data_fim_proposta))
      .split('<FICHA_DESPESA>').join(
        this.licitacao.formato_contratacao === 'RESERVA_VALOR' &&
          this.licitacao.itens?.some(i => i.rpl_itens?.some(r => r.lista_ficha_despesa.length > 0 && r.lista_ficha_despesa[0].ficha_despesa))
          ? (() => {
            // Usa um objeto para armazenar os números e descrições de ficha de despesa
            const fichaMap: { [numero: string]: string } = this.licitacao.itens.reduce((acc, i) => {
              i.rpl_itens.forEach(r => {
                if (r.aux === null) {
                  r.lista_ficha_despesa.forEach(f => {
                    const numero = f.ficha_despesa.numero.toString();
                    const descricao = f.ficha_despesa.despesa?.nome || 'Sem descrição'; // Adiciona uma descrição padrão, se necessário
                    acc[numero] = descricao; // Armazena a descrição associada ao número
                  });
                }
              });
              return acc;
            }, {});
            // Cria o resultado final, retornando os números e descrições
            return Object.entries(fichaMap).map(([numero, descricao]) =>
              `${numero} - ${descricao}`
            ).join('; ');
          })()
          : ''
      )
      .split('<DATA_ESCLARECIMENTO>').join(this.funcaoService.converteDataBR(this.licitacao.data_esclarecimento))
      .split('<JUSTIFICATIVA>').join(this.licitacao.justificativa)
      .split('<MODALIDADE>').join(this.licitacao.modalidade?.nome)
      .split('<NUMERO>').join(new LicitacaoPipe().transform(this.licitacao.numero))
      .split('<OBJETO>').join(this.licitacao.objeto)
      .split('<PRAZO_ENTREGA>').join(this.licitacao.prazo_entrega ? String(this.licitacao.prazo_entrega) : '')
      .split('<PRAZO_PAGAMENTO>').join(this.licitacao.prazo_pagamento ? String(this.licitacao.prazo_pagamento) : '')
      .split('<PROCESSO>').join(new LicitacaoPipe().transform(this.licitacao.processo))
      .split('<SETOR_NOME>').join(this.licitacao.setor?.nome)
      .split('<TIPO_CONTRATACAO>').join(this.licitacao.tipo_contratacao?.nome)
      .split('<VALOR_ESTIMADO>').join(this.funcaoService.convertToBrNumber(this.licitacao.valor_estimado, 2))
      .split('<VALOR_LICITACAO>').join(this.funcaoService.convertToBrNumber(this.licitacao.valor_licitado, 2))
      .split('<FISCAL>').join(this.licitacao.fiscais.map(f => f.pessoa.nome).join(', '))
      .split('<CPF_FISCAL>').join(this.licitacao.fiscais.map(f => this.funcaoService.mascarar('000.000.000-00', f.pessoa.cpf_cnpj)).join(', '))
      .split('<TESTEMUNHA>').join(this.licitacao.testemunhas.map(t => t.pessoa.nome).join(', '))
      .split('<CPF_TESTEMUNHA>').join(this.licitacao.testemunhas.map(t => this.funcaoService.mascarar('000.000.000-00', t.pessoa.cpf_cnpj)).join(', '))

    if (contrato) {
      const meses = this.funcaoService.diferencaEmMeses(new Date(contrato.data_inicio), new Date(contrato.data_termino));
      modelo = modelo
        .split('<DATA_ASSINATURA_CONTRATO>').join(this.funcaoService.converteDataBR(contrato.data_assinatura))
        .split('<DATA_INICIO_CONTRATO>').join(this.funcaoService.converteDataBR(contrato.data_inicio))
        .split('<DATA_TERMINO_CONTRATO>').join(this.funcaoService.converteDataBR(contrato.data_termino))
        .split('<FORNECEDOR_CPF>').join(new CpfPipe().transform(contrato.favorecido.cpf_cnpj))
        .split('<FORNECEDOR_NOME>').join(contrato.favorecido.nome)
        .split('<NUMERO_CONTRATO>').join(new LicitacaoPipe().transform(contrato.numero))
        .split('<OBJETO_CONTRATO>').join(contrato.objeto)
        .split('<PRAZO_CONTRATO>').join(contrato.prazo.nome)
        .split('<VALOR_CAUCAO>').join(this.funcaoService.convertToBrNumber(contrato.valor_caucao))
        .split('<VALOR_CONTRATO>').join(this.funcaoService.convertToBrNumber(contrato.valor_contrato))
        .split('<VALOR_GARANTIA>').join(this.funcaoService.convertToBrNumber(contrato.valor_garantia))
        .split('<TIPO_CONTRATO>').join(contrato.tipo === 'CONTRATO' ? 'CONTRATO' : 'REGISTRO PREÇO')
        .split('<UNIAO_NOME>').join(contrato.orgao.nome)
        .split('<UNIAO_CNPJ>').join(new CpfPipe().transform(contrato.orgao?.cnpj))
        .split('<UNIAO_ENDERECO>').join(contrato.orgao?.endereco)
        .split('<UNIAO_CIDADE>').join(contrato.orgao?.cidade?.nome)
        .split('<UNIAO_ESTADO>').join(contrato.orgao?.cidade?.estado?.nome)
        .split('<PREFEITO_NOME>').join(assinaturaOrgao?.prefeito)
        .split('<PREFEITO_CPF>').join(assinaturaOrgao?.cpf_prefeito)
        .split('<FORNECEDOR_ENDERECO>').join(contrato.favorecido.endereco)
        .split('<FORNECEDOR_NUMERO_ENDERECO>').join(contrato.favorecido?.num_endereco)
        .split('<FORNECEDOR_COMPLEMENTO_ENDERECO>').join(contrato.favorecido?.complemento ? contrato.favorecido?.complemento : '')
        .split('<FORNECEDOR_BAIRRO>').join(contrato.favorecido?.bairro)
        .split('<FORNECEDOR_CEP>').join(contrato.favorecido?.cep)
        .split('<FORNECEDOR_CIDADE_UF>').join(`${contrato.favorecido?.municipio} - ${contrato.favorecido?.cidade?.estado?.nome}`)
        .split('<FORNECEDOR_CIDADE>').join(contrato.favorecido?.cidade?.nome)
        .split('<FORNECEDOR_ESTADO>').join(contrato.favorecido?.cidade?.estado?.nome)
        .split('<FORNECEDOR_REPRESENTANTE>').join(contrato.favorecido?.responsavel)
        .split('<FORNECEDOR_REPRESENTANTE_FUNCAO>').join(contrato.favorecido['funcao_cargo'])
        .split('<FORNECEDOR_TELEFONE>').join(new TelefonePipe().transform(contrato.favorecido.telefone))
        .split('<FORNECEDOR_EMAIL>').join(contrato.favorecido.email)
        .split('<MATRICULA_COMISSAO>').join(this.licitacao?.comissao_membros.map(comissao => comissao?.comissao_membro['matricula_funcional']).join(','))
        .split('<SECRETARIA_NOME>').join(contrato.licitacao?.setor?.unidade?.nome)
        .split('<SECRETARIO_NOME>').join(contrato.licitacao?.setor?.unidade?.responsavel)
        .split('<SECRETARIO_CPF>').join(new CpfPipe().transform(contrato.licitacao?.setor?.unidade?.cpf))
        .split('<SECRETARIO_EMAIL>').join(contrato.licitacao?.setor?.unidade?.email)
        .split('<VALOR_MENSAL>').join(this.funcaoService.convertToBrNumber(contrato.valor_contrato / (meses < 1 ? 1 : meses + 1)))
        .split('<INDICE_REAJUSTE>').join(contrato['indice_reajuste'])
        .split('<PRAZO_PRORROGACAO>').join(this.funcaoService.converteDataBR(contrato['data_prorrogacao']))
        .split('<PRAZO_RESPOSTA>').join(this.funcaoService.converteDataBR(contrato['prazo_resposta']))
        .split('<PRAZO_TERMINO>').join(this.diferencaEmDias(new Date(), new Date(contrato.data_termino)))
        .split('<DIAS_VIGENCIA_CONTRATO>').join(this.diferencaEmDias(new Date(contrato.data_inicio), new Date()))
        // .split('<PRAZO_MULTA>').join(contrato['prazo_multa'])
        .split('<MULTA_MORATORIA>').join(this.funcaoService.convertToBrNumber(contrato['multa_moratoria']))
        .split('<LOCAL_DATA>').join(this.funcaoService.formatarDataExtenso(new Date(), contrato.orgao.cidade.nome))
        .split('<FISCAL_CONTRATO>').join(contrato.fiscais.filter(f => f.tipo === 'FISCAL' || f.tipo === 'FISCAL_GESTOR').map(f => f.pessoa.nome).join(', '))
        .split('<CPF_FISCAL_CONTRATO>').join(contrato.fiscais.filter(f => f.tipo === 'FISCAL' || f.tipo === 'FISCAL_GESTOR').map(f => this.funcaoService.mascarar('000.000.000-00', f.pessoa.cpf_cnpj)).join(', '))
        .split('<TESTEMUNHA_CONTRATO>').join(contrato.testemunhas.map(t => t.pessoa.nome).join(', '))
        .split('<CPF_TESTEMUNHA_CONTRATO>').join(contrato.testemunhas.map(t => this.funcaoService.mascarar('000.000.000-00', t.pessoa.cpf_cnpj)).join(', '))
        .split('<RESPONSAVEL_CONTRATADO_CONTRATO>').join(contrato.responsaveis?.find(r => !r.contratante)?.responsavel?.nome)
        .split('<CPF_CONTRATADO_CONTRATO>').join(this.funcaoService.mascarar('000.000.000-00', contrato.responsaveis?.find(r => !r.contratante)?.responsavel?.cpf_cnpj))
        .split('<E-MAIL_CONTRATADO_CONTRATO>').join(contrato.responsaveis?.find(r => !r.contratante)?.responsavel?.email)
        .split('<RESPONSAVEL_CONTRATANTE_CONTRATO>').join(contrato.responsaveis?.find(r => r.contratante)?.responsavel?.nome)
        .split('<CPF_RESPONSAVEL_CONTRATANTE_CONTRATO>').join(this.funcaoService.mascarar('000.000.000-00', contrato.responsaveis?.find(r => r.contratante)?.responsavel?.cpf_cnpj))
    }

    if (aditivo) {
      modelo = modelo
        .split('<ADITIVO_DATA_ASSINATURA>').join(this.funcaoService.converteDataBR(aditivo.data_aditamento))
        .split('<ADITIVO_DATA_INICIO>').join(this.funcaoService.converteDataBR(aditivo.data_inicio))
        .split('<ADITIVO_DATA_TERMINO>').join(this.funcaoService.converteDataBR(aditivo.data_termino))
        .split('<ADITIVO_NUMERO>').join(new LicitacaoPipe().transform(aditivo.numero))
        .split('<ADITIVO_TIPO>').join(aditivo.tipo?.nome)
        .split('<ADITIVO_FINALIDADE>').join(aditivo.finalidade)
        .split('<ADITIVO_VALOR_TOTAL>').join(this.funcaoService.convertToBrNumber(aditivo.valor_total))
        .split('<ADITIVO_RESPONSAVEL_CONTRATADO>').join(aditivo.contrato?.responsaveis?.find(r => !r.contratante)?.responsavel?.nome)
        .split('<ADITIVO_CPF_CONTRATADO>').join(this.funcaoService.mascarar('000.000.000-00', aditivo.contrato?.responsaveis?.find(r => !r.contratante)?.responsavel?.cpf_cnpj))
        .split('<ADITIVO_E-MAIL_CONTRATADO>').join(aditivo.contrato?.responsaveis?.find(r => !r.contratante)?.responsavel?.email)
        .split('<ADITIVO_VALOR_CONTRATO>').join(this.funcaoService.convertToBrNumber(aditivo.contrato.valor_contrato))
        .split('<ADITIVO_RESPONSAVEL_CONTRATANTE>').join(aditivo.contrato?.responsaveis?.find(r => r.contratante)?.responsavel?.nome)
        .split('<ADITIVO_CPF_RESPONSAVEL_CONTRATANTE>').join(this.funcaoService.mascarar('000.000.000-00', aditivo.contrato?.responsaveis?.find(r => r.contratante)?.responsavel?.cpf_cnpj))
    }

    return modelo
  }

  public toggleSelecionado() {
    // marcado === false marcado
    // marcado === true desmarcado
    this.licitacao.contratos.forEach(m => m.selecionado = !this.marcado)
  }

  public montarTabelaItensFavorecido(contrato: Contrato) {
    const conteudo = []
    const cabecalho = [];
    cabecalho.push(
      { text: 'ITEM', fontSize: 8, alignment: 'center', bold: true },
      { text: 'ESPECIFICAÇÃO', fontSize: 8, alignment: 'center', bold: true },
      { text: 'CATEGORIA', fontSize: 8, alignment: 'center', bold: true },
      { text: 'MARCA', fontSize: 8, alignment: 'center', bold: true },
      { text: 'Und.', fontSize: 8, alignment: 'center', bold: true },
      { text: 'Qtd.', fontSize: 8, alignment: 'center', bold: true },
      { text: 'VALOR', fontSize: 8, alignment: 'center', bold: true },
      { text: 'Vl TOTAL', fontSize: 8, alignment: 'center', bold: true }
    )
    conteudo.push(cabecalho);

    for (const item of contrato.itens) {
      const registros = [];
      registros.push({ text: `${item?.memorial?.ordem}`, alignment: 'center' });
      registros.push({ text: `${this.funcaoService.abreviarStr(item?.produto_unidade?.produto?.nome, 100)}`, alignment: 'left' });
      registros.push({ text: `${item?.produto_unidade?.produto?.material?.servico ? 'Serviço' : 'Material'}`, alignment: 'center' });
      const marca = (item?.memorial?.propostas?.find(proposta => proposta?.proponente?.favorecido?.id === contrato?.favorecido?.id))?.marca;
      registros.push({ text: `${marca ? marca : 'Nenhuma'}`, alignment: 'center' });
      registros.push({ text: `${item?.unidade}`, alignment: 'center' });
      registros.push({ text: `${item?.quantidade}`, alignment: 'center' });
      registros.push({ text: `R$${this.funcaoService.convertToBrNumber(item?.valor_unitario)}`, alignment: 'right' });
      registros.push({ text: `R$${this.funcaoService.convertToBrNumber((+item?.valor_unitario * +item.quantidade))}`, alignment: 'right' });
      conteudo.push(registros);
    }

    return [{
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', 100, '*', '*', '*', '*', '*', '*'],
        body: conteudo
      }
    }];
  }

  public diferencaEmDias(data1: Date, data2: Date): string {
    // Obtém o tempo em milissegundos das duas datas
    const time1 = data1.getTime();
    const time2 = data2.getTime();

    // Calcula a diferença em milissegundos
    const diferencaEmMilissegundos = time2 - time1;

    // Converte a diferença em dias
    const umDiaEmMilissegundos = 1000 * 60 * 60 * 24;
    const diferencaEmDias = '' + Math.floor(diferencaEmMilissegundos / umDiaEmMilissegundos);

    return diferencaEmDias;
  }
}