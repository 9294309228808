// tslint:disable: variable-name
import { ProcessoRelacionado } from './processo-relacionado.model';
import { ProcessoHistorico } from './processo-historico.model';
import { Orgao } from '../comum/orgao.model';
import { BaseResourceModel } from '../../models/base-resource.model';

export class Processo extends BaseResourceModel {
    constructor(
        public id?: number,
        public numero?: string,
        public data_processo?: Date,
        public data_termino?: Date,
        public assunto?: string,
        public prioridade?: string,
        public situacao?: string,
        public texto?: string,
        public tipo?: number,
        public cancelado?: boolean,
        public data_cadastro?: Date,
        public data_alteracao?: Date,
        public orgao?: Orgao,
        public relacionados?: Array<ProcessoRelacionado>,
        public historico?: ProcessoHistorico,
        public historicos?: Array<ProcessoHistorico>
    ) {
        super();
    }
    static converteJson(json: any): Processo {
        return Object.assign(new Processo(), json);
    }
}
