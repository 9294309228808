import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, TransparenciaParametro } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class TransparenciaParametroService extends BaseResourceService<TransparenciaParametro> {

  constructor(
    protected injector: Injector
  ) {
    super(`transparencia-parametros`, injector);
  }

  public obterParametro(orgaoId: number): Observable<TransparenciaParametro> {
    return this.http.get<TransparenciaParametro>(
      `${this.login.cidade.id}/${this.api}/obter-parametro/${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
