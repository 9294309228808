// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class Questionario  extends BaseResourceModel {
  constructor(
    public id?: number,
    public especie?: string,
    public pergunta?: string,
    public tipo_resposta?: string,
    public ordem?: number,
    public orgao?: Orgao
    ) {
      super();
    }
    static converteJson(json: any): Questionario {
      return Object.assign(new Questionario(), json);
    }
}
