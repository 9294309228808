import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class SiteMenu extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public url?: string,
    public ativo?: boolean,
    public parente?: SiteMenu,
    public orgao?: Orgao
  ) {
    super();
  }

  static converteJson(json: any): SiteMenu {
    return Object.assign(new SiteMenu(), json);
  }
}
