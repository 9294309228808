import { Component, EventEmitter, Output } from '@angular/core';
import { FormatoExportacao } from '../types';

@Component({
  selector: 'lib-export-list',
  templateUrl: './export-list.component.html'
})
export class ExportListComponent {

  @Output() acao = new EventEmitter<string>();

  constructor() { }

  exportar(formato: FormatoExportacao) {
    this.acao.emit(formato);
  }

}
