// tslint:disable: variable-name

import { BaseResourceModel } from '../../models/base-resource.model';
import { ProdutoUnidade } from '../almoxarifado/produto-unidade.model';
import { RequisicaoMovimento } from './requisicao-movimento.model';
import { StatusRequisicao } from './status-requisicao.enum';

export class RequisicaoItemMovimento extends BaseResourceModel {
  constructor(
    public id?: number,
    public status?: StatusRequisicao,
    public qtd_requisitada?: number,
    public qtd_aceita?: number,
    public saldo_atual?: number,
    public vencimento?: Date,
    public lote?: string,
    public requisicao?: RequisicaoMovimento,
    public produto_unidade?: ProdutoUnidade,
    public editavel?: boolean,
    public justificativa_troca?: string,
    public justificativa_recusado?: string,
    public produto_unidade_troca?: ProdutoUnidade,
    public sem_entrada?: boolean,
    public listLote?: {
      lote: string, saldo: number, entrada: number, saida: number
    }[]
  ) {
    super();
  }
  static converteJson(json: any): RequisicaoItemMovimento {
    return Object.assign(new RequisicaoItemMovimento(), json);
  }
}
