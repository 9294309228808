import { RcmsCotacao, RplCotacao } from "eddydata-lib";
import { BaseEstimativaPrecoRpt, EstimativaItemRpt, EstimativaRpt } from "./base-estimativa-preco-rpt";

export class EstimativaPrecoRpt extends BaseEstimativaPrecoRpt {

    constructor() {
        super();
    }

    protected montarConteudo(estimativa: EstimativaRpt): {}[] {
        const conteudo = this.conteudoEstimativa(estimativa);
        if (this.comAssinatura && this.assinatura)
            return [conteudo.concat(this.assinaturas())];
        return [conteudo];
    }

    protected conteudoEstimativa(estimativa: EstimativaRpt): {}[] {
        return [{
            layout: 'linhas',
            table: {
                unbreakable: true,
                dontBreakRows: true,
                headerRows: 0,
                widths: ['*', '*', '*', '*', '*', '*', '*'],
                body: this.cabecalho(estimativa).concat(this.itens(estimativa.itens))
            }
        }]
    }

    private itens(itens: EstimativaItemRpt[]): {}[] {
        let conteudo = [];
        for (const item of itens) {
            let produto = item.produto_unidade.produto.nome;



            if (item.lote && item.itens?.length > 0) {

                conteudo.push([
                    { text: 'ORDEM', fontSize: 12, bold: true, border: [true, true, true, true], alignment: 'center' },
                    { text: 'CÓDIGO', fontSize: 12, bold: true, border: [true, true, true, true], alignment: 'center' },
                    { text: 'DESCRIÇÃO', fontSize: 12, bold: true, border: [true, true, true, true], colSpan: 3, alignment: 'center' },
                    '', '',
                    { text: 'QUANTIDADE', fontSize: 12, bold: true, border: [true, true, true, true], alignment: 'center' },
                    { text: 'VALOR UNITÁRIO', fontSize: 12, bold: true, border: [true, true, true, true], alignment: 'center' },
                ]);

                conteudo.push([
                    { text: `${item.ordem}`, fontSize: 10, border: [true, true, true, true], alignment: 'center' },
                    { text: `${item.produto_unidade.produto.codigo ?? '-'}`, fontSize: 10, border: [true, true, true, true], alignment: 'center' },
                    { text: `${this.funcaoService.abreviarStr(produto.split('\r\n').join(' '), 80)}`, fontSize: 10, border: [true, true, true, true], colSpan: 3 },
                    '', '',
                    { text: `${item.quantidade_memorial}`, fontSize: 10, border: [true, true, true, true], alignment: 'center' },
                    { text: `${item['unidade'] ? item['unidade'] : '-'}`, fontSize: 10, border: [true, true, true, true], alignment: 'center' },
                ]);

                conteudo.push([
                    { text: '', border: [false, false, false, false], fontSize: 10, colSpan: 7, margin: [0, 5, 0, 5] },
                    '', '', '', '', '', ''
                ]);

                for (const itemL of item.itens) {
                    let produtoL = itemL.produto_unidade['produto_unidade'].produto.nome;

                    conteudo.push([
                        { text: 'ORDEM', fontSize: 12, bold: true, border: [true, true, true, true], alignment: 'center' },
                        { text: 'CÓDIGO', fontSize: 12, bold: true, border: [true, true, true, true], alignment: 'center' },
                        { text: 'DESCRIÇÃO', fontSize: 12, bold: true, border: [true, true, true, true], colSpan: 3, alignment: 'center' },
                        '', '',
                        { text: 'QUANTIDADE', fontSize: 12, bold: true, border: [true, true, true, true], alignment: 'center' },
                        { text: 'VALOR UNITÁRIO', fontSize: 12, bold: true, border: [true, true, true, true], alignment: 'center' },
                    ]);

                    conteudo.push([
                        { text: `${itemL.ordem ?? '-'}`, fontSize: 10, border: [true, true, true, true], alignment: 'center' },
                        { text: `${itemL.produto_unidade['produto_unidade'].produto.codigo ?? '-'}`, fontSize: 10, border: [true, true, true, true], alignment: 'center' },
                        { text: `${this.funcaoService.abreviarStr(produtoL?.split('\r\n').join(' '), 80)}`, fontSize: 10, border: [true, true, true, true], colSpan: 3 },
                        '', '',
                        { text: `${itemL.quantidade_memorial}`, fontSize: 10, border: [true, true, true, true], alignment: 'center' },
                        { text: `${itemL['unidade'] ? item['unidade'] : '-'}`, fontSize: 10, border: [true, true, true, true], alignment: 'center' },
                    ]);

                    conteudo = conteudo.concat(this.cotacoes(itemL));
                }
            } else {

                conteudo.push([
                    { text: 'ORDEM', fontSize: 12, bold: true, border: [true, true, true, true], alignment: 'center' },
                    { text: 'CÓDIGO', fontSize: 12, bold: true, border: [true, true, true, true], alignment: 'center' },
                    { text: 'DESCRIÇÃO', fontSize: 12, bold: true, border: [true, true, true, true], colSpan: 3, alignment: 'center' },
                    '', '',
                    { text: 'QUANTIDADE', fontSize: 12, bold: true, border: [true, true, true, true], alignment: 'center' },
                    { text: 'VALOR UNITÁRIO', fontSize: 12, bold: true, border: [true, true, true, true], alignment: 'center' },
                ]);
                conteudo.push([
                    { text: `${item.ordem}`, fontSize: 10, border: [true, true, true, true], alignment: 'center' },
                    { text: `${item.produto_unidade.produto.codigo ?? '-'}`, fontSize: 10, border: [true, true, true, true], alignment: 'center' },
                    { text: `${this.funcaoService.abreviarStr(produto.split('\r\n').join(' '), 80)}`, fontSize: 10, border: [true, true, true, true], colSpan: 3 },
                    '', '',
                    { text: `${item.quantidade_memorial}`, fontSize: 10, border: [true, true, true, true], alignment: 'center' },
                    { text: `${item['unidade'] ? item['unidade'] : '-'}`, fontSize: 10, border: [true, true, true, true], alignment: 'center' },
                ]);

                conteudo = conteudo.concat(this.cotacoes(item));
            }


        }

        return conteudo;
    }

    private cotacoes(item: EstimativaItemRpt): {}[] {
        const conteudo = [];

        let somaValorUnitario = 0
        let qntFornecedores = 0

        let listaFavorecidos: (RplCotacao | RcmsCotacao)[] = item.cotacoes.filter((v, i, a) => +a.findIndex(t => (+t.favorecido.favorecido.id === +v.favorecido.favorecido.id)) === i)
            .concat(item.cotacoesRcms.filter((v, i, a) => +a.findIndex(t => (+t.rcmsFavorecido.favorecido.id === +v.rcmsFavorecido.favorecido.id)) === i))

        listaFavorecidos = listaFavorecidos.map(cotacoes => {
            let cotacaoFavorecidos = [];
            let cotacaoRcms = [];
            if ('favorecido' in cotacoes) {
                cotacaoFavorecidos = item.cotacoes.filter(gf => +gf.favorecido.favorecido.id === +cotacoes.favorecido.favorecido.id)
            } else if ('rcmsFavorecido' in cotacoes) {
                cotacaoRcms = item.cotacoesRcms.filter(gf => +gf.rcmsFavorecido.favorecido.id === +cotacoes.rcmsFavorecido.favorecido.id)
            }
            
            const cotacao = cotacaoFavorecidos.concat(cotacaoRcms);
            let contador = 0;
            let valor_unitario = 0;
            cotacao.reduce((acc, interavel) => {
                if (interavel.valor_unitario > 0) {
                    contador++
                    return valor_unitario += +interavel.valor_unitario
                } else {
                    return valor_unitario
                }

            }, 0)
            cotacoes.valor_unitario = +valor_unitario / +contador;

            if (!cotacoes.valor_unitario) cotacoes.valor_unitario = 0;

            return cotacoes
        })

        for (const [idx, cotacao] of listaFavorecidos.entries()) {
            somaValorUnitario += +cotacao.valor_unitario;
            if (+cotacao.valor_unitario > 0)
                qntFornecedores++;

            let corDaLinha = (idx % 2) === 0 ? null : '#dee2e6'
            let nome_favorecido = '';
            if ('favorecido' in cotacao) {
                nome_favorecido = cotacao.favorecido.favorecido.nome
            } else if ('rcmsFavorecido' in cotacao) {
                nome_favorecido = cotacao.rcmsFavorecido.favorecido.nome
            }
            conteudo.push([
                { text: `${nome_favorecido}`, fontSize: 10, border: [true, false, false, false], colSpan: 5, fillColor: corDaLinha },
                '', '', '', '',
                { text: `${item.quantidade_memorial}`, fontSize: 10, border: [false, false, false, false], alignment: 'center', fillColor: corDaLinha },
                { text: `${this.funcaoService.convertToBrNumber(cotacao.valor_unitario, 4)}`, fontSize: 10, border: [false, false, true, false], alignment: 'center', fillColor: corDaLinha },
            ]);
        }

        let mediaUni = somaValorUnitario / qntFornecedores;
        let mediaTotal = mediaUni * +item.quantidade_memorial;

        conteudo.push([
            { text: `PREÇO MÉDIO UNITÁRIO: `, fontSize: 10, bold: true, border: [true, true, false, true], colSpan: 2 }, '',
            { text: `${this.funcaoService.convertToBrNumber(mediaUni, 4)}`, fontSize: 10, border: [false, true, false, true] },
            { text: `PREÇO MÉDIO TOTAL: `, fontSize: 10, bold: true, border: [false, true, false, true] },
            { text: `${this.funcaoService.convertToBrNumber(mediaTotal, 4)}`, fontSize: 10, border: [false, true, false, true], colSpan: 2 }, '',
            { text: '', border: [false, true, true, true] },
        ]);
        conteudo.push([
            { text: '', border: [false, false, false, false], fontSize: 10, colSpan: 7, margin: [0, 5, 0, 5] },
            '', '', '', '', '', ''
        ]);

        return conteudo;
    }

}