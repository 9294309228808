import { Directive, OnDestroy } from "@angular/core";
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, OrgaoAssinatura, OrgaoAssinaturaService, Relatorio } from "eddydata-lib";
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { QuadroSaudeService } from "../service/quadro-saude.service";

@Directive()
export class ProjecaoReceitaDespesa implements OnDestroy {

  protected unsubscribe: Subject<void> = new Subject();
  protected login: Login = new Login();
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private assinatura: OrgaoAssinatura;

  private percentualEstado: Map<number, number> = new Map();
  private percentualUniao: Map<number, number> = new Map();
  private estadoAnterior: Map<number, number> = new Map();
  private uniaoAnterior: Map<number, number> = new Map();
  private projecaoEstado: Map<number, number> = new Map();
  private projecaoUniao: Map<number, number> = new Map();

  private total_receita: number = 0;
  private total_empenhado: number = 0;
  private total_liquidado: number = 0;
  private total_empenhado_4: number = 0;
  private total_liquidado_4: number = 0;
  private total_projecao_ferias: number = 0;
  private total_pagto_restos: number = 0;
  private total_restos_aberto: number = 0;
  private total_empenhado_3390: number = 0;

  constructor(
    protected quadroServico: QuadroSaudeService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected exercicioAnterior: Exercicio,
    protected percentualFerias: number,
    protected percentual13: number,
    protected assinaturaService: OrgaoAssinaturaService
  ) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public manterCasas(num: number, casas: number) {
    let numAjustado = Math.pow(10, casas);
    return Math.floor(num * numAjustado) / numAjustado;
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
    this.quadroServico.obterProjecaoReceitaDespesa(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(
            'PROJEÇÃO DA RECEITA E DESPESA DA SAÚDE',
            this.login.usuario.nome,
            this.login.usuario.sobrenome,
            this.login.orgao.nome,
            this.login.brasao,
            this.conteudoReceita(dados[0])
              .concat(this.conteudoDespesa(dados[1], this.mes))
              .concat(this.conteudoDetalhes(dados)).concat(this.login.parametro?.contabil?.assinatura_por_funcao ? await this.conteudoAssinatura() : ''),
            'landscape',
            'PROJEÇÃO DA RECEITA E DESPESA DA SAÚDE',
            {
              linhas: {
                hLineWidth() { return 1 },
                vLineWidth() { return 1 },
                hLineColor() { return 'black' },
                paddingLeft() { return 3 },
                paddingRight() { return 3 }
              }
            }
          );
        } else {
          this.funcaoService.exportar(formato, this.normalizar(dados[0], dados[1], dados, this.mes), 'PROJEÇÃO DA RECEITA E DESPESA DA SAÚDE', this.colunas());
        }
      });
  }

  private conteudoReceita(dados: any[]): {}[] {
    const conteudo = [];
    const [proprios, transferencias_uniao, transferencias_estado, retencoes] = dados;

    conteudo.push([
      {
        text: `PROJEÇÃO RECEITA: REFERÊNCIA - ${this.globalService.obterMes(+this.mes)}/${this.exercicio.ano}`,
        bold: true, colSpan: 12, fontSize: 9, border: [false, false, false, false], margin: [0, -15, 0, 0]
      }, '', '', '', '', '', '', '', '', '', '', ''
    ]);
    conteudo.push([
      { text: 'MESES', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
      { text: 'RECEITA PRÓPRIA', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
      { text: '%', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
      { text: 'TRANSFERÊNCIA UNIÃO', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
      { text: '%', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
      { text: 'TRANSFERÊNCIA ESTADO', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
      { text: '%', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
      { text: 'TOTAL', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
    ]);
    conteudo.push([
      '',
      { text: this.exercicioAnterior.ano, alignment: 'center', bold: true, fontSize: 7 },
      { text: this.exercicio.ano, alignment: 'center', bold: true, fontSize: 7 }, '',
      { text: this.exercicioAnterior.ano, alignment: 'center', bold: true, fontSize: 7 },
      { text: this.exercicio.ano, alignment: 'center', bold: true, fontSize: 7 }, '',
      { text: this.exercicioAnterior.ano, alignment: 'center', bold: true, fontSize: 7 },
      { text: this.exercicio.ano, alignment: 'center', bold: true, fontSize: 7 }, '',
      { text: this.exercicioAnterior.ano, alignment: 'center', bold: true, fontSize: 7 },
      { text: this.exercicio.ano, alignment: 'center', bold: true, fontSize: 7 },
    ]);

    let totalProprioAnterior: number = 0;
    let totalProprioAtual: number = 0;
    let percentualProprio: number = 0;
    let totalUniaoAnterior: number = 0;
    let totalUniaoAtual: number = 0;
    let totalEstadoAnterior: number = 0;
    let totalEstadoAtual: number = 0;
    for (let i = 0; i < this.mes; i++) {
      totalProprioAnterior += proprios[i]?.['anterior'] ? +proprios[i]['anterior'] : 0;
      totalProprioAtual += proprios[i]?.['atual'] ? +proprios[i]['atual'] : 0;
      percentualProprio = ((totalProprioAtual / totalProprioAnterior) * 100) - 100;
      proprios[i] ? proprios[i]['percentual'] = percentualProprio : null;

      totalUniaoAnterior += transferencias_uniao[i]?.['anterior'] ? +transferencias_uniao[i]['anterior'] : 0;
      totalUniaoAtual += transferencias_uniao[i]?.['atual'] ? +transferencias_uniao[i]['atual'] : 0;
      this.percentualUniao.set(i, ((totalUniaoAtual / totalUniaoAnterior) * 100) - 100);
      transferencias_uniao[i] ? transferencias_uniao[i]['percentual'] = this.percentualUniao.get(i) : null;

      totalEstadoAnterior += transferencias_estado[i]?.['anterior'] ? +transferencias_estado[i]['anterior'] : 0;
      totalEstadoAtual += transferencias_estado[i]?.['atual'] ? +transferencias_estado[i]['atual'] : 0;
      this.percentualEstado.set(i, ((totalEstadoAtual / totalEstadoAnterior) * 100) - 100);
      transferencias_estado[i] ? transferencias_estado[i]['percentual'] = this.percentualEstado.get(i) : null;
    }

    for (let i = +this.mes; i < 12; i++) {
      if (proprios?.length) {
        const valorProprio = proprios[i]?.['anterior'];
        const projecaoProprio = (valorProprio * percentualProprio) / 100;
        proprios[i]['atual'] = valorProprio + projecaoProprio;
        proprios[i]['percentual'] = percentualProprio;
      }

      if (transferencias_uniao?.length) {
        const valorUniao = transferencias_uniao[i]?.['anterior'];
        const projecaoUniao = (valorUniao * this.percentualUniao.get(+this.mes - 1)) / 100;
        transferencias_uniao[i]['atual'] = valorUniao + projecaoUniao;
        transferencias_uniao[i]['percentual'] = this.percentualUniao.get(+this.mes - 1);
      }

      if (transferencias_estado?.length) {
        const valorEstado = transferencias_estado[i]?.['anterior'];
        const projecaoEstado = (valorEstado * this.percentualEstado.get(+this.mes - 1)) / 100;
        transferencias_estado[i]['atual'] = valorEstado + projecaoEstado;
        transferencias_estado[i]['percentual'] = this.percentualEstado.get(+this.mes - 1);
      }
    }

    for (let i = 0; i < 12; i++) {
      conteudo.push([
        { text: this.globalService.obterMes(i + 1), fontSize: 6, border: [true, false, true, false], alignment: 'left' },
        { text: this.funcaoService.convertToBrNumber(proprios[i]?.['anterior']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(proprios[i]?.['atual']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(proprios[i]?.['percentual'].toFixed(2)), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(transferencias_uniao[i]?.['anterior']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(transferencias_uniao[i]?.['atual']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(transferencias_uniao[i]?.['percentual'].toFixed(2)), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(transferencias_estado[i]?.['anterior']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(transferencias_estado[i]?.['atual']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(transferencias_estado[i]?.['percentual'].toFixed(2)), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(proprios[i]?.['anterior'] + transferencias_uniao[i]?.['anterior'] + transferencias_estado[i]?.['anterior']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(proprios[i]?.['atual'] + transferencias_uniao[i]?.['atual'] + transferencias_estado[i]?.['atual']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
      ]);

      this.total_receita += +proprios[i]?.['atual'] + +transferencias_uniao[i]?.['atual'] + +transferencias_estado[i]?.['atual'];
      this.estadoAnterior.set(i, +retencoes[i]?.['estado'] ?? 0);
      this.uniaoAnterior.set(i, +retencoes[i]?.['uniao'] ?? 0);
    }

    const totalizadorProprioAnterior = proprios.reduce((acumulador, receita) => acumulador += receita['anterior'], 0);
    const totalizadorProprioAtual = proprios.reduce((acumulador, receita) => acumulador += receita['atual'], 0);
    const totalizadorUniaoAnterior = transferencias_uniao.reduce((acumulador, receita) => acumulador += receita['anterior'], 0);
    const totalizadorUniaoAtual = transferencias_uniao.reduce((acumulador, receita) => acumulador += receita['atual'], 0);
    const totalizadorEstadoAnterior = transferencias_estado.reduce((acumulador, receita) => acumulador += receita['anterior'], 0);
    const totalizadorEstadoAtual = transferencias_estado.reduce((acumulador, receita) => acumulador += receita['atual'], 0);

    conteudo.push([
      { text: 'TOTAL', fontSize: 7, bold: true, alignment: 'left' },
      { text: this.funcaoService.convertToBrNumber(totalizadorProprioAnterior), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalizadorProprioAtual), fontSize: 7, bold: true, alignment: 'right' },
      { text: '' },
      { text: this.funcaoService.convertToBrNumber(totalizadorUniaoAnterior), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalizadorUniaoAtual), fontSize: 7, bold: true, alignment: 'right' },
      { text: '' },
      { text: this.funcaoService.convertToBrNumber(totalizadorEstadoAnterior), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalizadorEstadoAtual), fontSize: 7, bold: true, alignment: 'right' },
      { text: '' },
      { text: this.funcaoService.convertToBrNumber(totalizadorProprioAnterior + totalizadorEstadoAnterior + totalizadorUniaoAnterior), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalizadorProprioAtual + totalizadorEstadoAtual + totalizadorUniaoAtual), fontSize: 7, bold: true, alignment: 'right', }
    ]);

    return [{
      layout: 'linhas',
      table: {
        widths: ['*', '*', '*', 'auto', '*', '*', 'auto', '*', '*', 'auto', '*', '*'],
        body: conteudo
      }
    }];
  }

  private conteudoDespesa(dados: any[], mes: number) {
    const conteudo = [];
    const [despesas, { projecao_ferias: projecaoFerias }, { valor: pagtoResto }, { aberto: restosEmAberto }, { valor: empenhado3390 }] = dados;
    this.total_projecao_ferias = projecaoFerias;
    this.total_pagto_restos = +pagtoResto;
    this.total_restos_aberto = +restosEmAberto;
    this.total_empenhado_3390 = +empenhado3390;

    conteudo.push([
      { text: `PROJEÇÃO DESPESA: REFERÊNCIA - ${this.globalService.obterMes(+this.mes)}/${this.exercicio.ano}`, bold: true, colSpan: 13, fontSize: 9, border: [false, false, false, false] },
      '', '', '', '', '', '', '', '', '', '', '', ''
    ]);
    conteudo.push([
      { text: 'MESES', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
      { text: '3190 (exceto 31901143)', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
      { text: '31901143', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
      { text: '3350', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
      { text: '3390/3391', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
      { text: '4', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
      { text: 'TOTAL EMPENHADO', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
      { text: 'TOTAL LIQUIDADO', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 }
    ]);
    conteudo.push([
      '',
      { text: 'EMPENHADA', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'LIQUIDADA', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'EMPENHADA', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'LIQUIDADA', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'EMPENHADA', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'LIQUIDADA', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'EMPENHADA', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'LIQUIDADA', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'EMPENHADA', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'LIQUIDADA', alignment: 'center', bold: true, fontSize: 7 },
      '', ''
    ]);

    let empenhada_3190: number = 0;
    let liquidada_3190: number = 0;
    let empenhada_31901143: number = 0;
    let liquidada_31901143: number = 0;
    let empenhada_3350: number = 0;
    let liquidada_3350: number = 0;
    let empenhada_3390_3391: number = 0;
    let liquidada_3390_3391: number = 0;
    let projecao_3190: number = 0;
    let projecao_3350: number = 0;
    const projecao_despesas: any[] = [];
    for (let i = 0; i < 12; i++) {
      const despesa = despesas?.[i];
      if ((i + 1) <= +despesa?.['mes']) {
        empenhada_3190 += +despesa['empenhada_3190'];
        liquidada_3190 += +despesa['liquidada_3190'];
        empenhada_31901143 += +despesa['empenhada_31901143'];
        liquidada_31901143 += +despesa['liquidada_31901143'];
        empenhada_3350 += +despesa['empenhada_3350'];
        liquidada_3350 += +despesa['liquidada_3350'];
        empenhada_3390_3391 += +despesa['empenhada_3390_3391'];
        liquidada_3390_3391 += +despesa['liquidada_3390_3391'];
        this.total_empenhado_4 += +despesa['empenhada_4'];
        this.total_liquidado_4 += +despesa['liquidada_4'];
        this.total_empenhado += +despesa['empenhada_3190'] + +despesa['empenhada_31901143'] + +despesa['empenhada_3350'] + +despesa['empenhada_3390_3391'] + +despesa['empenhada_4'];
        this.total_liquidado += +despesa['liquidada_3190'] + +despesa['liquidada_31901143'] + +despesa['liquidada_3350'] + +despesa['liquidada_3390_3391'] + +despesa['liquidada_4'];
        conteudo.push([
          { text: this.globalService.obterMes(+despesa['mes']), fontSize: 6, border: [true, false, true, false], alignment: 'left' },
          { text: this.funcaoService.convertToBrNumber(despesa['empenhada_3190']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(despesa['liquidada_3190']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(despesa['empenhada_31901143']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(despesa['liquidada_31901143']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(despesa['empenhada_3350']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(despesa['liquidada_3350']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(despesa['empenhada_3390_3391']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(despesa['liquidada_3390_3391']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(despesa['empenhada_4']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(despesa['liquidada_4']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          {
            text: this.funcaoService.convertToBrNumber(+despesa['empenhada_3190'] + +despesa['empenhada_31901143'] + +despesa['empenhada_3350'] + +despesa['empenhada_3390_3391'] + +despesa['empenhada_4']),
            fontSize: 7, border: [true, false, true, false], alignment: 'right'
          },
          {
            text: this.funcaoService.convertToBrNumber(+despesa['liquidada_3190'] + +despesa['liquidada_31901143'] + +despesa['liquidada_3350'] + +despesa['liquidada_3390_3391'] + +despesa['liquidada_4']),
            fontSize: 7, border: [true, false, true, false], alignment: 'right'
          }
        ]);
      } else {
        projecao_3190 = liquidada_3190 / mes;
        if ((i + 1) === 12) {
          projecao_3190 += +projecaoFerias + (+projecaoFerias * this.percentualFerias) / 100;
          if (empenhada_3350 > liquidada_3350) {
            projecao_3350 = empenhada_3350 - liquidada_3350;
          }
        }

        projecao_despesas.push({
          mes: i + 1,
          empenhada_3190: 0,
          liquidada_3190: 0,
          empenhada_31901143: 0,
          liquidada_31901143: 0,
          empenhada_3350: 0,
          liquidada_3350: 0,
          empenhada_3390_3391: 0,
          liquidada_3390_3391: 0,
        });
      }
    }

    for (let i = +mes; i < 12; i++) {
      const despesa = projecao_despesas.find(item => +item.mes === (+i + 1));
      this.total_empenhado += +despesa['empenhada_3190'] + +despesa['empenhada_31901143'] + +despesa['empenhada_3350'] + +despesa['empenhada_3390_3391'];
      this.total_liquidado += +despesa['liquidada_3190'] + +despesa['liquidada_31901143'] + +despesa['liquidada_3350'] + +despesa['liquidada_3390_3391'];
      conteudo.push([
        { text: this.globalService.obterMes(+despesa['mes']) + (+despesa['mes'] === 12 ? ' *' : ''), fontSize: 6, border: [true, false, true, false], alignment: 'left' },
        { text: this.funcaoService.convertToBrNumber(despesa['empenhada_3190']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(despesa['liquidada_3190']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(despesa['empenhada_31901143']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(despesa['liquidada_31901143']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(despesa['empenhada_3350']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(despesa['liquidada_3350']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(despesa['empenhada_3390_3391']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(despesa['liquidada_3390_3391']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(0), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(0), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(+despesa['empenhada_3190'] + +despesa['empenhada_31901143'] + +despesa['empenhada_3350'] + +despesa['empenhada_3390_3391']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(+despesa['liquidada_3190'] + +despesa['liquidada_31901143'] + +despesa['liquidada_3350'] + +despesa['liquidada_3390_3391']), fontSize: 6, border: [true, false, true, false], alignment: 'right' }
      ]);
    }

    conteudo.push([
      { text: 'TOTAL', fontSize: 7, bold: true, alignment: 'left' },
      { text: this.funcaoService.convertToBrNumber(empenhada_3190 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['empenhada_3190'], 0)), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(liquidada_3190 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['liquidada_3190'], 0)), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(empenhada_31901143 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['empenhada_31901143'], 0)), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(liquidada_31901143 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['liquidada_31901143'], 0)), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(empenhada_3350 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['empenhada_3350'], 0)), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(liquidada_3350 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['liquidada_3350'], 0)), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(empenhada_3390_3391 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['empenhada_3390_3391'], 0)), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(liquidada_3390_3391 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['liquidada_3390_3391'], 0)), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(this.total_empenhado_4), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(this.total_liquidado_4), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(this.total_empenhado), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(this.total_liquidado), fontSize: 7, bold: true, alignment: 'right' }
    ]);

    return [{
      layout: 'linhas',
      table: {
        widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
        body: conteudo
      }
    }];
  }

  private conteudoDetalhes(dados: any[]) {
    const conteudo = [];

    conteudo.push([
      { text: 'Projeção Receita', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_receita, 2)), alignment: 'right', border: [false], fontSize: 6 },
      { text: 'Despesa Empenhada', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_empenhado, 2)), alignment: 'right', border: [false], fontSize: 6 },
      { text: 'Projeção Despesa Liquidada', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_liquidado, 2)), alignment: 'right', border: [false], fontSize: 6 },
      { text: 'Valor não liquidado de obra', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_empenhado_4 - this.total_liquidado_4, 2)), alignment: 'right', border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_empenhado_4 - this.total_liquidado_4, 2) / this.total_receita * 100) + '%', bold: true, border: [false], fontSize: 6 }
    ]);
    conteudo.push([
      { text: '15% aplicação obrigatória', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.manterCasas(+this.total_receita * 0.15, 2)), alignment: 'right', border: [false], fontSize: 6 },
      { text: '% Despesa Empenhada', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.manterCasas((+this.total_empenhado / +this.total_receita) * 100, 2)), alignment: 'right', border: [false], fontSize: 6 },
      { text: '% Despesa Liquidada', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.manterCasas((+this.total_liquidado / +this.total_receita) * 100, 2)), alignment: 'right', border: [false], fontSize: 6 },
      { text: 'Falta ou Sobra Despesa Liquidada', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.manterCasas(+this.total_liquidado - (+this.total_receita * 0.15), 2)), alignment: 'right', border: [false, false, false, false], fontSize: 6 },
      { text: '', border: [false] }
    ]);
    conteudo.push([
      { text: 'Pagto Resto a Pagar', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_pagto_restos, 2)), alignment: 'right', border: [false], fontSize: 6 },
      { text: '% Empenhada + Restos', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.manterCasas(((this.total_empenhado + this.total_pagto_restos) / this.total_receita) * 100, 2)), alignment: 'right', border: [false], fontSize: 6 },
      { text: '% Liquidada + Restos', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.manterCasas(((this.total_liquidado + this.total_pagto_restos) / this.total_receita) * 100, 2)), alignment: 'right', border: [false], fontSize: 6 },
      { text: 'Falta ou Sobra Desp. Liquidada + Restos', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_liquidado - (this.total_receita * 0.15) + this.total_pagto_restos, 2)), alignment: 'right', border: [false, false, false, false], fontSize: 6 },
      { text: '', border: [false] }
    ]);
    conteudo.push([
      { text: 'Restos a Pagar em aberto', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_restos_aberto, 2)), alignment: 'right', border: [false], fontSize: 6 },
      { text: '3390', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_empenhado_3390, 2)), alignment: 'right', border: [false], fontSize: 6 },
      { text: '4', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_empenhado_4, 2)), alignment: 'right', border: [false], fontSize: 6 },
      { text: 'Total Provisionado', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_restos_aberto + this.total_empenhado_3390 + this.total_empenhado_4, 2)), alignment: 'right', border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.manterCasas(((this.total_restos_aberto + this.total_empenhado_3390 + this.total_empenhado_4) / this.total_receita) * 100, 2)) + '%', bold: true, border: [false], fontSize: 6 }
    ]);
    conteudo.push([
      { text: '* Projeção de férias:', border: [false], fontSize: 6 },
      { text: `${this.funcaoService.convertToBrNumber(this.manterCasas(this.total_projecao_ferias, 2))} * ${this.manterCasas(this.percentualFerias, 2)}%`, border: [false, false, false, false], fontSize: 6, colSpan: 8 },
      '', '', '', '', '', '', ''
    ]);

    return [{
      layout: 'linhas',
      table: {
        widths: ['auto', '*', 'auto', '*', 'auto', '*', 'auto', '*', '*'],
        body: conteudo
      }
    }];
  }

  public async conteudoAssinatura() {
    this.assinatura = await this.assinaturaService.obter({ orgao_id: this.login.orgao.id }).toPromise();

    const assinatura = [
      [
        {
          text: '', alignment: 'center',
          border: [false, false, false, false], margin: [0, 30, 0, 0]
        },
        {
          text: this.assinatura.ordenador_despesa_funcao_10 ? '__________________________________________' : '', alignment: 'center',
          border: [false, false, false, false], margin: [0, 30, 0, 0]
        },
        {
          text: '', alignment: 'center',
          border: [false, false, false, false], margin: [0, 30, 0, 0]
        }
      ],
      [
        { text: '', alignment: 'center', border: [false, false, false, false] },
        { text: this.assinatura.ordenador_despesa_funcao_10 ? this.assinatura.ordenador_despesa_funcao_10 : '', alignment: 'center', border: [false, false, false, false] },
        { text: '', alignment: 'center', border: [false, false, false, false] }
      ],
      [
        { text: '', alignment: 'center', border: [false, false, false, false] },
        { text: this.assinatura.cargo_ordenador_despesa_funcao_10 ? this.assinatura.cargo_ordenador_despesa_funcao_10 : '', alignment: 'center', border: [false, false, false, false] },
        { text: '', alignment: 'center', border: [false, false, false, false] }
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [10, 0],
        widths: ['*', '*', '*'],
        body: assinatura
      }
    }];
  }

  public normalizar(lista1: any[], lista2: any[], lista: any[], mes: number) {
    const listaExportar = []

    const [proprios, transferencias_uniao, transferencias_estado, retencoes] = lista1;

    let totalProprioAnterior: number = 0;
    let totalProprioAtual: number = 0;
    let percentualProprio: number = 0;
    let totalUniaoAnterior: number = 0;
    let totalUniaoAtual: number = 0;
    let totalEstadoAnterior: number = 0;
    let totalEstadoAtual: number = 0;
    for (let i = 0; i < this.mes; i++) {
      totalProprioAnterior += proprios[i]['anterior'];
      totalProprioAtual += proprios[i]['atual'];
      percentualProprio = ((totalProprioAtual / totalProprioAnterior) * 100) - 100;
      proprios[i]['percentual'] = percentualProprio;

      totalUniaoAnterior += transferencias_uniao[i]?.['anterior'];
      totalUniaoAtual += transferencias_uniao[i]?.['atual'];
      this.percentualUniao.set(i, ((totalUniaoAtual / totalUniaoAnterior) * 100) - 100);
      transferencias_uniao[i] ? transferencias_uniao[i]['percentual'] = this.percentualUniao.get(i) : null;

      totalEstadoAnterior += transferencias_estado[i]?.['anterior'];
      totalEstadoAtual += transferencias_estado[i]?.['atual'];
      this.percentualEstado.set(i, ((totalEstadoAtual / totalEstadoAnterior) * 100) - 100);
      transferencias_estado[i] ? transferencias_estado[i]['percentual'] = this.percentualEstado.get(i) : null;
    }

    for (let i = +this.mes; i < 12; i++) {
      if (proprios?.length) {
        const valorProprio = proprios[i]?.['anterior'];
        const projecaoProprio = (valorProprio * percentualProprio) / 100;
        proprios[i]['atual'] = valorProprio + projecaoProprio;
        proprios[i]['percentual'] = percentualProprio;
      }

      if (transferencias_uniao?.length) {
        const valorUniao = transferencias_uniao[i]?.['anterior'];
        const projecaoUniao = (valorUniao * this.percentualUniao.get(+this.mes - 1)) / 100;
        transferencias_uniao[i]['atual'] = valorUniao + projecaoUniao;
        transferencias_uniao[i]['percentual'] = this.percentualUniao.get(+this.mes - 1);
      }

      if (transferencias_estado?.length) {
        const valorEstado = transferencias_estado[i]?.['anterior'];
        const projecaoEstado = (valorEstado * this.percentualEstado.get(+this.mes - 1)) / 100;
        transferencias_estado[i]['atual'] = valorEstado + projecaoEstado;
        transferencias_estado[i]['percentual'] = this.percentualEstado.get(+this.mes - 1);
      }
    }

    const ano = {
      col1: '',
      col2: this.exercicioAnterior.ano,
      col3: this.exercicio.ano,
      col4: '',
      col5: this.exercicioAnterior.ano,
      col6: this.exercicio.ano,
      col7: '',
      col8: this.exercicioAnterior.ano,
      col9: this.exercicio.ano,
      col10: '',
      col11: this.exercicioAnterior.ano,
      col12: this.exercicio.ano,
      col13: '',
    }
    listaExportar.push(ano)

    const descricao_receita = {
      col1: `PROJEÇÃO RECEITA: REFERÊNCIA - ${this.globalService.obterMes(+this.mes)}/${this.exercicio.ano}`,
      col2: '',
      col3: '',
      col4: '',
      col5: '',
      col6: '',
      col7: '',
      col8: '',
      col9: '',
      col10: '',
      col11: '',
      col12: '',
      col13: '',
    }
    listaExportar.push(descricao_receita)

    for (let i = 0; i < 12; i++) {
      const dados_receita = {
        col1: this.globalService.obterMes(proprios[i]?.['mes']),
        col2: this.funcaoService.convertToBrNumber(proprios[i]?.['anterior']),
        col3: this.funcaoService.convertToBrNumber(proprios[i]?.['atual']),
        col4: this.funcaoService.convertToBrNumber(proprios[i]?.['percentual'].toFixed(2)),
        col5: this.funcaoService.convertToBrNumber(transferencias_uniao[i]?.['anterior']),
        col6: this.funcaoService.convertToBrNumber(transferencias_uniao[i]?.['atual']),
        col7: this.funcaoService.convertToBrNumber(transferencias_uniao[i]?.['percentual'].toFixed(2)),
        col8: this.funcaoService.convertToBrNumber(transferencias_estado[i]?.['anterior']),
        col9: this.funcaoService.convertToBrNumber(transferencias_estado[i]?.['atual']),
        col10: this.funcaoService.convertToBrNumber(transferencias_estado[i]?.['percentual'].toFixed(2)),
        col11: this.funcaoService.convertToBrNumber(proprios[i]?.['anterior'] + transferencias_uniao[i]?.['anterior'] + transferencias_estado[i]?.['anterior']),
        col12: this.funcaoService.convertToBrNumber(proprios[i]?.['atual'] + transferencias_uniao[i]?.['atual'] + transferencias_estado[i]?.['atual']),
        col13: ''
      }
      this.total_receita += +proprios[i]['atual'] + +transferencias_uniao[i]?.['atual'] + +transferencias_estado[i]?.['atual'];
      this.estadoAnterior.set(i, +retencoes[i]?.['estado'] ?? 0);
      this.uniaoAnterior.set(i, +retencoes[i]?.['uniao'] ?? 0);

      listaExportar.push(dados_receita)
    }

    const totalizadorProprioAnterior = proprios.reduce((acumulador, receita) => acumulador += receita['anterior'], 0);
    const totalizadorProprioAtual = proprios.reduce((acumulador, receita) => acumulador += receita['atual'], 0);
    const totalizadorUniaoAnterior = transferencias_uniao.reduce((acumulador, receita) => acumulador += receita['anterior'], 0);
    const totalizadorUniaoAtual = transferencias_uniao.reduce((acumulador, receita) => acumulador += receita['atual'], 0);
    const totalizadorEstadoAnterior = transferencias_estado.reduce((acumulador, receita) => acumulador += receita['anterior'], 0);
    const totalizadorEstadoAtual = transferencias_estado.reduce((acumulador, receita) => acumulador += receita['atual'], 0);

    const total_receita = {
      col1: 'TOTAL',
      col2: this.funcaoService.convertToBrNumber(totalizadorProprioAnterior),
      col3: this.funcaoService.convertToBrNumber(totalizadorProprioAtual),
      col4: '',
      col5: this.funcaoService.convertToBrNumber(totalizadorUniaoAnterior),
      col6: this.funcaoService.convertToBrNumber(totalizadorUniaoAtual),
      col7: '',
      col8: this.funcaoService.convertToBrNumber(totalizadorEstadoAnterior),
      col9: this.funcaoService.convertToBrNumber(totalizadorEstadoAtual),
      col10: '',
      col11: this.funcaoService.convertToBrNumber(totalizadorProprioAnterior + totalizadorEstadoAnterior + totalizadorUniaoAnterior),
      col12: this.funcaoService.convertToBrNumber(totalizadorProprioAtual + totalizadorEstadoAtual + totalizadorUniaoAtual),
      col13: ''
    }
    listaExportar.push(total_receita)

    const [despesas, { projecao_ferias: projecaoFerias }, { valor: pagtoResto }, { aberto: restosEmAberto }, { valor: empenhado3390 }] = lista2;
    this.total_projecao_ferias = projecaoFerias;
    this.total_pagto_restos = +pagtoResto;
    this.total_restos_aberto = +restosEmAberto;
    this.total_empenhado_3390 = +empenhado3390;

    const espaco = {
      col1: '',
      col2: '',
      col3: '',
      col4: '',
      col5: '',
      col6: '',
      col7: '',
      col8: '',
      col9: '',
      col10: '',
      col11: '',
      col12: '',
      col13: ''
    }
    listaExportar.push(espaco)

    const descricao_despesa = {
      col1: `PROJEÇÃO DESPESA: REFERÊNCIA - ${this.globalService.obterMes(+this.mes)}/${this.exercicio.ano}`,
      col2: '',
      col3: '',
      col4: '',
      col5: '',
      col6: '',
      col7: '',
      col8: '',
      col9: '',
      col10: '',
      col11: '',
      col12: '',
      col13: '',
    }
    listaExportar.push(descricao_despesa)

    const titulo_despesa = {
      col1: 'MESES',
      col2: '3190 (exceto 31901143)',
      col3: '3190 (exceto 31901143)',
      col4: '31901143',
      col5: '31901143',
      col6: '3350',
      col7: '3350',
      col8: '3390/3391',
      col9: '3390/3391',
      col10: '4',
      col11: '4',
      col12: 'TOTAL EMPENHADO',
      col13: 'TOTAL LIQUIDADO',
    }
    listaExportar.push(titulo_despesa)

    const subtitulo_despesa = {
      col1: '',
      col2: 'EMPENHADA',
      col3: 'LIQUIDADA',
      col4: 'EMPENHADA',
      col5: 'LIQUIDADA',
      col6: 'EMPENHADA',
      col7: 'LIQUIDADA',
      col8: 'EMPENHADA',
      col9: 'LIQUIDADA',
      col10: 'EMPENHADA',
      col11: 'LIQUIDADA',
      col12: '',
      col13: '',
    }
    listaExportar.push(subtitulo_despesa)

    let empenhada_3190: number = 0;
    let liquidada_3190: number = 0;
    let empenhada_31901143: number = 0;
    let liquidada_31901143: number = 0;
    let empenhada_3350: number = 0;
    let liquidada_3350: number = 0;
    let empenhada_3390_3391: number = 0;
    let liquidada_3390_3391: number = 0;
    let projecao_3190: number = 0;
    let projecao_3350: number = 0;
    const projecao_despesas: any[] = [];
    for (let i = 0; i < 12; i++) {
      const despesa = despesas?.[i];
      if ((i + 1) <= +despesa?.['mes']) {
        empenhada_3190 += +despesa['empenhada_3190'];
        liquidada_3190 += +despesa['liquidada_3190'];
        empenhada_31901143 += +despesa['empenhada_31901143'];
        liquidada_31901143 += +despesa['liquidada_31901143'];
        empenhada_3350 += +despesa['empenhada_3350'];
        liquidada_3350 += +despesa['liquidada_3350'];
        empenhada_3390_3391 += +despesa['empenhada_3390_3391'];
        liquidada_3390_3391 += +despesa['liquidada_3390_3391'];
        this.total_empenhado_4 += +despesa['empenhada_4'];
        this.total_liquidado_4 += +despesa['liquidada_4'];
        this.total_empenhado += +despesa['empenhada_3190'] + +despesa['empenhada_31901143'] + +despesa['empenhada_3350'] + +despesa['empenhada_3390_3391'] + +despesa['empenhada_4'];
        this.total_liquidado += +despesa['liquidada_3190'] + +despesa['liquidada_31901143'] + +despesa['liquidada_3350'] + +despesa['liquidada_3390_3391'] + +despesa['liquidada_4'];
        const tipo_emp_espesa = {
          col1: this.globalService.obterMes(+despesa['mes']),
          col2: this.funcaoService.convertToBrNumber(despesa['empenhada_3190']),
          col3: this.funcaoService.convertToBrNumber(despesa['liquidada_3190']),
          col4: this.funcaoService.convertToBrNumber(despesa['empenhada_31901143']),
          col5: this.funcaoService.convertToBrNumber(despesa['liquidada_31901143']),
          col6: this.funcaoService.convertToBrNumber(despesa['empenhada_3350']),
          col7: this.funcaoService.convertToBrNumber(despesa['liquidada_3350']),
          col8: this.funcaoService.convertToBrNumber(despesa['empenhada_3390_3391']),
          col9: this.funcaoService.convertToBrNumber(despesa['liquidada_3390_3391']),
          col10: this.funcaoService.convertToBrNumber(despesa['empenhada_4']),
          col11: this.funcaoService.convertToBrNumber(despesa['liquidada_4']),
          col12: this.funcaoService.convertToBrNumber(+despesa['empenhada_3190'] + +despesa['empenhada_31901143'] + +despesa['empenhada_3350'] + +despesa['empenhada_3390_3391'] + +despesa['empenhada_4']),
          col13: this.funcaoService.convertToBrNumber(+despesa['liquidada_3190'] + +despesa['liquidada_31901143'] + +despesa['liquidada_3350'] + +despesa['liquidada_3390_3391'] + +despesa['liquidada_4']),
        }
        listaExportar.push(tipo_emp_espesa)
      } else {
        projecao_3190 = liquidada_3190 / mes;
        if ((i + 1) === 12) {
          projecao_3190 += +projecaoFerias + (+projecaoFerias * this.percentualFerias) / 100;
          if (empenhada_3350 > liquidada_3350) {
            projecao_3350 = empenhada_3350 - liquidada_3350;
          }
        }

        projecao_despesas.push({
          mes: i + 1,
          empenhada_3190: 0,
          liquidada_3190: 0,
          empenhada_31901143: 0,
          liquidada_31901143: 0,
          empenhada_3350: 0,
          liquidada_3350: 0,
          empenhada_3390_3391: 0,
          liquidada_3390_3391: 0,
        });
      }
    }

    for (let i = +mes; i < 12; i++) {
      const despesa = projecao_despesas.find(item => +item.mes === (+i + 1));
      this.total_empenhado += +despesa['empenhada_3190'] + +despesa['empenhada_31901143'] + +despesa['empenhada_3350'] + +despesa['empenhada_3390_3391'];
      this.total_liquidado += +despesa['liquidada_3190'] + +despesa['liquidada_31901143'] + +despesa['liquidada_3350'] + +despesa['liquidada_3390_3391'];
      const tipo_emp_espesa = {
        col1: this.globalService.obterMes(+despesa['mes']) + (+despesa['mes'] === 12 ? ' *' : ''),
        col2: this.funcaoService.convertToBrNumber(despesa['empenhada_3190']),
        col3: this.funcaoService.convertToBrNumber(despesa['liquidada_3190']),
        col4: this.funcaoService.convertToBrNumber(despesa['empenhada_31901143']),
        col5: this.funcaoService.convertToBrNumber(despesa['liquidada_31901143']),
        col6: this.funcaoService.convertToBrNumber(despesa['empenhada_3350']),
        col7: this.funcaoService.convertToBrNumber(despesa['liquidada_3350']),
        col8: this.funcaoService.convertToBrNumber(despesa['empenhada_3390_3391']),
        col9: this.funcaoService.convertToBrNumber(despesa['liquidada_3390_3391']),
        col10: this.funcaoService.convertToBrNumber(0),
        col11: this.funcaoService.convertToBrNumber(0),
        col12: this.funcaoService.convertToBrNumber(+despesa['empenhada_3190'] + +despesa['empenhada_31901143'] + +despesa['empenhada_3350'] + +despesa['empenhada_3390_3391'] + +despesa['empenhada_4']),
        col13: this.funcaoService.convertToBrNumber(+despesa['liquidada_3190'] + +despesa['liquidada_31901143'] + +despesa['liquidada_3350'] + +despesa['liquidada_3390_3391'] + +despesa['liquidada_4']),
      }
      listaExportar.push(tipo_emp_espesa)
    }

    const tipo_emp_espesa = {
      col1: 'TOTAL',
      col2: this.funcaoService.convertToBrNumber(empenhada_3190 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['empenhada_3190'], 0)),
      col3: this.funcaoService.convertToBrNumber(empenhada_3190 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['empenhada_3190'], 0)),
      col4: this.funcaoService.convertToBrNumber(empenhada_31901143 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['empenhada_31901143'], 0)),
      col5: this.funcaoService.convertToBrNumber(empenhada_31901143 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['empenhada_31901143'], 0)),
      col6: this.funcaoService.convertToBrNumber(empenhada_3350 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['empenhada_3350'], 0)),
      col7: this.funcaoService.convertToBrNumber(empenhada_3350 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['empenhada_3350'], 0)),
      col8: this.funcaoService.convertToBrNumber(empenhada_3390_3391 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['empenhada_3390_3391'], 0)),
      col9: this.funcaoService.convertToBrNumber(empenhada_3390_3391 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['empenhada_3390_3391'], 0)),
      col10: this.funcaoService.convertToBrNumber(this.total_empenhado_4),
      col11: this.funcaoService.convertToBrNumber(this.total_empenhado_4),
      col12: this.funcaoService.convertToBrNumber(this.total_empenhado),
      col13: this.funcaoService.convertToBrNumber(this.total_empenhado)
    }
    listaExportar.push(tipo_emp_espesa)

    const espaco1 = {
      col1: '',
      col2: '',
      col3: '',
      col4: '',
      col5: '',
      col6: '',
      col7: '',
      col8: '',
      col9: '',
      col10: '',
      col11: '',
      col12: '',
      col13: ''
    }
    listaExportar.push(espaco1)

    const linha_detalhes1 = {
      col1: 'Projeção Receita',
      col2: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_receita, 2)),
      col3: 'Despesa Empenhada',
      col4: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_empenhado, 2)),
      col5: 'Projeção Despesa Liquidada',
      col6: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_liquidado, 2)),
      col7: 'Valor não liquidado de obra',
      col8: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_empenhado_4 - this.total_liquidado_4, 2)),
      col9: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_empenhado_4 - this.total_liquidado_4, 2) / this.total_receita * 100) + '%',
      col10: '',
      col11: '',
      col12: '',
      col13: ''
    }
    listaExportar.push(linha_detalhes1)

    const linha_detalhes2 = {
      col1: '15% aplicação obrigatória',
      col2: this.funcaoService.convertToBrNumber(this.manterCasas(+this.total_receita * 0.15, 2)),
      col3: '% Despesa Empenhada',
      col4: this.funcaoService.convertToBrNumber(this.manterCasas((+this.total_empenhado / +this.total_receita) * 100, 2)),
      col5: '% Despesa Liquidada',
      col6: this.funcaoService.convertToBrNumber(this.manterCasas((+this.total_liquidado / +this.total_receita) * 100, 2)),
      col7: 'Falta ou Sobra Despesa Liquidada',
      col8: this.funcaoService.convertToBrNumber(this.manterCasas(+this.total_liquidado - (+this.total_receita * 0.15), 2)),
      col9: '',
      col10: '',
      col11: '',
      col12: '',
      col13: ''
    }
    listaExportar.push(linha_detalhes2)

    const linha_detalhes3 = {
      col1: 'Pagto Resto a Pagar',
      col2: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_pagto_restos, 2)),
      col3: '% Empenhada + Restos',
      col4: this.funcaoService.convertToBrNumber(this.manterCasas(((this.total_empenhado + this.total_pagto_restos) / this.total_receita) * 100, 2)),
      col5: '% Liquidada + Restos',
      col6: this.funcaoService.convertToBrNumber(this.manterCasas(((this.total_liquidado + this.total_pagto_restos) / this.total_receita) * 100, 2)),
      col7: 'Falta ou Sobra Desp. Liquidada + Restos',
      col8: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_liquidado - (this.total_receita * 0.15) + this.total_pagto_restos, 2)),
      col9: '',
      col10: '',
      col11: '',
      col12: '',
      col13: ''
    }
    listaExportar.push(linha_detalhes3)

    const linha_detalhes4 = {
      col1: 'Restos a Pagar em aberto',
      col2: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_restos_aberto, 2)),
      col3: '3390',
      col4: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_empenhado_3390, 2)),
      col5: '4',
      col6: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_empenhado_4, 2)),
      col7: 'Total Provisionado',
      col8: this.funcaoService.convertToBrNumber(this.manterCasas(this.total_restos_aberto + this.total_empenhado_3390 + this.total_empenhado_4, 2)),
      col9: this.funcaoService.convertToBrNumber(this.manterCasas(((this.total_restos_aberto + this.total_empenhado_3390 + this.total_empenhado_4) / this.total_receita) * 100, 2)) + '%',
      col10: '',
      col11: '',
      col12: '',
      col13: ''
    }
    listaExportar.push(linha_detalhes4)

    const linha_detalhes5 = {
      col1: '* Projeção de férias:',
      col2: `${this.funcaoService.convertToBrNumber(this.manterCasas(this.total_projecao_ferias, 2))} * ${this.manterCasas(this.percentualFerias, 2)}%`,
      col3: '',
      col4: '',
      col5: '',
      col6: '',
      col7: '',
      col8: '',
      col9: '',
      col10: '',
      col11: '',
      col12: '',
      col13: ''
    }
    listaExportar.push(linha_detalhes5)

    return listaExportar
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'MESES', coluna: 'col1' },
      { titulo: 'RECEITA PRÓPRIA ANTERIOR', coluna: 'col2' },
      { titulo: 'RECEITA PRÓPRIA ATUAL', coluna: 'col3' },
      { titulo: '%', coluna: 'col4' },
      { titulo: 'TRANSFERÊNCIA UNIÃO ANTERIOR', coluna: 'col5' },
      { titulo: 'TRANSFERÊNCIA UNIÃO ATUAL', coluna: 'col6' },
      { titulo: '%', coluna: 'col7' },
      { titulo: 'TRANSFERÊNCIA ESTADO ANTERIOR', coluna: 'col8' },
      { titulo: 'TRANSFERÊNCIA ESTADO ATUAL', coluna: 'col9' },
      { titulo: '%', coluna: 'col10' },
      { titulo: 'TOTAL ANTERIOR', coluna: 'col11' },
      { titulo: 'TOTAL ATUAL', coluna: 'col12' },
      { titulo: '', coluna: 'col13' }
    ];
    return colunasDefault;
  }
}
