// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Banco } from './banco.model';
import { Orgao } from '../comum/orgao.model';

export class ChequeLayout extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public tamanho_valor?: number,
    public margem_valor?: number,
    public tamanho_extenso1?: number,
    public margem_extenso1?: number,
    public tamanho_extenso2?: number,
    public margem_extenso2?: number,
    public tamanho_beneficiario?: number,
    public margem_beneficiario?: number,
    public tamanho_cidade?: number,
    public margem_cidade?: number,
    public tamanho_dia?: number,
    public margem_dia?: number,
    public tamanho_mes?: number,
    public margem_mes?: number,
    public tamanho_ano?: number,
    public margem_ano?: number,
    public banco?: Banco,
    public orgao?: Orgao,
    public margem_x?: number,
    public margem_y?: number,
    public ano_completo?: boolean
  ) {
    super();
  }

  static converteJson(json: any): ChequeLayout {
    return Object.assign(new ChequeLayout(), json);
  }
}
