import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "eddydata-lib";
import { ParametroRequisicaoFormComponent } from "./parametro-requisicao-form/parametro-requisicao-form.component";
import { ParametroRequisicaoViewComponent } from "./parametro-requisicao-view/parametro-requisicao-view.component";

const routes: Routes = [
  {
    path: "novo",
    component: ParametroRequisicaoFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ":id/editar",
    component: ParametroRequisicaoFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ":id/visualizar",
    component: ParametroRequisicaoViewComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ParametroRequisicaoRoutingModule {}
