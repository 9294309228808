import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class Prazo extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public intervalo?: string,
    public intervalo_qtd?: number,
    public aux?: number,
    public entrega?: boolean,
    public pagamento?: boolean,
    public orgao?: Orgao,
  ) {
    super();
  }
  static converteJson(json: any): Prazo {
    return Object.assign(new Prazo(), json);
  }
}
