import { Component, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BaseResourceFormComponent, PagamentoResto, LoginContabil,OrgaoAssinaturaService,
  GlobalService, DateFormatPipe
} from 'eddydata-lib';
import { PagamentoRestoService } from 'administrativo-lib';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { NotaPagamentoResto } from '../../relatorio-tesouraria/notas/nota-pagamento-resto';
import { LiquidacaoRestoService } from '../../../liquidacao-resto/service/liquidacao-resto.service';

@Component({
  selector: 'app-pagamento-resto-view',
  templateUrl: './pagamento-resto-view.component.html'
})
export class PagamentoRestoViewComponent extends BaseResourceFormComponent<PagamentoResto, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public especie: string;
  public liquidado: number;
  public saldo: number;
  public ano: number;
  public liquidadoParcela: number = 0;
  public pagoParcela: number = 0;
  public pagoRetencao: number = 0;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private messageService: MessageService,
    protected liquidacaoService: LiquidacaoRestoService,
    protected injector: Injector,
    protected globalService: GlobalService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected pagamentoService: PagamentoRestoService) {
    super(new PagamentoResto(), injector, PagamentoResto.converteJson, pagamentoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      valor_pago: 0,
      valor_retido: [null, [Validators.required]],
      data_pagamento: [null, [Validators.required]],
      especie: [null, [Validators.required]],
      documento: [null, [Validators.required]],
      impresso: [null, [Validators.required]],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      anulacao: [null, [Validators.required]],
      conta: [null, [Validators.required]],
      exercicio: this.fb.group({
        ano: [null, [Validators.required]],
      }),
      orgao: [null, [Validators.required]],
      liquidacao: this.fb.group({
        data_vencimento: [null, [Validators.required]],
        valor_liquidado: [null, [Validators.required]],
        parcela: [null, [Validators.required]],
        empenho: this.fb.group({
          numero: [null, [Validators.required]],
          ano: [null, [Validators.required]],
          exercicio: this.fb.group({
            ano: [null, [Validators.required]],
          }),
        })
      })
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'conta,conta.banco,liquidacao,liquidacao.empenho,liquidacao.empenho.favorecido,' +
        'exercicio,orgao'
    };
  }

  protected afterLoad() {
    this.entidade.data_pagamento = new DateFormatPipe().transform(this.entidade.data_pagamento, []);
    this.buscarTotalLiquidado(this.entidade.liquidacao.empenho.numero, this.entidade.liquidacao.empenho.ano, this.entidade.liquidacao.parcela);
  }

  protected afterInit(): void {
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: PagamentoResto): void {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public buscarTotalLiquidado(numero: number, ano: number, parcela: number) {
    this.liquidacaoService.obterTotalLiquidadoPorEmpenho(numero, ano, parcela, this.login.orgao.id, null, this.login.exercicio.id).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (res: any) => {
            this.liquidado = res.liquidado;
            this.liquidadoParcela = +res.liquidado;
            this.buscarTotalPago(numero, ano, parcela);
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
  }

  public buscarTotalPago(numero: number, ano: number, parcela: number) {
    this.pagamentoService.obterTotalPagoPorEmpenho(numero, ano, parcela, this.login.exercicio.id, this.login.orgao.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res: any) => {
          this.pagoParcela = +res.total_pago;
          this.pagoRetencao = +res.total_pago_retencao;
          this.saldo = +this.liquidadoParcela.toFixed(2) - +this.pagoParcela.toFixed(2) - +this.pagoRetencao;
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
      );
  }

  imprimir() {
    const parametros = {};

    let relations = 'liquidacao.empenho.contrato.licitacao.modalidade,'
    relations += 'liquidacao.empenho.contrato,liquidacao.empenho.favorecido.contas.banco,liquidacao.empenho.convenio,liquidacao.empenho.favorecido';

    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id']=this.entidade.id;

    this.pagamentoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        new NotaPagamentoResto(this.assinaturaService).imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

}
