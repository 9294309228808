import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { TransferenciaEntreOrgaoLstComponent } from './transferencia-entre-orgao-lst/transferencia-entre-orgao-lst.component';
import { TransferenciaEntreOrgaoShwComponent } from './transferencia-entre-orgao-shw/transferencia-entre-orgao-shw.component';
import { TransferenciaBancariaRoutingModule } from './transferencia-bancaria-routing.module';


@NgModule({
  declarations: [
    TransferenciaEntreOrgaoLstComponent,
    TransferenciaEntreOrgaoShwComponent,
  ],
  imports: [
    CommonModule,
    TransferenciaBancariaRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
  ]
})
export class TransferenciaBancariaModule { }
