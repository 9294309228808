import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ContratoFiscal } from 'eddydata-lib';

@Injectable({
    providedIn: 'root'
})
export class ContratoFiscalService extends BaseResourceService<ContratoFiscal> {

    constructor(
        protected injector: Injector
    ) {
        super(`contrato-fiscais`, injector);
    }

}
