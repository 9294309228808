import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { Convenio } from './convenio.model';

// tslint:disable: variable-name
export class ConvenioAditamento extends BaseResourceModel {
  constructor(
    public finalidade?: string,
    public descricao?: string,
    public data_aditamento?: Date,
    public data_inicio?: Date,
    public data_termino?: Date,
    public data_contabilizacao?: Date,
    public valor_transferencia?: number,
    public valor_contrapartida?: number,
    public valor_total?: number,
    public convenio?: Convenio,
    public usuario?: Usuario,
    public ordem?: number,
    public editavel?: boolean
    ) {
    super();
  }
  static converteJson(json: any): ConvenioAditamento {
    return Object.assign(new ConvenioAditamento(), json);
  }
}
