import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';

export class RecursoSiconfiAudesp extends BaseResourceModel {
  constructor(
    public id?: number,
    public recurso?: string,
    public aplicacao?: String,
    public variavel?: String,
    public recurso_siconfi?: String,
    public complemento_siconfi?: String,
    public exercicio?: Exercicio,
    public ativo?: boolean
    ) {
    super();
  }
  static converteJson(json: any): RecursoSiconfiAudesp {
    return Object.assign(new RecursoSiconfiAudesp(), json);
  }
}