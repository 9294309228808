import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { IMaskModule } from 'angular-imask';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CommonModule } from '@angular/common';
import { OrgaoRoutingModule } from './orgao-routing.module';
import { InputMaskModule } from 'primeng/inputmask';
import { ToastModule } from 'primeng/toast';
import { OrgaoLstComponent } from './orgao-lst/orgao-lst.component';
import { OrgaoShwComponent } from './orgao-shw/orgao-shw.component';
import { SharedModule } from '../util/shared.module';
import { OrgaoConfigracaoShwComponent } from './orgao-configuracao-shw/orgao-configuracao-shw.component';
import { OrgaoAssinaturaComponent } from './orgao-assinatura/orgao-assinatura.component';
import { OrgaoSuplementacaoComponent } from './orgao-suplementacao/orgao-suplementacao.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CalendarModule } from 'primeng/calendar';
import { FieldsetModule } from 'primeng/fieldset';
import { OrgaoViewComponent } from './orgao-view/orgao-view.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TrocarOrgaoComponent } from './trocar-orgao/trocar-orgao.component';
import { OrgaoIntegracaoShwComponent } from './orgao-integracao-shw/orgao-integracao-shw.component';

@NgModule({
  declarations: [OrgaoLstComponent, OrgaoShwComponent, OrgaoAssinaturaComponent, OrgaoSuplementacaoComponent, OrgaoConfigracaoShwComponent, OrgaoViewComponent, TrocarOrgaoComponent, OrgaoIntegracaoShwComponent],
  imports: [
    CommonModule,
    OrgaoRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    ImageCropperModule,
    IMaskModule,
    InputMaskModule,
    ToastModule,
    ConfirmDialogModule,
    CalendarModule,
    FieldsetModule,
    RadioButtonModule,
    DropdownModule,
    InputSwitchModule
  ],
  exports: [
    OrgaoLstComponent,
    OrgaoShwComponent,
    TrocarOrgaoComponent
  ],
  providers: [MessageService, ConfirmationService]
})
export class OrgaoModule { }
