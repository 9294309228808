import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, InventarioTombo } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class InventarioTomboService extends BaseResourceService<InventarioTombo> {

  constructor(
    protected injector: Injector
  ) {
    super(`inventario-tombos`, injector);
  }
}
