import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ReceitaPrevistaService } from "administrativo-lib";
import { AssinaturaService, BaseResourceRptPersonalizadoComponent, Credito, PpaService } from "eddydata-lib";
import { CreditoService } from "../service/credito.service";
import { takeUntil } from "rxjs/operators";
import * as toastr from 'toastr';


@Component({
  selector: 'app-credito-percentual-rpt',
  templateUrl: './credito-percentual-rpt.component.html'
})
export class CreditoPercentualRptComponent extends BaseResourceRptPersonalizadoComponent {

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public data_inicial: Date = new Date();
  public data_final: Date = new Date();
  public opcoes: string[] = ['Decreto', 'Período', 'Lei'];
  public opcaoSelecionado: 'Decreto' | 'Período' | 'Lei';
  public decretoList: { key: string, value: string }[] = [];
  private creditos: Credito[] = [];
  public decretoSelected: string;
  public leiSelected: string | undefined;
  public campoInvalido: boolean;

  public maskDecreto = {
    mask: [
      { mask: '0000/0000' },
      { mask: '00000/0000' }
    ]
  };

  constructor(
    private creditoService: CreditoService,
    private ppaService: PpaService,
    private receitaPrevistaService: ReceitaPrevistaService,
    protected assinaturaService: AssinaturaService,
  ) {
    super(assinaturaService);
    //this.assinaturaObrigatoria = true;
  }


  protected afterInit(): void {
    this.orientacao = 'landscape';
    this.formato = 'pdf';

    this.data_inicial = this.funcaoService.toDate(`01/01/${this.login.exercicio.ano}`);
    this.data_final = this.funcaoService.toDate(`30/12/${this.login.exercicio.ano}`);

    this.creditoService.filtrar(1, -1, {
      'relations': 'itens.ficha.executora,itens.ficha.programa,itens.ficha.acao,itens.ficha.despesa,itens.ficha.funcao,itens.ficha.subfuncao',
      'exercicio.id': this.login?.exercicio?.id,
      'orgao.id': this.login?.orgao?.id,
      'ativo': true
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.creditos = res.content;

        res.content.forEach((val: Credito) => {
          if (!this.decretoList.find(x => x.value === val.decreto.replace('/', ''))) this.decretoList.push({ key: val.decreto, value: val.decreto.replace('/', '') });
        });
        this.decretoList.sort((d1, d2) => +d1.value - +d2.value);
      });
  }


  protected modalRelatorio(): string {
    return null;
  }


  protected tituloRelatorio(): string {
    return 'LISTAGEM DE CRÉDITO ADICIONAL';
  }


  public imprimir(): void {

    if (!this.validar())
      return;
    super.imprimir();
  }


  public limparCampos():void{
      this.leiSelected = undefined;
      this.decretoSelected = undefined;
      this.data_inicial = this.funcaoService.toDate(`01/01/${this.login.exercicio.ano}`);
      this.data_final = this.funcaoService.toDate(`30/12/${this.login.exercicio.ano}`);
  }
 

  private validar(): boolean {
    if (this.opcaoSelecionado === 'Decreto' && this.decretoSelected === undefined) {
      toastr.warning(`Não foi informado o decreto para pesquisa`);
      return false;
    }

    if (this.opcaoSelecionado === 'Lei' && (this.leiSelected === undefined || this.leiSelected === '' )) {
      toastr.warning(`Não foi informado a lei para pesquisa`);
      return false;
    }

    if (this.opcaoSelecionado === 'Período' && (this.data_inicial === null || this.data_final === null)) {
      toastr.warning(`Não foi informado data para pesquisa`);
      return false;
    }

    return true;
  }


  protected async montarConteudo(): Promise<{}[]> {
    const conteudo: {}[] = [];
    const header = await this.dadosHeader();
    conteudo.push(header);
    return await new Promise((resolve) => {

    this.creditoService.creditoPorcentual(this.login.orgao.id, this.login.exercicio.id, this.funcaoService.converteDataSQL(this.data_inicial), this.funcaoService.converteDataSQL(this.data_final), this.decretoSelected, this.leiSelected, this.opcaoSelecionado)
      .subscribe((data) => {
        if (!data || data.length === 0)
          resolve(null);

        conteudo.push(this.dadosCreditos(data)
          .concat(this.dadosRodape()));

        resolve(conteudo);
      });
    });
  }


  private async dadosHeader(): Promise<{}[]> {
    const ppa = await this.ppaService.obterPorAno(this.login.exercicio.ano, this.login.cidade.id).toPromise();
    const receitas = await this.receitaPrevistaService.totalPrevistoArrecadadoAno(this.login.exercicio.id, this.login.orgao.id).toPromise();

    let referencia = "";

    if ( this.opcaoSelecionado === 'Período') {
      referencia = `${this.funcaoService.converteDataBR(this.data_inicial)} até ${this.funcaoService.converteDataBR(this.data_final)}`;
    }
    if (this.opcaoSelecionado === 'Decreto') {
      referencia = this.decretoSelected.length === 8 ? `0${this.funcaoService.mascarar('####/####', this.decretoSelected)}` : this.decretoSelected.length === 8 ? `${this.funcaoService.mascarar('#####/####', this.decretoSelected)}` : `${this.decretoSelected}`;
    }
    if (this.opcaoSelecionado === 'Lei') {
      referencia = this.leiSelected.length === 8 ? `${this.funcaoService.mascarar('####/####', this.leiSelected)}` : `${this.leiSelected}`;
    }

    let previsto = 0;

    if (receitas) {
      for (let receita of receitas) {
        previsto += +receita.previsto;
      }
    }

    return [
      {
        text: [
          { text: 'EXERCÍCIO:   ', fontSize: 11, bold: true },
          { text: `${this.login.exercicio.ano}   `, fontSize: 11 },
          { text: `REFERÊNCIA:   `, fontSize: 11, bold: true },
          { text: referencia, fontSize: 11 }
        ], margin: 3
      }, {
        text: [
          { text: 'LEI ORÇAMENTÁRIA (Nº E DATA):  ', fontSize: 11, bold: true },
          { text: ` ${ppa.lei_numero}  ${this.funcaoService.converteDataBR(ppa.data_vigencia)}`, fontSize: 11 }
        ], margin: 3
      }, {
        text: [
          { text: `RECEITA PREVISTA:  `, fontSize: 11, bold: true },
          { text: `${this.funcaoService.convertToBrNumber(previsto, 2)}`, fontSize: 11 }
        ], margin: 3
      }, {
        text: [
          { text: 'PERCENTUAL AUTORIZADO NA LEI ORÇAMENTÁRIA PARA SUPLEMENTAÇÃO:  ', fontSize: 11, bold: true },
          { text: ` ${ppa ? this.funcaoService.convertToBrNumber(ppa.percentual_suplementacao, 2) : '0,00'}`, fontSize: 11 }
        ], margin: 3
      },
      { text: '', margin: 5 }
    ];
  }


  private dadosCreditos(creditos: any): {}[] {
    let ocorrencia: string;

    let supl_anulacao = 0;
    let supl_excesso = 0;
    let supl_superavit = 0;

    let outros_anulacao = 0;
    let outros_excesso = 0;
    let outros_superavit = 0;

    let supl_anulacao_geral = 0;
    let supl_excesso_geral = 0;
    let supl_superavit_geral = 0;

    let outros_anulacao_geral = 0;
    let outros_excesso_geral = 0;
    let outros_superavit_geral = 0;

    let conteudo = [];

    for (let credito of creditos) {
      if (!ocorrencia || ocorrencia !== credito.ocorrencia) {
        if (ocorrencia) {
          conteudo.push([
            { text: 'SOMA', alignment: 'left', bold: true, colSpan: 5 }, { text: '' }, { text: '' }, { text: '' }, { text: '' },
            { text: this.funcaoService.convertToBrNumber(supl_anulacao, 2), alignment: 'right', bold: true },
            { text: this.funcaoService.convertToBrNumber(supl_excesso, 2), alignment: 'right', bold: true },
            { text: this.funcaoService.convertToBrNumber(supl_superavit, 2), alignment: 'right', bold: true },
            { text: this.funcaoService.convertToBrNumber(outros_anulacao, 2), alignment: 'right', bold: true },
            { text: this.funcaoService.convertToBrNumber(outros_excesso, 2), alignment: 'right', bold: true },
            { text: this.funcaoService.convertToBrNumber(outros_superavit, 2), alignment: 'right', bold: true }
          ]);
        }

        conteudo.push([
          { text: 'Lei', alignment: 'center', bold: true, colSpan: 2, fontSize: 8 }, { text: '' },
          { text: 'Decreto', alignment: 'center', bold: true, colSpan: 2, fontSize: 8 }, { text: '' },
          { text: '', alignment: 'center', bold: true },
          { text: 'SUPLEMENTAÇÃO', alignment: 'center', bold: true, colSpan: 3, fontSize: 8 }, { text: '' }, { text: '' },
          { text: 'ESPECIAL /EXTRAORDINARIO', alignment: 'center', bold: true, colSpan: 3, fontSize: 8 }, { text: '' }, { text: '' }
        ]);
        conteudo.push([
          { text: 'Número', alignment: 'center', bold: true, fontSize: 7, margin: [0, 10, 0, 10] },
          { text: 'Data', alignment: 'center', bold: true, fontSize: 7, margin: [0, 10, 0, 10] },
          { text: 'Número', alignment: 'center', bold: true, fontSize: 7, margin: [0, 10, 0, 10] },
          { text: 'Data', alignment: 'center', bold: true, fontSize: 7, margin: [0, 10, 0, 10] },
          { text: 'Finalidade ', alignment: 'center', bold: true, fontSize: 7, margin: [0, 10, 0, 10] },
          { text: 'Anulação de dotação', alignment: 'center', bold: true, fontSize: 7, margin: [0, 5, 0, 5] },
          { text: 'Excesso de Arrecadação', alignment: 'center', bold: true, fontSize: 7, margin: [0, 5, 0, 5] },
          { text: 'Superávit Financeiro Op. de Credito', alignment: 'center', bold: true, fontSize: 7, margin: [0, 5, 0, 5] },
          { text: 'Anulação de dotação', alignment: 'center', bold: true, fontSize: 7, margin: [0, 5, 0, 5] },
          { text: 'Excesso de Arrecadação', alignment: 'center', bold: true, fontSize: 7, margin: [0, 5, 0, 5] },
          { text: 'Superávit Financeiro Op. de Credito/Reserva de Contigência', alignment: 'center', bold: true, fontSize: 7 }
        ]);

        ocorrencia = credito.ocorrencia;
        supl_anulacao = 0;
        supl_excesso = 0;
        supl_superavit = 0;
        outros_anulacao = 0;
        outros_excesso = 0;
        outros_superavit = 0;
      }
      if (+credito.supl_anulacao === 0 && +credito.supl_excesso === 0 && +credito.supl_superavit === 0
        && +credito.outros_anulacao === 0 && +credito.outros_excesso === 0 && +credito.outros_superavit === 0)
        continue;

      if (+credito.supl_anulacao < 0)
        credito.supl_anulacao = +credito.supl_anulacao * (-1);
      if (+credito.outros_anulacao < 0)
        credito.outros_anulacao = +credito.outros_anulacao * (-1);

      const decreto = credito.decreto.length === 8 ? `0${this.funcaoService.mascarar('####/####', credito.decreto)}` : this.funcaoService.mascarar('#####/####', credito.decreto);
      conteudo.push([
        { text: this.funcaoService.mascarar('####/####', credito.lei), alignment: 'left', fontSize: 7 },
        { text: this.funcaoService.converteDataBR(credito.data_credito), alignment: 'left', fontSize: 7 },
        { text: decreto, alignment: 'left', fontSize: 7 },
        { text: this.funcaoService.converteDataBR(credito.data_credito), alignment: 'left', fontSize: 7 },
        { text: credito.justificativa, alignment: 'left', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(credito.supl_anulacao, 2), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(credito.supl_excesso, 2), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(credito.supl_superavit, 2), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(credito.outros_anulacao, 2), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(credito.outros_excesso, 2), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(credito.outros_superavit, 2), alignment: 'right', fontSize: 7 }
      ]);

      supl_anulacao += +credito.supl_anulacao;
      supl_excesso += +credito.supl_excesso;
      supl_superavit += +credito.supl_superavit;
      outros_anulacao += +credito.outros_anulacao;
      outros_excesso += +credito.outros_excesso;
      outros_superavit += +credito.outros_superavit;

      supl_anulacao_geral += +credito.supl_anulacao;
      supl_excesso_geral += +credito.supl_excesso;
      supl_superavit_geral += +credito.supl_superavit;
      outros_anulacao_geral += +credito.outros_anulacao;
      outros_excesso_geral += +credito.outros_excesso;
      outros_superavit_geral += +credito.outros_superavit;
    }

    conteudo.push([
      { text: 'TOTAL GERAL...', alignment: 'left', bold: true, colSpan: 5 }, { text: '' }, { text: '' }, { text: '' }, { text: '' },
      { text: this.funcaoService.convertToBrNumber(supl_anulacao_geral, 2), alignment: 'right', bold: true },
      { text: this.funcaoService.convertToBrNumber(supl_excesso_geral, 2), alignment: 'right', bold: true },
      { text: this.funcaoService.convertToBrNumber(supl_superavit_geral, 2), alignment: 'right', bold: true },
      { text: this.funcaoService.convertToBrNumber(outros_anulacao_geral, 2), alignment: 'right', bold: true },
      { text: this.funcaoService.convertToBrNumber(outros_excesso_geral, 2), alignment: 'right', bold: true },
      { text: this.funcaoService.convertToBrNumber(outros_superavit_geral, 2), alignment: 'right', bold: true }
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['auto', 'auto', 'auto', 'auto', '*', 60, 60, 60, 60, 60, 60],
        body: conteudo
      }
    }];
  }


  private dadosRodape(): {}[] {
    // const assinaturas = this.assinatura.pessoas.filter(p => p.selecionado);
    // if (assinaturas?.length === 0) return [{}];

    // return [{ text: '', margin: 20 }, this.imprimirAssinaturas(assinaturas)];
    return [{ text: '', margin: 20 }];
  }


  protected layoutRelatorio(): {} {
    return {
      table_eddy: {
        hLineWidth: function () {
          return 1;
        },
        vLineWidth: function () {
          return 0;
        },
        hLineColor: function () {
          return 'black';
        },
        paddingLeft: function () {
          return 3;
        },
        paddingRight: function () {
          return 3;
        },
        paddingTop: function () {
          return 8;
        },
        paddingBottom: function () {
          return 8;
        }
      }
    }
  }


  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }


  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }
}