import { BaseResourceModel } from "../../models/base-resource.model";
import { NotaExplicativaStorage } from "./nota-explicativa-arquivo.model";

export class NotaExplicativa extends BaseResourceModel {
  constructor(
    public id?: number,
    public tipo_relatorio?: string,
    public nota_explicativa?: string,
    public arquivos?: NotaExplicativaStorage[]
  ) {
    super();
  }

  static converteJson(json: any): NotaExplicativa {
    return Object.assign(new NotaExplicativa(), json);
  }
}