// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ContaBancaria } from './conta-bancaria.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { Recurso } from '../planejamento/recurso.model';
import { TransferenciaEspecie } from './transferencia-especie.model';

export class TransferenciaAutorizacao extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_transfere?: Date,
    public data_referencia?: Date,
    public mes?: number,
    public especie?: TransferenciaEspecie,
    public documento?: string,
    public tipo?: string,
    public impresso?: boolean,
    public finalidade?: number,
    public historico?: string,
    public valor_transferido?: number,
    public conta_recebedora?: ContaBancaria,
    public conta_pagadora?: ContaBancaria,
    public exercicio?: Exercicio,
    public orgao?: Orgao,
    public exportado_febraban?: boolean,
    public exportado_obn?: boolean,
    public numero_febraban?: number,
    public data_cadastro?: Date,
    public recurso_pagadora?: Recurso,
    public aplicacao_pagadora?: Recurso,
    public convenio_pagadora?: Recurso,
    public recurso_recebedora?: Recurso,
    public aplicacao_recebedora?: Recurso,
    public convenio_recebedora?: Recurso,
    public data_alteracao?: Date,
    public usuario_cadastro?: Usuario
  ) {
    super();
  }

  static converteJson(json: any): TransferenciaAutorizacao {
    return Object.assign(new TransferenciaAutorizacao(), json);
  }
}
