import { EventoContabilEspecie } from "eddydata-lib";
import { BaseResourceModel } from "../../models/base-resource.model";
import { TipoConvenio } from "./tipo-convenio.model";

export class TipoConvenioEvento extends BaseResourceModel {
    constructor(
        public id?: number,
        public tipo_fornecedor?: string,
        public finalidade?: number,
        public especie?: string,
        public tipo_convenio?: TipoConvenio,
        public evento_especie?: EventoContabilEspecie
    ) {
        super();
    }

    static converteJson(json: any): TipoConvenioEvento {
        return Object.assign(new TipoConvenioEvento(), json);
    }
}
