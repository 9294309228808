// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { DocumentoFiscalServicos } from './documento-fiscal.model';
import { PrecatorioIndicativo } from './precatorio-indicativo.model';
import { Precatorio } from './precatorio.model';

export class DocumentoFiscalProcesso extends BaseResourceModel {
  constructor(
    public id?: number,
    public valor_n_retido?: number,
    public tipo?: 'P' | 'A',
    public precatorio?: Precatorio,
    public documento_fiscal?: DocumentoFiscalServicos,
    public precatorio_indicativo?: PrecatorioIndicativo
  ) {
    super();
  }

  static converteJson(json: any): DocumentoFiscalProcesso {
    return Object.assign(new DocumentoFiscalProcesso(), json);
  }
}
