import { Component, Injector } from '@angular/core';
import {
  BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, GlobalService, LoginContabil, Prazo
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PrazoService } from '../service/prazo.service';

@Component({
  selector: 'lib-prazo-list',
  templateUrl: './prazo-list.component.html'
})
export class PrazoListComponent extends BaseResourceListComponent<Prazo, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public mes: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private prazoService: PrazoService,
    protected globalService: GlobalService) {
    super(prazoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return '';
  }

  protected condicoesGrid(): {} {
    return {
      'orgao.id': this.login.orgao.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['id$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['id'],
      text: ['nome'],
    };
  }

  protected afterInit(): void {
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  protected acaoRemover(model: Prazo): Observable<Prazo> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'id', alignment : 'center' },
      { titulo: 'Prazo', coluna: 'nome', alignment : 'left' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.relations() ?
      Object.assign({}, { relations: this.relations() }, this.obterParametros())
      : this.obterParametros();
    this.prazoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE PRAZOS`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem prazos', ['auto','*'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

}
