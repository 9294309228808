// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Chamamento } from './chamamento.model';
import { Recurso } from '../planejamento/recurso.model';

export class ChamamentoCronograma extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_cronograma?: Date,
    public valor_proponente?: number,
    public valor_concedente?: number,
    public recurso?: Recurso,
    public chamamento?: Chamamento,
    public editavel?: boolean
  ) {
    super();
  }
  static converteJson(json: any): ChamamentoCronograma {
    return Object.assign(new ChamamentoCronograma(), json);
  }
}
