import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../util/auth.guard';
import { AssinaturaFormComponent } from './assinatura-form/assinatura-form.component';
import { AssinaturaListComponent } from './assinatura-list/assinatura-list.component';
import { AssinaturaViewComponent } from './assinatura-view/assinatura-view.component';

const routes: Routes = [
  { path: '', component: AssinaturaListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: AssinaturaFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: AssinaturaFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: AssinaturaViewComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AssinaturaRoutingModule { }
