import { BaseResourceModel } from '../../models/base-resource.model';
import { Receita } from "./receita.model";
import { ReceitaSiope } from "./receita-siope.model";

export class ReceitaSiopeReceita extends BaseResourceModel {
  constructor(
    public id?: number,
    public receitaSiope?: ReceitaSiope,
    public receita?: Receita,) {
    super();
  }
  static converteJson(json: any): ReceitaSiopeReceita {
    return Object.assign(new ReceitaSiopeReceita(), json);
  }
}