// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { EmailErro } from '../comum/email-erro.model';
import { TransferenciaItemPatrimonio } from './transferencia-item-patrimonio.model';

export class TransferenciaItemEmailPatrimonio extends BaseResourceModel {
  constructor(
    public id?: number,
    public ok?: boolean,
    public tentativa?: number,
    public aviso?: number,
    public erro?: EmailErro,
    public transferencia_item?: TransferenciaItemPatrimonio,
  ) {
    super();
  }

  static converteJson(json: any): TransferenciaItemEmailPatrimonio {
    return Object.assign(new TransferenciaItemEmailPatrimonio(), json);
  }
}
