import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-ordenacao',
  templateUrl: './ordenacao.component.html'
})
export class OrdenacaoComponent {

  @Input() coluna: string;
  @Input() colunaOrdenacao: string;
  @Input() ascendente: boolean;
  @Output() acao = new EventEmitter<string>();

  constructor() { }

  reordenar(coluna: string) {
    this.acao.emit(coluna);
  }

}
