import { Component } from '@angular/core';
import * as toastr from 'toastr';
import { ProdutoUnidade } from '../../../entidade/almoxarifado/produto-unidade.model';
import { SetorProduto } from '../../../entidade/almoxarifado/setor-produto.model';
import { BaseResourceItemsComponent } from '../../../models/base-resource-items';
import { EddyAutoComplete } from '../../../models/form-components';
import { ProdutoUnidadeService } from '../service/produto-unidade.service';
import { SetorProdutoService } from '../service/setor-produto.service';

@Component({
  selector: 'app-setor-produto',
  templateUrl: './setor-produto.component.html'
})
export class SetorProdutoComponent extends BaseResourceItemsComponent<SetorProduto> {

  public produtoUnAutoComplete: EddyAutoComplete<ProdutoUnidade>;

  public imaskValor = {
    mask: Number,
    scale: 5,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private produtoUnidadeService: ProdutoUnidadeService,
    private setorProdutoService: SetorProdutoService,
  ) {
    super(new SetorProduto(), setorProdutoService);
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
  }

  protected validSave(item: SetorProduto): boolean {
    if (!item.produto_unidade) {
      toastr.warning('Informe o produto!');
      return false;
    }
    if (!item.estoque_minimo) {
      toastr.warning('Informe o estoque mínimo');
      return false;
    }
    if (!item.estoque_maximo) {
      toastr.warning('Informe o estoque máximo');
      return false;
    }
    return true;
  }

  protected beforeSave(item: SetorProduto): void { }



  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  private carregarAutoCompletes() {
    this.produtoUnAutoComplete = new EddyAutoComplete(null, this.produtoUnidadeService,
      'id', ['produto.codigo', 'produto.nome', 'unidade.nome'], {
      'produto.orgao.id': this.login.orgao.id,
      orderBy: 'produto.codigo,produto.nome', relations: ['produto.material.sub_grupo.grupo', 'unidade', 'produto.orgao']
    }, { number: ['id', 'produto.codigo'], text: ['produto.codigo', 'produto.nome', 'produto_codigo_comp'] },
      null, null // , (entidade: ProdutoUnidade) => `${entidade.produto.material.sub_grupo.grupo.codigo}.${entidade.produto.material.sub_grupo.codigo}.${entidade.produto.material.codigo}.${entidade.produto.codigo} - ${entidade.produto.nome}`
    );
  }

}
