import { Component, Input } from "@angular/core";
import { MemorialService } from "administrativo-lib";
import { BaseResourceRptComponent, Coluna, Licitacao, LicitacaoPipe, Memorial } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-memorial-rpt',
  templateUrl: './memorial-rpt.component.html'
})
export class MemorialRptComponent extends BaseResourceRptComponent {

  @Input() public licitacao: Licitacao;

  public tipoDescricao: 'nome' | 'descricao';
  public mostrar_coluna_valor_referencia: boolean = false;

  constructor(
    private memorialService: MemorialService
  ) {
    super();
  }

  protected afterInit(): void {
  }

  protected tituloRelatorio(): string {
    return `${this.login.orgao.nome} - Memorial Descritivo`;
  }

  protected subTituloRelatorio(): string {
    return `Licitação: ${new LicitacaoPipe().transform(this.licitacao.numero)} - Processo: ${new LicitacaoPipe().transform(this.licitacao.processo)}\nModalidade: ${this.licitacao.modalidade.nome}`;
  }

  protected obterColunasRelatorio(): Coluna[] {
    const retorno: Coluna[] = [];

    retorno.push({ titulo: 'Ordem', coluna: 'ordem', alignment: 'center', adicionaisBody: { border: [true, true, true, true] }, });
    retorno.push({ titulo: 'Código', coluna: 'codigo', alignment: 'center', adicionaisBody: { border: [true, true, true, true] } });
    retorno.push({ titulo: 'UN', coluna: 'unidade', alignment: 'center', adicionaisBody: { border: [true, true, true, true] } });
    retorno.push({ titulo: (this.tipoDescricao === 'nome' ? 'Item' : 'Descrição'), coluna: 'item', alignment: 'center', adicionaisBody: { alignment: 'left', border: [true, true, true, true] } });
    retorno.push({ titulo: 'Qtde.', coluna: 'qtde', decimais: 0, alignment: 'center', adicionaisBody: { border: [true, true, true, true] } });
    this.mostrar_coluna_valor_referencia ? retorno.push({ titulo: 'Valor Ref.', coluna: 'valor_ref', alignment: 'center', adicionaisBody: { border: [true, true, true, true] } }) : '';
    retorno.push({ titulo: 'Valor Unit.', coluna: 'valor', alignment: 'center', adicionaisBody: { alignment: 'rigth', border: [true, true, true, true] } });
    retorno.push({ titulo: 'Valor Total', coluna: 'total', alignment: 'center', adicionaisBody: { alignment: 'rigth', border: [true, true, true, true] } });

    return retorno;
  }

  protected larguraColunas(): (string | number)[] {
    if(this.mostrar_coluna_valor_referencia) {
      return ['auto', 'auto', 'auto', '*', 'auto', 'auto', 100, 100];
    } else {
      return ['auto', 'auto', 'auto', '*', 'auto', 100, 100];
    }
  }

  protected totalizarColunas(): (string | {})[] {
    return;
  }

  protected carregarLista(): Promise<any[]> {
    return new Promise((resolve) => {
      const lista: {
        ordem: number,
        codigo: string,
        unidade: string,
        item: string,
        qtde: number,
        valor_ref: string,
        valor: string,
        total: string,
      }[] = [];
      this.memorialService.obterPorLicitacao(this.licitacao.id).pipe(takeUntil(this.unsubscribe))
        .subscribe(async (res) => {
          let reg: Memorial[] = res.content;
          reg.forEach(m => {
            lista.push({
              ordem: m.ordem,
              codigo: m?.produto_unidade?.produto?.codigo,
              unidade: m.unidade,
              item: (this.tipoDescricao === 'nome' ? `${m.produto_unidade.produto.nome} ${this.informacaoCota(m)}` : `${m.produto_unidade.produto.descricao}`),
              qtde: m.quantidade,
              valor_ref: this.funcaoService.formatarMoedaPtBr(m.valor_referencia),
              valor: '',
              total: '',
            });
          });
          resolve(lista);
        });
    });
  }

  protected layout(): {} {
    return {
      hLineWidth() {
        return 1;
      },
      vLineWidth() {
        return 1;
      },
      hLineColor(i) {
        return 'black'
      },
      paddingLeft(i) {
        return 3
      },
      paddingRight(i, node) {
        return 3;
      }
    };
  }

  private informacaoCota(item: Memorial): string {
    if (!item || !item.cota || item.cota === 'NAO_DEFINIDO') return ''
    if (item.cota === 'PRINCIPAL') return '(Cota Principal)'
    if (item.cota === 'RESERVADO') return '(Cota Reservada)'
  }

}