import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';

export class RecursoSiconfiComplemento extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public nome?: string,
    public exercicio?: Exercicio,
    public arquivo_importacao?: string) {
    super();
  }
  static converteJson(json: any): RecursoSiconfiComplemento {
    return Object.assign(new RecursoSiconfiComplemento(), json);
  }
}