
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DemonstrativoContabilService {

  buscarDemonstrativoContabil(): Array<{ codigo: string, nome: string, layout: string, atualizacao: string, download: string }> {
    const layout = [];

    layout.push({ codigo: 'B12', nome: 'ANEXO 12 - BALANÇO ORÇAMENTÁRIO', layout: 'DEMONSTRATIVOS_CONTÁBEIS_AUDESP_AN_12_BAL_ORC_2023_v1.xls', atualizacao: '08/02/2024', download: 'https://app.eddydata.com/SGM/attachment/?id=328118' });
    layout.push({ codigo: 'B121', nome: 'ANEXO 12.1 - DEMONSTRATIVO DE EXECUÇÃO DE RESTOS A PAGAR NÃO PROCESSADO', layout: 'DEMONSTRATIVOS_CONTÁBEIS_AUDESP_AN_12_BAL_ORC_2022_v1.xls', atualizacao: '20/09/2023', download: 'https://app.eddydata.com/SGM/attachment/?id=296711' });
    layout.push({ codigo: 'B122', nome: 'ANEXO 12.2  - DEMONSTRATIVO DE EXECUÇÃO DE RESTOS A PAGAR PROCESSADO', layout: 'DEMONSTRATIVOS_CONTÁBEIS_AUDESP_AN_12_BAL_ORC_2022_v1.xls', atualizacao: '11/09/2023', download: 'https://app.eddydata.com/SGM/attachment/?id=296711' });
    layout.push({ codigo: 'B13', nome: 'ANEXO 13 - BALANÇO FINANCEIRO', layout: 'DEMONSTRATIVOS_CONTÁBEIS_AUDESP_AN_13_BAL_FIN_ 2023_v0_0.xls', atualizacao: '12/01/2024', download: 'https://app.eddydata.com/SGM/attachment/?id=313458' });
    layout.push({ codigo: 'B13A', nome: 'ANEXO 13 A - BALANÇO FINANCEIRO', layout: 'DEMONSTRATIVOS_CONTÁBEIS_AUDESP_AN_13A_2023_v0_0.xls', atualizacao: '16/11/2023', download: 'https://app.eddydata.com/SGM/attachment/?id=313670' });
    layout.push({ codigo: 'B14', nome: 'ANEXO 14 - BALANÇO PATRIMONIAL', layout: 'DEMONSTRATIVOS_CONTÁBEIS_AUDESP_AN_14_ BAL_PAT_2022_Isolado_Consolidado_v1.xls', atualizacao: '19/09/2023', download: 'https://app.eddydata.com/SGM/attachment/?id=296708' });
    layout.push({ codigo: 'B14A', nome: 'ANEXO 14 A - QUADRO DEMONSTRATIVO DAS CONTAS ANALÍTICAS DO ATIVO FINANCEIRO', layout: 'DEMONSTRATIVOS_CONTÁBEIS_AUDESP_AN_14A_2023_v0_0.xls', atualizacao: '16/11/2023', download: 'https://app.eddydata.com/SGM/attachment/?id=313971' });
    layout.push({ codigo: 'B14B', nome: 'ANEXO 14 B - QUADRO DEMONSTRATIVO DAS CONTAS ANALÍTICAS DO PASSIVO FINANCEIRO', layout: 'DEMONSTRATIVOS_CONTÁBEIS_AUDESP_AN_14B_2023_Isolado_Consolidado_v0_0.xls', atualizacao: '16/11/2023', download: 'https://app.eddydata.com/SGM/attachment/?id=317865' });
    layout.push({ codigo: 'B15', nome: 'ANEXO 15 - DEMONSTRAÇÃO DAS VARIAÇÕES PATRIMONIAIS', layout: 'DEMONSTRATIVOS_CONTÁBEIS_AUDESP_AN_15_DVP_2023_Isolado_Consolidado_v0_0.xls', atualizacao: '16/11/2023', download: 'https://app.eddydata.com/SGM/attachment/?id=314010' });
    layout.push({ codigo: 'B18', nome: 'ANEXO 18 - DEMONSTRAÇÃO DOS FLUXOS DE CAIXA', layout: 'DEMONSTRATIVOS_CONTÁBEIS_AUDESP_AN_18_FLUXO_DE_CAIXA_DFC_2023_v0_0.xls', atualizacao: '16/11/2023', download: 'https://app.eddydata.com/SGM/attachment/?id=314047' });   

    return layout;
  }

  buscarDemonstrativoPorCodigo(codigo: string): { codigo: string, nome: string, layout: string, atualizacao: string, download: string } {
    return this.buscarDemonstrativoContabil().find(i => i.codigo == codigo);
  }
}

