// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { OrdemPagamento } from './ordem-pagamento.model';

export class OrdemPagamentoFatura extends BaseResourceModel {
  constructor(
    public id?: number,
    public cod_barras?: string,
    public valor?: number,
    public funcionario?: number,
    public data_vencimento?: Date,
    public ordem?: OrdemPagamento
  ) {
    super();
  }

  static converteJson(json: any): OrdemPagamentoFatura {
    return Object.assign(new OrdemPagamentoFatura(), json);
  }
}
