import { Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../login/servico/login.service';
import { Orgao } from '../../entidade/comum/orgao.model';
import { Login } from '../../entidade/login/login';
import { GlobalService } from '../../util/global.service';

@Component({
  selector: 'lib-cidadao-recuperar-senha',
  templateUrl: './cidadao-recuperar-senha.component.html',
  styleUrls: ['./cidadao-recuperar-senha.component.css']
})
export class CidadaoRecuperarSenhaComponent implements OnInit {

  public msgs: string;
  public email: string;
  public orgao: Orgao = new Orgao()
  public login: Login

  @Input() sistema: string;

  protected router: Router;

  constructor(
    protected injector: Injector,
    private authService: LoginService,
  ) {
    this.router = this.injector.get(Router);
  }

  ngOnInit() {
    this.login = GlobalService.obterSessaoLogin()
  }

  async enviarEmail() {
    try {
      const resultado = await this.authService.recuperarSenha(this.email, this.login.orgao);
      if (resultado && resultado.error) {
        this.msgs = resultado.error.message;
      } else {
        this.router.navigate([`${this.sistema}/recuperar-senha-sucesso`]);
      }
    } catch (ex) {
      toastr.error('Atenção', ex.error);
    }
  }

  voltar() {
    this.router.navigate([`${this.sistema}/login`]);
  }

}
