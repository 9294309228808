// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Chamamento } from './chamamento.model';

export class ChamamentoComissao extends BaseResourceModel {
  constructor(
    public id?: number,
    public cpf?: string,
    public nome?: string,
    public email?: string,
    public cargo?: string,
    public chamamento?: Chamamento,
    public editavel?: boolean
  ) {
    super();
  }

  static converteJson(json: any): ChamamentoComissao {
    return Object.assign(new ChamamentoComissao(), json);
  }

}
