// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { EventoContabil } from './evento-contabil.model';
import { VariacaoItem } from './variacao-item.model';

export class Variacao extends BaseResourceModel {
  constructor(
    public id?: number,
    public valor?: number,
    public historico?: string,
    public exercicio?: Exercicio,
    public orgao?: Orgao,
    public evento?: EventoContabil,
    public itens?: VariacaoItem[],
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public data_variacao?: Date,
    public mes?: number,
    public inativo?: boolean,
    public justificativa_inativacao?: string,
    public usuario_inativacao?: Usuario,
    public data_inativacao?: Date,
  ) {
    super();
  }

  static converteJson(json: any): Variacao {
    return Object.assign(new Variacao(), json);
  }
}
