import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EventoContabilEspecieService, EventoContabilService } from 'contabil-lib';
import { BaseResourceItemsComponent, GlobalService, TipoConvenioEvento } from 'eddydata-lib';
import * as toastr from 'toastr';
import { TipoConvenioEventoService } from '../service/tipo-convenio-evento.service';

@Component({
  selector: 'app-tipo-convenio-evento',
  templateUrl: './tipo-convenio-evento.component.html'
})
export class TipoConvenioEventoComponent extends BaseResourceItemsComponent<TipoConvenioEvento> implements OnChanges {

  @Input() visualizar;
  @Input() origem;

  public listaEventos: Array<any>;
  public listaTipoFavorecido: Array<any>;
  public listaFinalidade: Array<any>;
  public origemFornecedor: boolean = false;

  constructor(public globalService: GlobalService,
    protected eventoService: EventoContabilService,
    protected eventoEspecieService: EventoContabilEspecieService,
    public tipoConvenioEventoService: TipoConvenioEventoService) {
    super(new TipoConvenioEvento(), tipoConvenioEventoService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['origem']) {
      if (changes['origem'].currentValue?.toString() === 'F') {
        this.origemFornecedor = true;
      } else {
        this.origemFornecedor = false;
      }
    }
  }

  public editar(item: TipoConvenioEvento) {
    item.tipo_fornecedor = 'F';
    super.editar(item);
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.listaTipoFavorecido = [
      { nome: 'FORNECEDOR', id: 'F' },
      { nome: 'AMBOS', id: 'A' },
      // { nome: 'CREDOR', id: 'C' },
      { nome: 'UNIÃO', id: 'U' },
      { nome: 'ESTADO', id: 'E' }
    ];
    this.listaFinalidade = [
      { id: 5, nome: 'ASSINATURA' },
      { id: 2, nome: 'AUMENTO' },
      { id: 1, nome: 'ESTORNO' },
      { id: 3, nome: 'VIGÊNCIA' },
      { id: 6, nome: 'APROVAÇÃO' },      
      { id: 7, nome: 'RECEBIMENTO/PAGTO' },
      { id: 8, nome: 'EDUCAÇÃO CONCESSÃO' },
      { id: 12, nome: 'EDUCAÇÃO PRESTAÇÃO' },
      { id: 9, nome: 'SAÚDE CONCESSÃO' },
      { id: 13, nome: 'SAÚDE PRESTAÇÃO' },
      { id: 10, nome: 'ASSISTÊNCIA CONCESSÃO' },
      { id: 14, nome: 'ASSISTÊNCIA PRESTAÇÃO' },
      { id: 11, nome: 'OUTROS CONCESSÃO' },
      { id: 15, nome: 'OUTROS PRESTAÇÃO' },
    ];    

    this.eventoEspecieService.filtrar(0, -1, { orderBy: 'nome' }).subscribe(res => {
      this.listaEventos = res.content;
    })

  }

  protected validSave(item: TipoConvenioEvento): boolean {
    if (!item.finalidade) {
      toastr.warning('Informe a finalidade!');
      return false;
    }

    if (!item.tipo_fornecedor) {
      toastr.warning('Informe se o convênio é um credor, fornecedor, união ou estado!');
      return false;
    }

    if (!item.evento_especie) {
      toastr.warning('Informe se o evento para o convênio!');
      return false;
    }

    item.especie = item.evento_especie.especie;
    return true;
  }

  protected beforeSave(item: TipoConvenioEvento): void {

  }


  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================
  private carregarAutoCompletes() {

  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  public obtertipoFavorecido(tipo: string) {
    switch (tipo) {
      case 'A':
        return 'AMBOS'
        break;
      case 'F':
        return 'FORNECEDOR'
        break;
      case 'C':
        return 'CREDOR'
        break;
      case 'U':
        return 'UNIÃO'
        break;
      case 'E':
        return 'ESTADO'
        break;
      default:
        break;
    }
  }

  public podeVisualizar() {
    if (this.visualizar === true) {
      return true;
    }
    if (this.visualizar === false) {
      return false;
    }
  }
}
