import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BaseResourceListComponent,
  Cargo, CargoHistorico,
  Coluna, Filtro, FormatoExportacao, LoginPublico
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CargoHistoricoService } from '../service/cargo-historico.service';

@Component({
  selector: 'lib-cargo-historico-lst',
  templateUrl: './cargo-historico-lst.component.html'
})
export class CargoHistoricoLstComponent extends BaseResourceListComponent<CargoHistorico, LoginPublico> {
  /**
   * Declaração de variáveis
   */
  public filtro: string;
  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private route: ActivatedRoute,
    protected injector: Injector,
    private cargoService: CargoHistoricoService) {
    super(cargoService, injector);
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.paginaCorrente = +params['pagina'];
        this.filtro = params['filtro'];
      });
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'cidade,cargo';
  }

  protected condicoesGrid(): {} {
    return {
      ['cidade.id']: this.login.cidade.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['id$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['vagas'],
      text: ['cargo.nome', 'cargo.regime_juridico'],
    };
  }

  protected afterInit(): void {
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
  }

  protected acaoRemover(model: Cargo): Observable<Cargo> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Nome', coluna: 'cargo.nome' },
      { titulo: 'Salário Base', coluna: 'cargo.salario_base', alignment: 'right' },
      { titulo: 'Escolaridade', coluna: 'cargo.escolaridade' },
      { titulo: 'Regime Jurídico', coluna: 'cargo.regime_juridico' },
      { titulo: 'Provimento', coluna: 'cargo.provimento' },
      { titulo: 'Atividade', coluna: 'cargo.atividade' },
      { titulo: 'Vagas', coluna: 'vagas' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    this.cargoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE CARGOS E SALÁRIOS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem Cargos', ['*', 'auto', '*', '*', '*', '*', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
