import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../assinaturas/assinaturas';
import { AnexoLRFService } from '../../service/anexo-lrf.service';

@Directive()
export class ReceitaAlienacaoLRF implements OnDestroy {
    protected funcaoService: FuncaoService;
    protected globalService: GlobalService;
    private login: Login = new Login();
    protected unsubscribe: Subject<void> = new Subject();

    constructor(
        protected anexoServico: AnexoLRFService,
        protected mes: number,
        protected exercicio: Exercicio,
        protected injector: Injector,
        protected assinaturaControleInterno: boolean) {
        this.globalService = new GlobalService();
        this.funcaoService = new FuncaoService();
        this.login = GlobalService.obterSessaoLogin();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    // tslint:disable: max-line-length
    public montarRelatorio(orgaos: number[], formato: FormatoExportacao = 'pdf') {
        const parametros: {} = {};
        this.anexoServico.obterReceitaAlienacao(this.mes, this.exercicio.id, orgaos)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(async dados => {
                if (formato === 'pdf') {
                    Relatorio.imprimirPersonalizado(
                        'DEMONSTRATIVO DA RECEITA DE ALIENAÇÃO DE ATIVOS E APLICAÇÃO DOS RECURSOS'
                        , this.login.usuario.nome, this.login.usuario.sobrenome,
                        this.login.orgao.nome, this.login.brasao,
                        await this.conteudo(dados),
                        'landscape', 'DEMONSTRATIVO DA RECEITA DE ALIENAÇÃO DE ATIVOS E APLICAÇÃO DOS RECURSOS',
                        {
                            linhas: {
                                hLineWidth() {
                                    return 1;
                                },
                                vLineWidth() {
                                    return 1;
                                },
                                hLineColor() {
                                    return 'black';
                                },
                                paddingLeft() {
                                    return 3;
                                },
                                paddingRight() {
                                    return 3;
                                }
                            }
                        });
                } else {
                    this.funcaoService.exportar(formato, this.normalizar(dados), 'DEMONSTRATIVO DAS RECEITAS DE OPERAÇÕES DE CRÉDITO E DESPESAS DE CAPITAL', this.colunas());
                }
            });
    }

    private async conteudo(dados: any[]): Promise<{}[]> {

        // monta o cabecalho
        const registros: {}[] = [
            [
                {
                    text: `REFERÊNCIA: ` +
                        this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano,
                    alignment: 'center',
                    bold: true,
                    colSpan: 11, border: [false, false, false, false]
                }, '', '', '', '', '', '', '', '', '', ''
            ],
            [
                {
                    text: 'LRF, Art. 53, § 1º, inciso III',
                    alignment: 'center',
                    bold: true,
                    colSpan: 11, border: [false, false, false, false]
                }, '', '', '', '', '', '', '', '', '', ''
            ],
            [
                {
                    text: 'MOVIMENTAÇÃO ORÇAMENTÁRIA E FINANCEIRA',
                    alignment: 'center',
                    bold: true,
                    colSpan: 11, border: [false, false, false, false]
                }, '', '', '', '', '', '', '', '', '', ''
            ],
            [
                {
                    text: '\n\nÓRGÃO',
                    alignment: 'center',
                    bold: true, fontSize: 6, rowSpan: 2
                },
                {
                    text: '\n\nFONTE DE RECURSOS',
                    alignment: 'center',
                    bold: true, fontSize: 6, rowSpan: 2
                },
                {
                    text: '\n\nCÓDIGO DE APLICAÇÃO',
                    alignment: 'center',
                    bold: true, fontSize: 6, rowSpan: 2
                },
                {
                    text: 'Saldo do Exercício Anterior (I)',
                    alignment: 'center',
                    bold: true, fontSize: 6, rowSpan: 2
                },
                {
                    text: 'Receita',
                    alignment: 'center',
                    bold: true, fontSize: 6, colSpan: 2
                },
                {
                    text: '',
                },
                {
                    text: 'Aplicação dos Recursos',
                    alignment: 'center',
                    bold: true, fontSize: 6, colSpan: 4
                },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' }
            ],
            [
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                {
                    text: 'Prevista',
                    alignment: 'center',
                    bold: true, fontSize: 6
                },
                {
                    text: 'Realizada (II)',
                    alignment: 'center',
                    bold: true, fontSize: 6
                },
                {
                    text: 'Dotação Atualizada',
                    alignment: 'center',
                    bold: true, fontSize: 6
                },
                {
                    text: 'Despesa Empenhada (III)',
                    alignment: 'center',
                    bold: true, fontSize: 6
                },
                {
                    text: 'Despesa Liquidada',
                    alignment: 'center',
                    bold: true, fontSize: 6
                },
                {
                    text: 'Despesa Paga',
                    alignment: 'center',
                    bold: true, fontSize: 6
                },
                { text: '' }
            ]
        ];

        const ass = new Assinaturas(this.login.orgao, this.injector);
        let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);

        // monta o agrupamento do relatório
        const grupos = this.funcaoService.agrupar(dados, 'orgao',
            ['anterior', 'previsto', 'realizado', 'dotacao', 'empenhado', 'liquidado', 'pago']);
        for (const grupo of grupos) {
            registros.push([
                {
                    text: grupo.grupo, bold: true, fontSize: 6, colSpan: 3
                },
                { text: '' },
                { text: '' },
                { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['anterior']), alignment: 'right', bold: true, fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsto']), alignment: 'right', bold: true, fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['realizado']), alignment: 'right', bold: true, fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao']), alignment: 'right', bold: true, fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']), alignment: 'right', bold: true, fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']), alignment: 'right', bold: true, fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago']), alignment: 'right', bold: true, fontSize: 6 },
                {
                    text: this.funcaoService.convertToBrNumber(
                        (+grupo.totalizadores['anterior'] + +grupo.totalizadores['realizado']) -
                        +grupo.totalizadores['empenhado']
                    ), alignment: 'right', bold: true, fontSize: 6
                }
            ]);

            const recursos = this.funcaoService.agrupar(grupo.registros, 'recurso',
                ['anterior', 'previsto', 'realizado', 'dotacao', 'empenhado', 'liquidado', 'pago']);
            for (const recurso of recursos) {
                registros.push([
                    { text: '' },
                    {
                        text: recurso.grupo, bold: true, fontSize: 6, colSpan: 2
                    },
                    { text: '' },
                    { text: this.funcaoService.convertToBrNumber(recurso.totalizadores['anterior']), alignment: 'right', bold: true, fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber(recurso.totalizadores['previsto']), alignment: 'right', bold: true, fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber(recurso.totalizadores['realizado']), alignment: 'right', bold: true, fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber(recurso.totalizadores['dotacao']), alignment: 'right', bold: true, fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber(recurso.totalizadores['empenhado']), alignment: 'right', bold: true, fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber(recurso.totalizadores['liquidado']), alignment: 'right', bold: true, fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber(recurso.totalizadores['pago']), alignment: 'right', bold: true, fontSize: 6 },
                    {
                        text: this.funcaoService.convertToBrNumber(
                            (+recurso.totalizadores['anterior'] + +recurso.totalizadores['realizado']) -
                            +recurso.totalizadores['empenhado']
                        ), alignment: 'right', bold: true, fontSize: 6
                    }
                ]);

                for (const item of recurso.registros) {
                    let valorRestoExercicio = 0;
                    this.mes < 11 ? valorRestoExercicio = item.rp_exercicio :
                        valorRestoExercicio = item.rp_exercicio - item.rp_inscricao;
                    registros.push([
                        { text: '', colSpan: 2 },
                        { text: '' },
                        {
                            text: `${item.aplicacao}`, fontSize: 6
                        },
                        { text: this.funcaoService.convertToBrNumber(item.anterior), alignment: 'right', fontSize: 6 },
                        { text: this.funcaoService.convertToBrNumber(item.previsto), alignment: 'right', fontSize: 6 },
                        { text: this.funcaoService.convertToBrNumber(item.realizado), alignment: 'right', fontSize: 6 },
                        { text: this.funcaoService.convertToBrNumber(item.dotacao), alignment: 'right', fontSize: 6 },
                        { text: this.funcaoService.convertToBrNumber(item.empenhado), alignment: 'right', fontSize: 6 },
                        { text: this.funcaoService.convertToBrNumber(item.liquidado), alignment: 'right', fontSize: 6 },
                        { text: this.funcaoService.convertToBrNumber(item.pago), alignment: 'right', fontSize: 6 },
                        {
                            text: this.funcaoService.convertToBrNumber(
                                (+item.anterior + +item.realizado) - +item.empenhado
                            ), alignment: 'right', fontSize: 6
                        }
                    ]);
                }
            }

        }

        return [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                headerRows: 3,
                widths: ['*', '*', '*', 55, 55, 55, 55, 55, 55, 55, 55],
                body: registros
            }
        },
        {
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                headerRows: 0,
                widths: ['*', '*', '*'],
                body: assinaturas
            }
        }];
    }

    public normalizar(lista: any[]) {
        const listaExportar = []

        let total0 = 0;

        let anterior = 0;
        let previsto = 0;
        let realizado = 0;
        let dotacao = 0;
        let empenhado = 0;
        let liquidado = 0;
        let pago = 0;

        const grupos = this.funcaoService.agrupar(lista, 'orgao',
            ['anterior', 'previsto', 'realizado', 'dotacao', 'empenhado', 'liquidado', 'pago']);
        for (const grupo of grupos) {

            anterior = grupo.totalizadores['anterior'];
            previsto = grupo.totalizadores['previsto'];
            realizado = grupo.totalizadores['realizado'];
            total0 = anterior + realizado + empenhado;
            dotacao = grupo.totalizadores['dotacao'];
            empenhado = grupo.totalizadores['empenhado'];
            liquidado = grupo.totalizadores['liquidado'];
            pago = grupo.totalizadores['pago'];

            const linha_grupo = {
                orgao: grupo.grupo,
                fonte_recurso: '',
                codigo_aplicacao: '',
                saldo_exercicio_anterior: this.funcaoService.convertToBrNumber(anterior),
                previstas: this.funcaoService.convertToBrNumber(previsto),
                realizada: this.funcaoService.convertToBrNumber(realizado),
                dotacao_atualizada: this.funcaoService.convertToBrNumber(dotacao),
                despesa_empenhada: this.funcaoService.convertToBrNumber(empenhado),
                despesa_liquidada: this.funcaoService.convertToBrNumber(liquidado),
                despesa_paga: this.funcaoService.convertToBrNumber(pago),
                pagas: this.funcaoService.convertToBrNumber(total0)
            }
            listaExportar.push(linha_grupo)

            const recursos = this.funcaoService.agrupar(grupo.registros, 'recurso',
                ['anterior', 'previsto', 'realizado', 'dotacao', 'empenhado', 'liquidado', 'pago']);
            for (const recurso of recursos) {

                anterior = recurso.totalizadores['anterior'];
                previsto = recurso.totalizadores['previsto'];
                realizado = recurso.totalizadores['realizado'];
                total0 = anterior + realizado + empenhado;
                dotacao = recurso.totalizadores['dotacao'];
                empenhado = recurso.totalizadores['empenhado'];
                liquidado = recurso.totalizadores['liquidado'];
                pago = recurso.totalizadores['pago'];

                const linha_recurso = {
                    orgao: '',
                    fonte_recurso: recurso.grupo,
                    codigo_aplicacao: '',
                    saldo_exercicio_anterior: this.funcaoService.convertToBrNumber(anterior),
                    previstas: this.funcaoService.convertToBrNumber(previsto),
                    realizada: this.funcaoService.convertToBrNumber(realizado),
                    dotacao_atualizada: this.funcaoService.convertToBrNumber(dotacao),
                    despesa_empenhada: this.funcaoService.convertToBrNumber(empenhado),
                    despesa_liquidada: this.funcaoService.convertToBrNumber(liquidado),
                    despesa_paga: this.funcaoService.convertToBrNumber(pago),
                    pagas: this.funcaoService.convertToBrNumber(total0)
                }
                listaExportar.push(linha_recurso)

                for (const item of recurso.registros) {
                    let valorRestoExercicio = 0;
                    this.mes < 11 ? valorRestoExercicio = item.rp_exercicio :
                        valorRestoExercicio = item.rp_exercicio - item.rp_inscricao;
                    const linha = {
                        orgao: '',
                        fonte_recurso: '',
                        codigo_aplicacao: item.aplicacao,
                        saldo_exercicio_anterior: this.funcaoService.convertToBrNumber(item.anterior),
                        previstas: this.funcaoService.convertToBrNumber(item.previsto),
                        realizada: this.funcaoService.convertToBrNumber(item.realizado),
                        dotacao_atualizada: this.funcaoService.convertToBrNumber(item.dotacao),
                        despesa_empenhada: this.funcaoService.convertToBrNumber(item.empenhado),
                        despesa_liquidada: this.funcaoService.convertToBrNumber(item.liquidado),
                        despesa_paga: this.funcaoService.convertToBrNumber(item.pago),
                        pagas: this.funcaoService.convertToBrNumber(+item.anterior + +item.realizado + +item.empenhado)
                    }
                    listaExportar.push(linha)
                }
            }
        }
        return listaExportar
    }

    colunas(): Coluna[] {
        const colunasDefault: Coluna[] = [
            { titulo: 'ÓRGÃO', coluna: 'orgao', bold: true },
            { titulo: 'FONTE DE RECURSOS', coluna: 'fonte_recurso' },
            { titulo: 'CÓDIGO DE APLICAÇÃO', coluna: 'codigo_aplicacao' },
            { titulo: 'Saldo do Exercício Anterior (I)', coluna: 'saldo_exercicio_anterior' },
            { titulo: 'Prevista', coluna: 'previstas' },
            { titulo: 'Realizada (II)', coluna: 'realizada' },
            { titulo: 'Dotação Atualizada', coluna: 'dotacao_atualizada' },
            { titulo: 'Despesa Empenhada (III)', coluna: 'despesa_empenhada' },
            { titulo: 'Despesa Liquidada', coluna: 'despesa_liquidada' },
            { titulo: 'Despesa Paga', coluna: 'despesa_paga' },
            { titulo: '', coluna: 'pagas' },
        ];
        return colunasDefault;
    }

}
