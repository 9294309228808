import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { EddyAutoComplete, EmailService, InventarioSetor, InventarioTransferencia, Login, ParametroPatrimonio, ParametroPatrimonioService, Tombamento } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { InventarioTomboService } from '../service/inventario-tombo.service';
import { InventarioTransferenciaService } from '../service/inventario-transferencia.service';
import { TombamentoService } from 'administrativo-lib';

declare var $: any;

@Component({
  selector: 'app-inventario-transferencia-solicitacao-dlg',
  templateUrl: './inventario-transferencia-solicitacao-dlg.component.html'
})
export class InventarioTransferenciaSolicitacaoDlgComponent implements OnChanges {

  /**
   * Declaração de variáveis
   */
  @Input() public placa: number;
  @Input() public origem: InventarioSetor;
  @Input() public login: Login;
  @Output() callback: EventEmitter<boolean> = new EventEmitter();

  public tombamentoPlaca: Tombamento;
  public entidade: InventarioTransferencia;
  public tipoEmail: 'T' | 'S' | 'SA' | 'SR' | 'I' = 'S';

  public tombamentoAutoComplete: EddyAutoComplete<Tombamento>;
  protected unsubscribe: Subject<void> = new Subject();

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private tombamentoService: TombamentoService,
    private itemService: InventarioTomboService,
    protected parametroPatrimonioService: ParametroPatrimonioService,
    private emailService: EmailService,
    protected confirmationPriService: ConfirmationService,
    private transferenciaService: InventarioTransferenciaService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tombamentoAutoComplete = new EddyAutoComplete(null, this.tombamentoService,
      'id', ['placa', 'produto.nome'], { 'orgao.id': this.login.orgao.id, orderBy: 'placa', relations: 'produto' },
      { number: ['placa'], text: ['produto.nome'] }
    );
    if (changes) {
      if (this.origem) {
        this.iniciarObjeto();
      }
      if (changes['placa'] && this.placa) {
        this.buscar();
      }
    };
  }

  private iniciarObjeto() {
    this.entidade = new InventarioTransferencia();

    this.entidade.origem = this.origem;
    this.entidade.usuario = this.login.usuario;
    this.entidade.status = 'PENDENTE';
    this.entidade.solicitacao = true;

    this.placa = undefined;
  }

  public async buscar() {
    if (!this.placa && !this.tombamentoPlaca) return;

    this.entidade.tombamento = undefined;
    this.entidade.destino = undefined;

    this.itemService.obter({
      'tombamento.placa': this.placa ? this.placa : this.tombamentoPlaca.placa, 'inventario_setor.inventario_id': this.origem.inventario.id, relations: 'tombamento.produto,inventario_setor.setor'
    }).subscribe(res => {
      if (!res) {
        toastr.warning('Nenhum item localizado com a placa informada!');
        return;
      }
      if (res.inventario_setor.id === this.origem.id) {
        toastr.warning('O item informado já pertence ao seu inventário!');
        return;
      }
      if (res.localizado) {
        toastr.warning('O item informado já se encontra como localizado em outro setor!');
        return;
      }
      if (res.tombamento.situacao === 'EXCLUIDO' || res.tombamento.situacao === 'BAIXADO') {
        toastr.warning('Bem Patrimonial excluído ou baixado. Selecione outro bem patrimonial.');
        return;
      }
      if (res.conservacao_atual === 'INSERVIVEL') {
        toastr.warning('Bem inservível, não é possível transferi-lo.');
        return
      }
      if (res.origem === 'TRANSF_PENDENTE') {
        toastr.warning('Bem possui transferência pendente, não é possível transferi-lo.');
        return
      }

      this.entidade.tombamento = res.tombamento;
      this.entidade.destino = res.inventario_setor;
    }, error => {
      toastr.error(error.error.payload)
    });
  }

  public async confirmar() {
    if (!this.placa && !this.tombamentoPlaca) {
      toastr.warning('Informe a placa para busca');
      return
    }
    if (!this.entidade.tombamento) {
      toastr.warning('Selecione o Bem Patrimonial');
      return
    }
    if (!this.entidade.motivo) {
      toastr.warning('Informe a Justificativa');
      return
    }

    await new Promise((resolve, reject) => {
      this.entidade['tipo'] = 'S';
      this.transferenciaService.inserir(this.entidade).subscribe(async res => {
        delete this.entidade['tipo'];
        this.email(this.entidade);
        // if (res.destino.setor.transferencia_automatica) {
        await new Promise((resolve, reject) => {
          this.transferenciaService.confirmar(res.id, this.login.usuario.id, 'S').subscribe(res => {
            resolve(true);
          }, error => {
            toastr.error(error.error.payload);
            reject(error);
          });
        });
        resolve(true);
      }, error => {
        toastr.error(error.error.payload)
        reject(error)
      });
    });

    this.callback.emit(true);
    $('#dialogInventarioTransferenciaSolicitacao').modal('hide');
    this.iniciarObjeto();
  }

  public email(entidade: InventarioTransferencia) {
    this.parametroPatrimonioService.obter({
      'orgao.id': this.login.orgao.id
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(parametroPatrimonio => {
        let mensagem = '';
        if (this.tipoEmail.includes('S'))
          mensagem += `<br />Setor - ${entidade.destino.setor.nome}(${entidade.destino.setor.email})`;

        if (mensagem.length === 0)
          return;

        this.enviarEmail(entidade, parametroPatrimonio);
      });
  }

  public async enviarEmail(solicitacao: InventarioTransferencia, parametro?: ParametroPatrimonio) {
    let subject = `Solicitação de Bem Móvel - Nº: ${solicitacao.tombamento.placa}`;
    let mensagem = `<p>${parametro.mensagem_solicitacao}</p>`;
    let destinatarios: { nome: string; email: string; }[] = [];

    if (this.tipoEmail.includes('S')) {
      destinatarios.push({ nome: solicitacao.destino.setor.nome, email: solicitacao.destino.setor.email });
    }

    if (destinatarios.length) {
      this.emailService.enviar({ titulo: subject, corpo: mensagem, destinos: destinatarios })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(data => toastr.success('E-mail encaminhado com sucesso!'),
          error => toastr.error(`Não foi possivel encaminhar o e-mail de confirmação!`));
    }
  }
}
