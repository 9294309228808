import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { Coluna, FormatoExportacao } from '../../components/types';
import { Usuario } from '../../entidade/comum/usuario.model';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { BaseResourceListComponent, Filtro } from '../../models/base-resource-list';
import { GlobalService } from '../../util/global.service';
import { UsuarioService } from '../service/usuario.service';

@Component({
  selector: 'lib-usuario-list',
  templateUrl: './usuario-list.component.html'
})
export class UsuarioListComponent extends BaseResourceListComponent<Usuario, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public filtro: string;
  public usuario: Usuario = new Usuario();
  public listaStatus: string[] = ['Todos', 'Administrador', 'Ativo', 'Convidado', 'Inativo', 'Pendente']
  public filtroStatus: 'Todos' | 'Administrador' | 'Ativo' | 'Convidado' | 'Inativo' | 'Pendente' = 'Todos';

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public globalService: GlobalService,
    private usuarioService: UsuarioService) {
    super(usuarioService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'orgao,favorecido,estoque,setor';
  }

  protected condicoesGrid(): {} {
    let parametros = {}

    parametros = { ['orgao.id']: this.login.orgao.id };
    
    if (this.filtroStatus === 'Ativo') {
      parametros['ativo'] = true
      parametros['administrador'] = false
      parametros['solicitacao'] = false
      parametros['convidado'] = false
    } else if (this.filtroStatus === 'Convidado') {
      parametros['ativo'] = true
      parametros['administrador'] = false
      parametros['solicitacao'] = true
      parametros['convidado'] = true
    } else if (this.filtroStatus === 'Administrador') {
      parametros['ativo'] = true
      parametros['administrador'] = true
    } else if (this.filtroStatus === 'Pendente') {
      parametros['ativo'] = false
      parametros['solicitacao'] = true
    } else if (this.filtroStatus === 'Inativo') {
      parametros['ativo'] = false
      parametros['solicitacao'] = false
    }

    return parametros
  }

  protected ordenacaoGrid(): string[] {
    return ['id$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['id', 'cpf'],
      text: ['nome', 'sobrenome', 'email', 'telefone', 'sistema']
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: Usuario): Observable<Usuario> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Cód', coluna: 'id' },
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'Sobrenome', coluna: 'sobrenome' },
      { titulo: 'E-mail', coluna: 'email' },
      { titulo: 'CPF', coluna: 'cpf' },
      { titulo: 'Telefone', coluna: 'telefone' },
      { titulo: 'Sistema', coluna: 'sistema' },
      { titulo: 'Setor', coluna: 'setor.nome' },
      { titulo: 'Secretária', coluna: 'setor.unidade.nome' },
      { titulo: 'Tipo de Perfil', coluna: 'tipoPerfil' },
      { titulo: 'Status', coluna: 'status' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'orgao,setor,setor.unidade';
    this.usuarioService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          lista.content.map(usuario => {
            //status
            if (usuario.ativo && usuario.administrador && !usuario.solicitacao && !usuario.convidado)
              usuario['status'] = 'Ativo'
            else if (usuario.ativo && !usuario.administrador && !usuario.solicitacao && !usuario.convidado)
              usuario['status'] = 'Ativo'
            else if (!usuario.ativo && usuario.solicitacao)
              usuario['status'] = 'Pendente'
            else if (!usuario.ativo && !usuario.solicitacao)
              usuario['status'] = 'Inativo'
            //tipo de perfil
            if (usuario.ativo && !usuario.administrador && usuario.solicitacao && usuario.convidado)
              usuario['tipoPerfil'] = 'Convidado'
            else if (usuario.ativo && usuario.administrador)
              usuario['tipoPerfil'] = 'Administrador'
            else if (!usuario.ativo && usuario.administrador)
              usuario['tipoPerfil'] = 'Administrador'
            else if (usuario.ativo && !usuario.administrador)
              usuario['tipoPerfil'] = 'Usuário Padrão'
            else if (!usuario.ativo && !usuario.administrador && !usuario.solicitacao)
              usuario['tipoPerfil'] = 'Usuário Padrão'
            else if (!usuario.ativo && usuario.solicitacao)
              usuario['tipoPerfil'] = '          - '
            //setor
            if (usuario.setor === null)
              usuario['setor.nome'] = '          - '
            //secretaria
            if (usuario.setor?.unidade === null)
              usuario['setor.unidade.nome'] = '          - '
            else if (!usuario.setor?.unidade)
              usuario['setor.unidade.nome'] = '          - '

            return usuario;
          });

          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE USUÁRIOS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem usuarios', ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
