import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrgaoShwComponent } from './orgao-shw/orgao-shw.component';
import { OrgaoLstComponent } from './orgao-lst/orgao-lst.component';
import { AuthGuard } from '../util/auth.guard';
import { OrgaoViewComponent } from './orgao-view/orgao-view.component';

const routes: Routes = [
  { path: '', component: OrgaoLstComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: OrgaoShwComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: OrgaoShwComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: OrgaoViewComponent, canActivate: [AuthGuard] },

];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrgaoRoutingModule { }
