import { Component, Injector, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { Favorecido } from '../../entidade/compra/favorecido.model';
import { Login } from '../../entidade/login/login';
import { FavorecidoService } from '../../favorecido/service/favorecido.service';
import { Filtro } from '../../models/base-resource-list';
import { FuncaoService } from '../../util/funcao.service';
import { GlobalService } from '../../util/global.service';
import { EmailService } from '../service/email.service';

declare var $: any;

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'lib-email-personalizado',
  templateUrl: './email-personalizado.component.html',
  styleUrls: ['./email-personalizado.component.css'],
})
export class EmailPersonalizadoComponent implements OnInit, OnDestroy {

  /**
   * Declaração de variáveis
   */
  public login: Login;
  private router: Router;
  private unsubscribe: Subject<void> = new Subject();

  public listaOpcoes: { id: string, nome: string, condicoes: Filtro }[];
  public opcao: { id: string, nome: string, condicoes: Filtro };
  public filtro: string;
  public marcado: boolean;
  public lista: { marcado: boolean, nome: string, email: string, valido: boolean }[]
  public listaEnviados: { nome: string, email: string, ok: boolean }[]

  public titulo: string;
  public corpo: string;
  public anexos: any[] = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private injector: Injector,
    public funcaoService: FuncaoService,
    private favorecidoService: FavorecidoService,
    private emailService: EmailService,
  ) {
    this.login = GlobalService.obterSessaoLogin();
    this.router = this.injector.get(Router);
  }

  ngOnInit(): void {
    this.listaOpcoes = [
      { id: '*', nome: 'Selecione uma opção', condicoes: undefined },
      {
        id: 'FORNECEDOR', nome: 'Fornecedores', condicoes: {
          number: ['cpf_cnpj'], text: ['nome', 'email'],
        }
      },
    ]
    this.opcao = this.listaOpcoes[0]
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
  public sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public preencherGrid() {
    if (!this.opcao || this.opcao.id === '*') return;

    this.lista = [];
    this.marcado = false;

    if (this.opcao.id === 'FORNECEDOR') {
      this.favorecidoService.filtrar(0, 30,
        Object.assign({}, this.funcaoService.obterParametros(this.opcao.condicoes, this.filtro), { orderBy: 'nome' })
      ).pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          if (res && res.content)
            (res.content as Favorecido[]).forEach(f => this.lista.push({ nome: f.nome, email: f.email, marcado: false, valido: this.funcaoService.validarEmail(f.email) }))
          this.toggleSelecionado();
          this.marcado = true;
        })
    }
  }

  public toggleSelecionado() {
    // marcado === false marcado
    // marcado === true desmarcado
    if (!this.lista) return

    this.lista.forEach(item => item.marcado = (item.valido) ? !this.marcado : false)
  }

  public async onUpload(event: any, fileUpload: any) {
    if (!event.files || event.files.length === 0) {
      toastr.warning('Selecione o(s) arquivo(s)');
      return;
    }
    try {
      for (const file of event.files) {
        this.anexos.push(file);
      }
    } catch (ex) {
      this.funcaoService.acaoErro(ex)
    }
    fileUpload.clear();
  }

  public removerAnexo(item: any) {
    if (!item || this.anexos.length === 0) return
    this.anexos.splice(this.anexos.indexOf(item), 1)
  }


  public enviarEmails() {
    if (!this.titulo || !this.titulo.trim()) {
      toastr.warning('Informe o título do e-mail')
      return;
    }
    if (!this.corpo || !this.corpo.trim()) {
      toastr.warning('Informe o corpo do e-mail')
      return;
    }
    if (!this.lista || this.lista.length === 0) {
      toastr.warning('Informe os destinatários')
      return;
    }

    const emails = this.lista.filter(item => item.marcado && item.valido);
    if (!emails || emails.length === 0) {
      toastr.warning('Informe os destinatários')
      return;
    }

    let destinos: { nome: string, email: string }[] = []
    for (const email of emails) destinos.push({ email: email.email, nome: email.nome })

    let corpoEmail: string = this.corpo;
    if (corpoEmail) corpoEmail = corpoEmail.split('class=\"ql-align-').join('style=\"text-align : ');

    this.emailService
      .enviar({ titulo: this.titulo, corpo: corpoEmail, destinos, anexos: this.anexos })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        $('#dialogEmailsEnviados').modal('show');
        this.listaEnviados = res;
      }, error => this.funcaoService.acaoErro(error));
  }
}
