import { AfterContentChecked, AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoComplete } from 'primeng/autocomplete';
import { Calendar } from 'primeng/calendar';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { DateFormatPipe } from '../components/pipe/date-format.pipe';
import { Login } from '../entidade/login/login';
import { TabelasService } from '../tabela/service/tabela.service';
import { FuncaoService } from '../util/funcao.service';
import { GlobalService } from '../util/global.service';
import { BaseResourceModel } from './base-resource.model';
import { BaseResourceService } from './services/base-resource.service';

declare var $: any;

@Directive()
export abstract class BaseResourceFormComponent<T extends BaseResourceModel, U extends Login>
  implements OnInit, AfterContentChecked, AfterViewInit, OnDestroy, OnChanges {

  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  @Input() public id: number;
  @Output() public idChange: EventEmitter<number> = new EventEmitter();
  @Input() public component: boolean = false;

  public ptBR: any;
  public login: U;
  public pagTitulo: string;
  public entidadeForm: FormGroup;
  public currentActionRoute: 'novo' | 'editar';
  public soVisualizar: boolean = false;
  public disableButton = false;
  public serverErrorMessages: string[] = null;
  public limparTela = false;
  public skipRedirect = false;
  public inclusao: boolean;

  public camposClasse: { nome: string, campo: string, tipo: string, nomeClasseSimples: string, classe: string }[] = [];

  submitted = false;

  public imaskConfig = {
    mask: Number,
    scale: 2,
    signed: true,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  public imaskConfigNumber = {
    mask: Number,
    scale: 2,
    signed: true,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ',',
    mapToRadix: [',']
  };

  public imaskValor4 = {
    mask: Number,
    scale: 4,
    signed: true,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  public imaskQtd = {
    mask: Number,
    scale: 0,
    signed: true,
  };

  public imaskValor = {
    mask: Number,
    scale: 5,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  public imaskDateGt = {
    mask: Date,
    pattern: 'd{/}`m{/}`Y',  // Pattern mask with defined blocks, default is 'd{.}`m{.}`Y'
    min: new Date(),
    autofix: false,
    overwrite: true,
    parse: function (str) {
      const yearMonthDay = str.split('.');
      return new Date(yearMonthDay[2], yearMonthDay[1] - 1, yearMonthDay[0]);
    },
  };
  public imaskDate = {
    mask: Date,
    pattern: 'd{.}`m{.}`Y',  // Pattern mask with defined blocks, default is 'd{.}`m{.}`Y'
    autofix: false,
    overwrite: true,
    parse: function (str) {
      const yearMonthDay = str.split('.');
      return new Date(yearMonthDay[2], yearMonthDay[1] - 1, yearMonthDay[0]);
    },
  };
  public imaskProcesso = {
    mask: [
      {
        mask: '0000/0000'
      },
      {
        mask: '00000/0000'
      }
    ]
  };

  /**
   * Caso verdadeira, o formulário não persistirá as informações informadas
   */
  public readOnly = false;

  /**
   * Caso verdadeira, o sistema vai adicionar automaticamente limite de tamanho ao campos
   */
  public validTamanhoForm = false;
  /**
   * Caso verdadeira, o sistema personaliza nome dos campos
   */
  public camposPersonalizados = true;

  /**
   * Atributo utilizado para controlar caso o componente que esteja estendendo o BaseResourceForm
   * também utilize outro componente que também estenda o BaseResource
   */
  public formularioPrincipal: boolean = true;

  public mensagemSucesso = 'Solicitação processada com sucesso!';
  protected router: Router;
  protected fb: FormBuilder;
  protected activatedRoute: ActivatedRoute;
  protected tabelaService: TabelasService;

  protected unsubscribe: Subject<void> = new Subject();
  private admin: boolean;
  private ignorarRota = false;

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get f() { return this.entidadeForm.controls; }

  // ========================================================================
  // ------------------------------ CONSTRUTOR ------------------------------
  // ========================================================================
  constructor(
    public entidade: T,
    protected injector: Injector,
    protected jsonDataToResourceFn: (json: any) => T,
    protected baseResourceService: BaseResourceService<T>) {
    this.router = this.injector.get(Router);
    this.fb = this.injector.get(FormBuilder);
    this.activatedRoute = this.injector.get(ActivatedRoute);
    this.tabelaService = this.injector.get(TabelasService);
  }

  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================
  /**
   * Método para vincular os campos da entidade no formbuilder,
   * executado dentro do método `ngOnInit()` entre `setCurrentActionRoute` e `loadResource()`
   * @see `ngOnInit`
   * @example this.entidadeForm = this.fb.group({
   * id: [null],
   * nome: [null, Validators.required]
   * });
   */
  protected abstract criarCamposForm(): void;
  /**
   * Método com os parâmetros extras necessários para a listagem
   * @example {
   * relations: orgao,orgao.cidade,usuario,
   * orderBy: 'item.id'
   * }
   */
  protected abstract parametrosExtras(): {};
  /**
   * Método para tratativas após carregamento da entidade,
   * executado dentro do `loadResource()` antes do `patchValue` do formGroup
   */
  protected abstract afterLoad(): void;
  /**
   * Método executado dentro do método `ngOnInit()` após o `loadResource()`
   * @see `ngOnInit`
   */
  protected abstract afterInit(): void;
  /**
   * Método com as validações antes de submeter o formulário, executado dentro do método `submitForm()`
   * @see `submitForm`
   */
  protected abstract beforeSubmit(): void | Promise<void>;
  /**
   * Método com as validações após de submeter o formulário, executado dentro do método `acaoSucesso()`
   * @see `acaoSucesso`
   */
  protected abstract afterSubmit(entidade: T): void;

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================
  ngOnInit() {
    // if (this.component)
    //   return;
    this.onInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.component && changes.id && this.id)
      this.onInit();
  }

  private onInit() {
    this.login = GlobalService.obterSessaoLogin() as U;
    this.ptBR = new GlobalService().obterDataBR();
    toastr.options.positionClass = 'toast-top-left';
    this.beforeInit();
    this.skipRedirect = false;
    new GlobalService().convertEnterParaTab();
    if ((this.baseResourceService.retornarRota().includes('novo') || this.baseResourceService.retornarRota().includes('editar')) && !this.podeIncluir()) {
      toastr.warning('Você não possui permissão para este acesso!');
      this.sair();
      return;
    }

    if (this.camposPersonalizados)
      this.obterCampos();

    this.setCurrentActionRoute();
    if (!this.readOnly) {
      this.criarCamposForm();
      if (this.validTamanhoForm)
        this.incluirLimiteCampos();
    }
    if (this.formularioPrincipal) {
      this.loadResource().then(() => {
        window.scrollTo(0, 0);
        if (!this.readOnly && this.campoFoco()) {
          new FuncaoService().focarCampo(this.campoFoco());
        }
      });
    }
    this.afterInit();
  }

  ngAfterContentChecked() {
    this.setTitulo();
  }

  ngAfterViewInit() {
    new GlobalService().calendarMascara();
  }

  async submitForm(limpa?: boolean, callback?: (entidade: T) => void) {
    $('button').blur();
    if (this.readOnly || this.soVisualizar) {
      toastr.error('Formulário em modo de apenas leitura');
      return;
    }
    this.limparTela = limpa ? true : false;
    this.disableButton = true;
    await this.beforeSubmit();
    this.inclusao = !new Boolean(this.entidadeForm.get('id').value).valueOf();
    this.submitted = true;
    if (this.entidadeForm.invalid) {
      const invalidos: string[] = [];
      let controls = this.entidadeForm.controls;
      Object.entries(controls).forEach(([key, value]) => {
        if (value.invalid) invalidos.push(key)
      });
      toastr.error(invalidos.map((c) => {
        if (this.camposClasse) {
          let campo = this.camposClasse.find((cl) => (cl.campo === c && !cl.classe) || cl.classe === c);
          return campo ? (campo.classe ? campo.nomeClasseSimples : campo.nome) : c;
        } else return c
      }).join(', '), 'Há campos obrigatórios não informados ou inválidos!');
      return;
    }
    if (this.currentActionRoute === 'novo') {
      this.criarEntidade(callback);
    } else {
      this.atualizarEntidade(callback);
    }
  }

  public podeIncluir(url?: string) {
    if (this.login) {
      if (this.administrador()) {
        return true;
      }
      if (!url) {
        url = this.baseResourceService.retornarRota();
      }
      return new FuncaoService().podeIncluir(this.login, url)
    }
    return false;
  }

  public podeAlterarAudesp(data: Date): boolean {
    if (!data || this.soVisualizar) {
      return true;
    }
    if (this.login['ultimoAudesp'] && data) {
      data = new Date(data);
      const mes = +this.login['ultimoAudesp'].split('-')[1];
      const ano = +this.login['ultimoAudesp'].split('-')[0];
      if ((data.getFullYear() < ano || (data.getFullYear() === ano && (+data.getMonth() + 1) <= mes)) && mes < 12)
        return false;
    }
    if (this.login['dias_bloquear_alteracoes']) {
      return new FuncaoService().diferencaEmDias(new Date(), new Date(data)) < this.login['dias_bloquear_alteracoes'];
    } else {
      return true;
    }
  }

  protected async podeAlterar(_entidade: T): Promise<boolean> {
    return true;
  }

  public podeVisualizar(url?: string) {
    if (this.login) {
      if (this.administrador()) {
        return true;
      }
      if (!url) {
        url = this.baseResourceService.retornarRota();
      }
      return new FuncaoService().podeIncluir(this.login, url, true)
    }
    return false;
  }

  administrador() {
    if (this.admin === null || this.admin === undefined) {
      this.admin = (new FuncaoService().campoJsonToken(this.login.token, 'administrador') == true) && this.login.usuario.sistemas_administrador?.includes(this.login.usuario.sistema);
    }
    return this.admin;
  }

  protected setCurrentActionRoute() {
    if (this.component) {
      this.currentActionRoute = 'editar';
      this.soVisualizar = false;
    } else {
      this.currentActionRoute = undefined;
      for (const url of this.activatedRoute.snapshot.url) {
        if (url.path === 'novo' || url.path === 'editar' || url.path === 'visualizar' || url.path === 'visualiza') {
          this.currentActionRoute = url.path === 'novo' ? 'novo' : 'editar';
          if (this.login?.usuario?.sistema === 'transparencia') {
            this.soVisualizar = false;
          } else {
            this.soVisualizar = url.path == 'visualiza' || url.path == 'visualizar' ? true : false;
          }
          break;
        }
      }
      if (!this.currentActionRoute) {
        const paramUrl = this.activatedRoute.snapshot.url[this.activatedRoute.snapshot.url.length - 1];
        if (paramUrl && paramUrl.path === 'novo') {
          this.currentActionRoute = 'novo';
        } if (paramUrl && paramUrl.path === 'editar') {
          this.currentActionRoute = 'editar';
        } else {
          this.currentActionRoute = 'novo';
          this.ignorarRota = true;
        }
      }
    }
  }

  private desabilitarCampos() {
    //Comentado até ter tempo para testar em mais telas
    if (this.soVisualizar) {
      if (this.entidadeForm?.controls) {
        Object.keys(this.entidadeForm.controls).forEach(k => {
          this.entidadeForm.controls[k + ''].disable();
        });
      }

      $("input").prop("disabled", true);//Desabilita todos campos do tipo input
      $("select").prop("disabled", true);//Desabilita todos campos do tipo input
      $("button").prop("disabled", true);//Desabilita todos os botões da tela
      $("button").hide();//Esconde da visualizar os botões da tela.

      $('.modal-content').each(function () {
        $(this).find('input').each(function () {
          $(this).prop("disabled", false);
        });
        $(this).find('select').each(function () {
          $(this).prop("disabled", false);
        });
        $(this).find('button').each(function () {
          $(this).prop("disabled", false);
          $(this).show();
        });
      });
    }
  }

  protected async loadResource() {
    if (this.currentActionRoute === 'editar') {
      if (!this.component) {
        this.activatedRoute
          .paramMap
          .pipe(switchMap(params => this.baseResourceService.obter(
            Object.assign({}, { id: +params.get('id') }, this.parametrosExtras())
          )))
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (entidade) => {
              if (entidade) {
                this.entidade = entidade;
                //Após carregar o entidade, verifica se realmente pode editar a tela
                this.podeAlterar(entidade).then((res) => {
                  if (!res && !this.soVisualizar) {//Se vier falso, não pode editar
                    this.router.navigate([this.baseResourceService.retornarRota().replace('/editar', '/visualizar')]);
                  } else {
                    if (this.datePipe())
                      for (let pipe of this.datePipe()) {
                        if (this.entidade[pipe])
                          this.entidade[pipe] = new DateFormatPipe().transform(this.entidade[pipe], [])
                      }
                    this.afterLoad();
                    if (!this.readOnly) {
                      this.entidadeForm.patchValue(entidade);
                      this.desabilitarCampos();
                    }
                  }
                });
              } else {
                this.sair();
              }
            }, (error) => {
              this.sair();
            });
      } else {
        this.baseResourceService.obter(
          Object.assign({}, { id: this.id }, this.parametrosExtras())
        ).pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (entidade) => {
              if (entidade) {
                this.entidade = entidade;
                //Após carregar o entidade, verifica se realmente pode editar a tela
                this.podeAlterar(entidade).then((res) => {
                  if (!res) {
                    this.router.navigate([this.baseResourceService.retornarRota().replace('/editar', '/visualizar')]);
                  } else {
                    if (this.datePipe())
                      for (let pipe of this.datePipe()) {
                        if (this.entidade[pipe])
                          this.entidade[pipe] = new DateFormatPipe().transform(this.entidade[pipe], [])
                      }
                    this.afterLoad();
                    if (!this.readOnly) {
                      this.entidadeForm.patchValue(entidade);
                      this.desabilitarCampos();
                    }
                  }
                });
              } else {
                this.sair();
              }
            }, (error) => {
              this.sair();
            });
      }
    }
  }

  protected setTitulo() {
    if (this.currentActionRoute === 'novo') {
      this.pagTitulo = this.criarPaginaTitulo();
    } else {
      this.pagTitulo = this.editarPaginaTitulo();
    }
  }

  protected criarPaginaTitulo(): string {
    return 'Novo';
  }

  protected editarPaginaTitulo(): string {
    return '';
  }

  protected criarEntidade(callback?: (entidade: T) => void) {
    if (this.readOnly) {
      toastr.error('Formulário em modo de apenas leitura');
      return;
    }
    const entidade: T = this.jsonDataToResourceFn(this.entidadeForm.getRawValue());
    this.baseResourceService
      .inserir(entidade)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.submitted = false;
        this.acaoSucesso(res);
      },
        error => this.acaoErro(error));
  }

  protected atualizarEntidade(callback?: (entidade: T) => void) {
    if (this.readOnly) {
      toastr.error('Formulário em modo de apenas leitura');
      return;
    }
    const entidade: T = this.jsonDataToResourceFn(this.entidadeForm.getRawValue());
    this.baseResourceService
      .atualizar(entidade)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.submitted = false;
        if (!callback)
          this.acaoSucesso(res);
        else
          callback(res);
      },
        error => this.acaoErro(error));
  }

  protected acaoSucesso(entidade: T) {
    this.afterSubmit(entidade);
    if (this.mensagemSucesso) toastr.success(this.mensagemSucesso);
    if (!this.skipRedirect) {
      let url: string = this.baseResourceService.retornarRota();
      if (this.limparTela) { // salvar e novo
        if (url.includes('novo')) {
          const rota = this.baseResourceService.retornarRota();
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate([rota]));
        } else {
          this.router.navigate([this.baseResourceService.retornarRota().replace(/[0-9].*\/editar/g, 'novo')]);
        }
      } else { // salvar
        if (url.includes('novo')) {
          let params = '';
          if (url.lastIndexOf('/novo') > 0) {
            params = url.substring(url.indexOf('/novo') + +'/novo'.length);
            url = url.substring(0, url.substring(1, url.length).indexOf('/novo') + 1);
          }
          url = url + '/' + entidade.id + '/editar' + params;
          this.router.navigate([url]);
        }
      }
    }
    this.limparTela = false;
  }

  protected acaoErro(error: any) {
    toastr.options.timeOut = 10000;
    toastr.options.closeButton = true;
    toastr.options.tapToDismiss = false;
    if (error.error && error.error.payload) {
      toastr.error(error.error.payload);
    } else {
      toastr.error('Ocorreu um erro ao processar a sua solicitação');
    }
    this.disableButton = false;

    if (error.status === 422) {
      this.serverErrorMessages = JSON.parse(error._body).errors;
    } else {
      this.serverErrorMessages = ['Falha na comunicação com o servidor. Por favor, tente mais tarde'];
    }
  }

  /**
   * Método com campo a ser incluído como foco principal
   */
  protected campoFoco(): ElementRef | AutoComplete | Calendar {
    return null;
  }

  /**
   * Método com campo a ser incluído como foco principal
   */
  protected datePipe(): string[] {
    return null;
  }

  /**
   * Método para verificação de objetos, usados em combos `<select>`
   */
  compareFn(c1: any, c2: any): boolean {
    // console.log(JSON.stringify(c1) + ' - ' + JSON.stringify(c2))
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 == c2;
      }
    } else {
      return false;
    }
  }

  /**
   * Método para retornar a página inicial do sistema logado em `login.usuario.sistema`
   */
  sair() {
    if (this.component) {
      this.id = null;
      this.idChange.emit(this.id);
    } else
      new FuncaoService().navegarPara(this.login.usuario.sistema, this.router);
  }


  public obterCampos() {
    this.tabelaService.obterCampos(this.entidade.constructor.name, { soment_camp_classe: true })
      .subscribe((campos) => {
        this.camposClasse = campos;
      }, (_) => { });
  }

  incluirLimiteCampos() {
    if (this.entidadeForm)
      this.baseResourceService.getEstrutura()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((t) => {
          let controls = this.entidadeForm.controls;
          Object.entries(controls).forEach(([key, value]) => {
            t.colunas.forEach((c) => {
              if (key === c.nome) {
                value.addValidators([Validators.maxLength(c.tamanho)]);
                $(`[formControlName='${key}']`).attr('maxlength', c.tamanho.toString())
              }
            })
          });
          this.entidadeForm.updateValueAndValidity();
        }, (error) => toastr.error(error.error.payload))
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'F2') {
      this.submitForm(true);
    }
  }

  /**
   * Método executado ao iniciar o `ngOnInit()`
   *
   * @see `ngOnInit`
   */
  public beforeInit(): void {
  }

}
