import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DespesaService, ProponenteService } from 'administrativo-lib';
import { Compra, CompraItem, FuncaoService, GlobalService, LoginContabil } from 'eddydata-lib';
import { FichaDespesaService } from 'contabil-lib';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-compra-selecionar-item-dlg',
  templateUrl: './compra-selecionar-item-dlg.component.html'
})
export class CompraSelecionarItemDlgComponent implements OnInit, OnChanges {

  @Input() public login: LoginContabil;

  @Input() compra: Compra = undefined;

  @Input() lista: CompraItem[] = []
  @Output() finalizado: EventEmitter<CompraItem[]> = new EventEmitter();

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Input() processo: boolean = false;

  /**
   * Declaração de variáveis
   */

  public selAll: boolean = false;
  public filtro: string = '';
  public aviso: string = '';

  public criterioOrdenacao: 'nome' | 'cod_produto' = 'nome'
  public ordemOrdenacaoPorCodProduto: 'desc' | 'asc'  = 'desc'
  public ordemOrdenacaoPorNome: 'desc' | 'asc'  = 'desc'

  public imaskValor = {
    mask: Number,
    scale: 5,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public despesaService: DespesaService,
    public fichaDespesaService: FichaDespesaService,
    public proponenteService: ProponenteService,
    public globalService: GlobalService) {
  }

  ngOnChanges(changes: SimpleChanges): void { }

  ngOnInit(): void { }


  public titulo() {
    if (this.processo)
      return 'Selecione os itens do processo que deseja requisitar';
    return 'Selecione os itens do contrato que deseja requisitar';
  }

  public finalizar() {
    if (!this.validar())
      return;
    this.finalizado.emit(this.listaSel(true));
    this.hide();
  }

  public validar(): boolean {
    if (this.listaSel(true).length === 0) {
      toastr.warning(`Nenhum item selecionado.`);
      return false;
    }
    for (let item of this.lista) {
      if (item['selecionado'] && item['quantidade_import'] > item['saldo_qtd'] && !this.compra?.contrato?.saldo_valor) {
        toastr.warning(`quantidade informada para o item ${item.produto_unidade.produto.nome} excede o saldo atual de ${new FuncaoService().convertToBrNumber(item['saldo_qtd'], 5)}`);
        return false;
      }
    }
    return true;
  }

  public selecionar(item: CompraItem, sel: boolean) {
    item['selecionado'] = sel;
  }

  public listaSel(sel: boolean): CompraItem[] {
    let lista = this.lista.filter((c) => sel ? c['selecionado'] : !c['selecionado']);
    if (!sel && this.filtro)
      lista = lista.filter((f) => {
        if (f.produto_unidade.produto.nome.toLowerCase().includes(this.filtro.toLowerCase()))
          return true;
        if (f.produto_unidade.unidade.nome.toLowerCase().includes(this.filtro.toLowerCase()))
          return true;
        if (f.produto_unidade.produto.codigo.toLowerCase().includes(this.filtro.toLowerCase()))
          return true;
      });
    return lista;
  }

  public selecionarTodos() {
    const selecionado = this.todosSelecionados();
    if (this.lista) {
      this.lista.forEach((i) => {
        if (!i['vantagem_bloqueio'] && !i['bloqueado'] && +i['saldo_qtd'] > 0)
          i['selecionado'] = !selecionado
      });
    }
  }

  public todosSelecionados(): boolean {
    return this.lista.filter((i) => !i['selecionado'] && !i['vantagem_bloqueio'] && !i['bloqueado'] && +i['saldo_qtd'] > 0).length === 0
  }

  public onClick(filtro: string) {
    this.filtro = filtro;
  }

  public onHide() {
    this.hide();
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public reordenarPorNome(){
 
    this.ordemOrdenacaoPorNome = this.ordemOrdenacaoPorNome == 'desc' ? 'asc' : 'desc'
    const valorRetorno =  this.ordemOrdenacaoPorNome == 'asc' ? 1 : -1;

        this.lista.sort(
               function(a,b){
                if(a.produto_unidade.produto.nome >  b.produto_unidade.produto.nome){
                  return valorRetorno
                }
                             
               if(a.produto_unidade.produto.nome <  b.produto_unidade.produto.nome)
               {
                return  -1 * valorRetorno
               }               
               return 0
            }
        )
}

public reordenarPorCodProduto(){

  this.ordemOrdenacaoPorCodProduto = this.ordemOrdenacaoPorCodProduto == 'desc' ? 'asc' : 'desc' 
  const valorRetorno =  this.ordemOrdenacaoPorCodProduto == 'asc' ? 1 : -1;

      this.lista.sort(
             function(a,b){
              if(a.produto_unidade.produto.codigo >  b.produto_unidade.produto.codigo){
                return valorRetorno
              }
                           
             if(a.produto_unidade.produto.codigo <  b.produto_unidade.produto.codigo)
             {
              return  -1 * valorRetorno
             }               
             return 0
          }
      )   
}




  public bloqueio() {
    if (!this.lista || this.lista.length === 0)
      return false;
    
    for (const item of this.lista) {
      if (item['mensagem_bloqueio']) {
        this.aviso = item['mensagem_bloqueio'];
        return true;
      }
    }
  }
}
