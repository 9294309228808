export enum Pagina {

    'Abastecimentos' = '/abastecimentos',
    'Abertura Diários' = '/abertura-diarios',
    'Abertura' = '/abertura-exercicio',
    'Adiantamentos' = '/adiantamentos',
    'Agendamentos' = '/agendamentos',
    'Alíquotas' = '/aliquotas',
    'Anexos de Contratos e Aditivos' = '/admin/transparencia/contratos-anexos',
    'Anexos de Empenhos Orçamentários' = '/admin/transparencia/empenhos-orcamentarios-anexos',
    'Anexos de Licitação' = '/admin/transparencia/licitacoes-anexos',
    'Anexos LDO' = '/anexos-ldo',
    'Anexos LOA' = '/anexos-loa',
    'Anexos LRF' = '/anexos-lrf',
    'Anexos PPA' = '/anexos-ppa',
    'Almoxarifado' = '/almoxarifado',
    'Aplicação de Recursos' = '/aplicacao-recursos',
    'Contas bancárias entidades' = '/contas-bancarias-entidades',
    'Aplicação Variável de Recursos' = '/aplicacao-variavel-recursos',
    'Arquivos de Transparência' = '/transparencia/transparencia-arquivos',
    'De para recursos AUDESP->SICONFI' = '/recursos-siconfi-audesp',
    'Legislação do Orçamento' = '/legislacao-orcamento',
    'De-para plano de contas' = '/plano-contas-anterior',
    'Assinaturas' = '/assinaturas',
    'Assuntos' = '/assuntos',
    'Atas de Registro de Preços - Anexo' = '/admin/transparencia/atas-registro-anexos',
    'AUDESP (layouts)' = '/layout-audesp',
    'AUDESP (validações)' = '/validacao-audesp',
    'Audesp Fase 4' = '/licitacoes-audesp',
    'AUDESP' = '/audesp-contabil',
    'Audesp-TCE-SP' = '/importar-audesp',
    'Audiências públicas' = '/audiencias-publica',
    'Auditoria de Adiantamentos' = '/auditoria-adiantamentos',
    'Auditoria de Avaliações' = '/auditoria-avaliacoes',
    'Auditoria de Contratos' = '/auditoria-contratos',
    'Auditoria de Empenhos' = '/auditoria-empenhos',
    'Auditoria de Licitações' = '/auditoria-licitacoes',
    'Auditoria de Patrimônios' = '/auditoria-patrimonios',
    'Auditoria' = '/auditoria',
    'Autorização de Abastecimentos' = '/autorizacao-abastecimentos',
    'Autorização Requisição Compras' = '/rcms-autorizacao',
    'Autorização Requisição Materiais' = '/requisicoes-autorizacao',
    'Autorização Requisição Materiais Devolução' = '/requisicoes-autorizacao-devolucao',
    'Autorização Requisição - Compras' = '/rcms/autorizacao',
    'Autorização Visualizar Todas Compras' = '/visualizar-todas-compras',
    'Ações do governo' = '/acoes-governo',
    'Ações' = '/acoes',
    'Baixas' = '/baixas-patrimonio',
    'Baixa Automática Reserva' = '/baixa-automatica-reserva',
    'Baixas Merenda' = '/baixas-merenda',
    'Balancetes' = '/balancetes',
    'Balanços' = '/balancos',
    'Bancos' = '/bancos',
    'Bens Imóveis' = '/imoveis',
    'Bens Móveis - Incorporação' = '/tombamentos-incorporacao',
    'Bens Móveis' = '/tombamentos',
    'Cardápios' = '/cardapios',
    'Cadastros Auxiliares' = '/cadastros-auxiliares',
    'Cargo - Frota' = '/cargos-frota',
    'Cargos' = '/cargos',
    'Chamamentos' = '/chamamentos',
    'Cancelamento Resto' = '/cancelamento-restos-pagar',
    'Restos Anulados' = '/restos-anulados',
    'Cheques Layout' = '/cheques-layout',
    'Cidadãos' = '/cidadaos',
    'Combustíveis' = '/combustiveis',
    'Comissões' = '/comissoes',
    'Comunicados' = '/comunicados',
    'Conferência Contábil Patrimônio' = '/conferencia-contabil',
    'Conferências' = '/conferencias',
    'Consignações em Aberto' = '/consignacoes-aberto',
    'Consulta de Empenhos Orçamentários' = '/consulta-empenhos',
    'Contas bancárias' = '/contas-bancarias',
    'Conciliações bancárias' = '/conciliacoes-bancarias',
    'Movimentos Diários' = '/movimentos-diarios',
    'Contratos' = '/contratos',
    'Controle Avançado de Conservação' = '/controle-avancado-conservacao',
    'Convênios' = '/convenios',
    'Credenciamentos' = '/credenciamentos',
    'Créditos Adicionais' = '/creditos',
    'Transferências Internas' = '/transferencias-internas',
    'Departamentos' = '/departamentos',
    'Depreciação' = '/depreciacoes',
    'Despesas Empenhadas Mensais' = '/despesa-nivel',
    'Despesas Orçamentárias' = '/despesas-orcamentaria',
    'Despesas por Função' = '/despesa-funcao',
    'Despesas Previstas' = '/despesas-prevista',
    'Deslocamentos' = '/deslocamentos',
    'Diário Oficial' = '/diario-oficial',
    'DIRF' = '/dirf',
    'Dívidas Fornecedor' = '/dividas-fornecedor',
    'Documentos Fiscais' = '/documentos-fiscais',
    'E-mails Personalizados' = '/emails-personalizados',
    'EFD Reinf' = '/efd-reinf',
    'Emissão de Cheques' = '/emissao-cheque',
    'Empenhos de Restos' = '/empenhos-resto',
    'Empenhos Extra-orçamentários' = '/empenhos-extra',
    'Empenhos Orçamentários' = '/empenhos-orcamentario',
    'Empréstimos' = '/emprestimos-patrimonio',
    'Empréstimo' = '/emprestimos',
    'Encerramento Anual' = '/encerramento-anual',
    'Encerramento Contábil' = '/encerramento-almoxarifado',
    'Entidade Partidos' = '/entidade-partidos',
    'Entradas' = '/entradas',
    'Escriturações' = '/escrituracoes',
    'Estatísticas' = '/estatisticas',
    'Estoques' = '/estoques',
    'Etapas' = '/etapas',
    'Eventos (Holerite)' = '/admin/transparencia/eventos-holerite',
    'Eventos Agendados' = '/schedules',
    'Eventos Contábeis' = '/eventos-contabeis',
    'Exercícios' = '/exercicios',
    'Expediêntes' = '/expedientes',
    'Exportação RCMS' = '/exportacao-rcms',
    'Favorecidos' = '/favorecidos',
    'Feriados' = '/feriados',
    'Ficha de Bens' = '/relatorio-ficha-bens',
    'Fichas de Controle' = '/fichas-controle',
    'Fichas de Despesa' = '/fichas-despesa',
    'Fichas de Receita' = '/fichas-receita',
    'Fichas De Variação' = '/fichas-variacoes',
    'Fichas Extras' = '/fichas-extra',
    'Fiscalizações' = '/fiscalizacoes',
    'Fontes de Financiamento' = '/fontes-financiamento',
    'Funcionários' = '/funcionarios',
    'Funções do governo' = '/funcoes-governo',
    'Fracionamentos' = '/fracionamentos',
    'Gerenciamento de Imóveis' = '/gerenciamento-imoveis',
    'Gráfico de gasto - consumo' = '/abastecimentos-estatistica',
    'Gráfico de gasto - manutenção' = '/manutencoes-estatistica',
    'Grupos da Receita' = '/grupos-receita',
    'Grupos de Depreciação' = '/grupos-depreciacao',
    'Grupos de Estoque' = '/grupos-estoque',
    'Grupos de Serviços' = '/grupos-servico',
    'Holerites' = '/holerites',
    'Importar Folha' = '/importar-folha',
    'Importar Liquidações' = '/importar-liquidacoes',
    'Importar Dívida Ativa' = '/importar-divida-ativa',
    'Indices Banco Preço' = '/indices-banco-preco',
    'Inventários' = '/inventarios',
    'Inventários Emails' = '/inventarios-emails',
    'Inventários Estoque' = '/inventario-estoque',
    'LDO' = '/ldo',
    'Legislação - Tipos' = '/legislacao-tipos',
    'Licitações' = '/processos-licitatorios',
    'Liquidações de Restos' = '/liquidacoes-restos-pagar',
    'Liquidações Estoque' = '/liquidacoes-estoque',
    'Liquidações Orçamentárias' = '/liquidacoes-orcamentaria',
    'Listagem geral de bens' = '/listagem-geral-bens',
    'Localizações' = '/produto-localizacoes',
    'Manutenções Preventivas' = '/manutencoes-preventivas',
    'Manutenções' = '/manutencoes-patrimonio',
    'Manutenções Solicitadas' = '/manutencoes-patrimonio/solicitacao',
    'Materiais' = '/materiais',
    'Matriz de Saldos Contábeis' = '/matriz-saldos-contabeis',
    'Metas de governo' = '/metas-governo',
    'Modalidades' = '/modalidades',
    'Modelos de Relatórios' = '/modelos-relatorio',
    'Modelos' = '/modelos',
    'Motivos de Baixas' = '/motivos-baixas-patrimonio',
    'Motoristas' = '/motoristas',
    'Movimentação de Materiais' = '/movimentacao',
    'Movimentos de Compras' = '/movimentos-compra',
    'Movimentos de Compras - Pedido' = '/movimentos-compra/pedido-compra',
    'Movimentos de Compras - Exportar Empenho' = '/movimentos-compra/exportar-empenho',
    'Movimentos de Compras - Importar Requisição' = '/movimentos-compra/importar-rcms',
    'Óleos' = '/oleos',
    'Órdens de Pagamento (OP)' = '/ordens-pagamento',
    'Ordens de Serviço' = '/ordens-servico',
    'Ordens Fornecimento' = '/compras',
    'Órgãos' = '/orgaos',
    'Pagamentos de Restos' = '/pagamentos-restos-pagar',
    'Pagamentos Extra-orçamentários' = '/pagamentos-extras',
    'Pagamentos' = '/pagamentos-orcamentarios',
    'Paramêtros Almoxarifado' = '/parametros-almoxarifado',
    'Paramêtros Contábil' = '/parametros-contabil',
    'Parâmetros da transparência' = '/admin/transparencia/parametros-transparencia',
    'Parâmetros de E-mail' = '/parametros-email',
    'Parâmetros do compra' = '/parametros-compra',
    'Parâmetros do Requisição' = '/parametros-requisicao',
    'Parâmetros do Terceiro Setor' = '/parametros-terceiro-setor',
    'Parâmetros Licitação' = '/parametros-licitacao',
    'Parâmetros Patrimônio' = '/parametros-patrimonio',
    'Partidos' = '/partidos',
    'PASEP' = '/pasep',
    'Pedidos' = '/pedidos',
    'Pendências de Transferência' = '/pendencias',
    'Planejamento' = '/planejamento',
    'Plano de Contas' = '/plano-contas',
    'Plano de Contratação Anual' = '/plano-contratacao',
    'Planos Plurianuais' = '/planos-plurianuais',
    'Abertura orçamento' = '/abertura-orcamento',
    'PNCP' = '/pncp',
    'Pneus' = '/pneus',
    'Portal Contabilidade' = '/contabilidade',
    'Portal da Entidade' = '/portal-entidade',
    'Portal Tesouraria' = '/tesouraria',
    'Portal Faturamento' = '/faturamento',
    'Portarias' = '/portarias',
    'Postos de Abastecimento' = '/postos-abastecimento',
    'Posição de Restos' = '/posicao-restos-pagar',
    'Prazos' = '/prazos',
    'Pré-Liquidações de Restos' = '/pre-liquidacoes-restos',
    'Pré-Liquidações Orçamentárias' = '/pre-liquidacoes-orcamentaria',
    'Precatórios' = '/precatorios',
    'Pregões' = '/pregoes',
    'Prestação de Contas' = '/prestacao-contas',
    'Previsão de Despesas' = '/despesas-previstas',
    'Previsão de Receitas' = '/receitas-previstas',
    'Processos' = '/processos',
    'Produtos' = '/produtos',
    'Programas' = '/programas',
    'Protocolo - Agendamentos' = '/protocolo/agendamentos',
    'Protocolo - Ajuda' = '/protocolo/ajuda',
    'Protocolo - cidadão' = '/protocolo-cidadao',
    'Quadros da saúde' = '/quadros-saude',
    'Quadros de ensino' = '/quadros-ensino',
    'Questionários' = '/questionarios',
    'Quóruns' = '/quoruns',
    'Razonetes' = '/razonetes',
    'Reavaliação' = '/reavaliacoes',
    'Recebimentos Extra-orçamentários' = '/recebimentos-extras',
    'Recebimentos' = '/recebimentos-orcamentarios',
    'Receitas Orçamentárias' = '/receitas-orcamentaria',
    'Receitas Previstas' = '/receitas-prevista',
    'Recibos' = '/recibos',
    'Recursos Aliquotas' = '/recursos-aliquotas',
    'Recursos' = '/recursos',
    'Relatório Atividades' = '/relatorio-atividades',
    'Relatório de Abastecimento' = '/abastecimentos-relatorio',
    'Relatorio de Contratos' = '/relatorios-contrato',
    'Relatório de Depreciação' = '/depreciacao-relatorio',
    'Relatório de Depreciação Geral' = '/depreciacao-relatorio-geral',
    'Relatório de Etiquetas' = '/etiqueta-relatorio',
    'Relatório Gerenciais' = '/relatorio-gerenciais',
    'Relatório de Manutenção preventiva' = '/manutencoes-preventivas-relatorio',
    'Relatório de Manutenção' = '/manutencao-relatorio',
    'Relatório de Motorista' = '/motoristas-relatorio',
    'Relatório de Multas' = '/multas-relatorio',
    'Relatório de Pontuação de motoristas' = '/pontuacoes-motorista',
    'Relatório de Reavaliação' = '/reavaliacao-relatorio',
    'Relatório de Vencimento de CRLV' = '/vencimentos-crlv',
    'Relatório de Vencimentos de CNH' = '/vencimentos-cnh',
    'Relatórios da Tesouraria' = '/relatorios-tesouraria',
    'Relatório de Prestação' = '/relatorio-prestacao',
    'Relatórios Personalizados' = '/relatorios-personalizados',
    'Relatórios TCE' = '/relatorios-tce-licitacao',
    'Requisição Compras' = '/rcms',
    'Requisição Licitações' = '/rpls',
    'Requisição Materiais' = '/requisicoes-materiais',
    'Reposição Automática' = '/reposicao-automatica',
    'Reservas de dotação' = '/reservas-dotacao',
    'Restituição Receitas' = '/restituicao-receitas',
    'Retenções IRRF' = '/retencoes-irrf',
    'Ritos' = '/ritos',
    'Saídas' = '/saidas',
    'Seguradoras' = '/seguradoras',
    'Serviços' = '/servicos',
    'Setores' = '/setores',
    'Seções' = '/secoes',
    'SIOPE' = '/siope',
    'SIOPS' = '/siops',
    'Relatórios SIOPS' = '/siops-relatorios',
    'Relatórios Variações Patrimoniais' = '/variacoes-patrimoniais-relatorios',
    'Solicitação de Credenciamento' = '/credenciamento-solicitacao',
    'Solicitações de Transferência' = '/transferencias-patrimonio-solicitacao',
    'Solicitações de Baixa' = '/baixas-patrimonio-solicitacao',
    'Solicitações de Manutenção' = '/manutencoes-patrimonio-solicitacao',
    'Subgrupos de Estoque' = '/subgrupos-estoque',
    'Subseções' = '/subsecoes',
    'Termo de Responsabilidade' = '/relatorio-termo-responsabilidade',
    'Tipo de Favorecidos' = '/favorecidos-tipos',
    'Tipos de Contratação' = '/tipos-contratacao',
    'Tipos de Convênios' = '/tipos-convenio',
    'Tipos de Documento' = '/tipos-documento',
    'Tipos de Ocorrências' = '/tipos-imoveis-ocorrencia',
    'Tipos de Movimento' = '/movimento-tipos',
    'Tipos de Transferências' = '/tipos-transferencias-patrimonio',
    'Tipos de Veículo' = '/tipos-veiculo',
    'Totalização por Sub-grupo' = '/totalizacao-subgrupo',
    'Transferências Autorização' = '/transferencias-autorizacao',
    'Transferências Bancárias' = '/transferencias-bancarias',
    'Transferências Espécies' = '/transferencias-especies',
    'Transferências Previstas' = '/transferencias-prevista',
    'Transferências Previstas - Concedidas' = '/transferencias-prevista/PTC',
    'Transferências Previstas - Recebidas' = '/transferencias-prevista/PTR',
    'Transferências Recursos' = '/transferencia-recursos',
    'Transferências Retenções' = '/transferencia-retencoes',
    'Transferências' = '/transferencias-patrimonio',
    'Transferência Empréstimo' = '/transferencias-patrimonio/emprestimo',
    'Unidades de Fornecimento' = '/unidades-fornecimento',
    'Unidades' = '/unidades',
    'Usuários' = '/usuarios',
    'Variações Patrimoniais' = '/variacoes',
    'Veículos' = '/veiculos',
    'Vereadores' = '/vereadores',
    'Viagens' = '/viagens',
    'Votação - Resultados' = '/votacao-resultados',
    'Votação - Tipos' = '/votacao-tipos',
    'Votação - Turnos' = '/votacao-turnos',
    'Recursos disponibilidade' = '/recursos-disponibilidades',
    'Decreto' = '/decreto',
    'Lei' = '/lei',
    'Classificação Receitas SIOPS' = '/classificacao-receitas-siops',
    'Logs do Sigs' = '/logs-sigs',
    'Indice correção' = '/indice-correcao',
    'Parecer Controle Interno' = '/parecer-controle-interno',
    'Relatórios Edital' = '/relatorios-edital',
    'Salvar Liquidações conversão' = '/salvar-liquidacoes-conversao',
    'Orçamento Autarquias' = '/orcamento-autarquias',
    'SQL Via Banco' = '/sql-via-banco',
    'Tipo de Fatura' = '/fatura-tipo',
    'Tipo de Fatura Imóvel' = '/fatura-tipo-imovel',
    'Fatura' = '/fatura',
    'Modelos de Relatório Patrimonio' = '/modelos-relatorio-patrimonio',
    'Despesa com Pessoal - Percentual' = '/despesa-pessoal-percentual',
    'Fatura Liquidação' = '/fatura-liquidacao'
}

interface ISistemas {
    paginas(): string[];
}

export class ComumPag implements ISistemas {
    paginas() {
        return [
            'Agendamentos',
            'Assinaturas',
            'Auditoria',
            'Comunicados',
            'E-mails Personalizados',
            'Órgãos',
            'Parâmetros de E-mail',
            'Relatórios Personalizados',
            'Usuários',
        ];
    }
}
export class AlmoxarifadoPag implements ISistemas {
    paginas() {
        return [
            'Almoxarifado',
            'Autorização Requisição Materiais',
            'Baixas Merenda',
            'Cardápios',
            'Controle Avançado de Conservação',
            'Encerramento Contábil',
            'Entradas',
            'Estoques',
            'Etapas',
            'Fracionamentos',
            'Grupos de Estoque',
            'Grupos de Serviços',
            'Inventários Estoque',
            'Localizações',
            'Logs do Sigs',
            'Materiais',
            'Paramêtros Almoxarifado',
            'Pedidos',
            'Pendências de Transferência',
            'Produtos',
            'Serviços',
            'Empréstimo',
            'Recibos',
            'Reposição Automática',
            'Saídas',
            'Setores',
            'Subgrupos de Estoque',
            'Unidades de Fornecimento',
            'Tipos de Movimento',
        ];
    }
}

export class ControleInternoPag implements ISistemas {
    paginas() {
        return [
            'Audesp-TCE-SP',
            'Auditoria de Adiantamentos',
            'Auditoria de Avaliações',
            'Auditoria de Contratos',
            'Auditoria de Empenhos',
            'Auditoria de Licitações',
            'Auditoria de Patrimônios',
            'Fiscalizações',
            'Processos',
            'Questionários',
        ];
    }
}

export class CompraPag implements ISistemas {
    paginas() {
        return [
            'Contratos',
            'Favorecidos',
            'Movimentos de Compras',
            'Movimentos de Compras - Pedido',
            'Movimentos de Compras - Exportar Empenho',
            'Movimentos de Compras - Importar Requisição',
            'Autorização Requisição - Compras',
            'Ordens Fornecimento',
            'Parâmetros do compra',
            'Prazos',
            'Indices Banco Preço',
            'Autorização Visualizar Todas Compras'
        ];
    }
}

export class PatrimonioPag implements ISistemas {
    paginas() {
        return [
            'Baixas',
            'Bens Imóveis',
            'Bens Móveis',
            'Bens Móveis - Incorporação',
            'Conferências',
            'Conferência Contábil Patrimônio',
            'Depreciação',
            'Empréstimos',
            'Ficha de Bens',
            'Gerenciamento de Imóveis',
            'Inventários',
            'Listagem geral de bens',
            'Manutenções',
            'Manutenções Solicitadas',
            'Motivos de Baixas',
            'Parâmetros Patrimônio',
            'Reavaliação',
            'Relatório de Depreciação',
            'Relatório de Depreciação Geral',
            'Relatório de Etiquetas',
            'Relatório de Reavaliação',
            'Solicitações de Baixa',
            'Solicitações de Manutenção',
            'Solicitações de Transferência',
            'Termo de Responsabilidade',
            'Tipos de Ocorrências',
            'Tipos de Transferências',
            'Totalização por Sub-grupo',
            'Transferências',
            'Transferência Empréstimo',
            'Modelos de Relatório Patrimonio',
        ];
    }
}

export class DiarioOficialPag implements ISistemas {
    paginas() {
        return [
            'Diário Oficial',
            'Feriados',
            'Seções',
            'Subseções',
        ];
    }
}
export class LegislativoPag implements ISistemas {
    paginas() {
        return [
            'Expediêntes',
            'Legislação - Tipos',
            'Partidos',
            'Quóruns',
            'Ritos',
            'Vereadores',
            'Votação - Turnos',
            'Votação - Tipos',
            'Votação - Resultados',
        ];
    }
}

export class ProtocoloPag implements ISistemas {
    paginas() {
        return [
            'Assuntos',
            'Cidadãos',
            'Estatísticas',
            'Protocolo - Agendamentos',
            'Protocolo - cidadão',
            'Protocolo - Ajuda',
        ];
    }
}

export class FolhaPag implements ISistemas {
    paginas() {
        return [
            'Cargos',
            'Departamentos',
            'Funcionários',
            'Holerites',
        ];
    }
}

export class TerceiroSetorPag implements ISistemas {
    paginas() {
        return [
            'Chamamentos',
            'Convênios',
            'Credenciamentos',
            'Modelos',
            'Parâmetros do Terceiro Setor',
            'Prestação de Contas',
            'Solicitação de Credenciamento',
            'Tipos de Convênios',
        ];
    }
}

export class PortalEntidadePag implements ISistemas {
    paginas() {
        return [
            'Portal da Entidade'
        ];
    }
}

export class PlanejamentoPag implements ISistemas {
    paginas() {
        return [
            'Planejamento',
            'Planos Plurianuais',
            'Abertura orçamento',
            'Audiências públicas',
            'Unidades',
            'Fontes de Financiamento',
            'Metas de governo',
            'Ações do governo',
            'LDO',
            'Anexos PPA',
            'Anexos LDO',
            'Receitas Orçamentárias',
            'Despesas Orçamentárias',
            'Fichas de Receita',
            'Receitas Previstas',
            'Fichas de Despesa',
            'Despesas Previstas',
            'Movimentação de Materiais',
            'Despesas por Função',
            'Transferências Previstas',
            'Transferências Internas',
            'Transferências Previstas - Concedidas',
            'Transferências Previstas - Recebidas',
            'Créditos Adicionais',
            'Reservas de dotação',
            'Anexos LOA',
            'Previsão de Despesas',
            'Previsão de Receitas',
            'Relatório Atividades'
        ];
    }
}

export class TesourariaPag implements ISistemas {
    paginas() {
        return [
            'Portal Tesouraria',
            'Transferências Bancárias',
            'Contas bancárias',
            'Conciliações bancárias',
            'Movimentos Diários',
            'Órdens de Pagamento (OP)',
            'Relatórios da Tesouraria',
            'Recebimentos',
            'Recebimentos Extra-orçamentários',
            'Pagamentos',
            'Pagamentos Extra-orçamentários',
            'Pagamentos de Restos',
            'Bancos',
            'Emissão de Cheques',
            'Recursos Aliquotas',
            'Restituição Receitas',
            'Transferências Autorização',
            'Transferências Espécies',
            'Transferências Recursos',
            'Transferências Retenções',
            'Relatório de Prestação'
        ];
    }
}

export class ContabilidadePag implements ISistemas {
    paginas() {
        return [
            'Abertura Diários',
            'Abertura',
            'Adiantamentos',
            'Alíquotas',
            'Anexos LRF',
            'Aplicação de Recursos',
            'Aplicação Variável de Recursos',
            'AUDESP (layouts)',
            'AUDESP (validações)',
            'AUDESP',
            'Ações',
            'Balancetes',
            'Balanços',
            'Cadastros Auxiliares',
            'Cancelamento Resto',
            'Cheques Layout',
            'Consignações em Aberto',
            'Consulta de Empenhos Orçamentários',
            'Contas bancárias entidades',
            'De para recursos AUDESP->SICONFI',
            'Legislação do Orçamento',
            'Decreto',
            'Dívidas Fornecedor',
            'Documentos Fiscais',
            'EFD Reinf',
            'Empenhos de Restos',
            'Empenhos Extra-orçamentários',
            'Empenhos Orçamentários',
            'Encerramento Anual',
            'Entidade Partidos',
            'Escriturações',
            'Eventos Contábeis',
            'Exercícios',
            'Fichas de Controle',
            'Fichas De Variação',
            'Fichas Extras',
            'Funções do governo',
            'Grupos da Receita',
            'Grupos de Depreciação',
            'Importar Dívida Ativa',
            'Importar Folha',
            'Importar Liquidações',
            'Lei',
            'Liquidações de Restos',
            'Liquidações Estoque',
            'Liquidações Orçamentárias',
            'Matriz de Saldos Contábeis',
            'Paramêtros Contábil',
            'PASEP',
            'Plano de Contas',
            'Portal Contabilidade',
            'Posição de Restos',
            'Pré-Liquidações de Restos',
            'Pré-Liquidações Orçamentárias',
            'Precatórios',
            'Programas',
            'Quadros da saúde',
            'Quadros de ensino',
            'Razonetes',
            'Recursos disponibilidade',
            'Recursos',
            'Relatório Gerenciais',
            'Relatórios Edital',
            'Relatórios SIOPS',
            'Relatórios Variações Patrimoniais',
            'Retenções IRRF',
            'SIOPE',
            'SIOPS',
            'Tipo de Favorecidos',
            'Variações Patrimoniais',
            'Salvar Liquidações conversão',
            'SQL Via Banco',
            // 'Tipo de Fatura',
            // 'Tipo de Fatura Imóvel',
            // 'Fatura',
            'Despesa com Pessoal - Percentual',
            // 'Fatura Liquidação'
        ];
    }
}

export class TransparenciaPag implements ISistemas {
    paginas() {
        return [
            'Anexos de Empenhos Orçamentários',
            'Anexos de Contratos e Aditivos',
            'Anexos de Licitação',
            'Atas de Registro de Preços - Anexo',
            'Arquivos de Transparência',
            'Despesas Empenhadas Mensais',
            'Eventos (Holerite)',
            'Parâmetros da transparência',
        ];
    }
}

export class LicitacaoPag implements ISistemas {
    paginas() {
        return [
            'Audesp Fase 4',
            'Comissões',
            'Exportação RCMS',
            'Licitações',
            'Modalidades',
            'Modelos de Relatórios',
            'Parâmetros Licitação',
            'Relatórios TCE',
            'Relatorio de Contratos',
            'Tipos de Contratação',
            'Pregões',
            'Plano de Contratação Anual',
            'PNCP'
        ];
    }
}

export class FrotaPag implements ISistemas {
    paginas() {
        return [
            'Abastecimentos',
            'Autorização de Abastecimentos',
            'Cargo - Frota',
            'Combustíveis',
            'Deslocamentos',
            'Gráfico de gasto - consumo',
            'Gráfico de gasto - manutenção',
            'Manutenções Preventivas',
            'Motoristas',
            'Óleos',
            'Ordens de Serviço',
            'Pneus',
            'Portarias',
            'Postos de Abastecimento',
            'Relatório de Abastecimento',
            'Relatório de Manutenção',
            'Relatório de Manutenção preventiva',
            'Relatório de Motorista',
            'Relatório de Multas',
            'Relatório de Pontuação de motoristas',
            'Relatório de Vencimentos de CNH',
            'Relatório de Vencimento de CRLV',
            'Tipos de Documento',
            'Tipos de Veículo',
            'Veículos',
            'Viagens',
        ];
    }
}

export class RequisicaoPag implements ISistemas {
    paginas() {
        return [
            'Autorização Requisição Compras',
            'Requisição Compras',
            'Requisição Licitações',
            'Requisição Materiais',
            'Parâmetros do Requisição',
            'Baixa Automática Reserva',
        ];
    }
}

export class FaturaPag implements ISistemas {
    paginas(): string[] {
        return [
            'Fatura',
            'Fatura Liquidação',
            'Portal Faturamento',
            'Tipo de Fatura',
            'Tipo de Fatura Imóvel',
        ]
    }
}

