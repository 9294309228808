import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ParametroCompra } from '../../../entidade/compra/parametro-compra.model';
import { BaseResourceService } from '../../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class ParametroCompraService extends BaseResourceService<ParametroCompra> {

  constructor(
    protected injector: Injector
  ) {
    super(`parametros-compra`, injector);
  }

  public obterParametro(orgao: number): Observable<ParametroCompra> {
    return this.http.get<ParametroCompra>(
      `${this.login.cidade.id}/${this.api}/obter/${orgao}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
