import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { PagamentoExtraService } from 'administrativo-lib';
import {
  BaseResourceListComponent, Coluna, DateFormatPipe, Filtro, FormatoExportacao, GlobalService, LoginContabil, OrgaoAssinaturaService, PagamentoExtra, ProgressoService
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmpenhoExtraService } from '../../../empenho-extra/service/empenho-extra.service';
import * as toastr from 'toastr';
import { ReinfRegistroService } from 'projects/contabil/src/app/efd-reinf/service/reinf-registro.service';
import { NotaPagamentoExtra } from '../../relatorio-tesouraria/notas/nota-pagamento-extra';
import { MessageService } from 'primeng/api';

declare var $: any;
@Component({
  selector: 'lib-pagamento-extra-list',
  templateUrl: './pagamento-extra-list.component.html'
})
export class PagamentoExtraListComponent extends BaseResourceListComponent<PagamentoExtra, LoginContabil> implements OnInit {
  /**
   * Declaração de variáveis
   */
  public especieSelect = 'EME';
  public listaEspecies: Array<any>;
  public ptBR: any;
  public datepipe: DatePipe;
  public data1: Date;
  public data2: Date;
  public pagamentoAnular: PagamentoExtra;
  public valorAnular: number = 0;
  public pagamentoAtualizar: PagamentoExtra
  public visualizarAtualizacao = false;
  public pagamentossSelecionados: any[] = [];
  public pagamentosId: any[] = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected assinaturaService: OrgaoAssinaturaService,
    private globalService: GlobalService,
    protected progressoService: ProgressoService,
    protected registroService: ReinfRegistroService,
    private pagamentoService: PagamentoExtraService,
    private empenhoExtraService: EmpenhoExtraService) {
    super(pagamentoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(entidade: PagamentoExtra): boolean {
    return +entidade.valor_pago + +entidade['total_anulado'] > 0;
  }

  protected relations(): string {
    return 'conta,conta.banco,empenho,empenho.favorecido,empenho.ficha,empenho.ficha.recurso,empenho.ficha.aplicacao,exercicio,orgao,conta_recurso.recurso,conta_recurso.aplicacao,conta_recurso.convenio';
  }

  protected condicoesGrid(): {} {
    const parametros = {};
    this.datepipe = new DatePipe('pt');
    parametros['data_pagamento$ge'] = this.datepipe.transform(this.data1, 'yyyy-MM-dd');
    parametros['data_pagamento$le'] = this.datepipe.transform(this.data2, 'yyyy-MM-dd');
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['exercicio.id'] = this.login.exercicio.id

    sessionStorage.setItem('/pagamentos-extras_data1', this.datepipe.transform(this.data1, 'yyyy-MM-dd'));
    sessionStorage.setItem('/pagamentos-extras_data2', this.datepipe.transform(this.data2, 'yyyy-MM-dd'));

    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['data_pagamento$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['empenho.numero', 'conta.agencia', 'conta.numero_conta', 'valor_pago', 'empenho.favorecido.cpf_cnpj', 'valor_retido'],
      date: ['data_pagamento'],
      text: ['empenho.favorecido.nome'],
    };
  }

  public beforeInit(): void {
    this.usarFiltroPersonalizado = true;
    this.usarExtendido = true;
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    const dt1: string = sessionStorage.getItem('/pagamentos-extras_data1');
    const dt2: string = sessionStorage.getItem('/pagamentos-extras_data2');
    if (!dt1) {
      this.data1 = new DateFormatPipe().transform(this.datepipe.transform(new Date(`01/01/${this.login.exercicio.ano}`), 'yyyy-MM-dd'), []);
    } else {
      this.data1 = new DateFormatPipe().transform(dt1, []);
    }
    if (!dt2) {
      this.data2 = new DateFormatPipe().transform(this.datepipe.transform(new Date(), 'yyyy-MM-dd'), []);
    } else {
      this.data2 = new DateFormatPipe().transform(dt2, []);
    }
  }

  protected afterInit(): void {
    this.listaEspecies = [
      { id: 'EMO', nome: 'ORÇAMENTÁRIO' },
      { id: 'EME', nome: 'EXTRA-ORÇAMENTARIO' },
      { id: 'EMR', nome: 'RESTOS A PAGAR' }
    ];
  }

  protected acaoRemover(model: PagamentoExtra): Observable<PagamentoExtra> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Data', coluna: 'data_pagamento' },
      { titulo: 'Número', coluna: 'empenho.numero' },
      { titulo: 'Fornecedor/credor', coluna: 'empenho.favorecido.nome' },
      { titulo: 'Agência nome', coluna: 'conta.agencia_nome' },
      { titulo: 'Número conta', coluna: 'conta.numero_conta' },
      { titulo: 'Valor', coluna: 'valor_pago', decimais: 2, alignment: 'right' }
    ];
  }

  public async aposPreencher(): Promise<void> {
    // Mapeia o valor pago para o valor líquido do empenho como pedido no chamado 166492;
    this.lista = await Promise.all(this.lista.map(async item => {
      const totalEmpenhado = await this.empenhoExtraService.
        obterTotalEmpenhadoPorNumero(item.empenho?.numero, item.empenho?.parcela, this.login.exercicio.id, this.login.orgao.id).toPromise();
      // return { ...item, valor_pago: item.valor_pago - (item.anulacao ? +totalEmpenhado.retido * -1 : +totalEmpenhado.retido) }
      return { ...item, valor_pago: item.valor_pago }
    }));
  }

  public obterColunasFiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Data pagamento', coluna: 'data_pagamento', tipo: 'Date', alignment: 'center', filtro2: new Date(), padrao: true });
    retorno.push({ titulo: 'Nº Empenho', coluna: 'empenho.numero', alignment: 'center', tipo: 'Number', padrao: true });
    retorno.push({ titulo: 'Nº Empenho origem', coluna: 'empenho.retencao.liquidacao.empenho.numero', alignment: 'center', tipo: 'Number', padrao: true });
    retorno.push({ titulo: 'Nº Empenho Origem Resto', coluna: 'empenho.retencao_resto.liquidacao.empenho.numero', alignment: 'center', tipo: 'Number' });
    retorno.push({ titulo: 'Parcela', coluna: 'parcela', alignment: 'center', tipo: 'Number', padrao: false });
    retorno.push({ titulo: 'Histórico', coluna: 'historico', tipo: 'String' });
    retorno.push({ titulo: 'Favorecido', coluna: 'empenho.favorecido.nome', tipo: 'String', padrao: false });
    retorno.push({ titulo: 'CPF/CNPJ', coluna: 'empenho.favorecido.cpf_cnpj', tipo: 'String', cols: 3, mask: [{ mask: '000.000.000-00' }, { mask: '00.000.000/0000-00' }] });
    retorno.push({ titulo: 'Documento', coluna: 'documento', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Ficha', coluna: 'empenho.ficha.numero', alignment: 'center', tipo: 'String', padrao: false, cols: 3 });
    retorno.push({ titulo: 'Nº da conta', coluna: 'conta.numero_conta', alignment: 'center', tipo: 'String', padrao: false, cols: 3 });
    retorno.push({ titulo: 'Recurso', coluna: 'conta_recurso.recurso.codigo', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Aplicação', coluna: 'conta_recurso.aplicacao.codigo', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Variável', coluna: 'conta_recurso.convenio.codigo', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Valor', coluna: 'valor_pago', alignment: 'center', tipo: 'Number', padrao: false });
    retorno.push({ titulo: 'Valor retido', coluna: 'valor_retido', alignment: 'center', tipo: 'Number' });

    return retorno;
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'conta,empenho.favorecido,empenho.ficha,exercicio,orgao';
    this.pagamentoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE PAGAMENTOS EXTRA ORÇAMENTÁRIOS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem pagamentos', ['auto', '*', 'auto', 'auto', 'auto', 'auto'], lista.content, ['valor_pago']);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  public onChangePagamento(value: any, especie: any) {
    if (especie === 'EMO') {
      this.router.navigate(['pagamentos-orcamentarios']);
    } else if (especie === 'EMR') {
      this.router.navigate(['pagamentos-restos-pagar']);
    }
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/tesouraria']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public anular(item: PagamentoExtra) {
    this.pagamentoAnular = item;
    this.pagamentoService.obterTotalPagoPorEmpenho(item.empenho.numero, item.empenho.parcela, this.login.exercicio.id, this.login.orgao.id, true).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      let pagoParcela = +res['pago'];
      this.valorAnular = +pagoParcela.toFixed(2);
      $('#dialogAnular').modal('show');
    }, error => {
      toastr.warning('Falha ao carregar valor disponível para anulação.');
      this.valorAnular = 0;
      $('#dialogAnular').modal('show');
    });
  }

  public acertarRetencoes() {
    this.pagamentoService.acertarReceitaRetencoes(this.login.exercicio.id, this.login.orgao.id).pipe(takeUntil(this.unsubscribe)).subscribe(idTransacao => {
      this.progressoService.show(idTransacao);
    },
      error => this.acaoErro(error));
  }

  public editarAnulacao(item: PagamentoExtra) {
    return item.anulacao && this.login.usuario.sistemas_administrador?.split(',').includes('contabil')
  }

  public alterarAnular(item: PagamentoExtra) {
    this.pagamentoAtualizar = item;
    this.visualizarAtualizacao = true;
  }

  protected acaoErro(error: any) {
    console.log('Erro disparado: ', error);
    toastr.options.timeOut = 10000;
    toastr.options.closeButton = true;
    toastr.options.tapToDismiss = false;
    if (error.error && error.error.payload) {
      toastr.error(error.error.payload);
    } else {
      toastr.error('Ocorreu um erro ao processar a sua solicitação');
    }
  }

  async mudarSituacao(item: PagamentoExtra) {
    let registro = null;
    switch (item.situacao) {
      case 'N':
      case 'R':
        registro = await this.registroService.obter({ 'orgao.id': this.login.orgao.id, ambiente: 1, sucesso: true, ratificado: false, evento: 'R2010', id_alvo: item.id }).toPromise();
        if (registro) {
          item.situacao = 'E';
        } else {
          item.situacao = 'I';
        }
        break;
      case 'I':
        registro = await this.registroService.obter({ 'orgao.id': this.login.orgao.id, ambiente: 1, sucesso: true, ratificado: false, evento: 'R2010', id_alvo: item.id }).toPromise();
        item.situacao = registro ? 'R' : 'N';
        break;
      case 'E':
        item.situacao = 'R';
        break;
      default:
    }
    this.pagamentoService.atualizarSituacaoReinf(item).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      toastr.success(`Situação alterada com sucesso`);
      this.preencherGrid()
    });
  }

  public ImprimirNotaPorPagamentosSelecionados(item: PagamentoExtra) {
    if (item['selecionado'] === true) {
      item['selecionado'] = false;
      // Encontre o índice do item na lista this.pagamentossSelecionados
      const index = this.pagamentossSelecionados.findIndex(e => e.id === item.id);
      if (index > -1) {
        this.pagamentossSelecionados.splice(index, 1);
      }
    } else {
      item['selecionado'] = true;
      // Verifique se o item já não está na lista
      if (!this.pagamentossSelecionados.some(e => e.id === item.id)) {
        this.pagamentossSelecionados.push(item);
      }
    }
    this.pagamentosId = [];
    for (const liquidacao of this.pagamentossSelecionados) {
      this.pagamentosId.push(liquidacao.id)
    }
  }

  imprimirNota() {
    const parametros = {};

    let relations = 'empenho.contrato.licitacao.modalidade,empenho.ficha.aplicacao,empenho.ficha.recurso,empenho.ficha.aplicacao_variavel,empenho.contrato,empenho.favorecido.contas.banco,empenho.';
    relations += 'convenio,empenho.retencao.liquidacao.empenho.favorecido,empenho.retencao.liquidacao.empenho';

    if (this.pagamentosId.length === 0 || !this.pagamentosId) {
      toastr.warning('Selecione ao menos um pagamento para imprimir')
      return;
    }

    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id$in'] = this.pagamentosId;

    this.pagamentoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        new NotaPagamentoExtra(this.assinaturaService).imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }
}
