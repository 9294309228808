import { BaseResourceModel } from '../../models/base-resource.model';
import { Noticia } from './noticia.model';

export class NoticiaComentario extends BaseResourceModel {
  constructor(
    public id?: number,
    public aprovado?: boolean,
    public comentario?: string,
    public nome?: string,
    public telefone?: string,
    public email?: string,
    public noticia?: Noticia
  ) {
    super();
  }

  static converteJson(json: any): NoticiaComentario {
    return Object.assign(new NoticiaComentario(), json);
  }
}
