import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, Chamamento } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ChamamentoService extends BaseResourceService<Chamamento> {

  constructor(
    protected injector: Injector
  ) {
    super(`chamamentos`, injector);
  }

  public obterPorNumero(numero: number, orgaoId: number): Observable<Chamamento> {
    return this.http.get<Chamamento>(
      `${this.login.cidade.id}/${this.api}?numero=${numero}&orgao_id=${orgaoId}`).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
