import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToastModule } from 'primeng/toast';
import { PessoaModule } from '../pessoa/pessoa.module';
import { SharedModule } from '../util/shared.module';
import { AssinaturaFormComponent } from './assinatura-form/assinatura-form.component';
import { AssinaturaListComponent } from './assinatura-list/assinatura-list.component';
import { AssinaturaPessoaComponent } from './assinatura-pessoa/assinatura-pessoa.component';
import { AssinaturaRoutingModule } from './assinatura-routing.module';
import { AssinaturaViewComponent } from './assinatura-view/assinatura-view.component';

@NgModule({
  declarations: [AssinaturaListComponent, AssinaturaFormComponent, AssinaturaViewComponent, AssinaturaPessoaComponent],
  imports: [
    CommonModule,
    AssinaturaRoutingModule,
    SharedModule,
    FormsModule,
    ToastModule,
    IMaskModule,
    AutoCompleteModule,
    PessoaModule,
    ConfirmDialogModule,
    OverlayPanelModule
  ]
})
export class AssinaturaModule { }
