import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'max100'
})
export class Max100Pipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): number {
    return value > 100 ? 100 : value;
  }

}
