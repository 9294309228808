import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ProdutoService } from 'almoxarifado-lib';
import { Contrato, ContratoItem, EddyAutoComplete, FuncaoService, Login, Produto, ProdutoUnidade } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { AutoComplete } from 'primeng/autocomplete';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ContratoItemService } from '../service/contrato-item.service';

declare var $: any;

@Component({
  selector: 'lib-contrato-item-cad',
  templateUrl: './contrato-item-cad.component.html'
})
export class ContratoItemCadComponent implements OnInit, OnDestroy {

  public imaskQtd = {
    mask: Number,
    scale: 2,
    signed: false
  };
  public imaskConfig = {
    mask: Number,
    scale: 6,
    signed: true,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  public produtoAutoComplete: EddyAutoComplete<Produto>;
  public itemAnterior: ContratoItem;
  public itemAtual: ContratoItem;
  public produtoAtual: Produto;

  @ViewChild('produto_item_') campoItem: AutoComplete;
  @ViewChild('quantidade_') campoQtde: ElementRef;
  @ViewChild('descricao_') campoDescricao: ElementRef;
  @ViewChild('btnAdicionar') public btnAdicionar: ElementRef;

  @Input() entidade: Contrato;
  @Input() login: Login;
  @Input() produtoBuscaDlgName?: string;
  @Input() visualizar: Boolean;

  @Output() blockButton = new EventEmitter<boolean>();

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected confirmationService: ConfirmationService,
    public funcaoService: FuncaoService,
    protected itemService: ContratoItemService,
    protected produtoService: ProdutoService,
  ) { }

  ngOnInit(): void {
    this.produtoAutoComplete = ProdutoService.autoCompleteCodigoCompleto(null, this.produtoService,
      'id', ['codigo', 'nome'], {orgao_id: this.login.orgao.id, orderBy: 'codigo,nome', relations: 'unidades.unidade,material' }, { number: ['codigo'], text: ['codigo', 'nome'] }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  @HostListener('window:keydown.control.i', ['$event'])
  public adicionarItem(event: KeyboardEvent) {
    event.preventDefault();
    this.adicionar();
  }

  @HostListener('window:keydown.control.p', ['$event'])
  public abrirConsultaProduto(event: KeyboardEvent) {
    event.preventDefault();
    $(this.produtoBuscaDlgName ? `#${this.produtoBuscaDlgName}` : '#dialogProdutoUnBusca').modal('show');
  }

  private adicionar() {
    if (this.entidade.itens && this.entidade.itens.find((itm) => { return itm.editavel === true })) {
      toastr.info('Finalize a edição atual antes de incluir outro item');
      return;
    }

    this.itemAnterior = undefined;
    this.itemAtual = new ContratoItem();
    this.itemAtual.editavel = true;
    this.produtoAtual = undefined;

    if (!this.entidade.itens || this.entidade.itens.length === 0) {
      this.entidade.itens = [];
      this.itemAtual.ordem = 1;
    } else {
      this.itemAtual.ordem = (this.entidade.itens.sort((a, b) => { return a.ordem - b.ordem })[this.entidade.itens.length - 1].ordem) + 1;
    }
      
    if (!this.entidade.itens || this.entidade.itens.length === 0) {
      this.entidade.itens = [];
    }

    this.entidade.itens.unshift(this.itemAtual);
    this.btnAdicionar.nativeElement.disabled = true;
    this.focarItem();
    this.blockButton.emit(false);
  }

  public editar(item: ContratoItem) {
    if (this.entidade.itens && this.entidade.itens.find((itm) => { return itm.editavel === true })) {
      toastr.info('Finalize a edição atual antes de editar outro item');
      return;
    }

    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual.produto_unidade = item.produto_unidade;
    this.produtoService.obter({orgao_id: this.login.orgao.id, id: this.itemAtual.produto_unidade.produto.id, relations: 'unidades.unidade' }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.produtoAtual = res;
        this.selecionarUnidade();
        this.focarItem();
      });
    this.btnAdicionar.nativeElement.disabled = true;
  }

  public salvar(item: ContratoItem) {
    try {
      if (!this.produtoAtual || !item.produto_unidade) {
        throw new Error('Informe o produto/serviço e a unidade!');
      }
      if (!item.quantidade || +item.quantidade <= 0) {
        throw new Error('Informe a quantidade!');
      }
      if (this.entidade.itens.find(m => m.produto_unidade && item.produto_unidade && m.produto_unidade.id === item.produto_unidade.id && m !== item)) {
        this.itemAtual.produto_unidade = undefined;
        this.itemAtual.quantidade = undefined;
        throw new Error('Item já incluído neste contrato!');
      }

      delete this.produtoAtual.unidades;
      item.produto_unidade.produto = this.produtoAtual;
      item.descricao = this.produtoAtual.descricao;
      item.unidade = item.produto_unidade.unidade.nome;

      this.itemAtual.editavel = false;
      this.itemAtual = undefined;
      this.itemAnterior = undefined;
      this.btnAdicionar.nativeElement.disabled = false;
    } catch (e) {
      this.funcaoService.acaoErro(e)
      throw e;
    }
  }

  public ordenarItens(force?: boolean) {
    if (!this.itemAtual.editavel && !force) return;
    this.entidade.itens.sort((a, b) => { return a.ordem - b.ordem });
  }

  public atualizarOrdem(force?: boolean) {
    if (!this.itemAtual.editavel && !force) return;
    this.ordenarItens(force);
    let ordem = 0;
    this.entidade.itens?.forEach((m) => {
      m.ordem = ++ordem;
    })
  }

  public cancelar(item: ContratoItem, index: number) {
    this.itemAtual = Object.assign({}, this.itemAnterior);

    if (!this.itemAtual) {
      this.entidade.itens.splice(index, 1);
      if (!this.entidade.itens?.length) {
        this.blockButton.emit(true);
      }
    } else if (!item.id && !this.itemAtual.quantidade && !this.itemAtual.produto_unidade) {
      this.entidade.itens.splice(index, 1);
      if (!this.entidade.itens?.length) {
        this.blockButton.emit(true);
      }
    } else {
      if (this.produtoAtual) {
        delete this.produtoAtual.unidades;
        item.produto_unidade.produto = this.produtoAtual;
      }
      item.editavel = false;
    }
    this.itemAtual = undefined;
    this.itemAnterior = undefined;
    this.btnAdicionar.nativeElement.disabled = false;
  }

  public remover(item: ContratoItem, index: number) {
    if (item.id) {
      this.confirmationService.confirm({
        message: 'Deseja realmente remover o item?',
        header: 'Exclusão',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Confirmar',
        rejectLabel: 'Cancelar',
        accept: () => {
          this.itemService.remover(item.id).pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
              toastr.info('Registro removido com sucesso!', 'Exclusão');
              this.entidade.itens.splice(index, 1);
              this.atualizarOrdem();
              if (!this.entidade.itens?.length) {
                this.blockButton.emit(true);
              }
              this.btnAdicionar.nativeElement.disabled = false;
            }, (err) => toastr.error(err.error.payload));
        }
      });
    } else {
      this.entidade.itens.splice(index, 1);
      this.atualizarOrdem();
      if (!this.entidade.itens?.length) {
        this.blockButton.emit(true);
      }
      this.btnAdicionar.nativeElement.disabled = false;
    }
  }

  private focarItem() {
    if (this.itemAtual) {
      setTimeout(() => this.campoItem ? this.campoItem.focusInput() : null, 500);
    }
  }

  public selecionarUnidade() {
    if (this.itemAtual && this.produtoAtual?.unidades) {
      if (this.itemAtual.unidade) {
        this.itemAtual.produto_unidade = this.produtoAtual.unidades.find((un) => un.unidade.nome === this.itemAtual.unidade);
      } else {
        this.itemAtual.produto_unidade = this.produtoAtual.unidades[0];
      }
    }
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public callbackProdutoUn(prodUn: ProdutoUnidade) {
    if (this.itemAtual) {
      this.itemAtual.produto_unidade = prodUn
      this.produtoService.obter({orgao_id: this.login.orgao.id, id: this.itemAtual.produto_unidade.produto.id, relations: 'unidades.unidade' }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.produtoAtual = res;
          setTimeout(() => this.campoQtde ? this.campoQtde.nativeElement.focus() : null, 500);
        });
    }
  }

  public podeVisualizar() {
    if (this.visualizar === true) {
      return true;
    }
    if(this.visualizar === false){
      return false;
    }
  }
}
