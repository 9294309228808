import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AnexoLoaService } from '../service/anexo-loa.service';
import { FuncaoService, LoginContabil, Exercicio, Relatorio, GlobalService } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class Anexo8 implements OnDestroy {
  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLoaService) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // tslint:disable: max-line-length
  public montarRelatorio(exercicio: Exercicio, orgaos: number[], orgaosNome: string[]) {
    this.anexoServico.obterAnexo8(exercicio.id, orgaos, this.login.ppa.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {

        Relatorio.imprimirPersonalizado(
          'DEMONSTRATIVO DA DESPESA POR FUNÇÕES, SUBFUNÇÕES E PROGRAMAS, CONFORME O VÍNCULO DE RECURSOS'
          , this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          this.conteudo(dados, exercicio, orgaosNome),
          'portrait', 'ANEXO 8',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          }, undefined, undefined, undefined, (orgaosNome.length === 1 ? orgaosNome[0] : 'Consolidado'));
      });
  }

  private conteudo(dados: any[], exercicio: Exercicio, orgaosNome: string[]): {}[] {

    // monta o cabecalho
    const registros: {}[] = [[{
      text: `EXERCÍCIO ${exercicio.ano}`,
      alignment: 'center', bold: true, colSpan: 6, border: [true, true, true, false]
    }, '', '', '', '', ''],
    [{
      text: 'Lei nº 4.320/64, Art. 2º, §1º, II - Anexo 8',
      alignment: 'center',
      bold: true,
      colSpan: 6, border: [true, false, true, false]
    }, '', '', '', '', ''],
    [{
      text: '',
      border: [true, true, true, false]
    }, {
      text: '',
      border: [true, true, true, false]
    },
    {
      text: 'TESOURO', alignment: 'center',
      colSpan: 2, border: [true, true, true, false]
    }, {
      text: ''
    }, {
      text: '',
      border: [true, true, true, false]
    }, {
      text: '',
      border: [true, true, true, false]
    }],
    [{
      text: 'CÓDIGO',
      alignment: 'center',
      bold: true, fontSize: 8, border: [true, false, true, true]
    }, {
      text: 'ESPECIFICAÇÃO',
      alignment: 'center',
      bold: true, fontSize: 8, border: [true, false, true, true]
    }, {
      text: 'ORDINÁRIO',
      alignment: 'center',
      bold: true, fontSize: 8
    }, {
      text: 'VINCULADO',
      alignment: 'center',
      bold: true, fontSize: 8
    }, {
      text: 'OUTRAS FONTES',
      alignment: 'center',
      bold: true, fontSize: 8, border: [true, false, true, true]
    }, {
      text: 'TOTAL',
      alignment: 'center',
      bold: true, fontSize: 8, border: [true, false, true, true]
    }]
    ];

    // monta o agrupamento do relatório
    const gruposFuncao = this.funcaoService.agrupar(dados, 'nome_funcao',
      ['ordinario', 'vinculado', 'outra']);
    let total1 = 0;
    let total2 = 0;
    let total0 = 0;
    for (const funcao of gruposFuncao) {
      registros.push(
        [{
          text: this.funcaoService.mascarar('##', funcao.grupo as string), fontSize: 8,
          bold: true, border: [true, true, true, true]
        }, {
          text: (funcao.grupo as string).substr(2), fontSize: 8,
          bold: true, border: [true, true, true, true]
        },
        {
          text: funcao.totalizadores[`ordinario`] > 0 ? this.funcaoService.convertToBrNumber(funcao.totalizadores[`ordinario`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, true, false, true]
        },
        {
          text: funcao.totalizadores[`vinculado`] > 0 ? this.funcaoService.convertToBrNumber(funcao.totalizadores[`vinculado`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, true, false, true]
        },
        {
          text: funcao.totalizadores[`outra`] > 0 ? this.funcaoService.convertToBrNumber(funcao.totalizadores[`outra`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, true, false, true]
        },
        {
          text: this.funcaoService.convertToBrNumber(+funcao.totalizadores[`ordinario`] + +funcao.totalizadores[`vinculado`] + +funcao.totalizadores[`outra`]),
          alignment: 'right', bold: true, fontSize: 8, border: [true, true, true, true]
        }
        ]);
      total1 += +funcao.totalizadores[`ordinario`];
      total2 += +funcao.totalizadores[`vinculado`];
      total0 += +funcao.totalizadores[`outra`];
      const gruposSubFuncao = this.funcaoService.agrupar(funcao.registros, 'nome_subfuncao',
        ['ordinario', 'vinculado', 'outra']);

      for (const subfuncao of gruposSubFuncao) {
        registros.push(
          [{
            text: this.funcaoService.mascarar('##.###', subfuncao.grupo as string), fontSize: 8,
            bold: true, border: [true, false, true, false]
          }, {
            text: (subfuncao.grupo as string).substr(5), fontSize: 8,
            bold: true, border: [true, false, true, false]
          },
          {
            text: subfuncao.totalizadores[`ordinario`] > 0 ? this.funcaoService.convertToBrNumber(subfuncao.totalizadores[`ordinario`]) : '', alignment: 'right',
            bold: true, fontSize: 8, border: [true, false, false, false]
          },
          {
            text: subfuncao.totalizadores[`vinculado`] > 0 ? this.funcaoService.convertToBrNumber(subfuncao.totalizadores[`vinculado`]) : '', alignment: 'right',
            bold: true, fontSize: 8, border: [true, false, false, false]
          },
          {
            text: subfuncao.totalizadores[`outra`] > 0 ? this.funcaoService.convertToBrNumber(subfuncao.totalizadores[`outra`]) : '', alignment: 'right',
            bold: true, fontSize: 8, border: [true, false, false, false]
          },
          {
            text: this.funcaoService.convertToBrNumber(+subfuncao.totalizadores[`ordinario`] + +subfuncao.totalizadores[`vinculado`] + +subfuncao.totalizadores[`outra`]), alignment: 'right',
            bold: true, fontSize: 8, border: [true, false, true, false]
          }
          ]);


        const gruposPrograma = this.funcaoService.agrupar(subfuncao.registros, 'nome_programa',
          ['ordinario', 'vinculado', 'outra']);

        for (const programa of gruposPrograma) {
          registros.push(
            [{
              text: this.funcaoService.mascarar('##.###.####', programa.grupo as string), fontSize: 8,
              bold: true, border: [true, false, true, false]
            }, {
              text: (programa.grupo as string).substr(9), fontSize: 8,
              bold: true, border: [true, false, true, false]
            },
            {
              text: programa.totalizadores[`ordinario`] > 0 ? this.funcaoService.convertToBrNumber(programa.totalizadores[`ordinario`]) : '', alignment: 'right',
              bold: true, fontSize: 8, border: [true, false, false, false]
            },
            {
              text: programa.totalizadores[`vinculado`] > 0 ? this.funcaoService.convertToBrNumber(programa.totalizadores[`vinculado`]) : '', alignment: 'right',
              bold: true, fontSize: 8, border: [true, false, false, false]
            },
            {
              text: programa.totalizadores[`outra`] > 0 ? this.funcaoService.convertToBrNumber(programa.totalizadores[`outra`]) : '', alignment: 'right',
              bold: true, fontSize: 8, border: [true, false, false, false]
            },
            {
              text: this.funcaoService.convertToBrNumber(+programa.totalizadores[`ordinario`] + +programa.totalizadores[`vinculado`] + +programa.totalizadores[`outra`]),
              alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
            }
            ]
          );

          // lista os registros do relatorio
          for (const registro of programa.registros) {
            registros.push([
              {
                text: `${this.funcaoService.mascarar('##.###.####.####', registro.codigo_acao)}`, fontSize: 8, border: [true, false, false, false],
              },
              {
                text: `${registro.nome_acao}`, fontSize: 8, border: [true, false, false, false]
              },
              {
                text: registro.ordinario > 0 ? this.funcaoService.convertToBrNumber(registro.ordinario) : '', alignment: 'right',
                fontSize: 8, border: [true, false, false, false]
              },
              {
                text: registro.vinculado > 0 ? this.funcaoService.convertToBrNumber(registro.vinculado) : '', alignment: 'right',
                fontSize: 8, border: [true, false, false, false]
              },
              {
                text: registro.outra > 0 ? this.funcaoService.convertToBrNumber(registro.outra) : '', alignment: 'right',
                fontSize: 8, border: [true, false, false, false]
              },
              {
                text: this.funcaoService.convertToBrNumber(+registro.ordinario + +registro.vinculado + +registro.outra),
                alignment: 'right', fontSize: 8, border: [true, false, true, false]
              }
            ]);
          }
        }
      }
    }

    registros.push([
      {
        text: 'TOTAL', colSpan: 2, fontSize: 8, border: [true, true, true, true], margin: [0, 0, 0, 5]
      },
      {
        text: '', fontSize: 8, border: [true, true, true, true]
      },
      {
        text: total1 > 0 ? this.funcaoService.convertToBrNumber(total1) : '', alignment: 'right',
        fontSize: 8, border: [true, true, true, true]
      },
      {
        text: total2 > 0 ? this.funcaoService.convertToBrNumber(total2) : '', alignment: 'right',
        fontSize: 8, border: [true, true, true, true]
      },
      {
        text: total0 > 0 ? this.funcaoService.convertToBrNumber(total0) : '', alignment: 'right',
        fontSize: 8, border: [true, true, true, true]
      },
      {
        text: this.funcaoService.convertToBrNumber(+total1 + +total2 + +total0),
        alignment: 'right', fontSize: 8, border: [true, true, true, true]
      }
    ]);

    return [{
      layout: 'linhas',
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        dontBreakRows: true,
        headerRows: 2,
        widths: ['auto', '*', 60, 60, 60, 70],
        body: registros
      }
    }, {text: '\n\n'}, {text: 'Entidade\n', fontSize: 7}, {text: orgaosNome.join('\n'), fontSize: 6}];
  }


}
