import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ContratoRescisao } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ContratoRescisaoService extends BaseResourceService<ContratoRescisao> {

  constructor(
    protected injector: Injector
  ) {
    super(`contrato-rescisoes`, injector);
  }
}
