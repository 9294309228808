import { BaseResourceModel } from "../../models/base-resource.model";
import { Licitacao } from "./licitacao.model";


export class ListaVerificacao extends BaseResourceModel {
  constructor(
    public id?: number,
    public servico?: boolean,
    public aquisicao?: boolean,
    public lista_verificacao1?: any,
    public lista_verificacao2A?: any,
    public lista_verificacao2B?: any,
    public lista_verificacao3A?: any,
    public lista_verificacao3B?: any,
    public lista_verificacao_comum?: any,
    public lista_verificacao_pesquisa?: any,
    public lista_verificacao_aquisicao?: any,
    public lista_verificacao_servico?: any,
    public licitacao?: Licitacao,

  ) {
    super();
  }

  static converteJson(json: any): ListaVerificacao {
    return Object.assign(new ListaVerificacao(), json);
  }
}
