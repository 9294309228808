import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, PregaoLance } from 'eddydata-lib';

@Injectable({
    providedIn: 'root'
})
export class PregaoLanceService extends BaseResourceService<PregaoLance> {

    constructor(
        protected injector: Injector
    ) {
        super(`pregao-lances`, injector);
    }

}
