import { PropostaService } from 'administrativo-lib';
import { AssinaturaPessoa, Compra, Favorecido, FuncaoService, GlobalService, Licitacao, LicitacaoPipe, Login, Relatorio } from 'eddydata-lib';
import { CompraService } from '../../compra/service/compra.service';
export class NotaCompra {
    protected funcaoService: FuncaoService;
    protected globalService: GlobalService;
    private prefeito: string;
    private cargo_prefeito: string;

    constructor(
        private compraService: CompraService,
        private login: Login,
        private pessoa?: AssinaturaPessoa,
        private propostaService?: PropostaService
    ) {
        this.funcaoService = new FuncaoService();
        this.globalService = new GlobalService();
    }

    public async imprimir(model: Compra[]) {
        Relatorio.imprimirPersonalizado('ORDEM DE FORNECIMENTO', this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.montarConteudo(model),
            'portrait', 'ORDEM DE FORNECIMENTO', this.layout(), true, false);
    }

    public async exportar(model: Compra[]): Promise<Blob> {
        return Relatorio.exportarPersonalizado('ORDEM DE FORNECIMENTO', this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.montarConteudo(model),
            'portrait', 'ORDEM DE FORNECIMENTO', this.layout(), true, false);
    }

    private async montarConteudo(lista: Compra[]) {
        const conteudo = [];

        for (const entidade of lista) {
            if (conteudo.length > 0) {
                conteudo.push([{ text: '', pageBreak: 'after' }]);
            }

            entidade.numero_impressao += 1;

            conteudo.push(this.dadosCabecalho(this.login, entidade?.exercicio?.ano)
                .concat(this.dadosCompra(entidade))
                .concat(this.dadosClassificacao(entidade))
                .concat(this.dadosFornecedor(entidade))
                .concat(await this.dadosItens(entidade))
                .concat(this.dadosAdicionais(entidade, this.login))
                .concat(this.dadosLocalEntrega(entidade)));
        }
        if (lista.length > 0)
            this.compraService.alterarNumeroImpressaoLote(lista.map((c) => { return { numero: c.numero_impressao, compra: c.id }; }))
                .subscribe((data) => { }, (error) => console.error(error.error.payload));
        return conteudo;
    }

    private dadosCabecalho(login: Login, ano: number): {}[] {
        let brasaoImage: {};
        if (login.brasao) {
            brasaoImage = {
                image: login.brasao,
                width: 70,
                alignment: 'left',
                margin: [0, 0, 0, 0],
                border: [true, true, false, false]
            };
        } else {
            brasaoImage = { margin: [0, 10, 0, 0], text: '', border: [true, true, false, false] };
        }

        brasaoImage['rowSpan'] = 5;

        const conteudo = [];

        conteudo.push([brasaoImage, { text: login.orgao.nome, bold: true, alignment: 'center', fontSize: 13, border: [false, true, true, false] }]);
        conteudo.push([
            { text: '' },
            {
                text: `${this.login.orgao.endereco}, ${this.login.cidade.nome} - CEP: ${this.login.orgao.cep} - PABX: ${this.login.orgao.telefone} - FAX: ${this.login.orgao.telefone}`,
                fontSize: 8, alignment: 'center', border: [false, false, true, false]
            }
        ]);
        conteudo.push([
            { text: '' },
            { text: `C.N.P.J.: ${this.login.orgao.cnpj}     -    Site: ${this.login.orgao.site}`, fontSize: 8, alignment: 'center', border: [false, false, true, false] }
        ]);
        conteudo.push([
            { text: '' },
            { text: 'ORDEM DE FORNECIMENTO', bold: true, alignment: 'center', fontSize: 13, border: [false, false, true, false] }
        ]);
        conteudo.push([
            { text: '' },
            { text: `PRÉ-EMPENHO - ${ano}`, bold: true, alignment: 'center', fontSize: 12, border: [false, false, true, false] }
        ]);

        return [{
            layout: 'linhas',
            table: {
                widths: [50, '*'],
                body: conteudo
            }
        }];
    }

    private dadosCompra(compra: Compra): {}[] {
        const tipoCompra = this.globalService.obterListaTiposEmpenhos().find(x => x.id === compra.tipo_compra);
        const conteudo = [
            [
                { text: 'OF Nº', fontSize: 7, border: [true, true, false, false] },
                { text: 'Ficha', fontSize: 7, border: [true, true, false, false] },
                { text: 'Tipo', fontSize: 7, border: [true, true, false, false] },
                { text: 'Data', fontSize: 7, border: [true, true, false, false] },
                { text: 'Prazo de Entrega', fontSize: 7, border: [true, true, false, false] },
                { text: 'Licitação', fontSize: 7, border: [true, true, false, false] },
                { text: 'Processo', fontSize: 7, border: [true, true, false, false] },
                { text: 'Contrato', fontSize: 7, border: [true, true, false, false] },
                { text: 'Venc. Contrato', fontSize: 7, border: [true, true, false, false] },
                { text: 'Prazo de pagamento', fontSize: 7, border: [true, true, false, false] },
                { text: 'Modalidade', fontSize: 7, border: [true, true, true, false] }
            ],
            [
                { text: compra.numero, alignment: 'center', bold: true, border: [true, false, true, true] },
                { text: compra.ficha?.numero, alignment: 'center', bold: true, border: [false, false, false, true] },
                {
                    text: tipoCompra ? tipoCompra.nome : '-',
                    alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
                },
                {
                    text: this.funcaoService.converteDataBR(compra.data_compra),
                    alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
                },
                {
                    text: compra?.prazo_entrega?.nome,
                    alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
                },
                {
                    text: compra.licitacao ? new LicitacaoPipe().transform(compra.licitacao?.numero) : '',
                    alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
                },
                {
                    text: compra.processo,
                    alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
                },
                {
                    text: compra?.contrato ? new LicitacaoPipe().transform(compra.contrato?.numero) : '',
                    alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
                },
                {
                    text: this.funcaoService.converteDataBR(compra.contrato?.data_termino),
                    alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
                },
                {
                    text: compra?.prazo_pagamento?.nome,
                    alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
                },
                {
                    text: compra.modalidade?.nome,
                    alignment: 'center', fontSize: 5, bold: true, border: [true, false, true, true]
                },
            ]
        ];

        return [{
            layout: 'linhas',
            table: {
                widths: [30, 25, 45, 40, 45, 45, 45, 45, 45, 45, '*'],
                body: conteudo
            }
        }];
    }

    private dadosClassificacao(compra: Compra): {}[] {
        const conteudo = [
            [
                { text: 'UNIDADE:', border: [true, false, false, false] },
                {
                    text: `${compra.ficha?.executora?.unidade?.codigo} ${compra.ficha?.executora?.unidade?.nome}`,
                    border: [false, false, false, false]
                },
                { text: `RCMS:      ${compra.rcms ? compra.rcms?.numero : ''}`, border: [false, false, true, false], fontSize: 12, bold: true },
            ],
            [
                { text: 'EXECUTORA:', border: [true, false, false, false] },
                { text: `${compra.ficha?.executora?.codigo} ${compra.ficha?.executora?.nome} `, border: [false, false, false, false] },
                { text: `Nº EMPENHO:    ${compra.empenho ? compra.empenho?.numero : ''}`, border: [false, false, true, false] },
            ],
            [
                { text: 'DESPESA:', border: [true, false, false, false] },
                { text: `${compra.ficha?.despesa?.codigo} ${compra.ficha?.despesa?.nome} `, border: [false, false, false, false] },
                { text: `SETOR:     ${compra?.setor ? compra.setor.nome : compra.rcms?.setor ? compra.rcms.setor.nome : ''}`, border: [false, false, true, false] },
            ],
            [
                { text: 'SUBELEMENTO:', border: [true, false, false, false] },
                { text: `${compra.subelemento?.codigo} ${compra.subelemento?.nome} `, border: [false, false, false, false] },
                { text: `VIA Nº:    ${compra.numero_impressao}`, border: [false, false, true, false], }
            ],
            [
                { text: 'RECURSO:', border: [true, false, false, false] },
                {
                    text: `${compra.ficha?.recurso?.codigo} ${compra.ficha?.aplicacao?.codigo} ${compra.ficha?.aplicacao?.nome}`,
                    border: [false, false, false, false]
                },
                { text: '', border: [false, false, true, false] }
            ],
            [
                { text: 'PROJETO:', border: [true, false, false, true] },
                { text: `${compra.ficha?.acao?.codigo} ${compra.ficha?.acao?.nome} `, border: [false, false, false, true] },
                { text: '', border: [false, false, true, true] }
            ],

        ];

        return [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                headerRows: 2,
                widths: [60, '*', 160],
                body: conteudo
            }
        }];
    }

    private dadosFornecedor(compra: Compra): {}[] {
        const conteudo = [
            [
                { text: 'FORNECEDOR:', border: [true, false, false, false], bold: true },
                {
                    text: compra.favorecido?.nome?.toUpperCase(),
                    border: [false, false, true, false], colSpan: 4, bold: true
                }, '', '', ''
            ],
            [
                { text: 'CPF/CNPJ:', border: [true, false, false, false] },
                {
                    text: compra.favorecido?.cpf_cnpj,
                    border: [false, false, true, false], colSpan: 4
                }, '', '', ''
            ],
            [
                { text: 'ENDEREÇO:', border: [true, false, false, false] },
                { text: compra.favorecido?.endereco?.toUpperCase(), border: [false, false, false, false] },
                { text: 'TIPO:', border: [false, false, false, false] },
                {
                    text: compra.favorecido?.tipo?.nome,
                    border: [false, false, false, false], alignment: 'left'
                },
                { text: '', border: [false, false, true, false] }
            ],
            [
                { text: 'BAIRRO:', border: [true, false, false, false] },
                {
                    columns: [
                        { text: compra.favorecido?.bairro?.toUpperCase(), width: '*' },
                        { text: `CEP:   ${compra.favorecido?.cep}`, width: 80 }
                    ], border: [false, false, false, false]
                },
                { text: 'CÓDIGO:', border: [false, false, false, false] },
                {
                    columns: [
                        { text: compra.favorecido?.id, width: '*' },
                        { text: `FONE:   ${compra.favorecido?.telefone}`, width: 80 }
                    ], border: [false, false, false, false], alignment: 'left'
                },
                { text: '', border: [false, false, true, false] }
            ],
            [
                { text: 'CIDADE:', border: [true, false, false, false] },
                {
                    text: compra.favorecido?.municipio?.toUpperCase(),
                    border: [false, false, false, false]
                },
                { text: 'ESTADO:', border: [false, false, false, false] },
                {
                    columns: [
                        { text: compra.favorecido?.uf, width: '*' },
                        { text: `FAX:`, width: 80 }
                    ], border: [false, false, false, false]
                },
                { text: '', border: [false, false, true, false] }
            ],
            [
                { text: 'E-MAIL:', border: [true, false, false, true] },
                { text: compra.favorecido?.email?.toUpperCase(), border: [false, false, false, true] },
                { text: 'AGÊNCIA:', border: [false, false, false, true] },
                {
                    columns: [
                        { text: '', width: '*' },
                        { text: `C/C: `, width: 80 }
                    ], border: [false, false, false, true]
                },
                { text: '', border: [false, false, true, true] }
            ],

        ];

        return [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                headerRows: 2,
                widths: [60, '*', 40, '*', 10],
                body: conteudo
            }
        }];
    }

    private async dadosItens(compra: Compra): Promise<{}[]> {
        let conteudo = [];

        conteudo.push([
            { text: 'QUANTIDADE', alignment: 'center', bold: true },
            { text: 'CÓDIGO', alignment: 'center', bold: true },
            { text: 'UN', alignment: 'center', bold: true },
            { text: 'DESCRIÇÃO DO ITEM', alignment: 'center', bold: true },
            { text: 'MARCA', alignment: 'center', bold: true },
            { text: 'VL.UNITÁRIO ', alignment: 'center', bold: true },
            { text: 'VALOR', alignment: 'center', bold: true }
        ]);

        let valor_total = 0;
        let valor_desconto = 0;
        let valor_icmsipi = 0;

        for (let item of compra.itens) {

            valor_total += +item.quantidade * +item.valor_unitario;
            valor_desconto += +item.valor_desconto;
            valor_icmsipi += +item.valor_icmsipi;

            conteudo.push([
                { text: this.funcaoService.convertToBrNumber(item.quantidade), alignment: 'right', border: [true, false, true, false] },
                { text: item.produto_unidade?.produto?.codigo, alignment: 'center', border: [true, false, true, false] },
                { text: item.produto_unidade?.unidade?.nome, alignment: 'center', border: [true, false, true, false] },
                { text: item.produto_unidade?.produto?.nome, alignment: 'left', border: [true, false, true, false] },
                { text: await this.buscarMarca(compra.licitacao, item, compra.favorecido), alignment: 'right', border: [true, false, true, false] },
                { text: this.funcaoService.convertToBrNumber(item.valor_unitario, 6), alignment: 'right', border: [true, false, true, false] },
                { text: this.funcaoService.convertToBrNumber(+item.quantidade * +item.valor_unitario, 6), alignment: 'right', border: [true, false, true, false] }
            ]);
        }

        let linhas = 51 - conteudo.length;
        if (linhas > 0) {
            conteudo = conteudo.concat(Array.from(Array(linhas).keys()).map(_ =>
                Array.from(Array(7).keys()).map((_) => { return { text: '', border: [true, false, true, false] }; })
            ));
        }

        conteudo.push([
            { text: '', colSpan: 5, border: [true, true, true, false] },
            '',
            '',
            '',
            '',
            { text: 'SUB-TOTAL', alignment: 'right', bold: true, border: [true, true, false, false] },
            { text: this.funcaoService.convertToBrNumber(valor_total, 6), alignment: 'right', border: [true, true, true, false] }
        ]);
        conteudo.push([
            { text: '', colSpan: 5, border: [true, false, true, false] },
            '',
            '',
            '',
            '',
            { text: 'DESCONTO', alignment: 'right', bold: true, border: [true, false, false, false] },
            { text: this.funcaoService.convertToBrNumber(valor_desconto), alignment: 'right', border: [true, false, true, false] }
        ]);
        conteudo.push([
            { text: '', colSpan: 5, border: [true, false, true, false] },
            '',
            '',
            '',
            '',
            { text: 'IPI/ICMS', alignment: 'right', bold: true, border: [true, false, false, false] },
            { text: this.funcaoService.convertToBrNumber(valor_icmsipi), alignment: 'right', border: [true, false, true, false] }
        ]);

        conteudo.push([
            { text: 'TOTAL GERAL', colSpan: 5, bold: true, border: [true, true, false, true] },
            '',
            '',
            '',
            '',
            { text: 'TOTAL', alignment: 'right', bold: true, border: [false, true, false, true] },
            { text: this.funcaoService.convertToBrNumber(valor_total + valor_icmsipi - valor_desconto), alignment: 'right', bold: true, border: [false, true, true, true] }
        ]);

        return [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                widths: ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto'],
                body: conteudo
            }
        }];
    }


    private dadosAdicionais(compra: Compra, log: Login): {}[] {
        const conteudo = [
            [
                {
                    text: compra.observacao ? compra.observacao : '',
                    border: [true, false, false, false], bold: true
                },
                { text: '', border: [false, false, false, false], alignment: 'left' },
                { text: '', border: [false, false, true, false] },
            ],
            [
                { text: this.pessoa?.pessoa.nome ? this.pessoa.pessoa.nome : 'Autorizado', border: [true, false, false, false], bold: true, alignment: 'left' },
                { text: '', border: [false, false, false, false], },
                { text: '', border: [false, false, true, false] }
            ],
            [
                { text: '', border: [true, false, false, false], },
                { text: this.pessoa?.cargo ? this.pessoa.cargo : '', border: [false, false, false, false], alignment: 'center' },
                { text: '', border: [false, false, true, false] }
            ],
            [
                {
                    text: [
                        { text: `OPERADOR     `, bold: true }, { text: log.usuario?.nome?.toUpperCase() }
                    ], border: [true, false, false, true]
                },
                { text: `${this.prefeito ? this.prefeito : ''} - ${this.cargo_prefeito ? this.cargo_prefeito : ''}`, border: [false, false, false, true] },
                { text: '', border: [false, false, true, true] }
            ],
        ];

        return [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                heights: [10, 0],
                widths: ['*', 200, 10],
                body: conteudo
            }
        }];
    }

    private dadosLocalEntrega(compra: Compra): {}[] {
        return [{
            text: [
                { text: `LOCAL ENTREGA:        `, fontSize: 8, bold: true },
                { text: compra.local_entrega ? compra.local_entrega : '', fontSize: 8 }
            ], margin: [5, 5, 0, 0]
        }, {
            text: [
                {
                    text: this.login.parametro['compras']?.observacao_of ?
                        this.login.parametro['compras']?.observacao_of : '', fontSize: 8, bold: false, alignment: 'justify'
                },
            ], margin: [5, 5, 5, 5]
        }];
    }


    private layout() {
        return {
            linhas: {
                hLineWidth() {
                    return 1;
                },
                vLineWidth() {
                    return 1;
                },
                hLineColor() {
                    return 'black';
                },
                paddingLeft() {
                    return 3;
                },
                paddingRight() {
                    return 3;
                }
            }
        };
    }

    public async buscarMarca(licitacao: Licitacao, item, favorecido: Favorecido) {
        let marca: string = ''

        if (licitacao?.id && item?.produto_unidade?.id) {
            let proposta = (await this.propostaService.filtrar(1, -1,
                {
                    'memorial.licitacao.id': licitacao.id,
                    'memorial.produto_unidade.id': item.produto_unidade.id,
                    'proponente.favorecido.id': favorecido.id
                }).toPromise()).content

            if (proposta) {
                marca = proposta[0]?.marca;
            }
        }

        return marca
    }
}

