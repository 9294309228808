import { Component, ElementRef, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { TombamentoService } from 'administrativo-lib';
import { BaseResourceFormDlgComponent, DateFormatPipe, EddyAutoComplete, FuncaoService, GlobalService, Inventario, LoginContabil, Setor, SetorService } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { InventarioService } from '../service/inventario.service';

declare var $: any;

@Component({
  selector: 'app-inventario-dlg',
  templateUrl: './inventario-dlg.component.html'
})
export class InventarioDlgComponent extends BaseResourceFormDlgComponent<Inventario, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public setorAutoComplete: EddyAutoComplete<Setor>;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected setorService: SetorService,
    protected tombamentoService: TombamentoService,
    protected inventarioService: InventarioService,
  ) {
    super(injector, Inventario.converteJson, inventarioService, "/inventarios");
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      status: ['PENDENTE', [Validators.required]],
      descricao: [null, [Validators.required]],
      data_inicio: [null, [Validators.required]],
      data_termino: [null, [Validators.required]],
      usuario: [this.login.usuario, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      setores: [null]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'usuario,orgao,setores,setores.setor' };
  }

  protected afterLoad() {
    this.entidade.data_inicio = new DateFormatPipe().transform(this.entidade.data_inicio, []);
    this.entidade.data_termino = new DateFormatPipe().transform(this.entidade.data_termino, []);
  }

  protected afterInit(): void {
    this.setorAutoComplete = new EddyAutoComplete(this.entidadeForm.get('setor'), this.setorService,
      'id', ['id', 'nome'],
      { orgao_id: this.login.orgao.id, relations: 'localizacoes', orderBy: 'nome,localizacoes.localizacao' }, { number: ['id'], text: ['nome'] });
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit() {
    try {
      this.entidadeForm.get('setores').setValue(this.entidade?.setores);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(modelo: Inventario) {
    this.sair();
  }

  public sair(): void {
    $('#dlgInventarioCad').modal('hide');
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
