import {
  Component, OnInit, OnChanges, Input,
  ViewChild, ElementRef, SimpleChanges,
  EventEmitter, Output, AfterViewInit, OnDestroy
} from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Executora, LoginContabil, FuncaoService, GlobalService, Unidade, ExecutoraService } from 'eddydata-lib';

@Component({
  selector: 'lib-executora-form',
  templateUrl: './executora-form.component.html'
})
export class ExecutoraFormComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('f') formGroup: any;

  public entity: Executora = new Executora();
  public listaExecutoras: Array<any>;
  protected unsubscribe: Subject<void> = new Subject();
  public proximoCodigo: number;

  public codigou: ElementRef;

  @Input() lista: Array<any>;
  @Input() entidade: Unidade;
  @Input() login: LoginContabil;
  @Input() codigounidade: any;
  @Output() preencherTabela = new EventEmitter();

  @ViewChild('codigoe') public inputCodigo: ElementRef;
  @ViewChild('btnAdicionarExecutora') public btnAdicionarExecutora: ElementRef;
  @ViewChild('btnEditarExecutora') public btnEditarExecutora: ElementRef;
  @ViewChild('btnFecharExecutora') public btnFecharExecutora: ElementRef;

  constructor(
    protected confirmationService: ConfirmationService,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected executoraService: ExecutoraService,
  ) { }

  ngOnInit() {
    this.listaExecutoras = this.lista;
    this.codigou = this.codigounidade;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lista) {
      this.listaExecutoras = this.lista;
    }
  }

  ngAfterViewInit() {
    new GlobalService().calendarMascara();
    if (this.entity) {
      setTimeout(() => this.inputCodigo.nativeElement.focus(), 2000);
    }
  }

  private loadExecutora() {
    if (this.entidade.id) {
      this.executoraService.filtrar(1, -1,
        { relations: 'unidade', 'unidade.id': this.entidade.id }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaExecutoras = res ? res.content : new Array<Executora>();
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    } else {
      this.listaExecutoras.splice(0, 1);
    }
  }

  public adicionarExecutora() {
    this.entity = new Executora();
    this.entity.unidade = this.entidade;
    
    let codigoUnidade = this.codigounidade.substr(0,4)

    if (this.lista.length > 8) {
      this.entity.codigo = codigoUnidade ? codigoUnidade + (codigoUnidade.substr(4, 1)) + (this.lista.length + 1) : '';
    } else {
      this.entity.codigo = codigoUnidade ? codigoUnidade + "0" + (codigoUnidade.substr(4, 1)) + (this.lista.length + 1) : '';
    }

    if (!this.listaExecutoras) {
      this.listaExecutoras = new Array();
    }
    this.listaExecutoras.unshift(this.entity);
    setTimeout(() => this.inputCodigo.nativeElement.focus(), 1000);
  }

  public async salvarExecutora(item: any) {
    try {
      if (!item.nome) {
        throw new Error('Informe o nome unidade administrativa!');
      }
      if (!item.tribunal) {
        throw new Error('Informe o código no TCE!');
      }
      if (!item.id) {
        const existe = await this.executoraService.obterPorCodigo(item.codigo, this.login.ppa.id).toPromise();
        const model = existe.content[0];
        if (model) {
          if (item.id === null || model.id !== item.id) {
            throw new Error('Código informado para a unidade executora já existe');
          }
        }
      }
      this.btnFecharExecutora.nativeElement.click();
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  public editarExecutora(item: Executora) {
    this.entity = item;
  }

  public cancelarExecutora() {
    this.loadExecutora();
  }

  public removerExecutora(item: Executora, index: number) {
    if (item.id) {
      this.confirmationService.confirm({
        message: 'Deseja realmente excluir a unidade executora?',
        header: 'Remoção',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.executoraService.remover(item.id).pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
              // this.preencherTabela.emit();
              this.messageService.add({ severity: 'warn', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
              this.listaExecutoras.splice(index, 1);
            });
        },
        reject: () => {
        }
      });
    } else {
      this.listaExecutoras.splice(index, 1);
    }

  }
}
