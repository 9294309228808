import { DatePipe } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContratoService, FuncaoGovernoService, RecursoService, DespesaService } from 'administrativo-lib';
import {
  Contrato, Despesa,
  EddyAutoComplete, EmpenhoResto, Executora, ExecutoraService, Exercicio, ExercicioService, Favorecido, FavorecidoService, FichaDespesa, FuncaoGoverno,
  FuncaoService, GlobalService, Login, LoginContabil, Recurso,
  Relatorio
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { tsXLXS } from 'ts-xlsx-export';
import { EmpenhoRestoService } from '../service/empenho-resto.service';
import * as toastr from 'toastr';
import { FichaDespesaService } from 'contabil-lib';

@Component({
  selector: 'lib-empenho-resto-rpt',
  templateUrl: './empenho-resto-rpt.component.html'
})
export class EmpenhoRestoRptComponent implements OnInit, OnDestroy {

  login: LoginContabil = new LoginContabil();
  funcaoGoverno: FuncaoGoverno;
  subFuncaoGoverno: FuncaoGoverno;
  despesa: Despesa;
  recurso: Recurso;
  aplicacao: Recurso;
  contrato: Contrato;
  filtroDespesa: boolean;
  filtroFuncao: boolean;
  filtroSubFuncao: boolean;
  filtroRecurso: boolean;
  filtroAplicacao: boolean;
  filtroContrato: boolean;
  public selectedOrdem: string;
  public selectUsuario: boolean;
  public filtroFicha: boolean;

  public exercicioId: number;
  public listaExercicios: Array<any>;

  public ptBR: any;

  public listaRelatorio: Array<any>;
  public contratoAutoComplete: EddyAutoComplete<Contrato>;
  public funcaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public subFuncaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public despesaAutoComplete: EddyAutoComplete<Despesa>;
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;
  public fichaDespesaAutoComplete: EddyAutoComplete<FichaDespesa>;
  public unidadeAutoComplete: EddyAutoComplete<Executora>;

  public relatorio = '1';
  public dataInicial: Date;
  public dataFinal: Date;
  protected unsubscribe: Subject<void> = new Subject();

  public listMeses: { id: string, nome: string }[];
  public mesSelecionado: string;
  public opcao: string = '2';

  public filtroPeriodoOf: boolean
  public dataOfInicial: Date;
  public dataOfFinal: Date;

  public filtroEmpenhoNumero: boolean;
  public numeroInicio: number;
  public numeroFinal: number;

  public filtroTipoEmpenho: boolean;
  public tipoEmpenhoSelecionado: string;
  public listaTipos: { id: string, nome: string }[];

  public selectAgrupamento: string = "1";

  filtroFavorecido: boolean;

  public favorecido: Favorecido;

  public ficha: FichaDespesa;

  public filtroUnidade: boolean;
  public unidade: Executora;

  constructor(
    private router: Router,
    protected injector: Injector,
    protected messageService: MessageService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected funcaoGovernoService: FuncaoGovernoService,
    protected recursoService: RecursoService,
    protected despesaService: DespesaService,
    protected contratoService: ContratoService,
    protected exercicioService: ExercicioService,
    protected empenhoRestosService: EmpenhoRestoService,
    protected favorecidoService: FavorecidoService,
    protected fichaDespesaService: FichaDespesaService,
    private executoraService: ExecutoraService) {
  }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.login = GlobalService.obterSessaoLogin();
    this.selectedOrdem = 'ord1';
    this.selectUsuario = false;
    this.exercicioId = this.login.exercicio.id;
    this.dataInicial = new Date(this.login.exercicio.ano, 0, 1);
    this.dataFinal = new Date();
    this.listaRelatorio = [];
    this.listaRelatorio.push({ label: 'EMPENHOS DE RESTOS A PAGAR', value: 1 });
    this.listaRelatorio.push({ label: 'EMPENHOS DE RESTOS A PAGAR EM ABERTO', value: 2 });
    this.carregarAutoCompletes();
    let interval = setInterval(() => { new GlobalService().calendarMascara(); clearInterval(interval) }, 1000);
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaExercicios = new Array();
        const lista = dados.content;
        for (const exercicio of lista as Array<Exercicio>) {
          this.listaExercicios.push({ id: exercicio.id, ano: exercicio.ano });
        }
      });
    this.listaTipos = [];
    this.listaTipos.push(
      { id: 'G', nome: 'Geral' },
      { id: 'O', nome: 'Ordinário' },
      { id: 'E', nome: 'Estimativa ou Global' }
    )
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public gerarRelatorio() {
    const parametros = {};
    if (!this.dataInicial || !this.dataFinal) {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }

    //Filtros gerais

    parametros['orgao.id'] = this.login.orgao.id;

    if (this.opcao === '2') {
      if (this.dataInicial && this.dataFinal) {
        parametros['data_empenho$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
        parametros['data_empenho$le'] = this.funcaoService.converteDataSQL(this.dataFinal);
      } else {
        toastr.warning(`Informe o período!`)
        throw new Error(`Informe o período!`)
      };
    } else {
      if (!this.exercicioId) {
        toastr.warning(`Selecione o exercício desejado!`)
        throw new Error(`Selecione o exercício desejado!`)
      };
      parametros['exercicio.id'] = this.exercicioId;
    };

    // parametros['data_empenho$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
    // parametros['data_empenho$le'] = this.funcaoService.converteDataSQL(this.dataFinal);

    if (this.filtroContrato) {
      parametros['contrato.numero'] = this.contrato.numero;
    }

    if (this.filtroRecurso) {
      parametros['recurso'] = this.recurso.codigo;
    }

    if (this.filtroAplicacao) {
      parametros['aplicacao'] = this.aplicacao.codigo;
    }

    if (this.selectUsuario) {
      parametros['usuario_cadastro.id'] = this.login.usuario.id;
    }

    //Não tem no extra orçamentário
    if (this.filtroFuncao) {
      parametros['funcao'] = this.funcaoGoverno.codigo;
    }
    if (this.filtroSubFuncao) {
      parametros['subfuncao'] = this.subFuncaoGoverno.codigo;
    }
    if (this.filtroDespesa) {
      parametros['subelemento'] = this.despesa.codigo;
    }


    if (this.selectedOrdem === 'ord1') {
      parametros['orderBy'] = 'data_empenho,numero';
    } else if (this.selectedOrdem === 'ord2') {
      parametros['orderBy'] = 'numero';
    } else if (this.selectedOrdem === 'ord3') {
      parametros['orderBy'] = 'favorecido.nome';
    } else if (this.selectedOrdem === 'ord4') {
      parametros['orderBy'] = 'executora';
    }

    let titulo = '';
    if (this.relatorio === '1') {
      titulo = 'EMPENHO DE RESTOS A PAGAR ';
      parametros['especie'] = 'EMR';
    } else {
      titulo = 'EMPENHO DE RESTOS A PAGAR EM ABERTO ';
      parametros['especie'] = 'EMR';
    }
    parametros['relations'] = 'favorecido';

    if (this.filtroPeriodoOf) {
      if (!this.dataOfInicial || !this.dataOfFinal) {
        toastr.warning(`Informe o período da OF!`)
        throw new Error(`Informe o período da OF!`);
      };

      parametros['compra.data_compra$ge'] = this.funcaoService.converteDataSQL(this.dataOfInicial);
      parametros['compra.data_compra$le'] = this.funcaoService.converteDataSQL(this.dataOfFinal);
    };

    if (this.filtroTipoEmpenho) {
      if (!this.tipoEmpenhoSelecionado) {
        toastr.warning(`Informe o tipo do empenho!`)
        throw new Error(`Informe o tipo do empenho!`);
      };

      parametros['tipo_empenho'] = this.tipoEmpenhoSelecionado;
    };

    if (this.filtroFavorecido && this.favorecido) {
      parametros['favorecido.id'] = this.favorecido.id;
    }

    if (this.filtroEmpenhoNumero) {
      if (!this.numeroInicio && !this.numeroFinal) {
        toastr.warning(`Informe o numero do empenho!`)
        throw new Error(`Informe o numero do empenho!`)
      };

      if (this.numeroInicio && this.numeroFinal) {
        parametros['numero$ge'] = this.numeroInicio;
        parametros['numero$le'] = this.numeroFinal
      } else if (this.numeroInicio) {
        parametros['numero'] = this.numeroInicio;
      } else if (this.numeroFinal) {
        parametros['numero'] = this.numeroFinal;
      };
    };

    if (this.filtroUnidade) {
      parametros['executora'] = this.unidade.codigo;
    }

    if ((this.selectAgrupamento === 'recurso' || this.selectAgrupamento === 'executora') && this.relatorio === '2') {

      if (parametros?.['orderBy']) {
        parametros['orderBy'] = `${this.selectAgrupamento},` + parametros['orderBy'];
      } else {
        parametros['orderBy'] = this.selectAgrupamento;
      };

    };

    if (this.selectAgrupamento === 'executora' && this.relatorio === '1') {

      if (parametros?.['orderBy']) {
        parametros['orderBy'] = `${this.selectAgrupamento},unidade_executora,` + parametros['orderBy'];
      } else {
        parametros['orderBy'] = `${this.selectAgrupamento},unidade_executora`;
      };

    };

    this.empenhoRestosService.extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((lista) => {

        let conteudo = lista.content;

        if (this.filtroFicha) {
          conteudo = this.filtrarFicha(lista.content);
        };

        const mapa: Record<string, number> = {}; // Um mapa para rastrear o índice mais recente de cada combinação de número e ano
        const resultado: EmpenhoResto[] = [];

        for (let i = 0; i < lista.content?.length; i++) {
          const item = lista.content[i];
          const chave = `${item.numero}_${item.ano}`;
          if (!(chave in mapa) || i > mapa[chave]) {
            mapa[chave] = i;
          }
        }
        for (const indice of Object.values(mapa)) {
          resultado.push(lista.content[indice]);
        }

        lista.content = resultado;

        if (conteudo.length == 0) {
          toastr.info('Sem itens para imprimir');
          return;
        }
        Relatorio.imprimirPersonalizado(titulo, this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          this.selectAgrupamento !== "1" ? this.montarConteudoAgrupado(lista.content, this.relatorio) : this.montarConteudo(lista.content, this.relatorio),
          'landscape', titulo,
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          }, false);
      });
  }

  private montarConteudo(lista: any[], relatorio: string) {
    let retorno: {}[] = [];
    let datepipe = new DatePipe('pt');
    if (this.opcao === '2') {
      retorno.push({
        text: `REFERÊNCIA: ${datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
        alignment: 'center', fontSize: 10, lineHeight: 1.5
      })
    } else {
      retorno.push({
        text: '',
        alignment: 'center', fontSize: 10, lineHeight: 1.5
      })
    }
    const conteudo = [];
    const cabecalho = [
      { text: 'EMPENHO Nº', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
      { text: 'DATA', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'FAVORECIDO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'UNIDADE', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'FUNCIONAL', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'DESPESA', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'RECURSO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
    ];

    if (relatorio === '2') {
      cabecalho.push(
        { text: 'EMPENHADO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
        { text: 'LIQUIDADO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
        { text: 'ABERTO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] }
      );
    } else {
      cabecalho.push({ text: 'VALOR', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] });
    };

    conteudo.push(cabecalho)

    let soma = 0;
    for (const entidade of lista) {
      const registro = [];
      registro.push({
        text: `${this.funcaoService.strZero(entidade.numero, 5)}/${this.funcaoService.strZero(entidade.ano, 4)}`,
        fontSize: 8, alignment: 'center', bold: false
      });
      registro.push({ text: datepipe.transform(entidade.data_empenho, 'dd/MM/yyyy'), fontSize: 8, alignment: 'center' });
      registro.push({ text: entidade.favorecido.nome, fontSize: 8 });
      registro.push({ text: `${entidade.unidade ? entidade.unidade : ''}`, fontSize: 8, alignment: 'center' });
      registro.push({ text: `${entidade.funcao}${entidade.subfuncao}${entidade.programa}.${entidade.acao}`, fontSize: 8, alignment: 'center' });
      registro.push({ text: `${entidade.subelemento.substr(0, 8)}`, fontSize: 8, alignment: 'center' });
      registro.push({ text: `${entidade.recurso} ${entidade.aplicacao}  ${entidade.aplicacao_variavel ? entidade.aplicacao_variavel : ''}`, fontSize: 8, alignment: 'center' });

      if (relatorio === '2') {
        const saldo = ((+entidade.valor_empenho - +entidade.total_cancelado_nao_processado - +entidade.total_cancelado_processado) - +entidade.total_liquidado);
        if (+saldo > 0) {
          registro.push({ text: this.funcaoService.convertToBrNumber(entidade.valor_empenho - +entidade.total_cancelado_nao_processado - +entidade.total_cancelado_processado), fontSize: 8, alignment: 'right', bold: false })
          registro.push({ text: this.funcaoService.convertToBrNumber(entidade?.total_liquidado), fontSize: 8, alignment: 'right', bold: false })
          registro.push({ text: this.funcaoService.convertToBrNumber(+saldo, 2), fontSize: 8, alignment: 'right', bold: false });
          soma += ((+entidade.valor_empenho - +entidade.total_cancelado_nao_processado - +entidade.total_cancelado_processado) - +entidade.total_liquidado);
          conteudo.push(registro);
        }
      } else {
        registro.push({ text: this.funcaoService.convertToBrNumber(entidade.valor_empenho, 2), fontSize: 8, alignment: 'right', bold: false });
        conteudo.push(registro);
        soma += +entidade.valor_empenho;
      }
    }

    const total = [];
    total.push({ text: 'TOTAL', fontSize: 8, alignment: 'left', bold: true, colSpan: relatorio === '2' ? 9 : 7, margin: [0, 5, 0, 5] });
    total.push('');
    total.push('');
    total.push('');
    total.push('');
    total.push('');
    total.push('');

    if (relatorio === '2') {
      total.push('');
      total.push('');
    };

    total.push({ text: this.funcaoService.convertToBrNumber(soma, 2), fontSize: 8, alignment: 'right', bold: true, margin: [0, 5, 0, 5] });
    conteudo.push(total);

    const larguras = (relatorio === '2' ? ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'] : ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 80])
    retorno.push({
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: larguras,
        body: conteudo
      }
    });

    return retorno;
  }

  exportarXlxs(parametros: any) {
    this.empenhoRestosService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          const listaItens = new Array();
          for (const item of lista.content) {
            const entity = {
              codigo: item.codigo,
              cpf: item.cpf,
              nome: item.pessoa.nome,
              endereco: item.pessoa.endereco,
              bairro: item.pessoa.bairro,
              cidade: item.pessoa.municipio,
              telefone: item.pessoa.telefone,
              cep: item.pessoa.cep
            };
            listaItens.push(entity);
          }
          tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile('funcionarios');
        },
        () => alert('erro ao retornar lista')
      );
  }

  private carregarAutoCompletes() {
    // autocomplete para funcao governo
    this.funcaoAutoComplete = new EddyAutoComplete(null, this.funcaoGovernoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para subfuncao governo
    this.subFuncaoAutoComplete = new EddyAutoComplete(null, this.funcaoGovernoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para despesa
    this.despesaAutoComplete = new EddyAutoComplete(null, this.despesaService,
      'id', ['codigo', 'nome'], { nivel: 6, exercicio_id: this.login.exercicio.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para recurso
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para contrato
    this.contratoAutoComplete = new EddyAutoComplete(null, this.contratoService,
      'id', ['numero', 'favorecido.nome'], {
      orgao_id: this.login.orgao.id, relations: 'favorecido',
      orderBy: 'favorecido.nome'
    }, { number: ['numero'], text: ['favorecido.nome'] }
    );

    // autocomplete para favorecido
    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['cpf_cnpj', 'nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );

    this.fichaDespesaAutoComplete = new EddyAutoComplete(null, this.fichaDespesaService,
      'number', ['ficha.numero', 'ficha.despesa.nome'],
      {
        'exercicio.ano': +this.login.exercicio.ano - 1, orgao_id: this.login.orgao.id, 'ignoreCondObrig': true,
        relations: 'despesa,recurso,aplicacao,aplicacao_variavel', orderBy: 'despesa.nome'
      },
      { number: ['numero'], text: ['despesa.codigo', 'despesa.nome'] })

    this.unidadeAutoComplete = new EddyAutoComplete(null, this.executoraService,
      'codigo', ['nome'], { 'unidade.ppa_id': this.login.ppa.id, orderBy: 'nome' }, { number: ['codigo'], text: ['codigo', 'nome'] });
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }

  changeOpcao() {
    setTimeout(() => {
      new GlobalService().calendarMascara();
    }, 100);
  }

  public compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  public filtrarFicha(lista: EmpenhoResto[]): EmpenhoResto[] {
    const listaFiltrada = lista.filter(empenhoR => empenhoR['ficha_empenho'] === this?.ficha?.id);

    return listaFiltrada?.length > 0 ? listaFiltrada : [];
  };

  private montarConteudoAgrupado(lista: any[], relatorio: string) {
    let retorno: {}[] = [];
    let datepipe = new DatePipe('pt');

    const camposAgrupamento = this.selectAgrupamento === 'recurso'
      ? ['recurso', 'recurso_nome']
      : ['executora', 'unidade_executora'];


    const agrupamento = this.funcaoService.agrupar(lista, camposAgrupamento);
    if (this.opcao === '2') {
      retorno.push({
        text: `REFERÊNCIA: ${datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
        alignment: 'center', fontSize: 10, lineHeight: 1.5
      })
    } else {
      retorno.push({
        text: ''
      })
    }

    const conteudo = [];
    const cabecalho = [
      { text: 'EMPENHO Nº', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
      { text: 'DATA', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'FAVORECIDO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'UNIDADE', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'FUNCIONAL', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'DESPESA', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'RECURSO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
    ];

    if (relatorio === '2') {
      cabecalho.push(
        { text: 'EMPENHADO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
        { text: 'LIQUIDADO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
        { text: 'ABERTO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] }
      );
    } else {
      cabecalho.push({ text: 'VALOR', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] });
    };

    conteudo.push(cabecalho)

    let soma = 0;
    for (const grupo of agrupamento) {
      const agrupamento = [];
      const registros = [];

      agrupamento.push({
        text: `${grupo?.grupo[camposAgrupamento[0]] ? grupo?.grupo[camposAgrupamento[0]] : ''} ${grupo?.grupo[camposAgrupamento[1]] ? grupo?.grupo[camposAgrupamento[1]] : ''}`,
        colSpan: relatorio === '2' ? 10 : 8, fontSize: 10, bold: true, margin: [0, 5, 0, 5]
      });

      if (relatorio === '2') {
        agrupamento.push('', '', '', '', '', '', '', '', '');
      } else {
        agrupamento.push('', '', '', '', '', '', '');
      };

      if (relatorio === '1') {
        conteudo.push(agrupamento);
      }

      for (const entidade of grupo.registros) {
        const registro = [];
        registro.push({
          text: `${this.funcaoService.strZero(entidade.numero, 5)}/${this.funcaoService.strZero(entidade.ano, 4)}`,
          fontSize: 8, alignment: 'center', bold: false
        });
        registro.push({ text: datepipe.transform(entidade.data_empenho, 'dd/MM/yyyy'), fontSize: 8, alignment: 'center' });
        registro.push({ text: entidade.favorecido.nome, fontSize: 8 });
        registro.push({ text: `${entidade?.unidade ? entidade?.unidade : ''}`, fontSize: 8, alignment: 'center' });
        registro.push({ text: `${entidade.funcao}${entidade.subfuncao}${entidade.programa}.${entidade.acao}`, fontSize: 8, alignment: 'center' });
        registro.push({ text: `${entidade.subelemento.substr(0, 8)}`, fontSize: 8, alignment: 'center' });
        registro.push({ text: `${entidade.recurso} ${entidade.aplicacao}  ${entidade.aplicacao_variavel ? entidade.aplicacao_variavel : ''}`, fontSize: 8, alignment: 'center' });

        if (relatorio === '2') {
          const saldo = ((+entidade.valor_empenho - +entidade.total_cancelado_nao_processado - +entidade.total_cancelado_processado) - +entidade.total_liquidado);
          if (+saldo > 0) {
            registro.push({ text: this.funcaoService.convertToBrNumber(entidade.valor_empenho - +entidade.total_cancelado_nao_processado - +entidade.total_cancelado_processado), fontSize: 8, alignment: 'right', bold: false })
            registro.push({ text: this.funcaoService.convertToBrNumber(entidade?.total_liquidado), fontSize: 8, alignment: 'right', bold: false })
            registro.push({ text: this.funcaoService.convertToBrNumber(+saldo, 2), fontSize: 8, alignment: 'right', bold: false });
            soma += ((+entidade.valor_empenho - +entidade.total_cancelado_nao_processado - +entidade.total_cancelado_processado) - +entidade.total_liquidado);
            registros.push(registro);
          }
        } else {
          registro.push({ text: this.funcaoService.convertToBrNumber(entidade.valor_empenho, 2), fontSize: 8, alignment: 'right', bold: false });
          conteudo.push(registro);
          soma += +entidade.valor_empenho;
        }
      };
      if (registros?.length > 0) {
        conteudo.push(agrupamento);
        registros.forEach((arrayRegistro) => conteudo.push(arrayRegistro));
      }
    };

    const total = [];
    total.push({ text: 'TOTAL', fontSize: 8, alignment: 'left', bold: true, colSpan: relatorio === '2' ? 9 : 7, margin: [0, 5, 0, 5] });
    total.push('');
    total.push('');
    total.push('');
    total.push('');
    total.push('');
    total.push('');

    if (relatorio === '2') {
      total.push('');
      total.push('');
    };

    total.push({ text: this.funcaoService.convertToBrNumber(soma, 2), fontSize: 8, alignment: 'right', bold: true, margin: [0, 5, 0, 5] });

    const larguras = (relatorio === '2' ? ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'] : ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 80])
    conteudo.push(total);

    retorno.push({
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: larguras,
        body: conteudo
      }
    });

    return retorno;
  }
};
