// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Cidade } from '../geo/cidade.model';
import { PpaLei } from './ppa-lei.model';

export class PpaPeriodo extends BaseResourceModel {
  constructor(
  public id?: number,
  public ano1?: number,
  public ano2?: number,
  public ano3?: number,
  public ano4?: number,
  public cidade?: Cidade,
  public data_cadastro?: Date,
  public data_alteracao?: Date,
  public ppaleis?: PpaLei[]
  ) {
    super();
  }

  static converteJson(json: any): PpaPeriodo {
    return Object.assign(new PpaPeriodo(), json);
  }

}
