import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, PpaLei } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class PpaLeiService extends BaseResourceService<PpaLei> {

  constructor(
    protected injector: Injector
  ) {
    super(`ppa-leis`, injector);
  }

}
