// tslint:disable: variable-name
import { Usuario } from '../comum/usuario.model';
import { CredenciamentoAtividadeArquivo } from './credenciamento-atividade-arquivo.model';
import { CredenciamentoAtividadeHistoricoView } from './credenciamento-atividade-historico-view.model';
import { CredenciamentoAtividade } from './credenciamento-atividade.model';

export class CredenciamentoAtividadeHistorico {

    id: number;
    data_limite: Date;
    comentario: string;
    data_cadastro: Date;
    data_alteracao: Date;
    credenciamentoAtividade: CredenciamentoAtividade;
    usuario: Usuario;
    arquivos: CredenciamentoAtividadeArquivo[];
    views: CredenciamentoAtividadeHistoricoView[];
}
