import { Component, OnInit, AfterViewInit, ViewChild, Input, Output, EventEmitter, ElementRef, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AcaoService } from '../service/acao.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoginContabil, FuncaoService, GlobalService, Acao } from 'eddydata-lib';

@Component({
  selector: 'app-acao-dlg',
  templateUrl: './acao-dlg.component.html'
})
export class AcaoDlgComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('f') formGroup: any;

  public entity: Acao = new Acao();
  protected unsubscribe: Subject<void> = new Subject();

  @Input() login: LoginContabil;
  @Output() atualizarAcao = new EventEmitter();

  @ViewChild('codigoe') public inputCodigo: ElementRef;
  @ViewChild('btnFecharAcao') public btnFecharAcao: ElementRef;

  constructor(
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected acaoService: AcaoService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    new GlobalService().calendarMascara();
    if (this.entity) {
      setTimeout(() => this.inputCodigo.nativeElement.focus(), 3000);
    }
  }

  public salvarAcao(item: any) {
    try {
      if (!item.codigo) {
        throw new Error('Informe o código da Ação!');
      }
      if (!item.nome) {
        throw new Error('Informe o nome da Ação!');
      }
      item.ppa = this.login.ppa;
      this.acaoService.inserir(item).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.atualizarAcao.emit({ event: res });
          this.messageService.add({ severity: 'success', summary: 'Informação', detail: 'Ação inserido com sucesso.' });
        });
      this.btnFecharAcao.nativeElement.click();
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

}
