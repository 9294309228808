import { Component, Input } from '@angular/core';
import { EventoContabilService } from 'contabil-lib';
import { BaseResourceItemsComponent, GlobalService, TipoContratacaoEvento } from 'eddydata-lib';
import * as toastr from 'toastr';
import { TipoContratacaoEventoService } from '../service/tipo-contratacao-evento.service';

@Component({
  selector: 'app-tipo-contratacao-evento',
  templateUrl: './tipo-contratacao-evento.component.html'
})
export class TipoContratacaoEventoComponent extends BaseResourceItemsComponent<TipoContratacaoEvento>{

  public listaEventos: Array<any>;
  public listaFinalidade: Array<any>;
  public listaSituacao: Array<any>;

  @Input() visualizar: boolean;

  constructor(public globalService: GlobalService,
    protected eventoService: EventoContabilService,
    public tipoContratacaoEventoService: TipoContratacaoEventoService) {
    super(new TipoContratacaoEvento(), tipoContratacaoEventoService);
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.listaFinalidade = [
      { id: 1, nome: 'ASSINATURA' },
      { id: 2, nome: 'BAIXA' },
    ];
    this.listaSituacao = [
      { nome: 'AMBOS', id: 'A' },
      { nome: 'SERVIÇO', id: 'S' },
      { nome: 'MATERIAL', id: 'M' },
      { nome: 'LOCAÇÃO', id: 'L' },
      { nome: 'OBRIGAÇÕES CONTRATUAIS', id: '0' },
      { nome: 'RISCOS NÃO PROVISIONADOS', id: '1' },
      { nome: 'GARANTIAS CONCEDIDAS', id: '2' },
      { nome: 'OUTROS PASSIVOS CONTINGENTES', id: '3' }
    ];
    this.listaEventos = this.globalService.obterListaEventos();
  }

  protected validSave(item: TipoContratacaoEvento): boolean {
    if (!item.finalidade) {
      toastr.warning('Informe a finalidade!');
      return false;
    }
    return true;
  }

  protected beforeSave(item: TipoContratacaoEvento): void {

  }

  public obterFinalidadeContratacao(tipo: number) {
    switch (tipo) {
      case 1:
        return 'ASSINATURA'
      case 2:
        return 'BAIXA'    
      default:
        return '-'
    }
  }

  public obterSituacaoContratacao(tipo: string) {
    switch (tipo) {
      case 'L':
        return 'LOCAÇÃO';
      case 'M':
        return 'MATERIAL';
      case 'S':
        return 'SERVIÇO'
      case '0':
        return 'OBRIGAÇÕES CONTRATUAIS';
      case '1':
        return 'RISCOS NÃO PROVISIONADOS';
      case '2':
        return 'GARANTIAS CONCEDIDAS';
      case '3':
        return 'OUTROS PASSIVOS CONTINGENTES';
      default:
        return 'AMBOS';
    }
  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================
  private carregarAutoCompletes() {

  }

  public podeVisualizar() {
    if (this.visualizar === true) {
      return true;
    }
    if(this.visualizar === false){
      return false;
    }
  }
}
