import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { SeguradoraFormComponent } from './seguradora-form/seguradora-form.component';
import { SeguradoraListComponent } from './seguradora-list/seguradora-list.component';
import { SeguradoraViewComponent } from './seguradora-view/seguradora-view.component';

const routes: Routes = [
  { path: '', component: SeguradoraListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: SeguradoraFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: SeguradoraFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: SeguradoraViewComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SeguradoraRoutingModule { }
