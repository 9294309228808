import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaisesService {

  protected http: HttpClient;

  constructor(
    protected injector: Injector
  ) {
    this.http = injector.get(HttpClient);
  }

  listaPaisesReinf(): { codigo: number, nome: string, moeda: string, sigla: string }[] {
    const lista = [];

    lista.push({ codigo: 13, nome: 'Afeganistão', moeda: 'AFN', sigla: 'AFG' });
    lista.push({ codigo: 17, nome: 'Albânia, República da', moeda: 'ALL', sigla: 'ALB' });
    lista.push({ codigo: 23, nome: 'Alemanha', moeda: 'EUR', sigla: 'DEU' });
    lista.push({ codigo: 31, nome: 'Burkina Faso', moeda: 'XOF', sigla: 'BFA' });
    lista.push({ codigo: 37, nome: 'Andorra', moeda: 'EUR', sigla: 'AND' });
    lista.push({ codigo: 40, nome: 'Angola', moeda: 'AOA', sigla: 'AGO' });
    lista.push({ codigo: 43, nome: 'Antígua e Barbuda', moeda: 'XCD', sigla: 'ATG' });
    lista.push({ codigo: 53, nome: 'Arábia Saudita', moeda: 'SAR', sigla: 'SAU' });
    lista.push({ codigo: 59, nome: 'Argélia', moeda: 'DZD', sigla: 'DZA' });
    lista.push({ codigo: 63, nome: 'Argentina', moeda: 'ARS', sigla: 'ARG' });
    lista.push({ codigo: 64, nome: 'Armênia, República da', moeda: 'AMD', sigla: 'ARM' });
    lista.push({ codigo: 69, nome: 'Austrália', moeda: 'AUD', sigla: 'AUS' });
    lista.push({ codigo: 72, nome: 'Áustria', moeda: 'EUR', sigla: 'AUT' });
    lista.push({ codigo: 73, nome: 'Azerbaijão, República do', moeda: 'AZN', sigla: 'AZE' });
    lista.push({ codigo: 77, nome: 'Bahamas, Ilhas', moeda: 'BSD', sigla: 'BHS' });
    lista.push({ codigo: 80, nome: 'Bahrein, Ilhas', moeda: 'BHD', sigla: 'BHR' });
    lista.push({ codigo: 81, nome: 'Bangladesh', moeda: 'BDT', sigla: 'BGD' });
    lista.push({ codigo: 83, nome: 'Barbados', moeda: 'BBD', sigla: 'BRB' });
    lista.push({ codigo: 85, nome: 'Belarus, República da', moeda: 'BYR', sigla: 'blr' });
    lista.push({ codigo: 87, nome: 'Bélgica', moeda: 'EUR', sigla: 'BEL' });
    lista.push({ codigo: 88, nome: 'Belize', moeda: 'BZD', sigla: 'BLZ' });
    lista.push({ codigo: 93, nome: 'Mianmar (Birmânia)', moeda: 'MMK', sigla: 'MMR' });
    lista.push({ codigo: 97, nome: 'Bolívia, Estado Plurinacional da', moeda: 'BOB', sigla: 'BOL' });
    lista.push({ codigo: 98, nome: 'Bósnia-Herzegovina, República da', moeda: 'BAM', sigla: 'BIH' });
    lista.push({ codigo: 101, nome: 'Botsuana', moeda: 'BWP', sigla: 'BWA' });
    lista.push({ codigo: 105, nome: 'Brasil', moeda: 'BRL', sigla: 'BRA' });
    lista.push({ codigo: 108, nome: 'Brunei', moeda: 'BND', sigla: 'BRN' });
    lista.push({ codigo: 111, nome: 'Bulgária, República da', moeda: 'BGN', sigla: 'BGR' });
    lista.push({ codigo: 115, nome: 'Burundi', moeda: 'BIF', sigla: 'BDI' });
    lista.push({ codigo: 119, nome: 'Butão', moeda: 'BTN', sigla: 'BTN' });
    lista.push({ codigo: 127, nome: 'Cabo Verde, República de', moeda: 'CVE', sigla: 'CPV' });
    lista.push({ codigo: 141, nome: 'Camboja', moeda: 'KHR', sigla: 'KHM' });
    lista.push({ codigo: 145, nome: 'Camarões', moeda: 'XAF', sigla: 'CMR' });
    lista.push({ codigo: 149, nome: 'Canadá', moeda: 'CAD	', sigla: 'CAN' });
    lista.push({ codigo: 153, nome: 'Cazaquistão, República do', moeda: 'KZT', sigla: 'KAZ' });
    lista.push({ codigo: 154, nome: 'Catar', moeda: 'QAR', sigla: 'QAT' });
    lista.push({ codigo: 158, nome: 'Chile', moeda: 'CLP', sigla: 'CHL' });
    lista.push({ codigo: 160, nome: 'China, República Popular da', moeda: 'CNY', sigla: 'CHN' });
    lista.push({ codigo: 163, nome: 'Chipre', moeda: 'EUR', sigla: 'CYP' });
    lista.push({ codigo: 169, nome: 'Colômbia', moeda: 'COP', sigla: 'COL' });
    lista.push({ codigo: 173, nome: 'Comores, Ilhas', moeda: 'KMF', sigla: 'COM' });
    lista.push({ codigo: 177, nome: 'Congo', moeda: 'XAF', sigla: 'COG' });
    lista.push({ codigo: 183, nome: 'Cook, Ilhas', moeda: 'NZD', sigla: 'COK' });
    lista.push({ codigo: 187, nome: 'Coreia (do Norte), República Popular Democrática da', moeda: 'KPW', sigla: 'PRK' });
    lista.push({ codigo: 190, nome: 'Coreia (do Sul), República da', moeda: 'KRW', sigla: 'KOR' });
    lista.push({ codigo: 193, nome: 'Costa do Marfim', moeda: 'XOF', sigla: 'CIV' });
    lista.push({ codigo: 195, nome: 'Croácia, República da', moeda: 'HRK', sigla: 'HRV' });
    lista.push({ codigo: 196, nome: 'Costa Rica', moeda: 'CRC', sigla: 'CRI' });
    lista.push({ codigo: 198, nome: 'Kuwait', moeda: 'KWD', sigla: 'KWT' });
    lista.push({ codigo: 199, nome: 'Cuba', moeda: 'CUC', sigla: 'CUB' });
    lista.push({ codigo: 229, nome: 'Benim', moeda: 'XOF', sigla: 'BEN' });
    lista.push({ codigo: 232, nome: 'Dinamarca', moeda: 'DKK', sigla: 'DNK' });
    lista.push({ codigo: 235, nome: 'Dominica, Ilha', moeda: 'XCD', sigla: 'DMA' });
    lista.push({ codigo: 239, nome: 'Equador', moeda: 'USD', sigla: 'ECU' });
    lista.push({ codigo: 240, nome: 'Egito', moeda: 'EGP', sigla: 'EGY' });
    lista.push({ codigo: 243, nome: 'Eritreia', moeda: 'ERN', sigla: 'ERI' });
    lista.push({ codigo: 244, nome: 'Emirados Árabes Unidos', moeda: 'AED', sigla: 'ARE' });
    lista.push({ codigo: 245, nome: 'Espanha', moeda: 'EUR', sigla: 'ESP' });
    lista.push({ codigo: 246, nome: 'Eslovênia, República da', moeda: 'EUR', sigla: 'SVN' });
    lista.push({ codigo: 247, nome: 'Eslovaca, República', moeda: 'EUR', sigla: 'SVK' });
    lista.push({ codigo: 249, nome: 'Estados Unidos', moeda: 'USD', sigla: 'USA' });
    lista.push({ codigo: 251, nome: 'Estônia, República da', moeda: 'EUR', sigla: 'EST' });
    lista.push({ codigo: 253, nome: 'Etiópia', moeda: 'ETB', sigla: 'ETH' });
    lista.push({ codigo: 267, nome: 'Filipinas', moeda: 'PHP', sigla: 'PHL' });
    lista.push({ codigo: 271, nome: 'Finlândia', moeda: 'EUR', sigla: 'FIN' });
    lista.push({ codigo: 275, nome: 'França', moeda: 'EUR', sigla: 'FRA' });
    lista.push({ codigo: 281, nome: 'Gabão', moeda: 'XAF', sigla: 'GAB' });
    lista.push({ codigo: 285, nome: 'Gâmbia', moeda: 'GMD', sigla: 'GMB' });
    lista.push({ codigo: 289, nome: 'Gana', moeda: 'GHS', sigla: 'GHA' });
    lista.push({ codigo: 291, nome: 'Geórgia, República da', moeda: 'GEL', sigla: 'GEO' });
    lista.push({ codigo: 297, nome: 'Granada', moeda: '	XCD', sigla: 'GRD' });
    lista.push({ codigo: 301, nome: 'Grécia', moeda: 'EUR', sigla: 'GRC' });
    lista.push({ codigo: 317, nome: 'Guatemala', moeda: 'GTQ', sigla: 'GTM' });
    lista.push({ codigo: 329, nome: 'Guiné', moeda: 'GNF', sigla: 'GIN' });
    lista.push({ codigo: 331, nome: 'Guiné Equatorial', moeda: 'XAF', sigla: 'GNQ' });
    lista.push({ codigo: 334, nome: 'Guiné-Bissau', moeda: 'XOF', sigla: 'GNB' });
    lista.push({ codigo: 337, nome: 'Guiana', moeda: 'GYD', sigla: 'GUY' });
    lista.push({ codigo: 341, nome: 'Haiti', moeda: 'HTG', sigla: 'HTI' });
    lista.push({ codigo: 345, nome: 'Honduras', moeda: 'HNL', sigla: 'HND' });
    lista.push({ codigo: 355, nome: 'Hungria, República da', moeda: 'HUF', sigla: 'HUN' });
    lista.push({ codigo: 357, nome: 'Iêmen', moeda: 'YER', sigla: 'YEM' });
    lista.push({ codigo: 361, nome: 'Índia', moeda: 'INR', sigla: 'IND' });
    lista.push({ codigo: 365, nome: 'Indonésia', moeda: 'IDR', sigla: 'IDN' });
    lista.push({ codigo: 369, nome: 'Iraque', moeda: 'IQD', sigla: 'IRQ' });
    lista.push({ codigo: 372, nome: 'Irã, República Islâmica do', moeda: 'IRR', sigla: 'IRN' });
    lista.push({ codigo: 375, nome: 'Irlanda', moeda: 'EUR', sigla: 'IRL' });
    lista.push({ codigo: 379, nome: 'Islândia', moeda: 'ISK', sigla: 'ISL' });
    lista.push({ codigo: 383, nome: 'Israel', moeda: 'ILS', sigla: 'ISR' });
    lista.push({ codigo: 386, nome: 'Itália', moeda: 'EUR', sigla: 'ITA' });
    lista.push({ codigo: 391, nome: 'Jamaica', moeda: 'JMD', sigla: 'JAM' });
    lista.push({ codigo: 399, nome: 'Japão', moeda: 'JPY', sigla: 'JPN' });
    lista.push({ codigo: 403, nome: 'Jordânia', moeda: 'JOD', sigla: 'JOR' });
    lista.push({ codigo: 411, nome: 'Kiribati', moeda: 'AUD', sigla: 'KIR' });
    lista.push({ codigo: 420, nome: 'Laos, República Popular Democrática do', moeda: 'LAK', sigla: 'LAO' });
    lista.push({ codigo: 426, nome: 'Lesoto', moeda: 'LSL', sigla: 'LSO' });
    lista.push({ codigo: 427, nome: 'Letônia, República da', moeda: 'EUR', sigla: 'LVA' });
    lista.push({ codigo: 431, nome: 'Líbano', moeda: 'LBP', sigla: 'LBN' });
    lista.push({ codigo: 434, nome: 'Libéria', moeda: 'LRD', sigla: 'LBR' });
    lista.push({ codigo: 438, nome: 'Líbia', moeda: 'LYD', sigla: 'LBY' });
    lista.push({ codigo: 440, nome: 'Liechtenstein', moeda: 'CHF', sigla: 'LIE' });
    lista.push({ codigo: 442, nome: 'Lituânia, República da', moeda: 'EUR', sigla: 'LTU' });
    lista.push({ codigo: 445, nome: 'Luxemburgo', moeda: 'EUR', sigla: 'LUX' });
    lista.push({ codigo: 449, nome: 'Macedônia do Norte', moeda: 'MKD', sigla: 'MKD' });
    lista.push({ codigo: 450, nome: 'Madagascar', moeda: 'MGA', sigla: 'MDG' });
    lista.push({ codigo: 455, nome: 'Malásia', moeda: 'MYR', sigla: 'MYS' });
    lista.push({ codigo: 458, nome: 'Malavi', moeda: 'MWK', sigla: 'MWI' });
    lista.push({ codigo: 461, nome: 'Maldivas', moeda: 'MVR', sigla: 'MDV' });
    lista.push({ codigo: 464, nome: 'Mali', moeda: 'XOF', sigla: 'MLI' });
    lista.push({ codigo: 467, nome: 'Malta', moeda: 'EUR', sigla: 'MLT' });
    lista.push({ codigo: 474, nome: 'Marrocos', moeda: 'MAD', sigla: 'Marrocos' });
    lista.push({ codigo: 476, nome: 'Marshall, Ilhas', moeda: 'USD', sigla: 'MHL' });
    lista.push({ codigo: 485, nome: 'Maurício', moeda: 'MUR', sigla: 'MUS' });
    lista.push({ codigo: 488, nome: 'Mauritânia', moeda: 'MRO', sigla: 'MRT' });
    lista.push({ codigo: 493, nome: 'México', moeda: 'MXN', sigla: 'MEX' });
    lista.push({ codigo: 494, nome: 'Moldávia, República da', moeda: 'MDL', sigla: 'MDA' });
    lista.push({ codigo: 495, nome: 'Mônaco', moeda: 'EUR', sigla: 'MCO' });
    lista.push({ codigo: 497, nome: 'Mongólia', moeda: 'MNT', sigla: 'MNG' });
    lista.push({ codigo: 498, nome: 'Montenegro', moeda: 'EUR', sigla: 'MNE' });
    lista.push({ codigo: 499, nome: 'Micronésia', moeda: 'USD', sigla: 'FSM' });
    lista.push({ codigo: 505, nome: 'Moçambique', moeda: '	MZN', sigla: 'MOZ' });
    lista.push({ codigo: 507, nome: 'Namíbia', moeda: 'NAD', sigla: 'NAM' });
    lista.push({ codigo: 508, nome: 'Nauru', moeda: 'AUD', sigla: 'NRU' });
    lista.push({ codigo: 517, nome: 'Nepal', moeda: 'NPR', sigla: 'NPL' });
    lista.push({ codigo: 521, nome: 'Nicarágua', moeda: 'NIO', sigla: 'NIC' });
    lista.push({ codigo: 525, nome: 'Níger', moeda: 'XOF', sigla: 'NER' });
    lista.push({ codigo: 528, nome: 'Nigéria', moeda: 'NGN', sigla: 'NGA' });
    lista.push({ codigo: 538, nome: 'Noruega', moeda: 'NOK', sigla: 'NOR' });
    lista.push({ codigo: 545, nome: 'Papua Nova Guiné', moeda: 'PGK', sigla: 'PNG' });
    lista.push({ codigo: 548, nome: 'Nova Zelândia', moeda: 'NZD', sigla: 'NZL' });
    lista.push({ codigo: 551, nome: 'Vanuatu', moeda: 'VUV', sigla: 'VUT' });
    lista.push({ codigo: 556, nome: 'Omã', moeda: 'OMR', sigla: 'OMN' });
    lista.push({ codigo: 573, nome: 'Países Baixos (Holanda)', moeda: 'EUR', sigla: 'NLD' });
    lista.push({ codigo: 575, nome: 'Palau', moeda: 'USD', sigla: 'PLW' });
    lista.push({ codigo: 576, nome: 'Paquistão', moeda: 'PKR', sigla: 'PAK' });
    lista.push({ codigo: 578, nome: 'Palestina', moeda: '	ILS', sigla: 'PSE' });
    lista.push({ codigo: 580, nome: 'Panamá', moeda: 'PAB', sigla: 'PAN' });
    lista.push({ codigo: 586, nome: 'Paraguai', moeda: 'PYG', sigla: 'PRY' });
    lista.push({ codigo: 589, nome: 'Peru', moeda: 'PEN', sigla: 'PER' });
    lista.push({ codigo: 603, nome: 'Polônia, República da', moeda: 'PLN', sigla: 'POL' });
    lista.push({ codigo: 607, nome: 'Portugal', moeda: 'EUR', sigla: 'PRT' });
    lista.push({ codigo: 623, nome: 'Quênia', moeda: 'KES', sigla: 'KEN' });
    lista.push({ codigo: 625, nome: 'Quirguiz, República', moeda: 'KGS', sigla: 'KGZ' });
    lista.push({ codigo: 628, nome: 'Reino Unido', moeda: 'GBP', sigla: 'GBR' });
    lista.push({ codigo: 640, nome: 'República Centro-Africana', moeda: 'CFA', sigla: 'CAF' });
    lista.push({ codigo: 647, nome: 'República Dominicana', moeda: 'DOP', sigla: 'DOM' });
    lista.push({ codigo: 665, nome: 'Zimbábue', moeda: 'ZAR', sigla: 'ZWE' });
    lista.push({ codigo: 670, nome: 'Romênia', moeda: 'RON', sigla: 'ROU' });
    lista.push({ codigo: 675, nome: 'Ruanda', moeda: 'RWF', sigla: 'RWA' });
    lista.push({ codigo: 676, nome: 'Rússia, Federação da', moeda: 'RUB', sigla: 'RUS' });
    lista.push({ codigo: 677, nome: 'Salomão, Ilhas', moeda: 'SBD', sigla: 'SLB' });
    lista.push({ codigo: 687, nome: 'El Salvador', moeda: 'USD', sigla: 'SLV' });
    lista.push({ codigo: 690, nome: 'Samoa', moeda: 'WST', sigla: 'WSM' });
    lista.push({ codigo: 695, nome: 'São Cristóvão e Neves, Ilhas', moeda: 'XCD', sigla: 'KNA' });
    lista.push({ codigo: 697, nome: 'San Marino', moeda: 'EUR', sigla: 'SMR' });
    lista.push({ codigo: 705, nome: 'São Vicente e Granadinas', moeda: 'XCD', sigla: 'VCT' });
    lista.push({ codigo: 715, nome: 'Santa Lúcia', moeda: 'XCD', sigla: 'LCA' });
    lista.push({ codigo: 720, nome: 'São Tomé e Príncipe, Ilhas', moeda: 'STD', sigla: 'STP' });
    lista.push({ codigo: 728, nome: 'Senegal', moeda: 'XOF', sigla: 'SEN' });
    lista.push({ codigo: 731, nome: 'Seychelles', moeda: 'SCR', sigla: 'SYC' });
    lista.push({ codigo: 735, nome: 'Serra Leoa', moeda: 'SLL', sigla: 'SLE' });
    lista.push({ codigo: 737, nome: 'Sérvia', moeda: 'RSD', sigla: 'SRB' });
    lista.push({ codigo: 741, nome: 'Singapura', moeda: 'BND', sigla: 'SGP' });
    lista.push({ codigo: 744, nome: 'Síria, República Árabe da', moeda: 'SYP', sigla: 'SYR' });
    lista.push({ codigo: 748, nome: 'Somália', moeda: 'SOS', sigla: 'SOM' });
    lista.push({ codigo: 750, nome: 'Sri Lanka', moeda: 'LKR', sigla: 'LKA' });
    lista.push({ codigo: 754, nome: 'eSwatini (Essuatíni, Suazilândia)', moeda: 'SZL', sigla: 'SWZ' });
    lista.push({ codigo: 756, nome: 'África do Sul', moeda: 'ZAR', sigla: 'ZAF' });
    lista.push({ codigo: 759, nome: 'Sudão', moeda: 'SDG', sigla: 'SDN' });
    lista.push({ codigo: 760, nome: 'Sudão do Sul', moeda: 'SSP', sigla: 'SSD' });
    lista.push({ codigo: 764, nome: 'Suécia', moeda: 'SEK', sigla: 'SWE' });
    lista.push({ codigo: 767, nome: 'Suíça', moeda: 'CHF', sigla: 'CHE' });
    lista.push({ codigo: 770, nome: 'Suriname', moeda: 'SRD', sigla: 'SUR' });
    lista.push({ codigo: 772, nome: 'Tadjiquistão, República do', moeda: 'TJS', sigla: 'TJK' });
    lista.push({ codigo: 776, nome: 'Tailândia', moeda: 'THB', sigla: 'THA' });
    lista.push({ codigo: 780, nome: 'Tanzânia, República Unida da', moeda: 'TZS', sigla: 'TZA' });
    lista.push({ codigo: 783, nome: 'Djibuti', moeda: 'DJF', sigla: 'DJI' });
    lista.push({ codigo: 788, nome: 'Chade', moeda: 'XAF', sigla: 'TCD' });
    lista.push({ codigo: 791, nome: 'Tcheca, República', moeda: 'CZK', sigla: 'CZE' });
    lista.push({ codigo: 795, nome: 'Timor Leste', moeda: 'USD', sigla: 'TLS' });
    lista.push({ codigo: 800, nome: 'Togo', moeda: 'XOF', sigla: 'TGO' });
    lista.push({ codigo: 810, nome: 'Tonga', moeda: 'TOP', sigla: 'TON' });
    lista.push({ codigo: 815, nome: 'Trinidad e Tobago', moeda: 'TTD', sigla: 'TTO' });
    lista.push({ codigo: 820, nome: 'Tunísia', moeda: 'TND', sigla: 'TUN' });
    lista.push({ codigo: 824, nome: 'Turcomenistão, República do', moeda: '	TMT', sigla: 'TKM' });
    lista.push({ codigo: 827, nome: 'Turquia', moeda: 'TRY', sigla: 'TUR' });
    lista.push({ codigo: 828, nome: 'Tuvalu', moeda: 'AUD', sigla: 'TUV' });
    lista.push({ codigo: 831, nome: 'Ucrânia', moeda: 'UAH', sigla: 'UKR' });
    lista.push({ codigo: 833, nome: 'Uganda', moeda: 'UGX', sigla: 'UGA' });
    lista.push({ codigo: 845, nome: 'Uruguai', moeda: 'UYU', sigla: 'URY' });
    lista.push({ codigo: 847, nome: 'Uzbequistão, República do', moeda: 'UZS', sigla: 'UZB' });
    lista.push({ codigo: 848, nome: 'Vaticano, Estado da Cidade do', moeda: 'EUR', sigla: 'VAT' });
    lista.push({ codigo: 850, nome: 'Venezuela', moeda: 'VES', sigla: 'VEN' });
    lista.push({ codigo: 858, nome: 'Vietnã', moeda: 'VND', sigla: 'VNM' });
    lista.push({ codigo: 870, nome: 'Fiji', moeda: 'FJD', sigla: 'FJI' });
    lista.push({ codigo: 888, nome: 'Congo, República Democrática do', moeda: 'CDF', sigla: 'COG' });
    lista.push({ codigo: 890, nome: 'Zâmbia', moeda: 'ZMK', sigla: 'ZMB' });

    return lista;
  }

}

