import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { FichaReceitaViewComponent } from './ficha-receita-view/ficha-receita-view.component';

const routes: Routes = [
  { 
    path: ':id/visualizar/:orgao',
    children: [
      { path: '', component: FichaReceitaViewComponent, canActivate: [AuthGuard] },
      { path: ':data', component: FichaReceitaViewComponent, canActivate: [AuthGuard] }
    ],
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FichaReceitaRoutingModule { }
