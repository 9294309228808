import { Component, Injector } from '@angular/core';
import { SubGrupoEstoqueService } from 'almoxarifado-lib';
import { BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, GlobalService, LoginContabil, SubGrupoEstoque } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-subgrupo-estoque-list',
  templateUrl: './subgrupo-estoque-list.component.html'
})
export class SubGrupoEstoqueListComponent extends BaseResourceListComponent<SubGrupoEstoque, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public mes: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private subGrupoService: SubGrupoEstoqueService,
    protected globalService: GlobalService) {
    super(subGrupoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(item: SubGrupoEstoque): boolean {
    return true;
  }

  protected relations(): string {
    return 'grupo';
  }

  protected condicoesGrid(): {} {
    return {
      'orgao_id': this.login.orgao.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['codigo$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['codigo', 'codigo_comp'],
      text: ['nome', 'grupo.nome', 'codigo_comp'],
    };
  }

  protected afterInit(): void {
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  protected acaoRemover(model: SubGrupoEstoque): Observable<SubGrupoEstoque> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'codigo' },
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'Grupo', coluna: 'grupo.nome' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'grupo';
    this.subGrupoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE SUB-GRUPOS DE ESTOQUE`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem grupo estoque', ['auto', '*', '*'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

}
