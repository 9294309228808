import { Component, Input, OnInit } from '@angular/core';
import { Ajuste, Audesp4TermoCiencia, EddyAutoComplete, Pessoa, PessoaService } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-termo-ciencia',
  templateUrl: './licitacao-audesp-termo-ciencia.component.html'
})
export class LicitacaoAudespTermoCienciaComponent extends BaseLicitacaoAudesp implements OnInit {

  @Input() public entidade: Ajuste;
  @Input() public campo: string;
  @Input() public titulo: string;

  public itemAtual: Audesp4TermoCiencia;
  public itemAnterior: Audesp4TermoCiencia;

  public opcoesVeiculo: { id: number, nome: string }[];

  constructor(
    private pessoaService: PessoaService
  ) {
    super()
  }

  ngOnInit(): void {
    this.pessoaAutoComplete = new EddyAutoComplete(null, this.pessoaService,
      'id', ['cpf_cnpj', 'nome'], { orderBy: 'nome' }, { number: ['cpf_cnpj'], text: ['nome'] }
    );
  }

  public adicionar() {
    if (!this.entidade[this.campo] || this.entidade[this.campo].length === 0) this.entidade[this.campo] = [];

    this.itemAtual = {
      CPF: undefined, Nome: undefined, EmailPessoal: undefined, EmailProfissional: undefined, AssinouContrato: 'S', editavel: true
    }
    this.itemAtual['pessoa'] = new Pessoa();
    this.entidade[this.campo].push(this.itemAtual);
  }

  public visualizar(item: Audesp4TermoCiencia) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
    if (this.itemAtual.pessoaId)
      this.pessoaService.obterId(this.itemAtual.pessoaId).subscribe((res) => {
        this.itemAtual['pessoa'] = res;
      })
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      delete this.itemAtual['pessoa'];
      let itm = this.entidade[this.campo].find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.entidade[this.campo].splice(this.entidade[this.campo].indexOf(this.itemAtual), 1, itm)
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4TermoCiencia) {
    this.itemAtual = undefined;
    this.entidade[this.campo].splice(this.entidade[this.campo].indexOf(item), 1)
  }

  public salvar() {
    delete this.itemAtual['pessoa'];
    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }

  public cadastroPessoa() {
    $('#dialogPessoa').modal('show');
  }

  public callbackPessoa(pessoa: Pessoa) {
    if (pessoa)
      this.itemAtual['pessoa'] = pessoa;
    else
      pessoa = this.itemAtual['pessoa'];

    this.itemAtual.pessoaId = pessoa.id;
    this.itemAtual.CPF = pessoa.cpf_cnpj;
    this.itemAtual.Nome = pessoa.nome;
    this.itemAtual.EmailPessoal = pessoa.email;
    this.itemAtual.EmailProfissional = pessoa.email_profissional;
  }
}
