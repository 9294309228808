
// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { MovimentoEstoque } from '../almoxarifado/movimento-estoque.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { LicitacaoAudesp } from '../licitacao/licitacao-audesp.model';
import { Pagamento } from '../tesouraria/pagamento.model';
import { DocumentoFiscalInfo } from './documento-fiscal-info.model';
import { Empenho } from './empenho.model';
import { LiquidacaoFaturaTipo } from './liquidacao-fatura-tipo.model';
import { PreLiquidacao } from './pre-liquidacao.model';
import { Retencao } from './retencao.model';

export class Liquidacao extends BaseResourceModel {
    constructor(
        public id?: number,
        public data_liquidacao?: Date,
        public mes?: number,
        public data_vencimento?: Date,
        public parcela?: number,
        public data_referencia?: Date,
        public documento?: string,
        public historico?: string,
        public aux?: number,
        public valor_liquidado?: number,
        public valor_base_ir?: number,
        public valor_base_csll?: number,
        public valor_base_cofins?: number,
        public valor_base_pis?: number,
        public patrimonio?: number,
        public impresso?: boolean,
        public anulacao?: boolean,
        public recolhimento_encargo?: boolean,
        public inscrito_resto?: boolean,
        public empenho?: Empenho,
        public exercicio?: Exercicio,
        public orgao?: Orgao,
        public preliquidacao?: PreLiquidacao,
        public usuario_cadastro?: Usuario,
        public licitacao_audesp?: LicitacaoAudesp,
        public licitacao_audesp_execucao?: LicitacaoAudesp,
        public pagamentos?: Pagamento[],
        public retencoes?: Retencao[],
        public movimentos_estoque?: MovimentoEstoque[],
        public anulado_total?: boolean,
        public liquidacao_anulada?: Liquidacao,
        public documento_fiscal_info?: DocumentoFiscalInfo,
        public anulacoes?: Liquidacao[],
        public faturas_liquidacao?: LiquidacaoFaturaTipo[]
    ) {
        super();
    }

    static converteJson(json: any): Liquidacao {
        return Object.assign(new Liquidacao(), json);
    }
}
