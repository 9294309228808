import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from './orgao.model';

export class ParametroEmail extends BaseResourceModel {
  constructor(
    public id?: number,
    public port?: number,
    public host?: string,
    public proxy?: string,
    public user?: string,
    public email?: string,
    public password?: string,
    public secure?: boolean,
    public ignoreTLS?: boolean,
    public requireTLS?: boolean,
    public connectionTimeout?: number,
    public gretingTimeout?: number,
    public socketTimeout?: number,
    public disableFileAccess?: boolean,
    public disableUrlAccess?: boolean,
    public disabled?: boolean,
    public logger?: boolean,
    public debug?: boolean,
    public orgao?: Orgao,
  ) {
    super();
  }

  static converteJson(json: any): ParametroEmail {
    return Object.assign(new ParametroEmail(), json);
  }

}
