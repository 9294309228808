// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Convenio } from '../compra/convenio.model';
import { CredenciamentoAtividadeHistorico } from './credenciamento-atividade-historico.model';

export class CredenciamentoAtividade extends BaseResourceModel {
    constructor(
        public id?: number,
        public mes?: string,
        public ano?: Date,
        public observacao?: string,
        public aprovado_comissao?: boolean,
        public aprovado_gestor?: boolean,
        public aprovado_ci?: boolean,
        public convenio?: Convenio,
        public credenciamentoAtividadeHistorico?: CredenciamentoAtividadeHistorico,
        public credenciamentoAtividadehistoricos?: Array<CredenciamentoAtividadeHistorico>
    ) {
        super();
    }
    static converteJson(json: any): CredenciamentoAtividade {
        return Object.assign(new CredenciamentoAtividade(), json);
    }
}
