import { Injectable, Injector } from '@angular/core';
import { Subject, Observable, throwError } from 'rxjs';
import { ProgressoState } from '../progresso';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Login } from '../../../entidade/login/login';
import jwt_decode from 'jwt-decode';
import * as bcrypt from 'bcryptjs';
import { GlobalService } from '../../../util/global.service';

@Injectable({
  providedIn: 'root'
})
export class ProgressoService {
  private progressoSubject = new Subject<ProgressoState>();
  progressoState = this.progressoSubject.asObservable();
  protected http: HttpClient;
  login: Login = new Login();

  constructor(protected injector: Injector) {
    this.http = injector.get(HttpClient);
    this.login = GlobalService.obterSessaoLogin();
  }
  show(id: number, funcao?: Function) {
    this.progressoSubject.next({ show: true, id, funcao } as ProgressoState);
  }
  hide() {
    this.progressoSubject.next({ show: false } as ProgressoState);
  }

  progresso(id: number, httpOptions?: {}): Observable<{}> {
    return this.http.get<{}>(this.obterUrl(id), (httpOptions ? httpOptions : this.httpOptions()))/*.pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    )*/;
  }

  abortar(id: number, httpOptions?: {}): Observable<{}> {
    return this.http.put<{}>(`${this.login.cidade.id}/progresso/abortar/${id}`, (httpOptions ? httpOptions : this.httpOptions())).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  protected handleError(error: any): Observable<any> {
    return throwError(error);
  }

  protected adicionarCriptografia(header: {}) {
    if (this.login && this.login.token) {
      const tokenJson = jwt_decode(this.login.token) as any;
      const agora = new Date().getTime();
      const comparar = ((agora - +tokenJson.iat) / +tokenJson.id).toFixed(0);
      const criptografado = bcrypt.hashSync(comparar, tokenJson.chave);
      header['data'] = agora;
      header['codigo'] = criptografado;
    }

    return header;
  }

  protected httpOptions() {
    if (!this.login) {
      this.login = GlobalService.obterSessaoLogin();
    }
    if (this.login) {
      return {
        headers: new HttpHeaders(this.adicionarCriptografia({
          'Content-Type': 'application/json',
          Authorization: this.login.token
        })),
        reportProgress: true
      };
    } else {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        reportProgress: true
      };
    }
  }

  private obterUrl(id: number) {
    return `${this.login.cidade.id}/progresso/${id}`;
  }
}
