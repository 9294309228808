import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ContratoService } from 'administrativo-lib';
import { BaseResourceListComponent, Coluna, Contrato, ContratoItem, DlgComponent, Filtro, FuncaoService, GlobalService, LoginContabil } from 'eddydata-lib';
import { MenuItem } from 'primeng/api';
import { Panel } from 'primeng/panel';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContratoItemService } from '../../contrato/service/contrato-item.service';

@Component({
  selector: 'app-contrato-list-dlg',
  templateUrl: './contrato-list-dlg.component.html'
})
export class ContratoListDlgComponent extends BaseResourceListComponent<Contrato, LoginContabil> implements OnChanges {

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public contrato: Contrato;
  public carregando: boolean = false;

  public ptBR: any;
  protected datepipe: DatePipe;

  public listaFiltros: Coluna[] = [];
  public menuFiltros: MenuItem[] = [];

  @ViewChild('toptabela') public topTabela: ElementRef;


  @ViewChild('panel') painel: Panel;


  /**
   * Declaração de variáveis
   */

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public globalService: GlobalService,
    public funcaoService: FuncaoService,
    private contratoService: ContratoService,
    private contratoItemService: ContratoItemService) {
    super(contratoService, injector);
    this.ptBR = new GlobalService().obterDataBR();
    this.datepipe = new DatePipe('pt');
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visualizar && this.visualizar) {
      this.show();
    }
  }

  public ngOnInit(): void { }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'orgao,favorecido,tipo_contratacao,modalidade,licitacao';
  }

  protected condicoesGrid(): {} {
    const parametros = {
      ['orgao.id']: this.login.orgao.id,
    };
    let filtros = this.obterFiltrosLista();
    return filtros ? Object.assign(parametros, filtros) : parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['numero'];
  }

  protected filtrosGrid(): Filtro {
    return {};
  }

  protected afterInit(): void {
  }

  public beforeInit(): void {
    this.usarExtendido = true;
  }

  protected acaoRemover(model: Contrato): Observable<Contrato> {
    return null;
  }

  public saldoRequisitar(total) {
    return +total.valor_contrato + +total.total_reajuste + +total.total_aditivo - +total.total_rcms_pendente - +total.total_compra_pendente - +total.total_empenho;
  }

  public saldoAtual(total) {
    return +total.valor_contrato + +total.total_reajuste + +total.total_aditivo;
  }

  public observacaoQuebraTexto(objeto: String) {
    let texto = objeto;
    let dist = 70;
    let divisao = texto.length / dist;
    let resultado = new Array(Math.round(divisao));
    for (var x = 0; x < texto.length / dist; x++) {
      resultado[x] = texto.substring(0 + x * dist, (x + 1) * dist);
    }
    let retorno = "";
    for (let parte of resultado) {
      retorno += parte + "\n";
    }
    return retorno;
  }

  private layoutItens() {
    return {
      hLineWidth() {
        return 1;
      },
      vLineWidth(i, node) {
        return 1;
      },
      hLineColor(i) {
        return i === 1 || i === 0 ? 'black' : '0aaa';
      },
      paddingLeft(i) {
        return 2;
      },
      paddingRight(i, node) {
        return 2;
      }
    }
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Contrato', coluna: 'numero', mask: '0000/0000', notUnmask: true },
      { titulo: 'Licitação', coluna: 'licitacao.numero', mask: '0000/0000', notUnmask: true },
      { titulo: 'Processo', coluna: 'processo' },
      { titulo: 'Tipo', coluna: 'tipo' },
      { titulo: 'Objeto', coluna: 'objeto' },
      { titulo: 'Data término', coluna: 'data_termino' },
      { titulo: 'Fornecedor', coluna: 'favorecido.nome' },
      { titulo: 'Saldo inicial', coluna: 'valor_contrato', decimais: 2 },
      { titulo: 'Saldo atual', coluna: 'atual', funcao: [{ valor: ['valor_contrato', '+', 'total_reajuste', '+', 'total_aditivo'] }] },
      { titulo: 'Saldo a requisitar', coluna: 'requisitar', funcao: [{ valor: ['valor_contrato', '+', 'total_reajuste', '+', 'total_aditivo', '-', 'total_rcms_pendente', '-', 'total_compra_pendente', '-', 'total_empenho'] }] },
      {
        titulo: null,
        coluna: 'itens',
        bold: true,
        fontSize: 14,
        larguras: ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'],
        colunas: [
          { titulo: 'Código', coluna: 'produto_unidade.produto.codigo', alignment: 'center' },
          { titulo: 'Nome', coluna: 'produto_unidade.produto.nome', alignment: 'center' },
          { titulo: 'Descrição', coluna: 'produto_unidade.produto.descricao', alignment: 'center' },
          { titulo: 'Unidade', coluna: 'produto_unidade.unidade.nome', alignment: 'center' },
          { titulo: 'Quantidade', coluna: 'quantidade', alignment: 'center' },
          { titulo: 'Saldo', coluna: 'saldo_qtd', alignment: 'center' },
          { titulo: 'Vl. Unitário', coluna: 'valor_unitario', alignment: 'center', decimais: 2 }
        ],
        layout: this.layoutItens()
      }
    ];
  }

  public retornaCota(itemAtual: ContratoItem, lista: ContratoItem[]) {
    let cota: 'P' | 'R'
    if (itemAtual.memorial) {
      cota = itemAtual.memorial.cota === 'PRINCIPAL' ? 'P' : 'R'
    } else {
      let itemIgual = lista.find(it => it.produto_unidade.id === itemAtual.produto_unidade.id && it.id !== itemAtual.id)
      cota = !itemIgual ? 'P' : +itemAtual.quantidade > +itemIgual.quantidade ? 'P' : 'R'
    }
    return cota
  }

  public imprimirRelatorio(contrato: Contrato) {
    let impressao = []
    let p = new Promise<void>((resolve, reject) => {
      if (!contrato['carregado']) {
        this.contratoItemService.filtrar(1, -1, {
          'contrato.id': contrato.id,
          relations: 'produto_unidade.produto,produto_unidade.unidade',
          orderBy: 'produto_unidade.produto.nome$ASC'
        }).subscribe(async (data) => {
          if (data && data.content && data.content.length) {
            contrato.itens = data.content
            for (let i of contrato.itens) {
              let cota = this.retornaCota(i, data.content)
              i['cota'] = this.retornaCota(i, data.content) === 'P' ? 'PRINCIPAL' : 'RESERVADO'
              let res = await this.contratoItemService.obterSaldo(this.login.orgao.id, i.id, { cota, contrato_id: contrato.id }).pipe(takeUntil(this.unsubscribe)).toPromise();
              i['saldo_qtd'] = +res.saldo_qtd;
              i['saldo_vl'] = +res.saldo_vl;
              i['vl_unit_atualizado'] = +res.valor_unitario;
            }
            resolve();
          } else {
            resolve();
          }
        }, (error) => {
          toastr.error(error.error.payload);
          reject();
        });
      } else {
        resolve();
      }
    });

    p.then(() => {
      contrato['carregado'] = true;
      impressao.push(contrato);
      this.imprimir('LISTAGEM - Contrato e Itens', this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
        'landscape', 'Listagem compras', ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto',], impressao);
    }).catch(() => { });
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================


  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
    super.ngOnInit();
    this.loadMenuFiltros(true);
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public carregarItens(item: Contrato, itens: DlgComponent, event) {
    if (this.carregando)
      return;
    itens.show();
    this.carregando = true;
    if (!item['carregado']) {
      this.contratoItemService.filtrar(1, -1, {
        'contrato.id': item.id,
        relations: 'produto_unidade.produto,produto_unidade.unidade',
        orderBy: 'produto_unidade.produto.nome$ASC'
      }).subscribe((data) => {
        if (data && data.content && data.content.length) {
          item.itens = data.content
          for (let i of item.itens) {
            let cota = this.retornaCota(i, data.content)
            i['cota'] = this.retornaCota(i, data.content) === 'P' ? 'PRINCIPAL' : 'RESERVADO'
            this.contratoItemService.obterSaldo(this.login.orgao.id, i.id, { cota, contrato_id: item.id }).pipe(takeUntil(this.unsubscribe))
              .subscribe((res) => {
                i['saldo_qtd'] = +res.saldo_qtd;
                i['saldo_vl'] = +res.saldo_vl;
                i['vl_unit_atualizado'] = +res.valor_unitario;
              });
          }
          this.contrato = item;
        }
        this.carregando = false;
        item['carregado'] = true;
      }, (error) => toastr.error(error.error.payload));
    } else {
      this.contrato = item;
      this.carregando = false;
    }
  }

  //***********************FILTRO PERSONALIZADO *************************//


  protected obterColunasfiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Código item', coluna: 'itens.produto_unidade.produto.codigo', tipo: 'String', cols: 2, });
    retorno.push({ titulo: 'Contrato', coluna: 'numero', tipo: 'String', cols: 2, mask: '0000/0000' });
    retorno.push({ titulo: 'Contrato Ativo', coluna: `data_termino$ge`, value: this.datepipe.transform(new Date(), 'yyyy-MM-dd'), tipo: 'Boolean' });
    retorno.push({ titulo: 'CPF/CNPJ', coluna: 'favorecido.cpf_cnpj', tipo: 'String' });
    retorno.push({ titulo: 'Descrição item', coluna: 'itens.produto_unidade.produto.nome', tipo: 'String' });
    retorno.push({ titulo: 'Fornecedor', coluna: 'favorecido.nome', tipo: 'String' });
    retorno.push({ titulo: 'Modalidade', coluna: 'modalidade.nome', tipo: 'String' });
    retorno.push({ titulo: 'Licitação', coluna: 'licitacao.numero', tipo: 'String', mask: '00000/0000' });
    retorno.push({ titulo: 'Objeto', coluna: 'licitacao.objeto', tipo: 'String' });
    retorno.push({ titulo: 'Processo', coluna: 'processo', tipo: 'String' });
    retorno.push({ titulo: 'Tipo', coluna: 'tipo', tipo: 'Selection', opcoesSelecao: [{ valor: 'CONTRATO', label: 'Contrato' }, { valor: 'REGISTRO_PRECO', label: 'Registro de Preço' }] });

    return retorno;
  }

  private loadMenuFiltros(init?: boolean) {
    let filtros = this.obterColunasfiltroPersonalizado();
    this.menuFiltros = filtros.map((l): MenuItem => {
      if (!l['filtro1'])
        l['filtro1'] = null;
      if (!l['filtro2'])
        l['filtro2'] = null;
      return {
        label: l.titulo,
        icon: this.iconMenu(l),

        visible: !this.filtroAdicionado(l),
        command: (event) => {
          this.adicionarFiltro(l);
        }
      };
    });
  }

  private iconMenu(coluna: Coluna) {
    let icon = 'plus';
    if (coluna.tipo === 'Date')
      icon = 'calendar';
    if (coluna.tipo === 'Number')
      icon = 'calculator';
    if (coluna.tipo === 'String')
      icon = 'font';
    if (coluna.tipo === 'Boolean')
      icon = 'check';
    if (coluna.tipo === 'Selection') {
      icon = 'list';
    }
    return `fa fa-${icon}`;
  }


  private adicionarFiltro(coluna: Coluna) {
    if (!this.filtroAdicionado(coluna)) {
      if (coluna.tipo === 'Boolean')
        coluna['filtro1'] = coluna.value ? coluna.value : false;
      this.listaFiltros.push(coluna);
      if (this.painel)
        this.painel.expand(null);
    }
    this.loadMenuFiltros();
  }

  public removerFiltro(coluna: Coluna) {
    let index = this.listaFiltros.indexOf(coluna);
    if (index || index === 0)
      this.listaFiltros.splice(index, 1);
    this.loadMenuFiltros();
  }

  public filtroAdicionado(coluna: Coluna): boolean {
    let filtro = this.listaFiltros.find((f) => f.coluna === coluna.coluna)
    return !(!filtro)
  }

  private obterFiltrosLista(): {} {
    let filtros: {} = {};
    try {
      for (let filtro of this.listaFiltros) {
        let convert = this.converterFiltro(filtro);
        if (convert)
          if (!convert['length']) {
            filtros[convert['chave']] = convert['valor'];
          } else {
            for (let fs of <[]>convert) {
              filtros[fs['chave']] = fs['valor'];
            }
          }
      }
      return filtros;
    } catch (error) {
      this.funcaoService.acaoErro(error);
      return null;
    }
  }

  private converterFiltro(coluna: Coluna): { chave: string, valor: string } | { chave: string, valor: string }[] {
    if (coluna.tipo === 'Number' && coluna['filtro1']) {
      if (coluna['filtro1'] && !coluna['filtro2']) {
        return { chave: `${coluna.coluna}$like`, valor: coluna['filtro1'] + '%' }
      }
      let filtro_data = [];
      if (coluna['filtro1']) {
        filtro_data.push({ chave: `${coluna.coluna}$ge`, valor: coluna['filtro1'] });
      }
      if (coluna['filtro2']) {
        filtro_data.push({ chave: `${coluna.coluna}$le`, valor: coluna['filtro2'] });
      }
      return filtro_data;
    }
    if (coluna.tipo === 'String' && coluna['filtro1'])
      return { chave: `${coluna.coluna}$like`, valor: `%${coluna['filtro1']}%` };

    if (coluna.tipo === 'Boolean' && coluna['filtro1'])
      return { chave: coluna.coluna, valor: coluna['filtro1'] };
    if (coluna.tipo === 'Date') {
      let filtro_data = [];
      if (coluna['filtro1'])
        filtro_data.push({ chave: `${coluna.coluna}$ge`, valor: this.datepipe.transform(coluna['filtro1'], 'yyyy-MM-dd') });
      if (coluna['filtro2'])
        filtro_data.push({ chave: `${coluna.coluna}$le`, valor: this.datepipe.transform(coluna['filtro2'], 'yyyy-MM-dd') });
      return filtro_data.length > 0 ? filtro_data : null;
    }

    if (coluna.tipo == 'Selection' && coluna['filtro1']) {
      return { chave: `${coluna.coluna}$eq`, valor: coluna['filtro1'] };
    }

    return null;
  }

  public filtroTipo(filtro: Coluna[], tipo: 'String' | 'Number' | 'Date' | 'Boolean' | 'HTML') {
    return filtro.filter((f) => f.tipo === tipo);
  }

  public ngClassCols(cols: number, padrao: string): {} {
    let ngClass = {};
    if (cols)
      ngClass[`col-sm-${cols}`] = true;
    else
      ngClass[padrao] = true;
    return ngClass;
  }

  gerarRelatorio() {
    // Relatorio.imprimir(titulo, usuario, orgao, brasao, lista, this.colunas(), orientacao, nomepdf, largura, ['valor_contrato']);
  }


}

