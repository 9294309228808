import { BaseResourceModel } from '../../models/base-resource.model';
import { Pessoa } from '../comum/pessoa.model';
import { Contrato } from './contrato.model';

export class ContratoResponsavel extends BaseResourceModel {
  constructor(
    public id?: number,
    public ativo?: boolean,
    public contratante?: boolean,
    public contrato?: Contrato,
    public responsavel?: Pessoa,
  ) {
    super();
  }
  static converteJson(json: any): ContratoResponsavel {
    return Object.assign(new ContratoResponsavel(), json);
  }
}
