// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Estoque } from './estoque.model';
import { Setor } from './setor.model';

export class SetorAlmoxarifado extends BaseResourceModel {
  constructor(
    public id?: number,
    public padrao?: boolean,
    public subalmoxarifado?: boolean,
    public estoque?: Estoque,
    public setor?: Setor,
    public editavel?: boolean
    ) {
    super();
  }
  static converteJson(json: any): SetorAlmoxarifado {
    return Object.assign(new SetorAlmoxarifado(), json);
  }
}
