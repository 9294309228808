// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { PpaPeriodo } from './ppa-periodo.model';
import { Ppa } from './ppa.model';

export class PpaLei extends BaseResourceModel {
  constructor(
    public id?: number,
    public lei?: string,
    public codigo?: string,
    public data_emissao?: Date,
    public data_publicacao?: Date,
    public data_vigencia?: Date,
    public tipo?: string,
    public ano?: number,
    public pib?: number,
    public rcl?: number,
    public inpc?: number,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public ppa?: Ppa,
    public ppaperiodo?: PpaPeriodo
  ) {
    super();
  }

  static converteJson(json: any): PpaLei {
    return Object.assign(new PpaLei(), json);
  }
}
