import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { EncerramentoContabilFormComponent } from './encerramento-contabil-form/encerramento-contabil-form.component';
import { EncerramentoContabilRoutingModule } from './encerramento-contabil-routing.module';
import { EncerramentoContabilJustificativaModule } from './justificativa/encerramento-contabil-justificativa.module';

@NgModule({
  declarations: [EncerramentoContabilFormComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    EncerramentoContabilRoutingModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    MessageModule,
    MessagesModule,
    EncerramentoContabilJustificativaModule
  ]
})
export class EncerramentoContabilModule { }
