// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Pessoa } from '../comum/pessoa.model';
import { Contrato } from './contrato.model';

export class ContratoFiscal extends BaseResourceModel {
  constructor(
    public id?: number,
    public pessoa?: Pessoa,
    public contrato?: Contrato,
    public tipo?: String
  ) {
    super();
  }

  static converteJson(json: any): ContratoFiscal {
    return Object.assign(new ContratoFiscal(), json);
  }
}
