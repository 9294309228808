import { RadioButtonModule } from 'primeng/radiobutton';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListboxModule } from 'primeng/listbox';
import { AnexoLRFRoutingModule } from './anexo-lrf-routing.module';
import { AnexoLRFComponent } from './anexo-lrf.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'eddydata-lib';
import { FieldsetModule } from 'primeng/fieldset';
import {InputNumberModule} from 'primeng/inputnumber';

@NgModule({
  declarations: [AnexoLRFComponent],
  imports: [
    SharedModule,
    FormsModule,
    CommonModule,
    AnexoLRFRoutingModule,
    ListboxModule,
    FieldsetModule,
    RadioButtonModule,
    InputNumberModule
  ]
})
export class AnexoLRFModule { }
