import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { LicitacaoService } from 'administrativo-lib';
import { BaseResourceFormComponent, FuncaoService, GlobalService, LoginContabil, Modalidade, TipoContratacao, TipoContratacaoModalidade } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { TipoContratacaoService } from '../../tipo-contratacao/service/tipo-contratacao.service';
import { ModalidadeService } from '../service/modalidade.service';

declare var $: any;

@Component({
  selector: 'lib-modalidade-form',
  templateUrl: './modalidade-form.component.html'
})
export class ModalidadeFormComponent extends BaseResourceFormComponent<Modalidade, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @ViewChild('nome') inputField: ElementRef;
  public listaTiposContratacao: { tipo_contratacao: TipoContratacao, marcado: boolean }[];
  public totalModalidade: number;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected tipoContratacaoService: TipoContratacaoService,
    protected licitacaoService: LicitacaoService,
    protected modalidadeService: ModalidadeService) {
    super(new Modalidade(), injector, Modalidade.converteJson, modalidadeService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      codigo: [null, [Validators.required]],
      codigo_pncp: [null, [Validators.required]],
      nome: [null, [Validators.required]],
      exigir_licitacao: [false, [Validators.required]],
      exigir_contrato: [false, [Validators.required]],
      exigir_convenio: [false, [Validators.required]],
      valor_limite: [null],
      prazo_limite: [null],
      modelo_ata: [null],
      cidade: [this.login.cidade, [Validators.required]],
      tipos_contratacao: [null],
      ativo: [true]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'cidade,tipos_contratacao.tipo_contratacao' };
  }

  protected afterLoad() {
    this.licitacaoService.totalizarPorModalidade(this.login.exercicio.id, this.login.orgao.id, this.entidade.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.totalModalidade = res;
      });
  }

  protected afterInit(): void {
    this.tipoContratacaoService.filtrar(1, -1, { orgao_id: this.login.orgao.id, orderBy: 'tce,nome' }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        const lista: TipoContratacao[] = res.content;

        this.listaTiposContratacao = [];
        lista.forEach(tc =>
          this.listaTiposContratacao.push({
            marcado: Boolean(this.entidade.tipos_contratacao?.find(ent => ent.tipo_contratacao.id === tc.id)).valueOf(),
            tipo_contratacao: tc
          })
        );
      }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected beforeSubmit() {
    try {
      if (this.entidadeForm.get('nome').value === '') {
        throw new Error('Informe o nome!');
      }

      const itens: TipoContratacaoModalidade[] = [];
      this.listaTiposContratacao.filter(m => m.marcado).forEach(m => {
        itens.push({ tipo_contratacao: m.tipo_contratacao });
      })
      if (itens.length === 0) {
        throw new Error('Informe o(s) tipo(s) de contratação que a modalidade poderá usar!');
      }
      this.entidadeForm.get('tipos_contratacao').setValue(itens);

      this.entidadeForm.get('cidade').setValue(this.login.cidade);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(ent: Modalidade) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
  public abrirModelo() {
    $('#dialogModeloAta').modal('show');
  }

  public callbackModelo(modelo: string) {
    if (modelo) {
      this.entidadeForm.get('modelo_ata').setValue(modelo);
    }
  }

}
