import { Component, ElementRef, Injector } from '@angular/core';
import { BaseResourceFormComponent, LoginContabil, Seguradora } from 'eddydata-lib';
import { SeguradoraService } from '../service/seguradora.service';

@Component({
  selector: 'app-seguradora-view',
  templateUrl: './seguradora-view.component.html'
})
export class SeguradoraViewComponent extends BaseResourceFormComponent<Seguradora, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  readOnly: boolean;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected seguradoraService: SeguradoraService) {
    super(new Seguradora(), injector, Seguradora.converteJson, seguradoraService);
    this.readOnly = true;
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {

  }

  protected parametrosExtras(): {} {
    return { relations: 'orgao' };
  }

  protected afterLoad() {
  }

  protected afterInit(): void {
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit() {
  }

  protected afterSubmit(modelo: Seguradora) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
