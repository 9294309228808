// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Chamamento } from './chamamento.model';

export class PlanoTrabalho extends BaseResourceModel {
  constructor(
    public id?: number,
    public meta?: string,
    public chamamento?: Chamamento,
    public editavel?: boolean
  ) {
    super();
  }
  static converteJson(json: any): PlanoTrabalho {
    return Object.assign(new PlanoTrabalho(), json);
  }
}
