import { BaseResourceStorage } from '../../models/base-resource.storage';
import { Rpl } from './rpl.model'; 

export class RplStorage extends BaseResourceStorage {
  constructor(
    public rpl?: Rpl,
    public visivel_transparencia?: boolean
  ) {
    super();
  }

  static converteJson(json: any): RplStorage {
    return Object.assign(new RplStorage(), json);
  }
}