// tslint:disable: variable-name
import { Legislacao } from './legislacao.model';
import { Vereador } from './vereador.model';

export class Autoria {

    id: number;

    data_cadastro: Date;

    data_alteracao: Date;

    vereador: Vereador;

    legislacao: Legislacao;
}
