import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BaseResourceListComponent,
  Coluna, DateFormatPipe, Exercicio, ExercicioService, FichaReceita, Filtro, FormatoExportacao, FuncaoService, LoginPublico, Receita, ReceitaPrevista, Sistemas
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RecebimentoService } from '../recebimento/service/recebimento.service';

@Component({
  selector: 'app-receita-nivel',
  templateUrl: './receita-nivel.component.html'
})
export class ReceitaNivelComponent extends BaseResourceListComponent<Receita, LoginPublico> implements OnInit {

  /**
   * Declaração de variáveis
   */
  public tipo: string;
  public programacaoId: number;
  public listaPlanilha: Array<any>;
  public listaArrecadacao: Array<Receita>;
  public ficha: FichaReceita;
  public usarExtendido: boolean;
  public exercicio: Exercicio;
  public sistema: Sistemas;
  public datepipe: DatePipe;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private route: ActivatedRoute,
    protected injector: Injector,
    private exercicioService: ExercicioService,
    protected funcaoService: FuncaoService,
    private recebimentoService: RecebimentoService) {
    super(recebimentoService, injector);
    this.datepipe = new DatePipe('pt');
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  public preencherGrid() {
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.sistema = params['sistema'];
        this.exercicioService.obterPorAno(+params['ano'], this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (res) => {
              this.exercicio = res.content[0];
              this.recebimentoService.arrecadacaoPorNivel(this.login.orgao.id,
                this.exercicio.id, 12).pipe(takeUntil(this.unsubscribe))
                .subscribe((lista) => {
                  this.listaPlanilha = lista;
                });
            }
          );
      });
  }

  protected relations(): string {
    return '';
  }

  protected condicoesGrid(): {} {
    return { ['exercicio.id']: this.login.exercicio.id };
  }

  protected ordenacaoGrid(): string[] {
    return ['r_numero$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {};
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: ReceitaPrevista): Observable<ReceitaPrevista> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'codigo' },
      { titulo: 'Descrição', coluna: 'nome' },
      { titulo: 'Janeiro', coluna: 'arrecadado_jan', decimais: 2, alignment: 'right' },
      { titulo: 'Fevereiro', coluna: 'arrecadado_fev', decimais: 2, alignment: 'right' },
      { titulo: 'Março', coluna: 'arrecadado_mar', decimais: 2, alignment: 'right' },
      { titulo: 'Abril', coluna: 'arrecadado_abr', decimais: 2, alignment: 'right' },
      { titulo: 'Maio', coluna: 'arrecadado_mai', decimais: 2, alignment: 'right' },
      { titulo: 'Junho', coluna: 'arrecadado_jun', decimais: 2, alignment: 'right' },
      { titulo: 'Julho', coluna: 'arrecadado_jul', decimais: 2, alignment: 'right' },
      { titulo: 'Agosto', coluna: 'arrecadado_ago', decimais: 2, alignment: 'right' },
      { titulo: 'Setembro', coluna: 'arrecadado_set', decimais: 2, alignment: 'right' },
      { titulo: 'Outubro', coluna: 'arrecadado_out', decimais: 2, alignment: 'right' },
      { titulo: 'Novembro', coluna: 'arrecadado_nov', decimais: 2, alignment: 'right' },
      { titulo: 'Dezembro', coluna: 'arrecadado_dez', decimais: 2, alignment: 'right' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    this.recebimentoService
      .arrecadacaoPorNivel(this.login.orgao.id,
        this.login.exercicio.id, 12
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('ARRECADAÇÃO MENSAL DE RECEITAS ORÇAMENTÁRIAS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem arrecadação receita', ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto',
              'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], lista);
          } else {
            this.exportar(formato, lista);
          }
        });
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public redirecionarReceita(mes: number, codigo: string) {
    const data1 = new DateFormatPipe().transform(this.datepipe.transform(new Date(`${mes}/01/${this.exercicio.ano}`), 'yyyy-MM-dd'), []);
    const data2 = new DateFormatPipe().transform(this.datepipe.transform(new Date(`${mes}/${this.funcaoService.ultimoDiaMes(mes)}/${this.exercicio.ano}`), 'yyyy-MM-dd'), []);
    const dt1 = this.datepipe.transform(data1, 'yyyy-MM-dd', 'GMT');
    const dt2 = this.datepipe.transform(data2, 'yyyy-MM-dd', 'GMT');

    switch (this.sistema) {
      case 'transparencia':
        this.router.navigate(['/transparencia/receitas-orcamentarias/pesquisa/todos/1', dt1, dt2, codigo]);
        break;
      case 'indicador-gestao':
      case 'controle-interno':
      case 'contabil':
        sessionStorage.setItem('/recebimentos_data1', dt1);
        sessionStorage.setItem('/recebimentos_data2', dt2);
        sessionStorage.setItem('/recebimentos_filtro', codigo);
        this.router.navigate(['/recebimentos-orcamentarios']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public voltar() {
    switch (this.sistema) {
      case 'transparencia':
        this.router.navigate(['/transparencia/portal']);
        break;
      default:
        this.sair();
        break;
    }
  }
}
