import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class SiteParametro extends BaseResourceModel {
  constructor(
    public id?: number,
    public mostrar_video?: boolean,
    public video?: string,
    public mostrar_rede_social?: boolean,
    public facebook?: string,
    public youtube?: string,
    public twitter?: string,
    public orgao?: Orgao,
  ) {
    super();
  }

  static converteJson(json: any): SiteParametro {
    return Object.assign(new SiteParametro(), json);
  }
}
