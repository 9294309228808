import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { FavorecidoTipoRoutingModule } from './favorecido-tipo-routing.module';
import { SharedModule } from 'eddydata-lib';
import { ToastModule } from 'primeng/toast';
import { FavorecidoTipoListComponent } from './favorecido-tipo-list/favorecido-tipo-list.component';
import { FavorecidoTipoFormComponent } from './favorecido-tipo-form/favorecido-tipo-form.component';


@NgModule({
  declarations: [FavorecidoTipoListComponent, FavorecidoTipoFormComponent],
  imports: [
    CommonModule,
    FavorecidoTipoRoutingModule,
    SharedModule,
    FormsModule,
    ToastModule
  ]
})
export class FavorecidoTipoModule { }
