import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, AdiantamentoDocumento, Page } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class AdiantamentoDocumentoService extends BaseResourceService<AdiantamentoDocumento> {

  constructor(
    protected injector: Injector
  ) {
    super(`adiantamento-documentos`, injector);
  }

  public obterPorAdiantamento(id: number): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar/?relations=adiantamento&adiantamento_id=${id}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
