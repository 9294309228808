import { Component, Injector, ElementRef } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BaseResourceFormComponent, Empenho, LoginContabil,
  Favorecido, GlobalService, FuncaoService, DateFormatPipe,
  Exercicio, FavorecidoService, Contrato, OrgaoAssinaturaService, Precatorio, EddyAutoComplete
} from 'eddydata-lib';
import { EmpenhoService, DespesaService, ConvenioService } from 'administrativo-lib';
import * as toastr from 'toastr';
import { FichaDespesaService } from '../../ficha-despesa/service/ficha-despesa.service';
import { takeUntil } from 'rxjs/operators';
import { NotaEmpenho } from '../../relatorio/contabil/nota-empenho';
import { MessageService } from 'primeng/api';
import { PrecatorioService } from '../../precatorio/service/precatorio.service';

@Component({
  selector: 'app-empenho-view',
  templateUrl: './empenho-view.component.html'
})
export class EmpenhoViewComponent extends BaseResourceFormComponent<Empenho, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public listaFavorecidos: Array<Favorecido>;
  public especie: string;
  public dotacao: number;
  public reservado: number;
  public anterior: number;
  public saldo: number;
  public empenhado: number;
  public liquidado: number;
  public pago: number;

  public saldoContrato: number = 0;
  public saldoConvenio: number = 0;
  public empenhoAtualizado: Empenho;
  public naoEditar = true
  public precatorioAutoComplete: EddyAutoComplete<Precatorio>;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected favorecidoService: FavorecidoService,
    protected funcaoService: FuncaoService,
    protected despesaService: DespesaService,
    protected fichaService: FichaDespesaService,
    protected empenhoService: EmpenhoService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected convenioService: ConvenioService,
    protected precatorioService: PrecatorioService,
    private messageService: MessageService
  ) {
    super(new Empenho(), injector, Empenho.converteJson, empenhoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, Validators.required],
      valor_empenho: [null, Validators.required],
      data_empenho: [null, Validators.required],
      data_vencimento: [null],
      especie: [null, Validators.required],
      tipo_empenho: [null, Validators.required],
      fase4: [null, Validators.required],
      disponibilidade_anterior: [false, [Validators.required]],
      adiantamento: [null, Validators.required],
      processo: [null],
      documento: [null],
      modalidade: [null, Validators.required],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      ficha: [null, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      favorecido: [null, Validators.required],
      subelemento: [null, Validators.required],
      autorizado: [false, [Validators.required]],
      compra: [null],
      licitacao: [null],
      precatorio: [null],
      ata: [null],
      contrato: [null],
      convenio: [null],
      mes: [null],
      aux: [0],
      usuario_cadastro: [this.login.usuario, [Validators.required]],
      inscrito_resto: [false],
      disponivel: [false],
      contrato_aditamento: [null]
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'ficha,modalidade,licitacao,subelemento,compra.rcms,convenio,favorecido,favorecido.tipo,contrato,ficha.despesa,exercicio,orgao,precatorio'
    };
  }

  protected afterInit(): void {
    this.soVisualizar = false;
    this.carregarAutoCompletes();
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected async afterLoad() {
    this.entidade.data_empenho = new DateFormatPipe().transform(this.entidade.data_empenho, []);
    this.entidade.data_vencimento = new DateFormatPipe().transform(this.entidade.data_vencimento, []);

    await this.loadTotalizadores();
    this.loadEspecie();

    if (this.entidade.contrato) {
      this.obterSaldoContrato(this.entidade.contrato);
    }
    if (this.entidade.convenio) {
      this.saldoConvenio = await this.convenioService.buscarSaldoConvenio(
        this.entidade?.convenio?.id,
         this.funcaoService.converteDataSQL(this.entidadeForm.get('data_empenho').value),
          this.login.orgao.id ).toPromise()
      // this.obterSaldoConvenio();
    }

    this.precatorioAutoComplete.id = this.entidade.precatorio ? this.entidade.precatorio.id : null;

    if (this.entidade.especie === 'EOA' && (this.funcaoService.converteDataSQL(new Date(this.entidade.data_cadastro)) === this.funcaoService.converteDataSQL(new Date()))) {
      this.naoEditar = false
    }
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: Empenho): void {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  voltar() {
    if (this.component) {
      this.id = null;
      this.idChange.emit(this.id);
    } else
      window.history.back();
  }

  public loadEspecie() {
    this.especie = this.globalService.obterTipoEmpenho(this.entidade.tipo_empenho);
  }

  private async loadTotalizadores() {
    this.empenhado = 0;
    this.liquidado = 0;
    this.pago = 0;
    this.dotacao = 0;
    this.anterior = 0;
    this.reservado = 0;

    const exercicio: Exercicio = this.entidadeForm.get('exercicio').value
      ? this.entidadeForm.get('exercicio').value
      : this.login.exercicio;

    let totalizadores: any;
    totalizadores = await this.empenhoService.totalizadores(exercicio.id, this.login.orgao.id, this.entidade.numero).toPromise();

    if (totalizadores) {
      totalizadores = totalizadores.content[0] ? totalizadores.content[0] : totalizadores.content;
      this.empenhado = totalizadores.total_empenhado;
      this.liquidado = totalizadores.total_liquidado;
      this.pago = totalizadores.total_pago;

      this.dotacao = (+totalizadores.total_dotacao_ficha + +totalizadores.total_creditado_ficha) - +totalizadores.total_reservado;
      this.anterior = +this.dotacao - +totalizadores.total_empenhado_ficha + +this.entidade.valor_empenho;
      this.reservado = +totalizadores.total_reservado;
    }
  }

  public async obterSaldoConvenio() {
    if (this.entidadeForm.get('convenio').value !== null) {
      this.saldoConvenio = +(await this.convenioService.buscarSaldoConvenio(this.entidadeForm.get('convenio').value.id, this.funcaoService.converteDataSQL(this.entidadeForm.get('data_empenho').value), this.login.orgao.id, { empenhoId: this.entidade.id }).toPromise())
    }
  }

  public obterSaldoContrato(contrato: Contrato) {
    this.empenhoService.totalEmpenhadoPorContrato(this.entidadeForm.get('contrato').value.id, this.login.orgao.id)
      .subscribe((saldo) => {
        if (contrato.tipo_contratacao && contrato.tipo_contratacao.material && contrato.tipo_contratacao.servico) {
          if (this.entidadeForm.get('ficha').value && this.entidadeForm.get('ficha').value.despesa.codigo.substr(0, 6) == '449052') {
            this.saldoContrato = this.entidadeForm.get('contrato').value.valor_material - saldo;
          } else if (this.entidadeForm.get('ficha').value && (
            this.entidadeForm.get('ficha').value.despesa.codigo.substr(0, 6) == '339036' || this.entidadeForm.get('ficha').value.despesa.codigo.substr(0, 6) == '339039')) {
            this.saldoContrato = this.entidadeForm.get('contrato').value.valor_servico - saldo;
          } else {
            this.saldoContrato = this.entidadeForm.get('contrato').value.valor_contrato - saldo;
          }
        } else {
          this.saldoContrato = this.entidadeForm.get('contrato').value.valor_contrato - saldo;
        }
      });
  }

  public imprimir() {
    const parametros = {};

    let relations = 'ficha.executora.unidade,ficha.despesa,ficha.funcao,ficha.subfuncao,ficha.programa,ficha.acao,ficha.recurso,ficha.aplicacao,ficha.despesa,ficha.aplicacao_variavel,contrato_aditamento,';
    relations += 'licitacao,modalidade,subelemento,favorecido.contas.banco,favorecido.contas.orgao,favorecido.tipo,contrato,convenio,liquidacoes,compra,compra.rcms,compra.rcms.requerente,compra.rcms.prazo';
    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id'] = this.entidade.id;
    parametros['orderBy'] = 'liquidacoes.parcela$ASC';
    this.empenhoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        new NotaEmpenho(this.assinaturaService).imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

  private carregarAutoCompletes() {
    // autocomplete para precatorio
    this.precatorioAutoComplete = new EddyAutoComplete(this.entidadeForm.get('precatorio'), this.precatorioService, 'id', null,
      { 'orgao.id': this.login.orgao.id, relations: 'orgao,favorecido', orderBy: 'numero_acao' },
      { text: ['numero_acao', 'numero_precatorio', 'favorecido.cpf_cnpj', 'favorecido.nome', 'favorecido.id'] }, null, null,
      (entidade: Precatorio) => {
        return 'Nº Ação: ' + entidade.numero_acao + ' - Nº Precatório: ' + entidade.numero_precatorio;
      }
    );
  }

  atualizarPrec() {
    if (this.entidadeForm.value.precatorio != null) {
      this.empenhoAtualizado = this.entidadeForm.value
      this.empenhoAtualizado['atualizar_precatorio'] = true;
      this.empenhoService.atualizar(this.empenhoAtualizado)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(dados => {
          toastr.success('Precatório do Empenho atualizado!')
        }, error => toastr.error(error.error.payload));
    } else {
      toastr.error('Informe um Precatório válido');
      throw new Error('Informe um Precatório válido')
    }
  }
}
