import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ContratoResponsavel } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ContratoResponsavelService extends BaseResourceService<ContratoResponsavel> {

  constructor(
    protected injector: Injector
  ) {
    super(`contrato-responsaveis`, injector);
  }
}
