import { TrocarSenhaComponent } from './trocar-senha/trocar-senha.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../util/shared.module';
import { IMaskModule } from 'angular-imask';
import { MessageService } from 'primeng/api';
import { LoginComponent } from './login.component';
import { RecuperarSenhaComponent } from './recuperar-senha/recuperar-senha.component';
import { RecuperarSenhaSucessoComponent } from './recuperar-senha-sucesso/recuperar-senha-sucesso.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { LoginRegistroComponent } from './login-registro/login-registro.component';
import { LoginRegistroSucessoComponent } from './login-registro-sucesso/login-registro-sucesso.component';

@NgModule({
  declarations: [
    LoginComponent,
    LoginRegistroComponent,
    LoginRegistroSucessoComponent,
    RecuperarSenhaComponent,
    RecuperarSenhaSucessoComponent,
    TrocarSenhaComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    IMaskModule,
    SelectButtonModule,
    ToastModule
  ],
  exports: [
    LoginComponent,
    LoginRegistroComponent,
    LoginRegistroSucessoComponent,
    RecuperarSenhaComponent,
    RecuperarSenhaSucessoComponent,
    TrocarSenhaComponent
  ],
  providers: [MessageService]
})
export class LoginModule { }
