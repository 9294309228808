// tslint:disable: variable-name
import { LegislacaoHistorico } from './legislacao-historico.model';
import { Usuario } from '../comum/usuario.model';

export class LegislacaoHistoricoView {

    id: number;

    usuario: Usuario;

    historico: LegislacaoHistorico;

    data_cadastro: Date;

    data_alteracao: Date;

}
