import { BaseResourceStorage } from '../../models/base-resource.storage';
import { ImovelOcorrencia } from './imovel-ocorrencia.model';

export class ImovelOcorrenciaStorage extends BaseResourceStorage {
    constructor(
        public imovel_ocorrencia?: ImovelOcorrencia,
    ) {
        super();
    }

    static converteJson(json: any): ImovelOcorrenciaStorage {
        return Object.assign(new ImovelOcorrenciaStorage(), json);
    }
}
