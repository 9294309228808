import { Component, ElementRef, Injector } from '@angular/core';
import { ProdutoService } from 'almoxarifado-lib';
import { BaseResourceFormComponent, Produto, LoginContabil, GlobalService } from 'eddydata-lib';

@Component({
  selector: 'lib-produto-view',
  templateUrl: './produto-view.component.html'
})
export class ProdutoViewComponent extends BaseResourceFormComponent<Produto, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  readOnly: boolean;
  public mes: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected produtoService: ProdutoService,
    protected globalService: GlobalService
  ) {
    super(new Produto(), injector, Produto.converteJson, produtoService);
    this.readOnly = true;
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
  }

  protected parametrosExtras(): {} {
    return { relations: 'material.sub_grupo.grupo,unidades.unidade' };
  }

  protected afterLoad() {
  }

  protected afterInit(): void {
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit() {
  }

  protected afterSubmit(modelo: Produto) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
}
