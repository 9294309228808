import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FuncaoService, Exercicio } from 'eddydata-lib';

@Directive()
export class Anexo8ReceitaFundeb implements OnDestroy {

  protected funcaoService: FuncaoService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number,
    protected exercicio: Exercicio) {
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(fundeb: any[], impostos: any[]): {}[] { 

    // Caso seja adicionado novos campos no layout será necessario mudar a posição dos arrays
    let total_destinado1 = 0;
    let total_destinado2 = 0;

    total_destinado1 = (impostos[4].fundeb_atualizado + impostos[6].fundeb_atualizado + impostos[7].fundeb_atualizado + impostos[8].fundeb_atualizado + impostos[9].fundeb_atualizado + impostos[11].fundeb_atualizado);

    total_destinado2 = (impostos[4].fundeb_recebido + impostos[6].fundeb_recebido + impostos[7].fundeb_recebido + impostos[8].fundeb_recebido + impostos[9].fundeb_recebido + impostos[11].fundeb_recebido);

    const registros: {}[] = [
      [{
        text: 'FUNDEB', colSpan: 3, fontSize: 8, bold: true,
        margin: [5, 5, 5, 5], alignment: 'center', border: [true, false, true, true]
      }, '', ''],
      [{
        text: 'RECEITAS RECEBIDAS DO FUNDEB NO EXERCÍCIO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'PREVISÃO ATUALIZADA (a)',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'RECEITAS REALIZADAS ATÉ O BIMESTRE (b)',
        alignment: 'center',
        bold: true, fontSize: 6
      }]
    ];

    const grupos2 = this.funcaoService.agrupar(fundeb, 'grupo', ['arrecadado', 'atualizado']); 
    let total_6_1 = 0;
    let total_6_2 = 0;
    for (const grupo of grupos2) {
      registros.push([
        { text: grupo.grupo, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['atualizado']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']), alignment: 'right', fontSize: 7 },
      ]);
      let subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['arrecadado', 'atualizado']);
      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo != '') {

          for (let item of subgrupo.registros) {
            if (item.nome === '6.1.1- Principal') {
              total_6_1 += +item.atualizado;
              total_6_2 += +item.arrecadado;
            }
          }

          registros.push([
            { text: subgrupo.grupo, fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['atualizado']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['arrecadado']), alignment: 'right', fontSize: 7 },
          ]);

          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              registros.push([
                { text: item.nome, fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.atualizado), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.arrecadado), alignment: 'right', fontSize: 7 },
              ]);
            }
          }
        }
      }
    }

    registros.push([
      { text: '7- RESULTADO LÍQUIDO DAS TRANSFERÊNCIAS DO FUNDEB (6.1.1 - 4)', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total_6_1 - total_destinado1), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total_6_2 - total_destinado2), alignment: 'right', fontSize: 7, bold: true },
    ]);

    return registros;

  }

  public montarQuadroCsv(dados: any[], impostos: any[], listaExportar): any {

    // Caso seja adicionado novos campos no layout será necessario mudar a posição dos arrays
    let total_destinado1 = 0;
    let total_destinado2 = 0;

    total_destinado1 = (impostos[4].atualizado + impostos[6].atualizado + impostos[7].atualizado + impostos[8].atualizado + impostos[9].atualizado) * 0.2;

    total_destinado2 = (impostos[4].arrecadado + impostos[6].arrecadado + impostos[7].arrecadado + impostos[8].arrecadado + impostos[9].arrecadado) * 0.2;

    const grupos2 = this.funcaoService.agrupar(dados, 'grupo', ['arrecadado', 'atualizado']);
    let total_6_1 = 0;
    let total_6_2 = 0;

    const espaco = {
      titulo: '',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(espaco)

    const primeira_linha = {
      titulo: 'FUNDEB',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(primeira_linha)

    const segunda_linha = {
      titulo: 'RECEITAS RECEBIDAS DO FUNDEB NO EXERCÍCIO ',
      coluna2: 'PREVISÃO ATUALIZADA (a)',
      coluna3: 'RECEITAS REALIZADAS ATÉ O BIMESTRE (b)',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(segunda_linha)

    for (const grupo of grupos2) {
      const linha_titulo = {
        titulo: grupo.grupo,
        coluna2: this.funcaoService.convertToBrNumber(grupo.totalizadores['atualizado']),
        coluna3: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']),
        coluna4: '',
        coluna5: '',
        coluna6: '',
        coluna7: ''
      }
      listaExportar.push(linha_titulo)

      let subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['arrecadado', 'atualizado']);
      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo != '') {

          for (let item of subgrupo.registros) {
            if (item.nome === '6.1.1- Principal') {
              total_6_1 += +item.atualizado;
              total_6_2 += +item.arrecadado;
            }
          }
          const linha_subgrupo = {
            titulo: subgrupo.grupo,
            coluna2: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['atualizado']),
            coluna3: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['arrecadado']),
            coluna4: '',
            coluna5: '',
            coluna6: '',
            coluna7: ''
          }
          listaExportar.push(linha_subgrupo)

          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              const linha_subgrupo_item = {
                titulo: item.nome,
                coluna2: this.funcaoService.convertToBrNumber(item.atualizado),
                coluna3: this.funcaoService.convertToBrNumber(item.arrecadado),
                coluna4: '',
                coluna5: '',
                coluna6: '',
                coluna7: ''
              }
              listaExportar.push(linha_subgrupo_item)
            }
          }

        }
      }
    }

    const linha_titulo = {
      titulo: '7- RESULTADO LÍQUIDO DAS TRANSFERÊNCIAS DO FUNDEB (6.1.1 - 4)',
      coluna2: this.funcaoService.convertToBrNumber(total_6_1 - total_destinado1),
      coluna3: this.funcaoService.convertToBrNumber(total_6_2 - total_destinado2),
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(linha_titulo)

  }

}