import { NgModule } from '@angular/core';

import { EmpenhoRestoRoutingModule } from './empenho-resto-routing.module';
import { EmpenhoRestoViewComponent } from './empenho-resto-view/empenho-resto-view.component';
import { EmpenhoRestoListComponent } from './empenho-resto-list/empenho-resto-list.component';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { IMaskModule } from 'angular-imask';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { EmpenhoRestoAnulacaoComponent } from './empenho-resto-anulacao/empenho-resto-anulacao.component';
import { SharedModule } from 'eddydata-lib';
import { EmpenhoRestoFormComponent } from './empenho-resto-form/empenho-resto-form.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { EmpenhoRestoRptComponent } from './empenho-resto-rpt/empenho-resto-rpt.component';
import { FieldsetModule } from 'primeng/fieldset';
import { RadioButtonModule } from 'primeng/radiobutton';
import { NotaEmpenhoRestoDlgComponent } from './nota-empenho-resto-dlg/nota-empenho-resto-dlg.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InplaceModule } from 'primeng/inplace';

@NgModule({
  declarations: [
    EmpenhoRestoViewComponent,
    EmpenhoRestoListComponent,
    EmpenhoRestoAnulacaoComponent,
    EmpenhoRestoFormComponent,
    EmpenhoRestoRptComponent,
    NotaEmpenhoRestoDlgComponent
  ],
  imports: [
    EmpenhoRestoRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    InputNumberModule,
    RadioButtonModule,
    FieldsetModule,
    ConfirmDialogModule,
    InplaceModule,
  ],
  providers: [MessageService, ConfirmationService]
})
export class EmpenhoRestoModule { }
