import { Component, Injector, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Coluna, DateFormatPipe, EddyAutoComplete, FormatoExportacao, FuncaoService, Imovel, ImovelOcorrencia, Login, Relatorio, TipoImovelOcorrencia } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { TipoImovelOcorrenciaService } from '../../../../../patrimonio/src/app/tipo-ocorrencia/service/tipo-ocorrencia.service';
import { BemImovelOcorrenciaService } from '../service/bem-imovel-ocorrencia.service';

declare var $: any;

@Component({
  selector: 'app-bem-imovel-ocorrencia',
  templateUrl: './bem-imovel-ocorrencia.component.html'
})
export class BemImovelOcorrenciaComponent implements OnInit, OnChanges, OnDestroy {

  public tipoImovelOcorrenciaAutoComplete: EddyAutoComplete<TipoImovelOcorrencia>;
  public entidade: ImovelOcorrencia = new ImovelOcorrencia();
  public novoItem: boolean;
  public posicaoItem: number;
  public status: any;
  public data_ocorrencia: Date;
  public descricao: string;
  public parecer: any;
  public tipo: TipoImovelOcorrencia;
  public solicitacao: boolean;
  public descricaoTipoOcorrencia: string;
  public imovel: any;
  public usuario: any;
  public ptBR: any;
  public itemTipoOcorrencia: TipoImovelOcorrencia = new TipoImovelOcorrencia();
  protected unsubscribe: Subject<void> = new Subject();

  @Input() lista: Array<any>;
  @Input() bemImovel: Imovel;
  @Input() login: Login;

  constructor(
    public funcaoService: FuncaoService,
    protected injector: Injector,
    protected tipoImovelOcorrenciaService: TipoImovelOcorrenciaService,
    private bemImovelOcorrenciaService: BemImovelOcorrenciaService) {
  }

  ngOnInit(): void {
    this.carregarAutoCompletes();
  }

  ngOnChanges(changes) {
    if (changes?.bemImovel?.currentValue?.id) {
      this.bemImovelOcorrenciaService.obterPorImovel(changes.bemImovel.currentValue.id)
        .subscribe(bensImoveisOcorrencias => this.lista = Array.from(bensImoveisOcorrencias as ImovelOcorrencia[]));
    }
    if (changes.data_ocorrencia) {
      changes.data_ocorrencia = new DateFormatPipe().transform(changes.data_ocorrencia, []);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private carregarAutoCompletes() {
    this.tipoImovelOcorrenciaAutoComplete = new EddyAutoComplete(null, this.tipoImovelOcorrenciaService,
      'id', ['descricao'], { orgao_id: this.login.orgao.id }, { text: ['descricao'] }
    );
  }

  public filtrar() {
    this.bemImovelOcorrenciaService.obterPorImovel(this.bemImovel.id)
      .subscribe(bensImoveisOcorrencias => {
        this.lista = Array.from(bensImoveisOcorrencias as ImovelOcorrencia[]);
      });
  }

  public adicionar() {
    if (!this.lista) {
      this.lista = [];
    }
    this.lista.unshift(new ImovelOcorrencia());
  }

  public salvar() {
    this.entidade.tipo = this.tipo;
    this.entidade.imovel = this.bemImovel;
    this.entidade.usuario = this.login.usuario;

    try {
      

      if (this.entidade.id) {
        this.bemImovelOcorrenciaService.atualizar(this.entidade).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro alterado com sucesso!');
            this.filtrar();
          });
      } else {
        this.bemImovelOcorrenciaService.inserir(this.entidade).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro inserido com sucesso!');
            this.filtrar();
          });
      }
    } catch (e) {
      toastr.error(e.message);
      throw e;
    }

    this.filtrar();
    this.fecharModal();
  }

  public editar(item: any) {
    this.entidade = item;
    this.tipo = this.entidade.tipo;
    this.imovel = this.entidade.imovel;
    this.usuario = this.entidade.usuario;

    if (!(this.login.usuario.administrador && this.login.usuario.sistemas_administrador?.includes('patrimonio'))) {
      this.solicitacao = true;
    }

    this.filtrar();
    this.abrirModal();
  }

  public novo() {
    this.entidade = new ImovelOcorrencia();
    this.status = 'SOLICITADA';
    if (!this.data_ocorrencia) {
      this.data_ocorrencia = new Date();
    }
    this.descricao = '';
    this.parecer = '';
    this.tipo = null;
    this.abrirModal();
  }

  public confirmar(item: any) {
    this.entidade = item;
    this.entidade.usuario = this.login.usuario;
    this.imovel = this.bemImovel;
    if (this.entidade.id) {
      this.bemImovelOcorrenciaService.atualizar(this.entidade).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          toastr.info('Registro confirmado com sucesso!');
          this.filtrar();
        });
    }
  }

  public recusar(item: any) {
    this.entidade = item;
    this.entidade.usuario = this.login.usuario;
    this.imovel = this.bemImovel;
    if (this.entidade.id) {
      this.bemImovelOcorrenciaService.atualizar(this.entidade).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          toastr.info('Registro recusado com sucesso!');
          this.filtrar();
        });
    }
  }

  public remover(item: any) {
    if (item.id) {
      this.bemImovelOcorrenciaService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          toastr.info('Registro removido com sucesso!');
          this.filtrar();
        });
    }
    this.lista.splice(this.lista.findIndex((i) => { return i === item }), 1);
  }

  public salvarModalTipoOcorrencia() {
    this.itemTipoOcorrencia.descricao = this.descricaoTipoOcorrencia;
    this.itemTipoOcorrencia.orgao = this.login.orgao;

    try {
      if (!this.itemTipoOcorrencia.descricao) {
        throw new Error('Informe a descrição!');
      }
      if (this.itemTipoOcorrencia.id) {
        this.tipoImovelOcorrenciaService.atualizar(this.itemTipoOcorrencia).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro alterado com sucesso!');
            this.tipo = res;
          });
      } else {
        this.tipoImovelOcorrenciaService.inserir(this.itemTipoOcorrencia).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro inserido com sucesso!');
            this.tipo = res;
          });
      }
    } catch (e) {
      toastr.error(e.message);
      throw e;
    }

    this.fecharModalTipoOcorrencia();
  }

  abrirModal() {
    $('#dialogBemImovelOcorrencia').modal('show');
  }

  fecharModal() {
    $('#dialogBemImovelOcorrencia').modal('hide');
  }

  abrirModalTipoOcorrencia() {
    $('#dialogTipoOcorrencia').modal('show');
  }

  fecharModalTipoOcorrencia() {
    $('#dialogTipoOcorrencia').modal('hide');
  }

  private colunasRelatorio(): Coluna[] {
    return [
      { titulo: 'Descrição', coluna: 'descricao' },
      { titulo: 'Data', coluna: 'data_ocorrencia' },
      { titulo: 'Tipo', coluna: 'tipo.descricao' },
      { titulo: 'Status', coluna: 'status', tipo: 'HTML' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    if (formato === 'pdf') {
      Relatorio.imprimir(`Ocorrências do imóvel ${this.bemImovel.matricula}`,
        this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, this.lista, this.colunasRelatorio(), 'portrait',
        `Ocorrências do imóvel ${this.bemImovel.matricula}`, ['*', 'auto', 'auto', 'auto']);
    } else {
      new FuncaoService().exportar(formato, this.lista, `Ocorrências do imóvel ${this.bemImovel.matricula}`, this.colunasRelatorio());
    }
  }
}
