import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { AdiantamentoDocumentoComponent } from './adiantamento-documento/adiantamento-documento.component';
import { AdiantamentoFormComponent } from './adiantamento-form/adiantamento-form.component';
import { AdiantamentoFotoComponent } from './adiantamento-foto/adiantamento-foto.component';
import { AdiantamentoListComponent } from './adiantamento-list/adiantamento-list.component';
import { AdiantamentoRoutingModule } from './adiantamento-routing.module';
import { AdiantamentoRptComponent } from './adiantamento-rpt/adiantamento-rpt.component';
import { AdiantamentoStorageComponent } from './adiantamento-storage/adiantamento-storage.component';
import { AdiantamentoViewComponent } from './adiantamento-view/adiantamento-view.component';
import { EtiquetaProtocoloDlgComponent } from './etiqueta-protocolo-dlg/etiqueta-protocolo-dlg.component';
import { NotaAdiantamentoDlgComponent } from './nota-adiantamento-dlg/nota-adiantamento-dlg.component';
import { DividerModule } from 'primeng/divider';
import { AdiantamentoControleInternoDlgComponent } from './adiantamento-controle-interno-dlg/adiantamento-controle-interno-dlg.component';
import { AdiantamentoParecerDlgComponent } from './adiantamento-parecer-dlg/adiantamento-parecer-dlg.component';
import { AdiantamentoPrestacaoDlgComponent } from './adiantamento-prestacao-dlg/adiantamento-prestacao-dlg.component';

@NgModule({
  declarations: [
    AdiantamentoListComponent,
    AdiantamentoFormComponent,
    AdiantamentoViewComponent,
    AdiantamentoDocumentoComponent,
    AdiantamentoStorageComponent,
    NotaAdiantamentoDlgComponent,
    AdiantamentoRptComponent,
    EtiquetaProtocoloDlgComponent,
    AdiantamentoFotoComponent,
    AdiantamentoControleInternoDlgComponent,
    AdiantamentoParecerDlgComponent,
    AdiantamentoPrestacaoDlgComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AdiantamentoRoutingModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    ConfirmDialogModule,
    IMaskModule,
    ToastModule,
    RadioButtonModule,
    FileUploadModule,
    FieldsetModule,
    DividerModule
  ],
  providers: [MessageService, ConfirmationService]
})
export class AdiantamentoModule { }
