import { Component, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  BaseResourceFormComponent, LoginContabil,
  GlobalService, FuncaoService, Filtro, DateFormatPipe, PreLiquidacao
} from 'eddydata-lib';
import { PreLiquidacaoService } from '../service/pre-liquidacao.service';

@Component({
  selector: 'lib-pre-liquidacao-view',
  templateUrl: './pre-liquidacao-view.component.html'
})
export class PreLiquidacaoViewComponent extends BaseResourceFormComponent<PreLiquidacao, LoginContabil> {

  /**
   * Declaração de variáveis
   */


  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected preliquidacaoService: PreLiquidacaoService) {
    super(new PreLiquidacao(), injector, PreLiquidacao.converteJson, preliquidacaoService);
  }


  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      parcela: [null, [Validators.required]],
      valor_liquidado: 0,
      data_liquidacao: [null, [Validators.required]],
      data_vencimento: [null, [Validators.required]],
      historico: [null, [Validators.required]],
      mes: [null, [Validators.required]],
      documento: [null, [Validators.required]],    
      anulacao: [null, [Validators.required, Validators.minLength(2)]],
      impresso: [null, [Validators.required]],
      orgao: [null, [Validators.required]],
      empenho: this.fb.group({
        numero: [null, [Validators.required]],
        exercicio: this.fb.group({
          ano: [null, [Validators.required]],
        })

      }),
      exercicio: [null, [Validators.required]],
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations:
        'empenho,empenho.modalidade,empenho.ficha,empenho.ficha.acao,empenho.favorecido,empenho.subelemento,empenho.exercicio'
    };
  }

  protected relations(): string {
    return;
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['numero$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['empenho.numero', 'valor_liquidacao'],
      date: ['data_liquidacao'],
      text: ['empenho.favorecido.nome', 'empenho.subelemento.nome'],
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: PreLiquidacao): Observable<PreLiquidacao> {
    return null;
  }

  protected afterLoad() {
    this.entidade.data_liquidacao = new DateFormatPipe().transform(this.entidade.data_liquidacao, []);
    this.entidade.data_vencimento = new DateFormatPipe().transform(this.entidade.data_vencimento, []);
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: PreLiquidacao): void {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }
}
