import { DatePipe } from '@angular/common';
import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { AssinaturaService, Coluna, Exercicio, ExercicioService, FormatoExportacao, FuncaoService, GlobalService, Login, OrgaoService, Relatorio } from 'eddydata-lib';
import { MessageService, SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as toastr from 'toastr';
import { EmpenhoExtraService } from '../service/empenho-extra.service';

@Component({
  selector: 'app-empenho-extra-em-aberto-dlg',
  templateUrl: './empenho-extra-em-aberto-dlg.component.html'
})
export class EmpenhoExtraEmAbertoDlgComponent implements OnInit, OnDestroy {

  protected datepipe: DatePipe;
  public ptBR: any;
  public ano: Exercicio;
  public listaExercicios: Array<Exercicio>;
  public listaOrgaos: SelectItem[];
  public orgaos: number[] = [];
  public dataInicio: Date;
  public dataFinal: Date;
  public listFormato: FormatoExportacao[] = ['pdf', 'xlsx', 'csv'];
  public formato: FormatoExportacao = 'pdf';
  protected unsubscribe: Subject<void> = new Subject();
  protected funcaoService: FuncaoService;

  public imaskNumero = {
    mask: Number,
    scale: 0,
    signed: false
  };

  @Input() login: Login;

  constructor(
    protected messageService: MessageService,
    protected injector: Injector,
    private globalService: GlobalService,
    protected exercicioService: ExercicioService,
    protected assinaturaService: AssinaturaService,
    protected orgaoService: OrgaoService,
    private empenhoExtraService: EmpenhoExtraService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.ano = this.login.exercicio;
    this.dataInicio = new Date();
    this.dataFinal = new Date();
    this.orgaos.push(this.login.orgao.id);
    this.orgaoService.filtrar(0, -1, { orderBy: 'codigo' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaOrgaos = [];
        for (const model of dados.content) {
          this.listaOrgaos.push({ label: `${model.codigo} - ${model.nome}`, value: model.id });
        }
      });
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaExercicios = dados.content;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  async imprimir() {

    if (this.dataInicio > this.dataFinal) {
      toastr.error('Data final está maior que a inicial!')
    }

    if (!this.dataInicio) {
      toastr.error('Preencha a data inicío!')
    }

    if (!this.dataFinal) {
      toastr.error('Preencha a data final')
    }

    if (!this.orgaos.length) {
      toastr.error('Preencha pelo menos um orgão!')
    }

    const lista = await this.empenhoExtraService.empenhoExtraEmAberto(this.dataInicio, this.dataFinal, this.ano.id, this.orgaos).toPromise();
    if (lista.length) {

      let listaFiltrada = lista.filter((item) => {
        let sum1 = Number(item.valor_retido).toFixed(2);
        let sum2 = Number(item.valor_pago).toFixed(2);
        let sum3 = Number(item.valor_anulado).toFixed(2);
        let sum4 = Number(item.valor_liquido).toFixed(2);
        if (+sum3 < 0) {
          sum3 = (+sum3 * -1).toFixed(2);
        }
        return +Number(+sum1 + +sum2 + +sum3).toFixed(2) != +sum4;
      })

      if (this.formato === 'pdf') {
        Relatorio.imprimir('EMISSÃO DE EMPENHOS EXTRAS EMPENHOS EXTRAS COM ORIGEM EM ABERTO', this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
          listaFiltrada, this.obterColunasRelatorioPdf(), 'landscape', 'EMISSÃO DE EMPENHOS EXTRAS EMPENHOS EXTRAS COM ORIGEM EM ABERTO', this.larguraColunas());
      } else {
        new FuncaoService().exportar(this.formato, listaFiltrada, 'EMISSÃO DE EMPENHOS EXTRAS EMPENHOS EXTRAS COM ORIGEM EM ABERTO', this.obterColunasRelatorio());
      }

    } else {
      toastr.warning('Nenhum registro encontrado!')
    }
  }

  protected obterColunasRelatorioPdf(filtro?: boolean): Coluna[] {

    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Data', coluna: 'data_extra', alignment: 'center', tipo: 'Date', fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Empenho Extra', coluna: 'empenho_extra', tipo: 'Number', alignment: 'center', fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Ficha', coluna: 'ficha_extra', tipo: 'Number', alignment: 'center', fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'CNPJ/CPF Extra', coluna: 'favorecido_extra', alignment: 'center', tipo: 'String', fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Valor Líquido', coluna: 'valor_liquido', tipo: 'Number', decimais: 2, alignment: 'left', fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Valor Retenção', coluna: 'valor_retido', alignment: 'left', tipo: 'Number', decimais: 2, fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Valor Pago', coluna: 'valor_pago', alignment: 'left', tipo: 'Number', decimais: 2, fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Saldo', coluna: 'saldo', alignment: 'left', tipo: 'Number', decimais: 2, fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Empenho', coluna: 'empenho', alignment: 'center', tipo: 'Number', fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Função', coluna: 'funcao', alignment: 'center', tipo: 'String', fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'SubFunção', coluna: 'subfuncao', alignment: 'center', tipo: 'String', fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Programa', coluna: 'programa', alignment: 'center', tipo: 'String', fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Projeto', coluna: 'projeto', alignment: 'center', tipo: 'String', fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Despesa', coluna: 'despesa', alignment: 'center', tipo: 'String', fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Data Empenho', coluna: 'data_empenho', alignment: 'center', tipo: 'Date', fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Valor Anulado', coluna: 'valor_anulado', alignment: 'left', tipo: 'Number', decimais: 2, fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Exercício Origem', coluna: 'exercicio_origem', alignment: 'center', tipo: 'Number', fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'CNPJ/CPF', coluna: 'favorecido_empenho', alignment: 'center', tipo: 'String', fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Cód. Contábil', coluna: 'conta_contabil', alignment: 'center', tipo: 'String', fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Data Retenção', coluna: 'data_retencao', alignment: 'center', tipo: 'Date', fontSize: 6, adicionaisBody: { border: [false, false, false, false] } });

    return retorno;
  }

  protected obterColunasRelatorio(filtro?: boolean): Coluna[] {

    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Data', coluna: 'data_extra', alignment: 'center', tipo: 'Date', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Empenho Extra', coluna: 'empenho_extra', tipo: 'Number', alignment: 'center', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Ficha', coluna: 'ficha_extra', tipo: 'Number', alignment: 'center', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'CNPJ/CPF Extra', coluna: 'favorecido_extra', alignment: 'center', tipo: 'String', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Valor Líquido', coluna: 'valor_liquido', tipo: 'Number', decimais: 2, alignment: 'left', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Valor Retenção', coluna: 'valor_retido', alignment: 'left', tipo: 'Number', decimais: 2, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Valor Pago', coluna: 'valor_pago', alignment: 'left', tipo: 'Number', decimais: 2, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Saldo', coluna: 'saldo', alignment: 'left', tipo: 'Number', decimais: 2, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Empenho', coluna: 'empenho', alignment: 'center', tipo: 'Number', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Função', coluna: 'funcao', alignment: 'center', tipo: 'String', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'SubFunção', coluna: 'subfuncao', alignment: 'center', tipo: 'String', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Programa', coluna: 'programa', alignment: 'center', tipo: 'String', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Projeto', coluna: 'projeto', alignment: 'center', tipo: 'String', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Despesa', coluna: 'despesa', alignment: 'center', tipo: 'String', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Recurso', coluna: 'recurso', alignment: 'center', tipo: 'String', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Aplicação', coluna: 'aplicacao', alignment: 'center', tipo: 'String', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Aplicação Variável', coluna: 'aplicacao_variavel', alignment: 'center', tipo: 'String', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Data Empenho', coluna: 'data_empenho', alignment: 'center', tipo: 'Date', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Valor Anulado', coluna: 'valor_anulado', alignment: 'left', tipo: 'Number', decimais: 2, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Exercício Origem', coluna: 'exercicio_origem', alignment: 'center', tipo: 'Number', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'CNPJ/CPF', coluna: 'favorecido_empenho', alignment: 'center', tipo: 'String', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Cód. Contábil', coluna: 'conta_contabil', alignment: 'center', tipo: 'String', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Data Retenção', coluna: 'data_retencao', alignment: 'center', tipo: 'Date', adicionaisBody: { border: [false, false, false, false] } });

    return retorno;
  }

  protected larguraColunas(): (string | number)[] {
    return ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

}
