import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BBLicitacoesEComponent } from './bb-licitacoes-e.component';

@NgModule({
    declarations: [
        BBLicitacoesEComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
    ],
    exports: [
        BBLicitacoesEComponent
    ],
    providers: [MessageService, ConfirmationService]
})
export class BBLicitacoesEModule { }
