import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { CompraService } from '../service/compra.service';
import {
  BaseResourceListComponent, Coluna, Compra, EmailService, Filtro, FormatoExportacao, FuncaoService, GlobalService, LoginContabil, OrgaoAssinaturaService
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { DatePipe } from '@angular/common';
import * as toastr from 'toastr';
import { Panel } from 'primeng/panel';
import { ParametroCompraService } from 'eddydata-lib';
import { NotaCompra } from '../../relatorio-compra/nota-compra-rpt/nota-compra-rpt';
import { CompraItemService } from '../service/compra-item.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { NotaCompraRptComponent } from '../../relatorio-compra/nota-compra-rpt/nota-compra-rpt.component';
import { CompraTxtComponent } from '../compra-txt/compra-txt.component';

@Component({
  selector: 'app-compra-list',
  templateUrl: './compra-list.component.html',
  styleUrls: [
    './compra-list.component.css'
  ]
})
export class CompraListComponent extends BaseResourceListComponent<Compra, LoginContabil> implements OnInit {

  /**
   * Declaração de variáveis
   */
  public ptBR: any;

  protected datepipe: DatePipe;

  public listaFiltros: Coluna[] = [];
  public menuFiltros: MenuItem[] = [];

  @ViewChild('panel') painel: Panel;

  public tipoEmail: string[] = ['R', 'F'];
  public compraSelecionada: Compra;

  public mes: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public funcaoService: FuncaoService,
    public globalService: GlobalService,
    private paramentroCompraService: ParametroCompraService,
    private emailService: EmailService,
    private confirmationPriService: ConfirmationService,
    private assinaturaService: OrgaoAssinaturaService,
    protected compraItemService: CompraItemService,
    private compraService: CompraService) {
    super(compraService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================
  
  protected relations(): string {
    return 'orgao,favorecido,ficha,ficha.despesa,requerente,subelemento,empenho,empenho.exercicio,rcms,empenho.liquidacoes.pagamentos,' +
      'licitacao,prazo,prazo_entrega,prazo_pagamento';
  }

  protected condicoesGrid(): {} {
    let parametros = {
      ['orgao.id']: this.login.orgao.id,
      ['exercicio.id']: this.login.exercicio.id,
      ['simplificado']: true,
    };
    if (!(this.login.usuario.administrador && this.login.usuario.sistemas_administrador?.includes('compras')) && this.login.estoque?.id && !this.funcaoService.podeIncluir(this.login, '/visualizar-todas-compras'))
      parametros['estoque.id'] = this.login.estoque.id;
    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['data_compra$DESC', 'numero$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {};
  }

  protected afterInit(): void { }

  public beforeInit() {
    this.ptBR = new GlobalService().obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.usarExtendido = true;
    this.usarFiltroPersonalizado = true;
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  protected acaoRemover(model: Compra): Observable<Compra> {
    return null;
  }

  //***********************FILTRO PERSONALIZADO *************************//

  public obterColunasfiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Data Compra', coluna: 'data_compra', tipo: 'Date', alignment: 'center', padrao: true, filtro2: new Date() });
    retorno.push({ titulo: 'Nº OF', coluna: 'numero', alignment: 'center', tipo: 'Number', padrao: true });
    retorno.push({ titulo: 'Nº Requisição', coluna: 'rcms.numero', alignment: 'center', tipo: 'Number' });
    retorno.push({ titulo: 'Nº Empenho', coluna: 'empenho.numero', alignment: 'center', tipo: 'Number' });
    retorno.push({ titulo: 'Fornecedor', coluna: 'favorecido.nome', tipo: 'String' });
    retorno.push({ titulo: 'Modalidade', coluna: 'modalidade.nome', tipo: 'String' });
    retorno.push({ titulo: 'Ficha', coluna: 'ficha.numero', alignment: 'center', tipo: 'String', cols: 2 });
    retorno.push({ titulo: 'Subelemento', coluna: 'subelemento.codigo', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Despesa', coluna: 'ficha.despesa.codigo', alignment: 'center', tipo: 'String', cols: 2 });
    retorno.push({ titulo: 'Recurso', coluna: 'ficha.recurso.codigo', alignment: 'center', tipo: 'String', cols: 2 });
    retorno.push({ titulo: 'Requerente', coluna: 'requerente.nome', tipo: 'String', cols: 4, padrao: true });
    retorno.push({
      titulo: 'Contrato', coluna: 'contrato.numero', alignment: 'center', tipo: 'String', cols: 2,
      mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }]
    });
    retorno.push({ titulo: 'Licitação', coluna: 'licitacao.numero', alignment: 'center', tipo: 'String', cols: 3, mask: '0000/0000' });
    retorno.push({
      titulo: 'Processo', coluna: 'processo', alignment: 'center', tipo: 'String', cols: 2,
      mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }], notUnmask: true
    });
    retorno.push({ titulo: 'Valor Total', coluna: 'valor_total_item', tipo: 'Number' });
    retorno.push({ titulo: 'Itens da OF', coluna: 'itens.produto_unidade.produto.nome', tipo: 'String' });
    retorno.push({ titulo: 'Com Requisição', coluna: 'rcms.id$not_null', tipo: 'Boolean' });
    retorno.push({ titulo: 'Com Empenho', coluna: 'empenho.id$not_null', tipo: 'Boolean' });
    retorno.push({ titulo: 'Sem Empenho', coluna: 'empenho.id$null', tipo: 'Boolean' });
    retorno.push({ titulo: 'Com Liquidação', coluna: 'empenho.liquidacoes.id$null', tipo: 'Boolean' });
    retorno.push({ titulo: 'Sem Ficha de Despesa', coluna: 'ficha.id$null', tipo: 'Boolean' });
    retorno.push({ titulo: 'Parcialmente Liquidada', coluna: 'valid_parcialmente_liquidado', tipo: 'Boolean' });
    retorno.push({ titulo: 'Totalmente Liquidada', coluna: 'valid_total_liquidado', tipo: 'Boolean' });
    retorno.push({ titulo: 'Parcialmente Paga', coluna: 'valid_parcialmente_pago', tipo: 'Boolean' });
    retorno.push({ titulo: 'Totalmente Paga', coluna: 'valid_total_pago', tipo: 'Boolean' });
    retorno.push({ titulo: 'Parcialmente Anulada', coluna: 'valid_parcialmente_anulado', tipo: 'Boolean' });
    retorno.push({ titulo: 'Totalmente Anulada', coluna: 'valid_total_anulado', tipo: 'Boolean' });
    retorno.push({ titulo: 'Excluída', coluna: 'excluido', tipo: 'Boolean' });

    return retorno;
  }

  protected podeAlterar(item: Compra): boolean {
    return !item.excluido && !item?.empenho?.id && this.login.sistema !== 'licitacao' && this.login.sistema != 'controle-interno';
  }

  //***********************FILTRO PERSONALIZADO*************************//

  public verificaPago(item: Compra, cor?: Boolean) {
    let valorEmpenho = +item['empenho']?.valor_empenho
    let valorPago = 0

    item['empenho']?.liquidacoes?.forEach(liquidacao => {
      liquidacao['pagamentos']?.forEach(pg => valorPago += +pg?.valor_pago)
    });

    if (!cor) {
      if (valorPago === valorEmpenho)
        return 'Pago'

      if (valorPago > 0)
        return 'Pago parcialmente'

    } else {

      if (valorPago > 0 && valorPago !== valorEmpenho) return 'info'
      if (item['situacao'] === 'LIQUIDADO') return 'primary'
      if (item['situacao'] === 'LIQUIDADE_PARCIAL') return 'info'
      else return 'warning'
    }
  }

  public tituloStatus(item: Compra) {
    if (item.excluido)
      return 'Excluído';
    if (item['situacao'] === 'EMPENHADO')
      return 'Empenhado';
    if (item.empenho?.liquidacoes) {
      let status = this.verificaPago(item)

      if (status)
        return status
    }
    if (item['situacao'] === 'LIQUIDADO_PARCIAL')
      return 'Parcialmente Liquidado';
    if (item['situacao'] === 'LIQUIDADO')
      return 'Liquidado';
    if (item['situacao'] === 'ANULADO')
      return 'Empenhado';
    if (item['situacao'] === 'ANULADO_PARCIAL')
      return 'Empenhado';
    return 'Aberto';
  }

  public corStatus(item: Compra) {
    let cor = 'success';
    if (item.excluido)
      cor = 'danger';
    else if (item['situacao'] === 'EMPENHADO')
      cor = 'warning';
    else if (item.empenho?.liquidacoes)
      cor = this.verificaPago(item, true)
    else if (item['situacao'] === 'LIQUIDADO_PARCIAL')
      cor = 'info';
    else if (item['situacao'] === 'LIQUIDADO')
      cor = 'primary';
    let ngCor = {};
    ngCor[`badge-${cor}`] = true;
    return ngCor;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Num./Exercício', coluna: 'numero', alignment: 'center' },
      { titulo: 'Data', coluna: 'data_compra', alignment: 'center' },
      { titulo: 'Requisição', coluna: 'rcms.numero', alignment: 'center' },
      { titulo: 'Fornecedor', coluna: 'favorecido.nome' },
      { titulo: 'Ficha', coluna: 'ficha.numero', alignment: 'center' },
      { titulo: 'Subelemento', coluna: 'subelemento.codigo', alignment: 'center' },
      { titulo: 'Requerente', coluna: 'requerente.nome' },
      { titulo: 'Valor Total', coluna: 'total_compra', decimais: 5, alignment: 'right' },
      { titulo: 'Situação', coluna: 'situacao_titulo', alignment: 'center' }
    ];
  }

  public selecionar(compra: Compra, notaCompra: NotaCompraRptComponent, op?: OverlayPanel, event?: any) {
    this.compraSelecionada = compra;
    if (notaCompra) {
      notaCompra.show();
    } else {
      this.tipoEmail = ['R', 'F'];
      op.toggle(event);
    }
  }

  public copiar(compra: Compra, compraTxt: CompraTxtComponent) {
    this.compraService.obterDetalhada(compra.id, compra.exercicio.id, compra.orgao.id)
      .subscribe((detalhada) => {
        this.compraSelecionada = detalhada;
        compraTxt.show();
      }, error => toastr.error(`Foi identificado um erro na impressão da ordem de fornecimento.`));
  }

  public email() {
    if (!this.tipoEmail.includes('R') && !this.tipoEmail.includes('F'))
      return;
    this.compraService.obter({
      id: this.compraSelecionada.id,
      'orgao.id': this.compraSelecionada.orgao.id,
      'exercicio.id': this.compraSelecionada.exercicio.id,
      relations: ['ficha', 'modalidade', 'licitacao', 'subelemento', 'operador',
        'convenio', 'favorecido.tipo', 'contrato', 'ficha.despesa', 'ficha.acao', 'ficha.recurso', 'contrato_aditamento',
        'ficha.executora.unidade', 'ficha.aplicacao', 'exercicio', 'orgao', 'contrato', 'requerente', 'rcms.setor', 'prazo', 'empenho', 'empenho.exercicio', 'estoque'
      ].join(',')
    }).subscribe((compra) => {
      if (!compra) {
        toastr.warning(`Ordem de fornecimento não encontrada.`)
        return;
      }
      this.compraItemService.filtrar(1, -1, {
        relations: [
          'produto_unidade', 'produto_unidade.produto', 'produto_unidade.unidade'], 'compra.id': this.compraSelecionada.id, orderBy: 'ordem$ASC'
      }).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
        compra.itens = res.content;

        let mensagem = '';
        if (this.tipoEmail.includes('R'))
          mensagem += `<br />Requerente ${compra.requerente.nome}(${compra.requerente.email})`;
        if (this.tipoEmail.includes('F'))
          mensagem += `<br />Fornecedor ${compra.favorecido.nome}(${compra.favorecido.email})`;

        if (mensagem.length === 0)
          return;

        this.confirmationPriService.confirm({
          message: `Encaminhar por e-mail a ordem de fornecimento para: ${mensagem}?`,
          header: `Envio de Ordem de Fornecimento - Nº: ${compra.numero}`,
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Sim',
          rejectLabel: 'Não',
          key: 'compra',
          accept: () => {
            this.enviarEmail(compra, true);
          }
        });
      }, error => this.funcaoService.acaoErro(error));
    }, (error) => toastr.error(`Foi identificado um erro na impressão da ordem de fornecimento.`));
  }

  public async enviarEmail(compra: Compra, validar?: boolean) {
    const anexo = (await new NotaCompra(this.assinaturaService, this.compraService, this.login).exportar([compra]))

    this.paramentroCompraService.obter({
      'orgao.id': compra.orgao.id
    }).subscribe((parametroCompra) => {
      let mensagem = '';
      if (!parametroCompra)
        return;
      if (parametroCompra.mensagem_of)
        mensagem += parametroCompra.mensagem_of;

      if (((validar && this.tipoEmail.includes('R')) || (!validar && parametroCompra.email_requerente)) && compra.requerente.email) {
        let subject = `Envio de Ordem de Fornecimento - Nº OF: ${compra.numero}`;
        let message = '<p><b>Ordem de Fornecimento enviado para o requerente:  </b>' + compra.requerente.nome
          + '<br><b>E-mail do requerente: </b>' + compra.requerente.email
          + '<br><b>Número da OF: </b>' + compra.numero
          + '<br><b>Data: </b>' + this.funcaoService.converteDataBR(new Date()) + '</p>';
        message += mensagem ? '<br />' + mensagem : '';

        this.emailService.enviar({ titulo: subject, corpo: mensagem, destinos: [{ nome: compra.requerente.nome, email: compra.requerente.email, }], anexos: [{ arquivo: anexo, nome: `OF ${compra.numero} - ${compra.exercicio.ano}.pdf` }] })
          .subscribe((data) => {
            toastr.success(`E-mail encaminhado para o requerente ${compra.requerente.nome}(${compra.requerente.email})`)
          }, (error) => toastr.error(`Não foi possivel encaminhar o e-mail para requerende ${compra.requerente.nome}(${compra.requerente.email})`));
      }

      if (((validar && this.tipoEmail.includes('F')) || (!validar && parametroCompra.email_favorecido)) && compra.favorecido.email) {
        let subject = `Envio de Ordem de Fornecimento - Nº OF: ${compra.numero}`;
        let subjectRetorno = `Envio de Pré-empenho - Nº OF: ${compra.numero}`
        let message = '<p><b>Ordem de Fornecimento enviado para o fornecedor:  </b>' + compra.favorecido.nome
          + '<br><b>E-mail do fornecedor: </b>' + compra.favorecido.email
          + '<br><b>Número da OF: </b>' + compra.numero
          + '<br><b>Data: </b>' + this.funcaoService.converteDataBR(new Date()) + '</p>';

        this.emailService.enviar({ titulo: subject, corpo: mensagem, destinos: [{ nome: compra.favorecido.nome, email: compra.favorecido.email }], anexos: [{ arquivo: anexo, nome: `OF ${compra.numero} - ${compra.exercicio.ano}.pdf` }] })
          .subscribe((data) => {
            toastr.success(`E-mail encaminhado para o fornecedor ${compra.favorecido.nome}(${compra.favorecido.email})`)
            if (parametroCompra.email_retorno) {
              this.emailService.enviar({ titulo: subjectRetorno, corpo: message, destinos: [{ nome: '', email: parametroCompra.email_retorno }] })
                .subscribe((data) => {
                  toastr.success(`E-mail de retorno encaminhado para o e-mail ${parametroCompra.email_retorno}`)
                })
            }
          }, (error) => toastr.error(`Não foi possivel encaminhar o e-mail para fornecedor ${compra.favorecido.nome}(${compra.favorecido.email})`));
      }
    });
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = this.relations();
    parametros['orderBy'] = this.ordenacaoGrid().join(',');
    this.compraService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE COMPRAS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem compras', ['auto', 'auto', 'auto', '*', 'auto', 'auto', '*', 'auto', 'auto'], lista.content.map((l) => {
                l.situacao_titulo = this.tituloStatus(l);
                l.numero = `${("00000" + l.numero).slice(-5)}/${l.exercicio.ano}`
                return l;
              }));
          } else {
            this.exportar(formato, lista.content.map((l) => {
              l.situacao_titulo = this.tituloStatus(l);
              return l;
            }));
          }
        },
        () => alert('erro ao retornar lista')
      );
  }
}
