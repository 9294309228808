import { NgModule } from '@angular/core';

import { LiquidacaoRoutingModule } from './liquidacao-routing.module';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { IMaskModule } from 'angular-imask';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { LiquidacaoListComponent } from './liquidacao-list/liquidacao-list.component';
import { LiquidacaoViewComponent } from './liquidacao-view/liquidacao-view.component';
import { MessageService, ConfirmationService } from 'primeng/api';
import { LiquidacaoFormComponent } from './liquidacao-form/liquidacao-form.component';
import { RetencaoComponent } from './retencao/retencao.component';
import { DatePipe } from '@angular/common';
import { RadioButtonModule } from 'primeng/radiobutton';
import { LiquidacaoRptComponent } from './liquidacao-rpt/liquidacao-rpt.component';
import { LiquidacaoAnulacaoComponent } from './liquidacao-anulacao/liquidacao-anulacao.component';
import { SharedModule, SimplesNacionalDlgModule } from 'eddydata-lib';
import { GpsLiquidacaoDlgComponent } from './gps-liquidacao-dlg/gps-liquidacao-dlg.component';
import { NotaLiquidacaoDlgComponent } from './nota-liquidacao-dlg/nota-liquidacao-dlg.component';
import { GrdLiquidacaoDlgComponent } from './grd-liquidacao-dlg/grd-liquidacao-dlg.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { PreLiquidacaoModule } from '../pre-liquidacao/pre-liquidacao.module';
import { InplaceModule } from 'primeng/inplace';
import { RetencaoRptComponent } from './retencao-rpt/retencao-rpt.component';
import { TooltipModule } from 'primeng/tooltip';
import { RetencaoReinfComponent } from './retencao-reinf/retencao-reinf.component';
import { ItensCompraComponent } from './itens-compra-dlg/itens-compra-dlg.component';
import { LiquidacaoMovimentoEstoqueDlgModule } from '../liquidacao-movimento-estoque/liquidacao-movimento-estoque-dlg.module';
import { EmpenhoModule } from '../empenho/empenho.module';

@NgModule({
  declarations: [
    LiquidacaoListComponent,
    LiquidacaoViewComponent,
    LiquidacaoFormComponent,
    RetencaoComponent,
    RetencaoReinfComponent,
    LiquidacaoRptComponent,
    RetencaoRptComponent,
    LiquidacaoAnulacaoComponent,
    GpsLiquidacaoDlgComponent,
    NotaLiquidacaoDlgComponent,
    GrdLiquidacaoDlgComponent,
    ItensCompraComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    LiquidacaoRoutingModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    InplaceModule,
    RadioButtonModule,
    FieldsetModule,
    ConfirmDialogModule,
    PreLiquidacaoModule,
    TooltipModule,
    SimplesNacionalDlgModule,
    LiquidacaoMovimentoEstoqueDlgModule,
    EmpenhoModule
  ],
  exports: [
    ItensCompraComponent
  ],
  providers: [MessageService, DatePipe, ConfirmationService]
})
export class LiquidacaoModule { }
