import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, ParametroContabil } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ParametroContabilService extends BaseResourceService<ParametroContabil> {

  constructor(
    protected injector: Injector
  ) {
    super(`parametros-contabil`, injector);
  }

  public obter(filtros?: {}, cidade?: number): Observable<ParametroContabil> {
    let parametros = '';
    if (filtros) {
      // tslint:disable-next-line: forin
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + filtros[key];
      }
    }
    return this.http.get<ParametroContabil>(
      `${(cidade ? cidade : this.login.cidade.id)}/${this.api}/obter${parametros}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
