import { Component, Input } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Login, NotaExplicativa, NotaExplicativaStorage } from "eddydata-lib";
import { NotaExplicativaStorageService } from "../../service/nota-explicativa-storage.service";
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'app-nota-explicativa-storage',
  templateUrl: './nota-explicativa-storage.component.html'
})
export class NotaExplicativaStorageComponent {

  @Input() public login: Login;
  @Input() public entidade: NotaExplicativa;
  @Input() public arquivos: NotaExplicativaStorage[];

  public uploadedFiles: any[] = [];
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected confirmationService: ConfirmationService,
    protected storageService: NotaExplicativaStorageService
  ) { }

  public async onUpload(event: any, fileUpload: any) {
    if (!event.files || event.files.length === 0) {
      toastr.warning('Selecione o(s) arquivo(s)');
      return;
    }
    try {
      for (const file of event.files) {
        this.uploadedFiles.push(file);
      }
      this.storageService.upload(this.uploadedFiles, this.login.orgao.id, this.entidade.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          for (const item of res) {
            item.data_cadastro = new Date();
            item['imagem_base64'] = event.base64
            this.arquivos.unshift(item);
          }
        }, (res) => {
          toastr.error(res.error.payload);
          this.uploadedFiles.length = 0;
        });
    } catch (ex) {
      toastr.error(ex.message ? ex.message : ex);
    }
    this.uploadedFiles = []
    fileUpload.clear();
    $('#dialogUpload').modal('hide');
  }

  public async remover(item: NotaExplicativaStorage) {
    if (!item) return
    this.confirmationService.confirm({
      message: 'Deseja realmente remover o arquivo?',
      header: 'Exclusão',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      key: 'arquivo',
      accept: () => {
        this.storageService.remover(item.id)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Arquivo removido com sucesso!', 'Exclusão');
            this.arquivos.splice(this.arquivos.indexOf(item), 1);
          }, (err) => toastr.error(err.error.payload));
      }
    });
  }
}