import { Injectable, Injector } from '@angular/core';
import { Aliquota, BaseResourceService } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class AliquotaService extends BaseResourceService<Aliquota> {

  constructor(
    protected injector: Injector
  ) {
    super(`aliquotas`, injector);
  }

}
