import { BaseResourceModel } from '../../models/base-resource.model';
import { Memorial } from '../licitacao/memorial.model';
import { Compra } from './compra.model';
import { Rcms } from './rcms.model';

// tslint:disable: variable-name
export class TabelaDescontoCompra extends BaseResourceModel {
  constructor(
    public compra?: Compra,
    public memorial?: Memorial,
    public rcms?: Rcms,
  ) {
    super();
  }
  static converteJson(json: any): TabelaDescontoCompra {
    return Object.assign(new TabelaDescontoCompra(), json);
  }
}
