import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ProdutoService, ProdutoUnidadeService } from 'almoxarifado-lib';
import {
  Aliquota,
  BaseResourceItemsComponent, Compra, CompraItem, EddyAutoComplete, FuncaoService,
  GlobalService, Produto, ProdutoUnidade
} from 'eddydata-lib';
import { takeUntil } from 'rxjs/operators';
import { CompraItemService } from '../service/compra-item.service';
import * as toastr from 'toastr';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ContratoItemService } from '../../contrato/service/contrato-item.service';
declare var $: any;
@Component({
  selector: 'app-compra-item',
  templateUrl: './compra-item.component.html'
})
export class CompraItemComponent extends BaseResourceItemsComponent<CompraItem> implements OnChanges {

  @ViewChild('inpQuantidade') public inpQuantidade: ElementRef;

  public imaskValor = {
    mask: Number,
    scale: 6,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  public imaskValorTotal = {
    mask: Number,
    scale: 6,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  @Input() somenteVisualizar: boolean = false;
  @Input() alterarValorTotal: boolean = false;
  @Output() visualizaDescricaoItens: EventEmitter<boolean> = new EventEmitter();

  public produtoAutoComplete: EddyAutoComplete<Produto>;

  public produtoUnidades: ProdutoUnidade[] = [];

  public mostrarDescricao: boolean = false;

  @Input() public compra: Compra;

  constructor(private produtoUnidadeService: ProdutoUnidadeService,
    private produtoService: ProdutoService,
    public funcaoService: FuncaoService,
    public globalService: GlobalService,
    public compraItemService: CompraItemService,
    public contratoItemService: ContratoItemService) {
    super(new CompraItem(), compraItemService);
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  protected afterInit(): void { }

  protected afterEdit(item: CompraItem) {
    item.valor_desconto = 0;
    item.valor_icmsipi = 0;
    item.valorTotal =  item.valorTotal ?? 0 ;
    item['valorTotalAnterior'] = item.valorTotal;
    this.carregarAutoCompletes();
    if (!item.produto_unidade)
      item.produto_unidade = new ProdutoUnidade();
    if (!item.ordem)
      item.ordem = this.lista.length + 1;

    //caso editando o item, não irá recalcular o valor referencia se não alterar o item no autocomplete
    item['afterEdit'] = true;

    this.carregarUnidades(item);
    if (this.compra?.contrato?.id && !this.compra?.licitacao?.tabela_desconto) {
      this.contratoItemService.obterSaldoProdutoContrato(this.login.orgao.id, this.compra.contrato.id, item.produto_unidade.id)
        .subscribe((data) => {
          if (data) {
            item['saldo_qtd'] = data['saldo_qtd'];
          }
        });
    }
  }

  protected validSave(item: CompraItem): boolean {
    if (!item.quantidade) {
      toastr.warning('Informe a quantidade para o item!');
      return false;
    }
    if (!item.valor_unitario) {
      toastr.warning('Informe o valor unitario para o item!');
      return false;
    }

    let informado = this.lista.find((i) => i.produto_unidade.id === item.produto_unidade.id && (!i['editavel']));
    if (informado && !item.alterar_total) {
      toastr.warning('Item já informado!');
      return false;
    }

    if (item['saldo_qtd'] && (+item['saldo_qtd'] - +item.quantidade) < 0 && !this.compra?.contrato?.saldo_valor) {
      toastr.warning(`Quantidade informada para o item ${item.produto_unidade.produto.nome} excede o saldo atual de ${this.funcaoService.convertToBrNumber(item['saldo_qtd'], 5)}`);
      return false;
    }
    return true;
  }

  protected beforeSave(item: CompraItem): void {
    item.quantidade = item.quantidade ? item.quantidade : 0;
    item.valor_unitario = item.valor_unitario ? item.valor_unitario : 0;
    // item.quantidade = item['valorTotal'] / item.valor_unitario;
    //   this.lista.map(i => {
    //     if (item.produto_unidade.produto.id === i.produto_unidade.produto.id) {
    //       i.quantidade = item.quantidade
    //     }
    //   })
    item.valor_desconto = item.valor_desconto ? item.valor_desconto : 0;
    item.valor_icmsipi = item.valor_icmsipi ? item.valor_icmsipi : 0;
    item['valorTotal'] = (item.quantidade * item.valor_unitario) + +item.valor_icmsipi + +item.valor_desconto;
  }

  public disabled(): boolean {
    if (!this.compra?.subelemento || !this.compra?.subelemento?.codigo || this.somenteVisualizar)
      return true;
    return super.disabled();
  }

  public carregarUnidades(item: CompraItem) {
    if (!item.produto_unidade?.produto)
      return;
    this.produtoUnidadeService.filtrarCodigoCompleto(1, -1, {
      'produto.id': item.produto_unidade.produto.id,
      ativo: true,
      relations: ['produto', 'unidade']
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(async (data) => {
        if (!data)
          return;
        this.produtoUnidades = data.content;
        if (this.produtoUnidades && this.produtoUnidades.length > 0)
          item.produto_unidade = this.produtoUnidades[0];

        if (!item['afterEdit'])
          item.valor_referencia = await this.getBancoPrecoItem(item);
        +item.valor_unitario

        item['afterEdit'] = false
      }, (error) => toastr.error(error.error.payload));
  }


  private carregarAutoCompletes() {
    if (this.compra.subelemento)
      this.produtoAutoComplete = ProdutoService.autoCompleteCodigoCompleto(null, this.produtoService,
        'id', ['codigo', 'nome'], {
        orgao_id: this.login.orgao.id, orderBy: 'codigo,nome', 'valid_sub_elemento$like': `%${this.compra.subelemento.id}%`, 'inativo': false, 'desabilita_compra': false
      }, { number: ['id', 'codigo'], text: ['codigo', 'nome'] });
  }

  public somaItens(): { quantidade?: number, unitario?: number, desconto?: number, icmsipi?: number, valor_total?: number } {

    let soma = {
      quantidade: 0,
      unitario: 0,
      desconto: 0,
      icmsipi: 0,
      valor_total: 0,
      total_valor_referencia: 0
    };

    if (!this.lista || this.lista.length === 0)
      return soma;
    for (let item of this.lista) {
      let quantidade = item.quantidade ? +item.quantidade : 0;
      let unitario = item.valor_unitario ? +item.valor_unitario : 0;
      let desconto = item.valor_desconto ? +item.valor_desconto : 0;
      let icmsipi = item.valor_icmsipi ? +item.valor_icmsipi : 0;
      if (!item['passou']) {
        item['valorTotal'] = (+item.quantidade * +item.valor_unitario) + +icmsipi - +desconto;
        item['passou'] = true;
      }
      soma['quantidade'] += quantidade;
      soma['unitario'] += unitario;
      soma['desconto'] += desconto;
      soma['icmsipi'] += icmsipi;
      soma['valor_total'] += (+quantidade * +unitario) + +icmsipi - +desconto;
      soma.total_valor_referencia += +item.valor_referencia;
    }
    return soma;
  }

  public refazerOrdenacao(event: CdkDragDrop<string[]>) {
    if (this.disabled())
      return;
    moveItemInArray(this.lista, event.previousIndex, event.currentIndex);
    this.lista = this.lista.map((l, i) => {
      l.ordem = i + 1;
      return l;
    });
    this.reordenar();
  }

  public reordenar() {
    this.lista = this.lista.sort((a, b) => a.ordem - b.ordem);
  }

  public campoFoco(): ElementRef {
    return this.inpQuantidade;
  }

  public parametrosProdutos(): {} {
    let parametros = {};
    parametros['inativo'] = false;
    parametros['desabilita_compra'] = false;
    if (this.compra && this.compra.subelemento && this.compra.subelemento.codigo) {
      parametros['valid_sub_elemento$like'] = `%${this.compra.subelemento.id}%`;
    }
    if (this.compra?.processo)
      parametros['unidades.memoriais.licitacao.processo'] = this.compra.processo;
    return parametros;
  }

  @HostListener('window:keydown.control.p', ['$event'])
  public abrirConsultaProduto(event: KeyboardEvent) {
    event.preventDefault();
    if (this.usarAtalho) {
      $('#dialogProdutoUnBusca').modal('show');
    }
  }

  public mostrarDescricaoItens() {
    this.visualizaDescricaoItens.emit(this.mostrarDescricao);
  }

  public callbackProdutoUn(prodUn: ProdutoUnidade) {
    let item = this.selecionarEditavel();
    if (item) {
      item.produto_unidade = prodUn;
      this.carregarUnidades(item);
      this.funcaoService.focarCampo(this.inpQuantidade);
    }
  }

  public async getBancoPrecoItem(item: CompraItem): Promise<number> {
    if (!item?.produto_unidade?.produto?.id || !item?.produto_unidade?.unidade?.id)
      return

    if (item['importado_rcms'])
      return item.valor_referencia;

    const valor = await this.compraItemService.itemBancoPreco(item?.produto_unidade?.produto?.id, item?.produto_unidade?.unidade?.id, this.login.orgao.id, this.compra?.id).toPromise();

    return valor;
  }

  public async attGetBancoPrecoItem(item: CompraItem) {
    item.valor_referencia = await this.getBancoPrecoItem(item);
  }

  public aliquotaTitle(item: CompraItem) {
    let aliquota: Aliquota
    let titleItem = ''

    if (item?.produto_unidade?.produto?.aliquota) aliquota = item.produto_unidade.produto.aliquota
    else if (item?.produto_unidade?.produto?.material?.aliquota) aliquota = item.produto_unidade.produto.material.aliquota

    if (aliquota) {
      titleItem = `${aliquota.resumo} - IR: ${aliquota.ir}% | CSLL: ${aliquota.csll}% | COFINS: ${aliquota.cofins}% | PIS / PASEP: ${aliquota.pis_pasep}%`
    }
    return titleItem
  }

  public atualizarValorTotal(item: CompraItem, semDividir?: boolean) {
    if(!semDividir){
      item.quantidade = item['valorTotal'] / item.valor_unitario;
      this.lista.map(i => {
        if (item.produto_unidade.produto.id === i.produto_unidade.produto.id) {
          i.quantidade = item.quantidade
        }
      })
    } else {
      item.valorTotal = item.valor_unitario * item.quantidade
    }
  }

  public cancelarItem(item: CompraItem) {
    item['valorTotal'] = item['valorTotalAnterior'];
    item.alterar_total = false;
  }

  public editarItem(item: CompraItem) {
    item['valorTotalAnterior'] = item['valorTotal'];
    item.alterar_total = true;
  }

  public async salvarItem(item: CompraItem) {
    this.atualizarValorTotal(item);
    this.lista.find(i => i.id === item.id);
    if (this.compra?.contrato?.id && !this.compra?.licitacao?.tabela_desconto && !item['saldo_qtd']) {
      item['saldo_qtd'] = (await this.contratoItemService.obterSaldoProdutoContrato(this.login.orgao.id, this.compra.contrato.id, item.produto_unidade.id).toPromise())?.saldo_qtd
    }
    if (+item.quantidade > +this.lista.find(i => i.id === item.id)['saldo_qtd']) {
      toastr.warning("Quantidade está maior que do Contrato!");
      item['valorTotal'] = item['valorTotalAnterior'];
      item.alterar_total = true;
    } else {
      this.validSave(item)
      item.alterar_total = false;
    }

  }



}