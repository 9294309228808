import { Component, ElementRef, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MaterialService, ProdutoService } from 'almoxarifado-lib';
import { BaseResourceFormDlgComponent, GlobalService, LoginContabil, ProdutoLocalizacao } from 'eddydata-lib';
import { ProdutoLocalizacaoService } from '../service/produto-localizacao.service';

declare var $: any;

@Component({
  selector: 'lib-produto-localizacao-form-dlg',
  templateUrl: './produto-localizacao-form-dlg.component.html'
})
export class ProdutoLocalizacaoFormDlgComponent extends BaseResourceFormDlgComponent<ProdutoLocalizacao, LoginContabil>{

  /**
   * Declaração de variáveis
   */

  @ViewChild('sala_') inputField: ElementRef;

  @Input() public visualizar: boolean = false;
  @Output() public visualizarChange: EventEmitter<boolean> = new EventEmitter();

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected materialService: MaterialService,
    protected produtoService: ProdutoService,
    protected produtoLocalizacaoService: ProdutoLocalizacaoService
  ) {
    super(injector, ProdutoLocalizacao.converteJson, produtoLocalizacaoService, '/grupos-servico');
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      sala: [null, [Validators.required]],
      armario: [null, [Validators.required]],
      prateleira: [null, [Validators.required]],
      pallet: [null, [Validators.required]],
      posicao: [null, [Validators.required]],
      corredor: [null, [Validators.required]],
      produto: [null]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'produto' };
  }

  protected afterLoad() { }

  protected afterInit(): void { }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected beforeSubmit() { }

  protected afterSubmit(modelo: ProdutoLocalizacao) {
    this.sair();
  }

  public sair(): void {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}

