// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { EventoFolha } from './evento-folha.model';
import { Holerite } from './holerite.model';

export class HoleriteEvento extends BaseResourceModel {
  constructor(
    public id?: number,
    public mes?: number,
    public valor?: number,
    public valor_base?: number,
    public quantidade?: number,
    public holerite?: Holerite,
    public evento?: EventoFolha
  ) {
    super();
  }
  static converteJson(json: any): HoleriteEvento {
    return Object.assign(new HoleriteEvento(), json);
  }
}
