import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { IMaskModule } from 'angular-imask';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { EditorModule } from 'primeng/editor';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { SharedModule } from '../util/shared.module';
import { AgendaListComponent } from './agenda-list/agenda-list.component';
import { AgendaRoutingModule } from './agenda-routing.module';

FullCalendarModule.registerPlugins([dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin]);

@NgModule({
  declarations: [AgendaListComponent],
  imports: [
    FormsModule,
    AgendaRoutingModule,
    AutoCompleteModule,
    CalendarModule,
    IMaskModule,
    ConfirmDialogModule,
    ToastModule,
    FullCalendarModule,
    EditorModule,
    SelectButtonModule,
    SharedModule
  ],
  providers: [MessageService, DatePipe]
})
export class AgendaModule { }
