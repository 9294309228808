import { Component, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { BaseResourceFormComponent, InventarioEstoque, LoginContabil, FuncaoService, GlobalService, DateFormatPipe } from 'eddydata-lib';
import { InventarioEstoqueService } from '../service/inventario-estoque.service';


@Component({
  selector: 'app-inventario-estoque-form',
  templateUrl: './inventario-estoque-form.component.html'
})
export class InventarioEstoqueFormComponent extends BaseResourceFormComponent<InventarioEstoque, LoginContabil> {

  /**
   * Declaração de variáveis
   */



  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected inventarioService: InventarioEstoqueService,
  ) {
    super(new InventarioEstoque(), injector, InventarioEstoque.converteJson, inventarioService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      data_inicio: [null, [Validators.required]],
      data_fim: [null],
      observacao: [null],
      finalizado: [false],
      setorAlmoxarifado: [this.login.setorAlmoxarifado],
      exercicio: [this.login.exercicio, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
    });
  }

  protected datePipe(): string[] {
    return ['data_inicio', 'data_fim'];
  }

  protected parametrosExtras(): {} {
    return {};
  }

  protected async podeAlterar(_entidade: InventarioEstoque): Promise<boolean> {
    return true;
  }

  public finalizarInventario() {
    this.entidadeForm.get('data_fim').setValue(new DateFormatPipe().transform(new Date(), []));
    this.entidadeForm.get('finalizado').setValue(true);
    this.submitForm();
  }

  protected afterLoad() {
  }

  protected afterInit(): void {
  }

  protected beforeSubmit() {

  }

  protected afterSubmit(modelo: InventarioEstoque) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
}
