import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Assinatura } from '../../entidade/comum/assinatura.model';
import { BaseResourceService } from '../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class AssinaturaService extends BaseResourceService<Assinatura> {

  constructor(
    protected injector: Injector
  ) {
    super(`assinaturas`, injector);
  }

  imageUrlToBase64(urL: string): Observable<string> {
    return this.http.get(urL, {
      observe: 'body', responseType: 'arraybuffer'
    }).pipe(take(1),
      map((arrayBuffer) => btoa(Array.from(new Uint8Array(arrayBuffer)).map((b) => String.fromCharCode(b)).join(''))))
  }


}
