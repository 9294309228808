import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, AtaRegistro } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class AtaRegistroService extends BaseResourceService<AtaRegistro> {

  constructor(
    protected injector: Injector
  ) {
    super(`atas-registro-precos`, injector);
  }

  public download(nome: string): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/download/${nome}`, {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders(this.adicionarCriptografia({
        Authorization: this.login.token,
      }))
    }).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public upload(entidade: any, arquivos: any): Observable<any> {
    const formData = new FormData();
    formData.append('entidade', JSON.stringify(entidade));
    let i = 1;
    if (!entidade['arquivoAssinado']) {
      for (const item of arquivos) {
        formData.append(`arquivo${i}`, item);
        i++;
      }
    } else {
      formData.append(`arquivoAssinado`, arquivos);
      i++;
    }

    return this.http.post(`${this.login.cidade.id}/${this.api}/upload`, formData, {
      headers: new HttpHeaders(this.adicionarCriptografia({
        Authorization: this.login.token
      }))
    }).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
