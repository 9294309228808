import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from './exercicio.model';
import { Orgao } from './orgao.model';

export class PncpRegistro extends BaseResourceModel {
  constructor(
    public id?: number,
    public id_usuario_pncp?: number,
    public tabela_alvo?: String,
    public id_alvo?: number,
    public exercicio?: Exercicio,
    public sequencial?: number,
    public ratificado?: boolean,
    public excluido?: boolean,
    public envio_ratificando?: PncpRegistro,
    public orgao?: Orgao
  ) {
    super();
  }

  static converteJson(json: any): PncpRegistro {
    return Object.assign(new PncpRegistro(), json);
  }

}
