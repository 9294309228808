import { Component, ElementRef, Injector } from '@angular/core';
import { MaterialService } from 'almoxarifado-lib';
import { BaseResourceFormComponent, GlobalService, LoginContabil, Material } from 'eddydata-lib';

@Component({
  selector: 'lib-material-view',
  templateUrl: './material-view.component.html'
})
export class MaterialViewComponent extends BaseResourceFormComponent<Material, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public mes: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected materialService: MaterialService,
    protected globalService: GlobalService
  ) {
    super(new Material(), injector, Material.converteJson, materialService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
  }

  protected parametrosExtras(): {} {
    return { relations: 'sub_grupo.grupo,produtos', servico : false };
  }

  protected afterLoad() {
  }

  protected afterInit(): void {
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit() {
  }

  protected afterSubmit(modelo: Material) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
}
