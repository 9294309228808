import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Favorecido, FavorecidoService, FuncaoService } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-favorecido-busca-dlg',
  templateUrl: './favorecido-busca-dlg.component.html'
})
export class FavorecidoBuscaDlgComponent implements OnInit, OnDestroy, OnChanges {

  @Output() callback: EventEmitter<Favorecido> = new EventEmitter();

  @Input() public nome: string;

  @Input() parametros: any;

  public cpfCnpj: string;
  public lista: Favorecido[];
  public paginaCorrente: number;
  public paginaTotal: number;
  limite: number = 20;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private favorecidoService: FavorecidoService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['nome'] && this.nome) {
      this.preencherGrid();
    }
  }

  ngOnInit() {
    if (!this.paginaCorrente) {
      this.paginaCorrente = 1;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public buscar() {
    this.paginaCorrente = 1;
    this.preencherGrid();
  }

  private preencherGrid() {
    const parametros = {};
    if (this.cpfCnpj) {
      parametros['cpf_cnpj$like'] = this.cpfCnpj + '%';
    }
    if (this.nome) {
      parametros['nome$like'] = `%${new FuncaoService().removerAcentos(this.nome)}%`
    }
    parametros['orderBy'] = 'nome';

    if (Object.keys(parametros).length <= 1) {
      toastr.info('Informe os valores de busca');
      return;
    }
    this.favorecidoService.filtrar(this.paginaCorrente, this.limite,
      this.parametros ? Object.assign(parametros, this.parametros) : parametros).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.lista = lista.content;
          this.paginaTotal = lista.totalPages;
        },
        error => alert('erro ao retornar lista')
      );
  }

  public proximaPagina() {
    this.paginaCorrente = this.paginaCorrente === this.paginaTotal ? this.paginaTotal : this.paginaCorrente + 1;
    this.preencherGrid();
  }

  public paginaAnterior() {
    this.paginaCorrente = this.paginaCorrente === 1 ? 1 : this.paginaCorrente - 1;
    this.preencherGrid();
  }

  public paginaDigitada(pagina: number) {
    this.paginaCorrente =
      +pagina >= this.paginaTotal
        ? this.paginaTotal
        : +pagina;
    this.preencherGrid();
    window.scrollTo(0, 0);
  }

  public mudarLimite(limite: number) {
    this.limite = limite;
    this.paginaDigitada(1);
  }

  public enviarFavorecido(fav: Favorecido) {
    this.callback.emit(fav);
    this.fechar();
  }

  public fechar() {
    this.cpfCnpj = undefined;
    this.nome = undefined;
    this.lista = [];
    $('#dialogFavorecidoBusca').modal('hide');
  }
}
