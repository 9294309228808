import { DatePipe, formatDate } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Compra, DateFormatPipe, FuncaoService, GlobalService, Licitacao, LicitacaoAudesp, RegistroPrecos, TipoObjetoLicitacao } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { LicitacaoAudespService } from '../../service/licitacao-audesp.service';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';

@Component({
  selector: 'app-licitacao-audesp-registro-preco',
  templateUrl: './licitacao-audesp-registro-preco.component.html'
})
export class LicitacaoAudespRegistroPrecoComponent extends BaseLicitacaoAudesp implements OnInit, OnChanges, OnDestroy {

  @Input() public registroPreco: 'S' | 'N';
  @Input() public entidade: LicitacaoAudesp;
  @Input() public licitacao: Licitacao;
  @Input() public compra: Compra;

  public arquivo: RegistroPrecos;
  public opcoesDivisaoLotes: { id: 'UNICA' | 'LOTES', nome: string }[];
  public opcoesTipoObjeto: { id: TipoObjetoLicitacao, nome: string }[];
  public opcoesTipoExecucao: { id: number, nome: string }[];
  public opcoesObjeto: { id: number, nome: string }[];
  public opcoesModalidade: { id: number, nome: string }[];
  public opcoesNatureza: { id: number, nome: string }[];
  public opcoesTipoLicitacao: { id: number, nome: string }[];
  public opcoesLei1232006: { id: number, nome: string }[];
  public opcoesFundamentoDispensa: { id: number, nome: string }[];
  public opcoesFundamentoInexigibilidade: { id: number, nome: string }[];

  protected unsubscribe: Subject<void> = new Subject();

  // public callParent = new EventEmitter<any>();
  public dataLicitacao: Date;
  public dataEdital: Date;
  public dataEdital2: Date;

  constructor(private globalService: GlobalService,
    private audespService: LicitacaoAudespService,
    private funcaoService: FuncaoService
  ) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && (changes['registroPreco'] || changes['licitacao'] || changes['compra'])) {
      if (this.entidade.id) {
        this.arquivo = this.entidade.arquivo as RegistroPrecos;

        this.carregarObjetosLicitacao();
      } else {
        if ((!this.licitacao && !this.compra) || !this.entidade) {
          this.iniciarObjeto();
        } else {
          this.importarInformacoes();
        }
      }
    }
  }

  ngOnInit(): void {
    this.opcoesTipoObjeto = this.globalService.obterTiposObjetoLicitacao();
    this.opcoesNatureza = this.globalService.obterNaturezaLicitacao();
    this.opcoesTipoLicitacao = this.globalService.obterTipoLicitacao()
    this.opcoesLei1232006 = this.globalService.obterLei1232006Licitacao();
    this.opcoesDivisaoLotes = [
      { id: 'UNICA', nome: 'Única' },
      { id: 'LOTES', nome: 'Dividida em lotes/itens' },
    ]
    this.opcoesModalidade = [
      { id: 1, nome: 'Concorrência' },
      { id: 2, nome: 'Concorrência Internacional' },
      { id: 10, nome: 'Contratação Direta - Dispensa de licitação' },
      { id: 11, nome: 'Contratação Direta - Inexigibilidade de licitação' },
      { id: 4, nome: 'Convite' },
      { id: 13, nome: 'Outra' },
      { id: 5, nome: 'Pregão Eletrônico' },
      { id: 6, nome: 'Pregão Presencial' },
      { id: 7, nome: 'Regime Diferenciado de Contratação - RDC' },
    ]
    this.opcoesTipoExecucao = [
      { id: 4, nome: 'Tarefa' },
      { id: 5, nome: 'Empreitada Integral' },
      { id: 9, nome: 'Contratação semi-integrada' },
      { id: 1, nome: 'Execução Direta' },
      { id: 2, nome: 'Empreitada por Preço Global' },
      { id: 3, nome: 'Empreitada por Preço Unitário' },
      { id: 6, nome: 'Contratação Integrada (RDC)' },
      { id: 7, nome: 'Concessão Pública com obra de engenharia' },
      { id: 8, nome: 'Não se aplica' },
    ];
    this.opcoesFundamentoDispensa = [
      { id: 82, nome: 'Art. 17, I, Lei 8.663/93' },
      { id: 83, nome: 'Art. 17, II, Lei 8.663/93' },
      { id: 84, nome: 'Art. 17, 2, Lei 8.663/93' },
      { id: 85, nome: 'Art. 2 Lei 14.124/01' },
      { id: 1, nome: 'Art. 24, I, Lei 8666/93' },
      { id: 2, nome: 'Art. 24, II, Lei 8666/93' },
      { id: 3, nome: 'Art. 24, III, Lei 8666/93' },
      { id: 4, nome: 'Art. 24, IV, Lei 8666/93' },
      { id: 5, nome: 'Art. 24, V, Lei 8666/93' },
      { id: 7, nome: 'Art. 24, VII, Lei 8666/93' },
      { id: 8, nome: 'Art. 24, VIII, Lei 8666/93' },
      { id: 10, nome: 'Art. 24, X, Lei 8666/93' },
      { id: 11, nome: 'Art. 24, XI, Lei 8666/93' },
      { id: 12, nome: 'Art. 24, XII, Lei 8666/93' },
      { id: 13, nome: 'Art. 24, XIII, Lei 8666/93' },
      { id: 14, nome: 'Art. 24, XIV, Lei 8666/93' },
      { id: 15, nome: 'Art. 24, XV, Lei 8666/93' },
      { id: 16, nome: 'Art. 24, XVI, Lei 8666/93' },
      { id: 17, nome: 'Art. 24, XVII, Lei 8666/93' },
      { id: 18, nome: 'Art. 24, XVIII, Lei 8666/93' },
      { id: 20, nome: 'Art. 24, XX, Lei 8666/93' },
      { id: 21, nome: 'Art. 24, XXI, Lei 8666/93' },
      { id: 22, nome: 'Art. 24, XXII, Lei 8666/93' },
      { id: 23, nome: 'Art. 24, XXIII, Lei 8666/93' },
      { id: 24, nome: 'Art. 24, XXIV, Lei 8666/93' },
      { id: 25, nome: 'Art. 24, XXV, Lei 8666/93' },
      { id: 26, nome: 'Art. 24, XXVI, Lei 8666/93' },
      { id: 27, nome: 'Art. 24, XXVII, Lei 8666/93' },
      { id: 31, nome: 'Art. 24, XXXI, Lei 8666/93' },
      { id: 32, nome: 'Art. 24, XXXII, Lei 8666/93' },
      { id: 33, nome: 'Art. 24, XXXIII, Lei 8666/93' },
      { id: 39, nome: 'Art. 24, XXX, Lei 8666/93' },
      { id: 40, nome: 'Art. 29, I, Lei 13.303/2016' },
      { id: 41, nome: 'Art. 29, II, Lei 13.303/2016' },
      { id: 42, nome: 'Art. 29, III, Lei 13.303/2016' },
      { id: 43, nome: 'Art. 29, IV, Lei 13.303/2016' },
      { id: 44, nome: 'Art. 29, V, Lei 13.303/2016' },
      { id: 45, nome: 'Art. 29, VI, Lei 13.303/2016' },
      { id: 46, nome: 'Art. 29, VII, Lei 13.303/2016' },
      { id: 47, nome: 'Art. 29, VIII, Lei 13.303/2016' },
      { id: 48, nome: 'Art. 29, IX, Lei 13.303/2016' },
      { id: 49, nome: 'Art. 29, X, Lei 13.303/2016' },
      { id: 50, nome: 'Art. 29, XI, Lei 13.303/2016' },
      { id: 51, nome: 'Art. 29, XII, Lei 13.303/2016' },
      { id: 52, nome: 'Art. 29, XIII, Lei 13.303/2016' },
      { id: 53, nome: 'Art. 29, XIV, Lei 13.303/2016' },
      { id: 54, nome: 'Art. 29, XV, Lei 13.303/2016' },
      { id: 63, nome: 'Art. 75, I, Lei 14.133 / 2021' },
      { id: 64, nome: 'Art. 75, II, Lei 14.133 / 2021' },
      { id: 65, nome: 'Art. 75, III, Lei 14.133 / 2021' },
      { id: 66, nome: 'Art. 75, IV, Lei 14.133 / 2021' },
      { id: 67, nome: 'Art. 75, V, Lei 14.133 / 2021' },
      { id: 68, nome: 'Art. 75, VI, Lei 14.133 / 2021' },
      { id: 69, nome: 'Art. 75, VII, Lei 14.133 / 2021' },
      { id: 70, nome: 'Art. 75, VIII, Lei 14.133 / 2021' },
      { id: 71, nome: 'Art. 75, IX, Lei 14.133 / 2021' },
      { id: 72, nome: 'Art. 75, X, Lei 14.133 / 2021' },
      { id: 73, nome: 'Art. 75, XI, Lei 14.133 / 2021' },
      { id: 74, nome: 'Art. 75, XII, Lei 14.133 / 2021' },
      { id: 75, nome: 'Art. 75, XIII, Lei 14.133 / 2021' },
      { id: 76, nome: 'Art. 75, XIV, Lei 14.133 / 2021' },
      { id: 77, nome: 'Art. 75, XV, Lei 14.133 / 2021' },
      { id: 78, nome: 'Art. 75, XVI, Lei 14.133 / 2021' },
      { id: 79, nome: 'Art. 76, I, Lei 14.133 / 2021' },
      { id: 80, nome: 'Art. 76, II, Lei 14.133 / 2021' },
      { id: 81, nome: 'Art. 76, 3, Lei 14.133 / 2021' },
      { id: 38, nome: 'Chamada Publica - Lei Federal 11.9847/2009' },
      { id: 903, nome: 'Outro - fundamento legal' },
    ]
    this.opcoesFundamentoInexigibilidade = [
      { id: 34, nome: 'Art. 25. Caput' },
      { id: 35, nome: 'Art. 25, I, Lei 8666/93' },
      { id: 36, nome: 'Art. 25, II, Lei 8666/93' },
      { id: 37, nome: 'Art. 25, III, Lei 8666/93' },
      { id: 55, nome: 'Art. 30, I, Lei 13.303/2016' },
      { id: 56, nome: 'Art. 30, II, Lei 13.303/2016' },
      { id: 57, nome: 'Art. 74, caput, Lei 14.133/2021' },
      { id: 58, nome: 'Art. 74, I, Lei 14.133/2021' },
      { id: 59, nome: 'Art. 74, II, Lei 14.133/2021' },
      { id: 60, nome: 'Art. 74, III, Lei 14.133/2021' },
      { id: 61, nome: 'Art. 74, IV, Lei 14.133/2021' },
      { id: 62, nome: 'Art. 74, V, Lei 14.133/2021' },
      { id: 904, nome: 'Outro-Informar o fundamento legal' }
    ]

    this.iniciarObjeto();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  public carregarObjetosLicitacao() {
    this.opcoesObjeto = this.globalService.obterObjetoLicitacao(this.arquivo.tipoObjeto);
    switch (this.arquivo.tipoObjeto) {
      case 'COMPRA_SERVICO':
        if (!this.arquivo.ComprasServicos.ObjetoLicitacao) {
          if (this.licitacao?.objeto_licitacao) {
            this.arquivo.ComprasServicos.ObjetoLicitacao = this.licitacao?.objeto_licitacao;
          } else {
            this.arquivo.ComprasServicos.ObjetoLicitacao = null;
          }
        }
        if (!this.arquivo.Subcontratacao) {
          this.arquivo.Subcontratacao = this.licitacao?.permite_subcontratacao ? 'S' : 'N';
        }
        break;
      case 'COMPRA_SERVICO_TI':
        if (!this.arquivo.ComprasServicosTI.ObjetoLicitacaoTI) {
          if (this.licitacao?.objeto_licitacao) {
            this.arquivo.ComprasServicosTI.ObjetoLicitacaoTI = this.licitacao?.objeto_licitacao;
          } else {
            this.arquivo.ComprasServicosTI.ObjetoLicitacaoTI = null;
          }
        }
        if (!this.arquivo.Subcontratacao) {
          this.arquivo.Subcontratacao = this.licitacao?.permite_subcontratacao ? 'S' : 'N';
        }
        break;
      case 'ENGENHARIA':
        if (!this.arquivo.ObrasServicosEngenharia.ObjetoLicitacaoEN) {
          if (this.licitacao?.objeto_licitacao) {
            this.arquivo.ObrasServicosEngenharia.ObjetoLicitacaoEN = this.licitacao?.objeto_licitacao;
          } else {
            this.arquivo.ObrasServicosEngenharia.ObjetoLicitacaoEN = null;
          }
        }
        if (!this.arquivo.Subcontratacao) {
          this.arquivo.Subcontratacao = this.licitacao?.permite_subcontratacao ? 'S' : 'N';
        }
        break;
    }
  }

  private iniciarObjeto() {
    this.entidade.arquivo = new RegistroPrecos();
    this.arquivo = this.entidade.arquivo;
    this.carregarObjetosLicitacao();
  }

  private importarInformacoes() {
    if ((!this.licitacao && !this.compra) || !this.entidade) return;
    this.audespService.importarDados(this.compra ? 'COMPRA' : 'LICITACAO', this.compra ? this.compra.id : this.licitacao.id).subscribe(res => {
      this.entidade.arquivo = res as RegistroPrecos;
      this.arquivo = this.entidade.arquivo;
      this.atribuirCodigo();
      this.arquivo.tipoObjeto = this.licitacao?.tipo_objeto;
      this.carregarObjetosLicitacao();
    })

  }

  public atribuirCodigo() {
    this.audespService.proximoCodigo('LICITACAO', this.login.exercicio.ano, this.login.orgao.id).subscribe(res => {
      if (res) this.arquivo.CodigoLicitacao = res;
    })
  }

  chamaTela() {
    let datePipe: DatePipe;
    if (!this.arquivo.HomologacaoPublicacaoDt) {
      if (["ADJUDICADA", "HOMOLOGADA", "HOMOLOGADA – PARCIAL"].includes(this.licitacao.situacao)) {
        this.arquivo.HomologacaoPublicacaoDt = formatDate(this.licitacao.data_homologacao, 'dd/MM/yyyy', 'en-US');
      } else if (["ANULADA", "DESERTA", "FRACASSADA", "REVOGADA"].includes(this.licitacao.situacao)) {
        this.arquivo.HomologacaoPublicacaoDt = formatDate(this.licitacao.data_cancelamento, 'dd/MM/yyyy', 'en-US');
      } else {
        this.arquivo.HomologacaoPublicacaoDt = null;
      }
    }
  }

  chamaTabEdital() {
    if (this.arquivo.tipoObjeto === 'ENGENHARIA') {
      if (!this.arquivo.ObrasServicosEngenharia.Edital.EditalDt && this.licitacao.data_edital) {
        this.arquivo.ObrasServicosEngenharia.Edital.EditalDt = formatDate(this.licitacao.data_edital, 'dd/MM/yyyy', 'en-US');
        this.arquivo.ObrasServicosEngenharia.Edital.EditalNumero = this.licitacao.edital;
      }
    }
    if (this.arquivo.tipoObjeto === 'COMPRA_SERVICO_TI' && this.licitacao.data_edital) {
      if (!this.arquivo.ComprasServicosTI.Edital.EditalDt) {
        this.arquivo.ComprasServicosTI.Edital.EditalDt = formatDate(this.licitacao.data_edital, 'dd/MM/yyyy', 'en-US');
        this.arquivo.ComprasServicosTI.Edital.EditalNumero = this.licitacao.edital;
      }
    }
    if (this.arquivo.tipoObjeto === 'COMPRA_SERVICO') {
      if (!this.arquivo.ComprasServicos.Edital.EditalDt && this.licitacao.data_edital) {
        this.arquivo.ComprasServicos.Edital.EditalDt = formatDate(this.licitacao.data_edital, 'dd/MM/yyyy', 'en-US');
        this.arquivo.ComprasServicos.Edital.EditalNumero = this.licitacao.edital;
      }
    }
  }

  chamaTabLotes() {
    if (this.arquivo.ObrasServicosEngenharia.Lote.length === 0 || this.arquivo.ComprasServicos.Lote.length === 0 || this.arquivo.ComprasServicosTI.Lote.length === 0) {
      if (this.arquivo.tipoObjeto === 'ENGENHARIA') {
        if (!this.arquivo.ObrasServicosEngenharia.Lote.length) {
          this.arquivo.ObrasServicosEngenharia.Lote = this.licitacao.itens.map(memorial => {
            return {
              NumeroLote: memorial.ordem,
              DescricaoLote: memorial.descricao,
              TipoExecucao: this.opcoesTipoExecucao[0].id,
              ClassificacaoEconomica: {
                TipoClassificacaoEconomica: undefined,
                CodigoClassificacaoEconomica: undefined,
                ClassificacaoEconomicaOutros: undefined
              },
              TipoObraServicoEng: undefined,
              TipoObraServicoEngOpcoes: {
                AssessoriasConsultoriasTecnicas: false,
                AuditoriaObrasServicosEngenharia: false,
                ConsertoInstalacaoManutencaoEm: {
                  elevadores10: false, instalacaoEletrica9: false, paisagismo13: false, sinalizacao3: false, alarmes5: false, climatizacao11: false,
                  incendio21: false, controleAcesso14: false, protecaoDescargas16: false, supervisao2: false, telefonia6: false, ventilacao7: false,
                },
                ConservacaoReparacaoManutencaoDe: {
                  adultoras18: false, barragens17: false, edificacoes20: false, obrasSaneamento8: false, pontes4: false,
                  rodovias12: false, tratamento19: false, trilho1: false, tuneis22: false, vias15: false,
                },
                ConstrucaoReformaAmpliacaoDe: {
                  adultoras18: false, barragens17: false, edificacoes20: false, obrasSaneamento8: false, pontes4: false,
                  rodovias12: false, tratamento19: false, trilho1: false, tuneis22: false, vias15: false,
                },
                ElaboracaoProjetoBasicoProjetoExecutivo: false,
                EnsaiosTecnologicos: false,
                EstudosImpactoAmbiental: false,
                EstudosViabilidadeTecnicaEconomica: false,
                LevantamentoAerofotogrametrico: false,
                LevantamentosTopograficosBatimetricosGeodesicos: false,
                Outros: false,
                PericiasAvaliacoes: false,
                SondagensOutrosProcedimentosInvestigacaoGeotecnica: false,
              },
              LocalizacaoObra: [],
              OrcamentoLoteSim: [],
              OrcamentoLoteNao: 'N',
            }
          })
          this.entidade.arquivo = JSON.stringify(this.entidade.arquivo);
          this.audespService.atualizar(this.entidade).toPromise();
        }
      }
      if (this.arquivo.tipoObjeto === 'COMPRA_SERVICO_TI') {
        if (!this.arquivo.ComprasServicosTI.Lote.length) {
          this.arquivo.ComprasServicosTI.Lote = this.licitacao.itens.map(memorial => {
            return {
              NumeroLote: memorial.ordem,
              DescricaoLote: memorial.descricao,
              Quantidade: memorial.quantidade,
              UnidadeMedida: memorial.unidade,
              TipoExecucao: this.opcoesTipoExecucao[0].id,
              ClassificacaoEconomica: {
                TipoClassificacaoEconomica: undefined,
                CodigoClassificacaoEconomica: undefined,
                ClassificacaoEconomicaOutros: undefined
              },
              OrcamentoLoteSim: [],
              OrcamentoLoteNao: 'N',
              LoteCompostoItensSim: [],
              LoteCompostoItensNao: 'N',
            }
          })
          this.entidade.arquivo = JSON.stringify(this.entidade.arquivo);
          this.audespService.atualizar(this.entidade).toPromise();
        }
      }
      if (this.arquivo.tipoObjeto === 'COMPRA_SERVICO') {
        if (!this.arquivo.ComprasServicos.Lote.length) {
          this.arquivo.ComprasServicos.Lote = this.licitacao.itens.map(memorial => {
            return {
              NumeroLote: memorial.ordem,
              DescricaoLote: memorial.descricao,
              Quantidade: memorial.quantidade,
              UnidadeMedida: memorial.unidade,
              TipoExecucao: this.opcoesTipoExecucao[0].id,
              ClassificacaoEconomica: {
                TipoClassificacaoEconomica: undefined,
                CodigoClassificacaoEconomica: undefined,
                ClassificacaoEconomicaOutros: undefined
              },
              OrcamentoLoteSim: [],
              OrcamentoLoteNao: 'S',
              LoteCompostoItensSim: [],
              LoteCompostoItensNao: 'N',
            }
          })
          this.entidade.arquivo = JSON.stringify(this.arquivo);
          this.audespService.atualizar(this.entidade).toPromise();
        }
      }
    }

  }
}
