import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-recuperar-senha-sucesso',
  templateUrl: './recuperar-senha-sucesso.component.html',
  styleUrls: ['./recuperar-senha-sucesso.component.css']
})
export class RecuperarSenhaSucessoComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }


  voltar() {
    this.router.navigate([`/login`]);
  }


}
