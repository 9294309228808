import { Injectable, Injector } from '@angular/core';
import {
  Exercicio,
  ExercicioService,
  FormatoExportacao,
  FuncaoService, GlobalService, Login, OrgaoAssinaturaService, OrgaoService,
  Relatorio
} from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FichaDespesaService } from 'contabil-lib';
import { Assinaturas } from '../../assinaturas/assinaturas';

@Injectable({
  providedIn: 'root'
})
export class BalanceteDespesaProcessadaService {

  protected unsubscribe: Subject<void> = new Subject();
  public login: Login = new Login();

  constructor(
    protected fichaService: FichaDespesaService,
    protected exercicioService: ExercicioService,
    protected orgaoService: OrgaoService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected injector: Injector,
  ) { this.login = GlobalService.obterSessaoLogin(); }

  async montarBalancete(formato: FormatoExportacao, mes: number, exercicioId: number, orgaos: number[], login: any, dtInicio: string, dtFim: string, sintetico?: boolean) {
    const parametros: {} = {};
    if (mes) {
      parametros['mes'] = mes;
    } else {
      parametros['dtInicio'] = dtInicio;
      parametros['dtFim'] = dtFim;
    }

    if (exercicioId) {
      parametros['ano'] = exercicioId;
    }
    parametros['orgaos'] = orgaos.join();
    parametros['sintetico'] = sintetico;
    const ex = await this.exercicioService.obterId(exercicioId).toPromise();
    this.fichaService.balanceteProcessada(parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        let subtitulo = '';
        if (mes) {
          subtitulo = this.globalService.obterDataBR().monthNames[mes - 1].toLocaleUpperCase() + '/' + ex.ano
        } else {
          let dt = dtInicio.split("-");
          subtitulo = dt[2] + '/' + dt[1] + '/' + dt[0] + ' à ';
          dt = dtFim.split("-");
          subtitulo += dt[2] + '/' + dt[1] + '/' + dt[0];
        }

        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado('DEMONSTRAÇÃO DA DESPESA EMPENHADA, PROCESSADA E PAGA POR UNIDADE ORÇAMENTARIAS SEGUNDO A NATUREZA DA DESPESA',
            login.usuario.nome, login.usuario.sobrenome, login.orgao.nome, login.brasao,
            await this.balanceteDespesaConteudo(dados, sintetico, ex, mes, dtFim),
            'landscape', 'Balancete Analítico da Despesa Processada',
            {
              linhas: {
                hLineWidth(i, node) {
                  return 1;
                },
                vLineWidth(i) {
                  return 1;
                },
                hLineColor(i) {
                  return 'black';
                },
                paddingLeft(i) {
                  return 3;
                },
                paddingRight(i, node) {
                  return 3;
                }
              }
            }, false, false, 'pdf', `REFERÊNCIA: ${subtitulo}`);
        } else if (formato === 'csv') {
          this.balanceteDespesaCSV(dados, sintetico);
        }
      });
  }

  private async balanceteDespesaConteudo(dados: any[], sintetico: boolean, exercicio: Exercicio, mes: number, dataFinal: string): Promise<{}[]> {
    let registros: {}[];
    if (sintetico) {
      registros = [
        // [{
        //   text: 'DEMONSTRAÇÃO DA DESPESA EMPENHADA, PROCESSADA E PAGA POR UNIDADE ORÇAMENTARIAS SEGUNDO A NATUREZA DA DESPESA',
        //   colSpan: 13, alignment: 'center', bold: true, fontSize: 11
        // },
        //   '', '', '', '', '', '', '', '', '', '', '', ''],
        [
          { text: sintetico ? 'RECURSO' : 'FICHA', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
          { text: 'DESCRIÇÃO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7, colSpan: 2 }, '',
          { text: 'ORÇADA ATUAL', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
          { text: 'EMPENHADA', alignment: 'center', colSpan: 2, bold: true, fontSize: 7 },
          { text: '' },
          { text: 'SALDO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
          { text: 'PROCESSADA', alignment: 'center', colSpan: 2, bold: true, fontSize: 7 },
          { text: '' },
          { text: 'SALDO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
          { text: 'PAGAMENTO', alignment: 'center', colSpan: 2, bold: true, fontSize: 7 },
          { text: '' },
          { text: 'DÍVIDA', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 }
        ],
        ['', '', '', '',
          { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 7 },
          { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 7 }, '',
          { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 7 },
          { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 7 }, '',
          { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 7 },
          { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 7 }, '']
      ];
    } else {
      registros = [
        // [{
        //   text: 'DEMONSTRAÇÃO DA DESPESA EMPENHADA, PROCESSADA E PAGA POR UNIDADE ORÇAMENTARIAS SEGUNDO A NATUREZA DA DESPESA',
        //   colSpan: 13, alignment: 'center', bold: true, fontSize: 11
        // },
        //   '', '', '', '', '', '', '', '', '', '', '', ''],
        [
          { text: 'FUNCIONAL', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
          { text: sintetico ? 'RECURSO' : 'FICHA', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
          { text: 'DESCRIÇÃO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
          { text: 'ORÇADA ATUAL', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
          { text: 'EMPENHADA', alignment: 'center', colSpan: 2, bold: true, fontSize: 7 },
          { text: '' },
          { text: 'SALDO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
          { text: 'PROCESSADA', alignment: 'center', colSpan: 2, bold: true, fontSize: 7 },
          { text: '' },
          { text: 'SALDO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
          { text: 'PAGAMENTO', alignment: 'center', colSpan: 2, bold: true, fontSize: 7 },
          { text: '' },
          { text: 'DÍVIDA', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 }
        ],
        ['', '', '', '',
          { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 7 },
          { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 7 }, '',
          { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 7 },
          { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 7 }, '',
          { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 7 },
          { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 7 }, '']
      ];
    }

    const ass = new Assinaturas(this.login.orgao, this.injector);
    const data = dataFinal ?? `${exercicio.ano}-${this.funcaoService.strZero(mes, 2)}-${this.funcaoService.ultimoDiaMes(+mes, exercicio.ano)}`;
    let assinaturas = await ass.dadosAssinatura(15, null, {
        assinatura_esquerda: 'ordenador_despesa',
        cargo_esquerda: 'cargo_ordenador_despesa',
        assinatura_centro: 'contador',
        cargo_centro: 'cargo_contador',
        assinatura_direita: 'tesoureiro',
        cargo_direita: 'cargo_tesoureiro'
    }, null, null, null, data, true);

    const funcao = {};
    let totalOrcado = 0.00;
    let totalEmpenhadoMes = 0.00;
    let totalEmpenhado = 0.00;
    let totalSaldo = 0.00;
    let totalLiquidadoMes = 0.00;
    let totalLiquidado = 0.00;
    let totalSaldoLiquidado = 0.00;
    let totalPago = 0.00;
    let totalPagoMes = 0.00;
    let totalDivida = 0.00;

    if (!sintetico) {
      for (const registro of dados) {

        if (!funcao['codigo'] || funcao['codigo'] !== registro['ue_codigo']) {
          if (funcao['codigo']) {
            registros.push(this.retornarLinhaSoma(funcao));
          }
          funcao['codigo'] = registro['ue_codigo'];
          funcao['nome'] = registro['ue_nome'];
          funcao['orcado'] = 0.00;
          funcao['empenhadoMes'] = 0.00;
          funcao['empenhado'] = 0.00;
          funcao['saldo'] = 0.00;
          funcao['liquidado'] = 0.00;
          funcao['liquidadoMes'] = 0.00;
          funcao['saldoLiquidado'] = 0.00;
          funcao['pagoMes'] = 0.00;
          funcao['pago'] = 0.00;  
          funcao['divida'] = 0.00;

          registros.push([
            {
              text: this.funcaoService.mascarar('##.##.##', funcao['codigo']) + ' ' + funcao['nome'], colSpan: 13,
              bold: true, fontSize: 7
            }, '', '', '', '', '', '', '', '', '', '', '', '',
          ]);
        }
        const orcado: number = +registro['total_orcado'] + +registro['movimento'] + +registro['especial'];
        const empenhadoMes: number = registro['empenhado_mes'];
        const empenhado: number = registro['empenhado'];
        const saldo = +orcado - +empenhado;
        const liquidadoMes: number = registro['liquidado_mes'];
        const liquidado: number = registro['liquidado'];
        const saldoLiquidado = +empenhado - +liquidado;
        const pagoMes: number = registro['pago_mes'];
        const pago: number = registro['pago'];
        const divida = +liquidado - +pago;
        funcao['orcado'] += +orcado;
        funcao['empenhadoMes'] += +empenhadoMes;
        funcao['empenhado'] += +empenhado;
        funcao['liquidadoMes'] += +liquidadoMes;
        funcao['liquidado'] += +liquidado;
        funcao['saldoLiquidado'] += +saldoLiquidado;
        funcao['saldo'] += +saldo;
        funcao['pagoMes'] += +pagoMes;
        funcao['pago'] += +pago;
        funcao['divida'] += +divida;
        totalOrcado += +orcado;
        totalEmpenhadoMes += +empenhadoMes;
        totalEmpenhado += +empenhado;
        totalDivida += +divida;
        totalLiquidadoMes += +liquidadoMes;
        totalLiquidado += +liquidado;
        totalSaldoLiquidado += +saldoLiquidado;
        totalPagoMes += +pagoMes;
        totalPago += +pago;
        totalSaldo += +saldo;

        registros.push([
          { text: registro['f_codigo'] + registro['sf_codigo'] + registro['p_codigo'] + '.' + registro['pr_codigo'], border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.strZero(registro['fh_numero'], 3), alignment: 'center', border: [true, false, true, false], fontSize: 7 },
          {
            text: this.funcaoService.mascarar('#.#.##.##', registro['d_codigo']) + ' ' + registro['d_nome'],
            border: [true, false, true, false], fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(orcado), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(empenhadoMes), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          {
            text: this.funcaoService.convertToBrNumber(empenhado), alignment: 'right',
            border: [true, false, true, false], fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(saldo), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(liquidadoMes), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(liquidado), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(saldoLiquidado), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(pagoMes), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(pago), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(divida), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
        ]);
      }
      if (funcao['codigo']) {
        registros.push(this.retornarLinhaSoma(funcao));
        registros.push([
          {
            text: 'TOTAL GERAL:', colSpan: 2,
            bold: true, fontSize: 7
          }, '', '',
          { text: this.funcaoService.convertToBrNumber(totalOrcado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhadoMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalSaldo), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalLiquidadoMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalLiquidado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalSaldoLiquidado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalPagoMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalPago), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalDivida), bold: true, alignment: 'right', fontSize: 7 },
        ]);
      }
    } else {

      const grupos = this.funcaoService.agrupar(dados, ['re_codigo', 'd_codigo'], ['empenhado', 'empenhado_mes', 'especial', 'liquidado', 'liquidado_mes', 'movimento', 'pago', 'pago_mes', 'total_orcado']);

      for (const registro of grupos) {

        if (!funcao['codigo'] || funcao['codigo'] !== registro.registros[0]['ue_codigo']) {
          if (funcao['codigo']) {
            registros.push(this.retornarLinhaSoma(funcao));
          }
          funcao['codigo'] = registro.registros[0]['ue_codigo'];
          funcao['nome'] = registro.registros[0]['ue_nome'];
          funcao['orcado'] = 0.00;
          funcao['empenhadoMes'] = 0.00;
          funcao['empenhado'] = 0.00;
          funcao['saldo'] = 0.00;
          funcao['liquidado'] = 0.00;
          funcao['liquidadoMes'] = 0.00;
          funcao['saldoLiquidado'] = 0.00;
          funcao['pagoMes'] = 0.00;
          funcao['pago'] = 0.00;
          funcao['divida'] = 0.00;
          registros.push([
            {
              text: this.funcaoService.mascarar('##.##.##', funcao['codigo']) + ' ' + funcao['nome'], colSpan: 13,
              bold: true, fontSize: 7
            }, '', '', '', '', '', '', '', '', '', '', '', '',
          ]);
        }
        const orcado: number = +registro.totalizadores['total_orcado'] + +registro.totalizadores['movimento'] + +registro.totalizadores['especial'];
        const empenhadoMes: number = registro.totalizadores['empenhado_mes'];
        const empenhado: number = registro.totalizadores['empenhado'];
        const saldo = +orcado - +empenhado;
        const liquidadoMes: number = registro.totalizadores['liquidado_mes'];
        const liquidado: number = registro.totalizadores['liquidado'];
        const saldoLiquidado = +empenhado - +liquidado;
        const pagoMes: number = registro.totalizadores['pago_mes'];
        const pago: number = registro.totalizadores['pago'];
        const divida = +liquidado - +pago;
        funcao['orcado'] += +orcado;
        funcao['empenhadoMes'] += +empenhadoMes;
        funcao['empenhado'] += +empenhado;
        funcao['liquidadoMes'] += +liquidadoMes;
        funcao['liquidado'] += +liquidado;
        funcao['saldoLiquidado'] += +saldoLiquidado;
        funcao['saldo'] += +saldo;
        funcao['pagoMes'] += +pagoMes;
        funcao['pago'] += +pago;
        funcao['divida'] += +divida;
        totalOrcado += +orcado;
        totalEmpenhadoMes += +empenhadoMes;
        totalEmpenhado += +empenhado;
        totalDivida += +divida;
        totalLiquidadoMes += +liquidadoMes;
        totalLiquidado += +liquidado;
        totalSaldoLiquidado += +saldoLiquidado;
        totalPagoMes += +pagoMes;
        totalPago += +pago;
        totalSaldo += +saldo;

        registros.push([
          { text: this.funcaoService.strZero(registro.registros[0]['re_codigo'], 2), alignment: 'center', border: [true, false, true, false], fontSize: 7 },
          {
            text: this.funcaoService.mascarar('#.#.##.##', registro.grupo['d_codigo']) + ' ' + registro.registros[0]['d_nome'],
            border: [true, false, true, false], fontSize: 7, colSpan: 2
          }, '',
          { text: this.funcaoService.convertToBrNumber(orcado), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(empenhadoMes), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          {
            text: this.funcaoService.convertToBrNumber(empenhado), alignment: 'right',
            border: [true, false, true, false], fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(saldo), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(liquidadoMes), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(liquidado), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(saldoLiquidado), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(pagoMes), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(pago), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(divida), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
        ]);
      }
      if (funcao['codigo']) {
        registros.push(this.retornarLinhaSoma(funcao));
        registros.push([
          {
            text: 'TOTAL GERAL:', colSpan: 3,
            bold: true, fontSize: 7
          },
          '', '',
          { text: this.funcaoService.convertToBrNumber(totalOrcado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhadoMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalSaldo), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalLiquidadoMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalLiquidado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalSaldoLiquidado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalPagoMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalPago), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalDivida), bold: true, alignment: 'right', fontSize: 7 },
        ]);
      }
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    },
    {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*'],
        body: assinaturas
      }
    }];
  }

  balanceteDespesaCSV(dados: any[], sintetico: boolean) {
    let registros: {}[][];
    if (sintetico) {
      registros = [[
        { text: sintetico ? 'RECURSO' : 'FICHA' },
        { text: 'DESCRIÇÃO' },
        { text: 'ORÇADA ATUAL' },
        { text: 'EMPENHADA DO MÊS' },
        { text: 'EMPENHADA ACUMULADO' },
        { text: 'SALDO' },
        { text: 'PROCESSADA DO MÊS' },
        { text: 'PROCESSADA ACUMULADO' },
        { text: 'SALDO' },
        { text: 'PAGAMENTO DO MÊS' },
        { text: 'PAGAMENTO ACUMULADO' },
        { text: 'DÍVIDA' },
      ]];
    } else {
      registros = [[
        { text: 'FUNCIONAL' },
        { text: sintetico ? 'RECURSO' : 'FICHA' },
        { text: 'DESCRIÇÃO' },
        { text: 'ORÇADA ATUAL' },
        { text: 'EMPENHADA DO MÊS' },
        { text: 'EMPENHADA ACUMULADO' },
        { text: 'SALDO' },
        { text: 'PROCESSADA DO MÊS' },
        { text: 'PROCESSADA ACUMULADO' },
        { text: 'SALDO' },
        { text: 'PAGAMENTO DO MÊS' },
        { text: 'PAGAMENTO ACUMULADO' },
        { text: 'DÍVIDA' },
      ]];
    }

    const funcao = {};
    let totalOrcado = 0.00;
    let totalEmpenhadoMes = 0.00;
    let totalEmpenhado = 0.00;
    let totalSaldo = 0.00;
    let totalLiquidadoMes = 0.00;
    let totalLiquidado = 0.00;
    let totalSaldoLiquidado = 0.00;
    let totalPago = 0.00;
    let totalPagoMes = 0.00;
    let totalDivida = 0.00;

    if (!sintetico) {
      for (const registro of dados) {
        if (!funcao['codigo'] || funcao['codigo'] !== registro['ue_codigo']) {
          if (funcao['codigo']) {
            registros.push([
              { text: 'SOMA' }, { text: '' }, { text: '' },
              { text: this.funcaoService.convertToBrNumber(funcao['orcado']) },
              { text: this.funcaoService.convertToBrNumber(funcao['empenhadoMes']) },
              { text: this.funcaoService.convertToBrNumber(funcao['empenhado']) },
              { text: this.funcaoService.convertToBrNumber(funcao['saldo']) },
              { text: this.funcaoService.convertToBrNumber(funcao['liquidadoMes']) },
              { text: this.funcaoService.convertToBrNumber(funcao['liquidado']) },
              { text: this.funcaoService.convertToBrNumber(funcao['saldoLiquidado']) },
              { text: this.funcaoService.convertToBrNumber(funcao['pagoMes']) },
              { text: this.funcaoService.convertToBrNumber(funcao['pago']) },
              { text: this.funcaoService.convertToBrNumber(funcao['divida']) },
            ]);
          }
          funcao['codigo'] = registro['ue_codigo'];
          funcao['nome'] = registro['ue_nome'];
          funcao['orcado'] = 0.00;
          funcao['empenhadoMes'] = 0.00;
          funcao['empenhado'] = 0.00;
          funcao['saldo'] = 0.00;
          funcao['liquidado'] = 0.00;
          funcao['liquidadoMes'] = 0.00;
          funcao['saldoLiquidado'] = 0.00;
          funcao['pagoMes'] = 0.00;
          funcao['pago'] = 0.00;  
          funcao['divida'] = 0.00;

          registros.push([{ text: this.funcaoService.mascarar('##.##.##', funcao['codigo']) + ' ' + funcao['nome'] }]);
        }
        const orcado: number = +registro['total_orcado'] + +registro['movimento'] + +registro['especial'];
        const empenhadoMes: number = registro['empenhado_mes'];
        const empenhado: number = registro['empenhado'];
        const saldo = +orcado - +empenhado;
        const liquidadoMes: number = registro['liquidado_mes'];
        const liquidado: number = registro['liquidado'];
        const saldoLiquidado = +empenhado - +liquidado;
        const pagoMes: number = registro['pago_mes'];
        const pago: number = registro['pago'];
        const divida = +liquidado - +pago;
        funcao['orcado'] += +orcado;
        funcao['empenhadoMes'] += +empenhadoMes;
        funcao['empenhado'] += +empenhado;
        funcao['liquidadoMes'] += +liquidadoMes;
        funcao['liquidado'] += +liquidado;
        funcao['saldoLiquidado'] += +saldoLiquidado;
        funcao['saldo'] += +saldo;
        funcao['pagoMes'] += +pagoMes;
        funcao['pago'] += +pago;
        funcao['divida'] += +divida;
        totalOrcado += +orcado;
        totalEmpenhadoMes += +empenhadoMes;
        totalEmpenhado += +empenhado;
        totalDivida += +divida;
        totalLiquidadoMes += +liquidadoMes;
        totalLiquidado += +liquidado;
        totalSaldoLiquidado += +saldoLiquidado;
        totalPagoMes += +pagoMes;
        totalPago += +pago;
        totalSaldo += +saldo;

        registros.push([
          { text: registro['f_codigo'] + registro['sf_codigo'] + registro['p_codigo'] + '.' + registro['pr_codigo'] },
          { text: this.funcaoService.strZero(registro['fh_numero'], 2) },
          { text: this.funcaoService.mascarar('#.#.##.##', registro['d_codigo']) + ' ' + registro['d_nome'] },
          { text: this.funcaoService.convertToBrNumber(orcado) },
          { text: this.funcaoService.convertToBrNumber(empenhadoMes) },
          { text: this.funcaoService.convertToBrNumber(empenhado) },
          { text: this.funcaoService.convertToBrNumber(saldo) },
          { text: this.funcaoService.convertToBrNumber(liquidadoMes) },
          { text: this.funcaoService.convertToBrNumber(liquidado) },
          { text: this.funcaoService.convertToBrNumber(saldoLiquidado) },
          { text: this.funcaoService.convertToBrNumber(pagoMes) },
          { text: this.funcaoService.convertToBrNumber(pago) },
          { text: this.funcaoService.convertToBrNumber(divida) },
        ]);
      }
      if (funcao['codigo']) {
        registros.push([
          { text: 'SOMA' }, { text: '' }, { text: '' },
          { text: this.funcaoService.convertToBrNumber(funcao['orcado']) },
          { text: this.funcaoService.convertToBrNumber(funcao['empenhadoMes']) },
          { text: this.funcaoService.convertToBrNumber(funcao['empenhado']) },
          { text: this.funcaoService.convertToBrNumber(funcao['saldo']) },
          { text: this.funcaoService.convertToBrNumber(funcao['liquidadoMes']) },
          { text: this.funcaoService.convertToBrNumber(funcao['liquidado']) },
          { text: this.funcaoService.convertToBrNumber(funcao['saldoLiquidado']) },
          { text: this.funcaoService.convertToBrNumber(funcao['pagoMes']) },
          { text: this.funcaoService.convertToBrNumber(funcao['pago']) },
          { text: this.funcaoService.convertToBrNumber(funcao['divida']) },
        ]);
        registros.push([
          { text: 'TOTAL GERAL:' }, { text: '' }, { text: '' },
          { text: this.funcaoService.convertToBrNumber(totalOrcado) },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhadoMes) },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhado) },
          { text: this.funcaoService.convertToBrNumber(totalSaldo) },
          { text: this.funcaoService.convertToBrNumber(totalLiquidadoMes) },
          { text: this.funcaoService.convertToBrNumber(totalLiquidado) },
          { text: this.funcaoService.convertToBrNumber(totalSaldoLiquidado) },
          { text: this.funcaoService.convertToBrNumber(totalPagoMes) },
          { text: this.funcaoService.convertToBrNumber(totalPago) },
          { text: this.funcaoService.convertToBrNumber(totalDivida) },
        ]);
      }
    } else {
      const grupos = this.funcaoService.agrupar(dados, ['re_codigo', 'd_codigo'], ['empenhado', 'empenhado_mes', 'especial', 'liquidado', 'liquidado_mes', 'movimento', 'pago', 'pago_mes', 'total_orcado']);

      for (const registro of grupos) {
        if (!funcao['codigo'] || funcao['codigo'] !== registro.registros[0]['ue_codigo']) {
          if (funcao['codigo']) {
            registros.push([
              { text: 'SOMA' }, { text: '' },
              { text: this.funcaoService.convertToBrNumber(funcao['orcado']) },
              { text: this.funcaoService.convertToBrNumber(funcao['empenhadoMes']) },
              { text: this.funcaoService.convertToBrNumber(funcao['empenhado']) },
              { text: this.funcaoService.convertToBrNumber(funcao['saldo']) },
              { text: this.funcaoService.convertToBrNumber(funcao['liquidadoMes']) },
              { text: this.funcaoService.convertToBrNumber(funcao['liquidado']) },
              { text: this.funcaoService.convertToBrNumber(funcao['saldoLiquidado']) },
              { text: this.funcaoService.convertToBrNumber(funcao['pagoMes']) },
              { text: this.funcaoService.convertToBrNumber(funcao['pago']) },
              { text: this.funcaoService.convertToBrNumber(funcao['divida']) },
            ]);
          }
          funcao['codigo'] = registro.registros[0]['ue_codigo'];
          funcao['nome'] = registro.registros[0]['ue_nome'];
          funcao['orcado'] = 0.00;
          funcao['empenhadoMes'] = 0.00;
          funcao['empenhado'] = 0.00;
          funcao['saldo'] = 0.00;
          funcao['liquidado'] = 0.00;
          funcao['liquidadoMes'] = 0.00;
          funcao['saldoLiquidado'] = 0.00;
          funcao['pagoMes'] = 0.00;
          funcao['pago'] = 0.00;
          funcao['divida'] = 0.00;

          registros.push([{ text: this.funcaoService.mascarar('##.##.##', funcao['codigo']) + ' ' + funcao['nome'] }]);
        }
        const orcado: number = +registro.totalizadores['total_orcado'] + +registro.totalizadores['movimento'] + +registro.totalizadores['especial'];
        const empenhadoMes: number = registro.totalizadores['empenhado_mes'];
        const empenhado: number = registro.totalizadores['empenhado'];
        const saldo = +orcado - +empenhado;
        const liquidadoMes: number = registro.totalizadores['liquidado_mes'];
        const liquidado: number = registro.totalizadores['liquidado'];
        const saldoLiquidado = +empenhado - +liquidado;
        const pagoMes: number = registro.totalizadores['pago_mes'];
        const pago: number = registro.totalizadores['pago'];
        const divida = +liquidado - +pago;
        funcao['orcado'] += +orcado;
        funcao['empenhadoMes'] += +empenhadoMes;
        funcao['empenhado'] += +empenhado;
        funcao['liquidadoMes'] += +liquidadoMes;
        funcao['liquidado'] += +liquidado;
        funcao['saldoLiquidado'] += +saldoLiquidado;
        funcao['saldo'] += +saldo;
        funcao['pagoMes'] += +pagoMes;
        funcao['pago'] += +pago;
        funcao['divida'] += +divida;
        totalOrcado += +orcado;
        totalEmpenhadoMes += +empenhadoMes;
        totalEmpenhado += +empenhado;
        totalDivida += +divida;
        totalLiquidadoMes += +liquidadoMes;
        totalLiquidado += +liquidado;
        totalSaldoLiquidado += +saldoLiquidado;
        totalPagoMes += +pagoMes;
        totalPago += +pago;
        totalSaldo += +saldo;

        registros.push([
          { text: this.funcaoService.strZero(registro.registros[0]['re_codigo'], 2) },
          { text: this.funcaoService.mascarar('#.#.##.##', registro.grupo['d_codigo']) + ' ' + registro.registros[0]['d_nome'] },
          { text: this.funcaoService.convertToBrNumber(orcado) },
          { text: this.funcaoService.convertToBrNumber(empenhadoMes) },
          { text: this.funcaoService.convertToBrNumber(empenhado) },
          { text: this.funcaoService.convertToBrNumber(saldo) },
          { text: this.funcaoService.convertToBrNumber(liquidadoMes) },
          { text: this.funcaoService.convertToBrNumber(liquidado) },
          { text: this.funcaoService.convertToBrNumber(saldoLiquidado) },
          { text: this.funcaoService.convertToBrNumber(pagoMes) },
          { text: this.funcaoService.convertToBrNumber(pago) },
          { text: this.funcaoService.convertToBrNumber(divida) },
        ]);
      }
      if (funcao['codigo']) {
        registros.push([
          { text: 'SOMA' }, { text: '' },
          { text: this.funcaoService.convertToBrNumber(funcao['orcado']) },
          { text: this.funcaoService.convertToBrNumber(funcao['empenhadoMes']) },
          { text: this.funcaoService.convertToBrNumber(funcao['empenhado']) },
          { text: this.funcaoService.convertToBrNumber(funcao['saldo']) },
          { text: this.funcaoService.convertToBrNumber(funcao['liquidadoMes']) },
          { text: this.funcaoService.convertToBrNumber(funcao['liquidado']) },
          { text: this.funcaoService.convertToBrNumber(funcao['saldoLiquidado']) },
          { text: this.funcaoService.convertToBrNumber(funcao['pagoMes']) },
          { text: this.funcaoService.convertToBrNumber(funcao['pago']) },
          { text: this.funcaoService.convertToBrNumber(funcao['divida']) },
        ]);
        registros.push([
          { text: 'TOTAL GERAL:' }, { text: '' },
          { text: this.funcaoService.convertToBrNumber(totalOrcado) },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhadoMes) },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhado) },
          { text: this.funcaoService.convertToBrNumber(totalSaldo) },
          { text: this.funcaoService.convertToBrNumber(totalLiquidadoMes) },
          { text: this.funcaoService.convertToBrNumber(totalLiquidado) },
          { text: this.funcaoService.convertToBrNumber(totalSaldoLiquidado) },
          { text: this.funcaoService.convertToBrNumber(totalPagoMes) },
          { text: this.funcaoService.convertToBrNumber(totalPago) },
          { text: this.funcaoService.convertToBrNumber(totalDivida) },
        ]);
      }
    }

    dados = null;
    let csv = '';
    for (let i=0; i<registros.length; i++) {
      const linha = registros[i];
      if (i > 0) csv += '\n';
      for (let x=0; x<linha.length; x++) {
        if (x > 0) csv += ';';
        csv += String(linha[x]['text']);
      }
    }
    registros = null;

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + csv));
    element.setAttribute("download", `Balancete Analítico da Despesa Processada.csv`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  retornarLinhaSoma(recurso: {}) {
    return [
      {
        text: 'SOMA', colSpan: 3,
        bold: true, fontSize: 7
      },
      '', '',
      { text: this.funcaoService.convertToBrNumber(recurso['orcado']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(recurso['empenhadoMes']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(recurso['empenhado']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(recurso['saldo']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(recurso['liquidadoMes']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(recurso['liquidado']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(recurso['saldoLiquidado']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(recurso['pagoMes']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(recurso['pago']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(recurso['divida']), bold: true, alignment: 'right', fontSize: 7 },
    ];
  }

}
