import { BaseResourceModel } from '../../models/base-resource.model';
import { RetencaoIRRF } from "./retencao-irrf.model";

export class RetencaoIRRFItem extends BaseResourceModel {
  constructor(
    public id?: number,

    public valor_inicial?: number,
    public valor_final?: number,
    public porcentagem?: number,
    public deducao?: number,
    public retencao?: RetencaoIRRF,
    public editavel?: boolean) {
    super();
  }
  static converteJson(json: any): RetencaoIRRFItem {
    return Object.assign(new RetencaoIRRFItem(), json);
  }
}