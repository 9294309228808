import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MovimentoEstoque } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';

declare var $: any;

@Component({
  selector: 'lib-liquidacao-movimento-estoque-dlg',
  templateUrl: './liquidacao-movimento-estoque-dlg.component.html'
})
export class LiquidacaoMovimentoEstoqueDlgComponent {

  @Input() movimentos: Array<MovimentoEstoque> = null;
  @Output() callback: EventEmitter<MovimentoEstoque> = new EventEmitter();

  constructor(
    protected confirmationService: ConfirmationService,
  ) { }


  public saldoTotalMovimento(movimento: MovimentoEstoque) {
    let valor = 0;
    if (movimento.itens) {
      for (let item of movimento.itens) {
        let valor_unitario = item.valor_unitario ? parseFloat(item.valor_unitario.toString()) : 0;
        let qtde = item.qtde ? parseFloat(item.qtde.toString()) : 0;
        valor += valor_unitario * qtde;
      }
    }
    return valor;
  }

  public selecionar(movimento: MovimentoEstoque) {
    this.callback.emit(movimento);
  }

}
