import { Component, Input } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Setor } from '../../../entidade/almoxarifado/setor.model';
import { InventarioSetorStorage } from '../../../entidade/patrimonio/inventario-setor-storage.model';
import { BaseResourceItemsComponent } from '../../../models/base-resource-items';
import { SetorAnexoListService } from '../service/setor-anexo-list.service';

@Component({
  selector: 'app-setor-anexo-list',
  templateUrl: './setor-anexo-list.component.html'
})
export class SetorAnexoListComponent extends BaseResourceItemsComponent<InventarioSetorStorage> {

  @Input() setor: Setor;
  public listaArquivos: InventarioSetorStorage[];

  constructor(
    private setorAnexoListService: SetorAnexoListService,
  ) {
    super(new InventarioSetorStorage(), setorAnexoListService);
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();

    this.setorAnexoListService.filtrar(1, -1, { 'inventario_setor.setor.id': this.setor.id, orderBy: 'data_cadastro$DESC' })
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((res) => {
      this.listaArquivos = res.content;
    });
  }

  protected validSave(item: InventarioSetorStorage): boolean {
    return true;
  }

  protected beforeSave(item: InventarioSetorStorage): void { }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  private carregarAutoCompletes() {

  }

}
