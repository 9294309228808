import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BaseResourceListComponent, Coluna, Exercicio, ExercicioService, FichaReceita, Filtro, FormatoExportacao, LoginPublico, ReceitaPrevista
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FichaReceitaService } from '../../ficha-receita/service/ficha-receita.service';
import { ReceitaPrevistaService } from '../service/receita-prevista.service';

@Component({
  selector: 'lib-receita-prevista-lst',
  templateUrl: './receita-prevista-lst.component.html'
})
export class ReceitaPrevistaLstComponent extends BaseResourceListComponent<ReceitaPrevista, LoginPublico> implements OnInit {

  /**
   * Declaração de variáveis
   */
  public tipo: string;
  public programacaoId: number;
  public listaPlanilha: Array<any>;
  public listaPrevisao: Array<ReceitaPrevista>;
  public ficha: FichaReceita;
  public usarExtendido: boolean;
  public exercicio: Exercicio;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private route: ActivatedRoute,
    protected injector: Injector,
    private messageService: MessageService,
    private fichaService: FichaReceitaService,
    private exercicioService: ExercicioService,
    private previstoService: ReceitaPrevistaService) {
    super(previstoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  public preencherGrid() {
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.exercicioService.obterPorAno(params['ano'], this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (res) => {
              this.exercicio = res.content[0];
              this.fichaService.planilhaPrevisao(this.paginaCorrente,
                this.limite, this.exercicio.id, this.login.orgao.id).pipe(takeUntil(this.unsubscribe))
                .subscribe((lista) => {
                  this.listaPlanilha = lista.content;
                  this.paginaTotal = lista.totalPages;
                });
            }
          );
      });
  }

  arrecadado(item: any): string {
    return (+item.previsto_jan + +item.previsto_fev + +item.previsto_mar + +item.previsto_abr + +item.previsto_mai +
      +item.previsto_jun + +item.previsto_jul + +item.previsto_ago + +item.previsto_set + +item.previsto_out +
      +item.previsto_nov + +item.previsto_dez).toFixed(2);
  }

  escapePrevistoArrecadado(item: any) {
    return `<div style='font-size:10px;'>
      <p style='margin: 0 !important'>PREVISTO: ${item.fh_valor_orcado.replace('.', ',')}</p>
      <p style='margin: 0 !important'>ARRECADADO: ${this.arrecadado(item).replace('.', ',')}</p>
      <p style='margin: 0 !important'>DIFERENÇA: ${(+this.arrecadado(item) - item.fh_valor_orcado).toFixed(2).replace('.', ',')}</p>
    </div>`
  }

  protected relations(): string {
    return 'receita';
  }

  protected condicoesGrid(): {} {
    return { ['exercicio.id']: this.login.exercicio.id };
  }

  protected ordenacaoGrid(): string[] {
    return ['r_numero$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {};
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: ReceitaPrevista): Observable<ReceitaPrevista> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'r_codigo' },
      { titulo: 'Descrição', coluna: 'r_nome' },
      { titulo: 'Janeiro', coluna: 'previsto_jan', decimais: 2, alignment: 'right' },
      { titulo: 'Fevereiro', coluna: 'previsto_fev', decimais: 2, alignment: 'right' },
      { titulo: 'Março', coluna: 'previsto_mar', decimais: 2, alignment: 'right' },
      { titulo: 'Abril', coluna: 'previsto_abr', decimais: 2, alignment: 'right' },
      { titulo: 'Maio', coluna: 'previsto_mai', decimais: 2, alignment: 'right' },
      { titulo: 'Junho', coluna: 'previsto_jun', decimais: 2, alignment: 'right' },
      { titulo: 'Julho', coluna: 'previsto_jul', decimais: 2, alignment: 'right' },
      { titulo: 'Agosto', coluna: 'previsto_ago', decimais: 2, alignment: 'right' },
      { titulo: 'Setembro', coluna: 'previsto_set', decimais: 2, alignment: 'right' },
      { titulo: 'Outubro', coluna: 'previsto_out', decimais: 2, alignment: 'right' },
      { titulo: 'Novembro', coluna: 'previsto_nov', decimais: 2, alignment: 'right' },
      { titulo: 'Dezembro', coluna: 'previsto_dez', decimais: 2, alignment: 'right' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    this.fichaService
      .planilhaPrevisao(1, 0,
        this.login.exercicio.id, this.login.orgao.id
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('PREVISÃO INICIAL DE RECEITAS ORÇAMENTÁRIAS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem previsao receita', ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto',
              'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        });
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public gerarPrevisaoAnual() {
    this.confirmationService.confirm({
      message: 'Deseja gerar automaticamente a previsão inicial de todas as receitas?',
      header: 'Gerar Previsão',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: () => {
        this.previstoService.gerarPrevisaoAnual(this.login.exercicio.id, this.login.orgao.id).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            this.messageService.add({ severity: 'info', summary: 'Informação', detail: res });
            this.preencherGrid();
          }, (error) => this.messageService.add({ severity: 'error', summary: 'Validação', detail: error.error }));
      },
      reject: () => {

      }
    });
  }

  alterarPrevisao(item: any) {
    this.fichaService.obter({
      id: item.fh_id,
      relations: 'receita'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((entidade) => {
        this.ficha = entidade;
        const params = {};
        params['ficha_id'] = this.ficha.id;
        params['orderBy'] = 'mes';
        params['relations'] = 'ficha';
        this.previstoService.filtrar(1, -1, params).pipe(takeUntil(this.unsubscribe))
          .subscribe((list) => {
            this.listaPrevisao = list.content;
          });
      });
  }

}
