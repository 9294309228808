import { Component, EventEmitter, Injector, Input, Output, SimpleChanges } from "@angular/core";
import { AdiantamentoService } from "administrativo-lib";
import { Adiantamento, BaseResourceFormDlgComponent, DateFormatPipe, Login, LoginContabil } from "eddydata-lib";
import { MessageService } from "primeng/api";
import { takeUntil } from "rxjs/operators";
import * as toastr from 'toastr';


@Component({
    selector: 'lib-adiantamento-prestacao-dlg',
    templateUrl: 'adiantamento-prestacao-dlg.component.html'
})
export class AdiantamentoPrestacaoDlgComponent extends BaseResourceFormDlgComponent<Adiantamento, LoginContabil> {

    @Input() public visualizar: boolean = false;
    @Output() public visualizarChange: EventEmitter<boolean> = new EventEmitter();

    @Input() login: Login;
    @Input() adiantamento: Adiantamento;

    public listaPareceres: Array<any>;

    constructor(
        protected injector: Injector,
        private messageService: MessageService,
        protected adiantamentoService: AdiantamentoService
    ) {
        super(injector, Adiantamento.converteJson, adiantamentoService, "/adiantamentos");
    }

    // ========================================================================
    //                        MÉTODOS ABSTRAÍDOS
    // ========================================================================

    protected criarCamposForm(): void {
        this.entidadeForm = this.fb.group({
            id: [null],
            data_prestacao: [null],
            parecer: [null],
            data_termino: [null],
            descricao_parecer: [null],
            naoEscriturar: [false]
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.adiantamento?.id && this.adiantamento.data_prestacao) {
            this.entidadeForm.get('data_prestacao').setValue(new DateFormatPipe().transform(this.adiantamento.data_prestacao, []));
            this.entidadeForm.get('parecer').setValue(this.adiantamento.parecer);
            this.entidadeForm.get('data_termino').setValue(new DateFormatPipe().transform(this.adiantamento.data_termino, []));
            this.entidadeForm.get('descricao_parecer').setValue(this.adiantamento.descricao_parecer);
        }
    }

    protected beforeSubmit() {
        if ((!this.podeAlterarAudesp(this.entidadeForm.get('data_prestacao').value)) || (!this.podeAlterarAudesp(this.entidadeForm.get('data_termino').value))) {
            toastr.warning('Não é possível alterar. Prazo esgotado!');
            throw new Error('Não é possível alterar. Prazo esgotado!');
        }
        if (this.entidadeForm.get('data_prestacao').value > this.entidadeForm.get('data_termino').value) {
            toastr.warning('A data de prestação não pode ser maior que a data de termino');
            throw new Error('A data de prestação não pode ser maior que a data de termino');
        }
    }

    protected parametrosExtras(): {} {
        return { relations: 'sub_grupo.grupo.orgao,sub_grupo.orgao,orgao', servico: true };
    }

    protected afterLoad() {
    }

    protected afterInit(): void {
        this.listaPareceres = [
            { id: 'F', nome: 'FAVORÁVEL' },
            { id: 'D', nome: 'DESFAVORÁVEL' }
        ];
    }

    protected afterSubmit(adiantamento: Adiantamento) {
        this.sair();
    }

    public sair(): void {
        this.visualizar = false;
        this.visualizarChange.emit(this.visualizar);
    }

    public salvarPrestacao() {
        this.beforeSubmit();
        let entidade: Adiantamento = this.adiantamento;
        entidade.data_prestacao = this.entidadeForm.get('data_prestacao').value;
        entidade.parecer = this.entidadeForm.get('parecer').value;
        entidade.data_termino = this.entidadeForm.get('data_termino').value;
        entidade.descricao_parecer = this.entidadeForm.get('descricao_parecer').value;
        this.entidadeForm.get('naoEscriturar').setValue(false);
        entidade.naoEscriturar = this.entidadeForm.get('naoEscriturar').value;
        if (!this.entidadeForm.get('data_prestacao').value && !this.entidadeForm.get('data_termino').value) {
            this.entidadeForm.get('descricao_parecer').setValue(null);
            this.entidadeForm.get('parecer').setValue(null);
        }

        if ((new Date(this.entidadeForm.get('data_prestacao').value).getFullYear() > this.login.exercicio.ano) || (new Date(this.entidadeForm.get('data_termino').value).getFullYear() > this.login.exercicio.ano)) {
            this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: 'O ano da data de prestação de contas informada é de exercício diferente do logado no sistema, neste caso será necessário fazer um variação no exercício posterior para contabilizar a prestação de contas!', });
            this.entidadeForm.get('naoEscriturar').setValue(true);
            entidade.naoEscriturar = this.entidadeForm.get('naoEscriturar').value;
        }

        this.adiantamentoService.salvarPrestacao(entidade).pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.messageService.add({ severity: 'success', summary: 'Informação', detail: 'Prestação de contas salva com sucesso' });
            },
                (error) => {
                    this.messageService.add(
                        { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
                    );
                    this.cancelarPrestacao();
                });
    }

    public cancelarPrestacao() {
        this.entidadeForm.get('data_prestacao').setValue(null);
        this.entidadeForm.get('data_termino').setValue(null);
        this.entidadeForm.get('descricao_parecer').setValue(null);
        this.entidadeForm.get('parecer').setValue(null);
        this.loadResource();
    }

    public acrescentaParecer() {
        const parecer = this.entidadeForm.get('parecer').value
        if (parecer) {
            this.entidadeForm.get('descricao_parecer').setValue(`${parecer === 'F' ? 'FAVORÁVEL' : parecer === 'D' ? 'DESFAVORÁVEL' : ''}`)
        }
    }
}