import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {

    constructor(
        @Inject('BASE_API_URL') private baseUrl: string) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.includes(':5678') || request.url.includes(':4567') || request.url.includes('viacep.com.br') || request.url.includes('storage.googleapis')
            || request.url.includes('servicodados.ibge.gov.br') || request.url.includes('hereapi.com') || request.url.includes('api.tecnospeed.com')
            || request.url.includes('treina.pncp.gov.br') || request.url.includes('audesp.tce.sp.gov.br') || request.url.includes('app.eddydata.com/SGM')
            || request.url.includes('pncp.gov.br') || request.url.includes('publica.cnpj.ws')) {
            return next.handle(request);
        }
        const apiReq = request.clone({ url: `${this.baseUrl}/${request.url}` });
        return next.handle(apiReq);
    }
}
