import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FuncaoService, RetencaoResto } from 'eddydata-lib';
import * as toastr from 'toastr';
import { RetencaoRestoService } from '../service/retencao-resto.service';

@Component({
  selector: 'lib-retencao-resto-reinf',
  templateUrl: './retencao-resto-reinf.component.html'
})
export class RetencaoRestoReinfComponent {
  @Input() retencao: RetencaoResto;
  @Output() callback: EventEmitter<RetencaoResto> = new EventEmitter();

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  paises: { codigo: number, nome: string }[] = [];

  constructor(private retencaoService: RetencaoRestoService, private funcaoService: FuncaoService) { }

  salvar() {
    this.retencaoService.atualizar(this.retencao).subscribe((res) => {
      toastr.success('Informações adicionais para retenção salva com sucesso');
      this.retencao = res;
      this.callback.emit(this.retencao);
    }, (e) => { this.funcaoService.acaoErro(e) });
  }
}
