// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { MetaGoverno } from './meta-governo.model';
import { Executora } from './executora.model';
import { Indicador } from './indicador.model';
import { Acao } from './acao.model';

export class AcaoGoverno extends BaseResourceModel {
  constructor(
    public id?: number,
    public tipo?: string,
    public custo1?: number,
    public custo2?: number,
    public custo3?: number,
    public custo4?: number,
    public executora?: Executora,
    public acao?: Acao,
    public meta?: MetaGoverno,
    public meta_acao?: string,
    public unidade_medida?: string,
    public indicador?: Indicador,
    public enviado_tce?: boolean,
  ) {
    super();
  }

  static converteJson(json: any): AcaoGoverno {
    return Object.assign(new AcaoGoverno(), json);
  }
}
