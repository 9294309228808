import { BaseResourceStorage } from '../../models/base-resource.storage';
import { ManutencaoPatrimonio } from './manutencao-patrimonio.model';

export class ManutencaoStoragePatrimonio extends BaseResourceStorage {
  constructor(
    public manutencao?: ManutencaoPatrimonio,
  ) {
    super();
  }

  static converteJson(json: any): ManutencaoStoragePatrimonio {
    return Object.assign(new ManutencaoStoragePatrimonio(), json);
  }
}
