// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido } from '../compra/favorecido.model';
import { Prazo } from '../compra/prazo.model';
import { Orgao } from '../comum/orgao.model';

export class AtaRegistro extends BaseResourceModel {
  constructor(
    public id?: number,
    public numero?: string,
    public processo?: string,
    public data_ata?: Date,
    public data_inicio?: Date,
    public data_termino?: Date,
    public data_autorizacao?: Date,
    public prazo?: Prazo,
    public fase4?: boolean,
    public validade?: number,
    public observacao?: string,
    public favorecido?: Favorecido,
    public orgao?: Orgao
  ) {
    super();
  }

  static converteJson(json: any): AtaRegistro {
    return Object.assign(new AtaRegistro(), json);
  }
}
