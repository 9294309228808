// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Produto } from './produto.model';

export class ProdutoReferencia extends BaseResourceModel {
  constructor(
    public id?: number,
    public marca?: string,
    public codigo_barra?: string,
    public produto?: Produto,
    public editavel?: boolean
  ) {
    super();
  }
  static converteJson(json: any): ProdutoReferencia {
    return Object.assign(new ProdutoReferencia(), json);
  }
}
