
import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { ImovelOcorrencia } from './imovel-ocorrencia.model';
export class ImovelOcorrenciaHistorico extends BaseResourceModel {
    constructor(
        public descricao?: string,
        public imovel_ocorrencia?: ImovelOcorrencia,
        public usuario?: Usuario,
    ) {
        super();
    }

    static converteJson(json: any): ImovelOcorrenciaHistorico {
        return Object.assign(new ImovelOcorrenciaHistorico(), json);
    }
}
