import { Component, Input, SimpleChanges } from '@angular/core';
import {
  BaseResourceItemsComponent,
  Contrato,
  ContratoAditamento,
  ContratoAditamentoItem,
  DateFormatPipe,
  FuncaoService,
  GlobalService
} from 'eddydata-lib';
import * as toastr from 'toastr';
import { ContratoAditamentoService } from '../service/contrato-aditamento.service';

declare var $: any;

@Component({
  selector: 'lib-contrato-aditamento',
  templateUrl: './contrato-aditamento.component.html'
})
export class ContratoAditamentoComponent extends BaseResourceItemsComponent<ContratoAditamento> {

  public ptBR: any;
  public itemAtual: ContratoAditamento;
  public visualizar_item: boolean;

  @Input() public contrato: Contrato;
  @Input() visualizarC: Boolean = false;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    protected globalService: GlobalService,
    public funcaoService: FuncaoService,
    aditamentoService: ContratoAditamentoService) {
    super(new ContratoAditamento(), aditamentoService);
  }

  protected afterInit(): void {
    this.ptBR = this.globalService.obterDataBR();
  }

  protected validSave(item: ContratoAditamento): boolean {
    if (!item.data_contabilizacao) {
      toastr.warning('Informe a data de contabilização!');
      return false;
    }
    if (!item.data_inicio) {
      toastr.warning('Informe a data de início!');
      return false;
    }
    if (!item.data_termino) {
      toastr.warning('Informe a data de término!');
      return false;
    }
    if (!item.data_aditamento) {
      toastr.warning('Informe a data de assinatura!');
      return false;
    }
    if (!item.numero) {
      toastr.warning('Informe o número do aditamento!');
      return false;
    }
    if (!this.funcaoService.podeAlterarAudesp(item.data_contabilizacao, this.login) || this.funcaoService.podeAlterarAudesp(item?.['data_historico'], this.login)) {
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return false;
    }
    return true;
  }
  protected beforeSave(item: ContratoAditamento): void { }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public adicionar() {
    this.visualizar_item = false;
    this.itemAtual = new ContratoAditamento();
    this.itemAtual.data_aditamento = this.funcaoService.obterDataUTC(new Date());
    this.itemAtual.data_contabilizacao = this.funcaoService.obterDataUTC(new Date());
    this.itemAtual.data_inicio = this.funcaoService.obterDataUTC(new Date());
    this.itemAtual.contrato = this.contrato;
    this.itemAtual.usuario = this.login.usuario;
    this.itemAtual.exercicio = this.login.exercicio;
    this.itemAtual.editavel = true;
    this.itemAtual.valor_total = 0;
    this.itemAtual.itens = [];
    for (const item of this.contrato.itens) {
      this.itemAtual.itens.push({ aditivo_qtd: item.quantidade, aditivo_valor: item['vl_unit_atualizado'], contrato_item: item });
    }

    $('#dialogAditamento').modal('show');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lista) {
      this.lista = this.lista.sort((a, b) => { return +new Date(a.data_aditamento) - +new Date(b.data_aditamento) });
    }
  }

  public ordernarLista(event) {
    this.lista = this.lista.sort((a, b) => { return +new Date(a.data_aditamento) - +new Date(b.data_aditamento) });
  }

  public editar(item: ContratoAditamento) {
    this.visualizar_item = false;
    this.itemAtual = item;
    // this.entity.editavel = true;
    this.itemAtual.data_aditamento = new DateFormatPipe().transform(item.data_aditamento, []);
    this.itemAtual.data_inicio = new DateFormatPipe().transform(item.data_inicio, []);
    this.itemAtual.data_termino = new DateFormatPipe().transform(item.data_termino, []);
    this.itemAtual.data_contabilizacao = new DateFormatPipe().transform(item.data_contabilizacao, []);
    this.itemAtual.itens.map(itm => itm.contrato_item['cota'] = this.retornaCota(itm, item.itens) === 'P' ? 'PRINCIPAL' : 'RESERVADO')
    $('#dialogAditamento').modal('show');
  }

  public visualizar(item: ContratoAditamento) {
    this.itemAtual = item;
    this.visualizar_item = true;
    this.itemAtual.data_aditamento = new DateFormatPipe().transform(item.data_aditamento, []);
    this.itemAtual.data_inicio = new DateFormatPipe().transform(item.data_inicio, []);
    this.itemAtual.data_termino = new DateFormatPipe().transform(item.data_termino, []);
    this.itemAtual.data_contabilizacao = new DateFormatPipe().transform(item.data_contabilizacao, []);
    $('#dialogAditamento').modal('show');
  }

  public condicaoEdicao(item: ContratoAditamento): Boolean {
    // const hoje = Date.now()
    // return this.funcaoService.converteDataSQL(new Date(hoje)) <= this.funcaoService.converteDataSQL(new Date(item.data_contabilizacao))

    const diasBloqueio = this.login.parametro.contabil?.dias_bloquear_alteracoes
    const dataAudesp = this.login.ultimoAudesp
    const dataContabilizacao = this.funcaoService.zerarHoras(new Date(item.data_contabilizacao))


    const dataLimiteSuperior = this.funcaoService.adicionarDia(diasBloqueio, this.funcaoService.zerarHoras(new Date()))
    const dataLimiteInferior = this.funcaoService.adicionarDia(diasBloqueio, this.funcaoService.zerarHoras(new Date()), false, true)

    if (dataContabilizacao >= dataLimiteSuperior || dataContabilizacao <= dataLimiteInferior) {
      return false
    } else {
      const mes = +dataAudesp.split('-')[1]
      const ano = +dataAudesp.split('-')[0]

      if ((dataContabilizacao.getFullYear() < ano || (dataContabilizacao.getFullYear() === ano && (+dataContabilizacao.getMonth() + 1) <= mes)) && mes < 12) {
        return false
      } else {
        return true
      }
    }
  }

  public retornaCota(itemAtual: ContratoAditamentoItem, lista: ContratoAditamentoItem[]) {
    let cota: 'P' | 'R'
    if (itemAtual.contrato_item.memorial) {
      cota = itemAtual.contrato_item.memorial.cota === 'PRINCIPAL' ? 'P' : itemAtual.contrato_item.memorial.cota === 'NAO_DEFINIDO' ? 'P' : 'R'
    } else {
      let itemIgual = lista.find(it => it.contrato_item.produto_unidade.id === itemAtual.contrato_item.produto_unidade.id && it.id !== itemAtual.contrato_item.id)
      cota = !itemIgual ? 'P' : +itemAtual.contrato_item.quantidade > +itemIgual.contrato_item.quantidade ? 'P' : 'R'
    }
    return cota
  }

  public podeVisualizar() {
    if (this.visualizarC === true) {
      return true;
    }
    if(this.visualizarC === false){
      return false;
    }
  }
}