import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { TransparenciaParametroService } from './service/transparencia-parametro.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { LoginPublico, TransparenciaParametro, FuncaoService, GlobalService } from 'eddydata-lib';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transparencia-parametro',
  templateUrl: './transparencia-parametro.component.html'
})
export class TransparenciaParametroComponent implements OnInit, OnDestroy {

  /**
   * Declaração de variáveis
   */
  public selectedTipo: string;
  public login = new LoginPublico();
  public entidadeForm: FormGroup;
  private fb: FormBuilder;
  public parametro: TransparenciaParametro;

  public data1: string;
  public data2: string;
  public datepipe: DatePipe;

  protected unsubscribe: Subject<void> = new Subject();

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private router: Router,
    private messageService: MessageService,
    protected injector: Injector,
    protected funcaoService: FuncaoService,
    protected parametroService: TransparenciaParametroService) {
    this.login = GlobalService.obterSessaoLogin();
    this.fb = this.injector.get(FormBuilder);
    this.datepipe = new DatePipe('pt');
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================


  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      esic: [false, [Validators.required]],
      data_atualizacao: [false, [Validators.required]],
      painel_receita: [false, [Validators.required]],
      receita: [false, [Validators.required]],
      despesa_extra: [false, [Validators.required]],
      receita_recurso: [false, [Validators.required]],
      despesa_recurso: [false, [Validators.required]],
      receita_extra: [false, [Validators.required]],
      empenho: [false, [Validators.required]],
      empenho_extra: [false, [Validators.required]],
      empenho_resto: [false, [Validators.required]],
      liquidacao: [false, [Validators.required]],
      liquidacao_resto: [false, [Validators.required, Validators.minLength(2)]],
      pagamento: [false, [Validators.required]],
      pagamento_extra: [false, [Validators.required]],
      pagamento_resto: [false, [Validators.required]],
      contrato: [false, [Validators.required]],
      licitacao: [false, [Validators.required]],
      repasse: [false, [Validators.required]],
      convenio: [false, [Validators.required]],
      transf_orgao: [false, [Validators.required]],
      transf_federal: [false, [Validators.required]],
      transf_estadual: [false, [Validators.required]],
      transf_privada: [false, [Validators.required]],
      arrecadacao_imposto: [false, [Validators.required]],
      operacao_credito: [false, [Validators.required]],
      planejamento: [false, [Validators.required]],
      remuneracao: [false, [Validators.required]],
      ata: [false, [Validators.required]],
      equipamento: [false, [Validators.required]],
      viagem: [false, [Validators.required]],
      cargo: [false, [Validators.required]],
      afastado: [false, [Validators.required]],
      cedido: [false, [Validators.required]],
      covid_receita: [false, [Validators.required]],
      covid_despesa: [false, [Validators.required]],
      conta_publica: [false, [Validators.required]],
      mostrar_relatorio_loa: [false, [Validators.required]],
      acumular_desconto_holerite: [false, [Validators.required]],
      detalhar_holerite: [false, [Validators.required]],
      detalhar_convenio: [false, [Validators.required]],
      exportar_xls: [false, [Validators.required]],
      exportar_xml: [false, [Validators.required]],
      exportar_pdf: [false, [Validators.required]],
      url: [null, [Validators.required]],
      conta_tipo: ['R', [Validators.required]],
      plan_tipo: ['R', [Validators.required]],
      plan_url: ['http://'],
      esic_url: ['', Validators.required],
      transparencia_externa: [false, [Validators.required]],
      url_remuneracao: [''],
      url_cargo: [''],
      url_servidores_cedidos: [''],
      url_servidores_afastados: [''],
      grafico_despesa: [true, [Validators.required]],
      orgao: [false, [Validators.required]],
      outras_informacoes: [false, [Validators.required]],
      outras_informacoes_url: ['', Validators.required],
      penalidade_fornecedor: [false],
      redes_sociais: [false, [Validators.required]],
      url_facebook: ['', Validators.required],
      url_instagram: ['', Validators.required],
      url_linkedin: ['', Validators.required],
      url_youtube: ['', Validators.required],
      url_twitter: ['', Validators.required],
      mostrar_campo_aviso: [false, [Validators.required]],
      aviso_url: ['', Validators.required],
      descricao_url: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.data1 = this.datepipe.transform(new Date(`01/01/${new Date().getFullYear()}`), 'yyyy-MM-dd');
    this.data2 = this.datepipe.transform(new Date(), 'yyyy-MM-dd');

    this.criarCamposForm();
    this.parametroService.obter({ orgao_id: this.login.orgao.id })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res) => {
          this.parametro = res;
          this.parametro.orgao = this.login.orgao;
          if (!res) {
            this.parametro = new TransparenciaParametro();
            this.parametro.receita = false;
            this.parametro.receita_extra = false;
            this.parametro.despesa_extra = false;
            this.parametro.receita_recurso = false;
            this.parametro.despesa_recurso = false;
            this.parametro.empenho = false;
            this.parametro.empenho_extra = false;
            this.parametro.empenho_resto = false;
            this.parametro.liquidacao = false;
            this.parametro.liquidacao_resto = false;
            this.parametro.pagamento = false;
            this.parametro.pagamento_extra = false;
            this.parametro.pagamento_resto = false;
            this.parametro.contrato = false;
            this.parametro.licitacao = false;
            this.parametro.repasse = false;
            this.parametro.transf_privada = false;
            this.parametro.convenio = false;
            this.parametro.equipamento = false;
            this.parametro.ata = false;
            this.parametro.remuneracao = false;
            this.parametro.transf_orgao = false;
            this.parametro.transf_federal = false;
            this.parametro.transf_estadual = false;
            this.parametro.arrecadacao_imposto = false;
            this.parametro.planejamento = false;
            this.parametro.conta_publica = false;
            this.parametro.mostrar_relatorio_loa = false;
            this.parametro.operacao_credito = false;
            this.parametro.covid_receita = false;
            this.parametro.covid_despesa = false;
            this.parametro.operacao_credito = false;
            this.parametro.acumular_desconto_holerite = false;
            this.parametro.detalhar_holerite = false;
            this.parametro.detalhar_convenio = false;
            this.parametro.exportar_xls = false;
            this.parametro.exportar_xml = false;
            this.parametro.exportar_pdf = false;
            this.parametro.plan_url = 'http://';
            this.parametro.esic_url = '';
            this.parametro.transparencia_externa = false;
            this.parametro.redes_sociais = false;
            this.parametro.aviso_url = '';

            this.parametro.grafico_despesa = true;
            this.parametro.outras_informacoes = false;
            this.parametro.outras_informacoes_url = '';
            this.parametro.data_atualizacao = new Date();
            this.parametro.penalidade_fornecedor = false
          } else {
            this.parametro.data_atualizacao = new Date(res.data_atualizacao);
          }

          this.entidadeForm.patchValue(this.parametro);
          if (!this.podeIncluir('/admin/transparencia/parametros-transparencia')) {
            this.entidadeForm.disable({
              emitEvent: false
            });
          }
        }
      );
  }

  public podeVisualizar(sistema: string) {
    // if (sistema !== this.login.sistema && !this.login.usuario.administrador)
    //   return false;
    return true;
  }

  public podeIncluir(url: string) {
    if (this.login) {
      if (this.login.usuario.administrador) {
        return true;
      }
      for (const acesso of this.login.acessos) {
        if (url === acesso.pagina && acesso.permissao === 2) {
          return true;
        }
      }
    }
    return false;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  submitForm() {
    this.parametro = JSON.parse(JSON.stringify(this.entidadeForm.value));
    if (!this.parametro.id) {
      this.parametroService
        .inserir(this.parametro)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.messageService.add({ severity: 'success', summary: 'Atenção', detail: 'Parâmetros salvo com sucesso!' });
        },
          error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));
    } else {
      this.parametroService
        .atualizar(this.parametro)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.messageService.add({ severity: 'success', summary: 'Atenção', detail: 'Parâmetros salvo com sucesso!' });
        },
          error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));
    }
  }

  desconectar() {
    GlobalService.limparSessaoLogin(true);
    this.router.navigate(['transparencia/login']);
  }

  rotas(botao: any) {
    switch (botao) {
      case 'usuarios':
        this.router.navigate(['/usuarios']);
        break;
      case 'atasRegistro':
        this.router.navigate(['/admin/transparencia/atas-registro-anexos/pesquisa', 1, this.data1, this.data2]);
        break;
      case 'empenhos':
        this.router.navigate(['/admin/transparencia/empenhos-orcamentarios-anexos/pesquisa', 1, this.data1, this.data2]);
        break;
      case 'contratosAditivos':
        this.router.navigate(['/admin/transparencia/contratos-anexos/pesquisa', 1, this.data1, this.data2]);
        break;
      case 'licitacoes':
        this.router.navigate(['/admin/transparencia/licitacoes-anexos/pesquisa', 1, this.data1, this.data2]);
        break;
      case 'anexarArquivos':
        this.router.navigate(['/transparencia/transparencia-arquivos']);
        break;
      default:
        console.log('switch case default')
    }
  }

  verificarPermi(url: string) {
    if (this.login) {
      if (this.login.usuario.administrador) {
        return true;
      }
      for (const acesso of this.login.acessos) {
        if (url === acesso.pagina) {
          return true;
        }
      }
    }
    return false;
  }
}
