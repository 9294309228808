import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { PessoaModule, SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { SaldoFichaModule } from '../saldo-ficha/saldo-ficha.module';
import { CompraFormComponent } from './compra-form/compra-form.component';
import { CompraItemComponent } from './compra-item/compra-item.component';
import { CompraListDlgComponent } from './compra-list-dlg/compra-list-dlg.component';
import { CompraListComponent } from './compra-list/compra-list.component';
import { CompraRcmsDlgComponent } from './compra-rcms-dlg/compra-rcms-dlg.component';
import { CompraLicitacoesDlgComponent } from "./compra-licitacoes-dlg/compra-licitacoes-dlg.component";
import { CompraRoutingModule } from './compra-routing.module';
import { CompraViewComponent } from './compra-view/compra-view.component';
import { ExclusaoCompraDlgComponent } from './exclusao-compra-dlg/exclusao-compra-dlg.component';
import { CompraStorageComponent } from './compra-storage/compra-storage.component';
import { DialogModule } from 'primeng/dialog';
import { CompraSelecionarItemDlgComponent } from './compra-selecionar-item-dlg/compra-selecionar-item-dlg.component';
import { CompraFavorecidosDlgComponent } from './compra-favorecidos-dlg/compra-favorecidos-dlg.component';
import { CompraContratosDlgComponent } from './compra-contratos-dlg/compra-contratos-dlg.component';
import { ServicoModule } from '../servico/servico.module';
import { RelatorioCompraModule } from '../relatorio-compra/relatorio-compra.module';
import { ProdutoDlgModule } from 'almoxarifado-lib';
import { CompraTabelasDescontoDlg } from './compra-tabela-desconto-dlg/compra-tabela-desconto-dlg.component';
import { EditorModule } from 'primeng/editor';
import { CompraTxtComponent } from './compra-txt/compra-txt.component';


@NgModule({
  declarations: [
    CompraListComponent,
    CompraFormComponent,
    CompraViewComponent,
    CompraListDlgComponent,
    CompraItemComponent,
    CompraSelecionarItemDlgComponent,
    CompraRcmsDlgComponent,
    ExclusaoCompraDlgComponent,
    CompraStorageComponent,
    CompraFavorecidosDlgComponent,
    CompraLicitacoesDlgComponent,
    CompraContratosDlgComponent,
    CompraTabelasDescontoDlg,
    CompraTxtComponent
  ],
  exports: [CompraListDlgComponent,CompraLicitacoesDlgComponent, CompraSelecionarItemDlgComponent,
    CompraRcmsDlgComponent, ExclusaoCompraDlgComponent, CompraFavorecidosDlgComponent, CompraContratosDlgComponent, 
    CompraViewComponent, CompraFormComponent,CompraTabelasDescontoDlg,CompraTxtComponent],
  imports: [
    CommonModule,
    CompraRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    InputMaskModule,
    FileUploadModule,
    PessoaModule,
    RadioButtonModule,
    FieldsetModule,
    SaldoFichaModule,
    CheckboxModule,
    DragDropModule,
    PanelModule,
    MenuModule,
    DialogModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    EditorModule,
    ServicoModule,
    RelatorioCompraModule,
    ProdutoDlgModule
  ],
  providers: [MessageService, ConfirmationService]
})
export class CompraModule { }
