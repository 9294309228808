import { Component, Injector, ElementRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

import {
  BaseResourceFormComponent, Holerite, LoginPublico,
  HoleriteEvento, GlobalService, FuncaoService, DateFormatPipe
} from 'eddydata-lib';
import { HoleriteEventoService } from '../../holerite/holerite-evento.service';
import { HoleriteService } from '../../holerite/holerite.service';

@Component({
  selector: 'lib-remuneracao-shw',
  templateUrl: './remuneracao-shw.component.html'
})
export class RemuneracaoShwComponent extends BaseResourceFormComponent<Holerite, LoginPublico> {

  /**
   * Declaração de variáveis
   */
  private pagina: string;
  private mes: number;
  private ano: number;
  private referencia: string;
  private filtro: string;

  private datepipe: DatePipe;

  public listaEventos = new Array<HoleriteEvento>();

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected eventoService: HoleriteEventoService,
    protected holeriteService: HoleriteService) {
    super(new Holerite(), injector, Holerite.converteJson, holeriteService);
    this.datepipe = new DatePipe('pt');
    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.pagina = params['pagina'];
        this.mes = +params['mes'];
        this.ano = +params['ano'];
        this.referencia = params['referencia'];
        this.filtro = params['filtro'];
      });
  }


  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      calculo: [null, [Validators.required]],
      referencia: [null, [Validators.required]],
      mes: [null, [Validators.required]],
      salario_base: [null, [Validators.required]],
      salario_adicional: [null, [Validators.required]],
      salario_total: [null, [Validators.required]],
      valor_desconto: [null, [Validators.required]],
      valor_bruto: [null, [Validators.required]],
      valor_liquido: [null, [Validators.required]],
      valor_fgts: [null, [Validators.required]],
      base_fgts: [null, [Validators.required]],
      base_inss: [null, [Validators.required]],
      base_irrf: [null, [Validators.required]],
      excedente_inss: [null, [Validators.required]],
      funcionario: [null, [Validators.required]],
      orgao: [null, [Validators.required]],
      exercicio: [null, [Validators.required]],
    });
    this.entidadeForm.disable();
  }

  protected parametrosExtras(): {} {
    return { relations: 'funcionario,funcionario.pessoa,funcionario.cargo,funcionario.departamento' };
  }

  protected afterInit(): void {
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: Holerite): void {
  }

  protected afterLoad() {
    this.entidade.funcionario.data_admissao = new DateFormatPipe().transform(this.entidade.funcionario.data_admissao, []);
    this.eventoService.filtrar(1, -1,
      {
        holerite_id: this.entidade.id,
        relations: 'evento',
        orderBy: 'quantidade$DESC'
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaEventos = res.content;
      });
  }

  public voltar() {
    if (!this.filtro || this.filtro === '') {
      this.router.navigate([`/remuneracao-servidores/pesquisa`, this.pagina, this.mes, this.ano, this.referencia]);
    } else {
      this.router.navigate([`/remuneracao-servidores/pesquisa?`,
      this.pagina, this.mes, this.ano, this.referencia, this.filtro]);
    }
  }

}
