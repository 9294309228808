import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../../assinaturas/assinaturas';
import { AnexoLrfFederalService } from '../../../service/anexo-lrf-federal.service';

@Directive()
export class Anexo3RreoLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoFederalServico: AnexoLrfFederalService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected assinaturaControleInterno: boolean) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
    this.anexoFederalServico.obterAnexo3(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(null, this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            await this.conteudo(dados),
            'landscape', 'RREO ANEXO3 - DEMONSTRATIVO DA RECEITA CORRENTE LÍQUIDA',
            {
              linhas: {
                hLineWidth() { return 1; },
                vLineWidth() { return 1; },
                hLineColor() { return 'black'; },
                paddingLeft() { return 2; },
                paddingRight() { return 2; }
              }
            }, true, false);
        } else {
          this.funcaoService.exportar(formato, this.normalizar(dados), 'RREO ANEXO3 - DEMONSTRATIVO DA RECEITA CORRENTE LÍQUIDA', this.colunas());
        }
      });
  }

  private async conteudo(dados: any[]): Promise<{}[]> {
    const registros: {}[] = [
      [
        { text: this.login.orgao.nome, bold: true, border: [false, false, false, false], colSpan: 15, fontSize: 9, margin: [0, 0, 0, 0] },
        '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      [
        { text: 'RELATÓRIO RESUMIDO DA EXECUÇÃO ORÇAMENTÁRIA', bold: true, border: [false, false, false, false], colSpan: 15, fontSize: 6, margin: [0, 0, 0, 0] },
        '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      [
        { text: 'DEMONSTRATIVO DA RECEITA CORRENTE LÍQUIDA', bold: true, border: [false, false, false, false], colSpan: 15, fontSize: 6, margin: [0, 0, 0, 0] },
        '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      [
        { text: 'ORÇAMENTOS FISCAL E DA SEGURIDADE SOCIAL', bold: true, border: [false, false, false, false], colSpan: 15, fontSize: 6, margin: [0, 0, 0, 0] },
        '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      [
        {
          text: `REFERÊNCIA: ${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano}`,
          bold: true, border: [false, false, false, false], colSpan: 15, fontSize: 6, margin: [0, 0, 0, 0]
        },
        '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      [
        { text: 'RREO - Anexo 3 (LRF, Art. 53, inciso I)', bold: true, border: [false, false, false, false], colSpan: 15, fontSize: 6, margin: [0, 0, 0, 0] },
        '', '', '', '', '', '', '', '', '', '', '', '', '', ''
      ],
      [
        {
          text: 'ESPECIFICAÇÃO',
          rowSpan: 2, alignment: 'center', bold: true, fontSize: 5
        },
        {
          text: 'EVOLUÇÃO DA RECEITA REALIZADA NOS ÚLTIMOS DOZE MESES',
          alignment: 'center',
          bold: true,
          colSpan: 12, fontSize: 5
        }, '', '', '', '', '', '', '', '', '', '', '',
        {
          text: 'Total 12 meses',
          rowSpan: 2, alignment: 'center', bold: true, fontSize: 5
        },
        {
          text: 'PREVISÃO ATUALIZADA',
          rowSpan: 2, alignment: 'center', bold: true, fontSize: 5
        }
      ],
      [
        { text: '' },
        { text: this.retornarNomeMes(-11), alignment: 'center', bold: true },
        { text: this.retornarNomeMes(-10), alignment: 'center', bold: true },
        { text: this.retornarNomeMes(-9), alignment: 'center', bold: true },
        { text: this.retornarNomeMes(-8), alignment: 'center', bold: true },
        { text: this.retornarNomeMes(-7), alignment: 'center', bold: true },
        { text: this.retornarNomeMes(-6), alignment: 'center', bold: true },
        { text: this.retornarNomeMes(-5), alignment: 'center', bold: true },
        { text: this.retornarNomeMes(-4), alignment: 'center', bold: true },
        { text: this.retornarNomeMes(-3), alignment: 'center', bold: true },
        { text: this.retornarNomeMes(-2), alignment: 'center', bold: true },
        { text: this.retornarNomeMes(-1), alignment: 'center', bold: true },
        { text: this.retornarNomeMes(0), alignment: 'center', bold: true }
      ]
    ];

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);

    const titulos = this.funcaoService.agrupar(dados[0], ['titulo', 'somador'],
      ['soma0', 'soma1', 'soma2', 'soma3', 'soma4', 'soma5', 'soma6', 'soma7', 'soma8', 'soma9', 'soma10', 'soma11', 'previsto']);
    let total0 = 0;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    let total7 = 0;
    let total8 = 0;
    let total9 = 0;
    let total10 = 0;
    let total11 = 0;
    let previsto = 0;
    for (const titulo of titulos) {
      registros.push([
        { text: titulo.grupo['titulo'], fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma11']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma10']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma9']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma8']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma7']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma6']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma5']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma4']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma3']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma2']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma1']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma0']), alignment: 'right', fontSize: 5, bold: true },
        {
          text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['soma0'] + +titulo.totalizadores['soma1'] + +titulo.totalizadores['soma2'] + +titulo.totalizadores['soma3'] +
            +titulo.totalizadores['soma4'] + +titulo.totalizadores['soma5'] + +titulo.totalizadores['soma6'] + +titulo.totalizadores['soma7'] +
            +titulo.totalizadores['soma8'] + +titulo.totalizadores['soma9'] + +titulo.totalizadores['soma10'] + +titulo.totalizadores['soma11']),
          alignment: 'right', fontSize: 5, bold: true
        },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['previsto']), alignment: 'right', fontSize: 5, bold: true }
      ]);
      total0 += titulo.grupo['somador'] && +titulo.totalizadores['soma0'] > 0 ? +titulo.totalizadores['soma0'] : +titulo.totalizadores['soma0'] * -1;
      total1 += titulo.grupo['somador'] && +titulo.totalizadores['soma1'] > 0 ? +titulo.totalizadores['soma1'] : +titulo.totalizadores['soma1'] * -1;
      total2 += titulo.grupo['somador'] && +titulo.totalizadores['soma2'] > 0 ? +titulo.totalizadores['soma2'] : +titulo.totalizadores['soma2'] * -1;
      total3 += titulo.grupo['somador'] && +titulo.totalizadores['soma3'] > 0 ? +titulo.totalizadores['soma3'] : +titulo.totalizadores['soma3'] * -1;
      total4 += titulo.grupo['somador'] && +titulo.totalizadores['soma4'] > 0 ? +titulo.totalizadores['soma4'] : +titulo.totalizadores['soma4'] * -1;
      total5 += titulo.grupo['somador'] && +titulo.totalizadores['soma5'] > 0 ? +titulo.totalizadores['soma5'] : +titulo.totalizadores['soma5'] * -1;
      total6 += titulo.grupo['somador'] && +titulo.totalizadores['soma6'] > 0 ? +titulo.totalizadores['soma6'] : +titulo.totalizadores['soma6'] * -1;
      total7 += titulo.grupo['somador'] && +titulo.totalizadores['soma7'] > 0 ? +titulo.totalizadores['soma7'] : +titulo.totalizadores['soma7'] * -1;
      total8 += titulo.grupo['somador'] && +titulo.totalizadores['soma8'] > 0 ? +titulo.totalizadores['soma8'] : +titulo.totalizadores['soma8'] * -1;
      total9 += titulo.grupo['somador'] && +titulo.totalizadores['soma9'] > 0 ? +titulo.totalizadores['soma9'] : +titulo.totalizadores['soma9'] * -1;
      total10 += titulo.grupo['somador'] && +titulo.totalizadores['soma10'] > 0 ? +titulo.totalizadores['soma10'] : +titulo.totalizadores['soma10'] * -1;
      total11 += titulo.grupo['somador'] && +titulo.totalizadores['soma11'] > 0 ? +titulo.totalizadores['soma11'] : +titulo.totalizadores['soma11'] * -1;
      previsto += titulo.grupo['somador'] && +titulo.totalizadores['previsto'] > 0 ? +titulo.totalizadores['previsto'] : +titulo.totalizadores['previsto'] * -1;
      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo',
        ['soma0', 'soma1', 'soma2', 'soma3', 'soma4', 'soma5', 'soma6', 'soma7', 'soma8', 'soma9', 'soma10', 'soma11', 'previsto']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          registros.push([
            { text: grupo.grupo, fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma11']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma10']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma9']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma8']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma7']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma6']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma5']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma4']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma3']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma2']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma1']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma0']), alignment: 'right', fontSize: 5, bold: true },
            {
              text: this.funcaoService.convertToBrNumber(
                +grupo.totalizadores['soma0'] + +grupo.totalizadores['soma1'] + +grupo.totalizadores['soma2'] + +grupo.totalizadores['soma3'] +
                +grupo.totalizadores['soma4'] + +grupo.totalizadores['soma5'] + +grupo.totalizadores['soma6'] + +grupo.totalizadores['soma7'] +
                +grupo.totalizadores['soma8'] + +grupo.totalizadores['soma9'] + +grupo.totalizadores['soma10'] + +grupo.totalizadores['soma11']),
              alignment: 'right', fontSize: 5, bold: true
            },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsto']), alignment: 'right', fontSize: 5, bold: true }
          ]);


          for (const item of grupo.registros) {
            if (item.nome != '') {
              registros.push([
                {
                  text: item.nome, fontSize: 5, margin: [5, 0, 0, 0]
                },
                { text: this.funcaoService.convertToBrNumber(item.soma11), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma10), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma9), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma8), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma7), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma6), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma5), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma4), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma3), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma2), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma1), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma0), alignment: 'right', fontSize: 5 },
                {
                  text: this.funcaoService.convertToBrNumber(
                    +item.soma0 + +item.soma1 + +item.soma2 + +item.soma3 + +item.soma4 + +item.soma5 + +item.soma6 +
                    +item.soma7 + +item.soma8 + +item.soma9 + +item.soma10 + +item.soma11
                  ), alignment: 'right', fontSize: 5
                },
                { text: this.funcaoService.convertToBrNumber(item.previsto), alignment: 'right', fontSize: 5 }
              ]);
            }
          }

        }
      }
    }
    registros.push([
      {
        text: 'RECEITA CORRENTE LÍQUIDA (III) = (I - II)', fontSize: 5, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(total11), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total10), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total9), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total8), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total7), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total6), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total5), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total4), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total0), alignment: 'right', fontSize: 5, bold: true },
      {
        text: this.funcaoService.convertToBrNumber(
          +total0 + +total1 + +total2 + +total3 + +total4 + +total5 + +total6 + +total7 + +total8 + +total9 + +total10 + +total11),
        alignment: 'right', fontSize: 5, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(previsto), alignment: 'right', fontSize: 5, bold: true }
    ]);

    registros.push([
      {
        text: dados[1][0].titulo, fontSize: 5,
      },
      { text: this.funcaoService.convertToBrNumber(dados[1][0].soma11), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][0].soma10), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][0].soma9), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][0].soma8), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][0].soma7), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][0].soma6), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][0].soma5), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][0].soma4), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][0].soma3), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][0].soma2), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][0].soma1), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][0].soma0), alignment: 'right', fontSize: 5 },
      {
        text: this.funcaoService.convertToBrNumber(
          +dados[1][0].soma0 + +dados[1][0].soma1 + +dados[1][0].soma2 + +dados[1][0].soma3 + +dados[1][0].soma4 + +dados[1][0].soma5 + +dados[1][0].soma6 +
          +dados[1][0].soma7 + +dados[1][0].soma8 + +dados[1][0].soma9 + +dados[1][0].soma10 + +dados[1][0].soma11
        ), alignment: 'right', fontSize: 5
      },
      { text: this.funcaoService.convertToBrNumber(dados[1][0].previsto), alignment: 'right', fontSize: 5 }
    ]);

    total11 -= +dados[1][0].soma11;
    total10 -= +dados[1][0].soma10;
    total9 -= +dados[1][0].soma9;
    total8 -= +dados[1][0].soma8;
    total7 -= +dados[1][0].soma7;
    total6 -= +dados[1][0].soma6;
    total5 -= +dados[1][0].soma5;
    total4 -= +dados[1][0].soma4;
    total3 -= +dados[1][0].soma3;
    total2 -= +dados[1][0].soma2;
    total1 -= +dados[1][0].soma1;
    total0 -= +dados[1][0].soma0;

    registros.push([
      {
        text: 'RECEITA CORRENTE LÍQUIDA AJUSTADA PARA PARA CÁLCULO DOS LIMITES DE ENDIVIDAMENTO (V) = (III - IV)', fontSize: 5, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(total11), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total10), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total9), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total8), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total7), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total6), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total5), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total4), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total0), alignment: 'right', fontSize: 5, bold: true },
      {
        text: this.funcaoService.convertToBrNumber(
          +total0 + +total1 + +total2 + +total3 + +total4 + +total5 + +total6 + +total7 + +total8 + +total9 + +total10 + +total11),
        alignment: 'right', fontSize: 5, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(previsto), alignment: 'right', fontSize: 5, bold: true }
    ]);



    registros.push([
      {
        text: dados[1][1].titulo, fontSize: 5,
      },
      { text: this.funcaoService.convertToBrNumber(dados[1][1].soma11), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][1].soma10), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][1].soma9), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][1].soma8), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][1].soma7), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][1].soma6), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][1].soma5), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][1].soma4), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][1].soma3), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][1].soma2), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][1].soma1), alignment: 'right', fontSize: 5 },
      { text: this.funcaoService.convertToBrNumber(dados[1][1].soma0), alignment: 'right', fontSize: 5 },
      {
        text: this.funcaoService.convertToBrNumber(
          +dados[1][1].soma0 + +dados[1][1].soma1 + +dados[1][1].soma2 + +dados[1][1].soma3 + +dados[1][1].soma4 + +dados[1][1].soma5 + +dados[1][1].soma6 +
          +dados[1][1].soma7 + +dados[1][1].soma8 + +dados[1][1].soma9 + +dados[1][1].soma10 + +dados[1][1].soma11
        ), alignment: 'right', fontSize: 5
      },
      { text: this.funcaoService.convertToBrNumber(dados[1][1].previsto), alignment: 'right', fontSize: 5 }
    ]);

    total11 -= +dados[1][1].soma11;
    total10 -= +dados[1][1].soma10;
    total9 -= +dados[1][1].soma9;
    total8 -= +dados[1][1].soma8;
    total7 -= +dados[1][1].soma7;
    total6 -= +dados[1][1].soma6;
    total5 -= +dados[1][1].soma5;
    total4 -= +dados[1][1].soma4;
    total3 -= +dados[1][1].soma3;
    total2 -= +dados[1][1].soma2;
    total1 -= +dados[1][1].soma1;
    total0 -= +dados[1][1].soma0;

    registros.push([
      {
        text: 'RECEITA CORRENTE LÍQUIDA AJUSTADA PARA  CÁLCULO DOS LIMITES DA DESPESA COM PESSOAL (VII) = (V - VI)', fontSize: 5, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(total11), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total10), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total9), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total8), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total7), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total6), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total5), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total4), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total0), alignment: 'right', fontSize: 5, bold: true },
      {
        text: this.funcaoService.convertToBrNumber(
          +total0 + +total1 + +total2 + +total3 + +total4 + +total5 + +total6 + +total7 + +total8 + +total9 + +total10 + +total11),
        alignment: 'right', fontSize: 5, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(previsto), alignment: 'right', fontSize: 5, bold: true }
    ]);


    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 45, 45],
        body: registros
      }
    },
    {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*'],
        body: assinaturas
      }
    }
    ];
  }

  retornarNomeMes(diminuir: number) {
    const fator: number = +this.mes + +diminuir;
    if (fator > 0) {
      return ('00' + fator).slice(-2)
        + `/${this.exercicio.ano}`;
    } else {
      const meses: number = +12 + +fator;
      return ('00' + meses).slice(-2) + `/${+this.exercicio.ano - 1}`;
    }
  }

  public normalizar(lista) {
    const listaExportar = []
    let total0 = 0;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    let total7 = 0;
    let total8 = 0;
    let total9 = 0;
    let total10 = 0;
    let total11 = 0;
    let previsto = 0;

    const titulos = this.funcaoService.agrupar(lista, ['titulo', 'somador'],
      ['soma0', 'soma1', 'soma2', 'soma3', 'soma4', 'soma5', 'soma6', 'soma7', 'soma8', 'soma9', 'soma10', 'soma11', 'previsto']);

    for (const titulo of titulos) {
      const primeira_linha = {
        especificacao: titulo.grupo['titulo'],
        soma11: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma11']),
        soma10: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma10']),
        soma9: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma9']),
        soma8: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma8']),
        soma7: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma7']),
        soma6: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma6']),
        soma5: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma5']),
        soma4: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma4']),
        soma3: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma3']),
        soma2: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma2']),
        soma1: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma1']),
        soma0: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma0']),
        total_12_meses: this.funcaoService.convertToBrNumber(+titulo.totalizadores['soma0'] + +titulo.totalizadores['soma1'] + +titulo.totalizadores['soma2'] + +titulo.totalizadores['soma3'] +
          +titulo.totalizadores['soma4'] + +titulo.totalizadores['soma5'] + +titulo.totalizadores['soma6'] + +titulo.totalizadores['soma7'] +
          +titulo.totalizadores['soma8'] + +titulo.totalizadores['soma9'] + +titulo.totalizadores['soma10'] + +titulo.totalizadores['soma11']),
        previsto: this.funcaoService.convertToBrNumber(titulo.totalizadores['previsto'])
      }
      listaExportar.push(primeira_linha)

      total0 += titulo.grupo['somador'] && +titulo.totalizadores['soma0'] > 0 ? +titulo.totalizadores['soma0'] : +titulo.totalizadores['soma0'] * 1;
      total1 += titulo.grupo['somador'] && +titulo.totalizadores['soma1'] > 0 ? +titulo.totalizadores['soma1'] : +titulo.totalizadores['soma1'] * 1;
      total2 += titulo.grupo['somador'] && +titulo.totalizadores['soma2'] > 0 ? +titulo.totalizadores['soma2'] : +titulo.totalizadores['soma2'] * 1;
      total3 += titulo.grupo['somador'] && +titulo.totalizadores['soma3'] > 0 ? +titulo.totalizadores['soma3'] : +titulo.totalizadores['soma3'] * 1;
      total4 += titulo.grupo['somador'] && +titulo.totalizadores['soma4'] > 0 ? +titulo.totalizadores['soma4'] : +titulo.totalizadores['soma4'] * 1;
      total5 += titulo.grupo['somador'] && +titulo.totalizadores['soma5'] > 0 ? +titulo.totalizadores['soma5'] : +titulo.totalizadores['soma5'] * 1;
      total6 += titulo.grupo['somador'] && +titulo.totalizadores['soma6'] > 0 ? +titulo.totalizadores['soma6'] : +titulo.totalizadores['soma6'] * 1;
      total7 += titulo.grupo['somador'] && +titulo.totalizadores['soma7'] > 0 ? +titulo.totalizadores['soma7'] : +titulo.totalizadores['soma7'] * 1;
      total8 += titulo.grupo['somador'] && +titulo.totalizadores['soma8'] > 0 ? +titulo.totalizadores['soma8'] : +titulo.totalizadores['soma8'] * 1;
      total9 += titulo.grupo['somador'] && +titulo.totalizadores['soma9'] > 0 ? +titulo.totalizadores['soma9'] : +titulo.totalizadores['soma9'] * 1;
      total10 += titulo.grupo['somador'] && +titulo.totalizadores['soma10'] > 0 ? +titulo.totalizadores['soma10'] : +titulo.totalizadores['soma10'] * 1;
      total11 += titulo.grupo['somador'] && +titulo.totalizadores['soma11'] > 0 ? +titulo.totalizadores['soma11'] : +titulo.totalizadores['soma11'] * 1;
      previsto += titulo.grupo['somador'] && +titulo.totalizadores['previsto'] > 0 ? +titulo.totalizadores['previsto'] : +titulo.totalizadores['previsto'] * 1;

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo',
        ['soma0', 'soma1', 'soma2', 'soma3', 'soma4', 'soma5', 'soma6', 'soma7', 'soma8', 'soma9', 'soma10', 'soma11', 'previsto']);

      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          const linha_grupo = {
            especificacao: grupo.grupo,
            soma11: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma11']),
            soma10: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma10']),
            soma9: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma9']),
            soma8: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma8']),
            soma7: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma7']),
            soma6: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma6']),
            soma5: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma5']),
            soma4: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma4']),
            soma3: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma3']),
            soma2: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma2']),
            soma1: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma1']),
            soma0: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma0']),
            total_12_meses: this.funcaoService.convertToBrNumber(+grupo.totalizadores['soma0'] + +grupo.totalizadores['soma1'] + +grupo.totalizadores['soma2'] + +grupo.totalizadores['soma3'] +
              +grupo.totalizadores['soma4'] + +grupo.totalizadores['soma5'] + +grupo.totalizadores['soma6'] + +grupo.totalizadores['soma7'] +
              +grupo.totalizadores['soma8'] + +grupo.totalizadores['soma9'] + +grupo.totalizadores['soma10'] + +grupo.totalizadores['soma11']),
            previsto: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsto'])
          }
          listaExportar.push(linha_grupo)
        }

        for (const item of grupo.registros) {
          if (item.nome != '') {
            const linha_item = {
              especificacao: item.nome,
              soma11: this.funcaoService.convertToBrNumber(item.soma11),
              soma10: this.funcaoService.convertToBrNumber(item.soma10),
              soma9: this.funcaoService.convertToBrNumber(item.soma9),
              soma8: this.funcaoService.convertToBrNumber(item.soma8),
              soma7: this.funcaoService.convertToBrNumber(item.soma7),
              soma6: this.funcaoService.convertToBrNumber(item.soma6),
              soma5: this.funcaoService.convertToBrNumber(item.soma5),
              soma4: this.funcaoService.convertToBrNumber(item.soma4),
              soma3: this.funcaoService.convertToBrNumber(item.soma3),
              soma2: this.funcaoService.convertToBrNumber(item.soma2),
              soma1: this.funcaoService.convertToBrNumber(item.soma1),
              soma0: this.funcaoService.convertToBrNumber(item.soma0),
              total_12_meses: this.funcaoService.convertToBrNumber(+item.soma0 + +item.soma1 + +item.soma2 + +item.soma3 +
                +item.soma4 + +item.soma5 + +item.soma6 + +item.soma7 +
                +item.soma8 + +item.soma9 + +item.soma10 + +item.soma11),
              previsto: this.funcaoService.convertToBrNumber(item.previsto)

            }
            listaExportar.push(linha_item)
          }
        }
      }
    }

    const linha_receita_liquida = {
      especificacao: 'RECEITA CORRENTE LÍQUIDA (III) = (I - II)',
      soma11: this.funcaoService.convertToBrNumber(total11),
      soma10: this.funcaoService.convertToBrNumber(total10),
      soma9: this.funcaoService.convertToBrNumber(total9),
      soma8: this.funcaoService.convertToBrNumber(total8),
      soma7: this.funcaoService.convertToBrNumber(total7),
      soma6: this.funcaoService.convertToBrNumber(total6),
      soma5: this.funcaoService.convertToBrNumber(total5),
      soma4: this.funcaoService.convertToBrNumber(total4),
      soma3: this.funcaoService.convertToBrNumber(total3),
      soma2: this.funcaoService.convertToBrNumber(total2),
      soma1: this.funcaoService.convertToBrNumber(total1),
      soma0: this.funcaoService.convertToBrNumber(total0),
      total_12_meses: this.funcaoService.convertToBrNumber(+total0 + +total1 + +total2 + +total3 +
        +total4 + +total5 + +total6 + +total7 +
        +total8 + +total9 + +total10 + +total11),
      previsto: this.funcaoService.convertToBrNumber(previsto)

    }
    listaExportar.push(linha_receita_liquida)

    const linha_transferencia_individual = {
      especificacao: '( - ) Transferências obrigatórias da União relativas às emendas individuais (art. 166-A, § 1º, da CF) (IV)',
      soma11: this.funcaoService.convertToBrNumber(0),
      soma10: this.funcaoService.convertToBrNumber(0),
      soma9: this.funcaoService.convertToBrNumber(0),
      soma8: this.funcaoService.convertToBrNumber(0),
      soma7: this.funcaoService.convertToBrNumber(0),
      soma6: this.funcaoService.convertToBrNumber(0),
      soma5: this.funcaoService.convertToBrNumber(0),
      soma4: this.funcaoService.convertToBrNumber(0),
      soma3: this.funcaoService.convertToBrNumber(0),
      soma2: this.funcaoService.convertToBrNumber(0),
      soma1: this.funcaoService.convertToBrNumber(0),
      soma0: this.funcaoService.convertToBrNumber(0),
      total_12_meses: this.funcaoService.convertToBrNumber(0),
      previsto: this.funcaoService.convertToBrNumber(0)

    }
    listaExportar.push(linha_transferencia_individual)

    const linha_receita_liquida2 = {
      especificacao: 'RECEITA CORRENTE LÍQUIDA (III) = (I - II)',
      soma11: this.funcaoService.convertToBrNumber(total11),
      soma10: this.funcaoService.convertToBrNumber(total10),
      soma9: this.funcaoService.convertToBrNumber(total9),
      soma8: this.funcaoService.convertToBrNumber(total8),
      soma7: this.funcaoService.convertToBrNumber(total7),
      soma6: this.funcaoService.convertToBrNumber(total6),
      soma5: this.funcaoService.convertToBrNumber(total5),
      soma4: this.funcaoService.convertToBrNumber(total4),
      soma3: this.funcaoService.convertToBrNumber(total3),
      soma2: this.funcaoService.convertToBrNumber(total2),
      soma1: this.funcaoService.convertToBrNumber(total1),
      soma0: this.funcaoService.convertToBrNumber(total0),
      total_12_meses: this.funcaoService.convertToBrNumber(+total0 + +total1 + +total2 + +total3 +
        +total4 + +total5 + +total6 + +total7 +
        +total8 + +total9 + +total10 + +total11),
      previsto: this.funcaoService.convertToBrNumber(previsto)

    }
    listaExportar.push(linha_receita_liquida2)

    const linha_transferencia_individual2 = {
      especificacao: '( - ) Transferências obrigatórias da União relativas às emendas de bancada (art. 166, § 16, da CF) (VI)',
      soma11: this.funcaoService.convertToBrNumber(0),
      soma10: this.funcaoService.convertToBrNumber(0),
      soma9: this.funcaoService.convertToBrNumber(0),
      soma8: this.funcaoService.convertToBrNumber(0),
      soma7: this.funcaoService.convertToBrNumber(0),
      soma6: this.funcaoService.convertToBrNumber(0),
      soma5: this.funcaoService.convertToBrNumber(0),
      soma4: this.funcaoService.convertToBrNumber(0),
      soma3: this.funcaoService.convertToBrNumber(0),
      soma2: this.funcaoService.convertToBrNumber(0),
      soma1: this.funcaoService.convertToBrNumber(0),
      soma0: this.funcaoService.convertToBrNumber(0),
      previsto: this.funcaoService.convertToBrNumber(0)

    }
    listaExportar.push(linha_transferencia_individual2)

    const linha_receita_liquida_ajustada = {
      especificacao: 'RECEITA CORRENTE LÍQUIDA AJUSTADA PARA  CÁLCULO DOS LIMITES DA DESPESA COM PESSOAL (VII) = (V - VI)',
      soma11: this.funcaoService.convertToBrNumber(total11),
      soma10: this.funcaoService.convertToBrNumber(total10),
      soma9: this.funcaoService.convertToBrNumber(total9),
      soma8: this.funcaoService.convertToBrNumber(total8),
      soma7: this.funcaoService.convertToBrNumber(total7),
      soma6: this.funcaoService.convertToBrNumber(total6),
      soma5: this.funcaoService.convertToBrNumber(total5),
      soma4: this.funcaoService.convertToBrNumber(total4),
      soma3: this.funcaoService.convertToBrNumber(total3),
      soma2: this.funcaoService.convertToBrNumber(total2),
      soma1: this.funcaoService.convertToBrNumber(total1),
      soma0: this.funcaoService.convertToBrNumber(total0),
      total_12_meses: this.funcaoService.convertToBrNumber(+total0 + +total1 + +total2 + +total3 +
        +total4 + +total5 + +total6 + +total7 +
        +total8 + +total9 + +total10 + +total11),
      previsto: this.funcaoService.convertToBrNumber(previsto)

    }
    listaExportar.push(linha_receita_liquida_ajustada)

    return listaExportar
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'ESPECIFICAÇÃO', coluna: 'especificacao', bold: true },
      { titulo: this.retornarNomeMes(-11), coluna: 'soma11' },
      { titulo: this.retornarNomeMes(-10), coluna: 'soma10' },
      { titulo: this.retornarNomeMes(-9), coluna: 'soma9' },
      { titulo: this.retornarNomeMes(-8), coluna: 'soma8' },
      { titulo: this.retornarNomeMes(-7), coluna: 'soma7' },
      { titulo: this.retornarNomeMes(-6), coluna: 'soma6' },
      { titulo: this.retornarNomeMes(-5), coluna: 'soma5' },
      { titulo: this.retornarNomeMes(-4), coluna: 'soma4' },
      { titulo: this.retornarNomeMes(-3), coluna: 'soma3' },
      { titulo: this.retornarNomeMes(-2), coluna: 'soma2' },
      { titulo: this.retornarNomeMes(-1), coluna: 'soma1' },
      { titulo: this.retornarNomeMes(0), coluna: 'soma0' },
      { titulo: 'Total 12 meses', coluna: 'total_12_meses' },
      { titulo: 'PREVISÃO ATUALIZADA', coluna: 'previsto' },
    ];
    return colunasDefault;
  }

}
