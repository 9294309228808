import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Estoque, EstoqueService, LoginContabil } from 'eddydata-lib';


declare var $: any;

@Component({
  selector: 'app-selecionar-estoque-usuario-dlg',
  templateUrl: './selecionar-estoque-usuario-dlg.component.html'
})
export class SelecionarEstoqueUsuarioDlgComponent implements OnInit, OnChanges {

  @Input() visualizar: boolean = false;
  @Input() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Input() login: LoginContabil;

  public estoques: Estoque[] = [];
  public estoque: Estoque;

  @Output() onSelecionar: EventEmitter<Estoque> = new EventEmitter();

  constructor(
    private estoqueService: EstoqueService
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visualizar && this.visualizar)
      this.estoque = this.login.estoque;
    this.preencherEstoques();
  }

  public onSelecionarEvent() {
    this.onSelecionar.emit(this.estoque);
    this.hide();
  }

  private preencherEstoques() {
    this.estoqueService.estoquesUsuarios(this.login.usuario.id, this.login.orgao.id).subscribe((data) => {
      if (!data || !data.content || data.content.length === 0)
        return;

      for (const estoque of data.content) {
        delete estoque.usuarios
        delete estoque.usuarios_estoque
      }
      this.estoques = data.content;
    })
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
    this.preencherEstoques();
    this.estoque = this.login.estoque;
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }
}