import { Component, Input, OnInit } from '@angular/core';
import { Audesp4ComprasServicos, Audesp4ComprasServicosTI, Audesp4LoteNaoObra } from 'eddydata-lib';
import { LicitacaoAudespService } from '../../service/licitacao-audesp.service';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-lote',
  templateUrl: './licitacao-audesp-lote.component.html'
})
export class LicitacaoAudespLoteComponent extends BaseLicitacaoAudesp implements OnInit {

  @Input() public objeto: Audesp4ComprasServicos | Audesp4ComprasServicosTI;

  public itemAtual: Audesp4LoteNaoObra;
  public itemAnterior: Audesp4LoteNaoObra;

  public opcoesTipoExecucao: { id: number, nome: string }[];
  public listaReceita: { id: number, nome: string }[];
  public listaDespesa: { id: number, nome: string }[];

  constructor(private audespService: LicitacaoAudespService) {
    super()
  }

  ngOnInit(): void {
    this.opcoesTipoExecucao = [
      { id: 4, nome: 'Tarefa' },
      { id: 5, nome: 'Empreitada Integral' },
      { id: 9, nome: 'Contratação semi-integrada' },
      { id: 1, nome: 'Execução Direta' },
      { id: 2, nome: 'Empreitada por Preço Global' },
      { id: 3, nome: 'Empreitada por Preço Unitário' },
      { id: 6, nome: 'Contratação Integrada (RDC)' },
      { id: 7, nome: 'Concessão Pública com obra de engenharia' },
      { id: 8, nome: 'Não se aplica' },
    ];
    this.listaReceita = this.audespService.listarTipoReceita();
    this.listaDespesa = this.audespService.listarTipoDespesa();
  }

  public adicionar() {
    if (!this.objeto.Lote || this.objeto.Lote.length === 0) this.objeto.Lote = [];
    this.itemAtual = {
      NumeroLote: this.objeto.Lote.length + 1,
      DescricaoLote: '',
      Quantidade: 0,
      UnidadeMedida: '',
      TipoExecucao: this.opcoesTipoExecucao[0].id,
      ClassificacaoEconomica: {
        TipoClassificacaoEconomica: this.itemAtual?.ClassificacaoEconomica?.TipoClassificacaoEconomica,
        CodigoClassificacaoEconomica: this.itemAtual?.ClassificacaoEconomica.CodigoClassificacaoEconomica,
        ClassificacaoEconomicaOutros: undefined
      },
      OrcamentoLoteSim: [],
      OrcamentoLoteNao: 'N',
      LoteCompostoItensSim: [],
      LoteCompostoItensNao: 'N',
    }
    this.objeto.Lote.push(this.itemAtual);
  }

  public visualizar(item: Audesp4LoteNaoObra) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual['edicao'] = true;
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      let itm = this.objeto.Lote.find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.objeto.Lote.splice(this.objeto.Lote.indexOf(this.itemAtual), 1, itm)
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4LoteNaoObra) {
    this.itemAtual = undefined;
    this.objeto.Lote.splice(this.objeto.Lote.indexOf(item), 1)
  }

  public salvar() {
    this.itemAtual = undefined;
  }

  public aplicarClassificacao() {
    if (this.itemAtual.ClassificacaoEconomica) {
      if (!this.itemAtual.ClassificacaoEconomica.CodigoClassificacaoEconomica || !this.itemAtual.ClassificacaoEconomica.TipoClassificacaoEconomica) {
        toastr.warning('Informe a classificação econômica antes de aplicar a outros itens');
        return;
      }
      this.objeto.Lote.forEach(l => l.ClassificacaoEconomica = Object.assign({}, this.itemAtual.ClassificacaoEconomica))
    }
  }

  public aplicarTipoExecucao() {
    if (!this.itemAtual.TipoExecucao) {
      toastr.warning('Informe o tipo de execução antes de aplicar a outros itens');
      return;
    } else {
      this.objeto.Lote.forEach(l => l.TipoExecucao = +this.itemAtual.TipoExecucao)
    }
  }

}
