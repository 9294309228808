import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, FavorecidoTipo } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class FavorecidoTipoService extends BaseResourceService<FavorecidoTipo> {

  constructor(
    protected injector: Injector
  ) {
    super('favorecidos-tipos', injector);
  }
}
