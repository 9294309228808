import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EddyAutoComplete, Exercicio, ExercicioService, Favorecido, FavorecidoService, FuncaoService, GlobalService, LoginContabil } from 'eddydata-lib';
import { CompraService } from '../../compra/service/compra.service';
import { PendenciaCompra } from './pendencia-compra-rpt';
import * as toastr from 'toastr';

@Component({
  selector: 'app-pendencia-compra-rpt',
  templateUrl: './pendencia-compra-rpt.component.html'
})
export class PendenciaCompraRptComponent implements OnInit {


  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public login: LoginContabil;

  public listExercicio: Exercicio[];
  public exercicio: Exercicio;

  public numero: number;

  public favorecido: Favorecido;
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;

  public entidadeForm: FormGroup;
  protected fb: FormBuilder;

  // ========================================================================
  // ------------------------------ CONSTRUTOR ------------------------------
  // ========================================================================
  constructor(
    protected injector: Injector,
    private funcaoService: FuncaoService,
    private compraService: CompraService,
    private exercicioService: ExercicioService,
    private favorecidoService: FavorecidoService) {
    this.fb = this.injector.get(FormBuilder);
    this.login = GlobalService.obterSessaoLogin();
  }


  ngOnInit(): void {
    this.entidadeForm = this.fb.group({
      favorecido: [null]
    })
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id)
      .subscribe(dados => {
        this.listExercicio = dados.content;
        this.exercicio = this.login.exercicio;
      });

    this.favorecidoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('favorecido'), this.favorecidoService,
      'id', ['cpf_cnpj', 'nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] });
  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  public imprimir() {
    if (!this.validar())
      return;

    // const estoque = this.login.estoque ? this.login.estoque : this.login.setorAlmoxarifado.estoque;

    if (this.favorecidoAutoComplete?.lista?.length === 1) {
      this.compraService.buscaOfAlmoxarifadoEstoqueNaoObrigatorio(this.login.exercicio.id, this.login.orgao.id, this.numero, new Date(), null, this.favorecido.id)
        .subscribe((data) => {
          new PendenciaCompra(this.login).imprimir(data);
        }, (error) => this.funcaoService.acaoErro(error))
    } else {
      this.compraService.buscaOfAlmoxarifadoEstoqueNaoObrigatorio(this.login.exercicio.id, this.login.orgao.id, this.numero, new Date(), null)
        .subscribe((data) => {
          new PendenciaCompra(this.login).imprimir(data);
        }, (error) => this.funcaoService.acaoErro(error))
    }
  }


  private validar(): boolean {
    if (!this.exercicio || !this.exercicio.id) {
      toastr.warning('Informe exercicio para imprimir relação');
      return;
    }
    if (!this.numero && !this.entidadeForm.get('favorecido')) {
      toastr.warning(`Informe a OF. ou o Fornecedor para imprimir relação.`)
      return false;
    }
    return true;
  }


  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

}
