import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Convenio, Login } from 'eddydata-lib';
import { Subject } from 'rxjs';

@Component({
  selector: 'lib-convenio-licitacao',
  templateUrl: './convenio-licitacao.component.html',
})
export class ConvenioLicitacaoComponent implements OnInit, OnChanges, OnDestroy {


  protected unsubscribe: Subject<void> = new Subject();

  @Input() lista: Array<any>;
  @Input() entidade: Convenio;
  @Input() login: Login;

  constructor(
  ) { }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
  }

}
