import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Favorecido, FuncaoService, GlobalService, Login, Proposta, SituacaoProposta } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PropostaService } from '../../proposta/service/proposta.service';
// import * as toastr from 'toastr';

@Component({
  selector: 'lib-favorecido-licitacao',
  templateUrl: './favorecido-licitacao.component.html'
})
export class FavorecidoLicitacaoComponent implements OnInit, OnDestroy, OnChanges {

  @Input() entidade: Favorecido;
  @Input() login: Login;

  public lista: Proposta[];
  private situacoesProposta: { id: SituacaoProposta, nome: string, style: string, ordem: number }[];

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    public funcaoService: FuncaoService,
    public propostaService: PropostaService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.entidade && this.entidade?.id) {
      this.propostaService.obterPorFavorecido(this.entidade.id, this.login.orgao.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.lista = res.content
        })
    }
  }

  ngOnInit(): void {
    this.situacoesProposta = new GlobalService().obterSituacoesProposta();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public obterNomeProposta(item: Proposta): string {
    try {
      return this.situacoesProposta.find(p => p.id === item.situacao).nome;
    } catch (err) {
      return item.situacao;
    }
  }

  public obterEstiloProposta(item: Proposta): string {
    try {
      return this.situacoesProposta.find(p => p.id === item.situacao).style;
    } catch (err) {
      return 'secondary';
    }
  }
}
