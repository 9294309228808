import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EddyAutoComplete, FuncaoService, Login, Memorial, MemorialItem, ParametroLicitacao, Rcms, RcmsItem, Rpl, RplItem } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { MemorialItemService } from '../memorial-item/service/memorial-item.service';
import { RcmsService } from '../rcms/service/rcms.service';
import { RplService } from '../rpl/service/rpl.service';

declare var $: any;

@Component({
  selector: 'app-memorial-item-rpl',
  templateUrl: './memorial-item-rpl.component.html'
})
export class MemorialItemRplComponent implements OnInit, OnDestroy {

  @Input() lote: Memorial;
  @Input() login: Login;
  @Input() parametrosLicitacao: ParametroLicitacao;

  @Output() callback: EventEmitter<RplItem[]> = new EventEmitter();
  @Output() callbackRcms: EventEmitter<RcmsItem[]> = new EventEmitter();

  public rplAutoComplete: EddyAutoComplete<Rpl>;
  public rplSelecionado: Rpl;
  public itensImportados: MemorialItem[];

  public rcmsAutoComplete: EddyAutoComplete<Rcms>;
  public rcmsSelecionado: Rcms;

  protected unsubscribe: Subject<void> = new Subject();

  public importarRequisicao: boolean = false;

  public importes: { id: boolean, nome: string }[] = [
    { id: true, nome: 'Requisição' },
    { id: false, nome: 'RPL' },
  ];

  constructor(
    public funcaoService: FuncaoService,
    public rplService: RplService,
    public itemService: MemorialItemService,
    private rcmsService: RcmsService
  ) { }

  ngOnInit(): void {
    this.rplAutoComplete = new EddyAutoComplete(null, this.rplService,
      'numero', ['numero', 'exercicio.ano'], { orgao_id: this.login.orgao.id, 'exercicio.id': this.login.exercicio.id, orderBy: 'numero,exercicio.ano' }, { number: ['numero', 'exercicio.ano', 'rpl_numero_ano'] }
    );
    this.rcmsAutoComplete = new EddyAutoComplete(null, this.rcmsService,
      'numero', ['numero', 'exercicio.ano'], { orgao_id: this.login.orgao.id, 'exercicio.id': this.login.exercicio.id, orderBy: 'numero,exercicio.ano' }, { number: ['numero', 'exercicio.ano', 'rcms_numero_ano'] }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public carregarDadosRPL() {
    if (!this.rplSelecionado) return;
    this.rplService.obter({
      id: this.rplSelecionado.id, 'itens.memorial$null': null,
      relations: 'exercicio,orgao,tipo_contratacao,setor,requerente,responsavel,itens.produto_unidade.produto,itens.produto_unidade.unidade,itens.cotacoes'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (!res || !res.itens || res.itens.length === 0) {
          toastr.warning('Nenhum item não importado foi localizado neste RPL');
          return;
        }
        this.rplSelecionado = res;

        this.rplSelecionado.itens.forEach(rplItem => {
          rplItem.memorial = this.lote;
          rplItem.memorial_item = this.lote.itens?.find(m => m.produto?.id === rplItem.produto_unidade.produto.id);
        })
      });
  }

  public carregarDadosRCMS() {
    if (!this.rcmsSelecionado) return;

    this.rcmsService.obter({
      id: this.rcmsSelecionado.id, 'itens.memorial$null': null,
      relations: 'exercicio,orgao,setor,requerente,itens.produto_unidade.produto,itens.produto_unidade.unidade,itens.cotacoes,setor'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {

        if (!res || !res.itens || res.itens.length === 0) {
          toastr.warning('Nenhum item não importado foi localizado neste RPL');
          return;
        }
        this.rcmsSelecionado = res;

        this.rcmsSelecionado.itens.forEach(rcmsItem => {
          rcmsItem.memorial = this.lote;
          rcmsItem.memorial_item = this.lote.itens?.find(m => m.produto?.id === rcmsItem.produto_unidade.produto.id);
        })
      });
  }

  public importarRPL() {
    if (this.rplSelecionado) {
      this.callback.emit(this.rplSelecionado.itens.filter(m => m.memorial_item !== 'N'));

      $('#dialogImportarLoteRPL').modal('hide');
      toastr.success('Importação do RPL realizada com sucesso!');
      this.rplSelecionado = undefined
    };

    if (this.rcmsSelecionado) {
      this.callbackRcms.emit(this.rcmsSelecionado.itens.filter(m => m.memorial_item !== 'N'));

      $('#dialogImportarLoteRPL').modal('hide');
      toastr.success('Importação do RPL realizada com sucesso!');
      this.rcmsSelecionado = undefined
    }

  }

  public carregarItensImportados() {
    this.itemService.filtrar(0, 0, {
      memorial_id: this.lote.id, orderBy: 'rpl_itens.rpl.numero',
      relations: 'produto,rpl_itens.produto_unidade.produto,rpl_itens.rpl.exercicio'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.itensImportados = res.content;
        $('#dialogItensImportadosLote').modal('show');
      });
  }

}
