// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { AuditoriaAvaliacao } from './auditoria-avaliacao.model';

export class AvaliacaoChecklist extends BaseResourceModel {
  constructor(
    public id?: number,
    public pergunta?: string,
    public tipo_resposta?: string,
    public ordem?: number,
    public descricao_resposta?: string,
    public resposta?: boolean,
    public observacao?: string,
    public auditoria_avaliacao?: AuditoriaAvaliacao
    ) {
      super();
    }
    static converteJson(json: any): AvaliacaoChecklist {
      return Object.assign(new AvaliacaoChecklist(), json);
    }
}
