import { Component, Injector, Input } from '@angular/core';
import { BaseResourceListComponent, Coluna, EncerramentoContabilJustificativa, Filtro, GlobalService, LoginContabil } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { EncerramentoContabilJustificativaService } from '../service/encerramento-contabil-justificativa.service';

@Component({
  selector: 'lib-encerramento-contabil-justificativa-simples',
  templateUrl: './encerramento-contabil-justificativa-simples.component.html',
})
export class EncerramentoContabilJustificativaSimplesComponent extends BaseResourceListComponent<EncerramentoContabilJustificativa, LoginContabil> {

  public meses: any = {
    jan: { nome: 'JANEIRO', index: 0 },
    fav: { nome: 'FEVEREIRO', index: 1 },
    mar: { nome: 'MARÇO', index: 2 },
    abr: { nome: 'ABRIL', index: 3 },
    mai: { nome: 'MAIO', index: 4 },
    jun: { nome: 'JUNHO', index: 5 },
    jul: { nome: 'JULHO', index: 6 },
    ago: { nome: 'AGOSTO', index: 7 },
    set: { nome: 'SETEMBRO', index: 8 },
    out: { nome: 'OUTUBRO', index: 9 },
    nov: { nome: 'NOVEMBRO', index: 10 },
    dez: { nome: 'DEZEMBRO', index: 11 },
  };

  public _parametros: {};

  @Input() set parametros(val: {}) {
    this._parametros = val;
    this.ngOnInit();
  }

  @Input() set reload(val: boolean) {
      this.ngOnInit();
  }

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public globalService: GlobalService,
    private justificativaService: EncerramentoContabilJustificativaService) {
    super(justificativaService, injector);
  }

  protected condicoesGrid(): {} {
    return Object.assign({}, this._parametros);
  }

  protected ordenacaoGrid(): string[] {
    return ['data_cadastro$DESC']
  }

  protected filtrosGrid(): Filtro {
    return {
      date: ['date_cadastro'],
      text: ['descricao']
    };
  }

  protected afterInit(): void {  }

  protected acaoRemover(model: EncerramentoContabilJustificativa): Observable<EncerramentoContabilJustificativa> {
    return null;
  }
  protected colunasRelatorio(): string[] | Coluna[] {
    return [];
  }
  protected relations(): string {
    return 'encerramento,responsavel';
  }

  getMes(index) {
    let mes: any = Object.entries(this.meses).find((m) => m[1]['index'] === index);
    return mes[1]['nome'];
  }

}
