// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from './orgao.model';

export class ArquivoXml  extends BaseResourceModel {
  constructor(
    public id?: number,
    public ano?: number,
    public nome?: string,
    public caminho?: string,
    public mes?: number,
    public tipo?: string,
    public descricao?: string,
    public documento?: any,
    public tamanho?: number,
    public armazenado?: boolean,
    public data_cadastro?: Date,
    public orgao?: Orgao
    ) {
      super();
    }
    static converteJson(json: any): ArquivoXml {
      return Object.assign(new ArquivoXml(), json);
    }
}
