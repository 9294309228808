import { DatePipe } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContratoService, FuncaoGovernoService, RecursoService } from 'administrativo-lib';
import { Coluna, Contrato, EddyAutoComplete, Favorecido, FavorecidoService, FormatoExportacao, FuncaoService, GlobalService, LoginContabil, Modalidade, Relatorio, Setor, SetorService, TipoContratacao, Unidade, UnidadeService } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalidadeService } from '../../modalidade/service/modalidade.service';
import { TipoContratacaoService } from '../../tipo-contratacao/service/tipo-contratacao.service';

@Component({
  selector: 'app-contrato-rpt',
  templateUrl: './contrato-rpt.component.html'
})
export class ContratoRptComponent implements OnInit, OnDestroy {
  protected datepipe: DatePipe;
  login: LoginContabil = new LoginContabil();
  tipoContratacao: TipoContratacao;
  objeto: string;
  favorecido: Favorecido;
  requerente: Setor;
  secretaria: Unidade;
  modalidade: Modalidade;
  filtroFavorecido: boolean;
  filtroRequerente: boolean;
  filtroSecretaria: boolean;
  filtroContratacao: boolean;
  filtroValor: boolean;
  filtroObjeto: boolean;
  filtroModalidade: boolean;
  public selectedOrdem: string;
  // public selectedData: string;
  public ptBR: any;
  public listaRelatorio: Array<{ value: string, label: string }>;
  public listaContratacao: TipoContratacao[];
  public listaModalidade: Modalidade[];
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;
  public setorAutoComplete: EddyAutoComplete<Setor>;
  public unidadeAutoComplete: EddyAutoComplete<Unidade>;
  public relatorio = '*';
  public dataInicial: string;
  public dataFinal: string;
  public valorInicial: number;
  public valorFinal: number;
  protected unsubscribe: Subject<void> = new Subject();

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private router: Router,
    protected injector: Injector,
    protected messageService: MessageService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected funcaoGovernoService: FuncaoGovernoService,
    protected contratacaoService: TipoContratacaoService,
    protected recursoService: RecursoService,
    protected favorecidoService: FavorecidoService,
    protected setorService: SetorService,
    protected unidadeService: UnidadeService,
    protected modalidadeService: ModalidadeService,
    protected contratoService: ContratoService) {
  }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.login = GlobalService.obterSessaoLogin();
    this.selectedOrdem = 'numero';
    // this.selectedData = 'dt1';
    this.listaRelatorio = [];
    this.listaRelatorio.push({ label: 'TODOS OS CONTRATOS E ADITIVOS', value: '*' });
    this.listaRelatorio.push({ label: 'FILTRAR SOMENTE CONTRATOS', value: 'CONTRATO' });
    this.listaRelatorio.push({ label: 'FILTRAR SOMENTE ADITIVOS', value: 'ADITIVO' });
    this.listaRelatorio.push({ label: 'FILTRAR SOMENTE ATAS DE REGISTRO DE PREÇOS', value: 'ATAS_REGISTRO_PRECOS' });
    this.listaRelatorio.push({ label: 'CONTRATOS A VENCER', value: 'A_VENCER' });
    this.listaRelatorio.push({ label: 'CONTRATOS E ADITIVOS A VENCER', value: 'CA_A_VENCER' });

    this.contratacaoService.filtrar(0, 0, { orgao_id: this.login.orgao.id }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaContratacao = res ? res.content : [];
      }, error => this.funcaoService.acaoErro(error));

    this.modalidadeService.filtrar(1, -1, { 'cidade.id': this.login.cidade.id, orderBy: 'codigo' }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaModalidade = res ? res.content : new Array<Modalidade>();
      }, error => this.funcaoService.acaoErro(error));

    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['cpf_cnpj', 'nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );

    this.setorAutoComplete = new EddyAutoComplete(null, this.setorService,
      'id', ['id', 'nome'], { orgao_id: this.login.orgao.id, relations: 'orgao', orderBy: 'nome' }, { number: ['id'], text: ['nome'] });

    this.unidadeAutoComplete = new EddyAutoComplete(null, this.unidadeService,
      'id', ['codigo', 'nome'], { ppa_id: this.login.ppa.id, relations: 'orgao', orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = {};
    let subtitulo: string = ''
    if ((!this.dataInicial || !this.dataFinal) || (this.dataInicial === '' || this.dataFinal === '')) {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }

    if (this.filtroValor) {
      parametros['valor_contrato$ge'] = this.funcaoService.converteValorSQL(this.valorInicial);
      parametros['valor_contrato$le'] = this.funcaoService.converteValorSQL(this.valorFinal);
    }

    if (this.filtroContratacao && this.tipoContratacao) {
      parametros['tipo_contratacao.id'] = this.tipoContratacao.id;
    }

    if (this.filtroModalidade && this.modalidade?.id) {
      parametros['modalidade.id'] = this.modalidade.id;
    }

    if (this.filtroObjeto) {
      parametros['objeto$like'] = `%${this.objeto}%`;
    }

    if (this.filtroFavorecido && this.favorecido) {
      parametros['favorecido.id'] = this.favorecido.id;
    }

    if (this.filtroRequerente && this.requerente) {
      parametros['licitacao.setor.id'] = this.requerente.id;
      subtitulo += `Requerente: ${this.requerente.nome} `
    }

    if (this.filtroSecretaria && this.secretaria) {
      parametros['licitacao.setor.unidade.id'] = this.secretaria.id;
      subtitulo += `Secretaria: ${this.secretaria.nome} `
    }
    
    if (this.relatorio === 'ADITIVO') {
      parametros['tipo$ne'] = 'REGISTRO_PRECO';
      parametros['aditamentos.contrato$not_null'] = '';
      parametros['data_inicio$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
      parametros['data_inicio$le'] = this.funcaoService.converteDataSQL(this.dataFinal);
    } else if (this.relatorio === 'CONTRATO') {
      parametros['tipo$not_null'] = '';
      parametros['tipo$ne'] = 'REGISTRO_PRECO';
      parametros['data_inicio$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
      parametros['data_inicio$le'] = this.funcaoService.converteDataSQL(this.dataFinal);
    }else if (this.relatorio === 'ATAS_REGISTRO_PRECOS') {
      parametros['tipo'] = 'REGISTRO_PRECO';
      parametros['tipo$ne'] = 'CONTRATO';
      parametros['data_inicio$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
      parametros['data_inicio$le'] = this.funcaoService.converteDataSQL(this.dataFinal);
    } else if (this.relatorio === 'A_VENCER') {
      parametros['tipo$ne'] = 'REGISTRO_PRECO';
      parametros['data_termino$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
      parametros['data_termino$le'] = this.funcaoService.converteDataSQL(this.dataFinal);
    } else if (this.relatorio === 'CA_A_VENCER') {
      parametros['tipo$ne'] = 'REGISTRO_PRECO';
      parametros['OR'] = `aditamentos.data_termino$bt=${this.funcaoService.converteDataSQL(this.dataInicial)},${this.funcaoService.converteDataSQL(this.dataFinal)};!;!;data_termino$bt=${this.funcaoService.converteDataSQL(this.dataInicial)},${this.funcaoService.converteDataSQL(this.dataFinal)}`
    } else {
      parametros['tipo$ne'] = 'REGISTRO_PRECO';
      parametros['data_inicio$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
      parametros['data_inicio$le'] = this.funcaoService.converteDataSQL(this.dataFinal);
    }
    
    if ((this.relatorio == '*' || this.relatorio == 'ADITIVO' || this.relatorio == 'CA_A_VENCER') && 
          (this.selectedOrdem == 'data_inicio' || this.selectedOrdem == 'data_termino')) {
      parametros['orderBy'] = 'aditamentos.' + this.selectedOrdem + ',numero,aditamentos.numero';
    } else {
      parametros['orderBy'] = this.selectedOrdem + ',numero,aditamentos.numero';
    }

    parametros['relations'] = 'favorecido,tipo_contratacao,aditamentos.tipo';
    parametros['orgao_id'] = this.login.orgao.id;

    this.contratoService.filtrar(1, -1, parametros).pipe(takeUntil(this.unsubscribe)).subscribe(
      lista => {
        if (formato === 'pdf') {
          // Relatorio.imprimir(`LISTAGEM NO PERÍODO DE ${this.dataInicial} à ${this.dataFinal} `,
          //   this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, lista.content, this.colunas(), 'landscape',
          //   'Listagem contratos', ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*', 'auto'], ['valor_contrato'], undefined, undefined, subtitulo);
          if(this.relatorio === '*') {
            Relatorio.imprimirPersonalizado('TODOS OS CONTRATOS E ADITIVOS',
            this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            this.montarConteudo(lista.content), 'landscape', 'Listagem Contratos',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, false, false, formato, `LISTAGEM NO PERÍODO DE ${this.dataInicial} à ${this.dataFinal}`);
          } else if(this.relatorio === 'CONTRATO') {
            Relatorio.imprimirPersonalizado('FILTRADOS SOMENTE POR CONTRATOS',
            this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            this.montarConteudo(lista.content), 'landscape', 'Listagem Contratos',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, false, false, formato, `LISTAGEM NO PERÍODO DE ${this.dataInicial} à ${this.dataFinal}`);
          } else if(this.relatorio === 'ADITIVO') {
            Relatorio.imprimirPersonalizado('FILTRADOS SOMENTE POR ADITIVOS',
            this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            this.montarConteudo(lista.content), 'landscape', 'Listagem Contratos',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, false, false, formato, `LISTAGEM NO PERÍODO DE ${this.dataInicial} à ${this.dataFinal}`);
          } else if(this.relatorio === 'ATAS_REGISTRO_PRECOS') {
            Relatorio.imprimirPersonalizado('FILTRADOS SOMENTE POR ATAS DE REGISTRO DE PREÇOS',
            this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            this.montarConteudo(lista.content), 'landscape', 'Listagem Contratos',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, false, false, formato, `LISTAGEM NO PERÍODO DE ${this.dataInicial} à ${this.dataFinal}`);
          } else if(this.relatorio === 'A_VENCER') {
            Relatorio.imprimirPersonalizado('CONTRATOS A VENCER',
            this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            this.montarConteudo(lista.content), 'landscape', 'Listagem Contratos',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, false, false, formato, `LISTAGEM NO PERÍODO DE ${this.dataInicial} à ${this.dataFinal}`);
          } else if(this.relatorio === 'CA_A_VENCER') {
            Relatorio.imprimirPersonalizado('CONTRATOS E ADITIVOS A VENCER',
            this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            this.montarConteudo(lista.content), 'landscape', 'Listagem Contratos',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, false, false, formato, `LISTAGEM NO PERÍODO DE ${this.dataInicial} à ${this.dataFinal}`);
          }
        } else {
          this.funcaoService.exportar(formato, this.normalizar(lista.content), 'Listagem de Contratos', this.colunas());
        }
      },
      () => alert('erro ao retornar lista')
    );
  }

  public normalizar(lista: Contrato[]) {
    const listaExportar = []
    for (const c of lista) {
      const linha = {
        numero: c.numero,
        data_inicio: c.data_inicio,
        data_termino: c.data_termino,
        favorecido: c.favorecido.nome,
        tipo_contratacao: c.tipo_contratacao.nome,
        processo: c.processo,
        objeto: c.objeto,
        valor_contrato: c.valor_contrato
      }
      listaExportar.push(linha)
      if (c.aditamentos.length > 0 && this.relatorio !== 'CONTRATO') {
        for (const a of c.aditamentos) {
          const line = {
            numero: a.numero,
            data_inicio: a.data_inicio,
            data_termino: a.data_termino,
            favorecido: c.favorecido.nome,
            tipo_contratacao: c.tipo_contratacao.nome,
            processo: c.processo,
            objeto: a.finalidade,
            valor_contrato: a.valor_total
          }
          listaExportar.push(line)
        }
      }
    }
    return listaExportar
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'Número', coluna: 'numero', bold: true },
      { titulo: 'Início', coluna: 'data_inicio' },
      { titulo: 'Término', coluna: 'data_termino' },
      { titulo: 'Favorecido', coluna: 'favorecido' },
      { titulo: 'Contratação', coluna: 'tipo_contratacao' },
      { titulo: 'Processo', coluna: 'processo' },
      { titulo: 'Objeto', coluna: 'objeto' },
      { titulo: 'Valor', coluna: 'valor_contrato', decimais: 2, alignment: 'right' }
    ];
    return colunasDefault;
  }

  private montarConteudo(lista: any[]) {
    let retorno: {}[] = [];
    let datepipe = new DatePipe('pt');
    const cabecalho = [];

    cabecalho.push([
      { text: 'Número', fontSize: 8, alignment: 'center', bold: true, border: [true, true, false, true], margin: [0, 4, 0, 4] },
      { text: 'Início', fontSize: 8, alignment: 'center', bold: true, border: [false, true, false, true], margin: [0, 4, 0, 4] },
      { text: 'Término', fontSize: 8, alignment: 'center', bold: true, border: [false, true, false, true], margin: [0, 4, 0, 4] },
      { text: 'Favorecido', fontSize: 8, alignment: 'center', bold: true, border: [false, true, false, true], margin: [0, 4, 0, 4] },
      { text: 'Contratação', fontSize: 8, alignment: 'center', bold: true, border: [false, true, false, true], margin: [0, 4, 0, 4] },
      { text: 'Processo', fontSize: 8, alignment: 'center', bold: true, border: [false, true, false, true], margin: [0, 4, 0, 4] },
      { text: 'Objeto', fontSize: 8, alignment: 'center', bold: true, border: [false, true, false, true], margin: [0, 4, 0, 4] },
      { text: 'Valor', fontSize: 8, alignment: 'center', bold: true, border: [false, true, true, true], margin: [0, 4, 0, 4] },
    ]);

    for (const entidade of lista) {
      const registro = [];
      registro.push({ text: `${entidade.numero}`, fontSize: 8, alignment: 'center', border: [true, true, false, true] });
      registro.push({ text: `${datepipe.transform(entidade.data_inicio, 'dd/MM/yyyy')}`, fontSize: 8, alignment: 'center', border: [false, true, false, true] });
      registro.push({ text: `${datepipe.transform(entidade.data_termino, 'dd/MM/yyyy')}`, fontSize: 8, alignment: 'center', border: [false, true, false, true] });
      registro.push({ text: `${entidade.favorecido.nome}`, fontSize: 8, alignment: 'center', border: [false, true, false, true] });
      registro.push({ text: `${entidade.tipo_contratacao.nome}`, fontSize: 8, alignment: 'center', border: [false, true, false, true] });
      registro.push({ text: `${entidade.processo}`, fontSize: 8, alignment: 'center', border: [false, true, false, true] });
      registro.push({ text: `${entidade.objeto}`, fontSize: 8, alignment: 'justify', border: [false, true, false, true] });
      registro.push({ text: `${this.funcaoService.convertToBrNumber(entidade.valor_contrato)}`, fontSize: 8, alignment: 'center', border: [false, true, true, true] });
      cabecalho.push(registro);

      if (entidade.aditamentos.length > 0 && this.relatorio !== 'CONTRATO') {
        const linhaExtra = [];
        linhaExtra.push({ text: `ADITAMENTOS CONTRATO ${entidade.numero}`, colSpan: 8, border: [true, true, true, false] },
          '', '', '', '', '', '', '')
        cabecalho.push(linhaExtra);
        for (const aditamento of entidade.aditamentos) {
          const registroExtra = [];
          registroExtra.push({ text: `${aditamento.numero}`, fontSize: 8, alignment: 'center', border: aditamento.id === entidade.aditamentos[entidade.aditamentos.length - 1].id ? [true, false, false, true] : [true, false, false, false] });
          registroExtra.push({ text: `${datepipe.transform(aditamento.data_inicio, 'dd/MM/yyyy')}`, fontSize: 8, alignment: 'center', border: aditamento.id === entidade.aditamentos[entidade.aditamentos.length - 1].id ? [false, false, false, true] : [false, false, false, false] });
          registroExtra.push({ text: `${datepipe.transform(aditamento.data_termino, 'dd/MM/yyyy')}`, fontSize: 8, alignment: 'center', border: aditamento.id === entidade.aditamentos[entidade.aditamentos.length - 1].id ? [false, false, false, true] : [false, false, false, false] });
          registroExtra.push({ text: `${entidade.favorecido.nome}`, fontSize: 8, alignment: 'center', border: aditamento.id === entidade.aditamentos[entidade.aditamentos.length - 1].id ? [false, false, false, true] : [false, false, false, false] });
          registroExtra.push({ text: `${entidade.tipo_contratacao.nome}`, fontSize: 8, alignment: 'center', border: aditamento.id === entidade.aditamentos[entidade.aditamentos.length - 1].id ? [false, false, false, true] : [false, false, false, false] });
          registroExtra.push({ text: `${entidade.processo}`, fontSize: 8, alignment: 'center', border: aditamento.id === entidade.aditamentos[entidade.aditamentos.length - 1].id ? [false, false, false, true] : [false, false, false, false] });
          registroExtra.push({ text: `${aditamento.finalidade}`, fontSize: 8, alignment: 'justify', border: aditamento.id === entidade.aditamentos[entidade.aditamentos.length - 1].id ? [false, false, false, true] : [false, false, false, false] });
          registroExtra.push({ text: `${this.funcaoService.convertToBrNumber(aditamento.valor_total)}`, fontSize: 8, alignment: 'center', border: aditamento.id === entidade.aditamentos[entidade.aditamentos.length - 1].id ? [false, false, true, true] : [false, false, true, false] });
          cabecalho.push(registroExtra);
        }
      }
    };

    retorno.push({
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['auto', 'auto', 'auto', '*', 'auto', 'auto', 200, 'auto'],
        body: cabecalho,
      }
    });

    return retorno;
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }
}
