import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido } from 'eddydata-lib';

export class FavorecidoInsencao extends BaseResourceModel {
    constructor(
        public tipo?: number,
        public valor_insento?: number,
        public descricao?: string,
        public data_laudo?: string,
        public favorecido?: Favorecido
    ) {
        super();
    }
    static converteJson(json: any): FavorecidoInsencao {
        return Object.assign(new FavorecidoInsencao(), json);
    }
}
