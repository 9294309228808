import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FuncaoService, Exercicio } from 'eddydata-lib';

@Directive()
export class Anexo8ReceitaAdicional implements OnDestroy {

  protected funcaoService: FuncaoService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number,
    protected exercicio: Exercicio) {
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(dados: any[]): {}[] {

    const registros: {}[] = [
      [{
        text: 'OUTRAS INFORMAÇÕES PARA CONTROLE',
        fontSize: 8, bold: true, border: [true, false, true, true],
        margin: [5, 5, 5, 5],
        alignment: 'center',
        colSpan: 3
      }, '', ''],
      [{
        text: 'RECEITAS ADICIONAIS PARA FINANCIAMENTO DO ENSINO',
        alignment: 'center', border: [true, false, true, true], bold: true, fontSize: 6
      }, {
        text: 'PREVISÃO ATUALIZADA (a)',
        alignment: 'center', border: [true, false, true, true], bold: true, fontSize: 6
      }, {
        text: 'RECEITAS REALIZADAS ATÉ O BIMESTRE (b)',
        alignment: 'center', border: [true, false, true, true], bold: true, fontSize: 6
      }]
    ];

    const grupos1 = this.funcaoService.agrupar(dados, 'grupo', ['atualizado', 'arrecadado']);
    let total_receita1 = 0;
    let total_receita2 = 0;
    for (const grupo of grupos1) {

      registros.push([
        { text: grupo.grupo, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['atualizado']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']), alignment: 'right', fontSize: 7 },
      ]);
      total_receita1 += +grupo.totalizadores['atualizado'];
      total_receita2 += +grupo.totalizadores['arrecadado'];

      let subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['atualizado', 'arrecadado']);
      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo != '') {

          registros.push([
            { text: subgrupo.grupo, fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['atualizado']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['arrecadado']), alignment: 'right', fontSize: 7 },
          ]);

          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              registros.push([
                { text: item.nome, fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.atualizado), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.arrecadado), alignment: 'right', fontSize: 7 },
              ]);
            }
          }
        }
      }
    }

    registros.push([
      { text: '40- TOTAL DAS RECEITAS ADICIONAIS PARA FINANCIAMENTO DO ENSINO = (35 + 36 + 37 + 38 + 39 )', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total_receita1), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total_receita2), alignment: 'right', fontSize: 7, bold: true },
    ]);

    return registros;

  }

  public montarQuadroCsv(dados: any[], listaExportar): any {

    const espaco = {
      titulo: '',
      coluna1: '',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
    }
    listaExportar.push(espaco)

    const primeira_linha = {
      titulo: 'OUTRAS INFORMAÇÕES PARA CONTROLE',
      coluna1: '',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
    }
    listaExportar.push(primeira_linha)

    const segunda_linha = {
      titulo: 'RECEITAS ADICIONAIS PARA FINANCIAMENTO DO ENSINO',
      coluna1: 'PREVISÃO ATUALIZADA (a)',
      coluna2: 'RECEITAS REALIZADAS ATÉ O BIMESTRE (b)',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
    }
    listaExportar.push(segunda_linha)

    const grupos1 = this.funcaoService.agrupar(dados, 'grupo', ['atualizado', 'arrecadado']);
    let total_receita1 = 0;
    let total_receita2 = 0;
    for (const grupo of grupos1) {
      const linha_grupo = {
        titulo: grupo.grupo,
        coluna1: this.funcaoService.convertToBrNumber(grupo.totalizadores['atualizado']),
        coluna2: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']),
        coluna3: '',
        coluna4: '',
        coluna5: '',
        coluna6: ''
      }
      listaExportar.push(linha_grupo)

      total_receita1 += +grupo.totalizadores['atualizado'];
      total_receita2 += +grupo.totalizadores['arrecadado'];

      let subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['atualizado', 'arrecadado']);
      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo != '') {
          const linha_subgrupo = {
            titulo: subgrupo.grupo,
            coluna1: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['atualizado']),
            coluna2: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['arrecadado']),
            coluna3: '',
            coluna4: '',
            coluna5: '',
            coluna6: ''
          }
          listaExportar.push(linha_subgrupo)

          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              const linha_subgrupo_item = {
                titulo: item.nome,
                coluna1: this.funcaoService.convertToBrNumber(item.atualizado),
                coluna2: this.funcaoService.convertToBrNumber(item.arrecadado),
                coluna3: '',
                coluna4: '',
                coluna5: '',
                coluna6: ''
              }
              listaExportar.push(linha_subgrupo_item)
            }
          }
        }
      }

    }

    const linha_40 = {
      titulo: '40- TOTAL DAS RECEITAS ADICIONAIS PARA FINANCIAMENTO DO ENSINO = (35 + 36 + 37 + 38 + 39 )',
      coluna1: this.funcaoService.convertToBrNumber(total_receita1),
      coluna2: this.funcaoService.convertToBrNumber(total_receita2),
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: ''
    }
    listaExportar.push(linha_40)
  }
}