import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { FavorecidoTipoListComponent } from './favorecido-tipo-list/favorecido-tipo-list.component';
import { FavorecidoTipoFormComponent } from './favorecido-tipo-form/favorecido-tipo-form.component';


const routes: Routes = [
  { path: '', component: FavorecidoTipoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: FavorecidoTipoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: FavorecidoTipoFormComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FavorecidoTipoRoutingModule { }
