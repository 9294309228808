import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { InventarioEstoqueFormComponent } from './inventario-estoque-form/inventario-estoque-form.component';
import { InventarioEstoqueListComponent } from './inventario-estoque-list/inventario-estoque-list.component';
import { InventarioEstoqueRoutingModule } from './inventario-estoque-routing.module';
import { SharedModule } from 'eddydata-lib';
import { BloqueioInventarioDirective } from './directives/bloqueio-inventario.directive';


@NgModule({
  declarations: [InventarioEstoqueListComponent, InventarioEstoqueFormComponent,BloqueioInventarioDirective],
  imports: [
    CommonModule,
    InventarioEstoqueRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
  ],
  exports: [
    BloqueioInventarioDirective
  ]

})
export class InventarioEstoqueModule { }
