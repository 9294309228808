import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import {
  FuncaoService, GlobalService, Exercicio, FormatoExportacao
} from 'eddydata-lib';

@Directive()
export class Anexo12Imposto implements OnDestroy {

  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number,
    protected exercicio: Exercicio) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(dados: any[], formato?: FormatoExportacao): {}[] {
    if (formato && formato !== 'pdf') return this.montarQuadroExportacao(dados);

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: 'DEMONSTRATIVO DAS RECEITAS E DESPESAS COM AÇÕES E SERVIÇOS COM SAÚDE',
        alignment: 'left',
        fontSize: 8,
        border: [false, false, false, false],
        bold: true,
        colSpan: 5
      }, '', '', '', ''],
      [{
        text: 'ORÇAMENTO FISCAL E DA SEGURIDADE SOCIAL',
        alignment: 'left',
        fontSize: 8,
        border: [false, false, false, false],
        bold: true,
        colSpan: 5
      }, '', '', '', ''],
      [{
        text: `REFERÊNCIA: ${this.mes ? this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano : this.exercicio.ano}`,
        alignment: 'left',
        fontSize: 8,
        border: [false, false, false, false],
        bold: true,
        colSpan: 5
      }, '', '', '', ''],
      [{
        text: 'RECEITAS PARA APURAÇÃO DA APLICAÇÃO EM AÇÕES E SERVIÇOS PÚBLICOS DE SAÚDE',
        alignment: 'center',
        fontSize: 6,
      }, {
        text: 'PREVISÃO INICIAL DO EXERCÍCIO',
        alignment: 'center',
        fontSize: 6
      }, {
        text: 'PREVISÃO ATUALIZADA (a)',
        alignment: 'center',
        fontSize: 6
      }, {
        text: 'ARRECADADO (b)',
        alignment: 'center',
        fontSize: 6
      }, {
        text: '% (b/a) x 100',
        alignment: 'center',
        fontSize: 6
      }]
    ];

    let total1 = 0;
    let total2 = 0;
    let total3 = 0;

    // monta o agrupamento do relatório

    let subgrupos: any;

    // monta o agrupamento do relatório
    const grupos = this.funcaoService.agrupar(dados, 'grupo',
      ['previsao', 'atualizado', 'arrecadado']);
    for (const titulo of grupos) {
      registros.push([
        {
          text: titulo.grupo, fontSize: 8,
        },
        {
          text: this.funcaoService.convertToBrNumber(titulo.totalizadores['previsao']),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(titulo.totalizadores['atualizado']),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(titulo.totalizadores['arrecadado']),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((+titulo.totalizadores['arrecadado'] / +titulo.totalizadores['atualizado']) * 100),
          alignment: 'right', fontSize: 7
        }
      ]);
      total1 += +titulo.totalizadores['previsao'];
      total2 += +titulo.totalizadores['atualizado'];
      total3 += +titulo.totalizadores['arrecadado'];

      subgrupos = this.funcaoService.agrupar(titulo.registros, 'subgrupo', ['previsao', 'atualizado', 'arrecadado']);
      for (const subgrupo of subgrupos) {
        registros.push([
          {
            text: subgrupo.grupo, fontSize: 7,
          },
          {
            text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['previsao']),
            alignment: 'right', fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['atualizado']),
            alignment: 'right', fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['arrecadado']),
            alignment: 'right', fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber((+subgrupo.totalizadores['arrecadado'] / +subgrupo.totalizadores['atualizado']) * 100),
            alignment: 'right', fontSize: 7
          }
        ]);

        for (const item of subgrupo.registros) {
          if (item.nome != '') {
            registros.push([
              {
                text: item.nome, fontSize: 7,
              },
              { text: this.funcaoService.convertToBrNumber(item.previsao), alignment: 'right', fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(item.atualizado), alignment: 'right', fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(item.arrecadado), alignment: 'right', fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber((+item.arrecadado / +item.atualizado) * 100), alignment: 'right', fontSize: 7 }
            ]);
          }
        }
      }
    }
    registros.push([
      {
        text: 'TOTAL DE RECEITA DE IMPOSTOS LÍQUIDA E TRANSFERÊNCIAS CONSTITUCIONAIS E LEGAIS (III) = I + II', fontSize: 7, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber((+total3 / +total2) * 100), alignment: 'right', fontSize: 7, bold: true }
    ]);

    return registros;
  }

  private montarQuadroExportacao(dados: any[]): {}[] {
      const listaItens = new Array();
      listaItens.push({
        'receita_desc': 'RECEITAS PARA APURAÇÃO DA APLICAÇÃO EM AÇÕES E SERVIÇOS PÚBLICOS DE SAÚDE',
        'inicial': 'PREVISÃO INICIAL DO EXERCÍCIO',
        'atualizada': 'PREVISÃO ATUALIZADA (a)',
        'arrecadado_empenhada': 'ARRECADADO (b)',
        'calculo_empenhada': '% (b/a) x 100',
        'liquidada': '',
        'calculo_liquidada': '',
      });

      const grupos = this.funcaoService.agrupar(dados, 'grupo', ['previsao', 'atualizado', 'arrecadado']);
      let subgrupos: any;
      let total1 = 0;
      let total2 = 0;
      let total3 = 0;

      for (const titulo of grupos) {
        listaItens.push({
          'receita_desc': titulo.grupo,
          'inicial': this.funcaoService.convertToBrNumber(titulo.totalizadores['previsao'], 2),
          'atualizada': this.funcaoService.convertToBrNumber(titulo.totalizadores['atualizado'], 2),
          'arrecadado_empenhada': this.funcaoService.convertToBrNumber(titulo.totalizadores['arrecadado'], 2),
          'calculo_empenhada': this.funcaoService.convertToBrNumber((+titulo.totalizadores['arrecadado'] / +titulo.totalizadores['atualizado']) * 100, 2),
          'liquidada': '',
          'calculo_liquidada': '',
        });

        subgrupos = this.funcaoService.agrupar(titulo.registros, 'subgrupo', ['previsao', 'atualizado', 'arrecadado']);
        total1 += +titulo.totalizadores['previsao'];
        total2 += +titulo.totalizadores['atualizado'];
        total3 += +titulo.totalizadores['arrecadado'];
        for (const subgrupo of subgrupos) {
          listaItens.push({
            'receita_desc': subgrupo.grupo,
            'inicial': this.funcaoService.convertToBrNumber(subgrupo.totalizadores['previsao'], 2),
            'atualizada': this.funcaoService.convertToBrNumber(subgrupo.totalizadores['atualizado'], 2),
            'arrecadado_empenhada': this.funcaoService.convertToBrNumber(subgrupo.totalizadores['arrecadado'], 2),
            'calculo_empenhada': this.funcaoService.convertToBrNumber((+subgrupo.totalizadores['arrecadado'] / +subgrupo.totalizadores['atualizado']) * 100, 2),
            'liquidada': '',
            'calculo_liquidada': '',
          });
          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              listaItens.push({
                'receita_desc': item.nome,
                'inicial': this.funcaoService.convertToBrNumber(item.previsao, 2),
                'atualizada': this.funcaoService.convertToBrNumber(item.atualizado, 2),
                'arrecadado_empenhada': this.funcaoService.convertToBrNumber(item.arrecadado, 2),
                'calculo_empenhada': this.funcaoService.convertToBrNumber((+item.arrecadado / +item.atualizado) * 100, 2),
                'liquidada': '',
                'calculo_liquidada': ''
              });
            }
          }
        }
      }
      listaItens.push({
        'receita_desc': 'TOTAL DE RECEITA DE IMPOSTOS LÍQUIDA E TRANSFERÊNCIAS CONSTITUCIONAIS E LEGAIS (III) = I + II',
        'inicial': this.funcaoService.convertToBrNumber(total1),
        'atualizada': this.funcaoService.convertToBrNumber(total2),
        'arrecadado_empenhada': this.funcaoService.convertToBrNumber(total3),
        'calculo_empenhada': this.funcaoService.convertToBrNumber((+total3 / +total2) * 100, 2),
        'liquidada': '',
        'calculo_liquidada': ''
      });

      listaItens.push({
        'receita_desc': ''
      });

      return listaItens;
  }

}
