import { PlanoConta } from './plano-conta.model';
import { EventoContabil } from './evento-contabil.model';
import { BaseResourceModel } from '../../models/base-resource.model';

export class EventoContabilConta extends BaseResourceModel {
  constructor(
    public id?: number,
    public debito?: PlanoConta,
    public credito?: PlanoConta,
    public evento?: EventoContabil,
    public editavel?: boolean,
  ) {
    super();
  }

  static converteJson(json: any): EventoContabil {
    return Object.assign(new EventoContabil(), json);
  }

}
