// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Funcionario } from './funcionario.model';
import { Afastamento } from './afastamento.model';

export class FuncionarioAfastamento extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_saida?: Date,
    public data_retorno?: Date,
    public motivo?: string,
    public afastamento_saida?: Afastamento,
    public afastamento_retorno?: Afastamento,
    public funcionario?: Funcionario,
  ) {
    super();
  }
  static converteJson(json: any): FuncionarioAfastamento {
    return Object.assign(new FuncionarioAfastamento(), json);
  }
}
