import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, Receita, Page } from 'eddydata-lib';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReceitaService extends BaseResourceService<Receita> {

  constructor(
    protected injector: Injector
  ) {
    super(`receitas`, injector);
  }

  obterNomePorEscrituracao(filtroTexto: string, escrituracao: boolean, exercicioId: number): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?nome$like=${filtroTexto}%&escrituracao=${escrituracao}&exercicio.id=${exercicioId}`,
      this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  obterCodigoPorEscrituracao(filtroTexto: string, escrituracao: boolean, exercicioId: number): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?codigo$like=${filtroTexto}%&escrituracao=${escrituracao}&exercicio.id=${exercicioId}`,
      this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public upload(documento: any): Observable<any> {
    const formData = new FormData();
    formData.append('exercicio_id', this.login.exercicio.id + '');
    formData.append('arquivo', documento);
    return this.http.post(`${this.login.cidade.id}/${this.api}/upload`, formData, {
      headers: new HttpHeaders(this.adicionarCriptografia({
        Authorization: this.login.token
      }))
    });
  }

  importarReceitas(exercicio_atual_id: number, exercicio_anterior_id: number, importado: boolean): Observable<number> {
      const registro = {
        exercicio_atual_id: exercicio_atual_id,
        exercicio_anterior_id: exercicio_anterior_id,
        importado: importado
      }

      return this.http.post(`${this.login.cidade.id}/${this.api}/importar-receitas`, registro, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  importarReceitasXls(exercicio_id: number, lista: Array<Receita>): Observable<number> {
    const registro = {
      exercicio_id: exercicio_id,
      lista: lista
    }

    return this.http.post(`${this.login.cidade.id}/${this.api}/importar-receitas-xls`, registro, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public atualizarReceitas(exercicioId: number, contasIncluir: Receita[], contasExcluir : Number[]) {
    const body = {
      exercicio: exercicioId,
      listaInclusao: contasIncluir,
      listaExclusao: contasExcluir
    }

    return this.http.post(`${this.login.cidade.id}/${this.api}/atualizar-receitas`, body, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
