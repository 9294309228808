import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, InventarioEstoque } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class InventarioEstoqueService extends BaseResourceService<InventarioEstoque> {

  constructor(
    protected injector: Injector
  ) {
    super(`inventario-estoques`, injector);
  }

}
