// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Produto } from './produto.model';
import { Estoque } from './estoque.model';

export class ProdutoLocalizacao extends BaseResourceModel {
  constructor(
    public id?: number,
    public sala?: string,
    public armario?: string,
    public prateleira?: string,
    public corredor?: string,
    public pallet?: string,
    public posicao?: string,
    public produto?: Produto,
    public estoque?: Estoque
  ) {
    super();
  }

  static converteJson(json: any): ProdutoLocalizacao {
    return Object.assign(new ProdutoLocalizacao(), json);
  }
}
