import { Injectable, OnDestroy } from '@angular/core';
import { AnexoLoaService } from '../service/anexo-loa.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuncaoService, LoginContabil, Exercicio, Relatorio, GlobalService } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ReceitaRecurso implements OnDestroy {
  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLoaService) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // tslint:disable: max-line-length
  public montarRelatorio(exercicio: Exercicio, orgaos: number[]) {
    this.anexoServico.obterReceitaRecurso(exercicio.id, orgaos, this.login.ppa.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        Relatorio.imprimirPersonalizado(
          'RECEITAS / RECURSO'
          , this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          this.conteudo(dados, exercicio),
          'landscape', 'RECEITAS / RECURSO',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private conteudo(dados: any[], exercicio: Exercicio): {}[] {

    // monta o cabecalho
    const registros: {}[] = [[{
      text: `EXERCÍCIO ${exercicio.ano}`, fontSize: 11,
      alignment: 'center', bold: true, colSpan: 3, border: [true, true, true, false]
    }, '', ''],
    [{
      text: 'Receitas / Recursos',
      alignment: 'center',
      bold: true,
      colSpan: 3, border: [true, false, true, false]
    }, '', '']
    ];

    // monta o agrupamento do relatório

    let total6 = 0;
    registros.push(
      [{
        text: 'CÓDIGO',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'DESCRIÇÃO',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'ORÇADA',
        alignment: 'center',
        bold: true, fontSize: 8
      }]
    );
    // lista os registros do relatorio
    let ultimo_codigo;
    let ultimo_registro;
    for (const registro of dados) {
      if (ultimo_registro && ultimo_registro.nivel >= registro.nivel) {
        registros.push([
          {
            text: ``, margin: [ultimo_registro.nivel * 4, 0, 0, 0], fontSize: 8, border: [true, false, false, false], bold: (ultimo_registro.nivel === 0 || ultimo_registro.nivel === 1) ? true : false
          },
          {
            text: 'Fonte/Aplicação: ' + `${ultimo_registro.variavel ? `${ultimo_registro.variavel} - ${ultimo_registro.variavel_nome}` : `${ultimo_registro.aplicacao} - ${ultimo_registro.aplicacao_nome}`}`, margin: [ultimo_registro.nivel * 4, 0, 0, 0], italics: true, fontSize: 8, border: [true, false, false, false], bold: (ultimo_registro.nivel === 0 || ultimo_registro.nivel === 1) ? true : false
          },
          {
            text: this.funcaoService.convertToBrNumber(ultimo_registro.total), alignment: 'right',
            fontSize: 8, italics: true, border: [true, false, true, false], bold: (ultimo_registro.nivel === 0 || ultimo_registro.nivel === 1)
          },
        ]);
      }
      ultimo_registro = registro;
      ultimo_codigo = registro.ultimo_codigo;
      registros.push([
        {
          text: `${this.funcaoService.mascarar('#.#.#.#.##.#.#', registro.codigo)}`, margin: [registro.nivel * 2, 0, 0, 0], fontSize: 8, border: [true, false, false, false], bold: (registro.nivel === 0 || registro.nivel === 1) ? true : false
        },
        {
          text: `${registro.nome_receita}`, margin: [registro.nivel * 2, 0, 0, 0], fontSize: 8, border: [true, false, false, false], bold: (registro.nivel === 0 || registro.nivel === 1) ? true : false
        },
        {
          text: this.funcaoService.convertToBrNumber(registro.total), alignment: 'right',
          fontSize: 8, border: [true, false, true, false], bold: (registro.nivel === 0 || registro.nivel === 1)
        },
      ]);
      total6 += registro.nivel === 0 ? +registro.total : 0;

    }
    registros.push([
      {
        text: ``, margin: [ultimo_registro.nivel * 4, 0, 0, 0], fontSize: 8, border: [true, false, false, false], bold: (ultimo_registro.nivel === 0 || ultimo_registro.nivel === 1) ? true : false
      },
      {
        text: 'Fonte/Aplicação: ' + `${ultimo_registro.variavel ? `${ultimo_registro.variavel} - ${ultimo_registro.variavel_nome}` : `${ultimo_registro.aplicacao} - ${ultimo_registro.aplicacao_nome}`}`, margin: [ultimo_registro.nivel * 4, 0, 0, 0], italics: true, fontSize: 8, border: [true, false, false, false], bold: (ultimo_registro.nivel === 0 || ultimo_registro.nivel === 1) ? true : false
      },
      {
        text: this.funcaoService.convertToBrNumber(ultimo_registro.total), alignment: 'right',
        fontSize: 8, italics: true, border: [true, false, true, false], bold: (ultimo_registro.nivel === 0 || ultimo_registro.nivel === 1)
      },
    ]);
    registros.push([{
      text: 'TOTAL', colSpan: 2, border: [true, true, true, true],
    }, '', {
      text: this.funcaoService.convertToBrNumber(total6), alignment: 'right', bold: true
    }]);

    registros.push([{
      text: '', colSpan: 3, border: [false, false, false, false], margin: [0, 0, 0, 10]
    }, '', '']);


    return [{
      layout: 'linhas',
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        dontBreakRows: true,
        headerRows: 2,
        widths: ['auto', '*', 'auto'],
        body: registros
      }
    }];
  }


}
