import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagamentoExtraListComponent } from './pagamento-extra-list/pagamento-extra-list.component';
import { PagamentoExtraFormComponent } from './pagamento-extra-form/pagamento-extra-form.component';
import { PagamentoExtraRptComponent } from './pagamento-extra-rpt/pagamento-extra-rpt.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: PagamentoExtraListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: PagamentoExtraFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: PagamentoExtraFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: PagamentoExtraFormComponent, canActivate: [AuthGuard] },
  { path: 'relatorios', component: PagamentoExtraRptComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagamentoExtraRoutingModule { }
