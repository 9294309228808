import { DatePipe } from '@angular/common';
import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Contrato, ContratoItem, ContratoReajuste, FuncaoService, GlobalService, Login } from 'eddydata-lib';
import { Subject } from 'rxjs';
import * as toastr from 'toastr';
import { ContratoItemService } from '../service/contrato-item.service';
import { ContratoReajusteService } from '../service/contrato-reajuste.service';

declare var $: any;

@Component({
  selector: 'app-contrato-reajuste-dlg',
  templateUrl: './contrato-reajuste-dlg.component.html'
})
export class ContratoReajusteDlgComponent implements OnChanges, OnDestroy, AfterContentInit {

  @Input() visualizar_item: boolean;
  @Input() reajuste: ContratoReajuste;
  @Input() contratoItens: ContratoItem[];
  @Input() login: Login;
  @Input() contrato: Contrato
  @Output() callback: EventEmitter<void> = new EventEmitter();
  @Input() inclusao: boolean;
  @Output() inclusaoChange: EventEmitter<boolean> = new EventEmitter();

  public itens: ContratoReajuste[];
  public listaEspecies: { id: string, nome: string }[];
  public ptBR: any;
  protected datepipe: DatePipe;
  public atualizarItens: boolean = false;

  protected unsubscribe: Subject<void> = new Subject();

  public imaskQtd = {
    mask: Number,
    scale: 0,
    signed: false
  };
  imaskConfig = {
    mask: Number,
    scale: 4,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    public funcaoService: FuncaoService,
    public itemService: ContratoItemService,
    public reajusteService: ContratoReajusteService,
    private globalService: GlobalService
  ) { }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes['inclusao'] && this.inclusao && this.contratoItens?.length > 0) {
        this.itens = [];
        this.contratoItens.forEach(async item => {
          const saldo = await this.itemService.obterSaldo(this.login.orgao.id, item.id, { cota: item['cota'] === 'PRINCIPAL' ? 'P' : 'R', contrato_id: this.contrato.id }).toPromise()
          this.itens.push({ contrato_item: item, ajuste_saldo: false, reajuste_valor: 0, reajuste_valor_total: 0, saldo: +saldo?.saldo_qtd })
        this.itens.sort((a, b) => { return +a.contrato_item?.ordem - +b.contrato_item?.ordem })
        })
        this.inclusao = false;
        this.inclusaoChange.emit(false);
      }
    }
  }
  // ngOnInit() {

  // }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterContentInit(): void {
    this.globalService.calendarMascara();
    this.listaEspecies = this.obterEspecies();
    this.ptBR = this.globalService.obterDataBR();
  }


  public visualizarItem(visualizar_item) {
    if (visualizar_item === true) {
      return true
    } else {
      return false
    }
  }

  public async salvar() {
    if (!this.funcaoService.podeAlterarAudesp(this.reajuste.data_contabilizacao, this.login)) {
      toastr.error('Data de contabilização inválida, período na contabilidade já está fechado, entre em contato com o contador.');
    }

    if (!this.reajuste.especie) {
      toastr.error('Campo espécie não preenchido!')
      return;
    }
    if (!this.reajuste.data_reajuste) {
      toastr.error('Campo data do reajuste não preenchido!')
      return;
    }
    if (!this.reajuste.data_contabilizacao) {
      toastr.error('Campo data de contabilização não preenchida!')
      return;
    }
    if (!this.reajuste.observacao) {
      toastr.error('Campo observação não preenchido!')
      return;
    }

    if (this.reajuste.id) { // alteração
      this.reajuste.usuario = this.login.usuario;

      this.reajusteService.atualizar(this.reajuste).subscribe(res => {
        toastr.success('Reajuste realizado com sucesso!');

        this.callback.emit();
        $('#dialogReajuste').modal('hide');
      }, error => this.funcaoService.acaoErro(error));
    } else {
      if (!this.itens.find(i => i.reajuste_valor > 0 || i.reajuste_valor_total > 0)) {
        toastr.warning('Nenhum item foi reajustado!');
        return;
      }

      await new Promise((resolve, reject) => {
        const contrato = Object.assign({}, this.contrato);
        delete contrato.itens;
        const reajustes: ContratoReajuste[] = this.itens.filter(i => i.reajuste_valor > 0 || i.reajuste_valor_total > 0);
        for (const ajuste of reajustes) {
          ajuste.especie = this.reajuste.especie;
          ajuste.data_reajuste = this.reajuste.data_reajuste;
          ajuste.data_contabilizacao = this.reajuste.data_contabilizacao;
          ajuste.ajuste_saldo = this.reajuste.ajuste_saldo;
          ajuste.observacao = this.reajuste.observacao;
          ajuste.usuario = this.login.usuario;
          ajuste.contrato_item = this.contratoItens.find((m) => m.id === ajuste.contrato_item.id)
          let cont: Contrato = contrato
          cont.favorecido = this.contrato.favorecido
          cont.favorecido.tipo = this.contrato.favorecido.tipo
          ajuste.contrato_item.contrato = cont;
        }
        let listaReajuste = []
        reajustes.map((reajusteItem) => {
          reajusteItem.reajuste_valor_total = (+reajusteItem.saldo * +reajusteItem.reajuste_valor)
          listaReajuste.push(reajusteItem)
        })

        console.log(listaReajuste)
        this.reajusteService.inserirLista(listaReajuste).subscribe(res => {
          toastr.success('Reajuste realizado com sucesso!');

          this.callback.emit();
          $('#dialogReajuste').modal('hide');
          resolve(true)
        }, error => this.funcaoService.acaoErro(error));
      })
    }
  }

  public obterEspecies(): { id: string, nome: string }[] {
    return [
      { id: 'R', nome: 'REAJUSTE' },
      { id: 'E', nome: 'ESTORNO' }
    ];
  }

}
