import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Coluna, FormatoExportacao } from '../../components/types';
import { Assinatura } from '../../entidade/comum/assinatura.model';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { BaseResourceListComponent, Filtro } from '../../models/base-resource-list';
import { AssinaturaService } from '../service/assinatura.service';

@Component({
  selector: 'lib-assinatura-list',
  templateUrl: './assinatura-list.component.html'
})
export class AssinaturaListComponent extends BaseResourceListComponent<Assinatura, LoginContabil> {

  /**
   * Declaração de variáveis
   */

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private assinaturaService: AssinaturaService) {
    super(assinaturaService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return '';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id,
      ['sistema']: this.login.sistema
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['nome'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['id'],
      text: ['nome']
    };
  }

  protected podeAlterar(item: Assinatura): boolean{
    return true;
  }

  protected afterInit(): void {
    console.log(this.login.sistema);
  }

  protected acaoRemover(model: Assinatura): Observable<Assinatura> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'ID', coluna: 'id' },
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'Ativo', coluna: 'ativo' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = this.relations();
    this.assinaturaService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE ASSINATURAS `,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem assinaturas', ['auto', '*', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
