// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { CardapioItemMerenda } from './cardapio-item-merenda.model';
import { PedidoEtapaSetorMerenda } from './pedido-etapa-setor-merenda.model';

export class PedidoEtapaSetorItemMerenda extends BaseResourceModel {
  constructor(
    public id?: number,
    public qtd_pedido?: number,
    public cardapio_item?: CardapioItemMerenda,
    public pedido_etapa_setor?: PedidoEtapaSetorMerenda) {
    super();
  }
  static converteJson(json: any): PedidoEtapaSetorItemMerenda {
    return Object.assign(new PedidoEtapaSetorItemMerenda(), json);
  }
}
