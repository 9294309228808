import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Contrato, ContratoFiscal, EddyAutoComplete, FuncaoService, LoginContabil, Pessoa, PessoaService, Unidade, UnidadeService } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ContratoFiscalService } from './service/contrato-fiscal.service';

@Component({
  selector: 'app-contrato-fiscal',
  templateUrl: './contrato-fiscal.component.html'
})
export class ContratoFiscalComponent implements OnInit, OnDestroy {

  public pessoaAutoComplete: EddyAutoComplete<Pessoa>;
  public pessoaAtual: Pessoa;
  public contratoFiscal: ContratoFiscal;
  public fiscaisContrato: ContratoFiscal[] = [];

  // importacao
  public listaUnidades: Unidade[];
  public unidadeCC: Unidade;
  public tipo = 'FISCAL';

  @Input() entidade: Contrato;
  @Input() login: LoginContabil;
  @Input() visualizar: Boolean = false;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected confirmationService: ConfirmationService,
    protected funcaoService: FuncaoService,
    protected pessoaService: PessoaService,
    protected unidadeService: UnidadeService,
    protected fiscalService: ContratoFiscalService,
  ) { }

  ngOnInit(): void {
    this.pessoaAutoComplete = new EddyAutoComplete(null, this.pessoaService,
      'id', ['nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );

    this.unidadeService.filtrar(1, -1, {
      'orgao_id': this.login.orgao.id, 'ppa_id': this.login.ppa.id,
      relations: 'membros.pessoa', orderBy: 'nome'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaUnidades = res ? res.content : [];
      }, error => this.funcaoService.acaoErro(error));
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public receberPessoa(pessoa?: Pessoa) {
    this.pessoaAtual = pessoa;
  }

  public adicionar(pessoa?: Pessoa, ignorarNotificacao?: boolean) {
    let pessoaExistente: ContratoFiscal;
    this.fiscaisContrato = this.entidade.fiscais;

    if (!pessoa) pessoa = this.pessoaAtual;
    if (pessoa) {
      if (!this.entidade.fiscais) {
        this.entidade.fiscais = [];
      }

      pessoaExistente = this.entidade.fiscais.find((f) => f.pessoa.id === pessoa?.id)

      if (this.entidade.fiscais.find((f) => f.pessoa.id === pessoa.id && f.tipo === this.tipo)) {
        if (this.tipo === 'FISCAL') {
          if (!ignorarNotificacao) toastr.warning('Fiscal já incluído neste contrato!');
        } else if (this.tipo === 'GESTOR') {
          if (!ignorarNotificacao) toastr.warning('Gestor já incluído neste contrato!');
        } else {
          if (!ignorarNotificacao) toastr.warning('Fiscal/Gestor já incluído neste contrato!');
        }
      } else if (pessoaExistente) {
        this.fiscalService.filtrar(1, -1, {
          'pessoa.id': this.pessoaAtual.id, 'contrato.id': this.entidade.id, relations: 'contrato'
        }).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {

            this.contratoFiscal = res.content[0];

            if (this.tipo === 'FISCAL') {
              this.contratoFiscal.tipo = 'FISCAL'
            } else if (this.tipo === 'GESTOR') {
              this.contratoFiscal.tipo = 'GESTOR'
            } else {
              this.contratoFiscal.tipo = 'FISCAL_GESTOR'
            }

            this.fiscalService.atualizar(this.contratoFiscal).subscribe(res => {
              toastr.success('Registro atualizado com sucesso!');
              for (const item of this.entidade.fiscais) {
                if (item.pessoa.id === this.contratoFiscal.pessoa.id) {
                  item.tipo = this.contratoFiscal.tipo
                }
              }
              this.tipo = 'FISCAL';
            }, error => {
              this.funcaoService.acaoErro(error)
            });
          }, error => this.funcaoService.acaoErro(error));
      } else {
        const item = new ContratoFiscal()
        item.pessoa = pessoa;
        item.contrato = this.entidade;
        if (this.tipo === 'FISCAL') {
          item.tipo = 'FISCAL'
        } else if (this.tipo === 'GESTOR') {
          item.tipo = 'GESTOR'
        } else {
          item.tipo = 'FISCAL_GESTOR'
        }

        this.fiscalService.inserir(item).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
          if (!ignorarNotificacao)
            toastr.success('Registro salvo com sucesso!');
          this.entidade.fiscais.push(res);
        }, error => {
          this.funcaoService.acaoErro(error)
        });

      }
      pessoa = undefined;
      this.pessoaAtual = undefined;
    }
  }

  public remover(item: ContratoFiscal, index: number) {
    if (item.id) {
      this.confirmationService.confirm({
        message: 'Deseja realmente remover o fiscal?',
        header: 'Exclusão',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Confirmar',
        rejectLabel: 'Cancelar',
        accept: () => {
          this.fiscalService.remover(item.id).pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
              toastr.info('Registro removido com sucesso!', 'Exclusão');
              this.entidade.fiscais.splice(index, 1);
            }, (err) => toastr.error(err.error.payload));
        }
      });
    } else {
      this.entidade.fiscais.splice(index, 1);
    }
  }

  public importarFiscais() {
    if (!this.unidadeCC) {
      toastr.warning('Selecione a unidade a ter os seus membros importados!');
      return;
    }
    if (!this.unidadeCC.membros || this.unidadeCC.membros.length <= 0) {
      toastr.warning('A unidade selecionada não possui membros cadastrados!');
      return;
    }

    if (!this.entidade.fiscais) {
      this.entidade.fiscais = new Array();
    }

    let count = 0;
    for (const membro of this.unidadeCC.membros) {
      if (this.entidade.fiscais.find((r) => r.pessoa.id === membro.pessoa.id)) {
        continue;
      }

      this.adicionar(membro.pessoa, true)

      count++;
    }

    toastr.info(`${count} fiscais importados!`);
  }

  public podeVisualizar() {
    if (this.visualizar === true) {
      return true;
    }
    if (this.visualizar === false) {
      return false;
    }
  }

}
