import { Despesa, ParametroRequisicao } from "eddydata-lib";
import { BaseResourceModel } from "../../models/base-resource.model";

export class RequisicaoSubelemento extends BaseResourceModel {
    constructor(
        public id?: number,
        public parametro?: ParametroRequisicao,
        public subelemento?: Despesa,
    ) {
        super();
    }

    static converteJson(json: any): RequisicaoSubelemento {
        return Object.assign(new RequisicaoSubelemento(), json);
    }
}
