import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { LicitacaoService } from 'administrativo-lib';
import { Comissao, FuncaoService, Licitacao, Login } from 'eddydata-lib';
import * as toastr from 'toastr';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';

declare var $: any;

@Component({
  selector: 'lib-bb-licitacoes-e',
  templateUrl: './bb-licitacoes-e.component.html',
  styleUrls: ['./bb-licitacoes-e.component.css']
})
export class BBLicitacoesEComponent implements OnInit, OnChanges, OnDestroy {

  public file: any;
  public visualizar: boolean = false;
  public datepipe = new DatePipe('pt');

  @Input() entidade: Licitacao;
  @Input() login: Login;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private funcaoService: FuncaoService,
    private licitacaoService: LicitacaoService
  ) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void { }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  public importarAOP03() {
    if (!this.validar())
      return;
    this.licitacaoService.importarAOP603(this.file)
      .subscribe((data) => {
        console.log(data);
        // if (!data || !data.dados || data.dados.length === 0) {
        //   toastr.warning(`Leitura de arquivo não retorno nenhum resultado, favor verificar o arquivo.`);
        //   return;
        // }
        if (data.status !== 'V') {
          if (data.status === 'I')
            toastr.warning(`Arquivo informado invalido, favor verificar;`);
          if (data.status === 'R')
            toastr.warning(`Arquivo recusado, favor verificar`);
          return;
        } else {
          toastr.success(`Importado o arquivo AOP712 com sucesso!`);
          return;
        }

      });

  }

  public async exportarAOP711() {

    const entidade = await this.licitacaoService.obter({ id: this.entidade.id, relations: 'modalidade.tipos_contratacao.tipo_contratacao,orgao,setor,setor.unidade,exercicio,comissao.membros,tipo_contratacao,fiscais.pessoa,prazo_pagamento,prazo_entrega,usuario_remocao,itens.produto_unidade.produto,comissao_membros.comissao_membro' }).toPromise()

    if (!entidade.data_abertura) {
      toastr.warning('Informe a data de abertura!');
      return;
    }

    if (!entidade.data_fim_proposta) {
      toastr.warning('Informe a data ínicio proposta!');
      return;
    }

    if (!entidade.data_inicio_proposta) {
      toastr.warning('Informe a data fim proposta!');
      return;
    }
    if (this.datepipe.transform(entidade.data_inicio_proposta, 'yyyy-MM-dd') >= this.datepipe.transform(entidade.data_fim_proposta, 'yyyy-MM-dd')) {
      toastr.warning('Data início proposta esta maior que a data término!');
      return;
    }

    if (this.datepipe.transform(entidade.data_inicio_proposta, 'yyyy-MM-dd') >= this.datepipe.transform(entidade.data_abertura, 'yyyy-MM-dd')) {
      toastr.warning('Data início proposta esta maior que a data de abertura!');
      return;
    }

    if (this.datepipe.transform(entidade.data_inicio_pregao, 'yyyy-MM-dd') >= this.datepipe.transform(entidade.data_abertura, 'yyyy-MM-dd')) {
      toastr.warning('Data términio proposta esta maior que a data de pregão!');
      return;
    }

    if (!entidade.data_inicio_pregao) {
      toastr.warning('Informe a data do pregão!');
      return;
    }

    if (!entidade.prazo_proposta) {
      toastr.warning('Informe a prazo de apresentação!');
      return;
    }

    if (!this.login.parametro['licitacao'].licitacoes_e || !this.login.parametro['licitacao'].convenio_licitacoes_e) {
      toastr.warning('Informe no orgão os códigos do licitações-e!');
      return;
    }

    if (!entidade.comissao) {
      toastr.warning('Informe uma comissão!');
      return;
    }

    if (!entidade.forma_participacao || entidade.forma_participacao === 'null') {
      toastr.warning('Informe uma forma de participação!');
      return;
    }

    if (entidade.itens.find(memorial => !memorial.tipo_disputa ? true : false)) {
      entidade.itens.find(memorial => {
        toastr.warning('Informe um tipo de disputa do memorial ' + memorial.descricao + '!')
      });
      return;
    }

    if (entidade.itens.find(memorial => !memorial.tempo_disputa ? true : false)) {
      entidade.itens.find(memorial => {
        toastr.warning('Informe o tempo de disputa do memorial ' + memorial.descricao + '!')
      });
      return;
    }


    if (entidade.itens.find(memorial => !memorial.produto_unidade.produto.cod_mercadoria && memorial.lote === false ? true : false)) {
      entidade.itens.find(memorial => {
        if (!memorial.produto_unidade.produto.cod_mercadoria && memorial.lote === false) {
          toastr.warning('Informe o código da mercadoria do bb no produto ' + memorial.produto_unidade.produto.nome + '!');
        }
      })
      return;
    }

    if (entidade.itens.find(memorial => memorial.lote && memorial.itens.find(lote => !lote.produto.cod_mercadoria ? true : false))) {
      entidade.itens.find(memorial => memorial.lote && memorial.itens.find(lote => {
        if (!lote.produto.cod_mercadoria) {
          toastr.warning('Informe o código da mercadoria do bb no produto ' + lote.produto.nome + '!');
        }
      }))
      return;
    }
    
    if (entidade.comissao_membros.find(membros => (!membros?.comissao_membro?.codigo_executante || !membros?.comissao_membro?.codigo_perfil) ? true : false)) {
      toastr.warning('Informe nos membros o código executante e/ou código perfil na comissão ' + entidade.comissao.nome + '!');
      return;
    }

    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=ISO-8859-1,' + encodeURIComponent(this.gerarAOP711(entidade)));
    element.setAttribute('download', `AOP711_${this.entidade.numero}.txt`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  public importarAOP712() {
    if (!this.validar())
      return;
    this.licitacaoService.importarAOP712(this.file)
      .subscribe((data) => {
        console.log(data);
        // if (!data || !data.dados || data.dados.length === 0) {
        //   toastr.warning(`Leitura de arquivo não retorno nenhum resultado, favor verificar o arquivo.`);
        //   return;
        // }
        if (data.status !== 'V') {
          if (data.status === 'I')
            toastr.warning(`Arquivo informado invalido, favor verificar;`);
          if (data.status === 'R')
            toastr.warning(`Arquivo recusado, favor verificar`);
          return;
        } else {
          toastr.success(`Importado o arquivo AOP712 com sucesso!`);
          return;
        }

      });

  }

  public exportarAOP713() {

    if (!this.login.parametro['licitacao'].licitacoes_e || !this.login.parametro['licitacao'].convenio_licitacoes_e) {
      toastr.warning('Informe no orgão os códigos do Licitações-e!');
      return;
    }

    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=ISO-8859-1,' + encodeURIComponent(this.gerarAOP713()));
    element.setAttribute('download', `AOP713_${this.entidade.numero}.txt`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  public importarAOP714() {
    if (!this.validar())
      return;
    this.licitacaoService.importarAOP714(this.file)
      .subscribe((data) => {
        // if (!data || !data.dados || data.dados.length === 0) {
        //   toastr.warning(`Leitura de arquivo não retorno nenhum resultado, favor verificar o arquivo.`);
        //   return;
        // }
        console.log(data);
        if (data.status !== 'V') {
          if (data.status === 'I')
            toastr.warning(`Arquivo informado invalido, favor verificar;`);
          if (data.status === 'R')
            toastr.warning(`Arquivo recusado, favor verificar`);
          return;
        } else {
          toastr.success(`Importado o arquivo AOP714 com sucesso!`);
          return;
        }

      });

  }

  public importarAOP715() {
    if (!this.validar())
      return;

    this.licitacaoService.importarAOP715(this.file).pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        // if (!data || !data.dados || data.dados.length === 0) {
        //   toastr.warning(`Leitura de arquivo não retorno nenhum resultado, favor verificar o arquivo.`);
        //   return;
        // }
        console.log(data);
        if (data.status !== 'V') {
          if (data.status === 'I')
            toastr.warning(`Arquivo informado invalido, favor verificar;`);
          if (data.status === 'R')
            toastr.warning(`Arquivo recusado, favor verificar`);
          return;
        } {
          toastr.success(`Importado o arquivo AOP715 com sucesso!`);
          return;
        }

      });

  }

  private gerarAOP711(entidade: Licitacao): string {
    let arquivo = '';

    // HEADER
    arquivo += '00000000000'; // FILLER	X(011)	1	11	Informado 00000000000    
    arquivo += 'AOP711'; // Nome do padrão do Arquivo	X(006)  	12	17	Informado AOP711    
    arquivo += this.funcaoService.strEspaco('', 2); // FILLER	X(002)	18	19	Preencher com espaços    
    arquivo += this.funcaoService.strZero(new Date().getDate(), 2); // Dia do arquivo (DD)	9(002)	20	21	Informar no formato DD    
    arquivo += this.funcaoService.strZero(new Date().getMonth() + 1, 2); // Mês do arquivo (MM)	9(002)	22	23	Informar no formato MM    
    arquivo += new Date().getFullYear(); // Ano do arquivo (AAAA)	9(004)	24	27	Informar no formato AAAA    
    arquivo += this.funcaoService.strEspaco('', 2); // FILLER	X(002)	28	29	Preencher com espaços
    arquivo += this.funcaoService.strEspaco(this.login.parametro['licitacao'].licitacoes_e, 9); // Código do cliente na base corporativa - MCI	9(009)	30	38	Código interno do BB, criado quando do cadastramento do orgão.Solicitar o número à agência de relacionamento.
    arquivo += this.funcaoService.strEspaco('', 2); // FILLER	X(002)	39	40	Preencher com espaços
    arquivo += '000000026'; // Código identificador BB do convênio de troca de arquivo	9(009)	41	49	Informar (000000026)
    arquivo += this.funcaoService.strEspaco('', 2); // FILLER	X(002)	50	51	Preencher com espaços
    arquivo += this.funcaoService.strEspaco(this.login.parametro['licitacao'].convenio_licitacoes_e, 9); // Código do convênio do cliente no IED e AOP	9(009)	52	60	Código interno do BB, gerado quando do cadastramento no aplicativo LICIT. Solicitar o número à agência de relacionamento.
    arquivo += this.funcaoService.strEspaco('', 1); // FILLER	X(001)	61	61	Preencher com espaço
    arquivo += this.funcaoService.strEspaco('', 120); // Texto de descrição do convênio	X(120)	62	181	(vazio)
    arquivo += '010000000'; // Número de remessa do IED	9(009)	182	190	Número gerado pelo comprador, seqüêncial, iniciando em 01.
    arquivo += this.funcaoService.strEspaco('', 835); // FILLER	X(835)	191	1025	Preencher com espaços

    // REGISTRO TIPO 1 - LICITAÇÃO
    arquivo += '\r\n';
    arquivo += '01'; // Tipo de registro	9(002)       	1	2	Informar 01
    arquivo += this.funcaoService.strZero(entidade.id, 9); // Identificador da licitação (PK do cliente)	9(009)       	3	11	Número composto a critério do comprador, iniciado pelo código da Unidade Organizacional mais o identificador numérico da licitação
    arquivo += this.funcaoService.strEspaco(this.login.parametro['licitacao'].convenio_licitacoes_e, 9); // Código BB do comprador 	9(009)       	12	20	Informado o mesmo código do campo "12" do HEADER
    arquivo += this.funcaoService.strZero(1, 9); // Código BB da Unidade Organizacional	9(009)	21	29	Código gerado pelo BB, quando do cadastrameto da Unidade no sistema . Solicitar o número à agência de relacionamento. 
    arquivo += '0002'; // Código BB modalidade da licitação	9(004)	30	33	Informar (0001) para dispensa ; (0002) para pregão; (0003) para convite; (0004) para cotação de preços
    arquivo += '0001'; // Código BB do tipo de licitação 	9(004)	34	37	Informar (0001)
    arquivo += '0103'; // Código BB da moeda	9(004)	38	41	Informar (0103) para Real
    arquivo += this.funcaoService.strZero(entidade.numero.split('/').join(''), 10); // Número do edital	X(010)	42	51	Número gerado pelo comprador 
    arquivo += this.funcaoService.strZero(entidade.processo.split('/').join(''), 20); // Número do processo 	X(020)	52	71	Número gerado pelo comprador
    arquivo += this.funcaoService.converteDataBR(entidade.data_publicacao ? entidade.data_publicacao : new Date()).split('/').join('.'); // Data de publicação do edital no Diário Oficial da União(DOU)	X(010)	72	81	Informar no formato DD.MM.AAAA (incluir separadores)
    arquivo += this.datepipe.transform(entidade.data_inicio_proposta, 'yyyy-MM-dd-hh.mm.ss.SSSSSS'); // Timestamp de início de entrega das propostas 	X(026)	82	107	Formato AAAA-MM-DD-HH.MM.SS.MMMMMM (Ano.Mês.Dia.Hora.Minuto.Segundo.Milissegundo)
    arquivo += this.datepipe.transform(entidade.data_fim_proposta, 'yyyy-MM-dd-hh.mm.ss.SSSSSS'); // Timestamp de fim de entrega das propostas	X(026)	108	133	Formato AAAA-MM-DD-HH.MM.SS.MMMMMM (Ano.Mês.Dia.Hora.Minuto.Segundo.Milissegundo)
    arquivo += this.datepipe.transform(entidade.data_abertura, 'yyyy-MM-dd-hh.mm.ss.SSSSSS'); // Timestamp de abertura das propostas 	X(026)	134	159	Formato AAAA-MM-DD-HH.MM.SS.MMMMMM (Ano.Mês.Dia.Hora.Minuto.Segundo.Milissegundo)
    arquivo += this.datepipe.transform(entidade.data_inicio_pregao, 'yyyy-MM-dd-hh.mm.ss.SSSSSS'); // Timestamp de início do pregão	X(026)	160	185	Formato AAAA-MM-DD-HH.MM.SS.MMMMMM (Ano.Mês.Dia.Hora.Minuto.Segundo.Milissegundo)
    arquivo += this.funcaoService.strZero(entidade.prazo_proposta, 4); // Prazo para apresentação de recurso (dias)	9(004)	186	189	Informar a quantidade de dias, completando com zero à esquerda
    arquivo += this.funcaoService.strZero(entidade.forma_participacao, 4);; // Código BB da forma de participação	9(004)	190	193	Informar (0001) para amplo; (0002) para restrito; (0003) restrito por categoria; (0004) para selecionados 
    arquivo += '0001'; // Código BB do idioma princpal	9(004)	194	197	Informar (0001) para Português
    arquivo += this.funcaoService.strEspaco('', 828); // FILLER	X(828)	198	1025	Preencher com espaços.

    // REGISTRO TIPO 2 - TEXTO DA LICITAÇÃO
    arquivo += '\r\n';
    arquivo += '02'; // Tipo de registro	9(002)       	1	2	Informar 02
    arquivo += this.funcaoService.strZero(entidade.id, 9); // Identificador da licitação (PK do cliente)	9(009)       	3	11	Informar o mesmo número constante no campo "02" do registro tipo 01, de acordo com a respectiva licitação.
    arquivo += '0001'; // Código BB do idioma do texto	9(004)	12	15	Informar (0001) para Português
    arquivo += this.funcaoService.strEspaco(this.funcaoService.abreviarStr(entidade.objeto, 1000), 1000); // Texto de descrição da licitação	X(1000)	16	1015	Informar resumidamente do que se trata a licitação, atentando para o limite de caracteres.
    arquivo += this.funcaoService.strEspaco('', 10); // FILLER	X(010)	1016	1025	Preencher com espaços.

    entidade.itens.forEach(memorial => {
      // REGISTRO TIPO 3 - LOTE DA LICITAÇÃO
      arquivo += '\r\n';
      arquivo += '03'; //Tipo de registro	9(002)       	1	2	Informar 03
      arquivo += this.funcaoService.strZero(entidade.id, 9); //Identificador da licitação (PK do cliente)	9(009)       	3	11	Informar o mesmo número constante no campo "02" do registro tipo 01, de acordo com a respectiva licitação.
      arquivo += this.funcaoService.strZero(memorial.ordem, 4); //Identificador do lote da licitação (PK do cliente)	9(004)	12	15	Número gerado pelo comprador
      arquivo += this.funcaoService.strZero(memorial.tempo_disputa, 4); //Tempo em minutos de disputa	9(004)	16	19	Informar a quantidade de minutos que a disputa terá (tempo regulamentar), para licitação com disputa em sessão pública
      arquivo += '0002'; //Código BB de critério de seleção de propostas 	9(004)	20	23	Informar (0002)
      arquivo += this.funcaoService.strZero(memorial.tipo_disputa, 4); //Código BB do tipo de disputa 	9(004)	24	27	Informar (0002) para "com disputa em sessão pública"; (0003) para "sem disputa - proposta única";(0004) para "com disputa sem sessão pública"
      arquivo += this.funcaoService.strEspaco('', 998); //FILLER	X(998)	28	1025	Preencher com espaços.

      // REGISTRO TIPO 4 - TEXTO DO LOTE DA LICITAÇÃO
      arquivo += '\r\n';
      arquivo += '04'; // Tipo de registro	9(002)       	1	2	Informar 04
      arquivo += this.funcaoService.strZero(entidade.id, 9); // Identificador da licitação (PK do cliente)	9(009)       	3	11	Informar mesmo número constante do campo "02" do registro tipo 01, de acordo com a respectiva licitação
      arquivo += this.funcaoService.strZero(memorial.ordem, 4); // Identificador do lote da licitação (PK do cliente)	9(004)       	12	15	Informar mesmo número constante do campo "03" do registro tipo 03
      arquivo += '0001'; // Código BB do idioma do texto	9(004)	16	19	Informar (0001) para Português
      arquivo += this.funcaoService.strEspaco(this.funcaoService.abreviarStr(memorial.descricao, 1000), 1000); // Texto de descrição do lote da licitação	X(1000)	20	1019	Descrever resumidamente o conteúdo do lote
      arquivo += this.funcaoService.strEspaco('', 6); // FILLER	X(006)  	1020	1025	Preencher com espaços.

      // REGISTRO TIPO 5 - ITEM DA LICITAÇÃO
      arquivo += '\r\n';
      arquivo += '05'; //Tipo de registro	9(002)       	1	2	Informar 05
      arquivo += this.funcaoService.strZero(entidade.id, 9); //Identificador da licitação (PK do cliente)	9(009)       	3	11	Informar mesmo número constante do campo "02" do registro tipo 01, de acordo com a respectiva licitação
      arquivo += this.funcaoService.strZero(memorial.ordem, 4); //Identificador do lote da licitação (PK do cliente)	9(004)       	12	15	Informar mesmo número constante do campo "03" do registro tipo 03
      arquivo += this.funcaoService.strZero(memorial.ordem, 4); //Identificador do item da licitação (PK do cliente)	9(004)	16	19	Número gerado pelo comprador
      arquivo += this.funcaoService.strZero(memorial.produto_unidade.produto.cod_mercadoria, 9); //Código BB da mercadoria 	9(009)	20	28	Código fornecido através do arquivo AOP714, gerado a partir do AOP713
      arquivo += '0000'; //Código BB do produto	9(004)	29	32	Código fornecido através do arquivo AOP714 (opcional), gerado a partir do AOP713. Se não informado, preencher com (0000)
      arquivo += this.funcaoService.strZero(memorial.quantidade, 15); //Quantidade de itens a adquirir	9(015)	33	47	Informar a quantidade de itens, preenchendo o campo com zeros à esquerda
      arquivo += this.funcaoService.strEspaco('', 978); //FILLER	X(978)	48	1025	Preencher com espaços.

      // REGISTRO TIPO 6 - TEXTO DO ITEM DA LICITAÇÃO
      arquivo += '\r\n';
      arquivo += '06'; //Tipo de registro	9(002)       	1	2	Informar 06
      arquivo += this.funcaoService.strZero(entidade.id, 9); //Identificador da licitação (PK do cliente)	9(009)       	3	11	Informar o mesmo número constante no campo "02" do registro tipo 01, de acordo com a respectiva licitação.
      arquivo += this.funcaoService.strZero(memorial.ordem, 4); //Identificador do lote da licitação (PK do cliente)	9(004)	12	15	Informar o mesmo número constante no campo "03" do registro tipo 03
      arquivo += this.funcaoService.strZero(memorial.ordem, 4); //Identificador do item da licitação (PK do cliente)	9(004)	16	19	Informar o mesmo número constante no campo "04" do registro tipo 05
      arquivo += '0001'; //Código BB do idioma do texto	9(004)	20	23	Informar (0001) para Português
      arquivo += this.funcaoService.strEspaco(this.funcaoService.abreviarStr(memorial.descricao, 1000), 1000); //Texto de descrição do item da licitação	X(1000)	24	1023	Descrever minuciosamente o conteúdo do item
      arquivo += this.funcaoService.strEspaco('', 2); //FILLER	X(02)	1024	1025	Preencher com espaços.
    })

    // REGISTRO TIPO 7 - AUTORIZAÇÕES (CONDUTORES) DA LICITAÇÃO
    for (const comissao of entidade.comissao_membros) {
      arquivo += '\r\n';
      arquivo += '07'; //Tipo de registro	9(002)       	1	2	Informar 07
      arquivo += this.funcaoService.strZero(entidade.id, 9); //Identificador da licitação (PK do cliente)	9(009)       	3	11	Informar o mesmo número constante no campo "02" do registro tipo 01, de acordo com a respectiva licitação.
      arquivo += this.funcaoService.strZero(comissao.comissao_membro.codigo_executante, 9);//'000000000'; //Código BB do executante	9(009)	12	20	Código interno do BB, gerado quando do cadastramento da pessoa física autorizada a operar o sistema. Solicitar o número à agência de relacionamento
      arquivo += this.funcaoService.strZero(comissao.comissao_membro.codigo_perfil, 4); //Código BB do perfil	9(004)	21	24	Informar (0002) para apoio; (0003) para pregoeiro/coordenador; (0004) para autoridade competente do comprador 
      arquivo += this.funcaoService.strEspaco('', 1001); //FILLER	X(1001)	25	1025	Preencher com espaços.
    }

    // TRAILER
    arquivo += '\r\n';
    arquivo += '99999999999'; //FILLER	X(011)       	1	11	Informar (99999999999)
    arquivo += this.funcaoService.strZero(arquivo.split('\r\n').length, 9); //Quantidade de registro do arquivo, incluindo HEADER e TRAILER	9(009)       	12	20	Efetuar somatório e preencher com zero à esquerda, se necessário, de modo a completar o campo
    arquivo += this.funcaoService.strEspaco('', 1005); //FILLER	X(1005)	21	1025	Preencher com espaços.
    arquivo += '\r\n';

    arquivo = this.funcaoService.removerAcentos(arquivo);

    return arquivo;
  }

  private gerarAOP713(): string {
    let arquivo = '';

    // HEADER
    arquivo += '00000000000'; // FILLER	X(011)	1	11	Informar 00000000000    
    arquivo += 'AOP713'; // Nome do padrão do Arquivo	X(006)  	12	17	Informar AOP713
    arquivo += this.funcaoService.strEspaco('', 2); // FILLER	X(002)	18	19	Preencher com espaços    
    arquivo += this.funcaoService.strZero(new Date().getDate(), 2); // Dia do arquivo (DD)	9(002)	20	21	Informar no formato DD    
    arquivo += this.funcaoService.strZero(new Date().getMonth() + 1, 2); // Mês do arquivo (MM)	9(002)	22	23	Informar no formato MM    
    arquivo += new Date().getFullYear(); // Ano do arquivo (AAAA)	9(004)	24	27	Informar no formato AAAA    
    arquivo += this.funcaoService.strEspaco('', 2); // FILLER	X(002)	28	29	Preencher com espaços
    arquivo += this.funcaoService.strEspaco(this.login.parametro['licitacao'].convenio_licitacoes_e, 9); // Código do cliente na base corporativa - MCI	9(009)	30	38	Código interno do BB, criado quando do cadastramento do orgão.Solicitar o número à agência de relacionamento.
    arquivo += this.funcaoService.strEspaco('', 2); // FILLER	X(002)	39	40	Preencher com espaços
    arquivo += '000000026'; // Código identificador BB do convênio de troca de arquivo	9(009)	41	49	Informar (000000026)
    arquivo += this.funcaoService.strEspaco('', 2); // FILLER	X(002)	50	51	Preencher com espaços
    arquivo += this.funcaoService.strEspaco(this.login.parametro['licitacao'].licitacoes_e, 9); // Código do convênio do cliente no IED e AOP	9(009)	52	60	Código interno do BB, gerado quando do cadastramento no aplicativo LICIT. Solicitar o número à agência de relacionamento.
    arquivo += this.funcaoService.strEspaco('', 1); // FILLER	X(001)	61	61	Preencher com espaço
    arquivo += this.funcaoService.strEspaco('', 120); // Texto de descrição do convênio	X(120)	62	181	(vazio)
    arquivo += '010000000'; // Número de remessa do IED	9(009)	182	190	Número gerado pelo comprador, seqüêncial, iniciando em 01.

    // REGISTRO DETALHE
    arquivo += '\r\n';
    arquivo += '00'; // Tipo de registro	9(002)       	1	2	Informar 00
    arquivo += this.funcaoService.strEspaco('', 188); // FILLER	X(188)	3	190	Preencher com espaços.

    // TRAILER
    arquivo += '\r\n';
    arquivo += '99999999999'; //FILLER	X(011)       	1	11	Informar (99999999999)
    arquivo += this.funcaoService.strZero(arquivo.split('\r\n').length, 9); //Quantidade de registro do arquivo, incluindo HEADER e TRAILER	9(009)       	12	20	Efetuar somatório e preencher com zero à esquerda, se necessário, de modo a completar o campo
    arquivo += this.funcaoService.strEspaco('', 170); //FILLER	X(170)	21	190	Preencher com espaços.
    arquivo += '\r\n';

    arquivo = this.funcaoService.removerAcentos(arquivo);

    return arquivo;
  }

  private validar(): boolean {
    if (!this.file) {
      toastr.warning(`Não foi informado o arquivo corretamente!`);
      return false;
    }
    return true;
  }

  public uploadAOP714(input: any) {
    this.file = input.files;
    input.value = '';
    this.importarAOP714();
  }

  public uploadAOP712(input: any) {
    this.file = input.files;
    input.value = '';
    this.importarAOP712();
  }

  public uploadAOP715(input: any) {
    this.file = input.files;
    input.value = '';
    this.importarAOP715();
  }

  public uploadAOP603(input: any) {
    this.file = input.files;
    input.value = '';
    this.importarAOP03();
  }
}
