import { DatePipe } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ConvenioRecurso } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConvenioRecursoService extends BaseResourceService<ConvenioRecurso> {

  constructor(
    protected injector: Injector
  ) {
    super(`convenio-recursos`, injector);
  }

  public obterRelacionamentosFichas(convenio_id: number, orgao_id: number): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/relacionamentos-fichas?convenio_id=${convenio_id}&orgao_id=${orgao_id}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterConvenio(ficha_id: number, orgao_id: number, data: Date | string): Observable<any> {
    data = new DatePipe('pt').transform(data, 'yyyy-MM-dd');
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/obter-convenio/${ficha_id}/${orgao_id}/${data}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
