import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ContratoOcorrencia } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ContratoOcorrenciaService extends BaseResourceService<ContratoOcorrencia> {

  constructor(
    protected injector: Injector
  ) {
    super(`contrato-ocorrencias`, injector);
  }
}
