import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { InplaceModule } from 'primeng/inplace';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { PagamentoLoteDlgComponent } from './pagamento-lote-dlg/pagamento-lote-dlg.component';

@NgModule({
  declarations: [
    PagamentoLoteDlgComponent
  ],
  exports: [
    PagamentoLoteDlgComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    InplaceModule,
    RadioButtonModule,
    ConfirmDialogModule,
    FieldsetModule,
    CheckboxModule
  ],
  providers: [MessageService, ConfirmationService]
})
export class PagamentoComumModule { }
