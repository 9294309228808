import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, Banco } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class BancoService extends BaseResourceService<Banco> {

  constructor(
    protected injector: Injector
  ) {
    super(`bancos`, injector);
  }

  public obterPorNome(nome: string): Observable<Banco> {
    return this.http.get<Banco>(`${this.login.cidade.id}/${this.api}/filtrar?nome$like=${nome}%`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
