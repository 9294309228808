// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { PlanoConta } from '../contabil/plano-conta.model';
import { ContaBancariaRecurso } from './conta-bancaria-recurso.model';
import { ContaBancaria } from './conta-bancaria.model';

export class ContaBancariaSaldo extends BaseResourceModel {
  constructor(
    public id?: number,
    public saldo_anterior?: number,
    public conta?: ContaBancaria,
    public exercicio?: Exercicio,
    public plano?: PlanoConta,
    public aux?: number,
    public importado?: boolean,
    public ano?: number, // usado na contabilização
    public editavel?: boolean,
    public recursos?: ContaBancariaRecurso[]
  ) {
    super();
  }

  static converteJson(json: any): ContaBancariaSaldo {
    return Object.assign(new ContaBancariaSaldo(), json);
  }
}
