import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AdiantamentoService } from "administrativo-lib";
import { Adiantamento, FuncaoService, Login, OrgaoAssinatura, OrgaoAssinaturaService, ParecerControleInterno, Relatorio } from "eddydata-lib";
import { ParecerControleInternoService } from "projects/contabil/src/app/parecer-controle-interno/service/parecer-controle-interno.service";
import { Subject } from "rxjs";
import * as toastr from 'toastr'

@Component({
  selector: 'lib-adiantamento-parecer-dlg',
  templateUrl: './adiantamento-parecer-dlg.component.html'
})
export class AdiantamentoParecerDlgComponent implements OnInit, OnDestroy {

  protected unsubscribe: Subject<void> = new Subject();
  public assinatura: OrgaoAssinatura
  public parecer: ParecerControleInterno

  @Input() login: Login
  @Input() adiantamento: Adiantamento

  constructor(
    public adiantamentoService: AdiantamentoService,
    public orgaoAssinaturaService: OrgaoAssinaturaService,
    public funcaoService: FuncaoService,
    public parecerService: ParecerControleInternoService
  ) { }

  public async ngOnInit(): Promise<void> {
    this.assinatura = await this.orgaoAssinaturaService.obter({ orgao_id: this.login.orgao.id, 'data_limite$ge': this.funcaoService.converteDataSQL(new Date()), 'orderBy': 'data_limite$DESC' }).toPromise()
    this.parecer = await this.parecerService.obter({ orgao_id: this.login.orgao.id, exercicio_id: this.login.exercicio.id, orderBy: 'id$DESC' }).toPromise()
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async imprimir() {
    if (!this.assinatura) {
      toastr.warning('Cadastre Assinatura de Controle Interno para prosseguir!')
      return;
    }

    if (!this.parecer) {
      toastr.warning('É necessário cadastrar parecer interno para prosseguir!')
      return;
    }

    Relatorio.imprimirPersonalizado('', this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, this.montarConteudo(this.adiantamento), 'portrait', this.tituloRelatorio(), this.layoutRelatorio(), false, false, 'pdf')
  }

  public tituloRelatorio() {
    return 'PARECER';
  }

  public montarConteudo(adiantamento: Adiantamento) {
    const conteudo = []

    conteudo.push([{ text: 'PARECER', fontSize: 16, bold: true, alignment: 'center', border: [false, false, false, false] }])
    conteudo.push([{ text: '', margin: [0, 10, 0, 10], border: [false, false, false, false] }])

    conteudo.push([{
      text: `A ${this.login.orgao.nome} de acordo com o comunicado Nº${this.funcaoService.mascarar('####/####', this.parecer.numero_comunicado)}, Lei Municipal Nº${this.funcaoService.mascarar('####/####', this.parecer.lei)}, do Regimento de Adiantamento do Numerário, através do Departamento de Controle Interno, vem dar parecer ${adiantamento.parecer === 'F' ? 'Favorável'.toUpperCase() : adiantamento.parecer === 'D' ? 'Desfavorável'.toUpperCase() : ''}, sobre a regularidade da prestação de contas do empenho de adiantamento n° ${adiantamento.empenho?.numero}/${adiantamento.empenho?.exercicio?.ano}, já elaboradas e em nosso poder.`,
      fontSize: 12, border: [false, false, false, false]
    }]);
    conteudo.push([{ text: '', margin: [0, 10, 0, 10], border: [false, false, false, false] }]);

    conteudo.push([{ text: `${this.login.cidade.nome} - ${this.login.cidade.estado.uf}, ${this.funcaoService.formatarDataExtenso(new Date)}`, fontSize: 12, alignment: 'center', border: [false, false, false, false] }]);
    conteudo.push([{ text: '', fontSize: 12, margin: [0, 10, 0, 10], border: [false, false, false, false] }])
    conteudo.push([{ text: '_________________________________________________', fontSize: 12, alignment: 'center', border: [false, false, false, false] }])
    conteudo.push([{ text: `${this.assinatura.almoxarife}`, fontSize: 12, alignment: 'center', border: [false, false, false, false] }])
    conteudo.push([{ text: `${this.assinatura.cargo_almoxarife}`, fontSize: 12, alignment: 'center', border: [false, false, false, false] }])

    return [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          fontSize: 12,
          headerRows: 0,
          widths: ['*'],
          body: conteudo
        }
      }
    ];
  }

  public layoutRelatorio() {
    return {
      linhas: {
        hLineWidth() {
          return 1;
        },
        vLineWidth() {
          return 1;
        },
        hLineColor() {
          return 'black';
        },
        paddingLeft() {
          return 3;
        },
        paddingRight() {
          return 3;
        }
      }
    };
  }
}