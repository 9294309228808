import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FuncaoService } from 'eddydata-lib';

@Directive()
export class Anexo8DespesaCusteadasFundeb implements OnDestroy {

  protected funcaoService: FuncaoService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number) {
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(fundeb: any[], despesa: any[]): {}[] {
    const registros: {}[] = [
      [{
        text: 'INDICADORES DO FUNDEB', colSpan: 7, fontSize: 8, bold: true,
        margin: [5, 5, 5, 5], alignment: 'center', border: [true, false, true, true]
      }, '', '', '', '', '', ''],
      [
        { text: 'DESPESAS CUSTEADAS COM RECEITAS DO FUNDEB RECEBIDAS NO EXERCÍCIO', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'DESPESAS EMPENHADAS ATÉ O BIMESTRE (d)', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'DESPESAS LIQUIDADAS ATÉ O BIMESTRE (e)', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'DESPESAS PAGAS ATÉ O BIMESTRE (f)', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'INSCRITAS EM RESTOS A PAGAR NÃO PROCESSADOS (g)', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'INSCRITAS EM RESTOS A PAGAR NÃO PROCESSADOS (SEM DISPONIBILIDADE DE CAIXA) (h)', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'DESPESAS LIQUIDADAS/EMPENHADAS EM VALOR SUPERIOR AO TOTAL DAS RECEITAS RECEBIDAS NO EXERCÍCIO (i)', alignment: 'center', bold: true, fontSize: 7 },
      ],
    ];

    const grupos = this.funcaoService.agrupar(despesa, 'grupo', ['empenhado', 'liquidado', 'pago', 'resto']);

    const grupoFundeb = this.funcaoService.agrupar(fundeb, 'grupo', ['arrecadado', 'atualizado'])


    for (const grupo of grupos) {

      let inscritoResto: number = 0;
      let totalFundeb: number = 0;
      let totalReceita: number = 0;

      totalFundeb = grupoFundeb.reduce((acc, item) => +item.totalizadores['arrecadado'] + acc, 0);

      if (grupo.grupo === '11- TOTAL DAS DESPESAS CUSTEADAS COM RECURSOS DO FUNDEB RECEBIDAS NO EXERCÍCIO') {
        totalReceita = (+this.mes < 12 ? +grupo.totalizadores['liquidado'] : +grupo.totalizadores['empenhado']) - totalFundeb;
      }

      registros.push([
        { text: grupo.grupo, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['resto']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(inscritoResto), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(totalReceita > 0 ? totalReceita : 0), alignment: 'right', fontSize: 7 }
      ]);

      const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['empenhado', 'liquidado', 'pago', 'resto']);

      let subFundeb = this.funcaoService.agrupar(fundeb, 'subgrupo', ['arrecadado', 'atualizado'])


      for (const subgrupo of subgrupos) {
        totalFundeb = subFundeb.reduce((acc, item) => ((((subgrupo.grupo as string).startsWith('11.1') && (item.grupo as string).startsWith('6.1'))
          || ((subgrupo.grupo as string).startsWith('11.2') && (item.grupo as string).startsWith('6.2'))
          || ((subgrupo.grupo as string).startsWith('11.3') && (item.grupo as string).startsWith('6.3'))
          || ((subgrupo.grupo as string).startsWith('11.4') && (item.grupo as string).startsWith('6.4'))) ? +item.totalizadores['arrecadado'] : 0) + acc, 0);

        totalReceita = (subgrupo.grupo as string).startsWith('11') ? ((+this.mes < 12 ? +subgrupo.totalizadores['liquidado'] : +subgrupo.totalizadores['empenhado']) - totalFundeb) : 0;
        if (subgrupo.grupo != '') {
          registros.push([
            { text: subgrupo.grupo, fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['pago']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['resto']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(inscritoResto), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(totalReceita > 0 ? totalReceita : 0), alignment: 'right', fontSize: 7 },
          ]);
        }
      }
    }

    return registros;

  }

  public montarQuadroCsv(fundeb: any[], dados: any[], listaExportar): any {

    const espaco = {
      titulo: '',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(espaco)
    
    const primeira_linha = {
      titulo: 'INDICADORES DO FUNDEB',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
    }
    listaExportar.push(primeira_linha)

    const segunda_linha = {
      titulo: 'DESPESAS CUSTEADAS COM RECEITAS DO FUNDEB RECEBIDAS NO EXERCÍCIO',
      coluna2: 'DESPESAS EMPENHADAS ATÉ O BIMESTRE (d)', alignment: 'center',
      coluna3: 'DESPESAS LIQUIDADAS ATÉ O BIMESTRE (e)',
      coluna4: 'DESPESAS PAGAS ATÉ O BIMESTRE (f)',
      coluna5: 'INSCRITAS EM RESTOS A PAGAR NÃO PROCESSADOS (g)',
      coluna6: 'INSCRITAS EM RESTOS A PAGAR NÃO PROCESSADOS (SEM DISPONIBILIDADE DE CAIXA) (h)',
      coluna7: 'DESPESAS LIQUIDADAS/EMPENHADAS EM VALOR SUPERIOR AO TOTAL DAS RECEITAS RECEBIDAS NO EXERCÍCIO (i)',
    }
    listaExportar.push(segunda_linha)

    const grupos = this.funcaoService.agrupar(dados, 'grupo', ['empenhado', 'liquidado', 'pago', 'resto']);

    const grupoFundeb = this.funcaoService.agrupar(fundeb, 'grupo', ['arrecadado', 'atualizado'])

    for (const grupo of grupos) {

      let inscritoResto: number = 0;
      let totalFundeb: number = 0;
      let totalReceita: number = 0;

      totalFundeb = grupoFundeb.reduce((acc, item) => +item.totalizadores['arrecadado'] + acc, 0);

      if (grupo.grupo === '11- TOTAL DAS DESPESAS CUSTEADAS COM RECURSOS DO FUNDEB RECEBIDAS NO EXERCÍCIO') {
        totalReceita = grupo.totalizadores['empenhado'] - totalFundeb;
      }

      const segunda_linha = {
        titulo: grupo.grupo,
        coluna2: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']), alignment: 'center',
        coluna3: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']),
        coluna4: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago']),
        coluna5: this.funcaoService.convertToBrNumber(grupo.totalizadores['resto']),
        coluna6: this.funcaoService.convertToBrNumber(inscritoResto),
        coluna7: this.funcaoService.convertToBrNumber(totalReceita)
      }
      listaExportar.push(segunda_linha)

      const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['empenhado', 'liquidado', 'pago', 'resto']);

      let subFundeb = this.funcaoService.agrupar(fundeb, 'subgrupo', ['arrecadado', 'atualizado'])


      for (const subgrupo of subgrupos) {
        totalFundeb = subFundeb.reduce((acc, item) => ((((subgrupo.grupo as string).startsWith('11.1') && (item.grupo as string).startsWith('6.1'))
          || ((subgrupo.grupo as string).startsWith('11.2') && (item.grupo as string).startsWith('6.2'))
          || ((subgrupo.grupo as string).startsWith('11.3') && (item.grupo as string).startsWith('6.3'))
          || ((subgrupo.grupo as string).startsWith('11.4') && (item.grupo as string).startsWith('6.4'))) ? +item.totalizadores['arrecadado'] : 0) + acc, 0);

        totalReceita = (subgrupo.grupo as string).startsWith('11') ? (subgrupo.totalizadores['empenhado'] - totalFundeb) : 0;
        if (subgrupo.grupo != '') {
          const linha_subgrupo = {
            titulo: subgrupo.grupo,
            coluna2: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado']), alignment: 'center',
            coluna3: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liquidado']),
            coluna4: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['pago']),
            coluna5: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['resto']),
            coluna6: this.funcaoService.convertToBrNumber(inscritoResto),
            coluna7: this.funcaoService.convertToBrNumber(totalReceita)
          }
          listaExportar.push(linha_subgrupo)
        }
      }
    }
  }

}