import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { ToastModule } from 'primeng/toast';
import { LicitacaoLstComponent } from './licitacao-lst/licitacao-lst.component';
import { LicitacaoShwComponent } from './licitacao-shw/licitacao-shw.component';
import { LicitacaoRoutingModule } from './licitacao-routing.module';


@NgModule({
  declarations: [
    LicitacaoLstComponent,
    LicitacaoShwComponent,
  ],
  imports: [
    CommonModule,
    LicitacaoRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
    ChartModule,
    IMaskModule,
    ToastModule,
  ]
})
export class LicitacaoModule { }
