import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DateFormatPipe, FuncaoService, GlobalService, Licitacao, LicitacaoOcorrencia, Login, TipoOcorrenciaLicitacao } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { LicitacaoOcorrenciaService } from './service/licitacao-ocorrencia.service';

declare var $: any;

@Component({
  selector: 'lib-licitacao-ocorrencia',
  templateUrl: './licitacao-ocorrencia.component.html'
})
export class LicitacaoOcorrenciaComponent implements OnInit, OnDestroy {

  @Input() entidade: Licitacao;
  @Input() login: Login;

  public itemAnterior: LicitacaoOcorrencia;
  public itemAtual: LicitacaoOcorrencia;
  public listaTipo: { id: TipoOcorrenciaLicitacao, nome: string }[];

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private globalService: GlobalService,
    public funcaoService: FuncaoService,
    protected confirmationService: ConfirmationService,
    protected ocorrenciaService: LicitacaoOcorrenciaService,
  ) { }

  ngOnInit(): void {
    this.listaTipo = this.globalService.obterTiposOcorrenciaLicitacao();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public adicionar() {
    this.itemAtual = new LicitacaoOcorrencia();
    this.itemAtual.data_ocorrencia = new Date();
    this.itemAtual.tipo = 'OUTRO';
    this.itemAtual.usuario = this.login.usuario;

    $('#dialogLicitacaoOcorrencia').modal('show');
  }

  public editar(item: LicitacaoOcorrencia) {
    if (!item) return;
    this.itemAtual = item;
    this.itemAtual.data_ocorrencia = new DateFormatPipe().transform(this.itemAtual.data_ocorrencia, []);

    $('#dialogLicitacaoOcorrencia').modal('show');
  }

  public salvar() {
    if (this.itemAtual) {
      if (!this.entidade.ocorrencias) {
        this.entidade.ocorrencias = [];
      }

      const itm = this.entidade.ocorrencias.find((i) => i.tipo === this.itemAtual.tipo && i.data_ocorrencia === this.itemAtual.data_ocorrencia && i.ocorrencia === this.itemAtual.ocorrencia)
      if (itm) {
        itm.data_ocorrencia = this.itemAtual.data_ocorrencia;
        itm.ocorrencia = this.itemAtual.ocorrencia;
        itm.tipo = this.itemAtual.tipo;
      } else {
        this.itemAtual.data_cadastro = new Date();
        this.entidade.ocorrencias.push(this.itemAtual);
      }
      this.entidade.ocorrencias.sort((a, b) => { return new Date(b.data_ocorrencia).getTime() - new Date(a.data_ocorrencia).getTime() });
      this.itemAtual = undefined

      $('#dialogLicitacaoOcorrencia').modal('hide');
    }
  }

  public remover(item: LicitacaoOcorrencia, index: number) {
    if (item.id) {
      this.confirmationService.confirm({
        message: 'Deseja realmente remover a ocorrência?',
        header: 'Exclusão',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Confirmar',
        rejectLabel: 'Cancelar',
        accept: () => {
          this.ocorrenciaService.remover(item.id).pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
              toastr.info('Registro removido com sucesso!', 'Exclusão');
              this.entidade.ocorrencias.splice(index, 1);
            }, (err) => toastr.error(err.error.payload));
        }
      });
    } else {
      this.entidade.ocorrencias.splice(index, 1);
    }
  }

}
