import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { ContratoService } from "administrativo-lib";
import { AssinaturaService, BaseResourceRptPersonalizadoComponent, Contrato, ContratoAditamento, CpfPipe, EddyAutoComplete, GlobalService, LicitacaoPipe } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-contrato-recurso-rpt-dlg',
  templateUrl: './contrato-recurso-rpt-dlg.component.html'
})
export class ContratoRecursoRptDlgComponent extends BaseResourceRptPersonalizadoComponent {

  protected datepipe: DatePipe;
  public ptBR: any;
  public dataInicio: Date;
  public dataFinal: Date;
  public contrato: Contrato;
  public contratoAutoComplete: EddyAutoComplete<Contrato>;
  public tipoRegistro: '*' | 'CONTRATO' | 'REGISTRO_PRECO';
  public listTipoRegistro: { id: '*' | 'CONTRATO' | 'REGISTRO_PRECO' | 'ATA_REGISTRO', nome: string }[];

  constructor(
    private globalService: GlobalService,
    protected assinaturaService: AssinaturaService,
    private contratoService: ContratoService,
  ) {
    super(assinaturaService);
  }

  protected afterInit(): void {
    this.orientacao = 'landscape'
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.dataInicio = new Date(new Date().getFullYear(), 0, 1)
    this.dataFinal = new Date();

    this.listTipoRegistro = [
      { id: '*', nome: 'Todos' },
      { id: 'CONTRATO', nome: 'Contratos' },
      { id: 'REGISTRO_PRECO', nome: 'Registros de Preço' },
    ]
    this.tipoRegistro = '*'

    this.contratoAutoComplete = new EddyAutoComplete(null, this.contratoService,
      'id', ['numero', 'favorecido.nome'], {
      orgao_id: this.login.orgao.id, relations: 'favorecido',
      orderBy: 'favorecido.nome'
    }, { number: ['numero'], text: ['numero', 'favorecido.nome'] });
  }

  protected modalRelatorio(): string {
    return 'dialogRelatorioContratoRecurso';
  }

  protected tituloRelatorio(): string {
    return `${this.login.orgao.nome} - Totalização de Contratos por Recurso`;
  }

  protected subTituloRelatorio(): string {
    const retorno = `Período de ${this.funcaoService.converteDataBR(this.dataInicio)} à ${this.funcaoService.converteDataBR(this.dataFinal)}`
    if (this.contrato)
      return `Contrato: ${new LicitacaoPipe().transform(this.contrato.numero)} - ` + retorno;
    else
      return retorno
  }

  protected layoutRelatorio(): {} {
    return 
  }

  protected async montarConteudo(): Promise<{}[]> {
    const conteudo = [];
    return await new Promise(async (resolve) => {
      conteudo.push(await this.dadosItens())

      resolve(conteudo);
    })
  }

  private async dadosItens(): Promise<{}[]> {
    return await new Promise((resolve) => {
      this.contratoService.relatorioRecurso(
        this.login.orgao.id, this.datepipe.transform(this.dataInicio, 'yyyy-MM-dd'), this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd'), this.contrato?.id, this.tipoRegistro
      ).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          const reg: {
            contrato: Contrato,
            aditivos: ContratoAditamento[],
            recursos: { recurso: string, total: number }[],
          }[] = res as any[];

          if (reg.length === 0) resolve([])
          else {
            const content = []

            for (const item of reg) {
              content.push({ text: '', margin: [0, 5] });

              // CONTRATO
              const tabelaContrato = []
              tabelaContrato.push([
                { text: 'Contrato', bold: true, alignment: 'center' },
                { text: 'Processo', bold: true, alignment: 'center' },
                { text: 'Licitação', bold: true, alignment: 'center' },
                { text: 'Data', bold: true, alignment: 'center' },
                { text: 'Modalidade', bold: true },
                { text: 'Tipo', bold: true },
                { text: 'Requisitante', bold: true },
                { text: 'Destino', bold: true },
                { text: 'Contratado', bold: true },
                { text: 'Vl. Contrato', bold: true, alignment: 'center' },
                { text: 'Vl. Licitação', bold: true, alignment: 'center' },
              ])
              tabelaContrato.push([
                { text: new LicitacaoPipe().transform(item.contrato.numero), alignment: 'center' },
                { text: new LicitacaoPipe().transform(item.contrato.processo), alignment: 'center' },
                { text: new LicitacaoPipe().transform(item.contrato.licitacao?.numero), alignment: 'center' },
                { text: this.funcaoService.converteDataBR(item.contrato.data_assinatura), alignment: 'center' },
                { text: item.contrato.modalidade ? item.contrato.modalidade.nome : item.contrato.licitacao?.modalidade?.nome },
                { text: item.contrato.tipo_contrato },
                { text: item.contrato.licitacao?.setor?.nome },
                { text: item.contrato.licitacao?.setor?.nome },
                { text: `${new CpfPipe().transform(item.contrato.favorecido.cpf_cnpj)} - ${item.contrato.favorecido.nome}` },
                { text: this.funcaoService.convertToBrNumber(item.contrato.valor_contrato), alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber(item.contrato.licitacao?.valor_licitado), alignment: 'right' },
              ])
              tabelaContrato.push([
                { text: item.contrato.objeto, alignment: 'justify', colSpan: 11 }
              ])
              content.push(
                {
                  layout: 'headerLineOnly',
                  table: {
                    dontBreakRows: true,
                    headerRows: 1,
                    widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto'],
                    body: tabelaContrato
                  }
                }
              )

              // ADITIVOS
              const tabelaAditivo = []
              tabelaAditivo.push([
                { text: 'Aditivos', bold: true, colSpan: 5 }, { text: '' }, { text: '' }, { text: '' }, { text: '' },
              ])
              if (item.aditivos?.length > 0) {
                for (const aditivo of item.aditivos) {
                  tabelaAditivo.push([
                    { text: aditivo.numero, alignment: 'center' },
                    { text: this.funcaoService.converteDataBR(aditivo.data_inicio), alignment: 'center' },
                    { text: this.funcaoService.converteDataBR(aditivo.data_termino), alignment: 'center' },
                    { text: aditivo.finalidade },
                    { text: this.funcaoService.convertToBrNumber(aditivo.valor_total), alignment: 'right' },
                  ])
                }
              }

              // RECURSOS
              const tabelaRecurso = []
              tabelaRecurso.push([
                { text: 'Recursos', bold: true, colSpan: 2, margin: [5, 0, 0, 0] }, { text: '' },
              ])
              if (item.recursos?.length > 0) {
                for (const recurso of item.recursos) {
                  tabelaRecurso.push([
                    { text: recurso.recurso, margin: [5, 0, 0, 0] },
                    { text: this.funcaoService.convertToBrNumber(recurso.total), alignment: 'right' },
                  ])
                }
              }

              content.push({
                columns: [
                  {
                    table: {
                      headerRows: 1,
                      widths: ['auto', 'auto', 'auto', '*', 'auto'],
                      body: tabelaAditivo
                    },
                    layout: 'noBorders'
                  },
                  {
                    table: {
                      headerRows: 1,
                      widths: ['*', 'auto'],
                      body: tabelaRecurso
                    },
                    layout: 'noBorders'
                  },
                ]
              })
            }

            resolve(content)
          }
        });
    })
  }

}