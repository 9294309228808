import { Component, Input, OnInit } from '@angular/core';
import { Audesp4LicencaAmbiental, ExigenciaObras, FuncaoService } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-licenca-ambiental',
  templateUrl: './licitacao-audesp-licenca-ambiental.component.html'
})
export class LicitacaoAudespLicencaAmbientalComponent extends BaseLicitacaoAudesp implements OnInit {

  @Input() public exigenciaObras: ExigenciaObras;

  public itemAtual: Audesp4LicencaAmbiental;
  public itemAnterior: Audesp4LicencaAmbiental;

  public opcoesTipoLicenca: { id: number, nome: string }[];

  constructor(public funcaoService: FuncaoService) {
    super()
  }

  ngOnInit(): void {
    this.opcoesTipoLicenca = [
      { id: 4, nome: 'Autorização Ambiental' },
      { id: 2, nome: 'Licença de Instalação' },
      { id: 3, nome: 'Licença de Operação' },
      { id: 1, nome: 'Licença Prévia' },
    ]
  }

  public adicionar() {
    if (!this.exigenciaObras.LicencaAmbientalSim || this.exigenciaObras.LicencaAmbientalSim.length === 0) this.exigenciaObras.LicencaAmbientalSim = [];

    this.itemAtual = { TipoLicenciamento: undefined, NumLicenca: undefined, DataLicenca: undefined, editavel: true }
    this.exigenciaObras.LicencaAmbientalSim.push(this.itemAtual);
  }

  public visualizar(item: Audesp4LicencaAmbiental) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      let itm = this.exigenciaObras.LicencaAmbientalSim.find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.exigenciaObras.LicencaAmbientalSim.splice(this.exigenciaObras.LicencaAmbientalSim.indexOf(this.itemAtual), 1, itm)
      this.itemAtual.editavel = false;
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4LicencaAmbiental) {
    this.itemAtual = undefined;
    this.exigenciaObras.LicencaAmbientalSim.splice(this.exigenciaObras.LicencaAmbientalSim.indexOf(item), 1)
  }

  public salvar() {
    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }

}
