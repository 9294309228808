// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { AdiantamentoChecklist } from './adiantamento-checklist.model';
import { Exercicio } from '../comum/exercicio.model';

export class AuditoriaAdiantamento  extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_auditoria?: Date,
    public data_adiantamento?: Date,
    public data_vencimento?: Date,
    public data_prestacao?: Date,
    public ano?: number,
    public numero?: number,
    public especie?: string,
    public motivo?: string,
    public beneficiario?: string,
    public cargo_beneficiario?: string,
    public destino?: string,
    public observacao?: string,
    public conclusao?: string,
    public recomendacao?: string,
    public cancelado?: boolean,
    public valor_adiantado?: number,
    public valor_complemento?: number,
    public orgao?: Orgao,
    public exercicio?: Exercicio,
    public checklist?: AdiantamentoChecklist[]
    ) {
      super();
    }
    static converteJson(json: any): AuditoriaAdiantamento {
      return Object.assign(new AuditoriaAdiantamento(), json);
    }
}
