import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { InputMaskModule } from 'primeng/inputmask';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { AtaPersonalizadaModule } from '../ata-personalizada/ata-personalizada.module';
import { PlanoContratacaoAnualFormComponent } from './plano-contratacao-anual-form/plano-contratacao-anual-form.component';
import { PlanoContratacaoAnualListComponent } from './plano-contratacao-anual-list/plano-contratacao-anual-list.component';
import { PlanoContratacaoAnualRoutingModule } from './plano-contratacao-anual-routing.module';
import { PlanoContratacaoAnualViewComponent } from './plano-contratacao-anual-view/plano-contratacao-anual-view.component';
import { RadioButtonModule } from 'primeng/radiobutton';

@NgModule({
  declarations: [PlanoContratacaoAnualListComponent, PlanoContratacaoAnualFormComponent, PlanoContratacaoAnualViewComponent],
  imports: [
    CommonModule,
    PlanoContratacaoAnualRoutingModule,
    SharedModule,
    FormsModule,
    ToastModule,
    IMaskModule,
    AtaPersonalizadaModule,
    AutoCompleteModule,
    SelectButtonModule,
    CalendarModule,
    InputMaskModule,
    FieldsetModule,
    CheckboxModule,
    ConfirmDialogModule,
    RadioButtonModule
  ],
  providers: [ConfirmationService]
})
export class PlanoContratacaoAnualModule { }
