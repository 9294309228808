import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService, Login, LoginService, UsuarioService } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';


@Component({
  selector: 'app-transparencia-trocar-senha',
  templateUrl: './transparencia-trocar-senha.component.html'
})
export class TransparenciaTrocarSenhaComponent implements OnInit, OnDestroy {

  public login: Login = new Login();
  public senhaAtual: string;
  public senhaNova: string;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected router: Router,
    private usuarioService: UsuarioService,
    private loginService: LoginService
  ) {
  }

  ngOnInit() {
    this.login = GlobalService.obterSessaoLogin();
    this.login.usuario.orgao = this.login.orgao
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  enviar() {
    this.login.usuario.senha = this.senhaAtual;
    this.login.usuario.senha_nova = this.senhaNova;
    this.usuarioService.trocarSenha(this.login.usuario).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        toastr.success('Senha foi alterada com sucesso!');
        this.login.usuario.orgao = this.login.orgao;
        this.loginService.desconectar(this.login.usuario);
      }, (error) => {
        toastr.error('Senha informada não confere, tente novamente!');
      });
    this.senhaAtual = null;
    this.senhaNova = null;
  }

}
