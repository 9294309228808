import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, Compra, Rcms } from 'eddydata-lib';
import { HttpParams } from '@angular/common/http';
import { ItensPendentesOFFilters } from '../itens-pendentes/pendencia-patrimonio-rpt';

@Injectable({
  providedIn: 'root'
})
export class CompraService extends BaseResourceService<Compra> {

  constructor(
    protected injector: Injector
  ) {
    super(`compras`, injector);
  }

  public obterPorNumero(numero: number, exercicioId: number, orgaoId: number, relations?: string): Observable<Compra> {
    return this.http.get<Compra>(`${this.login.cidade.id}/${this.api}/filtrar?numero=${numero}&exercicio.id=${exercicioId}&orgao.id=${orgaoId}${relations.length ? '&relations=' + relations : ''}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterDatasPosteriores(orgao: number, exercicio: number, parametros?: { data_base?: string, compra?: number, rcms?: number, empenho?: number, excluido: boolean }): Observable<any> {
    const params = this.converterParametrosStr(parametros);
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/datas-posteriores/${exercicio}/${orgao}${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public proximoNumeroOF(exercicio: number, orgaoId: number, semProgresso?: boolean): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-numero/${exercicio}/${orgaoId}`,
      this.httpOptions(semProgresso)).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public buscaOfAlmoxarifadoEstoqueNaoObrigatorio(exercicioId: number, orgaoId: number, numero: number, dt_movimento: Date, estoque_id: number, favorecido?: number, somenteEntrada?: boolean, exercicioAtual?: number) {
    return this.buscaOfAlmoxarifadoBase('busca-of-almoxarifado-estoque-nao-obrigatorio', exercicioId, orgaoId, numero, dt_movimento, estoque_id, favorecido, somenteEntrada, exercicioAtual)
  }

  public buscaOfAlmoxarifado(exercicioId: number, orgaoId: number, numero: number, dt_movimento: Date, estoque_id: number, favorecido?: number, somenteEntrada?: boolean, exercicioAtual?: number) {
    return this.buscaOfAlmoxarifadoBase('busca-of-almoxarifado', exercicioId, orgaoId, numero, dt_movimento, estoque_id, favorecido, somenteEntrada, exercicioAtual)
  }

  private buscaOfAlmoxarifadoBase(rotaBase: string, exercicioId: number, orgaoId: number, numero: number, dt_movimento: Date, estoque_id: number, favorecido?: number, somenteEntrada?: boolean, exercicioAtual?: number) {
    let rota = `${this.login.cidade.id}/${this.api}/${rotaBase}/${exercicioId}/${orgaoId}/${numero}/${dt_movimento}/${estoque_id}`

    rota += favorecido
      ? `/${favorecido}`
      : `?somenteEntrada=${somenteEntrada}${exercicioAtual ? `&exercicioAtual=${exercicioAtual}` : ''}`

    return this.http.get<any>(rota, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public buscaOfAlmoxarifadoOutroSetor(exercicioId: number, orgaoId: number, numero: number, dt_movimento: Date, favorecido?: number, somenteEntrada?: boolean, exercicioAtual?: number) {
    let rota: string;
    if (favorecido) {
      rota = `${this.login.cidade.id}/${this.api}/busca-of-almoxarifado-outro-setor/${exercicioId}/${orgaoId}/${numero}/${dt_movimento}/${favorecido}`;
    } else {
      rota = `${this.login.cidade.id}/${this.api}/busca-of-almoxarifado-outro-setor/${exercicioId}/${orgaoId}/${numero}/${dt_movimento}?somenteEntrada=${somenteEntrada}${exercicioAtual ? `&exercicioAtual=${exercicioAtual}` : ''}`;
    }
    return this.http.get<any>(rota, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public proximaDataCompra(exercicio: number, orgaoId: number): Observable<Date> {
    return this.http.get<Date>(
      `${this.login.cidade.id}/${this.api}/proxima-data/${exercicio}/${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public exportarCompra(compras: Compra[], exercicio: number, orgaoId: number): Observable<Compra[]> {
    return this.http.post<Compra[]>(
      `${this.login.cidade.id}/${this.api}/exportar-empenho/${exercicio}/${orgaoId}`, { compras },
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public importarRcms(rcmss: Rcms[], exercicio: number, orgaoId: number, data_compra: string): Observable<Compra[]> {
    return this.http.post<Compra[]>(
      `${this.login.cidade.id}/${this.api}/importar-rcms/${exercicio}/${orgaoId}`, { rcmss, data_compra, usuario: this.login.usuario },
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalizadores(exercicio: number, orgaoId: number, numero: number): Observable<any> {
    let parametros = '';
    parametros += '?exercicio_id=' + exercicio;
    parametros += '&orgao_id=' + orgaoId;
    parametros += '&numero=' + numero;
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/extendido/1/1${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalizadoresFicha(exercicio: number, orgaoId: number, fichaId: number, dataCompra: string, notProgress?: boolean): Observable<any> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/totalizadores-ficha/${exercicio}/${orgaoId}/${fichaId}/${dataCompra}`,
      this.httpOptions(notProgress)).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public saldoDispensacaoSubElemento(exercicio: number, orgaoId: number, subelemento: string, data_base: string, parametros?: { compra?: number, rcms?: number }): Observable<any> {
    let params = this.converterParametrosStr(parametros);
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/saldo-dispensa-subelemento/${exercicio}/${orgaoId}/${subelemento}/${data_base}${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public saldoDispensacaoFavorecido(exercicio: number, orgaoId: number, favorecido: number, data_base: string, parametros?: { compra?: number, rcms?: number }): Observable<any> {
    let params = this.converterParametrosStr(parametros);
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/saldo-dispensa-favorecido/${exercicio}/${orgaoId}/${favorecido}/${data_base}${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public saldoModalidade(exercicio: number, orgaoId: number, modalidade: number, data_base: string, parametros?: { compra?: number, rcms?: number }): Observable<any> {
    let params = this.converterParametrosStr(parametros);
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/saldo-modalidade/${exercicio}/${orgaoId}/${modalidade}/${data_base}${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public alterarNumeroImpressao(numero: number, compra: number): Observable<any> {
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/alterar-numero-impressao`, { numero, compra }, this.httpOptions());
  }

  public obterDetalhada(compra_id: number, exercicio_id: number, orgao_id: number): Observable<Compra> {
    return this.http.get<Compra>(`${this.login.cidade.id}/${this.api}/detalhado/${exercicio_id}/${orgao_id}/${compra_id}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err)));
  }


  public alterarNumeroImpressaoLote(numeros: { numero: number, compra: number }[]): Observable<any> {
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/alterar-numero-impressao/lote`, { numeros }, this.httpOptions());
  }

  public buscarProdutosOfsPendentes({
    exercicio,
    orgao,
    sequenciaCadastro,
    dataFim,
    dataIni,
    fornecedor,
    numeroFim,
    numeroIn,
    setor,
    usuario
  }: ItensPendentesOFFilters): Observable<Compra[]> {
    let params = new HttpParams().appendAll({
      exercicio,
      orgao,
      sequenciaCadastro,
    });

    if (dataIni) {
      params = params.append('dataIni', dataIni);
    }

    if (dataFim) {
      params = params.append('dataFim', dataFim);
    }

    if (numeroIn) {
      params = params.append('numeroIn', numeroIn);
    }

    if (numeroFim) {
      params = params.append('numeroFim', numeroFim);
    }

    if (fornecedor) {
      params = params.append('fornecedor', fornecedor);
    }

    if (setor) {
      params = params.append('setor', setor);
    }

    if (usuario) {
      params = params.append('usuario', usuario);
    }

    const authOptions = this.httpOptions();
    return this.http.get<Compra[]>(`${this.login.cidade.id}/${this.api}/produtos-pendentes-of`, { ...authOptions, params });
  };

  public comprasPendentesConsumoMedio(produtoId: number, exercicio: number, orgao: number): Observable<number> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/compras-pendentes-consumo-medio/${produtoId}/${exercicio}/${orgao}`, this.httpOptions()).pipe(map(res => res), catchError(err => this.handleError(err)));
  }
}
