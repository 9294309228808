import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BaseResourceListComponent, Coluna, DateFormatPipe, Empenho, Filtro, FormatoExportacao, GlobalService, LoginPublico
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmpenhoService } from '../service/empenho.service';

@Component({
  selector: 'lib-empenho-lst',
  templateUrl: './empenho-lst.component.html'
})
export class EmpenhoLstComponent extends BaseResourceListComponent<Empenho, LoginPublico> {

  /**
   * Declaração de variáveis
   */
  public titulo = 'EMPENHOS ORÇAMENTÁRIOS';
  public especie = 'todos';
  public ptBR: any;
  public datepipe: DatePipe;
  public data1: Date;
  public data2: Date;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private route: ActivatedRoute,
    protected injector: Injector,
    private globalService: GlobalService,
    private empenhoService: EmpenhoService) {
    super(empenhoService, injector);
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.especie = params['especie'];
        this.data1 = new DateFormatPipe().transform(params['data1'], []);
        this.data2 = new DateFormatPipe().transform(params['data2'], []);
        this.paginaCorrente = +params['pagina'];
        this.filtro = params['filtro'];
      });
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'favorecido,subelemento,compra,ficha,ficha.acao';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['data_empenho'];
  }

  protected filtrosGrid(): Filtro {
    return {};
  }

  public beforeInit(): void {
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
    if (this.login.exercicio && !this.data1) {
      this.data1 = new Date(`01/01/${this.login.exercicio.ano}`);
      if (this.login.exercicio.ano !== new Date().getFullYear()) {
        this.data2 = new Date(`12/31/${this.login.exercicio.ano}`);
      }
    }
    this.col = 2;
  }

  protected afterInit(): void {
    window.scrollTo(0, 0);
    this.ptBR = this.globalService.obterDataBR();
  }

  protected acaoRemover(model: Empenho): Observable<Empenho> {
    return null;
  }

  public obterParametros() {
    const parametros = {};
    this.datepipe = new DatePipe('pt');
    parametros['ignoreCondObrig'] = true;
    parametros['data_empenho$ge'] = this.datepipe.transform(this.data1, 'yyyy-MM-dd');
    parametros['data_empenho$le'] = this.datepipe.transform(this.data2, 'yyyy-MM-dd');
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['relations'] = 'favorecido,subelemento,compra,ficha,ficha.acao';
    if (this.filtro) {
      parametros['OR'] =
        // tslint:disable-next-line: max-line-length
        `favorecido.nome$like=${this.filtro}%;!;!;numero=${this.filtro};!;!;subelemento.nome$like=${this.filtro}%;!;!;subelemento.codigo$like=${this.filtro}%;!;!;valor_empenho=${this.filtro}`;
    }

    if (this.especie === 'repasses') {
      parametros['ficha.despesa.codigo$like'] = '335043';
      this.titulo = 'REPASSES TERCEIRO SETOR';
    } else if (this.especie === 'viagens') {
      parametros['ficha.despesa.codigo$like'] = '339033%';
      this.titulo = 'DESPESAS DE VIAGENS';
    } else if (this.especie === 'equipamentos') {
      parametros['ficha.despesa.codigo$like'] = '449052%';
      this.titulo = 'EQUIPAMENTOS E MATERIAL PERMANENTE';
    } else if (this.especie === 'covid') {
      parametros['ficha.aplicacao.codigo'] = '312';
      this.titulo = 'DESPESAS REALIZADAS COVID-19';
    }

    switch (this.col) {
      case 1:
        parametros['orderBy'] = `numero$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 2:
        parametros['orderBy'] = `data_empenho$${this.ascendente ? 'ASC' : 'DESC'},numero`;
        break;
      case 3:
        parametros['orderBy'] = `favorecido.nome$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 4:
        parametros['orderBy'] = `subelemento.nome$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 5:
        parametros['orderBy'] = `valor_empenho$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      default:
        break;
    }
    return parametros;
  }

  public preencherGrid() {
    const parametros = this.obterParametros();
    this.empenhoService
      .filtrar(
        this.paginaCorrente,
        this.login.limite,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.lista = lista.content;
          this.paginaTotal = lista.totalPages;
        },
        () => alert('erro ao retornar lista')
      );
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Data do empenho:', coluna: 'data_empenho', agrupar: true, bold: true },
      { titulo: 'Número', coluna: 'numero' },
      { titulo: 'Beneficiario', coluna: 'favorecido.nome' },
      { titulo: 'Despesa', coluna: 'subelemento.nome' },
      { titulo: 'Historico', coluna: 'historico' },
      { titulo: 'Vencimento', coluna: 'data_vencimento' },
      { titulo: 'Valor', coluna: 'valor_empenho', alignment: 'right', decimais: 2 }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    this.empenhoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE EMPENHOS ORÇAMENTÁRIOS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem empenhos', ['auto', 'auto', 'auto', '*', 'auto', 'auto'], lista.content, ['valor_empenho']);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public editar(id: number) {
    const dt1 = this.datepipe.transform(this.data1, 'yyyy-MM-dd', 'GMT');
    const dt2 = this.datepipe.transform(this.data2, 'yyyy-MM-dd', 'GMT');
    if (!this.filtro || this.filtro === '') {
      this.router.navigate([
        `/empenhos-orcamentarios/visualiza`, id, this.especie, this.paginaCorrente, dt1, dt2]);
    } else {
      this.router.navigate([
        `/empenhos-orcamentarios/visualiza`, id, this.especie, this.paginaCorrente, dt1, dt2, this.filtro]);
    }
  }

  public receitaCovid() {
    const dt1 = this.datepipe.transform(this.data1, 'yyyy-MM-dd', 'GMT');
    const dt2 = this.datepipe.transform(this.data2, 'yyyy-MM-dd', 'GMT');
    this.router.navigate([`/receitas-orcamentarias/pesquisa`, 'covid', 1, dt1, dt2]);
  }

  public onSelectPeriodo() {
    this.preencherGrid();
  }

}
