import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, Exercicio } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class QuadroEnsinoService extends BaseResourceService<Exercicio> {

  constructor(
    protected injector: Injector
  ) {
    super(`contabil-relatorio`, injector);
  }

  obterQuadro1(mes: number, exercicio: number, orgaos: number[], dataInicial: Date, dataFinal: Date): Observable<[]> {
    let query = '';
    if (dataInicial) {
      query = `?dataInicial=${dataInicial}`;
      if (dataFinal) query += `&dataFinal=${dataFinal}`;
    }
    return this.http.get<[]>(`${this.login.cidade.id}/${this.api}/quadro1-educacao/${mes}/${exercicio}/${orgaos.join()}${query}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterQuadro2(mes: number, exercicio: number, orgaos: number[], dataInicial: Date, dataFinal: Date): Observable<any> {
    let query = '';
    if (dataInicial) {
      query = `?dataInicial=${dataInicial}`;
      if (dataFinal) query += `&dataFinal=${dataFinal}`;
    }
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/quadro2-educacao/${mes}/${exercicio}/${orgaos.join()}${query}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterQuadro3(mes: number, exercicio: number, orgaos: number[], dataInicial: Date, dataFinal: Date): Observable<any> {
    let query = '';
    if (dataInicial) {
      query = `?dataInicial=${dataInicial}`;
      if (dataFinal) query += `&dataFinal=${dataFinal}`;
    }
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/quadro3-educacao/${mes}/${exercicio}/${orgaos.join()}${query}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterQuadro4(mes: number, exercicio: number, orgaos: number[], dataInicial: Date, dataFinal: Date): Observable<any> {
    let query = '';
    if (dataInicial) {
      query = `?dataInicial=${dataInicial}`;
      if (dataFinal) query += `&dataFinal=${dataFinal}`;
    }
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/quadro4-educacao/${mes}/${exercicio}/${orgaos.join()}${query}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterQuadro5(mes: number, exercicio: number, orgaos: number[], dataInicial: Date, dataFinal: Date): Observable<any> {
    let query = '';
    if (dataInicial) {
      query = `?dataInicial=${dataInicial}`;
      if (dataFinal) query += `&dataFinal=${dataFinal}`;
    }
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/quadro5-educacao/${mes}/${exercicio}/${orgaos.join()}${query}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterQuadro6(mes: number, exercicio: number, orgaos: number[], dataInicial: Date, dataFinal: Date): Observable<any> {
    let query = '';
    if (dataInicial) {
      query = `?dataInicial=${dataInicial}`;
      if (dataFinal) query += `&dataFinal=${dataFinal}`;
    }
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/quadro6-educacao/${mes}/${exercicio}/${orgaos.join()}${query}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo8(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo8-educacao/${mes}/${exercicio}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterProjecaoReceitaDespesa(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/projecao-receita-despesa-educacao/${mes}/${exercicio}/${orgaos.join()}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  obterProjecaoQSE(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/projecao-qse/${mes}/${exercicio}/${orgaos.join()}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  obterQuadro8(mes: number, exercicio: number, orgaos: number[], dataInicial: Date, dataFinal: Date): Observable<any> {
    let query = '';
    if (dataInicial) {
      query = `?dataInicial=${dataInicial}`;
      if (dataFinal) query += `&dataFinal=${dataFinal}`;
    }
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/quadro8-educacao/${mes}/${exercicio}/${orgaos.join()}${query}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
