import { BaseResourceModel } from '../../models/base-resource.model';

export class EventoContabilEspecie extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public especie?: string,
    public ativo?: boolean,
  ) {
    super();
  }

  static converteJson(json: any): EventoContabilEspecie {
    return Object.assign(new EventoContabilEspecie(), json);
  }

}
