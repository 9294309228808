import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, TipoContratacaoCondicaoDespesa } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class TipoContratacaoCondicaoDespesaService extends BaseResourceService<TipoContratacaoCondicaoDespesa> {

  constructor(
    protected injector: Injector
  ) {
    super(`tipo-contratacao-condicaos-despesas`, injector);
  }

}
