import { InventarioService } from '../service/inventario.service';
import { Directive, ElementRef, OnInit } from '@angular/core';
import { GlobalService, LoginContabil } from 'eddydata-lib';
import * as toastr from 'toastr'

@Directive({
  selector: '[mensagemInventarioPatrimonio]'
})
export class MensagemInventarioPatrimonioDirective implements OnInit {

  private login: LoginContabil;

  constructor(private inventarioService: InventarioService,
    private el: ElementRef) { }

  async ngOnInit(): Promise<void> {
    this.login = GlobalService.obterSessaoLogin();
    const inventario = this.login.orgao.id && this.login.usuario?.setor?.id ? await this.inventarioService.validarMovimentacao(this.login.orgao.id, this.login.usuario.setor.id).toPromise() : 0;

    if (+inventario > 0) {
      toastr.warning('Inventário em andamento. Movimentação patrimonial bloqueada')
    }
  }

}