import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseResourceRptComponent, Coluna, DateFormatPipe, EddyAutoComplete, FuncaoService, Inventario, InventarioSetor, ParametroPatrimonioService, ProgressoService } from 'eddydata-lib';
import { takeUntil } from 'rxjs/operators';
import { InventarioSetorService } from '../../inventario/service/inventario-setor.service';
import { InventarioService } from '../../inventario/service/inventario.service';

@Component({
  selector: 'app-inventario-secretaria-rpt',
  templateUrl: './inventario-secretaria-rpt.component.html'
})
export class InventarioSecretariaRptComponent extends BaseResourceRptComponent {

  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public parametros: { session?: any, filtros?: any } = {};
  public inventarioSetor: InventarioSetor;
  public listarSetores: boolean = false;
  public retorno: Coluna[] = [];
  public inventario: Inventario;
  public listaInventario: Inventario[] = [];

  public inventarioSetorAutoComplete: EddyAutoComplete<InventarioSetor>

  // ========================================================================
  // ------------------------------ CONSTRUTOR ------------------------------
  // ========================================================================
  constructor(
    protected funcaoService: FuncaoService,
    protected progressoService: ProgressoService,
    protected inventarioService: InventarioService,
    private parametroPatrimonioService: ParametroPatrimonioService,
    private inventarioSetorService: InventarioSetorService) {
    super();
  }

  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================

  protected afterInit(): void {
    this.carregarAutoCompletes();

    this.inventarioService.filtrar(0, 0, {
      orgao_id: this.login.orgao.id,
      orderBy: 'descricao'
    }).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      return this.listaInventario = res.content;
    });
  }

  protected tituloRelatorio(): string {
    return `${this.login.orgao.nome}`;
  }

  protected subTituloRelatorio(): string {
    return `Estatística por secretaria - ${this.inventario.descricao}`;
  }

  private carregarAutoCompletes() { }

  protected obterColunasRelatorio(): Coluna[] {
    this.retorno = [];

    if(!this.listarSetores){
    this.retorno.push({ titulo: 'CÓDIGO', coluna: 'codigo', fontSize: 10, alignment: 'left' });
    this.retorno.push({ titulo: 'SECRETARIA', coluna: 'nome', fontSize: 10, alignment: 'left' });
    this.retorno.push({ titulo: 'REPONSÁVEL', coluna: 'responsavel', fontSize: 10, alignment: 'center' });
    this.retorno.push({ titulo: 'NÃO INICIADOS', coluna: 'pendentes', fontSize: 10, alignment: 'center' });
    this.retorno.push({ titulo: 'INICIADOS', coluna: 'iniciados', fontSize: 10, alignment: 'center' });
    this.retorno.push({ titulo: 'CONCLUÍDOS', coluna: 'concluidos', fontSize: 10, alignment: 'center' });
    }else{
      this.retorno.push({ titulo: '', coluna: 'cabecalho_grupo', fontSize: 10, alignment: 'left',agrupar: true,bold: true,quebrarPaginaAgrupamento: true });
      this.retorno.push({ titulo: 'CÓDIGO SETOR', coluna: 'codigo_setor', fontSize: 10, alignment: 'center' });
      this.retorno.push({ titulo: 'NOME SETOR', coluna: 'nome_setor', fontSize: 10, alignment: 'center' });
      this.retorno.push({ titulo: 'STATUS', coluna: 'status', fontSize: 10, alignment: 'center' });
    }
    

    return this.retorno;
  }

  protected larguraColunas(): (string | number)[] {
    let qtdeColunas;
    if(!this.listarSetores){
     qtdeColunas = [40, '*', 150, 50,50,50];
    }else{
     qtdeColunas = [40,'*',60];
    }

    return qtdeColunas;
  }

  protected totalizarColunas(): (string | {})[] {
    if(!this.listarSetores){
      return ['pendentes', 'iniciados','concluidos']
    }else{
      return null;
    }
  }

  protected carregarLista(): Promise<any[]> {
    if(!this.listarSetores){
      return this.inventarioService.obterEstatisticasSecretaria(this.inventario.id).pipe(takeUntil(this.unsubscribe)).toPromise();
    }else{ 
      return this.inventarioService.obterEstatisticasSecretariaSetores(this.inventario.id).pipe(takeUntil(this.unsubscribe)).toPromise();
    }
  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================


  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

}
