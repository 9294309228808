import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { RemuneracaoLstComponent } from './remuneracao-lst/remuneracao-lst.component';
import { RemuneracaoShwComponent } from './remuneracao-shw/remuneracao-shw.component';

const routes: Routes = [
  {
    path: 'pesquisa/:pagina/:mes/:ano/:referencia',
    component: RemuneracaoLstComponent, canActivate: [AuthGuard]
  },
  {
    path: 'pesquisa/:pagina/:mes/:ano/:referencia/:filtro',
    component: RemuneracaoLstComponent, canActivate: [AuthGuard]
  },
  {
    path: 'visualiza/:id/:pagina/:mes/:ano/:referencia',
    component: RemuneracaoShwComponent, canActivate: [AuthGuard]
  },
  {
    path: 'visualiza/:id/:pagina/:mes/:ano/:referencia/:filtro',
    component: RemuneracaoShwComponent, canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RemuneracaoRoutingModule { }

