import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SetorLocalizacao } from '../../../entidade/almoxarifado/setor-localizacao.model';
import { BaseResourceService } from '../../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class SetorLocalizacaoService extends BaseResourceService<SetorLocalizacao> {

  constructor(
    protected injector: Injector
  ) {
    super(`setor-localizacoes`, injector);
  }

  public obterPorSetor(id: number): Observable<SetorLocalizacao[]> {
    return this.http.get<SetorLocalizacao>(
      `${this.login.cidade.id}/${this.api}/filtrar?setor.id=${id}&relations=setor`,
      this.httpOptions()).pipe(
        map(res => res['content']),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorLocalizacao(setor: number): Observable<SetorLocalizacao[]> {
    return this.http.get<SetorLocalizacao>(
      `${this.login.cidade.id}/${this.api}/filtrar?setor.id=${setor}`,
      this.httpOptions()).pipe(
        map(res => res['content']),
        catchError(err => this.handleError(err))
      );
  }

}
