// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';

export class IndiceCorrecao extends BaseResourceModel {
    constructor(
        public id?: number,
        public mes?: number,
        public orgao?: Orgao,
        public exercicio?: Exercicio
    ) {
        super();
    }
    static converteJson(json: any): IndiceCorrecao {
        return Object.assign(new IndiceCorrecao(), json);
    }
}
