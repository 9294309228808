import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { ChartModule } from 'primeng/chart';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { SharedModule } from 'eddydata-lib';
import { ReceitaPrevistaLstComponent } from './receita-prevista-lst/receita-prevista-lst.component';
import { ReceitaPrevistaRoutingModule } from './receita-prevista-routing.module';
import { TooltipModule } from 'primeng/tooltip';


@NgModule({
  declarations: [
    ReceitaPrevistaLstComponent
  ],
  imports: [
    CommonModule,
    ReceitaPrevistaRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
    ChartModule,
    IMaskModule,
    ToastModule,
    TooltipModule
  ],
  exports: [
    ReceitaPrevistaLstComponent
  ]
})
export class ReceitaPrevistaModule { }
