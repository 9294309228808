import { BaseResourceModel } from '../../models/base-resource.model';
import { FaturaIrAliquota } from './fatura-ir-aliquota.model';
import { FaturaTipoImovel } from './fatura-tipo-imovel.model';

export class FaturaTipo extends BaseResourceModel {
  constructor(
    public nome?: string,
    public ativo?: boolean,
    public info1?: string,
    public info2?: string,
    public info3?: string,
    public dias_para_vencer?: number,
    public taxa?: boolean,
    public juros_multa?: boolean,
    public att_monetaria?: boolean,
    public retencao_ir?: boolean,
    public fatura_tipo_imovel?: FaturaTipoImovel[],
    public aliquotas_ir?: FaturaIrAliquota[]
  ) {
    super();
  }

  static converteJson(json: any): FaturaTipo {
    return Object.assign(new FaturaTipo(), json);
  }
}
