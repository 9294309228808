import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AcaoRoutingModule } from './acao-routing.module';
import { AcaoDlgComponent } from './acao-dlg/acao-dlg.component';
import { AcaoFormComponent } from './acao-form/acao-form.component';
import { AcaoListComponent } from './acao-list/acao-list.component';
import { SharedModule } from 'eddydata-lib';
import { ToastModule } from 'primeng/toast';

@NgModule({
  declarations: [AcaoListComponent, AcaoFormComponent, AcaoDlgComponent],
  imports: [
    CommonModule,
    FormsModule,
    AcaoRoutingModule,
    SharedModule,
    ToastModule
  ],
  exports: [
    AcaoDlgComponent
  ]
})
export class AcaoModule { }
