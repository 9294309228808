// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { ContaBancariaRecurso } from './conta-bancaria-recurso.model';
import { ContaBancaria } from './conta-bancaria.model';
import { OrdemPagamentoFatura } from './ordem-pagamento-fatura.model';
import { OrdemPagamentoItem } from './ordem-pagamento-item.model';
import { TransferenciaRecurso } from './transferencia-recurso.model';

export class OrdemPagamento extends BaseResourceModel {
  constructor(
    public id?: number,
    public mes?: number,
    public aux?: number,
    public numero?: number,
    public data_op?: Date,
    public baixado?: boolean,
    public unico_fornecedor?: boolean,
    public anulado?: boolean,
    public fatura?: boolean,
    public exportado_febraban?: boolean,
    public exportado_obn?: boolean,
    public exportado_febraban_fatura?: boolean,
    public numero_febraban?: number,
    public pagamento_retencao?: boolean,
    public conta?: ContaBancaria,
    public conta_recurso?: ContaBancariaRecurso,
    public exercicio?: Exercicio,
    public orgao?: Orgao,
    public faturas?: OrdemPagamentoFatura[],
    public usuario_cadastro?: Usuario,
    public itens?: OrdemPagamentoItem[],
    public transferencias?: TransferenciaRecurso[],
  ) {
    super();
  }

  static converteJson(json: any): OrdemPagamento {
    return Object.assign(new OrdemPagamento(), json);
  }
}
