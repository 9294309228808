import { BaseResourceModel } from "../../models/base-resource.model";
import { Exercicio } from "../comum/exercicio.model";
import { Orgao } from "../comum/orgao.model";


export class ParecerControleInterno extends BaseResourceModel {
  constructor(
    public id?: number,
    public numero_comunicado?: string,
    public lei?: string,
    public exercicio?: Exercicio,
    public orgao?: Orgao
  ) {
    super();
  }
  
  static converteJson(json: any): ParecerControleInterno {
    return Object.assign(new ParecerControleInterno(), json);
  }
}