import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { ConsultaEmpenhoViewComponent } from './consulta-empenho-view/consulta-empenho-view.component';

const routes: Routes = [
  { 
    path: ':id/pesquisar/:orgao',
    children: [
      { path: '', component: ConsultaEmpenhoViewComponent, canActivate: [AuthGuard] },
      { path: ':data', component: ConsultaEmpenhoViewComponent, canActivate: [AuthGuard] }
    ],
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConsultaEmpenhoRoutingModule { }
