// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { FichaExtra } from './ficha-extra.model';
import { EmpenhoExtra } from './empenho-extra.model';
import { PagamentoExtra } from 'eddydata-lib';

export class RetencaoExtra extends BaseResourceModel {
  constructor(
    public id?: number,
    public aux?: number,
    public data_vencimento?: Date,
    public cei?: string,
    public gps?: string,
    public valor_retido?: number,
    public ficha?: FichaExtra,
    public empenho_extra?: EmpenhoExtra,
    public anulado?: boolean,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public editavel?: boolean,
    public base_IR?: number,
    public anulacoes?: RetencaoExtra[],
    public valor_IR?: number,
    public base_agregado?: number,
    public valor_agregado?: number,
    public base_csll?: number,
    public pagamento_anulado?: PagamentoExtra,
    public valor_csll?: number,
    public base_cofins?: number,
    public valor_cofins?: number,
    public base_pp?: number,
    public valor_pp?: number,
  ) {
    super();
  }

  static converteJson(json: any): RetencaoExtra {
    return Object.assign(new RetencaoExtra(), json);
  }
}
