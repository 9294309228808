import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './navbar-transparencia/core.module';
import {
  SharedModule, AuthGuard, AuthGuardFake,
  LoaderInterceptorService, NofificationService
} from 'eddydata-lib';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FieldsetModule } from 'primeng/fieldset';
import { ListboxModule } from 'primeng/listbox';
import { IMaskModule } from 'angular-imask';
import { TransparenciaParametroComponent } from './transparencia-parametro/transparencia-parametro.component';
import { TransparenciaDecretoComponent } from './transparencia-decreto/transparencia-decreto.component';
import { TransparenciaLoaComponent } from './transparencia-loa/transparencia-loa.component';
import { TransparenciaReceitaPrevistaComponent } from './transparencia-receita-prevista/transparencia-receita-prevista.component';
import { TransparenciaDespesaPrevistaComponent } from './transparencia-despesa-prevista/transparencia-despesa-prevista.component';
import { TransparenciaContaComponent } from './transparencia-conta/transparencia-conta.component';
import { TransparenciaExecucaoBalanceteComponent } from './transparencia-execucao-balancete/transparencia-execucao-balancete.component';
import { TransparenciaExecucaoLRFComponent } from './transparencia-execucao-lrf/transparencia-execucao-lrf.component';
import { TransparenciaRreoComponent } from './transparencia-rreo/transparencia-rreo.component';
import { TransparenciaPesquisaComponent } from './transparencia-pesquisa/transparencia-pesquisa.component';
import { TransparenciaLoginModule } from './transparencia-login/transparencia-login.module';
import { TransparenciaRegistroModule } from './transparencia-registro/transparencia-registro.module';
import { TransparenciaContaArquivoComponent } from './transparencia-conta-arquivo/transparencia-conta-arquivo.component';
import { TransparenciaContaUploadComponent } from './transparencia-conta-upload/transparencia-conta-upload.component';
import { TransparenciaArquivoComponent } from './transparencia-arquivo/transparencia-arquivo.component';
import { FileUploadModule } from 'primeng/fileupload';
import { TransparenciaArquivoViewComponent } from './transparencia-arquivo-view/transparencia-arquivo-view.component';
import { TransparenciaTrocarSenhaComponent } from './transparencia-trocar-senha/transparencia-trocar-senha.component';
import { TransparenciaLDOComponent } from './transparencia-ldo/transparencia-ldo.component';
import { TransparenciaAnexoLoaComponent } from './transparencia-anexo-loa/transparencia-anexo-loa.component';
import { LGPDComponent } from './lgpd/lgpd.component';
import { TransparenciaExecucaoBalanceteAnualComponent } from './transparencia-execucao-balacente-anual/transparencia-execucao-balancete-anual.component';
import { RedeSocialInfoComponent } from './rede-social-info-view/rede-social-info.component';
import { TransparenciaQuadroEnsinoComponent } from './transparencia-quadro-ensino/transparencia-quadro-ensino.component';
import { TransparenciaQuadroSaudeComponent } from './transparencia-quadro-saude/transparencia-quadro-saude.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';

@NgModule({
  declarations: [
    AppComponent,
    TransparenciaParametroComponent,
    TransparenciaDecretoComponent,
    TransparenciaLoaComponent,
    TransparenciaReceitaPrevistaComponent,
    TransparenciaDespesaPrevistaComponent,
    TransparenciaContaComponent,
    TransparenciaContaArquivoComponent,
    TransparenciaContaUploadComponent,
    TransparenciaArquivoComponent,
    TransparenciaArquivoViewComponent,
    TransparenciaExecucaoBalanceteComponent,
    TransparenciaExecucaoBalanceteAnualComponent,
    TransparenciaExecucaoLRFComponent,
    TransparenciaRreoComponent,
    TransparenciaLDOComponent,
    TransparenciaAnexoLoaComponent,
    TransparenciaPesquisaComponent,
    TransparenciaTrocarSenhaComponent,
    TransparenciaQuadroEnsinoComponent,
    TransparenciaQuadroSaudeComponent,
    RedeSocialInfoComponent,
    LGPDComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    ToastModule,
    RadioButtonModule,
    ConfirmDialogModule,
    CalendarModule,
    ReactiveFormsModule,
    FormsModule,
    IMaskModule,
    TransparenciaLoginModule,
    TransparenciaRegistroModule,
    FileUploadModule,
    FieldsetModule,
    ListboxModule,
    AutoCompleteModule  
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt' },
  { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthGuard, AuthGuardFake, {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptorService,
    multi: true
  }, NofificationService, ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
