import { BaseResourceModel } from '../../models/base-resource.model';

export class Estado extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public uf?: string
  ) {
    super();
  }

  static converteJson(json: any): Estado {
    return Object.assign(new Estado(), json);
  }
}
