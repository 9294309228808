import { Injectable, Injector } from "@angular/core";
import { BaseResourceService, RplItemFichaDespesa } from "eddydata-lib";
import { Observable } from "rxjs";
import { catchError, map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class RplItemFichaDespesaService extends BaseResourceService<RplItemFichaDespesa> {

    constructor(
        protected injector: Injector
    ) {
        super(`rpl-item-ficha-despesa`, injector);
    }

    public atualizarFichaDespesa(rpl_numero: number, orgao_id: number, exercicio: number, ficha_despesa_id: number, ficha_despesa_antiga_id: number): Observable<RplItemFichaDespesa> {
        return this.http.get<any[]>(
            `${this.login.cidade.id}/${this.api}/atualizar-ficha-despesa/${orgao_id}/${exercicio}/${rpl_numero}/${ficha_despesa_id}/${ficha_despesa_antiga_id}`, this.httpOptions()).pipe(
                map(res => res),
                catchError(err => this.handleError(err))
            );
    }

    public gerarPendenciaReserva(lista: [], orgao_id: number, exercicio_id: number, licitacao_id: number,): Observable<[]> {
        return this.http.post(
            `${this.login.cidade.id}/${this.api}/gerar-pendencia-reserva/${orgao_id}/${exercicio_id}/${licitacao_id}`, lista, this.httpOptions()).pipe(
                map(res => res),
                catchError(err => this.handleError(err))
            );
    }
}