// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { AcaoGoverno } from './acao-governo.model';
import { MetaGoverno } from './meta-governo.model';

export class Indicador extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public nome?: string,
    public justificativa1?: string,
    public justificativa2?: string,
    public justificativa3?: string,
    public justificativa4?: string,
    public unidade_medida?: string,
    public recente?: number,
    public futuro?: number,
    public evolucao1?: number,
    public evolucao2?: number,
    public evolucao3?: number,
    public evolucao4?: number,
    public meta1?: number,
    public meta2?: number,
    public meta3?: number,
    public meta4?: number,
    public meta_realizada1?: number,
    public meta_realizada2?: number,
    public meta_realizada3?: number,
    public meta_realizada4?: number,
    public meta?: MetaGoverno,
    public acao_governo?: AcaoGoverno,
    public editavel?: boolean,
    public lei?: string,
    public quadri?: number,
    public enviado_tce?: boolean,
  ) {
    super();
  }

  static converteJson(json: any): Indicador {
    return Object.assign(new Indicador(), json);
  }
}
