import { Injectable, Injector } from "@angular/core";
import { BaseResourceService, ReservaDotacaoEstorno } from "eddydata-lib";

@Injectable({
  providedIn: 'root'
})
export class ReservaDotacaoEstornoService extends BaseResourceService<ReservaDotacaoEstorno> {

  constructor(
    protected injector: Injector
  ) {
    super(`reserva-dotacao-estornos`, injector);
  }
}