import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProgramaListComponent } from './programa-list/programa-list.component';
import { ProgramaFormComponent } from './programa-form/programa-form.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: ProgramaListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: ProgramaFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: ProgramaFormComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProgramaRoutingModule { }
