import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { FieldsetModule } from 'primeng/fieldset';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TabViewModule } from 'primeng/tabview';
import { MultiSelectModule } from 'primeng/multiselect';
import { SharedModule } from '../util/shared.module';
import { UsuarioAcessoRptDlgComponent } from './usuario-acesso-rpt-dlg/usuario-acesso-rpt-dlg.component';
import { UsuarioAcessoComponent } from './usuario-acesso/usuario-acesso.component';
import { UsuarioDlgComponent } from './usuario-dlg/usuario-dlg.component';
import { UsuarioFormComponent } from './usuario-form/usuario-form.component';
import { UsuarioListSimplesComponent } from './usuario-list-simples/usuario-list-simples.component';
import { UsuarioListComponent } from './usuario-list/usuario-list.component';
import { UsuarioRegistroComponent } from './usuario-registro/usuario-registro.component';
import { UsuarioRoutingModule } from './usuario-routing.module';
import { UsuarioViewComponent } from './usuario-view/usuario-view.component';

@NgModule({
  declarations: [
    UsuarioListComponent,
    UsuarioFormComponent,
    UsuarioViewComponent,
    UsuarioAcessoComponent,
    UsuarioRegistroComponent,
    UsuarioDlgComponent,
    UsuarioListSimplesComponent,
    UsuarioAcessoRptDlgComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    UsuarioRoutingModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    TabViewModule,
    SelectButtonModule,
    RadioButtonModule,
    FieldsetModule,
    MultiSelectModule
  ],
  exports: [
    UsuarioListComponent,
    UsuarioFormComponent,
    UsuarioRegistroComponent,
    UsuarioDlgComponent,
    UsuarioListSimplesComponent
  ],
  providers: [MessageService]
})
export class UsuarioModule { }
