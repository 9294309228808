// tslint:disable: variable-name
import { Ppa } from './ppa.model';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class Ldo extends BaseResourceModel {
  constructor(
    public id?: number,
    public ano?: number,
    public linha?: number,
    public tipo?: string,
    public descricao1?: string,
    public descricao2?: string,
    public descricao3?: string,
    public descricao4?: string,
    public somador?: boolean,
    public titulo?: boolean,
    public valor1?: number,
    public valor2?: number,
    public valor3?: number,
    public valor4?: number,
    public valor5?: number,
    public valor6?: number,
    public valor7?: number,
    public valor8?: number,
    public valor9?: number,
    public valor10?: number,
    public valor11?: number,
    public valor12?: number,
    public editavel?: boolean,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public orgao?: Orgao,
    public ppa?: Ppa
  ) {
    super();
  }

  static converteJson(json: any): Ldo {
    return Object.assign(new Ldo(), json);
  }
}
