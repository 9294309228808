import { BaseResourceModel } from "../../models/base-resource.model";
import { Exercicio } from "../comum/exercicio.model";

export class SubfuncaoSiope extends BaseResourceModel {
    constructor(
        public id?: number,
        public selecionavel?: boolean,
        public texto?: string,
        public valor?: string,
        public cod_planilha?: string,
        public parente?: SubfuncaoSiope,
        public exercicio?: Exercicio
    ) {
    super();
  }
  
  static converteJson(json: any): SubfuncaoSiope {
    return Object.assign(new SubfuncaoSiope(), json);
  }
}