import { Banco } from '../banco';

export class BancoBrasil extends Banco {
    public getNumero(): string {
        return '001';
    }

    private getCampoLivre(): string {
        if (this.boleto.getNumConvenio() === null || this.boleto.getNumConvenio().trim() === '') {
            throw new Error('Boleto sem número de convênio.');
        }

        if (this.boleto.getCarteira() === null || this.boleto.getCarteira().trim() === '') {
            throw new Error('Boleto sem número da carteira.');
        }

        const campo = '000000' + this.boleto.getNumConvenio() + this.boleto.getNossoNumero() + this.boleto.getCarteira();

        return campo;
    }

    private getCampo1(): string {

        const campo = this.getNumero() + this.boleto.getMoeda() + this.getCampoLivre().substring(0, 5);
        return this.boleto.getDigitoCampo(campo, 2);

    }

    private getCampo2(): string {
        const campo = this.getCampoLivre().substring(5, 15);// + boleto.getAgencia();

        return this.boleto.getDigitoCampo(campo, 1);
    }

    private getCampo3(): string {
        const campo = this.getCampoLivre().substring(15);

        return this.boleto.getDigitoCampo(campo, 1);
    }

    private getCampo4(): string {
        const campo = this.getNumero() + this.boleto.getMoeda().toString() +
            this.boleto.getFatorVencimento() + this.boleto.getValorTitulo() + this.getCampoLivre();

        return this.boleto.getDigitoCodigoBarras(campo);
    }

    private getCampo5(): string {
        const campo = this.boleto.getFatorVencimento() + this.boleto.getValorTitulo();
        return campo;
    }

    public getCodigoBarras(): string {

        let campo = this.getNumero() + this.boleto.getMoeda().toString() + this.getCampo4() +
            this.boleto.getFatorVencimento() + this.boleto.getValorTitulo() + this.getCampoLivre();
        return campo;
    }

    public getLinhaDigitavel(): string {

        return this.getCampo1().substring(0, 5) + '.' + this.getCampo1().substring(5) + '  ' +
            this.getCampo2().substring(0, 5) + '.' + this.getCampo2().substring(5) + '  ' +
            this.getCampo3().substring(0, 5) + '.' + this.getCampo3().substring(5) + '  ' +
            this.getCampo4() + '  ' + this.getCampo5();
    }

    public getCarteiraFormatted(): string {
        return this.boleto.getCarteira();
    }

    public getAgenciaCodCedenteFormatted(): string {
        return this.boleto.getAgencia() + '-' + this.boleto.getDvAgencia() + ' / ' + this.boleto.getContaCorrente() + '-' + this.boleto.getDvContaCorrente();
    }

    public getNossoNumeroFormatted(): string {
        return this.boleto.getNumConvenio() + this.boleto.getNossoNumero();
    }
} 