import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { EmpenhoService, PagamentoService } from 'administrativo-lib';
import * as toastr from 'toastr';
import {
  BaseResourceListComponent, Coluna, DateFormatPipe, Empenho, Filtro, FormatoExportacao, GlobalService, LoginContabil, OrgaoAssinaturaService, Pagamento, ProgressoService
} from 'eddydata-lib';
import { ReinfRegistroService } from 'projects/contabil/src/app/efd-reinf/service/reinf-registro.service';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotaPagamento } from '../../relatorio-tesouraria/notas/nota-pagamento';
import { MessageService } from 'primeng/api';

declare var $: any;

@Component({
  selector: 'lib-pagamento-list',
  templateUrl: './pagamento-list.component.html'
})
export class PagamentoListComponent extends BaseResourceListComponent<Pagamento, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public especieSelect = 'EMO';
  public listaEspecies: Array<any>;
  public pagamentoAnular: Pagamento;
  public ptBR: any;
  public datepipe: DatePipe;
  public data1: Date;
  public data2: Date;
  valorAnular: number = 0;
  public pagamentoAtualizar: Pagamento
  public visualizarAtualizacao = false;
  public empenhosInscritos: Empenho[] = [];
  public pagamentosSelecionados: any[] = [];
  public pagamentosId: any[] = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private globalService: GlobalService,
    private messageService: MessageService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected progressoService: ProgressoService,
    protected registroService: ReinfRegistroService,
    private pagamentoService: PagamentoService,
    private empenhoService: EmpenhoService,
  ) {
    super(pagamentoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(item: Pagamento): boolean {
    return !item.anulacao && !item.anulado_total && this.login.sistema != 'controle-interno'
  }
  
  protected relations(): string {
    return 'liquidacao,liquidacao.empenho,liquidacao.empenho.favorecido,liquidacao.empenho.ficha,'
      + 'liquidacao.empenho.ficha.acao,liquidacao.empenho.ficha.recurso,liquidacao.empenho.ficha.aplicacao,liquidacao.empenho.ficha.aplicacao_variavel,exercicio,orgao,'
      + 'liquidacao.empenho.ficha.executora,conta,conta.banco,conta_recurso,conta_recurso.recurso,conta_recurso.aplicacao,conta_recurso.convenio,'
      + 'liquidacao.empenho.subelemento.vpd';
  }

  protected condicoesGrid(): {} {
    const parametros = {};
    this.datepipe = new DatePipe('pt');
    parametros['data_pagamento$ge'] = this.datepipe.transform(this.data1, 'yyyy-MM-dd');
    parametros['data_pagamento$le'] = this.datepipe.transform(this.data2, 'yyyy-MM-dd');
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['exercicio.id'] = this.login.exercicio.id;
    //parametros['anulado_total'] = false;

    sessionStorage.setItem('/pagamentos_data1', this.datepipe.transform(this.data1, 'yyyy-MM-dd'));
    sessionStorage.setItem('/pagamentos_data2', this.datepipe.transform(this.data2, 'yyyy-MM-dd'));

    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['data_pagamento$DESC', 'id$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      date: ['data_pagamento'],
      number: ['liquidacao.empenho.numero', 'valor_pago', 'valor_retido', 'conta.agencia', 'conta.numero_conta', 'liquidacao.empenho.favorecido.cpf_cnpj'],
      text: ['liquidacao.empenho.favorecido.nome']
    };
  }

  public beforeInit(): void {
    this.usarFiltroPersonalizado = true;
    this.usarExtendido = true;
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    const dt1: string = sessionStorage.getItem('/pagamentos_data1');
    const dt2: string = sessionStorage.getItem('/pagamentos_data2');
    if (!dt1) {
      this.data1 = new DateFormatPipe().transform(this.datepipe.transform(new Date(`01/01/${this.login.exercicio.ano}`), 'yyyy-MM-dd'), []);
    } else {
      this.data1 = new DateFormatPipe().transform(dt1, []);
    }
    if (!dt2) {
      const dt2 = new Date();
      dt2.setDate(dt2.getDate() + 10);
      this.data2 = new DateFormatPipe().transform(this.datepipe.transform(dt2, 'yyyy-MM-dd'), []);
    } else {
      this.data2 = new DateFormatPipe().transform(dt2, []);
    }
  }

  protected async afterInit(): Promise<void> {
    this.listaEspecies = [
      { id: 'EMO', nome: 'ORÇAMENTÁRIO' },
      { id: 'EME', nome: 'EXTRA-ORÇAMENTARIO' },
      { id: 'EMR', nome: 'RESTOS A PAGAR' }
    ];

    this.empenhosInscritos = (await this.empenhoService.filtrar(1, -1, {
      exercicio_id: this.login.exercicio.id,
      orgao_id: this.login.orgao.id,
      inscrito_resto: true
    }).toPromise()).content;
  }

  protected acaoRemover(model: Pagamento): Observable<Pagamento> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Data', coluna: 'data_pagamento', agrupar: true },
      { titulo: 'Conta', coluna: 'conta_bancaria' },
      { titulo: 'Documento', coluna: 'liquidacao.documento' },
      { titulo: 'Ficha', coluna: 'liquidacao.empenho.ficha.numero' },
      { titulo: 'Despesa', coluna: 'despesa' },
      { titulo: 'Emp.', coluna: 'empenho_parcela', alignment: 'center' },
      { titulo: 'Favorecido', coluna: 'liquidacao.empenho.favorecido.nome' },
      { titulo: 'Recurso', coluna: 'recurso_aplicacao_variavel' },
      {
        titulo: 'Valor Emp.', coluna: 'liquidacao.empenho.valor_empenho', decimais: 2, alignment: 'right', funcao: [{
          se: {
            condicao: 'anulacao=true',
            verdadeiro: [0],
            falso: ['liquidacao.empenho.valor_empenho']
          },
        }]
      },
      { titulo: 'Vl. Bruto', coluna: 'liquidacao.valor_liquidado', decimais: 2, alignment: 'right' },
      { titulo: 'Vl. Retido', coluna: 'valor_retido', decimais: 2, alignment: 'right' },
      { titulo: 'Vl. Líquido', coluna: 'valor_liquido', decimais: 2, alignment: 'right' },
      { titulo: 'Valor Pago', coluna: 'valor_pago', decimais: 2, alignment: 'right' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = this.relations();
    this.pagamentoService.filtrar(1, -1, parametros).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          let valor = lista.content.map((i) => {
            i.despesa = `${i.liquidacao.empenho.subelemento.codigo} ${i.liquidacao.empenho.subelemento.nome}`;
            i.conta_bancaria = `${i.conta.codigo} ${i.conta.nome}`;
            if (i.liquidacao.documento.split(' ')[0].length > 20) {
              i.liquidacao.documento = i.liquidacao.documento.replace(/\//g, ' / ');
            }
            if (i.liquidacao.parcela == null || i.liquidacao.parcela == undefined || i.liquidacao.parcela === 0) {
              return { ...i, valor_liquido: i.valor_retido == i.valor_pago ? i.liquidacao.empenho.valor_empenho : i.liquidacao.empenho.valor_empenho - i.valor_retido, empenho_parcela: i.liquidacao.empenho.numero, recurso_aplicacao_variavel: i.liquidacao.empenho.ficha?.aplicacao_variavel?.codigo ? i.liquidacao.empenho.ficha.aplicacao_variavel.codigo.toString() : i.liquidacao.empenho.ficha.recurso.codigo.toString() + i.liquidacao.empenho.ficha.aplicacao.codigo.toString() + "0000" };
            } else {
              return { ...i, valor_liquido: i.liquidacao.valor_liquidado - i.valor_retido, empenho_parcela: i.liquidacao.empenho.numero + " - " + i.liquidacao.parcela, recurso_aplicacao_variavel: i.liquidacao.empenho.ficha?.aplicacao_variavel?.codigo ? i.liquidacao.empenho.ficha.aplicacao_variavel.codigo.toString() : i.liquidacao.empenho.ficha.recurso.codigo.toString() + i.liquidacao.empenho.ficha.aplicacao.codigo.toString() + "0000" };
            }
          });
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE PAGAMENTOS ORÇAMENTÁRIOS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem pagamentos', ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], valor, ['valor_pago', 'valor_retido', 'valor_liquido']);
          } else {
            this.exportar(formato, valor);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  public obterColunasFiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Data pagamento', coluna: 'data_pagamento', tipo: 'Date', alignment: 'center', padrao: true, filtro2: new Date() });
    retorno.push({ titulo: 'Nº Empenho', coluna: 'liquidacao.empenho.numero', alignment: 'center', tipo: 'Number', padrao: true });
    retorno.push({ titulo: 'Parcela', coluna: 'liquidacao.parcela', alignment: 'center', tipo: 'Number', padrao: false });
    retorno.push({ titulo: 'Favorecido', coluna: 'liquidacao.empenho.favorecido.nome', tipo: 'String', padrao: true });
    retorno.push({ titulo: 'Histórico', coluna: 'historico', tipo: 'String' });
    retorno.push({ titulo: 'CPF/CNPJ', coluna: 'liquidacao.empenho.favorecido.cpf_cnpj', tipo: 'String', cols: 3, mask: [{ mask: '000.000.000-00' }, { mask: '00.000.000/0000-00' }] });
    retorno.push({ titulo: 'Documento', coluna: 'documento', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Código da conta', coluna: 'conta.codigo', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Nº da conta', coluna: 'conta.numero_conta', alignment: 'center', tipo: 'String', padrao: false, cols: 3 });
    retorno.push({ titulo: 'Recurso', coluna: 'conta_recurso.recurso.codigo', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Aplicação', coluna: 'conta_recurso.aplicacao.codigo', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Variável', coluna: 'conta_recurso.convenio.codigo', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Valor', coluna: 'valor_pago', alignment: 'center', tipo: 'Number', padrao: false });
    retorno.push({ titulo: 'Valor retido', coluna: 'valor_retido', alignment: 'center', tipo: 'Number', padrao: false });
    retorno.push({ titulo: 'Código Despesa', coluna: 'liquidacao.empenho.subelemento.codigo', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Modalidade', coluna: 'liquidacao.empenho.modalidade.nome', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Ficha', coluna: 'liquidacao.empenho.ficha.numero', alignment: 'center', tipo: 'Number', cols: 3 });

    return retorno;
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  onChangePagamento(value: any, especie: any) {
    if (especie === 'EME') {
      this.router.navigate(['pagamentos-extras']);
    } else if (especie === 'EMR') {
      this.router.navigate(['pagamentos-restos-pagar']);
    }
  }

  public anular(item: Pagamento) {
    this.pagamentoAnular = item;
    this.pagamentoService.obterTotalPagoPorLiquidacaoId(item.liquidacao.id).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      let pagoParcela = +res.total_pago;
      this.valorAnular = +pagoParcela.toFixed(2);
      $('#dialogAnular').modal('show');
    }, error => {
      toastr.warning('Falha ao carregar valor disponível para anulação.');
      this.valorAnular = 0;
      $('#dialogAnular').modal('show');
    });
  }

  public acertarRetencoes() {
    this.pagamentoService.acertarReceitaRetencoes(this.login.exercicio.id, this.login.orgao.id).pipe(takeUntil(this.unsubscribe)).subscribe(idTransacao => {
      this.progressoService.show(idTransacao);
    },
      error => this.acaoErro(error));
  }

  protected acaoErro(error: any) {
    console.log('Erro disparado: ', error);
    toastr.options.timeOut = 10000;
    toastr.options.closeButton = true;
    toastr.options.tapToDismiss = false;
    if (error.error && error.error.payload) {
      toastr.error(error.error.payload);
    } else {
      toastr.error('Ocorreu um erro ao processar a sua solicitação');
    }
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/tesouraria']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public editarAnulacao(item: Pagamento) {
    return item.anulacao && this.login.usuario.sistemas_administrador?.split(',').includes('contabil')
  }

  public alterarAnular(item: Pagamento) {
    this.pagamentoAtualizar = item;
    this.visualizarAtualizacao = true;
  }

  async mudarSituacao(item: Pagamento) {
    let registro = null;
    switch (item.situacao) {
      case 'N':
      case 'R':
        registro = await this.registroService.obter({ 'orgao.id': this.login.orgao.id, ambiente: 1, sucesso: true, ratificado: false, evento: 'R2010', id_alvo: item.id }).toPromise();
        if (registro) {
          item.situacao = 'E';
        } else {
          item.situacao = 'I';
        }
        break;
      case 'I':
        registro = await this.registroService.obter({ 'orgao.id': this.login.orgao.id, ambiente: 1, sucesso: true, ratificado: false, evento: 'R2010', id_alvo: item.id }).toPromise();
        item.situacao = registro ? 'R' : 'N';
        break;
      case 'E':
        item.situacao = 'R';
        break;
      default:
    }
    this.pagamentoService.atualizarSituacaoReinf(item).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      toastr.success(`Situação alterada com sucesso`);
      this.preencherGrid()
    });
  }

  public possuiInscricaoDeRestoNoExercicio(item: Pagamento) {
    if (this.empenhosInscritos.length > 0) {
      toastr.warning('Já foram inscrito os resto a pagar do exercício, não será possível alterar o registro!')
      this.router.navigate([`/pagamentos-orcamentarios/${item.id}/visualizar`]);
    }
  }

  public ImprimirNotaPorPagamentosSelecionados(item: Pagamento) {
    if (item['selecionado'] === true) {
      item['selecionado'] = false;
      // Encontre o índice do item na lista this.pagamentosSelecionados
      const index = this.pagamentosSelecionados.findIndex(e => e.id === item.id);
      if (index > -1) {
        this.pagamentosSelecionados.splice(index, 1);
      }
    } else {
      item['selecionado'] = true;
      // Verifique se o item já não está na lista
      if (!this.pagamentosSelecionados.some(e => e.id === item.id)) {
        this.pagamentosSelecionados.push(item);
      }
    }
    this.pagamentosId = [];
    for (const liquidacao of this.pagamentosSelecionados) {
      this.pagamentosId.push(liquidacao.id)
    }
  }

  public imprimirNota() {
    const parametros = {};
    let relations = 'liquidacao.empenho.ficha.executora.unidade,liquidacao.empenho.ficha.despesa,liquidacao.empenho.ficha.funcao,liquidacao.empenho.ficha.subfuncao,liquidacao.empenho.ficha.programa,liquidacao.empenho.ficha.acao,liquidacao.empenho.ficha.recurso,liquidacao.empenho.ficha.aplicacao,';
    relations += 'liquidacao.empenho.licitacao,liquidacao.empenho.modalidade,liquidacao.empenho.subelemento,liquidacao.empenho.favorecido.contas.banco,liquidacao.empenho.favorecido.tipo,liquidacao.empenho.contrato,liquidacao.empenho.convenio,liquidacao.empenho.liquidacoes,liquidacao.empenho.compra,liquidacao.empenho.compra.rcms,liquidacao.empenho.compra.rcms.requerente,liquidacao.empenho.compra.rcms.prazo';

    if (this.pagamentosId.length === 0 || !this.pagamentosId) {
      toastr.warning('Selecione ao menos um pagamento para imprimir')
      return;
    }

    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id$in'] = this.pagamentosId;
    this.pagamentoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        new NotaPagamento(this.assinaturaService).imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }
}
