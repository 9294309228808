import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { InputMaskModule } from 'primeng/inputmask';
import { ToastModule } from 'primeng/toast';
import { SharedModule } from '../util/shared.module';
import { PessoaCadastroDlgComponent } from './pessoa-cadastro-dlg/pessoa-cadastro-dlg.component';
import { PessoaFormComponent } from './pessoa-form/pessoa-form.component';
import { PessoaListComponent } from './pessoa-list/pessoa-list.component';
import { PessoaRoutingModule } from './pessoa-routing.module';
import { PessoaVisualizarDlgComponent } from './pessoa-visualizar-dlg/pessoa-visualizar-dlg.component';


@NgModule({
  declarations: [PessoaCadastroDlgComponent, PessoaListComponent, PessoaFormComponent, PessoaVisualizarDlgComponent],
  imports: [
    CommonModule,
    FormsModule,
    PessoaRoutingModule,
    SharedModule,
    AutoCompleteModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    ChartModule,
    InputMaskModule
  ],
  exports: [
    PessoaCadastroDlgComponent, PessoaVisualizarDlgComponent
  ],
  providers: [MessageService, ConfirmationService]
})
export class PessoaModule { }
