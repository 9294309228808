import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FuncaoService } from 'eddydata-lib';

@Directive()
export class Anexo8DespesaMdeFundeb implements OnDestroy {

  protected funcaoService: FuncaoService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number) {
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(dados: any[]): {}[] {

    const registros: {}[] = [
      [{
        text: 'DESPESAS COM MANUTENÇÃO E DESENVOLVIMENTO DO ENSINO - MDE - CUSTEADAS COM RECEITA DE IMPOSTOS E COM RECURSOS DO FUNDEB', colSpan: 6, fontSize: 8, bold: true,
        margin: [5, 5, 5, 5], alignment: 'center', border: [true, false, true, true]
      }, '', '', '', '', ''],
      [
        { text: 'DESPESAS COM AÇÕES TÍPICAS DE MDE - RECEITAS DE IMPOSTOS E RECURSOS DO FUNDEB (Por Área de Atuação)', alignment: 'center', bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: 'DOTAÇÃO ATUALIZADA (c)', alignment: 'center', bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: 'DESPESAS EMPENHADAS ATÉ O BIMESTRE (d)', alignment: 'center', bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: 'DESPESAS LIQUIDADAS ATÉ O BIMESTRE (e)', alignment: 'center', bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: 'DESPESAS PAGAS ATÉ O BIMESTRE (f)', alignment: 'center', bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: 'INSCRITAS EM RESTOS A PAGAR NÃO PROCESSADOS (g)', alignment: 'center', bold: true, fontSize: 7, border: [true, false, true, true] }
      ],
    ];

    const grupos2 = this.funcaoService.agrupar(dados, 'grupo', ['dotacao_atualizado', 'empenhado', 'liquidado', 'pago', 'resto']);

    for (const grupo of grupos2) {
      registros.push([
        { text: grupo.grupo, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_atualizado']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado'] - grupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 }
      ]);

      const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['dotacao_atualizado', 'empenhado', 'liquidado', 'pago', 'resto']);
      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo != '') {
          registros.push([
            { text: subgrupo.grupo, fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['dotacao_atualizado']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['pago']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado'] - subgrupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 }
          ]);

          if (subgrupo.grupo === '21.1- EDUCAÇÃO INFANTIL') {
            registros.push([
              { text: '21.1.1- Creche', fontSize: 7 },
              { text: '0,00', alignment: 'right', fontSize: 7 },
              { text: '0,00', alignment: 'right', fontSize: 7 },
              { text: '0,00', alignment: 'right', fontSize: 7 },
              { text: '0,00', alignment: 'right', fontSize: 7 },
              { text: '0,00', alignment: 'right', fontSize: 7 }
            ]);
            registros.push([
              { text: '21.1.2- Pré-escola', fontSize: 7 },
              { text: '0,00', alignment: 'right', fontSize: 7 },
              { text: '0,00', alignment: 'right', fontSize: 7 },
              { text: '0,00', alignment: 'right', fontSize: 7 },
              { text: '0,00', alignment: 'right', fontSize: 7 },
              { text: '0,00', alignment: 'right', fontSize: 7 }
            ]);
          }
        }
      }
    }

    return registros;

  }

  public montarQuadroCsv(dados: any[], listaExportar): any {

    const espaco = {
      titulo: '',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(espaco)

    const primeira_linha = {
      titulo: 'DESPESAS COM MANUTENÇÃO E DESENVOLVIMENTO DO ENSINO - MDE - CUSTEADAS COM RECEITA DE IMPOSTOS E COM RECURSOS DO FUNDEB',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(primeira_linha)

    const segunda_linha = {
      titulo: 'DESPESAS COM AÇÕES TÍPICAS DE MDE - RECEITAS DE IMPOSTOS - EXCETO FUNDEB (Por Subfunção)',
      coluna2: 'DOTAÇÃO ATUALIZADA (c)',
      coluna3: 'DESPESAS EMPENHADAS ATÉ O BIMESTRE (d)',
      coluna4: 'DESPESAS LIQUIDADAS ATÉ O BIMESTRE (e)',
      coluna5: 'DESPESAS PAGAS ATÉ O BIMESTRE (f)',
      coluna6: 'INSCRITAS EM RESTOS A PAGAR NÃO PROCESSADOS (g)',
      coluna7: ''
    }
    listaExportar.push(segunda_linha)

    const grupos2 = this.funcaoService.agrupar(dados, 'grupo', ['dotacao_atualizado', 'empenhado', 'liquidado', 'pago', 'resto']);

    for (const grupo of grupos2) {
      const linha_grupo = {
        titulo: grupo.grupo,
        coluna2: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_atualizado']),
        coluna3: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']),
        coluna4: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']),
        coluna5: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago']),
        coluna6: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado'] - grupo.totalizadores['liquidado']),
        coluna7: ''
      }
      listaExportar.push(linha_grupo)

      const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['dotacao_atualizado', 'empenhado', 'liquidado', 'pago', 'resto']);
      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo != '') {
          const linha_subgrupo = {
            titulo: subgrupo.grupo,
            coluna2: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['dotacao_atualizado']),
            coluna3: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado']),
            coluna4: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liquidado']),
            coluna5: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['pago']),
            coluna6: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado'] - subgrupo.totalizadores['liquidado']),
            coluna7: ''
          }
          listaExportar.push(linha_subgrupo)

          if (subgrupo.grupo === '21.1- EDUCAÇÃO INFANTIL') {
            const linha_creche = {
              titulo: '21.1.1- Creche',
              coluna2: '0,00',
              coluna3: '0,00',
              coluna4: '0,00',
              coluna5: '0,00',
              coluna6: '0,00',
              coluna7: '0,00'
            }
            listaExportar.push(linha_creche)

            const linha_pre_escola = {
              titulo: '21.1.2- Pré-escola',
              coluna2: '0,00',
              coluna3: '0,00',
              coluna4: '0,00',
              coluna5: '0,00',
              coluna6: '0,00',
              coluna7: '0,00'
            }
            listaExportar.push(linha_pre_escola)
          }
        }
      }
    }
  }
}