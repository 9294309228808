// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { CreditoItem } from './credito-item.model';
import { FichaReceita } from './ficha-receita.model';

export class ReceitaPrevista extends BaseResourceModel {
  constructor(
    public id?: number,
    public mes?: number,
    public referencia?: number,
    public evento?: string,
    public valor_previsto?: number,
    public aux?: number,
    public ficha?: FichaReceita,
    public credito_item?: CreditoItem,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public editavel?: boolean
  ) {
    super();
  }

  static converteJson(json: any): ReceitaPrevista {
    return Object.assign(new ReceitaPrevista(), json);
  }
}
