// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Ods } from './ods.model';

export class MetaOds extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: number,
    public descricao?: string,
    public ods?: Ods
  ) {
    super();
  }

  static converteJson(json: any): MetaOds {
    return Object.assign(new MetaOds(), json);
  }
}
