// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Exercicio } from '../comum/exercicio.model';

export class MatrizLayout extends BaseResourceModel {

  constructor(
    public id?: number,
    public exercicio?: Exercicio,
    public plano_conta?: string,
    public nome?: string,
    public tipo1?: string,
    public tipo2?: string,
    public tipo3?: string,
    public tipo4?: string,
    public tipo5?: string,
    public tipo6?: string,
    public tipo7?: string,
    public orgao?: Orgao
  ) {
    super();
  }

  static converteJson(json: any): MatrizLayout {
    return Object.assign(new MatrizLayout(), json);
  }
}
