import { BaseResourceModel } from "../../models/base-resource.model";
import { Exercicio } from "../comum/exercicio.model";
import { PlanoConta } from "./plano-conta.model";

export class PlanoContaAnterior extends BaseResourceModel {

  constructor(
    public id?: number,
    public plano_anterior?: PlanoConta,
    public plano_atual?: PlanoConta,
    public exercicio?: Exercicio,
    public tce?: boolean
  ) {
    super()
  }

  static converteJson(json: any): PlanoContaAnterior {
    return Object.assign(new PlanoContaAnterior(), json);
  }
}