import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagamentoListComponent } from './pagamento-list/pagamento-list.component';
import { PagamentoFormComponent } from './pagamento-form/pagamento-form.component';
import { PagamentoRptComponent } from './pagamento-rpt/pagamento-rpt.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: PagamentoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: PagamentoFormComponent, canActivate: [AuthGuard] },
  { path: 'novo/:liquidacao', component: PagamentoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: PagamentoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar/:liq', component: PagamentoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: PagamentoFormComponent, canActivate: [AuthGuard] },
  { path: 'relatorios', component: PagamentoRptComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagamentoRoutingModule { }
