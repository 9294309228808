import { BaseResourceStorage } from "../../models/base-resource.storage";
import { Compra } from "./compra.model";

export class CompraStorage extends BaseResourceStorage {
  constructor(
    public visivel_transparencia?: boolean,
    public compra?: Compra,
  ) {
    super();
  }

  static converteJson(json: any): CompraStorage {
    return Object.assign(new CompraStorage(), json);
  }
}