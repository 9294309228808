import { Audesp4Artigo16, Audesp4Artigo17, Audesp4Autorizacao, Audesp4ExistenciaRecursosSim, Audesp4PublicacaoAtoRatificacaoNao } from "./types/comum-type";
import { Audesp4ContratacaoDiretaDispensaLicitacao, Audesp4ContratacaoDiretaInexigibilidadeLicitacao } from "./types/modalidade-type";
import { Audesp4ComprasServicos, Audesp4ComprasServicosTI, Audesp4ObrasServicosEngenharia } from "./types/tipo-objeto-type";

export class RegistroPrecosNao3 {
    ComprasServicosTI?: Audesp4ComprasServicosTI;
    ComprasServicos?: Audesp4ComprasServicos;
    ObrasServicosEngenharia?: Audesp4ObrasServicosEngenharia;
    PublicacaoAtoRatificacaoNao?: Audesp4PublicacaoAtoRatificacaoNao;
    PublicacaoAtoRatificacaoDt?: string;
    ExistenciaRecursosSim?: Audesp4ExistenciaRecursosSim;
    ExistenciaRecursosNao?: 'N';
    Artigo16_ns3: Audesp4Artigo16;
    Artigo17_ns3: Audesp4Artigo17;
    NaoSeEnquadra: 'S';
    ExisteParecerTecnicoJuridico: 'S' | 'N';
    AudienciaPublicaProcLicNao?: 'N';
    AudienciaPublicaProcLicDt?: string;
    ContratacaoDiretaDispensaLicitacao?: Audesp4ContratacaoDiretaDispensaLicitacao;
    ContratacaoDiretaInexigibilidadeLicitacao?: Audesp4ContratacaoDiretaInexigibilidadeLicitacao;
    ExisteJustificativaContratacaoDireta: 'S' | 'N';
    TrataContratacaoFundArt3Resolucao072014: 'S' | 'N';
    Autorizacao?: Audesp4Autorizacao;
}