import { BaseResourceModel } from '../../models/base-resource.model';
import { SetorLocalizacao } from '../almoxarifado/setor-localizacao.model';
import { Setor } from '../almoxarifado/setor.model';
import { Usuario } from '../comum/usuario.model';
import { ConferenciaItem } from './conferencia-item.model';
import { TipoTransferenciaPatrimonio } from './tipo-transferencia-patrimonio.model';

export class Conferencia extends BaseResourceModel {
  constructor(
    public id?: number,
    public descricao?: string,
    public data_termino?: Date,
    public setor?: Setor,
    public localizacao?: SetorLocalizacao,
    public usuario?: Usuario,
    public itens?: ConferenciaItem[],
    public tipoTransferencia?: TipoTransferenciaPatrimonio,
  ) {
    super();
  }

  static converteJson(json: any): Conferencia {
    return Object.assign(new Conferencia(), json);
  }
}
