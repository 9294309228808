// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ChamamentoFavorecido } from './chamamento-favorecido.model';

export class CredenciamentoEquipe extends BaseResourceModel {
  constructor(
    public id?: number,
    public cargo?: string,
    public quantidade?: number,
    public remuneracao?: number,
    public favorecido?: ChamamentoFavorecido,
    public editavel?: boolean
  ) {
    super();
  }
  static converteJson(json: any): CredenciamentoEquipe {
    return Object.assign(new CredenciamentoEquipe(), json);
  }
}
