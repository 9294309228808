import { Component, ElementRef, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LicitacaoService } from 'administrativo-lib';
import { BaseResourceFormComponent, FuncaoService, LicitacaoAudesp, Login, LoginContabil } from 'eddydata-lib';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { LicitacaoAudespService } from '../../service/licitacao-audesp.service';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-form-empenho',
  templateUrl: './licitacao-audesp-form-empenho.component.html'
})
export class LicitacaoAudespFormEmpenhoComponent extends BaseResourceFormComponent<LicitacaoAudesp, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public registroPreco: LicitacaoAudesp;
  public ajuste: LicitacaoAudesp;
  public mensagemXML: string[];
  public xmlInvalido: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private route: ActivatedRoute,
    private funcaoService: FuncaoService,
    protected licitacaoService: LicitacaoService,
    protected audespService: LicitacaoAudespService,
  ) {
    super(new LicitacaoAudesp(), injector, LicitacaoAudesp.converteJson, audespService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      tipo: ['EMPENHO', [Validators.required]],
      arquivo: [null],
      parente: [null],
      codigo: [null],
      exportado: [false, [Validators.required]],
      enviado: [false, [Validators.required]],
      armazenado: [false, [Validators.required]],
      licitacao: [null, [Validators.required]],
      pacote: [null],
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'licitacao.modalidade,pacote,licitacao.orgao' };
  }

  protected afterLoad() {
    this.entidade.arquivo = JSON.parse(this.entidade.arquivo);
    this.tratarDatas('pt-br');
  }

  protected afterInit(): void {
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        if (params['ajuste']) {
          this.audespService.obter({ id: +params['ajuste'], relations: 'licitacao.modalidade,licitacao.orgao' }).pipe(takeUntil(this.unsubscribe))
            .subscribe((dados) => {
              this.ajuste = dados;
              this.entidadeForm.get('licitacao').setValue(this.ajuste.licitacao);
            });
        }
        if (params['registroPreco']) {
          this.audespService.obter({ id: +params['registroPreco'] }).pipe(takeUntil(this.unsubscribe))
            .subscribe((dados) => {
              this.registroPreco = dados;
            });
        }
      });
  }

  protected campoFoco(): ElementRef {
    return null
  }

  protected beforeSubmit() {
    this.tratarDatas('en');
    this.entidadeForm.get('parente').setValue(this.ajuste);
    this.entidadeForm.get('codigo').setValue(this.entidade.arquivo.CodigoAjuste);
    this.entidadeForm.get('arquivo').setValue(JSON.stringify(this.entidade.arquivo))
  }

  protected afterSubmit(modelo: LicitacaoAudesp) {
    if (!this.entidade.id) {
      this.router.navigateByUrl(this.audespService.retornarRota().replace(/\/novo/gm, `\/${modelo.id}/editar`));
    } else {
      this.tratarDatas('pt-br');
    }
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
  private tratarDatas(formato: 'pt-br' | 'en'): void {
    if (typeof this.entidade.arquivo === 'string') {
      this.entidade.arquivo = JSON.parse(this.entidade.arquivo);
    }
    this.converterDatas(this.entidade.arquivo, formato)
  }

  private converterDatas(obj: {}, formato: 'pt-br' | 'en') {
    for (var k in obj) {
      if (k === 'editavel') obj[k] = false
      if (typeof obj[k] == "object" && obj[k] !== null)
        this.converterDatas(obj[k], formato);
      else if (typeof obj[k] === 'string') {
        if (formato === 'en' && obj[k].match(/(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}/)) {
          const dtSplit = obj[k].split('/');
          obj[k] = `${dtSplit[2]}-${dtSplit[1]}-${dtSplit[0]}`
        } else if (formato === 'pt-br' && obj[k].match(/\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/)) {
          const dtSplit = obj[k].split('-');
          obj[k] = `${dtSplit[2]}/${dtSplit[1]}/${dtSplit[0]}`
        }
      }
    }
  }

  public async validarXML() {
    this.audespService.validarXML(this.entidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((xml) => {
        this.mensagemXML = undefined;
        this.xmlInvalido = undefined;
        toastr.success('Registro validado com sucesso!');
      }, error => {
        this.mensagemXML = String(error.error.payload).split('\n');
        if (this.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))) {
          this.xmlInvalido = this.mensagemXML.splice(this.mensagemXML.indexOf(this.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))), 1)[0];
          this.xmlInvalido = this.xmlInvalido.substring('ARQUIVO_XML:'.length, this.xmlInvalido.length);
        }
        $('#dialogMensagensXML').modal('show');
      });
  }

  public async gerarXML() {
    this.audespService.gerarXML(this.entidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((xml) => {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/xml;charset=ISO-8859-1,' + encodeURIComponent(this.funcaoService.formatXml(xml)));
        element.setAttribute('download', `${this.funcaoService.removerPontos(this.entidade.licitacao.numero)}_${this.entidade.tipo}.xml`);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        this.mensagemXML = undefined;
        this.xmlInvalido = undefined;
      }, error => {
        this.mensagemXML = String(error.error.payload).split('\n');
        if (this.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))) {
          this.xmlInvalido = this.mensagemXML.splice(this.mensagemXML.indexOf(this.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))), 1)[0];
          this.xmlInvalido = this.xmlInvalido.substring('ARQUIVO_XML:'.length, this.xmlInvalido.length);
        }
        $('#dialogMensagensXML').modal('show');
      });
    // location.reload()
  }

  public baixarXMLInvalido() {
    if (!this.xmlInvalido) return;
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/xml;charset=ISO-8859-1,' + encodeURIComponent(this.funcaoService.formatXml(this.xmlInvalido)));
    element.setAttribute('download', `${this.funcaoService.removerPontos(this.entidade.licitacao.numero)}_${this.entidade.tipo}_INVALIDO.xml`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  public atualizarStatus(tipo: 'ENVIADO' | 'ARMAZENADO', valor: boolean) {
    this.audespService
      .atualizarStatus(this.entidade.id, tipo, valor)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        toastr.success('Status do arquivo atualizado com sucesso!');
        location.reload()
      }, error => toastr.error(error.message ? error.message : error))
  }

  public podeIncluir(url?: string) {
    if (this.login) {
      if (this.administrador()) {
        return true;
      }
      if (!url) {
        url = this.baseResourceService.retornarRota();
      }
      return this.acessoPagina(this.login, url)
    }
    return false;
  }

  public acessoPagina(login: Login, url: string, visualizar?: boolean) {
    if (login) {
      if (this.funcaoService.campoJsonToken(login.token, "administrador") == true) {
        return true;
      }
      // url = url.replace('/solicitacao', '-solicitacao')
      if (
        url.lastIndexOf("/") > 0 &&
        (url.includes("editar") ||
          url.includes("visualizar") ||
          url.includes("novo"))
      ) {
        const idx = url.indexOf(
          "/".concat(
            url.includes("editar")
              ? "editar"
              : url.includes("visualizar")
                ? "visualizar"
                : "novo"
          )
        );
        url = url.substring(0, idx);
        if (url.substring(1, url.length).lastIndexOf("/") > 0) {
          url = url.substring(
            0,
            url.substring(1, url.length).lastIndexOf("/") + 1
          );
        }
      }
      for (const acesso of login.acessos) {
        if (login.sistema === 'licitacao' && acesso.pagina === '/licitacoes-audesp') {
          if (url.lastIndexOf('/') > 1) {
            let verificaURL = url.split('/');
            url = '/' + verificaURL['1'];
          }
        }
        if (url === acesso.pagina) {
          return acesso.permissao === (visualizar ? 1 : 2);
        }
      }
    }
    return false;
  }
}
