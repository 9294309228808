import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { SetorProcesso } from '../../../entidade/almoxarifado/setor-processo.model';
import { Setor } from '../../../entidade/almoxarifado/setor.model';
import { Login } from '../../../entidade/login/login';
import { FuncaoService } from '../../../util/funcao.service';
import { GlobalService } from '../../../util/global.service';
import { SetorProcessoService } from '../service/setor-processo.service';

@Component({
  selector: 'app-setor-processo',
  templateUrl: './setor-processo.component.html'
})
export class SetorProcessoComponent implements OnInit, OnChanges, OnDestroy {

  protected unsubscribe: Subject<void> = new Subject();
  public ptBR: any;
  public item: SetorProcesso;

  @Input() lista: Array<any>;
  @Input() setor: Setor;
  @Input() login: Login;
  @Output() callback: EventEmitter<SetorProcesso> = new EventEmitter();

  @ViewChild('btnAdicionar') public btnAdicionar: ElementRef;

  constructor(protected funcaoService: FuncaoService,
    public globalService: GlobalService,
    protected processoService: SetorProcessoService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes) {
    if (changes.setor) {
      this.processoService.filtrar(0, 0, { 'setor.id': changes.setor.currentValue.id })
        .subscribe(res => this.lista = Array.from(res.content as SetorProcesso[]));
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public adicionar() {
    if (!this.lista) {
      this.lista = [];
    }
    this.lista.unshift({ editavel: true });
  }

  public salvar(item: SetorProcesso) {
    try {
      if (!item.processo) {
        throw new Error('Informe o processo!');
      }

      item['visivel'] = true;
      item.setor = this.setor;

      if (item.id) {
        this.processoService.atualizar(item).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro alterado com sucesso!');
            item['editavel'] = false;
            this.callback.emit(res);
          },
            error => this.funcaoService.acaoErro(error));
      } else {
        this.processoService.inserir(item).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro inserido com sucesso!');
            item['editavel'] = false;
            if (res) {
              item.id = res.id;
              this.callback.emit(res);
            }
          },
            error => this.funcaoService.acaoErro(error));
      }

      this.btnAdicionar.nativeElement.disabled = false;

    } catch (e) {
      this.funcaoService.acaoErro(e.message);
      throw e;
    }
  }

  public editar(item: SetorProcesso) {
    try {
      if (!item.processo) {
        throw new Error('Informe o processo!');
      }

      item['editavel'] = true;

      if (item.id) {
        this.processoService.atualizar(item).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
          },
            error => this.funcaoService.acaoErro(error));
      }

      this.btnAdicionar.nativeElement.disabled = false;

    } catch (e) {
      this.funcaoService.acaoErro(e.message);
      throw e;
    }
  }

  public cancelar(item: SetorProcesso) {
    item['editavel'] = false;
  }

  public remover(item: SetorProcesso) {
    if (item.id) {
      this.processoService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          toastr.info('Registro removido com sucesso!');
        });
    }
    this.lista.splice(this.lista.findIndex((i) => { return i === item }), 1);
    this.btnAdicionar.nativeElement.disabled = false;
  }

  protected validSave(item: SetorProcesso): boolean {
    if (!item.processo) {
      toastr.warning('Informe o processo!');
      return false;
    }
    return true;
  }

  protected beforeSave(item: SetorProcesso): void { }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

}
