// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class ParametroDiarioOficial extends BaseResourceModel {

  constructor(
    public id?: number,
    public coluna?: number,
    public sumario?: string,
    public orgao?: Orgao
  ) {
    super();
  }

  static converteJson(json: any): ParametroDiarioOficial {
    return Object.assign(new ParametroDiarioOficial(), json);
  }
}
