import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Anexo121Balanco, Anexo122Balanco, Anexo12Balanco, Anexo13ABalanco, Anexo13Balanco, Anexo14ABalanco, Anexo14Balanco, Anexo14BBalanco, Anexo15Balanco, Anexo16Balanco, Anexo17Balanco, Anexo18Balanco, BalancoService, NotaExplicativaService } from 'contabil-lib';
import { AudespService, DemonstrativoContabilService, Exercicio, ExercicioService, FormatoExportacao, FuncaoService, GlobalService, LoginPublico, Orgao, OrgaoAssinaturaService, OrgaoService, ProgressoService } from 'eddydata-lib';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-transparencia-execucao-balancete-anual',
  templateUrl: './transparencia-execucao-balancete-anual.component.html'
})
export class TransparenciaExecucaoBalanceteAnualComponent implements OnInit, OnDestroy {
  login: LoginPublico = new LoginPublico();
  listaOrgaos: SelectItem[];
  orgaos: number[] = [];
  public todosOrgao: Orgao[];
  public listaExercicios: Array<any>;
  public balanceteSelect = 'BR';
  public listaBalancetes: Array<any>;
  public exercicioSelect: Exercicio;
  protected unsubscribe: Subject<void> = new Subject();
  public tab: number = 0;
  public opcao: any = 1
  public mes: number;
  public listaDemonstrativoContabil: Array<{ codigo: string, nome: string, layout: string, atualizacao: string, download: string }> = new Array<{ codigo: string, nome: string, layout: string, atualizacao: string, download: string }>();
  public layout: string = 'DEMONSTRATIVOS_CONTÁBEIS_AUDESP_AN_12_BAL_ORC_2022_v1';
  public atualizacao: string;
  public download: string = 'https://app.eddydata.com/SGM/attachment/?id=296711';
  public data1: Date;
  public data2: Date;
  public formato: FormatoExportacao

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    protected audespService: AudespService,
    protected balancoServico: BalancoService,
    protected notaService: NotaExplicativaService,
    protected exercicioService: ExercicioService,
    protected orgaoService: OrgaoService,
    protected progressoService: ProgressoService,
    protected orgaoAssinaturaService: OrgaoAssinaturaService,
    protected demonstrativoService: DemonstrativoContabilService,
    protected funcaoService: FuncaoService) {
    this.login = GlobalService.obterSessaoLogin();
    this.exercicioSelect = this.login.exercicio;
    this.balanceteSelect = "B12";
    this.orgaos.push(this.login.orgao.id);
  }

  ngOnInit() {
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
    this.orgaoService.obterTodos()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.todosOrgao = dados;
        this.listaOrgaos = [];
        for (const model of dados) {
          this.listaOrgaos.push({ label: `${model.codigo} - ${model.nome}`, value: model.id });
        }
      });
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaExercicios = new Array();
        const lista = dados.content;
        for (const exercicio of lista as Array<Exercicio>) {
          this.listaExercicios.push({ id: exercicio.id, ano: exercicio.ano });
        }
      });
    this.listaBalancetes = [
      { id: 'B12', nome: 'ANEXO 12 - BALANÇO ORÇAMENTÁRIO' },
      { id: 'B121', nome: 'ANEXO 12.1 - DEMONSTRATIVO DE EXECUÇÃO DE RESTOS A PAGAR NÃO PROCESSADO' },
      { id: 'B122', nome: 'ANEXO 12.2  - DEMONSTRATIVO DE EXECUÇÃO DE RESTOS A PAGAR PROCESSADO' },
      { id: 'B13', nome: 'ANEXO 13 - BALANÇO FINANCEIRO' },
      { id: 'B13A', nome: 'ANEXO 13 A - BALANÇO FINANCEIRO' },
      { id: 'B14', nome: 'ANEXO 14 - BALANÇO PATRIMONIAL' },
      { id: 'B14A', nome: 'ANEXO 14 A - QUADRO DEMONSTRATIVO DAS CONTAS ANALÍTICAS DO ATIVO FINANCEIRO' },
      { id: 'B14B', nome: 'ANEXO 14 B - QUADRO DEMONSTRATIVO DAS CONTAS ANALÍTICAS DO PASSIVO FINANCEIRO' },
      { id: 'B15', nome: 'ANEXO 15 - DEMONSTRAÇÃO DAS VARIAÇÕES PATRIMONIAIS' },
      { id: 'B16', nome: 'ANEXO 16 - DEMONSTRAÇÃO DA DÍVIDA FUNDADA' },
      { id: 'B17', nome: 'ANEXO 17 - DEMONSTRAÇÃO DA DÍVIDA FLUTUANTE' },
      { id: 'B18', nome: 'ANEXO 18 - DEMONSTRAÇÃO DOS FLUXOS DE CAIXA' },
    ];
    this.listaDemonstrativoContabil = this.demonstrativoService.buscarDemonstrativoContabil();


    this.atualizacao = this.listaDemonstrativoContabil[0].atualizacao;
    this.layout = this.listaDemonstrativoContabil[0].layout;
    this.download = this.listaDemonstrativoContabil[0].download;
    this.balanceteSelect = this.listaBalancetes[0].id;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  balanceteMudou() {
    for (let i of this.listaDemonstrativoContabil) {
      if (i.codigo == this.balanceteSelect) {
        this.layout = i.layout;
        this.atualizacao = i.atualizacao;
        this.download = i.download;
      }
    }
    if (this.balanceteSelect !== 'B13A' && this.opcao == 3) {
      this.opcao = 1;
    }
  }

  antesImprimir() {
    const parametros = {};

    parametros['relations'] = 'orgao';
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['ano'] = this.exercicioSelect.ano;
    parametros['descricao$in'] = 'BALANCETE-ISOLADO-CONTA-CORRENTE,BALANCETE-ISOLADO-CONTA-CORRENTE,BALANCETE-ISOLADO-CONTA-CONTABIL';

    this.audespService.filtrar(0, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res.content?.some(a => !a.armazenado)) {
          this.confirmationService.confirm({
            message: 'Mês não se encontra encerrado, dados poderão sofrer alterações.',
            acceptLabel: "OK",
            rejectVisible: false,
            header: 'Aviso!',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.imprimir(this.formato);
            },
          });
        } else {
          this.imprimir(this.formato);
        }
      });
  }

  imprimir(formato: FormatoExportacao) {
    this.formato = formato;
    if (this.exercicioSelect.id > this.login.exercicio.id) {
      this.messageService.add({ severity: 'warn', summary: 'Validação', detail: 'Ano selecionado não pode ser impresso.' });
    } else {
      if (this.orgaos && this.orgaos.length > 0) {
        const datepipe: DatePipe = new DatePipe('pt');
        let dtInicial = null;
        let dtFinal = null;
        if (+this.opcao == 3 || this.opcao == "3") {
          if (!this.data1 || !this.data2) {
            toastr.warning('Preencha as datas de período!');
            return;
          } else if (this.data1 > this.data2) {
            toastr.warning('Data inicial é maior que a data final!');
            return;
          }
          dtInicial = datepipe.transform(this.data1, 'yyyy-MM-dd');
          dtFinal = datepipe.transform(this.data2, 'yyyy-MM-dd');
        }
        if (this.balanceteSelect === 'B12') {
          // ANEXO 12 - BALANÇO ORÇAMENTÁRIO
          if (+this.opcao == 1 || this.opcao == "1") {
            const a12 = new Anexo12Balanco(this.balancoServico, this.exercicioSelect, this.notaService, this.progressoService, this.orgaoAssinaturaService);
            a12.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, undefined, formato);
          }

          if (+this.opcao == 2 || this.opcao == "2") {
            const a12 = new Anexo12Balanco(this.balancoServico, this.exercicioSelect, this.notaService, this.progressoService, this.orgaoAssinaturaService);
            a12.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, this.mes, formato);
          }
        } else if (this.balanceteSelect === 'B121') {
          // ANEXO 12.1 - DEMONSTRATIVO DE EXECUÇÃO DE RESTOS A PAGAR NÃO PROCESSADO
          if (+this.opcao == 1 || this.opcao == "1") {
            const a121 = new Anexo121Balanco(this.balancoServico, this.exercicioSelect, this.notaService, this.orgaoAssinaturaService);
            a121.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, undefined, formato);
          }

          if (+this.opcao == 2 || this.opcao == "2") {
            const a121 = new Anexo121Balanco(this.balancoServico, this.exercicioSelect, this.notaService, this.orgaoAssinaturaService);
            a121.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, this.mes, formato);
          }
        } else if (this.balanceteSelect === 'B122') {
          // ANEXO 12.2  - DEMONSTRATIVO DE EXECUÇÃO DE RESTOS A PAGAR PROCESSADO
          if (+this.opcao == 1 || this.opcao == "1") {
            const a122 = new Anexo122Balanco(this.balancoServico, this.exercicioSelect, this.notaService, this.orgaoAssinaturaService);
            a122.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, undefined, formato);
          }

          if (+this.opcao == 2 || this.opcao == "2") {
            const a122 = new Anexo122Balanco(this.balancoServico, this.exercicioSelect, this.notaService, this.orgaoAssinaturaService);
            a122.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, this.mes, formato);
          }
        } else if (this.balanceteSelect === 'B13') {
          // ANEXO 13 - BALANÇO FINANCEIRO
          if (+this.opcao == 1 || this.opcao == "1") {
            const a13 = new Anexo13Balanco(this.balancoServico, this.notaService, this.orgaoAssinaturaService);
            a13.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, undefined, formato);
          }

          if (+this.opcao == 2 || this.opcao == "2") {
            const a13 = new Anexo13Balanco(this.balancoServico, this.notaService, this.orgaoAssinaturaService);
            a13.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, this.mes, formato);
          }
        } else if (this.balanceteSelect === 'B13A') {
          // ANEXO 13 A - BALANÇO FINANCEIRO
          if (+this.opcao == 1 || this.opcao == "1") {
            const a13A = new Anexo13ABalanco(this.balancoServico, this.notaService, this.orgaoAssinaturaService);
            a13A.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, undefined, undefined, undefined, formato);
          }

          if (+this.opcao == 2 || this.opcao == "2") {
            const a13A = new Anexo13ABalanco(this.balancoServico, this.notaService, this.orgaoAssinaturaService);
            a13A.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, this.mes, undefined, undefined, formato);
          }

          if (+this.opcao == 3 || this.opcao == "3") {
            const a13A = new Anexo13ABalanco(this.balancoServico, this.notaService, this.orgaoAssinaturaService);
            a13A.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, undefined, dtInicial, dtFinal, formato);
          }
        } else if (this.balanceteSelect === 'B14') {
          // ANEXO 14 - BALANÇO PATRIMONIAL
          if (+this.opcao == 1 || this.opcao == "1") {
            const a14 = new Anexo14Balanco(this.balancoServico, this.notaService, this.progressoService, this.orgaoAssinaturaService);
            a14.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, undefined, formato);
          }

          if (+this.opcao == 2 || this.opcao == "2") {
            const a14 = new Anexo14Balanco(this.balancoServico, this.notaService, this.progressoService, this.orgaoAssinaturaService);
            a14.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, this.mes, formato);
          }
        } else if (this.balanceteSelect === 'B14A') {
          // ANEXO 14 A - QUADRO DEMONSTRATIVO DAS CONTAS ANALÍTICAS DO ATIVO FINANCEIRO
          if (+this.opcao == 1 || this.opcao == "1") {
            const a14A = new Anexo14ABalanco(this.balancoServico, this.exercicioSelect, this.notaService, this.orgaoAssinaturaService);
            a14A.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, undefined, formato);
          }

          if (+this.opcao == 2 || this.opcao == "2") {
            const a14A = new Anexo14ABalanco(this.balancoServico, this.exercicioSelect, this.notaService, this.orgaoAssinaturaService);
            a14A.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, this.mes, formato);
          }
        } else if (this.balanceteSelect === 'B14B') {
          // ANEXO 14 B - QUADRO DEMONSTRATIVO DAS CONTAS ANALÍTICAS DO PASSIVO FINANCEIRO
          if (+this.opcao == 1 || this.opcao == "1") {
            const a14B = new Anexo14BBalanco(this.balancoServico, this.exercicioSelect, this.notaService, this.orgaoAssinaturaService);
            a14B.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, undefined, formato);
          }

          if (+this.opcao == 2 || this.opcao == "2") {
            const a14B = new Anexo14BBalanco(this.balancoServico, this.exercicioSelect, this.notaService, this.orgaoAssinaturaService);
            a14B.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, this.mes, formato);
          }
        } else if (this.balanceteSelect === 'B15') {
          // ANEXO 15 - DEMONSTRAÇÃO DAS VARIAÇÕES PATRIMONIAIS
          if (+this.opcao == 1 || this.opcao == "1") {
            const a15 = new Anexo15Balanco(this.balancoServico, this.notaService, this.progressoService, this.orgaoAssinaturaService);
            a15.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, undefined, formato);
          }

          if (+this.opcao == 2 || this.opcao == "2") {
            const a15 = new Anexo15Balanco(this.balancoServico, this.notaService, this.progressoService, this.orgaoAssinaturaService);
            a15.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, this.mes, formato);
          }
        } else if (this.balanceteSelect === 'B16') {
          // ANEXO 16 - DEMONSTRAÇÃO DA DÍVIDA FUNDADA
          if (+this.opcao == 1 || this.opcao == "1") {
            const a16 = new Anexo16Balanco(this.balancoServico, this.notaService, this.progressoService, this.orgaoAssinaturaService);
            a16.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, undefined, formato);
          }

          if (+this.opcao == 2 || this.opcao == "2") {
            const a16 = new Anexo16Balanco(this.balancoServico, this.notaService, this.progressoService, this.orgaoAssinaturaService);
            a16.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, this.mes, formato);
          }
        } else if (this.balanceteSelect === 'B17') {
          // ANEXO 17 - DEMONSTRAÇÃO DA DÍVIDA FLUTUANTE
          if (+this.opcao == 1 || this.opcao == "1") {
            const a17 = new Anexo17Balanco(this.balancoServico, this.notaService, this.progressoService, this.orgaoAssinaturaService);
            a17.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, undefined, formato);
          }

          if (+this.opcao == 2 || this.opcao == "2") {
            const a17 = new Anexo17Balanco(this.balancoServico, this.notaService, this.progressoService, this.orgaoAssinaturaService);
            a17.montarRelatorio(this.exercicioSelect, this.orgaos, this.todosOrgao, this.mes, formato);
          }
        } else if (this.balanceteSelect === 'B18') {
          // ANEXO 18 - DEMONSTRAÇÃO DOS FLUXOS DE CAIXA
          if (+this.opcao == 1 || this.opcao == "1") {
            const a18 = new Anexo18Balanco(this.balancoServico, this.notaService, this.orgaoAssinaturaService, this.progressoService);
            a18.montarRelatorio(this.orgaos, this.exercicioSelect, false, this.todosOrgao, undefined, formato);
          }

          if (+this.opcao == 2 || this.opcao == "2") {
            const a18 = new Anexo18Balanco(this.balancoServico, this.notaService, this.orgaoAssinaturaService, this.progressoService);
            a18.montarRelatorio(this.orgaos, this.exercicioSelect, false, this.todosOrgao, this.mes, formato);
          }
        } else {
          toastr.warning('Selecione o anexo a ser impresso');
        }
      } else {
        toastr.warning('Selecione o(s) órgão(s) a serem impressos');
      }
    }
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }
}
