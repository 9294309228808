// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Setor } from '../almoxarifado/setor.model';
import { Usuario } from '../comum/usuario.model';
import { BaixaItemPatrimonio } from './baixa-item-patrimonio.model';
import { MotivoBaixaPatrimonio } from './motivo-baixa-patrimonio.model';

export class BaixaPatrimonio extends BaseResourceModel {
  constructor(
    public id?: number,
    public processo?: string,
    public data_baixa?: Date,
    public solicitacao?: boolean,
    public data_exclusao?: Date,
    public motivo_exclusao?: string,
    public obs?: string,
    public efetivado?: boolean,
    public solicitacao_finalizada?: boolean,
    public destino?: Setor,
    public motivo?: MotivoBaixaPatrimonio,
    public usuario?: Usuario,
    public usuario_exclusao?: Usuario,
    public itens?: BaixaItemPatrimonio[],

  ) {
    super();
  }

  static converteJson(json: any): BaixaPatrimonio {
    return Object.assign(new BaixaPatrimonio(), json);
  }
}
