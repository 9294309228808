export enum TagsAta {
    PROCESSO = 'Processo',
    NUMERO = 'Número da Licitação',
    MODALIDADE = 'Nome da Modalidade',
    OBJETO = 'Objeto do Processo',
    DATA_JULGAMENTO = 'Data do Julgamento',
    HORA_JULGAMENTO = 'Hora do Julgamento',
    SALA = 'Local do Julgamento',
    MEMORIAL = 'Itens do Processo',
    HISTORICO = 'Detalhamento de propostas e lances por item',
    ASSINATURAS = 'Assinaturas',

    PROPONENTES = '#Proponentes',
    QUADRO_LANCES = '#Quadro de Lances',
    CLASSIFICACAO = '#Classificação final dos proponentes por item',
    VENCEDORES = '#Vencedores por item',

    COMISSAO_PREGOEIRO = 'Comissão Pregoeiro',
    COMISSAO_MEMBROS = 'Comissão Membros',
    OBSERVACAO = 'Observação',
    RECURSO = 'Recurso',
    PREGOEIRO_EQUIPE_DE_APOIO = 'Pregoeiro e Equipe de Apoio',
    CLASSIFICACAO_PROPOSTA_INICAL  = 'Classificação Proposta Inicial'
}
