import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AnexoLoaService } from '../service/anexo-loa.service';
import { FuncaoService, LoginContabil, Exercicio, Relatorio, GlobalService } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class Anexo6 implements OnDestroy {
  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLoaService) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // tslint:disable: max-line-length
  public montarRelatorio(exercicio: Exercicio, orgaos: number[], executora: boolean) {
    this.anexoServico.obterAnexo6(exercicio.id, orgaos, this.login.ppa.id, executora)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {

        Relatorio.imprimirPersonalizado(
          'DEMONSTRATIVO CONSOLIDADO DA DESPESA DAS UNIDADES ORÇAMENTÁRIAS POR PROJETOS,ATIVIDADES E OP.ESPECIEAIS'
          , this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          this.conteudo(dados, exercicio, executora),
          'portrait', 'ANEXO 6',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private conteudo(dados: any[], exercicio: Exercicio, executora: boolean): {}[] {

    const tabela: {}[] = [];
    let primeiro = true;
    let gruposUnidade;
    if (executora)
      gruposUnidade = this.funcaoService.agrupar(dados, 'nome_executora',
        ['projeto', 'atividade', 'operacao']);
    else
      gruposUnidade = this.funcaoService.agrupar(dados, 'nome_unidade',
        ['projeto', 'atividade', 'operacao']);
    for (const unidade of gruposUnidade) {
      // monta o cabecalho
      const registros: {}[] = [[{
        text: `EXERCÍCIO ${exercicio.ano}`, fontSize: 11,
        alignment: 'center', bold: true, colSpan: 6, border: [true, true, true, false]
      }, '', '', '', '', ''],
      [{
        text: 'Lei nº 4.320/64, Art. 2º, §1º, II - Anexo 6',
        alignment: 'center',
        bold: true,
        colSpan: 6, border: [true, false, true, false]
      }, '', '', '', '', '']
      ];



      // monta o agrupamento do relatório
      let total1: number;
      let total2: number;
      let total0: number;
      let soma1 = 0;
      let soma2 = 0;
      let soma0 = 0;
      total1 = 0;
      total2 = 0;
      total0 = 0;
      registros.push(
        [{
          text: this.funcaoService.mascarar('##.##.## *', unidade.registros[0]['nome_orgao']), fontSize: 9,
          colSpan: 6, border: [true, true, true, false], margin: [0, 5, 0, 0]
        }, '', '', '', '', '']);

      registros.push(
        [{
          text: this.funcaoService.mascarar('##.##.## *', unidade.registros[0]['nome_unidade'] as string), fontSize: 9,
          bold: true, colSpan: 6, border: [true, false, true, false], margin: [0, 0, 0, 0]
        }, '', '', '', '', '']);
      if (executora) {
        registros.push(
          [{
            text: this.funcaoService.mascarar('##.##.## *', unidade.grupo as string), fontSize: 9,
            colSpan: 6, border: [true, false, true, false], margin: [0, 0, 0, 0]
          }, '', '', '', '', '']);
      }
      registros.push(
        [{
          text: 'CÓDIGO',
          alignment: 'center',
          bold: true, fontSize: 8
        }, {
          text: 'ESPECIFICAÇÃO',
          alignment: 'center',
          bold: true, fontSize: 8
        }, {
          text: 'OPERAÇÕES ESPECIAIS',
          alignment: 'center',
          bold: true, fontSize: 8
        }, {
          text: 'PROJETOS',
          alignment: 'center',
          bold: true, fontSize: 8
        }, {
          text: 'ATIVIDADES',
          alignment: 'center',
          bold: true, fontSize: 8
        }, {
          text: 'TOTAL',
          alignment: 'center',
          bold: true, fontSize: 8
        }]
      );

      const gruposFuncao = this.funcaoService.agrupar(unidade.registros, 'nome_funcao',
        ['projeto', 'atividade', 'operacao']);

      for (const funcao of gruposFuncao) {
        registros.push(
          [{
            text: this.funcaoService.mascarar('##', funcao.grupo as string), fontSize: 8,
            bold: true, border: [true, true, true, true]
          }, {
            text: (funcao.grupo as string).substr(2), fontSize: 8,
            bold: true, border: [true, true, true, true]
          },
          {
            text: funcao.totalizadores[`operacao`] > 0 ? this.funcaoService.convertToBrNumber(funcao.totalizadores[`operacao`]) : '', alignment: 'right',
            bold: true, fontSize: 8, border: [true, true, false, true]
          },
          {
            text: funcao.totalizadores[`projeto`] > 0 ? this.funcaoService.convertToBrNumber(funcao.totalizadores[`projeto`]) : '', alignment: 'right',
            bold: true, fontSize: 8, border: [true, true, false, true]
          },
          {
            text: funcao.totalizadores[`atividade`] > 0 ? this.funcaoService.convertToBrNumber(funcao.totalizadores[`atividade`]) : '', alignment: 'right',
            bold: true, fontSize: 8, border: [true, true, false, true]
          },
          {
            text: this.funcaoService.convertToBrNumber(+funcao.totalizadores[`projeto`] + +funcao.totalizadores[`atividade`] + +funcao.totalizadores[`operacao`]), alignment: 'right',
            bold: true, fontSize: 8, border: [true, true, true, true]
          }
          ]);
        total1 += +funcao.totalizadores[`projeto`];
        total2 += +funcao.totalizadores[`atividade`];
        total0 += +funcao.totalizadores[`operacao`];

        soma1 += +funcao.totalizadores[`projeto`];
        soma2 += +funcao.totalizadores[`atividade`];
        soma0 += +funcao.totalizadores[`operacao`];

        const gruposSubFuncao = this.funcaoService.agrupar(funcao.registros, 'nome_subfuncao',
          ['projeto', 'atividade', 'operacao']);

        for (const subfuncao of gruposSubFuncao) {
          registros.push(
            [{
              text: this.funcaoService.mascarar('##.###', subfuncao.grupo as string), fontSize: 8,
              bold: true, border: [true, false, true, false]
            }, {
              text: (subfuncao.grupo as string).substr(5), fontSize: 8,
              bold: true, border: [true, false, true, false]
            },
            {
              text: subfuncao.totalizadores[`operacao`] > 0 ? this.funcaoService.convertToBrNumber(subfuncao.totalizadores[`operacao`]) : '', alignment: 'right',
              bold: true, fontSize: 8, border: [true, false, false, false]
            },
            {
              text: subfuncao.totalizadores[`projeto`] > 0 ? this.funcaoService.convertToBrNumber(subfuncao.totalizadores[`projeto`]) : '', alignment: 'right',
              bold: true, fontSize: 8, border: [true, false, false, false]
            },
            {
              text: subfuncao.totalizadores[`atividade`] > 0 ? this.funcaoService.convertToBrNumber(subfuncao.totalizadores[`atividade`]) : '', alignment: 'right',
              bold: true, fontSize: 8, border: [true, false, false, false]
            },
            {
              text: this.funcaoService.convertToBrNumber(+subfuncao.totalizadores[`projeto`] + +subfuncao.totalizadores[`atividade`] + +subfuncao.totalizadores[`operacao`]), alignment: 'right',
              bold: true, fontSize: 8, border: [true, false, true, false]
            }
            ]);


          const gruposPrograma = this.funcaoService.agrupar(subfuncao.registros, 'nome_programa',
            ['projeto', 'atividade', 'operacao']);

          for (const programa of gruposPrograma) {
            registros.push(
              [{
                text: this.funcaoService.mascarar('##.###.####', programa.grupo as string), fontSize: 8,
                bold: true, border: [true, false, true, false]
              }, {
                text: (programa.grupo as string).substr(9), fontSize: 8,
                bold: true, border: [true, false, true, false]
              },
              {
                text: programa.totalizadores[`operacao`] > 0 ? this.funcaoService.convertToBrNumber(programa.totalizadores[`operacao`]) : '', alignment: 'right',
                bold: true, fontSize: 8, border: [true, false, false, false]
              },
              {
                text: programa.totalizadores[`projeto`] > 0 ? this.funcaoService.convertToBrNumber(programa.totalizadores[`projeto`]) : '', alignment: 'right',
                bold: true, fontSize: 8, border: [true, false, false, false]
              },
              {
                text: programa.totalizadores[`atividade`] > 0 ? this.funcaoService.convertToBrNumber(programa.totalizadores[`atividade`]) : '', alignment: 'right',
                bold: true, fontSize: 8, border: [true, false, false, false]
              },
              {
                text: this.funcaoService.convertToBrNumber(+programa.totalizadores[`projeto`] + +programa.totalizadores[`atividade`] + +programa.totalizadores[`operacao`]),
                alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
              }
              ]
            );

            // lista os registros do relatorio
            for (const registro of programa.registros) {
              registros.push([
                {
                  text: `${this.funcaoService.mascarar('##.###.####.####', registro.codigo_acao)}`, fontSize: 8, border: [true, false, false, false],
                },
                {
                  text: `${registro.nome_acao}`, fontSize: 8, border: [true, false, false, false]
                },
                {
                  text: registro.operacao > 0 ? this.funcaoService.convertToBrNumber(registro.operacao) : '', alignment: 'right',
                  fontSize: 8, border: [true, false, false, false]
                },
                {
                  text: registro.projeto > 0 ? this.funcaoService.convertToBrNumber(registro.projeto) : '', alignment: 'right',
                  fontSize: 8, border: [true, false, false, false]
                },
                {
                  text: registro.atividade > 0 ? this.funcaoService.convertToBrNumber(registro.atividade) : '', alignment: 'right',
                  fontSize: 8, border: [true, false, false, false]
                },
                {
                  text: this.funcaoService.convertToBrNumber(+registro.projeto + +registro.atividade + +registro.operacao),
                  alignment: 'right', fontSize: 8, border: [true, false, true, false]
                }
              ]);
            }
          }
        }
      }
      registros.push([
        {
          text: 'TOTAL', colSpan: 2, fontSize: 8, border: [true, true, true, true], margin: [0, 0, 0, 5]
        },
        {
          text: '', fontSize: 8, border: [true, true, true, true]
        },
        {
          text: total0 > 0 ? this.funcaoService.convertToBrNumber(total0) : '', alignment: 'right',
          fontSize: 8, border: [true, true, true, true]
        },
        {
          text: total1 > 0 ? this.funcaoService.convertToBrNumber(total1) : '', alignment: 'right',
          fontSize: 8, border: [true, true, true, true]
        },
        {
          text: total2 > 0 ? this.funcaoService.convertToBrNumber(total2) : '', alignment: 'right',
          fontSize: 8, border: [true, true, true, true]
        },
        {
          text: this.funcaoService.convertToBrNumber(+total1 + +total2 + +total0),
          alignment: 'right', fontSize: 8, border: [true, true, true, true]
        }
      ]);

      registros.push([{
        text: '', colSpan: 6, border: [false, false, false, false], margin: [0, 0, 0, 10]
      }, '', '', '', '', '']);
      if (!primeiro) {
        tabela.push([{
          layout: 'linhas',
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            dontBreakRows: true,
            headerRows: 2,
            widths: ['auto', '*', 60, 60, 60, 60],
            body: registros
          }, pageBreak: 'before'
        }]);
      } else {
        tabela.push([{
          layout: 'linhas',
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            dontBreakRows: true,
            headerRows: 2,
            widths: ['auto', '*', 60, 60, 60, 60],
            body: registros
          }
        }]);
        primeiro = false;
      }
    }
    return tabela;
  }


}
