import { Component, Injector } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Coluna, FormatoExportacao } from '../../components/types';
import { Exercicio } from '../../entidade/comum/exercicio.model';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { BaseResourceListComponent, Filtro } from '../../models/base-resource-list';
import { ExercicioService } from '../service/exercicio.service';

import * as toastr from 'toastr';

declare var $: any;
@Component({
  selector: 'lib-exercicio-list',
  templateUrl: './exercicio-list.component.html'
})
export class ExercicioListComponent extends BaseResourceListComponent<Exercicio, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public exercicioEdicao: Exercicio;
  public ocultar: boolean

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    private exService: ExercicioService) {
    super(exService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return '';
  }

  protected condicoesGrid(): {} {
    return {
      ['cidade.id']: this.login.cidade.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['ano$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['ano']
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: Exercicio): Observable<Exercicio> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Ano', coluna: 'ano' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    this.exService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE EXERCÍCIOS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem exercícios', ['auto', '*', '*', '*'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public remover(item: Exercicio, index: number) {
    if (item.id) {
      this.exService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.preencherGrid()
          toastr.info('Registro removido com sucesso!');
        }, err => toastr.error('Erro', err.error.payload));
    }
  }

  public retornar() {
    this.router.navigate([`/cadastros-auxiliares`]);
  }

  public editar(item: Exercicio) {
    this.ocultar = true
    this.exercicioEdicao = item
    $('#dialogExercicio').modal('show')
  }

  public salvar() {
    this.ocultar = false
    this.exercicioEdicao = new Exercicio()
    $('#dialogExercicio').modal('show')
  }

}
