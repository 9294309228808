// tslint:disable: variable-name
import { EmpenhoExtra, Pagamento } from 'eddydata-lib';
import { BaseResourceModel } from '../../models/base-resource.model';
import { FichaExtra } from './ficha-extra.model';
import { Liquidacao } from './liquidacao.model';

export class Retencao extends BaseResourceModel {
  constructor(
    public id?: number,
    public aux?: number,
    public data_vencimento?: Date,
    public data_anulacao?: Date,
    public cei?: string,
    public gps?: string,
    public valor_retido?: number,
    public valor_aliquota?: number,
    public ficha?: FichaExtra,
    public liquidacao?: Liquidacao,
    public anulado?: boolean,
    public retencao_anulada?: Retencao,
    public historico?: string,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public editavel?: boolean,
    public anulacoes?: Retencao[],
    public base_IR?: number,
    public valor_IR?: number,
    public pagamento_anulado?: Pagamento,
    public base_agregado?: number,
    public valor_agregado?: number,
    public base_csll?: number,
    public valor_csll?: number,
    public base_cofins?: number,
    public valor_cofins?: number,
    public base_pp?: number,
    public valor_pp?: number,
    public empenho_extra?: EmpenhoExtra,
  ) {
    super();
  }

  static converteJson(json: any): Retencao {
    return Object.assign(new Retencao(), json);
  }
}
