import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, LicitacaoOcorrencia } from 'eddydata-lib';

@Injectable({
    providedIn: 'root'
})
export class LicitacaoOcorrenciaService extends BaseResourceService<LicitacaoOcorrencia> {

    constructor(
        protected injector: Injector
    ) {
        super(`licitacao-ocorrencias`, injector);
    }

}
