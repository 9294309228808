import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OrgaoAssinatura } from '../../entidade/comum/orgao-assinatura.model';
import { BaseResourceService } from '../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class OrgaoAssinaturaService extends BaseResourceService<OrgaoAssinatura> {

  constructor(
    protected injector: Injector
  ) {
    super(`orgaos-assinaturas`, injector);
  }

  public obterPorDataLimite(dataLimite: string, orgaoId: number): Observable<OrgaoAssinatura> {
    return this.http.get<OrgaoAssinatura>(
      `${orgaoId}/${this.api}/filtrar?data_limite$le=${dataLimite}&orgao.id=${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
