// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { CreditoItem } from './credito-item.model';
import { Orgao } from '../comum/orgao.model';
import { Exercicio } from '../comum/exercicio.model';

export class Credito extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_credito?: Date,
    public data_publicacao?: Date,
    public data_vigencia?: Date,
    public mes?: number,
    public tipo?: string,
    public ocorrencia?: string,
    public decreto?: string,
    public decreto_interno?: string,
    public lei?: string,
    public motivo?: string,
    public ativo?: boolean,
    public justificativa?: string,
    public orgao?: Orgao,
    public exercicio?: Exercicio,
    public itens?: CreditoItem[]
  ) {
    super();
  }

  static converteJson(json: any): Credito {
    return Object.assign(new Credito(), json);
  }
}
