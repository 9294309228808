import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { LicitacaoAudesp } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LicitacaoAudespService } from '../../service/licitacao-audesp.service';

@Component({
  selector: 'lib-licitacao-audesp-exigencia-obras-list',
  templateUrl: './licitacao-audesp-exigencia-obras-list.component.html'
})
export class LicitacaoAudespExigenciaObrasListComponent implements OnChanges, OnDestroy {

  @Input() public licitacao: number;
  @Input() public registroPreco: number;
  @Input() public ajuste: number;
  public listaExigenciaObras: LicitacaoAudesp[];

  protected unsubscribe: Subject<void> = new Subject();

  constructor(private audespService: LicitacaoAudespService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.ajuste) {
      this.audespService.filtrar(0, 0, { parente_id: this.ajuste, tipo: 'EXIGENCIA_OBRAS' }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaExigenciaObras = res?.content ? res.content : [];
        })
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
