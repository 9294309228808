import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { Pessoa } from '../../entidade/comum/pessoa.model';
import { Login } from '../../entidade/login/login';
import { FuncaoService } from '../../util/funcao.service';
import { GlobalService } from '../../util/global.service';
import { ViaCEPService } from '../../util/viacep.service';
import { PessoaService } from '../service/pessoa.service';

declare var $: any;

@Component({
  selector: 'lib-pessoa-cadastro-dlg',
  templateUrl: './pessoa-cadastro-dlg.component.html'
})
export class PessoaCadastroDlgComponent implements OnInit, OnDestroy {

  @ViewChild('f') formGroup: any;
  @Input() login: Login;
  @Input() renderizar: string[] = [];
  @Input() entity: Pessoa = new Pessoa();
  @Output() callback: EventEmitter<Pessoa> = new EventEmitter();

  @Output() onSalvar: EventEmitter<Pessoa> = new EventEmitter();

  @Input() public nomeDlg: string = 'dialogPessoa';

  public listaTipos: Array<any>;
  protected unsubscribe: Subject<void> = new Subject();
  public encontrado = false;

  constructor(
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected pessoaService: PessoaService,
    private viaCepService: ViaCEPService
  ) { }

  ngOnInit() {
    this.listaTipos = this.globalService.obterListaTipoFornecedores();
    this.entity = new Pessoa();
    this.entity.especie = '2';
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public buscar() {
    if (this.entity.cpf_cnpj) {
      if (!this.funcaoService.validarCPF(this.entity.cpf_cnpj)) {
        toastr.warning('O CPF/CNPJ informado está inválido!')
        return;
      }
      this.encontrado = false;
      this.pessoaService.filtrar(0, 0, { cpf_cnpj: this.entity.cpf_cnpj, cidade_id: this.login.cidade.id })
        .pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
          if (res.content && res.content[0]) {
            this.entity = res.content[0];
            this.encontrado = true;
          }
        });
    }
  }

  public salvar() {
    try {
      if (!this.entity.nome) {
        throw new Error('Informe o nome!');
      }
      if (!this.entity.endereco) {
        throw new Error('Informe o endereço!');
      }
      if (!this.entity.numero) {
        throw new Error('Informe o número!');
      }
      if (!this.entity.municipio) {
        throw new Error('Informe o município!');
      }
      if (!this.entity.bairro) {
        throw new Error('Informe o bairro!');
      }
      if (!this.entity.cpf_cnpj && this.entity.especie === '2') {
        throw new Error('Informe o CPF!');
      }
      if (!this.entity.cpf_cnpj && this.entity.especie === '1') {
        throw new Error('Informe o CNPJ!');
      }
      if (!this.entity.cpf_cnpj && this.entity.especie !== '1' && this.entity.especie !== '2') {
        throw new Error('Informe a inscrição!');
      }
      if (!this.funcaoService.validarCPF(this.entity.cpf_cnpj)) {
        toastr.warning('O CPF/CNPJ informado está inválido!')
        return;
      }

      this.entity.cartao = !this.entity.cartao ? 0 : this.entity.cartao;
      this.entity.pis_pasep = !this.entity.pis_pasep ? '-' : this.entity.pis_pasep;
      this.entity.rg = !this.entity.rg ? '-' : this.entity.rg;
      this.entity.sexo = !this.entity.sexo ? 'N' : this.entity.sexo;
      this.entity.cidade = !this.entity.cidade?.id ? this.login.cidade : this.entity.cidade;
      this.entity.orgao = !this.entity.orgao?.id ? this.login.orgao : this.entity.orgao;

      if (this.encontrado) {
        this.pessoaService.atualizar(this.entity).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            this.callback.emit(res);
            this.onSalvar.emit(res)
            toastr.success('Registro salvo com sucesso');
            this.limparDados();
          }, (error) => toastr.error(this.funcaoService.obterMensagemErro(error)));
      } else {
        this.pessoaService.inserir(this.entity).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            this.callback.emit(res);
            this.onSalvar.emit(res)
            toastr.success('Registro inserido com sucesso');
            this.limparDados();
          }, (error) => toastr.error(this.funcaoService.obterMensagemErro(error)));
      }
    } catch (e) {
      toastr.error(this.funcaoService.obterMensagemErro(e), 'Validação');
      throw e;
    }
  }

  public limparDados() {
    this.entity = new Pessoa();
    this.entity.especie = '2';

    $(`#${this.nomeDlg}`).modal('hide');
  }

  public fechar() {
    this.entity = new Pessoa();
    this.entity.especie = '2';
    $(`#${this.nomeDlg}`).modal('hide');
  }

  public buscarCEP() {
    if (!this.entity.cep)
      return;
    this.viaCepService.obterPorCPF(this.entity.cep).subscribe((data) => {
      if (!data)
        return;
      this.entity.endereco = data.logradouro;
      this.entity.bairro = data.bairro;
      this.entity.municipio = data.localidade;
    }, error => toastr.error('Erro ao buscar endereço'))
  }
}
