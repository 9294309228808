import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListboxModule } from 'primeng/listbox';
import { BalanceteRoutingModule } from './balancete-routing.module';
import { BalanceteComponent } from './balancete.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'eddydata-lib';
import { FieldsetModule } from 'primeng/fieldset';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';

@NgModule({
  declarations: [BalanceteComponent],
  imports: [
    SharedModule,
    FormsModule,
    CommonModule,
    BalanceteRoutingModule,
    ListboxModule,
    FieldsetModule,
    RadioButtonModule,
    CalendarModule,
    AutoCompleteModule
  ]
})
export class BalanceteModule { }
