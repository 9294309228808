// tslint:disable: variable-name
import { SituacaoEmprestimo } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { TransferenciaPatrimonio } from './transferencia-patrimonio.model';

export class TransferenciaEmprestimoPatrimonio extends BaseResourceModel {
  constructor(
    public id?: number,
    public status?: SituacaoEmprestimo,
    public prev_devolucao?: Date,
    public devolucao?: Date,
    public transferencia?: TransferenciaPatrimonio,
  ) {
    super();
  }

  static converteJson(json: any): TransferenciaEmprestimoPatrimonio {
    return Object.assign(new TransferenciaEmprestimoPatrimonio(), json);
  }
}
