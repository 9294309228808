import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { ConvenioLstComponent } from './convenio-lst/convenio-lst.component';
import { ConvenioShwComponent } from './convenio-shw/convenio-shw.component';
import { ConvenioFrmComponent } from './convenio-frm/convenio-frm.component';

const routes: Routes = [
  { path: '', component: ConvenioLstComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: ConvenioFrmComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: ConvenioFrmComponent, canActivate: [AuthGuard] },
  { path: 'pesquisa/:pagina', component: ConvenioLstComponent, canActivate: [AuthGuard] },
  { path: 'pesquisa/:pagina/:filtro', component: ConvenioLstComponent, canActivate: [AuthGuard] },
  { path: ':id/visualiza/:pagina', component: ConvenioShwComponent, canActivate: [AuthGuard] },
  { path: ':id/visualiza/:pagina/:filtro', component: ConvenioShwComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConvenioRoutingModule { }

