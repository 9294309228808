import { HttpClient } from '@angular/common/http';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class NotaVersaoService {

  protected http: HttpClient;

  constructor(
    protected injector: Injector
  ) {
    this.http = injector.get(HttpClient);
  }

  public obterPorSistemaVersao(sistema: string): Observable<any> {
    return this.http.get<any>(`https://app.eddydata.com/SGM/nota-versao?sistema=${sistema}`).pipe(map(res => res));
  }

}
