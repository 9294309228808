import { Component, OnInit, Injector, Input, ViewChild, ElementRef } from '@angular/core';
import { LoginService } from './servico/login.service';
import { Router } from '@angular/router';
import { FuncaoService } from '../util/funcao.service';
import { Sistemas } from '../components/types';
import { environment } from '../../environments/environment';
import { GlobalService } from '../util/global.service';
import { Orgao } from '../entidade/comum/orgao.model';
import { OrgaoService } from '../orgao/service/orgao.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr'

@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild('email_') campoEmail: ElementRef;
  @Input() sistema: Sistemas;

  protected router: Router;
  protected unsubscribe: Subject<void> = new Subject();
  public listaOrgaos: Array<Orgao> = []
  public orgao: Orgao = new Orgao()
  public msgs: string;
  public email: string;
  public senha: string;
  public maskConfig = {
    mask: [
      {
        mask: '000.000.000-00'
      },
      {
        mask: /^\S*@?\S*$/
      }
    ]
  };

  constructor(
    protected injector: Injector,
    private authService: LoginService,
    protected orgaoService: OrgaoService
  ) {
    this.router = this.injector.get(Router);
    const login = GlobalService.obterSessaoLogin();
    if (login && login.usuario && login.usuario.sistema) {
      new FuncaoService().navegarPara(login.usuario.sistema, this.router, login);
    }
  }

  ngOnInit() {
    this.authService.mostrarMenuEmitter.emit(false);
    setTimeout(() => this.campoEmail ? this.campoEmail.nativeElement.focus() : null, 500);

    this.orgaoService
      .obterTodos()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaOrgaos = res;
        this.orgao = res[0]
      }, (e) => {
        this.listaOrgaos = [];
      });
  }

  async fazerLogin() {
    const resultado = await this.authService.autenticar(this.email, this.senha, this.sistema, null, this.orgao.id);
    if (resultado && !resultado.entidade) {
      this.msgs = resultado.error ? resultado.error.message : resultado.message;
    }
  }

  recuperarSenha() {
    this.authService.mostrarMenuEmitter.emit(false);
    this.router.navigate([`/recuperar-senha`]);
  }

  registroUsuario() {
    this.authService.mostrarMenuEmitter.emit(false);
    this.authService.sistemaEmitter.emit(this.sistema);
    this.router.navigate([`/registro`]);
  }

  loginEntidade() {
    this.authService.mostrarMenuEmitter.emit(false);
    this.router.navigate([`/login-entidade`]);
  }

  mostrarVersao(): string {
    return environment?.versao;
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

}
