import { BaseResourceModel, Favorecido } from "eddydata-lib";
import { EmissaoCheque } from "./emissao-cheque.model";

export class ChequeItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public   aux?: number,
    public   numero?: number,
    public   valor?: number,
    public   valor_retencao?: number,
    public   nome_fornecedor?: string,
    public   cpf_cnpj_fornecedor?: string,
    public   data_regularizacao?: Date,
    public   data_cancelamento?: Date,
    public   impresso?: boolean,
    public   registrado?: boolean,
    public   copia_impressa?: boolean,
    public   cheque?: EmissaoCheque,
    public   fornecedor?: Favorecido,) {
    super();
  }
  static converteJson(json: any): ChequeItem {
    return Object.assign(new ChequeItem(), json);
  }
}