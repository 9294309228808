import { Injectable, Injector } from '@angular/core';
import { BaseResourceService } from '../../models/services/base-resource.service';
import { PncpRegistro } from '../../entidade/comum/registro-pncp.model';

@Injectable({
  providedIn: 'root'
})
export class RegistroPncpService extends BaseResourceService<PncpRegistro> {

  constructor(
    protected injector: Injector
  ) {
    super(`pncp-registros`, injector);
  }

}
