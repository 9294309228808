import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AssinaturaService, BaseResourceRptPersonalizadoComponent, GlobalService, Licitacao, LicitacaoPipe, ParametroLicitacao, ParametroLicitacaoService, Rcms, Rpl } from "eddydata-lib";
import { RplService } from "../../rpl/service/rpl.service";
import * as toastr from 'toastr';
import { takeUntil } from "rxjs/operators";
import { RcmsService } from "../../rcms/service/rcms.service";

@Component({
  selector: 'app-autorizacao-responsavel-rpt',
  templateUrl: './autorizacao-responsavel-rpt.component.html'
})
export class AutorizacaoResponsavelRptComponent extends BaseResourceRptPersonalizadoComponent {

  @Input() public licitacao: Licitacao;
  @Input() visualizar: boolean = false;
  @Input() importarRequisicao: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  private requisicoes: Rpl[] | Rcms[] = [];
  public parametrosLicitacao: ParametroLicitacao; 
  public importes: { id: boolean, nome: string }[] = [
    { id: true, nome: 'Requisição' },
    { id: false, nome: 'RPL' },
  ];

  constructor(
    private rplService: RplService,
    private rcmsService: RcmsService,
    private parametroLicitacaoService: ParametroLicitacaoService,
    protected assinaturaService: AssinaturaService,
  ) {
    super(assinaturaService);
    this.assinaturaObrigatoria = true;
  }

  protected afterInit(): void {
    this.parametroLicitacaoService.filtrar(1, -1, { orgao_id: this.login.orgao.id }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.parametrosLicitacao = res.content[0];
      });
    this.listFormato = ['pdf', 'docx'];
  }

  protected tituloRelatorio(): string {
    return '';
  }

  protected async montarConteudo(): Promise<{}[]> {
    const assinaturas = this.assinatura.pessoas.filter(p => p.selecionado);

    return await new Promise(async (resolve) => {
      resolve([this.montarAutorizacao()]
        .concat(this.imprimirAssinaturas(assinaturas)));
    });
  }

  public imprimir(): void {
    if (!this.validar())
      return;
    const service = this.importarRequisicao ? this.rcmsService : this.rplService;
    service.filtrar(1, -1, { 'itens.memorial.licitacao.id': this.licitacao.id, 'orgao.id': this.login.orgao.id, 'ignoreCondObrig': true })
      .subscribe((data) => {
        if (!data.content || data.content.length === 0) {
          toastr.warning(`Nenhuma requisição de licitação foi encontrada!`);
          return;
        }
        this.requisicoes = data.content;
        super.imprimir();
      }, (err) => toastr.error(`Foi identificado um erro ao buscar os dados para emissão do relatorio!`));
  }

  private validar(): boolean {
    if (!this.licitacao?.id) {
      toastr.warning(`Não foi informado a licitação para impressão da autorização!`);
      return false;
    }
    return true;
  }

  private montarAutorizacao(): {}[] {
    const text = this.requisicoes.map((r) => r.numero).join(', ');
    const processo = new LicitacaoPipe().transform(this.licitacao.processo);
    let data = new Date();
    return [{
      stack: [
        { text: `Licitação: ${new LicitacaoPipe().transform(this.licitacao.numero)}`, fontSize: 12, alignment: 'left', margin: [0, 20, 0, 10] },
        { text: `Processo: ${new LicitacaoPipe().transform(this.licitacao.processo)}`, fontSize: 12, alignment: 'left', margin: [0, 0, 0, 10] },
        { text: `Modalidade: ${this.licitacao.modalidade.nome}`, fontSize: 12, alignment: 'left', margin: [0, 0, 0, 50] },
        {
          text: `                    De acordo com a legislação em vigor, autorizo o setor competente da ${this.login.orgao.nome} a efetuar a dispensa do procedimento licitatório originado pela(s) requisições n.º ${text} constante(s) do Processo de Número: ${processo}, encaminhando a(s) minuta(s) ao Departamento Jurídico para emissão de parecer nos termos do parágrafo único do art. 38, da Lei 8.666/93.`,
          fontSize: 13, alignment: 'justify',
          lineHeight: 2.0, margin: [0, 0, 0, 75],
          preserveLeadingSpaces: true
        },
        {
          text: `Pelo seu prosseguimento`,
          fontSize: 11, alignment: 'right', margin: [0, 0, 0, 25]
        },
        { text: `${this.login.cidade.nome}, ${data.getDate()} de ${new GlobalService().obterMes(data.getMonth() + 1)} de ${data.getFullYear()}`, fontSize: 13, margin: [0, 25], alignment: 'right' },
      ], margin: [20, 0, 20, 100]
    }];
  }

  protected modalRelatorio(): string {
    return null;
  }

  protected layoutRelatorio(): {} {
    return null;
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

}