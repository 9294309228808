import { Component, OnInit, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { BaseResourceFormComponent, Licitacao, LoginContabil, Proponente, Memorial, Proposta, GlobalService, FuncaoService, DateFormatPipe } from 'eddydata-lib';
import { PropostaService, ProponenteService, MemorialService, LicitacaoService } from 'administrativo-lib';

@Component({
  selector: 'app-licitacao-view',
  templateUrl: './licitacao-view.component.html'
})
export class LicitacaoViewComponent extends BaseResourceFormComponent<Licitacao, LoginContabil> implements OnInit {

  /**
   * Declaração de variáveis
   */
  public listaProponentes: Array<Proponente>;
  public listaMemoriais: Array<Memorial>;
  public listaPropostas: Array<Proposta>;

  public descricao: string;

  /**
   * Construtor com as injeções de dependencias
   */

  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected propostaService: PropostaService,
    protected proponenteService: ProponenteService,
    protected memorialService: MemorialService,
    protected licitacaoService: LicitacaoService) {
    super(new Licitacao(), injector, Licitacao.converteJson, licitacaoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      data_abertura: [null, [Validators.required]],
      valor_estimado: [null, [Validators.required]],
      valor_licitado: [null, [Validators.required]],
      data_ata: [null],
      data_publicacao: [null],
      data_homologacao: [null],
      data_edital: [null],
      data_julgamento: [null],
      enviar_audesp: [null],
      enviar_transparencia: [null],
      julgamento: [null],
      situacao: [null],
      processo: [null, [Validators.required]],
      modalidade: this.fb.group({
        id: [null, [Validators.required]],
        nome: [null, [Validators.required]]
      }),
      setor: [null, [Validators.required]],
      exercicio: this.fb.group({
        id: [null, [Validators.required]],
        ano: [null, [Validators.required]]
      }),
      objeto: [null, [Validators.required]]
    });
  }

  protected afterLoad() {
    this.entidade.data_abertura = new DateFormatPipe().transform(this.entidade.data_abertura, []);
    this.entidade.data_homologacao = new DateFormatPipe().transform(this.entidade.data_homologacao, []);
    this.preencherTabela();
  }


  protected parametrosExtras(): {} {
    return { relations: 'modalidade,orgao,setor,exercicio' };
  }

  protected afterInit(): void {
  }

  protected beforeSubmit() {
  }

  protected afterSubmit(ent: Licitacao) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private preencherTabela() {
    this.proponenteService.obterPorLicitacao(this.entidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaProponentes = res.content;
      });
    this.memorialService.obterPorLicitacao(this.entidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaMemoriais = res.content;
      });
  }

  public abrirProposta(item: Memorial) {
    this.descricao = item.descricao;
    this.propostaService.obterPorMemorial(item.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaPropostas = res.content;
      });
  }

}
