import { Injectable, Injector } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseResourceService } from '../../../models/services/base-resource.service';
import { SetorProduto } from '../../../entidade/almoxarifado/setor-produto.model';

@Injectable({
  providedIn: 'root'
})
export class SetorProdutoService extends BaseResourceService<SetorProduto> {

  constructor(
    protected injector: Injector
  ) {
    super(`setor-produtos`, injector);
  }

  public obterPorSetor(setor: number): Observable<SetorProduto[]> {
    return this.http.get<SetorProduto>(
      `${this.login.cidade.id}/${this.api}/filtrar?setor.id=${setor}&relations=produto_unidade.produto`,
      this.httpOptions()).pipe(
        map(res => res['content']),
        catchError(err => this.handleError(err))
      );
  }

}
