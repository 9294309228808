import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Compra, EddyAutoComplete, Exercicio, ExercicioService, FuncaoService, GlobalService, LoginContabil, Setor, SetorService, Usuario, UsuarioService, Favorecido, FavorecidoService } from 'eddydata-lib';
import { CompraService } from '../../compra/service/compra.service';
import { NotaCompra } from './nota-compra-rpt-almoxarifado';
import { PropostaService } from 'administrativo-lib';
import * as toastr from 'toastr';

@Component({
  selector: 'app-nota-compra-rpt-almoxarifado',
  templateUrl: './nota-compra-rpt-almoxarifado.component.html'
})
export class NotaCompraRptAlmoxarifadoComponent implements OnInit {

  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();
  @Input() compra: Compra;

  public login: LoginContabil;
  public listExercicio: Exercicio[];
  public exercicio: Exercicio;
  public tipo: 'N' | 'D' = 'N';
  public geral: boolean = false;
  public numeroInicial: number;
  public numeroFinal: number;
  public dataInicial: Date;
  public dataFinal: Date;
  public setorAutoComplete: EddyAutoComplete<Setor>;
  public usuarioAutoComplete: EddyAutoComplete<Usuario>;
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;
  public setor: Setor;
  public usuario: Usuario;
  public favorecido: Favorecido;
  public ptBR: any;
  public ordenacao: { nome: string, coluna: string, ordem: 'DESC' | 'ASC' };
  public listaOrdenacao: { nome: string, coluna: string, ordem: 'DESC' | 'ASC' }[] = [];

  // ========================================================================
  // ------------------------------ CONSTRUTOR ------------------------------
  // ========================================================================

  constructor(
    private compraService: CompraService,
    protected usuarioService: UsuarioService,
    private favorecidoService: FavorecidoService,
    private funcaoService: FuncaoService,
    private exercicioService: ExercicioService,
    public propostaService: PropostaService,
    private setorService: SetorService) {
    this.login = GlobalService.obterSessaoLogin();
    this.ptBR = new GlobalService().obterDataBR();
  }

  ngOnInit(): void {
    this.carregarAutoCompletes();
    this.listaOrdenacao = this.obterColunasOrdenacoes();
    this.ordenacao = this.listaOrdenacao[0];
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id)
      .subscribe(dados => {
        this.listExercicio = dados.content;
        this.exercicio = this.login.exercicio;
      });
  }

  atualizarMascara() {
    new GlobalService().calendarMascara();
  }

  private carregarAutoCompletes() {
    this.setorAutoComplete = new EddyAutoComplete(null, this.setorService,
      'id', ['codigo', 'nome'], { orgao_id: this.login.orgao.id, relations: 'orgao', orderBy: 'nome' }, { text: ['nome', 'codigo'] }
    );

    this.usuarioAutoComplete = new EddyAutoComplete(null, this.usuarioService,
      'id', ['nome','sobrenome'], { orderBy: 'nome' }, { text: ['nome'] }
    );

    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['id', 'nome'], { orderBy: 'nome' }, { number: ['id'], text: ['nome', 'cpf_cnpj'] }
    );
  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  public titulo() {
    if (this.compra)
      return 'ORDEM DE FORNECIMENTO   ' + (this.compra?.numero ? ('00000' + this.compra.numero).slice(-5) + '/' + this.compra.exercicio.ano : '');
    return 'ORDENS DE FORNECIMENTO';
  }

  public obterColunasOrdenacoes(): { nome: string, coluna: string, ordem: 'DESC' | 'ASC' }[] {
    return [
      { coluna: 'numero', nome: 'Número', ordem: 'ASC' },
      { coluna: 'data_compra', nome: 'Data Compra', ordem: 'ASC' },
      { coluna: 'favorecido.nome', nome: 'Fornecedor', ordem: 'ASC' },
      { coluna: 'requerente.nome', nome: 'Requerente', ordem: 'ASC' },
    ];
  }

  public imprimir() {
    if (!this.validar())
      return;

    const parametros = {
      'orgao.id': this.login.orgao.id,
      'exercicio.id': this.exercicio.id,
      relations: ['ficha', 'modalidade', 'licitacao', 'subelemento', 'operador',
        'convenio', 'favorecido.tipo', 'contrato', 'ficha.despesa', 'ficha.acao', 'ficha.recurso', 'contrato_aditamento',
        'ficha.executora.unidade', 'ficha.aplicacao', 'exercicio', 'orgao', 'contrato', 'requerente', 'rcms.setor', 'prazo', 'empenho', 'empenho.exercicio',
        'itens.produto_unidade', 'itens.produto_unidade.produto', 'itens.produto_unidade.unidade', 
        'prazo_entrega', 'prazo_pagamento', 'setor'
      ].join(',')
    };

    if (this.setor) {
      parametros['setor.id'] = this.setor.id;
    }

    if (this.favorecido) {
      parametros['favorecido.id'] = this.favorecido.id;
    }

    if (this.usuario) {
      parametros['rcms.operador.id'] = this.usuario.id;
    }

    if (!this.compra?.id) {
      if (this.tipo === 'N') {
        if (this.numeroInicial && !this.numeroFinal) {
          parametros['numero'] = this.numeroInicial;
        } else {
          if (this.numeroInicial)
            parametros['numero$ge'] = this.numeroInicial;
          if (this.numeroFinal)
            parametros['numero$le'] = this.numeroFinal;
        }
      } else if (this.tipo === 'D') {
        if (this.dataInicial)
          parametros['data_compra$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
        if (this.dataFinal)
          parametros['data_compra$le'] = this.funcaoService.converteDataSQL(this.dataFinal);
        if (!this.dataInicial || !this.dataFinal)
          parametros['exercicio.id'] = this.login.exercicio.id;
      }
    } else {
      parametros['id'] = this.compra.id;
    }

    if (this.geral) {
      if (this.tipo ==='N') {
        if (this.ordenacao.coluna === 'numero') {
          parametros['orderBy'] = 'numero$DESC';
        } else {
          parametros['orderBy'] = `numero$DESC,${this.ordenacao.coluna}$${this.ordenacao.ordem}`;
        }
      } else if (this.tipo ==='D') {
        if (this.ordenacao.coluna === 'data_compra') {
          parametros['orderBy'] = 'data_compra$DESC';
        } else {
          parametros['orderBy'] = `data_compra$DESC,${this.ordenacao.coluna}$${this.ordenacao.ordem}`;
        }
      }
    } else {
      parametros['orderBy'] = `${this.ordenacao.coluna}$${this.ordenacao.ordem}`;
    }

    this.compraService.filtrar(1, -1, parametros).subscribe(async (data) => {
      if (!data?.content || data.content.length === 0) {
        toastr.warning(`Orden(s) de fornecimento não encontrada.`)
        return;
      }
      new NotaCompra(this.compraService, this.login, null, this.propostaService)
        .imprimir(data.content);
    }, (error) => toastr.error(`Foi identificado um erro na impressão da ordem de fornecimento.`));
  }

  private validar(): boolean {
    if (!this.compra?.id) {
      if (this.tipo === 'N') {
        if (!this.exercicio || !this.exercicio.id) {
          toastr.warning('Informe exercicio para imprimir relação');
          return false;
        }
        if (!this.numeroInicial && !this.numeroFinal) {
          toastr.warning(`Informe número de OF. para imprimir relação`);
          return false;
        } else if (this.numeroFinal && !this.numeroInicial) {
          toastr.warning(`Informe valor para ambos os campos de número de OF para buscar OF por intervalo ou informe 'Número inicial' para buscar especifica'`);
          return false;
        }
      } else if (this.tipo === 'D') {
        if (!this.dataInicial && !this.dataFinal) {
          toastr.warning(`Informe data inicial ou data final para busca de OF `);
          return false;
        }
      }
    }
    return true;
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }
}