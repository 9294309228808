import { DatePipe } from '@angular/common';
import { Component, ElementRef, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { PagamentoService, ContaBancariaService, LiquidacaoService, EmpenhoService } from 'administrativo-lib';
import {
  BaseResourceFormComponent, OrgaoAssinaturaService,
  DateFormatPipe, GlobalService, LoginContabil, Pagamento
} from 'eddydata-lib';
import { NotaPagamento } from '../../relatorio-tesouraria/notas/nota-pagamento';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-pagamento-view',
  templateUrl: './pagamento-view.component.html'
})
export class PagamentoViewComponent extends BaseResourceFormComponent<Pagamento, LoginContabil> {

  /**
   * Declaração de variáveis
   */


  /**
   * Construtor com as injeções de dependencias
   */
  public especie: string;
  public liquidado: number;
  public saldoPagar = 0;
  public saldoBancario = 0;

  constructor(
    private messageService: MessageService,
    protected injector: Injector,
    protected contaService: ContaBancariaService,
    protected liquidacaoService: LiquidacaoService,
    protected globalService: GlobalService,
    protected empenhoService: EmpenhoService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected pagamentoService: PagamentoService) {
    super(new Pagamento(), injector, Pagamento.converteJson, pagamentoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      valor_pago: 0,
      valor_retido: [null, [Validators.required]],
      data_pagamento: [null, [Validators.required]],
      data_referencia: [null],
      especie: [null, [Validators.required]],
      documento: [null, [Validators.required]],
      impresso: [null, [Validators.required]],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      anulacao: [null, [Validators.required]],
      conta: [null, [Validators.required]],
      exercicio: this.fb.group({
        ano: [null, [Validators.required]],
      }),
      orgao: [null, [Validators.required]],
      liquidacao: this.fb.group({
        data_vencimento: [null, [Validators.required]],
        valor_liquidado: [null, [Validators.required]],
        parcela: [null, [Validators.required]],
        empenho: this.fb.group({
          numero: [null, [Validators.required]],
          exercicio: this.fb.group({
            ano: [null, [Validators.required]],
          }),
        })
      })
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'conta,conta.banco,liquidacao,liquidacao.empenho,liquidacao.empenho.favorecido,' +
      'liquidacao.empenho.subelemento,liquidacao.empenho.ficha,liquidacao.empenho.convenio,liquidacao.empenho.ficha.executora,' +
      'liquidacao.empenho.ficha.acao,liquidacao.empenho.modalidade,exercicio,orgao,liquidacao.empenho.ficha.recurso,' +
      'liquidacao.empenho.ficha.aplicacao,liquidacao.empenho.ficha.aplicacao_variavel,conta_recurso,conta_recurso.recurso,conta_recurso.aplicacao,conta_recurso.convenio'
  };
  }

  protected afterInit(): void {
  }

  protected campoFoco(): ElementRef {

    return null;
  }

  protected afterLoad() {
    this.entidade.data_pagamento = new DateFormatPipe().transform(this.entidade.data_pagamento, []);
    this.buscarTotalLiquidado(this.entidade.liquidacao.empenho.numero);
    this.buscarSaldoBancario();
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: Pagamento): void {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public buscarSaldoBancario() {
    let datepipe =  new DatePipe('pt');
    const dataPagamento = datepipe.transform(this.entidade.data_pagamento, 'yyyy-MM-dd', 'GMT');
    this.contaService.obterSaldoBancarioConta(this.entidade.conta.id, this.login.exercicio.id, this.login.orgao.id, dataPagamento)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (saldoTotal: any) => {
          this.saldoBancario = saldoTotal;
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
      );
  }

  public buscarTotalLiquidado(numero: number) {
    this.liquidacaoService.obterTotalLiquidadoPorEmpenho(numero, this.login.exercicio.id, this.login.orgao.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res: any) => {
          this.liquidado = res.liquidado;
          this.buscarTotalPago(numero);
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
      );
  }

  public buscarTotalPago(numero: number) {
    this.pagamentoService.obterTotalPagoPorEmpenho(numero, this.login.exercicio.id, this.login.orgao.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res: any) => {
          this.saldoPagar = +this.liquidado - +res.pago;
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
      );
  }
  public imprimir() {
    const parametros = {};
    let relations = 'liquidacao.empenho.ficha.executora.unidade,liquidacao.empenho.ficha.despesa,liquidacao.empenho.ficha.funcao,liquidacao.empenho.ficha.subfuncao,liquidacao.empenho.ficha.programa,liquidacao.empenho.ficha.acao,liquidacao.empenho.ficha.recurso,liquidacao.empenho.ficha.aplicacao,';
    relations += 'liquidacao.empenho.licitacao,liquidacao.empenho.modalidade,liquidacao.empenho.subelemento,liquidacao.empenho.favorecido.contas.banco,liquidacao.empenho.favorecido.tipo,liquidacao.empenho.contrato,liquidacao.empenho.convenio,liquidacao.empenho.liquidacoes,liquidacao.empenho.compra,liquidacao.empenho.compra.rcms,liquidacao.empenho.compra.rcms.requerente,liquidacao.empenho.compra.rcms.prazo';

    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id'] = this.entidade.id;
    this.pagamentoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        new NotaPagamento(this.assinaturaService).imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/tesouraria']);
        break;
      default:
        this.sair();
        break;
    }
  }
}
