import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class TransferenciaEspecie extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public ativo?: boolean,
    public padrao?: boolean,
    public estorno?: boolean,
    public retencao?: boolean,
    public exige_descendio?: boolean,
    public evento?: string,
    public orgao?: Orgao
  ) {
    super();
  }

  static converteJson(json: any): TransferenciaEspecie {
    return Object.assign(new TransferenciaEspecie(), json);
  }
}
