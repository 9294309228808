import { AfterContentInit, Directive, Input } from '@angular/core';
import { EddyAutoComplete, GlobalService, Login, Pessoa } from 'eddydata-lib';

@Directive()
export abstract class BaseLicitacaoAudesp implements AfterContentInit {

    @Input() login: Login;

    public imaskQtd = {
        mask: Number,
        scale: 0,
        signed: false
    };
    public imaskConfig = {
        mask: Number,
        scale: 2,
        signed: true,
        thousandsSeparator: '.',
        padFractionalZeros: true,
        normalizeZeros: true,
        radix: ','
    };
    public opcoesSimNao: { id: 'S' | 'N', nome: string }[];
    public opcoesSimNaoNA: { id: 'S' | 'N' | 'NA', nome: string }[];
    public opcoesSimNA: { id: 'S' | 'NA', nome: string }[];
    public opcoesDoc: ('CPF' | 'CNPJ' | 'OUTRO')[];
    public opcoesReceitaDespesa: { id: 1 | 2, nome: string }[];
    public opcoesRecurso: { id: number, nome: string }[];
    public opcoesUF: { id: string, nome: string }[];
    public opcoesLicitante: { id: number, nome: string }[];
    public pessoaAutoComplete: EddyAutoComplete<Pessoa>;

    ngAfterContentInit(): void {
        this.login = GlobalService.obterSessaoLogin();
        this.opcoesSimNao = [
            { id: 'S', nome: 'Sim' },
            { id: 'N', nome: 'Não' },
        ]
        this.opcoesSimNaoNA = [
            { id: 'S', nome: 'Sim' },
            { id: 'N', nome: 'Não' },
            { id: 'NA', nome: 'Não se aplica' },
        ]
        this.opcoesSimNA = [
            { id: 'S', nome: 'Sim' },
            { id: 'NA', nome: 'Não se aplica' },
        ]
        this.opcoesDoc = ['CPF', 'CNPJ', 'OUTRO'];
        this.opcoesReceitaDespesa = [
            { id: 1, nome: 'Receita' },
            { id: 2, nome: 'Despesa' }
        ];
        this.opcoesLicitante = [
            { id: 1, nome: 'Convidado e Não-participante' },
            { id: 2, nome: 'Convidado e Participante' },
            { id: 3, nome: 'Não Convidado e Participante' }
        ];
        this.opcoesRecurso = [
            { id: 1, nome: 'TESOURO' },
            { id: 2, nome: 'TRANSFERÊNCIAS E CONVÊNIOS ESTADUAIS-VINCULADOS' },
            { id: 3, nome: 'RECURSOS PRÓPRIOS DE FUNDOS ESPECIAIS DE DESPESA-VINCULADOS' },
            { id: 4, nome: 'RECURSOS PRÓPRIOS DA ADMINISTRAÇÃO INDIRETA' },
            { id: 5, nome: 'TRANSFERÊNCIAS E CONVÊNIOS FEDERAIS-VINCULADOS' },
            { id: 6, nome: 'OUTRAS FONTES DE RECURSOS' },
            { id: 7, nome: 'OPERAÇÕES DE CRÉDITO' },
            { id: 8, nome: 'EMENDAS PARLAMENTARES INDIVIDUAIS' },
        ]
        this.opcoesUF = [
            { id: 'AC', nome: 'Acre' },
            { id: 'AL', nome: 'Alagoas' },
            { id: 'AM', nome: 'Amazonas' },
            { id: 'AP', nome: 'Amapá' },
            { id: 'BA', nome: 'Bahia' },
            { id: 'CE', nome: 'Ceará' },
            { id: 'DF', nome: 'Distrito Federal' },
            { id: 'ES', nome: 'Espirito Santo' },
            { id: 'GO', nome: 'Goias' },
            { id: 'MA', nome: 'Maranhão' },
            { id: 'MG', nome: 'Minas Gerais' },
            { id: 'MS', nome: 'Mato Grosso do Sul' },
            { id: 'MT', nome: 'Mato Grosso' },
            { id: 'PA', nome: 'Pará' },
            { id: 'PB', nome: 'Paraíba' },
            { id: 'PE', nome: 'Pernambuco' },
            { id: 'PI', nome: 'Piauí' },
            { id: 'PR', nome: 'Paraná' },
            { id: 'RJ', nome: 'Rio de Janeiro' },
            { id: 'RN', nome: 'Rio Grande do Norte' },
            { id: 'RO', nome: 'Rondônia' },
            { id: 'RR', nome: 'Roraima' },
            { id: 'RS', nome: 'Rio Grande do Sul' },
            { id: 'SC', nome: 'Santa Catarina' },
            { id: 'SE', nome: 'Sergipe' },
            { id: 'SP', nome: 'São Paulo' },
            { id: 'TO', nome: 'Tocantins' },
        ]
    }

    public compareFn(c1: any, c2: any): boolean {
        return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
    }

    public onFocusDate() {
        new GlobalService().calendarMascara();
    }
}