import { RadioButtonModule } from 'primeng/radiobutton';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RelatorioPrestacaoRoutingModule } from './relatorio-prestacao-routing.module';
import { RelatorioPrestacaoComponent } from './relatorio-prestacao.component';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { SharedModule } from 'eddydata-lib';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FieldsetModule } from 'primeng/fieldset';

@NgModule({
  declarations: [RelatorioPrestacaoComponent],
  imports: [
    CommonModule,
    RelatorioPrestacaoRoutingModule,
    SharedModule,
    FormsModule,
    CommonModule,
    CalendarModule,
    AutoCompleteModule,
    RadioButtonModule,
    FieldsetModule
  ]
})
export class RelatorioPrestacaoModule { }
