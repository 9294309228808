import { Directive, OnDestroy, Injector, } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AnexoLrfFederalService } from '../../../service/anexo-lrf-federal.service';
import { Assinaturas } from '../../../../assinaturas/assinaturas';

@Directive()
export class Anexo2RreoLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoFederalServico: AnexoLrfFederalService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected assinaturaControleInterno: boolean) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
    this.anexoFederalServico.obterAnexo2(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(null, this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            await this.conteudo(dados),
            'portrait', 'ANEXO2 - EXECUÇÃO DAS DESPESAS POR FUNÇÃO/SUBFUNÇÃO',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 2;
                },
                paddingRight() {
                  return 2;
                }
              }
            });
        } else {
          this.funcaoService.exportar(formato, this.normalizar(dados), 'ANEXO2 - EXECUÇÃO DAS DESPESAS POR FUNÇÃO/SUBFUNÇÃO', this.colunas());
        }
      });
  }

  private async conteudo(dados: any[]): Promise<{}[]> {
    // monta o cabecalho
    const registros: {}[] = [
      [
        { text: 'RELATÓRIO RESUMIDO DA EXECUÇÃO ORÇAMENTÁRIA', bold: true, border: [false, false, false, false], colSpan: 12 },
        '', '', '', '', '', '', '', '', '', '', ''],
      [
        { text: 'DEMONSTRATIVO DA EXECUÇÃO DAS DESPESAS POR FUNÇÃO/SUBFUNÇÃO', bold: true, border: [false, false, false, false], colSpan: 12 },
        '', '', '', '', '', '', '', '', '', '', ''],
      [
        { text: 'ORÇAMENTOS FISCAL E DA SEGURIDADE SOCIAL', bold: true, border: [false, false, false, false], colSpan: 12 },
        '', '', '', '', '', '', '', '', '', '', ''],
      [
        { text: `REFERÊNCIA: ${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano}`, bold: true, border: [false, false, false, false], colSpan: 12 },
        '', '', '', '', '', '', '', '', '', '', ''],
      [
        { text: 'RREO - Anexo 2 (LRF, Art. 52, inciso II, alínea "c")', bold: true, border: [false, false, false, false], colSpan: 12 },
        '', '', '', '', '', '', '', '', '', '', ''
      ],
      [
        { text: 'FUNÇÃO/SUBFUNÇÃO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 5 },
        { text: 'DOTAÇÃO INICIAL', alignment: 'center', rowSpan: 2, bold: true, fontSize: 5 },
        { text: 'DOTAÇÃO ATUALIZADA (a)', alignment: 'center', rowSpan: 2, bold: true, fontSize: 5 },
        { text: 'DESPESAS EMPENHADAS', alignment: 'center', colSpan: 3, bold: true, fontSize: 5 },
        { text: '' },
        { text: '' },
        { text: 'SALDO (c)=(a-b)', alignment: 'center', rowSpan: 2, bold: true, fontSize: 5 },
        { text: 'DESPESAS LIQUIDADAS', alignment: 'center', colSpan: 3, bold: true, fontSize: 5 },
        { text: '' },
        { text: '' },
        { text: 'SALDO (e)=(a-d)', alignment: 'center', rowSpan: 2, bold: true, fontSize: 5 },
        { text: 'INSCRITAS EM RESTOS A PAGAR NÃO PROCESSADOS (f)', alignment: 'center', rowSpan: 2, bold: true, fontSize: 5 }
      ],
      ['', '', '',
        { text: 'NO BIMESTRE', alignment: 'center', bold: true, fontSize: 5 },
        { text: 'ATÉ O BIMESTRE (b)', alignment: 'center', bold: true, fontSize: 5 },
        { text: '% (b/total b)', alignment: 'center', bold: true, fontSize: 5 },
        '',
        { text: 'NO BIMESTRE', alignment: 'center', bold: true, fontSize: 5 },
        { text: 'ATÉ O BIMESTRE (d)', alignment: 'center', bold: true, fontSize: 5 },
        { text: '% (b/total d)', alignment: 'center', bold: true, fontSize: 5 }
      ]
    ];

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);

    const titulos = this.funcaoService.agrupar(dados, 'titulo',
      ['dotacao_inicial', 'credito', 'empenhado_bim', 'empenhado', 'liquidado_bim', 'liquidado', 'resto']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    let total7 = 0;
    for (const titulo of titulos) {
      registros.push([
        { text: titulo.grupo, fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['dotacao_inicial']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['credito']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['empenhado_bim']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['empenhado']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['empenhado_bim'] / +titulo.totalizadores['empenhado']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['credito'] - +titulo.totalizadores['empenhado']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['liquidado_bim']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['liquidado']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['liquidado_bim'] / +titulo.totalizadores['liquidado']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['credito'] - +titulo.totalizadores['liquidado']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['resto']), alignment: 'right', fontSize: 5, bold: true }
      ]);
      total1 += +titulo.totalizadores['dotacao_inicial'];
      total2 += +titulo.totalizadores['credito'];
      total3 += +titulo.totalizadores['empenhado_bim'];
      total4 += +titulo.totalizadores['empenhado'];
      total5 += +titulo.totalizadores['liquidado_bim'];
      total6 += +titulo.totalizadores['liquidado'];
      total7 += +titulo.totalizadores['resto'];
      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo',
        ['dotacao_inicial', 'credito', 'empenhado_bim', 'empenhado', 'liquidado_bim', 'liquidado', 'resto']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          registros.push([
            { text: grupo.grupo, fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_inicial']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['credito']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado_bim']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['empenhado_bim'] / +grupo.totalizadores['empenhado']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['credito'] - +grupo.totalizadores['empenhado']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado_bim']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['liquidado_bim'] / +grupo.totalizadores['liquidado']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['credito'] - +grupo.totalizadores['liquidado']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['resto']), alignment: 'right', fontSize: 5, bold: true }
          ]);


          for (const item of grupo.registros) {
            if (item.nome != '') {
              registros.push([
                {
                  text: item.nome, fontSize: 5
                },
                { text: this.funcaoService.convertToBrNumber(item.dotacao_inicial), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.credito), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.empenhado_bim), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.empenhado), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(+item.empenhado_bim / +item.empenhado), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(+item.credito - +item.empenhado), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.liquidado_bim), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.liquidado), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(+item.liquidado_bim / +item.liquidado), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(+item.credito - +item.liquidado), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.resto), alignment: 'right', fontSize: 5 }
              ]);
            }
          }

        }
      }
    }

    registros.push([
      {
        text: 'TOTAL (III) = (I + II)', fontSize: 5, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total4), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total3 / +total4), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total2 - +total4), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total5), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total6), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total5 / +total6), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total2 - +total6), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total7), alignment: 'right', fontSize: 5, bold: true }
    ]);

    return [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 40, 40, 40, 40, 25, 40, 40, 40, 25, 40, 40],
          body: registros
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*'],
          body: assinaturas
        }
      }

    ];
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'FUNÇÃO/SUBFUNÇÃO', coluna: 'funcao_subfuncao', bold: true },
      { titulo: 'DOTAÇÃO INICIAL', coluna: 'dotacao_inicial' },
      { titulo: 'DOTAÇÃO ATUALIZADA (a)', coluna: 'dotacao_atualizada' },
      { titulo: 'DESPESAS EMPENHADAS', coluna: 'empenhadas_no_bimestre' },
      { titulo: '', coluna: 'empenhadas_ate_bimestre' },
      { titulo: '', coluna: 'empenhadas_total' },
      { titulo: 'SALDO (c)=(a-b)', coluna: 'saldo_c' },
      { titulo: 'DESPESAS LIQUIDADAS', coluna: 'liquidadas_no_bimestre' },
      { titulo: '', coluna: 'liquidadas_ate_bimestre' },
      { titulo: '', coluna: 'liquidadas_total' },
      { titulo: 'SALDO (e)=(a-d)', coluna: 'saldo_e' },
      { titulo: 'INSCRITAS EM RESTOS A PAGAR NÃO PROCESSADOS (f)', coluna: 'restos_np' },
    ];
    return colunasDefault;
  }

  public normalizar(lista) {
    const listaExportar = []
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    let total7 = 0;

    const titulos = this.funcaoService.agrupar(lista, 'titulo',
      ['dotacao_inicial', 'credito', 'empenhado_bim', 'empenhado', 'liquidado_bim', 'liquidado', 'resto']);

      const primeira_linha = {
        funcao_subfuncao: '',
        dotacao_inicial: '',
        dotacao_atualizada: '',
        empenhadas_no_bimestre: 'NO BIMESTRE ',
        empenhadas_ate_bimestre: 'ATÉ O BIMESTRE (b)',
        empenhadas_total: '% (b/total b)',
        saldo_c: '',
        liquidadas_no_bimestre: 'NO BIMESTRE',
        liquidadas_ate_bimestre: 'ATÉ O BIMESTRE (d)',
        liquidadas_total: '% (b/total d)',
        saldo_e: '',
        restos_np: ''
      }
      listaExportar.push(primeira_linha)

    for (const titulo of titulos) {

      const linha_titulo = {
        funcao_subfuncao: titulo.grupo,
        dotacao_inicial: this.funcaoService.convertToBrNumber(titulo.totalizadores['dotacao_inicial']),
        dotacao_atualizada: this.funcaoService.convertToBrNumber(titulo.totalizadores['credito']),
        empenhadas_no_bimestre: this.funcaoService.convertToBrNumber(titulo.totalizadores['empenhado_bim']),
        empenhadas_ate_bimestre: this.funcaoService.convertToBrNumber(titulo.totalizadores['empenhado']),
        empenhadas_total: this.funcaoService.convertToBrNumber(+titulo.totalizadores['empenhado_bim'] / +titulo.totalizadores['empenhado']),
        saldo_c: this.funcaoService.convertToBrNumber(+titulo.totalizadores['credito'] - +titulo.totalizadores['empenhado']),
        liquidadas_no_bimestre: this.funcaoService.convertToBrNumber(titulo.totalizadores['liquidado_bim']),
        liquidadas_ate_bimestre: this.funcaoService.convertToBrNumber(titulo.totalizadores['liquidado']),
        liquidadas_total: this.funcaoService.convertToBrNumber(+titulo.totalizadores['liquidado_bim'] / +titulo.totalizadores['liquidado']),
        saldo_e: this.funcaoService.convertToBrNumber(+titulo.totalizadores['credito'] - +titulo.totalizadores['liquidado']),
        restos_np: this.funcaoService.convertToBrNumber(titulo.totalizadores['resto']),
      }
      listaExportar.push(linha_titulo)

      total1 += +titulo.totalizadores['dotacao_inicial'];
      total2 += +titulo.totalizadores['credito'];
      total3 += +titulo.totalizadores['empenhado_bim'];
      total4 += +titulo.totalizadores['empenhado'];
      total5 += +titulo.totalizadores['liquidado_bim'];
      total6 += +titulo.totalizadores['liquidado'];
      total7 += +titulo.totalizadores['resto'];

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo',
        ['dotacao_inicial', 'credito', 'empenhado_bim', 'empenhado', 'liquidado_bim', 'liquidado', 'resto']);

      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          const linha_grupo = {
            funcao_subfuncao: grupo.grupo,
            dotacao_inicial: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_inicial']),
            dotacao_atualizada: this.funcaoService.convertToBrNumber(grupo.totalizadores['credito']),
            empenhadas_no_bimestre: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado_bim']),
            empenhadas_ate_bimestre: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']),
            empenhadas_total: this.funcaoService.convertToBrNumber(+grupo.totalizadores['empenhado_bim'] / +grupo.totalizadores['empenhado']),
            saldo_c: this.funcaoService.convertToBrNumber(+grupo.totalizadores['credito'] - +grupo.totalizadores['empenhado']),
            liquidadas_no_bimestre: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado_bim']),
            liquidadas_ate_bimestre: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']),
            liquidadas_total: this.funcaoService.convertToBrNumber(+grupo.totalizadores['liquidado_bim'] / +grupo.totalizadores['liquidado']),
            saldo_e: this.funcaoService.convertToBrNumber(+grupo.totalizadores['credito'] - +grupo.totalizadores['liquidado']),
            restos_np: this.funcaoService.convertToBrNumber(grupo.totalizadores['resto']),
          }
          listaExportar.push(linha_grupo)
        }

        for (const item of grupo.registros) {
          if (item.nome != '') {
            const linha_item = {
              funcao_subfuncao: item.nome,
              dotacao_inicial: this.funcaoService.convertToBrNumber(item.dotacao_inicial),
              dotacao_atualizada: this.funcaoService.convertToBrNumber(item.credito),
              empenhadas_no_bimestre: this.funcaoService.convertToBrNumber(item.empenhado_bim),
              empenhadas_ate_bimestre: this.funcaoService.convertToBrNumber(item.empenhado),
              empenhadas_total: this.funcaoService.convertToBrNumber(+item.empenhado_bim / +item.empenhado),
              saldo_c: this.funcaoService.convertToBrNumber(+item.credito - +item.empenhado),
              liquidadas_no_bimestre: this.funcaoService.convertToBrNumber(item.liquidado_bim),
              liquidadas_ate_bimestre: this.funcaoService.convertToBrNumber(item.liquidado),
              liquidadas_total: this.funcaoService.convertToBrNumber(+item.liquidado_bim / +item.liquidado),
              saldo_e: this.funcaoService.convertToBrNumber(+item.credito - +item.liquidado),
              restos_np: this.funcaoService.convertToBrNumber(item.resto),
            }
            listaExportar.push(linha_item)
          }
        }
      }

      const linha_infra2 = {
        funcao_subfuncao: 'DESPESAS (INTRA-ORÇAMENTÁRIAS) (II)',
        dotacao_inicial: this.funcaoService.convertToBrNumber(total1),
        dotacao_atualizada: this.funcaoService.convertToBrNumber(total2),
        empenhadas_no_bimestre: this.funcaoService.convertToBrNumber(total3),
        empenhadas_ate_bimestre: this.funcaoService.convertToBrNumber(total4),
        empenhadas_total: this.funcaoService.convertToBrNumber(+total3 / +total4),
        saldo_c: this.funcaoService.convertToBrNumber(+total2 - +total4),
        liquidadas_no_bimestre: this.funcaoService.convertToBrNumber(total5),
        liquidadas_ate_bimestre: this.funcaoService.convertToBrNumber(total6),
        liquidadas_total: this.funcaoService.convertToBrNumber(+total5 / +total6),
        saldo_e: this.funcaoService.convertToBrNumber(+total2 - +total6),
        restos_np: this.funcaoService.convertToBrNumber(total7),
      }
      listaExportar.push(linha_infra2)

      const linha_infra_2 = {
        funcao_subfuncao: 'DESPESAS (INTRA-ORÇAMENTÁRIAS) (II)',
        dotacao_inicial: this.funcaoService.convertToBrNumber(0),
        dotacao_atualizada: this.funcaoService.convertToBrNumber(0),
        empenhadas_no_bimestre: this.funcaoService.convertToBrNumber(0),
        empenhadas_ate_bimestre: this.funcaoService.convertToBrNumber(0),
        empenhadas_total: this.funcaoService.convertToBrNumber(0),
        saldo_c: this.funcaoService.convertToBrNumber(0),
        liquidadas_no_bimestre: this.funcaoService.convertToBrNumber(0),
        liquidadas_ate_bimestre: this.funcaoService.convertToBrNumber(0),
        liquidadas_total: this.funcaoService.convertToBrNumber(0),
        saldo_e: this.funcaoService.convertToBrNumber(0),
        restos_np: this.funcaoService.convertToBrNumber(0),
      }
      listaExportar.push(linha_infra_2)

      const linha_total = {
        funcao_subfuncao: 'TOTAL (III) = (I + II)',
        dotacao_inicial: this.funcaoService.convertToBrNumber(total1),
        dotacao_atualizada: this.funcaoService.convertToBrNumber(total2),
        empenhadas_no_bimestre: this.funcaoService.convertToBrNumber(total3),
        empenhadas_ate_bimestre: this.funcaoService.convertToBrNumber(total4),
        empenhadas_total: this.funcaoService.convertToBrNumber(+total3 / +total4),
        saldo_c: this.funcaoService.convertToBrNumber(+total2 - +total4),
        liquidadas_no_bimestre: this.funcaoService.convertToBrNumber(total5),
        liquidadas_ate_bimestre: this.funcaoService.convertToBrNumber(total6),
        liquidadas_total: this.funcaoService.convertToBrNumber(+total5 / +total6),
        saldo_e: this.funcaoService.convertToBrNumber(+total2 - +total6),
        restos_np: this.funcaoService.convertToBrNumber(total7),
      }
      listaExportar.push(linha_total)

    }
    return listaExportar
  }
}