// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Cidade } from '../geo/cidade.model';

export class Partido extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public sigla?: string,
    public presidente?: string,
    public endereco?: string,
    public bairro?: string,
    public telefone?: string,
    public email?: string,
    public cidade?: Cidade
  ) {
    super();
  }
  static converteJson(json: any): Partido {
    return Object.assign(new Partido(), json);
  }
}
