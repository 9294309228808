import { FuncaoService, GlobalService, Login, PedidoCompra, PedidoCompraItem, Relatorio } from "eddydata-lib";

export class NotaPedidoCompra {

  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;

  private prefeito: string;
  private cargo_prefeito: string;

  constructor(
    private login: Login
  ) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
  }

  public async exportar(model: PedidoCompra[]): Promise<Blob> {
    return Relatorio.exportarPersonalizado('PEDIDO DE COMPRA', this.login.usuario.nome, this.login.usuario.sobrenome,
      this.login.orgao.nome, this.login.brasao,
      this.montarConteudo(model),
      'portrait', 'PEDIDO DE COMPRA', this.layout(), true, false);
  }

  public async imprimir(model: PedidoCompra[]) {
    Relatorio.imprimirPersonalizado('PEDIDO DE COMPRA', this.login.usuario.nome, this.login.usuario.sobrenome,
      this.login.orgao.nome, this.login.brasao,
      this.montarConteudo(model),
      'portrait', 'PEDIDO DE COMPRA', this.layout(), true, false);
  }

  private montarConteudo(lista: PedidoCompra[]) {

    const conteudo = [];

    for (const entidade of lista) {
      if (conteudo.length > 0) {
        conteudo.push([{ text: '', pageBreak: 'after' }]);
      }

      conteudo.push(
        this.dadosCabecalho(this.login, entidade)
          .concat(this.dadosPedido(entidade))
          .concat(this.dadosRequerente(entidade))
          .concat(this.dadosItens(entidade))
          .concat(this.dadosObservacao(entidade))
          .concat(this.dadosAssinatura(entidade, this.login))
      );
    }
    return conteudo;
  }

  private dadosCabecalho(login: Login, dados: PedidoCompra): {}[] {
    let brasaoImage: {};
    if (login.brasao) {
      brasaoImage = {
        image: login.brasao,
        width: 70,
        alignment: 'center',
        margin: [0, 0, 0, 0],
        border: [true, true, true, false]
      };
    } else {
      brasaoImage = { margin: [0, 10, 0, 0], text: '', border: [true, true, true, false] };
    }
    const conteudo = [
      [brasaoImage],
      [{ text: login.orgao.nome, bold: true, alignment: 'center', fontSize: 13, border: [true, false, true, false] }],
      [{ text: 'PEDIDO DE COMPRA', bold: true, alignment: 'center', fontSize: 12, border: [true, false, true, false] }],
      [{ text: dados.data_pedido ? this.funcaoService.converteDataBR(dados.data_pedido) : '', bold: false, alignment: 'center', fontSize: 12, border: [true, false, true, false] }]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosPedido(dados: PedidoCompra): {}[] {
    const conteudo = [
      [
        { text: 'PEDIDO Nº.:', fontSize: 7, border: [true, true, false, false] },
        { text: 'OF.:', fontSize: 7, border: [true, true, false, false] },
        { text: 'SEQ.:', fontSize: 7, border: [true, true, false, false] },
        { text: 'REQUISIÇÃO:', fontSize: 7, border: [true, true, false, false] },
        { text: 'PROCESSO:', fontSize: 7, border: [true, true, false, false] },
        { text: 'LICITAÇÃO:', fontSize: 7, border: [true, true, false, false] },
        { text: 'MODALIDADE:', fontSize: 7, border: [true, true, true, false] },
        { text: 'CONTRATO:', fontSize: 7, border: [true, true, true, false] }
      ], [
        { text: dados?.numero, alignment: 'center', bold: true, border: [true, false, false, true] },
        { text: `${dados?.compra?.numero}/${dados?.compra?.exercicio?.ano}`, alignment: 'center', bold: true, border: [true, false, false, true] },
        { text: dados?.sequencia, alignment: 'center', bold: true, border: [true, false, false, true] },
        { text: dados?.compra?.rcms ? `${dados?.compra?.rcms?.numero}/${dados?.compra?.rcms?.exercicio?.ano}` : '', alignment: 'center', bold: true, border: [true, false, false, true] },
        { text: dados?.compra?.processo ? dados?.compra?.processo : '', alignment: 'center', bold: true, border: [true, false, false, true] },
        { text: dados?.compra?.licitacao ? `${dados?.compra?.licitacao?.numero}` : '', alignment: 'center', bold: true, border: [true, false, false, true] },
        { text: dados?.compra?.modalidade?.nome, alignment: 'center', bold: true, border: [true, false, true, true] },
        { text: dados?.compra?.contrato ? `${dados?.compra?.contrato?.numero}` : '', alignment: 'center', bold: true, border: [true, false, true, true] }
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['auto', 'auto', 'auto', 'auto', 60, 'auto', '*', 65],
        body: conteudo
      }
    }];
  }

  private dadosRequerente(dados: PedidoCompra): {}[] {
    const conteudo = [
      [
        { text: 'Requerente: ', fontSize: 7, alignment: 'left', bold: true, border: [true, false, false, false] },
        { text: dados?.compra?.requerente?.nome, fontSize: 7, bold: true, border: [false, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] }
      ], [
        { text: 'Endereço: ', fontSize: 7, alignment: 'left', border: [true, false, false, true] },
        { text: dados?.compra?.requerente?.endereco ? `${dados?.compra?.requerente?.endereco} - ${dados?.compra?.requerente?.complemento} ${dados?.compra?.requerente?.numero} - ${dados?.compra?.requerente?.cidade?.nome}` : '', fontSize: 7, border: [false, false, false, true] },
        { text: 'Telefone: ', fontSize: 7, border: [false, false, false, true] },
        { text: dados?.compra?.requerente?.telefone, fontSize: 7, alignment: 'center', border: [false, false, true, true] }
      ], [
        { text: 'Fornecedor: ', fontSize: 7, alignment: 'left', bold: true, border: [true, false, false, false] },
        { text: dados?.compra?.favorecido?.nome, fontSize: 7, bold: true, border: [false, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] }
      ], [
        { text: 'Endereço: ', fontSize: 7, alignment: 'left', border: [true, false, false, true] },
        { text: dados?.compra?.favorecido?.endereco ? `${dados?.compra?.favorecido?.endereco} - ${dados?.compra?.favorecido?.complemento ? dados?.compra?.favorecido?.complemento : ''} ${dados?.compra?.favorecido?.num_endereco} - ${dados?.compra?.favorecido?.cidade?.nome}` : '', fontSize: 7, border: [false, false, false, true] },
        { text: 'Telefone: ', fontSize: 7, border: [false, false, false, true] },
        { text: dados?.compra?.favorecido?.telefone, fontSize: 7, alignment: 'center', border: [false, false, true, true] }
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['auto', '*', 'auto', 65],
        body: conteudo
      }
    }];
  }

  private dadosItens(dados: PedidoCompra): {}[] {
    let total = 0;
    const conteudo = [
      [
        { text: 'QUANTIDADE', fontSize: 9, alignment: 'center', bold: true, border: [true, false, false, true] },
        { text: 'CÓDIGO', fontSize: 9, alignment: 'center', bold: true, border: [true, false, false, true] },
        { text: 'UN', fontSize: 9, alignment: 'center', bold: true, border: [true, false, false, true] },
        { text: 'DESCRIÇÃO', fontSize: 9, alignment: 'center', bold: true, border: [true, false, false, true] },
        { text: 'VL. UNITÁRIO', fontSize: 9, alignment: 'center', bold: true, border: [true, false, false, true] },
        { text: 'VL. TOTAL', fontSize: 9, alignment: 'center', bold: true, border: [true, false, true, true] }
      ]
    ];

    dados.itens.forEach((item: PedidoCompraItem) => {
      let valor = (item?.quantidade || 0) * (item?.item?.valor_unitario || 0);
      total += valor;
      conteudo.push(
        [
          { text: item?.quantidade + '', fontSize: 9, bold: false, alignment: 'right', border: [true, false, false, false] },
          { text: item?.item?.produto_unidade?.produto?.codigo ? item?.item?.produto_unidade?.produto?.codigo : '', fontSize: 9, bold: false, alignment: 'right', border: [true, false, false, false] },
          { text: item?.item?.produto_unidade?.unidade?.nome ? item?.item?.produto_unidade?.unidade?.nome : item?.item?.unidade, fontSize: 9, bold: false, alignment: 'center', border: [true, false, false, false] },
          { text: item?.item?.produto_unidade?.produto?.descricao ? item?.item?.produto_unidade?.produto?.descricao : item?.item?.produto, fontSize: 9, alignment: 'left', bold: false, border: [true, false, false, false] },
          { text: this.funcaoService.convertToBrNumber(item?.item?.valor_unitario), fontSize: 9, bold: false, alignment: 'right', border: [true, false, false, false] },
          { text: this.funcaoService.convertToBrNumber(valor), fontSize: 9, bold: false, alignment: 'right', border: [true, false, true, false] }
        ]
      )
    });

    conteudo.push(
      [
        { text: 'TOTAL GERAL:', fontSize: 9, alignment: 'left', bold: true, border: [true, true, false, true] },
        { text: '', fontSize: 9, alignment: 'left', bold: true, border: [false, true, false, true] },
        { text: '', fontSize: 9, alignment: 'left', bold: true, border: [false, true, false, true] },
        { text: '', fontSize: 9, alignment: 'left', bold: true, border: [false, true, false, true] },
        { text: '', fontSize: 9, alignment: 'left', bold: true, border: [false, true, false, true] },
        { text: this.funcaoService.convertToBrNumber(total), fontSize: 9, alignment: 'right', bold: true, border: [true, true, true, true] }
      ]
    );

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['auto', 'auto', 20, '*', 70, 70],
        body: conteudo
      }
    }];
  }

  private dadosObservacao(dados: PedidoCompra): {}[] {
    const conteudo = [
      [
        { text: 'OBSERVAÇÃO: ', fontSize: 9, alignment: 'left', bold: true, border: [true, false, true, false] }

      ], [
        { text: dados.observacao ? dados.observacao : '', fontSize: 9, alignment: 'justify', border: [true, false, true, true] }
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosAssinatura(dados: PedidoCompra, log: Login): {}[] {
    const conteudo = [
      [
        { text: '', border: [true, false, false, false], bold: true },
        {
          type: 'none', border: [false, false, false, false], margin: [0, 10, 0, 0],
          ol: [
            { text: '', alignment: 'left' },
            { text: '_____________________________________________________', alignment: 'center' }
          ]
        },
        { text: '', border: [false, false, true, false] }
      ],
      [
        { text: '', border: [true, false, false, false], },
        { text: 'Autorizado', border: [false, false, false, false], alignment: 'center' },
        { text: '', border: [false, false, true, false] }
      ],
      [
        {
          text: [
            { text: `OPERADOR     `, bold: true }, { text: log.usuario?.nome?.toUpperCase() }
          ], border: [true, false, false, true]
        },
        { text: `${this.prefeito ? this.prefeito : ''} ${this.cargo_prefeito ? '- ' + this.cargo_prefeito : ''}`, border: [false, false, false, true] },
        { text: '', border: [false, false, true, true] }
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [10, 0],
        widths: ['*', 200, 10],
        body: conteudo
      }
    }];
  }

  private layout() {
    return {
      linhas: {
        hLineWidth() {
          return 1;
        },
        vLineWidth() {
          return 1;
        },
        hLineColor() {
          return 'black';
        },
        paddingLeft() {
          return 3;
        },
        paddingRight() {
          return 3;
        }
      }
    };
  }
}