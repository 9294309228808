import { BaseResourceModel } from "../../models/base-resource.model";
import { TipoContratacao } from "./tipo-contratacao.model";

export class TipoContratacaoCondicaoDespesa extends BaseResourceModel {
    constructor(
        public id?: number,
        public condicao?: string,
        public tipo_contratacao?: TipoContratacao
    ) {
        super();
    }

    static converteJson(json: any): TipoContratacaoCondicaoDespesa {
        return Object.assign(new TipoContratacaoCondicaoDespesa(), json);
    }
}
