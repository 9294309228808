import { Injectable, Injector } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseResourceService, CreditoItem, Page } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class CreditoItemService extends BaseResourceService<CreditoItem> {

  constructor(
    protected injector: Injector
  ) {
    super(`creditos-itens`, injector);
  }

  public obterPorCredito(id: number): Observable<Page> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/filtrar/?relations=ficha,ficha.despesa,ficha.funcao,ficha.subfuncao,ficha.programa,ficha.acao,ficha.executora,ficha.recurso,ficha.aplicacao,ficha.aplicacao_variavel,previsoes,previsoes.ficha,previsoes.ficha.receita&credito_id=${id}&orderBy=id`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
