import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { TipoConvenioListComponent } from './tipo-convenio-list/tipo-convenio-list.component';
import { TipoConvenioFormComponent } from './tipo-convenio-form/tipo-convenio-form.component';
import { TipoConvenioViewComponent } from './tipo-convenio-view/tipo-convenio-view.component';


const routes: Routes = [
  { path: '', component: TipoConvenioListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: TipoConvenioFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: TipoConvenioFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: TipoConvenioViewComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TipoConvenioRoutingModule { }
