import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContaPublicaAnexoService, ContaPublicaTipoService } from 'administrativo-lib';
import { BaseResourceListComponent, Coluna, ContaPublicaAnexo, ContaPublicaTipo, Exercicio, ExercicioService, Filtro, GlobalService, LoginContabil } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { tsXLXS } from 'ts-xlsx-export';

declare var $: any;

@Component({
  selector: 'app-transparencia-conta-upload',
  templateUrl: './transparencia-conta-upload.component.html',
  styleUrls: ['./transparencia-conta-upload.component.css']
})
export class TransparenciaContaUploadComponent extends BaseResourceListComponent<ContaPublicaAnexo, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public ptBR: any;
  public listaTipos: ContaPublicaTipo[];
  public tipo: number;
  public especie: 'CP' | 'PLAN';
  public datepipe: DatePipe;
  public data1 = new Date(`01/01/${new Date().getFullYear()}`);
  public data2 = new Date();

  public selectExercicio: number;
  public listaExercicios: Array<Exercicio>;

  public entidade = new ContaPublicaAnexo();
  public uploadedFiles: any[] = [];

  public AnexoExistente = new ContaPublicaAnexo();

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private route: ActivatedRoute,
    protected injector: Injector,
    protected exercicioService: ExercicioService,
    private messageService: MessageService,
    private globalService: GlobalService,
    private tipoService: ContaPublicaTipoService,
    private anexoService: ContaPublicaAnexoService) {
    super(anexoService, injector);
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.especie = params['especie'];
      });
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'orgao,tipo';
  }

  protected condicoesGrid(): {} {
    return {};
  }

  protected ordenacaoGrid(): string[] {
    return ['ano', 'referencia', 'data_anexo'];
  }

  protected filtrosGrid(): Filtro {
    return {};
  }

  public beforeInit(): void {
    if (this.login.exercicio) {
      this.data1 = new Date(`01/01/${this.login.exercicio.ano}`);
      if (this.login.exercicio.ano !== new Date().getFullYear()) {
        this.data2 = new Date(`12/31/${this.login.exercicio.ano}`);
      }
      this.selectExercicio = this.login.exercicio.ano;
    }
  }

  protected afterInit(): void {
    window.scrollTo(0, 0);
    this.ptBR = this.globalService.obterDataBR();
    this.preencherExercicio();
    this.tipoService.filtrar(0, 0, { especie: this.especie, orderBy: 'nome' }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaTipos = res.content;
        this.listaTipos = this.listaTipos.sort((a, b) => {
          if (this.verificarPeriodo(a.id) > this.verificarPeriodo(b.id)) {
            return 1;
          }
          if (this.verificarPeriodo(a.id) < this.verificarPeriodo(b.id)) {
            return -1;
          }
          return 0;
        });
      });
  }

  public verificarPeriodo(id: number) {
    switch (id) {
      case 1:
      case 9:
      case 20:
        return 1;
      case 6:
      case 7:
      case 8:
      case 15:
      case 16:
      case 17:
      case 18:
      case 19:
        return 2;
      case 21:
        return 3;
      case 3:
      case 4:
      case 5:
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
      case 23:
        return 4;
    }
  }

  protected acaoRemover(model: ContaPublicaAnexo): Observable<ContaPublicaAnexo> {
    return null;
  }

  public obterParametros() {
    const parametros = {};
    this.datepipe = new DatePipe('pt');
    parametros['ignoreCondObrig'] = true;
    parametros['ano'] = this.selectExercicio;
    parametros['relations'] = this.relations();
    parametros['especie'] = this.especie;
    if (this.tipo) {
      parametros['tipo_id'] = this.tipo;
    }
    if (this.filtro) {
      parametros['OR'] =
        // tslint:disable-next-line: max-line-length
        `ano=${this.filtro};!;!;nome$like=${this.filtro}%;!;!;descricao$like=${this.filtro}%;!;!;tamanho=${this.filtro}`;
    }

    switch (this.col) {
      case 1:
        parametros['orderBy'] = `data_anexo$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 2:
        parametros['orderBy'] = `ano$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 3:
        parametros['orderBy'] = `referencia$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 4:
        parametros['orderBy'] = `nome$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 5:
        parametros['orderBy'] = `orgao.nome$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 6:
        parametros['orderBy'] = `tamanho$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      default:
        break;
    }
    return parametros;
  }

  public preencherGrid() {
    const parametros = this.obterParametros();
    this.anexoService
      .filtrar(
        this.paginaCorrente,
        this.login.limite,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.lista = lista.content;
          this.paginaTotal = lista.totalPages;

          if (this.paginaTotal > 0 && this.paginaTotal < this.paginaCorrente) {
            this.paginaCorrente = 1;
            this.preencherGrid();
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Data Anexo', coluna: 'data_anexo' },
      { titulo: 'Ano', coluna: 'ano' },
      { titulo: 'Referência', coluna: 'referencia' },
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'Órgão', coluna: 'orgao.nome' },
      { titulo: 'Tamanho', coluna: 'tamanho', alignment: 'right' },
    ];
  }

  public imprimirPDF() {
    const parametros = this.obterParametros();
    this.anexoService
      .filtrar(0, 0, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.imprimir('LISTAGEM DE ANEXOS CONTAS PÚBLICAS',
            this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
            'Listagem contas públicas', ['auto', 'auto', 'auto', '*', '*', 'auto'], lista.content);
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private preencherExercicio() {
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaExercicios = res.content;
      });
  }

  public exportarXLSX() {
    const parametros = this.obterParametros();
    this.anexoService
      .filtrar(0, 0, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          const listaItens = new Array();
          for (const item of this.lista as ContaPublicaAnexo[]) {
            const entity = {
              tipo: item.tipo.nome,
              data_anexo: item.data_anexo,
              ano: item.ano,
              referencia: item.referencia,
              nome: item.nome,
              orgao: item.orgao.nome,
              tamanho: item.nome
            };
            listaItens.push(entity);
          }
          tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile('anexos-conta-publica');
        },
        () => alert('erro ao retornar lista')
      );
  }

  public retornarReferencia(anexo: ContaPublicaAnexo) {
    if (anexo.tipo == null) {
      return this.globalService.obterMes(+anexo.referencia, true);
    }
    switch (anexo.tipo.id) {
      case 1:
      case 9:
      case 20:
      case 25:
        return this.globalService.obterMes(+anexo.referencia, true);
      case 6:
      case 7:
      case 8:
      case 15:
      case 16:
      case 17:
      case 18:
      case 19:
        switch (+anexo.referencia) {
          case 2:
            return '1º BIM';
          case 4:
            return '2º BIM';
          case 6:
            return '3º BIM';
          case 8:
            return '4º BIM';
          case 10:
            return '5º BIM';
          case 12:
            return '6º BIM';
        }
      case 21:
        switch (+anexo.referencia) {
          case 1:
            return '1º QUA';
          case 2:
            return '2º QUA';
          case 3:
            return '3º QUA';
        }
      default:
        return "ANUAL";

    }
  }

  public onSelectPeriodo() {
    this.preencherGrid();
  }

  public inserirArquivo() {
    this.entidade = new ContaPublicaAnexo();
    this.entidade.ano = new Date().getFullYear();
    this.entidade.data_anexo = new Date();
    this.uploadedFiles = [];
    $('#dialogUpload').modal('show');
  }

  public async onUpload(event: any, fileUpload: any) {
    if (!event.files || event.files.length === 0) {
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Selecione o(s) arquivo(s)' });
      return;
    }
    if (this.entidade.ano === 0) {
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Informe o ano' });
      return;
    }
    if (this.entidade.referencia === '') {
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Informe a referência' });
      return;
    }
    if (this.entidade.descricao === '') {
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Informe a descrição' });
      return;
    }
    try {
      const file = event.files[0];
      this.uploadedFiles.push(file);
      this.entidade.nome = file.name;
      this.entidade.tamanho = file.size;
      this.entidade.orgao = this.login.orgao;
      this.entidade.especie = this.especie;
      const cpTipo = new ContaPublicaTipo();
      cpTipo.id = this.tipo;
      this.entidade.tipo = cpTipo;
      this.anexoService.obter({ nome: this.entidade.nome, referencia: this.entidade.referencia, 'tipo.id': this.entidade.tipo.id, especie: this.entidade.especie }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          if (!res) {
            this.anexoService
              .upload(this.entidade, this.uploadedFiles).pipe(takeUntil(this.unsubscribe))
              .subscribe((res) => {
                $('#dialogUpload').modal('hide');
                this.preencherGrid();
                this.messageService.add({ severity: 'success', summary: 'Informação', detail: 'Documento enviado com sucesso!' });
              }, (res) => {
                this.messageService.add({ severity: 'error', summary: 'Validação', detail: res.error.payload });
                this.uploadedFiles.length = 0;
              });
          } else {
            this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Arquivo já existe no sistema!' });
            $('#dialogUpload').modal('hide');
            return;
          }
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));
    } catch (ex) {
      toastr.error(ex.message ? ex.message : ex);
    }
    this.uploadedFiles = []
    fileUpload.clear();
  }

  public download(arquivo: any) {
    this.anexoService
      .download(arquivo.documento).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = arquivo.nome;
        link.click();
        window.URL.revokeObjectURL(downloadURL);
      });
  }

  public remover(item: ContaPublicaAnexo) {
    if (item.id) {
      this.anexoService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
          this.preencherGrid();
        });
    }
  }
}
