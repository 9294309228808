import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { PessoaModule, SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { CompraModule } from '../compra/compra.module';
import { RcmsModule } from '../rcms/rcms.module';
import { SaldoFichaModule } from '../saldo-ficha/saldo-ficha.module';
import { ServicoModule } from '../servico/servico.module';
import { ExportarEmpenhoComponent } from './exportar-empenho/exportar-empenho.component';
import { ImportarRcmsEmpateDlgComponent } from './importar-rcms-empate-dlg/importar-rcms-empate-dlg.component';
import { ImportarRcmsItensDlgComponent } from './importar-rcms-itens-dlg/importar-rcms-itens-dlg.component';
import { ImportarRcmsComponent } from './importar-rcms/importar-rcms.component';
import { MovimentoCompraRoutingModule } from './movimento-compra-routing.module';
import { PedidoCompraFormComponent } from './pedido-compra/pedido-compra-form/pedido-compra-form.component';
import { PedidoCompraItemComponent } from './pedido-compra/pedido-compra-item/pedido-compra-item.component';
import { PedidoCompraListComponent } from './pedido-compra/pedido-compra-list/pedido-compra-list.component';
import { PedidoCompraSelecionarItemDlgComponent } from './pedido-compra/pedido-compra-selecionar-item/pedido-compra-selecionar-item.conponent';


@NgModule({
  declarations: [
    ExportarEmpenhoComponent,
    PedidoCompraListComponent,
    PedidoCompraFormComponent,
    PedidoCompraItemComponent,
    PedidoCompraSelecionarItemDlgComponent,
    ImportarRcmsComponent,
    ImportarRcmsItensDlgComponent,
    ImportarRcmsEmpateDlgComponent
  ],
  exports: [ImportarRcmsItensDlgComponent,
    ImportarRcmsEmpateDlgComponent],
  imports: [
    CommonModule,
    MovimentoCompraRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    PessoaModule,
    RadioButtonModule,
    FieldsetModule,
    SaldoFichaModule,
    CheckboxModule,
    MenuModule,
    OverlayPanelModule,
    ConfirmDialogModule,
    RcmsModule,
    CompraModule,
    ServicoModule
  ],
  providers: [MessageService]
})
export class MovimentoCompraModule { }
