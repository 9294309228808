// tslint:disable: variable-name
import { TipoOcorrenciaLicitacao } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { Licitacao } from './licitacao.model';

export class LicitacaoOcorrencia extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_ocorrencia?: Date,
    public ocorrencia?: string,
    public tipo?: TipoOcorrenciaLicitacao,
    public licitacao?: Licitacao,
    public usuario?: Usuario,
  ) {
    super();
  }

  static converteJson(json: any): LicitacaoOcorrencia {
    return Object.assign(new LicitacaoOcorrencia(), json);
  }
}
