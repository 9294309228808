// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { FonteFinanciamento } from './fonte-financiamento.model';
import { Orgao } from '../comum/orgao.model';

export class FonteOrgao extends BaseResourceModel {
  constructor(
    public id?: number,
    public orgao?: Orgao,
    public fonte?: FonteFinanciamento,
    public ano1?: number,
    public ano2?: number,
    public ano3?: number,
    public ano4?: number,
    public editavel?: boolean,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public desdobramento?: any[]
  ) {
    super();
  }

  static converteJson(json: any): FonteOrgao {
    return Object.assign(new FonteOrgao(), json);
  }
}
