// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Chamamento } from './chamamento.model';

export class ChamamentoHistorico extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_historico?: Date,
    public comentario?: string,
    public usuario?: string,
    public chamamento?: Chamamento
  ) {
    super();
  }
  static converteJson(json: any): ChamamentoHistorico {
    return Object.assign(new ChamamentoHistorico(), json);
  }
}
