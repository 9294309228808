import { Component, Input } from "@angular/core";
import { MemorialService } from "administrativo-lib";
import { AssinaturaService, BaseResourceRptPersonalizadoComponent, CpfPipe, Licitacao, LicitacaoPipe, Memorial, PregaoHistorico, Proposta } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";
import { PregaoHistoricoService } from "../../licitacao/service/pregao-historico.service";

@Component({
  selector: 'app-ata-sessao-publica-rpt',
  templateUrl: './ata-sessao-publica-rpt.component.html'
})
export class AtaSessaoPublicaRptComponent extends BaseResourceRptPersonalizadoComponent {

  @Input() public licitacao: Licitacao;

  constructor(
    private memorialService: MemorialService,
    private historicoService: PregaoHistoricoService,
    protected assinaturaService: AssinaturaService,
  ) {
    super(assinaturaService);
    this.assinaturaObrigatoria = false;
  }

  protected afterInit(): void {
  }

  protected modalRelatorio(): string {
    return 'dialogRelatorioAtaPersonalizada';
  }

  protected tituloRelatorio(): string {
    return 'Ata de Sessão Pública';
  }

  protected layoutRelatorio(): {} {
    return {
      table_eddy: {
        hLineWidth: function (i, node) {
          return i === 0 ? 0 : 1;
        },
        vLineWidth: function (i) {
          return 0;
        },
        hLineColor: function (i, node) {
          return i === node.table.headerRows ? 'black' : '#eee';
        },
        paddingLeft: function (i) {
          return i === 0 ? 0 : 8;
        },
        paddingRight: function (i, node) {
          return (i === node.table.widths.length - 1) ? 0 : 8;
        },
        fillColor: function (i, node) {
          if (i === 0 || i === node.table.headerRows) {
            return null;
          }
          return (i % 2 === 0) ? '#f5f5f5' : null;
        }
      }
    }
  }

  protected async montarConteudo(): Promise<{}[]> {
    const conteudo = [];
    return await new Promise(async (resolve) => {
      conteudo.push(this.dadosCabecalho())
      conteudo.push(await this.dadosItens())
      conteudo.push(await this.dadosHistorico())
      conteudo.push(this.dadosRodape())

      resolve(conteudo);
    })
  }

  private dadosCabecalho(): {}[] {
    const conteudo = [
      {
        text: `${this.licitacao.modalidade.nome} Nº ${new LicitacaoPipe().transform(this.licitacao.numero)}`, alignment: 'center',
        bold: true, fontSize: 16, decoration: 'underline', margin: [0, 0, 0, 10]
      },
      { text: `Objeto: ${this.licitacao.objeto}`, bold: true, fontSize: 10, alignment: 'justify', margin: [0, 0, 0, 10] },
      {
        text: `Às 09:00 horas do dia 12 de agosto de 2021, reuniram-se o Pregoeiro Oficial deste Órgão e respectivos membros da Equipe de Apoio, designados pelo instrumento legal Portaria 001/2021 de 04/01/2021, em atendimento às disposições contidas na Lei nº 10.520 de 17 de julho de 2002 e no Decreto nº 10.024 de 20 de setembro de 2019, referente ao Processo nº 8039/2021, para realizar os procedimentos relativos ao Pregão nº 00039/2021.`,
        fontSize: 10, alignment: 'justify', margin: [0, 0, 0, 10]
      },
      {
        text: 'O Pregoeiro abriu a Sessão Pública em atendimento às disposições contidas no edital, divulgando as propostas recebidas. Abriu-se em seguida a fase de lances para classificação dos licitantes relativamente aos lances ofertados.',
        fontSize: 10, alignment: 'justify', margin: [0, 0, 0, 10]
      }
    ]
    return conteudo;
  }

  private async dadosItens(): Promise<{}[]> {
    const conteudo: {}[] = []

    return await new Promise((resolve) => {
      this.memorialService.obterPorLicitacao(this.licitacao.id, true).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          const reg: Memorial[] = res.content;

          reg.forEach(memorial => {
            conteudo.push({ text: `Item: ${memorial.ordem}`, bold: true, fontSize: 10, margin: [0, 10, 0, 5] })
            conteudo.push({
              fontSize: 10, margin: [0, 0, 0, 5],
              text: [
                { text: 'Descrição: ', bold: true }, { text: memorial.descricao }
              ]
            })
            conteudo.push({
              fontSize: 10, margin: [0, 0, 0, 5],
              columns: [
                {
                  text: [{ text: 'Unidade de Fornecimento: ', bold: true }, { text: memorial.unidade }]
                },
                {
                  text: [{ text: 'Quantidade: ', bold: true }, { text: `${this.funcaoService.convertToBrNumber(memorial.quantidade, 0)} ${this.informacaoCota(memorial)}` }]
                }
              ]
            })
            conteudo.push({
              fontSize: 10, margin: [0, 0, 0, 5],
              columns: [
                {
                  text: [{ text: 'Margem de Preferência: ', bold: true }, { text: 'Não' }]
                },
                {
                  text: [{ text: `Intervalo mínimo entre lances (${memorial.reducao_criterio}): `, bold: true }, { text: this.funcaoService.convertToBrNumber(memorial.reducao_lance, 4) }]
                }
              ]
            })
            const vencedor: Proposta = memorial.propostas.find(p => p.situacao === 'VENCEDOR');
            if (vencedor) {
              conteudo.push({
                fontSize: 10, margin: [0, 10, 0, 5],
                text: [
                  { text: 'Vencido por ', bold: true }, { text: vencedor.proponente.favorecido.nome },
                  { text: ' pelo melhor lance de ', bold: true }, { text: this.funcaoService.convertToBrNumber(vencedor.valor_final, 4) },
                ]
              })
            }
          })
          resolve(conteudo)
        });
    })
  }

  private async dadosHistorico(): Promise<{}[]> {
    return await new Promise((resolve) => {
      this.memorialService.obterPorLicitacao(this.licitacao.id, true).pipe(takeUntil(this.unsubscribe))
        .subscribe(async (res) => {
          const reg: Memorial[] = res.content;

          const conteudo = []
          conteudo.push({ text: 'Histórico', alignment: 'center', fontSize: 12, bold: true, margin: [0, 15, 0, 5] })

          for (let i = 0; i < reg.length; i++) {
            const memorial = reg[i];

            conteudo.push({ text: `Item: ${memorial.ordem} - ${memorial.descricao}`, bold: true, fontSize: 10, margin: [0, 15, 0, 5] })
            conteudo.push({
              fontSize: 10, margin: [0, 0, 0, 5], columns: [
                {
                  text: [{ text: 'Unidade de Fornecimento: ', bold: true }, { text: memorial.unidade }]
                },
                {
                  text: [{ text: 'Quantidade: ', bold: true }, { text: `${this.funcaoService.convertToBrNumber(memorial.quantidade, 0)} ${this.informacaoCota(memorial)}` }]
                }
              ]
            })

            const propostas = await this.dadosPropostas(memorial);
            conteudo.push({ text: 'Propostas', alignment: 'left', fontSize: 10, bold: true, margin: [0, 10, 0, 5] })
            conteudo.push(propostas);

            const lances = await this.dadosLances(memorial);
            conteudo.push({ text: 'Lances', alignment: 'left', fontSize: 10, bold: true, margin: [0, 10, 0, 5] })
            conteudo.push(lances);
          }

          resolve(conteudo)
        });
    })
  }

  private async dadosPropostas(memorial: Memorial): Promise<{}> {
    const tabela: {}[] = [];
    tabela.push(
      [
        { text: 'Favorecido', alignment: 'left', fontSize: 9 },
        { text: 'Marca', alignment: 'left', fontSize: 9 },
        { text: 'Valor Unit.', alignment: 'center', fontSize: 9 },
        { text: 'Valor Total', alignment: 'center', fontSize: 9 },
      ],
    )
    return await new Promise((resolve) => {
      memorial.propostas.forEach(p => {
        tabela.push(
          [
            { text: `${new CpfPipe().transform(p.proponente.favorecido.cpf_cnpj)} - ${p.proponente.favorecido.nome}`, alignment: 'left', fontSize: 8 },
            { text: `${p.marca}`, alignment: 'left', fontSize: 8 },
            { text: `${this.funcaoService.convertToBrNumber(p.valor_unitario, 4)}`, alignment: 'right', fontSize: 8 },
            { text: `${this.funcaoService.convertToBrNumber(+memorial.quantidade * +p.valor_unitario, 4)}`, alignment: 'right', fontSize: 8 },
          ],
        )
      })

      resolve({
        layout: 'table_eddy',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*', 100, 50, 65],
          body: tabela
        }
      })
    })
  }

  private async dadosLances(memorial: Memorial): Promise<{}> {
    const tabela: {}[] = [];
    tabela.push(
      [
        { text: 'Horário', alignment: 'left', fontSize: 9 },
        { text: 'Descrição', alignment: 'left', fontSize: 9 },
        { text: 'Favorecido', alignment: 'left', fontSize: 9 },
        { text: 'Rodada', alignment: 'left', fontSize: 9 },
        { text: 'Lance', alignment: 'left', fontSize: 9 },
        { text: 'Valor', alignment: 'center', fontSize: 9 },
      ],
    )
    return await new Promise((resolve) => {
      this.historicoService.obterPorItem(memorial.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          const reg: PregaoHistorico[] = res.content;
          for (let i = 0; i < reg.length; i++) {
            const h = reg[i];
            tabela.push(
              [
                { text: `${this.funcaoService.converteDataHoraBR(h.data_cadastro)}`, alignment: 'left', fontSize: 8 },
                { text: `${h.descricao}`, alignment: 'left', fontSize: 8 },
                { text: `${new CpfPipe().transform(h.lance.proponente.favorecido.cpf_cnpj)}`, alignment: 'left', fontSize: 8 },
                { text: `${h.lance.lance}`, alignment: 'center', fontSize: 8 },
                { text: `${h.lance.rodada}`, alignment: 'center', fontSize: 8 },
                { text: `${this.funcaoService.convertToBrNumber(h.lance.valor, 4)}`, alignment: 'right', fontSize: 8 },
              ],
            )
          }

          resolve({
            layout: 'table_eddy',
            table: {
              dontBreakRows: true,
              headerRows: 1,
              widths: ['auto', '*', '*', 'auto', 'auto', 'auto'],
              body: tabela
            }
          })
        })
    })
  }

  private dadosRodape(): {}[] {
    let assinaturas = [];
    if (this.assinatura) {
      assinaturas = this.assinatura.pessoas.filter(p => p.selecionado);
      if (assinaturas?.length === 0) return [{}];
    }

    const conteudo: {}[] = [
      {
        text: 'Em seguida, a Comissão resolveu adjudicar o objeto do presente certame conforme resultado acima classificado, sendo que o presente expediente será enviado ao Sr. Prefeito Municipal para homologação. Pelo Sr. Presidente foi determinada a publicação da minuta da Ata de Classificação no Diário Oficial para ciência dos Licitantes e demais interessados. Dada a palavra aos presentes não houve manifestação verbal. Nada mais havendo a ser tratado, lavra-se o presente termo que, lido e achado conforme, segue assinado pelos membros da Comissão Permanente de Licitações. ATA APROVADA.',
        fontSize: 10, alignment: 'justify', margin: [0, 10, 0, 10]
      },
      { text: this.funcaoService.formatarDataExtenso(new Date(), this.login.cidade.nome), alignment: 'center', fontSize: 10, margin: [0, 30, 0, 70] },
    ]

    return conteudo.concat(this.imprimirAssinaturas(assinaturas));
  }

  private informacaoCota(item: Memorial): string {
    if (!item || !item.cota || item.cota === 'NAO_DEFINIDO') return ''
    if (item.cota === 'PRINCIPAL') return '(Cota Principal)'
    if (item.cota === 'RESERVADO') return '(Cota Reservada)'
  }

}