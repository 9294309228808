import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, FuncaoService, Imovel } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SeguradoraService extends BaseResourceService<Imovel> {

  constructor(
    protected injector: Injector
  ) {
    super(`seguradoras`, injector);
  }

  public obterFiltro(nomeSeguradora?: string): {} {
    const parametros = {};

    if (nomeSeguradora)
      parametros['nome$like'] = `%${new FuncaoService().removerAcentos(nomeSeguradora)}%`;

    return parametros;
  }

  public proximoCodigo(): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-codigo`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
