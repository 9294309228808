import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ProdutoUnidade, RcmsItem } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RcmsItemService extends BaseResourceService<RcmsItem> {

  constructor(
    protected injector: Injector
  ) {
    super(`rcms-itens`, injector);
  }


  public atualizarProdutoUnidade(produto_unidade: ProdutoUnidade, produto: { produto: string, unidade: string, rcms_id: number }): Observable<void> {
    return this.http.post(
      `${this.login.cidade.id}/${this.api}/atualizar-produto-unidade`, { produto_unidade, produto }, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public atualizarProdutoUnidadeLote(lote: { produto_unidade: ProdutoUnidade, produto: { produto: string, unidade: string, rcms_id: number } }[]): Observable<void> {
    return this.http.post(
      `${this.login.cidade.id}/${this.api}/atualizar-produto-unidade-lote`, { lote }, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
