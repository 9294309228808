import { Component, ElementRef, Injector } from '@angular/core';
import { SubGrupoEstoqueService } from 'almoxarifado-lib';
import { BaseResourceFormComponent, GlobalService, LoginContabil, SubGrupoEstoque } from 'eddydata-lib';

@Component({
  selector: 'lib-subgrupo-estoque-view',
  templateUrl: './subgrupo-estoque-view.component.html'
})
export class SubGrupoEstoqueViewComponent extends BaseResourceFormComponent<SubGrupoEstoque, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  readOnly: boolean;
  public mes: string;
  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected subGrupoService: SubGrupoEstoqueService,
    protected globalService: GlobalService
  ) {
    super(new SubGrupoEstoque(), injector, SubGrupoEstoque.converteJson, subGrupoService);
    this.readOnly = true;
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
  }

  protected parametrosExtras(): {} {
    return { relations: 'grupo,materiais' };
  }

  protected afterLoad() {
  }

  protected afterInit(): void {
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit() {
  }

  protected afterSubmit(modelo: SubGrupoEstoque) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
}
