import { Component, Input } from "@angular/core";
import { MemorialService } from "administrativo-lib";
import { AssinaturaService, BaseResourceRptPersonalizadoComponent, CpfPipe, Licitacao, LicitacaoPipe, Memorial, PregaoHistorico, Proponente, Proposta } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";
import * as toastr from 'toastr';
import { PregaoHistoricoService } from "../../licitacao/service/pregao-historico.service";

declare var $: any;

@Component({
  selector: 'app-ata-personalizada-rpt',
  templateUrl: './ata-personalizada-rpt.component.html'
})
export class AtaPersonalizadaRptComponent extends BaseResourceRptPersonalizadoComponent {

  @Input() public licitacao: Licitacao;

  constructor(
    private historicoService: PregaoHistoricoService,
    protected memorialService: MemorialService,
    protected assinaturaService: AssinaturaService,
  ) {
    super(assinaturaService);
    this.assinaturaObrigatoria = false;
  }

  protected afterInit(): void {
  }

  protected modalRelatorio(): string {
    return 'dialogRelatorioAtaPersonalizada';
  }

  protected tituloRelatorio(): string {
    return 'Ata de Sessão Pública';
  }

  protected layoutRelatorio(): {} {
    return {
      table_eddy: {
        hLineWidth: function (i, node) {
          return i === 0 ? 0 : 1;
        },
        vLineWidth: function (i) {
          return 0;
        },
        hLineColor: function (i, node) {
          return i === node.table.headerRows ? 'black' : '#eee';
        },
        paddingLeft: function (i) {
          return i === 0 ? 0 : 8;
        },
        paddingRight: function (i, node) {
          return (i === node.table.widths.length - 1) ? 0 : 8;
        },
        fillColor: function (i, node) {
          if (i === 0 || i === node.table.headerRows) {
            return null;
          }
          return (i % 2 === 0) ? '#f5f5f5' : null;
        }
      }
    }
  }

  protected async montarConteudo(): Promise<{}[]> {
    let modelo: string = this.licitacao.modelo_ata ? this.licitacao.modelo_ata : this.licitacao.modalidade.modelo_ata;
    if (!modelo) {
      toastr.warning('Nenhum modelo encontrado para impressão da ata');
      return;
    }
    modelo = this.substituirTagsConteudo(modelo);
    const conteudo = [];
    return await new Promise(async (resolve) => {
      conteudo.push({
        text: `${this.licitacao.modalidade.nome} Nº ${new LicitacaoPipe().transform(this.licitacao.numero)}`, alignment: 'center',
        bold: true, fontSize: 16, decoration: 'underline', margin: [0, 0, 0, 10]
      })

      for (let i = 0; i < modelo.split('\n').length; i++) {
        const paragrafo = modelo.split('\n')[i];

        if (paragrafo.includes('<MEMORIAL>')) {
          conteudo.push(await this.dadosItens())
        } else if (paragrafo.includes('<HISTORICO>')) {
          conteudo.push(await this.dadosHistorico())
        } else if (paragrafo.includes('<ASSINATURAS>')) {
          conteudo.push(await this.assinaturasTag())
        } else if (paragrafo.includes('<VENCEDORES>')) {
          conteudo.push(await this.gerarVencedores(this.licitacao?.itens))
        } else if (paragrafo.includes('<QUADRO_LANCES>')) {
          conteudo.push(await this.dadosLancesSozinho())
        } else if (paragrafo.includes('<PREGOEIRO_EQUIPE_DE_APOIO>')) {
          conteudo.push(await this.pregoeiroEquipeApoio())
        } else if (paragrafo.includes('<CLASSIFICACAO>')) {
          conteudo.push(await this.classificacao())
        } else if (paragrafo.includes('<CLASSIFICACAO_PROPOSTA_INICIAL>')) {
          conteudo.push(await this.propostaClassificao());
        } else {
          conteudo.push({ text: paragrafo, fontSize: 10, alignment: 'justify', margin: [0, 0, 0, 10] })
        }
      }

      resolve(conteudo);
    })
  }

  public abrirModelo() {
    $('#dialogRelatorioAtaPersonalizada').modal('hide');
    $('#dialogModeloAta').modal('show');
  }

  public callbackModelo(modelo: string) {
    if (modelo) {
      this.licitacao.modelo_ata = modelo;
    }
    $('#dialogRelatorioAtaPersonalizada').modal('show');
  }

  private substituirTagsConteudo(modelo: string): string {
    let proponentes = '';
    for (let proponente of this.licitacao.proponentes) {
      if (proponentes !== '') {
        proponentes += '\n ';
      }

      proponentes += (`${proponente.favorecido.nome} - ${new CpfPipe().transform(proponente.favorecido.cpf_cnpj)}`)
    }

    return modelo
      .split('<DATA_JULGAMENTO>').join(this.funcaoService.converteDataBR(this.licitacao.data_julgamento))
      .split('<HORA_JULGAMENTO>').join((this.funcaoService.converteHoraBr(this.licitacao?.historicos[0]?.data_cadastro)))
      .split('<MODALIDADE>').join(this.licitacao.modalidade.nome)
      .split('<NUMERO>').join(new LicitacaoPipe().transform(this.licitacao.numero))
      .split('<OBJETO>').join(this.licitacao.objeto)
      .split('<PROCESSO>').join(new LicitacaoPipe().transform(this.licitacao.processo))
      .split('<PROPONENTES>').join(proponentes)
      .split('<COMISSAO_PREGOEIRO>').join(this.comissaoPregoeiro())
      .split('<COMISSAO_MEMBROS>').join(this.comissaoMembros())
      .split('<OBSERVACAO>').join(this.observação())
      .split('<RECURSO>').join(this.licitacao.situacao_pregao ? this.licitacao.situacao_pregao : 'Nada consta.')
  }

  public comissaoPregoeiro() {
    let pregoeiro: string = '';
    for (let membro of this.licitacao.comissao_membros) {
      if (membro.marcado === true && membro.comissao_membro.atribuicao === '3 - PREGOEIRO') {
        pregoeiro += membro.comissao_membro.pessoa.nome + ', ';
        break;
      }
    }
    return pregoeiro;
  }

  public comissaoMembros() {
    let membros: string = '';
    for (let membro of this.licitacao.comissao_membros) {
      if (membro.marcado === true && membro.comissao_membro.atribuicao === '4 - EQUIPE DE APOIO') {
        membros += membro.comissao_membro.pessoa.nome + ', ';
      }
    }
    return membros
  }

  public observação() {
    let observacao: string = ''
    for (let obser of this.licitacao.historicos) {
      if (obser.obs && obser.descricao === 'Registro de Ocorrência') {
        observacao += obser.obs + '\n ';
      }
    }
    return observacao
  }

  public assinaturasTag() {
    const tabela: {}[] = []

    tabela.push(
      [
        { text: 'PARTICIPANTE', alignment: 'left', fontSize: 9 },
        { text: 'REPRESENTANTE', alignment: 'left', fontSize: 9 },
        { text: 'DOCUMENTO', alignment: 'center', fontSize: 9 },
        { text: 'ASSINATURA', alignment: 'center', fontSize: 9 },
      ],
    )

    for (let prop of this.licitacao.proponentes) {
      tabela.push(
        [
          { text: prop?.favorecido?.nome, alignment: 'left', fontSize: 8 },
          { text: prop?.favorecido?.responsavel, alignment: 'left', fontSize: 8 },
          { text: new CpfPipe().transform(prop?.favorecido?.cpf_cnpj), alignment: 'center', fontSize: 8 },
          { canvas: [{ type: 'line', x1: -5, y1: 30, x2: this.orientacao === 'portrait' ? 270 : 350, y2: 30, lineWidth: 0.5 }] },
        ]
      )
    }

    return {
      layout: 'table_eddy',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['auto', 'auto', 'auto', '*'],
        body: tabela
      }
    }
  }

  public async pregoeiroEquipeApoio(): Promise<{}[]> {
    const conteudo: {}[] = []
    return await new Promise(async (resolve) => {
      let membrosComissao = [];
      let assinaturas = [];

      for (let membro of this.licitacao.comissao_membros) {
        if (membro.marcado) {
          membrosComissao.push({ pessoa: { nome: membro?.comissao_membro?.pessoa?.nome }, cargo: membro.comissao_membro.atribuicao });
        }
      }

      assinaturas.push(membrosComissao.find((f) => f.cargo === '3 - PREGOEIRO'));
      let tam: number = 1;

      for (let mem of membrosComissao) {
        if (mem.cargo != '3 - PREGOEIRO') {
          assinaturas.push(mem)
          tam += 1;
        }

        if (assinaturas.length === 2) {
          conteudo.push([
            { canvas: [{ type: 'line', x1: this.orientacao === 'portrait' ? 55 : 115, y1: 30, x2: this.orientacao === 'portrait' ? 215 : 275, y2: 30, lineWidth: 0.5 }] },
            { canvas: [{ type: 'line', x1: this.orientacao === 'portrait' ? 320 : 505, y1: 0, x2: this.orientacao === 'portrait' ? 480 : 665, y2: 0, lineWidth: 0.5 }] },
            {
              columns: [
                { text: this.funcaoService.abreviarStr(assinaturas[0]?.pessoa?.nome, 100), alignment: 'center', bold: true, fontSize: 10, margin: [0, 3, 0, 3] },
                { text: this.funcaoService.abreviarStr(assinaturas[1]?.pessoa?.nome, 100), alignment: 'center', bold: true, fontSize: 10, margin: [0, 3, 0, 3] }
              ]
            },
            {
              columns: [
                { text: this.funcaoService.abreviarStr(assinaturas[0]?.cargo, 100), alignment: 'center', fontSize: 10, margin: [0, 0, 0, 50] },
                { text: this.funcaoService.abreviarStr(assinaturas[1]?.cargo, 100), alignment: 'center', fontSize: 10, margin: [0, 0, 0, 50] }
              ]
            }
          ]);
          assinaturas = []
        } else if (assinaturas.length === 1 && membrosComissao.length === tam) {
          conteudo.push([
            { canvas: [{ type: 'line', x1: this.orientacao === 'portrait' ? 187 : 312, y1: 30, x2: this.orientacao === 'portrait' ? 347 : 472, y2: 30, lineWidth: 0.5, border: [true, false, true, false] }] },
            {
              columns: [
                { text: assinaturas[0]?.pessoa?.nome, alignment: 'center', bold: true, fontSize: 10, margin: [0, 3, 0, 3] },
              ]
            },
            {
              columns: [
                { text: assinaturas[0].cargo, alignment: 'center', fontSize: 10, margin: [0, 0, 0, 50] },
              ]
            },
          ])
        }
      }
      resolve(conteudo)
    });
  }

  public async classificacao(): Promise<{}[]> {
    return await new Promise(async (resolve) => {
      const conteudo = [];

      for (let item of this.licitacao?.itens) {
        conteudo.push({ text: `Item: ${item.ordem} - ${item.descricao}`, bold: true, fontSize: 10, margin: [0, 15, 0, 5] })
        const classificacao = await this.dadosClassificacao(item.propostas);
        conteudo.push(classificacao);
      }
      resolve(conteudo)
    });
  }

  public async propostaClassificao(): Promise<{}[]> {
    return await new Promise(async (resolve) => {
      this.memorialService.obterPorLicitacao(this.licitacao.id, true).pipe(takeUntil(this.unsubscribe))
        .subscribe(async (res) => {
          const reg: Memorial[] = res.content;

          const conteudo = [];

          conteudo.push({ text: `Classificação Proposta Inicial: `, bold: true, fontSize: 10, margin: [0, 15, 0, 5] });

          for (let i = 0; i < reg.length; i++) {
            const memorial = reg[i];

            conteudo.push({ text: `Item: ${memorial.produto_unidade?.produto?.codigo} - ${memorial.descricao}`, bold: true, fontSize: 10, margin: [0, 15, 0, 5] })
            const classificacao = await this.dadosClassProposta(memorial);
            conteudo.push(classificacao);
          }

          resolve(conteudo);
        });

    });
  }

  private async dadosClassificacao(propostas: any[]): Promise<{}> {
    const tabela: {}[] = [];
    tabela.push(
      [
        { text: 'Favorecido', alignment: 'left', fontSize: 9 },
        { text: 'Valor', alignment: 'left', fontSize: 9 },
        { text: 'Situação', alignment: 'center', fontSize: 9 },
        { text: 'Marca', alignment: 'center', fontSize: 9 },
      ],
    )
    return await new Promise((resolve) => {
      propostas.forEach(p => {
        const valor = p?.situacao === 'VENCEDOR' ? p?.valor_final : p?.valor_unitario
        tabela.push(
          [
            { text: `${p?.proponente?.favorecido?.nome}`, alignment: 'left', fontSize: 8 },
            { text: `${this.funcaoService.convertToBrNumber(valor, 4)}`, alignment: 'left', fontSize: 8 },
            { text: `${p?.situacao}`, alignment: 'center', fontSize: 8 },
            { text: `${p?.marca}`, alignment: 'center', fontSize: 8 },
          ],
        )
      })
      resolve({
        layout: 'table_eddy',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*', '*', '*', '*'],
          body: tabela
        }
      })
    })
  }

  private async dadosItens(): Promise<{}[]> {
    const conteudo: {}[] = [];

    return await new Promise((resolve) => {
      this.memorialService.obterPorLicitacao(this.licitacao.id, true).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          const reg: Memorial[] = res.content;

          reg.forEach(memorial => {
            conteudo.push({ text: `Item: ${memorial.ordem}`, bold: true, fontSize: 10, margin: [0, 10, 0, 5] })
            conteudo.push({
              fontSize: 10, margin: [0, 0, 0, 5],
              text: [
                { text: 'Descrição: ', bold: true }, { text: memorial.descricao }
              ]
            })
            conteudo.push({
              fontSize: 10, margin: [0, 0, 0, 5],
              columns: [
                {
                  text: [{ text: 'Unidade de Fornecimento: ', bold: true }, { text: memorial.unidade }]
                },
                {
                  text: [{ text: 'Quantidade: ', bold: true }, { text: this.funcaoService.convertToBrNumber(memorial.quantidade, 0) }]
                }
              ]
            })
            conteudo.push({
              fontSize: 10, margin: [0, 0, 0, 5],
              columns: [
                {
                  text: [{ text: 'Margem de Preferência: ', bold: true }, { text: 'Não' }]
                },
                {
                  text: [{ text: `Intervalo mínimo entre lances (${memorial.reducao_criterio}): `, bold: true }, { text: this.funcaoService.convertToBrNumber(memorial.reducao_lance, 4) }]
                }
              ]
            })
            const vencedor: Proposta = memorial.propostas.find(p => p.situacao === 'VENCEDOR');
            if (vencedor) {
              conteudo.push({
                fontSize: 10, margin: [0, 10, 0, 5],
                text: [
                  { text: 'Vencido por ', bold: true }, { text: vencedor.proponente.favorecido.nome },
                  { text: ' pelo melhor lance de ', bold: true }, { text: this.funcaoService.convertToBrNumber(vencedor.valor_final, 4) },
                ]
              })
            }
          })
          resolve(conteudo)
        });
    })
  }

  private async dadosHistorico(): Promise<{}[]> {
    return await new Promise((resolve) => {
      this.memorialService.obterPorLicitacao(this.licitacao.id, true).pipe(takeUntil(this.unsubscribe))
        .subscribe(async (res) => {
          const reg: Memorial[] = res.content;

          const conteudo = [];
          conteudo.push({ text: 'Histórico', alignment: 'center', fontSize: 12, bold: true, margin: [0, 15, 0, 5] })

          for (let i = 0; i < reg.length; i++) {
            const memorial = reg[i];

            conteudo.push({ text: `Item: ${memorial.ordem} - ${memorial.descricao}`, bold: true, fontSize: 10, margin: [0, 15, 0, 5] })
            conteudo.push({
              fontSize: 10, margin: [0, 0, 0, 5], columns: [
                {
                  text: [{ text: 'Unidade de Fornecimento: ', bold: true }, { text: memorial.unidade }]
                },
                {
                  text: [{ text: 'Quantidade: ', bold: true }, { text: this.funcaoService.convertToBrNumber(memorial.quantidade, 0) }]
                }
              ]
            })

            const propostas = await this.dadosPropostas(memorial);
            conteudo.push({ text: 'Propostas', alignment: 'left', fontSize: 10, bold: true, margin: [0, 10, 0, 5] })
            conteudo.push(propostas);

            const lances = await this.dadosLances(memorial);
            conteudo.push({ text: 'Lances', alignment: 'left', fontSize: 10, bold: true, margin: [0, 10, 0, 5] })
            conteudo.push(lances);
          }

          resolve(conteudo)
        });
    })
  }

  private async dadosLancesSozinho(): Promise<{}[]> {
    return await new Promise((resolve) => {
      this.memorialService.obterPorLicitacao(this.licitacao.id, true).pipe(takeUntil(this.unsubscribe))
        .subscribe(async (res) => {
          const reg: Memorial[] = res.content;

          const conteudo = [];

          for (let i = 0; i < reg.length; i++) {
            const memorial = reg[i];

            const lances = await this.dadosLances(memorial);
            conteudo.push({ text: 'Lances', alignment: 'left', fontSize: 10, bold: true, margin: [0, 10, 0, 5] })
            conteudo.push(lances);
          }

          resolve(conteudo);
        });
    })
  }

  private async dadosPropostas(memorial: Memorial): Promise<{}> {
    const tabela: {}[] = [];
    tabela.push(
      [
        { text: 'Favorecido', alignment: 'left', fontSize: 9 },
        { text: 'Marca', alignment: 'left', fontSize: 9 },
        { text: 'Valor Unit.', alignment: 'center', fontSize: 9 },
        { text: 'Valor Total', alignment: 'center', fontSize: 9 },
      ],
    )
    return await new Promise((resolve) => {
      memorial.propostas.forEach(p => {
        tabela.push(
          [
            { text: `${new CpfPipe().transform(p.proponente.favorecido.cpf_cnpj)} - ${p.proponente.favorecido.nome}`, alignment: 'left', fontSize: 8 },
            { text: `${p.marca}`, alignment: 'left', fontSize: 8 },
            { text: `${this.funcaoService.convertToBrNumber(p.valor_unitario, 4)}`, alignment: 'right', fontSize: 8 },
            { text: `${this.funcaoService.convertToBrNumber(+memorial.quantidade * +p.valor_unitario, 4)}`, alignment: 'right', fontSize: 8 },
          ],
        )
      })

      resolve({
        layout: 'table_eddy',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*', 100, 50, 65],
          body: tabela
        }
      })
    })
  }

  private async dadosLances(memorial: Memorial): Promise<{}> {
    const tabela: {}[] = [];
    tabela.push(
      [
        { text: 'Horário', alignment: 'left', fontSize: 9 },
        { text: 'Descrição', alignment: 'left', fontSize: 9 },
        { text: 'Favorecido', alignment: 'left', fontSize: 9 },
        { text: 'Rodada', alignment: 'left', fontSize: 9 },
        { text: 'Lance', alignment: 'left', fontSize: 9 },
        { text: 'Valor', alignment: 'center', fontSize: 9 },
      ],
    )
    return await new Promise((resolve) => {
      this.historicoService.obterPorItem(memorial.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          const reg: PregaoHistorico[] = res.content;
          for (let i = 0; i < reg.length; i++) {
            const h = reg[i];
            tabela.push(
              [
                { text: `${this.funcaoService.converteDataHoraBR(h.data_cadastro)}`, alignment: 'left', fontSize: 8 },
                { text: `${h.descricao}`, alignment: 'left', fontSize: 8 },
                { text: `${new CpfPipe().transform(h.lance.proponente.favorecido.cpf_cnpj)}`, alignment: 'left', fontSize: 8 },
                { text: `${h.lance.lance}`, alignment: 'center', fontSize: 8 },
                { text: `${h.lance.rodada}`, alignment: 'center', fontSize: 8 },
                { text: `${this.funcaoService.convertToBrNumber(h.lance.valor, 4)}`, alignment: 'right', fontSize: 8 },
              ],
            )
          }

          resolve({
            layout: 'table_eddy',
            table: {
              dontBreakRows: true,
              headerRows: 1,
              widths: ['auto', '*', '*', 'auto', 'auto', 'auto'],
              body: tabela
            }
          })
        })
    })
  }

  private async dadosClassProposta(memorial: Memorial): Promise<{}> {
    const tabela: {}[] = [];
    tabela.push(
      [
        { text: 'Favorecido', alignment: 'left', fontSize: 9, margin: [0, 10, 0, 0] },
        { text: 'Marca', alignment: 'left', fontSize: 9, margin: [0, 10, 0, 0] },
        { text: 'Situação da proposta', alignment: 'left', fontSize: 9, margin: [0, 10, 0, 0] },
        { text: 'Quantidade', alignment: 'left', fontSize: 9, margin: [0, 10, 0, 0] },
        { text: 'Valor unitário', alignment: 'center', fontSize: 9, margin: [0, 10, 0, 0] },
        { text: 'Valor total', alignment: 'right', fontSize: 9, margin: [0, 10, 0, 0] },
      ],
    )
    return await new Promise((resolve) => {

      memorial.propostas.forEach(p => {
        tabela.push(
          [
            { text: `${p.proponente.favorecido.nome}`, alignment: 'left', fontSize: 8 },
            { text: `${p.marca}`, alignment: 'left', fontSize: 8 },
            { text: `${p.situacao}`, alignment: 'left', fontSize: 8 },
            { text: `${this.funcaoService.convertToBrNumber(p.memorial.quantidade)}`, alignment: 'center', fontSize: 8 },
            { text: `${this.funcaoService.convertToBrNumber(p.valor_unitario)}`, alignment: 'center', fontSize: 8 },
            { text: `${this.funcaoService.convertToBrNumber(p.memorial.quantidade * p.valor_unitario)}`, alignment: 'right', fontSize: 8, margin: [0, 0, 0, 10] },
          ],
        )
      })

      resolve({
        layout: 'table_eddy',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['auto', 'auto', 'auto', '*', '*', '*'],
          body: tabela
        }
      });
    });
  }

  public async gerarVencedores(lista: any) {
    return await new Promise(async (resolve) => {
      const conteudo = [];

      let vencedores = lista.filter((c) => c.propostas && c.propostas.filter((p) => p.situacao === 'VENCEDOR').length > 0)
        .map((c) => {
          c['item'] = `${c.ordem} - ${c.produto_unidade.produto.descricao}`;
          c['vencedor'] = c.propostas.find((p) => p.situacao === 'VENCEDOR');
          c['valor_total'] = +c.quantidade * +c['vencedor'].valor_final;
          return c;
        });
      vencedores = vencedores.sort((a, b) => +a['vencedor'].proponente.ordem - +b['vencedor'].proponente.ordem);

      const agrupados = this.funcaoService.agrupar(vencedores, 'vencedor.proponente.favorecido.nome')

      conteudo.push([
        { text: `ITEM`, alignment: 'center' },
        { text: `CÓDIGO`, alignment: 'center' },
        { text: `DESCRIÇÃO`, alignment: 'center' },
        { text: 'MARCA', alignment: 'center' },
        { text: `QUANTIDADE`, alignment: 'center' },
        { text: `VL. UNITÁRIO`, alignment: 'center' },
        { text: `VL. TOTAL`, alignment: 'center' }
      ]);

      for (const grupo of agrupados) {
        conteudo.push([
          { text: `${grupo.grupo}`, colSpan: 7, bold: true, border: [true, true, true, true], fontSize: 10, margin: [5, 5, 5, 5] }, '', '', '', '', '', ''
        ])
        for (const registro of grupo.registros) {
          conteudo.push([
            { text: `${registro.ordem}`, alignment: 'center' },
            { text: `${registro.produto_unidade.produto.codigo}`, alignment: 'center' },
            { text: `${registro.produto_unidade.produto.nome}` },
            { text: `${registro.vencedor.marca}` },
            { text: `${this.funcaoService.convertToBrNumber(registro.quantidade, 3)}`, alignment: 'right' },
            { text: `${this.funcaoService.convertToBrNumber(registro.vencedor.valor_final, 4)}`, alignment: 'right' },
            { text: `${this.funcaoService.convertToBrNumber(registro.valor_total, 4)}`, alignment: 'right' }
          ])
        }
      }

      resolve({
        layout: 'table_eddy',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: [40, 60, '*', 'auto', 60, 60, 60],
          body: conteudo
        }
      });
    });
  };
}
