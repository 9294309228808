import { DatePipe } from '@angular/common';
import { Component, ElementRef, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import {
  BaseResourceFormComponent, CompraItem,
  DateFormatPipe, Empenho,
  FuncaoService, GlobalService, Liquidacao, LoginPublico,
  Pagamento, FavorecidoService
} from 'eddydata-lib';
import { EmpenhoService } from '../service/empenho.service';
import { LiquidacaoService } from '../../liquidacao/service/liquidacao.service';
import { PagamentoService } from '../../../tesouraria/pagamento/service/pagamento.service';
import { DespesaService } from '../../../planejamento/despesa/service/despesa.service';
import { FichaDespesaService } from 'contabil-lib';
import { CompraItemService } from 'compra-lib';

@Component({
  selector: 'lib-empenho-shw',
  templateUrl: './empenho-shw.component.html'
})
export class EmpenhoShwComponent extends BaseResourceFormComponent<Empenho, LoginPublico> {

  /**
   * Declaração de variáveis
   */
  public tipo: string;
  public especie = 'todos';
  public titulo = 'EMPENHOS ORÇAMENTÁRIAS';
  private pagina: string;
  private data1: string;
  private data2: string;
  private filtro: string;

  public listaCompras = new Array<CompraItem>();
  public listaLiquidacoes = new Array<Liquidacao>();
  public listaPagamentos = new Array<Pagamento>();

  private datepipe: DatePipe;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected favorecidoService: FavorecidoService,
    private liquidacaoService: LiquidacaoService,
    private pagamentoService: PagamentoService,
    private itemService: CompraItemService,
    protected despesaService: DespesaService,
    protected fichaService: FichaDespesaService,
    protected empenhoService: EmpenhoService) {
    super(new Empenho(), injector, Empenho.converteJson, empenhoService);
    this.datepipe = new DatePipe('pt');
    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.especie = params['especie'];
        this.pagina = params['pagina'];
        this.data1 = params['data1'];
        this.data2 = params['data2'];
        this.filtro = params['filtro'];
        if (this.especie === 'repasses') {
          this.titulo = 'REPASSES TERCEIRO SETOR';
        } else if (this.especie === 'viagens') {
          this.titulo = 'DESPESAS DE VIAGENS';
        } else if (this.especie === 'equipamentos') {
          this.titulo = 'EQUIPAMENTOS E MATERIAL PERMANENTE';
        }
      });
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      valor_empenho: 0,
      data_empenho: [null, [Validators.required]],
      data_vencimento: [null, [Validators.required]],
      especie: [null, [Validators.required]],
      fase4: [null, [Validators.required]],
      adiantamento: [null, [Validators.required]],
      processo: [null, [Validators.required]],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      orgao: [null, [Validators.required]],
      favorecido: [null, [Validators.required]],
      compra: [null, [Validators.required]],
      subelemento: [null, [Validators.required]],
      contrato: [null, [Validators.required]],
      convenio: [null, [Validators.required]]
    });
    this.entidadeForm.disable();
  }

  protected parametrosExtras(): {} {
    // tslint:disable-next-line: max-line-length
    return {
      relations:
        // tslint:disable-next-line: max-line-length
        'favorecido,subelemento,compra,ficha,ficha.acao,ficha.recurso,ficha.aplicacao,ficha.programa,ficha.funcao,ficha.subfuncao,contrato,convenio,exercicio,orgao'
    };
  }

  protected afterInit(): void {
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: Empenho): void {
  }

  protected async afterLoad() {
    this.entidade.data_empenho = new DateFormatPipe().transform(this.entidade.data_empenho, []);
    this.entidade.data_vencimento = new DateFormatPipe().transform(this.entidade.data_vencimento, []);
    this.loadEspecie();
    if (this.entidade.compra) {
      this.itemService.obterPorCompra(this.entidade.compra.id).subscribe(res => {
        this.listaCompras = res.content;
      });
    }
    this.liquidacaoService.filtrar(1, -1,
      {
        empenho_id: this.entidade.id,
        ignoreCondObrig: true,
        orderBy: 'parcela$DESC'
      }).subscribe(res => {
        this.listaLiquidacoes = res.content;
      });
    this.pagamentoService.filtrar(1, -1, {
      ignoreCondObrig: true,
      'liquidacao.empenho.id': this.entidade.id,
      relations: 'liquidacao,conta,conta.banco',
      orderBy: 'liquidacao.parcela$DESC'
    }).subscribe(res => {
      this.listaPagamentos = res.content;
    });
  }

  public loadEspecie() {
    return this.globalService.obterTipoEmpenho(this.entidade.tipo_empenho);
  }

  public voltar() {
    const dt1 = this.datepipe.transform(this.data1, 'yyyy-MM-dd', 'GMT');
    const dt2 = this.datepipe.transform(this.data2, 'yyyy-MM-dd', 'GMT');
    if (!this.filtro || this.filtro === '') {
      this.router.navigate([`/empenhos-orcamentarios/pesquisa`, this.especie, this.pagina, dt1, dt2]);
    } else {
      this.router.navigate([`/transparencia/empenhos-orcamentarios/pesquisa`,
        this.especie, this.pagina, dt1, dt2, this.filtro]);
    }
  }

}
