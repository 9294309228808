import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EddyAutoComplete, Licitacao, LicitacaoFiscal, Login, Pessoa, PessoaService } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { LicitacaoFiscalService } from './service/licitacao-fiscal.service';

@Component({
  selector: 'app-licitacao-fiscal',
  templateUrl: './licitacao-fiscal.component.html'
})
export class LicitacaoFiscalComponent implements OnInit, OnDestroy {

  public pessoaAutoComplete: EddyAutoComplete<Pessoa>;
  public pessoaAtual: Pessoa;

  @Input() entidade: Licitacao;
  @Input() login: Login;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected confirmationService: ConfirmationService,
    protected pessoaService: PessoaService,
    protected fiscalService: LicitacaoFiscalService,
  ) { }

  ngOnInit(): void {
    this.pessoaAutoComplete = new EddyAutoComplete(null, this.pessoaService,
      'id', ['nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public adicionar(pessoa?: Pessoa) {
    if (!pessoa) pessoa = this.pessoaAtual;
    if (pessoa) {
      if (!this.entidade.fiscais) {
        this.entidade.fiscais = [];
      }

      if (this.entidade.fiscais.find((f) => f.pessoa.id === pessoa.id)) {
        toastr.warning('Fiscal já incluído nesta licitação!');
      } else {
        const item = new LicitacaoFiscal()
        item.pessoa = pessoa;

        this.entidade.fiscais.push(item);
      }
      pessoa = undefined;
      this.pessoaAtual = undefined;
    }
  }

  public remover(item: LicitacaoFiscal, index: number) {
    if (item.id) {
      this.confirmationService.confirm({
        message: 'Deseja realmente remover o fiscal?',
        header: 'Exclusão',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Confirmar',
        rejectLabel: 'Cancelar',
        accept: () => {
          this.fiscalService.remover(item.id).pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
              toastr.info('Registro removido com sucesso!', 'Exclusão');
              this.entidade.fiscais.splice(index, 1);
            }, (err) => toastr.error(err.error.payload));
        }
      });
    } else {
      this.entidade.fiscais.splice(index, 1);
    }
  }

}
