import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContratoListComponent } from './contrato-list/contrato-list.component';
import { ContratoViewComponent } from './contrato-view/contrato-view.component';
import { ContratoFormComponent } from './contrato-form/contrato-form.component';
import { ContratoRptComponent } from './contrato-rpt/contrato-rpt.component';
import { ContratoPagamentoComponent } from './contrato-pagamento/contrato-pagamento.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: ContratoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: ContratoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: ContratoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: ContratoViewComponent, canActivate: [AuthGuard] },
  { path: 'pagamentos/:id', component: ContratoPagamentoComponent, canActivate: [AuthGuard] },
  { path: 'relatorios', component: ContratoRptComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContratoRoutingModule { }
