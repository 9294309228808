import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MemorialService } from 'administrativo-lib';
import { FuncaoService, Login, Memorial, Produto } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// import * as toastr from 'toastr';

@Component({
  selector: 'lib-produto-licitacao',
  templateUrl: './produto-licitacao.component.html'
})
export class ProdutoLicitacaoComponent implements OnInit, OnDestroy, OnChanges {

  @Input() entidade: Produto;
  @Input() login: Login;

  public lista: Memorial[];

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    public funcaoService: FuncaoService,
    public memorialService: MemorialService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.entidade && this.entidade.id) {
      this.memorialService.filtrar(0, 0, {
        'produto_unidade.produto_id': this.entidade.id,
        relations: 'licitacao,produto_unidade.unidade',
        orderBy: 'licitacao.numero$DESC'
      }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.lista = res.content
        })
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
