import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, LicitacaoTestemunha, Page } from 'eddydata-lib';

@Injectable({
    providedIn: 'root'
})
export class LicitacaoTestemunhaService extends BaseResourceService<LicitacaoTestemunha> {

    constructor(
        protected injector: Injector
    ) {
        super(`licitacao-testemunhas`, injector);
    }

}
