import { CloudArmazenamento } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from './orgao.model';

export class OrgaoConfiguracao extends BaseResourceModel {

  constructor(
    public id?: number,
    public cloud_armazenamento?: CloudArmazenamento,
    public sftp_secure?: boolean,
    public sftp_host?: string,
    public sftp_port?: string,
    public sftp_user?: string,
    public sftp_password?: string,
    public sftp_path?: string,
    public sftp_file_access_link?: string,
    public sftp_file_access_link_transparencia?: string,
    public firebase_conta_servico?: string,
    public firebase_bucket?: string,
    public ad_url?: string,
    public ad_dn?: string,
    public ad_usuario?: string,
    public ad_senha?: string,
    public orgao?: Orgao,
    public login_pncp?: string,
    public senha_pncp?: string,
  ) {
    super();
  }

  static converteJson(json: any): OrgaoConfiguracao {
    return Object.assign(new OrgaoConfiguracao(), json);
  }
}
