import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FuncaoService, GlobalService, Login, OrgaoAssinaturaService } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GpsLiquidacao } from '../../relatorio/liquidacao/gps-liquidacao';
import { RetencaoService } from '../service/retencao.service';

import * as toastr from 'toastr';

@Component({
  selector: 'app-gps-liquidacao-dlg',
  templateUrl: './gps-liquidacao-dlg.component.html'
})
export class GpsLiquidacaoDlgComponent implements OnInit, OnDestroy {

  protected datepipe: DatePipe;
  public ptBR: any;
  public selectedOrdem: string;
  public selectedOpt: string;
  public selectNaoImpresso: boolean;
  public selectUsuario: boolean;
  public dataInicio: Date;
  public dataFinal: Date;
  public numeroInicio: number;
  public numeroFinal: number;
  protected unsubscribe: Subject<void> = new Subject();

  public imaskNumero = {
    mask: Number,
    scale: 0,
    signed: false
  };

  @Input() login: Login;

  constructor(
    protected messageService: MessageService,
    private globalService: GlobalService,
    private funcaoService: FuncaoService,
    protected assinaturaService: OrgaoAssinaturaService,
    private retencaoService: RetencaoService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.selectedOrdem = 'ord1';
    this.selectedOpt = 'opt1';
    this.selectNaoImpresso = false;
    this.selectUsuario = false;
    this.dataInicio = new Date();
    this.dataFinal = new Date();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  async imprimir() {
    const parametros = {};

    let relations = '';
    relations += 'liquidacao,liquidacao.empenho,liquidacao.empenho.favorecido,liquidacao.empenho.favorecido.cidade,ficha,ficha.plano';
    parametros['relations'] = relations;
    parametros['liquidacao.exercicio.id'] = this.login.exercicio.id;
    parametros['liquidacao.orgao.id'] = this.login.orgao.id;
    parametros['liquidacao.anulacao'] = false;

    parametros['OR'] = `ficha.plano.codigo=218830102;!;!;ficha.plano.codigo=218810102`

    if (this.selectedOpt === 'opt1') {
      if (!this.dataFinal || !this.dataFinal) {
        toastr.warning('Informe a data inicial e final');
        return
      } else {
        parametros['liquidacao.data_liquidacao$ge'] = this.datepipe.transform(this.dataInicio, 'yyyy-MM-dd');
        parametros['liquidacao.data_liquidacao$le'] = this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd');
      }
    } else {
      if (!this.numeroInicio && !this.numeroFinal) {
        toastr.warning("Informe o numero final e inicial");
        return
      } 
      if ((this.numeroInicio && !this.numeroFinal) || (!this.numeroInicio && this.numeroFinal)) {
        this.numeroInicio ? parametros['liquidacao.empenho.numero'] = this.numeroInicio : parametros['liquidacao.empenho.numero'] = this.numeroFinal
      } else {
        parametros['liquidacao.empenho.numero$ge'] = this.numeroInicio;
        parametros['liquidacao.empenho.numero$le'] = this.numeroFinal;
      }
    }
    if (this.selectedOrdem === 'ord1') {
      parametros['orderBy'] = 'liquidacao.empenho.numero$ASC';
    } else if (this.selectedOrdem === 'ord2') {
      parametros['orderBy'] = 'liquidacao.empenho.favorecido.nome$ASC';
    } else {
      parametros['orderBy'] = 'liquidacao.data_cadastro$ASC';
    }
    if (this.selectNaoImpresso) {
      parametros['liquidacao.impresso'] = !this.selectNaoImpresso;
    }
    if (this.selectUsuario) {
      parametros['liquidacao.usuario_cadastro.id'] = this.login.usuario.id;
    }

    const base64 = await this.funcaoService.obterBase64('assets/img/previdencia.png');

    this.retencaoService
      .filtrar(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        new GpsLiquidacao(base64).imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );

  }

}
