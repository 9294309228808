import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Aliquota, CompraItem, Login, ParametroContabil } from "eddydata-lib";
import * as toastr from 'toastr';

@Component({
  selector: 'lib-itens-compra',
  templateUrl: './itens-compra-dlg.component.html'
})
export class ItensCompraComponent implements OnInit, OnChanges {

  @Output() onConcluir: EventEmitter<Object> = new EventEmitter()

  @Input() itensCompra: CompraItem[]
  @Input() login: Login

  public retencoes = { ir: 0, csll: 0, cofins: 0, pis: 0, valorBase: 0 }
  public paramContabil: ParametroContabil

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  public ngOnInit(): void {
    this.paramContabil = this.login.parametro.contabil
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.itensCompra && this.itensCompra) {
      this.itensCompra.forEach(item => item['qtd_cal'] = 0)
      this.retencoes = { ir: 0, csll: 0, cofins: 0, pis: 0, valorBase: 0 }
      this.calcularRetencao(this.itensCompra)
    }
  }


  public determinaAliquota(item: CompraItem, aliquotaNome: 'IR' | 'CSLL' | 'COFINS' | 'PIS'): number {
    let aliquota: Aliquota

    if (item?.produto_unidade?.produto?.aliquota) aliquota = item.produto_unidade.produto.aliquota
    else if (item?.produto_unidade?.produto?.material?.aliquota) aliquota = item.produto_unidade.produto.material.aliquota

    if (aliquotaNome === 'IR') return aliquota?.ir
    if (aliquotaNome === 'CSLL') return aliquota?.csll
    if (aliquotaNome === 'COFINS') return aliquota?.cofins
    if (aliquotaNome === 'PIS') return aliquota?.pis_pasep
  }

  public calcularRetencao(lista: CompraItem[], item?: CompraItem) {
    if (item && item.quantidade < item['qtd_cal']) {
      item['qtd_cal'] = 0
      toastr.warning('A quantidade para calculo não pode ser maior que a quantidade comprada!')
      return
    }

    this.retencoes = { ir: 0, csll: 0, cofins: 0, pis: 0, valorBase: 0 }
    lista.forEach(item => {
      let valor = item.valor_unitario * item['qtd_cal']

      this.retencoes.valorBase += (valor)
      this.retencoes.ir += (valor) * (this.determinaAliquota(item, 'IR') / 100)
      this.retencoes.csll += (valor) * (this.determinaAliquota(item, 'CSLL') / 100)
      this.retencoes.cofins += (valor) * (this.determinaAliquota(item, 'COFINS') / 100)
      this.retencoes.pis += (valor) * (this.determinaAliquota(item, 'PIS') / 100)
    })
  }

  public antesSalvar() {
    this.onConcluir.emit(this.retencoes)
  }

}