import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { LayoutSiopsItem } from "./layout-siops-item.model";

export class LayoutSiops extends BaseResourceModel {
  constructor(
    public id?: number,
    public titulo?: string,
    public itens?: LayoutSiopsItem[],
    public exercicio?: Exercicio,
    public tipo?: string,
    public tabela?: string,
    public especie_tabela?: string
    ) {
    super();
  }
  static converteJson(json: any): LayoutSiops {
    return Object.assign(new LayoutSiops(), json);
  }
}