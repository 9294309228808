import { ChequeItem } from 'contabil-lib';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido } from "../compra/favorecido.model";
import { FichaReceita } from '../planejamento/ficha-receita.model';
import { RestituicaoReceita } from "./restituicao-receita.model";

export class RestituicaoReceitaItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public documento?: string,
    public cheque?: boolean,
    public valor?: number,
    public restituicao_receita?: RestituicaoReceita,
    public data_referencia?: Date,
    public ficha?: FichaReceita,
    public favorecido?: Favorecido,
    public editavel?: boolean,
    public cheque_item?: ChequeItem) {
    super();
  }
  static converteJson(json: any): RestituicaoReceitaItem {
    return Object.assign(new RestituicaoReceitaItem(), json);
  }
}