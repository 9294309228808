import { Injector } from "@angular/core";
import { ContratoOcorrenciaService } from "../service/contrato-ocorrencia.service";
import { ContratoOcorrencia, FuncaoService, GlobalService, LoginContabil, Relatorio } from "eddydata-lib";
import { ContratoItemService } from "../service/contrato-item.service";

export class ContratoOcorrenciaRpt {

    constructor(
        private globalService: GlobalService,
        public funcaoService: FuncaoService,
        protected ocorrenciaService: ContratoOcorrenciaService,
        private login: LoginContabil,
        protected contratoItemService: ContratoItemService
    ) { }

    public async gerarRelatorio(ocorrencia: ContratoOcorrencia) {
        Relatorio.imprimirPersonalizado(
            'IMPRESSÃO DE OCORRÊNCIA',
            this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.montarConteudo(ocorrencia), 'landscape', 'IMPRESSÃO DE OCORRÊNCIA',
            {
                linhas: {
                    hLineWidth() {
                        return 1;
                    },
                    vLineWidth() {
                        return 1;
                    },
                    hLineColor() {
                        return 'black';
                    },
                    paddingLeft() {
                        return 3;
                    },
                    paddingRight() {
                        return 3;
                    }
                }
            }, false, false, 'pdf', ``);
    };

    private async montarConteudo(ocorrencia: ContratoOcorrencia): Promise<{}[]> {
        const retorno: {}[] = [];
        const cabecalho = [];

        if (ocorrencia?.reajuste) {

            const headerRegistro = [];

            headerRegistro.push([
                { text: 'Data reajuste:', fontSize: 10, alignment: 'left', bold: true, margin: [10, 4, 0, 4], border: [true, true, false, false] },
                { text: `${this.funcaoService.converteDataBR(ocorrencia?.reajuste?.data_reajuste)}`, fontSize: 9, alignment: 'left', margin: [0, 4, 0, 4], border: [false, true, false, false] },
                { text: 'Espécie:', fontSize: 10, alignment: 'center', bold: true, margin: [0, 4, 0, 4], border: [false, true, false, false] },
                { text: `${ocorrencia?.reajuste?.especie === 'R' ? 'reajuste' : 'estorno'}`, fontSize: 9, alignment: 'center', margin: [0, 4, 0, 4], border: [false, true, false, false] },
                { text: 'Usuário:', fontSize: 10, alignment: 'center', bold: true, margin: [0, 4, 0, 4], border: [false, true, false, false] },
                { text: `${ocorrencia?.usuario?.nome}`, fontSize: 9, alignment: 'left', margin: [0, 4, 0, 4], border: [false, true, true, false] },
            ]);

            headerRegistro.push([
                { text: `Ocorrência:`, fontSize: 10, alignment: 'left', bold: true, margin: [10, 0, 0, 0], border: [true, false, true, false], colSpan: 6 },
                "", "", "", "", ""
            ]);

            headerRegistro.push([
                { text: `${ocorrencia.reajuste.observacao}`, fontSize: 9, alignment: 'left', margin: [10, 4, 0, 4], border: [true, false, true, false], colSpan: 6 },
                "", "", "", "", ""
            ]);

            cabecalho.push([
                { text: 'Ordem', fontSize: 10, alignment: 'center', bold: true, margin: [0, 4, 0, 4] },
                { text: 'Código', fontSize: 10, alignment: 'center', bold: true, margin: [0, 4, 0, 4] },
                { text: 'Descrição', fontSize: 10, alignment: 'center', bold: true, margin: [0, 4, 0, 4] },
                { text: 'Alteração', fontSize: 10, alignment: 'center', bold: true, margin: [0, 4, 0, 4], colSpan: 3 },
                "", ""
            ]);

            const item = ocorrencia.reajuste.contrato_item;
            const dataReajuste = this.funcaoService.converterDataSQL(ocorrencia.reajuste.data_reajuste);

            const valorUnitarioAtualizado = await this.contratoItemService.buscarValorDoItemPorDataDeAdidamentoOuReajuste(item.id, dataReajuste, ocorrencia?.reajuste.id).toPromise();
            const registro = [
                { text: `${item.ordem}`, fontSize: 8, alignment: 'center' },
                { text: `${item.produto_unidade.produto.codigo}`, fontSize: 8, alignment: 'center' },
                { text: `${item.produto_unidade.produto.descricao}`, fontSize: 8, alignment: 'left' },
                { text: `Valor unitário alterado de R$${this.funcaoService.convertToBrNumber(item.valor_unitario)} para R$${this.funcaoService.convertToBrNumber(+valorUnitarioAtualizado + +ocorrencia.reajuste.reajuste_valor)}`, colSpan: 3 },
                "", ""
            ];

            cabecalho.push(registro)

            retorno.push([
                {
                    layout: 'linhas',
                    table: {
                        widths: ['auto', 'auto', 'auto', 'auto', 'auto', '*'],
                        body: headerRegistro
                    }
                },
                {
                    layout: 'linhas',
                    table: {
                        dontBreakRows: true,
                        headerRows: 1,
                        widths: ['auto', 'auto', '*', 'auto', 'auto', 'auto'],
                        body: cabecalho
                    }
                }]);

            return retorno;
        };

        if (ocorrencia.aditamento) {
            const headerRegistro = [];

            headerRegistro.push([
                { text: 'Data aditamento:', fontSize: 10, alignment: 'left', bold: true, margin: [10, 4, 0, 4], border: [true, true, false, false] },
                { text: `${this.funcaoService.converteDataBR(ocorrencia.aditamento?.data_aditamento)}`, fontSize: 9, alignment: 'left', margin: [0, 4, 0, 4], border: [false, true, false, false] },
                { text: 'Espécie:', fontSize: 10, alignment: 'center', bold: true, margin: [0, 4, 0, 4], border: [false, true, false, false] },
                { text: `aditamento`, fontSize: 9, alignment: 'center', margin: [0, 4, 0, 4], border: [false, true, false, false] },
                { text: 'Usuário:', fontSize: 10, alignment: 'center', bold: true, margin: [0, 4, 0, 4], border: [false, true, false, false] },
                { text: `${ocorrencia?.usuario?.nome}`, fontSize: 9, alignment: 'left', margin: [0, 4, 0, 4], border: [false, true, true, false] },
            ]);

            headerRegistro.push([
                { text: `Ocorrência:`, fontSize: 10, alignment: 'left', bold: true, margin: [10, 0, 0, 4], border: [true, false, true, false], colSpan: 6 },
                "", "", "", "", ""
            ]);

            headerRegistro.push([
                { text: `${ocorrencia.aditamento.finalidade}`, fontSize: 9, alignment: 'left', margin: [10, 0, 0, 4], border: [true, false, true, false], colSpan: 6 },
                "", "", "", "", ""
            ]);

            cabecalho.push([
                { text: 'Ordem', fontSize: 10, alignment: 'center', bold: true, margin: [0, 4, 0, 4] },
                { text: 'Código', fontSize: 10, alignment: 'center', bold: true, margin: [0, 4, 0, 4] },
                { text: 'Descrição', fontSize: 10, alignment: 'center', bold: true, margin: [0, 4, 0, 4] },
                { text: 'Alteração', fontSize: 10, alignment: 'center', bold: true, margin: [0, 4, 0, 4], colSpan: 3 },
                "", ""
            ]);

            for (const item of ocorrencia.aditamento.itens) {
                const registro = [
                    { text: `${item.contrato_item.ordem}`, fontSize: 8, alignment: 'center' },
                    { text: `${item.contrato_item.produto_unidade.produto.codigo}`, fontSize: 8, alignment: 'center' },
                    { text: `${item.contrato_item.produto_unidade.produto.descricao}`, fontSize: 8, alignment: 'left' },
                    { text: `Valor unitário alterado de R$${this.funcaoService.convertToBrNumber(item.contrato_item.valor_unitario)} para R$${this.funcaoService.convertToBrNumber(item.aditivo_valor)}`, colSpan: 3 },
                    "", ""
                ];

                cabecalho.push(registro)

            };

            retorno.push([
                {
                    layout: 'linhas',
                    table: {
                        widths: ['auto', 'auto', 'auto', 'auto', 'auto', '*'],
                        body: headerRegistro
                    }
                },
                {
                    layout: 'linhas',
                    table: {
                        dontBreakRows: true,
                        headerRows: 1,
                        widths: ['auto', 'auto', '*', 'auto', 'auto', 'auto'],
                        body: cabecalho
                    }
                }]);

            return retorno;
        };
    };
};