import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { Licitacao } from './licitacao.model';
import { PregaoLance } from './pregao-lance.model';

export class PregaoHistorico extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_cadastro?: Date,
    public cronometro?: string,
    public descricao?: string,
    public obs?: string,
    public lance?: PregaoLance,
    public usuario?: Usuario,
    public licitacao?: Licitacao,
  ) {
    super();
  }
}
