import { ContratoRescisao, ContratoStorage, ContratoTestemunha, Empenho, Usuario, ImovelOcorrencia, ContratoPpp } from 'eddydata-lib';
import { TipoContrato } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { LicitacaoAudesp } from '../licitacao/licitacao-audesp.model';
import { Licitacao } from '../licitacao/licitacao.model';
import { Modalidade } from '../licitacao/modalidade.model';
import { TipoContratacao } from '../licitacao/tipo-contratacao.model';
import { ContratoAditamento } from './contrato-aditamento.model';
import { ContratoFiscal } from './contrato-fiscal.model';
import { ContratoItem } from './contrato-item.model';
import { ContratoOcorrencia } from './contrato-ocorrencia.model';
import { ContratoPncp } from './contrato-pncp.model';
import { ContratoResponsavel } from './contrato-responsavel.model';
import { Favorecido } from './favorecido.model';
import { Prazo } from './prazo.model';

export class Contrato extends BaseResourceModel {
  constructor(
    public id?: number,
    public numero?: string,
    public ano?: number,
    public data_assinatura?: Date,
    public data_inicio?: Date,
    public data_termino?: Date,
    public data_contabilizacao?: Date,
    public processo?: string,
    public gestor_exige?: boolean,
    public gestor?: string,
    public gestor_cpf?: string,
    public gestor_cargo?: string,
    public tipo_contrato?: TipoContrato,
    public tipo?: 'CONTRATO' | 'REGISTRO_PRECO' | 'ATA_REGISTRO',
    public objeto?: string,
    public valor_contrato?: number,
    public valor_servico?: number,
    public valor_material?: number,
    public valor_locacao?: number,
    public valor_garantia?: number,
    public valor_caucao?: number,
    public enviar_email?: boolean,
    public inativo?: boolean,
    public saldo_quantidade?: boolean,
    public saldo_valor?: boolean,
    public unidade_medida?: string,
    public quantidade?: number,
    public parente?: Contrato,
    public licitacao?: Licitacao,
    public modalidade?: Modalidade,
    public orgao?: Orgao,
    public contratos_ppp?: ContratoPpp[],
    public favorecido?: Favorecido,
    public entrega?: Prazo,
    public prazo?: Prazo,
    public tipo_contratacao?: TipoContratacao,
    public licitacao_audesp?: LicitacaoAudesp,
    public licitacao_audesp_obra?: LicitacaoAudesp,
    public itens?: ContratoItem[],
    public responsaveis?: ContratoResponsavel[],
    public ocorrencias?: ContratoOcorrencia[],
    public fiscais?: ContratoFiscal[],
    public testemunhas?: ContratoTestemunha[],
    public selecionado?: boolean,
    public cno?: string,
    public ind_obra?: number,
    public aditamentos?: ContratoAditamento[],
    public rescisoes?: ContratoRescisao[],
    public sequencial_pncp?: number,
    public cadastrado_pncp?: boolean,
    public excluido_pncp?: boolean,
    public cancelado_pncp?: boolean,
    public contrato_pncp?: ContratoPncp,
    public empenhos?: Empenho[],
    public arquivos?: ContratoStorage[],
    public data_prorrogacao?: Date,
    public prazo_resposta?: Date,
    public indice_reajuste?: string,
    public multa_moratoria?: number,
    public permite_subcontratacao?: boolean,
    public porcentagem_subcontratacao?: number,
    public excluido?: boolean,
    public justificativa_exclusao?: string,
    public usuario_exclusao?: Usuario,
    public data_exclusao?: Date,
    public imovel_ocorrencia?: ImovelOcorrencia,
    public lei_14133_2021?: boolean,
  ) {
    super();
  }

  static converteJson(json: any): Contrato {
    return Object.assign(new Contrato(), json);
  }
}
