// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido } from './favorecido.model';

export class FavorecidoContador extends BaseResourceModel {
    constructor(
        public id?: number,
        public nome?: string,
        public cpf_cnpj?: string,
        public telefone?: string,
        public cep?: string,
        public endereco?: string,
        public num_endereco?: string,
        public bairro?: string,
        public municipio?: string,
        public email?: string,
        public crc?: string,
        public favorecido?: Favorecido,
        public data_cadastro?: Date,
        public data_alteracao?: Date,
        public editavel?: boolean
    ) {
        super();
    }
    static converteJson(json: any): FavorecidoContador {
        return Object.assign(new FavorecidoContador(), json);
    }
}
