import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FuncaoService, Exercicio, FormatoExportacao } from 'eddydata-lib';

@Directive()
export class Anexo12ControleDisponibilidade implements OnDestroy {

  protected funcaoService: FuncaoService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number,
    protected exercicio: Exercicio) {
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(dados: any[], formato?: FormatoExportacao): {}[] {
    if (formato && formato !== 'pdf') return this.montarQuadroExportacao(dados);

    const registros: {}[] = [
      [
        {
          text: 'CONTROLE DAS DESPESAS CUSTEADAS COM DISPONIBILIDADE DE CAIXA VINCULADA AO RESTOS A PAGAR CANCELADOS QUE FORAM CONSIDERADOS PARA FINS DE CUMPRIMENTO DO LIMITE',
          alignment: 'center', rowSpan: 2, bold: true, fontSize: 7, border: [true, false, true, true]
        },
        { text: 'RESTOS A PAGAR INSCRITOS COM DISPONIBILIDADE FINANCEIRAL', alignment: 'center', colSpan: 2, bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: '', border: [true, false, true, true] }
      ],
      ['',
        { text: 'Cancelados em (h)', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'Cancelados em (i)', alignment: 'center', bold: true, fontSize: 7 }
      ]
    ];

    let total1 = 0;
    let total2 = 0;

    for (const item of dados) {
      registros.push([
        {
          text: item.grupo, fontSize: 7
        },
        { text: this.funcaoService.convertToBrNumber(item.valor1), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(item.valor2), alignment: 'right', fontSize: 7 }
      ]);

      total1 += +item.valor1;
      total2 += +item.valor2;
    }

    registros.push([
      {
        text: 'SALDO DA DISPONIBILIDADE DE CAIXA VINCULADA AOS RESTOS A PAGAR CANCELADOS NÃO COMPENSADOS', fontSize: 7, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 7, bold: true },
    ]);

    return registros;
  }

  private montarQuadroExportacao(dados: any[]): {}[] {
    const listaItens = new Array();

    listaItens.push({
      'receita_desc': 'CONTROLE DAS DESPESAS CUSTEADAS COM DISPONIBILIDADE DE CAIXA VINCULADA AO RESTOS A PAGAR CANCELADOS QUE FORAM CONSIDERADOS PARA FINS DE CUMPRIMENTO DO LIMITE',
      'inicial': 'RESTOS A PAGAR INSCRITOS COM DISPONIBILIDADE FINANCEIRAL',
      'atualizada': '',
      'arrecadado_empenhada': '',
      'calculo_empenhada': '',
      'liquidada': '',
      'calculo_liquidada': '',
    });

    listaItens.push({
      'receita_desc': '',
      'inicial': 'Cancelados em (h)',
      'atualizada': 'Cancelados em (i)',
      'arrecadado_empenhada': '',
      'calculo_empenhada': '',
      'liquidada': '',
      'calculo_liquidada': '',
    });

    let total1 = 0;
    let total2 = 0;

    for (const item of dados) {
      listaItens.push({
        'receita_desc': item.grupo,
        'inicial': this.funcaoService.convertToBrNumber(item.valor1, 2),
        'atualizada': this.funcaoService.convertToBrNumber(item.valor2, 2),
        'arrecadado_empenhada': '',
        'calculo_empenhada': '',
        'liquidada': '',
        'calculo_liquidada': '',
      });

      total1 += +item.valor1;
      total2 += +item.valor2;
    }

    listaItens.push({
      'receita_desc': 'SALDO DA DISPONIBILIDADE DE CAIXA VINCULADA AOS RESTOS A PAGAR CANCELADOS NÃO COMPENSADOS',
      'inicial': this.funcaoService.convertToBrNumber(total1, 2),
      'atualizada': this.funcaoService.convertToBrNumber(total2, 2),
      'arrecadado_empenhada': '',
      'calculo_empenhada': '',
      'liquidada': '',
      'calculo_liquidada': '',
    });

    listaItens.push({
      'receita_desc': ''
    });

    return listaItens;
  }
}
