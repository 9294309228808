import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, AuthGuardFake } from 'eddydata-lib';
import { TransparenciaAnexoLoaComponent } from './transparencia-anexo-loa/transparencia-anexo-loa.component';
import { TransparenciaArquivoViewComponent } from './transparencia-arquivo-view/transparencia-arquivo-view.component';
import { TransparenciaArquivoComponent } from './transparencia-arquivo/transparencia-arquivo.component';
import { TransparenciaContaArquivoComponent } from './transparencia-conta-arquivo/transparencia-conta-arquivo.component';
import { TransparenciaContaUploadComponent } from './transparencia-conta-upload/transparencia-conta-upload.component';
import { TransparenciaContaComponent } from './transparencia-conta/transparencia-conta.component';
import { TransparenciaDecretoComponent } from './transparencia-decreto/transparencia-decreto.component';
import { TransparenciaDespesaPrevistaComponent } from './transparencia-despesa-prevista/transparencia-despesa-prevista.component';
import { TransparenciaExecucaoBalanceteAnualComponent } from './transparencia-execucao-balacente-anual/transparencia-execucao-balancete-anual.component';
import { TransparenciaExecucaoBalanceteComponent } from './transparencia-execucao-balancete/transparencia-execucao-balancete.component';
import { TransparenciaExecucaoLRFComponent } from './transparencia-execucao-lrf/transparencia-execucao-lrf.component';
import { TransparenciaLDOComponent } from './transparencia-ldo/transparencia-ldo.component';
import { TransparenciaLoaComponent } from './transparencia-loa/transparencia-loa.component';
import { TransparenciaLoginRecuperarSenhaSucessoComponent } from './transparencia-login-recuperar-senha-sucesso/transparencia-login-recuperar-senha-sucesso.component';
import { TransparenciaLoginRecuperarSenhaComponent } from './transparencia-login-recuperar-senha/transparencia-login-recuperar-senha.component';
import { TransparenciaLoginComponent } from './transparencia-login/transparencia-login.component';
import { TransparenciaParametroComponent } from './transparencia-parametro/transparencia-parametro.component';
import { TransparenciaPesquisaComponent } from './transparencia-pesquisa/transparencia-pesquisa.component';
import { TransparenciaReceitaPrevistaComponent } from './transparencia-receita-prevista/transparencia-receita-prevista.component';
import { TransparenciaRegistroComponent } from './transparencia-registro/transparencia-registro.component';
import { TransparenciaRreoComponent } from './transparencia-rreo/transparencia-rreo.component';
import { TransparenciaQuadroEnsinoComponent } from './transparencia-quadro-ensino/transparencia-quadro-ensino.component';
import { TransparenciaQuadroSaudeComponent } from './transparencia-quadro-saude/transparencia-quadro-saude.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./portal-transparencia/portal-transparencia.module').then(m => m.PortalTransparenciaModule)
  },
  { path: 'login', component: TransparenciaLoginComponent },
  { path: 'recuperar-senha', component: TransparenciaLoginRecuperarSenhaComponent },
  { path: 'recuperar-senha-sucesso', component: TransparenciaLoginRecuperarSenhaSucessoComponent },
  { path: 'transparencia/login', component: TransparenciaLoginComponent },
  { path: 'transparencia/registro', component: TransparenciaRegistroComponent },
  { path: 'admin/transparencia/parametros-transparencia', component: TransparenciaParametroComponent, canActivate: [AuthGuard] },
  { path: 'transparencia/decreto', component: TransparenciaDecretoComponent, canActivate: [AuthGuardFake] },
  { path: 'transparencia/planejamento', component: TransparenciaLoaComponent, canActivate: [AuthGuardFake] },
  { path: 'transparencia/receita-prevista/:ano', component: TransparenciaReceitaPrevistaComponent, canActivate: [AuthGuardFake] },
  { path: 'transparencia/despesa-prevista/:ano', component: TransparenciaDespesaPrevistaComponent, canActivate: [AuthGuardFake] },
  {
    path: 'transparencia/despesa-nivel',
    loadChildren: () => import('./module-lib/despesa-nivel-ci.module').then(m => m.DespesaNivelCIModule)
  },
  { path: 'transparencia/pesquisa/:filtro', component: TransparenciaPesquisaComponent, canActivate: [AuthGuardFake] },
  { path: 'transparencia/contas-publica', component: TransparenciaContaComponent, canActivate: [AuthGuardFake] },
  { path: 'transparencia/contas-publica-arquivos/:especie', component: TransparenciaContaArquivoComponent, canActivate: [AuthGuardFake] },
  { path: 'transparencia/contas-publica-anexos/:especie', component: TransparenciaContaUploadComponent, canActivate: [AuthGuard] },
  { path: 'transparencia/transparencia-arquivos', component: TransparenciaArquivoComponent, canActivate: [AuthGuard] },
  { path: 'transparencia/transparencia-arquivos-view/:grupo', component: TransparenciaArquivoViewComponent, canActivate: [AuthGuardFake] },
  { path: 'transparencia/execucao-orcamentaria/balancetes', component: TransparenciaExecucaoBalanceteComponent, canActivate: [AuthGuardFake] },
  { path: 'transparencia/execucao-orcamentaria/balancetes-anuais', component: TransparenciaExecucaoBalanceteAnualComponent, canActivate: [AuthGuardFake] },
  { path: 'transparencia/execucao-orcamentaria/lrf', component: TransparenciaExecucaoLRFComponent, canActivate: [AuthGuardFake] },
  { path: 'transparencia/rreo', component: TransparenciaRreoComponent, canActivate: [AuthGuardFake] },
  { path: 'transparencia/ldo', component: TransparenciaLDOComponent, canActivate: [AuthGuardFake] },
  { path: 'transparencia/loa', component: TransparenciaAnexoLoaComponent, canActivate: [AuthGuardFake] },
  { path: 'transparencia/quadros-ensino', component: TransparenciaQuadroEnsinoComponent, canActivate: [AuthGuardFake] },
  { path: 'transparencia/quadros-saude', component: TransparenciaQuadroSaudeComponent, canActivate: [AuthGuardFake] },

  {
    path: 'transparencia/fornecedores', loadChildren: () => import('./transparencia-fornecedor/transparencia-fornecedor.module').then(m => m.TransparenciaFornecedorModule)
  },
  {
    path: 'transparencia/ajuda',
    loadChildren: () => import('./transparencia-ajuda/transparencia-ajuda.module').then(m => m.TransparenciaAjudaModule)
  },
  {
    path: 'transparencia/receitas-orcamentarias',
    loadChildren: () => import('./transparencia-receita/transparencia-receita.module').then(m => m.TransparenciaReceitaModule)
  },
  {
    path: 'transparencia/receitas-extra-orcamentarias',
    loadChildren: () => import('./transparencia-receita-extra/transparencia-receita-extra.module').then(m => m.TransparenciaReceitaExtraModule)
  },
  {
    path: 'transparencia/despesas-extra-orcamentarias',
    loadChildren: () => import('./transparencia-despesa-extra/transparencia-despesa-extra.module').then(m => m.TransparenciaDespesaExtraModule)
  },
  {
    path: 'transparencia/empenhos-orcamentarios',
    loadChildren: () => import('./transparencia-empenho/transparencia-empenho.module').then(m => m.TransparenciaEmpenhoModule)
  },
  {
    path: 'transparencia/empenhos-resto',
    loadChildren: () => import('./transparencia-empenho-resto/transparencia-empenho-resto.module').then(m => m.TransparenciaEmpenhoRestoModule)
  },
  {
    path: 'transparencia/liquidacoes-orcamentarias',
    loadChildren: () => import('./transparencia-liquidacao/transparencia-liquidacao.module').then(m => m.TransparenciaLiquidacaoModule)
  },
  {
    path: 'transparencia/liquidacoes-restos',
    loadChildren: () => import('./transparencia-liquidacao-resto/transparencia-liquidacao-resto.module').then(m => m.TransparenciaLiquidacaoRestoModule)
  },
  {
    path: 'transparencia/pagamentos-orcamentarios',
    loadChildren: () => import('./transparencia-pagamento/transparencia-pagamento.module').then(m => m.TransparenciaPagamentoModule)
  },
  {
    path: 'transparencia/pagamentos-extras',
    loadChildren: () => import('./transparencia-pagamento-extra/transparencia-pagamento-extra.module')
      .then(m => m.TransparenciaPagamentoExtraModule)
  },
  {
    path: 'transparencia/pagamentos-restos',
    loadChildren: () => import('./transparencia-pagamento-resto/transparencia-pagamento-resto.module')
      .then(m => m.TransparenciaPagamentoRestoModule)
  },
  {
    path: 'transparencia/atas-registro-precos',
    loadChildren: () => import('./transparencia-ata/transparencia-ata.module').then(m => m.TransparenciaAtaModule)
  },
  {
    path: 'admin/transparencia/atas-registro-anexos',
    loadChildren: () => import('./transparencia-ata-anexo/transparencia-ata-anexo.module').then(m => m.TransparenciaAtaAnexoModule)
  },
  {
    path: 'admin/transparencia/empenhos-orcamentarios-anexos',
    loadChildren: () => import('./transparencia-empenho-anexo/transparencia-empenho-anexo.module').then(m => m.TransparenciaEmpenhoAnexoModule)
  },
  {
    path: 'admin/transparencia/eventos-holerite',
    loadChildren: () => import('./transparencia-evento-holerite/transparencia-evento-holerite.module').then(m => m.TransparenciaEventoHoleriteModule)
  },
  {
    path: 'admin/transparencia/contratos-anexos',
    loadChildren: () => import('./transparencia-contrato-anexo/transparencia-contrato-anexo.module').then(m => m.TransparenciaContratoAnexoModule)
  },
  {
    path: 'admin/transparencia/licitacoes-anexos',
    loadChildren: () => import('./transparencia-licitacao-anexo/transparencia-licitacao-anexo.module').then(m => m.TransparenciaLicitacaoAnexoModule)
  },
  {
    path: 'transparencia/entre-orgaos',
    loadChildren: () => import('./transparencia-repasse/transparencia-repasse.module').then(m => m.TransparenciaRepasseModule)
  },
  {
    path: 'transparencia/folha-pagamento',
    loadChildren: () => import('./transparencia-folha/transparencia-folha.module').then(m => m.TransparenciaFolhaModule)
  },
  {
    path: 'transparencia/cargos',
    loadChildren: () => import('./transparencia-cargo/transparencia-cargo.module').then(m => m.TransparenciaCargoModule)
  },
  {
    path: 'transparencia/servidores-afastados',
    loadChildren: () => import('./transparencia-afastado/transparencia-afastado.module').then(m => m.TransparenciaAfastadoModule)
  },
  {
    path: 'transparencia/servidores-cedidos',
    loadChildren: () => import('./transparencia-cedido/transparencia-cedido.module').then(m => m.TransparenciaCedidoModule)
  },
  {
    path: 'transparencia/contratos',
    loadChildren: () => import('./transparencia-contrato/transparencia-contrato.module').then(m => m.TransparenciaContratoModule)
  },
  {
    path: 'transparencia/convenios',
    loadChildren: () => import('./transparencia-convenio/transparencia-convenio.module').then(m => m.TransparenciaConvenioModule)
  },
  {
    path: 'transparencia/licitacoes',
    loadChildren: () => import('./transparencia-licitacao/transparencia-licitacao.module').then(m => m.TransparenciaLicitacaoModule)
  },
  {
    path: 'transparencia/compras',
    loadChildren: () => import('./transparencia-compra/transparencia-compra.module').then(m => m.TransparenciaCompraModule)
  },
  {
    path: 'transparencia/chamamentos-publico',
    loadChildren: () => import('./transparencia-chamamento/transparencia-chamamento.module').then(m => m.TransparenciaChamamentoModule)
  },
  {
    path: 'transparencia/ordem-cronologica',
    loadChildren: () => import('./transparencia-ordem-cronologica/transparencia-ordem-cronologica.module').then(m => m.TransparenciaOrdemCronologicaModule)
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./module-lib/usuario-transparencia.module').then(m => m.UsuarioTransparenciaModule)
  },
  {
    path: 'orgaos',
    loadChildren: () => import('./module-lib/orgao-transparencia.module').then(m => m.OrgaoTransparenciaModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
