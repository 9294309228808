import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import {
  BaseResourceListComponent, Coluna, DateFormatPipe, Filtro, FormatoExportacao, GlobalService, LiquidacaoResto,
  LoginContabil
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LiquidacaoRestoService } from '../service/liquidacao-resto.service';
import { ConfirmationService } from 'primeng/api';

declare var $: any;

@Component({
  selector: 'app-liquidacao-resto-list',
  templateUrl: './liquidacao-resto-list.component.html'
})
export class LiquidacaoRestoListComponent extends BaseResourceListComponent<LiquidacaoResto, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public especieSelect = 'LRP';
  public listaEspecies: Array<any>;
  public ptBR: any;
  public datepipe: DatePipe;
  public data1: Date;
  public data2: Date;
  public valorPago: number;
  public valorTotalLiquidado: number;

  public liquidacaoAnular: LiquidacaoResto;
  public visualizarAnulacao: boolean = false;
  public parcelarAnulacao: boolean = false;
  public liquidacaoAtualizar: LiquidacaoResto
  public visualizarAtualizacao = false;
  public superafit = false;

  public empenhosSelecionados: any[] = [];
  public numerosEmpenhos: any[] = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private globalService: GlobalService,
    protected confirmationService: ConfirmationService,
    private liquidacaoService: LiquidacaoRestoService) {
    super(liquidacaoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(item: LiquidacaoResto): boolean {
    return !item.anulacao && !item.anulado_total
  }

  protected relations(): string {
    return 'empenho,empenho.favorecido,empenho.exercicio,empenho.compra';
  }

  protected condicoesGrid(): {} {
    const parametros = {};
    this.datepipe = new DatePipe('pt');
    parametros['data_liquidacao$ge'] = this.datepipe.transform(this.data1, 'yyyy-MM-dd');
    parametros['data_liquidacao$le'] = this.datepipe.transform(this.data2, 'yyyy-MM-dd');
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['exercicio.id'] = this.login.exercicio.id;

    sessionStorage.removeItem('/liquidacoes-restos-pagar_data1');
    sessionStorage.removeItem('/liquidacoes-restos-pagar_data2');

    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['data_liquidacao$DESC', 'id$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['id', 'empenho.numero', 'valor_liquidado', 'empenho.subelemento', 'empenho.favorecido.cpf_cnpj'],
      date: ['data_liquidacao'],
      text: ['empenho.favorecido.nome', 'empenho.subelemento_nome'],
    };
  }

  public beforeInit(): void {
    this.usarFiltroPersonalizado = true;
    this.usarExtendido = true;
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    const dt1: string = sessionStorage.getItem('/liquidacoes-restos-pagar_data1');
    const dt2: string = sessionStorage.getItem('/liquidacoes-restos-pagar_data2');
    if (!dt1) {
      this.data1 = new DateFormatPipe().transform(this.datepipe.transform(new Date(`01/01/${(this.login.exercicio.ano - 10)}`), 'yyyy-MM-dd'), []);
    } else {
      this.data1 = new DateFormatPipe().transform(dt1, []);
    }
    if (!dt2) {
      this.data2 = new DateFormatPipe().transform(this.datepipe.transform(new Date(), 'yyyy-MM-dd'), []);
    } else {
      this.data2 = new DateFormatPipe().transform(dt2, []);
    }
  }

  protected afterInit(): void {
    this.listaEspecies = [
      { id: 'LEO', nome: 'ORÇAMENTÁRIA' },
      { id: 'LRP', nome: 'RESTOS A PAGAR' }
    ];
  }

  public anular(item: LiquidacaoResto, item2: any) {
    const isProcessado: boolean = new Date(item.data_liquidacao).getFullYear() < this.login.exercicio.ano;

    this.liquidacaoAnular = item;
    this.superafit = false;

    if (isProcessado) {
      this.confirmationService.confirm({
        message: `Deseja parcelar esta liquidação de restos processadas ou cancelar?`,
        header: `Atenção`,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Parcelar',
        rejectLabel: 'Cancelar',
        acceptIcon: "none",
        rejectIcon: "none",
        closeOnEscape: true,
        key: 'pergunta1',
        accept: async () => {
          this.parcelarAnulacao = true;
        },
        reject: async (button: number) => {
          if (button === 2) {
            return;
          } else {
            this.confirmationService.confirm({
              message: `Cancelamento da liquidação de restos processados será feita por Insuficiência Financeira ou Superávit Financeiro?`,
              header: `Atenção`,
              icon: 'pi pi-exclamation-triangle',
              acceptLabel: 'Insuficiência Financeira',
              rejectLabel: 'Superávit Financeiro',
              acceptIcon: "none",
              rejectIcon: "none",
              closeOnEscape: false,
              key: 'pergunta2',
              accept: async () => {
                this.visualizarAnulacao = true;
                this.valorTotalLiquidado = item2.total_liquidado
              },
              reject: async (button: number) => {
                if (button === 2) {
                  return;
                } else {
                  this.visualizarAnulacao = true;
                  this.superafit = true;
                  this.valorTotalLiquidado = item2.total_liquidado
                }
              }
            });
          }
        }
      });
    } else {
      this.visualizarAnulacao = true;
      this.valorTotalLiquidado = item2.total_liquidado
    }
  }
  public atualizarVisualizar() {
    this.parcelarAnulacao = false
    this.onClick();
  }


  protected acaoRemover(model: LiquidacaoResto): Observable<LiquidacaoResto> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Data', coluna: 'data_liquidacao', agrupar: true, bold: true },
      { titulo: 'Número', coluna: 'empenho.numero' },
      { titulo: 'Parcela', coluna: 'parcela' },
      { titulo: 'Beneficiario', coluna: 'empenho.favorecido.nome' },
      { titulo: 'Despesa', coluna: 'empenho.subelemento_nome' },
      { titulo: 'Historico', coluna: 'historico' },
      { titulo: 'Vencimento', coluna: 'data_vencimento' },
      { titulo: 'Valor', coluna: 'valor_liquidado', alignment: 'right', decimais: 2 }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'empenho,empenho.favorecido,orgao,exercicio';
    this.liquidacaoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE LIQUIDAÇÕES RESTOS A PAGAR',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem liquidações restos', ['auto', 'auto', '*', 'auto', '*', 'auto', 'auto'], lista.content, ['valor_liquidado']);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public onChangeLiquidacao(value: any, especie: any) {
    if (especie === 'LEO') {
      this.router.navigate(['liquidacoes-orcamentaria']);
    }
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public editarAnulacao(item: LiquidacaoResto) {
    return item.anulacao && this.login.usuario.sistemas_administrador?.split(',').includes('contabil')
  }

  public alterarAnular(item: LiquidacaoResto) {
    this.liquidacaoAtualizar = item;
    this.visualizarAtualizacao = true;
  }

  public obterColunasFiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];

    retorno.push({ titulo: 'Data Liquidação', coluna: 'data_liquidacao', tipo: 'Date', alignment: 'center', padrao: true, filtro1: this.data1, filtro2: this.data2 });
    retorno.push({ titulo: 'Nº Empenho', coluna: 'empenho.numero', tipo: 'Number', padrao: true });
    retorno.push({ titulo: 'Valor liquidado', coluna: 'valor_liquidado', alignment: 'center', tipo: 'Number', cols: 3 });
    retorno.push({ titulo: 'Parcela', coluna: 'parcela', alignment: 'center', tipo: 'Number', cols: 3, padrao: false });
    retorno.push({ titulo: 'Ano', coluna: 'empenho.ano', alignment: 'center', tipo: 'Number', cols: 3, padrao: true });
    retorno.push({ titulo: 'Valor retido', coluna: 'total_retido', alignment: 'center', tipo: 'Number' });
    retorno.push({ titulo: 'Nº da compra', coluna: 'empenho.compra.numero', tipo: 'Number' });
    retorno.push({ titulo: 'Convênio', coluna: 'empenho.convenio.numero', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Contrato', coluna: 'empenho.contrato.numero', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Favorecido', coluna: 'empenho.favorecido.nome', alignment: 'center', tipo: 'String', padrao: false });
    retorno.push({ titulo: 'Documento', coluna: 'documento', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Documento Fiscal', coluna: 'documento_fiscal_info.documento_fiscal.numero_documento', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Código da despesa', coluna: 'empenho.subelemento', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Descrição da despesa', coluna: 'empenho.subelemento_nome', alignment: 'center', tipo: 'String' });

    return retorno;
  }

  public ImprimirNotaPorEmpenhosSelecionados(item: LiquidacaoResto) {
    if (item['selecionado'] === true) {
      item['selecionado'] = false;
      // Encontre o índice do item na lista this.empenhosSelecionados
      const index = this.empenhosSelecionados.findIndex(e => e.empenho.id === item.empenho.id);
      if (index > -1) {
        this.empenhosSelecionados.splice(index, 1);
      }
    } else {
      item['selecionado'] = true;
      // Verifique se o item já não está na lista
      if (!this.empenhosSelecionados.some(e => e.empenho.id === item.empenho.id)) {
        this.empenhosSelecionados.push(item);
      }
    }
    this.numerosEmpenhos = [];
    for (const liquidacao of this.empenhosSelecionados) {
      this.numerosEmpenhos.push(liquidacao.empenho.numero)
    }
  }
}
