import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Contrato, ContratoTestemunha, EddyAutoComplete, FuncaoService, LoginContabil, Pessoa, PessoaService } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ContratoTestemunhaService } from './service/contrato-testemunha.service';

@Component({
  selector: 'app-contrato-testemunha',
  templateUrl: './contrato-testemunha.component.html'
})
export class ContratoTestemunhaComponent implements OnInit, OnDestroy {

  public pessoaAutoComplete: EddyAutoComplete<Pessoa>;
  public pessoaAtual: Pessoa;

  @Input() entidade: Contrato;
  @Input() login: LoginContabil;
  @Input() visualizar: Boolean;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected confirmationService: ConfirmationService,
    protected funcaoService: FuncaoService,
    protected pessoaService: PessoaService,
    protected testemunhaService: ContratoTestemunhaService,
  ) { }

  ngOnInit(): void {
    this.pessoaAutoComplete = new EddyAutoComplete(null, this.pessoaService,
      'id', ['nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public adicionar(pessoa?: Pessoa, ignorarNotificacao?: boolean) {
    if (!pessoa) pessoa = this.pessoaAtual;
    if (pessoa) {
      if (!this.entidade.testemunhas) {
        this.entidade.testemunhas = [];
      }

      if (this.entidade.testemunhas.find((f) => f.pessoa.id === pessoa.id)) {
        if (!ignorarNotificacao) toastr.warning('Testemunha já incluído neste contrato!');
      } else {
        const item = new ContratoTestemunha()
        item.pessoa = pessoa;
        item.contrato = this.entidade;

        this.testemunhaService.inserir(item).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
          if (!ignorarNotificacao)
            toastr.success('Registro salvo com sucesso!');
          this.entidade.testemunhas.push(res);
        }, error => {
          this.funcaoService.acaoErro(error)
        });

      }
      pessoa = undefined;
      this.pessoaAtual = undefined;
    }
  }

  public remover(item: ContratoTestemunha, index: number) {
    if (item.id) {
      this.confirmationService.confirm({
        message: 'Deseja realmente remover a testemunha?',
        header: 'Exclusão',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Confirmar',
        rejectLabel: 'Cancelar',
        accept: () => {
          this.testemunhaService.remover(item.id).pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
              toastr.info('Registro removido com sucesso!', 'Exclusão');
              this.entidade.testemunhas.splice(index, 1);
            }, (err) => toastr.error(err.error.payload));
        }
      });
    } else {
      this.entidade.testemunhas.splice(index, 1);
    }
  }

  public podeVisualizar() {
    if (this.visualizar === true) {
      return true;
    }
    if(this.visualizar === false){
      return false;
    }
  }

}
