import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, InventarioTransferencia } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InventarioTransferenciaService extends BaseResourceService<InventarioTransferencia> {

  constructor(
    protected injector: Injector
  ) {
    super(`inventario-transferencias`, injector);
  }

  public cancelar(id: number, usuario: number, motivo_status: string): Observable<InventarioTransferencia> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/cancelar/${id}/${usuario}`, { motivo_status }, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public recusar(id: number, usuario: number, motivo_status: string): Observable<InventarioTransferencia> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/recusar/${id}/${usuario}`, { motivo_status }, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public confirmar(id: number, usuario: number, tipo?: 'S' | 'T' | 'R'): Observable<InventarioTransferencia> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/confirmar/${id}/${usuario}/${tipo}`, undefined, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }
}
