import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LicitacaoService } from 'administrativo-lib';
import { FormatoExportacao, GlobalService, Licitacao, LicitacaoPipe, Login, Proponente } from 'eddydata-lib';
import * as toastr from 'toastr';
import { MapaPrecoRpt } from './mapa-preco-rpt';


@Component({
  selector: 'app-mapa-preco-rpt',
  templateUrl: './mapa-preco-rpt.component.html'
})
export class MapaPrecoRptComponent implements OnInit {

  @Input() public licitacao: Licitacao;

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public listFormato = ['pdf', 'docx'];
  public formato: FormatoExportacao = 'pdf';
  public ptBR: any;

  public opcao: 'DESC' | 'NOME' = 'NOME';
  public opcoes: { id: 'DESC' | 'NOME', nome: string }[] = [
    { id: 'NOME', nome: 'Nome do item' },
    { id: 'DESC', nome: 'Descrição do item' }
  ];
  public listaFavorecidos: Proponente[] = [];
  public data: Date;

  private login: Login;
  private dados: Licitacao;

  constructor(
    private licitacaoService: LicitacaoService
  ) {
    this.ptBR = new GlobalService().obterDataBR();
    this.login = GlobalService.obterSessaoLogin();
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  ngOnInit(): void {
    this.data = new Date();
  }


  protected tituloRelatorio(): string {
    return `${this.login.orgao.nome}`;
  }

  protected subTituloRelatorio(): string {
    return `MAPA DE PREÇOS
     Licitação: ${new LicitacaoPipe().transform(this.licitacao.numero)} - Processo: ${new LicitacaoPipe().transform(this.licitacao.processo)} - Modalidade: ${this.licitacao.modalidade.nome}`;
  }

  get selecionados(): Proponente[] {
    return this.listaFavorecidos
      .filter((s) => s['selecionado']);
  }

  public imprimir() {
    if (!this.validar())
      return;

    new MapaPrecoRpt().imprimir(this.tratarFavorecidos(this.dados), this.data, this.tituloRelatorio(), this.subTituloRelatorio(), this.formato, this.opcao == 'DESC');
  }

  private carregarDados() {
    const parametros = {
      'id': this.licitacao.id,
      'orgao.id': this.licitacao.orgao.id,
      'exercicio_id': this.licitacao.exercicio.id,
      relations: ['itens', 'itens.produto_unidade', 'itens.produto_unidade.produto',
        'itens.produto_unidade.unidade', 'proponentes', 'proponentes.favorecido', 'itens.propostas', 'itens.propostas.proponente',
        'itens.propostas.proponente.favorecido'].join(','),
      orderBy: ['itens.ordem'].join(',')
    };

    this.licitacaoService.filtrar(1, -1, parametros)
      .subscribe((page) => {
        this.dados = page.content[0];
        this.listaFavorecidos = this.dados
          .proponentes.map((l) => {
            l['selecionado'] = true;
            return l;
          });
      });
  }

  private tratarFavorecidos(licitacao: Licitacao): Licitacao {
    const retorno: Licitacao = JSON.parse(JSON.stringify(licitacao));
    retorno.proponentes = retorno.proponentes
      .filter((p) => this.selecionados.filter((s) => s.id === p.id).length > 0);
    retorno.itens = retorno.itens.map((i) => {
      i.propostas = i.propostas
        .filter((p) => this.selecionados
          .filter((s) => s.id === p.proponente.id).length > 0);
      return i;
    })
    return retorno;
  }

  public validar(): boolean {

    if (this.selecionados.length === 0) {
      toastr.warning(`Selecione os fornecedores!`);
      return false;
    }

    return true;
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
    this.carregarDados();
  }
}
