import { Component, Injector, Input, OnInit } from '@angular/core';
import { Login } from '../../entidade/login/login';
import { FuncaoService } from '../../util/funcao.service';
import { ConfirmationService } from 'primeng/api';
import { LicitacaoService, MemorialService } from 'administrativo-lib';
import { Licitacao } from '../../entidade/licitacao/licitacao.model';
import { PNCPService } from '../../util/pncp.service';
import { ProgressoService } from '../../components/progresso/service/progresso.service';
import { Memorial } from '../../entidade/licitacao/memorial.model';
import { LicitacaoStorageService } from 'compra-lib';
import { Proposta } from '../../entidade/licitacao/proposta.model';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'app-pncp-licitacao',
  templateUrl: './pncp-licitacao.component.html',
  styleUrls: ['./pncp-licitacao.component.css']
})
export class PncpLicitacaoComponent implements OnInit {

  @Input() login: Login;

  codConvocatorio: number = 0;
  lista: Array<any> = new Array<any>();
  tipoFiltro: number = 0;
  todos: boolean = false;
  envio: boolean = true;
  enviarResultadosAuto: boolean = false;
  pendentes: boolean = false;
  historicoCompra: Array<any> = new Array<any>();
  relationsLicitacao = 'modalidade,setor.unidade,exercicio,orgao';

  //Para modificações
  numeroLicitacao: string = '';
  listaEnviados: Array<Licitacao> = new Array<Licitacao>();
  licitacao: Licitacao = null;
  listaItensEnvio: Array<Memorial> = new Array<Memorial>();
  propostaCancelando: Proposta = new Proposta();
  listaEnvioResultados: Array<Proposta> = new Array<Proposta>();
  public colunaOrdenacao: string;
  public ascendente = true;

  resultados: Array<{ licitacao: string, sucesso: boolean, mensagem: string }> = new Array<{ licitacao: string, sucesso: boolean, mensagem: string }>();
  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected licitacaoService: LicitacaoService,
    protected memorialService: MemorialService,
    protected storageService: LicitacaoStorageService,
    protected confirmationService: ConfirmationService,
    protected progressoService: ProgressoService,
    private funcaoService: FuncaoService,
    private pncpService: PNCPService) {
  }

  ngOnInit(): void {
    this.filtrarLicitacoes();
  }

  filtrarLicitacoes() {
    //Se o filtro vier com -1, ele busca os enviados
    if (this.tipoFiltro < 0) {
      this.envio = false;
      this.licitacaoService.filtrar(0, 30, {
        'orgao.id': this.login.orgao.id, orderBy: 'id$DESC',
        relations: this.relationsLicitacao, cadastrado_pncp: true, situacao_pncp$not_null: true
      }).subscribe((res) => {
        this.listaEnviados = res.content;
        return;
      });
    } else {
      this.listaEnviados = [];
      this.envio = true;
    }

    const parametros = {};
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['exercicio.id'] = this.login.exercicio.id;
    parametros['cadastrado_pncp'] = false;
    parametros['lei_14133_2021'] = true;
    parametros['arquivos.documento_pncp'] = true;
    parametros['itens.id$not_null'] = true;
    parametros['ignorar_pncp'] = false;
    if (this.tipoFiltro == 1) {//Quando tiver 1, busca apenas os não inseridos
      parametros['sequencial_pncp$null'] = true;
    } else if (this.tipoFiltro == 2) {//Se vier 2, procura apenas os que estão em retificação
      parametros['sequencial_pncp$not_null'] = true;
    }//Se vier qualquer outro numero, traz todos.
    parametros['relations'] = this.relationsLicitacao;

    this.licitacaoService.filtrar(0, -1, parametros).subscribe(async (res) => {
      this.lista = res.content;

      this.lista.filter(i => {
        //Se não possui memorial, ou sem 1 arquivo principal, não permite o envio.
        if (i.itens.length < 0) {
          this.lista.splice(this.lista.indexOf(i), 1);
        }
      });

    }, e => {
      this.funcaoService.acaoErro(e);
    });
  }

  public podeIncluir(url?: string) {
    if (this.login) {
      if (new FuncaoService().campoJsonToken(this.login.token, "administrador") == true) {
        return true;
      }

      return new FuncaoService().podeIncluir(this.login, url)
    }
    return false;
  }

  marcarTodos() {
    this.lista.forEach(i => {
      i.enviar = this.todos;
    });
  }

  confirmarEnvio() {
    const envios: Array<number> = new Array<number>();
    let texto: string = '';
    for (const i of this.lista) {
      if (i.enviar) {
        envios.push(i.id);
        texto += '\n' + (i.numero.length == 8 ? i.numero.replace(/(\d{4})(\d{4})/g, '\$1\/\$2') : i.numero.replace(/(\d{5})(\d{4})/g, '\$1\/\$2')) + ` (${i.sequencial_pncp ? 'Retificação' : 'Novo'})`;
      }
    }

    this.confirmationService.confirm({
      header: 'Confirmar Envio',
      message: `<pre>Confirma o envio das Licitações a seguir para o PNCP? \nAlterações posteriores apenas via retificação ou exclusão: \n${texto}</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo envios.',
      rejectLabel: 'Não',
      accept: async () => {
        this.enviar(envios);
      }
    });
  }

  private async enviar(envios: Array<number>) {
    this.pncpService.inserirContratacao(envios).subscribe((idTransacao) => {
      this.progressoService.show(idTransacao, (retorno) => {
        this.resultados = retorno;
        this.filtrarLicitacoes();
      });
    }, e => {
      this.funcaoService.acaoErro(e);
    });
  }

  limparModificacao() {
    this.listaItensEnvio = new Array<Memorial>();
    this.resultados = [];
    this.licitacao = null;
    this.envio = true;
    this.filtrarLicitacoes();
  }

  async buscarLicitacao(item?: Licitacao) {
    let p = new Promise<void>(async (resolve, reject) => {
      if (item) {
        this.licitacao = item;
        //Carregamento de arquivos e memorial retirado da base, por sobrecarga em cenários com +1000
        const arqs = await this.storageService.filtrar(0, -1, { 'licitacao.id': item.id }).toPromise();
        this.licitacao.arquivos = [].concat(arqs.content);

        const itens = await this.memorialService
          .filtrar(0, -1, { relations: 'propostas.proponente.favorecido', 'licitacao.id': item.id, orderBy: 'ordem$ASC' })
          .toPromise();

        this.licitacao.itens = [].concat(itens.content);
        resolve();
      } else {
        if (!this.numeroLicitacao || this.numeroLicitacao == '') {
          if (!this.pendentes) {
            this.filtrarLicitacoes();
          } else {
            this.filtrarPendentes();
          }

          reject();
        }

        let licitacoes = await this.licitacaoService.filtrar(1, -1,
          {
            'orgao.id': this.login.orgao.id, 'exercicio.id': this.login.exercicio.id,
            relations: this.relationsLicitacao, cadastrado_pncp: true, numero: this.numeroLicitacao
          }).toPromise();

        if (licitacoes.content.length > 1) {
          this.listaEnviados = licitacoes.content;
          toastr.info('Mais de uma licitação encontrada')
          reject();
        } else {
          const lic = licitacoes.content[0];
          if (lic && lic.cadastrado_pncp) {
            this.buscarLicitacao(lic);
            reject();
          } else {
            toastr.info('Licitação não encontrada ou não cadastrada no PNCP')
            reject();
          }
        }
      }
    });

    p.then(() => {
      const validos = this.licitacao.arquivos.filter(a => a.tipo_documento || a.documento_pncp);
      this.licitacao.arquivos = validos;
      this.calcularCodConvocatorio();
    });
  }


  calcularCodConvocatorio() {
    if (this.licitacao.modalidade == null || this.licitacao.modo_disputa == 0) {
      this.codConvocatorio = 0;
      return;
    }

    //Prioridade 1 - Se for dispensa com disputa
    if (this.licitacao.modo_disputa == 4) {
      this.codConvocatorio = 2;
      return;
    }

    //Prioridade 2
    //Quando é Dispensa de licitação ou Inexigibilidade, entra como Ato que autoriza contratação direta, código PNCP 3
    if ((this.licitacao.modalidade.codigo_pncp == 8 || this.licitacao.modalidade.codigo_pncp == 9) && this.licitacao.modo_disputa != 4) {
      this.codConvocatorio = 3;
      return;
    }

    //Prioridade 3
    //Quando é selecionado preenchimento auxiliar, vai entrar como chamamento público código pncp 4
    if (this.licitacao.chamamento_publico) {
      this.codConvocatorio = 4;
      return;
    }

    //Prioridade 4
    if ((this.licitacao.modalidade.codigo_pncp >= 1 && this.licitacao.modalidade.codigo_pncp <= 7) || this.licitacao.modalidade.codigo_pncp == 13) {
      this.codConvocatorio = 1;
      return;
    }

    this.codConvocatorio = 0;
  }

  filtrarPendentes() {
    if (!this.pendentes) {
      this.licitacaoService.buscarPendenciasPncp(this.login.orgao.id, this.login.exercicio.id).subscribe((res) => {
        this.listaEnviados = res.content;
      });
    } else {
      this.tipoFiltro = -1;
      this.filtrarLicitacoes()
    }
  }

  public reordenar(coluna: string, toggle: boolean, lista: any) {
    if (!coluna) return;

    if (!toggle) this.ascendente = true;
    else this.ascendente = !this.ascendente;

    this.colunaOrdenacao = coluna;
    if (this.colunaOrdenacao === 'numero') {
      lista.sort((a, b) => (this.ascendente ? a.numero - b.numero : b.numero - a.numero))
    }
    if (this.colunaOrdenacao === 'processo') {
      lista.sort((a, b) => {
        if (this.ascendente) {
          return a.processo.localeCompare(b.processo);
        } else {
          return b.processo.localeCompare(a.processo);
        }
      });
    }
    if (this.colunaOrdenacao === 'modalidade.nome') {
      lista.sort((a, b) => {
        if (this.ascendente) {
          return a.modalidade.nome.localeCompare(b.modalidade.nome);
        } else {
          return b.modalidade.nome.localeCompare(a.modalidade.nome);
        }
      });
    }
    if (this.colunaOrdenacao === 'objeto') {
      lista.sort((a, b) => {
        if (this.ascendente) {
          return a.objeto.localeCompare(b.objeto);
        } else {
          return b.objeto.localeCompare(a.objeto);
        }
      });
    }
    
    if (this.colunaOrdenacao === 'data_abertura') {
      lista.sort((a, b) => (this.ascendente ? new Date(a.data_abertura).getTime() - new Date(b.data_abertura).getTime() : new Date(b.data_abertura).getTime() - new Date(a.data_abertura).getTime()));
    }
    if (this.colunaOrdenacao === 'valor_estimado') {
      lista.sort((a, b) => (this.ascendente ? +a.valor_estimado - +b.valor_estimado : +b.valor_estimado - +a.valor_estimado));
    }
    if (this.colunaOrdenacao === 'valor_licitado') {
      lista.sort((a, b) => (this.ascendente ? +a.valor_licitado - +b.valor_licitado : +b.valor_licitado - +a.valor_licitado));
    }
  }

}
