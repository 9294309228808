import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { TipoContratacaoListComponent } from './tipo-contratacao-list/tipo-contratacao-list.component';
import { TipoContratacaoFormComponent } from './tipo-contratacao-form/tipo-contratacao-form.component';
import { TipoContratacaoViewComponent } from './tipo-contratacao-view/tipo-contratacao-view.component';


const routes: Routes = [
  { path: '', component: TipoContratacaoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: TipoContratacaoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: TipoContratacaoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: TipoContratacaoViewComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TipoContratacaoRoutingModule { }
