// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { LiquidacaoResto } from './liquidacao-resto.model';
import { FichaExtra } from './ficha-extra.model';
import { EmpenhoExtra, PagamentoResto } from 'eddydata-lib';

export class RetencaoResto extends BaseResourceModel {
  constructor(
    public id?: number,
    public aux?: number,
    public data_vencimento?: Date,
    public cei?: string,
    public gps?: string,
    public valor_retido?: number,
    public valor_aliquota?: number,
    public ficha?: FichaExtra,
    public liquidacao?: LiquidacaoResto,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public editavel?: boolean,
    public anulado?: boolean,
    public retencao_anulada?: RetencaoResto,
    public anulacoes?: RetencaoResto[],
    public historico?: string,
    public data_anulacao?: Date,
    public base_IR?: number,
    public valor_IR?: number,
    public base_agregado?: number,
    public valor_agregado?: number,
    public base_csll?: number,
    public valor_csll?: number,
    public base_cofins?: number,
    public valor_cofins?: number,
    public pagamento_anulado?: PagamentoResto,
    public base_pp?: number,
    public valor_pp?: number,
    public empenho_extra?: EmpenhoExtra,
  ) {
    super();
  }

  static converteJson(json: any): RetencaoResto {
    return Object.assign(new RetencaoResto(), json);
  }
}
