import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RecebimentoRoutingModule } from './recebimento-routing.module';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { IMaskModule } from 'angular-imask';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RecebimentoListComponent } from './recebimento-list/recebimento-list.component';
import { RecebimentoViewComponent } from './recebimento-view/recebimento-view.component';
import { PlanilhaArrecadacaoComponent } from './planilha-arrecadacao/planilha-arrecadacao.component';
import { RecebimentoFormComponent } from './recebimento-form/recebimento-form.component';
import { RecebimentoRptComponent } from './recebimento-rpt/recebimento-rpt.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SharedModule } from 'eddydata-lib';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ImportarRecebimentoComponent } from './importar-recebimento/importar-recebimento.component';
import { TreeModule } from 'primeng/tree';
import { FileUploadModule } from 'primeng/fileupload';
import { ExcluirRecebimentoDlgComponent } from './excluir-recebimento-dlg/excluir-recebimento-dlg.component';

@NgModule({
  declarations: [
    RecebimentoListComponent,
    RecebimentoViewComponent,
    PlanilhaArrecadacaoComponent,
    RecebimentoFormComponent,
    RecebimentoRptComponent,
    ImportarRecebimentoComponent,
    ExcluirRecebimentoDlgComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    RecebimentoRoutingModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    RadioButtonModule,
    ConfirmDialogModule,
    TreeModule,
    FileUploadModule
  ],
  providers: [MessageService, ConfirmationService]
})
export class RecebimentoModule { }
