import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, OrdemPagamentoItem } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class OrdemPagamentoItemService extends BaseResourceService<OrdemPagamentoItem> {

  constructor(
    protected injector: Injector
  ) {
    super(`ordens-pagamento-item`, injector);
  }
}
