import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { EmpenhoService } from 'administrativo-lib';
import { BaseResourceListComponent, Coluna, Empenho, Filtro, Login, LoginContabil } from 'eddydata-lib';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'lib-historico-favorecido',
  templateUrl: './historico-favorecido.component.html'
})
export class HistoricoFavorecidoComponent extends BaseResourceListComponent<Empenho, LoginContabil> implements OnChanges, OnDestroy {

  protected unsubscribe: Subject<void> = new Subject();

  @Input() login: Login;
  @Input() favorecido: number;
  @Output() callback: EventEmitter<any> = new EventEmitter();

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    protected injector: Injector,
    private empenhoService: EmpenhoService,
  ) {
    super(empenhoService, injector)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['favorecido'] && this.favorecido) {
      this.preencherGrid();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao_id']: this.login.orgao.id,
      ['exercicio_id']: this.login.exercicio.id,
      ['especie']: 'EMO',
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['data_empenho$DESC', 'historico'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['numero'],
      date: ['data_empenho'],
      text: ['historico'],
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: Empenho): Observable<Empenho> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return null;
  }

  protected imprimirPDF(): void {
  }

  protected relations(): string {
    return '';
  }

  public preencherGrid() {
    if (!this.favorecido) {
      return;
    }

    const param = this.obterParametros();
    param['favorecido_id'] = this.favorecido;

    this.empenhoService
      .filtrar(this.paginaCorrente,
        this.login.limite,
        this.relations() ? Object.assign({}, { relations: this.relations() }, param) : param)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.lista = lista.content;
          this.paginaTotal = lista.totalPages;
        },
        error => alert('erro ao retornar lista')
      );
  }

  public importarHistorico(historico: string) {
    $('#dialogHistoricoFavorecido').modal('hide');
    this.callback.emit(historico);
  }

}
