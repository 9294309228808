import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { AliquotaFormComponent } from './aliquota-form/aliquota-form.component';
import { AliquotaListComponent } from './aliquota-list/aliquota-list.component';


const routes: Routes = [
  { path: '', component: AliquotaListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: AliquotaFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: AliquotaFormComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AliquotasRoutingModule { }
