// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Tombamento } from './tombamento.model';

export class TombamentoKit extends BaseResourceModel {
  constructor(
    public id?: number,
    public descricao?: string,
    public orgao?: Orgao,
    public tombamentos?: Tombamento[],
  ) {
    super();
  }

  static converteJson(json: any): TombamentoKit {
    return Object.assign(new TombamentoKit(), json);
  }
}
