import { Banco } from '../banco';

export class Bradesco extends Banco {
    public getNumero() {
        return '237';
    }

    private getCampoLivre() {

        let campo = this.boleto.getAgencia() + this.boleto.getCarteira() + this.boleto.getNossoNumero() + this.boleto.getContaCorrente() + '0';

        return campo;
    }

    private getCampo1() {
        let campo = this.getNumero() + this.boleto.getMoeda() + this.getCampoLivre().substring(0, 5);

        return this.boleto.getDigitoCampo(campo, 2);
    }

    private getCampo2() {
        let campo = this.getCampoLivre().substring(5, 15);

        return this.boleto.getDigitoCampo(campo, 1);
    }

    private getCampo3() {
        let campo = this.getCampoLivre().substring(15, 25);

        return this.boleto.getDigitoCampo(campo, 1);
    }

    private getCampo4() {
        let campo = this.getNumero() +
            this.boleto.getMoeda() +
            this.boleto.getFatorVencimento() +
            this.boleto.getValorTitulo() +
            this.boleto.getAgencia() +
            this.boleto.getCarteira() +
            this.boleto.getNossoNumero() +
            this.boleto.getContaCorrente() + '0';

        return this.boleto.getDigitoCodigoBarras(campo);
    }

    private getCampo5() {
        let campo = this.boleto.getFatorVencimento() + this.boleto.getValorTitulo();
        return campo;
    }

    public getCodigoBarras() {
        let campo = this.getNumero() + this.boleto.getMoeda().toString() + this.getCampo4() +
            this.boleto.getFatorVencimento() + this.boleto.getValorTitulo() + this.boleto.getAgencia() +
            this.boleto.getCarteira() + this.boleto.getNossoNumero() + this.boleto.getContaCorrente() + '0';

        return campo;
    }

    public getLinhaDigitavel() {
        return this.getCampo1().substring(0, 5) + '.' + this.getCampo1().substring(5) + '  ' +
            this.getCampo2().substring(0, 5) + '.' + this.getCampo2().substring(5) + '  ' +
            this.getCampo3().substring(0, 5) + '.' + this.getCampo3().substring(5) + '  ' +
            this.getCampo4() + '  ' + this.getCampo5();
    }

    /**
     * Recupera a carteira no padrao especificado pelo banco
     * @author Gladyston Batista/Eac Software
     */
    public getCarteiraFormatted() {
        return this.boleto.getCarteira();
    }

    /**
     * Recupera a agencia / codigo cedente no padrao especificado pelo banco
     * @author Gladyston Batista/Eac Software
     */
    public getAgenciaCodCedenteFormatted() {
        return this.boleto.getAgencia() + ' / ' + this.boleto.getContaCorrente() + '-' + this.boleto.getDvContaCorrente();
    }

    /**
     * Recupera o nossoNumero no padrao especificado pelo banco
     * @author Gladyston Batista/Eac Software
     */
    public getNossoNumeroFormatted() {
        return this.boleto.getNossoNumero();
    }
}