import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../util/shared.module';
import { IMaskModule } from 'angular-imask';
import { MessageService } from 'primeng/api';
import { SelectButtonModule } from 'primeng/selectbutton';
import { LoginCidadaoComponent } from './login-cidadao.component';
import { CidadaoRecuperarSenhaComponent } from './cidadao-recuperar-senha/cidadao-recuperar-senha.component';
import { CidadaoSenhaSucessoComponent } from './cidadao-senha-sucesso/cidadao-senha-sucesso.component';
import { CidadaoRegistroComponent } from './cidadao-registro/cidadao-registro.component';
import { ToastModule } from 'primeng/toast';

@NgModule({
  declarations: [
    LoginCidadaoComponent,
    CidadaoRecuperarSenhaComponent,
    CidadaoSenhaSucessoComponent,
    CidadaoRegistroComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    IMaskModule,
    SelectButtonModule,
    ToastModule
  ],
  exports: [
    LoginCidadaoComponent,
    CidadaoRecuperarSenhaComponent,
    CidadaoSenhaSucessoComponent,
    CidadaoRegistroComponent
  ],
  providers: [MessageService]
})
export class LoginCidadaoModule { }
