// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Cidade } from '../geo/cidade.model';
import { Nacionalidade } from './nacionalidade.model';
import { Orgao } from './orgao.model';
import { Raca } from './raca.model';

export class Pessoa extends BaseResourceModel {
  constructor(
    public id?: number,
    public cartao?: number,
    public aux?: number,
    public especie?: string,
    public nome?: string,
    public apelido?: string,
    public cpf_cnpj?: string,
    public estado_civil?: string,
    public pai?: string,
    public mae?: string,
    public conjuge?: string,
    public rg?: string,
    public rg_uf?: string,
    public rg_data?: Date,
    public pis_pasep?: string,
    public titulo_eleitor?: string,
    public titulo_zona?: number,
    public titulo_secao?: number,
    public carteira_motorista?: string,
    public carteira_categoria?: string,
    public carteira_emissao?: string,
    public carteira_validade?: Date,
    public sexo?: string,
    public telefone?: string,
    public celular?: string,
    public cep?: string,
    public endereco?: string,
    public complemento?: string,
    public bairro?: string,
    public numero?: string,
    public email?: string,
    public email_profissional?: string,
    public municipio?: string,
    public data_nascimento?: Date,
    public local_nascimento?: string,
    public profissao?: string,
    public crm?: string,
    public peso?: number,
    public nacionalidade?: Nacionalidade,
    public raca?: Raca,
    public cidade?: Cidade,
    public cargo? : string,
    public matricula?: string,
    public orgao?: Orgao
  ) {
    super();
  }

  static converteJson(json: any): Pessoa {
    return Object.assign(new Pessoa(), json);
  }
}
