import { Directive, Injector, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { QuadroEnsinoService } from '../service/quadro-ensino.service';
import {
  FuncaoService, GlobalService, Login,
  Exercicio, FuncaoGoverno, Relatorio, FormatoExportacao, Coluna
} from 'eddydata-lib';
import { Assinaturas } from 'administrativo-lib';

@Directive()
export class Quadro2Ensino implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected quadroServico: QuadroEnsinoService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected dataInicial: Date,
    protected dataFinal: Date,
    protected funcao?: FuncaoGoverno,
    protected injector?: Injector,
    protected assinatura_ensino?: boolean) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async listar(orgaos: number[]) {
    return await this.quadroServico
      .obterQuadro2(this.mes, this.exercicio.id, orgaos, this.dataInicial, this.dataFinal).toPromise();
  }

  public montarRelatorio(orgaos: number[], formato?: FormatoExportacao) {
    if (!formato) formato = 'pdf';

    this.quadroServico.obterQuadro2(this.mes, this.exercicio.id, orgaos, this.dataInicial, this.dataFinal)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(
            'QUADRO 2 - RECEITAS VINCULADAS - ENSINO'
            , this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.conteudo(dados),
            'portrait', 'QUADRO 2 - RECEITAS VINCULADAS - ENSINO',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            });
        } else {
          this.funcaoService.exportar(formato, this.conteudoExportar(dados), 'QUADRO 2 - RECEITAS VINCULADAS - ENSINO', this.colunasRelatorio())
        }
      });
  }

  private async conteudo(dados: any[]): Promise<{}[]> {
    let referencia = '';
    if (this.mes) {
      referencia = `${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase()}/${this.exercicio.ano}`;
    } else if (this.dataInicial && this.dataFinal) {
      let dt = this.dataInicial.toString().split("-");
      referencia = `${dt[2]}/${dt[1]}/${dt[0]}`;
      dt = this.dataFinal.toString().split("-");
      referencia += ` à ${dt[2]}/${dt[1]}/${dt[0]}`;
    } else {
      referencia += this.exercicio.ano;
    }

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `REFERÊNCIA: ${referencia}`,
        alignment: 'center',
        fontSize: 11,
        margin: [5, 5, 5, 5],
        bold: true,
        colSpan: 6
      }, '', '', '', '', ''],
      [{
        text: 'FONTE',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'CÓD.APLIC.',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'CLASSIF. ECONÔMICA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'PREVISÃO INICIAL',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'PREVISÃO ATUALIZ',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'ARRECADADA ATÉ O TRIMESTRE',
        alignment: 'center',
        bold: true, fontSize: 6
      }]
    ];

    // monta o agrupamento do relatório
    let grupos = this.funcaoService.agrupar(dados[0], 'grupo',
      ['previsao_inicial', 'previsao_atualizada', 'arrecadada']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    for (const grupo of grupos) {
      registros.push([
        {
          text: grupo.grupo,
          alignment: 'center',
          bold: true,
          colSpan: 6
        }, '', '', '', '', '']);
      total1 += +grupo.totalizadores['previsao_inicial'];
      total2 += +grupo.totalizadores['previsao_atualizada'];
      total3 += +grupo.totalizadores['arrecadada'];
      // RECURSOS --------------------------------------------------------------------------------
      const recursos = this.funcaoService.agrupar(grupo.registros, 'recurso',
        ['previsao_inicial', 'previsao_atualizada', 'arrecadada']);
      for (const recurso of recursos) {
        registros.push([
          {
            text: recurso.grupo, bold: true, fontSize: 7
          },
          {
            text: ''
          },
          {
            text: ''
          },
          {
            text: this.funcaoService.convertToBrNumber(recurso.totalizadores['previsao_inicial']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(recurso.totalizadores['previsao_atualizada']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(recurso.totalizadores['arrecadada']),
            alignment: 'right', bold: true, fontSize: 7
          }
        ]);

        for (const item of recurso.registros) {
          registros.push([
            {
              text: ''
            },
            {
              text: item.aplicacao === 'null null' ? '000 0000' : `${item.aplicacao} ${item.variavel ? item.variavel : ''}`, fontSize: 7
            },
            {
              text: item.receita, fontSize: 7
            },
            { text: item.receita.startsWith('19229901') ? '' : this.funcaoService.convertToBrNumber(item.previsao_inicial), alignment: 'right', fontSize: 7 },
            { text: item.receita.startsWith('19229901') ? '' : this.funcaoService.convertToBrNumber(item.previsao_atualizada), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(item.arrecadada), alignment: 'right', fontSize: 7 }
          ]);
        }
      }
    }

    // FUNDEB -------------------------------------------------
    registros.push([
      {
        text: 'FUNDEB',
        alignment: 'center',
        bold: true,
        colSpan: 6
      }, '', '', '', '', '']);
    grupos = this.funcaoService.agrupar(dados[1], 'grupo',
      ['previsao_inicial', 'previsao_atualizada', 'arrecadada']);
    total1 = 0;
    total2 = 0;
    total3 = 0;

    for (const grupo of grupos) {
      registros.push([
        {
          text: grupo.grupo,
          alignment: 'center',
          bold: true,
          colSpan: 6
        }, '', '', '', '', '']);
      total1 += +grupo.totalizadores['previsao_inicial'];
      total2 += +grupo.totalizadores['previsao_atualizada'];
      total3 += +grupo.totalizadores['arrecadada'];

      // RECURSOS --------------------------------------------------------------------------------
      const recursos = this.funcaoService.agrupar(grupo.registros, 'recurso',
        ['previsao_inicial', 'previsao_atualizada', 'arrecadada']);
      for (const recurso of recursos) {
        registros.push([
          {
            text: recurso.grupo, bold: true, fontSize: 7
          },
          {
            text: ''
          },
          {
            text: ''
          },
          {
            text: this.funcaoService.convertToBrNumber(recurso.totalizadores['previsao_inicial']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(recurso.totalizadores['previsao_atualizada']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(recurso.totalizadores['arrecadada']),
            alignment: 'right', bold: true, fontSize: 7
          }
        ]);

        for (const item of recurso.registros) {
          registros.push([
            {
              text: ''
            },
            {
              text: item.aplicacao === 'null null' ? '000 0000' : `${item.aplicacao} ${item.variavel ? item.variavel : ''}`, fontSize: 7
            },
            {
              text: item.receita, fontSize: 7
            },
            { text: this.funcaoService.convertToBrNumber(item.previsao_inicial), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(item.previsao_atualizada), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(item.arrecadada), alignment: 'right', fontSize: 7 }
          ]);
        }
      }
    }

    registros.push([
      {
        text: 'TOTAL', fontSize: 7, bold: true
      },
      {
        text: ''
      },
      {
        text: ''
      },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 7, bold: true }
    ]);

    registros.push([
      { text: '', fontSize: 7, bold: true, colSpan: 6, border: [false, false, false, false] },
      '', '', '', '', ''
    ]);

    if (this.exercicio.ano <= 2022) {
      this.complementoFundeb2022(registros, dados);
    } else if (this.exercicio.ano >= 2023) {
      this.complementoFundeb2023(registros, dados);
    };

    let assinaturas
    if (this.assinatura_ensino) {
      const ass = new Assinaturas(this.login.orgao, this.injector);
      assinaturas = await ass.dadosAssinatura(50, false, null, false, this.assinatura_ensino);
    }

    return this.assinatura_ensino ? [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*', 55, 55, 55],
          body: registros
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*'],
          body: assinaturas
        }
      }
    ] : [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*', 55, 55, 55],
        body: registros
      }
    }];
  }

  public complementoFundeb2023(registros: any, dados: any) {
    registros.push([
      { text: 'RECEITAS DA COMPLEMENTAÇÃO DO FUNDEB', bold: true, colSpan: 4 },
      '', '', '',
      { text: 'Transferências recebidas', bold: true, alignment: 'center' },
      { text: 'Receitas de aplicações financeiras', bold: true, alignment: 'center' }
    ]);

    registros.push([
      {
        text: 'Complementação da União VAAF', fontSize: 7, bold: true, colSpan: 4
      }, '', '', '',
      {
        text: `${dados[2][0].arrecadada}`
      },
      {
        text: `${dados[2][0].receita_financeira}`
      }
    ]);

    registros.push([
      {
        text: 'Complementação da União VAAT', fontSize: 7, bold: true, colSpan: 4
      }, '', '', '',
      {
        text: `${dados[2][1].arrecadada}`
      },
      {
        text: `${dados[2][1].receita_financeira}`
      }
    ]);

    registros.push([
      {
        text: 'Complementação da União VAAR', fontSize: 7, bold: true, colSpan: 4
      }, '', '', '',
      {
        text: `${dados[2][2].arrecadada}`
      },
      {
        text: `${dados[2][2].receita_financeira}`
      }
    ]);
  };


  public complementoFundeb2022(registros: any, dados: any) {
    registros.push([
      { text: 'RECEITAS DA COMPLEMENTAÇÃO DO FUNDEB', bold: true, colSpan: 6 },
      '', '', '', '', ''
    ]);

    registros.push([
      {
        text: 'Complementação da União VAAF', fontSize: 7, bold: true, colSpan: 5
      }, '', '', '', '',
      {
        text: `${dados[2][0].arrecadada}`
      }
    ]);

    registros.push([
      {
        text: 'Complementação da União VAAT', fontSize: 7, bold: true, colSpan: 5
      }, '', '', '', '',
      {
        text: `${dados[2][1].arrecadada}`
      }
    ]);
  };

  public colunasRelatorio(): Coluna[] {
    return [
      { titulo: 'Grupo', coluna: 'grupo' },
      { titulo: 'Fonte', coluna: 'recurso' },
      { titulo: 'Cod. Aplicação', coluna: 'aplicacao' },
      { titulo: 'Classificação Econômica', coluna: 'receita' },
      { titulo: 'Previsão Inicial', coluna: 'previsao_inicial', decimais: 2 },
      { titulo: 'Previsão Atualizada', coluna: 'previsao_atualizada', decimais: 2 },
      { titulo: 'Arrecadação', coluna: 'arrecadada', decimais: 2 },
      { titulo: 'Receita Financeira', coluna: 'receita_financeira', decimais: 2 }
    ]
  }

  public conteudoExportar(lista: any[]) {
    return [
      ...this.normalizarDados(lista[0]),
      ...this.normalizarDados(lista[1]),
      ...this.normalizarDados(lista[2])
    ]
  }

  // public normalizarDados(lista: []) {
  //   return lista.map((item: any) => {
  //     return {
  //       grupo: item.grupo,
  //       recurso: item.recurso,
  //       aplicacao: item.aplicacao,
  //       receita: item.receita,
  //       previsao_inicial: item.previsao_inicial,
  //       previsao_atualizada: item.previsao_atualizada,
  //       arrecadada: item.arrecadada,
  //       receita_financeira: item.receita_financeira
  //     }
  //   })
  // }

  public normalizarDados(lista: []) {
    const linhas: {}[] = []

    const totalGrupo = this.funcaoService.agrupar(lista, 'grupo', ['previsao_inicial', 'previsao_atualizada', 'arrecadada', 'receita_financeira'])

    totalGrupo.forEach(itemA => {
      linhas.push({
        grupo: `${itemA.grupo} - TOTALIZADO`,
        previsao_inicial: itemA.totalizadores['previsao_inicial'],
        previsao_atualizada: itemA.totalizadores['previsao_atualizada'],
        arrecadada: itemA.totalizadores['arrecadada'],
        receita_financeira: itemA.totalizadores['receita_financeira']
      })

      const totalRecurso = this.funcaoService.agrupar(itemA.registros, 'recurso', ['previsao_inicial', 'previsao_atualizada', 'arrecadada', 'receita_financeira'])

      totalRecurso.forEach(itemB => {
        linhas.push({
          grupo: itemA.grupo,
          recurso: `${itemB.grupo} - TOTALIZADO`,
          previsao_inicial: itemB.totalizadores['previsao_inicial'],
          previsao_atualizada: itemB.totalizadores['previsao_atualizada'],
          arrecadada: itemB.totalizadores['arrecadada'],
          receita_financeira: itemB.totalizadores['receita_financeira']
        })

        itemB.registros.forEach(itemC => {
          linhas.push({
            grupo: itemC.grupo,
            recurso: itemC.recurso,
            aplicacao: itemC.aplicacao,
            receita: itemC.receita,
            previsao_inicial: itemC.previsao_inicial,
            previsao_atualizada: itemC.previsao_atualizada,
            arrecadada: itemC.arrecadada,
            receita_financeira: itemC.receita_financeira
          })
        })
      })
    })

    return linhas

  }

}
