import { Component, OnInit, Input, Injector } from '@angular/core';
import { EtiquetaAdiantamento } from '../relatorio/etiqueta-adiantamento';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Login } from 'eddydata-lib';
import { AdiantamentoService } from 'administrativo-lib';

@Component({
  selector: 'lib-etiqueta-protocolo-dlg',
  templateUrl: './etiqueta-protocolo-dlg.component.html'
})
export class EtiquetaProtocoloDlgComponent implements OnInit {

  public processoInicial: number;
  public processoFinal: number;

  @Input() login: Login;

  protected unsubscribe: Subject<void> = new Subject();

  public imaskConfig = {
    mask: Number,
    scale: 0,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: false,
    normalizeZeros: false,
    radix: ','
  };

  constructor(
    private messageService: MessageService,
    private adiantamentoService: AdiantamentoService,
    protected injector: Injector
  ) { }

  ngOnInit() {
  }

  public imprimir() {
    const parametros = {};
    parametros['relations'] = 'empenho.favorecido';
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['numero$ge'] = this.processoInicial;
    parametros['numero$le'] = this.processoFinal;
    parametros['orderBy'] = 'numero';
    this.adiantamentoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        const etiqueta = new EtiquetaAdiantamento(this.injector);
        etiqueta.imprimir(lista.content, this.login, this.messageService);
      });
  }

}
