import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PreLiquidacao } from "eddydata-lib";

@Component({
  selector: 'app-pre-liquidacao-consulta-dlg',
  templateUrl: './pre-liquidacao-consulta-dlg.component.html'
})
export class PreLiquidacaoConsultaDlgComponent {

  /**
   * Declaração de variáveis
   */
  @Input() public lista: PreLiquidacao[];
  @Output() callback: EventEmitter<PreLiquidacao> = new EventEmitter();

  public visualizar: boolean = false;


  /**
   * Construtor com as injeções de dependencias
   */
  constructor() { }

  public selecionarPre(pre: PreLiquidacao): void {
    this.callback.emit(pre);
  }
}