import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import {
  BaseResourceFormComponent, FuncaoService, GlobalService, Unidade,
  LoginContabil, Executora, Orgao, ExecutoraService, OrgaoService, UnidadeService, PNCPService, EddyAutoComplete, Usuario, UsuarioService
} from 'eddydata-lib';
import * as toastr from 'toastr';

@Component({
  selector: 'lib-unidade-form',
  templateUrl: './unidade-form.component.html'
})
export class UnidadeFormComponent extends BaseResourceFormComponent<Unidade, LoginContabil> implements OnInit, AfterViewInit {

  /**
   * Declaração de variáveis
   */
  @ViewChild('codigo') inputField: ElementRef;

  public valorCampo: ElementRef; // usado para testar se o valor do campo ja foi preenchido

  public entity: Executora = new Executora();

  public orgao: Orgao;
  public listaAtos: Array<any>;
  public listaTipos: Array<any>;
  public listaExecutoras = new Array<any>();
  public listaMembros = new Array<any>();

  public usuarioAutoComplete: EddyAutoComplete<Usuario>;

  public mes: string;

  precadastropncp: boolean = false;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private route: ActivatedRoute,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected executoraService: ExecutoraService,
    protected orgaoService: OrgaoService,
    protected pncpService: PNCPService,
    protected confirmationService: ConfirmationService,
    protected unidadeService: UnidadeService,
    private usuarioService: UsuarioService) {
    super(new Unidade(), injector, Unidade.converteJson, unidadeService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected parametrosExtras(): {} {
    return { relations: 'ppa,orgao,membros.pessoa,responsavel_fatura' };
  }

  protected afterInit(): void {
    this.carregarAutocomplete();

    this.valorCampo = this.inputField;
    this.listaAtos = this.globalService.obterListaAtoUnidades();
    this.listaTipos = this.globalService.TipoUnidade();
    this.loadOrgao();
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);

    if (this.currentActionRoute === 'novo') {
      this.unidadeService.proximoNumero(this.login.orgao.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.entidadeForm.get('codigo').setValue(res);
        });
    }
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected async podeAlterar(item: Unidade): Promise<boolean> {
    return this.login.usuario.sistema != 'almoxarifado';
  }

  protected afterLoad() {
    this.loadExecutora(this.entidade.id);
    this.listaMembros = this.entidade.membros;

    this.usuarioAutoComplete.id = this.entidade.responsavel_fatura ? this.entidade.responsavel_fatura.id : null;
  }

  ngAfterViewInit() {
    new GlobalService().calendarMascara();
  }

  private loadOrgao() {
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.orgaoService.obterPorId(params['orgao']).pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (res) => {
              if (res) {
                this.orgao = res;
                this.entidade.orgao = res;
                this.entidadeForm.get('orgao').setValue(res);
              } else {
                this.messageService.add({ severity: 'error', summary: 'Atenção!', detail: 'Órgão não localizado!' });
                this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
              }
            }
          );
      });
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      codigo: [null, [Validators.required]],
      nome: [null, [Validators.required]],
      cpf: [null, [Validators.required]],
      telefone: [null],
      responsavel: [null, [Validators.required]],
      cargo: [null, [Validators.required]],
      ato: [null, [Validators.required]],
      ativo: [true, [Validators.required]],
      tribunal: [null, [Validators.required]],
      ordenador: [null],
      cargo_ordenador: [null],
      tesoureiro: [null],
      cargo_tesoureiro: [null],
      presidente_conselho: [null],
      cargo_presidente_conselho: [null],
      tipo: ['OUTROS'],
      ppa: [this.login.ppa, [Validators.required]],
      orgao: [null],
      executoras: [null],
      responsavel_fatura: [null],
      membros: [null],
      cadastrado_pncp: [null],
      email: [null],
    });
  }

  public updateExecutora(event: any) {
    this.listaExecutoras.push(event);
  }

  public updateMembro(event: any) {
    this.listaMembros.push(event);
  }

  protected beforeSubmit() {
    try {
      if (this.entidadeForm.get('codigo').value === '') {
        throw new Error('Informe o código da unidade!');
      }
      if (this.entidadeForm.get('nome').value === '') {
        throw new Error('Informe o nome da unidade!');
      }
      if (this.entidadeForm.get('ato').value === '' || this.entidadeForm.get('ato').value === '0') {
        throw new Error('Informe o ato jurídico!');
      }
      this.entidadeForm.get('orgao').setValue(this.orgao);
      this.entidadeForm.get('ppa').setValue(this.login.ppa);
      this.entidadeForm.get('executoras').setValue(this.listaExecutoras);
      this.entidadeForm.get('membros').setValue(this.listaMembros);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(ent: Unidade) {
    if (this.currentActionRoute === 'novo') {
      this.router.navigate(['/unidades', ent.id, 'editar', this.orgao.id]);
      this.valorCampo = this.inputField;
    } else {
      this.loadExecutora(ent.id);
      this.messageService.add({ severity: 'info', summary: 'Validação', detail: 'Unidade salva com sucesso!' });
    }
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
  private loadExecutora(id: number) {
    if (id) {
      this.executoraService.filtrar(1, -1,
        { relations: 'unidade', 'unidade.id': id }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaExecutoras = res ? res.content : new Array<Executora>();
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  public orgaoConversor(orgao: Orgao) {
    return orgao ? orgao.codigo + ' - ' + orgao.nome : '';
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/planejamento']);
        break;
      default:
        this.sair();
        break;
    }
  }

  verificarPreCadastroPncp() {
    this.pncpService.consultarUnidade(this.login.orgao.cnpj.replace(/\D/g, ''), this.entidadeForm.get('codigo').value)
      .pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
        this.entidadeForm.get('cadastrado_pncp').setValue(true);
        this.submitForm();
        this.precadastropncp = true;
        this.entidade.cadastrado_pncp = true;
      }, e => {
        if (+e.error.status == 404 || +e.error.status == 422) {
          toastr.info(e.error.message);
          this.precadastropncp = true;
        } else {
          toastr.error(e.message);
        }
      });
  }

  cadastrarUnidadePncp(atualizacao?: boolean) {
    const cnpj = this.login.orgao.cnpj.replace(/\D/g, '');
    const ibge = this.login.orgao.ibge;
    const unidade = this.entidadeForm.get('codigo').value.replace(/\D/g, '');
    const nome = this.entidadeForm.get('nome').value;

    if (!cnpj || cnpj.length < 14) { toastr.error('CNPJ inválido'); return; }
    if (!ibge) { toastr.error('IBGE inválido, cadastre o IBGE do orgão'); return; }
    if (!unidade) { toastr.error('Código de Unidade Inválido'); return; }
    if (!nome) { toastr.error('Nome inválido'); return; }

    if (!this.login.token_pncp) {
      toastr.warning('Você não está autenticado para o PNCP');
      return;
    }

    if (atualizacao) {
      this.confirmationService.confirm({
        message: `Deseja atualizar a unidade ao PNCP?`,
        header: `Atualização PNCP`,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim, confirmo atualizacao.',
        rejectLabel: 'Não',
        accept: async () => {
          this.pncpService.atualizarUnidade(cnpj, ibge, unidade, nome).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
            this.verificarPreCadastroPncp();
          }, e => {
            toastr.error(e.error.message);
          });
        }
      });
    } else {
      this.confirmationService.confirm({
        message: `Deseja cadastrar a unidade ao PNCP? Essa ação NÃO PODE SER REVERTIDA.`,
        header: `Cadastro PNCP`,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim, confirmo o cadastro.',
        rejectLabel: 'Não',
        accept: async () => {
          this.pncpService.inserirUnidade(cnpj, ibge, unidade, nome).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
            this.verificarPreCadastroPncp();
          }, e => {
            toastr.error(e.error.message);
          });
        }
      });
    }
  }

  private carregarAutocomplete(): void {
    this.usuarioAutoComplete = new EddyAutoComplete(this.entidadeForm.get('responsavel_fatura'), this.usuarioService, 'id', ['nome', 'email'], {}, {
      text: ['nome', 'email']
    });
  }

}
