import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { FieldsetModule } from 'primeng/fieldset';
import { InputMaskModule } from 'primeng/inputmask';
import { MenuModule } from 'primeng/menu';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { EmpenhoModalidadeRptComponent } from './empenho-modalidade-rpt/empenho-modalidade-rpt.component';

@NgModule({
    declarations: [EmpenhoModalidadeRptComponent],
    exports: [EmpenhoModalidadeRptComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        AutoCompleteModule,
        AccordionModule,
        CalendarModule,
        IMaskModule,
        ToastModule,
        RadioButtonModule,
        FieldsetModule,
        CheckboxModule,
        InputMaskModule,
        MenuModule],
    providers: []
})
export class RelatorioEmpenhoModule { }
