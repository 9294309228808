// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { DocumentoFiscalServicos } from './documento-fiscal.model';
import { LiquidacaoResto } from './liquidacao-resto.model';
import { Liquidacao } from './liquidacao.model';

export class DocumentoFiscalInfo extends BaseResourceModel {
  constructor(
    public id?: number,
    public tipo_servico?: number,
    public valor_base_ret: number = 0,
    public valor_ret_sub: number = 0,
    public valor_n_ret_princ: number = 0,
    public valor_n_ret_adic: number = 0,
    public valor_ret_15: number = 0,
    public valor_ret_20: number = 0,
    public valor_ret_25: number = 0,
    public documento?: DocumentoFiscalServicos,
    public liquidacoes: Liquidacao[] = [],
    public liquidacoesResto: LiquidacaoResto[] = []



  ) {
    super();
  }

  static converteJson(json: any): DocumentoFiscalInfo {
    return Object.assign(new DocumentoFiscalInfo(), json);
  }
}
