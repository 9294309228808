import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  AuditoriaService,
  BaseResourceFormComponent, CodigoGps, DateFormatPipe, EddyAutoComplete, Empenho, Favorecido, FavorecidoBanco, FavorecidoCei, FavorecidoCnae,
  FavorecidoContato, FavorecidoObservacao, FavorecidoPenalidade, FavorecidoPrestador, FavorecidoProduto,
  FavorecidoService, FavorecidoSocio, FavorecidoTerceiroSetor, FavorecidoTipo, FuncaoService, GlobalService,
  Login,
  LoginContabil, LoginService, TipoContato, TipoFavorecido, ViaCEPService
} from 'eddydata-lib';
import { MenuItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { InputMask } from 'primeng/inputmask';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { CodigoGpsService } from '../service/codigo-gps.service';
import { FavorecidoBancoService } from '../service/favorecido-banco.service';
import { FavorecidoCeiService } from '../service/favorecido-cei.service';
import { FavorecidoCnaeService } from '../service/favorecido-cnae.service';
import { FavorecidoContatoService } from '../service/favorecido-contato.service';
import { FavorecidoObservacaoService } from '../service/favorecido-observacao.service';
import { FavorecidoPenalidadeService } from '../service/favorecido-penalidade.service';
import { FavorecidoPrestadorService } from '../service/favorecido-prestador.service';
import { FavorecidoProdutoService } from '../service/favorecido-produto.service';
import { FavorecidoSocioService } from '../service/favorecido-socio.service';
import { FavorecidoTerceiroSetorService } from '../service/favorecido-terceiro-setor.service';
import { FavorecidoTipoService } from '../service/favorecido-tipo.service';
import { EmpenhoService } from 'administrativo-lib';

@Component({
  selector: 'lib-favorecido-frm',
  templateUrl: './favorecido-frm.component.html',
  styleUrls: ['./favorecido-frm.component.css']
})
export class FavorecidoFrmComponent extends BaseResourceFormComponent<Favorecido, LoginContabil> implements OnInit {

  /**
   * Declaração de variáveis
   */
  public codigoGpsAutoComplete: EddyAutoComplete<CodigoGps>;

  @ViewChild('tipo_') inputField: ElementRef;
  @ViewChild('cpf_') cpfField: ElementRef | InputMask;
  @ViewChild('numero_') numeroField: ElementRef;

  public lista: Array<any>;
  public listaTipos: Array<FavorecidoTipo>;
  public listaEnquadramento: Array<any>;
  public listaCnaes = new Array<any>();
  public listaCeis = new Array<any>();
  public listaContaBancos = new Array<any>();
  public listaPenalidades = new Array<any>();
  public listaObservacoes = new Array<any>();
  public listaProdutos: FavorecidoProduto[] = [];
  public listaEstados: any[];
  public listaTiposFavorecido: TipoFavorecido[] = [];
  public listaPaises: { nome: string, iso3: string, sigla: string }[];
  public listaNatJurifica: { codigo: string, nome: string }[];
  public listaSocios: FavorecidoSocio[] = [];
  public listaContatos: FavorecidoContato[] = [];
  public listaTerceiroSetor: FavorecidoTerceiroSetor[] = [];
  public listaTomadores: FavorecidoPrestador[] = [];
  public historicoRazao: Array<any> = new Array<any>();
  public optionsContatos: MenuItem[] = [];
  public bloquearCampo: boolean = false;
  public tab: number = 0;
  public uf: any;
  public validVenc: boolean = true;
  public loginExtra: Login;
  public mes: string;
  public deixarSalvar: boolean = false;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected favorecidoBancoService: FavorecidoBancoService,
    protected favorecidoTipoService: FavorecidoTipoService,
    protected penalidadeService: FavorecidoPenalidadeService,
    protected favorecidoCeiServce: FavorecidoCeiService,
    protected favorecidoCnaeService: FavorecidoCnaeService,
    protected favorecidoService: FavorecidoService,
    protected favObsService: FavorecidoObservacaoService,
    protected favorecidoProdutoService: FavorecidoProdutoService,
    protected favorecidoSocioService: FavorecidoSocioService,
    protected favorecidoTerceiroSetorService: FavorecidoTerceiroSetorService,
    protected favorecidoContatoService: FavorecidoContatoService,
    protected auditoriaService: AuditoriaService,
    private favorecidoPrestadorService: FavorecidoPrestadorService,
    private empenhoService: EmpenhoService,
    private codigoGpsService: CodigoGpsService,
    protected viacepService: ViaCEPService,
    private confirmationService: ConfirmationService,
    protected loginService: LoginService) {
    super(new Favorecido(), injector, Favorecido.converteJson, favorecidoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(entidade: Favorecido): Promise<boolean> {
    return this.login != 'controle-interno';
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      orgao: [this.login.orgao, [Validators.required]],
      cpf_cnpj: [null, [Validators.required]],
      tipo_favorecido: [TipoFavorecido.FORNECEDOR, [Validators.required]],
      nome: [null, [Validators.required]],
      nome_fantasia: [null, [Validators.required]],
      inscricao_estadual: [null],
      inscricao_municipal: [null],
      inscricao_inss: [null],
      cep: [null, [Validators.required]],
      endereco: [null, [Validators.required]],
      bairro: [null, [Validators.required]],
      num_endereco: [null, [Validators.required]],
      municipio: [null, [Validators.required]],
      uf: ['SP', [Validators.required, Validators.minLength(2)]],
      complemento: [null],
      pis: [null],
      tipo: [null, [Validators.required]],
      autarquia: [false, [Validators.required]],
      autorizado: [true, [Validators.required]],
      inativo: [false, [Validators.required]],
      irrf: [false, [Validators.required]],
      inss: [false, [Validators.required]],
      servidor: [false, [Validators.required]],
      simples_nacional: [false, [Validators.required]],
      gasto_fixo: [false, [Validators.required]],
      conta_multipla: [false, [Validators.required]],
      fatura: [false, [Validators.required]],
      cidade: [this.login.cidade, [Validators.required]],
      enquadramento: ['4', [Validators.required]],
      data_certidao: [null],
      data_fgts: [null],
      data_inss: [null],
      data_irrf: [null],
      data_icms: [null],
      data_entidade_profissional: [null],
      data_alvara_social: [null],
      data_contrato_social: [null],
      data_conjunta_federal: [null],
      data_contrato_federal: [null],
      data_divida_ativa_municipal: [null],
      data_debitos_trabalhistas: [null],
      data_certidao_falencia: [null],
      data_certidao_estadual: [null],
      data_certidao_municipal: [null],
      data_balanco_exercicio: [null],
      situacao_cadastral: [null],
      situacao_cnpj: [null],
      capital_social: [null],
      obs_crc: [null],
      sistema: [this.login.sistema],
      cnaes: [],
      penalidades: [],
      contas: [],
      ceis: [],
      observacoes: [],
      produtos: [],
      socios: [],
      contatos: [[]],
      terceiro_setores: [],
      tomadores: [],
      gps: [null],
      cprb: [false],
      nao_mostrar_dirf: [false],
      natureza_juridica: ['0000'],
      pais_iso3: ['BRA'],
      funcao_cargo: [null],
      considerar_reinf: [false],
      insencao_imunidade: [1],
      sociedade_civil: [null],
      conferencia_simples: [null],
      deixarSalvar: [false]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'cidade,tipo,gps,cnaes.cnae' };
  }

  protected async afterLoad() {
    this.entidade.data_certidao = new DateFormatPipe().transform(this.entidade.data_certidao, []);
    this.entidade.data_fgts = new DateFormatPipe().transform(this.entidade.data_fgts, []);
    this.entidade.data_inss = new DateFormatPipe().transform(this.entidade.data_inss, []);
    this.entidade.data_irrf = new DateFormatPipe().transform(this.entidade.data_irrf, []);
    this.entidade.data_icms = new DateFormatPipe().transform(this.entidade.data_icms, []);
    this.entidade.data_entidade_profissional = new DateFormatPipe().transform(this.entidade.data_entidade_profissional, []);
    this.entidade.data_alvara_social = new DateFormatPipe().transform(this.entidade.data_alvara_social, []);
    this.entidade.data_contrato_social = new DateFormatPipe().transform(this.entidade.data_contrato_social, []);
    this.entidade.data_conjunta_federal = new DateFormatPipe().transform(this.entidade.data_conjunta_federal, []);
    this.entidade.data_contrato_federal = new DateFormatPipe().transform(this.entidade.data_divida_ativa_municipal, []);
    this.entidade.data_divida_ativa_municipal = new DateFormatPipe().transform(this.entidade.data_divida_ativa_municipal, []);
    this.entidade.data_debitos_trabalhistas = new DateFormatPipe().transform(this.entidade.data_debitos_trabalhistas, []);
    this.entidade.data_certidao_falencia = new DateFormatPipe().transform(this.entidade.data_certidao_falencia, []);
    this.entidade.data_certidao_estadual = new DateFormatPipe().transform(this.entidade.data_certidao_estadual, []);
    this.entidade.data_certidao_municipal = new DateFormatPipe().transform(this.entidade.data_certidao_municipal, []);
    this.entidade.data_balanco_exercicio = new DateFormatPipe().transform(this.entidade.data_balanco_exercicio, []);
    this.favorecidoContatoService.filtrar(1, -1, { orgao_id: this.login.orgao.id, 'favorecido.id': this.entidade.id, relations: 'orgao' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.listaContatos = res ? res.content : new Array<FavorecidoContato>()
      }, error => this.funcaoService.acaoErro(error))
    this.favorecidoCeiServce.filtrar(1, -1, { orgao_id: this.login.orgao.id, 'favorecido.id': this.entidade.id, relations: 'orgao' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaCeis = res ? res.content : new Array<FavorecidoCei>();
      }, error => this.funcaoService.acaoErro(error));
    this.favorecidoCnaeService.filtrar(1, -1,
      { relations: 'cnae,orgao', orgao_id: this.login.orgao.id, 'favorecido.id': this.entidade.id, 'orderBy': 'principal$DESC' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaCnaes = res ? res.content : new Array<FavorecidoCnae>();
      }, error => this.funcaoService.acaoErro(error));
    this.penalidadeService.filtrar(1, -1,
      { orgao_id: this.login.orgao.id, 'favorecido.id': this.entidade.id, orderBy: 'data_inicio$DESC', relations: 'orgao' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaPenalidades = res ? res.content : new Array<FavorecidoPenalidade>();
      }, error => this.funcaoService.acaoErro(error));
    this.favorecidoBancoService.filtrar(1, -1,
      { 'favorecido.id': this.entidade.id, orgao_id: this.login.orgao.id, relations: 'orgao,banco,recurso,aplicacao,convenio', orderBy: 'id$DESC' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaContaBancos = res ? res.content : new Array<FavorecidoBanco>();
      }, error => this.funcaoService.acaoErro(error));
    this.favObsService.filtrar(1, -1,
      { relations: 'orgao,responsavel', orgao_id: this.login.orgao.id, 'favorecido.id': this.entidade.id, orderBy: 'data_cadastro$DESC' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaObservacoes = res ? res.content : new Array<FavorecidoObservacao>();
      }, error => this.funcaoService.acaoErro(error));
    this.favorecidoProdutoService.filtrar(1, -1,
      { relations: 'orgao,produto', orgao_id: this.login.orgao.id, 'favorecido.id': this.entidade.id, orderBy: 'data_cadastro$DESC' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaProdutos = res ? res.content : new Array<FavorecidoProduto>();
      }, error => this.funcaoService.acaoErro(error));

    this.favorecidoSocioService.filtrar(1, -1,
      { relations: 'orgao,pessoa', orgao_id: this.login.orgao.id, 'favorecido.id': this.entidade.id, orderBy: 'id$DESC' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaSocios = res ? res.content : new Array<FavorecidoSocio>();
      }, error => this.funcaoService.acaoErro(error));

    this.favorecidoTerceiroSetorService.filtrar(1, -1, { 'favorecido.id': this.entidade.id, orgao_id: this.login.orgao.id, orderBy: 'id$DESC', relations: 'orgao,presidente' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaTerceiroSetor = res ? res.content : new Array<FavorecidoTerceiroSetor>();
      }, error => this.funcaoService.acaoErro(error));

    this.favorecidoPrestadorService.filtrar(1, -1, { orgao_id: this.login.orgao.id, relations: 'orgao,favorecido,favorecido.cidade', 'prestador.id': this.entidade.id, orderBy: 'id$DESC' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaTomadores = res ? res.content : new Array<FavorecidoPrestador>();
      }, error => this.funcaoService.acaoErro(error));

    if (this.entidade != null && this.entidade.id != null && this.entidade.id != 0) {
      this.auditoriaService.filtrar(1, 10,
        {
          'alvo_id': this.entidade.id, 'alvo': 'Favorecido', 'depois$like': '%nome\":%',
          relations: 'usuario',
          orderBy: 'data_cadastro$DESC'
        })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.historicoRazao = res.content.map(d => {
            return {
              usuario: d.usuario ? d.usuario.nome : 'Procedimento automático',
              data: d.data_alteracao,
              nome: d.depois['nome']
            }
          });
        }, e => this.funcaoService.acaoErro(e));
    }
    if (this.entidade.id) {
      await this.validarMovimentacaoFavorecido(this.entidade);
    }

    this.uf = this.listaEstados.find((e) => e.uf === this.entidade.uf);
    // this.listaContatos = this.entidade.contatos;
  }

  protected afterInit(): void {
    this.listaEnquadramento = this.globalService.obterListaEnquadramentoFornecedores();
    this.listaEstados = this.globalService.listaEstados();
    this.listaTiposFavorecido = this.globalService.tiposFavorecido();
    this.listaPaises = this.globalService.listaPaises();
    this.listaNatJurifica = this.globalService.listaNatJuridica();
    this.carregarAutoComplete();

    if (this.currentActionRoute === 'novo') {
      this.entidade.data_cadastro = new Date();
    }

    this.favorecidoTipoService.filtrar(1, -1, { 'cidade.id': this.login.cidade.id, orderBy: 'id' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaTipos = res ? res.content : new Array<FavorecidoTipo>();
        if (this.listaTipos && this.listaTipos.length > 0 && !this.entidade.id)
          this.entidadeForm.get('tipo').setValue(this.listaTipos[0]);
      }, error => this.funcaoService.acaoErro(error));

    this.optionsContatos = [{
      label: 'Telefone',
      icon: 'fa fa-phone',
      command: (event) => {
        this.adicionarContato(TipoContato.TELEFONE);
      }
    }, {
      label: 'E-mail',
      icon: 'fa fa-envelope-open-text',
      command: (event) => {
        this.adicionarContato(TipoContato.EMAIL);
      }
    }, {
      label: 'Site',
      icon: 'fa fa-at',
      command: (event) => {
        this.adicionarContato(TipoContato.SITE);
      }
    }, {
      label: 'Outros',
      icon: 'fa fa-clipboard-list',
      command: (event) => {
        this.adicionarContato(TipoContato.OUTROS);
      }
    }];
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);

  }

  protected campoFoco(): ElementRef {
    // return this.inputField;
    return null;
  }

  protected beforeSubmit() {
    this.entidadeForm.get('deixarSalvar').setValue(this.deixarSalvar);
    try {
      if (this.entidadeForm.get('nome').value === null) {
        throw new Error('É necessário informar o nome do favorecido');
      }
      if (this.entidadeForm.get('cpf_cnpj').value === null) {
        throw new Error('É necessário informar cpf/cnpj/inscrição do favorecido');
      }
      this.entidadeForm.get('cnaes').setValue(this.listaCnaes);
      this.entidadeForm.get('ceis').setValue(this.listaCeis);
      this.entidadeForm.get('penalidades').setValue(this.listaPenalidades);
      this.entidadeForm.get('contas').setValue(this.listaContaBancos);
      this.entidadeForm.get('observacoes').setValue(this.listaObservacoes);
      this.entidadeForm.get('produtos').setValue(this.listaProdutos);
      this.entidadeForm.get('socios').setValue(this.listaSocios);
      this.entidadeForm.get('uf').setValue(this.uf?.uf);
      this.entidadeForm.get('contatos').setValue(this.listaContatos);
      this.entidadeForm.get('terceiro_setores').setValue(this.listaTerceiroSetor);
      this.entidadeForm.get('tomadores').setValue(this.listaTomadores);
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }

    let vencimentos = this.validarVencimento();
    if (this.validVenc && vencimentos && vencimentos.length > 0) {
      this.confirm(`Há documentos com data de vencimento expirada, deseja continuar?<br />${vencimentos.join(', ')}`, 'Certificado de Registro Cadastral');
      throw new Error(`Há documentos com data de vencimento expirada, deseja continuar?<br />${vencimentos.join(', ')}</b>`);
    }
  }

  public validarVencimento() {
    let mensagem = [];
    let data_certidao: Date = this.entidadeForm.get('data_certidao').value;
    if (data_certidao && data_certidao.getTime() < new Date().getTime())
      mensagem.push(`<b>Data de certidão</b>`);
    let data_fgts: Date = this.entidadeForm.get('data_fgts').value;
    if (data_fgts && data_fgts.getTime() < new Date().getTime())
      mensagem.push(`<b>Data de F.G.T.S</b>`);
    let data_inss: Date = this.entidadeForm.get('data_inss').value;
    if (data_inss && data_inss.getTime() < new Date().getTime())
      mensagem.push(`<b>Data de I.N.S.S</b>`);
    let data_irrf: Date = this.entidadeForm.get('data_irrf').value;
    if (data_irrf && data_irrf.getTime() < new Date().getTime())
      mensagem.push(`<b>Data de I.R.R.F</b>`);
    let data_icms: Date = this.entidadeForm.get('data_icms').value;
    if (data_icms && data_icms.getTime() < new Date().getTime())
      mensagem.push(`<b>Data de I.C.M.S</b>`);
    let data_entidade_profissional: Date = this.entidadeForm.get('data_entidade_profissional').value;
    if (data_entidade_profissional && data_entidade_profissional.getTime() < new Date().getTime())
      mensagem.push(`<b>Data de entidade profissional</b>`);
    let data_alvara_social: Date = this.entidadeForm.get('data_alvara_social').value;
    if (data_alvara_social && data_alvara_social.getTime() < new Date().getTime())
      mensagem.push(`<b>Data de alvará sanitário</b>`);
    let data_contrato_social: Date = this.entidadeForm.get('data_contrato_social').value;
    if (data_contrato_social && data_contrato_social.getTime() < new Date().getTime())
      mensagem.push(`<b>Data de contrato social</b>`);
    let data_conjunta_federal: Date = this.entidadeForm.get('data_conjunta_federal').value;
    if (data_conjunta_federal && data_conjunta_federal.getTime() < new Date().getTime())
      mensagem.push(`<b>Data de certidão conjunta federal</b>`);
    let data_divida_ativa_municipal: Date = this.entidadeForm.get('data_divida_ativa_municipal').value;
    if (data_divida_ativa_municipal && data_divida_ativa_municipal.getTime() < new Date().getTime())
      mensagem.push(`<b>Data de dívida ativa municipal</b>`);
    let data_debitos_trabalhistas: Date = this.entidadeForm.get('data_debitos_trabalhistas').value;
    if (data_debitos_trabalhistas && data_debitos_trabalhistas.getTime() < new Date().getTime())
      mensagem.push(`<b>Data de certidão negativa de débitos Trabalhistas</b>`);
    let data_certidao_falencia: Date = this.entidadeForm.get('data_certidao_falencia').value;
    if (data_certidao_falencia && data_certidao_falencia.getTime() < new Date().getTime())
      mensagem.push(`<b>Data de cert. neg. de falência e concordata</b>`);
    let data_certidao_estadual: Date = this.entidadeForm.get('data_certidao_estadual').value;
    if (data_certidao_estadual && data_certidao_estadual.getTime() < new Date().getTime())
      mensagem.push(`<b>Data de certidão negativa estadual</b>`);
    let data_certidao_municipal: Date = this.entidadeForm.get('data_certidao_municipal').value;
    if (data_certidao_municipal && data_certidao_municipal.getTime() < new Date().getTime())
      mensagem.push(`<b>Data de certidão negativa municipal</b>`);
    let data_balanco_exercicio: Date = this.entidadeForm.get('data_balanco_exercicio').value;
    if (data_balanco_exercicio && data_balanco_exercicio.getTime() < new Date().getTime())
      mensagem.push(`<b>Data de balanço do ultimo exercício</b>`);
    return mensagem;
  }

  public confirm(mensagem: string, titulo: string) {
    this.confirmationService.confirm({
      message: mensagem,
      header: titulo,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      key: 'favarecido',
      accept: () => {
        this.validVenc = false;
        this.submitForm();
      }
    });
  }

  protected afterSubmit(ent: Favorecido) {
    if (!this.limparTela)
      this.loadResource();
    window.scrollTo(0, 0);
    this.validVenc = true;
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private carregarAutoComplete() {
    this.codigoGpsAutoComplete = new EddyAutoComplete(null, this.codigoGpsService, 'id',
      ['descricao'], {}, { number: ['codigo'], text: ['descricao'] });
  }


  public buscarCEP() {
    let cep = this.entidadeForm.get('cep').value;
    if (!cep)
      return;
    this.viacepService.obterPorCPF(cep)
      .subscribe((endereco) => {
        if (!endereco) {
          toastr.warning('Endereço não foi encontrado')
          return;
        }
        this.entidadeForm.get('endereco').setValue(endereco.logradouro);
        this.entidadeForm.get('bairro').setValue(endereco.bairro);
        this.entidadeForm.get('municipio').setValue(endereco.localidade);
        this.entidadeForm.get('uf').setValue(endereco.uf);
        this.entidadeForm.get('complemento').setValue(endereco.complemento);

        let uf = this.entidadeForm.get('uf').value;
        this.uf = this.listaEstados.find((e) => e.uf === uf);
      }, (err) => toastr.warning('Endereço não foi encontrado'));

    this.funcaoService.focarCampo(this.numeroField);
  }

  public filterEstado(event) {
    let query: string = event.query;
    this.listaEstados = this.globalService.listaEstados()
      .filter((e) => this.funcaoService.removerAcentos(e.nome)
        .toLowerCase().includes(this.funcaoService.removerAcentos(query).toLowerCase()));
  }


  public async validarBuscaReceitaFederal() {
    let cnpj = this.entidadeForm.get('cpf_cnpj').value;
    if (!this.entidadeForm.get('tipo').value || this.entidadeForm.get('tipo').value.tce !== '01'
      || !cnpj)
      return;
    new Promise((resolve) => {
      this.confirmationService.confirm({
        message: `Deseja buscar os dados do fornecedor na receita federal?`,
        header: `Buscar dados de Fornecedor na Receita Federal`,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        key: 'favarecido',
        accept: () => {
          resolve(this.buscarReceitaFederal())
        },
        reject: async () => {
          resolve(await this.validarCPFCNPJ())
        }
      });
    })
  }

  public buscarReceitaFederal() {
    this.validarCPFCNPJ(() => {
      let cnpj = this.entidadeForm.get('cpf_cnpj').value;
      if (!this.entidadeForm.get('tipo').value || this.entidadeForm.get('tipo').value.tce !== '01'
        || !cnpj)
        return;
      cnpj = cnpj
        .replace(/[.]*[-]*[(]*[)]*/g, '');
      this.favorecidoService
        .buscarReceitaWS(cnpj, this.login.cidade.id).pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
          if (!data) {
            toastr.warning('Dados não foram entrados no web service da receita!')
            return;
          }
          this.carregarDadosReceitaWs(data);
        }, error => {
          toastr.warning(error.error.payload)
          this.entidadeForm.get('cpf_cnpj').setValue(null);
        });
    })
  }

  atualizarSimples(simples: boolean) {
    this.entidadeForm.get('conferencia_simples').setValue(new Date());
    this.entidadeForm.get('simples_nacional').setValue(simples);
  }

  private carregarDadosReceitaWs(fav: Favorecido) {
    let favorecido = this.entidadeForm.value;
    favorecido = Object.assign(favorecido, fav);
    if (fav.contatos) {
      fav.contatos.forEach(element => {
        if (!this.listaContatos.map(item => item.contato).includes(element.contato)) {
          element.principal = false;
          this.listaContatos.push(element);
        }
      });
    }
    if (fav.cnaes)
      this.listaCnaes = fav.cnaes
    this.uf = this.listaEstados.find((e) => e.uf === fav.uf);
    this.entidadeForm.patchValue(favorecido);
  }

  public adicionarContato(tipo: TipoContato) {
    if (!this.listaContatos || this.listaContatos.length === 0) {
      this.listaContatos = new Array();
    } else {
      if (this.listaContatos.filter((e) => e['editavel']).length > 0) {
        return;
      }
    }
    let item = new FavorecidoContato();
    item.tipo = tipo;
    item['editavel'] = true;
    this.listaContatos.unshift(item);
  };

  public selecionatTab(tab: number) {
    this.tab = tab;
  }
  public tabSelecionado(tab: number) {
    return this.tab === tab;
  }

  public validarCPFCNPJ(callback?: () => void) {
    let cpf_cnpj = this.entidadeForm.get('cpf_cnpj').value;
    let tipo = this.entidadeForm.get('tipo').value;
    if (!cpf_cnpj)
      return;

    let cnpj_login = this.login.orgao.cnpj.split('/').join('');
    cnpj_login = cnpj_login.split('.').join('');
    cnpj_login = cnpj_login.split('-').join('');
    if (cpf_cnpj === cnpj_login) {
      callback();
      return;
    }
    this.favorecidoService.obter({ 'cpf_cnpj': cpf_cnpj, 'tipo.tce': tipo.tce })
      .subscribe((data) => {
        if (data && data.id !== this.entidade.id) {
          toastr.warning('CPF/CNPJ já cadastrado em outro fornecedor/credor.')
          this.entidadeForm.get('cpf_cnpj').setValue(null);
          this.funcaoService.focarCampo(this.cpfField);
        } else {
          if (cpf_cnpj.length === 11) {
            if (!this.funcaoService.validarCPF(cpf_cnpj)) {
              toastr.warning('CPF inválido!')
              this.entidadeForm.get('cpf_cnpj').setValue(null);
            }
          } else {
            callback();
          }
        }
      })
  }

  public remover(item: FavorecidoContato, index: number) {
    if (!item.id) {
      this.lista.splice(index, 1);
    } else {
      this.favorecidoContatoService.remover(item.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          this.lista.splice(index, 1);
        }, (error) => toastr.error(error.error.payload));
    }
  }

  public mudouFavorecido() {
    this.deixarSalvar = true;
  }

  public async validarMovimentacaoFavorecido(favarecido: Favorecido) {
    let empenho: Empenho[] = await (await this.empenhoService.filtrar(1, -1, { 'favorecido.id ': this.entidade.id, relations: 'liquidacoes.pagamentos', 'ignoreCondObrig': true }).toPromise()).content
    if (empenho.find(e => e?.liquidacoes?.find(l => l?.pagamentos?.find(p => p.id && (!p.anulacao || !p.anulado_total)))) && empenho?.length > 0) {
      this.bloquearCampo = true;
    }

  }
}
