import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MemorialService } from 'administrativo-lib';
import { EddyAutoComplete, FuncaoService, Licitacao, Login, Memorial, ParametroLicitacao, ProdutoUnidade, Rcms, RcmsItem, Rpl, RplCotacao, RplItem, LicitacaoFiscal, RcmsCotacao } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { RcmsItemService } from '../rcms/service/rcms-item.service';
import { RcmsService } from '../rcms/service/rcms.service';
import { LicitacaoFiscalService } from '../licitacao-fiscal/service/licitacao-fiscal.service';
import { RplItemService } from '../rpl/service/rpl-item.service';
import { RplService } from '../rpl/service/rpl.service';

declare var $: any;

@Component({
  selector: 'app-memorial-rpl',
  templateUrl: './memorial-rpl.component.html'
})
export class MemorialRplComponent implements OnInit, OnDestroy {
  @Input() entidade: Licitacao;
  @Input() login: Login;
  @Input() parametrosLicitacao: ParametroLicitacao;
  @Input() importarRequisicao: boolean = false;
  @Input() mostrarDesc: boolean = false;
  @Output() callback: EventEmitter<RplItem[]> = new EventEmitter();
  @Output() callbackRcms: EventEmitter<RcmsItem[]> = new EventEmitter();
  @Output() serivoAjusteConversao: EventEmitter<RplItem[] | RcmsItem[]> = new EventEmitter();
  @Output() ordemFalse: EventEmitter<any> = new EventEmitter()
  @Output() mostrarDescricao: EventEmitter<Boolean> = new EventEmitter();

  public rplAutoComplete: EddyAutoComplete<Rpl>;
  public rplSelecionado: Rpl;
  public itensImportados: Memorial[];
  public rplsImportadas: Rpl[];
  public rcmsImportadas: Rcms[];
  public ordemAlf: boolean;
  public servicoDlg: String = "servicoDlgMemorial"
  public buscaProdutoDlg: String = 'dialogProdutoUnBuscaAjusteMemorialRpl';
  public cadastroProdutoDlg: String = 'dialogProdutoUnCadastroAjusteMemorialRpl';
  public visulizarAjusteServico: boolean = false;
  public ajusteSemSubmit: boolean = false;
  public produtosAjuste: any[] = [];
  public mostrarNomeItens: boolean = false;
  public rcmsAutoComplete: EddyAutoComplete<Rcms>;
  public rcmsSelecionado: Rcms;
  protected unsubscribe: Subject<void> = new Subject();

  public importes: { id: boolean, nome: string }[] = [
    { id: true, nome: 'Requisição' },
    { id: false, nome: 'RPL' },
  ];

  constructor(
    public funcaoService: FuncaoService,
    public rplService: RplService,
    public rplItemService: RplItemService,
    public memorialService: MemorialService,
    private rcmsService: RcmsService,
    private rcmsItemService: RcmsItemService,
    public fiscalService: LicitacaoFiscalService
  ) { }

  ngOnInit(): void {
    this.rplAutoComplete = new EddyAutoComplete(null, this.rplService,
      'numero', ['numero', 'exercicio.ano'], { orgao_id: this.login.orgao.id, ignoreCondObrig: true, orderBy: 'numero,exercicio.ano' }, { number: ['numero', 'exercicio.ano', 'rpl_numero_ano'] }
    );
    this.rcmsAutoComplete = new EddyAutoComplete(null, this.rcmsService,
      'numero', ['numero', 'exercicio.ano'], { orgao_id: this.login.orgao.id, ignoreCondObrig: true, orderBy: 'numero,exercicio.ano' }, { number: ['numero', 'exercicio.ano', 'rcms_numero_ano'] }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entidade) {
      this.ordemAlf = this.entidade.ordem_alfabetica_memorial;
    }
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public carregarDadosRPL() {
    if (!this.rplSelecionado) return;
    this.rplService.obter({
      id: this.rplSelecionado.id, 'itens.memorial$null': null,
      relations: 'exercicio,orgao,tipo_contratacao,setor,requerente,responsavel,itens.produto_unidade.produto,itens.produto_unidade.unidade,itens.cotacoes.favorecido.favorecido,itens.rpl'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (!res || !res.itens || res.itens.length === 0) {
          toastr.warning('RPL já foi importada!');
          return;
        }
        this.rplSelecionado = res;
        this.rplSelecionado.itens.sort((a, b) => { return a.ordem - b.ordem });

        // remove itens já importados (não salvos)
        if (!this.entidade.itens) this.entidade.itens = [];
        for (const memorial of this.entidade.itens) {
          const itemImportado = this.rplSelecionado.itens?.find(ri => memorial.rpl_itens?.find(ri2 => ri2.id === ri.id));
          if (itemImportado)
            this.rplSelecionado.itens.splice(this.rplSelecionado.itens.indexOf(itemImportado), 1);
        }
        if (!this.rplSelecionado.itens || this.rplSelecionado.itens.length === 0) {
          toastr.warning('RPL já foi importada!');
          return;
        }

        this.rplSelecionado.itens.forEach(rplItem => {
          rplItem.memorial = this.entidade.itens?.find(m => m.produto_unidade.id === rplItem.produto_unidade.id)
        });
      });
  }

  public carregarDadosRcms() {
    if (!this.rcmsSelecionado) return;
    this.rcmsService.obter({
      id: this.rcmsSelecionado.id, 'itens.memorial$null': null,
      relations: 'exercicio,orgao,setor,requerente,itens.produto_unidade.produto,itens.produto_unidade.unidade,itens.cotacoes.rcmsFavorecido.favorecido'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {

        if (!res || !res.itens || res.itens.length === 0) {
          toastr.warning('Requisição já foi importada!');
          return;
        }
        this.rcmsSelecionado = res;
        this.rcmsSelecionado.itens.sort((a, b) => { return a.ordem - b.ordem });

        // remove itens já importados (não salvos)
        if (!this.entidade.itens) this.entidade.itens = [];

        for (const memorial of this.entidade.itens) {
          const itemImportado = this.rcmsSelecionado?.itens?.find(ri => memorial?.rcms_itens?.find(ri2 => ri2.id === ri.id));
          if (itemImportado)
            this.rcmsSelecionado.itens.splice(this.rcmsSelecionado.itens.indexOf(itemImportado), 1);
        }

        if (!this.rcmsSelecionado.itens || this.rcmsSelecionado.itens.length === 0) {
          toastr.warning('Requisição já foi importada!');
          return;
        }

        this.rcmsSelecionado.itens.forEach(rplItem => {
          rplItem.memorial = this.entidade.itens.find(m => m.produto_unidade.id === rplItem.produto_unidade.id)
        });

      });
  }

  public fechar() {
    $('#dialogImportarRPL').modal('hide');
    this.rplSelecionado = undefined
  }

  public importarRPL() {
    this.callback.emit(this.rplSelecionado.itens.filter(m => m.memorial !== 'N'));
    $('#dialogImportarRPL').modal('hide');
    toastr.success('Importação do RPL realizada com sucesso!');
    this.rplSelecionado = undefined
  }

  public importarRCMS() {
    this.callbackRcms.emit(this.rcmsSelecionado.itens.filter(m => m.memorial !== 'N'));
    $('#dialogImportarRPL').modal('hide');
    toastr.success('Importação da Requisição realizada com sucesso!');
    this.rcmsSelecionado = undefined
  }

  public alterarMostrarDescricao() {
    this.mostrarDescricao.emit(!this.mostrarNomeItens);
  }

  public carregarItensImportadosRPL() {
    if (!this.entidade?.id) return
    this.memorialService.filtrar(0, 0, {
      licitacao_id: this.entidade.id, orderBy: 'ordem,rpl_itens.rpl.numero',
      relations: 'produto_unidade.produto,produto_unidade.unidade,rpl_itens.produto_unidade.produto,rpl_itens.produto_unidade.unidade,rpl_itens.rpl.exercicio'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.itensImportados = res.content;
        $('#dialogItensImportados').modal('show');
      });
  }

  public carregarItensImportadosRCMS() {
    if (!this.entidade?.id) return
    this.memorialService.filtrar(0, 0, {
      licitacao_id: this.entidade.id, orderBy: 'ordem,rcms_itens.rcms.numero',
      relations: 'produto_unidade.produto,produto_unidade.unidade,rcms_itens.produto_unidade.produto,rcms_itens.produto_unidade.unidade,rcms_itens.rcms.exercicio'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.itensImportados = res.content;
        $('#dialogItensImportados').modal('show');
      });
  }

  public carregarRPLsImportadas() {
    if (!this.entidade?.id) return
    this.rplService.filtrar(0, 0, {
      'itens.memorial.licitacao_id': this.entidade.id, ignoreCondObrig: true, orderBy: 'numero',
      relations: 'exercicio,itens.produto_unidade.produto,itens.produto_unidade.unidade,itens.cotacoes'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.rplsImportadas = res.content;
        $('#dialogDesvincularRPL').modal('show');
      });
  }

  public carregarRCMSImportadas() {
    if (!this.entidade?.id) return
    this.rcmsService.filtrar(0, 0, {
      'itens.memorial.licitacao_id': this.entidade.id, ignoreCondObrig: true, orderBy: 'numero',
      relations: 'exercicio,itens.produto_unidade.produto,itens.produto_unidade.unidade,itens.cotacoes'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.rcmsImportadas = res.content;
        $('#dialogDesvincularRPL').modal('show');
      });
  }

  public desvincularRPL(rpl: Rpl) {
    if (!rpl || !rpl.itens || rpl.itens.length === 0) return
    for (const item of rpl.itens) this.desvincularItemRpl(rpl, item, true)
    this.rplsImportadas.splice(this.rplsImportadas.indexOf(rpl), 1)
    this.ordemFalse.emit(false)
  }

  public desvincularRCMS(rcms: Rcms) {
    if (!rcms || !rcms.itens || rcms.itens.length === 0) return
    for (const item of rcms.itens) this.desvincularItemRCMS(rcms, item, true)
    this.rcmsImportadas.splice(this.rcmsImportadas.indexOf(rcms), 1)
    this.ordemFalse.emit(false)
  }

  public async desvincularItemRpl(rpl: Rpl, item: RplItem, geral?: boolean) {
    if (!item || !item.memorial) return

    if (!this.entidade.itens) this.entidade.itens = [];
    const memorial = this.entidade.itens.find(m => m.id === (item.memorial as Memorial).id);
    if (!memorial) return

    // se quantidade do memorial é superior, atualiza a quantidade e média, caso contrário remove memorial
    if (+memorial.quantidade > +item.quantidade) {
      memorial.quantidade -= +item.quantidade;

      this.rplItemService.desvincularMemorial(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe(async () => {
          let cotacoes: RplCotacao[] = [];
          for (const itemRpl of memorial.rpl_itens) { // inclui cotações ainda não salvas de outros rplsImportadas
            if (itemRpl.cotacoes && itemRpl.cotacoes.length > 0)
              cotacoes = cotacoes.concat(itemRpl.cotacoes);
          }
          for (const itemRpl of await (
            await this.rplItemService.filtrar(0, 0, { memorial_id: memorial.id, relations: 'cotacoes' }).toPromise()).content as RplItem[]
          ) { // inclui cotações já salvas de outros rpls
            if (itemRpl.cotacoes && itemRpl.cotacoes.length > 0)
              cotacoes = cotacoes.concat(itemRpl.cotacoes);
          }

          if (cotacoes.length > 0) {
            let valorCotacao = 0;
            cotacoes.forEach(c => valorCotacao += +c.valor_unitario);
            valorCotacao = +(+valorCotacao / +cotacoes.length).toFixed(4);

            memorial.valor_referencia = valorCotacao;
          }

          const itema = this.entidade.itens[this.entidade.itens.indexOf(memorial)].rpl_itens.find(rpli => rpli.rpl.id === rpl.id);

          this.entidade.itens[this.entidade.itens.indexOf(memorial)]
            .rpl_itens.splice(
              this.entidade.itens[this.entidade.itens.indexOf(memorial)].rpl_itens.indexOf(itema)
              , 1)

          const rplAIndaImportada = [];

          this?.entidade?.itens?.forEach((memorial) => {
            memorial?.rpl_itens?.filter((rplItem) => {
              if (rplItem?.rpl?.id === rpl.id) {
                rplAIndaImportada.push(rplItem);
              }
            });
          });

          if (rplAIndaImportada.length === 0) {
            if (this?.entidade?.id) {
              await this.fiscalService.removerPorRpl(this?.entidade?.id, rpl.id).toPromise();
            }

            const fiscais = this?.entidade?.fiscais.filter((fiscal) => fiscal?.rpl?.id === rpl.id);
            fiscais.forEach(fiscal => this?.entidade?.fiscais?.splice(this?.entidade?.fiscais?.indexOf(fiscal), 1));
          }

          const m = new Memorial();
          m.id = memorial.id;
          m.quantidade = memorial.quantidade;
          m.valor_referencia = memorial.valor_referencia;
          m.reducao_lance = memorial.reducao_lance;
          m.reducao_criterio = memorial.reducao_criterio;
          m.ordem_julgamento = memorial.ordem_julgamento;
          m.unidade = memorial.unidade;
          m.ordem = memorial.ordem;
          m.descricao = memorial.descricao;
          m.observacao = memorial.observacao;
          m.suspenso = memorial.suspenso;
          m.aux = memorial.aux;
          m.cota = memorial.cota;
          m.licitacao = new Licitacao();
          m.licitacao.id = memorial.licitacao.id;
          m.produto_unidade = new ProdutoUnidade();
          m.produto_unidade.id = memorial.produto_unidade.id;

          this.memorialService.atualizar(m).pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
            })
        }, (err) => this.funcaoService.acaoErro(err));
    } else {
      if (memorial.id) {
        this.memorialService.remover(memorial.id).pipe(takeUntil(this.unsubscribe))
          .subscribe(async (res) => {
            this.entidade.itens.splice(this.entidade.itens.indexOf(memorial), 1);
            this.atualizarOrdem();
            if (memorial?.rpl_itens?.[0]?.rpl?.id)
              await this.validaRemoverFiscalRpl(memorial?.rpl_itens?.[0]?.rpl?.id);
            toastr.success('RPL desvinculada com sucesso!');
          }, (err) => this.funcaoService.acaoErro(err));
      } else {
        this.entidade.itens.splice(this.entidade.itens.indexOf(memorial), 1);
        this.atualizarOrdem();
      }
    }

    // remove item da listagem (caso tenha vindo por ação da tela)
    if (!geral) rpl.itens.splice(rpl.itens.indexOf(item), 1);
  }

  private atualizarOrdem() {
    if (!this.entidade.itens) this.entidade.itens = [];
    this.entidade.itens.sort((a, b) => { return a.ordem - b.ordem });
    let ordem = 0;
    this.entidade.itens?.forEach((m) => {
      m.ordem = ++ordem;
      m.ordem_julgamento = m.ordem;
    });
  }

  public async validarServicosConversao() {
    if (this?.rplSelecionado?.itens?.length > 0) {
      this.visulizarAjusteServico = false
      if (this.rplSelecionado.itens.filter((i) => !i.produto_unidade?.id && i.memorial != 'N').length > 0) {
        toastr.warning(`Foram identificados itens com serviços não cadastrados`);
        this.produtosAjuste = this.rplSelecionado.itens.filter((i) => !i.produto_unidade?.id && i.memorial != 'N')
          .map((i) => { return { produto: i.produto_unidade.produto.nome, unidade: i.produto_unidade.unidade.nome }; });
        this.visulizarAjusteServico = true;
        throw new Error(`Foram identificados itens com serviços não cadastrados`);
      }

      if (this.entidade) {
        if (!this.entidade.fiscais) {
          this.entidade.fiscais = [];
        }

        if (!this.entidade.setor) {
          this.entidade.setor = this.rplSelecionado?.setor;
        }

        if (this.entidade.objeto == null || this.entidade.objeto == '') {
          this.entidade.objeto = this.rplSelecionado.objeto;
        }

        if (this.entidade.justificativa == null || this.entidade.justificativa == '') {
          this.entidade.justificativa = this.rplSelecionado.justificativa;
        }
      }

      if (!this.entidade?.setor) {
        this.entidade.setor = this.rplSelecionado?.setor;
      }

      if (this?.rplSelecionado?.responsavel) {
        const item = new LicitacaoFiscal();
        item.pessoa = this.rplSelecionado.responsavel ? this.rplSelecionado.responsavel : null;
        item.rpl = this.rplSelecionado;
        item.requerente_gestor = false;
        if (!this.validarImportarFicaisRPL(item))
          this.entidade.fiscais.push(item);
      }
      if (this?.rplSelecionado?.requerente) {
        const requerente = new LicitacaoFiscal();
        requerente.pessoa = this.rplSelecionado.requerente ? this.rplSelecionado.requerente : null;
        requerente.requerente_gestor = true;
        requerente.rpl = this.rplSelecionado;
        if (!this.validarImportarFicaisRPL(requerente))
          this.entidade.fiscais.push(requerente);
      }
      this.importarRPL()
    }

    if (this.rcmsSelecionado?.itens?.length > 0) {
      if (!this.entidade?.setor) {
        this.entidade.setor = this.rcmsSelecionado?.setor
      }

      this.visulizarAjusteServico = false
      if (this.rcmsSelecionado.itens.filter((i) => !i.produto_unidade?.id && i.memorial != 'N').length > 0) {
        toastr.warning(`Foram identificados itens com serviços não cadastrados`);
        this.produtosAjuste = this.rcmsSelecionado.itens.filter((i) => !i.produto_unidade?.id && i.memorial != 'N')
          .map((i) => { return { produto: i.produto_unidade.produto.nome, unidade: i.produto_unidade.unidade.nome }; });
        this.visulizarAjusteServico = true;
        throw new Error(`Foram identificados itens com serviços não cadastrados`);
      }
      this.importarRCMS();
    }
  }

  public cancelarAjuste() {
    if (this.ajusteSemSubmit) {
      this.entidade.itens = [];
    }
    this.visulizarAjusteServico = false;
  }

  public aposAjustarProdutos(produtos: any[]) {
    if (!produtos)
      return;
    if (produtos.filter((p) => !p.produto_unidade?.id).length > 0) {
      toastr.warning(`Alguns serviços não foram ajustados`);
      return;
    }
    if (this.rplSelecionado) {
      for (let item of this.rplSelecionado.itens) {
        if (item.produto_unidade?.id && item.memorial != "N")
          continue;
        let produto_unidade = produtos
          .find((p) => p.produto === item.produto_unidade.produto.nome
            && p.unidade === item.produto_unidade.unidade.nome);
        if (produto_unidade) {
          item.produto_unidade = produto_unidade.produto_unidade;
        }
      }
    }

    if (this.rcmsSelecionado) {
      for (let item of this.rcmsSelecionado.itens) {
        if (item.produto_unidade?.id && item.memorial != "N")
          continue;
        let produto_unidade = produtos
          .find((p) => p.produto === item.produto_unidade.produto.nome
            && p.unidade === item.produto_unidade.unidade.nome);
        if (produto_unidade) {
          item.produto_unidade = produto_unidade.produto_unidade;
        }
      }
    }

    if (!this.ajusteSemSubmit)
      // this.submitForm(this.limparTela);
      this.ajusteSemSubmit = false;
  }

  public restaurarVariaveis() {
    this.rplSelecionado = null;
    this.rcmsSelecionado = null;
  }

  public async desvincularItemRCMS(rcms: Rcms, item: RcmsItem, geral?: boolean) {
    if (!item || !item.memorial) return

    if (!this.entidade.itens) this.entidade.itens = [];
    const memorial = this.entidade.itens.find(m => m.id === (item.memorial as Memorial).id);
    if (!memorial) return

    // se quantidade do memorial é superior, atualiza a quantidade e média, caso contrário remove memorial
    if (+memorial.quantidade > +item.quantidade) {
      memorial.quantidade -= +item.quantidade;

      item.memorial = null;
      item.memorial_item = null;
      this.rcmsItemService.atualizar(item).pipe(takeUntil(this.unsubscribe))
        .subscribe(async () => {
          let cotacoes: RcmsCotacao[] = [];
          for (const itemRcms of memorial.rcms_itens) { // inclui cotações ainda não salvas de outros rplsImportadas
            if (itemRcms.cotacoes && itemRcms.cotacoes.length > 0)
              cotacoes = cotacoes.concat(itemRcms.cotacoes);
          }
          for (const itemRcms of await (
            await this.rcmsItemService.filtrar(0, 0, { memorial_id: memorial.id, relations: 'cotacoes' }).toPromise()).content as RcmsItem[]
          ) { // inclui cotações já salvas de outros rpls
            if (itemRcms.cotacoes && itemRcms.cotacoes.length > 0)
              cotacoes = cotacoes.concat(itemRcms.cotacoes);
          }

          if (cotacoes.length > 0) {
            let valorCotacao = 0;
            cotacoes.forEach(c => valorCotacao += +c.valor_unitario);
            valorCotacao = +(+valorCotacao / +cotacoes.length).toFixed(4);

            memorial.valor_referencia = valorCotacao;
          }

          const m = new Memorial();
          m.id = memorial.id;
          m.quantidade = memorial.quantidade;
          m.valor_referencia = memorial.valor_referencia;
          m.reducao_lance = memorial.reducao_lance;
          m.reducao_criterio = memorial.reducao_criterio;
          m.ordem_julgamento = memorial.ordem_julgamento;
          m.unidade = memorial.unidade;
          m.ordem = memorial.ordem;
          m.descricao = memorial.descricao;
          m.observacao = memorial.observacao;
          m.suspenso = memorial.suspenso;
          m.aux = memorial.aux;
          m.cota = memorial.cota;
          m.licitacao = new Licitacao();
          m.licitacao.id = memorial.licitacao.id;
          m.produto_unidade = new ProdutoUnidade();
          m.produto_unidade.id = memorial.produto_unidade.id;

          this.memorialService.atualizar(m).pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
            })
        }, (err) => this.funcaoService.acaoErro(err));
    } else {
      if (memorial.id) {
        this.memorialService.remover(memorial.id).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            this.entidade.itens.splice(this.entidade.itens.indexOf(memorial), 1);
            this.atualizarOrdem();
          }, (err) => this.funcaoService.acaoErro(err));
      } else {
        this.entidade.itens.splice(this.entidade.itens.indexOf(memorial), 1);
        this.atualizarOrdem();
      }
    }

    // remove item da listagem (caso tenha vindo por ação da tela)
    if (!geral) rcms.itens.splice(rcms.itens.indexOf(item), 1);
  }
  public async validaRemoverFiscalRpl(rplId: number) {
    if (rplId) {
      const rplAIndaImportada = []

      this?.entidade?.itens?.forEach((memorial) => {
        memorial?.rpl_itens?.filter((rplItem) => {
          if (rplItem?.rpl?.id === rplId) {
            rplAIndaImportada.push(rplItem);
          }
        });
      });

      if (rplAIndaImportada?.length === 0) {
        if (this?.entidade?.id) {
          await this.fiscalService.removerPorRpl(this?.entidade?.id, rplId).toPromise();
        };

        const fiscais = this?.entidade?.fiscais.filter((fiscal) => fiscal?.rpl?.id === rplId);
        fiscais.forEach(fiscal => this.entidade.fiscais.splice(this.entidade.fiscais.indexOf(fiscal), 1));
      }
    }
  }

  public validarImportarFicaisRPL(fiscal: LicitacaoFiscal): boolean {

    let jaExiste = [];
    if (fiscal) {
      jaExiste = this?.entidade?.fiscais.filter((licitacaoFiscal) => {
        return ((licitacaoFiscal?.pessoa?.id === fiscal?.pessoa?.id) && (licitacaoFiscal?.requerente_gestor === fiscal?.requerente_gestor));
      });
    }

    return jaExiste.length > 0;
  }
}
