import { Component, ElementRef, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { EmpenhoService, LiquidacaoService } from 'administrativo-lib';
import {
  BaseResourceFormComponent, DateFormatPipe, Empenho, FuncaoService, GlobalService, LoginContabil, Mensagem, OrgaoAssinaturaService, PreLiquidacao
} from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { FichaDespesaService } from '../../ficha-despesa/service/ficha-despesa.service';
import { NotaPreLiquidacao } from '../../relatorio/liquidacao/nota-pre-liquidacao';
import { PreLiquidacaoService } from '../service/pre-liquidacao.service';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-pre-liquidacao-form',
  templateUrl: './pre-liquidacao-form.component.html'
})
export class PreLiquidacaoFormComponent extends BaseResourceFormComponent<PreLiquidacao, LoginContabil> implements OnInit, OnChanges {

  /**
   * Declaração de variáveis
   */
  @ViewChild('numero_empenho') autoComplete: ElementRef;
  @Input() dlg: boolean = false;
  @Input() preliquidacao: PreLiquidacao;
  @Input() preencherPre: boolean = false;

  public especie: string;
  public listaUfs: Array<any>;
  public empenhado: number;
  public preliquidado: number;
  public liquidado: number;
  public pago: number;
  public saldoPreLiquidado: number = 0;
  public redirecionar = false;
  public empenhoNumero: number;
  public listaTipoDocumentos: Array<any>;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected confirmationService: ConfirmationService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected messageService: MessageService,
    protected fichaService: FichaDespesaService,
    protected empenhoService: EmpenhoService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected preliquidacaoService: PreLiquidacaoService,
    protected liquidacaoService: LiquidacaoService
  ) {
    super(new PreLiquidacao(), injector, PreLiquidacao.converteJson, preliquidacaoService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.preencherPre && this.preencherPre) {
      this.formularioPrincipal = true;
      super.ngOnInit();
      this.preencherEntidadeForm();
    }
  }

  ngOnInit(): void {
    if (this.dlg) {
      this.formularioPrincipal = false;
    }
    super.ngOnInit();
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(_entidade: PreLiquidacao): Promise<boolean> {
    return !_entidade.anulacao
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      parcela: [null, [Validators.required]],
      valor_liquidado: [null, [Validators.required]],
      data_liquidacao: [null, [Validators.required]],
      data_vencimento: [null],
      data_referencia: [null],
      data_emissao: [null],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      mes: [null, [Validators.required]],
      documento: [null, [Validators.required]],
      tipo_documento: [null, [Validators.required]],
      anulacao: [false, [Validators.required]],
      impresso: [false, [Validators.required]],
      empenho: [null, [Validators.required]],
      serie: [null],
      orgao: [this.login.orgao, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      usuario_cadastro: [this.login.usuario, [Validators.required]]
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'empenho,empenho.modalidade,empenho.ficha,empenho.ficha.acao,empenho.favorecido,empenho.favorecido.tipo,empenho.subelemento,'
        + 'empenho.exercicio,empenho.orgao,empenho.contrato,empenho.contrato.tipo_contratacao,empenho.ficha.despesa,empenho.ficha.funcao,'
        + 'empenho.ficha.subfuncao,empenho.ficha.programa,empenho.ficha.recurso,empenho.ficha.aplicacao,orgao,orgao.cidade,exercicio,liquidacoes'
    };
  }

  protected afterInit(): void {
    this.listaUfs = this.globalService.listaEstados();
    this.carregarAutoCompletes();
    this.inicializaVariavel();
    this.listaTipoDocumentos = [
      { nome: 'NOTA FISCAL', id: '1' },
      { nome: 'RECIBO', id: '2' },
      { nome: 'OUTRO', id: '3' }
    ];
  }

  protected campoFoco(): ElementRef {
    return this.autoComplete;
  }

  protected afterLoad() {
    if (!this.podeAlterarAudesp(this.entidade.data_liquidacao)) {
      this.router.navigate(['/pre-liquidacoes-orcamentaria']);
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return;
    }

    this.loadTotalizadores();
    this.empenhoNumero = this.entidade.empenho.numero;
    this.entidade.data_liquidacao = new DateFormatPipe().transform(this.entidade.data_liquidacao, []);
    // this.entidade.data_vencimento = new DateFormatPipe().transform(this.entidade.data_vencimento, []);
    this.entidade.data_referencia = new DateFormatPipe().transform(this.entidade.data_referencia, []);
    this.entidade.data_emissao = new DateFormatPipe().transform(this.entidade.data_emissao, []);
  }

  protected async beforeSubmit() {
    try {
      if (!this.entidadeForm.get('empenho').value) {
        throw new Error('Informe o empenho para pré-liquidação');
      }
      if (!this.entidadeForm.get('exercicio').value) {
        throw new Error('Informe o exercício da pré-liquidação');
      }
      if (!this.entidadeForm.get('orgao').value) {
        throw new Error('Informe o órgão da pré-liquidação');
      }
      if (!this.entidadeForm.get('historico').value) {
        throw new Error('Informe o histórico da pré-liquidação');
      }
      if (!this.entidadeForm.get('valor_liquidado').value) {
        throw new Error('Informe o valor da pré-liquidação');
      }
      if (!this.entidadeForm.get('data_liquidacao').value) {
        throw new Error('Informe a data da pré-liquidação');
      }
      let empenhoData = new DateFormatPipe().transform(this.entidade.empenho['data_empenho'], []);
      if (this.entidadeForm.get('data_liquidacao').value < empenhoData) {
        throw new Error('Data da liquidação menor que data do empenho!');
      }
      // if (!this.entidadeForm.get('data_vencimento').value) {
      //   throw new Error('Informe o vencimento da pré-liquidação');
      // }
      if (!this.entidadeForm.get('documento').value) {
        throw new Error('Informe o documento da pré-liquidação');
      }

      if (this.entidadeForm.get('valor_liquidado').value <= 0 && !this.entidadeForm.get('anulacao').value) {
        throw new Error('O valor da pré-liquidação deve ser maior que zero');
      }
      const dtLiquidacao: Date = this.entidadeForm.get('data_liquidacao').value;
      if (dtLiquidacao.getFullYear() !== this.login.exercicio.ano) {
        throw new Error('O ano da data da pré-liquidação está diferente do exercício logado');
      }
      if (+this.saldoPreLiquidado + (this.entidade.id ? +this.entidade.valor_liquidado : 0) < +this.entidadeForm.get('valor_liquidado').value) {
        throw new Error('Não há saldo para continuar a pré-liquidação, verifique!');
      }

      const validarDocumentoReptido = await this.validarDocumentoRepetido();
      if (!validarDocumentoReptido) {
        throw new Error('Documento repetido!');
      }

      const validarContratoVencido = await this.validarContratoVencido();
      if (!validarContratoVencido) {
        throw new Error('Contrato vencido!');
      }

      if (this.entidadeForm.get('anulacao').value && !this.entidadeForm.get('data_referencia')) {
        throw new Error('Informe a data de referência da anulação de pré-liquidação');
      }

      this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(dtLiquidacao)?.split('-')?.[1]);

      if (!this.funcaoService.podeAlterarAudesp(this.entidadeForm.get('data_liquidacao').value, this.login)) {
        throw new Error('O mês do registro já foi armazenado no TCE. Não é possível salvar!');
      }

    } catch (e) {
      Mensagem.erro(this.messageService, e)
      throw e;
    }
  }

  private async validarDocumentoRepetido() {
    if (!this.entidade.id) {
      const param = {};
      param['empenho.favorecido.id'] = this.entidade.empenho.favorecido.id;
      param['documento'] = this.entidadeForm.get('documento').value ? this.entidadeForm.get('documento').value : this.entidade.documento;
      param['valor_liquidado'] = this.entidadeForm.get('valor_liquidado').value ? this.entidadeForm.get('valor_liquidado').value : this.entidade.valor_liquidado;
      param['orgao.id'] = this.entidade.orgao.id;
      param['exercicio.id'] = this.entidade.exercicio.id;
      if (this.entidade.id) {
        param['id$ne'] = this.entidade.id;
      }
      const entity = await this.preliquidacaoService.obter(param).toPromise();
      if (entity) {
        return new Promise((resolve, reject) => {
          this.confirmationService.confirm({
            header: 'Atenção',
            message: 'Já existe liquidação com este fornecedor, documento e valor, deseja continuar ?',
            accept: () => {
              resolve(true);
            },
            reject: () => {
              resolve(false);
            }
          });
        });
      } else { return true; }
    } else { return true; }
  }

  private async validarContratoVencido() {
    if (!this.entidade.id && this.entidade.empenho.contrato
      && this.entidade.data_liquidacao > this.entidade.empenho.contrato.data_termino) {
      return new Promise((resolve, reject) => {
        this.confirmationService.confirm({
          header: 'Atenção',
          message:
            `Empenho ${this.entidade.empenho.numero}/${this.entidade.exercicio.ano} de contrato ${this.entidade.empenho.contrato.numero}
                 vencido em ${new DateFormatPipe().transform(this.entidade.data_liquidacao, ['local'])}. Deseja continuar?`,
          accept: () => {
            resolve(true);
          },
          reject: () => {
            resolve(false);
          }
        });
      });
    } else { return true; }
  }

  protected afterSubmit(ent: PreLiquidacao) {
    if (this.dlg) {
      this.router.navigate(['/pre-liquidacoes-orcamentaria']);
    } else if (this.currentActionRoute === 'novo') {
      if (!this.limparTela) {
        this.router.navigate(['/pre-liquidacoes-orcamentaria', ent.id, 'editar']);
      }
    } else {
      this.router.navigate(['/pre-liquidacoes-orcamentaria', ent.id, 'editar']).then(() => {
      });
    }
  }

  private inicializaVariavel() {
    if (this.currentActionRoute === 'novo') {
      this.preliquidacaoService.ultimaDataLiquidada(this.login.exercicio.id, this.login.orgao.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((dataLiq) => {
          if (dataLiq === 0) {
            this.entidade.data_liquidacao = new Date();
          } else {
            this.entidade.data_liquidacao = new DateFormatPipe().transform(dataLiq, []);
          }
          this.entidadeForm.get('data_liquidacao').setValue(this.entidade.data_liquidacao);
          this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidade.data_liquidacao)?.split('-')?.[1]);
        });

      this.activatedRoute.params.pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (params: any) => {
            if (params.hasOwnProperty('empenho')) {
              const parametros = {};
              parametros['id'] = params['empenho'];
              parametros['relations'] = 'exercicio,orgao,favorecido,subelemento,ficha,ficha.acao,ficha.despesa,ficha.programa';
              this.empenhoService.obter(parametros)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(
                  (entidade) => {
                    this.redirecionar = true;
                    this.empenhoNumero = entidade.numero;
                    this.entidadeForm.get('empenho').setValue(entidade);
                    this.entidadeForm.get('historico').setValue(entidade.historico);
                    // this.entidadeForm.get('data_vencimento').setValue(new DateFormatPipe().transform(entidade.data_vencimento, []));
                    this.entidade.empenho = entidade;
                    if (this.entidade.empenho) {
                      this.loadTotalizadores();
                      this.obterUltimaParcela();
                    }
                  }, () => this.sair());
            }
          });
    }
  }
  /* Se o usuario informou um valor diferente do empenhado
   * atribui a parcela como sendo a primeira
   */
  public verificarParcela() {
    if ((+this.empenhado + (this.entidade.id ? +this.entidade.valor_liquidado : 0) - +this.preliquidado - +this.entidadeForm.get('valor_liquidado').value) < 0) {
      Mensagem.alerta(this.messageService, 'Não há saldo disponível para esta pré-liquidação!');
    } else {
      this.obterUltimaParcela();
    }
  }

  private async obterUltimaParcela() {
    this.preliquidacaoService.obterUltimaParcela(
      this.entidade.empenho.numero, this.entidade.empenho.exercicio.id, this.entidade.empenho.orgao.id
    ).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res: any) => {
          this.entidade.parcela = res;
          this.liquidacaoService.obterUltimaParcela(this.entidade.empenho.id, this.login.exercicio.id, this.login.orgao.id)
            .pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
              if (+this.entidade.parcela > +res) {
                this.entidadeForm.get('parcela').setValue(+this.entidade.parcela)
              }
              if (+this.entidade.parcela < +res) {
                this.entidade.parcela = res;
                this.entidadeForm.get('parcela').setValue(res);
              }
              if (!this.entidade.id && +this.entidade.parcela === 0) {
                this.entidadeForm.get('parcela').setValue(res);
              }
              if (+this.entidadeForm.get('valor_liquidado').value !== +this.entidade.empenho.valor_empenho && +this.entidade.parcela === 0) {
                this.entidadeForm.get('parcela').setValue(res + 1)
              }
              if (+this.entidadeForm.get('valor_liquidado').value === +this.entidade.empenho.valor_empenho) {
                this.entidadeForm.get('parcela').setValue(0)
              }
              if (!this.entidade.id && this.entidade.parcela && +res > 0) {
                this.entidadeForm.get('parcela').setValue(res + 1);
              }
            }, error => {
              this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
            });
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
      );
  }

  private async loadTotalizadores() {
    this.empenhado = 0;
    this.preliquidado = 0;
    this.liquidado = 0;
    this.pago = 0;

    if (!this.entidade.empenho) {
      return;
    }

    let totalizadores = await this.empenhoService.totalizadores(
      this.login.exercicio.id, this.login.orgao.id, this.entidade.empenho.numero).toPromise();

    if (totalizadores) {
      totalizadores = totalizadores.content[0] ? totalizadores.content[0] : totalizadores.content
      this.empenhado = totalizadores.total_empenhado ? totalizadores.total_empenhado : 0
      this.preliquidado = totalizadores.total_preliquidado ? totalizadores.total_preliquidado : 0
      this.liquidado = totalizadores.total_liquidado ? totalizadores.total_liquidado : 0
      this.pago = totalizadores.total_pago ? totalizadores.total_pago : 0

      let valor_empenhado = totalizadores.valor_empenho ? totalizadores.valor_empenho : 0
      let total_empenho_anulado = totalizadores.total_empenho_anulado ? totalizadores.total_empenho_anulado : 0

      this.saldoPreLiquidado = +(+valor_empenhado + +total_empenho_anulado - +this.preliquidado - +this.liquidado).toFixed(2)
    }

    if (!this.entidadeForm.get('id').value) {
      const saldo = +(+this.empenhado - +this.preliquidado - +this.liquidado).toFixed(2);
      this.entidadeForm.get('valor_liquidado').setValue(saldo.toString());
      if (saldo <= 0) {
        Mensagem.alerta(this.messageService, 'Não há saldo disponível para esta pré-liquidação!');
      }
    }
  }

  private carregarAutoCompletes() {

  }

  public async atualizarEmpenho() {
    if (this.empenhoNumero > 0 && !this.entidade.id) {
      const param = {};
      param['numero'] = this.empenhoNumero;
      param['exercicio.id'] = this.login.exercicio.id;
      param['orgao.id'] = this.login.orgao.id;
      param['relations'] = 'modalidade,ficha,ficha.acao,favorecido,favorecido.tipo,subelemento,'
        + 'exercicio,orgao,contrato,contrato.tipo_contratacao,ficha.despesa,ficha.funcao,'
        + 'ficha.subfuncao,ficha.programa,ficha.recurso,ficha.aplicacao,orgao,orgao.cidade,exercicio'

      this.entidade.empenho = await this.empenhoService.obter(param).pipe(takeUntil(this.unsubscribe)).toPromise();
      if (!this.entidade.empenho) {
        Mensagem.alerta(this.messageService, 'Empenho não localizado!');
        this.entidadeForm.get('empenho').setValue(null);
        this.loadTotalizadores();
        return;
      }

      this.entidade.orgao = this.entidade.empenho.orgao;
      this.entidade.exercicio = this.entidade.empenho.exercicio;
      if (this.entidade.empenho) {
        this.loadTotalizadores();
        this.obterUltimaParcela();
        if (!this.entidadeForm.get('data_liquidacao').value) {
          this.entidadeForm.get('data_liquidacao').setValue(new Date());
        }
        this.entidadeForm.get('empenho').setValue(this.entidade.empenho);
        this.entidadeForm.get('orgao').setValue(this.entidade.empenho.orgao);
        this.entidadeForm.get('exercicio').setValue(this.entidade.empenho.exercicio);
        // this.entidadeForm.get('data_vencimento').setValue(new DateFormatPipe().transform(this.entidade.empenho.data_vencimento, []));
        this.entidadeForm.get('historico').setValue(this.entidade.empenho.historico);
        this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidade.data_liquidacao)?.split('-')?.[1]);
      }
    }
  }

  public imprimir() {
    const parametros = {};

    let relations = '';
    relations += 'empenho.contrato,empenho.licitacao,empenho.modalidade,empenho.subelemento,empenho.ficha,';
    relations += 'empenho.favorecido,empenho.ficha.executora.unidade,empenho.ficha.despesa,empenho.ficha.funcao,';
    relations += 'empenho.ficha.acao,empenho.ficha.aplicacao';
    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id'] = this.entidade.id;
    this.preliquidacaoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        this.assinaturaService.obter()
        new NotaPreLiquidacao(this.assinaturaService).imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

  sair() {
    if (this.redirecionar && this.podeIncluir('empenhos')) {
      this.router.navigate(['/empenhos-orcamentario']);
    } else {
      new FuncaoService().navegarPara(this.login.usuario.sistema, this.router);
    }
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public async confirmar(salvar: boolean) {
    if (salvar) {
      await this.submitForm(false);
    }
    $('#dialogPreliquidacao').modal('hide');
  }

  private preencherEntidadeForm(): void {
    if (!this.preliquidacao || !this.dlg || !this.entidadeForm) {
      return;
    }

    this.empenhoService.filtrar(1, 1, {
      id: this.preliquidacao.empenho.id,
      relations: 'modalidade,ficha,ficha.acao,favorecido,favorecido.tipo,subelemento,exercicio,orgao,contrato,contrato.tipo_contratacao,'
        + 'ficha.despesa,ficha.funcao,ficha.subfuncao,ficha.programa,ficha.recurso,ficha.aplicacao,orgao,orgao.cidade,exercicio'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(async res => {
        const empenho: Empenho = res.content[0];

        this.entidade = { ...this.preliquidacao, id: null, empenho: empenho };
        this.empenhoNumero = empenho.numero;

        this.entidadeForm?.get('data_liquidacao').setValue(new DateFormatPipe().transform(new Date(), []));
        this.entidadeForm?.get('empenho').setValue(empenho);
        this.entidadeForm?.get('historico').setValue(this.preliquidacao?.empenho?.historico);
        this.entidadeForm?.get('documento').setValue(this.preliquidacao?.documento);
        this.entidadeForm?.get('tipo_documento').setValue(this.preliquidacao?.tipo_documento);
        this.entidadeForm?.get('serie').setValue(this.preliquidacao?.serie);
        this.entidadeForm?.get('data_emissao').setValue(new DateFormatPipe().transform(this.preliquidacao?.data_emissao, []));
        // this.entidadeForm?.get('data_vencimento').setValue(new DateFormatPipe().transform(this.preliquidacao?.data_vencimento, []));

        this.verificarParcela();
        await this.loadTotalizadores();

        this.entidadeForm?.get('valor_liquidado').setValue(this.preliquidacao?.valor_liquidado?.toString());
      })
  }
}
