import { Component, Input } from '@angular/core';
import { Audesp4ObrasServicosEngenharia } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-visita',
  templateUrl: './licitacao-audesp-visita.component.html'
})
export class LicitacaoAudespVisitaComponent extends BaseLicitacaoAudesp {

  @Input() public objeto: Audesp4ObrasServicosEngenharia;

  constructor(
  ) {
    super()
  }
}
