import { Component, ElementRef, Injector } from '@angular/core';
import {
  BaseResourceFormComponent, EddyAutoComplete, FuncaoService, GlobalService, LoginContabil,
  OrgaoService,
  ParametroRequisicao,
  ParametroRequisicaoService,
  RequisicaoExcecao,
  Usuario,
  UsuarioService
} from 'eddydata-lib';
import { switchMap, takeUntil } from 'rxjs/operators';
import { RequisicaoExcessaoService } from '../service/requisicao-excecao.service';

@Component({
  selector: 'app-parametro-requisicao-view',
  templateUrl: './parametro-requisicao-view.component.html'
})
export class ParametroRequisicaoViewComponent extends BaseResourceFormComponent<ParametroRequisicao, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public usuarioAutoComplete: EddyAutoComplete<Usuario>;
  public usuarioNovo: Usuario;
  public listaExcecoes: RequisicaoExcecao[] = []
  public bloquearNovaRequisicao: boolean

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected funcaoService: FuncaoService,
    protected orgaoService: OrgaoService,
    private service: ParametroRequisicaoService,
    private requisicaoExcecaoService: RequisicaoExcessaoService,
    private usuarioService: UsuarioService) {
    super(new ParametroRequisicao(), injector, ParametroRequisicao.converteJson, service);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      nao_usar_ficha_despesa: [false],
      minimo_tres_cotacoes: [false],
      exigir_responsavel_cotacao: [false],
      exigir_dados_fiscalizacao: [false],
      bloquear_data: [false],
      orgao: [this.login.orgao],
      bloquear_nova_requisicao: [false],
      habilitar_desbloqueio_automatico: [false],
      requisicao_alfabetico: [false],
      requisicao_licitacao_alfabetico: [false],
      exigir_ficha_despesa: [false],
      verificar_saldo_ficha_requisicao: [false],
      reserva_dotacao_rpls: [false],
      calculo_medio: [0],
      usuarios_excecao: [[]]
    });
  }

  public adicionarUsuario() {
    if (this.listaExcecoes.find(item => item.usuario_excecao.email === this.usuarioNovo.email)) {
      toastr.warning(`Usuário ${this.usuarioNovo.nome} já está na lista de exceçoes.`);
      return;
    } else {
      let reqExc = new RequisicaoExcecao();
      reqExc.usuario_excecao = this.usuarioNovo;

      this.listaExcecoes.push(reqExc);
      this.usuarioNovo = new Usuario();
    }
  }

  protected parametrosExtras(): {} {
    return { relations: 'orgao,usuarios_excecao.usuario_excecao' };
  }

  protected afterInit(): void {
    this.activatedRoute
      .paramMap
      .pipe(switchMap(params => this.service.obter(
        Object.assign({}, { 'orgao.id': this.login.orgao.id }, this.parametrosExtras())
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (entidade) => {
          if (entidade) {
            this.entidade = entidade;
            this.afterLoad();
            this.entidadeForm.patchValue(entidade);
            this.bloquearNovaRequisicao = entidade.bloquear_nova_requisicao;
          }
        }, (error) => this.sair());
    this.carregarAutocomplete();
  }

  public carregarAutocomplete() {
    this.usuarioAutoComplete = new EddyAutoComplete(null, this.usuarioService, 'id', ['id', 'nome', 'sobrenome'], { orgao_id: this.login.orgao.id, ativo: true }, { text: ['nome', 'sobrenome', 'email', 'cpf'], number: ['id'] });
  }
  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit() {
    this.entidadeForm.get('usuarios_excecao').patchValue(this.listaExcecoes);
    this.entidadeForm.get('bloquear_nova_requisicao').patchValue(this.bloquearNovaRequisicao);
  }
  protected afterSubmit(entidade: ParametroRequisicao) {
    this.login.parametro['requisicao'] = entidade;

    GlobalService.salvarSessaoLogin(this.login);
  }

  protected async afterLoad() {
    this.listaExcecoes = this.entidade.usuarios_excecao;
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================


}
