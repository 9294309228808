// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Setor } from '../almoxarifado/setor.model';
import { Usuario } from '../comum/usuario.model';
import { Tombamento } from './tombamento.model';
import { TransferenciaItemEmailPatrimonio } from './transferencia-item-email-patrimonio.model';
import { TransferenciaPatrimonio } from './transferencia-patrimonio.model';

export class TransferenciaItemPatrimonio extends BaseResourceModel {
  constructor(
    public id?: number,
    public status?: 'PENDENTE' | 'ACEITO' | 'RECUSADO' | 'EXCLUIDO',
    public status_devolucao?: 'PENDENTE' | 'EMPRESTADO' | 'DEVOLVIDO' | 'NAO_DEVOLVIDO',
    public data_status?: Date,
    public motivo_status?: string,
    public aceite_automatico?: boolean,
    public primeiro_email?: boolean,
    public segundo_email?: boolean,
    public terceiro_email?: boolean,
    public transferencia?: TransferenciaPatrimonio,
    public origem?: Setor,
    public tombamento?: Tombamento,
    public usuario?: Usuario,
    public usuario_status?: Usuario,
    public emails?: TransferenciaItemEmailPatrimonio[],
    public selecionado?: boolean,
  ) {
    super();
  }

  static converteJson(json: any): TransferenciaItemPatrimonio {
    return Object.assign(new TransferenciaItemPatrimonio(), json);
  }
}
