import { BaseResourceStorage } from '../../models/base-resource.storage';
import { Fatura } from './fatura.model';
export class FaturaStorage extends BaseResourceStorage {
    constructor(
        public fatura?: Fatura,
    ) {
        super();
    }

    static converteJson(json: any): FaturaStorage {
        return Object.assign(new FaturaStorage(), json);
    }
}
