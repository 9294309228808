import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnderecoViaCEP } from '../entidade/viacep/endereco-via-cep.model';

@Injectable({
  providedIn: 'root'
})
export class ViaCEPService {

  protected http: HttpClient;

  constructor(
    protected injector: Injector
  ) {
    this.http = injector.get(HttpClient);
  }

  public obterPorCPF(cep: string): Observable<EnderecoViaCEP> {
    return this.http.get<EnderecoViaCEP>(`https://viacep.com.br/ws/${cep}/json/`).pipe(map(res => res));
  }

}

