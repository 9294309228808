import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ReavaliacaoItem } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class RPReavaliacaoItemService extends BaseResourceService<ReavaliacaoItem> {

  constructor(
    protected injector: Injector
  ) {
    super(`reavaliacao-itens`, injector);
  }

}
