import { BaseResourceModel } from "../../models/base-resource.model";

export class Decreto extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public data_decreto?: Date,
    public data_publicacao?: Date
  ) {
    super();
  }

  static converteJson(json: any): Decreto {
    return Object.assign(new Decreto(), json);
  }
}