import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../util/auth.guard";
import { ComunicadoFormComponent } from "./comunicado-form/comunicado-form.component";
import { ComunicadoListComponent } from "./comunicado-list/comunicado-list.component";

const routes: Routes = [
  { path: '', component: ComunicadoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: ComunicadoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: ComunicadoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: ComunicadoFormComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComunicadoRoutingModule { }