import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  BaseResourceFormComponent, Executora, ExecutoraService, FuncaoService, GlobalService, LoginContabil, Orgao, OrgaoService, Unidade, UnidadeService
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-unidade-view',
  templateUrl: './unidade-view.component.html'
})
export class UnidadeViewComponent extends BaseResourceFormComponent<Unidade, LoginContabil> implements OnInit {

  /**
   * Declaração de variáveis
   */
  @ViewChild('codigo') inputValor: ElementRef;

  public mensagemSucesso: string;
  public entity: Executora = new Executora();
  public ato: any;

  public ptBR: any;
  public orgao: Orgao;
  public listaAtos: Array<any>;
  public listaExecutoras: Array<any>;

  public mes: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private route: ActivatedRoute,
    private messageService: MessageService,
    public funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected executoraService: ExecutoraService,
    protected orgaoService: OrgaoService,
    protected unidadeService: UnidadeService) {
    super(new Unidade(), injector, Unidade.converteJson, unidadeService);
  }


  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected parametrosExtras(): {} {
    return { relations: 'ppa,orgao,membros.pessoa,responsavel_fatura' };
  }

  protected afterInit(): void {
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: Unidade): void {
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      codigo: [null, [Validators.required]],
      nome: [null, [Validators.required]],
      cpf: [null, [Validators.required]],
      responsavel: [null, [Validators.required]],
      cargo: [null, [Validators.required]],
      ato: [null, [Validators.required]],
      ativo: [true, [Validators.required]],
      tribunal: [null, [Validators.required]],
      ordenador: [null, [Validators.required]],
      cargo_ordenador: [null, [Validators.required]],
      tesoureiro: [null, [Validators.required]],
      cargo_tesoureiro: [null, [Validators.required]],
      responsavel_fatura: [null],
      presidente_conselho: [null],
      cargo_presidente_conselho: [null],
      tipo:['OUTROS'],
      orgao: [null, [Validators.required]],
      ppa: [this.login.ppa, [Validators.required]],
      executoras: [null]
    });
  }

  protected afterLoad() {
    this.loadExecutora();
    this.ato = this.globalService.obterListaAtoUnidades().find(idx => idx.id === this.entidade.ato);
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.orgaoService.obterPorId(params['orgao']).pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (res) => {
              if (res) {
                this.entidadeForm.get('orgao').setValue(res);
                this.orgao = res;
                this.entidade.orgao = res;
                this.entidadeForm.get('orgao').setValue(res);
              } else {
                this.messageService.add({ severity: 'error', summary: 'Atenção!', detail: 'Órgão não localizado!' });
                this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
              }
            }
          );
      });
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private loadExecutora() {
    if (this.entidade.id) {
      this.executoraService.filtrar(1, -1,
        { relations: 'unidade', 'unidade.id': this.entidade.id }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaExecutoras = res ? res.content : new Array<Executora>();
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  public updateExecutora(event: any) {
    this.listaExecutoras.push(event);
  }


  public orgaoConversor(orgao: Orgao) {
    return orgao ? orgao.codigo + ' - ' + orgao.nome : '';
  }

}
