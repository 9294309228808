import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, UnidadeMembro } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class UnidadeMembroService extends BaseResourceService<UnidadeMembro> {

  constructor(
    protected injector: Injector
  ) {
    super(`unidades-membro`, injector);
  }

}
