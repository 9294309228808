import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, SubGrupoEstoque } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class SubGrupoSubElementoEstoqueService extends BaseResourceService<SubGrupoEstoque> {

  constructor(
    protected injector: Injector
  ) {
    super(`sub-grupo-sub-elementos-estoque`, injector);
  }

}
