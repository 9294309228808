import { Directive, Injector, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { QuadroEnsinoService } from '../service/quadro-ensino.service';
import {
  FuncaoService, GlobalService, Login,
  Exercicio, FuncaoGoverno, Relatorio, FormatoExportacao, Coluna
} from 'eddydata-lib';
import { Assinaturas } from 'administrativo-lib';

@Directive()
export class Quadro4Ensino implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected quadroServico: QuadroEnsinoService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected dataInicial: Date,
    protected dataFinal: Date,
    protected funcao?: FuncaoGoverno,
    protected injector?: Injector,
    protected assinatura_ensino?: boolean) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async listar(orgaos: number[]) {
    return await this.quadroServico
      .obterQuadro4(this.mes, this.exercicio.id, orgaos, this.dataInicial, this.dataFinal).toPromise();
  }

  public montarRelatorio(orgaos: number[], formato?: FormatoExportacao) {
    if (!formato) formato = 'pdf';

    this.quadroServico.obterQuadro4(this.mes, this.exercicio.id, orgaos, this.dataInicial, this.dataFinal)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(
            'QUADRO 4 - MOVIMENTAÇÃO FINANCEIRA DA EDUCAÇÃO - ENSINO'
            , this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.conteudo(dados),
            'landscape', 'QUADRO 4 - MOVIMENTAÇÃO FINANCEIRA DA EDUCAÇÃO - ENSINO',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            });
        } else {
          this.funcaoService.exportar(formato, this.conteudoExportar(dados), 'QUADRO 4 - MOVIMENTAÇÃO FINANCEIRA DA EDUCAÇÃO - ENSINO', this.colunasRelatorio())
        }
      });
  }

  private async conteudo(dados: any[]): Promise<{}[]> {
    let referencia = '';
    if (this.mes) {
      referencia = `${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase()}/${this.exercicio.ano}`;
    } else if (this.dataInicial && this.dataFinal) {
      let dt = this.dataInicial.toString().split("-");
      referencia = `${dt[2]}/${dt[1]}/${dt[0]}`;
      dt = this.dataFinal.toString().split("-");
      referencia += ` à ${dt[2]}/${dt[1]}/${dt[0]}`;
    } else {
      referencia += this.exercicio.ano;
    }

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `REFERÊNCIA: ${referencia}`,
        alignment: 'center',
        fontSize: 11,
        margin: [5, 5, 5, 5],
        bold: true,
        colSpan: 13
      }, '', '', '', '', '', '', '', '', '', '', '', ''],
      [{
        text: 'CLASSIFICAÇÃO CONTÁBIL',
        alignment: 'center',
        bold: true,
        colSpan: 3
      }, '', '', {
        text: 'DOMICÍLIO BANCÁRIO',
        alignment: 'center',
        bold: true,
        colSpan: 4
      }, '', '', '', {
        text: 'SALDO EXERCÍCIO ANTERIOR',
        alignment: 'center',
        bold: true,
        colSpan: 2
      }, '', {
        text: 'MOVIMENTO ATÉ O TRIMESTRE',
        alignment: 'center',
        bold: true,
        colSpan: 2,
      }, '', {
        text: 'SALDO ATUAL',
        alignment: 'center',
        bold: true,
        colSpan: 2,
      }, ''],
      [{
        text: 'ÓRGÃO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'CÓDIGO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'NOME',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'BANCO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'AGÊNCIA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'CONTA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'NOME',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'VALOR',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'D/C',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'ENTRADAS',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'SAÍDAS',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'VALOR',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'D/C',
        alignment: 'center',
        bold: true, fontSize: 6
      }]
    ];

    // monta o agrupamento do relatório
    let i = 0;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    const dado = dados[0];
    // TITULO ORGÃOS DIRETA / INDIRETA --------------------------------------------------------------------------------
    const titulos = this.funcaoService.agrupar(dado, 'titulo',
      ['anterior', 'entradas', 'saidas', 'saldo']);
    i++;

    registros.push([
      {
        text: i === 1 ? 'A) MOVIMENTAÇÃO BANCÁRIA' : 'B) MOVIMENTAÇÃO POR FONTE DE RECURSOS',
        alignment: 'left',
        bold: true,
        colSpan: 13
      }, '', '', '', '', '', '', '', '', '', '', '', '']);

    for (const titulo of titulos) {
      registros.push([
        {
          text: titulo.grupo, bold: true, fontSize: 7, colSpan: 7
        }, '', '', '', '', '', '',
        {
          text: this.funcaoService.convertToBrNumber(titulo.totalizadores['anterior']),
          alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: +titulo.totalizadores['anterior'] > 0 ? 'D' : 'C', alignment: 'center', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(titulo.totalizadores['entradas']),
          alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(titulo.totalizadores['saidas']),
          alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(titulo.totalizadores['saldo']),
          alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: +titulo.totalizadores['saldo'] > 0 ? 'D' : 'C', alignment: 'center', fontSize: 7
        },
      ]);

      total1 += +titulo.totalizadores['anterior'];
      total2 += +titulo.totalizadores['entradas'];
      total3 += +titulo.totalizadores['saidas'];
      total4 += +titulo.totalizadores['saldo'];

      // GRUPOS DISPONIBILIDADES / APLICAÇÕES FINANCEIRAS ------------------------------------------------------
      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo',
        ['anterior', 'entradas', 'saidas', 'saldo']);

      for (const grupo of grupos) {
        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 7, colSpan: 7
          }, '', '', '', '', '', '',
          {
            text: this.funcaoService.convertToBrNumber(grupo.totalizadores['anterior']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: +titulo.totalizadores['anterior'] > 0 ? 'D' : 'C', alignment: 'center', fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(grupo.totalizadores['entradas']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(grupo.totalizadores['saidas']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(grupo.totalizadores['saldo']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: +titulo.totalizadores['saldo'] > 0 ? 'D' : 'C', alignment: 'center', fontSize: 7
          },
        ]);

        // TIPOS BANCOS / CAIXA --------------------------------------------------------------------------------
        const tipos = this.funcaoService.agrupar(grupo.registros, 'tipo',
          ['anterior', 'entradas', 'saidas', 'saldo']);
        for (const tipo of tipos) {
          registros.push([
            {
              text: '',
            },
            {
              text: tipo.grupo, bold: true, fontSize: 7, colSpan: 6
            }, '', '', '', '', '',
            {
              text: this.funcaoService.convertToBrNumber(tipo.totalizadores['anterior']),
              alignment: 'right', bold: true, fontSize: 7
            },
            {
              text: +titulo.totalizadores['anterior'] > 0 ? 'D' : 'C', alignment: 'center', fontSize: 7
            },
            {
              text: this.funcaoService.convertToBrNumber(tipo.totalizadores['entradas']),
              alignment: 'right', bold: true, fontSize: 7
            },
            {
              text: this.funcaoService.convertToBrNumber(tipo.totalizadores['saidas']),
              alignment: 'right', bold: true, fontSize: 7
            },
            {
              text: this.funcaoService.convertToBrNumber(tipo.totalizadores['saldo']),
              alignment: 'right', bold: true, fontSize: 7
            },
            {
              text: +titulo.totalizadores['saldo'] > 0 ? 'D' : 'C', alignment: 'center', fontSize: 7
            },
          ]);

          //  ITENS -------------------------------------------------------------------------------------------
          for (const item of tipo.registros) {
            if ((+item.anterior + +item.entradas + +item.saidas + +item.saldo) !== 0) {
              registros.push([
                {
                  text: item.orgao, fontSize: 6
                },
                {
                  text: item.codigo, fontSize: 6
                },
                {
                  text: item.nome, fontSize: 6
                },
                {
                  text: item.banco, fontSize: 6
                },
                {
                  text: item.agencia, fontSize: 6
                },
                {
                  text: item.conta, fontSize: 6
                },
                {
                  text: item.nome_conta, fontSize: 6
                },
                { text: this.funcaoService.convertToBrNumber(item.anterior), alignment: 'right', fontSize: 6 },
                {
                  text: +item.anterior > 0 ? 'D' : 'C', alignment: 'center', fontSize: 7
                },
                { text: this.funcaoService.convertToBrNumber(item.entradas), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(item.saidas), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(item.saldo), alignment: 'right', fontSize: 6 },
                {
                  text: +item.saldo > 0 ? 'D' : 'C', alignment: 'center', fontSize: 7
                }
              ]);
            }
          }
        }
      }
    }

    registros.push([
      {
        text: 'TOTAL', fontSize: 7, bold: true, colSpan: 7,
      }, '', '', '', '', '', '',
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 7, bold: true },
      {
        text: +total1 > 0 ? 'D' : 'C', alignment: 'center', fontSize: 7
      },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total4), alignment: 'right', fontSize: 7, bold: true },
      {
        text: +total4 > 0 ? 'D' : 'C', alignment: 'center', fontSize: 7
      }
    ]);

    // disponibilidade por recurso ------------------------------------------------------------------------------
    this.disponibilidadePorRecurso(dados[1], registros);

    let assinaturas
    if (this.assinatura_ensino) {
      const ass = new Assinaturas(this.login.orgao, this.injector);
      assinaturas = await ass.dadosAssinatura(50, false, null, false, this.assinatura_ensino);
    }

    return this.assinatura_ensino ? [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 3,
          widths: ['*', '*', '*', '*', '*', '*', '*', 55, 25, 55, 55, 55, 25],
          body: registros
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*'],
          body: assinaturas
        }
      }
    ] : [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 3,
        widths: ['*', '*', '*', '*', '*', '*', '*', 55, 25, 55, 55, 55, 25],
        body: registros
      }
    }];
  }

  public disponibilidadePorRecurso(dado: any, registros: any) {
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;

    // monta o cabecalho
    registros.push(
      [{
        text: '',
        alignment: 'center',
        bold: true,
        margin: [10, 20, 10, 20],
        colSpan: 13
      }, '', '', '', '', '', '', '', '', '', '', '', ''],
      [{
        text: 'DISCRIMINAÇÃO',
        alignment: 'center',
        bold: true,
        colSpan: 3
      }, '', '', {
        text: 'DOMICÍLIO BANCÁRIO',
        alignment: 'center',
        bold: true,
        colSpan: 4
      }, '', '', '', {
        text: 'SALDO EXERCÍCIO ANTERIOR',
        alignment: 'center',
        bold: true,
        colSpan: 2
      }, '', {
        text: 'MOVIMENTO ATÉ O TRIMESTRE',
        alignment: 'center',
        bold: true,
        colSpan: 2,
      }, '', {
        text: 'SALDO ATUAL',
        alignment: 'center',
        bold: true,
        colSpan: 2,
      }, ''],
      [{
        text: 'FONTE DE RECURSOS',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'CÓDIGO APLICAÇÃO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'ÓRGÃO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'BANCO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'AGÊNCIA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'CONTA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'NOME',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'VALOR',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'D/C',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'ENTRADAS',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'SAÍDAS',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'VALOR',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'D/C',
        alignment: 'center',
        bold: true, fontSize: 6
      }]
    );

    registros.push([
      {
        text: 'B) MOVIMENTAÇÃO POR FONTE DE RECURSOS',
        alignment: 'left',
        bold: true,
        colSpan: 13
      }, '', '', '', '', '', '', '', '', '', '', '', '']);

    const grupos = this.funcaoService.agrupar(dado, 'grupo',
      ['anterior', 'entradas', 'saidas', 'saldo']);
    for (const grupo of grupos) {
      registros.push([
        {
          text: grupo.grupo, bold: true, fontSize: 7, colSpan: 7
        }, '', '', '', '', '', '',
        {
          text: grupo.totalizadores['anterior'] < 0 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['anterior'] * -1) : this.funcaoService.convertToBrNumber(grupo.totalizadores['anterior']),
          alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: +grupo.totalizadores['anterior'] < 0 ? 'D' : 'C', alignment: 'center', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(grupo.totalizadores['entradas']),
          alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(grupo.totalizadores['saidas']),
          alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: grupo.totalizadores['anterior'] < 0 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['anterior'] * -1) : this.funcaoService.convertToBrNumber(grupo.totalizadores['anterior']),
          alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: +grupo.totalizadores['saldo'] < 0 ? 'D' : 'C', alignment: 'center', fontSize: 7
        },
      ]);
      total1 += +grupo.totalizadores['anterior'];
      total2 += +grupo.totalizadores['entradas'];
      total3 += +grupo.totalizadores['saidas'];
      total4 += +grupo.totalizadores['saldo'];

      //  DO EXERCICIO / EXERCICIO ANTERIOR ------------------------------------------------------
      const tipos = this.funcaoService.agrupar(grupo.registros, 'tipo',
        ['anterior', 'entradas', 'saidas', 'saldo']);
      for (const tipo of tipos) {
        registros.push([
          {
            text: ''
          },
          {
            text: tipo.grupo, bold: true, fontSize: 7, colSpan: 6
          }, '', '', '', '', '',
          {
            text: +tipo.totalizadores['anterior'] < 0 ? this.funcaoService.convertToBrNumber(tipo.totalizadores['anterior'] * -1) : this.funcaoService.convertToBrNumber(tipo.totalizadores['anterior']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: +tipo.totalizadores['anterior'] < 0 ? 'D' : 'C', alignment: 'center', fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(tipo.totalizadores['entradas']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(tipo.totalizadores['saidas']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: +tipo.totalizadores['saldo'] < 0 ? this.funcaoService.convertToBrNumber(tipo.totalizadores['saldo'] * -1) : this.funcaoService.convertToBrNumber(tipo.totalizadores['saldo']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: +tipo.totalizadores['saldo'] < 0 ? 'D' : 'C', alignment: 'center', fontSize: 7
          },
        ]);

        //  ITENS -------------------------------------------------------------------------------------------
        for (const item of tipo.registros) {
          registros.push([
            {
              text: item.orgao, fontSize: 6
            },
            {
              text: item.aplicacao, fontSize: 6
            },
            {
              text: item.recurso, fontSize: 6
            },
            {
              text: item.banco, fontSize: 6
            },
            {
              text: item.agencia, fontSize: 6
            },
            {
              text: item.conta, fontSize: 6
            },
            {
              text: item.nome_conta, fontSize: 6
            },
            { text: item.anterior < 0 ? this.funcaoService.convertToBrNumber(item.anterior * -1) : this.funcaoService.convertToBrNumber(item.anterior), alignment: 'right', fontSize: 6 },
            {
              text: +item.anterior < 0 ? 'D' : 'C', alignment: 'center', fontSize: 7
            },
            { text: this.funcaoService.convertToBrNumber(item.entradas), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(item.saidas), alignment: 'right', fontSize: 6 },
            { text: item.saldo < 0 ? this.funcaoService.convertToBrNumber(item.saldo * -1) : this.funcaoService.convertToBrNumber(item.saldo), alignment: 'right', fontSize: 6 },
            {
              text: +item.saldo < 0 ? 'D' : 'C', alignment: 'center', fontSize: 7
            }
          ]);
        }
      }
    }

    registros.push([
      {
        text: 'TOTAL', fontSize: 7, bold: true, colSpan: 7,
      }, '', '', '', '', '', '',
      { text: total1 < 0 ? this.funcaoService.convertToBrNumber(total1 * -1) : this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 7, bold: true },
      {
        text: +total1 < 0 ? 'D' : 'C', alignment: 'center', fontSize: 7
      },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 7, bold: true },
      { text: total4 < 0 ? this.funcaoService.convertToBrNumber(total4 * -1) : this.funcaoService.convertToBrNumber(total4), alignment: 'right', fontSize: 7, bold: true },
      {
        text: +total4 < 0 ? 'D' : 'C', alignment: 'center', fontSize: 7
      }
    ]);
  }

  public colunasRelatorio(): Coluna[] {
    return [
      { titulo: 'Titulo', coluna: 'titulo' },
      { titulo: 'Grupo', coluna: 'grupo' },
      { titulo: 'Tipo', coluna: 'tipo' },
      { titulo: 'Orgão', coluna: 'orgao' },
      { titulo: 'Código', coluna: 'codigo' },
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'Banco', coluna: 'banco' },
      { titulo: 'Agência', coluna: 'agencia' },
      { titulo: 'Conta', coluna: 'conta' },
      { titulo: 'Nome', coluna: 'nome_conta' },
      { titulo: 'Valor Anterior', coluna: 'anterior', decimais: 2 },
      { titulo: 'D/C Anterior', coluna: 'd_c_anterior' },
      { titulo: 'Entrada', coluna: 'entradas', decimais: 2 },
      { titulo: 'Saída', coluna: 'saidas', decimais: 2 },
      { titulo: 'Saldo', coluna: 'saldo', decimais: 2 },
      { titulo: 'D/C Saldo', coluna: 'd_c_saldo' }
    ]
  }

  public conteudoExportar(lista: any[]) {
    return [
      ...this.normalizarDados(lista[0]),
      ...this.normalizarDados(lista[1])
    ]
  }

  // public normalizarDados(lista: []) {
  //   return lista.map((item: any) => {
  //     return {
  //       titulo: item.titulo,
  //       grupo: item.grupo,
  //       orgao: item.orgao,
  //       codigo: item.codigo,
  //       nome: item.nome,
  //       banco: item.banco,
  //       agencia: item.agencia,
  //       conta: item.conta,
  //       nome_conta: item.nome_conta,
  //       anterior: item.anterior,
  //       d_c_anterior: +item.anterior < 0 ? 'D' : 'C',
  //       entradas: item.entradas,
  //       saidas: item.saidas,
  //       saldo: item.saldo,
  //       d_c_saldo: +item.saldo < 0 ? 'D' : 'C'
  //     }
  //   })
  // }


  public normalizarDados(lista: []) {
    const linhas: {}[] = []

    const totalTitulo = this.funcaoService.agrupar(lista, 'titulo', ['anterior', 'entradas', 'saidas', 'saldo'])

    totalTitulo.forEach(itemA => {
      linhas.push({
        titulo: `${itemA.grupo} - TOTALIZADO`,
        anterior: itemA.totalizadores['anterior'],
        d_c_anterior: +itemA.totalizadores['anterior'] < 0 ? 'D' : 'C',
        entradas: itemA.totalizadores['entradas'],
        saidas: itemA.totalizadores['saidas'],
        saldo: itemA.totalizadores['saldo'],
        d_c_saldo: +itemA.totalizadores['saldo'] < 0 ? 'D' : 'C'
      })

      const totalGrupo = this.funcaoService.agrupar(itemA.registros, 'grupo', ['anterior', 'entradas', 'saidas', 'saldo'])

      totalGrupo.forEach(itemB => {
        linhas.push({
          titulo: itemA.grupo,
          grupo: `${itemB.grupo} - TOTALIZADO`,
          anterior: itemB.totalizadores['anterior'],
          d_c_anterior: +itemB.totalizadores['anterior'] < 0 ? 'D' : 'C',
          entradas: itemB.totalizadores['entradas'],
          saidas: itemB.totalizadores['saidas'],
          saldo: itemB.totalizadores['saldo'],
          d_c_saldo: +itemB.totalizadores['saldo'] < 0 ? 'D' : 'C'
        })

        const totalTipo = this.funcaoService.agrupar(itemB.registros, 'tipo', ['anterior', 'entradas', 'saidas', 'saldo'])

        totalTipo.forEach(itemC => {
          linhas.push({
            titulo: itemA.grupo,
            grupo: itemB.grupo,
            tipo: `${itemC.grupo} - TOTALIZADO`,
            anterior: itemB.totalizadores['anterior'],
            d_c_anterior: +itemB.totalizadores['anterior'] < 0 ? 'D' : 'C',
            entradas: itemB.totalizadores['entradas'],
            saidas: itemB.totalizadores['saidas'],
            saldo: itemB.totalizadores['saldo'],
            d_c_saldo: +itemB.totalizadores['saldo'] < 0 ? 'D' : 'C'
          })

          itemC.registros.forEach(itemD => {
            linhas.push({
              titulo: itemD.titulo,
              grupo: itemD.grupo,
              tipo: itemD.tipo,
              orgao: itemD.orgao,
              codigo: itemD.codigo,
              nome: itemD.nome,
              banco: itemD.banco,
              agencia: itemD.agencia,
              conta: itemD.conta,
              nome_conta: itemD.nome_conta,
              anterior: itemD.anterior,
              d_c_anterior: +itemD.anterior < 0 ? 'D' : 'C',
              entradas: itemD.entradas,
              saidas: itemD.saidas,
              saldo: itemD.saldo,
              d_c_saldo: +itemD.saldo < 0 ? 'D' : 'C'
            })
          })

        })

      })
    })

    return linhas

  }

}
