import { Component, Injector } from '@angular/core';
import { MetaGovernoService } from 'administrativo-lib';
import { BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, LoginContabil, MetaGoverno, Orgao, OrgaoService } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-meta-governo-list',
  templateUrl: './meta-governo-list.component.html'
})
export class MetaGovernoListComponent extends BaseResourceListComponent<MetaGoverno, LoginContabil> {

  /**
   * Declaração de variáveis
   */

  public listaOrgaos: Orgao[] = [];
  public orgaoSelect: number = null;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private metaService: MetaGovernoService,
    private orgaoService: OrgaoService) {
    super(metaService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(_entidade: MetaGoverno): boolean {
    return this.login.sistema != 'controle-interno'
  }

  protected relations(): string {
    return 'programa,funcao,subfuncao,unidade,unidade.orgao';
  }

  protected condicoesGrid(): {} {
    return {
      ['ppa.id']: this.login.ppa.id, ['unidade.orgao.id']: this.orgaoSelect
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['programa.codigo$ASC', ''];
  }

  protected filtrosGrid(): Filtro {
    return {
      //number: ['programa.codigo', 'unidade.codigo', 'funcao.codigo'],
      text: ['programa.codigo', 'unidade.codigo', 'funcao.codigo', 'programa.nome', 'funcao.nome', 'unidade.nome'],
    };
  }

  public beforeInit(): void {
    this.usarFiltroPersonalizado = true;

    this.buscarOrgaos();
    this.orgaoSelect = this.login.orgao.id;
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: MetaGoverno): Observable<MetaGoverno> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'programa.codigo' },
      { titulo: 'Descrição', coluna: 'programa.nome' },
      { titulo: 'Função', coluna: 'funcao.nome' },
      { titulo: 'Subfunção', coluna: 'subfuncao.nome' },
      { titulo: 'Unidade', coluna: 'unidade.nome' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'programa,funcao,subfuncao,unidade';
    this.metaService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE METAS DE GOVERNO:
          ${this.login.ppa.ppaperiodo.ano1} - ${this.login.ppa.ppaperiodo.ano4}`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem Metas de Governo', ['auto', 'auto', 'auto', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public buscarOrgaos(): void {
    const parametros = {
      orderBy: 'codigo$ASC'
    };
    parametros['cidade.id'] = this.login.cidade.id;
    this.orgaoService
      .filtrar(
        1,
        -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        dados => {
          this.listaOrgaos = dados.content;
        },
        this.logErro
      );
  }

  public logErro(er: any): void {
    console.error(er);
    toastr.error(er.error.payload, 'Erro ao buscar orgão');
    throw er;
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/planejamento']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public obterColunasFiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Programa', coluna: 'programa.nome', tipo: 'String', alignment: 'center', padrao: true, });
    retorno.push({ titulo: 'Código', coluna: 'programa.codigo', alignment: 'center', tipo: 'Number', padrao: true });
    retorno.push({ titulo: 'Descrição da função', coluna: 'funcao.nome', tipo: 'String', padrao: true, cols: 3 });
    retorno.push({ titulo: 'Código da Função', coluna: 'funcao.codigo', alignment: 'center', tipo: 'String', cols: 2, padrao: true });
    retorno.push({ titulo: 'Descrição unidade', coluna: 'unidade.nome', alignment: 'center', tipo: 'String', cols: 3, padrao: true });
    retorno.push({ titulo: 'Código Unidade', coluna: 'unidade.codigo', alignment: 'center', tipo: 'String', padrao: true, cols: 2 });

    return retorno;
  }
}
