// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { EtapaMerenda } from './etapa-merenda.model';
import { EtapaItemMerenda } from './etapa-item-merenda.model';
import { EtapaSetorMerenda } from './etapa-setor-merenda.model';

export class EtapaItemSetor extends BaseResourceModel {
  constructor(
    public id?: number,
    public qtde?: number,
    public qtde_fornecedor?: number,
    public peso_unitario?: number,
    public etapa?: EtapaMerenda,
    public etapa_item?: EtapaItemMerenda,
    public etapa_setor?: EtapaSetorMerenda,
    public editavel?: boolean) {
    super();
  }
  static converteJson(json: any): EtapaItemSetor {
    return Object.assign(new EtapaItemSetor(), json);
  }
}
