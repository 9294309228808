import { Component, Input, OnInit } from '@angular/core';
import { GlobalService, RegistroPrecos } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-comissao',
  templateUrl: './licitacao-audesp-comissao.component.html'
})
export class LicitacaoAudespComissaoComponent extends BaseLicitacaoAudesp implements OnInit {

  @Input() public entidade: RegistroPrecos;

  public opcoesTipoComissao: { id: number, nome: string }[];

  constructor(public globalService: GlobalService
  ) {
    super()
  }

  ngOnInit(): void {
    this.opcoesTipoComissao = [
      { id: 1, nome: 'Permanente' },
      { id: 2, nome: 'Especial' },
      { id: 3, nome: 'Servidor Designado' },
    ];
  }
}
