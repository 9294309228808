import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { RequisicaoAutorizacaoFormComponent } from './requisicao-autorizacao-form/requisicao-autorizacao-form.component';
import { RequisicaoAutorizacaoListComponent } from './requisicao-autorizacao-list/requisicao-autorizacao-list.component';
import { RequisicaoAutorizacaoViewComponent } from './requisicao-autorizacao-view/requisicao-autorizacao-view.component';
import { RequisicaoAutorizacaoPacienteListComponent } from './requisicao-autorizacao-paciente-list/requisicao-autorizacao-paciente-list.component';

const routes: Routes = [
  { path: '', component: RequisicaoAutorizacaoListComponent, canActivate: [AuthGuard] },
  { path: 'paciente', component: RequisicaoAutorizacaoPacienteListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: RequisicaoAutorizacaoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: RequisicaoAutorizacaoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: RequisicaoAutorizacaoFormComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RequisicaoAutorizacaoRoutingModule { }
