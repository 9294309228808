import { EmissaoCheque } from '../../emissao-cheque/emissao-cheque.model';
import { Component, Injector, ElementRef, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { takeUntil } from 'rxjs/operators';
import { NotaPagamento } from '../../relatorio-tesouraria/notas/nota-pagamento';
import {
  BaseResourceFormComponent, ContaBancaria, ContaBancariaRecurso, DateFormatPipe, Empenho, EddyAutoComplete, FichaDespesa, FuncaoService, GlobalService,
  Liquidacao, LoginContabil, OrgaoAssinaturaService, Pagamento, Tabela1Reinf
} from 'eddydata-lib';
import { LiquidacaoService, ContaBancariaService, PagamentoService, AdiantamentoService, EmpenhoService } from 'administrativo-lib';
import { DatePipe } from '@angular/common';
import { ContaBancariaRecursoService } from '../../conta-bancaria/service/conta-bancaria-recurso.service';
import * as toastr from 'toastr';
import { FichaDespesaService } from '../../../ficha-despesa/service/ficha-despesa.service';
import { EmissaoChequeService } from '../../service/emissao-cheque.service';

@Component({
  selector: 'lib-pagamento-form',
  templateUrl: './pagamento-form.component.html'
})
export class PagamentoFormComponent extends BaseResourceFormComponent<Pagamento, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @ViewChild('InputFocus') private inputField: Calendar;

  public especie: string;
  public liquidado: number;
  public saldoPagar = 0;
  public saldoBancario = 0;
  public fichaDespesa: FichaDespesa;
  public contaAutoComplete: EddyAutoComplete<ContaBancaria>;
  public contaRecursoAutoComplete: EddyAutoComplete<ContaBancariaRecurso>;
  public contaCodigo: number;
  public contaId: number;
  public empenhoNumero: number;
  public parcela: number = 0;
  public anulacao = false;
  public redirecionar = false;
  public liquidadoParcela: number = 0;
  public totalRetido: number = 0;
  public pagoParcela: number = 0;
  public temCheque: boolean = false;
  public valorLiquido: number = 0;
  public desabilitar: boolean = true;
  public objetoReinfTabela1: any = null;
  public empenhosInscritos: Empenho[] = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected injector: Injector,
    protected confirmationService: ConfirmationService,
    protected contaService: ContaBancariaService,
    protected contaRecursoService: ContaBancariaRecursoService,
    protected liquidacaoService: LiquidacaoService,
    protected globalService: GlobalService,
    protected adiantamentoService: AdiantamentoService,
    protected fichaService: FichaDespesaService,
    protected pagamentoService: PagamentoService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected empenhoService: EmpenhoService,
    protected tabela1ReinfServico: Tabela1Reinf,
    protected emissaoChequeService: EmissaoChequeService) {
    super(new Pagamento(), injector, Pagamento.converteJson, pagamentoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(_entidade: Pagamento): Promise<boolean> {
    return !_entidade.anulacao && !_entidade.anulado_total && this.login.sistema != 'controle-interno'
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      valor_pago: [0, [Validators.required]],
      valor_retido: [0, [Validators.required]],
      valor_liquido: [0, [Validators.required]],
      mes: [null],
      aux: [null],
      data_pagamento: [null, [Validators.required]],
      data_referencia: [null],
      especie: [null],
      documento: [null, [Validators.required]],
      impresso: [false, [Validators.required]],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      anulacao: [false, [Validators.required]],
      conta: [null, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      conta_recurso: [null, [Validators.required]],
      liquidacao: [null, [Validators.required]],
      usuario_cadastro: [this.login.usuario, [Validators.required]],
      situacao: ['N'],
      pagamento_S13: [null],
      valor_RRA: [0],
      juridico_RRA: [null],
      indice_fci_scp: [null],
      cnpj_fci_scp: [null],
      porcentagem_scp: [0],
      pais_pagamento: [105],
      nif_tributo: [null],
      anulado_total: [false]
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'conta,conta.banco,liquidacao,liquidacao.empenho,liquidacao.empenho.favorecido,' +
        'liquidacao.empenho.subelemento.vpd,liquidacao.empenho.ficha,liquidacao.empenho.convenio,liquidacao.empenho.ficha.executora,' +
        'liquidacao.empenho.ficha.acao,liquidacao.empenho.modalidade,exercicio,orgao,liquidacao.empenho.ficha.recurso,' +
        'liquidacao.empenho.ficha.aplicacao,liquidacao.empenho.ficha.aplicacao_variavel,conta_recurso,conta_recurso.recurso,conta_recurso.aplicacao,conta_recurso.convenio'
    };
  }

  private carregarAutoCompletes() {
    // autocomplete para conta
    this.contaAutoComplete = new EddyAutoComplete(this.entidadeForm.get('conta'), this.contaService,
      'id', ['numero_conta', 'banco.nome', 'nome'], { orgao_id: this.login.orgao.id, relations: 'banco', orderBy: 'nome' },
      { number: ['numero_conta'], text: ['nome'] },
      this.buscarSaldoBancario()
    );
    this.carregarAutoCompletesContaRecurso();
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.inicializarVariavel();
    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (params: any) => {
          if (params.hasOwnProperty('liquidacao')) {
            const parametros = {};
            parametros['id'] = params['liquidacao'];
            parametros['relations'] = 'empenho,empenho.favorecido,empenho.subelemento,empenho.ficha,empenho.convenio,' +
              'empenho.ficha.acao,empenho.modalidade,exercicio,orgao,empenho.ficha.recurso,empenho.ficha.aplicacao,empenho.ficha.aplicacao_variavel';
            this.liquidacaoService.obter(parametros)
              .pipe(takeUntil(this.unsubscribe))
              .subscribe(
                (entidade) => {
                  if (entidade) {
                    this.redirecionar = true;
                    this.entidade.liquidacao = entidade;
                    this.empenhoNumero = this.entidade.liquidacao.empenho.numero;
                    this.parcela = this.entidade.liquidacao.parcela;
                    this.fichaDespesa = this.entidade.liquidacao.empenho.ficha;
                    this.entidadeForm.get('liquidacao').setValue(entidade);
                    this.entidadeForm.get('valor_pago').setValue(entidade.valor_liquidado);
                    this.entidadeForm.get('historico')
                      .setValue(`PAGTO EMP. ${entidade.empenho.numero} ${entidade.empenho.favorecido.nome}`);
                    this.buscarTotalLiquidado(this.entidade.liquidacao.empenho.numero);
                  } else {
                    this.messageService.add({
                      severity: 'info', summary: 'Informação',
                      detail: 'Empenho não foi encontrado!'
                    });
                  }
                }, () => this.sair());
          }
        });
  }

  protected campoFoco(): ElementRef<any> {
    return this.inputField.inputfieldViewChild.nativeElement.focus();
  }

  protected async afterLoad() {
    if (!this.podeAlterarAudesp(this.entidade.data_pagamento)) {
      this.router.navigate(['/pagamentos-orcamentarios']);
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return;
    }

    this.entidade.data_pagamento = new DateFormatPipe().transform(this.entidade.data_pagamento, []);
    this.empenhoNumero = this.entidade.liquidacao.empenho.numero;
    this.parcela = this.entidade.liquidacao.parcela;
    this.contaCodigo = this.entidade.conta.codigo;
    this.contaId = this.entidade.conta.id;
    this.anulacao = this.entidade.anulacao;
    this.fichaDespesa = this.entidade.liquidacao.empenho.ficha;
    this.buscarSaldoBancario();
    this.buscarContaRecurso(this.contaId);
    this.buscarTotalLiquidado(this.entidade.liquidacao.empenho.numero);
    this.inicializarVariavel();
    if (this.entidade.liquidacao?.empenho?.subelemento?.codigo_reinf) {
      this.objetoReinfTabela1 = this.tabela1ReinfServico.carregarPorCodigo(this.entidade.liquidacao.empenho.subelemento.codigo_reinf, true);
    }

    this.empenhosInscritos = (await this.empenhoService.filtrar(1, -1, {
      exercicio_id: this.login.exercicio.id,
      orgao_id: this.login.orgao.id,
      inscrito_resto: true
    }).toPromise()).content;
    if (this.empenhosInscritos.length > 0 && !this.soVisualizar) {
      toastr.warning('Já foram inscrito os resto a pagar do exercício, não será possível alterar o registro!')
      this.router.navigate([`/pagamentos-orcamentarios/${this.entidade.id}/visualizar`]);
    }
  }

  protected afterSubmit(ent: Pagamento) {
    if (this.currentActionRoute === 'novo' && this.login.redirecionar_liquidar_pagar && this.redirecionar
      && this.podeIncluir('empenhos-orcamentario')) {
      this.router.navigate(['/empenhos-orcamentario', 'novo']);
    }
  }

  public sair() {
    if (this.redirecionar && this.podeIncluir('empenhos-orcamentario')) {
      this.router.navigate(['/empenhos-orcamentario']);
    } else {
      new FuncaoService().navegarPara(this.login.usuario.sistema, this.router);
    }
  }

  protected beforeSubmit() {
    try {
      const dataPagamento = new Date(this.entidadeForm.get('data_pagamento').value);
      this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidadeForm.get('data_pagamento').value)?.split('-')?.[1]);
      this.entidadeForm.get('especie').setValue(!this.anulacao ? 'PGO' : 'PGA');
      this.entidadeForm.get('anulacao').setValue(this.anulacao);

      if (this.anulacao) {
        if (this.entidadeForm.get('valor_pago').value >= 0) {
          throw new Error('Valor anulado deve ser negativo!');
        }
      }
      if (dataPagamento > new Date()) {
        throw new Error('Data do pagamento está maior que a data atual');
      }
      if (this.entidade.liquidacao.data_liquidacao > dataPagamento) {
        throw new Error('Data de pagamento tem que ser maior ou igual a data de liquidação!');
      }

      const valorLiquido = +this.entidadeForm.get('valor_pago').value - +this.entidadeForm.get('valor_retido').value;
      if (+valorLiquido.toFixed(2) > +this.saldoBancario.toFixed(2)) {
        throw new Error('Saldo de banco insuficiente para efetuar o pagamento.');
      }

      if ((+this.saldoPagar.toFixed(2) - +(valorLiquido -
        (this.entidade.valor_pago ? this.entidade.valor_pago : +this.entidadeForm.get('valor_pago').value))) < 0) {
        throw new Error('Não há saldo para efetuar o pagto. Verifique se a liquidação já está paga ou foi anulada!');
      }

      if (!this.entidadeForm.get('conta_recurso').value) {
        throw new Error('O recurso da despesa não esta vinculada a esta conta bancária!');
      }
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private inicializarVariavel() {
    if (this.currentActionRoute === 'novo') {
      this.empenhoNumero = this.entidade.liquidacao ? this.entidade.liquidacao.empenho.numero : null;
      this.parcela = this.entidade.liquidacao ? this.entidade.liquidacao.parcela : null;
      this.entidade.valor_retido = 0;
      this.entidade.impresso = false;
      this.entidade.historico = 'PAGAMENTO EFETUADO NO DIA';
      this.entidade.exercicio = this.login.exercicio;
      this.entidade.orgao = this.login.orgao;
      this.contaCodigo = this.entidade.conta ? this.entidade.conta.codigo : null;
      this.contaId = this.entidade.conta ? this.entidade.conta.id : null;
      this.pagamentoService.ultimaDataPagamento(this.login.exercicio.id, this.login.orgao.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((dataLiq) => {
          if (dataLiq === 0) {
            this.entidade.data_pagamento = new Date();
          } else {
            this.entidade.data_pagamento = new DateFormatPipe().transform(dataLiq, []);
          }
          this.entidadeForm.get('data_pagamento').setValue(this.entidade.data_pagamento);
          this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidade.data_pagamento)?.split('-')?.[1]);
        });
    }
  }

  public buscarLiquidacao() {
    if (this.empenhoNumero > 0) {
      const param = {};
      param['empenho.numero'] = this.empenhoNumero;
      param['exercicio.id'] = this.login.exercicio.id;
      param['orgao.id'] = this.login.orgao.id;
      param['anulacao'] = false;
      param['anulado_total'] = false;
      param['relations'] = 'empenho,empenho.favorecido,empenho.subelemento,empenho.subelemento.vpd,empenho.ficha,empenho.convenio,' +
        'empenho.ficha.acao,exercicio,orgao,empenho.ficha.recurso,empenho.ficha.aplicacao,empenho.ficha.aplicacao_variavel,empenho.modalidade';
      if (this.parcela > 0) {
        param['parcela'] = this.parcela;
      }
      this.liquidacaoService.obter(param).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (model) => {
            if (model) {
              if (model.empenho?.subelemento?.vpd) {
                let datepipe: DatePipe = new DatePipe('pt');
                this.adiantamentoService.filtrar(1, -1, {
                  'empenho.id': model.empenho.id,
                  'orgao.id': this.login.orgao.id, 'exercicio.id': this.login.exercicio.id
                }).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
                  if (res.content.length > 0) {
                    if (!res.content[0].data_vencimento) {
                      toastr.warning(`O adiantamento ${res.content[0].numero} não possui data de vencimento. Preencha os campos obrigatórios do adiantamento para poder seguir com o pagamento.`)
                    } else {
                      this.carregarValores(model);
                    }
                  } else {
                    toastr.warning('Esse empenho foi identificado como uma despesa de adiantamento e não possui um processo de adiantamento aberto.');
                  }
                });
              } else {
                this.carregarValores(model);
              }
            } else {
              this.messageService.add({ severity: 'info', summary: 'Informação', detail: 'Nota de liquidação não foi encontrada!' });
            }
          }, (error) => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));
    }
  }

  private async carregarValores(model: Liquidacao) {
    this.entidade.liquidacao = model;
    this.parcela = model.parcela;
    this.fichaDespesa = model.empenho.ficha;
    this.entidadeForm.get('liquidacao').setValue(model);
    this.entidadeForm.get('historico').setValue(await this.gerarHistorico(this.empenhoNumero));
    this.entidadeForm.get('documento').setValue(this.temCheque ? this.entidadeForm.get('historico').value : '')
    this.buscarTotalLiquidado(this.entidade.liquidacao.empenho.numero);
  }

  public buscarConta() {
    if (this.contaCodigo && this.contaCodigo >= 0) {
      this.contaService.obter({ codigo: this.contaCodigo, 'orgao.id': this.login.orgao.id, relations: 'banco' }).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            if (data) {
              if (data.ativo) {
                this.entidadeForm.get('conta').setValue(data);
                this.contaId = data.id;
                this.carregarAutoCompletesContaRecurso();
                this.buscarSaldoBancario();
                this.buscarContaRecurso(this.contaId, data)
                if (!this.login.fonte_recurso_pagamento) {
                  this.desabilitar = false;
                }
              } else {
                this.contaCodigo = null;
                toastr.error('Conta bancária inativa!');
                return;
              }
            }
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  public changeConta() {
    if (this.entidadeForm.get('conta')) {
      const conta = this.entidadeForm.get('conta').value;
      this.contaId = conta.id;
      this.contaCodigo = conta.codigo;
      this.carregarAutoCompletesContaRecurso();
      this.buscarSaldoBancario();
      if (!this.login.fonte_recurso_pagamento) {
        this.desabilitar = false;
      }
    }
  }

  private carregarAutoCompletesContaRecurso() {
    let parametros = {}
    parametros = { 'conta.codigo': this.contaCodigo, relations: 'recurso,aplicacao,convenio', orderBy: 'aplicacao.codigo' };

    if (this.login.fonte_recurso_pagamento) {
      parametros = { 'conta.codigo': this.contaCodigo, 'excluido': false, relations: 'recurso,aplicacao,convenio' };
    }

    this.contaRecursoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('conta_recurso'), this.contaRecursoService,
      'id', ['recurso.codigo', 'aplicacao.codigo', 'aplicacao.nome'], parametros,
      { number: ['aplicacao.codigo'], text: ['aplicacao.nome'] },
      this.buscarContaRecurso(this.contaId)
    );
  }

  public buscarSaldoBancario() {
    if (this.contaId > 0) {
      let datepipe: DatePipe = new DatePipe('pt');
      const dataPagamento = this.entidadeForm.get('data_pagamento').value ? datepipe.transform(this.entidadeForm.get('data_pagamento').value, 'yyyy-MM-dd', 'GMT') :
        datepipe.transform(this.entidade.data_pagamento, 'yyyy-MM-dd', 'GMT');
      this.contaService.obterSaldoBancarioConta(this.contaId, this.login.exercicio.id, this.login.orgao.id, dataPagamento).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (saldoTotal: any) => {
            this.saldoBancario = saldoTotal;
            this.buscarSaldoRecurso();
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  public buscarSaldoRecurso() {
    // calculo do saldo de banco por recurso
    if (this.contaCodigo > 0) {
      let parametros = {}
      parametros = { 'conta.codigo': this.contaCodigo, relations: 'recurso,aplicacao,convenio' };

      if (this.login.fonte_recurso_pagamento) {
        parametros = { 'conta.codigo': this.contaCodigo, 'excluido': false, relations: 'recurso,aplicacao,convenio' };
      }

      this.contaRecursoService.obter(parametros).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (res: any) => {
            if (res) {
              this.carregarAutoCompletesContaRecurso();
              if (!this.entidadeForm.get('conta_recurso').value) {
                this.entidadeForm.get('conta_recurso').setValue(res);
              }
            }
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  private buscarContaRecurso(id: number, contaBancaria?: ContaBancaria) {
    if (this.fichaDespesa && !this.login.fonte_recurso_pagamento) {
      this.contaRecursoService.obterRecursoPorCodigo(id, this.fichaDespesa.recurso.codigo, this.fichaDespesa.aplicacao.codigo,
        this.fichaDespesa.aplicacao_variavel ? this.fichaDespesa.aplicacao_variavel.codigo : null)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            if (data.content[0]) {
              this.entidadeForm.get('conta_recurso').setValue(data.content[0]);
            } else {
              this.entidadeForm.get('conta_recurso').setValue(null);
              this.entidadeForm.get('conta').setValue(null);
              this.contaId = 0;
              this.messageService.add({
                severity: 'warn', summary: 'Atenção',
                detail: `O recurso da despesa ${this.fichaDespesa.recurso.codigo}.${this.fichaDespesa.aplicacao.codigo} ${this.fichaDespesa.aplicacao_variavel ? '.' + this.fichaDespesa.aplicacao_variavel.codigo : ''} não esta vinculado para esta conta bancária`
              });
            }
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  public buscarTotalLiquidado(numero: number) {
    if (this.empenhoNumero > 0) {
      this.liquidacaoService.obterTotalLiquidadoPorEmpenho(numero, this.login.exercicio.id, this.login.orgao.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (res: any) => {
            this.liquidado = res.liquidado;
            this.liquidacaoService.obterTotalLiquidadoPorParcela(numero, this.login.exercicio.id, this.login.orgao.id, this.entidade.liquidacao.parcela)
              .pipe(takeUntil(this.unsubscribe))
              .subscribe((res) => {
                this.liquidadoParcela = +res.liquidado;
                this.totalRetido = +res.retido;//Se foi anulado no pagamento, já pega o valor reduzido.
                this.buscarTotalPago();
              });
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  public buscarTotalPago() {
    this.pagamentoService.obterTotalPagoPorLiquidacaoId(this.entidade.liquidacao.id).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      //Retorna atuais valores pagos constando anulações.
      this.pagoParcela = +res.total_pago > 0 ? +res.total_pago : (+res.total_pago * -1)
      const pagoRetencao = +res.total_pago_retencao;

      if (!this.entidade.id) {
        this.entidadeForm.get('valor_retido').setValue(+this.totalRetido - +pagoRetencao);
      }

      this.saldoPagar = +this.liquidadoParcela.toFixed(2) - +this.pagoParcela

      if (!this.entidade.id) {
        this.entidadeForm.get('valor_pago').setValue(this.saldoPagar.toFixed(2));
      }

      this.atualizarLiquido();
      if (this.saldoPagar <= 0 && !this.entidade.id) {
        toastr.warning('Liquidação totalmente paga', 'Atenção')
        return
      }
      this.saldoPagar = +(+this.liquidadoParcela - (+this.totalRetido - +pagoRetencao)).toFixed(2);
    });
  }

  public atualizarLiquido() {
    this.entidadeForm.get('valor_liquido').setValue(+this.entidadeForm.get('valor_pago').value - +this.entidadeForm.get('valor_retido').value);
    if (this.saldoPagar > 0 || this.entidade.id) {
      this.valorLiquido = this.entidadeForm.get('valor_pago').value - this.entidadeForm.get('valor_retido').value
    }
  }

  public async gerarHistorico(empenhoNumero: number) {
    let cheques = []
    let emissaoCheques: EmissaoCheque[] = (await this.emissaoChequeService.filtrar(1, -1, { relations: 'cheque_empenho,cheque_item', 'cheque_empenho.numero': empenhoNumero, 'orgao_id': this.login.orgao.id }).toPromise()).content

    emissaoCheques.forEach(emissao => {
      emissao.cheque_item.forEach(item => {
        cheques.push(item.numero)
      });
    });

    cheques.sort((a, b) => +a - +b)

    if (cheques.length === 0) {
      this.temCheque = false
      return `PAGTO EMP. ${this.entidade.liquidacao.empenho.numero} ${this.entidade.liquidacao.empenho.favorecido.nome}`
    } else if (cheques.length > 1) {
      this.temCheque = true
      return `PAGTOS DOS CHEQUES Nº${cheques.find(ch => ch > 0)} à ${cheques[cheques.length - 1]}`
      // return `PAGTOS DOS CHEQUES Nº${cheques.join(', ')}`
    } else {
      this.temCheque = true
      return `PAGTO DO CHEQUE Nº${cheques[0]}`
      // return `PAGTO DO CHEQUE Nº${cheques.join(', ')}`
    }
  }

  public imprimir() {
    const parametros = {};
    let relations = 'liquidacao.empenho.ficha.executora.unidade,liquidacao.empenho.ficha.despesa,liquidacao.empenho.ficha.funcao,liquidacao.empenho.ficha.subfuncao,liquidacao.empenho.ficha.programa,liquidacao.empenho.ficha.acao,liquidacao.empenho.ficha.recurso,liquidacao.empenho.ficha.aplicacao,';
    relations += 'liquidacao.empenho.licitacao,liquidacao.empenho.modalidade,liquidacao.empenho.subelemento,liquidacao.empenho.favorecido.contas.banco,liquidacao.empenho.favorecido.tipo,liquidacao.empenho.contrato,liquidacao.empenho.convenio,liquidacao.empenho.liquidacoes,liquidacao.empenho.compra,liquidacao.empenho.compra.rcms,liquidacao.empenho.compra.rcms.requerente,liquidacao.empenho.compra.rcms.prazo';

    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id'] = this.entidade.id;
    this.pagamentoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        new NotaPagamento(this.assinaturaService).imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

  public voltar() {
    if (this.login.sistema === 'contabil') {
      this.router.navigate(['/tesouraria']);
    } else {
      this.sair();
    }
  }

  atualizarReinf(event: Pagamento) {
    this.entidade = event;
    this.entidadeForm.patchValue(this.entidade);
  }
}
