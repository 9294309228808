import { Directive, OnDestroy } from '@angular/core';
import { FuncaoService, GlobalService, Ldo, LoginContabil, Orgao, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { Dados, DemonstrativoDescricao1, DemonstrativoDescricao2, LdoService } from '../../../../planejamento/ldo/service/ldo.service';

@Directive()
export class RiscosFiscaisProvidencias implements OnDestroy {

  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected ano: number,
    protected ldoServico: LdoService,
    protected orgaos: number[] = [],) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio() {
    this.ldoServico.buscarDescricao1({ ano: this.ano, orgaos: this.orgaos, ppa: this.login.ppa.id })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        if (!dados.length) {
          toastr.error('Não foram encontrados registros.');
          return
        };
        Relatorio.imprimirPersonalizado('ARF/Tabela 1 - DEMONSTRATIVO DOS RISCOS FISCAIS E PROVIDÊNCIAS',
          this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
          this.conteudo(dados),
          'landscape', 'DEMONSTRATIVO RISCOS FISCAIS E PROVIDÊNCIAS',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private conteudo(dadosCrus: [DemonstrativoDescricao1[], DemonstrativoDescricao2[]]): {}[] {
    const registros: {}[] = []

    // Cabeçalho
    registros.push([
      { text: `LEI DE DIRETRIZES ORÇAMENTÁRIAS`, fontSize: 11, alignment: 'center', margin: [0, 3, 0, 3], colSpan: 4, border: [false, false, false, false], }, '', '', ''
    ])
    registros.push([
      { text: `ANEXO DE RISCOS FISCAIS`, fontSize: 11, alignment: 'center', margin: [0, 3, 0, 3], colSpan: 4, border: [false, false, false, false], }, '', '', ''
    ])
    registros.push([
      { text: `DEMONSTRATIVO DE RISCOS FISCAIS E PROVIDÊNCIAS`, fontSize: 11, alignment: 'center', margin: [0, 3, 0, 3], bold: true, colSpan: 4, border: [false, false, false, false], }, '', '', ''
    ])
    registros.push([
      { text: `EXERCÍCIO: ${this.ano}`, fontSize: 11, alignment: 'center', margin: [0, 3, 0, 3], bold: true, colSpan: 4, border: [false, false, false, false], }, '', '', ''
    ])
    registros.push([
      { text: `ARF (LRF, art 4º, § 3º)`, fontSize: 7, margin: [0, 3, 0, 3], bold: true, colSpan: 4, border: [false, false, false, false], }, '', '', ''
    ])
    registros.push([
      { text: `PASSIVOS CONTINGENTES`, fontSize: 11, alignment: 'center', margin: [0, 3, 0, 3], bold: true, colSpan: 2, border: [true, true, true, true], fillColor: '#f5f5f5' }, '',
      { text: 'PROVIDÊNCIAS', fontSize: 11, alignment: 'center', margin: [0, 3, 0, 3], bold: true, colSpan: 2, border: [true, true, true, true], fillColor: '#f5f5f5' }, ''
    ], [
      { text: 'Descrição', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3], fillColor: '#f5f5f5' },
      { text: 'Valor', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3], fillColor: '#f5f5f5' },
      { text: 'Descrição', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3], fillColor: '#f5f5f5' },
      { text: 'Valor', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3], fillColor: '#f5f5f5' },
    ]);

    let dados: Dados[] = [];

    let acc = 1;
    let linhaMestre = 0;
    let dadosAgrupados = this.funcaoService.agrupar(dadosCrus[1], 'linha');

    for (const grupo of dadosAgrupados) {
      let lista = dadosCrus[1].filter(dado => +dado.linha === +grupo.grupo && dado.somador)

      if (lista?.length > 1) {
        let itemSemDescricao = lista.find(i => !i.descricao2)
        let itemComDescricao = lista.find(i => i.descricao2)

        if (itemSemDescricao && itemComDescricao) {
          dadosCrus[1].splice(dadosCrus[1].indexOf(itemSemDescricao), 1)
        }
      }

    }

    dadosCrus[0].forEach(f => {
      if (linhaMestre !== f.linha) {
        linhaMestre = f.linha;
        acc = 1;
      }
      f.linhaAgrupamento = String(acc);
      acc++;
    });

    acc = 1;
    linhaMestre = 0;

    dadosCrus[1].forEach(f => {
      if (linhaMestre !== f.linha) {
        linhaMestre = f.linha;
        acc = 1;
      }
      f.linhaAgrupamento = String(acc);
      acc++;
    });

    if (dadosCrus[0].length > dadosCrus[1].length) {
      dados = dadosCrus[0].map<Dados>(dado => {
        const dados2 = dadosCrus[1].find(d => d.linha === dado.linha && d.somador === dado.somador && d.linhaAgrupamento === dado.linhaAgrupamento)
        return {
          ...dado,
          descricao2: dados2?.descricao2 || '',
          valor2: dados2?.valor2 || 0,
          valorSomado2: dados2?.valorSomado2 || dado.somador ? (dadosCrus[1].find(d => d.linha === dado.linha))?.valorSomado2 : 0
        }
      });
    } else {
      dados = dadosCrus[1].map<Dados>(dado => {
        const dados2 = dadosCrus[0].find(d => d.linha === dado.linha && d.somador === dado.somador && d.linhaAgrupamento === dado.linhaAgrupamento)
        return {
          ...dado,
          descricao1: dados2?.descricao1 || '',
          valor1: dados2?.valor1 || 0,
          valorSomado1: dados2?.valorSomado1 || dado.somador ? (dadosCrus[0].find(d => d.linha === dado.linha))?.valorSomado1 : 0
        }
      });
    }
    this.soma(dados)

    for (const item of dados) {
      if (item.titulo) {
        if (item.descricao1 === 'Descrição') {
          registros.push([
            { text: item.descricao1, fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3], fillColor: '#f5f5f5' },
            { text: item.descricao2, fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3], fillColor: '#f5f5f5' },
            { text: item.descricao1, fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3], fillColor: '#f5f5f5' },
            { text: item.descricao2, fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3], fillColor: '#f5f5f5' },
          ]);
        } else {
          registros.push([
            { text: item.descricao1, fontSize: 11, alignment: 'center', margin: [0, 3, 0, 3], bold: true, colSpan: 2, border: [true, true, true, true], fillColor: '#f5f5f5' }, '',
            { text: item.descricao2, fontSize: 11, alignment: 'center', margin: [0, 3, 0, 3], bold: true, colSpan: 2, border: [true, true, true, true], fillColor: '#f5f5f5' }, ''
          ])

        }
      } else {
        registros.push([
          { text: item.descricao1, bold: item.somador ? true : false, fontSize: 8, border: [true, true, true, true] },
          { text: this.funcaoService.convertToBrNumber(item.somador ? item.valorSomado1 : item.valor1, 2), bold: item.somador ? true : false, alignment: 'right', fontSize: 8, border: [true, true, true, true] },
          { text: item.descricao2, bold: item.somador ? true : false, fontSize: 8, border: [true, true, true, true] },
          { text: this.funcaoService.convertToBrNumber(item.somador ? item.valorSomado2 : item.valor2, 2), bold: item.somador ? true : false, alignment: 'right', fontSize: 8, border: [true, true, true, true] },
        ])
      }
    }
    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        // headerRows: 2,
        widths: ['*', 80, '*', 80],
        body: registros
      }
    }];
  }

  private soma(lista: Dados[]) {
    // for (const linhaTotal of lista.filter(item => item.somador)) {
    //   linhaTotal.valor1 = lista
    //     .filter(item => !item.somador && item.linha === linhaTotal.linha).length !== 0
    //     ? lista
    //       .filter(item => !item.somador && item.linha === linhaTotal.linha)
    //       .reduce((acc, atual) => acc += +atual.valor1, 0)
    //     : linhaTotal.valor1;
    //   linhaTotal.valor2 = lista
    //     .filter(item => !item.somador && item.linha === linhaTotal.linha).length !== 0
    //     ? lista
    //       .filter(item => !item.somador && item.linha === linhaTotal.linha)
    //       .reduce((acc, atual) => acc += +atual.valor2, 0)
    //     : linhaTotal.valor2;
    // }
    for (const linhaTotal of lista.filter(item => item.descricao1 === 'SUBTOTAL')) {
      linhaTotal.valorSomado1 = lista
        .filter(item => item.somador && item.descricao1 !== 'TOTAL' && item.descricao1 !== 'SUBTOTAL' && item.tipo === linhaTotal.tipo).length !== 0
        ? lista
          .filter(item => item.somador && item.descricao1 !== 'TOTAL' && item.descricao1 !== 'SUBTOTAL' && item.tipo === linhaTotal.tipo)
          .reduce((acc, atual) => acc += +atual.valorSomado1, 0)
        : linhaTotal.valorSomado1;
      linhaTotal.valorSomado2 = lista
        .filter(item => item.somador && item.descricao1 !== 'TOTAL' && item.descricao1 !== 'SUBTOTAL' && item.tipo === linhaTotal.tipo).length !== 0
        ? lista
          .filter(item => item.somador && item.descricao1 !== 'TOTAL' && item.descricao1 !== 'SUBTOTAL' && item.tipo === linhaTotal.tipo)
          .reduce((acc, atual) => acc += +atual.valorSomado2, 0)
        : linhaTotal.valorSomado2;
    }
    for (const linhaTotal of lista.filter(item => item.descricao1 === 'TOTAL')) {
      linhaTotal.valorSomado1 = lista
        .filter(item => item.somador && item.descricao1 !== 'TOTAL' && item.descricao1 == 'SUBTOTAL').length !== 0
        ? lista
          .filter(item => item.somador && item.descricao1 !== 'TOTAL' && item.descricao1 == 'SUBTOTAL')
          .reduce((acc, atual) => acc += +atual.valorSomado1, 0)
        : linhaTotal.valorSomado1;
      linhaTotal.valorSomado2 = lista
        .filter(item => item.somador && item.descricao1 !== 'TOTAL' && item.descricao1 == 'SUBTOTAL').length !== 0
        ? lista
          .filter(item => item.somador && item.descricao1 !== 'TOTAL' && item.descricao1 == 'SUBTOTAL')
          .reduce((acc, atual) => acc += +atual.valorSomado2, 0)
        : linhaTotal.valorSomado2;
    }
    return lista
  }

}
