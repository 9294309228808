import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { ConvenioLstComponent } from './convenio-lst/convenio-lst.component';
import { ConvenioShwComponent } from './convenio-shw/convenio-shw.component';
import { ConvenioRoutingModule } from './convenio-routing.module';
import { ConvenioFrmComponent } from './convenio-frm/convenio-frm.component';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CardModule } from 'primeng/card';
import { ConvenioComissaoComponent } from './convenio-comissao/convenio-comissao.component';
import { ConvenioHistoricoComponent } from './convenio-historico/convenio-historico.component';
import { ConvenioAditamentoComponent } from './convenio-aditamento/convenio-aditamento.component';
import { ConvenioAditamentoDlgComponent } from './convenio-aditamento-dlg/convenio-aditamento-dlg.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { ConvenioSituacaoComponent } from './convenio-situacao/convenio-situacao.component';
import { ConvenioAlteracaoComponent } from './convenio-alteracao/convenio-alteracao.component';
import { ConvenioReajusteComponent } from './convenio-reajuste/convenio-reajuste.component';
import { ConvenioReajusteDlgComponent } from './convenio-reajuste-dlg/convenio-reajuste-dlg.component';
import { TableModule } from 'primeng/table';
import { ConvenioFichaComponent } from './convenio-ficha/convenio-ficha.component';
import { ConvenioRecursoComponent } from './convenio-recurso/convenio-recurso.component';
import { TabViewModule } from 'primeng/tabview';
import { ConvenioLiquidacaoComponent } from './convenio-liquidacao/convenio-liquidacao.component';
import { ConvenioPagamentoComponent } from './convenio-pagamento/convenio-pagamento.component';
import { ConvenioRecebimentoComponent } from './convenio-recebimento/convenio-recebimento.component';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { ConvenioLicitacaoComponent } from './convenio-licitacao/convenio-licitacao.component';
import { controleSaldoDlgComponent } from './controle-saldo-dlg/controle-saldo-dlg.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FieldsetModule } from 'primeng/fieldset';
import { ConvenioEmpenhoComponent } from './convenio-empenho/convenio-empenho.component';
import { ConvenioSaldoComponent } from './convenio-saldo/convenio-saldo.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConvenioStorageComponent } from './convenio-storage/convenio-storage.component';
import { FileUploadModule } from 'primeng/fileupload';
@NgModule({
  declarations: [
    ConvenioLstComponent,
    ConvenioShwComponent,
    ConvenioFrmComponent,
    ConvenioHistoricoComponent,
    ConvenioAditamentoComponent,
    ConvenioAditamentoDlgComponent,
    ConvenioComissaoComponent,
    ConvenioSituacaoComponent,
    ConvenioAlteracaoComponent,
    ConvenioReajusteComponent,
    ConvenioReajusteDlgComponent,
    ConvenioFichaComponent,
    ConvenioRecursoComponent,
    ConvenioLiquidacaoComponent,
    ConvenioPagamentoComponent,
    ConvenioRecebimentoComponent,
    ConvenioLicitacaoComponent,
    ConvenioSaldoComponent,
    controleSaldoDlgComponent,
    ConvenioEmpenhoComponent,
    ConvenioStorageComponent
  ],
  imports: [
    CommonModule,
    ConvenioRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    ConfirmDialogModule,
    IMaskModule,
    ToastModule,
    FileUploadModule,
    CardModule,
    TableModule,
    TabViewModule,
    DividerModule,
    DropdownModule,
    RadioButtonModule,
    FieldsetModule,
    InputNumberModule
  ],
  providers: [ConfirmationService]
})
export class ConvenioModule { }
