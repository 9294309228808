import { Component, ElementRef, HostListener, Injector, Input, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MaterialService, ProdutoService } from 'almoxarifado-lib';
import {
  BaseResourceFormComponent, EddyAutoComplete, FuncaoService, GlobalService, LoginContabil, Produto, ProdutoLocalizacao, ProdutoUnidade
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { ProdutoLocalizacaoService } from '../service/produto-localizacao.service';
import { EtiquetaLocalizacao } from "./etiqueta-localizacao";

declare var $: any;
@Component({
  selector: 'lib-produto-localizacao-form',
  templateUrl: './produto-localizacao-form.component.html'
})
export class ProdutoLocalizacaoFormComponent extends BaseResourceFormComponent<ProdutoLocalizacao, LoginContabil> {

  /**
   * Declaração de variáveis
   */

  public produtoAutoComplete: EddyAutoComplete<Produto>;

  @ViewChild('sala_') inputField: ElementRef;

  @Input() compraId: number = 0;
  public produto: ProdutoUnidade

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected materialService: MaterialService,
    protected produtoService: ProdutoService,
    protected produtoLocalizacaoService: ProdutoLocalizacaoService
  ) {
    super(new ProdutoLocalizacao(), injector, ProdutoLocalizacao.converteJson, produtoLocalizacaoService);
  }

  @HostListener('window:keydown.control.p', ['$event'])
  public abrirConsultaProduto(event: KeyboardEvent) {
    event.preventDefault();
    $('#dialogProdutoUnBuscaLocalizacoes').modal('show');
  }

  public callbackProdutoUn(prodUn: ProdutoUnidade) {
    this.produto = prodUn.produto;
  };

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

protected async podeAlterar(_entidade: ProdutoLocalizacao): Promise<boolean> {
  return true;
}

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      sala: [null, [Validators.required]],
      corredor: [null, [Validators.required]],
      armario: [null, [Validators.required]],
      prateleira: [null, [Validators.required]],
      pallet: [null, [Validators.required]],
      posicao: [null, [Validators.required]],
      produto: [null],
      estoque: [this.login.setorAlmoxarifado.estoque, [Validators.required]],

    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'produto' };
  }

  protected afterLoad() {
    this.produtoAutoComplete.id = this.entidade.produto ? this.entidade.produto.id : null;
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  public gerarPDF() {
    new EtiquetaLocalizacao().gerarEtiquetas(this.login, this.entidade);
  }

  protected beforeSubmit() { }

  protected afterSubmit(modelo: ProdutoLocalizacao) { }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
  private carregarAutoCompletes() {
    this.produtoAutoComplete = ProdutoService.autoCompleteCodigoCompleto(null, this.produtoService,
      'id', ['codigo', 'nome'], { orgao_id: this.login.orgao.id, orderBy: 'nome' }, { text: ['nome', 'codigo'] }
    );
  }
}
