import { Component, OnInit, OnChanges, Input, ViewChild, ElementRef, SimpleChanges, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Indicador, LoginContabil, GlobalService, MetaGoverno } from 'eddydata-lib';
import { AcaoGovernoService, IndicadorService } from 'administrativo-lib';

@Component({
  selector: 'app-indicador-form',
  templateUrl: './indicador-form.component.html'
})
export class IndicadorFormComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * Declaração de variáveis
   */
  public ptBR: any;
  public entity: Indicador;
  private entityTemp: Indicador;
  private codigo: number;
  public ano1: number;
  public ano2: number;
  public ano3: number;
  public ano4: number;
  protected unsubscribe: Subject<void> = new Subject();
  public listaAcoes: Array<any>;

  @Input() lista: Array<any>;
  @Input() entidade: MetaGoverno;
  @Input() login: LoginContabil;

  @ViewChild('btnAdicionarIndicador') public btnAdicionarIndicador: ElementRef;
  @ViewChildren('btnsEditar') public btnsEditar: QueryList<ElementRef>;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected globalService: GlobalService,
    protected indicadorService: IndicadorService,
    protected acaoGovernoService: AcaoGovernoService
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.ano1 = this.login.ppa.ppaperiodo.ano1;
    this.ano2 = this.login.ppa.ppaperiodo.ano2;
    this.ano3 = this.login.ppa.ppaperiodo.ano3;
    this.ano4 = this.login.ppa.ppaperiodo.ano4;
    this.getUltimoCodigo();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) { }

  // ========================================================================
  //                     MÉTODOS DE CONTROLE DA TABELA
  // ========================================================================

  private async getUltimoCodigo() {
    this.codigo = await this.indicadorService.obterUtilmoCodigo().toPromise();
  }

  private desabilitarEdicao(acao: boolean) {
    this.btnsEditar.forEach((btn) => btn.nativeElement.disabled = acao)
    this.btnAdicionarIndicador.nativeElement.disabled = acao;
  }

  public async adicionarIndicador() {
    this.entity = new Indicador();
    this.entity.meta = this.entidade;
    this.entity.codigo = String(this.codigo++);
    this.entity.lei = this.login.ppa?.lei_numero;
    this.entity.editavel = true;
    this.entity['salvo'] = false;
    if (!this.lista) {
      this.lista = new Array();
    }
    this.lista.unshift(this.entity);
    this.desabilitarEdicao(true)
  }

  public salvarIndicador(item: Indicador) {
    try {
      if (!item.nome) {
        throw new Error('Informe o nome do indicador!');
      }
      if (!item.unidade_medida) {
        throw new Error('Informe a unidade de medida!');
      }
      if (!item.recente) {
        throw new Error('Informe o indíce recente!');
      }
      if (!item.futuro) {
        throw new Error('Informe o indíce futuro!');
      }
      // if (!item.quadri && item.quadri !== 0) {
      //   throw new Error('Informe um valor de quadrimestre!');
      // }
      if (item.quadri < 0 || item.quadri > 3) {
        throw new Error('Informe um valor de quadrimestre entre 0 e 3!');
      }

      item.editavel = false;
      item['salvo'] = true;
      this.desabilitarEdicao(false)
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public editarIndicador(item: Indicador) {

    if (!this.listaAcoes) {
      this.acaoGovernoService
        .filtrar(1, -1, { 'meta.id': this.entidade.id, 'ppa.id': this.login.ppa.id, relations: 'acao' })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaAcoes = res.content;
          this.entityTemp = JSON.parse(JSON.stringify(item));

          this.entity = item;
          this.entity.editavel = true;
          this.entity['salvo'] = true;
          this.desabilitarEdicao(true)
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Filtro', detail: error.error.payload });
          throw error;
        });
    } else {
      this.entityTemp = JSON.parse(JSON.stringify(item));

      this.entity = item;
      this.entity.editavel = true;
      this.entity['salvo'] = true;
      this.desabilitarEdicao(true)
    }

  }

  public cancelarIndicador(item: Indicador, index: number) {
    if (item['salvo'] && item.id === this.entityTemp?.id) {
      item.nome = this.entityTemp.nome;
      item.unidade_medida = this.entityTemp.unidade_medida;
      item.recente = this.entityTemp.recente;
      item.futuro = this.entityTemp.futuro;
      item.meta1 = this.entityTemp.meta1;
      item.meta2 = this.entityTemp.meta2;
      item.meta3 = this.entityTemp.meta3;
      item.meta4 = this.entityTemp.meta4;
      item.lei = this.entityTemp.lei;
      item.quadri = this.entityTemp.quadri;

      this.entity = item;
      this.entity.editavel = false;
    } else {
      this.finalizarRemocao(index);
    }
    this.desabilitarEdicao(false);
  }

  public removerIndicador(item: Indicador, index: number) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja remover item?',
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.removerItem(item, index);
        // if (item.id) {
        //   this.indicadorService
        //     .remover(item.id)
        //     .pipe(takeUntil(this.unsubscribe))
        //     .subscribe(() => {
        //       this.finalizarRemocao(index);
        //       this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
        //     }, error => {
        //       this.messageService.add({ severity: 'error', summary: 'Exclusão', detail: error.error.payload });
        //       throw error;
        //     });
        // } else {
        //   this.finalizarRemocao(index);
        // }
      },
    });
  }

  private removerItem(item: Indicador, index: number) {
    if (item.id) {
      if (this.login.usuario.administrador && this.login.usuario.sistemas_administrador?.includes('contabil')) {
        this.indicadorService
          .remover(item.id)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(() => {
            this.finalizarRemocao(index);
            this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
          }, error => {
            this.messageService.add({ severity: 'error', summary: 'Exclusão', detail: 'Operação não permita, por gentileza verificar com o administrador!' });
            throw error;
          });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Exclusão', detail: 'Operação não permita, por gentileza verificar com o administrador!' });
      }
    } else {
      this.finalizarRemocao(index);
    }
  }

  private finalizarRemocao(index: number) {
    this.codigo--;
    this.lista.splice(index, 1);
  }
}
