import { Injectable, Injector } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SetorResponsavel } from '../../../entidade/almoxarifado/setor-responsavel.model';
import { BaseResourceService } from '../../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class SetorResponsavelService extends BaseResourceService<SetorResponsavel> {

  constructor(
    protected injector: Injector
  ) {
    super(`setor-responsaveis`, injector);
  }

  public obterPorResponsavel(setor: number): Observable<SetorResponsavel[]> {
    return this.http.get<SetorResponsavel>(
      `${this.login.cidade.id}/${this.api}/filtrar?setor.id=${setor}&relations=responsavel`,
      this.httpOptions()).pipe(
        map(res => res['content']),
        catchError(err => this.handleError(err))
      );
  }

}
