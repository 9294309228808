import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, TransferenciaAutorizacao } from 'eddydata-lib';
import { HttpHeaders } from '@angular/common/http';
import { FEBRABANTransferencia } from 'contabil-lib';

@Injectable({
  providedIn: 'root'
})
export class TransferenciaAutorizacaoService extends BaseResourceService<TransferenciaAutorizacao> {

  constructor(
    protected injector: Injector
  ) {
    super('transferencias-autorizacao', injector);
  }

  public ultimaDataTransferencia(exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/ultima-data-transferencia/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public salvarExportado(campo: string, id: number, numeroFebraban: number): Observable<void> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/salvar-exportado/${campo}/${id}/${numeroFebraban}`, {}, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  baixarAutorizacao(resource: TransferenciaAutorizacao): Observable<any> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/baixar-autorizacao`, resource, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public marcarComoImpresso(parametros: any): Observable<any> {
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/marcar-como-impresso`, JSON.stringify(parametros), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

  public buscarPorFebraban(auts: FEBRABANTransferencia[]): Observable<TransferenciaAutorizacao[]> {
    return this.http.post<TransferenciaAutorizacao[]>(`${this.login.cidade.id}/${this.api}/buscar-por-febraban`, { auts }, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err)));
  }

  public obn601(parametros: any): Observable<any> {
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/obn-601`, JSON.stringify(parametros), {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders(this.adicionarCriptografia({
        Authorization: this.login.token,
      }))
    }).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
