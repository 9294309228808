import { DatePipe } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContratoService, DespesaService, FuncaoGovernoService, RecursoService } from 'administrativo-lib';
import {
  Coluna, Contrato, Despesa, EddyAutoComplete, Favorecido, FavorecidoService, FichaExtra,
  FuncaoGoverno, FuncaoService, GlobalService, Login, Recurso, Relatorio, Retencao, RetencaoResto
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { FichaExtraService } from '../../ficha-extra/service/ficha-extra.service';
import { RetencaoRestoService } from '../../liquidacao-resto/service/retencao-resto.service';
import { RetencaoService } from '../service/retencao.service';
import { right } from '@popperjs/core';
import { tsXLXS } from 'ts-xlsx-export';

@Component({
  selector: 'lib-retencao-rpt',
  templateUrl: './retencao-rpt.component.html'
})
export class RetencaoRptComponent implements OnInit, OnDestroy {

  login: Login = new Login();
  funcaoGoverno: FuncaoGoverno;
  subFuncaoGoverno: FuncaoGoverno;
  fichaExtra: FichaExtra;
  despesa: Despesa;
  recurso: Recurso;
  aplicacao: Recurso;
  contrato: Contrato;
  filtroFichaExtra: boolean;
  filtroDespesa: boolean;
  filtroFuncao: boolean;
  filtroSubFuncao: boolean;
  filtroRecurso: boolean;
  filtroAplicacao: boolean;
  filtroContrato: boolean;
  liquidado: boolean = true;
  anulados: boolean = true;

  public filtroFavorecido: boolean;
  public fitlroRecursosVariavel: boolean;
  public filtroDataVencimento: boolean;
  public filtroDataPagamento: boolean;
  public recursosVariavel_inicial: any;
  public recursosVariavel_final: any
  public dataVencimento: Date;
  public favorecido: any;

  public selectedOrdem: string;
  public selectUsuario: boolean;
  public ptBR: any;

  public listaRelatorio: Array<any>;
  public fichaExtraAutoComplete: EddyAutoComplete<FichaExtra>;
  public contratoAutoComplete: EddyAutoComplete<Contrato>;
  public funcaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;
  public subFuncaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public recursosAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public despesaAutoComplete: EddyAutoComplete<Despesa>;
  public relatorio = '1';
  public dataInicial: Date;
  public dataFinal: Date;
  public dataPagamentoInicial: Date;
  public dataPagamentoFinal: Date;
  public selectedOrdenacao: string;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    protected injector: Injector,
    protected messageService: MessageService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected funcaoGovernoService: FuncaoGovernoService,
    protected favorecidoService: FavorecidoService,
    protected recursoService: RecursoService,
    protected despesaService: DespesaService,
    protected contratoService: ContratoService,
    protected fichaExtraService: FichaExtraService,
    private retencaoService: RetencaoService,
    private retencaoRestoService: RetencaoRestoService
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.login = GlobalService.obterSessaoLogin();
    this.selectedOrdem = 'ord1';
    this.selectedOrdenacao = 'ASC';
    this.selectUsuario = false;
    this.dataInicial = new Date(this.login.exercicio.ano, 0, 1);
    this.dataFinal = new Date();
    this.dataPagamentoInicial = new Date(this.login.exercicio.ano, 0, 1);
    this.dataPagamentoFinal = new Date();
    this.dataVencimento = new Date();
    this.listaRelatorio = [];
    this.listaRelatorio.push({ label: 'RETENÇÕES ORÇAMENTÁRIAS', value: 1 });
    this.listaRelatorio.push({ label: 'RETENÇÕES DE RESTOS A PAGAR', value: 2 });
    // this.listaRelatorio.push({ label: 'RETENÇÕES ORÇAMENTÁRIAS EM ABERTO', value: 3 });
    // this.listaRelatorio.push({ label: 'RETENÇÕES DE RESTOS EM ABERTO', value: 4 });
    this.carregarAutoCompletes();
    let interval = setInterval(() => { new GlobalService().calendarMascara(); clearInterval(interval) }, 1000)
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public changeOpcao() {
    setTimeout(() => {
      new GlobalService().calendarMascara();
    }, 100);
  }

  imprimir(
    titulo: string, usuario: string, sobrenome: string, orgao: string,
    brasao: string, orientacao: 'landscape' | 'portrait',
    nomepdf?: string, largura?: string[], lista?: any[]) {
    Relatorio.imprimir(titulo, usuario, sobrenome, orgao, brasao, lista, this.colunas(), orientacao, nomepdf, largura,
      this.relatorio === '1' || this.relatorio === '2' ? ['valor_retido'] : null);
  }

  colunas(): string[] | Coluna[] {
    switch (this.relatorio) {
      case '1':
        return [
          { titulo: 'Data', coluna: 'liquidacao.data_liquidacao' },
          { titulo: 'Empenho Origem', coluna: 'liquidacao.empenho.numero' },
          { titulo: 'Empenho Extra', coluna: 'empenho_extra.numero' },
          { titulo: 'Ficha', coluna: 'ficha.numero' },
          { titulo: 'Descrição', coluna: 'ficha.nome' },
          { titulo: 'Benefíciario', coluna: 'liquidacao.empenho.favorecido.nome' },
          { titulo: 'Valor', coluna: 'valor_retido', decimais: 2, alignment: right }
        ];
      case '3':
        return [
          { titulo: 'Data', coluna: 'liquidacao.data_liquidacao' },
          { titulo: 'Empenho', coluna: 'liquidacao.empenho.numero' },
          { titulo: 'Ficha', coluna: 'ficha.numero' },
          { titulo: 'Descrição', coluna: 'ficha.nome' },
          { titulo: 'Benefíciario', coluna: 'liquidacao.empenho.favorecido.nome' },
          { titulo: 'Valor', coluna: 'valor_retido', decimais: 2, alignment: right }
        ];
      case '2':
        return [
          { titulo: 'Data', coluna: 'liquidacao.data_liquidacao' },
          { titulo: 'Empenho Origem', coluna: 'liquidacao.empenho.numero' },
          { titulo: 'Empenho Extra', coluna: 'empenho_extra.numero' },
          { titulo: 'Ficha', coluna: 'ficha.numero' },
          { titulo: 'Descrição', coluna: 'ficha.nome' },
          { titulo: 'Benefíciario', coluna: 'liquidacao.empenho.favorecido.nome' },
          { titulo: 'Valor', coluna: 'valor_retido', decimais: 2, alignment: right }
        ];
      case '4':
        return [
          { titulo: 'Data', coluna: 'liquidacao.data_liquidacao' },
          { titulo: 'Empenho', coluna: 'liquidacao.empenho.numero' },
          { titulo: 'Ficha', coluna: 'ficha.numero' },
          { titulo: 'Descrição', coluna: 'ficha.nome' },
          { titulo: 'Benefíciario', coluna: 'liquidacao.empenho.favorecido.nome' },
          { titulo: 'Valor', coluna: 'valor_retido', decimais: 2, alignment: right }
        ];
      default:
        return;
    }
  }

  gerarRelatorio() {
    if (!this.dataInicial || !this.dataFinal) {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }

    let titulo = '';
    const parametros = this.parametrosRelatorio();
    if (this.anulados && !this.liquidado) {
      titulo = 'DE ANULAÇÃO';
      parametros['anulado'] = 'true';
    } else if (!this.anulados && this.liquidado) {
      parametros['anulado'] = 'false';
    }

    switch (this.relatorio) {
      case '1':
        this.retencaoService
          .filtrar(1, -1, parametros)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(lista => {
            if (lista.content.length == 0) {
              toastr.info('Sem itens para imprimir');
              return;
            }
            const param = {};
            param['exercicio_id'] = this.login.exercicio.id;
            param['orgao.id'] = this.login.orgao.id;
            let datepipe = new DatePipe('pt');
            this.imprimir(this.filtroDataPagamento ? `DOCUMENTO: LISTAGEM ${titulo} DE RETENÇÕES COM DATA DE PAGAMENTO NO PERÍODO DE ${datepipe.transform(this.dataPagamentoInicial, 'dd/MM/yyyy')} à ${datepipe.transform(this.dataPagamentoFinal, 'dd/MM/yyyy')}` : `DOCUMENTO: LISTAGEM ${titulo} DE RETENÇÕES NO PERÍODO DE ${datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem retenções', ['auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto'], lista.content);
          },
            (error) => this.messageService.add(
              { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
            )
          );
        break;
      case '2':
        this.retencaoRestoService
          .filtrar(1, -1, parametros)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(lista => {
            if (lista.content.length == 0) {
              toastr.info('Sem itens para imprimir');
              return;
            }
            const param = {};
            param['exercicio_id'] = this.login.exercicio.id;
            param['orgao.id'] = this.login.orgao.id;
            let datepipe = new DatePipe('pt');
            this.imprimir(this.filtroDataPagamento ? `DOCUMENTO: LISTAGEM ${titulo} DE RETENÇÕES COM DATA DE PAGAMENTO NO PERÍODO DE ${datepipe.transform(this.dataPagamentoInicial, 'dd/MM/yyyy')} à ${datepipe.transform(this.dataPagamentoFinal, 'dd/MM/yyyy')}` : `DOCUMENTO: LISTAGEM ${titulo} DE RETENÇÕES NO PERÍODO DE ${datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem retencao', ['auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto'], lista.content);
          },
            (error) => this.messageService.add(
              { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
            )
          );
        break;
      case '3':
        this.retencaoService
          .emAberto(this.login.exercicio.id, this.login.orgao.id, {
            data_inicio: this.funcaoService.converteDataSQL(this.dataInicial),
            data_termino: this.funcaoService.converteDataSQL(this.dataFinal),
            orderBy: parametros['orderBy'],
            relations: ['liquidacao.empenho.favorecido', 'liquidacao.empenho.subelemento', 'liquidacao.empenho.ficha.recurso', 'liquidacao.empenho.ficha.aplicacao']
          })
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(lista => {
            if (lista.entities.length == 0) {
              toastr.info('Sem itens para imprimir');
              return;
            }
            const datepipe = new DatePipe('pt');
            const dados = lista.entities.map((item: Retencao, idx: number) => {
              return {
                ...item,
                fonte_recurso: item.liquidacao.empenho.ficha.recurso.codigo + item.liquidacao.empenho.ficha.aplicacao.codigo + ' - ' + item.liquidacao.empenho.ficha.aplicacao.nome,
              };
            });
            this.imprimir(
              `DOCUMENTO: LISTAGEM ${titulo} DE LIQUIDAÇÃO EM ABERTO NO PERÍODO DE ${datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem liquidacoes em aberto', ['auto', 'auto', 'auto', '*', 'auto', 'auto'], dados
            );
          },
            (error) => this.messageService.add(
              { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
            )
          );
        break;
      case '4':
        this.retencaoRestoService
          .emAberto(this.login.exercicio.id, this.login.orgao.id, {
            data_inicio: this.funcaoService.converteDataSQL(this.dataInicial),
            data_termino: this.funcaoService.converteDataSQL(this.dataFinal),
            orderBy: parametros['orderBy'], relations: ['empenho.favorecido']
          })
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(lista => {
            if (lista.entities.length == 0) {
              toastr.info('Sem itens para imprimir');
              return;
            }
            const datepipe = new DatePipe('pt');
            const dados = lista.entities.map((item: RetencaoResto, idx: number) => {
              return {
                ...item,
                fonte_recurso: item.liquidacao.empenho.recurso + item.liquidacao.empenho.aplicacao + ' - ' + item.liquidacao.empenho.aplicacao_nome,
              };
            });
            this.imprimir(
              `DOCUMENTO: LISTAGEM ${titulo} DE RETENÇÃOES DE RESTO EM ABERTO NO PERÍODO DE ${datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem retenções de resto em aberto',  ['auto', 'auto', 'auto', '*', 'auto', 'auto'], dados
            );
          });
        break;
    }

  }

  public async exportarXlxs() {
    if (!this.dataInicial || !this.dataFinal) {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }

    let titulo = '';
    const parametros = this.parametrosRelatorio();
    if (this.anulados && !this.liquidado) {
      titulo = 'DE ANULAÇÃO';
      parametros['anulado'] = 'true';
    } else if (!this.anulados && this.liquidado) {
      parametros['anulado'] = 'false';
    }

    let nome = '';
    const listaItens = new Array();
    switch (this.relatorio) {
      case '1':
        nome = `LISTAGEM ${titulo} DE RETENÇÕES`;
        this.retencaoService
          .filtrar(1, -1, parametros)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(lista => {
            if (lista.content.length == 0) {
              toastr.info('Sem itens para gerar Excel');
              return;
            }

            for (const item of lista.content) {
              const entity = {
                'Data': this.funcaoService.converteDataBR(item.liquidacao.data_liquidacao),
                'Empenho Origem': item.liquidacao.empenho.numero,
                'Empenho Extra': item.empenho_extra.numero,
                'Ficha': item.ficha.numero,
                'Descrição': item.ficha.nome,
                'Benefíciario': item.liquidacao.empenho.favorecido.nome,
                'Valor': this.funcaoService.convertToBrNumber(item.valor_retido, 2)
              };
              listaItens.push(entity);
            }
            tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile(nome);
          },
            (error) => this.messageService.add(
              { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
            )
          );
        break;
      case '2':
        nome = `LISTAGEM ${titulo} DE RETENÇÕES DE RESTO`;
        this.retencaoRestoService
          .filtrar(1, -1, parametros)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(lista => {
            if (lista.content.length == 0) {
              toastr.info('Sem itens para gerar Excel');
              return;
            }

            for (const item of lista.content) {
              const entity = {
                'Data': this.funcaoService.converteDataBR(item.liquidacao.data_liquidacao),
                'Empenho Origem': item.liquidacao.empenho.numero,
                'Empenho Extra': item.empenho_extra.numero,
                'Ficha': item.ficha.numero,
                'Descrição': item.ficha.nome,
                'Benefíciario': item.liquidacao.empenho.favorecido.nome,
                'Valor': item.valor_retido
              };
              listaItens.push(entity);
            }
            tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile(nome);
          },
            (error) => this.messageService.add(
              { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
            )
          );
        break;
      case '3':
        nome = `LISTAGEM ${titulo} DE RETENÇÕES EM ABERTO`;
        this.retencaoService
          .emAberto(this.login.exercicio.id, this.login.orgao.id, {
            data_inicio: this.funcaoService.converteDataSQL(this.dataInicial),
            data_termino: this.funcaoService.converteDataSQL(this.dataFinal),
            orderBy: parametros['orderBy'],
            relations: ['liquidacao.empenho.favorecido', 'liquidacao.empenho.subelemento', 'liquidacao.empenho.ficha.recurso', 'liquidacao.empenho.ficha.aplicacao']
          })
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(lista => {
            if (lista.entities.length == 0) {
              toastr.info('Sem itens para gerar Excel');
              return;
            }
            const dados = lista.entities.map((item: Retencao, idx: number) => {
              return {
                ...item,
                fonte_recurso: item.liquidacao.empenho.ficha.recurso.codigo + item.liquidacao.empenho.ficha.aplicacao.codigo + ' - ' + item.liquidacao.empenho.ficha.aplicacao.nome,
              };
            });
            for (const item of dados) {
              const entity = {
                'Data': this.funcaoService.converteDataBR(item.liquidacao.data_liquidacao),
                'Empenho': item.liquidacao.empenho.numero,
                'Ficha': item.ficha.numero,
                'Descrição': item.ficha.nome,
                'Benefíciario': item.liquidacao.empenho.favorecido.nome,
                'Valor': this.funcaoService.convertToBrNumber(item.valor_retido, 2)
              };
              listaItens.push(entity);
            }
            tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile(nome);
          },
            (error) => this.messageService.add(
              { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
            )
          );
        break;
      case '4':
        nome = `LISTAGEM ${titulo} DE RETENÇÕES DE RESTO EM ABERTO`;
        this.retencaoRestoService
          .emAberto(this.login.exercicio.id, this.login.orgao.id, {
            data_inicio: this.funcaoService.converteDataSQL(this.dataInicial),
            data_termino: this.funcaoService.converteDataSQL(this.dataFinal),
            orderBy: parametros['orderBy'], relations: ['empenho.favorecido']
          })
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(lista => {
            if (lista.entities.length == 0) {
              toastr.info('Sem itens para gerar Excel');
              return;
            }
            const dados = lista.entities.map((item: RetencaoResto, idx: number) => {
              return {
                ...item,
                fonte_recurso: item.liquidacao.empenho.recurso + item.liquidacao.empenho.aplicacao + ' - ' + item.liquidacao.empenho.aplicacao_nome,
              };
            });

            for (const item of dados) {
              const entity = {
                'Data': this.funcaoService.converteDataBR(item.liquidacao.data_liquidacao),
                'Empenho': item.liquidacao.empenho.numero,
                'Ficha': item.ficha.numero,
                'Descrição': item.ficha.nome,
                'Benefíciario': item.liquidacao.empenho.favorecido.nome,
                'Valor': item.valor_retido
              };
              listaItens.push(entity);
            }
            tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile(nome);
          });
        break;
    }
  }

  private parametrosRelatorio() {
    const parametros = {};

    parametros['liquidacao.exercicio_id'] = this.login.exercicio.id;
    parametros['liquidacao.orgao_id'] = this.login.orgao.id;
    if (!this.filtroDataPagamento) {
      parametros['liquidacao.data_liquidacao$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
      parametros['liquidacao.data_liquidacao$le'] = this.funcaoService.converteDataSQL(this.dataFinal);
    }

    if (this.filtroContrato) {
      parametros['liquidacao.empenho.contrato.numero'] = this.contrato.numero;
    }
    if (this.filtroFuncao) {
      parametros[this.relatorio === '1' || this.relatorio === '3' ? 'liquidacao.empenho.ficha.funcao.id' : 'liquidacao.empenho.funcao'] = (this.relatorio === '1' || this.relatorio === '3' ? this.funcaoGoverno.id : this.funcaoGoverno.codigo);
    }
    if (this.filtroSubFuncao) {
      parametros[this.relatorio === '1' || this.relatorio === '3' ? 'liquidacao.empenho.ficha.subfuncao.id' : 'liquidacao.empenho.subfuncao'] = (this.relatorio === '1' || this.relatorio === '3' ? this.subFuncaoGoverno.id : this.subFuncaoGoverno.codigo);
    }
    if (this.filtroDespesa) {
      parametros[this.relatorio === '1' || this.relatorio === '3' ? 'liquidacao.empenho.subelemento.id' : 'liquidacao.empenho.subelemento'] = (this.relatorio === '1' || this.relatorio === '3' ? this.despesa.id : this.despesa.codigo);
    }
    if (this.filtroRecurso) {
      parametros[this.relatorio === '1' || this.relatorio === '3' ? 'liquidacao.empenho.ficha.recurso.id' : 'liquidacao.empenho.recurso'] = (this.relatorio === '1' || this.relatorio === '3' ? this.recurso.id : this.recurso.codigo);
    }
    if (this.filtroAplicacao) {
      parametros[this.relatorio === '1' || this.relatorio === '3' ? 'liquidacao.empenho.ficha.aplicacao.id' : 'liquidacao.empenho.aplicacao'] = (this.relatorio === '1' || this.relatorio === '3' ? this.aplicacao.id : this.aplicacao.codigo);
    }
    if (this.filtroFavorecido) {
      parametros[this.relatorio === '1' || this.relatorio === '3' ? 'liquidacao.empenho.favorecido.id' : 'liquidacao.empenho.favorecido.id'] = (this.relatorio === '1' || this.relatorio === '3' ? this.favorecido.id : this.favorecido.id);
    }
    if (this.filtroFichaExtra) {
      parametros['ficha.id'] = this.fichaExtra.id;
    }
    if (this.fitlroRecursosVariavel) {
      if (this.recursosVariavel_inicial) {
        parametros[this.relatorio === '1' || this.relatorio === '3' ? 'liquidacao.empenho.ficha.aplicacao_variavel$ge' : 'liquidacao.recurso_variavel$ge'] = (this.relatorio === '1' || this.relatorio === '3' ? this.recursosVariavel_inicial : this.recursosVariavel_inicial);
      }
      if (this.recursosVariavel_final) {
        parametros[this.relatorio === '1' || this.relatorio === '3' ? 'liquidacao.empenho.ficha.aplicacao_variavel$le' : 'liquidacao.recurso_variavel$le'] = (this.relatorio === '1' || this.relatorio === '3' ? this.recursosVariavel_final : this.recursosVariavel_final);
      }
    }
    if (this.filtroDataVencimento) {
      parametros[this.relatorio === '1' || this.relatorio === '3' ? 'liquidacao.data_vencimento' : 'liquidacao.data_vencimento'] = (this.relatorio === '1' || this.relatorio === '3' ? this.funcaoService.converteDataSQL(this.dataVencimento) : this.funcaoService.converteDataSQL(this.dataVencimento));
    }
    if (this.filtroDataPagamento) {
      parametros['liquidacao.pagamentos.data_pagamento$ge'] = this.funcaoService.converteDataSQL(this.dataPagamentoInicial);
      parametros['liquidacao.pagamentos.data_pagamento$le'] = this.funcaoService.converteDataSQL(this.dataPagamentoFinal);
    }
    if (this.selectUsuario) {
      parametros['usuario_cadastro.id'] = this.login.usuario.id;
    }
    if (this.selectedOrdem === 'ord1') {
      parametros['orderBy'] = 'liquidacao.data_liquidacao$' + this.selectedOrdenacao;
    } else if (this.selectedOrdem === 'ord2') {
      parametros['orderBy'] = 'liquidacao.empenho.numero$' + this.selectedOrdenacao;
    } else if (this.selectedOrdem === 'ord3') {
      parametros['orderBy'] = 'liquidacao.empenho.favorecido.nome$' + this.selectedOrdenacao;
    }

    // tslint:disable-next-line: max-line-length
    if (this.relatorio === '1') {
      parametros['relations'] = 'ficha,liquidacao.empenho,liquidacao.empenho.favorecido,liquidacao.empenho.subelemento,liquidacao.empenho.ficha,liquidacao.empenho.ficha.funcao,liquidacao.empenho.ficha.subfuncao,liquidacao.empenho.ficha.recurso,liquidacao.empenho.ficha.aplicacao,liquidacao.empenho.convenio,liquidacao.empenho.convenio.aplicacao,liquidacao.empenho.convenio.recurso,liquidacao.empenho.ficha.aplicacao_variavel,liquidacao.pagamentos,empenho_extra';
    } else {
      parametros['relations'] = 'ficha,liquidacao.empenho,liquidacao.empenho.favorecido,liquidacao.empenho.convenio,liquidacao.empenho.convenio.aplicacao,liquidacao.empenho.convenio.recurso,liquidacao.empenho.convenio.aplicacao_variavel,liquidacao.pagamentos,empenho_extra';
    }

    return parametros;
  }

  private carregarAutoCompletes() {
    // autocomplete para funcao governo
    this.funcaoAutoComplete = new EddyAutoComplete(null, this.funcaoGovernoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para subfuncao governo
    this.subFuncaoAutoComplete = new EddyAutoComplete(null, this.funcaoGovernoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para despesa
    this.despesaAutoComplete = new EddyAutoComplete(null, this.despesaService,
      'id', ['codigo', 'nome'], { nivel: 6, exercicio_id: this.login.exercicio.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para favorecido
    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id'], text: ['nome'] }
    );

    // autocomplete para recursos - recurso-aplicacao-variavel
    this.recursosAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id'], text: ['codigo', 'nome'] }
    );

    // autocomplete para recursos
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id'], text: ['codigo', 'nome'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para ficha-extra
    this.fichaExtraAutoComplete = new EddyAutoComplete(null, this.fichaExtraService,
      'id', ['numero', 'nome'], { orgao_id: this.login.orgao.id, exercicio_id: this.login.exercicio.id, orderBy: 'numero,nome' },
      { text: ['nome'], number: ['numero'] }
    );

    // autocomplete para contrato
    this.contratoAutoComplete = new EddyAutoComplete(null, this.contratoService,
      'id', ['numero', 'favorecido.nome'], {
      orgao_id: this.login.orgao.id, relations: 'favorecido',
      orderBy: 'favorecido.nome'
    }, { number: ['numero'], text: ['favorecido.nome'] }
    );
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }
}
