// tslint:disable: variable-name
import { Variacao } from './variacao.model';
import { BaseResourceModel } from '../../models/base-resource.model';

export class VariacaoItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public tipo?: 'entidade' | 'data' | 'texto' | 'parcela',
    public titulo?: string,
    public entidade?: string,
    public valor?: string,
    public variacao?: Variacao,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public auto_complete?: any
  ) {
    super();
  }

  static converteJson(json: any): VariacaoItem {
    return Object.assign(new VariacaoItem(), json);
  }
}
