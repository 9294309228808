import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EmpenhoService } from 'administrativo-lib';
import { Audesp4LiquidacaoDocSimilarSim, Empenho, Execucao, FuncaoService, Liquidacao } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-liquidacao',
  templateUrl: './licitacao-audesp-liquidacao.component.html'
})
export class LicitacaoAudespLiquidacaoComponent extends BaseLicitacaoAudesp implements OnChanges {

  @Input() public execucao: Execucao;

  public itemAtual: Audesp4LiquidacaoDocSimilarSim;
  public itemAnterior: Audesp4LiquidacaoDocSimilarSim;

  public empenho: Empenho;

  constructor(
    public funcaoService: FuncaoService,
    public empenhoService: EmpenhoService,
  ) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.execucao.empenhoId) {
      this.carregarLiquidacoes();
    }
  }

  public adicionar() {
    if (!this.execucao.LiquidacaoDocSimilarSim || this.execucao.LiquidacaoDocSimilarSim.length === 0) this.execucao.LiquidacaoDocSimilarSim = [];

    if (!this.execucao.empenhoId) {
      toastr.warning('Nenhum empenho selecionado para esta execução')
      return;
    }
    if (!this.empenho || this.empenho.liquidacoes.length === 0) {
      this.carregarLiquidacoes();
      if (!this.empenho || this.empenho.liquidacoes.length === 0) {
        return;
      }
    }

    this.itemAtual = {
      NotaEmpenhoNumero: String(this.empenho.numero), NotaEmpenhoDataEmissao: this.funcaoService.converteDataBR(this.empenho.data_empenho),
      LiquidacaoEmpenhoDt: undefined, LiquidacaoEmpenhoValor: undefined, editavel: true
    }
    this.execucao.LiquidacaoDocSimilarSim.push(this.itemAtual);
  }

  public visualizar(item: Audesp4LiquidacaoDocSimilarSim) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      let itm = this.execucao.LiquidacaoDocSimilarSim.find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.execucao.LiquidacaoDocSimilarSim.splice(this.execucao.LiquidacaoDocSimilarSim.indexOf(this.itemAtual), 1, itm)
      this.itemAtual.editavel = false;
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4LiquidacaoDocSimilarSim) {
    this.itemAtual = undefined;
    this.execucao.LiquidacaoDocSimilarSim.splice(this.execucao.LiquidacaoDocSimilarSim.indexOf(item), 1)
  }

  public salvar() {
    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }

  private carregarLiquidacoes() {
    this.empenhoService.obter({ id: this.execucao.empenhoId, relations: 'liquidacoes' }).subscribe(res => {
      this.empenho = res;
      if (this.empenho.liquidacoes.length === 0) {
        toastr.warning('Nenhuma liquidação foi encontrada para este empenho')
        return;
      }
    })
  }

  public atribuirLiquidacao() {
    if (this.itemAtual.liquidacaoId) {
      const liquidacao: Liquidacao = this.empenho.liquidacoes.find(c => c.id === this.itemAtual.liquidacaoId)
      if (!liquidacao) return;

      this.itemAtual.LiquidacaoEmpenhoDt = this.funcaoService.converteDataBR(liquidacao.data_liquidacao)
      this.itemAtual.LiquidacaoEmpenhoValor = liquidacao.valor_liquidado
    }
  }
}
