export class DocumentoFiscalArray {
    CodigoAjuste: number;
    DocFiscal: DocumentoFiscal[];
}

export class DocumentoFiscal {
    MedicaoNumero: number;
    NotaEmpenhoNumero: string;
    NotaEmpenhoDataEmissao: string;
    DocumentoFiscalNumero: string;
    DocumentoFiscalUF: string;
    DocumentoFiscalValor: number;
    DocumentoFiscalDataEmissao: string;
    editavel?: boolean;
    liquidacaoId?: number;
}

export class ArrayDocumentoFiscal {
    ArrayDocumentoFiscal: {
        CodigoAjuste: number;
        DocFiscal: DocumentoFiscal;
    }[]
}