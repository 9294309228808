import { Component, Injector } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BaseResourceFormComponent } from '../../models/base-resource-form';
import { Orgao } from '../../entidade/comum/orgao.model';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { GlobalService } from '../../util/global.service';
import { FuncaoService } from '../../util/funcao.service';
import { Auditoria } from '../../entidade/comum/auditoria.model';
import { AuditoriaService } from '../service/auditoria.service';
import { TreeNode } from 'pdf-lib/cjs/core/structures/PDFPageTree';

@Component({
  selector: 'lib-auditoria-view',
  templateUrl: './auditoria-view.component.html'
})
export class AuditoriaViewComponent extends BaseResourceFormComponent<Auditoria, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  arvore_alteracoes: TreeNode[] = [];
  selectGalho: any;


  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private messageService: MessageService,
    protected injector: Injector,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected auditoriaServico: AuditoriaService) {
    super(new Orgao(), injector, Orgao.converteJson, auditoriaServico);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      alvo: [null],
      data_cadastro: [null],
      usuario: [null],
      operacao: [null],
      antes: [null],
      depois: [null]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'usuario' };
  }

  protected afterLoad() {

    let arvore;

    if (this.entidade.antes)
      arvore = this.gerarArvore(this.entidade.antes, this.arvore_alteracoes, true);
    if (this.entidade.depois)
      arvore = this.gerarArvore(this.entidade.depois, this.arvore_alteracoes, false);

    this.selectGalho = <any>{
      label: this.entidade.alvo,
      data: {
        antes: this.entidade.antes,
      },
      expandedIcon: 'fa fa-sitemap',
      collapsedIcon: 'fa fa-sitemap',
      children: arvore,
      expanded: true
    };
    this.arvore_alteracoes = <any>[
      this.selectGalho
    ];
  }


  gerarArvore(estrutura: any, arvore: any[], antes?: boolean, corCallback?: Function): any[] {
    for (let [key, value] of Object.entries(estrutura)) {
      let galho;
      let valid = arvore.find((t) => t.label === key);
      if (valid)
        galho = valid;
      else
        galho = {
          label: key,
          data: { antes: null, depois: null },
          expandedIcon: 'fa fa-database',
          collapsedIcon: 'fa fa-database',
          children: []
        };
      if (antes)
        galho.data.antes = value;
      else
        galho.data.depois = value;
      if (value && typeof value === 'object') {
        galho.collapsedIcon = 'fa fa-sitemap';
        galho.expandedIcon = 'fa fa-sitemap';
        galho.children = this.gerarArvore(value, galho.children, antes, () => {
          galho.styleClass = 'text-danger'
        });
      }
      if (!valid)
        arvore.push(galho);
    }
    return arvore;
  }

  protected afterInit(): void {
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: Orgao): void {
  }

  fileChangeEvent(event: any): void {
  }

  nodeSelect(tree) {
    this.selectGalho = tree;
    this.nodeSelectEvent(event);
  }

  nodeSelectEvent(event?) {
    this.selectGalho.expanded = true;
    window.scroll(0, 0);
  }

  private expandRecursive(node: any, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }
}
