import { Component } from '@angular/core';
import { BaseResourceItemsComponent, EddyAutoComplete, MetaGovernoOds, MetaOds } from 'eddydata-lib';
import { MetaGovernoOdsService } from '../service/meta-governo-ods.service';
import { MetaOdsService } from '../service/meta-ods.service';
import * as toastr from 'toastr';

@Component({
  selector: 'app-meta-governo-ods',
  templateUrl: './meta-governo-ods.component.html'
})
export class MetaGovernoOdsComponent extends BaseResourceItemsComponent<MetaGovernoOds>{

  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  public metaOdsAutoComplete: EddyAutoComplete<MetaOds>;


  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================

  constructor(private metaOdsServce: MetaOdsService,
    private metaGovernoOdsService: MetaGovernoOdsService) {
    super(new MetaGovernoOds(), metaGovernoOdsService);
  }

  protected afterInit(): void {
    this.carregarAutoComplete();
  }

  protected validSave(item: MetaGovernoOds): boolean {
    if (!item?.meta_ods?.id) {
      toastr.warning(`Não foi informado a Ods!`);
      return false;
    }
    return true;
  }

  protected beforeSave(item: MetaGovernoOds): void { }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================


  private carregarAutoComplete() {
    this.metaOdsAutoComplete = new EddyAutoComplete(null, this.metaOdsServce, 'id', ['codigo', 'descricao'], { 'ods.cidade.id': this.login.cidade.id },
      { number: ['codigo'], text: ['descricao', 'ods.descricao'] });
  }
}