import { Component, OnInit, Injector, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Sistemas } from '../components/types';
import { LoginService } from '../login/servico/login.service';

@Component({
  selector: 'lib-login-cidadao',
  templateUrl: './login-cidadao.component.html',
  styleUrls: ['./login-cidadao.component.css']
})
export class LoginCidadaoComponent implements OnInit {

  protected router: Router;

  @Input() sistema: Sistemas;

  public msgs: string;
  public email: string;
  public senha: string;
  public maskConfig = {
    mask: [
      {
        mask: '000.000.000-00'
      },
      {
        mask: /^\S*@?\S*$/
      }
    ]
  };

  constructor(
    protected injector: Injector,
    private authService: LoginService) {
    this.router = this.injector.get(Router);
  }

  ngOnInit() {
    this.authService.mostrarMenuEmitter.emit(false);
  }

  async fazerLogin() {
    const resultado = await this.authService.autenticar(this.email, this.senha, this.sistema);
    if (resultado && !resultado.entidade) {
      this.msgs = resultado.error ? resultado.error.message : resultado.message;
    }
  }

  recuperarSenha() {
    this.authService.mostrarMenuEmitter.emit(false);
    this.router.navigate([`${this.sistema}/recuperar-senha`]);
  }

  registroUsuario() {
    this.authService.mostrarMenuEmitter.emit(false);
    this.router.navigate([`${this.sistema}/registro`]);
  }

}
