import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Login } from '../../entidade/login/login';
import { GlobalService } from '../../util/global.service';
import { Orgao } from '../../entidade/comum/orgao.model';
import { UsuarioService } from '../../usuario/service/usuario.service';
import { Usuario } from '../../entidade/comum/usuario.model';
import { LoginService } from '../../login/servico/login.service';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-trocar-orgao',
  templateUrl: './trocar-orgao.component.html'
})
export class TrocarOrgaoComponent implements OnInit, OnDestroy {

  public login: Login = new Login();
  public orgao: Orgao = new Orgao();
  public listaOrgaos: Array<any>;
  public senha: string
  public email: string
  public sistema: any
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected usuarioServico: UsuarioService,
    private authService: LoginService
  ) { }

  public ngOnInit() {
    this.login = GlobalService.obterSessaoLogin();
    if (this.login) {
      this.orgao = this.login.orgao
      this.email = this.login.usuario.email
      this.sistema = this.login.sistema

      this.usuarioServico.filtrar(1, -1, { email: this.login.usuario.email, relations: 'orgao' }).pipe(takeUntil(this.unsubscribe))
        .subscribe(dados => {
          const usuario: Usuario[] = dados.content
          this.listaOrgaos = usuario.map(u => u.orgao)

          // if (this.listaOrgaos.length > 1) {
          //   $('#dialogTrocarOrgao').modal('show')
          // }
        })
    }
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async enviar() {
    if (this.login.orgao.id !== this.orgao.id) {
      const resultado = await this.authService.autenticar(this.email, this.senha, this.sistema, null, this.orgao.id)
      if (resultado && !resultado.entidade) {
        toastr.error(resultado.error ? resultado.error.message : resultado.message)
      } else {
        reloadSession()
      }
    }
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

}

export const reloadSession = () => {
  sessionStorage.setItem('listaco4', '');
  sessionStorage.setItem('listaco5', '');
  sessionStorage.setItem('lista1', '');
  sessionStorage.setItem('listaco3', '');
  sessionStorage.setItem('listaco2', '');
  sessionStorage.setItem('totalArrecadadoco', '');
  sessionStorage.setItem('totalEmpenhadoco', '');
  sessionStorage.setItem('totalLiquidadoco', '');
  sessionStorage.setItem('totalPagoco', '');
  location.reload();
}
