import { Component, Injector } from '@angular/core';
import {
  BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, Fracionamento, LoginContabil, Produto
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FracionamentoService } from '../service/fracionamento.service';

@Component({
  selector: 'app-fracionamento-list',
  templateUrl: './fracionamento-list.component.html'
})
export class FracionamentoListComponent extends BaseResourceListComponent<Fracionamento, LoginContabil> {

  /**
   * Declaração de variáveis
   */

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private fracionamentoService: FracionamentoService) {
    super(fracionamentoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'fracionar,fracionar.produto,fracionado,fracionado.produto,usuario';
  }

  protected condicoesGrid(): {} {
    return {
      'orgao.id': this.login.orgao.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['id$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['fracionar.produto.codigo', 'fracionado.produto.codigo', 'qtd_fracionar', 'qtd_fracionado'],
      text: ['fracionar.produto.nome', 'fracionado.produto.nome', 'usuario.nome'],
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: Produto): Observable<Produto> {
    return null;
  }

  protected podeAlterar(_entidade: Fracionamento): boolean {
    return true;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'id' },
      { titulo: 'Data', coluna: 'data_cadastro' },
      { titulo: 'Item a fracionar', coluna: 'fracionar.produto.nome' },
      { titulo: 'Qtd. a fracionar', coluna: 'qtd_fracionar', decimais: 5, alignment: 'center' },
      { titulo: 'Item fracionado', coluna: 'fracionado.produto.nome' },
      { titulo: 'Qtd. fracionado', coluna: 'qtd_fracionado', decimais: 5, alignment: 'center' },
      { titulo: 'Usuário', coluna: 'usuario.nome' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = this.relations();
    this.fracionamentoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE FRACIONAMENTOS`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem fracionamentos', ['auto', 'auto', '*', 'auto', '*', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

}
