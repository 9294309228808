import { DatePipe } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContratoService, DespesaService, FuncaoGovernoService, LiquidacaoService, RecursoService } from 'administrativo-lib';
import {
  Coluna,
  Contrato, Despesa, EddyAutoComplete, Favorecido, FavorecidoService, FichaDespesa, FuncaoGoverno, FuncaoService, GlobalService, Liquidacao, LiquidacaoResto, Login,
  Recurso, Relatorio
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FichaDespesaService, LiquidacaoRestoService } from 'contabil-lib';
import * as toastr from 'toastr';
import { tsXLXS } from 'ts-xlsx-export';
import { LiquidacaoFornecedorRelatorio } from './relatorio/liquidacaoFornecedorRelatorio';

@Component({
  selector: 'lib-liquidacao-rpt',
  templateUrl: './liquidacao-rpt.component.html'
})
export class LiquidacaoRptComponent implements OnInit, OnDestroy {

  login: Login = new Login();
  funcaoGoverno: FuncaoGoverno;
  subFuncaoGoverno: FuncaoGoverno;
  despesa: Despesa;
  recurso: Recurso;
  aplicacao: Recurso;
  contrato: Contrato;
  ficha: FichaDespesa;
  filtroDespesa: boolean;
  filtroFuncao: boolean;
  filtroSubFuncao: boolean;
  filtroRecurso: boolean;
  filtroAplicacao: boolean;
  filtroContrato: boolean;
  filtroFicha: boolean;
  liquidado: boolean = true;
  anulados: boolean = true;

  public filtroFavorecido: boolean;
  public fitlroRecursosVariavel: boolean;
  public filtroDataVencimento: boolean;
  public filtroNumeroEmpenho: boolean;
  public recursosVariavel_inicial: any;
  public recursosVariavel_final: any
  public dataVencimento: Date;
  public favorecido: any;
  public fichaDespesa: FichaDespesa
  public numeroInicial: number
  public numeroFinal: number
  public agruparRecurso: boolean
  public ordenarRelatorio5: "codigo" | "nome" | "cnpj" = 'codigo'
  public filtroEmpenho: boolean = false;
  public liquidacao: any;
  public liquidacaoResto: any;
  public selectedOrdem: string;
  public selectUsuario: boolean;
  public ptBR: any;
  public listaRelatorio: Array<any>;
  public contratoAutoComplete: EddyAutoComplete<Contrato>;
  public funcaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;
  public subFuncaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public recursosAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public despesaAutoComplete: EddyAutoComplete<Despesa>;
  public fichaDespesaAutoComplete: EddyAutoComplete<FichaDespesa>
  public liquidacaoAutoComplete: EddyAutoComplete<Recurso>;
  public liquidacaoRestoAutoComplete: EddyAutoComplete<Recurso>;
  public relatorio = '1';
  public dataInicial: Date;
  public dataFinal: Date;
  public selectedOrdenacao: string;
  public selectedAgrupamento: string;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    protected injector: Injector,
    protected messageService: MessageService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected funcaoGovernoService: FuncaoGovernoService,
    protected favorecidoService: FavorecidoService,
    protected recursoService: RecursoService,
    protected despesaService: DespesaService,
    protected fichaDespesaService: FichaDespesaService,
    protected contratoService: ContratoService,
    private liquidacaoService: LiquidacaoService,
    private liquidacaoRestoService: LiquidacaoRestoService
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.login = GlobalService.obterSessaoLogin();
    this.selectedOrdem = 'ord1';
    this.selectedOrdenacao = 'ASC';
    this.selectedAgrupamento = '1';
    this.selectUsuario = false;
    this.dataInicial = new Date(this.login.exercicio.ano, 0, 1);
    this.dataFinal = new Date();
    this.dataVencimento = new Date();
    this.listaRelatorio = [];
    this.listaRelatorio.push({ label: 'LIQUIDAÇÕES ORÇAMENTÁRIAS', value: 1 });
    this.listaRelatorio.push({ label: 'LIQUIDAÇÕES DE RESTOS', value: 2 });
    this.listaRelatorio.push({ label: 'LIQUIDAÇÕES ORÇAMENTÁRIAS EM ABERTO', value: 3 });
    this.listaRelatorio.push({ label: 'LIQUIDAÇÕES DE RESTOS EM ABERTO', value: 4 });
    this.listaRelatorio.push({ label: 'LIQUIDAÇÕES POR FORNECEDOR', value: 5 });
    this.carregarAutoCompletes();
    let interval = setInterval(() => { new GlobalService().calendarMascara(); clearInterval(interval) }, 1000)
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  imprimir(
    titulo: string, usuario: string, sobrenome: string, orgao: string,
    brasao: string, orientacao: 'landscape' | 'portrait',
    nomepdf?: string, largura?: string[], lista?: any[]) {
    let totalizadores: string[] = [];

    if (this.relatorio === '1' || this.relatorio === '2') totalizadores = ['valor_liquidado'];
    else if (this.relatorio === '3' && this.selectedAgrupamento === '2') totalizadores = ['total_liquidado', 'total_pago', 'saldo'];
    else totalizadores = ['saldo'];

    Relatorio.imprimir(titulo, usuario, sobrenome, orgao, brasao, lista, this.colunas(), orientacao, nomepdf, largura,
      totalizadores);
  }

  colunas(): string[] | Coluna[] {
    switch (this.relatorio) {
      case '1':
        return [
          { titulo: 'Data', coluna: 'data_liquidacao' },
          { titulo: 'Empenho', coluna: 'empenho.numero' },
          { titulo: 'Parcela', coluna: 'parcela', alignment: 'center' },
          { titulo: 'Ficha', coluna: 'empenho.ficha.numero' },
          { titulo: 'Benefíciario', coluna: 'empenho.favorecido.nome' },
          { titulo: 'Documento', coluna: 'documento' },
          { titulo: 'Valor', coluna: 'valor_liquidado', decimais: 2, alignment: 'right' }
        ];
      case '2':
        return [
          { titulo: 'Data', coluna: 'data_liquidacao' },
          { titulo: 'Empenho', coluna: 'empenho.numero' },
          { titulo: 'Parcela', coluna: 'parcela', alignment: 'center' },
          { titulo: 'Benefíciario', coluna: 'empenho.favorecido.nome' },
          { titulo: 'Documento', coluna: 'documento' },
          { titulo: 'Valor', coluna: 'valor_liquidado', decimais: 2, alignment: 'right' }
        ];
      case '3':
        if (this.selectedAgrupamento === '2') {
          return [
            { titulo: 'Recurso', coluna: 'aplicacao', agrupar: true, bold: true, totalizar: ['total_liquidado', 'total_pago'] },
            { titulo: 'Data da liquidação', coluna: 'data_liquidacao', alignment: 'left' },
            { titulo: 'Data de vencimento', coluna: 'data_vencimento', alignment: 'left' },
            { titulo: 'Empenho', coluna: 'empenho.numero', alignment: 'left' },
            { titulo: 'Parcela', coluna: 'parcela', alignment: 'center' },
            { titulo: 'Favorecido', coluna: 'empenho.favorecido.nome', alignment: 'left' },
            { titulo: 'Classificação da despesa', coluna: 'empenho.subelemento.codigo', alignment: 'left' },
            { titulo: 'Valor Liquidado', coluna: 'total_liquidado', decimais: 2, alignment: 'right' },
            { titulo: 'Valor Pago', coluna: 'total_pago', decimais: 2, alignment: 'right' },
            { titulo: 'Saldo', coluna: 'saldo', decimais: 2, alignment: 'right' }
          ];
        }
        return [
          { titulo: 'Data da liquidação', coluna: 'data_liquidacao', alignment: 'left' },
          { titulo: 'Data de vencimento', coluna: 'data_vencimento', alignment: 'left' },
          { titulo: 'Empenho', coluna: 'empenho.numero', alignment: 'left' },
          { titulo: 'Parcela', coluna: 'parcela', alignment: 'center' },
          { titulo: 'Favorecido', coluna: 'empenho.favorecido.nome', alignment: 'left' },
          { titulo: 'Classificação da despesa', coluna: 'empenho.subelemento.codigo', alignment: 'left' },
          { titulo: 'Recurso', coluna: 'fonte_recurso', alignment: 'left' },
          { titulo: 'Valor Liquidado', coluna: 'total_liquidado', decimais: 2, alignment: 'right' },
          { titulo: 'Valor Pago', coluna: 'total_pago', decimais: 2, alignment: 'right' },
          { titulo: 'Saldo', coluna: 'saldo', decimais: 2, alignment: 'right' }
        ];
      case '4':
        return [
          { titulo: 'Data da liquidação', coluna: 'a_data_liquidacao', alignment: 'center' },
          { titulo: 'Data de vencimento', coluna: 'a_data_vencimento', alignment: 'center' },
          { titulo: 'Empenho', coluna: 'b_numero', alignment: 'left' },
          { titulo: 'Parcela', coluna: 'a_parcela', alignment: 'center' },
          { titulo: 'Favorecido', coluna: 'c_nome', alignment: 'left' },
          { titulo: 'Classificação da despesa', coluna: 'b_subelemento', alignment: 'left' },
          { titulo: 'Recurso', coluna: 'fonte_recurso', alignment: 'left' },
          { titulo: 'Valor Liquidado', coluna: 'total_liquidado', decimais: 2, alignment: 'right' },
          { titulo: 'Valor Pago', coluna: 'total_pago', decimais: 2, alignment: 'right' },
          { titulo: 'Saldo', coluna: 'saldo', decimais: 2, alignment: 'right' }
        ];
      default:
        return;
    }
  }

  gerarRelatorio() {
    if (!this.dataInicial || !this.dataFinal) {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }

    let titulo = '';
    const parametros = this.parametrosRelatorio();
    if (this.anulados && !this.liquidado) {
      titulo = 'DE ANULAÇÃO';
      parametros['anulacao'] = 'true';
    } else if (!this.anulados && this.liquidado) {
      parametros['anulacao'] = 'false';
    }

    switch (this.relatorio) {
      case '1':
        this.liquidacaoService
          .filtrar(1, -1, parametros)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(lista => {
            if (lista.content.length == 0) {
              toastr.info('Sem itens para imprimir');
              return;
            }
            const param = {};
            param['exercicio_id'] = this.login.exercicio.id;
            param['orgao.id'] = this.login.orgao.id;
            let datepipe = new DatePipe('pt');
            this.imprimir(`DOCUMENTO: LISTAGEM DE LIQUIDAÇÃO ORÇAMENTÁRIA NO PERÍODO DE ${datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Liquidacoes orçamentárias', ['auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto'], lista.content);
          },
            (error) => this.messageService.add(
              { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
            )
          );
        break;
      case '2':
        this.liquidacaoRestoService
          .filtrar(1, -1, parametros)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(lista => {
            if (lista.content.length == 0) {
              toastr.info('Sem itens para imprimir');
              return;
            }
            const param = {};
            param['exercicio_id'] = this.login.exercicio.id;
            param['orgao.id'] = this.login.orgao.id;
            let datepipe = new DatePipe('pt');
            this.imprimir(`DOCUMENTO: LISTAGEM DE LIQUIDAÇÃO DE RESTO NO PERÍODO DE ${datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Liquidacoes de resto', ['auto', 'auto', 'auto', 'auto', '*', 'auto'], lista.content);
          },
            (error) => this.messageService.add(
              { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
            )
          );
        break;
      case '3':
        this.liquidacaoService
          .emAberto(this.login.exercicio.id, this.login.orgao.id, {
            data_inicio: this.funcaoService.converteDataSQL(this.dataInicial),
            data_termino: this.funcaoService.converteDataSQL(this.dataFinal),
            despesa_id: this.filtroDespesa ? String(this.despesa?.id) : null,
            funcaoGoverno_id: this.filtroFuncao ? String(this.funcaoGoverno?.id) : null,
            subFuncaoGoverno_id: this.filtroSubFuncao ? String(this.subFuncaoGoverno?.id) : null,
            recurso_id: this.filtroRecurso ? String(this.recurso?.id) : null,
            aplicacao_id: this.filtroAplicacao ? String(this.aplicacao?.id) : null,
            contrato_id: this.filtroContrato ? String(this.contrato?.id) : null,
            recursosVariavel_inicial: this.fitlroRecursosVariavel ? this.recursosVariavel_inicial : null,
            recursosVariavel_final: this.fitlroRecursosVariavel ? this.recursosVariavel_final : null,
            favorecido_id: this.filtroFavorecido ? this.favorecido?.id : null,
            data_vencimento: this.filtroDataVencimento ? this.funcaoService.converteDataSQL(this.dataVencimento) : null,
            empenho_id: this.filtroNumeroEmpenho ? this.liquidacao?.empenho.id : null,
            ficha_id: this.filtroFicha ? String(this.ficha?.id) : null,
            orderBy: this.selectedAgrupamento === '2' ? `coalesce(av.codigo, concat(re.codigo, ap.codigo, '0000'))$ASC` : '' + parametros['orderBy'],
            relations: ['empenho', 'empenho.favorecido', 'empenho.subelemento', 'empenho.ficha.recurso', 'empenho.ficha.aplicacao']
          })
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(lista => {
            if (!lista || lista.entities.length == 0) {
              toastr.info('Sem itens para imprimir');
              return;
            }
            const datepipe = new DatePipe('pt');
            const dados = lista.entities.map((item: Liquidacao, idx: number) => {
              return {
                ...item,
                fonte_recurso: item.empenho.ficha.recurso.codigo + item.empenho.ficha.aplicacao.codigo + ' - ' + item.empenho.ficha.aplicacao.nome,
                total_liquidado: +lista.raw[idx]['total_liquidado'],
                total_pago: +lista.raw[idx]['total_pago'],
                aplicacao: lista.raw[idx]['codigo_variavel'] + ' - ' + lista.raw[idx]['nome_variavel'],
                saldo: +lista.raw[idx]['total_liquidado'] - +lista.raw[idx]['total_pago']
              }
            });
            let colunas = ['auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto'];
            if (this.selectedAgrupamento === '2') {
              colunas = ['auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'];
            }
            this.imprimir(
              `DOCUMENTO: LISTAGEM DE LIQUIDAÇÃO ORÇAMENTÁRIAS EM ABERTO NO PERÍODO DE ${datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Liquidacoes orçamentárias em aberto', colunas, dados
            );
          },
            (error) => this.messageService.add(
              { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
            )
          );
        break;
      case '4':
        const parametros_ficha = {};
        if(this.filtroFicha){
        parametros_ficha['empenho.programa'] = this.ficha.programa.codigo;
        parametros_ficha['empenho.subelemento$like'] = this.ficha.despesa.codigo.substring(0,6)+'%';
        parametros_ficha['empenho.acao'] = this.ficha.acao.codigo;
        parametros_ficha['empenho.funcao'] = this.ficha.funcao.codigo;
        parametros_ficha['empenho.subfuncao'] = this.ficha.subfuncao.codigo;
        parametros_ficha['empenho.recurso'] = this.ficha.recurso.codigo;
        parametros_ficha['empenho.aplicacao'] = this.ficha.aplicacao.codigo;
        parametros_ficha['empenho.recurso_variavel'] = this.ficha.aplicacao_variavel.codigo;
        }
        this.liquidacaoRestoService
          .emAberto(this.login.exercicio.id, this.login.orgao.id, {
            data_inicio: this.funcaoService.converteDataSQL(this.dataInicial),
            data_termino: this.funcaoService.converteDataSQL(this.dataFinal),
            despesa_id: this.filtroDespesa ? String(this.despesa?.codigo) : null,
            funcaoGoverno_id: this.filtroFuncao ? String(this.funcaoGoverno?.codigo) : null,
            subFuncaoGoverno_id: this.filtroSubFuncao ? String(this.subFuncaoGoverno?.codigo) : null,
            recurso_id: this.filtroRecurso ? String(this.recurso?.codigo) : null,
            aplicacao_id: this.filtroAplicacao ? String(this.aplicacao?.codigo) : null,
            contrato_id: this.filtroContrato ? String(this.contrato?.id) : null,
            recursosVariavel_inicial: this.fitlroRecursosVariavel ? this.recursosVariavel_inicial : null,
            recursosVariavel_final: this.fitlroRecursosVariavel ? this.recursosVariavel_final : null,
            favorecido_id: this.filtroFavorecido ? this.favorecido?.id : null,
            data_vencimento: this.filtroDataVencimento ? this.funcaoService.converteDataSQL(this.dataVencimento) : null,
            empenho_id: this.filtroNumeroEmpenho ? this.liquidacaoResto?.empenho.id : null,
            orderBy: parametros['orderBy'],
            relations: ['empenho.favorecido'],
            programa_ficha: this.filtroFicha ? this.ficha.programa.codigo : null,
            despesa_ficha: this.filtroFicha ? this.ficha.despesa.codigo.substring(0,6)+'%' : null,
            acao_ficha: this.filtroFicha ? this.ficha.acao.codigo : null,
            funcao_ficha: this.filtroFicha ? this.ficha.funcao.codigo : null,
            subfuncao_ficha: this.filtroFicha ? this.ficha.subfuncao.codigo : null,
            recurso_ficha: this.filtroFicha ? this.ficha.recurso.codigo : null,
            aplicacao_ficha: this.filtroFicha ? this.ficha.aplicacao.codigo : null,
            recurso_variavel_ficha: this.filtroFicha ? this.ficha.aplicacao_variavel.codigo : null,
          })
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(lista => {
            if (!lista || lista.length == 0) {
              toastr.info('Sem itens para imprimir');
              return;
            }
            const datepipe = new DatePipe('pt');
            const dados = lista.map((item: LiquidacaoResto, idx: number) => {
              return {
                ...item,
                fonte_recurso: item['b_recurso'] + item['b_aplicacao'] + ' - ' + item['b_aplicacao_nome'],
                total_liquidado: +item['total_liquidado'],
                total_pago: +item['total_pago'],
                saldo: +item['total_liquidado'] - +item['total_pago']
              }
            });
            this.imprimir(
              `DOCUMENTO: LISTAGEM DE LIQUIDAÇÃO DE RESTO EM ABERTO NO PERÍODO DE ${datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Liquidacoes de resto em aberto', ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], dados
            );
          });
        break;
      case '5':
        const relatorio5 = new LiquidacaoFornecedorRelatorio(this.liquidacaoService)
        relatorio5.imprimir({ favorecido: this.favorecido, dtInicial: this.funcaoService.converteDataSQL(this.dataInicial), dtFinal: this.funcaoService.converteDataSQL(this.dataFinal), fichaDespesa: this.fichaDespesa, numeroFinal: this.numeroFinal, numeroInicial: this.numeroInicial, agruparRecurso: this.agruparRecurso, ordenadoPor: this.ordenarRelatorio5 })
        break
    }
  }

  public async exportarXlxs() {
    if (!this.dataInicial || !this.dataFinal) {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }

    let titulo = '';
    const parametros = this.parametrosRelatorio();
    if (this.anulados && !this.liquidado) {
      titulo = 'DE ANULAÇÃO';
      parametros['anulacao'] = 'true';
    } else if (!this.anulados && this.liquidado) {
      parametros['anulacao'] = 'false';
    }

    const listaItens = new Array();
    switch (this.relatorio) {
      case '1':
        titulo = 'LIQUIDAÇÃO ORÇAMENTÁRIA';
        this.liquidacaoService
          .filtrar(1, -1, parametros)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(lista => {
            if (lista.content.length === 0) {
              toastr.info('Sem itens para gerar Excel');
              return;
            }

            for (const item of lista.content) {
              const entity = {
                'Data': this.funcaoService.converteDataBR(item.data_liquidacao),
                'Empenho': item.empenho.numero,
                'Parcela': item.parcela,
                'Ficha': item.empenho.ficha.numero,
                'Benefíciario': item.empenho.favorecido.nome,
                'Documento': item.documento,
                'Valor': this.funcaoService.convertToBrNumber(item.valor_liquidado, 2)
              };
              listaItens.push(entity);
            }
            tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile(titulo);
          },
            (error) => this.messageService.add(
              { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
            )
          );
        break;
      case '2':
        titulo = 'LIQUIDAÇÃO DE RESTO';
        this.liquidacaoRestoService
          .filtrar(1, -1, parametros)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(lista => {
            if (lista.content.length === 0) {
              toastr.info('Sem itens para gerar Excel');
              return;
            }

            for (const item of lista.content) {
              const entity = {
                'Data': this.funcaoService.converteDataBR(item.data_liquidacao),
                'Empenho': item.empenho.numero,
                'Parcela': item.parcela,
                'Benefíciario': item.empenho.favorecido.nome,
                'Documento': item.documento,
                'Valor': this.funcaoService.convertToBrNumber(item.valor_liquidado, 2)
              };
              listaItens.push(entity);
            }
            tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile(titulo);
          },
            (error) => this.messageService.add(
              { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
            )
          );
        break;
      case '3':
        titulo = 'LIQUIDAÇÃO ORÇAMENTÁRIAS EM ABERTO';
        this.liquidacaoService
          .emAberto(this.login.exercicio.id, this.login.orgao.id, {
            data_inicio: this.funcaoService.converteDataSQL(this.dataInicial),
            data_termino: this.funcaoService.converteDataSQL(this.dataFinal),
            despesa_id: this.filtroDespesa ? String(this.despesa?.id) : null,
            funcaoGoverno_id: this.filtroFuncao ? String(this.funcaoGoverno?.id) : null,
            subFuncaoGoverno_id: this.filtroSubFuncao ? String(this.subFuncaoGoverno?.id) : null,
            recurso_id: this.filtroRecurso ? String(this.recurso?.id) : null,
            aplicacao_id: this.filtroAplicacao ? String(this.aplicacao?.id) : null,
            contrato_id: this.filtroContrato ? String(this.contrato?.id) : null,
            recursosVariavel_inicial: this.fitlroRecursosVariavel ? this.recursosVariavel_inicial : null,
            recursosVariavel_final: this.fitlroRecursosVariavel ? this.recursosVariavel_final : null,
            favorecido_id: this.filtroFavorecido ? this.favorecido?.id : null,
            data_vencimento: this.filtroDataVencimento ? this.funcaoService.converteDataSQL(this.dataVencimento) : null,
            empenho_id: this.filtroNumeroEmpenho ? this.liquidacao?.empenho.id : null,
            ficha_id: this.filtroFicha ? String(this.ficha?.id) : null,
            orderBy: parametros['orderBy'],
            relations: ['empenho', 'empenho.favorecido', 'empenho.subelemento', 'empenho.ficha.recurso', 'empenho.ficha.aplicacao']
          })
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(lista => {
            if (!lista || lista.entities.length == 0) {
              toastr.info('Sem itens para gerar Excel');
              return;
            }
            const dados = lista.entities.map((item: Liquidacao, idx: number) => {
              return {
                ...item,
                fonte_recurso: item.empenho.ficha.recurso.codigo + item.empenho.ficha.aplicacao.codigo + ' - ' + item.empenho.ficha.aplicacao.nome,
                total_liquidado: +lista.raw[idx]['total_liquidado'],
                total_pago: +lista.raw[idx]['total_pago'],
                saldo: +lista.raw[idx]['total_liquidado'] - +lista.raw[idx]['total_pago']
              }
            });
            for (const item of dados) {
              const entity = {
                'Data da liquidação': this.funcaoService.converteDataBR(item.data_liquidacao),
                'Data de vencimento': this.funcaoService.converteDataBR(item.data_vencimento),
                'Empenho': item.empenho.numero,
                'Parcela': item.parcela,
                'Favorecido': item.empenho.favorecido.nome,
                'Classificação da despesa': item.empenho.subelemento.codigo,
                'Recurso': item.fonte_recurso,
                'Valor Liquidado': this.funcaoService.convertToBrNumber(item.total_liquidado, 2),
                'Valor Pago': this.funcaoService.convertToBrNumber(item.total_pago, 2),
                'Saldo': this.funcaoService.convertToBrNumber(item.saldo, 2)
              };
              listaItens.push(entity);
            }
            tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile(titulo);
          },
            (error) => this.messageService.add(
              { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
            )
          );
        break;
      case '4':
        titulo = 'LIQUIDAÇÃO DE RESTO EM ABERTO';
        this.liquidacaoRestoService
          .emAberto(this.login.exercicio.id, this.login.orgao.id, {
            data_inicio: this.funcaoService.converteDataSQL(this.dataInicial),
            data_termino: this.funcaoService.converteDataSQL(this.dataFinal),
            despesa_id: this.filtroDespesa ? String(this.despesa?.codigo) : null,
            funcaoGoverno_id: this.filtroFuncao ? String(this.funcaoGoverno?.codigo) : null,
            subFuncaoGoverno_id: this.filtroSubFuncao ? String(this.subFuncaoGoverno?.codigo) : null,
            recurso_id: this.filtroRecurso ? String(this.recurso?.codigo) : null,
            aplicacao_id: this.filtroAplicacao ? String(this.aplicacao?.codigo) : null,
            contrato_id: this.filtroContrato ? String(this.contrato?.id) : null,
            recursosVariavel_inicial: this.fitlroRecursosVariavel ? this.recursosVariavel_inicial : null,
            recursosVariavel_final: this.fitlroRecursosVariavel ? this.recursosVariavel_final : null,
            favorecido_id: this.filtroFavorecido ? this.favorecido?.id : null,
            data_vencimento: this.filtroDataVencimento ? this.funcaoService.converteDataSQL(this.dataVencimento) : null,
            empenho_id: this.filtroNumeroEmpenho ? this.liquidacaoResto?.empenho.id : null,
            orderBy: parametros['orderBy'],
            relations: ['empenho.favorecido'],
            programa_ficha: this.filtroFicha ? this.ficha.programa.codigo : null,
            despesa_ficha: this.filtroFicha ? this.ficha.despesa.codigo.substring(0,6)+'%' : null,
            acao_ficha: this.filtroFicha ? this.ficha.acao.codigo : null,
            funcao_ficha: this.filtroFicha ? this.ficha.funcao.codigo : null,
            subfuncao_ficha: this.filtroFicha ? this.ficha.subfuncao.codigo : null,
            recurso_ficha: this.filtroFicha ? this.ficha.recurso.codigo : null,
            aplicacao_ficha: this.filtroFicha ? this.ficha.aplicacao.codigo : null,
            recurso_variavel_ficha: this.filtroFicha ? this.ficha.aplicacao_variavel.codigo : null,
          })
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(lista => {
            if (!lista || lista.length == 0) {
              toastr.info('Sem itens para gerar Excel');
              return;
            }
            const dados = lista.map((item: LiquidacaoResto, idx: number) => {
              return {
                ...item,
                fonte_recurso: item['b_recurso'] + item['b_aplicacao'] + ' - ' + item['b_aplicacao_nome'],
                total_liquidado: +item['total_liquidado'],
                total_pago: +item['total_pago'],
                saldo: +item['total_liquidado'] - +item['total_pago']
              }
            });
            for (const item of dados) {
              const entity = {
                'Data da liquidação': this.funcaoService.converteDataBR(item.a_data_liquidacao),
                'Data de vencimento': this.funcaoService.converteDataBR(item.a_data_vencimento),
                'Empenho': item.b_numero,
                'Parcela': item.a_parcela,
                'Favorecido': item.c_nome,
                'Classificação da despesa': item.b_subelemento,
                'Recurso': item.fonte_recurso,
                'Valor Liquidado': this.funcaoService.convertToBrNumber(item.total_liquidado, 2),
                'Valor Pago': this.funcaoService.convertToBrNumber(item.total_pago, 2),
                'Saldo': this.funcaoService.convertToBrNumber(item.saldo, 2)
              };
              listaItens.push(entity);
            }
            tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile(titulo);
          });
        break;
    }
  }

  private parametrosRelatorio() {
    const parametros = {};

    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['data_liquidacao$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
    parametros['data_liquidacao$le'] = this.funcaoService.converteDataSQL(this.dataFinal);

    if (this.filtroDespesa) {
      parametros[this.relatorio === '1' || this.relatorio === '3' ? 'empenho.subelemento.id' : 'empenho.subelemento'] = (this.relatorio === '1' || this.relatorio === '3' ? this.despesa.id : this.despesa.codigo);
    }
    if (this.filtroFuncao) {
      parametros[this.relatorio === '1' || this.relatorio === '3' ? 'empenho.ficha.funcao.id' : 'empenho.funcao'] = (this.relatorio === '1' || this.relatorio === '3' ? this.funcaoGoverno.id : this.funcaoGoverno.codigo);
    }
    if (this.filtroSubFuncao) {
      parametros[this.relatorio === '1' || this.relatorio === '3' ? 'empenho.ficha.subfuncao.id' : 'empenho.subfuncao'] = (this.relatorio === '1' || this.relatorio === '3' ? this.subFuncaoGoverno.id : this.subFuncaoGoverno.codigo);
    }
    if (this.filtroRecurso) {
      parametros[this.relatorio === '1' || this.relatorio === '3' ? 'empenho.ficha.recurso.id' : 'empenho.recurso'] = (this.relatorio === '1' || this.relatorio === '3' ? this.recurso.id : this.recurso.codigo);
    }
    if (this.filtroAplicacao) {
      parametros[this.relatorio === '1' || this.relatorio === '3' ? 'empenho.ficha.aplicacao.id' : 'empenho.aplicacao'] = (this.relatorio === '1' || this.relatorio === '3' ? this.aplicacao.id : this.aplicacao.codigo);
    }
    if (this.filtroFicha) {
      if(this.relatorio === '1' || this.relatorio === '3'){
      parametros['empenho.ficha.id'] = this.ficha.id;
      }else{
        parametros['empenho.programa'] = this.ficha.programa.codigo;
        parametros['empenho.subelemento$like'] = this.ficha.despesa.codigo.substring(0,6)+'%';
        parametros['empenho.acao'] = this.ficha.acao.codigo;
        parametros['empenho.funcao'] = this.ficha.funcao.codigo;
        parametros['empenho.subfuncao'] = this.ficha.subfuncao.codigo;
        parametros['empenho.recurso'] = this.ficha.recurso.codigo;
        parametros['empenho.aplicacao'] = this.ficha.aplicacao.codigo;
        parametros['empenho.recurso_variavel'] = this.ficha.aplicacao_variavel.codigo;
      }
    }
    if (this.filtroContrato) {
      parametros['empenho.contrato.numero'] = this.contrato.numero;
    }
    if (this.fitlroRecursosVariavel) {
      if (this.recursosVariavel_inicial) {
        parametros[this.relatorio === '1' || this.relatorio === '3' ? 'empenho.ficha.aplicacao_variavel$ge' : 'empenho.recurso_variavel$ge'] = (this.relatorio === '1' || this.relatorio === '3' ? this.recursosVariavel_inicial : this.recursosVariavel_inicial);
      }
      if (this.recursosVariavel_final) {
        parametros[this.relatorio === '1' || this.relatorio === '3' ? 'empenho.ficha.aplicacao_variavel$le' : 'empenho.recurso_variavel$le'] = (this.relatorio === '1' || this.relatorio === '3' ? this.recursosVariavel_final : this.recursosVariavel_final);
      }
    }
    if (this.filtroFavorecido) {
      parametros[this.relatorio === '1' || this.relatorio === '3' ? 'empenho.favorecido.id' : 'empenho.favorecido.id'] = (this.relatorio === '1' || this.relatorio === '3' ? this.favorecido.id : this.favorecido.id);
    }
    if (this.filtroDataVencimento) {
      parametros[this.relatorio === '1' || this.relatorio === '3' ? 'data_vencimento' : 'a_data_vencimento'] = (this.relatorio === '1' || this.relatorio === '3' ? this.funcaoService.converteDataSQL(this.dataVencimento) : this.funcaoService.converteDataSQL(this.dataVencimento));
    }
    if (this.filtroNumeroEmpenho) {
      parametros[this.relatorio === '1' || this.relatorio === '3' ? 'empenho.numero' : 'empenho.numero'] = (this.relatorio === '1' || this.relatorio === '3' ? this.liquidacao.empenho.numero : this.liquidacaoResto.empenho.numero);
    }
    if (this.selectUsuario) {
      parametros['usuario_cadastro.id'] = this.login.usuario.id;
    }
    if (this.selectedOrdem === 'ord1') {
      parametros['orderBy'] = 'data_liquidacao$' + this.selectedOrdenacao;
    } else if (this.selectedOrdem === 'ord2') {
      parametros['orderBy'] = 'empenho.numero$' + this.selectedOrdenacao;
    } else if (this.selectedOrdem === 'ord3') {
      parametros['orderBy'] = 'empenho.favorecido.nome$' + this.selectedOrdenacao;
    } else {
      parametros['orderBy'] = 'data_cadastro$' + this.selectedOrdenacao;
    }

    if (this.selectedAgrupamento === '2') {
      // parametros['orderBy'] = `coalesce(av.codigo, concat(re.codigo, ap.codigo, '0000'),` + parametros['orderBy'];
    }

    // tslint:disable-next-line: max-line-length
    if (this.relatorio === '1') {
      parametros['relations'] = 'empenho,empenho.favorecido,empenho.subelemento,empenho.ficha,empenho.ficha.funcao,empenho.ficha.subfuncao,empenho.ficha.recurso,empenho.ficha.aplicacao,empenho.convenio,empenho.convenio.aplicacao,empenho.convenio.recurso,empenho.ficha.aplicacao_variavel';
    } else {
      parametros['relations'] = 'empenho,empenho.favorecido,empenho.convenio,empenho.convenio.aplicacao,empenho.convenio.recurso,empenho.convenio.aplicacao_variavel';
    }

    return parametros;
  }

  private carregarAutoCompletes() {
    // autocomplete para funcao governo
    this.funcaoAutoComplete = new EddyAutoComplete(null, this.funcaoGovernoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para subfuncao governo
    this.subFuncaoAutoComplete = new EddyAutoComplete(null, this.funcaoGovernoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para despesa
    this.despesaAutoComplete = new EddyAutoComplete(null, this.despesaService,
      'id', ['codigo', 'nome'], { nivel: 6, exercicio_id: this.login.exercicio.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para ficha de despesa
    this.fichaDespesaAutoComplete = new EddyAutoComplete(null, this.fichaDespesaService, 'id', ['numero', 'despesa.nome'], { ativo: true, relations: 'despesa,programa,acao,funcao,subfuncao,recurso,aplicacao,aplicacao_variavel,exercicio', orgao_id: this.login.orgao.id,'ignoreCondObrig' : true }, { number: ['numero'], text: ['despesa.nome'] })

    // autocomplete para favorecido
    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['id', 'nome', 'cpf_cnpj'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id'], text: ['nome', 'cpf_cnpj'] }
    );

    // autocomplete para recursos - recurso-aplicacao-variavel
    this.recursosAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id'], text: ['codigo', 'nome'] }
    );

    // autocomplete para recursos
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id'], text: ['codigo', 'nome'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para empenho
    this.liquidacaoAutoComplete = new EddyAutoComplete(null, this.liquidacaoService,
      'id', ['empenho.numero', 'exercicio.ano'], { exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id, relations: 'exercicio,orgao,empenho', }, { number: ['empenho.numero'] }
    );

    // autocomplete para empenho resto
    this.liquidacaoRestoAutoComplete = new EddyAutoComplete(null, this.liquidacaoRestoService,
      'id', ['empenho.numero', 'exercicio.ano'], { exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id, relations: 'exercicio,orgao,empenho', }, { number: ['empenho.numero'] }
    );

    // autocomplete para contrato
    this.contratoAutoComplete = new EddyAutoComplete(null, this.contratoService,
      'id', ['numero', 'favorecido.nome'], {
      orgao_id: this.login.orgao.id, relations: 'favorecido',
      orderBy: 'favorecido.nome'
    }, { number: ['numero'], text: ['favorecido.nome'] }
    );
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  verEmpenho(relat: string | any) {
    relat === '1' || relat === '3' ? this.filtroEmpenho = true : this.filtroEmpenho = false;
  }
}
