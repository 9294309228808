import { BaseResourceModel } from "../../models/base-resource.model";
import { ComissaoMembro } from "./comissao-membro.model";
import { Licitacao } from "./licitacao.model";

export class LicitacaoComissaoMembro extends BaseResourceModel {
    constructor(
        public id?: number,
        public licitacao?: Licitacao,
        public comissao_membro?: ComissaoMembro,
        public marcado?: boolean,
    ) {
        super();
    }

    static converteJson(json: any): LicitacaoComissaoMembro {
        return Object.assign(new LicitacaoComissaoMembro(), json);
    }
}
