import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, TipoContratacao } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TipoContratacaoService extends BaseResourceService<TipoContratacao> {

  constructor(
    protected injector: Injector
  ) {
    super(`tipos-contratacao`, injector);
  }

  public validarDespasa(tipo_contratacao: number, despesa: number): Observable<{ valido: boolean }> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/validar-despesa/${tipo_contratacao}/${despesa}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
