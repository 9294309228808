// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from './usuario.model';
import { Orgao } from './orgao.model';
import { Sistemas } from '../../components/types';

export class RelatorioPersonalizado extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public descricao?: string,
    public campos?: string,
    public agrupamentos?: string,
    public totalizadores?: string,
    public url?: string,
    public administrador?: boolean,
    public sistema?: Sistemas,
    public modulo?: string,
    public orientacao?: 'portrait' | 'landscape',
    public usuario?: Usuario,
    public orgao?: Orgao,
    public data_cadastro?: Date,
    public data_alteracao?: Date
  ) {
    super();
  }

  static converteJson(json: any): RelatorioPersonalizado {
    return Object.assign(new RelatorioPersonalizado(), json);
  }

}
