import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReceitaNivelComponent } from './receita-nivel.component';

const routes: Routes = [
  {
    path: ':ano/:sistema',
    component: ReceitaNivelComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReceitaNivelRoutingModule { }

