import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToastModule } from 'primeng/toast';
import { IndiceCorrecaoRoutingModule } from './grupo-estoque-routing.module';
import { IndiceCorrecaoFormComponent } from './indice-correcao-form/indice-correcao-form.component';
import { IndiceCorrecaoListComponent } from './indice-correcao-list/indice-correcao-list.component';

@NgModule({
  declarations: [IndiceCorrecaoListComponent, IndiceCorrecaoFormComponent],
  imports: [
    CommonModule,
    IndiceCorrecaoRoutingModule,
    FormsModule,
    SharedModule,
    ToastModule,
    IMaskModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    OverlayPanelModule
  ],
  exports: [
    IndiceCorrecaoListComponent,
    IndiceCorrecaoFormComponent
  ]
})
export class IndiceCorrecaoModule { }
