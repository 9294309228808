import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FuncaoService, Exercicio, FormatoExportacao } from 'eddydata-lib';

@Directive()
export class Anexo12DespesaNaoComputada implements OnDestroy {

  protected funcaoService: FuncaoService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number,
    protected exercicio: Exercicio) {
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(dados: any[], dadosComputados: any[], dadosReceitas: any[], formato?: FormatoExportacao): {}[] {
    if (formato && formato !== 'pdf') return this.montarQuadroExportacao(dados, dadosComputados, dadosReceitas);

    const registros: {}[] = [
      [
        {
          text: 'DESPESAS COM AÇÕES E SERVIÇOS PÚBLICOS DE SAÚDE NÃO COMPUTADAS PARA FINS DE APURAÇÃO DO PERCENTUAL MÍNIMO',
          alignment: 'center', rowSpan: 2, bold: true, fontSize: 7, border: [true, false, true, true]
        },
        { text: 'DOTAÇÃO INICIAL', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: 'DOTAÇÃO ATUALIZADA (a)', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: 'DESPESAS EMPENHADAS', alignment: 'center', colSpan: 2, bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: '', border: [true, false, true, true] },
        { text: 'DESPESAS LIQUIDADAS', alignment: 'center', colSpan: 2, bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: '', border: [true, false, true, true] }
      ],
      ['', '', '',
        { text: 'ATÉ O BIMESTRE (e)', alignment: 'center', bold: true, fontSize: 7 },
        { text: '% (f) = (e/d)x100', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'ATÉ O BIMESTRE (g)', alignment: 'center', bold: true, fontSize: 7 },
        { text: '% (h) = (g/d)x100', alignment: 'center', bold: true, fontSize: 7 }
      ]
    ];

    const grupos2 = this.funcaoService.agrupar(dados, 'grupo',
      ['dotacao_inicial', 'dotacao_atualizado', 'empenhado', 'liquidado']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    for (const grupo of grupos2) {
      registros.push([
        {
          text: grupo.grupo, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_inicial']),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_atualizado']),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((+grupo.totalizadores['empenhado'] / +grupo.totalizadores['dotacao_atualizado']) * 100),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((+grupo.totalizadores['liquidado'] / +grupo.totalizadores['dotacao_atualizado']) * 100),
          alignment: 'right', fontSize: 7
        }
      ]);
      total1 += +grupo.totalizadores['dotacao_inicial'];
      total2 += +grupo.totalizadores['dotacao_atualizado'];
      total3 += +grupo.totalizadores['empenhado'];
      total4 += +grupo.totalizadores['liquidado'];

      const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo',
        ['dotacao_inicial', 'dotacao_atualizado', 'empenhado', 'liquidado']);
      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo != '') {
          registros.push([
            {
              text: subgrupo.grupo, fontSize: 7
            },
            {
              text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['dotacao_inicial']),
              alignment: 'right', fontSize: 7
            },
            {
              text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['dotacao_atualizado']),
              alignment: 'right', fontSize: 7
            },
            {
              text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado']),
              alignment: 'right', fontSize: 7
            },
            {
              text: this.funcaoService.convertToBrNumber((+subgrupo.totalizadores['empenhado'] / +subgrupo.totalizadores['dotacao_atualizado']) * 100),
              alignment: 'right', fontSize: 7
            },
            {
              text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liquidado']),
              alignment: 'right', fontSize: 7
            },
            {
              text: this.funcaoService.convertToBrNumber((+subgrupo.totalizadores['liquidado'] / +subgrupo.totalizadores['dotacao_atualizado']) * 100),
              alignment: 'right', fontSize: 7
            }
          ]);

          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              registros.push([
                {
                  text: item.nome, fontSize: 7
                },
                { text: this.funcaoService.convertToBrNumber(item.dotacao_inicial), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.dotacao_atualizado), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.empenhado), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber((+item.empenhado / +item.dotacao_atualizado) * 100), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.liquidado), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber((+item.liquidado / +item.dotacao_atualizado) * 100), alignment: 'right', fontSize: 7 }
              ]);
            }
          }
        }
      }
    }

    registros.push([
      {
        text: 'TOTAL DAS DESPESAS COM AÇÕES E SERVIÇOS PÚBLICOS DE SAÚDE NÃO COMPUTADAS (V)', fontSize: 7, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber((+total3 / +total2) * 100), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total4), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber((+total4 / +total2) * 100), alignment: 'right', fontSize: 7, bold: true },
    ]);

    let totalComputado1 = 0;
    let totalComputado2 = 0;
    let totalComputado3 = 0;
    let totalComputado4 = 0;
    const gruposComputados = this.funcaoService.agrupar(dadosComputados, 'grupo',
      ['dotacao_inicial', 'dotacao_atualizado', 'empenhado', 'liquidado']);
    for (const item of gruposComputados) {
      totalComputado1 += +item.totalizadores['dotacao_inicial'];
      totalComputado2 += +item.totalizadores['dotacao_atualizado'];
      totalComputado3 += +item.totalizadores['empenhado'];
      totalComputado4 += +item.totalizadores['liquidado'];
    }

    registros.push([
      {
        text: 'TOTAL DAS DESPESAS COM AÇÕES E SERVIÇOS PÚBLICOS DE SAÚDE (VI) = (IV - V)', fontSize: 7, bold: true
      },
      { text: this.funcaoService.convertToBrNumber((+totalComputado1 - +total1) < 0 ? (+totalComputado1 - +total1) * -1 : (+totalComputado1 - +total1)), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber((+totalComputado2 - +total2) < 0 ? (+totalComputado2 - +total2) * -1 : (+totalComputado2 - +total2)), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(+totalComputado3 - +total3), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber((((+totalComputado3 - +total3) / (+totalComputado2 - +total2)) < 0 ? (((+totalComputado3 - +total3) / (+totalComputado2 - +total2)) * -1) : ((+totalComputado3 - +total3) / (+totalComputado2 - +total2))) * 100), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber((+totalComputado4 - +total4) < 0 ? (+totalComputado4 - +total4) * -1 : (+totalComputado4 - +total4)), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber((((+totalComputado4 - +total4) / (+totalComputado2 - +total2)) < 0 ? (((+totalComputado4 - +total4) / (+totalComputado2 - +total2)) * -1) : ((+totalComputado4 - +total4) / (+totalComputado2 - +total2))) * 100), alignment: 'right', fontSize: 7, bold: true },
    ]);

    let totalArrecadado = 0;
    const gruposReceitas = this.funcaoService.agrupar(dadosReceitas, 'grupo', ['previsao', 'atualizado', 'arrecadado']);

    for (const item of gruposReceitas) {
      totalArrecadado += +item.totalizadores['arrecadado'];
    }

    registros.push([
      {
        text: 'PARTICIPAÇÃO DAS DESPESAS COM AÇÕES E SERVIÇOS PÚBLICOS DE SAÚDE NA RECEITA DE IMPOSTOS LÍQUIDA E TRANSFERÊNCIAS CONSTITUCIONAIS E LEGAIS - LIMITE CONSTITUCIONAL <%>² [(IVg) / IIIb x 100]',
        fontSize: 7, bold: true, colSpan: 6
      }, '', '', '', '', '',
      { text: this.funcaoService.convertToBrNumber((+totalComputado4 / +totalArrecadado) * 100), alignment: 'right', fontSize: 7, bold: true },
    ]);

    return registros;
  }

  private montarQuadroExportacao(dados: any[], dadosComputados: any[], dadosReceitas: any[]): {}[] {
    const listaItens = new Array();

    listaItens.push({
      'receita_desc': 'DESPESAS COM AÇÕES E SERVIÇOS PÚBLICOS DE SAÚDE NÃO COMPUTADAS PARA FINS DE APURAÇÃO DO PERCENTUAL MÍNIMO',
      'inicial': 'DOTAÇÃO INICIAL',
      'atualizada': 'DOTAÇÃO ATUALIZADA (a)',
      'arrecadado_empenhada': 'DESPESAS EMPENHADAS',
      'calculo_empenhada': '',
      'liquidada': 'DESPESAS LIQUIDADAS',
      'calculo_liquidada': '',
    });

    listaItens.push({
      'receita_desc': '',
      'inicial': '',
      'atualizada': '',
      'arrecadado_empenhada': 'ATÉ O BIMESTRE (e)',
      'calculo_empenhada': '% (f) = (e/d)x100',
      'liquidada': 'ATÉ O BIMESTRE (g)',
      'calculo_liquidada': '% (h) = (g/d)x100'
    });

    const grupos2 = this.funcaoService.agrupar(dados, 'grupo', ['dotacao_inicial', 'dotacao_atualizado', 'empenhado', 'liquidado']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;

    for (const grupo of grupos2) {
      listaItens.push({
        'receita_desc': grupo.grupo,
        'inicial': this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_inicial'], 2),
        'atualizada': this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_atualizado'], 2),
        'arrecadado_empenhada': this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado'], 2),
        'calculo_empenhada': this.funcaoService.convertToBrNumber((+grupo.totalizadores['empenhado'] / +grupo.totalizadores['dotacao_atualizado']) * 100, 2),
        'liquidada': this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado'], 2),
        'calculo_liquidada': this.funcaoService.convertToBrNumber((+grupo.totalizadores['liquidado'] / +grupo.totalizadores['dotacao_atualizado']) * 100, 2),
      });

      const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['dotacao_inicial', 'dotacao_atualizado', 'empenhado', 'liquidado']);
      total1 += +grupo.totalizadores['dotacao_inicial'];
      total2 += +grupo.totalizadores['dotacao_atualizado'];
      total3 += +grupo.totalizadores['empenhado'];
      total4 += +grupo.totalizadores['liquidado'];

      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo != '') {
          listaItens.push({
            'receita_desc': subgrupo.grupo,
            'inicial': this.funcaoService.convertToBrNumber(subgrupo.totalizadores['dotacao_inicial'], 2),
            'atualizada': this.funcaoService.convertToBrNumber(subgrupo.totalizadores['dotacao_atualizado'], 2),
            'arrecadado_empenhada': this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado'], 2),
            'calculo_empenhada': this.funcaoService.convertToBrNumber((+subgrupo.totalizadores['empenhado'] / +subgrupo.totalizadores['dotacao_atualizado']) * 100, 2),
            'liquidada': this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liquidado'], 2),
            'calculo_liquidada': this.funcaoService.convertToBrNumber((+subgrupo.totalizadores['liquidado'] / +subgrupo.totalizadores['dotacao_atualizado']) * 100, 2),
          });

          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              listaItens.push({
                'receita_desc': item.nome,
                'inicial': this.funcaoService.convertToBrNumber(item.dotacao_inicial, 2),
                'atualizada': this.funcaoService.convertToBrNumber(item.dotacao_atualizado, 2),
                'arrecadado_empenhada': this.funcaoService.convertToBrNumber(item.empenhado, 2),
                'calculo_empenhada': this.funcaoService.convertToBrNumber((+item.empenhado / +item.dotacao_atualizado) * 100, 2),
                'liquidada': this.funcaoService.convertToBrNumber(item.liquidado, 2),
                'calculo_liquidada': this.funcaoService.convertToBrNumber((+item.liquidado / +item.dotacao_atualizado) * 100, 2),
              });
            }
          }
        }
      }
    }

    listaItens.push({
      'receita_desc': 'TOTAL DAS DESPESAS COM AÇÕES E SERVIÇOS PÚBLICOS DE SAÚDE NÃO COMPUTADAS (V)',
      'inicial': this.funcaoService.convertToBrNumber(total1, 2),
      'atualizada': this.funcaoService.convertToBrNumber(total2, 2),
      'arrecadado_empenhada': this.funcaoService.convertToBrNumber(total3, 2),
      'calculo_empenhada': this.funcaoService.convertToBrNumber((+total3 / +total2) * 100, 2),
      'liquidada': this.funcaoService.convertToBrNumber(total4, 2),
      'calculo_liquidada': this.funcaoService.convertToBrNumber((total4 / +total2) * 100, 2),
    });

    const gruposComputados = this.funcaoService.agrupar(dadosComputados, 'grupo', ['dotacao_inicial', 'dotacao_atualizado', 'empenhado', 'liquidado']);
    let totalComputado1 = 0;
    let totalComputado2 = 0;
    let totalComputado3 = 0;
    let totalComputado4 = 0;

    for (const item of gruposComputados) {
      totalComputado1 += +item.totalizadores['dotacao_inicial'];
      totalComputado2 += +item.totalizadores['dotacao_atualizado'];
      totalComputado3 += +item.totalizadores['empenhado'];
      totalComputado4 += +item.totalizadores['liquidado'];
    }

    listaItens.push({
      'receita_desc': 'TOTAL DAS DESPESAS COM AÇÕES E SERVIÇOS PÚBLICOS DE SAÚDE (VI) = (IV - V)',
      'inicial': this.funcaoService.convertToBrNumber((+totalComputado1 - +total1) < 0 ? (+totalComputado1 - +total1) * -1 : (+totalComputado1 - +total1), 2),
      'atualizada': this.funcaoService.convertToBrNumber((+totalComputado2 - +total2) < 0 ? (+totalComputado2 - +total2) * -1 : (+totalComputado2 - +total2), 2),
      'arrecadado_empenhada': this.funcaoService.convertToBrNumber(+totalComputado3 - +total3, 2),
      'calculo_empenhada': this.funcaoService.convertToBrNumber((((+totalComputado3 - +total3) / (+totalComputado2 - +total2)) < 0 ? (((+totalComputado3 - +total3) / (+totalComputado2 - +total2)) * -1) : ((+totalComputado3 - +total3) / (+totalComputado2 - +total2))) * 100, 2),
      'liquidada': this.funcaoService.convertToBrNumber(+totalComputado4 - +total4, 2),
      'calculo_liquidada': this.funcaoService.convertToBrNumber(((+totalComputado4 - +total4) / (+totalComputado2 - +total2) < 0 ? ((+totalComputado4 - +total4) / (+totalComputado2 - +total2) * - 1) : (+totalComputado4 - +total4) / (+totalComputado2 - +total2)) * 100, 2),
    });

    const gruposReceitas = this.funcaoService.agrupar(dadosReceitas, 'grupo', ['previsao', 'atualizado', 'arrecadado']);
    let totalArrecadado = 0;

    for (const item of gruposReceitas) {
      totalArrecadado += +item.totalizadores['arrecadado'];
    }

    listaItens.push({
      'receita_desc': 'PARTICIPAÇÃO DAS DESPESAS COM AÇÕES E SERVIÇOS PÚBLICOS DE SAÚDE NA RECEITA DE IMPOSTOS LÍQUIDA E TRANSFERÊNCIAS CONSTITUCIONAIS E LEGAIS - LIMITE CONSTITUCIONAL <%>² [(IVf) / IIIb x 100]',
      'inicial': '',
      'atualizada': '',
      'arrecadado_empenhada': '',
      'calculo_empenhada': '',
      'liquidada': '',
      'calculo_liquidada': this.funcaoService.convertToBrNumber((+totalComputado4 / +totalArrecadado) * 100, 2),
    });

    listaItens.push({
      'receita_desc': ''
    });

    return listaItens;
  }
}
