import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { RcmsListComponent } from './rcms-list/rcms-list.component';
import { RcmsFormComponent } from './rcms-form/rcms-form.component';
import { RcmsViewComponent } from './rcms-view/rcms-view.component';
import { RcmsAutorizacaoListComponent } from './rcms-autorizacao-list/rcms-autorizacao-list.component';

const routes: Routes = [
  { path: '', component: RcmsListComponent, canActivate: [AuthGuard] },
  {
    path: 'autorizacao', canActivate: [AuthGuard], children: [
      { path: '', component: RcmsAutorizacaoListComponent },
      { path: ':id/editar', component: RcmsFormComponent },
      { path: ':id/visualizar', component: RcmsViewComponent },
    ],
    data: {
      route: 'autorizacao'
    }
  },
  { path: 'novo', component: RcmsFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: RcmsFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: RcmsViewComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RcmsRoutingModule { }
