import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ConvenioComissao } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ConvenioComissaoService extends BaseResourceService<ConvenioComissao> {

  constructor(
    protected injector: Injector
  ) {
    super(`convenio-comissoes`, injector);
  }

}
