import { Injectable, Injector } from "@angular/core";
import { BaseResourceService, PosicaoEstoque,  } from "eddydata-lib";

@Injectable({
    providedIn: 'root'
})
export class PosicaoEstoqueService extends BaseResourceService<PosicaoEstoque>{

    constructor(
        protected injector: Injector
    ){
        super(`posicoes-estoque`, injector);
    }
    
}