// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Setor } from './setor.model';

export class SetorProcesso extends BaseResourceModel {
  constructor(
    public id?: number,
    public processo?: string,
    public baixado?: boolean,
    public setor?: Setor,
  ) {
    super();
  }
  static converteJson(json: any): SetorProcesso {
    return Object.assign(new SetorProcesso(), json);
  }
}
