import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { BemImovelFormComponent } from './bem-imovel-form/bem-imovel-form.component';
import { BemImovelListComponent } from './bem-imovel-list/bem-imovel-list.component';
import { BemImovelRoutingModule } from './bem-imovel-routing.module';
import { BemImovelViewComponent } from './bem-imovel-view/bem-imovel-view.component';
import { InputMaskModule } from 'primeng/inputmask';
import { PessoaModule, SharedModule, UsuarioModule } from 'eddydata-lib';
import { BemImovelOcorrenciaComponent } from './bem-imovel-ocorrencia/bem-imovel-ocorrencia.component';
import { EditorModule } from 'primeng/editor';
import { BemImovelStorageComponent } from './bem-imovel-storage/bem-imovel-storage.component';
import { FileUploadModule } from 'primeng/fileupload';
import { BemImovelFotoComponent } from './bem-imovel-foto/bem-imovel-foto.component';
import { FieldsetModule } from 'primeng/fieldset';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InventarioModule } from '../../../../patrimonio/src/app/inventario/inventario.module';

@NgModule({
  declarations: [
    BemImovelListComponent,
    BemImovelFormComponent,
    BemImovelViewComponent,
    BemImovelOcorrenciaComponent,
    BemImovelStorageComponent,
    BemImovelFotoComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    BemImovelRoutingModule,
    PessoaModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    UsuarioModule,
    InputMaskModule,
    EditorModule,
    FileUploadModule,
    FieldsetModule,
    ConfirmDialogModule,
    InventarioModule
  ],
  exports: [
    BemImovelListComponent
  ],
  providers: [MessageService, ConfirmationService]
})
export class BemImovelModule { }
