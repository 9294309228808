import { ConstantPool } from '@angular/compiler';
import { Component, Injector, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { EmpenhoService, LicitacaoService } from 'administrativo-lib';
import { GlobalService, Licitacao, LicitacaoAudesp, Login } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LicitacaoAudespService } from '../../service/licitacao-audesp.service';

declare var $: any;
@Component({
  selector: 'lib-licitacao-audesp-ajuste-list',
  templateUrl: './licitacao-audesp-ajuste-list.component.html'
})
export class LicitacaoAudespAjusteListComponent implements OnChanges, OnDestroy {

  @Input() public licitacao: Licitacao;
  @Input() public registroPreco: number;
  public listaAjuste: LicitacaoAudesp[];
  public listaEmpenho: Array<any> = [];
  public login: Login = new Login();
  public licitacaoObjeto: Licitacao = new Licitacao;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected globalService: GlobalService,
    private licitacaoService: LicitacaoService,
    protected router: Router,
    protected injector: Injector,
    private empenhoService: EmpenhoService,
    private audespService: LicitacaoAudespService) {
    this.router = this.injector.get(Router);
  }

  ngOnInit(): void {
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.registroPreco) {
      this.audespService.filtrar(0, 0, { parente_id: this.registroPreco, 'tipo$like': 'AJUSTE_%', relations: 'parente' }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaAjuste = res?.content ? res.content : [];
        })
    }
  }

  public async buscarEmpenho() {
    this.listaEmpenho = [];
    const emp = await this.empenhoService.extendido(1, -1, {
      licitacao_id: this.licitacao.id,
      orgao_id: this.login.orgao.id,
      exercicio_id: this.login.exercicio.id,
      'licitacao_audesp_id$null': '',
      relations: 'licitacao_audesp,contrato.itens'
    }).toPromise()
    this.listaEmpenho.push(...emp.content)
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  async verificaListaEmpenho() {
    await this.buscarEmpenho()
    if (this.listaEmpenho.length < 1){
      this.router.navigate(['/licitacoes-audesp', this.registroPreco, 'ajuste', 'novo'], { queryParams: { isRegPreco: 'true' } })
    } else {
      $('#empContrato').modal('show');
    }
  }

  fecharModal() {
    $('#empContrato').modal('hide');
  }
}