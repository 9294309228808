// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Cidade } from '../geo/cidade.model';
import { Cargo } from './cargo.model';

export class CargoHistorico extends BaseResourceModel {
  constructor(
    public id?: number,
    public historico?: string,
    public fundamento_legal?: string,
    public vagas?: number,
    public cargo?: Cargo,
    public cidade?: Cidade,
    public aux?: number,
  ) {
    super();
  }
  static converteJson(json: any): CargoHistorico {
    return Object.assign(new CargoHistorico(), json);
  }
}
