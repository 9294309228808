import { Injectable, Injector } from "@angular/core";
import { BaseResourceService, FavorecidoCei } from "eddydata-lib";

@Injectable({
    providedIn: 'root'
})
export class FavorecidoCeiService extends BaseResourceService<FavorecidoCei>{

    constructor(
        protected injector: Injector
    ){
        super(`favorecido-ceis`, injector);
    }
}