import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, PpaPeriodo } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class PpaPeriodoService extends BaseResourceService<PpaPeriodo> {

  constructor(
    protected injector: Injector
  ) {
    super(`ppa-periodos`, injector);
  }

}
