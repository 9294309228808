
import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ConvenioPrestacao } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConvenioPrestacaoService extends BaseResourceService<ConvenioPrestacao> {

  constructor(
    protected injector: Injector
  ) {
    super(`convenio-prestacoes`, injector);
  }

  public obterUltimaParcela(convenio_id: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/ultima-parcela?convenio_id=${convenio_id}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterTotalPrestacaoContaPorConvenio(empenho_numero: number, empenho_ano: number, empenho_parcela: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/obter-total-prestacao-conta-por-convenio?empenho_numero=${empenho_numero}&empenho_ano=${empenho_ano}&empenho_parcela=${empenho_parcela}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
