import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { ModeloRelatorioListComponent } from './modelo-relatorio-list/modelo-relatorio-list.component';


const routes: Routes = [
  { path: '', component: ModeloRelatorioListComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModeloRelatorioRoutingModule { }
