import { Component, Input, OnInit } from '@angular/core';
import { Audesp4LoteCompostoItensSim, Audesp4LoteNaoObra } from 'eddydata-lib';
import { LicitacaoAudespService } from '../../service/licitacao-audesp.service';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-lote-item',
  templateUrl: './licitacao-audesp-lote-item.component.html'
})
export class LicitacaoAudespLoteItemComponent extends BaseLicitacaoAudesp implements OnInit {

  @Input() public lote: Audesp4LoteNaoObra;

  public itemAtual: Audesp4LoteCompostoItensSim;
  public itemAnterior: Audesp4LoteCompostoItensSim;
  public itemOrcamento: Audesp4LoteCompostoItensSim;

  public listaReceita: { id: number, nome: string }[];
  public listaDespesa: { id: number, nome: string }[];

  constructor(private audespService: LicitacaoAudespService) {
    super()
  }

  ngOnInit(): void {
    this.listaReceita = this.audespService.listarTipoReceita();
    this.listaDespesa = this.audespService.listarTipoDespesa();
  }

  public adicionar() {
    if (!this.lote.LoteCompostoItensSim || this.lote.LoteCompostoItensSim.length === 0) this.lote.LoteCompostoItensSim = [];

    this.itemAtual = {
      Produto: '', Quantidade: 0, OrcamentoItemNao: 'N', OrcamentoItemSim: [], UnidadeMedida: '', editavel: true,
      ClassificacaoEconomica: { 
        TipoClassificacaoEconomica: this.itemAtual?.ClassificacaoEconomica?.TipoClassificacaoEconomica,
        CodigoClassificacaoEconomica: this.itemAtual?.ClassificacaoEconomica?.CodigoClassificacaoEconomica,
        ClassificacaoEconomicaOutros: undefined 
      },
    }
    this.lote.LoteCompostoItensSim.push(this.itemAtual);
    console.log(this.lote);
  }

  public visualizar(item: Audesp4LoteCompostoItensSim) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      let itm = this.lote.LoteCompostoItensSim.find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.lote.LoteCompostoItensSim.splice(this.lote.LoteCompostoItensSim.indexOf(this.itemAtual), 1, itm)
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4LoteCompostoItensSim) {
    this.itemAtual = undefined;
    this.lote.LoteCompostoItensSim.splice(this.lote.LoteCompostoItensSim.indexOf(item), 1)
  }

  public salvar() {
    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }

  public abrirOrcamentoItem(item: Audesp4LoteCompostoItensSim) {
    this.itemOrcamento = item;
    $('#dialogLoteItemOrc').modal('show');
  }
}
