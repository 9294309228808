// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido } from './favorecido.model';
import { RcmsCotacao } from './rcms-cotacao.model';
import { Rcms } from './rcms.model';

export class RcmsFavorecido extends BaseResourceModel {
  constructor(
    public id?: number,
    public favorecido?: Favorecido,
    public rcms?: Rcms,
    public cotacoes?: RcmsCotacao[],
    public editavel?: boolean
  ) {
    super();
  }
  static converteJson(json: any): RcmsFavorecido {
    return Object.assign(new RcmsFavorecido(), json);
  }
}
