import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Convenio, Login } from 'eddydata-lib';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-convenio-ficha',
  templateUrl: './convenio-ficha.component.html',
})
export class ConvenioFichaComponent implements OnInit, OnChanges, OnDestroy {


  protected unsubscribe: Subject<void> = new Subject();

  @Input() listaReceitas: Array<any>;
  @Input() listaDespesas: Array<any>;
  @Input() entidade: Convenio;
  @Input() login: Login;

  constructor(
  ) { }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    
  }

}
