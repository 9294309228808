import { Component, Input } from '@angular/core';
import { BaseResourceItemsComponent, GlobalService, TipoContratacaoCondicaoDespesa } from 'eddydata-lib';
import * as toastr from 'toastr';
import { TipoContratacaoCondicaoDespesaService } from '../service/tipo-contratacao-condicao-despesa.service';

@Component({
  selector: 'app-tipo-contratacao-condicao-despesa',
  templateUrl: './tipo-contratacao-condicao-despesa.component.html'
})
export class TipoContratacaoCondicaoDespesaComponent extends BaseResourceItemsComponent<TipoContratacaoCondicaoDespesa> {

  @Input() visualizar: boolean;

  constructor(public globalService: GlobalService,
    public tipoContratacaoCondicaoDespesaService: TipoContratacaoCondicaoDespesaService) {
    super(new TipoContratacaoCondicaoDespesa(), tipoContratacaoCondicaoDespesaService);
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
  }

  protected validSave(item: TipoContratacaoCondicaoDespesa): boolean {
    if (!item.condicao) {
      toastr.warning('Informe a condição!');
      return false;
    }
    return true;
  }

  protected beforeSave(item: TipoContratacaoCondicaoDespesa): void { }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================
  private carregarAutoCompletes() {

  }

  public podeVisualizar() {
    if (this.visualizar === true) {
      return true;
    }
    if (this.visualizar === false) {
      return false;
    }
  }

}
