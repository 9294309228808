import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InplaceModule } from 'primeng/inplace';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { AdiantamentoCadastroDlgModule } from '../adiantamento/adiantamento-cadastro-dlg/adiantamento-cadastro-dlg.module';
import { EmpenhoAnulacaoComponent } from './empenho-anulacao/empenho-anulacao.component';
import { EmpenhoFormComponent } from './empenho-form/empenho-form.component';
import { EmpenhoFuncaoListComponent } from './empenho-funcao-list/empenho-funcao-list.component';
import { EmpenhoItemComponent } from './empenho-item/empenho-item.component';
import { EmpenhoListComponent } from './empenho-list/empenho-list.component';
import { EmpenhoRoutingModule } from './empenho-routing.module';
import { EmpenhoRptComponent } from './empenho-rpt/empenho-rpt.component';
import { EmpenhoViewComponent } from './empenho-view/empenho-view.component';
import { HistoricoFavorecidoComponent } from './historico-favorecido/historico-favorecido.component';
import { NotaEmpenhoDlgComponent } from './nota-empenho-dlg/nota-empenho-dlg.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { FieldsetModule } from 'primeng/fieldset';
import { EmpenhoFornecedorDlgComponent } from './empenho-fornecedor/empenho-fornecedor-dlg.component';
import { TooltipModule } from 'primeng/tooltip';
import { AtualizarDataAnulacaoComponent } from './empenho-anulacao-atualizacao-data/atualizar-data-anulacao.component';
import { ReservaDotacaoModule } from '../reserva-dotacao/reserva-dotacao.module';

@NgModule({
  declarations: [
    EmpenhoListComponent,
    EmpenhoViewComponent,
    EmpenhoFormComponent,
    EmpenhoFuncaoListComponent,
    EmpenhoRptComponent,
    NotaEmpenhoDlgComponent,
    EmpenhoFornecedorDlgComponent,
    EmpenhoAnulacaoComponent,
    HistoricoFavorecidoComponent,
    EmpenhoItemComponent,
    AtualizarDataAnulacaoComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    EmpenhoRoutingModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    RadioButtonModule,
    FileUploadModule,
    ConfirmDialogModule,
    InplaceModule,
    AdiantamentoCadastroDlgModule,
    InputNumberModule,
    FieldsetModule,
    TooltipModule,
    ReservaDotacaoModule,
  ],
  exports: [
    AtualizarDataAnulacaoComponent
  ],
  providers: [MessageService, ConfirmationService]
})
export class EmpenhoModule { }
