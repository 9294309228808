import { Component, Input } from '@angular/core';
import { Audesp4ExistenciaRecursosSim, Audesp4Financiamento } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-financiamento',
  templateUrl: './licitacao-audesp-financiamento.component.html'
})
export class LicitacaoAudespFinanciamentoComponent extends BaseLicitacaoAudesp {

  @Input() public recurso: Audesp4ExistenciaRecursosSim;

  public itemAtual: Audesp4Financiamento;
  public itemAnterior: Audesp4Financiamento;

  constructor() {
    super()
  }

  public adicionar() {
    if (!this.recurso.OperacoesCredito || this.recurso.OperacoesCredito.length === 0) this.recurso.OperacoesCredito = [];

    this.itemAtual = {
      ContratoFinanciamentoNum: undefined, ContratoFinanciamentoAno: undefined,
      RepasseContratoFinanciamentoValor: undefined, AgenteFinanceiro: undefined,
      editavel: true
    }
    this.recurso.OperacoesCredito.push(this.itemAtual);
  }

  public visualizar(item: Audesp4Financiamento) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      let itm = this.recurso.OperacoesCredito.find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.recurso.OperacoesCredito.splice(this.recurso.OperacoesCredito.indexOf(this.itemAtual), 1, itm)
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAtual.editavel = false;
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4Financiamento) {
    this.itemAtual = undefined;
    this.recurso.OperacoesCredito.splice(this.recurso.OperacoesCredito.indexOf(item), 1)
  }

  public salvar() {
    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }
}
