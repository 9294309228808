import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { PagamentoRestoService } from 'administrativo-lib';
import * as toastr from 'toastr';
import {
  BaseResourceListComponent, Coluna, DateFormatPipe, Filtro, FormatoExportacao, GlobalService, LoginContabil, OrgaoAssinaturaService, PagamentoResto, ProgressoService
} from 'eddydata-lib';
import { ReinfRegistroService } from 'projects/contabil/src/app/efd-reinf/service/reinf-registro.service';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { NotaPagamentoResto } from '../../relatorio-tesouraria/notas/nota-pagamento-resto';

declare var $: any;

@Component({
  selector: 'app-pagamento-resto-list',
  templateUrl: './pagamento-resto-list.component.html'
})
export class PagamentoRestoListComponent extends BaseResourceListComponent<PagamentoResto, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public especieSelect = 'EMR';
  public listaEspecies: Array<any>;
  public ptBR: any;
  public datepipe: DatePipe;
  public data1: Date;
  public data2: Date;
  public pagamentoAnular: PagamentoResto;
  public valorAnular: number = 0;
  public pagamentoAtualizar: PagamentoResto
  public visualizarAtualizacao = false;
  public pagamentossSelecionados: any[] = [];
  public pagamentosId: any[] = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected assinaturaService: OrgaoAssinaturaService,
    private globalService: GlobalService,
    protected registroService: ReinfRegistroService,
    protected progressoService: ProgressoService,
    private pagamentoService: PagamentoRestoService) {
    super(pagamentoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'conta,conta.banco,liquidacao,liquidacao.empenho,liquidacao.empenho.favorecido,exercicio,orgao';
  }

  protected condicoesGrid(): {} {
    const parametros = {};
    this.datepipe = new DatePipe('pt');
    parametros['data_pagamento$ge'] = this.datepipe.transform(this.data1, 'yyyy-MM-dd');
    parametros['data_pagamento$le'] = this.datepipe.transform(this.data2, 'yyyy-MM-dd');
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['exercicio.id'] = this.login.exercicio.id

    sessionStorage.setItem('/pagamentos-restos-pagar_data1', this.datepipe.transform(this.data1, 'yyyy-MM-dd'));
    sessionStorage.setItem('/pagamentos-restos-pagar_data2', this.datepipe.transform(this.data2, 'yyyy-MM-dd'));

    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['data_pagamento$DESC', 'id$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      date: ['data_pagamento'],
      number: ['liquidacao.empenho.numero', 'valor_pago', 'conta.agencia', 'conta.numero_conta', 'liquidacao.empenho.favorecido.cpf_cnpj', 'liquidacao.empenho.ano'],
      text: ['liquidacao.empenho.favorecido.nome', 'documento']
    };
  }

  public beforeInit(): void {
    this.usarFiltroPersonalizado = true;
    this.usarExtendido = true;
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    const dt1: string = sessionStorage.getItem('/pagamentos-restos-pagar_data1');
    const dt2: string = sessionStorage.getItem('/pagamentos-restos-pagar_data2');
    if (!dt1) {
      this.data1 = new DateFormatPipe().transform(this.datepipe.transform(new Date(`01/01/${this.login.exercicio.ano}`), 'yyyy-MM-dd'), []);
    } else {
      this.data1 = new DateFormatPipe().transform(dt1, []);
    }
    if (!dt2) {
      this.data2 = new DateFormatPipe().transform(this.datepipe.transform(new Date(), 'yyyy-MM-dd'), []);
    } else {
      this.data2 = new DateFormatPipe().transform(dt2, []);
    }
  }

  protected afterInit(): void {
    this.listaEspecies = [
      { id: 'EMO', nome: 'ORÇAMENTÁRIO' },
      { id: 'EME', nome: 'EXTRA-ORÇAMENTARIO' },
      { id: 'EMR', nome: 'RESTOS A PAGAR' }
    ];
  }

  protected acaoRemover(model: PagamentoResto): Observable<PagamentoResto> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Data', coluna: 'data_pagamento' },
      { titulo: 'Número', coluna: 'liquidacao.empenho.numero' },
      { titulo: 'Fornecedor/credor', coluna: 'liquidacao.empenho.favorecido.nome' },
      { titulo: 'Documento', coluna: 'liquidacao.documento' },
      { titulo: 'Vl. Bruto', coluna: 'valor_pago', decimais: 2, alignment: 'right' },
      { titulo: 'Vl. Retido', coluna: 'valor_retido', decimais: 2, alignment: 'right' },
      { titulo: 'Vl. Líquido', coluna: 'valor_liquido', decimais: 2, alignment: 'right', funcao: [{ valor: ['valor_pago', '-', 'valor_retido'] }] },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'conta,conta.banco,liquidacao,liquidacao.empenho,liquidacao.empenho.favorecido,exercicio,orgao';
    this.pagamentoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE PAGAMENTOS ORÇAMENTÁRIOS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem pagamentos', ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'], lista.content, ['valor_pago', 'valor_retido', 'valor_liquido']);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  public obterColunasFiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];

    retorno.push({ titulo: 'Data pagamento', coluna: 'data_pagamento', tipo: 'Date', alignment: 'center', padrao: true, filtro2: new Date() });
    retorno.push({ titulo: 'Nº Empenho', coluna: 'liquidacao.empenho.numero', alignment: 'center', tipo: 'Number', padrao: true });
    retorno.push({ titulo: 'Parcela', coluna: 'liquidacao.parcela', alignment: 'center', tipo: 'Number', padrao: false });
    retorno.push({ titulo: 'Favorecido', coluna: 'liquidacao.empenho.favorecido.nome', tipo: 'String', padrao: false });
    retorno.push({ titulo: 'Histórico', coluna: 'historico', tipo: 'String' });
    retorno.push({ titulo: 'CPF/CNPJ', coluna: 'liquidacao.empenho.favorecido.cpf_cnpj', tipo: 'String', cols: 3, mask: [{ mask: '000.000.000-00' }, { mask: '00.000.000/0000-00' }] });
    retorno.push({ titulo: 'Documento', coluna: 'documento', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Ficha nº', coluna: 'conta.codigo', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Nº da conta', coluna: 'conta.numero_conta', alignment: 'center', tipo: 'String', padrao: false, cols: 3 });
    retorno.push({ titulo: 'Recurso', coluna: 'conta_recurso.recurso.codigo', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Aplicação', coluna: 'conta_recurso.aplicacao.codigo', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Variável', coluna: 'conta_recurso.convenio.codigo', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Valor', coluna: 'valor_pago', alignment: 'center', tipo: 'Number', padrao: false });
    retorno.push({ titulo: 'Valor retido', coluna: 'valor_retido', alignment: 'center', tipo: 'Number', padrao: false });
    retorno.push({ titulo: 'Ano', coluna: 'liquidacao.empenho.ano', alignment: 'center', tipo: 'Number', padrao: false });
    retorno.push({ titulo: 'Código Despesa', coluna: 'liquidacao.empenho.subelemento', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Modalidade', coluna: 'liquidacao.empenho.modalidade.nome', tipo: 'String', cols: 3 });

    return retorno;
  }
  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public onChangePagamento(value: any, especie: any) {
    if (especie === 'EMO') {
      this.router.navigate(['pagamentos-orcamentarios']);
    } else if (especie === 'EME') {
      this.router.navigate(['pagamentos-extras']);
    }
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/tesouraria']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public anular(item) {
    this.pagamentoAnular = item;
    this.pagamentoService.obterTotalPagoPorLiquidacaoId(item.liquidacao.id).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      let pagoParcela = +res.total_pago;
      this.valorAnular = +pagoParcela.toFixed(2);
      $('#dialogAnular').modal('show');
    }, error => {
      toastr.warning('Falha ao carregar valor disponível para anulação.');
      this.valorAnular = 0;
      $('#dialogAnular').modal('show');
    });
  }


  public acertarRetencoes() {
    this.pagamentoService.acertarReceitaRetencoes(this.login.exercicio.id, this.login.orgao.id).pipe(takeUntil(this.unsubscribe)).subscribe(idTransacao => {
      this.progressoService.show(idTransacao);
    },
      error => this.acaoErro(error));
  }

  protected acaoErro(error: any) {
    console.log('Erro disparado: ', error);
    toastr.options.timeOut = 10000;
    toastr.options.closeButton = true;
    toastr.options.tapToDismiss = false;
    if (error.error && error.error.payload) {
      toastr.error(error.error.payload);
    } else {
      toastr.error('Ocorreu um erro ao processar a sua solicitação');
    }
  }

  public editarAnulacao(item: PagamentoResto) {
    return item.anulacao && this.login.usuario.sistemas_administrador?.split(',').includes('contabil')
  }

  public alterarAnular(item: PagamentoResto) {
    this.pagamentoAtualizar = item;
    this.visualizarAtualizacao = true;
  }

  async mudarSituacao(item: PagamentoResto) {
    let registro = null;
    switch (item.situacao) {
      case 'N':
      case 'R':
        registro = await this.registroService.obter({ 'orgao.id': this.login.orgao.id, ambiente: 1, sucesso: true, ratificado: false, evento: 'R2010', id_alvo: item.id }).toPromise();
        if (registro) {
          item.situacao = 'E';
        } else {
          item.situacao = 'I';
        }
        break;
      case 'I':
        registro = await this.registroService.obter({ 'orgao.id': this.login.orgao.id, ambiente: 1, sucesso: true, ratificado: false, evento: 'R2010', id_alvo: item.id }).toPromise();
        item.situacao = registro ? 'R' : 'N';
        break;
      case 'E':
        item.situacao = 'R';
        break;
      default:
    }
    this.pagamentoService.atualizarSituacaoReinf(item).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      toastr.success(`Situação alterada com sucesso`);
      this.preencherGrid()
    });
  }

  public ImprimirNotaPorPagamentosSelecionados(item: PagamentoResto) {
    if (item['selecionado'] === true) {
      item['selecionado'] = false;
      // Encontre o índice do item na lista this.pagamentossSelecionados
      const index = this.pagamentossSelecionados.findIndex(e => e.id === item.id);
      if (index > -1) {
        this.pagamentossSelecionados.splice(index, 1);
      }
    } else {
      item['selecionado'] = true;
      // Verifique se o item já não está na lista
      if (!this.pagamentossSelecionados.some(e => e.id === item.id)) {
        this.pagamentossSelecionados.push(item);
      }
    }
    this.pagamentosId = [];
    for (const liquidacao of this.pagamentossSelecionados) {
      this.pagamentosId.push(liquidacao.id)
    }
  }

  imprimirNota() {
    const parametros = {};

    let relations = 'liquidacao.empenho.contrato.licitacao.modalidade,'
    relations += 'liquidacao.empenho.contrato,liquidacao.empenho.favorecido.contas.banco,liquidacao.empenho.convenio,liquidacao.empenho.favorecido';

    if (this.pagamentosId.length === 0 || !this.pagamentosId) {
      toastr.warning('Selecione ao menos um pagamento para imprimir')
      return;
    }
    
    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id$in'] = this.pagamentosId;

    this.pagamentoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        new NotaPagamentoResto(this.assinaturaService).imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }
}
