import { Login } from './login';
import { Exercicio } from '../comum/exercicio.model';
import { Usuario } from '../comum/usuario.model';
import { Orgao } from '../comum/orgao.model';
import { Cidade } from '../geo/cidade.model';
import { Favorecido } from '../compra/favorecido.model';
import { Sistemas } from '../../components/types';

export class LoginEntidade extends Login {
    constructor(
        public sistema?: Sistemas,
        public exercicio?: Exercicio,
        public usuario?: Usuario,
        public orgao?: Orgao,
        public cidade?: Cidade,
        public limite?: number,
        public token?: string,
        public brasao?: string,
        public favorecido?: Favorecido
    ) {
        super(sistema, exercicio, usuario, orgao, cidade, limite, token, brasao);
    }
}
