import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  BaseResourceItemsComponent, DateFormatPipe, EddyAutoComplete, FuncaoService, GlobalService, Login, Page, Produto, ProdutoUnidade,
  RequisicaoItemMovimento, RequisicaoMovimento, SaldoEstoqueService, SetorAlmoxarifado
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ProdutoUnidadeService } from '../../produto/service/produto-unidade.service';
import { ProdutoService } from '../../produto/service/produto.service';

@Component({
  selector: 'lib-requisicao-autorizacao-item',
  templateUrl: './requisicao-autorizacao-item.component.html'
})
export class RequisicaoAutorizacaoItemComponent extends BaseResourceItemsComponent<RequisicaoItemMovimento> implements OnChanges {

  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  @Input() somenteVisualizar: boolean = false;
  @Input() requisicao: RequisicaoMovimento;
  @Input() setor_almoxarifado: SetorAlmoxarifado;
  @Input() login: Login;

  public ptBR: any;

  public produtoAutoComplete: EddyAutoComplete<Produto>;

  public listProdUn: ProdutoUnidade[];
  public listLoteVec: any[];
  public listLoteVecFiltro: any[];

  public itemTroca: RequisicaoItemMovimento;
  public indexTroca: number;
  public visualizarTroca: boolean = false;

  public imaskValor = {
    mask: Number,
    scale: 5,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================

  constructor(private produtoUnService: ProdutoUnidadeService,
    private saldoEstoqueService: SaldoEstoqueService,
    public funcaoService: FuncaoService,
    public globalService: GlobalService,
    private produtoService: ProdutoService) {
    super(new RequisicaoItemMovimento(), produtoService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.requisicao)
      this.carregarAutoCompletes();
  }

  protected afterInit(): void {
    this.ptBR = new GlobalService().obterDataBR();
    this.carregarAutoCompletes();
  }

  protected validSave(item: RequisicaoItemMovimento): boolean {
    if (!item.produto_unidade || !item.produto_unidade.id) {
      toastr.warning('Informe o produto e a unidade!');
      return false;
    }

    if (item.produto_unidade_troca && !item.justificativa_troca) {
      toastr.warning('Informe a justificativa de troca!');
      return false;
    }

    if (!this.validarSaldo(item))
      return false;

    const incluido = this.lista.find((i) => item.produto_unidade.id === i.produto_unidade.id && !i['editavel']);
    if (incluido) {
      toastr.warning(`O produto ${item.produto_unidade.produto.nome} já foi incluído.`);
      return false;
    }
    return true
  }

  protected beforeSave(item: RequisicaoItemMovimento): void {
    this.listProdUn = [];
  }

  protected afterEdit(item: RequisicaoItemMovimento) {
    this.carregarUnidades(item);
    item.vencimento = new DateFormatPipe().transform(item.vencimento, []);
  }

  public disabled() {
    if (!this.setor_almoxarifado)
      return true;
    return super.disabled();
  }
  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  private carregarAutoCompletes() {
    if (!this.requisicao)
      return;
    this.produtoAutoComplete = ProdutoService.autoCompleteCodigoCompleto(null, this.produtoService,
      'id', ['codigo', 'nome'], { orgao_id: this.login.orgao.id, orderBy: 'codigo,nome' }, { number: ['id', 'codigo'], text: ['codigo', 'nome'] }, null, null, null,
      (pagina: number, limite: number, filtros: {}, filtroStr: string): Observable<Page> => {
        let parametros: { codigo?: string, nome?: string } = {};
        if (new FuncaoService().isNumerico(filtroStr))
          parametros.codigo = filtroStr;
        else
          parametros.nome = filtroStr;
        return this.saldoEstoqueService
          .obterProdutosComSaldo(this.setor_almoxarifado.id, parametros)
          .pipe(map(res => {
            let page = new Page();
            page.content = res;
            return page;
          }));
      }
    );

  }

  public carregarUnidades(item: RequisicaoItemMovimento) {
    if (!item.produto_unidade.produto) {
      return;
    }
    this.produtoUnService.filtrarCodigoCompleto(0, 0, {
      produto_id: item.produto_unidade.produto.id,
      ativo: true,
      relations: 'produto,unidade',
      orderBy: 'unidade.nome'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listProdUn = res ? res.content : [];
        if (this.listProdUn.length === 0) {
          toastr.warning('Nenhuma unidade ativa foi localizada para este produto!');
          return;
        }
        if (!item.produto_unidade || !item.produto_unidade.id) {
          item.produto_unidade = this.listProdUn[0];
        }
        this.carregarLotes(item);

      }, error => toastr.error(error.message ? error.message : error));
  }

  public carregarLotes(item: RequisicaoItemMovimento) {
    if (!item.produto_unidade || !item.produto_unidade.id)
      return;
    this.saldoEstoqueService
      .saldoProdutoUnidadeLoteVencimento(item.produto_unidade.id, this.setor_almoxarifado.id, { nao_obg_saldo: true })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((saldos) => {
        if (!saldos || saldos.length === 0)
          return;
        this.listLoteVec = saldos;
        if (!item.lote || !item.vencimento) {
          let saldo = saldos[0];
          this.onChangeLote(item, saldo);
        } else {
          let itemL = saldos.find((s) => s.lote === item.lote
            && new Date(new Date(s.vencimento).toDateString()).getTime() === new Date(new Date(item.vencimento).toDateString()).getTime());
          this.onChangeLote(item, itemL);
        }
      });
  }

  onChangeLote(item: RequisicaoItemMovimento, itemL) {
    if (item.vencimento < itemL.vencimento) {
      toastr.warning('Atenção! Existem lotes com vencimentos anteriores, por favor verifique!');
    }

    item.lote = itemL.lote;
    item.vencimento = itemL.vencimento;
    item['saldo_atual'] = itemL.saldo_atual;
    item['saida'] = itemL.saida;
  }

  public trocar(item: RequisicaoItemMovimento) {
    this.itemTroca = item;
    this.visualizarTroca = true;
  }

  public trocatItem(item: RequisicaoItemMovimento) {
    item.produto_unidade['itens'] = []
    this.valorTotal(item);
    let itemList = this.lista.find((i) => i.id === item.id);
    this.lista[this.lista.indexOf(itemList)] = item;
  }

  public reverterTroca(item: RequisicaoItemMovimento) {
    if (item.produto_unidade_troca) {
      item.produto_unidade = item.produto_unidade_troca;
      this.saldoEstoqueService
        .saldoProdutoUnidadeLoteVencimento(item.produto_unidade.id, this.login.setorAlmoxarifado.id, { nao_obg_saldo: true })
        .subscribe((saldos) => {
          if (!saldos || saldos.length === 0)
            return;
          let saldo = saldos.sort((a, b) => new Date(new Date(a.vencimento).toDateString()).getTime() - new Date(new Date(b.vencimento).toDateString()).getTime())[0];
          item.lote = saldo.lote;
          item.vencimento = new DateFormatPipe().transform(saldo.vencimento, []);
          item['saldo_atual'] = +saldo.saldo_atual;
          item['saida'] = +saldo.saida;
        });

      item.produto_unidade_troca = null;
      item.justificativa_troca = null;
      item.qtd_aceita = 0;
    }
  }

  public valorTotal(item: RequisicaoItemMovimento, sem_notificacao?: boolean) {
    const saldo = this.requisicao.devolucao ? item['saida'] : item['saldo_atual'];
    if (!saldo) {
      if (!sem_notificacao)
        toastr.warning(`Item não possui saldo para requição.`);
      return;
    } else if (saldo >= +item.qtd_aceita) {
      if (saldo >= +item.qtd_requisitada) {
        if (!item.qtd_aceita || +item.qtd_aceita <= 0) {
          item.qtd_aceita = item.qtd_requisitada;
        }
      }
    } else {
      if (!sem_notificacao)
        toastr.warning(`Saldo insuficiente para a requisição, valor maximo adicionado.`);
      item.qtd_aceita = saldo;
    }
  }

  buscarLoteVencimento(event) {
    if (event.query)
      this.listLoteVecFiltro = this.listLoteVec.filter((l) => l.lote.toLowerCase().includes(event.query.toLowerCase()));
    else
      this.listLoteVecFiltro = Object.assign([], this.listLoteVec)
  }

  conversorLote(itemL) {
    if (!itemL || !itemL.lote || !itemL.vencimento)
      return '';
    return `${itemL.lote} - ${new FuncaoService().converteDataBR(itemL.vencimento)}`
  }

  public compareFnLoteVencimento(c1: any, c2: any): boolean {
    return c1 && c2 && c1.lote && c2.lote && c1.vencimento && c2.vencimento
      && c1.lote === c2.lote && new Date(c1.vencimento).toDateString() === new Date(c2.vencimento).toDateString();
  }


  private validarSaldo(item: RequisicaoItemMovimento, sem_notificacao?: boolean) {
    if (item.qtd_aceita > (item.saldo_atual)) {
      if (!sem_notificacao)
        toastr.warning('Quantidade aceita maior que o saldo atual!');
      return false;
    }
    return true;
  }


  public onBlurQtd(item: RequisicaoItemMovimento) {
    if (!this.validarSaldo(item))
      setTimeout(() => {
        item.qtd_aceita = item['saldo_atual'];
      }, 100);
  }

  public preencherItemComSaldo(item: RequisicaoItemMovimento) {
    if (!item.qtd_aceita || +item.qtd_aceita <= 0) {
      this.valorTotal(item);
    } else {
      item.qtd_aceita = 0;
    }
  }

  public todosSelecionados(): boolean {
    return !this.lista || this.lista
      .filter((r) => !r['checked']).length === 0
  }

  public selecionarTodos() {
    if (!this.lista)
      return;
    if (this.todosSelecionados())
      this.lista.forEach((r) => {
        r.qtd_aceita = 0
        r['checked'] = false;
      });
    else
      this.lista.forEach((r) => {
        if (!r.qtd_aceita || +r.qtd_aceita <= 0)
          this.valorTotal(r, true);
        r['checked'] = true;
      })
  }

}