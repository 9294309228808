import { NgModule } from '@angular/core';

import { PreLiquidacaoRestoRoutingModule } from './pre-liquidacao-resto-routing.module';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { IMaskModule } from 'angular-imask';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { PreLiquidacaoRestoListComponent } from './pre-liquidacao-resto-list/pre-liquidacao-resto-list.component';
import { PreLiquidacaoRestoViewComponent } from './pre-liquidacao-resto-view/pre-liquidacao-resto-view.component';
import { PreLiquidacaoRestoAnulacaoComponent } from './pre-liquidacao-resto-anulacao/pre-liquidacao-resto-anulacao.component';
import { PreLiquidacaoRestoFormComponent } from './pre-liquidacao-resto-form/pre-liquidacao-resto-form.component';
import { PreLiquidacaoRestoRptComponent } from './pre-liquidacao-resto-rpt/pre-liquidacao-resto-rpt.component';
import { NotaPreLiquidacaoRestoDlgComponent } from './nota-pre-liquidacao-resto-dlg/nota-pre-liquidacao-resto-dlg.component';
import { PreLiquidacaoRestoConsultaDlgComponent } from './pre-liquidacao-resto-consulta-dlg/pre-liquidacao-resto-consulta-dlg.component';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SharedModule } from 'eddydata-lib';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';

@NgModule({
  declarations: [
    PreLiquidacaoRestoListComponent,
    PreLiquidacaoRestoViewComponent,
    PreLiquidacaoRestoFormComponent,
    PreLiquidacaoRestoAnulacaoComponent,
    PreLiquidacaoRestoRptComponent,
    NotaPreLiquidacaoRestoDlgComponent,
    PreLiquidacaoRestoConsultaDlgComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    PreLiquidacaoRestoRoutingModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    RadioButtonModule,
    ConfirmDialogModule,
    FieldsetModule
  ],
  exports: [PreLiquidacaoRestoConsultaDlgComponent, PreLiquidacaoRestoFormComponent],
  providers: [MessageService, DatePipe, ConfirmationService]
})
export class PreLiquidacaoRestoModule { }
