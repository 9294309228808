import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import {
  FuncaoService, GlobalService, Exercicio, Login
} from 'eddydata-lib';

@Directive()
export class Anexo8Apuracao implements OnDestroy {

  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number,
    protected exercicio: Exercicio) {
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(impostos: any[], fundeb: any[], DCFundeb: any[], indicadores: any[], despesaMde: any[], restoMde: any[]): {}[] {

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: 'APURAÇÃO DAS DESPESAS PARA FINS DE LIMITE MÍNIMO CONSTITUCIONAL',
        alignment: 'center',
        bold: true,
        margin: [5, 5, 5, 5],
        colSpan: 3
      }, '', '', {
        text: 'VALOR',
        alignment: 'center',
        fontSize: 6,
      }],

    ];

    let totalReceitaImposto = 0;
    let empenhadoDespesa = 0;
    let empenhadoMDE = 0;
    let quadrimestre = 0;
    let disponibilidade = 0;
    let L3 = 0;
    let L4 = 0;
    let cancelado = 0;
    let total = 0;

    // Caso seja adicionado novos campos no layout será necessario mudar a posição dos arrays
    L4 = ((impostos[4].arrecadado + impostos[6].arrecadado + impostos[7].arrecadado + impostos[8].arrecadado + impostos[9].arrecadado) * 0.2);

    totalReceitaImposto = (fundeb[0].arrecadado - L4);

    const grupos = this.funcaoService.agrupar(impostos, 'grupo', ['atualizado', 'arrecadado']);

    L3 += grupos.reduce((acc, item) => +item.totalizadores['arrecadado'] + acc, 0);
    empenhadoMDE += despesaMde.reduce((acc, item) => +item.empenhado + acc, 0);

    for (let item of DCFundeb) {
      if (item.grupo === '14- Total das Despesas custeadas com FUNDEB - Impostos e Transferências de Impostos') {
        empenhadoDespesa = +item.empenhado;
        disponibilidade = +item.disponibilidade;
      }
    }

    for (let item of indicadores) {
      if (item.subgrupo === '23.1- Total das Despesas custeadas com FUNDEB - Impostos e Transferências de Impostos') {
        quadrimestre = +item.superavit + +item.valor;
      }
    }

    for (let item of restoMde) {
      if (item.subgrupo === '34.1 - Executadas com Recursos de Impostos e Transferências de Impostos' || item.subgrupo === '34.2 - Executadas com Recursos do FUNDEB - Impostos') {
        cancelado += item.cancelado;
      }
    }

    total = (empenhadoDespesa + empenhadoMDE + quadrimestre) - (totalReceitaImposto + disponibilidade + cancelado);

    registros.push([
      { text: '27- TOTAL DAS DESPESAS DE MDE CUSTEADAS COM RECURSOS DE IMPOSTOS (FUNDEB E RECEITA DE IMPOSTOS) = (L14(d ou e) + L26(d ou e) + L23.1(t))', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: this.funcaoService.convertToBrNumber(empenhadoDespesa + empenhadoMDE + quadrimestre), alignment: 'right', fontSize: 7, bold: true },
    ]);
    registros.push([
      { text: '28 (-) RESULTADO LÍQUIDO DAS TRANSFERÊNCIAS DO FUNDEB = (L7)', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: this.funcaoService.convertToBrNumber(totalReceitaImposto), alignment: 'right', fontSize: 7, bold: true },
    ]);
    registros.push([
      { text: '29 (-) RESTOS A PAGAR NÃO PROCESSADOS INSCRITOS NO EXERCÍCIO SEM DISPONIBILIDADE FINANCEIRA DE RECURSOS DO FUNDEB IMPOSTOS4 = (L14h)', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: this.funcaoService.convertToBrNumber(disponibilidade), alignment: 'right', fontSize: 7, bold: true },
    ]);
    registros.push([
      { text: '30 (-) RESTOS A PAGAR NÃO PROCESSADOS INSCRITOS NO EXERCÍCIO SEM DISPONIBILIDADE FINANCEIRA DE RECURSOS DE IMPOSTOS 4 e 7 ', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: '', alignment: 'right', fontSize: 7, bold: true },
    ]);
    registros.push([
      { text: '31 (-) CANCELAMENTO, NO EXERCÍCIO, DE RESTOS A PAGAR INSCRITOS COM DISPONIBILIDADE FINANCEIRA DE RECURSOS DE IMPOSTOS VINCULADOS AO ENSINO = (L34.1(ac) + L34.2(ac))', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: this.funcaoService.convertToBrNumber(cancelado), alignment: 'right', fontSize: 7, bold: true },
    ]);
    registros.push([
      { text: '32- TOTAL DAS DESPESAS PARA FINS DE LIMITE  (27 – (28 + 29 + 30 + 31))', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: this.funcaoService.convertToBrNumber(total), alignment: 'right', fontSize: 7, bold: true },
    ]);
    registros.push([
      { text: 'APURAÇÃO DO LIMITE MÍNIMO CONSTITUCIONAL 2 e 5', fontSize: 7, bold: true },
      { text: 'VALOR EXIGIDO (x)', alignment: 'right', fontSize: 7, bold: true },
      { text: 'VALOR APLICADO (w)', alignment: 'right', fontSize: 7, bold: true },
      { text: '% APLICADO (y)', alignment: 'right', fontSize: 7, bold: true },
    ]);
    registros.push([
      { text: '33- APLICAÇÃO EM MDE SOBRE A RECEITA RESULTANTE DE IMPOSTOS', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(L3 * 0.25), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber((total / L3) * 100), alignment: 'right', fontSize: 7, bold: true },
    ]);

    return registros;

  }

  public montarQuadroCsv(impostos: any[], fundeb: any[], DCFundeb: any[], indicadores: any[], despesaMde: any[], restoMde: any[], listaExportar): any {
    
    const espaco = {
      titulo: '',
      coluna1: '',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
    }
    listaExportar.push(espaco)

    const primeira_linha = {
      titulo: 'APURAÇÃO DAS DESPESAS PARA FINS DE LIMITE MÍNIMO CONSTITUCIONAL',
      coluna1: 'VALOR',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
    }
    listaExportar.push(primeira_linha)

    let totalReceitaImposto = 0;
    let empenhadoDespesa = 0;
    let empenhadoMDE = 0;
    let quadrimestre = 0;
    let disponibilidade = 0;
    let L3 = 0;
    let L4 = 0;
    let cancelado = 0;
    let total = 0;

    // Caso seja adicionado novos campos no layout será necessario mudar a posição dos arrays
    L4 = ((impostos[4].arrecadado + impostos[6].arrecadado + impostos[7].arrecadado + impostos[8].arrecadado + impostos[9].arrecadado) * 0.2);

    totalReceitaImposto = (fundeb[0].arrecadado - L4);

    const grupos = this.funcaoService.agrupar(impostos, 'grupo', ['atualizado', 'arrecadado']);

    L3 += grupos.reduce((acc, item) => +item.totalizadores['arrecadado'] + acc, 0);
    empenhadoMDE += despesaMde.reduce((acc, item) => +item.empenhado + acc, 0);

    for (let item of DCFundeb) {
      if (item.grupo === '14- Total das Despesas custeadas com FUNDEB - Impostos e Transferências de Impostos') {
        empenhadoDespesa = +item.empenhado;
        disponibilidade = +item.disponibilidade;
      }
    }

    for (let item of indicadores) {
      if (item.subgrupo === '23.1- Total das Despesas custeadas com FUNDEB - Impostos e Transferências de Impostos') {
        quadrimestre = +item.superavit + +item.valor;
      }
    }

    for (let item of restoMde) {
      if (item.subgrupo === '34.1 - Executadas com Recursos de Impostos e Transferências de Impostos' || item.subgrupo === '34.2 - Executadas com Recursos do FUNDEB - Impostos') {
        cancelado += item.cancelado;
      }
    }

    total = (empenhadoDespesa + empenhadoMDE + quadrimestre) - (totalReceitaImposto + disponibilidade + cancelado);

    const linha_27 = {
      titulo: '27- TOTAL DAS DESPESAS DE MDE CUSTEADAS COM RECURSOS DE IMPOSTOS (FUNDEB E RECEITA DE IMPOSTOS) = (L14(d ou e) + L26(d ou e) + L23.1(t))',
      coluna1: this.funcaoService.convertToBrNumber(empenhadoDespesa + empenhadoMDE + quadrimestre),
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: ''
    }
    listaExportar.push(linha_27)

    const linha_28 = {
      titulo: '28 (-) RESULTADO LÍQUIDO DAS TRANSFERÊNCIAS DO FUNDEB = (L7)',
      coluna1: this.funcaoService.convertToBrNumber(totalReceitaImposto),
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: ''
    }
    listaExportar.push(linha_28)

    const linha_29 = {
      titulo: '29 (-) RESTOS A PAGAR NÃO PROCESSADOS INSCRITOS NO EXERCÍCIO SEM DISPONIBILIDADE FINANCEIRA DE RECURSOS DO FUNDEB IMPOSTOS4 = (L14h)',
      coluna1: this.funcaoService.convertToBrNumber(disponibilidade),
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: ''
    }
    listaExportar.push(linha_29)

    const linha_30 = {
      titulo: '30 (-) RESTOS A PAGAR NÃO PROCESSADOS INSCRITOS NO EXERCÍCIO SEM DISPONIBILIDADE FINANCEIRA DE RECURSOS DE IMPOSTOS 4 e 7 ',
      coluna1: '',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: ''
    }
    listaExportar.push(linha_30)

    const linha_31 = {
      titulo: '31 (-) CANCELAMENTO, NO EXERCÍCIO, DE RESTOS A PAGAR INSCRITOS COM DISPONIBILIDADE FINANCEIRA DE RECURSOS DE IMPOSTOS VINCULADOS AO ENSINO = (L34.1(ac) + L34.2(ac))',
      coluna1: this.funcaoService.convertToBrNumber(cancelado),
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: ''
    }
    listaExportar.push(linha_31)

    const linha_32 = {
      titulo: '32- TOTAL DAS DESPESAS PARA FINS DE LIMITE  (27 – (28 + 29 + 30 + 31))',
      coluna1: this.funcaoService.convertToBrNumber(total),
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: ''
    }
    listaExportar.push(linha_32)

    const linha_apuracao = {
      titulo: 'APURAÇÃO DO LIMITE MÍNIMO CONSTITUCIONAL 2 e 5',
      coluna1: 'VALOR EXIGIDO (x)',
      coluna2: 'VALOR APLICADO (w)',
      coluna3: '% APLICADO (y)',
      coluna4: '',
      coluna5: '',
      coluna6: ''
    }
    listaExportar.push(linha_apuracao)

    const linha_33 = {
      titulo: '33- APLICAÇÃO EM MDE SOBRE A RECEITA RESULTANTE DE IMPOSTOS',
      coluna1: this.funcaoService.convertToBrNumber(L3 * 0.25),
      coluna2: this.funcaoService.convertToBrNumber(total),
      coluna3: this.funcaoService.convertToBrNumber((total / L3) * 100),
      coluna4: '',
      coluna5: '',
      coluna6: ''
    }
    listaExportar.push(linha_33)

  }

}