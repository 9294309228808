import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FuncaoService, GrupoEstoque, Login, MovimentoItemEstoque, Produto, ProdutoUnidade, SubGrupoEstoque } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ProdutoService } from '../service/produto.service';

declare var $: any;

@Component({
  selector: 'lib-produto-busca-dlg',
  templateUrl: './produto-busca-dlg.component.html'
})
export class ProdutoBuscaDlgComponent implements OnInit, OnDestroy {

  @Input() login: Login;
  @Output() callback: EventEmitter<Produto> = new EventEmitter();
  @Input() parametrosBusca: {
    aparecerServicos?: boolean,
    aparecerProdInativos?: boolean
  } = {
    aparecerServicos: true,
    aparecerProdInativos: true
  }


  // filtros listagem
  public listaGrupos: GrupoEstoque[];
  public grupo: GrupoEstoque;
  public subGrupo: SubGrupoEstoque;
  public servico: boolean = false;
  public codigo: string;
  public nome: string;

  // listagem
  public lista: Produto[];
  public paginaCorrente: number;
  public paginaTotal: number;
  limite: number = 10;

  protected unsubscribe: Subject<void> = new Subject();
  listProdUn: any;
  selecionarEditavel: any;

  constructor(
    private produtoService: ProdutoService
  ) { }

  ngOnInit() {
    if (!this.paginaCorrente) {
      this.paginaCorrente = 1;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public buscar() {
    this.paginaCorrente = 1;
    this.preencherGrid();
  }

  private preencherGrid() {
    const parametros = {};
    if(!this.parametrosBusca.aparecerProdInativos) {
      parametros['inativo'] = false;
    }
    parametros['relations'] = 'material.sub_grupo.grupo';
    parametros['orgao_id'] = this.login.orgao.id;
    if (this.subGrupo) {
      parametros['material.sub_grupo_id'] = this.subGrupo.id;
    } else if (this.grupo) {
      parametros['material.sub_grupo.grupo_id'] = this.grupo.id;
    } else if (this.servico) {
      parametros['material.servico'] = this.servico;
    }
    if (this.codigo) {
      let codigo = this.codigo
        .split('\.').join('')
        .split(',').join('');
      parametros['codigo_comp$like'] = '%' + codigo + '%';
    }
    if (this.nome) {
      // parametros['OR'] = `nome$like=%${this.nome}%;!;!;descricao$like=%${this.nome}%`;
      parametros['nome$like'] = `%${new FuncaoService().removerAcentos(this.nome)}%`;
    }
    parametros['orderBy'] = 'nome,unidades.unidade.nome';

    if (Object.keys(parametros).length <= 1) {
      toastr.info('Informe os valores de busca');
      return;
    }

    this.produtoService.filtrar(this.paginaCorrente, this.limite, parametros).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.lista = lista.content;
          this.paginaTotal = lista.totalPages;
        },
        error => alert('erro ao retornar lista')
      );
  }

  public proximaPagina() {
    this.paginaCorrente = this.paginaCorrente === this.paginaTotal ? this.paginaTotal : this.paginaCorrente + 1;
    this.preencherGrid();
  }

  public paginaAnterior() {
    this.paginaCorrente = this.paginaCorrente === 1 ? 1 : this.paginaCorrente - 1;
    this.preencherGrid();
  }

  public paginaDigitada(pagina: number) {
    this.paginaCorrente =
      +pagina >= this.paginaTotal
        ? this.paginaTotal
        : +pagina;
    this.preencherGrid();
    window.scrollTo(0, 0);
  }

  public mudarLimite(limite: number) {
    this.limite = limite;
    this.paginaDigitada(1);
  }

  public enviarProduto(prod: Produto) {
    this.callback.emit(prod);
    this.fechar();
  }

  public fechar() {
    this.codigo = undefined;
    this.nome = undefined;
    this.lista = [];
    $('#dialogProdutoBusca').modal('hide');
  }

  public buscarRegistroIncluido(item: Produto) {
    if (item) {
      this.codigo = undefined;
      this.nome = item.nome;
    }
    this.buscar();
  }

  @HostListener('window:keydown.control.p', ['$event'])
  public abrirConsultaProduto(event: KeyboardEvent) {
    event.preventDefault();
    $('#dialogProdutoUnBusca').modal('show');
  }

  public callbackProdutoUn(prodUn: ProdutoUnidade) {
    let item = this.selecionarEditavel();
    if (item) {
      item.produto_unidade = prodUn;
      this.carregarUnidades(item);
    }
  }


  public carregarUnidades(item: MovimentoItemEstoque) {
    if (!item?.produto_unidade?.produto) {
      return;
    }
    this.produtoService.filtrarCodigoCompleto(0, 0, {
      orgao_id: this.login.orgao.id,
      produto_id: item.produto_unidade.produto.id,
      ativo: true,
      relations: 'produto,unidade',
      orderBy: 'unidade.nome'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listProdUn = res ? res.content : [];
        if (this.listProdUn.length === 0) {
          toastr.warning('Nenhuma unidade ativa foi localizada para este produto!');
          return;
        }
        if (item.produto_unidade.id)
          item.produto_unidade = this.listProdUn.find((p) => p.id === item.produto_unidade.id);
        else
          item.produto_unidade = this.listProdUn[0];
      }, error => toastr.error(error.error.payload));
  }
}
