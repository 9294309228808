import { Injectable, Injector } from '@angular/core';
import { BaseResourceService } from '../../../models/services/base-resource.service';
import { Cnae } from '../../../entidade/comum/cnae.model';

@Injectable({
  providedIn: 'root'
})
export class CnaeService extends BaseResourceService<Cnae> {

  constructor(
    protected injector: Injector
  ) {
    super(`cnaes`, injector);
  }

}
