import { Component, Injector, OnInit } from '@angular/core';
import { BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, LoginContabil, MetaGoverno } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MetaGovernoService } from '../service/meta-governo.service';

@Component({
  selector: 'lib-meta-governo-lst',
  templateUrl: './meta-governo-lst.component.html'
})
export class MetaGovernoLstComponent extends BaseResourceListComponent<MetaGoverno, LoginContabil> implements OnInit {

  /**
   * Declaração de variáveis
   */

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private metaService: MetaGovernoService) {
    super(metaService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================


  protected relations(): string {
    return 'programa,funcao,subfuncao,unidade';
  }

  protected condicoesGrid(): {} {
    return {
      ['ppa.id']: this.login.ppa.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['programa.codigo$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['programa.codigo', 'unidade.codigo', 'funcao.codigo'],
      text: ['programa.nome', 'funcao.nome', 'unidade.nome'],
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: MetaGoverno): Observable<MetaGoverno> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'programa.codigo' },
      { titulo: 'Descrição', coluna: 'programa.nome' },
      { titulo: 'Função', coluna: 'funcao.nome' },
      { titulo: 'Subfunção', coluna: 'subfuncao.nome' },
      { titulo: 'Unidade', coluna: 'unidade.nome' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'programa,funcao,subfuncao,unidade';
    this.metaService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE METAS DE GOVERNO:
          ${this.login.ppa.ppaperiodo.ano1} - ${this.login.ppa.ppaperiodo.ano4}`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem Metas de Governo', ['auto', 'auto', 'auto', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
