import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EddydataLibComponent } from './eddydata-lib.component';
import { Max100Pipe } from './components/pipe/max-100.pipe';
import { NomeFormatadoPipe } from './components/pipe/nome-formatado.pipe';
import { LimitarTextoPipe } from './components/pipe/limitar-texto.pipe';
@NgModule({
  declarations: [EddydataLibComponent, Max100Pipe, NomeFormatadoPipe, LimitarTextoPipe],
  imports: [
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [EddydataLibComponent, NomeFormatadoPipe, LimitarTextoPipe]
})
export class EddydataLibModule { }
