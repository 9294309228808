import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-server-error-messages',
  templateUrl: './server-error-messages.component.html'
})
export class ServerErrorMessagesComponent {

// tslint:disable-next-line: no-input-rename
  @Input('server-error-messages') serverErrorMessages: string[] = null;

  constructor() { }

}
