import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { FieldsetModule } from 'primeng/fieldset';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { AtaPersonalizadaRptComponent } from './ata-personalizada-rpt/ata-personalizada-rpt.component';
import { ModeloAtaDlgComponent } from './modelo-ata-dlg/modelo-ata-dlg.component';

@NgModule({
    declarations: [
        AtaPersonalizadaRptComponent,
        ModeloAtaDlgComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        AutoCompleteModule,
        AccordionModule,
        CalendarModule,
        IMaskModule,
        ToastModule,
        RadioButtonModule,
        FieldsetModule,
        SelectButtonModule,
    ],
    exports: [
        AtaPersonalizadaRptComponent,
        ModeloAtaDlgComponent,
    ],
    providers: []
})
export class AtaPersonalizadaModule { }
