import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { BaseResourceService, EmpenhoExtra, RetencaoExtra, Usuario } from 'eddydata-lib';
import { DatePipe } from '@angular/common';

interface TotalEmpenhado {
  empenhado: string;
  retido: string;
}

@Injectable({
  providedIn: 'root'
})
export class EmpenhoExtraService extends BaseResourceService<EmpenhoExtra> {

  constructor(
    protected injector: Injector
  ) {
    super(`empenhos-extras`, injector);
  }

  public obterPorNumero(numero: number, ano: number, orgaoId: number): Observable<EmpenhoExtra> {
    return this.http.get<EmpenhoExtra>(
      `${this.login.cidade.id}/${this.api}?numero=${numero}&exercicio_ano=${ano}&orgao_id=${orgaoId}`).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public atualizarDataAnulacao(id: number, data: string, orgao_id: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/data-anulacao/${id}/${data}/${orgao_id}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err)))
  }

  public obterTotalEmpenhadoPorNumero(numero: number, parcela: number, exercicioId: number, orgaoId: number): Observable<TotalEmpenhado> {
    return this.http.get<TotalEmpenhado>(
      `${this.login.cidade.id}/${this.api}/total-empenhado-por-numero/${numero}/${parcela}/${exercicioId}/${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalizadores(exercicio: number, orgaoId: number, id: number): Observable<any> {
    let parametros = '';
    parametros += '?exercicio_id=' + exercicio;
    parametros += '&orgao_id=' + orgaoId;
    parametros += '&id=' + id;
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/extendido/1/1${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public anular(id: number, data: string, valor: number, historico: string, usuario: Usuario, retencoes: RetencaoExtra[]): Observable<EmpenhoExtra> {
    const anulacao = { id, data, valor, historico, usuario, retencoes };
    return this.http.post<EmpenhoExtra>(
      `${this.login.cidade.id}/${this.api}/anular`, JSON.stringify(anulacao), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

  public parcelar(id: number, data: string, data_vencimento: string, valor: number, historico: string, usuario: Usuario, listaRetencoes: RetencaoExtra[]): Observable<EmpenhoExtra> {
    console.log(listaRetencoes)
    const parcelamento = { id, data, data_vencimento, valor, historico, usuario, listaRetencoes };
    return this.http.post<EmpenhoExtra>(
      `${this.login.cidade.id}/${this.api}/parcelar`, JSON.stringify(parcelamento), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

  public proximoNumeroEmpenho(exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-numero/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public ultimaDataEmpenhada(exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/ultima-data-empenhada/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterUltimaParcela(numero: number, exercicioId: number, orgaoId: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/ultima-parcela/${numero}/${exercicioId}/${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public marcarComoImpresso(parametros: any): Observable<any> {
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/marcar-como-impresso`, JSON.stringify(parametros), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

  public importarEmpenhosAbertos(orgao: number, exercicio: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/abertura-importar-abertos/${exercicio}/${orgao}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public empenhoExtraEmAberto(dataInicio: Date, dataFinal: Date, exercicio: number, orgaos: number[]): Observable<any> {
    let data_inicial = new DatePipe('pt').transform(dataInicio, 'yyyy-MM-dd');
    let data_final = new DatePipe('pt').transform(dataFinal, 'yyyy-MM-dd');
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/empenho-extra-em-aberto?dataInicio=${data_inicial}&dataFinal=${data_final}&exercicio=${exercicio}&orgaos=${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
