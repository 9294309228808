// tslint:disable: variable-name
import { TipoHistoricoTombo } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { Tombamento } from './tombamento.model';

export class TombamentoHistorico extends BaseResourceModel {
  constructor(
    public id?: number,
    public destaque?: boolean,
    public tipo?: TipoHistoricoTombo,
    public descricao?: string,
    public tombamento?: Tombamento,
    public usuario?: Usuario,
  ) {
    super();
  }

  static converteJson(json: any): TombamentoHistorico {
    return Object.assign(new TombamentoHistorico(), json);
  }
}
