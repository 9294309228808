import { Component, Input } from '@angular/core';
import { TipoArquivoFase4, TipoObjetoLicitacao } from 'eddydata-lib';

@Component({
  selector: 'lib-licitacao-audesp-ajuste-arquivos',
  templateUrl: './licitacao-audesp-ajuste-arquivos.component.html'
})
export class LicitacaoAudespAjusteArquivosComponent {

  @Input() public licitacao: number;
  @Input() public registroPreco: number;
  @Input() public ajuste: number;
  @Input() public tipoAjuste: TipoArquivoFase4;
  @Input() public tipoObjeto: TipoObjetoLicitacao;

  constructor() {
  }

}
