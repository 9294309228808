import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdiantamentoListComponent } from './adiantamento-list/adiantamento-list.component';
import { AdiantamentoViewComponent } from './adiantamento-view/adiantamento-view.component';
import { AdiantamentoFormComponent } from './adiantamento-form/adiantamento-form.component';
import { AdiantamentoRptComponent } from './adiantamento-rpt/adiantamento-rpt.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: AdiantamentoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: AdiantamentoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: AdiantamentoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: AdiantamentoViewComponent, canActivate: [AuthGuard] },
  { path: 'relatorios', component: AdiantamentoRptComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdiantamentoRoutingModule { }
