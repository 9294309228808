import { Boleto } from './boleto';

export abstract class Banco {
    protected boleto: Boleto;

    public setBoleto(boleto: Boleto) {
        this.boleto = boleto;
    }

    public getBoleto(): Boleto {
        return this.boleto;
    }

    public getDvNumero(): string {
        return null;
    }

    public abstract getNumero(): string;

    public abstract getCodigoBarras(): string;

    public abstract getLinhaDigitavel(): string;

    public abstract getCarteiraFormatted(): string;

    public abstract getAgenciaCodCedenteFormatted(): string;

    public abstract getNossoNumeroFormatted(): string;

    public getSac(): string {
        return '';
    }
}