import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { IMaskModule } from 'angular-imask';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CommonModule } from '@angular/common';
import { InputMaskModule } from 'primeng/inputmask';
import { ToastModule } from 'primeng/toast';
import { SharedModule } from '../util/shared.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CalendarModule } from 'primeng/calendar';
import { FieldsetModule } from 'primeng/fieldset';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { NotaVersaoShowComponent } from './nota-versao-show/nota-versao-show.component';
import { PanelModule } from 'primeng/panel';

@NgModule({
  declarations: [NotaVersaoShowComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    ImageCropperModule,
    IMaskModule,
    InputMaskModule,
    ToastModule,
    ConfirmDialogModule,
    CalendarModule,
    FieldsetModule,
    RadioButtonModule,
    DropdownModule,
    InputSwitchModule,
    PanelModule,
  ],
  exports: [
    NotaVersaoShowComponent
  ],
  providers: []
})
export class NotaVersaoModule { }
