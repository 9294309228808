import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class IBGEService {

  protected http: HttpClient;

  constructor(
    protected injector: Injector
  ) {
    this.http = injector.get(HttpClient);
  }

  public buscarCidades(estado: number): Observable<any> {
    return this.http.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios`);
  }

}

