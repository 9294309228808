import { ConvenioRecursoService } from './../convenio-recurso/service/convenio-recurso.service';
import {
  Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy,
  OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren
} from '@angular/core';
import {
  Convenio, ConvenioPrestacao, ConvenioRecurso, ConvenioSituacao, DateFormatPipe, EddyAutoComplete, Empenho, FuncaoService,
  GlobalService, Login, Pagamento
} from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PagamentoService } from '../../../tesouraria/pagamento/service/pagamento.service';
import { ConvenioPrestacaoService } from '../service/convenio-prestacao.service';
import { ConvenioSituacaoService } from '../service/convenio-situacao.service';
import * as toastr from 'toastr'

declare var $: any;

@Component({
  selector: 'lib-convenio-situacao',
  templateUrl: './convenio-situacao.component.html',
  styleUrls: ['./convenio-situacao.component.css']
})
export class ConvenioSituacaoComponent implements OnInit, OnChanges, OnDestroy {

  public ptBR: any;
  public titulo: string;
  public tipo: string;
  public entity: ConvenioSituacao;
  public entityItem: ConvenioPrestacao;
  public entityTemp: ConvenioSituacao;
  public entityTempItem: ConvenioPrestacao;
  public empenho: Empenho;
  protected unsubscribe: Subject<void> = new Subject();
  public listaSituacoes: { id: string, nome: string }[];
  public total_pago: number;
  public total_geral_pago: number;
  public total_prestado_conta: number;
  public fonteRecurso: ConvenioRecurso
  public fonteRecursoAutoComplete: EddyAutoComplete<ConvenioRecurso>
  public ano: number = null;

  @Input() lista: Array<ConvenioSituacao>;
  @Input() entidade: Convenio;
  @Input() login: Login;
  @Output() callback: EventEmitter<Array<ConvenioSituacao>> = new EventEmitter();

  @ViewChild('btnAdicionar') public btnAdicionar: ElementRef;
  @ViewChildren('btnsEditar') public btnsEditar: QueryList<ElementRef>;
  @ViewChildren('btnsEditarItem') public btnsEditarItem: QueryList<ElementRef>;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected globalService: GlobalService,
    public funcaoService: FuncaoService,
    protected prestacaoService: ConvenioPrestacaoService,
    protected situacaoService: ConvenioSituacaoService,
    protected pagamentoService: PagamentoService,
    protected convenioRecursoService: ConvenioRecursoService
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.listaSituacoes = new Array();
    this.total_pago = 0;
    this.listaSituacoes.push({ id: '0', nome: 'APROVADO' });
    this.listaSituacoes.push({ id: '1', nome: 'IMPUGNADO' });
    this.listaSituacoes.push({ id: '2', nome: 'INADIMPLÊNCIA EFETIVA' });
    this.listaSituacoes.push({ id: '3', nome: 'INADIMPLÊNCIA SUSPENSA' });
    this.listaSituacoes.push({ id: '4', nome: 'HOMOLOGADO' });
    this.listaSituacoes.push({ id: '5', nome: 'CANCELADO' });
    this.listaSituacoes.push({ id: '6', nome: 'ARQUIVADO' });
    this.listaSituacoes.push({ id: '7', nome: 'NÃO RECEBIDO/LIBERADO' });
    this.listaSituacoes.push({ id: '8', nome: 'CONCLUÍDO' });

    this.fonteRecursoAutoComplete = new EddyAutoComplete(null, this.convenioRecursoService, 'id', ['aplicacao_variavel.codigo', 'aplicacao_variavel.nome'], { relations: 'convenio,aplicacao_variavel', 'convenio.id': this.entidade.id }, { text: ['aplicacao_variavel.codigo', 'aplicacao_variavel.nome'] });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public obterSituacao(id: string) {
    switch (id) {
      case '0': {
        return 'APROVADO';
      }
      case '1': {
        return 'IMPUGNADO';
      }
      case '2': {
        return 'INADIMPLÊNCIA EFETIVA';
      }
      case '3': {
        return 'INADIMPLÊNCIA SUSPENSA';
      }
      case '4': {
        return 'HOMOLOGADO';
      }
      case '5': {
        return 'CANCELADO';
      }
      case '6': {
        return 'ARQUIVADO';
      }
      case '7': {
        return 'NÃO RECEBIDO/LIBERADO';
      }
      case '8': {
        return 'CONCLUIDO';
      }
      default: {
        return '-';
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.entidade && this.entidade.id) {
      this.situacaoService.obterSomaPagamentosConvenio(this.entidade.id).subscribe(total => {
        this.total_geral_pago = total;
      });
    }

    if (changes.lista) {
      this.titulo = this.entidade.tipo_fornecedor === 'F' ? 'PAGAMENTOS' : 'RECEBIMENTOS';
      this.tipo = this.entidade.tipo_fornecedor;
      if (this.lista) {
        this.total_prestado_conta = 0;
        for (const it of this.lista) {
          this.total_prestado_conta += +it.valor_prestacao;
        }
      }
    }

    if (changes.lista && changes.lista.previousValue !== changes.lista.currentValue && changes.lista.previousValue?.find(i => !i.id)) {
      this.entity = { ...this.entity, id: changes.lista.currentValue[0]?.id }
    }
  }

  private desabilitarEdicao(desabilitar: boolean): void {
    this.btnsEditar.forEach((btn) => btn.nativeElement.disabled = desabilitar)
    this.btnAdicionar.nativeElement.disabled = desabilitar;
  }
  private desabilitarEdicaoItem(desabilitar: boolean): void {
    this.btnsEditar.forEach((btn) => btn.nativeElement.disabled = desabilitar)
    this.btnAdicionar.nativeElement.disabled = desabilitar;
  }

  public adicionar() {
    this.entity = new ConvenioSituacao();
    this.entity.data_orgao = new Date();
    this.entity.data_contabilizacao = new Date();
    this.entity.convenio = this.entidade;
    this.entity.usuario = this.login.usuario;
    this.entity.editavel = true;
    this.entity.itens = [];
    this.entity['salvo'] = false;
    this.desabilitarEdicao(true);
    $('#dialogSituacao').modal('show');
  }

  public salvar(item: ConvenioSituacao) {
    this.validarRegistro(item);
    // envia a entidade para ser salva no banco -----
    item.editavel = false;
    item['salvo'] = true;
    this.desabilitarEdicao(false);
    if (!this.entity.id) {
      if (!this.lista) {
        this.lista = new Array();
      }
      this.lista.unshift(this.entity);
    } else {
      const item = this.lista.find(l => l.id === this.entity.id);
      if (item) {
        item.situacao = this.entity.situacao;
      }
    }
    this.callback.emit(this.lista);
  }

  public editar(item: ConvenioSituacao) {
    this.entityTemp = JSON.parse(JSON.stringify(item));

    this.entity = item;
    this.entity.usuario = this.login.usuario;
    this.entity.editavel = true;
    this.entity['salvo'] = true;
    this.desabilitarEdicao(true);
    item.data_orgao = new DateFormatPipe().transform(item.data_orgao, []);
    item.data_prestacao = new DateFormatPipe().transform(item.data_prestacao, []);
    item.data_contabilizacao = new DateFormatPipe().transform(item.data_contabilizacao, []);
    this.total_pago = 0;
    for (const it of item.itens) {
      this.total_pago += +it.valor_prestacao;
    }
    $('#dialogSituacao').modal('show');
  }

  public cancelar() {
    if (this.entity['salvo'] && this.entity.id === this.entityTemp?.id) {
      this.entity.data_orgao = this.entityTemp.data_orgao;
      this.entity.data_contabilizacao = this.entityTemp.data_contabilizacao;
      this.entity.valor_situacao = this.entityTemp.valor_situacao;
      this.entity.usuario = this.login.usuario;
      this.entity.data_prestacao = this.entityTemp.data_prestacao;
      this.entity.situacao = this.entityTemp.situacao;
      this.entity.data_contabilizacao = this.entityTemp.data_contabilizacao;
      this.entity.editavel = false;
    }
    this.desabilitarEdicao(false);
    $('#dialogSituacao').modal('hide');
  }

  public confirmarRemocao(item: ConvenioPrestacao, index?: number) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja remover item?',
      acceptLabel: "Sim",
      key: 'removerItem',
      rejectLabel: "Não",
      header: 'Remoção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.remover(item, index)
      },
    });
  }

  private remover(item: ConvenioPrestacao, index?: number) {
    if (item.id) {
      this.prestacaoService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.entity.itens.splice(index, 1);
          this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Validação', detail: error.error.payload });
          this.situacaoService.filtrar(0, -1, {
            'convenio.id': this.entidade.id,
            relations: 'convenio,usuario'
          }).pipe(takeUntil(this.unsubscribe))
            .subscribe(
              (data: any) => {
                this.lista = data ? data.content : new Array<ConvenioSituacao>();
              }, err => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: err.error.payload }));
        });
    } else {
      this.lista.splice(index, 1);
    }
  }

  public validarRegistro(item: ConvenioSituacao) {
    try {
      if (!item.data_orgao) {
        throw new Error('Informe a data do orgão!');
      }
      if (!item.data_prestacao) {
        throw new Error('Informe a data da prestação de contas!');
      }
      if (!item.valor_prestacao || +item.valor_prestacao <= 0) {
        throw new Error('Informe o valor da prestação de contas!');
      }

      if (+(this.total_geral_pago - this.total_prestado_conta).toFixed(2) < 0) {
        throw new Error('Valor da prestação de contas maior que o total pago!');
      }

    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }


  public saldoPrestacaoConta() {
    this.situacaoService.obterSomaPagamentosConvenio(this.entidade.id).subscribe(total => {
      this.total_geral_pago = total ? +total : 0;
      this.total_prestado_conta = 0;
      for (const it of this.lista) {
        this.total_prestado_conta += +it.valor_prestacao;
      }
    });
  }

  public validaSaldoAExecutarPrestacao() {
    let totalPrestado = this.entity.itens.reduce((acc, item) => acc + +item.valor_prestacao, 0)
    return totalPrestado < this.total_geral_pago ? true : false
  }


  public async importar() {
    this.validarRegistro(this.entity);
    this.total_pago = 0;
    let parcela = await this.prestacaoService.obterUltimaParcela(this.entidade.id).toPromise();

    const parametros = {
      ignoreCondObrig: true,
      'liquidacao.empenho.convenio.id': this.entidade.id,
      relations: 'liquidacao.empenho.ficha.aplicacao_variavel,liquidacao.empenho.exercicio,conta,conta.banco',
      orderBy: 'data_pagamento,liquidacao.parcela'
    }

    if (this.fonteRecurso) {
      parametros['liquidacao.empenho.ficha.aplicacao_variavel.id'] = this.fonteRecurso.aplicacao_variavel.id
    }

    if (this.ano) {
      parametros['data_pagamento$extract_y'] = this.ano;
    }

    if (this.entity?.data_prestacao) {
      parametros['data_pagamento$le'] = this.funcaoService.converteDataSQL(this.entity.data_prestacao);
    }


    this.pagamentoService.filtrar(1, -1, parametros).subscribe(async res => {
      let lista: Pagamento[] = res.content;
      if (!lista.length) {
        toastr.warning('Não foram encontrados pagamentos referentes a data da prestação');
        return;
      }
      for (let pagamento of lista) {
        // const entidade = await this.prestacaoService.obter({
        //   empenho_numero: pagamento.liquidacao.empenho.numero,
        //   empenho_ano: pagamento.liquidacao.empenho.exercicio.ano,
        //   empenho_parcela: pagamento.liquidacao.parcela,
        //   ignoreCondObrig: true
        // }).toPromise();
        const entidade = await this.prestacaoService.obterTotalPrestacaoContaPorConvenio(pagamento.liquidacao.empenho.numero, pagamento.liquidacao.empenho.exercicio.ano, pagamento.liquidacao.parcela).toPromise();
        if (!this.entity.itens.find(item => item.empenho_numero == pagamento.liquidacao.empenho.numero
          && item.empenho_parcela == pagamento.liquidacao.parcela &&
          item.empenho_ano == pagamento.liquidacao.empenho.exercicio.ano &&
          item.empenho_tipo == pagamento.liquidacao.empenho.especie
        )) {
          if (!entidade) {
            let prestacao: ConvenioPrestacao = new ConvenioPrestacao();
            prestacao.parcela = ++parcela;
            prestacao.empenho_numero = pagamento.liquidacao.empenho.numero;
            prestacao.empenho_parcela = pagamento.liquidacao.parcela;
            prestacao.empenho_ano = pagamento.liquidacao.empenho.exercicio.ano;
            prestacao.empenho_tipo = pagamento.liquidacao.empenho.especie;
            prestacao.data_contabilizacao = this.entity.data_contabilizacao;
            prestacao.data_prestacao = this.entity.data_prestacao;
            prestacao.data_quitacao = pagamento.data_pagamento;
            prestacao.exercicio = this.login.exercicio;
            prestacao.valor_quitacao = pagamento.valor_pago;
            prestacao.usuario = this.login.usuario;
            if (+this.entity.valor_prestacao < (this.total_pago + +pagamento.valor_pago)) {
              prestacao.valor_prestacao = +this.entity.valor_prestacao - +this.total_pago;
            } else {
              prestacao.valor_prestacao = +pagamento.valor_pago;
            }
            this.total_pago += prestacao.valor_prestacao ? +prestacao.valor_prestacao : 0;
            this.entity.itens.push(prestacao);
            if (+this.total_pago === +this.entity.valor_prestacao) {
              break;
            }
          } else {
            if (+entidade.total_prestacao === +pagamento.valor_pago) {
              continue;
            } else {
              let prestacao: ConvenioPrestacao = new ConvenioPrestacao();
              prestacao.parcela = ++parcela;
              prestacao.empenho_numero = pagamento.liquidacao.empenho.numero;
              prestacao.empenho_parcela = pagamento.liquidacao.parcela;
              prestacao.empenho_ano = pagamento.liquidacao.empenho.exercicio.ano;
              prestacao.empenho_tipo = pagamento.liquidacao.empenho.especie;
              prestacao.data_contabilizacao = this.entity.data_contabilizacao;
              prestacao.data_prestacao = this.entity.data_prestacao;
              prestacao.data_quitacao = pagamento.data_pagamento;
              prestacao.exercicio = this.login.exercicio;
              prestacao.valor_quitacao = pagamento.valor_pago;
              prestacao.usuario = this.login.usuario;

              prestacao.valor_prestacao = +pagamento.valor_pago - +entidade.total_prestacao;

              this.total_pago += prestacao.valor_prestacao ? +prestacao.valor_prestacao : 0;
              this.entity.itens.push(prestacao);
              if (+this.total_pago === +this.entity.valor_prestacao) {
                break;
              }
            }
          }
          parcela++;
        }
      }
    });
  }

  public salvarItem(item: ConvenioPrestacao) {
    // envia a entidade para ser salva no banco -----

    this.total_pago = 0;
    let total_quitacao = 0;
    for (const it of this.entity.itens) {
      this.total_pago += +it.valor_prestacao;
      total_quitacao += +it.valor_quitacao;
    }

    if (+this.total_pago !== +this.entity.valor_prestacao) {
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'O total dos valores da prestação de contas não confere!' });
      return;
    }

    if (this.total_pago > total_quitacao) {
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Valor da prestação de contas maior que o total pago!' });
      return;
    }

    item.editavel = false;
    item['salvo'] = true;
    this.desabilitarEdicaoItem(false);
    this.callback.emit(this.lista);
  }

  public editarItem(item: ConvenioPrestacao) {
    this.entityTempItem = JSON.parse(JSON.stringify(item));

    this.entityItem = item;
    this.entityItem.usuario = this.login.usuario;
    this.entityItem.editavel = true;
    this.entityItem['salvo'] = true;
    this.desabilitarEdicaoItem(true);
    item.data_quitacao = new DateFormatPipe().transform(item.data_quitacao, []);
    item.data_prestacao = new DateFormatPipe().transform(item.data_prestacao, []);
    item.data_contabilizacao = new DateFormatPipe().transform(item.data_contabilizacao, []);

  }

  public cancelarItem(item: ConvenioPrestacao, index?: number) {
    if (item['salvo'] && item.id === this.entityTempItem?.id) {
      item.data_quitacao = this.entityTempItem.data_quitacao;
      item.valor_prestacao = this.entityTempItem.valor_prestacao;
      item.usuario = this.login.usuario;
      item.data_prestacao = this.entityTempItem.data_prestacao;
      item.convenio_situacao = this.entityTempItem.convenio_situacao;
      item.data_contabilizacao = this.entityTempItem.data_contabilizacao;

      this.entityItem = item;
      this.entityItem.editavel = false;
    } else {
      this.entity.itens.splice(index, 1);
    }
    this.desabilitarEdicaoItem(false);
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }


  public validaValorPrestacao(entity: ConvenioSituacao) {
    if (+(this.total_geral_pago - this.total_prestado_conta - +entity.valor_prestacao).toFixed(2) < 0) {
      toastr.warning('Valor da prestação de contas maior que o total pago!')
      entity.valor_prestacao = undefined
    }
  }

}
