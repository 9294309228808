import { Component, Injector } from '@angular/core';
import { BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, LoginContabil,Seguradora, FuncaoService } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SeguradoraService } from '../service/seguradora.service';

@Component({
  selector: 'app-seguradora-list',
  templateUrl: './seguradora-list.component.html'
})
export class SeguradoraListComponent extends BaseResourceListComponent<Seguradora, LoginContabil> {
  /**
   * Declaração de variáveis
   */
  public filtroSelecionado: any = 'NOME';
  public nomeSeguradora: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public funcaoService: FuncaoService,
    private seguradoraService: SeguradoraService) {
    super(seguradoraService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'orgao';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['id$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {};
  }

  protected afterInit(): void {}

  protected acaoRemover(model: Seguradora): Observable<Seguradora> {
    return null;
  }

  public obterParametros(): {} {
    let parametros = this.seguradoraService.obterFiltro(this.nomeSeguradora)

    parametros = Object.assign({}, parametros, this.condicoesGrid());
    parametros['orderBy'] = this.ordenacaoGrid();

    return parametros;
  }

  
  protected podeAlterar(item: Seguradora): boolean{
    return true;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'id' },
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'Nome para contato', coluna: 'nome_contato' },
      { titulo: 'Telefone', coluna: 'telefone' },
      { titulo: 'Celular', coluna: 'celular' },
      { titulo: 'CEP', coluna: 'cep' },
      { titulo: 'Endereço', coluna: 'endereco' },
      { titulo: 'Nº', coluna: 'numero' },
      { titulo: 'Bairro', coluna: 'bairro' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'orgao';
    this.seguradoraService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE SEGURADORAS`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem seguradoras', ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  public limparFiltros(todos: boolean) {
    this.filtroSelecionado = todos ? null : this.filtroSelecionado;
    this.nomeSeguradora = null;
  }
}
