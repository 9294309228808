import { Component, Injector, } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BaseResourceFormComponent,
  FichaReceita, LoginContabil, Receita, Empenho, FuncaoService, Recebimento, ReceitaPrevista
} from 'eddydata-lib';
import { EmpenhoService, FichaReceitaService, RecebimentoService, ReceitaPrevistaService } from 'administrativo-lib';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ReceitaService } from '../../planejamento/receita/service/receita.service';

@Component({
  selector: 'app-ficha-receita-view',
  templateUrl: './ficha-receita-view.component.html'
})
export class FichaReceitaViewComponent extends BaseResourceFormComponent<FichaReceita, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public arvReceita = {
    n1: "",
    n2: "",
    n3: "",
    n4: "",
    n1Nome: "",
    n2Nome: "",
    n3Nome: "",
    n4Nome: ""
  };
  public data: string;
  public tab: number = 0;
  public valorRecebido: number = 0;
  public valorAnulado: number = 0;
  public saldoAtual: number = 0;

  public colunaOrdenacao: string;
  public ascendente = true;

  public empenhos: Empenho[];
  public recebimento: Recebimento;
  public anuladas: Recebimento[];
  public vlSuplementado: number = 0;
  public vlReservado: number = 0;
  public vlAnulado: number = 0;
  public vlDotado: number = 0;
  public vlEmpenhado: number = 0;
  public vlPago: number = 0;
  public vlCompraAberto: number = 0;
  public vlRcmsAberto: number = 0;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected recebimentoService: RecebimentoService,
    protected injector: Injector,
    protected funcaoService: FuncaoService,
    protected fichaService: FichaReceitaService,
    protected receitaService: ReceitaService,
    protected receitaPrevistaService: ReceitaPrevistaService,
    protected empenhoService: EmpenhoService,
    private route: ActivatedRoute
  ) {
    super(new FichaReceita(), injector, FichaReceita.converteJson, fichaService);
    this.route.params.subscribe(params => this.data = params['data']);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      valor_orcado: 0,
      recurso: this.fb.group({
        codigo: [null, [Validators.required]],
        nome: [null, [Validators.required]]
      }),
      aplicacao: this.fb.group({
        codigo: [null, [Validators.required]],
        nome: [null, [Validators.required]]
      }),
      receita: this.fb.group({
        codigo: [null, [Validators.required]],
        nome: [null, [Validators.required]]
      }),
      acao: this.fb.group({
        codigo: [null, [Validators.required]],
        nome: [null, [Validators.required]]
      }),
      programa: this.fb.group({
        codigo: [null, [Validators.required]],
        nome: [null, [Validators.required]]
      }),
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'receita,recurso,aplicacao,aplicacao_variavel,orgao,receita.plano,previsoes' };
  }

  protected setCurrentActionRoute(): void {
    this.currentActionRoute = 'editar';
  }

  protected afterLoad() {
    // this.valorRecebido = this.recebimento.valor_recebido;
    this.arvReceita.n1 = this.entidade.receita.n1.padEnd(8, '0');
    this.arvReceita.n2 = (this.entidade.receita.n1 + this.entidade.receita.n2).padEnd(8, '0');
    this.arvReceita.n3 = (this.entidade.receita.n1 + this.entidade.receita.n2 + this.entidade.receita.n3).padEnd(8, '0');
    this.arvReceita.n4 = (this.entidade.receita.n1 + this.entidade.receita.n2 + this.entidade.receita.n3 + this.entidade.receita.n4).padEnd(8, '0');

    if (!this.data) {
      this.data = this.funcaoService.converteDataSQL(new Date());
    }

    this.receitaService.filtrar(1, -1, {
      'exercicio.id': this.login.exercicio.id,
      'OR': `codigo=${this.arvReceita.n1};!;!;codigo=${this.arvReceita.n2};!;!;codigo=${this.arvReceita.n3};!;!;codigo=${this.arvReceita.n4}`
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        res.content.forEach((receita: Receita) => {
          switch (receita.codigo) {
            case this.arvReceita.n1:
              this.arvReceita.n1Nome = receita.nome;
              break;
            case this.arvReceita.n2:
              this.arvReceita.n2Nome = receita.nome;
              break;
            case this.arvReceita.n3:
              this.arvReceita.n3Nome = receita.nome;
              break;
            case this.arvReceita.n4:
              this.arvReceita.n4Nome = receita.nome;
              break;
          }
        });
      })

    this.carregarGrid('receitas')

    this.carregarGrid('anulado')

    this.receitaPrevistaService.extendido(1, -1, {
      'evento': 'PRA',
      'ficha.id': this.entidade.id
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.vlSuplementado = res.content.reduce((acc: number, pra: ReceitaPrevista) => acc + +pra.valor_previsto, 0)
      })
  }

  public reordenar(coluna: string, toggle?: boolean, tipo?: string) {
    if (!coluna) return;

    if (!toggle) this.ascendente = true;
    else this.ascendente = !this.ascendente;

    this.colunaOrdenacao = coluna;
    this.carregarGrid(tipo, `${this.colunaOrdenacao}$${this.ascendente ? "ASC" : "DESC"}`);
  }

  public carregarGrid(tipo: string, orderBy?: string) {

    if (tipo === 'receitas') {

      this.recebimentoService.extendido(1, -1, {
        'ficha.id': this.entidade.id,
        'orgao.id': this.login.orgao.id,
        'especie$ne': 'ROA',
        'data_recebimento$le': this.data,
        'exercicio.id': this.login.exercicio.id,
        ['relations']: 'ficha,ficha.recurso,ficha.aplicacao,ficha.receita,conta,conta.banco',
        orderBy: orderBy ? orderBy : 'data_recebimento$DESC'
      }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.recebimento = res.content;
          this.valorRecebido = res.content.reduce((acc: number, recebido: Recebimento) => acc + +recebido.valor_recebido, 0)
        });
    }

    if (tipo === 'anulado') {
      this.recebimentoService.extendido(1, -1, {
        'ficha.id': this.entidade.id,
        'orgao.id': this.login.orgao.id,
        'especie': 'ROA',
        'data_recebimento$le': this.data,
        'exercicio.id': this.login.exercicio.id,
        ['relations']: 'ficha,ficha.recurso,ficha.aplicacao,ficha.receita,conta,conta.banco',
        orderBy: orderBy ? orderBy : 'data_recebimento$DESC'
      }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.anuladas = res.content;
          this.valorAnulado = res.content.reduce((acc: number, anulado: Recebimento) => acc + +anulado.valor_recebido, 0)
        });
    }

  }

  public selecionarTab(tab: number) {
    this.tab = tab;
  }

  protected afterInit(): void {
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: FichaReceita): void {
  }

}
