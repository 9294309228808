// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Legislacao } from './legislacao.model';
import { Expediente } from './expediente.model';

export class ExpedienteLegislacao extends BaseResourceModel {
    constructor(
      public legislacao?: Legislacao,
      public expediente?: Expediente,
      public editavel?: boolean
    ) {
      super();
    }
    static converteJson(json: any): ExpedienteLegislacao {
      return Object.assign(new ExpedienteLegislacao(), json);
    }
}
