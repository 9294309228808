import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { Cidade } from '../geo/cidade.model';
import { PublicacaoEnvio } from "./publicacao-envio.model";

export class Publicacao extends BaseResourceModel {
  constructor(
    public id?: number,

    public numero?: string,
    public data_publicacao?: Date,
    public data_agendamento?: Date,
    public titulo?: string,
    public edicao?: string,
    public ano?: string,
    public publicar?: boolean,
    public assinado?: boolean,
    public extra?: boolean,
    public cancelado?: boolean,
    public impressao_2colunas?: boolean,
    public edicao_extra?: boolean,
    public caminho?: string,
    public tipo?: string,
    public documento?: string,
    public tamanho?: number,
    public nome?: string,
    public cidade?: Cidade,
    public usuario?: Usuario,
    public envios?: PublicacaoEnvio[]) {
    super();
  }
  static converteJson(json: any): Publicacao {
    return Object.assign(new Publicacao(), json);
  }
}
