import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Login } from '../entidade/login/login';
import { GlobalService } from './global.service';
import jwt_decode from 'jwt-decode';
import * as bcrypt from 'bcryptjs';
import { Router } from '@angular/router';
import { LicitacaoStorage } from '../entidade/licitacao/licitacao-storage.model';

@Injectable({
  providedIn: 'root'
})
export class AudespFasesService {

  protected http: HttpClient;
  protected homologacao: string = 'https://audesp.tce.sp.gov.br/api';
  protected producao: string = 'https://audesp.tce.sp.gov.br/api';

  protected testes: boolean = true;
  public login: Login = new Login();
  private api = 'audesp-fases';
 
  constructor(
    protected injector: Injector
  ) {
    this.http = injector.get(HttpClient);
    this.login = GlobalService.obterSessaoLogin();
  }
  private retornaUrl() {
    if (this.testes) {
        return this.homologacao;
    } else {
        return this.producao;
    }
}

private gerarHeader(): HttpHeaders {
    let token = this.login.token_audesp;
    if (!token) {
        token = '';
    }
    if (token.includes('Bearer')) {
        token = token.substring(7);
    }
    if (token.includes('JWT ')) {
        token = token.substring(4);
    }

    return new HttpHeaders({ 'Authorization': token });
}

private formatarToken(): string {
    let token = this.login.token_audesp;
    if (!token) {
        token = '';
    }
    if (token.includes('Bearer')) {
        token = token.substring(7);
    }
    if (token.includes('JWT ')) {
        token = token.substring(4);
    }

    return token;
}

public loginUsuario(login: string, senha: string): Observable<any> {
    return this.http.post<any[]>(`${this.retornaUrl()}/login`, '', { headers: new HttpHeaders({ 'x-authorization': `${login}:${senha}`})}).pipe(map(res => res));
}

public loginAudesp(login: string, senha: string): Observable<any> {
    const body = { login: login, senha: senha, testes: this.testes };
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/login-audesp`, body, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
    );
}

public consultarSituacao(protocolo: string) {
    return this.http.get(`${this.retornaUrl()}/consulta/${protocolo}`);
}

public inserirContratacao(licitacoes: Array<number>): Observable<any> {
    let token = this.formatarToken();
    const body = { token: token, orgao: this.login.orgao, exercicio: this.login.exercicio, idAUDESP: this.login.dados_audesp?.id, licitacoes: licitacoes, testes: this.testes };
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/enviar-licitacoes`, body, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
    );
}

public enviarArquivoLicitacao(arquivo: LicitacaoStorage): Observable<any> {
    const body = { token: this.login.token_audesp, arquivo: arquivo, testes: this.testes, orgao: this.login.orgao, exercicio: this.login.exercicio, idAUDESP: this.login.dados_audesp?.id };
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/enviar-licitacao-arquivo`, body, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
    );

}

protected httpOptions(notProgress?: boolean) {
    this.login = GlobalService.obterSessaoLogin();
    const header = {
        'Content-Type': 'application/json',
    };
    if (this.login)
        header['Authorization'] = this.login.token;
    if (notProgress)
        header['Not-Progress'] = 'true';
    const teste = {
        headers: new HttpHeaders(this.adicionarCriptografia(header)),
        reportProgress: true
    };
    return teste;
}

protected adicionarCriptografia(header: {}) {
    this.login = GlobalService.obterSessaoLogin();
    if (this.login && this.login.token) {
        const tokenJson = jwt_decode(this.login.token) as any;
        const agora = new Date().getTime();
        const comparar = ((agora - +tokenJson.iat) / +tokenJson.id).toFixed(0);
        const criptografado = bcrypt.hashSync(comparar, tokenJson.chave);

        header['agora'] = agora + '';
        header['codigo'] = criptografado;

    }
    return header;
}

  protected handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      this.login = GlobalService.obterSessaoLogin();

      if (this.login && this.login.usuario.sistema === 'transparencia') {
        this.login = null;
        GlobalService.limparSessaoLogin();
        location.reload();
      } else {
        toastr.warning('Sua sessão expirou, por favor faça o login novamente!', 'Sessão expirada');
        sessionStorage.clear();
        this.injector.get(Router).navigate(['/login']);
      }
    } else {
      return throwError(error);
    }
  }
}

