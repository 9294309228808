import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, ContaBancariaSaldo, Page } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ContaBancariaSaldoService extends BaseResourceService<ContaBancariaSaldo> {

  constructor(
    protected injector: Injector
  ) {
    super(`contas-bancarias-saldos`, injector);
  }

  public obterPorConta(id: number): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar/?orderBy=exercicio.ano$DESC&relations=exercicio,plano&conta_id=${id}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
