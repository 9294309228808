import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { CargoHistoricoLstComponent } from './cargo-historico-lst/cargo-historico-lst.component';
import { CargoHistoricoRoutingModule } from './cargo-historico-routing.module';


@NgModule({
  declarations: [
    CargoHistoricoLstComponent
  ],
  imports: [
    CommonModule,
    CargoHistoricoRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
    IMaskModule,
    ToastModule
  ]
})
export class CargoHistoricoModule { }
