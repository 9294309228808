import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { DateFormatPipe, FuncaoService, Login, ProgressoService, ReservaDotacaoEstorno, ReservaDotacaoItem } from "eddydata-lib";
import { ReservaDotacaoEstornoService } from "../service/reserva-dotacao-estorno.service";
import { DatePipe } from "@angular/common";
import * as toastr from 'toastr';
import { EscrituracaoService } from "projects/contabil/src/app/escrituracao/service/escrituracao.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

declare var $: any;

@Component({
  selector: 'app-reserva-dotacao-estorno-dlg',
  templateUrl: './reserva-dotacao-estorno-dlg.component.html'
})
export class ReservaDotacaoEstornoDlg implements OnInit, OnChanges {

  @Input() public login: Login;
  @Input() public reserva_item: ReservaDotacaoItem;
  @Input() public estorno: ReservaDotacaoEstorno;
  @Output() atualizarListasEstorno: EventEmitter<void> = new EventEmitter();

  protected unsubscribe: Subject<void> = new Subject();


  public travaEstorno = false;

  public dataUltimoEnvioAudesp: string = null;

  constructor(
    public funcaoService: FuncaoService,
    protected escrituracaoService: EscrituracaoService,
    protected progressoService: ProgressoService,
    public reservaEstornoService: ReservaDotacaoEstornoService
  ) { }

  public opcoes: {
    adiantamento: boolean, contrato: boolean, convenio: boolean, credito: boolean,
    empenho: boolean, liquidacao: boolean, liquidacaoResto: boolean, pagamento: boolean,
    pagamentoExtra: boolean, pagamentoResto: boolean, pagamentoRetencao: boolean,
    previsaoDespesa: boolean, previsaoReceita: boolean, recebimento: boolean,
    recebimentoExtra: boolean, reservaDotacao: boolean, retencao: boolean, empenhoExtra: boolean,
    retencaoExtra: boolean, retencaoResto: boolean, transferenciaBanco: boolean, preliquidacao: boolean, preliquidacaoresto: boolean,
    transferenciaPrevista: boolean, variacao: boolean, prestacaoConta: boolean, mesTreze?: boolean, aberturaResto: boolean,
    encerramentoResto: boolean, bloqueioJudicial: boolean, cancelamentoRestos: boolean,
    periodo: Date | number, opcao: '1' | '2', orgaoId?: number, exercicioId?: number
  } = {
      adiantamento: false, contrato: false, convenio: false, credito: false,
      empenho: false, liquidacao: false, liquidacaoResto: false, pagamento: false,
      pagamentoExtra: false, pagamentoResto: false, pagamentoRetencao: false,
      previsaoDespesa: false, previsaoReceita: false, recebimento: false,
      recebimentoExtra: false, reservaDotacao: false, retencao: false, empenhoExtra: false,
      retencaoExtra: false, retencaoResto: false, transferenciaBanco: false, preliquidacao: false, preliquidacaoresto: false,
      transferenciaPrevista: false, variacao: false, prestacaoConta: false, encerramentoResto: false, bloqueioJudicial: false,
      cancelamentoRestos: false, periodo: new Date().getUTCMonth(), opcao: '2',
      mesTreze: false, aberturaResto: false
    };


  ngOnInit(): void {
    this.dataUltimoEnvioAudesp = new DatePipe('pt').transform(this.login['ultimoAudesp'], 'dd/MM/yyyy');
    if (!this.estorno) {
      this.estorno = new ReservaDotacaoEstorno();
    } else {
      this.estorno.data_adjudicacao = new DateFormatPipe().transform(this.estorno.data_adjudicacao, []);
      this.estorno.data_estorno = new DateFormatPipe().transform(this.estorno.data_estorno, []);
    }
    this.estorno.data_baixa = new DateFormatPipe().transform(this.reserva_item.data_reserva, []);

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reserva_item?.currentValue !== changes.reserva_item?.previousValue && this.reserva_item?.id) {
      if (!this.estorno) {
        this.estorno = new ReservaDotacaoEstorno();
      } else {
        this.estorno.data_adjudicacao = new DateFormatPipe().transform(this.estorno.data_adjudicacao, []);
        this.estorno.data_estorno = new DateFormatPipe().transform(this.estorno.data_estorno, []);
      }
      this.estorno.data_baixa = new DateFormatPipe().transform(this.reserva_item.data_reserva, []);
    }
  }

  public async antesSalvar(item: ReservaDotacaoEstorno): Promise<void> {
    if (!item.data_estorno || !item.valor_estorno || !item.data_baixa || !item.observacao) {
      throw new Error('Campos obrigatórios não preenchidos!');
    }
    if (!this.podeAlterarAudesp(this.estorno.data_estorno, this.login)) {
      throw new Error('Data de estorno não pode ser anterior a data do último Audesp!');
    }
    if (+item.valor_adjudicacao > +this.reserva_item.valor_reservado) {
      throw new Error('Valor de adjudicação não pode ser maior que o valor reservado!');
    }
    if (+item.valor_estorno > +this.reserva_item.valor_reservado) {
      throw new Error('Valor de estorno não pode ser maior que o valor reservado!');
    }
    if ((+item.valor_adjudicacao && !item.data_adjudicacao) || (+item.data_adjudicacao && !item.valor_adjudicacao)) {
      throw new Error('Preencha todos os dados da adjudicação!');
    }
  }

  public async salvarEstorno(): Promise<void> {
    try {
      await this.antesSalvar(this.estorno);
      this.estorno.data_estorno = new DateFormatPipe().transform(this.estorno.data_estorno, []);
      this.estorno.data_adjudicacao = new DateFormatPipe().transform(this.estorno.data_adjudicacao, []);
      this.estorno.reserva_item = this.reserva_item
      this.estorno.data_baixa = new DateFormatPipe().transform(this.estorno.data_baixa ?? new Date(), []);
      delete this.estorno.reserva_item.estornos;
      this.estorno = Object.assign({}, await this.reservaEstornoService.atualizar(this.estorno).toPromise());
      this.atualizarListasEstorno.emit();
      toastr.success(`Estorno inserido com sucesso`);
      $('#reservaEstornoModal').modal('hide');
    } catch (err) {
      console.error(err);
      toastr.error(err.message, 'Erro ao salvar estorno')
    }

    this.opcoes.reservaDotacao = true
    this.opcoes.exercicioId = this.login.exercicio.id
    this.opcoes.orgaoId = this.login.orgao.id
    this.opcoes.periodo = new Date(this.estorno.data_estorno).getMonth() + 1;

    this.escrituracaoService.escriturar(this.opcoes)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(idTransacao => {
        this.progressoService.show(idTransacao);
      },
        error => this.acaoErro(error));

  }

  protected acaoErro(error: any) {
    console.log('Erro disparado: ', error);
    toastr.options.timeOut = 10000;
    toastr.options.closeButton = true;
    toastr.options.tapToDismiss = false;
    console.log(error);
    if (error.error && error.error.payload) {
      toastr.error(error.error.payload);
    } else {
      toastr.error('Ocorreu um erro ao processar a sua solicitação');
    }
  }

  // unsubscribe(unsubscribe: any): import("rxjs").OperatorFunction<any, any> {
  //   throw new Error("Method not implemented.");
  // }

  public calcularEstorno(): void {
    if (this.estorno.valor_adjudicacao) {
      this.travaEstorno = true;
      this.estorno.valor_estorno = this.reserva_item.valor_reservado - +this.estorno.valor_adjudicacao;
    } else {
      this.travaEstorno = false;
      // this.estorno.valor_estorno = null;
    }
  }

  private podeAlterarAudesp(data: Date, login: any): boolean {
    if (!data) {
      return true;
    }
    if (login['ultimoAudesp'] && data) {
      data = new Date(data);
      const mes = +login['ultimoAudesp'].split('-')[1];
      const ano = +login['ultimoAudesp'].split('-')[0];
      if ((data.getFullYear() < ano || (data.getFullYear() === ano && (+data.getMonth() + 1) <= mes)) && mes < 12)
        return false;
    }
    return true;
  }

}