import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ProdutoDlgModule } from 'almoxarifado-lib';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { ToastModule } from 'primeng/toast';
import { GrupoServicoModule } from '../grupo-servico/grupo-servico.module';
import { ProdutoModule } from '../produto/produto.module';
import { AjusteConversaoServicoDlgComponent } from './ajuste-conversao-servico-dlg/ajuste-conversao-servico-dlg.component';
import { ServicoFormComponent } from './servico-form/servico-form.component';
import { ServicoLicitacaoComponent } from './servico-licitacao/servico-licitacao.component';
import { ServicoListComponent } from './servico-list/servico-list.component';
import { ServicoRoutingModule } from './servico-routing.module';
import { ServicoViewComponent } from './servico-view/servico-view.component';

@NgModule({
  declarations: [ServicoListComponent, ServicoFormComponent, ServicoViewComponent, AjusteConversaoServicoDlgComponent, ServicoLicitacaoComponent],
  exports: [AjusteConversaoServicoDlgComponent],
  imports: [
    CommonModule,
    ServicoRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    ProdutoModule,
    GrupoServicoModule,
    ConfirmDialogModule,
    ProdutoDlgModule,
    FieldsetModule
  ]
})
export class ServicoModule { }
