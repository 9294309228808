// tslint:disable: variable-name

import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Exercicio } from '../comum/exercicio.model';

export class ProgramacaoFinanceira extends BaseResourceModel {
  constructor(
    public id?: number,
    public tipo?: string,
    public percentual_mes1?: number,
    public percentual_mes2?: number,
    public percentual_mes3?: number,
    public percentual_mes4?: number,
    public percentual_mes5?: number,
    public percentual_mes6?: number,
    public percentual_mes7?: number,
    public percentual_mes8?: number,
    public percentual_mes9?: number,
    public percentual_mes10?: number,
    public percentual_mes11?: number,
    public percentual_mes12?: number,
    public orgao?: Orgao,
    public exercicio?: Exercicio,
    public data_cadastro?: Date,
    public data_alteracao?: Date
  ) {
    super();
  }

  static converteJson(json: any): ProgramacaoFinanceira {
    return Object.assign(new ProgramacaoFinanceira(), json);
  }
}
