import { BaseResourceModel, ContaBancaria, Orgao, Recurso } from "eddydata-lib";
import { ChequeEmpenho } from "./cheque-empenho.model";
import { ChequeItem } from "./cheque-item.model";

export class EmissaoCheque extends BaseResourceModel {
  constructor(
    public id?: number,
    public aux?: number,
    public data_emissao?: Date,
    public historico?: string,
    public orgao?: Orgao,
    public conta?: ContaBancaria,
    public recurso?: Recurso,
    public cheque_empenho?: ChequeEmpenho[],
    public cheque_item?: ChequeItem[]) {
    super();
  }
  static converteJson(json: any): EmissaoCheque {
    return Object.assign(new EmissaoCheque(), json);
  }
}