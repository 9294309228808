import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { tsXLXS } from 'ts-xlsx-export';
import { DateFormatPipe } from '../../components/pipe/date-format.pipe';
import { Coluna } from '../../components/types';
import { Auditoria } from '../../entidade/comum/auditoria.model';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { BaseResourceListComponent, Filtro } from '../../models/base-resource-list';
import { GlobalService } from '../../util/global.service';
import { AuditoriaService } from '../service/auditoria.service';
import { FuncaoService } from '../../util/funcao.service';
import { EddyAutoComplete } from '../../models/form-components';

@Component({
  selector: 'lib-auditoria-lst',
  templateUrl: './auditoria-lst.component.html'
})
export class AuditoriaLstComponent extends BaseResourceListComponent<Auditoria, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public ptBR: any;
  public datepipe: DatePipe;
  public alvoAutoComplete: EddyAutoComplete<Auditoria>
  public listAlvos: { classe: string, nome: string }[];
  public listAlvosAutoC: { classe: string, nome: string }[];
  public listOperacoes: any[];

  public data1: Date;
  public data2: Date;
  public alvo //: { classe: string, nome: string };
  public operacao: 'T' | 'R' | 'I' | 'A' = 'T';

  public usuarioNull = 'Procedimento automático';

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private globalService: GlobalService,
    private auditoriaService: AuditoriaService,
    private funcaoService: FuncaoService) {
    super(auditoriaService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'usuario';
  }

  protected condicoesGrid(): {} {
    const parametros = {};
    this.datepipe = new DatePipe('pt');
    let dt1 = this.datepipe.transform(this.data1, 'yyyy-MM-dd');
    let dt2 = this.datepipe.transform(this.data2, 'yyyy-MM-dd');

    parametros['usuario.orgao_id'] = this.login.orgao.id

    parametros['data_cadastro$ge'] = dt1;
    parametros['data_cadastro$le'] = dt2;

    sessionStorage.setItem('/auditoria_data1', dt1);
    sessionStorage.setItem('/auditoria_data2', dt2);

    if (this.operacao !== 'T') {
      parametros['operacao'] = this.operacao;
      sessionStorage.setItem('/auditoria_operacao', this.operacao);
    }

    if (this.alvo) {
      parametros['alvo'] = this.alvo.alvo
      sessionStorage.setItem('/auditoria_alvo', this.alvo.alvo)
    }
    // if (this.alvo || sessionStorage.getItem('/auditoria_alvo')) {
    //   if (this.alvo) {
    //     parametros['alvo'] = this.alvo.alvo;
    //     sessionStorage.setItem('/auditoria_alvo', this.alvo.alvo);
    //   } else {
    //     parametros['alvo'] = sessionStorage.getItem('/auditoria_alvo');
    //     sessionStorage.setItem('/auditoria_alvo', sessionStorage.getItem('/auditoria_alvo'));
    //   }
    // }
    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['data_cadastro$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      text: ['usuario.nome'],
      number: ['alvo_id']
    };
  }


  public beforeInit(): void {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    const dt1: string = sessionStorage.getItem('/auditoria_data1');
    const dt2: string = sessionStorage.getItem('/auditoria_data2');
    if (!dt1) {
      this.data1 = new DateFormatPipe().transform(this.datepipe.transform(new Date(`01/01/${new Date().getFullYear()}`), 'yyyy-MM-dd'), []);
    } else {
      this.data1 = new DateFormatPipe().transform(dt1, []);
    }
    if (!dt2) {
      this.data2 = new DateFormatPipe().transform(this.datepipe.transform(new Date(), 'yyyy-MM-dd'), []);
    } else {
      this.data2 = new DateFormatPipe().transform(dt2, []);
    }
    const alvo: string = sessionStorage.getItem('/auditoria_alvo');
    if (alvo)
      if (!this.listAlvos) {
        this.auditoriaService.getAlvos().pipe(takeUntil(this.unsubscribe))
          .subscribe(
            lista => {
              this.listAlvos = lista;
              this.alvo = this.listAlvos.find((c) => c.classe === alvo);
            }, () => alert('erro ao retornar lista')
          );
      } else {
        this.alvo = this.listAlvos.find((c) => c.classe === alvo);
      }
    const operacao: any = sessionStorage.getItem('/auditoria_operacao');
    if (operacao)
      this.operacao = operacao;
  }

  protected afterInit(): void {
    this.listOperacoes = [
      { id: 'T', nome: 'TODAS', class: '' },
      { id: 'I', nome: 'INSERÇÃO', class: '' },
      { id: 'A', nome: 'ALTERAÇÃO', class: '' },
      { id: 'R', nome: 'REMOÇÃO', class: '' },
    ];

    this.auditoriaService.getAlvos().pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.listAlvos = lista;
        }, () => alert('erro ao retornar lista')
      );

    this.carregarAutoCompletes()
  }

  protected acaoRemover(model: Auditoria): Observable<Auditoria> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Alvo', coluna: 'alvo' },
      { titulo: 'Usuario', coluna: 'usuario.nome' },
      { titulo: 'Antes', coluna: 'antes' },
      { titulo: 'Depois', coluna: 'depois' },
      { titulo: 'Data Cadastro', coluna: 'data_cadastro' }
    ];
  }

  public imprimirPDF(): void {
    const parametros = this.obterParametros();
    parametros['relations'] = 'usuario';
    this.auditoriaService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.imprimir('LISTAGEM DE AUDITORIA',
            this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
            'Listagem auditoria', ['auto', 'auto', 250, 250, 'auto'], lista.content.map((c) => {
              if (!c.usuario)
                c.usuario = { nome: this.usuarioNull };
              c.antes = c.antes ? JSON.stringify(c.antes) : '';
              c.depois = c.depois ? JSON.stringify(c.depois) : '';
              return c;
            }));
        },
        () => alert('erro ao retornar lista')
      );
  }

  public exportarXLSX() {
    const parametros = this.obterParametros();
    parametros['relations'] = 'usuario';
    this.auditoriaService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          const listaItens = new Array();
          for (const item of lista.content) {
            const entity = {
              alvo: item.alvo,
              antes: item.antes ? JSON.stringify(item.antes) : null,
              depois: item.depois ? JSON.stringify(item.depois) : null,
              usuario: item.usuario?.nome ? item.usuario.nome : 'Não informado'
            };
            listaItens.push(entity);
          }
          tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile('auditoria');
        },
        () => alert('erro ao retornar lista')
      );
  }

  public exportarDocx() {
    if (!this.baseResourceService) return;

    const nome = this.baseResourceService.retornarRota().startsWith("/")
      ? this.baseResourceService.retornarRota().substr(1)
      : this.baseResourceService.retornarRota();

    const colunas = this.colunasRelatorio() as Coluna[];
    const parametros = this.obterParametros();
    parametros['relations'] = 'usuario';
    this.auditoriaService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          const listaItens = new Array();
          for (const item of lista.content) {
            const entity = {
              alvo: item.alvo,
              antes: item.antes ? JSON.stringify(item.antes) : null,
              depois: item.depois ? JSON.stringify(item.depois) : null,
              usuario: item.usuario?.nome ? item.usuario.nome : 'Não informado'
            };
            listaItens.push(entity);
          }
          new FuncaoService().exportar("docx", listaItens, nome, colunas);
        },
        () => alert('erro ao retornar lista')
      );
  }



  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private carregarAutoCompletes() {
    this.alvoAutoComplete = new EddyAutoComplete(null, this.auditoriaService, 'id', ['alvo'], {}, { text: ['alvo'] }, null, null, null)
  }

  onChangeOperation(value: any, operacao: 'T' | 'R' | 'A' | 'I') {
    if (operacao) {
      this.operacao = operacao;
      this.preencherGrid();
    }
  }

  onChangeAlvo(event: any) {
    let query = event.query;
    this.listAlvosAutoC = this.listAlvos
      .filter((a) => a.nome.toLowerCase()
        .indexOf(query.toLowerCase()) === 0);
  }

  public async filtrarAuditoriaAlvo(event: any) {
    let query = event.query;

    this.alvoAutoComplete.lista = await this.auditoriaService.alvosAuditoria(query).toPromise()
  }

}
