export enum TagsModeloRelatorio {
    COMISSAO_INICIO = 'Comissão - Início de Vigência',
    COMISSAO_MEMBROS = 'Comissão - Demais Membros',
    COMISSAO_NOME = 'Comissão - Nome',
    COMISSAO_PORTARIA = 'Comissão - Portaria',
    COMISSAO_PREGOEIRO = 'Comissão - Pregoeiro',
    COMISSAO_PRESIDENTE = 'Comissão - Presidente',
    COMISSAO_PUBLICACAO = 'Comissão - Data de Publicação',
    COMISSAO_SECRETARIO = 'Comissão - Secretário',
    COMISSAO_TERMINO = 'Comissão - Término de Vigência',
    DATA_ABERTURA = 'Data de Abertura',
    DATA_ASSINATURA = 'Data de Assinatura',
    DATA_EDITAL = 'Data do Edital',
    DATA_HOMOLOGACAO = 'Data de Homologação',
    DATA_INICIO = 'Data de Início',
    DATA_JULGAMENTO = 'Data de Julgamento',
    DATA_PARECER = 'Data do Parecer',
    DATA_PUBLICACAO = 'Data de Publicação',
    DATA_TERMINO = 'Data de Término',
    INICIO_PROPOSTA = 'Início da proposta',
    TERMINO_PROPOSTA = 'Término da proposta',
    DATA_ESCLARECIMENTO = 'Data Esclarecimento',
    FICHA_DESPESA = 'Ficha de despesa',
    JUSTIFICATIVA = 'Justificativa do Processo',
    MODALIDADE = 'Nome da Modalidade',
    NUMERO = 'Número da Licitação',
    OBJETO = 'Objeto do Processo',
    PRAZO_ENTREGA = 'Prazo de Entrega',
    PRAZO_PAGAMENTO = 'Prazo de Pagamento',
    PROCESSO = 'Número do Processo',
    SETOR_NOME = 'Setor',
    TIPO_CONTRATACAO = 'Tipo de Contratação',
    VALOR_ESTIMADO = 'Valor estimado',
    VALOR_LICITACAO = 'Valor licitado',


    DATA_ASSINATURA_CONTRATO = 'Data Assinatura Contrato',
    DATA_INICIO_CONTRATO = 'Data Início Contrato',
    DATA_TERMINO_CONTRATO = 'Data Término Contrato',
    FORNECEDOR_CPF = 'Fornecedor - CPF/CNPJ',
    FORNECEDOR_NOME = 'Fornecedor - Nome',
    NUMERO_CONTRATO = 'Número do Contrato',
    OBJETO_CONTRATO = 'Objeto do Contrato',
    PRAZO_CONTRATO = 'Prazo do Contrato',
    VALOR_CAUCAO = 'Valor de Caução',
    VALOR_CONTRATO = 'Valor do Contrato',
    VALOR_GARANTIA = 'Valor de garantia',

    UNIAO_NOME = 'União/Autarquia - Nome',
    UNIAO_ENDERECO = 'União/Autarquia - Endereço',
    UNIAO_CIDADE = 'União/Autarquia - Cidade',
    UNIAO_ESTADO = 'União/Autarquia - UF',
    UNIAO_CNPJ = 'União/Autarquia - CNPJ',
    PREFEITO_NOME = 'Prefeito - Nome',
    PREFEITO_CPF = 'Prefeito - CPF',

    SECRETARIA_NOME = 'Secretaria',
    SECRETARIO_NOME = 'Secretário - Nome',
    SECRETARIO_CPF = 'Secretário - CPF',
    SECRETARIO_EMAIL = 'Secretário - E-mail',

    MATRICULA_COMISSAO = 'Matricula Funcional do Representante da Comissão',

    FORNECEDOR_ENDERECO = 'Fornecedor - Endereço',
    FORNECEDOR_NUMERO_ENDERECO = 'Fornecedor - Número do Endereço',
    FORNECEDOR_COMPLEMENTO_ENDERECO = 'Fornecedor - Complemento do Endereço',
    FORNECEDOR_BAIRRO = 'Fornecedor - Bairro',
    FORNECEDOR_CEP = 'Fornecedor - CEP',
    FORNECEDOR_CIDADE_UF = 'Fornecedor - Cidade e UF',
    FORNECEDOR_CIDADE = 'Fornecedor - Cidade',
    FORNECEDOR_ESTADO = 'Fornecedor - UF',
    FORNECEDOR_REPRESENTANTE = 'Fornecedor - Representante',
    FORNECEDOR_REPRESENTANTE_FUNCAO = 'Fornecedor - Função Representante',
    FORNECEDOR_TELEFONE = 'Fornecedor - Telefone',
    FORNECEDOR_EMAIL = 'Fornecedor - E-mail',

    ADITIVO_DATA_ASSINATURA = 'Aditivo - Data Assinatura',
    ADITIVO_DATA_INICIO = 'Aditivo - Data Início',
    ADITIVO_DATA_TERMINO = 'Aditivo - Data Término',
    ADITIVO_TIPO = 'Aditivo - Tipo',
    ADITIVO_FINALIDADE = 'Aditivo - Finalidade',
    ADITIVO_NUMERO = 'Aditivo - Número',
    ADITIVO_VALOR_TOTAL = 'Aditivo - Valor Total',

    VALOR_MENSAL = 'Valor Mensal',
    INDICE_REAJUSTE = 'Índice de Reajuste',
    PRAZO_PRORROGACAO = 'Prazo de Prorrogação',
    PRAZO_RESPOSTA = 'Prazo de Resposta',
    PRAZO_TERMINO = 'Prazo de Término da Vigência',
    PRAZO_MULTA = 'Prazo de Encaminhar Multa',
    MULTA_MORATORIA = 'Multa Moratória',
    DIAS_VIGENCIA_CONTRATO = 'Dias da Vigência da Contratação',
    ASSINATURA_COMISSAO = 'Assinatura do Representante da Comissão',
    ASSINATURA_FAVORECIDO = 'Assinatura do Representante do Favorecido',
    LOCAL_DATA = 'Local e Data',
    FISCAL = 'Fiscal',
    CPF_FISCAL = 'CPF do Fiscal',
    TESTEMUNHA = 'Testemunha',
    CPF_TESTEMUNHA = 'CPF da Testemunha',
    TIPO_CONTRATO = 'Tipo de Contrato',
    RESPONSAVEL_CONTRATADO = 'Responsável Contratado',
    CPF_CONTRATADO = 'CPF do Responsável Contratado',
    EMAIL_CONTRATADO = 'E-mail do Responsável Contratado',
    RESPONSAVEL_CONTRATANTE = 'Responsável Contratante',
    CPF_RESPONSAVEL_CONTRATANTE = 'CPF do Responsável Contratante',


}
