import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { ContratoItem } from './contrato-item.model';

// tslint:disable: variable-name
export class ContratoReajuste extends BaseResourceModel {
  constructor(
    public data_reajuste?: Date,
    public data_contabilizacao?: Date,
    public especie?: 'R' | 'E',
    public observacao?: string,
    public reajuste_valor?: number,
    public reajuste_valor_total?: number,
    public ajuste_saldo?: boolean,
    public contrato_item?: ContratoItem,
    public usuario?: Usuario,
    public saldo?: number, // atributo apenas de consulta
  ) {
    super();
  }
  static converteJson(json: any): ContratoReajuste {
    return Object.assign(new ContratoReajuste(), json);
  }
}
