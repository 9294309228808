import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, OrdemPagamentoItemConta } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class OrdemPagamentoContaService extends BaseResourceService<OrdemPagamentoItemConta> {

  constructor(
    protected injector: Injector
  ) {
    super(`ordens-pagamento-item-conta`, injector);
  }
}
