import { Component, OnInit, Input, AfterViewInit, OnDestroy, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { switchMap, takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { Subject } from 'rxjs';
import { Empenho, Login, GlobalService, Compra, CompraItem, FuncaoService } from 'eddydata-lib';
import { EmpenhoService, LiquidacaoService } from 'administrativo-lib';
import { CompraItemService, CompraService } from 'compra-lib';
import { Inplace } from 'primeng/inplace';
import { OrdemPagamentoItemService } from '../../tesouraria/service/ordem-item-pagamento.service';

declare var $: any;

@Component({
  selector: 'lib-empenho-anulacao',
  templateUrl: './empenho-anulacao.component.html'
})
export class EmpenhoAnulacaoComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  protected datepipe: DatePipe;
  public ptBR: any;
  public data: Date;
  public valor: number;
  public compra: Compra;
  public historico: string;
  public total: number = 0;
  public totalOriginal: number = 0;
  public totalParcelado = 0;
  public totalEmpenhado = 0;
  public totalPago = 0;
  public saldo = 0;
  public totalRetido = 0;
  public anularRetencoes: boolean = false;
  public idLiquidacao: number = 0;
  public diferencaEmpenhoCompra: number = 0;
  public listAnulacoes: Array<Empenho>;
  protected unsubscribe: Subject<void> = new Subject();
  public totalCalculado: number = 0;

  @Output() onFinalizar: EventEmitter<void> = new EventEmitter();

  @ViewChild('inplaceQuant') inplaceQuant: Inplace;
  @ViewChild('inplaceValor') inplaceValor: Inplace;
  @ViewChild('inplaceTotal') inplaceTotal: Inplace;

  @Input() login: Login;
  @Input() empNumero: number;
  @Input() empenhoId: number;
  @Input() compraId: number;
  @Input() empenho: any;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  imaskConfigServico = {
    mask: Number,
    scale: 5,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected liquidacaoService: LiquidacaoService,
    protected itemService: OrdemPagamentoItemService,
    private funcaoService: FuncaoService,
    private globalService: GlobalService,
    private empenhoService: EmpenhoService,
    private compraService: CompraService,
    private compraItemService: CompraItemService
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.data = new Date();
    this.valor = 0;
    this.historico = '';
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    this.globalService.calendarMascara();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.visualizar) {
      this.obterItensCompra();
      if (this.empNumero) {
        this.liquidacaoService.totalParcelado(this.empNumero, this.login.exercicio.id, this.login.orgao.id)
          .subscribe((res) => {
            this.totalParcelado = res.liquidado;
            this.empenhoService.extendido(0, -1, {
              'exercicio_id': this.login.exercicio.id, 'orgao_id': this.login.orgao.id,
              'numero': this.empNumero, 'especie': 'EMO', relations: 'liquidacoes,liquidacoes.retencoes'
            }).subscribe((res) => {
              let emp: Empenho = res.content[0];
              this.totalEmpenhado = emp['total_empenhado'];
              this.totalPago = emp['total_pago'];

              this.totalRetido = 0;
              for (const liq of emp.liquidacoes) {
                if (liq.parcela == 0 && !liq.anulacao && !liq.anulado_total) this.idLiquidacao = liq.id;

                for (const ret of liq.retencoes) {
                  this.totalRetido += +ret.valor_retido;
                }
              }

              this.totalRetido = +this.totalRetido.toFixed(2)

              if (+this.totalParcelado == 0) {//Se não tem parcela, considera apenas o valor pago
                this.saldo = +(+this.totalEmpenhado - +this.totalPago).toFixed(2);
              } else {//Se parcelado, considera apenas as Parcelas.
                this.saldo = +(+this.totalEmpenhado - +this.totalParcelado).toFixed(2);
              }

              this.valor = this.saldo;
              this.historico = '';
              if (this.saldo < 0) {
                this.confirmationService.confirm({
                  message: 'O empenho não possui saldo para anular, verifique se o pagamento foi anulado para prosseguir ou se esta totalmente anulado.',
                  acceptLabel: "OK",
                  rejectVisible: false,
                  closeOnEscape: false,
                  header: 'Aviso!',
                  icon: 'pi pi-exclamation-triangle',
                  accept: () => {
                    this.hide();
                  },
                });
              } else {
                let totalItens = 0.0;
                this.totalOriginal = 0;
                for (const item of this.compra.itens) {
                  totalItens += +item.valor_unitario * +item.quantidade;
                  this.totalOriginal += +item['valor_total'];
                }
                this.diferencaEmpenhoCompra = +(this.totalEmpenhado - this.totalOriginal).toFixed(2);
                totalItens = +totalItens.toFixed(2);
                if (+totalItens > +(this.saldo + this.totalRetido).toFixed(2)) {//Temporario para evitar disparo da mensagem todas vezes, até decidir a questão de anulação empenho x compras
                  this.confirmationService.confirm({
                    message: 'O valor total de itens é maior que a quantidade disponível para anulação. Altere o valor reduzindo a quantidade de itens, ou remova todos itens relacionados para anular apenas os valores.',
                    acceptLabel: "OK",
                    rejectVisible: false,
                    closeOnEscape: false,
                    header: 'Aviso!',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                      this.valor = +totalItens;
                    },
                  });
                }
              }
            });
          });
      }
    }
  }

  confirmarLiquidacao() {
    if (this.empenho?.liquidacoes?.length) {
      this.confirmationService.confirm({
        message: 'Possui vinculo de liquidação nesse empenho, deseja continuar?',
        acceptLabel: "Sim",
        rejectLabel: "Não",
        rejectVisible: true,
        closeOnEscape: false,
        header: 'Aviso!',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          // Timeout necessário, sem ele os outros confirmations não funcionam
          setTimeout(() => {
            this.anular();
          }, 100);
        },
      });
    } else {
      this.anular();
    }
  }

  anular() {
    const saldoAtualizado: number = this.saldo + (this.anularRetencoes ? +this.totalRetido : 0) - (+this.totalPago == 0 && +this.totalParcelado == 0 ? +this.totalRetido : 0);
    const parametros = {};
    parametros['relations'] = 'ordem';
    parametros['numero'] = this.empNumero;
    parametros['baixado'] = false;
    parametros['ordem.exercicio'] = this.login.exercicio.id;
    parametros['ordem.orgao'] = this.login.orgao.id;

    this.itemService.filtrar(0, 0, parametros).pipe(takeUntil(this.unsubscribe)).subscribe(async dados => {
      // if (dados.content.length) {
      //   for (const item of dados.content) {
      //     toastr.error(`não é possivel anular o empenho desejado, pois encontra vinculado na ordem de pagamento nº ${item.ordem.numero}`);
      //     return;
      //   }
      // }
      if (this.valor > saldoAtualizado) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Valor da anulação ultrapassa o saldo !' });
        return;
      }
      if (this.compra) {
        if (this.compra.itens.length > 0) {
          for (const i of this.compra.itens) {
            if (!i.produto_unidade) {
              toastr.error('Item sem Tipo especificado vinculado a esse empenho, anulação cancelada. Solicite ao Compras/Requisição o ajuste.')
              return;
            }
          }
          let frase = '<pre>Deseja continuar? Os itens vinculados serão anulados no compras:\n'
          for (const i of this.compra.itens) {
            frase += +i.quantidade + ' x ' + i.produto_unidade.produto.nome + '\n'
          }
          frase += '</pre>';
          this.confirmationService.confirm({
            message: frase,
            acceptLabel: "Continuar",
            rejectLabel: "Não",
            rejectVisible: true,
            closeOnEscape: false,
            header: 'Aviso!',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              // Timeout necessário, sem ele os outros confirmations não funcionam
              // setTimeout(() => {
              //   this.anular();
              // }, 100);
              this.anularRetencao();
            },
          });
        } else {
          this.anularRetencao();
        }
      } else {
        this.anularRetencao();
      }
    }, (error) => this.funcaoService.acaoErro(error));
  }

  anularRetencao() {
    //Se tiver valor retido, não estiver marcado para anular as retenções, e for valor 100%
    if (this.totalRetido > 0 && !this.anularRetencoes && this.valor == this.totalEmpenhado) {
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: `Empenho está retido, e marcado para anular o valor total empenhado, para continuar é necessário marcar que se deseja anular todas as retenções.` });
    } else if (this.totalRetido == 0) {//Se não tiver valor retido, continua.
      this.anularEmpenho();
    } else if (this.anularRetencoes) {//Se tiver marcado para anular retenções, necessário confirmar antes.
      this.confirmationService.confirm({
        message: 'Anulação marcada para anular TODAS retenções desse empenho, deseja realmente continuar?',
        acceptLabel: "Continuar",
        rejectLabel: "Não",
        rejectVisible: true,
        closeOnEscape: false,
        header: 'Aviso!',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.anularEmpenho();
        },
      });
    } else {//Se não for valor total, e não tiver marcado, só continua.
      this.anularEmpenho();
    }
  }

  anularEmpenho() {
    this.route.paramMap
      .pipe(switchMap(params => this.empenhoService.anular(
        Number(this.empenhoId),
        String(this.data),
        this.valor,
        this.historico,
        this.login.usuario,
        this.compra,
        this.anularRetencoes
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (dados) => {
          this.hide();
          this.router.navigate(['/empenhos-orcamentario']);
          toastr.success('Anulação realizada com sucesso!');
          this.onFinalizar.emit();
        }, error => {
          if (error.error && error.error.payload) {
            this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error.error.payload });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Atenção', detail: 'Ocorreu um erro ao processar a sua solicitação' });
          }
        });
  }

  abrirLiquidacao() {
    const url = this.router.createUrlTree([`/liquidacoes-orcamentaria/${this.idLiquidacao}/editar`]);
    window.open('#/' + url.toString(), '_blank');
  }

  buscarAnulacoes() {
    this.route.paramMap
      .pipe(switchMap(params => this.empenhoService.filtrar(0, -1,
        {
          relations: 'usuario_cadastro',
          especie: 'EOA',
          exercicio_id: this.login.exercicio.id,
          orgao_id: this.login.orgao.id,
          numero: this.empNumero
        }
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (dados) => {
          this.listAnulacoes = dados.content;
        }, error => {
          if (error.error && error.error.payload) {
            toastr.error(error.error.payload);
          } else {
            toastr.error('Ocorreu um erro ao processar a sua solicitação');
          }
        });
  }

  obterItensCompra() {
    if (this.compraId > 0) {
      this.compraService.obter({ id: this.compraId, relations: 'itens,itens.produto_unidade,itens.produto_unidade.produto.material', 'itens.quantidade$gt': 0 }).subscribe(async (dados) => {
        this.compra = dados;
        if (this.compra) {
          let index = 0;
          for (const item of this.compra.itens) {
            if (!item.produto_unidade) {
              this.confirmationService.confirm({
                message: 'O produto/serviço vinculado a esse empenho, não foi especificado o Tipo, a anulação será bloqueada até que os ajustes sejam feitos no Compras/Requisição, ou que os itens sejam removidos da listagem de anulação!',
                acceptLabel: "Entendido",
                rejectVisible: false,
                header: 'Remoção',
                icon: 'pi pi-exclamation-triangle',
              });
              return;
            }
            let qtd = item.quantidade = await (item.produto_unidade.id ? this.compraItemService.obterSaldoAtualizado(this.compraId, item.produto_unidade.id) :
              this.compraItemService.obterSaldoAtualizado(this.compraId, item.produto, item.unidade)).toPromise();
            if (+qtd['quantidade_atual'] == 0) {
              this.compra.itens.splice(index, 1);
              continue;
            }
            item.quantidade = qtd['quantidade_atual'];
            item['valor_total'] = (((+item.quantidade * +item.valor_unitario) - +item.valor_desconto) + +item.valor_icmsipi).toString();
            item['quantidade_base'] = +item.quantidade;
            item['totalCalculado'] = (((+item.quantidade * +item.valor_unitario) - +item.valor_desconto) + +item.valor_icmsipi).toString();
            index += 1;
          }
          this.calcularTotal();
        }
      });
    }
  }

  public calcularTotal() {
    this.total = 0;
    this.totalOriginal = 0;
    if (this.compra) {
      let vl = 0;
      for (const item of this.compra.itens) {
        vl += (((+item.quantidade * +item.valor_unitario) - +item.valor_desconto) + +item.valor_icmsipi)
        this.totalOriginal += +item['valor_total'];
      }
      this.diferencaEmpenhoCompra = +(this.totalEmpenhado - this.totalOriginal).toFixed(2);
      this.total = +vl.toFixed(2);
      this.valor = this.total;
    }
  }

  public salvarItem(item: any, ajuste_total?: boolean) {
    this.calcularTotal()
    if (item.quantidade > item['quantidade_base']) {
      item.quantidade = +item['quantidade_base'];
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Quantidade excede quantidade do item !' });
      return;
    }

    let valor_total_base = ((+item['quantidade_base'] * +item.valor_unitario) - +item.valor_desconto) + +item.valor_icmsipi;

    if (+item['valor_total'] > +valor_total_base.toFixed(2)) {
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Valor total excede o valor total do item !' });
      return;
    }

    if (!ajuste_total) {
      item.quantidade = +item['valor_total'] / +item.valor_unitario;
    }

    if (this.inplaceQuant) {
      this.inplaceQuant.deactivate();
    }
    if (this.inplaceValor) {
      this.inplaceValor.deactivate();
    }
    this.calcularTotal();
  }

  private removerItem(item: CompraItem, index: number) {
    this.compra.itens.splice(index, 1);
    this.calcularTotal();
  }

  public confirmarRemocao(item: CompraItem, index: number) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja remover item dessa anulação?',
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.removerItem(item, index)
      },
    });
  }

  public focusInput(input: any) {
    setTimeout(() => {
      input.select();
    }, 100);
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
    $('#dialogAnular').modal('hide');
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  //Quando o item for serviço o campo valor total sera liberado para o usuario digitar o valor total
  //e o sistema irá calcular o quantitativo.
  public calcularQuantitativo(itemAtual: CompraItem) {

    const totalCalculado = (+itemAtual['totalCalculado'] / +itemAtual?.valor_unitario).toFixed(5);

    if (!itemAtual?.['quantidade_base']) {
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Quantidade referência do item não encontrada!' });
      return;
    };
    if (+totalCalculado > +itemAtual?.['quantidade_base']) {
      itemAtual['totalCalculado'] = ((+itemAtual['quantidade_base'] * +itemAtual.valor_unitario) - +itemAtual.valor_desconto) + +itemAtual.valor_icmsipi;
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Quantidade excede quantidade do item !' });
      this.inplaceTotal.deactivate();
      return;
    };

    itemAtual['quantidade'] = +totalCalculado;

    if (this.inplaceTotal) {
      this.inplaceTotal.deactivate();
    };

    this.salvarItem(itemAtual, true);
  };
}
