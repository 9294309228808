import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../util/shared.module';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { SimplesNacionalDlgComponent } from './simples-nacional-dlg.component';

@NgModule({
  declarations: [SimplesNacionalDlgComponent],
  imports: [
    CommonModule,
    SharedModule,
    ToastModule,
    FormsModule
  ],
  exports: [
    SimplesNacionalDlgComponent
  ],
  providers: [MessageService, ConfirmationService]
})
export class SimplesNacionalDlgModule { }
