import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../util/auth.guard';
import { AuditoriaLstComponent } from './auditoria-lst/auditoria-lst.component';
import { AuditoriaViewComponent } from './auditoria-view/auditoria-view.component';

const routes: Routes = [
  { path: '', component: AuditoriaLstComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: AuditoriaViewComponent, canActivate: [AuthGuard] }];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuditoriaRoutingModule { }
