import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Coluna, FormatoExportacao } from '../../../components/types';
import { Setor } from '../../../entidade/almoxarifado/setor.model';
import { LoginContabil } from '../../../entidade/login/login-contabil';
import { BaseResourceListComponent, Filtro } from '../../../models/base-resource-list';
import { GlobalService } from '../../../util/global.service';
import { SetorService } from '../service/setor.service';
import * as toastr from 'toastr'

@Component({
  selector: 'app-setor-list',
  templateUrl: './setor-list.component.html'
})
export class SetorListComponent extends BaseResourceListComponent<Setor, LoginContabil> {
  /**
   * Declaração de variáveis
   */
  public mes: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private setorService: SetorService,
    protected globalService: GlobalService) {
    super(setorService, injector);
    this.usarFiltroPersonalizado = true;
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(item: Setor): boolean {
    return true;
  }
  protected relations(): string {
    return 'orgao,unidade';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['id$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['codigo'],
      text: ['nome', 'endereco', 'telefone'],
    };
  }

  protected afterInit(): void {
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }


  protected acaoRemover(model: Setor): Observable<Setor> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'codigo' },
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'Responsável', coluna: 'responsavel' },
      { titulo: 'Telefone', coluna: 'telefone' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'orgao';
    this.setorService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE SETORES`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem setores', ['auto', '*', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  public excluir(setor: Setor) {
    this.setorService.remover(setor.id).pipe(takeUntil(this.unsubscribe)).subscribe(_ => {
      toastr.success(`Setor ${setor.nome} removido com sucesso!`)
      this.preencherGrid()
    }, (err) => {
      console.error(err)
      toastr.error(err.error.payload)
    })
  }

  public obterColunasFiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Código', coluna: 'codigo', alignment: 'center', tipo: 'Number', cols: 2, padrao: true });
    retorno.push({ titulo: 'Nome', coluna: 'nome', tipo: 'String', cols: 2, padrao: true });
    retorno.push({ titulo: 'Endereço', coluna: 'endereco', tipo: 'String', padrao: true });
    retorno.push({
      titulo: 'Telefone', coluna: 'telefone', alignment: 'center', tipo: 'Processo', cols: 2,
      mask: [{ mask: '(00)00000-0000' }, { mask: '0000-0000' }], notUnmask: true, padrao: true
    });
    retorno.push({
      titulo: 'CEP', coluna: 'cep', alignment: 'center', tipo: 'Processo', cols: 2,
      mask: [{ mask: '00.000-000' }], notUnmask: true
    });
    retorno.push({ titulo: 'Bairro', coluna: 'bairro', tipo: 'String' });
    retorno.push({ titulo: 'Número', coluna: 'numero', tipo: 'Number' });
    retorno.push({ titulo: 'E-mail', coluna: 'email', tipo: 'String' });
    retorno.push({ titulo: 'Setor ativo', coluna: 'ativo', tipo: 'Boolean' });
    retorno.push({ titulo: 'Inativos', coluna: 'ativo$ne', tipo: 'Boolean' });
    retorno.push({ titulo: 'Setor protocolo', coluna: 'setor_protocolo', tipo: 'Boolean' });
    retorno.push({ titulo: 'Manutenção patrimônio', coluna: 'manutencao_patrimonio', tipo: 'Boolean' });
    retorno.push({ titulo: 'Baixa patrimônio', coluna: 'baixa_patrimonio', tipo: 'Boolean' });
    retorno.push({ titulo: 'Merenda', coluna: 'merenda', tipo: 'Boolean' });
    retorno.push({ titulo: 'Setor patrimônio', coluna: 'setor_patrimonio', tipo: 'Boolean' });
    retorno.push({ titulo: 'Cessão de Uso', coluna: 'cessao_uso', tipo: 'Boolean' });
    retorno.push({ titulo: 'Transferência Automática', coluna: 'transferencia_automatica', tipo: 'Boolean' });

    return retorno;
  }

}
