import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { AssinaturaService } from '../assinatura/service/assinatura.service';
import { Relatorio } from '../components/report';
import { FormatoExportacao } from '../components/types';
import { AssinaturaPessoa } from '../entidade/comum/assinatura-pessoa.model';
import { Assinatura } from '../entidade/comum/assinatura.model';
import { Login } from '../entidade/login/login';
import { FuncaoService } from '../util/funcao.service';
import { GlobalService } from '../util/global.service';

declare var $: any;

@Directive()
export abstract class BaseResourceRptPersonalizadoComponent implements OnInit, OnDestroy {

    // ========================================================================
    // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
    // ========================================================================
    public login: Login;
    public ptBR: any;

    public formato: FormatoExportacao;
    public listFormato: FormatoExportacao[];

    public orientacao: 'landscape' | 'portrait';
    public listOrientacao: { id: 'landscape' | 'portrait', nome: string }[];
    public exportar: boolean = false;

    public assinatura: Assinatura;
    public listAssinatura: Assinatura[];

    protected assinaturaObrigatoria: boolean = false;
    protected marcarTodos: boolean = false;
    protected unsubscribe: Subject<void> = new Subject();
    protected funcaoService: FuncaoService;

    public removerCabecalho: boolean = false;
    public removerRodape: boolean = false;

    // ========================================================================
    // ------------------------------ CONSTRUTOR ------------------------------
    // ========================================================================
    constructor(protected assinaturaService: AssinaturaService) {
        this.login = GlobalService.obterSessaoLogin();
        this.ptBR = new GlobalService().obterDataBR();
    }

    // ========================================================================
    // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
    // ========================================================================
    protected abstract afterInit(): void;
    protected abstract modalRelatorio(): string;
    protected abstract tituloRelatorio(): string;
    protected abstract layoutRelatorio(): {};
    protected abstract montarConteudo(): Promise<{}[]>;

    protected montarCabecalho(): {}[] {
        return null;
    }

    protected montarFooter(): any {
        return null;
    }

    protected montarFooterDocx(): any {
        return null;
    }

    protected margemRelatorio(): number[] {
        return null;
    }

    protected ocultarRodape(): boolean {
        return this.removerRodape;
    }

    protected subtituloRelatorio(): string {
        return null;
    }

    protected ocultarCabecalho(): boolean {
        return this.removerCabecalho;
    }

    // ========================================================================
    // -------------------------- MÉTODOS DA CLASSE ---------------------------
    // ========================================================================
    compareFn(c1: any, c2: any): boolean {
        return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
    }

    ngOnInit(): void {
        this.funcaoService = new FuncaoService();

        this.listFormato = ['pdf', 'docx'];
        this.formato = 'pdf';

        this.listOrientacao = [{ id: 'portrait', nome: 'Retrato' }, { id: 'landscape', nome: 'Paisagem' }];
        this.orientacao = 'portrait';

        this.assinaturaService.filtrar(1, -1, { 'orgao_id': this.login.orgao.id, ativo: true, sistema: this.login.sistema, relations: 'pessoas.pessoa', orderBy: 'nome' }).pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                this.listAssinatura = res ? res.content : [];
                this.listAssinatura.forEach(a => a.pessoas.forEach(async p => {
                    p.selecionado = false;
                }));
            }, error => toastr.error(error.message ? error.message : error));

        this.afterInit();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public alterarSelecaoAssinatura() {
        this.assinatura.pessoas.forEach(pessoa => {
            pessoa.selecionado = !this.marcarTodos
        })
    }

    public imprimir() {
        if (this.assinaturaObrigatoria) {
            if (!this.assinatura) {
                toastr.warning('Selecione a assinatura que será usada no relatório');
                return;
            }
            if (!this.assinatura.pessoas.find(p => p.selecionado === true)) {
                toastr.warning('Selecione as pessoas que farão parte da assinatura do relatório');
                return;
            }
        }

        this.montarConteudo().then(async (conteudo) => {
            if (conteudo?.length === 0) {
                toastr.warning('Não foi localizado conteúdo para impressão')
                return
            }
            if(!conteudo){
                return
            }
            if (this.exportar) {
                const blob = await Relatorio.exportarPersonalizado(this.tituloRelatorio().toUpperCase(), this.login.usuario.nome, this.login.usuario.sobrenome,
                    this.login.orgao.nome, this.login.brasao, conteudo, this.orientacao, this.tituloRelatorio(),
                    this.layoutRelatorio(), this.ocultarCabecalho(), this.ocultarRodape(), this.formato, this.subtituloRelatorio(), null, this.montarCabecalho(), this.montarFooter());

                this.fazerDownloadArquivo(blob)
            } else {
                Relatorio.imprimirPersonalizado(this.tituloRelatorio().toUpperCase(), this.login.usuario.nome, this.login.usuario.sobrenome,
                    this.login.orgao.nome, this.login.brasao, conteudo, this.orientacao, this.tituloRelatorio(),
                    this.layoutRelatorio(), this.ocultarCabecalho(), this.ocultarRodape(), this.formato, this.subtituloRelatorio(),
                    this.margemRelatorio(), false, this.montarCabecalho(), this.montarFooter(), false, this.montarFooterDocx());
            }

            if (this.modalRelatorio())
                $(`#${this.modalRelatorio()}`).modal('hide');

            this.aposEmitir();
        }).catch((error) => { return this.funcaoService.acaoErro(error) })
    }

    protected fazerDownloadArquivo(arquivo: Blob) {
        const downloadURL = window.URL.createObjectURL(arquivo);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${this.tituloRelatorio()}.${this.formato}`;
        link.target = '_blank';
        link.click();
        window.URL.revokeObjectURL(downloadURL);
    }

    protected aposEmitir() {
    }

    protected imprimirAssinaturas(assinaturas: AssinaturaPessoa[], marginPersonalizada?: number): {}[] {
        if (!assinaturas || assinaturas.length === 0) return [{}];
        if (assinaturas.length > 3) {
            let retorno: {}[] = [];
            for (let i = 0; i < assinaturas.length; i += 3) {
                retorno = retorno.concat(this.imprimirAssinaturas(assinaturas.slice(i, i + 3)));
            }
            return retorno;
        } else if (assinaturas.length > 2) {
            return this.imprimirAssinatura3Colunas(assinaturas);
        } else if (assinaturas.length > 1) {
            return this.imprimirAssinatura2Colunas(assinaturas, marginPersonalizada);
        } else {
            return this.imprimirAssinatura1Coluna(assinaturas[0], marginPersonalizada);
        }
    }

    private imprimirAssinatura1Coluna(assinatura: AssinaturaPessoa, marginPersonalizada?: number): {}[] {
        return [
            assinatura?.base64 ? { columns: [{ image: assinatura.base64, fit: ['50%', 30], alignment: 'center' }] } : { text: '' },
            { canvas: [{ type: 'line', x1: this.orientacao === 'portrait' ? 187 : 312, y1: 0, x2: this.orientacao === 'portrait' ? 347 : 472, y2: 0, lineWidth: 0.5 }] },
            {
                columns: [
                    { text: this.funcaoService.abreviarStr(assinatura.pessoa.nome, 100), alignment: 'center', bold: true, fontSize: 10, margin: [0, 3, 0, 3] },
                ]
            },
            {
                columns: [
                    { text: this.funcaoService.abreviarStr(assinatura.cargo, 100), alignment: 'center', fontSize: 10, margin: [0, 0, 0, marginPersonalizada ? marginPersonalizada : 50] },
                ]
            },
        ]
    }

    private imprimirAssinatura2Colunas(assinaturas: AssinaturaPessoa[], marginPersonalizada?: number): {}[] {
        return [
            {
                columns: [
                    assinaturas[0]?.base64 ? { image: assinaturas[0].base64, fit: ['50%', 30], alignment: 'center' } : { text: '' },
                    assinaturas[1]?.base64 ? { image: assinaturas[1].base64, fit: ['50%', 30], alignment: 'center' } : { text: '' }
                ]
            },
            { canvas: [{ type: 'line', x1: this.orientacao === 'portrait' ? 55 : 115, y1: 0, x2: this.orientacao === 'portrait' ? 215 : 275, y2: 0, lineWidth: 0.5 }] },
            { canvas: [{ type: 'line', x1: this.orientacao === 'portrait' ? 320 : 505, y1: 0, x2: this.orientacao === 'portrait' ? 480 : 665, y2: 0, lineWidth: 0.5 }] },
            {
                columns: [
                    { text: this.funcaoService.abreviarStr(assinaturas[0].pessoa.nome, 100), alignment: 'center', bold: true, fontSize: 10, margin: [0, 3, 0, 3] },
                    { text: this.funcaoService.abreviarStr(assinaturas[1].pessoa.nome, 100), alignment: 'center', bold: true, fontSize: 10, margin: [0, 3, 0, 3] },
                ]
            },
            {
                columns: [
                    { text: this.funcaoService.abreviarStr(assinaturas[0].cargo, 100), alignment: 'center', fontSize: 10, margin: [0, 0, 0, marginPersonalizada ? marginPersonalizada : 50] },
                    { text: this.funcaoService.abreviarStr(assinaturas[1].cargo, 100), alignment: 'center', fontSize: 10, margin: [0, 0, 0, marginPersonalizada ? marginPersonalizada : 50] },
                ]
            },
        ]
    }

    private imprimirAssinatura3Colunas(assinaturas: AssinaturaPessoa[], margin?: []): {}[] {
        return [
            {
                columns: [
                    assinaturas[0]?.base64 ? { image: assinaturas[0].base64, fit: ['50%', 30], alignment: 'center' } : { text: '' },
                    assinaturas[1]?.base64 ? { image: assinaturas[1].base64, fit: ['50%', 30], alignment: 'center' } : { text: '' },
                    assinaturas[2]?.base64 ? { image: assinaturas[2].base64, fit: ['50%', 30], alignment: 'center' } : { text: '' }
                ]
            },
            { canvas: [{ type: 'line', x1: this.orientacao === 'portrait' ? 7 : 53, y1: 0, x2: this.orientacao === 'portrait' ? 167 : 213, y2: 0, lineWidth: 0.5 }] },
            { canvas: [{ type: 'line', x1: this.orientacao === 'portrait' ? 187 : 312, y1: 0, x2: this.orientacao === 'portrait' ? 347 : 472, y2: 0, lineWidth: 0.5 }] },
            { canvas: [{ type: 'line', x1: this.orientacao === 'portrait' ? 365 : 570, y1: 0, x2: this.orientacao === 'portrait' ? 525 : 730, y2: 0, lineWidth: 0.5 }] },
            {
                columns: [
                    { text: this.funcaoService.abreviarStr(assinaturas[0].pessoa.nome, 100), alignment: 'center', bold: true, fontSize: 10, margin: [0, 3, 0, 3] },
                    { text: this.funcaoService.abreviarStr(assinaturas[1].pessoa.nome, 100), alignment: 'center', bold: true, fontSize: 10, margin: [0, 3, 0, 3] },
                    { text: this.funcaoService.abreviarStr(assinaturas[2].pessoa.nome, 100), alignment: 'center', bold: true, fontSize: 10, margin: [0, 3, 0, 3] },
                ]
            },
            {
                columns: [
                    { text: this.funcaoService.abreviarStr(assinaturas[0].cargo, 100), alignment: 'center', fontSize: 10, margin: [0, 0, 0, 50] },
                    { text: this.funcaoService.abreviarStr(assinaturas[1].cargo, 100), alignment: 'center', fontSize: 10, margin: [0, 0, 0, 50] },
                    { text: this.funcaoService.abreviarStr(assinaturas[2].cargo, 100), alignment: 'center', fontSize: 10, margin: [0, 0, 0, 50] },
                ]
            },
        ]
    }
}
