import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RplStorage } from '../../../entidade/licitacao/rpl-storage.model';
import { BaseResourceService } from '../../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class RplStorageService extends BaseResourceService<RplStorage> {

  constructor(
    protected injector: Injector
  ) {
    super(`rpl-storages`, injector);
  }

  public upload(arquivos: any[], orgao: number, licitacao: number, descricao?: string): Observable<RplStorage[]> {
    const formData = new FormData();
    for (let arquivo of arquivos) {
      formData.append('files', arquivo);
    }
    if (descricao) {
      formData.append('descricao', descricao);
    }

    const headers = this.httpOptions();
    headers.headers = headers.headers.delete('Content-Type');
    
    return this.http.post<RplStorage[]>(`${this.login.cidade.id}/${this.api}/upload/${orgao}/${licitacao}`,
      formData, headers)
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
