import { Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RcmsItemService } from 'compra-lib';
import { BaseResourceListComponent, Coluna, Contrato, Filtro, FuncaoService, GlobalService, Login, LoginContabil, ProdutoUnidade, Rcms, RcmsItem } from 'eddydata-lib';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RcmsService } from '../../rcms/service/rcms.service';

@Component({
  selector: 'lib-contrato-rcms',
  templateUrl: './contrato-rcms.component.html'
})
export class ContratoRcmsComponent extends BaseResourceListComponent<RcmsItem, LoginContabil> implements OnChanges {

  @Input() public login: Login;
  @Input() public contrato: Contrato;
  @Input() public itemFiltro: ProdutoUnidade;
  public listaItens: any[];

  public unsubscribe: Subject<void> = new Subject();

  public limite = 5;

  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    public funcaoService: FuncaoService,
    private rcmsItemService: RcmsItemService) {
    super(rcmsItemService, injector);
  }

  public beforeInit(): void {
    this.usarExtendido = true;
  }

  protected afterInit(): void {
  }

  protected relations(): string {
    return 'rcms,rcms.exercicio,rcms.setor,rcms.requerente,produto_unidade.produto';
  }

  protected acaoRemover(model: Rcms): Observable<Rcms> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
    ];
  }

  protected condicoesGrid(): {} {
    const parametros = {};
    parametros['rcms.contrato_id'] = this.contrato.id
    parametros['rcms.compras.id$null'] = true
    parametros['ignoreCondObrig'] = true;
    if (this.itemFiltro?.id) {
      parametros['produto_unidade.id'] = this.itemFiltro?.id
    }
    return parametros;
  }

  protected filtrosGrid(): Filtro {
    return {
    };
  }

  protected ordenacaoGrid(): string[] {
    return [];
  }



  ngOnChanges(changes: SimpleChanges): void {
    if (changes && (changes['contrato'] || changes['itemFiltro']) && this.contrato) {
      this.preencherGrid();
    }
  }

}