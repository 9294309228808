import { MemorialItemService } from './../../memorial-item/service/memorial-item.service';
import { Component, ElementRef, HostListener, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MaterialService, ProdutoService, ProdutoUnidadeService, RequisicaoItemMovimentoService } from 'almoxarifado-lib';
import {
  Aliquota,
  BaseResourceFormComponent, CodigoMercadoriaBB, EddyAutoComplete, FavorecidoProduto,
  FuncaoService, GlobalService, LoginContabil, Material, MovimentoItemEstoque,
  Produto, ProdutoLocalizacao, ProdutoReferencia, ProdutoUnidade, Tabela1Reinf
} from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { AutoComplete } from 'primeng/autocomplete';
import * as toastr from 'toastr';
import { ProdutoLocalizacaoService } from '../../produto-localizacao/service/produto-localizacao.service';
import { CodigoMercadoriaService } from '../service/codigo-mercadoria.service';
import { MemorialService, MovimentoItemEstoqueService, TombamentoService } from 'administrativo-lib';
import { CompraItemService } from '../../compra/service/compra-item.service';
import { RcmsItemService } from '../../rcms/service/rcms-item.service';
import { RplItemService } from '../../rpl/service/rpl-item.service';
import { ContratoItemService } from '../../contrato/service/contrato-item.service';
import { AliquotaService } from 'contabil-lib';

declare var $: any;
@Component({
  selector: 'lib-produto-form',
  templateUrl: './produto-form.component.html'
})
export class ProdutoFormComponent extends BaseResourceFormComponent<Produto, LoginContabil> {

  /**
   * Declaração de variáveis
   */

  public imaskCodigoBB = {
    mask: Number,
    scale: 0,
    signed: true,
  };

  @ViewChild('produto') produtoInput: AutoComplete;
  @ViewChild('qtd_') qtdInput: AutoComplete;
  public materialAutoComplete: EddyAutoComplete<Material>;
  public codigoMercadoriaComplete: EddyAutoComplete<CodigoMercadoriaBB>;
  public localizacaoAutoComplete: EddyAutoComplete<ProdutoLocalizacao>;
  public produtoAutoComplete: EddyAutoComplete<Produto>;
  public aliquotaAutoComplete: EddyAutoComplete<Aliquota>
  public aliquotaMaterial: Aliquota
  public unidades: ProdutoUnidade[];
  public referencias: ProdutoReferencia[];
  public favorecidos: FavorecidoProduto[];
  public listProdUn: ProdutoUnidade[];
  public codigoMercadoria: CodigoMercadoriaBB;
  public visualizarGrupo: boolean = false;
  public visualizarLocalizacao: boolean = false;
  public mes: string;
  public especie: string;
  public produtoNovo: string;
  public produto: ProdutoUnidade
  public produtos: string[] = [];
  public parametrosBusca: any
  public gruposReinf: Array<any> = new Array<any>();
  public grupoReinf: any;
  public carregarSaldoSetor: boolean = false;
  public carregarAlmoxarifado: boolean = false;

  @ViewChild('codigo_') inputField: ElementRef;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private produtoUnService: ProdutoUnidadeService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected materialService: MaterialService,
    protected produtoService: ProdutoService,
    private confirmationService: ConfirmationService,
    protected tabela1ReinfService: Tabela1Reinf,
    private codigoMercadoriaService: CodigoMercadoriaService,
    protected localizacaoService: ProdutoLocalizacaoService,
    protected aliquotaService: AliquotaService,
    protected estoqueItemService: MovimentoItemEstoqueService,
    protected compraItemService: CompraItemService,
    protected rcmsItemService: RcmsItemService,
    protected memorialItemService: MemorialItemService,
    protected memorialService: MemorialService,
    protected rplItemService: RplItemService,
    protected contratoItemService: ContratoItemService,
    protected tombamentoService: TombamentoService,
    protected requisicaoItemService: RequisicaoItemMovimentoService,
  ) {
    super(new Produto(), injector, Produto.converteJson, produtoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(item: Produto): Promise<boolean> {
    return true;
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      codigo: [null, [Validators.required]],
      nome: [null, [Validators.required]],
      descricao: [null, [Validators.required]],
      material: [null, [Validators.required]],
      bec: [false, [Validators.required]],
      localizacao: [null],
      medicamento: [null],
      financeiro_min: [0],
      financeiro_max: [0],
      estoque_min: [0],
      estoque_max: [0],
      unidades: [],
      referencias: [null],
      inativo: [false],
      desabilita_compra: [false],
      favorecidos: [],
      vida_util_meses: [],
      valor_residual: [],
      codigo_reinf: [null],
      cod_mercadoria: [null],
      orgao: [this.login.orgao, [Validators.required]],
      aliquota: [null],
      ignorarValidacao: [false]
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: ['material.sub_grupo.grupo,unidades.unidade', 'localizacao', 'referencias', 'favorecidos', 'favorecidos.favorecido',
        'medicamento.produto', 'medicamento.interacoes_medicamentosas', 'medicamento.concentracoes', 'material.aliquota', 'aliquota'],
      'material.servico': false
    };
  }

  protected afterLoad() {
    this.materialAutoComplete.id = this.entidade.material ? this.entidade.material.id : null;
    this.unidades = this.entidade.unidades;
    this.referencias = this.entidade.referencias;
    this.favorecidos = this.entidade.favorecidos;
    this.entidade.medicamento = this.entidade.medicamento ? this.entidade.medicamento : null;
    this.aliquotaMaterial = this.entidade.material.aliquota ? this.entidade.material.aliquota : this.entidadeForm.get('material').value?.aliquota
    this.carregarTabela1Reinf()
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.entidade.medicamento = this.entidade.medicamento ? this.entidade.medicamento : null;
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
    this.produtoNovo = this.currentActionRoute;
    this.carregarTabela1Reinf()
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected async beforeSubmit() {
    try {
      if (this.codigoMercadoria) {
        this.entidadeForm.get('cod_mercadoria').setValue('' + this.codigoMercadoria.codigo);
      }

      if (this.entidadeForm.get('nome').value === '') {
        throw new Error('Informe o nome do produto!');
      }



      if (!this.entidadeForm.get('material').value) {
        throw new Error('Informe o grupo de material!');
      }

      this.entidadeForm.get('nome').setValue((this.entidadeForm.get('nome').value + '').trimStart().trimEnd())
      for (const uni of this.unidades) {
        delete uni.produto
        if (uni['ignorarValidacao'] && this.entidade?.id) {
          this.entidadeForm.get('ignorarValidacao').setValue(true);
          return;
        }
      }
      this.entidadeForm.get('unidades').setValue(this.unidades);
      this.entidadeForm.get('referencias').setValue(this.referencias);
      this.entidadeForm.get('favorecidos').setValue(this.favorecidos);

      if (this.entidade.id && !this.entidade.inativo && this.entidadeForm.get('inativo').value && await this.buscaVinculo(this.entidadeForm.value)) {
        throw new Error('Este produto possui vinculo com outros processos (Compra, Contrato...) e não pode ser inativado.\n\nSe deseja que não haja novas compras, ative o "Desabilitado para Compras"')
      }

    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  protected afterSubmit(modelo: Produto) {
    this.loadResource();
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  callbackLoc(localizacao: ProdutoLocalizacao) {
    this.entidade.localizacao = localizacao;
    this.entidadeForm.get('localizacao').setValue(localizacao);
    this.localizacaoAutoComplete.id = localizacao ? localizacao.id : null;
  }

  @HostListener('window:keydown.control.p', ['$event'])
  public abrirConsultaProduto(event: KeyboardEvent) {
    event.preventDefault();
    $('#dialogBuscaMaterial').modal('show');
  }

  public callbackMaterial(mat: Material) {
    this.entidadeForm.get('material').setValue(mat);
    this.selectMaterial();
    this.visualizaCodigo();
  }

  public parametrosProdutos(): {} {
    let parametros = {};
    parametros['servico'] = false;
    return parametros;
  }

  public carregarUnidades(item: MovimentoItemEstoque) {
    if (!item?.produto_unidade?.produto) {
      return;
    }
    if (this.especie !== 'C' && item?.editavel) {
      let sub_elemento = item.produto_unidade.produto.material.sub_grupo.sub_elementos.find((e) => e.padrao);

      if (sub_elemento) {
        item.subelemento = sub_elemento;
      } else {
        toastr.warning('Não há subelementos vinculados ao subgrupo do material!');
        item.produto_unidade = new ProdutoUnidade();
        return;
      }
    }
    this.produtoUnService.filtrarCodigoCompleto(0, 0, {
      produto_id: item.produto_unidade.produto.id,
      ativo: true,
      relations: 'produto,unidade',
      orderBy: 'unidade.nome'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listProdUn = res ? res.content : [];
        if (this.listProdUn.length === 0) {
          toastr.warning('Nenhuma unidade ativa foi localizada para este produto!');
          return;
        }
        if (item.produto_unidade.id)
          item.produto_unidade = this.listProdUn.find((p) => p.id === item.produto_unidade.id);
        else
          item.produto_unidade = this.listProdUn[0];
      }, error => toastr.error(error.error.payload));

  }

  private carregarAutoCompletes() {
    this.materialAutoComplete = new EddyAutoComplete(null, this.materialService,
      'id', ['codigo', 'nome'], { orgao_id: this.login.orgao.id, relations: 'sub_grupo.grupo', orderBy: 'nome', servico: false }, { number: ['codigo'], text: ['nome', 'codigo_comp'] }, null, -1, (entidade) => {
        return `${entidade.sub_grupo.grupo.codigo}.${entidade.sub_grupo.codigo}.${entidade.codigo} - ${entidade.nome}`;
      }
    );

    this.codigoMercadoriaComplete = new EddyAutoComplete(this.entidadeForm.get('cod_mercadoria'), this.codigoMercadoriaService,
      'codigo', ['codigo'], null, { text: ['codigo', 'nome'] }
    );

    this.localizacaoAutoComplete = new EddyAutoComplete(null, this.localizacaoService,
      'id', ['sala', 'armario', 'prateleira'], {}, { text: ['sala', 'armario', 'prateleira'] }, null, null,
      (localizacao: ProdutoLocalizacao) => {
        return `SALA: ${localizacao.sala}, ARMÁRIO: ${localizacao.armario}, PRATELEIRA: ${localizacao.prateleira}`;
      }
    );

    this.parametrosBusca = this.parametrosProdutos();
    this.parametrosBusca['orderBy'] = 'codigo,nome';
    this.produtoAutoComplete = ProdutoService.autoCompleteCodigoCompleto(null, this.produtoService,
      'id', ['codigo', 'nome'], this.parametrosBusca, { number: ['id', 'codigo'], text: ['codigo', 'nome'] }
    );

    this.aliquotaAutoComplete = new EddyAutoComplete(this.entidadeForm.get('aliquota'), this.aliquotaService, 'id', ['id', 'resumo'], { ativo: true }, { number: ['id', 'codigo_receita'], text: ['resumo'] }, null, null, (entidade) => `${entidade.id} - ${entidade.resumo}`)
  }

  public selecionarLocalizacao(localizacao: ProdutoLocalizacao) {
    this.entidadeForm.get('localizacao').setValue(localizacao);
  }

  public selecionarMaterial(material: Material) {
    this.entidadeForm.get('material').setValue(material);
    this.selectMaterial();
  }

  public selectMaterial() {
    if (this.currentActionRoute === 'novo') {
      this.produtoService.proximoCodigo(this.entidadeForm.get('material').value.id, false, this.login.orgao.id)
        .subscribe((data) => {
          this.entidadeForm.get('codigo').setValue(data);
        });
    }
    const material = this.entidadeForm.get('material').value

    for (let g of this.gruposReinf) {
      if (g.codigo == (material.codigo_reinf / 1000).toFixed(0)) {
        this.grupoReinf = g;
        this.entidadeForm.get('codigo_reinf').setValue(material.codigo_reinf)
        continue;
      }
    }
  }

  public visualizaCodigo() {
    let codigo = this.entidadeForm.get('codigo').value;
    let material: Material = this.entidadeForm.get('material').value;
    if (!codigo)
      return '00.00.00.00';
    if (!material)
      return '00.00.00.00';
    return `${material?.sub_grupo.grupo.codigo}.${material?.sub_grupo.codigo}.${material?.codigo}.${codigo}`
  }

  public remover() {
    this.confirmationService.confirm({
      message: `Removendo o produto as unidades vinculdas serão removidas.Deseja remover o produto ${this.entidade.nome}?`,
      header: `Remover produto`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      key: 'produto',
      accept: () => {
        this.produtoService.remover(this.entidade.id)
          .subscribe((data) => {
            toastr.success(`Produto removido com sucesso.`);
            this.router.navigate(['/produtos']);
          }, error => {
            if (error && error.error && error.error.payload.includes('violates foreign key'))
              toastr.error(`Produto possui vinculo por isso não pode ser excluido.`);
            else
              toastr.error(error.error.payload);
          })
      }
    });
  }

  public carregarTabela1Reinf() {
    this.gruposReinf = this.tabela1ReinfService.carregarTabela1();
    if (this.entidade?.codigo_reinf) {
      this.grupoReinf = this.tabela1ReinfService.carregarPorCodigo(this.entidade.codigo_reinf, false);
      for (let i = 0; i < this.gruposReinf.length; i++) {
        if (this.gruposReinf[i].codigo == this.grupoReinf.codigo) {
          this.gruposReinf[i] = this.grupoReinf;
          break;
        }
      }
    }
  }

  public async buscaVinculo(produto: Produto) {
    const estoqueItem = await this.estoqueItemService.obter({ relations: 'produto_unidade', 'produto_unidade.produto_id': produto.id }).toPromise()
    const compraItem = await this.compraItemService.obter({ relations: 'produto_unidade', 'produto_unidade.produto': produto.id }).toPromise()
    const rcmsItem = await this.rcmsItemService.obter({ relations: 'produto_unidade', 'produto_unidade.produto_id': produto.id }).toPromise()
    const memorialItem = await this.memorialItemService.obter({ produto_id: produto.id }).toPromise()
    const memorial = await this.memorialService.obter({ relations: 'produto_unidade', 'produto_unidade.produto_id': produto.id }).toPromise()
    const rplItem = await this.rplItemService.obter({ relations: 'produto_unidade', 'produto_unidade.produto_id': produto.id }).toPromise()
    const contratoItem = await this.contratoItemService.obter({ relations: 'produto_unidade', 'produto_unidade.produto_id': produto.id }).toPromise()
    const tombamento = await this.tombamentoService.obter({ produto_id: produto.id, orgao_id: this.login.orgao.id }).toPromise()
    const requisicaoItem = await this.requisicaoItemService.obter({ relations: 'produto_unidade', 'produto_unidade.produto_id': produto.id }).toPromise()

    if (estoqueItem || compraItem || rcmsItem || memorialItem || memorial || rplItem || contratoItem || tombamento || requisicaoItem) return true
    else return false
  }

  public carregarSaldoAlmoxarifado() {
    this.carregarAlmoxarifado = true;
  }

  public carregarSetor() {
    this.carregarSaldoSetor = true;
  }
}