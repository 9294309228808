import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TransferenciaBancariaRoutingModule } from './transferencia-bancaria-routing.module';
import { TransferenciaBancariaViewComponent } from './transferencia-bancaria-view/transferencia-bancaria-view.component';
import { TransferenciaBancariaListComponent } from './transferencia-bancaria-list/transferencia-bancaria-list.component';
import { TransferenciaBancariaFormComponent } from './transferencia-bancaria-form/transferencia-bancaria-form.component';
import { TransferenciaDescendialDlgComponent } from './transferencia-descendial-dlg/transferencia-descendial-dlg.component';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { IMaskModule } from 'angular-imask';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SharedModule } from 'eddydata-lib';
import { InplaceModule } from 'primeng/inplace';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TransferenciaBancariaDlgComponent } from './transferencia-bancaria-dlg/transferencia-bancaria-dlg.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TransferenciaBancariaObnDlgComponent } from './transferencia-bancaria-obn-dlg/transferencia-bancaria-obn-dlg.component';
import { InputMaskModule } from 'primeng/inputmask';
import { TreeModule } from 'primeng/tree';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { FieldsetModule } from 'primeng/fieldset';
import { TransferenciaBancariaModalComponent } from './transferencia-bancaria-modal/transferencia-bancaria-modal.component';

@NgModule({
  declarations: [
    TransferenciaBancariaViewComponent, 
    TransferenciaBancariaListComponent, 
    TransferenciaBancariaFormComponent,
    TransferenciaBancariaObnDlgComponent,
    TransferenciaDescendialDlgComponent,
    TransferenciaBancariaDlgComponent,
    TransferenciaBancariaModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    TransferenciaBancariaRoutingModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    InplaceModule,
    ConfirmDialogModule,
    RadioButtonModule,
    InputMaskModule,
    TreeModule,
    TooltipModule,
    DialogModule,
    FieldsetModule
  ],
  exports: [
    TransferenciaDescendialDlgComponent,
  ],
  providers: [MessageService, ConfirmationService]
})
export class TransferenciaBancariaModule { }
