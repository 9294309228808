import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Favorecido } from '../../entidade/compra/favorecido.model';
import { BaseResourceService } from '../../models/services/base-resource.service';
import { Page } from '../../util/page';

@Injectable({
  providedIn: 'root'
})
export class FavorecidoService extends BaseResourceService<Favorecido> {

  constructor(
    protected injector: Injector
  ) {
    super(`favorecidos`, injector);
  }

  public obterPorNome(filtroTexto: string, cidadeId: number, page?: number, limit?: number): Observable<Page> {
    if (!Number.isInteger(page) || !Number.isInteger(page)) {
      page = 1;
      limit = 1000;
    }
    return this.http.get<Page>(
      `${cidadeId}/${this.api}/filtrar?nome$like=${filtroTexto}%&cidade_id=${cidadeId}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public obterPorCpfCnpj(filtroTexto: string, cidadeId: number, page?: number, limit?: number): Observable<Page> {
    if (!Number.isInteger(page) || !Number.isInteger(page)) {
      page = 1;
      limit = 1000;
    }
    return this.http.get<Page>(
      `${cidadeId}/${this.api}/filtrar?cpf_cnpj$like=${filtroTexto}&cidade_id=${cidadeId}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public obterPorCNPJ(filtroTexto: string, cidadeId: number): Observable<any> {
    return this.http.get<any>(
      `${cidadeId}/${this.api}/cnpj/${filtroTexto}/${cidadeId}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public buscarReceitaWS(cnpj: string, cidadeId: number): Observable<Favorecido> {
    return this.http.get<Favorecido>(
      `${cidadeId}/${this.api}/busca-receita/${cidadeId}/${cnpj}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public proximoIDFavorecido(orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-numero/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  getDividas(id: number, exercicioId: number, orgaoId: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/dividas/${id}/${exercicioId}/${orgaoId}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  consultaSimplesNacional(cnpj: string): Observable<any> {
    return this.http.get<any>(`https://publica.cnpj.ws/cnpj/${cnpj}`).pipe(map(res => res));
  }

  public atualizarRepresentante(favorecido_id: number, representante: string): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/atualizar-represantante/${favorecido_id}/${representante}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
