// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { EtapaMerenda } from './etapa-merenda.model';
import { Favorecido } from '../compra/favorecido.model';
import { Exercicio } from '../comum/exercicio.model';
import { SetorAlmoxarifado } from '../almoxarifado/setor-almoxarifado.model';
import { ReciboItemMerenda } from './recibo-item-merenda.model';

export class ReciboMerenda extends BaseResourceModel {
  constructor(
    public exercicio?: Exercicio,
    public data_entrega?: Date,
    public orgao?: Orgao,
    public email?: string,
    public etapa?: EtapaMerenda,
    public baixado?: boolean,
    public fornecedor?: Favorecido,
    public observacao?: string,
    public setor?: SetorAlmoxarifado,
    public itens?: ReciboItemMerenda[]) {
    super();
  }
  static converteJson(json: any): ReciboMerenda {
    return Object.assign(new ReciboMerenda(), json);
  }
}
