// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido } from '../compra/favorecido.model';
import { MovimentoEstoque } from './movimento-estoque.model';
import { ProdutoConcentracao } from './produto-concentracao.model';
import { ProdutoUnidade } from './produto-unidade.model';
import { Despesa } from '../planejamento/despesa.model';

export class MovimentoItemEstoque extends BaseResourceModel {
  constructor(
    public id?: number,
    public fabricacao?: Date,
    public vencimento?: Date,
    public lote?: string,
    public valor_unitario?: number,
    public qtde?: number,
    public cota?: 'P' | 'R',
    public pendente_incorporacao?: boolean,
    public produto_unidade?: ProdutoUnidade,
    public fabricante?: Favorecido,
    public concentracao?: ProdutoConcentracao,
    public movimento?: MovimentoEstoque,
    public editavel?: boolean,
    public subelemento?: Despesa,
    public total?: number,
    public cod_barra?: string
  ) {
    super();
  }

  static converteJson(json: any): MovimentoItemEstoque {
    return Object.assign(new MovimentoItemEstoque(), json);
  }
}
