// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Setor } from '../almoxarifado/setor.model';
import { Favorecido } from '../compra/favorecido.model';
import { Pessoa } from '../comum/pessoa.model';
import { Usuario } from '../comum/usuario.model';
import { Empenho } from '../contabil/empenho.model';
import { Tombamento } from './tombamento.model';

export class ManutencaoPatrimonio extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_manutencao?: Date,
    public motivo?: string,
    public status?: 'PENDENTE' | 'EM ANDAMENTO' | 'CONCLUIDO' | 'EXCLUIDO',
    public solicitacao?: boolean,
    public data_exclusao?: Date,
    public motivo_exclusao?: string,
    public obs?: string,
    public responsavel?: Pessoa,
    public tombamento?: Tombamento,
    public origem?: Setor,
    public destino?: Setor,
    public favorecido?: Favorecido,
    public usuario?: Usuario,
    public usuario_exclusao?: Usuario,
    public empenho?: Empenho,
  ) {
    super();
  }

  static converteJson(json: any): ManutencaoPatrimonio {
    return Object.assign(new ManutencaoPatrimonio(), json);
  }
}
