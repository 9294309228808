import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, TransferenciaDescendial } from 'eddydata-lib';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TransferenciaDescendialService extends BaseResourceService<TransferenciaDescendial> {

  constructor(
    protected injector: Injector
  ) {
    super('transferencia-descendiais', injector);
  }

  public ultimaDataTransferencia(exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/ultima-data-transferencia/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public marcarComoImpresso(parametros: any): Observable<any> {
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/marcar-como-impresso`, JSON.stringify(parametros), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

  public obn601(parametros: any): Observable<any> {
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/obn-601`,  JSON.stringify(parametros), {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders(this.adicionarCriptografia({
        Authorization: this.login.token,
      }))
    }).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
