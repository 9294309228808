import { BaseResourceModel } from '../../models/base-resource.model';
import { Produto } from '../almoxarifado/produto.model';

export class Oleo extends BaseResourceModel {
  constructor(
    public id?: number,
    public modelo?: string,
    public duracao_km?: number,
    public duracao_tempo?: number,
    public fabricante?: string,
    public garantia?: number,
    public produto?: Produto,
  ) {
    super();
  }

  static converteJson(json: any): Oleo {
    return Object.assign(new Oleo(), json);
  }
}
