import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, ProgressoService, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../assinaturas/assinaturas';
import { AnexoLRFService } from '../../service/anexo-lrf.service';

@Directive()
export class RestosPagarLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLRFService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected progressoService: ProgressoService,
    protected assinaturaControleInterno: boolean) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // tslint:disable: max-line-length
  public montarRelatorio(orgaos: number[], formato: FormatoExportacao = 'pdf') {
    this.anexoServico.obterRestosPagar(this.mes, this.exercicio.id, this.login.cidade.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async idTransacao => {
        let dados
        this.progressoService.show(idTransacao, async (retorno) => {
          dados = retorno
          if (formato === 'pdf') {
            Relatorio.imprimirPersonalizado(
              'DEMONSTRATIVO DE RESTOS A PAGAR',
              this.login.usuario.nome, this.login.usuario.sobrenome,
              this.login.orgao.nome, this.login.brasao,
              await this.conteudo(dados),
              'landscape', 'DEMONSTRATIVO DE RESTOS A PAGAR',
              {
                linhas: {
                  hLineWidth() {
                    return 1;
                  },
                  vLineWidth() {
                    return 1;
                  },
                  hLineColor() {
                    return 'black';
                  },
                  paddingLeft() {
                    return 3;
                  },
                  paddingRight() {
                    return 3;
                  }
                }
              }, true, false);
          } else {
            this.funcaoService.exportar(formato, this.normalizar(dados), 'DEMONSTRATIVO DE RESTOS A PAGAR', this.colunas());
          }
        })
      });
  }

  private async conteudo(dados: any[]): Promise<{}[]> {

    // monta o cabecalho
    const registros: {}[] = [
      [
        {
          text: this.login.orgao.nome,
          alignment: 'center',
          bold: true,
          colSpan: 12, border: [false, false, false, false], margin: [0, 0, 0, -3],
          fontSize: 13,
        }, '', '', '', '', '', '', '', '', '', '', ''
      ],
      [
        {
          text: `DEMONSTRATIVO DE RESTOS A PAGAR`,
          alignment: 'center',
          bold: true,
          colSpan: 12, border: [false, false, false, false], margin: [0, 0, 0, -3],
          fontSize: 12,
        }, '', '', '', '', '', '', '', '', '', '', ''
      ],
      [
        {
          text: `REFERÊNCIA: ` +
            this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano,
          alignment: 'center',
          bold: true,
          colSpan: 12, border: [false, false, false, false], margin: [0, 0, 0, -3]
        }, '', '', '', '', '', '', '', '', '', '', ''
      ],
      [
        {
          text: ' LRF, artigo 53, inciso V',
          alignment: 'left',
          bold: true,
          colSpan: 12, border: [false, false, false, false], margin: [0, 0, 0, -3]
        }, '', '', '', '', '', '', '', '', '', '', ''],
      [
        {
          text: '\n\nPODER / ÓRGÃO',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 3
        },
        {
          text: 'Saldo de Exercícios Anteriores',
          alignment: 'center',
          bold: true, fontSize: 6, colSpan: 2
        },
        { text: '' },
        {
          text: 'Movimentação até o Bimestre',
          alignment: 'center',
          bold: true, fontSize: 6, colSpan: 5
        },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        {
          text: 'Inscrição ao Final do Exercício',
          alignment: 'center',
          bold: true, fontSize: 6, colSpan: 2
        },
        { text: '' },
        {
          text: 'Saldo até o Bimestre',
          alignment: 'center',
          bold: true, fontSize: 6, colSpan: 2
        },
        { text: '' }
      ],
      [
        { text: '' },
        {
          text: '\n\nProcessados',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 2
        },
        {
          text: '\n\nNão Processados',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 2
        },
        {
          text: '\n\nLiquidação',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 2
        },
        {
          text: 'Pagamentos',
          alignment: 'center',
          bold: true, fontSize: 6, colSpan: 2
        },
        { text: '' },
        {
          text: 'Cancelamentos',
          alignment: 'center',
          bold: true, fontSize: 6, colSpan: 2
        },
        { text: '' },
        {
          text: '\n\nProcessados',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 2
        },
        {
          text: '\n\nNão Processados',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 2
        },
        {
          text: '\n\nProcessados',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 2
        },
        {
          text: '\n\nNão Processados',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 2
        }
      ],
      [
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        {
          text: '\nProcessados',
          alignment: 'center',
          bold: true, fontSize: 6
        },
        {
          text: '\nNão Processados',
          alignment: 'center',
          bold: true, fontSize: 6
        },
        {
          text: '\nProcessados',
          alignment: 'center',
          bold: true, fontSize: 6
        },
        {
          text: '\nNão Processados',
          alignment: 'center',
          bold: true, fontSize: 6
        },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' }
      ]
    ];

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);

    // monta o agrupamento do relatório
    const grupos = this.funcaoService.agrupar(dados, 'orgao',
      ['anterior_pr', 'anterior_np', 'liquidado', 'pago_pr', 'pago_np', 'cancelado_pr', 'cancelado_np', 'insc_pr', 'insc_np']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    let total7 = 0;
    let total8 = 0;
    let total9 = 0;
    let total10 = 0;
    let total11 = 0;

    for (const grupo of grupos) {
      registros.push([
        {
          text: grupo.grupo, bold: true, fontSize: 6, border: [false, false, false, false], margin: [0, 0, 0, -3]
        },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['anterior_pr']), alignment: 'right', bold: true, fontSize: 6, border: [false, false, false, false], margin: [0, 0, 0, -3] },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['anterior_np']), alignment: 'right', bold: true, fontSize: 6, border: [false, false, false, false], margin: [0, 0, 0, -3] },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']), alignment: 'right', bold: true, fontSize: 6, border: [false, false, false, false], margin: [0, 0, 0, -3] },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago_pr']), alignment: 'right', bold: true, fontSize: 6, border: [false, false, false, false], margin: [0, 0, 0, -3] },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago_np']), alignment: 'right', bold: true, fontSize: 6, border: [false, false, false, false], margin: [0, 0, 0, -3] },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['cancelado_pr']), alignment: 'right', bold: true, fontSize: 6, border: [false, false, false, false], margin: [0, 0, 0, -3] },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['cancelado_np']), alignment: 'right', bold: true, fontSize: 6, border: [false, false, false, false], margin: [0, 0, 0, -3] },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['insc_pr']), alignment: 'right', bold: true, fontSize: 6, border: [false, false, false, false], margin: [0, 0, 0, -3] },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['insc_np']), alignment: 'right', bold: true, fontSize: 6, border: [false, false, false, false], margin: [0, 0, 0, -3] },
        {
          text: this.funcaoService.convertToBrNumber(
            +grupo.totalizadores['anterior_pr'] - +grupo.totalizadores['pago_pr'] - +grupo.totalizadores['cancelado_pr'] + +grupo.totalizadores['insc_pr']),
          alignment: 'right', bold: true, fontSize: 6, border: [false, false, false, false], margin: [0, 0, 0, -3]
        },
        {
          text: this.funcaoService.convertToBrNumber(
            +grupo.totalizadores['anterior_np'] - +grupo.totalizadores['pago_np'] - +grupo.totalizadores['cancelado_np'] + +grupo.totalizadores['insc_np']),
          alignment: 'right', bold: true, fontSize: 6, border: [false, false, false, false], margin: [0, 0, 0, -3]
        }
      ]);
      total1 += +grupo.totalizadores['anterior_pr'];
      total2 += +grupo.totalizadores['anterior_np'];
      total3 += +grupo.totalizadores['liquidado'];
      total4 += +grupo.totalizadores['pago_pr'];
      total5 += +grupo.totalizadores['pago_np'];
      total6 += +grupo.totalizadores['cancelado_pr'];
      total7 += +grupo.totalizadores['cancelado_np'];
      total8 += +grupo.totalizadores['insc_pr'];
      total9 += +grupo.totalizadores['insc_np'];
      total10 += +grupo.totalizadores['anterior_pr'] - +grupo.totalizadores['pago_pr'] - +grupo.totalizadores['cancelado_pr'] + +grupo.totalizadores['insc_pr'];
      total11 += +grupo.totalizadores['anterior_np'] - +grupo.totalizadores['pago_np'] - +grupo.totalizadores['cancelado_np'] + +grupo.totalizadores['insc_np'];

      for (const item of grupo.registros) {
        registros.push([
          {
            text: `${item.codigo} ${item.variavel ? item.variavel : item.aplicacao}`, fontSize: 6, border: [false, false, false, false], margin: [10, 0, 0, -3]
          },
          { text: this.funcaoService.convertToBrNumber(item.anterior_pr), alignment: 'right', fontSize: 6, border: [false, false, false, false], margin: [10, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(item.anterior_np), alignment: 'right', fontSize: 6, border: [false, false, false, false], margin: [10, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(item.liquidado), alignment: 'right', fontSize: 6, border: [false, false, false, false], margin: [10, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(item.pago_pr), alignment: 'right', fontSize: 6, border: [false, false, false, false], margin: [10, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(item.pago_np), alignment: 'right', fontSize: 6, border: [false, false, false, false], margin: [10, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(item.cancelado_pr), alignment: 'right', fontSize: 6, border: [false, false, false, false], margin: [0, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(item.cancelado_np), alignment: 'right', fontSize: 6, border: [false, false, false, false], margin: [0, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(item.insc_pr), alignment: 'right', fontSize: 6, border: [false, false, false, false], margin: [10, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(item.insc_np), alignment: 'right', fontSize: 6, border: [false, false, false, false], margin: [10, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(+item.anterior_pr - +item.pago_pr - +item.cancelado_pr + +item.insc_pr), alignment: 'right', fontSize: 6, border: [false, false, false, false], margin: [10, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(+item.anterior_np - +item.pago_np - +item.cancelado_np + +item.insc_np), alignment: 'right', fontSize: 6, border: [false, false, false, false], margin: [10, 0, 0, -3] }
        ]);
      }
    }

    registros.push([
      {
        text: 'TOTAL', fontSize: 6, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(total4), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(total5), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(total6), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(total7), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(total8), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(total9), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(total10), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(total11), alignment: 'right', fontSize: 6, bold: true }
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 7,
        widths: [150, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52],
        body: registros
      }
    },
    {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*'],
        body: assinaturas
      }
    }];
  }

  public normalizar(lista) {
    const listaExportar = []
    let anterior_pr = 0;
    let anterior_np = 0;
    let liquidado = 0;
    let pago_pr = 0;
    let pago_np = 0;
    let cancelado_pr = 0;
    let cancelado_np = 0;
    let insc_pr = 0;
    let insc_np = 0;
    let saldoBimestre_pr = 0;
    let saldoBimestre_np = 0;

    const grupos = this.funcaoService.agrupar(lista, 'orgao',
      ['anterior_pr', 'anterior_np', 'liquidado', 'pago_pr', 'pago_np', 'cancelado_pr', 'cancelado_np', 'insc_pr', 'insc_np']); for (const item of grupos) {
        let total1 = 0;
        let total2 = 0;
        let total3 = 0;
        let total4 = 0;
        let total5 = 0;
        let total6 = 0;
        let total7 = 0;
        let total8 = 0;
        let total9 = 0;
        let total10 = 0;
        let total11 = 0;

        for (const grupo of grupos) {

          anterior_pr = grupo.totalizadores['anterior_pr'];
          anterior_np = grupo.totalizadores['anterior_np'];
          liquidado = grupo.totalizadores['liquidado'];
          pago_pr = grupo.totalizadores['pago_pr'];
          pago_np = grupo.totalizadores['pago_np'];
          cancelado_pr = grupo.totalizadores['cancelado_pr'];
          cancelado_np = grupo.totalizadores['cancelado_np'];
          insc_pr = grupo.totalizadores['insc_pr'];
          insc_np = grupo.totalizadores['insc_np'];
          saldoBimestre_pr -= anterior_pr - pago_pr - cancelado_pr + insc_pr
          saldoBimestre_np -= anterior_np - pago_np - cancelado_np + insc_np

          const primeira_linha = {
            orgao: grupo.grupo,
            anterior_pro: this.funcaoService.convertToBrNumber(anterior_pr),
            anterior_npro: this.funcaoService.convertToBrNumber(anterior_np),
            liquidados: this.funcaoService.convertToBrNumber(liquidado),
            pago_pro: this.funcaoService.convertToBrNumber(pago_pr),
            pago_npro: this.funcaoService.convertToBrNumber(pago_np),
            cancelado_pro: this.funcaoService.convertToBrNumber(cancelado_pr),
            cancelado_npro: this.funcaoService.convertToBrNumber(cancelado_np),
            insc_pro: this.funcaoService.convertToBrNumber(insc_pr),
            insc_npro: this.funcaoService.convertToBrNumber(insc_np),
            saldoBimestre_pro: this.funcaoService.convertToBrNumber(-saldoBimestre_pr),
            saldoBimestre_npro: this.funcaoService.convertToBrNumber(-saldoBimestre_np)
          }

          listaExportar.push(primeira_linha)

          total1 += anterior_pr;
          total2 += anterior_np;
          total3 += liquidado;
          total4 += pago_pr;
          total5 += pago_np;
          total6 += cancelado_pr;
          total7 += cancelado_np;
          total8 += insc_pr;
          total9 += insc_np;
          total10 += saldoBimestre_pr;
          total11 += saldoBimestre_np;

          for (const item of grupo.registros) {
            const linhas = {
              orgao: `${item.codigo} ${item.variavel ? item.variavel : item.aplicacao}`,
              anterior_pro: this.funcaoService.convertToBrNumber(item.anterior_pr),
              anterior_npro: this.funcaoService.convertToBrNumber(item.anterior_np),
              liquidados: this.funcaoService.convertToBrNumber(item.liquidado),
              pago_pro: this.funcaoService.convertToBrNumber(item.pago_pr),
              pago_npro: this.funcaoService.convertToBrNumber(item.pago_np),
              cancelado_pro: this.funcaoService.convertToBrNumber(item.cancelado_pr),
              cancelado_npro: this.funcaoService.convertToBrNumber(item.cancelado_np),
              insc_pro: this.funcaoService.convertToBrNumber(item.insc_pr),
              insc_npro: this.funcaoService.convertToBrNumber(item.insc_np),
              saldoBimestre_pro: this.funcaoService.convertToBrNumber(+item.anterior_pr - +item.pago_pr - +item.cancelado_pr + +item.insc_pr),
              saldoBimestre_npro: this.funcaoService.convertToBrNumber(+item.anterior_np - +item.pago_np - +item.cancelado_np + +item.insc_np)
            }
            listaExportar.push(linhas)
          }
        }

        const total = {
          orgao: 'TOTAL',
          anterior_pro: this.funcaoService.convertToBrNumber(total1),
          anterior_npro: this.funcaoService.convertToBrNumber(total2),
          liquidados: this.funcaoService.convertToBrNumber(total3),
          pago_pro: this.funcaoService.convertToBrNumber(total4),
          pago_npro: this.funcaoService.convertToBrNumber(total5),
          cancelado_pro: this.funcaoService.convertToBrNumber(total6),
          cancelado_npro: this.funcaoService.convertToBrNumber(total7),
          insc_pro: this.funcaoService.convertToBrNumber(total8),
          insc_npro: this.funcaoService.convertToBrNumber(total9),
          saldoBimestre_pro: this.funcaoService.convertToBrNumber(total10),
          saldoBimestre_npro: this.funcaoService.convertToBrNumber(-total11),
        }

        listaExportar.push(total)

        return listaExportar
      }
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'PODER / ÓRGÃO', coluna: 'orgao', bold: true },
      { titulo: 'SALDO DE EXERCÍCIOS ANTERIORES PROCESSADOS', coluna: 'anterior_pro' },
      { titulo: 'SALDO DE EXERCÍCIOS ANTERIORES NÃO PROCESSADOS', coluna: 'anterior_npro' },
      { titulo: 'LIQUIDAÇÃO', coluna: 'liquidados' },
      { titulo: 'PAGAMENTOS PROCESSADOS', coluna: 'pago_pro' },
      { titulo: 'PAGAMENTOS NÃO PROCESSADOS', coluna: 'pago_npro' },
      { titulo: 'CANCELAMENTOS PROCESSADOS', coluna: 'cancelado_pro' },
      { titulo: 'CANCELAMENTOS NÃO PROCESSADOS', coluna: 'cancelado_npro' },
      { titulo: 'INSCRIÇÃO AO FINAL DO EXERCÍCIO PROCESSADOS', coluna: 'insc_pro' },
      { titulo: 'INSCRIÇÃO AO FINAL DO EXERCÍCIO NÃO PROCESSADOS', coluna: 'insc_npro' },
      { titulo: 'SALDO ATÉ O BIMESTRE PROCESSADOS', coluna: 'saldoBimestre_pro' },
      { titulo: 'SALDO ATÉ O BIMESTRE NÃO PROCESSADOS', coluna: 'saldoBimestre_npro' },

    ];
    return colunasDefault;
  }
}
