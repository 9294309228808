import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ConvenioAditamento } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ConvenioAditamentoService extends BaseResourceService<ConvenioAditamento> {

  constructor(
    protected injector: Injector
  ) {
    super(`convenio-aditamentos`, injector);
  }

}
