// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ReservaDotacao } from './reserva-dotacao.model';


export class ReservaConvenioEstadual extends BaseResourceModel {
  constructor(
    public id?: number,
    public numero?: string,
    public ano?: string,
    public valor_reserva?: number,
    public valor_contrapartida?: number,
    public reserva?: ReservaDotacao,
    public editavel?: boolean
  ) {
    super();
  }

  static converteJson(json: any): ReservaConvenioEstadual {
    return Object.assign(new ReservaConvenioEstadual(), json);
  }

}
