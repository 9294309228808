import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ListboxModule } from 'primeng/listbox';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { BalancoRoutingModule } from './balanco-routing.module';
import { BalancoComponent } from './balanco.component';
import { NotaExplicativaComponent } from './nota-explicativa/nota-explicativa.component';
import { NotaExplicativaStorageComponent } from './nota-explicativa/nota-explicativa-storage/nota-explicativa-storage.component';
import { SharedModule } from 'eddydata-lib';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  declarations: [BalancoComponent, NotaExplicativaComponent, NotaExplicativaStorageComponent],
  imports: [
    SharedModule,
    FormsModule,
    CommonModule,
    BalancoRoutingModule,
    ListboxModule,
    CalendarModule,
    ConfirmDialogModule,
    FileUploadModule,
    RadioButtonModule
  ],
  providers: [
    ConfirmationService
  ]
})
export class BalancoModule { }
