import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Inventario } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InventarioService extends BaseResourceService<Inventario> {

  constructor(
    protected injector: Injector
  ) {
    super(`inventarios`, injector);
  }

  private adicionaZero(numero) {
    if (numero <= 9) {
      return "0" + numero;
    } else {
      return numero;
    }
  }

  public obterPorFiltro(descricaoInventario: string, statusSelecionado: string, dataInicial: Date, dataFinal: Date): {} {
    const parametros = {};


    if (descricaoInventario)
      parametros['descricao$like'] = `%${descricaoInventario}%`;
    if (statusSelecionado)
      parametros['status$like'] = `%${statusSelecionado}%`;

    if (dataInicial)
      parametros['data_inicio$ge'] = (dataInicial.getFullYear() + "-" + (this.adicionaZero(dataInicial.getMonth() + 1).toString()) + "-" + this.adicionaZero(dataInicial.getDate().toString()));
    if (dataFinal)
      parametros['data_termino$le'] = (dataFinal.getFullYear() + "-" + (this.adicionaZero(dataFinal.getMonth() + 1).toString()) + "-" + this.adicionaZero(dataFinal.getDate().toString()));

    return parametros;
  }

  public obterUltimoInventario(orgao: number): Observable<Inventario> {
    return this.http.get<Inventario>(
      `${this.login.cidade.id}/${this.api}/obter-ultimo/${orgao}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterEstatisticas(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.login.cidade.id}/${this.api}/estatisticas/${id}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public obterEstatisticasSecretaria(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.login.cidade.id}/${this.api}/estatisticas-secretaria/${id}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public obterEstatisticasSecretariaSetores(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.login.cidade.id}/${this.api}/estatisticas-secretaria-setores/${id}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }


  public iniciar(id: number): Observable<number> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/iniciar/${id}`, undefined, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public finalizar(id: number, usuario: number): Observable<number> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/finalizar/${id}?usuario=${usuario}`, undefined, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public validarMovimentacao(orgao: number,setor: number): Observable<number> {
    return this.http.get<number>(`${this.login.cidade.id}/${this.api}/validar-movimentacao/${orgao}/${setor}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    )
  }
}
