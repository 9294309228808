
import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ConvenioReajuste } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ConvenioReajusteService extends BaseResourceService<ConvenioReajuste> {

  constructor(
    protected injector: Injector
  ) {
    super(`convenio-reajustes`, injector);
  }

}
