import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { FracionamentoFormComponent } from './fracionamento-form/fracionamento-form.component';
import { FracionamentoListComponent } from './fracionamento-list/fracionamento-list.component';
import { FracionamentoViewComponent } from './fracionamento-view/fracionamento-view.component';

const routes: Routes = [
  { path: '', component: FracionamentoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: FracionamentoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: FracionamentoViewComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FracionamentoRoutingModule { }
