// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';

export class Storage extends BaseResourceModel {

    constructor(
        public expiracao?: Date,
        public url?: string,
        public tipo?: string,
        public anexo?: Buffer,
        public nome?: string
    ) {
        super();
    }

    static converteJson(json: any): Storage {
        return Object.assign(new Storage(), json);
    }
}
