import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { EddyAutoComplete, GlobalService, Login, Pessoa, PessoaService, Produto, UnidadeMembro } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnidadeMembroService } from '../service/unidade-membro.service';

@Component({
  selector: 'lib-unidade-membro',
  templateUrl: './unidade-membro.component.html'
})
export class UnidadeMembroComponent implements OnInit, OnChanges, OnDestroy {

  public itemAtual: UnidadeMembro;
  public listaMembros: UnidadeMembro[];
  public pessoaAutoComplete: EddyAutoComplete<Pessoa>;
  protected unsubscribe: Subject<void> = new Subject();

  @Input() lista: Array<any>;
  @Input() entidade: Produto;
  @Input() login: Login;

  @ViewChild('btnAdicionar') public btnAdicionar: ElementRef;

  constructor(
    private messageService: MessageService,
    protected pessoaService: PessoaService,
    protected membroService: UnidadeMembroService,
    protected globalService: GlobalService
  ) { }

  ngOnInit() {
    this.listaMembros = this.lista;
    this.carregarAutoCompletes();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lista) {
      this.listaMembros = this.lista;
    }
  }

  public adicionar() {
    this.itemAtual = new UnidadeMembro();
    this.itemAtual.editavel = true;
    if (!this.lista) {
      this.lista = [];
    }
    this.lista.unshift(this.itemAtual);
    this.btnAdicionar.nativeElement.disabled = true;
  }

  public salvar(item: UnidadeMembro) {
    try {
      if (!item.pessoa) {
        throw new Error('Informe a pessoa!');
      }

      // envia a entidade para ser salva no banco -----
      this.btnAdicionar.nativeElement.disabled = false;
      this.itemAtual.editavel = false;
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  public editar(item: UnidadeMembro) {
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.btnAdicionar.nativeElement.disabled = true;
  }

  public cancelar(item: UnidadeMembro) {
    this.itemAtual = item;
    if (!this.itemAtual.unidade && !this.itemAtual.id) {
      this.lista.splice(this.lista.findIndex((itm) => { return itm === item }), 1);
    } else {
      this.itemAtual.editavel = false;
    }
    this.btnAdicionar.nativeElement.disabled = false;
  }

  public remover(item: UnidadeMembro) {
    if (item.id) {
      this.membroService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
        });
    }
    this.lista.splice(this.lista.findIndex((itm) => { return itm === item }), 1);
    this.btnAdicionar.nativeElement.disabled = false;
  }

  private carregarAutoCompletes() {
    this.pessoaAutoComplete = new EddyAutoComplete(null, this.pessoaService,
      'id', ['nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );
  }

}
