// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Setor } from '../almoxarifado/setor.model';
import { Pessoa } from '../comum/pessoa.model';
import { Cargo } from '../folha/cargo.model';
import { Funcionario } from '../folha/funcionario.model';
import { MotoristaAcidente } from './motorista-acidente.model';
import { MotoristaMulta } from './motorista-multa.model';
import { Portaria } from './portaria.model';
import { Veiculo } from './veiculo.model';

export class Motorista extends BaseResourceModel {
  constructor(
    public id?: number,
    public ativo?: boolean,
    public chapa?: string,
    public funcionario?: Funcionario,
    public pessoa?: Pessoa,
    public setor?: Setor,
    public cargo?: Cargo,
    public veiculos_particulares?: Veiculo[],
    public multas?: MotoristaMulta[],
    public acidentes?: MotoristaAcidente[],
    public terceiros?: boolean,
    public veiculo_terceiros?: Veiculo,
    public portaria?: Portaria
  ) {
    super();
  }

  static converteJson(json: any): Motorista {
    return Object.assign(new Motorista(), json);
  }
}
