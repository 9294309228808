import { Component, Input, OnInit } from '@angular/core';
import { Audesp4Publicacao, FuncaoService, RegistroPrecos } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-publicacao',
  templateUrl: './licitacao-audesp-publicacao.component.html'
})
export class LicitacaoAudespPublicacaoComponent extends BaseLicitacaoAudesp implements OnInit {

  @Input() public entidade: RegistroPrecos;
  @Input() public campo: string;

  public itemAtual: Audesp4Publicacao;
  public itemAnterior: Audesp4Publicacao;

  public opcoesVeiculo: { id: number, nome: string }[];

  constructor(
    public funcaoService: FuncaoService,
  ) {
    super()
  }

  ngOnInit(): void {
    this.opcoesVeiculo = [
      { id: 9, nome: 'DJE (Diário da Justiça Eletrônico)' },
      { id: 5, nome: 'DOE' },
      { id: 7, nome: 'DOM' },
      { id: 1, nome: 'DOU' },
      { id: 2, nome: 'Jornal de grande circulação nacional' },
      { id: 4, nome: 'Jornal de grande circulação regional/municipal' },
      { id: 3, nome: 'Outros sites' },
      { id: 8, nome: 'Quadro ou mural de acesso público' },
      { id: 6, nome: 'Site da Administração Direta na Internet' },
    ]
  }

  public adicionar() {
    if (!this.entidade[this.campo] || this.entidade[this.campo].length === 0) this.entidade[this.campo] = [];

    this.itemAtual = {
      VeiculoPublicacao: undefined, PublicacaoData: undefined, PublicacaoDescr: undefined, PublicacaoOficial: 'N', editavel: true
    }
    this.entidade[this.campo].push(this.itemAtual);
  }

  public visualizar(item: Audesp4Publicacao) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      let itm = this.entidade[this.campo].find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.entidade[this.campo].splice(this.entidade[this.campo].indexOf(this.itemAtual), 1, itm)
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4Publicacao) {
    this.itemAtual = undefined;
    this.entidade[this.campo].splice(this.entidade[this.campo].indexOf(item), 1)
  }

  public salvar() {
    if ((this.itemAtual['edicao'] || this.itemAtual.editavel) && !this.itemAtual.VeiculoPublicacao) {
      toastr.warning('Finalize a edição antes de concluir essa ação!');
      return;
    }
    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }

}
