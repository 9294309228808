import { Component, Injector } from '@angular/core';
import { AcaoGoverno, BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, LoginContabil } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AcaoGovernoService } from '../service/acao-governo.service';

@Component({
  selector: 'lib-acao-governo-lst',
  templateUrl: './acao-governo-lst.component.html'
})
export class AcaoGovernoLstComponent extends BaseResourceListComponent<AcaoGoverno, LoginContabil> {

  /**
   * Declaração de variáveis
   */

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private acaoService: AcaoGovernoService) {
    super(acaoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'acao,executora,meta,meta.programa,meta.unidade.orgao';
  }

  protected condicoesGrid(): {} {
    return {
      ['ppa.id']: this.login.ppa.id, ['meta.unidade.orgao.id']: this.login.orgao.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['acao.codigo$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['acao.codigo', 'executora.codigo'],
      text: ['acao.nome', 'executora.nome'],
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: AcaoGoverno): Observable<AcaoGoverno> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'acao.codigo' },
      { titulo: 'Descrição', coluna: 'acao.nome' },
      { titulo: 'Programa', coluna: 'meta.programa.nome' },
      { titulo: 'Unidade', coluna: 'executora.nome' },
      { titulo: String(this.login.ppa.ppaperiodo.ano1), coluna: 'custo1' },
      { titulo: String(this.login.ppa.ppaperiodo.ano2), coluna: 'custo2' },
      { titulo: String(this.login.ppa.ppaperiodo.ano3), coluna: 'custo3' },
      { titulo: String(this.login.ppa.ppaperiodo.ano4), coluna: 'custo4' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'acao,executora,meta,meta.programa';
    this.acaoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE AÇÕES DE GOVERNO: ${this.login.ppa.ppaperiodo.ano1} - ${this.login.ppa.ppaperiodo.ano4}`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem Ações de Governo', ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
