import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { AutoComplete } from 'primeng/autocomplete';
import * as toastr from 'toastr';
import { AssinaturaPessoa } from '../../entidade/comum/assinatura-pessoa.model';
import { Assinatura } from '../../entidade/comum/assinatura.model';
import { Pessoa } from '../../entidade/comum/pessoa.model';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { Storage } from '../../entidade/storage/storage.model';
import { BaseResourceFormComponent } from '../../models/base-resource-form';
import { EddyAutoComplete } from '../../models/form-components';
import { PessoaService } from '../../pessoa/service/pessoa.service';
import { StorageService } from '../../storage/service/storage.service';
import { AssinaturaPessoaService } from '../service/assinatura-pessoa.service';
import { AssinaturaService } from '../service/assinatura.service';

@Component({
  selector: 'lib-assinatura-view',
  templateUrl: './assinatura-view.component.html'
})
export class AssinaturaViewComponent extends BaseResourceFormComponent<Assinatura, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @ViewChild('nome') inputField: ElementRef;
  @ViewChild('pessoa_') campoPessoa: AutoComplete;


  public pessoas: AssinaturaPessoa[] = [];

  public pessoaAutoComplete: EddyAutoComplete<Pessoa>;

  public pessoa: Pessoa

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected confirmationService: ConfirmationService,
    protected pessoaService: PessoaService,
    protected assinaturaPessoaService: AssinaturaPessoaService,
    private storageService: StorageService,
    protected assinaturaService: AssinaturaService) {
    super(new Assinatura(), injector, Assinatura.converteJson, assinaturaService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      nome: [null, [Validators.required]],
      ativo: [true, [Validators.required]],
      sistema: [this.login.sistema, [Validators.required]],      
      orgao: [this.login.orgao, [Validators.required]],
      pessoas: [],
    });
  }

  protected parametrosExtras(): {} {
    return { sistema: this.login.sistema, relations: 'pessoas.pessoa' };
  }

  protected afterLoad() {
    this.pessoas = this.entidade.pessoas;
  }

  protected afterInit(): void {

    this.pessoaAutoComplete = new EddyAutoComplete(null, this.pessoaService,
      'id', ['nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['cpf_cnpj'], text: ['nome'] }
    );
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected async beforeSubmit() {
    try {
      if (this.entidadeForm.get('nome').value === '') {
        throw new Error('Informe o nome!');
      }

      if (this.pessoas && this.pessoas.filter((p) => p['file']).length > 0) {
        const storages: Storage[] = await this.storageService
          .multiplos(this.pessoas
            .filter((p) => p['file']).map((p) => p['file']), this.login.orgao.id, 'COMUM').toPromise();
        let i = 0;
        for (let pessoa of this.pessoas) {
          if (!pessoa['file'])
            continue;
          pessoa.url = storages[i].url;
          delete pessoa['file'];
          i++;
        }
      }

      this.entidadeForm.get('pessoas').setValue(this.pessoas);
    } catch (e) {
      toastr.error('Validação', e);
      throw e;
    }
  }

  selecionarPessoa(event: any) {
    this.pessoa = event
    this.pessoas.map(p => {
      if(p.editavel){
        p.pessoa = this.pessoa;
      }
      return p;
    })
  }

  protected afterSubmit(ent: Assinatura) {
    if (!this.limparTela)
      this.loadResource();
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
