import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Favorecido, LoginContabil } from 'eddydata-lib';
import * as toastr from "toastr";

declare var $: any;

@Component({
  selector: 'lib-compra-favorecidos-dlg',
  templateUrl: './compra-favorecidos-dlg.component.html'
})
export class CompraFavorecidosDlgComponent implements OnInit {

  @Input() public login: LoginContabil;

  @Input() public favorecidos: Favorecido[] = [];
  @Input() public processo: string;

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Output() onSelecionar: EventEmitter<Favorecido> = new EventEmitter();
  
  @Input() static : boolean = false;

  ngOnInit(): void { }

  public onHide() {
    this.hide();
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public selecionarEvent(favorecido: Favorecido) {
    if(+favorecido.tipo.tce !== 1 && +favorecido.tipo.tce !== 2 && +favorecido.tipo.tce !== 10){
      toastr.warning('Verifique a espécie no cadastro de fornecedor');
      this.hide();
      return;
    }
    this.onSelecionar.emit(favorecido);
    this.hide();
  }
}
