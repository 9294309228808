import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LiquidacaoListComponent } from './liquidacao-list/liquidacao-list.component';
import { LiquidacaoViewComponent } from './liquidacao-view/liquidacao-view.component';
import { LiquidacaoFormComponent } from './liquidacao-form/liquidacao-form.component';
import { AuthGuard } from 'eddydata-lib';
import { LiquidacaoRptComponent } from './liquidacao-rpt/liquidacao-rpt.component';
import { RetencaoRptComponent } from './retencao-rpt/retencao-rpt.component';

const routes: Routes = [
  { path: '', component: LiquidacaoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: LiquidacaoFormComponent, canActivate: [AuthGuard] },
  { path: 'novo/:empenho', component: LiquidacaoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: LiquidacaoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: LiquidacaoViewComponent, canActivate: [AuthGuard] },
  { path: 'relatorios', component: LiquidacaoRptComponent, canActivate: [AuthGuard] },
  { path: 'relatorio-retencao', component: RetencaoRptComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LiquidacaoRoutingModule { }
