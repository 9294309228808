import { NgModule } from '@angular/core';

import { IMaskModule } from 'angular-imask';
import { FormsModule } from '@angular/forms';

import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { SharedModule } from 'eddydata-lib';
import { PanelModule } from 'primeng/panel';
import { FieldsetModule } from 'primeng/fieldset';
import { ConsultaEmpenhoViewComponent } from './consulta-empenho-view/consulta-empenho-view.component';
import { ConsultaEmpenhoDlgComponent } from './consulta-empenho-dlg/consulta-empenho-dlg.component';
import { ConsultaEmpenhoRoutingModule } from './consulta-empenho-routing.module';

@NgModule({
  declarations: [ConsultaEmpenhoViewComponent, ConsultaEmpenhoDlgComponent],
  exports: [ConsultaEmpenhoViewComponent, ConsultaEmpenhoDlgComponent],
  imports: [
    SharedModule,
    FormsModule,
    ConsultaEmpenhoRoutingModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    PanelModule,
    FieldsetModule
  ],
  providers: [MessageService]
})
export class ConsultaEmpenhoModule { }
