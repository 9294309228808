import { Component, OnInit, AfterViewInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Contrato, GlobalService, LoginContabil, Pagamento } from 'eddydata-lib';
import { ContratoService, PagamentoService } from 'administrativo-lib';

@Component({
  selector: 'app-contrato-pagamento',
  templateUrl: './contrato-pagamento.component.html'
})
export class ContratoPagamentoComponent implements OnInit, AfterViewInit {

  public entidade: Contrato;
  public login: LoginContabil;
  public listaPagamentos: Array<Pagamento>;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    protected contratoService: ContratoService,
    protected pagtoService: PagamentoService,
  ) {
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.contratoService.obter({id: params.id, relations: 'favorecido' }).subscribe((res) => {
          this.entidade = res;
          this.pagtoService.filtrar(0, -1, {
            'liquidacao.empenho.contrato.id': this.entidade.id,
            exercicio_id: this.login.exercicio.id,
            orgao_id: this.login.orgao.id,
            relations: 'liquidacao,liquidacao.empenho,liquidacao.empenho.favorecido,conta,conta.banco'
          }).subscribe((lista) => {
            this.listaPagamentos = lista.content;
          });
        });
      });
  }

  ngAfterViewInit() {

  }

}
