import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nomeFormatado'
})
export class NomeFormatadoPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    value = value.toLowerCase();
    return value.replace(/\b\w/g, (l) => {
      return l.toUpperCase();
    })
  }
}
