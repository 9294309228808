import { Routes, RouterModule } from '@angular/router';
import { ModalAssinadorComponent } from './modal-assinador.component';
import { NgModule } from '@angular/core';

export const routes: Routes = [
    { path: '', component: ModalAssinadorComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ModalAssinadorRoutingModule { }
