import { Injectable, Injector } from '@angular/core';
import { Nacionalidade } from '../../entidade/comum/nacionalidade.model';
import { BaseResourceService } from '../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class NacionalidadeService extends BaseResourceService<Nacionalidade> {

  constructor(
    protected injector: Injector
  ) {
    super('nacionalidades', injector);
  }
}
