// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { Assunto } from './assunto.model';
import { ProtocoloHistorico } from './protocolo-historico.model';
import { ProtocoloRelacionado } from './protocolo-relacionado.model';
import { Setor } from '../almoxarifado/setor.model';
import { Pessoa } from '../comum/pessoa.model';

export class Protocolo extends BaseResourceModel {
  constructor(
    public id?: number,
    public numero?: number,
    public data_protocolo?: Date,
    public data_termino?: Date,
    public prioridade?: 'N' | 'U',
    public baixado?: string,
    public interessado?: Pessoa,
    public representante?: string,
    public cancelado?: boolean,
    public internet?: boolean,
    public documentoFisico?: boolean,
    public usuario?: Usuario,
    public assunto?: Assunto,
    public remetente?: Setor,
    public destinatario?: Setor,
    public orgao?: Orgao,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public relacionados?: Array<ProtocoloRelacionado>,
    public historico?: ProtocoloHistorico,
    public historicos?: Array<ProtocoloHistorico>
  ) {
    super();
  }
  static converteJson(json: any): Protocolo {
    return Object.assign(new Protocolo(), json);
  }
}
