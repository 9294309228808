import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuncaoService, LoginContabil, Exercicio, Relatorio, GlobalService, OrgaoAssinaturaService, Orgao, ProgressoService, FormatoExportacao } from 'eddydata-lib';
import { BalancoService } from '../service/balanco.service';
import { NotaExplicativaService } from '../service/nota-explicativa.service';
import { BaseResourceNotaExplicativa } from '../base-resource-nota-explicativa';

@Injectable({
  providedIn: 'root'
})
export class Anexo15Balanco extends BaseResourceNotaExplicativa implements OnDestroy {

  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: BalancoService,
    protected notaService: NotaExplicativaService,
    protected progressoService: ProgressoService,
    protected assinaturaService: OrgaoAssinaturaService
  ) {
    super('B15', notaService, assinaturaService)
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // tslint:disable: max-line-length
  public montarRelatorio(exercicio: Exercicio, orgaos: number[], listaOrgaos?: Orgao[], mes?: number, formato?: FormatoExportacao) {
    formato = formato ?? 'pdf';
    this.anexoServico.obterBalancoVariacoes(exercicio.id, orgaos, mes)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async idTransacao => {
        this.progressoService.show(idTransacao, async (dados) => {
          const orgao = orgaos.length === 1 ? listaOrgaos.filter(o => o.id === orgaos[0])[0] : listaOrgaos.filter(o => o.id === 1)[0];
          const consolidado = orgaos.length === 1 ? orgao.nome : 'Consolidado';
          let orgaoNomes: string[] = [];
          for (const o of listaOrgaos) {
            if (orgaos.some(orgao => orgao === o.id)) {
              orgaoNomes.push(`${o.codigo} - ${o.nome}`);
            }
          }
          if (formato === 'pdf') {
            Relatorio.imprimirPersonalizado(
              'ANEXO 15 - DEMONSTRAÇÃO DAS VARIAÇÕES PATRIMONIAIS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
              this.cabecalhoRelatorio(orgao, exercicio, mes)
                .concat(this.conteudo(dados, mes))
                .concat(await this.conteudoNotaExplicativa())
                .concat(await this.conteudoAssinatura(this.login.orgao, orgaoNomes)),
              'portrait', 'ANEXO 15 - DEMONSTRAÇÃO DAS VARIAÇÕES PATRIMONIAIS',
              {
                linhas: {
                  hLineWidth() {
                    return 1;
                  },
                  vLineWidth() {
                    return 1;
                  },
                  hLineColor() {
                    return 'black';
                  },
                  paddingLeft() {
                    return 3;
                  },
                  paddingRight() {
                    return 3;
                  }
                }
              }, false, false, 'pdf', `Unidade Gestora: ${consolidado.toUpperCase()}`);
          } else if (formato === 'csv') {
            this.exportacaoCsv(dados);
          }
        });
      });
  }

  private cabecalhoRelatorio(orgao: Orgao, exercicio: Exercicio, mes?: number): {}[] {
    const registros = [
      [
        { text: `Município:`, alignment: 'left', fontSize: 8 },
        { text: orgao.cidade?.nome ? orgao.cidade.nome : this.login.cidade.nome, alignment: 'center', fontSize: 8 },
        { text: `Exercício:`, alignment: 'left', fontSize: 8 },
        { text: exercicio.ano, alignment: 'center', fontSize: 8 }
      ], [
        { text: `Poder:`, alignment: 'left', fontSize: 8 },
        { text: orgao.especie === 'C' ? 'PODER LEGISLATIVO' : 'PODER EXECUTIVO', alignment: 'center', fontSize: 8 },
        { text: `Mês:`, alignment: 'left', fontSize: 8 },
        { text: mes ? this.globalService.obterMes(+mes) : 'ANUAL', alignment: 'center', fontSize: 8 }
      ], [
        { text: `Órgão:`, alignment: 'left', fontSize: 8, border: [true, true, true, false] },
        { text: orgao.nome, alignment: 'center', fontSize: 8, border: [true, true, true, false] },
        { text: `Acumulado/Mensal:`, alignment: 'left', fontSize: 8, border: [true, true, true, false] },
        { text: mes ? 'MENSAL' : 'ACUMULADO', alignment: 'center', fontSize: 8, border: [true, true, true, false] }
      ], [
        { text: 'Em R$ 1,00', bold: true, alignment: 'right', colSpan: 4, border: [false, true, false, false] },
        '',
        '',
        '',
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*', '*', '*', '*'],
        body: registros
      }
    }];
  }

  private conteudo(dados: any, mes?: number): {}[] {
    // monta o cabecalho
    const registros: {}[] = [
      // [{
      //   text: `EXERCÍCIO ${exercicio.ano}`, fontSize: 13,
      //   alignment: 'center', bold: true, colSpan: 6, border: [true, true, true, false]
      // }, '', '', '', '', ''],
      // [{
      //   text: `${this.login.orgao.especie === 'C' ? 'PODER LEGISLATIVO' : 'PODER EXECUTIVO'}`,
      //   alignment: 'center',
      //   bold: true,
      //   colSpan: 6, border: [true, false, true, false]
      // }, '', '', '', '', ''],
      // [
      //   { text: `Municipio:`, alignment: 'left', fontSize: 8 },
      //   { text: orgao.cidade?.nome ? orgao.cidade.nome : this.login.cidade.nome, alignment: 'center', fontSize: 8, colSpan: 2 },
      //   '',
      //   { text: `Exercício`, alignment: 'left', fontSize: 8 },
      //   { text: exercicio.ano, alignment: 'center', fontSize: 8, colSpan: 2 },
      //   '',
      // ],
      // [
      //   { text: `Poder`, alignment: 'left', fontSize: 8 },
      //   { text: orgao.especie === 'C' ? 'PODER LEGISLATIVO' : 'PODER EXECUTIVO', alignment: 'center', fontSize: 8, colSpan: 2 },
      //   '',
      //   { text: `Mês`, alignment: 'left', fontSize: 8 },
      //   { text: mes ? this.globalService.obterMes(+mes) : '', alignment: 'center', fontSize: 8, colSpan: 2 },
      //   '',
      // ],
      // [
      //   { text: `Orgão:`, alignment: 'left', fontSize: 8, border: [true, true, true, false] },
      //   { text: orgao.nome, alignment: 'center', fontSize: 8, border: [true, true, true, false], colSpan: 2 },
      //   '',
      //   { text: `Acumulado/Mensal:`, alignment: 'left', fontSize: 8, border: [true, true, true, false] },
      //   { text: `Acumulado`, alignment: 'center', fontSize: 8, border: [true, true, true, false], colSpan: 2 },
      //   '',
      // ],
      [{
        text: 'TÍTULOS',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'EXERCÍCIO ATUAL',
        alignment: 'center',
        bold: true, fontSize: 8,
      }, {
        text: 'EXERCÍCIO ANTERIOR',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'TÍTULOS',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'EXERCÍCIO ATUAL',
        alignment: 'center',
        bold: true, fontSize: 8,
      }, {
        text: 'EXERCÍCIO ANTERIOR',
        alignment: 'center',
        bold: true, fontSize: 8
      }]
    ];

    // monta o agrupamento do relatório
    const grupos1 = this.funcaoService.agrupar(dados[0], 'grupo',
      ['valor_atual', 'valor_anterior']);
    const grupos2 = this.funcaoService.agrupar(dados[1], 'grupo',
      ['valor_atual', 'valor_anterior']);
    let totalGrupos = grupos1.length + grupos2.length;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    for (let index = 0; index < totalGrupos; index++) {
      const item1 = grupos1[index];
      const item2 = grupos2[index];

      registros.push([
        {
          text: item1 ? item1.grupo : '', bold: true, fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item1 ? this.funcaoService.convertToBrNumber(item1.totalizadores[`valor_atual`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item1 ? this.funcaoService.convertToBrNumber(item1.totalizadores[`valor_anterior`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item2 ? item2.grupo : '', bold: true, fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item2 ? this.funcaoService.convertToBrNumber(item2.totalizadores[`valor_atual`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, false, true, false]
        },
        {
          text: item2 ? this.funcaoService.convertToBrNumber(item2.totalizadores[`valor_anterior`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, false, true, false]
        }
      ]);

      // lista os registros do relatorio
      const totalRegistro = (item1 ? item1.registros.length : 0) + (item2 ? item2.registros.length : 0);
      for (let idx = 0; idx < totalRegistro; idx++) {
        const element1 = item1 ? item1.registros[idx] : null;
        const element2 = item2 ? item2.registros[idx] : null;

        registros.push([
          {
            text: element1 && element1.nome !== '' ? `${element1 ? element1.nome : ''}` : '', fontSize: 8, border: [true, false, false, false]
          },
          {
            text: element1 && element1.nome !== '' ? this.funcaoService.convertToBrNumber(element1.valor_atual) : '', alignment: 'right',
            fontSize: 8, border: [true, false, false, false]
          },
          {
            text: element1 && element1.nome !== '' ? this.funcaoService.convertToBrNumber(element1.valor_anterior) : '', alignment: 'right',
            fontSize: 8, border: [true, false, false, false]
          },
          {
            text: element2 && element2.nome !== '' ? `${element2 ? element2.nome : ''}` : '', fontSize: 8, border: [true, false, false, false]
          },
          {
            text: element2 && element2.nome !== '' ? this.funcaoService.convertToBrNumber(element2.valor_atual) : '', alignment: 'right',
            fontSize: 8, border: [true, false, false, false]
          },
          {
            text: element2 && element2.nome !== '' ? this.funcaoService.convertToBrNumber(element2.valor_anterior) : '', alignment: 'right',
            fontSize: 8, border: [true, false, true, false]
          }
        ]);
      }
      total1 += item1 ? +item1.totalizadores['valor_atual'] : 0;
      total2 += item1 ? +item1.totalizadores['valor_anterior'] : 0;
      total3 += item2 ? +item2.totalizadores['valor_atual'] : 0;
      total4 += item2 ? +item2.totalizadores['valor_anterior'] : 0;
    }

    registros.push([{
      text: 'Total das Variações Patrimonais Aumentativas',
      border: [true, true, true, true], fontSize: 8, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total1), alignment: 'right',
      border: [true, true, true, true], fontSize: 8, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total2), alignment: 'right',
      border: [true, true, true, true], fontSize: 8, bold: true
    },
    {
      text: 'Total das Variações Patrimonais Diminutivas',
      border: [true, true, false, true], fontSize: 8, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total3), alignment: 'right',
      border: [true, true, true, true], fontSize: 8, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total4), alignment: 'right',
      border: [true, true, true, true], fontSize: 8, bold: true
    }]);

    registros.push([{
      text: '', colSpan: 3
    },
    {
      text: ''
    },
    {
      text: ''
    },
    {
      text: 'Resultado Patrimonial do Período', border: [true, true, false, true], fontSize: 8, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total1 - total3), alignment: 'right',
      border: [true, true, true, true], fontSize: 8, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total2 - total4), alignment: 'right',
      border: [true, true, true, true], fontSize: 8, bold: true
    }]);

    return [{
      layout: 'linhas',
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', 'auto', 'auto', '*', 'auto', 'auto'],
        body: registros
      }
    }];
  }

  private exportacaoCsv(dados: any[]) {

    // monta o cabecalho
    const registros: {}[][] = [
      [
        { text: 'TÍTULOS' },
        { text: 'EXERCÍCIO ATUAL' },
        { text: 'EXERCÍCIO ANTERIOR' },
        { text: 'TÍTULOS' },
        { text: 'EXERCÍCIO ATUAL' },
        { text: 'EXERCÍCIO ANTERIOR' }
      ]
    ];

    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    const grupos1 = this.funcaoService.agrupar(dados[0], 'grupo', ['valor_atual', 'valor_anterior']);
    const grupos2 = this.funcaoService.agrupar(dados[1], 'grupo', ['valor_atual', 'valor_anterior']);
    let totalGrupos = +grupos1.length > +grupos2.length ? +grupos1.length : +grupos2.length;
    for (let index=0; index<totalGrupos; index++) {
      const item1 = grupos1[index];
      const item2 = grupos2[index];

      registros.push([
        { text: item1 ? item1.grupo : '' },
        { text: item1 ? this.funcaoService.convertToBrNumber(item1.totalizadores[`valor_atual`]) : '' },
        { text: item1 ? this.funcaoService.convertToBrNumber(item1.totalizadores[`valor_anterior`]) : '' },
        { text: item2 ? item2.grupo : '' },
        { text: item2 ? this.funcaoService.convertToBrNumber(item2.totalizadores[`valor_atual`]) : '' },
        { text: item2 ? this.funcaoService.convertToBrNumber(item2.totalizadores[`valor_anterior`]) : '' }
      ]);

      const totalRegistro = (item1 ? item1.registros.length : 0) > (item2 ? item2.registros.length : 0) ? (item1 ? item1.registros.length : 0) : (item2 ? item2.registros.length : 0);
      for (let idx = 0; idx < totalRegistro; idx++) {
        const element1 = item1 ? item1.registros[idx] : null;
        const element2 = item2 ? item2.registros[idx] : null;

        registros.push([
          { text: element1 && element1.nome !== '' ? `${element1 ? element1.nome : ''}` : '' },
          { text: element1 && element1.nome !== '' ? this.funcaoService.convertToBrNumber(element1.valor_atual) : '' },
          { text: element1 && element1.nome !== '' ? this.funcaoService.convertToBrNumber(element1.valor_anterior) : '' },
          { text: element2 && element2.nome !== '' ? `${element2 ? element2.nome : ''}` : '' },
          { text: element2 && element2.nome !== '' ? this.funcaoService.convertToBrNumber(element2.valor_atual) : '' },
          { text: element2 && element2.nome !== '' ? this.funcaoService.convertToBrNumber(element2.valor_anterior) : '' }
        ]);
      }

      total1 += item1 ? +item1.totalizadores['valor_atual'] : 0;
      total2 += item1 ? +item1.totalizadores['valor_anterior'] : 0;
      total3 += item2 ? +item2.totalizadores['valor_atual'] : 0;
      total4 += item2 ? +item2.totalizadores['valor_anterior'] : 0;
    }

    registros.push([
      { text: 'Total das Variações Patrimonias Aumentativas' },
      { text: this.funcaoService.convertToBrNumber(total1) },
      { text: this.funcaoService.convertToBrNumber(total2) },
      { text: 'Total das Variações Patrimonias Diminutivas' },
      { text: this.funcaoService.convertToBrNumber(total3) },
      { text: this.funcaoService.convertToBrNumber(total4) }
    ]);
    registros.push([
      { text: '' }, { text: '' }, { text: '' },
      { text: 'Resultado Patrimonial do Período' },
      { text: this.funcaoService.convertToBrNumber(total1 - total3) },
      { text: this.funcaoService.convertToBrNumber(total2 - total4) }
    ]);

    dados = null;
    let csv = '';
    for (let i=0; i<registros.length; i++) {
      const linha = registros[i];
      if (i > 0) csv += '\n';
      for (let x=0; x<linha.length; x++) {
        if (x > 0) csv += ';';
        csv += String(linha[x]['text']);
      }
    }

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + csv));
    element.setAttribute("download", `ANEXO 15 - DEMONSTRAÇÃO DAS VARIAÇÕES PATRIMONIAIS.csv`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

}
