import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmpenhoExtraListComponent } from './empenho-extra-list/empenho-extra-list.component';
import { EmpenhoExtraFormComponent } from './empenho-extra-form/empenho-extra-form.component';
import { AuthGuard } from 'eddydata-lib';
import { EmpenhoExtraRptComponent } from './empenho-extra-rpt/empenho-extra-rpt.component';


const routes: Routes = [
  { path: '', component: EmpenhoExtraListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: EmpenhoExtraFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: EmpenhoExtraFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: EmpenhoExtraFormComponent, canActivate: [AuthGuard] },
  { path: 'relatorios', component: EmpenhoExtraRptComponent, canActivate: [AuthGuard] }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmpenhoExtraRoutingModule { }
