import { DatePipe } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContratoService, DespesaService, FuncaoGovernoService, RecursoService } from 'administrativo-lib';
import { Coluna, Contrato, Despesa, EddyAutoComplete, FuncaoGoverno, FuncaoService, GlobalService, Login, Recurso, Relatorio } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { PreLiquidacaoRestoService } from '../../pre-liquidacao-resto/service/pre-liquidacao-resto.service';
import { PreLiquidacaoService } from '../service/pre-liquidacao.service';

@Component({
  selector: 'lib-pre-liquidacao-rpt',
  templateUrl: './pre-liquidacao-rpt.component.html'
})
export class PreLiquidacaoRptComponent implements OnInit, OnDestroy {

  login: Login = new Login();
  funcaoGoverno: FuncaoGoverno;
  subFuncaoGoverno: FuncaoGoverno;
  despesa: Despesa;
  recurso: Recurso;
  aplicacao: Recurso;
  contrato: Contrato;
  filtroDespesa: boolean;
  filtroFuncao: boolean;
  filtroSubFuncao: boolean;
  filtroRecurso: boolean;
  filtroAplicacao: boolean;
  filtroContrato: boolean;
  liquidado: boolean = true;
  anulados: boolean = true;
  public selectedOrdem: string;
  public selectUsuario: boolean;
  public ptBR: any;

  public listaRelatorio: Array<any>;
  public contratoAutoComplete: EddyAutoComplete<Contrato>;
  public funcaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public subFuncaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public despesaAutoComplete: EddyAutoComplete<Despesa>;
  public relatorio = '1';
  public dataInicial: Date;
  public dataFinal: Date;
  public selectedOrdenacao: string;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    protected injector: Injector,
    protected messageService: MessageService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected funcaoGovernoService: FuncaoGovernoService,
    protected recursoService: RecursoService,
    protected despesaService: DespesaService,
    protected contratoService: ContratoService,
    private preLiquidacaoService: PreLiquidacaoService,
    private PreLiquidacaoRestoService: PreLiquidacaoRestoService
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.login = GlobalService.obterSessaoLogin();
    this.selectedOrdem = 'ord1';
    this.selectedOrdenacao = 'ASC';
    this.selectUsuario = false;
    this.dataInicial = new Date(this.login.exercicio.ano, 0, 1);
    this.dataFinal = new Date();
    this.listaRelatorio = [];
    this.listaRelatorio.push({ label: 'PRÉ-LIQUIDAÇÕES ORÇAMENTÁRIAS', value: 1 });
    this.listaRelatorio.push({ label: 'PRÉ-LIQUIDAÇÕES DE RESTOS', value: 2 });
    this.carregarAutoCompletes();
    let interval = setInterval(() => { new GlobalService().calendarMascara(); clearInterval(interval) }, 1000)
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  imprimir(
    titulo: string, usuario: string, sobrenome: string, orgao: string,
    brasao: string, orientacao: 'landscape' | 'portrait',
    nomepdf?: string, largura?: string[], lista?: any[]) {
    Relatorio.imprimir(titulo, usuario, sobrenome, orgao, brasao, lista, this.colunas(), orientacao, nomepdf, largura, ['valor_liquidado']);
  }

  colunas(): string[] | Coluna[] {
    if (this.relatorio === '1') {
      return [
        { titulo: 'Data', coluna: 'data_liquidacao' },
        { titulo: 'Empenho', coluna: 'empenho.numero' },
        { titulo: 'Ficha', coluna: 'empenho.ficha.numero' },
        { titulo: 'Benefíciario', coluna: 'empenho.favorecido.nome' },
        { titulo: 'Documento', coluna: 'documento' },
        { titulo: 'Valor', coluna: 'valor_liquidado', decimais: 2 }
      ];
    }else{
      return [
        { titulo: 'Data', coluna: 'data_liquidacao' },
        { titulo: 'Empenho', coluna: 'empenho.numero' },
        { titulo: 'Benefíciario', coluna: 'empenho.favorecido.nome' },
        { titulo: 'Documento', coluna: 'documento' },
        { titulo: 'Valor', coluna: 'valor_liquidado', decimais: 2 }
      ];
    }
  }

  gerarRelatorio() {
    const parametros = {};
    if (!this.dataInicial || !this.dataFinal) {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }

    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;

    parametros['data_liquidacao$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
    parametros['data_liquidacao$le'] = this.funcaoService.converteDataSQL(this.dataFinal);

    if (this.filtroContrato) {
      parametros['empenho.contrato.numero'] = this.contrato.numero;
    }

    if (this.filtroFuncao) {
      parametros[this.relatorio === '1' ? 'empenho.ficha.funcao.id' : 'empenho.funcao'] = (this.relatorio === '1' ? this.funcaoGoverno.id : this.funcaoGoverno.codigo);
    }
    if (this.filtroSubFuncao) {
      parametros[this.relatorio === '1' ? 'empenho.ficha.subfuncao.id' : 'empenho.subfuncao'] = (this.relatorio === '1' ? this.subFuncaoGoverno.id : this.subFuncaoGoverno.codigo);
    }

    if (this.filtroDespesa) {
      parametros[this.relatorio === '1' ? 'empenho.subelemento.id' : 'empenho.subelemento'] = (this.relatorio === '1' ? this.despesa.id : this.despesa.codigo);
    }

    if (this.filtroRecurso) {
      parametros[this.relatorio === '1' ? 'empenho.ficha.recurso.id' : 'empenho.recurso'] = (this.relatorio === '1' ? this.recurso.id : this.recurso.codigo);
    }

    if (this.filtroAplicacao) {
      parametros[this.relatorio === '1' ? 'empenho.ficha.aplicacao.id' : 'empenho.aplicacao'] = (this.relatorio === '1' ? this.aplicacao.id : this.aplicacao.codigo);
    }

    if (this.selectUsuario) {
      parametros['usuario_cadastro.id'] = this.login.usuario.id;
    }

    if (this.selectedOrdem === 'ord1') {
      parametros['orderBy'] = 'data_liquidacao$' + this.selectedOrdenacao;;
    } else if (this.selectedOrdem === 'ord2') {
      parametros['orderBy'] = 'empenho.numero$' + this.selectedOrdenacao;;
    } else if (this.selectedOrdem === 'ord3') {
      parametros['orderBy'] = 'empenho.favorecido.nome$' + this.selectedOrdenacao;;
    }

    // tslint:disable-next-line: max-line-length
    if (this.relatorio === '1') {
      parametros['relations'] = 'empenho,empenho.favorecido,empenho.subelemento,empenho.ficha,empenho.ficha.funcao,empenho.ficha.subfuncao,empenho.ficha.recurso,empenho.ficha.aplicacao';
    } else {
      parametros['relations'] = 'empenho,empenho.favorecido';
    }

    let titulo = '';
    if (this.anulados && !this.liquidado) {
      titulo = 'DE ANULAÇÃO';
      parametros['anulacao'] = 'true';
    } else if (!this.anulados && this.liquidado) {
      parametros['anulacao'] = 'false';
    }

    if (this.relatorio === '1') {
      this.preLiquidacaoService
        .filtrar(1, -1, parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(lista => {
          if(lista.content.length == 0){
            toastr.info('Sem itens para imprimir');
            return;
          }
          const param = {};
          param['exercicio_id'] = this.login.exercicio.id;
          param['orgao.id'] = this.login.orgao.id;
          let datepipe = new DatePipe('pt');
          this.imprimir(`DOCUMENTO: LISTAGEM ${titulo} DE PRÉ-LIQUIDAÇÃO NO PERÍODO DE ${datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
            this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
            'Listagem liquidacoes', ['auto', 'auto', 'auto', '*', 'auto', 'auto'], lista.content);
        },
          (error) => this.messageService.add(
            { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
          )
        );
    } else {
      this.PreLiquidacaoRestoService
        .filtrar(1, -1, parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(lista => {
          if(lista.content.length == 0){
            toastr.info('Sem itens para imprimir');
            return;
          }
          const param = {};
          param['exercicio_id'] = this.login.exercicio.id;
          param['orgao.id'] = this.login.orgao.id;
          let datepipe = new DatePipe('pt');
          this.imprimir(`DOCUMENTO: LISTAGEM ${titulo} DE PRÉ-LIQUIDAÇÃO NO PERÍODO DE ${datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
            this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
            'Listagem liquidacoes', ['auto', 'auto', 'auto', '*', 'auto'], lista.content);
        },
          (error) => this.messageService.add(
            { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
          )
        );
    }

  }

  private carregarAutoCompletes() {
    // autocomplete para funcao governo
    this.funcaoAutoComplete = new EddyAutoComplete(null, this.funcaoGovernoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para subfuncao governo
    this.subFuncaoAutoComplete = new EddyAutoComplete(null, this.funcaoGovernoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para despesa
    this.despesaAutoComplete = new EddyAutoComplete(null, this.despesaService,
      'id', ['codigo', 'nome'], { nivel: 6, exercicio_id: this.login.exercicio.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para recurso
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para contrato
    this.contratoAutoComplete = new EddyAutoComplete(null, this.contratoService,
      'id', ['numero', 'favorecido.nome'], {
      orgao_id: this.login.orgao.id, relations: 'favorecido',
      orderBy: 'favorecido.nome'
    }, { number: ['numero'], text: ['favorecido.nome'] }
    );
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }
}
