// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ProdutoUnidade } from '../almoxarifado/produto-unidade.model';
import { Produto } from '../almoxarifado/produto.model';
import { RcmsItem } from '../compra/rcms-item.model';
import { Memorial } from './memorial.model';
import { RplItem } from './rpl-item.model';

export class MemorialItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public quantidade?: number,
    public descricao?: string,
    public memorial?: Memorial,
    public produto?: Produto,
    public produto_unidade?: ProdutoUnidade,
    public editavel?: boolean,
    public valor_referencia?: number,
    public unidade?: string,
    public rpl_itens?: RplItem[],
    public rcms_itens?: RcmsItem[],
  ) {
    super();
  }
}
