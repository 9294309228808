import { Component, Input } from '@angular/core';
import { Favorecido, FavorecidoDeducoes, FavorecidoDependente, FavorecidoExterior, FavorecidoInsencao, FavorecidoPlanoSaude, FuncaoService, Login, PaisesService } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import * as toastr from 'toastr';
import { FavorecidoDeducoesService } from '../service/favorecido-deducoes.service';
import { FavorecidoDependenteService } from '../service/favorecido-dependente.service';
import { FavorecidoExteriorService } from '../service/favorecido-exterior.service';
import { FavorecidoInsencaoService } from '../service/favorecido-insencao.service';
import { FavorecidoPlanoSaudeService } from '../service/favorecido-plano-saude.service';

@Component({
  selector: 'lib-favorecido-reinf',
  templateUrl: './favorecido-reinf.component.html'
})
export class FavorecidoReinfComponent {

  @Input() public login: Login;
  @Input() favorecido: Favorecido;
  tabIndex: number = 1;
  public ptBR: any;

  listaDependentes: Array<FavorecidoDependente> = new Array<FavorecidoDependente>();
  dependenteAntigo: FavorecidoDependente;

  listaDeducoes: Array<FavorecidoDeducoes> = new Array<FavorecidoDeducoes>();
  deducaoAntigo: FavorecidoDeducoes;

  listaInsencoes: Array<FavorecidoInsencao> = new Array<FavorecidoInsencao>();
  insencaoAntigo: FavorecidoInsencao;

  listaSaude: Array<FavorecidoPlanoSaude> = new Array<FavorecidoPlanoSaude>();
  saudeAntigo: FavorecidoPlanoSaude

  dadosExterior: FavorecidoExterior = new FavorecidoExterior();
  paises: { codigo: number, nome: string }[] = [];

  public imaskConfig = {
    mask: Number,
    scale: 2,
    signed: true,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(private dependenteService: FavorecidoDependenteService,
    private deducoesService: FavorecidoDeducoesService,
    private insencaoService: FavorecidoInsencaoService,
    private exteriorService: FavorecidoExteriorService,
    protected paisesService: PaisesService,
    private saudeService: FavorecidoPlanoSaudeService,
    private funcaoService: FuncaoService,
    private confirmationService: ConfirmationService,) {
  }

  ngOnInit(): void {
    this.paises = this.paisesService.listaPaisesReinf();
    this.ngOnChanges();
  }

  ngOnChanges() {
    if (this.favorecido.id) {
      this.buscarDependentes();
      this.buscarDeducoes();
      this.buscarInsencoes();
      this.buscarExterior();
      this.buscarSaude();
    }
  }

  // BLOCO DE DEPENDENTES -------------------------------------------------------------------------------------------------
  async buscarDependentes() {
    const dependentes = await this.dependenteService.filtrar(0, -1, { 'favorecido.id': this.favorecido.id }).toPromise();
    this.listaDependentes = [];
    this.listaDependentes = this.listaDependentes.concat(dependentes.content);
  }

  adicionarDependente() {
    if (!this.listaDependentes || this.listaDependentes.filter((e) => e['editavel']).length > 0) {
      toastr.warning('Já existe um item em edição, salve ou cancele para adicionar outro item');
      return;
    }
    const dependente: FavorecidoDependente = new FavorecidoDependente();
    dependente.favorecido = this.favorecido;
    dependente['editavel'] = true;
    this.listaDependentes.push(dependente);
  }

  editarDependente(d: FavorecidoDependente) {
    if (!this.listaDependentes || this.listaDependentes.filter((e) => e['editavel']).length > 0) {
      toastr.warning('Já existe um item em edição, salve ou cancele para editar outro item');
      return;
    }
    this.dependenteAntigo = Object.assign({}, d);
    d['editavel'] = true;
  }

  cancelarDependente(d: FavorecidoDependente, index: number) {
    if (!this.dependenteAntigo) {
      this.listaDependentes.splice(index, 1);
    } else {
      d = Object.assign({}, this.dependenteAntigo);
      this.listaDependentes.splice(index, 1, d);
    }
    this.dependenteAntigo = null;
  }

  salvarDependente(d: FavorecidoDependente) {
    if (d.relacao != 99) {
      d.descricao = null;
    }

    if (this.listaDependentes.filter((dep) => dep.cpf == d.cpf).length > 1) {
      toastr.warning('CPF duplicado para os dependentes, cada dependente pode ter apenas um registro.');
      return;
    }
    if (d.id) {
      this.dependenteService.atualizar(d).subscribe(() => {
        d['editavel'] = false;
        toastr.success('Dependente atualizado com sucesso!');
      }, (e) => {
        toastr.error('Falha ao atualizar dependente, ' + (e.error?.erro ? e.error.erro : e.error?.payload ? e.error.payload : JSON.stringify(e)));
      });
    } else {
      this.dependenteService.inserir(d).subscribe((res) => {
        this.buscarDependentes();
        toastr.success('Dependente atualizado com sucesso!');
      }, (e) => {
        toastr.error('Falha ao salvar dependente, ' + (e.error?.erro ? e.error.erro : e.error?.payload ? e.error.payload : JSON.stringify(e)));
      });
    }
  }

  apagarDependente(d: FavorecidoDependente, index: number) {
    this.confirmationService.confirm({
      message: 'Deseja remover esse dependente do favorecido?',
      header: 'Remoção de dependente',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      key: 'favarecido',
      accept: () => {
        this.dependenteService.remover(d.id).subscribe(() => {
          this.buscarDependentes();
          toastr.success('Dependente removido com sucesso!');
        }, (e) => {
          toastr.error('Falha ao remover dependente, ' + (e.error?.erro ? e.error.erro : e.error?.payload ? e.error.payload : JSON.stringify(e)));
        });
      }
    });
  }

  // BLOCO DE DEDUÇÕES -------------------------------------------------------------------------------------------------
  async buscarDeducoes() {
    const deducoes = await this.deducoesService.filtrar(0, -1, { 'favorecido.id': this.favorecido.id }).toPromise();
    this.listaDeducoes = [];
    this.listaDeducoes = this.listaDeducoes.concat(deducoes.content);
  }

  calcularValor(d: FavorecidoDeducoes) {
    if (d.tipo != 5 && d.tipo != 7) {
      return;
    }

    for (const dep of this.listaDependentes) {
      d.valor_deducao = +(d.valor_deducao + +(d.tipo == 5 ? (+dep.valor_pensao) : (+dep.valor_deducao))).toFixed(2);
    }
  }

  adicionarDeducao() {
    if (!this.listaDeducoes || this.listaDeducoes.filter((e) => e['editavel']).length > 0) {
      toastr.warning('Já existe um item em edição, salve ou cancele para adicionar outro item');
      return;
    }

    const deducao: FavorecidoDeducoes = new FavorecidoDeducoes();
    deducao.favorecido = this.favorecido;
    deducao['editavel'] = true;
    this.listaDeducoes.push(deducao);
  }

  editarDeducao(d: FavorecidoDeducoes) {
    if (!this.listaDeducoes || this.listaDeducoes.filter((e) => e['editavel']).length > 0) {
      toastr.warning('Já existe um item em edição, salve ou cancele para editar outro item');
      return;
    }
    this.deducaoAntigo = Object.assign({}, d);
    d['editavel'] = true;
  }

  cancelarDeducao(d: FavorecidoDeducoes, index: number) {
    if (!this.deducaoAntigo) {
      this.listaDeducoes.splice(index, 1);
    } else {
      d = Object.assign({}, this.deducaoAntigo);
      this.listaDeducoes.splice(index, 1, d);
    }
    this.deducaoAntigo = null;
  }

  salvarDeducao(d: FavorecidoDeducoes) {

    if (d.id) {
      this.deducoesService.atualizar(d).subscribe(() => {
        d['editavel'] = false;
        toastr.success('Dedução atualizado com sucesso!');
      }, (e) => this.funcaoService.acaoErro(e));
    } else {
      this.deducoesService.inserir(d).subscribe((res) => {
        this.buscarDeducoes();
        toastr.success('Dedução atualizado com sucesso!');
      }, (e) => {
        this.funcaoService.acaoErro(e);
      });
    }
  }

  apagarDeducao(d: FavorecidoDeducoes, index: number) {
    this.confirmationService.confirm({
      message: 'Deseja remover essa dedução do favorecido?',
      header: 'Remoção de Dedução',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      key: 'favarecido',
      accept: () => {
        this.deducoesService.remover(d.id).subscribe(() => {
          this.buscarDeducoes();
          toastr.success('Dedução removida com sucesso');
        }, (e) => { this.funcaoService.acaoErro(e) });
      }
    });
  }

  //BLOCO INSENÇÕES--------------------------------------------------------------------------------------------
  async buscarInsencoes() {
    const deducoes = await this.insencaoService.filtrar(0, -1, { 'favorecido.id': this.favorecido.id }).toPromise();
    this.listaInsencoes = [];
    this.listaInsencoes = this.listaInsencoes.concat(deducoes.content);
  }

  adicionarInsencao() {
    if (!this.listaInsencoes || this.listaInsencoes.filter((e) => e['editavel']).length > 0) {
      toastr.warning('Já existe um item em edição, salve ou cancele para adicionar outro item');
      return;
    }

    const insencao: FavorecidoInsencao = new FavorecidoInsencao();
    insencao.favorecido = this.favorecido;
    insencao['editavel'] = true;
    this.listaInsencoes.push(insencao);
  }

  editarInsencao(d: FavorecidoInsencao) {
    if (!this.listaInsencoes || this.listaInsencoes.filter((e) => e['editavel']).length > 0) {
      toastr.warning('Já existe um item em edição, salve ou cancele para editar outro item');
      return;
    }
    this.insencaoAntigo = Object.assign({}, d);
    d['editavel'] = true;
  }

  cancelarInsencao(d: FavorecidoInsencao, index: number) {
    if (!this.insencaoAntigo) {
      this.listaInsencoes.splice(index, 1);
    } else {
      d = Object.assign({}, this.insencaoAntigo);
      this.listaInsencoes.splice(index, 1, d);
    }
    this.insencaoAntigo = null;
  }

  salvarInsencao(d: FavorecidoInsencao) {
    if (d.id) {
      this.insencaoService.atualizar(d).subscribe(() => {
        d['editavel'] = false;
        toastr.success('Insenção atualizado com sucesso!');
      }, (e) => this.funcaoService.acaoErro(e));
    } else {
      this.insencaoService.inserir(d).subscribe((res) => {
        this.buscarInsencoes();
        toastr.success('Insenção atualizado com sucesso!');
      }, (e) => {
        this.funcaoService.acaoErro(e);
      });
    }
  }

  apagarInsencao(d: FavorecidoInsencao, index: number) {
    this.confirmationService.confirm({
      message: 'Deseja remover essa insenção do favorecido?',
      header: 'Remoção de Insenção',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      key: 'favarecido',
      accept: () => {
        this.deducoesService.remover(d.id).subscribe(() => {
          this.buscarInsencoes();
          toastr.success('Insenção removida com sucesso');
        }, (e) => { this.funcaoService.acaoErro(e) });
      }
    });
  }

  //BLOCO SAUDE-----------------------------------------------------------------------------------------------
  async buscarSaude() {
    const saude = await this.saudeService.filtrar(0, -1, { 'favorecido.id': this.favorecido.id, relations: 'dependente' }).toPromise();
    this.listaSaude = [];
    this.listaSaude = this.listaSaude.concat(saude.content);
  }

  adicionarSaude() {
    if (!this.listaSaude || this.listaSaude.filter((e) => e['editavel']).length > 0) {
      toastr.warning('Já existe um item em edição, salve ou cancele para adicionar outro item');
      return;
    }

    const saude: FavorecidoPlanoSaude = new FavorecidoPlanoSaude();
    saude.favorecido = this.favorecido;
    saude['editavel'] = true;
    this.listaSaude.push(saude);
  }

  editarSaude(d: FavorecidoPlanoSaude) {
    if (!this.listaSaude || this.listaSaude.filter((e) => e['editavel']).length > 0) {
      toastr.warning('Já existe um item em edição, salve ou cancele para editar outro item');
      return;
    }
    this.saudeAntigo = Object.assign({}, d);
    d['editavel'] = true;
  }

  cancelarSaude(d: FavorecidoPlanoSaude, index: number) {
    if (!this.saudeAntigo) {
      this.listaSaude.splice(index, 1);
    } else {
      d = Object.assign({}, this.saudeAntigo);
      this.listaSaude.splice(index, 1, d);
    }
    this.saudeAntigo = null;
  }

  salvarSaude(d: FavorecidoPlanoSaude) {
    if (d.id) {
      this.saudeService.atualizar(d).subscribe(() => {
        d['editavel'] = false;
        toastr.success('Informação atualizada com sucesso!');
      }, (e) => this.funcaoService.acaoErro(e));
    } else {
      this.saudeService.inserir(d).subscribe((res) => {
        this.buscarSaude();
        toastr.success('Informação atualizada com sucesso!');
      }, (e) => {
        this.funcaoService.acaoErro(e);
      });
    }
  }

  apagarSaude(d: FavorecidoPlanoSaude, index: number) {
    this.confirmationService.confirm({
      message: 'Deseja remover essa informação do favorecido?',
      header: 'Remoção de Informação Saúde',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      key: 'favarecido',
      accept: () => {
        this.saudeService.remover(d.id).subscribe(() => {
          this.buscarSaude();
          toastr.success('Informação removida com sucesso');
        }, (e) => { this.funcaoService.acaoErro(e) });
      }
    });
  }


  //BLOCO EXTERIOR--------------------------------------------------------------------------------------------

  async buscarExterior() {
    const dadosExterior = await this.exteriorService.filtrar(0, -1, { 'favorecido.id': this.favorecido.id }).toPromise();
    if (dadosExterior.content[0]) {
      this.dadosExterior = dadosExterior.content[0];
    }
  }

  adicionarExterior() {
    this.dadosExterior.favorecido = this.favorecido
    if (this.dadosExterior.id) {
      this.exteriorService.atualizar(this.dadosExterior).subscribe(() => {
        toastr.success('Dados exterior atualizados com sucesso!');
      })
    } else {
      this.exteriorService.inserir(this.dadosExterior).subscribe((res) => {
        this.dadosExterior = res;
        toastr.success('Dados exterior inseridos com sucesso!');
      })
    }
  }
}
