import { Directive, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { QuadroSaudeService } from '../service/quadro-saude.service';
import { FuncaoService, GlobalService, Login, Exercicio, Relatorio, FormatoExportacao, Coluna, OrgaoAssinatura, OrgaoAssinaturaService } from 'eddydata-lib';

@Directive()
export class Quadro2Saude implements OnDestroy {
  private assinatura: OrgaoAssinatura;
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected quadroServico: QuadroSaudeService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected assinaturaService: OrgaoAssinaturaService,
  ) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async listar(orgaos: number[]) {
    return await this.quadroServico
      .obterQuadro2(this.mes, this.exercicio.id, orgaos).toPromise();
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
    this.quadroServico.obterQuadro2(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(
            'QUADRO 2 - RECEITAS VINCULADAS - SAÚDE'
            , this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.conteudo(dados),
            'portrait', 'QUADRO 2 - RECEITAS VINCULADAS - SAÚDE',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            });
        } else {
          this.funcaoService.exportar(formato, this.conteudoExportacao(dados), 'QUADRO 2 - RECEITAS VINCULADAS - SAÚDE', this.colunasRelatorio());
        }
      });
  }

  private async conteudo(dados: any[]): Promise<{}[]> {

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `REFERÊNCIA: ${this.mes ? this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano : this.exercicio.ano}`,
        alignment: 'center',
        fontSize: 11,
        margin: [5, 5, 5, 5],
        bold: true,
        colSpan: 6
      }, '', '', '', '', ''],
      [{
        text: 'FONTE',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'CÓD.APLIC.',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'CLASSIF. ECONÔMICA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'PREVISÃO INICIAL',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'PREVISÃO ATUALIZ',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'ARRECADADA ATÉ O PERÍODO',
        alignment: 'center',
        bold: true, fontSize: 6
      }]
    ];

    // monta o agrupamento do relatório
    const grupos = this.funcaoService.agrupar(dados[0], 'grupo',
      ['previsao_inicial', 'previsao_atualizada', 'arrecadada']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    for (const grupo of grupos) {
      registros.push([
        {
          text: grupo.grupo,
          alignment: 'center',
          bold: true,
          colSpan: 6
        }, '', '', '', '', '']);
      total1 += +grupo.totalizadores['previsao_inicial'];
      total2 += +grupo.totalizadores['previsao_atualizada'];
      total3 += +grupo.totalizadores['arrecadada'];
      // RECURSOS --------------------------------------------------------------------------------
      const recursos = this.funcaoService.agrupar(grupo.registros, 'recurso',
        ['previsao_inicial', 'previsao_atualizada', 'arrecadada']);
      for (const recurso of recursos) {
        registros.push([
          {
            text: recurso.grupo, bold: true, fontSize: 7
          },
          {
            text: ''
          },
          {
            text: ''
          },
          {
            text: this.funcaoService.convertToBrNumber(recurso.totalizadores['previsao_inicial']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(recurso.totalizadores['previsao_atualizada']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(recurso.totalizadores['arrecadada']),
            alignment: 'right', bold: true, fontSize: 7
          }
        ]);
        for (const item of recurso.registros) {
          if (+item.previsao_inicial === 0 && +item.previsao_atualizada === 0 && +item.arrecadada === 0) continue;
          registros.push([
            {
              text: ''
            },
            {
              text: item.aplicacao, fontSize: 7
            },
            {
              text: item.receita, fontSize: 7
            },
            { text: this.funcaoService.convertToBrNumber(item.previsao_inicial), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(item.previsao_atualizada), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(item.arrecadada), alignment: 'right', fontSize: 7 }
          ]);
        }
      }
    }

    registros.push([
      {
        text: 'TOTAL', fontSize: 7, bold: true
      },
      {
        text: ''
      },
      {
        text: ''
      },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 7, bold: true }
    ]);

    if (this.login.parametro?.contabil?.assinatura_por_funcao) {
      this.assinatura = await this.assinaturaService.obter({ orgao_id: this.login.orgao.id }).toPromise();

      const assinatura = [
        [
          {
            text: '', alignment: 'center',
            border: [false, false, false, false], margin: [0, 30, 0, 0]
          },
          {
            text: this.assinatura.ordenador_despesa_funcao_10 ? '__________________________________________' : '', alignment: 'center',
            border: [false, false, false, false], margin: [0, 30, 0, 0]
          },
          {
            text: '', alignment: 'center',
            border: [false, false, false, false], margin: [0, 30, 0, 0]
          }
        ],
        [
          { text: '', alignment: 'center', border: [false, false, false, false] },
          { text: this.assinatura.ordenador_despesa_funcao_10 ? this.assinatura.ordenador_despesa_funcao_10 : '', alignment: 'center', border: [false, false, false, false] },
          { text: '', alignment: 'center', border: [false, false, false, false] }
        ],
        [
          { text: '', alignment: 'center', border: [false, false, false, false] },
          { text: this.assinatura.cargo_ordenador_despesa_funcao_10 ? this.assinatura.cargo_ordenador_despesa_funcao_10 : '', alignment: 'center', border: [false, false, false, false] },
          { text: '', alignment: 'center', border: [false, false, false, false] }
        ],
      ];

      return [{
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*', 55, 55, 55],
          body: registros
        }
      }, {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          heights: [10, 0],
          widths: ['*', '*', '*'],
          body: assinatura
        }
      }];
    } else {
      return [{
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*', 55, 55, 55],
          body: registros
        }
      }];
    }
  }

  private colunasRelatorio(): Coluna[] {
    return [
      { titulo: 'FONTE', coluna: 'fonte' },
      { titulo: 'CÓDIGO APLICAÇÃO', coluna: 'aplicacao' },
      { titulo: 'CLASSIFICAÇÃO ECONÔMICA', coluna: 'classificacao' },
      { titulo: 'PREVISÃO INICIAL', coluna: 'previsao_inicial' },
      { titulo: 'PREVISÃO ATUALIZADA', coluna: 'previsao_atualizada' },
      { titulo: 'ARRECADA ATÉ O PERÍODO', coluna: 'arrecadado' },
    ];
  }

  private conteudoExportacao(dados: any[]) {
    const titulos = this.funcaoService.agrupar(dados[0], 'grupo', ['previsao_inicial', 'previsao_atualizada', 'arrecadada']);
    const listaItens = new Array();
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    for (const grupo of titulos) {
      listaItens.push({
        'fonte': grupo.grupo,
        'aplicacao': '',
        'classificacao': '',
        'previsao_inicial': '',
        'previsao_atualizada': '',
        'arrecadado': '',
      });

      total1 += +grupo.totalizadores['previsao_inicial'];
      total2 += +grupo.totalizadores['previsao_atualizada'];
      total3 += +grupo.totalizadores['arrecadada'];
      // RECURSOS --------------------------------------------------------------------------------
      const recursos = this.funcaoService.agrupar(grupo.registros, 'recurso', ['previsao_inicial', 'previsao_atualizada', 'arrecadada']);
      for (const recurso of recursos) {
        listaItens.push({
          'fonte': recurso.grupo,
          'aplicacao': '',
          'classificacao': '',
          'previsao_inicial': this.funcaoService.convertToBrNumber(recurso.totalizadores['previsao_inicial'], 2),
          'previsao_atualizada': this.funcaoService.convertToBrNumber(recurso.totalizadores['previsao_atualizada'], 2),
          'arrecadado': this.funcaoService.convertToBrNumber(recurso.totalizadores['arrecadada'], 2),
        });
        for (const item of recurso.registros) {
          listaItens.push({
            'fonte': '',
            'aplicacao': item.aplicacao,
            'classificacao': item.receita,
            'previsao_inicial': this.funcaoService.convertToBrNumber(item.previsao_inicial, 2),
            'previsao_atualizada': this.funcaoService.convertToBrNumber(item.previsao_atualizada, 2),
            'arrecadado': this.funcaoService.convertToBrNumber(item.arrecadada, 2),
          });
        }
      }
    }
    listaItens.push({
      'fonte': 'TOTAL',
      'aplicacao': '',
      'classificacao': '',
      'previsao_inicial': this.funcaoService.convertToBrNumber(total1, 2),
      'previsao_atualizada': this.funcaoService.convertToBrNumber(total2, 2),
      'arrecadado': this.funcaoService.convertToBrNumber(total3, 2),
    });

    return listaItens;
  }

}
