import { BaseResourceStorage } from '../../models/base-resource.storage';
import { Convenio } from './convenio.model';

export class ConvenioStorage extends BaseResourceStorage {
    constructor(
        public visivel_transparencia?: boolean,
        public convenio?: Convenio,
    ) {
        super();
    }

    static converteJson(json: any): ConvenioStorage {
        return Object.assign(new ConvenioStorage(), json);
    }
}
