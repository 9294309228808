import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BaseResourceListComponent, Coluna, DateFormatPipe, Despesa, DespesaPrevista, Exercicio, ExercicioService, FichaDespesa, Filtro, FormatoExportacao, FuncaoService, LoginPublico, Sistemas
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmpenhoService } from '../empenho/service/empenho.service';

@Component({
  selector: 'app-despesa-nivel',
  templateUrl: './despesa-nivel.component.html'
})
export class DespesaNivelComponent extends BaseResourceListComponent<Despesa, LoginPublico> {

  /**
   * Declaração de variáveis
   */
  public tipo: string;
  public programacaoId: number;
  public listaPlanilha: Array<any>;

  public listaEmpenhado: Array<Despesa>;
  public ficha: FichaDespesa;
  public exercicio: Exercicio;
  public sistema: Sistemas;
  public datepipe: DatePipe;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private route: ActivatedRoute,
    protected injector: Injector,
    private exercicioService: ExercicioService,
    protected funcaoService: FuncaoService,
    private empenhoService: EmpenhoService) {
    super(empenhoService, injector);
    this.datepipe = new DatePipe('pt');
  }

  public preencherGrid() {
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.sistema = params['sistema'];
        this.exercicioService.obterPorAno(+params['ano'], this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (res) => {
              this.exercicio = res.content[0];
              this.empenhoService.empenhadoPorNivel(this.login.orgao.id, this.exercicio.id, 12)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((lista) => {
                  this.listaPlanilha = lista; 
                });
            }
          );
      });
  }


  protected relations(): string {
    return '';
  }

  protected condicoesGrid(): {} {
    return { ['exercicio.id']: this.login.exercicio.id };
  }

  protected ordenacaoGrid(): string[] {
    return ['codigo$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return;
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: DespesaPrevista): Observable<DespesaPrevista> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'codigo' },
      { titulo: 'Descrição', coluna: 'nome' },
      { titulo: 'Janeiro', coluna: 'empenhado_jan', decimais: 2, alignment: 'right' },
      { titulo: 'Fevereiro', coluna: 'empenhado_fev', decimais: 2, alignment: 'right' },
      { titulo: 'Março', coluna: 'empenhado_mar', decimais: 2, alignment: 'right' },
      { titulo: 'Abril', coluna: 'empenhado_abr', decimais: 2, alignment: 'right' },
      { titulo: 'Maio', coluna: 'empenhado_mai', decimais: 2, alignment: 'right' },
      { titulo: 'Junho', coluna: 'empenhado_jun', decimais: 2, alignment: 'right' },
      { titulo: 'Julho', coluna: 'empenhado_jul', decimais: 2, alignment: 'right' },
      { titulo: 'Agosto', coluna: 'empenhado_ago', decimais: 2, alignment: 'right' },
      { titulo: 'Setembro', coluna: 'empenhado_set', decimais: 2, alignment: 'right' },
      { titulo: 'Outubro', coluna: 'empenhado_out', decimais: 2, alignment: 'right' },
      { titulo: 'Novembro', coluna: 'empenhado_nov', decimais: 2, alignment: 'right' },
      { titulo: 'Dezembro', coluna: 'empenhado_dez', decimais: 2, alignment: 'right' },
      {
        titulo: 'Total', coluna: 'total', decimais: 2, alignment: 'right',
        funcao: [{
          valor: ['empenhado_jan', '+', 'empenhado_fev', '+', 'empenhado_mar', '+',
            'empenhado_abr', '+', 'empenhado_mai', '+', 'empenhado_jun', '+',
            'empenhado_jul', '+', 'empenhado_ago', '+', 'empenhado_set', '+',
            'empenhado_out', '+', 'empenhado_nov', '+', 'empenhado_dez'
          ]
        }]
      },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    this.empenhoService.empenhadoPorNivel(this.login.orgao.id, this.exercicio.id, 12)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('DESPESA EMPENHADA MENSAL',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem previsao despesa', ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto',
              'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], lista);
          } else {
            this.exportar(formato, lista);
          }
        });
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public redirecionarEmpenho(mes: number, codigo: string) {
    const data1 = new DateFormatPipe().transform(this.datepipe.transform(new Date(`${mes}/01/${this.exercicio.ano}`), 'yyyy-MM-dd'), []);
    const data2 = new DateFormatPipe().transform(this.datepipe.transform(new Date(`${mes}/${this.funcaoService.ultimoDiaMes(mes)}/${this.exercicio.ano}`), 'yyyy-MM-dd'), []);
    const dt1 = this.datepipe.transform(data1, 'yyyy-MM-dd', 'GMT');
    const dt2 = this.datepipe.transform(data2, 'yyyy-MM-dd', 'GMT');

    switch (this.sistema) {
      case 'transparencia':
        this.router.navigate(['/transparencia/empenhos-orcamentarios/pesquisa/todos/1', dt1, dt2, codigo.substr(0, codigo.length - 2)]);
        break;
      case 'indicador-gestao':
      case 'controle-interno':
      case 'contabil':
        sessionStorage.setItem('/empenhos_data1', dt1);
        sessionStorage.setItem('/empenhos_data2', dt2);
        sessionStorage.setItem('/empenhos_filtro', codigo.substr(0, codigo.length - 2));
        this.router.navigate(['/empenhos-orcamentario']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public voltar() {
    switch (this.sistema) {
      case 'transparencia':
        this.router.navigate(['/transparencia/portal']);
        break;
      case 'controle-interno':
      case 'contabil':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }
}
