import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transparencia-login-recuperar-senha',
  templateUrl: './transparencia-login-recuperar-senha.component.html'
})
export class TransparenciaLoginRecuperarSenhaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
