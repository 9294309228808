import { Injectable, Injector } from '@angular/core';
import { BaseResourceService } from '../../models/services/base-resource.service';
import { Agenda } from '../../entidade/comum/agenda.model';

@Injectable({
  providedIn: 'root'
})
export class AgendaService extends BaseResourceService<Agenda> {

  constructor(
    protected injector: Injector
  ) {
    super(`agendas`, injector);
  }

}
