import { Directive, Injector, OnDestroy } from '@angular/core';
import {
  Coluna,
  Exercicio, FormatoExportacao, FuncaoService, GlobalService, Liquidacao, Login,
  ProgressoService,
  Relatorio
} from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../assinaturas/assinaturas';
import { AnexoLRFService } from '../../service/anexo-lrf.service';

@Directive()
export class FuncaoSubfuncaoLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLRFService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected progressoService: ProgressoService,
    protected assinaturaControleInterno: boolean) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async listar(orgaos: number[], funcao: number, recurso?: number,) {
    return await this.anexoServico
      .obterFuncaoSubfuncao(this.mes, this.exercicio.id, orgaos, funcao, recurso).toPromise();
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao = 'pdf', funcao?: number, recurso?: number) {
    this.anexoServico.obterFuncaoSubfuncao(this.mes, this.exercicio.id, orgaos, funcao, recurso)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async idTransacao => {
        let dados
        this.progressoService.show(idTransacao, async (retorno) => {
          dados = retorno
          if (formato === 'pdf') {
            Relatorio.imprimirPersonalizado(
              'RREO - DEMONSTRATIVO DAS DESPESAS POR FUNÇÃO E SUBFUNÇÃO'
              , this.login.usuario.nome, this.login.usuario.sobrenome,
              this.login.orgao.nome, this.login.brasao,
              await this.conteudo(dados),
              'landscape', 'RREO - DEMONSTRATIVO DAS DESPESAS POR FUNÇÃO E SUBFUNÇÃO',
              {
                linhas: {
                  hLineWidth() {
                    return 1;
                  },
                  vLineWidth() {
                    return 1;
                  },
                  hLineColor() {
                    return 'black';
                  },
                  paddingLeft() {
                    return 3;
                  },
                  paddingRight() {
                    return 3;
                  }
                }
              }, true, false);
          } else {
            this.funcaoService.exportar(formato, this.normalizar(dados), 'RREO - DEMONSTRATIVO DAS DESPESAS POR FUNÇÃO E SUBFUNÇÃO', this.colunas());
          }
        })
      });
  }

  private async conteudo(dados: any[]): Promise<{}[]> {

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: this.login.orgao.nome,
        alignment: 'center',
        bold: true,
        colSpan: 8,
        fontSize: 12, border: [false, false, false, false,], margin: [0, 0, 0, -3]
      }, '', '', '', '', '', '', ''],
      [{
        text: `RREO - DEMONSTRATIVO DAS DESPESAS POR FUNÇÃO E SUBFUNÇÃO` +
          this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano,
        alignment: 'center',
        bold: true,
        colSpan: 8,
        fontSize: 12, border: [false, false, false, false,], margin: [0, 0, 0, -3]
      }, '', '', '', '', '', '', ''],
      [{
        text: `REFERÊNCIA: ` +
          this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano,
        alignment: 'center',
        bold: true,
        colSpan: 8, border: [false, false, false, false,], margin: [0, 0, 0, -3]
      }, '', '', '', '', '', '', ''],
      [{
        text: 'LRF, ART. 52, INCISO II, ALÍNEA "C"',
        alignment: 'left',
        bold: true,
        colSpan: 8, border: [false, false, false, false,], margin: [0, 0, 0, -3]
      }, '', '', '', '', '', '', ''],
      [{
        text: 'FUNÇÃO / SUBFUNÇÃO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'DOTAÇÃO INICIAL',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'CRÉDITOS ADICIONAIS / ANULAÇÕES',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'DOTAÇÃO ATUALIZADA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'DESPESAS EMPENHADAS ATÉ O BIMESTRE',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'SALDO A EMPENHAR',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'DESPESAS LIQUIDADAS ATÉ O BIMESTRE',
        alignment: 'center',
        bold: true, fontSize: 6
      },
      {
        text: 'SALDO A LIQUIDAR',
        alignment: 'center',
        bold: true, fontSize: 6
      }]
    ];

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);

    // monta o agrupamento do relatório
    const grupos = this.funcaoService.agrupar(dados, 'funcao',
      ['dotacao_inicial', 'credito', 'dotacao_atual', 'empenhado', 'liquidado', 'pago']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    let total8 = 0;
    for (const grupo of grupos) {
      registros.push([
        {
          text: grupo.grupo.toString().startsWith('99') ? grupo.grupo.toString().replace('99 ', '') : grupo.grupo, bold: true, fontSize: 8, border: [false, false, false, false,], margin: [0, 0, 0, -3]
        },
        {
          text: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_inicial']),
          alignment: 'right', bold: true, fontSize: 8, border: [false, false, false, false,], margin: [0, 0, 0, -3]
        },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['credito']), alignment: 'right', bold: true, fontSize: 8, border: [false, false, false, false,], margin: [0, 0, 0, -3] },
        {
          text: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_atual']),
          alignment: 'right', bold: true, fontSize: 8, border: [false, false, false, false,], margin: [0, 0, 0, -3]
        },
        {
          text: grupo.grupo.toString().startsWith('99') ? '0,00' : this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']),
          alignment: 'right', bold: true, fontSize: 8, border: [false, false, false, false,], margin: [0, 0, 0, -3]
        },
        {
          text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['dotacao_atual'] - +grupo.totalizadores['empenhado']),
          alignment: 'right', bold: true, fontSize: 8, border: [false, false, false, false,], margin: [0, 0, 0, -3]
        },
        {
          text: grupo.grupo.toString().startsWith('99') ? '0,00' : this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']),
          alignment: 'right', bold: true, fontSize: 8, border: [false, false, false, false,], margin: [0, 0, 0, -3]
        },
        {
          text: grupo.grupo.toString().startsWith('99') ? '0,00' : this.funcaoService.convertToBrNumber(+grupo.totalizadores['empenhado'] - +grupo.totalizadores['liquidado']),
          alignment: 'right', bold: true, fontSize: 8, border: [false, false, false, false,], margin: [0, 0, 0, -3]
        },
      ]);
      total1 += +grupo.totalizadores['dotacao_inicial'];
      total2 += +grupo.totalizadores['credito'];
      total3 += +grupo.totalizadores['dotacao_atual'];
      total4 += grupo.grupo.toString().startsWith('99') ? 0 : +grupo.totalizadores['empenhado'];
      total5 += +grupo.totalizadores['dotacao_atual'] - +grupo.totalizadores['empenhado'];
      total6 += grupo.grupo.toString().startsWith('99') ? 0 : +grupo.totalizadores['liquidado'];
      // total7 += +grupo.totalizadores['pago'];
      total8 += grupo.grupo.toString().startsWith('99') ? 0 : +grupo.totalizadores['empenhado'] - +grupo.totalizadores['liquidado'];
      if (!grupo.grupo.toString().startsWith('99')) {
        for (const item of grupo.registros) {
          registros.push([
            {
              text: item.subfuncao, fontSize: 7, border: [false, false, false, false,], margin: [15, 0, 0, -3]
            },
            { text: this.funcaoService.convertToBrNumber(item.dotacao_inicial), alignment: 'right', fontSize: 7, border: [false, false, false, false,], margin: [0, 0, 0, -3] },
            { text: this.funcaoService.convertToBrNumber(item.credito), alignment: 'right', fontSize: 7, border: [false, false, false, false,], margin: [0, 0, 0, -3] },
            { text: this.funcaoService.convertToBrNumber(item.dotacao_atual), alignment: 'right', fontSize: 7, border: [false, false, false, false,], margin: [10, 0, 0, -3] },
            { text: grupo.grupo.toString().startsWith('99') ? '0,00' : this.funcaoService.convertToBrNumber(item.empenhado), alignment: 'right', fontSize: 7, border: [false, false, false, false,], margin: [0, 0, 0, -3] },
            { text: grupo.grupo.toString().startsWith('99') ? '0,00' : this.funcaoService.convertToBrNumber(+item.dotacao_atual - +item.empenhado), alignment: 'right', fontSize: 7, border: [false, false, false, false,], margin: [0, 0, 0, -3] },
            { text: grupo.grupo.toString().startsWith('99') ? '0,00' : this.funcaoService.convertToBrNumber(item.liquidado), alignment: 'right', fontSize: 7, border: [false, false, false, false,], margin: [0, 0, 0, -3] },
            { text: grupo.grupo.toString().startsWith('99') ? '0,00' : this.funcaoService.convertToBrNumber(+item.empenhado - +item.liquidado), alignment: 'right', fontSize: 7, border: [false, false, false, false,], margin: [0, 0, 0, -3] },
          ]);
        }
      }
    }

    registros.push([
      {
        text: 'TOTAL', fontSize: 8, bold: true, border: [false, true, false, false,], margin: [0, 0, 0, -3]
      },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 8, bold: true, border: [false, true, false, false,], margin: [0, 0, 0, -3] },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 8, bold: true, border: [false, true, false, false,], margin: [0, 0, 0, -3] },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 8, bold: true, border: [false, true, false, false,], margin: [0, 0, 0, -3] },
      { text: this.funcaoService.convertToBrNumber(total4), alignment: 'right', fontSize: 8, bold: true, border: [false, true, false, false,], margin: [0, 0, 0, -3] },
      { text: this.funcaoService.convertToBrNumber(total5), alignment: 'right', fontSize: 8, bold: true, border: [false, true, false, false,], margin: [0, 0, 0, -3] },
      { text: this.funcaoService.convertToBrNumber(total6), alignment: 'right', fontSize: 8, bold: true, border: [false, true, false, false,], margin: [0, 0, 0, -3] },
      { text: this.funcaoService.convertToBrNumber(total8), alignment: 'right', fontSize: 8, bold: true, border: [false, true, false, false,], margin: [0, 0, 0, -3] },
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 4,
        widths: ['*', 70, 70, 70, 70, 70, 70, 70],
        body: registros
      }
    },
    {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*'],
        body: assinaturas
      }
    }
    ];
  }

  public normalizar(lista: any[]) {
    const listaExportar = []
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    let total7 = 0;

    let dotacao_inicial = 0;
    let credito = 0;
    let dotacao_atual = 0;
    let empenhado = 0;
    let saldo_a_empenhar = 0;
    let despesa_liquidada = 0;
    let saldo_a_liquidar = 0;

    let item_saldo_a_empenhar = 0;
    let item_saldo_a_liquidar = 0;


    const grupos = this.funcaoService.agrupar(lista, 'funcao',
      ['dotacao_inicial', 'credito', 'dotacao_atual', 'empenhado', 'liquidado', 'pago']);
    for (const grupo of grupos) {
      dotacao_inicial = grupo.totalizadores['dotacao_inicial'];
      credito = grupo.totalizadores['credito'];
      dotacao_atual = grupo.totalizadores['dotacao_atual'];
      empenhado = grupo.totalizadores['empenhado'];
      saldo_a_empenhar = dotacao_atual - empenhado;
      despesa_liquidada = grupo.totalizadores['liquidado'];
      saldo_a_liquidar = empenhado - despesa_liquidada;

      const primeira_linha = {
        funcao_subfuncao: grupo.grupo,
        dotacao_inicial: this.funcaoService.convertToBrNumber(dotacao_inicial),
        credito_adicional: this.funcaoService.convertToBrNumber(credito),
        dotacao_atualizada: this.funcaoService.convertToBrNumber(dotacao_atual),
        despesas_empenhadas: this.funcaoService.convertToBrNumber(empenhado),
        saldo_empenhar: this.funcaoService.convertToBrNumber(saldo_a_empenhar),
        despesas_liquidadas: this.funcaoService.convertToBrNumber(despesa_liquidada),
        saldo_liquidar: this.funcaoService.convertToBrNumber(saldo_a_liquidar)
      }
      listaExportar.push(primeira_linha)

      for (const item of grupo.registros) {
        item_saldo_a_empenhar = item.dotacao_atual - item.empenhado;
        item_saldo_a_liquidar = item.empenhado - item.liquidado;

        if (!grupo.grupo.toString().startsWith('99')) {
          const items = {
            funcao_subfuncao: item.subfuncao,
            dotacao_inicial: this.funcaoService.convertToBrNumber(item.dotacao_inicial),
            credito_adicional: this.funcaoService.convertToBrNumber(item.credito),
            dotacao_atualizada: this.funcaoService.convertToBrNumber(item.dotacao_atual),
            despesas_empenhadas: this.funcaoService.convertToBrNumber(item.empenhado),
            saldo_empenhar: this.funcaoService.convertToBrNumber(item_saldo_a_empenhar),
            despesas_liquidadas: this.funcaoService.convertToBrNumber(item.liquidado),
            saldo_liquidar: this.funcaoService.convertToBrNumber(item_saldo_a_liquidar)
          }
          listaExportar.push(items)
        }
      }

      total1 += dotacao_inicial;
      total2 += credito;
      total3 += dotacao_atual;
      total4 += empenhado;
      total5 += saldo_a_empenhar;
      total6 += despesa_liquidada;
      total7 += saldo_a_liquidar;
    }

    const total = {
      funcao_subfuncao: 'TOTAL',
      dotacao_inicial: this.funcaoService.convertToBrNumber(total1),
      credito_adicional: this.funcaoService.convertToBrNumber(total2),
      dotacao_atualizada: this.funcaoService.convertToBrNumber(total3),
      despesas_empenhadas: this.funcaoService.convertToBrNumber(total4),
      saldo_empenhar: this.funcaoService.convertToBrNumber(total5),
      despesas_liquidadas: this.funcaoService.convertToBrNumber(total6),
      saldo_liquidar: this.funcaoService.convertToBrNumber(total7)
    }
    listaExportar.push(total)

    return listaExportar
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'FUNÇÃO / SUBFUNÇÃO', coluna: 'funcao_subfuncao', bold: true },
      { titulo: 'DOTAÇÃO INICIAL', coluna: 'dotacao_inicial' },
      { titulo: 'CRÉDITOS ADICIONAIS / ANULAÇÕES', coluna: 'credito_adicional' },
      { titulo: 'DOTAÇÃO ATUALIZADA', coluna: 'dotacao_atualizada' },
      { titulo: 'DESPESAS EMPENHADAS ATÉ O BIMESTRE', coluna: 'despesas_empenhadas' },
      { titulo: 'SALDO A EMPENHAR', coluna: 'saldo_empenhar' },
      { titulo: 'DESPESAS LIQUIDADAS ATÉ O BIMESTRE', coluna: 'despesas_liquidadas' },
      { titulo: 'SALDO A LIQUIDAR', coluna: 'saldo_liquidar' },
    ];
    return colunasDefault;
  }

}
