// tslint:disable: variable-name
import { TipoOcorrenciaVeiculo } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { Veiculo } from './veiculo.model';

export class VeiculoOcorrencia extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_ocorrencia?: Date,
    public ocorrencia?: string,
    public tipo?: TipoOcorrenciaVeiculo,
    public veiculo?: Veiculo,
    public usuario?: Usuario,
  ) {
    super();
  }

  static converteJson(json: any): VeiculoOcorrencia {
    return Object.assign(new VeiculoOcorrencia(), json);
  }
}
