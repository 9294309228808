// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { PatrimonioChecklist } from './patrimonio-checklist.model';

export class AuditoriaPatrimonio extends BaseResourceModel {
  constructor(
    public id?: number,
    public placa?: string,
    public material?: string,
    public data_auditoria?: Date,
    public data_aquisicao?: Date,
    public data_baixa?: Date,
    public data_exclusao?: Date,
    public documento?: string,
    public observacao?: string,
    public conclusao?: string,
    public recomendacao?: string,
    public situacao?: string,
    public marca?: string,
    public localizacao?: string,
    public cor?: string,
    public valor_patrimonio?: number,
    public cancelado?: boolean,
    public orgao?: Orgao,
    public checklist?: PatrimonioChecklist[]
    ) {
      super();
    }
    static converteJson(json: any): AuditoriaPatrimonio {
      return Object.assign(new AuditoriaPatrimonio(), json);
    }
}
