import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { RecebimentoExtraListComponent } from './recebimento-extra-list/recebimento-extra-list.component';
import { RecebimentoExtraViewComponent } from './recebimento-extra-view/recebimento-extra-view.component';
import { RecebimentoExtraFormComponent } from './recebimento-extra-form/recebimento-extra-form.component';
import { RecebimentoExtraRptComponent } from './recebimento-extra-rpt/recebimento-extra-rpt.component';

const routes: Routes = [
  { path: '', component: RecebimentoExtraListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: RecebimentoExtraFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: RecebimentoExtraFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: RecebimentoExtraViewComponent, canActivate: [AuthGuard] },
  { path: 'relatorios', component: RecebimentoExtraRptComponent, canActivate: [AuthGuard] }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecebimentoExtraRoutingModule { }
