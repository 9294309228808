// tslint:disable: forin
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import * as toastr from 'toastr';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BaseResourceModel } from '../base-resource.model';
import { Login } from '../../entidade/login/login';
import { Page } from '../../util/page';
import jwt_decode from 'jwt-decode';
import * as bcrypt from 'bcryptjs';
import { GlobalService } from '../../util/global.service';
import { EstruturaEntidade } from '../../util/estrutura-entidade';

export abstract class BaseResourceService<T extends BaseResourceModel> {

  public login: Login = new Login();
  protected http: HttpClient;

  constructor(
    protected api: string,
    protected injector: Injector
  ) {
    this.http = injector.get(HttpClient);
    this.login = GlobalService.obterSessaoLogin();
  }

  retornarRota(): string {
    return this.injector.get(Router).url;
  }

  public converterParametrosStr(param: {}): string {
    let retorno = '';
    if (param) {
      for (const key in param) {
        if (retorno === '') {
          retorno += '?';
        } else {
          retorno += '&';
        }
        retorno += key + '=' + encodeURIComponent(param[key]);
      }
    }
    return retorno;
  }

  protected httpOptions(notProgress?: boolean) {
    this.login = GlobalService.obterSessaoLogin();
    this.atualizaLogoutAutomatico()
    const header = {
      'Content-Type': 'application/json',
    };
    if (this.login)
      header['Authorization'] = this.login.token;
    if (notProgress)
      header['not-progress'] = 'true';
    const teste = {
      headers: new HttpHeaders(this.adicionarCriptografia(header)),
      reportProgress: true
    };
    return teste;
  }

  protected atualizaLogoutAutomatico() {
    this.login.logoutTime = new Date().getTime()
    GlobalService.salvarSessaoLogin(this.login)
  }

  protected adicionarCriptografia(header: {}) {
    this.login = GlobalService.obterSessaoLogin();
    if (this.login && this.login.token) {
      const tokenJson = jwt_decode(this.login.token) as any;
      const agora = new Date().getTime();
      const comparar = ((agora - +tokenJson.iat) / +tokenJson.id).toFixed(0);
      const criptografado = bcrypt.hashSync(comparar, tokenJson.chave);

      header['agora'] = agora + '';
      header['codigo'] = criptografado;

    }
    return header;
  }

  todos(): Observable<Page>;
  todos(pagina: number, limite: number): Observable<Page>;
  todos(pagina?: number, limite?: number): Observable<Page> {
    if (!Number.isInteger(limite) || !Number.isInteger(pagina)) {
      pagina = 1;
      limite = 1000;
    }
    this.login = GlobalService.obterSessaoLogin();
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/${pagina}/${limite}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  obterId(id: number, httpOptions?: {}): Observable<T> {
    return this.http.get<T>(this.obterUrl(id), (httpOptions ? httpOptions : this.httpOptions())).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public obter(filtros?: {}): Observable<T> {
    this.login = GlobalService.obterSessaoLogin();
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/obter${this.converterParametrosStr(filtros)}`,
      this.httpOptions()).pipe(
        take(1),
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  inserir(resource: T): Observable<T> {
    this.login = GlobalService.obterSessaoLogin();
    return this.http.post(`${this.login.cidade.id}/${this.api}`, resource, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  atualizar(resource: T): Observable<T> {
    return this.http.put(this.obterUrl(resource.id), resource, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  atualizarSiconfi(resource: T): Observable<T> {
    return this.http.put(`${this.login.cidade.id}/${this.api}/atualiza-siconfi`, resource, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  inserirLista(resource: T[]): Observable<T> {
    this.login = GlobalService.obterSessaoLogin();
    return this.http.post(`${this.login.cidade.id}/${this.api}/lista`, resource, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  remover(id: number): Observable<T> {
    return this.http.delete(this.obterUrl(id), this.httpOptions()).pipe(
      map(() => null),
      catchError(err => this.handleError(err))
    );
  }

  private obterUrl(id: number) {
    this.login = GlobalService.obterSessaoLogin();
    return `${this.login.cidade.id}/${this.api}/${id}`;
  }

  protected handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      this.login = GlobalService.obterSessaoLogin();

      if (this.login && this.login.usuario.sistema === 'transparencia') {
        this.login = null;
        GlobalService.limparSessaoLogin();
        location.reload();
      } else {
        toastr.warning('Sua sessão expirou, por favor faça o login novamente!', 'Sessão expirada');
        sessionStorage.clear();
        this.injector.get(Router).navigate(['/login']);
      }
    } else {
      return throwError(error);
    }
  }

  filtrar(pagina: number, limite: number, filtros: {}, retirarCarregamento?: boolean): Observable<Page> {
    this.login = GlobalService.obterSessaoLogin();
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/${pagina}/${limite}${this.converterParametrosStr(filtros)}`, this.httpOptions(retirarCarregamento)).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public extendido(pagina: number, limite: number, filtros?: {}): Observable<Page> {
    this.login = GlobalService.obterSessaoLogin();
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/extendido/${pagina}/${limite}${this.converterParametrosStr(filtros)}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public download(nome: string): Observable<any> {
    this.login = GlobalService.obterSessaoLogin();
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/download/${nome}`, {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders(this.adicionarCriptografia({
        Authorization: this.login.token,
      }))
    }).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public assinar(arquivo: any): Observable<any> {
    return this.http.post<any>(`http://localhost:5678/`, arquivo, { responseType: 'blob' as 'json' }).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  getParametros(obj: object) {
    if (!obj)
      return '';
    return Object.entries(obj)
      .filter(([key, value]) => !(!value)).map(([key, value]) => {
        return `${key}=${value}`;
      }).join('&');
  }

  getEstrutura(): Observable<EstruturaEntidade> {
    this.login = GlobalService.obterSessaoLogin();
    return this.http.get<EstruturaEntidade>(`${this.login.cidade.id}/${this.api}/estrutura-entidade`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }
}
