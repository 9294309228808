// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido } from '../compra/favorecido.model';
import { Orgao } from '../comum/orgao.model';
import { Empenho } from './empenho.model';
import { PrecatorioAdvogados } from './precatorio-advogados.model';
import { PrecatorioIndicativo } from './precatorio-indicativo.model';

export class Precatorio extends BaseResourceModel {
  constructor(
    public id?: number,
    public aux?: number,
    public ano?: number,
    public data_ajuizamento?: Date,
    public data_apresentacao?: Date,
    public tipo?: string,
    public numero_acao?: string,
    public numero_precatorio?: string,
    public valor_original?: number,
    public valor_vencido?: number,
    public valor_exercicio?: number,
    public valor_exercicio_anterior?: number,
    public valor_atualizacao?: number,
    public valor_cancelado?: number,
    public valor_pago?: number,
    public historico?: string,
    public orgao?: Orgao,
    public favorecido?: Favorecido,
    public tipo_processo?: number,
    public indicativo_autoria?: number,
    public uf_vara?: string,
    public codigo_municipio?: number,
    public id_vara?: string,
    public enviar_reinf?: boolean,
    public valor_custas?: number,
    public origem_recurso?: number,
    public origem_recurso_cnpj?: string,
    public origem_recurso_descricao?: string,
    public possui_rra?: number,
    public indicativo_origem_rra?: number,
    public descricao_rra?: string,
    public qtd_meses_rra?: number,
    public cnpj_origem_rra?: string,
    public valor_custas_rra?: number,
    public indicativos?: PrecatorioIndicativo[],
    public advogados?: PrecatorioAdvogados[],
    public total_pago?: number,
    public empenhos?: Empenho[]

  ) {
    super();
  }
  static converteJson(json: any): Precatorio {
    return Object.assign(new Precatorio(), json);
  }
}
