// tslint:disable: variable-name
import { Ppa } from './ppa.model';
import { BaseResourceModel } from '../../models/base-resource.model';
import { FuncaoGoverno } from './funcao-governo.model';
import { Unidade } from './unidade.model';
import { Programa } from './programa.model';
import { MetaGovernoOds } from './meta-governo-ods.model';

export class MetaGoverno extends BaseResourceModel {
  constructor(
    public id?: number,
    public especie?: string,
    public gerente?: string,
    public tipo?: string,
    public alvo?: string,
    public aprovado?: boolean,
    public continuado?: boolean,
    public priorizado1?: boolean,
    public priorizado2?: boolean,
    public priorizado3?: boolean,
    public priorizado4?: boolean,
    public enviado_tce?: boolean,
    public objetivo?: string,
    public justificativa?: string,
    public programa?: Programa,
    public funcao?: FuncaoGoverno,
    public subfuncao?: FuncaoGoverno,
    public unidade?: Unidade,
    public ppa?: Ppa,
    public odss?: MetaGovernoOds[],
  ) {
    super();
  }

  static converteJson(json: any): MetaGoverno {
    return Object.assign(new MetaGoverno(), json);
  }
}
