import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContaBancariaService, ContratoService, ConvenioService, PagamentoExtraService, PagamentoRestoService, PagamentoService, RecursoService } from 'administrativo-lib';
import { Coluna, ContaBancaria, Contrato, Convenio, EddyAutoComplete, Exercicio, ExercicioService, FichaDespesa, FichaExtra, FormatoExportacao, FuncaoService, GlobalService, LoginContabil, Pagamento, PagamentoExtra, Recurso, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FichaDespesaService } from '../../ficha-despesa/service/ficha-despesa.service';
import * as toastr from 'toastr';
import { FichaExtraService } from '../../ficha-extra/service/ficha-extra.service';

@Component({
  selector: 'app-relatorio-prestacao',
  templateUrl: './relatorio-prestacao.component.html'
})
export class RelatorioPrestacaoComponent implements OnInit, OnDestroy {

  public login: LoginContabil = new LoginContabil();
  public relatoriosSelect = 'EMPENHO_CONVENIO_ORCAMENTARIO';
  public listaRelatorios: Array<any>;
  public dataInicial: Date = new Date();
  public dataFinal: Date = new Date();
  public opcao: string;
  public filtrarConta: boolean = false
  public novaConta: ContaBancaria = new ContaBancaria()
  public listaContaBancaria: ContaBancaria[] = []
  public novoRecurso: Recurso = new Recurso()
  public listaRecurso: Recurso[] = []
  public novoContrato: Contrato = new Contrato()
  public novoConvenio: Convenio = new Convenio()
  public listaContrato: Contrato[] = []
  public listaConvenio: Convenio[] = []
  public filtrarRecurso: boolean = false
  public recurso: Recurso;
  public aplicacao: Recurso;
  public aplicacao_variavel: Recurso;
  public aplicacao_variavel_final: Recurso;
  public RecursoAplicacao: Recurso;
  public RecursoAplicacaoFinal: Recurso;
  public modeloBusca: boolean = false;
  public modeloBuscaText: string = 'Avançado';
  public filtragemAvancada: boolean = false;
  public filtroRecursoAplicacao: boolean = false;
  public filtroFicha: boolean = false;
  public ficha: FichaDespesa;
  public fichaExtra: FichaExtra;
  public filtroRecurso: boolean;
  public filtroAplicacao: boolean;
  public filtroAplicacao_variavel: boolean;

  protected unsubscribe: Subject<void> = new Subject();
  protected datepipe: DatePipe;
  public ptBR: any;

  public mes: number;
  public ano: number;
  public listaExercicios: Array<any>;
  public ativo: any;
  public resto_ficha: boolean = false;
  public filtroTipoCredor: boolean = false
  public filtroTipoFornecedor: boolean = false
  public filtroContrato: boolean = false
  public filtroConvenio: boolean = false

  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public contaBancariaAutoComplete: EddyAutoComplete<ContaBancaria>;
  public contratoAutoComplete: EddyAutoComplete<Contrato>
  public convenioAutoComplete: EddyAutoComplete<Convenio>
  public aplicacaoVariavelAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoVariavelFinalAutoComplete: EddyAutoComplete<Recurso>;
  public recursoAplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public fichaAutoComplete: EddyAutoComplete<FichaDespesa>;
  public fichaExtraAutoComplete: EddyAutoComplete<FichaExtra>;
  public recursoAplicacaoFinalAutoComplete: EddyAutoComplete<Recurso>;

  public contaSelect: ContaBancaria;
  extraGrupBy: number;

  constructor(
    private router: Router,
    protected pagamentoService_orcamentario: PagamentoService,
    protected pagamentoService_resto: PagamentoRestoService,
    protected pagamentoService_extra: PagamentoExtraService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected exercicioService: ExercicioService,
    protected fichaService: FichaDespesaService,
    protected contaBancariaService: ContaBancariaService,
    protected recursoService: RecursoService,
    protected fichaDespesaService: FichaDespesaService,
    protected fichaExtraService: FichaExtraService,
    protected contratoService: ContratoService,
    protected convenioService: ConvenioService
  ) {
    this.login = GlobalService.obterSessaoLogin();
    this.datepipe = new DatePipe('pt');
    this.mes = 1;
    this.ano = this.login.exercicio.id;
  }


  ngOnInit() {
    this.listaRelatorios = [
      { id: 'EMPENHO_CONVENIO_ORCAMENTARIO', nome: 'PAGAMENTOS ORÇAMENTÁRIOS VINCULADOS A CONVÊNIOS' },
      { id: 'EMPENHO_CONVENIO_RESTO', nome: 'PAGAMENTOS DE RESTO VINCULADOS A CONVÊNIOS' },
      { id: 'EMPENHO_CONVENIO_EXTRA', nome: 'PAGAMENTOS EXTRA VINCULADOS A CONVÊNIOS' }
    ];

    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaExercicios = new Array();
        const lista = dados.content;
        for (const exercicio of lista as Array<Exercicio>) {
          this.listaExercicios.push({ id: exercicio.id, ano: exercicio.ano });
        }
      });
    this.carregarAutocomplete()
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    new GlobalService().calendarMascara();
  }

  public statusModeloBusca(x: number) {
    // Explicacao -> x == 1 ? click no filtro de aplicacao : click no filtro avancado.

    if (x == 1 && !this.filtroAplicacao_variavel && this.filtragemAvancada && !this.modeloBusca)
      this.filtroAplicacao_variavel = true;

    if (x == 2 && !this.modeloBusca) {
      this.modeloBuscaText = 'Avançado';
      this.filtroRecursoAplicacao = false;
    } else if (x == 2 && this.modeloBusca) {
      this.modeloBuscaText = 'Simples';
      this.filtrarRecurso = false; this.filtroAplicacao = false; this.filtroAplicacao_variavel = false;
    } else if (x == 2 && this.modeloBusca && !this.filtroRecursoAplicacao && this.filtragemAvancada) {
      this.modeloBuscaText = 'Simples';
      this.filtroRecursoAplicacao = true;
      this.filtrarRecurso = false; this.filtroAplicacao = false; this.filtroAplicacao_variavel = false;
    }
  }

  public carregarAutocomplete() {
    //Filtro por conta
    this.contaBancariaAutoComplete = new EddyAutoComplete(null, this.contaBancariaService, 'id', ['codigo', 'nome', 'agencia', 'numero_conta'], { orgao_id: this.login.orgao.id, ativo: true, orderBy: 'nome' }, { number: ['id', 'codigo'], text: ['nome', 'agencia_nome', 'numero_conta', 'agencia'] })

    //Filtro por contrato
    this.contratoAutoComplete = new EddyAutoComplete(null, this.contratoService, 'id', ['numero', 'favorecido.nome'], { orgao_id: this.login.orgao.id, relations: 'favorecido', orderBy: 'favorecido.nome' }, { number: ['numero'], text: ['favorecido.nome'] }
    );

    // Filtrar por convenio
    this.convenioAutoComplete = new EddyAutoComplete(null, this.convenioService, 'id', ['numero', 'ano', 'favorecido.nome'], { relations: 'favorecido,orgao', 'orgao.id': this.login.orgao.id, orderBy: 'ano$DESC,numero' }, { number: ['ano'], text: ['numero', 'numero_convenio', 'favorecido.nome', 'favorecido.cpf_cnpj'] })

    // autocomplete para ficha
    this.fichaAutoComplete = new EddyAutoComplete(null, this.fichaDespesaService,
      'numero', ['ficha.numero', 'ficha.despesa.nome', 'ficha.acao.codigo', 'ficha.programa.codigo', 'ficha.executora.codigo', 'ficha.subfuncao.codigo', 'ficha.recurso.codigo'], { exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id, relations: 'despesa,acao,funcao,subfuncao,programa,executora,recurso,aplicacao,aplicacao_variavel', orderBy: 'despesa.nome' }, { number: ['numero'], text: ['despesa.codigo', 'despesa.nome'] }
    );


    // autocomplete para ficha Extra
    this.fichaExtraAutoComplete = new EddyAutoComplete(null, this.fichaExtraService,
      'id', ['numero', 'nome'], { exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id, orderBy: 'nome' }, { number: ['numero'], text: ['nome'] }
    );

    //Filtro por recurso
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService, 'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['codigo', 'nome'] })

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para aplicacao Variavel
    this.aplicacaoVariavelAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['variavel', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['variavel', 'nome'] }
    );

    // autocomplete para aplicacao Variavel numero final (busca avancada)
    this.aplicacaoVariavelFinalAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['variavel', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['variavel', 'nome'] }
    );

    // autocomplete para recursoAplicacao e Variavel (busca simples)
    this.recursoAplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para recursoAplicacao e Variavel numero final (busca simples)
    this.recursoAplicacaoFinalAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['codigo', 'nome'] }
    );
  }

  public adicionarContaBancaria() {
    if (this.listaContaBancaria.find(item => item.id === this.novaConta.id)) {
      toastr.warning('Conta bancária já foi adicionada!')
      return
    } else {
      this.listaContaBancaria.push(this.novaConta)
      this.novaConta = new ContaBancaria()
    }
  }

  public adicionarRecurso() {
    if (this.listaRecurso.find(item => item.id === this.novoRecurso.id)) {
      toastr.warning('Recurso já foi adicionado!')
      return
    } else {
      this.listaRecurso.push(this.novoRecurso)
      this.novoRecurso = new Recurso()
    }
  }

  public adicionarContrato() {
    if (this.listaContrato.find(item => item.id === this.novoContrato.id)) {
      toastr.warning('Contrato já foi adicionado!')
      return
    } else {
      this.listaContrato.push(this.novoContrato)
      this.novoContrato = new Contrato()
    }
  }

  public adicionarConvenio() {
    if (this.listaConvenio.find(item => item.id === this.novoConvenio.id)) {
      toastr.warning('Convênio já foi adicionado')
    } else {
      this.listaConvenio.push(this.novoConvenio)
      this.novoConvenio = new Convenio()
    }
  }

  public removerContaBancaria(idx: number) {
    this.listaContaBancaria.splice(idx, 1)
  }

  public removerRecurso(idx: number) {
    this.listaRecurso.splice(idx, 1)
  }

  public removerContrato(idx: number) {
    this.listaContrato.splice(idx, 1)
  }

  public removerConvenio(idx: number) {
    this.listaConvenio.splice(idx, 1)
  }

  public async normalizadorXlsx(lista: any[], tipo: 'O' | 'R' | 'E') {
    let listaNormalizado: { convenio?: string, data?: string, codBanco?: number, ficha?: number, numero?: number, parcela?: number, recurso?: string, tipo?: string, documento?: string, nome?: string, anulacao?: 'N' | 'S', tipoFornecedor?: 'F' | 'C', origem?: number, parcelaOrigem?: number, fichaOrigem?: number, valorEmpenhado?: number, valorLiquidado?: number, valorRetido?: number }[] = []

    if (tipo === 'O') {
      lista.forEach((item: Pagamento) => {
        listaNormalizado.push({
          convenio: `${item?.liquidacao?.empenho?.convenio?.numero}/${item?.liquidacao?.empenho?.convenio?.ano} - ${item?.liquidacao?.empenho?.convenio?.finalidade}`,
          data: this.funcaoService.converteDataBR(item.data_pagamento),
          codBanco: item.conta?.codigo,
          ficha: item.liquidacao?.empenho?.ficha?.numero,
          numero: item.liquidacao?.empenho?.numero,
          parcela: item.liquidacao?.parcela,
          recurso: item.liquidacao?.empenho?.ficha?.aplicacao_variavel?.codigo,
          tipo: item.liquidacao?.empenho?.especie,
          documento: item.liquidacao?.documento,
          nome: item.liquidacao?.empenho?.favorecido?.nome,
          anulacao: !item.anulacao ? 'N' : 'S',
          tipoFornecedor: item.liquidacao?.empenho?.convenio?.tipo_fornecedor,
          valorEmpenhado: item.liquidacao?.empenho?.valor_empenho,
          valorLiquidado: item.valor_pago,
          valorRetido: item.valor_retido
        })
      })
    } else if (tipo === 'R') {
      for await (const item of lista) {
        let ficha = await this.retornaFichaResto(this.login.orgao.id, item.liquidacao.empenho.ano, item.liquidacao.empenho.acao, item.liquidacao.empenho.subfuncao, item.liquidacao.empenho.funcao, item.liquidacao.empenho.programa, item.liquidacao.empenho.recurso_variavel, item.liquidacao.empenho.recurso, item.liquidacao.empenho.aplicacao)

        let recurso = `${item.liquidacao.empenho.recurso}${item.liquidacao.empenho.aplicacao}${item.liquidacao.empenho.recurso_variavel}`

        listaNormalizado.push({
          convenio: `${item?.liquidacao?.empenho?.convenio?.numero}/${item?.liquidacao?.empenho?.convenio?.ano} - ${item?.liquidacao?.empenho?.convenio?.finalidade}`,
          data: this.funcaoService.converteDataBR(item.data_pagamento),
          codBanco: item.conta?.codigo,
          ficha: ficha !== '-' ? ficha : item?.ficha_empenho,
          numero: item.liquidacao?.empenho?.numero,
          parcela: item.liquidacao?.parcela,
          recurso: recurso,
          tipo: item.liquidacao?.empenho?.especie,
          documento: item.liquidacao?.documento,
          nome: item.liquidacao?.empenho?.favorecido?.nome,
          anulacao: !item.anulacao ? 'N' : 'S',
          tipoFornecedor: item.liquidacao?.empenho?.convenio?.tipo_fornecedor,
          valorEmpenhado: item.liquidacao?.empenho?.valor_empenho,
          valorLiquidado: item.valor_pago,
          valorRetido: item.valor_retido
        })
      }
    } else if (tipo === 'E') {
      lista.forEach((item: PagamentoExtra) => {

        listaNormalizado.push({
          convenio: `${item?.empenho?.retencao?.liquidacao?.empenho?.convenio?.numero}/${item?.empenho?.retencao?.liquidacao?.empenho?.convenio?.ano} - ${item?.empenho?.retencao?.liquidacao?.empenho?.convenio?.finalidade}`,
          data: this.funcaoService.converteDataBR(item.data_pagamento),
          codBanco: item.conta?.codigo,
          ficha: item?.empenho?.ficha?.numero,
          numero: item?.empenho?.numero,
          parcela: item.empenho?.parcela,
          recurso: `${item.empenho?.ficha?.recurso?.codigo}${item.empenho?.ficha?.aplicacao?.codigo}0000`,
          tipo: item.empenho?.especie,
          documento: item.empenho?.documento,
          nome: item.empenho?.favorecido?.nome,
          anulacao: !item.anulacao ? 'N' : 'S',
          tipoFornecedor: item?.empenho?.retencao?.liquidacao?.empenho?.convenio?.tipo_fornecedor,
          origem: item.empenho?.retencao?.liquidacao?.empenho?.numero,
          parcelaOrigem: item.empenho?.retencao?.liquidacao?.parcela,
          fichaOrigem: item.empenho?.retencao?.liquidacao?.empenho?.ficha?.numero,
          valorEmpenhado: item.empenho?.valor_empenho,
          valorLiquidado: item.valor_pago,
          valorRetido: item.valor_retido
        })
      })
    }
    return listaNormalizado
  }

  public colunasRelatorioOR(): Coluna[] {
    return [
      { titulo: 'Convenio', coluna: 'convenio' },
      { titulo: 'Data', coluna: 'data' },
      { titulo: 'Cod. Banco', coluna: 'codBanco' },
      { titulo: 'Ficha', coluna: 'ficha' },
      { titulo: 'Numero', coluna: 'numero' },
      { titulo: 'Parcela', coluna: 'parcela' },
      { titulo: 'Recurso', coluna: 'recurso' },
      { titulo: 'Tipo', coluna: 'tipo' },
      { titulo: 'Documento', coluna: 'documento' },
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'Anulação', coluna: 'anulacao' },
      { titulo: 'Tipo Fornecedor', coluna: 'tipoFornecedor' },
      { titulo: 'V. Empenho', coluna: 'valorEmpenhado' },
      { titulo: 'V. Liquidado', coluna: 'valorLiquidado' },
      { titulo: 'V. Retido', coluna: 'valorRetido' },
    ]
  }
  public colunasRelatorioE(): Coluna[] {
    return [
      { titulo: 'Convenio', coluna: 'convenio' },
      { titulo: 'Data', coluna: 'data' },
      { titulo: 'Cod. Banco', coluna: 'codBanco' },
      { titulo: 'Ficha', coluna: 'ficha' },
      { titulo: 'Numero', coluna: 'numero' },
      { titulo: 'Parcela', coluna: 'parcela' },
      { titulo: 'Recurso', coluna: 'recurso' },
      { titulo: 'Tipo', coluna: 'tipo' },
      { titulo: 'Documento', coluna: 'documento' },
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'Anulação', coluna: 'anulacao' },
      { titulo: 'Tipo Fornecedor', coluna: 'tipoFornecedor' },
      { titulo: 'Origem', coluna: 'origem' },
      { titulo: 'Origem Parcela', coluna: 'parcelaOrigem' },
      { titulo: 'Origem Ficha', coluna: 'fichaOrigem' },
      { titulo: 'V. Empenho', coluna: 'valorEmpenhado' },
      { titulo: 'V. Liquidado', coluna: 'valorLiquidado' },
      { titulo: 'V. Retido', coluna: 'valorRetido' },
    ]
  }

  public async exportar(lista, formato?: FormatoExportacao, colunas?: Coluna[], nome?: string, tipo?: 'O' | 'R' | 'E') {
    new FuncaoService().exportar(formato, await this.normalizadorXlsx(lista, tipo), nome, colunas);
  }

  public imprimirXlsx(formato: FormatoExportacao) {
    if (this.relatoriosSelect === 'EMPENHO_CONVENIO_ORCAMENTARIO') {
      this.imprimirConteudo_O(formato);
    } else if (this.relatoriosSelect === 'EMPENHO_CONVENIO_RESTO') {
      this.imprimirConteudo_R(formato);
    } else if (this.relatoriosSelect === 'EMPENHO_CONVENIO_EXTRA') {
      this.imprimirConteudo_E(formato);
    }
  }

  public imprimirPdf(formato: FormatoExportacao) {
    if (this.relatoriosSelect === 'EMPENHO_CONVENIO_ORCAMENTARIO') {
      this.imprimirConteudo_O(formato);
    } else if (this.relatoriosSelect === 'EMPENHO_CONVENIO_RESTO') {
      this.imprimirConteudo_R(formato);
    } else if (this.relatoriosSelect === 'EMPENHO_CONVENIO_EXTRA') {
      this.imprimirConteudo_E(formato);
    }
  }

  private async imprimirConteudo_O(formato: FormatoExportacao) {
    this.resto_ficha = false;

    let parametros = {};
    let relations = 'conta,conta.banco,liquidacao,liquidacao.empenho,liquidacao.empenho.ficha,liquidacao.empenho.ficha.recurso,liquidacao.empenho.ficha.aplicacao,liquidacao.empenho.ficha.aplicacao_variavel,liquidacao.empenho.favorecido,liquidacao.empenho.convenio,liquidacao.empenho.contrato';

    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['relations'] = relations;

    parametros['data_pagamento$ge'] = this.datepipe.transform(this.dataInicial, 'yyyy-MM-dd');
    parametros['data_pagamento$le'] = this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd');
    parametros['liquidacao.empenho.convenio.id$not_null'] = true;
    parametros['id$not_null'] = true;
    parametros['orderBy'] = 'liquidacao.empenho.convenio.numero,liquidacao.empenho.convenio.ano';
    if (this.filtrarConta && this.listaContaBancaria.length > 0) {
      let idsConta = (this.listaContaBancaria.map(item => item.id)).join(',')
      parametros['conta$in'] = idsConta
    }

    if (this.filtrarRecurso && !this.modeloBusca) {
      parametros['liquidacao.empenho.ficha.recurso.id'] = this.recurso.id;
    }

    if (this.filtroAplicacao && !this.modeloBusca) {
      parametros['liquidacao.empenho.ficha.aplicacao.id'] = this.aplicacao.id;
    }

    if (this.filtroFicha) {
      parametros['liquidacao.empenho.ficha.numero'] = this.ficha.numero;
    }

    // modelo de busca Avancado
    if (this.filtroAplicacao_variavel && !this.modeloBusca && !this.filtragemAvancada) {
      parametros['liquidacao.empenho.ficha.aplicacao_variavel.id'] = this.aplicacao_variavel.id;
    } else if (this.filtroAplicacao_variavel && !this.modeloBusca && this.filtragemAvancada) {
      parametros['liquidacao.empenho.ficha.aplicacao_variavel.id$ge'] = this.aplicacao_variavel.id;
      parametros['liquidacao.empenho.ficha.aplicacao_variavel.id$le'] = this.aplicacao_variavel_final.id;

      // modelo de busca Simples
    } else if (!this.filtroAplicacao_variavel && this.modeloBusca && this.filtroRecursoAplicacao && !this.filtragemAvancada) {
      parametros['liquidacao.empenho.ficha.aplicacao_variavel.id'] = this.RecursoAplicacao.id;
    } else if (!this.filtroAplicacao_variavel && this.modeloBusca && this.filtroRecursoAplicacao && this.filtragemAvancada) {
      parametros['liquidacao.empenho.ficha.aplicacao_variavel.id$ge'] = this.RecursoAplicacao.id;
      parametros['liquidacao.empenho.ficha.aplicacao_variavel.id$le'] = this.RecursoAplicacaoFinal.id;
    }

    if (this.filtroTipoFornecedor) {
      parametros['liquidacao.empenho.convenio.tipo_fornecedor'] = 'F'
    }

    if (this.filtroTipoCredor) {
      parametros['liquidacao.empenho.convenio.tipo_fornecedor'] = 'C'
    }

    if (this.filtroContrato && this.listaContrato.length > 0) {
      let idsContrato = (this.listaContrato.map(item => item.id)).join(',')
      parametros['liquidacao.empenho.contrato.id$in'] = idsContrato
    }

    if (this.filtroConvenio && this.listaConvenio.length > 0) {
      let idsConvenio = this.listaConvenio.map(item => item.id).join(',')
      parametros['liquidacao.empenho.convenio.id$in'] = idsConvenio
    }

    let lista = await this.pagamentoService_orcamentario.filtrar(1, -1, parametros).toPromise();

    if (lista.content.length === 0) {
      toastr.info('A pesquisa não retornou informações!')
      return
    }

    if (formato === 'pdf') {
      await this.gerarRelatorioPDF('Pagamentos Orçamentarios vinculados a Convênios', this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
        await this.montarConteudoConvenios([lista.content]), 'Pagamentos Orçamentarios vinculados a Convênios')
    } else {
      this.exportar(lista.content, formato, this.colunasRelatorioOR(), 'pagamentos_orcamentarios_vinculados_convenios', 'O')
    }

  }

  private async imprimirConteudo_R(formato: FormatoExportacao) {
    this.resto_ficha = true;
    let parametros = {};
    let relations = 'conta,conta.banco,liquidacao,liquidacao.empenho,liquidacao.empenho.favorecido,liquidacao.empenho.convenio,liquidacao.empenho.contrato,liquidacao.empenho';

    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['relations'] = relations;

    parametros['data_pagamento$ge'] = this.datepipe.transform(this.dataInicial, 'yyyy-MM-dd');
    parametros['data_pagamento$le'] = this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd');
    parametros['liquidacao.empenho.convenio.id$not_null'] = true;
    parametros['id$not_null'] = true;
    parametros['orderBy'] = 'liquidacao.empenho.convenio.numero,liquidacao.empenho.convenio.ano';
    if (this.filtrarConta && this.listaContaBancaria.length > 0) {
      let idsConta = (this.listaContaBancaria.map(item => item.id)).join(',')
      parametros['conta$in'] = idsConta
    }

    // if (this.filtrarRecurso && this.listaRecurso.length > 0) {
    //   let nomesRecursoVariavel = (this.listaRecurso.map(item => item.variavel)).join(',')
    //   parametros['liquidacao.empenho.recurso_variavel$in'] = nomesRecursoVariavel
    // }

    if (this.filtrarRecurso && !this.modeloBusca) {
      parametros['liquidacao.empenho.recurso'] = this.recurso.codigo;
    }

    if (this.filtroAplicacao && !this.modeloBusca) {
      parametros['liquidacao.empenho.aplicacao'] = this.aplicacao.codigo;
    }

    if (this.filtroTipoFornecedor) {
      parametros['liquidacao.empenho.convenio.tipo_fornecedor'] = 'F'
    }

    if (this.filtroTipoCredor) {
      parametros['liquidacao.empenho.convenio.tipo_fornecedor'] = 'C'
    }

    if (this.filtroContrato && this.listaContrato.length > 0) {
      let idsContrato = (this.listaContrato.map(item => item.id)).join(',')
      parametros['liquidacao.empenho.contrato.id$in'] = idsContrato
    }

    if (this.filtroFicha) {
      parametros['liquidacao.empenho.subelemento'] = this.ficha.despesa.codigo;
      parametros['liquidacao.empenho.acao'] = this.ficha.acao.codigo;
      parametros['liquidacao.empenho.funcao'] = this.ficha.funcao.codigo;
      parametros['liquidacao.empenho.subfuncao'] = this.ficha.subfuncao.codigo;
      parametros['liquidacao.empenho.executora'] = this.ficha.executora.codigo;
      parametros['liquidacao.empenho.programa'] = this.ficha.programa.codigo;
      parametros['liquidacao.empenho.recurso'] = this.ficha.recurso.codigo;
      parametros['liquidacao.empenho.aplicacao'] = this.ficha.aplicacao.codigo;
      parametros['liquidacao.empenho.recurso_variavel'] = this.ficha.aplicacao_variavel.variavel;
    }

    // modelo de busca Avancado
    if (this.filtroAplicacao_variavel && !this.modeloBusca && !this.filtragemAvancada) {
      let v
      if (this.aplicacao_variavel.codigo.length > 4)
        v = this.aplicacao_variavel.codigo.substring(5, this.aplicacao_variavel.codigo.length);

      parametros['liquidacao.empenho.recurso_variavel'] = v;
    } else if (this.filtroAplicacao_variavel && !this.modeloBusca && this.filtragemAvancada) {
      let v_inicial; let v_final
      if (this.aplicacao_variavel.codigo.length > 4)
        v_inicial = this.aplicacao_variavel.codigo.substring(5, this.aplicacao_variavel.codigo.length);

      if (this.aplicacao_variavel.codigo.length > 4)
        v_final = this.aplicacao_variavel.codigo.substring(5, this.aplicacao_variavel.codigo.length);

      parametros['liquidacao.empenho.recurso_variavel$ge'] = v_inicial;
      parametros['liquidacao.empenho.recurso_variavel$le'] = v_final;

      // modelo de busca Simples
    } else if (!this.filtroAplicacao_variavel && this.modeloBusca && this.filtroRecursoAplicacao && !this.filtragemAvancada) {
      let r = this.RecursoAplicacao.codigo.substring(0, 2);
      let a = this.RecursoAplicacao.codigo.substring(2, 5);
      let v = this.RecursoAplicacao.codigo.substring(5, this.RecursoAplicacao.codigo.length);

      parametros['liquidacao.empenho.recurso'] = r
      parametros['liquidacao.empenho.aplicacao'] = a
      parametros['liquidacao.empenho.recurso_variavel'] = v
    } else if (!this.filtroAplicacao_variavel && this.modeloBusca && this.filtroRecursoAplicacao && this.filtragemAvancada) {

      /* NUMERO INICIAL */
      let r_inicial = this.RecursoAplicacao.codigo.substring(0, 2);
      let a_inicial = this.RecursoAplicacao.codigo.substring(2, 5);
      let v_inicial = this.RecursoAplicacao.codigo.substring(5, this.RecursoAplicacao.codigo.length);

      parametros['liquidacao.empenho.recurso$ge'] = r_inicial
      parametros['liquidacao.empenho.aplicacao$ge'] = a_inicial
      parametros['liquidacao.empenho.recurso_variavel$ge'] = v_inicial

      /* NUMERO FINAL */
      let r_final = this.RecursoAplicacaoFinal.codigo.substring(0, 2);
      let a_final = this.RecursoAplicacaoFinal.codigo.substring(2, 5);
      let v_final = this.RecursoAplicacaoFinal.codigo.substring(5, this.RecursoAplicacaoFinal.codigo.length);

      parametros['liquidacao.empenho.recurso$le'] = r_final
      parametros['liquidacao.empenho.aplicacao$le'] = a_final
      parametros['liquidacao.empenho.recurso_variavel$le'] = v_final
    }


    let lista = await this.pagamentoService_resto.extendido(1, -1, parametros).toPromise();

    if (lista.content.length === 0) {
      toastr.info('A pesquisa não retornou informações!')
      return
    }

    if (formato === 'pdf') {
      await this.gerarRelatorioPDF('Pagamentos de Resto vinculados a Convênios', this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, await this.montarConteudoConvenios([lista.content]), 'Pagamentos de Resto vinculados a Convênios')
    } else {
      this.exportar(lista.content, formato, this.colunasRelatorioOR(), 'pagamentos_resto_vinculados_convenio', 'R')
    }
  }

  private async imprimirConteudo_E(formato: FormatoExportacao) {
    let lista_1
    let lista_2
    let lista_3
    let busca

    let parametros = {};
    let parametros_2 = {};
    let parametros_3 = {};

    this.resto_ficha = true;
    this.extraGrupBy = 0

    let relations = 'conta,conta.banco,empenho,empenho.ficha.recurso,empenho.ficha.aplicacao,empenho.ficha.aplicacao_variavel,empenho.favorecido,empenho.convenio,empenho.retencao,empenho.retencao.liquidacao,empenho.retencao.liquidacao.empenho,empenho.retencao.liquidacao.empenho.ficha,empenho.retencao.liquidacao.empenho.convenio,empenho.retencao_resto,empenho.retencao_resto.liquidacao,empenho.retencao_resto.liquidacao.empenho,empenho.retencao_resto.liquidacao.empenho.convenio,empenho.retencao_extra,empenho.retencao_extra.ficha,empenho.retencao_extra.empenho_extra,empenho.retencao_extra.empenho_extra.convenio,empenho.retencao.liquidacao.empenho.contrato';

    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['relations'] = relations;

    parametros['data_pagamento$ge'] = this.datepipe.transform(this.dataInicial, 'yyyy-MM-dd');
    parametros['data_pagamento$le'] = this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd');
    parametros['id$not_null'] = true;
    parametros['empenho.retencao.liquidacao.empenho.convenio.id$not_null'] = true;
    parametros['orderBy'] = 'empenho.retencao.liquidacao.empenho.convenio.numero,empenho.retencao.liquidacao.empenho.convenio.ano';
    if (this.filtrarConta && this.listaContaBancaria.length > 0) {
      let idsConta = (this.listaContaBancaria.map(item => item.id)).join(',')
      parametros['conta$in'] = idsConta
    }

    // if (this.filtrarRecurso && this.listaRecurso.length > 0) {
    //   let codigosRecurso = (this.listaRecurso.map(item => item.codigo.slice(0, 2))).join(',')
    //   let codigosAplicacao = (this.listaRecurso.map(item => item.codigo.slice(2, 5))).join(',')
    //   parametros['empenho.ficha.recurso.codigo$in'] = codigosRecurso
    //   parametros['empenho.ficha.aplicacao.codigo$in'] = codigosAplicacao
    // }

    if (this.filtroRecurso && !this.modeloBusca) {
      parametros['empenho.ficha.recurso.id'] = this.recurso.id;
    }

    if (this.filtroAplicacao && !this.modeloBusca) {
      parametros['empenho.ficha.aplicacao.id'] = this.aplicacao.id;
    }


    if (this.filtroTipoFornecedor) {
      parametros['empenho.retencao.liquidacao.empenho.convenio.tipo_fornecedor'] = 'F'
    }

    if (this.filtroTipoCredor) {
      parametros['empenho.retencao.liquidacao.empenho.convenio.tipo_fornecedor'] = 'C'
    }

    if (this.filtroFicha) {
      parametros['empenho.ficha.numero'] = this.fichaExtra.numero;
    }

    // modelo de busca Avancado
    if (this.filtroAplicacao_variavel && !this.modeloBusca && !this.filtragemAvancada) {
      parametros['empenho.ficha.aplicacao_variavel.id'] = this.aplicacao_variavel.id;
    } else if (this.filtroAplicacao_variavel && !this.modeloBusca && this.filtragemAvancada) {
      parametros['empenho.ficha.aplicacao_variavel.id$ge'] = this.aplicacao_variavel.id;
      parametros['empenho.ficha.aplicacao_variavel.id$le'] = this.aplicacao_variavel_final.id;

      // modelo de busca Simples
    } else if (!this.filtroAplicacao_variavel && this.modeloBusca && this.filtroRecursoAplicacao && !this.filtragemAvancada) {
      parametros['empenho.ficha.aplicacao_variavel.id'] = this.RecursoAplicacao.id;
    } else if (!this.filtroAplicacao_variavel && this.modeloBusca && this.filtroRecursoAplicacao && this.filtragemAvancada) {
      parametros['empenho.ficha.aplicacao_variavel.id$ge'] = this.RecursoAplicacao.id;
      parametros['empenho.ficha.aplicacao_variavel.id$le'] = this.RecursoAplicacaoFinal.id;
    }


    if (this.filtroContrato && this.listaContrato.length > 0) {
      let idsContrato = (this.listaContrato.map(item => item.id)).join(',')
      parametros['empenho.retencao.liquidacao.empenho.contrato.id$in'] = idsContrato
    }

    lista_1 = await this.pagamentoService_extra.filtrar(1, -1, parametros).toPromise();
    busca = lista_1.content;

    if (busca == undefined) {
      this.extraGrupBy = 1;
      parametros_2['exercicio_id'] = this.login.exercicio.id;
      parametros_2['orgao_id'] = this.login.orgao.id;
      parametros_2['relations'] = relations;

      parametros_2['data_pagamento$ge'] = this.datepipe.transform(this.dataInicial, 'yyyy-MM-dd');
      parametros_2['data_pagamento$le'] = this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd');
      parametros_2['id$not_null'] = true;
      parametros_2['empenho.retencao_resto.liquidacao.empenho.convenio.id$not_null'] = true;
      parametros_2['orderBy'] = 'empenho.retencao_resto.liquidacao.empenho.convenio.numero,empenho.retencao_resto.liquidacao.empenho.convenio.ano';
      if (this.filtrarConta && this.listaContaBancaria.length > 0) {
        let idsConta = (this.listaContaBancaria.map(item => item.id)).join(',')
        parametros_2['conta$in'] = idsConta
      }

      if (this.filtroRecurso && !this.modeloBusca) {
        parametros_2['empenho.ficha.recurso.id'] = this.recurso.id;
      }

      if (this.filtroAplicacao && !this.modeloBusca) {
        parametros_2['empenho.ficha.aplicacao.id'] = this.aplicacao.id;
      }

      if (this.filtroFicha) {
        parametros_2['empenho.ficha.numero'] = this.fichaExtra.numero;
      }

      // modelo de busca Avancado
      if (this.filtroAplicacao_variavel && !this.modeloBusca && !this.filtragemAvancada) {
        parametros_2['empenho.ficha.aplicacao_variavel.id'] = this.aplicacao_variavel.id;
      } else if (this.filtroAplicacao_variavel && !this.modeloBusca && this.filtragemAvancada) {
        parametros_2['empenho.ficha.aplicacao_variavel.id$ge'] = this.aplicacao_variavel.id;
        parametros_2['empenho.ficha.aplicacao_variavel.id$le'] = this.aplicacao_variavel_final.id;

        // modelo de busca Simples
      } else if (!this.filtroAplicacao_variavel && this.modeloBusca && this.filtroRecursoAplicacao && !this.filtragemAvancada) {
        parametros_2['empenho.ficha.aplicacao_variavel.id'] = this.RecursoAplicacao.id;
      } else if (!this.filtroAplicacao_variavel && this.modeloBusca && this.filtroRecursoAplicacao && this.filtragemAvancada) {
        parametros_2['empenho.ficha.aplicacao_variavel.id$ge'] = this.RecursoAplicacao.id;
        parametros_2['empenho.ficha.aplicacao_variavel.id$le'] = this.RecursoAplicacaoFinal.id;
      }

      // if (this.filtrarRecurso && this.listaRecurso.length > 0) {
      //   let codigosRecurso = (this.listaRecurso.map(item => item.codigo.slice(0, 2))).join(',')
      //   let codigosAplicacao = (this.listaRecurso.map(item => item.codigo.slice(2, 5))).join(',')
      //   parametros_2['empenho.ficha.recurso.codigo$in'] = codigosRecurso
      //   parametros_2['empenho.ficha.aplicacao.codigo$in'] = codigosAplicacao
      // }

      if (this.filtroTipoFornecedor) {
        parametros_2['empenho.retencao.liquidacao.empenho.convenio.tipo_fornecedor'] = 'F'
      }

      if (this.filtroTipoCredor) {
        parametros_2['empenho.retencao.liquidacao.empenho.convenio.tipo_fornecedor'] = 'C'
      }

      if (this.filtroContrato && this.listaContrato.length > 0) {
        let idsContrato = (this.listaContrato.map(item => item.id)).join(',')
        parametros_2['empenho.retencao.liquidacao.empenho.contrato.id$in'] = idsContrato
      }

      lista_2 = await this.pagamentoService_extra.filtrar(1, -1, parametros_2).toPromise();
      busca = lista_2.content;

    } else if (busca == undefined) {
      this.extraGrupBy = 2;
      parametros_3['exercicio_id'] = this.login.exercicio.id;
      parametros_3['orgao_id'] = this.login.orgao.id;
      parametros_3['relations'] = relations;

      parametros_3['data_pagamento$ge'] = this.datepipe.transform(this.dataInicial, 'yyyy-MM-dd');
      parametros_3['data_pagamento$le'] = this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd');
      parametros_3['id$not_null'] = true;
      parametros_3['empenho.retencao_extra.empenho_extra.convenio.id$not_null'] = true;
      parametros_3['orderBy'] = 'empenho.retencao_extra.empenho_extra.convenio.numero,empenho.retencao_extra.empenho_extra.convenio.ano';
      if (this.filtrarConta && this.listaContaBancaria.length > 0) {
        let idsConta = (this.listaContaBancaria.map(item => item.id)).join(',')
        parametros_3['conta$in'] = idsConta
      }

      // if (this.filtrarRecurso && this.listaRecurso.length > 0) {
      //   let codigosRecurso = (this.listaRecurso.map(item => item.codigo.slice(0, 2))).join(',')
      //   let codigosAplicacao = (this.listaRecurso.map(item => item.codigo.slice(2, 5))).join(',')
      //   parametros_3['empenho.ficha.recurso.codigo$in'] = codigosRecurso
      //   parametros_3['empenho.ficha.aplicacao.codigo$in'] = codigosAplicacao
      // }

      if (this.filtroRecurso && !this.modeloBusca) {
        parametros_3['empenho.ficha.recurso.id'] = this.recurso.id;
      }

      if (this.filtroAplicacao && !this.modeloBusca) {
        parametros_3['empenho.ficha.aplicacao.id'] = this.aplicacao.id;
      }

      if (this.filtroFicha) {
        parametros_3['empenho.ficha.numero'] = this.fichaExtra.numero;
      }

      // modelo de busca Avancado
      if (this.filtroAplicacao_variavel && !this.modeloBusca && !this.filtragemAvancada) {
        parametros_3['empenho.ficha.aplicacao_variavel.id'] = this.aplicacao_variavel.id;
      } else if (this.filtroAplicacao_variavel && !this.modeloBusca && this.filtragemAvancada) {
        parametros_3['empenho.ficha.aplicacao_variavel.id$ge'] = this.aplicacao_variavel.id;
        parametros_3['empenho.ficha.aplicacao_variavel.id$le'] = this.aplicacao_variavel_final.id;

        // modelo de busca Simples
      } else if (!this.filtroAplicacao_variavel && this.modeloBusca && this.filtroRecursoAplicacao && !this.filtragemAvancada) {
        parametros_3['empenho.ficha.aplicacao_variavel.id'] = this.RecursoAplicacao.id;
      } else if (!this.filtroAplicacao_variavel && this.modeloBusca && this.filtroRecursoAplicacao && this.filtragemAvancada) {
        parametros_3['empenho.ficha.aplicacao_variavel.id$ge'] = this.RecursoAplicacao.id;
        parametros_3['empenho.ficha.aplicacao_variavel.id$le'] = this.RecursoAplicacaoFinal.id;
      }

      if (this.filtroTipoFornecedor) {
        parametros_3['empenho.retencao_extra.empenho_extra.convenio.tipo_fornecedor'] = 'F'
      }

      if (this.filtroTipoCredor) {
        parametros_3['empenho.retencao_extra.empenho_extra.convenio.tipo_fornecedor'] = 'C'
      }

      if (this.filtroContrato && this.listaContrato.length > 0) {
        let idsContrato = (this.listaContrato.map(item => item.id)).join(',')
        parametros_3['empenho.retencao_extra.empenho_extra.contrato.id$in'] = idsContrato
      }

      lista_3 = await this.pagamentoService_extra.filtrar(1, -1, parametros_3).toPromise();
      busca = lista_3.content;
    }

    if (busca.length === 0) {
      toastr.info('A pesquisa não retornou informações!')
      return
    }
    if (formato === 'pdf') {
      await this.gerarRelatorioPDF('Pagamentos Extra vinculados a Convênios', this.login.usuario.nome, this.login.usuario.sobrenome,
        this.login.orgao.nome, this.login.brasao, await this.montarConteudoConvenios([busca]), 'Pagamentos Extra vinculados a Convênios')
    } else {
      this.exportar(busca, formato, this.colunasRelatorioE(), 'pagamento_extra_vinculados_convenio', 'E')
    }
  }

  public async gerarRelatorioPDF(titulo: string, nomeUsuario: string, sobrenomeUsuario: string, nomeOrgao: string, brasao: string, conteudo: {}[], nomePdf: string) {
    Relatorio.imprimirPersonalizado(titulo, nomeUsuario, sobrenomeUsuario, nomeOrgao, brasao, conteudo, 'landscape', nomePdf, await this.retornaLayout(), false);
  }

  public async retornaLayout() {
    return {
      linhas: {
        hLineWidth() {
          return 1;
        },
        vLineWidth() {
          return 1;
        },
        hLineColor() {
          return 'black';
        },
        paddingLeft() {
          return 3;
        },
        paddingRight() {
          return 3;
        }
      }
    }
  }

  public async retornaFichaResto(orgaoId, exercicioAno, codigoAcao, codigoSubfuncao, codigoFuncao, codigoPrograma, variavel, codigoRecurso, codigoAplicacao) {
    let parametro = { orgao_id: orgaoId, 'exercicio.ano': exercicioAno, 'acao.codigo': codigoAcao, 'subfuncao.codigo': codigoSubfuncao, 'funcao.codigo': codigoFuncao, 'programa.codigo': codigoPrograma, 'recurso.codigo': codigoRecurso, 'aplicacao.codigo': codigoAplicacao, relations: 'acao,subfuncao,funcao,programa,recurso,aplicacao,aplicacao_variavel,orgao,exercicio', 'ignoreCondObrig': true }
    if (variavel) {
      parametro['aplicacao_variavel.codigo'] = codigoRecurso.toString() + codigoAplicacao.toString() + variavel.toString()
    }
    let ficha = await this.fichaService.filtrar(1, -1, parametro).toPromise();
    return ficha.content[0]?.numero ? ficha.content[ficha.numberOfElements - 1]?.numero : '-'
  }

  private async montarConteudoConvenios(lista: any[]) {
    let ativo = lista
    if (this.ativo) {
      lista = ativo.filter((elemento) => {
        return elemento.ativo === true
      })
    }

    let grupos;
    if (this.relatoriosSelect === 'EMPENHO_CONVENIO_ORCAMENTARIO' || this.relatoriosSelect === 'EMPENHO_CONVENIO_RESTO') {
      grupos = this.funcaoService.agrupar(lista[0], ['liquidacao.empenho.convenio.numero', 'liquidacao.empenho.convenio.ano', 'liquidacao.empenho.convenio.finalidade'])
    } else if (this.relatoriosSelect === 'EMPENHO_CONVENIO_EXTRA') {
      if (this.extraGrupBy === 0) {
        grupos = this.funcaoService.agrupar(lista[0], ['empenho.retencao.liquidacao.empenho.convenio.numero', 'empenho.retencao.liquidacao.empenho.convenio.ano', 'empenho.retencao.liquidacao.empenho.convenio.finalidade'])
      } else if (this.extraGrupBy === 1) {
        grupos = this.funcaoService.agrupar(lista[0], ['empenho.retencao_resto.liquidacao.empenho.convenio.numero', 'empenho.retencao_resto.liquidacao.empenho.convenio.ano', 'empenho.retencao_resto.liquidacao.empenho.convenio.finalidade'])
      } else if (this.extraGrupBy === 2) {
        grupos = this.funcaoService.agrupar(lista[0], ['empenho.retencao_extra.empenho_extra.convenio.numero', 'empenho.retencao_extra.empenho_extra.convenio.ano', 'empenho.retencao_extra.empenho_extra.convenio.finalidade'])
      }
    }

    let retorno: {}[] = [];
    retorno.push({
      text: `REFERÊNCIA: ${this.datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} a ${this.datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
      alignment: 'center', fontSize: 10, lineHeight: 1.5
    })

    for (let grupo of grupos) {
      const conteudo = [];

      if (this.relatoriosSelect === 'EMPENHO_CONVENIO_ORCAMENTARIO' || this.relatoriosSelect === 'EMPENHO_CONVENIO_RESTO') {
        conteudo.push([
          { text: 'Data', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
          { text: 'Cod. Banco', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Ficha', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Numero', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Parcela', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Recurso', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Tipo', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Documento', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Nome', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Anulação', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Tipo Fornecedor', fontSize: 8, alignment: 'center', bold: true },
          { text: 'V. Empenhado', fontSize: 8, alignment: 'center', bold: true },
          { text: 'V. liquidado', fontSize: 8, alignment: 'center', bold: true },
          { text: 'V. Retido', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
        ]);

      } else {
        conteudo.push([
          { text: 'Data', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
          { text: 'Cod. Banco', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Ficha', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Empenho', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Parcela', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Recurso', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Tipo', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Documento', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Nome', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Anulação', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Tipo Fornecedor', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Origem', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Origem Parcela', fontSize: 8, alignment: 'center', bold: true },
          { text: 'Origem Ficha', fontSize: 8, alignment: 'center', bold: true },
          { text: 'V. Empenhado', fontSize: 8, alignment: 'center', bold: true },
          { text: 'V. liquidado', fontSize: 8, alignment: 'center', bold: true },
          { text: 'V. Retido', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
        ]);
      }

      if (this.relatoriosSelect === 'EMPENHO_CONVENIO_ORCAMENTARIO' || this.relatoriosSelect === 'EMPENHO_CONVENIO_RESTO') {

        conteudo.push([
          { text: `Convênio: ${grupo.grupo['liquidacao.empenho.convenio.numero'] + '/' + grupo.grupo['liquidacao.empenho.convenio.ano'] + ' - ' + grupo.grupo['liquidacao.empenho.convenio.finalidade']}`, colSpan: 14, bold: true, fontSize: 10 },
          { text: `` },
          { text: `` },
          { text: `` },
          { text: `` },
          { text: `` },
          { text: `` },
          { text: `` },
          { text: `` },
          { text: `` },
          { text: `` },
          { text: `` },
          { text: `` },
          { text: `` }
        ])

      } else {

        if (this.extraGrupBy === 0) {
          conteudo.push([
            { text: `Convênio: ${grupo.grupo['empenho.retencao.liquidacao.empenho.convenio.numero'] + '/' + grupo.grupo['empenho.retencao.liquidacao.empenho.convenio.ano'] + ' - ' + grupo.grupo['empenho.retencao.liquidacao.empenho.convenio.finalidade']}`, colSpan: 17, bold: true, fontSize: 10 },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` }
          ]);

        } else if (this.extraGrupBy === 1) {
          conteudo.push([
            { text: `Convênio: ${grupo.grupo['empenho.retencao_resto.liquidacao.empenho.convenio.numero'] + '/' + grupo.grupo['empenho.retencao_resto.liquidacao.empenho.convenio.ano'] + ' - ' + grupo.grupo['empenho.retencao_resto.liquidacao.empenho.convenio.finalidade']}`, colSpan: 17, bold: true, fontSize: 10 },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` }
          ]);

        } else if (this.extraGrupBy === 2) {
          conteudo.push([
            { text: `Convênio: ${grupo.grupo['empenho.retencao_extra.empenho_extra.convenio.numero'] + '/' + grupo.grupo['empenho.retencao_extra.empenho_extra.convenio.ano'] + ' - ' + grupo.grupo['empenho.retencao_extra.empenho_extra.convenio.finalidade']}`, colSpan: 17, bold: true, fontSize: 10 },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` }
          ]);
        }

      }

      if (this.relatoriosSelect === 'EMPENHO_CONVENIO_ORCAMENTARIO' || this.relatoriosSelect === 'EMPENHO_CONVENIO_RESTO') {

        for (const entidade of grupo.registros) {

          let ficha;
          if (this.resto_ficha === true) {
            // variaveis para achar a ficha sendo que no empenho_resto nao tem relacionamento;
            let acao = entidade.liquidacao.empenho.acao;
            let subfuncao = entidade.liquidacao.empenho.subfuncao;
            let funcao = entidade.liquidacao.empenho.funcao;
            let programa = entidade.liquidacao.empenho.programa;
            let recurso = entidade.liquidacao.empenho.recurso;
            let aplicacao = entidade.liquidacao.empenho.aplicacao;
            let variavel = entidade.liquidacao.empenho?.recurso_variavel;
            let exercicio_string = entidade.liquidacao.empenho.ano.toString();
            let exercicio = exercicio_string.substring(2, exercicio_string.length);
            let exercicio_id = Number(exercicio);

            let parametro_ficha = {};
            let relations_ficha = 'acao,subfuncao,funcao,programa,recurso,aplicacao,aplicacao_variavel,orgao,exercicio';

            parametro_ficha['relations'] = relations_ficha;
            parametro_ficha['orgao_id'] = this.login.orgao.id;
            parametro_ficha['exercicio_id'] = exercicio_id;
            parametro_ficha['acao.codigo'] = acao;
            parametro_ficha['subfuncao.codigo'] = subfuncao;
            parametro_ficha['funcao.codigo'] = funcao;
            parametro_ficha['programa.codigo'] = programa;
            if (variavel && variavel != null) {
              parametro_ficha['aplicacao_variavel.codigo'] = recurso.toString() + aplicacao.toString() + variavel.toString();
            } else {
              parametro_ficha['recurso.codigo'] = recurso;
              parametro_ficha['aplicacao.codigo'] = aplicacao;
            }

            let busca_ficha = await this.fichaService.filtrar(1, -1, parametro_ficha).toPromise();
            for (let i of busca_ficha.content) {
              ficha = i.numero;
            }
            if (!ficha) {
              ficha = entidade.ficha_empenho;
            }
          }

          const registro = [];
          registro.push({ text: this.funcaoService.converteDataBR(entidade.data_pagamento), fontSize: 8, alignment: 'center', bold: false });
          registro.push({ text: entidade.conta.codigo, fontSize: 8, alignment: 'left', bold: false });
          registro.push({
            text: (this.relatoriosSelect === 'EMPENHO_CONVENIO_ORCAMENTARIO' ?
              entidade.liquidacao?.empenho?.ficha?.numero : (ficha ? ficha : '-')), fontSize: 8, alignment: (this.relatoriosSelect === 'EMPENHO_CONVENIO_ORCAMENTARIO' ? 'left' : 'center'), bold: false
          });
          registro.push({ text: entidade.liquidacao.empenho.numero, fontSize: 8, alignment: 'left', bold: false });
          registro.push({ text: entidade.liquidacao.parcela, fontSize: 8, alignment: 'center', bold: false });
          registro.push({
            text: (this.relatoriosSelect === 'EMPENHO_CONVENIO_ORCAMENTARIO' ?
              (entidade.liquidacao.empenho?.ficha?.aplicacao_variavel?.codigo ? entidade.liquidacao.empenho?.ficha?.aplicacao_variavel?.codigo : entidade.liquidacao.empenho?.ficha?.recruso?.codigo + '' + entidade.liquidacao?.empenho?.ficha?.aplicacao?.codigo) :
              entidade.liquidacao?.empenho?.recurso + '' +
              entidade.liquidacao?.empenho?.aplicacao + '' +
              entidade.liquidacao.empenho.recurso_variavel),
            fontSize: 8, alignment: 'right', bold: false
          });
          registro.push({ text: entidade.liquidacao.empenho.especie, fontSize: 8, alignment: 'left', bold: false });
          registro.push({ text: entidade.liquidacao.documento ? entidade.liquidacao.documento : '', fontSize: 8, alignment: 'right', bold: false });
          registro.push({ text: entidade.liquidacao.empenho.favorecido.nome, fontSize: 8, alignment: 'center', bold: false });
          registro.push({ text: entidade.anulacao == false ? 'N' : 'S', fontSize: 8, alignment: 'center', bold: false });
          registro.push({ text: entidade.liquidacao.empenho.convenio.tipo_fornecedor, fontSize: 8, alignment: 'center', bold: false });
          registro.push({ text: this.funcaoService.convertToBrNumber(+entidade.liquidacao.empenho.valor_empenho), fontSize: 8, alignment: 'right', bold: false });
          registro.push({ text: this.funcaoService.convertToBrNumber(+entidade.valor_pago), fontSize: 8, alignment: 'right', bold: false });
          registro.push({ text: this.funcaoService.convertToBrNumber(+entidade.valor_retido), fontSize: 8, alignment: 'right', bold: false });
          conteudo.push(registro);

        }

        const totais = this.funcaoService.totalizar(grupo.registros, ['liquidacao.empenho.valor_empenho', 'valor_pago', 'valor_retido']);
        const total = [];
        total.push({ text: 'TOTAL', fontSize: 8, alignment: 'left', bold: true, colSpan: 11 });
        total.push('');
        total.push('');
        total.push('');
        total.push('');
        total.push('');
        total.push('');
        total.push('');
        total.push('');
        total.push('');
        total.push('');
        total.push({ text: this.funcaoService.convertToBrNumber(+totais['liquidacao.empenho.valor_empenho']), fontSize: 8, alignment: 'right', bold: true });
        total.push({ text: this.funcaoService.convertToBrNumber(+totais['valor_pago']), fontSize: 8, alignment: 'right', bold: true });
        total.push({ text: this.funcaoService.convertToBrNumber(+totais['valor_retido']), fontSize: 8, alignment: 'right', bold: true });
        conteudo.push(total);

      } else {

        for (const entidade of grupo.registros) {
          let origem; let origem_parcela; let ficha_origem; let tipo_fornecedor;

          if (entidade.empenho.retencao) {
            origem = entidade.empenho.retencao.liquidacao.empenho.numero;
            origem_parcela = entidade.empenho.retencao.liquidacao.parcela;
            ficha_origem = entidade.empenho.retencao.liquidacao.empenho?.ficha?.numero;
            tipo_fornecedor = entidade.empenho.retencao.liquidacao.empenho?.convenio?.tipo_fornecedor;

          } else if (entidade.empenho.retencao_resto) {
            origem = entidade.empenho.retencao_resto.liquidacao.empenho.numero;
            origem_parcela = entidade.empenho.retencao_resto.liquidacao.parcela;
            tipo_fornecedor = entidade.empenho.retencao_resto.liquidacao.empenho.convenio?.tipo_fornecedor;

            // variaveis para achar a ficha sendo que no empenho_resto nao tem relacionamento;
            let acao = entidade.empenho.retencao_resto.liquidacao.empenho.acao;
            let subfuncao = entidade.empenho.retencao_resto.liquidacao.empenho.subfuncao;
            let funcao = entidade.empenho.retencao_resto.liquidacao.empenho.funcao;
            let programa = entidade.empenho.retencao_resto.liquidacao.empenho.programa;
            let recurso = entidade.empenho.retencao_resto.liquidacao.empenho.recurso;
            let aplicacao = entidade.empenho.retencao_resto.liquidacao.empenho.aplicacao;
            let variavel = entidade.empenho.retencao_resto.liquidacao.empenho?.recurso_variavel;
            let exercicio_string = entidade.empenho.retencao_resto.liquidacao.empenho?.ano.toString();
            let exercicio = exercicio_string.substring(2, exercicio_string.length);
            let exercicio_id = Number(exercicio);

            let parametro_ficha = {};
            let relations_ficha = 'acao,subfuncao,funcao,programa,recurso,aplicacao,aplicacao_variavel,orgao,exercicio';

            parametro_ficha['relations'] = relations_ficha;
            parametro_ficha['orgao_id'] = this.login.orgao.id;
            parametro_ficha['exercicio_id'] = exercicio_id;
            parametro_ficha['acao.codigo'] = acao;
            parametro_ficha['subfuncao.codigo'] = subfuncao;
            parametro_ficha['funcao.codigo'] = funcao;
            parametro_ficha['programa.codigo'] = programa;
            if (variavel && variavel != null) {
              parametro_ficha['aplicacao_variavel.codigo'] = recurso.toString() + aplicacao.toString() + variavel.toString();
            } else {
              parametro_ficha['recurso.codigo'] = recurso;
              parametro_ficha['aplicacao.codigo'] = aplicacao;
            }

            let busca_ficha = await this.fichaService.filtrar(1, -1, parametro_ficha).toPromise();
            for (let i of busca_ficha.content) {
              ficha_origem = i.numero;
            }

          } else if (entidade.empenho.retencao_extra) {
            origem = entidade.empenho.retencao_extra.empenho_extra.numero;
            origem_parcela = entidade.empenho.retencao_extra.empenho_extra?.parcela;
            ficha_origem = entidade.empenho.retencao_extra.empenho_extra?.ficha?.numero;
            tipo_fornecedor = entidade.empenho.retencao_extra.empenho_extra?.convenio?.tipo_fornecedor;
          }

          const registro = [];
          registro.push({ text: this.funcaoService.converteDataBR(entidade.data_pagamento), fontSize: 8, alignment: 'center', bold: false });
          registro.push({ text: entidade.conta.codigo, fontSize: 8, alignment: 'left', bold: false });

          registro.push({ text: entidade.empenho.ficha.numero, fontSize: 8, alignment: 'center', bold: false });
          registro.push({ text: entidade.empenho.numero, fontSize: 8, alignment: 'center', bold: false });
          registro.push({ text: entidade.empenho.parcela, fontSize: 8, alignment: 'center', bold: false });

          registro.push({ text: (entidade.empenho?.ficha?.aplicacao_variavel?.codigo ? entidade.empenho?.ficha?.recurso?.aplicacao_variavel?.codigo : entidade.empenho?.ficha?.recurso?.codigo + '' + entidade.empenho?.ficha?.aplicacao?.codigo + '0000'), fontSize: 8, alignment: 'center', bold: false });
          registro.push({ text: entidade.empenho.especie, fontSize: 8, alignment: 'center', bold: false });
          registro.push({ text: entidade.empenho?.documento ? entidade.empenho?.documento : '-', fontSize: 8, alignment: 'center', bold: false });
          registro.push({ text: entidade.empenho?.favorecido?.nome, fontSize: 8, alignment: 'center', bold: false });
          registro.push({ text: entidade.anulacao == false ? 'N' : 'S', fontSize: 8, alignment: 'center', bold: false });
          registro.push({ text: tipo_fornecedor ? tipo_fornecedor : '-', fontSize: 8, alignment: 'center', bold: false });
          registro.push({ text: origem, fontSize: 8, alignment: 'right', bold: false });
          registro.push({ text: origem_parcela, fontSize: 8, alignment: 'right', bold: false });
          registro.push({ text: ficha_origem, fontSize: 8, alignment: 'right', bold: false });
          registro.push({ text: this.funcaoService.convertToBrNumber(+entidade.empenho.valor_empenho), fontSize: 8, alignment: 'right', bold: false });
          registro.push({ text: this.funcaoService.convertToBrNumber(+entidade.valor_pago), fontSize: 8, alignment: 'right', bold: false });
          registro.push({ text: this.funcaoService.convertToBrNumber(+entidade.valor_retido), fontSize: 8, alignment: 'right', bold: false });
          conteudo.push(registro);

        }

        const totais = this.funcaoService.totalizar(grupo.registros, ['empenho.valor_empenho', 'valor_pago', 'valor_retido']);
        const total = [];
        total.push({ text: 'TOTAL', fontSize: 8, alignment: 'left', bold: true, colSpan: 14 });
        total.push('');
        total.push('');
        total.push('');
        total.push('');
        total.push('');
        total.push('');
        total.push('');
        total.push('');
        total.push('');
        total.push('');
        total.push('');
        total.push('');
        total.push('');
        total.push({ text: this.funcaoService.convertToBrNumber(+totais['empenho.valor_empenho']), fontSize: 8, alignment: 'right', bold: true });
        total.push({ text: this.funcaoService.convertToBrNumber(+totais['valor_pago']), fontSize: 8, alignment: 'right', bold: true });
        total.push({ text: this.funcaoService.convertToBrNumber(+totais['valor_retido']), fontSize: 8, alignment: 'right', bold: true });
        conteudo.push(total);
      }

      if (this.relatoriosSelect === 'EMPENHO_CONVENIO_ORCAMENTARIO' || this.relatoriosSelect === 'EMPENHO_CONVENIO_RESTO') {
        retorno.push({
          layout: 'linhas',
          table: {
            dontBreakRows: true,
            headerRows: 1,
            widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: conteudo
          }, margin: [0, 0, 0, 15]
        });

      } else {
        retorno.push({
          layout: 'linhas',
          table: {
            dontBreakRows: true,
            headerRows: 1,
            widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: conteudo
          }, margin: [0, 0, 0, 15]
        });
      }

    }

    return retorno;
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/tesouraria']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public trocarRelatorio() {
    setTimeout(() => {
      this.globalService.calendarMascara();
      if (this.relatoriosSelect === 'EMPENHO_CONVENIO_ORCAMENTARIO') {
        this.opcao = 'opt1'
      }
    }, 100);
  }
}