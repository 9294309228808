import { BaseResourceModel } from '../../models/base-resource.model';
import { DeslocamentoItem } from './deslocamento-item.model';
import { Motorista } from './motorista.model';
import { Veiculo } from './veiculo.model';

export class Deslocamento extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_deslocamento?: Date,
    public veiculo?: Veiculo,
    public motorista?: Motorista,
    public itens?: DeslocamentoItem[],
  ) {
    super();
  }

  static converteJson(json: any): Deslocamento {
    return Object.assign(new Deslocamento(), json);
  }
}
