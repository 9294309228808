import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../../assinaturas/assinaturas';
import { AnexoLrfFederalService } from '../../../service/anexo-lrf-federal.service';

@Directive()
export class Anexo5RgfLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoFederalServico: AnexoLrfFederalService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected assinaturaControleInterno: boolean) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
    this.anexoFederalServico.obterAnexo5Rgf(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        const listaConteudo = await this.conteudo(dados, orgaos);
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(null, this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            listaConteudo,
            'landscape', 'ANEXO5 - DEMONSTRATIVO DISPONIBILIDADE DE CAIXA E DOS RESTOS A PAGAR',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 2;
                },
                paddingRight() {
                  return 2;
                }
              }
            }, true, false);
        } else {
          this.funcaoService.exportar(formato, await this.normalizar(dados, orgaos), 'ANEXO5 - DEMONSTRATIVO DISPONIBILIDADE DE CAIXA E DOS RESTOS A PAGAR', this.colunas());
        }
      });
  }

  private async conteudo(dados: any[], orgaos: any): Promise<{}[]> {
    // monta o cabecalho

    const registros: {}[] = [
      [
        { text: this.login.orgao.nome, bold: true, border: [false, false, false, false], colSpan: 11, fontSize: 10 },
        '', '', '', '', '', '', '', '', '', ''],

      [
        { text: 'RELATÓRIO DE GESTÃO FISCAL', bold: true, border: [false, false, false, false], colSpan: 11 },
        '', '', '', '', '', '', '', '', '', ''],
      [
        { text: 'DEMONSTRATIVO DA DISPONIBILIDADE DE CAIXA E DOS RESTOS A PAGAR', bold: true, border: [false, false, false, false], colSpan: 11 },
        '', '', '', '', '', '', '', '', '', ''],
      [
        { text: 'ORÇAMENTOS FISCAL E DA SEGURIDADE SOCIAL', bold: true, border: [false, false, false, false], colSpan: 11 },
        '', '', '', '', '', '', '', '', '', ''],
      [
        { text: `REFERÊNCIA: ${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano}`, bold: true, border: [false, false, false, false], colSpan: 11 },
        '', '', '', '', '', '', '', '', '', ''],
      [
        { text: 'RGF – ANEXO 5 (LRF, art. 55, Inciso III, alínea "a")', bold: true, border: [false, false, false, false], colSpan: 11 },
        '', '', '', '', '', '', '', '', '', ''],
      [
        { text: 'IDENTIFICAÇÃO DOS RECURSOS', rowSpan: 3, alignment: 'center' },
        { text: 'DISPONIBILIDADE DE CAIXA BRUTA (a)', alignment: 'center', rowSpan: 3 },
        { text: 'OBRIGAÇÕES FINANCEIRAS', colSpan: 4, alignment: 'center' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: 'INSUFICIÊNCIA FINANCEIRA VERIFICADA NO CONSÓRCIO PÚBLICO (f)', alignment: 'center', rowSpan: 3 },
        { text: 'DISPONIBILIDADE DE CAIXA LÍQUIDA (ANTES DA INSCRIÇÃO EM RESTOS A PAGAR NÃO PROCESSADOS DO EXERCÍCIO) (g) = (a – (b + c + d + e) - f)', alignment: 'center', rowSpan: 3 },
        { text: 'RESTOS A PAGAR EMPENHADOS E NÃO LIQUIDADOS DO EXERCÍCIO (h)', rowSpan: 3, alignment: 'center' },
        { text: 'EMPENHOS NÃO LIQUIDADOS CANCELADOS (NÃO INSCRITOS POR INSUFICIÊNCIA FINANCEIRA)', rowSpan: 3, alignment: 'center' },
        { text: 'DISPONIBILIDADE DE CAIXA LÍQUIDA (APÓS A INSCRIÇÃO EM RESTOS A PAGAR NÃO PROCESSADOS DO EXERCÍCIO) (i) = (g - h)', rowSpan: 3, alignment: 'center' },
      ],
      [
        { text: '' },
        { text: '' },
        { text: 'Restos a Pagar Liquidados e Não Pagos', alignment: 'center', colSpan: 2 },
        { text: '' },
        { text: 'Restos a Pagar Empenhados e Não Liquidados de Exercícios Anteriores (d)', alignment: 'center', rowSpan: 2 },
        { text: 'Demais Obrigaçãoes Fianceiras (e)', alignment: 'center', rowSpan: 2 },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' }
      ],
      [
        { text: '' },
        { text: '' },
        { text: 'De Exercícios Anteriores (b)', alignment: 'center' },
        { text: 'Do Exercício (c)', alignment: 'center' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' }
      ]

    ];

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);

    // monta o agrupamento do relatório
    const titulos = this.funcaoService.agrupar(dados, 'titulo', ['caixa', 'resto_anterior', 'resto_exercicio', 'obrigacoes', 'insuficiencia', 'resto_np', 'cancelado']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    let total7 = 0;
    let total8 = 0;
    let total9 = 0;
    let total10 = 0;

    for (const titulo of titulos) {

      let totalg = (+titulo.totalizadores['caixa'] -
        (+titulo.totalizadores['resto_anterior'] + +titulo.totalizadores['resto_exercicio'] + +titulo.totalizadores['resto_liquidado'] + +titulo.totalizadores['obrigacoes']) - +titulo.totalizadores['insuficiencia']);

      registros.push([
        { text: titulo.grupo, fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['caixa']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['resto_anterior']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['resto_exercicio']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['resto_liquidado']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['obrigacoes']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['insuficiencia']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(+totalg), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['resto_np']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['cancelado']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(+totalg - +titulo.totalizadores['resto_np']), alignment: 'right', fontSize: 8, bold: true }
      ]);

      total1 += +titulo.totalizadores['caixa'];
      total2 += +titulo.totalizadores['resto_anterior'];
      total3 += +titulo.totalizadores['resto_exercicio'];
      total4 += +titulo.totalizadores['resto_liquidado'];
      total5 += +titulo.totalizadores['obrigacoes'];
      total6 += +titulo.totalizadores['insuficiencia'];
      total7 += totalg;
      total8 += +titulo.totalizadores['resto_np'];
      total9 += +titulo.totalizadores['cancelado'];
      total10 += +totalg - +titulo.totalizadores['resto_np'];

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['caixa', 'resto_anterior', 'resto_exercicio', 'obrigacoes', 'insuficiencia', 'resto_np', 'cancelado']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          totalg = (+grupo.totalizadores['caixa'] -
            (+grupo.totalizadores['resto_anterior'] + +grupo.totalizadores['resto_exercicio'] + +grupo.totalizadores['resto_liquidado'] + +grupo.totalizadores['obrigacoes']) - +grupo.totalizadores['insuficiencia']);
          registros.push([
            { text: grupo.grupo, fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['caixa']), alignment: 'right', fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['resto_anterior']), alignment: 'right', fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['resto_exercicio']), alignment: 'right', fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['resto_liquidado']), alignment: 'right', fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['obrigacoes']), alignment: 'right', fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['insuficiencia']), alignment: 'right', fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(+totalg), alignment: 'right', fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['resto_np']), alignment: 'right', fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['cancelado']), alignment: 'right', fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(+totalg - +grupo.totalizadores['resto_np']), alignment: 'right', fontSize: 8 },
          ]);

          for (const item of grupo.registros) {
            if (item.nome != '') {
              totalg = (+item.caixa -
                (+item.resto_anterior + +item.resto_exercicio + +item.resto_liquidado + +item.obrigacoes) - +item.insuficiencia);
              registros.push([
                { text: item.nome, fontSize: 8, margin: [5, 0, 0, 0] },
                { text: this.funcaoService.convertToBrNumber(item.caixa), alignment: 'right', fontSize: 8 },
                { text: this.funcaoService.convertToBrNumber(item.resto_anterior), alignment: 'right', fontSize: 8 },
                { text: this.funcaoService.convertToBrNumber(item.resto_exercicio), alignment: 'right', fontSize: 8 },
                { text: this.funcaoService.convertToBrNumber(item.resto_liquidado), alignment: 'right', fontSize: 8 },
                { text: this.funcaoService.convertToBrNumber(item.obrigacoes), alignment: 'right', fontSize: 8 },
                { text: this.funcaoService.convertToBrNumber(item.insuficiencia), alignment: 'right', fontSize: 8 },
                { text: this.funcaoService.convertToBrNumber(totalg), alignment: 'right', fontSize: 8 },
                { text: this.funcaoService.convertToBrNumber(item.resto_np), alignment: 'right', fontSize: 8 },
                { text: this.funcaoService.convertToBrNumber(item.cancelado), alignment: 'right', fontSize: 8 },
                { text: this.funcaoService.convertToBrNumber(totalg - item.resto_np), alignment: 'right', fontSize: 8 },
              ]);
            }
          }

        }
      }
    }

    registros.push([
      { text: 'TOTAL (III) = (I + II)', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total1), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total2), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total3), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total4), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total5), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total6), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total7), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total8), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total9), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total10), alignment: 'right', fontSize: 8, bold: true },
    ]);

    return [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 55, 55, 55, 55, 55, 55, 55, 55, 55, 55],
          body: registros
        }
      }
    ];
  }

  public async normalizar(lista: any[], orgaos: any) {
    const listaExportar = []

    const titulos = this.funcaoService.agrupar(lista, 'titulo', ['caixa', 'resto_anterior', 'resto_exercicio', 'obrigacoes', 'insuficiencia', 'resto_np', 'cancelado']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    let total7 = 0;
    let total8 = 0;
    let total9 = 0;
    let total10 = 0;

    const linha_titulo = {
      titulo: '',
      caixa: '',
      resto_anterior: 'Restos a Pagar Liquidados e Não Pagos',
      resto_exercicio: '',
      resto_liquidado: 'Restos a Pagar Empenhados e Não Liquidados de Exercícios Anteriores (d)',
      obrigacoes: 'Demais Obrigaçãoes Fianceiras (e)',
      insuficiencia: '',
      total1: '',
      resto_np: '',
      cancelado: '',
      total2: ''
    }
    listaExportar.push(linha_titulo)

    const linha_titulo_2 = {
      titulo: '',
      caixa: '',
      resto_anterior: 'De Exercícios Anteriores (b)',
      resto_exercicio: 'Do Exercício (c)',
      resto_liquidado: '',
      obrigacoes: '',
      insuficiencia: '',
      total1: '',
      resto_np: '',
      cancelado: '',
      total2: ''
    }
    listaExportar.push(linha_titulo_2)

    for (const titulo of titulos) {

      let totalg = (+titulo.totalizadores['caixa'] -
        (+titulo.totalizadores['resto_anterior'] + +titulo.totalizadores['resto_exercicio'] + +titulo.totalizadores['resto_liquidado'] + +titulo.totalizadores['obrigacoes']) - +titulo.totalizadores['insuficiencia']);

      const primeira_linha = {
        titulo: titulo.grupo['titulo'],
        caixa: this.funcaoService.convertToBrNumber(+titulo.totalizadores['caixa']),
        resto_anterior: this.funcaoService.convertToBrNumber(+titulo.totalizadores['resto_anterior']),
        resto_exercicio: this.funcaoService.convertToBrNumber(+titulo.totalizadores['resto_exercicio']),
        resto_liquidado: this.funcaoService.convertToBrNumber(+titulo.totalizadores['resto_liquidado']),
        obrigacoes: this.funcaoService.convertToBrNumber(+titulo.totalizadores['obrigacoes']),
        insuficiencia: this.funcaoService.convertToBrNumber(+titulo.totalizadores['insuficiencia']),
        total1: this.funcaoService.convertToBrNumber(+totalg),
        resto_np: this.funcaoService.convertToBrNumber(+titulo.totalizadores['resto_np']),
        cancelado: this.funcaoService.convertToBrNumber(+titulo.totalizadores['cancelado']),
        total2: this.funcaoService.convertToBrNumber(+totalg - +titulo.totalizadores['resto_np'])
      }
      listaExportar.push(primeira_linha)

      total1 += +titulo.totalizadores['caixa'];
      total2 += +titulo.totalizadores['resto_anterior'];
      total3 += +titulo.totalizadores['resto_exercicio'];
      total4 += +titulo.totalizadores['resto_liquidado'];
      total5 += +titulo.totalizadores['obrigacoes'];
      total6 += +titulo.totalizadores['insuficiencia'];
      total7 += totalg;
      total8 += +titulo.totalizadores['resto_np'];
      total9 += +titulo.totalizadores['cancelado'];
      total10 += +totalg - +titulo.totalizadores['resto_np'];

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['caixa', 'resto_anterior', 'resto_exercicio', 'obrigacoes', 'insuficiencia', 'resto_np', 'cancelado']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          totalg = (+grupo.totalizadores['caixa'] -
            (+grupo.totalizadores['resto_anterior'] + +grupo.totalizadores['resto_exercicio'] + +grupo.totalizadores['resto_liquidado'] + +grupo.totalizadores['obrigacoes']) - +grupo.totalizadores['insuficiencia']);
          const linha_grupo = {
            titulo: grupo.grupo,
            caixa: this.funcaoService.convertToBrNumber(+grupo.totalizadores['caixa']),
            resto_anterior: this.funcaoService.convertToBrNumber(+grupo.totalizadores['resto_anterior']),
            resto_exercicio: this.funcaoService.convertToBrNumber(+grupo.totalizadores['resto_exercicio']),
            resto_liquidado: this.funcaoService.convertToBrNumber(+grupo.totalizadores['resto_liquidado']),
            obrigacoes: this.funcaoService.convertToBrNumber(+grupo.totalizadores['obrigacoes']),
            insuficiencia: this.funcaoService.convertToBrNumber(+grupo.totalizadores['insuficiencia']),
            total1: this.funcaoService.convertToBrNumber(+totalg),
            resto_np: this.funcaoService.convertToBrNumber(+grupo.totalizadores['resto_np']),
            cancelado: this.funcaoService.convertToBrNumber(+grupo.totalizadores['cancelado']),
            total2: this.funcaoService.convertToBrNumber(+totalg - +grupo.totalizadores['resto_np'])
          }
          listaExportar.push(linha_grupo)
        }

        for (const item of grupo.registros) {
          if (item.nome != '') {
            totalg = (+item.caixa -
              (+item.resto_anterior + +item.resto_exercicio + +item.resto_liquidado + +item.obrigacoes) - +item.insuficiencia);
            const linha_item = {
              titulo: item.nome,
              caixa: this.funcaoService.convertToBrNumber(+item.caixa),
              resto_anterior: this.funcaoService.convertToBrNumber(+item.resto_anterior),
              resto_exercicio: this.funcaoService.convertToBrNumber(+item.resto_exercicio),
              resto_liquidado: this.funcaoService.convertToBrNumber(+item.resto_liquidado),
              obrigacoes: this.funcaoService.convertToBrNumber(+item.obrigacoes),
              insuficiencia: this.funcaoService.convertToBrNumber(+item.insuficiencia),
              total1: this.funcaoService.convertToBrNumber(+totalg),
              resto_np: this.funcaoService.convertToBrNumber(+item.resto_np),
              cancelado: this.funcaoService.convertToBrNumber(+item.cancelado),
              total2: this.funcaoService.convertToBrNumber(+totalg - +item.resto_np)
            }
            listaExportar.push(linha_item)
          }
        }
      }
    }

    const linha_item = {
      titulo: 'TOTAL (III) = (I + II)',
      caixa: this.funcaoService.convertToBrNumber(+total1),
      resto_anterior: this.funcaoService.convertToBrNumber(+total2),
      resto_exercicio: this.funcaoService.convertToBrNumber(+total3),
      resto_liquidado: this.funcaoService.convertToBrNumber(+total4),
      obrigacoes: this.funcaoService.convertToBrNumber(+total5),
      insuficiencia: this.funcaoService.convertToBrNumber(+total6),
      total1: this.funcaoService.convertToBrNumber(+total7),
      resto_np: this.funcaoService.convertToBrNumber(+total8),
      cancelado: this.funcaoService.convertToBrNumber(+total9),
      total2: this.funcaoService.convertToBrNumber(+total10)
    }
    listaExportar.push(linha_item)

    return listaExportar
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'IDENTIFICAÇÃO DOS RECURSOS', coluna: 'titulo' },
      { titulo: 'DISPONIBILIDADE DE CAIXA BRUTA (a)', coluna: 'caixa' },
      { titulo: 'OBRIGAÇÕES FINANCEIRAS', coluna: 'resto_anterior' },
      { titulo: '', coluna: 'resto_exercicio' },
      { titulo: '', coluna: 'resto_liquidado' },
      { titulo: '', coluna: 'obrigacoes' },
      { titulo: 'INSUFICIÊNCIA FINANCEIRA VERIFICADA NO CONSÓRCIO PÚBLICO (f)', coluna: 'insuficiencia' },
      { titulo: 'DISPONIBILIDADE DE CAIXA LÍQUIDA (ANTES DA INSCRIÇÃO EM RESTOS A PAGAR NÃO PROCESSADOS DO EXERCÍCIO) (g) = (a – (b + c + d + e) - f)', coluna: 'total1' },
      { titulo: 'RESTOS A PAGAR EMPENHADOS E NÃO LIQUIDADOS DO EXERCÍCIO (h)', coluna: 'resto_np' },
      { titulo: 'EMPENHOS NÃO LIQUIDADOS CANCELADOS (NÃO INSCRITOS POR INSUFICIÊNCIA FINANCEIRA)', coluna: 'cancelado' },
      { titulo: 'DISPONIBILIDADE DE CAIXA LÍQUIDA (APÓS A INSCRIÇÃO EM RESTOS A PAGAR NÃO PROCESSADOS DO EXERCÍCIO) (i) = (g - h)', coluna: 'total2' },
    ];
    return colunasDefault;
  }

}
