import { Sistemas } from '../../components/types';
import { Acesso } from '../comum/acesso.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { Cidade } from '../geo/cidade.model';
import { SetorAlmoxarifado } from '../almoxarifado/setor-almoxarifado.model';
import { Estoque } from '../almoxarifado/estoque.model';
import { Setor } from '../almoxarifado/setor.model';

// tslint:disable: variable-name

export class Login {
  constructor(
    public sistema?: Sistemas,
    public exercicio?: Exercicio,
    public usuario?: Usuario,
    public orgao?: Orgao,
    public cidade?: Cidade,
    public limite?: number,
    public token?: string,
    public brasao?: string,
    public bandeira?: string,
    public versao?: string,
    public acessos?: Acesso[],
    public parametro?: any,
    public setorAlmoxarifado?: SetorAlmoxarifado,
    public setor?: Setor[],
    public estoque?: Estoque,
    public ultimoAudesp?: string,
    public token_pncp?: string,
    public validade_pncp?: string,
    public dados_pncp?: any,
    public dados_audesp?: any,
    public token_audesp?: string,
    public logoutTime?: number
  ) { }
}
