import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EmpenhoService } from 'administrativo-lib';
import { GlobalService } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { EmpenhoExtraService } from '../../empenho-extra/service/empenho-extra.service';
import { EmpenhoRestoService } from '../../empenho-resto/service/empenho-resto.service';

@Component({
  selector: 'app-consulta-empenho-dlg',
  templateUrl: './consulta-empenho-dlg.component.html'
})
export class ConsultaEmpenhoDlgComponent {

  @ViewChild('fechar') closeModal: ElementRef

  public compra: number;
  public empenho: number;
  public tipoEmpenho: { id: string, nome: string};
  public  listaEspecies = [
    { id: 'EMO', nome: 'ORÇAMENTÁRIO' },
    { id: 'EME', nome: 'EXTRA-ORÇAMENTÁRIO' },
    { id: 'EMR', nome: 'RESTOS A PAGAR' }
  ];
  
  public login = GlobalService.obterSessaoLogin();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private empenhoService: EmpenhoService,
    private empenhoExtraService: EmpenhoExtraService,
    private empenhoRestoService: EmpenhoRestoService,
    private route: Router
  ) { }

  public consultar(): void {
    if (!this.tipoEmpenho?.id) {
      toastr.warning('Selecione o tipo de empenho!');
      return
    }

    const parametros = {
      'orgao.id': this.login.orgao.id,
    };

    if (this.empenho) parametros['numero'] = this.empenho;

    if (this.tipoEmpenho.id === 'EMO') {
      parametros['exercicio.id'] = this.login.exercicio.id;
      parametros['relations'] = ['exercicio', 'orgao'].join(',');
      if (this.compra) parametros['compra.numero'] = this.compra;
      toastr.options.positionClass = 'toast-top-left';
      this.empenhoService.obter(parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          if (res) {
            this.closeModal.nativeElement.click();
            this.route.navigateByUrl(`/consulta-empenhos/${res.id}/pesquisar/${this.login.orgao.id}`);
          } else {
            toastr.warning('Empenho orçamentário não localizado!');
          }
        });
    }

    if (this.tipoEmpenho.id === 'EME') {
      parametros['exercicio.id'] = this.login.exercicio.id;
      parametros['especie'] = 'EME';
      if (this.compra) parametros['OR'] = `retencao.liquidacao.empenho.compra.numero=${this.compra};!;!;retencao_resto.liquidacao.empenho.compra.numero=${this.compra}`;
      toastr.options.positionClass = 'toast-top-left';
      this.empenhoExtraService.obter(parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          if (res) {
            this.closeModal.nativeElement.click();
            this.route.navigateByUrl(`/empenhos-extra/${res.id}/visualizar`)
          } else {
            toastr.warning('Empenho Extra orçamentário não localizado!');
          }
        });
    }

    if (this.tipoEmpenho.id === 'EMR') {
      parametros['especie'] = 'EMR';
      if (this.compra) parametros['compra.numero'] = this.compra;
      toastr.options.positionClass = 'toast-top-left';
      this.empenhoRestoService.obter(parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          if (res) {
            this.closeModal.nativeElement.click();
            this.route.navigateByUrl(`/empenhos-resto/${res.id}/visualizar`)
          } else {
            toastr.warning('Empenho Restos a Pagar não localizado!');
          }
        });
    }
  }
}
