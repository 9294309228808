import { BaseResourceModel } from '../../models/base-resource.model';
import { Cidade } from '../geo/cidade.model';
import { RecursoSiconfiComplemento } from './recurso-siconfi-complemento.model';
import { RecursoSiconfi } from './recurso-siconfi.model';

// tslint:disable: variable-name

export class Recurso extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public federal?: string,
    public nome?: string,
    public variavel?: string,
    public descricao?: string,
    public nivel?: number,
    public domicilio?: boolean,
    public ativo?: boolean,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public cidade?: Cidade,
    public recurso?: Recurso,
    public aplicacao?: Recurso,
    public aplicacao_variavel_receita?: Recurso,
    public recurso_siconfi?: RecursoSiconfi,
    public recurso_siconfi_complemento?: RecursoSiconfiComplemento,
    public aceita_variavel?: boolean,
    public exige_convenio?: boolean
  ) {
    super();
  }

  static converteJson(json: any): Recurso {
    return Object.assign(new Recurso(), json);
  }
}
