import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from "../comum/orgao.model";
import { Exercicio } from "../comum/exercicio.model";
import { FichaDespesa } from "../planejamento/ficha-despesa.model";

export class Pasep extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public expressao?: string,
    public compoeGuia?: boolean,
    public ficha?: FichaDespesa,
    public orgao?: Orgao,
    public exercicio?: Exercicio) {
    super();
  }
  static converteJson(json: any): Pasep {
    return Object.assign(new Pasep(), json);
  }
}