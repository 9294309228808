import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { InventarioListComponent } from './inventario-list/inventario-list.component';
import { InventarioTermoComponent } from './inventario-termo/inventario-termo.component';

const routes: Routes = [
  { path: '', component: InventarioListComponent, canActivate: [AuthGuard] },
  { path: 'termo/:inventario', component: InventarioTermoComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InventarioRoutingModule { }
