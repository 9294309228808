import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Audesp4LicitanteCNPJ, Audesp4LicitanteLote, FuncaoService } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-licitante-item',
  templateUrl: './licitacao-audesp-licitante-item.component.html'
})
export class LicitacaoAudespLicitanteItemComponent extends BaseLicitacaoAudesp implements OnInit, OnChanges {

  @Input() public licitante: Audesp4LicitanteCNPJ;
  @Input() public lotes: { id: number, nome: string }[];

  public itemAtual: Audesp4LicitanteLote;
  public itemAnterior: Audesp4LicitanteLote;

  public opcoesHabilitacao: { id: number, nome: string }[];

  constructor(public funcaoService: FuncaoService) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['licitante'] && this.licitante) {
      this.licitante.LicitanteLoteItens = this.licitante.LicitanteLoteItens.sort((a, b) => { return a.LicitanteNumLote - b.LicitanteNumLote })
      this.itemAtual = this.licitante.LicitanteLoteItens.find(item => item.editavel)
      if (this.itemAtual) this.cancelar()
    }
  }

  ngOnInit(): void {
    this.opcoesHabilitacao = [
      { id: 6, nome: 'Classificado' },
      { id: 2, nome: 'Classificado - Vencedor' },
      { id: 1, nome: 'Desclassificado' },
      { id: 5, nome: 'Desistiu' },
      { id: 7, nome: 'Habilitado' },
      { id: 3, nome: 'Inabilitado' },
      { id: 4, nome: 'Não Compareceu' },
      { id: 8, nome: 'Proposta não analisada' },
    ];
  }

  public adicionar() {
    if (!this.licitante.LicitanteLoteItens || this.licitante.LicitanteLoteItens.length === 0) this.licitante.LicitanteLoteItens = [];

    this.itemAtual = {
      LicitanteNumLote: undefined, ResultadoHabilitacao: undefined, MotivoDesab_Desclass: undefined,
      ValorProposta: 0, ValorPropostaPercentual: 0, CompativelMercadoValor: undefined,
      CompativelMercadoFonte: undefined, editavel: true
    }
    this.licitante.LicitanteLoteItens.push(this.itemAtual);
  }

  public visualizar(item: Audesp4LicitanteLote) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      let itm = this.licitante.LicitanteLoteItens.find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.licitante.LicitanteLoteItens.splice(this.licitante.LicitanteLoteItens.indexOf(this.itemAtual), 1, itm)
      this.itemAtual.editavel = false;
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4LicitanteLote) {
    this.itemAtual = undefined;
    this.licitante.LicitanteLoteItens.splice(this.licitante.LicitanteLoteItens.indexOf(item), 1)
  }

  public salvar() {
    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }

}
