import { BaseResourceModel } from '../../models/base-resource.model';
import { ContratoAditamento } from './contrato-aditamento.model';
import { ContratoItem } from './contrato-item.model';

export class ContratoAditamentoItem extends BaseResourceModel {
  constructor(
    public aditivo_valor?: number,
    public aditivo_qtd?: number,
    public contrato_item?: ContratoItem,
    public aditamento?: ContratoAditamento,
  ) {
    super();
  }
  static converteJson(json: any): ContratoAditamentoItem {
    return Object.assign(new ContratoAditamentoItem(), json);
  }
}
