import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { EddyAutoComplete, EmailService, InventarioSetor, InventarioTombo, InventarioTransferencia, Login, ParametroPatrimonio, ParametroPatrimonioService } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { InventarioSetorService } from '../service/inventario-setor.service';
import { InventarioTransferenciaService } from '../service/inventario-transferencia.service';

declare var $: any;

@Component({
  selector: 'app-inventario-transferencia-dlg',
  templateUrl: './inventario-transferencia-dlg.component.html'
})
export class InventarioTransferenciaDlgComponent implements OnChanges {

  /**
   * Declaração de variáveis
   */

  @Input() public item: InventarioTombo;
  @Input() public origem: InventarioSetor;
  @Input() public login: Login;
  @Output() callback: EventEmitter<boolean> = new EventEmitter();

  public setorAutoComplete: EddyAutoComplete<InventarioSetor>;
  public entidade: InventarioTransferencia;
  public tipoEmail: 'T' | 'S' | 'SA' | 'SR' | 'I' = 'T';

  protected unsubscribe: Subject<void> = new Subject();

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private setorService: InventarioSetorService,
    protected parametroPatrimonioService: ParametroPatrimonioService,
    private emailService: EmailService,
    protected confirmationPriService: ConfirmationService,
    private transferenciaService: InventarioTransferenciaService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['item'] && this.item) {
      this.entidade = new InventarioTransferencia();

      this.entidade.origem = this.origem;
      this.entidade.tombamento = this.item.tombamento;
      this.entidade.usuario = this.login.usuario;
      this.entidade.status = 'PENDENTE';
    }
    if (this.origem?.inventario) {
      this.setorAutoComplete = new EddyAutoComplete(null, this.setorService,
        'id', ['setor.codigo', 'setor.nome'], {
        inventario_id: this.origem.inventario.id, orderBy: 'setor.nome'
      }, { number: ['setor.codigo'], text: ['setor.nome'] });
    }
  }

  public async confirmar() {
    if (this.entidade.tombamento && (this.entidade.tombamento.situacao === 'EXCLUIDO' || this.entidade.tombamento.situacao === 'BAIXADO')) {
      toastr.warning('Bem Patrimonial excluído ou baixado. Selecione outro bem patrimonial.');
      return;
    }
    if (this.entidade.origem?.setor?.id === this.entidade.destino?.setor?.id) {
      toastr.warning('Bem patrimonial já pertence a esse setor. Selecione outro bem patrimonial.');
      return;
    }
    if (!this.entidade.destino) {
      toastr.warning('Selecione o Setor');
      return;
    }
    if (!this.entidade.motivo) {
      toastr.warning('Informe a Justificativa');
      return;
    }

    await new Promise((resolve, reject) => {
      this.entidade['tipo'] = 'T';
      this.transferenciaService.inserir(this.entidade).subscribe(async res => {
        delete this.entidade['tipo'];
        this.email(this.entidade);
        if (res.destino.setor.transferencia_automatica) {
          await new Promise((resolve, reject) => {
            this.transferenciaService.confirmar(res.id, this.login.usuario.id, 'R').subscribe(res => {
              resolve(true);
            }, error => {
              toastr.error(error.error.payload);
              reject(error);
            });
          });
        }
        resolve(true)
      }, error => {
        toastr.error(error.error.payload)
        reject(error)
      });
    });

    this.callback.emit(true);
    $('#dialogInventarioTransferencia').modal('hide');
  }

  public email(entidade: InventarioTransferencia) {
    this.parametroPatrimonioService.obter({
      'orgao.id': this.login.orgao.id
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(parametroPatrimonio => {
        let mensagem = '';
        if (this.tipoEmail.includes('T'))
          mensagem += `<br />Setor - ${entidade.destino.setor.nome}(${entidade.destino.setor.email})`;

        if (mensagem.length === 0)
          return;

        this.enviarEmail(entidade, parametroPatrimonio);
      });
  }

  public async enviarEmail(transferencia: InventarioTransferencia, parametro?: ParametroPatrimonio) {
    let subject = `Transferência de Bem Móvel - Nº: ${transferencia.tombamento.placa}`;
    let mensagem = `<p>${parametro.mensagem_transferencia}</p>`;
    let destinatarios: { nome: string; email: string; }[] = [];

    if (this.tipoEmail.includes('T')) {
      destinatarios.push({ nome: transferencia.destino.setor.nome, email: transferencia.destino.setor.email });
    }

    if (destinatarios.length) {
      this.emailService.enviar({ titulo: subject, corpo: mensagem, destinos: destinatarios })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(data => toastr.success('E-mail encaminhado com sucesso!'),
        error => toastr.error(`Não foi possivel encaminhar o e-mail de confirmação!`));
    }
  }
}
