import { Injectable, Injector } from "@angular/core";
import { ComunicadoUsuario } from "../../entidade/compra/comunicado-usuario.model";
import { BaseResourceService } from "../../models/services/base-resource.service";

@Injectable({
  providedIn: 'root'
})
export class ComunicadoUsuarioService extends BaseResourceService<ComunicadoUsuario> {

  constructor(
    protected injector: Injector
  ) {
    super(`comunicado-usuarios`, injector);
  }

}