import { Component, Input } from '@angular/core';
import { BaseResourceItemsComponent, GlobalService, TipoConvenioCondicaoDespesa } from 'eddydata-lib';
import { TipoConvenioCondicaoDespesaService } from '../service/tipo-convenio-condicao-despesa.service';

@Component({
  selector: 'app-tipo-convenio-condicao-despesa',
  templateUrl: './tipo-convenio-condicao-despesa.component.html',
  styleUrls: ['./tipo-convenio-condicao-despesa.component.css']
})
export class TipoConvenioCondicaoDespesaComponent extends BaseResourceItemsComponent<TipoConvenioCondicaoDespesa> {

  @Input() visualizar: boolean;

  constructor(public globalService: GlobalService,
    public tipoConvenioCondicaoDespesaService: TipoConvenioCondicaoDespesaService) {
    super(new TipoConvenioCondicaoDespesa(), tipoConvenioCondicaoDespesaService);
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
  }

  protected validSave(item: TipoConvenioCondicaoDespesa): boolean {
    if (!item.condicao) {
      toastr.warning('Informe a condição!');
      return false;
    }
    return true;
  }

  protected beforeSave(item: TipoConvenioCondicaoDespesa): void { }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================
  private carregarAutoCompletes() {

  }

  public podeVisualizar() {
    if (this.visualizar === true) {
      return true;
    }
    if (this.visualizar === false) {
      return false;
    }
  }

}
