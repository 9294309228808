import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Quadro2Saude } from './relatorio/quadro2.saude';
import { Quadro3Saude } from './relatorio/quadro3.saude';
import { QuadroSaudeService } from './service/quadro-saude.service';
import { Quadro1Saude } from './relatorio/quadro1.saude';
import { Quadro5Saude } from './relatorio/quadro5.saude';
import * as toastr from 'toastr';
import {
  LoginContabil, Exercicio, ExercicioService,
  OrgaoService, FuncaoService, GlobalService, ProgressoService, FormatoExportacao, Coluna,
  OrgaoAssinaturaService
} from 'eddydata-lib';
import { Anexo12Saude } from './relatorio/anexo12/anexo12.saude';
import { ProjecaoReceitaDespesa } from './relatorio/projecao-receita-despesa.saude';

@Component({
  selector: 'app-quadro-saude',
  templateUrl: './quadro-saude.component.html'
})
export class QuadroSaudeComponent implements OnInit, OnDestroy {

  public login: LoginContabil = new LoginContabil();
  public mes: number;
  listaOrgaos: SelectItem[];
  orgaos: number[] = [];
  public listaExercicios: Array<Exercicio>;
  public exercicioSelect: Exercicio;
  public listaAnexos: Array<any>;
  public anexoSelect: string;
  public percentualFerias: number = 0;
  public percentual13: number = 80;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    protected assinaturaService: OrgaoAssinaturaService,
    protected exercicioService: ExercicioService,
    protected orgaoService: OrgaoService,
    protected funcaoService: FuncaoService,
    protected quadroServico: QuadroSaudeService,
    private messageService: MessageService,
    protected progressoService: ProgressoService) {
    this.login = GlobalService.obterSessaoLogin();
    this.mes = 1;
    this.exercicioSelect = this.login.exercicio;
    this.orgaos.push(this.login.orgao.id);
  }

  ngOnInit() {
    this.orgaoService.obterTodos()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaOrgaos = [];
        for (const model of dados) {
          this.listaOrgaos.push({ label: `${model.codigo} - ${model.nome}`, value: model.id });
        }
      });
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaExercicios = dados.content;
      });
    this.listaAnexos = [
      { id: 'Q1', nome: 'QUADRO 1 - IMPOSTOS' },
      { id: 'Q2', nome: 'QUADRO 2 - RECEITAS VINCULADAS' },
      { id: 'Q3', nome: 'QUADRO 3 - DESPESAS ORÇAMENTÁRIAS' },
      { id: 'Q5', nome: 'QUADRO 5 - RESUMO DA APLICAÇÃO EM SAÚDE - RECURSOS PRÓPRIOS' },
      { id: 'A12', nome: 'ANEXO 12 - RECEITAS E DESPESAS COM AÇÕES E SERVIÇOS COM SAÚDE' }
    ];

    if (this.funcaoService.podeIncluir(this.login, '/relatorios-edital')) {
      this.listaAnexos.push({ id: 'PRD', nome: 'PROJEÇÃO DA RECEITA E DESPESA' });
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  imprimir(formato: FormatoExportacao) {
    if (this.anexoSelect === 'Q1') {
      const q1 = new Quadro1Saude(this.quadroServico, this.mes, this.exercicioSelect, this.assinaturaService);
      q1.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'Q2') {
      const q2 = new Quadro2Saude(this.quadroServico, this.mes, this.exercicioSelect, this.assinaturaService);
      q2.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'Q3') {
      try {
        toastr.warning('Por favor, aguarde! Este relatório realiza uma grande quantidade de cálculos para validação!');
      } catch (err) {
        this.messageService.add({ severity: 'error', summary: 'Validação', detail: err });
        throw err;
      }
      const q3 = new Quadro3Saude(this.quadroServico, this.mes, this.exercicioSelect, this.progressoService, this.assinaturaService);
      q3.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'Q5') {
      const q5 = new Quadro5Saude(this.quadroServico, this.mes, this.exercicioSelect, this.assinaturaService);
      q5.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'A12') {
      const a12 = new Anexo12Saude(this.quadroServico, this.mes, this.exercicioSelect, this.assinaturaService);
      a12.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'PRD') {
      this.exercicioService.obterPorAno(this.exercicioSelect.ano - 1, this.login.cidade.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          const prd = new ProjecaoReceitaDespesa(this.quadroServico, this.mes, this.exercicioSelect, res.content[0], this.percentualFerias, this.percentual13, this.assinaturaService);
          prd.montarRelatorio(this.orgaos, formato);
        });
    } else {
      toastr.warning('Selecione o(s) órgão(s) a serem impressos');
    }
  }

  /**
   * Método para verificação de objetos, usados em combos `<select>`
   */
  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }
}
