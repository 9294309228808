import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from "../comum/exercicio.model";

export class DespesaSiope extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public nome?: string,
    public mostrar_orcada?: boolean,
    public exercicio?: Exercicio) {
    super();
  }
  static converteJson(json: any): DespesaSiope {
    return Object.assign(new DespesaSiope(), json);
  }
}