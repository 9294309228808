import { Usuario } from 'eddydata-lib';
import { BaseResourceModel } from '../../models/base-resource.model';
import { CompraItem } from '../compra/compra-item.model';
import { Pessoa } from '../comum/pessoa.model';
import { Abastecimento } from './abastecimento.model';
import { Combustivel } from './combustivel.model';
import { Motorista } from './motorista.model';
import { Veiculo } from './veiculo.model';

export class AutorizacaoAbastecimento extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_autorizacao?: Date,
    public quantidade?: number,
    public observacao?: string,
    public combustivel?: Combustivel,
    public veiculo?: Veiculo,
    public motorista?: Motorista,
    public responsavel?: Pessoa,
    public compra_item?: CompraItem,
    public abastecimento?: Abastecimento,
    public km_anterior?: number,
    public km_atual?: number,
    public usuario_exclusao?: Usuario,
    public motivo_exclusao?: String,
    public data_exclusao?: Date,
    public excluido?: boolean,
  ) {
    super();
  }

  static converteJson(json: any): AutorizacaoAbastecimento {
    return Object.assign(new AutorizacaoAbastecimento(), json);
  }
}
