import { Cidade, Recurso } from 'eddydata-lib';
import { BaseResourceModel } from '../../models/base-resource.model';

export class RecursoDisponibilidade extends BaseResourceModel {
  constructor(
    public id?: number,
    public recurso_receita?: Recurso,
    public aplicacao_receita?: Recurso,
    public aplicacao_variavel_receita?: Recurso,
    public recurso_despesa?: Recurso,
    public aplicacao_despesa?: Recurso,
    public aplicacao_variavel_despesa?: Recurso,
    public cidade?: Cidade,
    public ativo?: boolean
  ) {
    super();
  }

  static converteJson(json: any): RecursoDisponibilidade {
    return Object.assign(new RecursoDisponibilidade(), json);
  }
}
