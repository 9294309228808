import { BaseResourceModel } from '../../models/base-resource.model';
import { Contrato } from './contrato.model';

export class ContratoPncp extends BaseResourceModel {
  constructor(
    public id?: number,
    public contrato?: Contrato,
    public categoria_processo?: number,
    public tipo?: boolean,
    public tipo_sub_fornecedor?: string,
    public documento_sub_fornecedor?: string,
    public nome_sub_fornecedor?: string,
    public parcelas: number = 1,
    public valor_parcela?: number,
    public valor_global?: number,
    public valor_acumulado?: number,
    public identificador_cipi?: string,
    public url_cipi?: string,
    public ano?: number,
    public ignorar_pncp?: boolean,
  ) {
    super();
  }
  static converteJson(json: any): ContratoPncp {
    return Object.assign(new ContratoPncp(), json);
  }
}
