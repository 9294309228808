import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { RecursoSiconfiComplemento } from './recurso-siconfi-complemento.model';
import { RecursoSiconfi } from './recurso-siconfi.model';
import { Recurso } from './recurso.model';

// tslint:disable: variable-name

export class RecursoAliquota extends BaseResourceModel {
  constructor(
    public id?: number,
    public mes?: number,
    public dia?: number,
    public orgao?: Orgao,
    public aliquota_educacao?: number,
    public recurso_educacao?: Recurso,
    public aliquota_saude?: number,
    public recurso_saude?: Recurso,
    public aliquota_assistencia?: number,
    public recurso_assistencia?: Recurso,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public recurso_siconfi_educacao?: RecursoSiconfi,
    public complemento_siconfi_educacao?: RecursoSiconfiComplemento,
    public recurso_siconfi_saude?: RecursoSiconfi,
    public complemento_siconfi_saude?: RecursoSiconfiComplemento,
    public recurso_siconfi_assistencia?: RecursoSiconfi,
    public complemento_siconfi_assistencia?: RecursoSiconfiComplemento,
    public exercicio?: Exercicio
  ) {
    super();
  }

  static converteJson(json: any): Recurso {
    return Object.assign(new Recurso(), json);
  }
}
