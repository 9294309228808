// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { MetaGoverno } from './meta-governo.model';

export class MetaAvaliacao extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_avaliacao?: Date,
    public situacao?: string,
    public observacao?: string,
    public meta?: MetaGoverno,
    public editavel?: boolean
  ) {
    super();
  }

  static converteJson(json: any): MetaAvaliacao {
    return Object.assign(new MetaAvaliacao(), json);
  }
}
