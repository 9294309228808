import { Directive, Injector, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { QuadroEnsinoService } from '../service/quadro-ensino.service';
import {
  FuncaoService, GlobalService, Login,
  Exercicio, FuncaoGoverno, Relatorio, FormatoExportacao, Coluna
} from 'eddydata-lib';
import { Assinaturas } from 'administrativo-lib';

@Directive()
export class Quadro3Ensino implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected quadroServico: QuadroEnsinoService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected dataInicial: Date,
    protected dataFinal: Date,
    protected funcao?: FuncaoGoverno,
    protected injector?: Injector,
    protected assinatura_ensino?: boolean) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async listar(orgaos: number[]) {
    return await this.quadroServico
      .obterQuadro3(this.mes, this.exercicio.id, orgaos, this.dataInicial, this.dataFinal).toPromise();
  }

  public montarRelatorio(orgaos: number[], formato?: FormatoExportacao) {
    if (!formato) formato = 'pdf';

    this.quadroServico.obterQuadro3(this.mes, this.exercicio.id, orgaos, this.dataInicial, this.dataFinal)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(
            'QUADRO 3 - DESPESAS ORÇAMENTÁRIAS - ENSINO'
            , this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.conteudo(dados),
            'landscape', 'QUADRO 3 - DESPESAS ORÇAMENTÁRIAS - ENSINO',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            });
        } else {
          this.funcaoService.exportar(formato, this.conteudoExportar(dados), 'QUADRO 3 - DESPESAS ORÇAMENTÁRIAS - ENSINO', this.colunasRelatorio())
        }


      });
  }

  private async conteudo(dados: any[]): Promise<{}[]> {
    let referencia = '';
    if (this.mes) {
      referencia = `${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase()}/${this.exercicio.ano}`;
    } else if (this.dataInicial && this.dataFinal) {
      let dt = this.dataInicial.toString().split("-");
      referencia = `${dt[2]}/${dt[1]}/${dt[0]}`;
      dt = this.dataFinal.toString().split("-");
      referencia += ` à ${dt[2]}/${dt[1]}/${dt[0]}`;
    } else {
      referencia += this.exercicio.ano;
    }

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `REFERÊNCIA: ${referencia}`,
        alignment: 'center',
        fontSize: 11,
        margin: [5, 5, 5, 5],
        bold: true,
        colSpan: 10
      }, '', '', '', '', '', '', '', '', ''],
      [{
        text: 'FUNÇÃO EDUCAÇÃO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'SUBFUNÇÕES',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'FONTE DE RECURSOS',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'CÓDIGO DE APLICAÇÃO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'CLASSIFIC. ECONÔMICA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'DOTAÇÃO INICIAL',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'DOTAÇÃO ATUALIZADA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'DESPESA EMPENHADA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'DESPESA LIQUIDADA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'DESPESA PAGA',
        alignment: 'center',
        bold: true, fontSize: 6
      }]
    ];

    // monta o agrupamento do relatório
    let i = 0;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    for (const dado of dados) {
      // FUNÇÕES DE GOVERNO --------------------------------------------------------------------------------
      const grupos = this.funcaoService.agrupar(dado, 'funcao',
        ['orcada', 'orcada_atualizada', 'empenhada', 'liquidada', 'paga']);
      i++;
      registros.push([
        {
          text: i === 1 ? 'TÍPICAS' : 'ATÍPICAS',
          alignment: 'center',
          bold: true,
          colSpan: 10
        }, '', '', '', '', '', '', '', '', '']);

      for (const grupo of grupos) {
        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 7,
          },
          {
            text: ''
          },
          {
            text: ''
          },
          {
            text: ''
          },
          {
            text: ''
          },
          {
            text: this.funcaoService.convertToBrNumber(grupo.totalizadores['orcada']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(grupo.totalizadores['orcada_atualizada']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhada']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidada']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(grupo.totalizadores['paga']),
            alignment: 'right', bold: true, fontSize: 7
          }
        ]);
        total1 += +grupo.totalizadores['orcada'];
        total2 += +grupo.totalizadores['orcada_atualizada'];
        total3 += +grupo.totalizadores['empenhada'];
        total4 += +grupo.totalizadores['liquidada'];
        total5 += +grupo.totalizadores['paga'];

        // SUB-FUNÇÕES DE GOVERNO --------------------------------------------------------------------------------
        const subfuncoes = this.funcaoService.agrupar(grupo.registros, 'subfuncao',
          ['orcada', 'orcada_atualizada', 'empenhada', 'liquidada', 'paga']);
        for (const subfuncao of subfuncoes) {
          registros.push([
            {
              text: ''
            },
            {
              text: subfuncao.grupo, bold: true, fontSize: 7,
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: this.funcaoService.convertToBrNumber(subfuncao.totalizadores['orcada']),
              alignment: 'right', bold: true, fontSize: 7
            },
            {
              text: this.funcaoService.convertToBrNumber(subfuncao.totalizadores['orcada_atualizada']),
              alignment: 'right', bold: true, fontSize: 7
            },
            {
              text: this.funcaoService.convertToBrNumber(subfuncao.totalizadores['empenhada']),
              alignment: 'right', bold: true, fontSize: 7
            },
            {
              text: this.funcaoService.convertToBrNumber(subfuncao.totalizadores['liquidada']),
              alignment: 'right', bold: true, fontSize: 7
            },
            {
              text: this.funcaoService.convertToBrNumber(subfuncao.totalizadores['paga']),
              alignment: 'right', bold: true, fontSize: 7
            }
          ]);

          // RECURSOS DE GOVERNO --------------------------------------------------------------------------------
          const recursos = this.funcaoService.agrupar(subfuncao.registros, 'recurso',
            ['orcada', 'orcada_atualizada', 'empenhada', 'liquidada', 'paga']);
          for (const recurso of recursos) {
            registros.push([
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: recurso.grupo, bold: true, fontSize: 7,
              },
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: this.funcaoService.convertToBrNumber(recurso.totalizadores['orcada']),
                alignment: 'right', bold: true, fontSize: 7
              },
              {
                text: this.funcaoService.convertToBrNumber(recurso.totalizadores['orcada_atualizada']),
                alignment: 'right', bold: true, fontSize: 7
              },
              {
                text: this.funcaoService.convertToBrNumber(recurso.totalizadores['empenhada']),
                alignment: 'right', bold: true, fontSize: 7
              },
              {
                text: this.funcaoService.convertToBrNumber(recurso.totalizadores['liquidada']),
                alignment: 'right', bold: true, fontSize: 7
              },
              {
                text: this.funcaoService.convertToBrNumber(recurso.totalizadores['paga']),
                alignment: 'right', bold: true, fontSize: 7
              }
            ]);

            // ELEMENTO DE DESPESA --------------------------------------------------------------------------------
            const elementos = this.funcaoService.agrupar(recurso.registros, ['aplicacao', 'elemento'],
              ['orcada', 'orcada_atualizada', 'empenhada', 'liquidada', 'paga']);
            for (const elemento of elementos) {
              registros.push([
                {
                  text: '',
                },
                {
                  text: ''
                },
                {
                  text: ''
                },
                {
                  text: elemento.grupo['aplicacao'], bold: true,
                },
                {
                  text: elemento.grupo['elemento']?.toLocaleUpperCase(), bold: true, fontSize: 7
                },
                {
                  text: this.funcaoService.convertToBrNumber(elemento.totalizadores['orcada']),
                  alignment: 'right', bold: true, fontSize: 7
                },
                {
                  text: this.funcaoService.convertToBrNumber(elemento.totalizadores['orcada_atualizada']),
                  alignment: 'right', bold: true, fontSize: 7
                },
                {
                  text: this.funcaoService.convertToBrNumber(elemento.totalizadores['empenhada']),
                  alignment: 'right', bold: true, fontSize: 7
                },
                {
                  text: this.funcaoService.convertToBrNumber(elemento.totalizadores['liquidada']),
                  alignment: 'right', bold: true, fontSize: 7
                },
                {
                  text: this.funcaoService.convertToBrNumber(elemento.totalizadores['paga']),
                  alignment: 'right', bold: true, fontSize: 7
                }
              ]);

              //  ITENS -------------------------------------------------------------------------------------------
              for (const item of elemento.registros) {
                if ((+item.empenhada + +item.liquidada + +item.paga) !== 0) {
                  registros.push([
                    {
                      text: '',
                    },
                    {
                      text: ''
                    },
                    {
                      text: ''
                    },
                    {
                      text: '',
                    },
                    {
                      text: item.despesa.toString().length === 9 ? item.elemento.toLocaleUpperCase() : item.despesa.toLocaleUpperCase(), fontSize: 7
                    },
                    { text: ' ', alignment: 'right', fontSize: 7 },
                    { text: ' ', alignment: 'right', fontSize: 7 },
                    { text: this.funcaoService.convertToBrNumber(item.empenhada), alignment: 'right', fontSize: 7 },
                    { text: this.funcaoService.convertToBrNumber(item.liquidada), alignment: 'right', fontSize: 7 },
                    { text: this.funcaoService.convertToBrNumber(item.paga), alignment: 'right', fontSize: 7 },
                  ]);
                }
              }
            }
          }
        }
      }
    }

    registros.push([
      {
        text: 'TOTAL', bold: true, fontSize: 7, colSpan: 5
      },
      '',
      '',
      '',
      '',
      {
        text: this.funcaoService.convertToBrNumber(total1),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(total2),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(total3),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(total4),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(total5),
        alignment: 'right', bold: true, fontSize: 7
      }
    ]);

    let assinaturas
    if (this.assinatura_ensino) {
      const ass = new Assinaturas(this.login.orgao, this.injector);
      assinaturas = await ass.dadosAssinatura(50, false, null, false, this.assinatura_ensino);
    }

    return this.assinatura_ensino ? [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*', '*', '*', 55, 55, 55, 55, 55],
          body: registros
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*'],
          body: assinaturas
        }
      }
    ] : [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*', '*', '*', 55, 55, 55, 55, 55],
        body: registros
      }
    }];
  }

  public colunasRelatorio(): Coluna[] {
    return [
      { titulo: 'Grupo', coluna: 'grupo' },
      { titulo: 'Função Educação', coluna: 'funcao' },
      { titulo: 'Subfunção', coluna: 'subfuncao' },
      { titulo: 'Fonte de Recurso', coluna: 'recurso' },
      { titulo: 'Código Aplicação', coluna: 'aplicacao' },
      { titulo: 'Classificação Econômica', coluna: 'elemento' },
      { titulo: 'Dotação Inicial', coluna: 'orcada', decimais: 2 },
      { titulo: 'Dotação Atualizada', coluna: 'orcada_atualizada', decimais: 2 },
      { titulo: 'Despesa Empenhada', coluna: 'empenhada', decimais: 2 },
      { titulo: 'Despesa Liquidada', coluna: 'liquidada', decimais: 2 },
      { titulo: 'Despesa Paga', coluna: 'paga', decimais: 2 }
    ]
  }

  public conteudoExportar(lista: any[]) {
    return [
      ...this.normalizarDados(lista[0], 'TÍPICAS'),
      ...this.normalizarDados(lista[1], 'ATÍPICAS')
    ]
  }

  // public normalizarDados(lista: [], grupo: string) {
  //   return lista.map((item: any) => {
  //     return {
  //       grupo: grupo,
  //       funcao: item.funcao,
  //       subfuncao: item.subfuncao,
  //       recurso: item.recurso,
  //       aplicacao: item.aplicacao,
  //       elemento: item.elemento,
  //       orcada: item.orcada,
  //       orcada_atualizada: item.orcada_atualizada,
  //       empenhada: item.empenhada,
  //       liquidada: item.liquidada,
  //       paga: item.paga
  //     }
  //   })
  // }

  public normalizarDados(lista: [], grupo: string) {
    const linhas: {}[] = []

    const totalFuncao = this.funcaoService.agrupar(lista, 'funcao', ['orcada', 'orcada_atualizada', 'empenhada', 'liquidada', 'paga'])

    totalFuncao.forEach(itemA => {
      linhas.push({
        grupo: grupo,
        funcao: `${itemA.grupo} - TOTALIZADO`,
        orcada: itemA.totalizadores['orcada'],
        orcada_atualizada: itemA.totalizadores['orcada_atualizada'],
        empenhada: itemA.totalizadores['empenhada'],
        liquidada: itemA.totalizadores['liquidada'],
        paga: itemA.totalizadores['paga']
      })

      const totalSubFuncao = this.funcaoService.agrupar(itemA.registros, 'subfuncao', ['orcada', 'orcada_atualizada', 'empenhada', 'liquidada', 'paga'])

      totalSubFuncao.forEach(itemB => {
        linhas.push({
          grupo: grupo,
          funcao: itemA.grupo,
          subfuncao: `${itemB.grupo} - TOTALIZADO`,
          orcada: itemB.totalizadores['orcada'],
          orcada_atualizada: itemB.totalizadores['orcada_atualizada'],
          empenhada: itemB.totalizadores['empenhada'],
          liquidada: itemB.totalizadores['liquidada'],
          paga: itemB.totalizadores['paga']
        })

        const totalRecurso = this.funcaoService.agrupar(itemB.registros, 'recurso', ['orcada', 'orcada_atualizada', 'empenhada', 'liquidada', 'paga'])

        totalRecurso.forEach(itemC => {
          linhas.push({
            grupo: grupo,
            funcao: itemA.grupo,
            subfuncao: itemB.grupo,
            recurso: `${itemC.grupo} - TOTALIZADO`,
            orcada: itemC.totalizadores['orcada'],
            orcada_atualizada: itemC.totalizadores['orcada_atualizada'],
            empenhada: itemC.totalizadores['empenhada'],
            liquidada: itemC.totalizadores['liquidada'],
            paga: itemC.totalizadores['paga']
          })

          itemC.registros.forEach(itemD => {
            linhas.push({
              grupo: grupo,
              funcao: itemD.funcao,
              subfuncao: itemD.subfuncao,
              recurso: itemD.recurso,
              aplicacao: itemD.aplicacao,
              elemento: itemD.elemento,
              orcada: itemD.orcada,
              orcada_atualizada: itemD.orcada_atualizada,
              empenhada: itemD.empenhada,
              liquidada: itemD.liquidada,
              paga: itemD.paga
            })
          })
        })
      })
    })

    return linhas

  }

}
