import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { ExportarEmpenhoComponent } from './exportar-empenho/exportar-empenho.component';
import { ImportarRcmsComponent } from './importar-rcms/importar-rcms.component';
import { PedidoCompraFormComponent } from './pedido-compra/pedido-compra-form/pedido-compra-form.component';
import { PedidoCompraListComponent } from './pedido-compra/pedido-compra-list/pedido-compra-list.component';

const routes: Routes = [
  { path: 'exportar-empenho', component: ExportarEmpenhoComponent, canActivate: [AuthGuard] },
  { path: 'importar-rcms', component: ImportarRcmsComponent, canActivate: [AuthGuard] },
  {
    path: 'pedido-compra',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: PedidoCompraListComponent, canActivate: [AuthGuard] },
      { path: 'novo', component: PedidoCompraFormComponent, canActivate: [AuthGuard] },
      {
        path: ':id',
        canActivate: [AuthGuard],
        children: [
          { path: 'editar', component: PedidoCompraFormComponent, canActivate: [AuthGuard] },
          { path: 'visualizar', component: PedidoCompraFormComponent, canActivate: [AuthGuard] },
        ]
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MovimentoCompraRoutingModule { }
