import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Rcms } from "eddydata-lib";
import { ImprimirNotaRcmsRpt } from "./imprimir-nota-rcms-rpt/imprimir-nota-rcms-rpt.component";

@Component({
  selector: "lib-nota-rcms-rpt",
  templateUrl: "./nota-rcms-rpt.component.html",
})
export class NotaRcmsRpt implements AfterViewInit {

  @Input() rcms: Rcms[];
  @Input() visualizar: boolean = false;
  @Input() mostrarDescricao: boolean = false;

  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(ImprimirNotaRcmsRpt)
  imprimirNotaRcmsRptComponent: ImprimirNotaRcmsRpt;

  titulo: string;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.titulo = this.imprimirNotaRcmsRptComponent.tituloRelatorio();
    this.cd.detectChanges();
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  imprimir() {
    this.imprimirNotaRcmsRptComponent.imprimir();
  }
}
