import { NgModule } from '@angular/core';

import { LiquidacaoRestoRoutingModule } from './liquidacao-resto-routing.module';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { IMaskModule } from 'angular-imask';
import { ToastModule } from 'primeng/toast';
import { LiquidacaoRestoListComponent } from './liquidacao-resto-list/liquidacao-resto-list.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LiquidacaoRestoFormComponent } from './liquidacao-resto-form/liquidacao-resto-form.component';
import { RetencaoRestoComponent } from './retencao-resto/retencao-resto.component';
import { SharedModule, SimplesNacionalDlgModule } from 'eddydata-lib';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { LiquidacaoRestoRptComponent } from './liquidacao-resto-rpt/liquidacao-resto-rpt.component';
import { FieldsetModule } from 'primeng/fieldset';
import { NotaLiquidacaoRestoDlgComponent } from './nota-liquidacao-resto-dlg/nota-liquidacao-resto-dlg.component';
import { LiquidacaoRestoAnulacaoComponent } from './liquidacao-resto-anulacao/liquidacao-resto-anulacao.component';
import { GpsLiquidacaoRestoDlgComponent } from './gps-liquidacao-resto-dlg/gps-liquidacao-resto-dlg.component';
import { GrdLiquidacaoRestoDlgComponent } from './grd-liquidacao-resto-dlg/grd-liquidacao-resto-dlg.component';
import { PreLiquidacaoRestoModule } from '../pre-liquidacao-resto/pre-liquidacao-resto.module';
import { InplaceModule } from 'primeng/inplace';
import { RetencaoRestoReinfComponent } from './retencao-reinf/retencao-resto-reinf.component';
import { LiquidacaoModule } from '../liquidacao/liquidacao.module';
import { LiquidacaoMovimentoEstoqueDlgModule } from '../liquidacao-movimento-estoque/liquidacao-movimento-estoque-dlg.module';
import { EmpenhoModule } from '../empenho/empenho.module';
import { LiquidacaoRestoParcelaDlgComponent } from './liquidacao-resto-parcela-dlg/liquidacao-resto-parcela-dlg.component';

@NgModule({
  declarations: [
    LiquidacaoRestoListComponent,
    LiquidacaoRestoFormComponent,
    RetencaoRestoComponent,
    LiquidacaoRestoRptComponent,
    NotaLiquidacaoRestoDlgComponent,
    LiquidacaoRestoAnulacaoComponent,
    GpsLiquidacaoRestoDlgComponent,
    GrdLiquidacaoRestoDlgComponent,
    RetencaoRestoReinfComponent,
    LiquidacaoRestoParcelaDlgComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    LiquidacaoRestoRoutingModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    ConfirmDialogModule,
    IMaskModule,
    ToastModule,
    RadioButtonModule,
    FieldsetModule,
    PreLiquidacaoRestoModule,
    InplaceModule,
    LiquidacaoModule,
    SimplesNacionalDlgModule,
    LiquidacaoMovimentoEstoqueDlgModule,
    EmpenhoModule
  ],
  exports: [LiquidacaoRestoFormComponent],
  providers: [MessageService, ConfirmationService]
})
export class LiquidacaoRestoModule { }
