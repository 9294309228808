import { ContratoReajuste } from 'eddydata-lib';
import { BaseResourceModel } from '../../models/base-resource.model';
import { ProdutoUnidade } from '../almoxarifado/produto-unidade.model';
import { Memorial } from '../licitacao/memorial.model';
import { Contrato } from './contrato.model';
import { RcmsItem } from './rcms-item.model';

export class ContratoItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public ordem?: number,
    public quantidade?: number,
    public valor_unitario?: number,
    public unidade?: string,
    public descricao?: string,
    public bloqueado?: boolean,
    public contrato?: Contrato,
    public produto_unidade?: ProdutoUnidade,
    public memorial?: Memorial,
    public editavel?: boolean,
    public rcms_itens?: RcmsItem[], //usado apenas como atributo, sem função de persistencia
    public vl_unit_atualizado?: number,
    public reajustes?: ContratoReajuste[]
  ) {
    super();
  }
  static converteJson(json: any): ContratoItem {
    return Object.assign(new ContratoItem(), json);
  }
}
