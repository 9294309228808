import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ProdutoReferencia } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ProdutoReferenciaService extends BaseResourceService<ProdutoReferencia> {

  constructor(
    protected injector: Injector
  ) {
    super(`produto-referencia`, injector);
  }

}
