import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { AudespXML } from '../../components/types';

export class LayoutAudesp extends BaseResourceModel {
  constructor(
    public id?: number,
    public tipo?: AudespXML,
    public tagInicial?: string,
    public atributosTagInicial?: string,
    public exercicio?: Exercicio,
  ) {
    super();
  }

  static converteJson(json: any): LayoutAudesp {
    return Object.assign(new LayoutAudesp(), json);
  }
}
