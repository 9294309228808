// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { AvaliacaoChecklist } from './avaliacao-checklist.model';

export class AuditoriaAvaliacao  extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_auditoria?: Date,
    public ano?: number,
    public especie?: string,
    public orgao?: Orgao,
    public observacao?: string,
    public conclusao?: string,
    public recomendacao?: string,
    public cancelado?: boolean,
    public checklist?: AvaliacaoChecklist[]
    ) {
      super();
    }
    static converteJson(json: any): AuditoriaAvaliacao {
      return Object.assign(new AuditoriaAvaliacao(), json);
    }
}
