// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { CardapioMerenda } from './cardapio-merenda.model';
import { EtapaMerenda } from './etapa-merenda.model';

export class CardapioEtapaMerenda extends BaseResourceModel {
  constructor(
    public id?: number,
    public etapa?: EtapaMerenda,
    public cardapio?: CardapioMerenda,
    public editavel?: boolean) {
    super();
  }
  static converteJson(json: any): CardapioEtapaMerenda {
    return Object.assign(new CardapioEtapaMerenda(), json);
  }
}
