import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, EventoContabil, GlobalService } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EscrituracaoService extends BaseResourceService<EventoContabil> {

  constructor(
    protected injector: Injector
  ) {
    super(`escrituracoes`, injector);
  }

  escriturar(resource: any): Observable<any> {
    this.login = GlobalService.obterSessaoLogin();
    return this.http.post(`${this.login.cidade.id}/${this.api}`, resource, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  removerEscrituracao(exercicioId: number, orgaoId: number, mes: number, opcoes, data): Observable<any> {
    this.login = GlobalService.obterSessaoLogin();
    let parametros = '';
    if (opcoes) {
      for (const key in opcoes) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + opcoes[key];
      }
    }
    return this.http.get(
      `${this.login.cidade.id}/${this.api}/remover-escrituracao/${exercicioId}/${orgaoId}/${mes}/${data}${parametros}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }
}
