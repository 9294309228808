import { DatePipe } from '@angular/common';
import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { tsXLXS } from 'ts-xlsx-export';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  Login, ContaBancaria, Recurso, Convenio, EddyAutoComplete,
  GlobalService, FuncaoService, Relatorio, Coluna, Despesa,
  PagamentoResto, ExecutoraService, FichaDespesa, Favorecido, FavorecidoService, FichaExtra, DateFormatPipe, FormatoExportacao
} from 'eddydata-lib';
import {
  ContaBancariaService, ConvenioService, DespesaService, EmpenhoService, PagamentoRestoService,
  RecursoService
} from 'administrativo-lib';
import { FichaDespesaService } from '../../../ficha-despesa/service/ficha-despesa.service';
import { FichaExtraService } from '../../../ficha-extra/service/ficha-extra.service';
import * as toastr from 'toastr';

@Component({
  selector: 'app-pagamento-resto-rpt',
  templateUrl: './pagamento-resto-rpt.component.html'
})
export class PagamentoRestoRptComponent implements OnInit, OnDestroy {

  login: Login = new Login();
  conta: ContaBancaria;
  despesa: Despesa;
  ficha: FichaDespesa;
  fichaExtra: FichaExtra;
  recurso: Recurso;
  aplicacao: Recurso;
  convenio: Convenio;
  favorecido: Favorecido;
  aplicacao_variavel: Recurso;
  aplicacao_variavel_final: Recurso;
  RecursoAplicacao: Recurso;
  RecursoAplicacaoFinal: Recurso;
  modeloBusca: boolean = false;
  modeloBuscaText: string = 'Avançado';
  filtragemAvancada: boolean = false;
  filtroRecursoAplicacao: boolean = false;
  filtroFavorecido: boolean;
  filtroFicha: boolean = false;
  filtroDespesa: boolean;
  filtroConta: boolean;
  filtroConvenio: boolean;
  filtroRecurso: boolean;
  filtroAplicacao: boolean;
  filtroAplicacao_variavel: boolean;
  filtroFichaExtra = false;
  public selectedOrdem: string;
  public selectedOrdenacao: string;
  public selectedModelo: string;

  public ptBR: any;

  public listaRelatorio: Array<any>;
  public listaConvenio: Array<Convenio>;
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;
  public contaAutoComplete: EddyAutoComplete<ContaBancaria>;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public despesaAutoComplete: EddyAutoComplete<Despesa>;
  public aplicacaoVariavelAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoVariavelFinalAutoComplete: EddyAutoComplete<Recurso>;
  public recursoAplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public fichaAutoComplete: EddyAutoComplete<FichaDespesa>;
  public fichaExtraAutoComplete: EddyAutoComplete<FichaExtra>;
  public recursoAplicacaoFinalAutoComplete: EddyAutoComplete<Recurso>;
  public relatorio = '1';
  public dataInicial: Date;
  public dataFinal: Date;
  protected datepipe: DatePipe;
  protected unsubscribe: Subject<void> = new Subject();

  // agrupamentos relatorio
  public selectAgrupamento = '1';
  public ativo: boolean = true;
  public fonteRecurso: any;
  public listaRecurso: boolean = false;
  saldoConta: any;

  constructor(
    private router: Router,
    protected injector: Injector,
    protected messageService: MessageService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected favorecidoService: FavorecidoService,
    protected contaService: ContaBancariaService,
    protected recursoService: RecursoService,
    protected despesaService: DespesaService,
    protected convenioService: ConvenioService,
    protected executoraService: ExecutoraService,
    protected fichaDespesaService: FichaDespesaService,
    protected empenhoService: EmpenhoService,
    protected fichaExtraService: FichaExtraService,
    protected pagamentoService: PagamentoRestoService) {
    this.datepipe = new DatePipe('pt')
  }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.login = GlobalService.obterSessaoLogin();
    this.selectedOrdem = 'ord1';
    this.selectedModelo = 'mod1'
    this.selectedOrdenacao = 'ASC';
    this.listaRelatorio = [];
    this.listaRelatorio.push({ label: 'TODOS OS PAGAMENTOS DE RESTO A PAGAR', value: 1 });
    this.listaRelatorio.push({ label: 'PAGAMENTOS ANULADOS DE RESTO A PAGAR', value: 2 });
    this.listaRelatorio.push({ label: 'PAGAMENTO DE RESTOS A PAGAR AGRUPADOS', value: 3 });
    this.carregarAutoCompletes();
    this.dataInicial = new DateFormatPipe().transform(this.datepipe.transform(new Date(`01/01/${this.login.exercicio.ano}`), 'yyyy-MM-dd'), []);
    this.dataFinal = new DateFormatPipe().transform(this.datepipe.transform(new Date(), 'yyyy-MM-dd'), []);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    new GlobalService().calendarMascara();
  }

  public statusModeloBusca(x: number) {
    // Explicacao -> x == 1 ? click no filtro de aplicacao : click no filtro avancado.

    if (x == 1 && !this.filtroAplicacao_variavel && this.filtragemAvancada && !this.modeloBusca)
      this.filtroAplicacao_variavel = true;

    if (x == 2 && !this.modeloBusca) {
      this.modeloBuscaText = 'Avançado';
      this.filtroRecursoAplicacao = false;
    } else if (x == 2 && this.modeloBusca) {
      this.modeloBuscaText = 'Simples';
      this.filtroRecurso = false; this.filtroAplicacao = false; this.filtroAplicacao_variavel = false;
    } else if (x == 2 && this.modeloBusca && !this.filtroRecursoAplicacao && this.filtragemAvancada) {
      this.modeloBuscaText = 'Simples';
      this.filtroRecursoAplicacao = true;
      this.filtroRecurso = false; this.filtroAplicacao = false; this.filtroAplicacao_variavel = false;
    }
  }

  public gerarRelatorio(formato: FormatoExportacao) {
    const parametros = {};
    if (!this.dataInicial || !this.dataFinal) {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }

    parametros['ignoreCondObrig'] = true
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['relations'] = 'conta,liquidacao.empenho.favorecido';

    parametros['data_pagamento$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
    parametros['data_pagamento$le'] = this.funcaoService.converteDataSQL(this.dataFinal);

    if (this.filtroConvenio) {
      parametros['convenio.numero'] = this.convenio.numero;
    }

    if (this.filtroConta) {
      parametros['conta.id'] = this.conta.id;
    }

    if (this.filtroDespesa) {
      parametros['liquidacao.empenho.subelemento'] = this.despesa.codigo;
    }

    if (this.filtroFavorecido) {
      if (!this.filtroFavorecido) {
        return toastr.warning(`Informe a ficha de retenção desejada!`)
      }
      parametros['liquidacao.empenho.favorecido.id'] = this.favorecido.id;
    }

    if (this.filtroFicha) {
      parametros['liquidacao.empenho.subelemento'] = this.ficha.despesa.codigo;
      parametros['liquidacao.empenho.acao'] = this.ficha.acao.codigo;
      parametros['liquidacao.empenho.funcao'] = this.ficha.funcao.codigo;
      parametros['liquidacao.empenho.subfuncao'] = this.ficha.subfuncao.codigo;
      parametros['liquidacao.empenho.executora'] = this.ficha.executora.codigo;
      parametros['liquidacao.empenho.programa'] = this.ficha.programa.codigo;
      parametros['liquidacao.empenho.recurso'] = this.ficha.recurso.codigo;
      parametros['liquidacao.empenho.aplicacao'] = this.ficha.aplicacao.codigo;
      parametros['liquidacao.empenho.recurso_variavel'] = this.ficha.aplicacao_variavel?.variavel;
    }

    if (this.filtroFichaExtra) {
      if (!this.fichaExtra?.id) {
        return toastr.warning(`Informe a ficha de retenção desejada!`)
      }
      parametros['liquidacao.retencoes.ficha.id'] = this.fichaExtra.id;
    }

    if (this.filtroRecurso && !this.modeloBusca) {
      parametros['liquidacao.empenho.recurso'] = this.recurso.codigo;
    }

    if (this.filtroAplicacao && !this.modeloBusca) {
      parametros['liquidacao.empenho.aplicacao'] = this.aplicacao.codigo;
    }

    // modelo de busca Avancado
    if (this.filtroAplicacao_variavel && !this.modeloBusca && !this.filtragemAvancada) {
      let v;
      if (this.aplicacao_variavel.codigo.length > 4)
        v = this.aplicacao_variavel.codigo.substring(5, this.aplicacao_variavel.codigo.length);

      parametros['liquidacao.empenho.recurso_variavel'] = v;
    } else if (this.filtroAplicacao_variavel && !this.modeloBusca && this.filtragemAvancada) {
      let v_inicial;
      let v_final;
      if (this.aplicacao_variavel.codigo.length > 4)
        v_inicial = this.aplicacao_variavel.codigo;

      if (this.aplicacao_variavel.codigo.length > 4)
        v_final = this.aplicacao_variavel.codigo;

      parametros['liquidacao.empenho.recurso_variavel$ge'] = v_inicial;
      parametros['liquidacao.empenho.recurso_variavel$le'] = v_final;

      // modelo de busca Simples
    } else if (!this.filtroAplicacao_variavel && this.modeloBusca && this.filtroRecursoAplicacao && !this.filtragemAvancada) {
      let r = this.RecursoAplicacao.codigo.substring(0, 2);
      let a = this.RecursoAplicacao.codigo.substring(2, 5);
      let v = this.RecursoAplicacao.codigo.substring(5, this.RecursoAplicacao.codigo.length);

      parametros['liquidacao.empenho.recurso$ge'] = r
      parametros['liquidacao.empenho.aplicacao$ge'] = a
      parametros['liquidacao.empenho.recurso_variavel$ge'] = v
    } else if (!this.filtroAplicacao_variavel && this.modeloBusca && this.filtroRecursoAplicacao && this.filtragemAvancada) {
      /* NUMERO INICIAL */
      // let r_inicial = this.RecursoAplicacao.codigo.substring(0, 2);
      // let a_inicial = this.RecursoAplicacao.codigo.substring(2, 5);
      let v_inicial = this.RecursoAplicacao.codigo;

      // parametros['liquidacao.empenho.recurso$ge'] = r_inicial
      // parametros['liquidacao.empenho.aplicacao$ge'] = a_inicial 
      parametros['liquidacao.empenho.recurso_variavel$ge'] = v_inicial

      /* NUMERO FINAL */
      // let r_final = this.RecursoAplicacaoFinal.codigo.substring(0, 2);
      // let a_final = this.RecursoAplicacaoFinal.codigo.substring(2, 5);
      let v_final = this.RecursoAplicacaoFinal.codigo;

      // parametros['liquidacao.empenho.recurso$le'] = r_final
      // parametros['liquidacao.empenho.aplicacao$le'] = a_final
      parametros['liquidacao.empenho.recurso_variavel$le'] = v_final
    }

    if (this.relatorio === '2') {
      parametros['anulacao'] = true;
    }

    if (this.selectedOrdem === 'ord1') {
      parametros['orderBy'] = 'data_pagamento$' + this.selectedOrdenacao;
    } else if (this.selectedOrdem === 'ord2') {
      parametros['orderBy'] = 'conta.numero_conta$' + this.selectedOrdenacao;
    } else if (this.selectedOrdem === 'ord3') {
      parametros['orderBy'] = 'liquidacao.empenho.subelemento$' + this.selectedOrdenacao;
    } else if (this.selectedOrdem === 'ord4') {
      parametros['orderBy'] = 'liquidacao.empenho.favorecido.nome$' + this.selectedOrdenacao;
    }

    this.pagamentoService.extendido(1, -1,
      parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((lista) => {
        if (lista.content.length === 0) {
          toastr.options.positionClass = 'toast-top-left';
          toastr.warning('Não foi encontrado nenhum pagamento de restos a pagar!', 'Atenção!')
          return;
        }
        let valor;
        valor = lista.content.map((i) => {
          i.despesa = `${i.liquidacao.empenho.subelemento} ${i.liquidacao.empenho.subelemento_nome}`
          i.conta_bancaria = `${i.conta.codigo} ${i.conta.nome}`
          i.ficha = `${i.ficha_empenho}`;
          let recurso_aplicacao_variavel = `${i.liquidacao.empenho.recurso.toString()}${i.liquidacao.empenho.aplicacao.toString()}`;
          if (!i.liquidacao.empenho.recurso_variavel) {
            recurso_aplicacao_variavel += '0000';
          } else if (i.liquidacao.empenho.recurso_variavel.length === 4) {
            recurso_aplicacao_variavel += i.liquidacao.empenho.recurso_variavel.toString();
          } else {
            recurso_aplicacao_variavel = i.liquidacao.empenho.recurso_variavel.toString();
          }

          if (i.liquidacao.parcela == null || i.liquidacao.parcela == undefined || i.liquidacao.parcela === 0) {
            return { ...i, valor_liquido: i.valor_retido == i.valor_pago ? i.liquidacao.empenho.valor_empenho : i.liquidacao.empenho.valor_empenho - i.valor_retido, empenho_parcela: i.liquidacao.empenho.numero, recurso_aplicacao_variavel, valor_retencao: i.liquidacao.retencoes?.reduce((valor_atual, retencao) => valor_atual += retencao.valor_retido, 0) };
          } else {
            return { ...i, valor_liquido: i.liquidacao.valor_liquidado - i.valor_retido, empenho_parcela: i.liquidacao.empenho.numero + " - " + i.liquidacao.parcela, recurso_aplicacao_variavel, valor_retencao: i.liquidacao.retencoes?.reduce((valor_atual, retencao) => valor_atual += retencao.valor_retido, 0) };
          }
        });
        let subtitulo: string;
        if (this.filtroFichaExtra) {
          subtitulo = `PAGAMENTOS RESTOS A PAGAR PERÍODO DE ${this.datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${this.datepipe.transform(this.dataFinal, 'dd/MM/yyyy')} \nFILTRADOS POR FICHA DE RETENÇÃO ${this.relatorio === '2' ? '\nANULADOS' : ''}`
        } else {
          subtitulo = `PAGAMENTOS RESTOS A PAGAR PERÍODO DE ${this.datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${this.datepipe.transform(this.dataFinal, 'dd/MM/yyyy')} ${this.relatorio === '2' ? '\nANULADOS' : ''}`
        }
        if (this.selectedModelo === 'mod1') {
          if (formato === 'pdf') {
            this.imprimir(subtitulo,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem pagamentos de restos', ['auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], valor);
          } else {
            this.funcaoService.exportar(formato, valor, subtitulo, this.colunas() as Coluna[]);
          }
        } else {
          if (formato === 'pdf') {
            this.imprimirMod2(subtitulo,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem pagamentos de restos', ['auto', 'auto', 'auto', '*', 'auto', 'auto'], valor);
          } else {
            this.funcaoService.exportar(formato, valor, subtitulo, this.colunasMod2() as Coluna[]);
          }
        }

      });
  }

  private imprimir(
    titulo: string, usuario: string, sobrenome: string, orgao: string,
    brasao: string, orientacao: 'landscape' | 'portrait', nomepdf?: string, largura?: string[], lista?: any[]) {
    Relatorio.imprimir(titulo, usuario, sobrenome, orgao, brasao, lista, this.colunas(), orientacao, nomepdf, largura, ['liquidacao.empenho.valor_empenho', 'liquidacao.valor_liquidado', 'valor_retido', 'valor_liquido', 'valor_pago']);
  }

  private imprimirMod2(
    titulo: string, usuario: string, sobrenome: string, orgao: string,
    brasao: string, orientacao: 'landscape' | 'portrait', nomepdf?: string, largura?: string[], lista?: any[]) {
    Relatorio.imprimir(titulo, usuario, sobrenome, orgao, brasao, lista, this.colunasMod2(), orientacao, nomepdf, largura, [{ nome: 'valor_liquidado', funcao: [{ se: { condicao: 'valor_retido=valor_pago', verdadeiro: [0], falso: ['valor_pago', '-', 'valor_retido'] } }] }]);
  }

  colunas(): string[] | Coluna[] {
    let retencao: Coluna = {
      titulo: 'Valor Retido', coluna: 'valor_retido', decimais: 2, alignment: 'right', funcao: [{
        se: {
          condicao: 'valor_retido=valor_pago$ent',
          verdadeiro: ['0,00'],
          falso: ['valor_retido']
        },
      }]
    };
    if (this.filtroFichaExtra) {
      retencao = {
        titulo: 'Valor Retido', coluna: 'valor_retido', decimais: 2, alignment: 'right', funcao: [{
          se: {
            condicao: 'valor_retido=valor_pago$ent',
            verdadeiro: ['0,00'],
            falso: ['valor_retencao']
          },
        }]
      };
    }
    return [
      { titulo: 'Data', coluna: 'data_pagamento', agrupar: true, alignment: 'left', bold: true },
      { titulo: 'Conta', coluna: 'conta_bancaria' },
      // { titulo: 'Descrição conta', coluna: 'conta.nome' },
      { titulo: 'Documento', coluna: 'documento' },
      { titulo: 'Ficha', coluna: 'ficha' },
      { titulo: 'Despesa', coluna: 'despesa' },
      // { titulo: '', coluna: 'liquidacao.empenho.subelemento.nome' },
      { titulo: 'Emp.', coluna: 'empenho_parcela', alignment: 'center' },
      { titulo: 'Favorecido', coluna: 'liquidacao.empenho.favorecido.nome' },
      { titulo: 'Recurso', coluna: 'recurso_aplicacao_variavel' },
      { titulo: 'Valor Emp.', coluna: 'liquidacao.empenho.valor_empenho', decimais: 2, alignment: 'right' },
      { titulo: 'Valor Bruto', coluna: 'liquidacao.valor_liquidado', decimais: 2, alignment: 'right' },
      retencao,
      { titulo: 'Valor Liquido', coluna: 'valor_liquido', decimais: 2, alignment: 'right' },
      { titulo: 'Valor Pago', coluna: 'valor_pago', decimais: 2, alignment: 'right' }
    ];
  }

  colunasMod2(): string[] | Coluna[] {
    let liquido: Coluna = {
      titulo: 'Valor Liquido', coluna: 'valor_liquidado', decimais: 2, alignment: 'right', funcao: [{
        se: {
          condicao: 'valor_retido=valor_pago',
          verdadeiro: ['0,00'],
          falso: ['valor_pago', '-', 'valor_retido']
        },
      }]
    };
    return [
      { titulo: 'Empenho', coluna: 'liquidacao.empenho.numero', alignment: 'left' },
      { titulo: 'Parcela', coluna: 'liquidacao.parcela', alignment: 'center' },
      { titulo: 'Favorecido', coluna: 'liquidacao.empenho.favorecido.nome' },
      { titulo: 'Historico', coluna: 'historico' },
      { titulo: 'Data pagamento', coluna: 'data_pagamento' },
      liquido
    ];
  }

  exportarXlxs() {
    const parametros = {};
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['relations'] = 'conta,liquidacao,liquidacao.empenho.ficha,liquidacao.empenho.subelemento,'
      + 'liquidacao.empenho.ficha.recurso,liquidacao.empenho.ficha.aplicacao';
    parametros['orderBy'] = 'codigo';
    this.pagamentoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          const listaItens = new Array();
          for (const item of lista.content) {
            const entity = {
              codigo: item.codigo,
              cpf: item.cpf,
              nome: item.pessoa.nome,
              endereco: item.pessoa.endereco,
              bairro: item.pessoa.bairro,
              cidade: item.pessoa.municipio,
              telefone: item.pessoa.telefone,
              cep: item.pessoa.cep
            };
            listaItens.push(entity);
          }
          tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile('funcionarios');
        },
        () => alert('erro ao retornar lista')
      );
  }

  private carregarAutoCompletes() {
    // autocomplete para favorecido
    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['cpf_cnpj', 'nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome', 'cpf_cnpj'] }
    );

    // autocomplete para conta
    this.contaAutoComplete = new EddyAutoComplete(
      null,
      this.contaService, "id", ["codigo", "banco.nome", "nome"],
      { orgao_id: this.login.orgao.id, ativo: true, relations: "banco", orderBy: "codigo" },
      { number: ["codigo"], text: ["banco.nome", "nome"] }
    );

    // autocomplete para despesa
    this.despesaAutoComplete = new EddyAutoComplete(null, this.despesaService,
      'id', ['codigo', 'nome'], { escrituracao: true, exercicio_id: this.login.exercicio.id, orderBy: 'nome' },
      { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para ficha
    this.fichaAutoComplete = new EddyAutoComplete(null, this.fichaDespesaService,
      'numero', ['ficha.numero', 'ficha.despesa.nome', 'ficha.acao.codigo', 'ficha.programa.codigo', 'ficha.executora.codigo', 'ficha.subfuncao.codigo', 'ficha.recurso.codigo'],
      { exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id, relations: 'despesa,acao,funcao,subfuncao,programa,executora,recurso,aplicacao,aplicacao_variavel', orderBy: 'despesa.nome' },
      { number: ['numero'], text: ['despesa.codigo', 'despesa.nome'] }
    );

    this.fichaExtraAutoComplete = new EddyAutoComplete(null, this.fichaExtraService,
      'numero', ['numero', 'nome'],
      { exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id, excluida: false, relations: 'plano,favorecido,ficha', orderBy: 'nome' },
      { number: ['id', 'numero'], text: ['nome'] }
    );

    // autocomplete para recurso
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'codigo', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'codigo', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para aplicacao Variavel
    this.aplicacaoVariavelAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'codigo', ['variavel', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['variavel', 'nome'] }
    );

    // autocomplete para aplicacao Variavel numero final (busca avancada)
    this.aplicacaoVariavelFinalAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'codigo', ['variavel', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['variavel', 'nome'] }
    );

    // autocomplete para recursoAplicacao e Variavel (busca simples)
    this.recursoAplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'codigo', ['codigo', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para recursoAplicacao e Variavel numero final (busca simples)
    this.recursoAplicacaoFinalAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'codigo', ['codigo', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['codigo', 'nome'] }
    );
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/tesouraria']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public async gerarAgrupamentos(formato: FormatoExportacao) {
    let parametrosFiltro = {};
    let relations = 'exercicio,orgao,conta,conta_recurso.recurso,conta_recurso.aplicacao,liquidacao,liquidacao.empenho,liquidacao.empenho.favorecido,liquidacao.ordem_item,liquidacao.ordem_item.ordem';
    parametrosFiltro['orgao_id'] = this.login.orgao.id;
    parametrosFiltro['relations'] = relations;

    if (!this.dataInicial || !this.dataFinal) {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }
    parametrosFiltro['data_pagamento$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
    parametrosFiltro['data_pagamento$le'] = this.funcaoService.converteDataSQL(this.dataFinal);

    if (this.filtroConvenio) {
      parametrosFiltro['convenio.numero'] = this.convenio.numero;
    }

    if (this.filtroConta) {
      parametrosFiltro['conta.id'] = this.conta.id;
    }

    if (this.filtroDespesa) {
      parametrosFiltro['liquidacao.empenho.subelemento'] = this.despesa.codigo;
    }

    if (this.filtroFavorecido) {
      parametrosFiltro['liquidacao.empenho.favorecido.id'] = this.favorecido.id;
    }

    if (this.filtroFicha) {
      parametrosFiltro['liquidacao.empenho.subelemento'] = this.ficha.numero;
      parametrosFiltro['liquidacao.empenho.acao'] = this.ficha.acao.codigo;
      parametrosFiltro['liquidacao.empenho.funcao'] = this.ficha.funcao.codigo;
      parametrosFiltro['liquidacao.empenho.subfuncao'] = this.ficha.subfuncao.codigo;
      parametrosFiltro['liquidacao.empenho.programa'] = this.ficha.programa.codigo;
      parametrosFiltro['liquidacao.empenho.recurso'] = this.ficha.recurso.codigo;
      parametrosFiltro['liquidacao.empenho.aplicacao'] = this.ficha.aplicacao.codigo;
      parametrosFiltro['liquidacao.empenho.recurso_variavel'] = this.ficha.aplicacao_variavel.variavel;
    }

    if (this.filtroRecurso && !this.modeloBusca) {
      parametrosFiltro['liquidacao.empenho.recurso'] = this.recurso.codigo;
    }

    if (this.filtroAplicacao && !this.modeloBusca) {
      parametrosFiltro['liquidacao.empenho.aplicacao'] = this.aplicacao.codigo;
    }

    // modelo de busca Avancado
    if (this.filtroAplicacao_variavel && !this.modeloBusca && !this.filtragemAvancada) {
      let v;
      if (this.aplicacao_variavel.codigo.length > 4)
        v = this.aplicacao_variavel.codigo.substring(5, this.aplicacao_variavel.codigo.length);

      parametrosFiltro['liquidacao.empenho.recurso_variavel'] = v;
    } else if (this.filtroAplicacao_variavel && !this.modeloBusca && this.filtragemAvancada) {
      let v_inicial;
      let v_final;
      if (this.aplicacao_variavel.codigo.length > 4)
        v_inicial = this.aplicacao_variavel.codigo.substring(5, this.aplicacao_variavel.codigo.length);

      if (this.aplicacao_variavel.codigo.length > 4)
        v_final = this.aplicacao_variavel.codigo.substring(5, this.aplicacao_variavel.codigo.length);

      parametrosFiltro['liquidacao.empenho.recurso_variavel$ge'] = v_inicial;
      parametrosFiltro['liquidacao.empenho.recurso_variavel$le'] = v_final;

      // modelo de busca Simples
    } else if (!this.filtroAplicacao_variavel && this.modeloBusca && this.filtroRecursoAplicacao && !this.filtragemAvancada) {
      let r = this.RecursoAplicacao.codigo.substring(0, 2);
      let a = this.RecursoAplicacao.codigo.substring(2, 5);
      let v = this.RecursoAplicacao.codigo.substring(5, this.RecursoAplicacao.codigo.length);

      parametrosFiltro['liquidacao.empenho.recurso'] = r
      parametrosFiltro['liquidacao.empenho.aplicacao'] = a
      parametrosFiltro['liquidacao.empenho.recurso_variavel'] = v
    } else if (!this.filtroAplicacao_variavel && this.modeloBusca && this.filtroRecursoAplicacao && this.filtragemAvancada) {

      /* NUMERO INICIAL */
      let r_inicial = this.RecursoAplicacao.codigo.substring(0, 2);
      let a_inicial = this.RecursoAplicacao.codigo.substring(2, 5);
      let v_inicial = this.RecursoAplicacao.codigo.substring(5, this.RecursoAplicacao.codigo.length);

      parametrosFiltro['liquidacao.empenho.recurso$ge'] = r_inicial
      parametrosFiltro['liquidacao.empenho.aplicacao$ge'] = a_inicial
      parametrosFiltro['liquidacao.empenho.recurso_variavel$ge'] = v_inicial

      /* NUMERO FINAL */
      let r_final = this.RecursoAplicacaoFinal.codigo.substring(0, 2);
      let a_final = this.RecursoAplicacaoFinal.codigo.substring(2, 5);
      let v_final = this.RecursoAplicacaoFinal.codigo.substring(5, this.RecursoAplicacaoFinal.codigo.length);

      parametrosFiltro['liquidacao.empenho.recurso$le'] = r_final
      parametrosFiltro['liquidacao.empenho.aplicacao$le'] = a_final
      parametrosFiltro['liquidacao.empenho.recurso_variavel$le'] = v_final
    }

    if (this.selectAgrupamento == '1') {
      parametrosFiltro['orderBy'] = 'exercicio.ano$' + this.selectedOrdenacao + ',data_pagamento$' + this.selectedOrdenacao;
    } else if (this.selectAgrupamento == '2') {
      parametrosFiltro['orderBy'] = 'exercicio.ano$' + this.selectedOrdenacao + ',liquidacao.empenho.unidade$' + this.selectedOrdenacao + ',data_pagamento$' + this.selectedOrdenacao;
    } else if (this.selectAgrupamento == '3') {
      parametrosFiltro['orderBy'] = 'exercicio.ano$' + this.selectedOrdenacao + ',liquidacao.empenho.recurso$' + this.selectedOrdenacao + ',liquidacao.empenho.aplicacao$' + this.selectedOrdenacao + ',liquidacao.empenho.recurso_variavel$' + this.selectedOrdenacao + ',data_pagamento$' + this.selectedOrdenacao;
    }

    let dados = await this.pagamentoService.extendido(1, -1, parametrosFiltro).toPromise();
    if (formato === 'pdf') {
      this.imprimirAgrupados(dados.content);
    } else {
      this.funcaoService.exportar(formato, await this.montarConteudoCSV(dados.content), `PAGAMENTOS RESTO A PAGAR PERIODO: ${this.datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} a ${this.datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`, this.colunasCSV() as Coluna[]);
    }
  }

  private async imprimirAgrupados(dados: PagamentoResto[]) {
    Relatorio.imprimirPersonalizado('PAGAMENTOS RESTO A PAGAR', this.login.usuario.nome, this.login.usuario.sobrenome,
      this.login.orgao.nome, this.login.brasao,
      await this.montarConteudo(dados),
      'landscape', 'PAGAMENTOS RESTO A PAGAR',
      {
        linhas: {
          hLineWidth() {
            return 1;
          },
          vLineWidth() {
            return 1;
          },
          hLineColor() {
            return 'black';
          },
          paddingLeft() {
            return 3;
          },
          paddingRight() {
            return 3;
          }
        }
      }, false);
  }

  private async montarConteudo(lista: any[]): Promise<{}[]> {
    let grupos;
    if (this.selectAgrupamento == '1') {
      // agrupar por exercicio
      grupos = this.funcaoService.agrupar(lista, 'exercicio.ano')
    } else if (this.selectAgrupamento == '2') {
      // agrupar por unidade
      grupos = this.funcaoService.agrupar(lista, ['exercicio.ano', 'liquidacao.empenho.unidade'])
    } else if (this.selectAgrupamento == '3') {
      // agrupar por recurso
      grupos = this.funcaoService.agrupar(lista, ['exercicio.ano', 'liquidacao.empenho.recurso', 'liquidacao.empenho.aplicacao'])
    }

    let retorno: {}[] = [];
    retorno.push({
      text: `REFERÊNCIA: ${this.datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} a ${this.datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
      alignment: 'center', fontSize: 10, lineHeight: 1.5
    });

    let data = []; let data_unidade = []; let data_recurso = [];
    for (let grupo of grupos) {
      const conteudo = [];

      let divisao = true; let divisao_unidade = true; let divisao_recurso = true;
      let rept: number; let rept_unidade: number; let rept_recurso: number;

      if (data != undefined)
        rept = data.find((i) => i == grupo.grupo['exercicio.ano']);

      if (data_unidade != undefined)
        rept_unidade = data_unidade.find((i) => i == grupo.grupo['exercicio.ano'] + grupo.grupo['liquidacao.empenho.unidade']);

      if (data_recurso != undefined)
        rept_recurso = data_recurso.find((i) => i == grupo.grupo['exercicio.ano'] + grupo.grupo['liquidacao.empenho.recurso'] + grupo.grupo['liquidacao.empenho.aplicacao']);

      // exercicio
      if (!rept) {
        data.push(grupo.grupo['exercicio.ano']);
      } else {
        divisao = false;
      }

      // unidade
      if (!rept_unidade) {
        data_unidade.push(grupo.grupo['exercicio.ano'] + grupo.grupo['liquidacao.empenho.unidade']);
      } else {
        divisao_unidade = false;
      }

      // recurso
      if (!rept_recurso) {
        data_recurso.push(grupo.grupo['exercicio.ano'] + grupo.grupo['liquidacao.empenho.recurso'] + grupo.grupo['liquidacao.empenho.aplicacao']);
      } else {
        divisao_recurso = false;
      }

      if (this.selectAgrupamento == '1') {
        conteudo.push([
          { text: 'DATA', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
          { text: 'EMPENHO', fontSize: 8, alignment: 'center', bold: true },
          { text: 'FICHA', fontSize: 8, alignment: 'center', bold: true },
          { text: 'OP', fontSize: 8, alignment: 'center', bold: true },
          { text: 'FAVORECIDO', fontSize: 8, alignment: 'center', bold: true },
          { text: 'CONTA BANCÁRIA', fontSize: 8, alignment: 'center', bold: true },
          { text: 'DOCUMENTO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
          { text: 'PROCESSADO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
          { text: 'NÃO PROCESSADO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
        ]);

        if (this.selectAgrupamento == '1') {
          conteudo.push([
            { text: `Exercício ${grupo.grupo}`, colSpan: 9, bold: true, fontSize: 12 },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
          ]);
        }

      } else if (this.selectAgrupamento == '2') {
        if (divisao_unidade === true) {
          conteudo.push([
            { text: 'DATA', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
            { text: 'EMPENHO', fontSize: 8, alignment: 'center', bold: true },
            { text: 'FICHA', fontSize: 8, alignment: 'center', bold: true },
            { text: 'OP', fontSize: 8, alignment: 'center', bold: true },
            { text: 'FAVORECIDO', fontSize: 8, alignment: 'center', bold: true },
            { text: 'CONTA BANCÁRIA', fontSize: 8, alignment: 'center', bold: true },
            { text: 'DOCUMENTO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
            { text: 'PROCESSADO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
            { text: 'NÃO PROCESSADO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
          ]);
        }

        if (divisao === true) {
          conteudo.push([
            { text: `Exercício ${grupo.grupo['exercicio.ano']}`, colSpan: 9, bold: true, fontSize: 12 },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
          ]);
        }

        if (divisao_unidade === true) {
          if (this.selectAgrupamento == '2') {
            let parametrosGrupo = {};
            let relations = 'unidade,unidade.orgao';
            parametrosGrupo['relations'] = relations;
            parametrosGrupo['unidade.codigo'] = grupo.grupo['liquidacao.empenho.unidade'];
            parametrosGrupo['unidade.orgao.id'] = this.login.orgao.id;

            let unidade = await this.executoraService.filtrar(1, -1, parametrosGrupo).toPromise();
            let dados;
            for (let item of unidade.content) {
              dados = item.nome;
            }

            conteudo.push([
              { text: `Unidade: ${grupo.grupo['liquidacao.empenho.unidade']} ${dados}`, colSpan: 9, bold: true, fontSize: 12, fillColor: '#dee2e6' },
              { text: `` },
              { text: `` },
              { text: `` },
              { text: `` },
              { text: `` },
              { text: `` },
              { text: `` },
              { text: `` },
            ]);
          }
        }

        // if (this.selectAgrupamento == '2') {
        //   conteudo.push([
        //     { text: `${grupo.grupo['liquidacao.empenho.unidade']} ${grupo.grupo['liquidacao.empenho.unidade_executora']}`, colSpan: 8, bold: true, fontSize: 10 },
        //     { text: `` },
        //     { text: `` },
        //     { text: `` },
        //     { text: `` },
        //     { text: `` },
        //     { text: `` },
        //     { text: `` }
        //   ])
        // }

      } else if (this.selectAgrupamento == '3') {
        if (divisao_recurso === true) {
          conteudo.push([
            { text: 'DATA', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
            { text: 'EMPENHO', fontSize: 8, alignment: 'center', bold: true },
            { text: 'FICHA', fontSize: 8, alignment: 'center', bold: true },
            { text: 'OP', fontSize: 8, alignment: 'center', bold: true },
            { text: 'FAVORECIDO', fontSize: 8, alignment: 'center', bold: true },
            { text: 'CONTA BANCÁRIA', fontSize: 8, alignment: 'center', bold: true },
            { text: 'DOCUMENTO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
            { text: 'PROCESSADO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
            { text: 'NÃO PROCESSADO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
          ]);
        }

        if (divisao === true) {
          conteudo.push([
            { text: `Exercício ${grupo.grupo['exercicio.ano']}`, colSpan: 9, bold: true, fontSize: 12 },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
          ]);
        }

        if (divisao_recurso === true || divisao === true) {
          if (this.selectAgrupamento == '3') {
            let parametrosGrupo = {};
            let relations = 'aplicacao';
            parametrosGrupo['relations'] = relations;
            parametrosGrupo['codigo'] = grupo.grupo['liquidacao.empenho.aplicacao'];

            let aplicacao_nome = await this.recursoService.filtrar(1, -1, parametrosGrupo).toPromise();
            let dados;
            for (let item of aplicacao_nome.content) {
              dados = item.nome;
            }

            conteudo.push([
              { text: `${grupo.grupo['liquidacao.empenho.recurso']} ${grupo.grupo['liquidacao.empenho.aplicacao']} ${dados}`, colSpan: 9, bold: true, fontSize: 12, fillColor: '#dee2e6' },
              { text: `` },
              { text: `` },
              { text: `` },
              { text: `` },
              { text: `` },
              { text: `` },
              { text: `` },
              { text: `` },
            ]);
          }
        }

        // if (this.selectAgrupamento == '3') {
        //   conteudo.push([
        //     { text: `${grupo.grupo['liquidacao.empenho.recurso']} ${grupo.grupo['liquidacao.empenho.aplicacao']} ${grupo.grupo['liquidacao.empenho.recurso_variavel'] ? grupo.grupo['liquidacao.empenho.recurso_variavel'] : '0000'}`, colSpan: 8, bold: true, fontSize: 10 },
        //     { text: `` },
        //     { text: `` },
        //     { text: `` },
        //     { text: `` },
        //     { text: `` },
        //     { text: `` },
        //     { text: `` }
        //   ])
        // }

      }

      for (const dados of grupo.registros) {
        let op_numero;
        for (let op of dados.liquidacao.ordem_item) {
          op_numero = op.ordem.numero;
        }

        const registro = [];
        registro.push({ text: this.funcaoService.converteDataBR(dados.data_pagamento), fontSize: 8, alignment: 'center', bold: false });
        registro.push({
          text: `${dados.liquidacao.empenho.numero}`,
          fontSize: 8, alignment: 'center', bold: false
        });
        registro.push({
          text: `${dados?.ficha_empenho}`,
          fontSize: 8, alignment: 'center', bold: false
        });
        registro.push({
          text: `${op_numero ? op_numero : ''}`,
          fontSize: 8, alignment: 'center', bold: false
        });
        registro.push({
          text: `${dados.liquidacao.empenho.favorecido.nome}`,
          fontSize: 8, alignment: 'left', bold: false
        });
        registro.push({
          text: `${dados.conta.nome} ${dados.conta.numero_conta} ${dados.conta.codigo}`,
          fontSize: 8, alignment: 'left', bold: false
        });
        registro.push({ text: dados.documento, fontSize: 8, alignment: 'left', bold: false });
        registro.push({ text: this.funcaoService.convertToBrNumber(dados.valor_pago), fontSize: 8, alignment: 'right', bold: false });
        registro.push({ text: this.funcaoService.convertToBrNumber(dados.valor_retido), fontSize: 8, alignment: 'right', bold: false });
        conteudo.push(registro)
      }
      const totais = this.funcaoService.totalizar(grupo.registros, ['valor_pago', 'valor_retido']);
      const total = [];
      total.push({ text: 'TOTAL', fontSize: 8, alignment: 'left', bold: true, colSpan: 6 });
      total.push('');
      total.push('');
      total.push('');
      total.push('');
      total.push('');
      total.push('');
      total.push({ text: this.funcaoService.convertToBrNumber(totais['valor_pago']), fontSize: 8, alignment: 'right', bold: true });
      total.push({ text: this.funcaoService.convertToBrNumber(totais['valor_retido']), fontSize: 8, alignment: 'right', bold: true });
      conteudo.push(total);
      retorno.push({
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['auto', 'auto', 'auto', 'auto', '*', '*', 'auto', 'auto', 'auto'],
          body: conteudo
        }, margin: [0, 0, 0, 15]
      });
    }

    const conteudo = [];
    const totais = this.funcaoService.totalizar(lista, ['valor_pago', 'valor_retido']);
    const total = [];
    total.push({ text: 'TOTAL GERAL', fontSize: 8, alignment: 'left', bold: true, colSpan: 6 });
    total.push('');
    total.push('');
    total.push('');
    total.push('');
    total.push('');
    total.push('');
    total.push({ text: this.funcaoService.convertToBrNumber(totais['valor_pago']), fontSize: 8, alignment: 'right', bold: true });
    total.push({ text: this.funcaoService.convertToBrNumber(totais['valor_retido']), fontSize: 8, alignment: 'right', bold: true });
    conteudo.push(total);
    retorno.push({
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['auto', 'auto', 'auto', 'auto', '*', '*', 'auto', 'auto', 'auto'],
        body: conteudo
      }, margin: [0, 0, 0, 15]
    });

    return retorno;
  }

  private async montarConteudoCSV(lista: any[]): Promise<{}[]> {
    let grupos;
    if (this.selectAgrupamento == '1') {
      // agrupar por exercicio
      grupos = this.funcaoService.agrupar(lista, 'exercicio.ano')
    } else if (this.selectAgrupamento == '2') {
      // agrupar por unidade
      grupos = this.funcaoService.agrupar(lista, ['exercicio.ano', 'liquidacao.empenho.unidade'])
    } else if (this.selectAgrupamento == '3') {
      // agrupar por recurso
      grupos = this.funcaoService.agrupar(lista, ['exercicio.ano', 'liquidacao.empenho.recurso', 'liquidacao.empenho.aplicacao'])
    }

    let retorno: {}[] = [];
    // retorno.push({
    //   'data': `REFERÊNCIA: ${this.datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} a ${this.datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
    //   'empenho': '',
    //   'ficha': '',
    //   'op': '',
    //   'favorecido': '',
    //   'conta': '',
    //   'documento': '',
    //   'processado': '',
    //   'nao_processado': ''
    // });

    let data = []; let data_unidade = []; let data_recurso = [];
    for (let grupo of grupos) {
      const conteudo = [];

      let divisao = true; let divisao_unidade = true; let divisao_recurso = true;
      let rept: number; let rept_unidade: number; let rept_recurso: number;

      if (data != undefined)
        rept = data.find((i) => i == grupo.grupo['exercicio.ano']);

      if (data_unidade != undefined)
        rept_unidade = data_unidade.find((i) => i == grupo.grupo['exercicio.ano'] + grupo.grupo['liquidacao.empenho.unidade']);

      if (data_recurso != undefined)
        rept_recurso = data_recurso.find((i) => i == grupo.grupo['exercicio.ano'] + grupo.grupo['liquidacao.empenho.recurso'] + grupo.grupo['liquidacao.empenho.aplicacao']);

      // exercicio
      if (!rept) {
        data.push(grupo.grupo['exercicio.ano']);
      } else {
        divisao = false;
      }

      // unidade
      if (!rept_unidade) {
        data_unidade.push(grupo.grupo['exercicio.ano'] + grupo.grupo['liquidacao.empenho.unidade']);
      } else {
        divisao_unidade = false;
      }

      // recurso
      if (!rept_recurso) {
        data_recurso.push(grupo.grupo['exercicio.ano'] + grupo.grupo['liquidacao.empenho.recurso'] + grupo.grupo['liquidacao.empenho.aplicacao']);
      } else {
        divisao_recurso = false;
      }

      if (this.selectAgrupamento == '1') {
        if (this.selectAgrupamento == '1') {
          retorno.push({
            'data': `Exercício ${grupo.grupo}`,
            'empenho': '',
            'ficha': '',
            'op': '',
            'favorecido': '',
            'conta': '',
            'documento': '',
            'processado': '',
            'nao_processado': ''
          });
        }

      } else if (this.selectAgrupamento == '2') {
        if (divisao === true) {
          retorno.push({
            'data': `Exercício ${grupo.grupo['exercicio.ano']}`,
            'empenho': '',
            'ficha': '',
            'op': '',
            'favorecido': '',
            'conta': '',
            'documento': '',
            'processado': '',
            'nao_processado': ''
          });
        }

        if (divisao_unidade === true) {
          if (this.selectAgrupamento == '2') {
            let parametrosGrupo = {};
            let relations = 'unidade,unidade.orgao';
            parametrosGrupo['relations'] = relations;
            parametrosGrupo['unidade.codigo'] = grupo.grupo['liquidacao.empenho.unidade'];
            parametrosGrupo['unidade.orgao.id'] = this.login.orgao.id;

            let unidade = await this.executoraService.filtrar(1, -1, parametrosGrupo).toPromise();
            let dados;
            for (let item of unidade.content) {
              dados = item.nome;
            }
            retorno.push({
              'data': `Unidade: ${grupo.grupo['liquidacao.empenho.unidade']} ${dados}`,
              'empenho': '',
              'ficha': '',
              'op': '',
              'favorecido': '',
              'conta': '',
              'documento': '',
              'processado': '',
              'nao_processado': ''
            });
          }
        }

      } else if (this.selectAgrupamento == '3') {
        if (divisao === true) {
          retorno.push({
            'data': `Exercício ${grupo.grupo['exercicio.ano']}`,
            'empenho': '',
            'ficha': '',
            'op': '',
            'favorecido': '',
            'conta': '',
            'documento': '',
            'processado': '',
            'nao_processado': ''
          });
        }

        if (divisao_recurso === true || divisao === true) {
          if (this.selectAgrupamento == '3') {
            let parametrosGrupo = {};
            let relations = 'aplicacao';
            parametrosGrupo['relations'] = relations;
            parametrosGrupo['codigo'] = grupo.grupo['liquidacao.empenho.aplicacao'];

            let aplicacao_nome = await this.recursoService.filtrar(1, -1, parametrosGrupo).toPromise();
            let dados;
            for (let item of aplicacao_nome.content) {
              dados = item.nome;
            }
            retorno.push({
              'data': `${grupo.grupo['liquidacao.empenho.recurso']} ${grupo.grupo['liquidacao.empenho.aplicacao']} ${dados}`,
              'empenho': '',
              'ficha': '',
              'op': '',
              'favorecido': '',
              'conta': '',
              'documento': '',
              'processado': '',
              'nao_processado': ''
            });
          }
        }

      }

      for (const dados of grupo.registros) {
        let op_numero;
        for (let op of dados.liquidacao.ordem_item) {
          op_numero = op.ordem.numero;
        }
        retorno.push({
          'data': `${this.funcaoService.converteDataBR(dados.data_pagamento)}`,
          'empenho': `${dados.liquidacao.empenho.numero}`,
          'ficha': `${dados?.ficha_empenho}`,
          'op': `${op_numero ? op_numero : ''}`,
          'favorecido': `${dados.liquidacao.empenho.favorecido.nome}`,
          'conta': `${dados.conta.nome} ${dados.conta.numero_conta} ${dados.conta.codigo}`,
          'documento': dados.documento,
          'processado': this.funcaoService.convertToBrNumber(dados.valor_pago),
          'nao_processado': this.funcaoService.convertToBrNumber(dados.valor_retido),
        });
      }
      const totais = this.funcaoService.totalizar(grupo.registros, ['valor_pago', 'valor_retido']);
      retorno.push({
        'data': `TOTAL`,
        'empenho': '',
        'ficha': '',
        'op': '',
        'favorecido': '',
        'conta': '',
        'documento': '',
        'processado': this.funcaoService.convertToBrNumber(totais['valor_pago']),
        'nao_processado': this.funcaoService.convertToBrNumber(totais['valor_retido'])
      });
    }
    const totais = this.funcaoService.totalizar(lista, ['valor_pago', 'valor_retido']);
    retorno.push({
      'data': `TOTAL`,
      'empenho': '',
      'ficha': '',
      'op': '',
      'favorecido': '',
      'conta': '',
      'documento': '',
      'processado': this.funcaoService.convertToBrNumber(totais['valor_pago']),
      'nao_processado': this.funcaoService.convertToBrNumber(totais['valor_retido'])
    });
    return retorno;
  }

  private colunasCSV(): Coluna[] {
    return [
      { titulo: 'DATA', coluna: 'data' },
      { titulo: 'EMPENHO', coluna: 'empenho' },
      { titulo: 'FICHA', coluna: 'ficha' },
      { titulo: 'OP', coluna: 'op' },
      { titulo: 'FAVORECIDO', coluna: 'favorecido' },
      { titulo: 'CONTA BANCÁRIA', coluna: 'conta' },
      { titulo: 'DOCUMENTO', coluna: 'documento' },
      { titulo: 'PROCESSADO', coluna: 'processado' },
      { titulo: 'NÃO PROCESSADO', coluna: 'nao_processado' },
    ];
  }
}
