import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Precatorio } from 'eddydata-lib';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PrecatorioService extends BaseResourceService<Precatorio> {

  constructor(
    protected injector: Injector
  ) {
    super('precatorios', injector);
  }

  public totalPago(precatorioId: number) {
    return this.http.get(
      `${this.login.cidade.id}/${this.api}/total-pago/${precatorioId}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public relatorioPrecatorio(exercicio: number, orgaoId: number, tipo_pagamento: string, tipo_precatorio: string, semVinculo: string, dataInicio: string, dataFinal: string) {
    let params = '';

    if (tipo_pagamento)
      params += `${params && params.length > 0 ? '&' : '?'}pagamento=${tipo_pagamento}`;
    if (tipo_precatorio)
      params += `${params && params.length > 0 ? '&' : '?'}precatorio=${tipo_precatorio}`;
    if (semVinculo)
      params += `${params && params.length > 0 ? '&' : '?'}sem_vinculo=${semVinculo}`;
    if (dataInicio)
      params += `${params && params.length > 0 ? '&' : '?'}data_inicio=${dataInicio}`;
    if (dataFinal)
      params += `${params && params.length > 0 ? '&' : '?'}data_final=${dataFinal}`;

    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/relatorio-precatorio/${exercicio}/${orgaoId}${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }
}
