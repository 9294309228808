import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Cidade } from '../../entidade/geo/cidade.model';
import { BaseResourceService } from '../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class CidadeService extends BaseResourceService<Cidade> {

  constructor(
    protected injector: Injector
  ) {
    super(`cidades`, injector);
  }

  public obterPorNome(nome: string): Observable<Cidade> {
    return this.http.get<Cidade>(
      `${this.api}/filtrar?nome$like=${nome}%`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
