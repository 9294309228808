import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseResourceService } from '../../models/services/base-resource.service';
import { UsuarioAcesso } from '../../entidade/comum/usuario-acesso.model';
import { Usuario } from '../../entidade/comum/usuario.model';
import { Sistemas } from '../../components/types';

@Injectable({
  providedIn: 'root'
})
export class UsuarioAcessoService extends BaseResourceService<UsuarioAcesso> {

  constructor(
    protected injector: Injector
  ) {
    super(`usuario-acessos`, injector);
  }

  public salvarLogoff(usuario: Usuario, sistema: Sistemas): Observable<UsuarioAcesso> {
    return this.http.post(`${this.api}/logoff`, { usuario, sistema }).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
