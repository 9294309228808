import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Rcms, RcmsFavorecido } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RcmsService extends BaseResourceService<Rcms> {

  constructor(
    protected injector: Injector
  ) {
    super(`rcms`, injector);
  }

  public buscaRCMSAlmoxarifado(exercicioId: number, orgaoId: number, numero: number) {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/busca-rcms-almoxarifado/${exercicioId}/${orgaoId}/${numero}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public proximoNumeroOF(exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-numero/${exercicio}/${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public desbloquearRcms(rcms: number, justificativa: string): Observable<any> {
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/desbloquear/${rcms}`, { justificativa },
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public autorizarRcms(rcms: number): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/autorizar/${rcms}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public autorizarRcmsLote(rcmss: number[], autorizado: boolean[]): Observable<any> {
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/autorizar/lote`, { rcmss, autorizado }, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterItensPorContrato(contrato: number, apenasPendentes: boolean, produtoUnidade?: number) {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/itens-por-contrato/${contrato}/${apenasPendentes ? 'S' : 'N'}/${produtoUnidade}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public importarRcms(numeros: number | number[], exercicio: number, orgaoId: number, somenteVencedores?: 'S' | 'N', compra?: boolean): Observable<RcmsFavorecido[]> {
    if (!compra) compra = false;
      return this.http.post<number>(
        `${this.login.cidade.id}/${this.api}/importacao-rcms/${exercicio}/${orgaoId}${somenteVencedores ? `?somenteVencedores=${somenteVencedores}&compra=${compra}` : '?compra=${compra}'}`, { numeros },
        this.httpOptions()).pipe(
          map(res => res),
          catchError(err => this.handleError(err))
        );
  }

  public gerarLote(rcmss: Rcms[], exercicio: number, orgao: number): Observable<number> {
    return this.http.post<number>(
      `${this.login.cidade.id}/${this.api}/gerar-lote/${exercicio}/${orgao}`, { rcmss },
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public listagemRequisicaoCompraRpt(parametros: {}): Observable<any> {
    let params = this.converterParametrosStr(parametros);
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/listagem-requisicao-compra-rpt${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
