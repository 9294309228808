
import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { DateFormatPipe, EddyAutoComplete, FuncaoService, Login, Page, Produto, ProdutoUnidade, RequisicaoItemMovimento, RequisicaoMovimento, SaldoEstoqueService } from 'eddydata-lib';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ProdutoUnidadeService } from '../../produto/service/produto-unidade.service';
import { ProdutoService } from '../../produto/service/produto.service';
declare var $: any;

@Component({
  selector: 'app-troca-item-requisicao-dlg',
  templateUrl: './troca-item-requisicao-dlg.component.html'
})
export class TrocaItemRequisicaoDlgComponent implements OnInit, OnChanges, OnDestroy {

  protected unsubscribe: Subject<void> = new Subject();

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Input() item: RequisicaoItemMovimento;
  @Output() itemChange: EventEmitter<RequisicaoItemMovimento> = new EventEmitter();

  @Input() login: Login;
  @Input() requisicao: RequisicaoMovimento;

  public ptBR: any;

  public produtoAutoComplete: EddyAutoComplete<Produto>;

  public produto: ProdutoUnidade
  public listProdUn: ProdutoUnidade[];
  public listLoteVec: any[] = [];
  public listLoteVecFiltro: any[] = [];
  public itemAlteracao: RequisicaoItemMovimento;
  public teste: any

  public imaskValor = {
    mask: Number,
    scale: 5,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private router: Router,
    private produtoUnService: ProdutoUnidadeService,
    private saldoEstoqueService: SaldoEstoqueService,
    private produtoService: ProdutoService,
    public funcaoService: FuncaoService,
  ) { }

  @HostListener('window:keydown.control.p', ['$event'])
  public abrirConsultaProduto(event: KeyboardEvent) {
    event.preventDefault();
    $('#dialogProdutoUnBuscaTrocaItem').appendTo("body").modal('show');
  }

  public callbackProdutoUn(prodUn: ProdutoUnidade) {
    this.listProdUn.push(prodUn);
    this.itemAlteracao.produto_unidade.produto = prodUn.produto;
    this.itemAlteracao.produto_unidade = prodUn;
    this.carregarLotes();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item) {
      this.loadItem();
    }
  }

  public loadItem() {
    if (!this.item)
      return;
    this.itemAlteracao = Object.assign(new RequisicaoItemMovimento(), this.item);
    if (!this.itemAlteracao.produto_unidade_troca)
      this.itemAlteracao.produto_unidade_troca = this.itemAlteracao.produto_unidade;
    this.itemAlteracao.produto_unidade = new ProdutoUnidade();
    this.carregarUnidades();
    this.itemAlteracao.vencimento = new DateFormatPipe().transform(this.itemAlteracao.vencimento, []);
  }

  ngOnInit() {
    this.carregarAutoCompletes();
  }

  public salvar() {
    if (!this.itemAlteracao.produto_unidade || !this.itemAlteracao.produto_unidade.id) {
      toastr.warning('Informe o produto e a unidade!');
      return;
    }
    if (!this.itemAlteracao.qtd_requisitada || this.itemAlteracao.qtd_requisitada <= 0 || this.itemAlteracao.qtd_aceita <= 0) {
      toastr.warning('Informe a quantidade!');
      return;
    }
    if (this.itemAlteracao.produto_unidade_troca && !this.itemAlteracao.justificativa_troca) {
      toastr.warning('Informe a justificativa de troca!');
      return;
    }
    if (this.itemAlteracao.qtd_aceita > this.itemAlteracao.saldo_atual) {
      toastr.warning('Quantidade aceita maior que o saldo atual!');
      return;
    }
    this.itemChange.emit(this.itemAlteracao);
    this.onHide();
  }

  private carregarAutoCompletes() {
    if (!this.requisicao)
      return;

    let data_movimento = new Date()

    this.produtoAutoComplete = new EddyAutoComplete(null, this.produtoService,
      'id', ['codigo', 'nome'], {orgao_id: this.login.orgao.id, orderBy: 'codigo,nome' }, { number: ['id', 'codigo'], text: ['codigo', 'nome'] }, null, null, null,
      (pagina: number, limite: number, filtros: {}, filtroStr: string): Observable<Page> => {
        let parametros: { codigo?: string, nome?: string, data?: string } = { data: this.funcaoService.converteDataSQL(data_movimento) };
        if (this.funcaoService.isNumerico(filtroStr))
          parametros.codigo = filtroStr;
        else
          parametros.nome = filtroStr;
        return this.saldoEstoqueService
          .obterProdutosComSaldo(this.login.setorAlmoxarifado.id, parametros)
          .pipe(map(res => {
            let page = new Page();
            page.content = res;
            return page;
          }));
      }
    );
  }

  public carregarUnidades() {
    if (this.itemAlteracao) {
      this.itemAlteracao.lote = null;
      this.itemAlteracao.vencimento = null;
    }
    if (!this.itemAlteracao.produto_unidade.produto) {
      return;
    }
    this.produtoUnService.filtrarCodigoCompleto(1, -1, {
      produto_id: this.itemAlteracao.produto_unidade.produto.id,
      ativo: true,
      relations: 'produto.material,unidade',
      'itens.movimento.data_movimento$le': this.funcaoService.converteDataSQL(new Date()),
      orderBy: 'unidade.nome'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listProdUn = res ? res.content : [];
        if (this.listProdUn.length === 0) {
          toastr.warning('Nenhuma unidade ativa foi localizada para este produto!');
          return;
        }
        this.itemAlteracao.produto_unidade = this.listProdUn[0];
        this.carregarLotes();
      }, error => toastr.error(error.message ? error.message : error));
  }

  public changeUnidade() {
    this.itemAlteracao.vencimento = null;
    this.itemAlteracao.lote = null;
    this.carregarLotes();
  }

  public carregarLotes() {
    if (!this.itemAlteracao.produto_unidade || !this.itemAlteracao.produto_unidade.id)
      return;
    let parametros: any = { data_limite: this.funcaoService.converteDataSQL(new Date()) };
    parametros['desc_item'] = this.itemAlteracao.id
    this.saldoEstoqueService
      .saldoProdutoUnidadeLoteVencimento(this.itemAlteracao.produto_unidade.id, this.login.setorAlmoxarifado.id, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((saldos) => {
        if (!saldos || saldos.length === 0)
          return;
        this.listLoteVec = saldos;
        if (!this.itemAlteracao.lote || !this.itemAlteracao.vencimento) {
          let saldo = saldos[0];
          this.itemAlteracao.lote = saldo.lote;
          this.itemAlteracao.vencimento = saldo.vencimento;
          this.itemAlteracao['saldo_atual'] = saldo.saldo_atual
          // this.onChangeLote(saldo);
        } else {
          let itemL = saldos.find((s) => s.lote === this.itemAlteracao.lote
            && new Date(new Date(s.vencimento).toDateString()).getTime() === new Date(new Date(this.itemAlteracao.vencimento).toDateString()).getTime());
          this.itemAlteracao['saldo_atual'] = itemL.saldo_atual
          // this.onChangeLote(itemL);
        }
      });
  }

  onChangeLote(itemL) {
    this.itemAlteracao.lote = itemL.lote;
    this.itemAlteracao.vencimento = itemL.vencimento;
    this.itemAlteracao['saldo_atual'] = itemL.saldo_atual;
    this.itemAlteracao['saida'] = itemL.saida;
    //this.itemAlteracao.qtd_aceita = this.item.saldo_atual 
  }

  buscarLoteVencimento(event) {
    if (event.query)
      this.listLoteVecFiltro = this.listLoteVec.filter((l) => l.lote.toLowerCase().includes(event.query.toLowerCase()));
    else
      this.listLoteVecFiltro = Object.assign([], this.listLoteVec)
  }

  conversorLote(itemL) {
    if (!itemL || !itemL.lote || !itemL.vencimento)
      return '';
    return `${itemL.lote} - ${new FuncaoService().converteDataBR(itemL.vencimento)}`
  }

  public compareFnLoteVencimento(c1: any, c2: any): boolean {
    return c1 && c2 && c1.lote && c2.lote && c1.vencimento && c2.vencimento
      && c1.lote === c2.lote && new Date(c1.vencimento).toDateString() === new Date(c2.vencimento).toDateString();
  }

  public compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  public onHide() {
    this.loadItem();
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
