import { BaseResourceModel } from '../../models/base-resource.model';
import { Recurso } from '../planejamento/recurso.model';
import { Convenio } from './convenio.model';

export class ConvenioRecurso extends BaseResourceModel {
  constructor(
    public principal?: boolean,
    public ativo?: boolean,
    public audesp?: boolean,
    public convenio?: Convenio,
    public recurso?: Recurso,
    public aplicacao?: Recurso,
    public aplicacao_variavel?: Recurso,
    public recurso_contra?: Recurso,
    public aplicacao_contra?: Recurso,
    public aplicacao_variavel_contra?: Recurso,
    public editavel?: boolean
    ) {
    super();
  }
  static converteJson(json: any): ConvenioRecurso {
    return Object.assign(new ConvenioRecurso(), json);
  }
}
