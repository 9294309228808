import { Component, Injector } from '@angular/core';
import { BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, IndiceCorrecao, LoginContabil, Modalidade } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IndiceCorrecaoService } from '../services/indice-correcao.service';
import * as toastr from 'toastr';

@Component({
  selector: 'lib-indice-correcao-list',
  templateUrl: './indice-correcao-list.component.html'
})
export class IndiceCorrecaoListComponent extends BaseResourceListComponent<IndiceCorrecao, LoginContabil> {

  /**
   * Declaração de variáveis
   */


  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected indiceCorrecaoService: IndiceCorrecaoService) {
    super(indiceCorrecaoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return '';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['id'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['id', 'ano', 'mes', 'indice'],
      // text: ['nome', 'codigo']
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: Modalidade): Observable<Modalidade> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'ID', coluna: 'id', alignment: 'center' },
      { titulo: 'Mês', coluna: 'mes', alignment: 'center' },
      { titulo: 'Ano', coluna: 'ano', alignment: 'center' },
      { titulo: 'Valor', coluna: 'indice', decimais: 2, alignment: 'center' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    this.indiceCorrecaoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE INDICES DE CORREÇÃO `,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem de indices de correção', ['*', '*', '*', '*'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public retornar() {
    this.router.navigate([`/`]);
  }

  public novo() {
    this.router.navigate([`/indice-correcao/novo`]);
  }
}
