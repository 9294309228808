import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ModalAssinadorService {

    constructor(private http: HttpClient) { }

    getAlias(): Observable<any> {
        return this.http.get('http://localhost:4567/api/assinar/alias');
    }

    assinar(file: File, a: any, tipo: String): Promise<any> {
        let p = new Promise((resolve, reject) => {
            var formData = new FormData();

            formData.append("file", file);
            formData.append("dados", JSON.stringify(a));
            formData.append("v", '2');//Caso seja diferente da versão interna no assinador, retorna erro forçando baixar novamente o assinador

            var xhr = new XMLHttpRequest();
            xhr.open("POST", 'http://localhost:4567/api/assinar/' + (tipo == 'A3' ? 'verificado' : 'livre'));
            xhr.onload = function () {
                var response = JSON.parse(xhr.responseText);
                if (xhr.status == 200) {
                    resolve(response);
                } else {
                    reject(response);
                }
            }
            xhr.onerror = function () {
                reject('API do assinador não se encontra online');
            }

            xhr.send(formData);
        });

        return p;
    }

    assinadorOnline(): Observable<any> {
        return this.http.get('http://localhost:4567/api/assinar');
    }

}
