import { Component, ElementRef, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BaseResourceFormComponent, FuncaoService, GlobalService, LoginContabil, RequisicaoItemMovimento, RequisicaoMovimento, StatusRequisicao
} from 'eddydata-lib';
import { RequisicaoMovimentoService } from '../service/requisicao-movimento.service';
import { ComprovanteRequisicaoRpt } from '../../relatorio/comprovante-requisicao-rpt/comprovante-requisicao-rpt';

@Component({
  selector: 'lib-requisicao-view',
  templateUrl: './requisicao-view.component.html'
})
export class RequisicaoViewComponent extends BaseResourceFormComponent<RequisicaoMovimento, LoginContabil> {
  /**
   * Declaração de variáveis
   */

  public itens: RequisicaoItemMovimento[];
  public formato: any;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public globalService: GlobalService,
    public funcaoService: FuncaoService,
    protected requisicaoMovService: RequisicaoMovimentoService) {
    super(new RequisicaoMovimento(), injector, RequisicaoMovimento.converteJson, requisicaoMovService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      data_cadastro: [new Date()],
      status: [StatusRequisicao.AGUARDANDO, Validators.required],
      destinatario: [null],
      motivo_devolucao: [null],
      devolucao: [false, Validators.required],
      setor_almoxarifado: [null, Validators.required],
      usuario: [this.login.usuario],
      usuario_aceite: [this.login.usuario],
      data_retirada: [new Date()],
      exercicio: [this.login.exercicio],
      orgao: [this.login.orgao],
      itens: [null]
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: [
        'usuario', 'setor_almoxarifado', 'setor_almoxarifado.setor.unidade', 'setor_almoxarifado.estoque',
        'exercicio', 'orgao', 'usuario', 'recebedor', 'itens', 'itens.produto_unidade',
        'itens.produto_unidade.produto', 'itens.produto_unidade.unidade', 'itens.produto_unidade_troca',
        'itens.produto_unidade_troca.produto', 'itens.produto_unidade_troca.unidade', 'usuario_estorno', 'usuario_aceite'
      ]
    };
  }

  protected afterInit(): void { }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected async afterLoad() {
    this.itens = this.entidade.itens;
  }

  protected beforeSubmit() { }

  protected afterSubmit(ent: RequisicaoMovimento) { }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================


  public tituloStatusRequisicao(status: StatusRequisicao): string {
    return this.globalService.tituloStatusRequisicao(status);
  }

  public iconStatusRequisicao(status: StatusRequisicao): {} {
    let icon = this.globalService.iconStatusRequisicao(status);
    let ngIcon = {};
    ngIcon[icon] = true;
    return ngIcon;
  }

  public corStatusRequisicao(status: StatusRequisicao): {} {
    let cor = this.globalService.corStatusRequisicao(status);
    let ngCor = {};
    ngCor[`bg-${cor}`] = true;
    return ngCor;
  }

  public badgeStatusRequisicao(status: StatusRequisicao): {} {
    let cor = this.globalService.corStatusRequisicao(status);
    let ngCor = {};
    ngCor[`badge-${cor}`] = true;
    return ngCor;
  }

  public emitirComprovante() {
    new ComprovanteRequisicaoRpt(this.login, this.globalService).imprimir(this.entidade, this.formato);
  }

  public valorRequisitado() {
    if (!this.itens)
      return 0.0;
    let vl = 0.0;
    for (let it of this.itens) {
      if (+it.qtd_requisitada)
        vl += Number.parseFloat(it.qtd_requisitada.toString());
    }
    return vl;
  }

  public valorAceito() {
    if (!this.itens)
      return 0.0;
    let vl = 0.0;
    for (let it of this.itens) {
      if (it.qtd_aceita)
        vl += Number.parseFloat(it.qtd_aceita.toString());
    }
    return vl;
  }

  public valorRecusado() {
    if (!this.itens)
      return 0.0;
    let vl = 0.0;
    for (let it of this.itens) {
      if (it.status && it.status !== StatusRequisicao.AGUARDANDO)
        vl += Number.parseFloat(it.qtd_requisitada.toString()) - Number.parseFloat(it.qtd_aceita.toString());
    }
    return vl;
  }

}
