import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DespesaService } from "administrativo-lib";
import { BaseResourceRptComponent, Coluna, EddyAutoComplete, FavorecidoService, FuncaoService, GlobalService, LoginContabil, PessoaService, ProgressoService, Relatorio, SetorService, UnidadeService, UsuarioService } from "eddydata-lib";
import { MenuItem } from "primeng/api";
import { takeUntil } from "rxjs/operators";
import { RcmsService } from "../../rcms/service/rcms.service";

@Component({
  selector: 'lib-rcms-rpt',
  templateUrl: './rcms-rpt.component.html'
})
export class RcmsRptComponent extends BaseResourceRptComponent {
  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================
  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  private loginContabil: LoginContabil;
  public ordernacao: { nome: string, coluna: string, ordem: 'DESC' | 'ASC' };
  public listaOrdenacao: { nome: string, coluna: string, ordem: 'DESC' | 'ASC' }[] = [];
  public opcoes: 'P' | 'A' | 'E' = 'P';
  public listaFiltros: Coluna[] = [];
  public menuFiltros: MenuItem[] = [];
  public parametros: { session?: any, filtros?: any } = {};
  protected datepipe: DatePipe;

  constructor(
    private rcmsService: RcmsService,
    private pessoaService: PessoaService,
    private setorService: SetorService,
    private usuarioService: UsuarioService,
    private unidadeService: UnidadeService,
    private favorecidoService: FavorecidoService,
    protected progressoService: ProgressoService,
    private despesaService: DespesaService
  ) {
    super();
  }

  protected afterInit(): void {
    this.datepipe = new DatePipe('pt');
    this.loginContabil = GlobalService.obterSessaoLogin();
    this.orientacao = "landscape";
    this.listaOrdenacao = this.obterColunasOrdenacoes();
    this.ordernacao = this.listaOrdenacao[0];
  }

  protected tituloRelatorio(): string {
    return `Listagem de Requisições de compra`;
  }

  public obterColunasOrdenacoes(): { nome: string, coluna: string, ordem: 'DESC' | 'ASC' }[] {
    return [
      { coluna: 'numero', nome: 'Número', ordem: 'ASC' },
      { coluna: 'data_rcms', nome: 'Data Requisição', ordem: 'ASC' },
      { coluna: 'requerente.nome', nome: 'Requerente', ordem: 'ASC' },
    ];
  }

  protected obterColunasRelatorio(filtro?: boolean): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Número', coluna: 'numero', alignment: 'center', tipo: 'Number', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Data Requisição', coluna: 'data_rcms', tipo: 'Date', alignment: 'center', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Ficha', coluna: 'ficha.numero', alignment: 'center', tipo: 'String', cols: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Despesa', coluna: 'ficha.despesa.codigo', alignment: 'center', tipo: 'String', cols: 6, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Requerente', coluna: 'requerente.nome', tipo: 'String', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Setor', coluna: 'setor.nome', tipo: 'String', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Contrato', coluna: 'contrato.numero', alignment: 'center', tipo: 'String', cols: 6, mask: '0000/0000', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Licitação', coluna: 'licitacao.numero', alignment: 'center', tipo: 'String', cols: 6, mask: '00000/0000', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Processo', coluna: 'processo', alignment: 'center', tipo: 'String', cols: 6, mask: '0000/0000', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Menor Preço Global', coluna: 'menor_preco_global', alignment: 'right', tipo: 'Number', decimais: 2, adicionaisBody: { border: [false, false, false, false] } });

    return retorno;
  }
  
  protected carregarLista(): Promise<any[]> {
    throw new Error("Method not implemented.");
  }

  public obterColunasfiltroPersonalizado(): Coluna[] {
    let retorno: Coluna[] = [];
    retorno.push({ titulo: 'Número', coluna: 'numero', alignment: 'center', tipo: 'Number' });
    retorno.push({ titulo: 'Data Requisição', coluna: 'data_rcms', tipo: 'Date', alignment: 'center', cols: 12 });
    retorno.push({ titulo: 'Ficha', coluna: 'ficha.numero', alignment: 'center', tipo: 'Number' });
    retorno.push({
      titulo: 'Despesa', coluna: 'ficha.despesa.codigo', alignment: 'center', tipo: 'AutoComplete', autocomplete: new EddyAutoComplete(null, this.despesaService,
        'codigo', ['codigo', 'nome'], { 'exercicio.id': this.login.exercicio.id, orderBy: 'codigo', nivel: 4 }, { number: ['codigo'], text: ['nome'] }
      ), cols: 12,
    });
    retorno.push({
      titulo: 'Requerente', coluna: 'requerente.id', tipo: 'AutoComplete', autocomplete: new EddyAutoComplete(null, this.pessoaService,
        'id', ['nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
      ), cols: 12
    });
    retorno.push({
      titulo: 'Setor', coluna: 'setor.id', tipo: 'AutoComplete', autocomplete: new EddyAutoComplete(null, this.setorService,
        'id', ['codigo', 'nome'], { orgao_id: this.login.orgao.id, orderBy: 'nome' }, { text: ['nome', 'codigo'] }
      ), cols: 12
    });
    retorno.push({ titulo: 'Contrato', coluna: 'contrato.numero', alignment: 'center', tipo: 'String', cols: 6, mask: '0000/0000' });
    retorno.push({ titulo: 'Licitação', coluna: 'licitacao.numero', alignment: 'center', tipo: 'String', cols: 6, mask: '00000/0000' });
    retorno.push({ titulo: 'Processo', coluna: 'processo', alignment: 'center', tipo: 'String', cols: 6 });
    retorno.push({
      titulo: 'Usuário', coluna: 'operador.id', tipo: 'AutoComplete', autocomplete: new EddyAutoComplete(null, this.usuarioService,
        'id', ['id', 'nome'], { orderBy: 'nome' }, { number: ['id'], text: ['nome'] }), cols: 12
    });
    retorno.push({
      titulo: 'Secretaria', coluna: 'setor.unidade.id', tipo: 'AutoComplete', autocomplete: new EddyAutoComplete(null, this.unidadeService,
        'id', ['id', 'nome'], { orderBy: 'nome', 'ppa.id': this.loginContabil.ppa.id }, { number: ['id'], text: ['nome'] }), cols: 12
    });
    retorno.push({
      titulo: 'Fornecedor', coluna: 'favorecidos.favorecido.id', tipo: 'AutoComplete', autocomplete: new EddyAutoComplete(null, this.favorecidoService,
        'id', ['cpf_cnpj', 'nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }), cols: 12
    });
    retorno.push({
      titulo: 'Subelemento', coluna: 'subelemento.codigo', alignment: 'center', tipo: 'AutoComplete', autocomplete: new EddyAutoComplete(null, this.despesaService,
        'codigo', ['codigo', 'nome'], { 'exercicio.id': this.login.exercicio.id, orderBy: 'codigo', nivel: 6 }, { number: ['codigo'], text: ['nome'] }
      ), cols: 12,
    });
    retorno.push({ titulo: 'Compra Direta', coluna: 'contrato.id$null,licitacao.id$null,processo$null,', tipo: 'Boolean' });

    return retorno;
  }

  protected larguraColunas(): (string | number)[] {
    return ['auto', 'auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', '*'];
  }

  protected totalizarColunas(): (string | {})[] {
    return ['menor_preco_global'];
  }

  public filtroTipo(filtro: Coluna[], tipo: 'String' | 'Number' | 'Date' | 'Boolean' | 'HTML') {
    return filtro.filter((f) => f.tipo === tipo);
  }

  protected layout(): {} {
    return {
      hLineWidth() {
        return 1;
      },
      vLineWidth(i, node) {
        return 1;
      },
      hLineColor(i) {
        return i === 1 || i === 0 ? 'black' : '0aaa';
      },
      paddingLeft(i) {
        return 2;
      },
      paddingRight(i, node) {
        return 2;
      }
    }
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public imprimirRelatorio() {
    let parametros = {
      'orderBy': `${this.ordernacao.coluna}\$${this.ordernacao.ordem}`,
      'orgao.id': this.login.orgao.id,
      'exercicio': this.login.exercicio.id,
      'ignoreCondObrig': true,
      relations: ['ficha', 'ficha.despesa', 'requerente', 'contrato', 'licitacao',
        'itens', 'itens.produto_unidade', 'itens.produto_unidade.produto', 'itens.produto_unidade.unidade', 'setor', 'operador']
    };

    if (this.parametros.filtros['data_rcms$ge']) {
      delete parametros['exercicio']
    }

    if (this.parametros?.filtros) {
      parametros = Object.assign(parametros, this.parametros.filtros);
    }

    parametros['excluido'] = this.opcoes === 'E';

    if (this.opcoes === 'A') {
      parametros['compras.numero$null'] = true;
    }
    
    this.rcmsService.listagemRequisicaoCompraRpt(parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        let dados
        this.progressoService.show(res, async (retorno) => {
          dados = retorno
           let lista = dados.content.map((c) => {
              if (c.contrato)
                c.contrato.numero = this.funcaoService.mascarar('0000/0000', c.contrato.numero);
              if (c.licitacao)
                c.licitacao.numero = this.funcaoService.mascarar('0000/0000', c.licitacao.numero);
              return c;
            })
          const colunasRelatorio = this.obterColunasRelatorio();   
          if (this.formato === 'pdf') {
            Relatorio.imprimir(this.subTituloRelatorio() ? this.subTituloRelatorio() : this.tituloRelatorio(),
              this.login.usuario.nome, this.login.usuario.sobrenome, this.subTituloRelatorio() ? this.tituloRelatorio() : this.login.orgao.nome, this.login.brasao,
              lista, colunasRelatorio, this.orientacao, this.tituloRelatorio(),
              this.larguraColunas(), (this.totalizarColunas() ? this.totalizarColunas() : undefined),
              this.conteudoAdicional(lista), this.layout()
            );
          } else {
            new FuncaoService().exportar(this.formato, lista, this.tituloRelatorio(), colunasRelatorio);
          }
        })
      }, error => {console.log(error)})
  }
}