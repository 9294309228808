import { Component, Injector } from '@angular/core';
import {
  BaseResourceListComponent,
  Coluna, Empenho,
  Filtro, LoginContabil
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { tsXLXS } from 'ts-xlsx-export';
import { EmpenhoService } from 'administrativo-lib';

@Component({
  selector: 'app-empenho-funcao-list',
  templateUrl: './empenho-funcao-list.component.html'
})
export class EmpenhoFuncaoListComponent extends BaseResourceListComponent<Empenho, LoginContabil> {

  public listaFuncao: Array<
    {
      codigo: '',
      nome: '',
      empenhado: 0
    }>;

  constructor(
    protected injector: Injector,
    private empenhoService: EmpenhoService) {
    super(empenhoService, injector);
  }

  public preencherGrid() {
    this.empenhoService.totalEmpenhadoFuncao(
      this.login.exercicio.id, this.login.orgao.id, this.login.cidade.id, 9999)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaFuncao = res;
      });
  }

  protected condicoesGrid(): {} {
    return {};
  }
  protected ordenacaoGrid(): string[] {
    return [];
  }
  protected filtrosGrid(): Filtro {
    return null;
  }
  protected afterInit(): void {
  }
  protected acaoRemover(model: Empenho): Observable<Empenho> {
    return null;
  }

  protected relations(): string {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'codigo' },
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'Valor', coluna: 'empenhado' }
    ];
  }

  public imprimirPDF(): void {
    this.imprimir('DESPESA EMPENHADA POR FUNÇÃO DE GOVERNO',
      this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
      'Listagem empenhada por funcao', ['auto', '*', 'auto'], this.listaFuncao);
  }

  public exportarXLSX() {
    tsXLXS().exportAsExcelFile(this.listaFuncao).saveAsExcelFile('empenho-funcao-governo');
  }
}
