import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { ProdutoService, SubGrupoEstoqueService } from "almoxarifado-lib";
import { BaseResourceRptComponent, Coluna, EddyAutoComplete, FavorecidoService, GlobalService, LoginContabil } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";
import { CompraItemService } from "../../compra/service/compra-item.service";
import { ModalidadeService } from "../../modalidade/service/modalidade.service";
import * as toastr from 'toastr';

@Component({
  selector: 'lib-banco-preco-compra-rpt',
  templateUrl: './banco-preco-compra-rpt.component.html'
})
export class BancoPrecoCompraRpt extends BaseResourceRptComponent {

  private loginContail: LoginContabil;

  public ordernacao: { nome: string, coluna: string, ordem: 'DESC' | 'ASC' };
  public listaOrdenacao: { nome: string, coluna: string, ordem: 'DESC' | 'ASC' }[] = [];

  public listaFiltros: any

  public tipo: 'S' | 'A' = 'S';
  public parametros: { session?: any, filtros?: any } = {};

  protected datepipe: DatePipe;

  public listFormatos = ['pdf', 'xlsx'];

  public indiceAcumulado: string;

  public dataInicial: string;
  public dataFinal: string;

  constructor(
    private favorecidoService: FavorecidoService,
    private compraItemService: CompraItemService,
    private subGrupoService: SubGrupoEstoqueService,
    private modalidadeService: ModalidadeService,
    private produtoService: ProdutoService
  ) {
    super();
  }

  protected afterInit(): void {
    this.datepipe = new DatePipe('pt');
    this.orientacao = "landscape";
    this.listaFiltros = this.obterColunasfiltroPersonalizado();
    this.listaOrdenacao = this.obterColunasOrdenacoes();
    this.ordernacao = this.listaOrdenacao[0];
    this.loginContail = GlobalService.obterSessaoLogin();
  }

  protected tituloRelatorio(): string {
    return this.login.orgao.nome;
  }

  protected subTituloRelatorio(): string {
    return `BANCO DE PREÇOS - Período: ${this.datepipe.transform(this.dataInicial, 'MM/yyyy')} à ${this.datepipe.transform(this.dataFinal, 'MM/yyyy')}
    Índice acumulado: ${this.indiceAcumulado}%`
  };

  public obterColunasOrdenacoes(): { nome: string, coluna: string, ordem: 'DESC' | 'ASC' }[] {
    if (this.tipo === 'A') {
      return [
        { coluna: 'p.nome', nome: 'Nome Produto', ordem: 'ASC' },
        { coluna: 'p.codigo', nome: 'Código produto', ordem: 'ASC' },
        { coluna: 'f.nome', nome: 'Fornecedor', ordem: 'ASC' },
        { coluna: 'md.nome', nome: 'Modalidade', ordem: 'ASC' },
        { coluna: 'c.data_compra', nome: 'Data compra', ordem: 'ASC' },
      ];
    } else {
      return [
        { coluna: 'p.nome', nome: 'Nome Produto', ordem: 'ASC' },
        { coluna: 'p.codigo', nome: 'Código produto', ordem: 'ASC' },
      ];
    }
  }

  public atualizaOrdenacao() {
    this.listaOrdenacao = this.obterColunasOrdenacoes();
    this.ordernacao = this.listaOrdenacao[0];
    this.listaFiltros = this.obterColunasfiltroPersonalizado();
  }

  protected obterColunasRelatorio(filtro?: boolean): Coluna[] {
    let retorno: Coluna[] = [];
    if (this.tipo === 'S') {
      retorno.push({ titulo: 'Código', coluna: 'codigo', alignment: 'center' });
      retorno.push({ titulo: 'Descrição', coluna: 'descricao', alignment: 'left' });
      retorno.push({ titulo: 'Unidade', coluna: 'unidade', tipo: 'String', alignment: 'center' });
      retorno.push({ titulo: 'Valor médio', coluna: 'valor_medio', alignment: 'right' });
      retorno.push({ titulo: 'Valor referencia', coluna: 'valor_referencia', alignment: 'right' });
      retorno = retorno.map((r) => Object.assign(r, { adicionaisBody: { border: [false, false, false, false] } }));
      retorno = retorno.map((r) => Object.assign(r, { adicionaisHeader: { margin: [0, 3, 0, 3] } }));
    } else {
      retorno = this.obterColunasAnalitico();
    }
    return retorno;
  }

  private obterColunasAnalitico(): Coluna[] {
    let retorno: Coluna[] = [];
    retorno.push({ titulo: 'Modalidade', coluna: 'modalidade', alignment: 'center' });
    retorno.push({ titulo: 'Data compra', coluna: 'data_compra', alignment: 'center' });
    retorno.push({ titulo: 'Nº compra', coluna: 'numero_compra', alignment: 'center' });
    retorno.push({ titulo: 'Código', coluna: 'codigo', alignment: 'center' });
    retorno.push({ titulo: 'Descrição', coluna: 'descricao', alignment: 'left' });
    retorno.push({ titulo: 'Unidade', coluna: 'unidade', tipo: 'String', alignment: 'center' });
    retorno.push({ titulo: 'Quantidade', coluna: 'quantidade', tipo: 'String', alignment: 'center' });
    retorno.push({ titulo: 'Valor unitário', coluna: 'valor_unitario', alignment: 'right' });
    retorno.push({ titulo: 'Valor referencia', coluna: 'valor_referencia', alignment: 'right' });
    retorno.push({ titulo: 'Favorecido', coluna: 'favorecido', alignment: 'center' });
    retorno = retorno.map((r) => Object.assign(r, { adicionaisBody: { border: [false, false, false, false] } }));
    retorno = retorno.map((r) => Object.assign(r, { adicionaisHeader: { margin: [0, 3, 0, 3] } }));
    return retorno;
  }

  public obterColunasfiltroPersonalizado(): Coluna[] {
    let retorno: Coluna[] = [];
    retorno.push({ titulo: 'Período', coluna: 'compra.data_compra', tipo: 'MonthSelector', alignment: 'center', cols: 12, padrao: true, semExcluir: true });
    retorno.push({
      titulo: 'Fornecedor', coluna: 'compra.favorecido.id', tipo: 'MultipleAutoComplete', autocomplete: new EddyAutoComplete(null, this.favorecidoService,
        'id', ['cpf_cnpj', 'nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }), cols: 12, itensMultiAutoComplete: []
    });
    retorno.push({
      titulo: 'Sub grupo', coluna: 'produto_unidade.produto.material.sub_grupo.id', tipo: 'MultipleAutoComplete', autocomplete: new EddyAutoComplete(null, this.subGrupoService,
        'id', ['codigo', 'nome'], { orgao_id: this.login.orgao.id, orderBy: 'codigo,nome' }, { number: ['codigo'], text: ['nome'] }), cols: 12, itensMultiAutoComplete: []
    });
    retorno.push({
      titulo: 'Modalidade', coluna: 'compra.modalidade.id', tipo: 'MultipleAutoComplete', autocomplete: new EddyAutoComplete(null, this.modalidadeService,
        'id', ['codigo', 'nome'], { cidade_id: this.login.cidade.id, orderBy: 'codigo' }, { number: ['codigo'], text: ['nome'] }), cols: 12, itensMultiAutoComplete: []
    });
    retorno.push({
      titulo: 'Produto/Serviço', coluna: 'produto_unidade.produto.id', tipo: 'MultipleAutoComplete', autocomplete: new EddyAutoComplete(null, this.produtoService,
        'id', ['codigo', 'nome'], { orgao_id: this.login.orgao.id, orderBy: 'codigo,nome' }, { number: ['id', 'codigo'], text: ['codigo', 'nome'] }), cols: 12, itensMultiAutoComplete: []
    });
    return retorno;
  }

  protected larguraColunas(): (string | number)[] {
    if (this.tipo === 'S') {
      return [100, '*', 'auto', 'auto', 'auto'];
    } else {
      return ['auto', 'auto', 'auto', 'auto', 'auto','auto','auto','auto','auto','*'];
    }
  }

  protected totalizarColunas(): (string | {})[] {
    return;
  }

  protected carregarLista(): Promise<any[]> {
    let parametros = {}
    if (this.parametros?.filtros)
      parametros = Object.assign(parametros, this.parametros.filtros);

    let mesInicial = parametros['compra.data_compra$ge'];
    let mesFinal = parametros['compra.data_compra$le'];
    let favorecidos = parametros['compra.favorecido.id$in'];
    let modalidades = parametros['compra.modalidade.id$in'];
    let produtos = parametros['produto_unidade.produto.id$in'];
    let subGrupos = parametros['produto_unidade.produto.material.sub_grupo.id$in'];
    let ordenacao = this.ordernacao.coluna;


    this.dataInicial = mesInicial;
    this.dataFinal = mesFinal;

    if (!mesInicial || !mesFinal) {
      toastr.error(`Informe o mês final e inicial!`);
      throw new Error(`Informe o mês final e inicial!`);
    };

    return new Promise((resolve) => {
      console.log(parametros)
      this.compraItemService.relatorioBancoPrecos(mesInicial, mesFinal, subGrupos, produtos, favorecidos, modalidades, this.tipo, ordenacao).pipe(takeUntil(this.unsubscribe))
        .subscribe(async (res) => {
          this.indiceAcumulado = res.indice;
          resolve(res['registros']);
        });
    })
  }

  protected layout(): {} {
    return {
      hLineWidth() {
        return 1;
      },
      vLineWidth(i, node) {
        if (this.tipo === 'A')
          return (i === 0 || i === node.table.widths.length) ? 1 : 0;
        else
          return 1;
      },
      hLineColor(i) {
        return i === 1 || i === 0 ? 'black' : '0aaa';
      },
      paddingLeft(i) {
        return 2;
      },
      paddingRight(i, node) {
        return 2;
      }
    }
  }

  private layoutItens() {
    return {
      hLineWidth() {
        return 1;
      },
      vLineWidth(i, node) {
        return 1;
      },
      hLineColor(i) {
        return i === 1 || i === 0 ? 'black' : '0aaa';
      },
      paddingLeft(i) {
        return 2;
      },
      paddingRight(i, node) {
        return 2;
      }
    }
  }


  public filtroTipo(filtro: Coluna[], tipo: 'String' | 'Number' | 'Date' | 'Boolean' | 'HTML') {
    return filtro.filter((f) => f.tipo === tipo);
  }

  public ngClassCols(cols: number, padrao: string): {} {
    let ngClass = {};
    if (cols)
      ngClass[`col-sm-${cols}`] = true;
    else
      ngClass[padrao] = true;
    return ngClass;
  }

  public formartaDataMesDiaAno(now: string) {
    let data = now.split('-');
    let ano = data[0];
    let mes = data[1];
    let dia = data[2];
    return mes + "-" + dia + "-" + ano;
  }

}