import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { tsXLXS } from 'ts-xlsx-export';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  Coluna, ContaBancaria, Convenio, DateFormatPipe, Despesa, EddyAutoComplete, Favorecido, FavorecidoService,
  FichaDespesa, FichaExtra, FormatoExportacao, FuncaoService, GlobalService, Login, Recurso, Relatorio
} from 'eddydata-lib';
import { ContaBancariaService, ConvenioService, PagamentoService, RecursoService, DespesaService } from 'administrativo-lib';
import { DatePipe } from '@angular/common';
import { FichaDespesaService } from '../../../ficha-despesa/service/ficha-despesa.service';
import { FichaExtraService } from 'contabil-lib';
import * as toastr from 'toastr';

@Component({
  selector: 'app-pagamento-rpt',
  templateUrl: './pagamento-rpt.component.html'
})
export class PagamentoRptComponent implements OnInit, OnDestroy {

  login: Login = new Login();
  conta: ContaBancaria;
  favorecido: Favorecido;
  despesa: Despesa;
  ficha: FichaDespesa;
  recurso: Recurso;
  aplicacao: Recurso;
  aplicacao_variavel: Recurso;
  aplicacao_variavel_final: Recurso;
  RecursoAplicacao: Recurso;
  RecursoAplicacaoFinal: Recurso;
  modeloBusca: boolean = false;
  modeloBuscaText: string = 'Avançado';
  filtragemAvancada: boolean = false;
  filtroRecursoAplicacao: boolean = false;
  filtroFicha: boolean = false;
  convenio: Convenio;
  filtroDespesa: boolean;
  filtroConta: boolean;
  filtroFavorecido: boolean;
  filtroConvenio: boolean;
  filtroRecurso: boolean;
  filtroAplicacao: boolean;
  filtroAplicacao_variavel: boolean;
  filtroOP: boolean = false;
  filtroCheque: boolean = false;
  public selectedOrdem: string;
  public selectedOrdenacao: string;
  public selectedModelo: string;
  public selectedAgrupamento: string;
  public condicaoSomaEmpenhos: string;

  public ptBR: any;

  public listaRelatorio: Array<any>;
  public listaConvenio: Array<Convenio>;
  public contaAutoComplete: EddyAutoComplete<ContaBancaria>;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoVariavelAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoVariavelFinalAutoComplete: EddyAutoComplete<Recurso>;
  public recursoAplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public fichaAutoComplete: EddyAutoComplete<FichaDespesa>;
  public recursoAplicacaoFinalAutoComplete: EddyAutoComplete<Recurso>;
  public despesaAutoComplete: EddyAutoComplete<Despesa>;
  public relatorio = '1';
  public dataInicial: string;
  public dataFinal: string;

  public fichaExtraAutoComplete: EddyAutoComplete<FichaExtra>;
  public fichaExtra: FichaExtra;
  public filtroFichaExtra = false;

  protected datepipe: DatePipe;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    protected injector: Injector,
    protected messageService: MessageService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected contaService: ContaBancariaService,
    protected favorecidoService: FavorecidoService,
    protected recursoService: RecursoService,
    protected despesaService: DespesaService,
    protected convenioService: ConvenioService,
    protected fichaDespesaService: FichaDespesaService,
    protected pagamentoService: PagamentoService,
    protected fichaExtraService: FichaExtraService) {
    this.datepipe = new DatePipe('pt');
  }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.login = GlobalService.obterSessaoLogin();
    this.selectedModelo = 'mod1'
    this.selectedOrdem = 'ord1';
    this.selectedOrdenacao = 'ASC';
    this.selectedAgrupamento = 'data';
    this.listaRelatorio = [];
    this.listaRelatorio.push({ label: 'TODOS OS PAGAMENTOS ORÇAMENTÁRIOS', value: 1 });
    this.listaRelatorio.push({ label: 'PAGAMENTOS ORÇAMENTÁRIOS ANULADOS', value: 2 });
    this.carregarAutoCompletes();
    this.dataInicial = new DateFormatPipe().transform(this.datepipe.transform(new Date(`01/01/${this.login.exercicio.ano}`), 'yyyy-MM-dd'), []);
    this.dataFinal = new DateFormatPipe().transform(this.datepipe.transform(new Date(), 'yyyy-MM-dd'), []);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    new GlobalService().calendarMascara();
  }

  public statusModeloBusca(x: number) {
    // Explicacao -> x == 1 ? click no filtro de aplicacao : click no filtro avancado.
    if (x == 1 && !this.filtroAplicacao_variavel && this.filtragemAvancada && !this.modeloBusca)
      this.filtroAplicacao_variavel = true;
    if (x == 2 && !this.modeloBusca) {
      this.modeloBuscaText = 'Avançado';
      this.filtroRecursoAplicacao = false;
    } else if (x == 2 && this.modeloBusca) {
      this.modeloBuscaText = 'Simples';
      this.filtroRecurso = false; this.filtroAplicacao = false; this.filtroAplicacao_variavel = false;
    } else if (x == 2 && this.modeloBusca && !this.filtroRecursoAplicacao && this.filtragemAvancada) {
      this.modeloBuscaText = 'Simples';
      this.filtroRecursoAplicacao = true;
      this.filtroRecurso = false; this.filtroAplicacao = false; this.filtroAplicacao_variavel = false;
    }
  }

  public gerarRelatorio(formato: FormatoExportacao) {
    const parametros = {};

    if (!this.dataInicial || !this.dataFinal) {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }

    parametros['ignoreCondObrig'] = true;
    parametros['relations'] = 'conta,liquidacao,liquidacao.empenho,liquidacao.empenho.ficha,liquidacao.empenho.subelemento,'
      + 'liquidacao.empenho.ficha.recurso,pagamento_anulado,liquidacao.empenho.ficha.aplicacao,liquidacao.empenho.ficha.aplicacao_variavel,liquidacao.empenho.favorecido,liquidacao.retencoes';
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['data_pagamento$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
    parametros['data_pagamento$le'] = this.funcaoService.converteDataSQL(this.dataFinal);

    if (this.filtroConvenio) {
      parametros['convenio.numero'] = this.convenio.numero;
    }
    if (this.filtroConta) {
      parametros['conta.codigo'] = this.conta.codigo;
    }
    if (this.filtroFavorecido) {
      parametros['liquidacao.empenho.favorecido.id'] = this.favorecido.id;
    }
    if (this.filtroDespesa) {
      parametros['liquidacao.empenho.subelemento.id'] = this.despesa.id;
    }
    if (this.filtroFicha) {
      parametros['liquidacao.empenho.ficha.numero'] = this.ficha.numero;
    }

    if (this.filtroFichaExtra) {
      if (!this.fichaExtra?.id) {
        return toastr.warning(`Informe a ficha de retenção desejada!`)
      }
      parametros['liquidacao.retencoes.ficha.id'] = this.fichaExtra.id;
    }

    if (this.filtroRecurso && !this.modeloBusca) {
      parametros['liquidacao.empenho.ficha.recurso.id'] = this.recurso.id;
    }
    if (this.filtroAplicacao && !this.modeloBusca) {
      parametros['liquidacao.empenho.ficha.aplicacao.id'] = this.aplicacao.id;
    }

    // modelo de busca Avancado
    if (this.filtroAplicacao_variavel && !this.modeloBusca && !this.filtragemAvancada) {
      parametros['liquidacao.empenho.ficha.aplicacao_variavel.id'] = this.aplicacao_variavel.id;
    } else if (this.filtroAplicacao_variavel && !this.modeloBusca && this.filtragemAvancada) {
      parametros['liquidacao.empenho.ficha.aplicacao_variavel.id$ge'] = this.aplicacao_variavel.id;
      parametros['liquidacao.empenho.ficha.aplicacao_variavel.id$le'] = this.aplicacao_variavel_final.id;
      // modelo de busca Simples
    } else if (!this.filtroAplicacao_variavel && this.modeloBusca && this.filtroRecursoAplicacao && !this.filtragemAvancada) {
      parametros['liquidacao.empenho.ficha.aplicacao_variavel.id'] = this.RecursoAplicacao.id;
    } else if (!this.filtroAplicacao_variavel && this.modeloBusca && this.filtroRecursoAplicacao && this.filtragemAvancada) {
      parametros['liquidacao.empenho.ficha.aplicacao_variavel.id$ge'] = this.RecursoAplicacao.id;
      parametros['liquidacao.empenho.ficha.aplicacao_variavel.id$le'] = this.RecursoAplicacaoFinal.id;
    }

    if (this.relatorio === '2') {
      parametros['anulacao'] = true;
    }
    if (this.filtroOP) {
      parametros['valid_possui_of'] = false;
    }
    if (this.filtroCheque) {
      parametros['valid_possui_cheque'] = false;
    }

    if (this.selectedAgrupamento === 'recurso') {
      parametros['orderBy'] = `liquidacao.empenho.ficha.recurso.codigo$${this.selectedOrdenacao},liquidacao.empenho.ficha.aplicacao.codigo$${this.selectedOrdenacao},liquidacao.empenho.ficha.aplicacao_variavel.variavel$${this.selectedOrdenacao},`;
    } else if (this.selectedAgrupamento === 'data') {
      parametros['orderBy'] = 'data_pagamento$' + this.selectedOrdenacao + ',';
    } else {
      parametros['orderBy'] = '';
    }
    if (this.selectedOrdem === 'ord1') {
      parametros['orderBy'] += 'data_pagamento$' + this.selectedOrdenacao;
    } else if (this.selectedOrdem === 'ord2') {
      parametros['orderBy'] += 'conta.numero_conta$' + this.selectedOrdenacao;
    } else if (this.selectedOrdem === 'ord3') {
      parametros['orderBy'] += 'liquidacao.empenho.subelemento.codigo$' + this.selectedOrdenacao;
    } else if (this.selectedOrdem === 'ord4') {
      parametros['orderBy'] += 'liquidacao.empenho.favorecido.nome$' + this.selectedOrdenacao;
    }

    this.pagamentoService.extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((lista) => {
        let valor;
        valor = lista.content.map((i) => {
          i.despesa = `${i.liquidacao.empenho.subelemento.codigo} ${i.liquidacao.empenho.subelemento.nome}`
          i.conta_bancaria = `${i.conta.codigo} ${i.conta.nome}`
          let nome_variavel = i.liquidacao.empenho.ficha?.aplicacao_variavel?.codigo ? i.liquidacao.empenho.ficha?.aplicacao_variavel?.nome : i.liquidacao.empenho.ficha.aplicacao.nome
          let codigo_variavel = i.liquidacao.empenho.ficha?.aplicacao_variavel?.codigo ? i.liquidacao.empenho.ficha.aplicacao_variavel.codigo.toString() : i.liquidacao.empenho.ficha.recurso.codigo.toString() + i.liquidacao.empenho.ficha.aplicacao.codigo.toString() + "0000"
          let variavel = '';
          if (this.selectedAgrupamento === 'recurso') {
            variavel = codigo_variavel + ' - ' + nome_variavel
          } else {
            variavel = codigo_variavel
          }
          if (i.liquidacao.parcela == null || i.liquidacao.parcela == undefined || i.liquidacao.parcela === 0) {
            return { ...i, valor_liquido: i.valor_retido == i.valor_pago ? i.liquidacao.empenho.valor_empenho : i.liquidacao.empenho.valor_empenho - i.valor_retido, empenho_parcela: i.liquidacao.empenho.numero, recurso_aplicacao_variavel: variavel, valor_retencao: i.liquidacao.retencoes.reduce((valor_atual, retencao) => valor_atual += retencao.valor_retido, 0) }
          } else {
            return { ...i, valor_liquido: i.liquidacao.valor_liquidado - i.valor_retido, empenho_parcela: i.liquidacao.empenho.numero + " - " + i.liquidacao.parcela, recurso_aplicacao_variavel: variavel, valor_retencao: i.liquidacao.retencoes.reduce((valor_atual, retencao) => valor_atual += retencao.valor_retido, 0) }
          }
        });
        if (this.selectedModelo === 'mod1') {
          if (formato === 'pdf') {

            Relatorio.imprimirPersonalizado(`PAGAMENTOS ORÇAMENTÁRIOS PERÍODO DE ${this.funcaoService.converteDataBR(this.dataInicial)} à ${this.funcaoService.converteDataBR(this.dataFinal)} `
              + (this.relatorio === '2' ? '\nANULADOS' : '')
              + (this.filtroFavorecido ? ` - ${this.favorecido?.nome}` : ''),
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, this.relatorio === '1' ? this.conteudo(valor) : this.conteudoAnulado(valor), 'landscape', 'Listagem pagamentos',
              {
                linhas: {
                  hLineWidth() {
                    return 1;
                  },
                  vLineWidth() {
                    return 1;
                  },
                  hLineColor() {
                    return 'black';
                  },
                  paddingLeft() {
                    return 3;
                  },
                  paddingRight() {
                    return 3;
                  }
                }
              });
            // this.imprimir(`PAGAMENTOS ORÇAMENTÁRIOS PERÍODO DE ${this.funcaoService.converteDataBR(this.dataInicial)} à ${this.funcaoService.converteDataBR(this.dataFinal)} `
            //   + (this.relatorio === '2' ? '\nANULADOS' : '')
            //   + (this.filtroFavorecido ? ` - ${this.favorecido?.nome}` : ''),
            //   this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
            //   'Listagem pagamentos', ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], valor);
          } else {
            this.funcaoService.exportar(formato, valor, `PAGAMENTOS ORCAMENTARIOS PERIODO DE ${this.funcaoService.converteDataBR(this.dataInicial)} A ${this.funcaoService.converteDataBR(this.dataFinal)}`
              + (this.relatorio === '2' ? ' ANULADOS' : '')
              + (this.filtroFavorecido ? ` - ${this.favorecido?.nome}` : ''), this.colunas() as Coluna[]);
          }
        } else {
          if (formato === 'pdf') {
            this.imprimirMod2(`PAGAMENTOS ORÇAMENTÁRIOS PERÍODO DE ${this.funcaoService.converteDataBR(this.dataInicial)} à ${this.funcaoService.converteDataBR(this.dataFinal)} `
              + (this.relatorio === '2' ? '\nANULADOS' : '')
              + (this.filtroFavorecido ? ` - ${this.favorecido?.nome}` : ''),
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem pagamentos', ['auto', 'auto', 'auto', '*', 'auto', 'auto'], valor);
          } else {
            this.funcaoService.exportar(formato, valor, `PAGAMENTOS ORCAMENTARIOS PERIODO DE ${this.funcaoService.converteDataBR(this.dataInicial)} A ${this.funcaoService.converteDataBR(this.dataFinal)}`
              + (this.relatorio === '2' ? ' ANULADOS' : '')
              + (this.filtroFavorecido ? ` - ${this.favorecido?.nome}` : ''), this.colunasMod2() as Coluna[]);
          }
        }
      });
  }

  private conteudo(dados: any[]): {}[] {

    let total_empenho = 0;
    let total_liquidado = 0;
    let total_retencao = 0;
    let total_liquido = 0;
    let total_pago = 0;
    let total_geral_empenho = 0;

    let empenhos: any[] = []

    if (dados.length === 0) {
      return;
    }

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: this.selectedAgrupamento === 'data' ? 'Conta' : 'Data',
        alignment: 'left',
        bold: true, border: [false, true, false, true], colSpan: this.selectedAgrupamento === 'data' ? 2 : 1
      }, {
        text: this.selectedAgrupamento === 'data' ? '' : 'Conta',
        alignment: 'left',
        bold: true, border: [false, true, false, true]
      }, {
        text: 'Documento',
        alignment: 'left',
        bold: true, border: [false, true, false, true]
      }, {
        text: 'Ficha',
        alignment: 'left',
        bold: true, border: [false, true, false, true]
      }, {
        text: 'Despesa',
        alignment: 'left',
        bold: true, border: [false, true, false, true]
      }, {
        text: 'Emp.',
        alignment: 'left',
        bold: true, border: [false, true, false, true]
      }, {
        text: 'Favorecido',
        alignment: 'left',
        bold: true, border: [false, true, false, true], colSpan: this.selectedAgrupamento === 'recurso' ? 2 : 1
      }, {
        text: 'Recurso',
        alignment: 'left',
        bold: true, border: [false, true, false, true]
      }, {
        text: 'Valor Emp.',
        alignment: 'right',
        bold: true, border: [false, true, false, true]
      }, {
        text: 'Valor Bruto',
        alignment: 'right',
        bold: true, border: [false, true, false, true]
      }, {
        text: 'Valor Retido',
        alignment: 'right',
        bold: true, border: [false, true, false, true]
      }, {
        text: 'Valor Liquido',
        alignment: 'right',
        bold: true, border: [false, true, false, true]
      }, {
        text: 'Valor Pago',
        alignment: 'right',
        bold: true, border: [false, true, false, true]
      }]
    ];

    let lista;

    if (this.selectedAgrupamento === 'data') {
      lista = this.funcaoService.agrupar(dados, ['data_pagamento'], ['liquidacao.empenho.valor_empenho', 'liquidacao.valor_liquidado', 'valor_retencao', 'valor_liquido', 'valor_pago'])
    } else {
      lista = this.funcaoService.agrupar(dados, ['recurso_aplicacao_variavel'], ['liquidacao.valor_liquidado', 'valor_retencao', 'valor_liquido', 'valor_pago'])
    }
    for (const item of lista) {

      const empenhosProcessados = new Set();

      total_empenho = 0;
      for (const empenho of item.registros) {
        const numeroEmpenho = empenho.liquidacao.empenho.numero;
        if (!empenhosProcessados.has(numeroEmpenho)) {
          total_empenho += +empenho.liquidacao.empenho.valor_empenho + +empenho.empenho_anulado;
          empenhosProcessados.add(numeroEmpenho);
        }
      }

      registros.push([
        {
          text: this.selectedAgrupamento === 'data' ? this.funcaoService.converteDataBR(item.grupo['data_pagamento']) : item.grupo['recurso_aplicacao_variavel'], bold: true, colSpan: 8, border: [false, false, false, false],
        },
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        {
          text: this.funcaoService.convertToBrNumber(total_empenho), bold: true, alignment: 'right',
          border: [false, false, false, false]
        }, {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['liquidacao.valor_liquidado']), bold: true, border: [false, false, false, false],
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor_retencao']), alignment: 'right',
          bold: true, border: [false, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor_liquido']), bold: true, border: [false, false, false, false],
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor_pago']), alignment: 'right',
          bold: true, border: [false, false, false, false]
        }
      ]);

      for (const [subIndex, pagamento] of item.registros.entries()) {
        const isLastItemInGroup = subIndex === item.registros.length - 1;

        registros.push([
          {
            text: this.selectedAgrupamento === 'data' ? pagamento.conta_bancaria : this.funcaoService.converteDataBR(pagamento.data_pagamento), colSpan: this.selectedAgrupamento === 'data' ? 2 : 1, border: [false, false, false, false],
          },
          {
            text: this.selectedAgrupamento === 'data' ? '' : pagamento.conta_bancaria, border: [false, false, false, false],
          },
          {
            text: pagamento.documento,
            border: [false, false, false, false]
          },
          {
            text: pagamento.liquidacao.empenho.ficha.numero, border: [false, false, false, false],
          },
          {
            text: pagamento.despesa,
            border: [false, false, false, false]
          }, {
            text: pagamento.empenho_parcela, border: [false, false, false, false],
          },
          {
            text: pagamento.liquidacao.empenho.favorecido.nome, colSpan: this.selectedAgrupamento === 'recurso' ? 2 : 1,
            border: [false, false, false, false]
          },
          {
            text: pagamento.recurso_aplicacao_variavel, border: [false, false, false, false],
          },
          {
            text: !pagamento.anulacao ? this.funcaoService.convertToBrNumber(+pagamento.liquidacao.empenho.valor_empenho + +pagamento.empenho_anulado) : this.funcaoService.convertToBrNumber(0), alignment: 'right',
            border: [false, false, false, false]
          }, {
            text: this.funcaoService.convertToBrNumber(pagamento.liquidacao.valor_liquidado), border: [false, false, false, false],
          },
          {
            text: pagamento.valor_retido == pagamento.valor_pago ? this.funcaoService.convertToBrNumber(0) : this.funcaoService.convertToBrNumber(pagamento.valor_retido), alignment: 'right',
            border: [false, false, false, false]
          },
          {
            text: this.funcaoService.convertToBrNumber(pagamento.valor_liquido), border: [false, false, false, false],
          },
          {
            text: this.funcaoService.convertToBrNumber(pagamento.valor_pago), alignment: 'right',
            margin: isLastItemInGroup ? [0, 0, 0, 30] : [0, 0, 0, 0] // Adiciona a margem de 30 apenas no último item do último grupo
            , border: [false, false, false, false]
          }
        ]);

        total_retencao += +pagamento.valor_retencao;
        total_liquidado += +pagamento.liquidacao.valor_liquidado;
        total_liquido += +pagamento.valor_liquido;
        total_pago += +pagamento.valor_pago;

        pagamento.liquidacao.empenho['valor_empenho_anulado'] = pagamento.empenho_anulado;

        empenhos.push(pagamento.liquidacao.empenho)
      }
    }

    const empenhosProcessados = new Set();

    for (const item of empenhos) {
      const numeroEmpenho = item.numero;
      if (!empenhosProcessados.has(numeroEmpenho)) {
        total_geral_empenho += +item.valor_empenho + +item.valor_empenho_anulado;
        empenhosProcessados.add(numeroEmpenho);
      }
    }

    registros.push([
      {
        text: 'TOTAL GERAL:', border: [false, false, false, false], bold: true, decoration: 'underline', colSpan: 8
      }, '', '', '', '', '', '', '',
      {
        text: this.funcaoService.convertToBrNumber(total_geral_empenho), bold: true, decoration: 'underline', alignment: 'right',
        border: [false, false, false, false]
      }, {
        text: this.funcaoService.convertToBrNumber(total_liquidado), bold: true, decoration: 'underline', border: [false, false, false, false],
      },
      {
        text: this.funcaoService.convertToBrNumber(total_retencao), alignment: 'right',
        bold: true, decoration: 'underline', border: [false, false, false, false]
      },
      {
        text: this.funcaoService.convertToBrNumber(total_liquido), bold: true, decoration: 'underline', border: [false, false, false, false],
      },
      {
        text: this.funcaoService.convertToBrNumber(total_pago), alignment: 'right',
        bold: true, decoration: 'underline', border: [false, false, false, false]
      }
    ]);


    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }];
  }

  private conteudoAnulado(dados: any[]): {}[] {
    let total_empenho = 0;
    let total_pago_anulado = 0;
    let total_retido_anulado = 0;
    let total_anulado = 0;
    let total_geral_empenho = 0;
    let total_geral_anulado = 0;

    let empenhos: any[] = []

    if (dados.length === 0) {
      return;
    }

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: this.selectedAgrupamento === 'data' ? 'Conta' : 'Data',
        alignment: 'left',
        bold: true, border: [false, true, false, true], colSpan: this.selectedAgrupamento === 'data' ? 2 : 1
      }, {
        text: this.selectedAgrupamento === 'data' ? '' : 'Conta',
        alignment: 'left',
        bold: true, border: [false, true, false, true]
      }, {
        text: 'Documento',
        alignment: 'left',
        bold: true, border: [false, true, false, true]
      }, {
        text: 'Ficha',
        alignment: 'left',
        bold: true, border: [false, true, false, true]
      }, {
        text: 'Despesa',
        alignment: 'left',
        bold: true, border: [false, true, false, true]
      }, {
        text: 'Emp.',
        alignment: 'left',
        bold: true, border: [false, true, false, true]
      }, {
        text: 'Favorecido',
        alignment: 'left',
        bold: true, border: [false, true, false, true], colSpan: this.selectedAgrupamento === 'recurso' ? 2 : 1
      }, {
        text: 'Recurso',
        alignment: 'left',
        bold: true, border: [false, true, false, true]
      }, {
        text: 'Valor Emp.',
        alignment: 'right',
        bold: true, border: [false, true, false, true]
      }, {
        text: 'Valor Pago',
        alignment: 'right',
        bold: true, border: [false, true, false, true]
      }, {
        text: 'Valor Retido Anulado',
        alignment: 'right',
        bold: true, border: [false, true, false, true]
      }, {
        text: 'Valor Anulado',
        alignment: 'right',
        bold: true, border: [false, true, false, true]
      }]
    ];

    let lista;

    if (this.selectedAgrupamento === 'data') {
      lista = this.funcaoService.agrupar(dados, ['data_pagamento'], ['liquidacao.empenho.valor_empenho', 'liquidacao.valor_liquidado', 'pagamento_anulado.valor_pago', 'valor_retido', 'valor_pago'])
    } else {
      lista = this.funcaoService.agrupar(dados, ['recurso_aplicacao_variavel'], ['liquidacao.valor_liquidado', 'pagamento_anulado.valor_pago', 'valor_retido', 'valor_pago'])
    }
    for (const item of lista) {

      const empenhosProcessados = new Set();

      total_empenho = 0;
      total_pago_anulado = 0;
      for (const i of item.registros) {
        const numeroEmpenho = i.liquidacao.empenho.numero;
        if (!empenhosProcessados.has(numeroEmpenho)) {
          total_empenho += +i.liquidacao.empenho.valor_empenho;
          empenhosProcessados.add(numeroEmpenho);
        }

        total_pago_anulado += i.pagamento_anulado ? +i.pagamento_anulado.valor_pago : +i.liquidacao.valor_liquidado
      }

      registros.push([
        {
          text: this.selectedAgrupamento === 'data' ? this.funcaoService.converteDataBR(item.grupo['data_pagamento']) : item.grupo['recurso_aplicacao_variavel'], bold: true, colSpan: 8, border: [false, false, false, false],
        },
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        {
          text: this.funcaoService.convertToBrNumber(total_empenho), bold: true, alignment: 'right',
          border: [false, false, false, false]
        }, {
          text: this.funcaoService.convertToBrNumber(total_pago_anulado), bold: true, border: [false, false, false, false],
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor_retido']), alignment: 'right',
          bold: true, border: [false, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor_pago']), alignment: 'right',
          bold: true, border: [false, false, false, false]
        }
      ]);

      for (const [subIndex, pagamento] of item.registros.entries()) {
        const isLastItemInGroup = subIndex === item.registros.length - 1;

        registros.push([
          {
            text: this.selectedAgrupamento === 'data' ? pagamento.conta_bancaria : this.funcaoService.converteDataBR(pagamento.data_pagamento), colSpan: this.selectedAgrupamento === 'data' ? 2 : 1, border: [false, false, false, false],
          },
          {
            text: this.selectedAgrupamento === 'data' ? '' : pagamento.conta_bancaria, border: [false, false, false, false],
          },
          {
            text: pagamento.documento,
            border: [false, false, false, false]
          },
          {
            text: pagamento.liquidacao.empenho.ficha.numero, border: [false, false, false, false],
          },
          {
            text: pagamento.despesa,
            border: [false, false, false, false]
          }, {
            text: pagamento.empenho_parcela, border: [false, false, false, false],
          },
          {
            text: pagamento.liquidacao.empenho.favorecido.nome, colSpan: this.selectedAgrupamento === 'recurso' ? 2 : 1,
            border: [false, false, false, false]
          },
          {
            text: pagamento.recurso_aplicacao_variavel, border: [false, false, false, false],
          },
          {
            text: this.funcaoService.convertToBrNumber(pagamento.liquidacao.empenho.valor_empenho), alignment: 'right',
            border: [false, false, false, false]
          }, {
            text: pagamento.pagamento_anulado ? this.funcaoService.convertToBrNumber(pagamento.pagamento_anulado?.valor_pago) : this.funcaoService.convertToBrNumber(pagamento.liquidacao.valor_liquidado), border: [false, false, false, false],
          },
          {
            text: this.funcaoService.convertToBrNumber(pagamento.valor_retido), alignment: 'right',
            border: [false, false, false, false]
          },
          {
            text: this.funcaoService.convertToBrNumber(pagamento.valor_pago), alignment: 'right',
            margin: isLastItemInGroup ? [0, 0, 0, 30] : [0, 0, 0, 0] // Adiciona a margem de 30 apenas no último item do último grupo
            , border: [false, false, false, false]
          }
        ]);

        total_retido_anulado += +pagamento.valor_retido;
        total_geral_anulado += !pagamento.pagamento_anulado ? +pagamento.liquidacao.valor_liquidado : +pagamento.pagamento_anulado?.valor_pago;
        total_anulado += +pagamento.liquidacao.valor_liquidado;

        empenhos.push(pagamento.liquidacao.empenho)
      }
    }

    const empenhosProcessados = new Set();

    for (const item of empenhos) {
      const numeroEmpenho = item.numero;
      if (!empenhosProcessados.has(numeroEmpenho)) {
        total_geral_empenho += +item.valor_empenho;
        empenhosProcessados.add(numeroEmpenho);
      }
    }

    registros.push([
      {
        text: 'TOTAL GERAL:', border: [false, false, false, false], bold: true, decoration: 'underline', colSpan: 8
      }, '', '', '', '', '', '', '',
      {
        text: this.funcaoService.convertToBrNumber(total_geral_empenho), bold: true, decoration: 'underline', alignment: 'right',
        border: [false, false, false, false]
      }, {
        text: this.funcaoService.convertToBrNumber(total_geral_anulado), bold: true, decoration: 'underline', border: [false, false, false, false],
      },
      {
        text: this.funcaoService.convertToBrNumber(total_retido_anulado), alignment: 'right',
        bold: true, decoration: 'underline', border: [false, false, false, false]
      },
      {
        text: this.funcaoService.convertToBrNumber(total_anulado), alignment: 'right',
        bold: true, decoration: 'underline', border: [false, false, false, false]
      }
    ]);


    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }];
  }

  // private imprimir(
  //   titulo: string, usuario: string, sobrenome: string, orgao: string,
  //   brasao: string, orientacao: 'landscape' | 'portrait', nomepdf?: string, largura?: string[], lista?: any[]) {
  //   Relatorio.imprimir(titulo, usuario, sobrenome, orgao, brasao, lista, this.colunas(), orientacao, nomepdf, largura, [
  //     { nome: 'liquidacao.empenho.valor_empenho', funcao: [{ se: { condicao: 'anulacao=true', verdadeiro: [0], falso: ['liquidacao.empenho.valor_empenho'] } }] },
  //     'liquidacao.valor_liquidado', 'valor_retido', 'valor_liquido', 'valor_pago'
  //   ]);
  // }

  colunas(): string[] | Coluna[] {
    let retencao: Coluna = {
      titulo: 'Valor Retido', coluna: 'valor_retido', decimais: 2, alignment: 'right', funcao: [{
        se: {
          condicao: 'valor_retido=valor_pago$ent',
          verdadeiro: ['0,00'],
          falso: ['valor_retido']
        },
      }]
    };

    if (this.filtroFichaExtra) {
      retencao = {
        titulo: 'Valor Retido', coluna: 'valor_retido', decimais: 2, alignment: 'right', funcao: [{
          se: {
            condicao: 'valor_retido=valor_pago$ent',
            verdadeiro: ['0,00'],
            falso: ['valor_retencao']
          },
        }]
      };
    };

    let valor_pago_anulacao: Coluna = {
      titulo: 'Valor Pago', coluna: 'pagamento_anulado.valor_pago', decimais: 2, alignment: 'right', funcao: [{
        se: {
          condicao: 'pagamento_anulado=null',
          verdadeiro: ['liquidacao.valor_liquidado'],
          falso: ['pagamento_anulado.valor_pago']
        },
      }]
    };

    if (this.relatorio === '1') {
      if (this.selectedAgrupamento === 'data') {
        return [
          { titulo: 'Data', coluna: 'data_pagamento', agrupar: true, bold: true, alignment: 'left' },
          { titulo: 'Conta', coluna: 'conta_bancaria' },
          { titulo: 'Documento', coluna: 'documento' },
          { titulo: 'Ficha', coluna: 'liquidacao.empenho.ficha.numero' },
          { titulo: 'Despesa', coluna: 'despesa' },
          { titulo: 'Emp.', coluna: 'empenho_parcela', alignment: 'center' },
          { titulo: 'Favorecido', coluna: 'liquidacao.empenho.favorecido.nome' },
          { titulo: 'Recurso', coluna: 'recurso_aplicacao_variavel' },
          { titulo: 'Valor Emp.', coluna: 'liquidacao.empenho.valor_empenho', decimais: 2, alignment: 'right' },
          { titulo: 'Valor Bruto', coluna: 'liquidacao.valor_liquidado', decimais: 2, alignment: 'right' },
          retencao,
          { titulo: 'Valor Liquido', coluna: 'valor_liquido', decimais: 2, alignment: 'right' },
          { titulo: 'Valor Pago', coluna: 'valor_pago', decimais: 2, alignment: 'right' },
        ];
      } else if (this.selectedAgrupamento === 'recurso') {
        return [
          { titulo: 'Recurso', coluna: 'recurso_aplicacao_variavel', agrupar: true, bold: true },
          { titulo: 'Data', coluna: 'data_pagamento', alignment: 'left' },
          { titulo: 'Conta', coluna: 'conta_bancaria' },
          { titulo: 'Documento', coluna: 'documento' },
          { titulo: 'Ficha', coluna: 'liquidacao.empenho.ficha.numero' },
          { titulo: 'Despesa', coluna: 'despesa' },
          { titulo: 'Emp.', coluna: 'empenho_parcela', alignment: 'center' },
          { titulo: 'Favorecido', coluna: 'liquidacao.empenho.favorecido.nome' },
          { titulo: 'Valor Emp.', coluna: 'liquidacao.empenho.valor_empenho', decimais: 2, alignment: 'right' },
          { titulo: 'Valor Bruto', coluna: 'liquidacao.valor_liquidado', decimais: 2, alignment: 'right' },
          retencao,
          { titulo: 'Valor Liquido', coluna: 'valor_liquido', decimais: 2, alignment: 'right' },
          { titulo: 'Valor Pago', coluna: 'valor_pago', decimais: 2, alignment: 'right' },
        ];
      }
    } else {
      if (this.selectedAgrupamento === 'data') {
        return [
          { titulo: 'Data', coluna: 'data_pagamento', agrupar: true, bold: true, alignment: 'left' },
          { titulo: 'Conta', coluna: 'conta_bancaria' },
          { titulo: 'Documento', coluna: 'documento' },
          { titulo: 'Ficha', coluna: 'liquidacao.empenho.ficha.numero' },
          { titulo: 'Despesa', coluna: 'despesa' },
          { titulo: 'Emp.', coluna: 'empenho_parcela', alignment: 'center' },
          { titulo: 'Favorecido', coluna: 'liquidacao.empenho.favorecido.nome' },
          { titulo: 'Recurso', coluna: 'recurso_aplicacao_variavel' },
          { titulo: 'Valor Emp.', coluna: 'liquidacao.empenho.valor_empenho', decimais: 2, alignment: 'right' },
          valor_pago_anulacao,
          { titulo: 'Valor Retido Anulado', coluna: 'valor_retido', decimais: 2, alignment: 'right' },
          { titulo: 'Valor anulado', coluna: 'valor_pago', decimais: 2, alignment: 'right' }
        ];
      } else if (this.selectedAgrupamento === 'recurso') {
        return [
          { titulo: 'Recurso', coluna: 'recurso_aplicacao_variavel', agrupar: true, bold: true },
          { titulo: 'Data', coluna: 'data_pagamento', alignment: 'left' },
          { titulo: 'Conta', coluna: 'conta_bancaria' },
          { titulo: 'Documento', coluna: 'documento' },
          { titulo: 'Ficha', coluna: 'liquidacao.empenho.ficha.numero' },
          { titulo: 'Despesa', coluna: 'despesa' },
          { titulo: 'Emp.', coluna: 'empenho_parcela', alignment: 'center' },
          { titulo: 'Favorecido', coluna: 'liquidacao.empenho.favorecido.nome' },
          { titulo: 'Valor Emp.', coluna: 'liquidacao.empenho.valor_empenho', decimais: 2, alignment: 'right' },
          valor_pago_anulacao,
          { titulo: 'Valor Retido Anulado', coluna: 'valor_retido', decimais: 2, alignment: 'right' },
          { titulo: 'Valor anulado', coluna: 'valor_pago', decimais: 2, alignment: 'right' }
        ];
      }
    }
  }

  exportarXlxs() {
    const parametros = {};
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['relations'] = 'conta,liquidacao,liquidacao.empenho.ficha,liquidacao.empenho.subelemento,'
      + 'liquidacao.empenho.ficha.recurso,liquidacao.empenho.ficha.aplicacao';
    parametros['orderBy'] = 'codigo';
    this.pagamentoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          const listaItens = new Array();
          for (const item of lista.content) {
            const entity = {
              codigo: item.codigo,
              cpf: item.cpf,
              nome: item.pessoa.nome,
              endereco: item.pessoa.endereco,
              bairro: item.pessoa.bairro,
              cidade: item.pessoa.municipio,
              telefone: item.pessoa.telefone,
              cep: item.pessoa.cep
            };
            listaItens.push(entity);
          }
          tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile('funcionarios');
        },
        () => alert('erro ao retornar lista')
      );
  }

  private imprimirMod2(
    titulo: string, usuario: string, sobrenome: string, orgao: string,
    brasao: string, orientacao: 'landscape' | 'portrait', nomepdf?: string, largura?: string[], lista?: any[]) {
    Relatorio.imprimir(titulo, usuario, sobrenome, orgao, brasao, lista, this.colunasMod2(), orientacao, nomepdf, largura, [{ nome: 'valor_liquidado', funcao: [{ se: { condicao: 'valor_retido=valor_pago', verdadeiro: [0], falso: ['valor_pago', '-', 'valor_retido'] } }] }]);
  }

  colunasMod2(): string[] | Coluna[] {
    let retencao: Coluna = {
      titulo: 'Valor Retido', coluna: 'valor_retido', decimais: 2, alignment: 'right', funcao: [{
        se: {
          condicao: 'valor_retido=valor_pago$ent',
          verdadeiro: ['0,00'],
          falso: ['valor_retido']
        },
      }]
    };

    if (this.filtroFichaExtra) {
      retencao = {
        titulo: 'Valor Retido', coluna: 'valor_retido', decimais: 2, alignment: 'right', funcao: [{
          se: {
            condicao: 'valor_retido=valor_pago$ent',
            verdadeiro: ['0,00'],
            falso: ['valor_retencao']
          },
        }]
      };
    }

    let liquido: Coluna = {
      titulo: 'Valor Liquido', coluna: 'valor_liquidado', decimais: 2, alignment: 'right', funcao: [{
        se: {
          condicao: 'valor_retido=valor_pago',
          verdadeiro: ['0,00'],
          falso: ['valor_pago', '-', 'valor_retido']
        },
      }]
    };

    return [
      { titulo: 'Empenho', coluna: 'liquidacao.empenho.numero', alignment: 'left' },
      { titulo: 'Parcela', coluna: 'liquidacao.parcela', alignment: 'center' },
      { titulo: 'Favorecido', coluna: 'liquidacao.empenho.favorecido.nome' },
      { titulo: 'Histórico', coluna: 'historico' },
      { titulo: 'Data pagamento', coluna: 'data_pagamento' },
      liquido
    ];
  }

  private carregarAutoCompletes() {
    // autocomplete para conta
    this.contaAutoComplete = new EddyAutoComplete(null, this.contaService,
      "id", ["codigo", "banco.nome", "nome"], { orgao_id: this.login.orgao.id, ativo: true, relations: "banco", orderBy: "codigo" },
      { number: ["codigo"], text: ["banco.nome", "nome"] }
    );

    // autocomplete para despesa
    this.despesaAutoComplete = new EddyAutoComplete(null, this.despesaService,
      'id', ['codigo', 'nome'], { escrituracao: true, exercicio_id: this.login.exercicio.id, orderBy: 'nome' },
      { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para ficha
    this.fichaAutoComplete = new EddyAutoComplete(null, this.fichaDespesaService,
      'numero', ['ficha.numero', 'ficha.despesa.nome'], { exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id, relations: 'despesa', orderBy: 'numero,despesa.nome' },
      { number: ['numero'], text: ['despesa.nome'] }
    );

    // autocomplete para recurso
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome', 'codigo'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para aplicacao Variavel
    this.aplicacaoVariavelAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['variavel', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['variavel', 'nome'] }
    );

    // autocomplete para aplicacao Variavel numero final (busca avancada)
    this.aplicacaoVariavelFinalAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['variavel', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['variavel', 'nome'] }
    );

    // autocomplete para recursoAplicacao e Variavel (busca simples)
    this.recursoAplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para recursoAplicacao e Variavel numero final (busca simples)
    this.recursoAplicacaoFinalAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['codigo', 'nome'] }
    );

    let parametros = {
      cidade_id: this.login.cidade.id,
      orderBy: "nome",
      relations: "tipo",
    };

    this.favorecidoAutoComplete = new EddyAutoComplete(null,
      this.favorecidoService, "id", ["cpf_cnpj", "nome"], parametros, { number: ["id", "cpf_cnpj"], text: ["nome"] }
    );

    this.fichaExtraAutoComplete = new EddyAutoComplete(null, this.fichaExtraService,
      'numero', ['numero', 'nome'],
      { exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id, retencao: true, excluida: false, relations: 'plano,favorecido,ficha', orderBy: 'nome' },
      { number: ['id', 'numero'], text: ['nome'] }
    );
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/tesouraria']);
        break;
      default:
        this.sair();
        break;
    }
  }
}
