import { Component, OnInit } from '@angular/core';
import { Sistemas } from '../../components/types';
import { Login } from '../../entidade/login/login';
import { GlobalService } from '../../util/global.service';
import { RelatorioPersonalizadoModules } from '../relatorio-personalizado-form/relatorio-personalizado-form.component';
import { RPBemImovelService } from '../service/rp-bem-imovel.service';
import { RPCompraItemService } from '../service/rp-compra-item.service';
import { RPCompraService } from '../service/rp-compra.service';
import { RPContratoAditamentoItemService } from '../service/rp-contrato-aditamento-item.service';
import { RPContratoService } from '../service/rp-contrato.service';
import { RPEmpenhoService } from '../service/rp-empenho.service';
import { RPEstoqueService } from '../service/rp-estoque.service';
import { RPFavorecidoService } from '../service/rp-favorecido.service';
import { RPFichaReceitaService } from '../service/rp-ficha-receita.service';
import { RPLicitacaoService } from '../service/rp-licitacao.service';
import { RPLiquidacaoService } from '../service/rp-liquidacao.service';
import { RPMemorialService } from '../service/rp-memorial.service';
import { RPMovimentoItemEstoqueService } from '../service/rp-movimento-estoque-item.service';
import { RPMovimentoEstoqueService } from '../service/rp-movimento-estoque.service';
import { RPPregaoHistoricoService } from '../service/rp-pregao-historico.service';
import { RPProponenteService } from '../service/rp-proponente.service';
import { RPPropostaService } from '../service/rp-proposta.service';
import { RPRcmsService } from '../service/rp-rcms.service';
import { RPRequisicaoMovimentoService } from '../service/rp-requisicao-movimento.service';
import { RPRplService } from '../service/rp-rpl.service';
import { RPSetorAlmoxarifadoService } from '../service/rp-setor-almoxarifado.service';
import { RPSetorService } from '../service/rp-setor.service';
import { RPTombamentoService } from '../service/rp-tombamento.service';
import { RPUsuarioService } from '../service/rp-usuario.service';
import { RPPagamentoService } from '../service/rp-pagamento.service';
import { RPPagamentoRestoService } from '../service/rp-pagamento-resto.service';
import { RPPagamentoExtraService } from '../service/rp-pagamento-extra.service';
import { RPConvenioService } from '../service/rp-convenio.service';
import { RPEventoContabilService } from '../service/rp-evento-contabil.service';
import { RPReavaliacaoItemService } from '../service/rp-reavalizacao-item.service';

@Component({
  selector: 'lib-relatorio-personalizado-view-base',
  templateUrl: './relatorio-personalizado-view-base.component.html',
  styleUrls: ['./relatorio-personalizado-view-base.component.css']
})
export class RelatorioPersonalizadoViewBaseComponent implements OnInit {


  public modulos: RelatorioPersonalizadoModules[] = [];
  private allModules: RelatorioPersonalizadoModules[] = [];
  public selectedSystem: Sistemas;

  constructor(
    private compraService: RPCompraService,
    private compraItemService: RPCompraItemService,
    private empenhoService: RPEmpenhoService,
    private contratoService: RPContratoService,
    private liquidacaoService: RPLiquidacaoService,
    private fichaReceitaService: RPFichaReceitaService,
    private licitacaoService: RPLicitacaoService,
    private itemAditamentoService: RPContratoAditamentoItemService,
    private memorialService: RPMemorialService,
    private proponenteService: RPProponenteService,
    private propostaService: RPPropostaService,
    private pregaoHistoricoService: RPPregaoHistoricoService,
    private tombamentoService: RPTombamentoService,
    private imovelService: RPBemImovelService,
    private rplService: RPRplService,
    private rcmsService: RPRcmsService,
    private requisaoMovimentoService: RPRequisicaoMovimentoService,
    private favorecidoService: RPFavorecidoService,
    private estoqueService: RPEstoqueService,
    private setorService: RPSetorService,
    private usuarioService: RPUsuarioService,
    private setorAlmoxarifadoService: RPSetorAlmoxarifadoService,
    private movimentoService: RPMovimentoEstoqueService,
    private movimentoItemService: RPMovimentoItemEstoqueService,
    private pagamentoService: RPPagamentoService,
    private pagamentoRestoService: RPPagamentoRestoService,
    private pagamentoExtraService: RPPagamentoExtraService,
    private convenioService: RPConvenioService,
    private eventosContabeisService: RPEventoContabilService,
    private reavaliacaoItemService: RPReavaliacaoItemService
  ) { }

  ngOnInit(): void {
    const login: Login = GlobalService.obterSessaoLogin();

    this.allModules.push(
      {
        sistema: 'compras',
        modulo: 'Ordem de Fornecimento',
        service: this.compraService,
        parametros: { 'orgao_id': login.orgao.id }
      },
      {
        sistema: 'compras',
        modulo: 'Itens Comprados',
        service: this.compraItemService,
        parametros: { 'compra.orgao_id': login.orgao.id }
      },
      {
        sistema: 'compras',
        modulo: 'Fornecedores / Credores',
        service: this.compraItemService,
        parametros: {}
      },
      {
        sistema: 'compras',
        modulo: 'Requisição de Compra',
        service: this.compraItemService,
        parametros: { 'orgao_id': login.orgao.id }
      },
      {
        sistema: 'contabil',
        modulo: 'Empenhos',
        service: this.empenhoService,
        parametros: { 'orgao_id': login.orgao.id, 'exercicio_id': login.exercicio.id }
      },
      {
        sistema: 'contabil',
        modulo: 'Contrato',
        service: this.contratoService,
        parametros: { 'orgao_id': login.orgao.id, }
      },
      {
        sistema: 'contabil',
        modulo: 'Liquidações Orçamentárias',
        service: this.liquidacaoService,
        parametros: { 'orgao_id': login.orgao.id, 'exercicio_id': login.exercicio.id }
      },
      {
        sistema: 'contabil',
        modulo: 'Ficha de Receita',
        service: this.fichaReceitaService,
        parametros: { 'orgao_id': login.orgao.id, 'exercicio_id': login.exercicio.id }
      },
      {
        sistema: 'contabil',
        modulo: 'Convênios',
        service: this.convenioService,
        parametros: { 'orgao_id': login.orgao.id }
      },
      {
        sistema: 'licitacao',
        modulo: 'Licitação',
        service: this.licitacaoService,
        parametros: { 'orgao_id': login.orgao.id }
      },
      {
        sistema: 'licitacao',
        modulo: 'Contrato',
        service: this.contratoService,
        parametros: { 'orgao_id': login.orgao.id }
      },
      {
        sistema: 'licitacao',
        modulo: 'Aditivo',
        service: this.itemAditamentoService,
        parametros: {}
      },
      {
        sistema: 'licitacao',
        modulo: 'Memorial',
        service: this.memorialService,
        parametros: {}
      },
      {
        sistema: 'licitacao',
        modulo: 'Proponente',
        service: this.proponenteService,
        parametros: {}
      },
      {
        sistema: 'licitacao',
        modulo: 'Proposta',
        service: this.propostaService,
        parametros: {}
      },
      {
        sistema: 'licitacao',
        modulo: 'Pregão Histórico',
        service: this.pregaoHistoricoService,
        parametros: {}
      },
      {
        sistema: 'licitacao',
        modulo: 'Empenhos',
        service: this.empenhoService,
        parametros: { 'orgao_id': login.orgao.id, 'exercicio_id': login.exercicio.id }
      },
      {
        sistema: 'patrimonio',
        modulo: 'Bens Móveis',
        service: this.tombamentoService,
        parametros: { 'orgao_id': login.orgao.id }
      },
      {
        sistema: 'patrimonio',
        modulo: 'Bens Imóveis',
        service: this.imovelService,
        parametros: { 'orgao_id': login.orgao.id }
      },
      {
        sistema: 'requisicao',
        modulo: 'Fornecedores / Credores',
        service: this.favorecidoService,
        parametros: {}
      },
      {
        sistema: 'requisicao',
        modulo: 'Requisição de Compra',
        service: this.rcmsService,
        parametros: { 'orgao.id': login.orgao.id, 'exercicio.id': login.exercicio.id }
      },
      {
        sistema: 'requisicao',
        modulo: 'Contrato',
        service: this.contratoService,
        parametros: { 'orgao_id': login.orgao.id, }
      },
      {
        sistema: 'requisicao',
        modulo: 'Requisição de Licitação',
        service: this.rplService,
        parametros: { 'orgao.id': login.orgao.id, 'exercicio.id': login.exercicio.id }
      },
      {
        sistema: 'requisicao',
        modulo: 'Requisição de Material',
        service: this.requisaoMovimentoService,
        parametros: { 'orgao.id': login.orgao.id, 'exercicio.id': login.exercicio.id }
      },
      {
        sistema: 'almoxarifado',
        modulo: 'Estoque',
        service: this.estoqueService,
        parametros: { 'orgao_id': login.orgao.id }
      },
      {
        sistema: 'almoxarifado',
        modulo: 'Setor',
        service: this.setorService,
        parametros: { 'orgao_id': login.orgao.id }
      },
      {
        sistema: 'almoxarifado',
        modulo: 'Usuário',
        service: this.usuarioService,
        parametros: { 'orgao_id': login.orgao.id }
      },
      {
        sistema: 'almoxarifado',
        modulo: 'Vinculo Setor e Estoque',
        service: this.setorAlmoxarifadoService,
        parametros: { 'orgao_id': login.orgao.id }
      },
      {
        sistema: 'almoxarifado',
        modulo: 'Movimento de Estoque',
        service: this.movimentoService,
        parametros: { 'orgao_id': login.orgao.id, 'excluido': false }
      },
      {
        sistema: 'almoxarifado',
        modulo: 'Itens De Movimento',
        service: this.movimentoItemService,
        parametros: {}
      },
      {
        sistema: 'contabil',
        modulo: 'Pagamentos Orçamentários',
        service: this.pagamentoService,
        parametros: { 'orgao_id': login.orgao.id, 'exercicio_id': login.exercicio.id }
      },
      {
        sistema: 'contabil',
        modulo: 'Pagamentos Extra Orçamentários',
        service: this.pagamentoExtraService,
        parametros: { 'orgao_id': login.orgao.id, 'exercicio_id': login.exercicio.id }
      },
      {
        sistema: 'contabil',
        modulo: 'Pagamentos Restos a Pagar',
        service: this.pagamentoRestoService,
        parametros: { 'orgao_id': login.orgao.id, 'exercicio_id': login.exercicio.id }
      },
      {
        sistema: 'contabil',
        modulo: 'Eventos Contábeis',
        service: this.eventosContabeisService,
        parametros: { 'orgao_id': login.orgao.id }
      },
      {
        sistema: 'patrimonio',
        modulo: 'Reavaliação Item',
        service: this.reavaliacaoItemService,
        parametros: {}
      }
    );

    this.modulos = this.allModules.filter(module => module.sistema === this.selectedSystem);
  }

  public changeSelectedSystem(system: Sistemas): void {
    this.selectedSystem = system;
    this.modulos = [...this.allModules.filter(module => module.sistema === this.selectedSystem)];
  }

}
