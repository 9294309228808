// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ContratoAditamento } from '../compra/contrato-aditamento.model';
import { FichaDespesa } from '../planejamento/ficha-despesa.model';
import { ReservaDotacaoEstorno } from './reserva-dotacao-estorno.model';
import { ReservaDotacao } from './reserva-dotacao.model';

export class ReservaDotacaoItem extends BaseResourceModel {
  constructor(
    public data_reserva?: Date,
    public data_estorno?: Date,
    public data_adjudicacao?: Date,
    public data_contabilizacao?: Date,
    public valor_reservado?: number,
    public valor_adjudicacao?: number,
    public valor_empenhado?: number,
    public ativo?: boolean,
    public observacao?: string,
    public aditamento?: boolean,
    public deduz?: boolean,
    public ficha?: FichaDespesa,
    public reserva?: ReservaDotacao,
    public data_baixa?: Date,
    public contrato_aditamento?: ContratoAditamento,
    public estornos?: ReservaDotacaoEstorno[],
  ) {
    super();
  }

  static converteJson(json: any): ReservaDotacaoItem {
    return Object.assign(new ReservaDotacaoItem(), json);
  }
}
