import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { Licitacao } from '../licitacao/licitacao.model';
import { TipoContratacao } from '../licitacao/tipo-contratacao.model';
import { Contrato } from './contrato.model';
import { Favorecido } from './favorecido.model';

// tslint:disable: variable-name
export class ContratoHistorico extends BaseResourceModel {
  constructor(
    public numero?: string,
    public processo?: string,
    public data_historico?: Date,
    public data_inicio?: Date,
    public data_termino?: Date,
    public valor_caucao?: number,
    public valor_contrato?: number,
    public contrato?: Contrato,
    public usuario?: Usuario,
    public licitacao?: Licitacao,
    public tipo_contratacao?: TipoContratacao,
    public editavel?: boolean,
    public data_contabilizacao?: Date,
    public tipo?: 'CONTRATO' | 'REGISTRO_PRECO' | 'ATA_REGISTRO',
    public favorecido?: Favorecido,
    public justificativa?: string
  ) {
    super();
  }
  static converteJson(json: any): ContratoHistorico {
    return Object.assign(new ContratoHistorico(), json);
  }
}
