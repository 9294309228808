import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-confirmacao',
  templateUrl: './confirmacao.component.html'
})
export class ConfirmacaoComponent implements OnInit {

  @Input() texto: string;

  @Output() confirmarClick = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  confirmar() {
    this.confirmarClick.emit();
  }

}
