import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, EventoContabilEspecie } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class EventoContabilEspecieService extends BaseResourceService<EventoContabilEspecie> {

  constructor(
    protected injector: Injector
  ) {
    super(`eventos-contabeis-especies`, injector);
  }


}
