import { Component, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { EmpenhoService, LicitacaoService, ParametroContabilService } from 'administrativo-lib';
import {
  BaseResourceFormComponent, DateFormatPipe, EddyAutoComplete, FuncaoService, Licitacao, LoginContabil, ReservaConvenioEstadual, ReservaDotacao, ReservaDotacaoItem
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { FichaDespesaService } from '../../ficha-despesa/service/ficha-despesa.service';
import { ReservaConvenioEstadualService } from '../service/reserva-convenio-estadual.service';
import { ReservaDotacaoItemService } from '../service/reserva-dotacao-item.service';
import { ReservaDotacaoService } from '../service/reserva-dotacao.service';

@Component({
  selector: 'app-reserva-dotacao-view',
  templateUrl: './reserva-dotacao-view.component.html'
})
export class ReservaDotacaoViewComponent extends BaseResourceFormComponent<ReservaDotacao, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  // @ViewChild('InputFocus') private inputField: ElementRef;

  public listaConvenioEstadual = new Array<any>();
  public listaConvenioFederal = new Array<any>();
  public listaFinanciamento = new Array<any>();
  public listaTipos: Array<any>;
  public listaFichas = new Array<ReservaDotacaoItem>();
  public tipo: number;

  public licitacaoAutoComplete: EddyAutoComplete<Licitacao>;

  public total_reservado = 0;
  public total_empenhado = 0;
  public total_adjudicacao = 0;
  public parametro_sem_pre: boolean;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected reservaService: ReservaDotacaoService,
    protected empenhoService: EmpenhoService,
    protected licitacaoService: LicitacaoService,
    protected convenioEstadualService: ReservaConvenioEstadualService,
    protected reservaItemService: ReservaDotacaoItemService,
    protected fichaService: FichaDespesaService,
    private parametroContabilService: ParametroContabilService) {
    super(new ReservaDotacao(), injector, ReservaDotacao.converteJson, reservaService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected parametrosExtras(): {} {
    return { relations: 'licitacao,licitacao.modalidade' };
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.listaTipos = [
      { nome: 'PRÉ EMPENHO', id: '0' },
      { nome: 'SEM PRÉ EMPENHO', id: '1' }
    ];
    this.parametroContabilService.obter({ 'orgao.id': this.login.orgao.id })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(parametro => {
        this.parametro_sem_pre = parametro.reserva_sem_pre_empenho;
      });
  }

  // protected campoFoco(): ElementRef | AutoComplete | Calendar {
  //   return this.inputField.nativeElement.focus();
  // }

  protected async afterLoad() {

    this.entidade.data_cancelamento = new DateFormatPipe().transform(this.entidade.data_cancelamento, []);
    this.entidade.data_adjudicacao = new DateFormatPipe().transform(this.entidade.data_adjudicacao, []);
    this.convenioEstadualService.filtrar(0, -1, {
      reserva_id: this.entidade.id
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaConvenioEstadual = res.content ? res.content : new Array<ReservaConvenioEstadual>();
      });

    this.reservaItemService.filtrar(0, -1, {
      reserva_id: this.entidade.id,
      relations: 'reserva,reserva.exercicio,reserva.orgao,ficha,ficha.acao,ficha.aplicacao,ficha.aplicacao_variavel,ficha.recurso,ficha.programa,' +
                 'ficha.despesa,ficha.funcao,ficha.subfuncao,ficha.executora,ficha.executora.unidade,ficha.recurso_siconfi,ficha.complemento_siconfi,' +
                 'ficha.orgao,ficha.exercicio'
    })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaFichas = res.content ? res.content : new Array<ReservaDotacaoItem>();
        for (let item of this.listaFichas) {
          this.reservaItemService.totalReservadoItem(item.id)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((resposta) => {
            item['saldo_reserva'] = +resposta.reserva;
          })
        }
        this.totalizarItens(this.listaFichas);
      });
  }

  public totalizarItens(lista: any) {
    this.total_reservado = 0;
    this.total_empenhado = 0;
    this.total_adjudicacao = 0;
    for (const item of lista) {
      this.total_reservado += +item.valor_reservado;
      this.total_empenhado += +item.valor_empenhado;
      this.total_adjudicacao += +item.valor_adjudicacao;
    }
    this.listaFichas = lista;
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      processo: [null],
      licitacao: [null],
      data_cancelamento: [null],
      observacao: ['-'],
      ativo: [true, [Validators.required]],
      tipo_reserva: ['1'],
      data_adjudicacao: [null],
      valor_adjudicacao: [0],
      declaracao: [false],
      tesouro: [false],
      convenio_estadual: [false],
      convenio_federal: [false],
      fundo_especial: [false],
      administracao_indireta: [false],
      outra_fonte: [false],
      operacao_credito: [false],
      outro_observacao: [],
      data_declaracao: [],
      estaduais: [],
      itens: [],
      orgao: [this.login.orgao, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]]
    });
  }

  protected async beforeSubmit() {
    try {

      if (!this.entidadeForm.get('tipo_reserva').value) {
        throw new Error('Informe o tipo de reserva');
      }

      if (this.entidadeForm.get('tipo_reserva').value === '0' && !this.entidadeForm.get('licitacao').value) {
        throw new Error('Informe o número do processo');
      }
      if (this.entidadeForm.get('licitacao').value?.processo) {
        this.entidadeForm.get('processo').setValue(this.entidadeForm.get('licitacao').value.processo);
      }

      if(!this.entidadeForm.get('licitacao').value && this.entidadeForm.get('tipo_reserva').value === '1'){
        this.entidadeForm.get('licitacao').setValue(null)
      }
      
      this.entidadeForm.get('itens').setValue(this.listaFichas);
      this.entidadeForm.get('estaduais').setValue(this.listaConvenioEstadual);

    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  protected async afterSubmit(ent: ReservaDotacao) {
    window.scrollTo(0, 0);
    // setTimeout(() => this.inputField.nativeElement.focus());
    if (this.limparTela) {
      this.entidadeForm.reset();
      this.router.navigate(['/reservas-dotacao', 'novo']);
    }
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private carregarAutoCompletes() {
    // autocomplete para licitacao
    this.licitacaoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('licitacao'), this.licitacaoService,
      'processo', ['processo', 'modalidade.nome'], {
      orgao_id: this.login.orgao.id,
      relations: 'modalidade',
      orderBy: 'processo,modalidade.nome'
    }, { number: ['id', 'processo'] }
    );

  }

  public alterarTipo(){
    if(this.entidadeForm.get('tipo_reserva').value == 1){
      this.entidadeForm.get('licitacao').setValue('')
    }
    return this.tipo = this.entidadeForm.get('tipo_reserva').value;
  }

  public voltar() {
    window.history.back();
  }

  public carregarNumProcesso(): boolean {
    if (this.parametro_sem_pre) {
      return true;
    } else return this.entidadeForm.get('tipo_reserva').value === '0';
  }


}
