import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ContratoService } from 'administrativo-lib';
import { ProdutoService, ProdutoUnidadeService } from 'almoxarifado-lib';
import { Aliquota, BaseResourceItemsComponent, EddyAutoComplete, FuncaoService, GlobalService, Produto, ProdutoUnidade, Rcms, RcmsItem, SaldoEstoqueService } from 'eddydata-lib';
import { Alignment, Workbook } from 'exceljs';
import { AutoComplete } from 'primeng/autocomplete';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ContratoItemService } from '../../contrato/service/contrato-item.service';
import { RcmsItemService } from '../service/rcms-item.service';

declare var $: any;
@Component({
  selector: 'app-rcms-item',
  templateUrl: './rcms-item.component.html'
})
export class RcmsItemComponent extends BaseResourceItemsComponent<RcmsItem> implements OnChanges {

  @ViewChild('produto') produtoInput: AutoComplete;
  @ViewChild('qtd_') qtdInput: AutoComplete;

  public imaskValor = {
    mask: Number,
    scale: 6,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  @Input() somenteVisualizar: boolean = false;
  @Input() rcms: Rcms;
  @Output() visualizaDescricaoItens: EventEmitter<boolean> = new EventEmitter();

  public produtoAutoComplete: EddyAutoComplete<Produto>;

  public produtoUnidades: ProdutoUnidade[] = [];

  public alfabetico: boolean = false;
  protected datepipe: DatePipe;

  public parametrosBusca: any
  public mostrarDescricao: boolean = false;

  constructor(private produtoUnidadeService: ProdutoUnidadeService,
    private produtoService: ProdutoService,
    public funcaoService: FuncaoService,
    public globalService: GlobalService,
    public rcmsItemService: RcmsItemService,
    public contratoService: ContratoService,
    private saldoEstoqueService: SaldoEstoqueService,
    public contratoItemService: ContratoItemService) {
    super(new RcmsItem(), rcmsItemService);
    this.autoAdd = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rcms) {
      this.carregarAutoCompletes();
    }
  }

  protected campoFoco() {
    return this.produtoInput;
  }

  protected afterInit(): void {
    this.datepipe = new DatePipe('pt');
  }

  protected afterEdit(item: RcmsItem) {
    this.carregarAutoCompletes();
    if (!item.produto_unidade)
      item.produto_unidade = new ProdutoUnidade();
    if (!item.ordem)
      item.ordem = this.lista.length + 1;
    this.carregarUnidades(item);
    if (this.rcms?.contrato?.id && (!this.rcms?.licitacao?.tabela_desconto) && item.produto_unidade.id) {
      this.contratoItemService.obterSaldoProdutoContrato(this.login.orgao.id, this.rcms.contrato.id, item.produto_unidade.id)
        .subscribe((data) => {
          if (data) {
            item['saldo_qtd'] = data['saldo_qtd'];
          }
        });
    }
  }

  protected validSave(item: RcmsItem): boolean {
    if (!item.quantidade) {
      toastr.warning('Informe a quantidade para o item!');
      return false;
    }
    if (!item.produto_unidade?.id) {
      if (item.produto_unidade?.produto?.nome)
        toastr.warning(`Serviço vínculado é proveniente de conversão, necessario cadastrar o serviço para alterar o dados.`)
      else
        toastr.warning(`Informe produto/serviço para o item`)
      return false;
    }
    if (this.lista) {
      let duplicado = this.lista.find((l) => l.produto_unidade.id === item.produto_unidade.id && !l['editavel']);
      if (duplicado) {
        toastr.warning('Item ja vinculado!');
        return false;
      }
    }
    if (item['saldo_qtd'] && (item['saldo_qtd'] - item.quantidade) < 0  && !this.rcms.contrato?.saldo_valor) {
      toastr.warning(`Quantidade informada para o item ${item.produto_unidade.produto.nome} excede o saldo atual de ${this.funcaoService.convertToBrNumber(item['saldo_qtd'], 5)}`);
      return false;
    }
    return true;
  }

  protected validRemover(item: RcmsItem): boolean {
    if (!item.cotacoes)
      return true;
    for (let cotacao of item.cotacoes) {
      if (cotacao.valor_unitario > 0) {
        toastr.warning(`Item vinculado a cotação, remova a cotação para alterar ou remover o item`);
        return false;
      }
    }
    return true;
  }

  protected beforeSave(item: RcmsItem): void {
    this.saldoEstoqueService.saldoProdutoPorAlmoxarifado(item.produto_unidade.produto.id, this.login.orgao.id)
      .subscribe((data) => {
        let com_saldo = data.filter((s) => s.saldo_atual > 0);
        if (com_saldo.length > 0)
          toastr.warning(`Produto com saldo no(s) estoque(s)(${com_saldo.map((s) => s.estoque).join(',')}).`)
      });
    if (!this.rcms.contrato || !this.rcms.contrato.numero) {
      this.contratoService.filtrar(1, -1, {
        'data_termino$ge': this.datepipe.transform(new Date(), 'yyyy-MM-dd'),
        'itens.produto_unidade.id': item.produto_unidade.id,
        inativo: false,
        excluido: false,
        'orgao.id': this.login.orgao.id
      }).subscribe((data) => {
        if (data && data.content && data.content.length > 0) {
          toastr.warning(`Produto com os seguinte(s) contrato(s) em aberto(${data.content.map((c) => c.numero).join(',')}).`);
        }
      });
    }
    if (this.login.parametro['requisicao']
      && this.login.parametro['requisicao'].requisicao_alfabetico)
      this.alfabetica();
    else
      this.reordenar();
  }

  verificaContratoItem() {
    if (!this.rcms.contrato || !this.rcms.contrato.numero) {
      this.lista.forEach((i) => {
        this.contratoService.filtrar(1, -1, {
          'data_termino$ge': this.datepipe.transform(new Date(), 'yyyy-MM-dd'),
          inativo: false,
          excluido: false,
          'itens.produto_unidade.id': i.produto_unidade.id,
          'orgao.id': this.login.orgao.id
        }).subscribe((data) => {
          i['aviso'] = (data && data.content && data.content.length > 0 ?
            `Produto disponível no contrato: ${(data.content.map((c) => c.numero.toString().substring(0, 4) + '/' + c.numero.toString().substring(4)).join(','))}` : '');
        });
      });
    }
  }

  public disabled(): boolean {
    if (!this.rcms?.somente_produto && !this.rcms?.somente_servico) {
      if (this.somenteVisualizar || !this.rcms.subelemento || !this.rcms.subelemento.codigo) {
        return true;
      }
    } else if ((this.rcms.somente_produto || this.rcms.somente_servico) && this.somenteVisualizar) {
      return true;
    }
    return super.disabled();
  }

  public disabledOrdenacao() {
    return this.alfabetico;
  }

  public superDisabled() {
    return super.disabled();
  }

  public carregarUnidades(item: RcmsItem) {
    if (!item.produto_unidade?.produto)
      return;

    let parametros = {
      'produto.id': item.produto_unidade.produto.id,
      ativo: true,
      relations: ['produto.material.sub_grupo.grupo', 'unidade', 'produto.material.aliquota', 'produto.aliquota']
    };
    if (this.rcms?.processo && !this.rcms?.licitacao?.tabela_desconto)
      parametros['memoriais.licitacao.processo'] = this.rcms?.processo;

    this.produtoUnidadeService.filtrarCodigoCompleto(1, -1, parametros).pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (!data)
          return;
        this.produtoUnidades = data.content;
        if (this.produtoUnidades && this.produtoUnidades.length > 0 && !item.produto_unidade.id)
          item.produto_unidade = this.produtoUnidades[0];
      }, (error) => toastr.error(error.error.payload));
  }


  private carregarAutoCompletes() {
    this.parametrosBusca = this.parametrosProdutos();
    this.parametrosBusca['orderBy'] = 'codigo,nome';
    this.parametrosBusca['orgao.id'] = this.login.orgao.id;

    this.produtoAutoComplete = ProdutoService.autoCompleteCodigoCompleto(null, this.produtoService,
      'id', ['codigo', 'nome'], this.parametrosBusca, { number: ['id', 'codigo'], text: ['codigo', 'nome'] }
    );
    if (window.location.href.toString().indexOf('/autorizacao') > 0 && this.lista) {
      if (this.lista.length > 0) this.verificaContratoItem();
    }
  }

  public parametrosProdutos(): {} {
    let parametros = {};
    parametros['inativo'] = false;
    parametros['desabilita_compra'] = false;
    if (this.rcms && this.rcms.subelemento && this.rcms.subelemento.codigo) {
      parametros['valid_sub_elemento$like'] = `%${this.rcms.subelemento.id}%`;
    } else if (this.rcms && (this.rcms.somente_produto || this.rcms.somente_servico)) {
      parametros['material.servico'] = this.rcms.somente_servico;
    }
    if (this.rcms?.processo && !this.rcms?.licitacao?.tabela_desconto)
      parametros['unidades.memoriais.licitacao.processo'] = this.rcms.processo;
    return parametros;
  }

  @HostListener('window:keydown.control.p', ['$event'])
  public abrirConsultaProduto(event: KeyboardEvent) {
    event.preventDefault();
    if (this.usarAtalho) {
      $('#dialogProdutoUnBusca').modal('show');
    }
  }

  public callbackProdutoUn(prodUn: ProdutoUnidade) {
    let item = this.selecionarEditavel();
    if (item) {
      item.produto_unidade = prodUn;
      this.carregarUnidades(item);
      this.funcaoService.focarCampo(this.qtdInput);
    }
  }

  public somarQuantidade(): number {
    let soma = 0.0;
    if (!this.lista || this.lista.length === 0)
      return soma;
    for (let item of this.lista) {
      if (item.quantidade)
        soma += +item.quantidade;
    }
    return soma;
  }

  public refazerOrdenacao(event: CdkDragDrop<string[]>) {
    if (this.disabled())
      return;
    moveItemInArray(this.lista, event.previousIndex, event.currentIndex);
    this.lista = this.lista.map((l, i) => {
      l.ordem = i + 1;
      return l;
    });
    this.reordenar();
  }

  public alfabetica() {
    this.lista = this.lista
      .sort((a, b) => a.produto_unidade.produto.nome.localeCompare(b.produto_unidade.produto.nome))
      .map((l, i) => {
        l.ordem = i + 1;
        return l;
      });
    this.reordenar();
  }

  public reordenar() {
    this.lista = this.lista.sort((a, b) => a.ordem - b.ordem);
  }


  public ordem(lista: RcmsItem[]) {
    let l: RcmsItem[] = Object.assign([], lista);
    if (this.alfabetico)
      l = l
        .sort((a, b) => {
          if (a['adicionado'])
            return -1;
          if (b['adicionado'])
            return 1;
          return a.produto_unidade.produto.nome
            .localeCompare(b.produto_unidade.produto.nome);
        });
    return l;
  }

  public mostrarDescricaoItens() {
    this.visualizaDescricaoItens.emit(this.mostrarDescricao);
  }

  public async exportarMapaPreco() {
    if (!this.rcms.id) return;
    const algCenter: Partial<Alignment> = { vertical: 'middle', horizontal: 'center' };
    const algRight: Partial<Alignment> = { vertical: 'middle', horizontal: 'right' };

    const wb = new Workbook();
    wb.creator = this.login.usuario.nome;
    wb.lastModifiedBy = this.login.usuario.nome;
    wb.created = new Date();
    wb.modified = new Date();
    wb.calcProperties.fullCalcOnLoad = true;

    const ws = wb.addWorksheet('Requisição', { views: [{ state: 'frozen', xSplit: 0, ySplit: 4, activeCell: 'E5' }] });

    ws.columns = [
      { key: 'item', width: 5, alignment: algCenter, protection: { locked: true } },
      { key: 'unidade', width: 10, alignment: algCenter, protection: { locked: true } },
      { key: 'quantidade', width: 10, alignment: algRight, protection: { locked: true } },
      { key: 'descricao', width: 120., alignment: { vertical: 'middle', horizontal: 'left' }, protection: { locked: true } },
      { key: 'valor', width: 12, alignment: algRight },
      { key: 'total', width: 24, alignment: algRight, protection: { locked: true } }
    ];

    ws.getCell('A1').value = 'Código:';
    ws.getCell('A1').alignment = algCenter;
    ws.mergeCells('A1:B1');

    ws.getCell('C1').value = this.rcms.id;
    ws.getCell('C1').alignment = algCenter;

    ws.getCell('D1').value = 'ARQUIVO DE COTAÇÃO DE PREÇOS PARA PREENCHIMENTO DE REQUISIÇÃO DE COMPRA DE PRODUTOS E SERVIÇOS';
    ws.getCell('D1').alignment = algCenter;
    ws.getCell('D1').font = { bold: true, size: 16 };
    ws.mergeCells('D1:G1');

    ws.getCell('A2').value = 'Documento';
    ws.getCell('A2').alignment = algCenter;
    ws.getCell('A2').font = { bold: true, size: 12, color: { argb: 'FFFF0000' } };
    ws.mergeCells('A2:C2');

    ws.getCell('D2').value = this.login.orgao.nome + ' - ' + this.login.cidade.nome;
    ws.getCell('D2').alignment = algCenter;
    ws.getCell('D2').font = { bold: true, size: 12 };
    ws.mergeCells('D2:G2');

    ws.getCell('A3').value = 'Informe o Documento';
    ws.getCell('A3').alignment = algCenter;
    ws.getCell('A3').protection = { locked: false };
    ws.getCell(`A3`).numFmt = '[<=9999]0000;[>=999999999999]00"."000"."000"/"0000-00;000"."000"."000-00';
    ws.getCell('A3').font = { size: 12, color: { argb: 'FFFF0000' } };
    ws.mergeCells('A3:C3');

    ws.getRow(4).values = ['Item', 'Und.', 'Qtde', 'Descrição', 'Valor Unit.', 'Total'];
    ws.getRow(4).alignment = algCenter;
    ws.getRow(4).font = { bold: true };

    let linha = 5;
    this.lista.forEach((item) => {
      ws.getRow(linha);

      ws.getCell(`A${linha}`).value = item.ordem;
      ws.getCell(`A${linha}`).alignment = algCenter;

      ws.getCell(`B${linha}`).value = item.produto_unidade.unidade.nome;
      ws.getCell(`B${linha}`).alignment = algCenter;

      ws.getCell(`C${linha}`).value = parseFloat(item.quantidade.toString()).toFixed(3).replace('.', ',');
      ws.getCell(`C${linha}`).alignment = algCenter;

      ws.getCell(`D${linha}`).value = item.produto_unidade.produto.nome;
      ws.getCell(`D${linha}`).alignment = { wrapText: true }

      ws.getCell(`E${linha}`).alignment = algRight;
      ws.getCell(`E${linha}`).numFmt = '#,##0.000;[Red]\-#,##0.000';
      ws.getCell(`E${linha}`).protection = { locked: false };
      ws.getCell(`E${linha}`).dataValidation = {
        type: 'decimal',
        operator: 'lessThanOrEqual',
        allowBlank: true,
        showInputMessage: true,
        formulae: [0],
        promptTitle: 'Valor Inválido!',
        prompt: 'O valor informado deve ser positivo'
      };

      ws.getCell(`F${linha}`).value = { formula: `C${linha} * E${linha}`, date1904: true };
      ws.getCell(`F${linha}`).alignment = algRight;
      ws.getCell(`F${linha}`).numFmt = '#,##0.000;[Red]\-#,##0.000';

      linha++;
    })

    await ws.protect('842d51933523ffbcdd1836e7d4a9323ff8b9cdd183', {});

    wb.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const downloadURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = `mapa-precos_${this.rcms.numero}.xlsx`;
      link.target = '_blank';
      link.click();
      window.URL.revokeObjectURL(downloadURL);
    })
  }

  public aliquotaTitle(item: RcmsItem) {
    let aliquota: Aliquota
    let titleItem = ''

    if (item?.produto_unidade?.produto?.aliquota) aliquota = item.produto_unidade.produto.aliquota
    else if (item?.produto_unidade?.produto?.material?.aliquota) aliquota = item.produto_unidade.produto.material.aliquota

    if (aliquota) {
      titleItem = `${aliquota.resumo} - IR: ${aliquota.ir}% | CSLL: ${aliquota.csll}% | COFINS: ${aliquota.cofins}% | PIS / PASEP: ${aliquota.pis_pasep}%`
    }
    return titleItem
  }
}