// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class Assunto extends BaseResourceModel {

  constructor(
    public id?: number,
    public nome?: string,
    public exigir_cobranca?: boolean,
    public orgao?: Orgao,
    public data_cadastro?: Date,
    public data_alteracao?: Date
    ) {
    super();
  }
  static converteJson(json: any): Assunto {
    return Object.assign(new Assunto(), json);
  }

}
