import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Usuario } from '../../entidade/comum/usuario.model';
import { Orgao } from '../../entidade/comum/orgao.model';
import { FuncaoService } from '../../util/funcao.service';
import { GlobalService } from '../../util/global.service';
import { OrgaoService } from '../../orgao/service/orgao.service';
import { UsuarioService } from '../../usuario/service/usuario.service';

@Component({
  selector: 'lib-cidadao-registro',
  templateUrl: './cidadao-registro.component.html',
  styleUrls: ['./cidadao-registro.component.css']
})
export class CidadaoRegistroComponent implements OnInit, OnDestroy {

  @ViewChild('nome') nomeField: ElementRef;
  @ViewChild('f') myForm: NgForm;

  @Input() sistema: string;

  public entidade: Usuario = new Usuario();
  public listaOrgaos: Array<Orgao>;
  public listaSistemas: Array<any>;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected orgaoService: OrgaoService,
    protected usuarioService: UsuarioService) {
  }

  ngOnInit() {
    this.nomeField.nativeElement.focus();
    this.orgaoService.obterTodos().pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaOrgaos = [];

        for (const model of res) {
          this.listaOrgaos.push(model);
          if (model.codigo.replace('.', '') === '020000') {
            this.entidade.orgao = model;
          }
        }
      });
    this.listaSistemas = this.globalService.obterListaSistemas();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public registrar(): void {
    if (this.myForm.valid) {
      this.entidade.convidado = true;
      this.entidade.sistema = 'e-sic';
      this.usuarioService.registrarSic(this.entidade)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data) => {
            this.entidade = new Usuario();
            this.myForm.reset();
            // reseta os campos
            // tslint:disable-next-line: forin
            for (const name in this.myForm.controls) {
              this.myForm.controls[name].setErrors(null);
            }
            this.router.navigate([`${this.sistema}/registro-sucesso`]);
          },
          error => this.funcaoService.acaoErro(error)
        );
    }
  }

  public sair() {
    this.router.navigate([`${this.sistema}/login`]);
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

}
