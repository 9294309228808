import { Directive, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { QuadroSaudeService } from '../service/quadro-saude.service';
import {
  FuncaoService, GlobalService, Login,
  Exercicio, FuncaoGoverno, Relatorio, FormatoExportacao, Coluna,
  OrgaoAssinatura,
  OrgaoAssinaturaService
} from 'eddydata-lib';

@Directive()
export class Quadro1Saude implements OnDestroy {
  private assinatura: OrgaoAssinatura;
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected quadroServico: QuadroSaudeService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected assinaturaService: OrgaoAssinaturaService,
    protected funcao?: FuncaoGoverno,) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async listar(orgaos: number[]) {
    return await this.quadroServico
      .obterQuadro1(this.mes, this.exercicio.id, orgaos).toPromise();
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
    this.quadroServico.obterQuadro1(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(
            'QUADRO 1 - RECEITAS DE IMPOSTOS'
            , this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.conteudo(dados),
            'portrait', 'QUADRO 1 - RECEITAS DE IMPOSTOS',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            });
        } else {
          this.funcaoService.exportar(formato, this.conteudoExportacao(dados), 'QUADRO 1 - RECEITAS DE IMPOSTOS', this.colunasRelatorio());
        }
      });
  }

  private async conteudo(dados: any[]) {

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `REFERÊNCIA: ${this.mes ? this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano : this.exercicio.ano}`,
        alignment: 'center',
        fontSize: 11,
        margin: [5, 5, 5, 5],
        bold: true,
        colSpan: 4
      }, '', '', ''],
      [{
        text: 'A) RECEITAS TOTAIS DE IMPOSTOS E TRANSFERÊNCIAS',
        alignment: 'left',
        bold: true,
        colSpan: 4
      }, '', '', ''],
      [{
        text: 'DISCRIMINAÇÃO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'PREVISÃO INICIAL DO EXERCÍCIO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'PREVISÃO ATUALIZADA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'ARRECADAÇÃO ATÉ O TRIMESTRE',
        alignment: 'center',
        bold: true, fontSize: 6
      }]
    ];
    let grupos: any;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    // monta o agrupamento do relatório
    const titulos = this.funcaoService.agrupar(dados[0], 'titulo',
      ['previsao', 'atualizado', 'arrecadado']);
    for (const titulo of titulos) {
      registros.push([
        {
          text: titulo.grupo, bold: true, fontSize: 8
        },
        {
          text: this.funcaoService.convertToBrNumber(titulo.totalizadores['previsao']),
          alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(titulo.totalizadores['atualizado']),
          alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(titulo.totalizadores['arrecadado']),
          alignment: 'right', bold: true, fontSize: 7
        }
      ]);

      grupos = this.funcaoService.agrupar(titulo.registros, 'tipo',
        ['previsao', 'atualizado', 'arrecadado']);

      for (const grupo of grupos) {
        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsao']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(grupo.totalizadores['atualizado']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']),
            alignment: 'right', bold: true, fontSize: 7
          }
        ]);
        total1 += +grupo.totalizadores['previsao'];
        total2 += +grupo.totalizadores['atualizado'];
        total3 += +grupo.totalizadores['arrecadado'];

        for (const item of grupo.registros) {
          if ((+item.previsao + +item.atualizado + +item.arrecadado) !== 0) {
            registros.push([
              {
                text: item.nome, fontSize: 7
              },
              { text: this.funcaoService.convertToBrNumber(item.previsao), alignment: 'right', fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(item.atualizado), alignment: 'right', fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(item.arrecadado), alignment: 'right', fontSize: 7 }
            ]);
          }
        }
      }
      registros.push([
        {
          text: 'TOTAL', fontSize: 7, bold: true
        },
        { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 7, bold: true },
        { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 7, bold: true },
        { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 7, bold: true }
      ]);
    }

    if (this.login.parametro?.contabil?.assinatura_por_funcao) {
      this.assinatura = await this.assinaturaService.obter({ orgao_id: this.login.orgao.id }).toPromise();

      const assinatura = [
        [
          {
            text: '', alignment: 'center',
            border: [false, false, false, false], margin: [0, 30, 0, 0]
          },
          {
            text: this.assinatura.ordenador_despesa_funcao_10 ? '__________________________________________' : '', alignment: 'center',
            border: [false, false, false, false], margin: [0, 30, 0, 0]
          },
          {
            text: '', alignment: 'center',
            border: [false, false, false, false], margin: [0, 30, 0, 0]
          }
        ],
        [
          { text: '', alignment: 'center', border: [false, false, false, false] },
          { text: this.assinatura.ordenador_despesa_funcao_10 ? this.assinatura.ordenador_despesa_funcao_10 : '', alignment: 'center', border: [false, false, false, false] },
          { text: '', alignment: 'center', border: [false, false, false, false] }
        ],
        [
          { text: '', alignment: 'center', border: [false, false, false, false] },
          { text: this.assinatura.cargo_ordenador_despesa_funcao_10 ? this.assinatura.cargo_ordenador_despesa_funcao_10 : '', alignment: 'center', border: [false, false, false, false] },
          { text: '', alignment: 'center', border: [false, false, false, false] }
        ],
      ];

      return [{
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 55, 55, 55],
          body: registros
        }
      }, {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          heights: [10, 0],
          widths: ['*', '*', '*'],
          body: assinatura
        }
      }];
    } else {
      return [{
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 55, 55, 55],
          body: registros
        }
      }];
    }
  }

  private colunasRelatorio(): Coluna[] {
    return [
      { titulo: 'DISCRIMINAÇÃO', coluna: 'titulo' },
      { titulo: 'PREVISÃO INICIAL DE EXERCÍCIO', coluna: 'previsao' },
      { titulo: 'PREVISÃO ATUALIZADA', coluna: 'atualizado' },
      { titulo: 'ARRECADAÇÃO ATÉ O TRIMESTRE', coluna: 'arrecadado' },
    ];
  }

  private conteudoExportacao(dados: any[]) {
    const titulos = this.funcaoService.agrupar(dados[0], 'titulo', ['previsao', 'atualizado', 'arrecadado']);
    const listaItens = new Array();
    let grupos: any;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    for (const titulo of titulos) {
      listaItens.push({
        'titulo': titulo.grupo,
        'previsao': this.funcaoService.convertToBrNumber(titulo.totalizadores['previsao'], 2),
        'atualizado': this.funcaoService.convertToBrNumber(titulo.totalizadores['atualizado'], 2),
        'arrecadado': this.funcaoService.convertToBrNumber(titulo.totalizadores['arrecadado'], 2),
      });

      grupos = this.funcaoService.agrupar(titulo.registros, 'tipo', ['previsao', 'atualizado', 'arrecadado']);

      for (const grupo of grupos) {
        listaItens.push({
          'titulo': grupo.grupo,
          'previsao': this.funcaoService.convertToBrNumber(grupo.totalizadores['previsao'], 2),
          'atualizado': this.funcaoService.convertToBrNumber(grupo.totalizadores['atualizado'], 2),
          'arrecadado': this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado'], 2),
        });

        total1 += +grupo.totalizadores['previsao'];
        total2 += +grupo.totalizadores['atualizado'];
        total3 += +grupo.totalizadores['arrecadado'];

        for (const item of grupo.registros) {
          if ((+item.previsao + +item.atualizado + +item.arrecadado) !== 0) {
            listaItens.push({
              'titulo': item.nome,
              'previsao': this.funcaoService.convertToBrNumber(item.previsao, 2),
              'atualizado': this.funcaoService.convertToBrNumber(item.atualizado, 2),
              'arrecadado': this.funcaoService.convertToBrNumber(item.arrecadado, 2),
            });
          }
        }
      }
      listaItens.push({
        'titulo': 'TOTAL',
        'previsao': this.funcaoService.convertToBrNumber(total1, 2),
        'atualizado': this.funcaoService.convertToBrNumber(total2, 2),
        'arrecadado': this.funcaoService.convertToBrNumber(total3, 2),
      });
    }
    return listaItens;
  }

}
