import { Injectable, Injector } from '@angular/core';
import { Schedule } from '../../entidade/comum/schedule.model';
import { BaseResourceService } from '../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService extends BaseResourceService<Schedule> {

  constructor(
    protected injector: Injector
  ) {
    super('schedules', injector);
  }
}
