import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { ToastModule } from 'primeng/toast';
import { AliquotaFormComponent } from './aliquota-form/aliquota-form.component';
import { AliquotaListComponent } from './aliquota-list/aliquota-list.component';
import { AliquotasRoutingModule } from './aliquotas-routing.module';

@NgModule({
  declarations: [AliquotaListComponent, AliquotaFormComponent],
  imports: [
    CommonModule,
    AliquotasRoutingModule,
    SharedModule,
    FormsModule,
    ToastModule,
    IMaskModule,
  ]
})
export class AliquotaModule { }
