import { Injectable, Injector } from '@angular/core';
import { BaseResourceService } from '../../models/services/base-resource.service';
import { Exercicio } from '../../entidade/comum/exercicio.model';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Page } from '../../util/page';


@Injectable({
  providedIn: 'root'
})
export class ExercicioService extends BaseResourceService<Exercicio> {

  constructor(
    protected injector: Injector
  ) {
    super(`exercicios`, injector);
  }

  // public pesquisarTransparenciaGeral(filtro: string, exercicio: number, orgao: number): Observable<any> {
  //   return this.http.get<any>(
  //     `${this.login.cidade.id}/${this.api}/transparencia/pesquisa/geral?filtro=${filtro}&exercicio=${exercicio}&orgao=${orgao}`,
  //     this.httpOptions()).pipe(
  //       map(res => res),
  //       catchError(err => this.handleError(err))
  //     );
  // }

  public obterPorAno(ano: number, cidadeId: number): Observable<any> {
    return this.http.get<any>(
      `${cidadeId}/${this.api}/filtrar?ano=${ano}&cidade_id=${cidadeId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterTodosOrdenadoPorAno(cidadeId: number): Observable<Page> {
    return this.http.get<Page>(
      `${cidadeId}/${this.api}/filtrar?orderBy=ano$DESC&cidade_id=${cidadeId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public proximoAno(cidade: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-ano/${cidade}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public exportarOrcamentoAutarquias(orgaoId: number, exercicioId: number, mes: number, opcoes: any) {
    let parametros = '';
    for (const key in opcoes) {
      if (parametros === '') {
        parametros += '?';
      } else {
        parametros += '&';
      }
      parametros += key + '=' + opcoes[key];
    }
    return this.http.get(
      `${this.login.cidade.id}/${this.api}/exportar-orcamento-autarquias/${orgaoId}/${exercicioId}/${mes}${parametros}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    )
  }
}
