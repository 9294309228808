import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LicitacaoAudesp } from 'eddydata-lib';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'lib-licitacao-audesp-menu',
  templateUrl: './licitacao-audesp-menu.component.html'
})
export class LicitacaoAudespMenuComponent {

  @Input() public entidade: LicitacaoAudesp;

  constructor() { }

}