import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BaseResourceFormComponent, FuncaoService, GlobalService, LoginContabil, Programa
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { ProgramaService } from '../service/programa.service';

@Component({
  selector: 'lib-programa-form',
  templateUrl: './programa-form.component.html'
})
export class ProgramaFormComponent extends BaseResourceFormComponent<Programa, LoginContabil> implements OnInit {

  /**
   * Declaração de variáveis
   */
  @ViewChild('nome') inputField: ElementRef;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected programaService: ProgramaService) {
    super(new Programa(), injector, Programa.converteJson, programaService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      codigo: [null, [Validators.required]],
      nome: [null, [Validators.required]],
      descricao: [null],
      ppa: [this.login.ppa, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'ppa' };
  }

  protected afterLoad() {
  }

  protected afterInit(): void {
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected beforeSubmit() {
    try {
      if (this.entidadeForm.get('nome').value === '') {
        throw new Error('Informe o nome da programa!');
      }
      this.entidadeForm.get('ppa').setValue(this.login.ppa);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(ent: Programa) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================



}
