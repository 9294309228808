// tslint:disable: variable-name
import { OrdemServico } from 'eddydata-lib';
import { TipoAquisicaoVeiculo } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Setor } from '../almoxarifado/setor.model';
import { Favorecido } from '../compra/favorecido.model';
import { Orgao } from '../comum/orgao.model';
import { Tombamento } from '../patrimonio/tombamento.model';
import { Combustivel } from './combustivel.model';
import { Motorista } from './motorista.model';
import { Portaria } from './portaria.model';
import { TipoVeiculo } from './tipo-veiculo.model';
import { VeiculoCombustivel } from './veiculo-combustivel.model';
import { VeiculoOcorrencia } from './veiculo-ocorrencia.model';

export class Veiculo extends BaseResourceModel {
  constructor(
    public id?: number,
    public descricao?: string,
    public placa_veiculo?: string,
    public pendente?: boolean,
    public ativo?: boolean,
    public rfid?: string,
    public frota?: string,
    public modelo?: string,
    public marca?: string,
    public chassis?: string,
    public renavam?: string,
    public aquisicao?: TipoAquisicaoVeiculo,
    public ano_fabricacao?: number,
    public ano_modelo?: number,
    public cor?: number,
    public tipo_seguro?: string,
    public capacidade_tanque?: number,
    public garantia?: number,
    public observacao?: string,
    public tipo_veiculo?: TipoVeiculo,
    public combustivel?: Combustivel,
    public seguradora?: Favorecido,
    public portaria?: Portaria,
    public motorista?: Motorista,
    public tombamento?: Tombamento,
    public orgao?: Orgao,
    public combustiveis_abastecimento?: VeiculoCombustivel[],
    public ocorrencias?: VeiculoOcorrencia[],
    public ordens_servicos?: OrdemServico[],
    public setor?: Setor,
    public km_atual?: number,
    public ult_abastecimento?: number,
    public ult_revisao?: number,
    public prox_revisao?: number,
    public prox_oleo?: number,
    public prox_pneu?: number,
    public prox_correia?: number,
    public prox_extintor?: Date,
    public prox_bateria?: number,
    public consumo_medio?: number,
    public terceiros?: boolean,
    
  ) {
    super();
  }

  static converteJson(json: any): Veiculo {
    return Object.assign(new Veiculo(), json);
  }
}
