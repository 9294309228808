import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { BaseResourceService } from '../../models/services/base-resource.service';
import { Executora } from '../../entidade/planejamento/executora.model';

@Injectable({
  providedIn: 'root'
})
export class ExecutoraService extends BaseResourceService<Executora> {

  constructor(
    protected injector: Injector
  ) {
    super(`executoras`, injector);
  }

  public anexo4(ppa: number, orgaos: number[]): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.login.cidade.id}/${this.api}/anexo4/${ppa}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        take(1),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorCodigo(codigo: string, ppaId: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/filtrar?codigo=${codigo}&unidade.ppa_id=${ppaId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorNome(nome: string, ppaId: number): Observable<Executora> {
    return this.http.get<Executora>(`${this.login.cidade.id}/${this.api}/filtrar?nome$like=${nome}%&unidade.ppa_id=${ppaId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
