import { Injectable, Injector } from "@angular/core";
import { BaseResourceService, NotaExplicativaStorage } from "eddydata-lib";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class NotaExplicativaStorageService extends BaseResourceService<NotaExplicativaStorage> {

  constructor(
    protected injector: Injector
  ) {
    super(`nota-explicativa-storages`, injector);
  }

  public upload(arquivos: any[], orgao: number, nota: number): Observable<NotaExplicativaStorage[]> {
    const formData = new FormData();
    for (let arquivo of arquivos) {
      formData.append('files', arquivo);
    }

    const headers = this.httpOptions();
    headers.headers = headers.headers.delete('Content-Type');

    return this.http.post<NotaExplicativaStorage[]>(`${this.login.cidade.id}/${this.api}/upload/${orgao}/${nota}`,
      formData, headers)
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}