import { Component, Input, OnInit } from '@angular/core';
import { ContratoPppService } from 'administrativo-lib';
import * as toastr from "toastr";
import { EventoContabilService } from 'contabil-lib';
import { BaseResourceItemsComponent, Contrato, GlobalService, Login, ContratoPpp } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'lib-contrato-ppp',
  templateUrl: './contrato-ppp.component.html',
})

export class ContratoPppComponent extends BaseResourceItemsComponent<ContratoPpp> implements OnInit {
  @Input() contrato: Contrato;
  @Input() public login: Login;

  public listaMeses: Array<any>;
  public mes = 1;
  public listaEspecies: { id: string; nome: string; }[];
  public ano: number;
  public valor: number = 0;
  public listaEventos: Array<any>;
  public especie: string = 'PP1';

  public imaskValor = {
    mask: Number,
    scale: 2,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private contratoService: ContratoPppService,
    public globalService: GlobalService,
    protected eventoService: EventoContabilService,
    protected confirmationService: ConfirmationService,
  ) {
    super(new ContratoPpp(), contratoService);
    this.persistence = true;
  }

  ngOnInit() {
    this.listaMeses = [];
    this.listaMeses.push({ label: 'JANEIRO', value: 1 });
    this.listaMeses.push({ label: 'FEVEREIRO', value: 2 });
    this.listaMeses.push({ label: 'MARÇO', value: 3 });
    this.listaMeses.push({ label: 'ABRIL', value: 4 });
    this.listaMeses.push({ label: 'MAIO', value: 5 });
    this.listaMeses.push({ label: 'JUNHO', value: 6 });
    this.listaMeses.push({ label: 'JULHO', value: 7 });
    this.listaMeses.push({ label: 'AGOSTO', value: 8 });
    this.listaMeses.push({ label: 'SETEMBRO', value: 9 });
    this.listaMeses.push({ label: 'OUTUBRO', value: 10 });
    this.listaMeses.push({ label: 'NOVEMBRO', value: 11 });
    this.listaMeses.push({ label: 'DEZEMBRO', value: 12 });
    this.ano = this.login.exercicio.ano;
    this.valor = 0;
    this.listaEventos = this.globalService.obterListaEventos().filter(evento => ['PP1', 'PP2', 'PP3', 'PP4', 'PP5', 'PP6', 'PP7', 'PP8', 'PP9', 'PP10'].includes(evento.id));
  }

  protected afterInit() {

  }

  protected async validSave(item: ContratoPpp) {
    item.mes = +this.mes;

    if (!item.contrato) {
      item.contrato = new Contrato();
    }

    item.contrato.id = this.contrato.id;

    if (this.especie) {
      item.especie = this.especie
    } else {
      toastr.error(`Informe um evento válido!`)
      throw new Error(`Informe um evento válido!`);
    }

    if (this.valor > 0) {
      item.valor = this.valor;
    } else {
      toastr.error(`Informe o valor!`)
      throw new Error(`Informe o valor!`);
    }

    if (this.ano && this.ano.toString().length == 4) {
      item.ano = this.ano;
    } else {
      toastr.error(`Informe um ano válido!`)
      throw new Error(`Informe um ano válido!`);
    }

    return true;
  }

  public antesAdicionar(item) {
    this.mes = 1;
    this.ano = this.login.exercicio.ano;
    this.valor = 0;
    this.especie = 'PP1';

    this.adicionar()
  }

  public antesEditar(item) {
    this.mes = item.mes;
    this.ano = item.ano;
    this.valor = item.valor;
    this.especie = item.especie;

    this.editar(item)
  }

  protected tratarErro(erro: any) {
    if (erro.error?.payload) {
      if (erro.error.payload.includes('UNX_contrato_especie')) {
        erro.error.payload = 'Evento duplicado no contrato!'
      }
      if (erro.error.payload.includes('UNX_contrato_ano')) {
        erro.error.payload = 'Ano duplicado no contrato!'
      }
    }
  }

  protected beforeSave(item: ContratoPpp) {
  }
}
