// tslint:disable: variable-name
import { Exercicio } from '../comum/exercicio.model';
import { BaseResourceModel } from '../../models/base-resource.model';
import { PlanoConta } from '../contabil/plano-conta.model';
import { GrupoReceitaItem } from 'eddydata-lib';

export class Receita extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public nome?: string,
    public n1?: string,
    public n2?: string,
    public n3?: string,
    public n4?: string,
    public n5?: string,
    public n6?: string,
    public n7?: string,
    public n8?: string,
    public nivel?: number,
    public escrituracao?: boolean,
    public competencia?: boolean,
    public vinculada_convenio?: boolean,
    public divida_tributaria?: boolean,
    public divida_nao_tributaria?: boolean,
    public descricao?: string,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public ativo?: boolean,
    public exercicio?: Exercicio,
    public plano?: PlanoConta,
    public plano_retencao?: PlanoConta,
    public plano_retencao_contra?: PlanoConta,
    public exigir_contrato?: boolean,
    public grupo_receita_itens?: GrupoReceitaItem[]
  ) {
    super();
  }

  static converteJson(json: any): Receita {
    return Object.assign(new Receita(), json);
  }
}
