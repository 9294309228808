import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido, FavorecidoDependente } from 'eddydata-lib';

export class FavorecidoPlanoSaude extends BaseResourceModel {
    constructor(
        public cnpj?: string,
        public ans?: number,
        public vlrSaude?: number,
        public tipo_inscricao?: number,
        public cnpj_incricao?: string,
        public valor_reembolso?: number,
        public valor_reembolso_anterior?: number,
        public dependente?: FavorecidoDependente,
        public favorecido?: Favorecido

    ) {
        super();
    }
    static converteJson(json: any): FavorecidoPlanoSaude {
        return Object.assign(new FavorecidoPlanoSaude(), json);
    }
}
