import { Component, Injector } from '@angular/core';
import { FichaReceitaService } from 'administrativo-lib';
import { BaseResourceListComponent, Coluna, FichaReceita, Filtro, FormatoExportacao, LoginContabil } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-planilha-arrecadacao',
  templateUrl: './planilha-arrecadacao.component.html'
})
export class PlanilhaArrecadacaoComponent extends BaseResourceListComponent<FichaReceita, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public listaPlanilha: Array<any>;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private fichaService: FichaReceitaService) {
    super(fichaService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  public preencherGrid() {
    this.fichaService.planilhaArrecadacao(this.paginaCorrente,
      this.limite, this.login.exercicio.id, this.login.orgao.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((lista) => {
        this.listaPlanilha = lista.content;
        this.paginaTotal = lista.totalPages;
      });
  }

  protected condicoesGrid(): {} {
    return {};
  }

  protected ordenacaoGrid(): string[] {
    return [];
  }

  protected filtrosGrid(): Filtro {
    return null;
  }

  protected afterInit(): void {
  }

  protected relations(): string {
    return null;
  }

  protected acaoRemover(model: FichaReceita): Observable<FichaReceita> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'r_codigo' },
      { titulo: 'Descrição', coluna: 'r_nome' },
      { titulo: 'Janeiro', coluna: 'arrecadado_jan' },
      { titulo: 'Fevereiro', coluna: 'arrecadado_fev' },
      { titulo: 'Março', coluna: 'arrecadado_mar' },
      { titulo: 'Abril', coluna: 'arrecadado_abr' },
      { titulo: 'Maio', coluna: 'arrecadado_mai' },
      { titulo: 'Junho', coluna: 'arrecadado_jun' },
      { titulo: 'Julho', coluna: 'arrecadado_jul' },
      { titulo: 'Agosto', coluna: 'arrecadado_ago' },
      { titulo: 'Setembro', coluna: 'arrecadado_set' },
      { titulo: 'Outubro', coluna: 'arrecadado_out' },
      { titulo: 'Novembro', coluna: 'arrecadado_nov' },
      { titulo: 'Dezembro', coluna: 'arrecadado_dez' },
      {
        titulo: 'Total', coluna: 'total', funcao: [{
          valor: ['arrecadado_jan', '+', 'arrecadado_fev', '+', 'arrecadado_mar', '+',
            'arrecadado_abr', '+', 'arrecadado_mai', '+', 'arrecadado_jun', '+',
            'arrecadado_jul', '+', 'arrecadado_ago', '+', 'arrecadado_set', '+',
            'arrecadado_out', '+', 'arrecadado_nov', '+', 'arrecadado_dez'
          ]
        }]
      }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    this.fichaService.planilhaArrecadacao(1,
      10000, this.login.exercicio.id, this.login.orgao.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((lista) => {
        if (formato === 'pdf') {
          this.imprimir('PLANILHA DE ARRECADAÇÃO',
            this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
            'Listagem transferencia', ['auto', '*', 40, 40, 40, 40, 40,
            40, 40, 40, 40, 40, 40, 40, 40], lista.content, ['arrecadado_jan', 'arrecadado_fev', 'arrecadado_mar',
            'arrecadado_abr', 'arrecadado_mai', 'arrecadado_jun',
            'arrecadado_jul', 'arrecadado_ago', 'arrecadado_set',
            'arrecadado_out', 'arrecadado_nov', 'arrecadado_dez', 'total'
          ]);
        } else {
          this.exportar(formato, lista.content);
        }
      });

  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
}
