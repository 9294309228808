import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Page, ProdutoUnidade } from 'eddydata-lib';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProdutoUnidadeService extends BaseResourceService<ProdutoUnidade> {

  constructor(
    protected injector: Injector
  ) {
    super(`produto-unidades`, injector);
  }

  public filtrarCodigoCompleto(pagina: number, limite: number, filtros: {}): Observable<Page> {
    let relations: string = filtros['relations'];
    if (relations) {
      if (!relations.includes('produto.material.sub_grupo.grupo'))
        relations = `${relations},produto.material.sub_grupo.grupo`;
    } else {
      relations = `produto.material.sub_grupo.grupo`;
    }
    filtros['relations'] = relations;

    return this.filtrar(pagina, limite, filtros);
  }
}
