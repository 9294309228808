import { Injectable } from '@angular/core';
import {
  Exercicio,
  ExercicioService,
  FormatoExportacao,
  FuncaoService, GlobalService,
  OrgaoAssinatura, OrgaoAssinaturaService, OrgaoService,
  Relatorio
} from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FichaDespesaService } from 'contabil-lib';

@Injectable({
  providedIn: 'root'
})
export class BalanceteDespesaGrupoService {

  protected unsubscribe: Subject<void> = new Subject();
  private assinatura: OrgaoAssinatura;

  constructor(
    protected fichaService: FichaDespesaService,
    protected exercicioService: ExercicioService,
    protected orgaoService: OrgaoService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected assinaturaService: OrgaoAssinaturaService,
  ) { }

  async montarBalanceteDespesa(formato: FormatoExportacao, mes: number, exercicioId: number, orgaos: number[], login: any, dtInicio: string, dtFim: string) {
    const parametros: {} = {};
    if (mes) {
      parametros['mes'] = mes;
    } else {
      parametros['dtInicio'] = dtInicio;
      parametros['dtFim'] = dtFim;
    }

    if (exercicioId) {
      parametros['ano'] = exercicioId;
    }
    parametros['orgaos'] = orgaos.join();
    const ex = await this.exercicioService.obterId(exercicioId).toPromise();
    this.fichaService.balancetePorGrupo(parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        let subtitulo = '';
        if (mes) {
          subtitulo = this.globalService.obterDataBR().monthNames[mes - 1].toLocaleUpperCase() + '/' + ex.ano
        } else {
          let dt = dtInicio.split("-");
          subtitulo = dt[2] + '/' + dt[1] + '/' + dt[0] + ' à ';
          dt = dtFim.split("-");
          subtitulo += dt[2] + '/' + dt[1] + '/' + dt[0];
        }

        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado('DEMONSTRAÇÃO DA DESPESA POR GRUPO DA DESPESA',
            login.usuario.nome, login.usuario.sobrenome, login.orgao.nome, login.brasao,
            this.balanceteDespesaConteudo(dados)
              .concat(await this.conteudoAssinatura(login, ex, mes, dtFim)),
            'landscape', 'Balancete da Despesa por Grupo',
            {
              linhas: {
                hLineWidth(i, node) {
                  return 1;
                },
                vLineWidth(i) {
                  return 1;
                },
                hLineColor(i) {
                  return 'black';
                },
                paddingLeft(i) {
                  return 3;
                },
                paddingRight(i, node) {
                  return 3;
                }
              }
            }, false, false, 'pdf', `REFERÊNCIA: ${subtitulo}`);
        } else if (formato === 'csv') {
          this.balanceteDespesaCSV(dados);
        }
      });
  }

  balanceteDespesaConteudo(dados: any[]): {}[] {
    const registros: {}[] = [
      // [{
      //   text: 'DEMONSTRAÇÃO DA DESPESA POR GRUPO DA DESPESA',
      //   colSpan: 14, alignment: 'center', bold: true, fontSize: 11
      // },
      //   '', '', '', '', '', '', '', '', '', '', '', '', ''],
      [
        { text: 'CODIGO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'DESCRIÇÃO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'ORÇADA', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'MOVIMENTO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'RECURSOS', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'EMPENHADA', alignment: 'center', colSpan: 2, bold: true, fontSize: 7 },
        { text: '' },
        { text: 'SALDO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'LIQUIDADO', alignment: 'center', colSpan: 2, bold: true, fontSize: 7 },
        { text: '' },
        { text: 'SALDO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'PAGAMENTO', alignment: 'center', colSpan: 2, bold: true, fontSize: 7 },
        { text: '' },
        { text: 'DÍVIDA', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 }
      ],
      ['', '', '', '', '',
        { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 7 }, '',
        { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 7 }, '',
        { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 7 }, '']
    ];

    let totalMovimento = 0.00;
    let totalEspecial = 0.00;
    let totalOrcado = 0.00;
    let totalEmpenhadoMes = 0.00;
    let totalEmpenhado = 0.00;
    let totalPagoMes = 0.00;
    let totalPago = 0.00;
    let totalLiquidadoMes = 0.00;
    let totalLiquidado = 0.00;
    let totalRecurso = 0.00;
    let totalSaldo = 0.00;
    let totalSaldoLiquidado = 0.00;
    let totalDivida = 0.00;
    for (const registro of dados) {

      const movimento: number = registro['movimento'];
      const especial: number = registro['especial'];
      const orcado: number = registro['orcado'];
      const empenhadoMes: number = registro['empenhado_mes'];
      const empenhado: number = registro['empenhado'];
      const liquidadoMes: number = registro['liquidado_mes'];
      const liquidado: number = registro['liquidado'];
      const pagoMes: number = registro['pago_mes'];
      const pago: number = registro['pago'];
      const recurso: number = (+movimento + +especial + +orcado);
      const saldo = +recurso - +empenhado;
      const saldoLiquidado = +empenhado - +liquidado;
      const divida = +liquidado - +pago;

      totalMovimento += +movimento + +especial;
      totalEspecial += +especial;
      totalOrcado += +orcado;
      totalEmpenhadoMes += +empenhadoMes;
      totalEmpenhado += +empenhado;
      totalPagoMes += +pagoMes;
      totalPago += +pago;
      totalLiquidadoMes += +liquidadoMes;
      totalLiquidado += +liquidado;
      totalRecurso += recurso;
      totalSaldo += +saldo;
      totalSaldoLiquidado += +saldoLiquidado;
      totalDivida += +divida;

      registros.push([
        { text: this.funcaoService.mascarar('#.#.##.##', registro['d_codigo']), border: [true, false, true, false], fontSize: 7 },
        {
          text: registro['d_nome'],
          border: [true, false, true, false]
        },
        { text: this.funcaoService.convertToBrNumber(orcado), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
        {
          text: this.funcaoService.convertToBrNumber(+movimento + +especial),
          alignment: 'right', border: [true, false, true, false], fontSize: 7
        },
        { text: this.funcaoService.convertToBrNumber(recurso), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(empenhadoMes), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(empenhado), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(saldo), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(liquidadoMes), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(liquidado), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(saldoLiquidado), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(pagoMes), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(pago), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(divida), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
      ]);
    }

    registros.push([
      {
        text: 'TOTAL GERAL:', colSpan: 2,
        bold: true, fontSize: 7
      },
      '',
      { text: this.funcaoService.convertToBrNumber(totalOrcado), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(totalMovimento), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(totalRecurso), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(totalEmpenhadoMes), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(totalEmpenhado), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(totalSaldo), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(totalLiquidadoMes), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(totalLiquidado), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(totalSaldoLiquidado), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(totalPagoMes), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(totalPago), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(totalDivida), bold: true, alignment: 'right', fontSize: 7 },
    ]);

    return [{
      layout: 'linhas',
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        dontBreakRows: true,
        headerRows: 2,
        widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }];
  }

  private balanceteDespesaCSV(dados: any[]) {
    let registros: {}[][] = [[
      { text: 'CODIGO' },
      { text: 'DESCRIÇÃO' },
      { text: 'ORÇADA' },
      { text: 'MOVIMENTO' },
      { text: 'RECURSOS' },
      { text: 'EMPENHADA DO MÊS'},
      { text: 'EMPENHADA ACUMULADO' },
      { text: 'SALDO' },
      { text: 'LIQUIDADO DO MÊS'},
      { text: 'LIQUIDADO ACUMULADO' },
      { text: 'SALDO' },
      { text: 'PAGAMENTO DO MÊS'},
      { text: 'PAGAMENTO ACUMULADO' },
      { text: 'DÍVIDA' },
    ]];

    let totalMovimento = 0.00;
    let totalOrcado = 0.00;
    let totalEmpenhadoMes = 0.00;
    let totalEmpenhado = 0.00;
    let totalPagoMes = 0.00;
    let totalPago = 0.00;
    let totalLiquidadoMes = 0.00;
    let totalLiquidado = 0.00;
    let totalRecurso = 0.00;
    let totalSaldo = 0.00;
    let totalSaldoLiquidado = 0.00;
    let totalDivida = 0.00;
    for (const registro of dados) {
      const movimento: number = registro['movimento'];
      const especial: number = registro['especial'];
      const orcado: number = registro['orcado'];
      const empenhadoMes: number = registro['empenhado_mes'];
      const empenhado: number = registro['empenhado'];
      const liquidadoMes: number = registro['liquidado_mes'];
      const liquidado: number = registro['liquidado'];
      const pagoMes: number = registro['pago_mes'];
      const pago: number = registro['pago'];
      const recurso: number = (+movimento + +especial + +orcado);
      const saldo = +recurso - +empenhado;
      const saldoLiquidado = +empenhado - +liquidado;
      const divida = +liquidado - +pago;

      totalMovimento += +movimento + +especial;
      totalOrcado += +orcado;
      totalEmpenhadoMes += +empenhadoMes;
      totalEmpenhado += +empenhado;
      totalPagoMes += +pagoMes;
      totalPago += +pago;
      totalLiquidadoMes += +liquidadoMes;
      totalLiquidado += +liquidado;
      totalRecurso += recurso;
      totalSaldo += +saldo;
      totalSaldoLiquidado += +saldoLiquidado;
      totalDivida += +divida;

      registros.push([
        { text: this.funcaoService.mascarar('#.#.##.##', registro['d_codigo']) },
        { text: registro['d_nome'] },
        { text: this.funcaoService.convertToBrNumber(orcado) },
        { text: this.funcaoService.convertToBrNumber(+movimento + +especial) },
        { text: this.funcaoService.convertToBrNumber(recurso) },
        { text: this.funcaoService.convertToBrNumber(empenhadoMes) },
        { text: this.funcaoService.convertToBrNumber(empenhado) },
        { text: this.funcaoService.convertToBrNumber(saldo) },
        { text: this.funcaoService.convertToBrNumber(liquidadoMes) },
        { text: this.funcaoService.convertToBrNumber(liquidado) },
        { text: this.funcaoService.convertToBrNumber(saldoLiquidado) },
        { text: this.funcaoService.convertToBrNumber(pagoMes) },
        { text: this.funcaoService.convertToBrNumber(pago) },
        { text: this.funcaoService.convertToBrNumber(divida) },
      ]);
    }

    registros.push([
      { text: 'TOTAL GERAL:' }, { text: '' },
      { text: this.funcaoService.convertToBrNumber(totalOrcado) },
      { text: this.funcaoService.convertToBrNumber(totalMovimento) },
      { text: this.funcaoService.convertToBrNumber(totalRecurso) },
      { text: this.funcaoService.convertToBrNumber(totalEmpenhadoMes) },
      { text: this.funcaoService.convertToBrNumber(totalEmpenhado) },
      { text: this.funcaoService.convertToBrNumber(totalSaldo) },
      { text: this.funcaoService.convertToBrNumber(totalLiquidadoMes) },
      { text: this.funcaoService.convertToBrNumber(totalLiquidado) },
      { text: this.funcaoService.convertToBrNumber(totalSaldoLiquidado) },
      { text: this.funcaoService.convertToBrNumber(totalPagoMes) },
      { text: this.funcaoService.convertToBrNumber(totalPago) },
      { text: this.funcaoService.convertToBrNumber(totalDivida) },
    ]);

    dados = null;
    let csv = '';
    for (let i=0; i<registros.length; i++) {
      const linha = registros[i];
      if (i > 0) csv += '\n';
      for (let x=0; x<linha.length; x++) {
        if (x > 0) csv += ';';
        csv += String(linha[x]['text']);
      }
    }
    registros = null;

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + csv));
    element.setAttribute("download", `Balancete da Despesa por Grupo.csv`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  private async conteudoAssinatura(login: any, exercicio: Exercicio, mes: number, dataFinal: string) {
    const conteudo = [];
    const data = dataFinal ?? `${exercicio.ano}-${this.funcaoService.strZero(mes, 2)}-${this.funcaoService.ultimoDiaMes(+mes, exercicio.ano)}`;
    this.assinatura = await this.assinaturaService.obter({ orgao_id: login.orgao.id, 'data_limite$ge': data, 'orderBy': 'data_limite$ASC' }).toPromise();

    if (!this.assinatura) {
      toastr.error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
      throw new Error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
    }

    conteudo.push([
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      },
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      },
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      }
    ]);
    conteudo.push([
      { text: this.assinatura.ordenador_despesa, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.contador, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.tesoureiro, border: [false, false, false, false], bold: true, alignment: 'center' }
    ]);
    conteudo.push([
      { text: this.assinatura.cargo_ordenador_despesa, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.cargo_contador + (this.assinatura.crc_contador ? ` - ${this.assinatura.crc_contador}` : ''), border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.cargo_tesoureiro, border: [false, false, false, false], bold: true, alignment: 'center' }
    ]);

    return [{
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', '*', '*'],
        body: conteudo
      }
    }];
  }

}
