import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../../assinaturas/assinaturas';
import { AnexoLrfFederalService } from '../../../service/anexo-lrf-federal.service';

@Directive()
export class Anexo2RgfLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoFederalServico: AnexoLrfFederalService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected assinaturaControleInterno: boolean) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
    this.anexoFederalServico.obterAnexo2Rgf(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          const listaConteudo = await this.conteudo(dados, orgaos);
          Relatorio.imprimirPersonalizado(null, this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            listaConteudo,
            'portrait', 'ANEXO2 - DEMONSTRATIVO DA DÍVIDA CONSOLIDADA LÍQUIDA',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 2;
                },
                paddingRight() {
                  return 2;
                }
              }
            }, true, false);
        } else {
          this.funcaoService.exportar(formato, await this.normalizar(dados, orgaos), 'ANEXO2 - DEMONSTRATIVO DA DÍVIDA CONSOLIDADA LÍQUIDA', this.colunas());
        }
      });
  }

  private async conteudo(dados: any[], orgaos: any): Promise<{}[]> {
    // monta o cabecalho

    const registros: {}[] = [
      [
        { text: this.login.orgao.nome, bold: true, border: [false, false, false, false], colSpan: 5, fontSize: 10 },
        '', '', '', ''
      ],

      [
        { text: 'RELATÓRIO DE GESTÃO FISCAL', bold: true, border: [false, false, false, false], colSpan: 5 },
        '', '', '', ''],
      [
        { text: 'DEMONSTRATIVO DA DÍVIDA CONSOLIDADA LÍQUIDA', bold: true, border: [false, false, false, false], colSpan: 5 },
        '', '', '', ''],
      [
        { text: 'ORÇAMENTOS FISCAL E DA SEGURIDADE SOCIAL', bold: true, border: [false, false, false, false], colSpan: 5 },
        '', '', '', ''],
      [
        { text: `REFERÊNCIA: ${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano}`, bold: true, border: [false, false, false, false], colSpan: 5 },
        '', '', '', ''],
      [
        { text: 'RGF - ANEXO 2 (LRF, art. 55, inciso I, alínea "b")', bold: true, border: [false, false, false, false], colSpan: 5 },
        '', '', '', ''
      ],
      [
        { text: 'DÍVIDA CONSOLIDADA', rowSpan: 2, alignment: 'center' },
        { text: 'SALDO EXERCÍCIO ANTERIOR', alignment: 'center', bold: true, fontSize: 8, rowSpan: 2 },
        { text: `SALDO DO EXERCÍCIO DE ${this.exercicio.ano}`, alignment: 'center', bold: true, fontSize: 8, colSpan: 3 },
        { text: '' },
        { text: '' }
      ],
      [
        { text: '' },
        { text: '' },
        { text: 'Até o 1º Quadrimestre', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'Até o 2º Quadrimestre', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'Até o 3º Quadrimestre', alignment: 'center', bold: true, fontSize: 8 },
      ]

    ];

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);

    // monta o agrupamento do relatório
    const titulos = this.funcaoService.agrupar(dados[0], ['titulo', 'somador'], ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
    let total0 = 0;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;

    let totalDC0 = 0;
    let totalDC1 = 0;
    let totalDC2 = 0;
    let totalDC3 = 0;


    for (const titulo of titulos) {
      registros.push([
        { text: titulo.grupo['titulo'], fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['saldo_anterior']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre1']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre2']) : '0,00', alignment: 'right', fontSize: 8, bold: true },
        { text: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre3']) : '0,00', alignment: 'right', fontSize: 8, bold: true }
      ]);
      total0 += titulo.grupo['somador'] ? +titulo.totalizadores['saldo_anterior'] : +titulo.totalizadores['saldo_anterior'] * -1;
      total1 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre1'] : +titulo.totalizadores['quadrimestre1'] * -1;
      total2 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre2'] : +titulo.totalizadores['quadrimestre2'] * -1;
      total3 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre3'] : +titulo.totalizadores['quadrimestre3'] * -1;

      if (titulo.grupo['somador']) {
        totalDC0 += +titulo.totalizadores['saldo_anterior'];
        totalDC1 += +titulo.totalizadores['quadrimestre1'];
        totalDC2 += +titulo.totalizadores['quadrimestre2'];
        totalDC3 += +titulo.totalizadores['quadrimestre3'];
      }

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          registros.push([
            { text: grupo.grupo, fontSize: 8, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['saldo_anterior']), alignment: 'right', fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre1']), alignment: 'right', fontSize: 8 },
            { text: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre2']) : '0,00', alignment: 'right', fontSize: 8 },
            { text: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre3']) : '0,00', alignment: 'right', fontSize: 8 },
          ]);

          const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
          for (const subgrupo of subgrupos) {
            if (subgrupo.grupo != '') {
              registros.push([
                { text: subgrupo.grupo, fontSize: 8, bold: true, margin: [5, 0, 0, 0] },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['saldo_anterior']), alignment: 'right', fontSize: 8 },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['quadrimestre1']), alignment: 'right', fontSize: 8 },
                { text: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(subgrupo.totalizadores['quadrimestre2']) : '0,00', alignment: 'right', fontSize: 8 },
                { text: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(subgrupo.totalizadores['quadrimestre3']) : '0,00', alignment: 'right', fontSize: 8 }
              ]);

              for (const item of subgrupo.registros) {
                if (item.nome != '') {
                  registros.push([
                    {
                      text: item.nome, fontSize: 8, margin: [10, 0, 0, 0]
                    },
                    { text: this.funcaoService.convertToBrNumber(item.saldo_anterior), alignment: 'right', fontSize: 8 },
                    { text: this.funcaoService.convertToBrNumber(item.quadrimestre1), alignment: 'right', fontSize: 8 },
                    { text: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(item.quadrimestre2) : '0,00', alignment: 'right', fontSize: 8 },
                    { text: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(item.quadrimestre3) : '0,00', alignment: 'right', fontSize: 8 },
                  ]);
                }
              }
            }
          }
        }
      }
    }

    registros.push([
      {
        text: 'DÍVIDA CONSOLIDADA LÍQUIDA² (DCL) (III) = (I - II)', fontSize: 8, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(+total0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total1), alignment: 'right', fontSize: 8, bold: true },
      { text: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(+total2) : '0,00', alignment: 'right', fontSize: 8, bold: true },
      { text: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(+total3) : '0,00', alignment: 'right', fontSize: 8, bold: true }
    ]);

    let totalRCL0 = 0;
    let totalRCL1 = 0;
    let totalRCL2 = 0;
    let totalRCL3 = 0;

    if (this.mes <= 4) {
      totalRCL0 = await this.anexoFederalServico.obterAnexo3Resuldado(0, this.exercicio.id, orgaos).toPromise();
      totalRCL1 = await this.anexoFederalServico.obterAnexo3Resuldado(this.mes, this.exercicio.id, orgaos).toPromise();
    }
    if (this.mes >= 5 && this.mes <= 8) {
      totalRCL2 = await this.anexoFederalServico.obterAnexo3Resuldado(this.mes, this.exercicio.id, orgaos).toPromise();
    }
    if (this.mes >= 9 && this.mes <= 12) {
      totalRCL3 = await this.anexoFederalServico.obterAnexo3Resuldado(this.mes, this.exercicio.id, orgaos).toPromise();
    }

    registros.push([
      {
        text: 'RECEITA CORRENTE LÍQUIDA - RCL (IV)', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(totalRCL0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalRCL1), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalRCL2), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalRCL3), alignment: 'right', fontSize: 8, bold: true }
    ]);

    registros.push([
      {
        text: '(-) Transferências obrigatórias da União relativas às emendas individuais (art. 166-A, § 1º, da CF)  (V)', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true }
    ]);

    registros.push([
      {
        text: 'RECEITA CORRENTE LÍQUIDA AJUSTADA PARA CÁLCULO DOS LIMITES DE ENDIVIDAMENTO (VI) = (IV - V)', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(totalRCL0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalRCL1), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalRCL2), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalRCL3), alignment: 'right', fontSize: 8, bold: true }
    ]);

    registros.push([
      {
        text: '% da DC sobre a RCL AJUSTADA (I/VI)', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(+totalDC0 / +totalRCL0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+totalDC1 / +totalRCL1), alignment: 'right', fontSize: 8, bold: true },
      { text: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(+totalDC2 / +totalRCL2) : '0,00', alignment: 'right', fontSize: 8, bold: true },
      { text: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(+totalDC3 / +totalRCL3) : '0,00', alignment: 'right', fontSize: 8, bold: true }
    ]);

    registros.push([
      {
        text: '% da DCL sobre a RCL AJUSTADA (III/VI)', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(+total0 / +totalRCL0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total1 / +totalRCL1), alignment: 'right', fontSize: 8, bold: true },
      { text: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(+total2 / +totalRCL2) : '0,00', alignment: 'right', fontSize: 8, bold: true },
      { text: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(+total3 / +totalRCL3) : '0,00', alignment: 'right', fontSize: 8, bold: true }
    ]);

    registros.push([
      {
        text: 'LIMITE DEFINIDO POR RESOLUÇÃO DO SENADO FEDERAL - <%>', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true }
    ]);

    registros.push([
      {
        text: 'LIMITE DE ALERTA (inciso III do §1º do art. 59 da LRF) - <%>', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true }
    ]);

    this.outrosValores(dados[1], registros);

    return [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 80, 80, 80, 80],
          body: registros
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*'],
          body: assinaturas
        }
      }
    ];
  }

  private outrosValores(dados: [], registros: any) {

    registros.push(
      [
        { text: 'OUTROS VALORES NÃO INTEGRANTES DA DC', rowSpan: 2, alignment: 'center' },
        { text: 'SALDO EXERCÍCIO ANTERIOR', alignment: 'center', bold: true, fontSize: 8, rowSpan: 2 },
        { text: `SALDO DO EXERCÍCIO DE ${this.exercicio.ano}`, alignment: 'center', bold: true, fontSize: 8, colSpan: 3 },
        { text: '' },
        { text: '' }
      ],
      [
        { text: '' },
        { text: '' },
        { text: 'Até o 1º Quadrimestre', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'Até o 2º Quadrimestre', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'Até o 3º Quadrimestre', alignment: 'center', bold: true, fontSize: 8 },
      ]
    );
    // monta o agrupamento do relatório
    const titulos = this.funcaoService.agrupar(dados, ['titulo', 'somador'], ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
    let total0 = 0;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;

    for (const titulo of titulos) {
      registros.push([
        { text: titulo.grupo['titulo'], fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['saldo_anterior']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre1']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre2']) : '0,00', alignment: 'right', fontSize: 8, bold: true },
        { text: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre3']) : '0,00', alignment: 'right', fontSize: 8, bold: true }
      ]);
      total0 += titulo.grupo['somador'] ? +titulo.totalizadores['saldo_anterior'] : +titulo.totalizadores['saldo_anterior'] * -1;
      total1 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre1'] : +titulo.totalizadores['quadrimestre1'] * -1;
      total2 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre2'] : +titulo.totalizadores['quadrimestre2'] * -1;
      total3 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre3'] : +titulo.totalizadores['quadrimestre3'] * -1;


      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          registros.push([
            { text: grupo.grupo, fontSize: 8, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['saldo_anterior']), alignment: 'right', fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre1']), alignment: 'right', fontSize: 8 },
            { text: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre2']) : '0,00', alignment: 'right', fontSize: 8 },
            { text: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre3']) : '0,00', alignment: 'right', fontSize: 8 },
          ]);

          const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
          for (const subgrupo of subgrupos) {
            if (subgrupo.grupo != '') {
              registros.push([
                { text: subgrupo.grupo, fontSize: 8, bold: true, margin: [5, 0, 0, 0] },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['saldo_anterior']), alignment: 'right', fontSize: 8 },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['quadrimestre1']), alignment: 'right', fontSize: 8 },
                { text: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(subgrupo.totalizadores['quadrimestre2']) : '0,00', alignment: 'right', fontSize: 8 },
                { text: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(subgrupo.totalizadores['quadrimestre3']) : '0,00', alignment: 'right', fontSize: 8 }
              ]);

              for (const item of subgrupo.registros) {
                if (item.nome != '') {
                  registros.push([
                    {
                      text: item.nome, fontSize: 8, margin: [10, 0, 0, 0]
                    },
                    { text: this.funcaoService.convertToBrNumber(item.saldo_anterior), alignment: 'right', fontSize: 8 },
                    { text: this.funcaoService.convertToBrNumber(item.quadrimestre1), alignment: 'right', fontSize: 8 },
                    { text: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(item.quadrimestre2) : '0,00', alignment: 'right', fontSize: 8 },
                    { text: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(item.quadrimestre3) : '0,00', alignment: 'right', fontSize: 8 },
                  ]);
                }
              }
            }
          }
        }
      }
    }

  }

  public async normalizar(lista: any[], orgaos: any): Promise<{}[]> {
    const listaExportar = []

    const titulos = this.funcaoService.agrupar(lista[0], ['titulo', 'somador'], ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
    let total0 = 0;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;

    let totalDC0 = 0;
    let totalDC1 = 0;
    let totalDC2 = 0;
    let totalDC3 = 0;

    const linha_titulo = {
      titulo: '',
      saldo_anterior: '',
      primeiro_quadrimestre: 'Até o 1º Quadrimestre',
      segundo_quadrimestre: 'Até o 2º Quadrimestre',
      terceiro_quadrimestre: 'Até o 3º Quadrimestre'
    }
    listaExportar.push(linha_titulo)

    for (const titulo of titulos) {
      const primeira_linha = {
        titulo: titulo.grupo['titulo'],
        saldo_anterior: this.funcaoService.convertToBrNumber(titulo.totalizadores['saldo_anterior']),
        primeiro_quadrimestre: this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre1']),
        segundo_quadrimestre: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre2']) : '0,00',
        terceiro_quadrimestre: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre3']) : '0,00'
      }
      listaExportar.push(primeira_linha)

      total0 += titulo.grupo['somador'] ? +titulo.totalizadores['saldo_anterior'] : +titulo.totalizadores['saldo_anterior'] * -1;
      total1 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre1'] : +titulo.totalizadores['quadrimestre1'] * -1;
      total2 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre2'] : +titulo.totalizadores['quadrimestre2'] * -1;
      total3 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre3'] : +titulo.totalizadores['quadrimestre3'] * -1;

      if (titulo.grupo['somador']) {
        totalDC0 += +titulo.totalizadores['saldo_anterior'];
        totalDC1 += +titulo.totalizadores['quadrimestre1'];
        totalDC2 += +titulo.totalizadores['quadrimestre2'];
        totalDC3 += +titulo.totalizadores['quadrimestre3'];
      }

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          const linha_grupo = {
            titulo: grupo.grupo,
            saldo_anterior: this.funcaoService.convertToBrNumber(grupo.totalizadores['saldo_anterior']),
            primeiro_quadrimestre: this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre1']),
            segundo_quadrimestre: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre2']) : '0,00',
            terceiro_quadrimestre: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre3']) : '0,00'
          }
          listaExportar.push(linha_grupo)
        }

        const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
        for (const subgrupo of subgrupos) {
          if (subgrupo.grupo != '') {
            const linha_subgrupo = {
              titulo: subgrupo.grupo,
              saldo_anterior: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['saldo_anterior']),
              primeiro_quadrimestre: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['quadrimestre1']),
              segundo_quadrimestre: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(subgrupo.totalizadores['quadrimestre2']) : '0,00',
              terceiro_quadrimestre: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(subgrupo.totalizadores['quadrimestre3']) : '0,00'
            }
            listaExportar.push(linha_subgrupo)
          }
          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              const linha_item_subgrupo = {
                titulo: item.nome,
                saldo_anterior: this.funcaoService.convertToBrNumber(item.saldo_anterior),
                primeiro_quadrimestre: this.funcaoService.convertToBrNumber(item.quadrimestre1),
                segundo_quadrimestre: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(item.quadrimestre2) : '0,00',
                terceiro_quadrimestre: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(item.quadrimestre3) : '0,00',
              }
              listaExportar.push(linha_item_subgrupo)
            }
          }
        }
      }
    }

    const linha_despesa_liquida = {
      titulo: 'DÍVIDA CONSOLIDADA LÍQUIDA² (DCL) (III) = (I - II)',
      saldo_anterior: this.funcaoService.convertToBrNumber(+total0),
      primeiro_quadrimestre: this.funcaoService.convertToBrNumber(+total1),
      segundo_quadrimestre: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(+total2) : '0,00',
      terceiro_quadrimestre: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(+total3) : '0,00'
    }
    listaExportar.push(linha_despesa_liquida)

    let totalRCL0 = 0;
    let totalRCL1 = 0;
    let totalRCL2 = 0;
    let totalRCL3 = 0;

    if (this.mes <= 4) {
      totalRCL0 = await this.anexoFederalServico.obterAnexo3Resuldado(0, this.exercicio.id, orgaos).toPromise();
      totalRCL1 = await this.anexoFederalServico.obterAnexo3Resuldado(this.mes, this.exercicio.id, orgaos).toPromise();
    }
    if (this.mes >= 5 && this.mes <= 8) {
      totalRCL2 = await this.anexoFederalServico.obterAnexo3Resuldado(this.mes, this.exercicio.id, orgaos).toPromise();
    }
    if (this.mes >= 9 && this.mes <= 12) {
      totalRCL3 = await this.anexoFederalServico.obterAnexo3Resuldado(this.mes, this.exercicio.id, orgaos).toPromise();
    }

    const linha_receita_corrente_liquida = {
      titulo: 'RECEITA CORRENTE LÍQUIDA - RCL (IV)',
      saldo_anterior: this.funcaoService.convertToBrNumber(totalRCL0),
      primeiro_quadrimestre: this.funcaoService.convertToBrNumber(totalRCL1),
      segundo_quadrimestre: this.funcaoService.convertToBrNumber(totalRCL2),
      terceiro_quadrimestre: this.funcaoService.convertToBrNumber(totalRCL3)
    }
    listaExportar.push(linha_receita_corrente_liquida)

    const linha_transferencia_individual = {
      titulo: '(-) Transferências obrigatórias da União relativas às emendas individuais (art. 166-A, § 1º, da CF)  (V)',
      saldo_anterior: this.funcaoService.convertToBrNumber(0),
      primeiro_quadrimestre: this.funcaoService.convertToBrNumber(0),
      segundo_quadrimestre: this.funcaoService.convertToBrNumber(0),
      terceiro_quadrimestre: this.funcaoService.convertToBrNumber(0)
    }
    listaExportar.push(linha_transferencia_individual)

    const linha_receita_ajustada = {
      titulo: 'RECEITA CORRENTE LÍQUIDA AJUSTADA PARA CÁLCULO DOS LIMITES DE ENDIVIDAMENTO (VI) = (IV - V)',
      saldo_anterior: this.funcaoService.convertToBrNumber(totalRCL0),
      primeiro_quadrimestre: this.funcaoService.convertToBrNumber(totalRCL1),
      segundo_quadrimestre: this.funcaoService.convertToBrNumber(totalRCL2),
      terceiro_quadrimestre: this.funcaoService.convertToBrNumber(totalRCL3),
    }
    listaExportar.push(linha_receita_ajustada)

    const linha_porcentagem_dc_rlc_1_2 = {
      titulo: '% da DC sobre a RCL AJUSTADA (I/VI)',
      saldo_anterior: this.funcaoService.convertToBrNumber(+totalDC0 / +totalRCL0),
      primeiro_quadrimestre: this.funcaoService.convertToBrNumber(+totalDC1 / +totalRCL1),
      segundo_quadrimestre: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(+totalDC2 / +totalRCL2) : '0,00',
      terceiro_quadrimestre: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(+totalDC3 / +totalRCL3) : '0,00'
    }
    listaExportar.push(linha_porcentagem_dc_rlc_1_2)

    const linha_porcentagem_dc_rlc_3_4 = {
      titulo: '% da DCL sobre a RCL AJUSTADA (III/VI)',
      saldo_anterior: this.funcaoService.convertToBrNumber(+total0 / +totalRCL0),
      primeiro_quadrimestre: this.funcaoService.convertToBrNumber(+total1 / +totalRCL1),
      segundo_quadrimestre: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(+total2 / +totalRCL2) : '0,00',
      terceiro_quadrimestre: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(+total3 / +totalRCL3) : '0,00',
    }
    listaExportar.push(linha_porcentagem_dc_rlc_3_4)

    const linha_limite_senado = {
      titulo: 'LIMITE DEFINIDO POR RESOLUÇÃO DO SENADO FEDERAL - <%>',
      saldo_anterior: this.funcaoService.convertToBrNumber(0),
      primeiro_quadrimestre: this.funcaoService.convertToBrNumber(0),
      segundo_quadrimestre: this.funcaoService.convertToBrNumber(0),
      terceiro_quadrimestre: this.funcaoService.convertToBrNumber(0)
    }
    listaExportar.push(linha_limite_senado)

    const linha_limite_alerta = {
      titulo: 'LIMITE DE ALERTA (XI) = (0,90 x IX) (inciso II do §1º do art. 59 da LRF)',
      saldo_anterior: this.funcaoService.convertToBrNumber(0),
      primeiro_quadrimestre: this.funcaoService.convertToBrNumber(0),
      segundo_quadrimestre: this.funcaoService.convertToBrNumber(0),
      terceiro_quadrimestre: this.funcaoService.convertToBrNumber(0)
    }
    listaExportar.push(linha_limite_alerta)

    this.outrosValoresCsv(lista[1], listaExportar)

    return listaExportar
  }

  private outrosValoresCsv(dados: [], listaExportar: any) {
    
    let total0 = 0;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;

    const linha_titulo_outros_valores = {
      titulo: 'OUTROS VALORES NÃO INTEGRANTES DA DC',
      saldo_anterior: 'SALDO EXERCÍCIO ANTERIOR',
      primeiro_quadrimestre: `SALDO DO EXERCÍCIO DE ${this.exercicio.ano}`,
      segundo_quadrimestre: '',
      terceiro_quadrimestre: '',
    }
    listaExportar.push(linha_titulo_outros_valores)

    const linha_subtitulo_outros_valores = {
      titulo: '',
      saldo_anterior: '',
      primeiro_quadrimestre: 'Até o 1º Quadrimestre',
      segundo_quadrimestre: 'Até o 2º Quadrimestre',
      terceiro_quadrimestre: 'Até o 3º Quadrimestre',
    }
    listaExportar.push(linha_subtitulo_outros_valores)

    const titulos = this.funcaoService.agrupar(dados, ['titulo', 'somador'], ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);

    for (const titulo of titulos) {
      console.log(titulo)
      const primeira_linha = {
        titulo: titulo.grupo['titulo'],
        saldo_anterior: this.funcaoService.convertToBrNumber(titulo.totalizadores['saldo_anterior']),
        primeiro_quadrimestre: this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre1']),
        segundo_quadrimestre: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre2']) : '0,00',
        terceiro_quadrimestre: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre3']) : '0,00'
      }
      listaExportar.push(primeira_linha)

      total0 += titulo.grupo['somador'] ? +titulo.totalizadores['saldo_anterior'] : +titulo.totalizadores['saldo_anterior'] * -1;
      total1 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre1'] : +titulo.totalizadores['quadrimestre1'] * -1;
      total2 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre2'] : +titulo.totalizadores['quadrimestre2'] * -1;
      total3 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre3'] : +titulo.totalizadores['quadrimestre3'] * -1;

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          const linha_grupo = {
            titulo: grupo.grupo,
            saldo_anterior: this.funcaoService.convertToBrNumber(grupo.totalizadores['saldo_anterior']),
            primeiro_quadrimestre: this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre1']),
            segundo_quadrimestre: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre2']) : '0,00',
            terceiro_quadrimestre: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre3']) : '0,00'
          }
          listaExportar.push(linha_grupo)
        }

        const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
        for (const subgrupo of subgrupos) {
          if (subgrupo.grupo != '') {
            const linha_subgrupo = {
              titulo: subgrupo.grupo,
              saldo_anterior: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['saldo_anterior']),
              primeiro_quadrimestre: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['quadrimestre1']),
              segundo_quadrimestre: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(subgrupo.totalizadores['quadrimestre2']) : '0,00',
              terceiro_quadrimestre: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(subgrupo.totalizadores['quadrimestre3']) : '0,00'
            }
            listaExportar.push(linha_subgrupo)
          }

          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              const linha_item_subgrupo = {
                titulo: item.nome,
                saldo_anterior: this.funcaoService.convertToBrNumber(item.saldo_anterior),
                primeiro_quadrimestre: this.funcaoService.convertToBrNumber(item.quadrimestre1),
                segundo_quadrimestre: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(item.quadrimestre2) : '0,00',
                terceiro_quadrimestre: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(item.quadrimestre3) : '0,00',
              }
              listaExportar.push(linha_item_subgrupo)
            }
          }
        }
      }
    }
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'DÍVIDA CONSOLIDADA', coluna: 'titulo' },
      { titulo: 'SALDO EXERCÍCIO ANTERIOR', coluna: 'saldo_anterior' },
      { titulo: `SALDO DO EXERCÍCIO DE ${this.exercicio.ano}`, coluna: 'primeiro_quadrimestre' },
      { titulo: '', coluna: 'segundo_quadrimestre' },
      { titulo: ' ', coluna: 'terceiro_quadrimestre' },
    ];
    return colunasDefault;
  }

}
