// tslint:disable: variable-name
import { Login } from './login';
import { Exercicio } from '../comum/exercicio.model';
import { Ppa } from '../planejamento/ppa.model';
import { Usuario } from '../comum/usuario.model';
import { Orgao } from '../comum/orgao.model';
import { Cidade } from '../geo/cidade.model';
import { Sistemas } from '../../components/types';

export class LoginContabil extends Login {
    constructor(
        public sistema?: Sistemas,
        public exercicio?: Exercicio,
        public usuario?: Usuario,
        public orgao?: Orgao,
        public cidade?: Cidade,
        public limite?: number,
        public token?: string,
        public brasao?: string,
        public contraste?: boolean,
        public ppa?: Ppa,
        public redirecionar_liquidar_pagar?: boolean,
        public dias_bloquear_alteracoes?: number,
        public repetir_dados_empenho?: boolean,
        public verificar_liquidacao_estoque?: boolean,
        public fonte_recurso_pagamento?: boolean,
        public informar_data_liquidacao_estoque?: boolean,
        public escriturar_aplicacao_dotacao?: boolean,
        public mesReferencia?: number,
        public token_pncp?: string,
        public validade_pncp?: string,
        public dados_pncp?: any,
        public logoutTime?: number
    ) {
        super(sistema, exercicio, usuario, orgao, cidade, limite, token, brasao);
    }
}
