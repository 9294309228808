import { Audesp4Empenho } from "./types/comum-type";

export class EmpenhoArray {
    CodigoAjuste: number;
    Empenho: Audesp4Empenho[];
}

export class ArrayNovoEmpenho {
    ArrayNovoEmpenho: {
        CodigoAjuste: number;
        Empenho: Audesp4Empenho;
    }[]
}