// tslint:disable: variable-name
import { ProcessoHistorico } from './processo-historico.model';

export class ProcessoArquivo {

    id: number;

    nome: string;

    caminho: string;

    tipo: string;

    documento: any;

    tamanho: number;

    historico: ProcessoHistorico;

    data_cadastro: Date;

    data_alteracao: Date;

}
