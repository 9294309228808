// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Exercicio } from '../comum/exercicio.model';
import { PlanoConta } from './plano-conta.model';
import { Recurso } from '../planejamento/recurso.model';
import { Favorecido } from '../compra/favorecido.model';
import { ContaBancariaSaldo } from '../tesouraria/conta-bancaria-saldo.model';
import { RecursoSiconfiComplemento } from '../planejamento/recurso-siconfi-complemento.model';
import { RecursoSiconfi } from '../planejamento/recurso-siconfi.model';
import { FichaReceita } from '../planejamento/ficha-receita.model';

export class FichaExtra extends BaseResourceModel {
  constructor(
    public id?: number,
    public numero?: number,
    public nome?: string,
    public especie?: string,
    public tipo_ficha?: string,
    public retencao?: boolean,
    public excluida?: boolean,
    public transfer_disponibilidade?: boolean,
    public ativo?: boolean,
    public saldo_anterior?: number,
    public orgao?: Orgao,
    public exercicio?: Exercicio,
    public plano?: PlanoConta,
    public domicilio?: ContaBancariaSaldo,
    public recurso?: Recurso,
    public aplicacao?: Recurso,
    public aplicacao_variavel?: Recurso,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public favorecido?: Favorecido,
    public recurso_siconfi?: RecursoSiconfi,
    public complemento_siconfi?: RecursoSiconfiComplemento,
    public enviar_efd_reinf?: boolean,
    public reinf_2000?: boolean,
    public reinf_4000?: boolean,
    public ir_reinf_4000?: boolean,
    public csll_reinf_4000?: boolean,
    public cofins_reinf_4000?: boolean,
    public pis_pasep_reinf_4000?: boolean,
    public sem_vinculo_liquidacao?: boolean,
    public nao_gerar_retencao?: boolean,
    public ficha?: FichaReceita,
    public codigo_reinf?: number,
  ) {
    super();
  }

  static converteJson(json: any): FichaExtra {
    return Object.assign(new FichaExtra(), json);
  }
}
