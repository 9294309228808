import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FichaReceitaService, RecursoService } from 'administrativo-lib';
import { AnexoLoaService, Anexo1, Anexo2Receita, Anexo2Despesa, Anexo6, Anexo7, Anexo8, Anexo9, Sumario, FichaDespesaService, Qdd, QddOrcamento, PrevisaoReceitaLoa, PrevisaoDespesaLoa, ReceitaRecurso, Anexo6Orgao, Anexo2Ce, AplicacaoVinculada, AnexoUnidadeAtuacao, Anexo2Executora } from 'contabil-lib';
import { EddyAutoComplete, Executora, ExecutoraService, Exercicio, ExercicioService, FuncaoService, GlobalService, LoginContabil, ProgressoService, Recurso, Unidade } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';

type FiltrosQdd = {
  condicao: 'T' | 'U'; // T - Todas, U - Por Unidade
  periodo: 'T' | 'Q' | 'D';// T - Trimestral, Q - Quadrimestral, D - Por datas
  trimestre: number;
  quadrimestre: number;
  tipo: 'E' | 'L' | 'P' | 'T';// E - Empenhado, L - Liquidado, P - Pago, T - (Todos) Empenhado/Liquidado/Pago
  agrupar: 'T' | 'F'; // T - Todas, F - Por ficha
}

@Component({
  selector: 'app-transparencia-anexo-loa',
  templateUrl: './transparencia-anexo-loa.component.html'
})
export class TransparenciaAnexoLoaComponent implements OnInit, OnDestroy {

  public login: LoginContabil = new LoginContabil();
  public mes: number;
  public anexoSelect: string;
  public listaExercicios: Array<any>;
  public listaBalancetes: Array<any>;
  public exercicioSelect: Exercicio;
  public orcamento = false;
  public quadrimestre: number;
  public data_inicial: Date = new Date(this.login?.exercicio?.ano || 2022, 1, 1);
  public data_final: Date = new Date();
  public filtrosQdd: FiltrosQdd = {
    condicao: 'T',
    periodo: 'Q',
    tipo: 'E',
    trimestre: 1,
    quadrimestre: 1,
    agrupar: 'T'
  };
  protected unsubscribe: Subject<void> = new Subject();
  public recursoDespesaAutoComplete: EddyAutoComplete<Recurso>;
  public recursoReceitaAutoComplete: EddyAutoComplete<Recurso>
  public executoraAutoComplete: EddyAutoComplete<Executora>;
  public recursoReceita: Recurso
  public recursoDespesa: Recurso
  public unidadeSelect: Unidade;
  public filtroExecutora: boolean = false;
  public executoraSelect: Executora;
  public filtroTipo: boolean = false;
  public filtroMes: boolean = false;
  public tipo_previsao: number = 1;
  public mes_inicial: number = 1;
  public mes_final: number = 12;
  public tribunal_executora: String;
  public tribunal_unidade: String;

  constructor(
    private router: Router,
    protected frService: FichaReceitaService,
    protected fichaService: FichaDespesaService,
    protected exercicioService: ExercicioService,
    protected funcaoService: FuncaoService,
    protected anexoServico: AnexoLoaService,
    protected fichaReceitaService: FichaReceitaService,
    protected fichaDespesaService: FichaDespesaService,
    protected recursoService: RecursoService,
    protected executoraService: ExecutoraService,
    protected progressoService: ProgressoService
  ) {
    this.login = GlobalService.obterSessaoLogin();
    this.mes = 1;
    this.exercicioSelect = this.login.exercicio;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit() {
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaExercicios = dados.content;
      });
    this.listaBalancetes = [
      { id: 'anexoI', nome: 'ANEXO I - DEMONSTRATIVO DA RECEITA E DESPESA SEGUNDO AS CATEGORIAS ECONÔMICAS' },
      { id: 'anexo2Receita', nome: 'ANEXO II - RECEITAS SEGUNDO AS CATEGORIAS ECONÔMICAS' },
      { id: 'anexo2Despesa', nome: 'ANEXO II - DEMONSTRATIVO DETALHADO SEGUNDO AS CATEGORIAS ECONÔMICAS' },
      { id: 'anexo2Executora', nome: 'ANEXO II - DEMONSTRATIVO DETALHADO SEGUNDO AS CATEGORIAS ECONÔMICAS - POR UNIDADE EXECUTORA' },
      { id: 'anexo6', nome: 'ANEXO VI - DEMONSTRATIVO DA DESPESA DAS UNIDADES ORÇAMENTÁRIAS POR PROJETOS,ATIVIDADES E OP.ESPECIEAIS' },
      { id: 'anexo7', nome: 'ANEXO VII - DEMONSTRATIVO DESPESA POR FUNÇÃO SUBFUNÇÃO,PROGRAMA,PROJETOS,ATIVIDADES E OPER.ESPECIAIS' },
      { id: 'anexo8', nome: 'ANEXO VIII - DEMONSTRATIVO DA DESPESA POR FUNÇÕES, SUBFUNÇÕES E PROGRAMAS, CONFORME O VÍNCULO DE RECURSOS' },
      { id: 'anexo9', nome: 'ANEXO IX - DEMONSTRATIVO DA DESPESA POR ÓRGÃOS E FUNÇÕES' },
      { id: 'sumario', nome: 'SUMÁRIO GERAL DA RECEITA POR FONTES E DA DESPESA POR FUNÇÕES DE GOVERNO' },
      { id: 'unidAtuacao', nome: 'CAMPO DE ATUAÇÃO DAS UNIDADES ORÇAMENTARIAS' },
      { id: 'prev', nome: 'ANEXO PREVISÃO FINANCEIRA DE ARRECADAÇÃO' },
      { id: 'crono', nome: 'ANEXO CRONOGRAMA DE EXECUÇÃO DE DESEMBOLSO' },
      { id: 'qdd', nome: 'QDD - QUADRO DETALHADO DA DESPESA' },
      { id: 'rel_acao', nome: 'RELAÇÃO DE AÇÕES DE GOVERNO CONSTANTES NO ORÇAMENTO' },
      { id: 'anexo2_ce', nome: 'DESPESAS SEGUNDO AS CATEGORIAS ECONÔMICAS' },
      { id: 'receitaRecurso', nome: 'ANEXO - RECEITA' },
      { id: 'apl_vinculadas', nome: 'APLICAÇÕES VINCULADAS' }
    ];

    this.carregarAutocomplete()
  }

  imprimir() {
    const orgaos: number[] = [];
    orgaos.push(this.login.orgao.id);

    if (this.anexoSelect === 'anexoI') {
      const dp = new Anexo1(this.anexoServico);
      dp.montarRelatorio(this.exercicioSelect, orgaos, [`${this.login.orgao.codigo} - ${this.login.orgao.nome}`]);
    } else if (this.anexoSelect === 'anexo2Receita') {
      const dp = new Anexo2Receita(this.anexoServico);
      dp.montarRelatorio(this.exercicioSelect, orgaos);
    } else if (this.anexoSelect === 'anexo2Despesa') {
      const dp = new Anexo2Despesa(this.anexoServico);
      dp.montarRelatorio(this.exercicioSelect, orgaos);
    } else if (this.anexoSelect === 'anexo2Executora') {
      const dp = new Anexo2Executora(this.anexoServico);
      dp.montarRelatorio(this.exercicioSelect, orgaos);
    } else if (this.anexoSelect === 'unidAtuacao') {
      const dp = new AnexoUnidadeAtuacao(this.anexoServico);
      dp.montarRelatorio(this.exercicioSelect, orgaos);
    } else if (this.anexoSelect === 'anexo6') {
      const dp = new Anexo6(this.anexoServico);
      dp.montarRelatorio(this.exercicioSelect, orgaos, false);
    } else if (this.anexoSelect === 'anexo7') {
      const dp = new Anexo7(this.anexoServico);
      dp.montarRelatorio(this.exercicioSelect, orgaos);
    } else if (this.anexoSelect === 'anexo8') {
      const dp = new Anexo8(this.anexoServico);
      dp.montarRelatorio(this.exercicioSelect, orgaos, [`${this.login.orgao.codigo} - ${this.login.orgao.nome}`]);
    } else if (this.anexoSelect === 'anexo9') {
      const dp = new Anexo9(this.anexoServico);
      dp.montarRelatorio(this.exercicioSelect, orgaos);
    } else if (this.anexoSelect === 'sumario') {
      const dp = new Sumario(this.anexoServico);
      dp.montarRelatorio(this.exercicioSelect, orgaos, [this.login.orgao.nome]);
    } else if (this.anexoSelect === 'qdd') {
      if (!this.orcamento) {
        const dp = new Qdd(this.anexoServico);
        dp.montarRelatorio(this.exercicioSelect, orgaos, this.montarFiltrosQdd())
      } else {
        const dp = new QddOrcamento(this.anexoServico);
        dp.montarRelatorio(this.exercicioSelect, orgaos, this.montarFiltrosQdd())
      }
    } else if (this.anexoSelect === 'prev') {
      const dp = new PrevisaoReceitaLoa(this.anexoServico, this.progressoService);
      dp.montarRelatorio(this.exercicioSelect, orgaos, '1', '12', 'todos', 0);
    } else if (this.anexoSelect === 'crono') {
      this.filtroExecutora === true ? this.tribunal_executora = this.executoraSelect.tribunal : this.tribunal_executora = 'undefined';
      this.filtroExecutora === true ? this.tribunal_unidade = this.executoraSelect.unidade.tribunal : this.tribunal_unidade = 'undefined';
      this.filtroTipo === true ? this.tipo_previsao = this.tipo_previsao : this.tipo_previsao = 1;
      this.filtroMes === true ? (this.mes_inicial = this.mes_inicial) && (this.mes_final = this.mes_final) : (this.mes_inicial = 1) && (this.mes_final = 12);
      if (this.mes_final < this.mes_inicial) {
        toastr.options.positionClass = 'toast-top-left';
        toastr.warning('O mês inicial deve ser menor que o mês final!', 'ATENÇÃO');
        return
      }
      const dp = new PrevisaoDespesaLoa(this.anexoServico);
      dp.montarRelatorio(this.exercicioSelect, orgaos, this.quadrimestre, this.tipo_previsao, this.mes_inicial, this.mes_final, this.tribunal_executora, this.tribunal_unidade);
    } else if (this.anexoSelect === 'receitaRecurso') {
      const dp = new ReceitaRecurso(this.anexoServico);
      dp.montarRelatorio(this.exercicioSelect, orgaos);
    } else if (this.anexoSelect === 'rel_acao') {
      const dp = new Anexo6Orgao(this.anexoServico);
      dp.montarRelatorio(this.exercicioSelect, orgaos);
    } else if (this.anexoSelect === 'anexo2_ce') {
      const dp = new Anexo2Ce(this.anexoServico);
      dp.montarRelatorio(this.exercicioSelect, orgaos);
    } else if (this.anexoSelect === 'apl_vinculadas') {
      const dp = new AplicacaoVinculada(this.fichaReceitaService, this.fichaDespesaService, this.anexoServico)
      dp.montarRelatorio(this.exercicioSelect, orgaos, this.recursoReceita?.id, this.recursoDespesa?.id)
    } else {
      toastr.warning('Selecione o anexo a ser impresso');
    }
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }

  /**
   * Método para verificação de objetos, usados em combos `<select>`
   */
  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  montarFiltrosQdd(): {} {
    const paramentros = {};

    switch (this.filtrosQdd.condicao) {
      case 'T':
        paramentros['unidade'] = -1
        break;
      case 'U':
        paramentros['unidade'] = this.unidadeSelect.id;
        break;
      default:
        throw new Error('Selecione um tipo de unidade.');
    }

    switch (this.filtrosQdd.periodo) {
      case 'T':
        paramentros['data_inicial'] = -1;
        paramentros['data_final'] = -1;
        paramentros['trimestre'] = this.filtrosQdd.trimestre;
        paramentros['quadrimestre'] = -1;
        break;
      case 'Q':
        paramentros['data_inicial'] = -1;
        paramentros['data_final'] = -1;
        paramentros['trimestre'] = -1;
        paramentros['quadrimestre'] = this.filtrosQdd.quadrimestre;
        break;
      case 'D':
        paramentros['data_inicial'] = this.funcaoService.converteDataSQL(this.data_inicial);
        paramentros['data_final'] = this.funcaoService.converteDataSQL(this.data_final);
        paramentros['trimestre'] = -1;
        paramentros['quadrimestre'] = -1
        break;
      default:
        throw new Error('Selecione ao menos um tipo de período.');
    }

    switch (this.filtrosQdd.tipo) {
      case 'E':
        paramentros['tipo_valor'] = 'empenhado'
        break;
      case 'L':
        paramentros['tipo_valor'] = 'liquidado'
        break;
      //Quando for pelo valor Emp, Liq, Pag, consulta apenas o vl de pagamento
      case 'P':
      case 'T':
        paramentros['tipo_valor'] = 'pago'
        break;
      default:
        throw new Error('Selecione ao menos um tipo de valor.');
    }

    switch (this.filtrosQdd.agrupar) {
      case 'F':
        paramentros['agrupamento'] = 'F'
        break;
      case 'T':
        paramentros['agrupamento'] = 'T'
        break;
      default:
        throw new Error('Selecione o tipo de agrupamento.');
    }

    return paramentros;
  }

  private carregarAutocomplete() {
    this.recursoDespesaAutoComplete = new EddyAutoComplete(null, this.recursoService, 'id', ['codigo', 'nome'], { nivel: '0', orderBy: 'codigo' }, { text: ['nome', 'codigo'] }
    );

    this.recursoReceitaAutoComplete = new EddyAutoComplete(null, this.recursoService, 'id', ['codigo', 'nome'], { nivel: '0', orderBy: 'codigo' }, { text: ['nome', 'codigo'] })

    this.executoraAutoComplete = new EddyAutoComplete(null, this.executoraService, 'id', ['codigo', 'nome'],
      { relations: 'unidade.ppa', orderBy: 'codigo,nome' },
      { text: ['codigo', 'nome'] }
    );
  }

}
