import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseResourceRptComponent, Coluna, DateFormatPipe, FuncaoService, Inventario, ProgressoService } from 'eddydata-lib';
import { takeUntil } from 'rxjs/operators';
import { InventarioIdentificacaoService } from '../../inventario/service/inventario-identificacao.service';
import { InventarioService } from '../../inventario/service/inventario.service';

@Component({
  selector: 'app-identificacao-rpt',
  templateUrl: './identificacao-rpt.component.html'
})
export class IdentificacaoRptComponent extends BaseResourceRptComponent {

  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public ordenacao: { nome: string, coluna: any, ordem: 'DESC' | 'ASC' };
  public listaOrdenacao: { nome: string, coluna: string, ordem: 'DESC' | 'ASC' }[] = [];
  public parametros: { session?: any, filtros?: any } = {};
  public retorno: Coluna[] = [];
  public agruparSecretaria: boolean = false;
  public situacao: boolean;
  public tipo: boolean;
  public inventario: Inventario;
  public listaInventario: Inventario[] = [];

  // ========================================================================
  // ------------------------------ CONSTRUTOR ------------------------------
  // ========================================================================
  constructor(
    protected funcaoService: FuncaoService,
    protected progressoService: ProgressoService,
    protected inventarioService: InventarioService,
    private inventarioIdentificacaoService: InventarioIdentificacaoService) {
    super();
  }

  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.listaOrdenacao = this.obterColunasOrdenacoes();
    this.ordenacao = this.listaOrdenacao[0];

    this.inventarioService.filtrar(0, 0, {
      orgao_id: this.login.orgao.id,
      orderBy: 'descricao'
    }).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      return this.listaInventario = res.content;
    });
  }

  protected tituloRelatorio(): string {
    return 'IDENTIFICAÇÕES DE BENS PATRIMONIAIS';
  }

  protected subTituloRelatorio(): string {
    let subTitulo = '';

    if (this.situacao !== undefined) {
      subTitulo = this.situacao ? 'Aguardando Identificação' : 'Identificação Concluída';
    }

    if (this.tipo !== undefined) {
      if (subTitulo.length) subTitulo += ' e ';
      subTitulo += this.tipo ? 'Sem Identificação' : 'Com Identificação';
    }

    return subTitulo;
  }

  private carregarAutoCompletes() {}

  protected obterColunasRelatorio(): Coluna[] {
    this.retorno = [];
    this.retorno.push({ titulo: 'GRUPO', coluna: 'setor', agrupar: true, fontSize: 15, bold: true });
    this.retorno.push({ titulo: 'SETOR', coluna: 'setor', fontSize: 8 });
    this.retorno.push({ titulo: 'USUÁRIO', coluna: 'usuario', fontSize: 8, alignment: 'left' });
    this.retorno.push({ titulo: 'PLACA', coluna: 'tombamento.placa', fontSize: 8, alignment: 'left' });
    this.retorno.push({ titulo: 'DESCRIÇÃO', coluna: 'descricao', fontSize: 8, alignment: 'left' });
    this.retorno.push({ titulo: 'OBSERVAÇÃO', coluna: 'observacao', fontSize: 8, alignment: 'left' });
    this.retorno.push({ titulo: 'DATA', coluna: 'data_cadastro', fontSize: 8, alignment: 'center' });
    return this.retorno;
  }

  protected larguraColunas(): (string | number)[] {
    return ['*', 'auto', 'auto', '*', 'auto', 'auto'];
  }

  protected totalizarColunas(): (string | {})[] {
    return null;
  }

  protected async carregarLista(): Promise<any[]> {
    return new Promise(async (resolve) => {
      let parametros = {
        relations: 'tombamento,tombamento.setor.unidade,inventario_setor.setor.unidade,inventario_setor.inventario,usuario',
      };

      if (this.inventario)
        parametros['inventario_setor.inventario.id'] = this.inventario.id;

      if (this.situacao !== undefined)
        parametros['pendente'] = this.situacao;

      if (this.tipo !== undefined)
        parametros[`tombamento.placa$${this.tipo ? 'null' : 'not_null'}`] = true;

      parametros['orderBy'] =  this.agruparSecretaria ? 'inventario_setor.setor.unidade.nome,inventario_setor.setor.nome' : 'inventario_setor.setor.nome';

      if (this.parametros?.filtros)
        parametros = Object.assign(parametros, this.parametros.filtros);

      const data = await this.inventarioIdentificacaoService.extendido(0, -1, parametros).toPromise();
      if (data?.content?.length > 0) {
        for (const element of data.content) {
          element.data_cadastro = new DateFormatPipe().transform(element.data_cadastro, []);
          element.usuario = element?.usuario?.nome;
          element.setor = element?.tombamento?.setor?.id ? element.tombamento.setor.nome : element?.inventario_setor?.setor.nome;
          element.setor_order = element?.tombamento?.setor?.id ? element.tombamento.setor : element?.inventario_setor?.setor;
        }

        data.content.sort((a, b) => +a.setor_order.id - +b.setor_order.id)
        resolve(data.content)
      } else {
        resolve(null)
      }
    });

  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================


  public obterColunasOrdenacoes(): { nome: string, coluna: string, ordem: 'DESC' | 'ASC' }[] {
    return [
      { nome: 'Placa', coluna: 'tombamento.placa', ordem: 'ASC' },
      { nome: 'Descrição', coluna: 'descricao', ordem: 'ASC' }
    ];
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

}
