import { Injectable, Injector } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BaseResourceService, EddyAutoComplete, Page, Produto } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProdutoService extends BaseResourceService<Produto> {

  constructor(
    protected injector: Injector
  ) {
    super(`produtos`, injector);
  }

  public obterPorMaterial(materialId: number): Observable<any> {
    return this.http.get<Produto>(
      `${this.login.cidade.id}/${this.api}/filtrar?relations=material,sub_grupo,grupo&orgao.id=${this.login.orgao.id}&material.id=${materialId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorNome(nome: string, orgaoId: number): Observable<Produto> {
    return this.http.get<Produto>(
      `${this.login.cidade.id}/${this.api}/filtrar?nome$like=${nome}%&orgao_id=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public proximoCodigo(material: number, bec: boolean, orgao: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-codigo/${material}/${bec}/${orgao}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public buscarProdutosSemelhanes(pagina: number, limite: number, descricoes: string[], produto: 'S' | 'N' = 'S', orgaoId: number, ignorar?: { produtoId: number }): Observable<Page> {
    const params = this.converterParametrosStr(ignorar);
    return this.http.post<Page>(
      `${this.login.cidade.id}/${this.api}/buscar-produtos-sempelhantes/${pagina}/${limite}/${produto}/${orgaoId}${params}`, { descricoes }, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public filtrarCodigoCompleto(pagina: number, limite: number, filtros: {}): Observable<Page> {
    let relations: string = filtros['relations'];
    if (relations) {
      if (!relations.includes('material.sub_grupo.grupo'))
        relations = `${relations},material.sub_grupo.grupo`;
    } else {
      relations = `material.sub_grupo.grupo`;
    }
    filtros['relations'] = relations;

    return this.filtrar(pagina, limite, filtros).pipe(map(res => {
      if (res?.content && res.content.length > 0)
        res.content = res.content.map((p) => {
          if (p?.material?.sub_grupo?.grupo)
            p.codigo = `${p.material.sub_grupo.grupo.codigo}.${p.material.sub_grupo.codigo}.${p.material.codigo}.${p.codigo}`;
          return p;
        });
      return res;
    }));
  }

  public listagemPorSubGrupo(parametros: { situacao: 'A' | 'I' | 'T', situacao_subelemento: 'A' | 'I' | 'T', exercicio_id: number, orgao_id: number, subelemento_id?: number, subgrupo_id?: number, material_tipo: 'M' | 'S' | 'T' }): Observable<listaSubgrupoType[]> {
    let params = this.converterParametrosStr(parametros);
    return this.http.get(`${this.login.cidade.id}/${this.api}/listagem-subgrupo${params}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    )
  }

  public static autoCompleteCodigoCompleto(campoForm: AbstractControl, service: ProdutoService, campoChave: string, campoLabel: string[], parametros: {}, filtros: {
    number?: string[]; date?: string[]; text?: string[];
  }, funcaoOnSelect?: any, limite?: any, conversorPersonalizado?: (entidade: Produto) => {}, filtrarPersonilizado?: (pagina: number, limite: number, filtros: {}, filtroStr: string, service?: ProdutoService) => Observable<Page>) {
    /*
        let relations: string = parametros['relations'];
        if (relations) {
          if (!relations.includes('material.sub_grupo.grupo'))
            relations = `${relations},material.sub_grupo.grupo`;
        } else {
          relations = `material.sub_grupo.grupo`;
        }
        parametros['relations'] = relations;
    
        if (filtros.number)
          filtros.text.push('codigo_comp');
        else
          filtros.text = ['codigo_comp'];
    
        if (!conversorPersonalizado)
          conversorPersonalizado = (entidade: Produto) => new GlobalService().descricaoProduto(entidade);
    */
    return new EddyAutoComplete(campoForm, service, campoChave, campoLabel, parametros, filtros, funcaoOnSelect, limite, conversorPersonalizado, filtrarPersonilizado);
  }
}

interface listaSubgrupoType {
  grupo_codigo: number,
  grupo_nome: string,
  subgrupo_codigo: number,
  subgrupo_nome: string
  subelemento_codigo: string,
  subelemento_nome: string,
  material_codigo: number,
  material_nome: string,
  material_tipo: string,
  material_aliquota_ir: string,
  produto_codigo: string,
  produto_nome: string,
  produto_aliquota_ir: string,
  subelemento_situacao: string,
  produto_situacao: string
}