import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'eddydata-lib';
import { CheckboxModule } from 'primeng/checkbox';
import { FieldsetModule } from 'primeng/fieldset';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AtaJulgamentoRptComponent } from './ata-julgamento-rpt/ata-julgamento-rpt.component';
import { AtaSessaoPublicaRptComponent } from './ata-sessao-publica-rpt/ata-sessao-publica-rpt.component';
import { ClassificacaoRptComponent } from './classificacao-rpt/classificacao-rpt.component';
import { EconomiaRptComponent } from './economia-rpt/economia-rpt.component';
import { EstimativaPrecoRptComponent } from './estimativa-preco-rpt/estimativa-preco-rpt.component';
import { LancesRptComponent } from './lances-rpt/lances-rpt.component';
import { MapaPrecoRptComponent } from './mapa-preco-rpt/mapa-preco-rpt.component';
import { MemorialRptComponent } from './memorial-rpt/memorial-rpt.component';
import { PresencaRptComponent } from './presenca-rpt/presenca-rpt.component';
import { TermoHomologacaoRptComponent } from './termo-homologacao-rpt/termo-homologacao-rpt.component';
import { VencedoresRptComponent } from './vencedores-rpt/vencedores-rpt.component';
import { CalendarModule } from 'primeng/calendar';
import { AutorizacaoResponsavelRptComponent } from './autorizacao-rpt/autorizacao-responsavel-rpt.component';
import { LicitacaoReciboRptComponent } from './licitacao-recibo-rpt/licitacao-recibo-rpt.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SelectButtonModule } from 'primeng/selectbutton';
import { AdjudicacaoRptComponent } from './adjudicacao-rpt/adjudicacao-rpt.component';
import { ClassificacaoJulgamentoRptComponent } from './classificacao-julgamento-rpt/classificacao-julgamento-rpt.component';
import { TableModule } from 'primeng/table';

@NgModule({
    declarations: [
        VencedoresRptComponent,
        ClassificacaoRptComponent,
        LancesRptComponent,
        MapaPrecoRptComponent,
        MemorialRptComponent,
        EstimativaPrecoRptComponent,
        TermoHomologacaoRptComponent,
        AtaJulgamentoRptComponent,
        AtaSessaoPublicaRptComponent,
        PresencaRptComponent,
        EconomiaRptComponent,
        AutorizacaoResponsavelRptComponent,
        LicitacaoReciboRptComponent,
        AdjudicacaoRptComponent,
        ClassificacaoJulgamentoRptComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        RadioButtonModule,
        FieldsetModule,
        SelectButtonModule,
        CheckboxModule,
        CalendarModule,
        AutoCompleteModule,
        TableModule,
    ],
    exports: [
        VencedoresRptComponent,
        ClassificacaoRptComponent,
        ClassificacaoJulgamentoRptComponent,
        LancesRptComponent,
        MapaPrecoRptComponent,
        MemorialRptComponent,
        EstimativaPrecoRptComponent,
        TermoHomologacaoRptComponent,
        AtaJulgamentoRptComponent,
        AtaSessaoPublicaRptComponent,
        PresencaRptComponent,
        EconomiaRptComponent,
        AutorizacaoResponsavelRptComponent,
        LicitacaoReciboRptComponent,
        AdjudicacaoRptComponent
    ],
    providers: []
})
export class RelatorioLicitacaoModule { }
