import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EddyAutoComplete, Favorecido, FavorecidoService, Fracionamento, LoginContabil, Produto, ProdutoUnidade, SetorAlmoxarifado, SetorAlmoxarifadoService, Unidade } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ProdutoUnidadeService } from '../../produto/service/produto-unidade.service';
import { ProdutoService } from '../../produto/service/produto.service';
import { FracionamentoService } from '../service/fracionamento.service';


declare var $: any;

@Component({
  selector: 'app-fracionamento-dlg-form',
  templateUrl: './fracionamento-dlg-form.component.html'
})
export class FracionamentoDlgFormComponent implements OnInit, OnChanges {

  @Input() login: LoginContabil;
  @Input() produto: Produto;

  public entidadeForm: FormGroup;
  protected unsubscribe: Subject<void> = new Subject();

  public imaskValor = {
    mask: Number,
    scale: 5,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;
  public dissolverAutoComplete: EddyAutoComplete<Produto>;
  public dissolvidoAutoComplete: EddyAutoComplete<Produto>;
  public listSetores: SetorAlmoxarifado[];
  public listProdUnDissolver: ProdutoUnidade[];
  public listProdUnDissolvido: ProdutoUnidade[];

  constructor(
    private dissolucaoService: FracionamentoService,
    private setorAlmoxarifadoService: SetorAlmoxarifadoService,
    private favorecidoService: FavorecidoService,
    private produtoService: ProdutoService,
    private produtoUnService: ProdutoUnidadeService,
    private fb: FormBuilder) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.carregarSetores();
    if (changes.produto) {
      if (this.entidadeForm && this.produto) {
        this.entidadeForm.patchValue({});
        console.log(this.entidadeForm.value);
        this.entidadeForm.get('dissolver')
          .get('produto').setValue(this.produto);
        this.carregarUnidades(this.produto, 'F1');
      }
    }
  }

  ngOnInit(): void {
    this.criarCamposForm();
    this.carregarAutoCompletes();
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      qtd_dissolver: [0, [Validators.required]],
      qtd_dissolvido: [0, [Validators.required]],
      dissolver: this.fb.group({
        id: [null, Validators.required],
        unidade: [null],
        produto: [null, Validators.required]
      }),
      dissolvido: this.fb.group({
        id: [null, Validators.required],
        unidade: [null],
        produto: [null, Validators.required]
      }),
      setor_almoxarifado: [null, Validators.required],
      usuario: [this.login.usuario],
      exercicio: [this.login.exercicio],
      localizacao: [null],
      orgao: [this.login.orgao],
      recebedor: this.fb.group({
        nome: [this.login.usuario.nome],
        cpf_cnpj: [this.login.usuario.cpf],
        email: [this.login.usuario.email],
        cidade: [this.login.cidade]
      }),
      favorecido: [null],
      observacao: [null]
    });

    this.entidadeForm.get('dissolver').get('produto').disable();
  }

  public validSubmit(): boolean {

    if (this.entidadeForm.get('qtd_dissolver').value <= 0) {
      toastr.warning('Quantidade a ser fracionado precisa ser maior que 0!');
      return false;
    } else if (this.entidadeForm.get('qtd_dissolvido').value <= 0) {
      toastr.warning('Quantidade fracionado precisa ser maior que 0!');
      return false;
    }

    let dissover: ProdutoUnidade = this.entidadeForm.get('dissolver').value;
    if (!dissover.id && !dissover.produto?.id) {
      toastr.warning('Necessario informar produto e unidade a ser fracionado!');
      return false;
    } else if (!dissover.id && !dissover.unidade?.id) {
      toastr.warning('Necessario informar unidade do produto a ser fracionado!');
      return false;
    }

    let dissolvido: ProdutoUnidade = this.entidadeForm.get('dissolvido').value;
    if (!dissolvido.id && !dissolvido.produto?.id) {
      toastr.warning('Necessario informar produto e unidade fracionado!');
      return false;
    } else if (!dissolvido.id && !dissolvido.unidade?.id) {
      toastr.warning('Necessario informar unidade do produto fracionado!');
      return false;
    } else if (!this.entidadeForm.get('setor_almoxarifado')) {
      toastr.warning('Necessario setor de produto a ser fracionado!');
      return false;
    }
    return true;
  }

  async submitForm() {
    if (this.entidadeForm.invalid) {
      toastr.error('Há campos obrigatórios não informados!');
      return;
    }
    if (!this.validSubmit())
      return;
    const entidade: Fracionamento = Fracionamento.converteJson(this.entidadeForm.getRawValue());
    this.dissolucaoService.atualizar(entidade)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        toastr.success('Produto fracionado!');
        $('#dlgFrancionadoForm').modal('hide');
      },
        error => toastr.error(error.error.payload));
  }

  carregarAutoCompletes() {
    this.favorecidoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('favorecido'), this.favorecidoService,
      'id', ['nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );
    // autocomplete para produto
    this.dissolverAutoComplete = new EddyAutoComplete(null, this.produtoService,
      'id', ['codigo', 'nome'], {orgao_id: this.login.orgao.id, orderBy: 'codigo,nome' }, { number: ['id', 'codigo'], text: ['codigo', 'nome'] }
    );
    // autocomplete para produto
    this.dissolvidoAutoComplete = new EddyAutoComplete(null, this.produtoService,
      'id', ['codigo', 'nome'], {orgao_id: this.login.orgao.id, orderBy: 'codigo,nome' }, { number: ['id', 'codigo'], text: ['codigo', 'nome'] }
    );
  }

  private carregarSetores() {
    this.setorAlmoxarifadoService.filtrar(0, 0, {
      estoque_id: this.login.estoque.id, relations: 'setor'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listSetores = res ? res.content : [];
        if (this.listSetores.length === 0) {
          toastr.error('Nenhum setor foi localizado neste almoxarifado!');
          $('#dlgFrancionadoForm').modal('hide');
          return;
        }
        this.entidadeForm.get('setor_almoxarifado').setValue(null);
        for (const s of res.content as SetorAlmoxarifado[]) {
          if (s.padrao) {
            this.entidadeForm.get('setor_almoxarifado').setValue(s);
            break;
          }
        }
      }, error => toastr.error('Nenhum setor foi localizado neste almoxarifado!'));
  }

  public carregarUnidades(produto: Produto, tipo: 'F1' | 'F2') {
    this.produtoUnService.filtrarCodigoCompleto(0, 0, {
      produto_id: produto.id,
      ativo: true,
      relations: 'produto,unidade',
      orderBy: 'unidade.nome'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        let listProdUn = res ? res.content : [];
        if (listProdUn.length === 0) {
          toastr.error('Nenhuma unidade ativa foi localizada para este produto!');
          return;
        }
        let f = this.getFormGroup(tipo);
        if (!f.value.id) {
          f.patchValue(listProdUn[0]);
        }
        if (tipo === 'F1')
          this.listProdUnDissolver = listProdUn;
        else
          this.listProdUnDissolvido = listProdUn;
      }, error => toastr.error(error.message ? error.message : error));
  }

  private getFormGroup(tipo: 'F1' | 'F2') {
    return this.entidadeForm.get(tipo === 'F1' ? 'dissolver' : 'dissolvido');
  }

  public changeUnidade(tipo: 'F1' | 'F2') {
    let f = this.getFormGroup(tipo);
    let uni: Unidade = f.get('unidade').value;
    if (uni && uni.id)
      if (tipo === 'F1')
        f.patchValue(this.listProdUnDissolver.find((pu) => pu.unidade.id === uni.id));
      else
        f.patchValue(this.listProdUnDissolvido.find((pu) => pu.unidade.id === uni.id));
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }
}