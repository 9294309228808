// tslint:disable: variable-name
import { Ppa } from './ppa.model';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Receita } from './receita.model';
import { FonteOrgao } from './fonte-orgao.model';

export class FonteFinanciamento extends BaseResourceModel {
  constructor(
    public id?: number,
    public receita?: Receita,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public ppa?: Ppa,
    public fonte_orgaos?: FonteOrgao[],
  ) {
    super();
  }

  static converteJson(json: any): FonteFinanciamento {
    return Object.assign(new FonteFinanciamento(), json);
  }
}
