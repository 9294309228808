import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../assinaturas/assinaturas';
import { AnexoLRFService } from '../../service/anexo-lrf.service';

@Directive()
export class ReceitaCorrenteLiquidaLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();
  public valorRCL = 0;

  constructor(
    protected anexoServico: AnexoLRFService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected assinaturaControleInterno: boolean) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async obterReceitaCorrenteLiquida(orgaos: number[]) {
    const result = await this.anexoServico.obterRCL(this.mes, this.exercicio.id, orgaos).toPromise();
    return this.rclConteudo(result);
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao = 'pdf') {
    const parametros: {} = {};
    if (this.mes) {
      parametros['mes'] = this.mes;
    }
    if (this.exercicio) {
      parametros['ano'] = this.exercicio.id;
    }
    this.anexoServico.obterRCL(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        parametros['orgaos'] = orgaos.join();
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado('DEMONSTRATIVO DE APURAÇÃO DA RECEITA CORRENTE LÍQUIDA - R.C.L.'
            , this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.rclConteudo(dados),
            'landscape', 'DEMONSTRATIVO DE APURAÇÃO DA RECEITA CORRENTE LÍQUIDA - R.C.L.',
            {
              linhas: {
                hLineWidth(i, node) {
                  return 1;
                },
                vLineWidth(i) {
                  return 1;
                },
                hLineColor(i) {
                  return 'black';
                },
                paddingLeft(i) {
                  return 3;
                },
                paddingRight(i, node) {
                  return 3;
                }
              }
            });
        } else {
          this.funcaoService.exportar(formato, this.normalizar(dados), 'DEMONSTRATIVO DE APURAÇÃO DA RECEITA CORRENTE LÍQUIDA - R.C.L.', this.colunas());
        }
      });
  }

  private async rclConteudo(dados: any[]): Promise<{}[]> {
    const registros: {}[] = [
      [
        {
          text: 'REFERÊNCIA: ' + this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano,
          alignment: 'center',
          fontSize: 12,
          bold: true,
          colSpan: 14, border: [false, false, false, false]
        }, '', '', '', '', '', '', '', '', '', '', '', '', ''
      ],
      [{
        text: 'LRF, art 53, inciso I',
        alignment: 'left',
        bold: true,
        colSpan: 14, border: [false, false, false, false]
      }, '', '', '', '', '', '', '', '', '', '', '', '', ''],
      [
        {
          text: '', bold: true, colSpan: 14, margin: [0, 0, 0, 0], fontSize: 6, border: [false, false, false, false]
        }, '', '', '', '', '', '', '', '', '', '', '', '', ''
      ],
      [
        {
          text: 'ESPECIFICAÇÃO',
          fontSize: 6,
          rowSpan: 2, alignment: 'center', bold: true
        },
        {
          text: 'EVOLUÇÃO DA RECEITA REALIZADA NOS ÚLTIMOS DOZE MESES',
          alignment: 'center',
          fontSize: 6,
          bold: true,
          colSpan: 12
        }, '', '', '', '', '', '', '', '', '', '', '',
        {
          text: 'Total 12 meses',
          fontSize: 6,
          rowSpan: 2, alignment: 'center', bold: true
        }
      ],
      [
        { text: '', fontSize: 6, },
        { text: this.retornarNomeMes(-11), alignment: 'center', fontSize: 6, bold: true },
        { text: this.retornarNomeMes(-10), alignment: 'center', fontSize: 6, bold: true },
        { text: this.retornarNomeMes(-9), alignment: 'center', fontSize: 6, bold: true },
        { text: this.retornarNomeMes(-8), alignment: 'center', fontSize: 6, bold: true },
        { text: this.retornarNomeMes(-7), alignment: 'center', fontSize: 6, bold: true },
        { text: this.retornarNomeMes(-6), alignment: 'center', fontSize: 6, bold: true },
        { text: this.retornarNomeMes(-5), alignment: 'center', fontSize: 6, bold: true },
        { text: this.retornarNomeMes(-4), alignment: 'center', fontSize: 6, bold: true },
        { text: this.retornarNomeMes(-3), alignment: 'center', fontSize: 6, bold: true },
        { text: this.retornarNomeMes(-2), alignment: 'center', fontSize: 6, bold: true },
        { text: this.retornarNomeMes(-1), alignment: 'center', fontSize: 6, bold: true },
        { text: this.retornarNomeMes(0), alignment: 'center', fontSize: 6, bold: true }
      ]
    ];

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(15, false, null, this.assinaturaControleInterno);

    const grupos = this.funcaoService.agrupar(dados, 'tipo',
      ['soma0', 'soma1', 'soma2', 'soma3', 'soma4', 'soma5', 'soma6', 'soma7', 'soma8', 'soma9', 'soma10', 'soma11']);
    let somaTotal = 0.0;
    let divisor = "";
    let totais = null;
    for (const grupo of grupos) {

      if (grupo.grupo === 'TOTALIZADORES') {
        totais = grupo.registros[0];
        continue;
      }
      let somaGrupo = 0.0;
      for (let idx = 11; idx >= 0; idx--) {
        somaGrupo += +grupo.totalizadores[`soma${idx}`];
      }
      if (somaTotal === 0.0) {
        somaTotal += somaGrupo;
      } else {
        somaTotal -= somaGrupo;
      }
      divisor = grupo.grupo.toString();
      if (!grupo.grupo.toString().includes('EMENDA')) { // Pedaço do relatorio foi retirado como solicitado no chamado  169317
        registros.push([
          {
            text: `${grupo.grupo}`, fontSize: 6, bold: true
          },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma11`]), alignment: 'right', fontSize: 6, bold: true },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma10`]), alignment: 'right', fontSize: 6, bold: true },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma9`]), alignment: 'right', fontSize: 6, bold: true },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma8`]), alignment: 'right', fontSize: 6, bold: true },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma7`]), alignment: 'right', fontSize: 6, bold: true },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma6`]), alignment: 'right', fontSize: 6, bold: true },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma5`]), alignment: 'right', fontSize: 6, bold: true },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma4`]), alignment: 'right', fontSize: 6, bold: true },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma3`]), alignment: 'right', fontSize: 6, bold: true },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma2`]), alignment: 'right', fontSize: 6, bold: true },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma1`]), alignment: 'right', fontSize: 6, bold: true },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma0`]), alignment: 'right', fontSize: 6, bold: true },
          {
            text: this.funcaoService.convertToBrNumber(somaGrupo), alignment: 'right', fontSize: 6, bold: true
          }
        ]);
      }
      for (const registro of grupo.registros) {
        if (registro?.nome !== 'Receita Tributária' && registro?.nome !== 'Receitas de Contribuições') {
          registros.push([
            {
              text: `${registro.nome}`, fontSize: 6,
            },
            { text: this.funcaoService.convertToBrNumber(registro.soma11), fontSize: 6, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(registro.soma10), fontSize: 6, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(registro.soma9), fontSize: 6, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(registro.soma8), fontSize: 6, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(registro.soma7), fontSize: 6, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(registro.soma6), fontSize: 6, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(registro.soma5), fontSize: 6, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(registro.soma4), fontSize: 6, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(registro.soma3), fontSize: 6, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(registro.soma2), fontSize: 6, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(registro.soma1), fontSize: 6, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(registro.soma0), fontSize: 6, alignment: 'right' },
            {
              text: this.funcaoService.convertToBrNumber((+registro.soma11 + +registro.soma10 + +registro.soma9 + +registro.soma8
                + +registro.soma7 + +registro.soma6 + +registro.soma5 + +registro.soma4
                + +registro.soma3 + +registro.soma2 + +registro.soma1 + +registro.soma0)), fontSize: 6, alignment: 'right'
            }
          ]);
        }
      }
    }

    //Total RCL O21
    registros.push([{
      text: `RECEITA CORRENTE LÍQUIDA AJUSTADA PARA CÁLCULO DOS LIMITES DA DESPESA COM PESSOAL (VII) = (V - VI${this.exercicio.ano >= 2024 ? ' - VII' : ''})`, bold: true,
      alignment: 'center',
      colSpan: 13,
      fontSize: 6,
    }, '', '', '', '', '', '', '', '', '', '', '', '', {
      text: this.funcaoService.convertToBrNumber(totais.O21),
      fontSize: 6,
      alignment: 'right', bold: true
    }]);

    //Total RCL O19
    registros.splice(registros.length + (this.exercicio.ano >= 2024 ? -3 : -2), 0, [{
      text: 'RECEITA CORRENTE LÍQUIDA AJUSTADA PARA PARA CÁLCULO DOS LIMITES DE ENDIVIDAMENTO (V) = (III - IV)', bold: true,
      alignment: 'center',
      fontSize: 6,
      colSpan: 13
    }, '', '', '', '', '', '', '', '', '', '', '', '', {
      text: this.funcaoService.convertToBrNumber(totais.O19),
      fontSize: 6,
      alignment: 'right', bold: true
    }]);

    //Total RCL 017
    registros.splice(registros.length + (this.exercicio.ano >= 2024 ? -5 : -4), 0, [{
      text: 'RECEITA CORRENTE LÍQUIDA (III) = (I - II)', bold: true,
      alignment: 'center',
      fontSize: 6,
      colSpan: 13
    }, '', '', '', '', '', '', '', '', '', '', '', '', {
      text: this.funcaoService.convertToBrNumber(totais.O17),
      fontSize: 6,
      alignment: 'right', bold: true
    }]);

    this.valorRCL = somaTotal;
    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    },
    {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*'],
        body: assinaturas
      }
    }
    ];
  }

  retornarNomeMes(diminuir: number) {
    const fator: number = +this.mes + +diminuir;
    if (fator > 0) {
      return ('00' + fator).slice(-2)
        + `/${this.exercicio.ano}`;
    } else {
      const meses: number = +12 + +fator;
      return ('00' + meses).slice(-2) + `/${+this.exercicio.ano - 1}`;
    }
  }

  public normalizar(lista) {
    const listaExportar = []
    let soma0 = 0;
    let soma1 = 0;
    let soma2 = 0;
    let soma3 = 0;
    let soma4 = 0;
    let soma5 = 0;
    let soma6 = 0;
    let soma7 = 0;
    let soma8 = 0;
    let soma9 = 0;
    let soma10 = 0;
    let soma11 = 0;
    let somaTotal = 0.0;

    let totais = null;
    let totais_17 = 0.0;
    let totais_19 = 0.0;
    let totais_21 = 0.0;

    const grupos = this.funcaoService.agrupar(lista, 'tipo',
      ['soma0', 'soma1', 'soma2', 'soma3', 'soma4', 'soma5', 'soma6', 'soma7', 'soma8', 'soma9', 'soma10', 'soma11']);
    for (const grupo of grupos) {
      let somaGrupo = 0.0;
      for (let idx = 11; idx >= 0; idx--) {
        somaGrupo += +grupo.totalizadores[`soma${idx}`];
      }
      if (somaTotal === 0.0) {
        somaTotal += somaGrupo;
      } else {
        somaTotal -= somaGrupo;
      }

      let total0 = 0;
      let total1 = 0;
      let total2 = 0;
      let total3 = 0;
      let total4 = 0;
      let total5 = 0;
      let total6 = 0;
      let total7 = 0;
      let total8 = 0;
      let total9 = 0;
      let total10 = 0;
      let total11 = 0;

      for (const grupo of grupos) {
        if (grupo.grupo === 'TOTALIZADORES') {
          totais = grupo.registros[0];
          continue;
        }

        soma11 = grupo.totalizadores['soma11'];
        soma10 = grupo.totalizadores['soma10'];
        soma9 = grupo.totalizadores['soma9'];
        soma8 = grupo.totalizadores['soma8'];
        soma7 = grupo.totalizadores['soma7'];
        soma6 = grupo.totalizadores['soma6'];
        soma5 = grupo.totalizadores['soma5'];
        soma4 = grupo.totalizadores['soma4'];
        soma3 = grupo.totalizadores['soma3'];
        soma2 = grupo.totalizadores['soma2'];
        soma1 = grupo.totalizadores['soma1'];
        soma0 = grupo.totalizadores['soma0'];

        total11 += soma11;
        total10 += soma10;
        total9 += soma9;
        total8 += soma8;
        total7 += soma7;
        total6 += soma6;
        total5 += soma5;
        total4 += soma4;
        total3 += soma3;
        total2 += soma2;
        total1 += soma1;
        total0 += soma0;

        if (!grupo.grupo.toString().includes('EMENDA')) {
          if (!grupo.grupo.toString().includes('TOTALIZADORES')) {
            const primeira_linha = {
              especificacao: grupo.grupo,
              soma11: this.funcaoService.convertToBrNumber(soma11),
              soma10: this.funcaoService.convertToBrNumber(soma10),
              soma9: this.funcaoService.convertToBrNumber(soma9),
              soma8: this.funcaoService.convertToBrNumber(soma8),
              soma7: this.funcaoService.convertToBrNumber(soma7),
              soma6: this.funcaoService.convertToBrNumber(soma6),
              soma5: this.funcaoService.convertToBrNumber(soma5),
              soma4: this.funcaoService.convertToBrNumber(soma4),
              soma3: this.funcaoService.convertToBrNumber(soma3),
              soma2: this.funcaoService.convertToBrNumber(soma2),
              soma1: this.funcaoService.convertToBrNumber(soma1),
              soma0: this.funcaoService.convertToBrNumber(soma0),
              total_12_meses: this.funcaoService.convertToBrNumber(somaGrupo)
            }
            listaExportar.push(primeira_linha)
          }
        }

        for (const item of grupo.registros) {
          if (item?.nome !== 'Receita Tributária' && item?.nome !== 'Receitas de Contribuições' && item?.nome !== 'TOTAIS') {
            const linhas = {
              especificacao: item.nome,
              soma11: this.funcaoService.convertToBrNumber(item.soma11),
              soma10: this.funcaoService.convertToBrNumber(item.soma10),
              soma9: this.funcaoService.convertToBrNumber(item.soma9),
              soma8: this.funcaoService.convertToBrNumber(item.soma8),
              soma7: this.funcaoService.convertToBrNumber(item.soma7),
              soma6: this.funcaoService.convertToBrNumber(item.soma6),
              soma5: this.funcaoService.convertToBrNumber(item.soma5),
              soma4: this.funcaoService.convertToBrNumber(item.soma4),
              soma3: this.funcaoService.convertToBrNumber(item.soma3),
              soma2: this.funcaoService.convertToBrNumber(item.soma2),
              soma1: this.funcaoService.convertToBrNumber(item.soma1),
              soma0: this.funcaoService.convertToBrNumber(item.soma0),
              total_12_meses: this.funcaoService.convertToBrNumber(+item.soma11 + +item.soma10 + +item.soma9 + +item.soma8
                + +item.soma7 + +item.soma6 + +item.soma5 + +item.soma4
                + +item.soma3 + +item.soma2 + +item.soma1 + +item.soma0)
            }
            listaExportar.push(linhas)
          }
        }
      }
      
      const totais_017 = {
        especificacao: 'RECEITA CORRENTE LÍQUIDA (III) = (I - II)',
        soma11: '',
        soma10: '',
        soma9: '',
        soma8: '',
        soma7: '',
        soma6: '',
        soma5: '',
        soma4: '',
        soma3: '',
        soma2: '',
        soma1: '',
        soma0: '',
        total_12_meses: this.funcaoService.convertToBrNumber(totais.O17)
      }
      listaExportar.splice(listaExportar.length - 2, 0, totais_017)

      const totais_019 = {
        especificacao: 'RECEITA CORRENTE LÍQUIDA AJUSTADA PARA PARA CÁLCULO DOS LIMITES DE ENDIVIDAMENTO (V) = (III - IV)',
        soma11: '',
        soma10: '',
        soma9: '',
        soma8: '',
        soma7: '',
        soma6: '',
        soma5: '',
        soma4: '',
        soma3: '',
        soma2: '',
        soma1: '',
        soma0: '',
        total_12_meses: this.funcaoService.convertToBrNumber(totais.O19)
      }
      listaExportar.splice(listaExportar.length - 1, 0, totais_019)


      const totais_021 = {
        especificacao: 'RECEITA CORRENTE LÍQUIDA AJUSTADA PARA CÁLCULO DOS LIMITES DA DESPESA COM PESSOAL (VII) = (V - VI)',
        soma11: '',
        soma10: '',
        soma9: '',
        soma8: '',
        soma7: '',
        soma6: '',
        soma5: '',
        soma4: '',
        soma3: '',
        soma2: '',
        soma1: '',
        soma0: '',
        total_12_meses: this.funcaoService.convertToBrNumber(totais.O21)
      }
      listaExportar.push(totais_021);

      return listaExportar

    }
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'ESPECIFICAÇÃO', coluna: 'especificacao', bold: true },
      { titulo: `${this.retornarNomeMes(-11)}`, coluna: 'soma11' },
      { titulo: this.retornarNomeMes(-10), coluna: 'soma10' },
      { titulo: this.retornarNomeMes(-9), coluna: 'soma9' },
      { titulo: this.retornarNomeMes(-8), coluna: 'soma8' },
      { titulo: this.retornarNomeMes(-7), coluna: 'soma7' },
      { titulo: this.retornarNomeMes(-6), coluna: 'soma6' },
      { titulo: this.retornarNomeMes(-5), coluna: 'soma5' },
      { titulo: this.retornarNomeMes(-4), coluna: 'soma4' },
      { titulo: this.retornarNomeMes(-3), coluna: 'soma3' },
      { titulo: this.retornarNomeMes(-2), coluna: 'soma2' },
      { titulo: this.retornarNomeMes(-1), coluna: 'soma1' },
      { titulo: this.retornarNomeMes(0), coluna: 'soma0' },
      { titulo: 'Total 12 meses', coluna: 'total_12_meses' },

    ];
    return colunasDefault;
  }

}
