import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, FuncionarioCedido } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class FuncionarioCedidoService  extends BaseResourceService<FuncionarioCedido> {

  constructor(
    protected injector: Injector
  ) {
    super(`funcionarios-cedidos`, injector);
  }

}
