import { DatePipe } from '@angular/common';
import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { tsXLXS } from 'ts-xlsx-export';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  Login, ContaBancaria, Recurso, Convenio, EddyAutoComplete,
  GlobalService, FuncaoService, Relatorio, Coluna, PlanoConta, DateFormatPipe, FichaDespesa, Despesa, FichaExtra, FormatoExportacao
} from 'eddydata-lib';
import {
  ContaBancariaService, ConvenioService, PagamentoExtraService,
  RecursoService
} from 'administrativo-lib';
import { PlanoContaService } from '../../service/plano-conta.service';
import { FichaExtraService } from '../../../ficha-extra/service/ficha-extra.service';

@Component({
  selector: 'app-pagamento-extra-rpt',
  templateUrl: './pagamento-extra-rpt.component.html'
})
export class PagamentoExtraRptComponent implements OnInit, OnDestroy {

  login: Login = new Login();
  conta: ContaBancaria;
  plano: PlanoConta;
  ficha: FichaDespesa;
  recurso: Recurso;
  aplicacao: Recurso;
  aplicacao_variavel: Recurso;
  aplicacao_variavel_final: Recurso;
  RecursoAplicacao: Recurso;
  RecursoAplicacaoFinal: Recurso;
  modeloBusca: boolean = false;
  modeloBuscaText: string = 'Avançado';
  filtragemAvancada: boolean = false;
  filtroRecursoAplicacao: boolean = false;
  filtroFicha: boolean = false;
  convenio: Convenio;
  filtroPlano: boolean;
  filtroConta: boolean;
  filtroConvenio: boolean;
  filtroRecurso: boolean;
  filtroAplicacao: boolean;
  filtroAplicacao_variavel: boolean;
  public selectedOrdem: string;
  public selectedOrdenacao: string;
  public selectedModelo: string;

  public ptBR: any;

  public listaRelatorio: Array<any>;
  public listaConvenio: Array<Convenio>;
  public contaAutoComplete: EddyAutoComplete<ContaBancaria>;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public planoAutoComplete: EddyAutoComplete<PlanoConta>;
  public aplicacaoVariavelAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoVariavelFinalAutoComplete: EddyAutoComplete<Recurso>;
  public recursoAplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public fichaAutoComplete: EddyAutoComplete<FichaExtra>;
  public recursoAplicacaoFinalAutoComplete: EddyAutoComplete<Recurso>;
  public despesaAutoComplete: EddyAutoComplete<Despesa>;
  public relatorio = '1';
  public dataInicial: Date;
  public dataFinal: Date;
  protected datepipe: DatePipe;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    protected injector: Injector,
    protected messageService: MessageService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected contaService: ContaBancariaService,
    protected recursoService: RecursoService,
    protected planoService: PlanoContaService,
    protected convenioService: ConvenioService,
    protected fichaExtraService: FichaExtraService,
    protected pagamentoService: PagamentoExtraService) {
    this.datepipe = new DatePipe('pt')

  }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.login = GlobalService.obterSessaoLogin();
    this.selectedOrdem = 'ord1';
    this.selectedOrdenacao = 'ASC';
    this.selectedModelo = 'mod1'
    this.listaRelatorio = [];
    this.listaRelatorio.push({ label: 'TODOS OS PAGAMENTOS EXTRA ORÇAMENTÁRIOS', value: 1 });
    this.listaRelatorio.push({ label: 'PAGAMENTOS ORÇAMENTÁRIOS EXTRA ANULADOS', value: 2 });
    this.carregarAutoCompletes();
    this.dataInicial = new DateFormatPipe().transform(this.datepipe.transform(new Date(`01/01/${this.login.exercicio.ano}`), 'yyyy-MM-dd'), []);
    this.dataFinal = new DateFormatPipe().transform(this.datepipe.transform(new Date(), 'yyyy-MM-dd'), []);

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    new GlobalService().calendarMascara();
  }

  public statusModeloBusca(x: number) {
    // Explicacao -> x == 1 ? click no filtro de aplicacao : click no filtro avancado.

    if (x == 1 && !this.filtroAplicacao_variavel && this.filtragemAvancada && !this.modeloBusca)
      this.filtroAplicacao_variavel = true;

    if (x == 2 && !this.modeloBusca) {
      this.modeloBuscaText = 'Avançado';
      this.filtroRecursoAplicacao = false;
    } else if (x == 2 && this.modeloBusca) {
      this.modeloBuscaText = 'Simples';
      this.filtroRecurso = false; this.filtroAplicacao = false; this.filtroAplicacao_variavel = false;
    } else if (x == 2 && this.modeloBusca && !this.filtroRecursoAplicacao && this.filtragemAvancada) {
      this.modeloBuscaText = 'Simples';
      this.filtroRecursoAplicacao = true;
      this.filtroRecurso = false; this.filtroAplicacao = false; this.filtroAplicacao_variavel = false;
    }
  }

  public gerarRelatorio(formato: FormatoExportacao) {
    const parametros = {};
    if (!this.dataInicial || !this.dataFinal) {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }

    parametros['ignoreCondObrig'] = true
    parametros['orgao_id'] = this.login.orgao.id;

    parametros['data_pagamento$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
    parametros['data_pagamento$le'] = this.funcaoService.converteDataSQL(this.dataFinal);

    if (this.filtroConvenio) {
      parametros['convenio.numero'] = this.convenio.numero;
    }

    if (this.filtroConta) {
      parametros['conta.codigo'] = this.conta.codigo;
    }

    if (this.filtroPlano) {
      parametros['empenho.ficha.plano.id'] = this.plano.id;
    }

    if (this.filtroFicha) {
      parametros['empenho.ficha.numero'] = this.ficha.numero;
    }

    if (this.filtroRecurso && !this.modeloBusca) {
      parametros['empenho.ficha.recurso.id'] = this.recurso.id;
    }

    if (this.filtroAplicacao && !this.modeloBusca) {
      parametros['empenho.ficha.aplicacao.id'] = this.aplicacao.id;
    }

    // modelo de busca Avancado
    if (this.filtroAplicacao_variavel && !this.modeloBusca && !this.filtragemAvancada) {
      parametros['empenho.ficha.aplicacao_variavel.id'] = this.aplicacao_variavel.id;
    } else if (this.filtroAplicacao_variavel && !this.modeloBusca && this.filtragemAvancada) {
      parametros['empenho.ficha.aplicacao_variavel.id$ge'] = this.aplicacao_variavel.id;
      parametros['empenho.ficha.aplicacao_variavel.id$le'] = this.aplicacao_variavel_final.id;

      // modelo de busca Simples
    } else if (!this.filtroAplicacao_variavel && this.modeloBusca && this.filtroRecursoAplicacao && !this.filtragemAvancada) {
      parametros['empenho.ficha.aplicacao_variavel.id'] = this.RecursoAplicacao.id;
    } else if (!this.filtroAplicacao_variavel && this.modeloBusca && this.filtroRecursoAplicacao && this.filtragemAvancada) {
      parametros['empenho.ficha.aplicacao_variavel.id$ge'] = this.RecursoAplicacao.id;
      parametros['empenho.ficha.aplicacao_variavel.id$le'] = this.RecursoAplicacaoFinal.id;
    }

    if (this.relatorio === '2') {
      parametros['anulacao'] = true;
    }

    if (this.selectedOrdem === 'ord1') {
      parametros['orderBy'] = 'data_pagamento$' + this.selectedOrdenacao;
    } else if (this.selectedOrdem === 'ord2') {
      parametros['orderBy'] = 'conta.numero_conta$' + this.selectedOrdenacao;
    } else if (this.selectedOrdem === 'ord3') {
      parametros['orderBy'] = 'empenho.ficha.plano.codigo$' + this.selectedOrdenacao;
    } else if (this.selectedOrdem === 'ord4') {
      parametros['orderBy'] = 'empenho.favorecido.nome$' + this.selectedOrdenacao;
    }

    parametros['relations'] = 'conta,empenho.ficha,empenho.ficha.plano,'
      + 'empenho.ficha.recurso,empenho.ficha.aplicacao,empenho.ficha.aplicacao_variavel,' +
      'empenho.ordem_pagamento_item,empenho.favorecido,'
      + 'empenho.ordem_pagamento_item.ordem';

    this.pagamentoService.filtrar(1, -1,
      parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((lista) => {
        if (this.selectedModelo === 'mod1') {
          if (formato === 'pdf') {
            this.imprimir(`PAGAMENTOS EXTRAS ORÇAMENTÁRIOS PERÍODO DE ${this.datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${this.datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`
              + (this.relatorio === '2' ? '\nANULADOS' : ''),
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem pagamentos extras', ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], lista.content);
          } else {
            this.funcaoService.exportar(formato, lista.content, `PAGAMENTOS EXTRAS ORÇAMENTARIOS PERIODO DE ${this.datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} A ${this.datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`
              + (this.relatorio === '2' ? 'ANULADOS' : ''), this.colunas() as Coluna[]);
          }
        } else {
          if (formato === 'pdf') {
            this.imprimirMod2(`PAGAMENTOS EXTRAS ORÇAMENTÁRIOS PERÍODO DE ${this.datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${this.datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`
              + (this.relatorio === '2' ? '\nANULADOS' : ''),
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem pagamentos extras', ['auto', 'auto', 'auto', '*', 'auto', 'auto'], lista.content);
          } else {
            this.funcaoService.exportar(formato, lista.content, `PAGAMENTOS EXTRAS ORÇAMENTARIOS PERIODO DE ${this.datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} A ${this.datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`
              + (this.relatorio === '2' ? 'ANULADOS' : ''), this.colunasMod2() as Coluna[]);
          }
        }
      });
  }

  private imprimir(
    titulo: string, usuario: string, sobrenome: string, orgao: string,
    brasao: string, orientacao: 'landscape' | 'portrait', nomepdf?: string, largura?: string[], lista?: any[]) {
    Relatorio.imprimir(titulo, usuario, sobrenome, orgao, brasao, lista, this.colunas(), orientacao, nomepdf, largura, ['empenho.valor_empenho', 'valor_pago', { nome: 'valor_liquido', funcao: [{ se: { condicao: '1=1', verdadeiro: [0], falso: ['valor_pago', '-', 'valor_retido'] } }] }, 'valor_retido']);
  }

  private imprimirMod2(
    titulo: string, usuario: string, sobrenome: string, orgao: string,
    brasao: string, orientacao: 'landscape' | 'portrait', nomepdf?: string, largura?: string[], lista?: any[]) {
    Relatorio.imprimir(titulo, usuario, sobrenome, orgao, brasao, lista, this.colunasMod2(), orientacao, nomepdf, largura, [{ nome: 'valor_liquido', funcao: [{ se: { condicao: 'valor_retido=valor_pago', verdadeiro: [0], falso: ['valor_pago', '-', 'valor_retido'] } }] }]);
  }

  colunas(): string[] | Coluna[] {
    let liquido: Coluna = {
      titulo: 'Valor Liquido', coluna: 'valor_liquido', decimais: 2, alignment: 'right', funcao: [{
        se: {
          condicao: 'valor_retido=valor_pago',
          verdadeiro: ['0,00'],
          falso: ['valor_pago', '-', 'valor_retido']
        },
      }]
    };

    return [
      { titulo: 'Data', coluna: 'data_pagamento', agrupar: true, alignment: 'left', bold: true },
      { titulo: 'Conta N˚', coluna: 'conta.numero_conta', alignment: 'right' },
      { titulo: '', coluna: 'conta.digito_conta' },
      // { titulo: 'Descrição conta', coluna: 'conta.nome' },
      { titulo: 'OP', coluna: 'empenho.ordem_pagamento_item.ordem.numero' },
      { titulo: 'EMPENHO Nº', coluna: 'empenho.numero', bold: true, alignment: 'center', distinct: 'empenho' },
      { titulo: 'Favorecido', coluna: 'empenho.favorecido.nome' },
      { titulo: 'Documento', coluna: 'documento' },
      { titulo: 'Ficha', coluna: 'empenho.ficha.numero' },
      { titulo: 'Plano', coluna: 'empenho.ficha.plano.codigo' },
      { titulo: '', coluna: 'empenho.ficha.plano.nome' },
      { titulo: 'Recurso', coluna: 'empenho.ficha.recurso.codigo', alignment: 'right' },
      { titulo: '', coluna: 'empenho.ficha.aplicacao.codigo' },
      { titulo: 'Aplicação', coluna: 'empenho.ficha.aplicacao.nome' },
      { titulo: 'Empenhado', coluna: 'empenho.valor_empenho', decimais: 2, alignment: 'right' },
      { titulo: 'Valor Bruto', coluna: 'valor_pago', decimais: 2, alignment: 'right' },
      { titulo: 'Valor Retido', coluna: 'valor_retido', decimais: 2, alignment: 'right' },
      liquido,
      { titulo: 'Valor Pago', coluna: 'valor_pago', decimais: 2, alignment: 'right' }
    ];
  }

  colunasMod2(): string[] | Coluna[] {
    let liquido: Coluna = {
      titulo: 'Valor Liquido', coluna: 'valor_liquido', decimais: 2, alignment: 'right', funcao: [{
        se: {
          condicao: 'valor_retido=valor_pago',
          verdadeiro: ['0,00'],
          falso: ['valor_pago', '-', 'valor_retido']
        },
      }]
    };

    return [
      { titulo: 'Empenho', coluna: 'empenho.numero', alignment: 'left' },
      { titulo: 'Parcela', coluna: 'empenho.parcela', alignment: 'center' },
      { titulo: 'Favorecido', coluna: 'empenho.favorecido.nome' },
      { titulo: 'Historico', coluna: 'historico' },
      { titulo: 'Data pagamento', coluna: 'data_pagamento' },
      liquido
    ];
  }

  exportarXlxs() {
    const parametros = {};
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['relations'] = 'conta,empenho.ficha,empenho.ficha.plano,'
      + 'empenho.ficha.recurso,empenho.ficha.aplicacao,' +
      'empenho.ordem_pagamento_item,'
      + 'empenho.ordem_pagamento_item.ordem';

    parametros['orderBy'] = 'codigo';
    this.pagamentoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          const listaItens = new Array();
          for (const item of lista.content) {
            const entity = {
              codigo: item.codigo,
              cpf: item.cpf,
              nome: item.pessoa.nome,
              endereco: item.pessoa.endereco,
              bairro: item.pessoa.bairro,
              cidade: item.pessoa.municipio,
              telefone: item.pessoa.telefone,
              cep: item.pessoa.cep
            };
            listaItens.push(entity);
          }
          tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile('funcionarios');
        },
        () => alert('erro ao retornar lista')
      );
  }

  private carregarAutoCompletes() {
    // autocomplete para conta
    // this.contaAutoComplete = new EddyAutoComplete(null, this.contaService,
    //   'codigo', ['numero_conta', 'digito_agencia', 'nome'], { orgao_id: this.login.orgao.id, relations: 'banco', orderBy: 'nome' },
    //   { text: ['nome', 'numero_conta'] }
    // );

    this.contaAutoComplete = new EddyAutoComplete(
      null,
      this.contaService, "id", ["codigo", "banco.nome", "nome"],
      { orgao_id: this.login.orgao.id, ativo: true, relations: "banco", orderBy: "codigo" },
      { number: ["codigo"], text: ["banco.nome", "nome"] }
    );

    // autocomplete para plano
    this.planoAutoComplete = new EddyAutoComplete(null, this.planoService,
      'id', ['codigo', 'nome'], { escrituracao: true, exercicio_id: this.login.exercicio.id, orderBy: 'nome' },
      { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para ficha
    this.fichaAutoComplete = new EddyAutoComplete(null, this.fichaExtraService,
      'id', ['numero', 'nome'], { exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id, orderBy: 'nome' }, { number: ['numero'], text: ['nome'] }
    );

    // autocomplete para recurso
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para aplicacao Variavel
    this.aplicacaoVariavelAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['variavel', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['variavel', 'nome'] }
    );

    // autocomplete para aplicacao Variavel numero final (busca avancada)
    this.aplicacaoVariavelFinalAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['variavel', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['variavel', 'nome'] }
    );

    // autocomplete para recursoAplicacao e Variavel (busca simples)
    this.recursoAplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para recursoAplicacao e Variavel numero final (busca simples)
    this.recursoAplicacaoFinalAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['codigo', 'nome'] }
    );
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/tesouraria']);
        break;
      default:
        this.sair();
        break;
    }
  }
}
