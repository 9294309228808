import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ContaPublicaTipo } from 'eddydata-lib';
import { HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
  export class ContaPublicaTipoService extends BaseResourceService<ContaPublicaTipo> {
  
    constructor(
      protected injector: Injector
    ) {
      super(`contas-publicas-tipo`, injector);
    }

    public upload(entidade: any, arquivos: any): Observable<any> {
        const formData = new FormData();
        formData.append('entidade', JSON.stringify(entidade));
        let i = 1;
        for (const item of arquivos) {
          formData.append(`arquivo${i}`, item);
          i++;
        }
    
        return this.http.post(`${this.login.cidade.id}/${this.api}/upload`, formData, {
          headers: new HttpHeaders(this.adicionarCriptografia({
            Authorization: this.login.token
          }))
        }).pipe(
          map(res => res),
          catchError(err => this.handleError(err))
        );
      }
  }
  