import { BaseResourceModel } from '../../models/base-resource.model';
import { Imovel } from '../patrimonio/imovel.model';
import { Despesa } from '../planejamento/despesa.model';
import { FichaDespesa } from '../planejamento/ficha-despesa.model';
import { FaturaTipoImovelFavorecido } from './fatura-tipo-imovel-favorecido.model';
import { FaturaTipoImovelHistorico } from './fatura-tipo-imovel-historico.model';
import { FaturaTipo } from './fatura-tipo.model';
import { Fatura } from './fatura.model';

export class FaturaTipoImovel extends BaseResourceModel {
    constructor(
        public ativo?: boolean,
        public info1?: string,
        public info2?: string,
        public info3?: string,
        public fatura_tipo?: FaturaTipo,
        public dia_vencimento?: number,
        public imovel?: Imovel,
        public ficha_despesa?: FichaDespesa,
        public subelemento_despesa?: Despesa,
        public ficha_despesa_taxa?: FichaDespesa,
        public subelemento_despesa_taxa?: Despesa,
        public ficha_despesa_juros?: FichaDespesa,
        public subelemento_despesa_juros?: Despesa,
        public ficha_despesa_atualizacao?: FichaDespesa,
        public subelemento_despesa_atualizacao?: Despesa,
        public faturas?: Fatura[],
        public favorecidos?: FaturaTipoImovelFavorecido[],
        public historico?: FaturaTipoImovelHistorico[],
    ) {
        super();
    }

    static converteJson(json: any): FaturaTipoImovel {
        return Object.assign(new FaturaTipoImovel(), json);
    }
}
