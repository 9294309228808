import { DatePipe } from '@angular/common';
import * as toastr from 'toastr';
import { Component, Injector } from '@angular/core';
import { EmpenhoService, LiquidacaoService } from 'administrativo-lib';
import {
  BaseResourceListComponent, Coluna, DateFormatPipe, Empenho, EstoqueService, Filtro,
  FormatoExportacao, FuncaoService, GlobalService, Liquidacao, Login, LoginContabil, ProgressoService
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'lib-liquidacao-list',
  templateUrl: './liquidacao-list.component.html'
})
export class LiquidacaoListComponent extends BaseResourceListComponent<Liquidacao, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public especieSelect = 'LEO';
  public listaEspecies: Array<any>;
  public liquidacaoAnular: Liquidacao;
  public ptBR: any;
  public datepipe: DatePipe;
  public data1: Date;
  public data2: Date;
  public valorTotalLiquidado: number;
  public visualizarAnulacao: boolean = false;
  private listaEstoque: { valor: any, label: string }[] = [];
  public listaMeses: any[] = [];
  public mes = 1;
  public liquidacaoAtualizar: Liquidacao
  public visualizarAtualizacao = false;
  public empenhosInscritos: Empenho[] = [];
  public empenhosSelecionados: any[] = [];
  public numerosEmpenhos: any[] = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected progressoService: ProgressoService,
    private globalService: GlobalService,
    protected funcaoService: FuncaoService,
    private liquidacaoService: LiquidacaoService,
    private estoqueService: EstoqueService,
    private empenhoService: EmpenhoService,
  ) {
    super(liquidacaoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(item: Liquidacao): boolean {
    return !item.anulacao && !item.anulado_total && this.login.sistema != 'controle-interno'
  }
  
  protected relations(): string {
    return 'empenho,movimentos_estoque,movimentos_estoque.setorAlmoxarifado.estoque,empenho.ficha,empenho.favorecido,empenho.subelemento,empenho.exercicio,empenho.ficha.recurso,empenho.ficha.aplicacao,empenho.ficha.executora';
  }

  protected condicoesGrid(): {} {
    const parametros = {};
    this.datepipe = new DatePipe('pt');
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['exercicio.id'] = this.login.exercicio.id;

    sessionStorage.removeItem('/liquidacoes_data1');
    sessionStorage.removeItem('/liquidacoes_data2');
    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['empenho.numero$DESC', 'parcela$DESC', 'id$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['id', 'empenho.numero', 'valor_liquidado', 'total_retido', 'empenho.subelemento.codigo', 'empenho.favorecido.cpf_cnpj'],
      date: ['data_liquidacao'],
      text: ['empenho.favorecido.nome', 'empenho.subelemento.nome'],
    };
  }

  public beforeInit(): void {
    this.usarFiltroPersonalizado = true;
    this.usarExtendido = true;
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    const dt1: string = sessionStorage.getItem('/liquidacoes_data1');
    const dt2: string = sessionStorage.getItem('/liquidacoes_data2');
    if (!dt1) {
      this.data1 = new DateFormatPipe().transform(this.datepipe.transform(new Date(`01/01/${this.login.exercicio.ano}`), 'yyyy-MM-dd'), []);
    } else {
      this.data1 = new DateFormatPipe().transform(dt1, []);
    }
    if (!dt2) {
      const dt2 = new Date();
      dt2.setDate(dt2.getDate() + 10);
      this.data2 = new DateFormatPipe().transform(this.datepipe.transform(dt2, 'yyyy-MM-dd'), []);
    } else {
      this.data2 = new DateFormatPipe().transform(dt2, []);
    }
  }

  protected async afterInit(): Promise<void> {
    this.estoqueService.filtrar(1, -1, { 'orgao.id': this.login.orgao.id }).subscribe(e => {
      e.content.forEach(e => {
        this.listaEstoque.push({ valor: e.id, label: e.nome })
      });
    });

    this.listaEspecies = [
      { id: 'LEO', nome: 'ORÇAMENTÁRIA' },
      { id: 'LRP', nome: 'RESTOS A PAGAR' }
    ];

    this.listaMeses = this.globalService.obterListaMeses();

    this.empenhosInscritos = (await this.empenhoService.filtrar(1, -1, {
      exercicio_id: this.login.exercicio.id,
      orgao_id: this.login.orgao.id,
      inscrito_resto: true
    }).toPromise()).content;
  }

  public anular(item: Liquidacao, item2: any) {
    this.liquidacaoAnular = item;
    this.visualizarAnulacao = true;
    this.valorTotalLiquidado = item2.total_liquidado
  }

  protected acaoRemover(model: Liquidacao): Observable<Liquidacao> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Número', coluna: 'empenho.numero' },
      { titulo: 'Parcela', coluna: 'parcela' },
      { titulo: 'Beneficiario', coluna: 'empenho.favorecido.nome' },
      { titulo: 'Ficha', coluna: 'empenho.ficha.numero' },
      { titulo: 'Historico', coluna: 'historico' },
      { titulo: 'Data', coluna: 'data_liquidacao' },
      { titulo: 'Vencimento', coluna: 'data_vencimento' },
      { titulo: 'Documento', coluna: 'documento' },
      { titulo: 'Despesa', coluna: 'empenho.subelemento.codigo'},
      { titulo: 'Vl. Bruto', coluna: 'valor_liquidado', alignment: 'right', decimais: 2 },
      { titulo: 'Vl. Retido', coluna: 'total_retido', decimais: 2, alignment: 'right' },
      {
        titulo: 'Vl. Líquido', coluna: 'valor_liquido', decimais: 2, alignment: 'right',
        funcao: [{ valor: ['valor_liquidado', '-', 'total_retido'] }]
      },
    ];
  }

  colunasXlsx(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'Número', coluna: 'numero' },
      { titulo: 'Parcela', coluna: 'parcela' },
      { titulo: 'Beneficiáio', coluna: 'beneficiario' },
      { titulo: 'Ficha', coluna: 'ficha' },
      { titulo: 'Histórico', coluna: 'historico' },
      { titulo: 'Data', coluna: 'data' },
      { titulo: 'Vencimento', coluna: 'vencimento' },
      { titulo: 'Documento', coluna: 'documento' },
      { titulo: 'Despesa', coluna: 'despesa'},
      { titulo: 'Vl. Bruto', coluna: 'vl_bruto' },
      { titulo: 'Vl. Retido', coluna: 'vl_retido' },
      { titulo: 'Total Retido', coluna: 'total_retido' }
    ];
    return colunasDefault;
  }

  colunasXml(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'número', coluna: 'numero' },
      { titulo: 'fornecedor', coluna: 'fornecedor' },
      { titulo: 'despesa', coluna: 'despesa' },
      { titulo: 'histórico', coluna: 'historico' },
      { titulo: 'data', coluna: 'data' },
      { titulo: 'vencimento', coluna: 'vencimento' },
      { titulo: 'valor', coluna: 'valor' },
    ];
    return colunasDefault;
  }

  colunasDocx(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'Número', coluna: 'numero' },
      { titulo: 'Data', coluna: 'data' },
      { titulo: 'Parcela', coluna: 'parcela' },
      { titulo: 'Beneficiário', coluna: 'beneficiario' },
      { titulo: 'Nº despesa', coluna: 'despesa' },
      { titulo: 'Descrição despesa', coluna: 'desc_despesa' },
      { titulo: 'Retencao', coluna: 'retencao' },
      { titulo: 'valor', coluna: 'valor' },
    ];
    return colunasDefault;
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    if (parametros['data_vencimento$ge']) {
      parametros['ignoreCondObrig'] = true;
      delete parametros['exercicio.id']
    }
    parametros['relations'] = 'empenho,empenho.favorecido,empenho.ficha,empenho.subelemento,empenho.orgao,empenho.exercicio';
    this.liquidacaoService
      .extendido(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE LIQUIDAÇÕES ORÇAMENTÁRIAS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem liquidações', ['auto', 'auto', 'auto', 'auto', 150, 'auto', 'auto', 100, 'auto', 'auto', 'auto', 'auto'], lista.content, ['valor_liquidado', 'total_retido', 'valor_liquido']);
          } else if (formato === 'xlsx') {
            this.funcaoService.exportar(formato, this.normalizarXlsx(lista.content), 'Listagem liquidações', this.colunasXlsx());
          } else if (formato === 'xml') {
            this.funcaoService.exportar(formato, this.normalizarXml(lista.content), 'Listagem liquidações', this.colunasXml());
          } else if (formato === 'docx') {
            this.funcaoService.exportar(formato, this.normalizarDocx(lista.content), 'Listagem liquidações', this.colunasDocx());
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public normalizarXlsx(dados: any[]) {
    let valor_liquido = 0;
    const listaExportar = []

    for (const item of dados) {
      valor_liquido = item.valor_liquidado - item.total_retido;

      const primeira_linha = {
        numero: item?.empenho?.numero,
        parcela: item?.parcela,
        beneficiario: item?.empenho?.favorecido?.nome,
        ficha: item?.empenho?.ficha?.numero,
        historico: item?.historico,
        data: item?.data_liquidacao,
        vencimento: item?.data_vencimento,
        documento: item?.documento,
        despesa: `${item?.empenho?.subelemento.codigo} - ${item?.empenho?.subelemento.nome}`,
        vl_bruto: this.funcaoService.formatarMoedaPtBr(item?.valor_liquidado),
        vl_retido: this.funcaoService.formatarMoedaPtBr(item?.total_retido),
        total_retido: this.funcaoService.formatarMoedaPtBr(valor_liquido),
      }
      listaExportar.push(primeira_linha)
    }

    return listaExportar
  }

  public normalizarXml(dados: any[]) {
    let valor_liquido = 0;
    const listaExportar = []

    for (const item of dados) {
      valor_liquido = item.valor_liquidado - item.total_retido;

      const primeira_linha = {
        numero: item?.empenho?.numero,
        fornecedor: item?.empenho?.favorecido?.nome,
        despesa: `${item?.empenho?.subelemento?.codigo} ${item?.empenho?.subelemento?.nome}`,
        historico: item?.historico,
        data: this.datepipe.transform(item?.data_liquidacao, 'dd/MM/yyyy', 'GMT'),
        vencimento: this.datepipe.transform(item?.data_vencimento, 'dd/MM/yyyy', 'GMT'),
        valor: this.funcaoService.formatarMoedaPtBrNCasasDecimais(item?.valor_liquidado),
      }
      listaExportar.push(primeira_linha)
    }
    return listaExportar
  }

  public normalizarDocx(dados: any[]) {
    let valor_liquido = 0;
    const listaExportar = []

    for (const item of dados) {
      valor_liquido = item?.valor_liquidado - item?.total_retido;

      const primeira_linha = {
        numero: item?.empenho?.numero,
        data: this.datepipe.transform(item?.data_liquidacao, 'dd/MM/yyyy', 'GMT'),
        parcela: item?.parcela,
        beneficiario: item?.empenho?.favorecido?.nome,
        despesa: `${item?.empenho?.subelemento?.codigo} ${item?.empenho?.subelemento?.nome}`,
        des_despesa: item?.empenho?.ficha?.despesa?.nome,
        retencao: this.funcaoService.formatarMoedaPtBr(item?.total_retido),
        valor: this.funcaoService.formatarMoedaPtBrNCasasDecimais(item?.valor_liquidado),
      }
      listaExportar.push(primeira_linha)
    }
    return listaExportar
  }

  public onChangeLiquidacao(value: any, especie: any) {
    if (especie === 'LRP') {
      this.router.navigate(['liquidacoes-restos-pagar']);
    }
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public acertarRetencoes() {
    this.liquidacaoService.acertarReceitaRetencoes(this.login.exercicio.id, this.login.orgao.id).pipe(takeUntil(this.unsubscribe)).subscribe(idTransacao => {
      this.progressoService.show(idTransacao);
    },
      error => this.acaoErro(error));
  }

  protected acaoErro(error: any) {
    console.log('Erro disparado: ', error);
    toastr.options.timeOut = 10000;
    toastr.options.closeButton = true;
    toastr.options.tapToDismiss = false;
    if (error.error && error.error.payload) {
      toastr.error(error.error.payload);
    } else {
      toastr.error('Ocorreu um erro ao processar a sua solicitação');
    }
  }

  public obterColunasFiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];

    retorno.push({ titulo: 'Data Liquidação', coluna: 'data_liquidacao', tipo: 'Date', alignment: 'center', padrao: true, filtro2: new Date() });
    retorno.push({ titulo: 'Nº Empenho', coluna: 'empenho.numero', tipo: 'Number', padrao: true });
    retorno.push({ titulo: 'Valor liquidado', coluna: 'valor_liquidado', alignment: 'center', tipo: 'Number', cols: 3 });
    retorno.push({ titulo: 'Parcela', coluna: 'parcela', alignment: 'center', tipo: 'Number', cols: 3, padrao: false });
    retorno.push({ titulo: 'Valor retido', coluna: 'total_retido', alignment: 'center', tipo: 'Number' });
    retorno.push({ titulo: 'Ficha', coluna: 'empenho.ficha.numero', tipo: 'String', padrao: false, cols: 3 });
    retorno.push({ titulo: 'Nº da compra', coluna: 'empenho.compra.numero', tipo: 'Number' });
    retorno.push({ titulo: 'Estoque', coluna: 'movimentos_estoque.setorAlmoxarifado.estoque.id', tipo: 'Selection', opcoesSelecao: this.listaEstoque, padrao: true });
    retorno.push({ titulo: 'Convênio', coluna: 'empenho.convenio.numero', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Contrato', coluna: 'empenho.contrato.numero', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Favorecido', coluna: 'empenho.favorecido.nome', alignment: 'center', tipo: 'String', padrao: false });
    retorno.push({ titulo: 'Documento', coluna: 'documento', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Documento Fiscal', coluna: 'documento_fiscal_info.documento_fiscal.numero_documento', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Código da despesa', coluna: 'empenho.subelemento.codigo', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Descrição da despesa', coluna: 'empenho.subelemento.nome', alignment: 'center', tipo: 'String' });
    retorno.push({ titulo: 'Data Vencimento', coluna: 'data_vencimento', tipo: 'Date', alignment: 'center', padrao: false, filtro2: new Date() });

    return retorno;
  }

  public podeIncluirSemADM(login: Login, url: string, visualizar?: boolean) {
    if (login) {
      if (url.match(GlobalService.TRANSPARENCIA_REGEXP)) {
        return true;
      }
      if (
        url.lastIndexOf("/") > 0 &&
        (url.includes("editar") ||
          url.includes("visualizar") ||
          url.includes("novo"))
      ) {
        const idx = url.indexOf(
          "/".concat(
            url.includes("editar")
              ? "editar"
              : url.includes("visualizar")
                ? "visualizar"
                : "novo"
          )
        );
        url = url.substring(0, idx);
        if (url.substring(1, url.length).lastIndexOf("/") > 0) {
          url = url.substring(
            0,
            url.substring(1, url.length).lastIndexOf("/") + 1
          );
        }
      }
      for (const acesso of login.acessos) {
        if (url === acesso.pagina) {
          return acesso.permissao === (visualizar ? 1 : 2);
        }
      }
    }
    return false;
  }

  public escolherMes() {
    $('#dialogEscolherMes').modal('show');
  }

  public salvarLiqConversao() {
    this.liquidacaoService.gerarEmpenhosExtras(this.mes).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        toastr.success(`Liquidação de conversão salva com sucesso!`)
      }, error => this.funcaoService.acaoErro(error));
    this.fecharModal()
  }

  public editarAnulacao(item: Liquidacao) {
    return item.anulacao && this.login.usuario.sistemas_administrador?.split(',').includes('contabil')
  }

  public alterarAnular(item: Liquidacao) {
    this.liquidacaoAtualizar = item;
    this.visualizarAtualizacao = true;
  }

  public fecharModal() {
    $('#dialogEscolherMes').modal('hide');
  }

  public possuiInscricaoDeRestoNoExercicio(item: Liquidacao) {
    if (this.empenhosInscritos.length > 0) {
      toastr.warning('Já foram inscrito os resto a pagar do exercício, não será possível alterar o registro!')
      this.router.navigate([`/liquidacoes-orcamentaria/${item.id}/visualizar`]);
    }
  }

  public ImprimirNotaPorEmpenhosSelecionados(item: Liquidacao) {
    if (item['selecionado'] === true) {
      item['selecionado'] = false;
      // Encontre o índice do item na lista this.empenhosSelecionados
      const index = this.empenhosSelecionados.findIndex(e => e.empenho.id === item.empenho.id);
      if (index > -1) {
        this.empenhosSelecionados.splice(index, 1);
      }
    } else {
      item['selecionado'] = true;
      // Verifique se o item já não está na lista
      if (!this.empenhosSelecionados.some(e => e.empenho.id === item.empenho.id)) {
        this.empenhosSelecionados.push(item);
      }
    }
    this.numerosEmpenhos = [];
    for (const liquidacao of this.empenhosSelecionados) {
      this.numerosEmpenhos.push(liquidacao.empenho.numero)
    }
  }
}
