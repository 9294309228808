import { Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseResourceListComponent, Coluna, CompraItem, Contrato, ContratoItem, Filtro, FuncaoService, GlobalService, Login, LoginContabil } from 'eddydata-lib';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompraItemService } from '../../compra/service/compra-item.service';

import * as toastr from 'toastr';

@Component({
  selector: 'lib-contrato-compra',
  templateUrl: './contrato-compra.component.html'
})
export class ContratoCompraComponent extends BaseResourceListComponent<CompraItem, LoginContabil> implements OnChanges {

  @Input() public login: Login;
  @Input() public contrato: Contrato;
  @Input() public itemFiltro: ContratoItem;
  @Input() public totalPendente: number;
  public listaCompraItem: CompraItem[];

  public unsubscribe: Subject<void> = new Subject();

  public limite = 5;

  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    public funcaoService: FuncaoService,
    private compraItemService: CompraItemService) {
    super(compraItemService, injector);
  }

  public ngOnInit() {
    toastr.options.positionClass = "toast-top-left";
    this.beforeInit();
    if (!this.paginaCorrente) {
      this.paginaCorrente = 1;
    }
    window.scrollTo(0, 0);
    // if (!this.usarFiltroPersonalizado) this.preencherGrid();
    this.afterInit();
  }

  public beforeInit(): void {
    this.usarExtendido = true;
  }

  protected afterInit(): void {
  }

  protected relations(): string {
    return 'compra.rcms.exercicio,compra.requerente';
  }

  protected acaoRemover(model: CompraItem): Observable<CompraItem> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
    ];
  }

  protected condicoesGrid(): {} {
    let produtoUnidade = this?.itemFiltro?.produto_unidade
    const parametros = {
      'compra.contrato_id': this.contrato.id,
    };
    if (produtoUnidade)
      parametros['produto_unidade_id'] = produtoUnidade.id

    return parametros;
  }

  protected filtrosGrid(): Filtro {
    return {
    };
  }

  protected ordenacaoGrid(): string[] {
    let produtoUnidade = this.itemFiltro.produto_unidade
    const order = []
    if (!produtoUnidade)
      order.push('produto_unidade.produto.nome')
    order.push('compra.exercicio.ano$DESC')
    order.push('compra.numero$DESC')

    return order;
  }

  ngOnChanges(changes: SimpleChanges): void {
    let produtoUnidade = this.itemFiltro.produto_unidade
    if (changes && (changes['contrato'] || changes['itemFiltro']) && this.contrato && (this.itemFiltro['total_compra_pendente_qtd'] > 0)) {
      this.preencherGrid();
    }
  }

}