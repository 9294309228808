import { DatePipe } from '@angular/common';
import * as extenso from 'extenso';
import { FuncaoService, Login, Relatorio, GlobalService, OrgaoAssinaturaService, PreLiquidacao } from 'eddydata-lib';
import JsBarcode from 'jsbarcode';

export class NotaPreLiquidacao {

  protected datepipe: DatePipe;
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;

  private log: Login;
  private ordenador_despesa: string;
  private cargo_ordenador_despesa: string;
  private contador: string;
  private cargo_contador: string;
  private tesoureiro: string;
  private cargo_tesoureiro: string;

  constructor(protected assinaturaService: OrgaoAssinaturaService) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.datepipe = new DatePipe('pt');
  }

  public async imprimir(model: PreLiquidacao[], login: Login) {
    this.log = login;

    const assinatura = await this.assinaturaService.obter({
      orgao_id: this.log.orgao.id,
      'data_limite$ge': String(this.funcaoService.converteDataSQL(new Date().toLocaleDateString()))
    }).toPromise();
    if (assinatura) {
      this.ordenador_despesa = assinatura.ordenador_despesa;
      this.cargo_ordenador_despesa = assinatura.cargo_ordenador_despesa;

      this.tesoureiro = assinatura.tesoureiro;
      this.cargo_tesoureiro = assinatura.cargo_tesoureiro;

      this.contador = assinatura.contador;
      this.cargo_contador = assinatura.cargo_contador;
    }

    Relatorio.imprimirPersonalizado('NOTA DE PRÉ-LIQUIDAÇÃO', login.usuario.nome, login.usuario.sobrenome,
      login.orgao.nome, login.brasao,
      this.montarConteudo(model),
      'portrait', 'NOTA PRÉ-LIQUIDAÇÃO',
      {
        linhas: {
          hLineWidth(i, node) {
            return 1;
          },
          vLineWidth(i) {
            return 1;
          },
          hLineColor(i) {
            return 'black';
          },
          paddingLeft(i) {
            return 3;
          },
          paddingRight(i, node) {
            return 3;
          }
        }
      }, true);
  }

  private montarConteudo(lista: PreLiquidacao[]) {

    const conteudo = [];

    for (const entidade of lista) {
      if (conteudo.length > 0) {
        conteudo.push([{ text: '', pageBreak: 'after' }]);
      }
      const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
      const barCode = `018170${this.funcaoService.strZero((+entidade.valor_liquidado).toFixed(2), 12).split('.').join('')}` +
        `${this.log.orgao.codigo.substring(0, 4)}${this.funcaoService.converteDataSQL(new Date(entidade.data_liquidacao)).split('-').join('')}` +
        `${this.funcaoService.strZero(entidade.empenho.numero, 10)}0000090`;
      JsBarcode(canvas, String(barCode), { displayValue: false, height: 70, margin: 0 });
      conteudo.push(this.dadosCabecalho(this.log, entidade)
        .concat(this.dadosEmpenho(entidade))
        .concat(this.dadosClassificacao(entidade))
        .concat(this.dadosFavorecido(entidade))
        .concat(this.dadosHistorico(entidade))
        .concat(this.dadosBanco(entidade))
        .concat(this.dadosAssinatura(this.log))
        .concat(this.dadosRecibo()).concat([{
          image: canvas.toDataURL(),
          fit: [250, 70], margin: [10, 5]
        }]));
    }
    return conteudo;
  }

  private dadosCabecalho(log: Login, dados: PreLiquidacao): {}[] {
    let brasaoImage: {};
    if (log.brasao) {
      brasaoImage = {
        image: log.brasao,
        width: 60,
        alignment: 'center',
        margin: [0, 0, 0, 0],
        border: [true, true, true, false]
      };
    } else {
      brasaoImage = { margin: [0, 10, 0, 0], text: '' };
    }
    const conteudo = [
      [brasaoImage],
      [{ text: log.orgao.nome, bold: true, alignment: 'center', fontSize: 13, border: [true, false, true, false] }],
      [{ text: `${log.orgao.endereco} ${log.cidade?.nome} ${log.cidade?.estado?.nome} CNPJ: ${log.orgao.cnpj}`, alignment: 'center', fontSize: 8, border: [true, false, true, false] }],
      [{
        text: `NOTA DE PRÉ-LIQUIDAÇÃO N˚ ${this.funcaoService.strZero(dados.id, 5)}`,
        bold: true, alignment: 'center', fontSize: 12, border: [true, false, true, false]
      }]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosEmpenho(dados: PreLiquidacao): {}[] {
    const tipoEmpenho = this.globalService.obterListaTiposEmpenhos().find(x => x.id === dados.empenho.tipo_empenho);
    const conteudo = [[{
      text: 'EMPENHO N˚', fontSize: 7, border: [true, true, false, false]
    },
    { text: 'Ficha', fontSize: 7, border: [true, true, false, false] },
    { text: 'Tipo', fontSize: 7, border: [true, true, false, false] },
    { text: 'Data', fontSize: 7, border: [true, true, false, false] },
    {
      text: 'Contrato', fontSize: 7, border: [true, true, false, false],
    },
    {
      text: 'Licitação', fontSize: 7, border: [true, true, false, false],
    },
    {
      text: 'Processo', fontSize: 7, border: [true, true, false, false],
    },
    {
      text: 'Modalidade',
      alignment: 'center', fontSize: 7, bold: true, border: [true, true, true, false],
    }],
    [
      {
        text: this.funcaoService.strZero(dados.empenho.numero, 4)
          + (dados.parcela > 0 ? '/'
            + this.funcaoService.strZero(dados.parcela, 2) : ''),
        alignment: 'center', fontSize: 10, bold: true, border: [true, false, true, true]
      },
      { text: dados.empenho.ficha.numero, alignment: 'center', bold: true, border: [false, false, false, true] },
      {
        text: tipoEmpenho ? tipoEmpenho.nome : '-',
        alignment: 'center', bold: true, border: [true, false, false, true]
      },
      {
        text: this.datepipe.transform(dados.data_liquidacao, 'dd/MM/yyyy'),
        alignment: 'center', bold: false, border: [true, false, false, true]
      },
      {
        text: dados.empenho.contrato ? dados.empenho.contrato.numero : '-',
        alignment: 'center', bold: false, border: [true, false, false, true]
      },
      {
        text: dados.empenho.licitacao ? dados.empenho.licitacao.numero : '-',
        alignment: 'center', bold: false, border: [true, false, false, true]
      },
      {
        text: dados.empenho.processo, alignment: 'center', bold: false, border: [true, false, false, true]
      },
      {
        text: dados.empenho.modalidade ? dados.empenho.modalidade.nome : '-',
        alignment: 'center', bold: false, border: [true, false, true, true]
      },
    ]];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [80, 40, 60, 50, 50, 50, 50, '*'],
        body: conteudo
      }
    }];
  }

  private dadosClassificacao(dados: any): {}[] {
    const saldo = +dados.total_empenhado - +dados.total_liquidado;
    const conteudo = [
      [
        { text: 'UNIDADE', border: [true, false, false, false] },
        {
          text: `${dados.empenho.ficha.executora.unidade.codigo} ${dados.empenho.ficha.executora.unidade.nome} `,
          border: [false, false, true, false]
        },
        { text: 'EMPENHADO', border: [true, false, false, false] },
        {
          text: this.funcaoService.convertToBrNumber(dados.total_empenhado),
          border: [false, false, true, false], alignment: 'right'
        },
      ],
      [
        { text: 'EXECUTORA', border: [true, false, false, false] },
        {
          text: `${dados.empenho.ficha.executora.codigo} ${dados.empenho.ficha.executora.nome} `,
          border: [false, false, true, false]
        },
        { text: 'PRÉ-LIQUIDADO', border: [true, false, false, false] },
        {
          text: this.funcaoService.convertToBrNumber(dados.valor_liquidado),
          border: [false, false, true, false], alignment: 'right'
        },
      ],
      [
        { text: 'NATUREZA', border: [true, false, false, false] },
        { text: `${dados.empenho.ficha.despesa.codigo} ${dados.empenho.ficha.despesa.nome} `, border: [false, false, true, false] },
        { text: 'SALDO', border: [true, false, false, false] },
        {
          text: this.funcaoService.convertToBrNumber(saldo),
          border: [false, false, true, false], alignment: 'right'
        },
      ],
      [
        { text: 'SUBELEMENTO', border: [true, false, false, false] },
        { text: `${dados.empenho.subelemento.codigo} ${dados.empenho.subelemento.nome} `, border: [false, false, true, false] },
        { text: '', border: [true, false, false, false] },
        { text: '', border: [false, false, true, false] },
      ],
      [
        { text: 'FUNCIONAL', border: [true, false, false, false] },
        { text: `${dados.empenho.ficha.funcao.codigo} ${dados.empenho.ficha.funcao.nome} `, border: [false, false, true, false] },
        { text: '', border: [true, false, false, false] },
        { text: '', border: [false, false, true, false] },
      ],
      [
        { text: 'AÇÃO', border: [true, false, false, false] },
        { text: `${dados.empenho.ficha.acao.codigo} ${dados.empenho.ficha.acao.nome} `, border: [false, false, true, false] },
        { text: '', border: [true, false, false, false] },
        { text: '', border: [false, false, true, false] },
      ],
      [
        { text: 'RECURSO', border: [true, false, false, true] },
        {
          text: `${dados.empenho.ficha.aplicacao.codigo} ${dados.empenho.ficha.aplicacao.nome} `,
          border: [false, false, false, true]
        },
        { text: '', border: [true, false, false, true] },
        { text: '', border: [false, false, true, true] },
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [60, '*', 60, 100],
        body: conteudo
      }
    }];
  }

  private dadosFavorecido(dados: PreLiquidacao): {}[] {
    
    const conteudo = [
      [
        { text: 'DEVERÁ SER PAGO À:', colSpan: 3, border: [true, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] },
        { text: '', border: [false, false, true, false] }
      ],
      [
        {
          text: `${dados.empenho.favorecido.nome}`, colSpan: 3,
          fontSize: 11, bold: true, border: [true, false, false, false]
        },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] },
        { text: `CÓDIGO: ${dados.empenho.favorecido?.id}`, bold: true, border: [false, false, true, false] },
      ],
      [
        { text: 'CPF/CNPJ', border: [true, false, false, false] },
        {
          text: `${dados.empenho.favorecido.cpf_cnpj}`, border: [false, false, false, false]
        },
        { text: 'TIPO PESSOA', border: [false, false, false, false] },
        { text: dados.empenho.favorecido?.tipo?.nome, border: [false, false, true, false] },
      ],
      [
        { text: 'ENDEREÇO', border: [true, false, false, false] },
        {
          text: `${dados.empenho.favorecido.endereco}, ${dados.empenho.favorecido.num_endereco}`,
          border: [false, false, false, false]
        },
        { text: 'BAIRRO:', border: [false, false, false, false] },
        { text: dados.empenho.favorecido.bairro, border: [false, false, true, false] },
      ],
      [
        { text: 'CIDADE', border: [true, false, false, true] },
        { text: `${dados.empenho.favorecido.municipio}, ${dados.empenho.favorecido.uf} `, border: [false, false, false, true] },
        { text: 'TELEFONE', border: [false, false, false, true] },
        { text: `(${dados.empenho.favorecido.ddd_fone ? dados.empenho.favorecido.ddd_fone : ''}) ${dados.empenho.favorecido.telefone ? dados.empenho.favorecido.telefone : ''}`, border: [false, false, true, true] },
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: [60, '*', 60, 100],
        body: conteudo
      }
    }];
  }

  private dadosHistorico(dados: PreLiquidacao): {}[] {
    const conteudo = [
      [{ text: 'PROVENIENTE DE:', border: [true, false, true, false] }],
      [{
        text: `${dados.historico}`, border: [true, false, true, true]
      }]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [15, 70],
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosBanco(dados: any): {}[] {
    const conteudo = [
      [
        {
          text: '', bold: true, border: [true, false, false, false]
        },
        { text: '', border: [false, false, false, false] },        
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] },
      ],
      [
        { text: 'VENCIMENTO:', border: [true, false, false, false] },
        {
          text: this.datepipe.transform(dados.data_vencimento, 'dd/MM/yyyy'), border: [false, false, false, false]
        },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] }
      ],
      [
        { text: 'DOCUMENTO:', border: [true, false, false, true], margin: [0, 0, 0, 10] },
        {
          text: dados.documento, border: [false, false, false, true], margin: [0, 0, 0, 10]
        },
        { text: '', border: [false, false, false, true] },
        {
          text: '', border: [false, false, true, true]
        },
      ],
      [
        { text: 'Fica liquidado a importância de: ', margin: [0, 10, 0, 0], colSpan: 3, border: [true, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] },
      ],
      [
        {
          text: extenso(this.funcaoService.convertToBrNumber(dados.valor_liquidado),
            { mode: 'currency', currency: { type: 'BRL' } }).toUpperCase(),
          colSpan: 3, border: [true, false, false, false], margin: [0, 0, 0, 10],
        },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] },
      ],
      [
        { text: '', border: [true, false, false, true] },
        { text: '', border: [false, false, false, true] },
        { text: '', border: [false, false, false, true] },
        { text: '', border: [false, false, true, true] },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: [60, '*', 60, 100],
        body: conteudo
      }
    }];
  }

  private dadosAssinatura(log: Login): {}[] {
    const conteudo = [
      [
        { text: 'AUTORIZAÇÃO PAGAMENTO', border: [true, false, false, false] },
        { text: '', border: [false, false, false, false], margin: [0, 20, 0, 10] },
        { text: '', border: [false, false, true, false] },
      ],
      [
        { text: '______________________________________', border: [true, false, false, false], margin: [10, 15, 0, 0] },
        { text: '______________________________________', border: [false, false, false, false], margin: [10, 15, 0, 0] },
        { text: '', border: [false, false, true, false] },
      ],
      [
        { text: this.tesoureiro ? this.tesoureiro : '', fontSize: 7, border: [true, false, false, false], margin: [10, 0, 0, 0] },
        { text: this.ordenador_despesa ? this.ordenador_despesa : '', fontSize: 7, border: [false, false, false, false], margin: [10, 0, 0, 0] },
        { text: '', border: [false, false, true, false] },
      ],
      [
        { text: this.cargo_tesoureiro ? this.cargo_tesoureiro : '', border: [true, false, false, false], margin: [10, 0, 0, 0] },
        { text: this.cargo_ordenador_despesa ? this.cargo_ordenador_despesa : '', fontSize: 7, border: [false, false, false, false], margin: [10, 0, 0, 0] },
        { text: '', border: [false, false, true, false] }
      ],
      [
        { text: '', border: [true, false, false, true], margin: [10, 0, 0, 50] },
        { text: '', fontSize: 7, border: [false, false, false, true], margin: [10, 0, 0, 50] },
        { text: '', border: [false, false, true, true] },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [10, 0],
        widths: [160, 160, '*'],
        body: conteudo
      }
    }];
  }

  private dadosRecibo(): {}[] {
    const conteudo = [
      [
        { text: 'RECIBO E QUITAÇÃO', border: [true, false, false, false], colSpan: 2 },
        { text: '', border: [false, false, false, false], },
        { text: '', border: [false, false, false, false], },
        { text: '', border: [false, false, true, false], },
      ],
      [
        {
          text: 'Recebemos o valor a que se refere este empenho, ao qual damos plena e geral quitação.',
          border: [true, false, false, false], colSpan: 2, margin: [0, 0, 20, 0]
        },
        { text: '', border: [false, false, false, false], },
        { text: 'Assinatura', border: [false, false, false, false], },
        { text: 'Documento', border: [false, false, true, false], },
      ],
      [
        { text: 'Data: _____/_____/________', border: [true, false, false, true], colSpan: 2 },
        { text: '', border: [false, false, false, true], },
        { text: '__________________________', border: [false, false, false, true], },
        { text: '__________________________', border: [false, false, true, true], },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [10, 0],
        widths: ['*', '*', '*', '*'],
        body: conteudo
      }
    }];
  }

}
