import { Component, Input } from '@angular/core';
import { Audesp4ComissaoDesignacao, Audesp4ComissaoLicitacao } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-comissao-designacao',
  templateUrl: './licitacao-audesp-comissao-designacao.component.html'
})
export class LicitacaoAudespComissaoDesignacaoComponent extends BaseLicitacaoAudesp {

  @Input() public comissao: Audesp4ComissaoLicitacao;

  public itemAtual: Audesp4ComissaoDesignacao;
  public itemAnterior: Audesp4ComissaoDesignacao;

  constructor() {
    super()
  }

  public adicionar() {
    if (!this.comissao.designacao || this.comissao.designacao.length === 0) this.comissao.designacao = [];

    this.itemAtual = {
      NumAtoDesignacao: undefined, AnoAtoDesignacao: undefined, editavel: true
    }
    this.comissao.designacao.push(this.itemAtual);
  }

  public visualizar(item: Audesp4ComissaoDesignacao) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      let itm = this.comissao.designacao.find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.comissao.designacao.splice(this.comissao.designacao.indexOf(this.itemAtual), 1, itm)
      this.itemAtual.editavel = false;
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4ComissaoDesignacao) {
    this.itemAtual = undefined;
    this.comissao.designacao.splice(this.comissao.designacao.indexOf(item), 1)
  }

  public salvar() {
    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }

}
