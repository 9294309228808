import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';

export class LegislacaoOrcamento extends BaseResourceModel {
  constructor(
    public descricao?: string,
    public legislacao?: string,
    public orgao?: Orgao,
    public exercicio?: Exercicio,
  ) {
    super();
  }

  static converteJson(json: any): LegislacaoOrcamento {
    return Object.assign(new LegislacaoOrcamento(), json);
  }
}