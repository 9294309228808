import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsuarioListComponent } from './usuario-list/usuario-list.component';
import { UsuarioFormComponent } from './usuario-form/usuario-form.component';
import { UsuarioViewComponent } from './usuario-view/usuario-view.component';
import { AuthGuard } from '../util/auth.guard';

const routes: Routes = [
  { path: '', component: UsuarioListComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: UsuarioFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: UsuarioViewComponent, canActivate: [AuthGuard] },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsuarioRoutingModule { }
