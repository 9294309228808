import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Favorecido } from '../../entidade/compra/favorecido.model';
import { FavorecidoService } from '../service/favorecido.service';

declare var $: any;

@Component({
  selector: 'lib-simples-nacional-dlg',
  templateUrl: './simples-nacional-dlg.component.html'
})
export class SimplesNacionalDlgComponent implements OnInit {

  @Input() favorecido: Favorecido;
  @Output() callback: EventEmitter<boolean> = new EventEmitter();

  retorno: string = '';
  dataBloqueio: Date = null;
  verificadoManual: boolean = false;

  constructor(
    protected confirmationService: ConfirmationService,
    protected service: FavorecidoService
  ) { }

  ngOnInit(): void {
    if (!this.favorecido) {
      return;
    }
    this.favorecido.simples_nacional == null;
    this.retorno = sessionStorage.getItem('bloqueio_simples_api');
    if (this.retorno) {
      if (this.retorno.indexOf('Liberação ocorrerá em ') < 0) {
        return;
      }
      let dt: string = this.retorno.substring(this.retorno.indexOf('Liberação ocorrerá em ') + 22);
      this.retorno = this.retorno.substring(0, this.retorno.indexOf('Liberação ocorrerá em ') + 22);
      if (dt.indexOf('(Horário Padrão de Brasília)') > -1) {
        dt = dt.substring(0, dt.indexOf(' (Horário Padrão de Brasília)'));
      } else {
        dt = dt.substring(0, dt.indexOf(' (Brasilia Standard Time)'));
      }
      this.dataBloqueio = new Date(dt);
      this.retorno += this.dataBloqueio.getHours() + ':' + this.dataBloqueio.getUTCMinutes() + ' de ' + this.dataBloqueio.toLocaleDateString();
      if (new Date() > this.dataBloqueio) {
        this.dataBloqueio = null;
        this.retorno = null;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  verificarSimplesOnline() {
    this.service.consultaSimplesNacional(this.favorecido.cpf_cnpj).subscribe((res) => {
      if (!res.simples) {
        this.retorno = 'CNPJ SEM INFORMAÇÕES de Simples Nacional informada nas bases Online. Por favor, verifique manualmente.'
      } else {
        this.retorno = 'CNPJ atualizado com a receita em ' + new Date(res.simples.atualizado_em).toLocaleDateString();
        this.retorno += ', e até essa data o CNPJ ' + (res.simples.simples == 'Sim' ? 'É OPTANTE' : 'NÃO É OPTANTE') + ' pelo Simples Nacional';
        this.favorecido.simples_nacional = res.simples.simples == 'Sim';
      }
    }, (e) => {
      sessionStorage.setItem('bloqueio_simples_api', e.error.detalhes);
      this.ngOnInit();
    });
  }

  verificarManual(inputElement) {
    this.verificadoManual = true;
    inputElement.select();
    document.execCommand('copy');
    window.open('https://consopt.www8.receita.fazenda.gov.br/consultaoptantes', '_blank');
  }

  resultado(simples: boolean) {
    this.callback.emit(simples);
  }

}
