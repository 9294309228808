import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { ContaBancaria } from "./conta-bancaria.model";

export class ConciliacaoBancaria extends BaseResourceModel {
  constructor(
    public id?: number,
    public mes?: number,
    public data_conciliacao?: Date,
    public data_regularizacao?: Date,
    public tipo?: 'ValoresCredContabNaoBanco' | 'ValoresDebContabNaoBanco' | 'ValoresCredBancoNaoContab' | 'ValoresDebBancoNaoContab' | 'BloqueioOrdemJudicial',
    public valor_conciliacao?: number,
    public processo?: string,
    public historico?: string,
    public observacao?: string,
    public conta?: ContaBancaria,
    public exercicio?: Exercicio,
    public orgao?: Orgao,) {
    super();
  }
  static converteJson(json: any): ConciliacaoBancaria {
    return Object.assign(new ConciliacaoBancaria(), json);
  }
}