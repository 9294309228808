import { DatePipe } from '@angular/common';
import { MessageService } from 'primeng/api';
import { Adiantamento, FuncaoService, Login, Relatorio } from 'eddydata-lib';
import { PagamentoService } from 'administrativo-lib';
import { Injector } from '@angular/core';

export class EtiquetaAdiantamento {
  protected funcaoService: FuncaoService;
  protected datepipe: DatePipe = new DatePipe('pt');

  public vlAnulado: number;
  public vlSeguinte: number;
  public entidade: Adiantamento;
  public saldo: number;

  constructor(protected injector: Injector) {
    this.funcaoService = new FuncaoService();
  }

  public async imprimir(lista: Adiantamento[], login: Login, messageService: MessageService) {
    if (!lista) {
      messageService.add({ severity: 'warn', summary: 'Validação', detail: 'Antes de imprimir salve o registro!' });
      return;
    }
    const retorno = [];
    let brasaoImage: {};
    if (login.brasao) {
      brasaoImage = {
        image: login.brasao,
        width: 38,
        alignment: 'left',
        margin: [5, 3, -200, 0],
        border: [false, false, false, false]
      };
    } else {
      brasaoImage = { margin: [0, 0, 0, 0], text: '' };
    }

    const pagtoService = new PagamentoService(this.injector);

    for (let index = 0; index <= lista.length - 1; index += 2) {
      const entidade = lista[index];
      const entidadeSeguinte = lista[index + 1];

      this.vlAnulado = await pagtoService.obterTotalPagoAnuladoPorEmpenho(entidade.empenho.numero, entidade.exercicio.id, entidade.orgao.id).toPromise();    

      this.saldo = +entidade.empenho.valor_empenho - +this.vlAnulado;

      const conteudo: {}[] = [];
      if (entidadeSeguinte) {

        const margin = 35//entidade.motivo.length > 40 ? 30 : 40;
        
        this.vlSeguinte = await pagtoService.obterTotalPagoAnuladoPorEmpenho(entidadeSeguinte.empenho.numero, entidadeSeguinte.exercicio.id, entidadeSeguinte.orgao.id).toPromise();
        
        entidade.motivo = entidade.motivo.length > 130? entidade.motivo.substring(0,130) + '...' : this.funcaoService.strChar(entidade.motivo,130, ' ');
        entidadeSeguinte.motivo = entidadeSeguinte.motivo.length > 130? entidadeSeguinte.motivo.substring(0,130) + '...' : this.funcaoService.strChar(entidadeSeguinte.motivo,130, ' ');
        const saldoSeguinte = +entidadeSeguinte.empenho.valor_empenho - +this.vlSeguinte;
        conteudo.push([
          [
            {
              table: {
                body: [
                  [
                    {
                      text: 'Processo: ' + ('00000' + entidade.numero).slice(-10),
                      fontSize: 14, bold: true, margin: [0, 10, 0, 0], border: [false, false, false, false]
                    },
                    {text: '', border: [false,false,false,false]},
                  ], [
                    {
                      text: 'Responsável: ' + (entidade.empenho?.favorecido?.nome),
                      bold: true, border: [false, false, false, false], colSpan: 2
                    },
                    '',
                  ], [
                    {
                      text: 'Assunto: ' + entidade.motivo, bold: true,
                      border: [false, false, false, false], colSpan: 2
                    },
                    '',
                  ], [
                    {
                      text: 'Empenho Nº: ' + (entidade.empenho?.numero > 1000 ? '0' + entidade.empenho?.numero : '00' + entidade.empenho?.numero),
                      bold: true, width: 235, alignment: 'justify',
                      border: [false, false, false, false], colSpan: 2
                    },
                    '',
                  ], [
                    {
                      text: 'Data Início: ' + this.datepipe.transform(entidade.data_adiantamento, 'dd/MM/yyyy'),
                      bold: true, border: [false, false, false, false], colSpan: 2
                    },
                    '',
                  ], [
                    {
                      text: 'Valor: ' + this.funcaoService.convertToBrNumber(this.saldo, 2, false),
                      bold: true, border: [false, false, false, false], colSpan: 2
                    },
                    '',
                  ],
                ]
              }
            }
          ], [
            {
              table: {
                body: [
                  [
                    {
                      text: '', border: [false,false,false,false]
                    }
                  ]
                ]
              }
            }
          ], [
            {
              table: {
                body: [
                  [
                    {
                      text: 'Processo: ' + ('00000' + entidadeSeguinte.numero).slice(-10),
                      fontSize: 14, bold: true, margin: [0, 10, 0, 0], border: [false, false, false, false]
                    },
                    {text: '', border: [false,false,false,false]},
                  ], [
                    {
                      text: 'Responsável: ' + (entidadeSeguinte.empenho?.favorecido?.nome),
                      bold: true, border: [false, false, false, false], colSpan: 2
                    },
                    '',
                  ], [
                    {
                      text: 'Assunto: ' +  entidadeSeguinte.motivo, bold: true,
                      border: [false, false, false, false], colSpan: 2
                    },
                    '',
                  ], [
                    {
                      text: 'Empenho Nº: ' + (entidadeSeguinte.empenho?.numero > 1000 ? '0' + entidadeSeguinte.empenho?.numero : '00' + entidadeSeguinte.empenho?.numero),
                      bold: true, width: 235, alignment: 'justify',
                      border: [false, false, false, false], colSpan: 2
                    },
                    '',
                  ], [
                    {
                      text: 'Data Início: ' + this.datepipe.transform(entidadeSeguinte.data_adiantamento, 'dd/MM/yyyy'),
                      bold: true, border: [false, false, false, false], colSpan: 2
                    },
                    '',
                  ], [
                    {
                      text: 'Valor: ' + this.funcaoService.convertToBrNumber(saldoSeguinte, 2, false),
                      bold: true, border: [false, false, false, false], margin: [0, 0, 0, margin], colSpan: 2
                    },
                    '',
                  ],
                ]
              }
            }
          ],
        ]);
      } else {
        conteudo.push([
          [
            {
              table: {
                body: [
                  [
                    {
                      text: 'Processo: ' + ('00000' + entidade.numero).slice(-10),
                      fontSize: 14, bold: true, margin: [0, 10, 0, 0], border: [false, false, false, false]
                    },
                    {text: '', border: [false,false,false,false]},
                  ], [
                    {
                      text: 'Responsável: ' + (entidade.empenho?.favorecido?.nome),
                      bold: true, border: [false, false, false, false], colSpan: 2
                    },
                    ''
                  ], [
                    {
                      text: 'Assunto: ' + entidade.motivo, bold: true,
                      border: [false, false, false, false], colSpan: 2
                    },
                    '',
                  ], [
                    {
                      text: 'Empenho Nº: ' + (entidade.empenho?.numero > 1000 ? '0' + entidade.empenho?.numero : '00' + entidade.empenho?.numero),
                      bold: true, width: 235, alignment: 'justify',
                      border: [false, false, false, false], colSpan: 2
                    },
                    '',
                  ], [
                    {
                      text: 'Data Início: ' + this.datepipe.transform(entidade.data_adiantamento, 'dd/MM/yyyy'),
                      bold: true, border: [false, false, false, false], colSpan: 2
                    },
                    ''
                  ], [
                    {
                      text: 'Valor: ' + this.funcaoService.convertToBrNumber(this.saldo, 2, false),
                      bold: true, border: [false, false, false, false], colSpan: 2
                    },
                    '',
                  ],
                ]
              }
            }
          ]
        ]);
      }

      retorno.push({
        layout: 'noBorders',
        table: {
          widths: ['*', 10, '*'],
          body: conteudo,
        }
      });
    }

    Relatorio.imprimirPersonalizado('ETIQUETA', login.usuario.nome, login.usuario.sobrenome, login.orgao.nome,
      login.brasao, retorno, 'portrait', 'Etiquetas Adiantamento ', null, true, true, null, null, [35, 30, 35, 35]);
  }

}