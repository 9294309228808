import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ProdutoDlgModule } from 'almoxarifado-lib';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { FieldsetModule } from 'primeng/fieldset';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { MaterialModule } from '../material/material.module';
import { ProdutoLocalizacaoModule } from '../produto-localizacao/produto-localizacao.module';
import { EtiquetaRptComponent } from './etiqueta-rpt/etiqueta-rpt.component';
import { ProdutoFavorecidoComponent } from './produto-favorecido/produto-favorecido.component';
import { ProdutoFormComponent } from './produto-form/produto-form.component';
import { ProdutoLicitacaoComponent } from './produto-licitacao/produto-licitacao.component';
import { ProdutoListComponent } from './produto-list/produto-list.component';
import { ProdutoMedicamentoComponent } from './produto-medicamento/produto-medicamento.component';
import { ProdutoReferenciaComponent } from './produto-referencia/produto-referencia.component';
import { ProdutoRoutingModule } from './produto-routing.module';
import { ProdutoSaldoEstoqueComponent } from './produto-saldo-estoque/produto-saldo-estoque.component';
import { ProdutoSaldoSetorComponent } from './produto-saldo-setor/produto-saldo-setor.component';
import { ProdutoUnidadeComponent } from './produto-unidade/produto-unidade.component';
import { ProdutoViewComponent } from './produto-view/produto-view.component';

@NgModule({
  declarations: [ProdutoListComponent, ProdutoFormComponent, ProdutoUnidadeComponent, ProdutoSaldoSetorComponent,
    ProdutoViewComponent, ProdutoReferenciaComponent, ProdutoSaldoEstoqueComponent, ProdutoFavorecidoComponent, ProdutoMedicamentoComponent,
    ProdutoLicitacaoComponent, EtiquetaRptComponent
  ],
  exports: [ProdutoUnidadeComponent, ProdutoFavorecidoComponent, EtiquetaRptComponent],
  imports: [
    CommonModule,
    DialogModule,
    ProdutoRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    ProdutoLocalizacaoModule,
    ProdutoDlgModule,
    MaterialModule,
    ConfirmDialogModule,
    FieldsetModule,
    InputTextModule
  ],
  providers: [MessageService, ConfirmationService]
})
export class ProdutoModule { }
