import { Comunicado } from "eddydata-lib";
import { BaseResourceModel } from "../../models/base-resource.model";
import { Usuario } from "../comum/usuario.model";

export class ComunicadoUsuario extends BaseResourceModel {
  constructor (
    public id?: number,
    public data_alteracao?: Date,
    public data_cadastro?: Date,
    public comunicado?: Comunicado,
    public lido?: boolean,
    public notificar?: boolean,
    public enviar_email?: boolean,
    public usuario?: Usuario
  ) {
    super();
  }
  static converteJson(json: any): ComunicadoUsuario {
    return Object.assign(new ComunicadoUsuario(), json);
  }
}