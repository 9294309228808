import { BaseResourceModel } from '../../models/base-resource.model';

export class FavorecidoTipo extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public tce?: string,
    public obrigadorio?: boolean
  ) {
    super();
  }
  static converteJson(json: any): FavorecidoTipo {
    return Object.assign(new FavorecidoTipo(), json);
  }
}
