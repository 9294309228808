import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { ReceitaPrevistaLstComponent } from './receita-prevista-lst/receita-prevista-lst.component';

const routes: Routes = [
  {
    path: ':ano',
    component: ReceitaPrevistaLstComponent, canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReceitaPrevistaRoutingModule { }

