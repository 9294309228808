import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { PessoaModule, SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { FieldsetModule } from 'primeng/fieldset';
import { ToastModule } from 'primeng/toast';
import { RelatorioEntradasCompraRptComponent } from './relatorio-entradas-compra-rpt.component';
import { RelatorioEntradasCompraRpt } from './relatorio-entradas-compra-rpt';


@NgModule({
  declarations: [RelatorioEntradasCompraRptComponent],
  exports: [RelatorioEntradasCompraRptComponent,RelatorioEntradasCompraRpt],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    IMaskModule,
    ToastModule,
    FieldsetModule,
    PessoaModule,
    CalendarModule,
  ],
  providers: []
})
export class RelatorioEntradasCompraRptComponentModule { }
