import { FuncaoService, GlobalService, Login, Relatorio } from "eddydata-lib";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EmpenhoService } from "administrativo-lib";
import { tsXLXS } from "ts-xlsx-export";
import * as toastr from 'toastr';

export class EmpenhosSubElemento {
  protected unsubscribe: Subject<void> = new Subject();
  protected login: Login = new Login();
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;

  constructor(
    protected empenhoService: EmpenhoService,
  ) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  public async montarRelatorio(formato, parametros) {
    this.empenhoService.extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((lista) => {
        if (lista.content.length == 0) {
          toastr.info('Sem itens para imprimir');
          return;
        }

        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado('EMPENHOS ORÇAMENTÁRIOS POR SUB-ELEMENTO', this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao, this.montarConteudo(lista.content, parametros),
            'landscape', 'EMPENHOS ORÇAMENTÁRIOS POR SUB-ELEMENTO',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, false);
        } else {
          this.montarExcel(lista.content, parametros)
        }
      });
  }

  private montarConteudo(dados, parametros): {}[] {
    const conteudo = [];

    conteudo.push([
      {
        text: parametros['mes'] ? `Periodo: ${this.globalService.obterMes(+parametros['mes'])}/${this.login.exercicio.ano}`
          : `Periodo: ${parametros['data_empenho$ge']} à ${parametros['data_empenho$le']}`,
        bold: true, colSpan: 11, fontSize: 11, border: [false, false, false, false], margin: [0, -15, 0, 0]
      }, '', '', '', '', '', '', '', '', '', ''
    ]);

    conteudo.push([
      { text: 'DATA', alignment: 'center', bold: true, fontSize: 10, rowSpan: 2 },
      { text: 'EMPENHO', alignment: 'center', bold: true, fontSize: 10, rowSpan: 2 },
      { text: 'FORNECEDOR', alignment: 'center', bold: true, fontSize: 10, rowSpan: 2 },
      { text: 'DOCUMENTO', alignment: 'center', bold: true, fontSize: 10, rowSpan: 2 },
      { text: 'UNIDADE', alignment: 'center', bold: true, fontSize: 10, rowSpan: 2 },
      { text: 'FICHA', alignment: 'center', bold: true, fontSize: 10, rowSpan: 2 },
      { text: 'DESPESA', alignment: 'center', bold: true, fontSize: 10, rowSpan: 2 },
      { text: 'EMPENHADO', alignment: 'center', bold: true, fontSize: 10, rowSpan: 2 },
      { text: 'LIQUIDADO', alignment: 'center', bold: true, fontSize: 10, rowSpan: 2 },
      { text: 'PAGO', alignment: 'center', bold: true, fontSize: 10, rowSpan: 2 },
      { text: 'DÍVIDA', alignment: 'center', bold: true, fontSize: 10, rowSpan: 2 },
    ]);

    conteudo.push([
      { text: '' },
      { text: '', alignment: 'center', bold: true, fontSize: 7 },
      { text: '', alignment: 'center', bold: true, fontSize: 7 },
      { text: '', alignment: 'center', bold: true, fontSize: 7 },
      { text: '', alignment: 'center', bold: true, fontSize: 7 },
      { text: '', alignment: 'center', bold: true, fontSize: 7 },
      { text: '', alignment: 'center', bold: true, fontSize: 7 },
      { text: '', alignment: 'center', bold: true, fontSize: 7 },
      { text: '', alignment: 'center', bold: true, fontSize: 7 },
      { text: '', alignment: 'center', bold: true, fontSize: 7 },
      { text: '', alignment: 'center', bold: true, fontSize: 7 },
    ]);

    let totalEmpenhado: number = 0;
    let totalLiquidado: number = 0;
    let totalPago: number = 0;

    if (parametros['orderBy'] == 'subelemento.codigo,favorecido.nome') {
      const dadosAgrupados = this.funcaoService.agrupar(dados, ['subelemento.codigo', 'favorecido.nome'], ['total_empenhado', 'total_liquidado', 'total_pago'])

      for (const da of dadosAgrupados) {
        conteudo.push([
          { text: `${da.registros[0]?.subelemento?.codigo} - ${da?.registros[0]?.subelemento?.nome}   ${da.registros[0]?.favorecido?.nome}`, alignment: 'left', bold: true, fontSize: 9, colSpan: 11 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
        ]);

        for (const d of da.registros) {
          conteudo.push([
            { text: this.funcaoService.converteDataBR(d.data_cadastro), alignment: 'center', bold: true, fontSize: 7 },
            { text: d.numero, alignment: 'center', bold: true, fontSize: 7 },
            { text: d?.favorecido?.nome, alignment: 'center', bold: true, fontSize: 7 },
            { text: d?.documento, alignment: 'center', bold: true, fontSize: 7 },
            { text: this.funcaoService.mascarar('##.##.##', d?.ficha?.executora?.codigo), alignment: 'center', bold: true, fontSize: 7 },
            { text: d?.ficha?.numero, alignment: 'center', bold: true, fontSize: 7 },
            { text: d?.subelemento?.codigo, alignment: 'center', bold: true, fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(d.total_empenhado, 2), alignment: 'right', bold: true, fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(d.total_liquidado, 2), alignment: 'right', bold: true, fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(d.total_pago, 2), alignment: 'right', bold: true, fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(+d.total_liquidado - +d.total_pago, 2), alignment: 'right', bold: true, fontSize: 7 },
          ]);
        }

        conteudo.push([
          { text: 'TOTAL DO SUBELEMENTO', alignment: 'left', bold: true, fontSize: 9, colSpan: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(da.totalizadores['total_empenhado'], 2), alignment: 'right', bold: true, fontSize: 9 },
          { text: this.funcaoService.convertToBrNumber(da.totalizadores['total_liquidado'], 2), alignment: 'right', bold: true, fontSize: 9 },
          { text: this.funcaoService.convertToBrNumber(da.totalizadores['total_pago'], 2), alignment: 'right', bold: true, fontSize: 9 },
          { text: this.funcaoService.convertToBrNumber(+da.totalizadores['total_liquidado'] - +da.totalizadores['total_pago'], 2), alignment: 'right', bold: true, fontSize: 9 },
        ]);

        totalEmpenhado += +da.totalizadores['total_empenhado'];
        totalLiquidado += +da.totalizadores['total_liquidado'];
        totalPago += +da.totalizadores['total_pago']
      }

      conteudo.push([
        { text: 'TOTAL DO GERAL', alignment: 'left', bold: true, fontSize: 9, colSpan: 7 },
        { text: '', alignment: 'center', bold: true, fontSize: 7 },
        { text: '', alignment: 'center', bold: true, fontSize: 7 },
        { text: '', alignment: 'center', bold: true, fontSize: 7 },
        { text: '', alignment: 'center', bold: true, fontSize: 7 },
        { text: '', alignment: 'center', bold: true, fontSize: 7 },
        { text: '', alignment: 'center', bold: true, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(totalEmpenhado, 2), alignment: 'right', bold: true, fontSize: 9 },
        { text: this.funcaoService.convertToBrNumber(totalLiquidado, 2), alignment: 'right', bold: true, fontSize: 9 },
        { text: this.funcaoService.convertToBrNumber(totalPago, 2), alignment: 'right', bold: true, fontSize: 9 },
        { text: this.funcaoService.convertToBrNumber(+totalLiquidado - +totalPago, 2), alignment: 'right', bold: true, fontSize: 9 },
      ]);

    } else if (parametros['orderBy'] == 'subelemento.codigo') {
      const dadosAgrupados = this.funcaoService.agrupar(dados, ['subelemento.codigo'], ['total_empenhado', 'total_liquidado', 'total_pago'])

      for (const da of dadosAgrupados) {
        conteudo.push([
          { text: `${da.registros[0]?.subelemento?.codigo} - ${da?.registros[0]?.subelemento?.nome}`, alignment: 'left', bold: true, fontSize: 9, colSpan: 11 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
        ]);

        for (const d of da.registros) {
          conteudo.push([
            { text: this.funcaoService.converteDataBR(d.data_cadastro), alignment: 'center', bold: true, fontSize: 7 },
            { text: d.numero, alignment: 'center', bold: true, fontSize: 7 },
            { text: d?.favorecido?.nome, alignment: 'center', bold: true, fontSize: 7 },
            { text: d?.documento, alignment: 'center', bold: true, fontSize: 7 },
            { text: this.funcaoService.mascarar('##.##.##', d?.ficha?.executora?.codigo), alignment: 'center', bold: true, fontSize: 7 },
            { text: d?.ficha?.numero, alignment: 'center', bold: true, fontSize: 7 },
            { text: d?.subelemento?.codigo, alignment: 'center', bold: true, fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(d.total_empenhado, 2), alignment: 'right', bold: true, fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(d.total_liquidado, 2), alignment: 'right', bold: true, fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(d.total_pago, 2), alignment: 'right', bold: true, fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(+d.total_liquidado - +d.total_pago, 2), alignment: 'right', bold: true, fontSize: 7 },
          ]);
        }

        conteudo.push([
          { text: 'TOTAL DO SUBELEMENTO', alignment: 'left', bold: true, fontSize: 9, colSpan: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: '', alignment: 'center', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(da.totalizadores['total_empenhado'], 2), alignment: 'right', bold: true, fontSize: 9 },
          { text: this.funcaoService.convertToBrNumber(da.totalizadores['total_liquidado'], 2), alignment: 'right', bold: true, fontSize: 9 },
          { text: this.funcaoService.convertToBrNumber(da.totalizadores['total_pago'], 2), alignment: 'right', bold: true, fontSize: 9 },
          { text: this.funcaoService.convertToBrNumber(+da.totalizadores['total_liquidado'] - +da.totalizadores['total_pago'], 2), alignment: 'right', bold: true, fontSize: 9 },
        ]);

        totalEmpenhado += +da.totalizadores['total_empenhado'];
        totalLiquidado += +da.totalizadores['total_liquidado'];
        totalPago += +da.totalizadores['total_pago']
      }

      conteudo.push([
        { text: 'TOTAL DO GERAL', alignment: 'left', bold: true, fontSize: 9, colSpan: 7 },
        { text: '', alignment: 'center', bold: true, fontSize: 7 },
        { text: '', alignment: 'center', bold: true, fontSize: 7 },
        { text: '', alignment: 'center', bold: true, fontSize: 7 },
        { text: '', alignment: 'center', bold: true, fontSize: 7 },
        { text: '', alignment: 'center', bold: true, fontSize: 7 },
        { text: '', alignment: 'center', bold: true, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(totalEmpenhado, 2), alignment: 'right', bold: true, fontSize: 9 },
        { text: this.funcaoService.convertToBrNumber(totalLiquidado, 2), alignment: 'right', bold: true, fontSize: 9 },
        { text: this.funcaoService.convertToBrNumber(totalPago, 2), alignment: 'right', bold: true, fontSize: 9 },
        { text: this.funcaoService.convertToBrNumber(+totalLiquidado - +totalPago, 2), alignment: 'right', bold: true, fontSize: 9 },
      ]);
    } else {
      for (const d of dados) {
        conteudo.push([
          { text: this.funcaoService.converteDataBR(d.data_cadastro), alignment: 'center', bold: true, fontSize: 7 },
          { text: d.numero, alignment: 'center', bold: true, fontSize: 7 },
          { text: d.favorecido.nome, alignment: 'center', bold: true, fontSize: 7 },
          { text: d.documento, alignment: 'center', bold: true, fontSize: 7 },
          { text: this.funcaoService.mascarar('##.##.##', d.ficha.executora.codigo), alignment: 'center', bold: true, fontSize: 7 },
          { text: d.ficha.numero, alignment: 'center', bold: true, fontSize: 7 },
          { text: d.subelemento.codigo, alignment: 'center', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(d.total_empenhado, 2), alignment: 'center', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(d.total_liquidado, 2), alignment: 'center', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(d.total_pago, 2), alignment: 'center', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(+d.total_liquidado - +d.total_pago, 2), alignment: 'center', bold: true, fontSize: 7 },
        ]);
      }
    }

    return [{
      layout: 'linhas',
      table: {
        widths: ['auto', 'auto', '*', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: conteudo
      }
    }];
  }

  public montarExcel(dados, parametros) {
    const listaItens = new Array();
    let totalEmpenhado: number = 0;
    let totalLiquidado: number = 0;
    let totalPago: number = 0;

    if (parametros['orderBy'] == 'data_empenho') {
      for (const d of dados) {
        const entity = {
          'DATA': this.funcaoService.converteDataBR(d.data_cadastro),
          'EMPENHO Nº': this.funcaoService.strZero(d.numero, 5),
          'FORNECEDOR': d.favorecido.nome,
          'DOCUMENTO': d.documento,
          'UNIDADE': this.funcaoService.mascarar('##.##.##', d.ficha.executora.codigo),
          'FICHA': d.ficha.numero,
          'DESPESA': d.subelemento.codigo,
          'EMPENHADO': this.funcaoService.convertToBrNumber(d.total_empenhado, 2),
          'LIQUIDADO': this.funcaoService.convertToBrNumber(d.total_liquidado, 2),
          'PAGO': this.funcaoService.convertToBrNumber(d.total_pago, 2),
          'DÍVIDA': this.funcaoService.convertToBrNumber(+d.total_liquidado - +d.total_pago, 2)
        };
        listaItens.push(entity);
      }
    } else if (parametros['orderBy'] == 'subelemento.codigo,favorecido.nome') {
      const dadosAgrupados = this.funcaoService.agrupar(dados, ['subelemento.codigo', 'favorecido.nome'], ['total_empenhado', 'total_liquidado', 'total_pago'])

      for (const da of dadosAgrupados) {
        let entity = {
          'DATA': `${da.registros[0]?.subelemento?.codigo} - ${da?.registros[0]?.subelemento?.nome}   ${da.registros[0]?.favorecido?.nome}`,
          'EMPENHO Nº': '',
          'FORNECEDOR': '',
          'DOCUMENTO': '',
          'UNIDADE': '',
          'FICHA': '',
          'DESPESA': '',
          'EMPENHADO': '',
          'LIQUIDADO': '',
          'PAGO': '',
          'DÍVIDA': ''
        };

        listaItens.push(entity);

        for (const d of da.registros) {
          const entity = {
            'DATA': this.funcaoService.converteDataBR(d.data_cadastro),
            'EMPENHO Nº': this.funcaoService.strZero(d.numero, 5),
            'FORNECEDOR': d.favorecido.nome,
            'DOCUMENTO': d.documento,
            'UNIDADE': this.funcaoService.mascarar('##.##.##', d.ficha.executora.codigo),
            'FICHA': d.ficha.numero,
            'DESPESA': d.subelemento.codigo,
            'EMPENHADO': this.funcaoService.convertToBrNumber(d.total_empenhado, 2),
            'LIQUIDADO': this.funcaoService.convertToBrNumber(d.total_liquidado, 2),
            'PAGO': this.funcaoService.convertToBrNumber(d.total_pago, 2),
            'DÍVIDA': this.funcaoService.convertToBrNumber(+d.total_liquidado - +d.total_pago, 2)
          };
          listaItens.push(entity);
        }

        entity = {
          'DATA': 'TOTAL DO SUBELEMENTO',
          'EMPENHO Nº': '',
          'FORNECEDOR': '',
          'DOCUMENTO': '',
          'UNIDADE': '',
          'FICHA': '',
          'DESPESA': '',
          'EMPENHADO': this.funcaoService.convertToBrNumber(da.totalizadores['total_empenhado'], 2),
          'LIQUIDADO': this.funcaoService.convertToBrNumber(da.totalizadores['total_liquidado'], 2),
          'PAGO': this.funcaoService.convertToBrNumber(da.totalizadores['total_pago'], 2),
          'DÍVIDA': this.funcaoService.convertToBrNumber(+da.totalizadores['total_liquidado'] - +da.totalizadores['total_pago'], 2)
        };
        listaItens.push(entity);

        totalEmpenhado += +da.totalizadores['total_empenhado'];
        totalLiquidado += +da.totalizadores['total_liquidado'];
        totalPago += +da.totalizadores['total_pago']
      }

      const entity = {
        'DATA': 'TOTAL DO GERAL',
        'EMPENHO Nº': '',
        'FORNECEDOR': '',
        'DOCUMENTO': '',
        'UNIDADE': '',
        'FICHA': '',
        'DESPESA': '',
        'EMPENHADO': this.funcaoService.convertToBrNumber(totalEmpenhado, 2),
        'LIQUIDADO': this.funcaoService.convertToBrNumber(totalLiquidado, 2),
        'PAGO': this.funcaoService.convertToBrNumber(totalPago, 2),
        'DÍVIDA': this.funcaoService.convertToBrNumber(+totalLiquidado - +totalPago, 2)
      };
      listaItens.push(entity);

    } else if (parametros['orderBy'] == 'subelemento.codigo') {
      const dadosAgrupados = this.funcaoService.agrupar(dados, ['subelemento.codigo'], ['total_empenhado', 'total_liquidado', 'total_pago'])

      for (const da of dadosAgrupados) {
        let entity = {
          'DATA': `${da.registros[0]?.subelemento?.codigo} - ${da?.registros[0]?.subelemento?.nome}`,
          'EMPENHO Nº': '',
          'FORNECEDOR': '',
          'DOCUMENTO': '',
          'UNIDADE': '',
          'FICHA': '',
          'DESPESA': '',
          'EMPENHADO': '',
          'LIQUIDADO': '',
          'PAGO': '',
          'DÍVIDA': ''
        };
        listaItens.push(entity);

        for (const d of da.registros) {
          const entity = {
            'DATA': this.funcaoService.converteDataBR(d.data_cadastro),
            'EMPENHO Nº': this.funcaoService.strZero(d.numero, 5),
            'FORNECEDOR': d.favorecido.nome,
            'DOCUMENTO': d.documento,
            'UNIDADE': this.funcaoService.mascarar('##.##.##', d.ficha.executora.codigo),
            'FICHA': d.ficha.numero,
            'DESPESA': d.subelemento.codigo,
            'EMPENHADO': this.funcaoService.convertToBrNumber(d.total_empenhado, 2),
            'LIQUIDADO': this.funcaoService.convertToBrNumber(d.total_liquidado, 2),
            'PAGO': this.funcaoService.convertToBrNumber(d.total_pago, 2),
            'DÍVIDA': this.funcaoService.convertToBrNumber(+d.total_liquidado - +d.total_pago, 2)
          };
          listaItens.push(entity);
        }

        entity = {
          'DATA': 'TOTAL DO SUBELEMENTO',
          'EMPENHO Nº': '',
          'FORNECEDOR': '',
          'DOCUMENTO': '',
          'UNIDADE': '',
          'FICHA': '',
          'DESPESA': '',
          'EMPENHADO': this.funcaoService.convertToBrNumber(da.totalizadores['total_empenhado'], 2),
          'LIQUIDADO': this.funcaoService.convertToBrNumber(da.totalizadores['total_liquidado'], 2),
          'PAGO': this.funcaoService.convertToBrNumber(da.totalizadores['total_pago'], 2),
          'DÍVIDA': this.funcaoService.convertToBrNumber(+da.totalizadores['total_liquidado'] - +da.totalizadores['total_pago'], 2)
        };
        listaItens.push(entity);

        totalEmpenhado += +da.totalizadores['total_empenhado'];
        totalLiquidado += +da.totalizadores['total_liquidado'];
        totalPago += +da.totalizadores['total_pago']
      }

      const entity = {
        'DATA': 'TOTAL DO GERAL',
        'EMPENHO Nº': '',
        'FORNECEDOR': '',
        'DOCUMENTO': '',
        'UNIDADE': '',
        'FICHA': '',
        'DESPESA': '',
        'EMPENHADO': this.funcaoService.convertToBrNumber(totalEmpenhado, 2),
        'LIQUIDADO': this.funcaoService.convertToBrNumber(totalLiquidado, 2),
        'PAGO': this.funcaoService.convertToBrNumber(totalPago, 2),
        'DÍVIDA': this.funcaoService.convertToBrNumber(+totalLiquidado - +totalPago, 2)
      };
      listaItens.push(entity);
    }

    tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile('EMPENHOS ORÇAMENTÁRIOS POR SUB-ELEMENTO');
  }
}