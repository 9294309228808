import { Component, ElementRef, Injector } from '@angular/core';
import { BaseResourceFormComponent, LoginContabil, Imovel, ImovelOcorrencia } from 'eddydata-lib';
import { BemImovelService } from '../service/bem-imovel.service';

@Component({
  selector: 'app-bem-imovel-view',
  templateUrl: './bem-imovel-view.component.html'
})
export class BemImovelViewComponent extends BaseResourceFormComponent<Imovel, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  readOnly: boolean;
  public ocorrencias: ImovelOcorrencia[] = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected bemImovelService: BemImovelService) {
    super(new Imovel(), injector, Imovel.converteJson, bemImovelService);
    this.readOnly = true;
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {

  }

  protected parametrosExtras(): {} {
    return { relations: 'setor.unidade,responsavel' };
  }

  protected afterLoad() {
  }

  protected afterInit(): void {
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit() {
  }

  protected afterSubmit(modelo: Imovel) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
