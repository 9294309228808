import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { Estoque } from "../../entidade/almoxarifado/estoque.model";
import { BaseResourceService } from "../../models/services/base-resource.service";
import { Page } from "../../util/page";


@Injectable({
  providedIn: 'root'
})
export class RPEstoqueService extends BaseResourceService<Estoque> {

  constructor(
    protected injector: Injector
  ) {
    super(`estoques`, injector);
  }


  public estoquesUsuarios(usuarioId: number, orgaoId: number): Observable<Page> {
    return this.filtrar(1, -1, {
      OR: `usuarios.id=${usuarioId};!;!;usuarios_estoque.usuario.id=${usuarioId}`, orderBy: 'usuarios.id', 'orgao.id': orgaoId
    })
  }
}
