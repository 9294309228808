import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from './exercicio.model';
import { Orgao } from './orgao.model';

export class OrgaoSuplementacao extends BaseResourceModel {

  constructor(
    public id?: number,
    public lei?: string,
    public percentual_suplementacao?: number,
    public limite_prudencial?: number,
    public exercicio?: Exercicio,
    public orgao?: Orgao,
    public editavel?: boolean
  ) {
    super();
  }

  static converteJson(json: any): OrgaoSuplementacao {
    return Object.assign(new OrgaoSuplementacao(), json);
  }
}
