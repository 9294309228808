import { SituacaoFatura } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido } from '../compra/favorecido.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { FaturaHistorico } from './fatura-historico.model';
import { FaturaStorage } from './fatura-storage.model';
import { FaturaTipoImovel } from './fatura-tipo-imovel.model';
import { LiquidacaoFaturaTipo } from './liquidacao-fatura-tipo.model';

export class Fatura extends BaseResourceModel {
  constructor(
    public codigo_barra?: string,
    public vencimento?: Date,
    public medicao?: number,
    public valor_fatura?: number,
    public taxa?: number,
    public juros?: number,
    public att_monetaria?: number,
    public justificativa?: string,
    public situacao?: SituacaoFatura,
    public fatura_tipo_imovel?: FaturaTipoImovel,
    public favorecido?: Favorecido,
    public arquivos?: FaturaStorage[],
    public historico?: FaturaHistorico[],
    public liquidacoes_fatura?: LiquidacaoFaturaTipo[],
    public exercicio?: Exercicio,
    public orgao?: Orgao
  ) {
    super();
  }

  static converteJson(json: any): Fatura {
    return Object.assign(new Fatura(), json);
  }
}
