import { Directive, Injector, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { QuadroEnsinoService } from '../service/quadro-ensino.service';
import {
  FuncaoService, GlobalService, Login,
  Exercicio, FuncaoGoverno, Relatorio, ProgressoService, FormatoExportacao, Coluna
} from 'eddydata-lib';
import { Assinaturas } from 'administrativo-lib';

@Directive()
export class Quadro5Ensino implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected quadroServico: QuadroEnsinoService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected progressoService: ProgressoService,
    protected dataInicial: Date,
    protected dataFinal: Date,
    protected funcao?: FuncaoGoverno,
    protected injector?: Injector,
    protected assinatura_ensino?: boolean) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async listar(orgaos: number[]) {
    return await this.quadroServico
      .obterQuadro5(this.mes, this.exercicio.id, orgaos, this.dataInicial, this.dataFinal).toPromise();
  }

  public montarRelatorio(orgaos: number[], formato?: FormatoExportacao) {
    if (!formato) formato = 'pdf';
    this.quadroServico.obterQuadro5(this.mes, this.exercicio.id, orgaos, this.dataInicial, this.dataFinal)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(idTransacao => {
        let dados
        this.progressoService.show(idTransacao, async (retorno) => {
          dados = retorno
          if (formato === 'pdf') {
            Relatorio.imprimirPersonalizado(
              'QUADRO 5 - FUNDEB'
              , this.login.usuario.nome, this.login.usuario.sobrenome,
              this.login.orgao.nome, this.login.brasao,
              this.conteudo(dados).concat(this.conteudoDespesa(dados))
                .concat(this.conteudoDeducao1(dados)
                  .concat(this.conteudoDeducao2(dados))
                  .concat(this.conteudoDeducao3(dados))
                  .concat(this.conteudoDespesaLiquida(dados))
                  .concat(await this.conteudoVaat(dados))),
              'portrait', 'QUADRO 5 - FUNDEB',
              {
                linhas: {
                  hLineWidth() {
                    return 1;
                  },
                  vLineWidth() {
                    return 1;
                  },
                  hLineColor() {
                    return 'black';
                  },
                  paddingLeft() {
                    return 3;
                  },
                  paddingRight() {
                    return 3;
                  }
                }
              });
          } else {
            this.funcaoService.exportar(formato, this.conteudoExportar(dados), 'QUADRO 5 - FUNDEB', this.colunasRelatorio())
          }
        });
      });
  }

  private conteudo(dados: any[]): {}[] {
    let referencia = '';
    if (this.mes) {
      referencia = `${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase()}/${this.exercicio.ano}`;
    } else if (this.dataInicial && this.dataFinal) {
      let dt = this.dataInicial.toString().split("-");
      referencia = `${dt[2]}/${dt[1]}/${dt[0]}`;
      dt = this.dataFinal.toString().split("-");
      referencia += ` à ${dt[2]}/${dt[1]}/${dt[0]}`;
    } else {
      referencia += this.exercicio.ano;
    }

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `REFERÊNCIA: ${referencia}`,
        alignment: 'center',
        margin: [5, 5, 5, 5],
        fontSize: 11,
        bold: true,
        colSpan: 7
      }, '', '', '', '', '', ''],
      [{
        text: 'RECEITAS DO FUNDEB',
        alignment: 'center',
        bold: true,
        colSpan: 3
      }, '', '', {
        text: 'RETENÇÕES AO FUNDEB',
        alignment: 'center',
        bold: true,
        colSpan: 4
      }, '', '', ''],
      [{
        text: '',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'PREVISÃO ATUALIZADA PARA O EXERCÍCIO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'RECEBIDO ATÉ O PERÍODO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'PREVISÃO ATUALIZADA PARA O EXERCÍCIO',
        alignment: 'center',
        bold: true, fontSize: 6, colSpan: 2
      }, '', {
        text: 'RETIDO ATÉ O PERÍODO',
        alignment: 'center',
        bold: true, fontSize: 6, colSpan: 2
      }, '']
    ];

    // monta o agrupamento do relatório
    const dado = dados[0];
    registros.push([
      {
        text: 'Impostos e Transferências de Impostos - Principal (I)',
        alignment: 'left', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.c4),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.d4),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.e4 * (-1)),
        alignment: 'right', fontSize: 7, colSpan: 2
      }, '',
      {
        text: this.funcaoService.convertToBrNumber(dado.f4 * (-1)),
        alignment: 'right', fontSize: 7, colSpan: 2
      }, ''
    ]);
    registros.push([
      {
        text: 'Impostos e Transferências de Impostos - Rendimentos de Aplicação Financeira (II)',
        alignment: 'left', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.c4a),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.d4a),
        alignment: 'right', fontSize: 7
      },
      {
        text: '',
        alignment: 'right', fontSize: 7, colSpan: 2
      }, '',
      {
        text: '',
        alignment: 'right', fontSize: 7, colSpan: 2
      }, ''
    ]);

    registros.push([
      {
        text: 'Complementação da União - VAAF - Principal (III)',
        alignment: 'left', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.c4b),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.d4b),
        alignment: 'right', fontSize: 7
      },
      {
        text: '',
        alignment: 'center', fontSize: 7, bold: true, colSpan: 2
      }, '',
      {
        text: '',
        alignment: 'center', fontSize: 7, bold: true, colSpan: 2
      }, ''
    ]);

    registros.push([
      {
        text: ' Complementação da União - VAAF - Rendimentos de Aplicação Financeira (IV)',
        alignment: 'left', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.c4c),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.d4c),
        alignment: 'right', fontSize: 7
      },
      {
        text: '',
        alignment: 'center', fontSize: 7, bold: true, colSpan: 2
      }, '',
      {
        text: '',
        alignment: 'center', fontSize: 7, bold: true, colSpan: 2
      }, ''
    ]);

    registros.push([
      {
        text: 'Complementação da União - VAAT - Principal (V)',
        alignment: 'left', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.c4d),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.d4d),
        alignment: 'right', fontSize: 7
      },
      {
        text: '',
        alignment: 'center', fontSize: 7, bold: true, colSpan: 2
      }, '',
      {
        text: '',
        alignment: 'center', fontSize: 7, bold: true, colSpan: 2
      }, ''
    ]);

    registros.push([
      {
        text: 'Complementação da União - VAAT -  Rendimentos de Aplicação Financeira (VI)',
        alignment: 'left', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.c4e),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.d4e),
        alignment: 'right', fontSize: 7
      },
      {
        text: '',
        alignment: 'center', fontSize: 7, bold: true, colSpan: 2
      }, '',
      {
        text: '',
        alignment: 'center', fontSize: 7, bold: true, colSpan: 2
      }, ''
    ]);

    registros.push([
      {
        text: 'Complementação da União - VAAR - Principal (VII)',
        alignment: 'left', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.c5),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.d5),
        alignment: 'right', fontSize: 7
      },
      {
        text: '',
        alignment: 'center', fontSize: 7, bold: true, colSpan: 2
      }, '',
      {
        text: '',
        alignment: 'center', fontSize: 7, bold: true, colSpan: 2
      }, ''
    ]);

    registros.push([
      {
        text: 'RECEITAS DE APLICAÇÕES FINANCEIRAS VAAR (IV)',
        alignment: 'left', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.c5a),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.d5a),
        alignment: 'right', fontSize: 7
      },
      {
        text: 'APURAÇÃO DO RESULTADO DO FUNDEB ATÉ O TRIMESTRE',
        alignment: 'center', fontSize: 7, bold: true, colSpan: 4
      }, '', '', ''
    ]);

    registros.push([
      {
        text: 'TOTAL (I+II+III+IV)',
        alignment: 'left', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.c6),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.d6),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: 'TRANSFERÊNCIAS RECEBIDAS',
        alignment: 'center', bold: true, fontSize: 7, colSpan: 2
      }, '',
      {
        text: 'RETENÇÕES',
        alignment: 'center', bold: true, fontSize: 7, colSpan: 2
      }, ''
    ]);

    registros.push([
      {
        text: '', colSpan: 3,
      },
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.e7),
        alignment: 'right', bold: true, fontSize: 7, colSpan: 2
      }, '',
      {
        text: this.funcaoService.convertToBrNumber(dado.f7),
        alignment: 'right', bold: true, fontSize: 7, colSpan: 2
      }, ''
    ]);

    registros.push([
      {
        text: '', colSpan: 3,
      },
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: 'DIFERENÇA (RECEBIDO - RETIDO)',
        alignment: 'center', bold: true, fontSize: 7, colSpan: 4
      },
      {
        text: ''
      },
      {
        text: ''
      },
      {
        text: ''
      }
    ]);

    registros.push([
      {
        text: '', colSpan: 3,
      },
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: 'GANHO',
        alignment: 'center', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.e9),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: 'PERDA',
        alignment: 'center', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.f9),
        alignment: 'right', bold: true, fontSize: 7
      }
    ]);

    registros.push([
      {
        text: 'APLICAÇÕES MÍNIMAS OBRIGATÓRIAS', colSpan: 3,
        alignment: 'center', bold: true, fontSize: 7
      }, '', '',
      {
        text: '', colSpan: 4
      }, '', '', ''
    ]);
    registros.push([
      {
        text: 'TOTAL, exceto VAAR (I+III)',
        alignment: 'left', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.c11),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.d11),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: '', colSpan: 4
      }, '', '', ''
    ]);

    registros.push([
      {
        text: 'PROFISSIONAIS DA EDUCAÇÃO (70% DO TOTAL, exceto VAAR)',
        alignment: 'left', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.c12),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.d12),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: '', colSpan: 4
      }, '', '', ''
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }];
  }

  public conteudoDespesa(lista: any) {
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: 'DESPESAS TOTAIS',
        alignment: 'center',
        bold: true,
        border: [true, false, true, false],
        margin: [10, 10, 10, 10],
        colSpan: 9
      }, '', '', '', '', '', '', '', ''],
      [{
        text: '',
        alignment: 'center',
        bold: true,
      }, {
        text: 'DOTAÇÃO ATUALIZADA PARA O EXERCÍCIO',
        alignment: 'center', fontSize: 7,
        bold: true,
        colSpan: 2,
      }, '', {
        text: 'DESPESA EMPENHADA ATÉ O TRIMESTRE',
        alignment: 'center', fontSize: 7,
        bold: true,
        colSpan: 2,
      }, '', {
        text: 'DESPESA LIQUIDADA ATÉ O TRIMESTRE',
        alignment: 'center', fontSize: 7,
        bold: true,
        colSpan: 2,
      }, '', {
        text: 'DESPESA PAGA ATÉ O TRIMESTRE',
        alignment: 'center', fontSize: 7,
        bold: true,
        colSpan: 2,
      }, ''],
      [{
        text: '',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'VALOR',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: '%',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'VALOR',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: '%',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'VALOR',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: '%',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'VALOR',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: '%',
        alignment: 'center',
        bold: true, fontSize: 6
      }]
    ];

    const dado = lista[0];
    for (const item of lista[1]) {
      total1 += +item.dotacao;
      total2 += +item.empenhado;
      total3 += +item.liquidado;
      total4 += +item.pago;
    }

    registros.push([
      {
        text: 'TOTAL**', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(total1),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber((total1 / dado.c11) * 100),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(total2),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber((total2 / dado.d11) * 100),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(total3),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber((total3 / dado.d11) * 100),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(total4),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber((total4 / dado.d11) * 100),
        alignment: 'right', bold: true, fontSize: 7
      },
    ]);

    for (const item of lista[1]) {
      registros.push([
        {
          text: item.tipo, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.dotacao),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.dotacao / dado.c11) * 100),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.empenhado),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.empenhado / dado.d11) * 100),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.liquidado),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.liquidado / dado.d11) * 100),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.pago),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.pago / dado.d11) * 100),
          alignment: 'right', fontSize: 7
        },
      ]);
    }

    registros.push([
      {
        text: '*No percentual de aplicação dos profissionais da educação não são considerados os valores relativos ao VAAR, conforme Art. 26 da Lei 14.133/2020'
        , fontSize: 7, colSpan: 9
      },
      '', '', '', '', '', '', '', ''
    ]);
    registros.push([
      {
        text: '**No percentual de aplicação dos recursos do FUNDEB são considerados os valores relativos à Complementação da União, conforme § 3º do Art. 25 da Lei 14.133/2020'
        , fontSize: 7, colSpan: 9
      },
      '', '', '', '', '', '', '', ''
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: [112, 60, 30, 60, 30, 60, 30, 60, 30],
        body: registros
      }
    }];
  }

  public conteudoDeducao1(lista: any) {
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    const dado = lista[0];
    for (const item of lista[2]) {
      total2 += +item.empenhado;
      total3 += +item.liquidado;
      total4 += +item.pago;
    }

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: 'DEDUÇÕES',
        alignment: 'center',
        bold: true,
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
        colSpan: 7
      }, '', '', '', '', '', ''],
      [{
        text: this.exercicio.ano >= 2023 ? 'PROFISSIONAIS DA EDUCAÇÃO BÁSICA* - exceto Complementação da União VAAR' : 'PROFISSIONAIS DA EDUCAÇÃO',
        alignment: 'center',
        bold: true,
      }, {
        text: this.funcaoService.convertToBrNumber(total2),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total2 / dado.c12),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total3),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total3 / dado.d12),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total4),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total4 / dado.d12),
        alignment: 'right', bold: true, fontSize: 7
      }]
    ];

    for (const item of lista[2]) {
      registros.push([
        {
          text: item.tipo, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.empenhado),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.empenhado / dado.d11) * 100),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.liquidado),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.liquidado / dado.d11) * 100),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.pago),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.pago / dado.d11) * 100),
          alignment: 'right', fontSize: 7
        },
      ]);
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: [216, 60, 30, 60, 30, 60, 30],
        body: registros
      }
    }];
  }

  public conteudoDeducao2(lista: any) {
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    const dado = lista[0];
    for (const item of lista[3]) {
      total2 += +item.empenhado;
      total3 += +item.liquidado;
      total4 += +item.pago;
    }

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }, {
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }, {
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }, {
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }, {
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }, {
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }, {
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }],
      [{
        text: this.exercicio.ano >= 2023 ? 'PROFISSIONAIS DA EDUCAÇÃO BÁSICA* - Complementação da União VAAR' : 'OUTRAS E VAAR',
        alignment: 'center',
        bold: true,
      }, {
        text: this.funcaoService.convertToBrNumber(total2),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total2 / dado.c12),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total3),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total3 / dado.d12),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total4),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total4 / dado.d12),
        alignment: 'right', bold: true, fontSize: 7
      }]
    ];

    for (const item of lista[3]) {
      registros.push([
        {
          text: item.tipo, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.empenhado),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.empenhado / dado.d11) * 100),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.liquidado),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.liquidado / dado.d11) * 100),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.pago),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.pago / dado.d11) * 100),
          alignment: 'right', fontSize: 7
        },
      ]);
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: [216, 60, 30, 60, 30, 60, 30],
        body: registros
      }
    }];
  }

  public conteudoDeducao3(lista: any) {
    if (this.exercicio.ano < 2023) return;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    const dado = lista[0];
    for (const item of lista[4]) {
      total2 += +item.empenhado;
      total3 += +item.liquidado;
      total4 += +item.pago;
    }

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }, {
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }, {
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }, {
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }, {
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }, {
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }, {
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }],
      [{
        text: 'OUTRAS',
        alignment: 'center',
        bold: true,
      }, {
        text: this.funcaoService.convertToBrNumber(total2),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total2 / dado.c12),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total3),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total3 / dado.d12),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total4),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total4 / dado.d12),
        alignment: 'right', bold: true, fontSize: 7
      }]
    ];

    for (const item of lista[4]) {
      registros.push([
        {
          text: item.tipo, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.empenhado),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.empenhado / dado.d11) * 100),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.liquidado),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.liquidado / dado.d11) * 100),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.pago),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.pago / dado.d11) * 100),
          alignment: 'right', fontSize: 7
        },
      ]);
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: [216, 60, 30, 60, 30, 60, 30],
        body: registros
      }
    }];
  }

  public conteudoDespesaLiquida(lista: any) {
    // dados do cabeçalho
    const dado = lista[0];

    // lista de despesas totais
    const itemDespesa = lista[1];
    const despesaMagisterio1 = +itemDespesa[0].empenhado;
    const despesaMagisterio2 = +itemDespesa[0].liquidado;
    const despesaMagisterio3 = +itemDespesa[0].pago;
    
    const despesaVaar1 = +itemDespesa[1].empenhado;
    const despesaVaar2 = +itemDespesa[1].liquidado;
    const despesaVaar3 = +itemDespesa[1].pago;

    const despesaOutras1 = this.exercicio.ano >= 2023 ? +itemDespesa[2].empenhado : 0;
    const despesaOutras2 = this.exercicio.ano >= 2023 ? +itemDespesa[2].liquidado : 0;
    const despesaOutras3 = this.exercicio.ano >= 2023 ? +itemDespesa[2].pago : 0;

    // lista de deducoes magisterio
    let deducaoMagisterio1 = 0;
    let deducaoMagisterio2 = 0;
    let deducaoMagisterio3 = 0;

    for (const item of lista[2]) {
      deducaoMagisterio1 += +item.empenhado;
      deducaoMagisterio2 += +item.liquidado;
      deducaoMagisterio3 += +item.pago;
    }
    // lista de deducoes magisterio
    let deducaoVaar1 = 0;
    let deducaoVaar2 = 0;
    let deducaoVaar3 = 0;

    for (const item of lista[3]) {
      deducaoVaar1 += +item.empenhado;
      deducaoVaar2 += +item.liquidado;
      deducaoVaar3 += +item.pago;
    }

    // lista de deduções outras
    let deducaoOutras1 = 0;
    let deducaoOutras2 = 0;
    let deducaoOutras3 = 0;
    if (this.exercicio.ano >= 2023) {
      for (const item of lista[4]) {
        deducaoOutras1 += +item.empenhado;
        deducaoOutras2 += +item.liquidado;
        deducaoOutras3 += +item.pago;
      }
    }

    const total1 = +despesaMagisterio1 + +despesaOutras1 + +despesaVaar1 + +deducaoMagisterio1 + +deducaoOutras1 + +deducaoVaar1;
    const total2 = +despesaMagisterio2 + +despesaOutras2 + +despesaVaar2 + +deducaoMagisterio2 + +deducaoOutras2 + deducaoVaar2;
    const total3 = +despesaMagisterio3 + +despesaOutras3 + +despesaVaar3 + +deducaoMagisterio3 + +deducaoOutras3 + +deducaoVaar3;

    // // monta o cabecalho
    const registros: {}[] = [
      [{
        text: 'DESPESAS LÍQUIDAS',
        alignment: 'center',
        bold: true,
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
        colSpan: 7
      }, '', '', '', '', '', ''],
      [{
        text: 'TOTAL**',
        alignment: 'left',
        bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total1),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber((total1 / dado.d11) * 100),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total2),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber((total2 / dado.d11) * 100),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total3),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber((total3 / dado.d11) * 100),
        alignment: 'right', bold: true, fontSize: 7
      }]
    ];

    registros.push([
      {
        text: this.exercicio.ano >= 2023 ? 'PROFISSIONAIS DA EDUCAÇÃO BÁSICA* - exceto Complementação da União VAAR (mín. 70%)' : 'PROFISSIONAIS DA EDUCAÇÃO', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+despesaMagisterio1 + +deducaoMagisterio1),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+despesaMagisterio1 + +deducaoMagisterio1) / dado.d11) * 100),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+despesaMagisterio2 + +deducaoMagisterio2),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+despesaMagisterio2 + +deducaoMagisterio2) / dado.d11) * 100),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+despesaMagisterio3 + +deducaoMagisterio3),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+despesaMagisterio3 + +deducaoMagisterio3) / dado.d11) * 100),
        alignment: 'right', fontSize: 7
      },
    ]);

    registros.push([
      {
        text: this.exercicio.ano >= 2023 ? 'PROFISSIONAIS DA EDUCAÇÃO BÁSICA* - Complementação da União VAAR' : 'OUTRAS E VAAR', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+despesaVaar1 + +deducaoVaar1),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+despesaVaar1 + +deducaoVaar1) / dado.d11) * 100),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+despesaVaar2 + +deducaoVaar2),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+despesaVaar2 + +deducaoVaar2) / dado.d11) * 100),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+despesaVaar3 + +deducaoVaar3),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+despesaVaar3 + +deducaoVaar3) / dado.d11) * 100),
        alignment: 'right', fontSize: 7
      },
    ]);
    if (this.exercicio.ano >= 2023) {
      registros.push([
        {
          text: 'OUTRAS', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(+despesaOutras1 + +deducaoOutras1),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(((+despesaOutras1 + +deducaoOutras1) / dado.d11) * 100),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(+despesaOutras2 + +deducaoOutras2),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(((+despesaOutras2 + +deducaoOutras2) / dado.d11) * 100),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(+despesaOutras3 + +deducaoOutras3),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(((+despesaOutras3 + +deducaoOutras3) / dado.d11) * 100),
          alignment: 'right', fontSize: 7
        },
      ]);
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: [216, 60, 30, 60, 30, 60, 30],
        body: registros
      }
    }];
  }

  public async conteudoVaat(lista: any) {
    const registros: {}[] = [
      [{
        text: 'Recursos recebidos a título de Complementação da União VAAT - Aplicação em Despesas de Capital - art. 27 Lei 14.113/2020',
        alignment: 'center',
        bold: true,
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
        colSpan: 7
      }, '', '', '', '', '', '']
    ];

    registros.push([
      { text: `Total da Complementação da União VAAT arrecadado`, border: [true, true, true, true], colSpan: 3 },
      '', '',
      { text: `${this.funcaoService.convertToBrNumber(lista[0]?.g38, 2)}`, colSpan: 2, alignment: 'center' },
      '',
      { text: '', colSpan: 2 },
      ''
    ]);

    registros.push([
      { text: `Percentual mínimo de aplicação - Despesa de Capital`, border: [true, true, true, true], colSpan: 3 },
      '', '',
      { text: `15%`, colSpan: 2, alignment: 'center' },
      '',
      { text: '', colSpan: 2 },
      ''
    ]);

    registros.push([
      { text: `` },
      { text: `DESPESA EMPENHADA`, colSpan: 2, alignment: 'center' },
      '',
      { text: `DESPESA LIQUIDADA`, colSpan: 2, alignment: 'center' },
      '',
      { text: `DESPESA PAGA`, colSpan: 2, alignment: 'center' }
    ]);

    registros.push([
      { text: `Complementação da União VAAT - Despesas de Capital` },
      { text: `${this.funcaoService.convertToBrNumber((this.exercicio.ano >= 2023 ? lista[5] : lista[4])[0].empenhado, 2)}`, alignment: 'right' },
      { text: `${+lista[0]?.g38 > 0 ? (+(this.exercicio.ano >= 2023 ? lista[5] : lista[4])[0].empenhado / +lista[0]?.g38) * 100 : `0,00%`}`, alignment: 'right' },
      { text: `${this.funcaoService.convertToBrNumber((this.exercicio.ano >= 2023 ? lista[5] : lista[4])[0].liquidado, 2)}`, alignment: 'right' },
      { text: `${+lista[0]?.g38 > 0 ? (+(this.exercicio.ano >= 2023 ? lista[5] : lista[4])[0].liquidado / +lista[0]?.g38) * 100 : `0,00%`}`, alignment: 'right' },
      { text: `${this.funcaoService.convertToBrNumber((this.exercicio.ano >= 2023 ? lista[5] : lista[4]).pago, 2)}`, alignment: 'right' },
      { text: `${+lista[0]?.g38 > 0 ? (+(this.exercicio.ano >= 2023 ? lista[5] : lista[4])[0].pago / +lista[0]?.g38) * 100 : `0,00%`}`, alignment: 'right' },
    ]);

    registros.push([
      {
        text: 'Recursos recebidos a título de Complementação da União VAAT - Aplicação em Educação Infantil - art. 28 Lei 14.113/2020',
        alignment: 'center',
        bold: true,
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
        colSpan: 7
      }, '', '', '', '', '', ''
    ]);

    registros.push([
      { text: `Percentual mínimo de aplicação - Educação Infantil`, border: [true, true, true, true], colSpan: 3 },
      '', '',
      { text: ``, colSpan: 2, alignment: 'center' },
      '',
      { text: '', colSpan: 2 },
      ''
    ]);

    registros.push([
      { text: `` },
      { text: `DESPESA EMPENHADA`, colSpan: 2, alignment: 'center' },
      '',
      { text: `DESPESA LIQUIDADA`, colSpan: 2, alignment: 'center' },
      '',
      { text: `DESPESA PAGA`, colSpan: 2, alignment: 'center' }
    ]);

    registros.push([
      { text: `Complementação da União VAAT - Despesas de Capital` },
      { text: `${this.funcaoService.convertToBrNumber((this.exercicio.ano >= 2023 ? lista[5] : lista[4])[1].empenhado, 2)}`, alignment: 'right' },
      { text: `${+lista[0]?.g38 > 0 ? (+(this.exercicio.ano >= 2023 ? lista[5] : lista[4])[1].empenhado / +lista[0]?.g38) * 100 : `0,00%`}`, alignment: 'right' },
      { text: `${this.funcaoService.convertToBrNumber((this.exercicio.ano >= 2023 ? lista[5] : lista[4])[1].liquidado, 2)}`, alignment: 'right' },
      { text: `${+lista[0]?.g38 > 0 ? (+(this.exercicio.ano >= 2023 ? lista[5] : lista[4])[1].liquidado / +lista[0]?.g38) * 100 : `0,00%`}`, alignment: 'right' },
      { text: `${this.funcaoService.convertToBrNumber((this.exercicio.ano >= 2023 ? lista[5] : lista[4])[1].pago, 2)}`, alignment: 'right' },
      { text: `${+lista[0]?.g38 > 0 ? (+(this.exercicio.ano >= 2023 ? lista[5] : lista[4])[1].pago / +lista[0]?.g38) * 100 : `0,00%`}`, alignment: 'right' },
    ]);

    let assinaturas: {}[]
    if (this.assinatura_ensino) {
      const ass = new Assinaturas(this.login.orgao, this.injector);
      assinaturas = await ass.dadosAssinatura(60, false, null, false, this.assinatura_ensino);
    }

    return this.assinatura_ensino ? [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: [198, 48, 48, 48, 48, 48, 48],
          body: registros
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: [180, 162, 162],
          body: assinaturas
        }
      }
    ] : [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: [198, 48, 48, 48, 48, 48, 48],
        body: registros
      }
    }];
  }

  public colunasRelatorio(): Coluna[] {
    return [
      { titulo: 'Grupo', coluna: 'grupo' },
      { titulo: 'Titulo', coluna: 'titulo' },
      { titulo: 'Tipo', coluna: 'tipo' },
      { titulo: 'Previsão Atualizada', coluna: 'atualizado', decimais: 2 },
      { titulo: 'Período Recebido', coluna: 'recebido', decimais: 2 },
      { titulo: 'Retido', coluna: 'retido', decimais: 2 },
      { titulo: 'Dotação Atualizada', coluna: 'dotacao', decimais: 2 },
      { titulo: 'Dotação %', coluna: 'dotacao_percente', decimais: 2 },
      { titulo: 'Despesa Empenhada', coluna: 'empenhado', decimais: 2 },
      { titulo: 'Empenho %', coluna: 'empenho_percente', decimais: 2 },
      { titulo: 'Despesa Liquidada', coluna: 'liquidado', decimais: 2 },
      { titulo: 'Liquidacao %', coluna: 'liquidacao_percente', decimais: 2 },
      { titulo: 'Despesa Paga', coluna: 'pago', decimais: 2 },
      { titulo: 'Pagamento %', coluna: 'pagamento_percente', decimais: 2 },
    ]
  }

  public conteudoExportar(lista: any[]) {
    return [
      ...this.normalizarDadosFundeb(lista[0]),
      ...this.normalizarDados(lista[1], lista[0], 'DESPESAS TOTAIS'),
      ...this.normalizarDados(lista[2], lista[0], 'DEDUÇÕES'),
      ...this.normalizarDados(lista[3], lista[0], 'DEDUÇÕES'),
      ...this.normalizarDespesaLiquida(lista, 'DESPESAS LÍQUIDAS'),
    ]
  }

  public normalizarDadosFundeb(lista: any) {
    return [
      {
        grupo: 'FUNDEB',
        titulo: 'RECEITAS DE TRANSFERÊNCIAS, exceto VAAR (I)',
        tipo: 'RECEITAS DO FUNDEB',
        atualizado: +lista.c4,
        recebido: +lista.d4
      },
      {
        grupo: 'FUNDEB',
        titulo: 'RECEITAS DE TRANSFERÊNCIAS, exceto VAAR (I)',
        tipo: 'RETENÇÕES AO FUNDEB',
        atualizado: +lista.e4 * (-1),
        retido: +lista.f4 * (-1)
      },
      {
        grupo: 'FUNDEB',
        titulo: 'RECEITAS DE TRANSFERÊNCIAS VAAR (II)',
        tipo: 'RECEITAS DO FUNDEB',
        atualizado: +lista.c4a,
        recebido: +lista.d4a,
      },
      {
        grupo: 'FUNDEB',
        titulo: 'RECEITAS DE APLICAÇÕES FINANCEIRAS, exceto VAAR (III)',
        tipo: 'RECEITAS DO FUNDEB',
        atualizado: +lista.c5,
        recebido: +lista.d5,
      },
      {
        grupo: 'FUNDEB',
        titulo: 'RECEITAS DE APLICAÇÕES FINANCEIRAS VAAR (IV)',
        tipo: 'RECEITAS DO FUNDEB',
        atualizado: +lista.c5a,
        recebido: +lista.d5a,
      },
      {
        grupo: 'FUNDEB',
        titulo: 'TOTAL (I+II+III+IV)',
        tipo: '',
        atualizado: +lista.c4 + +lista.e4 + +lista.c4a + +lista.c5 + +lista.c5a,
        recebido: +lista.d4 + +lista.f4 + +lista.d4a + +lista.d5 + +lista.d5a,
      }
    ]

  }


  public normalizarDados(lista: any[], dados, grupo: string) {
    const linhas: {}[] = [
      {
        grupo: grupo,
        titulo: 'TOTAL',
        dotacao: lista.reduce((acc, item) => +item.dotacao + acc, 0),
        dotacao_percente: (lista.reduce((acc, item) => +item.dotacao + acc, 0) / +dados.c11) * 100,
        empenhado: lista.reduce((acc, item) => +item.empenhado + acc, 0),
        empenho_percente: (lista.reduce((acc, item) => +item.empenhado + acc, 0) / +dados.d11) * 100,
        liquidado: lista.reduce((acc, item) => +item.liquidado + acc, 0),
        liquidacao_percente: (lista.reduce((acc, item) => +item.liquidado + acc, 0) / +dados.d11) * 100,
        pago: lista.reduce((acc, item) => +item.pago + acc, 0),
        pagamento_percente: (lista.reduce((acc, item) => +item.pago + acc, 0) / +dados.d11) * 100,
      }
    ]

    lista.forEach(item => {
      linhas.push({
        grupo: grupo,
        titulo: item.tipo,
        dotacao: item.dotacao,
        dotacao_percente: (+item.dotacao / +dados.c11) * 100,
        empenhado: item.empenhado,
        empenho_percente: (+item.empenhado / +dados.d11) * 100,
        liquidado: item.liquidado,
        liquidacao_percente: (+item.liquidado / +dados.d11) * 100,
        pago: item.pago,
        pagamento_percente: (+item.pago / +dados.d11) * 100,
      })
    })

    return linhas

  }

  public normalizarDespesaLiquida(lista: any[], grupo: string) {
    const linhas: {}[] = []
    const dados = lista[0];

    const itemDespesa = lista[1];
    const despesaMagisterio1 = +itemDespesa[0].empenhado;
    const despesaMagisterio2 = +itemDespesa[0].liquidado;
    const despesaMagisterio3 = +itemDespesa[0].pago;

    const despesaOutras1 = +itemDespesa[1].empenhado;
    const despesaOutras2 = +itemDespesa[1].liquidado;
    const despesaOutras3 = +itemDespesa[1].pago;

    let deducaoMagisterio1 = 0;
    let deducaoMagisterio2 = 0;
    let deducaoMagisterio3 = 0;

    for (const item of lista[2]) {
      deducaoMagisterio1 += +item.empenhado;
      deducaoMagisterio2 += +item.liquidado;
      deducaoMagisterio3 += +item.pago;
    }

    // lista de deduções outras
    let deducaoOutras1 = 0;
    let deducaoOutras2 = 0;
    let deducaoOutras3 = 0;

    for (const item of lista[3]) {
      deducaoOutras1 += +item.empenhado;
      deducaoOutras2 += +item.liquidado;
      deducaoOutras3 += +item.pago;
    }

    const total1 = +despesaMagisterio1 + +despesaOutras1 + +deducaoMagisterio1 + +deducaoOutras1;
    const total2 = +despesaMagisterio2 + +despesaOutras2 + +deducaoMagisterio2 + +deducaoOutras2;
    const total3 = +despesaMagisterio3 + +despesaOutras3 + +deducaoMagisterio3 + +deducaoOutras3;

    linhas.push({
      grupo: grupo,
      titulo: 'TOTAL',
      empenhado: total1,
      empenho_percente: (+total1 / +dados.d11) * 100,
      liquidado: total2,
      liquidacao_percente: (+total2 / +dados.d11) * 100,
      pago: total3,
      pagamento_percente: (+total3 / +dados.d11) * 100,
    },
      {
        grupo: grupo,
        titulo: 'PROFISSIONAIS DA EDUCAÇÃO',
        empenhado: +despesaMagisterio1 + +deducaoMagisterio1,
        empenho_percente: ((+despesaMagisterio1 + +deducaoMagisterio1) / dados.d11) * 100,
        liquidado: +despesaMagisterio2 + +deducaoMagisterio2,
        liquidacao_percente: ((+despesaMagisterio2 + +deducaoMagisterio2) / dados.d11) * 100,
        pago: +despesaMagisterio3 + +deducaoMagisterio3,
        pagamento_percente: ((+despesaMagisterio3 + +deducaoMagisterio3) / dados.d11) * 100,
      },
      {
        grupo: grupo,
        titulo: 'OUTRAS E VAAR',
        empenhado: +despesaOutras1 + +deducaoOutras1,
        empenho_percente: ((+despesaOutras1 + +deducaoOutras1) / dados.d11) * 100,
        liquidado: +despesaOutras2 + +deducaoOutras2,
        liquidacao_percente: ((+despesaOutras2 + +deducaoOutras2) / dados.d11) * 100,
        pago: +despesaOutras3 + +deducaoOutras3,
        pagamento_percente: ((+despesaOutras3 + +deducaoOutras3) / dados.d11) * 100,
      })

    return linhas

  }

  // public normalizarDados(lista: [], grupo: string) {
  //   return lista.map((item: any) => {
  //     return {
  //       grupo: grupo,
  //       titulo: item.tipo,
  //       dotacao: item.dotacao,
  //       empenhado: item.empenhado,
  //       liquidado: item.liquidado,
  //       pago: item.pago
  //     }
  //   })
  // }

}
