import { TipoOrdemServico } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Compra } from '../compra/compra.model';
import { Favorecido } from '../compra/favorecido.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { Funcionario } from '../folha/funcionario.model';
import { OrdemServicoItem } from './ordem-servico-item.model';
import { Veiculo } from './veiculo.model';
import { MovimentoEstoque } from '../almoxarifado/movimento-estoque.model';

export class OrdemServico extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_servico?: Date,
    public km_atual?: number,
    public data_troca?: Date,
    public km_anterior?: number,
    public tipo_servico?: TipoOrdemServico,
    public descricao?: string,
    public documento?: string,
    public compra?: Compra,
    public mecanico?: Funcionario,
    public favorecido?: Favorecido,
    public solicitante?: Usuario,
    public veiculo?: Veiculo,
    public nota_fiscal?: string,
    public orgao?: Orgao,
    public saida_origem?: MovimentoEstoque,
    public itens?: OrdemServicoItem[],
    public usuario_exclusao?: Usuario,
    public motivo_exclusao?: String,
    public data_exclusao?: Date,
    public excluido?: boolean,
    public ultima_ordem?: boolean,
  ) {
    super();
  }

  static converteJson(json: any): OrdemServico {
    return Object.assign(new OrdemServico(), json);
  }
}
