import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { FieldsetModule } from 'primeng/fieldset';
import { ToastModule } from 'primeng/toast';
import { SelecionarEstoqueUsuarioDlgComponent } from './selecionar-estoque-usuario-dlg/selecionar-estoque-usuario-dlg.component';

@NgModule({
  declarations : [SelecionarEstoqueUsuarioDlgComponent],
  exports : [SelecionarEstoqueUsuarioDlgComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    CardModule,
    FieldsetModule
  ]
})
export class EstoqueConsultaModule { }
