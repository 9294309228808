import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, FichaExtra, Page } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class FichaExtraService extends BaseResourceService<FichaExtra> {

  constructor(
    protected injector: Injector
  ) {
    super(`fichas-extras`, injector);
  }

  obterConsignacoesEmAberto(orgao_id: number, exercicio_id: number, ordenacao: string, data_aberto: string, empenho_inicial: number, empenho_final: number, ficha_inicial: number, ficha_final: number): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/consignacoes-em-aberto?orgao=${orgao_id}&exercicio=${exercicio_id}&ordenacao=${ordenacao}&dataAberto=${data_aberto}&empenho_inicial=${empenho_inicial}&empenho_final=${empenho_final}&ficha_inicial=${ficha_inicial}&ficha_final=${ficha_final}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterFichaControle(orgao_id: number, exercicio_id: number, data1: string, data2: string, ficha1: number, ficha2: number): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/ficha-controle-extra?orgao=${orgao_id}&exercicio=${exercicio_id}&data1=${data1}&data2=${data2}&ficha1=${ficha1}&ficha2=${ficha2}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterPorNome(filtro: string, orgao: number, exercicio: number, pagCorrente?: number, pagLimite?: number): Observable<Page> {
    if (!Number.isInteger(pagLimite) || !Number.isInteger(pagCorrente)) {
      pagCorrente = 1;
      pagLimite = 5000;
    }
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?nome$like=${filtro}%&exercicio_id=${exercicio}&orgao_id=${orgao}&relations=plano`,
      this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterPorNumero(ficha: number, orgaoId: number, exercicioId: number, especie: string): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?numero=${ficha}&orgao_id=${orgaoId}&exercicio_id=${exercicioId}&especie=${especie}&relations=plano,recurso,aplicacao,aplicacao_variavel`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterUltimaFicha(exercicioId: number, orgaoId: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/ultima-ficha/${exercicioId}/${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  balancete(filtros: {}): Observable<Array<any>> {
    let parametros = '';
    if (filtros) {
      // tslint:disable-next-line: forin
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + filtros[key];
      }
    }
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/balancete-extra${parametros}`).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
