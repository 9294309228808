import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseResourceFormComponent, FavorecidoTipo, FuncaoService, GlobalService, LoginContabil } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { FavorecidoTipoService } from 'administrativo-lib';

@Component({
  selector: 'lib-favorecido-tipo-form',
  templateUrl: './favorecido-tipo-form.component.html'
})
export class FavorecidoTipoFormComponent extends BaseResourceFormComponent<FavorecidoTipo, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @ViewChild('codigo') inputField: ElementRef;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected favorecidoTipoService: FavorecidoTipoService) {
    super(new FavorecidoTipo(), injector, FavorecidoTipo.converteJson, favorecidoTipoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      tce: [null, [Validators.required]],
      nome: [null, [Validators.required]],
      obrigatorio: [true, [Validators.required]],
      cidade: [this.login.cidade, [Validators.required]],
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'cidade' };
  }

  protected afterLoad() {
  }

  protected afterInit(): void {
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected beforeSubmit() {
    try {
      if (this.entidadeForm.get('nome').value === '') {
        throw new Error('Informe a descricao!');
      }
      this.entidadeForm.get('cidade').setValue(this.login.cidade);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(ent: FavorecidoTipo) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
