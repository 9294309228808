import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { Convenio } from './convenio.model';

// tslint:disable: variable-name
export class ConvenioReajuste extends BaseResourceModel {
  constructor(
    public data_reajuste?: Date,
    public data_contabilizacao?: Date,
    public especie?: 'R' | 'E' | 'X',
    public observacao?: string,
    public tipo_lancamento?: string,
    public valor_reajuste?: number,
    public ajuste_saldo?: boolean,
    public convenio?: Convenio,
    public usuario?: Usuario,
    public saldo?: number, // atributo apenas de consulta
  ) {
    super();
  }
  static converteJson(json: any): ConvenioReajuste {
    return Object.assign(new ConvenioReajuste(), json);
  }
}
