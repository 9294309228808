import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ParametroAlmoxarifado } from '../../../entidade/almoxarifado/parametro-almoxarifado.model';
import { LoginContabil } from '../../../entidade/login/login-contabil';
import { BaseResourceService } from '../../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class ParametroAlmoxarifadoService extends BaseResourceService<ParametroAlmoxarifado> {

  constructor(
    protected injector: Injector
  ) {
    super(`almoxarifado-parametros`, injector);
  }

  public obterParametro(estoque: number, exercicio: number): Observable<ParametroAlmoxarifado> {
    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/obter-parametro/${estoque}/${exercicio}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public async mesFechadoAlmoxarifadoa(login: LoginContabil, estoque_id: number): Promise<boolean> {
    const meses: string[] = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];
    const parametros = await this.obterParametro(estoque_id, login.exercicio.id).toPromise();
    return parametros[meses[login.mesReferencia]];
  }

}
