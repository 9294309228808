import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BaseResourceItemsComponent,
  Contrato,
  ContratoAditamento,
  ContratoReajuste,
  DateFormatPipe,
  FuncaoService,
  GlobalService
} from 'eddydata-lib';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ContratoReajusteService } from '../service/contrato-reajuste.service';

declare var $: any;

@Component({
  selector: 'lib-contrato-reajuste',
  templateUrl: './contrato-reajuste.component.html'
})
export class ContratoReajusteComponent extends BaseResourceItemsComponent<ContratoReajuste> {

  @Input() public contrato: Contrato;
  @Input() public data_contabilizacao: Date;
  @Output() callback: EventEmitter<void> = new EventEmitter();
  @Input() visualizarC: Boolean;

  public ptBR: any;
  public itemAtual: ContratoReajuste;
  public inclusao: boolean;
  public visualizar_item: boolean;
  public permite_item: boolean;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    protected globalService: GlobalService,
    public funcaoService: FuncaoService,
    private reajusteService: ContratoReajusteService) {
    super(new ContratoReajuste(), reajusteService);
  }

  protected afterInit(): void {
    this.ptBR = this.globalService.obterDataBR();
  }

  protected validSave(item: ContratoReajuste): boolean {
    if (!item.data_reajuste) {
      toastr.warning('Informe a data de contabilização!');
      return false;
    }
    if (!this.funcaoService.podeAlterarAudesp(item.data_reajuste, this.login)) {
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return false;
    }

    if (!this.funcaoService.podeAlterarAudesp(item.data_contabilizacao, this.login) || this.funcaoService.podeAlterarAudesp(item.data_reajuste, this.login)) {
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return false;
    }
    return true;
  }

  protected beforeSave(item: ContratoReajuste): void { }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public adicionar() {
    this.visualizar_item = false;
    this.itemAtual = new ContratoReajuste();
    this.itemAtual.data_reajuste = this.funcaoService.obterDataUTC(new Date());
    this.itemAtual.especie = 'R';
    this.itemAtual.data_contabilizacao = this.funcaoService.obterDataUTC(new Date());
    this.itemAtual.usuario = this.login.usuario;
    this.inclusao = true;
    $('#dialogReajuste').modal('show');
  }

  public visualizar(item: ContratoReajuste) {
    this.visualizar_item = true;
    this.itemAtual = item;

    this.itemAtual.data_reajuste = new DateFormatPipe().transform(item.data_reajuste, []);
    this.itemAtual.data_contabilizacao = new DateFormatPipe().transform(item.data_contabilizacao, []);
    this.itemAtual.data_reajuste = new DateFormatPipe().transform(item.data_reajuste, []);
    $('#dialogReajuste').modal('show');
  }

  public editar(item: ContratoReajuste) {
    this.visualizar_item = false;
    this.itemAtual = item;
    // this.entity.editavel = true;
    this.itemAtual.data_reajuste = new DateFormatPipe().transform(item.data_reajuste, []);
    this.itemAtual.data_contabilizacao = new DateFormatPipe().transform(item.data_contabilizacao, []);
    this.itemAtual.data_reajuste = new DateFormatPipe().transform(item.data_reajuste, []);
    $('#dialogReajuste').modal('show');
  }

  public atualizarLista() {
    this.reajusteService.filtrar(0, -1, {
      'contrato_item.contrato.id': this.contrato.id,
      relations: 'contrato_item.produto_unidade.produto.material,contrato_item.contrato.orgao'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((data: any) => {
        this.lista = data ? data.content : new Array<ContratoReajuste>();
        this.callback.emit();
      }, error => this.funcaoService.acaoErro(error));
  }

  public podeVisualizar() {
    if (this.visualizarC === true) {
      return true;
    }
    if(this.visualizarC === false){
      return false;
    }
  }

  public condicaoEdicao(item: ContratoReajuste): Boolean {
    const diasBloqueio = this.login.parametro.contabil.dias_bloquear_alteracoes
    const dataAudesp = this.login.ultimoAudesp
    const dataContabilizacao = this.funcaoService.zerarHoras(new Date(item.data_contabilizacao))


    const dataLimiteSuperior = this.funcaoService.adicionarDia(diasBloqueio, this.funcaoService.zerarHoras(new Date()))
    const dataLimiteInferior = this.funcaoService.adicionarDia(diasBloqueio, this.funcaoService.zerarHoras(new Date()), false, true)

    if (dataContabilizacao >= dataLimiteSuperior || dataContabilizacao <= dataLimiteInferior) {
      return false
    } else {
      const mes = +dataAudesp.split('-')[1]
      const ano = +dataAudesp.split('-')[0]

      if ((dataContabilizacao.getFullYear() < ano || (dataContabilizacao.getFullYear() === ano && (+dataContabilizacao.getMonth() + 1) <= mes)) && mes < 12) {
        return false
      } else {
        return true
      }
    }
  }
}