import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Licitacao, PregaoHistorico } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RPLicitacaoService extends BaseResourceService<Licitacao> {

  constructor(
    protected injector: Injector
  ) {
    super(`licitacoes`, injector);
  }

  public obterPorNumero(numero: number, ano: number, orgaoId: number): Observable<Licitacao> {
    return this.http.get<Licitacao>(
      `${this.login.cidade.id}/${this.api}/filtrar?numero=${numero}&exercicio.ano=${ano}&orgao_id=${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public proximoNumero(ano: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-numero/${ano}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalizarPorModalidade(exercicio: number, orgaoId: number, modalidade: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/total-modalidade/${exercicio}/${orgaoId}/${modalidade}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public licitacoesVigentes(exercicio: number, orgaoId: number, modalidade?: number): Observable<{}[]> {
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/tce-vigentes/${exercicio}/${orgaoId}${modalidade ? `?modalidade=${modalidade}` : ''}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public salvarHistoricoPregao(ent: Licitacao): Observable<PregaoHistorico[]> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/historico-pregao`, ent, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public removerLicitacao(ent: Licitacao): Observable<Licitacao> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/remover`, ent, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }
}
