import { Component, Injector } from '@angular/core';
import { BaseResourceListComponent, Produto, Filtro, Coluna, FormatoExportacao, ProdutoLocalizacao, LoginPublico } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProdutoLocalizacaoService } from '../service/produto-localizacao.service';

@Component({
  selector: 'lib-produto-localizacao-list',
  templateUrl: './produto-localizacao-list.component.html'
})
export class ProdutoLocalizacaoListComponent extends BaseResourceListComponent<ProdutoLocalizacao, LoginPublico> {

  /**
   * Declaração de variáveis
   */

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private produtoService: ProdutoLocalizacaoService) {
    super(produtoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(_entidade: ProdutoLocalizacao): boolean {
    return true;
  }

  protected relations(): string {
    return 'produto';
  }

  protected condicoesGrid(): {} {
    return { 'estoque.id': this.login.setorAlmoxarifado.estoque.id, 'estoque.orgao.id': this.login.orgao.id };
  }

  protected ordenacaoGrid(): string[] {
    return ['id$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['id'],
      text: ['sala', 'corredor', 'armario', 'prateleira', 'pallet', 'posicao', 'produto.nome'],
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: Produto): Observable<Produto> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Sala', coluna: 'sala' },
      { titulo: 'Armário', coluna: 'armario' },
      { titulo: 'Prateleira', coluna: 'prateleira' },
      { titulo: 'Produto', coluna: 'produto.nome' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'produto';
    parametros['orgao_id'] = this.login.orgao.id;
    this.produtoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE LOCALIZAÇÕES`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem de licalizações', ['auto', 'auto', 'auto', '*'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

}

