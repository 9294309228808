import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MaterialService, ProdutoService, RequisicaoItemMovimentoService } from 'almoxarifado-lib';
import {
  Aliquota,
  BaseResourceFormComponent, CodigoMercadoriaBB, EddyAutoComplete, FavorecidoProduto,
  FuncaoService, GlobalService, LoginContabil, Material, Produto,
  ProdutoUnidade, Tabela1Reinf
} from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import * as toastr from 'toastr';
import { ProdutoLocalizacaoService } from '../../produto-localizacao/service/produto-localizacao.service';
import { CodigoMercadoriaService } from '../../produto/service/codigo-mercadoria.service';
import { MemorialService, MovimentoItemEstoqueService, TombamentoService } from 'administrativo-lib';
import { CompraItemService } from '../../compra/service/compra-item.service';
import { RcmsItemService } from '../../rcms/service/rcms-item.service';
import { MemorialItemService } from '../../memorial-item/service/memorial-item.service';
import { RplItemService } from '../../rpl/service/rpl-item.service';
import { ContratoItemService } from '../../contrato/service/contrato-item.service';
import { AliquotaService } from 'contabil-lib';

@Component({
  selector: 'lib-servico-form',
  templateUrl: './servico-form.component.html'
})
export class ServicoFormComponent extends BaseResourceFormComponent<Produto, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public visualizarGrupo: boolean = false;

  public materialAutoComplete: EddyAutoComplete<Material>;
  public codigoMercadoriaComplete: EddyAutoComplete<CodigoMercadoriaBB>;
  public aliquotaAutoComplete: EddyAutoComplete<Aliquota>
  public aliquotaMaterial: Aliquota
  public unidades: ProdutoUnidade[];
  public favorecidos: FavorecidoProduto[];
  public codigoMercadoria: CodigoMercadoriaBB;
  public mes: string;
  public servicos: string[] = [];
  public gruposReinf: Array<any> = new Array<any>();
  public grupoReinf: any;

  @ViewChild('codigo_') inputField: ElementRef;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected materialService: MaterialService,
    protected produtoService: ProdutoService,
    protected tabela1ReinfService: Tabela1Reinf,
    private codigoMercadoriaService: CodigoMercadoriaService,
    private confirmationService: ConfirmationService,
    protected localizacaoService: ProdutoLocalizacaoService,
    protected aliquotaService: AliquotaService,
    protected estoqueItemService: MovimentoItemEstoqueService,
    protected compraItemService: CompraItemService,
    protected rcmsItemService: RcmsItemService,
    protected memorialItemService: MemorialItemService,
    protected memorialService: MemorialService,
    protected rplItemService: RplItemService,
    protected contratoItemService: ContratoItemService,
    protected tombamentoService: TombamentoService,
    protected requisicaoItemService: RequisicaoItemMovimentoService,
  ) {
    super(new Produto(), injector, Produto.converteJson, produtoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      codigo: [null, [Validators.required]],
      nome: [null, [Validators.required]],
      descricao: [null, [Validators.required]],
      material: [null, [Validators.required]],
      bec: [false, [Validators.required]],
      localizacao: [null],
      financeiro_min: [0],
      financeiro_max: [0],
      estoque_min: [0],
      estoque_max: [0],
      unidades: [],
      referencias: [null],
      inativo: [false],
      desabilita_compra: [false],
      favorecidos: [],
      codigo_reinf: [null],
      cod_mercadoria: [],
      orgao: [this.login.orgao, [Validators.required]],
      aliquota: [null]
    });
  }

  protected async podeAlterar(item: Produto): Promise<boolean> {
    return true;
  }

  protected parametrosExtras(): {} {
    return {
      relations: ['material.sub_grupo.grupo,unidades.unidade', 'localizacao', 'referencias', 'favorecidos', 'favorecidos.favorecido', 'aliquota', 'material.aliquota'],
      'material.servico': true
    };
  }

  protected afterLoad() {
    this.materialAutoComplete.id = this.entidade.material ? this.entidade.material.id : null;
    this.unidades = this.entidade.unidades;
    this.favorecidos = this.entidade.favorecidos;

    this.aliquotaMaterial = this.entidade.material.aliquota ? this.entidade.material.aliquota : this.entidadeForm.get('material').value?.aliquota
    this.carregarTabela1Reinf()
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
    this.carregarTabela1Reinf()
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  public caracterEspecial() {
    this.entidadeForm.get('nome').setValue(this.entidadeForm.get('nome').value.replace('’', '').replace("'", '').replace('´', '').replace("''", ''));
  }

  protected async beforeSubmit() {
    try {
      if (this.codigoMercadoria) {
        this.entidadeForm.get('cod_mercadoria').setValue('' + this.codigoMercadoria.codigo);
      }
      if (this.entidadeForm.get('nome').value === '') {
        throw new Error('Informe o nome do serviço!');
      }
      if (!this.entidadeForm.get('material').value) {
        throw new Error('Informe o grupo do serviço!');
      }
      this.entidadeForm.get('unidades').setValue(this.unidades);
      this.entidadeForm.get('favorecidos').setValue(this.favorecidos);

      if (this.entidade.id && !this.entidade.inativo && this.entidadeForm.get('inativo').value && await this.buscaVinculo(this.entidadeForm.value)) {
        throw new Error('Este produto possui vinculo com outros processos (Compra, Contrato...) e não pode ser inativado.\n\nSe deseja que não haja novas compras, ative o "Desabilitado para Compras"')
      }
    } catch (e) {
      this.funcaoService.acaoErro(e)
      throw e;
    }
  }

  protected afterSubmit(modelo: Produto) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
  private carregarAutoCompletes() {
    this.materialAutoComplete = new EddyAutoComplete(null, this.materialService,
      'id', ['codigo', 'nome'], { orgao_id: this.login.orgao.id, relations: 'sub_grupo.grupo', orderBy: 'nome', servico: true }, { number: ['codigo'], text: ['nome'] }, null, null, (entidade) => {
        return `${entidade.sub_grupo.grupo.codigo}.${entidade.sub_grupo.codigo}.${entidade.codigo} - ${entidade.nome}`;
      }
    );

    this.codigoMercadoriaComplete = new EddyAutoComplete(this.entidadeForm.get('cod_mercadoria'), this.codigoMercadoriaService,
      'codigo', ['codigo'], null, { text: ['codigo', 'nome'] }
    );

    this.aliquotaAutoComplete = new EddyAutoComplete(this.entidadeForm.get('aliquota'), this.aliquotaService, 'id', ['id', 'resumo'], { ativo: true }, { number: ['id', 'codigo_receita'], text: ['resumo'] }, null, null, (entidade) => `${entidade.id} - ${entidade.resumo}`)

  }

  public selecionarGrupo(material: Material) {
    this.entidadeForm.get('material').setValue(material);
    this.selectMaterial();
  }

  public selectMaterial() {
    if (this.currentActionRoute === 'novo') {
      this.produtoService.proximoCodigo(this.entidadeForm.get('material').value.id, false, this.login.orgao.id)
        .subscribe((data) => {
          this.entidadeForm.get('codigo').setValue(data);
        });
    }
    const material = this.entidadeForm.get('material').value

    for (let g of this.gruposReinf) {
      if (g.codigo == (material.codigo_reinf / 1000).toFixed(0)) {
        this.grupoReinf = g;
        this.entidadeForm.get('codigo_reinf').setValue(material.codigo_reinf)
        continue;
      }
    }
  }

  public visualizaCodigo() {
    let codigo = this.entidadeForm.get('codigo').value;
    let material: Material = this.entidadeForm.get('material').value;
    if (!codigo)
      return '00.00.00.00';
    if (!material)
      return '00.00.00.00';
    return `${material?.sub_grupo.grupo.codigo}.${material?.sub_grupo.codigo}.${material?.codigo}.${codigo}`
  }


  public remover() {
    this.confirmationService.confirm({
      message: `Removendo o serviço as unidades vinculdas serão removidas.Deseja remover o serviço ${this.entidade.nome}?`,
      header: `Remover serviço`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      key: 'produto',
      accept: () => {
        this.produtoService.remover(this.entidade.id)
          .subscribe((data) => {
            toastr.success(`Serviço removido com sucesso.`);
            this.router.navigate(['/servicos']);
          }, error => {
            if (error && error.error && error.error.payload.includes('violates foreign key'))
              toastr.error(`Serviço possui vinculo por isso não pode ser excluido.`);
            else
              toastr.error(error.error.payload);
          });
      }
    });
  }

  public carregarServicos(event) {
    const query = event.query;
    if (!query)
      return;
    const likes = query.split(' ');
    this.produtoService.buscarProdutosSemelhanes(1, 10, likes, 'N', this.login.orgao.id, this.entidade?.id ? { produtoId: this.entidade.id } : null)
      .subscribe((data) => {
        if (data?.content && data.content.length > 0)
          this.servicos = data.content.map((x) => x.nome);
        else
          this.servicos = [];
      })
  }

  public carregarTabela1Reinf() {
    this.gruposReinf = this.tabela1ReinfService.carregarTabela1();
    if (this.entidade?.codigo_reinf) {
      this.grupoReinf = this.tabela1ReinfService.carregarPorCodigo(this.entidade.codigo_reinf, false);
      for (let i = 0; i < this.gruposReinf.length; i++) {
        if (this.gruposReinf[i].codigo == this.grupoReinf.codigo) {
          this.gruposReinf[i] = this.grupoReinf;
          break;
        }
      }
    }
  }

  public async buscaVinculo(produto: Produto) {
    const estoqueItem = await this.estoqueItemService.obter({ relations: 'produto_unidade', 'produto_unidade.produto_id': produto.id }).toPromise()
    const compraItem = await this.compraItemService.obter({ relations: 'produto_unidade', 'produto_unidade.produto': produto.id }).toPromise()
    const rcmsItem = await this.rcmsItemService.obter({ relations: 'produto_unidade', 'produto_unidade.produto_id': produto.id }).toPromise()
    const memorialItem = await this.memorialItemService.obter({ produto_id: produto.id }).toPromise()
    const memorial = await this.memorialService.obter({ relations: 'produto_unidade', 'produto_unidade.produto_id': produto.id }).toPromise()
    const rplItem = await this.rplItemService.obter({ relations: 'produto_unidade', 'produto_unidade.produto_id': produto.id }).toPromise()
    const contratoItem = await this.contratoItemService.obter({ relations: 'produto_unidade', 'produto_unidade.produto_id': produto.id }).toPromise()
    const tombamento = await this.tombamentoService.obter({ produto_id: produto.id, orgao_id: this.login.orgao.id }).toPromise()
    const requisicaoItem = await this.requisicaoItemService.obter({ relations: 'produto_unidade', 'produto_unidade.produto_id': produto.id }).toPromise()

    if (estoqueItem || compraItem || rcmsItem || memorialItem || memorial || rplItem || contratoItem || tombamento || requisicaoItem) return true
    else return false
  }

}
