import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, FavorecidoObservacao } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class FavorecidoObservacaoService extends BaseResourceService<FavorecidoObservacao> {

  constructor(
    protected injector: Injector
  ) {
    super(`favorecido-observacoes`, injector);
  }

}
