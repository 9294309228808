import { DatePipe } from '@angular/common';

import { FuncaoService, Login, Relatorio, GlobalService, Liquidacao } from 'eddydata-lib';

export class TransferenciaBancariaRpt {

  protected datepipe: DatePipe;
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;

  private log: Login;
  private data1: Date;
  private data2: Date;

  constructor() {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.datepipe = new DatePipe('pt');
  }

  public async imprimir(model: Liquidacao[], login: Login, data1: Date, data2: Date) {
    
    this.log = login;
    this.data1 = data1;
    this.data2 = data2;

    Relatorio.imprimirPersonalizado('LISTAGEM DE TRANSFERÊNCIA FINANCEIRA', login.usuario.nome, login.usuario.sobrenome,
      login.orgao.nome, login.brasao,
      this.montarConteudo(model),
      'landscape', 'LISTAGEM DE TRANSFERÊNCIA TRANSFERÊNCIA',
      {
        linhas: {
          hLineWidth(i, node) {
            return 1;
          },
          vLineWidth(i) {
            return 1;
          },
          hLineColor(i) {
            return 'black';
          },
          paddingLeft(i) {
            return 3;
          },
          paddingRight(i, node) {
            return 3;
          }
        }
      }, true);
  }

  private montarConteudo(lista: any[]) {

    const conteudo = [];

    conteudo.push(
      this.dadosCabecalho(this.log)
        .concat(this.dadosDetalhe(lista))
    );
    return conteudo;
  }

  private dadosCabecalho(log: Login): {}[] {
    let brasaoImage: {};
    if (log.brasao) {
      brasaoImage = {
        image: log.brasao,
        width: 70,
        alignment: 'center',
        margin: [0, 30, 0, 0]
      };
    } else {
      brasaoImage = { margin: [0, 30, 0, 0], text: '' };
    }
    const conteudo = [
      [{ text: brasaoImage, border: [false, false, false, false] }],
      [{ text: log.orgao.nome, bold: true, alignment: 'center', fontSize: 13, border: [false, false, false, false] }],
      [{
        text: 'DOCUMENTO: LISTAGEM DE TRANSFERÊNCIAS BANCÁRIAS',
        bold: true, alignment: 'left', fontSize: 12, border: [false, false, false, false]
      }],
      [{
        text: `PERÍODO: ${this.datepipe.transform(this.data1, 'dd/MM/yyyy')} - ${this.datepipe.transform(this.data2, 'dd/MM/yyyy')}`,
        alignment: 'left', fontSize: 12, border: [false, false, false, false]
      }]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosDetalhe(dados: any): {}[] {
    const conteudo = [
      [
        { text: 'DATA', rowSpan: 2, fontSize: 8, alignment: 'center', bold: true, margin: [0, 5, 0, 0] },
        { text: 'DOCUMENTO', rowSpan: 2, fontSize: 8, alignment: 'center', bold: true, margin: [0, 5, 0, 0] },
        { text: 'CONTA PAGADORA', colSpan: 3, fontSize: 8, alignment: 'center', bold: true, margin: [0, 5, 0, 0] },
        { text: '' },
        { text: '' },
        { text: 'CONTA RECEBEDORA', colSpan: 3, fontSize: 8, alignment: 'center', bold: true, margin: [0, 5, 0, 0] },
        { text: '' },
        { text: '' },
        { text: 'VALOR', rowSpan: 2, fontSize: 8, alignment: 'center', bold: true, margin: [0, 5, 0, 0] },
      ],
      [
        { text: '' },
        { text: '' },       
        { text: 'AGÊNCIA', fontSize: 8, alignment: 'center', bold: true, colSpan: 1 },
        { text: 'CONTA', fontSize: 8, alignment: 'center', bold: true, colSpan: 1 },
        { text: 'DESCRIÇÃO', fontSize: 8, alignment: 'center', bold: true, colSpan: 1 },
        { text: 'AGÊNCIA', fontSize: 8, alignment: 'center', bold: true },
        { text: 'CONTA', fontSize: 8, alignment: 'center', bold: true, colSpan: 1 },
        { text: 'DESCRIÇÃO', fontSize: 8, alignment: 'center', bold: true },
        { text: '' },
      ]
    ];

    let total = 0;
    for (const item of dados) {
      total += +item.valor_transferido;
      conteudo.push(
        [
          { text: this.datepipe.transform(item.data_transfere, 'dd/MM/yyyy'), fontSize: 8, alignment: 'center', bold: false },
          { text: item.documento },
          { text: `${item.conta_pagadora.agencia} ${item.conta_pagadora.digito_agencia}`, fontSize: 8, alignment: 'left', bold: false },
          { text:`${item.conta_pagadora.numero_conta} ${item.conta_pagadora.digito_conta}`, fontSize: 8, alignment: 'left', bold: false },
          { text: item.conta_pagadora.nome, fontSize: 8, alignment: 'left', bold: false },
          { text: `${item.conta_recebedora.agencia} ${item.conta_recebedora.digito_agencia}`, fontSize: 8, alignment: 'left', bold: false },
          { text:`${item.conta_recebedora.numero_conta} ${item.conta_recebedora.digito_conta}`, fontSize: 8, alignment: 'left', bold: false },
          { text: item.conta_recebedora.nome, fontSize: 8, alignment: 'left', bold: false },
          { text: this.funcaoService.convertToBrNumber(item.valor_transferido), fontSize: 8, alignment: 'right', bold: false },
        ]
      );
    }


    conteudo.push(
      [
        { text: 'TOTAL', colSpan: 8, fontSize: 8, alignment: 'left', bold: true },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: this.funcaoService.convertToBrNumber(total), fontSize: 8, alignment: 'right', bold: true },
      ]
    );

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [50, 90, 40, 55, '*', 40, 55, '*', 65],
        body: conteudo
      }
    }];
  }


}
