// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { ExpedienteLegislacao } from './expediente-legislacao.model';
import { Orgao } from '../comum/orgao.model';

export class Expediente extends BaseResourceModel {
    constructor(
      public data_expediente?: Date,
      public observacao?: string,
      public usuario?: Usuario,
      public orgao?: Orgao,
      public documentos?: Array<ExpedienteLegislacao>,
    ) {
      super();
    }
    static converteJson(json: any): Expediente {
      return Object.assign(new Expediente(), json);
    }
}
