import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido } from '../compra/favorecido.model';
import { FaturaTipoImovel } from './fatura-tipo-imovel.model';

export class FaturaTipoImovelFavorecido extends BaseResourceModel {
    constructor(
        public fatura_tipo_imovel?: FaturaTipoImovel,
        public favorecido?: Favorecido,
        public ativo?: boolean,
        public justificativa?: string
    ) {
        super();
    }

    static converteJson(json: any): FaturaTipoImovelFavorecido {
        return Object.assign(new FaturaTipoImovelFavorecido(), json);
    }
}
