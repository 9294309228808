import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FuncaoService, Login, RcmsFavorecido } from 'eddydata-lib';

declare var $: any;

@Component({
  selector: 'app-importar-rcms-itens-dlg',
  templateUrl: './importar-rcms-itens-dlg.component.html'
})
export class ImportarRcmsItensDlgComponent implements OnInit {

  @Input() login: Login;

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Input() public favorecido: RcmsFavorecido;

  constructor(public funcaoService: FuncaoService) { }

  ngOnInit(): void { }

  public titulo() {
    if (this.favorecido)
      return `Itens da OF. ${("00000" + this.favorecido['numero_compra']).slice(-5)}/${this.login.exercicio.ano}`;
    return '';
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

}