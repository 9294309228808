import { TipoArquivoFase4 } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { LicitacaoAudesp } from './licitacao-audesp.model';

export class LicitacaoAudespPacote extends BaseResourceModel {
  constructor(
    public id?: number,
    public tipo?: TipoArquivoFase4,
    public enviado?: boolean,
    public orgao?: Orgao,
    public arquivos?: LicitacaoAudesp[],
  ) {
    super();
  }

  static converteJson(json: any): LicitacaoAudespPacote {
    return Object.assign(new LicitacaoAudespPacote(), json);
  }
}
