import { Component, Input } from '@angular/core';
import {
  BaseResourceItemsComponent,
  Convenio,
  ConvenioReajuste,
  DateFormatPipe,
  FuncaoService,
  GlobalService
} from 'eddydata-lib';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ConvenioReajusteService } from '../service/convenio-reajuste.service';

declare var $: any;

@Component({
  selector: 'lib-convenio-reajuste',
  templateUrl: './convenio-reajuste.component.html'
})
export class ConvenioReajusteComponent extends BaseResourceItemsComponent<ConvenioReajuste> {

  @Input() public convenio: Convenio;
  @Input() public data_contabilizacao: Date;

  public ptBR: any;
  public itemAtual: ConvenioReajuste;
  public inclusao: boolean;
  public visualizar_item: boolean;
  public permite_item: boolean;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    protected globalService: GlobalService,
    public funcaoService: FuncaoService,
    private reajusteService: ConvenioReajusteService) {
    super(new ConvenioReajuste(), reajusteService);
  }

  protected afterInit(): void {
    this.ptBR = this.globalService.obterDataBR();
  }

  protected validSave(item: ConvenioReajuste): boolean {
    if (!item.data_reajuste) {
      toastr.warning('Informe a data de contabilização!');
      return false;
    }
    if (!this.funcaoService.podeAlterarAudesp(item.data_reajuste, this.login)) {
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return false;
    }
    return true;
  }

  protected beforeSave(item: ConvenioReajuste): void { }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public adicionar() {
    this.visualizar_item = false;
    this.itemAtual = new ConvenioReajuste();
    this.itemAtual.data_reajuste = this.funcaoService.obterDataUTC(new Date());
    this.itemAtual.especie = 'R';
    this.itemAtual.data_contabilizacao = this.funcaoService.obterDataUTC(new Date());
    this.itemAtual.usuario = this.login.usuario;
    this.itemAtual.convenio = this.convenio;
    this.inclusao = true;
    $('#dialogReajuste').modal('show');
  }

  public visualizar(item: ConvenioReajuste) {
    this.visualizar_item = true;
    this.itemAtual = item;
    this.itemAtual.data_reajuste = new DateFormatPipe().transform(item.data_reajuste, []);
    this.itemAtual.data_contabilizacao = new DateFormatPipe().transform(item.data_contabilizacao, []);
    this.itemAtual.data_reajuste = new DateFormatPipe().transform(item.data_reajuste, []);
    $('#dialogReajuste').modal('show');
  }

  public editar(item: ConvenioReajuste) {
    this.visualizar_item = false;
    this.itemAtual = item;
    // this.entity.editavel = true;
    this.itemAtual.data_reajuste = new DateFormatPipe().transform(item.data_reajuste, []);
    this.itemAtual.data_contabilizacao = new DateFormatPipe().transform(item.data_contabilizacao, []);
    this.itemAtual.data_reajuste = new DateFormatPipe().transform(item.data_reajuste, []);
    $('#dialogReajuste').modal('show');
  }

  public atualizarLista() {
    this.reajusteService.filtrar(0, -1, {
      'convenio.id': this.convenio.id,
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((data: any) => {
        this.lista = data ? data.content : new Array<ConvenioReajuste>();
      }, error => this.funcaoService.acaoErro(error));
  }

  public condicaoEdicao(item: ConvenioReajuste): Boolean {
    const hoje = new Date()
    if (hoje < item.data_contabilizacao || item.data_reajuste < item.data_contabilizacao) {
      return false
    } else {
      return true
    }
  }

}