import { Component, OnInit, OnChanges, Input, ViewChild, ElementRef, SimpleChanges, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConvenioComissaoService } from './service/convenio-comissao.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ConvenioComissao, Convenio, Login, FuncaoService } from 'eddydata-lib';

@Component({
  selector: 'app-convenio-comissao',
  templateUrl: './convenio-comissao.component.html',
})
export class ConvenioComissaoComponent implements OnInit, OnChanges, OnDestroy {
  
  public entity: ConvenioComissao;
  public entityTemp: ConvenioComissao;
  public listaLocais: Array<any>;
  protected unsubscribe: Subject<void> = new Subject();

  @Input() lista: Array<any>;
  @Input() entidade: Convenio;
  @Input() login: Login;

  @ViewChild('btnAdicionar') public btnAdicionar: ElementRef;
  @ViewChildren('btnsEditar') public btnsEditar: QueryList<ElementRef>;

  constructor(
    private messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected funcaoService: FuncaoService,
    protected criterioService: ConvenioComissaoService,
  ) { }

  ngOnInit() {
    this.listaLocais = this.lista;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lista) {
      this.listaLocais = this.lista;
    }
  }

  private desabilitarEdicao(desabilitar: boolean) {
    this.btnsEditar.forEach((btn) => btn.nativeElement.disabled = desabilitar)
    this.btnAdicionar.nativeElement.disabled = desabilitar;
  }

  public adicionar() {
    this.entity = new ConvenioComissao();
    this.entity.convenio = this.entidade;
    this.entity.editavel = true;
    this.entity['salvo'] = false;
    if (!this.listaLocais) {
      this.listaLocais = new Array();
    }
    this.listaLocais.unshift(this.entity);
    this.desabilitarEdicao(true);
  }

  public salvar(item: ConvenioComissao) {
    try {
      if (!item.nome) {
        throw new Error('Informe a nome!');
      }
      // envia a entidade para ser salva no banco -----
      item.editavel = false;
      item['salvo'] = true;
      this.desabilitarEdicao(false);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  public editar(item: ConvenioComissao) {
    this.entityTemp = JSON.parse(JSON.stringify(item));

    this.entity = item;
    this.entity.editavel = true;
    this.entity['salvo'] = true;
    this.desabilitarEdicao(true)
  }

  public cancelar(item: ConvenioComissao, index: number) {
    if (item['salvo'] && item.id === this.entityTemp?.id) {
      item.cpf = this.entityTemp.cpf;
      item.nome = this.entityTemp.nome;
      item.cargo = this.entityTemp.cargo;
      item.email = this.entityTemp.email;

      this.entity = item;
      this.entity.editavel = false;
    } else {
      this.listaLocais.splice(index, 1);
    }
    this.desabilitarEdicao(false);
  }

  public confirmarRemocao(item: ConvenioComissao, index: number) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja remover item?',
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.remover(item, index)
      },
    });
  }

  private remover(item: ConvenioComissao, index: number) {
    if (item.id) {
      this.criterioService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaLocais.splice(index, 1);
          this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Exclusão', detail: error.error.payload });
          throw error;
        });
    } else {
      this.listaLocais.splice(index, 1);
    }
  }

}
