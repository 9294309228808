import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, LicitacaoAudespPacote } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LicitacaoAudespPacoteService extends BaseResourceService<LicitacaoAudespPacote> {

    constructor(
        protected injector: Injector
    ) {
        super(`licitacao-audesp-pacotes`, injector);
    }

    public enviarPacote(id: number): Observable<void> {
        return this.http.post(
            `${this.login.cidade.id}/${this.api}/enviar-pacote/${id}`, undefined, this.httpOptions()).pipe(
                map(res => res),
                catchError(err => this.handleError(err))
            );
    }

    public armazenarPacote(id: number): Observable<void> {
        return this.http.post(
            `${this.login.cidade.id}/${this.api}/armazenar-pacote/${id}`, undefined, this.httpOptions()).pipe(
                map(res => res),
                catchError(err => this.handleError(err))
            );
    }
}
