import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuadroEnsinoRoutingModule } from './quadro-ensino-routing.module';
import { QuadroEnsinoComponent } from './quadro-ensino.component';
import { ListboxModule } from 'primeng/listbox';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'eddydata-lib';
import { FieldsetModule } from 'primeng/fieldset';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  declarations: [QuadroEnsinoComponent],
  imports: [
    CommonModule,
    QuadroEnsinoRoutingModule,
    SharedModule,
    FormsModule,
    ListboxModule,
    FieldsetModule,
    RadioButtonModule,
    CalendarModule
  ]
})
export class QuadroEnsinoModule { }
