import { BaseResourceModel } from "../../models/base-resource.model";
import { Exercicio } from "../comum/exercicio.model";
import { Orgao } from "../comum/orgao.model";
import { RecursoTabelaSiops19 } from "./recurso-tabela-siops-19.model";

export class TabelaSiops19 extends BaseResourceModel {
  constructor(
    public bimestre?: number,
    public linha?: number,
    public exercicio?: Exercicio,
    public orgao?: Orgao,
    public recursos?: RecursoTabelaSiops19[],
  ) {
    super();
  }

  static converteJson(json: any): TabelaSiops19 {
    return Object.assign(new TabelaSiops19(), json);
  }
}