import { Injectable, Injector } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseResourceService, Credito } from 'eddydata-lib';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CreditoService extends BaseResourceService<Credito> {

  constructor(
    protected injector: Injector
  ) {
    super(`creditos`, injector);
  }

  public obterPorNumero(numero: number, ano: number, orgaoId: number): Observable<Credito> {
    return this.http.get<Credito>(`${this.login.cidade.id}/${this.api}/filtrar?numero=${numero}&exercicio.ano=${ano}&orgao_id=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public creditoPorcentual(orgao: number, exercicio: number, data_inicial: string, data_final: string, decreto: string, lei: string, criterioPesquisa: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.login.cidade.id}/${this.api}/credito-porcentual/${orgao}/${exercicio}/${data_inicial}/${data_final}/${decreto}/${lei}/${criterioPesquisa}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalCreditado(ocorrencia: string, especie: string, orgao: number, exercicio: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.login.cidade.id}/${this.api}/total-creditado/${ocorrencia}/${especie}/${orgao}/${exercicio}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public buscarCreditosNaoTotalizados(exercicio_id: number, orgao_id: number): Observable<number[]> {
    const params = new HttpParams().appendAll({ exercicio_id, orgao_id });
    const options = { ...this.httpOptions(), params };
    return this.http.get<number[]>(`${this.login.cidade.id}/${this.api}/creditos-nao-totalizados`, options).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
