import { BaseResourceModel } from '../../models/base-resource.model';
import { ContaBancaria } from "./conta-bancaria.model";
import { Contrato } from "../compra/contrato.model";
import { Convenio } from "../compra/convenio.model";
import { RestituicaoReceitaItem } from "./restituicao-receita-item.model";
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';

export class RestituicaoReceita extends BaseResourceModel {
  constructor(
    public id?: number,
    public baixado?: boolean,
    public exportado?: boolean,
    public data_restituicao?: Date,
    public exercicio?: Exercicio,
    public orgao?: Orgao,
    public conta?: ContaBancaria,
    public contrato?: Contrato,
    public convenio?: Convenio,
    public itens?: RestituicaoReceitaItem[],) {
    super();
  }
  static converteJson(json: any): RestituicaoReceita {
    return Object.assign(new RestituicaoReceita(), json);
  }
}