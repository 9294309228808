import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { DepreciacaoItem } from './depreciacao-item.model';
import { Tombamento } from './tombamento.model';

export class Depreciacao extends BaseResourceModel {
  constructor(
    public id?: number,
    public mes?: number,
    public ano?: number,
    public pendente?: boolean,
    public usuario?: Usuario,
    public itens?: DepreciacaoItem[],
    public orgao?: Orgao,
    public filtros?: {
      tombamento?: Tombamento;
      placaInicial?: number;
      placaFinal?: number;
    },
  ) {
    super();
  }

  static converteJson(json: any): Depreciacao {
    return Object.assign(new Depreciacao(), json);
  }
}
