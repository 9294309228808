// tslint:disable: variable-name
import { Processo } from './processo.model';
import { ProcessoArquivo } from './processo-arquivo.model';
import { Usuario } from '../comum/usuario.model';
import { ProcessoHistoricoView } from './processo-historico-view.model';

export class ProcessoHistorico {

    id: number;

    data_limite: Date;

    comentario: string;

    data_cadastro: Date;

    data_alteracao: Date;

    processo: Processo;

    usuario: Usuario;

    arquivos: ProcessoArquivo[];

    views: ProcessoHistoricoView[];
}
