import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Page, BaseResourceService, FuncaoGoverno } from 'eddydata-lib';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FuncaoGovernoService extends BaseResourceService<FuncaoGoverno> {

  constructor(
    protected injector: Injector
  ) {
    super(`funcoes-governo`, injector);
  }

  obterNumeroPorNivel(filtroTexto: string, nivel: number, cidadeId: number, page?: number, limit?: number): Observable<Page> {
    if (!Number.isInteger(limit) || !Number.isInteger(page)) {
      page = 1;
      limit = 1000;
    }
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?codigo$like=${filtroTexto}%&nivel=${nivel}&cidade_id=${cidadeId}`,
      this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterNomePorNivel(filtroTexto: string, nivel: number, cidadeId: number, page?: number, limit?: number): Observable<Page> {
    if (!Number.isInteger(limit) || !Number.isInteger(page)) {
      page = 1;
      limit = 1000;
    }
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?nome$like=${filtroTexto}%&nivel=${nivel}&cidade_id=${cidadeId}`,
      this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public selGraficoEmpenho(id: number, selecionar: boolean, grafico : string): Observable<FuncaoGoverno> {
    const selGrafEmpenho = { id, selecionar, grafico};
    return this.http.post<FuncaoGoverno>(
      `${this.login.cidade.id}/${this.api}/selecionar-grafico`, JSON.stringify(selGrafEmpenho), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }
}
