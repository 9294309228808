import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-cidadao-senha-sucesso',
  templateUrl: './cidadao-senha-sucesso.component.html',
  styleUrls: ['./cidadao-senha-sucesso.component.css']
})
export class CidadaoSenhaSucessoComponent implements OnInit {

  @Input() sistema: string;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  voltar() {
    this.router.navigate([`${this.sistema}/login`]);
  }

}
