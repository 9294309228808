// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { SubGrupoEstoque } from './sub-grupo-estoque.model';
import { Produto } from './produto.model';
import { Orgao } from '../comum/orgao.model';
import { Aliquota } from '../contabil/aliquota.model';

export class Material extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public codigo?: number,
    public contabilizar?: boolean,
    public exigir_placa?: boolean,
    public exigir_incorporacao?: boolean,
    public servico?: boolean,
    public locacao?: boolean,
    public codigo_reinf?: number,
    public bec?: boolean,
    public sub_grupo?: SubGrupoEstoque,
    public produtos?: Produto[],
    public orgao?: Orgao,
    public aliquota?: Aliquota
  ) {
    super();
  }
  static converteJson(json: any): Material {
    return Object.assign(new Material(), json);
  }
}
