import { Component, OnInit, OnDestroy } from '@angular/core';
import * as toastr from 'toastr';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Login } from '../../entidade/login/login';
import { UsuarioService } from '../../usuario/service/usuario.service';
import { LoginService } from '../servico/login.service';
import { Router } from '@angular/router';
import { GlobalService } from '../../util/global.service';
import { FuncaoService } from '../../util/funcao.service';


@Component({
  selector: 'lib-trocar-senha',
  templateUrl: './trocar-senha.component.html'
})
export class TrocarSenhaComponent implements OnInit, OnDestroy {

  public login: Login = new Login();
  public senhaAtual: string;
  public senhaNova: string;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected router: Router,
    private usuarioService: UsuarioService,
    private loginService: LoginService,
    private funcaoservice: FuncaoService
  ) {
  }

  ngOnInit() {
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async enviar() {
    const usuario = await this.usuarioService.obter({ relations: 'usuario_estoques,usuario_estoques.estoque,usuario_setores.setor,orgao', id: this.login.usuario.id }).toPromise()
    usuario.senha = this.senhaAtual
    usuario.senha_nova = this.senhaNova
    this.usuarioService.trocarSenha(usuario).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        toastr.success('Senha foi alterada com sucesso!');
        this.login.usuario.orgao = this.login.orgao;
        this.loginService.desconectar(this.login.usuario);
      }, (error) => {
        this.funcaoservice.acaoErro(error)
      });
    this.senhaAtual = null;
    this.senhaNova = null;
  }

}
