import {
    Assinatura, CpfPipe, FormatoExportacao, FuncaoService, GlobalService, Licitacao, LoginContabil, ProdutoUnidade,
    Rcms,
    RcmsCotacao,
    Relatorio, Rpl, RplCotacao, RplFavorecido, TelefonePipe
} from "eddydata-lib";

export type EstimativaItemRpt = {
    cotacoes: RplCotacao[],
    cotacoesRcms: RcmsCotacao[],
    quantidade: number,
    quantidadeRcms: number,
    quantidade_memorial: number
    produto_unidade: ProdutoUnidade
    valor_referencia: number
    lote: boolean
    itens: EstimativaItemRpt[]
    ordem: number
};

export type EstimativaRpt = {
    itens: EstimativaItemRpt[],
    favorecidos: RplFavorecido[],
    licitacao: Licitacao,
    rpls: Rpl[],
    rcms: Rcms[]
}

export abstract class BaseEstimativaPrecoRpt {


    // ========================================================================
    // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
    // ========================================================================
    protected funcaoService: FuncaoService;
    protected login: LoginContabil;

    protected comAssinatura: boolean = false;
    protected assinatura: Assinatura;

    // ========================================================================
    // ------------------------------ CONSTRUTOR ------------------------------
    // ========================================================================

    constructor() {
        this.funcaoService = new FuncaoService();
        this.login = GlobalService.obterSessaoLogin();
    }

    // ========================================================================
    // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
    // ========================================================================

    protected abstract montarConteudo(estimativa: EstimativaRpt): {}[];
    protected validar(estimativa: EstimativaRpt): boolean {
        return true;
    };

    // ========================================================================
    // -------------------------- MÉTODOS DA CLASSE ---------------------------
    // ========================================================================


    public imprimir(estimativa: EstimativaRpt, comAssinatura: boolean, assinatura: Assinatura, titulo: string, formato: FormatoExportacao) {
        this.comAssinatura = comAssinatura;
        this.assinatura = assinatura;

        if (!this.validar(estimativa))
            return;

        Relatorio.imprimirPersonalizado(titulo, this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            this.montarConteudo(estimativa), 'landscape', titulo, this.layout(), false, false, formato);
    }

    protected totalEstimado = (estimativa: EstimativaRpt) =>
        estimativa.itens.reduce((sum, i) => sum + this.totalMedia(i), 0);

    protected totalMedia(item: EstimativaItemRpt): number {
        const mediaUnitaria = this.totalMediaUnitaria(item);
        return mediaUnitaria * +item.quantidade_memorial;
    }

    protected totalMediaUnitaria(item: EstimativaItemRpt): number {
        let totalUnitario = 0

        if (item.lote && item.itens?.length > 0) {
            for (const itemL of item.itens) {
                totalUnitario += this.cotacoesMedia(itemL)
            }
            return totalUnitario
        } else {
            return this.calculoValorReferencia(item.cotacoes, item.cotacoesRcms); //item.cotacoes.reduce((sumC, c) => sumC + +c.valor_unitario, 0);
            // if (item.cotacoes.length === 0)
            //     return 0;
            // return totalUnitario / item.cotacoes.length;
        }
    }

    public calculoValorReferencia(cotacoes: RplCotacao[], cotacoesRcms: RcmsCotacao[]): number {
        let listaFavorecidos = [];
        let qntFornecedores = 0;
        let somaValorUnitario = 0
        if (cotacoes.length > 0) {
            listaFavorecidos = cotacoes.filter((v, i, a) => +a.findIndex(t => (+t.favorecido.favorecido.id === +v.favorecido.favorecido.id)) === i)

            listaFavorecidos = listaFavorecidos.map(cot => {
                let cotacao = cotacoes.filter(gf => +gf.favorecido.favorecido.id === +cot.favorecido.favorecido.id)
                let contador = 0;
                let valor_unitario = 0;
                cotacao.reduce((acc, interavel) => {
                    if (interavel.valor_unitario > 0) {
                        contador++
                        return valor_unitario += +interavel.valor_unitario
                    } else {
                        return valor_unitario
                    }
    
                }, 0)
                cot['valor'] = +valor_unitario / +contador;
    
                if (!cot.valor_unitario) cot['valor'] = 0;
    
                return cot
            })
        } else {
            listaFavorecidos = cotacoesRcms.filter((v, i, a) => +a.findIndex(t => (+t.rcmsFavorecido.favorecido.id === +v.rcmsFavorecido.favorecido.id)) === i)

            listaFavorecidos = listaFavorecidos.map(cot => {
                let cotacao = cotacoesRcms.filter(gf => +gf.rcmsFavorecido.favorecido.id === +cot.rcmsFavorecido.favorecido.id)
                let contador = 0;
                let valor_unitario = 0;
                cotacao.reduce((acc, interavel) => {
                    if (interavel.valor_unitario > 0) {
                        contador++
                        return valor_unitario += +interavel.valor_unitario
                    } else {
                        return valor_unitario
                    }
    
                }, 0)
                cot['valor'] = +valor_unitario / +contador;
    
                if (!cot.valor_unitario) cot['valor'] = 0;
    
                return cot
            })
        }

        
        for (const [idx, cotacao] of listaFavorecidos.entries()) {
            somaValorUnitario += +cotacao['valor'];
            if (+cotacao['valor'] > 0)
                qntFornecedores++;
        }
        return +(+somaValorUnitario / +qntFornecedores).toFixed(4);
    }

    private cotacoesMedia(item: EstimativaItemRpt): number {

        let somaValorUnitario = 0
        let qntFornecedores = 0

        let listaFavorecidos = item.cotacoes.filter((v, i, a) => +a.findIndex(t => (+t.favorecido.favorecido.id === +v.favorecido.favorecido.id)) === i)

        listaFavorecidos = listaFavorecidos.map(cotacoes => {
            let cotacao = item.cotacoes.filter(gf => +gf.favorecido.favorecido.id === +cotacoes.favorecido.favorecido.id)
            let contador = 0;
            let valor_unitario = 0;
            cotacao.reduce((acc, interavel) => {
                if (interavel.valor_unitario > 0) {
                    contador++
                    return valor_unitario += +interavel.valor_unitario
                } else {
                    return valor_unitario
                }

            }, 0)
            cotacoes.valor_unitario = +valor_unitario / +contador;

            if (!cotacoes.valor_unitario) cotacoes.valor_unitario = 0;

            return cotacoes
        })

        for (const [idx, cotacao] of listaFavorecidos.entries()) {
            somaValorUnitario += +cotacao.valor_unitario;
            if (+cotacao.valor_unitario > 0)
                qntFornecedores++;
        }

        let mediaUni = somaValorUnitario / qntFornecedores;
        let mediaTotal = mediaUni * +item.quantidade_memorial;

        return mediaTotal
    }


    // ========================================================================
    // -------------------------- MONTAR RELATORIOS ---------------------------
    // ========================================================================

    private requisicoes(estimativa: EstimativaRpt): string {
        if(estimativa.rpls.length > 0) {
            return estimativa.rpls.map((r) => `${r.numero} - ${r.exercicio.ano}`).join('; ');
        } 
        else if (estimativa.rcms.length > 0) {
            return estimativa.rcms.map((r) => `${r.numero} - ${r.exercicio.ano}`).join('; ');
        }
        
    }

    protected cabecalho(estimativa: EstimativaRpt): {}[] {
        let cabecalho = []

        const valorEstimado = this.totalEstimado(estimativa);

        cabecalho.push([
            { text: `Requisição: ${this.requisicoes(estimativa)}`, fontSize: 12, bold: true, border: [true, true, true, true], margin: [0, 5, 0, 5], colSpan: 7 },
            '', '', '', '', '', ''
        ]);
        cabecalho.push([
            { text: `Secretaria:`, fontSize: 12, border: [true, false, false, false], bold: true, colSpan: 3 }, '', '',
            { text: `Objeto:`, fontSize: 12, border: [false, false, true, false], bold: true, colSpan: 4 },
            '', '', ''
        ]);
        cabecalho.push([
            { text: `${estimativa.licitacao.setor?.unidade?.nome}`, fontSize: 10, border: [true, false, false, false], colSpan: 3 }, '', '',
            { text: `${estimativa.licitacao.objeto ? estimativa.licitacao.objeto.toUpperCase() : ''}`, fontSize: 10, border: [false, false, true, false], colSpan: 4 },
            '', '', ''
        ]);
        cabecalho.push([
            { text: `Valor estimado do processo:`, fontSize: 12, border: [true, false, false, false], bold: true, colSpan: 2 }, '',
            { text: `${this.funcaoService.convertToBrNumber(valorEstimado, 4)}`, fontSize: 10, border: [false, false, true, false], colSpan: 5 },
            '', '', '', ''
        ]);
        cabecalho.push([{ text: '', border: [true, false, true, false], colSpan: 7 }, '', '', '', '', '', '']);

        cabecalho.push([
            { text: `Fornecedores:`, fontSize: 12, border: [true, false, true, false], bold: true, colSpan: 7 },
            '', '', '', '', '', ''
        ]);
        cabecalho.push([
            { text: 'Razão social', fontSize: 10, border: [true, false, false, false], bold: true, colSpan: 3 }, '', '',
            { text: 'CNPJ/CPF ', fontSize: 10, alignment: 'center', border: [false, false, false, false], bold: true, colSpan: 2 }, '',
            { text: 'Telefone ', fontSize: 10, alignment: 'center', border: [false, false, true, false], bold: true, colSpan: 2 }, ''
        ]);

        let telefonePipe = new TelefonePipe()
        let cpfPipe = new CpfPipe()

        for (let favorecido of estimativa.favorecidos) {
            cabecalho.push([
                { text: `${favorecido.favorecido.nome}`, fontSize: 10, border: [true, false, false, false], colSpan: 3 }, '', '',
                {
                    text: `${favorecido.favorecido.cpf_cnpj.length > 11 ? cpfPipe.transform(favorecido.favorecido.cpf_cnpj) : this.funcaoService.mascarar('##.###.###/####-##', favorecido.favorecido.cpf_cnpj)}`,
                    alignment: 'center', fontSize: 10, border: [false, false, false, false], colSpan: 2
                }, '',
                { text: `${telefonePipe.transform(favorecido.favorecido.telefone)}`, fontSize: 10, border: [false, false, true, false], alignment: 'center', colSpan: 2 }, ''
            ]);
        }

        cabecalho.push([{ text: '', border: [true, false, true, true], colSpan: 7 }, '', '', '', '', '', '']);
        cabecalho.push([{ text: '', border: [false, false, false, false], fontSize: 10, colSpan: 7, margin: [0, 5, 0, 5] }, '', '', '', '', '', '']);

        return cabecalho;
    }

    protected assinaturas(): {}[] {
        const conteudo = [];
        const assinaturas = this.assinatura?.pessoas?.filter(p => p.selecionado);

        conteudo.push([
            { text: ``, fontSize: 12, margin: [0, 10, 0, 0], border: [true, false, false, false] },
            { text: '_______________________________________________', alignment: 'center', bold: true, border: [false, false, true, false], margin: [0, 50, 42, 0] }
        ]);
        conteudo.push([
            { text: `${this.funcaoService.formatarDataExtenso(new Date(), this.login.cidade.nome)}`, fontSize: 12, border: [true, false, false, false], margin: [5, 0, 0, 0] },
            { text: `${this.funcaoService.abreviarStr(assinaturas[0].pessoa?.nome, 100)}`, bold: true, border: [false, false, true, false], fontSize: 12, alignment: 'center', margin: [0, 0, 40, 0] },
        ]);
        conteudo.push([
            { text: '', border: [true, false, false, true] },
            { text: `${this.funcaoService.abreviarStr(assinaturas[0].cargo, 100)}`, bold: true, border: [false, false, true, true], fontSize: 12, alignment: 'center', margin: [0, 0, 40, 20] },
        ]);

        return conteudo;
    }

    public layout() {
        return {
            linhas: {
                hLineWidth() {
                    return 1;
                },
                vLineWidth() {
                    return 1;
                },
                hLineColor() {
                    return 'black';
                },
                paddingLeft() {
                    return 3;
                },
                paddingRight() {
                    return 3;
                }
            }
        };
    }
}