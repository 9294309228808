import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ContratoService } from 'administrativo-lib';
import { BaseResourceRptComponent, Coluna, Contrato, EddyAutoComplete, GlobalService, Favorecido, FavorecidoService, EmpenhoResto } from 'eddydata-lib';
import { Component } from '@angular/core'
import * as toastr from 'toastr';
import { EmpenhoRestoService } from 'contabil-lib';

@Component({
  selector: 'app-contrato-saldo-rpt-dlg',
  templateUrl: './contrato-saldo-rpt-dlg.component.html'
})
export class ContratoSaldoRptDlgComponet extends BaseResourceRptComponent {

  protected datepipe: DatePipe
  public ptBR: any
  public inicioContratoA: Date
  public inicioContratoB: Date
  public finalContratoA: Date
  public finalContratoB: Date
  public inicialEmpenhadoA: Date
  public finalEmpenhadoB: Date
  public inicialLiquidadoA: Date
  public finalLiquidadoB: Date
  public inicialPagamento: Date
  public finalPagamento: Date
  public nContratoInicial: string
  public nContratoFinal: string
  public anoContrato: string
  public anoProcesso: string
  public nProcessoInicial: string
  public nProcessoFinal: string
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>
  public favorecido: Favorecido
  public filtroContrato: 'ANO' | 'CONTRATO';
  public listFiltrosContrato: { id: 'ANO' | 'CONTRATO', nome: string }[];
  public tipoRegistro: '*' | 'CONTRATO' | 'REGISTRO_PRECO'
  public listTipoRegistro: { id: '*' | 'CONTRATO' | 'REGISTRO_PRECO', nome: string }[]
  public imaskProcesso = {
    mask: [
      { mask: '0000/0000' },
      { mask: '00000/0000'}
    ]
  };

  constructor(
    private globalService: GlobalService,
    private contratoService: ContratoService,
    private empenhoRestoService: EmpenhoRestoService,
    private favorecidoService: FavorecidoService
  ) {
    super()
  }

  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================

  protected afterInit(): void {
    this.ptBR = this.globalService.obterDataBR()
    this.datepipe = new DatePipe('pt')
    this.orientacao = 'landscape'

    this.listFiltrosContrato = [
      { id: 'ANO', nome: 'Por Ano' },
      { id: 'CONTRATO', nome: 'Número de Contrato' },
    ];
    this.filtroContrato = 'CONTRATO';

    this.listTipoRegistro = [
      { id: '*', nome: 'Todos' },
      { id: 'CONTRATO', nome: 'Contratos' },
      { id: 'REGISTRO_PRECO', nome: 'Registros de Preço' },
    ];
    this.tipoRegistro = '*'

    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['cpf_cnpj', 'nome'], {
      relations: 'contratos.orgao', orderBy: 'nome'
    }, { text: ['nome', 'cpf_cnpj'] })

    this.globalService.calendarMascara()
  }

  protected tituloRelatorio(): string {
    return `${this.login.orgao.nome} - Saldo de Contratos`
  }

  protected obterColunasRelatorio(): Coluna[] {
    const retorno: Coluna[] = []

    retorno.push({ titulo: 'Nº Contrato', coluna: 'contrato_numero', alignment: 'left', agrupar: true, bold: true })
    retorno.push({ titulo: 'Valor Contrato', coluna: 'contrato_valor', alignment: 'right' })
    retorno.push({ titulo: 'Valor Aditamento', coluna: 'aditamento_valor', alignment: 'right' })
    retorno.push({ titulo: 'Valor Estorno', coluna: 'estorno_valor', alignment: 'right' })
    retorno.push({ titulo: 'Valor Reajuste', coluna: 'reajuste_valor', alignment: 'right' })
    retorno.push({ titulo: 'Valor Supressões', coluna: 'supressao_valor', alignment: 'right' })
    retorno.push({ titulo: 'Data Empenhado', coluna: 'empenho_data', alignment: 'center' })
    retorno.push({ titulo: 'Número Empenho', coluna: 'empenho_numero', alignment: 'left' })
    retorno.push({ titulo: 'Espécie', coluna: 'empenho_especie', alignment: 'left' })
    retorno.push({ titulo: 'Valor empenhado', coluna: 'empenho_valor', alignment: 'right', decimais: 2 })
    retorno.push({ titulo: 'Valor liquidado', coluna: 'liquidacao_valor', alignment: 'right', decimais: 2 })
    retorno.push({ titulo: 'Valor Pago', coluna: 'pago_valor', alignment: 'right', decimais: 2 })

    return retorno
  }

  protected totalizarColunas(): (string | {})[] {
    return [{ nome: 'empenho_valor' }, { nome: 'liquidacao_valor' }, { nome: 'pago_valor' }]
  }

  protected carregarLista(): Promise<any[]> {
    return new Promise(async (resolve) => {
      this.contratoService.filtrar(1, -1, await this.gerarParametros()).pipe(takeUntil(this.unsubscribe)).subscribe(async (res) => {
        if (!res.content.length) {
          toastr.info('Não há informações para exibir.</br>Revise os parametros de pesquisa e tente novamente')
          return
        }

        const listaContratos: Contrato[] = [];
        for await (const contrato of res.content as Contrato[]) {
          const emp: any[] = [];
          emp.push(...contrato.empenhos)
          contrato['empenho_resto'] = await this.buscarEmpenhosResto(emp)
          listaContratos.push(contrato)
        }

        let lista = await this.normalizacao(listaContratos)
        resolve(lista)
      });
    });
  }

  public async buscarEmpenhosResto(empenhos: any[]) {
    if (!empenhos?.length) return [];
    let listaEmpenhos = []
    let listaAno = []
    empenhos.forEach(e => {
      listaEmpenhos.push(e.numero)
      listaAno.push(e.exercicio.ano)
    });

    const param = {};
    param['numero$in'] = listaEmpenhos;
    param['ano$in'] = listaAno;
    param['orgao.id'] = this.login.orgao.id;
    param['exercicio.id'] = this.login.exercicio.id;
    param['relations'] = 'liquidacoes.pagamentos';

    let empenhoResto: EmpenhoResto[] = [];

    empenhoResto = await (await this.empenhoRestoService.extendido(1, -1, param).toPromise()).content

    return empenhoResto;
  }

  protected larguraColunas(): (string | number)[] {
    return ['*', '*', '*', '*', '*', '*', 'auto','*', '*', '*', '*']
  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  public async normalizacao(lista: Contrato[]) {
    let normalizado: {}[] = []
    for await (const contrato of lista) {
      let linhaPreenchida: {}
      let linhaVazia = { contrato_numero: '', contrato_valor: '', aditamento_valor: '', estorno_valor: '', reajuste_valor: '', supressao_valor: '' }
      let ctl = 0

      linhaPreenchida = {
        contrato_numero: `${this.funcaoService.mascarar('0000/0000', contrato.numero)} - ${contrato.favorecido.nome}`,
        contrato_valor: this.funcaoService.convertToBrNumber(+contrato.valor_contrato),
        aditamento_valor: this.funcaoService.convertToBrNumber(contrato.aditamentos.reduce((acc, aditamento) => {
          if (aditamento.tipo?.reducao === false || !aditamento.tipo) return +aditamento.valor_total + acc
          else return acc
        }, 0)),
        estorno_valor: this.funcaoService.convertToBrNumber(contrato.itens.reduce((acc, itens) => {
          return +itens.reajustes.reduce((acc2, reajuste) => {
            if (reajuste.especie === 'E') return +reajuste.reajuste_valor_total + acc2
            else return acc2
          }, 0) + acc
        }, 0)),
        reajuste_valor: this.funcaoService.convertToBrNumber(contrato.itens.reduce((acc, itens) => {
          return +itens.reajustes.reduce((acc2, reajuste) => {
            if (reajuste.especie === 'R') return +reajuste.reajuste_valor_total + acc2
            else return acc2
          }, 0) + acc
        }, 0)),
        supressao_valor: this.funcaoService.convertToBrNumber(contrato.aditamentos.reduce((acc, aditamento) => {
          if (aditamento.tipo?.reducao === true) return +aditamento.valor_total + acc
          else return acc
        }, 0))
      };

      if (contrato.empenhos.length > 0) {
        for await (const empenho of contrato.empenhos) {
          let objEmpenhos = {
            empenho_data: this.funcaoService.converteDataBR(empenho.data_empenho),
            empenho_numero: `${empenho.numero}/${empenho.exercicio.ano}`,
            empenho_especie: empenho.especie,
            empenho_valor: empenho.valor_empenho,
            liquidacao_valor: empenho.liquidacoes.reduce((acc, liquidacao) => +liquidacao.valor_liquidado + acc, 0),
            pago_valor: empenho.liquidacoes.reduce((acc, liquidacao) => {
              return +liquidacao.pagamentos.reduce((acc2, pagamento) => +pagamento.valor_pago + acc2, 0) + acc
            }, 0)
          };
          linhaVazia.contrato_numero = linhaPreenchida['contrato_numero']
          if (ctl === 0 || this.formato !== 'pdf') {
            normalizado.push(Object.assign({}, linhaPreenchida, objEmpenhos))
          } else {
            normalizado.push(Object.assign({}, linhaVazia, objEmpenhos))
          }
          ctl++
        }
      } else {
        normalizado.push(Object.assign({}, linhaPreenchida, { empenho_data: '', empenho_numero: '', empenho_valor: 0, liquidacao_valor: 0, pago_valor: 0 }))
      }

      if (contrato['empenho_resto'].length > 0) {
        for await (const empenhoResto of contrato['empenho_resto']) {
          let objEmpenhos = {
            empenho_data: this.funcaoService.converteDataBR(empenhoResto.data_empenho),
            empenho_numero: `${empenhoResto.numero}/${empenhoResto.ano}`,
            empenho_especie: empenhoResto.especie,
            empenho_valor:  0,//empenhoResto.valor_empenho,
            liquidacao_valor: 0,//empenhoResto.liquidacoes.reduce((acc, liquidacao) => +liquidacao.valor_liquidado + acc, 0),
            pago_valor: empenhoResto.liquidacoes.reduce((acc, liquidacao) => {
              return +liquidacao.pagamentos.reduce((acc2, pagamento) => +pagamento.valor_pago + acc2, 0) + acc
            }, 0)
          };
          linhaVazia.contrato_numero = linhaPreenchida['contrato_numero']
          if (ctl === 0 || this.formato !== 'pdf') {
            normalizado.push(Object.assign({}, linhaPreenchida, objEmpenhos))
          } else {
            normalizado.push(Object.assign({}, linhaVazia, objEmpenhos))
          }
          ctl++
        }
      } else {
        normalizado.push(Object.assign({}, linhaPreenchida, { empenho_data: '', empenho_numero: '', empenho_valor: 0, liquidacao_valor: 0, pago_valor: 0 }))
      }
    }
    return normalizado
  }

  public async gerarParametros() {
    const param = {
      relations: 'favorecido,licitacao,empenhos.liquidacoes.pagamentos,empenhos.exercicio,aditamentos.tipo,orgao,itens.reajustes',
      'orgao_id': this.login.orgao.id,
      orderBy: 'data_inicio$DESC,empenhos.data_empenho'
    };

    if (this.tipoRegistro === 'CONTRATO') param['tipo'] = 'CONTRATO'
    if (this.tipoRegistro === 'REGISTRO_PRECO') param['tipo'] = 'REGISTRO_PRECO'
    if (this.favorecido) param['favorecido.id'] = this.favorecido.id;
    if (this.nContratoInicial && this.filtroContrato === 'CONTRATO') {
      if (this.nContratoFinal && this.anoContrato) {
        param['numero$ge'] = this.nContratoInicial.replace('/', '');
        param['numero$le'] = this.nContratoFinal.replace('/', '');
        param['ano'] = this.anoContrato
      } else if (!this.anoContrato) {
        toastr.warning('Informe o ano do contrato!', 'Atenção');
        return;
      }
    } else if (this.anoContrato && this.filtroContrato === 'ANO') {
      param['ano'] = this.anoContrato;
    }

    if (this.nProcessoInicial && this.anoProcesso) {
      if (this.nProcessoFinal) {
        // param['processo$ge'] = this.funcaoService.desmascarar('00000/0000', this.nProcessoInicial);
        // param['processo$le'] = this.funcaoService.desmascarar('00000/0000', this.nProcessoFinal);
        param['OR'] = `processo$bt=${this.nProcessoInicial},${this.nProcessoFinal};!;!;processo$bt=${this.nProcessoInicial.replace('/', '')},${this.nProcessoFinal.replace('/', '')}`;
        param['processo$like'] = `%${this.anoProcesso}`
      } else {
        // param['processo'] = this.funcaoService.desmascarar('00000/0000', this.nProcessoInicial);
        param['OR'] = `processo=${this.nProcessoInicial};!;!;processo=${this.nProcessoInicial.replace('/', '')}`;
        param['processo$like'] = `%${this.anoProcesso}`
      }
    } else if (this.nProcessoInicial && !this.anoProcesso) {
      toastr.warning('Informe o ano do processo!', 'Atenção')
      return;
    }
    if (this.inicioContratoA) {
      if (this.inicioContratoB) {
        param['data_inicio$ge'] = this.funcaoService.converteDataSQL(this.inicioContratoA)
        param['data_inicio$le'] = this.funcaoService.converteDataSQL(this.inicioContratoB)
      }
      else param['data_inicio'] = this.funcaoService.converteDataSQL(this.inicioContratoA)
    }
    if (this.finalContratoA) {
      if (this.finalContratoB) {
        param['data_termino$ge'] = this.funcaoService.converteDataSQL(this.finalContratoA)
        param['data_termino$le'] = this.funcaoService.converteDataSQL(this.finalContratoB)
      }
      else param['data_termino'] = this.funcaoService.converteDataSQL(this.finalContratoA)
    }
    if (this.inicialEmpenhadoA) {
      if (this.finalEmpenhadoB) {
        param['empenhos.data_empenho$ge'] = this.funcaoService.converteDataSQL(this.inicialEmpenhadoA)
        param['empenhos.data_empenho$le'] = this.funcaoService.converteDataSQL(this.finalEmpenhadoB)
      }
      else param['empenhos.data_empenho'] = this.funcaoService.converteDataSQL(this.inicialEmpenhadoA)
    }
    if (this.inicialLiquidadoA) {
      if (this.finalLiquidadoB) {
        param['empenhos.liquidacoes.data_liquidacao$ge'] = this.funcaoService.converteDataSQL(this.inicialLiquidadoA)
        param['empenhos.liquidacoes.data_liquidacao$le'] = this.funcaoService.converteDataSQL(this.finalLiquidadoB)
      }
      else param['empenhos.liquidacoes.data_liquidacao'] = this.funcaoService.converteDataSQL(this.inicialLiquidadoA)
    }
    if (this.inicialPagamento) {
      if (this.finalPagamento) {
        param['empenhos.liquidacoes.pagamentos.data_pagamento$ge'] = this.funcaoService.converteDataSQL(this.inicialPagamento)
        param['empenhos.liquidacoes.pagamentos.data_pagamento$le'] = this.funcaoService.converteDataSQL(this.finalPagamento)
      }
      else param['empenhos.liquidacoes.pagamentos.data_pagamento'] = this.funcaoService.converteDataSQL(this.inicialPagamento)
    }
    return param
  }

  // public desabilitarCampo(tipo: string) {
  //   if (tipo === 'contrato') {
  //     if (this.nProcessoInicial || this.nProcessoFinal || this.anoProcesso) return true
  //     else return false
  //   } else if (tipo === 'processo') {
  //     if (this.nContratoInicial || this.nContratoFinal || this.anoContrato) return true
  //     else return false
  //   }
  // }

  public verificarNumeroContrato() {
    if (this.nContratoInicial && (this.nContratoInicial.length < 9 || this.nContratoInicial.length > 10)) {
      toastr.warning('Informe número inicial do contrato completo! Ex: 1234/2000 ou 12345/2000', 'Atenção');
      this.nContratoInicial = this.nContratoFinal;
      return;
    }
    if (this.nContratoFinal && (this.nContratoFinal.length < 9 || this.nContratoFinal.length > 10)) {
      toastr.warning('Informe número final do contrato completo! Ex: 1234/2000 ou 12345/2000', 'Atenção');
      this.nContratoFinal = this.nContratoInicial;
      return;
    }
    if (this.nContratoInicial) {
      if (!this.nContratoFinal) {
        this.nContratoFinal = this.nContratoInicial;
      }
      this.anoContrato = this.nContratoInicial.split('/')[1];
    }
  }

  public trocarOpcaoContrato() {
    this.nContratoInicial = null;
    this.nContratoFinal = null;
    this.anoContrato = null;
  }

}
