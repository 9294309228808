import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InventarioEstoqueFormComponent } from './inventario-estoque-form/inventario-estoque-form.component';
import { InventarioEstoqueListComponent } from './inventario-estoque-list/inventario-estoque-list.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: InventarioEstoqueListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: InventarioEstoqueFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: InventarioEstoqueFormComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InventarioEstoqueRoutingModule { }
