import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EddyAutoComplete, Favorecido, FavorecidoService, GlobalService, Login, OrgaoAssinaturaService } from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotaLiquidacaoResto } from '../../relatorio/liquidacao/nota-liquidacao-resto';
import { LiquidacaoRestoService } from '../service/liquidacao-resto.service';
import * as toastr from 'toastr'

@Component({
  selector: 'app-nota-liquidacao-resto-dlg',
  templateUrl: './nota-liquidacao-resto-dlg.component.html'
})
export class NotaLiquidacaoRestoDlgComponent implements OnInit, OnDestroy {

  protected datepipe: DatePipe;
  public ptBR: any;
  public selectedOrdem: string;
  public selectedOpt: string;
  public selectNaoImpresso: boolean;
  public selectUsuario: boolean;
  public selectFornecedor: boolean;
  public marcarComoImpresso: boolean;
  public dataInicio: Date;
  public dataFinal: Date;
  public numeroInicio: number;
  public numeroFinal: number;
  public selectAnulado: boolean;
  public favorecido: Favorecido;

  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;

  protected unsubscribe: Subject<void> = new Subject();

  public imaskNumero = {
    mask: Number,
    scale: 0,
    signed: false
  };

  @Input() login: Login;
  @Input() empenhosSelecionados: any[];

  constructor(
    protected confirmationService: ConfirmationService,
    protected messageService: MessageService,
    private globalService: GlobalService,
    protected assinaturaService: OrgaoAssinaturaService,
    private liquidacaoRestoService: LiquidacaoRestoService,
    private favorecidoService: FavorecidoService
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.selectedOrdem = 'ord1';
    this.selectedOpt = 'opt1';
    this.selectNaoImpresso = true;
    this.selectUsuario = true;
    this.dataInicio = new Date();
    this.dataFinal = new Date();

    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['nome'], { 'cidade.id': this.login.cidade.id, relations: 'tipo,contas.banco', orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  imprimir() {
    if (this.selectNaoImpresso) {
      this.confirmationService.confirm({
        message: 'Deseja marcar as liquidações como impressas ?',
        acceptLabel: "Sim",
        rejectLabel: "Não",
        header: 'Confirmação',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.gerarImpressaoLiquidacao();
          this.marcarComoImpresso = true;
        },
        reject: () => {
          this.marcarComoImpresso = false;
          this.gerarImpressaoLiquidacao();
        }
      });
    } else {
      this.marcarComoImpresso = false;
      this.gerarImpressaoLiquidacao();
    }
  }

  gerarImpressaoLiquidacao() {

    const parametros = {};

    let relations = ['empenho', 'empenho.contrato', 'empenho.exercicio', 'empenho.licitacao', 'empenho.modalidade',
      'empenho.favorecido', 'empenho.favorecido.contas', 'empenho.favorecido.contas.banco', 'documento_fiscal_info', 'documento_fiscal_info.documento_fiscal'];

    if (this.selectedOpt === 'opt1') {
      if (+this.dataFinal < +this.dataInicio) {
        toastr.warning('Data final do empenho está maior que a inicial')
        return;
      }
      if (!this.dataInicio || !this.dataFinal || +this.dataFinal === 0 || +this.dataInicio === 0) {
        toastr.warning('Informe a data inicial e final para imprimir')
        return;
      }
    } else if (this.selectedOpt === 'opt2') {
      if (+this.numeroFinal < +this.numeroInicio) {
        toastr.warning('Número final do empenho está maior que o inicial')
        return;
      }
      if (!this.numeroInicio || !this.numeroFinal || +this.numeroFinal === 0 || +this.numeroInicio === 0) {
        toastr.warning('Informe o número inicial e final para imprimir')
        return;
      }
    } else {
      if (this.empenhosSelecionados.length === 0 || !this.empenhosSelecionados) {
        toastr.warning('Selecione ao menos uma liquidação para imprimir')
        return;
      }
    }

    parametros['relations'] = relations.join(',');
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    if (this.selectedOrdem === 'ord1') {
      parametros['orderBy'] = 'empenho.numero$ASC,parcela$ASC';
    } else if (this.selectedOrdem === 'ord2') {
      parametros['orderBy'] = 'empenho.favorecido.nome$ASC';
    } else if (this.selectedOrdem === 'ord3') {
      parametros['orderBy'] = 'data_cadastro$ASC';
    }

    if (this.selectedOpt === 'opt1') {
      parametros['data_liquidacao$ge'] = this.datepipe.transform(this.dataInicio, 'yyyy-MM-dd');
      parametros['data_liquidacao$le'] = this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd');
    } else if (this.selectedOpt === 'opt2') {
      parametros['empenho.numero$ge'] = +this.numeroInicio;
      parametros['empenho.numero$le'] = +this.numeroFinal;
    } else {
      parametros['empenho.numero$in'] = this.empenhosSelecionados;
    }
    if (this.selectNaoImpresso) {
      parametros['impresso'] = !this.selectNaoImpresso;
    }
    if (this.selectUsuario) {
      parametros['usuario_cadastro_id'] = this.login.usuario.id;
    }
    if (this.selectFornecedor) {
      parametros['empenho.favorecido.nome$like'] = this.favorecido.nome;
    }
    if (this.selectAnulado) {
      parametros['anulacao'] = true;
    } else {
      parametros['anulacao'] = false;
      parametros['anulado_total'] = false;
    }

    this.liquidacaoRestoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        new NotaLiquidacaoResto(this.assinaturaService).imprimir(lista.content, this.login);
        if (this.marcarComoImpresso && lista.content.length > 0) {
          this.liquidacaoRestoService.marcarComoImpresso(parametros).subscribe(() => {
          });
        }
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }
}
