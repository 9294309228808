import { Adiantamento } from 'eddydata-lib';
import { BaseResourceStorage } from '../../models/base-resource.storage';

export class AdiantamentoStorage extends BaseResourceStorage {
  constructor(
    public foto?: boolean,
    public adiantamento?: Adiantamento,
  ) {
    super();
  }

  static converteJson(json: any): AdiantamentoStorage {
    return Object.assign(new AdiantamentoStorage(), json);
  }
}