import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PpaLeiService } from 'administrativo-lib';
import { FonteFinanciamentoService } from 'contabil-lib';
import { ExecutoraService, FuncaoService, GlobalService, LoginContabil, OrgaoService } from 'eddydata-lib';
import { SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { AcaoGovernoService } from '../../../planejamento/acao-governo/service/acao-governo.service';
import { LdoService } from '../../../planejamento/ldo/service/ldo.service';
import { MetaGovernoService } from '../../../planejamento/meta-governo/service/meta-governo.service';
import { Anexo5Ldo } from './relatorio/anexo-5.ldo';
import { Anexo6Ldo } from './relatorio/anexo-6.ldo';
import { AnexoCompatibilidadeLdo } from './relatorio/anexo-compatibilidade.ldo';
import { Demo1Ldo } from './relatorio/demo-1.ldo';
import { Demo2Ldo } from './relatorio/demo-2.ldo';
import { Demo3Ldo } from './relatorio/demo-3.ldo';
import { Demo4Ldo } from './relatorio/demo-4.ldo';
import { Demo5Ldo } from './relatorio/demo-5.ldo';
import { Demo6Ldo } from './relatorio/demo-6.ldo';
import { Demo7Ldo } from './relatorio/demo-7.ldo';
import { Demo8Ldo } from './relatorio/demo-8.ldo';
import { RiscosFiscaisProvidencias } from './relatorio/riscos-fiscais-providencias.ldo';

@Component({
  selector: 'app-anexo-ldo',
  templateUrl: './anexo-ldo.component.html'
})
export class AnexoLdoComponent implements OnInit {
  public login: LoginContabil = new LoginContabil();
  public ano: number;
  public listaOrgaos: SelectItem[];
  public orgaos: number[] = [];
  public desconsiderarIndicadoresZeradosExercicio: boolean;
  public anexoSelect: string;
  public opt: number = 1;
  public listaExercicios: Array<any>;
  public listaBalancetes: Array<any>;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    protected fonteService: FonteFinanciamentoService,
    protected metaService: MetaGovernoService,
    protected acaoService: AcaoGovernoService,
    protected executoraService: ExecutoraService,
    protected orgaoService: OrgaoService,
    protected ldoService: LdoService,
    protected ppaLeiService: PpaLeiService,
    protected funcaoService: FuncaoService) {
    this.login = GlobalService.obterSessaoLogin();
    this.ano = this.login.exercicio.ano;
    this.orgaos.push(this.login.orgao.id);
  }

  ngOnInit() {
    this.orgaoService.filtrar(0, -1, { cidade_id: this.login.cidade.id, orderBy: 'codigo' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaOrgaos = [];
        for (const model of dados.content) {
          this.listaOrgaos.push({ label: `${model.codigo} - ${model.nome}`, value: model.id });
        }
      });

    this.listaExercicios = new Array();
    this.listaExercicios.push({ id: this.login.ppa.ppaperiodo.ano1, ano: this.login.ppa.ppaperiodo.ano1 });
    this.listaExercicios.push({ id: this.login.ppa.ppaperiodo.ano2, ano: this.login.ppa.ppaperiodo.ano2 });
    this.listaExercicios.push({ id: this.login.ppa.ppaperiodo.ano3, ano: this.login.ppa.ppaperiodo.ano3 });
    this.listaExercicios.push({ id: this.login.ppa.ppaperiodo.ano4, ano: this.login.ppa.ppaperiodo.ano4 });
    this.listaBalancetes = [
      { id: 'anexo5', nome: 'ANEXO V - DESCRIÇÃO DOS PROGRAMAS GOVERNAMENTAIS' },
      { id: 'anexo6', nome: 'ANEXO VI - UNIDADES EXECUTORAS E AÇÕES' },
      { id: 'demo1', nome: 'DEMONSTRATIVO I - METAS ANUAIS' },
      { id: 'demo2', nome: 'DEMONSTRATIVO II - AVALIAÇÃO DO CUMPRIMENTO DAS METAS FISCAIS DO EXERCÍCIO ANTERIOR' },
      { id: 'demo3', nome: 'DEMONSTRATIVO III - METAS FISCAIS COMPARADAS COM AS FIXADAS NOS TRÊS EXERCÍCIOS ANTERIORES' },
      { id: 'demo4', nome: 'DEMONSTRATIVO IV - EVOLUÇÃO DO PATRIMÔNIO LÍQUIDO' },
      { id: 'demo5', nome: 'DEMONSTRATIVO V - ORIGEM E APLICAÇÃO DOS RECURSOS OBTIDOS COM A ALIENAÇÃO DE ATIVOS' },
      { id: 'demo6', nome: 'DEMONSTRATIVO VI - AVALIAÇÃO DA SITUAÇÃO FINANCEIRA E ATUARIAL DO RPPS' },
      { id: 'demo7', nome: 'DEMONSTRATIVO VII - ESTIMATIVA E COMPENSAÇÃO DA RENÚNCIA DE RECEITA' },
      { id: 'demo8', nome: 'DEMONSTRATIVO VIII - MARGEM DE EXPANSÃO DAS DESPESAS OBRIGATÓRIAS DE CARÁTER CONTINUADOs' },
      { id: 'demo90', nome: 'DEMONSTRATIVO COMPATIBILIDADE LOA E LDO' },
      { id: 'riscos', nome: 'DEMONSTRATIVO DE RISCOS FISCAIS E PROVIDÊNCIAS' },
    ];
  }

  imprimir() {
    if (!this.orgaos || this.orgaos.length === 0) {
      toastr.warning('Selecione o(s) órgão(s) a serem impressos');
    }
    if (this.anexoSelect === 'anexo5') {
      const anexo = new Anexo5Ldo(this.ano, this.metaService);
      anexo.montarRelatorio(this.orgaos, this.opt, this.ano, this.desconsiderarIndicadoresZeradosExercicio);
    } else if (this.anexoSelect === 'anexo6') {
      const anexo = new Anexo6Ldo(this.ano, this.acaoService);
      anexo.montarRelatorio(this.orgaos, this.opt, this.ano, this.desconsiderarIndicadoresZeradosExercicio);
    } else if (this.anexoSelect === 'demo1') {
      const demo = new Demo1Ldo(this.ano, this.ldoService, this.orgaos, this.ppaLeiService, null, this.listaOrgaos);
      demo.montarRelatorio();
    } else if (this.anexoSelect === 'demo2') {
      const demo = new Demo2Ldo(this.ano, this.ldoService, this.orgaos, null, this.listaOrgaos);
      demo.montarRelatorio();
    } else if (this.anexoSelect === 'demo3') {
      const demo = new Demo3Ldo(this.ano, this.ldoService, this.orgaos, null, this.listaOrgaos);
      demo.montarRelatorio();
    } else if (this.anexoSelect === 'demo4') {
      const demo = new Demo4Ldo(this.ano, this.ldoService, this.orgaos, null, this.listaOrgaos);
      demo.montarRelatorio();
    } else if (this.anexoSelect === 'demo5') {
      const demo = new Demo5Ldo(this.ano, this.ldoService, this.orgaos, this.listaOrgaos);
      demo.montarRelatorio();
    } else if (this.anexoSelect === 'demo6') {
      const demo = new Demo6Ldo(this.ano, this.ldoService, this.orgaos, null, this.listaOrgaos);
      demo.montarRelatorio();
    } else if (this.anexoSelect === 'demo7') {
      const demo = new Demo7Ldo(this.ano, this.ldoService, this.orgaos, this.listaOrgaos);
      demo.montarRelatorio();
    } else if (this.anexoSelect === 'demo8') {
      const demo = new Demo8Ldo(this.ano, this.ldoService, this.orgaos, this.listaOrgaos);
      demo.montarRelatorio();
    } else if (this.anexoSelect === 'demo90') {
      const demo = new AnexoCompatibilidadeLdo(this.ano, this.ldoService);
      demo.montarRelatorio();
    } else if (this.anexoSelect === 'riscos') {
      const riscos = new RiscosFiscaisProvidencias(this.ano, this.ldoService, [this.login.orgao.id]);
      riscos.montarRelatorio();
    } else {
      toastr.warning('Selecione o anexo a ser impresso');
    }
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/planejamento']);
        break;
      default:
        this.sair();
        break;
    }
  }
}
