import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuncaoService, LoginContabil, Exercicio, Relatorio, GlobalService, OrgaoAssinaturaService, Orgao, FormatoExportacao } from 'eddydata-lib';
import { BalancoService } from '../service/balanco.service';
import { BaseResourceNotaExplicativa } from '../base-resource-nota-explicativa';
import { NotaExplicativaService } from '../service/nota-explicativa.service';

@Injectable({
  providedIn: 'root'
})
export class Anexo13Balanco extends BaseResourceNotaExplicativa implements OnDestroy {

  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: BalancoService,
    protected notaService: NotaExplicativaService,
    protected assinaturaService: OrgaoAssinaturaService
  ) {
    super('B13', notaService, assinaturaService)
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // tslint:disable: max-line-length
  public montarRelatorio(exercicio: Exercicio, orgaos: number[], listaOrgaos?: Orgao[], mes?: number, formato?: FormatoExportacao) {
    formato = formato ?? 'pdf';
    this.anexoServico.obterBalancoFinanceiro(exercicio.id, orgaos, mes)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        const orgao = orgaos.length === 1 ? listaOrgaos.filter(o => o.id === orgaos[0])[0] : listaOrgaos.filter(o => o.id === 1)[0];
        const consolidado = orgaos.length === 1 ? orgao.nome : 'Consolidado';
        let orgaoNomes: string[] = [];
        for (const o of listaOrgaos) {
          if (orgaos.some(orgao => orgao === o.id)) {
            orgaoNomes.push(`${o.codigo} - ${o.nome}`);
          }
        }
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(
            'ANEXO 13 - BALANÇO FINANCEIRO',
            this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            this.cabecalhoRelatorio(orgao, exercicio, mes)
              .concat(this.conteudo(dados[0], dados[1], mes))
              .concat(await this.conteudoNotaExplicativa())
              .concat(await this.conteudoAssinatura(this.login.orgao, orgaoNomes, 38)),
            'portrait', 'ANEXO 13 - BALANÇO FINANCEIRO',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, false, false, 'pdf', `Unidade Gestora: ${consolidado.toUpperCase()}`);
        } else if (formato === 'csv') {
          this.exportacaoCsv(dados[0], dados[1]);
        }
      });
  }

  private cabecalhoRelatorio(orgao: Orgao, exercicio: Exercicio, mes?: number): {}[] {
    const registros = [
      [
        { text: `Município:`, alignment: 'left', fontSize: 7 },
        { text: orgao.cidade?.nome ? orgao.cidade.nome : this.login.cidade.nome, alignment: 'center', fontSize: 7 },
        { text: `Exercício:`, alignment: 'left', fontSize: 7 },
        { text: exercicio.ano, alignment: 'center', fontSize: 7 }
      ], [
        { text: `Poder:`, alignment: 'left', fontSize: 7 },
        { text: orgao.especie === 'C' ? 'PODER LEGISLATIVO' : 'PODER EXECUTIVO', alignment: 'center', fontSize: 7 },
        { text: `Mês:`, alignment: 'left', fontSize: 7 },
        { text: mes ? this.globalService.obterMes(+mes) : 'ANUAL', alignment: 'center', fontSize: 7 }
      ], [
        { text: `Órgão:`, alignment: 'left', fontSize: 7, border: [true, true, true, false] },
        { text: orgao.nome, alignment: 'center', fontSize: 7, border: [true, true, true, false] },
        { text: `Acumulado/Mensal:`, alignment: 'left', fontSize: 7, border: [true, true, true, false] },
        { text: mes ? 'MENSAL' : 'ACUMULADO', alignment: 'center', fontSize: 7, border: [true, true, true, false] }
      ], [
        { text: 'Em R$ 1,00', bold: true, alignment: 'right', colSpan: 4, border: [false, true, false, false], fontSize: 7 },
        '',
        '',
        '',
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*', '*', '*', '*'],
        body: registros
      }
    }];
  }

  private montarConteudo(dados1: any[]) {
    const grupos1 = this.funcaoService.agrupar(dados1, ['titulo', 'especie'], ['valor']);
    const lst = [];

    for (const item of grupos1) {
      lst.push({
        nome: item.grupo['titulo'],
        especie: item.grupo['especie'] === 'I' || item.grupo['especie'] === 'T' ? 'T' : 'S',
        valor: 0,
        total: item.totalizadores[`valor`],
        somador: false
      });

      if (item && (item.grupo['especie'] === 'I')) {
        const subgrupos1 = this.funcaoService.agrupar(item.registros, ['subtitulo', 'especie'], ['valor']);

        for (const sub of subgrupos1) {

          let especie = sub.grupo['especie'] === 'I' || sub.grupo['especie'] === 'T' ? 'T' : 'S';
          if (item.grupo['titulo'] !== sub.grupo['subtitulo'])
            lst.push({
              nome: sub.grupo['subtitulo'],
              especie: especie,
              valor: 0,
              total: sub.totalizadores[`valor`],
              somador: false
            });

          if (sub && sub.grupo['especie'] === 'I') {
            for (const it of sub.registros) {
              lst.push({
                nome: it.nome,
                especie: it.especie,
                valor: it.valor,
                total: it.somador ? item.totalizadores['valor'] : 0,
                somador: it.somador
              });
            }
          }
        }
      }
    }

    return lst;
  }

  private conteudo(dados1: any[], dados2: any[], mes?: number): {}[] {
    const lstReceita = this.montarConteudo(dados1);
    const lstDespesa = this.montarConteudo(dados2);

    // monta o cabecalho
    const registros: {}[] = [
      // [{
      //   text: `EXERCÍCIO ${exercicio.ano}`, fontSize: 13,
      //   alignment: 'center', bold: true, colSpan: 6, border: [true, true, true, false]
      // }, '', '', '', '', ''],
      // [{
      //   text: `${this.login.orgao.especie === 'C' ? 'PODER LEGISLATIVO' : 'PODER EXECUTIVO'}`,
      //   alignment: 'center',
      //   bold: true,
      //   colSpan: 6, border: [true, false, true, false]
      // }, '', '', '', '', ''],
      // [
      //   { text: `Municipio:`, alignment: 'left', fontSize: 7 },
      //   { text: orgao.cidade?.nome ? orgao.cidade.nome : this.login.cidade.nome, alignment: 'center', fontSize: 7, colSpan: 2 },
      //   '',
      //   { text: `Exercício`, alignment: 'left', fontSize: 7 },
      //   { text: exercicio.ano, alignment: 'center', fontSize: 7, colSpan: 2 },
      //   '',
      // ],
      // [
      //   { text: `Poder`, alignment: 'left', fontSize: 7 },
      //   { text: orgao.especie === 'C' ? 'PODER LEGISLATIVO' : 'PODER EXECUTIVO', alignment: 'center', fontSize: 7, colSpan: 2 },
      //   '',
      //   { text: `Mês`, alignment: 'left', fontSize: 7 },
      //   { text: mes ? this.globalService.obterMes(+mes) : '', alignment: 'center', fontSize: 7, colSpan: 2 },
      //   '',
      // ],
      // [
      //   { text: `Orgão:`, alignment: 'left', fontSize: 7, border: [true, true, true, false] },
      //   { text: orgao.nome, alignment: 'center', fontSize: 7, border: [true, true, true, false], colSpan: 2 },
      //   '',
      //   { text: `Acumulado/Mensal:`, alignment: 'left', fontSize: 7, border: [true, true, true, false] },
      //   { text: `Acumulado`, alignment: 'center', fontSize: 7, border: [true, true, true, false], colSpan: 2 },
      //   '',
      // ],
      [{
        text: 'RECEITAS',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'VALOR',
        alignment: 'center',
        bold: true, fontSize: 7, colSpan: 2
      }, {
        text: '',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'DESPESAS',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'VALOR',
        alignment: 'center',
        bold: true, fontSize: 7, colSpan: 2
      }, {
        text: '',
        alignment: 'center',
        bold: true, fontSize: 7
      }]
    ];

    // monta o agrupamento do relatório
    const totalGrupos = +lstReceita.length > +lstDespesa.length ? +lstReceita.length : +lstDespesa.length;
    let total1 = 0;
    let total2 = 0;

    for (let index = 0; index < totalGrupos; index++) {
      const item1 = lstReceita[index];
      const item2 = lstDespesa[index];

      registros.push([
        {
          text: item1 ? item1.nome : '', bold: item1 && (item1.especie === 'I') ? false : true, fontSize: 6, border: [true, false, false, false]
        },
        {
          text: item1 && (item1.especie === 'I') ? this.funcaoService.convertToBrNumber(item1.valor) : '', alignment: 'right',
          bold: item1 && item1.especie === 'S' ? true : false, fontSize: 6, border: [true, false, false, false]
        },
        {
          text: item1 && (item1.especie === 'S' || item1.somador) ? this.funcaoService.convertToBrNumber(item1.total) : '', alignment: 'right',
          bold: item1 && (item1.especie === 'S' || item1.somador) ? true : false, fontSize: 6, border: [true, false, false, false]
        },
        {
          text: item2 ? item2.nome : '', bold: item2 && (item2.especie === 'I' || item2.especie === 'SG') ? false : true, fontSize: 6, border: [true, false, false, false]
        },
        {
          text: item2 && (item2.especie === 'I' || item2.especie === 'SG') ? this.funcaoService.convertToBrNumber(item2.valor) : '', alignment: 'right',
          bold: item2 && (item2.especie === 'I' || item2.especie === 'SG') ? false : true, fontSize: 6, border: [true, false, false, false]
        },
        {
          text: item2 && (item2.especie === 'S' || item2.somador) ? this.funcaoService.convertToBrNumber(item2.total) : '', alignment: 'right',
          bold: item2 && (item2.especie === 'S' || item2.somador) ? true : false, fontSize: 6, border: [true, false, true, false]
        }
      ]);

      if (item1 && item1.especie === 'S') {
        total1 += item1 ? +item1.total : 0;
      } else if (item1 && item1.especie === 'I') {
        total1 += item1 ? +item1.valor : 0;
      }

      if (item2 && item2.especie === 'S') {
        total2 += item2 ? +item2.total : 0;
      } else if (item2 && item2.especie === 'I') {
        total2 += item2 ? +item2.valor : 0;
      }
    }

    registros.push([{
      text: 'TOTAL', border: [true, true, true, true], fontSize: 7, bold: true, colSpan: 2
    },
    {
      text: '', alignment: 'right', border: [true, true, false, true], fontSize: 7, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total1), alignment: 'right',
      border: [true, true, true, true], fontSize: 7, bold: true
    },
    {
      text: 'TOTAL', border: [true, true, false, true], fontSize: 7, bold: true, colSpan: 2
    },
    {
      text: '', alignment: 'right', border: [true, true, false, true], fontSize: 7, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total2), alignment: 'right',
      border: [true, true, true, true], fontSize: 7, bold: true
    }]);

    return [{
      layout: 'linhas',
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        dontBreakRows: true,
        headerRows: 4,
        widths: ['*', 'auto', 'auto', '*', 'auto', 'auto'],
        body: registros
      }
    }];
  }

  private exportacaoCsv(dados1: any[], dados2: any[]) {
    const lstReceita = this.montarConteudo(dados1);
    const lstDespesa = this.montarConteudo(dados2);

    // monta o cabecalho
    const registros: {}[][] = [
      [
        { text: 'RECEITAS' },
        { text: 'VALOR' },
        { text: '' },
        { text: 'DESPESAS' },
        { text: 'VALOR' }
      ]
    ];

    let total1 = 0;
    let total2 = 0;
    const totalGrupos = +lstReceita.length > +lstDespesa.length ? +lstReceita.length : +lstDespesa.length;
    for (let index = 0; index < totalGrupos; index++) {
      const item1 = lstReceita[index];
      const item2 = lstDespesa[index];

      registros.push([
        { text: item1 ? item1.nome : '', bold: item1 && (item1.especie === 'I') ? false : true },
        { text: item1 && (item1.especie === 'I') ? this.funcaoService.convertToBrNumber(item1.valor) : '' },
        { text: item1 && (item1.especie === 'S' || item1.somador) ? this.funcaoService.convertToBrNumber(item1.total) : '' },
        { text: item2 ? item2.nome : '', bold: item2 && (item2.especie === 'I' || item2.especie === 'SG') ? false : true },
        { text: item2 && (item2.especie === 'I' || item2.especie === 'SG') ? this.funcaoService.convertToBrNumber(item2.valor) : '' },
        { text: item2 && (item2.especie === 'S' || item2.somador) ? this.funcaoService.convertToBrNumber(item2.total) : '' }
      ]);

      if (item1 && item1.especie === 'S') {
        total1 += item1 ? +item1.total : 0;
      } else if (item1 && item1.especie === 'I') {
        total1 += item1 ? +item1.valor : 0;
      }

      if (item2 && item2.especie === 'S') {
        total2 += item2 ? +item2.total : 0;
      } else if (item2 && item2.especie === 'I') {
        total2 += item2 ? +item2.valor : 0;
      }
    }

    registros.push([
      { text: 'TOTAL' }, { text: '' },
      { text: this.funcaoService.convertToBrNumber(total1) },
      { text: 'TOTAL' }, { text: '' },
      { text: this.funcaoService.convertToBrNumber(total2) }
    ]);

    dados1 = null;
    dados2 = null;
    let csv = '';
    for (let i=0; i<registros.length; i++) {
      const linha = registros[i];
      if (i > 0) csv += '\n';
      for (let x=0; x<linha.length; x++) {
        if (x > 0) csv += ';';
        csv += String(linha[x]['text']);
      }
    }

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + csv));
    element.setAttribute("download", `ANEXO 13 - BALANÇO FINANCEIRO.csv`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

}
