// tslint:disable: variable-name
import { ProcessoHistorico } from './processo-historico.model';
import { Usuario } from '../comum/usuario.model';

export class ProcessoHistoricoView {

    id: number;

    usuario: Usuario;

    historico: ProcessoHistorico;

    data_cadastro: Date;

    data_alteracao: Date;

}
