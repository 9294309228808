import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../../assinaturas/assinaturas';
import { AnexoLrfFederalService } from '../../../service/anexo-lrf-federal.service';
import { Anexo1Despesa } from './anexo1.despesa';
import { Anexo1Receita } from './anexo1.receita';

@Directive()
export class Anexo1RreoLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoFederalServico: AnexoLrfFederalService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected assinaturaControleInterno: boolean) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
    this.anexoFederalServico.obterAnexo1(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(null, this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            await this.conteudo(dados),
            'portrait', 'ANEXO1 - BALANÇO ORÇAMENTÁRIO',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 2;
                },
                paddingRight() {
                  return 2;
                }
              }
            });
        } else {
          this.funcaoService.exportar(formato, await this.conteudoCsv(dados), 'ANEXO1 - BALANÇO ORÇAMENTÁRIO', this.colunas());
        }
      });

  }

  private async conteudo(dados: any[]): Promise<{}[]> {
    let listaReceitas: {}[] = [];
    const receitas = new Anexo1Receita(this.mes, this.exercicio);
    listaReceitas = receitas.montarQuadro(dados[0], dados[1]);

    let listaDespesas: {}[] = [];
    const despesas = new Anexo1Despesa(this.mes, this.exercicio);
    listaDespesas = despesas.montarQuadro(dados[1], dados[0]);

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);

    return [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 55, 55, 55, 30, 55, 30, 55],
          body: listaReceitas
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 45, 45, 45, 45, 45, 45, 45, 45, 45],
          body: listaDespesas
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*'],
          body: assinaturas
        }
      }
    ];
  }

  public async conteudoCsv(lista: any[]): Promise<{}[]> {
    const listaExportar = []

    const receitas = new Anexo1Receita(this.mes, this.exercicio);
    receitas.montarQuadroCsv(lista[0], lista[1], listaExportar);

    const despesas = new Anexo1Despesa(this.mes, this.exercicio);
    despesas.montarQuadroCsv(lista[1], lista[0], listaExportar);

    return listaExportar
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'RECEITAS', coluna: 'titulo' },
      { titulo: 'PREVISÃO INICIAL', coluna: 'dotacao_inicial' },
      { titulo: 'PREVISÃO ATUALIZADA', coluna: 'dotacao_atualizado' },
      { titulo: 'RECEITAS REALIZADAS', coluna: 'empenhado_bim' },
      { titulo: '', coluna: 'empenhado' },
      { titulo: '', coluna: 'liquidado_bim' },
      { titulo: '', coluna: 'liquidado' },
      { titulo: 'SALDO (a-c)', coluna: 'total1' },
      { titulo: '', coluna: 'pago'}, 
      { titulo: '', coluna: 'resto'}
    ];
    return colunasDefault;
  }


}
