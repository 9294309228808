import { Injectable } from "@angular/core";
import { FichaDespesaService } from "contabil-lib";
import { Exercicio, ExercicioService, FuncaoService, GlobalService, OrgaoAssinatura, OrgaoAssinaturaService, Relatorio } from "eddydata-lib";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as toastr from 'toastr';

interface Totalizacao {
  orcada: number,
  empenhado_mes: number,
  empenhado: number,
  saldo_empenhado: number,
  liquidado_mes: number,
  liquidado: number,
  saldo_liquidado: number,
  pago_mes: number,
  pago: number,
  saldo_pago: number,
}

@Injectable({
  providedIn: 'root'
})
export class BalanceteDespesaAcaoService {

  protected unsubscribe: Subject<void> = new Subject();

  private assinatura: OrgaoAssinatura;

  constructor(
    protected fichaService: FichaDespesaService,
    protected exercicioService: ExercicioService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService
  ) { }

  async montarBalancetePorAcao(mes: number, exercicioId: number, orgaos: number[], login: any, sintetico: boolean, executoraId?: number, codigoAplicacao?: boolean, dtInicio?: string, dtFim?: string) {
    const parametros: {} = {};
    if (mes) {
      parametros['mes'] = mes;
    } else {
      parametros['dtInicio'] = dtInicio;
      parametros['dtFim'] = dtFim;
    }
    if (exercicioId) {
      parametros['ano'] = exercicioId;
    }
    if (executoraId) {
      parametros['executora'] = executoraId;
    }
    parametros['orgaos'] = orgaos.join();
    parametros['sintetico'] = sintetico;
    parametros['codigoAplicacao'] = codigoAplicacao;

    parametros['acao_id'] = 49;
    const ex = await this.exercicioService.obterId(exercicioId).toPromise();

    this.fichaService.balancetePorAcao(parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        let subtitulo = '';
        if (mes) {
          subtitulo = this.globalService.obterDataBR().monthNames[mes - 1].toLocaleUpperCase() + '/' + ex.ano
        } else {
          let dt = dtInicio.split("-");
          subtitulo = dt[2] + '/' + dt[1] + '/' + dt[0] + ' à ';
          dt = dtFim.split("-");
          subtitulo += dt[2] + '/' + dt[1] + '/' + dt[0];
        }
        Relatorio.imprimirPersonalizado(
          'DEMONSTRAÇÃO DA DESPESA EMPENHADA, PROCESSADA E PAGA POR UNIDADES ORÇAMENTARIAS SEGUNDO A NATUREZA DA DESPESA',
          login.usuario.nome, login.usuario.sobrenome, login.orgao.nome, login.brasao,
          this.balanceteAcaoConteudo(dados, orgaos, sintetico, codigoAplicacao)
            .concat(await this.conteudoAssinatura(login, ex, mes, dtFim)),
          'landscape',
          'Balancete da Despesa por Ação',
          {
            linhas: {
              hLineWidth(i, node) {
                return 1;
              },
              vLineWidth(i) {
                return 1;
              },
              hLineColor(i) {
                return 'black';
              },
              paddingLeft(i) {
                return 3;
              },
              paddingRight(i, node) {
                return 3;
              }
            }
          }, false, false, 'pdf', `REFERÊNCIA: ${subtitulo}`);
      });
  }

  private balanceteAcaoConteudo(dados: any[], orgaos: number[], sintetico: boolean, codigoAplicacao: boolean): {}[] {
    const conteudo: {}[] = [];
    const totalGeral: Totalizacao = {
      orcada: 0, empenhado_mes: 0, empenhado: 0,
      saldo_empenhado: 0, liquidado_mes: 0, liquidado: 0,
      saldo_liquidado: 0, pago_mes: 0, pago: 0, saldo_pago: 0
    };

    conteudo.push(...this.dadosCabecalho(sintetico))
    this.dadosExecutora(conteudo, dados, totalGeral, sintetico, codigoAplicacao);

    let conteudoArray = [
      { text: 'TOTAL GERAL...', colSpan: 3, fontSize: 7, bold: true },
      '', '', '',
      { text: this.funcaoService.convertToBrNumber(totalGeral.orcada), fontSize: 6, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalGeral.empenhado_mes), fontSize: 6, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalGeral.empenhado), fontSize: 6, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalGeral.saldo_empenhado), fontSize: 6, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalGeral.liquidado_mes), fontSize: 6, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalGeral.liquidado), fontSize: 6, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalGeral.saldo_liquidado), fontSize: 6, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalGeral.pago_mes), fontSize: 6, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalGeral.pago), fontSize: 6, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalGeral.saldo_pago), fontSize: 6, bold: true, alignment: 'right' },
    ];

    if (!sintetico) {
      conteudoArray.splice(3, 1)
    }

    conteudo.push(conteudoArray);

    let width = ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'];

    if (!sintetico) {
      width.splice(1, 1);
    }

    return [{
      layout: 'linhas',
      table: {
        headerRows: 2,
        widths: width,
        body: conteudo
      }
    }];
  }

  private dadosCabecalho(sintetico: boolean): {}[] {
    let dados = [
      // [
      //   {
      //     text: 'DEMONSTRAÇÃO DA DESPESA EMPENHADA, PROCESSADA E PAGA POR UNIDADES ORÇAMENTARIAS SEGUNDO A NATUREZA DA DESPESA',
      //     colSpan: 13, fontSize: 9, alignment: 'center', bold: true
      //   }, '', '', '', '', '', '', '', '', '', '', '', '', ''
      // ],
      [
        { text: 'FUNCIONAL', rowSpan: 2, fontSize: 7, bold: true, alignment: 'center' },
        { text: sintetico ? 'RECURSO' : 'FICHA', rowSpan: 2, fontSize: 7, bold: true, alignment: 'center' },
        { text: 'FICHA', rowSpan: 2, fontSize: 7, bold: true, alignment: 'center' },
        { text: 'DESCRIÇÃO', rowSpan: 2, fontSize: 7, bold: true },
        { text: 'ORÇADA ATUAL', rowSpan: 2, fontSize: 7, bold: true, alignment: 'center' },
        { text: 'EMPENHADA', colSpan: 2, fontSize: 7, bold: true, alignment: 'center' },
        '',
        { text: 'SALDO', rowSpan: 2, fontSize: 7, bold: true, alignment: 'center' },
        { text: 'PROCESSADA', colSpan: 2, fontSize: 7, bold: true, alignment: 'center' },
        '',
        { text: 'SALDO', rowSpan: 2, fontSize: 7, bold: true, alignment: 'center' },
        { text: 'PAGAMENTO', colSpan: 2, fontSize: 7, bold: true, alignment: 'center' },
        '',
        { text: 'DÍVIDA', rowSpan: 2, fontSize: 7, bold: true, alignment: 'center' }
      ],
      [
        '', '', '', '',
        { text: 'DO MÊS', fontSize: 7, bold: true, alignment: 'center' },
        { text: 'ACUMULADA', fontSize: 7, bold: true, alignment: 'center' },
        '',
        { text: 'DO MÊS', fontSize: 7, bold: true, alignment: 'center' },
        { text: 'ACUMULADO', fontSize: 7, bold: true, alignment: 'center' },
        '',
        { text: 'DO MÊS', fontSize: 7, bold: true, alignment: 'center' },
        { text: 'ACUMULADO', fontSize: 7, bold: true, alignment: 'center' },
        ''
      ]
    ];

    if (!sintetico) {
      // dados[0].splice(12, 1);
      dados[0].splice(2, 1);
    }

    return dados;
  }

  private dadosExecutora(conteudo: {}[], dados: any[], totalGeral: Totalizacao, sintetico: boolean, codigoAplicacao: boolean): void {
    const grupoExecutora = this.funcaoService.agrupar(
      dados,
      ['codigo_executora', 'nome_executora'],
      ['valor_orcado', 'total_creditado', 'empenhado', 'empenhado_mes', 'liquidado', 'liquidado_mes', 'pago', 'pago_mes']
    );

    for (const executora of grupoExecutora) {
      totalGeral.orcada += executora.totalizadores['valor_orcado'] + +executora.totalizadores['total_creditado'];
      totalGeral.empenhado_mes += executora.totalizadores['empenhado_mes'];
      totalGeral.empenhado += executora.totalizadores['empenhado'];
      totalGeral.liquidado_mes += executora.totalizadores['liquidado_mes'];
      totalGeral.liquidado += executora.totalizadores['liquidado'];
      totalGeral.pago_mes += executora.totalizadores['pago_mes'];
      totalGeral.pago += executora.totalizadores['pago'];
      totalGeral.saldo_empenhado += (+executora.totalizadores['valor_orcado'] + +executora.totalizadores['total_creditado']) - +executora.totalizadores['empenhado'];
      totalGeral.saldo_liquidado += +executora.totalizadores['empenhado'] - +executora.totalizadores['liquidado'];
      totalGeral.saldo_pago += +executora.totalizadores['liquidado'] - +executora.totalizadores['pago'];

      let colsSpan = 14;
      if (!sintetico) {
        colsSpan = 13;
      }

      let conteudoArray = [
        {
          text: `${this.funcaoService.mascarar('##.##.##', executora.grupo['codigo_executora'] || '')} ${executora.grupo['nome_executora'] || ''}`,
          colSpan: colsSpan, fontSize: 7, bold: true
        }, '', '', '', '', '', '', '', '', '', '', '', '', ''
      ];

      if (!sintetico) {
        conteudoArray.splice(12, 1)
      }

      conteudo.push(conteudoArray);
      this.dadosAcao(conteudo, executora.registros, sintetico, codigoAplicacao);

      let conteudoArray2 = [
        { text: 'SOMA', colSpan: 3, fontSize: 7, bold: true },
        '', '',
        { text: '' },
        { text: this.funcaoService.convertToBrNumber(+executora.totalizadores['valor_orcado'] + +executora.totalizadores['total_creditado']), fontSize: 6, bold: true, alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(executora.totalizadores['empenhado_mes']), fontSize: 6, bold: true, alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(executora.totalizadores['empenhado']), fontSize: 6, bold: true, alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber((+executora.totalizadores['valor_orcado'] + +executora.totalizadores['total_creditado']) - +executora.totalizadores['empenhado']), fontSize: 6, bold: true, alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(executora.totalizadores['liquidado_mes']), fontSize: 6, bold: true, alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(executora.totalizadores['liquidado']), fontSize: 6, bold: true, alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(+executora.totalizadores['empenhado'] - +executora.totalizadores['liquidado']), fontSize: 6, bold: true, alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(executora.totalizadores['pago_mes']), fontSize: 6, bold: true, alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(executora.totalizadores['pago']), fontSize: 6, bold: true, alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(+executora.totalizadores['liquidado'] - +executora.totalizadores['pago']), fontSize: 6, bold: true, alignment: 'right' },
      ];

      if (!sintetico) {
        conteudoArray2.splice(3, 1)
      }

      conteudo.push(conteudoArray2);
    }
  }

  private dadosAcao(conteudo: {}[], dados: any[], sintetico: boolean, codigoAplicacao: boolean): void {
    let itensOrdenados = [];
    const grupoAcao = this.funcaoService.agrupar(
      dados,
      ['codigo_acao', 'nome_acao'],
      ['valor_orcado', 'total_creditado', 'empenhado', 'empenhado_mes', 'liquidado', 'liquidado_mes', 'pago', 'pago_mes']
    );

    if (!sintetico) {
      for (const acao of grupoAcao) {
        conteudo.push([
          { text: '', fontSize: 6, border: [true, false, true, false] },
          { text: '', fontSize: 6, border: [true, false, true, false] },
          { text: `${acao.grupo['codigo_acao'] || ''} ${acao.grupo['nome_acao'] || ''}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false] },
          { text: `${this.funcaoService.convertToBrNumber(acao.totalizadores['valor_orcado'] + +acao.totalizadores['total_creditado'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber(acao.totalizadores['empenhado_mes'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber(acao.totalizadores['empenhado'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber((+acao.totalizadores['valor_orcado'] + +acao.totalizadores['total_creditado']) - +acao.totalizadores['empenhado'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber(acao.totalizadores['liquidado_mes'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber(acao.totalizadores['liquidado'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber(+acao.totalizadores['empenhado'] - +acao.totalizadores['liquidado'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber(acao.totalizadores['pago_mes'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber(acao.totalizadores['pago'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber(+acao.totalizadores['liquidado'] - +acao.totalizadores['pago'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
        ]);

        if (codigoAplicacao) {
          const grupoRecursos = this.funcaoService.agrupar(acao.registros, ['re_codigo', 'a_codigo', 'av_variavel', 'av_nome']);
          for (const recurso of grupoRecursos) {
            conteudo.push([
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: `${recurso.grupo['re_codigo']}.${recurso.grupo['a_codigo']}.${recurso.grupo['av_variavel']} ${recurso.grupo['av_nome']}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
            ]);
            for (const item of recurso.registros) {
              conteudo.push([
                { text: `${item['funcional'] || ''}`, fontSize: 6, border: [true, false, true, false] },
                { text: `${this.funcaoService.strZero(item['ficha_numero'] || '', 3)}`, fontSize: 6, border: [true, false, true, false], alignment: 'center' },
                { text: `${this.funcaoService.mascarar('#.#.##.##', item['codigo_despesa'])} ${item['nome_despesa'] || ''}`, fontSize: 6, border: [true, false, true, false] },
                { text: `${this.funcaoService.convertToBrNumber(+item['valor_orcado'] + +item['total_creditado'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: `${this.funcaoService.convertToBrNumber(item['empenhado_mes'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: `${this.funcaoService.convertToBrNumber(item['empenhado'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: `${this.funcaoService.convertToBrNumber((+item['valor_orcado'] + +item['total_creditado']) - +item['empenhado'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: `${this.funcaoService.convertToBrNumber(item['liquidado_mes'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: `${this.funcaoService.convertToBrNumber(item['liquidado'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: `${this.funcaoService.convertToBrNumber(+item['empenhado'] - +item['liquidado'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: `${this.funcaoService.convertToBrNumber(item['pago_mes'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: `${this.funcaoService.convertToBrNumber(item['pago'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: `${this.funcaoService.convertToBrNumber(+item['liquidado'] - +item['pago'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
              ]);
            }
          }
        } else {
          for (const item of acao.registros) {
            conteudo.push([
              { text: `${item['funcional'] || ''}`, fontSize: 6, border: [true, false, true, false] },
              { text: `${this.funcaoService.strZero(item['ficha_numero'] || '', 3)}`, fontSize: 6, border: [true, false, true, false], alignment: 'center' },
              { text: `${this.funcaoService.mascarar('#.#.##.##', item['codigo_despesa'])} ${item['nome_despesa'] || ''}`, fontSize: 6, border: [true, false, true, false] },
              { text: `${this.funcaoService.convertToBrNumber(+item['valor_orcado'] + +item['total_creditado'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
              { text: `${this.funcaoService.convertToBrNumber(item['empenhado_mes'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
              { text: `${this.funcaoService.convertToBrNumber(item['empenhado'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
              { text: `${this.funcaoService.convertToBrNumber((+item['valor_orcado'] + +item['total_creditado']) - +item['empenhado'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
              { text: `${this.funcaoService.convertToBrNumber(item['liquidado_mes'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
              { text: `${this.funcaoService.convertToBrNumber(item['liquidado'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
              { text: `${this.funcaoService.convertToBrNumber(+item['empenhado'] - +item['liquidado'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
              { text: `${this.funcaoService.convertToBrNumber(item['pago_mes'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
              { text: `${this.funcaoService.convertToBrNumber(item['pago'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
              { text: `${this.funcaoService.convertToBrNumber(+item['liquidado'] - +item['pago'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
            ]);
          }
        }
      }
    } else {
      for (const acao of grupoAcao) {
        const grupoDespesa = this.funcaoService.agrupar(
          acao.registros,
          ['codigo_despesa', 'nome_despesa', 're_codigo', 'a_codigo', 'av_variavel', 'av_nome', 'ficha_numero'],
          ['valor_orcado', 'total_creditado', 'empenhado', 'empenhado_mes', 'liquidado', 'liquidado_mes', 'pago', 'pago_mes']
        );

        conteudo.push([
          { text: '', fontSize: 6, border: [true, false, true, false] },
          { text: '', fontSize: 6, border: [true, false, true, false] },
          { text: '', fontSize: 6, border: [true, false, true, false] },
          { text: `${acao.grupo['codigo_acao'] || ''} ${acao.grupo['nome_acao'] || ''}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false] },
          { text: `${this.funcaoService.convertToBrNumber(acao.totalizadores['valor_orcado'] + +acao.totalizadores['total_creditado'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber(acao.totalizadores['empenhado_mes'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber(acao.totalizadores['empenhado'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber((+acao.totalizadores['valor_orcado'] + +acao.totalizadores['total_creditado']) - +acao.totalizadores['empenhado'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber(acao.totalizadores['liquidado_mes'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber(acao.totalizadores['liquidado'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber(+acao.totalizadores['empenhado'] - +acao.totalizadores['liquidado'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber(acao.totalizadores['pago_mes'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber(acao.totalizadores['pago'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber(+acao.totalizadores['liquidado'] - +acao.totalizadores['pago'])}`, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false], alignment: 'right' },
        ]);

        let recurso = '';
        itensOrdenados = grupoDespesa.sort((a, b) => +a.grupo['codigo_despesa'] - +b.grupo['codigo_despesa']);
        for (const item of itensOrdenados) {
          if (codigoAplicacao && recurso !== `${item.grupo['re_codigo']}.${item.grupo['a_codigo']}.${item.grupo['av_variavel']} ${item.grupo['av_nome']}`) {
            recurso = `${item.grupo['re_codigo']}.${item.grupo['a_codigo']}.${item.grupo['av_variavel']} ${item.grupo['av_nome']}`;
            conteudo.push([
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: recurso, fontSize: 6, bold: true, decoration: 'underline', border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
              { text: '', fontSize: 6, border: [true, false, true, false] },
            ]);
          }
          conteudo.push([
            { text: `${item.registros[0]['funcional'] || ''}`, fontSize: 6, border: [true, false, true, false] },
            { text: `${item.grupo['re_codigo']} ${item.grupo['a_codigo']} ${item.grupo['av_variavel'] != null ? item.grupo['av_variavel'] : ''}  `, fontSize: 6, border: [true, false, true, false], alignment: 'center' },
            { text: `${item.grupo['ficha_numero']}`, fontSize: 6, border: [true, false, true, false], alignment: 'center' },
            { text: `${this.funcaoService.mascarar('#.#.##.##', item.grupo['codigo_despesa'])} ${item.grupo['nome_despesa'] || ''}`, fontSize: 6, border: [true, false, true, false] },
            { text: `${this.funcaoService.convertToBrNumber(+item.totalizadores['valor_orcado'] + +item.totalizadores['total_creditado'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
            { text: `${this.funcaoService.convertToBrNumber(item.totalizadores['empenhado_mes'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
            { text: `${this.funcaoService.convertToBrNumber(item.totalizadores['empenhado'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
            { text: `${this.funcaoService.convertToBrNumber((+item.totalizadores['valor_orcado'] + +item.totalizadores['total_creditado']) - +item.totalizadores['empenhado'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
            { text: `${this.funcaoService.convertToBrNumber(item.totalizadores['liquidado_mes'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
            { text: `${this.funcaoService.convertToBrNumber(item.totalizadores['liquidado'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
            { text: `${this.funcaoService.convertToBrNumber(+item.totalizadores['empenhado'] - +item.totalizadores['liquidado'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
            { text: `${this.funcaoService.convertToBrNumber(item.totalizadores['pago_mes'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
            { text: `${this.funcaoService.convertToBrNumber(item.totalizadores['pago'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
            { text: `${this.funcaoService.convertToBrNumber(+item.totalizadores['liquidado'] - +item.totalizadores['pago'])}`, fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          ]);
        }
      }
    }
  }

  private async conteudoAssinatura(login: any, exercicio: Exercicio, mes: number, dataFinal: string) {
    const conteudo = [];
    const data = mes ? this.funcaoService.converteDataSQL(`${this.funcaoService.ultimoDiaMes(+mes, exercicio.ano)}/${this.funcaoService.strZero(mes, 2)}/${exercicio.ano}`) : dataFinal; 
    this.assinatura = await this.assinaturaService.obter({ orgao_id: login.orgao.id, 'data_limite$ge': data, 'orderBy': 'data_limite$ASC' }).toPromise();

    if (!this.assinatura) {
      toastr.error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
      throw new Error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
    }

    conteudo.push([
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      },
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      },
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      }
    ]);
    conteudo.push([
      { text: this.assinatura.ordenador_despesa, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.contador, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.tesoureiro, border: [false, false, false, false], bold: true, alignment: 'center' }
    ]);
    conteudo.push([
      { text: this.assinatura.cargo_ordenador_despesa, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.cargo_contador + (this.assinatura.crc_contador ? ` - ${this.assinatura.crc_contador}` : ''), border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.cargo_tesoureiro, border: [false, false, false, false], bold: true, alignment: 'center' }
    ]);

    return [{
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', '*', '*'],
        body: conteudo
      }
    }];
  }
}
