import { Component, OnInit, OnDestroy } from '@angular/core';
import { Sistemas, LoginService, LoginPublico, GlobalService } from 'eddydata-lib';
import { PrimeNGConfig } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  public mostrarMenu = false;
  public sistema: Sistemas = 'transparencia';
  public contraste = false;
  public color = '#eeeeee';
  public login: LoginPublico;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(private config: PrimeNGConfig, private authService: LoginService) {
  }

  ngOnInit() {
    this.config.setTranslation(new GlobalService().obterDataBR());
    var url = window.location.href;

    if(url.includes("login")){
      this.mostrarMenu = false;
    }else{
      this.mostrarMenu = true;
    }

    let interval = setTimeout(() => {
      this.login = GlobalService.obterSessaoLogin();
      if (this.login) {
        this.login.contraste = sessionStorage.getItem('contraste') === 'true';
        this.contraste = this.login.contraste;
      }  else {
        this.authService.usuarioEstaAutenticadoFake().then(() => {
          this.contraste = this.login.contraste
          location.reload();
        });
      }


      this.authService.mostrarMenuEmitter.pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (mostrar: boolean) => {
            this.mostrarMenu = mostrar;
          }
        );

      this.authService.sistemaEmitter.pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (sistema: Sistemas) => {
            this.sistema = sistema;
          }
        );
      this.authService.mostrarContrasteEmitter.pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (mostrar: boolean) => {
            this.color = mostrar ? '#757575' : '#eeeeee';
          }
        );

        clearInterval(interval);
    }, 5000);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  copyright(): string {
    return `Copyright © ${new Date().getFullYear()} Eddydata - Versão `;
  }

  versao(): string {
    return this.login?.versao;
  }
}
