import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TransferenciaBancariaService } from 'administrativo-lib';
import { GlobalService, Login, OrgaoAssinaturaService } from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AutorizacaoTransferenciaRpt } from '../autorizacao-transferencia-rpt';

import * as toastr from "toastr";
import { TransferenciaBancariaRpt } from '../transferencia-bancaria-rpt';

@Component({
  selector: 'app-transferencia-bancaria-dlg',
  templateUrl: './transferencia-bancaria-dlg.component.html'
})
export class TransferenciaBancariaDlgComponent implements OnInit, OnDestroy {

  protected datepipe: DatePipe;
  public ptBR: any;
  public selectedOrdem: string;
  public selectedOpt: string;
  public selectNaoImpresso: boolean;
  public selectUsuario: boolean;
  public selectEntreOrgao: boolean;
  public marcarComoImpresso: boolean;
  public dataInicio: Date;
  public dataFinal: Date;
  public numeroInicio: number;
  public numeroFinal: number;
  public relatorio = '1';
  public listaRelatorio: Array<any>;
  protected unsubscribe: Subject<void> = new Subject();

  public imaskNumero = {
    mask: Number,
    scale: 0,
    signed: false
  };

  @Input() login: Login;

  constructor(
    protected confirmationService: ConfirmationService,
    protected messageService: MessageService,
    private globalService: GlobalService,
    protected assinaturaService: OrgaoAssinaturaService,
    private transferenciaService: TransferenciaBancariaService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.selectedOrdem = 'ord1';
    this.selectedOpt = 'opt1';
    this.selectNaoImpresso = true;
    this.selectUsuario = true;
    this.dataInicio = new Date();
    this.dataFinal = new Date();
    this.listaRelatorio = [];
    this.listaRelatorio.push({ label: 'AUTORIZAÇÃO DE TRANSFERÊNCIA', value: 1 });
    this.listaRelatorio.push({ label: 'LISTAGEM TRANSFERÊNCIAS', value: 2 });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  imprimir() {
    this.gerarImpressao();
  }

  gerarImpressao() {
    if (!this.validar()) return;

    const parametros = {};

    let relations = '';
    relations += 'conta_pagadora,conta_pagadora.banco,conta_pagadora.orgao,conta_recebedora,conta_recebedora.orgao,conta_recebedora.banco,exercicio,orgao,usuario_cadastro';
    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;

    if (this.selectedOpt === 'opt1') {
      parametros['data_transfere$ge'] = this.datepipe.transform(this.dataInicio, 'yyyy-MM-dd');
      parametros['data_transfere$le'] = this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd');
    } else {
      parametros['id$ge'] = this.numeroInicio;
      parametros['id$le'] = this.numeroFinal;
    }
    parametros['orderBy'] = this.selectedOrdem === 'ord1' ? 'data_transfere' : 'id';
    if (this.selectNaoImpresso) {
      parametros['impresso'] = !this.selectNaoImpresso;
    }
    if (this.selectUsuario) {
      parametros['usuario_cadastro_id'] = this.login.usuario.id;
    }
    
    if (this.selectEntreOrgao) {
      parametros['OR'] = `conta_pagadora.orgao.id$ne=${this.login.orgao.id};!;!;conta_recebedora.orgao.id$ne=${this.login.orgao.id}`;
    } else {
      parametros['conta_pagadora.orgao.id'] = this.login.orgao.id;
      parametros['conta_recebedora.orgao.id'] = this.login.orgao.id;
    }

    if (this.relatorio === '1') {
      this.transferenciaService
        .extendido(1, -1, parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(lista => {
          if (lista.content.length === 0)
            return toastr.warning(`Nenhum registro encontrado!`)
          parametros['opcao'] = this.selectedOpt === 'opt1' ? 1 : 2;
          if (this.selectNaoImpresso) {
            this.confirmationService.confirm({
              message: 'Deseja marcar as autorizações de transferências como impressas ?',
              acceptLabel: "Sim",
              rejectLabel: "Não",
              header: 'Confirmação',
              icon: 'pi pi-info-circle',
              accept: () => {
                this.transferenciaService.marcarComoImpresso(parametros).subscribe(() => { });
                new AutorizacaoTransferenciaRpt(this.assinaturaService).imprimir(lista.content, this.login).then(() => { });
              },
              reject: () => { new AutorizacaoTransferenciaRpt(this.assinaturaService).imprimir(lista.content, this.login).then(() => { }) }
            });
          } else {
            new AutorizacaoTransferenciaRpt(this.assinaturaService).imprimir(lista.content, this.login).then(() => { })
          }
        }, (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        ));
    } else {
      this.transferenciaService
        .extendido(1, -1, parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(lista => {
          if (lista.content.length === 0)
            return toastr.warning(`Nenhum registro encontrado!`)
          new TransferenciaBancariaRpt().imprimir(lista.content, this.login, this.dataInicio, this.dataFinal).then(() => { })
        }, (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        ));
    }
  }

  private validar() {
    if (this.selectedOpt === 'opt1' && (!this.dataInicio || !this.dataFinal)) {
      toastr.warning(`Informe a data ${this.dataInicio ? 'final!' : 'inicial!'}`);
      return false;
    }

    if (this.selectedOpt === 'opt2' && (!this.numeroInicio || !this.numeroFinal)) {
      toastr.warning(`Informe o numero ${this.numeroInicio ? 'final!' : 'inicial!'}`);
      return false;
    }

    return true;
  }

}
