import { Component, Injector, ElementRef, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { AcaoService } from '../../acao/service/acao.service';
import { AcaoFonteRecursoService } from '../service/acao-fonte-recurso.service';
import { takeUntil } from 'rxjs/operators';
import {
  LoginContabil, GlobalService, FuncaoService, BaseResourceFormComponent,
  AcaoGoverno, AcaoFonteRecurso, EddyAutoComplete, Acao, Executora, MetaGoverno, ExecutoraService, Indicador
} from 'eddydata-lib';
import { MetaGovernoService, AcaoGovernoService } from 'administrativo-lib';

declare var $: any;

@Component({
  selector: 'app-acao-governo-form',
  templateUrl: './acao-governo-form.component.html'
})
export class AcaoGovernoFormComponent extends BaseResourceFormComponent<AcaoGoverno, LoginContabil> {
  /**
   * Declaração de variáveis
   */
  @ViewChild('tipo_') inputField: ElementRef;

  public listaTipos: Array<any>;
  public listaAcoesRecurso = new Array<AcaoFonteRecurso>();
  public acaoAutoComplete: EddyAutoComplete<Acao>;
  public executoraAutoComplete: EddyAutoComplete<Executora>;
  public metaAutoComplete: EddyAutoComplete<MetaGoverno>;
  public totalCustoAnual: number;
  public totalCustoDetalhado: number;
  public anoCorrente: number;
  indicador : Indicador = new Indicador();

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected confirmationService: ConfirmationService,
    protected messageService: MessageService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected metaService: MetaGovernoService,
    protected acaoRecursoService: AcaoFonteRecursoService,
    protected acService: AcaoService,
    protected executoraService: ExecutoraService,
    protected acaoService: AcaoGovernoService) {
    super(new AcaoGoverno(), injector, AcaoGoverno.converteJson, acaoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      aux: [0, [Validators.required]],
      tipo: ['I', [Validators.required]],
      custo1: [null, [Validators.required]],
      custo2: [null, [Validators.required]],
      custo3: [null, [Validators.required]],
      custo4: [null, [Validators.required]],
      meta1: [null],
      meta2: [null],
      meta3: [null],
      meta4: [null],
      acao: [null, [Validators.required]],
      meta_acao: [null, [Validators.required]],
      unidade_medida: [null, [Validators.required]],
      executora: [null, [Validators.required]],
      meta: [null, [Validators.required]],
      indicador: [null],
      ppa: [this.login.ppa, [Validators.required]],
      acoes_recurso: [],
      enviado_tce: [null]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'acao,executora,meta,meta.programa,meta.funcao,meta.subfuncao,meta.indicadores,indicador' };
  }

  protected afterLoad() {
    this.acaoRecursoService.filtrar(0, -1, {
      acao_governo_id: this.entidade.id, ano: this.login.exercicio.ano,
      relations: 'recurso,aplicacao,despesa'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaAcoesRecurso = res.content ? res.content : new Array<AcaoFonteRecurso>();
      });
    if (this.entidade.indicador?.id) {
      this.mudarMetas(this.entidade.indicador);
      this.indicador = this.entidade.indicador;
    }
  }

  protected afterInit(): void {
    this.listaTipos = this.globalService.obterListaTiposPpa();
    this.listaTipos.push({ id: 'N', nome: 'INCLUSÃO' });
    this.carregarAutoCompletes();
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  public mudarMetas(indicador: Indicador) {
    this.indicador = this.entidadeForm.get('indicador').value;
  }

  protected beforeSubmit() {
    try {
      if (!this.entidadeForm.get('meta_acao').value) {
        throw new Error('Informe o alvo');
      }
      if (!this.entidadeForm.get('acao').value) {
        throw new Error('Informe a ação de governo');
      }
      if (!this.entidadeForm.get('meta').value) {
        throw new Error('Informe a meta');
      }
      if (!this.entidadeForm.get('tipo').value) {
        throw new Error('Informe o tipo da ação');
      }
      if (!(this.entidadeForm.get('meta').value instanceof Object)) {
        throw new Error('Selecione um programa de governo válido');
      }
      if (!(this.entidadeForm.get('acao').value instanceof Object)) {
        throw new Error('Selecione uma ação de governo válida');
      }
      if ((+(+this.totalCustoDetalhado).toFixed(2) - +this.totalCustoAnual) !== 0 && +this.totalCustoDetalhado !== 0) {
        throw new Error('O total detalhado não confere com o custo total para o ano!');
      }

      this.entidadeForm.get('acoes_recurso').setValue(this.listaAcoesRecurso);
      this.entidadeForm.get('indicador').patchValue(this.indicador)
      this.entidadeForm.get('meta1').setValue(this.indicador.meta1);
      this.entidadeForm.get('meta2').setValue(this.indicador.meta2);
      this.entidadeForm.get('meta3').setValue(this.indicador.meta3);
      this.entidadeForm.get('meta4').setValue(this.indicador.meta4);
      this.entidadeForm.get('unidade_medida').setValue(this.indicador.unidade_medida);

    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(ent: AcaoGoverno) {
    this.acaoRecursoService.filtrar(0, -1, {
      acao_governo_id: this.entidade.id, ano: this.anoCorrente,
      relations: 'recurso,aplicacao,despesa'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaAcoesRecurso = res.content ? res.content : new Array<AcaoFonteRecurso>();
      });
    if (this.limparTela) {
      this.entidadeForm.reset();
      this.entidadeForm.get('aux').setValue(0);
      this.entidadeForm.get('tipo').setValue('I');
      this.entidadeForm.get('ppa').setValue(this.login.ppa);
      this.router.navigate(['/acoes-governo', 'novo']);
    }
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public atualizaIndicePPA(soma: any) {
    this.totalCustoAnual = soma.custo_anual;
    this.totalCustoDetalhado = soma.total_detalhado;
  }

  public atualizarLista(lista: any) {
    this.listaAcoesRecurso = lista;
  }

  public atualizarAnoCorrente(ano: any) {
    this.anoCorrente = +ano;
  }

  public async existeAcao() {
    var acaoPreenchida = this.entidadeForm.get('acao') ? this.entidadeForm.get('acao').value.split(' ') : null

    if (acaoPreenchida && (acaoPreenchida[0].length && this.funcaoService.isNumber(acaoPreenchida[0]))) {
      const acao = await this.acService.obter(
        {
          codigo: acaoPreenchida[0],
          ppa_id: this.login.ppa.id
        }
      ).pipe(takeUntil(this.unsubscribe)).toPromise();

      if (!acao) {
        this.confirmationService.confirm({
          message: 'A ação informada não está cadastrada. Deseja cadastrá-la?',
          header: 'Cadastro',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Sim',
          rejectLabel: 'Não',
          accept: () => {
            $('#dialogAcao').modal('show');
          },
          reject: () => {
            this.entidadeForm.get('acao').reset();
          }
        });
      }
    }
  }

  public updateAcao(event: any) {
    this.entidadeForm.get('acao').setValue(JSON.parse(JSON.stringify(event)).event);
  }

  private carregarAutoCompletes() {
    // autocomplete para acao
    this.acaoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('acao'), this.acService,
      'id', ['codigo', 'nome'], { ppa_id: this.login.ppa.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para executora
    this.executoraAutoComplete = new EddyAutoComplete(this.entidadeForm.get('executora'), this.executoraService,
      'id', ['codigo', 'nome'], { 'unidade.ppa_id': this.login.ppa.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para meta
    this.metaAutoComplete = new EddyAutoComplete(this.entidadeForm.get('meta'), this.metaService,
      'id', ['programa.codigo', 'programa.nome'], { ppa_id: this.login.ppa.id, relations: 'funcao,subfuncao,indicadores', orderBy: 'programa.nome,indicadores.codigo' },
      { number: ['programa.codigo'], text: ['programa.nome'] }
    );
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/planejamento']);
        break;
      default:
        this.sair();
        break;
    }
  }
}
