import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido } from 'eddydata-lib';

export class FavorecidoExterior extends BaseResourceModel {
    constructor(
        public indicativo_nif?: number,
        public nif?: string,
        public nif_tributo?: number,
        public logradouro?: string,
        public numero?: string,
        public complemento?: string,
        public bairro?: string,
        public cidade?: string,
        public estado?: string,
        public codPostal?: number,
        public telefone?: number,
        public cod_pais?: number,
        public favorecido?: Favorecido
    ) {
        super();
    }
    static converteJson(json: any): FavorecidoExterior {
        return Object.assign(new FavorecidoExterior(), json);
    }
}
