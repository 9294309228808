import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, Ldo, Page } from 'eddydata-lib';
import { HttpParams } from '@angular/common/http';

export interface ParametrosDemonstrativo {
  ano: number;
  orgaos: number[];
  ppa: number;
}

interface DemonstrativoBase {
  tipo: string;
  linha: number;
  titulo: boolean;
  somador: boolean;
  linhaAgrupamento: string;
}

export interface DemonstrativoDescricao1 extends DemonstrativoBase {
  descricao1: string;
  valor1: number;
  valorSomado1: number;
}

export interface DemonstrativoDescricao2 extends DemonstrativoBase {
  descricao2: string;
  valor2: number;
  valorSomado2: number;
}

export type Dados = DemonstrativoDescricao1 & DemonstrativoDescricao2;

@Injectable({
  providedIn: 'root'
})
export class LdoService extends BaseResourceService<Ldo> {

  constructor(
    protected injector: Injector
  ) {
    super(`ldo`, injector);
  }



  public demonstrativo1(ano: number, orgao: number) {
    return this.http.post(`${this.login.cidade.id}/${this.api}/demonstrativo-1/${ano}/${orgao}`, null,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public demonstrativo2(ano: number, orgao: number) {
    return this.http.post(`${this.login.cidade.id}/${this.api}/demonstrativo-2/${ano}/${orgao}`, null,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public demonstrativo3(ano: number, orgao: number) {
    return this.http.post(`${this.login.cidade.id}/${this.api}/demonstrativo-3/${ano}/${orgao}`, null,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public demonstrativo4(ano: number, orgao: number) {
    return this.http.post(`${this.login.cidade.id}/${this.api}/demonstrativo-4/${ano}/${orgao}`, null,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public demonstrativo5(ano: number, cidade: number, orgao: number) {
    return this.http.post(`${this.login.cidade.id}/${this.api}/demonstrativo-5?ano=${ano}&cidade=${cidade}&orgao=${orgao}`, null,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public demonstrativo6(ano: number, orgao: number) {
    return this.http.post(`${this.login.cidade.id}/${this.api}/demonstrativo-6/${ano}/${orgao}`, null,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public demonstrativo7(ano: number, cidade: number, orgao: number) {
    return this.http.post(`${this.login.cidade.id}/${this.api}/demonstrativo-7?ano=${ano}&cidade=${cidade}&orgao=${orgao}`, null,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public demonstrativo8(ano: number, cidade: number, orgao: number) {
    return this.http.post(`${this.login.cidade.id}/${this.api}/demonstrativo-8?ano=${ano}&cidade=${cidade}&orgao=${orgao}`, null,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public riscosFiscaisProvidencias(ano: number, cidade: number, orgao: number) {
    return this.http.post(`${this.login.cidade.id}/${this.api}/riscos-fiscais-providencias?ano=${ano}&cidade=${cidade}&orgao=${orgao}`, null,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
  public filtrar(filtros?: {}): Observable<Page> {
    let parametros = '';
    if (filtros) {
      // tslint:disable-next-line: forin
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + filtros[key];
      }
    }

    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/filtrar/${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public importarDemonstrativo(tipo: string, exercicioAtual: number, exercicioImportar: number, orgao: number) {
    return this.http.post(`${this.login.cidade.id}/${this.api}/importar-demonstrativo/${tipo}/${exercicioAtual}/${exercicioImportar}/${orgao}`, null,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public buscarDescricao1(dados: ParametrosDemonstrativo): Observable<[DemonstrativoDescricao1[], DemonstrativoDescricao2[]]> {
    const params = new HttpParams().appendAll({
      ...dados
    });

    return this.http.get<[DemonstrativoDescricao1[], DemonstrativoDescricao2[]]>(`${this.login.cidade.id}/${this.api}/buscar-descricao-1`, { params, ...this.httpOptions() }).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
