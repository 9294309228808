import { BaseResourceModel } from '../../models/base-resource.model';
import { RecursoSiconfiComplemento } from "./recurso-siconfi-complemento.model";

export class RecursoSiconfiComplementoReceita extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public siconfi?: RecursoSiconfiComplemento,) {
    super();
  }
  static converteJson(json: any): RecursoSiconfiComplementoReceita {
    return Object.assign(new RecursoSiconfiComplementoReceita(), json);
  }
}