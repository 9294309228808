import { Component, Input, OnInit } from '@angular/core';
import { Licitacao, RegistroPrecos } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-autorizacao',
  templateUrl: './licitacao-audesp-autorizacao.component.html'
})
export class LicitacaoAudespAutorizacaoComponent extends BaseLicitacaoAudesp implements OnInit {

  @Input() public registroPreco: 'S' | 'N';
  @Input() public entidade: RegistroPrecos;
  @Input() public licitacaoA: Licitacao;

  public opcoesSituacaoLicitacao: { id: number, nome: string }[];

  constructor(
  ) {
    super()
  }

  ngOnInit(): void {
    this.opcoesSituacaoLicitacao = [
      { id: 3, nome: 'Adjudicada' },
      { id: 6, nome: 'Anulada' },
      { id: 2, nome: 'Deserta' },
      { id: 1, nome: 'Fracassada' },
      { id: 7, nome: 'Homologação Parcial' },
      { id: 11, nome: 'Homologada' },
      { id: 10, nome: 'Homologada - Registro de Preço' },
      { id: 5, nome: 'Outra' },
      { id: 4, nome: 'Revogada' },
    ]
  }
}
