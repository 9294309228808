import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { PessoaModule, SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { ExecutoraFormComponent } from './executora-form/executora-form.component';
import { UnidadeFormComponent } from './unidade-form/unidade-form.component';
import { UnidadeListComponent } from './unidade-list/unidade-list.component';
import { UnidadeMembroComponent } from './unidade-membro/unidade-membro.component';
import { UnidadeRoutingModule } from './unidade-routing.module';
import { UnidadeViewComponent } from './unidade-view/unidade-view.component';

@NgModule({
  declarations: [
    UnidadeListComponent,
    UnidadeFormComponent,
    UnidadeViewComponent,
    ExecutoraFormComponent,
    UnidadeMembroComponent,
  ],
  imports: [
    CommonModule,
    UnidadeRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    ConfirmDialogModule,
    PessoaModule
  ],
  providers: [ConfirmationService, MessageService]
})
export class UnidadeModule { }
