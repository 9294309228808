import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagamentoRestoListComponent } from './pagamento-resto-list/pagamento-resto-list.component';
import { PagamentoRestoFormComponent } from './pagamento-resto-form/pagamento-resto-form.component';
import { PagamentoRestoRptComponent } from './pagamento-resto-rpt/pagamento-resto-rpt.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: PagamentoRestoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: PagamentoRestoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: PagamentoRestoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: PagamentoRestoFormComponent, canActivate: [AuthGuard] },
  { path: 'relatorios', component: PagamentoRestoRptComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagamentoRestoRoutingModule { }
