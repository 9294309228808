import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { BaseResourceListComponent, Coluna, Compra, Filtro, FuncaoService, GlobalService, LoginContabil } from 'eddydata-lib';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { CompraService } from '../../compra/service/compra.service';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-exportar-empenho',
  templateUrl: './exportar-empenho.component.html',
  styleUrls: ['./exportar-empenho.component.css']
})
export class ExportarEmpenhoComponent extends BaseResourceListComponent<Compra, LoginContabil> {

  /**
   * Declaração de variáveis
   */

  public listaFiltros: Coluna[] = [];
  public menuFiltros: MenuItem[] = [];
  public ptBR: any;
  public datepipe: DatePipe;

  public selecionados: Compra[] = [];

  public mes: string;

  public visualizarId: number;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private funcaoService: FuncaoService,
    public compraService: CompraService,
    protected globalService: GlobalService) {
    super(compraService, injector);
    this.datepipe = new DatePipe('pt');
    this.ptBR = new GlobalService().obterDataBR();
    this.usarExtendido = true;
    this.usarFiltroPersonalizado = true;
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'orgao,favorecido,ficha,ficha.despesa,requerente,subelemento,rcms,contrato_aditamento,modalidade,contrato.tipo_contratacao,subelemento';
  }

  protected condicoesGrid(): {} {
    return {
      'empenho.id$null': true,
      'exercicio.id': this.login.exercicio.id,
      'orgao.id': this.login.orgao.id,
      'excluido': false
    }
  }

  protected ordenacaoGrid(): string[] {
    return ['data_compra$DESC', 'numero$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {}
  }

  public beforeInit(): void {
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: Compra): Observable<Compra> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return null;
  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================


  public exportarCompras() {
    if (!this.selecionados || this.selecionados.length === 0) {
      toastr.warning('Não foram selecionadas ordens de fornecimento para exportação');
      return;
    }

    let material: Compra[] = []
    let servico: Compra[] = []

    for (let item of this.selecionados) {
      if (item.contrato && !item.contrato.saldo_quantidade) {
        if (item.contrato?.tipo_contratacao?.material && !item?.subelemento?.material) {
          servico.push(item)
        }

        if (item.contrato?.tipo_contratacao?.servico && !item?.subelemento?.servico) {
          material.push(item)
        }
      }
    }

    if (servico.length || material.length) {
      if (servico.length) {
        let servicoNumbers = servico.map(item => item.numero).join(', ');
        toastr.error(`O tipo do contrato informado nas compras nº ${servicoNumbers} está marcado como Materiais, porém os subelementos das despesas informados não estão habilitados para materiais!`)
      }
      if (material.length) {
        let materialNumbers = material.map(item => item.numero).join(', ');
        toastr.error(`O tipo do contrato informado nas compras nº ${materialNumbers} está marcado como Serviços, porém os subelementos das despesas informados não estão habilitados para serviços!`)
      }
      return
    }

    this.compraService.exportarCompra(this.selecionados, this.login.exercicio.id, this.login.orgao.id)
      .subscribe((data) => {
        const empenhos = data.map((c) => c.empenho.numero.toString() + '/' + c.empenho.exercicio.ano)
          .join(', ')
        toastr.success(`Ordens de fornecimento foram exportadas gerando os empenhos (${empenhos}).`);
        this.selecionados = [];
        this.preencherGrid();
      }, (error) => toastr.error(error.error.payload))
  }


  public obterColunasfiltroPersonalizado(): Coluna[] {

    const ultimoDia = this.funcaoService.ultimoDiaMes(this.login.mesReferencia + 1, this.login.exercicio.ano);

    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Nº OF', coluna: 'numero', alignment: 'center', tipo: 'Number' });
    retorno.push({
      titulo: 'Data Compra', coluna: 'data_compra', tipo: 'Date', alignment: 'center', padrao: true, cols: 12, colsPart: [12, 10],
      filtro1: new Date(this.login.exercicio.ano, this.login.mesReferencia, 1), filtro2: new Date(this.login.exercicio.ano, this.login.mesReferencia, ultimoDia)
    });
    retorno.push({ titulo: 'Nº Requisição', coluna: 'rcms.numero', alignment: 'center', tipo: 'Number', cols: 12 });
    retorno.push({ titulo: 'Fornecedor', coluna: 'favorecido.nome', tipo: 'String', cols: 12 });
    retorno.push({ titulo: 'Ficha', coluna: 'ficha.numero', alignment: 'center', tipo: 'String', cols: 12 });
    retorno.push({ titulo: 'Despesa', coluna: 'ficha.despesa.codigo', alignment: 'center', tipo: 'String', cols: 12 });
    retorno.push({ titulo: 'Requerente', coluna: 'requerente.nome', tipo: 'String', cols: 12 });
    retorno.push({ titulo: 'Contrato', coluna: 'contrato.numero', alignment: 'center', tipo: 'String', cols: 12, mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }] });
    retorno.push({ titulo: 'Licitação', coluna: 'licitacao.numero', alignment: 'center', tipo: 'String', cols: 12, mask: '00000/0000' });
    retorno.push({ titulo: 'Processo', coluna: 'processo', alignment: 'center', tipo: 'String', cols: 12, mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }] });
    retorno.push({ titulo: 'Lote', coluna: 'rcms.numero_lote', tipo: 'Number', padrao: true, cols: 12 })
    return retorno;
  }

  public selecionar(compra: Compra, select: boolean) {
    if (select) {
      this.selecionados.push(compra);
      this.selecionados.sort((a, b) => +a.numero - +b.numero);
    }
    else
      this.selecionados = this.selecionados.filter((s) => s.id !== compra.id);
  }

  public validSelect(compra: Compra) {
    const sel = this.selecionados.find((s) => s.id === compra.id);
    return !(!sel)
  }

  public ngClassCols(cols: number, padrao: string): {} {
    let ngClass = {};
    if (cols)
      ngClass[`col-sm-${cols}`] = true;
    else
      ngClass[padrao] = true;
    return ngClass;
  }

  public todosSelecionados(): boolean {
    return !this.lista ? true : this.lista.filter((l) => this.validSelect(l)).length === this.lista.length;
  }

  public selecionarTodos() {
    let todosSel = this.todosSelecionados();
    let lista: any[] = [];
    if (this.lista) {
      this.lista.forEach((l) => {
        if (!todosSel && !this.validSelect(l)) {
          this.selecionar(l, true);
          lista.push(l);
        } else if (todosSel && this.validSelect(l))
          this.selecionar(l, false);
      });
    }
  }
}
