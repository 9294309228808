import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido } from 'eddydata-lib';

export class FavorecidoDependente extends BaseResourceModel {
    constructor(
        public cpf?: string,
        public relacao?: number,
        public descricao?: string,
        public favorecido?: Favorecido,
        public valor_pensao?: number,
        public valor_pensao_suspensa?: number,
        public valor_deducao?: number,
        public valor_deducao_suspensa?: number,
        public valor_deducao_saude?: number,
        public valor_reembolso_saude?: number,

    ) {
        super();
    }
    static converteJson(json: any): FavorecidoDependente {
        return Object.assign(new FavorecidoDependente(), json);
    }
}
