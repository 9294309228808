import { Directive, OnDestroy } from '@angular/core';
import { FuncaoService, GlobalService, Ldo, LoginContabil, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LdoService } from '../../../../planejamento/ldo/service/ldo.service';
import { SelectItem } from 'primeng/api/selectitem';

@Directive()
export class Demo8Ldo implements OnDestroy {
  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();
  listaOrgaos: SelectItem[];
  constructor(
    protected ano: number,
    protected ldoServico: LdoService,
    protected orgaos: number[] = [],
    listaOrgaos?: SelectItem[]) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
    if (listaOrgaos) this.listaOrgaos = listaOrgaos;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio() {
    this.ldoServico.filtrar({
      tipo$in: 'D80', 'orgao.id$in': this.orgaos, ano: this.ano, relations: 'ppa', orderBy: 'tipo,linha'
    })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        const lista70 = [];
        let agrupamento = this.funcaoService.agrupar(dados.content, ['linha', 'descricao1', 'descricao2', 'titulo', 'somador'], ['valor1', 'valor2', 'valor3'])
        for (const dado of agrupamento) {
          let item: Ldo = {
            linha: dado.grupo['linha'],
            descricao1: dado.grupo['descricao1'],
            descricao2: dado.grupo['descricao2'],
            titulo: dado.grupo['titulo'],
            somador: dado.grupo['somador'],
            valor1: dado.totalizadores['valor1'],
            valor2: dado.totalizadores['valor2'],
          }
          lista70.push(item)
        }

        const lista = [];
        this.soma(lista70)
        lista.push(
          this.conteudo(lista70)
        );
        Relatorio.imprimirPersonalizado(
          'AMF/Tabela 8 - DEMONSTRATIVO 8 – MARGEM DE EXPANSÃO DAS DESPESAS OBRIGATÓRIAS DE CARÁTER CONTINUADO'
          , this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          lista,
          'portrait', 'DEMONSTRATIVO VIII - LDO',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private soma(lista: Ldo[]) {
    let linha3 = {
      valor1: 0,
      valor2: 0,
      valor3: 0,
    }
    let linha5 = {
      valor1: 0,
      valor2: 0,
      valor3: 0,
    }
    let linha6 = {
      valor1: 0,
      valor2: 0,
      valor3: 0,
    }
    for (const it of lista) {
      if (it.linha === 0) {
        linha3.valor1 += +it.valor1;
        linha3.valor2 += +it.valor2;
        linha3.valor3 += +it.valor3;
      }
      if (it.linha === 1 || it.linha === 2) {
        linha3.valor1 -= +it.valor1;
        linha3.valor2 -= +it.valor2;
        linha3.valor3 -= +it.valor3;
      }

      if (it.linha === 7 || it.linha === 8) {
        linha6.valor1 += +it.valor1;
        linha6.valor2 += +it.valor2;
        linha6.valor3 += +it.valor3;
      }
    }
    // atualiza sub-totais
    for (const it of lista) {
      if (it.linha === 3) {
        it.valor1 = +linha3.valor1;
        it.valor2 = +linha3.valor2;
        it.valor3 = +linha3.valor3;
      }
      if (it.linha === 3 || it.linha === 4) {
        linha5.valor1 += +it.valor1;
        linha5.valor2 += +it.valor2;
        linha5.valor3 += +it.valor3;
      }
      if (it.linha === 5) {
        it.valor1 = linha5.valor1;
        it.valor2 = linha5.valor2;
        it.valor3 = linha5.valor3;
      }
      if (it.linha === 6) {
        it.valor1 = +linha6.valor1;
        it.valor2 = +linha6.valor2;
        it.valor3 = +linha6.valor3;
      }
      if (it.linha === 9) {
        it.valor1 = (+linha5.valor1) - +linha6.valor1;
        it.valor2 = (+linha5.valor2) - +linha6.valor2;
        it.valor3 = (+linha5.valor3) - +linha6.valor3;
      }
    }
  }

  private conteudo(dados: any[]): {}[] {
    if (dados.length === 0) {
      return;
    }
    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `LEI DE DIRETRIZES ORÇAMENTÁRIAS`, fontSize: 11,
        alignment: 'center', colSpan: 2, border: [false, false, false, false], margin: [0, 3, 0, 3]
      }, ''],
      [{
        text: `ANEXO DE  METAS FISCAIS`, fontSize: 11,
        alignment: 'center', colSpan: 2, border: [false, false, false, false], margin: [0, 3, 0, 3]
      }, ''],
      [{
        text: `MARGEM DE EXPANSÃO DAS DESPESAS OBRIGATÓRIAS DE CARÁTER CONTINUADO`, fontSize: 11,
        alignment: 'center', bold: true, colSpan: 2, border: [false, false, false, false], margin: [0, 3, 0, 3]
      }, ''],
      [{
        text: `EXERCÍCIO: ${this.ano}`, fontSize: 11,
        alignment: 'center', bold: true, colSpan: 2, border: [false, false, false, false], margin: [0, 3, 0, 3]
      }, ''],
      [{
        text: 'AMF - Demonstrativo 8 (LRF, art. 4°, § 2°, inciso V)', fontSize: 7,
        alignment: 'left', bold: true, colSpan: 2, border: [false, false, false, false]
      }, ''],
      [{
        text: 'EVENTOS', fontSize: 12,
        alignment: 'center',
        bold: true,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      },
      {
        text: `Valor Previsto para ${this.ano}`,
        alignment: 'center',
        bold: true,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      }]
    ];
    // monta o agrupamento do relatório
    for (const item of dados) {
      registros.push([
        { text: item.descricao1, fontSize: 8, border: [true, false, false, false], bold: item.somador || item.titulo ? true : false, alignment: item.titulo ? 'center' : 'left' },
        {
          text: item.valor1 ? this.funcaoService.convertToBrNumber(item.valor1, 2, true) : '', alignment: 'right',
          fontSize: 8, border: [true, false, true, false], bold: item.somador || item.titulo ? true : false
        }
      ]);
    }

    registros.push([
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] }
    ]);

    if (this.listaOrgaos?.length > 0) {
      registros.push([{ text: '\n\n', colSpan: 2, border: [false, false, false, false] }, '']);
      registros.push([{ text: this.listaOrgaos.filter(o => this.orgaos.some(org => org === o.value)).map(o => o.label).join('\n'), fontSize: 8, colSpan: 2, border: [false, false, false, false] }, '']);
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 80],
        body: registros
      }
    }];
  }
}
