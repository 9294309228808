// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ProdutoConcentracao } from './produto-concentracao.model';
import { Produto } from './produto.model';

export class ProdutoMedicamento extends BaseResourceModel {
  constructor(
    public id?: number,
    public tipo_medicamento?: 'N' | 'A' | 'B' | 'C',
    public classificacao?: string,
    public produto?: Produto,
    public interacoes_medicamentosas?: Produto[],
    public concentracoes?: ProdutoConcentracao[],
  ) {
    super();
  }

  static converteJson(json: any): ProdutoMedicamento {
    return Object.assign(new ProdutoMedicamento(), json);
  }
}
