import { BaseResourceModel } from "../../models/base-resource.model";
import { Orgao } from "../comum/orgao.model";
import { Favorecido } from "./favorecido.model";

export class FavorecidoCei extends BaseResourceModel {
    constructor(
        public id?: number,
        public cei?: number,
        public ativo?: boolean,
        public editavel?: boolean,
        public favorecido?: Favorecido,
        public orgao?: Orgao
    ) {
        super();
    }
    static converteJson(json: any): FavorecidoCei {
        return Object.assign(new Favorecido(), json);
    }
}