import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-filtro',
  templateUrl: './filtro.component.html'
})
export class FiltroComponent implements OnInit {

  @Input() filtro: string;
  @Input() placeholder: string = 'Informe o que deseja localizar';

  @Output() localizaKeydown = new EventEmitter<string>();
  @Output() localizaClick = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  onKeydown(event: any) {
    if (event.key === 'Enter') {
      document.getElementById('button-addon2').click();
    }
  }

  onClick() {
    this.localizaClick.emit(this.filtro);
  }

}
