import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { TransferenciaEntreOrgaoLstComponent } from './transferencia-entre-orgao-lst/transferencia-entre-orgao-lst.component';
import { TransferenciaEntreOrgaoShwComponent } from './transferencia-entre-orgao-shw/transferencia-entre-orgao-shw.component';

const routes: Routes = [
  {
    path: 'pesquisa/:pagina/:data1/:data2',
    component: TransferenciaEntreOrgaoLstComponent, canActivate: [AuthGuard]
  },
  {
    path: 'pesquisa/:pagina/:data1/:data2/:filtro',
    component: TransferenciaEntreOrgaoLstComponent, canActivate: [AuthGuard]
  },
  {
    path: 'visualiza/:id/:pagina/:data1/:data2',
    component: TransferenciaEntreOrgaoShwComponent, canActivate: [AuthGuard]
  },
  {
    path: 'visualiza/:id/:pagina/:data1/:data2/:filtro',
    component: TransferenciaEntreOrgaoShwComponent, canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransferenciaBancariaRoutingModule { }

