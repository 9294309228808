import { Component, OnInit, AfterViewInit, ViewChild, Input, Output, EventEmitter, ElementRef, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProgramaService } from '../service/programa.service';
import { Programa, LoginContabil, FuncaoService, GlobalService } from 'eddydata-lib';

@Component({
  selector: 'app-programa-dlg',
  templateUrl: './programa-dlg.component.html'
})
export class ProgramaDlgComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('f') formGroup: any;

  public entity: Programa = new Programa();
  protected unsubscribe: Subject<void> = new Subject();

  @Input() login: LoginContabil;
  @Output() atualizarPrograma = new EventEmitter();

  @ViewChild('codigoe') public inputCodigo: ElementRef;
  @ViewChild('btnFecharPrograma') public btnFecharPrograma: ElementRef;

  constructor(
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected programaService: ProgramaService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    new GlobalService().calendarMascara();
    if (this.entity) {
      setTimeout(() => this.inputCodigo.nativeElement.focus(), 3000);
    }
  }

  public salvarPrograma(item: any) {
    try {
      if (!item.codigo) {
        throw new Error('Informe o código do programa!');
      }
      if (!item.nome) {
        throw new Error('Informe o nome do programa!');
      }
      item.ppa = this.login.ppa;
      this.programaService.inserir(item).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.atualizarPrograma.emit({ event: res });
          this.messageService.add({ severity: 'success', summary: 'Informação', detail: 'Programa inserido com sucesso.' });
        });
      this.btnFecharPrograma.nativeElement.click();
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

}
