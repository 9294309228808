// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Reavaliacao } from './reavaliacao.model';
import { Tombamento } from './tombamento.model';

export class ReavaliacaoItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public aux?: number,
    public valor_anterior?: number,
    public valor_cotacao1?: number,
    public valor_cotacao2?: number,
    public valor_cotacao3?: number,
    public valor_reavaliacao?: number,
    public reavaliacao?: Reavaliacao,
    public tombamento?: Tombamento,
  ) {
    super();
  }

  static converteJson(json: any): ReavaliacaoItem {
    return Object.assign(new ReavaliacaoItem(), json);
  }
}
