// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { AudienciaPublica } from './audiencia-publica.model';

export class AudienciaSugestao extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_sugestao?: Date,
    public sugestao?: string,
    public audiencia?: AudienciaPublica,
    public aceito?: number,
    public rejeitado?: number,
  ) {
    super();
  }

  static converteJson(json: any): AudienciaSugestao {
    return Object.assign(new AudienciaSugestao(), json);
  }
}
