import { Injectable, Injector } from '@angular/core';
import { InventarioSetorStorage } from '../../../entidade/patrimonio/inventario-setor-storage.model';
import { BaseResourceService } from '../../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class SetorAnexoListService extends BaseResourceService<InventarioSetorStorage> {

  constructor(
    protected injector: Injector
  ) {
    super(`inventario-setor-storages`, injector);
  }

}
