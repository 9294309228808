import { BaseResourceStorage } from '../../models/base-resource.storage';
import { BaixaPatrimonio } from './baixa-patrimonio.model';

export class BaixaStoragePatrimonio extends BaseResourceStorage {
  constructor(
    public baixa?: BaixaPatrimonio,
  ) {
    super();
  }

  static converteJson(json: any): BaixaStoragePatrimonio {
    return Object.assign(new BaixaStoragePatrimonio(), json);
  }
}
