import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FuncaoService } from 'eddydata-lib';

@Directive()
export class Anexo8ControleDisponibilidade implements OnDestroy {

  protected funcaoService: FuncaoService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number) {
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(dados: any[]): {}[] {

    const registros: {}[] = [
      [{
        text: 'CONTROLE DA DISPONIBILIDADE FINANCEIRA E CONCILIAÇÃO BANCÁRIA',
        alignment: 'center',
        bold: true, fontSize: 6
      },
      {
        text: 'FUNDEB (ae)',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'SALÁRIO EDUCAÇÃO (af)',
        alignment: 'center',
        bold: true, fontSize: 6
      }]
    ];

    const grupos2 = this.funcaoService.agrupar(dados, 'grupo', ['fundeb', 'salario_educacao']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;

    for (const grupo of grupos2) {

      if (grupo.grupo === '48- DISPONIBILIDADE FINANCEIRA EM 31 DE DEZEMBRO DE <EXERCÍCIO ANTERIOR>' || grupo.grupo === '49- (+) INGRESSO DE RECURSOS ATÉ O BIMESTRE (orçamentário)' || grupo.grupo === '50- (-) PAGAMENTOS EFETUADOS ATÉ O BIMESTRE (orçamentário e restos a pagar)') {
        total1 += +grupo.totalizadores['fundeb'];
        total2 += +grupo.totalizadores['salario_educacao'];
      } else {
        total3 += +grupo.totalizadores['fundeb'] + total1;
        total4 += +grupo.totalizadores['salario_educacao'] + total2;
      }

      if (grupo.grupo === '52- (+) AJUSTES POSITIVOS (RETENÇÕES E OUTROS VALORES EXTRAORÇAMENTÁRIOS)') {
        registros.push([
          { text: '51- (=) DISPONIBILIDADE FINANCEIRA ATÉ O BIMESTRE', fontSize: 7, bold: true },
          { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 7, bold: true },
          { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 7, bold: true },
        ])
      }
      registros.push([
        { text: grupo.grupo, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['fundeb']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['salario_educacao']), alignment: 'right', fontSize: 7 }
      ]);

      const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['fundeb', 'salario_educacao']);
      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo != '') {
          registros.push([
            { text: subgrupo.grupo, fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['fundeb']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['salario_educacao']), alignment: 'right', fontSize: 7 }
          ]);

          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              registros.push([
                { text: item.nome, fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.fundeb), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.salario_educacao), alignment: 'right', fontSize: 7 }
              ]);
            }
          }
        }
      }
    }

    registros.push([
      { text: '54- (=) SALDO FINANCEIRO CONCILIADO (Saldo Bancário)', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total3 - total1), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total4 - total2), alignment: 'right', fontSize: 7, bold: true },
    ]);

    registros.push(
      [{
        text: 'FONTE: Sistema de Admnistração de Finanças Públicas, Unidade Responsável: Secretaria Municipal de Finanças',
        fontSize: 8, colSpan: 3, border: [false, false, false, false]
      }, '', ''],
      [{
        text: '1  SE RESULTADO LÍQUIDO DA TRANSFERÊNCIA (7) > 0 = ACRÉSCIMO RESULTANTE DAS TRANSFERÊNCIAS DO FUNDEB, SE RESULTADO LÍQUIDO DA TRANSFERÊNCIA (7) < 0 = DECRÉSCIMO RESULTANTE DAS TRANSFERÊNCIAS DO FUNDEB',
        fontSize: 8, colSpan: 3, border: [false, false, false, false]
      }, '', ''],
      [{
        text: '2 Limites mínimos anuais a serem cumpridos no encerramento do exercício.',
        fontSize: 8, colSpan: 3, border: [false, false, false, false]
      }, '', ''],
      [{
        text: '3 Art. 25, § 3º, Lei 14.113/2020: “Até 10% (dez por cento) dos recursos recebidos à conta dos Fundos, inclusive relativos à complementação da União, nos termos do § 2º do art. 16 desta Lei, poderão ser utilizados no primeiro quadrimestre do exercício imediatamente subsequente, mediante abertura de crédito adicional.”',
        fontSize: 8, colSpan: 3, border: [false, false, false, false]
      }, '', ''],
      [{
        text: '4 Os valores referentes à parcela dos Restos a Pagar inscritos sem disponibilidade financeira vinculada à educação deverão ser informados somente no RREO do último bimestre do exercício.',
        fontSize: 8, colSpan: 3, border: [false, false, false, false]
      }, '', ''],
      [{
        text: '5 Nos cinco primeiros bimestres do exercício o acompanhamento será feito com base na despesa liquidada. No último bimestre do exercício, o valor deverá corresponder ao total da despesa empenhada. ',
        fontSize: 8, colSpan: 3, border: [false, false, false, false]
      }, '', ''],
      [{
        text: '6 As linhas representam áreas de atuação e não correspondem exatamente às subfunções da Função Educação. As despesas classificadas nas demais subfunções típicas e nas subfunções atípicas deverão ser rateadas para essas áreas de atuação.',
        fontSize: 8, colSpan: 3, border: [false, false, false, false]
      }, '', ''],
      [{
        text: '7 Valor inscrito em RPNP sem disponibilidade de caixa, que não deve ser considerado na apuração dos indicadores e limites',
        fontSize: 8, colSpan: 3, border: [false, false, false, false]
      }, '', ''],
      [{
        text: '8 Controle da execução de restos a pagar considerados no cumprimento do limite mínimo dos exercícios anteriores.',
        fontSize: 8, colSpan: 3, border: [false, false, false, false]
      }, '', ''],
    );

    return registros;

  }

  public montarQuadroCsv(dados: any[], listaExportar): any {

    const espaco = {
      titulo: '',
      coluna1: '',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
    }
    listaExportar.push(espaco)

    const primeira_linha = {
      titulo: 'CONTROLE DA DISPONIBILIDADE FINANCEIRA E CONCILIAÇÃO BANCÁRIA',
      coluna1: 'FUNDEB (ae)',
      coluna2: 'SALÁRIO EDUCAÇÃO (af)',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
    }
    listaExportar.push(primeira_linha)

    const grupos2 = this.funcaoService.agrupar(dados, 'grupo', ['fundeb', 'salario_educacao']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;

    for (const grupo of grupos2) {

      if (grupo.grupo === '48- DISPONIBILIDADE FINANCEIRA EM 31 DE DEZEMBRO DE <EXERCÍCIO ANTERIOR>' || grupo.grupo === '49- (+) INGRESSO DE RECURSOS ATÉ O BIMESTRE (orçamentário)' || grupo.grupo === '50- (-) PAGAMENTOS EFETUADOS ATÉ O BIMESTRE (orçamentário e restos a pagar)') {
        total1 += +grupo.totalizadores['fundeb'];
        total2 += +grupo.totalizadores['salario_educacao'];
      } else {
        total3 += +grupo.totalizadores['fundeb'] + total1;
        total4 += +grupo.totalizadores['salario_educacao'] + total2;
      }

      if (grupo.grupo === '52- (+) AJUSTES POSITIVOS (RETENÇÕES E OUTROS VALORES EXTRAORÇAMENTÁRIOS)') {
        const linha_52 = {
          titulo: '51- (=) DISPONIBILIDADE FINANCEIRA ATÉ O BIMESTRE',
          coluna1: this.funcaoService.convertToBrNumber(total1),
          coluna2: this.funcaoService.convertToBrNumber(total2),
          coluna3: '',
          coluna4: '',
          coluna5: '',
          coluna6: ''
        }
        listaExportar.push(linha_52)
      }
      const linha_grupo = {
        titulo: grupo.grupo,
        coluna1: this.funcaoService.convertToBrNumber(+grupo.totalizadores['fundeb']),
        coluna2: this.funcaoService.convertToBrNumber(+grupo.totalizadores['salario_educacao']),
        coluna3: '',
        coluna4: '',
        coluna5: '',
        coluna6: ''
      }
      listaExportar.push(linha_grupo)

      const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['fundeb', 'salario_educacao']);
      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo != '') {
          const linha_subgrupo = {
            titulo: subgrupo.grupo,
            coluna1: this.funcaoService.convertToBrNumber(+subgrupo.totalizadores['fundeb']),
            coluna2: this.funcaoService.convertToBrNumber(+subgrupo.totalizadores['salario_educacao']),
            coluna3: '',
            coluna4: '',
            coluna5: '',
            coluna6: ''
          }
          listaExportar.push(linha_subgrupo)

          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              const linha_subgrupo_item = {
                titulo: item.nome,
                coluna1: this.funcaoService.convertToBrNumber(item.fundeb),
                coluna2: this.funcaoService.convertToBrNumber(item.salario_educacao),
                coluna3: '',
                coluna4: '',
                coluna5: '',
                coluna6: ''
              }
              listaExportar.push(linha_subgrupo_item)
            }
          }
        }
      }
    }

    const linha_54 = {
      titulo: '54- (=) SALDO FINANCEIRO CONCILIADO (Saldo Bancário)',
      coluna1: this.funcaoService.convertToBrNumber(total3 - total1),
      coluna2: this.funcaoService.convertToBrNumber(total4 - total2),
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: ''
    }
    listaExportar.push(linha_54)
  }

}