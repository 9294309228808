import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FuncaoService, RequisicaoItemMovimento } from 'eddydata-lib';
declare var $: any;

@Component({
  selector: 'app-aceite-requisicao-dlg',
  templateUrl: './aceite-requisicao-dlg.component.html'
})
export class AceiteRequisicaoDlgComponent implements OnInit, OnChanges {


  @Input() public visualizar: boolean = false;
  @Output() public visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Input() lista: RequisicaoItemMovimento[] = [];

  @Output() onFinalizar: EventEmitter<RequisicaoItemMovimento[]> = new EventEmitter();

  constructor(
    public funcaoService: FuncaoService) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void { }


  public finalizar() {
    this.hide();
    this.onFinalizar.emit(this.lista);
  }


  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

}
