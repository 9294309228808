import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { BaseResourceService, MetaGoverno, Page } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class MetaGovernoService extends BaseResourceService<MetaGoverno> {

  constructor(
    protected injector: Injector
  ) {
    super(`metas-governo`, injector);
  }

  public anexo2(pagina: number, limite: number, ppa: number, orgaos: number[], opt:number, ano:number, desconsiderarIndicadoresZeradosExercicio?: boolean): Observable<Page> {
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/anexo2/${pagina}/${limite}/${ppa}/${orgaos.join()}/${opt}/${ano}/${desconsiderarIndicadoresZeradosExercicio}`,
      this.httpOptions()).pipe(
        map(res => res),
        take(1),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorNumero(numero: number, ano: number, orgaoId: number): Observable<MetaGoverno> {
    return this.http.get<MetaGoverno>(
      `${this.login.cidade.id}/${this.api}/filtrar?numero=${numero}&exercicio.ano=${ano}&orgao_id=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
