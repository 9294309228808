// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Setor } from '../almoxarifado/setor.model';
import { Pessoa } from '../comum/pessoa.model';
import { Usuario } from '../comum/usuario.model';
import { TipoTransferenciaPatrimonio } from './tipo-transferencia-patrimonio.model';
import { TransferenciaEmprestimoPatrimonio } from './transferencia-emprestimo-patrimonio.model';
import { TransferenciaItemPatrimonio } from './transferencia-item-patrimonio.model';

export class TransferenciaPatrimonio extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: number,
    public data_transferencia?: Date,
    public motivo?: string,
    public status_itens?: 'PENDENTE' | 'PARCIAL' | 'ACEITO' | 'RECUSADO' | 'ACEITO_PARCIAL' | 'EXCLUIDO',
    public solicitacao?: boolean,
    public enviar_email?: boolean,
    public data_exclusao?: Date,
    public motivo_exclusao?: string,
    public destino?: Setor,
    public tipo?: TipoTransferenciaPatrimonio,
    public usuario?: Usuario,
    public usuario_exclusao?: Usuario,
    public recebedor?: Pessoa,
    public emprestimo?: TransferenciaEmprestimoPatrimonio,
    public itens?: TransferenciaItemPatrimonio[],
  ) {
    super();
  }

  static converteJson(json: any): TransferenciaPatrimonio {
    return Object.assign(new TransferenciaPatrimonio(), json);
  }
}
