import { FuncaoService, LoginContabil, MovimentoItemEstoque, Relatorio } from "eddydata-lib";

export class RelatorioEntradasCompraRpt {


    private funcaoService: FuncaoService;

    constructor(
        private login: LoginContabil) {
        this.funcaoService = new FuncaoService();
    }


    public imprimir(compra: { numero: number, valor_total: number, forncedor: string }, itens: MovimentoItemEstoque[]) {
        Relatorio.imprimirPersonalizado('ENTRADA DE MATERIAIS', this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            this.montarConteudo(compra, itens), 'landscape', 'ENTRADA DE MATERIAIS');
    }

    private montarConteudo(compra: { numero: number, valor_total: number, forncedor: string }, itens: MovimentoItemEstoque[]): {}[] {
        const conteudo = [];


        conteudo.push({
            table: {
                headerRows: 2,
                body: this.montarDadosCompra(compra)
                    .concat(this.montarLinhas(itens)),
                widths: ['auto', 'auto', 'auto', '*', 'auto', '*', 'auto', 60, 60]
            }
        })

        return conteudo;
    }


    private montarDadosCompra(compra: { numero: number, valor_total: number, forncedor: string },): {}[] {
        const header = [];

        header.push({ text: `FORNECEDOR:   ${compra.forncedor}`, fontSize: 9, bold: true, colSpan: 4, alignment: 'left' }, { text: '' }, { text: '' }, { text: '' });
        header.push({ text: `Nº. OF.: ${compra.numero}`, fontSize: 11, bold: true, colSpan: 2, alignment: 'left', border: [true, true, false, true] }, { text: '' });
        header.push({ text: `VL. TOTAL:             ${this.funcaoService.convertToBrNumber(compra.valor_total)}`, fontSize: 11, bold: true, colSpan: 3, alignment: 'right', border: [false, true, true, true] }, { text: '' }, { text: '' });

        return [header]
    }

    private montarLinhas(itens: MovimentoItemEstoque[]): {}[] {
        const linhas = []

        ///header
        linhas.push([
            { text: 'DATA', fontSize: 8, bold: true, alignment: 'center' },
            { text: 'DOCUMENTO', fontSize: 8, bold: true, alignment: 'center' },
            { text: 'CÓDIGO', fontSize: 8, bold: true, alignment: 'center' },
            { text: 'PRODUTO', fontSize: 8, bold: true, alignment: 'center' },
            { text: 'UNIDADE', fontSize: 8, bold: true, alignment: 'center' },
            { text: 'ESTOQUE', fontSize: 8, bold: true, alignment: 'center' },
            { text: 'QUANTIDADE', fontSize: 8, bold: true, alignment: 'center' },
            { text: 'VL. UNITÁRIO', fontSize: 8, bold: true, alignment: 'center' },
            { text: 'VL. TOTAL', fontSize: 8, bold: true, alignment: 'center' }
        ]);

        let valor_total = 0;

        ///linhas
        for (let item of itens) {
            valor_total += (+item.qtde * +item.valor_unitario);

            linhas.push([
                { text: this.funcaoService.converteDataBR(item.movimento.data_movimento), fontSize: 8, border: [false, false, false, false], alignment: 'center' },
                { text: item.movimento.documento, fontSize: 8, border: [false, false, false, false], alignment: 'center' },
                { text: item.produto_unidade.produto.codigo, fontSize: 8, border: [false, false, false, false], alignment: 'center' },
                { text: item.produto_unidade.produto.nome, fontSize: 8, border: [false, false, false, false], alignment: 'left' },
                { text: item.produto_unidade.unidade.nome, fontSize: 8, border: [false, false, false, false], alignment: 'center' },
                { text: `${item.movimento.setorAlmoxarifado.estoque.id} - ${item.movimento.setorAlmoxarifado.estoque.nome}`, fontSize: 8, border: [false, false, false, false], alignment: 'left' },
                { text: this.funcaoService.convertToBrNumber(item.qtde), fontSize: 8, border: [false, false, false, false], alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber(item.valor_unitario), fontSize: 8, border: [false, false, false, false], alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber(+item.qtde * +item.valor_unitario), fontSize: 8, border: [false, false, false, false], alignment: 'right' }
            ]);
        }

        linhas.push([
            {
                text: [
                    { text: `TOTAL GERAL:         `, bold: true },
                    { text: this.funcaoService.convertToBrNumber(valor_total) }
                ], fontSize: 10, alignment: 'right', colSpan: 9, border: [false, false, false, false]
            }, { text: '' }, { text: '' }, { text: '' }, { text: '' }, { text: '' }, { text: '' }, { text: '' }, { text: '' }
        ]);

        return linhas;
    }


}