export class PagamentoArray {
    CodigoAjuste: number;
    Pagto: Pagto[];
}

export class Pagto {
    MedicaoNumero: number;
    NotaEmpenhoNumero: string;
    NotaEmpenhoDataEmissao: string;
    DocumentoFiscalNumero: string;
    DocumentoFiscalDataEmissao: string;
    DocumentoFiscalUF: string;
    NotaFiscalValorPago: number;
    NotaFiscalPagtoDt: string;
    RecolhidoEncargosPrevidenciario: 'S' | 'N'
    editavel?: boolean;
    liquidacaoId?: number;
    pagamentoId?: number;
}

export class ArrayPagamento {
    ArrayPagamento: {
        CodigoAjuste: number;
        Pagto: Pagto;
    }[]
}