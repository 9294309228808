import { BaseResourceStorage } from "../../models/base-resource.storage";
import { Rcms } from "./rcms.model";

export class RcmsStorage extends BaseResourceStorage {
  constructor(
    public visivel_transparencia?: boolean,
    public rcms?: Rcms,
  ) {
    super();
  }

  static converteJson(json: any): RcmsStorage {
    return Object.assign(new RcmsStorage(), json);
  }
}