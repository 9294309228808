// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Pessoa } from '../comum/pessoa.model';
import { Licitacao } from './licitacao.model';

export class LicitacaoTestemunha extends BaseResourceModel {
  constructor(
    public id?: number,
    public pessoa?: Pessoa,
    public licitacao?: Licitacao,
  ) {
    super();
  }

  static converteJson(json: any): LicitacaoTestemunha {
    return Object.assign(new LicitacaoTestemunha(), json);
  }
}
