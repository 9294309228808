import { Component, Injector } from '@angular/core';
import { RelatorioPersonalizadoService } from '../service/relatorio-personalizado.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { BaseResourceListComponent, Filtro } from '../../models/base-resource-list';
import { RelatorioPersonalizado } from '../../entidade/comum/relatorio-personalizado.model';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { FuncaoService } from '../../util/funcao.service';
import { Coluna, Sistemas } from '../../components/types';
import { ConfirmationService } from 'primeng/api';


@Component({
  selector: 'lib-relatorio-personalizado-lst',
  templateUrl: './relatorio-personalizado-lst.component.html',
  providers: [ConfirmationService]
})
export class RelatorioPersonalizadoLstComponent extends BaseResourceListComponent<RelatorioPersonalizado, LoginContabil> {

  /**
   * Declaração de variáveis
   */

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private route: ActivatedRoute,
    protected confirmationService: ConfirmationService,
    protected funcaoService: FuncaoService,
    private relatorioService: RelatorioPersonalizadoService
  ) {
    super(relatorioService, injector);
  }

  private dicionarioSistema: { id: Sistemas; label: string; }[] = [
    {
      id: 'almoxarifado',
      label: 'Almoxarifado'
    },
    {
      id: 'compras',
      label: 'Compras'
    },
    {
      id: 'contabil',
      label: 'Contábil'
    },
    {
      id: 'licitacao',
      label: 'Licitação'
    },
    {
      id: 'patrimonio',
      label: 'Patrimônio'
    },
    {
      id: 'requisicao',
      label: 'Requisição'
    }
  ];

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'usuario';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id,
      ['ignoreCondObrig']: true
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['id$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['id'],
      text: ['nome', 'descricao', 'usuario.nome'],
    };
  }

  protected afterInit(): void {
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.preencherGrid();
        this.paginaCorrente = 1;
      });

  }

  protected acaoRemover(model: RelatorioPersonalizado): Observable<RelatorioPersonalizado> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [{ titulo: 'Número', coluna: 'id', alignment: 'right' },
    { titulo: 'Data', coluna: 'data_cadastro' },
    { titulo: 'Nome', coluna: 'nome' },
    ];
  }

  public imprimirPDF(): void {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public retornaSistema(sistema: Sistemas): string {
    const system = this.dicionarioSistema.find(s => s.id === sistema);

    if (!system) {
      const err = new Error('Sistema não cadastro no relatório personalizado.');
      console.error(err.message);

      throw err;
    }

    return `Sistema: ${system.label}`;
  }

  public remover(model: RelatorioPersonalizado) {
    this.confirmationService.confirm({
      message: 'Deseja realmente remover esse registro?',
      header: 'Cancelamento',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: () => {
        this.relatorioService.remover(model.id).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.success('Registro removido com sucesso!');
            this.preencherGrid();
          });
      },
      reject: () => {
        toastr.info('Operação foi cancelada!');
      }
    });
  }

  public exportarXLSX() {

  }

}
