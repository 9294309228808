// tslint:disable: variable-name

import { CredenciamentoAtividadeHistorico } from './credenciamento-atividade-historico.model';

export class CredenciamentoAtividadeArquivo {

    id: number;
    nome: string;
    caminho: string;
    tipo: string;
    documento: any;
    tamanho: number;
    credenciamentoAtividadehistorico: CredenciamentoAtividadeHistorico;
    data_cadastro: Date;
    data_alteracao: Date;

}
