import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { ModalidadeListComponent } from './modalidade-list/modalidade-list.component';
import { ModalidadeFormComponent } from './modalidade-form/modalidade-form.component';


const routes: Routes = [
  { path: '', component: ModalidadeListComponent, canActivate: [AuthGuard] },
  // { path: 'novo', component: ModalidadeFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: ModalidadeFormComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModalidadeRoutingModule { }
