import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AssinaturaService, BaseResourceRptPersonalizadoComponent, CpfPipe, EddyAutoComplete, Favorecido, FavorecidoService, GlobalService, Licitacao, LicitacaoPipe, Rpl } from "eddydata-lib";
import * as toastr from 'toastr';

@Component({
  selector: 'app-licitacao-recibo-rpt',
  templateUrl: './licitacao-recibo-rpt.component.html'
})
export class LicitacaoReciboRptComponent extends BaseResourceRptPersonalizadoComponent {

  @Input() public licitacao: Licitacao;

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  private semFavorecido: boolean = false;
  public semObjeto: boolean = false;
  public favorecido: Favorecido;
  public favorecidos: Favorecido[] = [];
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;

  constructor(
    protected assinaturaService: AssinaturaService,
    private favorecidoService: FavorecidoService,
    private globalService: GlobalService
  ) {
    super(assinaturaService);
    this.assinaturaObrigatoria = false;
  }

  protected afterInit(): void {
    this.carregarAutoComplete();
  }

  protected tituloRelatorio(): string {
    return `Divisão de Licitações e Compras`;
  }

  public selecionar(favorecido?: Favorecido) {
    if (favorecido)
      this.favorecido = favorecido;
    this.favorecidos.push(this.favorecido);

    setTimeout(() => {
      this.favorecido = null;
    }, 100);

  }

  public remover(index: number) {
    this.favorecidos.splice(index, 1);
  }

  protected async montarConteudo(): Promise<{}[]> {
    let favorecidos = [];

    if (this.semFavorecido)
      favorecidos = [new Favorecido()];
    else
      favorecidos = this.favorecidos;
    this.semFavorecido = false;

    return await new Promise(async (resolve) => {
      resolve(favorecidos.map((f, i) => {
        const recibo = this.recibo(f);
        if (i < favorecidos.length - 1)
          recibo['pageBreak'] = 'after';
        return recibo;
      }));
    })
  }

  public imprimirSemFavorecido() {
    this.semFavorecido = true;
    this.imprimir();
  }

  public imprimir(): void {
    if (!this.validar())
      return;
    super.imprimir();
  }

  private validar(): boolean {
    if (!this.licitacao?.id) {
      toastr.warning(`Não foi informado a licitação para impressão do recibo!`);
      return false;
    }
    if (!this.semFavorecido && (!this.favorecidos || this.favorecidos.length === 0)) {
      toastr.warning(`Não foi selecionado fornecedor!`);
      return false;
    }
    return true;
  }

  private carregarAutoComplete() {
    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService, 'id',
      ['cpf_cnpj', 'nome'], { relations: 'cidade' }, {
      number: ['cpf_cnpj'], text: ['nome']
    });
  }

  private recibo(favorecido: Favorecido, afterBreak?: boolean): {} {
    const msg = this.mensagem();
    const endereco = `${favorecido.endereco}, ${favorecido.num_endereco}`
    return {
      stack: [
        {
          columns: [
            {
              width: 130,
              stack: [
                { text: 'RAZÃO SOCIAL:', fontSize: 12, alignment: 'right', margin: [0, 5] },
                { text: 'ENDEREÇO:', fontSize: 12, alignment: 'right', margin: [0, 5] },
                { text: 'CIDADE:', fontSize: 12, alignment: 'right', margin: [0, 5] },
                { text: 'CNPJ:', fontSize: 12, alignment: 'right', margin: [0, 5] },
                { text: 'IE:', fontSize: 12, alignment: 'right', margin: [0, 5] },
              ]
            },
            {
              width: '*',
              stack: [
                { text: favorecido.nome ? favorecido.nome : '', fontSize: 12, bold: true, alignment: 'left', margin: [10, 5, 20, 5] },
                { text: favorecido?.endereco ? endereco : '', fontSize: 12, alignment: 'left', margin: [10, 5, 20, 5] },
                { text: favorecido.cidade?.nome ? favorecido.cidade.nome : '', fontSize: 12, alignment: 'left', margin: [10, 5, 20, 5] },
                { text: favorecido.cpf_cnpj ? new CpfPipe().transform(favorecido.cpf_cnpj) : '', fontSize: 12, alignment: 'left', margin: [10, 5, 20, 5] },
                { text: favorecido?.inscricao_estadual ? favorecido?.inscricao_estadual : '', fontSize: 12, alignment: 'left', margin: [10, 5, 20, 5] },
              ]
            }
          ]
        },
        { canvas: [{ type: 'line', x1: 20, y1: 10, x2: 515, y2: 10, lineWidth: 0.5 }] },
        { text: msg, fontSize: 11, alignment: 'justify', lineHeight: 2.0, margin: [20, 10, 20, 75], preserveLeadingSpaces: true }
      ]
    };
  }

  protected montarFooter(): any {
    let data = new Date();
    return (currentPage, pageCount, pageSize) => [
      { text: `${this.login.cidade.nome}, ${data.getDate()} de ${new GlobalService().obterMes(data.getMonth() + 1)} de ${data.getFullYear()}`, fontSize: 13, margin: [30, 10], alignment: 'right' },
      {
        columns: [
          {
            canvas: [
              { type: 'line', x1: 30, y1: 30, x2: 60, y2: 30, lineWidth: 1.0 },
              { type: 'line', x1: 30, y1: 30, x2: 30, y2: 54, lineWidth: 1.0 },
              { type: 'line', x1: 230, y1: 30, x2: 250, y2: 30, lineWidth: 1.0 },
              { type: 'line', x1: 250, y1: 30, x2: 250, y2: 54, lineWidth: 1.0 },
              { type: 'line', x1: 30, y1: 150, x2: 60, y2: 150, lineWidth: 1.0 },
              { type: 'line', x1: 30, y1: 126, x2: 30, y2: 150, lineWidth: 1.0 },
              { type: 'line', x1: 230, y1: 150, x2: 250, y2: 150, lineWidth: 1.0 },
              { type: 'line', x1: 250, y1: 126, x2: 250, y2: 150, lineWidth: 1.0 },
            ]
          },
          {
            width: '*',
            stack: [
              { text: '_________________________________________________', fontSize: 12, margin: [0, 35, 30, 5], alignment: 'right' },
              { text: 'Assinatura', fontSize: 11, margin: [0, 0, 0, 10], alignment: 'center' },
              { text: 'Nome : _________________________________________ ', fontSize: 12, margin: [0, 20, 30, 5], alignment: 'right' },
              { text: '  RG : ___________________________________________', fontSize: 12, margin: [0, 10, 30, 0], alignment: 'right', preserveLeadingSpaces: true },
            ]
          }
        ]
      }
    ]
  }

  public mensagem() {

    let objeto = ` de objeto ${this.licitacao.objeto}`;
    let data_envelope = '__/__/____ às __:__ hs';

    if (this.licitacao.data_abertura_env)
      data_envelope = `${this.funcaoService.converteDataBR(this.licitacao.data_abertura_env)} às ${this.funcaoService.converteHoraBr(this.licitacao.data_abertura_env)} hs`

    if (this.semObjeto)
      objeto = '';

    return `                      Recebi(emos) uma via do instrumento convocatório do(a) ${this.licitacao.modalidade.nome} N.º ${new LicitacaoPipe().transform(this.licitacao.numero)}, Processo Administrativo N.º ${new LicitacaoPipe().transform(this.licitacao.processo)}${objeto}, para participar de ato público cuja abertura dos envelopes será realizada no dia ${data_envelope}, na Sala de Licitações localizada à ${this.login.orgao.endereco} - ${this.login.orgao.bairro}, ${this.login.cidade.nome} - ${this.login.cidade.estado.uf}`;
  }

  protected margemRelatorio(): number[] {
    return [30, 140, 30, 200];
  }

  protected modalRelatorio(): string {
    return null;
  }

  protected layoutRelatorio(): {} {
    return null;
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }
}