
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { FieldsetModule } from 'primeng/fieldset';
import { ToastModule } from 'primeng/toast';
import { FracionamentoDlgFormComponent } from './fracionamento-dlg-form/fracionamento-dlg-form.component';
import { FracionamentoFormComponent } from './fracionamento-form/fracionamento-form.component';
import { FracionamentoListComponent } from './fracionamento-list/fracionamento-list.component';
import { FracionamentoRoutingModule } from './fracionamento-routing.module';
import { FracionamentoViewComponent } from './fracionamento-view/fracionamento-view.component';
import { InventarioEstoqueModule } from '../inventario-estoque/inventario-estoque.module';
import { ProdutoDlgModule } from '../produto/produto-dlg.module';

@NgModule({
  declarations: [FracionamentoListComponent, FracionamentoFormComponent, FracionamentoDlgFormComponent, FracionamentoViewComponent],
  exports: [FracionamentoDlgFormComponent],
  imports: [
    CommonModule,
    FracionamentoRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    CardModule,
    FieldsetModule,
    InventarioEstoqueModule,
    ProdutoDlgModule
  ]
})
export class FracionamentoModule { }
