import { BaseResourceModel } from '../../models/base-resource.model';
import { Unidade } from '../planejamento/unidade.model';

export class SetorProtocolo extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public sigla?: string,
    public email?: string,
    public telefone?: string,
    public cep?: string,
    public endereco?: string,
    public bairro?: string,
    public responsavel?: string,
    public cargo?: string,
    public unidade?: Unidade
    ) {
    super();
  }
  static converteJson(json: any): SetorProtocolo {
    return Object.assign(new SetorProtocolo(), json);
  }
}
