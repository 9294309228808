import { Component, Injector, ElementRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { EmpenhoRestoService } from '../service/empenho-resto.service';
import {
  BaseResourceFormComponent, EmpenhoResto, LoginContabil,
  GlobalService, DateFormatPipe, FavorecidoService, OrgaoAssinaturaService, Tabela1Reinf
} from 'eddydata-lib';
import { DespesaService } from 'administrativo-lib';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { NotaEmpenhoResto } from '../../relatorio/contabil/nota-empenho-resto';

@Component({
  selector: 'app-empenho-resto-view',
  templateUrl: './empenho-resto-view.component.html'
})
export class EmpenhoRestoViewComponent extends BaseResourceFormComponent<EmpenhoResto, LoginContabil> {
  /**
   * Declaração de variáveis
   */
  public empenhado: number;
  public liquidado: number;
  public pago: number;
  public totalCancelado: number;
  public gruposReinf: Array<any> = new Array<any>();
  public grupoReinf: any;

  public imaskSiconfi = {
    mask: Number,
    scale: 0,
    signed: true,
    normalizeZeros: true
  };

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected favorecidoService: FavorecidoService,
    protected despesaService: DespesaService,
    protected assinaturaService: OrgaoAssinaturaService,
    private messageService: MessageService,
    protected tabela1ReinfService: Tabela1Reinf,
    protected empenhoService: EmpenhoRestoService) {
    super(new EmpenhoResto(), injector, EmpenhoResto.converteJson, empenhoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      valor_empenho: 0,
      data_empenho: [null, [Validators.required]],
      data_vencimento: [null, [Validators.required]],
      tipo_empenho: [null, [Validators.required]],
      especie: [null, [Validators.required]],
      fase4: [null, [Validators.required]],
      adiantamento: [null, [Validators.required]],
      processo: [null, [Validators.required]],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      orgao: [null, [Validators.required]],
      favorecido: [null, [Validators.required]],
      despesa: [null, [Validators.required]],
      despesa_nome: [null, [Validators.required]],
      subelemento: [null, [Validators.required]],
      subelemento_nome: [null, [Validators.required]],
      acao: [null, [Validators.required]],
      acao_nome: [null, [Validators.required]],
      exercicio: [null, [Validators.required]],
      codigo_reinf: [null]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'favorecido,compra,contrato,licitacao,modalidade,convenio,orgao,exercicio' };
  }

  protected afterInit(): void {
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit() {
  }

  protected afterSubmit() {
  }

  protected async afterLoad() {
    this.entidade.data_empenho = new DateFormatPipe().transform(this.entidade.data_empenho, []);
    this.entidade.data_vencimento = new DateFormatPipe().transform(this.entidade.data_vencimento, []);

    this.gruposReinf = this.tabela1ReinfService.carregarTabela1();
    if (this.entidade?.codigo_reinf) {
      this.grupoReinf = this.tabela1ReinfService.carregarPorCodigo(this.entidade.codigo_reinf, false);
      //Por algum motivo, o select só seleciona o grupo correto com esse for.
      for (let i = 0; i < this.gruposReinf.length; i++) {
        if (this.gruposReinf[i].codigo == this.grupoReinf.codigo) {
          this.gruposReinf[i] = this.grupoReinf;
          break;
        }
      }
    }
    
    await this.loadTotalizadores();
    this.entidadeForm.get('codigo_reinf').enable()
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private async loadTotalizadores() {
    this.empenhado = 0;
    this.liquidado = 0;
    this.pago = 0;
    this.totalCancelado = 0;

    let totalizadores = await this.empenhoService.totalizadores(
      this.entidade.ano, this.login.orgao.id, this.login.exercicio.id, this.entidade.id).toPromise();

    if (totalizadores) {
      totalizadores = totalizadores.content[0] ? totalizadores.content[0] : totalizadores.content;
      this.empenhado = totalizadores.total_empenhado;
      this.liquidado = totalizadores.total_liquidado;
      this.pago = totalizadores.total_pago;
      this.totalCancelado = +totalizadores.total_cancelado_nao_processado + +totalizadores.total_cancelado_processado;

      this.totalCancelado = this.totalCancelado < 0 ? (this.totalCancelado * -1) : this.totalCancelado
    }
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  imprimir() {
    const parametros = {};

    let relations = 'contrato,compra.ficha,favorecido.contas.banco,convenio,modalidade,licitacao';

    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id'] = this.entidade.id;

    this.empenhoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        new NotaEmpenhoResto(this.assinaturaService).imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

  public alterarRecursoSiconfi(recurso_siconfi: string) {
    if (recurso_siconfi) {
      this.entidade['editavel'] = true;
      this.entidade['recurso_temporario'] = this.entidade.recurso_siconfi;
    }
  }

  public salvarRecursoSiconfi(recurso_siconfi: string) {
    if (recurso_siconfi) {
      this.entidade['editavel'] = false;
      if (!this.entidade.recurso_siconfi || this.entidade.recurso_siconfi === '') {
        this.entidade.recurso_siconfi = this.entidade['recurso_temporario'];
      } else {
        this.entidade.recurso_siconfi = recurso_siconfi
        this.empenhoService.atualizar(this.entidade).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.success('Recurso Siconfi alterado com sucesso!');
          }, error => {
            toastr.error(error.error.payload);
            throw error;
          });
      }
    }
  }

  public alterarComplementoSiconfi(complemento_siconfi: string) {
    this.entidade['editavelC'] = true;
    this.entidade['complemento_temporario'] = this.entidade.complemento_siconfi ?? '1';
  }

  public salvarComplementoSiconfi(complemento_siconfi: string) {
    if (complemento_siconfi) {
      this.entidade['editavelC'] = false;
      if (!this.entidade.complemento_siconfi || this.entidade.complemento_siconfi === '') {
        this.entidade.complemento_siconfi = this.entidade['complemento_temporario'];
      } else {
        this.entidade.complemento_siconfi = complemento_siconfi
        this.empenhoService.atualizar(this.entidade).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.success('Complemento Siconfi alterado com sucesso!');
          }, error => {
            toastr.error(error.error.payload);
            throw error;
          });
      }
    }
  }

  public salvarCodigoReinf() {
    this.entidade.codigo_reinf = this.entidadeForm.get('codigo_reinf').value
    console.log(this.entidade)
    this.empenhoService.atualizar(this.entidade).pipe(takeUntil(this.unsubscribe))
    .subscribe((res) => {
      toastr.success('Codigo Reinf alterado com sucesso!');
    }, error => {
      toastr.error(error.error.payload);
      throw error;
    });
  }
}