import { Component, ElementRef, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProdutoService } from 'almoxarifado-lib';
import { EddyAutoComplete, FuncaoService, GlobalService, Login, Memorial, MemorialItem, ParametroLicitacao, Produto, RcmsItem, RplItem } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { AutoComplete } from 'primeng/autocomplete';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { MemorialItemService } from './service/memorial-item.service';

declare var $: any;

@Component({
  selector: 'app-memorial-item',
  templateUrl: './memorial-item.component.html'
})
export class MemorialItemComponent implements OnInit, OnDestroy {

  @Input() public memorial: Memorial;
  @Input() login: Login;
  @Input() parametrosLicitacao: ParametroLicitacao;
  @Input() mostrarDescricao: boolean = false;

  public imaskQtd = {
    mask: Number,
    scale: 0,
    signed: false
  };

  public imaskConfig = {
    mask: Number,
    scale: 6,
    signed: true,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };
  public produtoAutoComplete: EddyAutoComplete<Produto>;
  public itemAtual: MemorialItem;

  @ViewChild('produto_item_') campoItem: AutoComplete;
  @ViewChild('quantidade_') campoQtde: ElementRef;
  @ViewChild('btnAdicionar') public btnAdicionar: ElementRef;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected injector: Injector,
    public funcaoService: FuncaoService,
    protected messageService: MessageService,
    protected globalService: GlobalService,
    protected produtoService: ProdutoService,
    protected itemService: MemorialItemService) {
  }

  ngOnInit(): void {
    this.produtoAutoComplete = ProdutoService.autoCompleteCodigoCompleto(null, this.produtoService,
      'id', ['codigo', 'nome'], { orgao_id: this.login.orgao.id, relations: 'unidades.unidade', orderBy: 'codigo,nome' }, { number: ['codigo'], text: ['codigo', 'nome'] }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public adicionar() {
    if (this.memorial.itens && this.memorial.itens.find((itm) => { return itm.editavel === true })) {
      toastr.info('Finalize a edição atual antes de incluir outro item');
      return;
    }

    this.itemAtual = new MemorialItem();
    this.itemAtual.editavel = true;

    if (!this.memorial.itens || this.memorial.itens.length === 0)
      this.memorial.itens = [];

    this.memorial.itens.unshift(this.itemAtual);
    this.btnAdicionar.nativeElement.disabled = true;
    this.focarItem();
  }

  public editar(item: MemorialItem) {
    if (this.memorial.itens && this.memorial.itens.find((itm) => { return itm.editavel === true })) {
      toastr.info('Finalize a edição atual antes de editar outro item');
      return;
    }

    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.produtoService.obter({ orgao_id: this.login.orgao.id, id: this.itemAtual.produto.id }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.itemAtual.produto = res;
        this.focarItem();
      }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));
    this.btnAdicionar.nativeElement.disabled = true;
  }

  public salvar(item: MemorialItem) {
    try {

      if (!item.produto) {
        throw new Error('Informe o produto/serviço!');
      }
      if (!item.quantidade || +item.quantidade <= 0) {
        throw new Error('Informe a quantidade!');
      }
      if (!item.valor_referencia || +item.valor_referencia <= 0) {
        throw new Error('Informe a valor referência!');
      }
      if (this.memorial.itens.find(m => m.produto.id === item.produto.id && m !== item)) {
        this.itemAtual.produto = undefined;
        this.itemAtual.quantidade = undefined;
        throw new Error('Item já incluído neste lote!');
      }

      this.selecionarUnidade(item)

      this.memorial.itens.map( mi => this.memorial.valor_referencia += (+mi.valor_referencia * +mi.quantidade))
      item.descricao = this.itemAtual.produto.descricao;

      this.btnAdicionar.nativeElement.disabled = false;
      this.itemAtual.editavel = false;
      this.memorial.quantidade = 1;
    } catch (e) {
      this.funcaoService.acaoErro(e)
      throw e;
    }
  }

  public cancelar(item: MemorialItem, index: number) {
    this.itemAtual = item;

    if (!item.id && !this.itemAtual.quantidade && !this.itemAtual.produto) {
      this.memorial.itens.splice(index, 1);
    } else {
      this.itemAtual.editavel = false;
    }
    this.btnAdicionar.nativeElement.disabled = false;
  }

  public remover(item: MemorialItem, index: number) {
    if (item.id) {
      this.itemService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          toastr.info('Registro removido com sucesso!', 'Exclusão');
          this.memorial.itens.splice(index, 1);
          this.btnAdicionar.nativeElement.disabled = false;
          this.memorial.quantidade = 1;
        }, (err) => toastr.error(err.error.payload));
    } else {
      this.memorial.itens.splice(index, 1);
      this.btnAdicionar.nativeElement.disabled = false;
      this.memorial.quantidade = 1;
    }
  }

  private focarItem() {
    setTimeout(() => this.campoItem ? this.campoItem.focusInput() : null, 500);
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public abrirConsultaProduto() {
    $('#dialogProdutoBusca').modal('show');
    $('#dialogMemorialItem').modal('hide');
  }

  public callbackProduto(prod: Produto) {
    if (this.itemAtual) {
      this.itemAtual.produto = prod;
      setTimeout(() => this.campoQtde ? this.campoQtde.nativeElement.focus() : null, 500);
      $('#dialogMemorialItem').modal('show');
    }
  }

  public callbackImportacaoRPL(itensRPL: RplItem[]) {
    itensRPL.forEach(rplItem => {
      const item: RplItem = Object.assign({}, rplItem);
      let lote: MemorialItem | 'N' = rplItem.memorial_item;

      if (lote && lote != 'N') { // soma quantidade do rpl ao item do memorial
        lote.quantidade = +lote.quantidade + +item.quantidade;
      } else { // inclui item do RPL ao memorial
        if (!this.memorial.itens || this.memorial.itens.length === 0) {
          this.memorial.itens = [];
        }

        // informações do item do RPL
        lote = new MemorialItem();
        lote.produto = item.produto_unidade.produto;
        lote.descricao = item.produto_unidade.produto.descricao;
        lote.produto_unidade = item.produto_unidade;
        lote.unidade = item.produto_unidade.unidade?.nome;
        lote.quantidade = +item.quantidade;

        this.memorial.valor_referencia = 0;
        if (item.cotacoes?.length > 0) {
          item.cotacoes.forEach(c => this.memorial.valor_referencia += +c.valor_unitario);
          this.memorial.valor_referencia = +(+this.memorial.valor_referencia / +item.cotacoes.length).toFixed(4);
          lote.valor_referencia = this.memorial.valor_referencia;
        }

        this.memorial.itens.push(lote);
      }

      // vincula item do RPL ao memorial para ser salvo na api
      delete item.memorial;
      delete item.memorial_item;
      if (!lote.rpl_itens) lote.rpl_itens = [];
      lote.rpl_itens.push(item);
    })
  }

  public callbackImportacaoRCMS(itensRCMS: RcmsItem[]) {
    itensRCMS.forEach(rcmsItem => {
      const item: RplItem = Object.assign({}, rcmsItem);
      let lote: MemorialItem | 'N' = rcmsItem.memorial_item;

      if (lote && lote != 'N') { // soma quantidade do rpl ao item do memorial
        lote.quantidade = +lote.quantidade + +item.quantidade;
      } else { // inclui item do RPL ao memorial
        if (!this.memorial.itens || this.memorial.itens.length === 0) {
          this.memorial.itens = [];
        }

        // informações do item do RPL
        lote = new MemorialItem();
        lote.produto = item.produto_unidade.produto;
        lote.descricao = item.produto_unidade.produto.descricao;
        lote.produto_unidade = item.produto_unidade;
        lote.unidade = item.produto_unidade.unidade?.nome;
        lote.quantidade = +item.quantidade;

        this.memorial.valor_referencia = 0;
        if (item.cotacoes?.length > 0) {
          item.cotacoes.forEach(c => this.memorial.valor_referencia += +c.valor_unitario);
          this.memorial.valor_referencia = +(+this.memorial.valor_referencia / +item.cotacoes.length).toFixed(4);
          lote.valor_referencia = this.memorial.valor_referencia;
        }

        this.memorial.itens.push(lote);
      }

      // vincula item do RPL ao memorial para ser salvo na api
      delete item.memorial;
      delete item.memorial_item;
      if (!lote.rcms_itens) lote.rcms_itens = [];
      lote.rcms_itens.push(item);
    })
  }

  public selecionarUnidade(item: MemorialItem) {
    if (!item.editavel) return;
    if (item && item.produto?.unidades?.length) {
      if (item.unidade) {
        item.produto_unidade = item.produto.unidades[0];
      } else {
        item.produto_unidade = item.produto.unidades[0]
        item.unidade = item.produto_unidade.unidade.nome;
      }
    }
  }
}
