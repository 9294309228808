import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Relatorio } from "eddydata-lib";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { QuadroEnsinoService } from "../service/quadro-ensino.service";
import { Injector } from "@angular/core";
import { Assinaturas } from "administrativo-lib";

type DadosReceita = {
    mes: number;
    anterior?: number;
    atual?: number;
}

type DadosDespesa = {
    mes: number;
    empenhada?: number;
    liquidada?: number;
}

export class ProjecaoQSE {
    protected unsubscribe: Subject<void> = new Subject();
    protected login: Login = new Login();
    protected funcaoService: FuncaoService;
    protected globalService: GlobalService;

    public valorRepasseTotal: number = 0;
    public valorRendimentosTotal: number = 0;

    //Antes Resumo
    public projecaoReceita: number = 0;
    public despesaEmpenhada: number = 0;
    public projecaoRendimento: number = 0;
    public projecaoDespesaLiquidada: number = 0;
    public saldoGeral: number = 0;
    public saldoResto: number = 0;
    public valorNaoLiquidadoObra: number = 0;
    public totalProvisionadoPorcentagem: number = 0;
    public valorNaoLiquidadoObraPorcentagem: number = 0;

    //Resumo Financeiro
    public saldoConta: number = 0;
    public saldoRelacaoEmpenhada: number = 0;
    public saldoRelacaoPgtResto: number = 0;
    public saldoRelacaoPgt: number = 0;

    constructor(
        protected quadroServico: QuadroEnsinoService,
        protected mes: number,
        protected exercicio: Exercicio,
        protected exercicioAnterior: Exercicio,
        protected injector?: Injector,
        protected assinatura_ensino?: boolean
    ) {
        this.globalService = new GlobalService();
        this.funcaoService = new FuncaoService();
        this.login = GlobalService.obterSessaoLogin();
    }

    public async montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
        this.quadroServico.obterProjecaoQSE(this.mes, this.exercicio.id, orgaos)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(async dados => {
                if (formato === 'pdf') {
                    Relatorio.imprimirPersonalizado(
                        'PROJEÇÃO DO QSE',
                        this.login?.usuario?.nome,
                        this.login?.usuario?.sobrenome,
                        this.login?.orgao?.nome,
                        this.login?.orgao?.brasao?.brasao,
                        this.conteudoReceita(dados[0])
                            .concat(this.conteudoDespesa(dados[1]))
                            .concat(this.conteudoDetalhes(dados[2], dados[3]))
                            .concat(await this.conteudoResumoFinanceiro(dados[3])),
                        'portrait',
                        'PROJECAO DO QSE',
                        {
                            linhas: {
                                hLineWidth() { return 1 },
                                vLineWidth() { return 1 },
                                hLineColor() { return 'black' },
                                paddingLeft() { return 3 },
                                paddingRight() { return 3 }
                            }
                        }, false, false, 'pdf'
                    );
                } else {
                    this.funcaoService.exportar(formato, this.conteudoExportar(dados), 'PROJEÇÃO DO QSE', this.colunasRelatorio())
                }
            });
    }

    private conteudoReceita(dados: any[]): {}[] {
        const conteudo = [];
        let valorRepasse: DadosReceita[] = [];
        let rendimentos: DadosReceita[] = [];
        if (dados[0]) {
            valorRepasse.unshift(...dados[0])
        }
        if (dados[1]) {
            rendimentos.unshift(...dados[1])
        }
        conteudo.push([
            {
                text: `PROJEÇÃO RECEITA QSE: REFERÊNCIA - ${this.globalService.obterMes(+this.mes)}/${this.exercicio.ano}`,
                bold: true, colSpan: 9, fontSize: 9, border: [false, false, false, false], margin: [0, -15, 0, 0]
            }, '', '', '', '', '', '', '', ''
        ]);

        conteudo.push([
            { text: 'MESES', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
            { text: 'VALOR REPASSE', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
            { text: '%', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
            { text: 'RENDIMENTOS', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
            { text: '%', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
            { text: 'TOTAL', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
        ]);

        conteudo.push([
            '',
            { text: this.exercicioAnterior.ano, alignment: 'center', bold: true, fontSize: 7 },
            { text: this.exercicio.ano, alignment: 'center', bold: true, fontSize: 7 },
            '',
            { text: this.exercicioAnterior.ano, alignment: 'center', bold: true, fontSize: 7 },
            { text: this.exercicio.ano, alignment: 'center', bold: true, fontSize: 7 },
            '',
            { text: this.exercicioAnterior.ano, alignment: 'center', bold: true, fontSize: 7 },
            { text: this.exercicio.ano, alignment: 'center', bold: true, fontSize: 7 },
        ]);

        let totalAnteriorRepasse: number = 0;
        let totalAtualRepasse: number = 0;
        let totalAnteriorRendimento: number = 0;
        let totalAtualRendimento: number = 0;
        let percentualRepasse: number = 0;
        let percentualRendimento: number = 0;


        for (let i = 1; i <= this.mes; i++) {
            if (i > (rendimentos.length)) {
                rendimentos.push({ mes: i, anterior: 0, atual: 0 })
            }

            rendimentos[i]['totalAnterior'] = 0;
            rendimentos[i]['totalAtual'] = 0;

            let j = 1;
            while (valorRepasse[j]) {
                if (valorRepasse[j]['mes'] === (i)) {
                    totalAnteriorRepasse += valorRepasse[j]?.['anterior'] || 0;
                    totalAtualRepasse += valorRepasse[j]?.['atual'] || 0;
                    percentualRepasse = (+valorRepasse[j]?.['atual'] / +valorRepasse[j]['anterior'] * 100) - 100;
                    valorRepasse[i]['percentual'] = percentualRepasse;
                    rendimentos[i]['totalAnterior'] += valorRepasse[j]?.['anterior'] || 0;
                    rendimentos[i]['totalAtual'] += valorRepasse[j]?.['atual'] || 0;
                    break;
                }
                j++;
            }

            j = 1;
            while (rendimentos[j]) {
                if (rendimentos[j]['mes'] === (i)) {
                    totalAnteriorRendimento += rendimentos[j]?.['anterior'] || 0;
                    totalAtualRendimento += rendimentos[j]?.['anterior'] || 0;
                    percentualRendimento = (+rendimentos[j]?.['atual'] / +rendimentos[j]['anterior'] * 100) - 100;
                    rendimentos[j]['percentual'] = percentualRendimento;
                    rendimentos[i]['totalAnterior'] += rendimentos[j]?.['anterior'];
                    rendimentos[i]['totalAtual'] += rendimentos[j]?.['atual'];
                    break;
                }
                j++;
            }
        }

        if (+this.mes < 12) {
            for (let i = +this.mes; i <= 12; i++) {
                if (i > (rendimentos.length)) {
                    rendimentos.push({ mes: i, anterior: 0, atual: 0 })
                }

                rendimentos[i]['totalAnterior'] = 0;
                rendimentos[i]['totalAtual'] = 0;

                const valorRepasseProjecao = valorRepasse[i]?.['anterior'];
                const projecaoRepasse = (valorRepasseProjecao * percentualRepasse) / 100;
                valorRepasse[i]['atual'] = valorRepasseProjecao + projecaoRepasse;
                valorRepasse[i]['percentual'] = percentualRepasse;
                rendimentos[i]['totalAnterior'] += +valorRepasse[i]?.['anterior'];
                rendimentos[i]['totalAtual'] += +valorRepasse[i]?.['atual'];

                const valorRendimento = rendimentos[i]?.['anterior'];
                const projecaoRendimento = (valorRendimento * percentualRendimento) / 100;
                rendimentos[i]['atual'] = valorRendimento + projecaoRendimento;
                rendimentos[i]['percentual'] = percentualRendimento;
                rendimentos[i]['totalAnterior'] += +rendimentos[i]?.['anterior'];
                rendimentos[i]['totalAtual'] += +rendimentos[i]?.['atual'];
            }
        }

        for (let i = 1; i <= 12; i++) {
            conteudo.push([
                { text: this.globalService.obterMes(rendimentos[i]?.['mes']), fontSize: 6, border: [true, false, true, false], alignment: 'left' },
                { text: this.funcaoService.convertToBrNumber(valorRepasse[i]?.['anterior']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber(valorRepasse[i]?.['atual']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber((valorRepasse[i]?.['percentual'] || 0).toFixed(2)), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber(rendimentos[i]?.['anterior']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber(rendimentos[i]?.['atual']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber((rendimentos[i]?.['percentual'] || 0).toFixed(2)), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber(rendimentos[i]?.['totalAnterior']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber(rendimentos[i]?.['totalAtual']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
            ]);
        }

        const totalizadorValorRepasseAnterior = valorRepasse.reduce((acumulador, receita) => acumulador += receita?.['anterior'] || 0, 0);
        const totalizadorValorRepasseAtual = valorRepasse.reduce((acumulador, receita) => acumulador += receita?.['atual'] || 0, 0);
        const totalizadorRendimentosAnterior = rendimentos.reduce((acumulador, receita) => acumulador += receita?.['anterior'] || 0, 0);
        const totalizadorRendimentosAtual = rendimentos.reduce((acumulador, receita) => acumulador += receita?.['atual'] || 0, 0);
        const totalizadorTotalAnterior = rendimentos.reduce((acumulador, receita) => acumulador += receita?.['totalAnterior'] || 0, 0);
        const totalizadorTotalAtual = rendimentos.reduce((acumulador, receita) => acumulador += receita?.['totalAtual'] || 0, 0);

        this.valorRepasseTotal = totalizadorValorRepasseAtual;
        this.valorRendimentosTotal = totalizadorRendimentosAtual;

        this.projecaoReceita = totalizadorTotalAtual;
        this.projecaoRendimento = totalizadorRendimentosAtual;

        conteudo.push([
            { text: 'TOTAL', fontSize: 7, bold: true, alignment: 'left' },
            { text: this.funcaoService.convertToBrNumber(totalizadorValorRepasseAnterior), fontSize: 7, bold: true, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(totalizadorValorRepasseAtual), fontSize: 7, bold: true, alignment: 'right' },
            { text: '' },
            { text: this.funcaoService.convertToBrNumber(totalizadorRendimentosAnterior), fontSize: 7, bold: true, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(totalizadorRendimentosAtual), fontSize: 7, bold: true, alignment: 'right' },
            { text: '' },
            { text: this.funcaoService.convertToBrNumber(totalizadorTotalAnterior), fontSize: 7, bold: true, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(totalizadorTotalAtual), fontSize: 7, bold: true, alignment: 'right' },
        ]);

        return [{
            layout: 'linhas',
            table: {
                widths: ['*', '*', '*', 'auto', '*', '*', 'auto', '*', '*'],
                body: conteudo
            }
        }];
    }

    private conteudoDespesa(dados: any[]) {
        const conteudo = [];
        const [despesas3350, despesas3390, despesas3391, despesas4490] = dados

        conteudo.push([
            {
                text: `PROJEÇÃO DESPESA QSE: REFERÊNCIA - ${this.globalService.obterMes(+this.mes)}/${this.exercicio.ano}`,
                bold: true, colSpan: 9, fontSize: 9, border: [false, false, false, false]
            }, '', '', '', '', '', '', '', ''
        ]);

        conteudo.push([
            { text: 'MESES', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
            { text: '3350', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 },
            '',
            { text: '3390', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 },
            '',
            { text: '4490', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 },
            '',
            { text: 'TOTAL EMPENHADO', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
            { text: 'TOTAL LIQUIDADO', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 }
        ]);

        conteudo.push([
            '',
            { text: 'EMPENHADA', alignment: 'center', bold: true, fontSize: 7 },
            { text: 'LIQUIDADA', alignment: 'center', bold: true, fontSize: 7 },
            { text: 'EMPENHADA', alignment: 'center', bold: true, fontSize: 7 },
            { text: 'LIQUIDADA', alignment: 'center', bold: true, fontSize: 7 },
            { text: 'EMPENHADA', alignment: 'center', bold: true, fontSize: 7 },
            { text: 'LIQUIDADA', alignment: 'center', bold: true, fontSize: 7 },
            '',
            ''
        ]);

        let liquidada3390: number = 0;

        for (let i = 1; i <= +this.mes; i++) { //percorrer meses
            if (i > (despesas3350.length)) {
                despesas3350.push({ mes: i, liquidada: 0, empenhada: 0 })
            }
            despesas3350[i]['totalEmpenhado'] = 0;
            despesas3350[i]['totalLiquidado'] = 0;

            let j = 1;
            while (despesas3350[j]) {
                if (despesas3350[j]['mes'] === i) {
                    despesas3350[i]['totalEmpenhado'] += despesas3350[j]['empenhada'] || 0;
                    despesas3350[i]['totalLiquidado'] += despesas3350[j]['liquidada'] || 0;
                    break;
                }
                j++
            }

            j = 1;
            while (despesas3390[j]) {
                if (despesas3390[j]['mes'] === i) {
                    despesas3350[i]['totalEmpenhado'] += despesas3390[j]['empenhada'] || 0;
                    despesas3350[i]['totalLiquidado'] += despesas3390[j]['liquidada'] || 0;
                    liquidada3390 += despesas3390[j]['liquidada'] || 0;
                    break;
                }
                j++
            }

            j = 1;
            while (despesas3391[j]) {
                if (despesas3391[j]['mes'] === i) {
                    despesas3350[i]['totalEmpenhado'] += despesas3391[j]['empenhada'] || 0;
                    despesas3350[i]['totalLiquidado'] += despesas3391[j]['liquidada'] || 0;
                    break;
                }
                j++
            }

            j = 1;
            while (despesas4490[j]) {
                if (despesas4490[j]?.['mes'] === i) {
                    despesas3350[i]['totalEmpenhado'] += despesas4490[j]['empenhada'] || 0;
                    despesas3350[i]['totalLiquidado'] += despesas4490[j]['liquidada'] || 0;
                    break;
                }
                j++
            }
        }

        for (let i = (+this.mes + 1); i <= 12; i++) {
            if (i > (despesas3350.length)) {
                despesas3350.push({ mes: i, liquidada: 0, empenhada: 0 })
                despesas3390.push({ mes: i, liquidada: +(liquidada3390 / +this.mes), empenhada: 0 });
            }
            despesas3390[i]['liquidada'] = (liquidada3390 / +this.mes);
            despesas3350[i]['totalEmpenhado'] = 0;
            despesas3350[i]['totalLiquidado'] = despesas3390[i]['liquidada'];
        }

        for (let i = 1; i <= 12; i++) {
            conteudo.push([
                { text: this.globalService.obterMes(despesas3350[i]?.['mes']), fontSize: 6, border: [true, false, true, false], alignment: 'left' },
                { text: this.funcaoService.convertToBrNumber(despesas3350[i]?.['empenhada']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber(despesas3350[i]?.['liquidada']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber(despesas3390[i]?.['empenhada'] + despesas3391[i]?.['empenhada']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber(despesas3390[i]?.['liquidada'] + despesas3391[i]?.['liquidada']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber(despesas4490[i]?.['empenhada']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber(despesas4490[i]?.['liquidada']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber(despesas3350[i]?.['totalEmpenhado']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber(despesas3350[i]?.['totalLiquidado']), fontSize: 6, border: [true, false, true, false], alignment: 'right' }
            ]);
        }

        const totalizador3350Empenhado = despesas3350.reduce((acumulador, receita) => acumulador += receita?.['empenhada'] || 0, 0);
        const totalizador3350Liquidada = despesas3350.reduce((acumulador, receita) => acumulador += receita?.['liquidada'] || 0, 0);

        let totalizador3390Empenhado = despesas3390.reduce((acumulador, receita) => acumulador += receita?.['empenhada'] || 0, 0);
        let totalizador3390Liquidada = despesas3390.reduce((acumulador, receita) => acumulador += receita?.['liquidada'] || 0, 0);

        totalizador3390Empenhado += despesas3391.reduce((acumulador, receita) => acumulador += receita?.['empenhada'] || 0, 0);
        totalizador3390Liquidada += despesas3391.reduce((acumulador, receita) => acumulador += receita?.['liquidada'] || 0, 0);

        const totalizador4490Empenhado = despesas4490.reduce((acumulador, receita) => acumulador += receita?.['empenhada'] || 0, 0);
        const totalizador4490Liquidada = despesas4490.reduce((acumulador, receita) => acumulador += receita?.['liquidada'] || 0, 0);

        this.valorNaoLiquidadoObra = totalizador4490Empenhado - totalizador4490Liquidada

        const totalizadorTotalEmpenhado = despesas3350.reduce((acumulador, receita) => acumulador += receita?.['totalEmpenhado'] || 0, 0);
        this.despesaEmpenhada = totalizadorTotalEmpenhado;
        const totalizadorTotalLiquidada = despesas3350.reduce((acumulador, receita) => acumulador += receita?.['totalLiquidado'] || 0, 0);
        this.projecaoDespesaLiquidada = totalizadorTotalLiquidada;

        conteudo.push([
            { text: 'TOTAL', fontSize: 7, bold: true, alignment: 'left' },
            { text: this.funcaoService.convertToBrNumber(totalizador3350Empenhado), fontSize: 7, bold: true, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(totalizador3350Liquidada), fontSize: 7, bold: true, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(totalizador3390Empenhado), fontSize: 7, bold: true, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(totalizador3390Liquidada), fontSize: 7, bold: true, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(totalizador4490Empenhado), fontSize: 7, bold: true, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(totalizador4490Liquidada), fontSize: 7, bold: true, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(totalizadorTotalEmpenhado), fontSize: 7, bold: true, alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(totalizadorTotalLiquidada), fontSize: 7, bold: true, alignment: 'right' }
        ]);

        return [{
            layout: 'linhas',
            table: {
                widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*'],
                body: conteudo
            }
        }];
    }

    private conteudoDetalhes(dados: any[], dados1: any[]) {
        const conteudo = [];
        const [provisao3190, provisao3390, provisao4490, pagamentoRestos, saldoRestos] = dados
        const saldoConta1 = dados1
        let valor_empenho: number = 0;
        let totalProvisionado: number = 0;

        this.saldoConta = saldoConta1[0]?.['saldo_ano_anterior'] || 0;
        totalProvisionado = +provisao3390['valor'] + +provisao4490['valor']
        valor_empenho = saldoRestos.reduce((acumulador, receita) => acumulador += +receita['er_valor_empenho'] || 0, 0)
        this.saldoResto = valor_empenho - ((+saldoRestos[0]?.['cancelado'] || 0) + (+saldoRestos[0]?.['cancelado_NP'] || 0) + (+saldoRestos[0]?.['pagamento']) || 0);
        this.saldoGeral = this.valorRepasseTotal + this.valorRendimentosTotal - this.projecaoDespesaLiquidada + +this.saldoConta - +pagamentoRestos?.['valor'] - this.saldoResto
        this.totalProvisionadoPorcentagem = ((((+provisao3390?.['valor'] || 0) + (+provisao4490?.['valor'] || 0) + (+provisao3190['valor'] || 0)) / this.projecaoReceita) * 100)
        this.valorNaoLiquidadoObraPorcentagem = ((this.valorNaoLiquidadoObra / this.projecaoReceita) * 100)

        conteudo.push([
            { text: 'Projeção Receita', bold: true, border: [false], fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(this.projecaoReceita), alignment: 'right', border: [false], fontSize: 6 },
            { text: 'Despesa Empenhada', bold: true, border: [false], fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(this.despesaEmpenhada), alignment: 'right', border: [false], fontSize: 6 },
            { text: 'Provisionado 3390', bold: true, border: [false], fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(provisao3390['valor']), alignment: 'right', border: [false], fontSize: 6 },
            { text: '', alignment: 'right', border: [false], fontSize: 6 }
        ]);

        conteudo.push([
            { text: 'Projeção Rendimento', bold: true, border: [false], fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(this.projecaoRendimento), alignment: 'right', border: [false], fontSize: 6 },
            { text: 'Projeção Despesa Liquidada', bold: true, border: [false], fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(this.projecaoDespesaLiquidada), alignment: 'right', border: [false], fontSize: 6 },
            { text: 'Provisionado 4490', bold: true, border: [false], fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(provisao4490['valor']), alignment: 'right', border: [false], fontSize: 6 },
            { text: '', alignment: 'right', border: [false], fontSize: 6 }
        ]);

        conteudo.push([
            { text: 'Pagamento Restos', bold: true, border: [false], fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(pagamentoRestos['valor']), alignment: 'right', border: [false], fontSize: 6 },
            { text: 'Falta ou Sobra Despesa', bold: true, border: [false], fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(this.projecaoReceita - this.projecaoDespesaLiquidada), alignment: 'right', border: [false], fontSize: 6 },
            { text: 'Total Provisionado', bold: true, border: [false], fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(totalProvisionado), alignment: 'right', border: [false], fontSize: 6 },
            { text: `${this.funcaoService.convertToBrNumber(this.totalProvisionadoPorcentagem)}%`, alignment: 'right', border: [false], fontSize: 6 }
        ]);

        conteudo.push([
            { text: 'Saldo Restos', bold: true, border: [false], fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(this.saldoResto), alignment: 'right', border: [false], fontSize: 6 },
            { text: 'Saldo Geral', bold: true, border: [false], fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(this.saldoGeral), alignment: 'right', border: [false], fontSize: 6 },
            { text: 'Valor não liquidado de obra', bold: true, border: [false], fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(this.valorNaoLiquidadoObra), alignment: 'right', border: [false], fontSize: 6 },
            { text: `${this.funcaoService.convertToBrNumber(this.valorNaoLiquidadoObraPorcentagem)}%`, alignment: 'right', border: [false], fontSize: 6 }
        ]);

        return [{
            layout: 'linhas',
            table: {
                widths: ['auto', '*', 'auto', '*', 'auto', '*', '*'],
                body: conteudo
            }
        }];
    }

    private async conteudoResumoFinanceiro(dados: any[]) {
        const conteudo = [];
        const [resumoFinanceiro] = dados;
        let valor0, valor1, valor2, valor4, valor5, valor6, valor7, valor8, valor9, valor10, valor11, valor12: number = 0;

        valor0 = +resumoFinanceiro?.['saldo_ano_anterior'];
        valor1 = +resumoFinanceiro?.['arrecadada_false'];
        valor2 = +resumoFinanceiro?.['arrecadada_true'];
        valor4 = +resumoFinanceiro?.['pagamento_resto'];
        valor5 = +resumoFinanceiro?.['pagamento'];
        valor6 = (valor1 + valor2) - (valor4 + valor5);
        valor7 = +resumoFinanceiro?.['empenhada'];
        valor8 = (valor1 + valor2) - valor7;
        valor9 = ((valor1 + valor2) - valor7) - valor4;
        valor10 = +resumoFinanceiro?.['transferencia_contra'];
        valor11 = +resumoFinanceiro?.['transferencia'];
        valor12 = +resumoFinanceiro?.['transferencia_retirada'];

        this.saldoConta = valor0;
        this.saldoRelacaoEmpenhada = valor8 + valor0 + valor10 + valor11 - valor12;
        this.saldoRelacaoPgtResto = valor9 + valor0 + valor10 + valor11 - valor12;
        this.saldoRelacaoPgt = valor6 + valor0 + valor10 + valor11 - valor12;

        conteudo.push([
            { text: 'RESUMO FINANCEIRO', alignment: 'left', bold: true, border: [false, false, false, true], fontSize: 8, colSpan: 4 },
            { text: '', alignment: 'right', border: [false, false, false, false], fontSize: 7 },
            { text: '', alignment: 'right', border: [false, false, false, false], fontSize: 7 },
            { text: '', alignment: 'right', border: [false, false, false, false], fontSize: 7 },
        ]);

        conteudo.push([
            { text: `SALDO DA CONTA EM 31/12/${this.exercicioAnterior.ano}: `, border: [false], fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(this.saldoConta, 2), alignment: 'right', border: [false], fontSize: 7 },
            { text: `SALDO EM RELAÇÃO A EMPENHADA ATÉ ${this.globalService.obterMes(+this.mes)}: `, border: [false], fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(this.saldoRelacaoEmpenhada, 2), alignment: 'right', border: [false], fontSize: 7 },
        ]);

        conteudo.push([
            { text: `SALDO EM RELAÇÃO EMP.E PAGTO RESTOS ATÉ ${this.globalService.obterMes(+this.mes)}: `, border: [false], fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(this.saldoRelacaoPgtResto, 2), alignment: 'right', border: [false], fontSize: 7 },
            { text: `SALDO EM RELAÇÃO AOS PAGTOS ATÉ ${this.globalService.obterMes(+this.mes)}: `, border: [false], fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(this.saldoRelacaoPgt, 2), alignment: 'right', border: [false], fontSize: 7 },
        ]);

        let assinaturas
        if (this.assinatura_ensino) {
            const ass = new Assinaturas(this.login.orgao, this.injector);
            assinaturas = await ass.dadosAssinatura(50, false, null, false, this.assinatura_ensino);
        }

        return this.assinatura_ensino ? [
            {
                layout: 'linhas',
                table: {
                    widths: ['auto', '*', 'auto', '*'],
                    body: conteudo
                }
            },
            {
                layout: 'linhas',
                table: {
                    dontBreakRows: true,
                    headerRows: 0,
                    widths: ['*', '*', '*'],
                    body: assinaturas
                }
            }
        ] : [{
            layout: 'linhas',
            table: {
                widths: ['auto', '*', 'auto', '*'],
                body: conteudo
            }
        }];
    }

    public colunasRelatorio(): Coluna[] {
        return [
            { titulo: 'Grupo', coluna: 'grupo' },
            { titulo: 'Titulo', coluna: 'titulo' },
            { titulo: 'Mês', coluna: 'mes' },
            { titulo: 'Anterior', coluna: 'anterior', decimais: 2 },
            { titulo: 'Atual', coluna: 'atual', decimais: 2 },
            { titulo: 'Percentual', coluna: 'percentual', decimais: 2 },
            { titulo: 'Empenhado', coluna: 'empenhado', decimais: 2 },
            { titulo: 'Liquidado', coluna: 'liquidado', decimais: 2 }
        ]
    }

    public conteudoExportar(lista: any[]) {
        return [
            ...this.normalizarDadosReceita(lista[0][0], 'VALOR REPASSE'),
            ...this.normalizarDadosReceita(lista[0][1], 'RENDIMENTOS'),
            ...this.normalizarDadosDespesa(lista[1][0], '3350'),
            ...this.normalizarDadosDespesa(lista[1][1], '3390'),
            ...this.normalizarDadosDespesa(lista[1][3], '4490')
        ]
    }

    public normalizarDadosReceita(lista: any, titulo: string) {
        lista = lista.slice(1)

        return lista.map((item: any) => {
            return {
                grupo: 'PROJEÇÃO RECEITA QSE',
                titulo: titulo,
                mes: item?.mes,
                anterior: +item?.anterior,
                atual: +item?.atual,
                percentual: (+item?.atual / +item.anterior * 100) - 100
            }
        })
    }

    public normalizarDadosDespesa(lista: any, titulo: string) {
        lista = lista.slice(1)

        return lista.map((item: any) => {
            return {
                grupo: 'PROJEÇÃO DESPESA QSE',
                titulo: titulo,
                mes: item?.mes,
                liquidado: item?.liquidada,
                empenhado: item?.empenhada
            }
        })
    }
}