import { Injectable, Injector } from "@angular/core";
import { BaseResourceService, ParecerControleInterno } from "eddydata-lib";

@Injectable({
  providedIn: 'root'
})
export class ParecerControleInternoService extends BaseResourceService<ParecerControleInterno> {

  constructor(
    protected injector: Injector
  ) {
    super(`parecer-controle-internos`, injector);
  }

}
