import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Page, Proposta } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PropostaService extends BaseResourceService<Proposta> {

  constructor(
    protected injector: Injector
  ) {
    super(`propostas`, injector);
  }

  /**
 * @deprecated Método deverá ser substituído por `obterVencedores`
 */
  public obterVencedoresTransparencia(id: number): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/vencedores?id=${id}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterVencedores(id: number, ordem: string, vencedores?: boolean): Observable<Page> {
    if (!ordem) {
      new Error('Ordem não informada');
    }
    const parametros = {
      'memorial.licitacao.id': id,
      'relations': 'memorial,proponente.favorecido,memorial.produto_unidade,memorial.produto_unidade.produto',
      'orderBy': 'proponente.favorecido.cpf_cnpj'
    }

    if (vencedores)
      parametros['situacao'] = 'VENCEDOR';

    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar${this.converterParametrosStr(parametros)}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterVencedoresTermo(id: number, ordem: string, vencedores?: boolean): Observable<Page> {
    if (!ordem) {
      new Error('Ordem não informada');
    }
    const parametros = {
      'memorial.licitacao.id': id,
      'relations': 'memorial,proponente.favorecido,memorial.produto_unidade,memorial.produto_unidade.produto',
      'orderBy': ordem
    }

    if (vencedores)
      parametros['situacao'] = 'VENCEDOR';

    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar${this.converterParametrosStr(parametros)}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterVencedoresItem(licitacao: number, item: number, cota?: string, NEfavorecido?: number): Observable<Page> {
    const parametros = {
      'relations': 'proponente.favorecido',
      'memorial.licitacao.id': licitacao,
      'memorial.produto_unidade.id': item,
      'situacao': 'VENCEDOR',
    };

    let extra = '';
    if (cota) {
      extra += '&memorial.cota=' + cota;
    }
    if (!NEfavorecido) {
      extra += '&proponente.favorecido.id$ne=' + NEfavorecido;
    }

    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/filtrar${this.converterParametrosStr(parametros)}${extra}`, this.httpOptions()).pipe(map(res => res), catchError(err => this.handleError(err)));
  }

  /**
 * @deprecated Método deverá ser substituído por `obterPorMemorial`
 */
  public obterPorMemorialTransparencia(id: number): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?relations=memorial,proponente.favorecido&licitacao_id=${id}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorLicitacao(id: number): Observable<Page> {
    const parametros = {
      'memorial.licitacao.id': id,
      'relations': 'memorial,proponente.favorecido',
      'orderBy': 'memorial.ordem'
    }
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar${this.converterParametrosStr(parametros)}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorMemorial(id: number, pregao?: boolean): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?relations=proponente.favorecido&memorial_id=${id}&orderBy=${pregao ? 'valor_unitario' : 'memorial.ordem,proponente.ordem'}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorProponente(id: number): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?relations=proponente.licitacao,proponente.favorecido,memorial.produto_unidade&proponente.id=${id}&orderBy=memorial.ordem,id`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorFavorecido(id: number, orgao_id: number): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?relations=proponente.licitacao&proponente.licitacao.orgao_id=${orgao_id}&proponente.favorecido.id=${id}&orderBy=memorial.ordem,id`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
