import { Injectable, Injector } from "@angular/core";
import { BaseResourceService, Exercicio } from "eddydata-lib";
import { Observable, Subject } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DiarioCaixaService extends BaseResourceService<Exercicio> {

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected injector: Injector
  ) {
    super('contabil-relatorio', injector);
  }

  public obterDiarioCaixa(data: string, exercicio: number, orgaos: number, analitico?: boolean, dataFinal?: string): Observable<any> {
    const parametros = `?dataFinal=${dataFinal}`
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/diario-caixa/${data}/${exercicio}/${orgaos}/${analitico}${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}