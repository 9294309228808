import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DateFormatPipe, FichaDespesa, FuncaoService, Login } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompraService } from '../../compra/service/compra.service';

declare var $: any;

type SaldoFixa = {
  dotacao?: number,
  reservado?: number,
  saldo?: number,
  empenhado?: number,
  liquidado?: number,
  pago?: number,
  comprado?: number
}

@Component({
  selector: 'app-saldo-ficha-dlg',
  templateUrl: './saldo-ficha-dlg.component.html'
})
export class SaldoFichaDlgComponent implements OnInit, OnChanges {

  @Input() login: Login;
  @Input() ficha: FichaDespesa;
  @Input() data: Date;


  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public saldoFixa: SaldoFixa;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(public funcaoService: FuncaoService,
    private compraService: CompraService) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    this.carregarSaldo();
  }

  public carregarSaldo() {
    if (this.login && this.ficha && this.data)
      this.compraService.totalizadoresFicha(this.login.exercicio.id, this.login.orgao.id, this.ficha.id, String(this.funcaoService.converteDataSQL(new DateFormatPipe().transform(this.data, ['local']))))
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          this.carregarSaldoFixa(data);
        })
  }


  private carregarSaldoFixa(totalizadores: {
    comprado: number, empenhado: number, liquidado: number, pago: number, reservado: number,
    comprado_ficha: number, dotacao_ficha: FichaDespesa, creditado_ficha: number
  }) {
    if (!totalizadores)
      return;
    this.saldoFixa = {};

    this.saldoFixa.empenhado = totalizadores.empenhado;
    this.saldoFixa.liquidado = totalizadores.liquidado;
    this.saldoFixa.pago = totalizadores.pago;
    this.saldoFixa.comprado = totalizadores.comprado_ficha;

    this.saldoFixa.dotacao = +totalizadores.dotacao_ficha + +totalizadores.creditado_ficha;
    this.saldoFixa.reservado = totalizadores.reservado;
  }

  public compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  public onHide(){
    this.visualizar  = false;
    this.visualizarChange.emit(this.visualizar)
  }

}