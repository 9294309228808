import {
  Component, OnInit, OnChanges, ElementRef, Input,
  ViewChild, SimpleChanges, OnDestroy, ViewChildren, QueryList
} from '@angular/core';
import {
  RetencaoResto, EddyAutoComplete, FichaExtra, LiquidacaoResto,
  Login, GlobalService, FuncaoService, FavorecidoCei
} from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RetencaoRestoService } from '../service/retencao-resto.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FichaExtraService } from '../../ficha-extra/service/ficha-extra.service';
import { FavorecidoCeiService } from 'administrativo-lib';

@Component({
  selector: 'app-retencao-resto',
  templateUrl: './retencao-resto.component.html'
})
export class RetencaoRestoComponent implements OnInit, OnChanges, OnDestroy {

  @Input() lista: Array<any>;
  @Input() entidade: LiquidacaoResto;
  @Input() login: Login;
  @Input() objetoReinfTabela1: any = null;
  @Input() valor_liquidado: any = null;
  @Input() visualizacao: boolean = false;
  @Input() edicao: boolean = true;

  @ViewChild('btnAdicionarRetencao') public btnAdicionarRetencao: ElementRef;
  @ViewChildren('btnsEditar') public btnsEditar: QueryList<ElementRef>;

  public ptBR: any;
  public entity: RetencaoResto;
  public entityTemp: RetencaoResto;
  public listaRetencoes: Array<any>;
  public fichaAutoComplete: EddyAutoComplete<FichaExtra>;
  public ceiAutoComplete: EddyAutoComplete<FavorecidoCei>;
  protected unsubscribe: Subject<void> = new Subject();
  public totalRetido = 0;
  public favorecidoCei;
  public retencaoReinf: RetencaoResto;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  imaskConfigAliquota = {
    mask: Number,
    scale: 6,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected fichaService: FichaExtraService,
    protected favorecidoCeiService: FavorecidoCeiService,
    protected retencaoService: RetencaoRestoService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.listaRetencoes = this.lista;
    this.carregarAutoCompletes();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lista) {
      this.listaRetencoes = this.lista;
      for (let item of this.listaRetencoes) {
        this.calcularRetencao(item)
        this.calcularAliquota(item)
      }
      this.somarRetencao(this.listaRetencoes);
      this.carregarAutoCompletes();
    }
  }

  private desabilitarEdicao(desabilitar: boolean) {
    this.btnsEditar.forEach((btn) => btn.nativeElement.disabled = desabilitar)
    this.btnAdicionarRetencao.nativeElement.disabled = desabilitar;
  }

  public adicionarRetencao() {
    this.carregarAutoCompletes()
    this.entity = new RetencaoResto();
    this.entity.aux = 0;
    this.entity.liquidacao = this.entidade;
    this.entity.data_vencimento = this.entidade.data_vencimento;
    this.entity.editavel = true;
    this.entity['salvo'] = false;
    if (!this.listaRetencoes) {
      this.listaRetencoes = new Array();
    }
    this.listaRetencoes.unshift(this.entity);
    this.desabilitarEdicao(true);
  }

  public salvarRetencao(item: RetencaoResto) {
    try {
      if (this.favorecidoCei) {
        item.cei = '' + this.favorecidoCei.cei;
      }
      if (!item.ficha) {
        throw new Error('Informe a ficha de retenção!');
      }

      if (!item.data_vencimento) {
        throw Error('Campo data vencimento da retenção não preenchido!');
      }
      if (+item.valor_retido > +item.liquidacao.valor_liquidado) {
        throw new Error('O valor da retenção não pode ser superior ao valor liquidado');
      }

      // envia a entidade para ser salva no banco -----
      item.editavel = false;
      item['salvo'] = true;
      this.desabilitarEdicao(false);
      this.somarRetencao(this.listaRetencoes);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  public editarRetencao(item: RetencaoResto) {
    this.entityTemp = JSON.parse(JSON.stringify(item));
    this.entity = item;
    this.entity.editavel = true;
    this.entity['salvo'] = true;
    this.favorecidoCeiService.obter({ cei: item.cei, favorecido_id: item.liquidacao.empenho.favorecido.id, orgao_id: this.login.orgao.id }).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      this.favorecidoCei = res;
      this.ceiAutoComplete.id = this.favorecidoCei ? this.favorecidoCei.id : null;
    });
    this.desabilitarEdicao(true);
  }

  public cancelarRetencao(item: RetencaoResto, index: number) {
    if (item['salvo'] && item.id === this.entityTemp?.id) {
      item.data_vencimento = this.entityTemp.data_vencimento;
      item.ficha = this.entityTemp.ficha;
      item.valor_retido = this.entityTemp.valor_retido;
      item.valor_aliquota = this.entityTemp.valor_aliquota;
      item.cei = this.entityTemp.cei;
      item.gps = this.entityTemp.gps;
      this.entity = item;
      this.entity.editavel = false;
    } else {
      this.listaRetencoes.splice(index, 1);
    }
    this.desabilitarEdicao(false);
    this.somarRetencao(this.listaRetencoes);
  }

  public confirmarRemocao(item: RetencaoResto, index: number) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja remover item?',
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.removerRetencao(item, index)
      },
    });
  }

  public removerRetencao(item: RetencaoResto, index: number) {
    if (item.id) {
      this.retencaoService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaRetencoes.splice(index, 1);
          this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Exclusão', detail: error.error.payload });
          throw error;
        });
    } else {
      this.listaRetencoes.splice(index, 1);
    }
    this.somarRetencao(this.listaRetencoes);
  }

  private carregarAutoCompletes() {
    // autocomplete para ficha
    this.fichaAutoComplete = new EddyAutoComplete(null, this.fichaService,
      'numero', ['numero', 'nome'],
      {
        exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id, retencao: true, excluida: false,
        relations: 'plano,favorecido,ficha', orderBy: 'nome'
      },
      { number: ['id', 'numero'], text: ['nome'] }
    );

    this.ceiAutoComplete = new EddyAutoComplete(null, this.favorecidoCeiService, 'cei', ['cei'],
      { favorecido_id: this.entidade.empenho?.favorecido?.id, ativo: true, orgao_id: this.login.orgao.id },
      { text: ['cei'] }
    );
  }

  public calcularRetencao(item: RetencaoResto) {
    if (item.valor_aliquota > 0) {
      item.valor_retido = this.entidade.valor_liquidado * (item.valor_aliquota / 100)
    }
  }

  public calcularAliquota(item: RetencaoResto) {
    if (item.valor_retido > 0) {
      item.valor_aliquota = item.valor_retido * (100 / this.entidade.valor_liquidado)
    }
  }

  private somarRetencao(list: Array<RetencaoResto>) {
    if (list) {
      this.totalRetido = 0;
      for (const item of list) {
        this.totalRetido += +item.valor_retido;
      }
    }
  }

  private carregarRetencoes() {
    this.retencaoService.extendido(0, -1, {
      liquidacao_id: this.entidade.id,
      relations: 'ficha,ficha.ficha,ficha.plano,ficha.favorecido,liquidacao,liquidacao.empenho,liquidacao.empenho.favorecido,'
        + 'liquidacao.orgao,liquidacao.exercicio'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        async (data: any) => {
          this.listaRetencoes = data.content;
          this.somarRetencao(this.listaRetencoes);
        });
  }

  atualizarReinf(event: RetencaoResto) {
    this.carregarRetencoes();
    this.retencaoReinf = null;
  }

}
