// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class Departamento extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public codigo?: number,
    public orgao?: Orgao,
    ) {
    super();
  }
  static converteJson(json: any): Departamento {
    return Object.assign(new Departamento(), json);
  }
}


