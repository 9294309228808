import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { FaturaTipoImovel } from './fatura-tipo-imovel.model';

export class FaturaTipoImovelHistorico extends BaseResourceModel {
    constructor(
        public descricao?: string,
        public fatura_tipo_imovel?: FaturaTipoImovel,
        public usuario?: Usuario,
    ) {
        super();
    }

    static converteJson(json: any): FaturaTipoImovelHistorico {
        return Object.assign(new FaturaTipoImovelHistorico(), json);
    }
}
