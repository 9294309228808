import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FuncaoService } from 'eddydata-lib';

@Directive()
export class Anexo8Superavit implements OnDestroy {

  protected funcaoService: FuncaoService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number) {
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(superavit: any[], fundeb: any[]): {}[] {

    let valor = 0;

    for (let fun of fundeb) {
      valor += fun.arrecadado;
    }

    let subgrupos: any;

    const registros: {}[] = [
      [{
        text: 'RECURSOS RECEBIDOS EM EXERCÍCIOS ANTERIORES E NÃO UTILIZADOS (SUPERÁVIT)',
        alignment: 'center',
        fontSize: 6,
      }, {
        text: 'VALOR',
        alignment: 'center',
        fontSize: 6
      }]
    ];

    const grupos = this.funcaoService.agrupar(superavit, 'grupo', ['receita', 'empenhado']);
    for (const titulo of grupos) {
      registros.push([
        { text: titulo.grupo, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['receita'] + titulo.totalizadores['empenhado']), alignment: 'right', fontSize: 7 }
      ])
      valor += +titulo.totalizadores['receita'] + titulo.totalizadores['empenhado'];
      subgrupos = this.funcaoService.agrupar(titulo.registros, 'subgrupo', ['receita', 'empenhado']);

      for (let subgrupo of subgrupos) {
        if (subgrupo.grupo !== '') {
          registros.push([
            { text: subgrupo.grupo, fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['receita'] + subgrupo.totalizadores['empenhado']), alignment: 'right', fontSize: 7 }
          ])
        }

        for (const item of subgrupo.registros) {
          if (item.nome != '') {
            registros.push([
              { text: item.nome, fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(item.valor), alignment: 'right', fontSize: 7 }
            ]);
          }
        }
      }
    }

    registros.push([
      { text: '9- TOTAL DOS RECURSOS DO FUNDEB DISPONÍVEIS PARA UTILIZAÇÃO (6 + 8)', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(valor), alignment: 'right', fontSize: 7 },
    ]);

    return registros;

  }

  public montarQuadroCsv(superavit: any[], fundeb: any[], listaExportar): any {
    let valor = 0;

    for (let fun of fundeb) {
      valor += fun.arrecadado;
    }

    let subgrupos: any;

    const espaco = {
      titulo: '',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(espaco)

    const primeira_linha = {
      titulo: 'RECURSOS RECEBIDOS EM EXERCÍCIOS ANTERIORES E NÃO UTILIZADOS (SUPERÁVIT)',
      coluna2: 'VALOR',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(primeira_linha)

    const grupos = this.funcaoService.agrupar(superavit, 'grupo', ['receita', 'empenhado']);
    for (const titulo of grupos) {
      const linha_titulo = {
        titulo: titulo.grupo,
        coluna2: this.funcaoService.convertToBrNumber(titulo.totalizadores['receita'] + titulo.totalizadores['empenhado']),
        coluna3: '',
        coluna4: '',
        coluna5: '',
        coluna6: '',
        coluna7: ''
      }
      listaExportar.push(linha_titulo)

      valor += +titulo.totalizadores['receita'] + titulo.totalizadores['empenhado'];
      subgrupos = this.funcaoService.agrupar(titulo.registros, 'subgrupo', ['receita', 'empenhado']);

      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo !== '') {
          const linha_subgrupo = {
            titulo: subgrupo.grupo,
            coluna2: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['receita'] + subgrupo.totalizadores['empenhado']),
            coluna3: '',
            coluna4: '',
            coluna5: '',
            coluna6: '',
            coluna7: ''
          }
          listaExportar.push(linha_subgrupo)
        }

        for (const item of subgrupo.registros) {
          if (item.nome != '') {
            const linha_subgrupo_item = {
              titulo: item.nome,
              coluna2: this.funcaoService.convertToBrNumber(item.valor),
              coluna3: '',
              coluna4: '',
              coluna5: '',
              coluna6: '',
              coluna7: ''
            }
            listaExportar.push(linha_subgrupo_item)
          }
        }
      }
    }

    const linha_total_9 = {
      titulo: '9- TOTAL DOS RECURSOS DO FUNDEB DISPONÍVEIS PARA UTILIZAÇÃO (6 + 8)',
      coluna2: this.funcaoService.convertToBrNumber(valor),
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(linha_total_9)
  }

}