import { MessageService } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService, LoginPublico, LoginService, TransparenciaParametro } from 'eddydata-lib';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TransparenciaParametroService } from '../transparencia-parametro/service/transparencia-parametro.service';

declare var $: any;

@Component({
  selector: 'rede-social-info',
  templateUrl: './rede-social-info.component.html',

})
export class RedeSocialInfoComponent implements OnInit {

  public login = new LoginPublico();
  public filtro: string;
  public orgaoRedesSociais: Array<String> = new Array<String>();
  public exibirRedesSociaisInfo: boolean = false;
  protected unsubscribe: Subject<void> = new Subject();


  constructor(
    private messageService: MessageService,
    protected router: Router,
    private parametrosTransparenciaService: TransparenciaParametroService,
    private authService: LoginService
  ) { }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit() {

    this.login = GlobalService.obterSessaoLogin();


    this.parametrosTransparenciaService.filtrar(1, -1,
      { relations: 'orgao', 'orgao.id': this.login.orgao.id }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {

       

       let redes  = res.content[0] 
       
       this.exibirRedesSociaisInfo = redes.redes_sociais;

       if(redes.url_facebook != null && redes.url_facebook != undefined){
        this.orgaoRedesSociais.push(redes.url_facebook)

       }
       if(redes.url_twitter != null && redes.url_twitter != undefined){
        this.orgaoRedesSociais.push(redes.url_twitter)

       }
       if(redes.url_instagram != null && redes.url_instagram != undefined){
        this.orgaoRedesSociais.push(redes.url_instagram)

       }
       if(redes.url_youtube != null && redes.url_youtube != undefined){
        this.orgaoRedesSociais.push(redes.url_youtube)

       }
       if(redes.url_linkedin != null && redes.url_linkedin != undefined){
        this.orgaoRedesSociais.push(redes.url_linkedin)

       }
   

      

      }, error =>  this.messageService.add({ severity: 'warn', summary: 'erro', detail: 'Não foi possível carregar as redes sociais' })

      );
 
  }


  public verificarRedeSocial(rede:string){

      
    
     if(rede.toLowerCase().includes('facebook.com'))
     {
       return '<i  class="fab fa-facebook-square icon-facebook"></i> <span class="text-white">  Facebook </span>';
       
     }

     if(rede.toLowerCase().includes('linkedin.com'))
     {
       return '<i  style="color:#00f"  class="fab fa-linkedin icon-linkedin"></i> <span class="text-white"> LinkedIn </span>';
     }

     if(rede.toLowerCase().includes('instagram.com'))
     {
       return '<i class="fab icon-instragram fa-instagram-square"></i> <span class="text-white"> Instagram </span> ';
     }

     if(rede.toLowerCase().includes('youtube.com'))
     {
       return '<i style="background-color:#f00" class="fab fa-youtube-square icon-youtube"></i> <span class="text-white"> Youtube </span>';
     }

     if(rede.toLowerCase().includes('twitter.com')  )
     {
       return '<i style="color:#0f0"  class="fab fa-twitter-square icon-twitter"></i> <span class="text-white"> Twitter  </span> <span>&nbsp;&nbsp</span>' ;
     }
     

 }



}
