import { BaseResourceModel } from '../../models/base-resource.model';
import { SubGrupoSubElementoEstoque } from '../almoxarifado/sub-grupo-sub-elemento-estoque.model';
import { Exercicio } from '../comum/exercicio.model';
import { PlanoConta } from '../contabil/plano-conta.model';
import { TipoContratacao } from '../licitacao/tipo-contratacao.model';

// tslint:disable: variable-name

export class Despesa extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public nome?: string,
    public n1?: string,
    public n2?: string,
    public n3?: string,
    public n4?: string,
    public n5?: string,
    public n6?: string,
    public nivel?: number,
    public escrituracao?: boolean,
    public obras_engenharia?: boolean,
    public aceita_retencao?: boolean,
    public material?: boolean,
    public servico?: boolean,
    public descricao?: string,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public codigo_siops?: string,
    public codigo_reinf?: number,
    public ativo?: boolean,
    public exercicio?: Exercicio,
    public plano?: PlanoConta,
    public contraPartida?: PlanoConta,
    public contraPartidaResto?: PlanoConta,
    public contaPreliquidacao?: PlanoConta,
    public vpd?: PlanoConta,
    public sub_grupos?: SubGrupoSubElementoEstoque[],
    public tipo_contratacao?: TipoContratacao,
    public projecao_ferias?: boolean
  ) {
    super();
  }

  static converteJson(json: any): Despesa {
    return Object.assign(new Despesa(), json);
  }
}
