import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TransferenciaAutorizacaoRoutingModule } from './transferencia-autorizacao-routing.module';
import { TransferenciaAutorizacaoViewComponent } from './transferencia-autorizacao-view/transferencia-autorizacao-view.component';
import { TransferenciaAutorizacaoListComponent } from './transferencia-autorizacao-list/transferencia-autorizacao-list.component';
import { TransferenciaAutorizacaoFormComponent } from './transferencia-autorizacao-form/transferencia-autorizacao-form.component';
import { TransferenciaAutorizacaoDlgComponent } from './transferencia-autorizacao-dlg/transferencia-autorizacao-dlg.component';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { IMaskModule } from 'angular-imask';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SharedModule } from 'eddydata-lib';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TransferenciaAutorizacaoObnDlgComponent } from './transferencia-autorizacao-obn-dlg/transferencia-autorizacao-obn-dlg.component';
import { TransferenciaBancariaModule } from 'contabil-lib';
import { TransferenciaAutorizacaoModalComponent } from './transferencia-autorizacao-modal/transferencia-autorizacao-modal.component';
import { FieldsetModule } from 'primeng/fieldset';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { InputMaskModule } from 'primeng/inputmask';

@NgModule({
  declarations: [
    TransferenciaAutorizacaoViewComponent, 
    TransferenciaAutorizacaoListComponent, 
    TransferenciaAutorizacaoFormComponent,
    TransferenciaAutorizacaoDlgComponent,
    TransferenciaAutorizacaoObnDlgComponent,
    TransferenciaAutorizacaoModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    TransferenciaAutorizacaoRoutingModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    ConfirmDialogModule,
    RadioButtonModule,
    TransferenciaBancariaModule,
    InputMaskModule,
    TreeModule,
    TooltipModule,
    DialogModule,
    FieldsetModule
  ],
  providers: [MessageService, ConfirmationService]
})
export class TransferenciaAutorizacaoModule { }
