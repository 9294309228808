import { DatePipe, formatDate } from '@angular/common';

export class DateFormatPipe {
    transform(value: any, args: any[]): any {

        if (value) {
            if (args && args[0] === 'local') {
                const datePipe = new DatePipe('en-US');
                value = datePipe.transform(value, 'dd/MM/yyyy');
                return value;
            } else if (args && args[0] === 'datetime') {
                return new Date((new Date(formatDate(value, 'yyyy-MM-dd h:mm:ss a z', 'en-US')).toISOString()));
            } else {
                return new Date((new Date(value).toISOString()).match(/^\d{4}\-\d{2}\-\d{2}/g)[0].replace(/-/g, ','));
            }
        }
        return value;
    }
}
