import { Injectable, Injector } from '@angular/core';
import { SetorProcesso } from '../../../entidade/almoxarifado/setor-processo.model';
import { BaseResourceService } from '../../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class SetorProcessoService extends BaseResourceService<SetorProcesso> {

  constructor(
    protected injector: Injector
  ) {
    super(`setor-processos`, injector);
  }

}
