import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { RplStorageComponent } from './rpl-storage.component';
// import { RplStorageService } from './service/rpl-storage.service';

@NgModule({
  declarations: [
    RplStorageComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    IMaskModule,
    ToastModule,
    RadioButtonModule,
    FileUploadModule,
    FieldsetModule,
    SelectButtonModule,
    DragDropModule,
    ConfirmDialogModule
  ],
  exports: [
    RplStorageComponent
  ],
  providers: [MessageService, ConfirmationService]
})
export class RplStorageModule { }
