import { Component } from '@angular/core';
import { SetorAlmoxarifadoService } from '../service/setor-almoxarifado.service';
import { SetorAlmoxarifado } from '../../../entidade/almoxarifado/setor-almoxarifado.model';
import { EddyAutoComplete } from '../../../models/form-components';
import { Estoque } from '../../../entidade/almoxarifado/estoque.model';
import { FuncaoService } from '../../../util/funcao.service';
import { EstoqueService } from '../../estoque/service/estoque.service';
import { BaseResourceItemsComponent } from '../../../models/base-resource-items';
import * as toastr from 'toastr';

@Component({
  selector: 'app-setor-almoxarifado',
  templateUrl: './setor-almoxarifado.component.html'
})
export class SetorAlmoxarifadoComponent extends BaseResourceItemsComponent<SetorAlmoxarifado>{


  public estoqueAutoComplete: EddyAutoComplete<Estoque>;

  constructor(protected funcaoService: FuncaoService,
    protected setorAlmoxService: SetorAlmoxarifadoService,
    protected estoqueService: EstoqueService) {
    super(new SetorAlmoxarifado(), setorAlmoxService);
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
  }

  protected validSave(item: SetorAlmoxarifado): boolean {
    if (!item.estoque || !item.estoque.id) {
      toastr.error('Informe o estoque!');
      return false;
    }
    return true;
  }

  protected beforeSave(item: SetorAlmoxarifado): void { }


  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  private carregarAutoCompletes() {
    this.estoqueAutoComplete = new EddyAutoComplete(null, this.estoqueService,
      'id', ['id', 'nome'], { orgao_id: this.login.orgao.id, orderBy: 'nome' }, { number: ['id'], text: ['nome'] }
    );
  }

}
