// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ProdutoUnidade } from '../almoxarifado/produto-unidade.model';
import { CompraItem } from '../compra/compra-item.model';
import { Favorecido } from '../compra/favorecido.model';
import { CardapioMerenda } from './cardapio-merenda.model';

export class CardapioItemMerenda extends BaseResourceModel {
  constructor(
    public id?: number,
    public favorecido?: Favorecido,
    public produto_unidade?: ProdutoUnidade,
    public compra_item?: CompraItem,
    public cardapio?: CardapioMerenda,
    public editavel?: boolean) {
    super();
  }
  static converteJson(json: any): CardapioItemMerenda {
    return Object.assign(new CardapioItemMerenda(), json);
  }
}
