import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GlobalService, Login, OrgaoAssinaturaService } from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TransferenciaAutorizacaoService } from '../service/transferencia-autorizacao.service';
import { TransferenciaAutorizacaoRpt } from '../transferencia-autorizacao-rpt';

@Component({
  selector: 'app-transferencia-autorizacao-dlg',
  templateUrl: './transferencia-autorizacao-dlg.component.html'
})
export class TransferenciaAutorizacaoDlgComponent implements OnInit, OnDestroy {

  protected datepipe: DatePipe;
  public ptBR: any;
  public selectedOrdem: string;
  public selectedOpt: string;
  public selectNaoImpresso: boolean;
  public selectUsuario: boolean;
  public marcarComoImpresso: boolean;
  public dataInicio: Date;
  public dataFinal: Date;
  public numeroInicio: number;
  public numeroFinal: number;
  protected unsubscribe: Subject<void> = new Subject();

  public imaskNumero = {
    mask: Number,
    scale: 0,
    signed: false
  };

  @Input() login: Login;

  constructor(
    protected confirmationService: ConfirmationService,
    protected messageService: MessageService,
    private globalService: GlobalService,
    protected assinaturaService: OrgaoAssinaturaService,
    private autorizacaoService: TransferenciaAutorizacaoService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.selectedOrdem = 'ord1';
    this.selectedOpt = 'opt1';
    this.selectNaoImpresso = true;
    this.selectUsuario = true;
    this.dataInicio = new Date();
    this.dataFinal = new Date();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  imprimir() {
    if (this.selectNaoImpresso) {
      this.confirmationService.confirm({
        message: 'Deseja marcar as autorizações de transferências como impressas ?',
        acceptLabel: "Sim",
        rejectLabel: "Não",
        header: 'Confirmação',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.gerarImpressao();
          this.marcarComoImpresso = true;
        },
        reject: () => { 
          this.marcarComoImpresso = false;
          this.gerarImpressao();
        }
      }); 
    } else {
      this.marcarComoImpresso = false;
      this.gerarImpressao();
    }
  }

  gerarImpressao() {

    const parametros = {};

    let relations = '';
    relations += 'conta_pagadora,conta_pagadora.banco,conta_pagadora.orgao,conta_recebedora,conta_recebedora.orgao,conta_recebedora.banco,exercicio,orgao,usuario_cadastro,conta_pagadora.recursos,conta_pagadora.recursos.aplicacao,conta_pagadora.conta_entidade,conta_recebedora.recursos,conta_recebedora.recursos.aplicacao,conta_recebedora.conta_entidade,transferencia_recurso,transferencia_recurso.itens';
    parametros['relations'] = relations;
    
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;

    if (this.selectedOpt === 'opt1') {
      parametros['data_transfere$ge'] = this.datepipe.transform(this.dataInicio, 'yyyy-MM-dd');
      parametros['data_transfere$le'] = this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd');
    } else {
      parametros['id$ge'] = this.numeroInicio;
      parametros['id$le'] = this.numeroFinal;
    }
    parametros['orderBy'] = this.selectedOrdem === 'ord1' ? 'data_transfere' : 'id';
    if (this.selectNaoImpresso) {
      parametros['impresso'] = !this.selectNaoImpresso;
    }
    if (this.selectUsuario) {
      parametros['usuario_cadastro_id'] = this.login.usuario.id;
    }

    this.autorizacaoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        parametros['opcao'] = this.selectedOpt === 'opt1' ? 1 : 2;
        new TransferenciaAutorizacaoRpt(this.assinaturaService, this.autorizacaoService).imprimir(lista.content, this.login).then(() => {
          if (this.marcarComoImpresso) {
            this.autorizacaoService.marcarComoImpresso(parametros).subscribe(() => {
            });
          }
        });
      }, (error) => this.messageService.add(
        { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
      )
      );
  }

}
