// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { Cidade } from '../geo/cidade.model';
import { CargoHistorico } from './cargo-historico.model';

export class Cargo extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public cbo?: string,
    public escolaridade?: string,
    public provimento?: string,
    public atividade?: string,
    public norma?: string,
    public regime_juridico?: string,
    public vagas?: number,
    public salario_base?: number,
    public cidade?: Cidade,
    public aux?: number,
    public ativo?: boolean,
    public tipo?: 'C' | 'P',
    public usuario_exclusao?: Usuario,
    public motivo_exclusao?: string,
    public data_exclusao?: Date,
    public excluido?: boolean,
    public historicos?: CargoHistorico[],
  ) {
    super();
  }
  static converteJson(json: any): Cargo {
    return Object.assign(new Cargo(), json);
  }
}
