// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';

export class DeParaTransparencia extends BaseResourceModel {
  constructor(
    public id?: number,
    public exercicio?: Exercicio,
    public orgao?: Orgao,
    public pagina?: string,
    public codigos?: string
  ) {
    super();
  }

  static converteJson(json: any): DeParaTransparencia {
    return Object.assign(new DeParaTransparencia(), json);
  }
}
