import { HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, LicitacaoStorage } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LicitacaoStorageService extends BaseResourceService<LicitacaoStorage> {

  constructor(
    protected injector: Injector
  ) {
    super(`licitacao-storages`, injector);
  }

  public upload(arquivos: any[], orgao: number, licitacao: number, descricao?: string): Observable<LicitacaoStorage[]> {
    const formData = new FormData();
    for (let arquivo of arquivos) {
      formData.append('files', arquivo);
    }
    if (descricao) {
      formData.append('descricao', descricao);
    }

    const headers = this.httpOptions();
    headers.headers = headers.headers.delete('Content-Type');

    return this.http.post<LicitacaoStorage[]>(`${this.login.cidade.id}/${this.api}/upload/${orgao}/${licitacao}`,
      formData, headers)
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public removerTodos(licitacao: number): Observable<any> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/remover-todos/${licitacao}`, {}, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err)));
  }

  public visuTransparenciaTodos(licitacao: number, visualizacao: boolean): Observable<any> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/visualizar-todos/${licitacao}/${visualizacao}`, {}, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err)));
  }

  public baixarTodos(licitacao: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/download-todos-licitacao/${licitacao}`, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public downloadZip(): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/download-zip`, {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders(this.adicionarCriptografia({
        Authorization: this.login.token,
      }))
    }).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }
}
