import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, Indicador, Page } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class IndicadorService extends BaseResourceService<Indicador> {

  constructor(
    protected injector: Injector
  ) {
    super(`indicadores`, injector);
  }

  public obterPorMeta(metaId: number): Observable<Page> {
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/filtrar?meta.id=${metaId}&relations=acao_governo,acao_governo.acao&orderBy=codigo`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  obterUtilmoCodigo() {
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/ultimo-codigo`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
