import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Fracionamento } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class FracionamentoService extends BaseResourceService<Fracionamento> {

  constructor(
    protected injector: Injector
  ) {
    super(`fracionamentos`, injector);
  }

}
