import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, Adiantamento } from 'eddydata-lib';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdiantamentoService extends BaseResourceService<Adiantamento> {

  constructor(
    protected injector: Injector
  ) {
    super(`adiantamentos`, injector);
  }

  obterUltimoAdiantamento(orgao: number, exercicio: number) {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-numero/${exercicio}/${orgao}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  salvarPrestacao(entidade: Adiantamento): Observable<Adiantamento> {
    return this.http.put(`${this.login.cidade.id}/${this.api}/prestacao-conta/${entidade.id}`, entidade,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public relatorioAdiantamento(parametros: any): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/extendido/1/1${parametros}`, {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

  public totalAnuladoPorAdiantamento(empenho: number, exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/total-anulado/${empenho}/${exercicio}/${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
