import { Directive, OnDestroy } from '@angular/core';
import { FuncaoService, GlobalService, LoginContabil, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { LdoService } from '../../../../planejamento/ldo/service/ldo.service';
import { SelectItem } from 'primeng/api/selectitem';

@Directive()
export class Demo3Ldo implements OnDestroy {
  public ano1: number;
  public ano2: number;
  public ano3: number;
  public ano4: number;
  public ano5: number;
  public ano6: number;
  private login: LoginContabil = new LoginContabil();
  private dados: any;
  protected funcaoService: FuncaoService;
  protected unsubscribe: Subject<void> = new Subject();
  listaOrgaos: SelectItem[];

  constructor(
    protected ano: number,
    protected ldoServico: LdoService,
    protected orgaos: number[] = [],
    dados?: any,
    listaOrgaos?: SelectItem[]) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
    if (dados) this.dados = dados;
    if (listaOrgaos) this.listaOrgaos = listaOrgaos;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async montarRelatorio() {
    if (!this.dados) {
      this.dados = await this.ldoServico.filtrar({
        tipo$in: 'D30,D31',
        'ppa.id': this.login.ppa.id,
        'orgao.id$in': this.orgaos,
        'ano': this.ano,
        orderBy: 'linha'
      }).toPromise();

      if (this.dados.content.length) this.dados = this.dados.content;
    }

    this.ano1 = +this.ano - 3;
    this.ano2 = +this.ano - 2;
    this.ano3 = +this.ano - 1;
    this.ano4 = +this.ano;
    this.ano5 = +this.ano + 1;
    this.ano6 = +this.ano + 2;

    const lista30 = [];
    const lista31 = [];
    const lista = [];

    if (this.dados) {
      for (const item of this.dados) {
        item.tipo === 'D30' ? lista30.push(item) : lista31.push(item);
      }

      if (lista30.length > 0 && lista31.length > 0) {
        lista.push(this.conteudo(lista30).concat(this.conteudo31(lista31)));
      } else if (lista30.length > 0 && lista31.length == 0) {
        lista.push(this.conteudo(lista30));
      } else if (lista30.length == 0 && lista31.length > 0) {
        lista.push(this.conteudo31(lista31));
      }
    }

    Relatorio.imprimirPersonalizado(
      'DEMONSTRATIVO III - METAS FISCAIS COMPARADAS COM AS FIXADAS NOS TRÊS EXERCÍCIOS ANTERIORES'
      , this.login.usuario.nome, this.login.usuario.sobrenome,
      this.login.orgao.nome, this.login.brasao,
      lista,
      'landscape', 'DEMONSTRATIVO III - LDO',
      {
        linhas: {
          hLineWidth() {
            return 1;
          },
          vLineWidth() {
            return 1;
          },
          hLineColor() {
            return 'black';
          },
          paddingLeft() {
            return 3;
          },
          paddingRight() {
            return 3;
          }
        }
      });
  }

  private conteudo(dados1: any[]): {}[] {
    if (dados1.length === 0) return;

    let listaAgrupada = this.funcaoService.agrupar(dados1, ['descricao1'], ['valor1', 'valor2', 'valor4', 'valor6', 'valor8', 'valor10'])

    let lista1 = this.soma(listaAgrupada);

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `EXERCÍCIO: ${this.ano}     Lei nº 4.320/64, Art. 2º, §1º, II`,
        alignment: 'center',
        bold: true, fontSize: 11, margin: [0, -15, 0, 0],
        colSpan: 12, border: [false, false, false, false]
      }, '', '', '', '', '', '', '', '', '', '', ''],
      [{
        text: '',
        border: [true, true, true, false]
      }, {
        text: 'VALORES A PREÇOS CORRENTES', colSpan: 11, alignment: 'center',
        border: [true, true, true, false]
      }, '', '', '', '', '', '', '', '', '', ''],
      [{
        text: 'ESPECIFICAÇÃO',
        alignment: 'center',
        border: [true, false, false, true],
        bold: true, fontSize: 8
      }, {
        text: `${this.ano1}`,
        alignment: 'center', margin: [0, 0, 0, 0],
        bold: true, fontSize: 8,
      }, {
        text: `${this.ano2}`,
        alignment: 'center', margin: [0, 0, 0, 0],
        bold: true, fontSize: 8
      }, {
        text: '%',
        alignment: 'center', margin: [0, 0, 0, 0],
        bold: true, fontSize: 8
      }, {
        text: `${this.ano3}`,
        alignment: 'center', margin: [0, 0, 0, 0],
        bold: true, fontSize: 8
      }, {
        text: '%',
        alignment: 'center', margin: [0, 0, 0, 0],
        bold: true, fontSize: 8
      }, {
        text: `${this.ano4}`,
        alignment: 'center', margin: [0, 0, 0, 0],
        bold: true, fontSize: 8
      }, {
        text: '%',
        alignment: 'center', margin: [0, 0, 0, 0],
        bold: true, fontSize: 8
      }, {
        text: `${this.ano5}`,
        alignment: 'center', margin: [0, 0, 0, 0],
        bold: true, fontSize: 8
      }, {
        text: '%',
        alignment: 'center', margin: [0, 0, 0, 0],
        bold: true, fontSize: 8
      }, {
        text: `${this.ano6}`,
        alignment: 'center', margin: [0, 0, 0, 0],
        bold: true, fontSize: 8
      }, {
        text: '%',
        alignment: 'center', margin: [0, 0, 0, 0],
        bold: true, fontSize: 8
      }]
    ];

    // monta relatório
    for (const item of lista1) {
      registros.push([
        {
          text: item.registros[0].descricao1, fontSize: 8, border: [true, false, false, false],
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor1']), alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor2']), alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor3']), alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor4']), alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor5']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor6']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor7']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor8']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor9']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor10']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor11']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        }
      ]);
    }

    registros.push([
      {
        text: '', border: [false, true, false, false], margin: [0, 30, 0, 0], colSpan: 12,
      }, '', '', '', '', '', '', '', '', '', '', ''
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        // headerRows: 2,
        widths: ['*', 70, 70, 40, 70, 40, 70, 40, 70, 40, 70, 40],
        body: registros
      }
    }];
  }

  private conteudo31(dados1: any[]): {}[] {
    if (dados1.length === 0) return;

    let listaAgrupada = this.funcaoService.agrupar(dados1, ['descricao1'],
      ['valor1', 'valor2', 'valor3', 'valor4', 'valor5', 'valor6', 'valor7', 'valor8', 'valor9', 'valor10', 'valor11'])

    listaAgrupada.shift();

    let lista1 = this.soma(listaAgrupada);
    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: '',
        border: [true, true, true, false]
      }, {
        text: 'VALORES A PREÇOS CONSTANTES', colSpan: 11, alignment: 'center',
        border: [true, true, true, false],
      }, '', '', '', '', '', '', '', '', '', ''],
      [{
        text: 'ESPECIFICAÇÃO',
        alignment: 'center',
        border: [true, false, false, true],
        bold: true, fontSize: 8
      }, {
        text: `${this.ano1}`,
        alignment: 'center',
        bold: true, fontSize: 8,
      }, {
        text: `${this.ano2}`,
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: '%',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: `${this.ano3}`,
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: '%',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: `${this.ano4}`,
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: '%',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: `${this.ano5}`,
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: '%',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: `${this.ano6}`,
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: '%',
        alignment: 'center',
        bold: true, fontSize: 8
      }]
    ];

    // monta relatório
    for (const item of lista1) {
      registros.push([
        {
          text: item.registros[0].descricao1, fontSize: 8, border: [true, false, false, false],
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor1']), alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor2']), alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor3']), alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor4']), alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor5']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor6']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor7']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor8']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor9']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor10']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor11']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        }
      ]);
    }

    registros.push([
      {
        text: '', border: [false, true, false, false], colSpan: 12
      }, '', '', '', '', '', '', '', '', '', '', ''
    ]);

    if (this.listaOrgaos?.length > 0) {
      registros.push([{ text: '\n\n', colSpan: 12, border: [false, false, false, false] }, '', '', '', '', '', '', '', '', '', '', '']);
      registros.push([{ text: this.listaOrgaos.filter(o => this.orgaos.some(org => org === o.value)).map(o => o.label).join('\n'), fontSize: 8, colSpan: 12, border: [false, false, false, false] }, '', '', '', '', '', '', '', '', '', '', '']);
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        // headerRows: 2,
        widths: ['*', 70, 70, 40, 70, 40, 70, 40, 70, 40, 70, 40],
        body: registros
      }
    }];
  }

  private soma(lista) {
    //calcular as porcentagens 
    // (campo ÷ total) ×100
    for (const ldo of lista) {
      ldo.totalizadores['valor3'] = this.funcaoService.arredondar((+ldo.totalizadores['valor2'] / (+ldo.totalizadores['valor1'] > 0 ? +ldo.totalizadores['valor1'] : 1)) * 100, 2);
      ldo.totalizadores['valor5'] = this.funcaoService.arredondar((+ldo.totalizadores['valor4'] / (+ldo.totalizadores['valor2'] > 0 ? +ldo.totalizadores['valor2'] : 1)) * 100, 2);
      ldo.totalizadores['valor7'] = this.funcaoService.arredondar((+ldo.totalizadores['valor6'] / (+ldo.totalizadores['valor4'] > 0 ? +ldo.totalizadores['valor4'] : 1)) * 100, 2);
      ldo.totalizadores['valor9'] = this.funcaoService.arredondar((+ldo.totalizadores['valor8'] / (+ldo.totalizadores['valor6'] > 0 ? +ldo.totalizadores['valor6'] : 1)) * 100, 2);
      ldo.totalizadores['valor11'] = this.funcaoService.arredondar((+ldo.totalizadores['valor10'] / (+ldo.totalizadores['valor8'] > 0 ? +ldo.totalizadores['valor8'] : 1)) * 100, 2);
    }
    return lista;
  }
}
