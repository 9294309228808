import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class Tabela1Reinf {

  protected http: HttpClient;

  constructor(
    protected injector: Injector
  ) {
    this.http = injector.get(HttpClient);
  }

  carregarTabela1(): any[] {
    const grupos = [];

    grupos.push(this.carregarGrupo10());
    grupos.push(this.carregarGrupo11());
    grupos.push(this.carregarGrupo12());
    grupos.push(this.carregarGrupo13());
    grupos.push(this.carregarGrupo14());
    grupos.push(this.carregarGrupo15());
    grupos.push(this.carregarGrupo16());
    grupos.push(this.carregarGrupo17());
    grupos.push(this.carregarGrupo18());
    grupos.push(this.carregarGrupo19());
    grupos.push(this.carregarGrupo20());

    return grupos;
  }

  carregarGrupo10(): any {
    const grupo10 = {
      codigo: null,
      nome: null,
      itens: [{
        codigo: null,
        nome: null,
        FCI: null,
        S13: null,
        RRA: null,
        ded: null,
        insencao: null,
        tributo: null,
      }]
    };
    grupo10.codigo = 10;
    grupo10.nome = "Grupo 10 - Rendimento do Trabalho e da Previdência Social";
    //Itens do grupo 10
    grupo10.itens.push({ codigo: 10001, nome: "Rendimento decorrente do trabalho com vínculo empregatício", FCI: "N", S13: "S", RRA: "S", ded: "1;2;3;4;5;7", insencao: "2;3;4;5;8;99", tributo: "IR" });
    grupo10.itens.push({ codigo: 10002, nome: "Rendimento decorrente do trabalho sem vínculo empregatício", FCI: "N", S13: "N", RRA: "S", ded: "1;2;3;4;5;7", insencao: "2;3;4;5;8;99", tributo: "IR" });
    grupo10.itens.push({ codigo: 10003, nome: "Rendimento decorrente do trabalho pago a trabalhador avulso", FCI: "N", S13: "S", RRA: "S", ded: "1;2;3;4;5;7", insencao: "2;3;4;5;8;99", tributo: "IR" });
    grupo10.itens.push({ codigo: 10004, nome: "Participação nos lucros ou resultados (PLR)", FCI: "N", S13: "N", RRA: "S", ded: "1;2;3;5;7", insencao: "", tributo: "IR" });
    grupo10.itens.push({ codigo: 10005, nome: "Benefício de Regime Próprio de Previdência Social", FCI: "N", S13: "S", RRA: "S", ded: "1;2;3;5", insencao: "1;6;99", tributo: "IR" });
    grupo10.itens.push({ codigo: 10006, nome: "Benefício do Regime Geral de Previdência Social", FCI: "N", S13: "S", RRA: "S", ded: "1;2;3;5;7", insencao: "1;6;99", tributo: "IR" });
    grupo10.itens.push({ codigo: 10007, nome: "Rendimentos relativos a prestação de serviços de Transporte Rodoviário Internacional de Carga, Auferidos por Transportador Autônomo Pessoa Física, Residente na República do Paraguai, considerado como Sociedade Unipessoal nesse País", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo10.itens.push({ codigo: 10008, nome: "Honorários advocatícios de sucumbência recebidos pelos advogados e procuradores públicos de que trata o art. 27 da Lei nº 13.327", FCI: "N", S13: "N", RRA: "S", ded: "1;2;3;4;5;7", insencao: "", tributo: "IR" });
    grupo10.itens.push({ codigo: 10009, nome: "Auxílio moradia", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "10", tributo: "IR" });
    grupo10.itens.push({ codigo: 10010, nome: "Bolsa ao médico residente", FCI: "N", S13: "S", RRA: "S", ded: "", insencao: "", tributo: "" });

    return grupo10;
  }

  carregarGrupo11(): any {
    const grupo11 = {
      codigo: null,
      nome: null,
      itens: [{
        codigo: null,
        nome: null,
        FCI: null,
        S13: null,
        RRA: null,
        ded: null,
        insencao: null,
        tributo: null,
      }]
    };
    grupo11.codigo = 11;
    grupo11.nome = "Grupo 11 - Rendimento decorrente de Decisão Judicial";
    //Itens do grupo 11
    grupo11.itens.push({ codigo: 11001, nome: "Decorrente de Decisão da Justiça do Trabalho", FCI: "N", S13: "S", RRA: "S", ded: "1;5;7", insencao: "2;3;4;5;7;8;99", tributo: "IR" });
    grupo11.itens.push({ codigo: 11002, nome: "Decorrente de Decisão da Justiça Federal", FCI: "N", S13: "S", RRA: "S", ded: "1;2;3;4;5;7", insencao: "1,2;3;4;5;6;7;8;9;9 9", tributo: "IR" });
    grupo11.itens.push({ codigo: 11003, nome: "Decorrente de Decisão da Justiça dos Estados/Distrito Federal", FCI: "N", S13: "S", RRA: "S", ded: "1;2;3;4;5;7", insencao: "1,2;3;4;5;6;7;8;99", tributo: "IR" });
    grupo11.itens.push({ codigo: 11004, nome: "Responsabilidade Civil - juros e indenizações por lucros cessantes, inclusive astreinte", FCI: "N", S13: "S", RRA: "S", ded: "1;2;3;4;5;7", insencao: "1,2;3;4;5;6;7;8;99", tributo: "IR" });
    grupo11.itens.push({ codigo: 11005, nome: "Decisão Judicial – Importâncias pagas a título de indenizações por danos morais, decorrentes de sentença judicial.", FCI: "N", S13: "S", RRA: "S", ded: "1;2;3;4;5;7", insencao: "1,2;3;4;5;6;7;8;99", tributo: "IR" });

    return grupo11;
  }

  carregarGrupo12(): any {
    const grupo12 = {
      codigo: null,
      nome: null,
      itens: [{
        codigo: null,
        nome: null,
        FCI: null,
        S13: null,
        RRA: null,
        ded: null,
        insencao: null,
        tributo: null,
      }]
    };
    grupo12.codigo = 12;
    grupo12.nome = "Grupo 12 - Rendimento do Capital";
    //Itens do grupo 12
    grupo12.itens.push({ codigo: 12001, nome: "Lucro e Dividendo", FCI: "N", S13: "N", RRA: "S", ded: "", insencao: "", tributo: "" });
    grupo12.itens.push({ codigo: 12002, nome: "Resgate de Previdência Complementar - Modalidade Contribuição Definida/Variável - Não Optante pela Tributação Exclusiva", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "7", tributo: "IR" });
    grupo12.itens.push({ codigo: 12003, nome: "Resgate de Fundo de Aposentadoria Programada Individual (Fapi)- Não Optante pela Tributação Exclusiva", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "7", tributo: "IR" });
    grupo12.itens.push({ codigo: 12004, nome: "Resgate de Previdência Complementar - Modalidade Benefício Definido - Não Optante pela Tributação Exclusiva", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "7", tributo: "IR" });
    grupo12.itens.push({ codigo: 12005, nome: "Resgate de Previdência Complementar - Modalidade Contribuição Definida/Variável - Optante pela Tributação Exclusiva", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "7", tributo: "IR" });
    grupo12.itens.push({ codigo: 12006, nome: "Resgate de Fundo de Aposentadoria Programada Individual (Fapi)- Optante pela Tributação Exclusiva", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "7", tributo: "IR" });
    grupo12.itens.push({ codigo: 12007, nome: "Resgate de Planos de Seguro de Vida com Cláusula de Cobertura por Sobrevivência- Optante pela Tributação Exclusiva", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12008, nome: "Resgate de Planos de Seguro de Vida com Cláusula de Cobertura por Sobrevivência - Não Optante pela Tributação Exclusiva", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12009, nome: "Benefício de Previdência Complementar - Modalidade Contribuição Definida/Variável - Não Optante pela Tributação Exclusiva", FCI: "N", S13: "S", RRA: "S", ded: "2;3;4;5;7", insencao: "1,6,7,99", tributo: "IR" });
    grupo12.itens.push({ codigo: 12010, nome: "Benefício de Fundo de Aposentadoria Programada Individual (Fapi)- Não Optante pela Tributação Exclusiva", FCI: "N", S13: "S", RRA: "S", ded: "2;3;4;5;7", insencao: "1,6,7,99", tributo: "IR" });
    grupo12.itens.push({ codigo: 12011, nome: "Benefício de Previdência Complementar - Modalidade Benefício Definido - Não Optante pela Tributação Exclusiva", FCI: "N", S13: "S", RRA: "S", ded: "2;3;4;5;7", insencao: "1,6,7,99", tributo: "IR" });
    grupo12.itens.push({ codigo: 12012, nome: "Benefício de Previdência Complementar - Modalidade Contribuição Definida/Variável - Optante pela Tributação Exclusiva", FCI: "N", S13: "S", RRA: "S", ded: "", insencao: "6;7", tributo: "IR" });
    grupo12.itens.push({ codigo: 12013, nome: "Benefício de Fundo de Aposentadoria Programada Individual (Fapi)- Optante pela Tributação Exclusiva", FCI: "N", S13: "S", RRA: "S", ded: "", insencao: "6;7", tributo: "IR" });
    grupo12.itens.push({ codigo: 12014, nome: "Benefício de Planos de Seguro de Vida com Cláusula de Cobertura por Sobrevivência- Optante pela Tributação Exclusiva", FCI: "N", S13: "S", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12015, nome: "Benefício de Planos de Seguro de Vida com Cláusula de Cobertura por Sobrevivência - Não Optante pela Tributação Exclusiva", FCI: "N", S13: "N", RRA: "S", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12016, nome: "Juros sobre o Capital Próprio", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12017, nome: "Rendimento de Aplicações Financeiras de Renda Fixa, decorrentes de alienação, liquidação (total ou parcial), resgate, cessão ou repactuação do título ou aplicação", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12018, nome: "Rendimentos auferidos pela entrega de recursos à pessoa jurídica, sob qualquer forma e a qualquer título, independentemente de ser ou não a fonte pagadora instituição autorizada a funcionar pelo Banco Central", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12019, nome: "Rendimentos predeterminados obtidos em operações conjugadas realizadas: nos mercados de opções de compra e venda em bolsas de valores, de mercadorias e de futuros (box);no mercado a termo nas bolsas de valores, de mercadorias e de futuros, em operações de venda coberta e sem ajustes diários, e no mercado de balcão.", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12020, nome: "Rendimentos obtidos nas operações de transferência de dívidas realizadas com instituição financeira e outras instituições autorizadas a funcionar pelo Banco Central do Brasil", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12021, nome: "Rendimentos periódicos produzidos por título ou aplicação, bem como qualquer remuneração adicional aos rendimentos prefixados", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12022, nome: "Rendimentos auferidos nas operações de mútuo de recursos financeiros entre pessoa física e pessoa jurídica e entre pessoas jurídicas, inclusive controladoras, controladas, coligadas e interligadas", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12023, nome: "Rendimentos auferidos em operações de adiantamento sobre contratos de câmbio de exportação, não sacado (trava de câmbio), bem como operações com export notes, com debêntures, com depósitos voluntários para garantia de instância e com depósitos judiciais ou administrativos, quando seu levantamento se der em favor do depositante", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12024, nome: "Rendimentos obtidos nas operações de mútuo e de compra vinculada à revenda tendo por objeto ouro, ativo financeiro", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12025, nome: "Rendimentos auferidos em contas de depósitos de poupança", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12026, nome: "Rendimentos auferidos sobre juros produzidos por letras hipotecárias", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12027, nome: "Rendimentos ou ganhos decorrentes da negociação de títulos ou valores mobiliários de renda fixa em bolsas de valores, de mercadorias, de futuros e assemelhadas", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12028, nome: "Rendimentos auferidos em outras aplicações financeiras de renda fixa ou de renda variável", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12029, nome: "Rendimentos auferidos em Fundo de Investimento", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12030, nome: "Rendimentos auferidos em Fundos de investimento em quotas de fundos de investimento", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12031, nome: "Rendimentos produzidos por aplicações em fundos de investimento em ações", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12032, nome: "Rendimentos produzidos por aplicações em fundos de investimento em quotas de fundos de investimento em ações", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12033, nome: "Rendimentos produzidos por aplicações em Fundos Mútuos de Privatização com recursos do Fundo de Garantia por Tempo de Serviço (FGTS)", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12034, nome: "Rendimentos auferidos pela carteira dos Fundos de Investimento Imobiliário", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12035, nome: "Rendimentos distribuídos pelo Fundo de Investimento Imobiliário aos seus cotistas", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12036, nome: "Rendimento auferido pelo cotista no resgate de cotas na liquidação do Fundo de Investimento Imobiliário", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12037, nome: "Rendimentos auferidos pela carteira dos Fundos de Investimento Imobiliário – Distribuição semestral", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12038, nome: "Rendimentos distribuídos pelo Fundo de Investimento Imobiliário aos seus cotistas - – Distribuição semestral", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12039, nome: "Rendimento auferido pelo cotista no resgate de cotas na liquidação do Fundo de Investimento Imobiliário – Distribuição semestral", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12040, nome: "Rendimentos e ganhos de capital distribuídos pelo Fundo de Investimento Cultural e Artístico (Ficart) ", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12041, nome: "Rendimentos e ganhos de capital distribuídos pelo Fundo de Financiamento da Indústria Cinematográfica Nacional (Funcines)", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12042, nome: "Rendimentos auferidos no resgate de quotas de fundos de investimento mantidos com recursos provenientes de conversão de débitos externos brasileiros, e de que participem, exclusivamente, residentes ou domiciliados no exterior", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12043, nome: "Ganho de capital decorrente da integralização de cotas de fundos ou clubes de investimento por meio da entrega de ativos financeiros", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12044, nome: "Distribuição de Juros sobre o Capital Próprio pela companhia emissora de ações objeto de empréstimo conforme art 8º da Lei nº 13.043 de 2014", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12045, nome: "Rendimentos de Partes Beneficiárias ou de Fundador", FCI: "N", S13: "N", RRA: "N", ded: "1;5", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12046, nome: "Rendimentos auferidos em operações de swap", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12047, nome: "Rendimentos auferidos em operações day trade realizadas em bolsa de valores, de mercadorias, de futuros e assemelhadas", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12048, nome: "Rendimento decorrente de Operação realizada em bolsas de valores, de mercadorias, de futuros, e assemelhadas, exceto day trade", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12049, nome: "Rendimento decorrente de Operação realizada no mercado de balcão, com intermediação, tendo por objeto ações, ouro ativo financeiro e outros valores mobiliários negociados no mercado à vista", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12050, nome: "Rendimento decorrente de Operação realizada em mercados de liquidação futura fora de bolsa", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12051, nome: "Rendimentos de aplicações financeiras em ativos de infraestrutura - tributação exclusiva - conforme art. 1º da Lei 11.478 de 2007 e arts. 2º e 3º da Lei nº 12.431 de 2011", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12053, nome: "Rendimentos e ganhos de capitual distribuídos e resgate de cotas de Fundo de Investimento nas Cadeias Produtivas do Agronegócio (Fiagro) conforme art 20C e D da Lei 8.668 de 1993", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12054, nome: "Rendimentos distribuídos, resgate ou amortização de cotas de Fundo de Investimento em Participações (FIP) classificado como entidade de investimento (art. 18 da Lei nº 14.754 de 2023)", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12055, nome: "Rendimentos distribuídos, resgate ou amortização de cotas de Fundo de Investimento em índice de Mercado (Exchange Traded Fund - ETF) classificado como entidade de investimento (art. 18 da Lei nº 14.754 de 2023)", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12056, nome: "Rendimentos distribuídos, resgate ou amortização de cotas de Fundo de Investimento em Direitos Creditórios (FIDC) classfificado como entidade de investimento (art. 18 da Lei nº 14.754 de 2023)", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12057, nome: "Rendimentos distribuídos, resgate ou amortização de cotas de Fundo de Investimento em Participações (FIP) não classificado como entidade de investimento e sujeito à tributação periódica (Art. 26 da Lei nº 14.754, de 2023)", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12058, nome: "Rendimentos distribuídos, resgate ou amortização de cotas de Fundo de Investimento em índice de Mercado (Exchange Traded Fund - ETF) não classificado como entidade de investimento e sujeito à tributação periódica (art. 26 da Lei nº 14.754, de 2023)", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12059, nome: "Rendimentos distribuídos, resgate ou amortização de cotas de Fundo de Investimento em Direitos Creditórios (FIDC) não classificado como entidade de investimento e sujeito à tributação periódica (art. 26 da Lei nº 14.754, de 2023)", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12060, nome: "Rendimentos e ganhos de capital auferidos por cotistas de Fundo de índice de Renda Fixa (ETF) conforme art. 2º da Lei nº 13.043 de 2014", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12061, nome: "Rendimentos distribuídos, resgate ou amortização de cotas de Fundo Multimercado (fim) CONFORME ART. 40 DA lEI Nº 14.754 DE 2023", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12062, nome: "Rendimentos decorrente de debêntures de infraestrutura conforme previsto no art. 4º da Lei nº 18.801 de 2024, quando auferidos pelos fundos isentos no resgate, na amortização e na alienação de cotas ou na distribuição de rendimentos.", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo12.itens.push({ codigo: 12099, nome: "Demais rendimentos de Capital", FCI: "S", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });

    return grupo12;
  }

  carregarGrupo13(): any {
    const grupo13 = {
      codigo: null,
      nome: null,
      itens: [{
        codigo: null,
        nome: null,
        FCI: null,
        S13: null,
        RRA: null,
        ded: null,
        insencao: null,
        tributo: null,
      }]
    };
    grupo13.codigo = 13;
    grupo13.nome = "Grupo 13 - Rendimento de Direitos (Royalties)";
    //Itens do grupo 13
    grupo13.itens.push({ codigo: 13001, nome: "Rendimentos de Aforamento", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13002, nome: "Rendimentos de Locação ou Sublocação", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13003, nome: "Rendimentos de Arrendamento ou Subarrendamento", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13004, nome: "Importâncias pagas por terceiros por conta do locador do bem (juros, comissões etc.)", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13005, nome: "Importâncias pagas ao locador pelo contrato celebrado (luvas, prêmios etc.)", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13006, nome: "Benfeitorias e quaisquer melhoramentos realizados no bem locado", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13007, nome: "Juros decorrente da alienação a prazo de bens", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13008, nome: "Rendimentos de Direito de Uso ou Passagem de Terrenos e de aproveitamento de águas", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13009, nome: "Rendimentos de Direito de colher ou extrair recursos vegetais, pesquisar e extrair recursos minerais", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13010, nome: "Rendimentos de Direito Autoral", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13011, nome: "Rendimentos de Direito Autoral (quando não percebidos pelo autor ou criador da obra)", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13012, nome: "Rendimentos de Direito de Imagem", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13013, nome: "Rendimentos de Direito sobre películas cinematográficas, Obras Audiovisuais, e Videofônicas", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13014, nome: "Rendimento de Direito relativo a radiodifusão de sons e imagens e serviço de comunicação eletrônica de massa por assinatura", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13015, nome: "Rendimentos de Direito de Conjuntos Industriais e Invenções", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13016, nome: "Rendimento de Direito de marcas de indústria e comércio, patentes de invenção e processo ou fórmulas de fabricação", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13017, nome: "Importâncias pagas por terceiros por conta do cedente dos direitos (juros, comissões etc.)", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13018, nome: "Importâncias pagas ao cedente do direito, pelo contrato celebrado (luvas, prêmios etc.)", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13019, nome: "Despesas para conservação dos direitos cedidos (quando compensadas pelo uso do bem ou direito)", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13020, nome: "Juros de mora e quaisquer outras compensações pelo atraso no pagamento de royalties – decorrente de prestação de serviço", FCI: "N", S13: "N", RRA: "S", ded: "1;5", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13021, nome: "Juros de mora e quaisquer outras compensações pelo atraso no pagamento de royalties – decorrente de aquisição de bens", FCI: "N", S13: "N", RRA: "S", ded: "1;5", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13022, nome: "Juros decorrente da alienação a prazo de direitos – decorrente de prestação de serviço", FCI: "N", S13: "N", RRA: "S", ded: "1;5", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13023, nome: "Juros decorrente da alienação a prazo de direitos – decorrente de aquisição de bens", FCI: "N", S13: "N", RRA: "S", ded: "1;5", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13024, nome: "Alienação de bens e direitos do ativo não circulante localizados no Brasil", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13025, nome: "Rendimento de Direito decorrente da transferência de atleta profissional", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13026, nome: "Juros e comissões correspondentes à parcela dos créditos de que trata o inciso XI do art. 1º da Lei nº 9.481, de 1997, não aplicada no financiamento de exportações", FCI: "N", S13: "N", RRA: "S", ded: "1;5", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13098, nome: "Demais rendimentos de Royalties", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });
    grupo13.itens.push({ codigo: 13099, nome: "Demais rendimentos de Direito", FCI: "N", S13: "N", RRA: "S", ded: "1;5;7", insencao: "", tributo: "IR" });

    return grupo13;
  }

  carregarGrupo14(): any {
    const grupo14 = {
      codigo: null,
      nome: null,
      itens: [{
        codigo: null,
        nome: null,
        FCI: null,
        S13: null,
        RRA: null,
        ded: null,
        insencao: null,
        tributo: null,
      }]
    };
    grupo14.codigo = 14;
    grupo14.nome = "Grupo 14 - Prêmios e demais rendimentos";

    grupo14.itens.push({ codigo: 14001, nome: "Prêmios distribuídos, sob a forma de bens e serviços, mediante loterias, concursos e sorteios, exceto a distribuição realizada por meio de vale-brinde", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo14.itens.push({ codigo: 14002, nome: "Prêmios distribuídos, sob a forma de dinheiro, mediante loterias, concursos e sorteios, exceto os de antecipação nos títulos de capitalização e os de amortização e resgate das ações das sociedades anônimas", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo14.itens.push({ codigo: 14003, nome: "Prêmios de Proprietários e Criadores de Cavalos de Corrida", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo14.itens.push({ codigo: 14004, nome: "Benefícios líquidos mediante sorteio de títulos de capitalização, sem amortização antecipada", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo14.itens.push({ codigo: 14005, nome: "Benefícios líquidos resultantes da amortização antecipada, mediante sorteio, dos títulos de capitalização e benefícios atribuídos aos portadores de títulos de capitalização nos lucros da empresa emitente", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo14.itens.push({ codigo: 14006, nome: "Prêmios distribuídos, sob a forma de bens e serviços, mediante sorteios de jogos de bingo permanente ou eventual", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo14.itens.push({ codigo: 14007, nome: "Prêmios distribuídos, em dinheiro, obtido mediante sorteios de jogos de bingo permanente ou eventual", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo14.itens.push({ codigo: 14008, nome: "Importâncias correspondentes a multas e qualquer outra vantagem, ainda que a título de indenização, em virtude de rescisão de contrato", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo14.itens.push({ codigo: 14099, nome: "Demais Benefícios Líquidos decorrentes de título de capitalização", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });

    return grupo14;
  }

  carregarGrupo15(): any {
    const grupo15 = {
      codigo: null,
      nome: null,
      itens: [{
        codigo: null,
        nome: null,
        FCI: null,
        S13: null,
        RRA: null,
        ded: null,
        insencao: null,
        tributo: null,
      }]
    };
    grupo15.codigo = 15;
    grupo15.nome = "Grupo 15 - Rendimento Pago/Creditado a Pessoa Jurídica";

    //Itens grupo 15
    grupo15.itens.push({ codigo: 15001, nome: "Importâncias pagas ou creditadas a cooperativas de trabalho relativas a serviços pessoais que lhes forem prestados por associados destas ou colocados à disposição", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15002, nome: "Importâncias pagas ou creditadas a associações de profissionais ou assemelhadas, relativas a serviços pessoais que lhes forem prestados por associados destas ou colocados à disposição", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15003, nome: "Remuneração de Serviços de administração de bens ou negócios em geral, exceto consórcios ou fundos mútuos para aquisição de bens", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15004, nome: "Remuneração de Serviços de advocacia", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15005, nome: "Remuneração de Serviços de análise clínica laboratorial", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15006, nome: "Remuneração de Serviços de análises técnicas", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15007, nome: "Remuneração de Serviços de arquitetura", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15008, nome: "Remuneração de Serviços de assessoria e consultoria técnica, exceto serviço de assistência técnica prestado a terceiros e concernente a ramo de indústria ou comércio explorado pelo prestador do serviço;", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15009, nome: "Remuneração de Serviços de assistência social;", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15010, nome: "Remuneração de Serviços de auditoria;", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15011, nome: "Remuneração de Serviços de avaliação e perícia;", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15012, nome: "Remuneração de Serviços de biologia e biomedicina;", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15013, nome: "Remuneração de Serviços de cálculo em geral", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15014, nome: "Remuneração de Serviços de consultoria", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15015, nome: "Remuneração de Serviços de contabilidade", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15016, nome: "Remuneração de Serviços de desenho técnico", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15017, nome: "Remuneração de Serviços de economia", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15018, nome: "Remuneração de Serviços de elaboração de projetos", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg " });
    grupo15.itens.push({ codigo: 15019, nome: "Remuneração de Serviços de engenharia, exceto construção de estradas, pontes, prédios e obras assemelhada", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15020, nome: "Remuneração de Serviços de ensino e treinamento", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15021, nome: "Remuneração de Serviços de estatística", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15022, nome: "Remuneração de Serviços de fisioterapia", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15023, nome: "Remuneração de Serviços de fonoaudiologia", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15024, nome: "Remuneração de Serviços de geologia", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15025, nome: "Remuneração de Serviços de leilão", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15026, nome: "Remuneração de Serviços de medicina, exceto aquela prestada por ambulatório, banco de sangue, casa de saúde, casa de recuperação ou repouso sob orientação médica, hospital e pronto-socorro", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15027, nome: "Remuneração de Serviços de nutricionismo e dietética", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15028, nome: "Remuneração de Serviços de odontologia", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15029, nome: "Remuneração de Serviços de organização de feiras de amostras, congressos, seminários, simpósios e congêneres", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15030, nome: "Remuneração de Serviços de pesquisa em geral", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15031, nome: "Remuneração de Serviços de planejamento", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15032, nome: "Remuneração de Serviços de programação", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15033, nome: "Remuneração de Serviços de prótese", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15034, nome: "Remuneração de Serviços de psicologia e psicanálise", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15035, nome: "Remuneração de Serviços de química", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15036, nome: "Remuneração de Serviços de radiologia e radioterapia", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15037, nome: "Remuneração de Serviços de relações públicas", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15038, nome: "Remuneração de Serviços de serviço de despachante", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15039, nome: "Remuneração de Serviços de terapêutica ocupacional", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15040, nome: "Remuneração de Serviços de tradução ou interpretação comercial", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15041, nome: "Remuneração de Serviços de urbanismo", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15042, nome: "Remuneração de Serviços de veterinária", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15043, nome: "Remuneração de Serviços de Limpeza", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15044, nome: "Remuneração de Serviços de Conservação/ Manutenção, exceto reformas e obras assemelhadas", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15045, nome: "Remuneração de Serviços de Segurança/Vigilância/Transporte de valores", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15046, nome: "Remuneração de Serviços Locação de Mão de obra", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15047, nome: "Remuneração de Serviços de Assessoria Creditícia, Mercadológica, Gestão de Crédito, Seleção e Riscos e Administração de Contas a Pagar e a Receber", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo15.itens.push({ codigo: 15048, nome: "Pagamentos Referentes à Aquisição de Autopeças", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "COFINS, PP" });
    grupo15.itens.push({ codigo: 15049, nome: "Pagamentos a entidades imunes ou isentas – IN RFB 1.234/2012", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "" });
    grupo15.itens.push({ codigo: 15050, nome: "Pagamento a título de transporte internacional de valores efetuado por empresas nacionais estaleiros navais brasileiros nas atividades de conservação, modernização, conversão e reparo de embarcações pré-registradas ou registradas no Registro Especial Brasileiro (REB)", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL" });
    grupo15.itens.push({ codigo: 15051, nome: "Pagamento efetuado a empresas estrangeiras de transporte de valores", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo15.itens.push({ codigo: 15099, nome: "Demais Rendimentos de serviços técnicos, de assistência técnica, de assistência administrativa e semelhantes", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });


    return grupo15;
  }

  carregarGrupo16(): any {
    const grupo16 = {
      codigo: null,
      nome: null,
      itens: [{
        codigo: null,
        nome: null,
        FCI: null,
        S13: null,
        RRA: null,
        ded: null,
        insencao: null,
        tributo: null,
      }]
    };
    grupo16.codigo = 16;
    grupo16.nome = "Grupo 16 - Demais Rendimentos de Residentes ou domiciliados no Exterior";

    grupo16.itens.push({ codigo: 16001, nome: "Rendimentos de serviços técnicos, de assistência técnica, de assistência administrativa e semelhantes", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo16.itens.push({ codigo: 16002, nome: "Demais Rendimentos de juros e comissões", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo16.itens.push({ codigo: 16003, nome: "Rendimento pago a companhia de navegação aérea e marítima", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo16.itens.push({ codigo: 16004, nome: "Rendimento de Direito relativo a exploração de obras audiovisuais estrangeiras, radiodifusão de sons e imagens e serviço de comunicação eletrônica de massa por assinatura", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo16.itens.push({ codigo: 16005, nome: "Demais Rendimentos de qualquer natureza", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo16.itens.push({ codigo: 16006, nome: "Demais Rendimentos sujeitos à Alíquota Zero", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });


    return grupo16;
  }

  carregarGrupo17(): any {
    const grupo17 = {
      codigo: null,
      nome: null,
      itens: [{
        codigo: null,
        nome: null,
        FCI: null,
        S13: null,
        RRA: null,
        ded: null,
        insencao: null,
        tributo: null,
      }]
    };
    grupo17.codigo = 17;
    grupo17.nome = "Grupo 17 - Rendimentos pagos/creditados EXCLUSIVAMENTE por órgãos da administração federal direta, autarquias e fundações federais, empresas públicas, sociedades de economia mista e demais entidades em que a União, direta ou indiretamente detenha a maioria do capital social sujeito a voto, e que recebam recursos do Tesouro Nacional";

    //Itens grupo 17
    grupo17.itens.push({ codigo: 17001, nome: "Alimentação", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17002, nome: "Energia elétrica", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17003, nome: "Serviços prestados com emprego de materiais", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17004, nome: "Construção Civil por empreitada com emprego de materiais", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17005, nome: "Serviços hospitalares de que trata o art. 30 da Instrução Normativa RFB nº 1.234, de 11 de janeiro de 2012", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17006, nome: "Transporte de cargas, exceto os relacionados na natureza de rendimento 17017", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17007, nome: "Serviços de auxílio diagnóstico e terapia, patologia clínica, imagenologia, anatomia patológica e citopatológica, medicina nuclear e análises e patologias clínicas, exames por métodos gráficos, procedimentos endoscópicos, radioterapia, quimioterapia, diálise e oxigenoterapia hiperbárica de que trata o art. 31 e parágrafo único da Instrução Normativa RFB nº 1.234, de 2012", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17008, nome: "Produtos farmacêuticos, de perfumaria, de toucador ou de higiene pessoal adquiridos de produtor, importador, distribuidor ou varejista, exceto os relacionados nas naturezas de rendimentos de 17019 a 17022", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17009, nome: "Mercadorias e bens em geral", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17010, nome: "Gasolina, inclusive de aviação, óleo diesel, gás liquefeito de petróleo (GLP), combustíveis derivados de petróleo ou de gás natural, querosene de aviação (QAV), e demais produtos derivados de petróleo, adquiridos de refinarias de petróleo, de demais produtores, de importadores, de distribuidor ou varejista", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17011, nome: "Álcool etílico hidratado, inclusive para fins carburantes, adquirido diretamente de produtor, importador ou do distribuidor", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17012, nome: "Biodiesel adquirido de produtor ou importador", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17013, nome: "Gasolina, exceto gasolina de aviação, óleo diesel e gás liquefeito de petróleo (GLP), derivados de petróleo ou de gás natural e querosene de aviação adquiridos de distribuidores e comerciantes varejistas", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, Agreg" });
    grupo17.itens.push({ codigo: 17014, nome: "Álcool etílico hidratado nacional, inclusive para fins carburantes adquirido de comerciante varejista", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, Agreg" });
    grupo17.itens.push({ codigo: 17015, nome: "Biodiesel adquirido de distribuidores e comerciantes varejistas", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, Agreg" });
    grupo17.itens.push({ codigo: 17016, nome: "Biodiesel adquirido de produtor detentor regular do selo “Combustível Social”, fabricado a partir de mamona ou fruto, caroço ou amêndoa de palma produzidos nas regiões norte e nordeste e no semiárido, por agricultor familiar enquadrado no Programa Nacional de Fortalecimento da Agricultura Familiar (Pronaf)", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, Agreg" });
    grupo17.itens.push({ codigo: 17017, nome: "Transporte internacional de cargas efetuado por empresas nacionais", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, Agreg" });
    grupo17.itens.push({ codigo: 17018, nome: "Estaleiros navais brasileiros nas atividades de Construção, conservação, modernização, conversão e reparo de embarcações pré- registradas ou registradas no REB", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, Agreg" });
    grupo17.itens.push({ codigo: 17019, nome: "Produtos de perfumaria, de toucador e de higiene pessoal a que se refere o § 1º do art. 22 da Instrução Normativa RFB nº 1.234, de 2012, adquiridos de distribuidores e de comerciantes varejistas", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, Agreg" });
    grupo17.itens.push({ codigo: 17020, nome: "Produtos a que se refere o § 2º do art. 22 da Instrução Normativa RFB nº 1.234, de 2012", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, Agreg" });
    grupo17.itens.push({ codigo: 17021, nome: "Produtos de que tratam as alíneas “c” a “k” do inciso I do art. 5º da Instrução Normativa RFB nº 1.234, de 2012", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, Agreg" });
    grupo17.itens.push({ codigo: 17022, nome: "Outros produtos ou serviços beneficiados com isenção, não incidência ou alíquotas zero da Cofins e da Contribuição para o PIS/Pasep, observado o disposto no § 5º do art. 2º da Instrução Normativa RFB nº 1.234, de 2012", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, Agreg" });
    grupo17.itens.push({ codigo: 17023, nome: "Passagens aéreas, rodoviárias e demais serviços de transporte de passageiros, inclusive, tarifa de embarque, exceto transporte internacional de passageiros, efetuado por empresas nacionais", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17024, nome: "Transporte internacional de passageiros efetuado por empresas nacionais", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, Agreg" });
    grupo17.itens.push({ codigo: 17025, nome: "Serviços prestados por associações profissionais ou assemelhadas e cooperativas", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17026, nome: "Serviços prestados por bancos comerciais, bancos de investimento, bancos de desenvolvimento, caixas econômicas, sociedades de crédito, financiamento e investimento, sociedades de crédito imobiliário, e câmbio, distribuidoras de títulos e valores mobiliários, empresas de arrendamento mercantil, cooperativas de crédito, empresas de seguros privados e de capitalização e entidades abertas de previdência complementar", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17027, nome: "Seguro Saúde", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17028, nome: "Serviços de abastecimento de água", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17029, nome: "Telefone", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17030, nome: "Correio e telégrafos", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17031, nome: "Vigilância", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17032, nome: "Limpeza", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17033, nome: "Locação de mão de obra", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17034, nome: "Intermediação de negócios;", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17035, nome: "Administração, locação ou cessão de bens imóveis, móveis e direitos de qualquer natureza", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17036, nome: "Factoring", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17037, nome: "Plano de saúde humano, veterinário ou odontológico com valores fixos por servidor, por empregado ou por animal", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17038, nome: "Pagamento efetuado a sociedade cooperativa pelo fornecimento de bens, conforme art. 24, da IN 1234/12.", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17039, nome: "Pagamento a Cooperativa de produção, em relação aos atos decorrentes da comercialização ou da industrialização de produtos de seus associados, excetuado o previsto no §§ 1º e 2º do art. 25 da IN 1.234/12", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "" });
    grupo17.itens.push({ codigo: 17040, nome: "Serviços prestados por associações profissionais ou assemelhadas e cooperativas que envolver parcela de serviços fornecidos por terceiros não cooperados ou não associados, contratados ou conveniados, para cumprimento de contratos – Serviços prestados com emprego de materiais, inclusive o de que trata a alínea “C” do Inciso II do art. 27 da IN 1.1234.", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17041, nome: "Serviços prestados por associações profissionais ou assemelhadas e cooperativas que envolver parcela de serviços fornecidos por terceiros não cooperados ou não associados, contratados ou conveniados, para cumprimento de contratos - Demais serviços", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17042, nome: "Pagamentos efetuados às associações e às cooperativas de médicos e de odontólogos, relativamente às importâncias recebidas a título de comissão, taxa de administração ou de adesão ao plano", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17043, nome: "Pagamento efetuado a sociedade cooperativa de produção, em relação aos atos decorrentes da comercialização ou de industrialização, pelas cooperativas agropecuárias e de pesca, de produtos adquiridos de não associados, agricultores, pecuaristas ou pescadores, para completar lotes destinados aoa cumprimento de contratos ou para suprir capacidade ociosa de suas instalações industriais, conforme § 1º do art. 25, da IN 1234/12.", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17044, nome: "Pagamento referente a aluguel de imóvel quando efetuado à entidade aberta de previdência complementar sem fins lucrativos, de que trata o art 34, § 2º da IN 1.234/2012.", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "CSLL, COFINS, PP" });
    grupo17.itens.push({ codigo: 17045, nome: "Serviços prestados por cooperativas de radiotaxi, bem como àquelas cujos cooperados se dediquem a serviços relacionados a atividades culturais e demais cooperativas de serviços, conforme art. 5º– A, da IN RFB 1.234/2012.", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL" });
    grupo17.itens.push({ codigo: 17046, nome: "Pagamento efetuado na aquisição de bem imóvel, quando o vendedor for pessoa jurídica que exerce a atividade de compra e venda de imóveis, ou quando se tratar de imóveis adquiridos de entidades abertas de previdência complementar com fins lucrativos, conforme art. 23, inc I, da IN RFB 1234/2012.", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17047, nome: "Pagamento efetuado na aquisição de bem imóvel adquirido pertencente ao ativo não circulante da empresa vendedora, conforme art. 23, inc II da IN RFB 1234/2012.", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, Agreg" });
    grupo17.itens.push({ codigo: 17048, nome: "Pagamento efetuado na aquisição de bem imóvel adquirido de entidade aberta de previdência complementar sem fins lucrativos, conforme art. 23, inc III, da IN RFB 1234/2012.", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "CSLL, COFINS, PP" });
    grupo17.itens.push({ codigo: 17049, nome: "Propaganda e Publicidade, em desconformidade ao art 16 da IN RFB 1234/2012, referente ao § 4º do citado artigo.", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17050, nome: "Propaganda e Publicidade, em conformidade ao art 16 da IN RFB 1234/2012, referente ao § 4º do citado artigo.", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "CSLL, COFINS, PP, Agreg" });
    grupo17.itens.push({ codigo: 17099, nome: "Demais serviços", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR, CSLL, COFINS, PP, Agreg" });

    return grupo17;
  }

  carregarGrupo18(): any {
    const grupo18 = {
      codigo: null,
      nome: null,
      itens: [{
        codigo: null,
        nome: null,
        FCI: null,
        S13: null,
        RRA: null,
        ded: null,
        insencao: null,
        tributo: null,
      }]
    };
    grupo18.codigo = 18;
    grupo18.nome = "Grupo 18 - Rendimentos pagos/creditados EXCLUSIVAMENTE por órgãos, autarquias e fundações dos estados, do Distrito Federal e dos municípios";

    //Itens grupo 18
    grupo18.itens.push({ codigo: 18001, nome: "Fornecimento de bens, nos termos do art. 33 da Lei nº 10.833, de 2003", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "CSLL, COFINS, PP, Agreg" });
    grupo18.itens.push({ codigo: 18002, nome: "Prestação de serviços em geral, nos termos do art. 33 da Lei nº 10.833, de 2003", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "CSLL, COFINS, PP, Agreg" });
    grupo18.itens.push({ codigo: 18003, nome: "Transporte internacional de cargas ou de passageiros efetuados por empresas nacionais, aos estaleiros navais brasileiros e na aquisição de produtos isentos ou com Alíquota zero da Cofins e Pis/Pasep, conforme art. 4º, da IN SRF nº 475 de 2004.", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "CSLL" });
    grupo18.itens.push({ codigo: 18004, nome: "Pagamentos efetuados às cooperativas, em relação aos atos cooperativos, conforme art. 5º, da IN SRF nº 475 de 2004.", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "COFINS, PP" });
    grupo18.itens.push({ codigo: 18005, nome: "Aquisição de imóvel pertencente a ativo permanente da empresa vendedora, conforme art. 19, II, da IN SRF nº 475 de 2004.", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "CSLL" });
    grupo18.itens.push({ codigo: 18006, nome: "Pagamentos efetuados às sociedades cooperativas, pelo fornecimento de bens ou serviços, conforme art. 24, II, da IN SRF nº 475 de 2004.", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "COFINS, PP" });
    grupo18.itens.push({ codigo: 18007, nome: "Pagamentos efetuados à sociedade cooperativa de produção, em relação aos atos decorrentes da comercialização ou industrialização de produtos de seus associados, conforme art. 25, da IN SRF nº 475 de 2004.", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "" });
    grupo18.itens.push({ codigo: 18008, nome: "Pagamentos efetuados às cooperativas de trabalho, pela prestação de serviços pessoais prestados pelos cooperados, nos termos do art. 26, da IN SRF nº 475 de 2004.", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "CSLL, COFINS, PP, Agreg" });

    return grupo18;
  }

  carregarGrupo19(): any {
    const grupo19 = {
      codigo: null,
      nome: null,
      itens: [{
        codigo: null,
        nome: null,
        FCI: null,
        S13: null,
        RRA: null,
        ded: null,
        insencao: null,
        tributo: null,
      }]
    };
    grupo19.codigo = 19;
    grupo19.nome = "Grupo 19 - Pagamento a Beneficiário não Identificado – Uso exclusivo para o evento R-4040";
    //Itens grupo 19
    grupo19.itens.push({ codigo: 19001, nome: "Pagamento de remuneração indireta a Beneficiário não identificado", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR" });
    grupo19.itens.push({ codigo: 19009, nome: "Pagamento a Beneficiário não identificado", FCI: "N", S13: "N", RRA: "", ded: "", insencao: "", tributo: "IR" });


    return grupo19;
  }

  carregarGrupo20(): any {
    const grupo20 = {
      codigo: null,
      nome: null,
      itens: [{
        codigo: null,
        nome: null,
        FCI: null,
        S13: null,
        RRA: null,
        ded: null,
        insencao: null,
        tributo: null,
      }]
    };
    grupo20.codigo = 20;
    grupo20.nome = "Grupo 20 - Rendimentos a Pessoa Jurídica – Retenção no recebimento";
    //Itens grupo 20
    grupo20.itens.push({ codigo: 20001, nome: "Rendimento de Serviços de propaganda e publicidade", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo20.itens.push({ codigo: 20002, nome: "Importâncias a título de comissões e corretagens relativas a colocação ou negociação de títulos de renda fixa", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo20.itens.push({ codigo: 20003, nome: "Importâncias a título de comissões e corretagens relativas a operações realizadas em Bolsas de Valores e em Bolsas de Mercadorias", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo20.itens.push({ codigo: 20004, nome: "Importâncias a título de comissões e corretagens relativas a distribuição de emissão de valores mobiliários, quando a pessoa jurídica atuar como agente da companhia emissora", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo20.itens.push({ codigo: 20005, nome: "Importâncias a título de comissões e corretagens relativas a operações de câmbio", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo20.itens.push({ codigo: 20006, nome: "Importâncias a título de comissões e corretagens relativas a vendas de passagens, excursões ou viagens", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo20.itens.push({ codigo: 20007, nome: "Importâncias a título de comissões e corretagens relativas a administração de cartões de crédito", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo20.itens.push({ codigo: 20008, nome: "Importâncias a título de comissões e corretagens relativas a prestação de serviços de distribuição de refeições pelo sistema de refeições-convênio", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo20.itens.push({ codigo: 20009, nome: "Importâncias a título de comissões e corretagens relativas a prestação de serviço de administração de convênios", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });
    grupo20.itens.push({ codigo: 20010, nome: "Demais Importâncias a título de comissões, corretagens, ou qualquer outra importância paga/creditada pela representação comercial ou pela mediação na realização de negócios civis e comerciais", FCI: "N", S13: "N", RRA: "N", ded: "", insencao: "", tributo: "IR" });

    return grupo20;
  }

  carregarPorCodigo(codigo: number, apenasItem: boolean): any {
    switch (+codigo?.toString().substring(0, 2)) {
      case 10:
        if (!apenasItem) { return this.carregarGrupo10(); }
        else { return this.carregarGrupo10().itens.find(i => i.codigo === codigo); }
      case 11:
        if (!apenasItem) { return this.carregarGrupo11(); }
        else { return this.carregarGrupo11().itens.find(i => i.codigo === codigo); }
      case 12:
        if (!apenasItem) { return this.carregarGrupo12(); }
        else { return this.carregarGrupo12().itens.find(i => i.codigo === codigo); }
      case 13:
        if (!apenasItem) { return this.carregarGrupo13(); }
        else { return this.carregarGrupo13().itens.find(i => i.codigo === codigo); }
      case 14:
        if (!apenasItem) { return this.carregarGrupo14(); }
        else { return this.carregarGrupo14().itens.find(i => i.codigo === codigo); }
      case 15:
        if (!apenasItem) { return this.carregarGrupo15(); }
        else { return this.carregarGrupo15().itens.find(i => i.codigo === codigo); }
      case 16:
        if (!apenasItem) { return this.carregarGrupo16(); }
        else { return this.carregarGrupo16().itens.find(i => i.codigo === codigo); }
      case 17:
        if (!apenasItem) { return this.carregarGrupo17(); }
        else { return this.carregarGrupo17().itens.find(i => i.codigo === codigo); }
      case 18:
        if (!apenasItem) { return this.carregarGrupo18(); }
        else { return this.carregarGrupo18().itens.find(i => i.codigo === codigo); }
      case 19:
        if (!apenasItem) { return this.carregarGrupo19(); }
        else { return this.carregarGrupo19().itens.find(i => i.codigo === codigo); }
      case 20:
        if (!apenasItem) { return this.carregarGrupo20(); }
        else { return this.carregarGrupo20().itens.find(i => i.codigo === codigo); }
      default: break;
    }
  }

}

