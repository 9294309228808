import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { GlobalService } from '../../util/global.service';

declare var $: any;

@Component({
  selector: 'lib-dlg',
  templateUrl: './dlg.component.html'
})
export class DlgComponent implements OnInit, OnChanges {

  @Input() public titulo: String;
  @Input() public nome: String;
  @Input() public styleBody: String = '';
  @Input() public styleClassBody: String = '';
  @Input() public styleClassHeader: String = '';
  @Input() public modal: String = '';
  @Input() public close: boolean = true;
  @Input() public fotter: boolean = true;
  @Input() public static: boolean = false;
  @Input() public nomeAleatorio: boolean = false;

  @Input() public visualizar: boolean = false;
  @Output() public visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Output() public onHide: EventEmitter<void> = new EventEmitter();
  @Output() public onShow: EventEmitter<void> = new EventEmitter();



  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visualizar)
      this.alterVisualizar(this.visualizar);
  }

  ngOnInit() {
    if (!this.nome && !this.nomeAleatorio)
      throw new Error('Nome de dlg obrigatorio');
    if (this.nomeAleatorio)
      this.nome = new GlobalService().makeid(30);
  }

  public toogleEvent() {
    this.alterVisualizar(!this.visualizar, true);
  }

  public alterVisualizar(visualizar: boolean, emit?: boolean, validar?: boolean) {
    if (!visualizar && validar && this.static)
      return;
    this.visualizar = visualizar;
    if (visualizar)
      this.showEvent();
    else
      this.hideEvent();
    if (emit)
      this.visualizarChange.emit(visualizar);
  }


  public show() {
    this.alterVisualizar(true, true);
  }

  public hide() {
    this.alterVisualizar(false, true);
  }

  public hideEvent() {
    $(`#${this.nome}`).modal('hide');
    this.onHide.emit();
  }

  public showEvent() {
    $(`#${this.nome}`).modal('show');
    this.onShow.emit();
  }
}
