// tslint:disable: variable-name

import { BaseResourceModel } from '../../models/base-resource.model';
import { Setor } from '../almoxarifado/setor.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { Publicacao } from './publicacao.model';
import { Secao } from './secao.model';
import { SubSecao } from './subsecao.model';

export class PublicacaoEnvio extends BaseResourceModel {
    constructor(
        public id?: number,
        public nome?: string,
        public caminho?: string,
        public tipo?: string,
        public texto?: string,
        public documento?: any,
        public tamanho?: number,
        public publicacao?: Publicacao,
        public secao?: Secao,
        public subsecao?: SubSecao,
        public orgao?: Orgao,
        public usuario?: Usuario,
        public setor?: Setor,
    ) {
        super();
    }
    static converteJson(json: any): PublicacaoEnvio {
        return Object.assign(new PublicacaoEnvio(), json);
    }
}
