import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';

export class ValidacaoAudesp extends BaseResourceModel {
  constructor(
    public id?: number,
    public contas_origem?: string,
    public valor_origem?: string,
    public meses?: string,
    public valor_destino?: string,
    public arquivo?: 'BALANCETE-ISOLADO-CONTA-CORRENTE' | 'BALANCETE-ISOLADO-CONTA-CONTABIL',
    public mensagem?: string,
    public exercicio?: Exercicio) {
    super();
  }
  static converteJson(json: any): ValidacaoAudesp {
    return Object.assign(new ValidacaoAudesp(), json);
  }
}