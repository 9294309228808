import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { FuncaoGovernoService, IndicadorService, MetaAvaliacaoService, MetaGovernoService } from 'administrativo-lib';
import {
  BaseResourceFormComponent,
  EddyAutoComplete, FuncaoGoverno, FuncaoService, GlobalService,
  Indicador, LoginContabil,
  MetaAvaliacao, MetaGoverno, MetaGovernoOds, Programa, Unidade, UnidadeService
} from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { ProgramaService } from '../../programa/service/programa.service';
import { MetaGovernoOdsService } from '../service/meta-governo-ods.service';


declare var $: any;

@Component({
  selector: 'lib-meta-governo-form',
  templateUrl: './meta-governo-form.component.html'
})
export class MetaGovernoFormComponent extends BaseResourceFormComponent<MetaGoverno, LoginContabil> implements OnInit {

  /**
   * Declaração de variáveis
   */
  @ViewChild('tipo_') inputField: ElementRef;

  public listaIndicadores = new Array<Indicador>();
  public programaAutoComplete: EddyAutoComplete<Programa>;
  public unidadeAutoComplete: EddyAutoComplete<Unidade>;
  public funcaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public subFuncaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public listaTipos: Array<any>;
  public listaClassificacao: Array<any>;
  public listaAvaliacoes = new Array<MetaAvaliacao>();
  public listaOds: MetaGovernoOds[] = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected confirmationService: ConfirmationService,
    protected messageService: MessageService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected funcaoGovernoService: FuncaoGovernoService,
    protected metaGovernoOdsService: MetaGovernoOdsService,
    protected indicadorService: IndicadorService,
    protected avaliacaoService: MetaAvaliacaoService,
    protected programaService: ProgramaService,
    protected unidadeService: UnidadeService,
    protected metaService: MetaGovernoService) {
    super(new MetaGoverno(), injector, MetaGoverno.converteJson, metaService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      aux: [0],
      tipo: [null, [Validators.required]],
      especie: [null, [Validators.required]],
      classificacao: [null, [Validators.required]],
      programa: [null, [Validators.required]],
      unidade: [null, [Validators.required]],
      continuado: [false, [Validators.required]],
      aprovado: [true, [Validators.required]],
      alvo: [null, [Validators.required]],
      funcao: [null, [Validators.required]],
      subfuncao: [null, [Validators.required]],
      priorizado1: [false, [Validators.required]],
      priorizado2: [false, [Validators.required]],
      priorizado3: [false, [Validators.required]],
      priorizado4: [false, [Validators.required]],
      gerente: [null, [Validators.required]],
      objetivo: [null, [Validators.required]],
      justificativa: [null, [Validators.required]],
      ppa: [this.login.ppa, [Validators.required]],
      indicadores: [null],
      avaliacoes: [null],
      odss: [null],
      enviado_tce: [null]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'programa,funcao,subfuncao,unidade' };
  }

  protected afterInit(): void {
    this.listaTipos = this.globalService.obterListaTiposPpa();
    this.listaClassificacao = this.globalService.obterListaProgramas();
    this.carregarAutoCompletes();
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected afterLoad() {
    this.indicadorService.obterPorMeta(this.entidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaIndicadores = res.content ? res.content : new Array<Indicador>();
      });
    this.avaliacaoService.filtrar(0, -1, { meta_id: this.entidade.id }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaAvaliacoes = res.content ? res.content : new Array<MetaAvaliacao>();
      });
    this.metaGovernoOdsService.filtrar(0, -1, { 'meta_governo.id': this.entidade.id, relations : 'meta_ods.ods' })
      .subscribe((res) => {
         this.listaOds = res.content ? res.content : [];
      });
  }

  protected beforeSubmit() {
    try {
      if (!this.entidadeForm.get('tipo').value) {
        throw new Error('Informe o tipo de programa');
      }

      if (!this.entidadeForm.get('especie').value) {
        throw new Error('Informe a classificação do programa');
      } else {
        this.entidadeForm.controls['classificacao'].setValue(this.entidadeForm.get('especie').value)
      }

      if (!this.entidadeForm.get('alvo').value) {
        throw new Error('Informe o público alvo');
      }

      if (!(this.entidadeForm.get('programa').value instanceof Object)) {
        throw new Error('Informe um programa de governo válido');
      }

      if (!(this.entidadeForm.get('funcao').value instanceof Object)) {
        throw new Error('Informe uma função de governo válida');
      }

      if (!(this.entidadeForm.get('unidade').value instanceof Object)) {
        throw new Error('Informe uma unidade administrativa válida');
      }

      if (!this.entidadeForm.get('subfuncao').value) {
        throw new Error('Informe a subfunção de governo');
      }

      if (!this.entidadeForm.get('gerente').value) {
        throw new Error('Informe o gerente do programa');
      }

      if (!this.entidadeForm.get('objetivo').value) {
        throw new Error('Informe o objetivo');
      }

      if (!this.entidadeForm.get('justificativa').value) {
        throw new Error('Informe a justificativa');
      }

      this.entidadeForm.get('indicadores').setValue(this.listaIndicadores);
      this.entidadeForm.get('avaliacoes').setValue(this.listaAvaliacoes);
      this.entidadeForm.get('odss').setValue(this.listaOds);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(ent: Unidade) {
    window.scrollTo(0, 0);
    setTimeout(() => this.inputField.nativeElement.focus());
    if (this.limparTela) {
      this.entidade.ppa = this.login.ppa;
      this.entidade.tipo = 'I';
      this.entidadeForm.get('aux').setValue(0);
      this.entidadeForm.get('aprovado').setValue(true);
      this.entidadeForm.get('priorizado1').setValue(false);
      this.entidadeForm.get('priorizado2').setValue(false);
      this.entidadeForm.get('priorizado3').setValue(false);
      this.entidadeForm.get('priorizado4').setValue(false);
      this.entidadeForm.get('ppa').setValue(this.login.ppa);
      this.listaIndicadores = new Array<Indicador>();
      this.router.navigate(['/metas-governo', 'novo']);
    }
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public existePrograma() {
    if (this.entidadeForm.get('programa').value &&
      !(this.entidadeForm.get('programa').value instanceof Object)) {
      this.confirmationService.confirm({
        message: 'O programa informado não está cadastrado. Deseja cadastrá-lo?',
        acceptLabel: "Sim",
        rejectLabel: "Não",
        header: 'Cadastro',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          $('#dialogPrograma').modal('show');
        },
        reject: () => {
          this.entidadeForm.get('programa').reset();
        }
      });
    }
  }

  public updatePrograma(event?: any) {
    this.entidadeForm.get('programa').setValue(JSON.parse(JSON.stringify(event)).event);
  }
  private carregarAutoCompletes() {
    // autocomplete para programa
    this.programaAutoComplete = new EddyAutoComplete(this.entidadeForm.get('programa'), this.programaService,
      'id', ['codigo', 'nome'], { ppa_id: this.login.ppa.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para unidade
    this.unidadeAutoComplete = new EddyAutoComplete(this.entidadeForm.get('unidade'), this.unidadeService,
      'id', ['codigo', 'nome'], { ppa_id: this.login.ppa.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para funcao governo
    this.funcaoAutoComplete = new EddyAutoComplete(null, this.funcaoGovernoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para subfuncao governo
    this.subFuncaoAutoComplete = new EddyAutoComplete(null, this.funcaoGovernoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/planejamento']);
        break;
      default:
        this.sair();
        break;
    }
  }
}
