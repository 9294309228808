import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { BaseResourceRptComponent, Coluna, FavorecidoService } from "eddydata-lib";
import { MenuItem } from "primeng/api";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'lib-favorecido-rpt',
  templateUrl: './favorecido-rpt.component.html'
})
export class favorecidoRptComponent extends BaseResourceRptComponent {

  public ordernacao: { nome: string, coluna: string, ordem: 'DESC' | 'ASC' };
  public listaOrdenacao: { nome: string, coluna: string, ordem: 'DESC' | 'ASC' }[] = [];

  public listaFiltros: Coluna[] = [];
  public menuFiltros: MenuItem[] = [];

  protected datepipe: DatePipe;

  constructor(
    private favorecidoService: FavorecidoService
  ) {
    super();
  }

  protected afterInit(): void {
    this.datepipe = new DatePipe('pt');

    this.orientacao = "landscape";
    this.listaOrdenacao = this.obterColunasOrdenacoes();
    this.ordernacao = this.listaOrdenacao[0];
    this.loadMenuFiltros();
  }

  private loadMenuFiltros() {
    this.menuFiltros = this.obterColunasRelatorio().map((l): MenuItem => {
      if (!l['filtro1'])
        l['filtro1'] = null;
      if (!l['filtro2'])
        l['filtro2'] = null;
      return {
        label: l.titulo,
        icon: 'fa fa-plus',
        visible: !this.filtroAdicionado(l),
        command: (event) => {
          this.adicionarFiltro(l);
        }
      };
    });
  }

  private adicionarFiltro(coluna: Coluna) {
    if (!this.filtroAdicionado(coluna))
      this.listaFiltros.push(coluna);
    this.loadMenuFiltros();
  }

  public removerFiltro(coluna: Coluna) {
    let index = this.listaFiltros.indexOf(coluna);
    if (index || index === 0)
      this.listaFiltros.splice(index, 1);
    this.loadMenuFiltros();
  }

  public filtroAdicionado(coluna: Coluna): boolean {
    let filtro = this.listaFiltros.find((f) => f.coluna === coluna.coluna)
    return !(!filtro)
  }

  protected tituloRelatorio(): string {
    return `Fornecedores`;
  }

  public obterColunasOrdenacoes(): { nome: string, coluna: string, ordem: 'DESC' | 'ASC' }[] {
    return [
      { coluna: 'id', nome: 'Código', ordem: 'ASC' },
      { coluna: 'cpf_cnpj', nome: 'CPF/CNPJ', ordem: 'ASC' },
      { coluna: 'nome', nome: 'Razão Social', ordem: 'ASC' },
      { coluna: 'nome_fantasia', nome: 'Nome Fantasia', ordem: 'ASC' },
    ];
  }

  protected obterColunasRelatorio(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Código', coluna: 'id', alignment: 'center', cols: 12, tipo: 'Number' });
    retorno.push({ titulo: 'CPF/CNPJ', coluna: 'cpf_cnpj', alignment: 'center', cols: 8, tipo: 'String' });
    retorno.push({ titulo: 'Razão Social', coluna: 'nome', tipo: 'String', });
    retorno.push({ titulo: 'Nome Fantasia', coluna: 'nome_fantasia', tipo: 'String' });
    return retorno;
  }

  protected larguraColunas(): (string | number)[] {
    return ['auto', 'auto', '*', '*'];
  }

  protected totalizarColunas(): (string | {})[] {
    return undefined;
  }


  private obterFiltrosLista(): {} {
    let filtros: {} = {};
    for (let filtro of this.listaFiltros) {
      let convert = this.converterFiltro(filtro);
      if (convert)
        if (!convert['length']) {
          filtros[convert['chave']] = convert['valor'];
        } else {
          for (let fs of <[]>convert) {
            filtros[fs['chave']] = fs['valor'];
          }
        }
    }
    return filtros;
  }

  private converterFiltro(coluna: Coluna): { chave: string, valor: string } | { chave: string, valor: string }[] {
    if (coluna.tipo === 'Number' && coluna['filtro1']) {
      if (coluna['filtro1'] && coluna['filtro2']) {
        let filtro_data = [];
        if (coluna['filtro1']) {
          filtro_data.push({ chave: `${coluna.coluna}$ge`, valor: coluna['filtro1'] });
        }
        if (coluna['filtro2']) {
          filtro_data.push({ chave: `${coluna.coluna}$le`, valor: coluna['filtro2'] });
        }
        return filtro_data;
      } else {
        return { chave: coluna.coluna, valor: coluna['filtro1'] };
      }

    }

    if (coluna.tipo === 'String' && coluna['filtro1'])
      return { chave: `${coluna.coluna}$like`, valor: `%${coluna['filtro1']}%` };
    if (coluna.tipo === 'Date') {
      let filtro_data = [];
      if (coluna['filtro1'])
        filtro_data.push({ chave: `${coluna.coluna}$ge`, valor: this.datepipe.transform(coluna['filtro1'], 'yyyy-MM-dd') });
      if (coluna['filtro2'])
        filtro_data.push({ chave: `${coluna.coluna}$le`, valor: this.datepipe.transform(coluna['filtro2'], 'yyyy-MM-dd') });
      return filtro_data;
    }
    return null;
  }

  protected carregarLista(): Promise<any[]> {
    let filtros = this.obterFiltrosLista();
    let parametros = {
      'orderBy': `${this.ordernacao.coluna}\$${this.ordernacao.ordem}`
    };
    return new Promise((resolve) => {
      this.favorecidoService.filtrar(1, -1, filtros ? Object.assign(parametros, filtros) : parametros).pipe(takeUntil(this.unsubscribe))
        .subscribe(async (res) => {
          let lista = res.content;
          resolve(lista);
        });
    })
  }

  protected layout(): {} {
    return {
      hLineWidth() {
        return 1;
      },
      vLineWidth() {
        return 1;
      },
      hLineColor(i) {
        return i === 1 || i === 0 ? 'black' : '#aaa';
      },
      paddingLeft(i) {
        return i === 0 ? 0 : 3;
      },
      paddingRight(i, node) {
        return (i === node.table.widths.length - 1) ? 0 : 3;
      }
    }
  }



  public ngClassCols(cols: number, padrao: string): {} {
    let ngClass = {};
    if (cols)
      ngClass[`col-sm-${cols}`] = true;
    else
      ngClass[padrao] = true;
    return ngClass;
  }

}