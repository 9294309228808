import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { ProdutoLocalizacaoFormDlgComponent } from './produto-localizacao-form-dlg/produto-localizacao-form-dlg.component';
import { ProdutoLocalizacaoFormComponent } from './produto-localizacao-form/produto-localizacao-form.component';
import { ProdutoLocalizacaoListComponent } from './produto-localizacao-list/produto-localizacao-list.component';
import { ProdutoLocalizacaoRoutingModule } from './produto-localizacao-routing.module';
import { ProdutoDlgModule } from 'almoxarifado-lib';
import { ProdutoLocalizacaoViewComponent } from './produto-localizacao-view/produto-localizacao-view.component';



@NgModule({
  declarations: [
    ProdutoLocalizacaoListComponent,
    ProdutoLocalizacaoFormComponent,
    ProdutoLocalizacaoViewComponent,
    ProdutoLocalizacaoFormDlgComponent
  ],
  exports : [
    ProdutoLocalizacaoFormDlgComponent
  ],
  imports: [
    CommonModule,
    ProdutoLocalizacaoRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    ProdutoDlgModule
  ],
})
export class ProdutoLocalizacaoModule { }
