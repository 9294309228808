import { Component } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { Coluna } from "../../components/types";
import { Acesso } from "../../entidade/comum/acesso.model";
import { Usuario } from "../../entidade/comum/usuario.model";
import { BaseResourceRptComponent } from "../../models/base-resource-rpt";
// import * as toastr from 'toastr';
import { AcessoService } from "../service/acesso.service";
import { UsuarioService } from "../service/usuario.service";

@Component({
  selector: 'app-usuario-acesso-rpt-dlg',
  templateUrl: './usuario-acesso-rpt-dlg.component.html'
})
export class UsuarioAcessoRptDlgComponent extends BaseResourceRptComponent {

  public paginas: { sistema: string, nome: string, pagina: string }[]

  // filtros
  public usuario: Usuario;
  public listaUsuario: Usuario[];
  public sistema: string;
  public listaSistema: string[];
  public pagina: string;
  public listaPagina: string[];

  public ordenacao: 'usuario' | 'sistema' | 'nome';
  public listaOrdenacao: { id: 'usuario' | 'sistema' | 'nome', nome: string }[];

  public agrupamento: 'nenhum' | 'usuario' | 'sistema' | 'nome';
  public listaAgrupamento: { id: 'nenhum' | 'usuario' | 'sistema' | 'nome', nome: string }[];

  constructor(
    private acessoService: AcessoService,
    private usuarioService: UsuarioService,
  ) {
    super();
  }

  protected afterInit(): void {
    this.paginas = this.acessoService.retornaPaginasPorSistema();

    this.listaSistema = [...new Set(this.paginas.map(p => p.sistema))].sort((a, b) => { return a.localeCompare(b) });
    this.listaPagina = this.paginas.map(p => p.nome).sort((a, b) => { return a.localeCompare(b) });

    this.listaOrdenacao = [
      { id: 'usuario', nome: 'Usuário' },
      { id: 'sistema', nome: 'Sistema' },
      { id: 'nome', nome: 'Página' },
    ]
    this.ordenacao = 'usuario';

    this.listaAgrupamento = [
      { id: 'nenhum', nome: 'Nenhum' },
      { id: 'usuario', nome: 'Usuário' },
      { id: 'sistema', nome: 'Sistema' },
      { id: 'nome', nome: 'Página' },
    ]
    this.agrupamento = 'nenhum';

    this.usuarioService.filtrar(1, -1, {
      orgao_id: this.login.orgao.id, orderBy: 'nome'
    }).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      this.listaUsuario = res ? res.content : [];
    }, error => this.funcaoService.acaoErro(error));
  }

  protected tituloRelatorio(): string {
    return `${this.login.orgao.nome} - Controle de Permissões`;
  }

  protected subTituloRelatorio(): string {
    return '';
  }

  protected obterColunasRelatorio(): Coluna[] {
    const retorno: Coluna[] = [];

    retorno.push({ titulo: 'Usuário', coluna: 'usuario', agrupar: this.agrupamento === 'usuario', bold: this.agrupamento === 'usuario' });
    retorno.push({ titulo: 'Sistema', coluna: 'sistema', agrupar: this.agrupamento === 'sistema', bold: this.agrupamento === 'sistema' });
    retorno.push({ titulo: 'Página', coluna: 'nome', agrupar: this.agrupamento === 'nome', bold: this.agrupamento === 'nome' });
    retorno.push({ titulo: 'URL', coluna: 'pagina', agrupar: false });
    retorno.push({ titulo: 'Permissão', coluna: 'permissao', agrupar: false });

    return retorno.sort((a, b) => { return a.agrupar === b.agrupar ? 0 : a.agrupar ? -1 : 1 });
  }

  protected larguraColunas(): (string | number)[] {
    if (this.agrupamento !== 'nenhum')
      return ['auto', '*', 'auto', 'auto'];
    else
      return ['auto', 'auto', '*', 'auto', 'auto'];
  }

  protected totalizarColunas(): (string | {})[] {
    return null
  }

  protected carregarLista(): Promise<any[]> {
    const parametros = {}
    parametros['relations'] = 'usuario';

    // filtros
    if (this.usuario?.id)
      parametros['usuario.id'] = this.usuario.id
    if (this.sistema && this.sistema !== 'Todos' && (!this.pagina || this.pagina === 'Todas'))
      parametros['pagina$in'] = this.paginas.filter(p => p.sistema === this.sistema).map(p => p.pagina).join(',')
    if (this.pagina && this.pagina !== 'Todas')
      parametros['pagina'] = this.paginas.find(p => p.nome === this.pagina).pagina

    const lista: { usuario: string, sistema: string, nome: string, pagina: string, permissao: string }[] = []
    return new Promise((resolve) => {
      this.acessoService.filtrar(0, 0, parametros).pipe(takeUntil(this.unsubscribe)).subscribe(async (res) => {
        if (res?.content?.length > 0) {
          for (const acesso of res.content as Acesso[]) {
            const pag = this.paginas.find(p => p.pagina === acesso.pagina);
            lista.push({
              usuario: `${acesso.usuario.nome} ${acesso.usuario.sobrenome}`,
              sistema: pag ? pag.sistema : '',
              nome: pag ? pag.nome : acesso.pagina,
              pagina: acesso.pagina,
              permissao: acesso.usuario.administrador ? 'Administrador' :
                acesso.permissao === 0 ? 'Sem acesso' :
                  acesso.permissao === 1 ? 'Visualizar' :
                    acesso.permissao === 2 ? 'Incluir/Alterar' :
                      String(acesso.permissao)
            })
          }
        }

        // ordenacao
        if (this.ordenacao === 'usuario')
          lista
            .sort((a, b) => { return a.nome.localeCompare(b.nome) })
            .sort((a, b) => { return a.sistema.localeCompare(b.sistema) })
            .sort((a, b) => { return a.usuario.localeCompare(b.usuario) })
        else if (this.ordenacao === 'sistema')
          lista
            .sort((a, b) => { return a.usuario.localeCompare(b.usuario) })
            .sort((a, b) => { return a.nome.localeCompare(b.nome) })
            .sort((a, b) => { return a.sistema.localeCompare(b.sistema) })
        else if (this.ordenacao === 'nome')
          lista
            .sort((a, b) => { return a.usuario.localeCompare(b.usuario) })
            .sort((a, b) => { return a.nome.localeCompare(b.nome) })

        if (this.agrupamento !== 'nenhum')
          lista.sort((a, b) => { return a[this.agrupamento].localeCompare(b[this.agrupamento]) })

        resolve(lista.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.usuario === value.usuario && t.pagina === value.pagina
          ))
        ))
      });
    })
  }

}