import { Injectable, Injector } from "@angular/core";
import { BaseResourceService, TombamentoEmpenhoExtra } from "eddydata-lib";

@Injectable({
    providedIn: 'root'
})
export class TombamentoEmpenhoExtraService extends BaseResourceService<TombamentoEmpenhoExtra> {
    constructor(
        protected injector: Injector
    ) {
        super(`tombamento-empenhos-extra`, injector)
    }
}