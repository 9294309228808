import { Component, Input } from '@angular/core';
import { Audesp4LoteCompostoItensSim, Audesp4OrcamentoItemSim } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-lote-item-orc',
  templateUrl: './licitacao-audesp-lote-item-orc.component.html'
})
export class LicitacaoAudespLoteItemOrcComponent extends BaseLicitacaoAudesp {

  @Input() public item: Audesp4LoteCompostoItensSim;

  public itemAtual: Audesp4OrcamentoItemSim;
  public itemAnterior: Audesp4OrcamentoItemSim;

  constructor() {
    super()
  }

  public adicionar() {
    if (!this.item.OrcamentoItemSim || this.item.OrcamentoItemSim.length === 0) this.item.OrcamentoItemSim = [];

    this.itemAtual = {
      ValorUnitario: 0, Quantidade: 0, UnidadeMedida: '', DtOrcamento: undefined, tipoDoc: 'CPF', editavel: true
    }
    this.item.OrcamentoItemSim.push(this.itemAtual);
  }

  public visualizar(item: Audesp4OrcamentoItemSim) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      let itm = this.item.OrcamentoItemSim.find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.item.OrcamentoItemSim.splice(this.item.OrcamentoItemSim.indexOf(this.itemAtual), 1, itm)
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAtual.editavel = false;
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4OrcamentoItemSim) {
    this.itemAtual = undefined;
    this.item.OrcamentoItemSim.splice(this.item.OrcamentoItemSim.indexOf(item), 1)
  }

  public salvar() {
    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }

}
