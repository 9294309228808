import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FuncaoService, Exercicio, FormatoExportacao } from 'eddydata-lib';

@Directive()
export class Anexo12ControleRecurso implements OnDestroy {

  protected funcaoService: FuncaoService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number,
    protected exercicio: Exercicio) {
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(dados: any[], formato?: FormatoExportacao): {}[] {
    if (formato && formato !== 'pdf') return this.montarQuadroExportacao(dados);

    const registros: {}[] = [
      [
        {
          text: 'CONTROLE DAS DESPESAS CUSTEADAS COM RECURSOS VINCULADOS À PARCELA DO PERCENTUAL MÍNIMO QUE NÃO FOI APLICADA EM AÇÕES E SERVIÇOS DE SAÚDE EM EXERCÍCIOS ANTERIORES',
          alignment: 'center', rowSpan: 2, bold: true, fontSize: 7, border: [true, false, true, true]
        },
        { text: 'DIFERENÇA DE LIMITE NÃO CUMPRIDO', alignment: 'center', colSpan: 2, bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: '', border: [true, false, true, true] }
      ],
      ['',
        { text: 'Não Compensado (j)', alignment: 'center', bold: true, fontSize: 7 },
        { text: '(l)', alignment: 'center', bold: true, fontSize: 7 }
      ]
    ];

    let total1 = 0;
    let total2 = 0;

    for (const item of dados) {
      registros.push([
        {
          text: item.grupo, fontSize: 7
        },
        { text: this.funcaoService.convertToBrNumber(item.valor1), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(item.valor2), alignment: 'right', fontSize: 7 }
      ]);

      total1 += +item.valor1;
      total2 += +item.valor2;
    }

    registros.push([
      {
        text: 'SALDO DOS RECURSOS VINCULADOS À PARCELA NÃO CUMPRIDA EM EXERCÍCIOS ANTERIORES NÃO COMPENSADOS', fontSize: 7, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 7, bold: true },
    ]);

    return registros;
  }

  private montarQuadroExportacao(dados: any[]): {}[] {
    const listaItens = new Array();

    listaItens.push({
      'receita_desc': 'CONTROLE DAS DESPESAS CUSTEADAS COM RECURSOS VINCULADOS À PARCELA DO PERCENTUAL MÍNIMO QUE NÃO FOI APLICADA EM AÇÕES E SERVIÇOS DE SAÚDE EM EXERCÍCIOS ANTERIORES',
      'inicial': 'DIFERENÇA DE LIMITE NÃO CUMPRIDO',
      'atualizada': '',
      'arrecadado_empenhada': '',
      'calculo_empenhada': '',
      'liquidada': '',
      'calculo_liquidada': '',
    });

    listaItens.push({
      'receita_desc': '',
      'inicial': 'Não Compensado (j)',
      'atualizada': '(j)',
      'arrecadado_empenhada': '',
      'calculo_empenhada': '',
      'liquidada': '',
      'calculo_liquidada': '',
    });

    let total1 = 0;
    let total2 = 0;

    for (const item of dados) {
      listaItens.push({
        'receita_desc': item.grupo,
        'inicial': this.funcaoService.convertToBrNumber(item.valor1, 2),
        'atualizada': this.funcaoService.convertToBrNumber(item.valor2, 2),
        'arrecadado_empenhada': '',
        'calculo_empenhada': '',
        'liquidada': '',
        'calculo_liquidada': '',
      });

      total1 += +item.valor1;
      total2 += +item.valor2;
    }

    listaItens.push({
      'receita_desc': 'SALDO DOS RECURSOS VINCULADOS À PARCELA NÃO CUMPRIDA EM EXERCÍCIOS ANTERIORES NÃO COMPENSADOS',
      'inicial': this.funcaoService.convertToBrNumber(total1, 2),
      'atualizada': this.funcaoService.convertToBrNumber(total2, 2),
      'arrecadado_empenhada': '',
      'calculo_empenhada': '',
      'liquidada': '',
      'calculo_liquidada': '',
    });

    listaItens.push({
      'receita_desc': ''
    });

    return listaItens;
  }

}
