import { Component, Injector, ElementRef } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BaseResourceFormComponent, TransferenciaBancaria,
  LoginContabil, GlobalService, DateFormatPipe
} from 'eddydata-lib';
import { TransferenciaBancariaService } from 'administrativo-lib';

@Component({
  selector: 'app-transferencia-bancaria-view',
  templateUrl: './transferencia-bancaria-view.component.html'
})
export class TransferenciaBancariaViewComponent extends BaseResourceFormComponent<TransferenciaBancaria, LoginContabil> {

  /**
   * Declaração de variáveis
   */


  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected transferenciaService: TransferenciaBancariaService) {
    super(new TransferenciaBancaria(), injector, TransferenciaBancaria.converteJson, transferenciaService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      data_transfere: [null, [Validators.required]],
      data_referencia: [null, [Validators.required]],
      valor_transferido: 0,
      mes: [null, [Validators.required]],
      especie: [null, [Validators.required]],
      documento: [null, [Validators.required]],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      tipo: [null, [Validators.required]],
      exercicio: this.fb.group({
        ano: [null, [Validators.required]],
      }),
      orgao: [null, [Validators.required]],
      conta_pagadora: this.fb.group({
        agencia: [null, [Validators.required]],
        conta_numero: [null, [Validators.required]],
      }),
      conta_recebedora: this.fb.group({
        agencia: [null, [Validators.required]],
        conta_numero: [null, [Validators.required]],
      }),
      recurso_pagadora: [null],
      aplicacao_pagadora: [null],
      convenio_pagadora: [null],
      recurso_recebedora: [null],
      aplicacao_recebedora: [null],
      convenio_recebedora: [null],
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'conta_pagadora,conta_pagadora.banco,especie,transferencia_retencao_item,transferencia_retencao_item.retencao,transferencia_retencao_item.retencao_extra,transferencia_retencao_item.retencao_resto,' +
        'conta_pagadora.orgao,conta_recebedora,conta_recebedora.orgao,conta_recebedora.banco,conta_recebedora.entidade,conta_pagadora.entidade,recurso_pagadora,convenio_pagadora,aplicacao_pagadora' +
        ',recurso_recebedora,convenio_recebedora,aplicacao_recebedora,orgao.cidade'
    };
  }

  protected afterInit(): void {

  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected afterLoad() {
    this.entidade.data_transfere = new DateFormatPipe().transform(this.entidade.data_transfere, []);
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: TransferenciaBancaria): void {
  }


  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================


}
