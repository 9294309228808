import { Component, Input, OnInit } from '@angular/core';
import { Audesp4ComissaoLicitacao, Audesp4MembroLicitacao, EddyAutoComplete, FuncaoService, Pessoa, PessoaService } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-comissao-membro',
  templateUrl: './licitacao-audesp-comissao-membro.component.html'
})
export class LicitacaoAudespComissaoMembroComponent extends BaseLicitacaoAudesp implements OnInit {

  @Input() public comissao: Audesp4ComissaoLicitacao;

  public itemAtual: Audesp4MembroLicitacao;
  public itemAnterior: Audesp4MembroLicitacao;

  public opcoesAtribuicao: { id: number, nome: string }[];
  public opcoesNatureza: { id: number, nome: string }[];

  constructor(
    public funcaoService: FuncaoService,
    private pessoaService: PessoaService,
  ) {
    super()
  }

  ngOnInit(): void {
    this.opcoesAtribuicao = [
      { id: 1, nome: 'Presidente' },
      { id: 7, nome: 'Secretario' },
      { id: 6, nome: 'Leiloeiro' },
      { id: 5, nome: 'Servidor Designado' },
      { id: 4, nome: 'Equipe de Apoio' },
      { id: 3, nome: 'Pregoeiro' },
      { id: 2, nome: 'Membro' },
      { id: 8, nome: 'Autoridade Pregão' },
      { id: 9, nome: 'Responsável' },
      { id: 10, nome: 'Autoridade Convite' },
      { id: 11, nome: 'Agente de Contratação' },
      { id: 12, nome: 'Comissão de Contratação' },
    ];
    this.opcoesNatureza = [
      { id: 1, nome: 'Efetivo' },
      { id: 2, nome: 'Comissionado' },
      { id: 6, nome: 'Outros' },
      { id: 3, nome: 'Agente Político' },
      { id: 4, nome: 'Empregado Temporário' },
      { id: 5, nome: 'Empregado Público' },
    ];
    this.pessoaAutoComplete = new EddyAutoComplete(null, this.pessoaService,
      'id', ['cpf_cnpj', 'nome'], { orderBy: 'nome' }, { number: ['cpf_cnpj'], text: ['nome'] }
    );
  }

  public adicionar() {
    if (!this.comissao.membros || this.comissao.membros.length === 0) this.comissao.membros = [];

    this.itemAtual = {
      CPFIntegrante: undefined, NomeIntegrante: undefined, AtribuicaoIntegrante: undefined,
      CargoOcupadoIntegrante: undefined, NaturezaCargoOcupado: undefined, editavel: true
    }
    this.itemAtual['pessoa'] = new Pessoa();
    this.comissao.membros.push(this.itemAtual);
  }

  public visualizar(item: Audesp4MembroLicitacao) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
    if (this.itemAtual.pessoaId)
      this.pessoaService.obterId(this.itemAtual.pessoaId).subscribe((res) => {
        this.itemAtual['pessoa'] = res;
      })
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      delete this.itemAtual['pessoa'];
      let itm = this.comissao.membros.find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.comissao.membros.splice(this.comissao.membros.indexOf(this.itemAtual), 1, itm)
      this.itemAtual.editavel = false;
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4MembroLicitacao) {
    this.itemAtual = undefined;
    this.comissao.membros.splice(this.comissao.membros.indexOf(item), 1)
  }

  public salvar() {
    delete this.itemAtual['pessoa'];
    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }


  public cadastroPessoa() {
    $('#dialogPessoa').modal('show');
  }

  public callbackPessoa(pessoa: Pessoa) {
    if (pessoa)
      this.itemAtual['pessoa'] = pessoa;
    else
      pessoa = this.itemAtual['pessoa']

    this.itemAtual.pessoaId = pessoa.id;
    this.itemAtual.CPFIntegrante = pessoa.cpf_cnpj;
    this.itemAtual.NomeIntegrante = pessoa.nome;
  }
}
