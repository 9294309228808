import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuncaoService, LoginContabil, Exercicio, Relatorio, GlobalService, OrgaoAssinaturaService, ProgressoService, Orgao, FormatoExportacao } from 'eddydata-lib';
import { BalancoService } from '../service/balanco.service';
import { NotaExplicativaService } from '../service/nota-explicativa.service';
import { BaseResourceNotaExplicativa } from '../base-resource-nota-explicativa';
import { Anexo18Dfc } from './anexo-18.dfc';
import { Anexo18A } from './anexo-18.A';
import { Anexo18B } from './anexo-18.B';
import { Anexo18C } from './anexo-18.C';
import { Anexo18D } from './anexo-18.D';

@Injectable({
  providedIn: 'root'
})
export class Anexo18Balanco extends BaseResourceNotaExplicativa implements OnDestroy {

  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();
  protected globalService: GlobalService;

  constructor(
    protected anexoServico: BalancoService,
    protected notaService: NotaExplicativaService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected progressoService: ProgressoService,
  ) {
    super('B18', notaService, assinaturaService)
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // tslint:disable: max-line-length
  public montarRelatorio(orgaos: number[], exercicio: Exercicio, sintetico: boolean, listaOrgaos?: Orgao[], mes?: number, formato?: FormatoExportacao) {
    this.anexoServico.obterFluxoCaixa(exercicio.id, orgaos, mes)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async idTransacao => {
        this.progressoService.show(idTransacao, async (dados) => {
          formato = formato ?? 'pdf';
          const orgao = orgaos.length === 1 ? listaOrgaos.filter(o => o.id === orgaos[0])[0] : listaOrgaos.filter(o => o.id === 1)[0];
          const consolidado = orgaos.length === 1 ? orgao.nome : 'Consolidado';
          let orgaoNomes: string[] = [];
          for (const o of listaOrgaos) {
            if (orgaos.some(orgao => orgao === o.id)) {
              orgaoNomes.push(`${o.codigo} - ${o.nome}`);
            }
          }
          if (formato === 'pdf') {
            Relatorio.imprimirPersonalizado(null,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
              this.conteudo(dados, exercicio, mes, orgao).concat(await this.conteudoNotaExplicativa()).concat(await this.conteudoAssinatura(this.login.orgao, orgaoNomes)),
              'portrait', 'ANEXO 18 - DEMONSTRAÇÃO DOS FLUXOS DE CAIXA',
              {
                linhas: {
                  hLineWidth() {
                    return 1;
                  },
                  vLineWidth() {
                    return 1;
                  },
                  hLineColor() {
                    return 'black';
                  },
                  paddingLeft() {
                    return 3;
                  },
                  paddingRight() {
                    return 3;
                  }
                }
              }, false, false, 'pdf', `Unidade Gestora: ${consolidado.toUpperCase()}`);
          } else if (formato === 'csv') {
            this.conteudoCSV(dados);
          }
        });
      });
  }

  private cabecalhoRelatorio(orgao: Orgao, exercicio: Exercicio, mes?: number): {} {
    const registros = [
      [
        { text: `Município:`, alignment: 'left', fontSize: 8 },
        { text: orgao?.cidade?.nome ? orgao?.cidade?.nome : this.login.cidade.nome, alignment: 'center', fontSize: 8 },
        { text: `Exercício:`, alignment: 'left', fontSize: 8 },
        { text: exercicio.ano, alignment: 'center', fontSize: 8 }
      ], [
        { text: `Poder:`, alignment: 'left', fontSize: 8 },
        { text: orgao?.especie === 'C' ? 'PODER LEGISLATIVO' : 'PODER EXECUTIVO', alignment: 'center', fontSize: 8 },
        { text: `Mês:`, alignment: 'left', fontSize: 8 },
        { text: mes ? this.globalService.obterMes(+mes) : 'ANUAL', alignment: 'center', fontSize: 8 }
      ], [
        { text: `Órgão:`, alignment: 'left', fontSize: 8, border: [true, true, true, false] },
        { text: orgao?.nome, alignment: 'center', fontSize: 8, border: [true, true, true, false] },
        { text: `Acumulado/Mensal:`, alignment: 'left', fontSize: 8, border: [true, true, true, false] },
        { text: mes ? 'MENSAL' : 'ACUMULADO', alignment: 'center', fontSize: 8, border: [true, true, true, false] }
      ], [
        { text: 'Em R$ 1,00', bold: true, alignment: 'right', colSpan: 4, border: [false, true, false, false] },
        '',
        '',
        '',
      ]
    ];

    return {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*', '*', '*', '*'],
        body: registros
      }
    };
  }

  private conteudo(dados: any, exercicio: Exercicio, mes: number, orgao: Orgao): {}[] {
    // ANEXO 18 - DEMONSTRAÇÃO DOS FLUXOS DE CAIXA
    let listaDfc: {}[] = [];
    const anexo18Dfc = new Anexo18Dfc(this.anexoServico, this.notaService, this.assinaturaService);
    listaDfc = anexo18Dfc.montarRelatorio(dados, exercicio);

    // ANEXO 18A - QUADRO DE RECEITAS DERIVADAS E ORIGINÁRIAS
    let listaA: {}[] = [];
    const anexo18A = new Anexo18A(this.anexoServico, this.notaService, this.assinaturaService);
    listaA = anexo18A.montarRelatorio(dados, exercicio);

    // ANEXO 18B - QUADRO DE TRANSFERÊNCIAS RECEBIDAS E CONCEDIDAS
    let listaB: {}[] = [];
    const anexo18B = new Anexo18B(this.anexoServico, this.notaService, this.assinaturaService);
    listaB = anexo18B.montarRelatorio(dados, exercicio);

    // ANEXO 18C - QUADRO DE DESEMBOLSOS DE PESSOAL E DEMAIS DESPESAS POR FUNÇÃO
    let listaC: {}[] = [];
    const anexo18C = new Anexo18C(this.anexoServico, this.notaService, this.assinaturaService);
    listaC = anexo18C.montarRelatorio(dados, exercicio);

    // ANEXO 18D - QUADRO DE JUROS E ENCARGOS DA DÍVIDA
    let listaD: {}[] = [];
    const anexo18D = new Anexo18D(this.anexoServico, this.notaService, this.assinaturaService);
    listaD = anexo18D.montarRelatorio(dados, exercicio);

    return [
      this.cabecalhoRelatorio(orgao, exercicio, mes),
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 3,
          widths: ['*', 100, 100],
          body: listaDfc
        }, pageBreak: 'after'
      },
      this.cabecalhoRelatorio(orgao, exercicio, mes),
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*', 100, 100],
          body: listaA
        }, pageBreak: 'after'
      },
      this.cabecalhoRelatorio(orgao, exercicio, mes),
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*', 100, 100],
          body: listaB
        }, pageBreak: 'after'
      },
      this.cabecalhoRelatorio(orgao, exercicio, mes),
      , {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*', 100, 100],
          body: listaC
        }, pageBreak: 'after'
      },
      this.cabecalhoRelatorio(orgao, exercicio, mes),
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*', 100, 100],
          body: listaD
        }
      }];
  }

  private conteudoCSV(dados: any) {
    // ANEXO 18 - DEMONSTRAÇÃO DOS FLUXOS DE CAIXA
    let listaDfc: {}[] = [];
    const anexo18Dfc = new Anexo18Dfc(this.anexoServico, this.notaService, this.assinaturaService);
    listaDfc = anexo18Dfc.montarRelatorioCSV(dados);

    // ANEXO 18A - QUADRO DE RECEITAS DERIVADAS E ORIGINÁRIAS
    let listaA: {}[] = [];
    const anexo18A = new Anexo18A(this.anexoServico, this.notaService, this.assinaturaService);
    listaA = anexo18A.montarRelatorioCSV(dados);

    // ANEXO 18B - QUADRO DE TRANSFERÊNCIAS RECEBIDAS E CONCEDIDAS
    let listaB: {}[] = [];
    const anexo18B = new Anexo18B(this.anexoServico, this.notaService, this.assinaturaService);
    listaB = anexo18B.montarRelatorioCSV(dados);

    // ANEXO 18C - QUADRO DE DESEMBOLSOS DE PESSOAL E DEMAIS DESPESAS POR FUNÇÃO
    let listaC: {}[] = [];
    const anexo18C = new Anexo18C(this.anexoServico, this.notaService, this.assinaturaService);
    listaC = anexo18C.montarRelatorioCSV(dados);

    // ANEXO 18D - QUADRO DE JUROS E ENCARGOS DA DÍVIDA
    let listaD: {}[] = [];
    const anexo18D = new Anexo18D(this.anexoServico, this.notaService, this.assinaturaService);
    listaD = anexo18D.montarRelatorioCSV(dados);

    let registros = [];
    registros.push(...listaDfc, ...listaA, ...listaB, ...listaC, ...listaD);

    dados = null;
    let csv = '';
    for (let i=0; i<registros.length; i++) {
      const linha = registros[i];
      if (i > 0) csv += '\n';
      for (let x=0; x<linha.length; x++) {
        if (x > 0) csv += ';';
        csv += String(linha[x]['text']);
      }
    }
    registros = null;

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + csv));
    element.setAttribute("download", `ANEXO 18 - DEMONSTRAÇÃO DOS FLUXOS DE CAIXA.csv`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

}
