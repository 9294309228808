import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GrupoServicoListComponent } from './grupo-servico-list/grupo-servico-list.component';
import { GrupoServicoFormComponent } from './grupo-servico-form/grupo-servico-form.component';
import { GrupoServicoViewComponent } from './grupo-servico-view/grupo-servico-view.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: GrupoServicoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: GrupoServicoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: GrupoServicoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: GrupoServicoViewComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GrupoSevicoRoutingModule { }
