import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { LoginModule, SharedModule } from 'eddydata-lib';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { TransparenciaLoginRecuperarSenhaSucessoComponent } from '../transparencia-login-recuperar-senha-sucesso/transparencia-login-recuperar-senha-sucesso.component';
import { TransparenciaLoginRecuperarSenhaComponent } from '../transparencia-login-recuperar-senha/transparencia-login-recuperar-senha.component';
import { TransparenciaLoginComponent } from './transparencia-login.component';

@NgModule({
  declarations: [TransparenciaLoginComponent, TransparenciaLoginRecuperarSenhaComponent, TransparenciaLoginRecuperarSenhaSucessoComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    IMaskModule,
    SelectButtonModule,
    ToastModule,
    LoginModule
  ]
})
export class TransparenciaLoginModule { }
