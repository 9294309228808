import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { SeguradoraFormComponent } from './seguradora-form/seguradora-form.component';
import { SeguradoraListComponent } from './seguradora-list/seguradora-list.component';
import { SeguradoraRoutingModule } from './seguradora-routing.module';
import { SeguradoraViewComponent } from './seguradora-view/seguradora-view.component';
import { InputMaskModule } from 'primeng/inputmask';
import { PessoaModule, SharedModule, UsuarioModule } from 'eddydata-lib';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { FieldsetModule } from 'primeng/fieldset';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
  declarations: [
    SeguradoraListComponent,
    SeguradoraFormComponent,
    SeguradoraViewComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    SeguradoraRoutingModule,
    PessoaModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    UsuarioModule,
    InputMaskModule,
    EditorModule,
    FileUploadModule,
    FieldsetModule,
    ConfirmDialogModule,
  ],
  providers: [MessageService, ConfirmationService]
})
export class SeguradoraModule { }
