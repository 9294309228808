import {
    Audesp4Artigo16, Audesp4Artigo17, Audesp4AtestadoDesempenho, Audesp4Autorizacao, Audesp4ComissaoLicitacao, Audesp4ExistenciaRecursosSim, Audesp4IndiceEconomico,
    Audesp4JulgamentoComInversao, Audesp4JulgamentoSemInversao, Audesp4Recurso
} from "./types/comum-type";
import {
    Audesp4Concorrencia, Audesp4ContrratacaoRDC, Audesp4Convite, Audesp4Outras, Audesp4PregaoEletronico, Audesp4PregaoPresencial, Audesp4TomadaPreco
} from "./types/modalidade-type";
import { Audesp4ComprasServicos, Audesp4ComprasServicosTI, Audesp4ObrasServicosEngenharia } from "./types/tipo-objeto-type";

export class RegistroPrecosNao1 {
    LicitacaoPossuiParticipantes: 'S' | 'N';
    CodigoLicitacao: number;
    NumeroProcessoAdm: string;
    AnoProcessoAdm: number;
    NumeroLicitacao: string;
    AnoLicitacao: number;
    Lei13121: 'S' | 'N';
    DescricaoObjeto: string;
    JustificativaContratacao?: string;
    TotalLicitacaoValor: number;
    TipoLicitacao: number;
    Subcontratacao: 'S' | 'N';
    BeneficioLei1232006: number;
    ExistenciaRecursosSim?: Audesp4ExistenciaRecursosSim;
    ExistenciaRecursosNao?: 'N';
    QuantidadeLotes: number;
    ComprasServicosTI?: Audesp4ComprasServicosTI;
    ComprasServicos?: Audesp4ComprasServicos;
    ObrasServicosEngenharia?: Audesp4ObrasServicosEngenharia;
    Concorrencia?: Audesp4Concorrencia;
    TomadaPrecos?: Audesp4TomadaPreco;
    Convite?: Audesp4Convite;
    PregaoEletronico?: Audesp4PregaoEletronico;
    PregaoPresencial?: Audesp4PregaoPresencial;
    ContrratacaoRDC?: Audesp4ContrratacaoRDC;
    Outras?: Audesp4Outras;
    LRFArtigo16?: Audesp4Artigo16;
    LRFArtigo17?: Audesp4Artigo17;
    LRFNaoSeEnquadra?: 'S';
    ParecerTecnicoJuridico: 'S' | 'N';
    AudienciaPublicaDt?: string;
    DataEntregaPropostaNao?: 'N';
    DatasEntregaPropostaDt?: string;
    DatasAberturaLicitacaoDt?: string;
    DatasJulgamentoDt?: string;
    ComissaoLicitacao?: Audesp4ComissaoLicitacao;
    AtestadoDesempenho?: Audesp4AtestadoDesempenho[];
    IndiceEconomico?: Audesp4IndiceEconomico[];
    TributosFederais: 'S' | 'N';
    TributosEstaduais: 'S' | 'N';
    TributosMunicipais: 'S' | 'N';
    JulgamentoSemInversao?: Audesp4JulgamentoSemInversao;
    JulgamentoComInversao?: Audesp4JulgamentoComInversao;
    Recurso?: Audesp4Recurso[];
    SessaoPublica?: 'S' | 'N';
    HomologacaoPublicacaoNao?: 'N';
    HomologacaoPublicacaoDt?: string;
    AdjudicacaoNao?: 'N';
    AdjudicacaoDt?: string;
    Autorizacao?: Audesp4Autorizacao;
    SituacaoLicitacao: number;
    DtSituacaoLicitacao: string;
    DescricaoSituacaoLicitacao?: string;
}