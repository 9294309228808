// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Empenho } from './empenho.model';

export class EmpenhoAnexo extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public caminho?: string,
    public tipo?: string,
    public documento?: string,
    public arquivo?: string,
    public url?: string, 
    public descricao?: string,
    public tamanho?: number,
    public empenho?: Empenho,
    public editavel?: boolean,
  ) {
    super();
  }

  static converteJson(json: any): EmpenhoAnexo {
    return Object.assign(new EmpenhoAnexo(), json);
  }
}
