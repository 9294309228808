import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseResourceFormComponent, FuncaoService, GlobalService, Seguradora, LoginContabil, ViaCEPService } from 'eddydata-lib';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { SeguradoraService } from '../service/seguradora.service';

@Component({
  selector: 'app-seguradora-form',
  templateUrl: './seguradora-form.component.html'
})
export class SeguradoraFormComponent extends BaseResourceFormComponent<Seguradora, LoginContabil> {

  /**
   * Declaração de variáveis
   */

  public codigoSeguradora: number;

  @ViewChild('sigla') inputSigla: ElementRef;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    public globalService: GlobalService,
    protected injector: Injector,
    protected viacepService: ViaCEPService,
    protected funcaoService: FuncaoService,
    protected seguradoraService: SeguradoraService) {
    super(new Seguradora(), injector, Seguradora.converteJson, seguradoraService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      nome: [null],
      nome_contato: [null],
      telefone: [null],
      celular: [null],
      cep: [null],
      endereco: [null],
      numero: [null],
      bairro: [null],
      uf: [null],
      cidade: [null],
      tipo_seguro: [null],
      orgao: [this.login.orgao, [Validators.required]],
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'orgao' };
  }

  protected afterLoad() {
  }

  protected afterInit(): void {
    this.seguradoraService.proximoCodigo()
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((codigo) => {
      this.codigoSeguradora = codigo
    })
  }

  protected campoFoco(): ElementRef {
    return this.inputSigla;
  }

  protected async podeAlterar(item: Seguradora): Promise<boolean> {
    return true;
  }

  protected beforeSubmit() {
    console.log(this.entidade)
    try {
      if (!this.entidadeForm.get('nome').value) {
        throw new Error('Informe o nome!');
      }
    } catch (e) {
      toastr.error(e.message);
      throw e;
    }
  }

  protected afterSubmit(modelo: Seguradora) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public buscarCEP() {
    if (this.entidadeForm.get('cep').value) {
      let cep = this.entidadeForm.get('cep').value;
      this.viacepService.obterPorCPF(cep)
        .subscribe((endereco) => {
          console.log(endereco)
          if (!endereco) {
            toastr.warning('Endereço não foi encontrado')
            return;
          }
          this.entidadeForm.get('endereco').setValue(endereco.logradouro);
          this.entidadeForm.get('bairro').setValue(endereco.bairro);
          this.entidadeForm.get('cidade').setValue(endereco.localidade);
          this.entidadeForm.get('uf').setValue(endereco.uf)
        }, (err) => toastr.warning('Endereço não foi encontrado'));
    }
  }

}
