// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class TipoTransferenciaPatrimonio extends BaseResourceModel {
  constructor(
    public id?: number,
    public emprestimo?: boolean,
    public padrao_encerramento?: boolean,
    public descricao?: string,
    public termo?: string,
    public orgao?: Orgao,
  ) {
    super();
  }

  static converteJson(json: any): TipoTransferenciaPatrimonio {
    return Object.assign(new TipoTransferenciaPatrimonio(), json);
  }
}
