import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { FichaReceita, BaseResourceService } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class BalancoService extends BaseResourceService<FichaReceita> {

  constructor(
    protected injector: Injector
  ) {
    super(`contabil-relatorio`, injector);
  }

  obterBalancoOrcamentario(exercicio: number, orgaos: number[], mes?: number): Observable<number> {
    return this.http.get<number>(`${this.login.cidade.id}/${this.api}/balanco-anexo12/${exercicio}/${orgaos.join()}/${mes}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterBalancoOrcamentarioRPNaoProcessado(exercicio: number, orgaos: number[], mes?: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/balanco-anexo12-1/${exercicio}/${orgaos.join()}/${mes}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterBalancoOrcamentarioRPProcessado(exercicio: number, orgaos: number[], mes?: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/balanco-anexo12-2/${exercicio}/${orgaos.join()}/${mes}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterBalancoFinanceiro(exercicio: number, orgaos: number[], mes?: number): Observable<any> {
    mes = !mes ? 13 : mes;
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/balanco-anexo13/${exercicio}/${orgaos.join()}/${mes}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterBalancoFinanceiroA(exercicio: number, orgaos: number[], mes?: number, dtInicial?: string, dtFinal?: string): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/balanco-anexo13-A/${exercicio}/${orgaos.join()}/${mes}/${dtInicial}/${dtFinal}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterBalancoPatrimonial(exercicio: number, orgaos: number[], mes?: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/balanco-anexo14/${exercicio}/${orgaos.join()}/${mes}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterBalancoPatrimonialA(exercicio: number, orgaos: number[], mes?: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/balanco-anexo14-A/${exercicio}/${orgaos.join()}/${mes}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterBalancoPatrimonialB(exercicio: number, orgaos: number[],mes?: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/balanco-anexo14-B/${exercicio}/${orgaos.join()}/${mes}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterBalancoVariacoes(exercicio: number, orgaos: number[], mes?: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/balanco-anexo15/${exercicio}/${orgaos.join()}/${mes}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterDividaFundada(exercicio: number, orgaos: number[], mes?: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/balanco-anexo16/${exercicio}/${orgaos.join()}/${mes}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterDividaFlutuante(exercicio: number, orgaos: number[], mes?: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/balanco-anexo17/${exercicio}/${orgaos.join()}/${mes}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterFluxoCaixa(exercicio: number, orgaos: number[], mes?: number): Observable<any> {
    mes  = mes && mes > 0 ? mes : 13;
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/balanco-anexo18/${exercicio}/${orgaos.join()}/${mes}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
