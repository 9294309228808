// tslint:disable: variable-name
import { Processo } from './processo.model';
import { Usuario } from '../comum/usuario.model';

export class ProcessoRelacionado {

    id: number;

    data_cadastro: Date;

    data_alteracao: Date;

    usuario: Usuario;

    processo: Processo;
}
