// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class MotivoBaixaPatrimonio extends BaseResourceModel {
  constructor(
    public id?: number,
    public descricao?: string,
    public ativo?: boolean,
    public orgao?: Orgao,
  ) {
    super();
  }

  static converteJson(json: any): MotivoBaixaPatrimonio {
    return Object.assign(new MotivoBaixaPatrimonio(), json);
  }
}
