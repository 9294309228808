// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Executora } from '../planejamento/executora.model';
import { Setor } from './setor.model';

export class SetorExecutora extends BaseResourceModel {
  constructor(
    public id?: number,
    public executora?: Executora,
    public setor?: Setor,
  ) {
    super();
  }
  static converteJson(json: any): SetorExecutora {
    return Object.assign(new SetorExecutora(), json);
  }
}
