import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, ProgressoService, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../assinaturas/assinaturas';
import { AnexoLRFService } from '../../service/anexo-lrf.service';

@Directive()
export class DespesaPessoalLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();
  public valorDespesaPessoal = 0;

  constructor(
    private anexoServico: AnexoLRFService,
    private executivo: boolean,
    private mes: number,
    private exercicio: Exercicio,
    protected injector: Injector,
    protected progressoService: ProgressoService,
    protected assinaturaControleInterno: boolean,
    protected percentual: boolean) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async obterDespesaPessoal(orgaos: number[]) {
    const result = await this.anexoServico.obterDespesaPessoal(this.mes, this.exercicio.id, orgaos, true, this.percentual).toPromise();
    return this.conteudo(result);
  }


  // tslint:disable: max-line-length
  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
    const parametros: {} = {};
    if (this.mes) {
      parametros['mes'] = this.mes;
    }
    if (this.exercicio) {
      parametros['ano'] = this.exercicio.id;
    }
    this.anexoServico.obterDespesaPessoal(this.mes, this.exercicio.id, orgaos, this.executivo, this.percentual)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async idTransacao => {
        let dados
        this.progressoService.show(idTransacao, async (retorno) => {
          dados = retorno
          parametros['orgaos'] = orgaos.join();
          if (formato === 'pdf') {
            Relatorio.imprimirPersonalizado(
              `DEMONSTRATIVO DE APURAÇÃO DAS DESPESAS COM PESSOAL - ${this.executivo ? 'PODER EXECUTIVO' : 'PODER LEGISLATIVO'}`,
              this.login.usuario.nome, this.login.usuario.sobrenome,
              this.login.orgao.nome, this.login.brasao,
              await this.conteudo(dados),
              'landscape', `DEMONSTRATIVO DE APURAÇÃO DAS DESPESAS COM PESSOAL - ${this.executivo ? 'PODER EXECUTIVO' : 'PODER LEGISLATIVO'}`,
              {
                linhas: {
                  hLineWidth() {
                    return 1;
                  },
                  vLineWidth() {
                    return 1;
                  },
                  hLineColor() {
                    return 'black';
                  },
                  paddingLeft() {
                    return 3;
                  },
                  paddingRight() {
                    return 3;
                  }
                }
              }, false, false, null, 'Referência: ' + this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano);
          } else {
            this.funcaoService.exportar(formato, this.normalizar(dados), `DEMONSTRATIVO DE APURAÇÃO DAS DESPESAS COM PESSOAL - ${this.executivo ? 'PODER EXECUTIVO' : 'PODER LEGISLATIVO'}`, this.colunas());
          }
        })
      });
  }

  private async conteudo(dados: any[]): Promise<{}[]> {
    const registros: {}[] = [[{
      text: 'DESPESAS COM PESSOAL',
      rowSpan: 2, alignment: 'center', bold: true
    },
    {
      text: 'EVOLUÇÃO DA DESPESA LÍQUIDA NOS ÚLTIMOS DOZE MESES',
      alignment: 'center',
      bold: true,
      fontSize: 6,
      colSpan: 12
    },
      '', '', '', '', '', '', '', '', '', '', '',
    {
      text: 'Total 12 meses',
      fontSize: 6,
      rowSpan: 2, alignment: 'center', bold: true
    }],
    [
      { text: '' },
      { text: this.retornarNomeMes(-11), alignment: 'center', fontSize: 6, bold: true },
      { text: this.retornarNomeMes(-10), alignment: 'center', fontSize: 6, bold: true },
      { text: this.retornarNomeMes(-9), alignment: 'center', fontSize: 6, bold: true },
      { text: this.retornarNomeMes(-8), alignment: 'center', fontSize: 6, bold: true },
      { text: this.retornarNomeMes(-7), alignment: 'center', fontSize: 6, bold: true },
      { text: this.retornarNomeMes(-6), alignment: 'center', fontSize: 6, bold: true },
      { text: this.retornarNomeMes(-5), alignment: 'center', fontSize: 6, bold: true },
      { text: this.retornarNomeMes(-4), alignment: 'center', fontSize: 6, bold: true },
      { text: this.retornarNomeMes(-3), alignment: 'center', fontSize: 6, bold: true },
      { text: this.retornarNomeMes(-2), alignment: 'center', fontSize: 6, bold: true },
      { text: this.retornarNomeMes(-1), alignment: 'center', fontSize: 6, bold: true },
      { text: this.retornarNomeMes(0), alignment: 'center', fontSize: 6, bold: true }
    ]];

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(50, false, null, this.assinaturaControleInterno);
    let despesaPercentual = {
      nome: 'TOTAL DESPESA COM PESSOAL', tipo: 2, soma11: 0, soma10: 0,
      soma9: 0, soma8: 0, soma7: 0, soma6: 0, soma5: 0, soma4: 0,
      soma3: 0, soma2: 0, soma1: 0, soma0: 0
    }
    let rcl = {
      nome: 'RECEITA CORRENTE LIQUIDA', tipo: 2, soma11: 0, soma10: 0,
      soma9: 0, soma8: 0, soma7: 0, soma6: 0, soma5: 0, soma4: 0,
      soma3: 0, soma2: 0, soma1: 0, soma0: 0, somaTotal: 0
    }
    const grupos = this.funcaoService.agrupar(dados, 'tipo',
      ['soma0', 'soma1', 'soma2', 'soma3', 'soma4', 'soma5', 'soma6', 'soma7', 'soma8', 'soma9', 'soma10', 'soma11']);
    let somaTotal = 0.0;
    for (const grupo of grupos) {
      let somaGrupo = 0.0;
      if (grupo.grupo !== 2) {
        for (let idx = 11; idx >= 0; idx--) {
          somaGrupo += +grupo.totalizadores[`soma${idx}`];
        }
        if (this.percentual) {
          if (grupo.grupo === 0) {
            despesaPercentual.soma11 += grupo.totalizadores[`soma11`];
            despesaPercentual.soma10 += grupo.totalizadores[`soma10`];
            despesaPercentual.soma9 += grupo.totalizadores[`soma9`];
            despesaPercentual.soma8 += grupo.totalizadores[`soma8`];
            despesaPercentual.soma7 += grupo.totalizadores[`soma7`];
            despesaPercentual.soma6 += grupo.totalizadores[`soma6`];
            despesaPercentual.soma5 += grupo.totalizadores[`soma5`];
            despesaPercentual.soma4 += grupo.totalizadores[`soma4`];
            despesaPercentual.soma3 += grupo.totalizadores[`soma3`];
            despesaPercentual.soma2 += grupo.totalizadores[`soma2`];
            despesaPercentual.soma1 += grupo.totalizadores[`soma1`];
            despesaPercentual.soma0 += grupo.totalizadores[`soma0`];
          } else {
            despesaPercentual.soma11 -= grupo.totalizadores[`soma11`];
            despesaPercentual.soma10 -= grupo.totalizadores[`soma10`];
            despesaPercentual.soma9 -= grupo.totalizadores[`soma9`];
            despesaPercentual.soma8 -= grupo.totalizadores[`soma8`];
            despesaPercentual.soma7 -= grupo.totalizadores[`soma7`];
            despesaPercentual.soma6 -= grupo.totalizadores[`soma6`];
            despesaPercentual.soma5 -= grupo.totalizadores[`soma5`];
            despesaPercentual.soma4 -= grupo.totalizadores[`soma4`];
            despesaPercentual.soma3 -= grupo.totalizadores[`soma3`];
            despesaPercentual.soma2 -= grupo.totalizadores[`soma2`];
            despesaPercentual.soma1 -= grupo.totalizadores[`soma1`];
            despesaPercentual.soma0 -= grupo.totalizadores[`soma0`];
          }
        }
      } else {
        rcl.soma11 = grupo.totalizadores[`soma11`];
        rcl.soma10 = grupo.totalizadores[`soma10`];
        rcl.soma9 = grupo.totalizadores[`soma9`];
        rcl.soma8 = grupo.totalizadores[`soma8`];
        rcl.soma7 = grupo.totalizadores[`soma7`];
        rcl.soma6 = grupo.totalizadores[`soma6`];
        rcl.soma5 = grupo.totalizadores[`soma5`];
        rcl.soma4 = grupo.totalizadores[`soma4`];
        rcl.soma3 = grupo.totalizadores[`soma3`];
        rcl.soma2 = grupo.totalizadores[`soma2`];
        rcl.soma1 = grupo.totalizadores[`soma1`];
        rcl.soma0 = grupo.totalizadores[`soma0`];
        rcl.somaTotal = (rcl.soma11 + rcl.soma10 + rcl.soma9 + rcl.soma8 + rcl.soma7 + rcl.soma6 + rcl.soma5 + rcl.soma4 + rcl.soma3 + rcl.soma2 + rcl.soma1 + rcl.soma0)
      }

      if (somaTotal === 0.0) {
        somaTotal += somaGrupo;
      } else {
        somaTotal -= somaGrupo;
      }

      for (const registro of grupo.registros) {
        registros.push([
          {
            text: `${registro.nome}`, bold: grupo.grupo !== 2 ? false : true, fontSize: 6,
          },
          { text: this.funcaoService.convertToBrNumber(registro.soma11), fontSize: 6, bold: grupo.grupo !== 2 ? false : true, alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(registro.soma10), fontSize: 6, bold: grupo.grupo !== 2 ? false : true, alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(registro.soma9), fontSize: 6, bold: grupo.grupo !== 2 ? false : true, alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(registro.soma8), fontSize: 6, bold: grupo.grupo !== 2 ? false : true, alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(registro.soma7), fontSize: 6, bold: grupo.grupo !== 2 ? false : true, alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(registro.soma6), fontSize: 6, bold: grupo.grupo !== 2 ? false : true, alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(registro.soma5), fontSize: 6, bold: grupo.grupo !== 2 ? false : true, alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(registro.soma4), fontSize: 6, bold: grupo.grupo !== 2 ? false : true, alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(registro.soma3), fontSize: 6, bold: grupo.grupo !== 2 ? false : true, alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(registro.soma2), fontSize: 6, bold: grupo.grupo !== 2 ? false : true, alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(registro.soma1), fontSize: 6, bold: grupo.grupo !== 2 ? false : true, alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(registro.soma0), fontSize: 6, bold: grupo.grupo !== 2 ? false : true, alignment: 'right' },
          {
            text: this.funcaoService.convertToBrNumber((+registro.soma11 + +registro.soma10 + +registro.soma9 + +registro.soma8
              + +registro.soma7 + +registro.soma6 + +registro.soma5 + +registro.soma4
              + +registro.soma3 + +registro.soma2 + +registro.soma1 + +registro.soma0)), bold: grupo.grupo !== 2 ? false : true, fontSize: 6, alignment: 'right'
          }
        ]);
    }
    if (grupo.grupo !== 2) {
      registros.push([
        {
          text: Number(grupo.grupo) === 0 ? 'SUBTOTAL (I)' : 'SUBTOTAL (II)', bold: true
        },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma11`]), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma10`]), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma9`]), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma8`]), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma7`]), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma6`]), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma5`]), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma4`]), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma3`]), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma2`]), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma1`]), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`soma0`]), alignment: 'right', fontSize: 6, bold: true },
        {
          text: this.funcaoService.convertToBrNumber(somaGrupo), fontSize: 6, alignment: 'right', bold: true
        }
      ]);
      registros.push([{
        text: ' ',
        fontSize: 6,
        colSpan: 14
      }, '', '', '', '', '', '', '', '', '', '', '', '', '']);
    }
    if (Number(grupo.grupo) === 0) {
      registros.push([{
        text: 'DESPESAS NÃO COMPUTADAS',
        fontSize: 6,
        bold: true
      }, { text: '', colSpan: 13 }, '', '', '', '', '', '', '', '', '', '', '', '']);
    }
  }
  if(this.percentual) {
    registros.push([
      {
        text: despesaPercentual.nome, bold: true, fontSize: 6
      },
      { text: this.funcaoService.convertToBrNumber(despesaPercentual.soma11), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(despesaPercentual.soma10), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(despesaPercentual.soma9), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(despesaPercentual.soma8), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(despesaPercentual.soma7), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(despesaPercentual.soma6), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(despesaPercentual.soma5), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(despesaPercentual.soma4), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(despesaPercentual.soma3), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(despesaPercentual.soma2), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(despesaPercentual.soma1), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(despesaPercentual.soma0), alignment: 'right', fontSize: 6, bold: true },
      {
        text: this.funcaoService.convertToBrNumber(somaTotal), fontSize: 6, alignment: 'right', bold: true
      }
    ]);
    registros.push([
      {
        text: 'PERCENTUAL LRF', bold: true, fontSize: 6
      },
      { text: this.funcaoService.convertToBrNumber((+despesaPercentual.soma11 / +rcl.soma11) * 100), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber((+despesaPercentual.soma10 / +rcl.soma10) * 100), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber((+despesaPercentual.soma9 / +rcl.soma9) * 100), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber((+despesaPercentual.soma8 / +rcl.soma8) * 100), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber((+despesaPercentual.soma7 / +rcl.soma7) * 100), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber((+despesaPercentual.soma6 / +rcl.soma6) * 100), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber((+despesaPercentual.soma5 / +rcl.soma5) * 100), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber((+despesaPercentual.soma4 / +rcl.soma4) * 100), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber((+despesaPercentual.soma3 / +rcl.soma3) * 100), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber((+despesaPercentual.soma2 / +rcl.soma2) * 100), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber((+despesaPercentual.soma1 / +rcl.soma1) * 100), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber((+despesaPercentual.soma0 / +rcl.soma0) * 100), alignment: 'right', fontSize: 6, bold: true },
      {
        text: this.funcaoService.convertToBrNumber((somaTotal / rcl.somaTotal) * 100), fontSize: 6, alignment: 'right', bold: true
      }
    ]);
  }
    registros.push([{
    text: 'TOTAL LÍQUIDO ( I - II )', bold: true,
    alignment: 'left', fontSize: 6,
    colSpan: 13
    }, '', '', '', '', '', '', '', '', '', '', '', '', {
      text: this.funcaoService.convertToBrNumber(somaTotal),
        alignment: 'right', fontSize: 6, bold: true
    }]);
this.valorDespesaPessoal = somaTotal;
return [{
  layout: 'linhas',
  table: {
    // headers are automatically repeated if the table spans over multiple pages
    // you can declare how many rows should be treated as headers
    dontBreakRows: true,
    headerRows: 2,
    widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
    body: registros
  }
},
{
  layout: 'linhas',
  table: {
    dontBreakRows: true,
    headerRows: 0,
    widths: ['*', '*', '*'],
    body: assinaturas
  }
}
];
  }

  private retornarNomeMes(diminuir: number) {
  const fator: number = +this.mes + +diminuir;
  if (fator > 0) {
    return ('00' + fator).slice(-2)
      + `/ ${this.exercicio.ano}`;
  } else {
    const meses: number = +12 + +fator;
    return ('00' + meses).slice(-2) + `/ ${+ this.exercicio.ano - 1}`;
  }
}

  public normalizar(lista: any[]) {
    const listaExportar = []

    let somaTotal = 0.0;

    let soma11 = 0;
    let soma10 = 0;
    let soma9 = 0;
    let soma8 = 0;
    let soma7 = 0;
    let soma6 = 0;
    let soma5 = 0;
    let soma4 = 0;
    let soma3 = 0;
    let soma2 = 0;
    let soma1 = 0;
    let soma0 = 0;

    const grupos = this.funcaoService.agrupar(lista, 'tipo',
      ['soma0', 'soma1', 'soma2', 'soma3', 'soma4', 'soma5', 'soma6', 'soma7', 'soma8', 'soma9', 'soma10', 'soma11']);
    for (const grupo of grupos) {
      let somaGrupo = 0.0;
      for (let idx = 11; idx >= 0; idx--) {
        somaGrupo += +grupo.totalizadores[`soma${idx}`];
      }
      if (somaTotal === 0.0) {
        somaTotal += somaGrupo;
      } else {
        somaTotal -= somaGrupo;
      }

      for (const item of grupo.registros) {
        const items = {
          nome_despesa: item.nome,
          mes11: this.funcaoService.convertToBrNumber(item.soma11),
          mes10: this.funcaoService.convertToBrNumber(item.soma10),
          mes9: this.funcaoService.convertToBrNumber(item.soma9),
          mes8: this.funcaoService.convertToBrNumber(item.soma8),
          mes7: this.funcaoService.convertToBrNumber(item.soma7),
          mes6: this.funcaoService.convertToBrNumber(item.soma6),
          mes5: this.funcaoService.convertToBrNumber(item.soma5),
          mes4: this.funcaoService.convertToBrNumber(item.soma4),
          mes3: this.funcaoService.convertToBrNumber(item.soma3),
          mes2: this.funcaoService.convertToBrNumber(item.soma2),
          mes1: this.funcaoService.convertToBrNumber(item.soma1),
          mes0: this.funcaoService.convertToBrNumber(item.soma0),
          total_12_meses: this.funcaoService.convertToBrNumber((+item.soma11 + +item.soma10 + +item.soma9 + +item.soma8
            + +item.soma7 + +item.soma6 + +item.soma5 + +item.soma4
            + +item.soma3 + +item.soma2 + +item.soma1 + +item.soma0))
        }
        listaExportar.push(items)
      }

      soma11 = grupo.totalizadores['soma11'];
      soma10 = grupo.totalizadores['soma10'];
      soma9 = grupo.totalizadores['soma9'];
      soma8 = grupo.totalizadores['soma8'];
      soma7 = grupo.totalizadores['soma7'];
      soma6 = grupo.totalizadores['soma6'];
      soma5 = grupo.totalizadores['soma5'];
      soma4 = grupo.totalizadores['soma4'];
      soma3 = grupo.totalizadores['soma3'];
      soma2 = grupo.totalizadores['soma2'];
      soma1 = grupo.totalizadores['soma1'];
      soma0 = grupo.totalizadores['soma0'];

      const subtotal1 = {
        nome_despesa: Number(grupo.grupo) === 0 ? 'SUBTOTAL (I)' : 'SUBTOTAL (II)',
        mes11: this.funcaoService.convertToBrNumber(soma11),
        mes10: this.funcaoService.convertToBrNumber(soma10),
        mes9: this.funcaoService.convertToBrNumber(soma9),
        mes8: this.funcaoService.convertToBrNumber(soma8),
        mes7: this.funcaoService.convertToBrNumber(soma7),
        mes6: this.funcaoService.convertToBrNumber(soma6),
        mes5: this.funcaoService.convertToBrNumber(soma5),
        mes4: this.funcaoService.convertToBrNumber(soma4),
        mes3: this.funcaoService.convertToBrNumber(soma3),
        mes2: this.funcaoService.convertToBrNumber(soma2),
        mes1: this.funcaoService.convertToBrNumber(soma1),
        mes0: this.funcaoService.convertToBrNumber(soma0),
        total_12_meses: this.funcaoService.convertToBrNumber(somaGrupo)
      }
      listaExportar.push(subtotal1)

      if (Number(grupo.grupo) === 0) {
        const despesas_nao_computadas = {
          nome_despesa: 'DESPESAS NÃO COMPUTADAS',
          mes11: '',
          mes10: '',
          mes9: '',
          mes8: '',
          mes7: '',
          mes6: '',
          mes5: '',
          mes4: '',
          mes3: '',
          mes2: '',
          mes1: '',
          mes0: '',
          total_12_meses: ''
        }
        listaExportar.push(despesas_nao_computadas)
      }
    }

    const total = {
      nome_despesa: 'TOTAL LÍQUIDO ( I - II )',
      mes11: '',
      mes10: '',
      mes9: '',
      mes8: '',
      mes7: '',
      mes6: '',
      mes5: '',
      mes4: '',
      mes3: '',
      mes2: '',
      mes1: '',
      mes0: '',
      total_12_meses: this.funcaoService.convertToBrNumber(somaTotal)
    }
    listaExportar.push(total)

    return listaExportar
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'DESPESAS COM PESSOAL', coluna: 'nome_despesa', bold: true },
      { titulo: this.retornarNomeMes(-11), coluna: 'mes11' },
      { titulo: this.retornarNomeMes(-10), coluna: 'mes10' },
      { titulo: this.retornarNomeMes(-9), coluna: 'mes9' },
      { titulo: this.retornarNomeMes(-8), coluna: 'mes8' },
      { titulo: this.retornarNomeMes(-7), coluna: 'mes7' },
      { titulo: this.retornarNomeMes(-6), coluna: 'mes6' },
      { titulo: this.retornarNomeMes(-5), coluna: 'mes5' },
      { titulo: this.retornarNomeMes(-4), coluna: 'mes4' },
      { titulo: this.retornarNomeMes(-3), coluna: 'mes3' },
      { titulo: this.retornarNomeMes(-2), coluna: 'mes2' },
      { titulo: this.retornarNomeMes(-1), coluna: 'mes1' },
      { titulo: this.retornarNomeMes(0), coluna: 'mes0' },
      { titulo: 'Total 12 meses', coluna: 'total_12_meses' },
    ];
    return colunasDefault;
  }

}
