import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Audesp4AtestadoDesempenho, FuncaoService, RegistroPrecos } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-atestado',
  templateUrl: './licitacao-audesp-atestado.component.html'
})
export class LicitacaoAudespAtestadoComponent extends BaseLicitacaoAudesp implements OnChanges {

  @Input() public entidade: RegistroPrecos;

  public itemAtual: Audesp4AtestadoDesempenho;
  public itemAnterior: Audesp4AtestadoDesempenho;
  public listaLotes: { id: number, nome: string }[];

  constructor(
    public funcaoService: FuncaoService
  ) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['entidade']) {
      this.listaLotes = [];
      if (this.entidade.tipoObjeto === 'COMPRA_SERVICO' && this.entidade.ComprasServicos?.Lote) {
        this.entidade.ComprasServicos.Lote.forEach(lote => {
          this.listaLotes.push({ id: lote.NumeroLote, nome: lote.DescricaoLote })
        })
      } else if (this.entidade.tipoObjeto === 'COMPRA_SERVICO_TI' && this.entidade.ComprasServicosTI?.Lote) {
        this.entidade.ComprasServicosTI.Lote.forEach(lote => {
          this.listaLotes.push({ id: lote.NumeroLote, nome: lote.DescricaoLote })
        })
      } else if (this.entidade.tipoObjeto === 'ENGENHARIA' && this.entidade.ObrasServicosEngenharia?.Lote) {
        this.entidade.ObrasServicosEngenharia.Lote.forEach(lote => {
          this.listaLotes.push({ id: lote.NumeroLote, nome: lote.DescricaoLote })
        })
      }
    }
  }

  public adicionar() {
    if (!this.entidade.AtestadoDesempenho || this.entidade.AtestadoDesempenho.length === 0) this.entidade.AtestadoDesempenho = [];

    this.itemAtual = {
      AtestadoPercentual: undefined, AtestadoQuantidade: undefined, NumeroLote: undefined, AtestadoDescricao: undefined, AtestadoEditalItem: undefined, editavel: true
    }
    this.entidade.AtestadoDesempenho.push(this.itemAtual);
  }

  public visualizar(item: Audesp4AtestadoDesempenho) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      let itm = this.entidade.AtestadoDesempenho.find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.entidade.AtestadoDesempenho.splice(this.entidade.AtestadoDesempenho.indexOf(this.itemAtual), 1, itm)
      this.itemAtual.editavel = false;
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4AtestadoDesempenho) {
    this.itemAtual = undefined;
    this.entidade.AtestadoDesempenho.splice(this.entidade.AtestadoDesempenho.indexOf(item), 1)
  }

  public salvar() {
    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }

  public aplicarDescricao() {
    if (!this.itemAtual) return;
    if (!this.itemAtual.AtestadoDescricao) {
      toastr.warning('Informe a descrição antes de aplicar a outros itens');
      return;
    }
    this.entidade.AtestadoDesempenho.forEach(l => l.AtestadoDescricao = this.itemAtual.AtestadoDescricao)
  }

  public aplicarPercentual() {
    if (!this.itemAtual) return;
    if (!this.itemAtual.AtestadoPercentual) {
      toastr.warning('Informe o percentual antes de aplicar a outros itens');
      return;
    }
    this.entidade.AtestadoDesempenho.forEach(l => l.AtestadoPercentual = this.itemAtual.AtestadoPercentual)
  }

  public aplicarItemEdital() {
    if (!this.itemAtual) return;
    if (!this.itemAtual.AtestadoEditalItem) {
      toastr.warning('Informe o item do edital antes de aplicar a outros itens');
      return;
    }
    this.entidade.AtestadoDesempenho.forEach(l => l.AtestadoEditalItem = this.itemAtual.AtestadoEditalItem)
  }
}
