import { Component, Input, SimpleChanges } from "@angular/core";
import { MemorialService, PropostaService } from "administrativo-lib";
import { AssinaturaService, BaseResourceRptPersonalizadoComponent, CpfPipe, Licitacao, LicitacaoPipe, Memorial, Proposta } from "eddydata-lib";
import { data } from "jquery";
import { takeUntil } from "rxjs/operators";
import * as toastr from 'toastr';

interface ItensSelected {
  memorial: Memorial,
  selecionado: boolean,
}
@Component({
  selector: 'app-termo-homologacao-rpt',
  templateUrl: './termo-homologacao-rpt.component.html',
  styleUrls: ['./termo-homologacao-rpt.component.css']
})
export class TermoHomologacaoRptComponent extends BaseResourceRptPersonalizadoComponent {

  @Input() public licitacao: Licitacao;

  public dataHomologacao: Date;
  public itensSelected: ItensSelected[] = [];
  public modelo: any;
  public listModelo: { id: number, nome: string }[] = [];;
  public ordenacao: any;
  public listaOrdenacao: { nome: string, coluna: string }[] = [];


  constructor(
    private propostaService: PropostaService,
    private memorialService: MemorialService,
    protected assinaturaService: AssinaturaService,
  ) {
    super(assinaturaService);
    this.assinaturaObrigatoria = true;
    this.listModelo = [{ id: 1, nome: 'Modelo 1' }, { id: 2, nome: 'Modelo 2' }];
    this.modelo = this.listModelo[0].id;
    this.dataHomologacao = new Date();
    this.listaOrdenacao = this.obterColunasOrdenacoes();
    this.ordenacao = this.listaOrdenacao[0].coluna;
  }

  protected async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.licitacao && this.licitacao?.id) {
      await this.obterMemorial(this.licitacao?.id)
    }
  }

  protected afterInit(): void {
  }

  protected modalRelatorio(): string {
    return 'dialogRelatorioTermoHomologacao';
  }

  protected tituloRelatorio(): string {
    return this.modelo == 1 ? 'Termo de Homologação' : 'Divisão de Licitações e Compras';
  }

  protected layoutRelatorio(): {} {
    return {
      table_eddy: {
        hLineWidth: function (i, node) {
          return i === node.table.body.length || i === 0 ? 0 : 1;
        },
        vLineWidth: function (i) {
          return 0;
        },
        hLineColor: function (i, node) {
          return i === node.table.headerRows || i === node.table.body.length - 1 ? 'black' : '#eee';
        },
        paddingLeft: function (i) {
          return i === 0 ? 0 : 8;
        },
        paddingRight: function (i, node) {
          return (i === node.table.widths.length - 1) ? 0 : 8;
        },
        fillColor: function (i, node) {
          if (i === 0 || i === node.table.headerRows || i === node.table.body.length - 1) {
            return null;
          }
          return (i % 2 === 0) ? '#f5f5f5' : null;
        }
      }
    }
  }

  protected async montarConteudo(): Promise<{}[]> {
    if (!this.dataHomologacao) {
      toastr.options.positionClass = 'toast-top-left';
      throw new Error('Informe a data de homologação!')
    } else {
      const conteudo = [];
      if (this.modelo === 2) {
        return await new Promise(async (resolve) => {
          conteudo.push(this.dadosModelo2())
          conteudo.push(await this.dadosFavorecidos())
          conteudo.push(this.dadosRodapeModelo2())

          resolve(conteudo);
        })
      } else {
        return await new Promise(async (resolve) => {
          conteudo.push(this.dadosCabecalho())
          conteudo.push(await this.dadosVencedores());
          conteudo.push(await this.dadosFracassados())
          conteudo.push(this.dadosRodape())

          resolve(conteudo);
        })
      }
    }

  }

  private dadosCabecalho(): {}[] {
    let texto = `Consoante ata. Precedentes, pregoeiro(a) designada pela Portaria ${this.licitacao.comissao.portaria}`;
    if (this.licitacao.comissao.publicacao) {
      texto += ` de ${this.licitacao.comissao.publicacao}`;
    }
    texto += `, comunicou sem quaisquer óbices, a ADJUDICAÇÃO do objeto do ${this.licitacao.modalidade.nome} nº ${new LicitacaoPipe().transform(this.licitacao.numero)} cujo objeto refere-se ao ${this.licitacao.objeto}, foram adjudicados:`;

    const conteudo = [
      { text: 'HOMOLOGAÇÃO', alignment: 'center', bold: true, fontSize: 16, decoration: 'underline', margin: [0, 0, 0, 10] },
      {
        text: texto, fontSize: 10, alignment: 'justify', margin: [0, 0, 0, 10]
      },
    ]
    return conteudo;
  }

  private dadosModelo2(): {}[] {
    const assinaturas = this.assinatura.pessoas.filter(p => p.selecionado);
    let texto: string = (assinaturas.length > 1 ? 'Os(as)' : 'O(a)') + ' ';
    assinaturas.forEach(a => {
      texto += `${a.pessoa.nome.toLocaleUpperCase()} - ${a.cargo.toLocaleUpperCase()}, `;
    });
    texto += ` tendo em vista o julgamento exarado no(a) ${this.licitacao.modalidade.nome} N.° ${new LicitacaoPipe().transform(this.licitacao.numero)} pela comissão de licitações nomeada pela portaria N.º ${this.licitacao.comissao.portaria} ${assinaturas.length > 1 ? 'homologamos' : 'homologa'} ${this.licitacao.modalidade.nome} a favor da(s) firma(s):`;

    const conteudo = [
      { text: 'HOMOLOGAÇÃO', alignment: 'center', bold: true, fontSize: 16, decoration: 'underline', margin: [0, 0, 0, 10] },
      { text: 'PROCESSO ADMINISTRATIVO N.º ' + new LicitacaoPipe().transform(this.licitacao.processo), alignment: 'center', fontSize: 14, margin: [0, 0, 0, 10] },
      { text: 'PREGÃO N.º ' + new LicitacaoPipe().transform(this.licitacao.numero), alignment: 'center', bold: true, fontSize: 14, margin: [0, 0, 0, 10] },
      { text: 'OBJETO: ', bold: true, decoration: 'underline', fontSize: 14, margin: [0, 0, 0, 10] },
      {
        text: this.licitacao.objeto, fontSize: 10, alignment: 'justify', margin: [0, 0, 0, 10]
      },
      {
        text: texto, fontSize: 10, alignment: 'justify', margin: [0, 0, 0, 10]
      },
    ]
    return conteudo;
  }

  private async dadosVencedores(): Promise<{}[]> {
    const vencedores: {
      favorecido: string,
      nome_favorecido: string,
      memorial_id: number,
      ordem: number,
      item: string,
      quantidade: number,
      valor_unit: number,
      marca: string,
      total: number,
    }[] = []

    return await new Promise((resolve) => {
      this.propostaService.obterVencedoresTermo(this.licitacao.id, `${this.ordenacao}`, true).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          const reg: Proposta[] = res.content;
          reg.forEach(p => {
            vencedores.push({
              favorecido: `${new CpfPipe().transform(p.proponente.favorecido.cpf_cnpj)} - ${p.proponente.favorecido.nome}`,
              memorial_id: p.memorial.id,
              nome_favorecido: p.proponente.favorecido.nome,
              ordem: p.memorial.ordem,
              item: `${p.memorial.ordem} - ${p.memorial.descricao} ${p.memorial.unidade}`,
              quantidade: +p.quantidade_final,
              marca: p?.marca,
              valor_unit: +p.valor_final,
              total: +Number(+p.quantidade_final * +p.valor_final).toFixed(4)
            })
          });
          let selecionados = []
          if (this.itensSelected.find(i => i.selecionado)) {
            for (const i of this.itensSelected) {
              selecionados.push(...vencedores.filter(v => v.memorial_id === i.memorial.id && i.selecionado))
            }
            selecionados = this.ordenacaoLista(selecionados)
          }
          if (this.ordenacao === 'memorial.ordem') {
            const itensAgrupados = this.funcaoService.agrupar(selecionados.length ? selecionados : this.ordenacaoLista(vencedores), 'item', ['quantidade', 'valor_unit', 'total']);
            const tabelas = [];
            itensAgrupados.forEach(grupo => {
              const conteudo = [];
              conteudo.push(
                [
                  { text: `${grupo.grupo}`, fontSize: 11, bold: true, colSpan: 4, alignment: 'left', margin: [0, 10, 0, 5] }, {}, {}, {}, {}
                ],
                [
                  { text: 'FAVORECIDO', alignment: 'center', fontSize: 10, bold: true },
                  { text: 'QTDE.', alignment: 'center', fontSize: 10, bold: true },
                  { text: 'VALOR UNIT.', alignment: 'center', fontSize: 10, bold: true },
                  { text: 'MARCA', alignment: 'center', fontSize: 10, bold: true },
                  { text: 'VALOR TOTAL', alignment: 'center', fontSize: 10, bold: true },
                ],
              )
              grupo.registros.forEach(item => {
                conteudo.push(
                  [
                    { text: `${item['favorecido']}`, alignment: 'left', fontSize: 9 },
                    { text: `${this.funcaoService.convertToBrNumber(item['quantidade'], 0)}`, alignment: 'right', fontSize: 9 },
                    { text: `${this.funcaoService.convertToBrNumber(item['valor_unit'], 4)}`, alignment: 'right', fontSize: 9 },
                    { text: `${item['marca']}`, alignment: 'center', fontSize: 9 },
                    { text: `${this.funcaoService.convertToBrNumber(item['total'], 4)}`, alignment: 'right', fontSize: 9 },
                  ],
                )
              })
              conteudo.push(
                [
                  { text: 'Total', fontSize: 9, bold: true, alignment: 'left', margin: [0, 5, 0, 5] },
                  { text: `${this.funcaoService.convertToBrNumber(grupo.totalizadores['quantidade'], 0)}`, fontSize: 9, bold: true, alignment: 'right', margin: [0, 5, 0, 5] },
                  { text: `${this.funcaoService.convertToBrNumber(grupo.totalizadores['valor_unit'], 4)}`, fontSize: 9, bold: true, alignment: 'right', margin: [0, 5, 0, 5] },
                  { text: '' },
                  { text: `${this.funcaoService.convertToBrNumber(grupo.totalizadores['total'], 4)}`, fontSize: 9, bold: true, alignment: 'right', margin: [0, 5, 0, 5] },
                ],
              )
              tabelas.push(conteudo)
            })
            const content = []
            content.push({ text: 'VENCEDORES', alignment: 'center', bold: true, fontSize: 12, margin: [0, 10, 0, 0] })
            tabelas.forEach(t => {
              content.push(
                {
                  layout: 'table_eddy',
                  table: {
                    dontBreakRows: true,
                    headerRows: 1,
                    widths: ['*', 40, 60, 70, 75],
                    body: t
                  }
                }
              )
            })
            resolve(content)
          } else {
            const itensAgrupados = this.funcaoService.agrupar(selecionados.length ? selecionados : this.ordenacaoLista(vencedores), 'favorecido', ['quantidade', 'valor_unit', 'total']);
            const tabelas = [];
            itensAgrupados.forEach(grupo => {
              const conteudo = [];
              conteudo.push(
                [
                  { text: `${grupo.grupo}`, fontSize: 11, bold: true, colSpan: 4, alignment: 'left', margin: [0, 10, 0, 5] }, {}, {}, {}, {}
                ],
                [
                  { text: 'ITEM', alignment: 'center', fontSize: 10, bold: true },
                  { text: 'QTDE.', alignment: 'center', fontSize: 10, bold: true },
                  { text: 'VALOR UNIT.', alignment: 'center', fontSize: 10, bold: true },
                  { text: 'MARCA', alignment: 'center', fontSize: 10, bold: true },
                  { text: 'VALOR TOTAL', alignment: 'center', fontSize: 10, bold: true },
                ],
              )
              grupo.registros.forEach(item => {
                conteudo.push(
                  [
                    { text: `${item['item']}`, alignment: 'left', fontSize: 9 },
                    { text: `${this.funcaoService.convertToBrNumber(item['quantidade'], 0)}`, alignment: 'right', fontSize: 9 },
                    { text: `${this.funcaoService.convertToBrNumber(item['valor_unit'], 4)}`, alignment: 'right', fontSize: 9 },
                    { text: `${item['marca']}`, alignment: 'center', fontSize: 9 },
                    { text: `${this.funcaoService.convertToBrNumber(item['total'], 4)}`, alignment: 'right', fontSize: 9 },
                  ],
                )
              })
              conteudo.push(
                [
                  { text: 'Total', fontSize: 9, bold: true, alignment: 'left', margin: [0, 5, 0, 5] },
                  { text: `${this.funcaoService.convertToBrNumber(grupo.totalizadores['quantidade'], 0)}`, fontSize: 9, bold: true, alignment: 'right', margin: [0, 5, 0, 5] },
                  { text: `${this.funcaoService.convertToBrNumber(grupo.totalizadores['valor_unit'], 4)}`, fontSize: 9, bold: true, alignment: 'right', margin: [0, 5, 0, 5] },
                  { text: '' },
                  { text: `${this.funcaoService.convertToBrNumber(grupo.totalizadores['total'], 4)}`, fontSize: 9, bold: true, alignment: 'right', margin: [0, 5, 0, 5] },
                ],
              )
              tabelas.push(conteudo)
            })
            const content = []
            content.push({ text: 'VENCEDORES', alignment: 'center', bold: true, fontSize: 12, margin: [0, 10, 0, 0] })
            tabelas.forEach(t => {
              content.push(
                {
                  layout: 'table_eddy',
                  table: {
                    dontBreakRows: true,
                    headerRows: 1,
                    widths: ['*', 40, 60, 70, 75],
                    body: t
                  }
                }
              )
            })
            resolve(content)
          }
        });
    })
  }

  private async dadosFracassados(): Promise<{}[]> {
    return await new Promise((resolve) => {
      this.propostaService.filtrar(0, 0, {
        'memorial.licitacao_id': this.licitacao.id,
        'situacao': 'FRACASSADO',
        'orderBy': 'memorial.ordem'
      }
      ).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          const reg: Proposta[] = res.content;
          let itens: any;
          if (reg.length === 0) resolve([])
          else {
            if (this.itensSelected.find(i => i.selecionado)) {
              let selecionados = []
              for (const i of this.itensSelected) {
                selecionados.push(...reg.filter(p => p.memorial.id === i.memorial.id && i.selecionado))
              }
              itens = selecionados.map(p => p.memorial).filter((item, idx, self) => idx === self.findIndex((itm) => itm.id === item.id))
            } else {
              itens = reg.map(p => p.memorial).filter((item, idx, self) => idx === self.findIndex((itm) => itm.id === item.id));
            }

            const conteudo = [];
            conteudo.push(
              [
                { text: 'ITEM', alignment: 'center', fontSize: 10, bold: true },
                { text: 'QTDE.', alignment: 'center', fontSize: 10, bold: true },
              ],
            )

            itens.forEach(item => {
              conteudo.push(
                [
                  { text: `${item.ordem} - ${item.descricao} ${item.unidade} ${this.informacaoCota(item)}`, alignment: 'left', fontSize: 9 },
                  { text: `${this.funcaoService.convertToBrNumber(item.quantidade, 0)}`, alignment: 'right', fontSize: 9 },
                ],
              )
            })

            const content = []
            content.push({ text: 'FRACASSADOS', alignment: 'center', bold: true, fontSize: 12, margin: [0, 10, 0, 5] })
            content.push(
              {
                layout: 'table_eddy',
                table: {
                  dontBreakRows: true,
                  headerRows: 1,
                  widths: ['*', 'auto'],
                  body: conteudo
                }
              }
            )

            resolve(content)
          }
        });
    })
  }

  private dadosRodape(): {}[] {
    const assinaturas = this.assinatura.pessoas.filter(p => p.selecionado);
    if (assinaturas?.length === 0) return [{}];

    let texto: string = (assinaturas.length > 1 ? 'Nós' : 'Eu') + ', ';
    assinaturas.forEach(a => {
      texto += `${a.pessoa.nome} - ${a.cargo}, `;
    });
    texto += (assinaturas.length > 1 ? 'homologamos' : 'homologo');

    const conteudo: {}[] = [
      {
        text: `${texto} o objeto a mesma empresa, pelo valor retro, nos termos da legislação de regência de matéria.`,
        fontSize: 10, alignment: 'justify', margin: [0, 20, 0, 10]
      },
      {
        text: 'Prossiga o feito com formalização de ata de registro de preços, respectiva publicação sintética, e demais providências administrativas.',
        fontSize: 10, alignment: 'justify', margin: [0, 0, 0, 10]
      },
      { text: this.funcaoService.formatarDataExtenso(this.dataHomologacao, this.login.cidade.nome), alignment: 'center', fontSize: 10, margin: [0, 30, 0, 70] },
    ]

    return conteudo.concat(this.imprimirAssinaturas(assinaturas));
  }

  private dadosRodapeModelo2(): {}[] {
    const assinaturas = this.assinatura.pessoas.filter(p => p.selecionado);
    if (assinaturas?.length === 0) return [{}];

    let texto: string = (assinaturas.length > 1 ? 'Nós' : 'E eu') + ', ';
    assinaturas.forEach(a => {
      texto += `${a.pessoa.nome.toLocaleUpperCase()} - ${a.cargo.toLocaleUpperCase()}, `;
    });
    texto += (assinaturas.length > 1 ? 'homologamos' : 'homologo');

    const conteudo: {}[] = [
      {
        text: `${texto} o objeto e os itens de cada empresa, pelo valor retro, nos termos da legislação de regência da matéria.`,
        fontSize: 10, bold: true, alignment: 'justify', margin: [0, 20, 0, 70]
      },
      {
        text: 'Os interessados deverão aguardar os demais atos necessários ao presente certame.',
        fontSize: 10, alignment: 'justify', margin: [0, 0, 0, 0]
      },
      { text: this.funcaoService.formatarDataExtenso(this.dataHomologacao, this.login.cidade.nome), bold: true, alignment: 'center', fontSize: 10, margin: [0, 10, 0, 70] },
    ]

    return conteudo.concat(this.imprimirAssinaturas(assinaturas));
  }

  private async dadosFavorecidos(): Promise<{}[]> {
    const vencedores: {
      favorecido: string,
      nome_favorecido: string,
      memorial_id: number,
      ordem: number,
      total: number,
    }[] = []
    return await new Promise((resolve) => {
      this.propostaService.obterVencedoresTermo(this.licitacao.id, `${this.ordenacao}`, true).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          const reg: Proposta[] = res.content;
          reg.forEach(p => {
            vencedores.push({
              favorecido: `${p.proponente.favorecido.nome}`,
              nome_favorecido: p.proponente.favorecido.nome,
              ordem: p.memorial.ordem,
              memorial_id: p.memorial.id,
              total: +Number(+p.quantidade_final * +p.valor_final).toFixed(4)
            })
          });
          let selecionados = []
          if (this.itensSelected.find(i => i.selecionado)) {
            for (const i of this.itensSelected) {
              selecionados.push(...vencedores.filter(v => v.memorial_id === i.memorial.id && i.selecionado))
            }
            selecionados = this.ordenacaoLista(selecionados)
          }
          const itensAgrupados = this.funcaoService.agrupar(selecionados.length ? selecionados : this.ordenacaoLista(vencedores), 'favorecido', ['total']);
          const tabelas = [];
          itensAgrupados.forEach(grupo => {
            const conteudo = [];
            conteudo.push(
              [
                { text: `${grupo.grupo}`, fontSize: 11, bold: true, alignment: 'left', margin: [0, 10, 0, 5] }, { text: `R$ ${this.funcaoService.convertToBrNumber(grupo.totalizadores['total'], 4)}`, fontSize: 11, bold: true, margin: [0, 10, 0, 5] }
              ]
            )
            tabelas.push(conteudo)
          })
          const content = []
          tabelas.forEach(t => {
            content.push(
              {
                layout: 'table_eddy',
                table: {
                  dontBreakRows: true,
                  headerRows: 1,
                  widths: ['*', 100],
                  body: t
                }
              }
            )
          })
          resolve(content)
        });
    })
  }

  private informacaoCota(item: Memorial): string {
    if (!item || !item.cota || item.cota === 'NAO_DEFINIDO') return ''
    if (item.cota === 'PRINCIPAL') return '(Cota Principal)'
    if (item.cota === 'RESERVADO') return '(Cota Reservada)'
  }

  public obterColunasOrdenacoes(): { nome: string, coluna: string }[] {
    return [
      { coluna: 'proponente.favorecido.nome,memorial.ordem', nome: 'Fornecedor' },
      { coluna: 'memorial.ordem', nome: 'Memorial' },
    ]
  }

  public async obterMemorial(id: number) {
    let itens = (await this.propostaService.filtrar(1, -1, { 'relations': 'memorial,proponente', 'memorial.licitacao_id': id, 'orderBy': 'memorial.ordem' }).toPromise()).content
    const prop = this.funcaoService.agrupar(itens, 'memorial.id');
    for (const p of prop) {
      let i: ItensSelected = {} as ItensSelected
      i.memorial = p.registros[0].memorial
      i.selecionado = false
      this.itensSelected.push(i)
    }
  }

  public ordenacaoLista(lista: any) {
    if (this.ordenacao === 'memorial.ordem') {
      lista
        .sort((a, b) => { return a.nome_favorecido.localeCompare(b.nome_favorecido) })
        .sort((a, b) => { return a.ordem - b.ordem })
    } else {
      lista
        .sort((a, b) => { return a.ordem - b.ordem })
        .sort((a, b) => { return a.nome_favorecido.localeCompare(b.nome_favorecido) })
    }
    return lista
  }

}