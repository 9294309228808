// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Despesa } from '../planejamento/despesa.model';
import { SubGrupoEstoque } from './sub-grupo-estoque.model';

export class SubGrupoSubElementoEstoque extends BaseResourceModel {
  constructor(
    public id?: number,
    public inativo?: boolean,
    public sub_grupo?: SubGrupoEstoque,
    public sub_elemento?: Despesa,
    public editavel?: boolean,
    public padrao?: boolean
  ) {
    super();
  }
  static converteJson(json: any): SubGrupoSubElementoEstoque {
    return Object.assign(new SubGrupoSubElementoEstoque(), json);
  }
}
