import { Component, OnDestroy, OnInit } from '@angular/core';
import { AcaoGovernoService, Anexo5Ldo, Anexo6Ldo, AnexoCompatibilidadeLdo, Demo1Ldo, Demo2Ldo, Demo3Ldo, Demo4Ldo, Demo5Ldo, Demo6Ldo, Demo7Ldo, Demo8Ldo, LdoService, MetaGovernoService, PpaLeiService, RiscosFiscaisProvidencias } from 'administrativo-lib';
import { FonteFinanciamentoService } from 'contabil-lib';
import { ExecutoraService, Exercicio, FuncaoService, GlobalService, LoginPublico, OrgaoService } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-transparencia-ldo',
  templateUrl: './transparencia-ldo.component.html'
})

export class TransparenciaLDOComponent implements OnInit, OnDestroy {
  public login: LoginPublico = new LoginPublico();
  public ano: number;
  public listaExercicios: Array<Exercicio>;
  public anexoSelect: string;
  public opt: number = 1;
  public listaBalancetes: Array<any>;
  public listaOrgaos: SelectItem[];
  public desconsiderarIndicadoresZeradosExercicio: boolean;
  private orgaos: number[] = [];
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected fonteService: FonteFinanciamentoService,
    protected metaService: MetaGovernoService,
    protected acaoService: AcaoGovernoService,
    protected executoraService: ExecutoraService,
    protected orgaoService: OrgaoService,
    protected ldoService: LdoService,
    protected ppaLeiService: PpaLeiService,
    protected funcaoService: FuncaoService) {
    this.login = GlobalService.obterSessaoLogin();
    this.ano = this.login.exercicio.ano;
    this.orgaos.push(this.login.orgao.id);
  }

  public ngOnInit() {
    this.orgaoService.filtrar(0, -1, { cidade_id: this.login.cidade.id, orderBy: 'codigo' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaOrgaos = [];
        for (const model of dados.content) {
          this.listaOrgaos.push({ label: `${model.codigo} - ${model.nome}`, value: model.id });
        }
      });
    this.listaExercicios = new Array();
    this.listaExercicios.push({ id: this.login.ppa.ppaperiodo.ano1, ano: this.login.ppa.ppaperiodo.ano1 });
    this.listaExercicios.push({ id: this.login.ppa.ppaperiodo.ano2, ano: this.login.ppa.ppaperiodo.ano2 });
    this.listaExercicios.push({ id: this.login.ppa.ppaperiodo.ano3, ano: this.login.ppa.ppaperiodo.ano3 });
    this.listaExercicios.push({ id: this.login.ppa.ppaperiodo.ano4, ano: this.login.ppa.ppaperiodo.ano4 });
    this.listaBalancetes = [
      { id: 'anexo5', nome: 'ANEXO V - DESCRIÇÃO DOS PROGRAMAS GOVERNAMENTAIS' },
      { id: 'anexo6', nome: 'ANEXO VI - UNIDADES EXECUTORAS E AÇÕES' },
      { id: 'demo1', nome: 'DEMONSTRATIVO I - METAS ANUAIS' },
      { id: 'demo2', nome: 'DEMONSTRATIVO II - AVALIAÇÃO DO CUMPRIMENTO DAS METAS FISCAIS DO EXERCÍCIO ANTERIOR' },
      { id: 'demo3', nome: 'DEMONSTRATIVO III - METAS FISCAIS COMPARADAS COM AS FIXADAS NOS TRÊS EXERCÍCIOS ANTERIORES' },
      { id: 'demo4', nome: 'DEMONSTRATIVO IV - EVOLUÇÃO DO PATRIMÔNIO LÍQUIDO' },
      { id: 'demo5', nome: 'DEMONSTRATIVO V - ORIGEM E APLICAÇÃO DOS RECURSOS OBTIDOS COM A ALIENAÇÃO DE ATIVOS' },
      { id: 'demo6', nome: 'DEMONSTRATIVO VI - AVALIAÇÃO DA SITUAÇÃO FINANCEIRA E ATUARIAL DO RPPS' },
      { id: 'demo7', nome: 'DEMONSTRATIVO VII - ESTIMATIVA E COMPENSAÇÃO DA RENÚNCIA DE RECEITA' },
      { id: 'demo8', nome: 'DEMONSTRATIVO VIII - MARGEM DE EXPANSÃO DAS DESPESAS OBRIGATÓRIAS DE CARÁTER CONTINUADO' },
      { id: 'demo90', nome: 'DEMONSTRATIVO COMPATIBILIDADE LOA E LDO' },
      { id: 'riscos', nome: 'DEMONSTRATIVO DE RISCOS FISCAIS E PROVIDÊNCIAS' },
    ];
  }

  public imprimir() {
    if (this.anexoSelect === 'anexo5' || this.anexoSelect === 'anexo6') {
      if (!this.orgaos || this.orgaos.length === 0) {
        toastr.warning('Selecione o(s) órgão(s) a serem impressos');
      }
    }

    if (this.anexoSelect === 'anexo5') {
      const anexo = new Anexo5Ldo(this.ano, this.metaService);
      anexo.montarRelatorio(this.orgaos);
    } else if (this.anexoSelect === 'anexo6') {
      const anexo = new Anexo6Ldo(this.ano, this.acaoService);
      anexo.montarRelatorio(this.orgaos);
    } else if (this.anexoSelect === 'demo1') {
      const demo = new Demo1Ldo(this.ano, this.ldoService, this.orgaos, this.ppaLeiService);
      demo.montarRelatorio();
    } else if (this.anexoSelect === 'demo2') {
      const demo = new Demo2Ldo(this.ano, this.ldoService, this.orgaos);
      demo.montarRelatorio();
    } else if (this.anexoSelect === 'demo3') {
      const demo = new Demo3Ldo(this.ano, this.ldoService, this.orgaos);
      demo.montarRelatorio();
    } else if (this.anexoSelect === 'demo4') {
      const demo = new Demo4Ldo(this.ano, this.ldoService, this.orgaos);
      demo.montarRelatorio();
    } else if (this.anexoSelect === 'demo5') {
      const demo = new Demo5Ldo(this.ano, this.ldoService, this.orgaos);
      demo.montarRelatorio();
    } else if (this.anexoSelect === 'demo6') {
      const demo = new Demo6Ldo(this.ano, this.ldoService, this.orgaos);
      demo.montarRelatorio();
    } else if (this.anexoSelect === 'demo7') {
      const demo = new Demo7Ldo(this.ano, this.ldoService, this.orgaos);
      demo.montarRelatorio();
    } else if (this.anexoSelect === 'demo8') {
      const demo = new Demo8Ldo(this.ano, this.ldoService, this.orgaos);
      demo.montarRelatorio();
    } else if (this.anexoSelect === 'demo90') {
      const demo = new AnexoCompatibilidadeLdo(this.ano, this.ldoService);
      demo.montarRelatorio();
    } else if (this.anexoSelect === 'riscos') {
      const riscos = new RiscosFiscaisProvidencias(this.ano, this.ldoService, this.orgaos);
      riscos.montarRelatorio();
    } else {
      toastr.warning('Selecione o anexo a ser impresso');
    }
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * Método para verificação de objetos, usados em combos `<select>`
   */
  public compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }
}
