import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Usuario } from '../comum/usuario.model';
import { LicitacaoAudesp } from '../licitacao/licitacao-audesp.model';
import { ContratoAditamentoItem } from './contrato-aditamento-item.model';
import { Contrato } from './contrato.model';
import { TipoAditivo } from './tipo-aditivo.model';

// tslint:disable: variable-name
export class ContratoAditamento extends BaseResourceModel {
  constructor(
    public numero?: string,
    public finalidade?: string,
    public data_aditamento?: Date,
    public data_inicio?: Date,
    public data_termino?: Date,
    public data_contabilizacao?: Date,
    public valor_servico?: number,
    public valor_material?: number,
    public valor_total?: number,
    public contrato?: Contrato,
    public usuario?: Usuario,
    public exercicio?: Exercicio,
    public tipo?: TipoAditivo,
    public licitacao_audesp?: LicitacaoAudesp,
    public itens?: ContratoAditamentoItem[],
    public editavel?: boolean,
    public cadastrado_pncp?: boolean,
    public sequencial_pncp?: number,
    public dias_aditamento?: number,
    public numero_parcelas?: number,
    public valor_aditado?: number,
    public mudanca_vigencia?: boolean,
    public mudanca_reajuste?: boolean,
    public mudanca_favorecido?: boolean,
  ) {
    super();
  }
  static converteJson(json: any): ContratoAditamento {
    return Object.assign(new ContratoAditamento(), json);
  }
}
