import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FuncaoService, Exercicio, FormatoExportacao } from 'eddydata-lib';

@Directive()
export class Anexo12DespesaFuncao implements OnDestroy {

  protected funcaoService: FuncaoService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number,
    protected exercicio: Exercicio) {
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(dados: any[], formato?: FormatoExportacao): {}[] {
    if (formato && formato !== 'pdf') return this.montarQuadroExportacao(dados);

    const registros: {}[] = [
      [
        {
          text: 'DESPESAS COM SAÚDE(Por SubFunção)',
          alignment: 'center', rowSpan: 2, bold: true, fontSize: 7, border: [true, false, true, true]
        },
        { text: 'DOTAÇÃO INICIAL', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: 'DOTAÇÃO ATUALIZADA (a)', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: 'DESPESAS EMPENHADAS', alignment: 'center', colSpan: 2, bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: '', border: [true, false, true, true] },
        { text: 'DESPESAS LIQUIDADAS', alignment: 'center', colSpan: 2, bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: '', border: [true, false, true, true] }
      ],
      ['', '', '',
        { text: 'ATÉ O BIMESTRE (l)', alignment: 'center', bold: true, fontSize: 7 },
        { text: '%((l/total)x100)', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'ATÉ O BIMESTRE (m)', alignment: 'center', bold: true, fontSize: 7 },
        { text: '%((m/total m)x100)', alignment: 'center', bold: true, fontSize: 7 }
      ]
    ];

    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;

    for (const item of dados) {
      total1 += +item.dotacao_inicial;
      total2 += +item.dotacao_atualizado;
      total3 += +item.empenhado;
      total4 += +item.liquidado;
    }

    for (const item of dados) {

      registros.push([
        {
          text: item.nome, fontSize: 7
        },
        { text: this.funcaoService.convertToBrNumber(item.dotacao_inicial), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(item.dotacao_atualizado), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(item.empenhado), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber((+item.empenhado / +total3) * 100), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(item.liquidado), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber((+item.liquidado / +total4) * 100), alignment: 'right', fontSize: 7 }
      ]);
    }

    registros.push([
      {
        text: 'TOTAL', fontSize: 7, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber((total3 / total3) * 100), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total4), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber((total4 / total4) * 100), alignment: 'right', fontSize: 7, bold: true }
    ]);

    return registros;
  }

  private montarQuadroExportacao(dados: any[]): {}[] {
    const listaItens = new Array();

    listaItens.push({
      'receita_desc': 'DESPESAS COM SAÚDE(Por SubFunção)',
      'inicial': 'DOTAÇÃO INICIAL',
      'atualizada': 'DOTAÇÃO ATUALIZADA (a)',
      'arrecadado_empenhada': 'DESPESAS EMPENHADAS',
      'calculo_empenhada': '',
      'liquidada': 'DESPESAS LIQUIDADAS',
      'calculo_liquidada': '',
    });

    listaItens.push({
      'receita_desc': '',
      'inicial': '',
      'atualizada': '',
      'arrecadado_empenhada': 'ATÉ O BIMESTRE (l)',
      'calculo_empenhada': '%((l/total)x100)',
      'liquidada': 'ATÉ O BIMESTRE (m)',
      'calculo_liquidada': '%((m/total m)x100)',
    });

    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;

    for (const item of dados) {
      total1 += +item.dotacao_inicial;
      total2 += +item.dotacao_atualizado;
      total3 += +item.empenhado;
      total4 += +item.liquidado;
    }

    for (const item of dados) {
      listaItens.push({
        'receita_desc': item.nome,
        'inicial': this.funcaoService.convertToBrNumber(item.dotacao_inicial, 2),
        'atualizada': this.funcaoService.convertToBrNumber(item.dotacao_atualizado, 2),
        'arrecadado_empenhada': this.funcaoService.convertToBrNumber(item.empenhado, 2),
        'calculo_empenhada': this.funcaoService.convertToBrNumber((+item.empenhado / +total3) * 100, 2),
        'liquidada': this.funcaoService.convertToBrNumber(item.liquidado, 2),
        'calculo_liquidada': this.funcaoService.convertToBrNumber((+item.liquidado / +total4) * 100, 2),
      });
    }

    listaItens.push({
      'receita_desc': 'TOTAL',
      'inicial': this.funcaoService.convertToBrNumber(total1, 2),
      'atualizada': this.funcaoService.convertToBrNumber(total2, 2),
      'arrecadado_empenhada': this.funcaoService.convertToBrNumber(total3, 2),
      'calculo_empenhada': this.funcaoService.convertToBrNumber((total3 / total3) * 100, 2),
      'liquidada': this.funcaoService.convertToBrNumber(total4, 2),
      'calculo_liquidada': this.funcaoService.convertToBrNumber((total4 / total4) * 100, 2),
    });

    listaItens.push({
      'receita_desc': ''
    });

    return listaItens;
  }
}
