import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { BaseResourceService } from '../../models/services/base-resource.service';
import { RelatorioPersonalizado } from '../../entidade/comum/relatorio-personalizado.model';
import { Sistemas, ModuloSistema } from '../../components/types';

@Injectable({
  providedIn: 'root'
})
export class RelatorioPersonalizadoService extends BaseResourceService<RelatorioPersonalizado> {

  constructor(
    protected injector: Injector
  ) {
    super(`relatorios-personalizados`, injector);
  }

  private obterHttp() {
    return {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    };
  }

  public obterModulos(sistema: Sistemas): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/modulos/${sistema}`, this.obterHttp()).pipe(
      map(res => res),
      catchError(err => { return this.handleError(err) })
    );
  }

  public obterMetadata(ms: ModuloSistema): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/metadata/${ms.sistema}/${ms.modulo}`, this.obterHttp()).pipe(
      map(res => res),
      catchError(err => { return this.handleError(err) })
    );
  }
}
