import { TipoContratacaoCondicaoDespesa } from "eddydata-lib";
import { BaseResourceModel } from "../../models/base-resource.model";
import { Orgao } from "../comum/orgao.model";
import { TipoContratacaoModalidade } from "./tipo-contratacao-modalidade.model";

export class TipoContratacao extends BaseResourceModel {
    constructor(
        public id?: number,
        public nome?: string,
        public tce?: number,
        public servico?: boolean,
        public material?: boolean,
        public locacao?: boolean,
        public concessao?: boolean,
        public exige_cno?: boolean,
        public tipo_pncp?: number,
        public orgao?: Orgao,
        public modalidades?: TipoContratacaoModalidade[],
        public editavel?: boolean,
        public condicoes_despsa?: TipoContratacaoCondicaoDespesa[]
    ) {
        super();
    }

    static converteJson(json: any): TipoContratacao {
        return Object.assign(new TipoContratacao(), json);
    }
}
