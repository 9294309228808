import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, FavorecidoContador, Page } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class FavorecidoContadorService extends BaseResourceService<FavorecidoContador> {

  constructor(
    protected injector: Injector
  ) {
    super(`favorecido-contadores`, injector);
  }

  public obterPorNome(filtroTexto: string, cidadeId: number, page?: number, limit?: number): Observable<Page> {
    if (!Number.isInteger(page) || !Number.isInteger(page)) {
      page = 1;
      limit = 1000;
    }
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?nome$like=${filtroTexto}%&cidade_id=${cidadeId}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public obterPorCpfCnpj(filtroTexto: string, cidadeId: number, page?: number, limit?: number): Observable<Page> {
    if (!Number.isInteger(page) || !Number.isInteger(page)) {
      page = 1;
      limit = 1000;
    }
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?cpf_cnpj$like=${filtroTexto}&cidade_id=${cidadeId}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
