import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { InplaceModule } from 'primeng/inplace';
import { ToastModule } from 'primeng/toast';
import { SharedModule, PessoaModule } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { AdiantamentoCadastroDlgComponent } from './adiantamento-cadastro-dlg.component';

@NgModule({
  declarations: [
    AdiantamentoCadastroDlgComponent
  ],
  exports: [ AdiantamentoCadastroDlgComponent ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    InplaceModule,
    PessoaModule,
  ],
  providers: [
    MessageService
  ]
})
export class AdiantamentoCadastroDlgModule { }
