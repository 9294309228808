import {
  Component, OnInit, OnChanges, ElementRef, Input,
  ViewChild, SimpleChanges, OnDestroy, ViewChildren, QueryList
} from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';

import { OrgaoSuplementacaoService } from '../service/orgao-suplementacao.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  Liquidacao, Login, GlobalService, FuncaoService, OrgaoSuplementacao, EddyAutoComplete, Exercicio, ExercicioService
} from 'eddydata-lib';

@Component({
  selector: 'lib-orgao-suplementacao',
  templateUrl: './orgao-suplementacao.component.html'
})
export class OrgaoSuplementacaoComponent implements OnInit, OnChanges, OnDestroy {

  public ptBR: any;
  public entity: OrgaoSuplementacao;
  public entityTemp: OrgaoSuplementacao;
  public listaSuplementacoes: Array<any>;
  public exercicioAutoComplete: EddyAutoComplete<Exercicio>;

  protected unsubscribe: Subject<void> = new Subject();

  @Input() lista: Array<any>;
  @Input() entidade: Liquidacao;
  @Input() login: Login;
  @Input() visualizar: boolean;

  @ViewChild('btnAdicionar') public btnAdicionar: ElementRef;
  @ViewChildren('btnsEditar') public btnsEditar: QueryList<ElementRef>;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected anoService: ExercicioService,
    protected orgaoSuplementacaoService: OrgaoSuplementacaoService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.listaSuplementacoes = this.lista;
    // autocomplete para exercicio
    this.exercicioAutoComplete = new EddyAutoComplete(null, this.anoService,
      'id', ['ano'], { cidade_id: this.login.cidade.id, orderBy: 'ano' }, { number: ['ano'] }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lista) {
      this.listaSuplementacoes = this.lista;
    }
  }

  private desabilitarEdicao(desabilitar: boolean) {
    this.btnsEditar.forEach((btn) => btn.nativeElement.disabled = desabilitar)
    this.btnAdicionar.nativeElement.disabled = desabilitar;
  }

  public adicionar() {
    this.entity = new OrgaoSuplementacao();
    this.entity.orgao = this.entidade;
    this.entity.editavel = true;
    this.entity.exercicio = this.login.exercicio;
    this.entity['salvo'] = false;
    if (!this.listaSuplementacoes) {
      this.listaSuplementacoes = new Array();
    }
    this.listaSuplementacoes.unshift(this.entity);
    this.desabilitarEdicao(true);

    setInterval(() => new GlobalService().calendarMascara(), 500)
  }

  public salvar(item: OrgaoSuplementacao) {
    try {

      if (+item.limite_prudencial <= 0) {
        throw new Error('Informe o limite prudencial!')
      }

      if (+item.percentual_suplementacao <= 0) {
        throw new Error('Informe o limite percentual para suplementação!')
      }

      item.editavel = false;
      item['salvo'] = true;
      this.desabilitarEdicao(false);

    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  public editar(item: OrgaoSuplementacao) {
    this.entityTemp = JSON.parse(JSON.stringify(item));

    this.entity = item;
    this.entity.editavel = true;
    this.entity['salvo'] = true;
    this.desabilitarEdicao(true);
  }

  public cancelar(item: OrgaoSuplementacao, index: number) {
    if (item['salvo'] && item.id === this.entityTemp?.id) {
      item.limite_prudencial = this.entityTemp.limite_prudencial;
      item.percentual_suplementacao = this.entityTemp.percentual_suplementacao;
      this.entity = item;
      this.entity.editavel = false;
    } else {
      this.listaSuplementacoes.splice(index, 1);
    }
    this.desabilitarEdicao(false);
  }

  public confirmarRemocao(item: OrgaoSuplementacao, index: number) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja remover item?',
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.remover(item, index)
      },
    });
  }

  private remover(item: OrgaoSuplementacao, index: number) {
    if (item.id) {
      this.orgaoSuplementacaoService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaSuplementacoes.splice(index, 1);
          this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Exclusão', detail: error.error.payload });
          throw error;
        });
    } else {
      this.listaSuplementacoes.splice(index, 1);
    }
  }

  public podeVisualizar() {
    if (this.visualizar === true) {
      return true;
    }
    if (this.visualizar === false) {
      return false;
    }
  }

}


