import { DatePipe } from '@angular/common';
import * as extenso from 'extenso';
import { FuncaoService, LoginContabil, Relatorio, Login, Adiantamento, OrgaoAssinaturaService } from 'eddydata-lib';

export class NotaAdiantamento {

  protected datepipe: DatePipe;
  protected funcaoService: FuncaoService;

  private log: LoginContabil;

  private prefeito: string;
  private cargo_prefeito: string;
  private contador: string;
  private cargo_contador: string;

  constructor(protected assinaturaService: OrgaoAssinaturaService) {
    this.funcaoService = new FuncaoService();
    this.datepipe = new DatePipe('pt');
  }

  public async imprimir(model: Adiantamento[], login: LoginContabil) {
    this.log = login;

    const assinatura = await this.assinaturaService.obter({
      orgao_id: this.log.orgao.id,
      'data_limite$ge': String(this.funcaoService.converteDataSQL(new Date().toLocaleDateString()))
    }).toPromise();
    if (assinatura) {
      this.prefeito = assinatura.ordenador_despesa;
      this.cargo_prefeito = assinatura.cargo_ordenador_despesa;

      this.contador = assinatura.contador;
      this.cargo_contador = assinatura.cargo_contador;
    }

    Relatorio.imprimirPersonalizado('NOTA DE ADIANTAMENTO', login.usuario.nome, login.usuario.sobrenome,
      login.orgao.nome, login.brasao,
      this.montarConteudo(model),
      'portrait', 'NOTA DE ADIANTAMENTO',
      {
        linhas: {
          hLineWidth() {
            return 1;
          },
          vLineWidth() {
            return 1;
          },
          hLineColor() {
            return 'black';
          },
          paddingLeft() {
            return 3;
          },
          paddingRight() {
            return 3;
          }
        }
      }, false);
  }

  private montarConteudo(lista: Adiantamento[]) {

    const conteudo = [];

    for (const entidade of lista) {
      if (conteudo.length > 0) {
        conteudo.push([{ text: '', pageBreak: 'after' }]);
      }
      conteudo.push(this.dadosCabecalho(this.log)
        .concat(this.dadosAdiantamento(entidade))
        .concat(this.dadosClassificacao(entidade))
        .concat(this.dadosFavorecido(entidade))
        .concat(this.dadosHistorico(entidade))
        //.concat(this.dadosAssinatura(this.log))
        //.concat(this.dadosRecibo())
        );
    }
    return conteudo;
  }

  private dadosCabecalho(log: Login): {}[] {
    let brasaoImage: {};
    if (log.brasao) {
      brasaoImage = {
        image: log.brasao,
        width: 70,
        alignment: 'center',
        margin: [0, 30, 0, 0]
      };
    } else {
      brasaoImage = { margin: [0, 30, 0, 0], text: '' };
    }
    const conteudo = [
      [{ text: brasaoImage, border: [true, true, true, false] }],
      [{
        text: `EXERCÍCIO - ${log.exercicio.ano}`,
        bold: true, alignment: 'center', fontSize: 12, border: [true, false, true, false]
      }]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosAdiantamento(dados: Adiantamento): {}[] {
    const conteudo = [[{
      text: 'PROCESSO N˚ ' + this.funcaoService.strZero(dados.numero, 4), rowSpan: 2, fontSize: 12,
      alignment: 'left', bold: true, margin: [0, 10, 10, 10], border: [true, true, false, false]
    },
    {
      text: 'Data Início', fontSize: 7, border: [true, true, false, false],
    },
    {
      text: 'Data Vencimento', fontSize: 7, border: [true, true, false, false],
    },
    {
      text: 'Data Liberação', fontSize: 7, border: [true, true, true, false],
    }],
    [
      { text: '' },
      {
        text: this.datepipe.transform(dados.data_adiantamento, 'dd/MM/yyyy'),
        alignment: 'center', bold: true, border: [true, false, false, false]
      },
      {
        text: this.datepipe.transform(dados.data_vencimento, 'dd/MM/yyyy'),
        alignment: 'center', bold: true, border: [true, false, false, false]
      },
      {
        text: this.datepipe.transform(dados.data_liberacao, 'dd/MM/yyyy'),
        alignment: 'center', bold: true, border: [true, false, true, false]
      }
    ]];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [180, 100, 100, '*'],
        body: conteudo
      }
    }];
  }

  private dadosClassificacao(dados: Adiantamento): {}[] {
    const conteudo = [
      [
        { text: 'UNIDADE', border: [true, true, false, false] },
        {
          text: `${dados.empenho?.ficha?.executora?.unidade?.codigo} ${dados.empenho?.ficha?.executora?.unidade?.nome}`,
          border: [false, true, true, false]
        }
      ],
      [
        { text: 'EXECUTORA', border: [true, false, false, false] },
        {
          text: `${dados.empenho?.ficha?.executora?.codigo} ${dados.empenho?.ficha?.executora?.nome}`,
          border: [false, false, true, false]
        }
      ],
      [
        { text: 'NATUREZA', border: [true, false, false, false] },
        {
          text: `${dados.empenho?.ficha?.despesa?.codigo} ${dados.empenho?.ficha?.despesa?.nome}`,
          border: [false, false, true, false]
        }
      ],
      [
        { text: 'SUBELEMENTO', border: [true, false, false, false] },
        {
          text: `${dados.empenho?.subelemento?.codigo} ${dados.empenho?.subelemento?.nome} `,
          border: [false, false, true, false]
        },
      ],
      [
        { text: 'FUNCIONAL', border: [true, false, false, false] },
        {
          // tslint:disable-next-line: max-line-length
          text: `${dados.empenho?.ficha?.funcao?.codigo}.${dados.empenho?.ficha?.subfuncao?.codigo}.${dados.empenho?.ficha?.programa?.codigo} ${dados.empenho?.ficha?.programa?.nome} `,
          border: [false, false, true, false]
        }
      ],
      [
        { text: 'AÇÃO', border: [true, false, false, false] },
        {
          text: `${dados.empenho?.ficha?.acao?.codigo} ${dados.empenho?.ficha?.acao?.nome} `,
          border: [false, false, true, false]
        }
      ],
      [
        { text: 'RECURSO', border: [true, false, false, true] },
        {
          text: `${dados.empenho?.ficha?.aplicacao?.codigo} ${dados.empenho?.ficha?.aplicacao?.nome} `,
          border: [false, false, true, true]
        }
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [60, '*'],
        body: conteudo
      }
    }];
  }

  private dadosFavorecido(dados: Adiantamento): {}[] {
    const conteudo = [
      [
        { text: 'BENEFICIÁRIO:', colSpan: 3, border: [true, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] },
        { text: '', border: [false, false, true, false] }
      ],
      [
        {
          text: `${dados.empenho?.favorecido?.nome}`, colSpan: 3,
          fontSize: 11, bold: true, border: [true, false, false, false]
        },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] },
        { text: '', border: [false, false, true, false] },
      ],
      [
        { text: 'CPF/CNPJ', border: [true, false, false, false] },
        { text: `${dados.empenho?.favorecido?.cpf_cnpj}`, border: [false, false, false, false] },
        { text: 'TIPO PESSOA', border: [false, false, false, false] },
        { text: dados.empenho?.favorecido?.tipo, border: [false, false, true, false] },
      ],
      [
        { text: 'ENDEREÇO', border: [true, false, false, false] },
        {
          // tslint:disable-next-line: max-line-length
          text: `${dados.empenho?.favorecido?.endereco}, ${dados.empenho?.favorecido?.num_endereco} ${dados.empenho?.favorecido?.bairro}`,
          border: [false, false, false, false]
        },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] },
      ],
      [
        { text: 'CIDADE', border: [true, false, false, true] },
        {
          text: `${dados.empenho?.favorecido?.municipio}, ${dados.empenho?.favorecido?.uf}`,
          border: [false, false, false, true]
        },
        { text: 'TELEFONE', border: [false, false, false, true] },
        {
          text: `(${dados.empenho?.favorecido?.ddd_fone ? dados.empenho?.favorecido?.ddd_fone : ''}) ${dados.empenho?.favorecido?.telefone}`,
          border: [false, false, true, true]
        },
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: [60, '*', 60, 100],
        body: conteudo
      }
    }];
  }


  private dadosHistorico(dados: Adiantamento): {}[] {
    const conteudo = [
      [
        { text: 'Histórico:', border: [true, false, true, false], colSpan: 3 },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] }
      ],
      [
        {
          text: `${dados.motivo}`, border: [true, false, true, true], colSpan: 3
        },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] }
      ],
      [
        { text: 'Fica empenhado a importância de: ', border: [true, false, false, false], colSpan: 2 },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] }
      ],
      [
        {
          text: extenso(this.funcaoService.convertToBrNumber(dados.valor_adiantado),
            { mode: 'currency', currency: { type: 'BRL' } }).toUpperCase(), fontSize: 13,
          border: [true, false, false, true], margin: [0, 0, 0, 20]
        },
        { text: 'VALOR R$:', bold: true, fontSize: 12, border: [false, false, false, true], alignment: 'right' },
        {
          text: this.funcaoService.convertToBrNumber(dados.valor_adiantado),
          alignment: 'center', bold: true, fontSize: 13, border: [false, false, true, true], margin: [0, 0, 0, 20]
        },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [15, 120],
        widths: ['*', 60, 100],
        body: conteudo
      }
    }];
  }

  private dadosAssinatura(log: Login): {}[] {
    const conteudo = [
      [
        { text: 'AUTORIZAÇÃO PAGAMENTO', border: [true, false, false, false], colSpan: 2 },
        { text: '', border: [true, false, false, false], },
        { text: '______________________________________', border: [false, false, false, false], margin: [0, 30, 0, 0] },
        { text: '______________________________________', border: [false, false, false, false], margin: [0, 30, 0, 0] },
        { text: '', border: [false, false, true, false] },
      ],
      [
        { text: '', border: [true, false, false, false], margin: [0, 0, 0, 5] },
        { text: '', border: [false, false, false, false], margin: [0, 0, 0, 5] },
        { text: this.contador, border: [false, false, false, false], margin: [0, 0, 0, 5] },
        { text: this.prefeito, border: [false, false, false, false], margin: [0, 0, 0, 5] },
        { text: '', border: [false, false, true, false] },
      ],
      [
        { text: 'Data: _____/_____/________', border: [true, false, false, true], colSpan: 2 },
        { text: '', border: [false, false, false, false], },
        { text: this.cargo_contador, border: [false, false, false, true], margin: [0, 0, 0, 10] },
        { text: this.cargo_prefeito, border: [false, false, false, true], margin: [0, 0, 0, 10] },
        { text: '', border: [false, false, true, true] },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [10, 0],
        widths: ['*', 0, 150, 150, '*'],
        body: conteudo
      }
    }];
  }

  private dadosRecibo(): {}[] {
    const conteudo = [
      [
        { text: 'RECIBO E QUITAÇÃO', border: [true, false, false, false], colSpan: 2 },
        { text: '', border: [false, false, false, false], },
        { text: '', border: [false, false, false, false], },
        { text: '', border: [false, false, true, false], },
      ],
      [
        {
          text: 'Recebemos o valor a que se refere este empenho, ao qual damos plena e geral quitação.',
          border: [true, false, false, false], colSpan: 2, margin: [0, 0, 20, 0]
        },
        { text: '', border: [false, false, false, false], },
        { text: 'Assinatura', border: [false, false, false, false], },
        { text: 'Documento', border: [false, false, true, false], },
      ],
      [
        { text: 'Data: _____/_____/________', border: [true, false, false, true], colSpan: 2 },
        { text: '', border: [false, false, false, true], },
        { text: '__________________________', border: [false, false, false, true], margin: [0, 0, 0, 10] },
        { text: '__________________________', border: [false, false, true, true], margin: [0, 0, 0, 10] },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [10, 0],
        widths: ['*', '*', '*', '*'],
        body: conteudo
      }
    }];
  }

}
