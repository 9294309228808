import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { BaseResourceService, AcaoGoverno } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class AcaoGovernoService extends BaseResourceService<AcaoGoverno> {

  constructor(
    protected injector: Injector
  ) {
    super(`acoes-governo`, injector);
  }

  public anexo3(ppa: number, orgaos: number[], opt:number, ano:number, desconsiderarIndicadoresZeradosExercicio:boolean): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.login.cidade.id}/${this.api}/anexo3/${ppa}/${orgaos.join()}/${opt}/${ano}/${desconsiderarIndicadoresZeradosExercicio}`,
      this.httpOptions()).pipe(
        map(res => res),
        take(1),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorNumero(numero: number, ppaId: number): Observable<AcaoGoverno> {
    return this.http.get<AcaoGoverno>(
      `${this.login.cidade.id}/${this.api}/filtrar?numero=${numero}&ppa_id=${ppaId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
