import { Licitacao, Rpl } from 'eddydata-lib';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Rcms } from './rcms.model';

export class RcmsJustificativaDesbloqueioAutomatico extends BaseResourceModel {
  constructor(
    public id?: number,
    public questao_1_licitacao?: Licitacao,
    public questao_2_licitacao?: Licitacao,
    public questao_3?: string,
    public questao_4_rpl?: Rpl,
    public pontuacao?: number,
    public responsavel_nome?: string,
    public responsavel_cpf?: string,
    public rcms?: Rcms,
  ) {
    super();
  }
  static converteJson(json: any): RcmsJustificativaDesbloqueioAutomatico {
    return Object.assign(new RcmsJustificativaDesbloqueioAutomatico(), json);
  }
}
