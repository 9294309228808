import { Directive, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuncaoService } from '../util/funcao.service';
import { Page } from '../util/page';
import { BaseResourceModel } from './base-resource.model';
import { BaseResourceService } from './services/base-resource.service';

@Directive()
export class EddyAutoComplete<T extends BaseResourceModel> implements OnDestroy {

  public lista: Array<T>;
  public id: number | string;
  public disabled: boolean;
  protected unsubscribe: Subject<void> = new Subject();
  public limite = 10;
  public userExtendido = false;

  constructor(
    private campoForm: AbstractControl,
    private service: BaseResourceService<T>,
    public campoChave: string,
    public campoLabel: string[],
    private parametros: {},
    private filtros: {
      number?: string[];
      date?: string[];
      text?: string[];
    },
    funcaoOnSelect?: any,
    limite?: any,
    private conversorPersonalizado?: (entidade: T) => {},
    private filtrarPersonilizado?: (pagina: number, limite: number, filtros: {}, filtroStr: string, service?: BaseResourceService<T>) => Observable<Page> // deve retornar um Promise<Page>,
  ) {
    if (funcaoOnSelect) {
      this.funcaoOnSelect = funcaoOnSelect;
    }
    if (limite) {
      this.limite = limite;
    }
    if (campoForm && campoForm.value)
      this.id = campoForm.value[campoChave];
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public funcaoOnSelect = () => {
    // deverá ser sobrescrita nas classes filhas
  }

  public filtrar(event: any) {
    let filtro: string = String(event.query).trim();

    const parametrosCampo = new FuncaoService().obterParametros(this.filtros, filtro);
    if (!this.disabled)
      this.filtrarService(1, this.limite, Object.assign({}, this.parametros, parametrosCampo), filtro)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(lista => { this.lista = lista.content; }
        );

  }

  public async atualizar(campoCodigo: boolean, model?: { ngModel: T, field: string }) {
    await new Promise(resolve => {
      if (campoCodigo) {
        const entidade: any = model ? model.ngModel[model.field] : this.campoForm?.value;
        if (entidade) {
          this.id = entidade[this.campoChave];
          resolve(true);
        } else {
          this.id = null;
          resolve(true);
        }
      } else {
        if (this.id && !this.disabled) {
          this.filtrarService(1, this.limite, Object.assign({}, this.parametros, { [this.campoChave]: this.id }), '')
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(dados => {
              if (model)
                model.ngModel[model.field] = dados.content[0];
              else
                this.campoForm.setValue(dados.content[0]);
              resolve(true);
            }
            );
        } else {
          resolve(true);
        }
      }
    });
    this.funcaoOnSelect();
  }

  public conversor = (entidade: T) => {
    let retorno = '';

    if (this.conversorPersonalizado)
      return this.conversorPersonalizado(entidade);

    for (const campo of this.campoLabel) {
      let valor: any;
      const campoSplit = campo.split('.');
      for (const coluna of campoSplit) {
        if (!valor) {
          valor = entidade[coluna];
        } else {
          valor = valor[coluna];
        }
      }
      if (valor)
        retorno = retorno.concat(valor).concat(' - ');
    }

    if (retorno.length > ' - '.length)
      return retorno.substring(0, retorno.length - ' - '.length);
    else
      return retorno;
  }

  public onDropDownClick(event: any) {
    event.query = '';
    this.campoForm.setValue(null);
    this.id = null;
  }

  private filtrarService(pagina: number, limite: number, filtros: {}, filtroStr: string): Observable<Page> {
    if (!this.filtrarPersonilizado)
      if (this.userExtendido)
        return this.service.extendido(pagina, limite, filtros);
      else
        return this.service
          .filtrar(pagina, limite, filtros);
    else
      return this.filtrarPersonilizado(pagina, limite, filtros, filtroStr, this.service);
  }
}
