import { Directive, OnDestroy } from '@angular/core';
import { Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Orgao, OrgaoAssinaturaService, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseResourceNotaExplicativa } from '../base-resource-nota-explicativa';
import { BalancoService } from '../service/balanco.service';
import { NotaExplicativaService } from '../service/nota-explicativa.service';

@Directive()
export class Anexo14BBalanco extends BaseResourceNotaExplicativa implements OnDestroy {

  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected balancoServico: BalancoService,
    protected exercicio: Exercicio,
    protected notaService: NotaExplicativaService,
    protected assinaturaService: OrgaoAssinaturaService
  ) {
    super('B14B', notaService, assinaturaService)
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(exercicio: Exercicio, orgaos: number[], listaOrgaos: Orgao[], mes?: number, formato?: FormatoExportacao) {
    formato = formato ?? 'pdf';
    this.balancoServico.obterBalancoPatrimonialB(this.exercicio.id, orgaos, mes)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        const orgao = orgaos.length === 1 ? listaOrgaos.filter(o => o.id === orgaos[0])[0] : listaOrgaos.filter(o => o.id === 1)[0];
        const consolidado = orgaos.length === 1 ? orgao.nome : 'Consolidado';
        let orgaoNomes: string[] = [];
        for (const o of listaOrgaos) {
          if (orgaos.some(orgao => orgao === o.id)) {
            orgaoNomes.push(`${o.codigo} - ${o.nome}`);
          }
        }
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(
            `ANEXO 14 B - QUADRO DEMONSTRATIVO DAS CONTAS ANALÍTICAS DO ATIVO E PASSIVO PERMANENTE`,
            this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            this.cabecalhoRelatorio(orgao, exercicio, mes)
              .concat(this.conteudo(dados, exercicio))
              .concat(await this.conteudoNotaExplicativa())
              .concat(await this.conteudoAssinatura(this.login.orgao, orgaoNomes)),
            'portrait', 'ANEXO 14 B - QUADRO DEMONSTRATIVO DAS CONTAS ANALÍTICAS DO ATIVO E PASSIVO PERMANENTE',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, false, true, 'pdf', `Unidade Gestora: ${consolidado.toUpperCase()}`);
        } else if (formato === 'csv') {
          this.exportacaoCsv(dados);
        }
      });
  }

  private cabecalhoRelatorio(orgao: Orgao, exercicio: Exercicio, mes?: number): {}[] {
    const registros = [
      [
        { text: `Município:`, alignment: 'left', fontSize: 8 },
        { text: orgao.cidade?.nome ? orgao.cidade.nome : this.login.cidade.nome, alignment: 'center', fontSize: 8 },
        { text: `Exercício:`, alignment: 'left', fontSize: 8 },
        { text: exercicio.ano, alignment: 'center', fontSize: 8 }
      ], [
        { text: `Poder:`, alignment: 'left', fontSize: 8 },
        { text: orgao.especie === 'C' ? 'PODER LEGISLATIVO' : 'PODER EXECUTIVO', alignment: 'center', fontSize: 8 },
        { text: `Mês:`, alignment: 'left', fontSize: 8 },
        { text: mes ? this.globalService.obterMes(+mes) : 'ANUAL', alignment: 'center', fontSize: 8 }
      ], [
        { text: `Órgão:`, alignment: 'left', fontSize: 8, border: [true, true, true, false] },
        { text: orgao.nome, alignment: 'center', fontSize: 8, border: [true, true, true, false] },
        { text: `Acumulado/Mensal:`, alignment: 'left', fontSize: 8, border: [true, true, true, false] },
        { text: mes ? 'MENSAL' : 'ACUMULADO', alignment: 'center', fontSize: 8, border: [true, true, true, false] }
      ], [
        { text: 'Em R$ 1,00', bold: true, alignment: 'right', colSpan: 4, border: [false, true, false, false] },
        '',
        '',
        '',
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*', '*', '*', '*'],
        body: registros
      }
    }];
  }

  private conteudo(dados: any[], exercicio: Exercicio): {}[] {
    // monta o cabecalho
    const registros: {}[] = [
      [
        {
          text: 'ATIVO PERMANENTE',
          alignment: 'center',
          colSpan: 3,
        }, '', ''
      ],
      [{
        text: 'TÍTULOS',
        alignment: 'center',
        fontSize: 7,
        bold: true,
        rowSpan: 2
      }, {
        text: 'VALOR',
        alignment: 'center',
        fontSize: 7,
        bold: true,
        colSpan: 2
      }, {
        text: '',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }],
      [
        { text: '' },
        {
          text: 'Exercício Atual',
          alignment: 'center',
          fontSize: 7,
          bold: true,
        }, {
          text: 'Exercício Anterior',
          alignment: 'center',
          fontSize: 7,
          bold: true,
        }
      ]
    ];

    let col2 = 0;
    let col3 = 0;
    for (const item of dados[0]) {
      registros.push([
        {
          text: item.nome, bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(
            item.valor_atual), alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(
            item.valor_anterior), alignment: 'right', bold: true, fontSize: 7
        }
      ]);

      col2 += +item.valor_atual;
      col3 += +item.valor_anterior;
    }

    registros.push([{
      text: 'TOTAL', bold: true,
      alignment: 'left', fontSize: 7
    }, {
      text: this.funcaoService.convertToBrNumber(col2),
      alignment: 'right', bold: true, fontSize: 7
    }, {
      text: this.funcaoService.convertToBrNumber(col3),
      alignment: 'right', bold: true, fontSize: 7
    }]);

    registros.push([{
      text: '', colSpan: 3, margin: [10, 10, 10, 10], border: [false, false, false, false]
    }, '', '']);

    registros.push(
      [
        {
          text: 'PASSIVO PERMANENTE',
          alignment: 'center',
          colSpan: 3, margin: [5, 5, 5, 5]
        }, '', ''
      ],
      [{
        text: 'TÍTULOS',
        alignment: 'center',
        fontSize: 7,
        bold: true,
        rowSpan: 2
      }, {
        text: 'VALOR',
        alignment: 'center',
        fontSize: 7,
        bold: true,
        colSpan: 2
      }, {
        text: '',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }],
      [
        { text: '' },
        {
          text: 'Exercício Atual',
          alignment: 'center',
          fontSize: 7,
          bold: true,
        }, {
          text: 'Exercício Anterior',
          alignment: 'center',
          fontSize: 7,
          bold: true,
        }
      ]
    );
    col2 = 0;
    col3 = 0;
    const grupos = this.funcaoService.agrupar(dados[1], ['grupo'], ['valor_atual', 'valor_anterior']);
    for (const item of grupos) {
      registros.push([
        {
          text: item.grupo['grupo'], bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(
            item.totalizadores['valor_atual']), alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(
            item.totalizadores['valor_anterior']), alignment: 'right', bold: true, fontSize: 7
        }
      ]);

      col2 += +item.totalizadores['valor_atual'];
      col3 += +item.totalizadores['valor_anterior'];
      for (const it of item.registros) {
        if (it['nome'] !== '') {
          registros.push([
            {
              text: it['nome'], fontSize: 8
            },
            {
              text: this.funcaoService.convertToBrNumber(it['valor_atual']), alignment: 'right',
              fontSize: 8
            },
            {
              text: this.funcaoService.convertToBrNumber(it['valor_anterior']), alignment: 'right',
              fontSize: 8
            }
          ]);
        }
      }

    }

    registros.push([{
      text: 'TOTAL', bold: true,
      alignment: 'left', fontSize: 7
    }, {
      text: this.funcaoService.convertToBrNumber(col2),
      alignment: 'right', bold: true, fontSize: 7
    }, {
      text: this.funcaoService.convertToBrNumber(col3),
      alignment: 'right', bold: true, fontSize: 7
    }]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 120, 120],
        body: registros
      }
    }];
  }

  private exportacaoCsv(dados: any[]) {

    // monta o cabecalho
    const registros: {}[][] = [
      [{ text: 'ATIVO PERMANENTE' }],
      [
        { text: 'TÍTULOS' },
        { text: 'Valor Exercício Atual' },
        { text: 'Valor Exercício Anterior' }
      ]
    ];

    let col2 = 0;
    let col3 = 0;
    for (const item of dados[0]) {
      registros.push([
        { text: item.nome },
        { text: this.funcaoService.convertToBrNumber(item.valor_atual) },
        { text: this.funcaoService.convertToBrNumber(item.valor_anterior) }
      ]);

      col2 += +item.valor_atual;
      col3 += +item.valor_anterior;
    }

    registros.push([
      { text: 'TOTAL' },
      { text: this.funcaoService.convertToBrNumber(col2) },
      { text: this.funcaoService.convertToBrNumber(col3) }
    ]);
    registros.push([{ text: '' }]);
    registros.push([{ text: 'PASSIVO PERMANENTE' }]);
    registros.push([
      { text: 'TÍTULOS' },
      { text: 'Valor Exercício Atual' },
      { text: 'Valor Exercício Anterior'}
    ]);

    col2 = 0;
    col3 = 0;
    const grupos = this.funcaoService.agrupar(dados[1], ['grupo'], ['valor_atual', 'valor_anterior']);
    for (const item of grupos) {
      registros.push([
        { text: item.grupo['grupo'] },
        { text: this.funcaoService.convertToBrNumber(item.totalizadores['valor_atual']) },
        { text: this.funcaoService.convertToBrNumber(item.totalizadores['valor_anterior']) }
      ]);

      col2 += +item.totalizadores['valor_atual'];
      col3 += +item.totalizadores['valor_anterior'];
      for (const it of item.registros) {
        if (it['nome'] !== '') {
          registros.push([
            { text: it['nome'] },
            { text: this.funcaoService.convertToBrNumber(it['valor_atual']) },
            { text: this.funcaoService.convertToBrNumber(it['valor_anterior']) }
          ]);
        }
      }
    }

    registros.push([
      { text: 'TOTAL' },
      { text: this.funcaoService.convertToBrNumber(col2) },
      { text: this.funcaoService.convertToBrNumber(col3) }
    ]);

    dados = null;
    let csv = '';
    for (let i=0; i<registros.length; i++) {
      const linha = registros[i];
      if (i > 0) csv += '\n';
      for (let x=0; x<linha.length; x++) {
        if (x > 0) csv += ';';
        csv += String(linha[x]['text']);
      }
    }

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + csv));
    element.setAttribute("download", `ANEXO 14 B - QUADRO DEMONSTRATIVO DAS CONTAS ANALÍTICAS DO ATIVO E PASSIVO PERMANENTE.csv`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

}
