// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { ProdutoUnidade } from './produto-unidade.model';
import { SetorAlmoxarifado } from './setor-almoxarifado.model';

export class PosicaoEstoque extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_cadastro?: Date,
    public saldo?: number,
    public custo_medio?: number,
    public consumo_medio?: number,
    public setor_almoxarifado?: SetorAlmoxarifado[],
    public produto_unidade?: ProdutoUnidade[],
    public exercicio?: Exercicio[],
  ) {
    super();
  }
  static converteJson(json: any): PosicaoEstoque {
    return Object.assign(new PosicaoEstoque(), json);
  }
  
}
