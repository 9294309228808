// tslint:disable: variable-name
import { ReservaDotacaoItem } from 'eddydata-lib';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { Licitacao } from '../licitacao/licitacao.model';

export class ReservaDotacao extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_cancelamento?: Date,
    public data_adjudicacao?: Date,
    public valor_adjudicacao?: number,
    public processo?: string,
    public tipo_reserva?: string,
    public observacao?: string,
    public ativo?: boolean,
    public declaracao?: boolean,
    public convenio_estadual?: boolean,
    public convenio_federal?: boolean,
    public tesouro?: boolean,
    public fundo_especial?: boolean,
    public operacao_credito?: boolean,
    public outra_fonte?: boolean,
    public administracao_indireta?: boolean,
    public outro_observacao?: string,
    public data_declaracao?: Date,
    public licitacao?: Licitacao,
    public orgao?: Orgao,
    public exercicio?: Exercicio,
    public itens?: ReservaDotacaoItem[]

    ) {
    super();
  }

  static converteJson(json: any): ReservaDotacao {
    return Object.assign(new ReservaDotacao(), json);
  }
}
