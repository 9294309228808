import { BaseResourceModel } from '../../models/base-resource.model';
import { GrupoReceita } from './grupo-receita.model';
import { Receita } from '../planejamento/receita.model';

export class GrupoReceitaItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public grupo?: GrupoReceita,
    public receita?: Receita,
    public editavel?: boolean
  ) {
    super();
  }
  static converteJson(json: any): GrupoReceitaItem {
    return Object.assign(new GrupoReceitaItem(), json);
  }
}