import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { PlanoContaService } from 'contabil-lib';
import { BaseResourceFormComponent, DateFormatPipe, EddyAutoComplete, FuncaoService, GlobalService, Imovel, ImovelOcorrencia, ImovelStorage, LoginContabil, PlanoConta, Setor, SetorService, SituacaoImovel, Usuario, UsuarioService, ViaCEPService } from 'eddydata-lib';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ImovelStorageService } from '../service/bem-imovel-storage.service';
import { BemImovelService } from '../service/bem-imovel.service';
import { TableFields } from '../../../../../patrimonio/src/app/gerenciamento-imoveis/gerenciamento-imoveis-list/gerenciamento-imoveis-list.component';

@Component({
  selector: 'app-bem-imovel-form',
  templateUrl: './bem-imovel-form.component.html'
})
export class BemImovelFormComponent extends BaseResourceFormComponent<Imovel, LoginContabil> {
  /**
   * Declaração de variáveis
   */

  @ViewChild('sigla') inputSigla: ElementRef;

  public setorAutoComplete: EddyAutoComplete<Setor>;
  public planoContaAutoComplete: EddyAutoComplete<PlanoConta>;
  public usuarioAutoComplete: EddyAutoComplete<Usuario>;
  public ocorrencias: ImovelOcorrencia[] = [];
  public listaArquivos: ImovelStorage[];
  public listaFotos: ImovelStorage[];
  public fotoPrincipal: ImovelStorage;
  public listaSituacao: { id: SituacaoImovel, nome: string, estilo: string }[];
  public imovelDescricaoDuplicada: Imovel;
  public imoveis: Imovel[] = [];

  public imaskConfigValorComVirgula = {
    mask: Number,
    scale: 2,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  public imaskConfigValorSemVirgula = {
    mask: Number,
    // scale: 0,
    signed: true,
    thousandsSeparator: '.',
    padFractionalZeros: false,
    normalizeZeros: true,
    radix: ','
  };

  public tableFields: TableFields[] = [
    { label: 'Data de Inclusão', field: 'data_inclusao', style: 'text-align: center' },
    { label: 'Tipo', field: 'tipo', style: 'text-align: center' },
    { label: 'Imóvel', field: 'imovel_id', style: 'text-align: center' }
  ];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    public globalService: GlobalService,
    protected injector: Injector,
    protected storageService: ImovelStorageService,
    protected viacepService: ViaCEPService,
    protected funcaoService: FuncaoService,
    protected setorService: SetorService,
    protected planoContaService: PlanoContaService,
    protected bemImovelService: BemImovelService,
    protected usuarioService: UsuarioService) {
    super(new Imovel(), injector, Imovel.converteJson, bemImovelService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(_entidade: Imovel): Promise<boolean> {
    return true
  }
  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      situacao: [null],
      data_cadastro: [null],
      data_aquisicao: [null],
      data_construcao: [null],
      vencimento_seguro: [null],
      valor_aquisicao: [null],
      valor_avaliacao: [null],
      valor_depreciado: [0],
      valor_atual: [null],
      valor_terreno: [null],
      valor_construcao: [null],
      valor_residual: [0],
      vida_util_meses: [0],
      matricula: [null],
      descricao: [null],
      topografia: [null],
      area_construcao: [null],
      area_terreno: [null],
      cep: [null],
      endereco: [null],
      num_endereco: [null],
      bairro: [null],
      municipio: [null],
      uf: [null],
      complemento: [null],
      obs: [null],
      documento: [null],
      destinacao_area: [null],
      controle_cadastro: [null],
      cadastro: [null],
      setor: [null],
      responsavel: [null],
      plano_conta: [null],
      orgao: [this.login.orgao, [Validators.required]],
      ocorrencias: [null],
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'orgao,setor.unidade,plano_conta,ocorrencias.tipo,ocorrencias.imovel,responsavel' };
  }

  protected afterLoad() {
    this.entidade.data_cadastro = new DateFormatPipe().transform(this.entidade.data_cadastro, []);
    this.entidade.data_aquisicao = new DateFormatPipe().transform(this.entidade.data_aquisicao, []);
    this.entidade.data_construcao = new DateFormatPipe().transform(this.entidade.data_construcao, []);
    this.entidade.vencimento_seguro = new DateFormatPipe().transform(this.entidade.vencimento_seguro, []);

    this.storageService.filtrar(1, -1, { 'imovel.id': this.entidade.id, foto: false, orderBy: 'data_cadastro$DESC' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaArquivos = res.content;
      });
    this.storageService.filtrar(1, -1, { 'imovel.id': this.entidade.id, foto: true, orderBy: 'data_cadastro$DESC' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaFotos = res.content;
        this.fotoPrincipal = this.listaFotos.find(f => f.principal);
      });

    this.usuarioAutoComplete.id = this.entidade.responsavel ? this.entidade.responsavel.id : null;
  }

  protected afterInit(): void {
    this.listaSituacao = this.globalService.obterSituacaoImovel().sort((a, b) => { return a.nome.localeCompare(b.nome) });
    this.carregarAutoCompletes();

    this.bemImovelService.filtrar(1, -1, { orgao_id: this.login.orgao.id, relations: 'orgao' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.imoveis = res.content;
      })
  }

  protected campoFoco(): ElementRef {
    return this.inputSigla;
  }

  protected beforeSubmit() {
    try {
      if (!this.entidadeForm.get('situacao').value) {
        throw new Error('Informe a situação!');
      }
      if (!this.entidadeForm.get('data_aquisicao').value) {
        throw new Error('Informe a data de aquisição!');
      }
      // if (!this.entidadeForm.get('data_construcao').value) {
      //   throw new Error('Informe a data de construção!');
      // }
      if (!this.entidadeForm.get('valor_aquisicao').value) {
        throw new Error('Informe o valor de aquisição!');
      }
      if (!this.entidadeForm.get('valor_terreno').value) {
        throw new Error('Informe o valor do terreno!');
      }

      const valorConstrucao = this.entidadeForm.get('valor_construcao').value;
      if (!valorConstrucao && valorConstrucao !== 0) {
        throw new Error('Informe o valor da construção!');
      }

      const areaContrucao = this.entidadeForm.get('area_construcao').value;
      if (!areaContrucao && areaContrucao !== 0) {
        throw new Error('Informe a área construída!');
      }

      if ((valorConstrucao && +valorConstrucao > 0 || areaContrucao && +areaContrucao > 0) && !this.entidadeForm.get('data_construcao').value) {
        throw new Error('Preencha os campos referentes a construção!');
      }

      if (!this.entidadeForm.get('descricao').value) {
        throw new Error('Informe a descrição!');
      }

      if (!this.entidadeForm.get('area_terreno').value) {
        throw new Error('Informe a área do terreno!');
      }
      if (!this.entidadeForm.get('cep').value) {
        throw new Error('Informe o CEP!');
      }
      if (!this.entidadeForm.get('endereco').value) {
        throw new Error('Informe o endereço!');
      }
      if (!this.entidadeForm.get('num_endereco').value) {
        throw new Error('Informe o número do endereço!');
      }
      if (!this.entidadeForm.get('bairro').value) {
        throw new Error('Informe o bairro!');
      }
      if (!this.entidadeForm.get('municipio').value) {
        throw new Error('Informe o município!');
      }
      if (!this.entidadeForm.get('uf').value) {
        throw new Error('Informe a UF!');
      }
      if (!this.entidadeForm.get('setor').value) {
        throw new Error('Informe o Setor!');
      }

      if (this.entidadeForm.get('destinacao_area').value == 'null'){
        this.entidadeForm.get('destinacao_area').setValue(null);
      }
      if (this.entidadeForm.get('controle_cadastro').value == ''){
        this.entidadeForm.get('controle_cadastro').setValue(null);
      }
      if (this.entidadeForm.get('cadastro').value == ''){
        this.entidadeForm.get('cadastro').setValue(null);
      }
    } catch (e) {
      toastr.error(e.message);
      throw e;
    }
  }

  protected afterSubmit(modelo: Imovel) {
    this.imovelDescricaoDuplicada = this.imoveis.find(i => i.descricao === modelo.descricao && i.id !== modelo?.id)
    if (this.imovelDescricaoDuplicada) {
      toastr.warning(`Já existe cadastro com esta descrição, no imóvel nº ${this.imovelDescricaoDuplicada.id}!`);
    }
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private carregarAutoCompletes() {
    this.setorAutoComplete = new EddyAutoComplete(null, this.setorService, 'id', ['id', 'nome'],
      { orgao_id: this.login.orgao.id, relations: 'unidade', orderBy: 'nome', }, { number: ['id'], text: ['nome'] });

    this.planoContaAutoComplete = new EddyAutoComplete(null, this.planoContaService, 'id', ['codigo', 'nome'],
      { exercicio_id: this.login.exercicio.id, 'codigo$like': '123%' }, { text: ['nome', 'codigo'] });

    this.usuarioAutoComplete = new EddyAutoComplete(this.entidadeForm.get('responsavel'), this.usuarioService, 'id', ['nome', 'email'], {}, {
      text: ['nome', 'email']
    });
  }

  public buscarCEP() {
    if (this.entidadeForm.get('cep').value && (this.entidadeForm.get('cep').value !== this.entidade.cep)) {
      let cep = this.entidadeForm.get('cep').value;
      this.viacepService.obterPorCPF(cep)
        .subscribe((endereco) => {
          if (!endereco) {
            toastr.warning('Endereço não foi encontrado')
            return;
          }
          this.entidadeForm.get('endereco').setValue(endereco.logradouro);
          this.entidadeForm.get('bairro').setValue(endereco.bairro);
          this.entidadeForm.get('municipio').setValue(endereco.localidade);
          this.entidadeForm.get('uf').setValue(endereco.uf);
          this.entidadeForm.get('complemento').setValue(endereco.complemento);
        }, (err) => toastr.warning('Endereço não foi encontrado'));
    }
  }

  public atualizarPrincipal(foto: ImovelStorage) {
    if (!foto) return;
    this.fotoPrincipal = foto;
  }
}
