import { AcaoGovernoService } from "administrativo-lib";
import { AcaoGoverno, FuncaoService, LoginContabil, Relatorio } from "eddydata-lib";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

export class UnidadeAcaoRelatorio {
    protected unsubscribe: Subject<void> = new Subject();

    constructor(
        protected funcaoService: FuncaoService,
        public login: LoginContabil,
        private acaoGovernoService: AcaoGovernoService,
    ) { }

    imprimir(somenteLeiProjeto, ppa) {
        const parametros = {
            relations: 'acao,executora,meta.programa,meta.funcao,meta.subfuncao,meta.indicadores,meta.ppa,indicador,meta.unidade,meta.unidade.orgao',
            'ppa.id': this.login.ppa.id,
            'orderBy': 'executora.codigo'
        };

        if (somenteLeiProjeto) parametros['meta.indicadores.lei$like'] = `${ppa.lei_numero.split('/').join('')}%`;

        this.acaoGovernoService.filtrar(1, -1, parametros)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(lista => {
                Relatorio.imprimirPersonalizado('LISTAGEM DE UNIDADES EXECUTORAS E AÇÕES GOVERNAMENTAIS',
                    this.login.usuario.nome, this.login.usuario.sobrenome,
                    this.login.orgao.nome, this.login.brasao, this.montarConteudo(lista.content),
                    'portrait', 'LISTAGEM DE UNIDADES EXECUTORAS E AÇÕES GOVERNAMENTAIS',
                    {
                        linhas: {
                            hLineWidth() {
                                return 1;
                            },
                            vLineWidth() {
                                return 1;
                            },
                            hLineColor() {
                                return 'black';
                            },
                            paddingLeft() {
                                return 3;
                            },
                            paddingRight() {
                                return 3;
                            }
                        }
                    });
            }, () => alert('erro ao retornar lista'));
    }

    public montarConteudo(acoes: AcaoGoverno[]) {
        console.log(acoes)
        const conteudo = [];
        let total1 = 0;
        let total2 = 0;
        let total3 = 0;
        let total4 = 0;

        conteudo.push([
            { text: 'CÓDIGO', bold: true, alignment: 'center', border: [true, true, true, true] },
            { text: 'DESCRIÇÃO', bold: true, border: [true, true, true, true] },
            { text: this.login.ppa.ppaperiodo.ano1, bold: true, alignment: 'center', border: [true, true, true, true] },
            { text: this.login.ppa.ppaperiodo.ano2, bold: true, alignment: 'center', border: [true, true, true, true] },
            { text: this.login.ppa.ppaperiodo.ano3, bold: true, alignment: 'center', border: [true, true, true, true] },
            { text: this.login.ppa.ppaperiodo.ano4, bold: true, alignment: 'center', border: [true, true, true, true] },
        ]);

        const lista = this.funcaoService.agrupar(acoes, ['executora.codigo'], ['custo1', 'custo2', 'custo3', 'custo4'])

        for (const item of lista) {
            conteudo.push([
                { text: item.grupo['executora.codigo'], bold: true, border: [false, false, false, true] },
                { text: item.registros[0].executora.nome, bold: true, border: [false, false, false, true] },
                { text: '', border: [false, false, false, false] },
                { text: '', border: [false, false, false, false] },
                { text: '', border: [false, false, false, false] },
                { text: '', border: [false, false, false, false] }
            ]);

            for (const reg of item.registros) {
                conteudo.push([
                    { text: reg.acao.codigo, border: [false, false, false, false], alignment: 'right' },
                    { text: reg.acao.nome, border: [false, false, false, false] },
                    { text: this.funcaoService.convertToBrNumber(+reg.custo1, 2), border: [false, false, false, false], alignment: 'right' },
                    { text: this.funcaoService.convertToBrNumber(+reg.custo2, 2), border: [false, false, false, false], alignment: 'right' },
                    { text: this.funcaoService.convertToBrNumber(+reg.custo3, 2), border: [false, false, false, false], alignment: 'right' },
                    { text: this.funcaoService.convertToBrNumber(+reg.custo4, 2), border: [false, false, false, false], alignment: 'right' },
                ]);
            }

            conteudo.push([
                { text: 'TOTAL:', bold: true, border: [false, false, false, false], alignment: 'left', colSpan: 2 },
                '',
                { text: this.funcaoService.convertToBrNumber(+item.totalizadores['custo1'], 2), bold: true, border: [false, true, false, false], alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber(+item.totalizadores['custo2'], 2), bold: true, border: [false, true, false, false], alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber(+item.totalizadores['custo3'], 2), bold: true, border: [false, true, false, false], alignment: 'right' },
                { text: this.funcaoService.convertToBrNumber(+item.totalizadores['custo4'], 2), bold: true, border: [false, true, false, false], alignment: 'right' },
            ]);
        }
        
        total1 = acoes.reduce((acc, i) => acc + +i.custo1, 0);
        total2 = acoes.reduce((acc, i) => acc + +i.custo2, 0);
        total3 = acoes.reduce((acc, i) => acc + +i.custo3, 0);
        total4 = acoes.reduce((acc, i) => acc + +i.custo4, 0);

        conteudo.push([
            { text: '', bold: true, border: [false, true, false, false], alignment: 'right', colSpan: 2 }, 
            '',
            { text: this.funcaoService.convertToBrNumber(total1, 2), bold: true, border: [false, true, false, false], alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(total2, 2), bold: true, border: [false, true, false, false], alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(total3, 2), bold: true, border: [false, true, false, false], alignment: 'right' },
            { text: this.funcaoService.convertToBrNumber(total4, 2), bold: true, border: [false, true, false, false], alignment: 'right' },
        ]);

        return [{
            layout: 'linhas',
            table: {
                widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto'],
                dontBreakRows: true,
                headerRows: 1,
                body: conteudo
            }
        }];
    }
}