import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrazoListComponent } from './prazo-list/prazo-list.component';
import { PrazoFormComponent } from './prazo-form/prazo-form.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: PrazoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: PrazoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: PrazoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: PrazoFormComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrazoRoutingModule { }
