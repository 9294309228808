import { Input } from '@angular/core';
import { Injector } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FuncaoService, GlobalService, LoginService, Orgao, OrgaoService, Sistemas } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-transparencia-login',
  templateUrl: './transparencia-login.component.html',
  styleUrls: ['./transparencia-login.component.css']
})
export class TransparenciaLoginComponent implements OnInit {

  @Input() sistema: Sistemas;

  protected router: Router;
  protected unsubscribe: Subject<void> = new Subject();

  public listaOrgaos: Array<Orgao> = []
  public orgao: Orgao = new Orgao()
  public msgs: string;
  public email: string;
  public senha: string;
  public maskConfig = {
    mask: [
      {
        mask: '000.000.000-00'
      },
      {
        mask: /^\S*@?\S*$/
      }
    ]
  };

  constructor(
    protected injector: Injector,
    private authService: LoginService,
    protected orgaoService: OrgaoService
  ) {
    this.router = this.injector.get(Router);
    const login = GlobalService.obterSessaoLogin();
    if (login && login.usuario && login.usuario.sistema) {
      new FuncaoService().navegarPara(login.usuario.sistema, this.router, login);
    }
  }

  ngOnInit() {
    this.authService.mostrarMenuEmitter.emit(false);
    this.orgaoService.obterTodos()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaOrgaos = res;
        this.orgao = res[0];
      });
  }

  async fazerLogin() {
    const resultado = await this.authService.autenticarTransparencia(this.email, this.senha, this.sistema, this.orgao.id);
    if (resultado && !resultado.entidade) {
      this.msgs = resultado.error ? resultado.error.message : resultado.message;
    }
  }

  recuperarSenha() {
    this.authService.mostrarMenuEmitter.emit(false);
    this.router.navigate([`/recuperar-senha`]);
  }

  registroUsuario() {
    this.authService.mostrarMenuEmitter.emit(false);
    this.router.navigate([`/transparencia/registro`]);
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

}
