import { Injectable, Injector } from '@angular/core';
import { Raca } from '../../entidade/comum/raca.model';
import { BaseResourceService } from '../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class RacaService extends BaseResourceService<Raca> {

  constructor(
    protected injector: Injector
  ) {
    super('racas', injector);
  }
}
