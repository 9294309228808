import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Login } from '../entidade/login/login';
import { FuncaoService } from '../util/funcao.service';
import * as toastr from 'toastr';
import { BaseResourceModel } from './base-resource.model';
import { BaseResourceService } from './services/base-resource.service';
import { GlobalService } from '../util/global.service';
import { AutoComplete } from 'primeng/autocomplete';
import { Calendar } from 'primeng/calendar';

@Directive()
export abstract class BaseResourceItemsComponent<T extends BaseResourceModel> implements OnInit, OnDestroy {

  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  @Input() public lista: T[] = [];
  @Output() public listaChange: EventEmitter<T[]> = new EventEmitter();

  @Input() public login: Login;

  @Input() public usarAtalho: boolean = false;

  @Input() public autoAdd: boolean = false;

  @Input() public soVisualizar: boolean = false;

  protected anterior: T;

  protected tipo: 'adicionar' | 'editar';

  protected changeAfterEdit: boolean = false;

  protected unsubscribe: Subject<void> = new Subject();

  public persistence: boolean = false;
  private admin: boolean;

  // ========================================================================
  // ------------------------------ CONSTRUTOR ------------------------------
  // ========================================================================
  constructor(
    public entidade: T,
    protected baseResourceService: BaseResourceService<T>
  ) { }

  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================
  protected abstract afterInit(): void;
  protected abstract validSave(item: T): Promise<boolean> | boolean;
  protected abstract beforeSave(item: T): void;
  protected afterEdit(item: T) { }

  protected validRemover(item: T): boolean {
    return true;
  }

  protected campoFoco(): ElementRef | AutoComplete | Calendar {
    return null;
  }

  @HostListener('window:keydown.control.i', ['$event'])
  public adicionarAtalho(event: KeyboardEvent) {
    event.preventDefault();
    if (this.usarAtalho) {
      if (this.disabled()) {
        let item = this.selecionarEditavel();
        if (item)
          this.salvar(item);
      }
      this.adicionar();
    }
  }

  @HostListener('window:keydown.control.o', ['$event'])
  public cancelarAtalho(event: KeyboardEvent) {
    event.preventDefault();
    if (this.usarAtalho) {
      if (this.disabled()) {
        let item = this.selecionarEditavel();
        if (item)
          this.cancelar(item, this.indexItem(item));
      }
    }
  }

  public adicionar() {
    if (this.disabled()) return;
    if (!this.lista) this.lista = [];
    let item = this.assing(this.entidade);
    this.afterEdit(item);
    item['editavel'] = true;
    item['adicionado'] = true;
    this.lista.unshift(item);
    this.campoFocoEvent();
    this.calendarMascara();
    if (this.changeAfterEdit)
      this.change();
    this.tipo = 'adicionar';
  };

  public editar(item: T) {
    if (this.disabled())
      return;
    this.anterior = this.assing(item);
    this.afterEdit(item);
    item['editavel'] = true;
    delete item['adicionado'];
    this.campoFocoEvent();
    this.calendarMascara();
    if (this.changeAfterEdit)
      this.change();
    this.tipo = 'editar';
  }

  public cancelar(item: T, index: number) {
    if (!this.anterior) {
      this.lista.splice(index, 1);
    } else {
      item = this.assing(this.anterior);
      this.lista.splice(index, 1, item);
    }
    this.anterior = null;
    this.change();
  }


  public remover(item: T, index: number) {
    if (!this.validRemover(item))
      return;
    if (!item.id) {
      this.lista.splice(index, 1);
      toastr.success('Item removido com sucesso!');
      this.change();
    } else {
      this.baseResourceService.remover(item.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          this.lista.splice(index, 1);
          toastr.success('Item removido com sucesso!');
          this.change();
        }, (error) => toastr.error(error.error.payload));
    }
  }

  public async salvar(item: T): Promise<void | boolean> {
    if (!(await this.validSave(item)).valueOf())
      return false;
    this.beforeSave(item);
    item['editavel'] = false;
    delete item['adicionado'];
    if (this.persistence) {
      if (!item.id) {
        this.baseResourceService.inserir(item)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((data) => {
            item.id = data.id;
            item.data_alteracao = data.data_alteracao;
            item.data_cadastro = data.data_cadastro;
            toastr.success('Item salvo com sucesso');
            this.change();
            if (this.autoAdd && this.tipo === 'adicionar') {
              this.adicionar();
            }
            return true;
          }, (error) => {
            item['editavel'] = true;
            this.acaoErro(error)
          });
      } else {
        this.baseResourceService.atualizar(item)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((data) => {
            item.id = data.id
            item.data_cadastro = data.data_cadastro
            item.data_alteracao = data.data_alteracao
            toastr.success('Item atualizado com sucesso');
            this.change();
            if (this.autoAdd && this.tipo === 'adicionar') {
              this.adicionar();
            }
            return true;
          }, (error) => {
            item['editavel'] = true;
            this.acaoErro(error)
          });
      }
    } else {
      this.change();
      if (this.autoAdd && this.tipo === 'adicionar')
        this.adicionar();
      return true;
    }
  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================


  ngOnInit(): void {
    if (!this.lista) this.lista = [];
    this.afterInit();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  public disabled() {
    return !this.lista || this.lista.filter((e) => e['editavel']).length > 0 || this.soVisualizar;
  }

  public selecionarEditavel() {
    let item = this.lista.find((e) => e['editavel'])
    if (item)
      return item;
    return null;
  }

  public indexItem(item: T): number {
    return this.lista.indexOf(item);
  }

  private assing(model: T): T {
    return Object.assign({}, model)
  }

  protected change() {
    this.listaChange.emit(this.lista);
  }

  private calendarMascara() {
    setTimeout(() => {
      new GlobalService().calendarMascara();
    }, 500);
  }

  private campoFocoEvent() {
    setTimeout(() => {
      if (this.campoFoco())
        new FuncaoService().focarCampo(this.campoFoco());
    }, 100);
  }

  public podeIncluir(url?: string) {
    if (this.login) {
      if (this.administrador()) {
        return true;
      }
      if (!url) {
        url = this.baseResourceService.retornarRota();
      }
      return new FuncaoService().podeIncluir(this.login, url)
    }
    return false;
  }

  administrador() {
    if (this.admin === null || this.admin === undefined) {
      this.admin = (new FuncaoService().campoJsonToken(this.login.token, 'administrador') == true) && this.login.usuario.sistemas_administrador?.includes(this.login.usuario.sistema);
    }
    return this.admin;
  }

  public acaoErro(error: any) {
    this.tratarErro(error);
    toastr.options.timeOut = 10000;
    toastr.options.closeButton = true;
    toastr.options.tapToDismiss = false;
    if (!error.error && error.message) {
      toastr.error(error.message);
    } else if (error.error && error.error.payload) {
      toastr.error(error.error.payload);
    } else {
      toastr.error("Ocorreu um erro ao processar a sua solicitação");
    }
  }

  protected tratarErro(error: any) { }
}
