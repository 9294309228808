import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { BemImovelFormComponent } from './bem-imovel-form/bem-imovel-form.component';
import { BemImovelListComponent } from './bem-imovel-list/bem-imovel-list.component';
import { BemImovelViewComponent } from './bem-imovel-view/bem-imovel-view.component';

const routes: Routes = [
  { path: '', component: BemImovelListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: BemImovelFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: BemImovelFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: BemImovelViewComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BemImovelRoutingModule { }
