import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { BaseResourceFormComponent, EncerramentoContabil, EncerramentoContabilJustificativa, LoginContabil } from 'eddydata-lib';
import * as toastr from 'toastr';
import { switchMap, takeUntil } from 'rxjs/operators';
import { EncerramentoContabilService } from '../service/encerramento-contabil.service';


declare var $: any;

@Component({
  selector: 'lib-encerramento-contabil-form',
  templateUrl: './encerramento-contabil-form.component.html'
})
export class EncerramentoContabilFormComponent extends BaseResourceFormComponent<EncerramentoContabil, LoginContabil> {

  @ViewChild('justificativa_') inputField: ElementRef;

  public mesSelect: { mes: string, select: boolean };
  public meses: any = {
    jan: { nome: 'JANEIRO', index: 0 },
    fev: { nome: 'FEVEREIRO', index: 1 },
    mar: { nome: 'MARÇO', index: 2 },
    abr: { nome: 'ABRIL', index: 3 },
    mai: { nome: 'MAIO', index: 4 },
    jun: { nome: 'JUNHO', index: 5 },
    jul: { nome: 'JULHO', index: 6 },
    ago: { nome: 'AGOSTO', index: 7 },
    set: { nome: 'SETEMBRO', index: 8 },
    out: { nome: 'OUTUBRO', index: 9 },
    nov: { nome: 'NOVEMBRO', index: 10 },
    dez: { nome: 'DEZEMBRO', index: 11 },
  };
  public reloadJust: boolean = false;

  private entidadeOld: EncerramentoContabil;
  private justificativaOld: string = null;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private encContabilService: EncerramentoContabilService
  ) {
    super(new EncerramentoContabil(), injector, EncerramentoContabil.converteJson, encContabilService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================


  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      jan: [false],
      fev: [false],
      mar: [false],
      abr: [false],
      mai: [false],
      jun: [false],
      jul: [false],
      ago: [false],
      set: [false],
      out: [false],
      nov: [false],
      dez: [false],
      orgao: [this.login.orgao],
      exercicio: [this.login.exercicio],
      justificativa: [null],
      just: [null]
    });
  }

  protected parametrosExtras(): {} {
    return {
      'relations': 'justificativas',
      'exercicio.id': this.login.exercicio.id,
      'orgao.id': this.login.orgao.id
    };
  }
  protected afterLoad(): void {
    this.entidadeOld = this.entidade;
    this.entidadeForm.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((e) => this.loadSelect(e));
  }

  loadSelect(entidade: any) {
    if (entidade['just'] === this.justificativaOld) {
      this.inputField.nativeElement.focus();
      this.entidadeForm.enable({ emitEvent: false })
      this.mesSelect = null;
      Object.entries(entidade).forEach(([k, v]) => {
        if (typeof v === 'boolean')
          if (this.entidadeOld[k] !== v)
            this.disabled(k, v, entidade);
      })
    }
    this.justificativaOld = entidade['just'];
  }

  disabled(mes: string, select: boolean, entidade: any) {
    this.mesSelect = { mes: mes, select: select };
    Object.entries(entidade).forEach(([k, v]) => {
      if (k !== mes && typeof v === 'boolean')
        this.entidadeForm.get(k).disable({ emitEvent: false });
    })
  }

  protected afterInit(): void {
    this.activatedRoute
      .paramMap
      .pipe(switchMap(params => this.encContabilService.obter(
        this.parametrosExtras()
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (entidade) => {
          if (entidade) {
            this.entidade = entidade;
            this.afterLoad();
            this.entidadeForm.patchValue(entidade);
            this.currentActionRoute = 'editar';
            this.skipRedirect = true;
          } else {
            this.sair();
          }
        }, (error) => this.sair());
  }

  protected beforeSubmit(): void {
    try {
      let just: string = this.entidadeForm.get('just').value;
      if (!just) {
        throw new Error('Informe justificativa de ' + (this.mesSelect.select ? 'encerramento' : 'aberto') + ' de mês');
      }
      let justificativa: EncerramentoContabilJustificativa = new EncerramentoContabilJustificativa();
      justificativa.descricao = just;
      justificativa.mes = this.meses[this.mesSelect.mes]['index'];
      justificativa.abertura = !this.mesSelect.select;
      justificativa.responsavel = this.login.usuario;
      this.entidadeForm.get('justificativa').setValue(justificativa);
    } catch (e) {
      toastr.error(e.message ? e.message : e);
      throw e;
    }
  }


  protected afterSubmit(entidade: EncerramentoContabil): void {
    this.entidadeOld = Object.assign(this.entidadeOld, entidade);
    this.entidadeForm.enable({ emitEvent: false });
    this.mesSelect = null;
    this.reloadJust = !this.reloadJust;
    this.entidadeForm.get('just').setValue(null);
  }


  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
