import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnexoLRFComponent } from './anexo-lrf.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: AnexoLRFComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AnexoLRFRoutingModule { }
