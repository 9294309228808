import {
    Audesp4Artigo16, Audesp4Artigo17, Audesp4Autorizacao, Audesp4ExistenciaRecursosSim, Audesp4LicitanteCNPJ, Audesp4LicitanteCPF, Audesp4LicitanteEstrangeiro
} from "./types/comum-type";
import { Audesp4ComprasServicosTI, Audesp4ComprasServicos, Audesp4ObrasServicosEngenharia } from "./types/tipo-objeto-type";

export class RegistroPrecosSim {
    LicitacaoPossuiParticipantes: 'S' | 'N';
    CodigoLicitacao: number;
    NumeroProcessoAdm: string;
    AnoProcessoAdm: number;
    NumAtaRegPreco: number;
    DtAtaRegPreco: string;
    NomeDetentor: string;
    NumeroLicitacao: string;
    DtAutorizacao: string;
    Validade: number;
    QuantidadeLotes: number;
    DescricaoObjeto: string;
    JustificativaContratacao?: string;
    Subcontratacao: 'S' | 'N';
    ComprasServicosTI?: Audesp4ComprasServicosTI;
    ComprasServicos?: Audesp4ComprasServicos;
    ObrasServicosEngenharia?: Audesp4ObrasServicosEngenharia;
    ExistenciaRecursosSim?: Audesp4ExistenciaRecursosSim;
    ExistenciaRecursosNao?: 'N';
    LRFArtigo16?: Audesp4Artigo16;
    LRFArtigo17?: Audesp4Artigo17;
    LRFNaoSeEnquadra?: 'S';
    ParecerTecnicoJuridico: 'S' | 'N';
    AudienciaPublicaNao?: 'N';
    AudienciaPublicaDt?: string;
    LicitanteCNPJ?: Audesp4LicitanteCNPJ[];
    LicitanteCPF?: Audesp4LicitanteCPF[];
    LicitanteEstrangeiro?: Audesp4LicitanteEstrangeiro[];
    Autorizacao?: Audesp4Autorizacao;
}