import { BaseResourceModel, Usuario, ParametroRequisicao } from 'eddydata-lib';

export class RequisicaoExcecao extends BaseResourceModel {
    constructor(
        public id?: number,
        public parametro?: ParametroRequisicao,
        public usuario_excecao?: Usuario
    ) {
        super();
    }

    static converteJson(json: any): RequisicaoExcecao {
        return Object.assign(new RequisicaoExcecao(), json)
    }
}