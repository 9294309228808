import { DatePipe } from "@angular/common";
import { Injector } from "@angular/core";
import { EmpenhoService } from "administrativo-lib";
import { FormatoExportacao, FuncaoService, GlobalService, Login, ProgressoService, Relatorio } from "eddydata-lib";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

export class ContasPagarRelatorio {
    protected unsubscribe: Subject<void> = new Subject();
    protected datepipe: DatePipe;
    protected funcaoService: FuncaoService;
    protected globalService: GlobalService;
    public nomeRel: string;
    public liquidados: boolean;
    private ordenacao: 'fornecedor' | 'vencimento' | 'recurso' | 'ficha';
    private vencto: 'empenho' | 'liquidacao';

    constructor(
        protected injector: Injector,
        protected empenhoServico: EmpenhoService,
        protected progressoService: ProgressoService,
    ) {
        this.funcaoService = new FuncaoService();
        this.globalService = new GlobalService();
        this.datepipe = new DatePipe('pt');
    }

    public async imprimirContasPagar(formato: FormatoExportacao, login: Login, param: {}, nomRel: string, tipoEmp: string, liq: string) {
        this.ordenacao = param['ordem'];
        this.vencto = param['vencto'];
        this.empenhoServico.gerarContasAPagar(param)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(async idTransacao => {
                this.progressoService.show(idTransacao, async (dados) => {
                    this.nomeRel = nomRel;
                    this.liquidados = liq === 'liquidado';

                    if (formato === 'pdf') {
                        let list: any[] = [];
                        if (tipoEmp == 'orcamentario') {
                            list.push(this.montarConteudoOrcamentario(dados));
                        } else if (tipoEmp == 'extra') {
                            list.push(this.montarConteudoExtra(dados));
                        } else {
                            list.push(this.montarConteudoRestos(dados));
                        }

                        Relatorio.imprimirPersonalizado(
                            this.nomeRel,
                            login.usuario.nome, login.usuario.sobrenome,
                            login.orgao.nome, login.brasao,
                            list,
                            'landscape',
                            this.nomeRel,
                            {
                                linhas: {
                                    hLineWidth() {
                                        return 1;
                                    },
                                    vLineWidth() {
                                        return 1;
                                    },
                                    hLineColor() {
                                        return 'black';
                                    },
                                    paddingLeft() {
                                        return 3;
                                    },
                                    paddingRight() {
                                        return 3;
                                    }
                                }
                            }, true);
                    } else if (formato === 'csv') {
                        // this.imprimirCSV(lista.emo, lista.eme, lista.emr, infoExtras, `${infoExtras.tipoDespesa} OS TIPOS ${infoExtras.tipoRetencao} NÃO INCLUÍDOS EM OP`);
                    }
                })
            })
    }

    public montarConteudoOrcamentario(dados: any[]): {}[] {
        const conteudo = [];
        let totalValorEmpenho: number = 0;
        let totalValorLiquido: number = 0;

        const camposAgrupar = [];
        const camposTotalizar = [];

        const nomeRel = { text: this.nomeRel, alignment: 'center', bold: true, fontSize: 16, colSpan: 7 };

        switch(this.ordenacao) {
            case 'fornecedor':
                camposAgrupar.push('fa_nome');
                break;
            case 'vencimento':
                camposAgrupar.push('vencto');
                camposAgrupar.push('fi_numero');
                break;
            case 'recurso':
                camposAgrupar.push('apv_codigo');
                break;
            case 'ficha':
                camposAgrupar.push('fi_numero');
                break;
        }
        if (this.liquidados) {
            camposTotalizar.push('e_valor_empenho');
            camposTotalizar.push('valor_liquidado');

            conteudo.push([
                { ...nomeRel, colSpan: 7 },
                '', '', '', '', '', ''
            ]);
            conteudo.push([
                { text: '', alignment: 'center', bold: true, fontSize: 16, colSpan: 7 },
                '', '', '', '', '', ''
            ]);
            const vencimentoNome: string = this.vencto === 'empenho' ? 'VENCIMENTO EMPENHO' : 'VENCIMENTO LIQUIDAÇÃO';
            conteudo.push([
                { text: 'EMPENHO', alignment: 'center', bold: true, fontSize: 8 },
                { text: 'FORNECEDOR', alignment: 'center', bold: true, fontSize: 8 },
                { text: vencimentoNome, alignment: 'center', bold: true, fontSize: 8 },
                { text: 'UNIDADE', alignment: 'center', bold: true, fontSize: 8 },
                { text: 'FICHA', alignment: 'center', bold: true, fontSize: 8 },
                { text: 'VALOR EMPENHO', alignment: 'center', bold: true, fontSize: 8 },
                { text: 'VALOR LIQUIDO', alignment: 'center', bold: true, fontSize: 8 },
            ]);
        } else {
            camposTotalizar.push('valor_empenhado');
            camposTotalizar.push('valor_liquidado');

            conteudo.push([
                { ...nomeRel, colSpan: 6 },
                '', '', '', '', ''
            ]);
            conteudo.push([
                { text: '', alignment: 'center', bold: true, fontSize: 16, colSpan: 6 },
                '', '', '', '', ''
            ]);
            conteudo.push([
                { text: 'EMPENHO', alignment: 'center', bold: true, fontSize: 8 },
                { text: 'FORNECEDOR', alignment: 'center', bold: true, fontSize: 8 },
                { text: 'VENCIMENTO EMPENHO', alignment: 'center', bold: true, fontSize: 8 },
                { text: 'UNIDADE', alignment: 'center', bold: true, fontSize: 8 },
                { text: 'FICHA', alignment: 'center', bold: true, fontSize: 8 },
                { text: 'VALOR EMPENHO', alignment: 'center', bold: true, fontSize: 8 },
            ]);
        }

        const lista = this.funcaoService.agrupar(dados, camposAgrupar, camposTotalizar);

        for (const l of lista) {
            let nomeGrupo: {} = { text: '' };
            if ('recurso' === this.ordenacao) nomeGrupo = { text: `${l.registros[0].apv_codigo} ${l.registros[0].apv_nome}`, alignment: 'left', bold: true, fontSize: 10 };

            if (this.liquidados) {
                conteudo.push([
                    { ...nomeGrupo, colSpan: 7 },
                    '', '', '', '', '', ''
                ]);
                for (const r of l.registros) {
                    conteudo.push([
                        { text: r.e_numero, alignment: 'center' },
                        { text: r.fa_nome, alignment: 'left' },
                        { text: this.funcaoService.converteDataBR(r.vencto), alignment: 'center' },
                        { text: this.funcaoService.mascarar('##.##.##', r.exc_codigo), alignment: 'center' },
                        { text: r.fi_numero, alignment: 'center' },
                        { text: this.funcaoService.convertToBrNumber(r.e_valor_empenho), alignment: 'right' },
                        { text: this.funcaoService.convertToBrNumber(r.valor_liquidado), alignment: 'right' },
                    ]);
                }
            } else {
                conteudo.push([
                    { ...nomeGrupo, colSpan: 6 },
                    '', '', '', '', ''
                ]);
                for (const r of l.registros) {
                    conteudo.push([
                        { text: r.e_numero, alignment: 'center' },
                        { text: r.fa_nome, alignment: 'left' },
                        { text: this.funcaoService.converteDataBR(r.vencto), alignment: 'center' },
                        { text: this.funcaoService.mascarar('##.##.##', r.exc_codigo), alignment: 'center' },
                        { text: r.fi_numero, alignment: 'center' },
                        { text: this.funcaoService.convertToBrNumber(r.valor_empenhado - +r.valor_liquidado), alignment: 'right' },
                    ]);
                }
            }

            if (this.liquidados) {
                totalValorEmpenho += +l.totalizadores['e_valor_empenho'];
            } else {
                totalValorEmpenho += +l.totalizadores['valor_empenhado']  - l.totalizadores['valor_liquidado'];
            }
            if (this.liquidados)
                totalValorLiquido += +l.totalizadores['valor_liquidado'];

            if (this.liquidados) {
                conteudo.push([
                    { text: `TOTAL `, alignment: 'left', bold: true, fontSize: 9, colSpan: 5 },
                    '', '', '', '',
                    { text: this.funcaoService.convertToBrNumber(l.totalizadores['e_valor_empenho']), alignment: 'right', bold: true },
                    { text: this.funcaoService.convertToBrNumber(l.totalizadores['valor_liquidado']), alignment: 'right', bold: true },
                ]);
            } else {
                conteudo.push([
                    { text: `TOTAL `, alignment: 'left', bold: true, fontSize: 9, colSpan: 5 },
                    '', '', '', '',
                    { text: this.funcaoService.convertToBrNumber(l.totalizadores['valor_empenhado'] - l.totalizadores['valor_liquidado']), alignment: 'right', bold: true },
                ]);
            }
        }

        let larguraColunas: string[];
        if (this.liquidados) {
            conteudo.push([
                { text: `TOTAL GERAL `, alignment: 'left', bold: true, fontSize: 9, colSpan: 5 },
                '', '', '', '',
                { text: this.funcaoService.convertToBrNumber(totalValorEmpenho), alignment: 'right', bold: true },
                { text: this.funcaoService.convertToBrNumber(totalValorLiquido), alignment: 'right', bold: true },
            ]);
            larguraColunas = ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto'];
        } else {
            conteudo.push([
                { text: `TOTAL GERAL `, alignment: 'left', bold: true, fontSize: 9, colSpan: 5 },
                '', '', '', '',
                { text: this.funcaoService.convertToBrNumber(totalValorEmpenho), alignment: 'right', bold: true },
            ]);
            larguraColunas = ['auto', '*', 'auto', 'auto', 'auto', 'auto'];
        }

        return [{
            layout: 'linhas',
            table: {
                widths: larguraColunas,
                body: conteudo
            }
        }];
    }

    public montarConteudoExtra(dados: any[]): {}[] {
        const conteudo = [];
        let totalValorEmpenho: number = 0;

        conteudo.push([
            { text: this.nomeRel, alignment: 'center', bold: true, fontSize: 16, colSpan: 6 },
            '', '', '', '', ''
        ]);

        conteudo.push([
            { text: '', alignment: 'center', bold: true, fontSize: 16, colSpan: 6 },
            '', '', '', '', ''
        ]);

        conteudo.push([
            { text: 'EMPENHO', alignment: 'center', bold: true, fontSize: 8 },
            { text: 'FORNECEDOR', alignment: 'center', bold: true, fontSize: 8 },
            { text: 'VENCIMENTO EMPENHO', alignment: 'center', bold: true, fontSize: 8 },
            { text: 'UNIDADE', alignment: 'center', bold: true, fontSize: 8 },
            { text: 'FICHA', alignment: 'center', bold: true, fontSize: 8 },
            { text: 'VALOR EMPENHO', alignment: 'center', bold: true, fontSize: 8 },
        ]);

        const camposAgrupar = [];
        switch(this.ordenacao) {
            case 'fornecedor':
                camposAgrupar.push('fa_nome');
                break;
            case 'vencimento':
                camposAgrupar.push('vencto');
                break;
            case 'recurso':
                camposAgrupar.push('apv_codigo');
                break;
            case 'ficha':
                camposAgrupar.push('fi_numero');
                break;
        }
        const lista = this.funcaoService.agrupar(dados, camposAgrupar, ['e_valor_empenho']);

        for (const l of lista) {
            let nomeGrupo: {} = { text: '', colSpan: 6 };
            if ('recurso' === this.ordenacao) nomeGrupo = { text: `${l.registros[0].apv_codigo} ${l.registros[0].apv_nome}`, alignment: 'left', bold: true, fontSize: 10, colSpan: 6 };

            conteudo.push([
                {...nomeGrupo },
                '', '', '', '', ''
            ]);

            for (const r of l.registros) {
                conteudo.push([
                    { text: r.e_numero, alignment: 'center' },
                    { text: r.fa_nome, alignment: 'left' },
                    { text: this.funcaoService.converteDataBR(r.vencto), alignment: 'center' },
                    { text: this.funcaoService.mascarar('##.##.##', r.exc_codigo), alignment: 'center' },
                    { text: r.fi_numero, alignment: 'center' },
                    { text: this.funcaoService.convertToBrNumber(r.e_valor_empenho), alignment: 'right' },
                ]);
            }

            totalValorEmpenho += +l.totalizadores['e_valor_empenho'];

            conteudo.push([
                { text: `TOTAL `, alignment: 'left', bold: true, fontSize: 9, colSpan: 5 },
                '', '', '', '',
                { text: this.funcaoService.convertToBrNumber(l.totalizadores['e_valor_empenho']), alignment: 'right', bold: true },
            ]);
        }

        conteudo.push([
            { text: `TOTAL GERAL `, alignment: 'left', bold: true, fontSize: 9, colSpan: 5 },
            '', '', '', '',
            { text: this.funcaoService.convertToBrNumber(totalValorEmpenho), alignment: 'right', bold: true },
        ]);

        return [{
            layout: 'linhas',
            table: {
                widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto'],
                body: conteudo
            }
        }];
    }

    public montarConteudoRestos(dados: any[]): {}[] { //alterar as variaveis
        const conteudo = [];
        let totalValorEmpenho: number = 0;
        let totalValorLiquido: number = 0;

        const camposTotalizar = [];
        const camposAgrupar = [];

        switch(this.ordenacao) {
            case 'fornecedor':
                camposAgrupar.push('fa_nome');
                break;
            case 'vencimento':
                camposAgrupar.push('vencto');
                camposAgrupar.push('numero_ficha');
                break;
            case 'recurso':
                camposAgrupar.push('e_recurso_variavel');
                break;
            case 'ficha':
                camposAgrupar.push('numero_ficha');
                break;
        }

        if (this.liquidados) {
            camposTotalizar.push('e_valor_empenho')
            camposTotalizar.push('valor_liquidado');
            conteudo.push([
                { text: this.nomeRel, alignment: 'center', bold: true, fontSize: 16, colSpan: 7 },
                '', '', '', '', '', ''
            ]);

            conteudo.push([
                { text: '', alignment: 'center', bold: true, fontSize: 16, colSpan: 7 },
                '', '', '', '', '', '']);

            const vencimentoNome: string = this.vencto === 'empenho' ? 'VENCIMENTO EMPENHO' : 'VENCIMENTO LIQUIDAÇÃO';
            conteudo.push([
                { text: 'EMPENHO', alignment: 'center', bold: true, fontSize: 7 },
                { text: 'FORNECEDOR', alignment: 'center', bold: true, fontSize: 7 },
                { text: vencimentoNome, alignment: 'center', bold: true, fontSize: 7 },
                { text: 'UNIDADE', alignment: 'center', bold: true, fontSize: 7 },
                { text: 'FICHA', alignment: 'center', bold: true, fontSize: 7 },
                { text: 'VALOR EMPENHO', alignment: 'center', bold: true, fontSize: 7 },
                { text: 'VALOR LIQUIDO', alignment: 'center', bold: true, fontSize: 7 },
            ]);
        } else {
            camposTotalizar.push('valor_empenhado');
            camposTotalizar.push('valor_cancelado');
            camposTotalizar.push('valor_liquidado');
            conteudo.push([
                { text: this.nomeRel, alignment: 'center', bold: true, fontSize: 16, colSpan: 6 },
                '', '', '', '', ''
            ]);

            conteudo.push([
                { text: '', alignment: 'center', bold: true, fontSize: 16, colSpan: 6 },
                '', '', '', '', ''
            ]);

            conteudo.push([
                { text: 'EMPENHO', alignment: 'center', bold: true, fontSize: 7 },
                { text: 'FORNECEDOR', alignment: 'center', bold: true, fontSize: 7 },
                { text: 'VENCIMENTO EMPENHO', alignment: 'center', bold: true, fontSize: 7 },
                { text: 'UNIDADE', alignment: 'center', bold: true, fontSize: 7 },
                { text: 'FICHA', alignment: 'center', bold: true, fontSize: 7 },
                { text: 'VALOR EMPENHO', alignment: 'center', bold: true, fontSize: 7 },
            ]);
        }

        const lista = this.funcaoService.agrupar(dados, camposAgrupar, camposTotalizar);

        for (const l of lista) {
            let nomeGrupo: {} = { text: '' };
            if ('recurso' === this.ordenacao) nomeGrupo = { text: `${l.registros[0].e_recurso_variavel} ${l.registros[0].e_recurso_variavel_nome}`, alignment: 'left', bold: true, fontSize: 10 };

            if (this.liquidados) {
                conteudo.push([
                    { ...nomeGrupo, colSpan: 7 },
                    '', '', '', '', '', ''
                ]);
            } else {
                conteudo.push([
                    { ...nomeGrupo, colSpan: 6 },
                    '', '', '', '', ''
                ]);
            }

            for (const r of l.registros) {
                if (this.liquidados) {
                    conteudo.push([
                        { text: r.e_numero, alignment: 'center' },
                        { text: r.fa_nome, alignment: 'left' },
                        { text: this.funcaoService.converteDataBR(r.vencto), alignment: 'center' },
                        { text: this.funcaoService.mascarar('##.##.##', r.codigo_unidade), alignment: 'center' },
                        { text: r.numero_ficha, alignment: 'center' },
                        { text: this.funcaoService.convertToBrNumber(r.e_valor_empenho), alignment: 'right' },
                        { text: this.funcaoService.convertToBrNumber(r.valor_liquidado), alignment: 'right' },
                    ]);
                } else {
                    conteudo.push([
                        { text: r.e_numero, alignment: 'center' },
                        { text: r.fa_nome, alignment: 'left' },
                        { text: this.funcaoService.converteDataBR(r.vencto), alignment: 'center' },
                        { text: this.funcaoService.mascarar('##.##.##', r.codigo_unidade), alignment: 'center' },
                        { text: r.numero_ficha, alignment: 'center' },
                        { text: this.funcaoService.convertToBrNumber(+r.valor_empenhado - +r.valor_cancelado - +r.valor_liquidado), alignment: 'right' },
                    ]);
                }
            }

            if (this.liquidados) {
                totalValorEmpenho += +l.totalizadores['e_valor_empenho'];
            } else {
                totalValorEmpenho += +l.totalizadores['valor_empenhado'] - +l.totalizadores['valor_cancelado'] - +l.totalizadores['valor_liquidado'];
            }
            if (this.liquidados) totalValorLiquido += +l.totalizadores['valor_liquidado'];

            if (this.liquidados) {
                conteudo.push([
                    { text: `TOTAL `, alignment: 'left', bold: true, fontSize: 9, colSpan: 5 },
                    '', '', '', '',
                    { text: this.funcaoService.convertToBrNumber(l.totalizadores['e_valor_empenho']), alignment: 'right', bold: true },
                    { text: this.funcaoService.convertToBrNumber(l.totalizadores['valor_liquidado']), alignment: 'right', bold: true },
                ]);
            } else {
                conteudo.push([
                    { text: `TOTAL `, alignment: 'left', bold: true, fontSize: 9, colSpan: 5 },
                    '', '', '', '',
                    { text: this.funcaoService.convertToBrNumber(+l.totalizadores['valor_empenhado'] - +l.totalizadores['valor_cancelado'] - +l.totalizadores['valor_liquidado']), alignment: 'right', bold: true },
                ]);
            }
        }

        let larguraColunas: string[];
        if (this.liquidados) {
            larguraColunas = ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto'];
            conteudo.push([
                { text: `TOTAL GERAL `, alignment: 'left', bold: true, fontSize: 9, colSpan: 5 },
                '', '', '', '',
                { text: this.funcaoService.convertToBrNumber(totalValorEmpenho), alignment: 'right', bold: true },
                { text: this.funcaoService.convertToBrNumber(totalValorLiquido), alignment: 'right', bold: true },
            ]);
        } else {
            larguraColunas = ['auto', '*', 'auto', 'auto', 'auto', 'auto'];
            conteudo.push([
                { text: `TOTAL GERAL `, alignment: 'left', bold: true, fontSize: 9, colSpan: 5 },
                '', '', '', '',
                { text: this.funcaoService.convertToBrNumber(totalValorEmpenho), alignment: 'right', bold: true },
            ]);
        }

        return [{
            layout: 'linhas',
            table: {
                widths: larguraColunas,
                body: conteudo
            }
        }];
    }
}