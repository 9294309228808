import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  AssinaturaService, BaseResourceRptPersonalizadoComponent, DateFormatPipe, FuncaoService,
  GlobalService, Licitacao, LicitacaoComissaoMembro, Login
} from "eddydata-lib";
import * as toastr from 'toastr';

@Component({
  selector: 'app-adjudicacao-rpt',
  templateUrl: './adjudicacao-rpt.component.html'
})
export class AdjudicacaoRptComponent extends BaseResourceRptPersonalizadoComponent {

  @Input() public licitacao: Licitacao;
  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public ptBR: any;
  public login: Login;
  public data: Date;
  public modelo: string = 'ADJUDICA1';
  public listModelo: { id: 'ADJUDICA1' | 'ADJUDICA2', nome: string }[];

  constructor(
    protected funcaoService: FuncaoService,
    protected assinaturaService: AssinaturaService
  ) {
    super(assinaturaService);
    this.assinaturaObrigatoria = false;
    this.removerCabecalho = true;
    this.removerRodape = true;
    this.login = GlobalService.obterSessaoLogin();
    this.data = new DateFormatPipe().transform(new Date(), []);
  }

  protected afterInit(): void {
    this.listModelo = [
      { id: 'ADJUDICA1', nome: 'Modelo 1' },
      { id: 'ADJUDICA2', nome: 'Modelo 2' }
    ];
    this.ptBR = new GlobalService().obterDataBR();
  }

  protected layoutRelatorio(): {} {
    return {
      table_eddy: {
        hLineWidth: function (i, node) {
          return i === node.table.body.length || i === 0 ? 0 : 1;
        },
        vLineWidth: function (i) {
          return 0;
        },
        hLineColor: function (i, node) {
          return i === node.table.headerRows || i === node.table.body.length - 1 ? 'black' : '#eee';
        },
        paddingLeft: function (i) {
          return i === 0 ? 0 : 8;
        },
        paddingRight: function (i, node) {
          return (i === node.table.widths.length - 1) ? 0 : 8;
        },
        fillColor: function (i, node) {
          if (i === 0 || i === node.table.headerRows || i === node.table.body.length - 1) {
            return null;
          }
          return (i % 2 === 0) ? '#f5f5f5' : null;
        }
      }
    };
  }

  protected modalRelatorio(): string {
    return 'dialogRelatorioAdjudicacao';
  }

  protected tituloRelatorio(): string {
    return 'ADJUDICAÇÃO';
  }

  protected async montarConteudo(): Promise<{}[]> {
    const conteudo = [];

    conteudo.push(this.dadosCabecalho()
      .concat(this.dadosTitulo())
      .concat(this.dadosConteudo())
      .concat(this.dadosRodape()));

    return conteudo;
  }

  private dadosCabecalho(): {}[] {
    const conteudo = [];
    let brasaoImage: {};
    if (this.login.brasao) {
      brasaoImage = {
        image: this.login.brasao,
        width: 70,
        alignment: 'left',
        margin: [0, 0, 0, 0],
        border: [false, false, false, false]
      };
    } else {
      brasaoImage = { margin: [0, 10, 0, 0], text: '', border: [false, false, false, false] };
    }

    brasaoImage['rowSpan'] = 2;

    conteudo.push([brasaoImage, { text: this.login.orgao.nome, alignment: 'left', fontSize: 12, margin: [35, 8, 0, 0], border: [false, false, false, false] }]);
    conteudo.push([
      { text: '', margin: [0, 0, 0, 0], border: [false, false, false, false] },
      { text: this.login.cidade.estado.nome.toUpperCase(), alignment: 'left', fontSize: 12, margin: [35, 12, 0, 0], border: [false, false, false, false] }
    ]);
    conteudo.push([
      { text: '', margin: [0, 0, 0, 0], border: [false, false, false, false] },
      { text: 'Divisão de Licitações e Compras', alignment: 'left', fontSize: 12, border: [false, false, false, false], margin: [35, 0, 0, 0] }
    ]);

    return [{
      layout: 'linhas',
      table: {
        widths: [40, '*'],
        body: conteudo
      }
    }];
  }

  private dadosTitulo(): {}[] {
    const conteudo = [];

    conteudo.push([
      { text: 'EDITAL DE ADJUDICAÇÃO', alignment: 'center', decoration: 'underline', fontSize: 16, margin: [0, 18, 0, 0], border: [false, false, false, false] }
    ]);
    conteudo.push([
      { text: `PROCESSO ADMINISTRATIVO N.° ${this.licitacao.processo}`, alignment: 'center', fontSize: 12, margin: [0, 8, 0, 0], border: [false, false, false, false] }
    ]);
    conteudo.push([
      {
        text: `${this.licitacao.modalidade.nome} N.° ${this.funcaoService.mascarar(this.licitacao.numero.length === 9 ? '00000/0000' : '0000/0000', this.licitacao.numero)}`,
        bold: true, alignment: 'center', fontSize: 12, border: [false, false, false, false], margin: [0, 8, 0, 0]
      }
    ]);
    conteudo.push([
      { text: 'OBJETO:', alignment: 'left', decoration: 'underline', fontSize: 12, border: [false, false, false, false], margin: [0, 12, 0, 0] }
    ]);
    conteudo.push([
      { text: this.licitacao.objeto, alignment: 'left', fontSize: 12, border: [false, false, false, false], margin: [0, 0, 0, 0] }
    ]);

    if (this.modelo === 'ADJUDICA1') {
      conteudo.push([
        {
          text: `\u200B\t\t\t\t\t\t\t\t\t\t\tA Comissão de Licitação da PREFEITURA MUNICIPAL DE ${this.login.cidade.nome.toLocaleUpperCase()}, nomeada pela portaria N.° ${this.licitacao.comissao?.portaria}/${this.licitacao.comissao?.ano_portaria} leva ao conhecimento dos interessados que objeto do presente processo licitatório foi adjudicado à(s) seguinte(s) firma(s):`,
          alignment: 'justify', fontSize: 12, border: [false, false, false, false], margin: [0, 2, 0, 0]
        }
      ]);
    } else {
      conteudo.push([
        {
          text: `\u200B\t\t\t\t\t\t\tFace ao julgamento e homologação do presente certame licitatório, fica adjudicado o presente objeto do(a) ${this.licitacao.modalidade.nome} N.° ${this.licitacao.numero.slice(0, -5)}/${this.licitacao.numero.slice(-4)} à(s) seguinte(s) firma(s):`,
          alignment: 'justify', fontSize: 12, border: [false, false, false, false], margin: [0, 2, 0, 0]
        }
      ]);
    }

    return [{
      layout: 'linhas',
      table: {
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosConteudo(): {}[] {
    const conteudo = [];
    let col1;
    let col3;

    if (this.modelo === 'ADJUDICA1') {
      col1 = 'auto';
      col3 = '*';
      const propostas = [];
      this.licitacao.itens.forEach(item => {
        const vencedor = item.propostas.filter(p => p.situacao === 'VENCEDOR')[0];
        if (!vencedor) return
        const quantidade = +vencedor?.quantidade_final > 0 ? +vencedor?.quantidade_final : +vencedor?.memorial?.quantidade;
        const valor = vencedor?.valor_final > 0 ? vencedor?.valor_final : +vencedor?.valor_unitario;
        propostas.push({
          favorecido_id: vencedor.proponente.favorecido.id,
          favorecido: vencedor.proponente.favorecido.nome,
          valor_total: (+quantidade * +valor)
        });
      });

      const itensAgrupados = this.funcaoService.agrupar(propostas.sort((a, b) => a.favorecido_id - b.favorecido_id), 'favorecido', ['valor_total']);

      for (const item of itensAgrupados) {
        conteudo.push([
          {
            text: item.grupo,
            alignment: 'left', spanCol: 2, fontSize: 10, border: [false, false, false, false], margin: [0, 4, 0, 0]
          },
          { text: '', border: [false, false, false, false], alignment: 'center', margin: [0, 0, 0, 0] },
          {
            text: `R$ ${this.funcaoService.convertToBrNumber(item.totalizadores['valor_total'], 2)}`,
            alignment: 'left', fontSize: 10, border: [false, false, false, false], margin: [0, 0, 0, 0]
          }
        ]);
      }
    } else {
      col1 = 56;
      col3 = 112;

      conteudo.push([
        { text: 'ITEM', alignment: 'center', fontSize: 12, border: [false, false, false, false], margin: [0, 8, 0, 0] },
        { text: 'FORNECEDOR', alignment: 'center', fontSize: 12, border: [false, false, false, false], margin: [0, 8, 0, 0] },
        { text: 'VALOR', alignment: 'center', fontSize: 12, border: [false, false, false, false], margin: [0, 8, 0, 0] }
      ]);

      for (const item of this.licitacao.itens) {
        if (item.propostas.length === 0) continue;
        const vencedor = item.propostas.filter(p => p.situacao === 'VENCEDOR')[0];
        if (!vencedor) continue
        conteudo.push([
          { text: item.ordem, alignment: 'left', fontSize: 10, border: [false, false, false, false], margin: [0, 4, 0, 0] },
          {
            text: vencedor.proponente?.favorecido?.nome, fontSize: 10, alignment: 'left',
            border: [false, false, false, false], margin: [0, 0, 0, 0]
          },
          {
            text: `R$ ${this.funcaoService.convertToBrNumber(+vencedor.quantidade_final * +vencedor.valor_final, 2)}`,
            alignment: 'left', fontSize: 10, border: [false, false, false, false], margin: [0, 0, 0, 0]
          }
        ]);
      }
    }

    return [{
      layout: 'linhas',
      table: {
        widths: [col1, '*', col3],
        body: conteudo
      }
    }];;
  }

  private dadosRodape(): {}[] {
    let assinaturas: any[] = [];
    let texto = '';

    if (this.modelo === 'ADJUDICA1') {
      assinaturas = this.licitacao.comissao_membros.filter(m => m.marcado);
      texto = `Ficam os interessados cientes de que o prazo para interposição de recursos é de 2 (dois) dias úteis, a partir da publicação deste edital.`;
    } else {
      assinaturas = this.assinatura.pessoas.filter(p => p.selecionado);
      texto = `O interessado deverá procurar o setor competente da PREFEITURA MUNICIPAL DE ${this.login.cidade.nome.toLocaleUpperCase()}, para providências legais.`;
    }

    const conteudo: {}[] = [
      {
        text: `\u200B\t\t\t\t\t\t\t\t\t\t${texto}`,
        alignment: 'justify', fontSize: 12, border: [false, false, false, false], margin: [0, 85, 0, 0]
      },
      {
        text: `${this.login.cidade.nome},   ${this.funcaoService.formatarDataExtenso(this.data)}`,
        alignment: 'center', fontSize: 12, border: [false, false, false, false], margin: [0, 12, 0, 50]
      }
    ];

    return conteudo.concat(this.dadosAssinatura(assinaturas));
  }

  private dadosAssinatura(assinaturas: any[]) {
    if (!assinaturas || assinaturas.length === 0) return [{}];
    if (this.modelo === 'ADJUDICA2') {
      return this.imprimirAssinaturas(assinaturas);
    } else {
      if (assinaturas.length > 2) {
        let retorno: {}[] = [];
        for (let i = 0; i < assinaturas.length; i += 2) {
          retorno = retorno.concat(this.dadosAssinatura(assinaturas.slice(i, i + 2)));
        }
        return retorno;
      } else if (assinaturas.length > 1) {
        return this.assinatura2Colunas(assinaturas);
      } else {
        return this.assinatura1Coluna(assinaturas[0]);
      }
    }
  }

  private assinatura1Coluna(assinatura: LicitacaoComissaoMembro): {}[] {
    return [
      { canvas: [{ type: 'line', x1: this.orientacao === 'portrait' ? 187 : 312, y1: 0, x2: this.orientacao === 'portrait' ? 347 : 472, y2: 0, lineWidth: 0.5 }] },
      {
        columns: [
          { text: this.funcaoService.abreviarStr(assinatura.comissao_membro.pessoa.nome, 100), alignment: 'center', bold: true, fontSize: 10, margin: [0, 3, 0, 3] },
        ]
      },
      {
        columns: [
          { text: this.funcaoService.abreviarStr(assinatura.comissao_membro.atribuicao.split(' - ')[1], 100), alignment: 'center', fontSize: 10, margin: [0, 0, 0, 40] },
        ]
      },
    ];
  }

  private assinatura2Colunas(assinaturas: LicitacaoComissaoMembro[]): {}[] {
    return [
      { canvas: [{ type: 'line', x1: this.orientacao === 'portrait' ? 55 : 115, y1: 0, x2: this.orientacao === 'portrait' ? 215 : 275, y2: 0, lineWidth: 0.5 }] },
      { canvas: [{ type: 'line', x1: this.orientacao === 'portrait' ? 320 : 505, y1: 0, x2: this.orientacao === 'portrait' ? 480 : 665, y2: 0, lineWidth: 0.5 }] },
      {
        columns: [
          { text: this.funcaoService.abreviarStr(assinaturas[0].comissao_membro.pessoa.nome, 100), alignment: 'center', bold: true, fontSize: 10, margin: [0, 3, 0, 3] },
          { text: this.funcaoService.abreviarStr(assinaturas[1].comissao_membro.pessoa.nome, 100), alignment: 'center', bold: true, fontSize: 10, margin: [0, 3, 0, 3] },
        ]
      },
      {
        columns: [
          { text: this.funcaoService.abreviarStr(assinaturas[0].comissao_membro.atribuicao.split(' - ')[1], 100), alignment: 'center', fontSize: 10, margin: [0, 0, 0, 40] },
          { text: this.funcaoService.abreviarStr(assinaturas[1].comissao_membro.atribuicao.split(' - ')[1], 100), alignment: 'center', fontSize: 10, margin: [0, 0, 0, 40] },
        ]
      },
    ];
  }

  public imprimir(): void {
    if (!this.validar())
      return;
    super.imprimir();
  }

  private validar(): boolean {
    if (!this.licitacao?.id) {
      toastr.warning(`Não foi informado a licitação para impressão da autorização!`);
      return false;
    }
    if (this.modelo === 'ADJUDICA2' && (!this.assinatura || this.assinatura?.pessoas.filter(p => p.selecionado).length === 0)) {
      toastr.warning(`Assinatura obrigatória para o modelo 2!`);
      return false;
    }
    return true;
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

}
