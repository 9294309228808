import { BaseResourceModel } from '../../models/base-resource.model';
import { Material } from '../almoxarifado/material.model';
import { Produto } from '../almoxarifado/produto.model';
import { FaturaIrAliquota } from './fatura-ir-aliquota.model';

export class Aliquota extends BaseResourceModel {
  constructor(
    public id?: number,
    public resumo?: string,
    public natureza?: string,
    public ativo?: boolean,
    public ir?: number,
    public csll?: number,
    public cofins?: number,
    public pis_pasep?: number,
    public codigo_receita?: number,
    public materiais?: Material[],
    public produtos?: Produto[],
    public aliquotas_fatura_ir?: FaturaIrAliquota[]
  ) {
    super();
  }

  static converteJson(json: any): Aliquota {
    return Object.assign(new Aliquota(), json);
  }
}
