import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FuncaoService, Login, Rcms } from 'eddydata-lib';

@Component({
  selector: 'app-importar-rcms-empate-dlg',
  templateUrl: './importar-rcms-empate-dlg.component.html'
})
export class ImportarRcmsEmpateDlgComponent implements OnInit {

  @Input() login: Login;

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Input() public rcmss: Rcms[] = [];
  @Output() public onFinalizar: EventEmitter<Rcms[]> = new EventEmitter();

  constructor(public funcaoService: FuncaoService) { }

  ngOnInit(): void { }

  public finalizar() {
    for (let rcms of this.rcmss) {
      for (let item of rcms.itens) {
        if (!item['vencedor']) {
          toastr.warning(`Não foi selecionado fornecedor vencedor.`)
          return;
        }
      }
    }
    for (let r of this.rcmss) {
      r.itens = r.itens.map((i) => {
        i.cotacoes = i.cotacoes.filter((c) => c.id === i['vencedor']);
        return i;
      })
    }
    this.onFinalizar.emit(this.rcmss);
    this.onHide();
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

}