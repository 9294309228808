import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { DataViewModule } from 'primeng/dataview';
import { FieldsetModule } from 'primeng/fieldset';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { FavorecidoRelatorioCertificado } from './favorecido-relatorio-certificado.component';

@NgModule({
    declarations: [
       FavorecidoRelatorioCertificado
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        AutoCompleteModule,
        AccordionModule,
        CalendarModule,
        IMaskModule,
        ToastModule,
        RadioButtonModule,
        FieldsetModule,
        SelectButtonModule,
        DataViewModule
    ],
    exports: [
        FavorecidoRelatorioCertificado
    ],
    providers: []
})
export class FavorecidoRelatorioCertificadoModule { }
