import { Component, ElementRef, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { ContratoService, ConvenioService, DespesaService, LicitacaoService } from 'administrativo-lib';
import { ProdutoService } from 'almoxarifado-lib';
import { FichaDespesaService } from 'contabil-lib';
import {
  BaseResourceFormComponent, DateFormatPipe, Exercicio, FavorecidoService, FichaDespesa, FuncaoService, GlobalService, LoginContabil,
  Modalidade,
  PessoaService, Rcms,
  RcmsJustificativaDesbloqueioAutomatico,
  RcmsFavorecido, RcmsItem, Licitacao, Memorial
} from 'eddydata-lib';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ModalidadeService } from '../../modalidade/service/modalidade.service';
import { NotaRcmsRpt } from '../nota-rcms-rpt/nota-rcms-rpt.component';
import { RcmsJustificativaDesbloqueioAutomaticoComponent } from '../rcms-justificativa-desbloqueio-automatico/rcms-justificativa-desbloqueio-automatico.component';
import { RcmsFavorecidorService } from '../service/rcms-favorecido.service';
import { RcmsItemService } from '../service/rcms-item.service';
import { RcmsJustificativaDesbloqueioAutomaticoService } from '../service/rcms-justificativa-desbloqueio-automatico.service';
import { RcmsService } from '../service/rcms.service';

type SaldoFixa = {
  dotacao?: number,
  reservado?: number,
  saldo?: number,
  empenhado?: number,
  compra?: number,
  rcms?: number
}

// declare var $: any;

@Component({
  selector: 'app-rcms-view',
  templateUrl: './rcms-view.component.html'
})
export class RcmsViewComponent extends BaseResourceFormComponent<Rcms, LoginContabil> implements OnChanges {

  /**
   * Declaração de variáveis
   */

  @Input() id: number;
  @Output() idChange: EventEmitter<number> = new EventEmitter();
  @Input() component: boolean = false;

  public saldoFixa: SaldoFixa;
  public itens: RcmsItem[];
  public favorecidos: RcmsFavorecido[];
  public visualizarFicha: boolean = false;
  public tab: number = 1;
  public visualizarExclusao: boolean = false;
  public justificativa_aceite = '';
  public visualizarAceite = false;
  public usarFichaDespesa = true;
  public mes: string;
  public rcmsImprimir: Rcms[] = [];
  public pedidoCompraDireta: boolean = false;
  public numeroConvenio: string = ''; public justificativaDesbloqueioAutomatico: RcmsJustificativaDesbloqueioAutomatico;
  public saldoConvenio: number;
  public justificativaDesbloqueioAutomaticoAcaoAoSalvar: {
    codigo: string;
    mensagem: string;
  };
  rcmsJustificativaDesbloqueioAutomatico: RcmsJustificativaDesbloqueioAutomaticoComponent;

  public licitacoesImportadas: Licitacao[] = [];

  /**
   * Auto completes
   */

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public globalService: GlobalService,
    public funcaoService: FuncaoService,
    protected favorecidoService: FavorecidoService,
    protected despesaService: DespesaService,
    protected fichaService: FichaDespesaService,
    protected licitacaoService: LicitacaoService,
    protected contratoService: ContratoService,
    protected convenioService: ConvenioService,
    protected modalidadeService: ModalidadeService,
    protected produtoService: ProdutoService,
    protected pessoaService: PessoaService,
    protected rcmsItemService: RcmsItemService,
    protected rcmsFavorecidoService: RcmsFavorecidorService,
    protected rcmsService: RcmsService,
    private rcmsJustificativaDesbloqueioAutomaticoService: RcmsJustificativaDesbloqueioAutomaticoService

  ) {
    super(new Rcms(), injector, Rcms.converteJson, rcmsService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  ngOnInit(): void {
    if (!this.component)
      super.ngOnInit();
    else {
      this.login = GlobalService.obterSessaoLogin() as LoginContabil;
      this.criarCamposForm();
      this.loadResource();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.component && changes.id && this.id)
      super.ngOnInit();
  }

  protected async loadResource(): Promise<void> {
    if (this.component) {
      this.baseResourceService.obter(
        Object.assign({}, { id: this.id }, this.parametrosExtras())
      ).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (entidade) => {
            if (entidade) {
              this.entidade = entidade;
              if (this.datePipe())
                for (let pipe of this.datePipe()) {
                  if (this.entidade[pipe])
                    this.entidade[pipe] = new DateFormatPipe().transform(this.entidade[pipe], [])
                }
              this.afterLoad();
              if (!this.readOnly) {
                this.entidadeForm.patchValue(entidade);
              }
            } else {
              this.sair();
            }
          }, (error) => {
            this.sair();
          });
    } else {
      super.loadResource();
    }
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      data_rcms: [new Date(), [Validators.required]],
      tipo_rcms: ['O', [Validators.required]],
      numero: [null, [Validators.required]],
      justificativa: [null], documento: [null],
      local_entrega: [null],
      observacao: [null],
      modalidade: [null, [Validators.required]],
      ficha: [null],
      orgao: [this.login.orgao, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      subelemento: [null],
      prazo_entrega: [null, [Validators.required]],
      prazo_pagamento: [null, [Validators.required]],
      setor: [null, [Validators.required]],
      estoque: [null],
      licitacao: this.fb.group({
        id: [null],
        numero: [null]
      }),
      processo: [null],
      contrato: this.fb.group({
        id: [null],
        numero: [null],
      }),
      convenio: [null],
      usuario: [this.login.usuario],
      itens: [null],
      favorecidos: [null],
      requerente: [null, [Validators.required]],
      autorizado_compra: [false],
      somente_produto: [false],
      somente_servico: [false],
      responsavel_cotacao: [null],
      responsavel_fiscalizacao: [null],
      prazo_execucao: [null],
      justificativa_contratacao: [null],
      rcmsJustificativa: [null],
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: ['setor', 'licitacao.modalidade', 'contrato', 'exercicio', 'orgao', 'ficha', 'rcmsJustificativa',
        'ficha.despesa', 'subelemento', 'requerente', 'modalidade', 'prazo', 'usuario_exclusao', 'compras.empenho', 'ficha.executora.unidade',
        'ficha.recurso', 'ficha.acao', 'ficha.aplicacao', 'responsavel_cotacao', 'responsavel_fiscalizacao', 'prazo_pagamento', 'prazo_entrega',
        'reserva_dotacao.reserva', 'convenio', 'estoque', 'contrato_aditamento', 'veiculo']
    };
  }

  protected afterInit(): void {
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
    this.usarFichaDespesa = !this.login.parametro?.requisicao?.nao_usar_ficha_despesa;
    toastr.options.positionClass = 'toast-top-right';
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected async afterLoad() {
    this.entidade.data_rcms = new DateFormatPipe().transform(this.entidade.data_rcms, []);
    this.entidade.prazo_execucao = new DateFormatPipe().transform(this.entidade.prazo_execucao, []);
    this.buscarSaldoFixa(true);
    this.rcmsItemService.filtrar(1, -1, {
      relations: ['produto_unidade', 'produto_unidade.produto', 'produto_unidade.unidade',
        'cotacoes', 'cotacoes.rcmsFavorecido', 'cotacoes.rcmsFavorecido.favorecido', 'memorial.licitacao'], 'rcms.id': this.entidade.id, orderBy: ['ordem$ASC', 'cotacoes.id$DESC']
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.itens = res ? res.content : new Array<RcmsItem>();
      }, error => this.funcaoService.acaoErro(error));
    this.rcmsFavorecidoService.filtrar(1, -1, { relations: ['favorecido'], 'rcms.id': this.entidade.id, orderBy: 'id$DESC' })
      .pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
        this.favorecidos = res ? res.content : new Array<RcmsFavorecido>();
      }, error => this.funcaoService.acaoErro(error));

    this.entidade.data_rcms = new DateFormatPipe().transform(this.entidade.data_rcms, []);
    this.entidade.prazo_execucao = new DateFormatPipe().transform(this.entidade.prazo_execucao, []);

    this.rcmsJustificativaDesbloqueioAutomaticoService
      .filtrar(1, -1, {
        rcms_id: this.entidade.id,
        relations:
          "questao_1_licitacao.modalidade,questao_1_licitacao.exercicio,questao_2_licitacao.modalidade,questao_2_licitacao.exercicio,questao_4_rpl.exercicio",
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data) => {
          if (data.content.length > 0) {
            this.justificativaDesbloqueioAutomatico = data.content[0];
          }
        },
        (error) => this.funcaoService.acaoErro(error)
      );
    if (this.entidade?.convenio) {
      console.log(this.entidade)
      this.numeroConvenio = `${this.entidade.convenio.numero}/${this.entidade.convenio.ano}`
      this.saldoConvenio = await this.convenioService.buscarSaldoConvenio(
        this.entidade?.convenio?.id,
        this.funcaoService.converteDataSQL(this.entidadeForm.get('data_rcms').value),
        this?.login?.orgao?.id

      ).toPromise();
    }

    this.licitacoesImportadas = [];
    for (const item of this?.itens) {
      if (item?.memorial && item?.memorial !== 'N' && item?.memorial?.licitacao) {
        if (!this?.licitacoesImportadas?.find(l => l.id === (item?.memorial as Memorial)?.licitacao?.id)) {
          this.licitacoesImportadas?.push(item?.memorial?.licitacao);
        }
      }
    }
  }

  protected beforeSubmit() { }

  protected afterSubmit(ent: Rcms) { }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private buscarSaldoFixa(init?: boolean) {
    const exercicio: Exercicio = this.entidade.exercicio ? this.entidade.exercicio : this.entidadeForm.get('exercicio').value;
    const ficha: FichaDespesa = this.entidade.ficha ? this.entidade.ficha : this.entidadeForm.get('ficha').value;
    const dataCompra: Date = this.entidadeForm.get('data_rcms').value;
    const id: number = this.entidade.id ? this.entidade.id : this.entidadeForm.get('id').value;

    if (!ficha || !dataCompra || !exercicio)
      return;

    this.fichaService.saldoAtualFicha(ficha.id, this.login.orgao.id, this.login.exercicio.id,
      String(this.funcaoService.converteDataSQL(new DateFormatPipe().transform(dataCompra, ['local']))),
      (id ? { rcmsId: id } : null)).subscribe((data) => {
        if (data)
          this.carregarSaldoFixa(data, init);
      });
  }

  private carregarSaldoFixa(totalizadores: any, init?: boolean) {
    if (!totalizadores)
      return;

    // if (totalizadores.saldo_atual <= 0) {
    //   toastr.warning(`Ficha de '${this.entidadeForm.get('ficha').value.despesa.nome}' não possui saldo para estar realizando a requisição`);
    // }

    this.saldoFixa = {};

    this.saldoFixa.empenhado = +totalizadores.total_empenhado;
    this.saldoFixa.rcms = +totalizadores.total_rcms_pendente;
    this.saldoFixa.compra = +totalizadores.total_compra_pendente;

    this.saldoFixa.dotacao = +totalizadores.total_dotacao + +totalizadores.total_creditado;
    this.saldoFixa.reservado = +totalizadores.total_reservado;
    this.saldoFixa.saldo = +totalizadores.saldo_atual;
    this.verificarPedidoCompraDireta()
  }

  public tipoRcms(tipo: string) {
    let tiposRcms = this.globalService.obterListaTiposEmpenhos();
    let tiporcms = tiposRcms.find((t) => t.id === tipo);
    if (tiporcms)
      return tiporcms.nome;
    return '';
  }

  public saldoAtual() {
    let saldo = 0.0;
    if (!this.itens)
      return saldo;
    for (let item of this.itens) {
      if (!item.cotacoes)
        continue;
      let menor = item.cotacoes.sort((a, b) => a.valor_unitario - b.valor_unitario)[0];
      if (menor)
        saldo += menor.valor_unitario * item.quantidade;
    }
    return saldo;
  }

  public totalRCSM() {
    let saldo = 0.0;
    if (!this.itens)
      return saldo;
    let itensAnulados = this.itens.filter((c) => c.cotacoes && c.cotacoes.find((d) => +d.valor_unitario < 0));
    for (let item of this.itens) {
      if (!item.cotacoes)
        continue;
      let menor = item.cotacoes.filter((c) => c.valor_unitario > 0).sort((a, b) => a.valor_unitario - b.valor_unitario)[0];
      if (menor) {
        saldo += menor.valor_unitario * item.quantidade;
        if (itensAnulados) {
          for (let anulado of itensAnulados) {
            for (let cotacaoAnulada of anulado.cotacoes) {
              if (cotacaoAnulada.rcmsFavorecido.id === menor.rcmsFavorecido.id) {
                saldo += +cotacaoAnulada.valor_unitario;
              }
            }
          }
        }
      }
    }
    return this.funcaoService.arredondar(saldo, 2);
  }

  public menorPreco() {
    if (!this.itens)
      return 0.0;
    return this.itens.map((i): number => {
      if (!i.cotacoes || !i.quantidade)
        return 0.0;
      let menor = i.cotacoes
        .sort((a, b) => a.valor_unitario === 0.0 ? 1 :
          (+i.quantidade * +a.valor_unitario) - (+i.quantidade * +b.valor_unitario))[0];
      if (!menor)
        return 0;
      return (+menor.valor_unitario * +i.quantidade)
    }).sort((a, b) => a === 0.0 ? 1 : a - b)[0];
  }

  public selecionarTab(tab: number) {
    this.tab = tab;
  }

  public tabSelecionado(tab: number) {
    return this.tab === tab;
  }

  public reservaDotacao(): string {
    if (this.entidade?.reserva_dotacao?.reserva.tipo_reserva === '1') {
      return this.entidade?.reserva_dotacao ? `${this.entidade.licitacao?.processo} ${this.entidade.licitacao?.modalidade?.nome} - SEM PRÉ EMPENHO` : ''
    } else if (this.entidade?.reserva_dotacao?.reserva.tipo_reserva === '0') {
      return this.entidade?.reserva_dotacao ? `${this.entidade.licitacao?.processo} ${this.entidade.licitacao?.modalidade?.nome} - PRÉ EMPENHO` : ''
    }
  }

  public imprimir(nota: NotaRcmsRpt) {
    let p = new Promise<void>((resolve, reject) => {
      if (
        this.login.parametro?.requisicao?.minimo_tres_cotacoes &&
        !(this.login.usuario.administrador && this.login.usuario.sistemas_administrador?.includes('compras')) &&
        this.entidadeForm.get("processo").value == null &&
        this.entidadeForm.get("licitacao").value["id"] == null &&
        this.entidadeForm.get("contrato").value["id"] == null
      ) {
        let parametros = {
          "orgao.id": this.login.orgao.id,
          id: this.entidade.id,
          minimo_tres_cotacoes: true,
        };
        this.rcmsService
          .filtrar(1, -1, parametros)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            if (res.content.length === 0) {
              reject();
            } else {
              resolve();
            }
          });
      } else {
        resolve();
      }
    });

    p.then(() => {
      this.rcmsService
        .obter({
          relations: [
            "setor",
            "licitacao",
            "contrato",
            "contrato_aditamento",
            "exercicio",
            "orgao",
            "ficha",
            "ficha.despesa",
            "subelemento",
            "requerente",
            "modalidade",
            "operador",
            "ficha.executora.unidade",
            "ficha.recurso",
            "ficha.acao",
            "ficha.aplicacao",
            "responsavel_cotacao",
            "responsavel_fiscalizacao",
            "estoque",
          ],
          "exercicio.id": this.login.exercicio.id,
          "orgao.id": this.login.orgao.id,
          id: this.entidade.id,
        })
        .subscribe((data) => {
          this.rcmsItemService
            .filtrar(1, -1, {
              relations: [
                "produto_unidade",
                "produto_unidade.produto",
                "produto_unidade.unidade",
                "cotacoes",
                "cotacoes.rcmsFavorecido",
                "cotacoes.rcmsFavorecido.favorecido",
              ],
              "rcms.id": this.entidade.id,
              orderBy: ["ordem$ASC", "cotacoes.id$DESC"],
            })
            .subscribe((itens) => {
              this.rcmsFavorecidoService
                .filtrar(1, -1, {
                  relations: ["favorecido"],
                  "rcms.id": this.entidade.id,
                  orderBy: "id$DESC",
                })
                .subscribe(
                  (favorecidos) => {
                    data.itens = itens.content;
                    data.favorecidos = favorecidos.content;
                    this.rcmsImprimir = [data];
                    nota.show();
                  },
                  (error) => this.funcaoService.acaoErro(error)
                );
            });
        });
    }).catch(() => {
      toastr.info("Requisição com menos de 3 cotações, impressão cancelada!");
    });
  }

  sair(): void {
    if (this.component) {
      this.id = null;
      this.idChange.emit(this.id);
    } else
      super.sair();
  }

  public verificarPedidoCompraDireta() {
    const licitacao = this.entidadeForm.get("licitacao").value;
    const processo = this.entidadeForm.get("processo").value;
    const contrato = this.entidadeForm.get("contrato").value;
    const modalidade: Modalidade = this.entidadeForm.get("modalidade").value;

    if (
      (!licitacao || !licitacao.id) &&
      (!contrato || !contrato.id) &&
      !processo &&
      modalidade?.codigo === 5
    )
      this.pedidoCompraDireta = true;
    else this.pedidoCompraDireta = false;
  }

  voltar() {
    if (this.component) {
      this.id = null;
      this.idChange.emit(this.id);
    } else
      window.history.back();
  }

  public validaAditivoVigente(rcms: Rcms) {

    if (rcms.contrato_aditamento) {
      const dataHoje = new Date()
      const DataInicioAditamento = new Date(rcms.contrato_aditamento.data_inicio)

      return dataHoje < DataInicioAditamento
    } else {
      return false
    }
  }
}
