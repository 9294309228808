import { Component, ElementRef, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BaseResourceFormComponent, DateFormatPipe, EddyAutoComplete, FuncaoService,
  GlobalService, LoginContabil, ParametroAlmoxarifadoService, Pessoa, PessoaService, RequisicaoItemMovimento, RequisicaoMovimento, SaldoEstoqueService, SetorAlmoxarifado, StatusRequisicao
} from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ComprovanteRequisicaoRpt } from '../../relatorio/comprovante-requisicao-rpt/comprovante-requisicao-rpt';
import { RequisicaoMovimentoService } from '../../requisicao/service/requisicao-movimento.service';
import { RequisicaoItemMovimentoService } from '../service/requisicao-item-movimento.service';
@Component({
  selector: 'lib-requisicao-autorizacao-devolucao-form',
  templateUrl: './requisicao-autorizacao-devolucao-form.component.html'
})
export class RequisicaoAutorizacaoDevolucaoFormComponent extends BaseResourceFormComponent<RequisicaoMovimento, LoginContabil> {
  /**
   * Declaração de variáveis
   */
  public listSetores: SetorAlmoxarifado[];
  public itens: RequisicaoItemMovimento[];

  public pessoaAutoComplete: EddyAutoComplete<Pessoa>;

  protected unsubscribe: Subject<void> = new Subject();

  public visualizarMotivoRecusa: boolean = false;
  public visualizarAceiteAut: boolean = false;

  public event: 'R' | 'A';

  public listaAceite: RequisicaoItemMovimento[] = [];

  public formato: any;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public globalService: GlobalService,
    public funcaoService: FuncaoService,
    private confirmationService: ConfirmationService,
    protected requisicaoMovService: RequisicaoMovimentoService,
    protected requisicaoItemService: RequisicaoItemMovimentoService,
    private saldoEstoqueService: SaldoEstoqueService,
    private parametroAlmoxarifadoService: ParametroAlmoxarifadoService,
    protected pessoaService: PessoaService) {
    super(new RequisicaoMovimento(), injector, RequisicaoMovimento.converteJson, requisicaoMovService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      data_cadastro: [new Date()],
      status: [StatusRequisicao.AGUARDANDO, Validators.required],
      destinatario: [null],
      motivo_devolucao: [null],
      devolucao: [false, Validators.required],
      setor_almoxarifado: [null, Validators.required],
      usuario: [this.login.usuario],
      usuario_aceite: [this.login.usuario],
      data_retirada: [new Date()],
      exercicio: [this.login.exercicio],
      orgao: [this.login.orgao],
      recebedor: [null, [Validators.required]],
      itens: [null],
      setor_almoxarifado_saida: [null],
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: [
        'usuario', 'setor_almoxarifado', 'setor_almoxarifado.setor', 'setor_almoxarifado.estoque',
        'exercicio', 'orgao', 'usuario', 'recebedor'
      ]
    };
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected async podeAlterar(entidade: RequisicaoMovimento): Promise<boolean> {
    const mesEncerrado = await this.parametroAlmoxarifadoService.mesFechadoAlmoxarifadoa(this.login, this.login.setorAlmoxarifado ? this.login.setorAlmoxarifado.estoque.id : this.login.estoque.id);
    return entidade.status === 'A' && !mesEncerrado;
  }

  protected async afterLoad() {
    this.itens = this.entidade.itens;
    if (this.entidade.status !== StatusRequisicao.AGUARDANDO)
      this.entidadeForm.disable();

    this.requisicaoItemService.extendido(1, -1, {
      'requisicao.id': this.entidade.id,
      'relations': ['produto_unidade', 'produto_unidade.produto', 'produto_unidade.unidade', 'produto_unidade_troca',
        'produto_unidade_troca.produto', 'produto_unidade_troca.unidade']
    }).subscribe((res) => {
      this.itens = res ? res.content : new Array<RequisicaoItemMovimento>();
      this.carregarLotes();
    }, error => this.funcaoService.acaoErro(error))
  }

  private carregarLotes() {
    if (!this.itens)
      return;
    this.itens.forEach((i) => {
      if (i.lote)
        return;
      this.saldoEstoqueService
        .saldoProdutoUnidadeLoteVencimento(i.produto_unidade.id, this.login.setorAlmoxarifado.id, { nao_obg_saldo: true, saida_obrigatoria: true })
        .subscribe((saldos) => {
          if (!saldos || saldos.length === 0)
            return;
          let saldo = saldos.sort((a, b) => new Date(new Date(a.vencimento).toDateString()).getTime() - new Date(new Date(b.vencimento).toDateString()).getTime())[0];
          i.lote = saldo.lote;
          i.vencimento = new DateFormatPipe().transform(saldo.vencimento, []);
          i['saldo_atual'] = +saldo.saldo_atual;
          i['saida'] = +saldo.saida;
        });
    })
  }

  protected beforeSubmit() {
    try {
      this.entidadeForm.get('setor_almoxarifado_saida').setValue(this.login.setorAlmoxarifado);
      if (!this.entidadeForm.value.recebedor || !this.entidadeForm.value.recebedor.id) {
        throw new Error('Recebedor obrigatorio!');
      }
      this.entidadeForm.get('itens').setValue(this.itens);
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  protected afterSubmit(ent: RequisicaoMovimento) {
    this.entidadeForm.get('itens').setValue(ent.itens);

  }

  public afterAprovar() {
    this.beforeSubmit();
    const recusados = this.itens.filter((i) => +i.qtd_aceita === 0);
    if (recusados.length > 0)
      this.confirm(`Foi identificado itens sem quantidade de aceite, desejá rescusar todos os itens ou fazer o aceite automatico?`, `Confirmação de itens sem quantidade de aceite`, { labelSim: 'Aceitar', labelNao: 'recusar' })
        .then((aceite) => {
          if (aceite) {
            this.carregarAceiteAutomatico();
          } else {
            this.visualizarMotivoRecusa = true;
            this.event = 'A';
          }
        })
    else
      this.aprovar();
  }

  private async carregarAceiteAutomatico() {
    let lista = this.itens.map((i) => Object.assign(new RequisicaoItemMovimento(), i))
      .filter((i) => +i.qtd_aceita === 0);
    for (let i of lista) {
      let saldos = await this.saldoEstoqueService
        .saldoProdutoUnidadeLoteVencimento(i.produto_unidade.id, this.login.setorAlmoxarifado.id, { nao_obg_saldo: true, saida_obrigatoria: true }).toPromise();
      if (!saldos || saldos.length === 0) {
        toastr.warning(`Item ${i.produto_unidade.produto.nome} - ${i.produto_unidade.unidade.nome} não possui saldo para aceite`);
        return;
      }

      let saldo = saldos.sort((a, b) => new Date(new Date(a.vencimento).toDateString()).getTime() - new Date(new Date(b.vencimento).toDateString()).getTime())[0];
      if ((+saldo.saida < +i.qtd_requisitada)) {
        toastr.warning(`Item ${i.produto_unidade.produto.nome} - ${i.produto_unidade.unidade.nome} não possui saldo suficiente para aceite, saldo do lote/vencimento ${saldo.lote}/${this.funcaoService.converteDataBR(saldo.vencimento)} é de ${this.funcaoService.convertToBrNumber(saldo.saida)}`);
        return;
      }

      i['saldo_atual'] = +saldo.saldo_atual;
      i['saida'] = +saldo.saida;
      i.lote = saldo.lote;
      i.vencimento = new DateFormatPipe().transform(saldo.vencimento, []);


      const saldo_atual = i['saida'];
      if (saldo_atual >= +i.qtd_requisitada) {
        i.qtd_aceita = +i.qtd_requisitada;
      } else {
        i.qtd_aceita = saldo_atual;
      }
    }
    this.visualizarAceiteAut = true;
    this.listaAceite = lista;
  }

  public afterAceiteAutomatico(lista: RequisicaoItemMovimento[]) {
    this.itens = this.itens.map((i) => {
      let item2 = lista.find((l) => l.id === i.id);
      if (item2) {
        i.lote = item2.lote;
        i.vencimento = item2.vencimento;
        i.qtd_aceita = item2.qtd_aceita;
      }
      return i;
    });
    this.aprovar()
  }

  public afterRecusar() {
    this.itens = this.itens.map((i) => {
      i.qtd_aceita = 0;
      return i;
    })
    this.visualizarMotivoRecusa = true;
    this.event = 'R';
  }

  public afterMotivoRecusa(itens: RequisicaoItemMovimento[]) {
    this.itens = itens;
    if (this.event === 'A')
      this.aprovar();
    else
      this.recusar();
  }

  public async aprovar() {
    this.disableButton = true;
    this.entidadeForm.get('data_retirada').setValue(this.funcaoService.obterDataUTC(new Date()));
    this.beforeSubmit();
    this.submitted = true;
    if (this.entidadeForm.invalid) {
      toastr.error('Há campos obrigatórios não informados!');
      return;
    }
    const entidade: RequisicaoItemMovimento = this.jsonDataToResourceFn(this.entidadeForm.value);
    this.requisicaoMovService
      .aprovar(entidade)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.submitted = false;
        if (res) {
          toastr.success(`Salvo com sucesso.`);

          new ComprovanteRequisicaoRpt(this.login, this.globalService).imprimir(entidade, this.formato);

          setTimeout(() => {
            this.router.navigate(['/requisicoes-autorizacao-devolucao', res.id, 'visualizar']);
          }, 100);
        } else {
          this.sair();
        }
      },
        error => this.acaoErro(error));
  }

  public async recusar() {
    this.disableButton = true;
    this.submitted = true;
    this.entidadeForm.get('itens').setValue(this.itens);
    const entidade: RequisicaoMovimento = this.jsonDataToResourceFn(this.entidadeForm.value);
    this.requisicaoMovService
      .recusar(entidade)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.submitted = false;
        if (res) {
          toastr.success(`Recusado com sucesso.`);
          this.router.navigate(['/requisicoes-autorizacao', res.id, 'visualizar']);
        } else {
          this.sair();
        }
      },
        error => this.acaoErro(error));
  }


  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private carregarAutoCompletes() {
    // autocomplete para produto
    this.pessoaAutoComplete = new EddyAutoComplete(this.entidadeForm.get('pessoa'), this.pessoaService,
      'id', ['nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );
  }


  public valorRequisitado() {
    if (!this.itens)
      return 0.0;
    let vl = 0.0;
    for (let it of this.itens) {
      if (+it.qtd_requisitada)
        vl += Number.parseFloat(it.qtd_requisitada.toString());
    }
    return vl;
  }

  public valorAceito() {
    if (!this.itens)
      return 0.0;
    let vl = 0.0;
    for (let it of this.itens) {
      if (it.qtd_aceita)
        vl += Number.parseFloat(it.qtd_aceita.toString());
    }
    return vl;
  }

  public valorRecusado() {
    if (!this.itens)
      return 0.0;
    let vl = 0.0;
    for (let it of this.itens) {
      if (it.status && it.status !== StatusRequisicao.AGUARDANDO)
        vl += Number.parseFloat(it.qtd_requisitada.toString()) - Number.parseFloat(it.qtd_aceita.toString());
    }
    return vl;
  }

  public tituloStatusRequisicao(status: StatusRequisicao): string {
    return this.globalService.tituloStatusRequisicao(status);
  }

  public iconStatusRequisicao(status: StatusRequisicao): {} {
    let icon = this.globalService.iconStatusRequisicao(status);
    let ngIcon = {};
    ngIcon[icon] = true;
    return ngIcon;
  }

  public corStatusRequisicao(status: StatusRequisicao): {} {
    let cor = this.globalService.corStatusRequisicao(status);
    let ngCor = {};
    ngCor[`bg-${cor}`] = true;
    return ngCor;
  }

  public badgeStatusRequisicao(status: StatusRequisicao): {} {
    let cor = this.globalService.corStatusRequisicao(status);
    let ngCor = {};
    ngCor[`badge-${cor}`] = true;
    return ngCor;
  }

  public async confirm(mensagem: string, titulo: string, parametros?: { labelSim?: string, labelNao?: string }): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.confirmationService.confirm({
        message: mensagem,
        header: titulo,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: parametros?.labelSim ? parametros.labelSim : 'Sim',
        rejectLabel: parametros?.labelNao ? parametros.labelNao : 'Não',
        key: 'rrm',
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        }
      });
    });
  }

}
