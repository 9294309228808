// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Unidade } from '../planejamento/unidade.model';

export class Chamamento extends BaseResourceModel {
  constructor(
    public id?: number,
    public numero?: string,
    public titulo?: string,
    public controle?: string,
    public situacao?: string,
    public lei_numero?: string,
    public lei_data?: Date,
    public modalidade?: string,
    public especie?: string,
    public tipo_repasse?: string,
    public valor_repasse?: number,
    public data_prazo_inicial?: Date,
    public data_prazo_termino?: Date,
    public data_inicio?: Date,
    public data_termino?: Date,
    public data_audiencia?: Date,
    public objeto?: string,
    public justificativa?: string,
    public enviar_email?: boolean,
    public unidade?: Unidade,
    public orgao?: Orgao,
    public nome_repasse?: string,
  ) {
    super();
  }
  static converteJson(json: any): Chamamento {
    return Object.assign(new Chamamento(), json);
  }
}
