import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, Recebimento, Page } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class RecebimentoService extends BaseResourceService<Recebimento> {

  constructor(
    protected injector: Injector
  ) {
    super(`recebimentos`, injector);
  }

  public totalArrecadadoAno(exercicio: number, orgaoId: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/total-arrecadado-ano/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalArrecadadoMes(exercicio: number, orgaoId: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/total-arrecadado-mes/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalArrecadadoTributo(exercicio: number, orgaoId: number, codigo: string): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/total-arrecadado-tributo/${exercicio}/${orgaoId}/${codigo}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public planilhaArrecadacao(exercicio: number, orgaoId: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/planilha-arrecadacao/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorNumero(numero: number, ano: number, orgaoId: number): Observable<Recebimento> {
    return this.http.get<Recebimento>(
      `${this.login.cidade.id}/${this.api}?numero=${numero}&exercicio_ano=${ano}&orgao_id=${orgaoId}`).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public transparencia(pagina: number, limite: number, filtros: {}): Observable<Page> {
    let parametros = '';
    if (filtros) {
      // tslint:disable-next-line: forin
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + encodeURIComponent(filtros[key]);
      }
    }

    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/transparencia/${pagina}/${limite}${parametros}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public ultimaData(exercicio: number, orgaoId: number): Observable<any> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/ultima-data/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public leituraArquivo(arquivo: any) {
    const formData = new FormData();
    formData.append('file', arquivo);

    const headers = this.httpOptions();
    headers.headers = headers.headers.delete('Content-Type');

    return this.http.post(`${this.login.cidade.id}/${this.api}/leitura-arquivo/${this.login.orgao.id}/${this.login.exercicio.id}`,
      formData, headers)
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  importarRecebimentos(resource: Recebimento[]): Observable<any> {

    return this.http.post(`${this.login.cidade.id}/${this.api}/importar-recebimentos`, resource, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public dataReferencia(exercicio: number, orgaoId: number, receita: string, ficha: number): Observable<any> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/data-referencia/${exercicio}/${orgaoId}/${receita}/${ficha}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public exclusaoLote(exercicio: number, orgaoId: number, nomeArquivo: string): Observable<any> {
    return this.http.delete(`${this.login.cidade.id}/${this.api}/exclusao-lote/${exercicio}/${orgaoId}/${nomeArquivo}`, this.httpOptions()).pipe(
      map(res => res), catchError(err => this.handleError(err))
    )
  }

  public conferenciaRecebimentoDiario(filtros: any) {
    let parametros = ''
    for (const key in filtros) {
      if (parametros === '') {
        parametros += '?';
      } else {
        parametros += '&';
      }
      parametros += key + '=' + encodeURIComponent(filtros[key]);
    }

    return this.http.get<any[]>(`${this.login.cidade.id}/${this.api}/conferencia-recebimento-diario${parametros}`, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      )
  }

}
