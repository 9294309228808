import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Adiantamento, Empenho, GlobalService, Login, ParametroContabil, ParametroContabilService } from 'eddydata-lib';
import * as toastr from 'toastr';
import { AdiantamentoService } from 'administrativo-lib';

declare var $: any;

@Component({
  selector: 'app-adiantamento-cadastro-dlg',
  templateUrl: './adiantamento-cadastro-dlg.component.html'
})
export class AdiantamentoCadastroDlgComponent implements OnInit, AfterViewInit, OnDestroy {

  public ptBR: any;
  public listaTipos: Array<any>;
  public listaEspecies: Array<any>;
  public parametro: ParametroContabil;
  protected unsubscribe: Subject<void> = new Subject();
  public adiantamento = new Adiantamento();

  @Input() login: Login;
  @Input() empenho: Empenho;

  constructor(
    private globalService: GlobalService,
    private adiantamentoService: AdiantamentoService,
    protected parametroContabilService: ParametroContabilService,
  ) {
  }

  async ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.listaTipos = [
      { id: 'S', nome: 'SERVIDOR PÚBLICO' },
      { id: 'A', nome: 'AGENTE POLÍTICO' },
      { id: 'V', nome: 'VEREADOR' },
      { id: 'O', nome: 'OUTRO' }
    ];
    this.listaEspecies = [
      { id: 'N', nome: 'NUMERARIAS' },
      { id: 'D', nome: 'DIÁRIAS' }
    ];

    const parametro = await this.parametroContabilService.filtrar(1, -1, { relations: 'orgao', 'orgao_id': this.login.orgao.id, }).toPromise();
    this.parametro = parametro?.content?.length ?  parametro.content[0]: this.login?.parametro['contabil'];

    this.adiantamento.motivo = this.empenho.historico;
    this.adiantamento.data_adiantamento = this.empenho.data_empenho;
    this.adiantamento.valor_adiantado = this.empenho.valor_empenho;
    this.adiantamento.empenho = this.empenho;
    this.adiantamento.beneficiario = this.empenho.favorecido.nome;
    this.adiantamento.exercicio = this.login.exercicio;
    this.adiantamento.orgao = this.login.orgao;
    this.adiantamento.situacao = 'A';
    this.adiantamento.valor_complemento = 0;
    this.adiantamento.data_vencimento = this.empenho.data_vencimento;
    this.adiantamento.oficio = this.empenho.processo;
    if (this.empenho.favorecido.contas) {
      let contaPrincipal = this.empenho.favorecido.contas.find((m) => m.principal === true);
      if (contaPrincipal) {
        this.adiantamento.banco = contaPrincipal.banco.nome;
        this.adiantamento.agencia = contaPrincipal.agencia;
        this.adiantamento.numero_conta = contaPrincipal.numero_conta;
      } else {
        let contaAtiva = this.empenho.favorecido.contas.find((m) => m.ativo === true);
        if (contaAtiva) {
          this.adiantamento.banco = contaAtiva.banco.nome;
          this.adiantamento.agencia = contaAtiva.agencia;
          this.adiantamento.numero_conta = contaAtiva.numero_conta;
        }
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
  }

  public carregarMascara() {
    new GlobalService().calendarMascara();
  }

  async inserir() {
    if (!this.adiantamento.data_liberacao && !this.parametro?.puxar_data_automatica) {
      toastr.error('Adicione a data de liberação!');
      return;
    }
    if (new Date(this.adiantamento.data_partida).getTime() < new Date(this.empenho.data_empenho).getTime()) {
      toastr.error('A data de partida não pode ser menor que a data do empenho.');
      return;
    }
    this.adiantamentoService.obterUltimoAdiantamento(this.login.orgao.id, this.login.exercicio.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((numero) => {
        this.adiantamento.numero = numero;
        this.adiantamento.mes = (this.adiantamento.data_adiantamento.getUTCMonth() + 1);
        this.adiantamento.data_vencimento = new Date(this.adiantamento.data_liberacao);
        this.adiantamento.data_vencimento.setDate(this.adiantamento.data_vencimento.getDate() + 30);
        this.adiantamentoService
          .atualizar(this.adiantamento)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(res => {
            $('#dlgAdiantamentoCadastro').modal('hide');
            toastr.success('Processo de adiantamento cadastrado com sucesso!');
          },
            error => {
              if (error.error && error.error.payload) {
                toastr.error(error.error.payload);
              } else {
                toastr.error('Não foi possível fazer o cadastro do processo de adiantamento!');
              }
            });
      });
  }
}
