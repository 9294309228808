import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Usuario } from "../../entidade/comum/usuario.model";
import { Orgao } from "../../entidade/comum/orgao.model";
import { FuncaoService } from "../../util/funcao.service";
import { GlobalService } from "../../util/global.service";
import { OrgaoService } from "../../orgao/service/orgao.service";
import { UsuarioService } from "../../usuario/service/usuario.service";
import { Sistemas } from "../../components/types";
import * as toastr from "toastr";
import { environment } from "../../../environments/environment";
import { LoginService } from "../servico/login.service";

@Component({
  selector: "lib-login-registro",
  templateUrl: "./login-registro.component.html",
  styleUrls: ["./login-registro.component.css"],
})
export class LoginRegistroComponent implements OnInit, OnDestroy {
  @ViewChild("nome") nomeField: ElementRef;
  @ViewChild("f") myForm: NgForm;

  @Input() sistema: Sistemas;

  public sis: Sistemas;
  public entidade: Usuario = new Usuario();
  public listaOrgaos: Array<Orgao>;
  public listaSistemas: { id: Sistemas, nome: string }[];
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected orgaoService: OrgaoService,
    protected usuarioService: UsuarioService,
    protected authService: LoginService
  ) {}

   ngOnInit() {

    this.sis = this.obterSistema(document.title)

    this.orgaoService
      .obterTodos()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaOrgaos = [];

        const orgao = !environment.orgao ? "020000" : environment.orgao;

        for (const model of res) {
          this.listaOrgaos.push(model);
          if (model.codigo.replace(".", "") === orgao) {
            this.entidade.orgao = model;
          }
        }
      });
    this.listaSistemas = this.globalService.obterListaSistemas();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public registrar(): void {
    if (this.myForm.valid) {
      if (this.sistema === "controle-interno") {
        this.entidade.convidado = true;
      }
      this.entidade.sistema = this.sis;
      this.usuarioService
        .registrar(this.entidade)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data) => {

            this.entidade = new Usuario(); 
            this.myForm.reset();
            // reseta os campos
            for (const name in this.myForm.controls) {
              this.myForm.controls[name].setErrors(null);
            }
            toastr.success(
              "Uma senha provisória de acesso foi direcionada ao seu e-mail. O acesso será possível após autorizado pelo administrador do sistema.",
              "Seu registro foi enviado com sucesso"
            );
            this.router.navigate(["/login"]);
          },
          (error) => this.funcaoService.acaoErro(error)
        );
    } else {
      toastr.error(
        "Verifique se todos os campos foram preenchidos e tente novamente"
      );
    }
  }

  public sair() {
    this.router.navigate([`/login`]);
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  public obterSistema(title: string): Sistemas{

    switch (title){
      case 'Almoxarifado':
        return 'almoxarifado';
      case 'Compras':
        return 'compras';
      case 'Contabilidade':
        return 'contabil';
      case 'Frota': 
        return 'frota';
      case 'Licitação':
        return 'licitacao';
      case 'Patrimônio':
        return 'patrimonio';
      case 'Requisição':
        return 'requisicao';
    }

  }
}
