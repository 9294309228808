import { BaseResourceModel } from '../../models/base-resource.model';
import { Cidade } from '../geo/cidade.model';

export class Cnae extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public nome?: string,
    public fator?: boolean,
    public anexo?: boolean,
    public ativo?: boolean,
    public cidade?: Cidade,
    public editavel?: boolean
    ) {
    super();
  }
  static converteJson(json: any): Cnae {
    return Object.assign(new Cnae(), json);
  }
}
