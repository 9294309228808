import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FuncaoService, Login, RcmsFavorecido } from 'eddydata-lib';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'app-compra-rcms-dlg',
  templateUrl: './compra-rcms-dlg.component.html'
})
export class CompraRcmsDlgComponent implements OnInit {

  @Input() login: Login;

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Input() public favorecidos: RcmsFavorecido[] = [];
  @Output() selecionarFavorecido: EventEmitter<RcmsFavorecido> = new EventEmitter();

  public favorecido: RcmsFavorecido;
  public selectedValues: boolean = false;

  constructor(public funcaoService: FuncaoService) { }

  ngOnInit(): void { }

  public selecionarFavorecidoEvent() {
    let favorecido = Object.assign({}, this.favorecido);
    favorecido.cotacoes = favorecido.cotacoes.filter((c) => c['selecionar']);
    if (!favorecido.cotacoes || favorecido.cotacoes.length === 0) {
      toastr.warning('Nenhum Item selecionado');
      return;
    }
    if(+favorecido.favorecido.tipo.tce !== 1 && +favorecido.favorecido.tipo.tce !== 2 && +favorecido.favorecido.tipo.tce !== 10){
      toastr.warning('Verifique a espécie no cadastro de fornecedor');
      this.selecionarFavorecido.emit(null)
      this.onHide();
      return;
    }
    this.selecionarFavorecido.emit(favorecido);
    this.onHide();
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }


  public carregarFavorecido(favorecido: RcmsFavorecido) {
    this.favorecido = favorecido;
    this.favorecido.cotacoes = this.favorecido.cotacoes
      .map((c) => {
        c['selecionar'] = true;
        return c;
      });
  }

  public compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }
}