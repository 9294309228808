import { BaseResourceStorage } from '../../models/base-resource.storage';
import { Imovel } from './imovel.model';

export class ImovelStorage extends BaseResourceStorage {
  constructor(
    public foto?: boolean,
    public principal?: boolean,
    public imovel?: Imovel,
  ) {
    super();
  }

  static converteJson(json: any): ImovelStorage {
    return Object.assign(new ImovelStorage(), json);
  }
}
