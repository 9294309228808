import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Unidade } from '../planejamento/unidade.model';
import { NoticiaComentario } from './noticia-comentario.model';

export class Noticia extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_publicacao?: Date,
    public assunto?: string,
    public resumo?: string,
    public imagem?: string,
    public texto?: string,
    public situacao?: string,
    public tipo?: string,
    public unidade?: Unidade,
    public orgao?: Orgao,
    public comentario?: NoticiaComentario,
    public comentarios?: Array<NoticiaComentario>
  ) {
    super();
  }

  static converteJson(json: any): Noticia {
    return Object.assign(new Noticia(), json);
  }
}
