// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ContaBancaria } from './conta-bancaria.model';
import { Recurso } from '../planejamento/recurso.model';
import { RecursoSiconfiComplemento } from '../planejamento/recurso-siconfi-complemento.model';
import { RecursoSiconfi } from '../planejamento/recurso-siconfi.model';
import { Usuario } from '../comum/usuario.model';

export class ContaBancariaRecurso extends BaseResourceModel {
  constructor(
    public id?: number,
    public conta?: ContaBancaria,
    public recurso?: Recurso,
    public aplicacao?: Recurso,
    public convenio?: Recurso,
    public aux?: number,
    public recurso_pagto?: boolean,
    public domicilio?: boolean,
    public recurso_siconfi?: RecursoSiconfi,
    public complemento_siconfi?: RecursoSiconfiComplemento,
    public excluido?: boolean,
    public data_exclusao?: Date,
    public usuario_exclusao?: Usuario,
    public editavel?: boolean
  ) {
    super();
  }

  static converteJson(json: any): ContaBancariaRecurso {
    return Object.assign(new ContaBancariaRecurso(), json);
  }
}
