import { Component, EventEmitter, Injector, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as toastr from 'toastr';
import { ConfirmationService } from 'primeng/api';
import { PlanoContratacaoAnualService } from 'compra-lib';
import { Login } from '../../../entidade/login/login';
import { PlanoContratacaoAnual } from '../../../entidade/compra/plano-contratacao-anual.model';
import { PNCPService } from '../../../util/pncp.service';
import { ProgressoService } from '../../../components/progresso/service/progresso.service';
import { FuncaoService } from '../../../util/funcao.service';
import { RegistroPncpService } from '../../service/registro-pncp.service';
import { PlanoContratacaoItem } from '../../../entidade/compra/plano-contratacao-item.model';

declare var google: any;

@Component({
  selector: 'app-pncp-plano-cadastro',
  templateUrl: './pncp-plano-cadastro.component.html'
})
export class PncpPlanoCadastroComponent implements OnInit {

  @Input() login: Login;
  @Input() plano: PlanoContratacaoAnual = null;
  @Output() callback: EventEmitter<void> = new EventEmitter();

  totalServico: number = 0;
  quantidadeServico: number = 0;
  totalMaterial: number = 0;
  quantidadeMaterial: number = 0;
  totalCadastrado: number = 0;

  resultados: Array<{ item: string, sucesso: boolean, mensagem: string }> = new Array<{ item: string, sucesso: boolean, mensagem: string }>();

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected planoService: PlanoContratacaoAnualService,
    protected confirmationService: ConfirmationService,
    protected pncpService: PNCPService,
    protected progressoService: ProgressoService,
    private funcaoService: FuncaoService,
    private service: RegistroPncpService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.carregarBase();
  }

  carregarBase() {
    this.totalMaterial = 0;
    this.totalServico = 0;
    this.quantidadeMaterial = 0;
    this.quantidadeServico = 0;
    this.totalCadastrado = 0;
    for (const i of this.plano.itens) {
      if (!i.cadastrado_pncp) {
        continue;
      }

      this.totalCadastrado++;

      if (i.classificacao_catalogo == 1) {
        this.totalMaterial += +i.valor_unitario * +i.quantidade;
        this.quantidadeMaterial++;
      } else {
        this.totalServico += +i.valor_unitario * +i.quantidade;
        this.quantidadeServico++;
      }
    }

    this.grafico();
  }

  idPncp(): string {
    return `${this.login.orgao.cnpj.replace(/\D/g, '')}-1-${("000000" + this.plano.sequencial_pncp).slice((this.plano.sequencial_pncp + '').length)}/${this.plano.exercicio.ano + 1}`;
  }

  abrirPncp(orgao: boolean) {
    const ano = this.plano.exercicio.ano + 1;
    const sequencial_pncp = this.plano.sequencial_pncp;

    window.open(this.pncpService.abrirPortalPNCP(this.plano.orgao.cnpj, ano, (orgao ? null : sequencial_pncp), 'P'), '_blank');
  }

  private async grafico() {
    const lista = [];
    const lista2 = [];

    lista.push(['Categoria', 'Valor']);
    lista.push(['Material', this.totalMaterial]);
    lista.push(['Serviços', this.totalServico]);

    lista2.push(['Categoria', 'Quantidade']);
    lista2.push(['Material', +this.quantidadeMaterial]);
    lista2.push(['Serviços', +this.quantidadeServico]);

    google.charts.load('current', { packages: ['corechart'], language: 'pt_BR' });
    google.charts.setOnLoadCallback(drawChart);

    function drawChart() {

      const data = google.visualization.arrayToDataTable(
        lista
      );
      const data2 = google.visualization.arrayToDataTable(
        lista2
      );

      const options = {
        title: 'Valor Total Estimado por Categoria',
        legend: { position: 'top' }
      };
      const options2 = {
        title: 'Quantidade por Categoria',
        legend: { position: 'top' }
      };

      const chart = new google.visualization.BarChart(document.getElementById('chart1'));
      const chart2 = new google.visualization.BarChart(document.getElementById('chart2'));
      chart.draw(data, options);
      chart2.draw(data2, options2);
    }

  }


  apagarPlano() {
    this.confirmationService.confirm({
      header: 'Apagar Plano de Contratação',
      message: `<pre>Confirma a exclusão desse Plano de Contratação Anual? Essa ação NÃO pode ser desfeita.</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo exclusão.',
      rejectLabel: 'Não',
      accept: async () => {
        this.pncpService.excluirPlanoContratacao(this.plano).subscribe(async (res) => {
          console.log('Chegou no OK')
          const sequencial = this.plano.sequencial_pncp
          this.plano.cadastrado_pncp = false;
          this.plano.sequencial_pncp = null;
          await this.planoService.atualizar(this.plano).toPromise();
          this.plano = null;
          toastr.success('Atualizado com sucesso!');
          this.callback.emit();
        }, (e) => {
          console.log('Deu erro');
          this.funcaoService.acaoErro(e);
        });
      }
    });
  }


  enviarListaItens() {
    this.confirmationService.confirm({
      header: 'Envio de item',
      message: '<pre>Confirma o envio de todos itens ao PNCP? Lembrando que por limitações do PNCP, '
        + 'apenas 1.000 itens serão enviados por vez, itens excedentes devem ser enviados posteriormente acionando a rotina novamente.</pre>.',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo envio.',
      rejectLabel: 'Não',
      accept: async () => {
        const itens = [];
        const copia = [];
        for (const i of this.plano.itens) {
          copia.push(Object.assign({}, i));
          if (i.cadastrado_pncp) continue;

          if (itens.length === 1000) continue;

          i.cadastrado_pncp = true;
          itens.push(i);
        }

        this.pncpService.enviarItensPlano(this.plano, itens).subscribe(async (res) => {
          await this.planoService.atualizar(this.plano).toPromise();
          toastr.success('Item enviado com sucesso!');
          this.carregarBase();
        }, (e) => {
          let texto = '';
          this.plano.itens = copia;
          if (e.erros) {
            for (const er of e.erros) {
              texto += er.mensagem + '\n';
            }
          }
          if (e.error) {
            texto += e.error.message;
          }
          toastr.error(texto);
        });
      }
    });
  }

  enviarItem(item: PlanoContratacaoItem) {
    this.confirmationService.confirm({
      header: 'Envio de item',
      message: `<pre>Confirma o envio do item (inclusão pós-cadastro) ${item.classificacao_superior_nome}?</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo envio.',
      rejectLabel: 'Não',
      accept: async () => {
        this.pncpService.enviarItensPlano(this.plano, [item]).subscribe(async (res) => {
          this.plano.itens[this.plano.itens.indexOf(item)].cadastrado_pncp = true;
          //Copia para salvar somente o objeto modificado
          const p = Object.assign({}, this.plano);
          p.itens = [];
          item.cadastrado_pncp = true;
          p.itens.push(item);
          await this.planoService.atualizar(p).toPromise();
          toastr.success('Item enviado com sucesso!');
          this.carregarBase();
        }, (e) => {
          let texto = '';
          if (e.erros) {
            for (const er of e.erros) {
              texto += er.mensagem + '\n';
            }
          }
          if (e.error) {
            texto += e.error.message;
          }
          toastr.error(texto);
        });
      }
    });
  }

  apagarListaItens() {
    this.confirmationService.confirm({
      header: 'Apagar Itens enviados',
      message: `<pre>Confirma a remoção de todos itens? A rotina <b>pode levar varios minutos</b> pois o PNCP irá processar item por item.</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo exclusão.',
      rejectLabel: 'Não',
      accept: () => {
        const lista = [];
        const copia = [];

        for (const i of this.plano.itens) {
          copia.push(Object.assign({}, i));
          if (i.cadastrado_pncp) {
            i.cadastrado_pncp = false;
            lista.push(i);
          }
        }

        this.pncpService.excluirListaItensPlanoContratacao(this.plano, lista).subscribe(async (res) => {
          await this.planoService.atualizar(this.plano).toPromise();
          toastr.success('Itens apagados com sucesso!');
          this.carregarBase();
        }, (e) => {
          let texto = '';
          this.plano.itens = copia;
          if (e.erros) {
            for (const er of e.erros) {
              texto += er.mensagem + '\n';
            }
          }
          if (e.error) {
            texto += e.error.message;
          }
          toastr.error(texto);
        });
      }
    });
  }


  apagarItem(item: PlanoContratacaoItem) {
    this.confirmationService.confirm({
      header: 'Apagar Item enviado',
      message: `<pre>Confirma a remoção do item? ${item.classificacao_superior_nome}</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo exclusão.',
      rejectLabel: 'Não',
      accept: async () => {
        this.pncpService.excluirItemPlanoContratacao(this.plano, item).subscribe(async (res) => {
          this.plano.itens[this.plano.itens.indexOf(item)].cadastrado_pncp = false;
          //Copia para salvar somente o objeto modificado
          const p = Object.assign({}, this.plano);
          p.itens = [];
          item.cadastrado_pncp = false;
          p.itens.push(item);
          await this.planoService.atualizar(p).toPromise();
          toastr.success('Item removido com sucesso!');
          this.carregarBase();
        }, e => {
          if (e.error?.message) {
            toastr.error(e.error.message);
          } else {
            this.funcaoService.acaoErro(e);
          }
        });
      }
    });
  }

}
