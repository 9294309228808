import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ProdutoLocalizacao } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ProdutoLocalizacaoService extends BaseResourceService<ProdutoLocalizacao> {

  constructor(
    protected injector: Injector
  ) {
    super(`produto-localizacoes`, injector);
  }

}