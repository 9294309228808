import { Injectable, OnDestroy } from '@angular/core';
import { AnexoLoaService } from '../service/anexo-loa.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuncaoService, LoginContabil, Exercicio, Relatorio, GlobalService } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class Anexo2Executora implements OnDestroy {
  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLoaService) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // tslint:disable: max-line-length
  public montarRelatorio(exercicio: Exercicio, orgaos: number[]) {
    this.anexoServico.obterAnexo2Executora(exercicio.id, orgaos, this.login.ppa.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        Relatorio.imprimirPersonalizado(
          'DEMONSTRATIVO DETALHADO SEGUNDO AS CATEGORIAS ECONÔMICAS - POR UNIDADE EXECUTORA'
          , this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          this.conteudo(dados, exercicio),
          'portrait', 'ANEXO 2 - POR UNIDADE EXECUTORA',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private conteudo(dados: any[], exercicio: Exercicio): {}[] {    
    // monta o cabecalho
    const registros: {}[] = [[{
      text: `EXERCÍCIO ${exercicio.ano}`, fontSize: 11,
      alignment: 'center', bold: true, colSpan: 6, border: [true, true, true, false]
    }, '', '', '', '', ''],
    [{
      text: 'Lei nº 4.320/64, Art. 2º, §1º, II - Anexo 2',
      alignment: 'center',
      bold: true,
      colSpan: 6, border: [true, false, true, false]
    }, '', '', '', '', '']
    ];

    // monta o agrupamento do relatório
    const grupos = this.funcaoService.agrupar(dados, ['orgao','unidade','executora'], ['total']);
    let soma1 = 0;
    for (const grupo of grupos) {
      let total6 = 0;
      registros.push(
        [{
          text: this.funcaoService.mascarar('##.##.## *', grupo.grupo['orgao'] as string), fontSize: 8,
          bold: true, colSpan: 6, border: [true, true, true, false], margin: [0, 5, 0, 0]
        }, '', '', '', '', ''],
        [{
          text: this.funcaoService.mascarar('##.##.## *', grupo.grupo['unidade'] as string), fontSize: 8,
          bold: true, colSpan: 6, border: [true, false, true, false],
        }, '', '', '', '', ''],
        [{
          text: this.funcaoService.mascarar('##.##.## *', grupo.grupo['executora'] as string), fontSize: 8,
          bold: true, colSpan: 6, border: [true, false, true, false], margin: [0, 0, 0, 5]
        }, '', '', '', '', ''],
        [{
          text: 'CÓDIGO',
          alignment: 'center',
          bold: true, fontSize: 7
        }, {
          text: 'ESPECIFICAÇÃO',
          alignment: 'center',
          bold: true, fontSize: 7
        }, {
          text: 'ELEMENTO',
          alignment: 'center',
          bold: true, fontSize: 7
        }, {
          text: 'MODALIDADE DE APLICAÇÃO',
          alignment: 'center',
          bold: true, fontSize: 7
        }, {
          text: 'GRUPO DE DESPESA',
          alignment: 'center',
          bold: true, fontSize: 7
        }, {
          text: 'CATEGORIA ECONÔMICA',
          alignment: 'center',
          bold: true, fontSize: 7
        }]
      );
      // lista os registros do relatorio
      for (const registro of grupo.registros) {

        registros.push([
          {
            text: `${this.funcaoService.mascarar('#.#.##.##.##', registro.codigo)}`, fontSize: 7, border: [true, false, false, false], bold: (registro.nivel === 0 || registro.nivel === 1) ? true : false
          },
          {
            text: `${registro.nome_despesa}`, fontSize: 7, border: [true, false, false, false], bold: (registro.nivel === 0 || registro.nivel === 1) ? true : false
          },
          {
            text: registro.nivel === 3 || registro.nivel === 4 ? this.funcaoService.convertToBrNumber(registro.total) : '', alignment: 'right',
            fontSize: 7, border: [true, false, false, false]
          },
          {
            text: registro.nivel === 2 ? this.funcaoService.convertToBrNumber(registro.total) : '', alignment: 'right',
            fontSize: 7, border: [true, false, false, false]
          },
          {
            text: registro.nivel === 1 ? this.funcaoService.convertToBrNumber(registro.total) : '', alignment: 'right',
            fontSize: 7, border: [true, false, false, false]
          },
          {
            text: registro.nivel === 0 ? this.funcaoService.convertToBrNumber(registro.total) : '', alignment: 'right',
            fontSize: 7, border: [true, false, true, false]
          },
        ]);
        total6 += registro.nivel === 0 ? +registro.total : 0;
        soma1 += registro.nivel === 0 ? +registro.total : 0;

      }
      registros.push([{
        text: 'TOTAL', colSpan: 5, border: [true, true, true, true],
      }, '', '', '', '', {
        text: this.funcaoService.convertToBrNumber(total6), alignment: 'right', bold: true
      }]);

      registros.push([{
        text: '', colSpan: 6, border: [false, false, false, false], margin: [0, 0, 0, 10]
      }, '', '', '', '', '']);
    }

    registros.push([{
      text: 'TOTAL GERAL', colSpan: 5, border: [true, true, true, true],
    }, '', '', '','', {
      text: this.funcaoService.convertToBrNumber(soma1), alignment: 'right', bold: true
    }]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }];
  }


}
