import { Recebimento } from '../tesouraria/recebimento.model';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Recurso } from './recurso.model';
import { Receita } from './receita.model';
import { Orgao } from '../comum/orgao.model';
import { RecursoSiconfi } from './recurso-siconfi.model';
import { RecursoSiconfiComplemento } from './recurso-siconfi-complemento.model';
import { Convenio } from '../compra/convenio.model';
import { Favorecido } from '../compra/favorecido.model';
import { Contrato } from '../compra/contrato.model';

// tslint:disable: variable-name

export class FichaReceita extends BaseResourceModel {
  constructor(
    public id?: number,
    public numero?: number,
    public especie?: string,
    public ativo?: boolean,
    public renuncia?: boolean,
    public exercicio?: Exercicio,
    public recurso?: Recurso,
    public aplicacao?: Recurso,
    public aplicacao_variavel?: Recurso,
    public orgao?: Orgao,
    public valor_orcado?: number,
    public ensino?: number,
    public saude?: number,
    public redutor?: boolean,
    public restituicao?: boolean,
    public retencao?: boolean,
    public reinf_2000?: boolean,
    public reinf_4000?: boolean,
    public receita?: Receita,
    public convenio?: Convenio,
    public recurso_siconfi?: RecursoSiconfi,
    public complemento_siconfi?: RecursoSiconfiComplemento,
    public favorecido?: Favorecido,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public exigir_credor?: boolean,
    public exigir_contrato?: boolean,
    public naoImportar?: boolean,
    public exigir_convenio?: boolean,
    public tipo_redutor?: string,
    public previsoes?: [],
    public recebimentos?: Recebimento[],
    public contrato?: Contrato,
    public tipo_imposto?: 'IR' | 'CSLL' | 'COFINS' | 'PIS',
    public tipo_favorecido?: 'PF' | 'PJ'
  ) {
    super();
  }

  static converteJson(json: any): FichaReceita {
    return Object.assign(new FichaReceita(), json);
  }

}
