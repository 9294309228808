import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import * as toastr from 'toastr';
import { AdiantamentoService, EmpenhoService, LiquidacaoService } from 'administrativo-lib';
import {
  Aliquota,
  BaseResourceFormComponent, Compra, DateFormatPipe,
  DocumentoFiscalInfo, Empenho, Favorecido, FichaExtra, FuncaoService,
  GlobalService, Liquidacao, LoginContabil, OrgaoAssinaturaService,
  PreLiquidacao, Retencao, Tabela1Reinf, FavorecidoService, Login, MovimentoEstoque, Produto
} from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { FichaDespesaService } from '../../ficha-despesa/service/ficha-despesa.service';
import { PreLiquidacaoService } from '../../pre-liquidacao/service/pre-liquidacao.service';
import { NotaLiquidacao } from '../../relatorio/liquidacao/nota-liquidacao';
import { RetencaoService } from '../service/retencao.service';
import { DocumentoFiscalInfoService } from '../../documentos-fiscais/service/documento-fiscal-info.service';
import { MovimentoEstoqueService } from 'almoxarifado-lib';
import { DatePipe } from '@angular/common';
import { DocumentoFiscalService } from '../../documentos-fiscais/service/documento-fiscal.service';
import { FichaExtraService, OrdemPagamentoItemService } from 'contabil-lib';
import { CompraService } from 'compra-lib';

declare var $: any;

interface IPreLiquidacaoExtendido extends PreLiquidacao {
  total_preliquidado?: string;
  total_preliquidado_anterior?: string;
  total_empenhado?: string;
  total_anulado?: string;
}

@Component({
  selector: 'lib-liquidacao-form',
  templateUrl: './liquidacao-form.component.html'
})
export class LiquidacaoFormComponent extends BaseResourceFormComponent<Liquidacao, LoginContabil> implements OnInit {
  /**
   * Declaração de variáveis
   */
  @ViewChild('numero_empenho') autoComplete: ElementRef;

  public especie: string;
  public listaUfs: Array<any>;
  public empenhado: number = 0
  public liquidado: number = 0
  public pago: number = 0
  public listaRetencoes: Array<Retencao>;
  public redirecionar = false;
  public empenhoNumero: number;
  public anulado: boolean = false;
  public empenho: Empenho;
  public listaDocumentos: Array<DocumentoFiscalInfo> = new Array<DocumentoFiscalInfo>();
  public preliquidacoes: PreLiquidacao[];
  public vincularPre: boolean = false;
  public totalAnulado: number = 0
  public datepipe: DatePipe;
  public saldo: number;
  public objetoReinfTabela1: any = null;
  public compra: Compra
  public totalPreliquidado: number = 0;
  public vencimento: string;
  public movimentoEstoque: MovimentoEstoque;
  public movimentosPendentes: Array<MovimentoEstoque> = null;
  public empenhosInscritos: Empenho[] = [];
  public data_nova_liquidacao: Date;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected confirmationService: ConfirmationService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected messageService: MessageService,
    protected documentoFiscalService: DocumentoFiscalService,
    protected fichaService: FichaDespesaService,
    protected fichaExtraService: FichaExtraService,
    protected empenhoService: EmpenhoService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected retencaoService: RetencaoService,
    protected preService: PreLiquidacaoService,
    protected docFiscalService: DocumentoFiscalInfoService,
    protected liquidacaoService: LiquidacaoService,
    protected movimentoEstoqueService: MovimentoEstoqueService,
    protected ordemItemPagamentoService: OrdemPagamentoItemService,
    protected tabela1ReinfServico: Tabela1Reinf,
    protected compraService: CompraService,
    protected favorecidoServico: FavorecidoService,
    protected adiantamentoService: AdiantamentoService) {
    super(new Liquidacao(), injector, Liquidacao.converteJson, liquidacaoService);
    this.datepipe = new DatePipe('pt');
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(_entidade: Liquidacao): Promise<boolean> {
    return !_entidade.anulacao && !_entidade.anulado_total && this.login.sistema != 'controle-interno'
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      parcela: [null, [Validators.required]],
      valor_liquidado: [0, [Validators.required]],
      data_liquidacao: [null, [Validators.required]],
      data_vencimento: [null, [Validators.required]],
      data_referencia: [null],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      mes: [null, [Validators.required]],
      preliquidacao: [null],
      documento: [null, [Validators.required]],
      anulacao: [false, [Validators.required]],
      impresso: [false, [Validators.required]],
      recolhimento_encargo: [false, [Validators.required]],
      empenho: [null, [Validators.required]],
      aux: [],
      orgao: [this.login.orgao, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      retencoes: [null],
      usuario_cadastro: [this.login.usuario, [Validators.required]],
      documento_fiscal_info: [null],
      patrimonio: [null],
      valor_base_ir: [null],
      valor_base_csll: [null],
      valor_base_cofins: [null],
      valor_base_pis: [null]
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'documento_fiscal_info,documento_fiscal_info.documento_fiscal,'
        + 'empenho,empenho.contrato,empenho.contrato.tipo_contratacao,'
        + 'empenho.exercicio,empenho.favorecido,empenho.favorecido.tipo,'
        + 'empenho.ficha.despesa,empenho.ficha.acao,empenho.ficha.programa,empenho.ficha.subfuncao,'
        + 'empenho.orgao,empenho.subelemento.plano,empenho.subelemento.vpd,empenho.compra,'
        + 'exercicio,orgao,orgao.cidade,empenho.contrato.prazo,movimentos_estoque,'
        + 'preliquidacao.empenho,preliquidacao.usuario_cadastro,empenho.contrato.aditamentos'
    };
  }

  protected afterInit(): void {
    this.listaUfs = this.globalService.listaEstados();
    this.inicializaVariavel();
  }

  protected campoFoco(): ElementRef {
    return this.autoComplete;
  }

  protected async afterLoad() {
    if (!this.podeAlterarAudesp(this.entidade.data_liquidacao)) {
      this.router.navigate(['/liquidacoes-orcamentaria']);
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return;
    }

    if (this.entidade.id) {
      await this.obterRetencoes(this.entidade.id);

      this.empenhoNumero = this.entidade.empenho.numero;
      this.entidade.data_liquidacao = new DateFormatPipe().transform(this.entidade.data_liquidacao, []);
      this.entidade.data_vencimento = new DateFormatPipe().transform(this.entidade.data_vencimento, []);
      this.entidade.data_referencia = new DateFormatPipe().transform(this.entidade.data_referencia, []);

      this.entidadeForm.patchValue(this.entidade);

      this.liquidacaoService.valorAnulado(this.entidade.id).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
        this.anulado = +res != 0
        this.totalAnulado = +res;
      });
      this.empenho = this.entidade.empenho;
      this.calcularPrazo(this.entidade.empenho);
      this.carregarDocumentosFiscais();
      this.movimentoEstoqueService.filtrar(0, -1, {
        relations: 'itens,compra,compra.exercicio,compra.contrato,tipo,recebedor,itens.produto_unidade,itens.produto_unidade.produto,itens.produto_unidade.produto.material,itens.produto_unidade.unidade',
        'liquidacao.id': this.entidade.id, excluido: false, 'orgao.id': this.login.orgao.id
      }).pipe(takeUntil(this.unsubscribe)).subscribe({
        next: (res) => {
          if (res?.content?.length > 0) {
            this.movimentoEstoque = res.content[0];
          } else {
            if (this.entidade.empenho?.subelemento?.codigo_reinf) {
              this.objetoReinfTabela1 = this.tabela1ReinfServico.carregarPorCodigo(this.entidade.empenho.subelemento.codigo_reinf, true);
            }
          }
        }, error: (e) => { this.messageService.add({ severity: 'error', summary: 'Atenção', detail: e.error.payload }) }
      });
    }

    this.empenhosInscritos = (await this.empenhoService.filtrar(1, -1, {
      exercicio_id: this.login.exercicio.id,
      orgao_id: this.login.orgao.id,
      inscrito_resto: true
    }).toPromise()).content;
    if (this.empenhosInscritos.length > 0 && !this.soVisualizar) {
      toastr.warning('Já foram inscrito os resto a pagar do exercício, não será possível alterar o registro!')
      this.router.navigate([`/liquidacoes-orcamentaria/${this.entidade.id}/visualizar`]);
    }
  }

  private async obterRetencoes(id: number): Promise<void> {
    await this.loadTotalizadores();

    const data = await this.retencaoService.extendido(0, -1, {
      liquidacao_id: id,
      relations: 'ficha,ficha.plano,ficha.favorecido,liquidacao,liquidacao.empenho,liquidacao.empenho.favorecido,'
        + 'liquidacao.orgao,liquidacao.exercicio'
    }).toPromise();
    this.listaRetencoes = data ? data.content : new Array<Retencao>();
    this.listaRetencoes.forEach(async ret => this.editarRetencaoOrcamentaria(ret));
  }

  public async editarRetencaoOrcamentaria(item: Retencao) {
    if (item.id && item.ficha.especie === 'O') {
      let ordemPagamento = await this.ordemItemPagamentoService.obter({ liquidacao_id: this.entidade.id }).toPromise();
      if (!item.liquidacao?.pagamentos?.length && !ordemPagamento) {
        item['sem_escrituracao'] = true;
      } else {
        for (const pag of item.liquidacao.pagamentos) {
          if ((pag.anulacao || pag.anulado_total) && !ordemPagamento) {
            item['sem_escrituracao'] = true;
          } else {
            item['sem_escrituracao'] = false;
          }
        }
      }
    } else {
      item['sem_escrituracao'] = false;
    }
  }

  protected async beforeSubmit() {
    try {
      if (this.liquidado === 0 && this.data_nova_liquidacao && this.converteDataParaDate(this.funcaoService.converteDataBR(this.entidadeForm.get('data_liquidacao').value)) < this.converteDataParaDate(this.funcaoService.converteDataBR(this.data_nova_liquidacao))) {
        throw new Error('Data de liquidação anterior a data de anulação, empenho não há saldo');
      }
      if (!this.entidadeForm.get('empenho').value) {
        throw new Error('Informe o empenho para liquidação');
      }
      if (!this.entidadeForm.get('exercicio').value) {
        throw new Error('Informe o exercício da liquidação');
      }
      if (!this.entidadeForm.get('orgao').value) {
        throw new Error('Informe o órgão da liquidação');
      }
      if (!this.entidadeForm.get('historico').value) {
        throw new Error('Informe o histórico da liquidação');
      }
      if (!this.entidadeForm.get('valor_liquidado').value) {
        throw new Error('Informe o valor da liquidação');
      }

      //Adicionado por meio da Const, pois estava retornando TRUE sem o valor realmente ser maior.
      const valorLiq: number = +this.entidadeForm.get('valor_liquidado').value
      const valorTotal: number = +valorLiq - +this.totalAnulado; //Trava não estava considerando o valor anulado da liquidação
      const preLiq: IPreLiquidacaoExtendido = this.entidadeForm.get("preliquidacao")?.value;
      const totalPreliquidado: number = preLiq?.id ? (+this.totalPreliquidado - (+preLiq?.valor_liquidado + +preLiq?.total_anulado)) : this.totalPreliquidado;
      if (+valorTotal.toFixed(2) > +(+this.empenhado - +totalPreliquidado).toFixed(2)) {
        throw new Error('Não há saldo disponível para esta liquidação!')
      }
      if (!this.entidadeForm.get('data_liquidacao').value) {
        throw new Error('Informe a data da liquidação');
      }
      if (!this.entidadeForm.get('data_vencimento').value) {
        throw new Error('Informe o vencimento da liquidação');
      }
      if (!this.entidadeForm.get('documento').value) {
        throw new Error('Informe o documento da liquidação');
      }
      if (+this.somarRetencao(this.listaRetencoes).toFixed(2) > +valorLiq.toFixed(2)) {
        throw new Error('O valor retido não pode ser maior que o valor liquidado');
      }
      if (this.entidadeForm.get('valor_liquidado').value <= 0 && !this.entidadeForm.get('anulacao').value) {
        throw new Error('O valor da liquidação deve ser maior que zero');
      }

      const dtLiquidacao: Date = this.entidadeForm.get('data_liquidacao').value;
      if (dtLiquidacao > new Date()) {
        throw new Error('Data da liquidação está maior que a data atual');
      }
      if (dtLiquidacao.getFullYear() !== this.login.exercicio.ano) {
        throw new Error('O ano da data da liquidação está diferente do exercício logado');
      }

      const saldo_liquidacao = +(+this.empenhado - +this.liquidado).toFixed(2) + ((this.entidade.valor_liquidado ? +this.entidade.valor_liquidado : 0) - +this.entidadeForm.get('valor_liquidado').value);
      if (saldo_liquidacao < 0) {
        throw new Error('Não há saldo para continuar a liquidação, verifique!');
      }

      const validado = await this.validarSimplesNacional();
      if (!validado) {
        throw new Error('Simples Nacional inválido!');
      }

      const validarDocumentoReptido = await this.validarDocumentoRepetido();
      if (!validarDocumentoReptido) {
        throw new Error('Documento repetido!');
      }

      const validarContratoVencido = await this.validarContratoVencido();
      if (!validarContratoVencido) {
        throw new Error('Contrato vencido!');
      }

      for (const retencao of this.listaRetencoes) {
        if (!retencao.valor_retido) {
          throw new Error('Informe o valor da retenção!');
        }
      }

      if (this.entidade?.empenho?.subelemento?.vpd) {
        const lista = await this.verificaAdiantamento()
        if (lista.length === 0)
          throw new Error(`Esse empenho foi identificado como uma despesa de adiantamento e não possui um processo de adiantamento aberto. Para prosseguir abra um processo de adiantamento para o empenho numero ${this.entidade.empenho.numero}`);
      }
      if (!await this.validarEntradaAlmoxarifado()) {
        throw new Error(`Não foi encontrado entrada deste empenho no almoxarifado/patrimônio. Favor verificar`);
      }

      const pre: PreLiquidacao = this.entidadeForm.get('preliquidacao').value;
      const data_liquidacao: Date = this.entidadeForm.get('data_liquidacao').value;
      const emp = this.entidadeForm.get('empenho').value;
      const empenho: Empenho = (await this.empenhoService.filtrar(1, -1, { id: emp.id, 'orgao.id': emp.orgao.id, 'exercicio.id': this.login.exercicio.id, relations: 'exercicio,orgao,subelemento.plano,favorecido.tipo,ficha.despesa,usuario_cadastro,modalidade,licitacao' }).toPromise()).content[0];
      if (!empenho.data_vencimento) {
        empenho.usuario_cadastro = this.login.usuario;
        empenho.data_vencimento = this.entidadeForm.get('data_vencimento').value;
        empenho['atualizar_precatorio'] = true;
        this.empenhoService.atualizar(empenho)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(res => { }, error => this.funcaoService.acaoErro(error));
      }
      if (pre && new Date(pre.data_liquidacao) > data_liquidacao) {
        throw new Error('A data da liquidação não pode ser superior a data da pré-liquidação!');
      }

      this.entidadeForm.get('retencoes').setValue(this.listaRetencoes);
      this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidadeForm.get('data_liquidacao').value)?.split('-')?.[1]);
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  private somarRetencao(list: Array<Retencao>) {
    let total = 0;
    if (list) {
      total = 0;
      for (const item of list) {
        total += +item.valor_retido;
      }
    }
    return total;
  }

  private validarSimplesNacional() {
    if (!this.entidade.id && this.entidade.empenho.favorecido.simples_nacional &&
      (this.entidade.empenho.subelemento.codigo.substring(4, 2) === '36' ||
        this.entidade.empenho.subelemento.codigo.substring(4, 2) === '39' ||
        this.entidade.empenho.subelemento.codigo.substring(4, 2) === '51')) {
      return new Promise((resolve, reject) => {
        this.confirmationService.confirm({
          header: 'Atenção',
          message: 'Empresa optante pelo simples nacional, dispensada da retenção de IRRF e INSS desde que apresente declaração.'
            + 'Se a mesma estiver  enquadrada no ANEXO IV da LEI 123/06, porém, haverá retenção de INSS. Deseja continuar?',
          accept: () => {
            resolve(true);
          },
          reject: () => {
            resolve(false);
          }
        });
      });
    } else { return true; }
  }

  private async validarDocumentoRepetido() {
    if (!this.entidade.id) {
      const param = {};
      param['empenho.favorecido.id'] = this.entidadeForm.get('empenho').value?.favorecido.id;
      param['documento'] = this.entidadeForm.get('documento').value || this.entidade.documento;
      param['valor_liquidado'] = this.entidadeForm.get('valor_liquidado').value || this.entidade.valor_liquidado;
      param['orgao.id'] = this.entidadeForm.get('orgao').value.id;
      param['exercicio.id'] = this.entidadeForm.get('exercicio').value.id;
      if (this.entidade.id) {
        param['id$ne'] = this.entidade.id;
      }
      const entity = await this.liquidacaoService.obter(param).toPromise();
      if (entity) {
        return new Promise((resolve, reject) => {
          this.confirmationService.confirm({
            header: 'Atenção',
            message: 'Já existe liquidação com este fornecedor, documento e valor, deseja continuar ?',
            accept: () => {
              resolve(true);
            },
            reject: () => {
              resolve(false);
            }
          });
        });
      } else { return true; }
    } else { return true; }
  }

  private async validarContratoVencido() {
    let dataFimContrato = this.entidade?.empenho?.contrato?.data_termino
    let dataFimAditamento = this.entidade?.empenho?.contrato?.aditamentos[0]?.data_termino
    let dataLiquidacao = this.entidadeForm.get('data_liquidacao').value

    this.entidade?.empenho?.contrato?.aditamentos.forEach(ad => {
      if (ad.data_termino > dataFimAditamento) {
        dataFimAditamento = ad.data_termino
      }
    });
    if (!dataFimAditamento) dataFimAditamento = dataFimContrato

    if (!this.entidade.id && this.entidade.empenho.contrato
      && (new Date(dataLiquidacao) > new Date(dataFimAditamento) && new Date(dataLiquidacao) > new Date(dataFimContrato))) {
      return new Promise((resolve, reject) => {
        this.confirmationService.confirm({
          header: 'Atenção contrato vencido',
          icon: 'pi pi-exclamation-triangle',
          message:
            `Empenho ${this.entidade.empenho.numero}/${this.entidade.exercicio.ano} de contrato ${this.entidade.empenho.contrato.numero}
                 vencido em ${new DateFormatPipe().transform(dataFimAditamento ? dataFimAditamento : dataFimContrato, ['local'])}. Deseja continuar?`,
          accept: () => {
            resolve(true);
          },
          reject: () => {
            resolve(false);
          }
        });
      });
    } else { return true; }
  }

  private async confirmarValorPre() {
    if (!this.entidade.id && +this.entidadeForm.get('valor_liquidado').value !== this.saldo) {
      return new Promise((resolve, reject) => {
        this.confirmationService.confirm({
          header: 'Atenção!',
          message:
            `O valor liquidado será alterado. Deseja realizar essa alteração?`,
          accept: () => {
            resolve(true);
          },
          reject: () => {
            resolve(false);
          }
        });
      });
    }
  }

  protected async afterSubmit(ent: Liquidacao) {
    this.listaRetencoes = new Array<Retencao>();
    if (this.currentActionRoute === 'novo') {
      //Atualiza o movimento estoque quando existir
      if (this.movimentoEstoque?.id) {
        await this.movimentoEstoqueService.vincularNovaLiquidacao(this.movimentoEstoque.id, ent.id, 'O').toPromise();
      }
      if (this.login.redirecionar_liquidar_pagar && this.podeIncluir('/pagamentos-orcamentarios')) {
        this.router.navigate(['/pagamentos-orcamentarios', 'novo', ent.id]);
      } else if (!this.limparTela) {
        this.router.navigate(['/liquidacoes-orcamentaria', ent.id, 'editar']);
      }
    } else {
      this.router.navigate(['/liquidacoes-orcamentaria', ent.id, 'editar']).then(async () => {
        await this.obterRetencoes(ent.id);
      });
    }
  }

  protected acaoSucesso(entidade: Liquidacao) {
    this.afterSubmit(entidade);
    if (this.mensagemSucesso) toastr.success(this.mensagemSucesso);
    if (!this.skipRedirect) {
      let url: string = this.baseResourceService.retornarRota();
      if (this.limparTela) { // salvar e novo
        if (url.includes('novo')) {
          const rota = this.baseResourceService.retornarRota();
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate([rota]));
        } else {
          this.router.navigate([this.baseResourceService.retornarRota().replace(/[0-9].*\/editar/g, 'novo')]);
        }
      } else { // salvar
        if (url.includes('novo')) {
          if (url.lastIndexOf('/novo') > 0) {
            url = url.substring(0, url.substring(1, url.length).indexOf('/novo') + 1);
          }
          url = url + '/' + entidade.id + '/editar';
          this.router.navigate([url]);
        }
      }
    }
    this.limparTela = false;
  }

  private inicializaVariavel() {
    if (this.currentActionRoute === 'novo') {
      this.listaRetencoes = new Array<Retencao>();

      this.liquidacaoService.ultimaDataLiquidada(this.login.exercicio.id, this.login.orgao.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((dataLiq) => {
          if (dataLiq === 0) {
            this.entidade.data_liquidacao = new Date();
          } else {
            this.entidade.data_liquidacao = new DateFormatPipe().transform(dataLiq, []);
          }
          this.entidadeForm.get('data_liquidacao').setValue(this.entidade.data_liquidacao);
          this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidade.data_liquidacao)?.split('-')?.[1]);
        });

      this.activatedRoute.params.pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (params: any) => {
            if (params.hasOwnProperty('empenho')) {
              const parametros = {};
              parametros['id'] = params['empenho'];
              parametros['relations'] = 'exercicio,orgao,favorecido,subelemento.plano,ficha,ficha.acao,ficha.despesa,ficha.programa,contrato.prazo,liquidacoes.movimentos_estoque';
              this.empenhoService.obter(parametros)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(
                  (entidade) => {
                    this.redirecionar = true;
                    this.empenhoNumero = entidade.numero;
                    this.entidadeForm.get('empenho').setValue(entidade);
                    this.entidadeForm.get('historico').setValue(entidade.historico);
                    this.entidadeForm.get('data_vencimento').setValue(new DateFormatPipe().transform(entidade.data_vencimento, []));
                    this.entidade.empenho = entidade;
                    this.calcularPrazo(entidade);
                    if (this.entidade.empenho) {
                      this.loadTotalizadores();
                      this.obterUltimaParcela();
                    }
                  }, () => this.sair());
            }
          });
    }
  }
  /* Se o usuario informou um valor diferente do empenhado
   * atribui a parcela como sendo a primeira
   */
  public verificarParcela() {
    const preLiq: IPreLiquidacaoExtendido = this.entidadeForm.get("preliquidacao")?.value;
    const totalPreliquidado: number = preLiq?.id ? (+this.totalPreliquidado - (+preLiq?.valor_liquidado + +preLiq?.total_anulado)) : this.totalPreliquidado;
    if (+(+this.empenhado - +this.liquidado).toFixed(2) + ((this.entidade.valor_liquidado ? +this.entidade.valor_liquidado : 0) - +this.entidadeForm.get('valor_liquidado').value) < 0) {
      toastr.warning('Não há saldo disponível para esta liquidação!')
    } else {
      this.obterUltimaParcela();
    }
  }

  public async verificarValorLiquidado() {
    if (+(+this.empenhado - +this.liquidado).toFixed(2) + ((this.entidade.valor_liquidado ? +this.entidade.valor_liquidado : 0) - +this.entidadeForm.get('valor_liquidado').value) < 0) {
      return;
    }
    if (this.entidadeForm.get('preliquidacao').value) {
      const confirmar = await this.confirmarValorPre();
      if (confirmar) {
        this.preliquidacoes = null;
        this.entidadeForm.get('preliquidacao').setValue(null);
      } else {
        this.entidadeForm.get('valor_liquidado').setValue(this.saldo + '');
      }
    }
  }

  private async obterUltimaParcela() {
    this.liquidacaoService.obterUltimaParcela(
      this.entidade.empenho.numero, this.entidade.empenho.exercicio.id, this.entidade.empenho.orgao.id
    ).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res: any) => {
          if (!this.entidade.id) {
            if (+this.empenhado > +this.entidadeForm.get('valor_liquidado').value && res == 0) {
              res = 1;
            } else {
              if (+res > 0) {
                res = +res + 1;
              }
            }
            this.entidade.parcela = res;
            this.entidadeForm.get('parcela').setValue(res);
            if (!this.entidadeForm.get('preliquidacao').value) {
              this.obterPreLiquidacao(this.entidade.empenho);
            }
          }
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
      );
  }

  private async loadTotalizadores(): Promise<void> {
    this.empenhado = 0;
    this.liquidado = 0;
    this.pago = 0;
    this.totalPreliquidado = 0;

    if (!this.entidade.empenho) {
      return;
    }

    try {
      let param = {
        exercicio_id: this.login.exercicio.id,
        orgao_id: this.login.orgao.id,
        numero: this.entidade.empenho.numero,
      }

      const result = await this.empenhoService.extendido(1, 1, param).toPromise();
      if (result) {
        const totalizadores = result.content[0] || result.content;
        this.empenhado = +totalizadores.total_empenhado;
        this.liquidado = +totalizadores.total_liquidado;
        this.pago = +totalizadores.total_pago;
        this.totalPreliquidado = +totalizadores?.total_preliquidado;
        this.data_nova_liquidacao = totalizadores.data_liq_nova
        if (this.liquidado === 0 &&
          totalizadores?.data_liq_nova &&
          this.converteDataParaDate(this.funcaoService.converteDataBR(this.entidadeForm.get('data_liquidacao').value)) < this.converteDataParaDate(this.funcaoService.converteDataBR(totalizadores.data_liq_nova))) {
          toastr.warning('Data de liquidação anterior a data de anulação, empenho não há saldo');
          return;
        }
      }
    } catch (e) {
      //As vezes, ao entrar na tela de liquidação, por algum motivo essa rotina da erro e no F5 resolve (perca de token), forçando reload no caso desse erro.
      window.location.reload();
    }

    if (!this.entidadeForm.get('id').value) {
      const preLiq: IPreLiquidacaoExtendido = this.entidadeForm.get("preliquidacao")?.value;
      const totalPreliquidado: number = preLiq?.id ? (+this.totalPreliquidado - (+preLiq?.valor_liquidado + +preLiq?.total_anulado)) : this.totalPreliquidado;
      const saldo = +(+this.empenhado - +this.liquidado - +totalPreliquidado).toFixed(2);
      this.saldo = saldo;
      this.entidadeForm.get('valor_liquidado').setValue(saldo.toString());
      if (saldo <= 0 && +totalPreliquidado > 0) {
        toastr.warning('Não há saldo para realizar essa liquidação sem vinculo com alguma pré liquidação!');
        return;
      }
      if (saldo <= 0) {
        toastr.warning('Não há saldo disponível para esta liquidação!');
        return;
      }
    }
  }

  public carregarDocumentosFiscais(edicao?: boolean) {
    if (this.liquidado === 0 && this.data_nova_liquidacao && this.converteDataParaDate(this.funcaoService.converteDataBR(this.entidadeForm.get('data_liquidacao').value)) < this.converteDataParaDate(this.funcaoService.converteDataBR(this.data_nova_liquidacao))) {
      throw new Error('Data de liquidação anterior a data de anulação, empenho não há saldo');
    }
    const params = {};
    params['documento_fiscal.orgao.id'] = this.login.orgao.id;
    params['relations'] = 'documento_fiscal';
    const favorecido = (this.entidade.id ? this.entidade.empenho.favorecido
      : this.entidadeForm.get('empenho').value ? this.entidadeForm.get('empenho').value['favorecido'] : null);
    if (!favorecido) return;

    params['documento_fiscal.favorecido.id'] = favorecido.id;
    const vencimento: Date = this.entidade.id && !edicao ? this.entidade.data_vencimento : this.entidadeForm.get('data_vencimento').value;
    let data: Date = this.entidade.id && !edicao ? this.entidade.data_liquidacao : this.entidadeForm.get('data_liquidacao').value;
    if (data) {
      data = new Date(data.getFullYear(), data.getMonth() - (data.getMonth() == 1 ? 1 : 2), 1, 0, 0, 0);
    }
    if (vencimento && data) {
      params['documento_fiscal.data_emissao$ge'] = this.datepipe.transform(data, 'yyyy-MM-dd');
      params['documento_fiscal.data_emissao$le'] = this.datepipe.transform(vencimento, 'yyyy-MM-dd');
    }

    if (vencimento && favorecido) {
      this.docFiscalService.filtrar(0, -1, params).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
        this.listaDocumentos = res.content;
      });
    }

    this.calcularPrazo(this.entidade.empenho);
  }

  public async atualizarEmpenho() {
    if (this.empenhoNumero > 0 && !this.entidade.id) {
      const param = {};
      param['numero'] = this.empenhoNumero;
      param['exercicio.id'] = this.login.exercicio.id;
      param['orgao.id'] = this.login.orgao.id;
      param['relations'] = 'modalidade,ficha.despesa,ficha.acao,favorecido,favorecido.tipo,favorecido.cidade,subelemento.plano,'
        + 'exercicio,orgao,contrato,contrato.tipo_contratacao,ficha.despesa,ficha.funcao,compra,'
        + 'ficha.subfuncao,ficha.programa,ficha.recurso,ficha.aplicacao,orgao,orgao.cidade,exercicio,subelemento.vpd,'
        + 'contrato.aditamentos,contrato.prazo'

      this.entidade.empenho = await this.empenhoService.obter(param).pipe(takeUntil(this.unsubscribe)).toPromise();
      if (!this.entidade.empenho) {
        toastr.warning('Empenho não localizado!');
        this.entidadeForm.get('empenho').setValue(null);
        this.loadTotalizadores();
        return;
      } else {
        this.entidade.empenho['atualizar_precatorio'] = true;

        //Se já existir outra liquidação pro empenho, em parcela 0 não anulado, não pode permitir fazer
        const res = await this.liquidacaoService.filtrar(0, -1, {
          'empenho.id': this.entidade.empenho.id, parcela: 0,
          'orgao.id': this.login.orgao.id, 'exercicio.id': this.login.exercicio.id, anulado_total: false, anulacao: false
        }).toPromise();
        if (res.content.length > 0) {
          this.messageService.add({ severity: 'warn', summary: 'Erro', detail: `Esse empenho foi criado como parcela única e não pode ser parcelado. Para parcela-lo é necessário que toda a liquidação existente seja anulada.` });
          return;
        }
        this.calcularPrazo(this.entidade.empenho);
      }

      if (!await this.validarEntradaAlmoxarifado()) {
        this.messageService.add({ severity: 'warn', summary: 'Erro', detail: `Não foi encontrado entrada deste empenho no almoxarifado/patrimônio. Favor verificar.` });
        this.entidadeForm.get('empenho').setValue(null);
        this.loadTotalizadores();
      }

      this.entidade.orgao = this.entidade.empenho.orgao;
      this.entidade.exercicio = this.entidade.empenho.exercicio;
      if (this.entidade.empenho) {
        this.carregarDocumentosFiscais();
        this.loadTotalizadores();
        this.obterUltimaParcela();
        if (!this.entidadeForm.get('data_liquidacao').value) {
          this.entidadeForm.get('data_liquidacao').setValue(new Date());
        }
        this.entidadeForm.get('empenho').setValue(this.entidade.empenho);
        this.entidadeForm.get('orgao').setValue(this.entidade.empenho.orgao);
        this.entidadeForm.get('exercicio').setValue(this.entidade.empenho.exercicio);
        this.entidadeForm.get('data_vencimento').setValue(new DateFormatPipe().transform(this.entidade.empenho.data_vencimento, []));
        this.entidadeForm.get('historico').setValue(this.entidade.empenho.historico);
        this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidade.data_liquidacao)?.split('-')?.[1]);
        //Verifica se existem movimentações pendentes de novo vinculo no sistema
        if (this.entidade.empenho.compra) {
          this.movimentoEstoqueService.filtrar(0, -1, {
            relations: 'itens,compra,compra.exercicio,compra.contrato,tipo,recebedor,itens.produto_unidade,'
              + 'itens.produto_unidade.produto,itens.produto_unidade.produto.material,itens.produto_unidade.unidade',
            excluido: false, 'orgao.id': this.login.orgao.id, desvinculado_liquidacao: true,
            'compra.id': this.entidade.empenho.compra.id
          }).pipe(takeUntil(this.unsubscribe)).subscribe({
            next: (res) => {
              this.movimentosPendentes = res.content;
              if (this.movimentosPendentes.length > 0) {
                $('#dlgMovimento').modal('show');
              }
            }, error: (e) => {
              toastr.warning(`Erro ao procurar pendências de movimentação!`)
            }
          });
        }
      }
      if (this.entidade?.empenho?.subelemento?.vpd) {
        const lista = await this.verificaAdiantamento()
        if (lista.length === 0)
          toastr.warning(`Esse empenho foi identificado como uma despesa de adiantamento e não possui um processo de adiantamento aberto. Para prosseguir abra um processo de adiantamento!`)
      }

      if (this.entidade?.empenho?.favorecido?.tipo.tce === '01' &&
        (this.login.parametro['contabil'].utilizar_aliquota_ir
          || this.login.parametro['contabil'].utilizar_aliquota_cofins
          || this.login.parametro['contabil'].utilizar_aliquota_csll
          || this.login.parametro['contabil'].utilizar_aliquota_pis_pasep)) {
        if (!this.entidade.empenho.favorecido.conferencia_simples) {//Se nunca foi validado, obrigatóriamente tem que fazer a primeira validação.
          $('#dlgSimplesNacional').modal('show');
        } else if (this.entidade.empenho.favorecido.simples_nacional) {
          //Se forem simples, e a ultima validação for antes de janeiro do ano, 
          if (this.entidade.empenho.favorecido.conferencia_simples < new Date(this.login.exercicio.ano, 1, 1, 0, 0, 0)) {
            $('#dlgSimplesNacional').modal('show');
          }
        }
      }
    }
  }

  public vincularMovimento(movimento: MovimentoEstoque) {
    movimento.desvinculado_liquidacao = false;
    this.movimentoEstoque = movimento;
    this.entidadeForm.get('valor_liquidado').setValue('' + this.saldoTotalMovimento());
    this.entidade.valor_liquidado = this.saldoTotalMovimento();
    this.verificarValorLiquidado();
    this.verificarParcela();
    $('#dlgMovimento').modal('hide');
  }

  async atualizarSimples(simples: boolean) {
    const fav: Favorecido = this.entidade.empenho.favorecido;
    fav.simples_nacional = simples;
    fav.conferencia_simples = new Date();
    await this.favorecidoServico.atualizar(fav).toPromise();
    this.entidade.empenho.favorecido = fav;
  }

  public abrirDocsFiscais() {
    const param = {};

    if (this.entidade.empenho.contrato && this.entidade.empenho.contrato.tipo_contratacao.exige_cno) {
      param['tipo'] = 'C';
      param['id'] = this.entidade.empenho.contrato.id;
    } else {
      param['tipo'] = 'F';
      param['id'] = this.entidade.empenho.favorecido.id;
    }
    param['doc'] = this.entidadeForm.get('documento').value;

    let favorecido = this.entidade.empenho.favorecido

    this.documentoFiscalService.filtrar(1, 1, { 'orgao.id': this.login.orgao.id, 'numero_documento': this.entidadeForm.get('documento').value, 'favorecido.cpf_cnpj': favorecido.cpf_cnpj }).subscribe(async e => {
      if (e.content?.length > 0) {
        const text = `Número de documento ${this.entidadeForm.get('documento').value} existente para o CNPJ informado!'`;
        toastr.info(text, 'Validação');
      } else {
        const url = this.router.createUrlTree([`/documentos-fiscais/novo/${param['tipo']}/${param['id']}/${param['doc']}`]);
        window.open('#/' + url.toString(), '_blank');
      }
    });
  }

  public async validarEntradaAlmoxarifado() {
    if (this.login.verificar_liquidacao_estoque) {
      if (this.entidade.empenho.ficha.despesa?.codigo.substring(4, 6) === '30' || this.entidade.empenho.ficha.despesa.codigo.substring(4, 6) === '52') {
        const movimento = await this.movimentoEstoqueService.obter({
          orgao_id: this.login.orgao.id,
          excluido: false,
          compra_id: this.entidade.empenho.compra ? this.entidade.empenho.compra.id : 0
        }).pipe(takeUntil(this.unsubscribe)).toPromise();
        if (!movimento) {
          return false;
        }
      }
    }
    return true;
  }

  public async obterPreLiquidacao(empenho: Empenho) {
    const param = {};
    param['empenho_id'] = empenho.id;
    param['exercicio.id'] = this.login.exercicio.id;
    param['orgao.id'] = this.login.orgao.id;
    param['liquidacoes.id$null'] = '';
    param['anulacao'] = false;
    param['anulado_total'] = false;
    param['orderBy'] = 'id$ASC';
    param['relations'] = 'usuario_cadastro,empenho,liquidacoes';

    const pre = await this.preService.extendido(1, -1, param).toPromise();

    const encontrouPre = this.getVinculoPreLiquidacao(pre.content);
    this.preliquidacoes = pre.content;
  }

  public selecionarPre(pre: PreLiquidacao): void {
    this.entidadeForm.get('preliquidacao').setValue(pre);
    const anulado = pre['total_anulado'] ? +pre['total_anulado'] : 0;
    const valor_liquidado = +pre.valor_liquidado + anulado;
    this.entidadeForm.get('valor_liquidado').setValue(valor_liquidado.toString());
    this.verificarParcela();
    this.entidadeForm.get('historico').setValue(pre.historico);
    this.entidadeForm.get('documento').setValue(pre.documento);
    this.vincularPre = true;

    $('#dlgconsultaPreLiquidacao').modal('hide');
  }

  public imprimir() {
    const parametros = {};
    let relations = '';
    relations += 'empenho.contrato,empenho.licitacao,empenho.modalidade,empenho.subelemento.plano,empenho.ficha,';
    relations += 'empenho.favorecido.contas.banco,empenho.favorecido.contas.orgao,empenho.favorecido.tipo,empenho.ficha.executora.unidade,empenho.ficha.despesa,empenho.ficha.funcao,';
    relations += 'empenho.ficha.acao,empenho.ficha.aplicacao,empenho.ficha.recurso,empenho.ficha.aplicacao_variavel';
    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id'] = this.entidade.id;
    this.liquidacaoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        this.assinaturaService.obter()
        new NotaLiquidacao(this.assinaturaService).imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

  sair() {
    if (this.redirecionar && this.podeIncluir('empenhos')) {
      this.router.navigate(['/empenhos-orcamentario']);
    } else {
      new FuncaoService().navegarPara(this.login.usuario.sistema, this.router);
    }
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public async verificaAdiantamento(): Promise<[]> {
    const parametros = {}
    parametros['empenho.numero'] = this.entidade.empenho.numero;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    const lista = await this.adiantamentoService.filtrar(1, -1, parametros).toPromise()
    const conteudo = lista.content
    return conteudo
  }

  public documentoFiscalSelecionado() {
    const documentoFiscal = this.entidadeForm.get('documento_fiscal_info').value
    this.entidadeForm.get('documento').setValue(documentoFiscal?.documento_fiscal?.numero_documento)
  }

  public quantidadeTotalMovimento() {
    let valor = 0;
    if (this.movimentoEstoque.itens)
      for (let item of this.movimentoEstoque.itens) {
        valor += item.qtde ? parseFloat(item.qtde.toString()) : 0;
      }
    return valor;
  }

  public saldoTotalMovimento() {
    let valor = 0;
    if (this.movimentoEstoque.itens)
      for (let item of this.movimentoEstoque.itens) {
        let valor_unitario = item.valor_unitario ? parseFloat(item.valor_unitario.toString()) : 0;
        let qtde = item.qtde ? parseFloat(item.qtde.toString()) : 0;
        valor += valor_unitario * qtde;
      }
    return this.funcaoService.arredondar(valor, 2);
  }

  public naturezaRendimento(item: Produto) {
    const obj = this.tabela1ReinfServico.carregarPorCodigo(item.codigo_reinf != null ? item.codigo_reinf : item.material.codigo_reinf != null ? item.material.codigo_reinf : this.entidade.empenho.subelemento.codigo_reinf, true);
    if (obj) {
      return obj?.codigo + ' - ' + (obj?.nome.length < 30 ? obj?.nome : obj?.nome.substring(0, 30) + '...');
    } else {
      return '';
    }
  }

  public async calcularRetencoesAliquotas() {
    const favorecido: Favorecido = this.entidadeForm.get('empenho').value.favorecido

    if (favorecido && (favorecido.simples_nacional || favorecido.terceiro_setor || favorecido.autarquia)) return

    if (this.anulado ? 'readonly' : this.entidade.parcela == 0 && this.entidade.id ? 'readonly' : this.entidadeForm.get('preliquidacao').value ? 'readonly' : null) return

    // Verifica se o calculo deve ser realizado
    if (this.login.parametro.contabil.utilizar_aliquota_ir || this.login.parametro.contabil.utilizar_aliquota_csll || this.login.parametro.contabil.utilizar_aliquota_cofins || this.login.parametro.contabil.utilizar_aliquota_pis_pasep) {
      if (!this.entidadeForm.get('data_vencimento').value) {
        toastr.info('Informe a data de vencimento!')
        return;
      }

      const valorBaseIR = this.entidadeForm.get('valor_base_ir').value ? this.entidadeForm.get('valor_base_ir').value : this.entidade.valor_base_ir
      const valorBaseCSLL = this.entidadeForm.get('valor_base_csll').value ? this.entidadeForm.get('valor_base_csll').value : this.entidade.valor_base_csll
      const valorBaseCOFINS = this.entidadeForm.get('valor_base_cofins').value ? this.entidadeForm.get('valor_base_cofins').value : this.entidade.valor_base_cofins
      const valorBasePIS = this.entidadeForm.get('valor_base_pis').value ? this.entidadeForm.get('valor_base_pis').value : this.entidade.valor_base_pis
      const valorLiquidacao = this.entidadeForm.get('valor_liquidado').value ? this.entidadeForm.get('valor_liquidado').value : this.entidade.valor_liquidado
      const empenho: Empenho = this.entidadeForm.get('empenho').value ? this.entidadeForm.get('empenho').value : this.entidade.empenho

      if ((+valorBaseIR > +valorLiquidacao) || (+valorBaseCSLL > +valorLiquidacao) || (+valorBaseCOFINS > +valorLiquidacao) || (+valorBasePIS > +valorLiquidacao)) {
        toastr.warning('O valor da base de calculo não pode ser maior que o Valor da Liquidação.<br>Por favor, revise e tente novamente!')
        return;
      }

      this.compra = await this.compraService.obter({ 'empenho.id': empenho.id, 'orgao_id': this.login.orgao.id, 'exercicio_id': this.login.exercicio.id, relations: 'empenho,itens.produto_unidade.produto.aliquota,itens.produto_unidade.produto.material.aliquota' }).toPromise()

      // Busca nos itens da compra a primeira aliquota que encontrar
      let aliquota: Aliquota
      for (const item of this.compra.itens) {
        if (item.produto_unidade.produto.aliquota) {
          aliquota = item.produto_unidade.produto.aliquota
          break;
        } else if (item.produto_unidade.produto.material.aliquota) {
          aliquota = item.produto_unidade.produto.material.aliquota
          break;
        }
      }

      // Verifica se todos os itens da compra estão na mesma aliquota
      if (aliquota) {
        const aliquotaDiferente = this.compra.itens.filter(item => {
          if (item.produto_unidade.produto?.aliquota) {
            if (item.produto_unidade.produto?.aliquota.id !== aliquota.id) return item
          } else if (item.produto_unidade.produto?.material?.aliquota) {
            if (item.produto_unidade.produto?.material?.aliquota.id !== aliquota.id) return item
          }
        });

        if (aliquotaDiferente.length > 0) {
          // se tem mais de uma aliquota, abre o modal para selecionar as quantidades dos itens
          $('#dlgItensCompra').modal('show')

          this.entidadeForm.get('valor_base_ir').setValue(null)
          this.entidadeForm.get('valor_base_csll').setValue(null)
          this.entidadeForm.get('valor_base_cofins').setValue(null)
          this.entidadeForm.get('valor_base_pis').setValue(null)

          toastr.info('Não foi possível gerar retenções automaticamente.<br>Itens da compra possui mais de um tipo de aliquota cadastrada.<br>Informe as quantidades dos itens recebidos para calcular automaticamente!')
          return;
        }
      } else {
        toastr.info('Não foi possível gerar retenções automaticamente.<br>Não foi encontrado alíquota vinculada aos itens da compra.')
        return;
      }

      if (this.login.parametro.contabil.utilizar_aliquota_ir) {
        this.entidadeForm.get('valor_base_ir').setValue(valorBaseIR ? valorBaseIR : valorLiquidacao)
        this.calcularRetencao(valorBaseIR ? valorBaseIR : valorLiquidacao, aliquota.ir, 'IR')
      }

      if (this.login.parametro.contabil.utilizar_aliquota_csll) {
        this.entidadeForm.get('valor_base_csll').setValue(valorBaseCSLL ? valorBaseCSLL : valorLiquidacao)
        this.calcularRetencao(valorBaseCSLL ? valorBaseCSLL : valorLiquidacao, aliquota.csll, 'CSLL')
      }

      if (this.login.parametro.contabil.utilizar_aliquota_cofins) {
        this.entidadeForm.get('valor_base_cofins').setValue(valorBaseCOFINS ? valorBaseCOFINS : valorLiquidacao)
        this.calcularRetencao(valorBaseCOFINS ? valorBaseCOFINS : valorLiquidacao, aliquota.cofins, 'COFINS')
      }

      if (this.login.parametro.contabil.utilizar_aliquota_pis_pasep) {
        this.entidadeForm.get('valor_base_pis').setValue(valorBasePIS ? valorBasePIS : valorLiquidacao)
        this.calcularRetencao(valorBasePIS ? valorBasePIS : valorLiquidacao, aliquota.pis_pasep, 'PIS')
      }
    }
  }

  public async calcularRetencao(valor: number, aliquotaValor: number, aliquotaNome: 'IR' | 'CSLL' | 'COFINS' | 'PIS') {
    const tipoFavorecido = this.entidade.empenho.favorecido.tipo.nome === 'CPF - PESSOA FÍSICA' ? 'PF' : this.entidade.empenho.favorecido.tipo.nome === 'CNPJ - PESSOA JURÍDICA' ? 'PJ' : ''

    let fichaExtra: FichaExtra = await this.fichaExtraService.obter({ exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id, retencao: true, excluida: false, 'ficha.tipo_imposto': `${aliquotaNome}`, 'ficha.tipo_favorecido': tipoFavorecido, relations: 'plano,favorecido,ficha' }).toPromise()

    if (!fichaExtra) {
      fichaExtra = await this.fichaExtraService.obter({ exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id, retencao: true, excluida: false, 'ficha.tipo_imposto': `${aliquotaNome}`, 'ficha.tipo_favorecido$null': 'true', relations: 'plano,favorecido,ficha' }).toPromise()
    }

    const retencaoIgual = this.listaRetencoes.find(item => item.ficha?.id === fichaExtra?.id)

    if (!fichaExtra) {
      toastr.info(`Não foi possível gerar retenção para ${aliquotaNome}.`, 'Ficha não encontrada')
      return;
    }

    if (retencaoIgual) return

    const retencao = new Retencao()
    retencao.valor_retido = +valor * (+aliquotaValor / 100)
    retencao.ficha = fichaExtra
    retencao.liquidacao = this.entidade
    retencao.data_vencimento = this.entidadeForm.get('data_vencimento').value
    retencao.anulado = false
    retencao.editavel = false
    retencao['salvo'] = false

    if (aliquotaNome === 'IR') {
      retencao.base_IR = +valor
      retencao.valor_IR = +valor * (+aliquotaValor / 100)
    } else if (aliquotaNome === 'CSLL') {
      retencao.base_csll = +valor
      retencao.valor_csll = +valor * (+aliquotaValor / 100)
    } else if (aliquotaNome === 'COFINS') {
      retencao.base_cofins = +valor
      retencao.valor_cofins = +valor * (+aliquotaValor / 100)
    } else if (aliquotaNome === 'PIS') {
      retencao.base_pp = +valor
      retencao.valor_pp = +valor * (+aliquotaValor / 100)
    }

    this.listaRetencoes.push(retencao)
  }

  public calcularPrazo(empenho: Empenho) {
    const vencimento = this.currentActionRoute === 'novo' ? new Date(this.entidadeForm.get('data_liquidacao').value) : this.entidade.movimentos_estoque.length > 0 ? new Date(this.entidade.movimentos_estoque[0]?.data_movimento) : undefined

    if (this.login.informar_data_liquidacao_estoque && (!empenho?.contrato || !empenho?.contrato?.prazo || !empenho?.contrato?.prazo || !empenho?.contrato?.prazo?.intervalo || !vencimento)) {
      return;
    } else {
      const prazo = empenho?.contrato?.prazo?.intervalo_qtd;
      switch (empenho?.contrato?.prazo?.intervalo) {
        case 'À vista':
          this.vencimento = empenho.contrato.prazo.nome;
          break;
        case 'Mês':
          this.vencimento = empenho.contrato.prazo.nome;
          break;
        case 'Dia (corrido)':
          this.vencimento = empenho.contrato.prazo.nome;
          break;
        case undefined:
          this.vencimento = 'À VISTA';
          break;
        case 'Dia útil':
          this.vencimento = empenho.contrato.prazo.nome;
          break;
        default:
          this.vencimento = this.vencimento = empenho.contrato.prazo.nome;
      }
    }
  }

  public baseRetencoes(retencoes: { valorBase: number; ir: number; csll: number; cofins: number; pis: number; }) {
    const valorLiquidacao: number = this.entidadeForm.get('valor_liquidado').value ? this.entidadeForm.get('valor_liquidado').value : this.entidade.valor_liquidado

    if (+retencoes.valorBase.toFixed(2) > valorLiquidacao) {
      toastr.warning(`As quantidades selecionada ultrapassam o Valor Total da Liquidação (${this.funcaoService.formatarMoedaPtBr(valorLiquidacao)}).<br>Por favor, revise e tente novamente!`)
      return;
    }

    $('#dlgItensCompra').modal('hide')

    if (this.login.parametro.contabil.utilizar_aliquota_ir) {
      this.entidadeForm.get('valor_base_ir').setValue(retencoes.valorBase + '')
      this.calcularRetencao(retencoes.valorBase, (retencoes.ir / retencoes.valorBase) * 100, 'IR')
    }

    if (this.login.parametro.contabil.utilizar_aliquota_csll) {
      this.entidadeForm.get('valor_base_csll').setValue(retencoes.valorBase + '')
      this.calcularRetencao(retencoes.valorBase, (retencoes.csll / retencoes.valorBase) * 100, 'CSLL')
    }

    if (this.login.parametro.contabil.utilizar_aliquota_cofins) {
      this.entidadeForm.get('valor_base_cofins').setValue(retencoes.valorBase + '')
      this.calcularRetencao(retencoes.valorBase, (retencoes.cofins / retencoes.valorBase) * 100, 'COFINS')
    }

    if (this.login.parametro.contabil.utilizar_aliquota_pis_pasep) {
      this.entidadeForm.get('valor_base_pis').setValue(retencoes.valorBase + '')
      this.calcularRetencao(retencoes.valorBase, (retencoes.pis / retencoes.valorBase) * 100, 'PIS')
    }
  }

  private getVinculoPreLiquidacao(preLiquidacoes: IPreLiquidacaoExtendido[]) {
    //entrontra as pre liquidações que possuem o mesmo valor que a liquidação
    const preLiquidacaoValorIgual = preLiquidacoes.filter((pre) => (+pre?.valor_liquidado + +pre?.total_anulado) === +this.entidadeForm.get('valor_liquidado').value);
    if (preLiquidacaoValorIgual?.length === 1) { // se encontrar apenas uma vincula ela
      this.entidadeForm.get('preliquidacao').setValue(preLiquidacaoValorIgual[0]);
      return;
    } else if (preLiquidacaoValorIgual?.length > 1) { // se encontrar mais de uma com o mesmo valor tenta procurar pelo documento
      const preLiquidacaoDocumentoIgual = this.validaDocumentoIgual(preLiquidacaoValorIgual);
      if (preLiquidacaoDocumentoIgual) { //encontrou documento igual
        this.entidadeForm.get('preliquidacao').setValue(preLiquidacaoDocumentoIgual);
        return;
      } else { // se nao encontrar o documento igual vincul a com valor igual com menor ID.
        this.entidadeForm.get('preliquidacao').setValue(preLiquidacaoValorIgual[0]);
        return;
      }
    }
  }

  private validaDocumentoIgual(preLiqs: PreLiquidacao[]): PreLiquidacao | undefined {
    if (!this.entidadeForm.get('documento').value) {
      return;
    }

    let documento: string = this.entidadeForm.get('documento').value; // doc normal do valor digitado
    let doc = this.entidadeForm.get('documento').value.replace(/^(0+)(\d)/g, "$2"); // sem os zeros a esquerda
    let docZero: string = doc;
    let docPonto: string = this.entidadeForm.get('documento').value;

    docPonto = docPonto.split('.').join(''); // valor do input digitado sem os pontos
    let docPontoZero = docPonto.split('.').join('').replace(/^(0+)(\d)/g, "$2"); // sem os pontos e sem os zeros
    let docZeroPonto = this.entidadeForm.get('documento').value.split('.').join('').replace(/^(0+)(\d)/g, "$2").trim(); // sem espaços desnecessarios

    const documentoLiq = [documento, docPontoZero, docZero, docPonto, docZeroPonto];

    const documentosIguais = []
    for (const pre of preLiqs) {

      let documento: string = pre?.documento; // doc normal do valor digitado
      let doc = pre.documento?.replace(/^(0+)(\d)/g, "$2"); // sem os zeros a esquerda
      let docZero: string = doc;
      let docPonto: string = pre?.documento;

      docPonto = docPonto?.split('.')?.join(''); // valor do input digitado sem os pontos
      let docPontoZero = docPonto?.split('.')?.join('')?.replace(/^(0+)(\d)/g, "$2"); // sem os pontos e sem os zeros
      let docZeroPonto = pre?.documento?.split('.')?.join('')?.replace(/^(0+)(\d)/g, "$2")?.trim(); // sem espaços desnecessarios

      const documentoPre = `${documento},${docPontoZero},${docZero},${docPonto},${docZeroPonto}`;

      for (const docLiq of documentoLiq) {
        if (documentoPre.includes(docLiq)) {
          documentosIguais.push(pre);
          break;
        }
      }
    }
    const preSelecionada = documentosIguais?.[0];
    return preSelecionada;
  }

  public desvincularPre() {
    if (!this.entidade?.id) {
      return;
    }

    if (!this.login.usuario.administrador) {
      toastr.warning('Operação permitida apenas para administradores.');
      return;
    }

    if (!this.funcaoService.podeAlterarAudesp(this.entidade?.data_liquidacao, this.login)) {
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return;
    }

    this.confirmationService.confirm({
      header: 'Atenção',
      message: 'Deseja realmente remover o vinculo com a pré-liquidação?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: () => {
        this.liquidacaoService
          .removerVinculoPre(this.entidade.id)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(res => {
            this.entidade.preliquidacao = null;
            this.entidadeForm.get('preliquidacao').setValue(null);
            this.submitForm()
          },
            err => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: err.error.payload })
          )
      }
    });
  }

  public converteDataParaDate(data: string): Date {
    const [dia, mes, ano] = data.split('/').map(Number);
    return new Date(ano, mes - 1, dia); // Mês é zero-indexado
  }
}
