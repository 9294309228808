import { Component, Injector, OnInit } from '@angular/core';
import { TransferenciaBancariaService } from 'administrativo-lib';
import {
  BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, LoginContabil, TransferenciaBancaria
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-transferencia-bancaria-list',
  templateUrl: './transferencia-bancaria-list.component.html'
})
export class TransferenciaBancariaListComponent extends BaseResourceListComponent<TransferenciaBancaria, LoginContabil> implements OnInit {

  /**
   * Declaração de variáveis
   */
  public tipoArquivo = 'FEBRABAN';

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private transferenciaService: TransferenciaBancariaService) {
    super(transferenciaService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'especie,conta_pagadora,conta_pagadora.banco,conta_pagadora.orgao,conta_recebedora,conta_recebedora.banco,conta_recebedora.orgao,exercicio,orgao';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id,
      ['exercicio.id']: this.login.exercicio.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['data_transfere$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      date: ['data_transfere'],
      number: ['valor_transferido', 'id'],
      text: ['conta_pagadora.nome', 'conta_recebedora.nome', 'conta_pagadora.numero_conta', 'conta_recebedora.numero_conta', 'especie.nome', 'conta_pagadora.banco.nome'],
    };
  }

  protected afterInit(): void {
    // Depois de iniciar
    this.usarFiltroPersonalizado = true;
  }

  protected acaoRemover(model: TransferenciaBancaria): Observable<TransferenciaBancaria> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Data', coluna: 'data_transfere', agrupar: true },
      { titulo: 'Conta Pagadora', coluna: 'conta_pagadora.nome' },
      { titulo: 'Número conta pagadora', coluna: 'conta_pagadora.numero_conta' },
      { titulo: 'Conta Recebedora', coluna: 'conta_recebedora.nome' },
      { titulo: 'Número conta recebedora', coluna: 'conta_recebedora.numero_conta' },
      {
        titulo: 'Valor', coluna: 'valor_transferido_teste', decimais: 2, alignment: 'right', funcao: [{
          se: {
            condicao: 'especie.estorno=true',
            verdadeiro: ['valor_transferido', '*', -1],
            falso: ['valor_transferido', '*', 1]
          },
        }]
      },
      { titulo: 'Espécie', coluna: 'especie.nome', alignment: 'right' }
    ];
  }

  public obterColunasfiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];

    retorno.push({ titulo: 'Número', coluna: 'id', tipo: 'Number', padrao: true });
    retorno.push({ titulo: 'Data', coluna: 'data_transfere', tipo: 'Date', padrao: true, filtro2: new Date() });
    retorno.push({ titulo: 'Código Pagador', coluna: 'conta_pagadora.numero_conta', tipo: 'String', padrao: true, cols: 3 });
    retorno.push({ titulo: 'Descrição Pagador', coluna: 'descricao_pagadora', tipo: 'String' });
    retorno.push({ titulo: 'Código Recebedor', coluna: 'conta_recebedora.numero_conta', tipo: 'String', padrao: true, cols: 3 });
    retorno.push({ titulo: 'Descrição Recebedor', coluna: 'descricao_recebedora', tipo: 'String' });
    retorno.push({ titulo: 'Espécie', coluna: 'especie.nome', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Valor', coluna: 'valor_transferido', tipo: 'Number', padrao: true });

    return retorno;
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'especie,conta_pagadora,conta_pagadora.banco,conta_pagadora.orgao,conta_recebedora,conta_recebedora.banco,conta_recebedora.orgao,exercicio,orgao';
    this.transferenciaService
      .filtrar(this.paginaCorrente,
        this.limite,
        this.relations()
          ? Object.assign({}, { relations: this.relations() }, parametros)
          : parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE TRANSFERÊNCIAS BANCÁRIAS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem transferencia', ['*', 'auto', '*', 'auto', 'auto', 'auto'], lista.content, [{
                nome: 'valor_transferido_teste', alignment: 'center', funcao: [{
                  se: {
                    condicao: 'especie.estorno=true',
                    verdadeiro: ['valor_transferido', '*', -1],
                    falso: ['valor_transferido', '*', 1]
                  }
                }]
              }]);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public voltar() {
    if (this.login.sistema === 'contabil') {
      this.router.navigate(['/tesouraria']);
    } else {
      this.sair();
    }
  }

  protected podeAlterar(item: TransferenciaBancaria): boolean {
    return this.login.sistema != 'controle-interno'
  }
}
