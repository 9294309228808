import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { AnexoLRFService, BalancoOrcamentariolLRF } from 'administrativo-lib';
import { Exercicio, ExercicioService, GlobalService, LoginPublico } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-transparencia-rreo',
  templateUrl: './transparencia-rreo.component.html'
})
export class TransparenciaRreoComponent implements OnInit, OnDestroy {

  public login: LoginPublico = new LoginPublico();
  public mes: number;
  public listaExercicios: Array<Exercicio>;
  public exercicioSelect: Exercicio;
  public listaAnexos: Array<any>;
  public anexoSelect: string;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLRFService,
    protected exercicioService: ExercicioService,
    protected injector: Injector) {
    this.login = GlobalService.obterSessaoLogin();
    this.mes = 1;
    this.exercicioSelect = this.login.exercicio;
  }

  ngOnInit() {
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaExercicios = dados.content;
      });

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  imprimir() {
    const dp = new BalancoOrcamentariolLRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, false);
    dp.montarRelatorio([this.login.orgao.id]);
  }

  /**
   * Método para verificação de objetos, usados em combos `<select>`
   */
  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

}
