import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Combustivel } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CombustivelService extends BaseResourceService<Combustivel> {

  constructor(
    protected injector: Injector
  ) {
    super(`combustiveis`, injector);
  }

  public obterPorNome(nome: string, orgaoId: number): Observable<Combustivel> {
    return this.http.get<Combustivel>(
      `${this.login.cidade.id}/${this.api}/filtrar?nome$like=${nome}%&orgao_id=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public proximoCodigo(): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-codigo`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
