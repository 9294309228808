
// tslint:disable: no-input-rename
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-page-header',
  templateUrl: './page-header.component.html'
})
export class PageHeaderComponent implements OnInit {

  @Input('page-title') pageTitle: string;

  @Input('page-subtitle') pageSubtitle: string;

  @Input('page-change') pageChange: string;

  @Input('image-title') imageTitle: string;

  @Input('fa-icon') faIcon: string;

  @Input('show-change') showChange: boolean;

  @Input('versao-pncp') versaoPNCP: String;

  constructor() { }

  ngOnInit() {
  }

}
