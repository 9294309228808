import { Injectable, Injector } from '@angular/core';
import { TabelaDescontoCompra } from 'administrativo-lib';
import { BaseResourceService, Compra } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class CompraTabelaDesconto extends BaseResourceService<TabelaDescontoCompra> {

  constructor(
    protected injector: Injector
  ) {
    super(`tabela-desconto-compras`, injector);
  }

}
