import { Component } from '@angular/core';
import { FavorecidoObservacao, BaseResourceItemsComponent } from 'eddydata-lib';
import { FavorecidoObservacaoService } from '../service/favorecido-observacao.service';
import * as toastr from 'toastr';

@Component({
  selector: 'lib-favorecido-observacao',
  templateUrl: './favorecido-observacao.component.html'
})
export class FavorecidoObservacaoComponent extends BaseResourceItemsComponent<FavorecidoObservacao>{


  constructor(public favorecidoObsService: FavorecidoObservacaoService) {
    super(new FavorecidoObservacao(), favorecidoObsService);
  }

  protected afterInit(): void {
  }

  protected validSave(item: FavorecidoObservacao): boolean {
    if (!item.observacao) {
      toastr.warning('Informe o observação!');
      return false;
    }
    return true;
  }

  protected beforeSave(item: FavorecidoObservacao): void {
    item.responsavel = this.login.usuario;
    item.sistema = this.login.sistema;
    item.orgao = this.login.orgao
  }

}