// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { ManutencaoPatrimonio } from './manutencao-patrimonio.model';

export class ManutencaoHistoricoPatrimonio extends BaseResourceModel {
  constructor(
    public id?: number,
    public historico?: string,
    public usuario?: Usuario,
    public manutencao?: ManutencaoPatrimonio,
  ) {
    super();
  }

  static converteJson(json: any): ManutencaoHistoricoPatrimonio {
    return Object.assign(new ManutencaoHistoricoPatrimonio(), json);
  }
}
