// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ChamamentoFavorecido } from './chamamento-favorecido.model';
import { ChamamentoCriterio } from './chamamento-criterio.model';

export class ChamamentoHabilitacao extends BaseResourceModel {
  constructor(
    public id?: number,
    public criterio?: ChamamentoCriterio,
    public favorecido?: ChamamentoFavorecido,
    public habilitado?: boolean,
    public editavel?: boolean
  ) {
    super();
  }
  static converteJson(json: any): ChamamentoHabilitacao {
    return Object.assign(new ChamamentoHabilitacao(), json);
  }
}
