import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, FavorecidoTerceiroSetor } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class FavorecidoTerceiroSetorService extends BaseResourceService<FavorecidoTerceiroSetor> {

  constructor(
    protected injector: Injector
  ) {
    super(`favorecido-terceiro-setores`, injector);
  }

}
