// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { OrgaoAudesp } from '../comum/orgao-audesp.model';
import { Funcionario } from './funcionario.model';

export class FuncionarioCedido extends BaseResourceModel {
  constructor(
    public id?: number,
    public local?: string,
    public data?: Date,
    public motivo?: string,
    public funcionario?: Funcionario,
    public destino?: OrgaoAudesp,
  ) {
    super();
  }
  static converteJson(json: any): FuncionarioCedido {
    return Object.assign(new FuncionarioCedido(), json);
  }
}
