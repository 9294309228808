import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, MovimentoItemEstoque } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class RPMovimentoItemEstoqueService extends BaseResourceService<MovimentoItemEstoque> {

  constructor(
    protected injector: Injector
  ) {
    super(`movimentos-itens-estoque`, injector);
  }

}
