import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, InventarioSetor } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InventarioSetorService extends BaseResourceService<InventarioSetor> {

  constructor(
    protected injector: Injector
  ) {
    super(`inventario-setores`, injector);
  }

  public salvarLocalizados(ent: InventarioSetor, idsMarcar: number[], idsDesmarcar: number[], usuarioId?: number): Observable<InventarioSetor> {
    let params = '';
    if (idsMarcar?.length > 0) params += `marcados=${idsMarcar.join(',')}&`
    if (idsDesmarcar?.length > 0) params += `desmarcados=${idsDesmarcar.join(',')}&`
    if (usuarioId) params += `usuarioId=${usuarioId}&`;

    params += ')';
    params = params.replace('&)', '');

    return this.http.post(`${this.login.cidade.id}/${this.api}/salvar-localizados?${params}`, ent, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public atualizarTermo(id: number): Observable<void> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/atualizar-termo/${id}`, undefined, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public totalizarTermo(inventarioSetor: number, ocultarLoad?: boolean): Observable<{}> {
    return this.http.get<{}>(`${this.login.cidade.id}/${this.api}/totalizar-termo/${inventarioSetor}`, this.httpOptions(ocultarLoad)).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public finalizarTermo(inventarioSetor: number, usuario: number): Observable<InventarioSetor> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/finalizar-termo/${inventarioSetor}/${usuario}`, undefined, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }
}
