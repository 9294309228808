// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { Cidade } from '../geo/cidade.model';

export class Secao extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public cidade?: Cidade,
    public usuarios?: Usuario[]
  ) {
    super();
  }
  static converteJson(json: any): Secao {
    return Object.assign(new Secao(), json);
  }
}
