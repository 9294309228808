import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FuncaoService, GlobalService, Licitacao, ListaVerificacao, ListaVerificacao1, ListaVerificacao2A, ListaVerificacao2B, ListaVerificacao3A, ListaVerificacao3B, ListaVerificacaoAquisicao, ListaVerificacaoComum, ListaVerificacaoPesquisa, ListaVerificacaoServico, Login } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-lista-verificacao',
  templateUrl: './lista-verificacao.component.html',
})
export class ListaVerificacaoComponent implements OnInit, OnDestroy, OnChanges {

  public opcoesSimNaoNA: { id: 'S' | 'N' | 'NA', nome: string }[];
  public opcoesSimNao: { id: boolean, nome: string }[];
  public listaVerificacao1: ListaVerificacao1;
  public listaVerificacao2A: ListaVerificacao2A;
  public listaVerificacao2B: ListaVerificacao2B;
  public listaVerificacao3A: ListaVerificacao3A;
  public listaVerificacao3B: ListaVerificacao3B;
  public listaVerificacaoComum: ListaVerificacaoComum;
  public listaVerificacaoPesquisa: ListaVerificacaoPesquisa;
  public listaVerificacaoAquisicao: ListaVerificacaoAquisicao;
  public listaVerificacaoServico: ListaVerificacaoServico;

  public aquisicao: boolean = false;
  public servico: boolean = false;

  @Input() edicao: boolean = true; //
  @Input() entidade: Licitacao;
  @Input() login: Login;
  @Output() lista: EventEmitter<any> = new EventEmitter();

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected confirmationService: ConfirmationService,
    public funcaoService: FuncaoService,
    protected globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.opcoesSimNaoNA = [
      { id: 'S', nome: 'Sim' },
      { id: 'N', nome: 'Não' },
      { id: 'NA', nome: 'Não se aplica' },
    ]
    this.opcoesSimNao = [
      { id: true, nome: 'Sim' },
      { id: false, nome: 'Não' },
    ]
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['entidade']) {
      if (this.entidade.modalidade.codigo === 6 || this.entidade.modalidade.codigo === 5) {
        if (this.entidade.lista_verificacao?.id) {
          this.listaVerificacao1 = JSON.parse(this.entidade.lista_verificacao.lista_verificacao1) as ListaVerificacao1;
          this.listaVerificacao2A = this.entidade.lista_verificacao.lista_verificacao2A ? JSON.parse(this.entidade.lista_verificacao.lista_verificacao2A) as ListaVerificacao2A : new ListaVerificacao2A();
          this.listaVerificacao2B = this.entidade.lista_verificacao.lista_verificacao2B ? JSON.parse(this.entidade.lista_verificacao.lista_verificacao2B) as ListaVerificacao2B : new ListaVerificacao2B();
          this.listaVerificacao3A = this.entidade.lista_verificacao.lista_verificacao3A ? JSON.parse(this.entidade.lista_verificacao.lista_verificacao3A) as ListaVerificacao3A : new ListaVerificacao3A();
          this.listaVerificacao3B = this.entidade.lista_verificacao.lista_verificacao3B ? JSON.parse(this.entidade.lista_verificacao.lista_verificacao3B) as ListaVerificacao3B : new ListaVerificacao3B();
          this.servico = this.entidade.lista_verificacao.servico;
          this.aquisicao = this.entidade.lista_verificacao.aquisicao;
        } else {
          this.listaVerificacao1 = new ListaVerificacao1();
          this.listaVerificacao2A = new ListaVerificacao2A();
          this.listaVerificacao2B = new ListaVerificacao2B();
          this.listaVerificacao3A = new ListaVerificacao3A();
          this.listaVerificacao3B = new ListaVerificacao3B();
        }
      } else {
        if (this.entidade.lista_verificacao?.id) {
          this.listaVerificacaoComum = this.entidade.lista_verificacao.lista_verificacao_comum ? JSON.parse(this.entidade.lista_verificacao.lista_verificacao_comum) as ListaVerificacaoComum: new ListaVerificacaoComum();
          this.listaVerificacaoPesquisa = this.entidade.lista_verificacao.lista_verificacao_pesquisa ? JSON.parse(this.entidade.lista_verificacao.lista_verificacao_pesquisa) as ListaVerificacaoPesquisa : new ListaVerificacaoPesquisa();
          this.listaVerificacaoAquisicao = this.entidade.lista_verificacao.lista_verificacao_aquisicao ? JSON.parse(this.entidade.lista_verificacao.lista_verificacao_aquisicao) as ListaVerificacaoAquisicao : new ListaVerificacaoAquisicao();
          this.listaVerificacaoServico = this.entidade.lista_verificacao.lista_verificacao_servico ? JSON.parse(this.entidade.lista_verificacao.lista_verificacao_servico) as ListaVerificacaoServico : new ListaVerificacaoServico();
          this.servico = this.entidade.lista_verificacao.servico;
          this.aquisicao = this.entidade.lista_verificacao.aquisicao;
        } else {
          this.listaVerificacaoComum = new ListaVerificacaoComum();
          this.listaVerificacaoPesquisa = new ListaVerificacaoPesquisa();
          this.listaVerificacaoAquisicao = new ListaVerificacaoAquisicao();
          this.listaVerificacaoServico = new ListaVerificacaoServico();
        }
      }
    }
  }


  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public enviarResposta() {
    if (this.entidade.modalidade.codigo === 6 || this.entidade.modalidade.codigo === 5) {
      let lista: ListaVerificacao = {
        lista_verificacao1: this.listaVerificacao1,
        lista_verificacao2A: this.listaVerificacao2A,
        lista_verificacao2B: this.listaVerificacao2B,
        lista_verificacao3A: this.listaVerificacao3A,
        lista_verificacao3B: this.listaVerificacao3B,
        servico: this.servico,
        aquisicao: this.aquisicao,
      }
      this.lista.emit(lista);
    } else {
      let lista: ListaVerificacao = {
        lista_verificacao_comum: this.listaVerificacaoComum,
        lista_verificacao_pesquisa: this.listaVerificacaoPesquisa,
        lista_verificacao_aquisicao: this.listaVerificacaoAquisicao,
        lista_verificacao_servico: this.listaVerificacaoServico,
        servico: this.servico,
        aquisicao: this.aquisicao,
      }
      this.lista.emit(lista);
    }

  }
}
