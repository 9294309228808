import { Component, Input } from "@angular/core";
import { PropostaService } from "administrativo-lib";
import { BaseResourceRptComponent, Coluna, CpfPipe, GlobalService, Licitacao, LicitacaoPipe, Memorial, PregaoHistorico, PregaoLance, Proposta, SituacaoProposta } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";
import { PregaoHistoricoService } from "../../licitacao/service/pregao-historico.service";
import { PregaoLanceService } from "../../licitacao/service/pregao-lance.service";

@Component({
  selector: 'app-classificacao-rpt',
  templateUrl: './classificacao-rpt.component.html'
})
export class ClassificacaoRptComponent extends BaseResourceRptComponent {

  @Input() public licitacao: Licitacao;

  public agrupamento: 'MEMORIAL' | 'PROPONENTE';
  public listAgrupamento: { id: 'MEMORIAL' | 'PROPONENTE', nome: string }[];
  private situacoesProposta: { id: SituacaoProposta, nome: string, style: string, ordem: number }[];
  public pregaoLance: PregaoLance[];

  constructor(
    private globalService: GlobalService,
    private propostaService: PropostaService,
    private historicoService: PregaoHistoricoService,
    private pregaoLanceService: PregaoLanceService,
  ) {
    super();
  }

  protected afterInit(): void {
    this.listAgrupamento = [
      { id: 'MEMORIAL', nome: 'Agrupar por Itens do Memorial' },
      { id: 'PROPONENTE', nome: 'Agrupar por Favorecidos' },
    ];
    this.agrupamento = 'MEMORIAL';

    this.situacoesProposta = this.globalService.obterSituacoesProposta();
  }

  protected tituloRelatorio(): string {
    return `${this.login.orgao.nome} - Classificação Final`;
  }

  protected subTituloRelatorio(): string {
    return `Licitação: ${new LicitacaoPipe().transform(this.licitacao.numero)} - Processo: ${new LicitacaoPipe().transform(this.licitacao.processo)}\nModalidade: ${this.licitacao.modalidade.nome}`;
  }

  protected obterColunasRelatorio(): Coluna[] {
    const retorno: Coluna[] = [];
    if (this.agrupamento === 'MEMORIAL') {
      retorno.push({ titulo: 'Item:', coluna: 'item', bold: true, agrupar: true });
      retorno.push({ titulo: 'Favorecido', coluna: 'favorecido' });
    } else {
      retorno.push({ titulo: 'Favorecido:', coluna: 'favorecido', bold: true, agrupar: true });
      retorno.push({ titulo: 'Item', coluna: 'item' });
    }
    retorno.push({ titulo: 'Marca', coluna: 'marca' });
    retorno.push({ titulo: 'Qtde.', coluna: 'qtde', decimais: 0, alignment: 'right' });
    retorno.push({ titulo: 'Valor Unit.', coluna: 'valor', decimais: 4, alignment: 'right' });
    retorno.push({ titulo: 'Valor Total', coluna: 'total', decimais: 4, alignment: 'right' });
    retorno.push({ titulo: 'Situação', coluna: 'situacao', bold: true, alignment: 'center' });

    return retorno;
  }

  protected larguraColunas(): (string | number)[] {
    return ['*', 100, 'auto', 'auto', 'auto', 'auto'];
  }

  protected totalizarColunas(): (string | {})[] {
    return undefined;
  }

  protected carregarLista(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const lista: {
        item: string,
        favorecido: string,
        marca: string,
        qtde: number,
        valor: number,
        total: number,
        situacao: string,
      }[] = [];
      this.propostaService.obterPorLicitacao(this.licitacao.id).pipe(takeUntil(this.unsubscribe))
        .subscribe(async (res) => {
          const reg: Proposta[] = res.content;
          // ordena lista de acordo com agrupamento e situação, respectivamente
          if (this.agrupamento === 'MEMORIAL') {
            reg
              .sort((a, b) => { return a.proponente.favorecido.nome.localeCompare(b.proponente.favorecido.nome) })
              .sort((a, b) => { return this.situacoesProposta.find(s => s.id === a.situacao).ordem - this.situacoesProposta.find(s => s.id === b.situacao).ordem })
              .sort((a, b) => { return a.memorial.ordem - b.memorial.ordem })
          } else {
            reg
            .sort((a, b) => { return this.situacoesProposta.find(s => s.id === a.situacao).ordem - this.situacoesProposta.find(s => s.id === b.situacao).ordem })
            .sort((a, b) => { return a.memorial.ordem - b.memorial.ordem })
            .sort((a, b) => { return a.proponente.favorecido.nome.localeCompare(b.proponente.favorecido.nome) })
          }

          for(const p of reg) {
            lista.push({
              item: `${p.memorial.ordem} ${p.memorial.descricao} ${p.memorial.unidade} ${this.informacaoCota(p.memorial)}`,
              favorecido: `${new CpfPipe().transform(p.proponente.favorecido.cpf_cnpj)} - ${p.proponente.favorecido.nome}`,
              marca: p.marca,
              qtde: p.situacao === 'VENCEDOR' ? p.quantidade_final : p.memorial.quantidade,
              valor: this.licitacao.modalidade.nome === 'PREGÃO PRESENCIAL' ? await this.valorPregaoLance(p) : (p.situacao === 'VENCEDOR' ? p.valor_final : p.valor_unitario),
              total: this.licitacao.modalidade.nome === 'PREGÃO PRESENCIAL' ? await this.valorPregaoLanceTotal(p) : (p.situacao === 'VENCEDOR' ? p.quantidade_final * p.valor_final : p.memorial.quantidade * p.valor_unitario),
              situacao: this.converterSituacaoProposta(p.situacao, this.obterPosicaoPropostaItem(res.content, p) + 1),
            })
          }
          resolve(lista);
        });
    })
  }

  public async valorPregaoLance(p){
    // se for pregao presencial ele pega do pregao
    this.pregaoLance = (await this.pregaoLanceService.filtrar(1, 1,
      {
        'proponente_id': p.proponente.id,
        'memorial_id': p.memorial.id,
        'cancelado': false,
        'orderBy': 'lance$DESC',
      }).toPromise()).content;

    return this.pregaoLance[0]?.valor ? this.pregaoLance[0]?.valor : 0;
  }

  public async valorPregaoLanceTotal(p){
    // se for pregao presencial ele pega do pregao
    this.pregaoLance = (await this.pregaoLanceService.filtrar(1, 1,
      {
        'proponente_id': p.proponente.id,
        'memorial_id': p.memorial.id,
        'cancelado': false,
        'orderBy': 'lance$DESC',
      }).toPromise()).content;

    return p.quantidade_final > 0 ? this.pregaoLance[0]?.valor * p.quantidade_final : this.pregaoLance[0]?.valor * p.memorial.quantidade;
  }

  private converterSituacaoProposta(situacao: SituacaoProposta, posicao: number): string {
    if (!situacao) return

    switch (situacao) {
      case 'VENCEDOR':
        return 'Vencedor'
      case 'DESISTIDO':
      case 'DESISTIDO_EPPME':
      case 'INEXEQUIVEL':
      case 'EM_NEGOCIACAO':
      case 'CLASSIFICADO':
      case 'DECLINADO':
        return `Classificado ${posicao}º`
      default:
        return this.situacoesProposta.find(p2 => p2.id === situacao).nome
    }
  }

  private obterPosicaoPropostaItem(lista: Proposta[], proposta: Proposta): number {
    if (!lista || !proposta) return

    lista = lista
      .filter(p => p.memorial.id === proposta.memorial.id)
      .sort((a, b) => { return a.proponente.favorecido.nome.localeCompare(b.proponente.favorecido.nome) })
      .sort((a, b) => { return this.situacoesProposta.find(s => s.id === a.situacao).ordem - this.situacoesProposta.find(s => s.id === b.situacao).ordem })
      .sort((a, b) => { return a.memorial.ordem - b.memorial.ordem })

    return lista.indexOf(proposta)
  }
  
  private informacaoCota(item: Memorial): string {
    if (!item || !item.cota || item.cota === 'NAO_DEFINIDO') return ''
    if (item.cota === 'PRINCIPAL') return '(Cota Principal)'
    if (item.cota === 'RESERVADO') return '(Cota Reservada)'
  }
}