import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseResourceRptComponent, Coluna, DateFormatPipe, EddyAutoComplete, FuncaoService, Inventario, InventarioSetor, ParametroPatrimonioService, ProgressoService, Setor, SetorService, Unidade, UnidadeService } from 'eddydata-lib';
import { takeUntil } from 'rxjs/operators';
import { InventarioSetorService } from '../../inventario/service/inventario-setor.service';
import { InventarioService } from '../../inventario/service/inventario.service';
import { InventarioTomboService } from '../../inventario/service/inventario-tombo.service';

@Component({
  selector: 'app-inventario-situacao-rpt',
  templateUrl: './inventario-situacao-rpt.component.html'
})
export class InventarioSituacaoRptComponent extends BaseResourceRptComponent {

  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public ordenacao: { nome: string, coluna: any, ordem: 'DESC' | 'ASC' };
  public conservacao: { nome: string, id: any};
  public listaOrdenacao: { nome: string, coluna: string, ordem: 'DESC' | 'ASC' }[] = [];
  public listaConservacao: { nome: string, id: string}[] = [];
  public parametros: { session?: any, filtros?: any } = {};
  public inventarioSetor: InventarioSetor;
  public retorno: Coluna[] = [];
  public listaSetor: Setor[] = [];
  public setor: Setor;
  public listaSecretaria: Unidade[] = [];
  public secretaria: Unidade;
  public inventario: Inventario;
  public listaInventario: Inventario[] = [];

  public inventarioSetorAutoComplete: EddyAutoComplete<InventarioSetor>

  // ========================================================================
  // ------------------------------ CONSTRUTOR ------------------------------
  // ========================================================================
  constructor(
    protected funcaoService: FuncaoService,
    protected progressoService: ProgressoService,
    protected inventarioService: InventarioService,
    private parametroPatrimonioService: ParametroPatrimonioService,
    private inventarioSetorService: InventarioSetorService,
    private inventarioTomboService: InventarioTomboService,
    private setorService: SetorService,
    private secretariaService: UnidadeService) {
    super();
  }

  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.listaOrdenacao = this.obterColunasOrdenacoes();
    this.listaConservacao = this.obterConservacoes();
    this.ordenacao = this.listaOrdenacao[0];
    this.conservacao = this.listaConservacao[0];

    this.inventarioService.filtrar(0, 0, {
      orgao_id: this.login.orgao.id,
      orderBy: 'descricao'
    }).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      return this.listaInventario = res.content;
    });

    this.setorService.filtrar(0, 0, {
      orgao_id: this.login.orgao.id,
      setor_patrimonio: true,
      ativo: true,
      orderBy: 'nome'
    }).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      const listaSetorAux = [];
        this.listaSetor = res.content;
    })

    this.secretariaService.filtrar(0, 0, {
      orgao_id: this.login.orgao.id,
      ppa_id: this.login['ppa'].id 
    }).pipe(takeUntil(this.unsubscribe)).subscribe(res => {

        this.listaSecretaria = res.content;
    })
  }

  protected tituloRelatorio(): string {
    return `${this.login.orgao.nome}`;
  }

  protected subTituloRelatorio(): string {
    let subTitulo = "Relatório Situações Inventário - ";

    switch(this.conservacao.id){
      case 'A':
        subTitulo += 'ATIVO';
        break; 
      case 'N':
        subTitulo += 'NÃO LOCALIZADO';
        break; 
      case 'M':
        subTitulo += 'EM MANUTENÇÃO';
        break; 
      case 'C':
        subTitulo += 'CESSÃO DE USO';
        break; 
      case 'I':
        subTitulo += 'INSERVÍVEL';
        break; 
    }


    if(this.secretaria){
      subTitulo = subTitulo + " - " + this.secretaria.nome
    }
    
    return subTitulo;
  }

  private carregarAutoCompletes() { }

  protected obterColunasRelatorio(): Coluna[] {
    this.retorno = [];
      this.retorno.push({ titulo: 'Setor', coluna: 'inventario_setor.setor.nome', agrupar: true,bold:true ,fontSize: 10 });
      this.retorno.push({ titulo: 'Placa', coluna: 'tombamento.placa', fontSize: 8, alignment: 'left' });
      this.retorno.push({ titulo: 'Descrição', coluna: 'tombamento.produto.nome', fontSize: 8, alignment: 'left' });
      this.retorno.push({ titulo: 'Marca', coluna: 'tombamento.marca', fontSize: 8, alignment: 'left' });
      this.retorno.push({ titulo: 'Justificativa', coluna: 'situacao_justificativa', fontSize: 8, alignment: 'left' });
      this.retorno.push({ titulo: 'Inventário', coluna: 'inventario_setor.inventario.descricao', fontSize: 8, alignment: 'left' });

    return this.retorno;
  }

  protected larguraColunas(): (string | number)[] {
    let qtdeColunas = ['auto', '*', 'auto', 'auto','auto'];;
   
   
    return qtdeColunas;
  }

  protected totalizarColunas(): (string | {})[] {
    return null;
  }

  protected carregarLista(): Promise<any[]> {
    return new Promise((resolve) => {
      let parametros = {
        relations: 'inventario_setor.setor,inventario_setor.inventario,tombamento.produto'
      };

      parametros['orderBy'] = `inventario_setor.setor.nome,${this.ordenacao.coluna}`;
         
      if (this.inventario)
        parametros['inventario_setor.inventario.id'] = this.inventario.id;
      if (this.secretaria)
        parametros['inventario_setor.setor.unidade.id'] = this.secretaria.id;
      if (this.setor)
        parametros['inventario_setor.setor.id'] = this.setor.id;

      switch(this.conservacao.id){
        case 'A':
          parametros['situacao_atual'] = 'ATIVO';
          break; 
        case 'N':
          parametros['situacao_atual'] = 'NAO_LOCALIZADO';
          break; 
        case 'M':
          parametros['situacao_atual'] = 'MANUTENCAO';
          break; 
        case 'C':
          parametros['situacao_atual'] = 'CESSAO';
          break; 
        case 'I':
          parametros['conservacao_atual'] = 'INSERVIVEL';
          break; 
      }


      if (this.parametros?.filtros)
        parametros = Object.assign(parametros, this.parametros.filtros);

      this.inventarioTomboService.filtrar(0, 0, parametros)
        .subscribe((data) => {
          if (!data?.content)
            resolve(null);
          else
            resolve(data.content);
        });
    });
  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  public obterConservacoes(): { nome: string, id: string}[] {
    return [
      { nome: 'Ativo', id: 'A'},
      { nome: 'Não localizados no setor', id: 'N'},
      { nome: 'Em manutenção', id: 'M'},
      { nome: 'Cessão de uso', id: 'C'},
      { nome: 'Inservível', id: 'I'},
    ];
  }

  public obterColunasOrdenacoes(): { nome: string, coluna: string, ordem: 'DESC' | 'ASC' }[] {
    return [
      { nome: 'Descrição do Bem', coluna: 'tombamento.produto.nome', ordem: 'ASC' },
      { nome: 'Placa', coluna: 'tombamento.placa', ordem: 'ASC' },
    ];
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

}
