// tslint:disable: variable-name
import { EquipeManutencao, TipoInvasao, TipoTransferencia } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Setor } from '../almoxarifado/setor.model';
import { Contrato } from '../compra/contrato.model';
import { Favorecido } from '../compra/favorecido.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { Empenho } from '../contabil/empenho.model';
import { ImovelOcorrenciaHistorico } from './imovel-ocorrencia-historico.model';
import { ImovelOcorrenciaStorage } from './imovel-ocorrencia-storage.model';
import { Imovel } from './imovel.model';
import { TipoImovelOcorrencia } from './tipo-imovel-ocorrencia.model';

export class ImovelOcorrencia extends BaseResourceModel {
  constructor(
    public data_inclusao?: Date,
    public motivo?: string,
    public observacao?: string,
    public tipo?: TipoImovelOcorrencia,
    public data_solicitacao_transferencia?: Date,
    public motivo_solicitacao_transferencia?: string,
    public orgao_fiscalizador?: string,
    public transferencia_pendente?: boolean,
    public status_transferencia?: 'ACEITO' | 'RECUSADO',
    public cont_envio_email?: number,
    public tipo_invasao?: TipoInvasao,
    public tipo_equipe?: EquipeManutencao,
    public tipo_transferencia?: TipoTransferencia,
    public imobiliaria?: Favorecido,
    public imovel?: Imovel,
    public usuario?: Usuario,
    public orgao?: Orgao,
    public setor_origem?: Setor,
    public setor_destino?: Setor,
    public empenhos?: Empenho[],
    public contratos?: Contrato[],
    public arquivos?: ImovelOcorrenciaStorage[],
    public historicos?: ImovelOcorrenciaHistorico[],
    public fornecedor?: Favorecido
  ) {
    super();
  }

  static converteJson(json: any): ImovelOcorrencia {
    return Object.assign(new ImovelOcorrencia(), json);
  }
}
