import { Banco } from '../banco';

export class Itau extends Banco {
    public getNumero() {
        return '341';
    }

    public getDacNossoNumero(): number {
        let dac = 0;

        let campo = this.boleto.getAgencia() + this.boleto.getContaCorrente() + this.boleto.getCarteira() + this.boleto.getNossoNumero();

        let multiplicador = 1;
        let multiplicacao = 0;
        let soma_campo = 0;

        for (let i = 0; i < campo.length; i++) {
            multiplicacao = Number.parseInt(campo.substring(i, 1 + i)) * multiplicador;

            if (multiplicacao >= 10) {
                multiplicacao = Number.parseInt(multiplicacao.toString().substring(0, 1)) + Number.parseInt(multiplicacao.toString().substring(1));
            }
            soma_campo = soma_campo + multiplicacao;

            if (multiplicador == 2)
                multiplicador = 1;
            else
                multiplicador = 2;

        }

        dac = 10 - (soma_campo % 10);

        if (dac == 10)
            dac = 0;

        return dac;
    }

    private getCampo1() {
        let campo = this.getNumero() + this.boleto.getMoeda().toString() + this.boleto.getCarteira().toString() + this.boleto.getNossoNumero().substring(0, 2);
        return this.boleto.getDigitoCampo(campo, 2);
    }

    private getCampo2() {
        let campo = this.boleto.getNossoNumero().substring(2) + this.getDacNossoNumero().toString() + this.boleto.getAgencia().toString().substring(0, 3);

        return this.boleto.getDigitoCampo(campo, 1);
    }

    private getCampo3() {
        let campo = this.boleto.getAgencia().toString().substring(3) + this.boleto.getContaCorrente() + this.boleto.getDvContaCorrente() + '000';

        return this.boleto.getDigitoCampo(campo, 1);
    }

    private getCampo4() {
        let campo = this.getNumero() + this.boleto.getMoeda().toString +
            this.boleto.getFatorVencimento() + this.boleto.getValorTitulo() + this.boleto.getCarteira().toString() +
            this.boleto.getNossoNumero().toString() + this.getDacNossoNumero() +
            this.boleto.getAgencia().toString() + this.boleto.getContaCorrente() + this.boleto.getDvContaCorrente() + '000';

        return this.boleto.getDigitoCodigoBarras(campo);
    }

    private getCampo5() {
        let campo = this.boleto.getFatorVencimento() + this.boleto.getValorTitulo();
        return campo;
    }

    public getCodigoBarras() {
        return this.getNumero() + this.boleto.getMoeda().toString() + this.getCampo4() + this.getCampo5() + this.boleto.getCarteira() + this.boleto.getNossoNumero() + this.getDacNossoNumero() + this.boleto.getAgencia() + this.boleto.getContaCorrente() + this.boleto.getDvContaCorrente() + '000';
    }

    public getLinhaDigitavel() {
        return this.getCampo1().substring(0, 5) + '.' + this.getCampo1().substring(5) + '  ' +
            this.getCampo2().substring(0, 5) + '.' + this.getCampo2().substring(5) + '  ' +
            this.getCampo3().substring(0, 5) + '.' + this.getCampo3().substring(5) + '  ' +
            this.getCampo4() + '  ' + this.getCampo5();
    }

    /**
     * Recupera a carteira no padrao especificado pelo banco
     * @author Gladyston Batista/Eac Software
     */
    public getCarteiraFormatted() {
        return this.boleto.getCarteira();
    }

    /**
     * Recupera a agencia / codigo cedente no padrao especificado pelo banco
     * @author Gladyston Batista/Eac Software
     */
    public getAgenciaCodCedenteFormatted() {
        return this.boleto.getAgencia() + ' / ' + this.boleto.getContaCorrente() + '-' + this.boleto.getDvContaCorrente();
    }

    /**
     * Recupera o nossoNumero no padrao especificado pelo banco
     * @author Gladyston Batista/Eac Software
     */
    public getNossoNumeroFormatted() {
        return Number.parseInt(this.boleto.getNossoNumero()).toString();
    }
}