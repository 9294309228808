import { MemorialService } from "administrativo-lib";
import { Favorecido, Coluna, Memorial, Licitacao, Login, GlobalService, FuncaoService, FormatoExportacao } from "eddydata-lib";

export class EstimativaPrecoExportarRpt {

    private login: Login;
    protected funcaoService: FuncaoService;

    private licitacao: Licitacao;
    private orientacao: 'landscape' | 'portrait';

    private empresas: { indice: number, favorecido: Favorecido }[] = [];
    private modelo: 'SEM_AGRUPAMENTO' | 'COM_AGRUPAMENTO' | 'LISTA' = 'SEM_AGRUPAMENTO';

    constructor(private memorialService: MemorialService) {
        this.funcaoService = new FuncaoService();
        this.login = GlobalService.obterSessaoLogin();
    }

    public exportar(licitacao: Licitacao, titulo: string, formato: FormatoExportacao, orientacao: 'landscape' | 'portrait') {
        this.licitacao = licitacao;
        this.orientacao = orientacao;

        this.carregarLista().then((lista) => {

            const colunasRelatorio = this.obterColunasRelatorio();

            if (lista)
                this.funcaoService.exportar(formato, lista, titulo, colunasRelatorio);
        });
    }


    protected obterColunasRelatorio(): Coluna[] {
        const retorno: Coluna[] = [];

        if (this.modelo === 'SEM_AGRUPAMENTO') {
            retorno.push({ titulo: 'Item', coluna: 'item' });
        } else {
            retorno.push({ titulo: 'Item:', coluna: 'item', bold: true, agrupar: true });
        }

        this.empresas.forEach(f => {
            retorno.push({ titulo: `${f.indice}ª Cotação`, coluna: `valor${f.indice}`, decimais: 4, alignment: 'right' });
        });

        retorno.push({ titulo: 'Média', coluna: 'media', decimais: 4, alignment: 'right' });
        retorno.push({ titulo: 'Estimativa', coluna: 'estimativa', decimais: 4, alignment: 'right' });

        return retorno;
    }

    private carregarLista(): Promise<any[]> {
        return new Promise((resolve) => {
            const lista: any[] = [];
            this.memorialService.filtrar(0, 0, {
                licitacao_id: this.licitacao.id, relations: 'produto_unidade,rpl_itens.cotacoes.favorecido', orderBy: 'ordem,rpl_itens.cotacoes.id'
            }).subscribe(async (res) => {
                const reg: Memorial[] = res.content;

                let favorecidos: Favorecido[] = [];
                this.empresas = [];
                for (const m of reg) {
                    if (m.rpl_itens) {
                        m.cotacoes = []
                        for (const ri of m.rpl_itens) {
                            m.cotacoes = m.cotacoes.concat(Object.assign([], ri.cotacoes));
                            delete ri.cotacoes;
                        }
                    }
                }

                for (const m of reg) {
                    if (m.cotacoes) {
                        for (const c of m.cotacoes) {
                            favorecidos.push(c.favorecido)
                            favorecidos = favorecidos.filter((fav, idx, self) => idx === self.findIndex((f) => f.id === fav.id));
                        }
                    }
                }

                for (let i = 0; i < favorecidos.length; i++) {
                    this.empresas.push({ indice: i + 1, favorecido: favorecidos[i] });
                }

                if (this.orientacao === 'portrait') {
                    if (this.empresas.length <= 3) {
                        this.modelo = 'SEM_AGRUPAMENTO';
                    } else if (this.empresas.length <= 8) {
                        this.modelo = 'COM_AGRUPAMENTO';
                    } else {
                        this.modelo = 'LISTA';
                    }
                } else {
                    if (this.empresas.length <= 8) {
                        this.modelo = 'SEM_AGRUPAMENTO';
                    } else if (this.empresas.length <= 13) {
                        this.modelo = 'COM_AGRUPAMENTO';
                    } else {
                        this.modelo = 'LISTA';
                    }
                }

                for (const memorial of reg) {
                    const mapa = {};
                    mapa['item'] = `${memorial.ordem} - ${memorial.descricao} ${memorial.unidade} - ${memorial.quantidade}x ${this.informacaoCota(memorial)}`;
                    let total = 0;
                    let totalCotacoes = 0;

                    for (const f of this.empresas) {
                        let valorCotacao = +memorial.cotacoes.find(p => p.favorecido.id === f.favorecido.id)?.valor_unitario

                        // caso seja cota e não tenha cotação, pegar do item espelhado da cota
                        if (!valorCotacao && memorial.cota !== 'NAO_DEFINIDO') {
                            const itemCota = reg.find(m => m.produto_unidade.id === memorial.produto_unidade.id && +m.id !== +memorial.id)
                            if (itemCota?.cotacoes)
                                valorCotacao = +itemCota.cotacoes.find(p => p.favorecido.id === f.favorecido.id)?.valor_unitario
                        }
                        if (valorCotacao && valorCotacao > 0)
                            totalCotacoes++;
                        else
                            valorCotacao = 0;

                        mapa[`valor${f.indice}`] = valorCotacao;
                        total += +mapa[`valor${f.indice}`];
                    }
                    mapa['media'] = +Number((total / totalCotacoes).toFixed(4));
                    mapa['estimativa'] = +Number((+mapa['media'] * +memorial.quantidade).toFixed(4));
                    lista.push(mapa);
                }

                resolve(lista)
            });
        });
    }

    private informacaoCota(item: Memorial): string {
        if (!item || !item.cota || item.cota === 'NAO_DEFINIDO') return ''
        if (item.cota === 'PRINCIPAL') return '(Cota Principal)'
        if (item.cota === 'RESERVADO') return '(Cota Reservada)'
    }

}