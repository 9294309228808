import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ExercicioService, OrgaoService, GlobalService, FuncaoService, Relatorio, OrgaoAssinatura, OrgaoAssinaturaService, FormatoExportacao, Exercicio } from 'eddydata-lib';
import { FichaDespesaService } from 'contabil-lib';

@Injectable({
  providedIn: 'root'
})
export class BalanceteDespesaService {

  protected unsubscribe: Subject<void> = new Subject();

  private assinatura: OrgaoAssinatura;

  constructor(
    protected fichaService: FichaDespesaService,
    protected exercicioService: ExercicioService,
    protected orgaoService: OrgaoService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected assinaturaService: OrgaoAssinaturaService
  ) { }

  async montarBalanceteDespesa(formato: FormatoExportacao, mes: number, exercicioId: number, orgaos: number[], login: any, periodoInicial: string, periodoFinal: string, sintetico?: boolean) {
    const parametros: {} = {};
    if (mes) {
      parametros['mes'] = mes;
    } else {
      parametros['dtInicio'] = periodoInicial;
      parametros['dtFim'] = periodoFinal;
    }
    if (exercicioId) {
      parametros['ano'] = exercicioId;
    }
    parametros['orgaos'] = orgaos.join();
    parametros['sintetico'] = sintetico;
    const ex = await this.exercicioService.obterId(exercicioId).toPromise();
    this.fichaService.balancete(parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        let subtitulo = '';
        if (mes) {
          subtitulo = this.globalService.obterDataBR().monthNames[mes - 1].toLocaleUpperCase() + '/' + ex.ano
        } else {
          let dt = periodoInicial.split("-");
          subtitulo = dt[2] + '/' + dt[1] + '/' + dt[0] + ' à ';
          dt = periodoFinal.split("-");
          subtitulo += dt[2] + '/' + dt[1] + '/' + dt[0];
        }
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado('DEMONSTRAÇÃO DA DESPESA POR UNIDADES ORÇAMENTÁRIAS SEGUNDO A NATUREZA DA DESPESA',
            login.usuario.nome, login.usuario.sobrenome, login.orgao.nome, login.brasao,
            this.balanceteDespesaConteudo(dados, sintetico)
              .concat(await this.conteudoAssinatura(login, ex, mes, periodoFinal)),
            'landscape', 'Balancete Analítico da Despesa',
            {
              linhas: {
                hLineWidth(i, node) {
                  return 1;
                },
                vLineWidth(i) {
                  return 1;
                },
                hLineColor(i) {
                  return 'black';
                },
                paddingLeft(i) {
                  return 3;
                },
                paddingRight(i, node) {
                  return 3;
                }
              }
            }, false, false, 'pdf', `REFERÊNCIA: ${subtitulo}`);
        } else if (formato === 'csv') {
          this.balanceteDespesaCSV(dados, sintetico);
        }
      });
  }

  balanceteDespesaConteudo(dados: any[], sintetico: boolean): {}[] {
    let registros: {}[];
    if (sintetico) {
      registros = [
        // [{
        //   text: 'DEMONSTRAÇÃO DA DESPESA POR UNIDADES ORÇAMENTÁRIAS SEGUNDO A NATUREZA DA DESPESA',
        //   colSpan: 13, alignment: 'center', bold: true, fontSize: 11
        // },
        //   '', '', '', '', '', '', '', '', '', '', '', ''],
        [
          { text: !sintetico ? 'FICHA' : 'RECURSO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 },
          { text: 'DESCRIÇÃO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8, colSpan: 2 }, '',
          { text: 'ORÇADA', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 },
          { text: 'MOVIMENTO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 },
          { text: 'ESPECIAL/\nEXTRAORD.', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 },
          { text: 'RECURSOS', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 },
          { text: 'EMPENHADA', alignment: 'center', colSpan: 2, bold: true, fontSize: 8 },
          { text: '' },
          { text: 'SALDO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 },
          { text: 'PAGAMENTO', alignment: 'center', colSpan: 2, bold: true, fontSize: 8 },
          { text: '' },
          { text: 'DÍVIDA', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 }
        ],
        ['', '', '', '', '', '', '',
          { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 8 },
          { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 8 }, '',
          { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 8 },
          { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 8 }, '']
      ];
    } else {
      registros = [
        // [{
        //   text: 'DEMONSTRAÇÃO DA DESPESA POR UNIDADES ORÇAMENTÁRIAS SEGUNDO A NATUREZA DA DESPESA',
        //   colSpan: 13, alignment: 'center', bold: true, fontSize: 11
        // },
        //   '', '', '', '', '', '', '', '', '', '', '', ''],
        [
          { text: 'FUNCIONAL', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 },
          { text: !sintetico ? 'FICHA' : 'RECURSO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 },
          { text: 'DESCRIÇÃO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 },
          { text: 'ORÇADA', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 },
          { text: 'MOVIMENTO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 },
          { text: 'ESPECIAL/\nEXTRAORD.', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 },
          { text: 'RECURSOS', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 },
          { text: 'EMPENHADA', alignment: 'center', colSpan: 2, bold: true, fontSize: 8 },
          { text: '' },
          { text: 'SALDO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 },
          { text: 'PAGAMENTO', alignment: 'center', colSpan: 2, bold: true, fontSize: 8 },
          { text: '' },
          { text: 'DÍVIDA', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 }
        ],
        ['', '', '', '', '', '', '',
          { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 8 },
          { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 8 }, '',
          { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 8 },
          { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 8 }, '']
      ];
    }
    const executora = {};
    let totalMovimento = 0.00;
    let totalEspecial = 0.00;
    let totalOrcado = 0.00;
    let totalEmpenhadoMes = 0.00;
    let totalEmpenhadoArrecadado = 0.00;
    let totalPagoMes = 0.00;
    let totalPagoArrecadado = 0.00;
    let totalRecurso = 0.00;
    let totalSaldo = 0.00;
    let totalDivida = 0.00;

    if (!sintetico) {
      for (const registro of dados) {

        if (!executora['codigo'] || executora['codigo'] !== registro['e_codigo']) {
          if (executora['codigo']) {
            registros.push(this.retornarLinhaSomaExecutora(executora));
          }
          executora['codigo'] = registro['e_codigo'];
          executora['nome'] = registro['e_nome'];
          executora['orcado'] = 0.00;
          executora['movimento'] = 0.00;
          executora['especial'] = 0.00;
          executora['recurso'] = 0.00;
          executora['empenhadoMes'] = 0.00;
          executora['empenhadoArrecadado'] = 0.00;
          executora['saldo'] = 0.00;
          executora['pagoMes'] = 0.00;
          executora['pagoArrecadado'] = 0.00;
          executora['divida'] = 0.00;
          registros.push([
            {
              text: this.funcaoService.mascarar('##.##.##', executora['codigo']) + ' ' + executora['nome'], colSpan: 13,
              bold: true, fontSize: 10
            }, '', '', '', '', '', '', '', '', '', '', '', '',
          ]);
        }
        const movimento: number = registro['movimento'];
        const especial: number = registro['especial'];
        const orcado: number = registro['fh_valor_orcado'];
        const empenhadoMes: number = registro['empenhado_mes'];
        const empenhadoArrecadado: number = registro['empenho_acumulado'];
        const pagoMes: number = registro['pago_mes'];
        const pagoArrecadado: number = registro['pago_acumulado'];
        const recurso: number = (+movimento + +especial + +orcado);
        const saldo = +recurso - +empenhadoArrecadado;
        const divida = +empenhadoArrecadado - +pagoArrecadado;
        executora['orcado'] += +orcado;
        executora['movimento'] += +movimento;
        executora['especial'] += +especial;
        executora['recurso'] += recurso;
        executora['empenhadoMes'] += +empenhadoMes;
        executora['empenhadoArrecadado'] += +empenhadoArrecadado;
        executora['saldo'] += +saldo;
        executora['pagoMes'] += +pagoMes;
        executora['pagoArrecadado'] += +pagoArrecadado;
        executora['divida'] += +divida;
        totalMovimento += +movimento;
        totalEspecial += +especial;
        totalOrcado += +orcado;
        totalEmpenhadoMes += +empenhadoMes;
        totalEmpenhadoArrecadado += +empenhadoArrecadado;
        totalPagoMes += +pagoMes;
        totalPagoArrecadado += +pagoArrecadado;
        totalRecurso += recurso;
        totalSaldo += +saldo;
        totalDivida += +divida;

        registros.push([
          { text: registro['funcional'], border: [true, false, true, false], fontSize: 7 },
          {
            text: this.funcaoService.strZero(registro['fh_numero'], 3),
            border: [true, false, true, false], alignment: 'center', fontSize: 7
          },
          {
            text: this.funcaoService.mascarar('#.#.##.##', registro['d_codigo']) + ' ' + registro['d_nome'],
            border: [true, false, true, false], fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(orcado), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(movimento), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(especial), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(recurso), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(empenhadoMes), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          {
            text: this.funcaoService.convertToBrNumber(empenhadoArrecadado), alignment: 'right',
            border: [true, false, true, false], fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(saldo), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(pagoMes), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(pagoArrecadado), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(divida), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
        ]);
      }
      if (executora['codigo']) {
        registros.push(this.retornarLinhaSomaExecutora(executora));
        registros.push([
          {
            text: 'TOTAL GERAL:', colSpan: 3,
            bold: true, fontSize: 7
          }, '', '',
          { text: this.funcaoService.convertToBrNumber(totalOrcado), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalMovimento), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalEspecial), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalRecurso), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhadoMes), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhadoArrecadado), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalSaldo), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalPagoMes), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalPagoArrecadado), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalDivida), bold: true, alignment: 'right', fontSize: 8 },
        ]);
      }
    } else {
      const grupos = this.funcaoService.agrupar(dados, ['re_codigo', 'd_codigo'], ['empenhado_mes', 'empenho_acumulado', 'especial', 'fh_valor_orcado', 'movimento', 'pago_acumulado', 'pago_mes']);

      for (const registro of grupos) {

        if (!executora['codigo'] || executora['codigo'] !== registro.registros[0]['e_codigo']) {
          if (executora['codigo']) {
            registros.push(this.retornarLinhaSomaExecutora(executora));
          }
          executora['codigo'] = registro.registros[0]['e_codigo'];
          executora['nome'] = registro.registros[0]['e_nome'];
          executora['orcado'] = 0.00;
          executora['movimento'] = 0.00;
          executora['especial'] = 0.00;
          executora['recurso'] = 0.00;
          executora['empenhadoMes'] = 0.00;
          executora['empenhadoArrecadado'] = 0.00;
          executora['saldo'] = 0.00;
          executora['pagoMes'] = 0.00;
          executora['pagoArrecadado'] = 0.00;
          executora['divida'] = 0.00;
          registros.push([
            {
              text: this.funcaoService.mascarar('##.##.##', executora['codigo']) + ' ' + executora['nome'], colSpan: 13,
              bold: true, fontSize: 10
            }, '', '', '', '', '', '', '', '', '', '', '', '',
          ]);
        }
        const movimento: number = registro.totalizadores['movimento'];
        const especial: number = registro.totalizadores['especial'];
        const orcado: number = registro.totalizadores['fh_valor_orcado'];
        const empenhadoMes: number = registro.totalizadores['empenhado_mes'];
        const empenhadoArrecadado: number = registro.totalizadores['empenho_acumulado'];
        const pagoMes: number = registro.totalizadores['pago_mes'];
        const pagoArrecadado: number = registro.totalizadores['pago_acumulado'];
        const recurso: number = (+movimento + +especial + +orcado);
        const saldo = +recurso - +empenhadoArrecadado;
        const divida = +empenhadoArrecadado - +pagoArrecadado;
        executora['orcado'] += +orcado;
        executora['movimento'] += +movimento;
        executora['especial'] += +especial;
        executora['recurso'] += recurso;
        executora['empenhadoMes'] += +empenhadoMes;
        executora['empenhadoArrecadado'] += +empenhadoArrecadado;
        executora['saldo'] += +saldo;
        executora['pagoMes'] += +pagoMes;
        executora['pagoArrecadado'] += +pagoArrecadado;
        executora['divida'] += +divida;
        totalMovimento += +movimento;
        totalEspecial += +especial;
        totalOrcado += +orcado;
        totalEmpenhadoMes += +empenhadoMes;
        totalEmpenhadoArrecadado += +empenhadoArrecadado;
        totalPagoMes += +pagoMes;
        totalPagoArrecadado += +pagoArrecadado;
        totalRecurso += recurso;
        totalSaldo += +saldo;
        totalDivida += +divida;

        registros.push([
          {
            text: this.funcaoService.strZero(registro.grupo['re_codigo'], 2),
            border: [true, false, true, false], alignment: 'center', fontSize: 7
          },
          {
            text: this.funcaoService.mascarar('#.#.##.##', registro.grupo['d_codigo']) + ' ' + registro.registros[0]['d_nome'],
            border: [true, false, true, false], fontSize: 7, colSpan: 2
          }, '',
          { text: this.funcaoService.convertToBrNumber(orcado), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(movimento), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(especial), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(recurso), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(empenhadoMes), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          {
            text: this.funcaoService.convertToBrNumber(empenhadoArrecadado), alignment: 'right',
            border: [true, false, true, false], fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(saldo), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(pagoMes), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(pagoArrecadado), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(divida), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
        ]);
      }
      if (executora['codigo']) {
        registros.push(this.retornarLinhaSomaExecutora(executora));
        registros.push([
          {
            text: 'TOTAL GERAL:', colSpan: 3,
            bold: true, fontSize: 8
          }, '', '',
          { text: this.funcaoService.convertToBrNumber(totalOrcado), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalMovimento), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalEspecial), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalRecurso), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhadoMes), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhadoArrecadado), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalSaldo), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalPagoMes), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalPagoArrecadado), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalDivida), bold: true, alignment: 'right', fontSize: 8 },
        ]);
      }
    }
    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }];
  }

  balanceteDespesaCSV(dados: any[], sintetico: boolean) {
    let registros: {}[][];
    if (sintetico) {
      registros = [[
        { text: 'RECURSO' },
        { text: 'DESCRIÇÃO' },
        { text: 'ORÇADA' },
        { text: 'MOVIMENTO' },
        { text: 'ESPECIAL/EXTRAORD.' },
        { text: 'RECURSOS' },
        { text: 'EMPENHADA DO MÊS' },
        { text: 'EMPENHADA ACUMULADO' },
        { text: 'SALDO' },
        { text: 'PAGAMENTO DO MÊS' },
        { text: 'PAGAMENTO ACUMULADO' },
        { text: 'DÍVIDA' },
      ]];
    } else {
      registros = [[
        { text: 'FUNCIONAL' },
        { text: 'RECURSO' },
        { text: 'DESCRIÇÃO' },
        { text: 'ORÇADA' },
        { text: 'MOVIMENTO' },
        { text: 'ESPECIAL/EXTRAORD.' },
        { text: 'RECURSOS' },
        { text: 'EMPENHADA DO MÊS' },
        { text: 'EMPENHADA ACUMULADO' },
        { text: 'SALDO' },
        { text: 'PAGAMENTO DO MÊS' },
        { text: 'PAGAMENTO ACUMULADO' },
        { text: 'DÍVIDA' },
      ]];
    }
    const executora = {};
    let totalMovimento = 0.00;
    let totalEspecial = 0.00;
    let totalOrcado = 0.00;
    let totalEmpenhadoMes = 0.00;
    let totalEmpenhadoArrecadado = 0.00;
    let totalPagoMes = 0.00;
    let totalPagoArrecadado = 0.00;
    let totalRecurso = 0.00;
    let totalSaldo = 0.00;
    let totalDivida = 0.00;

    if (!sintetico) {
      for (const registro of dados) {
        if (!executora['codigo'] || executora['codigo'] !== registro['e_codigo']) {
          if (executora['codigo']) {
            registros.push([
              { text: 'SOMA' }, { text: '' }, { text: '' },
              { text: this.funcaoService.convertToBrNumber(executora['orcado']) },
              { text: this.funcaoService.convertToBrNumber(executora['movimento']) },
              { text: this.funcaoService.convertToBrNumber(executora['especial']) },
              { text: this.funcaoService.convertToBrNumber(executora['recurso']) },
              { text: this.funcaoService.convertToBrNumber(executora['empenhadoMes']) },
              { text: this.funcaoService.convertToBrNumber(executora['empenhadoArrecadado']) },
              { text: this.funcaoService.convertToBrNumber(executora['saldo']) },
              { text: this.funcaoService.convertToBrNumber(executora['pagoMes']) },
              { text: this.funcaoService.convertToBrNumber(executora['pagoArrecadado']) },
              { text: this.funcaoService.convertToBrNumber(executora['divida']) },
            ]);
          }
          executora['codigo'] = registro['e_codigo'];
          executora['nome'] = registro['e_nome'];
          executora['orcado'] = 0.00;
          executora['movimento'] = 0.00;
          executora['especial'] = 0.00;
          executora['recurso'] = 0.00;
          executora['empenhadoMes'] = 0.00;
          executora['empenhadoArrecadado'] = 0.00;
          executora['saldo'] = 0.00;
          executora['pagoMes'] = 0.00;
          executora['pagoArrecadado'] = 0.00;
          executora['divida'] = 0.00;
          registros.push([{ text: this.funcaoService.mascarar('##.##.##', executora['codigo']) + ' ' + executora['nome'] }]);
        }
        const movimento: number = registro['movimento'];
        const especial: number = registro['especial'];
        const orcado: number = registro['fh_valor_orcado'];
        const empenhadoMes: number = registro['empenhado_mes'];
        const empenhadoArrecadado: number = registro['empenho_acumulado'];
        const pagoMes: number = registro['pago_mes'];
        const pagoArrecadado: number = registro['pago_acumulado'];
        const recurso: number = (+movimento + +especial + +orcado);
        const saldo = +recurso - +empenhadoArrecadado;
        const divida = +empenhadoArrecadado - +pagoArrecadado;
        executora['orcado'] += +orcado;
        executora['movimento'] += +movimento;
        executora['especial'] += +especial;
        executora['recurso'] += recurso;
        executora['empenhadoMes'] += +empenhadoMes;
        executora['empenhadoArrecadado'] += +empenhadoArrecadado;
        executora['saldo'] += +saldo;
        executora['pagoMes'] += +pagoMes;
        executora['pagoArrecadado'] += +pagoArrecadado;
        executora['divida'] += +divida;
        totalMovimento += +movimento;
        totalEspecial += +especial;
        totalOrcado += +orcado;
        totalEmpenhadoMes += +empenhadoMes;
        totalEmpenhadoArrecadado += +empenhadoArrecadado;
        totalPagoMes += +pagoMes;
        totalPagoArrecadado += +pagoArrecadado;
        totalRecurso += recurso;
        totalSaldo += +saldo;
        totalDivida += +divida;

        registros.push([
          { text: registro['funcional'] },
          { text: this.funcaoService.strZero(registro['fh_numero'], 3) },
          { text: this.funcaoService.mascarar('#.#.##.##', registro['d_codigo']) + ' ' + registro['d_nome'] },
          { text: this.funcaoService.convertToBrNumber(orcado) },
          { text: this.funcaoService.convertToBrNumber(movimento) },
          { text: this.funcaoService.convertToBrNumber(especial) },
          { text: this.funcaoService.convertToBrNumber(recurso) },
          { text: this.funcaoService.convertToBrNumber(empenhadoMes) },
          { text: this.funcaoService.convertToBrNumber(empenhadoArrecadado) },
          { text: this.funcaoService.convertToBrNumber(saldo) },
          { text: this.funcaoService.convertToBrNumber(pagoMes) },
          { text: this.funcaoService.convertToBrNumber(pagoArrecadado) },
          { text: this.funcaoService.convertToBrNumber(divida) },
        ]);
      }
      if (executora['codigo']) {
        registros.push([
          { text: 'SOMA' }, { text: '' }, { text: '' },
          { text: this.funcaoService.convertToBrNumber(executora['orcado']) },
          { text: this.funcaoService.convertToBrNumber(executora['movimento']) },
          { text: this.funcaoService.convertToBrNumber(executora['especial']) },
          { text: this.funcaoService.convertToBrNumber(executora['recurso']) },
          { text: this.funcaoService.convertToBrNumber(executora['empenhadoMes']) },
          { text: this.funcaoService.convertToBrNumber(executora['empenhadoArrecadado']) },
          { text: this.funcaoService.convertToBrNumber(executora['saldo']) },
          { text: this.funcaoService.convertToBrNumber(executora['pagoMes']) },
          { text: this.funcaoService.convertToBrNumber(executora['pagoArrecadado']) },
          { text: this.funcaoService.convertToBrNumber(executora['divida']) },
        ]);
        registros.push([
          { text: 'TOTAL GERAL:' }, { text: '' }, { text: '' },
          { text: this.funcaoService.convertToBrNumber(totalOrcado) },
          { text: this.funcaoService.convertToBrNumber(totalMovimento) },
          { text: this.funcaoService.convertToBrNumber(totalEspecial) },
          { text: this.funcaoService.convertToBrNumber(totalRecurso) },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhadoMes) },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhadoArrecadado) },
          { text: this.funcaoService.convertToBrNumber(totalSaldo) },
          { text: this.funcaoService.convertToBrNumber(totalPagoMes) },
          { text: this.funcaoService.convertToBrNumber(totalPagoArrecadado) },
          { text: this.funcaoService.convertToBrNumber(totalDivida) },
        ]);
      }
    } else {
      const grupos = this.funcaoService.agrupar(dados, ['re_codigo', 'd_codigo'], ['empenhado_mes', 'empenho_acumulado', 'especial', 'fh_valor_orcado', 'movimento', 'pago_acumulado', 'pago_mes']);

      for (const registro of grupos) {
        if (!executora['codigo'] || executora['codigo'] !== registro.registros[0]['e_codigo']) {
          if (executora['codigo']) {
            registros.push([
              { text: 'SOMA' }, { text: '' },
              { text: this.funcaoService.convertToBrNumber(executora['orcado']) },
              { text: this.funcaoService.convertToBrNumber(executora['movimento']) },
              { text: this.funcaoService.convertToBrNumber(executora['especial']) },
              { text: this.funcaoService.convertToBrNumber(executora['recurso']) },
              { text: this.funcaoService.convertToBrNumber(executora['empenhadoMes']) },
              { text: this.funcaoService.convertToBrNumber(executora['empenhadoArrecadado']) },
              { text: this.funcaoService.convertToBrNumber(executora['saldo']) },
              { text: this.funcaoService.convertToBrNumber(executora['pagoMes']) },
              { text: this.funcaoService.convertToBrNumber(executora['pagoArrecadado']) },
              { text: this.funcaoService.convertToBrNumber(executora['divida']) },
            ]);
          }
          executora['codigo'] = registro.registros[0]['e_codigo'];
          executora['nome'] = registro.registros[0]['e_nome'];
          executora['orcado'] = 0.00;
          executora['movimento'] = 0.00;
          executora['especial'] = 0.00;
          executora['recurso'] = 0.00;
          executora['empenhadoMes'] = 0.00;
          executora['empenhadoArrecadado'] = 0.00;
          executora['saldo'] = 0.00;
          executora['pagoMes'] = 0.00;
          executora['pagoArrecadado'] = 0.00;
          executora['divida'] = 0.00;
          registros.push([{ text: this.funcaoService.mascarar('##.##.##', executora['codigo']) + ' ' + executora['nome'] }]);
        }
        const movimento: number = registro.totalizadores['movimento'];
        const especial: number = registro.totalizadores['especial'];
        const orcado: number = registro.totalizadores['fh_valor_orcado'];
        const empenhadoMes: number = registro.totalizadores['empenhado_mes'];
        const empenhadoArrecadado: number = registro.totalizadores['empenho_acumulado'];
        const pagoMes: number = registro.totalizadores['pago_mes'];
        const pagoArrecadado: number = registro.totalizadores['pago_acumulado'];
        const recurso: number = (+movimento + +especial + +orcado);
        const saldo = +recurso - +empenhadoArrecadado;
        const divida = +empenhadoArrecadado - +pagoArrecadado;
        executora['orcado'] += +orcado;
        executora['movimento'] += +movimento;
        executora['especial'] += +especial;
        executora['recurso'] += recurso;
        executora['empenhadoMes'] += +empenhadoMes;
        executora['empenhadoArrecadado'] += +empenhadoArrecadado;
        executora['saldo'] += +saldo;
        executora['pagoMes'] += +pagoMes;
        executora['pagoArrecadado'] += +pagoArrecadado;
        executora['divida'] += +divida;
        totalMovimento += +movimento;
        totalEspecial += +especial;
        totalOrcado += +orcado;
        totalEmpenhadoMes += +empenhadoMes;
        totalEmpenhadoArrecadado += +empenhadoArrecadado;
        totalPagoMes += +pagoMes;
        totalPagoArrecadado += +pagoArrecadado;
        totalRecurso += recurso;
        totalSaldo += +saldo;
        totalDivida += +divida;

        registros.push([
          { text: this.funcaoService.strZero(registro.grupo['re_codigo'], 2) },
          { text: this.funcaoService.mascarar('#.#.##.##', registro.grupo['d_codigo']) + ' ' + registro.registros[0]['d_nome'] },
          { text: this.funcaoService.convertToBrNumber(orcado) },
          { text: this.funcaoService.convertToBrNumber(movimento) },
          { text: this.funcaoService.convertToBrNumber(especial) },
          { text: this.funcaoService.convertToBrNumber(recurso) },
          { text: this.funcaoService.convertToBrNumber(empenhadoMes) },
          { text: this.funcaoService.convertToBrNumber(empenhadoArrecadado) },
          { text: this.funcaoService.convertToBrNumber(saldo) },
          { text: this.funcaoService.convertToBrNumber(pagoMes) },
          { text: this.funcaoService.convertToBrNumber(pagoArrecadado) },
          { text: this.funcaoService.convertToBrNumber(divida) },
        ]);
      }
      if (executora['codigo']) {
        registros.push([
          { text: 'SOMA' }, { text: '' },
          { text: this.funcaoService.convertToBrNumber(executora['orcado']) },
          { text: this.funcaoService.convertToBrNumber(executora['movimento']) },
          { text: this.funcaoService.convertToBrNumber(executora['especial']) },
          { text: this.funcaoService.convertToBrNumber(executora['recurso']) },
          { text: this.funcaoService.convertToBrNumber(executora['empenhadoMes']) },
          { text: this.funcaoService.convertToBrNumber(executora['empenhadoArrecadado']) },
          { text: this.funcaoService.convertToBrNumber(executora['saldo']) },
          { text: this.funcaoService.convertToBrNumber(executora['pagoMes']) },
          { text: this.funcaoService.convertToBrNumber(executora['pagoArrecadado']) },
          { text: this.funcaoService.convertToBrNumber(executora['divida']) },
        ]);
        registros.push([
          { text: 'TOTAL GERAL:', fontSize: 8 }, { text: '' },
          { text: this.funcaoService.convertToBrNumber(totalOrcado), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalMovimento), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalEspecial), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalRecurso), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhadoMes), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhadoArrecadado), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalSaldo), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalPagoMes), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalPagoArrecadado), bold: true, alignment: 'right', fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(totalDivida), bold: true, alignment: 'right', fontSize: 8 },
        ]);
      }
    }

    dados = null;
    let csv = '';
    for (let i=0; i<registros.length; i++) {
      const linha = registros[i];
      if (i > 0) csv += '\n';
      for (let x=0; x<linha.length; x++) {
        if (x > 0) csv += ';';
        csv += String(linha[x]['text']);
      }
    }
    registros = null;

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + csv));
    element.setAttribute("download", `Balancete Analítico da Despesa.csv`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  retornarLinhaSomaExecutora(executora: {}) {
    return [
      {
        text: 'SOMA', colSpan: 3,
        bold: true, fontSize: 7
      },
      '',
      '',
      { text: this.funcaoService.convertToBrNumber(executora['orcado']), bold: true, alignment: 'right', fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(executora['movimento']), bold: true, alignment: 'right', fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(executora['especial']), bold: true, alignment: 'right', fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(executora['recurso']), bold: true, alignment: 'right', fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(executora['empenhadoMes']), bold: true, alignment: 'right', fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(executora['empenhadoArrecadado']), bold: true, alignment: 'right', fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(executora['saldo']), bold: true, alignment: 'right', fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(executora['pagoMes']), bold: true, alignment: 'right', fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(executora['pagoArrecadado']), bold: true, alignment: 'right', fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(executora['divida']), bold: true, alignment: 'right', fontSize: 8 },
    ];
  }

  private async conteudoAssinatura(login: any, exercicio: Exercicio, mes: number, dataFinal: string) {
    const conteudo = [];
    const data = dataFinal ?? `${exercicio.ano}-${this.funcaoService.strZero(mes, 2)}-${this.funcaoService.ultimoDiaMes(+mes, exercicio.ano)}`;
    this.assinatura = await this.assinaturaService.obter({ orgao_id: login.orgao.id, 'data_limite$ge': data, 'orderBy': 'data_limite$ASC' }).toPromise();

    if (!this.assinatura) {
      toastr.error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
      throw new Error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
    }

    conteudo.push([
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      },
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      },
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      }
    ]);
    conteudo.push([
      { text: this.assinatura.ordenador_despesa, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.contador, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.tesoureiro, border: [false, false, false, false], bold: true, alignment: 'center' }
    ]);
    conteudo.push([
      { text: this.assinatura.cargo_ordenador_despesa, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.cargo_contador + (this.assinatura.crc_contador ? ` - ${this.assinatura.crc_contador}` : ''), border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.cargo_tesoureiro, border: [false, false, false, false], bold: true, alignment: 'center' }
    ]);

    return [{
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', '*', '*'],
        body: conteudo
      }
    }];
  }

}
