// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';

export class CodigoMercadoriaBB extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public nome?: string,
  ) {
    super();
  }
  static converteJson(json: any): CodigoMercadoriaBB {
    return Object.assign(new CodigoMercadoriaBB(), json);
  }
}
