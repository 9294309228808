import { TabelaSiops19 } from "eddydata-lib";
import { BaseResourceModel } from "../../models/base-resource.model";
import { Recurso } from "../planejamento/recurso.model";

export class RecursoTabelaSiops19 extends BaseResourceModel {
  constructor(
    public tabela_siops_19?: TabelaSiops19,
    public recurso?: Recurso,
  ) {
    super();
  }

  static converteJson(json: any): RecursoTabelaSiops19 {
    return Object.assign(new RecursoTabelaSiops19(), json);
  }
}