// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Ppa } from './ppa.model';
import { Receita } from './receita.model';

export class ProjecaoFonte extends BaseResourceModel {
  constructor(
    public id?: number,
    public percentual_ano1?: number,
    public percentual_ano2?: number,
    public percentual_ano3?: number,
    public percentual_ano4?: number,
    public editavel?: boolean,
    public receita?: Receita,
    public ppa?: Ppa,
    public data_cadastro?: Date,
    public data_alteracao?: Date
  ) {
    super();
  }

  static converteJson(json: any): ProjecaoFonte {
    return Object.assign(new ProjecaoFonte(), json);
  }
}
