import { Banco } from './banco';
import { Santander } from './banco/santander';
import { Bancoob } from './banco/bancoob';
import { CaixaEconomica } from './banco/caixa-economica';
import { CaixaEconomicaNovo } from './banco/caixa-economica-novo';
import { Hsbc } from './banco/hsbc';
import { BancoBrasil } from './banco/banco-brasil';
import { Bradesco } from './banco/bradesco';
import { Boleto } from './boleto';
import { BoletoCampos } from './boleto-campos';

export class RelatorioBoleto {
  private boletos: Array<BoletoCampos> = new Array<BoletoCampos>();
  constructor(protected banco: Banco) {
    if (!(banco instanceof Santander) && !(banco instanceof Bancoob)
      && !(banco instanceof CaixaEconomica) && !(banco instanceof CaixaEconomicaNovo)
      && !(banco instanceof Hsbc) && !(banco instanceof BancoBrasil) && !(banco instanceof Bradesco)) {
      throw new Error('O banco utilizado ainda não é suportado.');
    }
  }

  public getBoletos(): Array<BoletoCampos> {
    return this.boletos;
  }

  public addBoleto(boleto: Boleto) {
    const campos = new BoletoCampos();
    this.banco.setBoleto(boleto);
    const numero = this.banco.getDvNumero() == null ?
      this.banco.getNumero() + '-' +
      boleto.getDigitoCodigoBarras(this.banco.getNumero()) : this.banco.getNumero() + this.banco.getDvNumero();
    campos.banco = numero;
    campos.carteira = this.banco.getCarteiraFormatted();
    campos.cedente = boleto.getCedente();
    campos.cliente_nome = boleto.getNomeSacado();
    campos.conta_corrente = this.banco.getAgenciaCodCedenteFormatted();
    campos.dt_doc = boleto.getDataDocumento();
    campos.dt_processamento = boleto.getDataProcessamento();
    campos.dt_vencimento = boleto.getDataVencimento();
    campos.endereco1 = boleto.getNomeSacado() + '     ' + boleto.getCpfSacado();
    campos.endereco2 = boleto.getEnderecoSacado();
    campos.endereco3 = boleto.getCepSacado() +
      ' ' + boleto.getBairroSacado() + ' - ' + boleto.getCidadeSacado() + ' ' + boleto.getUfSacado();
    campos.especie = boleto.getEspecie();
    campos.instrucao1 = boleto.getInstrucao1();
    campos.instrucao2 = boleto.getInstrucao2();
    campos.instrucao3 = boleto.getInstrucao3();
    campos.instrucao4 = boleto.getInstrucao4();
    campos.instrucao5 = boleto.getInstrucao5();
    campos.acrescimo = boleto.getAcrescimo() != null ?
      Number.parseFloat(boleto.getAcrescimo()).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.0';
    campos.local_pagamento = boleto.getLocalPagamento();
    campos.local_pagamento2 = boleto.getLocalPagamento2();
    campos.moeda = /*boleto.getMoeda()*/'R$';
    campos.nosso_numero = this.banco.getNossoNumeroFormatted();
    campos.valor = Number.parseFloat(
      boleto.getValorBoleto()).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    campos.digito_codbarra = this.banco.getLinhaDigitavel();
    campos.documento = boleto.getDocumento();
    campos.sacado_cpf_cnpj = boleto.getCpfSacado();
    campos.sacado_nome = boleto.getNomeSacado();
    campos.especie_doc = boleto.getEspecieDocumento();
    campos.parcela = boleto.getParcela();
    campos.aceite = boleto.getAceite();
    campos.informacoes_sac = this.banco.getSac();
    campos.endereco = boleto.getEndereco() != null && boleto.getEndereco().length > 0 ? 'ENDEREÇO: ' + boleto.getEndereco() : '';

    campos.codbarra = this.banco.getCodigoBarras();

    this.boletos.push(campos);
  }
}
