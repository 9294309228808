import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { VeiculoService } from 'administrativo-lib';
import { FuncaoService, Login, Veiculo } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-rcms-veiculo-busca-dlg',
  templateUrl: './rcms-veiculo-busca-dlg.component.html'
})
export class RcmsVeiculoBuscaDlgComponent implements OnInit, OnDestroy {

  @Input() login: Login;
  @Input() parametros: {} = {};
  @Output() callback: EventEmitter<Veiculo> = new EventEmitter();
  @Output() cancelarCallback: EventEmitter<boolean> = new EventEmitter();

  public lista: Veiculo[];
  public placa: string;
  public descricao: string;
  public paginaCorrente: number;
  public paginaTotal: number;
  limite: number = 10;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private veiculoService: VeiculoService,
  ) {
  }

  ngOnInit() {
    if (!this.paginaCorrente) {
      this.paginaCorrente = 1;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public buscar() {
    this.paginaCorrente = 1;
    this.preencherGrid();
  }

  private preencherGrid() {
    let parametros = {};
    if (this.parametros) parametros = Object.assign({}, this.parametros);

    if (this.placa) {
      let placa = this.placa.split('-').join('');
      parametros['placa_veiculo$like'] = `%${placa}%`;
    }

    if (this.descricao) {
      parametros['descricao$like'] = `%${new FuncaoService().removerAcentos(this.descricao)}%`;
    }

    if (Object.keys(parametros).length <= 1) {
      toastr.info('Informe os valores de busca');
      return;
    }

    parametros['relations'] = 'tombamento.setor,orgao';
    parametros['orderBy'] = 'descricao,placa_veiculo';
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['ativo'] = true;

    this.veiculoService.filtrar(this.paginaCorrente, this.limite, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        this.lista = lista.content;
        this.paginaTotal = lista.totalPages;
      }, error => alert('erro ao retornar lista'));
  }

  public proximaPagina() {
    this.paginaCorrente = this.paginaCorrente === this.paginaTotal ? this.paginaTotal : this.paginaCorrente + 1;
    this.preencherGrid();
  }

  public paginaAnterior() {
    this.paginaCorrente = this.paginaCorrente === 1 ? 1 : this.paginaCorrente - 1;
    this.preencherGrid();
  }

  public paginaDigitada(pagina: number) {
    this.paginaCorrente = +pagina >= this.paginaTotal ? this.paginaTotal : +pagina;
    this.preencherGrid();
    window.scrollTo(0, 0);
  }

  public mudarLimite(limite: number) {
    this.limite = limite;
    this.paginaDigitada(1);
  }

  public enviarVeiculo(veiculo: Veiculo) {
    this.callback.emit(veiculo);
    this.fechar();
  }

  public fechar() {
    this.placa = undefined;
    this.descricao = undefined;
    this.lista = [];
    $('#dialogVeiculoBusca').modal('hide');
    this.cancelarCallback.emit(true);
  }

  public buscarRegistroIncluido(item: Veiculo) {
    if (item) {
      this.placa = undefined;
      this.descricao = item.descricao;
    }
    this.buscar();
  }

  public podeIncluir(url?: string) {
    if (this.login) {
      if (this.administrador()) {
        return true;
      }
      if (url.lastIndexOf("/") > 0) {
        url = url.substring(0, url.substring(1, url.length).indexOf("/") + 1);
      }
      for (const acesso of this.login.acessos) {
        if (url === acesso.pagina) {
          return acesso.permissao === 2;
        }
      }
    }
    return false;
  }

  administrador() {
    return new FuncaoService().campoJsonToken(this.login.token, "administrador");
  }
}
