import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ContratoPpp } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ContratoPppService extends BaseResourceService<ContratoPpp> {

  constructor(
    protected injector: Injector
  ) {
    super(`contrato-ppp`, injector);
  }

}
