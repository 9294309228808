import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  ContratoService, ConvenioRecursoService, ConvenioService, DespesaService, EmpenhoService, FuncaoGovernoService,
  LicitacaoService, RecursoService
} from 'administrativo-lib';
import { CompraService, ContratoAditamentoService, ModalidadeService } from 'compra-lib';
import {
  OrgaoAssinaturaService, Acao, BaseResourceFormComponent, Contrato, Convenio,
  DateFormatPipe, Despesa, EddyAutoComplete, EmpenhoResto,
  ExercicioService, Favorecido, FavorecidoService, FuncaoGoverno, FuncaoService, GlobalService, Licitacao,
  LoginContabil, Modalidade, Programa, Recurso, ContratoAditamento, Page, Tabela1Reinf
} from 'eddydata-lib';
import * as toastr from 'toastr';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { AcaoService } from '../../planejamento/acao/service/acao.service';
import { ProgramaService } from '../../planejamento/programa/service/programa.service';
import { NotaEmpenhoResto } from '../../relatorio/contabil/nota-empenho-resto';
import { EmpenhoRestoService } from '../service/empenho-resto.service';

@Component({
  selector: 'app-empenho-resto-form',
  templateUrl: './empenho-resto-form.component.html'
})
export class EmpenhoRestoFormComponent extends BaseResourceFormComponent<EmpenhoResto, LoginContabil> {
  /**
   * Declaração de variáveis
   */
  public empenhado: number;
  public liquidado: number;
  public contratoAutoComplete: EddyAutoComplete<Contrato>;
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;
  public subElementoAutoComplete: EddyAutoComplete<Despesa>;
  public licitacaoAutoComplete: EddyAutoComplete<Licitacao>;
  public convenioAutoComplete: EddyAutoComplete<Convenio>;
  public programaAutoComplete: EddyAutoComplete<Programa>;
  public acaoAutoComplete: EddyAutoComplete<Acao>;
  public funcaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public subFuncaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public recursoVariavelAutoComplete: EddyAutoComplete<Recurso>;
  public aditamentoAutoComplete: EddyAutoComplete<ContratoAditamento>;
  public pago: number;
  public especie = "EMR"; // empenho de resto
  public listaModalidades: Array<Modalidade>;
  public naoExisteAditamento = false;
  public saldoConvenio: number = 0;
  public saldoContrato: number = 0;
  public numeroOF: number;
  public validSalvarFechar = false;
  public totalCancelado: number = 0;
  public gruposReinf: Array<any> = new Array<any>();
  public grupoReinf: any;
  @ViewChild('codigo_subelemento') subelementoField: ElementRef;
  @ViewChild('codigo_acao') acaoField: ElementRef;
  @ViewChild('codigo_programa') programaField: ElementRef;
  @ViewChild('codigo_funcao') funcaoField: ElementRef;
  @ViewChild('codigo_subfuncao') subfuncaoField: ElementRef;
  @ViewChild('codigo_recurso') recursoField: ElementRef;
  @ViewChild('codigo_aplicacao') aplicacaoField: ElementRef;
  @ViewChild('codigo_variavel') variavelField: ElementRef;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected favorecidoService: FavorecidoService,
    protected licitacaoService: LicitacaoService,
    protected contratoService: ContratoService,
    protected compraService: CompraService,
    protected convenioService: ConvenioService,
    protected despesaService: DespesaService,
    protected tabela1ReinfService: Tabela1Reinf,
    protected modalidadeService: ModalidadeService,
    private messageService: MessageService,
    private empenhoService: EmpenhoService,
    protected programaService: ProgramaService,
    protected acaoService: AcaoService,
    protected funcaoGovernoService: FuncaoGovernoService,
    protected recursoService: RecursoService,
    protected exercicioService: ExercicioService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected empenhoRestoService: EmpenhoRestoService,
    protected convenioRecursoService: ConvenioRecursoService,
    private aditamentoService: ContratoAditamentoService) {
    super(new EmpenhoResto(), injector, EmpenhoResto.converteJson, empenhoRestoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(_entidade: EmpenhoResto): Promise<boolean> {
    return !_entidade.inscrito_resto && _entidade.especie === 'EMR'
      && (+_entidade.valor_empenho - +_entidade.total_empenho_anulado) > 0
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      valor_empenho: 0,
      data_empenho: [null, [Validators.required]],
      data_vencimento: [null, [Validators.required]],
      tipo_empenho: [null],
      especie: [null, [Validators.required]],
      processo: [null, [Validators.required]],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      orgao: [this.login.orgao, [Validators.required]],
      favorecido: [null, [Validators.required]],
      subelemento: [null, [Validators.required]],
      subelemento_nome: [null, [Validators.required]],
      programa: [null, [Validators.required]],
      programa_nome: [null, [Validators.required]],
      acao: [null, [Validators.required]],
      acao_nome: [null, [Validators.required]],
      funcao: [null, [Validators.required]],
      funcao_nome: [null, [Validators.required]],
      subfuncao: [null, [Validators.required]],
      subfuncao_nome: [null, [Validators.required]],
      recurso: [null, [Validators.required]],
      recurso_nome: [null, [Validators.required]],
      aplicacao: [null, [Validators.required]],
      aplicacao_nome: [null, [Validators.required]],
      recurso_variavel: [null],
      recurso_variavel_nome: [null],
      contrato: [null],
      compra: [null],
      modalidade: [null],
      ata: [null],
      convenio: [null],
      licitacao: [null],
      documento: [null],
      mes: [null],
      exercicio: [this.login.exercicio, [Validators.required]],
      ano: [null, [Validators.required]],
      inscrito_resto: [null],
      usuario_cadastro: [this.login.usuario, [Validators.required]],
      contrato_aditamento: [null],
      data_inscricao: [null, [Validators.required]],
      recurso_siconfi: [null],
      complemento_siconfi: [null],
      complemento_temporario: [null],
      recurso_temporario: [null],
      codigo_reinf: [null],
      ficha_superavit: [false],
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'favorecido,compra,contrato,licitacao,modalidade,convenio,orgao,contrato_aditamento,exercicio' };
  }

  protected afterInit(): void {
    this.modalidadeService.filtrar(1, -1, { 'cidade.id': this.login.cidade.id, orderBy: 'codigo' }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaModalidades = res ? res.content : new Array<Modalidade>();
      }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));
    this.carregarAutoCompletes();
    if (this.currentActionRoute === 'novo') {
      this.empenhoService.proximoNumeroEmpenho(this.login.exercicio.id, this.login.orgao.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.entidadeForm.get('numero').setValue(res);
          this.empenhoService.ultimaDataEmpenhada(this.login.exercicio.id, this.login.orgao.id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((dataEmp) => {
              if (dataEmp === 0) {
                this.entidade.data_empenho = new Date();
              } else {
                this.entidade.data_empenho = new DateFormatPipe().transform(dataEmp, []);
              }
              this.entidadeForm.get('data_empenho').setValue(this.entidade.data_empenho);
              this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidade.data_empenho)?.split('-')?.[1]);
            });
        });
    }
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  private validaClassificacao() {
    try {
      if (this.entidadeForm.get('subelemento').value) {
        this.entidadeForm.get('subelemento_nome').setValue(this.entidadeForm.get('subelemento').value.nome);
        this.entidadeForm.get('subelemento').setValue(this.entidadeForm.get('subelemento').value.codigo);
      }

      if (this.entidadeForm.get('acao').value) {
        this.entidadeForm.get('acao_nome').setValue(this.entidadeForm.get('acao').value.nome);
        this.entidadeForm.get('acao').setValue(this.entidadeForm.get('acao').value.codigo);
      }

      if (this.entidadeForm.get('programa').value) {
        this.entidadeForm.get('programa_nome').setValue(this.entidadeForm.get('programa').value.nome);
        this.entidadeForm.get('programa').setValue(this.entidadeForm.get('programa').value.codigo);
      }

      if (this.entidadeForm.get('funcao').value) {
        this.entidadeForm.get('funcao_nome').setValue(this.entidadeForm.get('funcao').value.nome);
        this.entidadeForm.get('funcao').setValue(this.entidadeForm.get('funcao').value.codigo);
      }

      if (this.entidadeForm.get('subfuncao').value) {
        this.entidadeForm.get('subfuncao_nome').setValue(this.entidadeForm.get('subfuncao').value.nome);
        this.entidadeForm.get('subfuncao').setValue(this.entidadeForm.get('subfuncao').value.codigo);
      }

      if (this.entidadeForm.get('recurso').value) {
        this.entidadeForm.get('recurso_nome').setValue(this.entidadeForm.get('recurso').value.nome);
        this.entidadeForm.get('recurso').setValue(this.entidadeForm.get('recurso').value.codigo);
      }

      if (this.entidadeForm.get('aplicacao').value) {
        this.entidadeForm.get('aplicacao_nome').setValue(this.entidadeForm.get('aplicacao').value.nome);
        this.entidadeForm.get('aplicacao').setValue(this.entidadeForm.get('aplicacao').value.codigo);
      }

      if (this.entidadeForm.get('recurso_variavel').value) {
        this.entidadeForm.get('recurso_variavel_nome').setValue(this.entidadeForm.get('recurso_variavel').value.nome);
        this.entidadeForm.get('recurso_variavel').setValue(this.entidadeForm.get('recurso_variavel').value.codigo);
      }

      if (!this.entidadeForm.get('especie').value) {
        this.entidadeForm.get('especie').setValue(this.especie);
      }
      const dtEmpenho: Date = this.entidadeForm.get('data_empenho').value;
      if (dtEmpenho.getFullYear() !== +this.entidadeForm.get('ano').value) {
        throw new Error('O ano da data do empenho está diferente do exercício logado');
      }
      if (!this.entidadeForm.get('mes').value)
        this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidadeForm.get('data_empenho').value)?.split('-')?.[1]);
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  protected beforeSubmit() {
    this.validaClassificacao();
    try {
      if (!this.entidadeForm.get('numero').value) {
        throw new Error('Informe o número do empenho');
      }
      if (!this.entidadeForm.get('exercicio').value) {
        throw new Error('Informe o exercício do empenho');
      }
      if (!this.entidadeForm.get('orgao').value) {
        throw new Error('Informe o órgão do empenho');
      }
      if (!this.entidadeForm.get('subelemento').value) {
        throw new Error('Informe a despesa do empenho');
      }
      if (!this.entidadeForm.get('subelemento_nome').value) {
        throw new Error('Informe o nome da despesa do empenho');
      }
      if (!this.entidadeForm.get('favorecido').value) {
        throw new Error('Informe o favorecido do empenho');
      }
      if (!this.entidadeForm.get('historico').value) {
        throw new Error('Informe o histórico do empenho');
      }
      if (!this.entidadeForm.get('valor_empenho').value && this.entidadeForm.get('valor_empenho').value != 0) {
        throw new Error('Informe o valor do empenho');
      }
      if (this.entidadeForm.get('valor_empenho').value <= 0) {
        this.entidadeForm.get('valor_empenho').setValue(this.entidadeForm.get('valor_empenho').value * -1);
      }
      if (this.entidadeForm.get('licitacao').value === '') {
        this.entidadeForm.get('licitacao').setValue(null);
      }
      if (this.entidadeForm.get('convenio').value === '') {
        this.entidadeForm.get('convenio').setValue(null);
      }
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  protected afterSubmit(ent: EmpenhoResto) {
    this.mensagemSucesso = `Registro salvo com sucesso!\nEmpenho nº ${ent.numero}`;
    if (this.currentActionRoute === 'novo' && this.login.redirecionar_liquidar_pagar
      && this.podeIncluir('/liquidacoes-orcamentaria')) {
      this.router.navigate(['/liquidacoes-orcamentaria', 'novo', ent.id]);
    } else if (this.currentActionRoute === 'novo' && !this.limparTela) {
      this.router.navigate(['/empenhos-orcamentario', ent.id, 'editar']);
    }
  }

  protected async afterLoad() {
    if (!this.podeAlterarAudesp(this.entidade.data_empenho)) {
      this.router.navigate(['/empenhos-resto']);
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return;
    }

    this.entidade.data_empenho = new DateFormatPipe().transform(this.entidade.data_empenho, []);
    this.entidade.data_vencimento = new DateFormatPipe().transform(this.entidade.data_vencimento, []);
    this.entidade.data_inscricao = new DateFormatPipe().transform(this.entidade.data_inscricao, []);
    this.contratoAutoComplete.id = this.entidade.contrato ? this.entidade.contrato.id : null;
    this.favorecidoAutoComplete.id = this.entidade.favorecido ? this.entidade.favorecido.id : null;
    this.subElementoAutoComplete.id = this.entidade.subelemento;
    this.programaAutoComplete.id = this.entidade.programa;
    this.acaoAutoComplete.id = this.entidade.acao;
    this.funcaoAutoComplete.id = this.entidade.funcao;
    this.subFuncaoAutoComplete.id = this.entidade.subfuncao;
    this.recursoAutoComplete.id = this.entidade.recurso;
    this.aplicacaoAutoComplete.id = this.entidade.aplicacao;
    this.recursoVariavelAutoComplete.id = this.entidade.recurso_variavel;
    this.licitacaoAutoComplete.id = this.entidade.licitacao ? this.entidade.licitacao.id : null;
    this.convenioAutoComplete.id = this.entidade.convenio ? this.entidade.convenio.id : null;

    setTimeout(() => {
      this.subelementoField.nativeElement.select();
      this.acaoField.nativeElement.select();
      this.programaField.nativeElement.select();
      this.funcaoField.nativeElement.select();
      this.subfuncaoField.nativeElement.select();
      this.recursoField.nativeElement.select();
      this.aplicacaoField.nativeElement.select();
      this.variavelField.nativeElement.select();
    })
    this.numeroOF = this.entidade.compra ? this.entidade.compra.numero : null;

    if (this.entidade.contrato) {
      this.obterSaldoContrato(this.entidade.contrato);
    }

    await this.loadTotalizadores();

    this.carregarAditamentoAutoComplete();

    const adiantamentos = await this.findAditamentos();

    if (adiantamentos.content.length < 2) {
      this.naoExisteAditamento = true;
    }

    this.gruposReinf = this.tabela1ReinfService.carregarTabela1();
    if (this.entidade?.codigo_reinf) {
      this.grupoReinf = this.tabela1ReinfService.carregarPorCodigo(this.entidade.codigo_reinf, false);
      //Por algum motivo, o select só seleciona o grupo correto com esse for.
      for (let i = 0; i < this.gruposReinf.length; i++) {
        if (this.gruposReinf[i].codigo == this.grupoReinf.codigo) {
          this.gruposReinf[i] = this.grupoReinf;
          break;
        }
      }
    }
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private async loadTotalizadores() {
    this.empenhado = 0;
    this.liquidado = 0;
    this.pago = 0;

    let totalizadores = await this.empenhoRestoService.totalizadores(
      this.entidade.ano, this.login.orgao.id, this.login.exercicio.id, this.entidade.id).toPromise();

    if (totalizadores) {
      totalizadores = totalizadores.content[0] ? totalizadores.content[0] : totalizadores.content;
      this.empenhado = totalizadores.total_empenhado;
      this.liquidado = totalizadores.total_liquidado;
      this.pago = totalizadores.total_pago;
      this.totalCancelado = +totalizadores.total_cancelado_nao_processado + +totalizadores.total_cancelado_processado

      this.totalCancelado = this.totalCancelado < 0 ? (this.totalCancelado * -1) : this.totalCancelado
    }
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public conversor = (entidade: object | string) => {
    if (typeof entidade === 'string') {
      return entidade;
    }
    return entidade ? entidade['nome'] : null;
  }

  public obterSaldoConvenio() {
    this.empenhoService.totalEmpenhadoPorContrato(this.entidadeForm.get('convenio').value.id, this.login.orgao.id)
      .subscribe((saldo) => {
        this.saldoConvenio = this.entidadeForm.get('convenio').value.valor_convenio - saldo;
      });
  }

  public obterSaldoContrato(contrato: Contrato) {
    this.empenhoService.totalEmpenhadoPorContrato(contrato ? contrato.id : this.entidadeForm.get('contrato').value.id, this.login.orgao.id)
      .subscribe((saldo) => {
        if (contrato.tipo_contratacao && contrato.tipo_contratacao.material && contrato.tipo_contratacao.servico) {
          if (this.entidadeForm.get('subelemento').value.substr(0, 6) == '449052') {
            this.saldoContrato = this.entidadeForm.get('contrato').value.valor_material - saldo;
          } else if (this.entidadeForm.get('ficha').value && (
            this.entidadeForm.get('subelemento').value.substr(0, 6) == '339036' || this.entidadeForm.get('subelemento').value.substr(0, 6) == '339039')) {
            this.saldoContrato = this.entidadeForm.get('contrato').value.valor_servico - saldo;
          } else {
            this.saldoContrato = this.entidadeForm.get('contrato').value.valor_contrato - saldo;
          }
        } else {
          this.saldoContrato = this.entidadeForm.get('contrato').value.valor_contrato - saldo;
        }
      });
  }

  private carregarAutoCompletes() {
    this.subElementoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('subelemento'), this.despesaService, 'codigo', ['nome'],
      { exercicio_id: this.login.exercicio.id, nivel: 6, orderBy: 'codigo', ativo: true },
      { number: ['id'], text: ['codigo', 'nome'] },
    );

    this.favorecidoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('favorecido'), this.favorecidoService,
      'id', ['nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );

    // autocomplete para acao
    this.acaoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('acao'), this.acaoService,
      'codigo', ['nome'], { ppa_id: this.login.ppa.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para licitacao
    this.licitacaoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('licitacao'), this.licitacaoService,
      'numero', ['numero'], { orgao_id: this.login.orgao.id, orderBy: 'numero' }, { number: ['numero'] }
    );

    // autocomplete para convenio
    this.convenioAutoComplete = new EddyAutoComplete(this.entidadeForm.get('convenio'), this.convenioService,
      'numero', ['numero', 'ano'], { orgao_id: this.login.orgao.id, orderBy: 'numero' }, { number: ['id', 'numero'] }
    );

    // autocomplete para programa
    this.programaAutoComplete = new EddyAutoComplete(this.entidadeForm.get('programa'), this.programaService,
      'codigo', ['nome'], { ppa_id: this.login.ppa.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para funcao governo
    this.funcaoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('funcao'), this.funcaoGovernoService,
      'codigo', ['nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para subfuncao governo
    this.subFuncaoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('subfuncao'), this.funcaoGovernoService,
      'codigo', ['nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para aplicacao
    this.recursoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('recurso'),
      this.recursoService, 'codigo', ['nome'],
      { cidade_id: this.login.cidade.id, nivel$in: '0', orderBy: 'codigo' },
      { text: ['codigo', 'nome'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('aplicacao'),
      this.recursoService, 'codigo', ['nome'],
      { cidade_id: this.login.cidade.id, nivel$in: '1', orderBy: 'codigo' },
      { text: ['codigo', 'nome'] }
    );

    // autocomplete para aplicacao
    this.recursoVariavelAutoComplete = new EddyAutoComplete(this.entidadeForm.get('recurso_variavel'),
      this.recursoService, 'codigo', ['nome'],
      { cidade_id: this.login.cidade.id, nivel$in: '2', orderBy: 'codigo' },
      { text: ['codigo', 'nome'] }
    );

    // Contrato Autocomplete
    this.contratoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('contrato'), this.contratoService,
      'numero', ['numero'], {
      orgao_id: this.login.orgao.id, orderBy: 'numero',
      relations: 'licitacao,tipo_contratacao'
    }, { text: ['numero'] },
      () => {
        const contrato: Contrato = this.entidadeForm.get('contrato').value
          ? this.entidadeForm.get('contrato').value
          : this.entidade.contrato;
        if (contrato) {
          this.entidadeForm.get('licitacao').setValue(null);
          this.entidadeForm.get('modalidade').setValue(null);
          this.entidadeForm.get('contrato').setValue(contrato);
          this.entidadeForm.get('processo').setValue(contrato.processo);
          if (contrato.licitacao) {
            this.licitacaoService.obter({ id: contrato.licitacao.id, relations: 'modalidade' }).pipe(takeUntil(this.unsubscribe))
              .subscribe((res) => {
                this.entidadeForm.get('licitacao').setValue(res);
                this.entidadeForm.get('modalidade').setValue(res.modalidade);
              }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));
          }
          this.obterSaldoContrato(contrato);
        }
      }
    );

    // Aditamento Autocomplete
    this.carregarAditamentoAutoComplete();
  }

  // Autocomplete do aditamento
  private carregarAditamentoAutoComplete(): void {
    let contrato_id = null;
    if (this.entidadeForm.get('contrato').value) {
      contrato_id = +this.entidadeForm.get('contrato').value.id
    } else {
      contrato_id = 0;
    }

    this.aditamentoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('contrato_aditamento'), this.aditamentoService,
      'id', ['numero'],
      {
        contrato_id,
        data_inicio$le: this.funcaoService.converteDataSQL(this.entidadeForm.get('data_empenho').value),
        data_termino$ge: this.funcaoService.converteDataSQL(this.entidadeForm.get('data_empenho').value),
      },
      {
        text: ['numero']
      })
  }

  private async findAditamentos(): Promise<Page> {
    let contrato_id = null;
    if (this.entidadeForm.get('contrato').value) {
      contrato_id = +this.entidadeForm.get('contrato').value.id
    } else {
      contrato_id = 0;
    }

    const data = this.entidadeForm.get('data_empenho').value;

    if (!data) {
      return { content: [] } as Page;
    }


    return await this.aditamentoService.filtrar(1, 0, {
      contrato_id,
      data_inicio$le: this.funcaoService.converteDataSQL(data),
      data_termino$ge: this.funcaoService.converteDataSQL(data),
    }).toPromise();
  }

  public async setAditamento(): Promise<void> {
    try {
      this.entidadeForm.get('contrato_aditamento').setValue(null);
      this.aditamentoAutoComplete.id = null;

      const aditamentos = await this.findAditamentos();

      if (!aditamentos.content.length) {
        this.naoExisteAditamento = true;
      } else if (aditamentos.content.length === 1) {
        this.naoExisteAditamento = true;
        this.carregarAditamentoAutoComplete();
        this.entidadeForm.get('contrato_aditamento').setValue(aditamentos.content[0]);
      } else {
        this.naoExisteAditamento = false;
        this.carregarAditamentoAutoComplete();
      }
    } catch (err) {
      if (err instanceof Error) {
        console.error(err.message);
        toastr.error(err.message, 'Erro ao buscar aditamentos')
      }
    }
  }

  public async carregarDadosCompra() {
    if (this.numeroOF === 0) {
      return;
    }

    let ano = 0;
    if (this.entidade.id) {
      ano = this.entidade.ano;
    } else {
      ano = this.entidadeForm.get('ano').value;
    }

    const ex = await this.exercicioService.obter({ ano: ano, cidade_id: this.login.cidade.id }).toPromise();

    this.compraService.extendido(1, 1, {
      numero: this.numeroOF, 'exercicio.id': ex.id, 'orgao.id': this.login.orgao.id, simplificado: true,
      relations: ['empenho', 'empenho.exercicio', 'ficha', 'modalidade', 'licitacao', 'subelemento',
        'convenio', 'favorecido', 'favorecido.tipo', 'ficha.despesa', 'exercicio', 'orgao', 'requerente', 'rcms', 'contrato'
      ].join(',')
    }).subscribe((pagina) => {
      if (!pagina?.content || pagina.content.length === 0)
        return;
      let compra = pagina.content[0];
      if (compra.excluido) {
        this.messageService.add({ severity: 'warn', summary: 'Erro', detail: `Ordem de fornecimento ${compra.numero}/${compra.exercicio.ano} está excluido.` });
        this.entidadeForm.get('compra').setValue(null);
        return;
      }
      if (compra?.empenho?.id) {
        this.messageService.add({ severity: 'warn', summary: 'Erro', detail: `Ordem de fornecimento ${compra.numero}/${compra.exercicio.ano} já foi vinculado ao empenho ${compra.empenho.numero}/${compra.empenho.exercicio.id}` });
        this.entidadeForm.get('compra').setValue(null);
        return;
      }

      this.entidadeForm.get('compra').setValue(compra);
      this.entidadeForm.get('tipo_empenho').setValue(compra.tipo_compra);
      this.entidadeForm.get('processo').setValue(compra.processo);
      this.entidadeForm.get('licitacao').setValue(compra.licitacao);
      this.entidadeForm.get('contrato').setValue(compra.contrato);
      this.entidadeForm.get('convenio').setValue(compra.convenio);
      this.entidadeForm.get('documento').setValue(compra.documento);
      this.entidadeForm.get('valor_empenho').setValue(compra['total_compra']);
      this.entidadeForm.get('ficha').setValue(compra.ficha);
      this.entidadeForm.get('subelemento').setValue(compra.subelemento);
      this.entidadeForm.get('modalidade').setValue(compra.modalidade);
      this.entidadeForm.get('favorecido').setValue(compra.favorecido);
      this.favorecidoAutoComplete.atualizar(true);
      this.subElementoAutoComplete.atualizar(true);
    });
  }

  public async salvarFechar() {
    this.validSalvarFechar = true;
    await this.submitForm(false);
  }

  imprimir() {
    const parametros = {};

    let relations = 'contrato,compra.ficha.aplicacao_variavel,favorecido.contas.banco,convenio,modalidade,licitacao';

    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id'] = this.entidade.id;

    this.empenhoRestoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        new NotaEmpenhoResto(this.assinaturaService).imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error.payload ? error.error.payload : error }
        )
      );
  }

  public alterarRecursoSiconfi(recurso_siconfi: string) {
    if (recurso_siconfi) {
      this.entidade['editavel'] = true;
      this.entidadeForm.get(['recurso_temporario']).setValue(this.entidadeForm.get('recurso_siconfi').value)
    }
  }

  public salvarRecursoSiconfi(recurso_siconfi: string) {
    if (recurso_siconfi) {
      this.entidade['editavel'] = false;
      if (!this.entidadeForm.get('recurso_siconfi')?.value || this.entidadeForm.get('recurso_siconfi')?.value === '') {
        this.entidadeForm.get('recurso_siconfi').setValue(this.entidade['recurso_temporario']);
      } else {
        this.entidadeForm.get('recurso_siconfi').setValue(recurso_siconfi)

        this.empenhoRestoService.atualizar(this.entidadeForm.value).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.success('Recurso Siconfi alterado com sucesso!');
          }, error => {
            toastr.error(error.error.payload);
            throw error;
          });
      }
    }
  }

  public alterarComplementoSiconfi(complemento_siconfi: string) {
    this.entidade['editavelC'] = true;
    this.entidadeForm.get(['complemento_temporario']).setValue(this.entidadeForm.get('complemento_siconfi').value ?? '1')
  }

  public salvarComplementoSiconfi(complemento_siconfi: string) {
    if (complemento_siconfi) {
      this.entidade['editavelC'] = false;
      if (!this.entidadeForm.get('complemento_siconfi').value || this.entidadeForm.get('complemento_siconfi').value === '') {
        this.entidadeForm.get('complemento_siconfi').setValue(this.entidade['complemento_temporario']);
      } else {
        this.entidadeForm.get('complemento_siconfi').setValue(complemento_siconfi)
        this.empenhoRestoService.atualizar(this.entidadeForm.value).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.success('Complemento Siconfi alterado com sucesso!');
          }, error => {
            toastr.error(error.error.payload);
            throw error;
          });
      }
    }
  }

  public salvarCodigoReinf() {
    this.entidade.codigo_reinf = this.entidadeForm.get('codigo_reinf').value
    this.empenhoRestoService.atualizar(this.entidade).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        toastr.success('Codigo Reinf alterado com sucesso!');
      }, error => {
        toastr.error(error?.error?.payload);
        throw error;
      });
  }

}
