import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from '../../entidade/comum/usuario.model';
import { Subject } from 'rxjs';
import { Sistemas } from '../../components/types';
import { FuncaoService } from '../../util/funcao.service';
import { ProgressoService } from '../../components/progresso/service/progresso.service';
import { Login } from '../../entidade/login/login';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { LoginEntidade } from '../../entidade/login/login-entidade';
import { LoginPublico } from '../../entidade/login/login-publico';
import { LoginProtocolo } from '../../entidade/login/login-protocolo';
import { LoginFolha } from '../../entidade/login/login-folha';
import { Orgao } from '../../entidade/comum/orgao.model';
import { takeUntil } from 'rxjs/operators';
import { OrgaoService } from '../../orgao/service/orgao.service';
import { ExercicioService } from '../../exercicio/service/exercicio.service';
import { UsuarioService } from '../../usuario/service/usuario.service';
import { UsuarioAcessoService } from '../../usuario/service/usuario-acesso.service';
import { environment } from '../../../environments/environment';
import { ParametroContabilService } from './parametro-contabil.service';
import { PpaService } from './ppa.service';
import { GlobalService } from '../../util/global.service';
import { ParametroLicitacaoService } from '../../licitacao/parametro-licitacao/service/parametro-licitacao.service';
import { SetorAlmoxarifadoService } from '../../almoxarifado/setor/service/setor-almoxarifado.service';
import { ParametroRequisicaoService } from '../../requisicao/parametros-requisicao/service/parametro-requisicao.service';
import { ParametroPatrimonioService } from '../../patrimonio/parametro-patrimonio/service/parametro-patrimonio.service';
import { ParametroCompraService } from '../../compra/parametros-compra/service/parametro-compra.service';
import { EstoqueService } from '../../almoxarifado/estoque/service/estoque.service';
import { ParametroAlmoxarifadoService } from '../../almoxarifado/parametro-almoxarifado/service/parametro-almoxarifado.service';
import { SetorService } from '../../almoxarifado/setor/service/setor.service';
import { AudespService } from '../../audesp/service/audesp.service';


@Injectable({
  providedIn: 'root'
})
export class LoginService implements OnDestroy {

  private usuario: Usuario;
  public usuarioAutenticado = false;
  protected unsubscribe: Subject<void> = new Subject();
  mostrarMenuEmitter = new EventEmitter<boolean>();
  mostrarMenuLateralEmitter = new EventEmitter<boolean>();
  sistemaEmitter = new EventEmitter<Sistemas>();
  mostrarContrasteEmitter = new EventEmitter<boolean>();

  error = {
    error: {
      message: ''
    }
  };

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  constructor(
    private router: Router,
    private orgaoServico: OrgaoService,
    private funcaoServico: FuncaoService,
    private usuarioServico: UsuarioService,
    private exercicioServico: ExercicioService,
    private setorServico: SetorService,
    private setorAlmoxarifadoServico: SetorAlmoxarifadoService,
    private parametroAlmoxarifadoService: ParametroAlmoxarifadoService,
    private estoqueService: EstoqueService,
    private parametroServico: ParametroContabilService,
    private parametroLicitacaoServico: ParametroLicitacaoService,
    private parametroPatrimonioServico: ParametroPatrimonioService,
    private ppaServico: PpaService,
    private usuarioAcessoServico: UsuarioAcessoService,
    private progressoServico: ProgressoService,
    private parametroRequisicaoServico: ParametroRequisicaoService,
    private audespServico: AudespService,
    private parametroComprasServico: ParametroCompraService
  ) { }

  async autenticar(email: string, senha: string, sistema: Sistemas, cnpj?: string, orgao?: number) {
    try {
      this.error = {
        error: {
          message: ''
        }
      };
      const dados = await this.usuarioServico.autenticar(email, senha, sistema, orgao).toPromise();
      if (dados.entidade) {
        this.usuario = dados.entidade;
        if (this.usuario.favorecido && (!cnpj || cnpj === '')) {
          this.error.error.message = 'Informe o CNPJ da Entidade!';
          GlobalService.limparSessaoLogin(true);
          return this.error;
        }
        let login = new Login();
        switch (sistema) {
          case 'e-sic':
          case 'transparencia':
          case 'almoxarifado':
          case 'site':
            login = new LoginPublico();
            break;
          case 'protocolo':
          case 'protocolo-cidadao':
            login = new LoginProtocolo();
            break;
          case 'recursos-humanos':
            login = new LoginFolha();
            break;
          default:
            login = new LoginContabil();
        }
        const usuario = new Usuario();
        usuario.id = this.usuario.id;
        usuario.nome = this.usuario.nome;
        usuario.convidado = this.usuario.convidado;
        usuario.sobrenome = this.usuario.sobrenome;
        usuario.cpf = this.usuario.cpf;
        usuario.telefone = this.usuario.telefone;
        usuario.email = this.usuario.email;
        usuario.administrador = this.usuario.administrador;
        usuario.sistemas_administrador = this.usuario.sistemas_administrador;
        usuario.sistema = await this.getSistema(login, sistema);
        usuario.setor = this.usuario.setor;
        usuario.unidade = this.usuario.unidade;
        usuario.estoque = this.usuario.estoque;
        usuario.controle_interno = this.usuario.controle_interno;
        usuario.eddydata = this.usuario.eddydata;
        usuario.tipo_chave = this.usuario.tipo_chave;
        usuario.caminho_a1 = this.usuario.caminho_a1;
        login.usuario = usuario;
        const orgao = new Orgao();
        orgao.id = this.usuario.orgao.id;
        orgao.codigo = this.usuario.orgao.codigo;
        orgao.nome = this.usuario.orgao.nome;
        orgao.cnpj = this.usuario.orgao.cnpj;
        orgao.endereco = this.usuario.orgao.endereco;
        orgao.cep = this.usuario.orgao.cep;
        orgao.bairro = this.usuario.orgao.bairro;
        orgao.especie = this.usuario.orgao.especie;
        orgao.telefone = this.usuario.orgao.telefone;
        orgao.site = this.usuario.orgao.site;
        orgao.tribunal = this.usuario.orgao.tribunal;
        orgao.ibge = this.usuario.orgao.ibge;
        orgao.email = this.usuario.orgao.email;
        orgao.tipo_orgao_siconfi = this.usuario.orgao.tipo_orgao_siconfi;
        orgao.data_implantacao = this.usuario.orgao.data_implantacao;
        orgao.tipo_integracao = this.usuario?.orgao?.tipo_integracao;
        // orgao.chave_acesso = this.usuario?.orgao?.chave_acesso;

        login.orgao = orgao;

        login.brasao = this.usuario.orgao.brasao ? this.usuario.orgao.brasao.brasao : '';
        login.bandeira = this.usuario.orgao.brasao ? this.usuario.orgao.brasao.bandeira : '';
        login.sistema = sistema;
        login.versao = environment.versao;
        login.cidade = this.usuario.orgao.cidade;
        let ano = new Date().getFullYear();

        login.token = 'JWT ' + dados.token;
        login.acessos = this.usuario.acessos;
        login.limite = 20;
        GlobalService.salvarSessaoLogin(login);
        let ex = await this.exercicioServico.obterPorAno(ano, login.cidade.id).toPromise();

        if (!ex.content[0]) {
          ano = +ano - 1;
          ex = await this.exercicioServico.obterPorAno(ano, login.cidade.id).toPromise();
          if (!ex.content[0]) {
            this.error.error.message = 'O exercício atual não foi cadastrado, por favor entre em contato com o administrador do sistema!';
            GlobalService.limparSessaoLogin(true);
            return this.error;
          }
        }
        login.exercicio = ex.content[0];
        switch (usuario.sistema) {
          case 'e-sic':
          case 'site':
          case 'transparencia':
            const loginPublico = login as LoginPublico;
            await this.loginContabilidade(loginPublico, ano);
            login = loginPublico;
            if (!loginPublico.ppa) {
              this.error.error.message =
                'Não foi encontrado um plano plurianual para o ano corrente, entre em contato com o Administrador do sistema!';
              GlobalService.limparSessaoLogin(true);
              return this.error;
            }
            break;
          case 'protocolo':
            const loginProtocolo = login as LoginProtocolo;
            await this.loginContabilidade(loginProtocolo, ano);
            login = loginProtocolo;
            break;
          case 'almoxarifado':
            if (login.usuario.estoque?.id && login.usuario.setor?.id) {
              login.setorAlmoxarifado = await this.setorAlmoxarifadoServico.obter({
                'estoque.id': login.usuario.estoque.id, 'setor.id': login.usuario.setor.id, relations: ['setor', 'estoque']
              }).toPromise();
              if (!login.setorAlmoxarifado) {
                this.error.error.message = 'Usuário não vinculado à nenhum setor do almoxarifado principal!';
                GlobalService.limparSessaoLogin(true);
                return this.error;
              }
            } else {
              this.error.error.message = 'Usuário não vinculado à nenhum setor ou almoxarifado!';
              GlobalService.limparSessaoLogin(true);
              return this.error;
            }
            const loginContabilAlmox = login as LoginContabil;
            await this.loginContabilidade(loginContabilAlmox, ano);
            login = loginContabilAlmox;
            GlobalService.salvarSessaoLogin(login);
            this.parametroAlmoxarifadoService.login = login;
            login.parametro = login.parametro ? login.parametro : {}
            login.parametro['almoxarifado'] = await this.parametroAlmoxarifadoService.obterParametro(login.setorAlmoxarifado.estoque.id, login.exercicio.id).toPromise();
            break;
          case 'recursos-humanos':
            const loginFolha = login as LoginFolha;
            await this.loginFolha(loginFolha, ano);
            login = loginFolha;
            if (!loginFolha.ppa) {
              this.error.error.message =
                'Não foi encontrado um plano plurianual para o ano corrente, entre em contato com o Administrador do sistema!';
              GlobalService.limparSessaoLogin(true);
              return this.error;
            }
            break;
          case 'patrimonio':
            if (!usuario.setor) {
              this.error.error.message =
                'Usuário não vinculado a nenhum Setor, entre em contato com o Administrador do sistema!';
              return this.error;
            }
            GlobalService.salvarSessaoLogin(login);
            this.parametroPatrimonioServico.login = login;
            login.parametro = login.parametro ? login.parametro : {}
            login.parametro['patrimonio'] = await this.parametroPatrimonioServico.obterParametro(login.orgao.id).toPromise();
          case 'licitacao':
            if (!usuario.setor && !usuario.administrador) {
              this.error.error.message =
                'Usuário não vinculado a nenhum Setor, entre em contato com o Administrador do sistema!';
              return this.error;
            }
            GlobalService.salvarSessaoLogin(login);
            this.parametroLicitacaoServico.login = login;
            login.parametro = login.parametro ? login.parametro : {}
            login.parametro['licitacao'] = await this.parametroLicitacaoServico.obterParametro(login.orgao.id).toPromise();
          case 'requisicao':
            if (!usuario.setor && !usuario.administrador) {
              this.error.error.message =
                'Usuário não vinculado a nenhum Setor, entre em contato com o Administrador do sistema!';
              return this.error;
            }
            GlobalService.salvarSessaoLogin(login);
            this.parametroRequisicaoServico.login = login;
            this.parametroComprasServico.login = login;
            this.parametroLicitacaoServico.login = login;
            login.parametro = login.parametro ? login.parametro : {}
            login.parametro['requisicao'] = await this.parametroRequisicaoServico.obterParametro(login.orgao.id).toPromise();
            login.parametro['compras'] = await this.parametroComprasServico.obterParametro(login.orgao.id).toPromise();
            login.parametro['licitacao'] = await this.parametroLicitacaoServico.obterParametro(login.orgao.id).toPromise();
            this.carregarEstoqueUsuario(login);
            this.carregarSetorUsuario(login);
          case 'compras':
            if (!usuario.setor && !usuario.administrador) {
              this.error.error.message =
                'Usuário não vinculado a nenhum Setor, entre em contato com o Administrador do sistema!';
              return this.error;
            }
            GlobalService.salvarSessaoLogin(login);
            this.parametroRequisicaoServico.login = login;
            this.parametroComprasServico.login = login;
            login.parametro = login.parametro ? login.parametro : {}
            login.parametro['requisicao'] = await this.parametroRequisicaoServico.obterParametro(login.orgao.id).toPromise();
            login.parametro['compras'] = await this.parametroComprasServico.obterParametro(login.orgao.id).toPromise();
            this.carregarEstoqueUsuario(login);
          default:
            const loginContabil = login as LoginContabil;
            await this.loginContabilidade(loginContabil, ano);

            if (!loginContabil.ppa) {
              this.error.error.message =
                'Não foi encontrado um plano plurianual para o ano corrente, entre em contato com o Administrador do sistema!';
              GlobalService.limparSessaoLogin(true);
              return this.error;
            }
            login = loginContabil;
            this.parametroAlmoxarifadoService.login = login;
            this.parametroRequisicaoServico.login = login;
            this.parametroComprasServico.login = login;
            this.parametroLicitacaoServico.login = login;
            login.parametro = login.parametro ? login.parametro : {}
            login.parametro['requisicao'] = await this.parametroRequisicaoServico.obterParametro(login.orgao.id).toPromise();
            login.parametro['compras'] = await this.parametroComprasServico.obterParametro(login.orgao.id).toPromise();
            login.parametro['licitacao'] = await this.parametroLicitacaoServico.obterParametro(login.orgao.id).toPromise();
            login.parametro['contabil'] = await this.parametroServico.obter({ 'orgao.id': this.usuario.orgao.id }, loginContabil.cidade.id).toPromise();
            await this.carregarEstoqueUsuario(login);

        }
        GlobalService.salvarSessaoLogin(login);
        this.usuarioServico.login = login;
        this.exercicioServico.login = login;
        this.parametroServico.login = login;
        this.ppaServico.login = login;
        this.orgaoServico.login = login;
        this.progressoServico.login = login;
        this.usuarioAutenticado = true;
        if (this.error.error.message !== '') {
          GlobalService.limparSessaoLogin(true);
          return this.error;
        } else {
          this.mostrarMenuEmitter.emit(true);
          this.sistemaEmitter.emit(usuario.sistema);
          this.funcaoServico.navegarPara(usuario.sistema, this.router, login);
        }
      } else {
        this.usuarioAutenticado = false;
        this.mostrarMenuEmitter.emit(false);
        const login = new Login();
        this.sistemaEmitter.emit(await this.getSistema(login, sistema));
        return '';
      }
    } catch (e) {
      return e;
    }
  }

  public async autenticarTransparencia(email: string, senha: string, sistema: Sistemas, orgao?: number) {
    try {
      this.error = {
        error: {
          message: ''
        }
      };
      const dados = await this.usuarioServico.autenticar(email, senha, sistema, orgao).toPromise();
      if (dados.entidade) {
        this.usuario = dados.entidade;
        let login = new LoginPublico();

        const usuario = new Usuario();
        usuario.id = this.usuario.id;
        usuario.nome = this.usuario.nome;
        usuario.convidado = this.usuario.convidado;
        usuario.sobrenome = this.usuario.sobrenome;
        usuario.cpf = this.usuario.cpf;
        usuario.telefone = this.usuario.telefone;
        usuario.email = this.usuario.email;
        usuario.administrador = this.usuario.administrador;
        usuario.sistemas_administrador = this.usuario.sistemas_administrador;
        usuario.sistema = 'transparencia';
        usuario.setor = this.usuario.setor;
        usuario.unidade = this.usuario.unidade;
        login.usuario = usuario;

        const orgao = new Orgao();
        orgao.id = this.usuario.orgao.id;
        orgao.codigo = this.usuario.orgao.codigo;
        orgao.nome = this.usuario.orgao.nome;
        orgao.cnpj = this.usuario.orgao.cnpj;
        orgao.endereco = this.usuario.orgao.endereco;
        orgao.cep = this.usuario.orgao.cep;
        orgao.bairro = this.usuario.orgao.bairro;
        orgao.especie = this.usuario.orgao.especie;
        orgao.telefone = this.usuario.orgao.telefone;
        orgao.site = this.usuario.orgao.site;
        login.orgao = orgao;

        login.brasao = this.usuario.orgao.brasao ? this.usuario.orgao.brasao.brasao : '';
        login.bandeira = this.usuario.orgao.brasao ? this.usuario.orgao.brasao.bandeira : '';
        login.cidade = this.usuario.orgao.cidade;
        login.token = 'JWT ' + dados.token;
        login.acessos = this.usuario.acessos;
        login.limite = 20;
        login.versao = environment.versao;
        GlobalService.salvarSessaoLogin(login);

        const ano = new Date().getFullYear();
        const ex = await this.exercicioServico.obterPorAno(ano, login.cidade.id).toPromise();
        if (!ex.content[0]) {
          this.error.error.message = 'O exercício atual não foi cadastrado, por favor entre em contato com o administrador do sistema!';
          GlobalService.limparSessaoLogin(true);
          return this.error;
        }
        login.exercicio = ex.content[0];
        const loginPublico = login as LoginPublico;
        await this.loginContabilidade(loginPublico, ano);
        login = loginPublico;
        if (!loginPublico.ppa) {
          this.error.error.message =
            'Não foi encontrado um plano plurianual para o ano corrente, entre em contato com o Administrador do sistema!';
          GlobalService.limparSessaoLogin(true);
          return this.error;
        }

        GlobalService.salvarSessaoLogin(login);
        this.usuarioServico.login = login;
        this.exercicioServico.login = login;
        this.parametroServico.login = login;
        this.ppaServico.login = login;
        this.orgaoServico.login = login;
        this.progressoServico.login = login;
        this.usuarioAutenticado = true;
        if (this.error.error.message !== '') {
          GlobalService.limparSessaoLogin(true);
          return this.error;
        } else {
          this.mostrarMenuEmitter.emit(true);
          this.sistemaEmitter.emit(usuario.sistema);
          let valid: boolean = false;
          for (const acesso of login.acessos) {
            if (acesso.pagina === '/admin/transparencia/parametros-transparencia')
              valid = true;
          }
          if (valid || login.usuario.administrador)
            this.router.navigate(['/admin/transparencia/parametros-transparencia']);
          else
            this.router.navigate(['/transparencia/portal']);
        }
      }
    } catch (e) {
      return e;
    }
  }

  async getSistema(login: Login, sistemaLogin: string) {
    switch (sistemaLogin) {
      case 'controle-interno':
      case 'contabilidade':
      case 'compras':
      case 'terceiro-setor':
      case 'diario-oficial':
      case 'indicador-gestao':
      case 'legislativo':
        if (login instanceof LoginContabil) {
          return sistemaLogin as Sistemas;
        } else {
          return null;
        }
      default:
        return sistemaLogin as Sistemas;

    }
  }

  async loginContabilidade(loginContabil: LoginContabil, ano: number) {
    loginContabil.mesReferencia = (new Date()).getMonth();
    const ppa = await this.ppaServico.obterPorAno(ano, loginContabil.cidade.id).toPromise();
    if (!ppa) {
      this.usuarioAutenticado = false;
      this.mostrarMenuEmitter.emit(false);
      return null;
    } else {
      loginContabil.ppa = ppa;
      const parametro = await this.parametroServico.obter({
        'orgao.id': this.usuario.orgao.id
      }, loginContabil.cidade.id).toPromise();
      loginContabil.redirecionar_liquidar_pagar = parametro ? parametro.redirecionar_liquidar_pagar : false;
      loginContabil.dias_bloquear_alteracoes = parametro ? parametro.dias_bloquear_alteracoes : 1;
      loginContabil.repetir_dados_empenho = parametro ? parametro.repetir_dados_empenho : false;
      loginContabil.verificar_liquidacao_estoque = parametro ? parametro.verificar_liquidacao_estoque : false;
      loginContabil.fonte_recurso_pagamento = parametro ? parametro.fonte_recurso_pagamento : false;
      loginContabil.informar_data_liquidacao_estoque = parametro ? parametro.informar_data_liquidacao_estoque : false;
      if (loginContabil) {
        this.audespServico.login = loginContabil;
        const ultimoAudesp = await this.audespServico.obter({
          armazenado: true, descricao: 'BALANCETE-ISOLADO-CONTA-CORRENTE', 'orgao.id': loginContabil.orgao.id,
          'orderBy': 'ano$desc,mes$DESC', fields: 'mes,ano', onlyFields: true
        }).toPromise();
        loginContabil.ultimoAudesp = (ultimoAudesp?.ano ?? '1900') + '-' + (ultimoAudesp?.mes ?? '1');
      }
      return loginContabil;
    }
  }

  async loginPublico(loginPublico: LoginPublico, ano: number) {
    const ppa = await this.ppaServico.obterPorAno(ano, loginPublico.cidade.id).toPromise();
    if (!ppa) {
      this.usuarioAutenticado = false;
      this.mostrarMenuEmitter.emit(false);
      return null;
    } else {
      loginPublico.ppa = ppa;
      return loginPublico;
    }
  }

  async loginFolha(loginFolha: LoginFolha, ano: number) {
    const ppa = await this.ppaServico.obterPorAno(ano, loginFolha.cidade.id).toPromise();
    if (!ppa) {
      this.usuarioAutenticado = false;
      this.mostrarMenuEmitter.emit(false);
      return null;
    } else {
      loginFolha.ppa = ppa;
      return loginFolha;
    }
  }

  async loginEntidade(loginEntidade: LoginEntidade, cnpj: string) {
    loginEntidade.favorecido = this.usuario.favorecido;

    if (!loginEntidade.favorecido) {
      this.usuarioAutenticado = false;
      this.mostrarMenuEmitter.emit(false);
      this.error.error.message = 'Para acessar o sistema você tem que se cadastrar se vinculando a uma entidade.';
      GlobalService.limparSessaoLogin(true);
      return this.error;
    }

    if (loginEntidade.favorecido && loginEntidade.favorecido.cpf_cnpj !== cnpj) {
      this.usuarioAutenticado = false;
      this.mostrarMenuEmitter.emit(false);
      this.error.error.message = `Você não está cadastrado na entidade ${cnpj}, tente novamente! `;
      GlobalService.limparSessaoLogin(true);
      return this.error;
    }

    return null;

  }

  async recuperarSenha(email: string, orgao: Orgao) {
    try {
      await this.usuarioServico.recuperarSenha(email, orgao).toPromise();
    } catch (e) {
      return e;
    }
  }

  usuarioEstaAutenticado() {
    if (!this.usuarioAutenticado) {
      const log = GlobalService.obterSessaoLogin();
      if (log) {
        this.usuarioAutenticado = true;
        this.mostrarMenuEmitter.emit(true);
        this.sistemaEmitter.emit(log.usuario.sistema);
      }
    }
    return this.usuarioAutenticado ? GlobalService.obterSessaoLogin() : null;
  }

  async usuarioEstaAutenticadoFake(): Promise<boolean> {
    // recupera login pela sessao
    let login: LoginPublico = GlobalService.obterSessaoLogin();

    // se nao possui login gera um login publico
    if (!login) {
      login = new LoginPublico();

      const dados = await this.usuarioServico.gerarTokenPublico().toPromise();
      login.token = 'JWT ' + dados.token;
      login.acessos = [];

      login.versao = environment.versao;
      login.limite = 25;
      login.contraste = false;

      GlobalService.salvarSessaoLogin(login);

      const orgao: Orgao = await (await this.orgaoServico.obterPorCodigo(environment.orgao, environment.cidade).toPromise()).content[0];
      const ano = new Date().getFullYear();
      const ex = await this.exercicioServico.obterPorAno(ano, environment.cidade).toPromise();
      const ppa = await this.ppaServico.obterPorAno(ano, environment.cidade).toPromise();

      login.orgao = orgao;
      login.brasao = orgao.brasao ? orgao.brasao.brasao : '';
      login.bandeira = orgao.brasao ? orgao.brasao.bandeira : '';
      login.cidade = orgao.cidade;
      login.exercicio = ex.content[0];
      login.ppa = ppa;
    }

    // se perdeu o token ou não possui gera um novo token publico
    if (!login.token) {
      const dados = await this.usuarioServico.gerarTokenPublico().toPromise();
      login.token = 'JWT ' + dados.token;
      login.acessos = [];
    }

    // armazena login na sessao
    login.usuario.sistema = 'transparencia';
    GlobalService.salvarSessaoLogin(login);

    // configura sistema para TRANSPARENCIA
    this.mostrarMenuEmitter.emit(true);
    this.sistemaEmitter.emit(login.usuario.sistema);
    this.usuarioAutenticado = false;
    return true;
  }

  desconectar(usuario: Usuario) {
    const login: Login = GlobalService.obterSessaoLogin();
    this.usuarioAcessoServico.salvarLogoff(usuario, login.sistema).pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.usuarioAutenticado = false;
        this.mostrarMenuEmitter.emit(false);
        this.sistemaEmitter.emit('transparencia');
        GlobalService.limparSessaoLogin(true);
        if (login.sistema === 'e-sic') {
          this.router.navigate(['/e-sic/login']);
        } else {
          if (login && login.usuario.sistema === 'protocolo-cidadao') {
            this.router.navigate(['/protocolo/login-cidadao']);
          } else {
            this.router.navigate(['/login']);
          }
        }
      });
  }

  desconectarRegistrar(usuario: Usuario) {

    const login: Login = GlobalService.obterSessaoLogin();
    this.usuarioAcessoServico.salvarLogoff(usuario, login.sistema).pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.usuarioAutenticado = false;
        this.mostrarMenuEmitter.emit(false);
        this.sistemaEmitter.emit('transparencia');
        GlobalService.limparSessaoLogin(true);
        this.router.navigate(['/registro']);

      });
  }

  private async carregarEstoqueUsuario(login: Login) {
    let data = await this.estoqueService.estoquesUsuarios(login.usuario.id, login.orgao.id).toPromise();
    if (!data || !data.content || data.content.length === 0)
      return;

    for (const estoque of data.content) {
      delete estoque.usuarios
      delete estoque.usuarios_estoque
    }
    login.estoque = data.content[0];
    if (login.estoque) {
      GlobalService.salvarSessaoLogin(login);
      this.parametroAlmoxarifadoService.login = login;
      login.parametro = login.parametro ? login.parametro : {}
      login.parametro['almoxarifado'] = await this.parametroAlmoxarifadoService.obterParametro(login.estoque.id, login.exercicio.id).toPromise();
    }
  }

  private async carregarSetorUsuario(login: Login) {
    let setores = await this.setorServico.setorUsuarios(login.usuario.id, login.orgao.id).toPromise();
    login.setor = setores.content
  }

}
