import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-declaracao-convenio-federal',
  templateUrl: './declaracao-convenio-federal.component.html'
})
export class DeclaracaoConvenioFederalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
