// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Cidade } from '../geo/cidade.model';
import { ProdutoUnidade } from './produto-unidade.model';

export class UnidadeFornecimento extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public ativo?: boolean,
    public bec?: boolean,
    public cidade?: Cidade,
    public orgao?: Orgao,
    public produtos_unidade?: ProdutoUnidade
  ) {
    super();
  }
  static converteJson(json: any): UnidadeFornecimento {
    return Object.assign(new UnidadeFornecimento(), json);
  }
}
