import { Component, Injector, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { CompraItemService } from 'compra-lib';
import { MessageService } from 'primeng/api';
import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { CompraItem } from 'eddydata-lib';

@Component({
  selector: 'app-empenho-item',
  templateUrl: './empenho-item.component.html'
})
export class EmpenhoItemComponent implements OnInit, OnDestroy {


  public mensagemSucesso: string;
  private unsubscribe: Subject<void> = new Subject();
  public listaItens: any;

  @Input() id: number;

  public imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    protected injector: Injector,
    protected messageService: MessageService,
    protected itemService: CompraItemService) {
  }

  ngOnInit(): void {
    this.loadResource();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private loadResource() {

    this.itemService.obterPorCompra(this.id).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            this.listaItens = data ? data.content : new Array<CompraItem>();
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
  }


}
