import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, FavorecidoExterior } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class FavorecidoExteriorService extends BaseResourceService<FavorecidoExterior> {

  constructor(
    protected injector: Injector
  ) {
    super(`favorecido-exterior`, injector);
  }

}
