import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { DespesaService } from "administrativo-lib";
import { SubGrupoEstoqueService } from "almoxarifado-lib";
import { BaseResourceRptComponent, Coluna, EddyAutoComplete, FavorecidoService, GlobalService, LoginContabil, PessoaService, UnidadeService } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";
import { CompraService } from "../../compra/service/compra.service";

@Component({
  selector: 'lib-compra-rpt',
  templateUrl: './compra-rpt.component.html'
})
export class CompraRptComponent extends BaseResourceRptComponent {

  private loginContail: LoginContabil;

  public ordernacao: { nome: string, coluna: string, ordem: 'DESC' | 'ASC' };
  public listaOrdenacao: { nome: string, coluna: string, ordem: 'DESC' | 'ASC' }[] = [];

  public tipo: 'S' | 'A' = 'S';
  public parametros: { session?: any, filtros?: any } = {};

  protected datepipe: DatePipe;

  constructor(
    private favorecidoService: FavorecidoService,
    private despesaService: DespesaService,
    private pessoaService: PessoaService,
    private unidadeService: UnidadeService,
    private compraService: CompraService,
    private subGrupoService: SubGrupoEstoqueService
  ) {
    super();
  }

  protected afterInit(): void {
    this.datepipe = new DatePipe('pt');
    this.orientacao = "landscape";
    this.listaOrdenacao = this.obterColunasOrdenacoes();
    this.ordernacao = this.listaOrdenacao[0];
    this.loginContail = GlobalService.obterSessaoLogin();
  }

  protected tituloRelatorio(): string {
    return `Ordens de Fornecimento`;
  }

  public obterColunasOrdenacoes(): { nome: string, coluna: string, ordem: 'DESC' | 'ASC' }[] {
    return [
      { coluna: 'numero', nome: 'Número', ordem: 'ASC' },
      { coluna: 'data_compra', nome: 'Data Compra', ordem: 'ASC' },
      { coluna: 'favorecido.nome', nome: 'Fornecedor', ordem: 'ASC' },
      { coluna: 'requerente.nome', nome: 'Requerente', ordem: 'ASC' },
    ];
  }

  protected obterColunasRelatorio(filtro?: boolean): Coluna[] {
    let retorno: Coluna[] = [];
    retorno.push({ titulo: 'Número', coluna: 'numero', alignment: 'center' });
    retorno.push({ titulo: 'Data Compra', coluna: 'data_compra', alignment: 'center' });
    retorno.push({ titulo: 'Nº Requisição', coluna: 'rcms.numero', alignment: 'center' });
    retorno.push({ titulo: 'Fornecedor', coluna: 'favorecido.nome', tipo: 'String' });
    retorno.push({ titulo: 'Ficha', coluna: 'ficha.numero', alignment: 'center' });
    retorno.push({ titulo: 'Despesa', coluna: 'subelemento.codigo', alignment: 'center' });
    retorno.push({ titulo: 'Requerente', coluna: 'requerente.nome', tipo: 'String' });
    retorno.push({ titulo: 'Contrato', coluna: 'contrato.numero', alignment: 'center' });
    retorno.push({ titulo: 'Licitação', coluna: 'licitacao.numero', alignment: 'center' });
    retorno.push({ titulo: 'Processo', coluna: 'processo', alignment: 'center' });
    retorno.push({ titulo: 'Vl. Total', coluna: 'total_compra', alignment: 'right', decimais: 2 });
    if (this.tipo === 'A') {
      retorno.push({
        titulo: null, coluna: 'itens', bold: true, fontSize: 14, larguras: [40, '*', 'auto', 50, 50, '*'],
        colunas: this.obterColunasAnalitico(), layout: this.layoutItens(),
        adicionaisBody: { border: [false, false, false, false], margin: [-3, -2, -3, 0] }
      });
    } else
      retorno = retorno.map((r) => Object.assign(r, { adicionaisBody: { border: [false, false, false, false] } }));
    retorno = retorno.map((r) => Object.assign(r, { adicionaisHeader: { margin: [0, 3, 0, 3] } }));

    return retorno;
  }

  private obterColunasAnalitico(): Coluna[] {
    let retorno: Coluna[] = [];
    retorno.push({ titulo: 'Cód.', coluna: 'produto_unidade.produto.codigo', alignment: 'center', });
    retorno.push({ titulo: 'Descrição', coluna: 'produto_unidade.produto.nome', alignment: 'left', });
    retorno.push({ titulo: 'Unidade', coluna: 'produto_unidade.unidade.nome', alignment: 'center', });
    retorno.push({ titulo: 'Qtd.', coluna: 'quantidade', alignment: 'right', decimais: 2, });
    retorno.push({ titulo: 'Vl. Unitário', coluna: 'valor_unitario', alignment: 'right', decimais: 2, });
    retorno.push({
      titulo: 'Vl. Total', coluna: 'valor_total', alignment: 'right', decimais: 2, funcao: [
        { valor: ['valor_unitario', '*', 'quantidade'] }
      ]
    });
    retorno = retorno.map((r) => Object.assign(r, { adicionaisBody: { border: [false, false, false, false] }, adicionaisHeader: { border: [true, false, true, true] } }));
    return retorno;
  }

  public obterColunasfiltroPersonalizado(): Coluna[] {
    let retorno: Coluna[] = [];
    retorno.push({ titulo: 'Número', coluna: 'numero', alignment: 'center', tipo: 'Number' });
    retorno.push({ titulo: 'Data Compra', coluna: 'data_compra', tipo: 'Date', alignment: 'center', cols: 12 });
    retorno.push({ titulo: 'Nº Requisição', coluna: 'rcms.numero', alignment: 'center', tipo: 'String', cols: 6 });
    retorno.push({
      titulo: 'Fornecedor', coluna: 'favorecido.id', tipo: 'AutoComplete', autocomplete: new EddyAutoComplete(null, this.favorecidoService,
        'id', ['cpf_cnpj', 'nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }), cols: 12
    });
    retorno.push({ titulo: 'Ficha', coluna: 'ficha.numero', alignment: 'center', tipo: 'String', cols: 6 });
    retorno.push({
      titulo: 'Despesa', coluna: 'ficha.despesa.codigo', alignment: 'center', tipo: 'AutoComplete', autocomplete: new EddyAutoComplete(null, this.despesaService,
        'codigo', ['codigo', 'nome'], { 'exercicio.id': this.login.exercicio.id, orderBy: 'codigo', nivel: 4 }, { number: ['codigo'], text: ['nome'] }
      ), cols: 12,
    });
    retorno.push({
      titulo: 'Subelemento', coluna: 'subelemento.codigo', alignment: 'center', tipo: 'AutoComplete', autocomplete: new EddyAutoComplete(null, this.despesaService,
        'codigo', ['codigo', 'nome'], { 'exercicio.id': this.login.exercicio.id, orderBy: 'codigo', nivel: 6 }, { number: ['codigo'], text: ['nome'] }
      ), cols: 12,
    });
    retorno.push({
      titulo: 'Requerente', coluna: 'requerente.id', tipo: 'AutoComplete', autocomplete: new EddyAutoComplete(null, this.pessoaService,
        'id', ['nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
      ), cols: 12
    });
    retorno.push({ titulo: 'Contrato', coluna: 'contrato.numero', alignment: 'center', tipo: 'String', cols: 6, mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }] });
    retorno.push({ titulo: 'Licitação', coluna: 'licitacao.numero', alignment: 'center', tipo: 'String', cols: 6, mask: '00000/0000' });
    retorno.push({ titulo: 'Processo', coluna: 'processo', alignment: 'center', tipo: 'String', cols: 6, mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }], notUnmask: true });
    retorno.push({ titulo: 'Vl. Total', coluna: 'total_compra', alignment: 'right', tipo: 'Number', decimais: 2 });
    retorno.push({
      titulo: 'Secretaria', coluna: 'setor.unidade.id', tipo: 'AutoComplete', autocomplete: new EddyAutoComplete(null, this.unidadeService,
        'id', ['id', 'nome'], { orderBy: 'nome', 'ppa.id': this.loginContail.ppa.id }, { number: ['id'], text: ['nome'] }), cols: 12
    });
    retorno.push({ titulo: 'Descrição Item', coluna: 'itens.produto_unidade.produto.descricao', tipo: 'String', cols: 6 });
    retorno.push({ titulo: 'Código Item', coluna: 'itens.produto_unidade.produto.codigo', tipo: 'String' });
    retorno.push({
      titulo: 'Subgrupo', coluna: 'itens.produto_unidade.produto.material.sub_grupo.id', tipo: 'AutoComplete',
      autocomplete: new EddyAutoComplete(null, this.subGrupoService,
        'id', ['codigo', 'nome'], { 'orgao_id': this.login.orgao.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }), cols: 12
    });
    retorno.push({ titulo: 'Compra Direta', coluna: 'contrato.id$null,licitacao.id$null', tipo: 'Boolean' });
    retorno.push({ titulo: 'Anuladas', coluna: 'valid_total_anulado', tipo: 'Boolean' });
    return retorno;
  }

  protected larguraColunas(): (string | number)[] {
    return [40, 60, 60, 'auto', 'auto', 'auto', '*', 50, 50, 50, '*'];
  }

  protected totalizarColunas(): (string | {})[] {
    return ['total_compra'];
  }

  protected carregarLista(): Promise<any[]> {
    let parametros = {
      'orderBy': `${this.ordernacao.coluna}\$${this.ordernacao.ordem}`,
      'orgao.id': this.login.orgao.id, 'exercicio.id': this.login.exercicio.id, 'excluido': false,
      relations: ['favorecido', 'ficha', 'ficha.despesa', 'requerente', 'contrato', 'licitacao', 'rcms',
        'itens', 'itens.produto_unidade', 'itens.produto_unidade.produto', 'itens.produto_unidade.unidade',
        'itens.produto_unidade.produto.material.sub_grupo', 'subelemento'
      ]
    };

    if (this.parametros?.filtros)
      parametros = Object.assign(parametros, this.parametros.filtros);
    if (!parametros['valid_total_anulado'])
      parametros['valid_total_anulado'] = false;

    return new Promise((resolve) => {
      this.compraService.extendido(1, -1, parametros).pipe(takeUntil(this.unsubscribe))
        .subscribe(async (res) => {
          console.log(res.content);
          let lista = res.content.map((c) => {
            if (c.contrato)
              c.contrato.numero = this.funcaoService.mascarar('0000/0000', c.contrato.numero);
            if (c.licitacao)
              c.licitacao.numero = this.formataNumeroDaLicitacao(c.licitacao.numero)
            let total_compra = 0;
            if (c.itens && c.itens.length > 0)
              for (let item of c.itens)
                total_compra += +item.valor_unitario * +item.quantidade;
            c['total_compra'] = total_compra;
            if (!c.itens || c.itens.length === 0 && this.tipo === 'A') {
              c.itens = [{
                produto_unidade: {
                  produto: { nome: 'Não possui itens.' }
                }
              }];
            }
            return c;
          });
          resolve(lista);
        });
    });
  }

  protected layout(): {} {
    return {
      hLineWidth() {
        return 1;
      },
      vLineWidth(i, node) {
        if (this.tipo === 'A')
          return (i === 0 || i === node.table.widths.length) ? 1 : 0;
        else
          return 1;
      },
      hLineColor(i) {
        return i === 1 || i === 0 ? 'black' : '0aaa';
      },
      paddingLeft(i) {
        return 2;
      },
      paddingRight(i, node) {
        return 2;
      }
    };
  }

  private layoutItens() {
    return {
      hLineWidth() {
        return 1;
      },
      vLineWidth(i, node) {
        return 1;
      },
      hLineColor(i) {
        return i === 1 || i === 0 ? 'black' : '0aaa';
      },
      paddingLeft(i) {
        return 2;
      },
      paddingRight(i, node) {
        return 2;
      }
    };
  }


  public filtroTipo(filtro: Coluna[], tipo: 'String' | 'Number' | 'Date' | 'Boolean' | 'HTML') {
    return filtro.filter((f) => f.tipo === tipo);
  }

  public ngClassCols(cols: number, padrao: string): {} {
    let ngClass = {};
    if (cols)
      ngClass[`col-sm-${cols}`] = true;
    else
      ngClass[padrao] = true;
    return ngClass;
  }

  private formataNumeroDaLicitacao(numeroDaLicitacao: string): string {
    let mascara = numeroDaLicitacao.substring(4).length === 4
      ? '0000/0000'
      : '00000/0000'

    return this.funcaoService.mascarar(mascara, numeroDaLicitacao);
  }
}
