import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { MetaGovernoShwComponent } from './meta-governo-shw/meta-governo-shw.component';
import { MetaGovernoLstComponent } from './meta-governo-lst/meta-governo-lst.component';

const routes: Routes = [
  { path: '', component: MetaGovernoLstComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: MetaGovernoShwComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MetaGovernoRoutingModule { }
