// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { GrupoAlmoxarifado } from './grupo-almoxarifado.model';
import { Usuario } from '../comum/usuario.model';
import { SetorAlmoxarifado } from './setor-almoxarifado.model';
import { Pessoa } from '../comum/pessoa.model';
import { UsuarioEstoque } from './usuario-estoque.model';

export class Estoque extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public telefone?: string,
    public local_entrega?: string,
    public email?: string,
    public aux?: number,
    public orgao?: Orgao,
    public grupos?: GrupoAlmoxarifado[],
    public setores?: SetorAlmoxarifado[],
    public usuarios?: Usuario[],
    public usuarios_estoque?: UsuarioEstoque[],
    public ativo?: boolean,
    public responsavel?: Pessoa
  ) {
    super();
  }
  static converteJson(json: any): Estoque {
    return Object.assign(new Estoque(), json);
  }
}
