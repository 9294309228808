import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InplaceModule } from 'primeng/inplace';
import { ToastModule } from 'primeng/toast';
import { IndicadorModule } from '../indicador/indicador.module';
import { ProgramaModule } from '../programa/programa.module';
import { IndicadorAvaliacaoFormComponent } from './indicador-avaliacao-form/indicador-avaliacao-form.component';
import { MetaAvaliacaoFormComponent } from './meta-avaliacao-form/meta-avaliacao-form.component';
import { MetaGovernoFormComponent } from './meta-governo-form/meta-governo-form.component';
import { MetaGovernoListComponent } from './meta-governo-list/meta-governo-list.component';
import { MetaGovernoOdsComponent } from './meta-governo-ods/meta-governo-ods.component';
import { MetaGovernoRoutingModule } from './meta-governo-routing.module';
import { MetaGovernoViewComponent } from './meta-governo-view/meta-governo-view.component';

@NgModule({
  declarations: [
    MetaGovernoListComponent,
    MetaGovernoViewComponent,
    MetaGovernoFormComponent,
    MetaAvaliacaoFormComponent,
    IndicadorAvaliacaoFormComponent,
    MetaGovernoOdsComponent
  ],
  imports: [
    CommonModule,
    MetaGovernoRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    ConfirmDialogModule,
    InplaceModule,
    IndicadorModule,
    ProgramaModule,
  ],
  providers: [MessageService, ConfirmationService]
})
export class MetaGovernoModule { }
