import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { IMaskModule } from 'angular-imask';
import { MessageService } from 'primeng/api';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { SharedModule } from '../util/shared.module';
import { AuditoriaRoutingModule } from './auditoria-routing.module';
import { InputMaskModule } from 'primeng/inputmask';
import { AuditoriaLstComponent } from './auditoria-lst/auditoria-lst.component';
import { CalendarModule } from 'primeng/calendar';
import { AuditoriaViewComponent } from './auditoria-view/auditoria-view.component';
import {TreeModule} from 'primeng/tree';

@NgModule({
  declarations: [AuditoriaLstComponent, AuditoriaViewComponent],
  imports: [
    CommonModule,
    AuditoriaRoutingModule,
    CalendarModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    ImageCropperModule,
    IMaskModule,
    InputMaskModule,
    ToastModule,
    TreeModule
  ],
  exports: [AuditoriaLstComponent, AuditoriaViewComponent],
  providers: [MessageService]
})
export class AuditoriaModule { }
