import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoginContabil, ReservaDotacaoItem } from 'eddydata-lib';

declare var $: any;

@Component({
  selector: 'lib-rcms-ficha-reserva-dotacao',
  templateUrl: './rcms-ficha-reserva-dotacao-dlg.component.html'
})
export class RcmsFichaReservaDotacaoDlg implements OnInit {

  @Input() public login: LoginContabil;

  @Input() visualizar: boolean = false;

  @Output() callBackVisualizar: EventEmitter<ReservaDotacaoItem> = new EventEmitter();

  @Input() static : boolean = false;

  @Input() reservaDotacaoItens: ReservaDotacaoItem[];

  ngOnInit(): void { }

  public onHide() {
    this.hide();
  }

  public show() {
    this.visualizar = true;
  }

  public hide() {
    this.visualizar = false;
    $('#dlgFichaReservaDotacao').modal('hide');
  }

  public selecionarEvent(reserva: ReservaDotacaoItem) {
    this.callBackVisualizar.emit(reserva);
    this.hide();
  }
}
