import { Component, Input } from '@angular/core';
import { Audesp4TipoObraServicoEngOpcoes } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-lote-eng-tipo',
  templateUrl: './licitacao-audesp-lote-eng-tipo.component.html'
})
export class LicitacaoAudespLoteEngTipoComponent extends BaseLicitacaoAudesp {

  @Input() public tipo: Audesp4TipoObraServicoEngOpcoes;

  constructor(
  ) {
    super()
  }

}
