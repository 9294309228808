import { BaseResourceRptComponent, Coluna, FuncaoService, PlanoContratacaoItem } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";
import { PlanoContratacaoAnualItensService } from "../../plano-contratacao-anual/service/plano-contratacao-anual-itens.service";

export class PlanoContratacaoPlanejamentoRelatorioSubelemento extends BaseResourceRptComponent {

  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  constructor(
    protected planoItemService: PlanoContratacaoAnualItensService,
    protected funcaoService: FuncaoService
  ) {
    super()
  }


  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================

  protected afterInit(): void {
    this.login
  }

  protected tituloRelatorio(): string {
    return this.login.orgao.nome
  }

  protected subTituloRelatorio(): string {
    return 'PLANO DE CONTRATACAO ANUAL - PLANEJAMENTO / POR SUBELEMENTO'
  }

  protected obterColunasRelatorio(): Coluna[] {
    return [
      { titulo: 'Subelemento', coluna: 'subelemento' },
      { titulo: 'Valor', coluna: 'valor', decimais: 2, alignment: 'right', }
    ]

  }

  protected larguraColunas(): (string | number)[] {
    return ['*', 'auto']

  }

  protected totalizarColunas(): (string | {})[] {
    return ['valor']

  }

  public carregarLista(): Promise<any[]> {
    const parametros = {
      'plano.orgao.id': this.login.orgao.id,
      'plano.exercicio.id': this.login.exercicio.id,
      'relations': 'plano,plano.orgao,plano.exercicio'
    }

    return new Promise((resolve) => {
      this.planoItemService.filtrar(1, -1, parametros).pipe(takeUntil(this.unsubscribe)).subscribe(lista => {
        this.formato = 'pdf'
        resolve(this.agruparSubelementoTotalizadores(lista.content))
      })
    })
  }


  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  public agruparSubelementoTotalizadores(lista: PlanoContratacaoItem[]) {
    let novaLista = []

    lista.forEach(item => {
      item['valor_total'] = item.quantidade * item.valor_unitario
    })

    lista.sort((a, b) => a.subelemento > b.subelemento ? 1 : -1)

    this.funcaoService.agrupar(lista, 'subelemento', ['valor_total']).map(g =>
      ({ subelemento: g.grupo, valor: g.totalizadores['valor_total'] })
    ).forEach(sub => {

      novaLista.push({
        subelemento: sub.subelemento,
        valor: sub.valor
      })
    })

    return novaLista
  }

}