// tslint:disable: variable-name
import { TipoContato } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Favorecido } from './favorecido.model';

export class FavorecidoContato extends BaseResourceModel {
    constructor(
        public id?: number,
        public contato?: string,
        public tipo?: TipoContato,
        public principal?: boolean,
        public favorecido?: Favorecido,
        public orgao?: Orgao
    ) {
        super();
    }
    static converteJson(json: any): FavorecidoContato {
        return Object.assign(new FavorecidoContato(), json);
    }
}
