import { Orgao } from 'eddydata-lib';

// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Usuario } from '../comum/usuario.model';
import { OrdemPagamentoItem } from '../tesouraria/ordem-pagamento-item.model';
import { PagamentoResto } from '../tesouraria/pagamento-resto.model';
import { DocumentoFiscalInfo } from './documento-fiscal-info.model';
import { EmpenhoResto } from './empenho-resto.model';
import { PreLiquidacaoResto } from './pre-liquidacao-resto.model';
import { RetencaoResto } from './retencao-resto.model';

export class LiquidacaoResto extends BaseResourceModel {
    constructor(
        public id?: number,
        public data_liquidacao?: Date,
        public mes?: number,
        public data_vencimento?: Date,
        public parcela?: number,
        public documento?: string,
        public historico?: string,
        public aux?: number,
        public valor_liquidado?: number,
        public valor_original?: number,
        public valor_base_ir?: number,
        public valor_base_csll?: number,
        public valor_base_cofins?: number,
        public valor_base_pis?: number,
        public impresso?: boolean,
        public anulacao?: boolean,
        public recolhimento_encargo?: boolean,
        public empenho?: EmpenhoResto,
        public exercicio?: Exercicio,
        public orgao?: Orgao,
        public inscrito_resto?: boolean,
        public data_cadastro?: Date,
        public data_alteracao?: Date,
        public preliquidacao?: PreLiquidacaoResto,
        public usuario_cadastro?: Usuario,
        public documento_fiscal_info?: DocumentoFiscalInfo,
        public retencoes?: RetencaoResto[],
        public pagamentos?: PagamentoResto[],
        public liquidacao_anulada?: LiquidacaoResto,
        public ordem_item?: OrdemPagamentoItem[],
        public anulado_total?: boolean,
        public superavit?: boolean,
    ) {
        super();
    }

    static converteJson(json: any): LiquidacaoResto {
        return Object.assign(new LiquidacaoResto(), json);
    }
}
