import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SetorFormComponent } from './setor-form/setor-form.component';
import { SetorListComponent } from './setor-list/setor-list.component';
import { SetorViewComponent } from './setor-view/setor-view.component';
import { AuthGuard } from '../../util/auth.guard';

const routes: Routes = [
  { path: '', component: SetorListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: SetorFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: SetorFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: SetorViewComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SetorRoutingModule { }
