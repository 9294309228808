import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Orgao } from '../entidade/comum/orgao.model';
@Injectable({
  providedIn: 'root'
})
export class TecnoSpeedService {

  protected http: HttpClient;

  constructor(
    protected injector: Injector
  ) {
    this.http = injector.get(HttpClient);
  }

  private cnpj_sh: string = '57714750000142';
  private token_sh: string = '710f9ccbec22414c5bf356b3ddda5ff4';
  private headers = new HttpHeaders({ 'cnpj_sh': this.cnpj_sh, 'token_sh': this.token_sh });
  private versao = '2.1.02';

  buscarVinculo(cnpj, token): Observable<any> {
    return this.http.get(`https://api.tecnospeed.com.br/reinf/v2/empregadores`, {
      headers: new HttpHeaders({ 'empregador': cnpj, 'authorization': token })
    });
  }

  buscarChaves(cnpj, token): Observable<any> {
    return this.http.get(`https://api.tecnospeed.com.br/reinf/v2/empregadores/certificado`, {
      headers: new HttpHeaders({ 'empregador': cnpj, 'authorization': token })
    });
  }

  consultarPorEnvio(idEnvio: String, ambiente: number, consolidar: boolean, token: any, cnpj: string): Observable<any> {
    //${idEnvio}?ambiente=${ambiente}&versaomanual=${this.versao}${consolidar ? '&consultatotalizador=1' : ''}0
    return this.http.get(`https://api.tecnospeed.com.br/reinf/v2/evento/consultar`, { headers: new HttpHeaders({ 'id': '' + idEnvio, 'empregador': cnpj, 'authorization': token, 'ambiente': '' + ambiente, 'versaomanual': this.versao, 'consultatotalizador': consolidar ? '1' : '' }) });
  }

  cadastrarChave(certificado, senha, cnpj, email, razao, token): Promise<any> {
    let promessa = new Promise((resolve, reject) => {
      var formData = new FormData();
      formData.append("certificado", certificado);
      formData.append("senha", senha);
      formData.append("email", email);
      formData.append("apelido", razao);

      var xhr = new XMLHttpRequest();

      xhr.open("POST", 'https://api.tecnospeed.com.br/reinf/v2/certificados');

      xhr.setRequestHeader('empregador', cnpj);
      xhr.setRequestHeader('authorization', token);

      xhr.onload = function () {
        var response = JSON.parse(xhr.responseText);
        if (xhr.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      }

      xhr.onerror = function () {
        reject('Falha ao enviar cadastro de chave.');
      }

      xhr.send(formData);
    });

    return promessa;
  }

  vincularChave(handle: number, cnpj: string, token): Observable<any> {
    const body = {
      cpfcnpjempregador: cnpj,
      handleCertificado: handle
    }
    return this.http.post(`https://api.tecnospeed.com.br/reinf/v2/certificados/vincular`, body,
      { headers: new HttpHeaders({ 'empregador': cnpj, 'authorization': token }) });
  }

  desvincularChave(handle, cnpj: string, token): Observable<any> {
    const body = {
      handleCertificado: handle
    }
    return this.http.delete(`https://api.tecnospeed.com.br/reinf/v2/empregadores/certificado`,
      { headers: new HttpHeaders({ 'empregador': cnpj, 'authorization': token }), body: body });
  }

  enviarEvento(arquivo): Promise<any> {
    let promessa = new Promise((resolve, reject) => {

      var formData = new FormData();

      formData.append('tx2', arquivo);

      const xhr = new XMLHttpRequest();

      xhr.open("POST", 'https://api.tecnospeed.com.br/reinf/v2/evento/enviar/tx2');
      // xhr.setRequestHeader('content-type', 'text/tx2');
      xhr.setRequestHeader('cnpj_sh', this.cnpj_sh);
      xhr.setRequestHeader('token_sh', this.token_sh);
      xhr.onload = function () {
        var response = JSON.parse(xhr.responseText);
        if (xhr.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      }

      xhr.onerror = function () {
        reject('Falha ao enviar evento.');
      }

      xhr.send(arquivo);
    });

    return promessa;
  }

  enviarEvento3(arquivo): Promise<any> {
    const promessa = new Promise<any>(async (resolve, reject) => {

      const response = await fetch('https://api.tecnospeed.com.br/reinf/v2/evento/enviar/tx2', {
        method: 'POST',
        body: arquivo,
        headers: {
          'cnpj_sh': '57714750000142',
          'token_sh': '710f9ccbec22414c5bf356b3ddda5ff4',
          'content-type': 'text/tx2'
        }
      });

      if (!response.ok) {
        reject(response.body)
      }
      // const asString = new TextDecoder("utf-8").decode(response.body);
      // and further:
      resolve(response.body);
    });

    return promessa;
  }

  gerarTokenTecnospeed(): Promise<any> {
    const p = new Promise<any>((resolve, reject) => {
      var data = "cnpj_sh=57714750000142&token_sh=710f9ccbec22414c5bf356b3ddda5ff4";
      var xhr = new XMLHttpRequest();

      xhr.onload = function () {
        var response = JSON.parse(xhr.responseText);
        if (xhr.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      }
      xhr.onerror = function () {
        reject('API do assinador não se encontra online');
      }

      xhr.open("POST", "https://api.tecnospeed.com.br/reinf/v2/auth");
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.send(data);
    });
    return p;
  }

}

