import { Injectable, OnDestroy } from '@angular/core';
import { AnexoLoaService } from '../service/anexo-loa.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuncaoService, LoginContabil, Exercicio, Relatorio, GlobalService } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class Anexo2Receita implements OnDestroy {
  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLoaService) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // tslint:disable: max-line-length
  public montarRelatorio(exercicio: Exercicio, orgaos: number[]) {
    this.anexoServico.obterAnexo2Receita(exercicio.id, orgaos, this.login.ppa.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        Relatorio.imprimirPersonalizado(
          'RECEITAS SEGUNDO AS CATEGORIAS ECONÔMICAS'
          , this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          this.conteudo(dados, exercicio),
          'landscape', 'ANEXO 2',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private conteudo(dados: any[], exercicio: Exercicio): {}[] {

    // monta o cabecalho
    const registros: {}[] = [[{
      text: `EXERCÍCIO ${exercicio.ano}`, fontSize: 11,
      alignment: 'center', bold: true, colSpan: 9, border: [true, true, true, false]
    }, '', '', '', '', '', '', '', ''],
    [{
      text: 'Lei nº 4.320/64, Art. 2º, §1º, II - Anexo 2',
      alignment: 'center',
      bold: true,
      colSpan: 9, border: [true, false, true, false]
    }, '', '', '', '', '', '', '', '']
    ];

    // monta o agrupamento do relatório

    let total6 = 0;
    registros.push(
      [{
        text: 'CÓDIGO',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'DESCRIÇÃO',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'TIPO',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'DESDOBRAMENTO 3',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'DESDOBRAMENTO 2',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'DESDOBRAMENTO 1',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'ESPÉCIE',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'ORIGEM',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'CATEGORIA',
        alignment: 'center',
        bold: true, fontSize: 8
      }]
    );
    // lista os registros do relatorio
    for (const registro of dados) {

      registros.push([
        {
          text: `${this.funcaoService.mascarar('#.#.#.#.##.#.#', registro.codigo)}`, fontSize: 8, border: [true, false, false, false], bold: (registro.nivel === 0 || registro.nivel === 1) ? true : false
        },
        {
          text: `${registro.nome_receita}`, fontSize: 8, border: [true, false, false, false], bold: (registro.nivel === 0 || registro.nivel === 1) ? true : false
        },
        {
          text: registro.nivel === 6 ? this.funcaoService.convertToBrNumber(registro.total) : '', alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: registro.nivel === 5 ? this.funcaoService.convertToBrNumber(registro.total) : '', alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: registro.nivel === 4 ? this.funcaoService.convertToBrNumber(registro.total) : '', alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: registro.nivel === 3 ? this.funcaoService.convertToBrNumber(registro.total) : '', alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: registro.nivel === 2 ? this.funcaoService.convertToBrNumber(registro.total) : '', alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: registro.nivel === 1 ? this.funcaoService.convertToBrNumber(registro.total) : '', alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: registro.nivel === 0 ? this.funcaoService.convertToBrNumber(registro.total) : '', alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        },
      ]);
      
      if (registro.nivel === 0) {
        console.log(+registro.total);
      }

      total6 += registro.nivel === 0 ? +registro.total : 0;

    }
    registros.push([{
      text: 'TOTAL', colSpan: 8, border: [true, true, true, true],
    }, '', '', '', '', '', '', '', {
      text: this.funcaoService.convertToBrNumber(total6), alignment: 'right', bold: true
    }]);

    registros.push([{
      text: '', colSpan: 9, border: [false, false, false, false], margin: [0, 0, 0, 10]
    }, '', '', '', '', '', '', '', '']);


    return [{
      layout: 'linhas',
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        dontBreakRows: true,
        headerRows: 2,
        widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }];
  }


}
