import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, ReservaDotacaoItem } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ReservaDotacaoItemService extends BaseResourceService<ReservaDotacaoItem> {

  constructor(
    protected injector: Injector
  ) {
    super(`reserva-dotacao-itens`, injector);
  }

  public totalizadoresFicha(exercicio: number, orgaoId: number, fichaId: number): Observable<any> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/totalizadores-ficha/${exercicio}/${orgaoId}/${fichaId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalizadoresFichaData(exercicio: number, orgaoId: number, fichaId: number, dataReserva: string): Observable<any> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/totalizadores-ficha-data/${exercicio}/${orgaoId}/${fichaId}/${dataReserva}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalReservadoItem(reserva_id: number): Observable<any> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/saldo-total-reserva/${reserva_id}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
