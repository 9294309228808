import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreditoRoutingModule } from './credito-routing.module';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { IMaskModule } from 'angular-imask';
import { ToastModule } from 'primeng/toast';
import { CreditoListComponent } from './credito-list/credito-list.component';
import { CreditoViewComponent } from './credito-view/credito-view.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CreditoFormComponent } from './credito-form/credito-form.component';
import { CreditoItemComponent } from './credito-item/credito-item.component';
import { InputMaskModule } from 'primeng/inputmask';
import { SharedModule } from 'eddydata-lib';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CreditoPercentualRptComponent } from './credito-percentual-rpt/credito-percentual-rpt.component';
import { FieldsetModule } from 'primeng/fieldset';
import { MinutaRptComponent } from './minuta-rpt/minuta-rpt.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CreditoExportarComponent } from './credito-exportar/credito-exportar.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { FichaDespesaViewModule } from '../../ficha-despesa/ficha-despesa.module';

@NgModule({
  declarations: [
    CreditoListComponent,
    CreditoViewComponent,
    CreditoFormComponent,
    CreditoItemComponent,
    CreditoPercentualRptComponent,
    CreditoExportarComponent,
    MinutaRptComponent],
  exports: [CreditoPercentualRptComponent,
    MinutaRptComponent
  ],
  imports: [
    CommonModule,
    CreditoRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    ConfirmDialogModule,
    IMaskModule,
    ToastModule,
    InputMaskModule,
    FieldsetModule,
    RadioButtonModule,
    DialogModule,
    ButtonModule,
    FichaDespesaViewModule
  ],
  providers: [MessageService, ConfirmationService]
})
export class CreditoModule { }
