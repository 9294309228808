import { Injectable, Injector } from '@angular/core';
import { BaseResourceService } from '../../models/services/base-resource.service';
import { Auditoria } from '../../entidade/comum/auditoria.model';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuditoriaService extends BaseResourceService<Auditoria> {

  constructor(
    protected injector: Injector
  ) {
    super(`auditorias`, injector);
  }

  public getAlvos(nome?: string): Observable<{ classe: string, nome: string }[]> {
    let param = '';
    if (nome)
      param += '?nome=' + nome;
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/alvos${param}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public alvosAuditoria(nome?: string): Observable<any[]> {
    let param = '';
    if (nome)
      param += '?nome=' + nome;
    return this.http.get<any[]>(`
    ${this.login.cidade.id}/${this.api}/alvos-auditoria${param}
    `, this.httpOptions()).pipe(map(res => res), catchError(err => this.handleError(err)))
  }


}
