import { Component, Input, OnInit } from '@angular/core';
import { Audesp4ConferenciaMedicaoSimTI, Audesp4ResponsavelTI, EddyAutoComplete, Execucao, FuncaoService, Pessoa, PessoaService } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-responsavel',
  templateUrl: './licitacao-audesp-responsavel.component.html'
})
export class LicitacaoAudespResponsavelComponent extends BaseLicitacaoAudesp implements OnInit {

  @Input() public execucao: Execucao | Audesp4ConferenciaMedicaoSimTI;
  @Input() public campo: string;

  public itemAtual: Audesp4ResponsavelTI;
  public itemAnterior: Audesp4ResponsavelTI;

  constructor(
    public funcaoService: FuncaoService,
    private pessoaService: PessoaService,
  ) {
    super()
  }

  ngOnInit(): void {
    this.pessoaAutoComplete = new EddyAutoComplete(null, this.pessoaService,
      'id', ['cpf_cnpj', 'nome'], { orderBy: 'nome' }, { number: ['cpf_cnpj'], text: ['nome'] }
    );
  }

  public adicionar() {
    if (!this.execucao[this.campo] || this.execucao[this.campo].length === 0) this.execucao[this.campo] = [];

    this.itemAtual = {
      CPFResponsavel: undefined, Nome: undefined, Cargo: undefined, ResponsavelAreaTI: 'N', editavel: true
    }
    this.itemAtual['pessoa'] = new Pessoa();
    this.execucao[this.campo].push(this.itemAtual);
  }

  public visualizar(item: Audesp4ResponsavelTI) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
    if (this.itemAtual.pessoaId)
      this.pessoaService.obterId(this.itemAtual.pessoaId).subscribe((res) => {
        this.itemAtual['pessoa'] = res;
      })
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      delete this.itemAtual['pessoa'];
      let itm = this.execucao[this.campo].find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.execucao[this.campo].splice(this.execucao[this.campo].indexOf(this.itemAtual), 1, itm)
      this.itemAtual.editavel = false;
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4ResponsavelTI) {
    this.itemAtual = undefined;
    this.execucao[this.campo].splice(this.execucao[this.campo].indexOf(item), 1)
  }

  public salvar() {
    delete this.itemAtual['pessoa'];
    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }

  public cadastroPessoa() {
    $('#dialogPessoa').modal('show');
  }

  public callbackPessoa(pessoa: Pessoa) {
    if (pessoa)
      this.itemAtual['pessoa'] = pessoa;
    else
      pessoa = this.itemAtual['pessoa']

    this.itemAtual.pessoaId = pessoa.id;
    this.itemAtual.CPFResponsavel = pessoa.cpf_cnpj;
    this.itemAtual.Nome = pessoa.nome;
  }
}
