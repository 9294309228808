// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ProtocoloHistorico } from './protocolo-historico.model';

export class ProtocoloArquivo extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public caminho?: string,
    public tipo?: string,
    public documento?: string,
    public tamanho?: number,
    public historico?: ProtocoloHistorico,
    public data_cadastro?: Date,
    public data_alteracao?: Date
    ) {
    super();
  }
  static converteJson(json: any): ProtocoloArquivo {
    return Object.assign(new ProtocoloArquivo(), json);
  }
}
