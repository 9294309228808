import { BaseResourceModel } from '../../models/base-resource.model';
import { Assinatura } from './assinatura.model';
import { Pessoa } from './pessoa.model';

export class AssinaturaPessoa extends BaseResourceModel {
  constructor(
    public id?: number,
    public cargo?: string,
    public url?: string,
    public base64?: boolean,
    public pessoa?: Pessoa,
    public assinatura?: Assinatura,
    public editavel?: boolean,
    public selecionado?: boolean,
  ) {
    super();
  }
  static converteJson(json: any): AssinaturaPessoa {
    return Object.assign(new AssinaturaPessoa(), json);
  }
}
