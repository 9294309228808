import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { InventarioSetor } from './inventario-setor.model';
import { Tombamento } from './tombamento.model';
import { TransferenciaItemPatrimonio } from './transferencia-item-patrimonio.model';

export class InventarioTransferencia extends BaseResourceModel {
  constructor(
    public id?: number,
    public motivo?: string,
    public status?: 'PENDENTE' | 'ACEITO' | 'RECUSADO' | 'CANCELADO',
    public data_status?: Date,
    public motivo_status?: string,
    public solicitacao?: boolean,
    public tombamento?: Tombamento,
    public transferencia_item_origem?: TransferenciaItemPatrimonio,
    public origem?: InventarioSetor,
    public destino?: InventarioSetor,
    public usuario?: Usuario,
    public usuario_status?: Usuario,
  ) {
    super();
  }

  static converteJson(json: any): InventarioTransferencia {
    return Object.assign(new InventarioTransferencia(), json);
  }
}
