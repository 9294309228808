import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Page, RplItem } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RplItemService extends BaseResourceService<RplItem> {

    constructor(
        protected injector: Injector
    ) {
        super(`rpl-itens`, injector);
    }

    public obterPorRpl(id: number, incluirMemorial?: boolean): Observable<Page> {
        let uri = `relations=produto_unidade.produto,produto_unidade.unidade,lista_ficha_despesa,lista_ficha_despesa.ficha_despesa.despesa,lista_ficha_despesa.ficha_despesa.orgao,lista_ficha_despesa.ficha_despesa.exercicio`;
        if (incluirMemorial) uri += ',memorial.licitacao,memorial.produto_unidade.produto,memorial.produto_unidade.unidade';

        return this.http.get<Page>(
            `${this.login.cidade.id}/${this.api}/filtrar?${uri}&rpl_id=${id}&orderBy=ordem,id`
            , this.httpOptions()).pipe(
                map(res => res),
                catchError(err => this.handleError(err))
            );
    }

    public desvincularMemorial(id: number): Observable<void> {
        let url = `${this.login.cidade.id}/${this.api}/desvincular-memorial/${id}`;
        return this.http.post(url, undefined, this.httpOptions()).pipe(
            map(() => null),
            catchError(err => this.handleError(err))
        );
    }
}
