
import { Injectable } from '@angular/core';
import { LoginService } from '../login/servico/login.service';
import { GlobalService } from './global.service';
import { Login } from '../entidade/login/login';

const login: Login = GlobalService.obterSessaoLogin();

@Injectable({
    providedIn: 'root'
})
export class MapaRotasTransparenciaService {

    buscarRotaTransparencia(): Array<{ pagina: string, rota: string }> {
        const lista = [];

        lista.push({ pagina: 'PARÂMETROS', rota: 'admin/transparencia/parametros-transparencia' });
        lista.push({ pagina: 'DECRETO', rota: 'transparencia/decreto' });
        lista.push({ pagina: 'PLANO PLURIANUAL (PPA)', rota: 'transparencia/planejamento' });
        lista.push({ pagina: 'RECEITA PREVISTA', rota: `transparencia/receita-prevista/${login.exercicio.ano}` });
        lista.push({ pagina: 'DESPESA PREVISTA', rota: `transparencia/despesa-prevista/${login.exercicio.ano}` });
        lista.push({ pagina: 'RECEBIMENTOS COVID-19', rota: 'transparencia/receitas-orcamentarias/pesquisa/covid/1' });
        lista.push({ pagina: 'DESPESAS REALIZADAS COVID-19', rota: 'transparencia/empenhos-orcamentarios/pesquisa/covid/1' });
        lista.push({ pagina: 'ARRECADAÇÃO DE IMPOSTOS', rota: 'transparencia/receitas-orcamentarias/pesquisa/impostos/1' });
        lista.push({ pagina: 'ARRECADAÇÃO MENSAL DE RECEITAS ORÇAMENTÁRIAS', rota: `transparencia/receitas-orcamentarias/arrecadacao-mensal/${login.exercicio.ano}/transparencia` });
        lista.push({ pagina: 'DESPESAS ORÇAMENTÁRIAS MENSAIS', rota: `transparencia/despesa-nivel/${login.exercicio.ano}/transparencia` });
        lista.push({ pagina: 'DESPESAS DE VIAGENS', rota: 'transparencia/empenhos-orcamentarios/pesquisa/viagens/1' });
        lista.push({ pagina: 'EQUIPAMENTOS E MATERIAL PERMANENTE', rota: 'transparencia/empenhos-orcamentarios/pesquisa/equipamentos/1' });
        lista.push({ pagina: 'TRANSFERÊNCIAS ESTADUAIS', rota: 'transparencia/receitas-orcamentarias/pesquisa/estaduais/1' });
        lista.push({ pagina: 'TRANSFERÊNCIAS FEDERAIS', rota: 'transparencia/receitas-orcamentarias/pesquisa/federais/1' });
        lista.push({ pagina: 'OPERAÇÕES DE CRÉDITO', rota: 'transparencia/receitas-orcamentarias/pesquisa/operacoes/1' });
        lista.push({ pagina: 'REPASSES TERCEIRO SETOR', rota: 'transparencia/empenhos-orcamentarios/pesquisa/repasses/1' });
        lista.push({ pagina: 'CONTAS PÚBLICAS', rota: 'transparencia/contas-publica' });
        lista.push({ pagina: 'ANEXAR ARQUIVOS POR GRUPO', rota: 'transparencia/transparencia-arquivos' });
        lista.push({ pagina: 'BALANCETES', rota: 'transparencia/execucao-orcamentaria/balancetes' });
        lista.push({ pagina: 'BALANÇOS', rota: 'transparencia/execucao-orcamentaria/balancetes-anuais' });
        lista.push({ pagina: 'EXECUÇÃO ORÇAMENTÁRIA - LRF', rota: 'transparencia/execucao-orcamentaria/lrf' });
        lista.push({ pagina: 'RELATÓRIO RESUMIDO DA EXECUÇÃO ORÇAMENTÁRIA - RREO', rota: 'transparencia/rreo' });
        lista.push({ pagina: 'Anexos Lei nº 4.320/64 - LDO', rota: 'transparencia/ldo' });
        lista.push({ pagina: 'ANEXOS LEI Nº 4.320/64 - LOA', rota: 'transparencia/loa' });
        lista.push({ pagina: 'QUADROS DE ENSINO', rota: 'transparencia/quadros-ensino' });
        lista.push({ pagina: 'QUADROS DA SAÚDE', rota: 'transparencia/quadros-saude' });
        lista.push({ pagina: 'CONSULTA DE FORNECEDORES', rota: 'transparencia/fornecedores/pesquisa' });
        lista.push({ pagina: 'RECEITAS ORÇAMENTÁRIAS', rota: 'transparencia/receitas-orcamentarias/pesquisa/todos/0' });
        lista.push({ pagina: 'RECEITAS EXTRA ORÇAMENTÁRIAS', rota: 'transparencia/receitas-extra-orcamentarias/pesquisa/1' });
        lista.push({ pagina: 'EMPENHOS ORÇAMENTÁRIOS', rota: 'transparencia/empenhos-orcamentarios/pesquisa/todos/1' });
        lista.push({ pagina: 'EMPENHOS DE RESTOS A PAGAR', rota: 'transparencia/empenhos-resto/pesquisa/todos/1' });
        lista.push({ pagina: 'LIQUIDAÇÕES ORÇAMENTÁRIAS', rota: 'transparencia/liquidacoes-orcamentarias/pesquisa/1' });
        lista.push({ pagina: 'LIQUIDAÇÕES RESTO A PAGAR', rota: 'transparencia/liquidacoes-restos/pesquisa/1' });
        lista.push({ pagina: 'PAGAMENTO ORÇAMENTÁRIO', rota: 'transparencia/pagamentos-orcamentarios/pesquisa/1' });
        lista.push({ pagina: 'PAGAMENTO EXTRA ORÇAMENTÁRIO', rota: 'transparencia/pagamentos-extras/pesquisa/1' });
        lista.push({ pagina: 'PAGAMENTO DE RESTOS A PAGAR', rota: 'transparencia/pagamentos-restos/pesquisa/1' });
        lista.push({ pagina: 'ATAS DE REGISTRO DE PREÇOS', rota: 'transparencia/atas-registro-precos/pesquisa/1' });
        lista.push({ pagina: 'EVENTOS (HOLERITE)', rota: 'admin/transparencia/eventos-holerite' });
        lista.push({ pagina: 'TRANSFERÊNCIAS BANCÁRIAS', rota: 'transparencia/entre-orgaos/pesquisa/1' });
        lista.push({ pagina: 'REMUNERAÇÃO SERVIDORES PÚBLICOS', rota: `transparencia/folha-pagamento/pesquisa/1/${login.orgao.id}/${login.exercicio.ano}/TODAS` });
        lista.push({ pagina: 'CARGOS E SALÁRIOS', rota: 'transparencia/cargos/pesquisa/1' });
        lista.push({ pagina: 'CONTRATOS E ADITIVOS', rota: 'transparencia/contratos/pesquisa/1' });
        lista.push({ pagina: 'CONVÊNIOS', rota: 'transparencia/convenios/pesquisa/1' });
        lista.push({ pagina: 'LICITAÇÕES', rota: 'transparencia/licitacoes/pesquisa/1' });
        lista.push({ pagina: 'COMPRAS - (OF)', rota: 'transparencia/compras/pesquisa/1' });
        lista.push({ pagina: 'USUÁRIOS', rota: 'usuarios' });
        lista.push({ pagina: 'ORGÃOS PÚBLICOS', rota: 'orgaos' });

        return lista;
    }
}

