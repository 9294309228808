import { DatePipe } from "@angular/common";
import { ElementRef, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Document, Packer, Paragraph, TextRun } from "docx";
import jwt_decode from "jwt-decode";
import { MessageService } from "primeng/api";
import { AutoComplete } from "primeng/autocomplete";
import { Calendar } from "primeng/calendar";
import { InputMask } from "primeng/inputmask";
import * as toastr from "toastr";
import { tsXLXS } from "ts-xlsx-export";
import X2JS from "x2js";
import { CpfPipe } from "../components/pipe/cpf.pipe";
import { TelefonePipe } from "../components/pipe/telefone.pipe";
import {
  Coluna,
  DIA_TIME,
  FormatoExportacao,
  Sistemas,
} from "../components/types";
import { Login } from "../entidade/login/login";
import { Filtro } from "../models/base-resource-list";
import { GlobalService } from "./global.service";

@Injectable({
  providedIn: "root",
})
export class FuncaoService {
  public navegarPara(sistema: Sistemas, router: Router, login?: Login) {
    switch (sistema) {
      case "transparencia": {
        router.navigate(["/admin/transparencia/parametros-transparencia"]);
        break;
      }
      case "site": {
        router.navigate(["/portal-site"]);
        break;
      }
      default: {
        router.navigate(["/"]);
        break;
      }
    }
  }

  public zerarHoras(data: Date): Date {
    let dt = this.formartaData(new Date(data)).substring(0, 10).split('-')
    return new Date(+dt[0], +dt[1] - 1, +dt[2], 0, 0, 0);
  }

  public customFlat(arr: any[], depth: number = 1): any[] {
    if (depth === 0) {
      return arr.slice();
    }

    return arr.reduce((acc, current) => {
      if (Array.isArray(current)) {
        acc.push(...this.customFlat(current, depth - 1));
      } else {
        acc.push(current);
      }
      return acc;
    }, []);
  }

  public podeAlterarAudesp(data: Date, login: any): boolean {
    if (!data) {
      return true;
    }
    if (login['ultimoAudesp'] && data) {
      data = new Date(data);
      const mes = +login['ultimoAudesp'].split('-')[1];
      const ano = +login['ultimoAudesp'].split('-')[0];
      if ((data.getFullYear() < ano || (data.getFullYear() === ano && (+data.getMonth() + 1) <= mes)) && mes < 12)
        return false;
    }
    if (login["dias_bloquear_alteracoes"]) {
      return (
        this.diferencaEmDias(new Date(), new Date(data)) <
        login["dias_bloquear_alteracoes"]
      );
    } else {
      return true;
    }
  }

  public obterMensagemErro(err: any): string {
    if (!err) return "";
    if (err.error) {
      if (err.error.payload) return err.error.payload;
      return err.error;
    }

    if (err.message) return err.message;

    return err;
  }

  public converteDataBR(dateStr: string | Date): string {
    if (!dateStr) return "";
    if (typeof dateStr !== "string") {
      dateStr = dateStr.toISOString();
    }
    if (dateStr.match(/\d{2}\/\d{2}\/\d{4}/)) {
      return dateStr.match(/\d{2}\/\d{2}\/\d{4}/)[0];
    }

    if (dateStr.length > 10) {
      dateStr = dateStr.substring(0, 10);
    }
    var parts = dateStr.split("-");
    return `${parts[2]}/${parts[1]}/${parts[0]}`;
  }

  /**
* @deprecated Método deverá ser substituído por `converteHoraBr`, por conta de um bug ao converter o horário de forma errada.
*/
  public converteHoraBR(dateStr: string | Date): string {
    if (!dateStr) return "";
    if (typeof dateStr !== "string") {
      dateStr = dateStr.toISOString();
    }
    if (dateStr.match(/\d{2}\:\d{2}/)) {
      return dateStr.match(/\d{2}\:\d{2}/)[0];
    }
    return dateStr.substring(11, 5);
  }

  public converteDataHoraBR(dt: string | Date): string {
    if (!dt) return "";
    try {
      if (typeof dt !== "string") {
        dt = dt.toISOString();
      }
      return new Intl.DateTimeFormat("pt-br", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
      }).format(new Date(dt));
    } catch (err) {
      return new Intl.DateTimeFormat("pt-br").format(new Date(dt));
    }
  }

  public converteHoraBr(dt: string | Date): string {
    if (!dt) return "";
    try {
      if (typeof dt !== "string") {
        dt = dt.toISOString();
      }
      return new Intl.DateTimeFormat("pt-br", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      }).format(new Date(dt));
    } catch (err) {
      return new Intl.DateTimeFormat("pt-br").format(new Date(dt));
    };
  };

  public adicionarDia(dias: number, date: Date, diasUteis?: boolean, removerDias?: boolean): Date {
    if (!date) return null
    if (!dias) return date;
    if (typeof date === 'string') date = new Date(date)

    if (diasUteis) {
      let dt = date;

      let diasIncluidos = 0;
      while (diasIncluidos < dias) {
        dt = this.adicionarDia(1, dt);
        if (dt.getDay() > 0 && dt.getDay() < 6) {
          diasIncluidos++;
        }
      }

      return dt;
    } else {
      if (removerDias) return new Date(date.setDate(date.getDate() - dias))
      else return new Date(date.setDate(date.getDate() + dias))
    }
  }

  public adicionarMes(meses: number, date: Date): Date {
    if (!date) return null
    if (!meses) return date;
    if (typeof date === 'string') date = new Date(date)
    return new Date(date.setMonth(date.getMonth() + meses));
  }

  public adicionarAno(anos: number, date: Date): Date {
    if (!date) return null
    if (!anos) return date;
    if (typeof date === 'string') date = new Date(date)
    return new Date(date.setFullYear(date.getFullYear() + anos));
  }

  public ultimoDiaMes(mes: number, ano?: number): number {
    switch (mes) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        return 31;
      case 2:
        ano = ano ? ano : new Date().getFullYear();
        return ano % 4 === 0 ? 29 : 28;
      default:
        return 30;
    }
  }

  public isNumerico(str: any) {
    const er = /^[0-9]+$/;
    return er.test(str);
  }

  public formatarData(now: Date) {
    const year = "" + now.getFullYear();
    let month = "" + (now.getUTCMonth() + 1);
    if (month.length === 1) {
      month = "0" + month;
    }
    let day = "" + now.getDate();
    if (day.length === 1) {
      day = "0" + day;
    }
    let hour = "" + now.getHours();
    if (hour.length === 1) {
      hour = "0" + hour;
    }
    let minute = "" + now.getMinutes();
    if (minute.length === 1) {
      minute = "0" + minute;
    }
    let second = "" + now.getSeconds();
    if (second.length === 1) {
      second = "0" + second;
    }
    return (
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second
    );
  }

  public formatarHora(now: Date) {
    if (!now) return "";
    let hour = "" + now.getHours();
    if (hour.length === 1) {
      hour = "0" + hour;
    }
    let minute = "" + now.getMinutes();
    if (minute.length === 1) {
      minute = "0" + minute;
    }
    return (
      hour + "h" + minute
    );
  }

  public formatarDataExtenso(data: Date, cidade?: string): string {
    if (!data) return "";
    const ano: number = data.getFullYear();
    const mes: string = new GlobalService()
      .obterListaMeses()
      .find((m) => +m.id === data.getUTCMonth() + 1).nome;
    const dia: string = this.strZero(data.getDate(), 2);

    return `${cidade ? cidade + ", " : ""
      }${dia} de ${this.capitalizeFirstLetter(mes, true)} de ${ano}`;
  }

  public toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }

  toString(value: any): string {
    return value !== undefined && value !== null ? `${value}` : "";
  }

  getValueInRange(value: number, max: number, min = 0): number {
    return Math.max(Math.min(value, max), min);
  }

  isString(value: any): value is string {
    return typeof value === "string";
  }

  isNumber(value: any): value is number {
    if (
      value.match(/^\d{2}\/\d{2}\/\d{2}$/) ||
      value.match(/^\d{2}\/\d{2}\/\d{4}$/)
    ) {
      return false;
    } else {
      return !isNaN(+value);
    }
  }

  isInteger(value: any): value is number {
    return (
      typeof value === "number" &&
      isFinite(value) &&
      Math.floor(value) === value
    );
  }

  isDefined(value: any): boolean {
    return value !== undefined && value !== null;
  }

  padNumber(value: number) {
    if (this.isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return "";
    }
  }

  regExpEscape(text: string) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  hasClassName(element: any, className: string): boolean {
    return (
      element &&
      element.className &&
      element.className.split &&
      element.className.split(/\s+/).indexOf(className) >= 0
    );
  }

  isDate(d: string | number | Date) {
    const date = new Date(d);
    let day = "" + date.getDate();
    if (day.length === 1) {
      day = "0" + day;
    }
    let month = "" + (date.getUTCMonth() + 1);
    if (month.length === 1) {
      month = "0" + month;
    }
    const year = "" + date.getFullYear();

    return month + "/" + day + "/" + year === d;
  }

  public toDate(dateStr: any) {
    let dateObject: Date;
    if (dateStr) {
      const dateParts = dateStr.split("/");
      dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]); // month is 0-based
    }
    return dateObject;
  }

  public diaDaSemana(dia: number): string {
    switch (dia) {
      case 0:
        return "Domingo";
      case 1:
        return "Segunda-feira";
      case 2:
        return "Terça-feira";
      case 3:
        return "Quarta-feira";
      case 4:
        return "Quinta-feira";
      case 5:
        return "Sexta-feira";
      case 6:
        return "Sábado";
    }
  }

  converteDataSQL(dateStr: string | Date): string {
    if (!dateStr) return "";
    if (typeof dateStr !== "string") {
      dateStr = dateStr.toISOString();
      return dateStr.substring(0, "0000-00-00".length);
    } else {
      const parts = dateStr.split("/");
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
  }

  converteValorSQL(valor: number) {
    return Number(valor.toString().replace(/[^0-9.-]+/g, ""));
  }

  public convertArrayOfObjectsToCSV(args: any) {
    let result: string;
    let ctr: number;
    let keys: any;
    let columnDelimiter: string;
    let lineDelimiter: string;
    let data: any;

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ",";
    lineDelimiter = args.lineDelimiter || "\n";

    keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach((item: { [x: string]: string }) => {
      ctr = 0;
      keys.forEach((key: string | number) => {
        if (ctr > 0) {
          result += columnDelimiter;
        }
        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });
    return result;
  }

  public downloadCSV(args: any, fileJSON: any) {
    let data: any;
    let filename: any;
    let link: any;
    let csv = this.convertArrayOfObjectsToCSV({
      data: fileJSON,
    });
    if (csv == null) {
      return;
    }

    filename = args.filename || "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = "data:text/csv;charset=utf-8," + csv;
    }

    data = encodeURI(csv);

    link = document.createElement("a");
    link.setAttribute("content", "text/csv;charset=utf-8");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
  }

  public mascarar(mascara: string, valor: string) {
    if (!valor) {
      return valor;
    }
    let sb = "";
    let j = 0;
    for (let i = 0; i < mascara.length; i++) {
      if (j >= valor.length) {
        break;
      } else if (mascara.charAt(i) === "0" || mascara.charAt(i) === "#") {
        sb += valor.charAt(j);
        j++;
      } else if (mascara.charAt(i) === "*") {
        sb += valor.substring(j, valor.length);
        return sb;
      } else {
        sb += mascara.charAt(i);
      }
    }
    return sb;
  }

  public capitalizeFirstLetter(texto: string, apenasPrimeira?: boolean) {
    if (!texto) return "";
    if (apenasPrimeira)
      return texto.charAt(0).toUpperCase() + texto.substr(1).toLowerCase()
    else
      return texto.replace(/\w\S*/g, (txt: string) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
  }

  public strZero(obj: any, tamanho: number): string {
    let sb = "";
    let strValue = this.extrairStr(obj).trim();
    strValue = this.desmascarar(".", strValue);
    let diff = tamanho - strValue.length;
    if (diff < 0) {
      return obj;
    }
    while (diff > 0) {
      sb += "0";
      diff--;
    }
    sb += strValue;

    return sb;
  }

  public alinharEsquerda(obj: any, tamanho: number) {
    let out = "";
    let obj_ = this.extrairStr(obj).trim();

    for (let i = 0; i < tamanho; i++) {
      if (i < obj_.length) {
        out += obj_.charAt(i);
      } else {
        out += ' ';
      }
    }
    return out;
  }

  public strEspaco(obj: string, tamanho: number): string {
    let sb = "";
    let strValue = this.extrairStr(obj).trim();
    let diff = tamanho - strValue.length;
    if (diff < 0) {
      return obj;
    }
    while (diff > 0) {
      sb += " ";
      diff--;
    }
    sb = strValue + sb;

    return sb;
  }

  public strChar(obj: String, tamanho: number, caractere: string): String {
    let sb = "";
    let strValue = this.extrairStr(obj).trim();
    let diff = tamanho - strValue.length;
    if (diff < 0) {
      return obj;
    }
    while (diff > 0) {
      sb += caractere;
      diff--;
    }
    sb = strValue + sb;

    return sb;
  }

  public desmascarar(mascara: string, valor: string): string {
    if (mascara == null) {
      return valor;
    } else {
      const hm = new Map();
      for (let j = 0; j < mascara.length; j++) {
        if (
          (mascara.charAt(j) === "0" || mascara.charAt(j) === "#") === false
        ) {
          hm.set(mascara.charAt(j), true);
        }
      }

      let sb = valor;
      for (let j = 0; j < sb.length; j++) {
        if (hm.get(sb.charAt(j))) {
          sb = this.remove_character(sb, j);
          j--;
        }
      }
      return sb;
    }
  }

  private remove_character(str: string, charPos: number) {
    const part1 = str.substring(0, charPos);
    const part2 = str.substring(charPos + 1, str.length);
    return part1 + part2;
  }

  public extrairStr(val: any): string {
    if (!val) {
      return "";
    } else {
      return String(val);
    }
  }

  public convertToBrNumber(
    numero: number | string,
    decimais?: number,
    negativoParenteses?: boolean
  ): string {
    if (decimais === undefined) {
      decimais = 2;
    }
    if (!numero) {
      if (numero !== 0) {
        numero = 0;
      }
    }
    if (typeof numero === "string") {
      numero = Number(numero);
    }
    if (numero === 0) numero = Math.abs(numero)
    if (negativoParenteses && numero < 0) {
      return (
        "(" +
        (+numero * -1).toLocaleString("de-DE", {
          minimumFractionDigits: decimais,
          maximumFractionDigits: decimais,
        }) +
        ")"
      );
    } else {
      return numero.toLocaleString("de-DE", {
        minimumFractionDigits: decimais,
        maximumFractionDigits: decimais,
      });
    }
  }

  public formatarMoedaPtBr(valor: number, decimais = 2): string {
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      currency: "BRL",
      minimumFractionDigits: decimais,
      maximumFractionDigits: decimais,
    });

    return formatter.format(valor);
  }

  public formatarMoedaPtBrNCasasDecimais(valor: number): string {
    const casasDecimais: number = this.contarCasasDecimais(valor);
    return this.formatarMoedaPtBr(valor, casasDecimais)
  }

  public contarCasasDecimais(valor: number): number {
    const casasDecimais = valor.toString().split(".")[1];
    const temMaisQueUmaCasaDecimal = casasDecimais && casasDecimais.length > 1;

    if (temMaisQueUmaCasaDecimal) {
      const casasDecimaisValidas = casasDecimais.replace(/0+$/, "");
      return casasDecimaisValidas.length;
    }

    return 2;
  }

  /**
   * Verifica diferença de dias entre data1 (geralmente maior) e data2
   * @param data1 -
   * @param data2 -
   */
  public diferencaEmDias(data1: Date, data2: Date): number {
    if (!data1 || !data2) {
      return 0;
    } else {
      return (data1.getTime() - data2.getTime()) / (1000 * 3600 * 24);
    }
  }

  public diferencaEmMeses(data1: Date, data2: Date): number {
    let meses = data2.getFullYear() - data1.getFullYear();
    meses *= 12;
    meses -= data1.getMonth();
    meses += data2.getMonth();
    return meses <= 0 ? 0 : meses;
  }

  public adicionarDiasData(dias: number) {
    const hoje = new Date();
    const dataVenc = new Date(hoje.getTime() + dias * DIA_TIME);
    return (
      dataVenc.getDate() +
      "/" +
      (dataVenc.getUTCMonth() + 1) +
      "/" +
      dataVenc.getFullYear()
    );
  }

  public compareObjects<T = unknown>(obj1: T, obj2: T): boolean {
    const differences: (keyof T)[] = [];

    for (const key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        if (obj1[key] !== obj2[key]) {
          differences.push(key);
        }
      }
    }

    for (const key in obj2) {
      if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
        differences.push(key);
      }
    }

    if (differences.length > 0) {
      return true;
    }

    return false;
  }

  public compararDatas(dataMenor: any, dataMaior: any) {

    let data1 = (new Date(dataMaior.getTime() -
      (dataMaior.getTimezoneOffset() * 60 * 1000))).toISOString().split('T')[0];

    let trechos = this.converteDataBR(dataMenor).split("/");
    let data2 = [trechos[2], trechos[1], trechos[0]].join("-")
    // comparar as duas datas que estão como string e no mesmo formato
    let resultado = data1 > data2;
    return resultado;
  }

  public validarCNPJ(cnpj: string | number | any[]): boolean {
    if (!cnpj) return false;

    // Aceita receber o valor como string, número ou array com todos os dígitos
    const isString = typeof cnpj === "string";
    const validTypes =
      isString || Number.isInteger(cnpj) || Array.isArray(cnpj);

    // Elimina valor em formato inválido
    if (!validTypes) return false;

    // Filtro inicial para entradas do tipo string
    if (typeof cnpj === "string") {
      // Limita ao máximo de 18 caracteres, para CNPJ formatado
      if (cnpj.length > 18) return false;
      if (cnpj.length === 11) return this.validarCPF(cnpj);
      if (cnpj.length !== 14) return false;

      // Teste Regex para veificar se é uma string apenas dígitos válida
      const digitsOnly = /^\d{14}$/.test(cnpj);
      // Teste Regex para verificar se é uma string formatada válida
      const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(cnpj);

      // Se o formato é válido, usa um truque para seguir o fluxo da validação
      if (digitsOnly || validFormat) true;
      // Se não, retorna inválido
      else return false;
    }

    // Guarda um array com todos os dígitos do valor
    const match = cnpj.toString().match(/\d/g);
    const numbers = Array.isArray(match) ? match.map(Number) : [];

    // Valida a quantidade de dígitos
    if (numbers.length === 11) return this.validarCPF(cnpj);
    if (numbers.length !== 14) return false;

    // Elimina inválidos com todos os dígitos iguais
    const items = [...new Set(numbers)];
    if (items.length === 1) return false;

    // Cálculo validador
    const calc = (x: number) => {
      const slice = numbers.slice(0, x);
      let factor = x - 7;
      let sum = 0;

      for (let i = x; i >= 1; i--) {
        const n = slice[x - i];
        sum += n * factor--;
        if (factor < 2) factor = 9;
      }

      const result = 11 - (sum % 11);

      return result > 9 ? 0 : result;
    };

    // Separa os 2 últimos dígitos de verificadores
    const digits = numbers.slice(12);

    // Valida 1o. dígito verificador
    const digit0 = calc(12);
    if (digit0 !== digits[0]) return false;

    // Valida 2o. dígito verificador
    const digit1 = calc(13);
    return digit1 === digits[1];
  }

  public validarCPF(cpf: string | number | any[]): boolean {
    if (!cpf) return false;

    // Aceita receber o valor como string, número ou array com todos os dígitos
    const validTypes =
      typeof cpf === "string" || Number.isInteger(cpf) || Array.isArray(cpf);

    // Elimina valores com formato inválido
    if (!validTypes) return false;

    // Guarda todos os dígitos em um array
    const numbers = cpf.toString().match(/\d/g).map(Number);

    // Valida quantidade de dígitos
    if (numbers.length === 14) return this.validarCNPJ(cpf);
    if (numbers.length !== 11) return false;

    // Elimina valores inválidos com todos os dígitos repetidos
    const items = [...new Set(numbers)];
    if (items.length === 1) return false;

    // Separa número base do dígito verificador
    const base = numbers.slice(0, 9);
    const digits = numbers.slice(9);

    // Cálculo base
    const calc = (n: number, i: number, x: number) => n * (x - i);

    // Utilitário de soma
    const sum = (r: any, n: any) => r + n;

    // Cálculo de dígito verificador
    const digit = (n: number) => {
      const rest = n % numbers.length;
      return rest < 2 ? 0 : numbers.length - rest;
    };

    // Cálculo sobre o número base
    const calc0 = base
      .map((n, i) => calc(n, i, numbers.length - 1))
      .reduce(sum, 0);
    // 1o. dígito verificador
    const digit0 = digit(calc0);

    // Valida 1o. digito verificador
    if (digit0 !== digits[0]) return false;

    // Cálculo sobre o número base + 1o. dígito verificador
    const calc1 = base
      .concat(digit0)
      .map((n, i) => calc(n, i, numbers.length))
      .reduce(sum, 0);
    // 2o. dígito verificador
    const digit1 = digit(calc1);

    // Valida 2o. dígito verificador
    return digit1 === digits[1];
  }

  public validarEmail(valor: string): boolean {
    if (valor) {
      return Boolean(
        valor.match(
          /[_\-a-zA-Z0-9\.\+]+@[a-zA-Z0-9](\.?[\-a-zA-Z0-9]*[a-zA-Z0-9])*/
        )
      ).valueOf();
    }
    return false;
  }

  public agrupar(
    lista: {}[],
    campo: string | string[],
    totalizadores?: (string | {})[],
    distinct?: string
  ): { grupo: string | {}; totalizadores: {}; registros: any[] }[] {
    const listaRetorno: {
      grupo: string | {};
      totalizadores: {};
      registros: any[];
    }[] = [];
    let linha: { grupo: string | {}; totalizadores: {}; registros: any[] };
    if (!lista || lista.length === 0) {
      return listaRetorno;
    }
    let aux: {}[] = [];
    for (const registro of lista) {
      if (!linha || this.trocarGrupo(campo, linha, registro)) {
        if (linha) {
          listaRetorno.push(linha);
        }
        const totais = {};
        if (totalizadores) {
          for (const totalizador of totalizadores) {
            if (typeof totalizador === "string") {
              totais[totalizador] = 0.0;
            } else {
              totais[totalizador["nome"]] = 0.0;
            }
          }
        }
        if (typeof campo === "string") {
          if (campo.includes(".") && !registro[campo]) {
            let grupo;
            for (const chave of campo.split(".")) {
              grupo = !grupo ? registro[chave] : grupo[chave];
            }
            linha = { grupo, totalizadores: totais, registros: [] };
          } else {
            linha = {
              grupo: registro[campo],
              totalizadores: totais,
              registros: [],
            };
          }
        } else {
          const grupo = {};
          for (const chave of campo) {
            if (chave.includes(".") && !registro[chave]) {
              let grupo2;
              for (const chave2 of chave.split(".")) {
                grupo2 = !grupo2 ? registro[chave2] : grupo2[chave2];
              }
              grupo[chave] = grupo2;
            } else {
              grupo[chave] = registro[chave];
            }
          }

          linha = { grupo, totalizadores: totais, registros: [] };
        }
      }
      if (totalizadores) {
        for (const totalizador of totalizadores) {
          if (typeof totalizador === "string") {
            if (totalizador.includes(".") && !registro[totalizador]) {
              let entidade: any;
              for (const chave of totalizador.split(".")) {
                entidade = entidade ? entidade[chave] : registro[chave];

                if (aux.filter(value => Object.values(value).toString() === Object.values(entidade).toString()).length > 0 && distinct && chave === distinct) break;

                if (distinct && chave === distinct)
                  aux.push(entidade);
              }
              linha.totalizadores[totalizador] += entidade && !isNaN(entidade)
                ? +entidade
                : 0;
            } else {
              linha.totalizadores[totalizador] += +registro[totalizador];
            }
          } else {
            if (totalizador["funcao"])
              linha.totalizadores[totalizador["nome"]] += this.processarFuncao(
                totalizador["funcao"],
                registro
              );
            else
              linha.totalizadores[totalizador["nome"]] +=
                +registro[totalizador["nome"]];
          }
        }
      }
      linha.registros.push(registro);
    }
    listaRetorno.push(linha);
    return listaRetorno;
  }

  private trocarGrupo(
    campo: string | string[],
    linha: { grupo: string | {}; totalizadores: {}; registros: any[] },
    registro: {}
  ): boolean {
    if (typeof campo === "string") {
      if (campo.includes(".") && !registro[campo]) {
        let grupo;
        for (const campo2 of campo.split(".")) {
          grupo = !grupo ? registro[campo2] : grupo[campo2];
        }
        return linha.grupo !== grupo;
      } else {
        return linha.grupo !== registro[campo];
      }
    } else {
      let diferente = false;
      for (const chave of campo) {
        if (chave.includes(".") && !registro[chave]) {
          let chave2;
          for (const valor of chave.split(".")) {
            chave2 = !chave2 ? registro[valor] : chave2[valor];
          }

          if (linha.grupo[chave] !== chave2) {
            diferente = true;
          }
        } else if (linha.grupo[chave] !== registro[chave]) {
          diferente = true;
        }
      }
      return diferente;
    }
  }

  public totalizar(lista: {}[], totalizadores: (string | {})[], distinct?: string[]): {} {
    const linha = {};
    for (const totalizador of totalizadores) {
      if (typeof totalizador === "string") {
        linha[totalizador] = 0.0;
      } else {
        linha[totalizador["nome"]] = 0.0;
      }
    }
    let aux: {}[] = [];
    for (const registro of lista) {
      for (const totalizador of totalizadores) {
        if (typeof totalizador === "string") {
          if (totalizador.includes(".") && !registro[totalizador]) {
            let entidade;
            for (const chave of totalizador.split(".")) {
              entidade = entidade ? entidade[chave] : registro[chave];

              if (aux.filter(value => Object.values(value).toString() === Object.values(entidade).toString()).length > 0 && distinct && distinct.includes(chave)) break;

              if (distinct && distinct.includes(chave))
                aux.push(entidade);
            }
            linha[totalizador] += entidade && !isNaN(entidade)
              ? +entidade
              : 0;
          } else {
            linha[totalizador] += registro[totalizador]
              ? +registro[totalizador]
              : 0;
          }
        } else {
          if (totalizador["funcao"])
            linha[totalizador["nome"]] += this.processarFuncao(
              totalizador["funcao"],
              registro
            );
          else linha[totalizador["nome"]] += +registro[totalizador["nome"]];
        }
      }
    }
    return linha;
  }

  retornaValorEntidade(ent: any, coluna: string): any {
    if (!ent || !coluna) {
      return "";
    }
    const retorno: string = ent[coluna];
    try {
      if (coluna.includes(".")) {
        const fields = coluna.split(".");
        for (let i = 0; i < fields.length; i++) {
          ent = ent ? ent[fields[i]] : null;
        }
        return ent ? ent : retorno.replace(/\u00a0/g, " ");
      } else {
        return retorno.replace(/\u00a0/g, " ");
      }
    } catch (e) {
      return retorno;
    }
  }

  /**
   * Formata o valor da célula
   * @param coluna - coluna a ser formatada
   * @param valorColuna - valor da célula
   * @param ent - entidade da linha
   */
  retornaCelula(coluna: Coluna, valorColuna: any, ent: any): string {
    try {
      if (coluna.funcao) {
        const retornoFuncao: number | string = this.processarFuncao(
          coluna,
          ent
        );
        if (typeof retornoFuncao === "number") {
          coluna.alignment = coluna.alignment ? coluna.alignment : "right";
          return this.convertToBrNumber(
            retornoFuncao,
            coluna.decimais ? coluna.decimais : 2
          );
        } else {
          return retornoFuncao;
        }
      } else if (coluna.tipo === "HTML") {
        return this.removerTagsHTML(valorColuna);
        // return htmlToPdfMake(valorColuna).toString();
      } else if (coluna.decimais) {
        return this.convertToBrNumber(valorColuna, coluna.decimais);
      } else if (typeof valorColuna === "number") {
        coluna.alignment = coluna.alignment ? coluna.alignment : "right";
        return valorColuna.toFixed();
      } else if (typeof valorColuna === "boolean") {
        coluna.alignment = coluna.alignment ? coluna.alignment : "center";
        return valorColuna ? "Sim" : "Não";
      } else if (valorColuna && valorColuna instanceof Date) {
        coluna.alignment = coluna.alignment ? coluna.alignment : "center";
        return this.converteDataBR(valorColuna);
      } else if (
        valorColuna &&
        valorColuna.match(/^\d+(\.\d{1,2})?$/) &&
        valorColuna.match(/^\d+(\.\d{1,2})?$/)[1]
      ) {
        coluna.alignment = coluna.alignment ? coluna.alignment : "right";
        return this.convertToBrNumber(
          valorColuna,
          valorColuna.match(/^\d+(\.\d{1,2})?$/)[1].length - 1
        );
      } else if (
        valorColuna &&
        valorColuna.match(/^\d{4}-(\d{2}|\d{1})-(\d{2}|\d{1})$/)
      ) {
        coluna.alignment = coluna.alignment ? coluna.alignment : "center";
        return this.converteDataBR(valorColuna);
      } else if (
        valorColuna &&
        valorColuna.match(
          /^\d{4}-(\d{2}|\d{1})-(\d{2}|\d{1})T\d{2}:\d{2}:\d{2}.\d{3}Z$/
        )
      ) {
        coluna.alignment = coluna.alignment ? coluna.alignment : "center";
        let dt = new DatePipe("pt").transform(
          valorColuna,
          "dd/MM/yyyy HH:mm:ss",
          "GMT"
        );
        if (
          dt.endsWith(" 00:00:00") ||
          dt.endsWith(" 02:00:00") ||
          dt.endsWith(" 03:00:00")
        ) {
          dt = dt.substring(0, dt.length - " 00:00:00".length);
        }
        return dt;
      } else if (coluna.coluna === "especie") {
        return new GlobalService().obterEspecie(valorColuna);
      } else if (coluna.coluna === "tipo_empenho") {
        return new GlobalService().obterTipoEmpenho(valorColuna);
      } else if (coluna.coluna === "prioridade") {
        return new GlobalService().obterPrioridade(valorColuna);
      } else if (coluna.coluna === "ocorrencia") {
        return new GlobalService().obterOcorrenciasCreditos(valorColuna);
      } else if (
        coluna.coluna.includes("cpf") ||
        coluna.coluna.includes("cnpj")
      ) {
        coluna.alignment = coluna.alignment ? coluna.alignment : "center";
        return new CpfPipe().transform(valorColuna, []);
      } else if (
        coluna.coluna.includes("telefone") ||
        coluna.coluna.includes("celular")
      ) {
        return new TelefonePipe().transform(valorColuna, []);
      } else {
        return valorColuna;
      }
    } catch (e) {
      console.log(
        `Não foi possível converter coluna ${coluna.coluna}, retornando valor padrao (${valorColuna})`,
        e
      );
      return valorColuna;
    }
  }

  public processarFuncao(colFuncao: Coluna | any, ent: any, teste?: boolean): number | string {
    let resultado = 0.0;
    let operador = "+";
    let operadorRecursivo = "+";

    // percorre expressoes da coluna
    const exp: any = colFuncao.funcao ? colFuncao.funcao : colFuncao;
    for (const expressao of exp) {
      let valorReal: (
        | number
        | "-"
        | "+"
        | "("
        | ")"
        | "*"
        | "/"
        | string
        | {}
      )[];

      if (expressao.valor) {
        // se usou a expressao 'valor'
        valorReal = expressao.valor;
      } else if (expressao.se) {
        // se usou a expressao 'se'
        // separa a condicao e o valor em um vetor
        const cond = expressao.se.condicao.split("=");
        // caso tenha operadores especiais separa por $
        const fieldCond = cond[0].split("$");
        // verifica se a condicao passada é verdadeira ou falsa
        let testeVerdadeiro = false;
        if (fieldCond.length > 1) {
          switch (fieldCond[1].split("=")[0]) {
            case "gt":
              testeVerdadeiro =
                +ent[fieldCond[0]] >
                +(cond[1].endsWith("$ent")
                  ? ent[cond[1].replace("$ent", "")]
                  : cond[1]);
              break;
            case "ge":
              testeVerdadeiro =
                +ent[fieldCond[0]] >=
                +(cond[1].endsWith("$ent")
                  ? ent[cond[1].replace("$ent", "")]
                  : cond[1]);
              break;
            case "lt":
              testeVerdadeiro =
                +ent[fieldCond[0]] <
                +(cond[1].endsWith("$ent")
                  ? ent[cond[1].replace("$ent", "")]
                  : cond[1]);
              break;
            case "le":
              testeVerdadeiro =
                +ent[fieldCond[0]] <=
                +(cond[1].endsWith("$ent")
                  ? ent[cond[1].replace("$ent", "")]
                  : cond[1]);
              break;
            case "ne":
              testeVerdadeiro =
                ent[fieldCond[0]] !==
                (cond[1].endsWith("$ent")
                  ? ent[cond[1].replace("$ent", "")]
                  : cond[1]);
              break;
            default:
              testeVerdadeiro =
                ent[fieldCond[0]] ===
                (cond[1].endsWith("$ent")
                  ? ent[cond[1].replace("$ent", "")]
                  : cond[1]);
              break;
          }
        } else {
          let entFinal = ent;
          let condFinal = cond[0];
          while (condFinal.includes('.')) {
            entFinal = ent[condFinal.split('.')[0]];
            condFinal = condFinal.split('.')[1];
          }
          testeVerdadeiro =
            String(entFinal[condFinal]) ===
            (cond[1].endsWith("$ent")
              ? ent[cond[1].replace("$ent", "")]
              : cond[1]);
        }
        // armazena o valor caso a condicao seja verdadeira ou falsa
        valorReal = testeVerdadeiro
          ? expressao.se.verdadeiro
          : expressao.se.falso;
      }

      // percorre o valor para executar a fórmula
      let resultadoRecursivo = -1;
      for (const valor of valorReal) {
        if (valor === "(") {
          resultadoRecursivo = resultado;
          resultado = 0;
          continue;
        }
        if (resultadoRecursivo >= 0 && valor === ")") {
          switch (operadorRecursivo) {
            case "+":
              resultado += resultadoRecursivo;
              break;
            case "-":
              resultado -= resultadoRecursivo;
              break;
            case "*":
              resultado *= resultadoRecursivo;
              break;
            case "/":
              resultado /= resultadoRecursivo;
              break;
          }
          resultadoRecursivo = -1;
          continue;
        }
        // caso o valor contenha apostrofe será considerado um texto e retornará o texto sem apostrofe
        if (String(valor).match(/\'/)) {
          return String(valor).split("'").join("");
        }

        if (valor[0] && valor[0]["se"]) {
          return this.processarFuncao(valor, ent);
        }
        // inicia variavel como number ou boolean para saber se é um operador ou numero
        let numero: number | boolean = false;
        if (typeof valor === "number") {
          // se for number armazena o numero explicito
          numero = valor;
        } else if (typeof valor === "string") {
          const obj = valor.split('.');
          let aux = ent[obj[0]];
          for (let i = 1; i < obj.length; i++) {
            aux = aux[obj[i]];
          }
          if (aux) {
            // tenta pegar o valor por nome de coluna ent[<coluna>] para calcular
            numero = +aux;
          } else {
            // caso contrário armazena o operador
            operador = valor;
            operadorRecursivo =
              resultadoRecursivo >= 0 ? operadorRecursivo : operador;
          }
        }
        // caso nao seja um operador executa a fórmula de acordo com o operador
        if (typeof numero === "number") {
          switch (operador) {
            case "+":
              resultado += numero;
              break;
            case "-":
              resultado -= numero;
              break;
            case "*":
              resultado *= numero;
              break;
            case "/":
              resultado /= numero;
              break;
          }
        }
      }
    }
    if (colFuncao.funcao) {
      ent[colFuncao.coluna] = resultado;
    }
    return resultado;
  }

  public removerAcentos(texto: string): string {
    if (!texto) {
      return "";
    }
    return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  public removerPontos(texto: string): string {
    if (!texto) {
      return "";
    }
    return texto
      .split(".")
      .join("")
      .split(",")
      .join("")
      .split(":")
      .join("")
      .split(";")
      .join("")
      .split("/")
      .join("")
      .split("-")
      .join("");
  }

  public obterObjetoPorChave(list: any[], id: number | string) {
    return list.find((t) => t.id === id);
  }

  public obterNomePorChave(list: any[], id: number | string): string {
    const obj = list.find((t) => t.id === id);
    return obj ? obj.nome : "";
  }

  public formatXml(xml: string) {
    // tab = optional indent value, default is tab (\t)
    var reg = /(>)\s*(<)(\/*)/g; // updated Mar 30, 2015
    var wsexp = / *(.*) +\n/g;
    var contexp = /(<.+>)(.+\n)/g;
    xml = xml
      .replace(reg, "$1\n$2$3")
      .replace(wsexp, "$1\n")
      .replace(contexp, "$1\n$2");
    var pad = 0;
    var formatted = "";
    var lines = xml.split("\n");
    var indent = 0;
    var lastType = "other";
    // 4 types of tags - single, closing, opening, other (text, doctype, comment) - 4*4 = 16 transitions
    var transitions = {
      "single->single": 0,
      "single->closing": -1,
      "single->opening": 0,
      "single->other": 0,
      "closing->single": 0,
      "closing->closing": -1,
      "closing->opening": 0,
      "closing->other": 0,
      "opening->single": 1,
      "opening->closing": 0,
      "opening->opening": 1,
      "opening->other": 1,
      "other->single": 0,
      "other->closing": -1,
      "other->opening": 0,
      "other->other": 0,
    };

    for (var i = 0; i < lines.length; i++) {
      var ln = lines[i];

      // Luca Viggiani 2017-07-03: handle optional <?xml ... ?> declaration
      if (ln.match(/\s*<\?xml/)) {
        formatted += ln + "\n";
        continue;
      }
      // ---

      var single = Boolean(ln.match(/<.+\/>/)); // is this line a single tag? ex. <br />
      var closing = Boolean(ln.match(/<\/.+>/)); // is this a closing tag? ex. </a>
      var opening = Boolean(ln.match(/<[^!].*>/)); // is this even a tag (that's not <!something>)
      var type = single
        ? "single"
        : closing
          ? "closing"
          : opening
            ? "opening"
            : "other";
      var fromTo = lastType + "->" + type;
      lastType = type;
      var padding = "";

      indent += transitions[fromTo];
      for (var j = 0; j < indent; j++) {
        padding += "\t";
      }
      if (fromTo == "opening->closing")
        formatted = formatted.substr(0, formatted.length - 1) + ln + "\n";
      // substr removes line break (\n) from prev loop
      else formatted += padding + ln + "\n";
    }

    return formatted;
  }

  public converterObjParaXML(obj: any) {
    let xml = "";
    // tslint:disable-next-line: forin
    for (const prop in obj) {
      xml += obj[prop] instanceof Array ? "" : "<" + prop + ">";
      if (obj[prop] instanceof Array) {
        // tslint:disable-next-line: forin
        for (const array in obj[prop]) {
          xml += "<" + prop + ">";
          xml += this.converterObjParaXML(new Object(obj[prop][array]));
          xml += "</" + prop + ">";
        }
      } else if (typeof obj[prop] === "object") {
        xml += this.converterObjParaXML(new Object(obj[prop]));
      } else {
        xml += obj[prop];
      }
      xml += obj[prop] instanceof Array ? "" : "</" + prop + ">";
    }
    xml = xml.replace(/<\/?[0-9]{1,}>/g, "");
    return xml;
  }

  public isJSON(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  public removerTagsHTML(str: string): string {
    if (!str) {
      return "";
    }
    return str.split(/<.+?>/).join("");
  }

  public obterDataUTC(date: Date | string) {
    const dt: Date = date instanceof Date ? date : new Date(date);
    return new Date(
      Date.UTC(
        dt.getFullYear(),
        dt.getMonth(),
        dt.getDate(),
        dt.getHours(),
        dt.getMinutes(),
        dt.getSeconds(),
        dt.getMilliseconds()
      )
    );
  }

  public retornarDiaDoAno(date: Date): number {
    const inicioAno = new Date(date.getFullYear(), 0, 0);
    const dif = date.getTime() - inicioAno.getTime();
    const umDia = 1000 * 60 * 60 * 24;
    return Math.floor(dif / umDia);
  }

  public abreviarStr(str: string, n: number, ret?: boolean): string {
    if (!str || !n) {
      return "";
    }
    let retorno = str;
    retorno = retorno.trim();
    if (retorno.length > n) {
      retorno = retorno.substr(0, n);
      if (ret) {
        retorno = retorno.substr(0, n - 3).concat("...");
      }
    }
    return retorno;
  }

  public focarCampo(
    input: ElementRef | AutoComplete | Calendar | InputMask,
    select?: boolean
  ): void {
    if (input instanceof ElementRef) {
      if (input["el"]) {
        setTimeout(() => (input["el"] as ElementRef).nativeElement.select());
      } else {
        if (select)
          setTimeout(() => (input as ElementRef).nativeElement.select());
        else setTimeout(() => (input as ElementRef).nativeElement.focus());
      }
    } else if (input instanceof AutoComplete) {
      setTimeout(() => (input as AutoComplete).focusInput());
    } else if (input instanceof InputMask) {
      setTimeout(() => (input as InputMask).focus());
    } else {
      console.log("Campo informado para foco não é válido", input);
    }
  }

  public campoJsonToken(token: string, campo: string) {
    if (!token) {
      return null;
    }
    const tokenJson = jwt_decode(token) as any;

    return tokenJson[campo];
  }

  public exportar(
    formato: FormatoExportacao,
    lista: any[],
    nome: string,
    colunas: Coluna[]
  ) {
    const listaItens = new Array();

    if (formato === "xlsx") {
      for (const item of lista) {
        const model = {};
        for (const col of colunas) {
          let valor = this.retornaCelula(
            col,
            this.retornaValorEntidade(item, col.coluna),
            item
          )
          if (!valor) {
            valor = "";
          }
          valor = valor.replace((col.removeNegativo ? '-' : ''), '');

          model[col.titulo] = valor ? valor : "";
        }
        listaItens.push(model);
      }
      tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile(nome);
    } else if (formato === "xml") {
      const x2js = new X2JS({});
      for (const item of lista) {
        const model = {};
        for (const col of colunas) {
          model[
            this.removerPontos(
              this.removerAcentos(col.titulo.split(" ").join(""))
            )
          ] = this.retornaCelula(
            col,
            this.retornaValorEntidade(item, col.coluna),
            item
          );
        }
        listaItens.push(model);
      }
      const xmlDocStr = x2js.js2xml({
        registros: {
          item: listaItens,
        },
      });
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/xml;charset=utf-8," +
        encodeURIComponent(this.formatXml(xmlDocStr))
      );
      element.setAttribute("download", nome);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } else if (formato === "JSON") {
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:application/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(lista))
      );
      element.setAttribute("download", `${nome}`);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } else if (formato === "csv") {
      let arquivo = "";
      for (const col of colunas) {
        arquivo += col.titulo + ";";
      }
      arquivo += "\n";
      for (const item of lista) {
        for (const col of colunas) {
          let valor = this.retornaCelula(
            col,
            this.retornaValorEntidade(item, col.coluna),
            item
          );
          if (!valor) {
            valor = "";
          }
          valor = valor.replace((col.removeNegativo ? '-' : ''), '');
          if (valor?.includes(";")) {
            valor = valor.split(";").join("");
          }
          arquivo += String(valor) + ";";
        }
        arquivo += "\n";
      }

      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + arquivo)
      );
      element.setAttribute("download", `${nome}.csv`);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } else if (formato === "txt") {
      let arquivo = "";
      for (const col of colunas) {
        arquivo += col.titulo + "|";
      }
      arquivo += "\n";
      for (const item of lista) {
        for (const col of colunas) {
          let valor = this.retornaCelula(
            col,
            this.retornaValorEntidade(item, col.coluna),
            item
          );
          if (valor?.includes("|")) {
            valor = valor.split("|").join("");
          }
          arquivo += String(valor) + "|";
        }
        arquivo += "\n";
      }

      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(arquivo)
      );
      element.setAttribute("download", `${nome}.txt`);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } else if (formato === "docx") {
      const paragrafos: Paragraph[] = [];
      for (const item of lista) {
        for (const col of colunas) {
          const parag = new Paragraph({
            children: [
              new TextRun({
                text:
                  String(
                    col.titulo.endsWith(":") ? col.titulo : col.titulo + ":"
                  ) + "\t",
                bold: true,
              }),
              new TextRun({
                text: this.retornaCelula(
                  col,
                  this.retornaValorEntidade(item, col.coluna),
                  item
                ),
              }),
            ],
          });
          paragrafos.push(parag);
        }
        paragrafos.push(new Paragraph({}));
      }
      const doc = new Document({
        sections: [
          {
            properties: {},
            children: paragrafos,
          },
        ],
      });

      Packer.toBlob(doc).then((blob) => {
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadURL;
        link.download = `${nome}.docx`;
        link.target = "_blank";
        link.click();
        window.URL.revokeObjectURL(downloadURL);
      });
    }
  }

  public async obterBase64(caminhoImg: string): Promise<string> {
    // obtem uri absoluta por caminho relativo
    let uri: string;
    if (caminhoImg.indexOf("http") == 0) {
      uri = caminhoImg;
    } else {
      var rootPath =
        window.location.protocol + "//" + window.location.host + "/";
      var path = window.location.pathname;
      if (path.indexOf("/") == 0) {
        path = path.substring(1);
      }
      let path2 = path.split("/", 1);
      if (path2.length > 0) {
        rootPath = rootPath + path + "/";
      }
      uri = rootPath + caminhoImg;
    }

    // envia objeto por caminho absoluto e retorna blob para ser convertido em base64
    return await new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", uri, true);
      xhr.responseType = "blob";
      xhr.onload = function (e) {
        var reader = new FileReader();
        reader.onload = function (event) {
          var res = event.target.result;
          resolve(res as string);
        };
        var file = this.response;
        reader.readAsDataURL(file);
      };
      xhr.send();
    });
  }

  public acaoErro(error: any) {
    toastr.options.timeOut = 10000;
    toastr.options.closeButton = true;
    toastr.options.tapToDismiss = false;
    if (!error.error && error.message) {
      toastr.error(error.message);
    } else if (error.error && error.error.payload) {
      toastr.error(error.error.payload);
    } else {
      toastr.error("Ocorreu um erro ao processar a sua solicitação");
    }
  }

  public obterParametros(filtros: Filtro, busca: string): {} {
    const parametros = {};
    if (filtros && busca) {
      busca = busca.trim();
      if (
        new FuncaoService().isNumber(
          busca
            .split(".")
            .join("")
            .split(",")
            .join(".")
            .split("-")
            .join("")
            .split("/")
            .join("")
            .split("(")
            .join("")
            .split(")")
            .join("")
        ) &&
        !busca.match(/^\d{2}\/\d{2}\/\d{2}$/) &&
        !busca.match(/^\d{2}\/\d{2}\/\d{4}$/)
      ) {
        // caso a pesquisa seja de um número
        if (filtros.number) {
          let filtroNumber: number | string = busca
            .split(".")
            .join("")
            .split(",")
            .join(".")
            .split("-")
            .join("")
            .split("(")
            .join("")
            .split(")")
            .join("");
          if (busca.startsWith("0") && +filtroNumber > 0) {
            filtroNumber = +busca
              .split(".")
              .join("")
              .split(",")
              .join(".")
              .split("-")
              .join("");
          }
          let filtroNumberOR: string[] = [];
          for (const f of filtros.number) {
            filtroNumberOR = filtroNumberOR.concat(
              f.concat("$like=").concat(String(filtroNumber + "%"))
            );
            filtroNumberOR = filtroNumberOR.concat(
              f
                .concat("$like=")
                .concat(String(String(filtroNumber).split("/").join("") + "%"))
            );
          }
          parametros["OR"] = filtroNumberOR.join(";!;!;");
        }

        if (filtros.text && busca.startsWith("0")) {
          let filtroSemAcentos = new FuncaoService().removerAcentos(busca);

          if (
            new FuncaoService().isNumber(
              busca.split(".").join("").split(",").join(".").split("-").join("")
            )
          ) {
            // caso a pesquisa seja de um número
            filtroSemAcentos = busca
              .split(".")
              .join("")
              .split(",")
              .join(".")
              .split("-")
              .join("");
          }

          let filtroTextOR: string[] = [];
          for (const f of filtros.text) {
            filtroTextOR = filtroTextOR.concat(
              f.concat("$like=%").concat(filtroSemAcentos).concat("%")
            );
            filtroTextOR = filtroTextOR.concat(
              f
                .concat("$like=%")
                .concat(filtroSemAcentos.split("/").join(""))
                .concat("%")
            );
          }
          if (parametros["OR"]) {
            parametros["OR"] += ";!;!;" + filtroTextOR.join(";!;!;");
          } else {
            parametros["OR"] = filtroTextOR.join(";!;!;");
          }
        } else if (filtros.text) {
          let filtroSemAcentos = new FuncaoService()
            .removerAcentos(busca)
            .split(".")
            .join("")
            .split(",")
            .join(".")
            .split("-")
            .join("");
          // caso exista apenas um campo de filtro inclui como parametro normal, caso contrário inclui como `OR`

          let filtroTextOR: string[] = [];
          for (const f of filtros.text) {
            filtroTextOR = filtroTextOR.concat(
              f.concat("$like=%").concat(filtroSemAcentos).concat("%")
            );
          }
          if (parametros["OR"]) {
            parametros["OR"] += ";!;!;" + filtroTextOR.join(";!;!;");
          } else {
            parametros["OR"] = filtroTextOR.join(";!;!;");
          }
        }
      } else if (
        busca.match(/^\d{2}\/\d{2}\/\d{2}$/) ||
        busca.match(/^\d{2}\/\d{2}\/\d{4}$/)
      ) {
        // caso a pesquisa seja de uma data
        if (busca.match(/^\d{2}\/\d{2}\/\d{2}$/)) {
          const dtSplit = busca.split("/");
          busca = `${dtSplit[0]}/${dtSplit[1]}/20${dtSplit[2]}`;
        }
        if (filtros.date) {
          // caso exista apenas um campo de filtro inclui como parametro normal, caso contrário inclui como `OR`
          if (filtros.date.length === 1) {
            parametros[filtros.date[0]] = busca;
          } else {
            let filtroDateOR: string[] = [];
            for (const f of filtros.date) {
              filtroDateOR = filtroDateOR.concat(f.concat("=").concat(busca));
            }
            parametros["OR"] = filtroDateOR.join(";!;!;");
          }
        }
      } else {
        // caso a pesquisa seja de um texto
        if (filtros.text) {
          let filtroSemAcentos = new FuncaoService().removerAcentos(busca);
          // caso exista apenas um campo de filtro inclui como parametro normal, caso contrário inclui como `OR`
          if (filtros.text.length === 1) {
            parametros[filtros.text[0].concat("$like")] = "%"
              .concat(filtroSemAcentos)
              .concat("%");
          } else {
            let filtroTextOR: string[] = [];
            for (const f of filtros.text) {
              filtroTextOR = filtroTextOR.concat(
                f.concat("$like=%").concat(filtroSemAcentos).concat("%")
              );
            }
            parametros["OR"] = filtroTextOR.join(";!;!;");
          }
        }
      }
    }

    // retorna parâmetros
    return parametros;
  }

  public podeIncluir(login: Login, url: string, visualizar?: boolean) {
    if (login) {
      if (this.campoJsonToken(login.token, "administrador") == true) {
        return true;
      }
      if (url.match(GlobalService.TRANSPARENCIA_REGEXP)) {
        // urls do transparencia
        return true;
      }
      // url = url.replace('/solicitacao', '-solicitacao')
      if (
        url.lastIndexOf("/") > 0 &&
        (url.includes("editar") ||
          url.includes("visualizar") ||
          url.includes("novo"))
      ) {
        const idx = url.indexOf(
          "/".concat(
            url.includes("editar")
              ? "editar"
              : url.includes("visualizar")
                ? "visualizar"
                : "novo"
          )
        );
        url = url.substring(0, idx);
        if (url.substring(1, url.length).lastIndexOf("/") > 0) {
          url = url.substring(
            0,
            url.substring(1, url.length).lastIndexOf("/") + 1
          );
        }
      }
      for (const acesso of login.acessos) {
        if (url === acesso.pagina) {
          return acesso.permissao === (visualizar ? 1 : 2);
        }
      }
    }
    return false;
  }

  public traduzirRecorrencia(recorrencia: string): string {
    if (!recorrencia) return ''

    const parts: string[] = recorrencia.split(' ')
    if (parts.length < 5) return recorrencia

    const adicional: number = parts.length > 5 ? 1 : 0 // parametro opcional para segundos (primeiro parametro)

    const dia_semana = parts[4 + adicional] //day of week (0 - 7) (0 or 7 is Sun)
    const mes = parts[3 + adicional] //month (1 - 12)
    const dia_mes = parts[2 + adicional] //day of month (1 - 31)
    const hora = parts[1 + adicional] //hour (0 - 23)
    const minuto = parts[0 + adicional] //minute (0 - 59)
    const segundo = adicional === 1 ? parts[0] : undefined //second (0 - 59, OPTIONAL)

    let retorno = '';
    retorno += this.tratarRecorrencia(dia_semana, 'dia da semana')
    retorno += this.tratarRecorrencia(mes, 'mês')
    retorno += this.tratarRecorrencia(dia_mes, 'dia do mês')
    retorno += this.tratarRecorrencia(hora, 'hora', true)
    retorno += this.tratarRecorrencia(minuto, 'minuto')
    retorno += this.tratarRecorrencia(segundo, 'segundo')
    return this.capitalizeFirstLetter(retorno, true)
  }

  public arredondar(valor: number, casasDecimais: number): number {
    if (!valor) {
      return 0;
    } else if (valor % 1 == 0) {//Não tem decimais
      return valor;
    } else {
      const mult = +this.strChar('1', casasDecimais + 1, '0');
      const num = valor.toString();
      //Se a quantidade de decimais for a mesma que a quantidade desejada, retorna o valor;
      if (num.split('.')[1].length <= casasDecimais) {
        return valor;
      }
      if (num[num.length - 1] == '5') { //Quando o final vem em 5 exato, as vezes ele não considera para mais se não bater +3 decimais
        valor = +(num.slice(0, num.length - 1) + '6');
      }
      return Math.round((valor + Number.EPSILON) * mult) / mult;
    }
  }

  private tratarRecorrencia(valor: string, nome: string, feminino?: boolean): string {
    if (!valor || !nome) return ''
    const artigo = feminino ? 'a' : 'o'

    if (valor === '*')
      return ''//`tod${artigo} ${nome}\n`
    if (this.isNumber(valor))
      return `n${artigo} ${nome} ${+valor}\n`
    if (valor.includes('/'))
      return `a cada ${valor.split('/')[1]} ${nome}s\n`

    return valor
  }

  public obterProximaRecorrencia(ultima_exec: Date, recorrencia: string): Date {
    if (!recorrencia || !ultima_exec) return undefined

    const parts: string[] = recorrencia.split(' ')
    if (parts.length < 5) return undefined

    const adicional: number = parts.length > 5 ? 1 : 0 // parametro opcional para segundos (primeiro parametro)

    const dia_semana = parts[4 + adicional] //day of week (0 - 7) (0 or 7 is Sun)
    const mes = parts[3 + adicional] //month (1 - 12)
    const dia_mes = parts[2 + adicional] //day of month (1 - 31)
    const hora = parts[1 + adicional] //hour (0 - 23)
    const minuto = parts[0 + adicional] //minute (0 - 59)
    const segundo = adicional === 1 ? parts[0] : undefined //second (0 - 59, OPTIONAL)

    let retorno: Date = new Date(ultima_exec)
    // retorno = this.calcularRecorrencia(retorno, dia_semana, 1000 * 60 * 60)
    // retorno = this.calcularRecorrencia(retorno, mes, 1000 * 60 * 60 * 24)
    // retorno = this.calcularRecorrencia(retorno, dia_mes, 1000 * 60 * 60 * 24 *
    //   (new Date().getDate() > +dia_mes ? this.ultimoDiaMes(new Date().getMonth() + 1) + +dia_mes : new Date().getDate() - +dia_mes)
    // )
    retorno = this.calcularRecorrencia(retorno, hora, 1000 * 60 * 60)
    retorno = this.calcularRecorrencia(retorno, minuto, 1000 * 60)
    retorno = this.calcularRecorrencia(retorno, segundo, 1000)
    return retorno;
  }

  private calcularRecorrencia(data: Date, valor: string, ms: number): Date {
    if (!data || !ms) return undefined

    if (valor && valor !== '*') {
      if (this.isNumber(valor))
        data = new Date(data.getTime() + (+valor * ms))
      if (valor.includes('/'))
        data = new Date(data.getTime() + (+valor.split('/')[1] * ms))
    }

    return data
  }

  buscarDescServicoReinf(codigo: number) {
    switch (codigo) {
      case 100000001: return 'Limpeza, conservação ou zeladoria';
      case 100000002: return 'Vigilância ou segurança';
      case 100000003: return 'Construção civil';
      case 100000004: return 'Serviços de natureza rural';
      case 100000005: return 'Digitação';
      case 100000006: return 'Preparação de dados para processamento';
      case 100000007: return 'Acabamento';
      case 100000008: return 'Embalagem';
      case 100000009: return 'Acondicionamento';
      case 100000010: return 'Cobrança';
      case 100000011: return 'Coleta ou reciclagem de lixo ou de resíduos';
      case 100000012: return 'Copa';
      case 100000013: return 'Hotelaria';
      case 100000014: return 'Corte ou ligação de serviços públicos';
      case 100000015: return 'Distribuição';
      case 100000016: return 'Treinamento e ensino';
      case 100000017: return 'Entrega de contas e de documentos';
      case 100000018: return 'Ligação de medidores';
      case 100000019: return 'Leitura de medidores';
      case 100000020: return 'Manutenção de instalações, de máquinas ou de equipamentos';
      case 100000021: return 'Montagem';
      case 100000022: return 'Operação de máquinas, de equipamentos e de veículos';
      case 100000023: return 'Operação de pedágio ou de terminal de transporte';
      case 100000024: return 'Operação de transporte de passageiros';
      case 100000025: return 'Portaria, recepção ou ascensorista';
      case 100000026: return 'Recepção, triagem ou movimentação de materiais';
      case 100000027: return 'Promoção de vendas ou de eventos';
      case 100000028: return 'Secretaria e expediente';
      case 100000029: return 'Saúde';
      case 100000030: return 'Telefonia ou telemarketing';
      case 100000031: return 'Trabalho temporário na forma da Lei nº 6.019, de janeiro de 1974';
    }
  }

  public copyToClipboard(text: string) {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(text);
    } else {
      this.unsecuredCopyToClipboard(text);
    }
  }

  private unsecuredCopyToClipboard(text: string) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }

    document.body.removeChild(textArea);
  }

  public formartaData(now: Date, separarHoras?: boolean) {
    let year = "" + now.getFullYear();
    let month = "" + (now.getUTCMonth() + 1); if (month.length == 1) { month = "0" + month; }
    let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
    let hour = "" + now.getHours(); if (hour.length == 1) { hour = "0" + hour; }
    let minute = "" + now.getMinutes(); if (minute.length == 1) { minute = "0" + minute; }
    let second = "" + now.getSeconds(); if (second.length == 1) { second = "0" + second; }
    return year + "-" + month + "-" + day + (separarHoras ? "-" : " ") + hour + "" + minute + "" + second;
  }

  public obterNomeSistema(sistema: string) {
    switch (sistema) {
      case 'C':
        return 'COMPENSAÇÃO';
      case 'F':
        return 'FINANCEIRO';
      case 'O':
        return 'ORÇAMENTÁRIO';
      case 'P':
        return 'PATRIMONIAL';
      default:
        return '';
    }
  }

  public converterDataSQL(dataStr: Date | string) {
    if (dataStr instanceof Date) {
      return dataStr?.toISOString()?.slice(0, 10);
    } else {
      return dataStr?.slice(0, 10);
    }
  }
}

export class Mensagem {
  static sucesso(msgService: MessageService, mensagem: string): void;
  static sucesso(
    msgService: MessageService,
    mensagem: string,
    titulo: string
  ): void;
  static sucesso(
    msgService: MessageService,
    mensagem: string,
    titulo: string,
    life: number
  ): void;
  static sucesso(
    msgService: MessageService,
    mensagem: string,
    titulo?: string,
    life?: number
  ): void {
    this.criarMensagem(msgService, "success", titulo, mensagem, life);
  }

  static info(msgService: MessageService, mensagem: string): void;
  static info(
    msgService: MessageService,
    mensagem: string,
    titulo: string
  ): void;
  static info(
    msgService: MessageService,
    mensagem: string,
    titulo: string,
    life: number
  ): void;
  static info(
    msgService: MessageService,
    mensagem: string,
    titulo?: string,
    life?: number
  ): void {
    this.criarMensagem(
      msgService,
      "info",
      titulo ? titulo : "Informação!",
      mensagem,
      life
    );
  }

  static alerta(msgService: MessageService, mensagem: string): void;
  static alerta(
    msgService: MessageService,
    mensagem: string,
    titulo: string
  ): void;
  static alerta(
    msgService: MessageService,
    mensagem: string,
    titulo: string,
    life: number
  ): void;
  static alerta(
    msgService: MessageService,
    mensagem: string,
    titulo?: string,
    life?: number
  ): void {
    this.criarMensagem(
      msgService,
      "warn",
      titulo ? titulo : "Atenção!",
      mensagem,
      life
    );
  }

  static erro(msgService: MessageService, mensagem: string): void;
  static erro(
    msgService: MessageService,
    mensagem: string,
    titulo: string
  ): void;
  static erro(
    msgService: MessageService,
    mensagem: string,
    titulo: string,
    life: number
  ): void;
  static erro(
    msgService: MessageService,
    mensagem: string,
    titulo?: string,
    life?: number
  ): void {
    this.criarMensagem(
      msgService,
      "error",
      titulo ? titulo : "Atenção!",
      mensagem,
      life
    );
  }

  private static criarMensagem(
    msgService: MessageService,
    severity: "success" | "info" | "warn" | "error",
    summary: string,
    detail: string,
    life?: number
  ): void {
    msgService.add({
      severity: severity,
      summary: summary,
      detail: detail,
      life: life ? life : 5000,
    });
  }
}
