import { HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ConvenioSituacao } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConvenioSituacaoService extends BaseResourceService<ConvenioSituacao> {

  constructor(
    protected injector: Injector
  ) {
    super('convenio-situacoes', injector);
  }

  public obterSomaPagamentosConvenio(convenio: number): Observable<number> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/obter-soma-pagamentos-convenio?convenio_id=${convenio}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  importarPrestacaoConta(convenio_id: number, exercicio_id: number, orgao_id: number, usuario_id: number,
    data_prestacao: Date, data_contabilizacao: Date): Observable<any> {
    const dados = { convenio_id, exercicio_id, orgao_id, usuario_id, data_prestacao, data_contabilizacao };
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/importar-prestacao-contas`, JSON.stringify(dados), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }
}
