import { Audesp4LicitanteConvite, Audesp4LicitanteLista, Audesp4PreQualificacaoSim } from "./comum-type"

export type Audesp4Concorrencia = {
    ModalidadeLicitacao: 1;
    NaturezaLicitacao: number;
    PreQualificacaoNao: 'S' | 'N';
    PreQualificacaoSim?: Audesp4PreQualificacaoSim;
    Licitante?: Audesp4LicitanteLista;
}

export type Audesp4ConcorrenciaInternacional = {
    ModalidadeLicitacao: 2;
}

export type Audesp4TomadaPreco = {
    ModalidadeLicitacao: 3;
    Licitante?: Audesp4LicitanteLista;
}

export type Audesp4Convite = {
    ModalidadeLicitacao: 4;
    Licitante?: Audesp4LicitanteConvite;
}

export type Audesp4PregaoEletronico = {
    ModalidadeLicitacao: 5;
    NaturezaLicitacao: number;
    Licitante?: Audesp4LicitanteLista;
}

export type Audesp4PregaoPresencial = {
    ModalidadeLicitacao: 6;
    NaturezaLicitacao: number;
    Licitante?: Audesp4LicitanteLista;
}

export type Audesp4ContrratacaoRDC = {
    ModalidadeLicitacao: 7;
    Licitante?: Audesp4LicitanteLista;
}

export type Audesp4ContratacaoDiretaDispensaLicitacao = {
    ModalidadeLicitacao: 10;
    FundamentoLei8666Art24?: number;
    FundamentoLei13303Art29?: number;
}

export type Audesp4ContratacaoDiretaInexigibilidadeLicitacao = {
    ModalidadeLicitacao: 11;
    FundamentoLei8666Art25?: number;
    FundamentoLei13303Art30?: number;
}

export type Audesp4Outras = {
    ModalidadeLicitacao: 13;
    ModalidadeLicitacaoOutros: string;
    NaturezaLicitacao: number;
    PreQualificacaoSim?: Audesp4PreQualificacaoSim;
    Licitante?: Audesp4LicitanteLista;
}