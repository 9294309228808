import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DespesaService } from 'administrativo-lib';
import { SubGrupoSubElementoEstoqueService } from 'almoxarifado-lib';
import { BaseResourceItemsComponent, EddyAutoComplete, GrupoEstoque, SubGrupoSubElementoEstoque } from 'eddydata-lib';
import * as toastr from 'toastr';

@Component({
  selector: 'lib-sub-grupo-sub-elemento',
  templateUrl: './sub-grupo-sub-elemento.component.html'
})
export class SubGrupoSubElementoComponent extends BaseResourceItemsComponent<SubGrupoSubElementoEstoque> implements OnChanges {

  public despesaAutoComplete: EddyAutoComplete<SubGrupoSubElementoEstoque>;

  @Input() grupo: GrupoEstoque;

  constructor(
    protected subGrupoSubElementoService: SubGrupoSubElementoEstoqueService,
    protected despesaService: DespesaService) {
    super(new SubGrupoSubElementoEstoque(), subGrupoSubElementoService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.grupo)
      this.carregarAutoCompletes();
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
  }

  protected validSave(item: SubGrupoSubElementoEstoque): boolean {
    if (!item.sub_elemento) {
      toastr.warning('Informe o sub elemento vinculado!');
      return false;
    }
    return true;
  }

  protected beforeSave(item: SubGrupoSubElementoEstoque): void { }

  public disabled(): boolean {
    if (!this.grupo || !this.grupo.id)
      return true;
    return super.disabled();
  }


  private carregarAutoCompletes() {
    if (this.grupo)
    this.despesaAutoComplete = new EddyAutoComplete(null, this.despesaService,
      'id', ['codigo', 'nome'], { 'exercicio.id': this.login.exercicio.id, orderBy: 'codigo', nivel: 6, 'relations': 'exercicio', ativo: true }, { number: ['codigo'], text: ['nome'] }
    );
  }


}
