import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, RequisicaoItemMovimento } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class RequisicaoItemMovimentoService extends BaseResourceService<RequisicaoItemMovimento> {

  constructor(
    protected injector: Injector
  ) {
    super(`requisicao-item-movimento`, injector);
  }
}
