import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'eddydata-lib';
import { FieldsetModule } from 'primeng/fieldset';
import { LicitacaoAudespMenuComponent } from './licitacao-audesp-menu.component';

@NgModule({
    declarations: [LicitacaoAudespMenuComponent],
    imports: [
        CommonModule,
        SharedModule,
        FieldsetModule
    ],
    exports: [LicitacaoAudespMenuComponent]
})
export class LicitacaoAudespMenuModule { }
