import { Component, Injector, Input } from '@angular/core';
import { BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, LoginContabil, Imovel, SituacaoImovel, GlobalService, FuncaoService, EddyAutoComplete, UnidadeService, SetorService } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BemImovelService } from '../service/bem-imovel.service';

@Component({
  selector: 'app-bem-imovel-list',
  templateUrl: './bem-imovel-list.component.html'
})
export class BemImovelListComponent extends BaseResourceListComponent<Imovel, LoginContabil> {
  /**
   * Declaração de variáveis
   */
  public filtroSelecionado: any = 'DESCRICAO';
  public descricaoImovel: string;
  public matricula: string;
  public cep: string;
  public bairro: string;
  public municipio: string;
  public secretaria: string;
  public somenteAtivos: boolean = true;
  public listaStatusPatrimonio: { id: SituacaoImovel, nome: string, estilo: string }[];

  @Input() contabil: boolean = false;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public funcaoService: FuncaoService,
    private unidadeService: UnidadeService,
    private setorService: SetorService,
    private bemImovelService: BemImovelService) {
    super(bemImovelService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'orgao,setor.unidade';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['id$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['id'],
      text: ['descricao', 'matricula', 'cep', 'bairro', 'municipio', 'setor.unidade.nome', 'setor.nome']
    };
  }

  public obterColunasFiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = []

    retorno.push({ titulo: 'Matrícula', coluna: 'matricula', tipo: 'String', cols: 3 })
    retorno.push({ titulo: 'CEP', coluna: 'cep', tipo: 'String', cols: 3 })
    retorno.push({ titulo: 'Endereço', coluna: 'endereco', tipo: 'String', cols: 3 })
    retorno.push({ titulo: 'Bairro', coluna: 'bairro', tipo: 'String', cols: 3 })
    retorno.push({ titulo: 'Município', coluna: 'municipio', tipo: 'String', cols: 3 })

    retorno.push({ titulo: 'Código', coluna: 'id', tipo: 'String', cols: 3, padrao: true })
    retorno.push({ titulo: 'Descrição', coluna: 'descricao', tipo: 'String', cols: 3, padrao: true })
    retorno.push({ titulo: 'Secretaria', coluna: 'setor.unidade.id', tipo: 'AutoComplete', cols: 3, padrao: true, autocomplete: new EddyAutoComplete(null, this.unidadeService, 'id', ['codigo', 'nome'], { 'ppa.id': this.login.ppa.id, relations: 'ppa,orgao', 'ativo': true, 'orgao.id': this.login.orgao.id }, { text: ['codigo', 'nome'] }) })
    retorno.push({ titulo: 'Setor', coluna: 'setor.nome', tipo: 'AutoComplete', cols: 3, padrao: true, autocomplete: new EddyAutoComplete(null, this.setorService, 'nome', ['codigo', 'nome'], { 'orgao.id': this.login.orgao.id, 'ativo': true }, { text: ['codigo', 'nome'] }) })
    retorno.push({
      titulo: 'Situação Ativo', coluna: 'situacao', tipo: 'Boolean', padrao: true, callBack: (coluna) => {
        delete coluna.coluna['situacao']
        return [{ chave: 'situacao$not_in', valor: 'EXCLUIDO' }]
      }
    })

    return retorno
  }

  protected podeAlterar(item: Imovel): boolean{
    return true;
  }

  public beforeInit(): void {
    this.usarFiltroPersonalizado = true
  }

  protected afterInit(): void {
    this.listaStatusPatrimonio = new GlobalService().obterSituacaoImovel();
  }

  protected acaoRemover(model: Imovel): Observable<Imovel> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Id', coluna: 'id' },
      { titulo: 'Descrição', coluna: 'descricao' },
      { titulo: 'Endereço', coluna: 'endereco' },
      { titulo: 'Nº', coluna: 'num_endereco' },
      { titulo: 'Bairro', coluna: 'bairro' },
      { titulo: 'Setor', coluna: 'setor.nome' },
      { titulo: 'Área Construída', coluna: 'area_construcao' },
      { titulo: 'Valor da Construção', coluna: 'valor_construcao' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'orgao,setor.unidade';
    this.bemImovelService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          lista.content.map(element => {
            element.area_construcao = Number(element.area_construcao).toString().replace(/(\d{1,3}|\G\d{3})(?=(?:\d{3})+(?!\d))/g, "$1.");
            element.valor_construcao = Number(element.valor_construcao).toString().replace('.', '|').replace(/(\d{1,3}|\G\d{3})(?=(?:\d{3})+(?!\d))/g, "$1.").replace('|', ',');
          });
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE BENS IMÓVEIS`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem bens imóveis', ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  public limparFiltros(todos: boolean) {
    this.filtroSelecionado = todos ? null : this.filtroSelecionado;
    this.descricaoImovel = null;
  }
}
