import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { ToastModule } from 'primeng/toast';
import { AtaPersonalizadaModule } from '../ata-personalizada/ata-personalizada.module';
import { ModalidadeFormComponent } from './modalidade-form/modalidade-form.component';
import { ModalidadeListComponent } from './modalidade-list/modalidade-list.component';
import { ModalidadeRoutingModule } from './modalidade-routing.module';

@NgModule({
  declarations: [ModalidadeListComponent, ModalidadeFormComponent],
  imports: [
    CommonModule,
    ModalidadeRoutingModule,
    SharedModule,
    FormsModule,
    ToastModule,
    IMaskModule,
    AtaPersonalizadaModule,
  ]
})
export class ModalidadeModule { }
