import { ComissaoMembroAtribuicao, ComissaoMembroNaturezaCargo } from "../../components/types";
import { BaseResourceModel } from "../../models/base-resource.model";
import { Pessoa } from "../comum/pessoa.model";
import { Comissao } from "./comissao.model";

export class ComissaoMembro extends BaseResourceModel {
    constructor(
        public id?: number,
        public pessoa?: Pessoa,
        public atribuicao?: ComissaoMembroAtribuicao,
        public natureza_cargo?: ComissaoMembroNaturezaCargo,
        public ativo?: boolean,
        public comissao?: Comissao,
        public editavel?: boolean,
        public codigo_executante?: string,
        public codigo_perfil?: string,
        public matricula_funcional?: string,
    ) {
        super();
    }

    static converteJson(json: any): ComissaoMembro {
        return Object.assign(new ComissaoMembro(), json);
    }
}
