import { BaseResourceModel } from '../../models/base-resource.model';
import { Cidade } from "../geo/cidade.model";

export class Feriado extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public data_feriado?: Date,
    public cidade?: Cidade,) {
    super();
  }
  static converteJson(json: any): Feriado {
    return Object.assign(new Feriado(), json);
  }
}