import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ContratoStorage } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContratoStorageService extends BaseResourceService<ContratoStorage> {

  constructor(
    protected injector: Injector
  ) {
    super(`contrato-storages`, injector);
  }

  public upload(arquivos: any[], orgao: number, contrato: number, descricao?: string, visivel_transparencia?: boolean): Observable<ContratoStorage[]> {
    const formData = new FormData();
    for (let arquivo of arquivos) {
      formData.append('files', arquivo);
    }
    if (descricao) {
      formData.append('descricao', descricao);
    }
    if (visivel_transparencia)
      formData.append('visivel_transparencia', 'true');
    const headers = this.httpOptions();
    headers.headers = headers.headers.delete('Content-Type');

    return this.http.post<ContratoStorage[]>(`${this.login.cidade.id}/${this.api}/upload/${orgao}/${contrato}`,
      formData, headers)
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
