import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { PessoaModule, SharedModule, UsuarioModule } from 'eddydata-lib';
import { NgChartsModule } from 'ng2-charts';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputMaskModule } from 'primeng/inputmask';
import { ToastModule } from 'primeng/toast';
import { FileUploadModule } from 'primeng/fileupload';
import { CheckboxModule } from 'primeng/checkbox';
import { BemMovelHistoricoDlgModule } from '../bem-movel/bem-movel-historico-dlg/bem-movel-historico-dlg.module';
import { InventarioDlgComponent } from './inventario-dlg/inventario-dlg.component';
import { InventarioListComponent } from './inventario-list/inventario-list.component';
import { InventarioRoutingModule } from './inventario-routing.module';
import { InventarioTermoComponent } from './inventario-termo/inventario-termo.component';
import { InventarioTransferenciaDlgComponent } from './inventario-transferencia-dlg/inventario-transferencia-dlg.component';
import { InventarioTransferenciaSolicitacaoDlgComponent } from './inventario-transferencia-solicitacao-dlg/inventario-transferencia-solicitacao-dlg.component';
import { InventarioTransferenciaComponent } from './inventario-transferencia/inventario-transferencia.component';
import { InventarioStorageComponent } from './inventario-storage/inventario-storage.component';
import { InventarioIdentificacaoComponent } from './inventario-identificacao/inventario-identificacao.component';
import { InventarioIdentificacaoDlgComponent } from './inventario-identificacao-dlg/inventario-identificacao-dlg.component';
import { InventarioRptComponentModule } from '../relatorio-patrimonio/inventario-rpt/inventario-rpt.module';
import { BloqueioInventarioPatrimonioDirective } from './directives/bloqueio-inventario-patrimonio.directive';
import { MensagemInventarioPatrimonioDirective } from './directives/mensagem-inventario-patrimonio.directive';
import { SolicitacaoTransferenciaPendenteRptComponentModule } from '../relatorio-patrimonio/solicitacao-transferencia-pendente-rpt/solicitacao-transferencia-pendente-rpt.module';
import { TransferenciaPendenteRptComponentModule } from '../relatorio-patrimonio/transferencia-pendente-rpt/transferencia-pendente-rpt.module';
import { IdentificacaoRptComponentModule } from '../relatorio-patrimonio/identificacao-rpt/identificacao-rpt.module';
import { InventarioSituacaoRptComponentModule } from '../relatorio-patrimonio/inventario-situacao-rpt/inventario-situacao-rpt.module';
import { InventarioSecretariaRptComponentModule } from '../relatorio-patrimonio/inventario-secretaria-rpt/inventario-secretaria-rpt.module';

@NgModule({
  declarations: [
    InventarioListComponent,
    InventarioDlgComponent,
    InventarioTermoComponent,
    InventarioTransferenciaDlgComponent,
    InventarioTransferenciaSolicitacaoDlgComponent,
    InventarioTransferenciaComponent,
    InventarioIdentificacaoComponent,
    InventarioIdentificacaoDlgComponent,
    InventarioStorageComponent,
    BloqueioInventarioPatrimonioDirective,
    MensagemInventarioPatrimonioDirective
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    InventarioRoutingModule,
    PessoaModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    UsuarioModule,
    InventarioSecretariaRptComponentModule,
    InputMaskModule,
    FileUploadModule,
    NgChartsModule,
    ConfirmDialogModule,
    BemMovelHistoricoDlgModule,
    CheckboxModule,
    InventarioRptComponentModule,
    InventarioSituacaoRptComponentModule,
    SolicitacaoTransferenciaPendenteRptComponentModule,
    TransferenciaPendenteRptComponentModule,
    IdentificacaoRptComponentModule
  ],
  exports: [
    BloqueioInventarioPatrimonioDirective,
    MensagemInventarioPatrimonioDirective
  ],
  providers: [MessageService]
})
export class InventarioModule { }
