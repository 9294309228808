import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MaterialListComponent } from './material-list/material-list.component';
import { MaterialFormComponent } from './material-form/material-form.component';
import { MaterialViewComponent } from './material-view/material-view.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: MaterialListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: MaterialFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: MaterialFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: MaterialViewComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MaterialRoutingModule { }
