import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ReservaDotacao } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ReservaDotacaoService extends BaseResourceService<ReservaDotacao> {

  constructor(
    protected injector: Injector
  ) {
    super(`reservas-dotacao`, injector);
  }
}
