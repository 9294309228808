import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { EmpenhoRestoService, FichaDespesaService } from 'contabil-lib';
import { Convenio, Empenho, EmpenhoResto, FuncaoService, GlobalService, Login } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { tsXLXS } from 'ts-xlsx-export';
import { ConfirmationService } from 'primeng/api';
import { EmpenhoService } from '../../../contabil/empenho/service/empenho.service';

@Component({
  selector: 'lib-convenio-empenho',
  templateUrl: './convenio-empenho.component.html'
})

export class ConvenioEmpenhoComponent implements OnInit, OnChanges, OnDestroy {
  protected unsubscribe: Subject<void> = new Subject();
  public empenhos: Array<Empenho> = [];
  public empenhosResto: Array<EmpenhoResto> = [];
  public listaEmps: Array<any> = [];

  @Input() lista: Array<{ id: number, resto: boolean }>;
  @Input() listaResto: Array<{ id: number, resto: boolean }>;
  @Input() convenio: Convenio;
  @Input() login: Login;

  constructor(
    protected confirmationService: ConfirmationService,
    public globalService: GlobalService,
    protected funcaoService: FuncaoService,
    // protected fichaService: FichaDespesaService,
    protected empenhoService: EmpenhoService,
    protected empenhoRestoService: EmpenhoRestoService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['lista']) {
      const empenhos_ids = this.lista.filter(item => !item.resto).map(item => item.id).join(',');
      if (empenhos_ids) {
        const empenhos = await this.empenhoService.filtrar(1, -1, {
          'id$in': empenhos_ids,
          'ignoreCondObrig': true,
          'relations': 'exercicio,ficha.recurso,ficha.aplicacao,ficha.aplicacao_variavel,subelemento,favorecido'
        }).toPromise();
        this.empenhos.push(...empenhos.content);
      }

      const empenhos_resto_ids = this.listaResto.filter(item => !item.resto).map(item => item.id).join(',');
      if (empenhos_resto_ids) {
        const empenhos_resto = await this.empenhoRestoService.filtrar(1, -1, {
          'id$in': empenhos_resto_ids,
          'ignoreCondObrig': true,
          'relations': 'exercicio,favorecido,orgao'
        }).toPromise();
        this.empenhosResto.push(...empenhos_resto.content);
      }

      //orcamentario
      for (const item of this.empenhos) {
        const entity = {
          impresso: item.impresso,
          tipo: 'EO',
          ano: item.exercicio?.ano,
          data_empenho: item.data_empenho,
          numero: item?.numero,
          ficha_numero: item?.ficha?.numero,
          despesa: item?.subelemento?.codigo,
          recurso: `${item?.ficha?.recurso?.codigo}${item?.ficha?.aplicacao?.codigo}${item?.ficha?.aplicacao_variavel?.variavel}`,
          cpf_cnpj: this.cpfCnpj(item.favorecido?.cpf_cnpj),
          razao_social: item.favorecido?.nome,
          valor_empenhado: item.valor_empenho
        };
        this.listaEmps.push(entity);
      }

      //resto
      for (const item of this.empenhosResto) {
        const entity = {
          impresso: item.impresso,
          tipo: 'ER',
          ano: item.exercicio?.ano,
          data_empenho: item.data_empenho,
          numero: item?.numero,
          ficha_numero: await this.buscarFichaDespesa(item),
          despesa: item?.subelemento,
          // recurso: `${item?.recurso}${item?.aplicacao}${item?.recurso_variavel}`,
          recurso: item?.recurso_variavel,
          cpf_cnpj: this.cpfCnpj(item.favorecido?.cpf_cnpj),
          razao_social: item.favorecido?.nome,
          valor_empenhado: item.valor_empenho
        };
        this.listaEmps.push(entity);
      }
    }
  }

  public async exportarXLSX() {
    const listaItens = new Array();
    for (const item of this.empenhos) {
      const entity = {
        tipo: 'EO',
        ano: item.exercicio?.ano,
        data_empenho: this.funcaoService.converteDataBR(item.data_empenho),
        numero: item?.numero,
        ficha_numero: item?.ficha?.numero,
        despesa: item?.subelemento?.codigo,
        recurso: `${item?.ficha?.recurso?.codigo}${item?.ficha?.aplicacao?.codigo}${item?.ficha?.aplicacao_variavel?.variavel}`,
        cpf_cnpj: item.favorecido?.cpf_cnpj,
        razao_social: item.favorecido?.nome,
        valor_empenhado: item.valor_empenho
      };
      listaItens.push(entity);
    }

    for (const item of this.empenhosResto) {
      const entity = {
        tipo: 'ER',
        ano: item.exercicio?.ano,
        data_empenho: this.funcaoService.converteDataBR(item.data_empenho),
        numero: item?.numero,
        ficha_numero: await this.buscarFichaDespesa(item),
        despesa: item?.subelemento,
        // recurso: `${item?.recurso}${item?.aplicacao}${item?.recurso_variavel}`,
        recurso: item?.recurso_variavel,
        cpf_cnpj: item.favorecido?.cpf_cnpj,
        razao_social: item.favorecido?.nome,
        valor_empenhado: item.valor_empenho
      };
      listaItens.push(entity);
    }

    tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile(`empenhos - ${this.convenio.numero} ${this.convenio.ano}`);
  }

  private cpfCnpj(doc: string) {
    if (doc.length > 11)
      return this.funcaoService.mascarar('##.###.###/####-##', doc);
    else
      return this.funcaoService.mascarar('###.###.###-##', doc);
  }

  public async buscarFichaDespesa(empenhoResto: EmpenhoResto) {
    const empenhoOrc = await this.empenhoService.obter({
      'ignoreCondObrig': true,
      'exercicio.ano' : empenhoResto.ano,
      'numero' : empenhoResto.numero,
      'orgao.id' : empenhoResto.orgao.id,
      'relations': 'ficha'
    }).toPromise()

    return empenhoOrc.ficha ? empenhoOrc.ficha.numero : 'XXXX';
  }
}

