import { InventarioService } from '../service/inventario.service';
import { Directive, ElementRef, OnInit } from '@angular/core';
import { GlobalService, LoginContabil } from 'eddydata-lib';

@Directive({
  selector: '[bloqueioInventarioPatrimonio]'
})
export class BloqueioInventarioPatrimonioDirective implements OnInit {

  private login: LoginContabil;

  constructor(private inventarioService: InventarioService,
    private el: ElementRef) { }

  async ngOnInit(): Promise<void> {
    this.login = GlobalService.obterSessaoLogin();
    let inventario = this.login.orgao.id && this.login.usuario?.setor?.id ? await this.inventarioService.validarMovimentacao(this.login.orgao.id, this.login.usuario.setor.id).toPromise() : 0;

    if (+inventario > 0) {
      this.el.nativeElement.disabled = true
      this.el.nativeElement.classList.add('disabled')
    }
  }

}