import { DatePipe, formatDate } from '@angular/common';
import { Component, Injector, ViewChild } from '@angular/core';
import { ContratoService, EmpenhoService, LicitacaoService, LiquidacaoService, PagamentoService } from 'administrativo-lib';
import { Ajuste, BaseResourceListComponent, Coluna, Compra, Contrato, ContratoAditamento, DateFormatPipe, Empenho, Exercicio, ExercicioService, Filtro, FuncaoService, GlobalService, Licitacao, LicitacaoAudesp, Liquidacao, LoginContabil, Pagamento, ProgressoService, TipoArquivoFase4 } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { CompraService } from '../../compra/service/compra.service';
import { ContratoAditamentoService } from '../../contrato/service/contrato-aditamento.service';
import { LicitacaoAudespService } from '../service/licitacao-audesp.service';
import { merge } from 'jquery';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-list',
  templateUrl: './licitacao-audesp-list.component.html'
})
export class LicitacaoAudespListComponent extends BaseResourceListComponent<any, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @ViewChild('dateFilter') private dateFilter: any;

  public listaTipoFiltro: { id: TipoArquivoFase4 | 'COMPRA_DIRETA' | '*' | '_', nome: string }[];
  public tipoFiltro: TipoArquivoFase4 | 'COMPRA_DIRETA' | '*';
  public filtroData: Date[];
  public opcoesFiltro: { id: '*' | 'S' | 'N', nome: string }[]
  public filtroExportado: '*' | 'S' | 'N';
  public filtroEnviado: '*' | 'S' | 'N';
  public filtroArmazenado: '*' | 'S' | 'N';
  public listaSecundaria: boolean = false;
  public exercicioInicio: Exercicio = new Exercicio();
  public exercicioFinal: Exercicio = new Exercicio();
  public listaExercicios: Array<any>;

  public marcado: boolean = false;
  public listaAudesp: LicitacaoAudesp[];

  public arquivosValidos: boolean = false;
  public somenteValidos: boolean = false;
  public itemInvalido: LicitacaoAudesp;

  public datepipe: DatePipe;

  public mensagemXML: string[];
  public xmlInvalido: string;

  public filtroAjusteContratoEmpenho: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private funcaoService: FuncaoService,
    private licitacaoService: LicitacaoService,
    private contratoService: ContratoService,
    private aditamentoService: ContratoAditamentoService,
    private empenhoService: EmpenhoService,
    private liquidacaoService: LiquidacaoService,
    private pagamentoService: PagamentoService,
    private compraService: CompraService,
    private exercicioService: ExercicioService,
    private progressoService: ProgressoService,
    private audespService: LicitacaoAudespService) {
    super(undefined, injector);
    this.datepipe = new DatePipe('pt');
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    if (this.tipoFiltro === '*') {
      return 'licitacao.modalidade';
    } else if (this.tipoFiltro === 'LIC_REG_SIM' || this.tipoFiltro === 'LIC_REG_NAO1' || this.tipoFiltro === 'LIC_REG_NAO2' || this.tipoFiltro === 'LIC_REG_NAO3') {
      return 'modalidade,licitacao_audesp';
    } else if (this.tipoFiltro === 'AJUSTE_CONTRATO') {
      return 'favorecido,licitacao.licitacao_audesp,licitacao_audesp'
    } else if (this.tipoFiltro === 'TERMO_ADITIVO') {
      return 'contrato.favorecido,contrato.licitacao.licitacao_audesp,contrato.licitacao_audesp,licitacao_audesp'
    } else if (this.tipoFiltro === 'EMPENHO') {
      return 'favorecido,ficha.recurso,contrato.licitacao_audesp,licitacao_audesp'
    } else if (this.tipoFiltro === 'EXIGENCIA_OBRAS') {
      return 'licitacao_audesp,licitacao.modalidade,licitacao.licitacao_audesp,licitacao_audesp_obra'
    } else if (this.tipoFiltro === 'EXECUCAO' && !this.listaSecundaria) { // liquidação
      return 'empenho.favorecido,empenho.contrato.licitacao_audesp,empenho.contrato.licitacao.licitacao_audesp,licitacao_audesp_execucao,empenho.contrato.licitacao.licitacao_audesp'
    } else if (this.tipoFiltro === 'EXECUCAO' && this.listaSecundaria) { // empenho
      return 'favorecido,licitacao_audesp,contrato.licitacao_audesp,contrato.licitacao.licitacao_audesp,licitacao.licitacao_audesp,licitacao_audesp_execucao,contrato.licitacao.licitacao_audesp'
    } else if (this.tipoFiltro === 'DOC_FISCAL') {
      return 'empenho.licitacao_audesp.parente,empenho.licitacao_audesp_execucao.parente,licitacao_audesp.parente,licitacao_audesp_execucao.parente'
    } else if (this.tipoFiltro === 'PAGAMENTO') {
      return 'liquidacao.empenho,liquidacao.licitacao_audesp,licitacao_audesp'
    } else if (this.tipoFiltro === 'AJUSTE_EMPENHO') {
      return 'licitacao.licitacao_audesp.parente,favorecido,contrato,licitacao_audesp.parente'
    } else if (this.tipoFiltro === 'COMPRA_DIRETA') {
      return 'licitacao_audesp,favorecido,requerente'
    } else {
      toastr.error(`Tipo de consulta não disponível (${this.tipoFiltro})`);
    }
    return '';
  }

  protected condicoesGrid(): {} {
    let datas = this.filtroData;
    // if (!datas || datas.length === 0)
    //   datas = [
    //     new DateFormatPipe().transform(this.datepipe.transform(new Date(`01/01/${this.login.exercicio.ano}`), 'yyyy-MM-dd'), []),
    //     new DateFormatPipe().transform(this.datepipe.transform(new Date(`12/31/${this.login.exercicio.ano}`), 'yyyy-MM-dd'), []),
    //   ]

    if (this.tipoFiltro === '*') {
      return Object.assign({}, {
        'licitacao.orgao_id': this.login.orgao.id,
        'tipo$like': 'LIC_REG_%',
        'licitacao.exercicio.ano$ge': this.exercicioInicio.ano,
        'licitacao.exercicio.ano$le': this.exercicioFinal.ano
      }, this.obterFiltroStatus());
    } else if (this.tipoFiltro === 'LIC_REG_SIM' || this.tipoFiltro === 'LIC_REG_NAO1' || this.tipoFiltro === 'LIC_REG_NAO2' || this.tipoFiltro === 'LIC_REG_NAO3') {
      const retorno = {
        orgao_id: this.login.orgao.id,
        'exercicio.ano$ge': this.exercicioInicio.ano,
        'exercicio.ano$le': this.exercicioFinal.ano,
        'OR': `licitacao_audesp.id$null;!;!;licitacao_audesp.tipo=${this.tipoFiltro}`,
        'situacao$in': 'ADJUDICADA,HOMOLOGADA,HOMOLOGADA – PARCIAL,ANULADA,DESERTA,FRACASSADA,REVOGADA'
      };

      if (this.tipoFiltro === 'LIC_REG_NAO1') retorno['modalidade.codigo$not_in'] = '5,6';
      else if (this.tipoFiltro === 'LIC_REG_NAO3') retorno['modalidade.codigo$in'] = '5,6';

      if (datas?.length === 1) {
        const data1 = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
        retorno['OR'] += `;!;!;data_homologacao=${data1};!;!;data_cancelamento=${data1}`;
      } else if (datas?.length === 2) {
        const data1 = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
        const data2 = this.datepipe.transform(datas[1], 'yyyy-MM-dd');
        retorno['OR'] += `;!;!;data_homologacao$in=${data1},${data2};!;!;data_cancelamento$in=${data1},${data2}`;
      }
      return Object.assign({}, retorno, this.obterFiltroStatus());
    } else if (this.tipoFiltro === 'AJUSTE_CONTRATO') {
      const retorno = {
        orgao_id: this.login.orgao.id,
        'OR': `licitacao_audesp.id$null;!;!;licitacao_audesp.tipo=${this.tipoFiltro}`,
        'licitacao.licitacao_audesp.armazenado': true,
        'excluido': false,
        tipo: 'CONTRATO',
        relations: 'favorecido,empenhos',
        'ano$ge': this.exercicioInicio.ano,
        'ano$le': this.exercicioFinal.ano
      };
      if (this.filtroAjusteContratoEmpenho === 'S')
        retorno['empenhos.id$not_null'] = '';

      if (this.filtroAjusteContratoEmpenho === 'N')
        retorno['empenhos.id$null'] = '';

      if (datas?.length === 1) {
        retorno['data_inicio'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
      } else if (datas?.length === 2) {
        retorno['data_inicio$ge'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
        retorno['data_inicio$le'] = this.datepipe.transform(datas[1], 'yyyy-MM-dd');
      }
      return Object.assign({}, retorno, this.obterFiltroStatus());
    } else if (this.tipoFiltro === 'TERMO_ADITIVO') {
      const retorno = { 'contrato.orgao_id': this.login.orgao.id, 'contrato.licitacao.licitacao_audesp.armazenado': true, 'exercicio.ano$ge': this.exercicioInicio.ano, 'exercicio.ano$le': this.exercicioFinal.ano };
      if (datas?.length === 1) {
        retorno['data_inicio'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
      } else if (datas?.length === 2) {
        retorno['data_inicio$ge'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
        retorno['data_inicio$le'] = this.datepipe.transform(datas[1], 'yyyy-MM-dd');
      }
      return Object.assign({}, retorno, this.obterFiltroStatus());
    } else if (this.tipoFiltro === 'EMPENHO') {
      const retorno = { orgao_id: this.login.orgao.id, ignoreCondObrig: true, 'contrato.licitacao_audesp_id$not_null': undefined, 'valor_empenho$gt': 0, 'exercicio.ano$ge': this.exercicioInicio.ano, 'exercicio.ano$le': this.exercicioFinal.ano, 'contrato.licitacao_audesp.armazenado': true };
      retorno['contrato.tipo'] = 'CONTRATO'
      if (datas?.length === 1) {
        retorno['data_empenho'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
      } else if (datas?.length === 2) {
        retorno['data_empenho$ge'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
        retorno['data_empenho$le'] = this.datepipe.transform(datas[1], 'yyyy-MM-dd');
      }
      return Object.assign({}, retorno, this.obterFiltroStatus());
    } else if (this.tipoFiltro === 'EXIGENCIA_OBRAS') {
      const retorno = { orgao_id: this.login.orgao.id, 'licitacao_audesp.armazenado': true, 'licitacao.exercicio.ano$ge': this.exercicioInicio.ano, 'licitacao.exercicio.ano$le': this.exercicioFinal.ano };
      if (datas?.length === 1) {
        retorno['licitacao.data_homologacao'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
      } else if (datas?.length === 2) {
        retorno['licitacao.data_homologacao$ge'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
        retorno['licitacao.data_homologacao$le'] = this.datepipe.transform(datas[1], 'yyyy-MM-dd');
      }
      return Object.assign({}, retorno, this.obterFiltroStatus());
    } else if (this.tipoFiltro === 'EXECUCAO' && !this.listaSecundaria) { // liquidação
      const retorno = { orgao_id: this.login.orgao.id, ignoreCondObrig: true, 'empenho.contrato.licitacao_audesp.armazenado': true, 'valor_liquidado$gt': 0, 'exercicio.ano$ge': this.exercicioInicio.ano, 'exercicio.ano$le': this.exercicioFinal.ano };
      if (datas?.length === 1) {
        retorno['data_liquidacao'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
      } else if (datas?.length === 2) {
        retorno['data_liquidacao$ge'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
        retorno['data_liquidacao$le'] = this.datepipe.transform(datas[1], 'yyyy-MM-dd');
      }
      return Object.assign({}, retorno, this.obterFiltroStatus());
    } else if (this.tipoFiltro === 'EXECUCAO' && this.listaSecundaria) { // empenho
      const retorno = {
        orgao_id: this.login.orgao.id, ignoreCondObrig: true, 'valor_empenho$gt': 0, 'liquidacoes.id$null': undefined,
        'OR': 'licitacao_audesp.id$not_null;!;!;contrato.licitacao_audesp.id$not_null',
        'exercicio.ano$ge': this.exercicioInicio.ano,
        'exercicio.ano$le': this.exercicioFinal.ano
      };
      if (datas?.length === 1) {
        retorno['data_empenho'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
      } else if (datas?.length === 2) {
        retorno['data_empenho$ge'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
        retorno['data_empenho$le'] = this.datepipe.transform(datas[1], 'yyyy-MM-dd');
      }
      return Object.assign({}, retorno, this.obterFiltroStatus());
    } else if (this.tipoFiltro === 'DOC_FISCAL') {
      const retorno = { orgao_id: this.login.orgao.id, ignoreCondObrig: true, 'empenho.licitacao_audesp_execucao_id$not_null': undefined, 'exercicio.ano$ge': this.exercicioInicio.ano, 'exercicio.ano$le': this.exercicioFinal.ano };
      if (datas?.length === 1) {
        retorno['data_liquidacao'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
      } else if (datas?.length === 2) {
        retorno['data_liquidacao$ge'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
        retorno['data_liquidacao$le'] = this.datepipe.transform(datas[1], 'yyyy-MM-dd');
      }
      return Object.assign({}, retorno, this.obterFiltroStatus());
    } else if (this.tipoFiltro === 'PAGAMENTO') {
      const retorno = {
        orgao_id: this.login.orgao.id, ignoreCondObrig: true, 'liquidacao.licitacao_audesp_id$not_null': undefined, 'exercicio.ano$ge': this.exercicioInicio.ano,
        'exercicio.ano$le': this.exercicioFinal.ano
      };
      if (datas?.length === 1) {
        retorno['data_pagamento'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
      } else if (datas?.length === 2) {
        retorno['data_pagamento$ge'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
        retorno['data_pagamento$le'] = this.datepipe.transform(datas[1], 'yyyy-MM-dd');
      }
      return Object.assign({}, retorno, this.obterFiltroStatus());
    } else if (this.tipoFiltro === 'AJUSTE_EMPENHO') {
      const retorno = {
        orgao_id: this.login.orgao.id, ignoreCondObrig: true, 'valor_empenho$gt': 0, 'licitacao.licitacao_audesp_id$not_null': undefined,
        'OR_4': 'contrato.tipo=REGISTRO_PRECO;!;!;contrato_id$null',
        // 'contrato.tipo': 'REGISTRO_PRECO',
        'licitacao.licitacao_audesp.armazenado': true,
        'exercicio.ano$ge': this.exercicioInicio.ano,
        'exercicio.ano$le': this.exercicioFinal.ano
      };
      if (datas?.length === 1) {
        retorno['data_empenho'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
      } else if (datas?.length === 2) {
        retorno['data_empenho$ge'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
        retorno['data_empenho$le'] = this.datepipe.transform(datas[1], 'yyyy-MM-dd');
      }
      return Object.assign({}, retorno, this.obterFiltroStatus());
    } else if (this.tipoFiltro === 'COMPRA_DIRETA') {
      const retorno = {
        orgao_id: this.login.orgao.id,
        exercicio_id: this.login.exercicio.id,
        'modalidade.codigo': 5,
        'exercicio.ano$ge': this.exercicioInicio.ano,
        'exercicio.ano$le': this.exercicioFinal.ano,
      }
      if (this.login.parametro.licitacao.filtrar_ufesp) {
        retorno['total_compra$ge'] = (+this.login.parametro.licitacao.valor_ufesp * +this.login.parametro.licitacao.ufesp)
      }
      if (datas?.length === 1) {
        retorno['data_compra'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
      } else if (datas?.length === 2) {
        retorno['data_compra$ge'] = this.datepipe.transform(datas[0], 'yyyy-MM-dd');
        retorno['data_compra$le'] = this.datepipe.transform(datas[1], 'yyyy-MM-dd');
      }
      return Object.assign({}, retorno, this.obterFiltroStatus());
    } else {
      toastr.error(`Tipo de consulta não disponível (${this.tipoFiltro})`);
    }
    return {};
  }

  protected ordenacaoGrid(): string[] {
    let order = [];
    if (this.colunaOrdenacao != undefined && [true, false].includes(this.ascendente)) {
      const ordem = `${this.colunaOrdenacao}$${this.ascendente ? "ASC" : "DESC"}`
      order.push(ordem);
    }
    if (this.tipoFiltro === '*') {
      order = order.concat(['codigo$DESC']);
    } else if (this.tipoFiltro === 'LIC_REG_SIM' || this.tipoFiltro === 'LIC_REG_NAO1' || this.tipoFiltro === 'LIC_REG_NAO2' || this.tipoFiltro === 'LIC_REG_NAO3') {
      order = order.concat(['data_abertura$DESC', 'numero$DESC']);
    } else if (this.tipoFiltro === 'AJUSTE_CONTRATO' || this.tipoFiltro === 'TERMO_ADITIVO') {
      order = order.concat(['data_inicio$DESC']);
    } else if (this.tipoFiltro === 'EMPENHO') {
      order = order.concat(['numero$DESC', 'data_empenho$DESC', 'id$DESC']);
    } else if (this.tipoFiltro === 'EXIGENCIA_OBRAS') {
      order = order.concat(['licitacao.data_abertura$DESC', 'licitacao.numero$DESC']);
    } else if (this.tipoFiltro === 'EXECUCAO' && !this.listaSecundaria) { // liquidacao
      order = order.concat(['data_liquidacao$DESC', 'id$DESC']);
    } else if (this.tipoFiltro === 'EXECUCAO' && this.listaSecundaria) { // empenho
      order = order.concat(['numero$DESC', 'data_empenho$DESC', 'id$DESC']);
    } else if (this.tipoFiltro === 'DOC_FISCAL') {
      order = order.concat(['data_liquidacao$DESC', 'id$DESC']);
    } else if (this.tipoFiltro === 'PAGAMENTO') {
      order = order.concat(['data_pagamento$DESC', 'id$DESC']);
    } else if (this.tipoFiltro === 'AJUSTE_EMPENHO') {
      order = order.concat(['data_empenho$DESC', 'id$DESC']);
    } else if (this.tipoFiltro === 'COMPRA_DIRETA') {
      order = order.concat(['data_compra$DESC', 'id$DESC']);
    } else {
      toastr.error(`Tipo de consulta não disponível (${this.tipoFiltro})`);
    }

    return order;
  }

  protected filtrosGrid(): Filtro {
    if (this.tipoFiltro === '*') {
      return {
        number: ['licitacao.valor_estimado', 'licitacao.valor_licitado'],
        text: ['tipo', 'licitacao.modalidade.nome', 'licitacao.situacao', 'licitacao.objeto', 'codigo', 'licitacao.numero', 'licitacao.processo'],
      };
    } else if (this.tipoFiltro === 'LIC_REG_SIM' || this.tipoFiltro === 'LIC_REG_NAO1' || this.tipoFiltro === 'LIC_REG_NAO2' || this.tipoFiltro === 'LIC_REG_NAO3') {
      return {
        number: ['valor_estimado', 'valor_licitado'],
        text: ['numero', 'processo', 'modalidade.nome', 'licitacao_audesp.codigo'],
      }
    } else if (this.tipoFiltro === 'AJUSTE_CONTRATO') {
      return {
        number: ['valor_contrato'],
        text: ['favorecido.nome', 'numero', 'licitacao_audesp.codigo'],
      }
    } else if (this.tipoFiltro === 'TERMO_ADITIVO') {
      return {
        number: ['valor_total'],
        text: ['contrato.favorecido.nome', 'licitacao_audesp.codigo', 'numero'],
      }
    } else if (this.tipoFiltro === 'EMPENHO') {
      return {
        number: ['numero', 'valor_empenho'],
        text: ['favorecido.nome', 'contrato.numero', 'licitacao_audesp.codigo']
      }
    } else if (this.tipoFiltro === 'EXIGENCIA_OBRAS') {
      return {
        text: ['licitacao.modalidade.nome', 'licitacao_audesp_obra.codigo', 'numero', 'licitacao.numero', 'licitacao.processo']
      }
    } else if (this.tipoFiltro === 'EXECUCAO' && !this.listaSecundaria) { // liquidação
      return {
        number: ['empenho.numero', 'valor_liquidado'],
        text: ['empenho.favorecido.nome', 'licitacao_audesp_execucao.codigo']
      }
    } else if (this.tipoFiltro === 'EXECUCAO' && this.listaSecundaria) { // empenho
      return {
        number: ['numero', 'valor_empenho'],
        text: ['favorecido.nome', 'licitacao_audesp_execucao.codigo']
      }
    } else if (this.tipoFiltro === 'DOC_FISCAL') {
      return {
        number: ['empenho.numero', 'valor_liquidado'],
        text: ['licitacao_audesp.codigo', 'documento']
      }
    } else if (this.tipoFiltro === 'PAGAMENTO') {
      return {
        number: ['liquidacao.empenho.numero', 'valor_pago'],
        text: ['licitacao_audesp.codigo', 'documento']
      }
    } else if (this.tipoFiltro === 'AJUSTE_EMPENHO') {
      return {
        number: ['numero', 'valor_empenho'],
        text: ['licitacao_audesp.codigo', 'contrato.numero']
      }
    } else if (this.tipoFiltro === 'COMPRA_DIRETA') {
      return {
        number: ['numero',],
        text: ['licitacao_audesp.codigo']
      }
    } else {
      toastr.error(`Tipo de consulta não disponível (${this.tipoFiltro})`);
    }
  }

  public beforeInit(): void {
    const tipoFiltroSessao = sessionStorage.getItem('/licitacoes_audesp_tipoFiltro');
    if (!tipoFiltroSessao || tipoFiltroSessao === '*')
      this.tipoFiltro = '*'
    else
      this.tipoFiltro = tipoFiltroSessao as TipoArquivoFase4

    const filtroDataStr = sessionStorage.getItem('/licitacoes_audesp_filtroData');
    if (filtroDataStr && filtroDataStr !== 'undefined') {
      this.filtroData = filtroDataStr.split(',').map(str => new Date(+str))
    }

    const filtroListaSecundaria = sessionStorage.getItem('/licitacoes_audesp_filtroListaSecundaria');
    if (filtroListaSecundaria && filtroListaSecundaria !== 'undefined') {
      this.listaSecundaria = new Boolean(filtroListaSecundaria === 'true').valueOf()
    }

    const exercicio_inicial = sessionStorage.getItem('/licitacoes_audesp_exercicio_inicial')
    if (exercicio_inicial && exercicio_inicial !== '{}') {
      this.exercicioInicio = JSON.parse(exercicio_inicial)
    } else {
      this.exercicioInicio = this.login.exercicio
    }

    const exercicio_final = sessionStorage.getItem('/licitacoes_audesp_exercicio_final')
    if (exercicio_final && exercicio_final !== '{}') {
      this.exercicioFinal = JSON.parse(exercicio_final)
    } else {
      this.exercicioFinal = this.login.exercicio
    }

    const possui_empenho = sessionStorage.getItem('/licitacoes_audesp_possui_empenho')
    if (possui_empenho && possui_empenho !== 'undefined') {
      this.filtroAjusteContratoEmpenho = possui_empenho
    }

    const audesp_exportado = sessionStorage.getItem('/licitacoes_audesp_exportado') as "*" | "S" | "N" | 'undefined'
    if (audesp_exportado && audesp_exportado !== 'undefined') {
      this.filtroExportado = audesp_exportado
    }

    const audesp_enviado = sessionStorage.getItem('/licitacoes_audesp_enviado') as "*" | "S" | "N" | 'undefined'
    if (audesp_enviado && audesp_enviado !== 'undefined') {
      this.filtroEnviado = audesp_enviado
    }

    const audesp_armazenado = sessionStorage.getItem('/licitacoes_audesp_armazenado') as "*" | "S" | "N" | 'undefined'
    if (audesp_armazenado && audesp_armazenado !== 'undefined') {
      this.filtroArmazenado = audesp_armazenado
    }

    const filtro = sessionStorage.getItem('/licitacoes-audesp_filtro')
    if (filtro && filtro !== 'undefined') {
      this.filtro = filtro
    }

    this.login = GlobalService.obterSessaoLogin();
    if (this.login) {
      // this.exercicioInicio = this.login.exercicio;
      // this.exercicioFinal = this.login.exercicio;
      this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
        .subscribe(dados => {
          this.listaExercicios = dados.content;
        });
    }
  }

  protected afterInit(): void {
    this.listaTipoFiltro = [
      { id: '*', nome: 'Licitações iniciadas' },
      { id: '_', nome: '──────────────────────────────' },
      { id: 'LIC_REG_SIM', nome: 'Registros de Preços Sim' },
      { id: 'LIC_REG_NAO1', nome: 'Registros de Preços Não' },
      { id: 'LIC_REG_NAO2', nome: 'Registros de Preços Não (Internacional)' },
      { id: 'LIC_REG_NAO3', nome: 'Registros de Preços Não (Contratação Direta)' },
      { id: 'COMPRA_DIRETA', nome: 'Registros de Preços Não (Compra Direta)' },
      { id: '_', nome: '──────────────────────────────' },
      { id: 'AJUSTE_CONTRATO', nome: 'Ajustes de Contratos' },
      { id: 'AJUSTE_EMPENHO', nome: 'Ajustes de Notas de Empenho' },
      { id: '_', nome: '──────────────────────────────' },
      { id: 'EXECUCAO', nome: 'Execuções' },
      { id: 'DOC_FISCAL', nome: 'Documentos Fiscais' },
      { id: 'PAGAMENTO', nome: 'Pagamentos' },
      { id: '_', nome: '──────────────────────────────' },
      { id: 'TERMO_ADITIVO', nome: 'Termos Aditivos' },
      { id: 'EMPENHO', nome: 'Empenhos' },
      { id: 'EXIGENCIA_OBRAS', nome: 'Exigências de Obras' },
    ]
    this.trocarTipo();

    this.opcoesFiltro = [
      { id: '*', nome: 'Todas' },
      { id: 'S', nome: 'Sim' },
      { id: 'N', nome: 'Não' },
    ]
    this.filtro = this.filtro ? this.filtro : ''
    this.filtroExportado = this.filtroExportado ? this.filtroExportado : '*';
    this.filtroEnviado = this.filtroEnviado ? this.filtroEnviado : '*';
    this.filtroArmazenado = this.filtroArmazenado ? this.filtroArmazenado : '*';
    this.filtroAjusteContratoEmpenho = this.filtroAjusteContratoEmpenho ? this.filtroAjusteContratoEmpenho : '*';
    this.exercicioInicio = !!this.exercicioInicio ? this.exercicioInicio : this.login.exercicio;
    this.exercicioFinal = !!this.exercicioFinal ? this.exercicioFinal : this.login.exercicio;
  }

  protected acaoRemover(model: LicitacaoAudesp): Observable<LicitacaoAudesp> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return null;
  }

  public iniciarFiltros() {
    this.filtro = '';
    this.filtroData = [];
    this.filtroAjusteContratoEmpenho = '*'
    this.filtroExportado = '*';
    this.filtroEnviado = '*';
    this.filtroArmazenado = '*';
    this.exercicioInicio = this.login.exercicio;
    this.exercicioFinal = this.login.exercicio;

    sessionStorage.removeItem('/licitacoes-audesp_filtro')
    sessionStorage.removeItem(`/licitacoes_audesp_exercicio_inicial`)
    sessionStorage.removeItem(`/licitacoes_audesp_exercicio_final`)
    sessionStorage.removeItem(`/licitacoes_audesp_possui_empenho`)
    sessionStorage.removeItem(`/licitacoes_audesp_exportado`)
    sessionStorage.removeItem(`/licitacoes_audesp_enviado`)
    sessionStorage.removeItem(`/licitacoes_audesp_armazenado`)
  }

  public trocarTipo() {
    this.lista = [];
    if (this.tipoFiltro === '*') {
      this.baseResourceService = this.audespService;
    } else if (this.tipoFiltro === 'LIC_REG_SIM' || this.tipoFiltro === 'LIC_REG_NAO1' || this.tipoFiltro === 'LIC_REG_NAO2' || this.tipoFiltro === 'LIC_REG_NAO3') {
      this.baseResourceService = this.licitacaoService;
    } else if (this.tipoFiltro === 'AJUSTE_CONTRATO' || this.tipoFiltro === 'EXIGENCIA_OBRAS') {
      this.baseResourceService = this.contratoService;
    } else if (this.tipoFiltro === 'TERMO_ADITIVO') {
      this.baseResourceService = this.aditamentoService;
    } else if (this.tipoFiltro === 'EMPENHO' || this.tipoFiltro === 'AJUSTE_EMPENHO') {
      this.baseResourceService = this.empenhoService;
    } else if (this.tipoFiltro === 'EXECUCAO' && !this.listaSecundaria) { // liquidação
      this.baseResourceService = this.liquidacaoService;
    } else if (this.tipoFiltro === 'EXECUCAO' && this.listaSecundaria) { // empenho
      this.baseResourceService = this.empenhoService;
    } else if (this.tipoFiltro === 'DOC_FISCAL') {
      this.baseResourceService = this.liquidacaoService;
    } else if (this.tipoFiltro === 'PAGAMENTO') {
      this.baseResourceService = this.pagamentoService;
    } else if (this.tipoFiltro === 'COMPRA_DIRETA') {
      this.baseResourceService = this.compraService;
    } else {
      toastr.error(`Tipo de consulta não disponível (${this.tipoFiltro})`);
      return
    }
    this.atualizarListagem();
  }

  public atualizarListagem() {
    this.onClick(this.filtro);
    sessionStorage.setItem(`/licitacoes_audesp_tipoFiltro`, this.tipoFiltro);
    sessionStorage.setItem(`/licitacoes_audesp_filtroListaSecundaria`, String(this.listaSecundaria));
    sessionStorage.setItem('/licitacoes_audesp_exercicio_inicial', JSON.stringify(this.exercicioInicio))
    sessionStorage.setItem('/licitacoes_audesp_exercicio_final', JSON.stringify(this.exercicioFinal))
    sessionStorage.setItem('/licitacoes_audesp_possui_empenho', this.filtroAjusteContratoEmpenho)
    sessionStorage.setItem('/licitacoes_audesp_exportado', this.filtroExportado)
    sessionStorage.setItem('/licitacoes_audesp_enviado', this.filtroEnviado)
    sessionStorage.setItem('/licitacoes_audesp_armazenado', this.filtroArmazenado)

    if (this.filtroData?.length > 0)
      sessionStorage.setItem(`/licitacoes_audesp_filtroData`, this.filtroData?.map(d => d.getTime()).join(','));
    else
      sessionStorage.removeItem(`/licitacoes_audesp_filtroData`);
  }

  public toggleSelecionado() {
    // marcado === false marcado
    // marcado === true desmarcado
    if (this.tipoFiltro === 'EMPENHO')
      this.lista.filter(l => l.contrato?.licitacao_audesp?.exportado && (!l.licitacao_audesp?.exportado)).forEach(l => l.selecionado = !this.marcado)
    else if (this.tipoFiltro === 'EXIGENCIA_OBRAS')
      this.lista.filter(l => l.licitacao_audesp_obra && !l.licitacao_audesp_obra.armazenado).forEach(l => l.selecionado = !this.marcado)
    else if (this.tipoFiltro === 'EXECUCAO')
      this.lista.filter(l => l.licitacao_audesp_execucao && !l.licitacao_audesp_execucao.armazenado).forEach(l => l.selecionado = !this.marcado)
    else if (this.tipoFiltro === 'PAGAMENTO')
      this.lista.filter(l => l.liquidacao?.licitacao_audesp && !l.liquidacao.licitacao_audesp.armazenado).forEach(l => l.selecionado = !this.marcado)
    else if (this.tipoFiltro === 'DOC_FISCAL')
      this.lista.filter(l => l.empenho?.licitacao_audesp && !l.empenho.licitacao_audesp.armazenado).forEach(l => l.selecionado = !this.marcado)
    else
      this.lista.filter(l => l.licitacao_audesp && !l.licitacao_audesp.armazenado).forEach(l => l.selecionado = !this.marcado)
  }

  public iniciarPacote() {
    this.listaAudesp = [];
    new Promise((resolve, reject) => {
      if (this.tipoFiltro === 'EMPENHO') {
        if (this.lista.filter(l => l.contrato?.licitacao_audesp?.exportado && (!l.licitacao_audesp?.exportado) && l.selecionado).map(a => a.id).length === 0) {
          toastr.error(`Nenhum registro selecionado para gerar o pacote!`)
          throw new Error(`Nenhum registro selecionado para gerar o pacote!`)
        }
        this.audespService.gerarEmpenhos(this.lista.filter(l => l.contrato?.licitacao_audesp?.exportado && (!l.licitacao_audesp?.exportado) && l.selecionado).map(a => a.id)).pipe(takeUntil(this.unsubscribe))
          .subscribe((retorno) => { this.listaAudesp = retorno; resolve(true); }, error => {
            toastr.error(error.error.payload)
          })
      } else if (this.tipoFiltro === 'EXIGENCIA_OBRAS') {
        this.lista.filter(l => l.licitacao_audesp_obra && !l.licitacao_audesp_obra.armazenado && l.selecionado).forEach(l => this.listaAudesp.push(l.licitacao_audesp_obra))
        resolve(true)
      } else if (this.tipoFiltro === 'EXECUCAO') {
        this.lista.filter(l => l.licitacao_audesp_execucao && !l.licitacao_audesp_execucao.armazenado && l.selecionado).forEach(l => this.listaAudesp.push(l.licitacao_audesp_execucao))
        resolve(true)
      } else if (this.tipoFiltro === 'PAGAMENTO') {
        this.audespService.gerarPagamentos(this.lista.filter(l => l.liquidacao?.licitacao_audesp && l.liquidacao?.licitacao_audesp?.armazenado && l.selecionado).map(a => a.id)).pipe(takeUntil(this.unsubscribe))
          .subscribe((retorno) => { this.listaAudesp = retorno; resolve(true); })
      } else {
        (this.lista as Licitacao[]).filter(l => l.licitacao_audesp && !l.licitacao_audesp.armazenado && l.selecionado).forEach(l => {
          l.licitacao_audesp.licitacao = Object.assign({}, l);
          delete l.licitacao_audesp.licitacao.licitacao_audesp;
          this.listaAudesp.push(l.licitacao_audesp)
        })
        resolve(true)
      }
    }).then(() => {
      if (this.listaAudesp.length === 0) {
        toastr.warning('Nenhum registro foi selecionado para geração do pacote');
        return;
      }
      $('#dialogFase4Pacote').modal('show');
      this.arquivosValidos = false;
      this.somenteValidos = false;
    });
  }

  public async validarRegistros() {
    this.arquivosValidos = false;
    this.somenteValidos = false;
    let ids = [];
    let validados = 0;

    if (this.tipoFiltro === 'AJUSTE_CONTRATO' || this.tipoFiltro === 'AJUSTE_EMPENHO') {
      ids = this.listaAudesp.map(item => item.id);
      this.audespService.validarLoteXML(ids).pipe(takeUntil(this.unsubscribe)).subscribe((idTransacao) => {
        this.progressoService.show(idTransacao, async (retorno) => {
          this.listaAudesp = retorno;
          this.listaAudesp.forEach(a => {
            a.mensagemXML = String(a.mensagemXML).split('\n');
            if (a.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))) {
              a.valido = false;
              a.mensagemXML.splice(a.mensagemXML.indexOf(a.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))), 1)[0];
            }
            if (a.mensagemXML.find(msg => msg !== 'undefined')) {
              a.valido = false;
            }
            if (a.valido === undefined) {
              a.mensagemXML = undefined;
              a.valido = true;
            }
            validados++;
            if (validados === this.listaAudesp.length)
              this.arquivosValidos = new Boolean(!this.listaAudesp.find(a => !a.valido)).valueOf()
          })

          let validos = this.listaAudesp.filter(a => { if (a.valido) return a })
          if (validos?.length > 0)
            this.somenteValidos = true;
        });
      }, e => {
        this.funcaoService.acaoErro(e);
      });
    } else {
      this.listaAudesp.forEach(async a => {
        a.valido = undefined;
        a.mensagemXML = undefined;
        this.audespService.validarXML(a.id).toPromise().catch(error => {
          a.valido = false;
          a.mensagemXML = String(error.error.payload).split('\n');
          if (a.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))) {
            a.mensagemXML.splice(a.mensagemXML.indexOf(a.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))), 1)[0];
          }
        }).finally(() => {
          if (a.valido === undefined) a.valido = true;
          validados++;
          if (validados === this.listaAudesp.length)
            this.arquivosValidos = new Boolean(!this.listaAudesp.find(a => !a.valido)).valueOf()
        })
      })

      let validos = this.listaAudesp.filter(a => { if (a.valido) return a })
      if (validos?.length > 0)
        this.somenteValidos = true;
    }
  }

  private converterFonteRecurso(codigo: string): 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 {
    if (!codigo) return 6; // Outras Fontes de Recursos

    if (codigo === '01' || codigo === '010000000') return 1; // Tesouro
    else if (codigo === '02' || codigo === '02000000') return 2; // Transferências e convênios estaduais-vinculados
    else if (codigo === '03' || codigo === '03000000') return 3; // Recursos Próprios de Fundos Especiais de Despesa-Vinculados
    else if (codigo === '04' || codigo === '04000000') return 4; // Recursos Próprios da Administração Indireta
    else if (codigo === '05' || codigo === '05000000') return 5; // Transferências e convênios Federais-Vinculados
    else if (codigo === '06' || codigo === '06000000') return 6; // Outras Fontes de Recursos
    else if (codigo === '07' || codigo === '07000000') return 7; // Operações de Crédito
    else if (codigo === '08' || codigo === '08000000') return 8; // Emendas Parlamentares Individuais
    else return 6; // Outras Fontes de Recursos
  }

  public visualizarInconsistencias(item: LicitacaoAudesp) {
    if (!item || item.valido || item.mensagemXML?.length === 0) return;
    this.itemInvalido = item;
    $('#dialogMensagensXML').modal('show');
  }

  public gerarPacotes() {
    this.audespService.gerarPacote(this.listaAudesp.map(a => a.id), true).pipe(takeUntil(this.unsubscribe))
      .subscribe((retorno) => {
        if (!retorno) return;
        let selecionados = this.tipoFiltro === 'AJUSTE_CONTRATO'
          ? this.lista.filter(item => item.selecionado).map(item => item.numero)
          : this.tipoFiltro === 'LIC_REG_NAO3'
            ? this.lista.filter(item => item.selecionado).map(item => item.numero)
            : this.lista.filter(item => item.selecionado).map(item => item.contrato?.numero)

        retorno.forEach(arquivo => {
          let nomeArquivo = '';
          if (arquivo.pacote)
            nomeArquivo = `PACOTE_${arquivo.pacote.id}_${arquivo.pacote.tipo}`
          else
            nomeArquivo = `${this.funcaoService.removerPontos(`${this.listaAudesp[0].licitacao.numero}`)}_${this.listaAudesp[0].tipo}`
          // nomeArquivo = `${this.funcaoService.removerPontos(`${this.listaAudesp[0].codigo}`)}_${this.listaAudesp[0].tipo}`

          const element = document.createElement('a');
          element.setAttribute('href', 'data:text/xml;charset=ISO-8859-1,' + encodeURIComponent(this.funcaoService.formatXml(arquivo.xml)));
          element.setAttribute('download', `${nomeArquivo}.xml`);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          this.mensagemXML = undefined;
          this.xmlInvalido = undefined;
          $('#dialogFase4Pacote').modal('hide');
          this.atualizarListagem();
        })
      }, error => {
        this.mensagemXML = String(error.error.payload).split('\n');
        if (this.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))) {
          this.xmlInvalido = this.mensagemXML.splice(this.mensagemXML.indexOf(this.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))), 1)[0];
          this.xmlInvalido = this.xmlInvalido.substring('ARQUIVO_XML:'.length, this.xmlInvalido.length);
        }
        $('#dialogMensagensXMLPacote').modal('show');
      });
  }

  public gerarPacotesValidos() {
    let lista = this.listaAudesp.filter(a => { if (a.valido) return a })

    this.audespService.gerarPacote(lista.map(a => a.id), true).pipe(takeUntil(this.unsubscribe))
      .subscribe((retorno) => {
        if (!retorno) return;
        let selecionados = this.tipoFiltro === 'AJUSTE_CONTRATO'
          ? this.lista.filter(item => item.selecionado).map(item => item.numero)
          : this.tipoFiltro === 'LIC_REG_NAO3'
            ? this.lista.filter(item => item.selecionado).map(item => item.numero)
            : this.lista.filter(item => item.selecionado).map(item => item.contrato?.numero)

        retorno.forEach(arquivo => {
          let nomeArquivo = '';
          if (arquivo.pacote)
            nomeArquivo = `PACOTE_${arquivo.pacote.id}_${arquivo.pacote.tipo}`
          else
            nomeArquivo = `${this.funcaoService.removerPontos(`${this.listaAudesp[0].licitacao.numero}`)}_${this.listaAudesp[0].tipo}`
          // nomeArquivo = `${this.funcaoService.removerPontos(`${this.listaAudesp[0].codigo}`)}_${this.listaAudesp[0].tipo}`

          const element = document.createElement('a');
          element.setAttribute('href', 'data:text/xml;charset=ISO-8859-1,' + encodeURIComponent(this.funcaoService.formatXml(arquivo.xml)));
          element.setAttribute('download', `${nomeArquivo}.xml`);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          this.mensagemXML = undefined;
          this.xmlInvalido = undefined;
          $('#dialogFase4Pacote').modal('hide');
          this.atualizarListagem();
        })
      }, error => {
        this.mensagemXML = String(error.error.payload).split('\n');
        if (this.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))) {
          this.xmlInvalido = this.mensagemXML.splice(this.mensagemXML.indexOf(this.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))), 1)[0];
          this.xmlInvalido = this.xmlInvalido.substring('ARQUIVO_XML:'.length, this.xmlInvalido.length);
        }
        $('#dialogMensagensXMLPacote').modal('show');
      });
  }

  public baixarXMLInvalido() {
    if (!this.xmlInvalido) return;
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/xml;charset=ISO-8859-1,' + encodeURIComponent(this.funcaoService.formatXml(this.xmlInvalido)));
    element.setAttribute('download', `PACOTE_INVALIDO.xml`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  private obterFiltroStatus() {
    const parametro = {}

    let prefix = 'licitacao_audesp.';
    if (this.tipoFiltro === '*') prefix = ''
    else if (this.tipoFiltro === 'EXIGENCIA_OBRAS') prefix = 'licitacao_audesp_obra.'
    else if (this.tipoFiltro === 'EXECUCAO') prefix = 'licitacao_audesp_execucao.'

    if (this.filtroExportado === 'S')
      parametro[`${prefix}exportado`] = true;
    else if (this.filtroExportado === 'N')
      parametro['OR_1'] = `${prefix}id$null;!;!;${prefix}exportado=false`;

    if (this.filtroEnviado === 'S')
      parametro[`${prefix}enviado`] = true;
    else if (this.filtroEnviado === 'N')
      parametro['OR_2'] = `${prefix}id$null;!;!;${prefix}enviado=false`;

    if (this.filtroArmazenado === 'S')
      parametro[`${prefix}armazenado`] = true;
    else if (this.filtroArmazenado === 'N' && this.tipoFiltro !== 'EMPENHO') {
      parametro['OR_3'] = `${prefix}id$null;!;!;${prefix}armazenado=false`;
    }

    return parametro;
  }

  public onDatesRangeFilterSelected(selectedValue: Date) {
    if (this.filtroData[1]) {
      this.dateFilter.hideOverlay()
    };
  }

  public onBlurDateRange(event: any) {
    const dateStr: string = $('p-calendar[selectionmode=range] span.p-calendar input')[0].value;
    if (dateStr.match(/^\d{2}\/\d{2}\/\d{4} \- \d{2}\/\d{2}\/\d{4}$/)) {
      this.filtroData = [];

      const dateStrSplit: string[] = dateStr.split(' - ');
      dateStrSplit.forEach(date => {
        const dateSplit = date.split('/');
        this.filtroData.push(
          new DateFormatPipe().transform(this.datepipe.transform(new Date(`${dateSplit[1]}/${dateSplit[0]}/${dateSplit[2]}`), 'yyyy-MM-dd'), []),
        )
      })
    }
  }

  public onFocusDate() {
    new GlobalService().calendarMascara();
  }

  public obterPrazo(item: any): Date {
    if (this.tipoFiltro === 'LIC_REG_SIM' || this.tipoFiltro === 'LIC_REG_NAO1' || this.tipoFiltro === 'LIC_REG_NAO2' || this.tipoFiltro === 'LIC_REG_NAO3') {
      const itm: Licitacao = item;
      if (itm.situacao === 'ATIVA' || itm.situacao === 'HOMOLOGADA' || itm.situacao === 'ADJUDICADA') {
        let dt = new DateFormatPipe().transform(itm.data_homologacao, [])
        if (!dt || dt < itm.data_adjudicacao) dt = itm.data_adjudicacao
        if (dt) return this.funcaoService.adicionarDia(10, dt, true)
      } else {
        if (itm.data_cancelamento) return this.funcaoService.adicionarDia(5, itm.data_cancelamento, true)
      }
    } else if (this.tipoFiltro === 'AJUSTE_CONTRATO' || this.tipoFiltro === 'EXIGENCIA_OBRAS') {
      const itm: Contrato = item;
      return this.funcaoService.adicionarDia(10, itm.data_assinatura, true)
    } else if (this.tipoFiltro === 'TERMO_ADITIVO') {
      const itm: ContratoAditamento = item;
      return this.funcaoService.adicionarDia(10, itm.data_aditamento, true)
    } else if (this.tipoFiltro === 'EMPENHO' || this.tipoFiltro === 'AJUSTE_EMPENHO') {
      const itm: Empenho = item;
      return this.funcaoService.adicionarDia(10, itm.data_empenho, true)
    } else if (this.tipoFiltro === 'EXECUCAO' && !this.listaSecundaria) { // liquidação
      const itm: Liquidacao = item;
      return this.funcaoService.adicionarDia(10, itm.data_liquidacao, true)
    } else if (this.tipoFiltro === 'EXECUCAO' && this.listaSecundaria) { // empenho
      const itm: Empenho = item;
      return this.funcaoService.adicionarDia(10, itm.data_empenho, true)
    } else if (this.tipoFiltro === 'DOC_FISCAL') {
      const itm: Liquidacao = item;
      return this.funcaoService.adicionarDia(10, itm.data_liquidacao, true)
    } else if (this.tipoFiltro === 'PAGAMENTO') {
      const itm: Pagamento = item;
      return this.funcaoService.adicionarDia(10, itm.data_pagamento, true)
    } else if (this.tipoFiltro === 'COMPRA_DIRETA') {
      const itm: Compra = item;
      return this.funcaoService.adicionarDia(10, itm.data_compra, true)
    }

    return null
  }

  public prazoVencido(item: any): boolean {
    if (!item) return false

    if (this.tipoFiltro === 'LIC_REG_SIM' || this.tipoFiltro === 'LIC_REG_NAO1' || this.tipoFiltro === 'LIC_REG_NAO2' || this.tipoFiltro === 'LIC_REG_NAO3') {
      const itm: Licitacao = item;
      if (itm.licitacao_audesp?.exportado) return false
    } else if (this.tipoFiltro === 'AJUSTE_CONTRATO' || this.tipoFiltro === 'EXIGENCIA_OBRAS') {
      const itm: Contrato = item;
      if (this.tipoFiltro === 'EXIGENCIA_OBRAS') {
        if (itm.licitacao_audesp_obra?.exportado) return false
      } else
        if (itm.licitacao_audesp?.exportado) return false
    } else if (this.tipoFiltro === 'TERMO_ADITIVO') {
      const itm: ContratoAditamento = item;
      if (itm.licitacao_audesp?.exportado) return false
    } else if (this.tipoFiltro === 'EMPENHO' || this.tipoFiltro === 'AJUSTE_EMPENHO') {
      const itm: Empenho = item;
      if (itm.licitacao_audesp?.exportado) return false
    } else if (this.tipoFiltro === 'EXECUCAO' && !this.listaSecundaria) { // liquidação
      const itm: Liquidacao = item;
      if (itm.licitacao_audesp_execucao?.exportado) return false
    } else if (this.tipoFiltro === 'EXECUCAO' && this.listaSecundaria) { // empenho
      const itm: Empenho = item;
      if (itm.licitacao_audesp_execucao?.exportado) return false
    } else if (this.tipoFiltro === 'DOC_FISCAL') {
      const itm: Liquidacao = item;
      if (itm.licitacao_audesp?.exportado) return false
    } else if (this.tipoFiltro === 'PAGAMENTO') {
      const itm: Pagamento = item;
      if (itm.licitacao_audesp?.exportado) return false
    } else if (this.tipoFiltro === 'COMPRA_DIRETA') {
      const itm: Compra = item;
      if (itm.licitacao_audesp?.exportado) return false
    } else {
      return false
    }

    return this.funcaoService.diferencaEmDias(new Date(), this.obterPrazo(item)) > 0
  }
}
