import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { SetorExecutora } from '../../../entidade/almoxarifado/setor-executora.model';
import { Setor } from '../../../entidade/almoxarifado/setor.model';
import { LoginContabil } from '../../../entidade/login/login-contabil';
import { Executora } from '../../../entidade/planejamento/executora.model';
import { EddyAutoComplete } from '../../../models/form-components';
import { ExecutoraService } from '../../../unidade/service/executora.service';
import { FuncaoService } from '../../../util/funcao.service';
import { GlobalService } from '../../../util/global.service';
import { SetorExecutoraService } from '../service/setor-executora.service';

@Component({
  selector: 'app-setor-executora',
  templateUrl: './setor-executora.component.html'
})
export class SetorExecutoraComponent implements OnInit, OnChanges, OnDestroy {

  protected unsubscribe: Subject<void> = new Subject();
  public executoraAutoComplete: EddyAutoComplete<Executora>;
  public item: SetorExecutora;

  @Input() lista: Array<any>;
  @Input() setor: Setor;
  @Input() login: LoginContabil;
  @Output() callback: EventEmitter<SetorExecutora> = new EventEmitter();

  @ViewChild('btnAdicionar') public btnAdicionar: ElementRef;

  constructor(protected funcaoService: FuncaoService,
    public globalService: GlobalService,
    protected setorExecutoraService: SetorExecutoraService,
    protected executoraService: ExecutoraService) {
  }

  ngOnInit(): void {
    this.carregarAutoCompletes();
  }

  ngOnChanges(changes) {
    if (changes.setor) {
      this.setorExecutoraService.filtrar(0, 0, {
        setor_id: changes.setor.currentValue.id, relations: 'executora,setor'
      }).subscribe(executoras => this.lista = Array.from(executoras.content as SetorExecutora[]));
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public adicionar() {
    if (!this.lista) {
      this.lista = [];
    }
    this.lista.unshift({ editavel: true });
  }

  public salvar(item: SetorExecutora) {
    try {
      if (!item.executora) {
        throw new Error('Informe a executora!');
      }

      item['visivel'] = true;
      item.setor = this.setor;
      item.setor.orgao = this.login.orgao;

      if (item.id) {
        this.setorExecutoraService.atualizar(item).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro alterado com sucesso!');
            item['editavel'] = false;
            this.callback.emit(res);
          },
            error => toastr.error('Falha ao alterar registro!'));
      } else {
        this.setorExecutoraService.inserir(item).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro inserido com sucesso!');
            item['editavel'] = false;
            if (res) {
              item.id = res.id;
              this.callback.emit(res);
            }
          },
            error => toastr.error('Falha ao inserir registro!'));
      }

      this.btnAdicionar.nativeElement.disabled = false;

    } catch (e) {
      toastr.error(e.message);
      throw e;
    }
  }

  public editar(item: SetorExecutora) {
    try {
      if (!item.executora) {
        throw new Error('Informe a executora!');
      }

      item['editavel'] = true;

      if (item.id) {
        this.setorExecutoraService.atualizar(item).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
          },
            error => toastr.error('Falha ao alterar registro!'));
      }

      this.btnAdicionar.nativeElement.disabled = false;

    } catch (e) {
      toastr.error(e.message);
      throw e;
    }
  }

  public cancelar(item: SetorExecutora) {
    item['editavel'] = false;
  }

  public remover(item: SetorExecutora) {
    if (item.id) {
      this.setorExecutoraService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          toastr.info('Registro removido com sucesso!');
        });
    }
    this.lista.splice(this.lista.findIndex((i) => { return i === item }), 1);
    this.btnAdicionar.nativeElement.disabled = false;
  }

  protected validSave(item: SetorExecutora): boolean {
    if (!item.executora) {
      toastr.warning('Informe a executora!');
      return false;
    }
    return true;
  }

  protected beforeSave(item: SetorExecutora): void { }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  private carregarAutoCompletes() {
    this.executoraAutoComplete = new EddyAutoComplete(null, this.executoraService,
      'codigo', ['nome'], { 'unidade.ppa_id': this.login.ppa.id, orderBy: 'nome' }, { number: ['codigo'], text: ['codigo', 'nome'] }
    );
  }

}
