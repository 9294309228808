import { BaseResourceModel } from '../../models/base-resource.model';
import { Cidade } from '../geo/cidade.model';

export class Nacionalidade extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public cidade?: Cidade
  ) {
    super();
  }
  static converteJson(json: any): Nacionalidade {
    return Object.assign(new Nacionalidade(), json);
  }
}
