// tslint:disable: variable-name
import { Legislacao } from './legislacao.model';
import { LegislacaoArquivo } from './legislacao-arquivo.model';
import { Usuario } from '../comum/usuario.model';
import { LegislacaoHistoricoView } from './legislacao-historico-view.model';

export class LegislacaoHistorico {

    id: number;

    data_limite: Date;

    comentario: string;

    data_cadastro: Date;

    data_alteracao: Date;

    legislacao: Legislacao;

    usuario: Usuario;

    arquivos: LegislacaoArquivo[];

    views: LegislacaoHistoricoView[];
}
