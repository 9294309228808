import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, Page, RecebimentoExtra } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class RecebimentoExtraService extends BaseResourceService<RecebimentoExtra> {

  constructor(
    protected injector: Injector
  ) {
    super(`recebimentos-extras`, injector);
  }

  public transparencia(pagina: number, limite: number, filtros: {}): Observable<Page> {
    let parametros = '';
    if (filtros) {
      // tslint:disable-next-line: forin
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + encodeURIComponent(filtros[key]);
      }
    }
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/transparencia/${pagina}/${limite}${parametros}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorNumero(numero: number, ano: number, orgaoId: number): Observable<RecebimentoExtra> {
    return this.http.get<RecebimentoExtra>(
      `${this.login.cidade.id}/${this.api}?numero=${numero}&exercicio_ano=${ano}&orgao_id=${orgaoId}`).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public ultimaData(exercicio: number, orgaoId: number): Observable<any> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/ultima-data/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  importarRecebimentosExtras(resource: RecebimentoExtra[]): Observable<any> {
    
    return this.http.post(`${this.login.cidade.id}/${this.api}/importar-recebimentos-extras`, resource, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }


}
