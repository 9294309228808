import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class SiteLink extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public url?: string,
    public tipo?: string,
    public imagem?: string,
    public orgao?: Orgao,
  ) {
    super();
  }

  static converteJson(json: any): SiteLink {
    return Object.assign(new SiteLink(), json);
  }
}
