import { Component, Injector, ElementRef } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BaseResourceFormComponent, TransferenciaAutorizacao,
  LoginContabil, GlobalService, DateFormatPipe
} from 'eddydata-lib';
import { TransferenciaAutorizacaoService } from '../service/transferencia-autorizacao.service';

@Component({
  selector: 'app-transferencia-autorizacao-view',
  templateUrl: './transferencia-autorizacao-view.component.html'
})
export class TransferenciaAutorizacaoViewComponent extends BaseResourceFormComponent<TransferenciaAutorizacao, LoginContabil> {

  /**
   * Declaração de variáveis
   */


  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected transferenciaService: TransferenciaAutorizacaoService) {
    super(new TransferenciaAutorizacao(), injector, TransferenciaAutorizacao.converteJson, transferenciaService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      data_transfere: [null, [Validators.required]],
      data_referencia: [null, [Validators.required]],
      valor_transferido: 0,
      mes: [null, [Validators.required]],
      especie: [null, [Validators.required]],
      documento: [null, [Validators.required]],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      tipo: [null, [Validators.required]],
      exercicio: this.fb.group({
        ano: [null, [Validators.required]],
      }),
      orgao: [null, [Validators.required]],
      conta_pagadora: this.fb.group({
        agencia: [null, [Validators.required]],
        conta_numero: [null, [Validators.required]],
      }),
      conta_recebedora: this.fb.group({
        agencia: [null, [Validators.required]],
        conta_numero: [null, [Validators.required]],
      })
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'conta_pagadora,conta_pagadora.banco,conta_recebedora,conta_recebedora.banco' };
  }

  protected afterInit(): void {

  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected afterLoad() {
    this.entidade.data_transfere = new DateFormatPipe().transform(this.entidade.data_transfere, []);
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: TransferenciaAutorizacao): void {
  }


  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================


}
