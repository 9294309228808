import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';

export class ModeloRelatorio extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public modelo?: string,
    public tipo?: 'LICITACAO' | 'CONTRATO' | 'ADITAMENTO',
    public fixo?: boolean,
    public usuario?: Usuario,
    public orgao?: Orgao,
  ) {
    super();
  }

  static converteJson(json: any): ModeloRelatorio {
    return Object.assign(new ModeloRelatorio(), json);
  }
}
