import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MemorialService } from "administrativo-lib";
import { BaseResourceRptComponent, Coluna, Licitacao, LicitacaoPipe } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-economia-rpt',
  templateUrl: './economia-rpt.component.html'
})
export class EconomiaRptComponent extends BaseResourceRptComponent {

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Input() public licitacao: Licitacao;

  constructor(
    private memorialService: MemorialService
  ) {
    super();
  }

  protected afterInit(): void { }

  protected tituloRelatorio(): string {
    return `${this.login.orgao.nome}`;
  }

  protected subTituloRelatorio(): string {
    return `COMPARATIVO DE DIFERENÇAS ECONÔMICAS
     Licitação: ${new LicitacaoPipe().transform(this.licitacao.numero)} - Processo: ${new LicitacaoPipe().transform(this.licitacao.processo)} - Modalidade: ${this.licitacao.modalidade.nome}`;
  }

  protected obterColunasRelatorio(): Coluna[] {

    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'ITEM', coluna: 'item' });
    retorno.push({ titulo: 'QUANTIDADE', coluna: 'quantidade', decimais: 3, alignment: 'right' });
    retorno.push({ titulo: 'FORNECEDOR', coluna: 'vencedor.proponente.favorecido.nome', alignment: 'left'});
    retorno.push({ titulo: 'VL. REFERÊNCIA', coluna: 'valor_referencia', decimais: 4, alignment: 'right' });
    retorno.push({ titulo: 'VL. NEGOCIADO', coluna: 'vencedor.valor_final', decimais: 4, alignment: 'right' });
    retorno.push({ titulo: 'DIFERENÇA', coluna: 'diferenca', decimais: 4, alignment: 'right' });

    return retorno.map((r) => Object.assign(r, { adicionaisHeader: { margin: 3, fontSize: 8, border: [true, true, true, true], alignment: 'center' }, adicionaisBody: { margin: 2, border: [false, false, false, false] } }));
  }

  protected larguraColunas(): (string | number)[] {
    return ['*', 60, '*', 70, 70, 70];
  }

  protected totalizarColunas(): (string | {})[] {
    return [
      { nome: 'valor_referencia', funcao: [{ valor: ['valor_referencia', '*', 'quantidade'] }], abaixo: true },
      { nome: 'vencedor.valor_final', funcao: [{ valor: ['vencedor.valor_final', '*', 'quantidade'] }], abaixo: true },
      { nome: 'diferenca', funcao: [{ valor: ['diferenca', '*', 'quantidade'] }], abaixo: true }
    ];
  }

  protected carregarLista(): Promise<any[]> {

    const relations = ['propostas.proponente.favorecido', 'licitacao', 'produto_unidade.unidade', 'produto_unidade.produto'];

    const orderBy = ['ordem$ASC'];

    return new Promise((resolve, reject) => {
      this.memorialService.filtrar(1, -1, { 'licitacao.id': this.licitacao.id, relations: relations.join(','), orderBy: orderBy.join(',') })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          if (!res.content) {
            resolve(res.content);
            return;
          }
          resolve(res.content.filter((c) => c.propostas && c.propostas.filter((p) => p.situacao === 'VENCEDOR').length > 0)
            .map((c) => {
              c['item'] = `${c.ordem} - ${c.produto_unidade.produto.nome}`;
              c['vencedor'] = c.propostas.find((p) => p.situacao === 'VENCEDOR');
              c['diferenca'] = +c.valor_referencia - +c['vencedor'].valor_final;
              return c;
            }));
        });
    });
  }


  protected layout(): {} {
    return {
      hLineWidth() {
        return 1;
      },
      vLineWidth(i, node) {
        return 1;
      },
      hLineColor(i) {
        return i === 1 || i === 0 ? 'black' : '0aaa';
      },
      paddingLeft(i) {
        return 2;
      },
      paddingRight(i, node) {
        return 2;
      }
    }
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }
}