import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MaterialService } from 'almoxarifado-lib';
import { EddyAutoComplete, FuncaoService, Login, Material } from 'eddydata-lib';
import { AutoComplete } from 'primeng/autocomplete';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';


declare var $: any;

@Component({
  selector: 'lib-grupo-material-busca-dlg',
  templateUrl: './grupo-material-busca-dlg.component.html'
})
export class MaterialBuscaDlgComponent implements OnInit, OnDestroy {

  @Input() unidade: boolean = true;
  @Input() somenteServico: boolean = false;
  @Input() subelemento: number;
  @Input() produtoNovo: string;
  @Input() entidade: Material;

  @Input() login: Login;

  @Output() callback: EventEmitter<Material> = new EventEmitter();
  @Output() onCancelar: EventEmitter<void> = new EventEmitter();
  @Output() cancelarImportacoesB: EventEmitter<boolean> = new EventEmitter();
  @Output() cancelarCallback: EventEmitter<boolean> = new EventEmitter();

  @Input() parametros: {} = {};

  @Input() public nomeDlg: string = 'dialogProdutoCadastro';
  @Input() public nomeDlgCad: string = 'ProdutoCadastro';

  public visualizarGrupo: boolean = false;

  // filtros listagem
  public codigo: string;
  public nome: string;

  // listagem
  public lista: Material[];
  public paginaCorrente: number;
  public paginaTotal: number;
  limite: number = 10;

  // cadastro unidade
  @ViewChild('unidade_cad_') campoUnidade: AutoComplete;
  public materialAutoComplete: EddyAutoComplete<Material>;

  protected unsubscribe: Subject<void> = new Subject();
  produtoService: any;

  constructor(
    private materialService: MaterialService,
  ) { }

  ngOnInit() {
    if (!this.paginaCorrente) {
      this.paginaCorrente = 1;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public buscar() {
    this.paginaCorrente = 1;
    this.preencherGrid();
  }

  private preencherGrid() {
    let parametros = {};
    if (this.parametros)
      parametros = Object.assign({}, this.parametros);

    parametros['relations'] = 'sub_grupo.grupo';
    parametros['orgao_id'] = this.login.orgao.id;

    if (this.codigo) {
      let codigos = this.codigo.split('.');
      let codigoGrupo = codigos[0];
      let codigoSubGrupo = codigos[1];
      let codigoMaterial = codigos[2];

      codigoGrupo === '' ? codigoGrupo : parametros['sub_grupo.grupo.codigo'] = codigoGrupo;
      codigos.length == 2 ? codigoSubGrupo === '' ? codigoSubGrupo : parametros['sub_grupo.codigo'] = codigoSubGrupo : codigoSubGrupo;
      codigos.length == 3 ? codigoMaterial === '' ? codigoMaterial : parametros['codigo'] = codigoMaterial : codigoMaterial;
    }
    if (this.nome) {
      parametros['nome$like'] = `%${new FuncaoService().removerAcentos(this.nome)}%`;
    }

    if (Object.keys(parametros).length <= 1) {
      toastr.info('Informe os valores de busca');
      return;
    }

    parametros['orderBy'] = 'nome';

    this.materialService.filtrar(this.paginaCorrente, this.limite, parametros).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.lista = lista.content;
          this.paginaTotal = lista.totalPages;
        },
        error => alert('erro ao retornar lista')
      );
  }

  public proximaPagina() {
    this.paginaCorrente = this.paginaCorrente === this.paginaTotal ? this.paginaTotal : this.paginaCorrente + 1;
    this.preencherGrid();
  }

  public paginaAnterior() {
    this.paginaCorrente = this.paginaCorrente === 1 ? 1 : this.paginaCorrente - 1;
    this.preencherGrid();
  }

  public paginaDigitada(pagina: number) {
    this.paginaCorrente =
      +pagina >= this.paginaTotal
        ? this.paginaTotal
        : +pagina;
    this.preencherGrid();
    window.scrollTo(0, 0);
  }

  public mudarLimite(limite: number) {
    this.limite = limite;
    this.paginaDigitada(1);
  }

  public enviarMaterial(mat: Material) {
    this.callback.emit(mat);
    this.fechar();
  }

  public fechar() {
    this.codigo = undefined;
    this.nome = undefined;
    this.lista = [];
    $(`#${this.nomeDlg}`).modal('hide');
    this.cancelarCallback.emit(true);
  }

  public buscarRegistroIncluido(item: Material) {
    if (item) {
      this.codigo = undefined;
      this.nome = item.nome;
    }
    this.buscar();
  }

  public enviarGrupoMaterial(item: Material) {
    const prod: Material = new Material();
    prod.id = item.id;
    prod.codigo = item.codigo;
    prod.nome = item.nome;
    prod.sub_grupo = item.sub_grupo;
    prod.servico = item.servico;
    prod.contabilizar = item.contabilizar;
    prod.bec = item.bec;

    this.callback.emit(prod);
    this.fechar();
  }

  public podeIncluir(url?: string) {
    if (this.login) {
      if (this.administrador()) {
        return true;
      }
      if (url.lastIndexOf("/") > 0) {
        url = url.substring(0, url.substring(1, url.length).indexOf("/") + 1);
      }
      for (const acesso of this.login.acessos) {
        if (url === acesso.pagina) {
          return acesso.permissao === 2;
        }
      }
    }
    return false;
  }

  administrador() {
    return new FuncaoService().campoJsonToken(this.login.token, "administrador")
  }

}
