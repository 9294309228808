import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EddyAutoComplete, EmpenhoExtra, Favorecido, FavorecidoService, GlobalService, Login, OrgaoAssinaturaService } from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotaEmpenhoExtra } from '../../relatorio/contabil/nota-empenho-extra';
import { EmpenhoExtraService } from '../service/empenho-extra.service';
import * as toastr from 'toastr';

@Component({
  selector: 'lib-nota-empenho-extra-dlg',
  templateUrl: './nota-empenho-extra-dlg.component.html'
})
export class NotaEmpenhoExtraDlgComponent implements OnInit, OnDestroy {


  protected datepipe: DatePipe;
  public ptBR: any;
  public selectedOrdem: string;
  public selectedOpt: string;
  public selectNaoImpresso: boolean;
  public selectUsuario: boolean;
  public selectAnulado: boolean;
  public selectSubempenho: boolean;
  public selectFornecedor: boolean;
  public marcarComoImpresso: boolean;
  public dataInicio: Date;
  public dataFinal: Date;
  public numeroInicio: number;
  public numeroFinal: number;
  public fichaInicio: number;
  public fichaFinal: number;
  public favorecido: Favorecido;
  protected unsubscribe: Subject<void> = new Subject();

  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;

  public imaskNumero = {
    mask: Number,
    scale: 0,
    signed: false
  };

  @Input() login: Login;
  @Input() empenhosSelecionados: any[];

  constructor(
    protected confirmationService: ConfirmationService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected messageService: MessageService,
    private globalService: GlobalService,
    private favorecidoService: FavorecidoService,
    private empenhoExtraService: EmpenhoExtraService
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.selectedOrdem = 'ord1';
    this.selectedOpt = 'opt1';
    this.selectNaoImpresso = true;
    this.selectUsuario = true;
    this.dataInicio = new Date();
    this.dataFinal = new Date();

    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['nome'], { 'cidade.id': this.login.cidade.id, relations: 'tipo,contas.banco', orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  imprimir() {
    if (this.selectNaoImpresso) {
      this.confirmationService.confirm({
        message: 'Deseja marcar os empenhos extras como impressos ?',
        acceptLabel: "Sim",
        rejectLabel: "Não",
        header: 'Confirmação',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.marcarComoImpresso = true;
          this.gerarImpressaoEmpenhos();
        },
        reject: () => {
          this.marcarComoImpresso = false;
          this.gerarImpressaoEmpenhos();
        }
      });
    } else {
      this.marcarComoImpresso = false;
      this.gerarImpressaoEmpenhos();
    }
  }

  async gerarImpressaoEmpenhos() {
    const parametros = {};

    let relations = 'contrato.licitacao.modalidade,ficha.aplicacao,ficha.recurso,ficha.aplicacao_variavel,contrato,favorecido.contas.banco,favorecido.contas.orgao,convenio,retencao.liquidacao.empenho.favorecido';

    if (this.selectedOpt === 'opt1') {
      if (+this.dataFinal < +this.dataInicio) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Data final do empenho está maior que a inicial' });
        return;
      }
      if (!this.dataInicio || !this.dataFinal || +this.dataFinal === 0 || +this.dataInicio === 0) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Informe a data inicial e final para imprimir' });
        return;
      }

      if (+this.fichaFinal < +this.fichaInicio) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Ficha final do empenho está maior que a inicial' });
        return;
      }
      if ((!this.fichaInicio && this.fichaFinal) || (this.fichaInicio && !this.fichaFinal)) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Informe a ficha inicial e final para imprimir' });
        return;
      }
    } else if (this.selectedOpt === 'opt2') {
      if (+this.numeroFinal < +this.numeroInicio) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Número final do empenho está maior que o inicial' });
        return;
      }
      if (!this.numeroInicio || !this.numeroFinal || +this.numeroFinal === 0 || +this.numeroInicio === 0) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Informe o número inicial e final para imprimir' });
        return;
      }
    } else {
      if (this.empenhosSelecionados.length === 0 || !this.empenhosSelecionados) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Selecione ao menos um empenho para imprimir' });
        return;
      }
    }

    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['orderBy'] = this.selectedOrdem === 'ord1' ? 'numero$ASC' : 'favorecido.nome$ASC';

    if (this.selectedOpt === 'opt1') {
      parametros['data_empenho$ge'] = this.datepipe.transform(this.dataInicio, 'yyyy-MM-dd');
      parametros['data_empenho$le'] = this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd');

      if (this.fichaInicio && this.fichaFinal) {
        parametros['ficha.numero$ge'] = +this.fichaInicio;
        parametros['ficha.numero$le'] = +this.fichaFinal;
      }
    } else if (this.selectedOpt === 'opt2') {
      parametros['numero$ge'] = +this.numeroInicio;
      parametros['numero$le'] = +this.numeroFinal;
    } else {
      parametros['numero$in'] = this.empenhosSelecionados;
    }
    if (this.selectNaoImpresso) {
      parametros['impresso'] = !this.selectNaoImpresso;
    }
    if (this.selectUsuario) {
      parametros['usuario_cadastro_id'] = this.login.usuario.id;
    }
    if (this.selectFornecedor) {
      parametros['favorecido.nome$like'] = this.favorecido.nome;
    }
    if (this.selectAnulado) {
      parametros['especie'] = 'EEA';
    } else if (this.selectSubempenho) {
      parametros['especie'] = 'SEE';
    } else {
      parametros['especie'] = 'EME';
    }

    this.empenhoExtraService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        let empenhos: EmpenhoExtra[] = [];
        lista.content.forEach(e => {
          if (e.favorecido.contas.length === 0) {
            empenhos.push(e)
          } else {
            let contasValidas = e.favorecido.contas.filter(c => c.orgao.id === this.login.orgao.id);
            if (contasValidas.length > 0) {
              e.favorecido.contas = [];
              e.favorecido.contas = contasValidas;
              empenhos.push(e);
            } else {
              e.favorecido.contas = [];
              empenhos.push(e);
            }
          }
        });
        if (empenhos.length === 0 || lista.content.length === 0) {
          toastr.warning('Nenhum empenho extra encontrado!', 'Atenção!')
          return;
        }
        new NotaEmpenhoExtra(this.assinaturaService).imprimir(empenhos, this.login);
        if (this.marcarComoImpresso) {
          this.empenhoExtraService.marcarComoImpresso(parametros).subscribe(() => {
          });
        }
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );

  }


}
