import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BaseResourceListComponent, Coluna, Exercicio, ExercicioService, Filtro, FormatoExportacao, GlobalService, Holerite, LoginPublico
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HoleriteService } from '../../holerite/holerite.service';

@Component({
  selector: 'lib-remuneracao-lst',
  templateUrl: './remuneracao-lst.component.html'
})
export class RemuneracaoLstComponent extends BaseResourceListComponent<Holerite, LoginPublico> {

  public ptBR: any;
  public selectExercicio: number;
  public mes: number;
  public ano: number;
  public referencia = 'TODAS';
  public listaExercicios: Array<Exercicio>;
  public listaMeses: Array<any>;
  public listaReferencia: Array<string>;
  public datepipe: DatePipe;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private route: ActivatedRoute,
    protected injector: Injector,
    private globalService: GlobalService,
    protected exercicioService: ExercicioService,
    private holeriteService: HoleriteService) {
    super(holeriteService, injector);
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.mes = params['mes'];
        this.ano = params['ano'];
        this.referencia = params['referencia'];
        this.paginaCorrente = +params['pagina'];
        this.filtro = params['filtro'];
      });
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'funcionario,funcionario.pessoa,funcionario.cargo,funcionario.departamento,referencia';
  }

  protected condicoesGrid(): {} {
    return {
      ['referencia.mes']: this.mes,
      ['orgao.id']: this.login.orgao.id,
      ['referencia.ano']: this.selectExercicio
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['id$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {};
  }

  protected afterInit(): void {
    window.scrollTo(0, 0);
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
    this.ptBR = this.globalService.obterDataBR();
    this.listaMeses = this.globalService.obterListaMeses();
    this.listaReferencia = ['TODAS', 'FOLHA', '13 SALARIO', 'FERIAS'];
    this.selectExercicio = this.ano;
    this.preencherExercicio();
  }

  protected acaoRemover(model: Holerite): Observable<Holerite> {
    return null;
  }

  public obterParametros() {
    this.datepipe = new DatePipe('pt');
    const parametros = {};
    parametros['ignoreCondObrig'] = true;
    parametros['referencia.mes'] = this.mes;
    parametros['referencia.ano'] = this.selectExercicio;
    parametros['orgao.id'] = this.login.orgao.id;
    if (this.filtro) {
      parametros['funcionario.pessoa.nome$like'] = this.filtro + '%';
    }
    if (this.referencia !== 'TODAS') {
      parametros['calculo$like'] = '%' + this.referencia + '%';
    }
    switch (this.col) {
      case 1:
        parametros['orderBy'] = `funcionario.pessoa.nome$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 2:
        parametros['orderBy'] = `funcionario.cargo.nome$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 3:
        parametros['orderBy'] = `calculo$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 4:
        parametros['orderBy'] = `referencia$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 5:
        parametros['orderBy'] = `valor_bruto$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 6:
        parametros['orderBy'] = `valor_desconto$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 7:
        parametros['orderBy'] = `valor_liquido$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      default:
        break;
    }
    parametros['relations'] = 'funcionario,funcionario.pessoa,funcionario.cargo,funcionario.departamento,referencia';
    return parametros;
  }

  public preencherGrid() {
    const parametros = this.obterParametros();
    this.holeriteService
      .filtrar(
        this.paginaCorrente,
        this.login.limite,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.lista = lista.content;
          this.paginaTotal = lista.totalPages;
        },
        () => alert('erro ao retornar lista')
      );
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Admissão', coluna: 'funcionario.data_admissao' },
      { titulo: 'CPF', coluna: 'funcionario.pessoa.cpf' },
      { titulo: 'Nome', coluna: 'funcionario.pessoa.nome' },
      { titulo: 'Valor Bruto', coluna: 'valor_bruto', decimais: 2, alignment: 'right' },
      { titulo: 'Valor Liquido', coluna: 'valor_liquido', decimais: 2, alignment: 'right' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    this.holeriteService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE FUNCIONÁRIOS',
              '', '', this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem Funcionarios', ['auto', 'auto', '*', 'auto', 'auto'], lista.content, ['valor_bruto', 'valor_liquido']);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public editar(id: number) {
    if (!this.filtro || this.filtro === '') {
      this.router.navigate([
        `/folha-pagamento/visualiza`, id, this.paginaCorrente, this.mes, this.ano, this.referencia]);
    } else {
      this.router.navigate([
        `/folha-pagamento/visualiza?`,
        id, this.paginaCorrente, this.mes, this.ano, this.referencia, this.filtro]);
    }
  }

  private preencherExercicio() {
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaExercicios = res.content;
        this.preencherGrid();
      });
  }


}
