import { Component, OnInit, OnChanges, Input, ViewChild, ElementRef, SimpleChanges, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConvenioRecursoService } from './service/convenio-recurso.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ConvenioRecurso, Convenio, Login, FuncaoService, EddyAutoComplete, Recurso } from 'eddydata-lib';
import { RecursoService } from 'administrativo-lib';

@Component({
  selector: 'app-convenio-recurso',
  templateUrl: './convenio-recurso.component.html',
})
export class ConvenioRecursoComponent implements OnInit, OnChanges, OnDestroy {
  
  public entity: ConvenioRecurso;
  public entityTemp: ConvenioRecurso;
  public listaLocais: Array<any>;
  protected unsubscribe: Subject<void> = new Subject();

  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoVariavelAutoComplete: EddyAutoComplete<Recurso>;
  public recursoContraAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoContraAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoVariavelContraAutoComplete: EddyAutoComplete<Recurso>;

  @Input() lista: Array<any>;
  @Input() entidade: Convenio;
  @Input() login: Login;

  @ViewChild('btnAdicionar') public btnAdicionar: ElementRef;
  @ViewChildren('btnsEditar') public btnsEditar: QueryList<ElementRef>;

  constructor(
    private messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected funcaoService: FuncaoService,
    protected recursoService: RecursoService,
    protected conveniorecursoService: ConvenioRecursoService,
  ) { }

  ngOnInit() {
    this.listaLocais = this.lista;
    this.carregarAutoCompletes();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lista) {
      this.listaLocais = this.lista;
      this.carregarAutoCompletes();
    }
  }

  private desabilitarEdicao(desabilitar: boolean) {
    this.btnsEditar.forEach((btn) => btn.nativeElement.disabled = desabilitar)
    this.btnAdicionar.nativeElement.disabled = desabilitar;
  }

  public adicionar() {
    this.entity = new ConvenioRecurso();
    this.entity.convenio = this.entidade;
    this.entity.editavel = true;
    this.entity['salvo'] = false;
    if (!this.listaLocais) {
      this.listaLocais = new Array();
    }
    this.listaLocais.unshift(this.entity);
    this.desabilitarEdicao(true);
  }

  public salvar(item: ConvenioRecurso) {
    try {
      if (!item.recurso) {
        throw new Error('Informe o recurso!');
      }
      // envia a entidade para ser salva no banco -----
      item.editavel = false;
      item['salvo'] = true;
      this.desabilitarEdicao(false);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  public editar(item: ConvenioRecurso) {
    this.entityTemp = JSON.parse(JSON.stringify(item));

    this.entity = item;
    this.entity.editavel = true;
    this.entity['salvo'] = true;
    this.desabilitarEdicao(true)
  }

  public cancelar(item: ConvenioRecurso, index: number) {
    if (item['salvo'] && item.id === this.entityTemp?.id) {
      item.recurso = this.entityTemp.recurso;
      item.aplicacao = this.entityTemp.aplicacao;
      item.aplicacao_variavel = this.entityTemp.aplicacao_variavel;
      item.recurso_contra = this.entityTemp.recurso_contra;
      item.aplicacao_contra = this.entityTemp.aplicacao_contra;
      item.aplicacao_variavel_contra = this.entityTemp.aplicacao_variavel_contra;

      this.entity = item;
      this.entity.editavel = false;
    } else {
      this.listaLocais.splice(index, 1);
    }
    this.desabilitarEdicao(false);
  }

  public confirmarRemocao(item: ConvenioRecurso, index: number) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja remover item?',
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.remover(item, index)
      },
    });
  }

  private remover(item: ConvenioRecurso, index: number) {
    if (item.id) {
      this.conveniorecursoService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaLocais.splice(index, 1);
          this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Exclusão', detail: error.error.payload });
          throw error;
        });
    } else {
      this.listaLocais.splice(index, 1);
    }
  }

  private carregarAutoCompletes() {
    // autocomplete para recurso
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['codigo', 'nome'] }
    );
    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['codigo', 'nome'] }
    );
    // autocomplete para aplicacao variavao
    this.aplicacaoVariavelAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, 'OR': 'ativo$eq=true;!;!;ativo$null=true', orderBy: 'nome' }, { text: ['codigo', 'nome'] }
    );
    
    this.recursoContraAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['codigo', 'nome'] }
    );
    // autocomplete para aplicacao
    this.aplicacaoContraAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['codigo', 'nome'] }
    );
    // autocomplete para aplicacao variavao
    this.aplicacaoVariavelContraAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['codigo', 'nome'] }
    );

  }

}
