// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { SetorAlmoxarifado } from '../almoxarifado/setor-almoxarifado.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { Pessoa } from '../comum/pessoa.model';
import { Usuario } from '../comum/usuario.model';
import { RequisicaoItemMovimento } from './requisicao-item-movimento.model';
import { StatusRequisicao } from './status-requisicao.enum';

export class RequisicaoMovimento extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: number,
    public data_cadastro?: Date,
    public status?: StatusRequisicao,
    public destinatario?: string,
    public motivo_recusado?: string,
    public motivo_devolucao?: string,
    public obs?: string,
    public devolucao?: boolean,
    public data_retirada?: Date,
    public data_estorno?: Date,
    public paciente?: boolean,
    public nome_paciente?: string,
    public mcv_paciente?: string,
    public motivo_estorno?: string,
    public usuario_estorno?: Usuario,
    public recebedor?: Pessoa,
    public setor_almoxarifado?: SetorAlmoxarifado,
    public usuario?: Usuario,
    public usuario_aceite?: Usuario,
    public exercicio?: Exercicio,
    public orgao?: Orgao,
    public itens?: RequisicaoItemMovimento[]
  ) {
    super();
  }
  static converteJson(json: any): RequisicaoMovimento {
    return Object.assign(new RequisicaoMovimento(), json);
  }
}
