// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { OrdemPagamento } from './ordem-pagamento.model';
import { LiquidacaoResto } from '../contabil/liquidacao-resto.model';
import { Liquidacao } from '../contabil/liquidacao.model';
import { EmpenhoExtra } from '../contabil/empenho-extra.model';
import { OrdemPagamentoItemConta } from './ordem-pagamento-item-conta.model';

export class OrdemPagamentoItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public especie?: string,
    public numero?: number,
    public parcela?: number,
    public valor_pago?: number,
    public valor_empenho?: number,
    public obn?: number,
    public ordem?: OrdemPagamento,
    public liquidacao?: Liquidacao,
    public empenho_extra?: EmpenhoExtra,
    public liquidacao_resto?: LiquidacaoResto,
    public contas?: OrdemPagamentoItemConta[],
    public editavel?: boolean
  ) {
    super();
  }

  static converteJson(json: any): OrdemPagamentoItem {
    return Object.assign(new OrdemPagamentoItem(), json);
  }
}
