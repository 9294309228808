import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoginContabil,Licitacao } from 'eddydata-lib';

declare var $: any;

@Component({
  selector: 'lib-rcms-licitacoes-dlg',
  templateUrl: './rcms-licitacoes-dlg.component.html'
})
export class RcmsLicitacoesDlgComponent implements OnInit {

  @Input() public login: LoginContabil;

  @Input() public licitacoes: Licitacao[] = [];
  @Input() public processo: string;
  
  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Output() onSelecionar: EventEmitter<Licitacao> = new EventEmitter();

  @Input() static : boolean = false;

  ngOnInit(): void { }

  public onHide() {
    this.hide();
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public selecionarEvent(licitacao: Licitacao) {
    this.onSelecionar.emit(licitacao);
    this.hide();
  }
}
