import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService } from '../../models/services/base-resource.service';
import { Unidade } from '../../entidade/planejamento/unidade.model';

@Injectable({
  providedIn: 'root'
})
export class UnidadeService extends BaseResourceService<Unidade> {

  constructor(
    protected injector: Injector
  ) {
    super(`unidades`, injector);
  }

  public obterPorCodigo(codigo: string, ppaId: number): Observable<any> {
    return this.http.get<any>(`${ppaId}/${this.api}/filtrar?codigo=${codigo}&ppa_id=${ppaId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorNome(nome: string, ppaId: number): Observable<Unidade> {
    return this.http.get<Unidade>(`${ppaId}/${this.api}/filtrar?nome$like=${nome}%&ppa_id=${ppaId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public proximoNumero(orgao: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-numero/${orgao}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
