import { Component, Injector, OnInit } from '@angular/core';
import { BaseResourceListComponent, Coluna, Compra, Filtro, FormatoExportacao, FuncaoService, GlobalService, LoginContabil, Rcms } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotaRcmsRpt } from '../nota-rcms-rpt/nota-rcms-rpt.component';
import { RcmsService } from '../service/rcms.service';
import { RcmsFavorecidorService } from "../service/rcms-favorecido.service";
import { RcmsItemService } from "../service/rcms-item.service";
import * as toastr from "toastr";


declare var $: any;

@Component({
  selector: 'app-rcms-list',
  templateUrl: './rcms-list.component.html'
})
export class RcmsListComponent extends BaseResourceListComponent<Rcms, LoginContabil> implements OnInit {
  /**
   * Declaração de variáveis
   */

  public ptBR: any;
  public rcms: Rcms = new Rcms();
  public rcmsObservacao: Rcms = new Rcms();
  public visualizar: boolean = false;
  public rcmsImprimir: Rcms[] = [];
  public mes: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public funcaoService: FuncaoService,
    public globalService: GlobalService,
    protected rcmsFavorecidoService: RcmsFavorecidorService,
    protected rcmsItemService: RcmsItemService,
    private rcmsService: RcmsService) {
    super(rcmsService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================
  
  //***********************FILTRO PERSONALIZADO *************************//

  public obterColunasFiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({
      titulo: 'Contrato', coluna: 'contrato.numero', alignment: 'center', tipo: 'String', cols: 2,
      mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }]
    });
    retorno.push({ titulo: 'Convenio', coluna: 'convenio.numero', alignment: 'center', tipo: 'String', cols: 2 });
    retorno.push({ titulo: 'Código Item', coluna: 'itens.produto_unidade.produto.codigo', tipo: 'String', cols: 2 });
    retorno.push({ titulo: 'Data Requisição', coluna: 'data_rcms', tipo: 'Date', alignment: 'center', padrao: true, filtro2: new Date() });
    retorno.push({ titulo: 'Descrição Item', coluna: 'itens.produto_unidade.produto.nome', tipo: 'String' });
    retorno.push({ titulo: 'Despesa', coluna: 'ficha.despesa.codigo', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Subelemento', coluna: 'subelemento.codigo', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Excluído', coluna: 'excluido', tipo: 'Boolean' });
    retorno.push({ titulo: 'Ficha', coluna: 'ficha.numero', alignment: 'center', tipo: 'String', cols: 2 });
    retorno.push({ titulo: 'Fornecedor', coluna: 'favorecidos.favorecido.nome', tipo: 'String' });
    retorno.push({ titulo: 'Licitação', coluna: 'licitacao.numero', alignment: 'center', tipo: 'String', cols: 2, mask: '00000/0000' });
    retorno.push({ titulo: 'Modalidade', coluna: 'modalidade.nome', tipo: 'String', cols: 4 });
    retorno.push({ titulo: 'Nº Requisição', coluna: 'numero', alignment: 'center', tipo: 'Number', padrao: true });
    retorno.push({ titulo: 'Nº OF', coluna: 'compras.numero', alignment: 'center', tipo: 'Number' });
    retorno.push({
      titulo: 'Processo', coluna: 'processo', alignment: 'center', tipo: 'Processo', cols: 2,
      mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }], notUnmask: true
    });
    retorno.push({ titulo: 'Parcialmente Anulada', coluna: 'valid_parcialmente_anulado', tipo: 'Boolean' });
    retorno.push({ titulo: 'Parcialmente Liquidada', coluna: 'valid_parcialmente_liquidado', tipo: 'Boolean' });
    retorno.push({ titulo: 'Parcialmente Paga', coluna: 'valid_parcialmente_pago', tipo: 'Boolean' });
    retorno.push({ titulo: 'Totalmente Liquidada', coluna: 'valid_total_liquidado', tipo: 'Boolean' });
    retorno.push({ titulo: 'Totalmente Anulada', coluna: 'valid_total_anulado', tipo: 'Boolean' });
    retorno.push({ titulo: 'Totalmente Paga', coluna: 'valid_total_pago', tipo: 'Boolean' });
    retorno.push({ titulo: 'Requerente', coluna: 'requerente.nome', tipo: 'String', cols: 4, padrao: true });
    retorno.push({ titulo: 'Usuário', coluna: 'operador.nome', alignment: 'center', tipo: 'String', cols: 4 });

    return retorno;
  }

  protected podeAlterar(item: Rcms): boolean {
    return !item.excluido  && !item.autorizado && (!item['compras_view'] || item['compras_view']?.length === 0);
  }

  //***********************FILTRO PERSONALIZADO*************************//

  protected relations(): string {
    return ['setor', 'licitacao', 'contrato', 'exercicio', 'orgao', 'ficha', 'convenio', 'compras.empenho.liquidacoes',
      'ficha.despesa', 'subelemento', 'requerente', 'modalidade', 'prazo'].join(',');
  }

  protected condicoesGrid(): {} {
    let parametros = {
      ['orgao.id']: this.login.orgao.id,
      ['exercicio.id']: this.login.exercicio.id
    };
    if (!(this.login.usuario.administrador && this.login.usuario.sistemas_administrador?.includes('compras')) && this.login?.estoque?.id)
      parametros['estoque.id'] = this.login.estoque.id;
    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['numero$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['numero', 'ficha.despesa.codigo'],
      date: ['data_rcms'],
      text: ['requerente.nome', 'requerente', 'ficha.despesa.nome', 'contrato.numero', 'processo', 'licitacao.numero', 'modalidade.nome', 'setor.nome'],
    };
  }

  protected afterInit(): void {
    this.ptBR = new GlobalService().obterDataBR();
  }

  public beforeInit(): void {
    this.usarExtendido = true;
    this.usarFiltroPersonalizado = true;
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  protected acaoRemover(model: Rcms): Observable<Rcms> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Número', coluna: 'numero', alignment: 'center' },
      { titulo: 'Data', coluna: 'data_rcms' },
      { titulo: 'Ficha', coluna: 'ficha.numero' },
      { titulo: 'Despesa', coluna: 'ficha.despesa.nome' },
      { titulo: 'Setor', coluna: 'setor.nome' },
      { titulo: 'Requerente', coluna: 'requerente.nome' },
      { titulo: 'Menor Preço Global', coluna: 'menor_preco_global', decimais: 2, alignment: 'right' }
    ];
  }

  public visualizarObservacao(rcms: Rcms) {
    this.rcmsObservacao = rcms;
    $('#dialogObservacao').modal('show');
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = this.relations();
    parametros['orderBy'] = this.ordenacaoGrid().join(',');
    this.rcmsService
      .extendido(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE REQUISIÇÕES',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem rcms', ['auto', 'auto', 'auto', '*', '*', '*', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        (error) => toastr.error(error.error.payload)
      );
  }

  public excluir(rcms: Rcms) {
    this.rcms = rcms;
    this.visualizar = true;
  }

  fechar() {
    $('#dialogObservacao').modal('hide');
  }

  public corEspecieRcms(rcms: Rcms) {
    if (rcms['situacao'] && rcms['situacao'] != 'NAO_EMPENHADO') {
      let cor = 'success';
      if (rcms.excluido){
        cor = 'danger';
      } else if (rcms['situacao'] === 'EMPENHADO') {
        cor = 'warning';
      } else if (rcms.compras.find(c => c?.empenho?.liquidacoes)) {
        cor = this.verificaPago(rcms.compras.find(c => c?.empenho?.liquidacoes), true)
      } else if (rcms['situacao'] === 'LIQUIDADO_PARCIAL') {
        cor = 'info';
      } else if (rcms['situacao'] === 'LIQUIDADO') {
        cor = 'primary';
      }
      let ngCor = {};
      ngCor[`badge-${cor}`] = true;
      return cor;
    } else {
      if (rcms['compras_view'] && rcms['compras_view'].length > 0) {
        return 'dark';
      } else {
        if (rcms.autorizado_compra) {
          if (rcms.autorizado) {
            return 'primary';
          } else {
            return 'warning';
          }
        } else {
          return 'danger';
        }
      }
    }
  }

  public tituloEspecieRcms(rcms: Rcms) {
    if (rcms.cotacao_processo){
      return `Cotação de Processo`;
    }

    if (rcms['situacao'] && rcms['situacao'] != 'NAO_EMPENHADO') {
      if (rcms['situacao'] === 'EMPENHADO') {
        return 'Empenhado';
      }

      if (rcms.compras.find(c => c?.empenho?.liquidacoes)) {
        let status = this.verificaPago(rcms.compras.find(c => c?.empenho?.liquidacoes))

        if (status) {
          return status
        }
      }

      if (rcms['situacao'] === 'LIQUIDADO_PARCIAL'){
        return 'Parcialmente Liquidado';
      }

      if (rcms['situacao'] === 'LIQUIDADO') {
        return 'Liquidado';
      }

      if (rcms['situacao'] === 'ANULADO') {
        return 'Empenhado';
      }

      if (rcms['situacao'] === 'ANULADO_PARCIAL') {
        return 'Empenhado';
      }
    } else {
      if (rcms['compras_view'] && rcms['compras_view'].length > 0) {
        return 'Possui OF';
      } else {
        if (rcms.autorizado_compra) {
          if (rcms.autorizado) {
            return 'Autorizado';
          } else {
            return 'Não autorizada';
          }
        } else {
          return 'Não concluída';
        }
      }
    }
  }

  public verificaPago(item: Compra, cor?: Boolean) {
    let valorEmpenho = +item['empenho']?.valor_empenho
    let valorPago = 0

    item['empenho']?.liquidacoes?.forEach(liquidacao => {
      liquidacao['pagamentos']?.forEach(pg => valorPago += +pg?.valor_pago)
    });

    if (!cor) {
      if (valorPago === valorEmpenho)
        return 'Pago'

      if (valorPago > 0)
        return 'Pago parcialmente'

    } else {
      if (valorPago > 0 && valorPago !== valorEmpenho) return 'info'
      if (item['situacao'] === 'LIQUIDADO') return 'primary'
      if (item['situacao'] === 'LIQUIDADE_PARCIAL') return 'info'
      else return 'warning'
    }
  }

  public imprimirItem(item, nota: NotaRcmsRpt) {
    let p = new Promise<void>((resolve, reject) => {
      if (
        this.login.parametro?.requisicao?.minimo_tres_cotacoes &&
        !(this.login.usuario.administrador && this.login.usuario.sistemas_administrador?.includes('compras')) &&
        !item.processo &&
        !item.licitacao &&
        !item.contrato
      ) {
        let parametros = {
          "orgao.id": this.login.orgao.id,
          id: item.id,
          minimo_tres_cotacoes: true,
        };
        this.rcmsService
          .filtrar(1, -1, parametros)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            if (res.content.length === 0) {
              reject();
            } else {
              resolve();
            }
          });
      } else {
        resolve();
      }
    });
    p.then(() => {
      this.rcmsService
        .obter({
          relations: [
            "setor",
            "licitacao",
            "contrato",
            "contrato_aditamento",
            "exercicio",
            "orgao",
            "ficha",
            "ficha.despesa",
            "subelemento",
            "requerente",
            "modalidade",
            "operador",
            "ficha.executora.unidade",
            "ficha.recurso",
            "ficha.acao",
            "ficha.aplicacao",
            "responsavel_cotacao",
            "responsavel_fiscalizacao",
            "estoque",
          ],
          "exercicio.id": this.login.exercicio.id,
          "orgao.id": this.login.orgao.id,
          id: item.id,
        })
        .subscribe((data) => {
          this.rcmsItemService
            .filtrar(1, -1, {
              relations: [
                "produto_unidade",
                "produto_unidade.produto",
                "produto_unidade.unidade",
                "cotacoes",
                "cotacoes.rcmsFavorecido",
                "cotacoes.rcmsFavorecido.favorecido",
              ],
              "rcms.id": item.id,
              orderBy: ["ordem$ASC", "cotacoes.id$DESC"],
            })
            .subscribe((itens) => {
              this.rcmsFavorecidoService
                .filtrar(1, -1, {
                  relations: ["favorecido,favorecido.tipo"],
                  "rcms.id": item.id,
                  orderBy: "id$DESC",
                })
                .subscribe(
                  (favorecidos) => {
                    data.itens = itens.content;
                    data.favorecidos = favorecidos.content;
                    this.rcmsImprimir = [data];
                    nota.show();
                  },
                  (error) => this.funcaoService.acaoErro(error)
                );
            });
        });
    }).catch(() => {
      toastr.info("Requisição com menos de 3 cotações, impressão cancelada!");
    });
  }
}
