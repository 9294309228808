import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { ProdutoDlgModule } from '../produto/produto-dlg.module';
import { EstornoRequisicaoDlgComponent } from './estorno-requisicao-dlg/estorno-requisicao-dlg.component';
import { RequisicaoFormComponent } from './requisicao-form/requisicao-form.component';
import { RequisicaoItemComponent } from './requisicao-item/requisicao-item.component';
import { RequisicaoListDlgComponent } from './requisicao-list-dlg/requisicao-list-dlg.component';
import { RequisicaoListComponent } from './requisicao-list/requisicao-list.component';
import { RequisicaoRoutingModule } from './requisicao-routing.module';
import { RequisicaoViewComponent } from './requisicao-view/requisicao-view.component';


@NgModule({
  declarations: [
    RequisicaoListComponent,
    RequisicaoFormComponent,
    RequisicaoListDlgComponent,
    RequisicaoItemComponent,
    RequisicaoViewComponent,
    EstornoRequisicaoDlgComponent
  ],
  exports : [
    RequisicaoListComponent,
    RequisicaoFormComponent,
    RequisicaoListDlgComponent,
    EstornoRequisicaoDlgComponent
  ],
  imports: [
    CommonModule,
    RequisicaoRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    RadioButtonModule,
    TooltipModule,
    ProdutoDlgModule
  ],
  providers: [MessageService]
})
export class RequisicaoModule { }
