// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Pessoa } from '../comum/pessoa.model';
import { Setor } from './setor.model';

export class SetorResponsavel extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_inicio?: Date,
    public data_termino?: Date,
    public responsavel?: Pessoa,
    public setor?: Setor,
  ) {
    super();
  }
  static converteJson(json: any): SetorResponsavel {
    return Object.assign(new SetorResponsavel(), json);
  }
}
