// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ChamamentoModelo } from './chamamento-modelo.model';

export class ChamamentoModeloItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public ponto_inicial?: number,
    public ponto_final?: number,
    public modelo?: ChamamentoModelo,
    public editavel?: boolean
  ) {
    super();
  }
  static converteJson(json: any): ChamamentoModeloItem {
    return Object.assign(new ChamamentoModeloItem(), json);
  }
}
