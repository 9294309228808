
import { BaseResourceModel } from '../../models/base-resource.model';
import { Recebimento } from './recebimento.model';
import { TransferenciaBancaria } from './transferencia-bancaria.model';

export class TransferenciaDescendial extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_transferencia?: Date,
    public data_lancto?: Date,
    public descendio?: number,
    public recebimento?: Recebimento,
    public transferenciaBanco?: TransferenciaBancaria,
    public valor_descendio?: number,
    public selecionado?: boolean
  ) {
    super();
  }

  static converteJson(json: any): TransferenciaDescendial {
    return Object.assign(new TransferenciaDescendial(), json);
  }
}
