import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { ContaBancaria } from "./conta-bancaria.model";

export class ConciliacaoBancariaExtrato extends BaseResourceModel {
  constructor(
    public id?: number,
    public valor_janeiro?: number,
    public valor_fevereiro?: number,
    public valor_marco?: number,
    public valor_abril?: number,
    public valor_maio?: number,
    public valor_junho?: number,
    public valor_julho?: number,
    public valor_agosto?: number,
    public valor_setembro?: number,
    public valor_outubro?: number,
    public valor_novembro?: number,
    public valor_dezembro?: number,
    public conta?: ContaBancaria,
    public exercicio?: Exercicio,
    public orgao?: Orgao,) {
    super();
  }
  static converteJson(json: any): ConciliacaoBancariaExtrato {
    return Object.assign(new ConciliacaoBancariaExtrato(), json);
  }
}