import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { LoginPublico, FuncaoService, GlobalService } from 'eddydata-lib';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-transparencia-conta',
  templateUrl: './transparencia-conta.component.html',
  styleUrls: ['./transparencia-conta.component.css']
})
export class TransparenciaContaComponent implements OnInit, OnDestroy {

  public login: LoginPublico = new LoginPublico();
  public data1: string;
  public data2: string;
  public datepipe: DatePipe;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private funcaoServico: FuncaoService,
  ) {
    this.datepipe = new DatePipe('pt');
  }

  ngOnInit() {
    this.login = GlobalService.obterSessaoLogin();
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
    const dt1 = new Date(`01/01/${new Date().getFullYear()}`);
    const dt2 = new Date();
    this.data1 = this.datepipe.transform(dt1, 'yyyy-MM-dd', 'GMT');
    this.data2 = this.datepipe.transform(dt2, 'yyyy-MM-dd', 'GMT');
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
