import { BaseResourceModel, EventoContabil, Exercicio, Orgao, PlanoConta } from 'eddydata-lib';
import { GrupoDepreciacaoItem } from './grupo-depreciacao-item.model';

export class GrupoDepreciacao extends BaseResourceModel {
    constructor(
        public id?: number,
        public grupo_patrimonio?: PlanoConta,
        public conta_depreciacao?: PlanoConta,
        public ficha_variacao?: EventoContabil,
        public exercicio?: Exercicio,
        public orgao?: Orgao,
        public contas?: GrupoDepreciacaoItem[]
    ) {
        super()
    }

    static converteJson(json: any): GrupoDepreciacao {
        return Object.assign(new GrupoDepreciacao(), json)
    }
}