import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FichaReceitaService } from 'administrativo-lib';
import { FuncaoService, GlobalService } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';

@Component({
  selector: 'app-ficha-receita-dlg',
  templateUrl: './ficha-receita-dlg.component.html'
})
export class FichaReceitaDlgComponent {

  @ViewChild('fechar') closeModal: ElementRef

  public ficha: number;
  public data: Date;

  public login = GlobalService.obterSessaoLogin();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private fichaReceitaService: FichaReceitaService,
    private funcaoService: FuncaoService,
    private route: Router
  ) { }

  public consultar(): void {
    toastr.options.positionClass = 'toast-top-left';
    this.fichaReceitaService.obter({
      'numero': this.ficha,
      'orgao.id': this.login.orgao.id,
      'exercicio.id': this.login.exercicio.id,
      relations: ['exercicio', 'orgao'].join(',')
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res) {
          this.closeModal.nativeElement.click();
          this.route.navigateByUrl(`/fichas-receita/${res.id}/visualizar/${this.login.orgao.id}/${this.data ? this.funcaoService.converteDataSQL(this.data) : ''}`);
        } else {
          toastr.warning('Ficha não localizada!');
        }
      })
  }
}
