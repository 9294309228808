import { DatePipe } from '@angular/common';
import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { tsXLXS } from 'ts-xlsx-export';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  Login, ContaBancaria, Recurso, Convenio,
  EddyAutoComplete, FuncaoService, GlobalService, Relatorio, Coluna, PlanoConta, FichaExtra
} from 'eddydata-lib';
import {
  ConvenioService, ContaBancariaService, RecursoService
} from 'administrativo-lib';
import { RecebimentoExtraService } from '../service/recebimento-extra.service';
import { PlanoContaService } from '../../service/plano-conta.service';
import { FichaExtraService } from '../../../ficha-extra/service/ficha-extra.service';

@Component({
  selector: 'app-recebimento-extra-rpt',
  templateUrl: './recebimento-extra-rpt.component.html'
})

export class RecebimentoExtraRptComponent implements OnInit, OnDestroy {
  login: Login = new Login();
  conta: ContaBancaria;
  plano: PlanoConta;
  recurso: Recurso;
  aplicacao: Recurso;
  ficha: FichaExtra;
  convenio: Convenio;
  filtroPlano: boolean;
  filtroConta: boolean;
  filtroConvenio: boolean;
  filtroRecurso: boolean;
  filtroAplicacao: boolean;
  filtroFicha: boolean;
  selectUsuario: boolean;
  public selectedOrdem: string;
  public selectedOrdenacao: string;
  public selectedAgrupamento: string;
  public ptBR: any;
  public listaRelatorio: Array<any>;
  public listaConvenio: Array<Convenio>;
  public contaAutoComplete: EddyAutoComplete<ContaBancaria>;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public fichaAutoComplete: EddyAutoComplete<FichaExtra>;
  public planoAutoComplete: EddyAutoComplete<PlanoConta>;
  public relatorio = '1';
  public dataInicial: Date;
  public dataFinal: Date;
  protected datepipe: DatePipe;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    protected injector: Injector,
    protected messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected contaService: ContaBancariaService,
    protected recursoService: RecursoService,
    protected fichaExtraService: FichaExtraService,
    protected planoService: PlanoContaService,
    protected convenioService: ConvenioService,
    protected recebimentoService: RecebimentoExtraService) {
    this.datepipe = new DatePipe('pt')
  }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.login = GlobalService.obterSessaoLogin();
    this.selectedOrdem = 'ord1';
    this.selectedOrdenacao = 'ASC';
    this.selectedAgrupamento = '';
    this.listaRelatorio = [];
    this.listaRelatorio.push({ label: 'TODOS OS RECEBIMENTOS EXTRAS', value: 1 });
    this.listaRelatorio.push({ label: 'RECEBIMENTOS EXTRAS ANULADOS', value: 2 });
    this.carregarAutoCompletes();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    new GlobalService().calendarMascara();
  }

  public gerarRelatorio() {
    const parametros = {};

    if (!this.dataInicial || !this.dataFinal) {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }

    parametros['ignoreCondObrig'] = true;
    parametros['data_recebimento$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
    parametros['data_recebimento$le'] = this.funcaoService.converteDataSQL(this.dataFinal);
    parametros['orgao.id'] = this.login.orgao.id

    if (this.filtroConvenio) {
      parametros['convenio.numero'] = this.convenio.numero;
    }

    if (this.filtroConta) {
      parametros['conta.id'] = this.conta.id;
    }

    if (this.filtroPlano) {
      parametros['ficha.plano.id'] = this.plano.id;
    }

    if (this.filtroRecurso) {
      parametros['ficha.recurso.id'] = this.recurso.id;
    }

    if (this.filtroAplicacao) {
      parametros['ficha.aplicacao.id'] = this.aplicacao.id;
    }

    if (this.filtroFicha) {
      parametros['ficha.id'] = this.ficha.id;
    }

    if (this.relatorio === '2') {
      parametros['especie'] = 'REA';
    }

    if (this.selectedOrdem === 'ord1') {
      // parametros['orderBy'] = 'data_recebimento$' + this.selectedOrdenacao;
      if (this.selectedAgrupamento === 'ord1') {
        parametros['orderBy'] = 'data_recebimento,ficha.plano.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,data_recebimento$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === 'ord2') {
        parametros['orderBy'] = 'conta.numero_conta,ficha.plano.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,data_recebimento$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === 'ord3') {
        parametros['orderBy'] = 'ficha.numero,ficha.plano.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,data_recebimento$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === '') {
        parametros['orderBy'] = 'data_recebimento$' + this.selectedOrdenacao;
      }
    } else if (this.selectedOrdem === 'ord2') {
      // parametros['orderBy'] = 'conta.numero_conta$' + this.selectedOrdenacao;
      if (this.selectedAgrupamento === 'ord1') {
        parametros['orderBy'] = 'data_recebimento,ficha.plano.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,conta.numero_conta$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === 'ord2') {
        parametros['orderBy'] = 'conta.numero_conta,ficha.plano.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,conta.numero_conta$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === 'ord3') {
        parametros['orderBy'] = 'ficha.numero,ficha.plano.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,conta.numero_conta$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === '') {
        parametros['orderBy'] = 'conta.numero_conta$' + this.selectedOrdenacao;
      }
    } else if (this.selectedOrdem === 'ord3') {
      // parametros['orderBy'] = 'ficha.plano.codigo$' + this.selectedOrdenacao;
      if (this.selectedAgrupamento === 'ord1') {
        parametros['orderBy'] = 'data_recebimento,ficha.plano.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,ficha.plano.codigo$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === 'ord2') {
        parametros['orderBy'] = 'conta.numero_conta,ficha.plano.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,ficha.plano.codigo$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === 'ord3') {
        parametros['orderBy'] = 'ficha.numero,ficha.plano.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,ficha.plano.codigo$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === '') {
        parametros['orderBy'] = 'ficha.receita.nome$' + this.selectedOrdenacao;
      }
    } else if (this.selectedOrdem === 'ord4') {
      // parametros['orderBy'] = 'ficha.numero$' + this.selectedOrdenacao;
      if (this.selectedAgrupamento === 'ord1') {
        parametros['orderBy'] = 'data_recebimento,ficha.plano.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,ficha.numero$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === 'ord2') {
        parametros['orderBy'] = 'conta.numero_conta,ficha.plano.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,ficha.numero$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === 'ord3') {
        parametros['orderBy'] = 'ficha.numero,ficha.plano.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,ficha.numero$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === '') {
        parametros['orderBy'] = 'ficha.numero$' + this.selectedOrdenacao;
      }
    }

    parametros['relations'] = 'conta,ficha,ficha.plano,ficha.recurso,ficha.aplicacao,ficha.aplicacao_variavel';

    this.recebimentoService.filtrar(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((lista) => {
        const listaAgrupada = this.agruparRecebimentos(lista.content)
        Relatorio.imprimirPersonalizado(
          `RECEBIMENTOS EXTRAS ORÇAMENTÁRIOS PERÍODO DE ${this.datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${this.datepipe.transform(this.dataFinal, 'dd/MM/yyyy')} `,
          this.login.usuario.nome,
          this.login.usuario.sobrenome,
          this.login.orgao.nome,
          this.login.brasao,
          listaAgrupada,
          'landscape',
          'Listagem recebimentos extras',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          }
        )
      });
  }

  public agruparRecebimentos(lista: any[]) {
    const total = [];
    const conteudo = [];
    let listaAgrupada: any[];

    if (this.selectedAgrupamento === 'ord1') {
      listaAgrupada = this.funcaoService.agrupar(lista, ['data_recebimento', 'ficha.plano.codigo', 'ficha.recurso.codigo', 'ficha.aplicacao.codigo', 'ficha.aplicacao_variavel.codigo'], ['valor_recebido'])
    } else if (this.selectedAgrupamento === 'ord2') {
      listaAgrupada = this.funcaoService.agrupar(lista, ['conta.numero_conta', 'ficha.plano.codigo', 'ficha.recurso.codigo', 'ficha.aplicacao.codigo', 'ficha.aplicacao_variavel.codigo'], ['valor_recebido'])
    } else if (this.selectedAgrupamento === 'ord3') {
      listaAgrupada = this.funcaoService.agrupar(lista, ['ficha.numero', 'ficha.plano.codigo', 'ficha.recurso.codigo', 'ficha.aplicacao.codigo', 'ficha.aplicacao_variavel.codigo'], ['valor_recebido'])
    } else if (this.selectedAgrupamento === '') {
      listaAgrupada = lista
    }

    conteudo.push([
      { text: `Data` },
      { text: `Conta N˚` },
      { text: `` },
      { text: `Descrição` },
      { text: `Guia` },
      { text: `Código` },
      { text: `Descrição` },
      { text: `Ficha` },
      { text: `Variável` },
      { text: `Valor` },
    ]);

    if (this.selectedAgrupamento !== '') {
      let valor_total = 0;
      for (let item of listaAgrupada) {
        valor_total += +item.totalizadores['valor_recebido'];
        conteudo.push([
          { text: this.funcaoService.converteDataBR(item.registros[0].data_recebimento) },
          { text: item.registros[0].conta.numero_conta },
          { text: item.registros[0].conta.digito_conta },
          { text: item.registros[0].conta.nome },
          { text: item.registros[0].guia },
          { text: item.registros[0].ficha.plano.codigo },
          { text: item.registros[0].ficha.plano.nome },
          { text: item.registros[0].ficha.numero },
          { text: `${item.registros[0].ficha.aplicacao_variavel.codigo} ${item.registros[0].ficha.aplicacao_variavel.nome}` },
          { text: this.funcaoService.formatarMoedaPtBr(item.totalizadores['valor_recebido']) }
        ]);
      }
      total.push([
        { text: 'TOTAL GERAL', alignment: 'center', bold: true },
        { text: '' },
        { text: this.funcaoService.convertToBrNumber(valor_total, 2), alignment: 'center', bold: true }
      ]);
    } else {
      let valor_total = 0;
      for (let item of listaAgrupada) {
        valor_total += +item.valor_recebido;
        conteudo.push([
          { text: this.funcaoService.converteDataBR(item.data_recebimento) },
          { text: item.conta.numero_conta },
          { text: item.conta.digito_conta },
          { text: item.conta.nome },
          { text: item.guia },
          { text: item.ficha.plano.codigo },
          { text: item.ficha.plano.nome },
          { text: item.ficha.numero },
          { text: `${this.validaCampo(item?.ficha?.aplicacao_variavel?.codigo)} ${this.validaCampo(item?.ficha?.aplicacao_variavel?.nome, '')}` },
          { text: this.funcaoService.formatarMoedaPtBr(item.valor_recebido) }
        ]);
      }
      total.push([
        { text: 'TOTAL GERAL', alignment: 'center', bold: true },
        { text: '' },
        { text: this.funcaoService.convertToBrNumber(valor_total, 2), alignment: 'center', bold: true }
      ]);
    }

    return [{
      layout: 'linhas',
      table: {
        widths: [60, 70, 5, 100, 45, 50, '*', 20, 150, 60],
        body: conteudo
      }
    }, {
      layout: 'linhas',
      table: {
        widths: [60, '*', 60],
        body: total
      }
    }];
  }

  private carregarAutoCompletes() {
    // autocomplete para conta
    this.contaAutoComplete = new EddyAutoComplete(
      null,
      this.contaService, "id", ["codigo", "banco.nome", "nome"],
      { orgao_id: this.login.orgao.id, ativo: true, relations: "banco", orderBy: "codigo" },
      { number: ["codigo"], text: ["banco.nome", "nome"] }
    );

    // autocomplete para plano de contas
    this.planoAutoComplete = new EddyAutoComplete(null, this.planoService,
      'id', ['codigo', 'nome'], {
      escrituracao: true, exercicio_id: this.login.exercicio.id,
      orderBy: 'nome'
    }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para recurso
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome', 'codigo'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para ficha extra
    this.fichaAutoComplete = new EddyAutoComplete(null, this.fichaExtraService,
      'id', ['numero', 'nome'], { exercicio_id: this.login.exercicio.id, especie: 'E', orgao_id: this.login.orgao.id, orderBy: 'numero' }, { number: ['numero'], text: ['nome'] }
    );
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }

  private validaCampo(campo: string, alternativo?: string) {
    if (campo) return campo
    else return alternativo !== undefined ? alternativo : 'Não informado'
  }

  public voltar() {
    if (this.login.sistema === 'contabil') {
      this.router.navigate(['/tesouraria']);
    } else {
      this.sair();
    }
  }
}
