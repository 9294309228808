// tslint:disable: variable-name
import { Orgao } from 'eddydata-lib';
import { BaseResourceModel } from '../../models/base-resource.model';

export class ContaBancariaEntidade extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public nome?: string,
    public cnpj?: string,
    public ativo?: boolean,
    public orgao?: Orgao,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
  ) {
    super();
  }

  static converteJson(json: any): ContaBancariaEntidade {
    return Object.assign(new ContaBancariaEntidade(), json);
  }
}
