import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, Recurso } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class RecursoService extends BaseResourceService<Recurso> {

  constructor(
    protected injector: Injector
  ) {
    super(`recursos`, injector);
  }

  public obterPorCodigo(codigo: string, nivel: number, cidadeId: number): Observable<any> {
    return this.http.get<any>(`${cidadeId}/${this.api}/filtrar?codigo=${codigo}&nivel=${nivel}&cidade_id=${cidadeId}`,
      this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public obterPorNome(nome: string, nivel: number, cidadeId: number): Observable<Recurso> {
    return this.http.get<Recurso>(`${cidadeId}/${this.api}/filtrar?nome$like=${nome}%&nivel=${nivel}&cidade_id=${cidadeId}`,
      this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
