import { Component, Input } from '@angular/core';
import {
  BaseResourceItemsComponent,
  Convenio,
  ConvenioAlteracao,
  DateFormatPipe,
  FuncaoService,
  GlobalService,
  TipoConvenio
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import * as toastr from 'toastr';
import { ConvenioAlteracaoService } from './service/convenio-alteracao.service';

declare var $: any;

@Component({
  selector: 'lib-convenio-alteracao',
  templateUrl: './convenio-alteracao.component.html'
})
export class ConvenioAlteracaoComponent extends BaseResourceItemsComponent<ConvenioAlteracao> {

  public ptBR: any;
  public itemAtual: ConvenioAlteracao;

  @Input() public listaTipoConvenio: TipoConvenio[];
  @Input() public convenio: Convenio;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    protected messageService: MessageService,
    protected globalService: GlobalService,
    public funcaoService: FuncaoService,
    public convenioService: ConvenioAlteracaoService) {
    super(new ConvenioAlteracao(), convenioService);
  }

  protected afterInit(): void {
    this.ptBR = this.globalService.obterDataBR();
  }

  protected validSave(item: ConvenioAlteracao): boolean {
    if (!item.data_alteracao) {
      toastr.warning('Informe a data de alteção!');
      return false;
    }
    return true;
  }

  protected beforeSave(item: ConvenioAlteracao): void { }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public adicionar() {
    this.itemAtual = new ConvenioAlteracao();
    this.itemAtual.data_inicio = this.convenio.data_inicio;
    this.itemAtual.data_termino = this.convenio.data_termino;
    this.itemAtual.processo = this.convenio.processo;
    this.itemAtual.usuario = this.login.usuario;
    this.itemAtual.editavel = true;
    $('#dialogHistorico').modal('show');
  }

  public editar(item: ConvenioAlteracao) {
    this.itemAtual = item;
    // this.entity.editavel = true;
    this.itemAtual.data_inicio = new DateFormatPipe().transform(item.data_inicio, []);
    this.itemAtual.data_termino = new DateFormatPipe().transform(item.data_termino, []);
    $('#dialogHistorico').modal('show');
  }

  
  public salvarAlteracao() {
    try {
      if (!this.itemAtual.data_alteracao) {
        throw new Error('Informe a data do alteração!');
      }
      if (!this.funcaoService.podeAlterarAudesp(this.itemAtual.data_alteracao, this.login)) {
        throw new Error('Data de alteração inválida, período na contabilidade já está fechado, entre em contato com o contador.');
      }
      // envia a entidade para ser salva no banco -----
      this.itemAtual.usuario = this.login.usuario;
      this.itemAtual.convenio = this.convenio;
      this.itemAtual.editavel = false;
      if (!this.lista) {
        this.lista = new Array();
      }
      if (!this.itemAtual.id) {
        console.log(this.itemAtual);
        this.convenioService.inserir(this.itemAtual).subscribe((dados) => {
          this.messageService.add({ severity: 'info', summary: 'Validação', detail: 'Registro inserido com sucesso!' });
          $('#dialogHistorico').modal('hide');
          this.lista.unshift(this.itemAtual);
        },
          (error) => this.messageService.add(
            { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
          )
        );
      } else {
        this.convenioService.atualizar(this.itemAtual).subscribe((dados) => {
          this.messageService.add({ severity: 'info', summary: 'Validação', detail: 'Registro atualizado com sucesso!' });
          $('#dialogHistorico').modal('hide');
        },
          (error) => this.messageService.add(
            { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
          )
        );
      }
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  /**
  * Método para verificação de objetos, usados em combos `<select>`
 */
  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }


}