import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResourceListComponent, Coluna, Credito, Filtro, GlobalService, LoginContabil } from 'eddydata-lib';
import { CreditoService } from '../../credito/service/credito.service';

declare var $: any;

@Component({
  selector: 'app-transferencia-interna-list',
  templateUrl: './transferencia-interna-list.component.html'
})
export class TransferenciaInternaListComponent extends BaseResourceListComponent<Credito, LoginContabil> {

  /**
   * Declaração de variáveis
   */


  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public globalService: GlobalService,
    protected creditoService: CreditoService) {
    super(creditoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'orgao,exercicio,itens.ficha';
  }

  protected condicoesGrid(): {} {
    return {
      ['exercicio.id']: this.login.exercicio.id,
      ['orgao.id']: this.login.orgao.id,
      ['ativo']: true,
      ['ocorrencia']: '99'
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['data_credito$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      date: ['data_credito'],
      text: ['itens.ficha.numero', 'decreto', 'justificativa'],
      number: ['id']
    };
  }

  protected afterInit(): void {
  }

  public beforeInit(): void {
    this.usarFiltroPersonalizado = true;
    this.usarExtendido = true;
  }

  protected acaoRemover(model: Credito): Observable<Credito> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Decreto', coluna: 'decreto', bold: true },
      { titulo: 'Crédito', coluna: 'data_credito' },
      { titulo: 'Ocorrência', coluna: 'ocorrencia' }
    ];
  }

  public abrirDlgImpressao() {
    $('#rptTransferenciaInterna').modal('show');
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public reload() {
    this.preencherGrid();
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/planejamento']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public obterColunasFiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];

    retorno.push({ titulo: 'Decreto', coluna: 'decreto_interno', alignment: 'center', tipo: 'String', padrao: true, cols: 3 });
    retorno.push({ titulo: 'Data', coluna: 'data_credito', tipo: 'Date', padrao: true, cols: 3 });
    retorno.push({ titulo: 'Justificativa', coluna: 'justificativa', alignment: 'center', tipo: 'String', cols: 3 });

    return retorno;
  }
}
