
import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ReservaConvenioEstadual } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ReservaConvenioEstadualService extends BaseResourceService<ReservaConvenioEstadual> {

  constructor(
    protected injector: Injector
  ) {
    super(`reservas-convenio-estadual`, injector);
  }

}
