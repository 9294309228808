import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnidadeListComponent } from './unidade-list/unidade-list.component';
import { UnidadeFormComponent } from './unidade-form/unidade-form.component';
import { UnidadeViewComponent } from './unidade-view/unidade-view.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: ':orgao', component: UnidadeListComponent, canActivate: [AuthGuard] },
  { path: 'novo/:orgao', component: UnidadeFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar/:orgao', component: UnidadeViewComponent, canActivate: [AuthGuard] },
  { path: ':id/editar/:orgao', component: UnidadeFormComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UnidadeRoutingModule { }
