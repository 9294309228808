import { Directive, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import * as toastr from 'toastr';
import { Login } from "../entidade/login/login";
import { FuncaoService } from "../util/funcao.service";
import { GlobalService } from "../util/global.service";
import { BaseResourceFormComponent } from "./base-resource-form";
import { BaseResourceModel } from "./base-resource.model";
import { BaseResourceService } from "./services/base-resource.service";


@Directive()
export abstract class BaseResourceFormDlgComponent<T extends BaseResourceModel, U extends Login>
    extends BaseResourceFormComponent<T, U> implements OnChanges {


    // ========================================================================
    // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
    // ========================================================================
    @Input() public entidade: T;
    @Output() public entidadeChange: EventEmitter<T> = new EventEmitter();


    // ========================================================================
    // ------------------------------ CONSTRUTOR ------------------------------
    // ========================================================================

    constructor(
        protected injector: Injector,
        protected jsonDataToResourceFn: (json: any) => T,
        protected baseResourceService: BaseResourceService<T>,
        protected route: string) {
        super(null, injector, jsonDataToResourceFn, baseResourceService);
    }


    // ========================================================================
    // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
    // ========================================================================
    public abstract sair(): void;

    // ========================================================================
    // -------------------------- MÉTODOS DA CLASSE ---------------------------
    // ========================================================================
    ngOnChanges(changes: SimpleChanges): void {
        if (!this.login) this.login = GlobalService.obterSessaoLogin() as U;
        if (changes.entidade) {
            if (this.entidade && this.entidade.id)
                this.loadForm(false);
            else
                this.criarCamposForm()
        }
    }

    ngOnInit() {
        this.login = GlobalService.obterSessaoLogin() as U;
        this.ptBR = new GlobalService().obterDataBR();
        toastr.options.positionClass = 'toast-top-left';
        new GlobalService().convertEnterParaTab();
        if (!this.podeIncluir()) {
            toastr.warning('Você não possui permissão para este acesso!');
            this.sair();
            return;
        }
        if (!this.readOnly) {
            this.criarCamposForm();
            if (this.validTamanhoForm)
                this.incluirLimiteCampos();
        }
        this.loadForm(false);
        this.afterInit();
    }

    private loadForm(emit?: boolean) {
        this.setCurrentActionRoute();
        const x = this.loadResource(emit).then(() => {
            window.scrollTo(0, 0);
            if (!this.readOnly && this.campoFoco()) {
                new FuncaoService().focarCampo(this.campoFoco());
            }
        });
    }


    public podeIncluir(url?: string) {
        if (this.login) {
            if (this.administrador()) {
                return true;
            }
            if (!url) {
                url = this.route;
            }
            return new FuncaoService().podeIncluir(this.login, url)
        }
        return false;
    }


    protected setCurrentActionRoute() {
        this.currentActionRoute = undefined;
        this.currentActionRoute = !this.entidade || !this.entidade.id ? 'novo' : 'editar';
    }

    protected async loadResource(emit?: boolean) {
        if (this.currentActionRoute === 'editar' && this.entidade && this.entidade.id) {
            this.baseResourceService.obter(
                Object.assign({}, { id: this.entidade.id }, this.parametrosExtras())
            ).pipe(takeUntil(this.unsubscribe))
                .subscribe(
                    (entidade) => {
                        if (entidade) {
                            this.entidade = entidade;
                            if (emit)
                                this.entidadeChange.emit(this.entidade);
                            this.afterLoad();
                            if (!this.readOnly) {
                                this.entidadeForm.patchValue(entidade);
                            }
                        } else {
                            this.sair();
                        }
                    }, (error) => {
                        this.sair();
                    }
                );
        }
    }

    protected acaoSucesso(entidade: T) {
        this.entidade = entidade;
        this.entidadeChange.emit(this.entidade);
        this.loadForm(true);
        this.afterSubmit(entidade);
        if (this.mensagemSucesso) toastr.success(this.mensagemSucesso);
        this.limparTela = false;
    }


}