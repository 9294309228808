import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../assinaturas/assinaturas';
import { AnexoLRFService } from '../../service/anexo-lrf.service';

@Directive()
export class BalancoOrcamentariolLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLRFService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected assinaturaControleInterno: boolean) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao = 'pdf') {
    this.anexoServico.obterBalancoOrcamentario(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {

        if (formato === 'pdf') {
          //Buscar assinatura
          const ass = new Assinaturas(this.login.orgao, this.injector);
          let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);
          let teste = [{
            layout: 'linhas',
            table: {
              dontBreakRows: true,
              headerRows: 0,
              widths: ['*', '*', '*'],
              body: assinaturas
            }
          }
          ];
          Relatorio.imprimirPersonalizado(
            `RELATÓRIO RESUMIDO DA EXECUÇÃO ORÇAMENTÁRIA - RREO`
            , this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            this.conteudoReceita(dados[0], dados[1]).concat(this.conteudoDespesa(dados[1], dados[0])).concat(teste),
            'landscape', 'RELATÓRIO RESUMIDO DA EXECUÇÃO ORÇAMENTÁRIA - RREO',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, true, false);
        } else {
          this.funcaoService.exportar(formato, this.normalizar(dados[0], dados[1]), `RELATÓRIO RESUMIDO DA EXECUÇÃO ORÇAMENTÁRIA - RREO`, this.colunas() as Coluna[]);
        }
      });
  }

  public normalizar(lista: any[], lista2: any[]) {

    const gruposReceitaTotal = this.funcaoService.agrupar(lista2, 'tipo',
      ['dotacao', 'adicionais', 'empenhado', 'liquidado', 'pago']);

    let totalLiquidado = 0;
    for (const grupo of gruposReceitaTotal) {
      totalLiquidado = totalLiquidado + grupo.totalizadores['liquidado'];
    }

    const gruposReceita = this.funcaoService.agrupar(lista, 'tipo',
      ['previsao', 'atualizado', 'previsto_total', 'arrecadado']);

    const totalizador = new Array<any>();
    let sub2 = 0;
    let sub3 = 0;
    let sub4 = 0;
    let sub5 = 0;
    let sub6 = 0;

    let op2 = 0;
    let op3 = 0;
    let op4 = 0;
    let op5 = 0;
    let op6 = 0;

    const listaExportar = []

    for (const grupo of gruposReceita) {
      if (grupo.grupo === 'OPERAÇÕES DE CRÉDITO - REFINANCIAMENTO ( II )') {
        op2 = op2 + +grupo.totalizadores[`previsao`];
        op3 = op3 + +grupo.totalizadores[`atualizado`];
        op4 = op4 + +grupo.totalizadores[`previsto_total`];
        op5 = op5 + +grupo.totalizadores[`arrecadado`];
        op6 = op6 + +grupo.totalizadores[`arrecadado`] - +grupo.totalizadores[`atualizado`];
        continue
      }

      if (grupo.grupo === '(-) DEDUÇÕES DA RECEITA') {
        const saldoRealizar = (+grupo.totalizadores[`atualizado`] - +grupo.totalizadores[`arrecadado`]);
        sub2 -= +grupo.totalizadores[`previsao`];
        sub3 -= +grupo.totalizadores[`atualizado`];
        sub4 -= +grupo.totalizadores[`previsto_total`];
        sub5 -= +grupo.totalizadores[`arrecadado`];
        sub6 -= +saldoRealizar;

        const linhaGrupo = {
          receitas: grupo.grupo,
          prev_inicial: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`previsao`]),
          prev_atualizada: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`atualizado`]),
          prev_bimestre: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`previsto_total`]),
          real_bimestre: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`arrecadado`]),
          saldo_realizar: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`atualizado`] - +grupo.totalizadores[`arrecadado`]),
          pago_bimestre: '',
          saldo_empenhar: '',
          saldo_liquidar: '',
          saldo_pagar: ''
        }
        listaExportar.push(linhaGrupo)
        continue;
      }

      if (grupo.grupo === 'RECEITAS INTRAORÇAMENTÁRIAS') {
        const saldoRealizar = (+grupo.totalizadores[`atualizado`] - +grupo.totalizadores[`arrecadado`]);
        sub2 = sub2 + +grupo.totalizadores[`previsao`];
        sub3 = sub3 + +grupo.totalizadores[`atualizado`];
        sub4 = sub4 + +grupo.totalizadores[`previsto_total`];
        sub5 = sub5 + +grupo.totalizadores[`arrecadado`];
        sub6 = sub6 + +saldoRealizar;

        const linhaGrupoReceita = {
          receitas: grupo.grupo,
          prev_inicial: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`previsao`]),
          prev_atualizada: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`atualizado`]),
          prev_bimestre: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`previsto_total`]),
          real_bimestre: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`arrecadado`]),
          saldo_realizar: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`atualizado`] - +grupo.totalizadores[`arrecadado`]),
          pago_bimestre: '',
          saldo_empenhar: '',
          saldo_liquidar: '',
          saldo_pagar: ''
        }
        listaExportar.push(linhaGrupoReceita)
        continue;
      }

      else {
        const saldoRealizar = (+grupo.totalizadores[`atualizado`] - +grupo.totalizadores[`arrecadado`]);
        sub2 += +grupo.totalizadores[`previsao`];
        sub3 += +grupo.totalizadores[`atualizado`];
        sub4 += +grupo.totalizadores[`previsto_total`];
        sub5 += +grupo.totalizadores[`arrecadado`];
        sub6 += +saldoRealizar;
      }

      const linhaGrupo = {
        receitas: grupo.grupo,
        prev_inicial: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`previsao`]),
        prev_atualizada: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`atualizado`]),
        prev_bimestre: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`previsto_total`]),
        real_bimestre: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`arrecadado`]),
        saldo_realizar: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`atualizado`] - +grupo.totalizadores[`arrecadado`]),
        pago_bimestre: '',
        saldo_empenhar: '',
        saldo_liquidar: '',
        saldo_pagar: ''
      }
      listaExportar.push(linhaGrupo)

      if (grupo.grupo === 'RECEITAS CORRENTES' || grupo.grupo === 'RECEITAS DE CAPITAL') {
        for (const item of grupo.registros) {
          const linha = {
            receitas: item.nome,
            prev_inicial: this.funcaoService.convertToBrNumber(item.previsao),
            prev_atualizada: this.funcaoService.convertToBrNumber(item.atualizado),
            prev_bimestre: this.funcaoService.convertToBrNumber(item.previsto_total),
            real_bimestre: this.funcaoService.convertToBrNumber(item.arrecadado),
            saldo_realizar: this.funcaoService.convertToBrNumber(+item.atualizado - +item.arrecadado),
            pago_bimestre: '',
            saldo_empenhar: '',
            saldo_liquidar: '',
            saldo_pagar: ''
          }
          listaExportar.push(linha)
        }
      }
    }

    const linhaSubtotalReceitas = {
      receitas: 'SUBTOTAL DAS RECEITAS ( I )',
      prev_inicial: this.funcaoService.convertToBrNumber(sub2),
      prev_atualizada: this.funcaoService.convertToBrNumber(sub3),
      prev_bimestre: this.funcaoService.convertToBrNumber(sub4),
      real_bimestre: this.funcaoService.convertToBrNumber(sub5),
      saldo_realizar: this.funcaoService.convertToBrNumber(sub6),
      pago_bimestre: '',
      saldo_empenhar: '',
      saldo_liquidar: '',
      saldo_pagar: ''
    }
    listaExportar.push(linhaSubtotalReceitas)

    const linhaOperacoesCredito = {
      receitas: 'OPERAÇÕES DE CRÉDITO - REFINANCIAMENTO ( II )',
      prev_inicial: this.funcaoService.convertToBrNumber(op2),
      prev_atualizada: this.funcaoService.convertToBrNumber(op3),
      prev_bimestre: this.funcaoService.convertToBrNumber(op4),
      real_bimestre: this.funcaoService.convertToBrNumber(op5),
      saldo_realizar: this.funcaoService.convertToBrNumber(op6),
      pago_bimestre: '',
      saldo_empenhar: '',
      saldo_liquidar: '',
      saldo_pagar: ''
    }
    listaExportar.push(linhaOperacoesCredito)

    const linhaSubtotalRefinanciamento = {
      receitas: 'SUBTOTAL COM REFINANCIAMENTO ( III ) = (I + II)',
      prev_inicial: this.funcaoService.convertToBrNumber(sub2 + op2),
      prev_atualizada: this.funcaoService.convertToBrNumber(sub3 + op3),
      prev_bimestre: this.funcaoService.convertToBrNumber(sub4 + op4),
      real_bimestre: this.funcaoService.convertToBrNumber(sub5 + op5),
      saldo_realizar: this.funcaoService.convertToBrNumber(sub6 + op6),
      pago_bimestre: '',
      saldo_empenhar: '',
      saldo_liquidar: '',
      saldo_pagar: ''
    }
    listaExportar.push(linhaSubtotalRefinanciamento)

    const linhaDeficit = {
      receitas: 'DÉFICIT ( IV )',
      prev_inicial: '',
      prev_atualizada: '',
      prev_bimestre: '',
      real_bimestre: totalLiquidado > (sub5 + op5) ? this.funcaoService.convertToBrNumber(totalLiquidado - (sub5 + op5)) : '0,00',
      saldo_realizar: '',
      pago_bimestre: '',
      saldo_empenhar: '',
      saldo_liquidar: '',
      saldo_pagar: ''
    }
    listaExportar.push(linhaDeficit)

    const linhaTotal = {
      receitas: 'TOTAL ( V ) = ( III + IV )',
      prev_inicial: this.funcaoService.convertToBrNumber(sub2 + op2),
      prev_atualizada: this.funcaoService.convertToBrNumber(sub3 + op3),
      prev_bimestre: this.funcaoService.convertToBrNumber(sub4 + op4),
      real_bimestre: totalLiquidado > (sub5 + op5) ?
        this.funcaoService.convertToBrNumber((totalLiquidado - (sub5 + op5)) +
          (sub5 + op5)) : this.funcaoService.convertToBrNumber(sub5 + op5),
      saldo_realizar: this.funcaoService.convertToBrNumber(sub6 + op6),
      pago_bimestre: '',
      saldo_empenhar: '',
      saldo_liquidar: '',
      saldo_pagar: ''
    }
    listaExportar.push(linhaTotal)

    const gruposDespesaTotal = this.funcaoService.agrupar(lista, 'tipo',
      ['previsao', 'atualizado', 'previsto_total', 'arrecadado']);

    let totalArrecadado = 0;
    for (const item of gruposDespesaTotal) {
      totalArrecadado = item.grupo !== '(-) DEDUÇÕES DA RECEITA' ?
        totalArrecadado + item.totalizadores['arrecadado'] :
        totalArrecadado - item.totalizadores['arrecadado'];
    }

    const espaco = {
      receitas: '',
      prev_inicial: '',
      prev_atualizada: '',
      prev_bimestre: '',
      real_bimestre: '',
      saldo_realizar: '',
      pago_bimestre: '',
      saldo_empenhar: '',
      saldo_liquidar: '',
      saldo_pagar: ''
    }
    listaExportar.push(espaco)

    const linhaTituloDespesa = {
      receitas: 'DESPESAS',
      prev_inicial: 'DOTAÇÃO INICIAL ANUAL',
      prev_atualizada: 'CRÉDITOS ADICIONAIS/ANULAÇÕES',
      prev_bimestre: 'DOTAÇÃO ATUALIZADA ANUAL',
      real_bimestre: 'EMPENHADO ATÉ O BIMESTRE',
      saldo_realizar: 'LIQUIDADO ATÉ O BIMESTRE',
      pago_bimestre: 'PAGO ATÉ O BIMESTRE',
      saldo_empenhar: 'SALDO A EMPENHAR',
      saldo_liquidar: 'SALDO A LIQUIDAR',
      saldo_pagar: 'SALDO A PAGAR'
    }
    listaExportar.push(linhaTituloDespesa)

    const gruposDespesa = this.funcaoService.agrupar(lista2, 'tipo',
      ['dotacao', 'adicionais', 'empenhado', 'liquidado', 'pago']);

    for (const grupo of gruposDespesa) {

      if (grupo.grupo !== 'AMORTIZAÇÃO DA DÍVIDA - REFINANCIAMENTOS (IX)' && grupo.grupo !== 'RESERVA DE CONTINGÊNCIA'
        && grupo.grupo !== 'DESPESAS INTRAORÇAMENTÁRIAS') {
        const linhaGrupoDespesa = {
          receitas: grupo.grupo,
          prev_inicial: this.funcaoService.convertToBrNumber(grupo.totalizadores[`dotacao`]),
          prev_atualizada: this.funcaoService.convertToBrNumber(grupo.totalizadores[`adicionais`]),
          prev_bimestre: +grupo.totalizadores[`dotacao`] + +grupo.totalizadores[`adicionais`],
          real_bimestre: this.funcaoService.convertToBrNumber(grupo.totalizadores[`empenhado`]),
          saldo_realizar: this.funcaoService.convertToBrNumber(grupo.totalizadores[`liquidado`]),
          pago_bimestre: this.funcaoService.convertToBrNumber(grupo.totalizadores[`pago`]),
          saldo_empenhar: this.funcaoService.convertToBrNumber((+grupo.totalizadores[`dotacao`] + +grupo.totalizadores[`adicionais`]) - grupo.totalizadores[`empenhado`]),
          saldo_liquidar: this.funcaoService.convertToBrNumber(grupo.totalizadores[`empenhado`] - grupo.totalizadores[`liquidado`]),
          saldo_pagar: this.funcaoService.convertToBrNumber(grupo.totalizadores[`liquidado`] - grupo.totalizadores[`pago`])
        }
        listaExportar.push(linhaGrupoDespesa)

        for (const item of grupo.registros) {
          const linhaItemDespesa = {
            receitas: item.nome,
            prev_inicial: this.funcaoService.convertToBrNumber(item.dotacao),
            prev_atualizada: this.funcaoService.convertToBrNumber(item.adicionais),
            prev_bimestre: +item.dotacao + +item.adicionais,
            real_bimestre: this.funcaoService.convertToBrNumber(item.empenhado),
            saldo_realizar: this.funcaoService.convertToBrNumber(item.liquidado),
            pago_bimestre: this.funcaoService.convertToBrNumber(item.pago),
            saldo_empenhar: this.funcaoService.convertToBrNumber((+item.dotacao + +item.adicionais) - item.empenhado),
            saldo_liquidar: this.funcaoService.convertToBrNumber(item.empenhado - item.liquidado),
            saldo_pagar: this.funcaoService.convertToBrNumber(item.liquidado - item.pago)
          }
          listaExportar.push(linhaItemDespesa)
        }
      }

      totalizador.push({
        nome: grupo.grupo,
        col2: grupo.totalizadores['dotacao'],
        col3: grupo.totalizadores['adicionais'],
        col4: +grupo.totalizadores[`dotacao`] + +grupo.totalizadores[`adicionais`],
        col5: grupo.totalizadores['empenhado'],
        col6: grupo.totalizadores['liquidado'],
        col7: grupo.totalizadores['pago'],
        col8: (+grupo.totalizadores[`dotacao`] + +grupo.totalizadores[`adicionais`]) - +grupo.totalizadores[`empenhado`],
        col9: grupo.totalizadores[`empenhado`] - +grupo.totalizadores[`liquidado`],
        col10: grupo.totalizadores[`liquidado`] - +grupo.totalizadores[`pago`],
      });
    }

    let subDes2 = 0;
    let subDes3 = 0;
    let subDes4 = 0;
    let subDes5 = 0;
    let subDes6 = 0;
    let subDes7 = 0;
    let subDes8 = 0;
    let subDes9 = 0;
    let subDes10 = 0;

    let opDes2 = 0;
    let opDes3 = 0;
    let opDes4 = 0;
    let opDes5 = 0;
    let opDes6 = 0;
    let opDes7 = 0;
    let opDes8 = 0;
    let opDes9 = 0;
    let opDes10 = 0;

    let intraDes2 = 0;
    let intraDes3 = 0;
    let intraDes4 = 0;
    let intraDes5 = 0;
    let intraDes6 = 0;
    let intraDes7 = 0;
    let intraDes8 = 0;
    let intraDes9 = 0;
    let intraDes10 = 0;

    let resDes2 = 0;
    let resDes3 = 0;
    let resDes4 = 0;

    for (const val of totalizador) {
      if (val.nome === 'AMORTIZAÇÃO DA DÍVIDA - REFINANCIAMENTOS (IX)') {
        opDes2 = opDes2 + val.col2;
        opDes3 = opDes3 + val.col3;
        opDes4 = opDes4 + val.col4;
        opDes5 = opDes5 + val.col5;
        opDes6 = opDes6 + val.col6;
        opDes7 = opDes7 + val.col7;
        opDes8 = opDes8 + val.col8;
        opDes9 = opDes9 + val.col9;
        opDes10 = opDes10 + val.col10;
      } else if (val.nome === 'DESPESAS INTRAORÇAMENTÁRIAS') {
        intraDes2 = intraDes2 + val.col2;
        intraDes3 = intraDes3 + val.col3;
        intraDes4 = intraDes4 + val.col4;
        intraDes5 = intraDes5 + val.col5;
        intraDes6 = intraDes6 + val.col6;
        intraDes7 = intraDes7 + val.col7;
        intraDes8 = intraDes8 + val.col8;
        intraDes9 = intraDes9 + val.col9;
        intraDes10 = intraDes10 + val.col10;
      } else if (val.nome === 'RESERVA DE CONTINGÊNCIA') {
        resDes2 = resDes2 + val.col2;
        resDes3 = resDes3 + val.col3;
        resDes4 = resDes4 + val.col4;
      } else {
        subDes2 = subDes2 + val.col2;
        subDes3 = subDes3 + val.col3;
        subDes4 = subDes4 + val.col4;
        subDes5 = subDes5 + val.col5;
        subDes6 = subDes6 + val.col6;
        subDes7 = subDes7 + val.col7;
        subDes8 = subDes8 + val.col8;
        subDes9 = subDes9 + val.col9;
        subDes10 = subDes10 + val.col10;
      }
    }

    const linhaReservaContingencia = {
      receitas: 'RESERVA DE CONTINGÊNCIA',
      prev_inicial: this.funcaoService.convertToBrNumber(resDes2),
      prev_atualizada: this.funcaoService.convertToBrNumber(resDes3),
      prev_bimestre: this.funcaoService.convertToBrNumber(resDes4),
      real_bimestre: '',
      saldo_realizar: '',
      pago_bimestre: '',
      saldo_empenhar: '',
      saldo_liquidar: '',
      saldo_pagar: '',
    }
    listaExportar.push(linhaReservaContingencia)

    const linhaDespesaIntraorcamentaria = {
      receitas: 'DESPESAS INTRAORÇAMENTÁRIAS',
      prev_inicial: this.funcaoService.convertToBrNumber(intraDes2),
      prev_atualizada: this.funcaoService.convertToBrNumber(intraDes3),
      prev_bimestre: this.funcaoService.convertToBrNumber(intraDes4),
      real_bimestre: this.funcaoService.convertToBrNumber(intraDes5),
      saldo_realizar: this.funcaoService.convertToBrNumber(intraDes6),
      pago_bimestre: this.funcaoService.convertToBrNumber(intraDes7),
      saldo_empenhar: this.funcaoService.convertToBrNumber(intraDes8),
      saldo_liquidar: this.funcaoService.convertToBrNumber(intraDes9),
      saldo_pagar: this.funcaoService.convertToBrNumber(intraDes10),
    }
    listaExportar.push(linhaDespesaIntraorcamentaria)

    const linhaSubDespesa = {
      receitas: 'SUBTOTAL DAS DESPESAS ( VIII )',
      prev_inicial: this.funcaoService.convertToBrNumber(subDes2 + resDes2 + intraDes2),
      prev_atualizada: this.funcaoService.convertToBrNumber(subDes3 + resDes3 + intraDes3),
      prev_bimestre: this.funcaoService.convertToBrNumber(subDes4 + resDes4 + intraDes4),
      real_bimestre: this.funcaoService.convertToBrNumber(subDes5 + intraDes5),
      saldo_realizar: this.funcaoService.convertToBrNumber(subDes6 + intraDes6),
      pago_bimestre: this.funcaoService.convertToBrNumber(subDes7 + intraDes7),
      saldo_empenhar: this.funcaoService.convertToBrNumber(subDes8 + intraDes8),
      saldo_liquidar: this.funcaoService.convertToBrNumber(subDes9 + intraDes9),
      saldo_pagar: this.funcaoService.convertToBrNumber(subDes10 + intraDes10),
    }
    listaExportar.push(linhaSubDespesa)

    const linhaAmortizacaoDespesa = {
      receitas: 'AMORTIZAÇÃO DA DÍVIDA - REFINANCIAMENTOS (IX)',
      prev_inicial: this.funcaoService.convertToBrNumber(opDes2),
      prev_atualizada: this.funcaoService.convertToBrNumber(opDes3),
      prev_bimestre: this.funcaoService.convertToBrNumber(opDes4),
      real_bimestre: this.funcaoService.convertToBrNumber(opDes5),
      saldo_realizar: this.funcaoService.convertToBrNumber(opDes6),
      pago_bimestre: this.funcaoService.convertToBrNumber(opDes7),
      saldo_empenhar: this.funcaoService.convertToBrNumber(opDes8),
      saldo_liquidar: this.funcaoService.convertToBrNumber(opDes9),
      saldo_pagar: this.funcaoService.convertToBrNumber(opDes10),
    }
    listaExportar.push(linhaAmortizacaoDespesa)

    const linhaSubRefinanciamentoDespesa = {
      receitas: 'SUBTOTAL COM REFINANCIAMENTO (X) = (VIII + IX)',
      prev_inicial: this.funcaoService.convertToBrNumber(subDes2 + opDes2 + resDes2 + intraDes2),
      prev_atualizada: this.funcaoService.convertToBrNumber(subDes3 + opDes3 + resDes3 + intraDes3),
      prev_bimestre: this.funcaoService.convertToBrNumber(subDes4 + opDes4 + resDes4 + intraDes4),
      real_bimestre: this.funcaoService.convertToBrNumber(subDes5 + opDes5 + intraDes5),
      saldo_realizar: this.funcaoService.convertToBrNumber(subDes6 + opDes6 + intraDes6),
      pago_bimestre: this.funcaoService.convertToBrNumber(subDes7 + opDes7 + intraDes7),
      saldo_empenhar: this.funcaoService.convertToBrNumber(subDes8 + opDes8 + intraDes8),
      saldo_liquidar: this.funcaoService.convertToBrNumber(subDes9 + opDes9 + intraDes9),
      saldo_pagar: this.funcaoService.convertToBrNumber(subDes10 + opDes10 + intraDes10),
    }
    listaExportar.push(linhaSubRefinanciamentoDespesa)

    const superavit = totalArrecadado > (subDes6 + opDes6 + intraDes6) ? this.funcaoService.convertToBrNumber(totalArrecadado - (subDes6 + opDes6 + intraDes6)) : '0,00';

    const linhaSuperavitDespesa = {
      receitas: 'SUPERÁVIT ( XI )',
      prev_inicial: '',
      prev_atualizada: '',
      prev_bimestre: '',
      real_bimestre: '',
      saldo_realizar: superavit,
      pago_bimestre: '',
      saldo_empenhar: '',
      saldo_liquidar: '',
      saldo_pagar: '',
    }
    listaExportar.push(linhaSuperavitDespesa)

    const resultado_supervit = totalArrecadado > (subDes6 + opDes6 + intraDes6) ? this.funcaoService.convertToBrNumber(
      (totalArrecadado - (subDes6 + opDes6 + intraDes6)) + (subDes6 + opDes6 + intraDes6)) : this.funcaoService.convertToBrNumber(subDes6 + opDes6 + intraDes6);

    const linhaTotalDespesa = {
      receitas: 'TOTAL ( XII ) = ( X + XI )',
      prev_inicial: this.funcaoService.convertToBrNumber(subDes2 + opDes2 + intraDes2 + resDes2),
      prev_atualizada: this.funcaoService.convertToBrNumber(subDes3 + opDes3 + intraDes3 + resDes3),
      prev_bimestre: this.funcaoService.convertToBrNumber(subDes4 + opDes4 + intraDes4 + resDes4),
      real_bimestre: this.funcaoService.convertToBrNumber(subDes5 + opDes5 + intraDes5),
      saldo_realizar: resultado_supervit,
      pago_bimestre: this.funcaoService.convertToBrNumber(subDes7 + opDes7 + intraDes7),
      saldo_empenhar: this.funcaoService.convertToBrNumber(subDes8 + opDes8 + intraDes8),
      saldo_liquidar: this.funcaoService.convertToBrNumber(subDes9 + opDes9 + intraDes9),
      saldo_pagar: this.funcaoService.convertToBrNumber(subDes10 + opDes10 + intraDes10),
    }
    listaExportar.push(linhaTotalDespesa)

    return listaExportar
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'RECEITAS', coluna: 'receitas' },
      { titulo: 'PREVISÃO INICIAL', coluna: 'prev_inicial' },
      { titulo: 'PREVISÃO ATUALIZADA', coluna: 'prev_atualizada' },
      { titulo: 'PREVISTAS ATÉ O BIMESTRE', coluna: 'prev_bimestre' },
      { titulo: 'REALIZADAS ATÉ O BIMESTRE', coluna: 'real_bimestre' },
      { titulo: 'SALDO A REALIZAR', coluna: 'saldo_realizar' },
      { titulo: '', coluna: 'pago_bimestre' },
      { titulo: '', coluna: 'saldo_empenhar' },
      { titulo: '', coluna: 'saldo_liquidar' },
      { titulo: '', coluna: 'saldo_pagar' },

    ];
    return colunasDefault;
  }


  private conteudoReceita(dados: any[], dadosReceita: any[]) {

    // loop para pegar o total liquidado e verificar se houve defict ou superavit
    const gruposReceita = this.funcaoService.agrupar(dadosReceita, 'tipo',
      ['dotacao', 'adicionais', 'empenhado', 'liquidado', 'pago']);

    let totalLiquidado = 0;
    for (const grupo of gruposReceita) {
      totalLiquidado = totalLiquidado + grupo.totalizadores['liquidado'];
    }
    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: this.login.orgao.nome,
        alignment: 'center', bold: true, colSpan: 6, fontSize: 13, border: [false, false, false, false], margin: [0, 0, 0, -3]
      }, '', '', '', '', ''],
      [{
        text: 'RELATÓRIO RESUMIDO DA EXECUÇÃO ORÇAMENTÁRIA - RREO',
        alignment: 'center', bold: true, colSpan: 6, fontSize: 13, border: [false, false, false, false], margin: [0, 0, 0, -3]
      }, '', '', '', '', ''],
      [{
        text: 'BALANÇO ORÇAMENTÁRIO',
        alignment: 'center', bold: true, colSpan: 6, border: [false, false, false, false], margin: [0, 0, 0, -3]
      }, '', '', '', '', ''],
      [{
        text: `PERÍODO DE REFERÊNCIA: ${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano}`,
        alignment: 'center', bold: true, colSpan: 6, border: [false, false, false, false], margin: [0, 0, 0, -3]
      }, '', '', '', '', ''],
      [{
        text: 'LRF, art. 52,  alineas "a" e "b" do inciso I e  II , alíneas "a" e "b" do inciso II',
        alignment: 'left',
        bold: true,
        colSpan: 6, border: [false, false, false, false]
      }, '', '', '', '', ''],
      [{
        text: 'RECEITAS',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'PREVISÃO INICIAL',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'PREVISÃO ATUALIZADA',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'PREVISTAS ATÉ O BIMESTRE',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'REALIZADAS ATÉ O BIMESTRE',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'SALDO A REALIZAR',
        alignment: 'center',
        bold: true, fontSize: 7
      }]
    ];

    // monta o agrupamento do relatório
    const grupos = this.funcaoService.agrupar(dados, 'tipo',
      ['previsao', 'atualizado', 'previsto_total', 'arrecadado']);
    const totalizador = new Array<any>();
    let sub2 = 0;
    let sub3 = 0;
    let sub4 = 0;
    let sub5 = 0;
    let sub6 = 0;

    let op2 = 0;
    let op3 = 0;
    let op4 = 0;
    let op5 = 0;
    let op6 = 0;

    for (const grupo of grupos) {

      if (grupo.grupo === 'OPERAÇÕES DE CRÉDITO - REFINANCIAMENTO ( II )') {
        op2 = op2 + +grupo.totalizadores[`previsao`];
        op3 = op3 + +grupo.totalizadores[`atualizado`];
        op4 = op4 + +grupo.totalizadores[`previsto_total`];
        op5 = op5 + +grupo.totalizadores[`arrecadado`];
        op6 = op6 + +grupo.totalizadores[`arrecadado`] - +grupo.totalizadores[`atualizado`];
        continue
      }

      if (grupo.grupo === '(-) DEDUÇÕES DA RECEITA') {
        registros.push([
          { text: grupo.grupo, bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`previsao`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`atualizado`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`previsto_total`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`arrecadado`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`atualizado`] - +grupo.totalizadores[`arrecadado`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] }
        ]);
        const saldoRealizar = (+grupo.totalizadores[`atualizado`] - +grupo.totalizadores[`arrecadado`]);
        sub2 = sub2 - +grupo.totalizadores[`previsao`];
        sub3 = sub3 - +grupo.totalizadores[`atualizado`];
        sub4 = sub4 - +grupo.totalizadores[`previsto_total`];
        sub5 = sub5 - +grupo.totalizadores[`arrecadado`];
        sub6 = sub6 - +saldoRealizar;
        continue;
      }
      if (grupo.grupo === 'RECEITAS INTRAORÇAMENTÁRIAS') {
        registros.push([
          { text: grupo.grupo, bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`previsao`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`atualizado`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`previsto_total`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`arrecadado`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`atualizado`] - +grupo.totalizadores[`arrecadado`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] }
        ]);
        const saldoRealizar = (+grupo.totalizadores[`atualizado`] - +grupo.totalizadores[`arrecadado`]);
        sub2 = sub2 + +grupo.totalizadores[`previsao`];
        sub3 = sub3 + +grupo.totalizadores[`atualizado`];
        sub4 = sub4 + +grupo.totalizadores[`previsto_total`];
        sub5 = sub5 + +grupo.totalizadores[`arrecadado`];
        sub6 = sub6 + +saldoRealizar;
        continue;
      }

      registros.push([
        { text: grupo.grupo, bold: true, fontSize: 7, border: [false, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`previsao`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`atualizado`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`previsto_total`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`arrecadado`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
        { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`atualizado`] - +grupo.totalizadores[`arrecadado`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] }
      ]);
      const saldoRealizar = (+grupo.totalizadores[`atualizado`] - +grupo.totalizadores[`arrecadado`])
      sub2 = sub2 + +grupo.totalizadores[`previsao`];
      sub3 = sub3 + +grupo.totalizadores[`atualizado`];
      sub4 = sub4 + +grupo.totalizadores[`previsto_total`];
      sub5 = sub5 + +grupo.totalizadores[`arrecadado`];
      sub6 = sub6 + +saldoRealizar;
      // lista os registros do relatorio
      for (const registro of grupo.registros) {
        registros.push([
          {
            text: `${registro.nome}`, fontSize: 7, border: [false, false, false, false], margin: [15, 0, 0, -3]
          },
          { text: this.funcaoService.convertToBrNumber(registro.previsao), alignment: 'right', fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(registro.atualizado), alignment: 'right', fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(registro.previsto_total), alignment: 'right', fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(registro.arrecadado), alignment: 'right', fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
          { text: this.funcaoService.convertToBrNumber(+registro.atualizado - +registro.arrecadado), alignment: 'right', fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] }
        ]);
      }

    }

    // Monta os totalizadores do relatorio

    registros.push([{
      text: 'SUBTOTAL DAS RECEITAS ( I )', bold: true,
      alignment: 'left', fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -2]
    }, {
      text: this.funcaoService.convertToBrNumber(sub2),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -2]
    }, {
      text: this.funcaoService.convertToBrNumber(sub3),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -2]
    }, {
      text: this.funcaoService.convertToBrNumber(sub4),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -2]
    }, {
      text: this.funcaoService.convertToBrNumber(sub5),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -2]
    }, {
      text: this.funcaoService.convertToBrNumber(sub6),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -2]
    }]);
    registros.push([{
      text: 'OPERAÇÕES DE CRÉDITO - REFINANCIAMENTO ( II )', bold: true,
      alignment: 'left', fontSize: 7, border: [false, false, false, true], margin: [0, 0, 0, -2]
    }, {
      text: this.funcaoService.convertToBrNumber(op2),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, true], margin: [0, 0, 0, -2]
    }, {
      text: this.funcaoService.convertToBrNumber(op3),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, true], margin: [0, 0, 0, -2]
    }, {
      text: this.funcaoService.convertToBrNumber(op4),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, true], margin: [0, 0, 0, -2]
    }, {
      text: this.funcaoService.convertToBrNumber(op5),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, true], margin: [0, 0, 0, -2]
    }, {
      text: this.funcaoService.convertToBrNumber(op6),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, true], margin: [0, 0, 0, -2]
    }]);
    registros.push([{
      text: 'SUBTOTAL COM REFINANCIAMENTO ( III ) = (I + II)', bold: true,
      alignment: 'left', fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2]
    }, {
      text: this.funcaoService.convertToBrNumber(sub2 + op2),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2]
    }, {
      text: this.funcaoService.convertToBrNumber(sub3 + op3),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2]
    }, {
      text: this.funcaoService.convertToBrNumber(sub4 + op4),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2]
    }, {
      text: this.funcaoService.convertToBrNumber(sub5 + op5),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2]
    }, {
      text: this.funcaoService.convertToBrNumber(sub6 + op6),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2]
    }]);

    registros.push([{
      text: 'DÉFICIT ( IV )', bold: true,
      alignment: 'left', fontSize: 7, border: [false, false, false, true], margin: [0, 0, 0, -2]
    }, {
      text: '',
      colSpan: 3, border: [false, false, false, true], margin: [0, 0, 0, -2]
    }, {
      text: '', border: [false, false, false, true], margin: [0, 0, 0, -2]
    }, {
      text: '', border: [false, false, false, true], margin: [0, 0, 0, -2]
    }, {
      text: totalLiquidado > (sub5 + op5) ? this.funcaoService.convertToBrNumber(totalLiquidado - (sub5 + op5)) : '0,00',
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, true], margin: [0, 0, 0, -2]
    }, {
      text: '', border: [false, false, false, true], margin: [0, 0, 0, -2]
    }]);

    registros.push([{
      text: 'TOTAL ( V ) = ( III + IV )', bold: true,
      alignment: 'left', fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2]
    }, {
      text: this.funcaoService.convertToBrNumber(sub2 + op2),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2]
    }, {
      text: this.funcaoService.convertToBrNumber(sub3 + op3),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2]
    }, {
      text: this.funcaoService.convertToBrNumber(sub4 + op4),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2]
    }, {
      text: totalLiquidado > (sub5 + op5) ?
        this.funcaoService.convertToBrNumber((totalLiquidado - (sub5 + op5)) +
          (sub5 + op5)) : this.funcaoService.convertToBrNumber(sub5 + op5),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2]
    }, {
      text: this.funcaoService.convertToBrNumber(sub6 + op6),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2]
    }]);

    return [{
      layout: 'linhas',
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }];
  }

  private conteudoDespesa(dados: any[], dadosDespesa: any[]) {

    // loop para pegar o total arrecadado e verificar se houve defict ou superavit
    const gruposDespesa = this.funcaoService.agrupar(dadosDespesa, 'tipo',
      ['previsao', 'atualizado', 'previsto_total', 'arrecadado']);
    let totalArrecadado = 0;
    for (const item of gruposDespesa) {
      totalArrecadado = item.grupo !== '(-) DEDUÇÕES DA RECEITA' ?
        totalArrecadado + item.totalizadores['arrecadado'] :
        totalArrecadado - item.totalizadores['arrecadado'];
    }
    // monta o cabecalho
    const registros: {}[] = [
      [
        {
          text: ' ',
          colSpan: 10,
          border: [false, false, false, false]
        }, '', '', '', '', '', '', '', '', ''
      ],
      [{
        text: 'DESPESAS',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }, {
        text: 'Dotação Inicial Anual',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }, {
        text: 'Créditos Adicionais/Anulações',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }, {
        text: 'Dotação Atualizada Anual',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }, {
        text: 'Empenhado até o Bimestre',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }, {
        text: 'Liquidado até o Bimestre',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }, {
        text: 'Pago até o Bimestre',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }, {
        text: 'Saldo a Empenhar',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }, {
        text: 'Saldo a Liquidar',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }, {
        text: 'Saldo a Pagar',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }]
    ];

    const grupos = this.funcaoService.agrupar(dados, 'tipo',
      ['dotacao', 'adicionais', 'empenhado', 'liquidado', 'pago']);

    const totalizador = new Array<any>();
    for (const grupo of grupos) {

      // desconsidera esses grupos porque serão impressos somente nos totalizadores
      if (grupo.grupo !== 'AMORTIZAÇÃO DA DÍVIDA - REFINANCIAMENTOS (IX)' && grupo.grupo !== 'RESERVA DE CONTINGÊNCIA'
        && grupo.grupo !== 'DESPESAS INTRAORÇAMENTÁRIAS') {
        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2]
          },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`dotacao`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2] },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`adicionais`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2] },
          {
            text: this.funcaoService.convertToBrNumber(
              +grupo.totalizadores[`dotacao`] + +grupo.totalizadores[`adicionais`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2]
          },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`empenhado`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2] },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`liquidado`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2] },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`pago`]), alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2] },
          {
            text: this.funcaoService.convertToBrNumber(
              (+grupo.totalizadores[`dotacao`] + +grupo.totalizadores[`adicionais`]) - grupo.totalizadores[`empenhado`]),
            alignment: 'right',
            bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2]
          },
          {
            text: this.funcaoService.convertToBrNumber(
              grupo.totalizadores[`empenhado`] - grupo.totalizadores[`liquidado`]),
            alignment: 'right',
            bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2]
          },
          {
            text: this.funcaoService.convertToBrNumber(
              grupo.totalizadores[`liquidado`] - grupo.totalizadores[`pago`]),
            alignment: 'right',
            bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -2]
          }
        ]);

        // lista dos registros do grupo
        for (const registro of grupo.registros) {
          registros.push([
            {
              text: `${registro.nome}`, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
            },
            { text: this.funcaoService.convertToBrNumber(registro.dotacao), alignment: 'right', fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
            { text: this.funcaoService.convertToBrNumber(registro.adicionais), alignment: 'right', fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
            { text: this.funcaoService.convertToBrNumber(+registro.dotacao + +registro.adicionais), alignment: 'right', fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
            { text: this.funcaoService.convertToBrNumber(registro.empenhado), alignment: 'right', fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
            { text: this.funcaoService.convertToBrNumber(registro.liquidado), alignment: 'right', fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
            { text: this.funcaoService.convertToBrNumber(registro.pago), alignment: 'right', fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3] },
            {
              text: this.funcaoService.convertToBrNumber((
                +registro.dotacao + +registro.adicionais) - +registro.empenhado), alignment: 'right', fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
            },
            {
              text: this.funcaoService.convertToBrNumber(+registro.empenhado - +registro.liquidado), alignment: 'right', fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
            },
            {
              text: this.funcaoService.convertToBrNumber(+registro.liquidado - +registro.pago), alignment: 'right', fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
            }
          ]);
        }
      }
      totalizador.push({
        nome: grupo.grupo,
        col2: grupo.totalizadores['dotacao'],
        col3: grupo.totalizadores['adicionais'],
        col4: +grupo.totalizadores[`dotacao`] + +grupo.totalizadores[`adicionais`],
        col5: grupo.totalizadores['empenhado'],
        col6: grupo.totalizadores['liquidado'],
        col7: grupo.totalizadores['pago'],
        col8: (+grupo.totalizadores[`dotacao`] + +grupo.totalizadores[`adicionais`]) - +grupo.totalizadores[`empenhado`],
        col9: grupo.totalizadores[`empenhado`] - +grupo.totalizadores[`liquidado`],
        col10: grupo.totalizadores[`liquidado`] - +grupo.totalizadores[`pago`],
      });
    }

    let sub2 = 0; let sub3 = 0; let sub4 = 0; let sub5 = 0; let sub6 = 0; let sub7 = 0; let sub8 = 0; let sub9 = 0; let sub10 = 0;
    let op2 = 0; let op3 = 0; let op4 = 0; let op5 = 0; let op6 = 0; let op7 = 0; let op8 = 0; let op9 = 0; let op10 = 0;
    let intra2 = 0; let intra3 = 0; let intra4 = 0; let intra5 = 0;
    let intra6 = 0; let intra7 = 0; let intra8 = 0; let intra9 = 0; let intra10 = 0;
    let res2 = 0;
    let res3 = 0;
    let res4 = 0;
    for (const val of totalizador) {
      if (val.nome === 'AMORTIZAÇÃO DA DÍVIDA - REFINANCIAMENTOS (IX)') {
        op2 = op2 + val.col2;
        op3 = op3 + val.col3;
        op4 = op4 + val.col4;
        op5 = op5 + val.col5;
        op6 = op6 + val.col6;
        op7 = op7 + val.col7;
        op8 = op8 + val.col8;
        op9 = op9 + val.col9;
        op10 = op10 + val.col10;
      } else if (val.nome === 'DESPESAS INTRAORÇAMENTÁRIAS') {
        intra2 = intra2 + val.col2;
        intra3 = intra3 + val.col3;
        intra4 = intra4 + val.col4;
        intra5 = intra5 + val.col5;
        intra6 = intra6 + val.col6;
        intra7 = intra7 + val.col7;
        intra8 = intra8 + val.col8;
        intra9 = intra9 + val.col9;
        intra10 = intra10 + val.col10;
      } else if (val.nome === 'RESERVA DE CONTINGÊNCIA') {
        res2 = res2 + val.col2;
        res3 = res3 + val.col3;
        res4 = res4 + val.col4;
      } else {
        sub2 = sub2 + val.col2;
        sub3 = sub3 + val.col3;
        sub4 = sub4 + val.col4;
        sub5 = sub5 + val.col5;
        sub6 = sub6 + val.col6;
        sub7 = sub7 + val.col7;
        sub8 = sub8 + val.col8;
        sub9 = sub9 + val.col9;
        sub10 = sub10 + val.col10;
      }
    }
    // totais da reserva de contigencia
    registros.push([{
      text: 'RESERVA DE CONTINGÊNCIA', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3],
      alignment: 'left',
    }, {
      text: this.funcaoService.convertToBrNumber(res2),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(res3),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(res4),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: '',
      alignment: 'right', bold: true, fontSize: 7, colSpan: 6, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: '',
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: '',
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: '',
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: '',
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: '',
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }]);

    // totais da despesa intra orcamentaria
    registros.push([{
      text: 'DESPESAS INTRAORÇAMENTÁRIAS', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3],
      alignment: 'left',
    }, {
      text: this.funcaoService.convertToBrNumber(intra2),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(intra3),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(intra4),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(intra5),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(intra6),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(intra7),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(intra8),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(intra9),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(intra10),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }]);

    registros.push([{
      text: 'SUBTOTAL DAS DESPESAS ( VIII )', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3],
      alignment: 'left',
    }, {
      text: this.funcaoService.convertToBrNumber(sub2 + res2 + intra2),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub3 + res3 + intra3),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub4 + res4 + intra4),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub5 + intra5),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub6 + intra6),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub7 + intra7),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub8 + intra8),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub9 + intra9),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub10 + intra10),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }]);

    registros.push([{
      text: 'AMORTIZAÇÃO DA DÍVIDA - REFINANCIAMENTOS (IX)', bold: true,
      alignment: 'left', fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(op2),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(op3),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(op4),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(op5),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(op6),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(op7),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(op8),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(op9),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(op10),
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }]);

    registros.push([{
      text: 'SUBTOTAL COM REFINANCIAMENTO (X) = (VIII + IX)', bold: true,
      alignment: 'left', fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub2 + op2 + res2 + intra2),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub3 + op3 + res3 + intra3),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub4 + op4 + res4 + intra4),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub5 + op5 + intra5),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub6 + op6 + intra6),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub7 + op7 + intra7),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub8 + op8 + intra8),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub9 + op9 + intra9),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub10 + op10 + intra10),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }]);

    const superavit = totalArrecadado > (sub6 + op6 + intra6) ? this.funcaoService.convertToBrNumber(totalArrecadado - (sub6 + op6 + intra6)) : '0,00';

    registros.push([{
      text: 'SUPERÁVIT ( XI )', bold: true,
      alignment: 'left', fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: '',
      colSpan: 4, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: '', border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: '', border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: '', border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: superavit,
      alignment: 'right', bold: true, fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: '', colSpan: 4, border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: '', border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: '', border: [false, false, false, false], margin: [0, 0, 0, -3]
    }, {
      text: '', border: [false, false, false, false], margin: [0, 0, 0, -3]
    }]);

    const resultado_supervit = totalArrecadado > (sub6 + op6 + intra6) ? this.funcaoService.convertToBrNumber(
      (totalArrecadado - (sub6 + op6 + intra6)) + (sub6 + op6 + intra6)) : this.funcaoService.convertToBrNumber(sub6 + op6 + intra6);

    registros.push([{
      text: 'TOTAL ( XII ) = ( X + XI )', bold: true,
      alignment: 'left', fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub2 + op2 + intra2 + res2),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub3 + op3 + intra3 + res3),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub4 + op4 + intra4 + res4),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub5 + op5 + intra5),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: resultado_supervit,
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub7 + op7 + intra7),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub8 + op8 + intra8),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub9 + op9 + intra9),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }, {
      text: this.funcaoService.convertToBrNumber(sub10 + op10 + intra10),
      alignment: 'right', bold: true, fontSize: 7, border: [false, true, false, false], margin: [0, 0, 0, -3]
    }]);

    return [{
      layout: 'linhas',
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }
    ];
  }

}
