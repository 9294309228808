import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, LicitacaoComissaoMembro } from 'eddydata-lib';

@Injectable({
    providedIn: 'root'
})
export class LicitacaoComissaoMembroService extends BaseResourceService<LicitacaoComissaoMembro> {

    constructor(
        protected injector: Injector
    ) {
        super(`licitacao-comissao-membros`, injector);
    }

}
