// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { FonteOrgao } from './fonte-orgao.model';
import { Receita } from './receita.model';

export class FonteSubalinea extends BaseResourceModel {
  constructor(
    public id?: number,
    public fonteOrgao?: FonteOrgao,
    public receita?: Receita,
    public ano1?: number,
    public ano2?: number,
    public ano3?: number,
    public ano4?: number,
    public editavel?: boolean,
    public data_cadastro?: Date,
    public data_alteracao?: Date
  ) {
    super();
  }

  static converteJson(json: any): FonteSubalinea {
    return Object.assign(new FonteSubalinea(), json);
  }
}
