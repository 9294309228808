// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Precatorio } from './precatorio.model';

export class PrecatorioIndicativo extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo_suspensao?: number,
    public indicativo_suspensao?: string,
    public data_decisao?: Date,
    public indicativo_deposito?: string,
    public valor_deposito?: number,
    public valor_nao_retido?: number,
    public valor_compensacao?: number,
    public valor_compensacao_anterior?: number,
    public valor_suspenso?: number,
    public precatorio?: Precatorio,
    public base_IR?: number,
    public nao_IR?: number,
    public deposito_IR?: number,
    public base_csll?: number,
    public nao_csll?: number,
    public deposito_csll?: number,
    public base_cofins?: number,
    public nao_cofins?: number,
    public deposito_cofins?: number,
    public base_pp?: number,
    public nao_pp?: number,
    public deposito_pp?: number,

  ) {
    super();
  }
  static converteJson(json: any): PrecatorioIndicativo {
    return Object.assign(new PrecatorioIndicativo(), json);
  }
}
