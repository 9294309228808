import { Injectable, Injector } from "@angular/core";
import { BaseResourceService, Decreto } from "eddydata-lib";

@Injectable({
  providedIn: 'root'
})
export class DecretoService extends BaseResourceService<Decreto> {

  constructor(
    protected injector: Injector
  ) {
    super(`decretos`, injector);
  }

}
