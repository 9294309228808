import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, CodigoMercadoriaBB } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class CodigoMercadoriaService extends BaseResourceService<CodigoMercadoriaBB> {

  constructor(
    protected injector: Injector
  ) {
    super(`codigo-mercadorias`, injector);
  }

}
