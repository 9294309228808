// tslint:disable: variable-name
import { TipoDestinoMerenda } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Pessoa } from '../comum/pessoa.model';
import { Usuario } from '../comum/usuario.model';
import { Tombamento } from '../patrimonio/tombamento.model';
import { Unidade } from '../planejamento/unidade.model';
import { SetorAlmoxarifado } from './setor-almoxarifado.model';
import { SetorExecutora } from './setor-executora.model';
import { SetorLocalizacao } from './setor-localizacao.model';
import { SetorProcesso } from './setor-processo.model';
import { SetorProduto } from './setor-produto.model';
import { SetorResponsavel } from './setor-responsavel.model';
import { SetorStorage } from './setor-storage.model';
import { UsuarioSetor } from './usuario-setor.model';

export class Setor extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public nome?: string,
    public cep?: string,
    public endereco?: string,
    public bairro?: string,
    public numero?: string,
    public telefone?: string,
    public email?: string,
    public complemento?: string,
    public ativo?: boolean,
    public cargo?: string,
    public termo_extra?: string,
    public setor_patrimonio?: boolean,
    public setor_protocolo?: boolean,
    public aux?: number,
    public aux_patrimonio?: number,
    public manutencao_patrimonio?: boolean,
    public baixa_patrimonio?: boolean,
    public cessao_uso?: boolean,
    public responsavel?: Pessoa,
    public orgao?: Orgao,
    public unidade?: Unidade,
    public estoques?: SetorAlmoxarifado[],
    public executoras?: SetorExecutora[],
    public responsaveis?: SetorResponsavel[],
    public produtos?: SetorProduto[],
    public localizacoes?: SetorLocalizacao[],
    public tombamentos?: Tombamento[],
    public processos?: SetorProcesso[],
    public storages?: SetorStorage[],
    public merenda?: Boolean,
    public transferencia_automatica?: Boolean,
    public destino_merenda?: TipoDestinoMerenda,
    public usuarios?: Usuario[],
    public usuarios_setor?: UsuarioSetor[],
    public cnes?: string,
  ) {
    super();
  }
  static converteJson(json: any): Setor {
    return Object.assign(new Setor(), json);
  }
}
