import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, GlobalService, LoginContabil, Orgao, OrgaoService, Unidade, UnidadeService } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-unidade-list',
  templateUrl: './unidade-list.component.html'
})
export class UnidadeListComponent extends BaseResourceListComponent<Unidade, LoginContabil> implements OnInit {

  /**
   * Declaração de variáveis
   */
  public listaOrgaos: Array<any>;
  public orgaoSelect: Orgao;

  public mes: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private route: ActivatedRoute,
    private orgaoService: OrgaoService,
    private unidadeService: UnidadeService,
    protected globalService: GlobalService) {
    super(unidadeService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(item: Unidade): boolean {
    return this.login.usuario.sistema != 'almoxarifado'
  }

  protected relations(): string {
    return 'orgao,ppa';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.orgaoSelect ? this.orgaoSelect.id : 0,
      ['ppa.id']: this.login.ppa.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['codigo$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      //number: ['codigo', 'tribunal'],
      text: ['codigo', 'tribunal', 'nome', 'responsavel', 'cargo'],
    };
  }

  protected afterInit(): void {
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.orgaoService.obterId(params['orgao']).pipe(takeUntil(this.unsubscribe))
          .subscribe((dados) => {
            this.orgaoSelect = dados;
            this.preencherComboOrgaos();
            this.preencherGrid();
          });
      });
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  protected acaoRemover(model: Unidade): Observable<Unidade> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'codigo' },
      { titulo: 'Descrição', coluna: 'nome' },
      { titulo: 'Nº Tribunal', coluna: 'tribunal' },
      { titulo: 'Responsável', coluna: 'responsavel' },
      { titulo: 'Cargo', coluna: 'cargo' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'orgao,ppa';
    this.unidadeService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE UNIDADES ADMINISTRATIVAS`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem unidades', ['auto', '*', 'auto', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        error => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  preencherComboOrgaos() {
    const parametros = {};
    parametros['orderBy'] = 'nome$ASC';
    this.orgaoService
      .filtrar(
        this.paginaCorrente,
        this.login.limite,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        dados => {
          this.listaOrgaos = dados.content;
        },
        error => alert('erro ao retornar lista')
      );
  }

  public alternarOrgao(orgaoId: number) {
    this.router.navigate(['/unidades', orgaoId]);
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/planejamento']);
        break;
      default:
        this.sair();
        break;
    }
  }
}
