// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class EventoFolha extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public especie?: string,
    public tipo?: string,
    public calculo?: string,
    public fixo?: boolean,
    public decimo_terceiro?: boolean,
    public ferias?: boolean,
    public fgts?: boolean,
    public irrf?: boolean,
    public inss?: boolean,
    public rais?: boolean,
    public media_decimo_terceiro?: boolean,
    public media_ferias?: boolean,
    public salario_familia?: boolean,
    public visivel_transparencia?: boolean,
    public orgao?: Orgao,
    ) {
    super();
  }
  static converteJson(json: any): EventoFolha {
    return Object.assign(new EventoFolha(), json);
  }
}
