import { Banco } from '../banco';

export class BancoReal extends Banco {
    /**
     * Metdodo responsavel por resgatar o numero do banco, coloque no return o codigo do seu banco
     */
    public getNumero() {
        return '356';
    }

    /**
     * Metodo responsavel por fazer o calculo do digitao da cobranca.
     */
    public getDigitao() {
        let campo = this.boleto.getNossoNumero() + this.boleto.getAgencia() + this.boleto.getContaCorrente();

        return this.boleto.getDigitoCampo(campo, 1).substring(campo.length);
    }

    /**
     * Metodo que monta o primeiro campo do codigo de barras
     * Este campo como os demais e feito a partir do da documentacao do banco
     * A documentacao do banco tem a estrutura de como monta cada campo, depois disso
     * Ã© sÃ³ concatenar os campos como no exemplo abaixo.
     */
    private getCampo1() {
        let campo = this.getNumero() + this.boleto.getMoeda().toString() + this.boleto.getAgencia() + this.boleto.getContaCorrente().substring(0, 1);

        return this.boleto.getDigitoCampo(campo, 2);
    }

    /**
     * ver documentacao do banco para saber qual a ordem deste campo
     */
    private getCampo2() {
        let campo = this.boleto.getContaCorrente().substring(1) + this.getDigitao() + this.boleto.getNossoNumero().substring(0, 3);

        return this.boleto.getDigitoCampo(campo, 1);
    }

    /**
     * ver documentacao do banco para saber qual a ordem deste campo
     */
    private getCampo3() {
        let campo = this.boleto.getNossoNumero().substring(3);

        return this.boleto.getDigitoCampo(campo, 1);
    }

    /**
     * ver documentacao do banco para saber qual a ordem deste campo
     */
    private getCampo4() {
        let campo = this.getNumero() + this.boleto.getMoeda().toString() +
            this.boleto.getFatorVencimento() + this.boleto.getValorTitulo() + this.boleto.getAgencia() +
            this.boleto.getContaCorrente() + this.getDigitao() + this.boleto.getNossoNumero();

        return this.boleto.getDigitoCodigoBarras(campo);
    }

    /**
     * ver documentacao do banco para saber qual a ordem deste campo
     */
    private getCampo5() {
        let campo = this.boleto.getFatorVencimento() + this.boleto.getValorTitulo();
        return campo;
    }

    /**
     * Metodo para monta o desenho do codigo de barras
     * A ordem destes campos tambem variam de banco para banco, entao e so olhar na documentacao do seu banco
     * qual a ordem correta
     */
    public getCodigoBarras() {
        return this.getNumero() + this.boleto.getMoeda().toString() + this.getCampo4() +
            this.boleto.getFatorVencimento() + this.boleto.getValorTitulo() + this.boleto.getAgencia() + this.boleto.getContaCorrente() + this.getDigitao() +
            this.boleto.getNossoNumero();
    }

    /**
     * Metodo que concatena os campo para formar a linha digitavel
     * E necessario tambem olhar a documentacao do banco para saber a ordem correta a linha digitavel
     */
    public getLinhaDigitavel() {
        return this.getCampo1().substring(0, 5) + '.' + this.getCampo1().substring(5) + '  ' +
            this.getCampo2().substring(0, 5) + '.' + this.getCampo2().substring(5) + '  ' +
            this.getCampo3().substring(0, 5) + '.' + this.getCampo3().substring(5) + '  ' +
            this.getCampo4() + '  ' + this.getCampo5();
    }

    /**
     * Recupera a carteira no padrao especificado pelo banco
     * @author Gladyston Batista/Eac Software
     */
    public getCarteiraFormatted() {
        return this.boleto.getCarteira();
    }

    /**
     * Recupera a agencia / codigo cedente no padrao especificado pelo banco
     * @author Gladyston Batista/Eac Software
     */
    public getAgenciaCodCedenteFormatted() {
        return this.boleto.getAgencia() + ' / ' + this.boleto.getContaCorrente() + '-' + this.boleto.getDvContaCorrente();
    }

    /**
     * Recupera o nossoNumero no padrao especificado pelo banco
     */
    public getNossoNumeroFormatted() {
        return Number.parseInt(this.boleto.getNossoNumero()).toString();
    }
}