import { Estado } from './estado.model';
import { BaseResourceModel } from '../../models/base-resource.model';

export class Cidade extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public url?: string,
    public siafi?: number,
    public estado?: Estado
  ) {
    super();
  }

  static converteJson(json: any): Cidade {
    return Object.assign(new Cidade(), json);
  }
}
