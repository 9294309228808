
import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ConvenioAlteracao } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ConvenioAlteracaoService extends BaseResourceService<ConvenioAlteracao> {

  constructor(
    protected injector: Injector
  ) {
    super('convenio-alteracoes', injector);
  }

}
