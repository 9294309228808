import { Directive, OnDestroy } from '@angular/core';
import { FuncaoService, GlobalService, Ldo, LoginContabil, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LdoService } from '../../../../planejamento/ldo/service/ldo.service';
import { SelectItem } from 'primeng/api/selectitem';

@Directive()
export class Demo5Ldo implements OnDestroy {
  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();
  listaOrgaos: SelectItem[];

  constructor(
    protected ano: number,
    protected ldoServico: LdoService,
    protected orgaos: number[] = [],
    listaOrgaos?: SelectItem[]) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
    if (listaOrgaos) this.listaOrgaos = listaOrgaos;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio() {
    this.ldoServico.filtrar({
      tipo$like: 'D5%', 'orgao.id$in': this.orgaos, ano: this.ano, relations: 'ppa', orderBy: 'tipo,linha'
    })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        const lista50 = [];
        const lista = [];
        if (dados.content.length) {
          let agrupamento = this.funcaoService.agrupar(dados.content, ['linha', 'descricao1', 'descricao2', 'titulo', 'somador'], ['valor1', 'valor2', 'valor3'])
          for (const dado of agrupamento) {
            let item: Ldo = {
              linha: dado.grupo['linha'],
              descricao1: dado.grupo['descricao1'],
              descricao2: dado.grupo['descricao2'],
              titulo: dado.grupo['titulo'],
              somador: dado.grupo['somador'],
              valor1: dado.totalizadores['valor1'],
              valor2: dado.totalizadores['valor2'],
              valor3: dado.totalizadores['valor3'],
            }
            lista50.push(item)
          }
          this.soma(lista50)
          lista.push(
            this.conteudo(lista50)
          );
        }
        Relatorio.imprimirPersonalizado(
          'AMF/Tabela 5 - DEMONSTRATIVO 5 – ORIGEM E APLICAÇÃO DOS RECURSOS OBTIDOS COM A ALIENAÇÃO DE ATIVOS'
          , this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          lista,
          'portrait', 'DEMONSTRATIVO V - LDO',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private conteudo(dados: any[]): {}[] {
    const registros: {}[] = [
      [{
        text: `LEI DE DIRETRIZES ORÇAMENTÁRIAS`, fontSize: 11,
        alignment: 'center', colSpan: 4, border: [false, false, false, false]
      }, '', '', ''],
      [{
        text: `ANEXO DE  METAS FISCAIS`, fontSize: 11,
        alignment: 'center', colSpan: 4, border: [false, false, false, false]
      }, '', '', ''],
      [{
        text: `ORIGEM E APLICAÇÃO DOS RECURSOS OBTIDOS COM A ALIENAÇÃO DE ATIVOS`, fontSize: 11,
        alignment: 'center', bold: true, colSpan: 4, border: [false, false, false, false]
      }, '', '', ''],
      // [{
      //   text: `PPA: ${this.login.ppa.ppaperiodo.ano1} - ${this.login.ppa.ppaperiodo.ano4}`, fontSize: 11,
      //   alignment: 'center', bold: true, colSpan: 4, border: [false, false, false, false]
      // }, '', '', ''],
      [{
        text: `EXERCÍCIO: ${this.ano}`, fontSize: 10,
        alignment: 'center', bold: true, colSpan: 4, border: [false, false, false, false], margin: [0, 3, 0, 3]
      }, '', '', ''],
      [{
        text: 'AMF - Demonstrativo 5 (LRF, art.4º, §2º, inciso III)', fontSize: 7,
        alignment: 'left', bold: true, colSpan: 4, border: [false, false, false, false]
      }, '', '', ''],
      [
        { text: '', border: [false, false, false, true] },
        { text: '', border: [false, false, false, true] },
        { text: '', border: [false, false, false, true] },
        { text: '', border: [false, false, false, true] }
      ],
      [{
        text: 'RECEITAS REALIZADAS',
        alignment: 'center',
        bold: true,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      }, {
        text: this.ano - 2,
        alignment: 'center',
        bold: true, fontSize: 8,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      }, {
        text: this.ano - 3,
        alignment: 'center',
        bold: true, fontSize: 8,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      }, {
        text: this.ano - 4,
        alignment: 'center',
        bold: true, fontSize: 8,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      }]
    ];
    // monta o agrupamento do relatório
    for (const item of dados) {
      if (item.linha !== 5 && item.linha !== 14) {
        registros.push([
          { text: item.descricao1, fontSize: 8, border: [true, false, false, false], bold: item.somador || item.titulo ? true : false, alignment: item.titulo ? 'center' : 'left' },
          {
            text: item.titulo ? '' : this.funcaoService.convertToBrNumber(item.valor1), alignment: 'right',
            fontSize: 8, border: [true, false, false, false], bold: item.somador || item.titulo ? true : false
          },
          {
            text: item.titulo ? '' : this.funcaoService.convertToBrNumber(item.valor2), alignment: 'right',
            fontSize: 8, border: [true, false, false, false], bold: item.somador || item.titulo ? true : false
          },
          {
            text: item.titulo ? '' : this.funcaoService.convertToBrNumber(item.valor3), alignment: 'right',
            fontSize: 8, border: [true, false, true, false], bold: item.somador || item.titulo ? true : false
          }
        ]);
      } else {
        registros.push([
          { text: item.descricao1, fontSize: 8, border: [true, true, true, true], bold: true, margin: [0, 1, 0, 1], fillColor: '#f5f5f5', alignment: item.titulo ? 'center' : 'left' },
          {
            text: this.ano - 2, alignment: 'center', fontSize: 8,
            border: [true, true, true, true], bold: true, margin: [0, 1, 0, 1], fillColor: '#f5f5f5'
          },
          {
            text: this.ano - 3, alignment: 'center', fontSize: 8,
            border: [true, true, true, true], bold: true, margin: [0, 1, 0, 1], fillColor: '#f5f5f5'
          },
          {
            text: this.ano - 4, alignment: 'center', fontSize: 8,
            border: [true, true, true, true], bold: true, margin: [0, 1, 0, 1], fillColor: '#f5f5f5'
          }
        ]);
      }
    }

    registros.push([
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] }
    ]);

    if (this.listaOrgaos?.length > 0) {
      registros.push([{ text: '\n\n', colSpan: 4, border: [false, false, false, false] }, '', '', '']);
      registros.push([{ text: this.listaOrgaos.filter(o => this.orgaos.some(org => org === o.value)).map(o => o.label).join('\n'), fontSize: 8, colSpan: 4, border: [false, false, false, false] }, '', '', '']);
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 80, 80, 80],
        body: registros
      }
    }];
  }


  private soma(lista: Ldo[]) {
    let total41 = 0;
    let total42 = 0;
    let total43 = 0;
    let total131 = 0;
    let total132 = 0;
    let total133 = 0;
    let total141 = 0;
    let total142 = 0;
    let total143 = 0;

    for (const it of lista) {
      if (it.linha === 1 || it.linha === 2 || it.linha === 3 || it.linha === 4) {
        total41 += +it.valor1;
        total42 += +it.valor2;
        total43 += +it.valor3;
      }
      if (it.linha === 8 || it.linha === 9 || it.linha === 10) {
        total131 += +it.valor1;
        total132 += +it.valor2;
        total133 += +it.valor3;
      }
      if (it.linha === 12 || it.linha === 13) {
        total141 += +it.valor1;
        total142 += +it.valor2;
        total143 += +it.valor3;
      }
    }
    // atualiza sub-totais
    for (const it of lista) {
      if (it.linha === 0) {
        it.valor1 = +total41;
        it.valor2 = +total42;
        it.valor3 = +total43;
      }
      if (it.linha === 7) {
        it.valor1 = +total131;
        it.valor2 = +total132;
        it.valor3 = +total133;
      }
      if (it.linha === 11) {
        it.valor1 = +total141;
        it.valor2 = +total142;
        it.valor3 = +total143;
      }
      if (it.linha === 6) {
        it.valor1 = +total131 + +total141;
        it.valor2 = +total132 + +total142;
        it.valor3 = +total133 + +total143;
      }
      if (it.linha === 15) {
        it.valor3 = +total43 - (+total133 + +total143);
        it.valor2 = (+total42 - (+total132 + +total142)) + it.valor3;
        it.valor1 = (+total41 - (+total131 + +total141)) + it.valor2;
        // it.valor2 += it.valor3
      }
    }
  }

}
