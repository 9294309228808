
import {
  AlignmentType, BorderStyle, convertMillimetersToTwip, Document, Footer, Header, ISpacingProperties, Packer, PageOrientation,
  Paragraph, ParagraphChild, Table, TableCell, TableOfContents, TableRow, TextRun, UnderlineType, WidthType
} from 'docx';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { FuncaoService } from '../util/funcao.service';
import { Coluna, Converter, FormatoExportacao } from './types';

export class Relatorio {

  private static funcaoService = new FuncaoService();
  private static VARIACAO_TAM: number = 1.75;

  constructor() {
  }

  /**
   * Imprime de forma dinâmica um PDF
   * @param titulo - Título do relatório
   * @param usuario - Usuário logado
   * @param sobrenome - Usuário logado
   * @param orgao - órgão logado
   * @param brasao - brasão do órgão em base64
   * @param lista - lista de registros
   * @param colunas - colunas que serão impressas
   * @param orientacao - orientação da página
   * @param nomePdf - nome do arquivo PDF
   * @param largura - vetor com as larguras das colunas
   * @param totalizar - colunas para totalizar ao fim do relatório
   * @param zebrado - listrar as linhas do arquivo
   */
  static imprimir(titulo: string, usuario: string, sobrenome: string, orgao: string, brasao: string,
    lista: any[], colunas: string[] | Coluna[], orientacao: 'landscape' | 'portrait', nomePdf?: string,
    largura?: (string | number)[], totalizar?: (string | {})[], conteudoAdicional?: {}[], layouts?: {}, subtitulo?: string, zebrado?: boolean, tamanhoFonteTotalizador?: number) {
    if (!largura) {
      largura = [];
      for (let i = 0; i < colunas.length; i++) {
        const coluna: any = colunas[i];
        if (typeof coluna === 'string') {
          largura.push('*');
        } else {
          if (coluna['agrupar'] !== true) {
            largura.push('*');
          }
        }
      }
    }
    const conteudo: Array<Array<any>> = this.retornarConteudo(colunas, lista, totalizar, zebrado, tamanhoFonteTotalizador);

    let mesclar = false;
    for (let coluna of colunas) {
      if (typeof coluna !== 'string' && coluna.mesclar)
        mesclar = true;
    }

    const content: {}[] = [
      {
        layout: 'padrao',
        table: {
          headerRows: mesclar ? 2 : 1,
          widths: largura,

          body: conteudo
        }
      },
      { text: 'Total de registros: ' + lista.length, alignment: 'right', margin: [0, 10, 0, 0] }
    ];

    content.push(conteudoAdicional);
    lista = null;
    this.imprimirPersonalizado(titulo, usuario, sobrenome, orgao, brasao, content, orientacao, nomePdf, layouts, false, false, null, subtitulo);
  }

  static async imprimirPersonalizado(
    titulo: string, usuario: string, sobrenome: string, orgao: string, brasao: string, conteudo: {}[],
    orientacao: 'landscape' | 'portrait', nomePdf?: string, layouts?: {}, ocultarCabecalho: boolean = false,
    ocultarRodape: boolean = false, formato?: FormatoExportacao, subtitulo?: string, margens?: number[], exportar?: boolean,
    cabecalhoPersonalizado?: any, rodapePersonalizado?: any, download?: boolean, rodapePersonalizadoDocx?: any, getAll?: boolean): Promise<any> {
    formato = !formato ? 'pdf' : formato;

    if (formato === 'docx') {

      return await this.imprimirPersonalizadoDocx(titulo, usuario, sobrenome, orgao, brasao, conteudo, orientacao, nomePdf, ocultarCabecalho, exportar, rodapePersonalizadoDocx);

    } else if (formato === 'pdf') {

      if (!layouts) {
        layouts = {
          padrao: {
            hLineWidth(i, node) {
              return (i === node.table.headerRows || i === 0) ? 2 : 0;
            },
            vLineWidth(i) {
              return 0;
            },
            hLineColor(i) {
              return i === 1 || i === 0 ? 'black' : 'gray';
            },
            paddingLeft(i) {
              return i === 0 ? 0 : 3;
            },
            paddingRight(i, node) {
              return (i === node.table.widths.length - 1) ? 0 : 3;
            }
          }
        };
      } else {
        layouts['padrao'] = layouts
      }
      if (!orientacao) {
        orientacao = 'portrait';
      }
      let brasaoImage: {};
      if (brasao) {
        brasaoImage = {
          image: brasao,
          width: 60,
          alignment: 'center',
          margin: [0, 30, 0, 0]
        };
      } else {
        brasaoImage = { margin: [0, 30, 0, 0], text: '' };
      }
      let cabecalho = [];

      if (!ocultarCabecalho) {
        cabecalho = [
          brasaoImage,
          { text: orgao, style: 'header' },
          { text: titulo, style: 'sub_header' }
        ];
        if (subtitulo?.length > 0) {
          cabecalho.push({ text: subtitulo, fontSize: 10, alignment: 'center' });
        }
      }

      if (cabecalhoPersonalizado) {
        ocultarCabecalho = false;
      }

      const dd: any = {
        compress: true,
        info: {
          title: (nomePdf ? nomePdf : 'listagem')
        },
        header: cabecalhoPersonalizado ? cabecalhoPersonalizado : cabecalho,
        footer(currentPage, pageCount, pageSize) {
          const hoje = new Date();

          if (ocultarRodape)
            return [];

          if (rodapePersonalizado) {
            if (typeof rodapePersonalizado === 'function')
              return rodapePersonalizado(currentPage, pageCount, pageSize);
            else
              return rodapePersonalizado;
          }

          return [
            { canvas: [{ type: 'line', x1: 30, y1: 10, x2: pageSize.width - 30, y2: 10, lineWidth: 0.5 }] },
            {
              columns: [{
                text: (usuario ? 'Emitido por ' + usuario + ' ' : '') + (sobrenome ? sobrenome + ' - ' : '') + hoje.toLocaleString(),
                alignment: 'left', margin: [30, 5, 0, 0], style: 'footer'
              },
              {
                text: 'Página ' + currentPage.toString() + ' de ' + pageCount, alignment: 'right',
                margin: [0, 5, 30, 0], style: 'footer'
              }]
            }
          ];
        },
        content: conteudo,
        styles: {
          header: {
            bold: true,
            fontSize: 14,
            alignment: 'center'
          },
          sub_header: {
            fontSize: 10,
            alignment: 'center'
          },
          footer: {
            fontSize: 8
          }
        },
        defaultStyle: {
          fontSize: 8
        },
        pageMargins: margens ? margens : [30, ocultarCabecalho ? 20 : brasao ? 140 : 80, 30, ocultarRodape ? 20 : 40],
        pageSize: 'A4',
        pageOrientation: orientacao
      };

      if (exportar)
        return await new Promise((resolve, reject) => {
          pdfMake.createPdf(dd, layouts, null, pdfFonts.pdfMake.vfs).getBlob((blob) => { resolve(blob) })
        })
      else
        if (getAll) return pdfMake.createPdf(dd, layouts, null, pdfFonts.pdfMake.vfs)
        else if (download) pdfMake.createPdf(dd, layouts, null, pdfFonts.pdfMake.vfs).download();
        else pdfMake.createPdf(dd, layouts, null, pdfFonts.pdfMake.vfs).open();

    } else {
      throw new Error('Formato não suportado');
    }
  }

  static async exportarPersonalizado(
    titulo: string, usuario: string, sobrenome: string, orgao: string, brasao: string, conteudo: {}[],
    orientacao: 'landscape' | 'portrait', nomePdf?: string, layouts?: {}, ocultarCabecalho: boolean = false,
    ocultarRodape: boolean = false, formato?: FormatoExportacao, subtitulo?: string, margens?: number[], cabecalhoPersonalizado?: any, rodapePersonalizado?: any): Promise<Blob> {
    return await this.imprimirPersonalizado(titulo, usuario, sobrenome, orgao, brasao, conteudo, orientacao, nomePdf, layouts, ocultarCabecalho, ocultarRodape, formato, subtitulo, margens, true, cabecalhoPersonalizado, rodapePersonalizado);
  }

  private static async imprimirPersonalizadoDocx(
    titulo: string, usuario: string, sobrenome: string, orgao: string, brasao: string, conteudo: {}[], orientacao: 'landscape' | 'portrait',
    nomeArquivo?: string, ocultarCabecalho?: boolean, exportar?: boolean, rodapePersonalizado?: {}[]): Promise<any> {
    const header: (Paragraph | Table)[] = [];
    /*
    if (brasao) {
      header.push(new ImageRun({
        data: brasao, transformation: { width: 200, height: 200, }
      }))
    }
    */
    header.push(new Paragraph({
      alignment: AlignmentType.CENTER,
      children: [new TextRun({ text: orgao, bold: true, size: 16 * this.VARIACAO_TAM })]
    }))
    header.push(new Paragraph({
      alignment: AlignmentType.CENTER,
      children: [new TextRun({ text: titulo, bold: true, size: 12 * this.VARIACAO_TAM })]
    }))

    const paragrafos: (Paragraph | Table | TableOfContents)[] = this.converterPdfMakeParaChildren(conteudo);

    const doc = new Document({
      creator: usuario,
      sections: [{
        properties: {
          page: {
            size: {
              orientation: orientacao === 'landscape' ? PageOrientation.LANDSCAPE : PageOrientation.PORTRAIT
            }
          }
        },
        headers: ocultarCabecalho ? {} : {
          default: new Header({ children: header }),
        },
        footers: !rodapePersonalizado ? {} : {
          default: new Footer({ children: this.converterPdfMakeParaChildren(rodapePersonalizado) })
        },
        children: paragrafos
      }]
    });

    if (exportar)
      return await Packer.toBlob(doc)
    else
      Packer.toBlob(doc).then(blob => {
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${nomeArquivo}.docx`;
        link.target = '_blank';
        link.click();
        window.URL.revokeObjectURL(downloadURL);
      });
  }


  private static converterPdfMakeParaChildren(conteudo: {}[]): (Paragraph | Table | TableOfContents)[] {
    const paragrafos: (Paragraph | Table | TableOfContents)[] = [];
    for (const bloco of conteudo) {
      if (Array.isArray(bloco)) {
        (bloco as []).forEach(item => {
          if (item['table'] || (Array.isArray(item) && item[0]['table'])) {
            paragrafos.push(this.converterPDFMakeTableParaTable((item['table'] ? item['table'] : item[0]['table'])))
          } else if (item['columns']) {
            paragrafos.push(this.converterPDFMakeColumnsParaTable(item['columns']))
          } else if (item['stack']) {
            paragrafos.push(this.converterPDFMakeStackParaTable(item['stack']))
          } else if (item['ul']) {
            paragrafos.push(...this.converterPDFMakeUlParaParagraph(item['ul']))
          } else {
            paragrafos.push(this.converterPDFMakeContentParaParagraph(item))
          }
        })
      } else {
        if (bloco?.['table']) {
          paragrafos.push(this.converterPDFMakeTableParaTable(bloco['table']))
        } else if (bloco?.['columns']) {
          paragrafos.push(this.converterPDFMakeColumnsParaTable(bloco['columns']))
        } else if (bloco?.['stack']) {
          paragrafos.push(this.converterPDFMakeStackParaTable(bloco['stack']))
        } else {
          paragrafos.push(this.converterPDFMakeContentParaParagraph(bloco))
        }
      }
    }
    return paragrafos;
  }

  private static converterPDFMakeContentParaParagraph(item: any): Paragraph {
    const col: Coluna = item;
    if (!item || !col) return new Paragraph({});

    let alignment: AlignmentType;
    switch (col.alignment) {
      default:
      case 'left':
        alignment = AlignmentType.LEFT;
        break;
      case 'right':
        alignment = AlignmentType.RIGHT;
        break;
      case 'center':
        alignment = AlignmentType.CENTER;
        break;
      case 'justify':
        alignment = AlignmentType.JUSTIFIED;
        break;
    }

    let spacing: ISpacingProperties;
    const margin: number[] = item['margin'];
    if (margin) {
      let top: number = 0, bottom: number = 0;
      if (margin.length === 1) {
        top = margin[0] * 10;
        bottom = margin[0] * 10;
      } else if (margin.length === 2) {
        top = margin[1] * 10;
        bottom = margin[1] * 10;
      } else if (margin.length === 4) {
        top = margin[1] * 10;
        bottom = margin[3] * 10;
      }

      spacing = {
        before: top > 0 ? (top * this.VARIACAO_TAM) : undefined,
        after: bottom > 0 ? (bottom * this.VARIACAO_TAM) : undefined,
      }
    }

    let children: ParagraphChild[] = [];
    if (!Array.isArray(item['text'])) {
      children = [this.converterPDFMakeContentParaTextRun(item)]
    } else {
      (item['text'] as []).forEach(text => {
        children.push(this.converterPDFMakeContentParaTextRun(text));
      })
    }

    return new Paragraph({ alignment, spacing, children })
  }

  private static converterPDFMakeContentParaTextRun(item: any): TextRun {
    const col: Coluna = item;
    if (!item || !col) return new TextRun({});

    let underline: { color?: string; type?: UnderlineType; };
    if (col.decoration === 'underline') {
      underline = { type: UnderlineType.SINGLE }
    }

    if (item['children'])
      return new TextRun({
        children: item['children'],
        bold: col.bold,
        italics: col.italics,
        underline,
        size: (col.fontSize * this.VARIACAO_TAM)
      });

    return new TextRun({
      text: item['text'],
      bold: col.bold,
      italics: col.italics,
      underline,
      size: (col.fontSize * this.VARIACAO_TAM)
    })
  }

  private static converterPDFMakeTableParaTable(table: any): Table {
    const larguras: (string | number)[] = table['widths'];
    const linhas: TableRow[] = [];
    const borders = {
      bottom: {
        size: 0, color: '#fff', style: BorderStyle.NONE
      },
      top: {
        size: 0, color: '#fff', style: BorderStyle.NONE
      },
      left: {
        size: 0, color: '#fff', style: BorderStyle.NONE
      },
      right: {
        size: 0, color: '#fff', style: BorderStyle.NONE
      },
      insideHorizontal: {
        size: 0, color: '#fff', style: BorderStyle.NONE
      },
      insideVertical: {
        size: 0, color: '#fff', style: BorderStyle.NONE
      },
    }

    const tbody: any[] = table['body'];
    for (let i = 0; i < tbody.length; i++) {
      const colunas: TableCell[] = [];

      const tr: {}[] = tbody[i];
      for (let j = 0; j < tr.length; j++) {
        const td = tr[j];
        let width: {
          size: number | string;
          type?: WidthType;
        };
        if (typeof larguras[j] === 'string') {
          width = { size: 100, type: WidthType.PERCENTAGE }
        } else {
          width = { size: convertMillimetersToTwip(+larguras[j]), type: WidthType.DXA }
        }

        const colSpan: number = td['colSpan'] ? +td['colSpan'] : 0;

        colunas.push(new TableCell({
          borders, columnSpan: colSpan, children: [this.converterPDFMakeContentParaParagraph(td)],
        }))

        j += colSpan;
      }
      linhas.push(new TableRow({ children: colunas }))
    }

    return new Table({
      alignment: AlignmentType.CENTER, rows: linhas, borders, width: { size: 100, type: WidthType.PERCENTAGE },
    })
  }

  private static converterPDFMakeColumnsParaTable(columns: {}[]): Table {
    const borders = {
      bottom: {
        size: 0, color: '#fff', style: BorderStyle.NONE
      },
      top: {
        size: 0, color: '#fff', style: BorderStyle.NONE
      },
      left: {
        size: 0, color: '#fff', style: BorderStyle.NONE
      },
      right: {
        size: 0, color: '#fff', style: BorderStyle.NONE
      },
      insideHorizontal: {
        size: 0, color: '#fff', style: BorderStyle.NONE
      },
      insideVertical: {
        size: 0, color: '#fff', style: BorderStyle.NONE
      },
    }

    const colunas: TableCell[] = [];
    columns.forEach(column => {
      column['alignment'] = 'center';
      if (column['alignmentDocx'])
        column['alignment'] = column['alignmentDocx'];
      colunas.push(new TableCell({
        borders, children: [this.converterPDFMakeContentParaParagraph(column)],
      }))
    })

    return new Table({
      alignment: AlignmentType.CENTER, borders,
      width: { size: 100, type: WidthType.PERCENTAGE },
      rows: [
        new TableRow({ children: colunas })
      ]
    })
  }

  private static converterPDFMakeStackParaTable(stack: {}[]): Table {
    const borders = {
      bottom: {
        size: 0, color: '#fff', style: BorderStyle.NONE
      },
      top: {
        size: 0, color: '#fff', style: BorderStyle.NONE
      },
      left: {
        size: 0, color: '#fff', style: BorderStyle.NONE
      },
      right: {
        size: 0, color: '#fff', style: BorderStyle.NONE
      },
      insideHorizontal: {
        size: 0, color: '#fff', style: BorderStyle.NONE
      },
      insideVertical: {
        size: 0, color: '#fff', style: BorderStyle.NONE
      },
    }

    return new Table({
      alignment: AlignmentType.CENTER, borders,
      width: { size: 100, type: WidthType.PERCENTAGE },
      rows: stack.map((s) => new TableRow({
        children: [new TableCell({
          borders, children: [this.converterPDFMakeContentParaParagraph(s)],
        })]
      }))
    })
  }

  private static converterPDFMakeUlParaParagraph(ul: [][]): Paragraph[] {

    let children: Paragraph[] = [];

    for (const linha of ul) {
      for (const item of linha) {
        children.push(this.converterPDFMakeContentParaParagraph({ text: item }));
      }
    }
    return children;
  }

  static retornarConteudo(colunas: string[] | Coluna[], lista: any[], totalizar: (string | {})[], zebrado?: boolean, tamanhoFonteTotalizador?: number): Array<Array<any>> {
    const retorno: Array<Array<any>> = new Array();
    const header = new Array();
    let qtdGrupos = 0;
    let qtdSubTabelas = 0;
    let mesclar = false;
    for (let coluna of colunas) {
      if (typeof coluna !== 'string' && coluna.mesclar)
        mesclar = true;
    }
    // varre as colunas para montar cabeçalho
    for (let i = 0; i < colunas.length; i++) {
      const coluna: string | Coluna = colunas[i];
      if (typeof coluna === 'string') {
        let h = { text: coluna, bold: true };
        if (mesclar)
          h['rowSpan'] = 2;
        header.push(h);
      } else if (coluna.agrupar) {
        qtdGrupos++;
      } else if (coluna.mesclar) {
        let h = { text: coluna.titulo, bold: true, alignment: coluna.alignment ? coluna.alignment : 'left', colSpan: coluna.colunas.length };
        if (coluna.margin)
          h['margin'] = coluna.margin;
        if (coluna.adicionaisHeader)
          h = Object.assign(h, coluna.adicionaisHeader);
        header.push(h);
        for (let j = 0; j < coluna.colunas.length - 1; j++) {
          header.push({ text: '' });
        }
      } else if (coluna.colunas && !coluna.mesclar && coluna.colunas.length > 0) {
        qtdSubTabelas++;
      } else {
        let h = { text: coluna.titulo, bold: true, alignment: coluna.alignment ? coluna.alignment : 'left' };
        if (mesclar)
          h['rowSpan'] = 2;
        if (coluna.margin)
          h['margin'] = coluna.margin;
        if (coluna.adicionaisHeader)
          h = Object.assign(h, coluna.adicionaisHeader);
        header.push(h);
      }
    }
    retorno.push(header);

    if (mesclar) {
      const headerextend = new Array();
      for (let i = 0; i < colunas.length; i++) {
        const coluna: string | Coluna = colunas[i];
        if (typeof coluna === 'string') {
          headerextend.push({ text: '' });
        } else if (!coluna.agrupar && (!coluna.colunas || coluna.colunas.length === 0 || coluna.mesclar)) {
          if (coluna.mesclar) {
            for (let coluna2 of coluna.colunas) {
              let h = { text: coluna2.titulo, bold: true, alignment: coluna2.alignment ? coluna2.alignment : 'left' };
              if (coluna2.margin)
                h['margin'] = coluna2.margin;
              headerextend.push(h);
            }
          } else {
            headerextend.push({ text: '' });
          }
        }
      }
      retorno.push(headerextend);
      let colunas2 = [];
      for (let coluna of colunas) {
        if (typeof coluna === 'string' || !coluna.mesclar) {
          colunas2.push(coluna)
        } else {
          for (let coluna2 of coluna.colunas) {
            colunas2.push(coluna2);
          }
        }
      }
      colunas = colunas2;
    }

    // método que agrupa os registros (funciona mesmo sem agrupamento)
    const linhas = this.conteudoAgrupado(colunas, lista, colunas.length, qtdGrupos, qtdSubTabelas, totalizar, null, zebrado);
    if (totalizar) {
      const totalGeral = this.funcaoService.totalizar(lista, totalizar, colunas.map(coluna => coluna['distinct']));
      const registroGeral = new Array();
      let indiceTotalizador = 0;
      let qtdeColunas = 0;
      const colunasTermino = [];
      const indices = [];
      let cont = 0;
      // percorre as colunas para encontrar e procura o totalizador
      for_colunas:
      for (const col of colunas) {
        // caso a coluna seja de agrupamento ignora pois nao aparece como coluna
        if (typeof col !== 'string' && (col.agrupar || (col.colunas && col.colunas.length > 0))) {
          continue;
        } else {
          qtdeColunas++;
          const nomeColuna: string = (typeof col === 'string' ? col : col.coluna);

          // percorre os totalizadores para procurar a coluna atual, caso nao encontre inclui uma posição vazia

          for (const total of totalizar) {

            const nomeTotalizador: string = (typeof total === 'string' ? total : total['nome']);
            // caso encontre a coluna no totalizador a inclui e continua o laço das colunas
            if (nomeColuna === nomeTotalizador) {
              if (!indiceTotalizador) {
                indiceTotalizador = qtdeColunas - 1;
              }
              let col2;
              totalGeral[(nomeTotalizador)] = (+totalGeral[(nomeTotalizador)]).toFixed(typeof col === 'string' ? 2 : (col.decimais ? col.decimais : 2));
              col2 = {
                text: this.funcaoService.convertToBrNumber(totalGeral[(nomeTotalizador)], (typeof col === 'string' ? 2 : (col.decimais ? col.decimais : 2))).replace((typeof col !== 'string' && col.removeNegativo ? '-' : ''), ''),
                bold: true, alignment: 'right',
                preserveLeadingSpaces: true,
                fontSize: tamanhoFonteTotalizador ?? 8,
                decoration: 'underline'
              };


              if (typeof col === 'object' && col.adicionaisBody)
                col2 = Object.assign(col2, col.adicionaisBody);
              if (typeof total !== 'string' && total['processarTermino']) {
                colunasTermino.push(total);
                indices.push(+cont);
              }
              registroGeral.push(col2);
              cont++;
              continue for_colunas;
            }
          }

          if (typeof col === 'object' && col.adicionaisBody)
            registroGeral.push(Object.assign({ text: '' }, col.adicionaisBody));
          else
            registroGeral.push({ text: '' });
          cont++;
        }
      }
      let conta = 0;
      for (let indice of indices) {
        const totalTermino = colunasTermino[conta++];

        registroGeral[indice].text = this.funcaoService.processarFuncao(totalTermino, totalGeral);
        if (totalTermino.alignment) {
          registroGeral[indice].alignment = totalTermino.alignment;
        }
      }

      // remove a primeira posição para incluir o nome do agrupamento
      // registroGeral.shift();

      let col2 = {
        colSpan: indiceTotalizador < 1 ? 0 : indiceTotalizador,
        text: 'TOTAL GERAL:',
        bold: true, alignment: 'left', preserveLeadingSpaces: true,
        fontSize: tamanhoFonteTotalizador ?? 8, decoration: 'underline'
      };

      const campo = colunas[0];
      if (typeof campo === 'object' && campo.adicionaisBody)
        col2 = Object.assign(col2, campo.adicionaisBody);
      if (typeof campo === 'object' && campo.adicionaisBodyEspaco)
        col2 = Object.assign(col2, campo.adicionaisBodyEspaco);

      registroGeral.shift();
      registroGeral.unshift(col2);

      linhas.push(registroGeral);
    }


    return retorno.concat(linhas);
  }

  static conteudoAgrupado(
    colunas: string[] | Coluna[], lista: any[], tamanho: number,
    qtdGrupos: number, qtdSubTabelas: number, totalizar?: (string | {})[], processados?: number, zebrado?: boolean): Array<Array<any>> {
    let retorno: Array<Array<any>> = new Array();
    if (!lista || lista.length === 0) {
      return retorno;
    }
    // varre as colunas procurando agrupamentos
    if (!processados) {
      processados = 0;
    }

    for (let i = processados; i < colunas.length; i++) {
      const coluna: string | Coluna = colunas[i];
      if (typeof coluna !== 'string') {
        if (coluna.agrupar) {
          // retorna o grupo com os registros e totalizadores
          const grupos = this.funcaoService.agrupar(lista, coluna.coluna, totalizar, coluna.distinct);
          for (const grupo of grupos) {
            const registroGrupo = new Array();
            // caso coluna seja um enum, converte para texto
            if (coluna.converter) {
              const valores = coluna.converter as Converter[];
              for (let c = 0; c < valores.length; c++) {
                const val = valores[c];
                if (val.id === grupo.grupo) {
                  grupo.grupo = val.nome;
                }
              }
            }

            let indiceTotalizador = 0;
            let qtdeColunas = 0;
            let colunasTermino = [];
            let indices = [];
            let cont = 0;
            // percorre as colunas para encontrar e procura o totalizador
            for_colunas:
            for (const col of colunas) {
              // caso a coluna seja de agrupamento ignora pois nao aparece como coluna++
              if (typeof col !== 'string' && (col.agrupar || (col.colunas && col.colunas.length > 0))) {
                continue;
              } else {
                qtdeColunas++;
                // caso haja totalizadores os inclui na posição de cada coluna correspondente
                if (totalizar) {
                  const nomeColuna: string = (typeof col === 'string' ? col : col.coluna);

                  // percorre os totalizadores para procurar a coluna atual, caso nao encontre inclui uma posição vazia

                  for (const total of totalizar) {
                    if (typeof total === 'object' && total['abaixo'])
                      continue;
                    const nomeTotalizador: string = (typeof total === 'string' ? total : total['nome']);
                    // caso encontre a coluna no totalizador a inclui e continua o laço das colunas
                    if (nomeColuna === nomeTotalizador) {
                      if (!indiceTotalizador) {
                        indiceTotalizador = qtdeColunas - 1;
                      }
                      grupo.totalizadores[(nomeTotalizador)] = (+grupo.totalizadores[(nomeTotalizador)]).toFixed(typeof col === 'string' ? 2 : (col.decimais ? col.decimais : 2));
                      let col2 = {
                        text: this.funcaoService.convertToBrNumber(grupo.totalizadores[(nomeTotalizador)], (typeof col === 'string' ? 2 : (col.decimais ? col.decimais : 2))).replace((typeof col !== 'string' && col.removeNegativo ? '-' : ''), ''),
                        bold: coluna.bold, alignment: 'right', italics: coluna.italics, decoration: coluna.decoration
                      };
                      if (typeof col === 'object' && col.adicionaisBody)
                        col2 = Object.assign(col2, coluna.adicionaisBody);
                      if (typeof total !== 'string' && total['processarTermino']) {
                        colunasTermino.push(total);
                        indices.push(+cont);
                      }
                      registroGrupo.push(col2);
                      cont++;
                      continue for_colunas;
                    }
                  }

                  registroGrupo.push('');
                  cont++;
                } else {
                  registroGrupo.push('');
                  cont++;
                }

              }
            }
            let conta = 0;
            for (let indice of indices) {
              const totalTermino = colunasTermino[conta++];
              registroGrupo[indice].text = this.funcaoService.processarFuncao(totalTermino, grupo.totalizadores);
              if (totalTermino.alignment) {
                registroGrupo[indice].alignment = totalTermino.alignment;
              }
            }

            // remove a primeira posição para incluir o nome do agrupamento
            registroGrupo.shift();

            let grupo2 = {
              colSpan: indiceTotalizador < 1 ? colunas.length - qtdGrupos - qtdSubTabelas : indiceTotalizador - 1,
              text: coluna.concattitulogrupo ? coluna.titulo + ' ' + this.funcaoService.retornaCelula(coluna, grupo.grupo, null) : this.funcaoService.retornaCelula(coluna, grupo.grupo, null),
              preserveLeadingSpaces: true
            };
            if (coluna.font) grupo2['font'] = coluna.font;
            if (coluna.fontSize) grupo2['fontSize'] = coluna.fontSize;
            if (coluna.fontFeatures) grupo2['fontFeatures'] = coluna.fontFeatures;
            if (coluna.lineHeight) grupo2['lineHeight'] = coluna.lineHeight;
            if (coluna.bold) grupo2['bold'] = coluna.bold;
            if (coluna.italics) grupo2['italics'] = coluna.italics;
            if (coluna.alignment) grupo2['alignment'] = coluna.alignment;
            if (coluna.characterSpacing) grupo2['characterSpacing'] = coluna.characterSpacing;
            if (coluna.color) grupo2['color'] = coluna.color;
            if (coluna.background) grupo2['background'] = coluna.background;
            if (coluna.markerColor) grupo2['markerColor'] = coluna.markerColor;
            if (coluna.decoration) grupo2['decoration'] = coluna.decoration;
            if (coluna.decorationStyle) grupo2['decorationStyle'] = coluna.decorationStyle;
            if (coluna.decorationColor) grupo2['decorationColor'] = coluna.decorationColor;

            if (coluna.adicionaisBody)
              grupo2 = Object.assign(grupo2, coluna.adicionaisBody);
            registroGrupo.unshift(grupo2);
            // adiciona a linha do grupo no relatório
            retorno.push(registroGrupo);
            // chamada recursiva para fazer subgrupos ou inserir linhas do grupo
            retorno = retorno.concat(this.conteudoAgrupado(colunas, grupo.registros, tamanho, qtdGrupos, qtdSubTabelas, totalizar, +processados + 1));

            let colunastotalizacao = [];
            colunasTermino = [];
            indices = [];
            cont = 0;

            for_colunas2:
            for (const col of colunas) {
              // caso a coluna seja de agrupamento ignora pois nao aparece como coluna++
              if (typeof col !== 'string' && (col.agrupar || (col.colunas && col.colunas.length > 0))) {
                continue;
              } else {
                qtdeColunas++;
                // caso haja totalizadores os inclui na posição de cada coluna correspondente
                if (totalizar) {
                  const nomeColuna: string = (typeof col === 'string' ? col : col.coluna);

                  // percorre os totalizadores para procurar a coluna atual, caso nao encontre inclui uma posição vazia

                  for (const total of totalizar) {

                    if (typeof total !== 'object' || !total['abaixo'])
                      continue;
                    const nomeTotalizador: string = (typeof total === 'string' ? total : total['nome']);
                    // caso encontre a coluna no totalizador a inclui e continua o laço das colunas
                    if (nomeColuna === nomeTotalizador) {
                      if (!indiceTotalizador) {
                        indiceTotalizador = qtdeColunas - 1;
                      }
                      grupo.totalizadores[(nomeTotalizador)] = (+grupo.totalizadores[(nomeTotalizador)]).toFixed(typeof col === 'string' ? 2 : (col.decimais ? col.decimais : 2));
                      let col2 = {
                        text: typeof total === 'object' && total['titulo'] ? total['titulo'] + ':     ' + this.funcaoService.convertToBrNumber(grupo.totalizadores[(nomeTotalizador)], (typeof col === 'string' ? 2 : (col.decimais ? col.decimais : 2))) :
                          this.funcaoService.convertToBrNumber(grupo.totalizadores[(nomeTotalizador)], (typeof col === 'string' ? 2 : (col.decimais ? col.decimais : 2))),
                        bold: coluna.bold, alignment: 'right', italics: coluna.italics, decoration: coluna.decoration, border: [false, true, false, false]
                      };

                      if (typeof total !== 'string' && total['processarTermino']) {
                        colunasTermino.push(total);
                        indices.push(+cont);
                      }
                      colunastotalizacao.push(col2);
                      cont++;
                      continue for_colunas2;
                    }
                  }

                  colunastotalizacao.push({ text: '', border: [false, false, false, false] });
                  cont++;
                } else {
                  colunastotalizacao.push({ text: '', border: [false, false, false, false] });
                  cont++;
                }
              }
            }
            conta = 0;
            for (let indice of indices) {
              const totalTermino = colunasTermino[conta++];
              registroGrupo[indice].text = this.funcaoService.processarFuncao(totalTermino, grupo.totalizadores);
              if (totalTermino.alignment) {
                registroGrupo[indice].alignment = totalTermino.alignment;
              }
            }
            if (colunastotalizacao.length > 0) {
              retorno.push(colunastotalizacao);
            }
            // linha com margem para sepração de grupos
            let espaco = { colSpan: colunas.length - qtdGrupos - qtdSubTabelas, text: '', margin: [0, 0, 0, 10] };
            if (coluna.adicionaisBody)
              espaco = Object.assign(espaco, coluna.adicionaisBody);
            if (coluna.adicionaisBodyEspaco)
              espaco = Object.assign(espaco, coluna.adicionaisBodyEspaco);

            //Quebra a página ao final do grupo, desconsiderando o último
            if(coluna.quebrarPaginaAgrupamento && grupos[grupos.length-1] != grupo)
              espaco = Object.assign(espaco, {pageBreak: 'before'});
            
          

            retorno.push([espaco])
          }
          return retorno;
        } else {
          // caso não seja coluna de agrupamento, retorna as linhas do relatório (antigo Detail do jasper)
          return this.conteudoGeral(colunas, lista, tamanho, qtdGrupos, qtdSubTabelas, zebrado);
        }
      } else {
        // caso não seja coluna de agrupamento, retorna as linhas do relatório (antigo Detail do jasper)
        return this.conteudoGeral(colunas, lista, tamanho, qtdGrupos, qtdSubTabelas, zebrado);
      }
    }
  }

  // static espacos(quantidade: number): string {
  //   let retorno = '';
  //   for (let i = 0; i < quantidade; i++) {
  //     retorno += '    ';
  //   }
  //   return retorno;
  // }

  static conteudoGeral(colunas: string[] | Coluna[], lista: any[], tamanho: number, qtdGrupos: number, qtdSubTabelas: number, zebrado?: boolean): Array<Array<any>> {
    const retorno: Array<Array<any>> = new Array();
    // varre os registros para criar linha do relatório
    let contadorListra = 0;
    for (let i = 0; i < lista.length; i++) {

      contadorListra++

      const registro = new Array();
      let regSubTabela = new Array();
      const ent = lista[i];
      for (let a = qtdGrupos; a < colunas.length; a++) {
        const coluna: string | Coluna = colunas[a];
        if (typeof coluna === 'string') {
          registro.push(ent[coluna]);
        } else if (coluna.colunas && !coluna.mesclar && coluna.colunas.length > 0) {
          regSubTabela.push(this.subTabela(this.funcaoService.retornaValorEntidade(ent, coluna.coluna),
            coluna, colunas.length - qtdSubTabelas - qtdGrupos));
          for (let t = 0; t < colunas.length - qtdSubTabelas - qtdGrupos - 1; t++) {
            regSubTabela.push('');
          }
        } else {
          let valor = this.funcaoService.retornaValorEntidade(ent, coluna.coluna);
          let selecionado = false;
          if (coluna.converter) {
            const valores = coluna.converter as Converter[];
            for (let c = 0; c < valores.length; c++) {
              const val = valores[c];
              if (val.id === valor && !selecionado) {
                valor = val.nome;
                selecionado = true;
              }
            }
          }
          // tive que colocar depois que colocou a variavel removeNegativo; 
          valor = valor === null ? '' : valor;

          let coluna2 = {
            text: this.funcaoService.retornaCelula(coluna, valor, ent) ? this.funcaoService.retornaCelula(coluna, valor, ent).replace((coluna.removeNegativo ? '-' : ''), '') : '',
            preserveLeadingSpaces: true,
            fillColor: !zebrado ? null : contadorListra % 2 === 0 ? '#dee2e6' : null
          };
          if (coluna.font) coluna2['font'] = coluna.font;
          if (coluna.fontSize) coluna2['fontSize'] = coluna.fontSize;
          if (coluna.fontFeatures) coluna2['fontFeatures'] = coluna.fontFeatures;
          if (coluna.lineHeight) coluna2['lineHeight'] = coluna.lineHeight;
          if (coluna.bold) coluna2['bold'] = coluna.bold;
          if (coluna.italics) coluna2['italics'] = coluna.italics;
          if (coluna.alignment) coluna2['alignment'] = coluna.alignment;
          if (coluna.characterSpacing) coluna2['characterSpacing'] = coluna.characterSpacing;
          if (coluna.color) coluna2['color'] = coluna.color;
          if (coluna.background) coluna2['background'] = coluna.background;
          if (coluna.markerColor) coluna2['markerColor'] = coluna.markerColor;
          if (coluna.decoration) coluna2['decoration'] = coluna.decoration;
          if (coluna.decorationStyle) coluna2['decorationStyle'] = coluna.decorationStyle;
          if (coluna.decorationColor) coluna2['decorationColor'] = coluna.decorationColor;

          if (coluna.adicionaisBody)
            coluna2 = Object.assign(coluna2, coluna.adicionaisBody);

          registro.push(coluna2);
        }
      }
      retorno.push(registro);
      if (regSubTabela.length > 0) {
        retorno.push(regSubTabela);
      }
    }
    return retorno;
  }

  private static subTabela(lista: {}[], coluna: Coluna, colunas: number): {} {
    let largura = coluna.larguras;
    if (!largura) {
      largura = [];
      for (let i = 0; i < coluna.colunas.length; i++) {
        const coluna: any = colunas[i];
        if (typeof coluna === 'string') {
          largura.push('*');
        } else {
          if (coluna['agrupar'] !== true) {
            largura.push('*');
          }
        }
      }
    }

    let layout_padrao = coluna.layout ? coluna.layout : {
      hLineWidth(i, node) {
        return (i === node.table.headerRows || i === 0) ? 2 : 0;
      },
      vLineWidth(i) {
        return 0;
      },
      hLineColor(i) {
        return i === 1 || i === 0 ? 'black' : 'gray';
      },
      paddingLeft(i) {
        return i === 0 ? 0 : 3;
      },
      paddingRight(i, node) {
        return (i === node.table.widths.length - 1) ? 0 : 3;
      }
    };

    const conteudo: Array<Array<any>> = this.retornarConteudo(coluna.colunas, lista, coluna.totalizar);
    const content: {}[] = [
      {
        layout: layout_padrao,
        table: {
          headerRows: 1,
          widths: largura,

          body: conteudo
        }
      }
    ];
    let subTabela: {} = {
      colSpan: colunas,
      stack: coluna.titulo ? [
        {
          text: coluna.titulo,
          preserveLeadingSpaces: true,
          font: coluna.font,
          fontSize: coluna.fontSize,
          fontFeatures: coluna.fontFeatures,
          lineHeight: coluna.lineHeight,
          bold: coluna.bold,
          italics: coluna.italics,
          alignment: coluna.alignment ? coluna.alignment : 'left',
          characterSpacing: coluna.characterSpacing,
          color: coluna.color,
          background: coluna.background,
          markerColor: coluna.markerColor,
          decoration: coluna.decoration,
          decorationStyle: coluna.decorationStyle,
          decorationColor: coluna.decorationColor,
        },
        content
      ] : [content]
    };

    subTabela = Object.assign(subTabela, coluna.adicionaisBody);

    return subTabela;
  }

  public static getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }
}