import { DatePipe } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { EddyAutoComplete, Favorecido, FavorecidoService, GlobalService, Login, OrgaoAssinaturaService } from "eddydata-lib";
import { ConfirmationService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NotaEmpenhoResto } from "../../relatorio/contabil/nota-empenho-resto";
import { EmpenhoRestoService } from "../service/empenho-resto.service";
import * as toastr from 'toastr'

@Component({
  selector: 'lib-nota-empenho-resto-dlg',
  templateUrl: './nota-empenho-resto-dlg.component.html'
})
export class NotaEmpenhoRestoDlgComponent implements OnInit, OnDestroy {

  protected datepipe: DatePipe;
  public ptBR: any;
  public selectedOrdem: string;
  public selectedOpt: string;
  public selectNaoImpresso: boolean;
  public selectUsuario: boolean;
  public selectAnulado: boolean;
  public selectFornecedor: boolean;
  public marcarComoImpresso: boolean;
  public dataInicio: Date;
  public dataFinal: Date;
  public numeroInicio: number;
  public numeroFinal: number;
  public favorecido: Favorecido;
  protected unsubscribe: Subject<void> = new Subject();

  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;

  public imaskNumero = {
    mask: Number,
    scale: 0,
    signed: false
  };

  @Input() login: Login;
  @Input() empenhosSelecionados: any[];

  constructor(
    protected confirmationService: ConfirmationService,
    protected assinaturaService: OrgaoAssinaturaService,
    private globalService: GlobalService,
    private favorecidoService: FavorecidoService,
    private empenhoRestoService: EmpenhoRestoService
  ) { }

  ngOnInit(): void {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.selectedOrdem = 'ord1';
    this.selectedOpt = 'opt1';
    this.selectNaoImpresso = true;
    this.selectUsuario = true;
    this.dataInicio = new Date();
    this.dataFinal = new Date();

    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['nome'], { 'cidade.id': this.login.cidade.id, relations: 'tipo,contas.banco', orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  imprimir() {
    if (this.selectNaoImpresso) {
      this.confirmationService.confirm({
        message: 'Deseja marcar os empenhos de resto como impressos ?',
        acceptLabel: "Sim",
        rejectLabel: "Não",
        header: 'Confirmação',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.gerarImpressaoEmpenhos();
          this.marcarComoImpresso = true;
        },
        reject: () => {
          this.marcarComoImpresso = false;
          this.gerarImpressaoEmpenhos();
        }
      });
    } else {
      this.marcarComoImpresso = false;
      this.gerarImpressaoEmpenhos();
    }
  }

  async gerarImpressaoEmpenhos() {
    const parametros = {};

    let relations = 'contrato,compra.ficha.aplicacao_variavel,favorecido.contas.banco,convenio,modalidade,licitacao';

    if (this.selectedOpt === 'opt1') {
      if (+this.dataFinal < +this.dataInicio) {
        toastr.warning('Data final do empenho está maior que a inicial')
        return;
      }
      if (!this.dataInicio || !this.dataFinal || +this.dataFinal === 0 || +this.dataInicio === 0) {
        toastr.warning('Informe a data inicial e final para imprimir')
        return;
      }
    } else if (this.selectedOpt === 'opt2') {
      if (+this.numeroFinal < +this.numeroInicio) {
        toastr.warning('Número final do empenho está maior que o inicial')
        return;
      }
      if (!this.numeroInicio || !this.numeroFinal || +this.numeroFinal === 0 || +this.numeroInicio === 0) {
        toastr.warning('Informe o número inicial e final para imprimir')
        return;
      }
    } else {
      if (this.empenhosSelecionados.length === 0 || !this.empenhosSelecionados) {
        toastr.warning('Selecione ao menos um empenho para imprimir')
        return;
      }
    }

    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['orderBy'] = this.selectedOrdem === 'ord1' ? 'numero$ASC' : 'favorecido.nome$ASC';

    if (this.selectedOpt === 'opt1') {
      parametros['data_empenho$ge'] = this.datepipe.transform(this.dataInicio, 'yyyy-MM-dd');
      parametros['data_empenho$le'] = this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd');
    }  else if (this.selectedOpt === 'opt2') {
      parametros['numero$ge'] = +this.numeroInicio;
      parametros['numero$le'] = +this.numeroFinal;
    } else {
      parametros['numero$in'] = this.empenhosSelecionados;
    }
    if (this.selectNaoImpresso) {
      parametros['impresso'] = !this.selectNaoImpresso;
    }
    if (this.selectUsuario) {
      parametros['usuario_cadastro_id'] = this.login.usuario.id;
    }
    if (this.selectFornecedor) {
      parametros['favorecido.nome$like'] = this.favorecido.nome;
    }
    if (this.selectAnulado) {
      parametros['especie$in'] = 'RNI,RNP,CRP,RNL,RPI';
    } else {
      parametros['especie'] = 'EMR';
    }

    this.empenhoRestoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        if (lista.content.length === 0) {
          toastr.warning('Nenhum empenho resto encontrado!')
          return;
        }
        new NotaEmpenhoResto(this.assinaturaService).imprimir(lista.content, this.login);
        if (this.marcarComoImpresso) {
          this.empenhoRestoService.marcarComoImpresso(parametros).subscribe(() => { });
        }
      },
        (error) => toastr.error(`${error.error && error.error.payload ? error.error.payload : error}`)
      );
  }
}