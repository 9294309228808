import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import {
  FuncaoService, GlobalService, Exercicio
} from 'eddydata-lib';

@Directive()
export class Anexo1Receita implements OnDestroy {

  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number,
    protected exercicio: Exercicio) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(dados: any[], despesas: []): {}[] {
    // monta o cabecalho
    const registros: {}[] = [
      [
        { text: 'RELATÓRIO RESUMIDO DA EXECUÇÃO ORÇAMENTÁRIA', bold: true, border: [false, false, false, false], colSpan: 8 },
        '', '', '', '', '', '', ''],
      [
        { text: 'BALANÇO ORÇAMENTÁRIO', bold: true, border: [false, false, false, false], colSpan: 8 },
        '', '', '', '', '', '', ''],
      [
        { text: 'ORÇAMENTOS FISCAL E DA SEGURIDADE SOCIAL', bold: true, border: [false, false, false, false], colSpan: 8 },
        '', '', '', '', '', '', ''],
      [
        { text: `REFERÊNCIA: ${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano}`, bold: true, border: [false, false, false, false], colSpan: 8 },
        '', '', '', '', '', '', ''],
      [
        { text: 'LRF, art. 52,  alineas "a" e "b" do inciso I e  II , alíneas "a" e "b" do inciso II', bold: true, border: [false, false, false, false], colSpan: 8 },
        '', '', '', '', '', '', ''
      ],
      [
        { text: 'RECEITAS', alignment: 'center', bold: true, fontSize: 6, rowSpan: 2 },
        { text: 'PREVISÃO INICIAL', alignment: 'center', bold: true, fontSize: 6, rowSpan: 2 },
        { text: 'PREVISÃO ATUALIZADA (a)', alignment: 'center', bold: true, fontSize: 6, rowSpan: 2 },
        { text: 'RECEITAS REALIZADAS', alignment: 'center', bold: true, fontSize: 6, colSpan: 4 },
        '', '', '',
        { text: 'SALDO (a-c)', alignment: 'center', bold: true, fontSize: 6, rowSpan: 2 }
      ],
      [
        '', '', '',
        { text: 'NO BIMESTRE (b)', alignment: 'center', bold: true, fontSize: 6 },
        { text: '% (b/a)', alignment: 'center', bold: true, fontSize: 6 },
        { text: 'ATÉ O BIMESTRE (c)', alignment: 'center', bold: true, fontSize: 6 },
        { text: '% (c/a)', alignment: 'center', bold: true, fontSize: 6 },
        ''
      ],
    ];
    // monta o agrupamento do relatório
    const titulos = this.funcaoService.agrupar(dados[0], 'titulo', ['previsto', 'atualizado', 'arrecadado']);
    let subgrupos: any;
    let total_receita_intra1 = 0;
    let total_receita_intra2 = 0;
    let total_receita_intra3 = 0;
    let total_receita_intra4 = 0;
    for (const titulo of titulos) {
      registros.push([
        { text: titulo.grupo, fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['previsto']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['previsto'] + +titulo.totalizadores['atualizado']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['bimestre']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber((+titulo.totalizadores['bimestre'] / (+titulo.totalizadores['previsto'] + +titulo.totalizadores['atualizado'])) * 100), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['arrecadado']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber((+titulo.totalizadores['arrecadado'] / (+titulo.totalizadores['previsto'] + +titulo.totalizadores['atualizado'])) * 100), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber((+titulo.totalizadores['previsto'] + +titulo.totalizadores['atualizado']) - +titulo.totalizadores['arrecadado']), alignment: 'right', fontSize: 6, bold: true }
      ]);
      total_receita_intra1 += +titulo.totalizadores['previsto'];
      total_receita_intra2 += +titulo.totalizadores['previsto'] + +titulo.totalizadores['atualizado'];
      total_receita_intra3 += +titulo.totalizadores['bimestre'];
      total_receita_intra4 += +titulo.totalizadores['arrecadado'];

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['previsto', 'atualizado', 'arrecadado']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          registros.push([
            { text: grupo.grupo, fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsto']), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['previsto'] + +grupo.totalizadores['atualizado']), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['bimestre']), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber((+grupo.totalizadores['bimestre'] / (+grupo.totalizadores['previsto'] + +grupo.totalizadores['atualizado'])) * 100), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber((+grupo.totalizadores['arrecadado'] / (+grupo.totalizadores['previsto'] + +grupo.totalizadores['atualizado'])) * 100), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber((+grupo.totalizadores['previsto'] + +grupo.totalizadores['atualizado'])), alignment: 'right', fontSize: 6, bold: true },
          ]);

          subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['previsto', 'atualizado', 'arrecadado']);
          for (const subgrupo of subgrupos) {
            if (subgrupo.grupo != '') {
              registros.push([
                { text: subgrupo.grupo, fontSize: 6, margin: [3, 0, 0, 0] },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['previsto']), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(+subgrupo.totalizadores['previsto'] + +subgrupo.totalizadores['atualizado']), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['bimestre']), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber((+subgrupo.totalizadores['bimestre'] / (+subgrupo.totalizadores['previsto'] + +subgrupo.totalizadores['atualizado'])) * 100), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['arrecadado']), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber((+subgrupo.totalizadores['arrecadado'] / (+subgrupo.totalizadores['previsto'] + +subgrupo.totalizadores['atualizado'])) * 100), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber((+subgrupo.totalizadores['previsto'] + +subgrupo.totalizadores['atualizado']) - +subgrupo.totalizadores['arrecadado']), alignment: 'right', fontSize: 6 },
              ]);

              for (const item of subgrupo.registros) {
                if (item.nome != '') {
                  registros.push([
                    {
                      text: item.nome, fontSize: 6, margin: [6, 0, 0, 0]
                    },
                    { text: this.funcaoService.convertToBrNumber(item.previsto), alignment: 'right', fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber(+item.previsto + +item.atualizado), alignment: 'right', fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber(item.bimestre), alignment: 'right', fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber((+item.bimestre / (+item.previsto + +item.atualizado)) * 100), alignment: 'right', fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber(item.arrecadado), alignment: 'right', fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber((+item.arrecadado / (+item.previsto + +item.atualizado)) * 100), alignment: 'right', fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber((+item.previsto + +item.atualizado) - +item.arrecadado), alignment: 'right', fontSize: 6 }
                  ]);
                }
              }
            }
          }
        }
      }
    }
    /*registros.push([
      {
        text: 'RECEITAS (INTRA-ORÇAMENTÁRIAS) (II)', fontSize: 6, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 6, bold: true }
    ]);*/
    registros.push([
      {
        text: 'SUBTOTAL DAS RECEITAS (III) = (I + II)', fontSize: 6, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(total_receita_intra1), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total_receita_intra2), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(total_receita_intra3), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total_receita_intra3 / +total_receita_intra2), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(total_receita_intra4), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total_receita_intra4 / +total_receita_intra2), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total_receita_intra2 - +total_receita_intra4), alignment: 'right', fontSize: 6, bold: true }
    ]);

    const totalV = this.montarOperacoes(dados[1], registros, total_receita_intra1, total_receita_intra2, total_receita_intra3, total_receita_intra4);

    this.calcularDeficit(despesas, registros, totalV);

    this.montarSaldoAnterior(dados[2], registros);

    return registros;

  }

  private calcularDeficit(dados: any[], registros: {}[], totalV: any) {
    let totalLiquidado = 0;
    let totalEmpenhado = 0;
    let totalAtualizado = 0;

    const titulos = this.funcaoService.agrupar(dados[0], 'titulo',
      ['dotacao_inicial', 'dotacao_atualizado', 'empenhado_bim', 'empenhado', 'liquidado_bim', 'liquidado', 'resto']);
    for (const titulo of titulos) {
      totalAtualizado += +titulo.totalizadores['dotacao_atualizado'];
      totalEmpenhado += +titulo.totalizadores['empenhado'];
      totalLiquidado += +titulo.totalizadores['liquidado'];
    }
    const totalDespesa = (this.mes === 12) ? totalEmpenhado : totalLiquidado;
    const deficit = +totalV.arrecadado - +totalDespesa;
    const deficitAtualizado = (+totalV.previsto + +totalV.atualizado) - +totalAtualizado;
    registros.push([
      { text: 'DÉFICIT (VI)', fontSize: 6, bold: true, colSpan: 2 }, '',
      { text: +deficitAtualizado < 0 ? this.funcaoService.convertToBrNumber(deficitAtualizado) : '0,00', alignment: 'right', fontSize: 6, bold: true },
      { text: '', colSpan: 2 }, '',
      { text: +deficit < 0 ? this.funcaoService.convertToBrNumber(deficit) : '0,00', alignment: 'right', fontSize: 6, bold: true },
      { text: '', colSpan: 2 }, ''
    ]);
    registros.push([
      { text: 'TOTAL COM DÉFICIT (VII) = (V + VI)', fontSize: 6, bold: true, colSpan: 2 }, '',
      { text: this.funcaoService.convertToBrNumber(+totalV.atualizado + (+deficitAtualizado < 0 ? +deficitAtualizado : 0)), alignment: 'right', fontSize: 6, bold: true },
      { text: '', colSpan: 2 }, '',
      { text: this.funcaoService.convertToBrNumber(+totalV.arrecadado + (+deficit < 0 ? +deficit : 0)), alignment: 'right', fontSize: 6, bold: true },
      { text: '', colSpan: 2 }, ''
    ])
  }

  private montarOperacoes(dados: any[], registros: {}[], total1: number, total2: number, total3: number, total4: number) {
    let total_operacao1 = 0;
    let total_operacao2 = 0;
    let total_operacao3 = 0;
    let total_operacao4 = 0;
    const titulos = this.funcaoService.agrupar(dados, 'titulo', ['previsto', 'atualizado', 'arrecadado']);
    for (const titulo of titulos) {
      registros.push([
        { text: titulo.grupo, fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['previsto']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['previsto'] + +titulo.totalizadores['atualizado']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['bimestre']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['bimestre'] / +titulo.totalizadores['atualizado']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['arrecadado']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['arrecadado'] / +titulo.totalizadores['atualizado']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['arrecadado'] - +titulo.totalizadores['atualizado']), alignment: 'right', fontSize: 6, bold: true }
      ]);

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['previsto', 'atualizado', 'arrecadado']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          registros.push([
            { text: grupo.grupo, fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsto']), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['previsto'] + +grupo.totalizadores['atualizado']), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['bimestre']), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['bimestre'] / +grupo.totalizadores['atualizado']), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['arrecadado'] / +grupo.totalizadores['atualizado']), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['arrecadado'] - +grupo.totalizadores['atualizado']), alignment: 'right', fontSize: 6, bold: true },
          ]);

          for (const item of grupo.registros) {
            if (item.subgrupo != '') {
              registros.push([
                {
                  text: item.subgrupo, fontSize: 6, margin: [6, 0, 0, 0]
                },
                { text: this.funcaoService.convertToBrNumber(item.previsto), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(+item.previsto + +item.atualizado), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(item.bimestre), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(+item.bimestre / +item.atualizado), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(item.arrecadado), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(+item.arrecadado / +item.atualizado), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(+item.arrecadado - +item.atualizado), alignment: 'right', fontSize: 6 }
              ]);
            }
          }

        }
      }

      registros.push([
        {
          text: 'TOTAL DAS RECEITAS (V) = (III + IV)', fontSize: 6, bold: true
        },
        { text: this.funcaoService.convertToBrNumber(+total1 + +total_operacao1), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(+total2 + +total_operacao2), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(+total3 + +total_operacao3), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber((+total3 + +total_operacao3) / (+total2 + +total_operacao2)), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(+total4 + +total_operacao4), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber((+total4 + +total_operacao4) / (+total2 + +total_operacao2)), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber((+total2 + +total_operacao2) - (+total4 + +total_operacao4)), alignment: 'right', fontSize: 6, bold: true }
      ]);

    }

    return {
      previsao: +total1 + +total_operacao1,
      atualizado: +total2 + +total_operacao2,
      bimestre: +total3 + +total_operacao3,
      arrecadado: +total4 + +total_operacao4,
    }
  }

  private montarSaldoAnterior(dados: any[], registros: {}[]) {
    const titulos = this.funcaoService.agrupar(dados, 'titulo', ['previsto', 'atualizado', 'arrecadado']);
    for (const titulo of titulos) {
      registros.push([
        { text: titulo.grupo, fontSize: 6, bold: true, colSpan: 2 },
        { text: '' },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['previsto'] + +titulo.totalizadores['atualizado']), alignment: 'right', fontSize: 6, bold: true },
        { text: '', colSpan: 2 },
        { text: '' },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['arrecadado']), alignment: 'right', fontSize: 6, bold: true },
        { text: '', colSpan: 2 },
        { text: '' }
      ]);

      for (const item of titulo.registros) {
        if (item.grupo != '') {
          registros.push([
            {
              text: item.grupo, fontSize: 6, margin: [6, 0, 0, 0], colSpan: 2
            },
            { text: '' },
            { text: this.funcaoService.convertToBrNumber(+item.previsto + +item.atualizado), alignment: 'right', fontSize: 6 },
            { text: '', colSpan: 2 },
            { text: '' },
            { text: this.funcaoService.convertToBrNumber(item.arrecadado), alignment: 'right', fontSize: 6 },
            { text: '', colSpan: 2 },
            { text: '' }
          ]);
        }
      }
    }
  }

  public montarQuadroCsv(dados: any[], despesas: [], listaExportar: any) {

    const linha_titulo = {
      titulo: '',
      dotacao_inicial: '',
      dotacao_atualizado: '',
      empenhado_bim: 'NO BIMESTRE (b)',
      empenhado: '% (b/a)',
      liquidado_bim: 'ATÉ O BIMESTRE (c)',
      liquidado: '% (c/a)',
      total1: '',
      pago: '',
      resto: ''
    }
    listaExportar.push(linha_titulo)

    const titulos = this.funcaoService.agrupar(dados[0], 'titulo', ['previsto', 'atualizado', 'arrecadado']);
    let subgrupos: any;
    let total_receita_intra1 = 0;
    let total_receita_intra2 = 0;
    let total_receita_intra3 = 0;
    let total_receita_intra4 = 0;
    for (const titulo of titulos) {
      const linha_titulo = {
        titulo: titulo.grupo,
        dotacao_inicial: this.funcaoService.convertToBrNumber(titulo.totalizadores['previsto']),
        dotacao_atualizado: this.funcaoService.convertToBrNumber(+titulo.totalizadores['previsto'] + +titulo.totalizadores['atualizado']),
        empenhado_bim: this.funcaoService.convertToBrNumber(titulo.totalizadores['bimestre']),
        empenhado: this.funcaoService.convertToBrNumber(+titulo.totalizadores['bimestre'] / +titulo.totalizadores['atualizado']),
        liquidado_bim: this.funcaoService.convertToBrNumber(titulo.totalizadores['arrecadado']),
        liquidado: this.funcaoService.convertToBrNumber(+titulo.totalizadores['arrecadado'] / +titulo.totalizadores['atualizado']),
        total1: this.funcaoService.convertToBrNumber(+titulo.totalizadores['arrecadado'] - +titulo.totalizadores['atualizado']),
        pago: '',
        resto: ''
      }
      listaExportar.push(linha_titulo)

      total_receita_intra1 += +titulo.totalizadores['previsto'];
      total_receita_intra2 += +titulo.totalizadores['previsto'] + +titulo.totalizadores['atualizado'];
      total_receita_intra3 += +titulo.totalizadores['bimestre'];
      total_receita_intra4 += +titulo.totalizadores['arrecadado'];

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['previsto', 'atualizado', 'arrecadado']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          const linha_grupo = {
            titulo: grupo.grupo,
            dotacao_inicial: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsto']),
            dotacao_atualizado: this.funcaoService.convertToBrNumber(+ grupo.totalizadores['previsto'] + +grupo.totalizadores['atualizado']),
            empenhado_bim: this.funcaoService.convertToBrNumber(grupo.totalizadores['bimestre']),
            empenhado: this.funcaoService.convertToBrNumber(+grupo.totalizadores['bimestre'] / +grupo.totalizadores['atualizado']),
            liquidado_bim: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']),
            liquidado: this.funcaoService.convertToBrNumber(+grupo.totalizadores['arrecadado'] / +grupo.totalizadores['atualizado']),
            total1: this.funcaoService.convertToBrNumber(+grupo.totalizadores['arrecadado'] - +grupo.totalizadores['atualizado']),
            pago: '',
            resto: ''
          }
          listaExportar.push(linha_grupo)
        }

        subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['previsto', 'atualizado', 'arrecadado']);
        for (const subgrupo of subgrupos) {
          if (subgrupo.grupo != '') {
            const linha_subgrupo = {
              titulo: subgrupo.grupo,
              dotacao_inicial: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['previsto']),
              dotacao_atualizado: this.funcaoService.convertToBrNumber(+ subgrupo.totalizadores['previsto'] + +subgrupo.totalizadores['atualizado']),
              empenhado_bim: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['bimestre']),
              empenhado: this.funcaoService.convertToBrNumber(+subgrupo.totalizadores['bimestre'] / +subgrupo.totalizadores['atualizado']),
              liquidado_bim: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['arrecadado']),
              liquidado: this.funcaoService.convertToBrNumber(+subgrupo.totalizadores['arrecadado'] / +subgrupo.totalizadores['atualizado']),
              total1: this.funcaoService.convertToBrNumber(+subgrupo.totalizadores['arrecadado'] - +subgrupo.totalizadores['atualizado']),
              pago: '',
              resto: ''
            }
            listaExportar.push(linha_subgrupo)
          }

          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              const linha_subgrupo = {
                titulo: item.nome,
                dotacao_inicial: this.funcaoService.convertToBrNumber(item.previsto),
                dotacao_atualizado: this.funcaoService.convertToBrNumber(+ item.previsto + +item.atualizado),
                empenhado_bim: this.funcaoService.convertToBrNumber(item.bimestre),
                empenhado: this.funcaoService.convertToBrNumber(+item.bimestre / +item.atualizado),
                liquidado_bim: this.funcaoService.convertToBrNumber(item.arrecadado),
                liquidado: this.funcaoService.convertToBrNumber(+item.arrecadado / +item.atualizado),
                total1: this.funcaoService.convertToBrNumber(+item.arrecadado - +item.atualizado),
                pago: '',
                resto: ''
              }
              listaExportar.push(linha_subgrupo)
            }
          }
        }
      }

      const linha_intra = {
        titulo: 'RECEITAS (INTRA-ORÇAMENTÁRIAS) (II)',
        dotacao_inicial: this.funcaoService.convertToBrNumber(0),
        dotacao_atualizado: this.funcaoService.convertToBrNumber(0),
        empenhado_bim: this.funcaoService.convertToBrNumber(0),
        empenhado: this.funcaoService.convertToBrNumber(0),
        liquidado_bim: this.funcaoService.convertToBrNumber(0),
        liquidado: this.funcaoService.convertToBrNumber(0),
        total1: this.funcaoService.convertToBrNumber(0),
        pago: '',
        resto: ''
      }
      listaExportar.push(linha_intra)

      const linha_subtotal = {
        titulo: 'SUBTOTAL DAS RECEITAS (III) = (I + II)',
        dotacao_inicial: this.funcaoService.convertToBrNumber(total_receita_intra1),
        dotacao_atualizado: this.funcaoService.convertToBrNumber(+total_receita_intra2),
        empenhado_bim: this.funcaoService.convertToBrNumber(total_receita_intra3),
        empenhado: this.funcaoService.convertToBrNumber(+total_receita_intra3 / +total_receita_intra2),
        liquidado_bim: this.funcaoService.convertToBrNumber(total_receita_intra4),
        liquidado: this.funcaoService.convertToBrNumber(+total_receita_intra4 / +total_receita_intra2),
        total1: this.funcaoService.convertToBrNumber(+total_receita_intra2 - +total_receita_intra4),
        pago: '',
        resto: ''
      }
      listaExportar.push(linha_subtotal)
    }

    const totalV = this.montarOperacoesCsv(dados[1], listaExportar, total_receita_intra1, total_receita_intra2, total_receita_intra3, total_receita_intra4);

    this.calcularDeficitCsv(despesas, listaExportar, totalV);

    this.montarSaldoAnteriorCsv(dados[2], listaExportar);

  }

  public montarOperacoesCsv(dados: any[], listaExportar: any, total1: number, total2: number, total3: number, total4: number) {
    let total_operacao1 = 0;
    let total_operacao2 = 0;
    let total_operacao3 = 0;
    let total_operacao4 = 0;
    const titulos = this.funcaoService.agrupar(dados, 'titulo', ['previsto', 'atualizado', 'arrecadado']);
    for (const titulo of titulos) {
      const linha_titulo = {
        titulo: titulo.grupo,
        dotacao_inicial: this.funcaoService.convertToBrNumber(titulo.totalizadores['previsto']),
        dotacao_atualizado: this.funcaoService.convertToBrNumber(+titulo.totalizadores['previsto'] + +titulo.totalizadores['atualizado']),
        empenhado_bim: this.funcaoService.convertToBrNumber(titulo.totalizadores['bimestre']),
        empenhado: this.funcaoService.convertToBrNumber(+titulo.totalizadores['bimestre'] / +titulo.totalizadores['atualizado']),
        liquidado_bim: this.funcaoService.convertToBrNumber(titulo.totalizadores['arrecadado']),
        liquidado: this.funcaoService.convertToBrNumber(+titulo.totalizadores['arrecadado'] / +titulo.totalizadores['atualizado']),
        total1: this.funcaoService.convertToBrNumber(+titulo.totalizadores['arrecadado'] - +titulo.totalizadores['atualizado']),
        pago: '',
        resto: ''
      }
      listaExportar.push(linha_titulo)

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['previsto', 'atualizado', 'arrecadado']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          const linha_grupo = {
            titulo: grupo.grupo,
            dotacao_inicial: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsto']),
            dotacao_atualizado: this.funcaoService.convertToBrNumber(+grupo.totalizadores['previsto'] + +grupo.totalizadores['atualizado']),
            empenhado_bim: this.funcaoService.convertToBrNumber(grupo.totalizadores['bimestre']),
            empenhado: this.funcaoService.convertToBrNumber(+grupo.totalizadores['bimestre'] / +grupo.totalizadores['atualizado']),
            liquidado_bim: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']),
            liquidado: this.funcaoService.convertToBrNumber(+grupo.totalizadores['arrecadado'] / +grupo.totalizadores['atualizado']),
            total1: this.funcaoService.convertToBrNumber(+grupo.totalizadores['arrecadado'] - +grupo.totalizadores['atualizado']),
            pago: '',
            resto: ''
          }
          listaExportar.push(linha_grupo)
        }

        for (const item of grupo.registros) {
          if (item.subgrupo != '') {
            const linha_item = {
              titulo: item.subgrupo,
              dotacao_inicial: this.funcaoService.convertToBrNumber(item.previsto),
              dotacao_atualizado: this.funcaoService.convertToBrNumber(+item.previsto + +item.atualizado),
              empenhado_bim: this.funcaoService.convertToBrNumber(item.bimestre),
              empenhado: this.funcaoService.convertToBrNumber(+item.bimestre / +item.atualizado),
              liquidado_bim: this.funcaoService.convertToBrNumber(item.arrecadado),
              liquidado: this.funcaoService.convertToBrNumber(+item.arrecadado / +item.atualizado),
              total1: this.funcaoService.convertToBrNumber(+item.arrecadado - +item.atualizado),
              pago: '',
              resto: ''
            }
            listaExportar.push(linha_item)
          }
        }
      }
    }

    const linha_item = {
      titulo: 'TOTAL DAS RECEITAS (V) = (III + IV)',
      dotacao_inicial: this.funcaoService.convertToBrNumber(+total1 + +total_operacao1),
      dotacao_atualizado: this.funcaoService.convertToBrNumber(+total2 + +total_operacao2),
      empenhado_bim: this.funcaoService.convertToBrNumber(+total3 + +total_operacao3),
      empenhado: this.funcaoService.convertToBrNumber((+total3 + +total_operacao3) / (+total2 + +total_operacao2)),
      liquidado_bim: this.funcaoService.convertToBrNumber(+total4 + +total_operacao4),
      liquidado: this.funcaoService.convertToBrNumber((+total4 + +total_operacao4) / (+total2 + +total_operacao2)),
      total1: this.funcaoService.convertToBrNumber((+total2 + +total_operacao2) - (+total4 + +total_operacao4)),
      pago: '',
      resto: ''
    }
    listaExportar.push(linha_item)

    return {
      previsao: +total1 + +total_operacao1,
      atualizado: +total2 + +total_operacao2,
      bimestre: +total3 + +total_operacao3,
      arrecadado: +total4 + +total_operacao4,
    }
  }

  private calcularDeficitCsv(dados: any[], listaExportar: any, totalV: any) {
    let totalLiquidado = 0;
    let totalEmpenhado = 0;
    let totalAtualizado = 0;

    const titulos = this.funcaoService.agrupar(dados[0], 'titulo',
      ['dotacao_inicial', 'dotacao_atualizado', 'empenhado_bim', 'empenhado', 'liquidado_bim', 'liquidado', 'resto']);
    for (const titulo of titulos) {
      totalAtualizado += +titulo.totalizadores['dotacao_atualizado'];
      totalEmpenhado += +titulo.totalizadores['empenhado'];
      totalLiquidado += +titulo.totalizadores['liquidado'];
    }

    const totalDespesa = (this.mes === 12) ? totalEmpenhado : totalLiquidado;
    const deficit = +totalV.arrecadado - +totalDespesa;
    const deficitAtualizado = (+totalV.previsto + +totalV.atualizado) - +totalAtualizado;

    const linha_deficit = {
      titulo: 'DÉFICIT (VI)',
      dotacao_inicial: '',
      dotacao_atualizado: +deficitAtualizado < 0 ? this.funcaoService.convertToBrNumber(deficitAtualizado) : '0,00',
      empenhado_bim: '',
      empenhado: '',
      liquidado_bim: +deficit < 0 ? this.funcaoService.convertToBrNumber(deficit) : '0,00',
      liquidado: '',
      total1: '',
      pago: '',
      resto: ''
    }
    listaExportar.push(linha_deficit)

    const linha_titul = {
      titulo: 'TOTAL COM DÉFICIT (VII) = (V + VI)',
      dotacao_inicial: '',
      dotacao_atualizado: this.funcaoService.convertToBrNumber(+totalV.atualizado + (+deficitAtualizado < 0 ? +deficitAtualizado : 0)),
      empenhado_bim: '',
      empenhado: '',
      liquidado_bim: this.funcaoService.convertToBrNumber(+totalV.arrecadado + (+deficit < 0 ? +deficit : 0)),
      liquidado: '',
      total1: '',
      pago: '',
      resto: ''
    }
    listaExportar.push(linha_titul)
  }

  private montarSaldoAnteriorCsv(dados: any[], listaExportar: any) {
    const titulos = this.funcaoService.agrupar(dados, 'titulo', ['previsto', 'atualizado', 'arrecadado']);
    for (const titulo of titulos) {
      const linha_titulo_saldo = {
        titulo: titulo.grupo,
        dotacao_inicial: '',
        dotacao_atualizado: this.funcaoService.convertToBrNumber(+titulo.totalizadores['previsto'] + +titulo.totalizadores['atualizado']),
        empenhado_bim: '',
        empenhado: '',
        liquidado_bim: this.funcaoService.convertToBrNumber(titulo.totalizadores['arrecadado']),
        liquidado: '',
        total1: '',
        pago: '',
        resto: ''
      }
      listaExportar.push(linha_titulo_saldo)

      for (const item of titulo.registros) {
        if (item.grupo != '') {
          const linha_titulo_saldo = {
            titulo: item.grupo,
            dotacao_inicial: '',
            dotacao_atualizado: this.funcaoService.convertToBrNumber(+item.previsto + +item.atualizado),
            empenhado_bim: '',
            empenhado: '',
            liquidado_bim: this.funcaoService.convertToBrNumber(item.arrecadado),
            liquidado: '',
            total1: '',
            pago: '',
            resto: ''
          }
          listaExportar.push(linha_titulo_saldo)
        }
      }
    }
  }
}
