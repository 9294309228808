import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Contrato, Licitacao, Page, Proponente, Proposta } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RPContratoService extends BaseResourceService<Contrato> {

  constructor(
    protected injector: Injector
  ) {
    super(`contratos`, injector);
  }

  public obterPorNumero(numero: number, orgaoId: number): Observable<Contrato> {
    return this.http.get<Contrato>(
      `${this.login.cidade.id}/${this.api}/filtrar?relations=${encodeURIComponent('favorecido,favorecido.tipo')}&numero$like=${numero}%&orgao_id=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterSaldo(orgao: number, id: number, desconsiderar?: {
    rcmsId?: number, compraId?: number
  }, saldoQuantidade?: boolean): Observable<{ saldo: number }> {
    if (saldoQuantidade) {
      return this.obterSaldoQtd(orgao, id, desconsiderar)
    } else {
      let params = this.converterParametrosStr(desconsiderar);
      return this.http.get<{ saldo: number }>(
        `${this.login.cidade.id}/${this.api}/saldo/${orgao}/${id}${params}`, this.httpOptions()).pipe(
          map(res => res),
          catchError(err => this.handleError(err))
        );
    }
  }

  public obterSaldoQtd(orgao: number, id: number, desconsiderar?: {
    rcmsId?: number, compraId?: number
  }): Observable<{ saldo: number }> {
    let params = this.converterParametrosStr(desconsiderar);
    return this.http.get<{ saldo: number }>(
      `${this.login.cidade.id}/${this.api}/saldo-qtd/${orgao}/${id}${params}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public proximoNumero(ano: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-numero/${ano}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorLicitacao(id: number, orgaoId: number): Observable<Page> {
    let uri = `relations=${encodeURIComponent('favorecido,favorecido.tipo,itens.produto_unidade.produto,itens.memorial')}&licitacao.id=${id}&orgao_id=${orgaoId}`;
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?${uri}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorLicitacaoParaExportacaoRCMS(id: number, orgaoId: number): Observable<Page> {
    let relations = 'prazo,favorecido,'
    // itens
    relations += 'itens.memorial.produto_unidade.produto,itens.memorial.produto_unidade.unidade,'
    // rpl
    relations += 'itens.memorial.rpl_itens.rpl.requerente,itens.memorial.rpl_itens.rpl.setor.estoques,itens.memorial.rpl_itens.rpl.exercicio,'
    // cotacoes
    relations += 'itens.memorial.rpl_itens.cotacoes.favorecido'

    let uri = `relations=${relations}&licitacao_id=${id}&orgao_id=${orgaoId}`;
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?${uri}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterContratosPendentesLicitacao(entidade: Licitacao): Proponente[] {
    const listaPendentes = [];

    if (entidade?.proponentes?.length > 0) {
      for (const proponente of entidade.proponentes) {
        if (!entidade.contratos?.find(c => c.favorecido.id === proponente.favorecido.id)) {
          const vencedoras: Proposta[] = Object.assign([], proponente.propostas.filter(p => p.situacao === 'VENCEDOR'));
          if (vencedoras?.length > 0) {
            const pendente = Object.assign({}, proponente);
            pendente.propostas = vencedoras;
            pendente.propostas.forEach(p => p.memorial.produto_unidade = entidade.itens.find(m => m.id === p.memorial.id)?.produto_unidade)
            pendente.licitacao = Object.assign({}, entidade);
            delete pendente.licitacao.proponentes;
            delete pendente.licitacao.itens;
            listaPendentes.push(pendente);
          }
        }
      }
    }

    return listaPendentes;
  }

  public contratosVigentes(registroPreco: boolean, data1: string, data2: string, iniciadosPeriodo: boolean, desconsiderarAditivos: boolean): Observable<{}[]> {
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/tce-vigentes/${registroPreco ? 'S' : 'N'}/${data1}/${data2}/${iniciadosPeriodo ? 'S' : 'N'}/${desconsiderarAditivos ? 'S' : 'N'}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public relatorioExtrato(orgao: number, data1: string, data2: string, contrato: number, tipo: '*' | 'CONTRATO' | 'REGISTRO_PRECO'): Observable<{}[]> {
    if (!contrato) contrato = 0
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/relatorio-extrato/${orgao}/${data1}/${data2}/${contrato}/${tipo}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public relatorioRecurso(orgao: number, data1: string, data2: string, contrato: number, tipo: '*' | 'CONTRATO' | 'REGISTRO_PRECO'): Observable<{}[]> {
    if (!contrato) contrato = 0
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/relatorio-recurso/${orgao}/${data1}/${data2}/${contrato}/${tipo}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
