import { Directive, Injector, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { QuadroEnsinoService } from '../service/quadro-ensino.service';
import {
    FuncaoService, GlobalService, Login,
    Exercicio, FuncaoGoverno, Relatorio, FormatoExportacao, Coluna
} from 'eddydata-lib';
import { Assinaturas } from 'administrativo-lib';

@Directive()
export class Quadro8Ensino implements OnDestroy {
    protected funcaoService: FuncaoService;
    protected globalService: GlobalService;
    private login: Login = new Login();
    protected unsubscribe: Subject<void> = new Subject();

    constructor(
        protected quadroServico: QuadroEnsinoService,
        protected mes: number,
        protected exercicio: Exercicio,
        protected dataInicial: Date,
        protected dataFinal: Date,
        protected funcao?: FuncaoGoverno,
        protected injector?: Injector,
        protected assinatura_ensino?: boolean) {
        this.globalService = new GlobalService();
        this.funcaoService = new FuncaoService();
        this.login = GlobalService.obterSessaoLogin();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public async listar(orgaos: number[]) {
        return await this.quadroServico
            .obterQuadro3(this.mes, this.exercicio.id, orgaos, this.dataInicial, this.dataFinal).toPromise();
    }

    public montarRelatorio(orgaos: number[], formato?: FormatoExportacao) {
        if (!formato) formato = 'pdf';
        this.quadroServico.obterQuadro8(this.mes, this.exercicio.id, orgaos, this.dataInicial, this.dataFinal)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(async dados => {
                if (formato === 'pdf') {
                    Relatorio.imprimirPersonalizado(
                        'QUADRO 8 - CÁLCULO ESTIMADO DO REPASSE DECENDIAL NO TRIMESTRE - ENSINO'
                        , this.login.usuario.nome, this.login.usuario.sobrenome,
                        this.login.orgao.nome, this.login.brasao,
                        await this.conteudo(dados),
                        'portrait', 'QUADRO 8 - CÁLCULO ESTIMADO DO REPASSE DECENDIAL NO TRIMESTRE - ENSINO',
                        {
                            linhas: {
                                hLineWidth() {
                                    return 1;
                                },
                                vLineWidth() {
                                    return 1;
                                },
                                hLineColor() {
                                    return 'black';
                                },
                                paddingLeft() {
                                    return 3;
                                },
                                paddingRight() {
                                    return 3;
                                }
                            }
                        });
                } else {
                    this.funcaoService.exportar(formato, this.conteudoExportar(dados), 'QUADRO 8 - CÁLCULO ESTIMADO DO REPASSE DECENDIAL NO TRIMESTRE - ENSINO', this.colunasRelatorio())
                }
            });
    }

    private async conteudo(dados: any[]): Promise<{}[]> {
        let referencia = '';
        if (this.mes) {
            referencia = `${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase()}/${this.exercicio.ano}`;
        } else if (this.dataInicial && this.dataFinal) {
            let dt = this.dataInicial.toString().split("-");
            referencia = `${dt[2]}/${dt[1]}/${dt[0]}`;
            dt = this.dataFinal.toString().split("-");
            referencia += ` à ${dt[2]}/${dt[1]}/${dt[0]}`;
        } else {
          referencia += this.exercicio.ano;
        }

        const arrecadada = parseFloat(dados[0]?.valor)?.toFixed(2);
        const repasse = parseFloat(dados[1]?.valor)?.toFixed(2);

        const percentualRepasse = (+repasse / +arrecadada) * 100;

        const resultadoRepasse = +percentualRepasse.toFixed(2) >= 25 ? 'ATENDEU' : 'NÃO ATENDEU'
        // monta o cabecalho
        const registros: {}[] = [
            [{
                text: `REFERÊNCIA: ${referencia}`,
                alignment: 'center',
                fontSize: 11,
                margin: [5, 5, 5, 5],
                bold: true,
                colSpan: 2
            }, ''],
            [{
                text: 'Valor da Receita Arrecadada',
                alignment: 'center', margin: [2, 2, 2, 2],
                bold: true, fontSize: 10
            },
            {
                text: `${this.funcaoService.convertToBrNumber(arrecadada)}`,
                alignment: 'center', margin: [2, 2, 2, 2],
                bold: true, fontSize: 10
            }],
            [{
                text: 'VALOR DOS REPASSES',
                alignment: 'center', margin: [2, 2, 2, 2],
                bold: true, fontSize: 10
            },
            {
                text: `${this.funcaoService.convertToBrNumber(repasse)}`,
                alignment: 'center', margin: [2, 2, 2, 2],
                bold: true, fontSize: 10
            }],
            [{
                text: 'PERCENTUAL DE REPASSE',
                alignment: 'center', margin: [2, 2, 2, 2],
                bold: true, fontSize: 10
            },
            {
                text: `${this.funcaoService.convertToBrNumber(percentualRepasse)}`,
                alignment: 'center', margin: [2, 2, 2, 2],
                bold: true, fontSize: 10
            }],
            [{
                text: 'Resultados',
                alignment: 'center', margin: [2, 2, 2, 2],
                bold: true, fontSize: 10
            },
            {
                text: `${resultadoRepasse}`,
                alignment: 'center', margin: [2, 2, 2, 2],
                bold: true, fontSize: 10
            }]
        ];

        let assinaturas
        if (this.assinatura_ensino) {
            const ass = new Assinaturas(this.login.orgao, this.injector);
            assinaturas = await ass.dadosAssinatura(50, false, null, false, this.assinatura_ensino);
        }


        return this.assinatura_ensino ? [
            {
                layout: 'linhas',
                table: {
                    dontBreakRows: true,
                    headerRows: 0,
                    widths: ['*', 55, 55, 55],
                    body: registros
                }
            },
            {
                layout: 'linhas',
                table: {
                    dontBreakRows: true,
                    headerRows: 0,
                    widths: ['*', '*', '*'],
                    body: assinaturas
                }
            }
        ] : [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                headerRows: 0,
                widths: ['*', '*'],
                body: registros
            }
        }];
    }

    public colunasRelatorio(): Coluna[] {
        return [
            { titulo: 'Titulo', coluna: 'titulo' },
            { titulo: 'Valor', coluna: 'valor', decimais: 2 },
            { titulo: 'Resultado', coluna: 'resultado' }
        ]
    }

    public conteudoExportar(lista: any[]) {
        const arrecadada = parseFloat(lista[0]?.valor)?.toFixed(2);
        const repasse = parseFloat(lista[1]?.valor)?.toFixed(2);
        const percentualRepasse = (+repasse / +arrecadada) * 100;
        const resultadoRepasse = +percentualRepasse.toFixed(2) >= 25 ? 'ATENDEU' : 'NÃO ATENDEU'

        const linhas: {}[] = [
            {
                titulo: 'Valor da Receita Arrecadada',
                valor: arrecadada
            },
            {
                titulo: 'VALOR DOS REPASSES',
                valor: repasse
            },
            {
                titulo: 'PERCENTUAL DE REPASSE',
                valor: percentualRepasse
            },
            {
                titulo: 'Resultados',
                resultado: resultadoRepasse
            }
        ]

        return linhas

    }

}
