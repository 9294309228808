import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, FavorecidoProduto } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class FavorecidoProdutoService extends BaseResourceService<FavorecidoProduto> {

  constructor(
    protected injector: Injector
  ) {
    super(`favorecido-produtos`, injector);
  }

}
