import { Component, Input } from "@angular/core";
import { Login, Rcms, RcmsStorage } from "eddydata-lib";
import { ConfirmationService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RcmsStorageService } from '../service/rcms-storage.service';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-rcms-storage',
  templateUrl: './rcms-storage.component.html'
})
export class RcmsStorageComponent {

  @Input() public login: Login;
  @Input() public entidade: Rcms;
  @Input() public arquivos: RcmsStorage[];

  protected unsubscribe: Subject<void> = new Subject();

  public uploadedFiles: any[] = [];
  public descricao: string = null;

  constructor(
    protected confirmationService: ConfirmationService,
    private storageService: RcmsStorageService) {
  }

  public async onUpload(event: any, fileUpload: any) {
    if (!event.files || event.files.length === 0) {
      toastr.warning('Selecione o(s) arquivo(s)');
      return;
    }
    try {
      for (const file of event.files) {
        this.uploadedFiles.push(file);
      }
      this.storageService
        .upload(this.uploadedFiles, this.login.orgao.id, this.entidade.id, this.descricao)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          for (const item of res) {
            item.data_cadastro = new Date();
            this.arquivos.unshift(item);
          }
        }, (res) => {
          toastr.error(res.error.payload);
          this.uploadedFiles.length = 0;
        });
    } catch (ex) {
      toastr.error(ex.message ? ex.message : ex);
    }
    this.descricao = undefined;
    this.uploadedFiles = []
    fileUpload.clear();
    $('#dialogUpload').modal('hide');
  }

  public async remover(item: RcmsStorage) {
    if (!item) return
    this.confirmationService.confirm({
      message: 'Deseja realmente remover o arquivo?',
      header: 'Exclusão',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      key: 'arquivo',
      accept: () => {
        this.storageService.remover(item.id).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro removido com sucesso!', 'Exclusão');
            this.arquivos.splice(this.arquivos.indexOf(item), 1);
          }, (err) => toastr.error(err.error.payload));
      }
    });
  }

  public async toggleTransparencia(item: RcmsStorage) {
    if (!item) return;
    item.visivel_transparencia = !item.visivel_transparencia;
    await new Promise((resolve, reject) => {
      this.storageService
        .atualizar(item).subscribe(res => {
          toastr.success('Arquivo atualizado com sucesso');
          resolve(true)
        }, error => {
          toastr.error(error.error.payload)
          reject(error)
        });
    });
  }

}