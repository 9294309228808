import { BaseResourceRptComponent, Coluna, FormatoExportacao, PlanoContratacaoItem } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";
import { PlanoContratacaoAnualItensService } from "../../plano-contratacao-anual/service/plano-contratacao-anual-itens.service";
import { DatePipe } from "@angular/common";

export class PlanoContratacaoPlanejamentoRelatorioPlano extends BaseResourceRptComponent {

  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  constructor(
    protected planoItemService: PlanoContratacaoAnualItensService,
    protected parametrosImpressao: {},
    protected formatoExportacao: FormatoExportacao,
    protected subtitulo: string
  ) {
    super(
    )
  }


  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================

  protected afterInit(): void {
    this.login
  }

  protected tituloRelatorio(): string {
    return this.login.orgao.nome;
  }

  protected subTituloRelatorio(): string {
    return `PLANO DE CONTRATAÇÃO ANUAL - ${this.subtitulo}`
  }

  protected obterColunasRelatorio(): Coluna[] {
    return [
      { titulo: 'Item', coluna: 'numero', alignment: 'center' },
      { titulo: 'Tipo', coluna: 'tipo' },
      { titulo: 'Categoria', coluna: 'categoria' },
      { titulo: 'Subelemento', coluna: 'subelemento' },
      { titulo: 'Produto / Serviço', coluna: 'produto' },
      { titulo: 'Unidade', coluna: 'unidade_fornecimento' },
      { titulo: 'Quantidade', coluna: 'quantidade', decimais: 2 },
      { titulo: 'Valor Unit.', coluna: 'valor_unitario', decimais: 2 },
      { titulo: 'Total', coluna: 'total', decimais: 2 },
      { titulo: 'Valor Orc.', coluna: 'valor_orcamentario', decimais: 2 }
    ]
  }

  protected larguraColunas(): (string | number)[] {
    return ['*', '*', '*', 'auto', 'auto', '*', '*', '*', '*', '*']
  }

  protected totalizarColunas(): (string | {})[] {
    return ['total', 'valor_orcamentario']
  }

  public carregarLista(): Promise<any[]> {
    return new Promise((resolve) => {
      this.planoItemService.filtrar(1, -1, this.parametrosImpressao).pipe(takeUntil(this.unsubscribe)).subscribe(lista => {
        this.formato = this.formatoExportacao
        this.orientacao = "landscape";
        resolve(this.normalizarDados(lista.content))
      })
    })
  }


  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  public retornaCategoria(valor: number) {
    if (+valor === 1) return '1 - Material'
    if (+valor === 2) return '2 - Serviço'
    if (+valor === 3) return '3 - Obras'
    if (+valor === 4) return '4 - Serviços de Engenharia'
    if (+valor === 5) return '5 - Soluções de TIC'
    if (+valor === 6) return '6 - Locação de Imóveis'
    if (+valor === 7) return '7 - Alienação, concessão ou permissão'
    if (+valor === 8) return '8 - Obras e Serviços de Engenharia'
  }

  public retornaClassificacao(valor: number) {
    if (+valor === 1) return '1 - Material'
    if (+valor === 2) return '2 - Serviço'
  }

  public normalizarDados(lista: PlanoContratacaoItem[]) {

    const novaLista = lista.map((item, idx) => {
      item['numero'] = idx + 1
      item['categoria'] = this.retornaCategoria(item.categoria_item)
      item['tipo'] = this.retornaClassificacao(item.classificacao_catalogo)
      item['produto'] = `${item.codigo_item} - ${item.classificacao_superior_nome}`
      item['total'] = +item.quantidade * +item.valor_unitario
      return item
    })

    return novaLista
  }

}