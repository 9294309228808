import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { ChartModule } from 'primeng/chart';
import { CalendarModule } from 'primeng/calendar';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FileUploadModule } from 'primeng/fileupload';
import { SharedModule, SimplesNacionalDlgModule } from 'eddydata-lib';
import { LiquidacaoLstComponent } from './liquidacao-lst/liquidacao-lst.component';
import { LiquidacaoShwComponent } from './liquidacao-shw/liquidacao-shw.component';
import { LiquidacaoRoutingModule } from './liquidacao-routing.module';


@NgModule({
  declarations: [
    LiquidacaoLstComponent,
    LiquidacaoShwComponent,
  ],
  imports: [
    CommonModule,
    LiquidacaoRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
    ChartModule,
    IMaskModule,
    ListboxModule,
    TableModule,
    ProgressSpinnerModule,
    ToastModule,
    RadioButtonModule,
    FileUploadModule,
    SimplesNacionalDlgModule
  ]
})
export class LiquidacaoModule { }
