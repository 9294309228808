import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, TransferenciaBancaria } from 'eddydata-lib';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TransferenciaBancariaService extends BaseResourceService<TransferenciaBancaria> {

  constructor(
    protected injector: Injector
  ) {
    super(`transferencias-bancarias`, injector);
  }

  public totalDuodecimoMes(exercicio: number, orgaoId: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/total-duodecimo-mes/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalDuodecimoAno(exercicio: number, orgaoId: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/total-duodecimo-ano/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public ultimaDataTransferencia(exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/ultima-data-transferencia/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public marcarComoImpresso(parametros: any): Observable<any> {
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/marcar-como-impresso`, JSON.stringify(parametros), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

}
