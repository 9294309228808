import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EmailService, FuncaoService, InventarioSetor, InventarioTransferencia, Login, ParametroPatrimonio, ParametroPatrimonioService, Setor } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { InventarioTransferenciaService } from '../service/inventario-transferencia.service';

declare var $: any;

@Component({
  selector: 'app-inventario-transferencia',
  templateUrl: './inventario-transferencia.component.html'
})
export class InventarioTransferenciaComponent implements OnInit, OnChanges {

  /**
   * Declaração de variáveis
   */
  @Input() public tipo: 'T' | 'R' | 'S';
  @Input() public inventarioSetor: InventarioSetor;
  @Input() public login: Login;
  @Input() public dataAtualizacao: Date;
  @Input() visualizar: boolean;
  @Output() callbackPreencherGrid: EventEmitter<boolean> = new EventEmitter();
  @Output() filtroTransferencia: EventEmitter<string> = new EventEmitter();
  @Output() callbackAutomatico: EventEmitter<void> = new EventEmitter();

  public lista: InventarioTransferencia[];
  public listaStatus: {
    id: 'PENDENTE' | 'ACEITO' | 'RECUSADO' | 'CANCELADO',
    nome: string, estilo: string
  }[];
  public itemAtual: InventarioTransferencia;
  public tipoEmail: 'T' | 'S' | 'SA' | 'SR' | 'I';
  public filtroSelecionado: string = '';

  public setor: Setor;

  protected unsubscribe: Subject<void> = new Subject();

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    public funcaoService: FuncaoService,
    protected parametroPatrimonioService: ParametroPatrimonioService,
    private emailService: EmailService,
    protected confirmationPriService: ConfirmationService,
    private transferenciaService: InventarioTransferenciaService,
  ) {
  }

  ngOnInit(): void {
    this.setor = this.login.setor.find(setor => {
      return this.inventarioSetor?.setor.id === setor.id
    })
    
    this.listaStatus = [
      { id: 'PENDENTE', nome: 'Pendente', estilo: 'secondary' },
      { id: 'ACEITO', nome: 'Aceito', estilo: 'success' },
      { id: 'RECUSADO', nome: 'Recusado', estilo: 'danger' },
      { id: 'CANCELADO', nome: 'Cancelado', estilo: 'light' },
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.inventarioSetor && this.tipo) {
      this.carregarListagem();
      this.callbackPreencherGrid.emit(true);
    }
  }

  public carregarListagem() {
    this.setor = this.login.setor.find(setor => {
      return this.inventarioSetor?.setor.id === setor.id
    })

    let params = { orderBy: 'data_cadastro$DESC' };
    this.filtroTransferencia.emit(this.filtroSelecionado);

    if (this.tipo === 'T') {
      if (this.filtroSelecionado)
        params['status'] = this.filtroSelecionado;
      params['origem_id'] = this.inventarioSetor.id;
      params['relations'] = 'tombamento.produto,destino.setor,usuario_status';
      params['solicitacao'] = false;
    } else if (this.tipo === 'R') {
      params['destino_id'] = this.inventarioSetor.id;
      params['relations'] = 'tombamento.produto,origem.setor,usuario_status';
      params['solicitacao'] = false;
    } else if (this.tipo === 'S') {
      params['OR'] = `origem_id=${this.inventarioSetor.id};!;!;destino_id=${this.inventarioSetor.id}`;
      params['relations'] = 'tombamento.produto,origem.setor,destino.setor,usuario_status';
      params['solicitacao'] = true;
    }

    this.transferenciaService.filtrar(0, 0, params, true).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => this.lista = res.content, error => toastr.error(error));

    this.atualizarAuto();
  }

  atualizarAuto() {
    timer(30000).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.carregarListagem();
      this.callbackAutomatico.emit();
    });
  }

  public obterDescricaoTransferencia(item: InventarioTransferencia): string {
    if (!item || item.status === 'PENDENTE') return '';

    return `${this.funcaoService.obterNomePorChave(this.listaStatus, item.status)} pelo usuário ${item.usuario_status?.nome} em ${this.funcaoService.converteDataBR(item.data_status)}`
  }

  public abrirCancelar(item: InventarioTransferencia) {
    if (!item || item.status !== 'PENDENTE') return;
    this.itemAtual = item;
    $(`#dialogInventarioTransferencia${this.itemAtual.solicitacao ? 'Solicitacao' : ''}Cancelar`).modal('show');
  }

  public async confirmarCancelar() {
    if (!this.itemAtual || this.itemAtual.status !== 'PENDENTE') return;

    await new Promise((resolve, reject) => {
      this.transferenciaService.cancelar(this.itemAtual.id, this.login.usuario.id, this.itemAtual.motivo_status).subscribe(res => {
        resolve(true);
        this.carregarListagem();
        this.callbackPreencherGrid.emit(true);
        $(`#dialogInventarioTransferencia${this.itemAtual.solicitacao ? 'Solicitacao' : ''}Cancelar`).modal('hide');
      }, error => {
        toastr.error(error.error.payload)
        reject(error)
      });
    });
  }

  public abrirRecusar(item: InventarioTransferencia) {
    if (!item || item.status !== 'PENDENTE') return;
    this.itemAtual = item;
    $(`#dialogInventarioTransferencia${this.itemAtual.solicitacao ? 'Solicitacao' : ''}Recusar`).modal('show');
  }

  public async confirmarRecusar() {
    if (!this.itemAtual || this.itemAtual.status !== 'PENDENTE') return;
    this.tipoEmail = 'SR';

    await new Promise((resolve, reject) => {
      this.transferenciaService.recusar(this.itemAtual.id, this.login.usuario.id, this.itemAtual.motivo_status).subscribe(res => {
        this.email(this.itemAtual);
        resolve(true);
        this.carregarListagem();
        this.callbackPreencherGrid.emit(true);
        $(`#dialogInventarioTransferencia${this.itemAtual.solicitacao ? 'Solicitacao' : ''}Recusar`).modal('hide');
      }, error => {
        toastr.error(error.error.payload)
        reject(error)
      });
    });
  }

  public abrirConfirmar(item: InventarioTransferencia) {
    if (!item || item.status !== 'PENDENTE') return;
    this.itemAtual = item;
    $(`#dialogInventarioTransferencia${this.itemAtual.solicitacao ? 'Solicitacao' : ''}Confirmar`).modal('show');
  }

  public async confirmarConfirmar() {
    if (!this.itemAtual || this.itemAtual.status !== 'PENDENTE') return;
    this.tipoEmail = 'SA';

    await new Promise((resolve, reject) => {
      this.transferenciaService.confirmar(this.itemAtual.id, this.login.usuario.id, this.tipo).subscribe(res => {
        this.email(this.itemAtual);
        resolve(true);
        this.carregarListagem();
        this.callbackPreencherGrid.emit(true);
        $(`#dialogInventarioTransferencia${this.itemAtual.solicitacao ? 'Solicitacao' : ''}Confirmar`).modal('hide');
      }, error => {
        toastr.error(error.error.payload)
        reject(error)
      });
    });
  }

  public email(item: InventarioTransferencia) {
    this.parametroPatrimonioService.obter({
      'orgao.id': this.login.orgao.id
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(parametroPatrimonio => {
        let mensagem = '';
        if (this.tipoEmail.includes('SA' || 'SR'))
          mensagem += `<br />Setor - ${item.origem.setor.nome}(${item.origem.setor.email})`;

        if (mensagem.length === 0)
          return;

        this.enviarEmail(item, parametroPatrimonio);
      });
  }

  public async enviarEmail(solicitacao: InventarioTransferencia, parametro?: ParametroPatrimonio) {
    let subject = `Solicitação de Bem Móvel - Nº: ${solicitacao.tombamento.placa}`;
    let destinatarios: { nome: string; email: string; }[] = [];
    let mensagem;

    if (this.tipoEmail.includes('SA')) {
      mensagem = `<p>${parametro.mensagem_solicitacao_aceite}</p>`;
      destinatarios.push({ nome: solicitacao.origem.setor.nome, email: solicitacao.origem.setor.email });
    }
    if (this.tipoEmail.includes('SR')) {
      mensagem = `<p>${parametro.mensagem_solicitacao_recusa}</p>`;
      destinatarios.push({ nome: solicitacao.origem.setor.nome, email: solicitacao.origem.setor.email });
    }

    if (destinatarios.length) {
      this.emailService.enviar({ titulo: subject, corpo: mensagem, destinos: destinatarios })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(data => toastr.success('E-mail encaminhado com sucesso!'),
          error => toastr.error(`Não foi possivel encaminhar o e-mail de confirmação!`));
    }
  }
}
