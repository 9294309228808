import { Component, Injector } from '@angular/core';
import { Aliquota, BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, LoginContabil, Modalidade } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { AliquotaService } from '../service/aliquota.service';

@Component({
  selector: 'lib-aliquota-list',
  templateUrl: './aliquota-list.component.html'
})
export class AliquotaListComponent extends BaseResourceListComponent<Aliquota, LoginContabil> {

  /**
   * Declaração de variáveis
   */


  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private service: AliquotaService) {
    super(service, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return '';
  }

  protected condicoesGrid(): {} {
    return {};
  }

  protected ordenacaoGrid(): string[] {
    return ['id'];
  }

  protected filtrosGrid(): Filtro {
    return {
      // number: ['id', 'codigo', 'valor_limite', 'prazo_limite'],
      // text: ['nome', 'codigo']
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: Modalidade): Observable<Modalidade> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      // { titulo: 'ID', coluna: 'id' },
      // { titulo: 'TCE', coluna: 'codigo' },
      // { titulo: 'Nome', coluna: 'nome' },
      // { titulo: 'Valor limite', coluna: 'valor_limite', decimais: 2 },
      // { titulo: 'Prazo limite', coluna: 'prazo_limite', decimais: 0 },
      // { titulo: 'Exige Licitação', coluna: 'exige_licitacao' },
      // { titulo: 'Exige Contrato', coluna: 'exige_contrato' },
      // { titulo: 'Exige Convênio', coluna: 'exige_convenio' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    // const parametros = this.obterParametros();
    // parametros['relations'] = 'cidade';
    // this.modalidadeService
    //   .filtrar(1, -1,
    //     parametros
    //   )
    //   .pipe(takeUntil(this.unsubscribe))
    //   .subscribe(
    //     lista => {
    //       if (formato === 'pdf') {
    //         this.imprimir(`LISTAGEM DE MODALIDADES `,
    //           this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
    //           'Listagem modalidade', ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto'], lista.content);
    //       } else {
    //         this.exportar(formato, lista.content);
    //       }
    //     },
    //     () => alert('erro ao retornar lista')
    //   );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public retornar() {
    this.router.navigate([`/cadastros-auxiliares`]);
  }
}
