import {
  Component, OnInit, ViewChild, Input, SimpleChanges,
  Output, EventEmitter, AfterViewInit, OnDestroy, OnChanges, ViewChildren, QueryList, ElementRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { AutoComplete } from 'primeng/autocomplete';
import { AcaoFonteRecursoService } from '../service/acao-fonte-recurso.service';
import { GlobalService, AcaoFonteRecurso,
  EddyAutoComplete, Recurso, Despesa, AcaoGoverno, ExercicioService, Exercicio
} from 'eddydata-lib';
import { RecursoService, DespesaService } from 'administrativo-lib';
import { Event } from 'jquery';

@Component({
  selector: 'app-acao-fonte-recurso-form',
  templateUrl: './acao-fonte-recurso-form.component.html'
})
export class AcaoFonteRecursoFormComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @ViewChild('f') formGroup: any;

  public entity: AcaoFonteRecurso;
  public entityTemp: AcaoFonteRecurso;

  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public despesaAutoComplete: EddyAutoComplete<Despesa>;
  public listaExercicios: Array<any>;

  private ex: Exercicio = new Exercicio();

  protected unsubscribe: Subject<void> = new Subject();

  @Output() atualizarClick = new EventEmitter();
  @Input() lista: Array<any>;
  @Input() entidade: AcaoGoverno;
  @Input() login: any;
  @Input() custo1: number;
  @Input() custo2: number;
  @Input() custo3: number;
  @Input() custo4: number;
  @Input() funcao: string;
  @Output() anoEmitter = new EventEmitter<any>();
  @Output() anoCorrenteEmitter = new EventEmitter<any>();

  public anoCorrente: number;
  public totalCustoDetalhado: number;
  public totalCustoAnual: number;

  @ViewChild('recurso_') public inputCodigo: AutoComplete;
  @ViewChild('btnAdicionarAcaoFonteRecurso') public btnAdicionarConta: ElementRef;
  @ViewChildren('btnsEditar') public btnsEditar: QueryList<ElementRef>;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected recursoService: RecursoService,
    protected exercicioService: ExercicioService,
    protected despesaService: DespesaService,
    protected acaoFonteService: AcaoFonteRecursoService,
  ) { }

  ngOnInit() {
    this.carregarAutoComplete();
    this.listaExercicios = [];
    this.listaExercicios.push({ id: 1, ano: this.login.ppa.ppaperiodo.ano1 });
    this.listaExercicios.push({ id: 2, ano: this.login.ppa.ppaperiodo.ano2 });
    this.listaExercicios.push({ id: 3, ano: this.login.ppa.ppaperiodo.ano3 });
    this.listaExercicios.push({ id: 4, ano: this.login.ppa.ppaperiodo.ano4 });
    this.anoCorrente = this.login.exercicio.ano;
    this.obterTotalAno(this.anoCorrente);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ex = this.login.exercicio;
    if (changes.lista) {
      this.somarRecurso(this.lista);
    }
  }

  ngAfterViewInit() {
    new GlobalService().calendarMascara();
    if (this.entity) {
      setTimeout(() => this.inputCodigo ? this.inputCodigo.focusInput() : null, 2000);
    }
  }

  private loadAcaoFonteRecurso() {
    if (this.entidade.id) {
      this.acaoFonteService.filtrar(1, -1,
        { relations: 'recurso,aplicacao,despesa', 'acao_governo.id': this.entidade.id, ano: this.anoCorrente })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.lista = res ? res.content : new Array<AcaoFonteRecurso>();
          this.somarRecurso(this.lista);
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  private desabilitarEdicao(desabilitar: boolean) {
    this.btnsEditar.forEach((btn) => btn.nativeElement.disabled = desabilitar)
    this.btnAdicionarConta.nativeElement.disabled = desabilitar;
  }

  public adicionarAcaoFonteRecurso() {
    this.entity = new AcaoFonteRecurso();
    this.entity.acao_governo = this.entidade;
    if (!this.lista) {
      this.lista = new Array();
    }
    this.entity.ano = this.anoCorrente;
    this.entity.editavel = true;
    this.entity['salvo'] = false;
    this.lista.unshift(this.entity);
    setTimeout(() => this.inputCodigo.focusInput(), 1000);
    this.desabilitarEdicao(true);
  }

  public async salvarAcaoFonteRecurso(item: any) {
    try {
      if (!item.recurso) {
        throw new Error('Informe a fonte de recurso!');
      }
      if (!item.aplicacao) {
        throw new Error('Informe a aplicação do recurso!');
      }
      if (!item.despesa) {
        throw new Error('Informe a despesa orçamentária!');
      }
      this.entity.editavel = false;
      item['salvo'] = true;
      this.desabilitarEdicao(false);
      this.somarRecurso(this.lista);
      this.atualizarClick.emit(this.lista);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  public editarAcaoFonteRecurso(item: AcaoFonteRecurso) {
    this.entityTemp = JSON.parse(JSON.stringify(item));

    this.entity = item;
    this.entity.editavel = true;
    this.entity['salvo'] = true;
    this.desabilitarEdicao(true)
  }

  public cancelarAcaoFonteRecurso(item: AcaoFonteRecurso, index: number) {
    if (item['salvo'] && item.id === this.entityTemp?.id) {
      item.recurso = this.entityTemp.recurso;
      item.aplicacao = this.entityTemp.aplicacao;
      item.despesa = this.entityTemp.despesa;
      item.valor = this.entityTemp.valor;

      this.entity = item;
      this.entity.editavel = false;
    } else {
      this.lista.splice(index, 1);
    }
    this.desabilitarEdicao(false);
  }

  public confirmarRemocao(item: AcaoFonteRecurso, index: number) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja remover item?',
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.removerAcaoFonteRecurso(item, index)
      },
    });
  }

  private removerAcaoFonteRecurso(item: AcaoFonteRecurso, index: number) {
    if (item.id) {
      this.acaoFonteService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.lista.splice(index, 1);
          this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Exclusão', detail: error.error.payload });
          throw error;
        });
    } else {
      this.lista.splice(index, 1);
    }
    this.somarRecurso(this.lista);
  }

  private carregarAutoComplete() {
    // autocomplete para recurso
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'codigo', ['codigo', 'nome'],
      { cidade_id: this.login.cidade.id, nivel: '0', orderBy: 'nome' },
      { number: ['codigo'], text: ['nome', 'codigo'] }
    );
    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'codigo', ['codigo', 'nome'],
      { cidade_id: this.login.cidade.id, nivel: '2', orderBy: 'nome' },
      { number: ['codigo'], text: ['nome', 'codigo'] }
    );
    // autocomplete para despesa
    this.despesaAutoComplete = new EddyAutoComplete(null, this.despesaService,
      'codigo', ['codigo', 'nome'],
      { exercicio_id: this.ex.id, nivel: '4', orderBy: 'nome' },
      { number: ['codigo'], text: ['nome'] }
    );
  }

  private somarRecurso(list: Array<AcaoFonteRecurso>) {
    if (list) {
      this.totalCustoDetalhado = 0;
      for (const item of list) {
        this.totalCustoDetalhado += +(+item.valor).toFixed(2);
        // +(+valor).toFixed(2)
      }
      this.anoEmitter.emit({ custo_anual: this.totalCustoAnual, total_detalhado: this.totalCustoDetalhado });
    }
  }

  public async alternarAno(ano: number) {
    this.obterTotalAno(ano);
    this.loadAcaoFonteRecurso();
    this.anoCorrenteEmitter.emit(this.anoCorrente);
    const resultado = await this.exercicioService.obterPorAno(ano, this.login.cidade.id).toPromise();
    this.ex = resultado.content[0];
    this.despesaAutoComplete = new EddyAutoComplete(null, this.despesaService,
      'codigo', ['codigo', 'nome'],
      { exercicio_id: this.ex.id, nivel: '4', orderBy: 'nome' },
      { number: ['codigo'], text: ['nome'] }
    );
  }

  private obterTotalAno(ano: number) {
    const item = this.listaExercicios.find(element => +element.ano === +ano);
    if (item.id === 1) {
      this.totalCustoAnual = +this.custo1;
    } else if (item.id === 2) {
      this.totalCustoAnual = +this.custo2;
    } else if (item.id === 3) {
      this.totalCustoAnual = +this.custo3;
    } else if (item.id === 4) {
      this.totalCustoAnual = +this.custo4;
    }
  }

}
