import { Component, Injector, ViewChild, ElementRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ParametroEmailService } from '../service/parametro-email.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ActivatedRoute } from '@angular/router';
import { BaseResourceFormComponent } from '../../models/base-resource-form';
import { ParametroEmail } from '../../entidade/comum/parametro-email.model';
import { LoginContabil } from '../../entidade/login/login-contabil';

declare var $: any;

@Component({
  selector: 'lib-parametro-email-form',
  templateUrl: './parametro-email-form.component.html',
  providers: [ConfirmationService]
})
export class ParametroEmailFormComponent extends BaseResourceFormComponent<ParametroEmail, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @ViewChild('email_') inputField: ElementRef;
  public destinatario: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private route: ActivatedRoute,
    protected injector: Injector,
    protected messageService: MessageService,
    protected parametroEmailServico: ParametroEmailService) {
    super(new ParametroEmail(), injector, ParametroEmail.converteJson, parametroEmailServico);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================
  protected async loadResource() {
    this.activatedRoute
      .paramMap
      .pipe(switchMap(params => this.baseResourceService.obter(
        Object.assign({}, { 'orgao.id': this.login.orgao.id }, this.parametrosExtras())
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (entidade) => {
          if (entidade) {
            this.entidade = entidade;
            this.afterLoad();
            this.entidadeForm.patchValue(entidade);
          }
        }, (error) => this.sair());
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      port: [null, [Validators.required]],
      host: [null, [Validators.required]],
      proxy: [null],
      email: [null, [Validators.required]],
      user: [null, [Validators.required]],
      password: [null, [Validators.required]],
      secure: [false, [Validators.required]],
      ignoreTLS: [false, [Validators.required]],
      requireTLS: [false, [Validators.required]],
      connectionTimeout: [null],
      gretingTimeout: [null],
      socketTimeout: [null],
      disableFileAccess: [false, [Validators.required]],
      disableUrlAccess: [false, [Validators.required]],
      logger: [false, [Validators.required]],
      debug: [false, [Validators.required]],
      disabled: [false, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]]
    });
  }

  protected parametrosExtras(): {} {
    return {};
  }

  protected afterLoad(): void {

  }

  protected afterInit(): void {
    $('[data-toggle="tooltip"]').tooltip();
  }

  protected beforeSubmit(): void {

  }

  protected afterSubmit(entidade: ParametroEmail): void {

  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public testar() {
    this.route.paramMap
      .pipe(switchMap(params => this.parametroEmailServico.testar(
        this.entidadeForm.value, this.destinatario
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (dados) => {
          $('#dialogTestar').modal('hide');
          toastr.success(`E-mail enviado para ${this.destinatario}! Verifique a sua caixa de entrada!`);
        }, error => {
          if (error.error && error.error.payload) {
            toastr.error(error.error.payload);
          } else {
            toastr.error('Não foi possível enviar o e-mail, verifique as configurações informadas');
          }
        });
  }
}
