import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../../assinaturas/assinaturas';
import { AnexoLrfFederalService } from '../../../service/anexo-lrf-federal.service';

@Directive()
export class Anexo7RreoLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoFederalServico: AnexoLrfFederalService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected assinaturaControleInterno: boolean) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
    this.anexoFederalServico.obterAnexo7(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(null, this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            await this.conteudo(dados),
            'landscape', 'ANEXO7 - DEMONSTRATIVO DOS RESTOS A PAGAR POR PODER E ÓRGÃO',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 2;
                },
                paddingRight() {
                  return 2;
                }
              }
            }, true, false);
        } else {
          this.funcaoService.exportar(formato, this.normalizar(dados), 'ANEXO7 - DEMONSTRATIVO DOS RESTOS A PAGAR POR PODER E ÓRGÃO', this.colunas());
        }
      });
  }

  private async conteudo(dados: any[]): Promise<{}[]> {
    // monta o cabecalho

    const bimestre = this.mes;

    const registros: {}[] = [
      [
        { text: this.login.orgao.nome, bold: true, border: [false, false, false, false], colSpan: 13, fontSize: 10 },
        '', '', '', '', '', '', '', '', '', '', '', ''
      ],

      [
        { text: 'RELATÓRIO RESUMIDO DA EXECUÇÃO ORÇAMENTÁRIA', bold: true, border: [false, false, false, false], colSpan: 13 },
        '', '', '', '', '', '', '', '', '', '', '', ''],
      [
        { text: 'DEMONSTRATIVO DOS RESTOS A PAGAR POR PODER E ÓRGÃO', bold: true, border: [false, false, false, false], colSpan: 13 },
        '', '', '', '', '', '', '', '', '', '', '', ''],
      [
        { text: 'ORÇAMENTOS FISCAL E DA SEGURIDADE SOCIAL', bold: true, border: [false, false, false, false], colSpan: 13 },
        '', '', '', '', '', '', '', '', '', '', '', ''],
      [
        {
          text: `REFERÊNCIA: ${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano}`, bold: true, border: [false, false, false, false],
          colSpan: 13
        },
        '', '', '', '', '', '', '', '', '', '', '', ''],
      [
        { text: 'RREO - ANEXO 7 (LRF, art. 53, inciso V)', bold: true, border: [false, false, false, false], colSpan: 13 },
        '', '', '', '', '', '', '', '', '', '', '', ''
      ],
      [
        { text: 'PODER/ÓRGÃO', alignment: 'center', bold: true, fontSize: 7, rowSpan: 3 },
        { text: 'RESTOS A PAGAR PROCESSADOS', alignment: 'center', bold: true, fontSize: 7, colSpan: 5 },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: 'RESTOS A PAGAR NÃO PROCESSADOS', alignment: 'center', bold: true, fontSize: 7, colSpan: 6 },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: 'SALDO TOTAL (l = e + k)', alignment: 'center', bold: true, fontSize: 7, rowSpan: 3 }
      ],
      [
        { text: '' },
        { text: 'INSCRITOS', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 },
        { text: '' },
        { text: 'PAGOS (c)', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
        { text: 'CANCELADOS (d)', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
        { text: 'SALDO (e=(a+b)-(c+d))', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
        { text: 'INSCRITOS', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 },
        { text: '' },
        { text: 'LIQUIDADOS (h)', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
        { text: 'PAGOS (i)', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
        { text: 'CANCELADOS (j)', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
        { text: 'SALDO (k=(f+g)-(i+j))', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
        { text: '' },
      ],
      [
        { text: '' },
        { text: 'EM EXERCÍCIOS ANTERIORES', alignment: 'center', bold: true, fontSize: 7 },
        { text: `EM 31 DE DEZEMBRO DE ${(+this.exercicio.ano - 1)}`, alignment: 'center', bold: true, fontSize: 7 },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: 'EM EXERCÍCIOS ANTERIORES', alignment: 'center', bold: true, fontSize: 7 },
        { text: `EM 31 DE DEZEMBRO DE ${(+this.exercicio.ano - 1)}`, alignment: 'center', bold: true, fontSize: 7 },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' }
      ]

    ];

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);

    // monta o agrupamento do relatório
    const titulos = this.funcaoService.agrupar(dados, 'titulo',
      ['inscrito_anterior_pr', 'inscrito_pr', 'pago_pr', 'cancelado_pr', 'inscrito_anterior_np', 'inscrito_np', 'liquidado_np', 'pago_np', 'cancelado_np']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    let total7 = 0;
    let total8 = 0;
    let total9 = 0;
    let total10 = 0;
    let total11 = 0;
    let total12 = 0;
    for (const titulo of titulos) {
      registros.push([
        { text: titulo.grupo, fontSize: 7, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['inscrito_anterior_pr']), alignment: 'right', fontSize: 7, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['inscrito_pr']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['pago_pr']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['cancelado_pr']), alignment: 'right', fontSize: 7 },
        {
          text: this.funcaoService.convertToBrNumber(
            (+titulo.totalizadores['inscrito_anterior_pr'] + +titulo.totalizadores['inscrito_pr']) - (+titulo.totalizadores['pago_pr'] + +titulo.totalizadores['cancelado_pr'])), alignment: 'right', fontSize: 7
        },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['inscrito_anterior_np']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['inscrito_np']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['liquidado_np']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['pago_np']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['cancelado_np']), alignment: 'right', fontSize: 7 },
        {
          text: this.funcaoService.convertToBrNumber(
            (+titulo.totalizadores['inscrito_anterior_np'] + +titulo.totalizadores['inscrito_np']) - (+titulo.totalizadores['pago_np'] + +titulo.totalizadores['cancelado_np'])
          ), alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(
            (+titulo.totalizadores['inscrito_anterior_pr'] + +titulo.totalizadores['inscrito_pr']) - (+titulo.totalizadores['pago_pr'] + +titulo.totalizadores['cancelado_pr']) +
            (+titulo.totalizadores['inscrito_anterior_np'] + +titulo.totalizadores['inscrito_np']) - (+titulo.totalizadores['pago_np'] + +titulo.totalizadores['cancelado_np'])
          ), alignment: 'right', fontSize: 7
        }
      ]);
      total1 += +titulo.totalizadores['inscrito_anterior_pr'];
      total2 += +titulo.totalizadores['inscrito_pr'];
      total3 += +titulo.totalizadores['pago_pr'];
      total4 += +titulo.totalizadores['cancelado_pr'];
      total5 += (+titulo.totalizadores['inscrito_anterior_pr'] + +titulo.totalizadores['inscrito_pr'] - (+titulo.totalizadores['pago_pr'] + +titulo.totalizadores['cancelado_pr']));
      total6 += +titulo.totalizadores['inscrito_anterior_np'];
      total7 += +titulo.totalizadores['inscrito_np'];
      total8 += +titulo.totalizadores['liquidado_np'];
      total9 += +titulo.totalizadores['pago'];
      total10 += +titulo.totalizadores['cancelado_np'];
      total11 += (+titulo.totalizadores['inscrito_anterior_np'] + +titulo.totalizadores['inscrito_np'] - (+titulo.totalizadores['pago_np'] + +titulo.totalizadores['cancelado_np']));
      total12 += (+titulo.totalizadores['inscrito_anterior_pr'] + +titulo.totalizadores['inscrito_pr'] - (+titulo.totalizadores['pago_pr'] + +titulo.totalizadores['cancelado_pr'])) +
        (+titulo.totalizadores['inscrito_anterior_np'] + +titulo.totalizadores['inscrito_np'] - (+titulo.totalizadores['pago_np'] + +titulo.totalizadores['cancelado_np']))

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo',
        ['inscrito_anterior_pr', 'inscrito_pr', 'pago_pr', 'cancelado_pr', 'inscrito_anterior_np', 'inscrito_np', 'liquidado_np', 'pago_np', 'cancelado_np']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          registros.push([
            { text: grupo.grupo, fontSize: 7, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['inscrito_anterior_pr']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['inscrito_pr']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago_pr']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['cancelado_pr']), alignment: 'right', fontSize: 7 },
            {
              text: this.funcaoService.convertToBrNumber(
                (+grupo.totalizadores['inscrito_anterior_pr'] + +grupo.totalizadores['inscrito_pr']) - (+grupo.totalizadores['pago_pr'] + +grupo.totalizadores['cancelado_pr'])), alignment: 'right', fontSize: 7
            },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['inscrito_anterior_np']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['inscrito_np']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado_np']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago_np']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['cancelado_np']), alignment: 'right', fontSize: 7 },
            {
              text: this.funcaoService.convertToBrNumber(
                (+grupo.totalizadores['inscrito_anterior_np'] + +grupo.totalizadores['inscrito_np']) - (+grupo.totalizadores['pago_np'] + +grupo.totalizadores['cancelado_np'])
              ), alignment: 'right', fontSize: 7
            },
            {
              text: this.funcaoService.convertToBrNumber(
                (+grupo.totalizadores['inscrito_anterior_pr'] + +grupo.totalizadores['inscrito_pr']) - (+grupo.totalizadores['pago_pr'] + +grupo.totalizadores['cancelado_pr']) +
                (+grupo.totalizadores['inscrito_anterior_np'] + +grupo.totalizadores['inscrito_np']) - (+grupo.totalizadores['pago_np'] + +grupo.totalizadores['cancelado_np'])
              ), alignment: 'right', fontSize: 7
            }
          ]);

          for (const item of grupo.registros) {
            if (item.nome != '') {
              registros.push([
                {
                  text: item.nome, fontSize: 7, margin: [5, 0, 0, 0]
                },
                { text: this.funcaoService.convertToBrNumber(item.inscrito_anterior_pr), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.inscrito_pr), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.pago_pr), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.cancelado_pr), alignment: 'right', fontSize: 7 },
                {
                  text: this.funcaoService.convertToBrNumber(
                    (+item.inscrito_anterior_pr + +item.inscrito_pr) - (+item.inscrito_anterior_pr + +item.inscrito_pr)), alignment: 'right', fontSize: 7
                },
                { text: this.funcaoService.convertToBrNumber(item.inscrito_anterior_np), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.inscrito_np), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.liquidado_np), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.pago_np), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.cancelado_np), alignment: 'right', fontSize: 7 },
                {
                  text: this.funcaoService.convertToBrNumber(
                    (+item.inscrito_anterior_np + +item.inscrito_np) - (+item.inscrito_anterior_np + +item.inscrito_np)
                  ), alignment: 'right', fontSize: 7
                },
                {
                  text: this.funcaoService.convertToBrNumber(
                    (+item.inscrito_anterior_pr + +item.inscrito_pr) - (+item.inscrito_anterior_pr + +item.inscrito_pr) +
                    (+item.inscrito_anterior_np + +item.inscrito_np) - (+item.inscrito_anterior_np + +item.inscrito_np)
                  ), alignment: 'right', fontSize: 7
                }
              ]);
            }
          }
        }
      }
    }

    registros.push([
      { text: 'TOTAL (III) = (I + II)', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(total4), alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(total5), alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(total6), alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(total7), alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(total8), alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(total9), alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(total10), alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(total11), alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(total12), alignment: 'right', fontSize: 7 }
    ]);

    return [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45],
          body: registros
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*'],
          body: assinaturas
        }
      }
    ];
  }

  public normalizar(lista) {
    const listaExportar = []

    const titulos = this.funcaoService.agrupar(lista, 'titulo',
      ['inscrito_anterior_pr', 'inscrito_pr', 'pago_pr', 'cancelado_pr', 'inscrito_anterior_np', 'inscrito_np', 'liquidado_np', 'pago_np', 'cancelado_np']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    let total7 = 0;
    let total8 = 0;
    let total9 = 0;
    let total10 = 0;
    let total11 = 0;
    let total12 = 0;

    for (const titulo of titulos) {
      const linha_titulo = {
        orgao: titulo.grupo,
        anterior_pro: this.funcaoService.convertToBrNumber(titulo.totalizadores['inscrito_anterior_pr']),
        ate_pro: this.funcaoService.convertToBrNumber(titulo.totalizadores['inscrito_pr']),
        pagos_c: this.funcaoService.convertToBrNumber(titulo.totalizadores['pago_pr']),
        cancelados_d: this.funcaoService.convertToBrNumber(titulo.totalizadores['cancelado_pr']),
        saldo_e: this.funcaoService.convertToBrNumber((+titulo.totalizadores['inscrito_anterior_pr'] + +titulo.totalizadores['inscrito_pr']) - (+titulo.totalizadores['pago_pr'] + +titulo.totalizadores['cancelado_pr'])),
        anterior_npro: this.funcaoService.convertToBrNumber(titulo.totalizadores['inscrito_anterior_np']),
        ate_npro: this.funcaoService.convertToBrNumber(titulo.totalizadores['inscrito_np']),
        liquidados_h: this.funcaoService.convertToBrNumber(titulo.totalizadores['liquidado_np']),
        pagos_i: this.funcaoService.convertToBrNumber(titulo.totalizadores['pago_np']),
        cancelados_j: this.funcaoService.convertToBrNumber(titulo.totalizadores['cancelado_np']),
        saldo_k: this.funcaoService.convertToBrNumber((+titulo.totalizadores['inscrito_anterior_np'] + +titulo.totalizadores['inscrito_np']) - (+titulo.totalizadores['pago_np'] + +titulo.totalizadores['cancelado_np'])),
        saldo_total: this.funcaoService.convertToBrNumber((+titulo.totalizadores['inscrito_anterior_pr'] + +titulo.totalizadores['inscrito_pr']) - (+titulo.totalizadores['pago_pr'] + +titulo.totalizadores['cancelado_pr']) +
          (+titulo.totalizadores['inscrito_anterior_np'] + +titulo.totalizadores['inscrito_np']) - (+titulo.totalizadores['pago_np'] + +titulo.totalizadores['cancelado_np'])),
      }
      listaExportar.push(linha_titulo)

      total1 += +titulo.totalizadores['inscrito_anterior_pr'];
      total2 += +titulo.totalizadores['inscrito_pr'];
      total3 += +titulo.totalizadores['pago_pr'];
      total4 += +titulo.totalizadores['cancelado_pr'];
      total5 += (+titulo.totalizadores['inscrito_anterior_pr'] + +titulo.totalizadores['inscrito_pr'] - (+titulo.totalizadores['pago_pr'] + +titulo.totalizadores['cancelado_pr']));
      total6 += +titulo.totalizadores['inscrito_anterior_np'];
      total7 += +titulo.totalizadores['inscrito_np'];
      total8 += +titulo.totalizadores['liquidado_np'];
      total9 += +titulo.totalizadores['pago'];
      total10 += +titulo.totalizadores['cancelado_np'];
      total11 += (+titulo.totalizadores['inscrito_anterior_np'] + +titulo.totalizadores['inscrito_np'] - (+titulo.totalizadores['pago_np'] + +titulo.totalizadores['cancelado_np']));
      total12 += (+titulo.totalizadores['inscrito_anterior_pr'] + +titulo.totalizadores['inscrito_pr'] - (+titulo.totalizadores['pago_pr'] + +titulo.totalizadores['cancelado_pr'])) +
        (+titulo.totalizadores['inscrito_anterior_np'] + +titulo.totalizadores['inscrito_np'] - (+titulo.totalizadores['pago_np'] + +titulo.totalizadores['cancelado_np']))

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo',
        ['inscrito_anterior_pr', 'inscrito_pr', 'pago_pr', 'cancelado_pr', 'inscrito_anterior_np', 'inscrito_np', 'liquidado_np', 'pago_np', 'cancelado_np']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          const linha_grupo = {
            orgao: grupo.grupo,
            anterior_pro: this.funcaoService.convertToBrNumber(grupo.totalizadores['inscrito_anterior_pr']),
            ate_pro: this.funcaoService.convertToBrNumber(grupo.totalizadores['inscrito_pr']),
            pagos_c: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago_pr']),
            cancelados_d: this.funcaoService.convertToBrNumber(grupo.totalizadores['cancelado_pr']),
            saldo_e: this.funcaoService.convertToBrNumber((+grupo.totalizadores['inscrito_anterior_pr'] + +grupo.totalizadores['inscrito_pr']) - (+grupo.totalizadores['pago_pr'] + +grupo.totalizadores['cancelado_pr'])),
            anterior_npro: this.funcaoService.convertToBrNumber(grupo.totalizadores['inscrito_anterior_np']),
            ate_npro: this.funcaoService.convertToBrNumber(grupo.totalizadores['inscrito_np']),
            liquidados_h: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado_np']),
            pagos_i: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago_np']),
            cancelados_j: this.funcaoService.convertToBrNumber(grupo.totalizadores['cancelado_np']),
            saldo_k: this.funcaoService.convertToBrNumber((+grupo.totalizadores['inscrito_anterior_np'] + +grupo.totalizadores['inscrito_np']) - (+grupo.totalizadores['pago_np'] + +grupo.totalizadores['cancelado_np'])),
            saldo_total: this.funcaoService.convertToBrNumber((+grupo.totalizadores['inscrito_anterior_pr'] + +grupo.totalizadores['inscrito_pr']) - (+grupo.totalizadores['pago_pr'] + +grupo.totalizadores['cancelado_pr']) +
              (+grupo.totalizadores['inscrito_anterior_np'] + +grupo.totalizadores['inscrito_np']) - (+grupo.totalizadores['pago_np'] + +grupo.totalizadores['cancelado_np'])),
          }
          listaExportar.push(linha_grupo)
        }

        for (const item of grupo.registros) {
          if (item.nome != '') {
            const linha_item = {
              orgao: item.item,
              anterior_pro: this.funcaoService.convertToBrNumber(item.inscrito_anterior_pr),
              ate_pro: this.funcaoService.convertToBrNumber(item.inscrito_pr),
              pagos_c: this.funcaoService.convertToBrNumber(item.pago_pr),
              cancelados_d: this.funcaoService.convertToBrNumber(item.cancelado_pr),
              saldo_e: this.funcaoService.convertToBrNumber((+item.inscrito_anterior_pr + +item.inscrito_pr) - (+item.pago_pr + +item.cancelado_pr)),
              anterior_npro: this.funcaoService.convertToBrNumber(item.inscrito_anterior_np),
              ate_npro: this.funcaoService.convertToBrNumber(item.inscrito_np),
              liquidados_h: this.funcaoService.convertToBrNumber(item.liquidado_np),
              pagos_i: this.funcaoService.convertToBrNumber(item.pago_np),
              cancelados_j: this.funcaoService.convertToBrNumber(item.cancelado_np),
              saldo_k: this.funcaoService.convertToBrNumber((+item.inscrito_anterior_np + +item.inscrito_np) - (+item.pago_np + +item.cancelado_np)),
              saldo_total: this.funcaoService.convertToBrNumber((+item.inscrito_anterior_pr + +item.inscrito_pr) - (+item.pago_pr + +item.cancelado_pr) +
                (+item.inscrito_anterior_np + +item.inscrito_np) - (+item.pago_np + +item.cancelado_np)),
            }
            listaExportar.push(linha_item)
          }
        }
      }
    }

    const total = {
      orgao: 'TOTAL (III) = (I + II)',
      anterior_pro: this.funcaoService.convertToBrNumber(total1),
      ate_pro: this.funcaoService.convertToBrNumber(total2),
      pagos_c: this.funcaoService.convertToBrNumber(total3),
      cancelados_d: this.funcaoService.convertToBrNumber(total4),
      saldo_e: this.funcaoService.convertToBrNumber(total5),
      anterior_npro: this.funcaoService.convertToBrNumber(total6),
      ate_npro: this.funcaoService.convertToBrNumber(total7),
      liquidados_h: this.funcaoService.convertToBrNumber(total8),
      pagos_i: this.funcaoService.convertToBrNumber(total9),
      cancelados_j: this.funcaoService.convertToBrNumber(total10),
      saldo_k: this.funcaoService.convertToBrNumber(total11),
      saldo_total: this.funcaoService.convertToBrNumber(total12),
    }
    listaExportar.push(total)

    return listaExportar
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'PODER / ÓRGÃO', coluna: 'orgao', bold: true },
      { titulo: 'INSCRITOS EM EXERCÍCIOS ANTERIORES PRO', coluna: 'anterior_pro' },
      { titulo: `INSCRITOS EM 31 DE DEZEMBRO DE ${this.exercicio.ano} PRO`, coluna: 'ate_pro' },
      { titulo: 'PAGOS (c)', coluna: 'pagos_c' },
      { titulo: 'CANCELADOS (d)', coluna: 'cancelados_d' },
      { titulo: 'SALDO (e=(a+b)', coluna: 'saldo_e' },
      { titulo: 'INSCRITOS EM EXERCÍCIOS ANTERIORES NP', coluna: 'anterior_npro' },
      { titulo: `INSCRITOS EM 31 DE DEZEMBRO DE ${this.exercicio.ano} NP`, coluna: 'ate_npro' },
      { titulo: 'LIQUIDADOS (h)', coluna: 'liquidados_h' },
      { titulo: 'PAGOS (i)', coluna: 'pagos_i' },
      { titulo: 'CANCELADOS (j)', coluna: 'cancelados_j' },
      { titulo: 'SALDO (k=(f+g)-(i+j))', coluna: 'saldo_k' },
      { titulo: 'SALDO TOTAL (l = e + k) ', coluna: 'saldo_total' }
    ];
    return colunasDefault;
  }

}
