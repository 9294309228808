import { RetencaoExtraService } from '../service/retencao-extra.service';
import { RetencaoService } from '../../liquidacao/service/retencao.service';
import { FichaExtraService, RetencaoRestoService } from 'contabil-lib';
import { DatePipe } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContratoService, EmpenhoService, RecursoService } from 'administrativo-lib';
import {
  Contrato, EddyAutoComplete, EmpenhoExtra, Favorecido, FavorecidoService, FichaExtra, FuncaoService, GlobalService, Login, Recurso,
  Relatorio,
  Retencao,
  RetencaoExtra,
  RetencaoResto
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmpenhoExtraService } from '../service/empenho-extra.service';
import * as toastr from 'toastr';
import { tsXLXS } from 'ts-xlsx-export';

export class RetencaoAgrupado {
  tipo: 'O' | 'R' | 'E'
  empenho_numero: number
  liq_parcela: number
  data: Date
  executora_codigo: string
  documento: string
  pis: string
  cpf_cnpj: string
  favorecido_nome: string
  valor: number
  valor_retencao: number
  ficha_numero: number
}
@Component({
  selector: 'lib-empenho-extra-rpt',
  templateUrl: './empenho-extra-rpt.component.html'
})
export class EmpenhoExtraRptComponent implements OnInit, OnDestroy {

  login: Login = new Login();
  recurso: Recurso;
  aplicacao: Recurso;
  variavel: Recurso;
  contrato: Contrato;
  ficha: FichaExtra;
  pfisica: Favorecido;
  pjuridica: Favorecido;
  fornecedor: Favorecido;
  filtroRecurso: boolean;
  filtroAplicacao: boolean;
  filtroVariavel: boolean;
  filtroContrato: boolean;
  agruparFornecedor: boolean;
  filtroFicha: boolean;
  filtroPFisica: boolean;
  filtroPJuridica: boolean
  filtroFornecedor: boolean;
  public documento: boolean = false;
  fornecedoresAgrupados = []

  public selectedOrdem: string;
  public selectUsuario: boolean;

  public ptBR: any;

  public listaRelatorio: Array<any>;
  public contratoAutoComplete: EddyAutoComplete<Contrato>;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public variavelAutoComplete: EddyAutoComplete<Recurso>;
  public fichaExtraAutoComplete: EddyAutoComplete<FichaExtra>;
  public pessoaFisicaAutoComplete: EddyAutoComplete<Favorecido>;
  public pessoaJuridicaAutoComplete: EddyAutoComplete<Favorecido>;
  public fornecedorAutoComplete: EddyAutoComplete<Favorecido>;

  public relatorio = '1';
  public dataInicial: Date;
  public dataFinal: Date;
  public datepipe = new DatePipe('pt');
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    protected injector: Injector,
    protected funcaoService: FuncaoService,
    protected messageService: MessageService,
    protected globalService: GlobalService,
    protected recursoService: RecursoService,
    protected contratoService: ContratoService,
    protected empenhoService: EmpenhoService,
    protected empenhoExtraService: EmpenhoExtraService,
    protected fichaExtraService: FichaExtraService,
    protected favorecidoService: FavorecidoService,
    protected retencaoService: RetencaoService,
    protected retencaoExtraService: RetencaoExtraService,
    protected retencaoRestoService: RetencaoRestoService) {
  }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.login = GlobalService.obterSessaoLogin();
    this.selectedOrdem = 'ord1';
    this.selectUsuario = false;
    this.dataInicial = new Date(this.login.exercicio.ano, 0, 1);
    this.dataFinal = new Date();
    this.listaRelatorio = [];
    this.listaRelatorio.push({ label: 'EMPENHOS EXTRAS ORÇAMENTÁRIOS', value: 1 });
    this.listaRelatorio.push({ label: 'EMPENHOS EXTRAS ANULADOS', value: 2 });
    this.listaRelatorio.push({ label: 'EMPENHOS EXTRAS EM ABERTO', value: 3 });
    this.listaRelatorio.push({ label: 'RETENÇÕES - PF', value: 4 });
    this.listaRelatorio.push({ label: 'RETENÇÕES - PJ', value: 5 });
    this.carregarAutoCompletes();
    let interval = setInterval(() => { new GlobalService().calendarMascara(); clearInterval(interval) }, 1000)
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async gerarRelatorio() {
    let titulo = '';
    const parametros = {};
    const parametrosOrcamentario = {}
    const parametrosResto = {}
    const parametrosExtra = {}

    if (!this.dataInicial || !this.dataFinal) {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }
    let relatorioRetencao = this.relatorio === '4' || this.relatorio === '5' ? true : false
    let dtInicial = this.funcaoService.converteDataSQL(this.dataInicial)
    let dtFinal = this.funcaoService.converteDataSQL(this.dataFinal)

    //Filtro data inicial
    parametros['data_empenho$ge'] = dtInicial
    parametrosOrcamentario['liquidacao.data_liquidacao$ge'] = dtInicial
    parametrosResto['liquidacao.data_liquidacao$ge'] = dtInicial
    parametrosExtra['empenho_extra.data_empenho$ge'] = dtInicial

    //Filtro data final
    parametros['data_empenho$le'] = dtFinal
    parametrosOrcamentario['liquidacao.data_liquidacao$le'] = dtFinal
    parametrosResto['liquidacao.data_liquidacao$le'] = dtFinal
    parametrosExtra['empenho_extra.data_empenho$le'] = dtFinal

    // Filtro fonte de recurso
    if (this.filtroRecurso) {
      parametros['ficha.recurso.id'] = this.recurso.id;
      parametrosOrcamentario['ficha.recurso.id'] = this.recurso.id;
      parametrosResto['ficha.recurso.id'] = this.recurso.id;
      parametrosExtra['ficha.recurso.id'] = this.recurso.id;
    }
    //Filtro aplicação de recurso
    if (this.filtroAplicacao) {
      parametros['ficha.aplicacao.id'] = this.aplicacao.id;
      parametrosOrcamentario['ficha.aplicacao.id'] = this.aplicacao.id;
      parametrosResto['ficha.aplicacao.id'] = this.aplicacao.id;
      parametrosExtra['ficha.aplicacao.id'] = this.aplicacao.id;
    }
    //Filtro aplicação variável
    if (this.filtroVariavel) {
      parametros['ficha.aplicacao_variavel.id'] = this.variavel.id;
      parametrosOrcamentario['ficha.aplicacao_variavel.id'] = this.variavel.id;
      parametrosResto['ficha.aplicacao_variavel.id'] = this.variavel.id;
      parametrosExtra['ficha.aplicacao_variavel.id'] = this.variavel.id;
    }
    //Filtro número do contrato
    if (this.filtroContrato) {
      parametros['contrato.numero'] = this.contrato.numero;
      parametrosOrcamentario['liquidacao.empenho.contrato.numero'] = this.contrato.numero;
      parametrosResto['liquidacao.empenho.contrato.numero'] = this.contrato.numero;
      parametrosExtra['empenho_extra.contrato.numero'] = this.contrato.numero;
    }
    //Filtro número da ficha
    if (this.filtroFicha) {
      parametros['ficha.id'] = this.ficha.id
      parametrosOrcamentario['ficha.id'] = this.ficha.id
      parametrosResto['ficha.id'] = this.ficha.id
      parametrosExtra['ficha.id'] = this.ficha.id
    }
    //Filtro pessoa física
    if (this.filtroPFisica) {
      parametros['favorecido.id'] = this.pfisica.id
      parametrosOrcamentario['liquidacao.empenho.favorecido.id'] = this.pfisica.id
      parametrosResto['liquidacao.empenho.favorecido.id'] = this.pfisica.id
      parametrosExtra['empenho_extra.favorecido.id'] = this.pfisica.id
    }
    //Filtro pessoa jurídica
    if (this.filtroPJuridica) {
      parametros['favorecido.id'] = this.pjuridica.id
      parametrosOrcamentario['liquidacao.empenho.favorecido.id'] = this.pjuridica.id
      parametrosResto['liquidacao.empenho.favorecido.id'] = this.pjuridica.id
      parametrosExtra['empenho_extra.favorecido.id'] = this.pjuridica.id
    }
    // Filtro por fornecedor
    if (this.filtroFornecedor) {
      parametros['favorecido.id'] = this.fornecedor.id;
      parametrosOrcamentario['liquidacao.empenho.favorecido.id'] = this.fornecedor.id;
      parametrosResto['liquidacao.empenho.favorecido.id'] = this.fornecedor.id;
      parametrosExtra['empenho_extra.favorecido.id'] = this.fornecedor.id;
    }
    //Filtro default Exercicio
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametrosOrcamentario['liquidacao.exercicio.id'] = this.login.exercicio.id;
    parametrosResto['liquidacao.exercicio'] = this.login.exercicio.id;
    parametrosExtra['empenho_extra.exercicio'] = this.login.exercicio.id;

    //Filtro default Orgão
    parametros['orgao.id'] = this.login.orgao.id;
    parametrosOrcamentario['liquidacao.orgao.id'] = this.login.orgao.id;
    parametrosResto['liquidacao.empenho.orgao'] = this.login.orgao.id;
    parametrosExtra['empenho_extra.orgao'] = this.login.orgao.id;

    //****ORDENADORES****
    if (this.selectedOrdem === 'ord1') {
      parametros['orderBy'] = this.agruparFornecedor ? 'favorecido.nome,data_empenho' : 'data_empenho';

    } else if (this.selectedOrdem === 'ord2') {
      parametros['orderBy'] = this.agruparFornecedor ? 'favorecido.nome,numero' : 'numero';

    } else if (this.selectedOrdem === 'ord3') {
      parametros['orderBy'] = 'favorecido.nome,data_empenho';

    } else if (this.selectedOrdem === 'ord4') {
      parametros['orderBy'] = 'ficha.numero,data_empenho'
    }

    // ****RELACIONAMENTOS****
    parametros['relations'] = 'favorecido,ficha,ficha.recurso,ficha.aplicacao,ficha.aplicacao_variavel';
    parametrosOrcamentario['relations'] = 'ficha.recurso,ficha.aplicacao,liquidacao.exercicio,liquidacao.orgao,liquidacao.usuario_cadastro,liquidacao.empenho.favorecido.tipo,liquidacao.empenho.ficha.executora'
    parametrosResto['relations'] = 'ficha.recurso,ficha.aplicacao,liquidacao.empenho.favorecido.tipo,liquidacao.exercicio,liquidacao.empenho.orgao,liquidacao.empenho.contrato,liquidacao.usuario_cadastro'
    parametrosExtra['relations'] = 'ficha.recurso,ficha.aplicacao,empenho_extra.favorecido.tipo,empenho_extra.exercicio,empenho_extra.orgao,empenho_extra.contrato,empenho_extra.usuario_cadastro'

    // ****TITULO DO RELATÓRIO E PARAMETRO DE SELEÇÃO DO TIPO DE RELATÓRIO****
    if (this.relatorio === '1') {
      titulo = 'EMPENHOS EXTRA-ORÇAMENTÁRIOS';
      parametros['especie$in'] = 'EME,EEA'

    } else if (this.relatorio === '2') {
      titulo = 'EMPENHOS EXTRA-ORÇAMENTÁRIOS ANULADOS';
      parametros['especie'] = 'EEA';

    } else if (this.relatorio === '3') {
      titulo = 'EMPENHOS EXTRA-ORÇAMENTÁRIOS EM ABERTO';
      parametros['especie$in'] = 'EME,SEE';
      parametros['anulado_total'] = false;

    } else if (this.relatorio === '4') {
      titulo = 'LISTAGEM RETENÇÕES: PESSOA FÍSICA';
      parametrosOrcamentario['liquidacao.empenho.favorecido.tipo.nome'] = 'CPF - PESSOA FÍSICA'
      parametrosResto['liquidacao.empenho.favorecido.tipo.nome'] = 'CPF - PESSOA FÍSICA'
      parametrosExtra['empenho_extra.favorecido.tipo.nome'] = 'CPF - PESSOA FÍSICA'

    } else if (this.relatorio === '5') {
      titulo = 'LISTAGEM RETENÇÕES: PESSOA JURÍDICA';
      parametrosOrcamentario['liquidacao.empenho.favorecido.tipo.nome'] = 'CNPJ - PESSOA JURÍDICA'
      parametrosResto['liquidacao.empenho.favorecido.tipo.nome'] = 'CNPJ - PESSOA JURÍDICA'
      parametrosExtra['empenho_extra.favorecido.tipo.nome'] = 'CNPJ - PESSOA JURÍDICA'
    }

    if (!relatorioRetencao) {
      this.empenhoExtraService.extendido(1, -1, parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((lista) => {
          if (lista.content.length == 0) {
            toastr.info('Sem itens para imprimir');
            return;
          }

          Relatorio.imprimirPersonalizado(titulo, this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            this.selecionarMontagem(lista.content),
            'portrait', titulo,
            this.layout(), false);
        });
    } else {
      let todasRetencoes: RetencaoAgrupado[] = []

      let retencoes: Retencao[] = await (await this.retencaoService.extendido(1, -1, parametrosOrcamentario).toPromise()).content

      let retencoesResto: RetencaoResto[] = await (await this.retencaoRestoService.extendido(1, -1, parametrosResto).toPromise()).content

      let retencoesExtra: RetencaoExtra[] = await (await this.retencaoExtraService.extendido(1, -1, parametrosExtra).toPromise()).content

      if (retencoes.length === 0 && retencoesExtra.length === 0 && retencoesResto.length === 0) {
        toastr.info('Sem itens para imprimir');
        return;
      }

      todasRetencoes = await this.aglutinadorRetencoes(retencoes, retencoesResto, retencoesExtra)

      if (this.selectedOrdem) {
        await this.ordenarRetencoes(todasRetencoes)
      }

      Relatorio.imprimirPersonalizado(titulo, this.login.usuario.nome, this.login.usuario.sobrenome,
        this.login.orgao.nome, this.login.brasao,
        this.selecionarMontagem(null, todasRetencoes),
        'landscape', titulo,
        this.layout(), false, null, null, `REFERÊNCIA: ${this.datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} À ${this.datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`);
    }
  }

  private async ordenarRetencoes(retencoes: RetencaoAgrupado[]) {
    if (this.agruparFornecedor) {
      retencoes.sort((a, b) => {
        if ((a.favorecido_nome > b.favorecido_nome)) return 1
        if (a.favorecido_nome < b.favorecido_nome) return -1
        return 0
      });

      this.fornecedoresAgrupados = this.funcaoService.agrupar(retencoes, ['cpf_cnpj', 'favorecido_nome'], ['valor', 'valor_retencao'])

      this.fornecedoresAgrupados.forEach(item => {

        if (this.selectedOrdem === 'ord1') {
          item['registros'].sort((a: RetencaoAgrupado, b: RetencaoAgrupado) => {
            if (a.data > b.data) return 1
            if (a.data < b.data) return -1
            return 0
          });
        } else if (this.selectedOrdem === 'ord2') {
          item['registros'].sort((a: RetencaoAgrupado, b: RetencaoAgrupado) => {
            if ((a.empenho_numero > b.empenho_numero)) return 1
            if (a.empenho_numero < b.empenho_numero) return -1
            return 0
          });

        } else if (this.selectedOrdem === 'ord4') {
          item['registros'].sort((a: RetencaoAgrupado, b: RetencaoAgrupado) => {
            if ((a.ficha_numero > b.ficha_numero)) return 1
            if (a.ficha_numero < b.ficha_numero) return -1
            return 0
          });
        }
      });

    } else {

      if (this.selectedOrdem === 'ord1') {
        retencoes.sort((a, b) => {
          if ((a.data > b.data)) return 1
          if (a.data < b.data) return -1
          return 0
        });
      } else if (this.selectedOrdem === 'ord2') {
        retencoes.sort((a, b) => {
          if ((a.empenho_numero > b.empenho_numero)) return 1
          if (a.empenho_numero < b.empenho_numero) return -1
          return 0
        });

      } else if (this.selectedOrdem === 'ord3') {
        retencoes.sort((a, b) => {
          if ((a.favorecido_nome > b.favorecido_nome)) return 1
          if (a.favorecido_nome < b.favorecido_nome) return -1
          return 0
        });

      } else if (this.selectedOrdem === 'ord4') {
        retencoes.sort((a, b) => {
          if ((a.ficha_numero > b.ficha_numero)) return 1
          if (a.ficha_numero < b.ficha_numero) return -1
          return 0
        });
      }
    }
  }

  private async aglutinadorRetencoes(retencoes: Retencao[], retencoesResto: RetencaoResto[], retencoesExtra: RetencaoExtra[]): Promise<RetencaoAgrupado[]> {
    let todasRetencoes = []
    let retencao: RetencaoAgrupado

    retencoes.forEach(r => {
      retencao = {
        tipo: 'O',
        empenho_numero: r.liquidacao?.empenho?.numero,
        liq_parcela: r.liquidacao?.parcela,
        data: r.liquidacao?.data_liquidacao,
        executora_codigo: r.liquidacao?.empenho?.ficha?.executora?.codigo,
        documento: r.liquidacao?.documento,
        pis: r.liquidacao?.empenho?.favorecido?.pis,
        cpf_cnpj: r.liquidacao?.empenho?.favorecido?.cpf_cnpj,
        favorecido_nome: r.liquidacao?.empenho?.favorecido?.nome,
        valor: r.liquidacao?.valor_liquidado,
        valor_retencao: r.valor_retido,
        ficha_numero: r.ficha.numero
      };

      todasRetencoes.push(retencao)
    });

    retencoesResto.forEach(r => {
      retencao = {
        tipo: 'R',
        empenho_numero: r.liquidacao?.empenho?.numero,
        liq_parcela: r.liquidacao?.parcela,
        data: r.liquidacao?.data_liquidacao,
        executora_codigo: r.liquidacao.empenho.executora,
        documento: r.liquidacao?.documento,
        pis: r.liquidacao?.empenho?.favorecido?.pis,
        cpf_cnpj: r.liquidacao?.empenho?.favorecido?.cpf_cnpj,
        favorecido_nome: r.liquidacao?.empenho?.favorecido?.nome,
        valor: r.liquidacao?.valor_liquidado,
        valor_retencao: r.valor_retido,
        ficha_numero: r.ficha.numero
      };

      todasRetencoes.push(retencao)
    });

    retencoesExtra.forEach(r => {
      retencao = {
        tipo: 'E',
        empenho_numero: r.empenho_extra?.numero,
        liq_parcela: r.empenho_extra?.parcela,
        data: r.empenho_extra?.data_empenho,
        executora_codigo: '',
        documento: r.empenho_extra?.documento,
        pis: r.empenho_extra?.favorecido?.pis,
        cpf_cnpj: r.empenho_extra?.favorecido?.cpf_cnpj,
        favorecido_nome: r.empenho_extra?.favorecido?.nome,
        valor: r.empenho_extra?.valor_empenho,
        valor_retencao: r.valor_retido,
        ficha_numero: r.ficha.numero
      };

      todasRetencoes.push(retencao)
    });

    return todasRetencoes
  }

  private layout() {
    return {
      linhas: {
        hLineWidth() {
          return 1;
        },
        vLineWidth() {
          return 1;
        },
        hLineColor() {
          return 'black';
        },
        paddingLeft() {
          return 3;
        },
        paddingRight() {
          return 3;
        }
      }
    };
  }

  private selecionarMontagem(listaEmpenhoExtra?: EmpenhoExtra[], listaRetencao?: RetencaoAgrupado[]) {
    if (this.relatorio === '1' || this.relatorio === '2' || this.relatorio === '3') {
      return this.agruparFornecedor ? this.montarConteudoFavorecido(listaEmpenhoExtra, this.relatorio) : this.montarConteudo(listaEmpenhoExtra, this.relatorio)
    } else if (this.relatorio === '4' || this.relatorio === '5') {
      return this.montarRetencao(listaRetencao)
    }
  }

  private montarRetencao(lista: RetencaoAgrupado[]) {
    let tabela = []
    let totalVlLiq = lista.reduce((acc, retencao) => acc + +retencao.valor, 0)
    let totalRetido = lista.reduce((acc, retencao) => acc + +retencao.valor_retencao, 0)

    tabela.push([
      { text: 'DATA', fontSize: 10, bold: true, border: [true, true, true, true], alignment: 'center' },
      { text: 'EMPENHO', fontSize: 10, bold: true, border: [true, true, true, true], alignment: 'center' },
      { text: 'UNIDADE', fontSize: 10, bold: true, border: [true, true, true, true], alignment: 'center' },
      { text: 'DOCUMENTO', fontSize: 10, bold: true, border: [true, true, true, true], alignment: 'center' },
      { text: 'PIS/INSS', fontSize: 10, bold: true, border: [true, true, true, true], alignment: 'center' },
      { text: 'CNPJ/CPF', fontSize: 10, bold: true, border: [true, true, true, true], alignment: 'center' },
      { text: 'FORNECEDOR', fontSize: 10, bold: true, border: [true, true, true, true], alignment: 'center' },
      { text: 'VALOR LIQ.', fontSize: 10, bold: true, border: [true, true, true, true], alignment: 'center' },
      { text: 'VALOR RETIDO', fontSize: 10, bold: true, border: [true, true, true, true], alignment: 'center' },
    ]);

    if (this.agruparFornecedor) {

      this.fornecedoresAgrupados.forEach((item) => {

        item.registros.forEach((retencao: RetencaoAgrupado) => {

          let empenhoParcelaTipo = `${retencao?.empenho_numero}/${retencao.liq_parcela} - ${retencao?.tipo}`

          tabela.push([
            { text: `${this.funcaoService.converteDataBR(retencao.data)}`, fontSize: 8, border: [true, false, false, false], alignment: 'center' },
            { text: `${empenhoParcelaTipo}`, fontSize: 8, border: [false, false, false, false], alignment: 'right' },
            { text: `${retencao?.executora_codigo || ''}`, fontSize: 8, border: [false, false, false, false], alignment: 'right' },
            { text: `${retencao?.documento?.replace('              ', '') || ''}`, fontSize: 8, border: [false, false, false, false] },
            { text: `${retencao.pis || ''}`, fontSize: 8, border: [false, false, false, false], alignment: 'right' },
            { text: `${retencao.cpf_cnpj}`, fontSize: 8, border: [false, false, false, false], alignment: 'right' },
            { text: `${retencao.favorecido_nome}`, fontSize: 8, border: [false, false, false, false] },
            { text: `${this.funcaoService.convertToBrNumber(retencao.valor, 2, true)}`, fontSize: 8, border: [false, false, false, false], alignment: 'right' },
            { text: `${this.funcaoService.convertToBrNumber(retencao.valor_retencao, 2, true)}`, fontSize: 8, border: [false, false, true, false], alignment: 'right' },
          ]);
        });

        tabela.push([
          { text: 'Total', fontSize: 10, bold: true, border: [true, false, false, true], alignment: 'right', colSpan: 7 },
          '', '', '', '', '', '',
          { text: `${this.funcaoService.convertToBrNumber(item.totalizadores['valor'], 2, true)}`, fontSize: 10, bold: true, border: [false, false, false, true], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber(item.totalizadores['valor_retencao'], 2, true)}`, fontSize: 10, bold: true, border: [false, false, true, true], alignment: 'right' }
        ]);
      });

    } else {
      lista.forEach((retencao: RetencaoAgrupado) => {
        let empenhoParcelaTipo = `${retencao?.empenho_numero}/${retencao.liq_parcela} - ${retencao?.tipo}`

        tabela.push([
          { text: `${this.funcaoService.converteDataBR(retencao.data)}`, fontSize: 8, border: [true, false, false, false], alignment: 'center' },
          { text: `${empenhoParcelaTipo}`, fontSize: 8, border: [false, false, false, false], alignment: 'right' },
          { text: `${retencao?.executora_codigo || ''}`, fontSize: 8, border: [false, false, false, false], alignment: 'right' },
          { text: `${retencao?.documento?.replace('              ', '') || ''}`, fontSize: 8, border: [false, false, false, false] },
          { text: `${retencao.pis || ''}`, fontSize: 8, border: [false, false, false, false], alignment: 'right' },
          { text: `${retencao.cpf_cnpj}`, fontSize: 8, border: [false, false, false, false], alignment: 'right' },
          { text: `${retencao.favorecido_nome}`, fontSize: 8, border: [false, false, false, false] },
          { text: `${this.funcaoService.convertToBrNumber(retencao.valor, 2, true)}`, fontSize: 8, border: [false, false, false, false], alignment: 'right' },
          { text: `${this.funcaoService.convertToBrNumber(retencao.valor_retencao, 2, true)}`, fontSize: 8, border: [false, false, true, false], alignment: 'right' },
        ]);
      });

      tabela.push([
        { text: '', border: [true, false, true, true], colSpan: 9 },
        '', '', '', '', '', '', '', ''
      ]);
    }

    tabela.push([
      { text: 'Total geral: ', fontSize: 10, bold: true, border: [true, false, false, true], alignment: 'right', colSpan: 7 },
      '', '', '', '', '', '',
      { text: `${this.funcaoService.convertToBrNumber(totalVlLiq, 2, true)}`, fontSize: 10, bold: true, border: [false, false, false, true], alignment: 'right' },
      { text: `${this.funcaoService.convertToBrNumber(totalRetido, 2, true)}`, fontSize: 10, bold: true, border: [false, false, true, true], alignment: 'right' }
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto'],
        body: tabela
      }
    }];
  }

  private montarConteudo(lista: any[], relatorio: string) {
    // monta o cabecalho
    const registros: {}[] = [
      [
        {
          text: `REFERÊNCIA: ${this.datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${this.datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
          alignment: 'center', fontSize: 10, lineHeight: 1.5, colSpan: 7, border: [false, false, false, false]
        }, '', '', '', '', '', ''
      ],
      [
        { text: 'EMPENHO Nº', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
        { text: 'FICHA Nº', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
        { text: 'DATA', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
        { text: 'FAVORECIDO', fontSize: 8, alignment: 'center', bold: true, colSpan: this.documento && relatorio === '3' ? 0 : 2, margin: [0, 10, 0, 10] },
        this.documento && relatorio === '3' ? { text: 'DOCUMENTO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] } : '',
        { text: 'RECURSO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
        { text: 'VALOR', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      ],
    ];

    let total = 0;
    for (const item of lista) {
      let valor_empenho = 0;
      let em_aberto = false;

      if (relatorio === '3') {
        if (item.parcela == 0) {
          //Se a parcela for 0, temos que confirmar o quanto foi parcelado
          valor_empenho = +(+item.valor_empenho - +item.total_parcelado).toFixed(2);
          valor_empenho = +(valor_empenho + +item.total_anulado_empenho - +item.total_pago_empenho).toFixed(2);
        } else {
          //Se for parcela
          valor_empenho = +(+(+item.valor_empenho + +item.total_anulado_empenho).toFixed(2) - +item.total_pago_empenho).toFixed(2);
        }

        if (+valor_empenho > 0) {
          total += valor_empenho;
          em_aberto = true;
        }
      } else {
        valor_empenho = +item.valor_empenho;
        total += +item.valor_empenho;
        em_aberto = true;
      }
      const linha = [
        { text: `${item.numero}${item.parcela ? '/' + item.parcela : ''}`, alignment: 'center', fontSize: 6, border: [true, false, true, false] },
        { text: item.ficha.numero, fontSize: 6, alignment: 'center', border: [true, false, true, false] },
        { text: this.funcaoService.converteDataBR(item.data_empenho), alignment: 'center', fontSize: 6, border: [true, false, true, false] },
        { text: item.favorecido.nome, fontSize: 6, colSpan: this.documento && relatorio === '3' ? 0 : 2, border: [true, false, true, false] },
        this.documento && relatorio === '3' ? { text: item.processo, alignment: 'center', fontSize: 6, border: [true, false, true, false] } : '',
        { text: `${item.ficha.recurso.codigo}${item.ficha.aplicacao.codigo}${item.ficha.aplicacao_variavel ? item.ficha.aplicacao_variavel.variavel : ''}`, alignment: 'center', fontSize: 6, border: [true, false, true, false] },
        { text: this.funcaoService.convertToBrNumber(valor_empenho), alignment: 'right', fontSize: 6, border: [true, false, true, false] },
      ];

      if (relatorio === '3' && +valor_empenho > 0) {
        if (em_aberto) {
          registros.push(linha);
        }
      } else if (relatorio === '1' || relatorio === '2') {
        if (em_aberto) {
          registros.push(linha);
        }
      }
    }

    registros.push([
      {
        text: 'TOTAL', fontSize: 6, bold: true, colSpan: 6
      },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: this.funcaoService.convertToBrNumber(total), alignment: 'right', fontSize: 6, bold: true }
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [50, 35, 'auto', '*', 'auto', 40, 90],
        body: registros
      }
    }];
  }


  private montarConteudoFavorecido(lista: any[], relatorio: string) {

    // monta o cabecalho
    let datepipe = new DatePipe('pt');
    const registros: {}[] = [
      [
        {
          text: `REFERÊNCIA: ${datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
          alignment: 'center', fontSize: 10, lineHeight: 1.5, colSpan: 7, border: [false, false, false, false]
        }, '', '', '', '', '', ''
      ],
      [
        { text: 'EMPENHO Nº', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
        { text: 'FICHA Nº', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
        { text: 'DATA', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
        { text: 'HISTÓRICO', fontSize: 8, alignment: 'center', bold: true, colSpan: this.documento && relatorio === '3' ? 0 : 2, margin: [0, 10, 0, 10] },
        this.documento && relatorio === '3' ? { text: 'DOCUMENTO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] } : '',
        { text: 'RECURSO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
        { text: 'VALOR', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      ],
    ];
    let grupos = this.funcaoService.agrupar(lista, ['favorecido.nome']);
    let total = 0;
    for (const grupo of grupos) {
      const possui_em_abertos = grupo.registros.filter(empenho => +empenho['valor_empenho'] - +empenho['total_pago'] > 0).length > 0;
      if (!possui_em_abertos) continue;

      registros.push([
        { text: `FAVORECIDO: ${grupo.grupo['favorecido.nome']}`, fontSize: 8, alignment: 'left', bold: true, colSpan: 7 },
        '', '', '', '', '', ''
      ]);
      let soma = 0;
      for (const item of grupo.registros) {
        let valor_empenho = 0;
        let em_aberto = false;
        if (relatorio === '3') {
          if (item.parcela == 0) {
            //Se a parcela for 0, temos que confirmar o quanto foi parcelado
            valor_empenho = +(+item.valor_empenho - +item.total_parcelado).toFixed(2);
            valor_empenho = +(valor_empenho + +item.total_anulado_empenho - +item.total_pago_empenho).toFixed(2);
          } else {
            //Se for parcela
            valor_empenho = +(+(+item.valor_empenho + +item.total_anulado_empenho).toFixed(2) - +item.total_pago_empenho).toFixed(2);
          }

          if (+valor_empenho > 0) {
            total += valor_empenho;
            soma += valor_empenho;
            em_aberto = true;
          }
          // if ((+item.valor_empenho - +item.total_pago) > 0) {
          //   em_aberto = true;
          //   valor_empenho = +item.valor_empenho - +item.total_pago;
          //   total += +item.valor_empenho - +item.total_pago;
          //   soma += +item.valor_empenho - +item.total_pago;
          // }
        } else {
          em_aberto = true;
          valor_empenho = +item.valor_empenho;
          total += +item.valor_empenho;
          soma += +item.valor_empenho;
        }
        const linha = [
          { text: `${item.numero}${item.parcela ? '/' + item.parcela : ''}`, alignment: 'center', fontSize: 6, border: [true, false, true, false] },
          { text: item.ficha.numero, fontSize: 6, alignment: 'center', border: [true, false, true, false] },
          { text: this.funcaoService.converteDataBR(item.data_empenho), alignment: 'center', fontSize: 6, border: [true, false, true, false] },
          { text: item.historico, fontSize: 6, colSpan: this.documento && relatorio === '3' ? 0 : 2, border: [true, false, true, false] },
          this.documento && relatorio === '3' ? { text: item.processo, alignment: 'center', fontSize: 6, border: [true, false, true, false] } : '',
          { text: `${item.ficha.recurso.codigo}${item.ficha.aplicacao.codigo}${item.ficha.aplicacao_variavel ? item.ficha.aplicacao_variavel.codigo : ''}`, alignment: 'center', fontSize: 6, border: [true, false, true, false] },
          { text: this.funcaoService.convertToBrNumber(item.valor_empenho), alignment: 'right', fontSize: 6, border: [true, false, true, false] },
        ];
        if (relatorio === '3' && valor_empenho > 0) {
          if (em_aberto) {
            registros.push(linha);
          }
        } else {
          if (em_aberto) {
            registros.push(linha);
          }
        }
      }
      registros.push([
        {
          text: 'SOMA', fontSize: 6, bold: true, colSpan: 6
        },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: this.funcaoService.convertToBrNumber(soma), alignment: 'right', fontSize: 6, bold: true }
      ]);
    }

    registros.push([
      {
        text: 'TOTAL', fontSize: 6, bold: true, colSpan: 6
      },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: this.funcaoService.convertToBrNumber(total), alignment: 'right', fontSize: 6, bold: true }
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [50, 35, 'auto', '*', 'auto', 40, 90],
        body: registros
      }
    }];
  }

  private carregarAutoCompletes() {

    // autocomplete para recurso
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para aplicação variável
    this.variavelAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para contrato
    this.contratoAutoComplete = new EddyAutoComplete(null, this.contratoService,
      'id', ['numero', 'favorecido.nome'], {
      orgao_id: this.login.orgao.id, relations: 'favorecido',
      orderBy: 'favorecido.nome'
    }, { number: ['numero'], text: ['favorecido.nome'] }
    );

    // autocomplete para ficha extra
    this.fichaExtraAutoComplete = new EddyAutoComplete(null, this.fichaExtraService, 'id', ['numero', 'nome'], { orgao_id: this.login.orgao.id, exercicio_id: this.login.exercicio.id, orderBy: 'nome' }, { text: ['nome'], number: ['numero'] })

    // autocomplete para pessoa fisica
    this.pessoaFisicaAutoComplete = new EddyAutoComplete(null, this.favorecidoService, 'id', ['cpf_cnpj', 'nome'], { 'tipo.nome': 'CPF - PESSOA FÍSICA', orderBy: 'nome', relations: 'tipo' }, { text: ['cpf_cnpj', 'nome'] })

    // autocomplete para pessoa juridica
    this.pessoaJuridicaAutoComplete = new EddyAutoComplete(null, this.favorecidoService, 'id', ['cpf_cnpj', 'nome'], { 'tipo.nome': 'CNPJ - PESSOA JURÍDICA', orderBy: 'nome', relations: 'tipo' }, { text: ['cpf_cnpj', 'nome'] })

    // autocomplete para fornecedor
    this.fornecedorAutoComplete = new EddyAutoComplete(null, this.favorecidoService, 'id', ['cpf_cnpj', 'nome'], { orderBy: 'nome', relations: 'tipo' }, { text: ['cpf_cnpj', 'nome'] });
  }

  public async exportarXlxs(relatorio: any) {
    let titulo = '';
    const parametros = {};
    const parametrosOrcamentario = {};
    const parametrosResto = {};
    const parametrosExtra = {};

    if (!this.dataInicial || !this.dataFinal) {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }
    let relatorioRetencao = this.relatorio === '4' || this.relatorio === '5' ? true : false;
    let dtInicial = this.funcaoService.converteDataSQL(this.dataInicial);
    let dtFinal = this.funcaoService.converteDataSQL(this.dataFinal);

    //Filtro data inicial
    parametros['data_empenho$ge'] = dtInicial;
    parametrosOrcamentario['liquidacao.data_liquidacao$ge'] = dtInicial;
    parametrosResto['liquidacao.data_liquidacao$ge'] = dtInicial;
    parametrosExtra['empenho_extra.data_empenho$ge'] = dtInicial;

    //Filtro data final
    parametros['data_empenho$le'] = dtFinal;
    parametrosOrcamentario['liquidacao.data_liquidacao$le'] = dtFinal;
    parametrosResto['liquidacao.data_liquidacao$le'] = dtFinal;
    parametrosExtra['empenho_extra.data_empenho$le'] = dtFinal;

    // Filtro fonte de recurso
    if (this.filtroRecurso) {
      parametros['ficha.recurso.id'] = this.recurso.id;
      parametrosOrcamentario['ficha.recurso.id'] = this.recurso.id;
      parametrosResto['ficha.recurso.id'] = this.recurso.id;
      parametrosExtra['ficha.recurso.id'] = this.recurso.id;
    }
    //Filtro aplicação de recurso
    if (this.filtroAplicacao) {
      parametros['ficha.aplicacao.id'] = this.aplicacao.id;
      parametrosOrcamentario['ficha.aplicacao.id'] = this.aplicacao.id;
      parametrosResto['ficha.aplicacao.id'] = this.aplicacao.id;
      parametrosExtra['ficha.aplicacao.id'] = this.aplicacao.id;
    }
    //Filtro aplicação variável
    if (this.filtroVariavel) {
      parametros['ficha.aplicacao_variavel.id'] = this.variavel.id;
      parametrosOrcamentario['ficha.aplicacao_variavel.id'] = this.variavel.id;
      parametrosResto['ficha.aplicacao_variavel.id'] = this.variavel.id;
      parametrosExtra['ficha.aplicacao_variavel.id'] = this.variavel.id;
    }
    //Filtro número do contrato
    if (this.filtroContrato) {
      parametros['contrato.numero'] = this.contrato.numero;
      parametrosOrcamentario['liquidacao.empenho.contrato.numero'] = this.contrato.numero;
      parametrosResto['liquidacao.empenho.contrato.numero'] = this.contrato.numero;
      parametrosExtra['empenho_extra.contrato.numero'] = this.contrato.numero;
    }
    //Filtro número da ficha
    if (this.filtroFicha) {
      parametros['ficha.id'] = this.ficha.id;
      parametrosOrcamentario['ficha.id'] = this.ficha.id;
      parametrosResto['ficha.id'] = this.ficha.id;
      parametrosExtra['ficha.id'] = this.ficha.id;
    }
    //Filtro pessoa física
    if (this.filtroPFisica) {
      parametros['favorecido.id'] = this.pfisica.id;
      parametrosOrcamentario['liquidacao.empenho.favorecido.id'] = this.pfisica.id;
      parametrosResto['liquidacao.empenho.favorecido.id'] = this.pfisica.id;
      parametrosExtra['empenho_extra.favorecido.id'] = this.pfisica.id;
    }
    //Filtro pessoa jurídica
    if (this.filtroPJuridica) {
      parametros['favorecido.id'] = this.pjuridica.id;
      parametrosOrcamentario['liquidacao.empenho.favorecido.id'] = this.pjuridica.id;
      parametrosResto['liquidacao.empenho.favorecido.id'] = this.pjuridica.id;
      parametrosExtra['empenho_extra.favorecido.id'] = this.pjuridica.id;
    }
    // Filtro por fornecedor
    if (this.filtroFornecedor) {
      parametros['favorecido.id'] = this.fornecedor.id;
      parametrosOrcamentario['liquidacao.empenho.favorecido.id'] = this.fornecedor.id;
      parametrosResto['liquidacao.empenho.favorecido.id'] = this.fornecedor.id;
      parametrosExtra['empenho_extra.favorecido.id'] = this.fornecedor.id;
    }
    //Filtro default Exercicio
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametrosOrcamentario['liquidacao.exercicio.id'] = this.login.exercicio.id;
    parametrosResto['liquidacao.exercicio'] = this.login.exercicio.id;
    parametrosExtra['empenho_extra.exercicio'] = this.login.exercicio.id;

    //Filtro default Orgão
    parametros['orgao.id'] = this.login.orgao.id;
    parametrosOrcamentario['liquidacao.orgao.id'] = this.login.orgao.id;
    parametrosResto['liquidacao.empenho.orgao'] = this.login.orgao.id;
    parametrosExtra['empenho_extra.orgao'] = this.login.orgao.id;

    //****ORDENADORES****
    if (this.selectedOrdem === 'ord1') {
      parametros['orderBy'] = this.agruparFornecedor ? 'favorecido.nome,data_empenho' : 'data_empenho';
    } else if (this.selectedOrdem === 'ord2') {
      parametros['orderBy'] = this.agruparFornecedor ? 'favorecido.nome,numero' : 'numero';
    } else if (this.selectedOrdem === 'ord3') {
      parametros['orderBy'] = 'favorecido.nome,data_empenho';
    } else if (this.selectedOrdem === 'ord4') {
      parametros['orderBy'] = 'ficha.numero,data_empenho';
    }

    // ****RELACIONAMENTOS****
    parametros['relations'] = 'favorecido,ficha,ficha.recurso,ficha.aplicacao,ficha.aplicacao_variavel';
    parametrosOrcamentario['relations'] = 'ficha.recurso,ficha.aplicacao,liquidacao.exercicio,liquidacao.orgao,liquidacao.usuario_cadastro,liquidacao.empenho.favorecido.tipo,liquidacao.empenho.ficha.executora';
    parametrosResto['relations'] = 'ficha.recurso,ficha.aplicacao,liquidacao.empenho.favorecido.tipo,liquidacao.exercicio,liquidacao.empenho.orgao,liquidacao.empenho.contrato,liquidacao.usuario_cadastro';
    parametrosExtra['relations'] = 'ficha.recurso,ficha.aplicacao,empenho_extra.favorecido.tipo,empenho_extra.exercicio,empenho_extra.orgao,empenho_extra.contrato,empenho_extra.usuario_cadastro';

    // ****TITULO DO RELATÓRIO E PARAMETRO DE SELEÇÃO DO TIPO DE RELATÓRIO****
    if (this.relatorio === '1') {
      titulo = 'EMPENHOS EXTRA-ORÇAMENTÁRIOS';
      parametros['especie$in'] = 'EME,EEA';
    } else if (this.relatorio === '2') {
      titulo = 'EMPENHOS EXTRA-ORÇAMENTÁRIOS ANULADOS';
      parametros['especie'] = 'EEA';
    } else if (this.relatorio === '3') {
      titulo = 'EMPENHOS EXTRA-ORÇAMENTÁRIOS EM ABERTO';
      parametros['especie$in'] = 'EME,SEE';
      parametros['anulado_total'] = false;
    } else if (this.relatorio === '4') {
      titulo = 'LISTAGEM RETENÇÕES: PESSOA FÍSICA';
      parametrosOrcamentario['liquidacao.empenho.favorecido.tipo.nome'] = 'CPF - PESSOA FÍSICA';
      parametrosResto['liquidacao.empenho.favorecido.tipo.nome'] = 'CPF - PESSOA FÍSICA';
      parametrosExtra['empenho_extra.favorecido.tipo.nome'] = 'CPF - PESSOA FÍSICA';
    } else if (this.relatorio === '5') {
      titulo = 'LISTAGEM RETENÇÕES: PESSOA JURÍDICA';
      parametrosOrcamentario['liquidacao.empenho.favorecido.tipo.nome'] = 'CNPJ - PESSOA JURÍDICA';
      parametrosResto['liquidacao.empenho.favorecido.tipo.nome'] = 'CNPJ - PESSOA JURÍDICA';
      parametrosExtra['empenho_extra.favorecido.tipo.nome'] = 'CNPJ - PESSOA JURÍDICA';
    }

    if (!relatorioRetencao) {
      this.empenhoExtraService.extendido(1, -1, parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((lista) => {
          if (lista.content.length === 0) {
            toastr.info('Sem itens para gerar Excel');
            return;
          }

          const listaItens = new Array();
          if (this.agruparFornecedor) {
            let grupos = this.funcaoService.agrupar(lista.content, ['favorecido.nome']);
            for (const grupo of grupos) {
              for (const item of grupo.registros) {
                let valor_empenho = 0;
                if (this.relatorio === '3') {
                  if ((+item.valor_empenho - +item.total_pago) > 0) {
                    valor_empenho = +item.valor_empenho - +item.total_pago;
                  }
                } else {
                  valor_empenho = +item.valor_empenho;
                }
                const entity = {
                  'EMPENHO Nº': `${item.numero}${item.parcela ? '/' + item.parcela : ''}`,
                  'FICHA Nº': item.ficha.numero,
                  'DATA': this.funcaoService.converteDataBR(item.data_empenho),
                  'HISTÓRICO': item.historico,

                };
                if (relatorio === '3') {
                  this.documento ? entity['DOCUMENTO'] = item?.processo : ''
                }

                entity['RECURSO'] = `${item.ficha.recurso.codigo}${item.ficha.aplicacao.codigo}${item.ficha.aplicacao_variavel ? item.ficha.aplicacao_variavel.codigo : ''}`,
                  entity['VALOR'] = this.funcaoService.convertToBrNumber(item.valor_empenho)

                if (relatorio === '3' && valor_empenho > 0) {
                  listaItens.push(entity);
                } else {
                  listaItens.push(entity);
                }
              }
            }
            tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile(titulo);
          } else {
            for (const item of lista.content) {
              let valor_empenho = 0;
              if (relatorio === '3') {
                if (item.parcela == 0) {
                  valor_empenho = +(+item.valor_empenho - +item.total_parcelado).toFixed(2);
                  valor_empenho = +(valor_empenho + +item.total_anulado_empenho - +item.total_pago_empenho).toFixed(2);
                } else {
                  valor_empenho = +(+(+item.valor_empenho + +item.total_anulado_empenho).toFixed(2) - +item.total_pago_empenho).toFixed(2);
                }
              } else {
                valor_empenho = +item.valor_empenho;
              }
              const entity = {
                'EMPENHO Nº': `${item.numero}${item.parcela ? '/' + item.parcela : ''}`,
                'FICHA Nº': item.ficha.numero,
                'DATA': this.funcaoService.converteDataBR(item.data_empenho),
                'FAVORECIDO': item.favorecido.nome,

              };

              if (relatorio === '3') {
                this.documento ? entity['DOCUMENTO'] = item?.processo : ''
              }

              entity['RECURSO'] = `${item.ficha.recurso.codigo}${item.ficha.aplicacao.codigo}${item.ficha.aplicacao_variavel ? item.ficha.aplicacao_variavel.codigo : ''}`,
              entity['VALOR'] = this.funcaoService.convertToBrNumber(item.valor_empenho)

              if (relatorio === '3' && +valor_empenho > 0) {
                listaItens.push(entity);
              } else if (relatorio === '1' || relatorio === '2') {
                listaItens.push(entity);
              }
            }
            tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile(titulo);
          }
        });
    } else {
      let todasRetencoes: RetencaoAgrupado[] = [];
      let retencoes: Retencao[] = await (await this.retencaoService.extendido(1, -1, parametrosOrcamentario).toPromise()).content;
      let retencoesResto: RetencaoResto[] = await (await this.retencaoRestoService.extendido(1, -1, parametrosResto).toPromise()).content;
      let retencoesExtra: RetencaoExtra[] = await (await this.retencaoExtraService.extendido(1, -1, parametrosExtra).toPromise()).content;

      if (retencoes.length === 0 && retencoesExtra.length === 0 && retencoesResto.length === 0) {
        toastr.info('Sem itens para gerar Excel');
        return;
      }

      todasRetencoes = await this.aglutinadorRetencoes(retencoes, retencoesResto, retencoesExtra);

      if (this.selectedOrdem) {
        await this.ordenarRetencoes(todasRetencoes);
      }

      const listaItens = new Array();
      if (this.agruparFornecedor) {
        this.fornecedoresAgrupados.forEach((item) => {
          item.registros.forEach((retencao: RetencaoAgrupado) => {
            let empenhoParcelaTipo = `${retencao?.empenho_numero}/${retencao.liq_parcela} - ${retencao?.tipo}`;

            const entity = {
              'DATA': this.funcaoService.converteDataBR(retencao.data),
              'EMPENHO': empenhoParcelaTipo,
              'UNIDADE': `${retencao?.executora_codigo || ''}`,
              'DOCUMENTO': `${retencao?.documento?.replace('              ', '') || ''}`,
              'PIS/INSS': `${retencao.pis || ''}`,
              'CNPJ/CPF': retencao.cpf_cnpj,
              'FORNECEDOR': retencao.favorecido_nome,
              'VALOR LIQ. ': this.funcaoService.convertToBrNumber(retencao.valor, 2, true),
              'VALOR RETIDO': this.funcaoService.convertToBrNumber(retencao.valor_retencao, 2, true)
            };
            listaItens.push(entity);
          });
        });
        tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile(titulo);
      } else {
        todasRetencoes.forEach((retencao: RetencaoAgrupado) => {
          let empenhoParcelaTipo = `${retencao?.empenho_numero}/${retencao.liq_parcela} - ${retencao?.tipo}`;

          const entity = {
            'DATA': this.funcaoService.converteDataBR(retencao.data),
            'EMPENHO': empenhoParcelaTipo,
            'UNIDADE': `${retencao?.executora_codigo || ''}`,
            'DOCUMENTO': `${retencao?.documento?.replace('              ', '') || ''}`,
            'PIS/INSS': `${retencao.pis || ''}`,
            'CNPJ/CPF': retencao.cpf_cnpj,
            'FORNECEDOR': retencao.favorecido_nome,
            'VALOR LIQ. ': this.funcaoService.convertToBrNumber(retencao.valor, 2, true),
            'VALOR RETIDO': this.funcaoService.convertToBrNumber(retencao.valor_retencao, 2, true)
          };
          listaItens.push(entity);
        });
        tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile(titulo);
      }
    }
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }
}
