import { Directive, OnDestroy } from '@angular/core';
import { Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Orgao, OrgaoAssinaturaService, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseResourceNotaExplicativa } from '../base-resource-nota-explicativa';
import { BalancoService } from '../service/balanco.service';
import { NotaExplicativaService } from '../service/nota-explicativa.service';

@Directive()
export class Anexo121Balanco extends BaseResourceNotaExplicativa implements OnDestroy {

  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected balancoServico: BalancoService,
    protected exercicio: Exercicio,
    protected notaService: NotaExplicativaService,
    protected assinaturaService: OrgaoAssinaturaService,
  ) {
    super('B121', notaService, assinaturaService)
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(exercicio: Exercicio, orgaos: number[], listaOrgaos: Orgao[], mes?: number, formato?: FormatoExportacao) {
    formato = formato ?? 'pdf';
    this.balancoServico.obterBalancoOrcamentarioRPNaoProcessado(this.exercicio.id, orgaos, mes)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        const orgao = orgaos.length === 1 ? listaOrgaos.filter(o => o.id === orgaos[0])[0] : listaOrgaos.filter(o => o.id === 1)[0];
        const consolidado = orgaos.length === 1 ? orgao.nome : 'Consolidado';
        let orgaoNomes: string[] = [];
        for (const o of listaOrgaos) {
          if (orgaos.some(orgao => orgao === o.id)) {
            orgaoNomes.push(`${o.codigo} - ${o.nome}`);
          }
        }
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(
            `ANEXO 12.1 - DEMONSTRATIVO DE EXECUÇÃO DE RESTOS A PAGAR NÃO PROCESSADO`,
            this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            this.cabecalhoRelatorio(orgao, exercicio, mes)
              .concat(this.conteudo(dados))
              .concat(await this.conteudoNotaExplicativa())
              .concat(await this.conteudoAssinatura(this.login.orgao, orgaoNomes)),
            'landscape', 'ANEXO 12.1 - DEMONSTRATIVO DE EXECUÇÃO DE RESTOS A PAGAR NÃO PROCESSADO',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, false, false, 'pdf', `Unidade Gestora: ${consolidado.toUpperCase()}`);
        } else if (formato === 'csv') {
          this.exportacaoCsv(dados);
        }
      });
  }

  private cabecalhoRelatorio(orgao: Orgao, exercicio: Exercicio, mes?: number): {}[] {
    const registros = [
      [
        { text: `Município:`, alignment: 'left', fontSize: 8 },
        { text: orgao.cidade?.nome ? orgao.cidade.nome : this.login.cidade.nome, alignment: 'center', fontSize: 8 },
        { text: `Exercício:`, alignment: 'left', fontSize: 8 },
        { text: exercicio.ano, alignment: 'center', fontSize: 8 }
      ], [
        { text: `Poder:`, alignment: 'left', fontSize: 8 },
        { text: orgao.especie === 'C' ? 'PODER LEGISLATIVO' : 'PODER EXECUTIVO', alignment: 'center', fontSize: 8 },
        { text: `Mês:`, alignment: 'left', fontSize: 8 },
        { text: mes ? this.globalService.obterMes(+mes) : 'ANUAL', alignment: 'center', fontSize: 8 }
      ], [
        { text: `Órgão:`, alignment: 'left', fontSize: 8, border: [true, true, true, false] },
        { text: orgao.nome, alignment: 'center', fontSize: 8, border: [true, true, true, false] },
        { text: `Acumulado/Mensal:`, alignment: 'left', fontSize: 8, border: [true, true, true, false] },
        { text: mes ? 'MENSAL' : 'ACUMULADO', alignment: 'center', fontSize: 8, border: [true, true, true, false] }
      ], [
        { text: 'Em R$ 1,00', bold: true, alignment: 'right', colSpan: 4, border: [false, true, false, false] },
        '',
        '',
        '',
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*', '*', '*', '*'],
        body: registros
      }
    }];
  }

  private conteudo(dados: any[]): {}[] {
    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: 'RESTOS A PAGAR NÃO PROCESSADOS',
        alignment: 'center',
        fontSize: 7,
        bold: true,
        rowSpan: 2
      }, {
        text: 'INSCRITOS',
        alignment: 'center',
        fontSize: 7,
        bold: true,
        colSpan: 2
      }, {
        text: '',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }, {
        text: 'LIQUIDADOS  (c)',
        alignment: 'center',
        fontSize: 7,
        bold: true,
        rowSpan: 2
      }, {
        text: 'PAGOS (d)',
        alignment: 'center',
        fontSize: 7,
        bold: true,
        rowSpan: 2
      }, {
        text: 'CANCELADOS (e)',
        alignment: 'center',
        fontSize: 7,
        bold: true,
        rowSpan: 2
      }, {
        text: 'SALDO (f)=(a+b-d-e)',
        alignment: 'center',
        fontSize: 7,
        bold: true,
        rowSpan: 2
      }],
      [
        { text: '' },
        {
          text: 'EM EXERCÍCIOS ANTERIORES (a)',
          alignment: 'center',
          fontSize: 7,
          bold: true,
        }, {
          text: 'EM 31 DE DEZEMBRO DO EXERCÍCIO (b)',
          alignment: 'center',
          fontSize: 7,
          bold: true,
        },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' }
      ]
    ];


    const grupos = this.funcaoService.agrupar(dados, 'tipo',
      ['anterior', 'doexercicio', 'liquidado', 'pago', 'cancelado']);
    const totalizador = new Array<any>();
    let col2 = 0;
    let col3 = 0;
    let col4 = 0;
    let col5 = 0;
    let col6 = 0;
    let col7 = 0;
    for (const grupo of grupos) {
      registros.push([
        {
          text: grupo.grupo, bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(
            grupo.totalizadores['anterior']), alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(
            grupo.totalizadores['doexercicio']), alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(
            grupo.totalizadores['liquidado']), alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(
            grupo.totalizadores['pago']), alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(
            grupo.totalizadores['cancelado']), alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(
            +grupo.totalizadores['anterior'] + +grupo.totalizadores['doexercicio'] - +grupo.totalizadores['pago'] - +grupo.totalizadores['cancelado']), alignment: 'right', bold: true, fontSize: 7
        }
      ]);

      // lista dos registros do grupo
      for (const registro of grupo.registros) {
        registros.push([
          {
            text: `${registro.nome}`, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(registro.anterior), alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(registro.doexercicio), alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(registro.liquidado), alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(registro.pago), alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(registro.cancelado), alignment: 'right', fontSize: 7 },
          {
            text: this.funcaoService.convertToBrNumber(
              +registro.anterior + +registro.doexercicio - +registro.pago - +registro.cancelado), alignment: 'right', fontSize: 7
          }
        ]);
      }

      col2 += +grupo.totalizadores['anterior'];
      col3 += +grupo.totalizadores['doexercicio'];
      col4 += +grupo.totalizadores['liquidado'];
      col5 += +grupo.totalizadores['pago'];
      col6 += +grupo.totalizadores['cancelado'];
      col7 += (+grupo.totalizadores['anterior'] + +grupo.totalizadores['doexercicio'] - +grupo.totalizadores['pago'] - +grupo.totalizadores['cancelado']);
    }

    registros.push([{
      text: 'TOTAL', bold: true,
      alignment: 'left', fontSize: 7
    }, {
      text: this.funcaoService.convertToBrNumber(col2),
      alignment: 'right', bold: true, fontSize: 7
    }, {
      text: this.funcaoService.convertToBrNumber(col3),
      alignment: 'right', bold: true, fontSize: 7
    }, {
      text: this.funcaoService.convertToBrNumber(col4),
      alignment: 'right', bold: true, fontSize: 7
    }, {
      text: this.funcaoService.convertToBrNumber(col5),
      alignment: 'right', bold: true, fontSize: 7
    }, {
      text: this.funcaoService.convertToBrNumber(col6),
      alignment: 'right', bold: true, fontSize: 7
    }, {
      text: this.funcaoService.convertToBrNumber(col7),
      alignment: 'right', bold: true, fontSize: 7
    }]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 80, 80, 80, 80, 80, 80],
        body: registros
      }
    }];
  }

  private exportacaoCsv(dados: any[]) {
    
    // monta o cabecalho
    const registros: {}[][] = [
      [
        { text: 'RESTOS A PAGAR NÃO PROCESSADOS' },
        { text: 'INSCRITOS EM EXERCÍCIOS ANTERIORES (a)' },
        { text: 'INSCRITOS EM 31 DE DEZEMBRO DO EXERCÍCIO (b)' },
        { text: 'LIQUIDADOS  (c)' },
        { text: 'PAGOS (d)' },
        { text: 'CANCELADOS (e)' },
        { text: 'SALDO (f)=(a+b-d-e)' }
      ]
    ];
    
    let col2 = 0;
    let col3 = 0;
    let col4 = 0;
    let col5 = 0;
    let col6 = 0;
    let col7 = 0;
    const totalizador = new Array<any>();
    const grupos = this.funcaoService.agrupar(dados, 'tipo', ['anterior', 'doexercicio', 'liquidado', 'pago', 'cancelado']);
    for (const grupo of grupos) {
      registros.push([
        { text: grupo.grupo },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['anterior']) },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['doexercicio']) },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']) },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago']) },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['cancelado']) },
        { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['anterior'] + +grupo.totalizadores['doexercicio'] - +grupo.totalizadores['pago'] - +grupo.totalizadores['cancelado']) }
      ]);

      for (const registro of grupo.registros) {
        registros.push([
          { text: registro.nome },
          { text: this.funcaoService.convertToBrNumber(registro.anterior) },
          { text: this.funcaoService.convertToBrNumber(registro.doexercicio) },
          { text: this.funcaoService.convertToBrNumber(registro.liquidado) },
          { text: this.funcaoService.convertToBrNumber(registro.pago) },
          { text: this.funcaoService.convertToBrNumber(registro.cancelado) },
          { text: this.funcaoService.convertToBrNumber(+registro.anterior + +registro.doexercicio - +registro.pago - +registro.cancelado) }
        ]);
      }

      col2 += +grupo.totalizadores['anterior'];
      col3 += +grupo.totalizadores['doexercicio'];
      col4 += +grupo.totalizadores['liquidado'];
      col5 += +grupo.totalizadores['pago'];
      col6 += +grupo.totalizadores['cancelado'];
      col7 += (+grupo.totalizadores['anterior'] + +grupo.totalizadores['doexercicio'] - +grupo.totalizadores['pago'] - +grupo.totalizadores['cancelado']);
    }

    registros.push([
      { text: 'TOTAL' },
      { text: this.funcaoService.convertToBrNumber(col2) },
      { text: this.funcaoService.convertToBrNumber(col3) },
      { text: this.funcaoService.convertToBrNumber(col4) },
      { text: this.funcaoService.convertToBrNumber(col5) },
      { text: this.funcaoService.convertToBrNumber(col6) },
      { text: this.funcaoService.convertToBrNumber(col7) }
    ]);

    dados = null;
    let csv = '';
    for (let i=0; i<registros.length; i++) {
      const linha = registros[i];
      if (i > 0) csv += '\n';
      for (let x=0; x<linha.length; x++) {
        if (x > 0) csv += ';';
        csv += String(linha[x]['text']);
      }
    }

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + csv));
    element.setAttribute("download", `ANEXO 12.1 - DEMONSTRATIVO DE EXECUÇÃO DE RESTOS A PAGAR NÃO PROCESSADO.csv`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

}
