// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { AuditoriaPatrimonio } from './auditoria-patrimonio.model';

export class PatrimonioChecklist extends BaseResourceModel {
  constructor(
    public id?: number,
    public pergunta?: string,
    public tipo_resposta?: string,
    public descricao_resposta?: string,
    public resposta?: boolean,
    public observacao?: string,
    public auditoria_patrimonio?: AuditoriaPatrimonio
    ) {
      super();
    }
    static converteJson(json: any): PatrimonioChecklist {
      return Object.assign(new PatrimonioChecklist(), json);
    }
}
