import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, Exercicio } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class QuadroSaudeService extends BaseResourceService<Exercicio> {

  constructor(
    protected injector: Injector
  ) {
    super(`contabil-relatorio`, injector);
  }

  obterQuadro1(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/quadro1-saude/${mes}/${exercicio}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterQuadro2(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/quadro2-saude/${mes}/${exercicio}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterQuadro3(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/quadro3-saude/${mes}/${exercicio}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterQuadro5(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/quadro5-saude/${mes}/${exercicio}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  
  obterAnexo12(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo12-saude/${mes}/${exercicio}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterProjecaoReceitaDespesa(mes: number, exercicio: number, orgaos: number[]) {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/projecao-receita-despesa-saude/${mes}/${exercicio}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
}


}
