import { AfterViewInit, Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar, CalendarOptions } from '@fullcalendar/core';
import { ConfirmationService } from 'primeng/api';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ChaveValor, Coluna, DIA_TIME, FormatoExportacao, FullCalendarProperties } from '../../components/types';
import { Agenda } from '../../entidade/comum/agenda.model';
import { Login } from '../../entidade/login/login';
import { BaseResourceListComponent, Filtro } from '../../models/base-resource-list';
import { FuncaoService } from '../../util/funcao.service';
import { AgendaService } from '../service/agenda.service';

declare var $: any;

@Component({
  selector: 'lib-agenda-list',
  templateUrl: './agenda-list.component.html',
  providers: [ConfirmationService]
})
export class AgendaListComponent extends BaseResourceListComponent<Agenda, Login> implements AfterViewInit {

  /**
   * Declaração de variáveis
   */

  @ViewChild('calendar')
  public calendarComponent: FullCalendarComponent;

  @ViewChild('titulo_')
  private tituloEvento: ElementRef;

  public calendarOptions: CalendarOptions = { initialView: 'dayGridMonth', weekends: false };
  public calendarApi: Calendar;
  public selectedEvent: FullCalendarProperties = new FullCalendarProperties();
  public listViews: ChaveValor[] = [];
  public selectedView: ChaveValor;
  public listRecorrentes: ChaveValor[] = [];
  public listCores: { value: string }[] = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private funcaoService: FuncaoService,
    private agendaService: AgendaService) {
    super(agendaService, injector);
  }

  ngAfterViewInit(): void {
    this.carregarFullCalendarProps().then(() =>
      this.alternarView()
    );
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return '';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id,
      ['usuario.id']: this.login.usuario.id,
      ['concluido']: false,
    };
  }

  protected ordenacaoGrid(): string[] {
    return [];
  }

  protected filtrosGrid(): Filtro {
    return {
      date: ['inicio', 'fim'],
      text: ['titulo', 'descricao'],
    };
  }

  protected afterInit(): void {
    this.listViews = [];
    this.listViews.push({ chave: 'timeGridWeek', valor: 'Agenda' });
    this.listViews.push({ chave: 'listWeek', valor: 'Programação' });
    this.listViews.push({ chave: 'dayGrid', valor: 'Dia' });
    this.listViews.push({ chave: 'dayGridWeek', valor: 'Semana' });
    this.listViews.push({ chave: 'dayGridMonth', valor: 'Mês' });

    this.listRecorrentes = [];
    this.listRecorrentes.push({ chave: 'N', valor: 'Não se repete' });
    this.listRecorrentes.push({ chave: 'D', valor: 'Todos os dias' });
    this.listRecorrentes.push({ chave: 'DS', valor: 'Segunda a sexta-feira' });
    this.listRecorrentes.push({ chave: 'S', valor: 'Semanalmente' });
    this.listRecorrentes.push({ chave: 'M', valor: 'Mensalmente' });
    this.listRecorrentes.push({ chave: 'A', valor: 'Anualmente' });

    this.listCores = [];
    this.listCores.push({ value: '#007bff' });
    this.listCores.push({ value: '#6f42c1' });
    this.listCores.push({ value: '#e83e8c' });
    this.listCores.push({ value: '#dc3545' });
    this.listCores.push({ value: '#fd7e14' });
    this.listCores.push({ value: '#ffc107' });
    this.listCores.push({ value: '#28a745' });
    this.listCores.push({ value: '#6c757d' });
    this.listCores.push({ value: '#ccc' });

    this.selectedView = this.listViews[0];
  }

  public preencherGrid() {
    this.agendaService
      .filtrar(this.paginaCorrente,
        this.login.limite,
        this.relations() ? Object.assign({}, { relations: this.relations() }, this.obterParametros()) : this.obterParametros())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        async lista => {
          this.lista = lista.content;
          this.paginaTotal = lista.totalPages;

          this.carregarEventosRecorrentes();
        },
        error => alert('erro ao retornar lista')
      );
  }

  protected acaoRemover(model: Agenda): Observable<Agenda> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Ínicio', coluna: 'inicio' },
      { titulo: 'Fim', coluna: 'fim' },
      { titulo: 'Título', coluna: 'titulo' },
      { titulo: 'Descrição', coluna: 'descricao', tipo: 'HTML' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    this.agendaService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`PROGRAMAÇÃO DE EVENTOS`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Programação de eventos', ['auto', 'auto', 'auto', '*'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
  private async carregarFullCalendarProps() {
    const self = this;

    this.calendarApi = this.calendarComponent.getApi();
    this.calendarApi.setOption('locale', 'pt-br');
    this.calendarApi.setOption('weekends', true);
    this.calendarApi.setOption('slotMinTime', '07:00:00');
    this.calendarApi.setOption('slotMaxTime', '21:00:00');
    this.calendarApi.setOption('nowIndicator', true);
    this.calendarApi.setOption('allDaySlot', true);
    this.calendarApi.setOption('allDayText', '');
    this.calendarApi.setOption('slotEventOverlap', true);
    this.calendarApi.setOption('noEventsText', 'Nenhum evento localizado');
    this.calendarApi.setOption('eventClick', (info: any) => {
      const origem: Agenda = info.event.extendedProps.agenda.origem;
      if (origem) {
        self.selectedEvent.id = origem.id;
        self.selectedEvent.allDay = origem.diaInteiro;
        self.selectedEvent.start = new Date(origem.inicio);
        self.selectedEvent.end = new Date(origem.fim);
        self.selectedEvent.title = origem.titulo;
        self.selectedEvent.color = origem.corHex;
        self.selectedEvent.descricao = origem.descricao;
        self.selectedEvent.recorrente = origem.recorrente;
        self.selectedEvent.concluido = origem.concluido;
      } else {
        self.selectedEvent.id = info.event.id;
        self.selectedEvent.allDay = info.event.allDay;
        self.selectedEvent.start = info.event.start;
        self.selectedEvent.end = info.event.end;
        self.selectedEvent.title = info.event.title;
        self.selectedEvent.color = info.event.backgroundColor;
        self.selectedEvent.descricao = info.event.extendedProps.descricao;
        self.selectedEvent.recorrente = info.event.extendedProps.recorrente;
        self.selectedEvent.concluido = info.event.extendedProps.concluido;
      }

      $('#dialogEvento').modal('show');
      setTimeout(() => self.tituloEvento.nativeElement.focus());
    });
  }

  public iniciarNovoEvento() {
    this.selectedEvent = new FullCalendarProperties();

    this.selectedEvent.allDay = false;
    this.selectedEvent.color = '#ccc';
    this.selectedEvent.concluido = false;
    this.selectedEvent.recorrente = 'N';
    this.selectedEvent.orgao = this.login.orgao;
    this.selectedEvent.usuario = this.login.usuario;

    $('#dialogEvento').modal('show');
    setTimeout(() => this.tituloEvento.nativeElement.focus());
  }

  public salvarEvento(evento?: FullCalendarProperties) {
    evento = evento ? evento : this.selectedEvent;
    if (!evento) {
      return;
    }
    if (!evento.id) {
      this.agendaService
        .inserir(this.converterEventToAgenda(evento))
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((dados) => {
          this.selectedEvent = new FullCalendarProperties();
          $('#dialogEvento').modal('hide');
          toastr.success('Evento salvo com sucesso!');
          this.preencherGrid();
        },
          error => toastr.error('Não foi possível incluir o evento', error)
        );
    } else {
      this.agendaService
        .atualizar(this.converterEventToAgenda(evento))
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((dados) => {
          this.selectedEvent = new FullCalendarProperties();
          $('#dialogEvento').modal('hide');
          toastr.success('Evento salvo com sucesso!');
          this.preencherGrid();
        },
          error => toastr.error('Não foi possível salvar o evento', error)
        );
    }
  }

  private converterAgendaToEvent(agenda: Agenda): FullCalendarProperties {
    if (!agenda) {
      return null;
    }
    const event = new FullCalendarProperties();

    event.agenda = agenda;
    event.id = +agenda.id;
    event.title = agenda.titulo;
    event.start = this.funcaoService.obterDataUTC(agenda.inicio);
    event.end = this.funcaoService.obterDataUTC(agenda.fim);
    event.start = agenda.inicio;
    event.end = agenda.fim;
    event.allDay = agenda.diaInteiro;
    event.color = agenda.corHex;
    event.concluido = agenda.concluido;
    event.descricao = agenda.descricao;
    event.recorrente = agenda.recorrente;
    event.orgao = agenda.orgao ? agenda.orgao : this.login.orgao;
    event.usuario = agenda.usuario ? agenda.usuario : this.login.usuario;

    return event;
  }

  private converterEventToAgenda(event: FullCalendarProperties): Agenda {
    if (!event) {
      return null;
    }
    const agenda = event.agenda ? event.agenda : new Agenda();

    agenda.id = +event.id;
    agenda.titulo = event.title;
    agenda.inicio = this.funcaoService.obterDataUTC(event.start);
    agenda.fim = this.funcaoService.obterDataUTC(event.end);
    agenda.diaInteiro = event.allDay;
    agenda.corHex = event.color;
    agenda.concluido = event.concluido;
    agenda.descricao = event.descricao;
    agenda.recorrente = event.recorrente;
    agenda.orgao = event.orgao ? event.orgao : this.login.orgao;
    agenda.usuario = event.usuario ? event.usuario : this.login.usuario;

    return agenda;
  }

  public alternarView() {
    this.calendarApi.changeView(this.selectedView.chave);
    this.carregarEventosRecorrentes();
  }

  public navPrev() {
    this.calendarApi.prev();
    this.carregarEventosRecorrentes();
  }

  public navNext() {
    this.calendarApi.next();
    this.carregarEventosRecorrentes();
  }

  private carregarEventosRecorrentes() {
    let calendarEvents = [];
    for (const agenda of this.lista) {
      calendarEvents.push(this.converterAgendaToEvent(agenda));
      if (agenda.recorrente !== 'N') {
        calendarEvents = this.replicarEventosRecorrentes(agenda, calendarEvents);
      }
    }
    this.calendarOptions['events'] = calendarEvents;
  }

  private replicarEventosRecorrentes(evento: Agenda, eventos: any[]): any[] {
    let inicioAgenda: Date = this.calendarApi.view.activeStart;
    const fimAgenda: Date = this.calendarApi.view.activeEnd;
    const inicioEvento: Date = new Date(evento.inicio);

    inicioAgenda = inicioAgenda > inicioEvento ? inicioAgenda : inicioEvento;

    let diasAgenda: number;
    switch (this.selectedView.chave) {
      case 'timeGridWeek':
      case 'dayGridWeek':
      case 'listWeek':
        diasAgenda = 6; // 7 dias - 1
        break;
      case 'dayGrid':
        diasAgenda = 0; // 1 dia - 1
        break;
      case 'dayGridMonth':
        diasAgenda = new Date(inicioAgenda.getFullYear(), inicioAgenda.getUTCMonth() + 1, 0).getDate() - 1; // n dias - 1
        break;
    }

    let diasSomar = Math.floor((fimAgenda.getTime() - inicioEvento.getTime()) / DIA_TIME) - diasAgenda;
    diasSomar = diasSomar < 0 ? 0 : diasSomar;
    let diaSemana = inicioAgenda.getDay();

    for (let inicioAtual = inicioAgenda.getTime(); inicioAtual < fimAgenda.getTime(); inicioAtual += DIA_TIME, diasSomar++) {
      if (inicioAtual !== inicioEvento.getTime()) {
        switch (evento.recorrente) {
          case 'D':
            eventos.push(this.converterAgendaToEvent(this.gerarCopiaEvento(evento, diasSomar)));
            break;
          case 'DS':
            if (diaSemana !== 0 && diaSemana !== 6) {
              eventos.push(this.converterAgendaToEvent(this.gerarCopiaEvento(evento, diasSomar)));
            }
            break;
          case 'S':
            if (new Date(inicioAtual).getDay() === inicioEvento.getDay()) {
              eventos.push(this.converterAgendaToEvent(this.gerarCopiaEvento(evento, diasSomar)));
            }
            break;
          case 'M':
            if (new Date(inicioAtual).getDate() === inicioEvento.getDate()) {
              eventos.push(this.converterAgendaToEvent(this.gerarCopiaEvento(evento, diasSomar)));
            }
            break;
          case 'A':
            if (new Date(inicioAtual).getDate() === inicioEvento.getDate() &&
              new Date(inicioAtual).getMonth() === inicioEvento.getMonth()) {
              eventos.push(this.converterAgendaToEvent(this.gerarCopiaEvento(evento, diasSomar)));
            }
            break;
        }
      }
      diaSemana = diaSemana === 6 ? 0 : diaSemana + 1;
    }
    return eventos;
  }

  private gerarCopiaEvento(evento: Agenda, diasSomar: number): Agenda {
    const agendaCC = new Agenda();

    agendaCC.id = evento.id;
    agendaCC.concluido = evento.concluido;
    agendaCC.corHex = evento.corHex;
    agendaCC.descricao = evento.descricao;
    agendaCC.diaInteiro = evento.diaInteiro;
    agendaCC.orgao = evento.orgao;
    agendaCC.recorrente = evento.recorrente;
    agendaCC.titulo = evento.titulo;
    agendaCC.usuario = evento.usuario;
    agendaCC.inicio = new Date(new Date(evento.inicio).getTime() + (diasSomar * DIA_TIME));
    agendaCC.fim = new Date(new Date(evento.fim).getTime() + (diasSomar * DIA_TIME));

    agendaCC.origem = evento;

    return agendaCC;
  }
}
