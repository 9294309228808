import { Component, Input } from '@angular/core';
import { Audesp4LocalizacaoObra, Audesp4LoteObra } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-lote-eng-loc',
  templateUrl: './licitacao-audesp-lote-eng-loc.component.html'
})
export class LicitacaoAudespLoteEngLocComponent extends BaseLicitacaoAudesp {

  @Input() public lote: Audesp4LoteObra;

  public itemAtual: Audesp4LocalizacaoObra;
  public itemAnterior: Audesp4LocalizacaoObra;

  constructor() {
    super()
  }

  public adicionar() {
    if (!this.lote.LocalizacaoObra || this.lote.LocalizacaoObra.length === 0) this.lote.LocalizacaoObra = [];

    this.itemAtual = {
      LocalObraServico: undefined, Latitude: undefined, Longitude: undefined, editavel: true
    }
    this.lote.LocalizacaoObra.push(this.itemAtual);
  }

  public visualizar(item: Audesp4LocalizacaoObra) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      let itm = this.lote.LocalizacaoObra.find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.lote.LocalizacaoObra.splice(this.lote.LocalizacaoObra.indexOf(this.itemAtual), 1, itm)
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAtual.editavel = false;
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4LocalizacaoObra) {
    this.itemAtual = undefined;
    this.lote.LocalizacaoObra.splice(this.lote.LocalizacaoObra.indexOf(item), 1)
  }

  public salvar() {
    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }
}
