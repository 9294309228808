import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { GrupoEstoqueService } from 'almoxarifado-lib';
import {
  BaseResourceFormComponent, FuncaoService, GlobalService, GrupoEstoque, LoginContabil
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'lib-grupo-estoque-form',
  templateUrl: './grupo-estoque-form.component.html'
})
export class GrupoEstoqueFormComponent extends BaseResourceFormComponent<GrupoEstoque, LoginContabil> {

  /**
   * Declaração de variáveis
   */

  public editarCodigo: boolean = false;

  public mes: string;

  @ViewChild('codigo') inputField: ElementRef;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected grupoService: GrupoEstoqueService,
  ) {
    super(new GrupoEstoque(), injector, GrupoEstoque.converteJson, grupoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(item: GrupoEstoque): Promise<boolean> {
    return true;
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      codigo: [null, [Validators.required]],
      nome: [null, [Validators.required]],
      bec: [false, [Validators.required]],
      medicamento: [false, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      estoques: [],
      subgrupos: [],
      vida_util_meses: [],
      valor_residual: []
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'estoques.estoque,subgrupos' };
  }

  protected afterLoad() {
  }

  protected afterInit(): void {
    if (this.currentActionRoute === 'novo') {
      this.grupoService.proximoCodigo(false, this.login.orgao.id)
        .subscribe((data) => {
          this.entidadeForm.get('codigo').setValue(data);
        });
    }
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected beforeSubmit() {
    try {
      if (this.entidadeForm.get('nome').value === '') {
        throw new Error('Informe o nome do grupo!');
      }
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(modelo: GrupoEstoque) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
}
