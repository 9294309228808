import { Injectable, Injector } from "@angular/core";
import { BaseResourceService, NotaExplicativa } from "eddydata-lib";

@Injectable({
  providedIn: 'root'
})
export class NotaExplicativaService extends BaseResourceService<NotaExplicativa> {

  constructor(
    protected injector: Injector
  ) {
    super(`notas-explicativas`, injector);
  }
}