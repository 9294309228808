import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LiquidacaoService } from 'administrativo-lib';
import { Audesp4LiquidacaoDocSimilarSim, DocumentoFiscal, DocumentoFiscalArray, Execucao, FuncaoService, LicitacaoAudesp } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LicitacaoAudespService } from '../../service/licitacao-audesp.service';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-documento-fiscal-array',
  templateUrl: './licitacao-audesp-documento-fiscal-array.component.html'
})
export class LicitacaoAudespDocumentoFiscalArrayComponent extends BaseLicitacaoAudesp implements OnChanges {

  @Input() public entidade: DocumentoFiscalArray;
  @Input() public ajuste: LicitacaoAudesp;

  public itemAtual: DocumentoFiscal;
  public itemAnterior: DocumentoFiscal;
  public listaExecucao: Execucao[];
  public listaLiquidacao: Audesp4LiquidacaoDocSimilarSim[];

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    public funcaoService: FuncaoService,
    public audespService: LicitacaoAudespService,
    public liquidacaoService: LiquidacaoService,
  ) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.carregarExecucoes();
    }
  }

  public adicionar() {
    if (!this.entidade.DocFiscal || this.entidade.DocFiscal.length === 0) this.entidade.DocFiscal = [];

    this.itemAtual = {
      DocumentoFiscalDataEmissao: undefined, DocumentoFiscalNumero: undefined, DocumentoFiscalUF: undefined,
      DocumentoFiscalValor: undefined, MedicaoNumero: undefined, NotaEmpenhoDataEmissao: undefined,
      NotaEmpenhoNumero: undefined, editavel: true
    }
    this.entidade.DocFiscal.push(this.itemAtual);

    if (this.listaExecucao?.length === 0) {
      toastr.warning('Nenhuma medição foi localizada para este ajuste');
      return;
    }
    this.itemAtual.MedicaoNumero = this.listaExecucao[0].ConferenciaMedicaoSim.NumeroMedicao;
    this.carregarLiquidacoes(this.itemAtual.MedicaoNumero);
  }

  public visualizar(item: DocumentoFiscal) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
    this.carregarLiquidacoes(this.itemAtual.MedicaoNumero);
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      let itm = this.entidade.DocFiscal.find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.entidade.DocFiscal.splice(this.entidade.DocFiscal.indexOf(this.itemAtual), 1, itm)
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: DocumentoFiscal) {
    this.itemAtual = undefined;
    this.entidade.DocFiscal.splice(this.entidade.DocFiscal.indexOf(item), 1)
  }

  public salvar() {
    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }

  private carregarExecucoes() {
    if (!this.ajuste) return;
    this.audespService.filtrar(0, 0, { parente_id: this.ajuste.id, tipo: 'EXECUCAO' }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res?.content) {
          const arquivos: LicitacaoAudesp[] = res.content;
          if (arquivos?.length > 0) {
            this.listaExecucao = arquivos.map(a => JSON.parse(a.arquivo) as Execucao).filter(e => e.ConferenciaMedicaoSim);
          }
        }
      })
  }

  public carregarLiquidacoes(medicao: number) {
    if (!medicao) return;

    const execucao = this.listaExecucao.find(e => e.ConferenciaMedicaoSim.NumeroMedicao === medicao);
    if (execucao)
      this.listaLiquidacao = execucao.LiquidacaoDocSimilarSim;
  }

  public atribuirLiquidacao() {
    if (!this.itemAtual?.liquidacaoId) return;

    const liquidacao = this.listaLiquidacao.find(l => l.liquidacaoId === this.itemAtual.liquidacaoId)
    if (!liquidacao) return;

    this.itemAtual.NotaEmpenhoNumero = liquidacao.NotaEmpenhoNumero;
    this.itemAtual.NotaEmpenhoDataEmissao = this.funcaoService.converteDataBR(liquidacao.NotaEmpenhoDataEmissao);
    this.itemAtual.DocumentoFiscalDataEmissao = this.funcaoService.converteDataBR(liquidacao.LiquidacaoEmpenhoDt);
    this.itemAtual.DocumentoFiscalValor = liquidacao.LiquidacaoEmpenhoValor;

    this.liquidacaoService.obter({ id: this.itemAtual.liquidacaoId, relations: 'empenho.favorecido' }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res) {
          this.itemAtual.DocumentoFiscalNumero = res.documento;
          this.itemAtual.DocumentoFiscalUF = res.empenho.favorecido.uf;
        }
      })
  }
}
