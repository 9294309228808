import { BaseResourceModel } from '../../models/base-resource.model';
import { Pessoa } from '../comum/pessoa.model';
import { Unidade } from '../planejamento/unidade.model';

export class UnidadeMembro extends BaseResourceModel {
  constructor(
    public id?: number,
    public licitacao?: boolean,
    public responsavel_contrato?: boolean,
    public unidade?: Unidade,
    public pessoa?: Pessoa,
    public editavel?: boolean,
  ) {
    super();
  }
  static converteJson(json: any): UnidadeMembro {
    return Object.assign(new UnidadeMembro(), json);
  }
}
