import { Component, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { MessageService } from 'primeng/api';
import * as toastr from 'toastr';
import { Login, GlobalService, FuncaoService, PreLiquidacaoResto } from 'eddydata-lib';
import { PreLiquidacaoRestoService } from '../service/pre-liquidacao-resto.service';

declare var $: any;

@Component({
  selector: 'lib-pre-liquidacao-resto-anulacao',
  templateUrl: './pre-liquidacao-resto-anulacao.component.html'
})
export class PreLiquidacaoRestoAnulacaoComponent implements OnInit, OnDestroy, AfterViewInit {

  protected datepipe: DatePipe;
  public ptBR: any;
  public data: Date;
  public data_referencia: Date;
  public valor: number;
  public historico: string;
  public liquidacaoId: number;
  public listAnulacoes: Array<PreLiquidacaoResto>;
  protected unsubscribe: Subject<void> = new Subject();

  @Input() login: Login;
  @Input() empNumero: number;
  @Input() idLiquidacao: number;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected messageService: MessageService,
    private globalService: GlobalService,
    private funcaoService: FuncaoService,
    private preService: PreLiquidacaoRestoService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.data = new Date();
    this.valor = 0;
    this.historico = '';
    this.liquidacaoId = 0;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    this.globalService.calendarMascara();
  }

  anular() {
    this.route.paramMap
      .pipe(switchMap(params => this.preService.anular(
        Number(this.idLiquidacao),
        String(this.funcaoService.converteDataSQL(this.data.toLocaleDateString())),
        this.valor,
        this.historico,
        this.login.usuario,
        String(this.funcaoService.converteDataSQL(this.data_referencia))
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (dados) => {
          $('#dialogAnular').modal('hide');
          this.router.navigate(['/pre-iquidacoes']);
          toastr.success('Anulação realizada com sucesso!');
        }, error => {
          if (error.error && error.error.payload) {
            toastr.error(error.error.payload);
          } else {
            toastr.error('Ocorreu um erro ao processar a sua solicitação');
          }
        });
  }

  buscarAnulacoes() {
    this.route.paramMap
      .pipe(switchMap(params => this.preService.filtrar(0, -1,
        {
          relations: 'usuario_cadastro',
          anulacao: true,
          exercicio_id: this.login.exercicio.id,
          orgao_id: this.login.orgao.id,
          'empenho.numero': this.empNumero
        }
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (dados) => {
          this.listAnulacoes = dados.content;
        }, error => {
          if (error.error && error.error.payload) {
            toastr.error(error.error.payload);
          } else {
            toastr.error('Ocorreu um erro ao processar a sua solicitação');
          }
        });
  }

}
