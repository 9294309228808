// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { SicHistorico } from './sic-historico.model';
import { Setor } from '../almoxarifado/setor.model';
import { Usuario } from '../comum/usuario.model';

export class Sic extends BaseResourceModel {
  constructor(
    public id?: number,
    public numero?: number,
    public data_abertura?: Date,
    public data_termino?: Date,
    public resumo?: string,
    public prioridade?: string,
    public situacao?: string,
    public texto?: string,
    public setor?: Setor,
    public cancelado?: boolean,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public orgao?: Orgao,
    public usuario?: Usuario,
    public historico?: SicHistorico,
    public historicos?: Array<SicHistorico>
  ) {
    super();
  }
  static converteJson(json: any): Sic {
    return Object.assign(new Sic(), json);
  }
}
