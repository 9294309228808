import { BaseResourceModel } from '../../models/base-resource.model';
import { EventoContabil } from './evento-contabil.model';

export class EventoContabilConcomitante extends BaseResourceModel {
  constructor(
    public id?: number,
    public evento?: EventoContabil,
    public concomitante?: EventoContabil,
    public condicao?: string,
    public editavel?: boolean,
  ) {
    super();
  }

  static converteJson(json: any): EventoContabilConcomitante {
    return Object.assign(new EventoContabilConcomitante(), json);
  }

}
