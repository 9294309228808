import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { PessoaModule, SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { FieldsetModule } from 'primeng/fieldset';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { TransferenciaPendenteRptRoutingModule } from './transferencia-pendente-rpt-routing.module';
import { TransferenciaPendenteRptComponent } from './transferencia-pendente-rpt.component';

@NgModule({
  declarations: [TransferenciaPendenteRptComponent],
  imports: [
    CommonModule,
    TransferenciaPendenteRptRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    IMaskModule,
    ToastModule,
    FieldsetModule,
    PessoaModule,
    CalendarModule,
    RadioButtonModule,
  ],
  exports: [
    TransferenciaPendenteRptComponent,
  ],
  providers: []
})
export class TransferenciaPendenteRptComponentModule { }
