import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AssinaturaService, BaseResourceRptPersonalizadoComponent, Credito, CreditoItem, FormatoExportacao, OrgaoAssinatura, OrgaoAssinaturaService } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";
import * as extenso from "extenso";
import * as toastr from "toastr";
import { CreditoService } from "../../credito/service/credito.service";
import { CreditoItemService } from "../../credito/service/credito-item.service";

@Component({
  selector: 'app-minuta-transferencia-rpt',
  templateUrl: './minuta-transferencia-rpt.component.html'
})
export class MinutaTransferenciaRptComponent extends BaseResourceRptPersonalizadoComponent {

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  private creditos: Credito[] = [];
  public decretoSelected: string;
  public leiSelected: string;
  public decretoList2: { key: string, value: string }[] = [];
  public leiList: {key: string, value: string }[] = [];
  public listFormato: FormatoExportacao[] = ['pdf', 'xlsx', 'xml', 'JSON', 'csv', 'docx', 'txt']
  public formato: FormatoExportacao;
  private assinaturaVigente: OrgaoAssinatura;
  public listaCreditoItens: Array<CreditoItem>;
  public da = [];


  constructor(
    private creditoService: CreditoService,
    protected assinaturaService: AssinaturaService,
    protected creditoItemService: CreditoItemService,
    protected orgaoAssinaturaService: OrgaoAssinaturaService,
  ) {
    super(assinaturaService);
    this.assinaturaObrigatoria = false;
    this.removerCabecalho = true;
    this.removerRodape = true;
  }

  protected afterInit(): void {

    //6074



 this.creditoItemService.obterPorCredito(6074)
  .pipe(takeUntil(this.unsubscribe))
  .subscribe((dados) => {
    this.listaCreditoItens = dados ? dados.content : new Array<CreditoItem>();
    for (const item of this.listaCreditoItens) {
        this.da.push({text: item.id})
        
    }
     
  });


    this.creditoService.filtrar(1, -1, {
      'relations': 'orgao,exercicio,itens.ficha',
      'exercicio.id': this.login?.exercicio?.id,
      'orgao.id': this.login?.orgao?.id,
      'ativo': true
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.creditos = res.content;


        res.content.forEach((val: Credito) => {
          if (!this.decretoList2.find(x => x.value === val.decreto_interno)) this.decretoList2.push({ key: val.decreto_interno == null ? '' : val.decreto_interno   , value: val.decreto_interno == null ? '' : val.decreto_interno  });
        });

        res.content.forEach((val: Credito) => {
          if (!this.leiList.find(x => x.value === val.lei)) this.leiList.push({ key: val.lei == null ? '' : val.lei   , value: val.lei == null ? '' : val.lei  });
        });


        this.decretoList2.sort((d1, d2) => +d1.value - +d2.value);

        this.leiList.sort((d1, d2) => +d1.value - +d2.value);

        



        // res.content.forEach((val: Credito) => {
        //   if (!this.leiList.find(x => x.value === val.lei.replace('/', ''))) this.leiList.push({ key: val.lei, value: val.lei.replace('/', '') });
        // });
        // this.leiList.sort((l1, l2) => +l1.value - +l2.value);
      });



    this.orgaoAssinaturaService.filtrar(1, -1, {
      'orgao.id': this.login?.orgao?.id,
      'data_limite$ge': this.funcaoService.converteDataSQL(new Date()),
      'orderBy': 'data_limite'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        if (res.content && res.content.length > 0) {
          this.assinaturaVigente = res.content[0];
        }
      });

    this.orientacao = 'portrait';
    this.formato = 'pdf';
  }

  protected modalRelatorio(): string {
    return null;
  }

  protected tituloRelatorio(): string {
    return '';
  }

  public imprimir(): void {
    if (!this.validar()) return;

    super.imprimir();
  }

  private validar(): boolean {
    if (!this.decretoSelected && !this.leiSelected) {
      toastr.warning(`Informe ao menos um número de decreto ou lei!`);
      return false;
    }

    if (!this.assinaturaVigente) {
      toastr.warning(`Não foi encontrado assinatura vigente para emitir o relatório!`);
      return false;
    }
    return true;
  }

  protected async montarConteudo(): Promise<{}[]> {
    const conteudo: {}[] = [];

    let dados = this.creditos.filter((credito: Credito) => {
      if (this.decretoSelected && this.leiSelected) {
        return credito.decreto === this.decretoSelected && credito.lei === this.leiSelected;
      }

      if (this.decretoSelected) {
        return credito.decreto === this.decretoSelected;
      }

      return credito.lei === this.leiSelected;
    });

    if (!dados) {
      toastr.warning('Não foi localizado minutas com os filtros informados!');
      return;
    }

    for (const credito of dados) {
      conteudo.push(this.dadosCabecalho(credito));
      conteudo.push(this.dadosMinuta(credito, false));
      conteudo.push(this.dadosMinuta(credito, true));
      conteudo.push(this.dadosAssinatura());
    }

    return conteudo;
  }

  protected dadosCabecalho(credito: Credito): {}[] {
    let brasaoImage: {};
    if (this.login?.brasao) {
      brasaoImage = { image: this.login.brasao, width: 65, height: 55, alignment: 'center', margin: [0, 12, 0, 0] };
    } else {
      brasaoImage = { margin: [0, 30, 0, 0], text: '' };
    }
    brasaoImage['rowSpan'] = 1;
    brasaoImage['border'] = [false];

    return [
      {
        layout: 'linhas',
        unbreakable: true,
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*'],
          body: [
            [brasaoImage],
            [
              {
                text: `${this.login?.orgao?.nome ? this.login?.orgao?.nome.toUpperCase() : ''}`,
                bold: true, alignment: 'center', fontSize: 12, border: [false], margin: [0, 13, 0, 0]
              }
            ], [
              {
                text: `DECRETO Nº ${credito?.decreto ? (credito?.decreto.replace('/', '').length === 8 ? '0' + this.funcaoService.mascarar('####/####', credito.decreto.replace('/', '')) : this.funcaoService.mascarar('#####/####', credito.decreto.replace('/', ''))) + ', DE ' +
                
               this.funcaoService.formatarDataExtenso(new Date()).toUpperCase()

                : ''}`,
                bold: true, alignment: 'center', fontSize: 12, border: [false], margin: [0, 28, 0, 0]
              }

            ]
          ]
        }
      }, {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: [ '*'],
          body: [
            [
        
              {
                text: `Dispõe sobre alterações no Orçamento de 2022, através de transferências de recursos entre dotações de classificações aprovadas na Lei nº. 9.099, de 24/11/2021 - Lei Orçamentária Anual, e dá outras providências.`,
                alignment: 'justify', fontSize: 10, border: [false], margin: [0, 0, 0, 0]
              }
            ]
          ]
        }
      },
      
         
      
      
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*'],
          body: [
            [
              
             
              {
                text: `\u200B\t\t\t\t\t\t\t\t\t\t\t\t\t\t ${this.login?.usuario?.nome.toUpperCase()} , Prefeito Municipal de Franca,
                Estado de ${this.login?.cidade?.estado?.nome}, no exercício de suas atribuições legais,`,


                


                alignment: 'left', fontSize: 10, border: [false], margin: [0, 0, 0, 25]
              }

             
              
            ],
            [
              { text: 'D E C R E T A', alignment: 'center', fontSize: 12, border: [false], margin: [0, 5] }
 
             ],
            [
               { text: `Art. 1º - Ficam efetuadas, através da Secretaria Municipal de Finanças, as transferências de
               recursos orçamentários, respeitada a Lei nº. 9.099, de 24/11/2021 - Lei
               Orçamentária Anual, no valor total de 3.706.741,79 (três milhões e setecentos e
               seis mil e setecentos e quarenta e um reais e setenta e nove centavos), destinadas
               às seguintes classificações:`, alignment: 'center', fontSize: 12, border: [false], margin: [0, 5] }
            ]
            
          
          ]
        }
      }
    ];
  }

  protected dadosMinuta(credito: Credito, anulacao: boolean): {}[] {
    
       

     return this.da;
 
  }

  protected dadosAssinatura(): {} {
    return {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*'],
        body: [
          [
            {
             
            }
          ]
        ]
      }
    };
  }

  protected layoutRelatorio(): {} {
    return {
      table_eddy: {
        hLineWidth: function () {
          return 1;
        },
        vLineWidth: function () {
          return 0;
        },
        hLineColor: function () {
          return 'black';
        },
        paddingLeft: function () {
          return 3;
        },
        paddingRight: function () {
          return 3;
        },
        paddingTop: function () {
          return 8;
        },
        paddingBottom: function () {
          return 8;
        }
      }
    }
  }


  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  public hide() {
    this.visualizar = false;
    this.decretoSelected = null;
    this.leiSelected = null;
    this.visualizarChange.emit(this.visualizar);
  }
}