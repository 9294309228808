import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class GrupoArquivoTransparencia extends BaseResourceModel {
    constructor(
        public id?: number,
        public nome?: string,
        public descricao?: string,
        public orgao?: Orgao
    ) {
        super();
    }

    static converteJson(json: any): GrupoArquivoTransparencia {
        return Object.assign(new GrupoArquivoTransparencia(), json);
    }
}