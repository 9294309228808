// tslint:disable: variable-name
import { SituacaoProposta } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Memorial } from './memorial.model';
import { Proponente } from './proponente.model';

export class Proposta extends BaseResourceModel {
  constructor(
    public id?: number,
    public valor_unitario?: number,
    public quantidade_final?: number,
    public valor_final?: number,
    public marca?: string,
    public proponente?: Proponente,
    public situacao?: SituacaoProposta,
    public motivo?: string,
    public memorial?: Memorial,
    public exerceu_preferencia?: boolean,
    public editavel?: boolean,
    public porcentagem?: number,
    public proxima_rodada?: number,
    public valor_anterior?: number,
    public valor_desconto?: number,
    public sequencial_pncp?: number,
    public data_homologacao?: Date,
    public data_cancelamento?: Date,
    public motivo_cancelamento?: string,
    public subcontratacao: boolean = false,
    public motivoDesclassificacao?: string,
    public aplicacao_preferencia?: boolean,
    public amparo_legal_preferencia?: number,
    public pais_origem_produto?: string,
    public aplicacao_desempate?: boolean,
    public amparo_legal_desempate?: number,
    public simbolo_moeda?: string,
    public data_cotacao_moeda?: Date,
    public timezone_cotacao_moeda?: string,
    public valor_nominal_moeda?: number,
  ) {
    super();
  }
}
