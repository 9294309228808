import { BaseResourceRptComponent, Coluna, FuncaoService, PlanoContratacaoAnual } from "eddydata-lib";
import { PlanoContratacaoAnualService } from "../../plano-contratacao-anual/service/plano-contratacao-anual.service";
import { takeUntil } from "rxjs/operators";

export class PlanoContratacaoPlanejamentoRelatorioSecretaria extends BaseResourceRptComponent {

  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  constructor(
    protected planoService: PlanoContratacaoAnualService,
    protected funcaoService: FuncaoService
  ) {
    super()
  }


  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================

  protected afterInit(): void {
    this.login
  }

  protected tituloRelatorio(): string {
    return this.login.orgao.nome
  }

  protected subTituloRelatorio(): string {
    return 'PLANO DE CONTRATACAO ANUAL - PLANEJAMENTO / POR SECRETARIA'
  }

  protected obterColunasRelatorio(): Coluna[] {
    return [
      { titulo: 'Secretaria', coluna: 'secretaria', agrupar: true, bold: true },
      { titulo: 'Subelemento', coluna: 'subelemento' },
      { titulo: 'Valor', coluna: 'valor', decimais: 2, alignment: 'right', }
    ]

  }

  protected larguraColunas(): (string | number)[] {
    return ['*', 'auto']

  }

  protected totalizarColunas(): (string | {})[] {
    return ['valor']

  }

  public carregarLista(): Promise<any[]> {
    const parametros = {
      'orgao.id': this.login.orgao.id,
      'exercicio.id': this.login.exercicio.id,
      'relations': 'itens,orgao,exercicio,unidade'
    }

    return new Promise((resolve) => {
      this.planoService.filtrar(1, -1, parametros).pipe(takeUntil(this.unsubscribe)).subscribe(lista => {
        this.formato = 'pdf'
        resolve(this.agruparSubelementoTotalizadores(lista.content))
      })
    })
  }


  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  public agruparSubelementoTotalizadores(lista: PlanoContratacaoAnual[]) {
    let novaLista = []

    lista.forEach(item => {
      item.itens.forEach(it => it['valor_total'] = it.quantidade * it.valor_unitario)
      item.itens.sort((a, b) => a.subelemento > b.subelemento ? 1 : -1)

      this.funcaoService.agrupar(item.itens, 'subelemento', ['valor_total']).map(g =>
        ({ subelemento: g.grupo, valor: g.totalizadores['valor_total'] })
      ).forEach(sub => {

        novaLista.push({
          secretaria: item.unidade.nome,
          subelemento: sub.subelemento,
          valor: sub.valor
        })
      })
    })

    return novaLista
  }

}