
import { BaseResourceModel } from '../../models/base-resource.model'
import { Empenho } from '../contabil/empenho.model'
import { Tombamento } from './tombamento.model'

export class TombamentoEmpenhoExtra extends BaseResourceModel {
    constructor(
        public id?: number,
        public tombamento?: Tombamento,
        public empenho?: Empenho,
    ) {
        super()
    }
    static converteJson(json: any): TombamentoEmpenhoExtra {
        return Object.assign(new TombamentoEmpenhoExtra(), json)
    }
}