import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseResourceService, Convenio } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class RPConvenioService extends BaseResourceService<Convenio> {

  constructor(
    protected injector: Injector
  ) {
    super(`convenios`, injector);
  }

  public obterPorNumero(numero: number, ano: number, orgaoId: number): Observable<Convenio> {
    return this.http.get<Convenio>(`${this.login.cidade.id}/${this.api}/filtrar?numero=${numero}&exercicio.ano=${ano}&orgao_id=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public fichaVinculada(orgao: number, aplicacaoId: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/fichas-vinculadas?orgao=${orgao}&aplicacao_id=${aplicacaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public anexoRp05(ano: number, orgaoId: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.login.cidade.id}/${this.api}/anexo-rp05?&ano=${ano}&orgaos=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalidadorEntidade(orgaoId: number, limite): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/totalizador-entidade?orgao=${orgaoId}&limite=${limite}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterLicitacaoVinculada(convenio_id: number): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/licitacoes-vinculadas?convenio_id=${convenio_id}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public buscarSaldoConvenio(convenio_id: number, data_empenho: string, orgaoId: number, desconsiderar?: { rcmsId?: number, compraId?: number, empenhoId?: number }): Observable<number> {

    let query = `?convenio_id=${convenio_id}&data_empenho=${data_empenho}&orgao=${orgaoId}`

    if (desconsiderar?.rcmsId) {
      query = query + `&rcmsId=${desconsiderar?.rcmsId}&`
    };

    if (desconsiderar?.compraId) {
      query = query + `&compraId=${desconsiderar?.compraId}&`
    };

    if (desconsiderar?.empenhoId) {
      query = query + `&empenhoId=${desconsiderar?.empenhoId}&`
    };
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/buscar-saldo-convenio${query}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
