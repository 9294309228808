import { Injectable, Injector } from "@angular/core";
import { BaseResourceService, PlanoContaAnterior } from "eddydata-lib";

@Injectable({
  providedIn: 'root'
})
export class PlanoContaAnteriorService extends BaseResourceService<PlanoContaAnterior> {

  constructor(
    protected injector: Injector
  ) {
    super('plano-contas-anterior', injector);
  }
}