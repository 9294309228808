import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido } from './favorecido.model';
import { Cnae } from '../comum/cnae.model';
import { Orgao } from '../comum/orgao.model';

export class FavorecidoCnae extends BaseResourceModel {
  constructor(
    public id?: number,
    public principal?: boolean,
    public cnae?: Cnae,
    public favorecido?: Favorecido,
    public editavel?: boolean,
    public orgao?: Orgao
  ) {
    super();
  }
  static converteJson(json: any): FavorecidoCnae {
    return Object.assign(new FavorecidoCnae(), json);
  }
}
