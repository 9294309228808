import { EmissaoCheque } from 'contabil-lib';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { ContaBancaria } from './conta-bancaria.model';
import { OrdemPagamento } from './ordem-pagamento.model';
import { TransferenciaRecursoItem } from './transferencia-recurso-item.model';

export class TransferenciaRecurso extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_transferencia?: Date,
    public baixado?: boolean,
    public exportado_febraban?: boolean,
    public exportado_obn?: boolean,
    public numero_febraban?: number,
    public finalidade?: number,
    public exercicio?: Exercicio,
    public orgao?: Orgao,
    public ordem?: OrdemPagamento,
    public cheque?: EmissaoCheque,
    public origem?: Orgao,
    public destino?: Orgao,
    public conta_origem?: ContaBancaria,
    public conta_destino?: ContaBancaria,
    public itens?: TransferenciaRecursoItem[],
  ) {
    super();
  }

  static converteJson(json: any): TransferenciaRecurso {
    return Object.assign(new TransferenciaRecurso(), json);
  }
}
