import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EddyAutoComplete, GrupoEstoque, Login, Material, Produto, ProdutoUnidade, SubGrupoEstoque, UnidadeFornecimento } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { GrupoEstoqueService } from '../../grupo-estoque/service/grupo-estoque.service';
import { MaterialService } from '../../material/service/material.service';
import { SubGrupoEstoqueService } from '../../subgrupo-estoque/service/subgrupo-estoque.service';
import { SubGrupoSubElementoEstoqueService } from '../../subgrupo-estoque/service/subgrupo-subelemento-estoque.service';
import { UnidadeFornecimentoService } from '../../unidade-fornecimento/service/unidade-fornecimento.service';
import { ProdutoService } from '../service/produto.service';

declare var $: any;

@Component({
  selector: 'lib-produto-cadastro-dlg',
  templateUrl: './produto-cadastro-dlg.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProdutoCadastroDlgComponent implements OnInit, OnChanges {

  public listaGrupo: GrupoEstoque[];
  public listaGrupoServico: GrupoEstoque[];
  public grupoAtual: number;
  public listaSubGrupo: SubGrupoEstoque[];
  public subGrupoAtual: number;
  public listaMaterial: Material[];
  public unidade: UnidadeFornecimento;
  public unidadeAutoComplete: EddyAutoComplete<UnidadeFornecimento>;

  @Input() public nomeDlg: string = 'dialogProdutoCadastro';

  @Input() public login: Login;
  @Input() public servico: boolean = false;
  @Input() public subelemento: number
  @Output() callback: EventEmitter<any> = new EventEmitter();
  @Output() cancelarImportacoesA: EventEmitter<boolean> = new EventEmitter();

  public entidadeForm: FormGroup;
  protected router: Router;
  protected activatedRoute: ActivatedRoute;
  protected fb: FormBuilder;
  protected unsubscribe: Subject<void> = new Subject();


  constructor(
    protected injector: Injector,
    protected grupoService: GrupoEstoqueService,
    protected subGrupoService: SubGrupoEstoqueService,
    protected materialService: MaterialService,
    protected unidadeService: UnidadeFornecimentoService,
    protected subGrupoElementoService: SubGrupoSubElementoEstoqueService,
    private produtoService: ProdutoService,
    private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.router = this.injector.get(Router);
    this.fb = this.injector.get(FormBuilder);
    this.activatedRoute = this.injector.get(ActivatedRoute);
    this.criarCamposForm();

    this.unidadeAutoComplete = new EddyAutoComplete(null, this.unidadeService,
      'id', ['nome'], { cidade_id: this.login?.cidade.id, orderBy: 'nome' }, { text: ['nome'] },
    );
  }

  async ngOnChanges(changes: SimpleChanges) {

    if (changes.servico) {
      this.listaGrupo = (await this.grupoService.filtrar(1, -1, { orgao_id: this.login?.orgao?.id, orderBy: 'codigo', 'subgrupos.materiais.servico': this.servico }).toPromise()).content;
      // this.changeDetector.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async submitForm() {
    if (this.subGrupoAtual && this.subelemento) {
      if (await this.verificarVinculoSubGrupoSubElemento()) {
        this.cancelarImportacoesA.emit(true)
        $(`#${this.nomeDlg}`).modal('hide')
        toastr.error('O SUBGRUPO e o SUBELEMENTO da ficha não estão vinculados. Para continuar, procure o setor responsável')
        return;
      }
    }

    if (this.entidadeForm.invalid) {
      toastr.error('Há campos obrigatórios não informados!');
      return;
    }

    const unidades: ProdutoUnidade[] = [];
    unidades.push({ unidade: this.unidade });
    this.entidadeForm.get('unidades').setValue(unidades);

    this.criarEntidade();
  }

  private criarEntidade() {
    const entidade: Produto = Produto.converteJson(this.entidadeForm.value);
    entidade.orgao = this.login.orgao;
    this.produtoService
      .inserir(entidade)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.acaoSucesso(res);
      },
        error => this.acaoErro(error));
  }

  private acaoSucesso(entidade: Produto) {
    toastr.success('Registro salvo com sucesso!');
    this.callback.emit(entidade);
    this.fechar(entidade);
  }

  public fechar(entidade?) {
    this.criarCamposForm();
    // precisa verificar se o serviço foi criado.
    // caso falso, chama a função this.cancelarImportacoes.emit(true)
    // caso verdadeiro, deverá pular essa função
    // if (!entidade) {
    //   this.cancelarImportacoesA.emit(true)
    // }
    $(`#${this.nomeDlg}`).modal('hide');
  }

  private acaoErro(error: any) {
    toastr.options.timeOut = 10000;
    toastr.options.closeButton = true;
    toastr.options.tapToDismiss = false;
    if (error.error && error.error.payload) {
      toastr.error(error.error.payload);
    } else {
      toastr.error('Ocorreu um erro ao processar a sua solicitação');
    }
  }

  private criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      bec: [false],
      nome: [null, [Validators.required]],
      descricao: [null, [Validators.required]],
      material: [null, [Validators.required]],
      unidades: []
    });
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public carregarSubGrupo() {
    if (this.grupoAtual) {
      this.subGrupoAtual = null;
      this.entidadeForm.get("material").setValue(null);
      this.subGrupoService.filtrar(1, -1, { orgao_id: this.login.orgao?.id, grupo_id: this.grupoAtual, orderBy: 'codigo', 'materiais.servico': this.servico }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaSubGrupo = res ? res.content : [];
          if (this.listaSubGrupo.length === 1) {
            this.subGrupoAtual = this.listaSubGrupo[0].id;
            this.carregarMaterial();
          }
        });
    }
  }

  public carregarMaterial() {
    if (this.subGrupoAtual) {
      this.materialService.filtrar(1, -1, {
        orgao_id: this.login.orgao.id,
        servico: this.servico, sub_grupo_id: this.subGrupoAtual, orderBy: 'codigo', relations: 'sub_grupo.grupo' 
      }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaMaterial = res ? res.content : [];
          if (this.listaMaterial.length === 1) {
            this.entidadeForm.get('material').patchValue(this.listaMaterial[0]);
          }
        });
    }
  }

  public async verificarVinculoSubGrupoSubElemento() {

    let res = await this.subGrupoElementoService.filtrar(1, -1, {
      'sub_grupo.id': this.subGrupoAtual,
      'sub_elemento.id': this.subelemento
    }).toPromise();
    return res.content.length === 0;
  }

}
