import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, FichaDespesa } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class AnexoLrfFederalService extends BaseResourceService<FichaDespesa> {

  constructor(
    protected injector: Injector
  ) {
    super(`anexos-rreo-federal`, injector);
  }

  obterAnexo1(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo1?mes=${mes}&exercicio=${exercicio}&orgaos=${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo2(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo2?mes=${mes}&exercicio=${exercicio}&orgaos=${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo3(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo3?mes=${mes}&exercicio=${exercicio}&orgaos=${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo3Resuldado(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo3-resultado?mes=${mes}&exercicio=${exercicio}&orgaos=${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo6(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo6?mes=${mes}&exercicio=${exercicio}&orgaos=${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo7(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo7?mes=${mes}&exercicio=${exercicio}&orgaos=${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo8(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo8?mes=${mes}&exercicio=${exercicio}&orgaos=${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  
  obterAnexo9(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo9?mes=${mes}&exercicio=${exercicio}&orgaos=${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo11(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo11?mes=${mes}&exercicio=${exercicio}&orgaos=${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo1Rgf(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo1-rgf?mes=${mes}&exercicio=${exercicio}&orgaos=${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo2Rgf(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo2-rgf?mes=${mes}&exercicio=${exercicio}&orgaos=${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo3Rgf(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo3-rgf?mes=${mes}&exercicio=${exercicio}&orgaos=${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo4Rgf(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo4-rgf?mes=${mes}&exercicio=${exercicio}&orgaos=${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo5Rgf(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo5-rgf?mes=${mes}&exercicio=${exercicio}&orgaos=${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }


}
