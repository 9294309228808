// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { PlanoConta } from './plano-conta.model';
import { Orgao } from '../comum/orgao.model';
import { Exercicio } from '../comum/exercicio.model';

export class Diario extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public natureza?: string,
    public mes?: number,
    public aux?: number,
    public referencia?: number,
    public data_emissao?: Date,
    public data_lancamento?: Date,
    public data_arrecadacao?: Date,
    public data_prestacao?: Date,
    public data_devolucao?: Date,
    public data_publicacao?: Date,
    public data_vencimento?: Date,
    public data_pagamento?: Date,
    public data_liquidacao?: Date,
    public data_concessao?: Date,
    public processo?: string,
    public tipo_despesa?: string,
    public tipo_convenio?: string,
    public tipo_credor?: string,
    public regime_despesa?: string,
    public uo?: string,
    public ue?: string,
    public recurso?: string,
    public aplicacao?: string,
    public aplicacao_variavel?: string,
    public programa?: string,
    public acao?: string,
    public numero_convenio?: string,
    public ano_convenio?: number,
    public funcao?: string,
    public sub_funcao?: string,
    public agencia_banco?: string,
    public agencia_digito?: string,
    public banco?: string,
    public conta_corrente?: string,
    public conta_corrente_digito?: string,
    public credor?: string,
    public numero_licitacao?: string,
    public grupo_despesa?: string,
    public modalidade?: number,
    public recebedor?: number,
    public orgao_concessor?: number,
    public orgao_recebedor?: number,
    public numero_empenho?: number,
    public ano_empenho?: number,
    public numero_contrato?: string,
    public ano_contrato?: number,
    public receita?: string,
    public evento?: string,
    public despesa?: string,
    public valor?: number,
    public id_lancto?: number,
    public plano?: PlanoConta,
    public orgao?: Orgao,
    public exercicio?: Exercicio,
    public historico?: string,
    public recurso_siconfi?: string,
    public codigo_contrapartida?: string
    ) {
    super();
  }

  static converteJson(json: any): Diario {
    return Object.assign(new Diario(), json);
  }
}
