import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, OrdemPagamento, RestituicaoReceita, TransferenciaAutorizacao } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

interface BaixaArquivos {
  data_baixa: string;
  usuario: number;
  opsParaBaixar: ListaOP[];
  transfsParaBaixar: FEBRABANTransferencia[];
  autorizParaBaixar: TransferenciaAutorizacao[];
  restisParaBaixar: RestituicaoReceita[];
}

interface FEBRABANPagto {
  liquidacao_id: number;
  valor: number;
}
export interface FEBRABANTransferencia {
  id: number;
  valor: number;
}

interface ArquivoFEBRABAN {
  data_arquivo: Date;
  pagamentos: FEBRABANPagto[];
  transferencias: FEBRABANTransferencia[];
  restituicoes: FEBRABANTransferencia[];
}

export interface OpPorItem {
  id: number;
  numero_op: number;
  agencia: string;
  conta: string;
  total_op: string;
  recurso: number;
}

export interface ListaOP {
  id: number;
  recurso: number;
}

@Injectable({
  providedIn: 'root'
})
export class OrdemPagamentoService extends BaseResourceService<OrdemPagamento> {

  constructor(
    protected injector: Injector
  ) {
    super(`ordens-pagamento`, injector);
  }

  public proximoNumeroOP(exercicio: number, orgao: number): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/proximo-numero/${exercicio}/${orgao}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public leituraArquivo(arquivo: any, parametros: { simplificado?: 'S' | 'N', tipoArquivo?: string } = { simplificado: 'S' },) {

    const params = this.converterParametrosStr(parametros);

    const formData = new FormData();
    formData.append('file', arquivo);

    const headers = this.httpOptions();
    headers.headers = headers.headers.delete('Content-Type');

    return this.http.post(`${this.login.cidade.id}/${this.api}/leitura-arquivo/${this.login.orgao.id}/${this.login.exercicio.id}/${params}`,
      formData, headers)
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public baixaOpFEBRABAN(arquivo: any): Observable<ArquivoFEBRABAN> {

    const formData = new FormData();
    formData.append('file', arquivo);

    const headers = this.httpOptions();
    headers.headers = headers.headers.delete('Content-Type');

    return this.http.post<ArquivoFEBRABAN>(`${this.login.cidade.id}/${this.api}/baixa-op-febraban`,
      formData, headers)
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public baixarArquivoFEBRABAN({ opsParaBaixar, transfsParaBaixar, usuario, data_baixa, autorizParaBaixar, restisParaBaixar }: BaixaArquivos): Observable<number> {
    return this.http.post<number>(`${this.login.cidade.id}/${this.api}/baixar-varias-ops`, { opsParaBaixar, usuario, transfsParaBaixar, data_baixa, autorizParaBaixar, restisParaBaixar }, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public baixarOPItens(ordens: { id: number, data_pagamento?: Date }[], usuario: number): Observable<void> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/baixar-itens/${usuario}`, { ordens }, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public salvarExportado(campo: string, id: number, numeroFebraban: number): Observable<void> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/salvar-exportado/${campo}/${id}/${numeroFebraban}`, {}, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public baixarOP(id: number, recurso: number, usuario: number, data?: string): Observable<void> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/baixar/${id}/${recurso}/${usuario}${data ? `/${data}` : ''}`, undefined, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public relatorioOP(exercicio: number, orgao: number, ordenacao: string, usuario: number, data1: string, data2: string, numero1: number, numero2: number): Observable<{}[]> {
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/relatorio-op/${exercicio}/${orgao}/${ordenacao}/${usuario}/${data1}/${data2}/${numero1}/${numero2}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public empenhoSemVinculoOP(exercicio: number, orgao: number, tipoData: string, dataIncial: Date, dataFinal: Date, tipoDespesa: string, tipoRetencao: string, tipoValor: string): Observable<{}[]> {

    let data1 = new DatePipe('pt').transform(dataIncial, 'yyyy-MM-dd');
    let data2 = new DatePipe('pt').transform(dataFinal, 'yyyy-MM-dd');

    let params = `?tipoData=${tipoData}&dataIncial=${data1}&dataFinal=${data2}&tipoDespesa=${tipoDespesa}&tipoRetencao=${tipoRetencao}&tipoValor=${tipoValor}`
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/empenho-sem-vinculo-op/${exercicio}/${orgao}${params}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public opsPorItem(dados: FEBRABANPagto[], exercicio: number, orgao: number): Observable<OpPorItem[]> {
    return this.http.post<OpPorItem[]>(`${this.login.cidade.id}/${this.api}/ops-por-item`, { dados, exercicio, orgao }, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }
}
