import { BaseResourceModel } from '../../models/base-resource.model';
import { Despesa } from "./despesa.model";
import { DespesaSiconfi } from './despesa-siconfi.model';

export class DespesaSiconfiDespesa extends BaseResourceModel {
  constructor(
    public id?: number,
    public despesa_siconfi?: DespesaSiconfi,
    public despesa?: Despesa,
    public despesa_resto?: string,
  ) {
    super();
  }
  static converteJson(json: any): DespesaSiconfiDespesa {
    return Object.assign(new DespesaSiconfiDespesa(), json);
  }
}