import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ProgramaRoutingModule } from './programa-routing.module';
import { ProgramaDlgComponent } from './programa-dlg/programa-dlg.component';
import { SharedModule } from 'eddydata-lib';
import { ToastModule } from 'primeng/toast';
import { ProgramaListComponent } from './programa-list/programa-list.component';
import { ProgramaFormComponent } from './programa-form/programa-form.component';

@NgModule({
  declarations: [ProgramaListComponent, ProgramaFormComponent, ProgramaDlgComponent],
  imports: [
    CommonModule,
    FormsModule,
    ProgramaRoutingModule,
    SharedModule,
    ToastModule
  ],
  exports: [
    ProgramaDlgComponent
  ]
})
export class ProgramaModule { }
