import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Programa } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ProgramaService extends BaseResourceService<Programa> {

  constructor(
    protected injector: Injector
  ) {
    super(`programas`, injector);
  }

}
