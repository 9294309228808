import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FuncaoService, GlobalService, Login, OrgaoAssinaturaService, Relatorio } from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { ReservaDotacaoService } from '../service/reserva-dotacao.service';
import * as toastr from 'toastr';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-reserva-dotacao-rpt',
  templateUrl: './reserva-dotacao-rpt-dlg.component.html'
})
export class ReservaDotacaoRpt implements OnInit, OnDestroy {

  @Input() login: Login;

  protected datepipe: DatePipe;
  public ptBR: any;
  public selectedOpt: string;
  public reservaInicio: number;
  public reservaFinal: number;
  public processoInicio: string;
  public processoFinal: string;
  public fichaInicio: string;
  public fichaFinal: string;
  public recursoInicio: string;
  public recursoFinal: string;
  public aplicacaoInicio: string;
  public aplicacaoFinal: string
  public listaTipos: Array<any>;
  public tipo: { id: number, nome: string };
  public ficha_ativa: boolean = false;
  protected unsubscribe: Subject<void> = new Subject();

  public imaskNumero = {
    mask: Number,
    scale: 0,
    signed: false
  };

  constructor(
    protected confirmationService: ConfirmationService,
    protected messageService: MessageService,
    private globalService: GlobalService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected reservaDotacaoService: ReservaDotacaoService,
    protected funcaoService: FuncaoService
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.listaTipos = [
      { nome: 'PRÉ EMPENHO', id: '0' },
      { nome: 'SEM PRÉ EMPENHO', id: '1' }
    ];
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  imprimir() {
    if (this.reservaInicio && this.reservaFinal && (+this.reservaInicio > +this.reservaFinal)) {
      return toastr.warning(`Número inicial informado maior que o número final informado!`)
    }
    if (this.fichaInicio && this.fichaFinal && (+this.fichaInicio > +this.fichaFinal)) {
      return toastr.warning(`Número inicial informado maior que o número final informado!`)
    }
    if (this.recursoInicio && this.recursoFinal && (+this.recursoInicio > +this.recursoFinal)) {
      return toastr.warning(`Número inicial informado maior que o número final informado!`)
    }
    if (this.aplicacaoInicio && this.aplicacaoFinal && (+this.aplicacaoInicio > +this.aplicacaoFinal)) {
      return toastr.warning(`Número inicial informado maior que o número final informado!`)
    }

    const parametros = {}
    if (this.selectedOpt === 'opt1') {
      parametros['id$bt'] = `${this.reservaInicio ? this.reservaInicio : this.reservaFinal}, ${this.reservaFinal ? this.reservaFinal : this.reservaInicio}`;
    }
    if (this.selectedOpt === 'opt2') {
      parametros['processo$bt'] = `${this.processoInicio ? this.processoInicio : this.processoFinal},${this.processoFinal ? this.processoFinal : this.processoInicio}`;
    }
    if (this.selectedOpt === 'opt3') {
      parametros['itens.ficha.numero$bt'] = `${this.fichaInicio ? this.fichaInicio : this.fichaFinal}, ${this.fichaFinal ? this.fichaFinal : this.fichaInicio}`;
    }
    if (this.selectedOpt === 'opt4' && !this.aplicacaoInicio) {
      parametros['itens.ficha.recurso.codigo$bt'] = `${this.recursoInicio ? this.recursoInicio : this.recursoFinal},${this.recursoFinal ? this.recursoFinal : this.recursoInicio}`;
    }
    if (this.selectedOpt === 'opt4' && !this.recursoInicio) {
      parametros['itens.ficha.aplicacao.codigo$bt'] = `${this.aplicacaoInicio ? this.aplicacaoInicio : this.aplicacaoFinal},${this.aplicacaoFinal ? this.aplicacaoFinal : this.aplicacaoInicio}`;
    }
    if (this.selectedOpt === 'opt4' && (this.recursoInicio && this.aplicacaoInicio)) {
      parametros['itens.ficha.recurso.codigo$bt'] = `${this.recursoInicio ? this.recursoInicio : this.recursoFinal},${this.recursoFinal ? this.recursoFinal : this.recursoInicio}`;
      parametros['itens.ficha.aplicacao.codigo$bt'] = `${this.aplicacaoInicio ? this.aplicacaoInicio : this.aplicacaoFinal},${this.aplicacaoFinal ? this.aplicacaoFinal : this.aplicacaoInicio}`;
    }
    if (this.tipo) {
      parametros['tipo_reserva'] = this.tipo.id;
    }

    this.ficha_ativa ? parametros['itens.ativo'] = true : null;

    parametros['orderBy'] = 'id$ASC'
    parametros['orgao.id'] = this.login.orgao.id
    parametros['exercicio'] = this.login.exercicio.id
    parametros['relations'] = 'itens.ficha,itens.estornos,licitacao,itens.ficha.executora,itens.ficha.programa,itens.ficha.aplicacao,itens.ficha.funcao,itens.ficha.subfuncao,' +
      'itens.ficha.recurso,itens.ficha.despesa,itens.ficha.funcao,itens.ficha.programa,itens.ficha.aplicacao_variavel,itens.ficha.acao'

    this.reservaDotacaoService.filtrar(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((resposta) => {
        if (resposta.content.length === 0)
          return toastr.warning(`Não foi encontrado registros para os filtros selecionados`)
        Relatorio.imprimirPersonalizado('Reserva Dotação', this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
          this.montarConteudo(resposta.content), 'portrait', 'Reserva dotação', {
          linhas: {
            hLineWidth() {
              return 1;
            },
            vLineWidth() {
              return 1;
            },
            hLineColor() {
              return 'black';
            },
            paddingLeft() {
              return 3;
            },
            paddingRight() {
              return 3;
            }
          }
        });
      });
  }

  montarConteudo(dados: any): {}[] {
    const conteudo = [];
    let index = 0;
    let fichas = 0;
    let pularPg = false;
    let total_ficha = 0;
    
    for (let reserva of dados) {
      let totLetrasObs = 0;
      if (reserva.tipo_reserva == 1) {
        totLetrasObs = reserva?.observacao.length
      } else {
        totLetrasObs = reserva?.licitacao?.objeto.length
      }

      if (reserva.itens.length > 1 && conteudo.length > 1) {
        conteudo.push([{ text: '', pageBreak: 'after', colSpan: 6 }, '', '', '', '', ''])
        index = 0
        pularPg = true
      } else if (index == 2 || fichas > 1 || pularPg === true) {
        conteudo.push([{ text: '', pageBreak: 'after', colSpan: 6 }, '', '', '', '', ''])
        index = 0
        pularPg = false
      } else if (totLetrasObs > 500) {
        conteudo.push([{ text: '', pageBreak: 'after', colSpan: 6 }, '', '', '', '', ''])
        index = 0
        pularPg = false
      } else {
        conteudo.push([
          { text: ` `, fontSize: 2, colSpan: 6, bold: true, border: [false, false, false, false] },
          '', '', '', '', ''
        ]);
        pularPg = false
      }

      conteudo.push([
        { text: `Reserva Nº ${reserva.id}`, fontSize: 12, colSpan: 6, bold: true, border: [true, true, true, false], margin: [5, 5, 0, 2] },
        '', '', '', '', ''
      ]);

      conteudo.push([
        { text: `Processo: `, fontSize: 10, bold: true, border: [true, false, false, false], margin: [5, 0, 0, 2] },
        { text: `${reserva.processo ? this.funcaoService.mascarar('#####/####', reserva.processo.replace('/', '')) : ''}`, fontSize: 10, border: [false, false, false, false] },
        { text: `Orgão: `, fontSize: 10, alignment: 'right', bold: true, border: [false, false, false, false], margin: [0, 0, 15, 0] },
        { text: `${this.funcaoService.mascarar('##.##.##', reserva?.orgao?.codigo)} - ${reserva?.orgao?.nome} `, fontSize: 10, colSpan: 3, border: [false, false, true, false] },
        '', ''
      ]);

      conteudo.push([
        { text: `Obj. processo: `, fontSize: 10, bold: true, border: [true, false, true, false], colSpan: 6, margin: [5, 0, 0, 0] },
        '', '', '', '', ''
      ]);

      if (reserva.tipo_reserva == 1) {
        conteudo.push([
          { text: `${reserva?.observacao ? reserva?.observacao : '-'}`, fontSize: 10, colSpan: 6, border: [true, false, true, true], margin: [5, 0, 0, 0] },
          '', '', '', '', ''
        ]);
      } else {
        conteudo.push([
          { text: `${reserva?.licitacao?.objeto ? reserva?.licitacao?.objeto : '-'}`, fontSize: 10, colSpan: 6, border: [true, false, true, true], margin: [5, 0, 0, 0] },
          '', '', '', '', ''
        ]);
      }
      index ++
      
      fichas = 0;
      for (let item of reserva.itens) {
        const valorEstorno = item.estornos?.length ? +item.estornos.reduce((acc, item) => +item.valor_estorno + acc, 0) : 0;
        total_ficha += +item?.valor_reservado - valorEstorno;
        if (fichas >= 3) {
          conteudo.push([{ text: '', pageBreak: 'after', colSpan: 6, border: [false, false, false, false] }, '', '', '', '', '']);
          fichas = 1;
        } else if (pularPg){
          fichas ++;
        }

        conteudo.push([
          { text: `Ficha Nº: `, fontSize: 10, bold: true, border: [true, true, false, false], margin: [5, 0, 0, 0] },
          { text: `${item.ficha.numero}`, fontSize: 10, bold: true, border: [false, true, false, false], margin: [0, 0, 0, 0], colSpan: 2 },
          '',
          { text: `Uni. Executora: `, fontSize: 10, bold: true, border: [false, true, false, false], margin: [0, 2, 0, 0] },
          { text: `${item?.ficha?.executora?.codigo} - ${item?.ficha?.executora?.nome} `, fontSize: 10, colSpan: 2, border: [false, true, true, false], margin: [0, 2, 0, 0] },
          '',
        ]);

        conteudo.push([
          { text: `Elemento: `, fontSize: 10, bold: true, border: [true, false, false, false], margin: [5, 2, 0, 0] },
          { text: `${this.funcaoService.mascarar('##.##.##', item?.ficha?.despesa?.codigo)} - ${item?.ficha?.despesa?.nome}`, fontSize: 10, colSpan: 2, border: [false, false, false, false], margin: [0, 2, 0, 0] },
          '',
          { text: `Data: `, fontSize: 10, border: [false, false, false, false], bold: true, margin: [0, 2, 0, 0] },
          { text: `${this.funcaoService.converteDataBR(item.data_reserva)}`, fontSize: 10, alignment: 'left', colSpan: 2, border: [false, false, true, false], margin: [0, 2, 0, 0] },
          '',
        ]);

        conteudo.push([
          { text: `Projeto/Atividade: `, fontSize: 10, bold: true, border: [true, false, false, false], margin: [5, 2, 0, 0] },
          { text: `${this.funcaoService.mascarar('#.###', item?.ficha?.acao?.codigo)} - ${item?.ficha?.acao?.nome}`, fontSize: 10, colSpan: 2, border: [false, false, false, false], margin: [0, 2, 0, 0] },
          '',
          { text: `Função: `, bold: true, fontSize: 10, border: [false, false, false, false], margin: [0, 2, 0, 0] },
          { text: `${item?.ficha?.funcao.codigo} - ${item?.ficha?.funcao?.nome}`, colSpan: 2, fontSize: 10, border: [false, false, true, false], margin: [0, 2, 0, 0] },
          '',
        ]);

        conteudo.push([
          { text: `Programa: `, fontSize: 10, bold: true, border: [true, false, false, false], margin: [5, 2, 0, 0] },
          { text: `${item?.ficha?.programa?.codigo} - ${item?.ficha?.programa?.nome}`, fontSize: 10, colSpan: 2, border: [false, false, false, false], margin: [0, 2, 0, 0] },
          '',
          { text: `Sub-função: `, bold: true, fontSize: 10, border: [false, false, false, false], margin: [0, 2, 0, 0] },
          { text: `${item?.ficha?.subfuncao.codigo} - ${item?.ficha?.subfuncao?.nome}`, colSpan: 2, fontSize: 10, border: [false, false, true, false], margin: [0, 2, 0, 0] },
          '',
        ]);

        conteudo.push([
          { text: `Fonte Recurso: `, bold: true, fontSize: 10, border: [true, false, false, false], margin: [5, 2, 0, 0] },
          { text: `${item?.ficha?.recurso?.codigo} - ${item?.ficha?.recurso?.nome}`, colSpan: 5, fontSize: 10, border: [false, false, true, false], margin: [0, 2, 0, 0] },
          '', '', '', '',
        ]);

        conteudo.push([
          { text: `Aplicação: `, fontSize: 10, bold: true, border: [true, false, false, false], margin: [5, 2, 0, 0] },
          { text: `${item?.ficha?.aplicacao?.codigo} - ${item?.ficha?.aplicacao?.nome}`, fontSize: 10, colSpan: 2, border: [false, false, false, false], margin: [0, 2, 0, 0] },
          '',
          { text: `Valor: `, bold: true, fontSize: 10, border: [false, false, false, false], margin: [0, 2, 0, 0] },
          { text: `${this.funcaoService.convertToBrNumber(+item?.valor_reservado - +valorEstorno)}`, colSpan: 2, fontSize: 10, border: [false, false, true, false], margin: [0, 2, 0, 0] },
          '',
        ]);

        conteudo.push([
          { text: `Variável: `, fontSize: 10, bold: true, border: [true, false, false, true], margin: [5, 2, 0, 0] },
          { text: `${item?.ficha?.aplicacao_variavel?.codigo} - ${item?.ficha?.aplicacao_variavel?.nome}`, fontSize: 10, colSpan: 5, border: [false, false, true, true], margin: [0, 2, 0, 0] },
          '', '', '', '',
        ]);
      }
    }
    conteudo.push([
      { text: ``, colSpan: 3, border: [false, false, false, false], margin: [5, 2, 0, 0] },
      '',
      '',
      { text: `Total das Fichas: `, fontSize: 14, bold: true, colSpan: 2, border: [false, false, false, false], margin: [0, 2, 0, 0] },
      '',
      { text: `${this.funcaoService.convertToBrNumber(total_ficha)}`, fontSize: 14, colSpan: 1, border: [false, false, false, false], margin: [0, 2, 0, 0] },
    ]);

    // {text: `Unidade: `, bold:true, fontSize: 11, border: [true, true, true,true], margin: [0, 5, 0, 0]},
    // {text: `${item.ficha.executora.codigo} - ${item.ficha.executora.nome}`, fontSize: 11, border: [true, true, true,true], margin: [0, 5, 0, 0]},

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: [70, '*', '*', 70, '*', 110],
        body: conteudo
      }
    }];
  }

}
