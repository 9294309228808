import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { TransferenciaEspecieListComponent } from './transferencia-especie-list/transferencia-especie-list.component';
import { TransferenciaEspecieFormComponent } from './transferencia-especie-form/transferencia-especie-form.component';
import { TransferenciaEspecieViewComponent } from './transferencia-especie-view/transferencia-especie-view.component';


const routes: Routes = [
  { path: '', component: TransferenciaEspecieListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: TransferenciaEspecieFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: TransferenciaEspecieFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: TransferenciaEspecieViewComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransferenciaEspecieRoutingModule { }
