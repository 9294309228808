// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { ChamamentoFavorecido } from './chamamento-favorecido.model';

export class CredenciamentoHistorico extends BaseResourceModel {
  constructor(
    public id?: number,
    public mensagem?: string,
    public chamamento_favorecido?: ChamamentoFavorecido,
    public usuario?: Usuario,
    public data_cadastro?: Date
  ) {
    super();
  }
  static converteJson(json: any): CredenciamentoHistorico {
    return Object.assign(new CredenciamentoHistorico(), json);
  }
}
