import { Component, OnInit, Input, OnDestroy, AfterViewInit, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as toastr from 'toastr';
import { Login, GlobalService, FuncaoService, DateFormatPipe, RetencaoResto, PreLiquidacaoResto, LiquidacaoResto, Compra, CompraItem, EmpenhoResto } from 'eddydata-lib';
import { LiquidacaoRestoService } from '../service/liquidacao-resto.service';
import { RetencaoRestoService } from '../service/retencao-resto.service';
import { PreLiquidacaoRestoService } from '../../pre-liquidacao-resto/service/pre-liquidacao-resto.service';
import { CompraItemService, CompraService } from 'compra-lib';

declare var $: any;

@Component({
  selector: 'lib-liquidacao-resto-anulacao',
  templateUrl: './liquidacao-resto-anulacao.component.html'
})

export class LiquidacaoRestoAnulacaoComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() visualizar: boolean = false;
  @Input() login: Login;
  @Input() empNumero: number;
  @Input() idLiquidacao: number;
  @Input() data_referencia: Date;
  @Input() empenho: EmpenhoResto;
  @Input() valorLiquidado: number;
  @Input() valorPago: number;
  @Input() compraId: number;
  @Input() valorMovimento: number = 0;
  @Input() superafit: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();
  @Output() onFinalizar: EventEmitter<void> = new EventEmitter();

  public ptBR: any;
  public data: Date;
  public data_referencia2: Date;
  public valor: number;
  public historico: string;
  public liquidacaoId: number;
  public listAnulacoes: Array<LiquidacaoResto>;
  public preliquidacaoIncluir: PreLiquidacaoResto;
  public visualizarPre: boolean = false;
  public especie: string;
  public compra: Compra;
  public total: number = 0;
  public totalOriginal: number = 0;
  public diferencaEmpenhoCompra: number = 0;
  public totalEmpenhado: number = 0;
  public anularEmpenho: boolean;
  public listaRetencoes: Array<RetencaoResto>;
  public listaRetencoesAnuladas: Array<RetencaoResto>;
  public valorRetencao: number;
  public valorLiquidacao: number;
  public liquidacao: LiquidacaoResto
  protected unsubscribe: Subject<void> = new Subject();
  protected datepipe: DatePipe;

  //Totalizadores
  liquidado: number = 0;
  retido: number = 0;
  pago: number = 0;

  //Aviso
  retornoRetencoes = [];
  carregando: boolean = false;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected retencaoService: RetencaoRestoService,
    protected messageService: MessageService,
    private globalService: GlobalService,
    private funcaoService: FuncaoService,
    private liquidacaoService: LiquidacaoRestoService,
    protected preService: PreLiquidacaoRestoService,
    protected confirmationService: ConfirmationService,
    private compraService: CompraService,
    private compraItemService: CompraItemService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
    if (this.visualizar) {
      this.obterItensCompra();
    }
  }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.anularEmpenho = false;
    this.datepipe = new DatePipe('pt');
    this.data = new Date();
    this.valor = 0;
    this.historico = '';
    this.liquidacaoId = 0;
    this.data_referencia = new DateFormatPipe().transform(this.data_referencia, []);

    //Variaveis novos
    this.valorLiquidacao = 0;
    this.valorRetencao = 0;
    this.liquidado = 0;
    this.retido = 0;
    this.pago = 0;
    this.retornoRetencoes = [];

    if (this.idLiquidacao && !this.carregando) {
      this.carregando = true;
      this.obterRetencoes(this.idLiquidacao);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    this.globalService.calendarMascara();
  }

  public selectEspecie() {
    if (!this.anularEmpenho) {
      this.anularEmpenho = true;
    } else {
      this.anularEmpenho = false;
    }

    let ano = this.liquidacao.data_liquidacao.toString().substring(0, 4);

    if (this.anularEmpenho && this.liquidacao.empenho.ano == +ano) {
      this.confirmationService.confirm({
        message: 'O cancelamento de restos a pagar processado é por insuficiência financiaria ?',
        acceptLabel: "Sim",
        rejectLabel: "Não",
        rejectVisible: true,
        closeOnEscape: false,
        key: 'especie',
        header: 'Aviso!',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.especie = 'RPI';
        },
        reject: () => {
          this.especie = 'CRP';
        }
      });
    } else {
      this.especie = 'RNL';
    }
  }

  public anular() {
    if (this.valorMovimento > 0 && (this.valor != this.valorMovimento)) {
      this.messageService.add({ severity: 'error', summary: 'Atenção', detail: `Valor total da anulação (R$${this.valor}) é diferente do valor da movimentação ${this.valorMovimento}, liquidações de movimentação somente podem ser anuladas integralmente.` })
      return;
    }

    if (this.liquidacao.valor_liquidado == this.valorPago) {
      toastr.warning('Liquidação já paga, faça a anulação do pagamento antes de anular a liquidação');
      return;
    }

    if (this.valor > this.funcaoService.arredondar(+(this.liquidado - this.pago), 2)) {
      this.messageService.add({ severity: 'error', summary: 'Atenção', detail: `Valor total da anulação (R$${this.valor}) ultrapassa o limite de R$${this.funcaoService.arredondar(+(this.liquidado - this.pago), 2)}` })
      return;
    }
    if (this.retornoRetencoes.length > 0 && this.listaRetencoes.length > 0) {
      this.messageService.add({ severity: 'error', summary: 'Atenção', detail: `Existem impedimentos para que a anulação seja realizada, verifique a seção "ATENÇÃO"` })
      return;
    }
    if (this.listaRetencoesAnuladas?.length > 0) {
      let soma: number = 0.0;
      for (const r of this.listaRetencoesAnuladas) {
        soma = this.funcaoService.arredondar(+(soma + (r['total_retido_atual'] * -1)), 2);
      }

      if (this.valor < soma) {
        this.messageService.add({ severity: 'error', summary: 'Atenção', detail: `Valor sendo anulado inferior aos valores de retenções já anuladas, o mínimo deve ser R$${soma * -1}` });
        return;
      }
    }

    if (this.valorLiquidacao > 0) {
      let retencoes = [];
      retencoes = this.listaRetencoes;
      retencoes = retencoes.concat(this.listaRetencoesAnuladas);

      this.route.paramMap
        .pipe(switchMap(params => this.liquidacaoService.anular(
          Number(this.idLiquidacao),
          `${this.data.getFullYear()}-${this.data.getMonth() + 1}-${this.data.getDate()}`,
          this.valorLiquidacao,
          this.historico,
          this.login.usuario,
          this.especie,
          this.anularEmpenho,
          this.compra,
          retencoes,
          this.superafit
        )))
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (dados: LiquidacaoResto) => {
            this.preliquidacaoIncluir = dados?.liquidacao_anulada?.preliquidacao;
            if (this.preliquidacaoIncluir) {
              this.confirmationService.confirm({
                key: 'confirmNovaPreResto',
                header: 'Atenção',
                message: 'Ao realizar a anulação da liquidação a pré-liquidação será anulada automaticamente. Deseja cadastrar uma nova pré-liquidação?',
                acceptLabel: 'Sim',
                rejectLabel: 'Não',
                accept: () => this.novaPre(),
                reject: () => this.voltar(),
              });
            } else {
              this.voltar()
            }
          }, error => {
            if (error.error && error.error.payload) {
              toastr.error(error.error.payload);
            } else {
              toastr.error('Ocorreu um erro ao processar a sua solicitação');
            }
          });
    }
    // else if (this.valorRetencao > 0) {
    //   this.retencaoService.anular(this.listaRetencoes,
    //     String(this.funcaoService.converteDataSQL(this.data.toLocaleDateString())),
    //     this.historico,
    //     this.login.usuario).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
    //       this.voltar();
    //     }, (e) => {
    //       if (e.error && e.error.payload) {
    //         toastr.error(e.error.payload);
    //       } else {
    //         toastr.error('Ocorreu um erro ao processar a sua solicitação');
    //       }
    //     });
    // }
  }

  private novaPre(): void {
    this.hide();
    this.visualizarPre = true;
  }

  private voltar(): void {
    this.hide();
    this.router.navigate(['/liquidacoes-restos-pagar']);
    toastr.success('Anulação realizada com sucesso!');
    this.onFinalizar.emit();
  }

  public buscarAnulacoes() {
    this.route.paramMap
      .pipe(switchMap(params => this.liquidacaoService.filtrar(0, -1,
        {
          relations: 'usuario_cadastro',
          anulacao: true,
          exercicio_id: this.login.exercicio.id,
          orgao_id: this.login.orgao.id,
          'empenho.numero': this.empNumero
        }
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (dados) => {
          this.listAnulacoes = dados.content;
        }, error => {
          if (error.error && error.error.payload) {
            toastr.error(error.error.payload);
          } else {
            toastr.error('Ocorreu um erro ao processar a sua solicitação');
          }
        });
  }

  private obterRetencoes(id: number) {
    this.retencaoService.extendido(0, -1, {
      liquidacao_id: id,
      relations: 'ficha,ficha.ficha,ficha.plano,ficha.favorecido,liquidacao,liquidacao.empenho,liquidacao.empenho.favorecido,'
        + 'liquidacao.orgao,liquidacao.exercicio,anulacoes'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(async (data) => {
        this.valorRetencao = 0;
        this.retido = 0;
        const lst = data ? data.content : new Array<RetencaoResto>();
        this.listaRetencoes = [];
        this.listaRetencoesAnuladas = [];
        let valor = 0;
        // for (const item of lst) {
        //   if (item.anulado || item.valor_retido < 0) {
        //     this.retido += +item.valor_retido;
        //     continue;
        //   }

        //   if (item.valor_retido < 0) {
        //     this.listaRetencoesAnuladas.push(item);
        //     continue;
        //   }

        //   const obj = Object.assign(new RetencaoResto(), item);
        //   //Valores originais para comparação
        //   obj['valor_original'] = item.valor_retido;
        //   obj['ret_codigo'] = item.id;
        //   obj.valor_retido = 0;

        //   this.valor = this.valorRetencao;
        //   await this.verificarPagamentoRetencao(obj);

        //   //Total de valor a ser anulado nas retenções
        //   valor += +obj['valor_anulando'].toFixed(2);

        //   this.listaRetencoes.push(obj);

        //   //Total de valor retido
        //   this.retido += +item.valor_retido;
        // }

        for (const item of lst) {
          let total_anulado_retencao = 0;
          for (const retAnulacao of item?.anulacoes) {
            total_anulado_retencao += +retAnulacao.valor_retido
          }

          if (item.valor_retido < 0) {
            this.listaRetencoesAnuladas.push(item);
            continue;
          }

          if (item.anulado) {
            this.retido += +item.valor_retido + total_anulado_retencao;
            continue;
          }

          const obj = Object.assign(new RetencaoResto(), item);
          //Valores originais para comparação
          obj['valor_original'] = item.valor_retido;
          obj['ret_codigo'] = item.id;
          obj.valor_retido = 0;

          this.valor = this.valorRetencao;
          await this.verificarPagamentoRetencao(obj);

          //Total de valor a ser anulado nas retenções
          this.valorRetencao += +obj['valor_anulando'];

          this.listaRetencoes.push(obj);

          //Total de valor retido
          this.retido += +item.valor_retido;
        }
        // this.valorRetencao = +valor.toFixed(2);
        this.carregando = false;
        this.totalizar(false);
      }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));
  }

  private async verificarPagamentoRetencao(retencao: RetencaoResto) {
    const retorno = await this.retencaoService.verificarPagamentoRetencao(retencao.id, this.login.exercicio.id, this.login.orgao.id).toPromise();

    retencao['disponivel_anulacao'] = this.funcaoService.arredondar(+(retencao['total_retido_atual'] - +retorno[1]), 2);
    if (+retencao['disponivel_anulacao'] > +retencao['valor_original']) {
      retencao['disponivel_anulacao'] = +retencao['valor_original']
    }
    retencao['valor_anulando'] = this.funcaoService.arredondar(+(+retencao['disponivel_anulacao']), 2);
    retencao.valor_retido = this.funcaoService.arredondar(+(+retencao['total_retido_atual'] - +retencao['valor_anulando']), 2);

    if (retorno[0] != '' && !retorno[0].includes('já está paga e precisa antes ser anulada no pagamento')) this.retornoRetencoes.push(retorno);
  }

  public async totalizar(jaTotalizado: boolean) {
    this.valor = 0;
    if (!jaTotalizado) {
      try {
        this.liquidacao = await this.liquidacaoService.obter({ id: this.idLiquidacao, relations: 'empenho', 'orgao.id': this.login.orgao.id, 'exercicio.id': this.login.exercicio.id }).toPromise();
        const liq = this.liquidacao;
        let totalizadores = { total_liquidado: 0, total_pago: 0 }
        totalizadores.total_liquidado = (await this.liquidacaoService.obterTotalLiquidadoPorParcela(liq.empenho.numero, liq.exercicio.id, liq.orgao.id, liq.parcela).toPromise()).liquidado;
        totalizadores.total_pago = (await this.liquidacaoService.obterTotalPagoPorParcela(liq.exercicio.id, liq.orgao.id, liq.id).toPromise()).pago;
        
        if (totalizadores) {
          this.liquidado = totalizadores.total_liquidado;
          this.pago = totalizadores.total_pago;

          this.valorLiquidacao = this.funcaoService.arredondar(+((this.liquidado - this.pago) - this.retido), 2);
          this.valor = this.funcaoService.arredondar(+(this.valorLiquidacao + this.valorRetencao), 2);

          if (this.valorLiquidacao >= this.funcaoService.arredondar(+(this.liquidado - this.pago), 2)) {
            this.valorLiquidacao = this.funcaoService.arredondar(+(this.liquidado - this.retido), 2);
            this.valor = this.funcaoService.arredondar(+(this.valorLiquidacao + this.valorRetencao), 2);
          }

          if (this.pago > 0 && this.listaRetencoes.length > 0) {
            this.retornoRetencoes = [];
            this.retornoRetencoes.push(['Liquidação paga, as anulações das retenções devem ser feitas pelo pagamento dessa liquidação se necessário. '
              + `Atualmente R$${this.funcaoService.arredondar(this.retido, 2)} em ${this.listaRetencoes.length} ${(this.listaRetencoes.length > 1 ? 'retenções' : 'retenção')}.`, 0]);

            this.listaRetencoes = [];
            this.valorRetencao = 0;
            this.valorLiquidacao = this.funcaoService.arredondar(+(this.liquidado - this.pago), 2);
            this.valor = this.valorLiquidacao;
          }
          this.totalizar(true);//Totaliza as retenções carregadas;
        } else {
          this.liquidado = 0;
          this.pago = 0;
          this.valorLiquidacao = 0;
          this.valor = 0;
          this.retido = 0;
          this.listaRetencoes = new Array<any>();
          this.retornoRetencoes.push(['Falha ao encontrar e contabilizar retenções e pagamento de rentenções, por favor, recarregue a pagina, se o erro persistir, entre em contato com um administrador.', 0]);
        }
      } catch (e) {
        this.liquidado = 0;
        this.pago = 0;
        this.valorLiquidacao = 0;
        this.valor = 0;
        this.retido = 0;
        this.listaRetencoes = new Array<any>();
        this.retornoRetencoes.push(['Falha ao encontrar e contabilizar retenções e pagamento de rentenções, por favor, recarregue a pagina, se o erro persistir, entre em contato com um administrador.', 0]);

      }
    } else {
      if (this.valorLiquidacao > this.funcaoService.arredondar(+(this.liquidado - this.pago - (this.listaRetencoes?.length > 0 ? this.retido : 0)), 2)) {
        this.valorLiquidacao = this.funcaoService.arredondar(+(this.liquidado - this.pago), 2);
        toastr.warning('Valor a anular da liquidação é superior ao disponível' + (this.retido > 0 ? ' considerando as retenções,' : ',')
          + ' valor ajustado automaticamente para o limite possível');
      }
      this.valor = this.funcaoService.arredondar(+(this.valorLiquidacao + this.valorRetencao), 2);
    }
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
    $('#dialogAnular').modal('hide');
  }

  public hidePre() {
    $('#dialogPreliquidacaoResto').modal('hide');
    this.visualizarPre = false;
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
    $('#dialogAnular').modal('show');
  }

  public focusInput(input: any) {
    let interval = setTimeout(() => {
      input.select();
      clearInterval(interval);
    }, 100);
  }

  public confirmarRemocao(item: RetencaoResto, index: number) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja remover item dessa anulação?',
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.removerItem(item, index)
      },
    });
  }

  private removerItem(item: RetencaoResto, index: number) {
    // this.listaRetencoes.splice(index, 1);
    // this.valorRetencao = 0;
    // let valor = 0;
    // for (const r of this.listaRetencoes) {
    //   valor += +r['valor_anulando'].toFixed(2);
    // }
    // this.valorRetencao = +valor.toFixed(2);
    // this.totalizar(true);

    this.listaRetencoes.splice(index, 1);
    this.valorRetencao = 0;
    for (const r of this.listaRetencoes) {
      this.valorRetencao += +r['valor_anulando'];
    }
    this.totalizar(true);
  }

  public salvarItem(retencao: RetencaoResto) {
    if (+retencao['valor_anulando'] < 0) {
      retencao.valor_retido = retencao['total_retido_atual'];
      retencao['valor_anulando'] = 0.0;
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Valor a ser anulado da retenção não pode ser negativo!' });
      return;
    } else if (+retencao['valor_anulando'] > +retencao['total_retido_atual']) {
      retencao.valor_retido = retencao['total_retido_atual'];
      retencao['valor_anulando'] = 0.0;
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Valor a ser anulado deve ser superior no máximo o valor original da retenção. !' });
      return;
    } else if (+retencao['valor_anulando'] > +retencao['disponivel_anulacao']) {
      retencao['valor_anulando'] = 0.0;
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Valor a ser anulado é superior ao valor disponível!' });
      return;
    }

    retencao.valor_retido = this.funcaoService.arredondar(+(+retencao['total_retido_atual'] - +retencao['valor_anulando']), 2);
    this.valorRetencao = 0;
    for (const r of this.listaRetencoes) {
      this.valorRetencao += +r['valor_anulando'];
    }
    // this.calcularTotal();
    this.totalizar(true);
  }

  obterItensCompra() {
    if (this.compraId > 0) {
      this.compraService.obter({ id: this.compraId, relations: 'itens,itens.produto_unidade,itens.produto_unidade.produto', 'itens.quantidade$gt': 0 }).subscribe(async (dados) => {
        this.compra = dados;
        if (this.compra) {
          let index = 0;
          this.calcularTotal();
          for (const item of this.compra.itens) {
            if (!item.produto_unidade) {
              this.confirmationService.confirm({
                message: 'O produto/serviço vinculado a esse empenho, não foi especificado o Tipo, a anulação será bloqueada até que os ajustes sejam feitos no Compras/Requisição, ou que os itens sejam removidos da listagem de anulação!',
                acceptLabel: "Entendido",
                rejectVisible: false,
                key: 'anular',
                header: 'Remoção',
                icon: 'pi pi-exclamation-triangle',
              });
              return;
            }
            let qtd = item.quantidade = await (item.produto_unidade.id ? this.compraItemService.obterSaldoAtualizado(this.compraId, item.produto_unidade.id) :
              this.compraItemService.obterSaldoAtualizado(this.compraId, item.produto, item.unidade)).toPromise();
            if (+qtd['quantidade_atual'] == 0) {
              this.compra.itens.splice(index, 1);
              continue;
            }
            item.quantidade = qtd['quantidade_atual'];
            item['valor_total'] = (((+item.quantidade * +item.valor_unitario) - +item.valor_desconto) + +item.valor_icmsipi).toString();
            item['quantidade_base'] = +item.quantidade;
            index += 1;
          }
        }
      });
    }
  }

  public calcularTotal() {
    this.total = 0;
    this.totalOriginal = 0;
    if (this.compra) {
      let vl = 0;
      for (const item of this.compra.itens) {
        vl += (((+item.quantidade * +item.valor_unitario) - +item.valor_desconto) + +item.valor_icmsipi)
        this.totalOriginal += +item['valor_total'];
      }
      this.diferencaEmpenhoCompra = this.funcaoService.arredondar(+(this.totalEmpenhado - this.totalOriginal), 2);
      this.total = this.ajuste(+vl, 2);
      this.valor = this.total;
    }
  }

  public ajuste(numero: number, casas: number) {
    const og = Math.pow(10, casas)
    return Math.trunc(numero * og) / og;
  }
}
