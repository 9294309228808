// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Precatorio } from './precatorio.model';

export class PrecatorioAdvogados extends BaseResourceModel {
  constructor(
    public id?: number,
    public tipo: number = 1,
    public documento?: string,
    public valor_pago?: number,
    public valor_pago_rra?: number,
    public precatorio?: Precatorio

  ) {
    super();
  }
  static converteJson(json: any): PrecatorioAdvogados {
    return Object.assign(new PrecatorioAdvogados(), json);
  }
}
