// tslint:disable: variable-name
import { Sistemas } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Estoque } from '../almoxarifado/estoque.model';
import { Setor } from '../almoxarifado/setor.model';
import { UsuarioEstoque } from '../almoxarifado/usuario-estoque.model';
import { UsuarioSetor } from '../almoxarifado/usuario-setor.model';
import { Favorecido } from '../compra/favorecido.model';
import { Unidade } from '../planejamento/unidade.model';
import { Acesso } from './acesso.model';
import { Orgao } from './orgao.model';

export class Usuario extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public sobrenome?: string,
    public cpf?: string,
    public telefone?: string,
    public email?: string,
    public senha?: string,
    public administrador?: boolean,
    public convidado?: boolean,
    public horario_inicio?: string,
    public horario_termino?: string,
    public sabado?: boolean,
    public domingo?: boolean,
    public ativo?: boolean,
    public controle_interno?: boolean,
    public solicitacao?: boolean,
    public orgao?: Orgao,
    public senha_nova?: string,
    public sistema?: Sistemas,
    public data_cadastro?: Date,
    public setor?: Setor,
    public unidade?: Unidade,
    public estoque?: Estoque,
    public favorecido?: Favorecido,
    public acessos?: Acesso[],
    public usuario_estoques?: UsuarioEstoque[],
    public usuario_setores?: UsuarioSetor[],
    public sistemas_administrador?: string,
    public login_audesp?: string,
    public senha_audesp?: string,
    public eddydata?: boolean, // este checkbox é não pode aparecer no front e apenas para controle nosso e para algumas rotinas espeficas 
    public tipo_chave?: number,
    public caminho_a1?: string,
  ) {
    super();
  }

  static converteJson(json: any): Usuario {
    return Object.assign(new Usuario(), json);
  }

}
