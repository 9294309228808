import { BaseResourceModel } from "../../models/base-resource.model";
import { FichaDespesa } from "../planejamento/ficha-despesa.model";
import { RplItem } from "./rpl-item.model";

export class RplItemFichaDespesa extends BaseResourceModel {
    constructor(
        public ordem?: number,
        public ficha_despesa?: FichaDespesa,
        public rpl_item?: RplItem,
        public quantidade?: number,
        public tipo_reserva?: 'TOTAL' | 'PARCIAL' | 'NENHUMA',
        public data_reserva?: Date,
        public mes_reservado?: string,
        public valor_reservado?: number,
        public ficha?: FichaDespesa,
        public alterar_ficha?:boolean,
        public editavel?: boolean,
    ) {
        super();
    }

    static converteJson(json: any): RplItemFichaDespesa {
        return Object.assign(new RplItemFichaDespesa(), json);
    }
}