import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, Recebimento, Page } from 'eddydata-lib';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RecebimentoService extends BaseResourceService<Recebimento> {

  constructor(
    protected injector: Injector
  ) {
    super(`recebimentos`, injector);
  }

  public totalArrecadadoAno(exercicio: number, orgaoId: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/total-arrecadado-ano/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalArrecadadoMes(exercicio: number, orgaoId: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/total-arrecadado-mes/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public numeroBoletim(data: string, exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/numero-boletim-recebimento/${data}/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public boletimRecebimento(filtros: {}): Observable<Array<any>> {
    let parametros = '';
    if (filtros) {
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + filtros[key];
      }
    }
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/boletim-recebimento${parametros}`).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public totalArrecadadoTributo(exercicio: number, orgaoId: number, codigo: string): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/total-arrecadado-tributo/${exercicio}/${orgaoId}/${codigo}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public planilhaArrecadacao(exercicio: number, orgaoId: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/planilha-arrecadacao/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public arrecadacaoPorNivel(orgao: number, exercicio: number, mes: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/arrecadacao-por-nivel/${orgao}/${exercicio}/${mes}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorNumero(numero: number, ano: number, orgaoId: number): Observable<Recebimento> {
    return this.http.get<Recebimento>(
      `${this.login.cidade.id}/${this.api}?numero=${numero}&exercicio_ano=${ano}&orgao_id=${orgaoId}`).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public transparencia(pagina: number, limite: number, filtros: {}): Observable<Page> {
    let parametros = '';
    if (filtros) {
      // tslint:disable-next-line: forin
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + encodeURIComponent(filtros[key]);
      }
    }

    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/transparencia/${pagina}/${limite}${parametros}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public uploadReceita(arquivo: any): Observable<any> {
    const formData = new FormData();
    formData.append('arquivo', arquivo);

    return this.http.post(`${this.login.cidade.id}/${this.api}/upload-receitas`, formData, {
      headers: new HttpHeaders(this.adicionarCriptografia({
        Authorization: this.login.token
      }))
    }).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
