// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { OrdemPagamentoItem } from './ordem-pagamento-item.model';

export class OrdemPagamentoItemConta extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public cpf?: string,
    public banco?: string,
    public agencia?: string,
    public agencia_digito?: string,
    public tipo_conta?: string,
    public conta_numero?: string,
    public valor?: number,
    public item?: OrdemPagamentoItem,
    public editavel?: boolean
  ) {
    super();
  }

  static converteJson(json: any): OrdemPagamentoItemConta {
    return Object.assign(new OrdemPagamentoItemConta(), json);
  }
}
