import { BaseResourceModel } from "../../models/base-resource.model";
import { Orgao } from "../comum/orgao.model";
import { TipoConvenioCondicaoDespesa } from "./tipo-convenio-condicao-despesa.model";

export class TipoConvenio extends BaseResourceModel {
    constructor(
        public id?: number,
        public nome?: string,
        public tce?: number,
        public orgao?: Orgao,
        public origem?: 'F' | 'C',
        public condicoes_despesa?: TipoConvenioCondicaoDespesa[]
    ) {
        super();
    }

    static converteJson(json: any): TipoConvenio {
        return Object.assign(new TipoConvenio(), json);
    }
}
