import { BaseResourceModel } from "../../models/base-resource.model";
import { TipoConvenio } from "./tipo-convenio.model";

export class TipoConvenioCondicaoDespesa extends BaseResourceModel {
    constructor(
        public id?: number,
        public condicao?: string,
        public tipo_convenio?: TipoConvenio
    ) {
        super();
    }

    static converteJson(json: any): TipoConvenioCondicaoDespesa {
        return Object.assign(new TipoConvenioCondicaoDespesa(), json);
    }
}
