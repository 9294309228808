import { Login, ProdutoLocalizacao } from "eddydata-lib";
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

export class EtiquetaLocalizacao {

    public async gerarEtiquetas(login: Login, localizacao: ProdutoLocalizacao) {
        const dd = {
            info: {
                title: 'etiquetas'
            },
            pageSize: {
                width: 155,
                height: 120,
            },
            content: [],
            pageMargins: <any>[0, 10, 0, 10]
        };

        dd.content = this.etiqueta(login, localizacao);

        pdfMake.createPdf(dd, null, null, pdfFonts.pdfMake.vfs).open();
    }

    private etiqueta(login: Login, localizacao: ProdutoLocalizacao) {
        const resultado: {}[] = [];

        resultado.push({ text: login.orgao.nome.toUpperCase(), alignment: 'center', fontSize: 7, margin: [0, 0, 0, 2] });
        resultado.push({ text: localizacao.armario, alignment: 'center', fontSize: 9, margin: [0, 0, 0, 2] });
        resultado.push({ text: localizacao.prateleira, alignment: 'center', fontSize: 9, margin: [0, 0, 0, 5] });
        resultado.push({ text: localizacao.produto.codigo, alignment: 'center', fontSize: 9, margin: [0, 0, 0, 5] });
        resultado.push({ text: localizacao.produto.nome, alignment: 'center', fontSize: 9, margin: [0, 0, 0, 0] });
        return resultado;
    }

    public layout(): {} {
        return {
            linhas: {
                hLineWidth(i) {
                    return 1;
                },
                vLineWidth(i, node, rowIndex) {
                    return 1;
                },
                hLineColor() {
                    return 'black';
                },
                paddingLeft() {
                    return 3;
                },
                paddingRight() {
                    return 3;
                }
            }
        };
    }

}