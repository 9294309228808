import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReservaDotacaoListComponent } from './reserva-dotacao-list/reserva-dotacao-list.component';
import { ReservaDotacaoFormComponent } from './reserva-dotacao-form/reserva-dotacao-form.component';
// import { ReservaDotacaoViewComponent } from './reserva-dotacao-view/reserva-dotacao-view.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: ReservaDotacaoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: ReservaDotacaoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: ReservaDotacaoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: ReservaDotacaoFormComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReservaDotacaoRoutingModule { }
