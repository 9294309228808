import { BaseResourceStorage } from '../../models/base-resource.storage';
import { InventarioSetor } from './inventario-setor.model';

export class InventarioSetorStorage extends BaseResourceStorage {
  constructor(
    public inventario_setor?: InventarioSetor,
  ) {
    super();
  }

  static converteJson(json: any): InventarioSetorStorage {
    return Object.assign(new InventarioSetorStorage(), json);
  }
}
