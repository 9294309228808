import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseResourceFormComponent, FuncaoService, GlobalService, LoginContabil, TransferenciaEspecie } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { TransferenciaEspecieService } from '../service/transferencia-especie.service';

@Component({
  selector: 'lib-transferencia-especie-view',
  templateUrl: './transferencia-especie-view.component.html'
})
export class TransferenciaEspecieViewComponent extends BaseResourceFormComponent<TransferenciaEspecie, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @ViewChild('nome') inputField: ElementRef;
  public totalTransferenciaEspecie: number;
  public listaEspecies: { id: string; nome: string; }[];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected tipoService: TransferenciaEspecieService) {
    super(new TransferenciaEspecie(), injector, TransferenciaEspecie.converteJson, tipoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      nome: [null, [Validators.required]],
      evento: [null, [Validators.required]],
      ativo: [true, [Validators.required]],
      padrao: [false, [Validators.required]],
      duodecimo: [false, [Validators.required]],
      retencao: [false, [Validators.required]],
      exige_descendio: [false, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'orgao' };
  }

  protected afterLoad() {

  }

  protected afterInit(): void {
    this.listaEspecies = this.globalService.obterListaEventos()
      .filter((e) => e.id !== 'VAR');
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected beforeSubmit() {
    try {
      if (this.entidadeForm.get('nome').value === '') {
        throw new Error('Informe o nome!');
      }
      if (this.entidadeForm.get('evento').value === '') {
        throw new Error('Informe o evento contábil para o tipo de transferência!');
      }
      this.entidadeForm.get('orgao').setValue(this.login.orgao);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(ent: TransferenciaEspecie) {
    this.loadResource();
    window.scrollTo(0, 0);
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
