import { Banco } from '../banco';
import { FuncaoService } from '../../util/funcao.service';

export class Bancoob extends Banco {
    public getNumero() {
        return '756';
    }


    public getDvNumero() {
        return '-0';
    }

    private getModalidadeCodCliente() {
        let s = (new FuncaoService()).desmascarar(' ', this.boleto.getContaCorrente());
        if (s.length == 10) {
            s = s.substring(0, 2) + s.substring(3);
        }
        return s;
    }

    private getCampoCodigoBarras() {
        let dvCampo2 = this.boleto.getDigitoCampo(this.getCampo2());
        dvCampo2 = dvCampo2.substring(dvCampo2.length - 1);
        return this.getNumero() + this.boleto.getMoeda() + this.boleto.getFatorVencimento() +
            this.boleto.getValorTitulo() + this.boleto.getCarteira() + this.boleto.getAgencia() +
            this.getModalidadeCodCliente() + this.boleto.getNossoNumero() + this.getDvNossoNumero() +
            '001';
    }


    public getCodigoBarras() {
        let campo = this.getCampoCodigoBarras();
        let dvCodigoBarra = this.boleto.getDigitoCodigoBarras(campo);
        campo = campo.substring(0, 4) + dvCodigoBarra +
            campo.substring(4, campo.length);
        return campo;
    }

    private getCampo2() {
        return this.getModalidadeCodCliente() + this.boleto.getNossoNumero().substring(0, 1);
    }

    private getDvNossoNumero() {
        let c = '319731973197319731973';

        let s = this.boleto.getAgencia() + '000' + this.getModalidadeCodCliente().substring(2) + this.boleto.getNossoNumero();
        let val = 0;
        for (let i = 0; i < s.length; i++) {
            val += Number.parseInt(s.substring(i, i + 1)) * Number.parseInt(c.substring(i, i + 1));
        }

        let resto = val % 11;
        if (resto <= 1) {
            return '0';
        }
        else {
            let resultado = 11 - resto;
            return resultado.toString();
        }

    }


    public getLinhaDigitavel() {
        let campo1 = this.getNumero() + this.boleto.getMoeda() + this.boleto.getCarteira() +
            this.boleto.getAgencia();
        campo1 = this.boleto.getDigitoCampo(campo1);

        let campo2 = this.getCampo2();
        campo2 = this.boleto.getDigitoCampo(campo2);

        let campo3 = this.boleto.getNossoNumero().substring(1) + this.getDvNossoNumero() + '001';
        campo3 = this.boleto.getDigitoCampo(campo3);

        let dvCodigoBarra = this.boleto.getDigitoCodigoBarras(this.getCampoCodigoBarras());

        let campo4 = this.boleto.getFatorVencimento() + this.boleto.getValorTitulo();

        return campo1 + campo2 + campo3 + dvCodigoBarra + campo4;
    }


    public getCarteiraFormatted() {
        return this.boleto.getCarteira();
    }


    public getAgenciaCodCedenteFormatted() {
        let codCliente = (new FuncaoService()).mascarar('#######-#', '0' + this.getModalidadeCodCliente().substring(2));
        return this.boleto.getAgencia() + ' / ' + codCliente;
    }


    public getNossoNumeroFormatted() {
        return this.boleto.getNossoNumero() + ' ' + this.getDvNossoNumero();
    }
}
