import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { IdentificacaoRptComponent } from './identificacao-rpt.component';

const routes: Routes = [
  { path: '', component: IdentificacaoRptComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IdentificacaoRptRoutingModule { }
