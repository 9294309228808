import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NacionalidadeRoutingModule } from './nacionalidade-routing.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NacionalidadeRoutingModule
  ]
})
export class NacionalidadeModule { }
