import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ContaBancaria, PagamentoExtra, Usuario } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RPPagamentoExtraService extends BaseResourceService<PagamentoExtra> {
  constructor(
    protected injector: Injector
  ) {
    super(`pagamentos-extras`, injector);
  }

  public obterPorNumero(numero: number, ano: number, orgaoId: number): Observable<PagamentoExtra> {
    return this.http.get<PagamentoExtra>(
      `${this.login.cidade.id}/${this.api}?numero=${numero}&exercicio_ano=${ano}&orgao_id=${orgaoId}`).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterTotalPagoPorEmpenho(numero: number, parcela: number, exercicioId: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/total-pago-por-empenho/${numero}/${parcela}/${exercicioId}/${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public ultimaDataPagamento(exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/ultima-data-pagamento/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public anular(id: number, data: string, valor: number, historico: string, usuario: Usuario, conta: ContaBancaria): Observable<PagamentoExtra> {
    const anulacao = { id, data, valor, historico, usuario, conta };
    return this.http.post<PagamentoExtra>(
      `${this.login.cidade.id}/${this.api}/anular`, JSON.stringify(anulacao), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    }).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }  

  public pagamentoEmLote(parametros: {
    numero_inicial: number, numero_final: number, data_pagamento: string, documento: string,
    exercicio: number, orgao: number, conta: number, ignorar?: {numero: number, parcela: number}[]
  }): Observable<{ numero: number, parcela: number, erro?: string }[]> {
    return this.http.post<{ numero: number, parcela: number, erro?: string }[]>(`${this.login.cidade.id}/${this.api}/pagamento-lote`,
      parametros, this.httpOptions());
  }
  
  public validarParcelas(numero_inicial: number, numero_final: number, exercicio: number, orgao: number,): Observable<{ numero: number, parcela: number }[]> {
    return this.http.get<{ numero: number, parcela: number }[]>(`${this.login.cidade.id}/${this.api}/duplicidade-pagamento-lote/${exercicio}/${orgao}/${numero_inicial}/${numero_final}`, this.httpOptions());
  }
}
