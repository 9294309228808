import { Component } from '@angular/core';
import {
  BaseResourceItemsComponent, DateFormatPipe, EddyAutoComplete, FavorecidoTerceiroSetor, FuncaoService, GlobalService, Pessoa, PessoaService
} from 'eddydata-lib';
import * as toastr from 'toastr';
import { FavorecidoTerceiroSetorService } from '../service/favorecido-terceiro-setor.service';

declare var $: any;

@Component({
  selector: 'lib-favorecido-terceiro-setor',
  templateUrl: './favorecido-terceiro-setor.component.html'
})
export class FavorecidoTerceiroSetorComponent extends BaseResourceItemsComponent<FavorecidoTerceiroSetor> {


  public pessoaAutoComplete: EddyAutoComplete<Pessoa>;
  public itemAtual: FavorecidoTerceiroSetor;
  public cadPessoa: Pessoa;

  public ptBR: any;

  constructor(
    public funcaoService: FuncaoService,
    public globalService: GlobalService,
    private pessoaService: PessoaService,
    favorecidoTerceiroSetorService: FavorecidoTerceiroSetorService) {
    super(new FavorecidoTerceiroSetor(), favorecidoTerceiroSetorService);
  }

  protected afterInit(): void {
    this.ptBR = new GlobalService().obterDataBR();
    this.carregarAutoCompletes();
  }


  protected afterEdit(item: FavorecidoTerceiroSetor) {
    item.dt_fim_mandato = new DateFormatPipe().transform(item.dt_fim_mandato, []);
    item.dt_inicio_mandato = new DateFormatPipe().transform(item.dt_inicio_mandato, []);
  }

  protected validSave(item: FavorecidoTerceiroSetor): boolean {
    if (!item.presidente || !item.presidente.id) {
      toastr.warning('Informe o presidente do presidente!');
      return false;
    }
    if (!item.dt_inicio_mandato) {
      toastr.warning('Informe data de inicio de mandato!');
      return false;
    }
    if (!item.dt_fim_mandato) {
      toastr.warning('Informe data de fim de mandato!');
      return false;
    }
    if (item.dt_fim_mandato.getTime() < item.dt_inicio_mandato.getTime()) {
      toastr.warning('Data de fim de mandato menor que data de início');
      return false;
    }
    return true;
  }

  protected beforeSave(item: FavorecidoTerceiroSetor): void {
    item.orgao = this.login.orgao
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================


  private carregarAutoCompletes() {
    this.pessoaAutoComplete = new EddyAutoComplete(null, this.pessoaService,
      'id', ['cpf_cnpj', 'nome'], { orderBy: 'nome' }, { number: ['cpf_cnpj'], text: ['nome'] }
    );
  }

  public cadastroPessoa(item: FavorecidoTerceiroSetor) {
    this.itemAtual = item;
    if (!this.itemAtual.presidente)
      this.cadPessoa = new Pessoa();
    else
      this.cadPessoa = this.itemAtual.presidente;
    $('#dialogPessoa').modal('show');
  }

  public callbackPessoa(pessoa: Pessoa) {
    this.itemAtual.presidente = pessoa;
    this.cadPessoa = new Pessoa();
  }

}
