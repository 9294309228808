import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TransferenciaBancariaListComponent } from './transferencia-bancaria-list/transferencia-bancaria-list.component';
import { TransferenciaBancariaViewComponent } from './transferencia-bancaria-view/transferencia-bancaria-view.component';
import { TransferenciaBancariaFormComponent } from './transferencia-bancaria-form/transferencia-bancaria-form.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: TransferenciaBancariaListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: TransferenciaBancariaFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: TransferenciaBancariaFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: TransferenciaBancariaViewComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransferenciaBancariaRoutingModule { }
