// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Setor } from './setor.model';

export class SetorLocalizacao extends BaseResourceModel {
  constructor(
    public id?: number,
    public localizacao?: string,
    public setor?: Setor,
    ) {
    super();
  }
  static converteJson(json: any): SetorLocalizacao {
    return Object.assign(new SetorLocalizacao(), json);
  }
}
