import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, EncerramentoContabil } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class EncerramentoContabilService extends BaseResourceService<EncerramentoContabil> {

  constructor(
    protected injector: Injector
  ) {
    super(`encerramentos-contabil`, injector);
  }
  
}
