import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Adiantamento, AdiantamentoStorage, Login } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { AdiantamentoStorageService } from '../service/adiantamento-storage.service';

declare var $: any;

@Component({
  selector: 'app-adiantamento-foto',
  templateUrl: './adiantamento-foto.component.html'
})
export class AdiantamentoFotoComponent {

  @Input() public login: Login;
  @Input() public entidade: Adiantamento;
  @Input() public arquivos: AdiantamentoStorage[];
  @Output() public callbackPrincipal: EventEmitter<AdiantamentoStorage> = new EventEmitter();

  public uploadedFiles: any[] = [];
  public descricao: string = null;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected confirmationService: ConfirmationService,
    private storageService: AdiantamentoStorageService) {
  }

  public async onUpload(event: any, fileUpload: any) {
    if (!event.files || event.files.length === 0) {
      toastr.warning('Selecione o(s) arquivo(s)');
      return;
    }
    try {
      for (const file of event.files) {
        this.uploadedFiles.push(file);
      }
      this.storageService
        .upload(this.uploadedFiles, this.login.orgao.id, this.entidade.id, this.descricao, true).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          for (const item of res) {
            item.data_cadastro = new Date();
            this.arquivos.unshift(item);
          }
        }, (res) => {
          toastr.error(res.error.payload);
          this.uploadedFiles.length = 0;
        });
    } catch (ex) {
      toastr.error(ex.message ? ex.message : ex);
    }
    this.descricao = undefined;
    this.uploadedFiles = []
    fileUpload.clear();
    $('#dialogUploadFoto').modal('hide');
  }

  public async remover(item: AdiantamentoStorage) {
    if (!item) return
    this.confirmationService.confirm({
      message: 'Deseja realmente remover o item?',
      header: 'Exclusão',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.storageService.remover(item.id).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro removido com sucesso!', 'Exclusão');
            this.arquivos.splice(this.arquivos.indexOf(item), 1);
          }, (err) => toastr.error(err.error.payload));
      }
    });
  }

  public permitirEditar() {
    this.entidade['editavel'] = true
  }

//   public async definirPrincipal(item: AdiantamentoStorage) {
//     if (!item) return
//     this.storageService.definirPrincipal(this.entidade.id, item.id).pipe(takeUntil(this.unsubscribe))
//       .subscribe((res) => {
//         this.storageService.filtrar(1, -1, { 'imovel.id': this.entidade.id, foto: true, orderBy: 'data_cadastro$DESC' })
//           .pipe(takeUntil(this.unsubscribe))
//           .subscribe((res) => {
//             this.arquivos = res.content;
//             this.callbackPrincipal.emit(item);
//           });
//       }, (err) => toastr.error(err.error.payload));
//   }

}