import { Injectable, Injector } from '@angular/core';
import { Exercicio, ExercicioService, FuncaoService, GlobalService, Login, OrgaoAssinatura, OrgaoAssinaturaService, OrgaoService, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FichaReceitaService } from '../../../../planejamento/ficha-receita/service/ficha-receita.service';

@Injectable({
  providedIn: 'root'
})
export class BalanceteReceitaRecursoService {

  protected unsubscribe: Subject<void> = new Subject();
  public login: Login = new Login();
  private assinatura: OrgaoAssinatura;

  constructor(
    protected frService: FichaReceitaService,
    protected exercicioService: ExercicioService,
    protected orgaoService: OrgaoService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected injector: Injector,
    protected assinaturaService: OrgaoAssinaturaService
  ) {
    this.login = GlobalService.obterSessaoLogin();
  }
  /* -------------------------- balancete da receita -------------------------*/

  async montarBalanceteReceitaRecurso(recurso: string, exercicioId: number, orgaos: number[], login: any, periodoInicial?: string, periodoFinal?: string, quadrimestre?: number, mes?: number) {
    const parametros: {} = {};

    parametros['ano'] = exercicioId;
    parametros['orgaos'] = orgaos.join();
    if (recurso !== 'TODOS') {
      parametros['recurso'] = recurso;
    }
    if (quadrimestre) {
      parametros['quadrimestre'] = quadrimestre;
    } else if (mes) {
      parametros['mes'] = mes;
    } else if (periodoInicial && periodoFinal) {
      parametros['data_inicio'] = periodoInicial;
      parametros['data_limite'] = periodoFinal;
    }

    const ex = await this.exercicioService.obterId(exercicioId).toPromise();
    this.frService.balanceteRecurso(parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        let subtitulo = '';
        if (periodoInicial && periodoFinal) {
          let dt = periodoInicial.split("-");
          subtitulo = `${dt[2]}/${dt[1]}/${dt[0]} à `;
          dt = periodoFinal.split("-");
          subtitulo += `${dt[2]}/${dt[1]}/${dt[0]}`;
        } else if (mes) {
          subtitulo = `${this.globalService.obterDataBR().monthNames[mes - 1].toLocaleUpperCase()}/${ex.ano} `;
        } else {
          subtitulo = `EXERCÍCIO - ${ex.ano}`;
          if (quadrimestre) {
            subtitulo += ` QUADRIMESTRE: ${+quadrimestre === 1 ? 'JANEIRO - ABRIL' : +quadrimestre === 2 ? 'MAIO - AGOSTO' : 'SETEMBRO - DEZEMBRO'}`;
          }
        }

        Relatorio.imprimirPersonalizado('DEMONSTRAÇÃO DA RECEITA ORÇAMENTÁRIA',
          login.usuario.nome, login.usuario.sobrenome, login.orgao.nome, login.brasao,
          this.balanceteRecursoConteudo(dados, quadrimestre, mes)
            .concat(await this.conteudoAssinatura(this.login, ex, mes, quadrimestre, periodoFinal)),
          'landscape', 'Balancete Analítico da Receita',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          }, false, false, 'pdf', `REFERÊNCIA: ${subtitulo}`);
      });
  }

  private balanceteRecursoConteudo(dados: any[], quadrimestre: number, mes: number): {}[] {
    const registros: {}[] = [];
    // const titulo = 'DEMONSTRAÇÃO DA RECEITA ORÇAMENTÁRIA';
    let widths: any[] = [];

    if (quadrimestre) {
      widths = ['auto', 'auto', '*', 65, 65, 65, 65, 65, 35];
      const grupos = this.funcaoService.agrupar(dados, ['re_codigo', 'a_codigo', 'av_variavel', 'a_nome'], ['mes_q1', 'mes_q2', 'mes_q3', 'mes_q4']);
      const mes_q1 = +quadrimestre === 1 ? 1 : +quadrimestre === 2 ? 5 : 9;
      const mes_q2 = +quadrimestre === 1 ? 2 : +quadrimestre === 2 ? 6 : 10;
      const mes_q3 = +quadrimestre === 1 ? 3 : +quadrimestre === 2 ? 7 : 11;
      const mes_q4 = +quadrimestre === 1 ? 4 : +quadrimestre === 2 ? 8 : 12;
      let total_q1 = 0.00;
      let total_q2 = 0.00;
      let total_q3 = 0.00;
      let total_q4 = 0.00;
      let total_geral = 0.00;

      registros.push(
        // [
        //   {
        //     text: titulo,
        //     fontSize: 9, colSpan: 9, alignment: 'center', bold: true
        //   }, '', '', '', '', '', '', '', ''
        // ],
        [
          { text: '\nFICHA', alignment: 'center', rowSpan: 2, bold: true, fontSize: 9 },
          { text: '\nCÓDIGO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 9 },
          { text: '\nESPECIFICAÇÕES', alignment: 'center', rowSpan: 2, bold: true, fontSize: 9 },
          { text: 'VALORES', alignment: 'center', colSpan: 5, bold: true, fontSize: 9 },
          '', '', '', '',
          { text: '\n%', alignment: 'center', rowSpan: 2, bold: true, fontSize: 9 },
        ],
        [
          '', '', '',
          { text: this.globalService.obterMes(mes_q1), alignment: 'center', bold: true, fontSize: 9 },
          { text: this.globalService.obterMes(mes_q2), alignment: 'center', bold: true, fontSize: 9 },
          { text: this.globalService.obterMes(mes_q3), alignment: 'center', bold: true, fontSize: 9 },
          { text: this.globalService.obterMes(mes_q4), alignment: 'center', bold: true, fontSize: 9 },
          { text: 'TOTAIS', alignment: 'center', bold: true, fontSize: 9 }, '',
        ]
      );
      for (const grupo of grupos) {
        const total_ficha = +grupo.totalizadores['mes_q1'] + +grupo.totalizadores['mes_q2'] + +grupo.totalizadores['mes_q3'] + +grupo.totalizadores['mes_q4'];
        total_geral += total_ficha;
        registros.push([
          { text: '', alignment: 'center', border: [true, false, true, false], bold: true, fontSize: 9 },
          { text: `${grupo.grupo['re_codigo']}.${grupo.grupo['a_codigo']}.${grupo.grupo['av_variavel'] ? grupo.grupo['av_variavel'] : '0000'}`, alignment: 'left', border: [true, false, true, false], fontSize: 9 },
          { text: grupo.grupo['a_nome'], alignment: 'left', border: [true, false, true, false], fontSize: 9 },
          { text: '', alignment: 'center', border: [true, false, true, false], fontSize: 9 },
          { text: '', alignment: 'center', border: [true, false, true, false], fontSize: 9 },
          { text: '', alignment: 'center', border: [true, false, true, false], fontSize: 9 },
          { text: '', alignment: 'center', border: [true, false, true, false], fontSize: 9 },
          { text: this.funcaoService.convertToBrNumber(total_ficha, 2, true), alignment: 'right', border: [true, false, true, false], decoration: 'underline', bold: true, fontSize: 9 },
          { text: '', alignment: 'center', border: [true, false, true, false], fontSize: 9 },
        ]);
        for (const registro of grupo.registros) {
          const total = +registro['mes_q1'] + +registro['mes_q2'] + +registro['mes_q3'] + +registro['mes_q4'];
          const porcentagem = !+total ? 0.00 : +registro['fh_valor_orcado'] / +total;
          total_q1 += +registro['mes_q1'];
          total_q2 += +registro['mes_q2'];
          total_q3 += +registro['mes_q3'];
          total_q4 += +registro['mes_q4'];
          registros.push([
            { text: registro['fh_numero'], alignment: 'center', border: [true, false, true, false], bold: true, fontSize: 8 },
            { text: this.funcaoService.mascarar('####.##.##.###', registro['r_codigo']), alignment: 'left', border: [true, false, true, false], fontSize: 8 },
            { text: registro['r_nome'], alignment: 'left', border: [true, false, true, false], fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(registro['mes_q1'], 2, true), alignment: 'right', border: [true, false, true, false], fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(registro['mes_q2'], 2, true), alignment: 'right', border: [true, false, true, false], fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(registro['mes_q3'], 2, true), alignment: 'right', border: [true, false, true, false], fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(registro['mes_q4'], 2, true), alignment: 'right', border: [true, false, true, false], fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(total, 2), alignment: 'right', border: [true, false, true, false], bold: true, fontSize: 8 },
            { text: `${this.funcaoService.convertToBrNumber(porcentagem, 2)}%`, alignment: 'center', border: [true, false, true, false], fontSize: 8 },
          ]);
        }
      }

      registros.push([
        '', '', '',
        { text: this.funcaoService.convertToBrNumber(total_q1, 2), alignment: 'right', bold: true, fontSize: 8 },
        { text: this.funcaoService.convertToBrNumber(total_q2, 2), alignment: 'right', bold: true, fontSize: 8 },
        { text: this.funcaoService.convertToBrNumber(total_q3, 2), alignment: 'right', bold: true, fontSize: 8 },
        { text: this.funcaoService.convertToBrNumber(total_q4, 2), alignment: 'right', bold: true, fontSize: 8 },
        { text: this.funcaoService.convertToBrNumber(total_geral, 2), alignment: 'right', bold: true, fontSize: 8 },
        '',
      ]);
    } else if (mes) {
      widths = ['auto', 'auto', '*', 65, 65, 65, 35];
      const grupos = this.funcaoService.agrupar(dados, ['re_codigo', 'a_codigo', 'av_variavel', 'a_nome'], ['valor_mensal']);
      let total_orcado = 0.00;
      let total_mensal = 0.00;
      let total_acumulado = 0.00;

      registros.push(
        // [
        //   {
        //     text: titulo,
        //     fontSize: 9, colSpan: 7, alignment: 'center', bold: true
        //   }, '', '', '', '', '', '',
        // ],
        [
          { text: '\nFICHA', alignment: 'center', rowSpan: 2, bold: true, fontSize: 9 },
          { text: '\nCÓDIGO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 9 },
          { text: '\nESPECIFICAÇÕES', alignment: 'center', rowSpan: 2, bold: true, fontSize: 9 },
          { text: 'VALORES', alignment: 'center', colSpan: 3, bold: true, fontSize: 9 }, '', '',
          { text: '\n%', alignment: 'center', rowSpan: 2, bold: true, fontSize: 9 }
        ],
        [
          '', '', '',
          { text: 'ORÇADA', alignment: 'center', bold: true, fontSize: 9 },
          { text: 'MENSAL', alignment: 'center', bold: true, fontSize: 9 },
          { text: 'ACUMULADA', alignment: 'center', bold: true, fontSize: 9 },
          '',
        ]
      );
      for (const grupo of grupos) {
        total_acumulado += grupo.totalizadores['valor_mensal'];
        registros.push([
          { text: '', alignment: 'center', border: [true, false, true, false], fontSize: 9 },
          { text: `${grupo.grupo['re_codigo']}.${grupo.grupo['a_codigo']}.${grupo.grupo['av_variavel'] ? grupo.grupo['av_variavel'] : '0000'}`, alignment: 'left', border: [true, false, true, false], fontSize: 9 },
          { text: grupo.grupo['a_nome'], alignment: 'left', border: [true, false, true, false], fontSize: 9 },
          { text: '', alignment: 'center', border: [true, false, true, false], fontSize: 9 },
          { text: '', alignment: 'center', border: [true, false, true, false], fontSize: 9 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['valor_mensal'], 2, true), alignment: 'right', border: [true, false, true, false], decoration: 'underline', bold: true, fontSize: 9 },
          { text: '', alignment: 'center', border: [true, false, true, false], fontSize: 9 },
        ]);
        for (const registro of grupo.registros) {
          const porcentagem = !+registro['valor_mensal'] ? 0.00 : +registro['fh_valor_orcado'] / +registro['valor_mensal'];
          total_orcado += +registro['fh_valor_orcado'];
          total_mensal += +registro['valor_mensal'];
          registros.push([
            { text: registro['fh_numero'], alignment: 'center', border: [true, false, true, false], bold: true, fontSize: 8 },
            { text: this.funcaoService.mascarar('####.##.##.###', registro['r_codigo']), alignment: 'left', border: [true, false, true, false], fontSize: 8 },
            { text: registro['r_nome'], alignment: 'left', border: [true, false, true, false], fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(registro['fh_valor_orcado'], 2, true), alignment: 'right', border: [true, false, true, false], fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(registro['valor_mensal'], 2, true), alignment: 'right', border: [true, false, true, false], fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(registro['valor_mensal'], 2, true), alignment: 'right', border: [true, false, true, false], fontSize: 8 },
            { text: porcentagem ? `${this.funcaoService.convertToBrNumber(+porcentagem, 2)}%` : '0%', alignment: 'center', border: [true, false, true, false], fontSize: 8 },
          ]);
        }
      }

      registros.push([
        '', '', '',
        { text: this.funcaoService.convertToBrNumber(total_orcado, 2), alignment: 'right', bold: true, fontSize: 8 },
        { text: this.funcaoService.convertToBrNumber(total_mensal, 2), alignment: 'right', bold: true, fontSize: 8 },
        { text: this.funcaoService.convertToBrNumber(total_acumulado, 2), alignment: 'right', bold: true, fontSize: 8 },
        '',
      ]);
    } else {
      widths = ['auto', 'auto', '*', 65, 65, 35];
      const grupos = this.funcaoService.agrupar(dados, ['re_codigo', 'a_codigo', 'av_variavel', 'a_nome'], ['valor_arrecadado']);
      let total_orcado = 0.00;
      let total_arrecadado = 0.00;

      registros.push(
        // [
        //   {
        //     text: titulo,
        //     fontSize: 9, colSpan: 6, alignment: 'center', bold: true
        //   }, '', '', '', '', ''
        // ],
        [
          { text: '\nFICHA', alignment: 'center', rowSpan: 2, bold: true, fontSize: 9 },
          { text: '\nCÓDIGO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 9 },
          { text: '\nESPECIFICAÇÕES', alignment: 'center', rowSpan: 2, bold: true, fontSize: 9 },
          { text: 'VALORES', alignment: 'center', colSpan: 2, bold: true, fontSize: 9 }, '',
          { text: '\n%', alignment: 'center', rowSpan: 2, bold: true, fontSize: 9 },
        ],
        [
          '', '', '',
          { text: 'ORÇADA', alignment: 'center', bold: true, fontSize: 9 },
          { text: 'ARRECADADA', alignment: 'center', bold: true, fontSize: 9 },
          '',
        ]
      );
      for (const grupo of grupos) {
        total_arrecadado += +grupo.totalizadores['valor_arrecadado'];
        registros.push([
          { text: '', alignment: 'center', border: [true, false, true, false], fontSize: 9 },
          { text: `${grupo.grupo['re_codigo']}.${grupo.grupo['a_codigo']}.${grupo.grupo['av_variavel'] ? grupo.grupo['av_variavel'] : '0000'}`, alignment: 'left', border: [true, false, true, false], fontSize: 9 },
          { text: grupo.grupo['a_nome'], alignment: 'left', border: [true, false, true, false], fontSize: 9 },
          { text: '', alignment: 'center', border: [true, false, true, false], fontSize: 9 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['valor_arrecadado'], 2), alignment: 'right', border: [true, false, true, false], decoration: 'underline', bold: true, fontSize: 9 },
          { text: '', alignment: 'center', border: [true, false, true, false], fontSize: 9 },
        ]);
        for (const registro of grupo.registros) {
          const porcentagem = !+registro['valor_arrecadado'] ? 0.00 : +registro['fh_valor_orcado'] / +registro['valor_arrecadado'];
          total_orcado += +registro['fh_valor_orcado'];
          registros.push([
            { text: registro['fh_numero'], alignment: 'center', border: [true, false, true, false], bold: true, fontSize: 8 },
            { text: this.funcaoService.mascarar('####.##.##.###', registro['r_codigo']), alignment: 'left', border: [true, false, true, false], fontSize: 8 },
            { text: registro['r_nome'], alignment: 'left', border: [true, false, true, false], fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(registro['fh_valor_orcado'], 2), alignment: 'right', border: [true, false, true, false], fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(registro['valor_arrecadado'], 2), alignment: 'right', border: [true, false, true, false], fontSize: 8 },
            { text: `${this.funcaoService.convertToBrNumber(porcentagem, 2)}%`, alignment: 'center', border: [true, false, true, false], fontSize: 8 },
          ]);
        }
      }

      registros.push([
        '', '', '',
        { text: this.funcaoService.convertToBrNumber(total_orcado, 2), alignment: 'right', bold: true, fontSize: 8 },
        { text: this.funcaoService.convertToBrNumber(total_arrecadado, 2), alignment: 'right', bold: true, fontSize: 8 },
        '',
      ]);
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 3,
        widths: widths,
        body: registros
      }
    }];
  }

  private async conteudoAssinatura(login: any, exercicio: Exercicio, mes: number, quadrimestre: number, dataFinal: string) {
    const conteudo = [];
    const data = dataFinal ?? (quadrimestre || mes ? `${exercicio.ano}-${this.funcaoService.strZero(mes ?? (+quadrimestre * 4), 2)}-${this.funcaoService.ultimoDiaMes(+mes ?? (+quadrimestre * 4), exercicio.ano)}` : `${exercicio.ano}-12-31`);
    this.assinatura = await this.assinaturaService.obter({ orgao_id: login.orgao.id, 'data_limite$ge': data, 'orderBy': 'data_limite$ASC' }).toPromise();

    if (!this.assinatura) {
      toastr.error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
      throw new Error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
    }

    conteudo.push([
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      },
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      },
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      }
    ]);
    conteudo.push([
      { text: this.assinatura.ordenador_despesa, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.contador, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.tesoureiro, border: [false, false, false, false], bold: true, alignment: 'center' }
    ]);
    conteudo.push([
      { text: this.assinatura.cargo_ordenador_despesa, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.cargo_contador + (this.assinatura.crc_contador ? ` - ${this.assinatura.crc_contador}` : ''), border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.cargo_tesoureiro, border: [false, false, false, false], bold: true, alignment: 'center' }
    ]);

    return [{
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', '*', '*'],
        body: conteudo
      }
    }];
  }

}
