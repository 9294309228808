import { Injectable, OnDestroy } from "@angular/core";
import { FichaReceitaService } from "administrativo-lib";
import { Exercicio, FichaDespesa, FichaReceita, FuncaoService, GlobalService, LoginContabil, Recurso, Relatorio } from "eddydata-lib";
import { Subject } from "rxjs";
import { FichaDespesaService } from "../../../ficha-despesa/service/ficha-despesa.service";
import { AnexoLoaService } from "../service/anexo-loa.service";

@Injectable({
  providedIn: 'root'
})
export class AplicacaoVinculada implements OnDestroy {

  private login: LoginContabil = new LoginContabil()
  protected funcaoService: FuncaoService
  protected unsubscribe: Subject<void> = new Subject();


  constructor(
    protected fichaReceitaService: FichaReceitaService,
    protected fichaDespesaService: FichaDespesaService,
    protected anexoServico: AnexoLoaService
  ) {
    this.funcaoService = new FuncaoService()
    this.login = GlobalService.obterSessaoLogin()
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async montarRelatorio(exercicio: Exercicio, orgaos: number[], fonteReceita?: number, fonteDespesa?: number) {

    const despesas = []
    const receitas = []

    // await this.anexoServico.obteraplicacaoVinculada(exercicio.id, orgaos, fonteReceita, fonteDespesa).toPromise()

    /**
    * A forma como este relatório estava sendo desenvolvido foi abortado pelo suporte
    * Ele não está completamente desenvolvido, mas como o diario atualmente não grava a aplicação variavel, o relatório não tem serventia para o cliente. Vamos usar fichas de despesa e receita
    * O deixo aqui para que, caso alguem vá desenvolve-lo no futuro, não precise partir do zero.
    */

    for await (let id of orgaos) {
      const paramReceira = { exercicio_id: exercicio.id, orgao_id: id, 'valor_orcado$ne': 0, relations: 'orgao,aplicacao_variavel,recurso,receita', orderBy: 'recurso.codigo,aplicacao_variavel.codigo,receita.codigo' }
      const paramDespesa = { exercicio_id: exercicio.id, orgao_id: id, 'valor_orcado$ne': 0, relations: 'orgao,aplicacao_variavel,recurso,despesa', orderBy: 'recurso.codigo,aplicacao_variavel.codigo,despesa.codigo' }

      if (fonteReceita) paramReceira['recurso.id'] = fonteReceita
      if (fonteDespesa) paramDespesa['recurso.id'] = fonteDespesa

      receitas.push(... (await this.fichaReceitaService.filtrar(1, -1, paramReceira).toPromise()).content)

      despesas.push(...(await this.fichaDespesaService.filtrar(1, -1, paramDespesa).toPromise()).content)

    }

    Relatorio.imprimirPersonalizado(`APLICAÇÕES VINCULADAS - ORÇAMENTO ${exercicio.ano}`, this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, this.conteudo(receitas, despesas), 'landscape', 'APLICAÇÃO VINCULADA', {
      linhas: {
        hLineWidth() {
          return 1;
        },
        vLineWidth() {
          return 1;
        },
        hLineColor() {
          return 'black';
        },
        paddingLeft() {
          return 3;
        },
        paddingRight() {
          return 3;
        }
      }
    })
  }

  private conteudo(receitas: FichaReceita[], despesas: FichaDespesa[]) {

    const receitasOrgao = this.funcaoService.agrupar(receitas, 'orgao.nome', ['valor_orcado'])
    const despesasOrgao = this.funcaoService.agrupar(despesas, 'orgao.nome', ['valor_orcado'])

    const qntOrgaos = despesasOrgao.length > receitasOrgao.length ? despesasOrgao.length : receitasOrgao.length

    const registros: {}[] = [
      [
        { text: 'Receitas', bold: true, border: [true, true, true, true], fontSize: 12 },
        { text: 'R$', bold: true, border: [true, true, true, true], fontSize: 12 },
        { text: 'Despesas', bold: true, border: [true, true, true, true], fontSize: 12 },
        { text: 'R$', bold: true, border: [true, true, true, true], fontSize: 12 }
      ]
    ]

    for (let i = 0; i < qntOrgaos; i++) {

      const orgaoNome = despesasOrgao.length > receitasOrgao.length ? despesasOrgao[i].grupo : receitasOrgao[i].grupo
      const orgaoCodigoNome = despesasOrgao.length > receitasOrgao.length ? `${despesasOrgao[i].registros[0].orgao.codigo} - ${despesasOrgao[i].registros[0].orgao.nome}` : `${receitasOrgao[i].registros[0].orgao.codigo} - ${receitasOrgao[i].registros[0].orgao.nome}`

      const receitaOrgao = receitasOrgao.find(r => r.grupo === orgaoNome)?.registros
      const despesaOrgao = despesasOrgao.find(d => d.grupo === orgaoNome)?.registros

      registros.push(
        [
          { text: `${orgaoCodigoNome}`, alignment: 'center', bold: true, colSpan: 4, border: [true, true, true, true], fontSize: 11 }, '', '', ''

        ]
      )

      const recursoReceitas = receitaOrgao ? this.funcaoService.agrupar(receitaOrgao, ['recurso.codigo', 'recurso.nome'], ['valor_orcado']) : []
      const recursoDespesas = despesaOrgao ? this.funcaoService.agrupar(despesaOrgao, ['recurso.codigo', 'recurso.nome'], ['valor_orcado']) : []

      const qntRecursos = recursoReceitas.length > recursoDespesas.length ? recursoReceitas.length : recursoDespesas.length

      for (let j = 0; j < qntRecursos; j++) {
        registros.push([
          { text: recursoReceitas[j] ? `${recursoReceitas[j].grupo['recurso.codigo']} - ${recursoReceitas[j].grupo['recurso.nome']}` : '', bold: true, border: [true, false, false, false], fontSize: 10 },
          { text: recursoReceitas[j] ? this.funcaoService.convertToBrNumber(recursoReceitas[j].totalizadores['valor_orcado'], 2, true) : '', bold: true, border: [true, false, true, false], fontSize: 10 },
          { text: recursoDespesas[j] ? `${recursoDespesas[j].grupo['recurso.codigo']} - ${recursoDespesas[j].grupo['recurso.nome']}` : '', bold: true, border: [true, false, false, false], fontSize: 10 },
          { text: recursoDespesas[j] ? this.funcaoService.convertToBrNumber(recursoDespesas[j].totalizadores['valor_orcado'], 2, true) : '', bold: true, border: [true, false, true, false], fontSize: 10 },
        ])

        const aplReceitas = recursoReceitas[j] ? this.funcaoService.agrupar(recursoReceitas[j].registros, ['aplicacao_variavel.codigo', 'aplicacao_variavel.nome', 'receita.codigo', 'receita.nome'], ['valor_orcado']) : []
        const aplDespesas = recursoDespesas[j] ? this.funcaoService.agrupar(recursoDespesas[j].registros, ['aplicacao_variavel.codigo', 'aplicacao_variavel.nome', 'despesa.codigo', 'despesa.nome'], ['valor_orcado']) : []

        const qntAplVar = aplReceitas.length > aplDespesas.length ? aplReceitas.length : aplDespesas.length

        for (let k = 0; k < qntAplVar; k++) {

          registros.push([
            { text: aplReceitas[k] ? `${aplReceitas[k].grupo['aplicacao_variavel.codigo']} - ${aplReceitas[k].grupo['aplicacao_variavel.nome']} ${aplReceitas[k].grupo['receita.codigo']} - ${aplReceitas[k].grupo['receita.nome']}` : '', border: [true, false, false, false], },
            { text: aplReceitas[k] ? this.funcaoService.convertToBrNumber(aplReceitas[k].totalizadores['valor_orcado'], 2, true) : '', border: [true, false, true, false], },
            { text: aplDespesas[k] ? `${aplDespesas[k].grupo['aplicacao_variavel.codigo']} - ${aplDespesas[k].grupo['aplicacao_variavel.nome']} ${aplDespesas[k].grupo['despesa.codigo']} - ${aplDespesas[k].grupo['despesa.nome']}` : '', border: [true, false, false, false], },
            { text: aplDespesas[k] ? this.funcaoService.convertToBrNumber(aplDespesas[k].totalizadores['valor_orcado'], 2, true) : '', border: [true, false, true, false], },
          ])
        }
      }

      const totalReceitaOrgao = receitaOrgao ? receitasOrgao.find(r => r.grupo === orgaoNome)?.totalizadores['valor_orcado'] : 0
      const totalDespesaOrgao = despesaOrgao ? despesasOrgao.find(d => d.grupo === orgaoNome)?.totalizadores['valor_orcado'] : 0

      registros.push([
        { text: 'Total:', border: [true, false, false, false], bold: true, fontSize: 11 },
        { text: this.funcaoService.convertToBrNumber(totalReceitaOrgao, 2, true), border: [true, false, true, false], bold: true, fontSize: 11 },
        { text: 'Total:', border: [true, false, false, false], bold: true, fontSize: 11 },
        { text: this.funcaoService.convertToBrNumber(totalDespesaOrgao, 2, true), border: [true, false, true, false], bold: true, fontSize: 11 },
      ])
    }

    const totalReceita = receitasOrgao.reduce((acc, r) => acc + r.totalizadores['valor_orcado'], 0)
    const totalDespesa = despesasOrgao.reduce((acc, d) => acc + d.totalizadores['valor_orcado'], 0)

    registros.push([
      { text: 'Total:', border: [true, true, true, true], bold: true, fontSize: 12 },
      { text: this.funcaoService.convertToBrNumber(totalReceita, 2, true), border: [true, true, true, true], bold: true, fontSize: 12 },
      { text: 'Total:', border: [true, true, true, true], bold: true, fontSize: 12 },
      { text: this.funcaoService.convertToBrNumber(totalDespesa, 2, true), border: [true, true, true, true], bold: true, fontSize: 12 },
    ])


    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }]
  }
}