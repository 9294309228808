import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ParametroRequisicao } from '../../../entidade/requisicao/parametro-requisicao.model';
import { BaseResourceService } from '../../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class ParametroRequisicaoService extends BaseResourceService<ParametroRequisicao> {

  constructor(
    protected injector: Injector
  ) {
    super(`parametros-requisicao`, injector);
  }

  public obterParametro(orgao: number): Observable<ParametroRequisicao> {
    return this.http.get<ParametroRequisicao>(
      `${this.login.cidade.id}/${this.api}/obter/${orgao}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
