// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { SetorAlmoxarifado } from './setor-almoxarifado.model';
import { ProdutoUnidade } from './produto-unidade.model';
import { Exercicio } from '../comum/exercicio.model';
import { Favorecido } from '../compra/favorecido.model';
import { Pessoa } from '../comum/pessoa.model';
import { MovimentoEstoque } from './movimento-estoque.model';

export class Fracionamento extends BaseResourceModel {

  constructor(
    public id?: number,
    public qtd_fracionar?: number,
    public qtd_fracionado?: number,
    public vencimento?: Date,
    public lote?: string,
    public fracionar?: ProdutoUnidade,
    public fracionado?: ProdutoUnidade,
    public setor_almoxarifado?: SetorAlmoxarifado,
    public usuario?: Usuario,
    public exercicio?: Exercicio,
    public orgao?: Orgao,
    public observacao?: string,
    public favorecido?: Favorecido,
    public recebedor?: Pessoa,
    public saida?: MovimentoEstoque,
    public entrada?: MovimentoEstoque
  ) {
    super();
  }

  static converteJson(json: any): Fracionamento {
    return Object.assign(new Fracionamento(), json);
  }
}
