import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { EncerramentoContabilJustificativaSimplesComponent } from './encerramento-contabil-justificativa-simples/encerramento-contabil-justificativa-simples.component';
@NgModule({
  declarations: [EncerramentoContabilJustificativaSimplesComponent],
  exports : [EncerramentoContabilJustificativaSimplesComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    MessageModule,
    MessagesModule
  ]
})
export class EncerramentoContabilJustificativaModule { }
