import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { LicitacaoService } from 'administrativo-lib';
import { ProdutoService } from 'almoxarifado-lib';
import { BaseResourceListComponent, Coluna, Contrato, DlgComponent, Filtro, FormatoExportacao, FuncaoService, GlobalService, LoginContabil, Produto } from 'eddydata-lib';
import { MenuItem } from 'primeng/api';
import { Panel } from 'primeng/panel';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-produtos-list-dlg',
  templateUrl: './produtos-list-dlg.component.html'
})

export class ProdutosListDlgComponent extends BaseResourceListComponent<Produto, LoginContabil> implements OnChanges {
  @ViewChild('toptabela') public topTabela: ElementRef;
  @ViewChild('panel') painel: Panel;

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public produto: Produto;
  public carregando: boolean = false;
  public ptBR: any;
  protected datepipe: DatePipe;
  public listaFiltros: Coluna[] = [];
  public menuFiltros: MenuItem[] = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public globalService: GlobalService,
    public funcaoService: FuncaoService,
    private produtoService: ProdutoService,
    private licitacaoService: LicitacaoService) {
    super(produtoService, injector);
    this.ptBR = new GlobalService().obterDataBR();
    this.datepipe = new DatePipe('pt');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visualizar && this.visualizar) {
      this.show();
    }
  }

  public ngOnInit(): void { 
    this.beforeInit();
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return '';
  }

  protected condicoesGrid(): {} {
    const parametros = {'orgao.id': this.login.orgao.id, 'estoque_id' : this.login.estoque ? this.login.estoque.id : this.login.setorAlmoxarifado.estoque.id};

    let filtros = this.obterFiltrosLista();
    return filtros ? Object.assign(parametros, filtros) : parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['codigo'];
  }

  protected filtrosGrid(): Filtro {
    return {};
  }

  protected afterInit(): void {
  }

  public beforeInit(): void { 
    this.usarExtendido = true;
  }

  protected acaoRemover(model: Produto): Observable<Produto> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [];
  }

  public exportarListagem(formato: FormatoExportacao) { }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
    this.loadMenuFiltros(true);
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public carregarItens(item: Produto, itens: DlgComponent) {
    if (this.carregando)
      return;
    itens.show();
    this.carregando = true;
    if (!item['carregado'])
      this.licitacaoService.filtrar(1, -1, {
        'orgao.id': this.login.orgao.id,
        'itens.produto_unidade.produto.id': item.id
      }).subscribe((data) => {
        if (data && data.content && data.content.length) {
          item.licitacoes = data.content
        }
        this.produto = item;
        this.carregando = false;
        item['carregado'] = true;
      }, (error) => toastr.error(error.error.payload));
    else {
      this.produto = item;
      this.carregando = false;
    }
  }

  //***********************FILTRO PERSONALIZADO *************************//

  protected obterColunasfiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Código', coluna: 'codigo', tipo: 'String', cols: 2, });
    retorno.push({ titulo: 'Descrição', coluna: 'nome', tipo: 'String' });

    return retorno;
  }

  private loadMenuFiltros(init?: boolean) {
    let filtros = this.obterColunasfiltroPersonalizado();
    this.menuFiltros = filtros.map((l): MenuItem => {
      if (!l['filtro1'])
        l['filtro1'] = null;
      if (!l['filtro2'])
        l['filtro2'] = null;
      return {
        label: l.titulo,
        icon: this.iconMenu(l),

        visible: !this.filtroAdicionado(l),
        command: (event) => {
          this.adicionarFiltro(l);
        }
      };
    });
  }

  private iconMenu(coluna: Coluna) {
    let icon = 'plus';
    if (coluna.tipo === 'Date')
      icon = 'calendar';
    if (coluna.tipo === 'Number')
      icon = 'calculator';
    if (coluna.tipo === 'String')
      icon = 'font';
    if (coluna.tipo === 'Boolean')
      icon = 'check';
    return `fa fa-${icon}`;
  }


  private adicionarFiltro(coluna: Coluna) {
    if (!this.filtroAdicionado(coluna)) {
      if (coluna.tipo === 'Boolean')
        coluna['filtro1'] = coluna.value ? coluna.value : false;
      this.listaFiltros.push(coluna);
      if (this.painel)
        this.painel.expand(null);
    }
    this.loadMenuFiltros();
  }

  public removerFiltro(coluna: Coluna) {
    let index = this.listaFiltros.indexOf(coluna);
    if (index || index === 0)
      this.listaFiltros.splice(index, 1);
    this.loadMenuFiltros();
  }

  public filtroAdicionado(coluna: Coluna): boolean {
    let filtro = this.listaFiltros.find((f) => f.coluna === coluna.coluna)
    return !(!filtro)
  }

  private obterFiltrosLista(): {} {
    let filtros: {} = {};
    try {
      for (let filtro of this.listaFiltros) {
        let convert = this.converterFiltro(filtro);
        if (convert)
          if (!convert['length']) {
            filtros[convert['chave']] = convert['valor'];
          } else {
            for (let fs of <[]>convert) {
              filtros[fs['chave']] = fs['valor'];
            }
          }
      }
      return filtros;
    } catch (error) {
      this.funcaoService.acaoErro(error);
      return null;
    }
  }

  private converterFiltro(coluna: Coluna): { chave: string, valor: string } | { chave: string, valor: string }[] {
    if (coluna.tipo === 'Number' && coluna['filtro1']) {
      if (coluna['filtro1'] && !coluna['filtro2']) {
        return { chave: `${coluna.coluna}$like`, valor: coluna['filtro1'] + '%' }
      }
      let filtro_data = [];
      if (coluna['filtro1']) {
        filtro_data.push({ chave: `${coluna.coluna}$ge`, valor: coluna['filtro1'] });
      }
      if (coluna['filtro2']) {
        filtro_data.push({ chave: `${coluna.coluna}$le`, valor: coluna['filtro2'] });
      }
      return filtro_data;
    }
    if (coluna.tipo === 'String' && coluna['filtro1'])
      return { chave: `${coluna.coluna}$like`, valor: `%${coluna['filtro1']}%` };

    if (coluna.tipo === 'Boolean' && coluna['filtro1'])
      return { chave: coluna.coluna, valor: coluna['filtro1'] };
    if (coluna.tipo === 'Date') {
      let filtro_data = [];
      if (coluna['filtro1'])
        filtro_data.push({ chave: `${coluna.coluna}$ge`, valor: this.datepipe.transform(coluna['filtro1'], 'yyyy-MM-dd') });
      if (coluna['filtro2'])
        filtro_data.push({ chave: `${coluna.coluna}$le`, valor: this.datepipe.transform(coluna['filtro2'], 'yyyy-MM-dd') });
      return filtro_data.length > 0 ? filtro_data : null;
    }
    return null;
  }

  public filtroTipo(filtro: Coluna[], tipo: 'String' | 'Number' | 'Date' | 'Boolean' | 'HTML') {
    return filtro.filter((f) => f.tipo === tipo);
  }

  public ngClassCols(cols: number, padrao: string): {} {
    let ngClass = {};
    if (cols)
      ngClass[`col-sm-${cols}`] = true;
    else
      ngClass[padrao] = true;
    return ngClass;
  }
}
