import { Contrato, Favorecido, FuncaoService, GlobalService, IBGEService, LoginContabil, Relatorio, ViaCEPService } from "eddydata-lib";

export class AtestadoCapacidadeTecnicaRpt {


  private login: LoginContabil;
  private funcaoService: FuncaoService;

  constructor(login: LoginContabil) {
    this.login = login;
    this.funcaoService = new FuncaoService();
  }

  prestador: Favorecido;
  tomador: Favorecido;
  emissaoLimpa: boolean;
  contrato: Contrato
  public observacao: string;

  protected afterInit(): void {
    throw new Error("Method not implemented.");
  }

  protected modalRelatorio(): string {
    return 'atestado-capacidade-tecnica';
  }

  protected tituloRelatorio(): string {
    return "Atestado de capacidade técnica";
  }

  protected layoutRelatorio(): {} {
    return null;
  }

  protected montarConteudo(): Promise<{}[]> {
    return new Promise((resolve) => {
      resolve(this.montarAtestado());
    });
  }

  protected ocultarCabecalho(): boolean {
    return true;
  }

  private async montarAtestado(): Promise<{}[]> {
    let data = new Date();
    /*Por padrao agora caso nao seja emitido na forma de impressao limpa,
     o certificado deve ser emitido com as informacoes do orgao.
    */
    const orgaoLogado = this.login.orgao;

    //Remove espacos no comeco e fim e caso termine em ponto final ele retina o ponto.
    const objetoContratoTratado = this.contrato?.objeto?.trim()?.endsWith('.') ?
      this.contrato?.objeto?.trim()?.slice(0, -1) : this.contrato?.objeto?.trim();

    //Caso selecionado o contrato na hora da emissão, o certificado ira sair com o numero e objeto do contrato.
    const linhaObjetoContrato = ` referentes ao contrato nº ${this.funcaoService.mascarar('####/####', this.contrato?.numero)} - Objeto: ${objetoContratoTratado}`;

    const conteudo = [{
      stack: [
        { text: 'ATESTADO DE CAPACIDADE TÉCNICA', fontSize: 14, bold: true, margin: [0, 0, 0, 30], alignment: 'center' },
        {
          text: [{ text: `Atestamos, para os devidos fins, que a empresa ` },
          { text: this.prestador ? this.prestador.nome : this.tracos(40), bold: true },
          { text: `, inscrita no CNPJ sob o nº ` }, { text: this.prestador ? this.cpfCnpj(this.prestador.cpf_cnpj) : this.tracos(25), bold: true },
          { text: `, estabelecida na Rua ` }, { text: this.prestador ? this.prestador.endereco : this.tracos(60), bold: true },
          { text: `, nº ` }, { text: this.prestador ? this.prestador.num_endereco : this.tracos(10), bold: true },
          { text: `, bairro ` }, { text: this.prestador ? this.prestador.bairro : this.tracos(40), bold: true },
          { text: `, na cidade de ` }, { text: this.prestador ? this.prestador.municipio : this.tracos(25), bold: true },
          { text: `, Estado de ` }, { text: this.prestador ? this.prestador.uf : this.tracos(10), bold: true },
          { text: `, prestou serviços à ` }, { text: (this.tomador ? this.tomador.nome : (!this.emissaoLimpa ? orgaoLogado?.nome : this.tracos(40))), bold: true },
          { text: `, CNPJ nº ` }, { text: (this.tomador ? this.cpfCnpj(this.tomador.cpf_cnpj) : (!this.emissaoLimpa ? orgaoLogado?.cnpj : this.tracos(25))), bold: true },
          { text: `, estabelecida na Rua ` }, { text: (this.tomador ? this.tomador.endereco : (!this.emissaoLimpa ? orgaoLogado?.endereco : this.tracos(60))), bold: true },
          { text: `, nº ` }, { text: (this.tomador ? this.tomador.num_endereco : (!this.emissaoLimpa ? orgaoLogado?.numero_endereco : this.tracos(10))), bold: true },
          { text: `, bairro ` }, { text: (this.tomador ? this.tomador.bairro : (!this.emissaoLimpa ? orgaoLogado?.bairro : this.tracos(40))), bold: true },
          { text: `, na cidade de ` }, { text: (this.tomador ? this.tomador.municipio : (!this.emissaoLimpa ? this.login?.cidade?.nome : this.tracos(25))), bold: true },
          { text: `, Estado de ` }, { text: (this.tomador ? this.tomador.uf : (!this.emissaoLimpa ? this.login?.cidade?.estado?.uf : this.tracos(10))) + '.', bold: true }
          ],
          fontSize: 13, alignment: 'justify',
          lineHeight: 1.5
        },
        {
          text: `Registramos que a empresa prestou serviços/entregou produtos ${this.contrato ? linhaObjetoContrato : this.tracos(200)}.`,
          fontSize: 13, alignment: 'justify',
          lineHeight: 1.5
        },
        {
          text: `Informamos ainda que as prestações dos serviços/entrega dos materiais acima referidos apresentaram bom desempenho operacional, tendo a empresa cumprido fielmente com suas obrigações, nada constando que a desabone técnica e comercialmente, até a presente data.`,
          fontSize: 13, alignment: 'justify',
          lineHeight: 1.5, margin: [0, 0, 0, 25]
        },
        { text: `${this.login.cidade.nome}, ${data.getDate()} de ${new GlobalService().obterMes(data.getMonth() + 1)} de ${data.getFullYear()}`, fontSize: 13, margin: [0, 25] },
        { text: this.tracos(50), fontSize: 13 },
        { text: this.prestador ? this.prestador.nome : '', fontSize: 13, margin: [0, 10, 0, 5] },
        { text: this.prestador ? this.cpfCnpj(this.prestador.cpf_cnpj) : '', fontSize: 13 },
      ], margin: [20, 100]
    }];

    if (this.observacao?.length > 0) {
      const linhaObservacao = {
        text: [
          { text: 'Observação: ', bold: true },
          { text: `${this.observacao}` }
        ],
        lineHeight: 1.5, margin: [0, 25],
        fontSize: 13, alignment: 'justify'
      };

      conteudo[0].stack.splice(3, 0, linhaObservacao as any);
    };

    return conteudo;
  }

  private cpfCnpj(doc: string) {
    if (doc.length > 11)
      return this.funcaoService.mascarar('##.###.###/####-##', doc);
    else
      return this.funcaoService.mascarar('###.###.###-##', doc);
  }


  private tracos(cols: number) {
    return Array.from(Array(cols).keys()).map((_, i) => {
      if (i % 2 === 0) return '_';
      else return ' '
    }).join('');
  }

  public imprimir(
    prestador: Favorecido, tomador: Favorecido, tipo: 'pdf' | 'docx',
    emissaoLimpa: boolean, contrato: Contrato, observacao?: string
  ) {
    this.prestador = prestador;
    this.tomador = tomador;
    this.emissaoLimpa = emissaoLimpa;
    this.contrato = contrato;
    this.observacao = observacao;

    this.montarConteudo().then((conteudo) => {
      Relatorio.imprimirPersonalizado(this.tituloRelatorio().toUpperCase(), this.login.usuario.nome, this.login.usuario.sobrenome,
        this.login.orgao.nome, this.login.brasao, conteudo, 'portrait', this.tituloRelatorio(),
        this.layoutRelatorio(), this.ocultarCabecalho(), true, tipo);
    });
  };
}