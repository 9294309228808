import { Component, Injector } from "@angular/core";
import { BaseResourceListComponent, Coluna, EmailService, Filtro, GlobalService, LoginContabil, ParametroCompra, ParametroCompraService, PedidoCompra } from "eddydata-lib";
import { ConfirmationService } from "primeng/api";
import { OverlayPanel } from "primeng/overlaypanel";
import { Observable } from "rxjs";
import { NotaPedidoCompra } from "../../../relatorio-compra/pedido-compra-rpt/pedido-compra-rpt";
import { PedidoCompraItemService } from "../service/pedido-compra-item.service";
import { PedidoCompraService } from "../service/pedido-compra.service";
import * as toastr from 'toastr';
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'lib-pedido-compra',
  templateUrl: './pedido-compra-list.component.html'
})
export class PedidoCompraListComponent extends BaseResourceListComponent<PedidoCompra, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public mes: string;
  public tipoEmail: string[] = ['R', 'F'];
  public pedidoSelecionado: PedidoCompra;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected pedidoCompraService: PedidoCompraService,
    protected pedidoCompraItemService: PedidoCompraItemService,
    protected parametroCompraService: ParametroCompraService,
    protected emailService: EmailService,
    protected confirmationPriService: ConfirmationService,
    protected globalService: GlobalService
  ) {
    super(pedidoCompraService, injector);
    toastr.options.positionClass = 'toast-top-left'
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================
  protected relations(): string {
    return 'orgao,exercicio,compra,compra.exercicio,requerente,compra.favorecido';
  }

  protected condicoesGrid(): {} {
    return {
      'exercicio.id': this.login.exercicio.id,
      'orgao.id': this.login.orgao.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['numero$DESC', 'sequencia$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {};
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: PedidoCompra): Observable<PedidoCompra> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return null;
  }

  public beforeInit() {
    this.usarExtendido = true;
    this.usarFiltroPersonalizado = true;
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  public obterColunasfiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Licitação', coluna: 'licitacao.numero', alignment: 'center', tipo: 'String', cols: 3, mask: '0000/0000' });
    retorno.push({
      titulo: 'Processo', coluna: 'processo', alignment: 'center', tipo: 'String', cols: 2,
      mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }], notUnmask: true
    });
    retorno.push({
      titulo: 'Contrato', coluna: 'contrato.numero', alignment: 'center', tipo: 'String', cols: 2,
      mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }]
    });
    retorno.push({ titulo: 'Nº OF', coluna: 'compra.numero', alignment: 'center', tipo: 'Number' });
    retorno.push({ titulo: 'Nº Requisição', coluna: 'compra.rcms.numero', alignment: 'center', tipo: 'Number' });
    retorno.push({ titulo: 'Fornecedor', coluna: 'compra.favorecido.nome', tipo: 'String' });
    retorno.push({ titulo: 'Nº Pedido', coluna: 'numero', tipo: 'Number' });
    retorno.push({ titulo: 'Nº Sequência', coluna: 'sequencia', tipo: 'Number' });
    retorno.push({ titulo: 'Código do Item', coluna: 'itens.item.produto.codigo', alignment: 'center', tipo: 'String' });
    retorno.push({ titulo: 'Descrição do Item', coluna: 'itens.item.produto.descricao', alignment: 'center', tipo: 'String' });

    return retorno;
  }

  public selecionar(pedidoCompra: PedidoCompra, op: OverlayPanel, event: any) {
    this.pedidoSelecionado = pedidoCompra;
    this.tipoEmail = ['R', 'F'];
    op.toggle(event);
  }

  public email() {
    if (!this.tipoEmail.includes('R') && !this.tipoEmail.includes('F'))
      return;

    this.pedidoCompraService.obter({
      'id': this.pedidoSelecionado.id,
      'relations': 'compra.exercicio,compra.rcms.exercicio,compra.licitacao,compra.licitacao.modalidade,compra.contrato,compra.favorecido.cidade,compra.requerente.cidade,itens.item.produto_unidade.produto.material,itens.item.produto_unidade.unidade'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(pedidoCompra => {
        if (!pedidoCompra) {
          toastr.warning(`Ordem de fornecimento não encontrada.`)
          return;
        }

        this.parametroCompraService.obter({
          'orgao.id': this.login.orgao.id
        }).pipe(takeUntil(this.unsubscribe))
          .subscribe(parametroCompra => {
            if (!parametroCompra) {
              return;
            }

            let mensagem = '';
            if (this.tipoEmail.includes('R'))
              mensagem += `<br />Requerente ${pedidoCompra.compra.requerente.nome}(${pedidoCompra.compra.requerente.email})`;
            if (this.tipoEmail.includes('F'))
              mensagem += `<br />Fornecedor ${pedidoCompra.compra.favorecido.nome}(${pedidoCompra.compra.favorecido.email})`;

            if (mensagem.length === 0)
              return;

            this.confirmationPriService.confirm({
              message: `Encaminhar por e-mail o pedido de compra para: ${mensagem}?`,
              header: `Envio de Pedido de Compra - Nº:${pedidoCompra.numero}/${pedidoCompra.sequencia}`,
              icon: 'pi pi-exclamation-triangle',
              acceptLabel: 'Sim',
              rejectLabel: 'Não',
              key: 'pedido',
              accept: () => {
                this.enviarEmail(pedidoCompra, parametroCompra);
              }
            });
          });
      });
  }

  public async enviarEmail(pedido: PedidoCompra, parametro?: ParametroCompra) {
    let subject = `Pedido de Compra - Nº: ${pedido.numero}/${pedido.sequencia}`;
    let mensagem = `<p>${parametro.mensagem_pedido}</p>`;
    let destinatarios: { nome: string; email: string; }[] = [];

    if (this.tipoEmail.includes('F')) {
      destinatarios.push({ nome: pedido.compra.favorecido.nome, email: pedido.compra.favorecido.email });
    }
    if (this.tipoEmail.includes('R')) {
      destinatarios.push({ nome: pedido.compra.requerente.nome, email: pedido.compra.requerente.email });
    }

    if (destinatarios.length) {
      const anexo = (await new NotaPedidoCompra(this.login).exportar([pedido]))
      this.emailService.enviar({ titulo: subject, corpo: mensagem, destinos: destinatarios, anexos: [{ arquivo: anexo, nome: `Pedido de Compra ${pedido.numero} - ${pedido.sequencia}.pdf` }] })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(data => toastr.success('E-mail encaminhado com sucesso!'), error => toastr.error(`Não foi possivel encaminhar o e-mail de confirmação!`));
    }
  }
}