import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Storage } from '../../entidade/storage/storage.model';
import { BaseResourceService } from '../../models/services/base-resource.service';

export type DestinoStorage = 'COMUM' | 'COMPRA' | 'ALMOXARIFADO' | 'CONTABIL' | 'REQUISICAO' | 'LICITACAO' | 'PATRIMONIO';

@Injectable({
    providedIn: 'root'
})
export class StorageService extends BaseResourceService<Storage> {

    constructor(
        protected injector: Injector
    ) {
        super(`storage`, injector);
    }

    public enviar(arquivo: any, orgao: number, destino: DestinoStorage): Observable<Storage> {
        const formData = new FormData();
        formData.append('file', arquivo);

        const headers = this.httpOptions();
        headers.headers = headers.headers
            .delete('Content-Type');
        return this.http.post<Storage>(`${this.login.cidade.id}/${this.api}/enviar/${orgao}/${destino}`,
            formData, headers)
            .pipe(
                map(res => res),
                catchError(err => this.handleError(err))
            );
    }

    public multiplos(arquivos: any[], orgao: number, destino: DestinoStorage): Observable<Storage[]> {
        const formData = new FormData();
        for (let arquivo of arquivos) {
            formData.append('files', arquivo);
        }
        const headers = this.httpOptions();
        headers.headers = headers.headers
            .delete('Content-Type');
        console.log(headers);
        return this.http.post<Storage[]>(`${this.login.cidade.id}/${this.api}/multiplos/${orgao}/${destino}`,
            formData, headers)
            .pipe(
                map(res => res),
                catchError(err => this.handleError(err))
            );
    }

}
