import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ProdutoUnidade, EddyAutoComplete, UnidadeFornecimento,
  Produto, Login, FuncaoService, GlobalService
} from 'eddydata-lib';
import { ProdutoUnidadeService, UnidadeFornecimentoService } from 'almoxarifado-lib';

@Component({
  selector: 'lib-produto-unidade',
  templateUrl: './produto-unidade.component.html'
})
export class ProdutoUnidadeComponent implements OnInit, OnDestroy {

  public itemAtual: ProdutoUnidade;
  public unidadeAutoComplete: EddyAutoComplete<UnidadeFornecimento>;
  protected unsubscribe: Subject<void> = new Subject();

  @Input() lista: Array<any>;
  @Output() listaChange: EventEmitter<any> = new EventEmitter();
  @Input() entidade: Produto;
  @Input() login: Login;

  @ViewChild('btnAdicionar') public btnAdicionar: ElementRef;

  constructor(
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected produtoUnidadeService: ProdutoUnidadeService,
    protected unidadeService: UnidadeFornecimentoService,
    protected globalService: GlobalService
  ) { }

  ngOnInit() {
    this.carregarAutoCompletes();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  public adicionar() {
    this.itemAtual = new ProdutoUnidade();
    this.itemAtual.editavel = true;
    this.itemAtual.ativo = true;
    if (!this.lista) {
      this.lista = [];
    }
    this.lista.unshift(this.itemAtual);
    this.btnAdicionar.nativeElement.disabled = true;
    this.change();
  }

  public salvar(item: ProdutoUnidade) {
    try {
      if (!item.unidade) {
        throw new Error('Informe a unidade!');
      }
      // envia a entidade para ser salva no banco -----
      // if (item?.id) {
      item['ignorarValidacao'] = true;
      // }
      this.btnAdicionar.nativeElement.disabled = false;
      this.itemAtual.editavel = false;
      this.change();
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  public editar(item: ProdutoUnidade) {
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.btnAdicionar.nativeElement.disabled = true;
    this.change();
  }

  public cancelar(item: ProdutoUnidade) {
    this.itemAtual = item;
    if (!this.itemAtual.unidade && !this.itemAtual.id) {
      this.lista.splice(this.lista.findIndex((itm) => { return itm === item }), 1);
    } else {
      this.itemAtual.editavel = false;
    }
    this.btnAdicionar.nativeElement.disabled = false;
    this.change();
  }

  public remover(item: ProdutoUnidade) {
    if (item.id) {
      this.produtoUnidadeService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
          this.lista.splice(this.lista.findIndex((itm) => { return itm === item }), 1);
          this.btnAdicionar.nativeElement.disabled = false;
          this.change();
        }, error => {
          console.log(error)
          this.messageService.add({ severity: 'error', summary: 'Exclusão', detail: error?.error?.payload });
        });
    } else {
      this.lista.splice(this.lista.findIndex((itm) => { return itm === item }), 1);
      this.btnAdicionar.nativeElement.disabled = false;
    }
  }

  private carregarAutoCompletes() {
    this.unidadeAutoComplete = new EddyAutoComplete(null, this.unidadeService,
      'id', ['nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome', ativo: true }, { number: ['codigo'], text: ['nome'] }
    );
  }

  private change() {
    this.listaChange.emit(this.lista);
  }

}
