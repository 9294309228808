import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Despesa } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DespesaService extends BaseResourceService<Despesa> {

  constructor(
    protected injector: Injector
  ) {
    super(`despesas`, injector);
  }

  public buscarSubElemento(codigos: string[], exercicio: number, pagina: number, limite: number, parametros?: { codigo?: string, nome?: string }): Observable<Despesa[]> {
    let params = this.converterParametrosStr(parametros);
    if (params.length > 0)
      params = `&${params.substring(1)}`;
    return this.http.get<Despesa[]>(`${this.login.cidade.id}/${this.api}/buscar-subelemento/${exercicio}/${pagina}/${limite}?codigos=${codigos.join(',')}${params}`, this.httpOptions())
  }

  importarDespesas(exercicio_atual_id: number, exercicio_anterior_id: number, importado: boolean): Observable<number> {
    const registro = {
      exercicio_atual_id: exercicio_atual_id,
      exercicio_anterior_id: exercicio_anterior_id,
      importado: importado
    }

    return this.http.post(`${this.login.cidade.id}/${this.api}/importar-despesas`, registro, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public importarXlsxDespesas(despesas: Array<Despesa>, exercicio_atual_id: number, importado: boolean): Observable<any>{
    const body = {
      despesas: despesas,
      exercicioAtualId: +exercicio_atual_id
    };
    console.log(body)
    return this.http.post(`${this.login.cidade.id}/${this.api}/importar-despesas-xlsx`, body, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public gerenciamentoReceitaDespesa(parametros?: {}): Observable<any> {
    let query = '';

    if (parametros) {
      // tslint:disable-next-line: forin
      for (const key in parametros) {
        if (query === '') {
          query += '?';
        } else {
          query += '&';
        }
        query += key + '=' + parametros[key];
      }
    }

    return this.http.get<Despesa>(`${this.login.cidade.id}/${this.api}/gerenciamento-receita-despesa${query}`,
      this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public importarPlanoContaXls(exercicioId: number, contas: any[]) {
    const body = {
      exercicio: exercicioId,
      lista: contas
    }

    return this.http.post(`${this.login.cidade.id}/${this.api}/importar-plano-conta-xls`, body, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
