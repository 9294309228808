import { Component, Injector, ElementRef } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BaseResourceFormComponent,
  LoginContabil, Despesa, Compra, Rcms, Empenho, FuncaoService, Liquidacao, CompraItem, Pagamento, FichaDespesa, DateFormatPipe, Exercicio, Contrato
} from 'eddydata-lib';
import { DespesaService, EmpenhoService, LiquidacaoService, PagamentoService } from 'administrativo-lib';
import { CompraItemService, CompraService, RcmsService } from 'compra-lib';
import { takeUntil } from 'rxjs/operators';
import { FichaDespesaService } from '../../ficha-despesa/service/ficha-despesa.service';

type SaldoFicha = {
  dotacao?: number,
  reservado?: number,
  saldo?: number,
  empenhado?: number,
  compra?: number,
  rcms?: number
}


@Component({
  selector: 'app-consulta-empenho-view',
  templateUrl: './consulta-empenho-view.component.html'
})
export class ConsultaEmpenhoViewComponent extends BaseResourceFormComponent<Empenho, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public arvDespesa = {
    n1: "",
    n2: "",
    n3: "",
    n4: "",
    n1Nome: "",
    n2Nome: "",
    n3Nome: "",
    n4Nome: ""
  };

  public tab: number = 0;

  public compra: Compra;
  public itensCompra: CompraItem[];
  public requisicao: Rcms;
  public liquidacoes: Liquidacao[];
  public pagamentos: Pagamento[];

  public vlLiquidado: number = 0;
  public vlReservado: number = 0;
  public vlDotacao: number = 0;
  public vlEmpenhado: number = 0;
  public vlPago: number = 0;
  public vlAnterior: number = 0;

  public saldoFicha: SaldoFicha;
  public saldoContrato: number = 0;
  public saldoConvenio: number = 0;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public funcaoService: FuncaoService,
    protected fichaService: FichaDespesaService,
    protected liquidacaoService: LiquidacaoService,
    protected pagtoService: PagamentoService,
    protected despesaService: DespesaService,
    protected compraService: CompraService,
    protected compraItemService: CompraItemService,
    protected rcmsService: RcmsService,
    protected empenhoService: EmpenhoService
  ) {
    super(new Empenho(), injector, Empenho.converteJson, empenhoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      valor_empenho: null,
      data_empenho: [null, [Validators.required]],
      data_vencimento: [null],
      especie: ['EMO', [Validators.required]],
      tipo_empenho: ['O', [Validators.required]],
      fase4: [0, [Validators.required]],
      disponibilidade_anterior: [false, [Validators.required]],
      adiantamento: [false, [Validators.required]],
      processo: [null],
      documento: [null],
      modalidade: [null, [Validators.required]],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      ficha: [null, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      favorecido: [null, [Validators.required]],
      subelemento: [null, [Validators.required]],
      compra: [null],
      licitacao: [null],
      precatorio: [null],
      ata: [null],
      contrato: [null],
      convenio: [null],
      mes: [null],
      aux: [0],
      usuario_cadastro: [this.login.usuario, [Validators.required]],
      inscrito_resto: [false],
      disponivel: [false]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'favorecido,contrato,convenio,licitacao,modalidade,subelemento,ficha,ficha.despesa,ficha.programa,ficha.acao,ficha.recurso,ficha.aplicacao,ficha.executora,ficha.funcao,ficha.subfuncao,compra,compra.rcms' };
  }

  protected setCurrentActionRoute(): void {
    this.currentActionRoute = 'editar';
  }

  protected afterLoad() {
    this.arvDespesa.n1 = this.entidade.subelemento.n1.padEnd(8, '0');
    this.arvDespesa.n2 = (this.entidade.subelemento.n1 + this.entidade.subelemento.n2).padEnd(8, '0');
    this.arvDespesa.n3 = (this.entidade.subelemento.n1 + this.entidade.subelemento.n2 + this.entidade.subelemento.n3).padEnd(8, '0');
    this.arvDespesa.n4 = (this.entidade.subelemento.n1 + this.entidade.subelemento.n2 + this.entidade.subelemento.n3 + this.entidade.subelemento.n4).padEnd(8, '0');

    this.despesaService.filtrar(1, -1, {
      'exercicio.id': this.login.exercicio.id,
      'OR': `codigo=${this.arvDespesa.n1};!;!;codigo=${this.arvDespesa.n2};!;!;codigo=${this.arvDespesa.n3};!;!;codigo=${this.arvDespesa.n4}`
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        res.content.forEach((despesa: Despesa) => {
          switch (despesa.codigo) {
            case this.arvDespesa.n1:
              this.arvDespesa.n1Nome = despesa.nome;
              break;
            case this.arvDespesa.n2:
              this.arvDespesa.n2Nome = despesa.nome;
              break;
            case this.arvDespesa.n3:
              this.arvDespesa.n3Nome = despesa.nome;
              break;
            case this.arvDespesa.n4:
              this.arvDespesa.n4Nome = despesa.nome;
              break;
          }
        });
      })
    if (this.entidade.compra) {
      this.compraService.extendido(1, -1, {
        'id': this.entidade.compra.id,
        'orgao.id': this.login.orgao.id,
        'exercicio.id': this.login.exercicio.id,
        ['relations']: 'rcms,requerente,prazo_entrega,prazo_pagamento',
        orderBy: 'data_compra$DESC'
      }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.compra = res.content[0];
          this.compraItemService.filtrar(0, -1, { compra_id: this.compra.id, relations: 'produto_unidade,produto_unidade.produto' }).subscribe(dados => {
            this.itensCompra = dados.content;
          });
        })
      if (this.entidade.compra.rcms) {

        this.rcmsService.extendido(1, -1, {
          'id': this.entidade.compra.rcms.id,
          'orgao.id': this.login.orgao.id,
          'exercicio.id': this.login.exercicio.id,
          ['relations']: 'ficha.despesa,setor,requerente,estoque',
          orderBy: 'data_rcms$DESC'
        }).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            this.requisicao = res.content[0];
          });
      }
    }
    this.liquidacaoService.extendido(1, -1, {
      'empenho.id': this.entidade.id,
      'orgao.id': this.login.orgao.id,
      'exercicio.id': this.login.exercicio.id,
      ['relations']: 'empenho,empenho.favorecido,empenho.subelemento,empenho.ficha,empenho.ficha.recurso,empenho.ficha.aplicacao',
      orderBy: 'data_liquidacao$DESC'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.liquidacoes = res.content;
      });

    this.pagtoService.extendido(1, -1, {
      'liquidacao.empenho.id': this.entidade.id,
      'orgao.id': this.login.orgao.id,
      'exercicio.id': this.login.exercicio.id,
      ['relations']: 'liquidacao,liquidacao.empenho,conta,conta.banco,conta_recurso,conta_recurso.recurso,conta_recurso.aplicacao,conta_recurso.convenio',
      orderBy: 'data_pagamento$DESC'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.pagamentos = res.content;
      })

    this.carregarTotalizadores();
  }

  public selecionarTab(tab: number) {
    this.tab = tab;
  }

  public corStatus(item: Compra) {
    let cor = 'success';
    if (item.excluido)
      cor = 'danger';
    else if (item['situacao'] === 'EMPENHADO')
      cor = 'warning';
    else if (item['situacao'] === 'LIQUIDADO_PARCIAL')
      cor = 'info';
    else if (item['situacao'] === 'LIQUIDADO')
      cor = 'primary';
    let ngCor = {};
    ngCor[`badge-${cor}`] = true;
    return ngCor;
  }

  public tituloStatus(item: Compra) {
    if (item.excluido)
      return 'Excluido';
    if (item['situacao'] === 'EMPENHADO')
      return 'Empenhado';
    if (item['situacao'] === 'LIQUIDADO_PARCIAL')
      return 'Parcialmente Liquidado';
    if (item['situacao'] === 'LIQUIDADO')
      return 'Liquidado';
    return 'Aberto';
  }

  public corEspecieRcms(rcms: Rcms) {
    if (rcms['compras_view'] && rcms['compras_view'].length > 0) {
      return 'dark';
    } else {
      if (rcms.autorizado_compra) {
        if (rcms.autorizado) {
          return 'primary';
        } else {
          return 'warning';
        }
      } else {
        return 'danger';
      }
    }
  }

  public tituloEspecieRcms(rcms: Rcms) {
    if (rcms['compras_view'] && rcms['compras_view'].length > 0) {
      return 'Possui OF';
    } else {
      if (rcms.autorizado_compra) {
        if (rcms.autorizado) {
          return 'Autorizado';
        } else {
          return 'Não autorizada';
        }
      } else {
        return 'Não concluída';
      }
    }
  }

  protected async carregarTotalizadores(): Promise<void> {
    // this.fichaService.totalizacaoFicha(this.entidade.ficha.id, this.login.orgao.id, this.login.exercicio.id, this.data)
    //   .pipe(takeUntil(this.unsubscribe))
    //   .subscribe((res) => {
    //     this.vlDotacao = +res.total_dotacao + +res.total_creditado;
    //     this.vlEmpenhado = +res.total_empenhado;
    //     this.vlPago = +res.total_pago;
    //     this.vlReservado = +res.total_reservado;
    //     this.vlAnulado = +res.total_empenho_anulado;
    //     this.vlCompraAberto = +res.total_compra - +res.total_compra_empenhada;
    //     this.vlRcmsAberto = +res.total_rcms_pendente;
    //     this.vlSuplementado = +res.valor_creditado;
    //     this.vlAnulado = +res.valor_anulado;
    //   })

    this.vlEmpenhado = 0;
    this.vlLiquidado = 0;
    this.vlPago = 0;
    this.vlDotacao = 0;
    this.vlAnterior = 0;
    this.vlReservado = 0;

    const exercicio: Exercicio = this.entidadeForm.get('exercicio').value
      ? this.entidadeForm.get('exercicio').value
      : this.login.exercicio;

    let totalizadores: any;
    if (this.entidade.id) {
      this.buscarSaldoFicha();
      totalizadores = await this.empenhoService.totalizadores(exercicio.id, this.login.orgao.id, this.entidade.numero).toPromise();
    } else {
      const ficha: FichaDespesa = this.entidadeForm.get('ficha').value
        ? this.entidadeForm.get('ficha').value
        : this.entidade.ficha;
      const dataEmpenho: Date = this.entidadeForm.get('data_empenho').value
        ? this.entidadeForm.get('data_empenho').value
        : this.entidade.data_empenho;
      if (ficha && dataEmpenho) {
        this.buscarSaldoFicha();
        totalizadores = await this.empenhoService.totalizadoresFicha(
          exercicio.id, this.login.orgao.id, ficha.id,
          String(this.funcaoService.converteDataSQL(new DateFormatPipe().transform(dataEmpenho, ['local'])))).toPromise();
        totalizadores = { content: totalizadores };
      }
    }

    if (totalizadores && totalizadores.content) {
      totalizadores = totalizadores.content[0] ? totalizadores.content[0] : totalizadores.content;
      this.vlEmpenhado = totalizadores.total_empenhado;
      this.vlLiquidado = totalizadores.total_liquidado;
      this.vlPago = totalizadores.total_pago;

      this.vlDotacao = +totalizadores.total_dotacao_ficha + +totalizadores.total_creditado_ficha;

      const valorAnterior = totalizadores.total_empenhado_ficha_anterior ?
        +totalizadores.total_empenhado_ficha_anterior : +totalizadores.total_empenhado_ficha;

      this.vlAnterior = +this.vlDotacao - +valorAnterior;
    }

  }


  private buscarSaldoFicha(init?: boolean) {
    const exercicio: Exercicio = this.entidade.exercicio ? this.entidade.exercicio : this.entidadeForm.get('exercicio').value;
    const ficha: FichaDespesa = this.entidade.ficha ? this.entidade.ficha : this.entidadeForm.get('ficha').value;
    const dataEmpenho: Date = this.entidadeForm.get('data_empenho').value;

    if (!ficha || !dataEmpenho || !exercicio)
      return;

    this.fichaService.saldoAtualFicha(ficha.id, this.login.orgao.id, this.login.exercicio.id,
      String(this.funcaoService.converteDataSQL(new DateFormatPipe().transform(dataEmpenho, ['local']))),
      (this.entidade.id ? { compraId: this.entidade.id } : null), true).subscribe((data) => {
        if (data)
          this.carregarSaldoFicha(data, init);
      })
  }

  private carregarSaldoFicha(totalizadores: any, init?: boolean) {
    if (!totalizadores)
      return;

    if (totalizadores.saldo_atual <= 0 && !init && !this.entidade.id) {
      toastr.warning(`Ficha de '${this.entidadeForm.get('ficha').value.despesa.nome}' não possui saldo para estar realizando o empenho`);
    }

    this.saldoFicha = {};

    this.saldoFicha.empenhado = +totalizadores.total_empenhado;
    this.saldoFicha.rcms = +totalizadores.total_rcms_pendente;
    this.saldoFicha.compra = +totalizadores.total_compra_pendente;

    this.saldoFicha.dotacao = +totalizadores.total_dotacao + +totalizadores.total_creditado;
    this.saldoFicha.reservado = +totalizadores.total_reservado;
    this.saldoFicha.saldo = +totalizadores.saldo_atual;
  }


  public obterSaldoConvenio() {
    if (this.entidadeForm.get('convenio').value !== null) {
      this.empenhoService.totalEmpenhadoPorConvenio(this.entidadeForm.get('convenio').value.id, this.login.orgao.id)
        .subscribe((saldo) => {
          this.saldoConvenio = this.entidadeForm.get('convenio').value.valor_convenio - saldo;
        });
    }
  }


  public obterSaldoContrato(contrato: Contrato) {
    this.empenhoService.totalEmpenhadoPorContrato(this.entidadeForm.get('contrato').value.id, this.login.orgao.id)
      .subscribe((saldo) => {
        if (contrato.tipo_contratacao && contrato.tipo_contratacao.material && contrato.tipo_contratacao.servico) {
          if (this.entidadeForm.get('ficha').value && this.entidadeForm.get('ficha').value.despesa.codigo.substr(0, 6) == '449052') {
            this.saldoContrato = this.entidadeForm.get('contrato').value.valor_material - saldo;
          } else if (this.entidadeForm.get('ficha').value && (
            this.entidadeForm.get('ficha').value.despesa.codigo.substr(0, 6) == '339036' || this.entidadeForm.get('ficha').value.despesa.codigo.substr(0, 6) == '339039')) {
            this.saldoContrato = this.entidadeForm.get('contrato').value.valor_servico - saldo;
          } else {
            this.saldoContrato = this.entidadeForm.get('contrato').value.valor_contrato - saldo;
          }
        } else {
          this.saldoContrato = this.entidadeForm.get('contrato').value.valor_contrato - saldo;
        }
      });
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected afterInit(): void {
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: Empenho): void {
  }

}
