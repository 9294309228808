import { Component, OnInit, Injector, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NotaVersaoService } from '../servico/nota-versao.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalService } from '../../util/global.service';

@Component({
  selector: 'lib-nota-versao-show',
  templateUrl: './nota-versao-show.component.html'
})
export class NotaVersaoShowComponent implements OnInit {

  @Input() sistema: string;
  @Input() login: string;

  protected router: Router;
  protected unsubscribe: Subject<void> = new Subject();

  public notasVersoes: any[];
  public notaVersao: any;

  constructor(
    protected injector: Injector,
    private notaVersaoService: NotaVersaoService) {
    this.router = this.injector.get(Router);
    const login = GlobalService.obterSessaoLogin();
  }

  ngOnInit() {
    this.notaVersaoService.obterPorSistemaVersao(this.sistema)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.notasVersoes = res;
        if (this.notasVersoes.length > 0) {
          this.notaVersao = this.notasVersoes[0];
        }
      });
  }

  carregarNotasVersao(versao: any) {
    this.notaVersao = versao;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }



}
