import { Component, Injector, OnInit } from '@angular/core';
import {
  BaseResourceListComponent, Coluna, RequisicaoMovimento, Filtro, FormatoExportacao, FuncaoService, GlobalService, LoginContabil, StatusRequisicao, Converter, ParametroAlmoxarifadoService
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RequisicaoMovimentoService } from '../../requisicao/service/requisicao-movimento.service';

@Component({
  selector: 'lib-requisicao-autorizacao-devolucao-list',
  templateUrl: './requisicao-autorizacao-devolucao-list.component.html'
})
export class RequisicaoAutorizacaoDevolucaoListComponent extends BaseResourceListComponent<RequisicaoMovimento, LoginContabil> implements OnInit {

  /**
   * Declaração de variáveis
   */
  public mesEncerrado: boolean = false;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public funcaoService: FuncaoService,
    public globalService: GlobalService,
    private parametroAlmoxarifadoService: ParametroAlmoxarifadoService,
    private requisicaoMovService: RequisicaoMovimentoService) {
    super(requisicaoMovService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'usuario,setor_almoxarifado,setor_almoxarifado.setor,setor_almoxarifado.estoque,exercicio,orgao,usuario,recebedor,itens,itens.produto_unidade';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id,
      ['exercicio.id']: this.login.exercicio.id,
      ['devolucao']: true,
      // 'data_cadastro$extract_m': this.login.mesReferencia + 1,
      'status$ne': 'E',
      ['setor_almoxarifado.estoque.id']: this.login.parametro.almoxarifado.estoque.id
    }
  }

  protected ordenacaoGrid(): string[] {
    return ['data_cadastro$DESC', 'id$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['id'],
      date: ['data_cadastro'],
      text: ['usuario.nome', 'recebedor.nome'],
    };
  }

  protected afterInit(): void {
    this.parametroAlmoxarifadoService.mesFechadoAlmoxarifadoa(this.login, this.login.setorAlmoxarifado ?
      this.login.setorAlmoxarifado.estoque.id : this.login.estoque.id).then((valid) => {
        this.mesEncerrado = valid;
      })
  }

  protected acaoRemover(model: RequisicaoMovimento): Observable<RequisicaoMovimento> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Nº RRM', coluna: 'id', alignment: 'center' },
      { titulo: 'Data Requisição', coluna: 'data_cadastro' },
      { titulo: 'Setor', coluna: 'setor_almoxarifado.setor.nome' },
      { titulo: 'Usuário', coluna: 'usuario.nome' },
      {
        titulo: 'Status', coluna: 'status', converter: this.globalService.statusRequisicao()
          .map((s): Converter => {
            return {
              id: s,
              nome: this.globalService.tituloStatusRequisicao(s)
            };
          }), alignment: 'center'
      }
    ];
  }

  protected podeAlterar(item: RequisicaoMovimento): boolean {
    return item.status === 'A' && !this.mesEncerrado;
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = this.relations();
    parametros['orderBy'] = this.ordenacaoGrid().join(',');
    this.requisicaoMovService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (lista.content && lista.content.length > 0)
            lista.content = lista.content.map((l) => {
              l.data_cadastro = new FuncaoService().converteDataBR(new Date(l.data_cadastro));
              return l;
            });
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE REQUISIÇÕES',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem Requisicões', ['auto', 'auto', '*', '*', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }


  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================


  public tituloStatusRequisicao(status: StatusRequisicao): string {
    return this.globalService.tituloStatusRequisicao(status);
  }

  public corStatusRequisicao(status: StatusRequisicao): {} {
    let cor = this.globalService.corStatusRequisicao(status);
    let ngCor = {};
    ngCor[`badge-${cor}`] = true;
    return ngCor;
  }
}
