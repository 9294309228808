import {
  AfterViewInit, Component, ElementRef, EventEmitter, Input,
  OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core';
import { OrgaoAssinaturaService } from '../service/orgao-assinatura.service';
import { Subject } from 'rxjs';
import { Calendar } from 'primeng/calendar';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OrgaoAssinatura } from '../../entidade/comum/orgao-assinatura.model';
import { Orgao } from '../../entidade/comum/orgao.model';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { FuncaoService } from '../../util/funcao.service';
import { GlobalService } from '../../util/global.service';
import { Assinatura } from '../../entidade/comum/assinatura.model';
import * as toastr from 'toastr';

@Component({
  selector: 'lib-orgao-assinatura',
  templateUrl: './orgao-assinatura.component.html'
})
export class OrgaoAssinaturaComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  public ptBR: any;

  @ViewChild('f') formGroup: any;

  public entity: OrgaoAssinatura = new OrgaoAssinatura();
  public listaAssinaturas: Array<any>;
  protected unsubscribe: Subject<void> = new Subject();
  public data_limite: string;
  public data_inicio: string;
  public block_inicio: string = 'disabled';
  showMessage: boolean = false;
  debounceTimeout: any;

  @Input() lista: Array<any>;
  @Input() entidade: Orgao;
  @Input() login: LoginContabil;
  @Input() visualizar: boolean;
  @Output() preencherTabela = new EventEmitter();

  @ViewChild('data_') public inputData: Calendar;
  @ViewChild('btnFecharAssinatura') public btnFecharAssinatura: ElementRef;

  constructor(
    protected confirmationService: ConfirmationService,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    public assinaturaService: OrgaoAssinaturaService) {
  }

  ngOnInit() {
    this.listaAssinaturas = this.lista;
    this.ptBR = new GlobalService().obterDataBR();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lista) {
      this.listaAssinaturas = this.lista;
    }
  }

  ngAfterViewInit() {
    new GlobalService().calendarMascara();
    if (this.inputData) {
      setTimeout(() => this.inputData.inputfieldViewChild.nativeElement.focus(), 2000);
    }
  }
  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================


  public adicionarAssinatura() {
    if (!this.entidade.id) {
      this.messageService.add({ severity: 'warn', summary: 'Validação', detail: 'Antes de inserir assinaturas salve o orgão público' });
      return;
    }
    this.block_inicio = 'disabled';
    const itemAnterior = this.listaAssinaturas.slice(- 1);
    if (itemAnterior.length) {
      const dataAnterior = new Date(itemAnterior[0].data_limite);

      dataAnterior.setDate(dataAnterior.getDate() + 1);

      this.data_inicio = this.funcaoService.converteDataBR(dataAnterior);
    } else {
      this.block_inicio = null;
      this.data_inicio = this.funcaoService.converteDataBR(new Date());
    }
    this.data_limite = '';
    this.entity = new Assinatura();
    this.entity.orgao = this.entidade;
  }

  public async salvarAssinatura(item: OrgaoAssinatura) {
    try {
      const dtLimite = this.funcaoService.converteDataSQL(this.data_limite);

      item.data_limite = this.funcaoService.toDate(this.data_limite);
      if (!item.ordenador_despesa) {
        throw new Error('Informe o nome do ordenador de despesa!');
      }
      if (!item.contador) {
        throw new Error('Informe o nome do contador(a)!');
      }
      if (!item.tesoureiro) {
        throw new Error('Informe o nome do tesoureiro(a)!');
      }
      if (!item.data_limite) {
        throw new Error('Informe a data limite!');
      }
      if (!item.id) {
        const existe = await this.assinaturaService.obterPorDataLimite(dtLimite, this.login.orgao.id).toPromise();
        if (existe) {
          if (item.id === null || existe.id !== item.id) {
            throw new Error('Data limite já informado para o orgão público');
          }
        }
      }
      if (!this.listaAssinaturas) {
        this.listaAssinaturas = new Array();
      }
      if ((this.data_limite.substring(0, 2) <= this.data_inicio.substring(0, 2) && this.data_limite.substring(3, 5) <= this.data_inicio.substring(3, 5) && this.data_limite.substring(6, 10) <= this.data_inicio.substring(6, 10))
        || (this.data_limite.substring(0, 2) > this.data_inicio.substring(0, 2) && (this.data_limite.substring(3, 5) < this.data_inicio.substring(3, 5)) || (this.data_limite.substring(6, 10) < this.data_inicio.substring(6, 10)))) {
        throw new Error('A data limite é menor que a data inicial.');
      }
      item.orgao = this.entidade;
      if (!item.id) {
        item = await this.assinaturaService.inserir(item).toPromise();
      } else {
        item = await this.assinaturaService.atualizar(item).toPromise();
      }
      this.preencherTabela.emit();
      this.btnFecharAssinatura.nativeElement.click();
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  public editarAssinatura(item: OrgaoAssinatura) {
    const indexItem = this.listaAssinaturas.indexOf(item);
    const itemAnterior = this.listaAssinaturas[indexItem - 1];
    if (indexItem > 0) {
      this.data_inicio = this.funcaoService.converteDataBR(itemAnterior.data_limite);
    } else {
      this.data_inicio = this.funcaoService.converteDataBR(item.data_cadastro);
    }
    this.data_limite = this.funcaoService.converteDataBR(item.data_limite);
    this.entity = item;
  }

  public cancelarAssinatura() {
    this.loadAssinatura();
  }

  private loadAssinatura() {
    if (this.entidade.id) {
      this.assinaturaService.filtrar(1, -1,
        { relations: 'orgao', 'orgao.id': this.entidade.id }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaAssinaturas = res ? res.content : new Array<OrgaoAssinatura>();
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    } else {
      this.listaAssinaturas.splice(0, 1);
    }
  }

  public removerAssinatura(item: Assinatura, index: number) {
    if (item.id) {
      this.confirmationService.confirm({
        message: 'Deseja realmente excluir a assinatura?',
        header: 'Remoção',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.assinaturaService.remover(item.id).pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
              this.preencherTabela.emit();
              this.messageService.add({ severity: 'warn', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
              this.listaAssinaturas.splice(index, 1);
            });
        },
        reject: () => {
        }
      });
    } else {
      this.listaAssinaturas.splice(index, 1);
    }
  }

  onBlur() {
    this.showMessage = true;
  }

  onModelChange(value: string) {
    if (this.data_limite.length >= 10) {
      if ((this.data_limite.substring(0, 2) <= this.data_inicio.substring(0, 2) && this.data_limite.substring(3, 5) <= this.data_inicio.substring(3, 5) && this.data_limite.substring(6, 10) <= this.data_inicio.substring(6, 10))
        || (this.data_limite.substring(0, 2) > this.data_inicio.substring(0, 2) && (this.data_limite.substring(3, 5) < this.data_inicio.substring(3, 5)) || (this.data_limite.substring(6, 10) < this.data_inicio.substring(6, 10)))) {
        toastr.warning('A data limite é menor que a data inicial.', 'Atenção!')
      }
    }
  }

  public podeVisualizar() {
    if (this.visualizar === true) {
      return true;
    }
    if (this.visualizar === false) {
      return false;
    }
  }

}
