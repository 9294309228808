import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Veiculo } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VeiculoService extends BaseResourceService<Veiculo> {

  constructor(
    protected injector: Injector
  ) {
    super(`veiculos`, injector);
  }

  public obterPorNome(nome: string, orgaoId: number): Observable<Veiculo> {
    return this.http.get<Veiculo>(
      `${this.login.cidade.id}/${this.api}/filtrar?nome$like=${nome}%&orgao_id=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterManutencoesPreventivas(pagina: number, limite: number, filtroKm: number, filtroDias: number, filtro: string): Observable<any> {
    if (!limite || limite === 0) limite = this.login.limite
    if (!filtroKm) filtroKm = 0
    if (!filtroDias) filtroDias = 0
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/manutencoes-preventivas/${pagina}/${limite}/${filtroKm}/${filtroDias}${filtro ? '?filtro='+filtro :''} `,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public proximoCodigo(): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-codigo`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
