// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Convenio } from './convenio.model';

export class ConvenioComissao extends BaseResourceModel {
  constructor(
    public id?: number,
    public cpf?: string,
    public nome?: string,
    public email?: string,
    public cargo?: string,
    public convenio?: Convenio,
    public editavel?: boolean
  ) {
    super();
  }

  static converteJson(json: any): ConvenioComissao {
    return Object.assign(new ConvenioComissao(), json);
  }

}
