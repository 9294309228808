// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Funcionario } from './funcionario.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { Referencia } from './referencia.model';
import { Departamento } from './departamento.model';

export class Holerite extends BaseResourceModel {
  constructor(
    public id?: number,
    public mes?: number,
    public calculo?: string,
    public referencia?: Referencia,
    public salario_base?: number,
    public salario_adicional?: number,
    public salario_total?: number,
    public valor_desconto?: number,
    public valor_bruto?: number,
    public valor_liquido?: number,
    public valor_fgts?: number,
    public base_fgts?: number,
    public base_inss?: number,
    public base_irrf?: number,
    public excedente_inss?: number,
    public base_decimo_inss?: number,
    public departamento?: Departamento,
    public funcionario?: Funcionario,
    public exercicio?: Exercicio,
    public orgao?: Orgao
    ) {
    super();
  }
  static converteJson(json: any): Holerite {
    return Object.assign(new Holerite(), json);
  }
}
