import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class CronometroService {
    public iniciarCronometro(): void {
        document.getElementById('btnCronStart').click();
    }

    public pausarCronometro(): void {
        document.getElementById('btnCronStop').click();
    }

    public zerarCronometro(): void {
        document.getElementById('btnCronReset').click();
    }

    public atribuirHorario(h: number, m: number, s: number, ms?: number): void {
        if (h > 0) (document.getElementById('txtCronH') as HTMLInputElement).value = String(h);
        if (m > 0) (document.getElementById('txtCronM') as HTMLInputElement).value = String(m);
        if (s > 0) (document.getElementById('txtCronS') as HTMLInputElement).value = String(s);
        if (ms > 0) (document.getElementById('txtCronMS') as HTMLInputElement).value = String(ms);

        document.getElementById('btnCronSet').click();
    }

    public obterHorario(): string {
        return document.getElementById('cronometroAtual')?.innerHTML;
    }

    public ativo(): boolean {
        return (document.getElementById('txtActive') as HTMLInputElement).value === 'true';
    }
}