import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { GrupoEstoqueService, MaterialService, SubGrupoEstoqueService, SubGrupoSubElementoEstoqueService } from 'almoxarifado-lib';
import {
  BaseResourceFormComponent, EddyAutoComplete, FuncaoService, GlobalService, GrupoEstoque, LoginContabil, Material, SubGrupoEstoque, SubGrupoSubElementoEstoque
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'lib-subgrupo-estoque-form',
  templateUrl: './subgrupo-estoque-form.component.html'
})
export class SubGrupoEstoqueFormComponent extends BaseResourceFormComponent<SubGrupoEstoque, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public sub_elementos: SubGrupoSubElementoEstoque[] = [];

  public grupoAutoComplete: EddyAutoComplete<GrupoEstoque>;

  public editarCodigo: boolean = false;

  @ViewChild('codigo') inputField: ElementRef;

  public listaMaterial: Material[] = [];
  public listaServico: Material[] = [];

  public mes: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected subGrupoService: SubGrupoEstoqueService,
    private materialService: MaterialService,
    protected grupoService: GrupoEstoqueService,
    protected subElementoService: SubGrupoSubElementoEstoqueService
  ) {
    super(new SubGrupoEstoque(), injector, SubGrupoEstoque.converteJson, subGrupoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(item: SubGrupoEstoque): Promise<boolean> {
    return true;
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      codigo: [null, [Validators.required]],
      nome: [null, [Validators.required]],
      bec: [false, [Validators.required]],
      grupo: [null, [Validators.required]],
      orgao: [this.login.orgao.id, [Validators.required]],
      materiais: [],
      sub_elementos: []
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'grupo,sub_elementos,sub_elementos.sub_elemento,sub_elementos.sub_elemento.exercicio',
      // OR: `sub_elementos.sub_elemento.id$null=true;!;!;sub_elementos.sub_elemento.exercicio.id=${this.login.exercicio.id}`
    };
  }

  protected afterLoad() {
    this.grupoAutoComplete.id = this.entidade.grupo ? this.entidade.grupo.id : null;
    this.sub_elementos = this.entidade.sub_elementos;

    this.materialService.filtrar(1, -1, {
      orgao_id: this.login.orgao.id,
      'sub_grupo.id': this.entidade.id, 'servico': false
    }).subscribe((data) => {
      if (data.content)
        this.listaMaterial = data.content;
    }, (error) => toastr.error(error.error.payload));

    this.materialService.filtrar(1, -1, {
      orgao_id: this.login.orgao.id,
      'sub_grupo.id': this.entidade.id, 'servico': true
    }).subscribe((data) => {
      if (data.content)
        this.listaServico = data.content;
    }, (error) => toastr.error(error.error.payload));
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }



  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected beforeSubmit() {
    try {
      if (this.entidadeForm.get('nome').value === '') {
        throw new Error('Informe o nome do sub-grupo!');
      }
      this.entidadeForm.get('sub_elementos').setValue(this.sub_elementos);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(modelo: SubGrupoEstoque) {
    this.sub_elementos = modelo.sub_elementos;
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
  private carregarAutoCompletes() {
    this.grupoAutoComplete = new EddyAutoComplete(null, this.grupoService,
      'id', ['codigo', 'nome'], { orgao_id: this.login.orgao?.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );
  }

  public selectGrupo() {
    if (this.currentActionRoute === 'novo') {
      this.subGrupoService.proximoCodigo(this.entidadeForm.get('grupo').value.id, false)
        .subscribe((data) => {
          this.entidadeForm.get('codigo').setValue(data);
        });
    }
  }

  public visualizaCodigo() {
    let codigo = this.entidadeForm.get('codigo').value;
    let grupo: GrupoEstoque = this.entidadeForm.get('grupo').value;
    if (!codigo)
      return '00.00';
    return `${grupo.codigo}.${codigo}`;
  }
}
