// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ProdutoMedicamento } from './produto-medicamento.model';

export class ProdutoConcentracao extends BaseResourceModel {
  constructor(
    public id?: number,
    public peso_inicial?: number,
    public peso_final?: number,
    public idade_inicial?: number,
    public idade_final?: number,
    public dose_diaria?: number,
    public produto_medicamento?: ProdutoMedicamento,
  ) {
    super();
  }

  static converteJson(json: any): ProdutoConcentracao {
    return Object.assign(new ProdutoConcentracao(), json);
  }
}
