// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ProdutoUnidade } from '../almoxarifado/produto-unidade.model';
import { BaixaMerenda } from './baixa-merenda.model';

export class BaixaItemMerenda extends BaseResourceModel {
  constructor(
    public id?: number,
    public produto_unidade?: ProdutoUnidade,
    public qtde?: number,
    public vencimento?: Date,
    public lote?: string,
    public baixa?: BaixaMerenda,
    public editavel?: boolean) {
    super();
  }
  static converteJson(json: any): BaixaItemMerenda {
    return Object.assign(new BaixaItemMerenda(), json);
  }
}
