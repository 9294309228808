import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, FavorecidoBanco } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class FavorecidoBancoService extends BaseResourceService<FavorecidoBanco> {

  constructor(
    protected injector: Injector
  ) {
    super(`favorecido-bancos`, injector);
  }

}
