// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { PagamentoExtra } from './pagamento-extra.model';
import { PagamentoResto } from './pagamento-resto.model';
import { Pagamento } from './pagamento.model';
import { TransferenciaRetencao } from './transferencia-retencao.model';

export class TransferenciaRetencaoItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public especie?: string,
    public valor_retencao?: number,
    public transferencia?: TransferenciaRetencao,
    public pagamento?: Pagamento,
    public pagamento_extra?: PagamentoExtra,
    public pagamento_resto?: PagamentoResto,
    public usuario_cadastro?: Usuario,
    public editavel?: boolean
  ) {
    super();
  }

  static converteJson(json: any): TransferenciaRetencaoItem {
    return Object.assign(new TransferenciaRetencaoItem(), json);
  }
}
