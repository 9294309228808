import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../util/shared.module';
import { ScheduleListComponent } from './schedule-list/schedule-list.component';
import { ScheduleLogDlgComponent } from './schedule-log-dlg/schedule-log-dlg.component';
import { ScheduleRoutingModule } from './schedule-routing.module';

@NgModule({
  declarations: [ScheduleListComponent, ScheduleLogDlgComponent],
  imports: [
    CommonModule,
    ScheduleRoutingModule,
    SharedModule,
  ]
})
export class ScheduleModule { }
