import { NgModule } from '@angular/core';

import { FichaDespesaRoutingModule } from './ficha-despesa-routing.module';
import { IMaskModule } from 'angular-imask';
import { FormsModule } from '@angular/forms';

import { AcertoPrevisaoFichaDespesaDlgComponent } from './acerto-previsao-ficha-despesa-dlg/acerto-previsao-ficha-despesa-dlg.component';
import { FichaDespesaViewComponent } from './ficha-despesa-view/ficha-despesa-view.component';
import { FichaDespesaDlgComponent } from './ficha-despesa-dlg/ficha-despesa-dlg.component';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { SharedModule } from 'eddydata-lib';
import { PanelModule } from 'primeng/panel';
import { FieldsetModule } from 'primeng/fieldset';

@NgModule({
  declarations: [FichaDespesaViewComponent, FichaDespesaDlgComponent, AcertoPrevisaoFichaDespesaDlgComponent],
  exports: [FichaDespesaViewComponent, FichaDespesaDlgComponent, AcertoPrevisaoFichaDespesaDlgComponent],
  imports: [
    SharedModule,
    FormsModule,
    FichaDespesaRoutingModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    PanelModule,
    FieldsetModule
  ],
  providers: [MessageService]
})
export class FichaDespesaViewModule { }
