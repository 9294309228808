import { Directive, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { QuadroSaudeService } from '../../service/quadro-saude.service';
import {
  FuncaoService, GlobalService, Login,
  Exercicio, FuncaoGoverno, Relatorio, FormatoExportacao, Coluna,
  OrgaoAssinatura,
  OrgaoAssinaturaService
} from 'eddydata-lib';
import { Anexo12Imposto } from './anexo12.imposto';
import { Anexo12ReceitaAdicional } from './anexo12.receita-adicional';
import { Anexo12DespesaComputada } from './anexo12.despesa-computada';
import { Anexo12DespesaNaoComputada } from './anexo12.despesa-nao-computada';
import { Anexo12ControleDisponibilidade } from './anexo12.controle-disponibilidade';
import { Anexo12ControleRecurso } from './anexo12.controle-recurso';
import { Anexo12DespesaFuncao } from './anexo12.despesa-funcao';

@Directive()
export class Anexo12Saude implements OnDestroy {
  private assinatura: OrgaoAssinatura;
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected quadroServico: QuadroSaudeService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected assinaturaService: OrgaoAssinaturaService,
    protected funcao?: FuncaoGoverno) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async listar(orgaos: number[]) {
    return await this.quadroServico
      .obterAnexo12(this.mes, this.exercicio.id, orgaos).toPromise();
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
    this.quadroServico.obterAnexo12(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(
            null
            , this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.conteudo(dados),
            'portrait', 'ANEXO 12 - SAÚDE',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            });
        } else {
          const conteudo = this.conteudoExportacao(dados, formato);
          this.funcaoService.exportar(formato, conteudo, 'ANEXO 12 - SAÚDE', this.colunasRelatorio());
        }
      });
  }

  private async conteudo(dados: any[]): Promise<{}[]> {

    let registros: {}[] = [];
    const impostos = new Anexo12Imposto(this.mes, this.exercicio);
    registros = impostos.montarQuadro(dados[0]);

    let adicionais: {}[] = [];
    const receitaAdicional = new Anexo12ReceitaAdicional(this.mes, this.exercicio);
    adicionais = receitaAdicional.montarQuadro(dados[1]);

    let computadas: {}[] = [];
    const despesaComputada = new Anexo12DespesaComputada(this.mes, this.exercicio);
    computadas = despesaComputada.montarQuadro(dados[2]);

    let naoComputadas: {}[] = [];
    const despesaNaoComputada = new Anexo12DespesaNaoComputada(this.mes, this.exercicio);
    naoComputadas = despesaNaoComputada.montarQuadro(dados[3], dados[2], dados[0]);

    let despesaCusteadadisponibilidades: {}[] = [];
    const controleDisponibilidade = new Anexo12ControleDisponibilidade(this.mes, this.exercicio);
    despesaCusteadadisponibilidades = controleDisponibilidade.montarQuadro(dados[4]);

    let despesaCusteadaRecursos: {}[] = [];
    const controleRecurso = new Anexo12ControleRecurso(this.mes, this.exercicio);
    despesaCusteadaRecursos = controleRecurso.montarQuadro(dados[5]);

    let despesaFuncoes: {}[] = [];
    const despesaFuncao = new Anexo12DespesaFuncao(this.mes, this.exercicio);
    despesaFuncoes = despesaFuncao.montarQuadro(dados[6]);

    if (this.login.parametro?.contabil?.assinatura_por_funcao) {
      this.assinatura = await this.assinaturaService.obter({ orgao_id: this.login.orgao.id }).toPromise();

      const assinatura = [
        [
          {
            text: '', alignment: 'center',
            border: [false, false, false, false], margin: [0, 30, 0, 0]
          },
          {
            text: this.assinatura.ordenador_despesa_funcao_10 ? '__________________________________________' : '', alignment: 'center',
            border: [false, false, false, false], margin: [0, 30, 0, 0]
          },
          {
            text: '', alignment: 'center',
            border: [false, false, false, false], margin: [0, 30, 0, 0]
          }
        ],
        [
          { text: '', alignment: 'center', border: [false, false, false, false] },
          { text: this.assinatura.ordenador_despesa_funcao_10 ? this.assinatura.ordenador_despesa_funcao_10 : '', alignment: 'center', border: [false, false, false, false] },
          { text: '', alignment: 'center', border: [false, false, false, false] }
        ],
        [
          { text: '', alignment: 'center', border: [false, false, false, false] },
          { text: this.assinatura.cargo_ordenador_despesa_funcao_10 ? this.assinatura.cargo_ordenador_despesa_funcao_10 : '', alignment: 'center', border: [false, false, false, false] },
          { text: '', alignment: 'center', border: [false, false, false, false] }
        ],
      ];

      return [{
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 60, 60, 60, 40],
          body: registros
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 60, 60, 60, 40],
          body: adicionais
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 60, 60, 60, 40, 60, 40],
          body: computadas
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 60, 60, 60, 40, 60, 40],
          body: naoComputadas
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 100, 100],
          body: despesaCusteadadisponibilidades
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 100, 100],
          body: despesaCusteadaRecursos
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 60, 60, 60, 40, 60, 40],
          body: despesaFuncoes
        }
      }, 
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          heights: [10, 0],
          widths: ['*', '*', '*'],
          body: assinatura
        }
      }];
    } else {
      return [{
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 60, 60, 60, 40],
          body: registros
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 60, 60, 60, 40],
          body: adicionais
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 60, 60, 60, 40, 60, 40],
          body: computadas
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 60, 60, 60, 40, 60, 40],
          body: naoComputadas
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 100, 100],
          body: despesaCusteadadisponibilidades
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 100, 100],
          body: despesaCusteadaRecursos
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 60, 60, 60, 40, 60, 40],
          body: despesaFuncoes
        }
      }];
    }
  }

  private colunasRelatorio(): Coluna[] {
    return [
      { titulo: 'RECEITAS PARA APURAÇÃO DA APLICAÇÃO EM AÇÕES E SERVIÇOS PÚBLICOS DE SAÚDE', coluna: 'receita_desc' },
      { titulo: 'PREVISÃO INICIAL DO EXERCÍCIO', coluna: 'inicial' },
      { titulo: 'PREVISÃO ATUALIZADA (a)', coluna: 'atualizada' },
      { titulo: 'ARRECADADO (b)', coluna: 'arrecadado_empenhada' },
      { titulo: '% (b/a) x 100', coluna: 'calculo_empenhada' },
      { titulo: '', coluna: 'liquidada' },
      { titulo: '', coluna: 'calculo_liquidada' },
    ];
  }

  private conteudoExportacao(dados: any[], formato: FormatoExportacao): {}[] {
    let registros: {}[] = [];
    const impostos = new Anexo12Imposto(this.mes, this.exercicio);
    registros = impostos.montarQuadro(dados[0], formato);

    let adicionais: {}[] = [];
    const receitaAdicional = new Anexo12ReceitaAdicional(this.mes, this.exercicio);
    adicionais = receitaAdicional.montarQuadro(dados[1], formato);

    let computadas: {}[] = [];
    const despesaComputada = new Anexo12DespesaComputada(this.mes, this.exercicio);
    computadas = despesaComputada.montarQuadro(dados[2], formato);

    let naoComputadas: {}[] = [];
    const despesaNaoComputada = new Anexo12DespesaNaoComputada(this.mes, this.exercicio);
    naoComputadas = despesaNaoComputada.montarQuadro(dados[3], dados[2], dados[0], formato);

    let despesaCusteadadisponibilidades: {}[] = [];
    const controleDisponibilidade = new Anexo12ControleDisponibilidade(this.mes, this.exercicio);
    despesaCusteadadisponibilidades = controleDisponibilidade.montarQuadro(dados[4], formato);

    let despesaCusteadaRecursos: {}[] = [];
    const controleRecurso = new Anexo12ControleRecurso(this.mes, this.exercicio);
    despesaCusteadaRecursos = controleRecurso.montarQuadro(dados[5], formato);

    let despesaFuncoes: {}[] = [];
    const despesaFuncao = new Anexo12DespesaFuncao(this.mes, this.exercicio);
    despesaFuncoes = despesaFuncao.montarQuadro(dados[6], formato);

    return registros.concat(adicionais)
      .concat(computadas).concat(naoComputadas)
      .concat(despesaCusteadadisponibilidades)
      .concat(despesaCusteadaRecursos).concat(despesaFuncoes);
  }

}
