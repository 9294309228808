import { TipoUnidade } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { UnidadeMembro } from '../compra/unidade-membro.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { Ppa } from './ppa.model';

export class Unidade extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public nome?: string,
    public cpf?: string,
    public telefone?: string,
    public responsavel?: string,
    public cargo?: string,
    public ato?: string,
    public ativo?: boolean,
    public tribunal?: string,
    public ordenador?: string,
    public cargo_ordenador?: string,
    public tesoureiro?: string,
    public cargo_tesoureiro?: string,
    public presidente_conselho?: string,
    public cargo_presidente_conselho?: string,
    public tipo?: TipoUnidade,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public cadastrado_pncp?: boolean,
    public orgao?: Orgao,
    public ppa?: Ppa,
    public responsavel_fatura?: Usuario,
    public membros?: UnidadeMembro[],
    public email?: string
  ) {
    super();
  }

  static converteJson(json: any): Unidade {
    return Object.assign(new Unidade(), json);
  }
}
