import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, CotaMensal } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class CotaMensalService extends BaseResourceService<CotaMensal> {

  constructor(
    protected injector: Injector
  ) {
    super(`cotas-mensais`, injector);
  }

}
