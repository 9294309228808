import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, FavorecidoCnae } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class FavorecidoCnaeService extends BaseResourceService<FavorecidoCnae> {

  constructor(
    protected injector: Injector
  ) {
    super(`favorecido-cnaes`, injector);
  }

}
