import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { PessoaModule, SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AceiteRequisicaoDevolucaoDlgComponent } from './aceite-requisicao-devolucao-dlg/aceite-requisicao-devolucao-dlg.component';
import { RecusarRequisicaoDevolucaoDlgComponent } from './recusar-requisicao-devolucao-dlg/recusar-requisicao-devolucao-dlg.component';
import { RequisicaoAutorizacaoDevolucaoFormComponent } from './requisicao-autorizacao-devolucao-form/requisicao-autorizacao-devolucao-form.component';
import { RequisicaoAutorizacaoDevolucaoItemComponent } from './requisicao-autorizacao-devolucao-item/requisicao-autorizacao-devolucao-item.component';
import { RequisicaoAutorizacaoDevolucaoListComponent } from './requisicao-autorizacao-devolucao-list/requisicao-autorizacao-devolucao-list.component';
import { RequisicaoAutorizacaoDevolucaoRoutingModule } from './requisicao-autorizacao-devolucao-routing.module';
import { RequisicaoAutorizacaoDevolucaoViewComponent } from './requisicao-autorizacao-devolucao-view/requisicao-autorizacao-devolucao-view.component';
import { InventarioEstoqueModule } from '../inventario-estoque/inventario-estoque.module';
import { ProdutoDlgModule } from '../produto/produto-dlg.module';



@NgModule({
  declarations: [
    RequisicaoAutorizacaoDevolucaoListComponent,
    RequisicaoAutorizacaoDevolucaoFormComponent,
    RequisicaoAutorizacaoDevolucaoItemComponent,
    RequisicaoAutorizacaoDevolucaoViewComponent, 
    RecusarRequisicaoDevolucaoDlgComponent,
    AceiteRequisicaoDevolucaoDlgComponent
  ],
  exports: [
    RequisicaoAutorizacaoDevolucaoListComponent,
    RequisicaoAutorizacaoDevolucaoFormComponent,
    RecusarRequisicaoDevolucaoDlgComponent,
    AceiteRequisicaoDevolucaoDlgComponent
  ],
  imports: [
    CommonModule,
    RequisicaoAutorizacaoDevolucaoRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    RadioButtonModule,
    InputNumberModule,
    PessoaModule,
    FieldsetModule,
    TooltipModule,
    CheckboxModule,
    ConfirmDialogModule,
    InventarioEstoqueModule,
    ProdutoDlgModule
  ],
  providers: [MessageService]
})
export class RequisicaoAutorizacaoDevolucaoModule { }
