import { Empenho } from 'eddydata-lib';
import { BaseResourceStorage } from '../../models/base-resource.storage';

export class EmpenhoStorage extends BaseResourceStorage {
  constructor(
    public arquivo?: string,
    public visivel_transparencia?: boolean,
    public foto?: boolean,
    public empenho?: Empenho,
  ) {
    super();
  }

  static converteJson(json: any): EmpenhoStorage {
    return Object.assign(new EmpenhoStorage(), json);
  }
}