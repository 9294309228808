import { BaseResourceModel } from "../../models/base-resource.model";
import { TipoContratacao } from "./tipo-contratacao.model";

export class TipoContratacaoEvento extends BaseResourceModel {
    constructor(
        public id?: number,
        public finalidade?: number,
        public especie?: string,
        public situacao?: string,
        public tipo_contratacao?: TipoContratacao,
        public evento_especie?: TipoContratacaoEvento,
        public editavel?: boolean
    ) {
        super();
    }

    static converteJson(json: any): TipoContratacaoEvento {
        return Object.assign(new TipoContratacaoEvento(), json);
    }
}
