import { BaseResourceModel } from "../../models/base-resource.model";
import { Orgao } from "../comum/orgao.model";

export class ArquivoLiquidacao extends BaseResourceModel {
  constructor(
    public id?: number,
    public descricao?: string,
    public nome?: string,
    public armazenado?: boolean,
    public orgao?: Orgao,
  ) {
    super();
  }

  static converteJson(json: any): ArquivoLiquidacao {
    return Object.assign(new ArquivoLiquidacao(), json);
  }
}