import { Component, Input, OnInit } from '@angular/core';
import { FuncaoService } from '../../util/funcao.service';
import { GlobalService } from '../../util/global.service';
import { Orgao } from '../../entidade/comum/orgao.model';

interface IntegracaoTypes {
  nome: string;
  id: string;
}
@Component({
  selector: 'lib-orgao-integracao-shw',
  templateUrl: './orgao-integracao-shw.component.html',
  styleUrls: ['./orgao-integracao-shw.component.css']
})
export class OrgaoIntegracaoShwComponent implements OnInit {

  @Input() visualizar: boolean;
  @Input() orgao: Orgao;

  public integracaoTypes: IntegracaoTypes[] = [
    { id: 'PCP', nome: 'Portal de Compras Públicas' },
    { id: 'BLL', nome: 'BLL Compras' },
    { id: 'BNC', nome: 'BNC Compras' },
    { id: 'NENHUMA', nome: 'Nenhuma' }
  ]

  public integracaoLicitacao: {
    id: string,
    nome: string
  }[] = [];

  constructor(private funcaoService: FuncaoService,
    private globalService: GlobalService) { }

  ngOnInit(): void {
    this.integracaoLicitacao = this.globalService.obterIntegracaoLicitacao();
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public podeVisualizar() {
    if (this.visualizar === true) {
      return true;
    }
    if (this.visualizar === false) {
      return false;
    }
  }

  public trocaIntegracao() {
    this.orgao.chave_acesso = ''
  }
}
