import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { IMaskModule } from 'angular-imask';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { EmpenhoExtraRoutingModule } from './empenho-extra-routing.module';
import { EmpenhoExtraListComponent } from './empenho-extra-list/empenho-extra-list.component';
import { EmpenhoExtraAnulacaoComponent } from './empenho-extra-anulacao/empenho-extra-anulacao.component';
import { RetencaoExtraComponent } from './retencao-extra/retencao-extra.component';
import { EmpenhoExtraFormComponent } from './empenho-extra-form/empenho-extra-form.component';
import { EmpenhoExtraParcelaComponent } from './empenho-extra-parcela/empenho-extra-parcela.component';
import { SharedModule } from 'eddydata-lib';
import { NotaEmpenhoExtraDlgComponent } from './nota-empenho-extra-dlg/nota-empenho-extra-dlg.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { EmpenhoExtraRptComponent } from './empenho-extra-rpt/empenho-extra-rpt.component';
import { FieldsetModule } from 'primeng/fieldset';
import { EmpenhoExtraRetencaoComponent } from './empenho-extra-retencao/empenho-extra-retencao.component';
import { GrdEmpenhoExtraDlgComponent } from './grd-empenho-extra-dlg/grd-empenho-extra-dlg.component';
import { EmpenhoExtraEmAbertoDlgComponent } from './empenho-extra-em-aberto-dlg/empenho-extra-em-aberto-dlg.component';
import { ListboxModule } from 'primeng/listbox';
import { InplaceModule } from 'primeng/inplace';
import { EmpenhoModule } from '../empenho/empenho.module';
import { RetencaoExtraReinfComponent } from './retencao-extra-reinf/retencao-extra-reinf.component';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [
    EmpenhoExtraListComponent,
    EmpenhoExtraFormComponent,
    EmpenhoExtraRptComponent,
    EmpenhoExtraAnulacaoComponent,
    EmpenhoExtraParcelaComponent,
    RetencaoExtraComponent,
    NotaEmpenhoExtraDlgComponent,
    EmpenhoExtraRetencaoComponent,
    GrdEmpenhoExtraDlgComponent,
    EmpenhoExtraEmAbertoDlgComponent,
    RetencaoExtraReinfComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    EmpenhoExtraRoutingModule,
    ListboxModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    ConfirmDialogModule,
    IMaskModule,
    ToastModule,
    InplaceModule,
    RadioButtonModule,
    FieldsetModule,
    TableModule,
    EmpenhoModule,
    TooltipModule
  ],
  providers: [MessageService, ConfirmationService]
})
export class EmpenhoExtraModule { }
