import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import {
  FuncaoService, GlobalService, Exercicio, Login
} from 'eddydata-lib';

@Directive()
export class Anexo8Apuracao implements OnDestroy {

  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number,
    protected exercicio: Exercicio) {
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(impostos: any[], fundeb: any[], DCFundeb: any[], indicadores: any[], despesaMde: any[], restoMde: any[]): {}[] {

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: 'APURAÇÃO DAS DESPESAS PARA FINS DE LIMITE MÍNIMO CONSTITUCIONAL',
        alignment: 'center',
        bold: true,
        margin: [5, 5, 5, 5],
        colSpan: 3
      }, '', '', {
        text: 'VALOR',
        alignment: 'center',
        fontSize: 6,
      }],

    ];

    let liquidadoDespesa: number = 0;
    let empenhadoDespesa: number = 0;
    let empenhadoMDE: number = 0;
    let liquidadoMDE: number = 0;
    let disponibilidade: number = 0;
    let L3: number = 0;
    let L4: number = 0;
    let L18: number = 0;
    let L19: number = 0;
    let cancelado: number = 0;
    let total: number = 0;
    let arrecadadoFundeb: number = 0;
    let arrecadadoAnterior: number = 0;
    let naoAplicado: number = 0;
    let ajuste: number = 0;

    // Caso seja adicionado novos campos no layout será necessario mudar a posição dos arrays
    L4 = ((impostos[4].fundeb_recebido + impostos[6].fundeb_recebido + impostos[7].fundeb_recebido + impostos[8].fundeb_recebido + impostos[9].fundeb_recebido + impostos[11].fundeb_recebido));

    const grupoImposto = this.funcaoService.agrupar(impostos, 'grupo', ['atualizado', 'arrecadado']);

    const grupoDespesa = this.funcaoService.agrupar(DCFundeb, 'grupo', ['empenhado', 'liquidado', 'pago', 'resto', 'disponibilidade'])

    const grupoFundeb = this.funcaoService.agrupar(fundeb, 'grupo', ['arrecadado', 'atualizado', 'arrecadado_anterior', 'atualizado_anterior']);

    L3 += grupoImposto.reduce((acc, item) => +item.totalizadores['arrecadado'] + acc, 0);
    empenhadoMDE += despesaMde.reduce((acc, item) => +item.empenhado + acc, 0);
    liquidadoMDE += despesaMde.reduce((acc, item) => +item.liquidado + acc, 0);
    arrecadadoFundeb = grupoFundeb.reduce((acc, item) => +item.totalizadores['arrecadado'] + acc, 0);
    arrecadadoAnterior = grupoFundeb.reduce((acc, item) => +item.totalizadores['arrecadado_anterior'] + acc, 0);
    liquidadoDespesa = grupoDespesa.reduce((acc, item) => (item.grupo === '11- TOTAL DAS DESPESAS CUSTEADAS COM RECURSOS DO FUNDEB RECEBIDAS NO EXERCÍCIO' ? +item.totalizadores['liquidado'] : 0) + acc, 0);
    empenhadoDespesa = grupoDespesa.reduce((acc, item) => +item.totalizadores['empenhado'] + acc, 0);

    naoAplicado = arrecadadoFundeb - (liquidadoDespesa);
    ajuste = ((arrecadadoFundeb * 10) / 100);
    L18 = naoAplicado > ajuste ? naoAplicado - ajuste : 0;

    for (let item of indicadores) {
      if (item.subgrupo === '19.1- Total das Despesas custeadas com FUNDEB - Impostos e Transferências de Impostos') {
        L19 = 0;//+item.valor + arrecadadoAnterior + +item.superavit;
      }
    }

    for (let item of restoMde) {
      if (item.subgrupo === '34.1 - Executadas com Recursos de Impostos e Transferências de Impostos' || item.subgrupo === '34.2 - Executadas com Recursos do FUNDEB - Impostos') {
        cancelado += item.cancelado;
      }
    }

    total = (this.mes === 12 ? empenhadoMDE : liquidadoMDE) + L4 - L18 + L19 - cancelado;

    registros.push([
      { text: '22- TOTAL DAS DESPESAS DE MDE CUSTEADAS COM RECURSOS DE IMPOSTOS = L20(d ou e)', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: this.funcaoService.convertToBrNumber(this.mes === 12 ? empenhadoMDE : liquidadoMDE), alignment: 'right', fontSize: 7, bold: true },
    ]);
    registros.push([
      { text: '23- TOTAL DAS RECEITAS TRANSFERIDAS AO FUNDEB = (L4)', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: this.funcaoService.convertToBrNumber(L4), alignment: 'right', fontSize: 7, bold: true },
    ]);
    registros.push([
      { text: '24- (-) RECEITAS DO FUNDEB NÃO UTILIZADAS NO EXERCÍCIO, EM VALOR SUPERIOR A 10% = L18(q)', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: this.funcaoService.convertToBrNumber(L18), alignment: 'right', fontSize: 7, bold: true },
    ]);
    registros.push([
      { text: '25- VALOR APLICADO ATÉ O PRIMEIRO QUADRIMESTRE QUE INTEGRARÁ O LIMITE CONSTITUCIONAL = L19.1(x)', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: this.funcaoService.convertToBrNumber(L19), alignment: 'right', fontSize: 7, bold: true },
    ]);
    registros.push([
      { text: '26- (-) RESTOS A PAGAR NÃO PROCESSADOS INSCRITOS NO EXERCÍCIO SEM DISPONIBILIDADE FINANCEIRA DE RECURSOS DE IMPOSTOS', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: '0,00', alignment: 'right', fontSize: 7, bold: true },
    ]);
    registros.push([
      { text: '27- (-) CANCELAMENTO, NO EXERCÍCIO, DE RESTOS A PAGAR INSCRITOS COM DISPONIBILIDADE FINANCEIRA DE RECURSOS DE IMPOSTOS VINCULADOS AO ENSINO = (L30.1(af) + L30.2(af))', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: this.funcaoService.convertToBrNumber(cancelado), alignment: 'right', fontSize: 7, bold: true },
    ]);
    registros.push([
      { text: '28- TOTAL DAS DESPESAS PARA FINS DE LIMITE (22 + 23 - 24 + 25 - 26 - 27)', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: this.funcaoService.convertToBrNumber(total), alignment: 'right', fontSize: 7, bold: true },
    ]);
    registros.push([
      { text: 'APURAÇÃO DO LIMITE MÍNIMO CONSTITUCIONAL 2 e 5', fontSize: 7, bold: true },
      { text: 'VALOR EXIGIDO (x)', alignment: 'right', fontSize: 7, bold: true },
      { text: 'VALOR APLICADO (w)', alignment: 'right', fontSize: 7, bold: true },
      { text: '% APLICADO (y)', alignment: 'right', fontSize: 7, bold: true },
    ]);
    registros.push([
      { text: '29- APLICAÇÃO EM MDE SOBRE A RECEITA RESULTANTE DE IMPOSTOS', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(L3 * 0.25), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber((total / L3) * 100), alignment: 'right', fontSize: 7, bold: true },
    ]);

    return registros;

  }

  public montarQuadroCsv(impostos: any[], fundeb: any[], DCFundeb: any[], indicadores: any[], despesaMde: any[], restoMde: any[], listaExportar): any {
    
    const espaco = {
      titulo: '',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(espaco)

    const primeira_linha = {
      titulo: 'APURAÇÃO DAS DESPESAS PARA FINS DE LIMITE MÍNIMO CONSTITUCIONAL',
      coluna2: 'VALOR',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(primeira_linha)

    let liquidadoDespesa: number = 0;
    let empenhadoDespesa: number = 0;
    let empenhadoMDE: number = 0;
    let liquidadoMDE: number = 0;
    let disponibilidade: number = 0;
    let L3: number = 0;
    let L4: number = 0;
    let L18: number = 0;
    let L19: number = 0;
    let cancelado: number = 0;
    let total: number = 0;
    let arrecadadoFundeb: number = 0;
    let arrecadadoAnterior: number = 0;
    let naoAplicado: number = 0;
    let ajuste: number = 0;

    // Caso seja adicionado novos campos no layout será necessario mudar a posição dos arrays
    L4 = ((impostos[4].fundeb_recebido + impostos[6].fundeb_recebido + impostos[7].fundeb_recebido + impostos[8].fundeb_recebido + impostos[9].fundeb_recebido + impostos[11].fundeb_recebido));

    const grupoImposto = this.funcaoService.agrupar(impostos, 'grupo', ['atualizado', 'arrecadado']);

    const grupoDespesa = this.funcaoService.agrupar(DCFundeb, 'grupo', ['empenhado', 'liquidado', 'pago', 'resto', 'disponibilidade'])

    const grupoFundeb = this.funcaoService.agrupar(fundeb, 'grupo', ['arrecadado', 'atualizado', 'arrecadado_anterior', 'atualizado_anterior']);

    L3 += grupoImposto.reduce((acc, item) => +item.totalizadores['arrecadado'] + acc, 0);
    empenhadoMDE += despesaMde.reduce((acc, item) => +item.empenhado + acc, 0);
    liquidadoMDE += despesaMde.reduce((acc, item) => +item.liquidado + acc, 0);
    arrecadadoFundeb = grupoFundeb.reduce((acc, item) => +item.totalizadores['arrecadado'] + acc, 0);
    arrecadadoAnterior = grupoFundeb.reduce((acc, item) => +item.totalizadores['arrecadado_anterior'] + acc, 0);
    liquidadoDespesa = grupoDespesa.reduce((acc, item) => (item.grupo === '11- TOTAL DAS DESPESAS CUSTEADAS COM RECURSOS DO FUNDEB RECEBIDAS NO EXERCÍCIO' ? +item.totalizadores['liquidado'] : 0) + acc, 0);
    empenhadoDespesa = grupoDespesa.reduce((acc, item) => +item.totalizadores['empenhado'] + acc, 0);

    naoAplicado = arrecadadoFundeb - (liquidadoDespesa);
    ajuste = ((arrecadadoFundeb * 10) / 100);
    L18 = naoAplicado > ajuste ? naoAplicado - ajuste : 0;

    for (let item of indicadores) {
      if (item.subgrupo === '19.1- Total das Despesas custeadas com FUNDEB - Impostos e Transferências de Impostos') {
        L19 = 0;//+item.valor + arrecadadoAnterior + +item.superavit;
      }
    }

    for (let item of restoMde) {
      if (item.subgrupo === '34.1 - Executadas com Recursos de Impostos e Transferências de Impostos' || item.subgrupo === '34.2 - Executadas com Recursos do FUNDEB - Impostos') {
        cancelado += item.cancelado;
      }
    }

    total = (this.mes === 12 ? empenhadoMDE : liquidadoMDE) + L4 - L18 + L19 - cancelado;

    const primeira_22 = {
      titulo: '22- TOTAL DAS DESPESAS DE MDE CUSTEADAS COM RECURSOS DE IMPOSTOS = L20(d ou e)',
      coluna2: this.funcaoService.convertToBrNumber(this.mes === 12 ? empenhadoMDE : liquidadoMDE),
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(primeira_22)

    const primeira_23 = {
      titulo: '23- TOTAL DAS RECEITAS TRANSFERIDAS AO FUNDEB = (L4)',
      coluna2: this.funcaoService.convertToBrNumber(L4),
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(primeira_23)

    const primeira_24 = {
      titulo: '24- (-) RECEITAS DO FUNDEB NÃO UTILIZADAS NO EXERCÍCIO, EM VALOR SUPERIOR A 10% = L18(q)',
      coluna2: this.funcaoService.convertToBrNumber(L18),
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(primeira_24)

    const primeira_25 = {
      titulo: '25- VALOR APLICADO ATÉ O PRIMEIRO QUADRIMESTRE QUE INTEGRARÁ O LIMITE CONSTITUCIONAL = L19.1(x)',
      coluna2: this.funcaoService.convertToBrNumber(L19),
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(primeira_25)

    const primeira_26 = {
      titulo: '26- (-) RESTOS A PAGAR NÃO PROCESSADOS INSCRITOS NO EXERCÍCIO SEM DISPONIBILIDADE FINANCEIRA DE RECURSOS DE IMPOSTOS',
      coluna2: '0,00',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(primeira_26)

    const primeira_27 = {
      titulo: '27- (-) CANCELAMENTO, NO EXERCÍCIO, DE RESTOS A PAGAR INSCRITOS COM DISPONIBILIDADE FINANCEIRA DE RECURSOS DE IMPOSTOS VINCULADOS AO ENSINO = (L30.1(af) + L30.2(af))',
      coluna2: this.funcaoService.convertToBrNumber(cancelado),
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(primeira_27)

    const primeira_28 = {
      titulo: '28- TOTAL DAS DESPESAS PARA FINS DE LIMITE (22 + 23 - 24 + 25 - 26 - 27)',
      coluna2: this.funcaoService.convertToBrNumber(total),
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(primeira_28)

    const primeira_apuracao = {
      titulo: 'APURAÇÃO DO LIMITE MÍNIMO CONSTITUCIONAL 2 e 5',
      coluna2: 'VALOR EXIGIDO (x)',
      coluna3: 'VALOR APLICADO (w)',
      coluna4: '% APLICADO (y)',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(primeira_apuracao)

    const primeira_29 = {
      titulo: '29- APLICAÇÃO EM MDE SOBRE A RECEITA RESULTANTE DE IMPOSTOS',
      coluna2: this.funcaoService.convertToBrNumber(L3 * 0.25),
      coluna3: this.funcaoService.convertToBrNumber(total),
      coluna4: this.funcaoService.convertToBrNumber((total / L3) * 100),
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(primeira_29)

  }

}