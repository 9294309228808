// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Pessoa } from '../comum/pessoa.model';

export class ParametroLicitacao extends BaseResourceModel {
  constructor(
    public id?: number,
    public bloqueio_razaosocial?: boolean,
    public orgao?: Orgao,
    public saldo_contrato_reservada?: boolean,
    public valor_ufesp?: number,
    public filtrar_ufesp?: boolean,
    public ufesp?: number,
    public licitacoes_bb?: boolean,
    public licitacoes_e?: string,
    public convenio_licitacoes_e?: string,
    public lei_14133_2021?: boolean,
    public contratante_padrao?: boolean,
    public contratante?: Pessoa,
    public agrupamento_pregao?: boolean,
    public contratos_inativos?: boolean,
    public reserva_ativa?: boolean,
    public importar_requisicao?: boolean
  ) {
    super();
  }

  static converteJson(json: any): ParametroLicitacao {
    return Object.assign(new ParametroLicitacao(), json);
  }
}
