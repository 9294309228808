import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Contrato, LoginContabil } from 'eddydata-lib';
import * as toastr from "toastr";
declare var $: any;

@Component({
  selector: 'lib-compra-contratos-dlg',
  templateUrl: './compra-contratos-dlg.component.html'
})
export class CompraContratosDlgComponent implements OnInit {

  @Input() public login: LoginContabil;

  @Input() public contratos: Contrato[] = [];
  @Input() public processo: string;
  
  @Input() public apertouProcesso: boolean
  @Output() apertouProcessoChange: EventEmitter<boolean>  = new EventEmitter();

  @Input() public apertouContrato: boolean
  @Output() apertouContratoChange: EventEmitter<boolean> = new EventEmitter();

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Output() onSelecionar: EventEmitter<Contrato> = new EventEmitter();

  @Input() static : boolean = false;

  ngOnInit(): void { }

  public onHide() {
    this.hide();
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
    this.apertouContrato = false;
    this.apertouProcesso = false;
    this.apertouContratoChange.emit(this.apertouContrato);
    this.apertouProcessoChange.emit(this.apertouProcesso);
  }

  public selecionarEvent(contrato: Contrato) {
    if(+contrato.favorecido.tipo.tce !== 1 && +contrato.favorecido.tipo.tce !== 2 && +contrato.favorecido.tipo.tce !== 10){
      toastr.warning('Verifique a espécie no cadastro de fornecedor');
      this.hide();
      return;
    }
    this.onSelecionar.emit(contrato);
    this.hide();
  }
}
