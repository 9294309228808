import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transparencia-login-recuperar-senha-sucesso',
  templateUrl: './transparencia-login-recuperar-senha-sucesso.component.html'
})
export class TransparenciaLoginRecuperarSenhaSucessoComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }

}
