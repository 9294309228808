import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { ContaBancariaService } from 'administrativo-lib';
import { ContaBancariaSaldoService, FichaExtraService } from 'contabil-lib';
import {
  BaseResourceFormComponent, ContaBancaria,
  EddyAutoComplete, FuncaoService, LoginContabil, OrgaoService, FichaExtra, GlobalService, OrgaoAssinaturaService, Login, TransferenciaAutorizacao, ContaBancariaEntidade
} from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TransferenciaAutorizacaoService } from '../service/transferencia-autorizacao.service';
import { TransferenciaAutorizacaoRpt } from '../transferencia-autorizacao-rpt';

declare var $: any;

@Component({
  selector: 'app-transferencia-autorizacao-modal',
  templateUrl: './transferencia-autorizacao-modal.component.html'
})
export class TransferenciaAutorizacaoModalComponent extends BaseResourceFormComponent<TransferenciaAutorizacao, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @Input() login: Login;
  @Input() display: boolean;
  @Output() callback: EventEmitter<boolean> = new EventEmitter();

  protected datepipe: DatePipe;
  public ptBR: any;
  public selectedOrdem: string;
  public selectedOpt: string;
  public selectNaoImpresso: boolean;
  public selectUsuario: boolean;
  public marcarComoImpresso: boolean;
  public dataInicio: Date;
  public dataFinal: Date;
  public numeroInicio: number;
  public contaEntidade: ContaBancariaEntidade;
  public numeroFinal: number;
  public contaBancaria_numero: any;
  public contaBancaria_codigo: any;
  public ativarFild: any = 1;
  public filtros: {
    data1?: Date,
    data2?: Date,
    conta?: ContaBancaria
  }

  protected unsubscribe: Subject<void> = new Subject();

  public origemAutoComplete: EddyAutoComplete<ContaBancaria>;
  public fichaAutoComplete: EddyAutoComplete<FichaExtra>;

  public periodoInicial: Date;
  public periodoFinal: Date;
  public selectFicha: number;
  public fichaExtra: FichaExtra;
  public listaRetencoes: Array<any>;

  public total: number;

  /**
   * Construtor com as injeções de dependencias
   */

  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected contaService: ContaBancariaService,
    protected contaSaldoService: ContaBancariaSaldoService,
    protected orgaoService: OrgaoService,
    protected fichaService: FichaExtraService,
    protected confirmationService: ConfirmationService,
    private globalService: GlobalService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected autorizacaoService: TransferenciaAutorizacaoService) {
    super(new TransferenciaAutorizacao(), injector, TransferenciaAutorizacao.converteJson, autorizacaoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
  }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.selectedOrdem = 'ord1';
    this.selectedOpt = 'opt1';
    this.selectNaoImpresso = false;
    this.selectUsuario = false;
    this.dataInicio = new Date();
    this.dataFinal = new Date();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public imaskNumero = {
    mask: Number,
    scale: 0,
    signed: false
  };

  protected parametrosExtras(): {} {
    return {
      relations: '',
    };
  }

  showDialog() {
  }
  protected afterLoad() {
  }
  protected afterInit(): void {
  }
  public pesquisar() {
  }
  protected beforeSubmit() {
  }
  protected afterSubmit(ent: TransferenciaAutorizacao): void {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public carregarOrigem() {
  }



  public ativarCol(n: number) {
    if (n == 1) {
      this.ativarFild = 1;
      this.selectedOpt = 'opt1';
    }
    if (n == 2) {
      this.ativarFild = 2;
      this.selectedOpt = 'opt2';
    }
    if (n == 3) {
      this.ativarFild = 3;
      this.selectedOpt = 'emp1';
    }
    if (n == 4) {
      this.ativarFild = 4;
      this.selectedOpt = 'cqe1';
    }
    if (n == 5)
      this.ativarFild = 5;
  }

  imprimir() {
    if (this.selectNaoImpresso) {
      this.confirmationService.confirm({
        message: 'Deseja marcar as autorizações de transferências como impressas ?',
        acceptLabel: "Sim",
        rejectLabel: "Não",
        header: 'Confirmação',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.gerarImpressao();
          this.marcarComoImpresso = true;
        },
        reject: () => {
          this.marcarComoImpresso = false;
          this.gerarImpressao();
        }
      });
    } else {
      this.marcarComoImpresso = false;
      this.gerarImpressao();
    }
  }

  async gerarImpressao() {

    const parametros = {};

    let relations = '';
    relations += 'conta_pagadora,conta_pagadora.banco,conta_pagadora.orgao,conta_recebedora,conta_recebedora.orgao,conta_recebedora.banco,exercicio,orgao,conta_pagadora.recursos,conta_pagadora.recursos.aplicacao,conta_pagadora.conta_entidade,conta_recebedora.recursos,conta_recebedora.recursos.aplicacao,conta_recebedora.conta_entidade';
    parametros['relations'] = relations;

    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;

    // opção data
    if (this.selectedOpt === 'opt1') {
      parametros['data_transfere$ge'] = this.datepipe.transform(this.dataInicio, 'yyyy-MM-dd');
      parametros['data_transfere$le'] = this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd');


      if (!this.dataInicio || !this.dataFinal)
        throw new Error('Informe os dados requeridos!');
    }
    // opção NUMERO ID
    if (this.selectedOpt === 'opt2') {
      parametros['id$ge'] = this.numeroInicio;
      parametros['id$le'] = this.numeroFinal;

      if (!this.numeroInicio || !this.numeroFinal)
        throw new Error('Informe os dados requeridos!');

    }

    // Conta Bancaria - Codigo
    if (this.selectedOpt === 'bnc1') {
      parametros['conta_pagadora.numero_conta'] = this.contaBancaria_numero;

      if (!this.contaBancaria_numero) {
        throw new Error("Informe os dados requeridos: 'Numero da conta' !");
      }
    }

    // Conta Bancaria - Numero da Conta
    if (this.selectedOpt === 'bnc2') {
      parametros['conta_pagadora.codigo'] = this.contaBancaria_codigo;

      if (!this.contaBancaria_codigo) {
        throw new Error("Informe os dados requeridos: 'Código da conta bancaria' !");
      }
    }

    parametros['orderBy'] = this.selectedOrdem === 'ord1' ? 'data_transfere' : 'id';

    if (this.selectNaoImpresso)
      parametros['impresso'] = !this.selectNaoImpresso;

    if (this.selectUsuario)
      parametros['usuario_cadastro_id'] = this.login.usuario.id;

    parametros['conta_pagadora.recursos.recurso_pagto'] = true;

    this.autorizacaoService
      .filtrar(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        parametros['opcao'] = this.selectedOpt === 'opt1' ? 1 : 2;
        new TransferenciaAutorizacaoRpt(this.assinaturaService, this.autorizacaoService).imprimir(lista.content, this.login).then(() => {
          if (this.marcarComoImpresso) {
            this.autorizacaoService.marcarComoImpresso(parametros).subscribe(() => {
            });
          }
        });
      }, (error) => this.messageService.add(
        { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
      )
      );
  }

}