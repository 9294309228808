import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { PanelModule } from 'primeng/panel';
import { ToastModule } from 'primeng/toast';
import { PessoaModule } from '../../pessoa/pessoa.module';
import { UsuarioModule } from '../../usuario/usuario.module';
import { SharedModule } from '../../util/shared.module';
import { SetorAlmoxarifadoComponent } from './setor-almoxarifado/setor-almoxarifado.component';
import { SetorAnexoListComponent } from './setor-anexo-list/setor-anexo-list.component';
import { SetorExecutoraComponent } from './setor-executora/setor-executora.component';
import { SetorFormComponent } from './setor-form/setor-form.component';
import { SetorListComponent } from './setor-list/setor-list.component';
import { SetorLocalizacaoComponent } from './setor-localizacao/setor-localizacao.component';
import { SetorProcessoComponent } from './setor-processo/setor-processo.component';
import { SetorProdutoComponent } from './setor-produto/setor-produto.component';
import { SetorResponsavelComponent } from './setor-responsavel/setor-responsavel.component';
import { SetorRoutingModule } from './setor-routing.module';
import { SetorStorageComponent } from './setor-storage/setor-storage.component';
import { SetorViewComponent } from './setor-view/setor-view.component';

@NgModule({
  declarations: [
    SetorAnexoListComponent,
    SetorListComponent,
    SetorFormComponent,
    SetorViewComponent,
    SetorAlmoxarifadoComponent,
    SetorLocalizacaoComponent,
    SetorResponsavelComponent,
    SetorProdutoComponent,
    SetorProcessoComponent,
    SetorStorageComponent,
    SetorExecutoraComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    SetorRoutingModule,
    PessoaModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    UsuarioModule,
    InputMaskModule,
    PanelModule,
    FileUploadModule,
    EditorModule,
  ],
  providers: [MessageService]
})
export class SetorModule { }
