import { BaseResourceModel } from '../../models/base-resource.model';
import { Viagem } from './viagem.model';

export class ViagemPedagio extends BaseResourceModel {
  constructor(
    public id?: number,
    public cidade?: string,
    public praca?: string,
    public valor?: number,
    public viagem?: Viagem,
  ) {
    super();
  }

  static converteJson(json: any): ViagemPedagio {
    return Object.assign(new ViagemPedagio(), json);
  }
}
