import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { PessoaModule, SharedModule, SimplesNacionalDlgModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DataViewModule } from 'primeng/dataview';
import { FieldsetModule } from 'primeng/fieldset';
import { InputMaskModule } from 'primeng/inputmask';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { RelatorioFavorecidoModule } from '../relatorio-favorecido/relatorio-favorecido.module';
import { FavorecidoBancoComponent } from './favorecido-banco/favorecido-banco.component';
import { FavorecidoCeiComponent } from './favorecido-cei/favorecido-cei.component';
import { FavorecidoCnaeComponent } from './favorecido-cnae/favorecido-cnae.component';
import { FavorecidoContatoComponent } from './favorecido-contato/favorecido-contato.component';
import { FavorecidoFrmComponent } from './favorecido-frm/favorecido-frm.component';
import { FavorecidoLicitacaoComponent } from './favorecido-licitacao/favorecido-licitacao.component';
import { FavorecidoLstComponent } from './favorecido-list/favorecido-lst.component';
import { FavorecidoObservacaoComponent } from './favorecido-observacao/favorecido-observacao.component';
import { FavorecidoPenalidadeComponent } from './favorecido-penalidade/favorecido-penalidade.component';
import { FavorecidoPrestadorComponent } from './favorecido-prestador/favorecido-prestador.component';
import { FavorecidoProdutoComponent } from './favorecido-produto/favorecido-produto.component';
import { FavorecidoReinfComponent } from './favorecido-reinf/favorecido-reinf.component';
import { FavorecidoRelatorioCertificadoModule } from './favorecido-relatorio/favorecido-relatorio-certificado.module';
import { FavorecidoRoutingModule } from './favorecido-routing.module';
import { FavorecidoSocioComponent } from './favorecido-socio/favorecido-socio.component';
import { FavorecidoTerceiroSetorComponent } from './favorecido-terceiro-setor/favorecido-terceiro-setor.component';
import { FavorecidoViewComponent } from './favorecido-view/favorecido-view.component';

@NgModule({
  declarations: [
    FavorecidoLstComponent,
    FavorecidoViewComponent,
    FavorecidoFrmComponent,
    FavorecidoCnaeComponent,
    FavorecidoCeiComponent,
    FavorecidoPenalidadeComponent,
    FavorecidoBancoComponent,
    FavorecidoObservacaoComponent,
    FavorecidoProdutoComponent,
    FavorecidoSocioComponent,
    FavorecidoContatoComponent,
    FavorecidoTerceiroSetorComponent,
    FavorecidoPrestadorComponent,
    FavorecidoLicitacaoComponent,
    FavorecidoReinfComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    FavorecidoRoutingModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    ChartModule,
    InputMaskModule,
    RadioButtonModule,
    DataViewModule,
    FieldsetModule,
    PanelModule,
    PessoaModule,
    ButtonModule,
    MenuModule,
    RelatorioFavorecidoModule,
    CheckboxModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    FavorecidoRelatorioCertificadoModule,
    TableModule,
    SimplesNacionalDlgModule
  ],
  exports: [
    FavorecidoLstComponent,
    FavorecidoViewComponent,
    FavorecidoFrmComponent,
    FavorecidoCnaeComponent,
    FavorecidoPenalidadeComponent,
    FavorecidoBancoComponent,
    FavorecidoObservacaoComponent,
    FavorecidoSocioComponent
  ],
  providers: [MessageService]
})
export class FavorecidoModule { }
