import { Directive, OnDestroy } from '@angular/core';
import { FuncaoService, GlobalService, LoginContabil, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LdoService } from '../../../../planejamento/ldo/service/ldo.service';

@Directive()
export class AnexoCompatibilidadeLdo implements OnDestroy {
  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected ano: number,
    protected ldoServico: LdoService) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio() {
    this.ldoServico.filtrar({
      tipo$in: 'D50,D51',
      'ppa.id': this.login.ppa.id,
      'orgao.id': this.login.orgao.id,
      orderBy: 'linha'
    })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        const lista50 = [];
        const lista51 = [];
        for (const item of dados.content) {
          item.tipo === 'D50' ? lista50.push(item) : lista51.push(item);
        }
        const lista = [];
        lista.push(
          this.conteudo(lista50)
        );
        Relatorio.imprimirPersonalizado(
          `LEI ORÇAMENTÁRIA ANUAL - ${this.ano}`
          , this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          lista,
          'portrait', 'COMPATIBILIDADE LOA X LDO',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private conteudo(dados1: any[]): {}[] {
    if (dados1.length === 0) {
      return;
    }
    const ano1 = +this.ano - 3;
    const ano2 = +this.ano - 2;
    const ano3 = +this.ano - 1;
    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `DEMONSTRATIVO DA COMPATIBILIDADE DA PROGRAMAÇÃO DA LOA COM O ANEXO DE METAS DA LDO`,
        alignment: 'center',
        bold: true, fontSize: 11,
        colSpan: 4, border: [false, false, false, false]
      }, '', '', ''],
      [{
        text: 'Art. 5º, I, da LC 101/00',
        alignment: 'center',
        bold: true,
        colSpan: 4, border: [false, false, false, false]
      }, '', '', ''],
      [{
        text: 'Anexo Metas Fiscais - LDO',
        alignment: 'center',
        bold: true, fontSize: 8, colSpan: 2
      }, '', {
        text: 'LOA',
        alignment: 'center',
        bold: true, fontSize: 8, colSpan: 2
      }, ''],
      [{
        text: 'ESPECIFICAÇÃO',
        alignment: 'center',
        bold: true, fontSize: 8,
      }, {
        text: 'VALOR',
        alignment: 'center',
        bold: true, fontSize: 8,
      }, {
        text: 'ESPECIFICAÇÃO',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'VALOR',
        alignment: 'center',
        bold: true, fontSize: 8
      }]
    ];
    // monta relatório
    dados1 = [];
    dados1.push(
      {descricao1: 'RECEITAS CORRENTES', valor1: 1032773.01, descricao2: 'RECEITAS CORRENTES', valor2: 1032773.01},
      {descricao1: 'RECEITAS INTRA-ORÇAMENTÁRIA', valor1: 702822.00, descricao2: 'INTRA-ORÇAMENTÁRIA', valor2: 702822.00},
      {descricao1: 'RECEITAS DE CAPITAL', valor1: 282907.00, descricao2: 'RECEITAS DE CAPITAL', valor2: 282907.00},
      {descricao1: 'TOTAL DAS RECEITAS', valor1: 282907.00, descricao2: 'TOTAL DAS RECEITAS', valor2: 282907.00},
      {descricao1: 'DESPESAS CORRENTES', valor1: 8002922.01, descricao2: 'DESPESAS CORRENTES', valor2: 8002922.00},
      {descricao1: 'DESPESAS DE CAPITAL', valor1: 502027.00, descricao2: 'DESPESAS DE CAPITAL', valor2: 502027.01},
      {descricao1: 'RESERVA DE CONTINGÊNCIA', valor1: 112973.00, descricao2: 'RESERVA DE CONTINGÊNCIA', valor2: 112973.00},
      {descricao1: 'TOTAL DAS DESPESAS', valor1: 13837222.00, descricao2: 'TOTAL DAS DESPESAS', valor2: 13837222.00},
      {descricao1: 'RESULTADO PRIMÁRIO', valor1: 783672.00, descricao2: 'RESULTADO PRIMÁRIO', valor2: 783672.00},
      {descricao1: 'RESULTADO NOMINAL', valor1: 2341234.00, descricao2: 'RESULTADO NOMINAL', valor2: 2341234.00},
      {descricao1: 'ESTIMATIVA DA RENÚNCIA DE RECEITA', valor1: 13333.00, descricao2: 'ESTIMATIVA DA RENÚNCIA DE RECEITA', valor2: 13333.00},
      {descricao1: 'MARGEM EXPANSÃO DESPESAS OBRIGATÓRIAS DE CARÁTER CONTINUADO', valor1: 1328373.00, descricao2: 'MARGEM EXPANSÃO DESPESAS OBRIGATÓRIAS DE CARÁTER CONTINUADO', valor2: 1328373.00},
    );
    for (const item of dados1) {
      registros.push([
        {
          text: item.descricao1, fontSize: 8, border: [true, false, false, false],
        },
        {
          text: this.funcaoService.convertToBrNumber(item.valor1), alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item.descricao2, fontSize: 8, border: [true, false, false, false],
        },
        {
          text: this.funcaoService.convertToBrNumber(item.valor2), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        }
      ]);
    }
  
    registros.push([
      {
        text: '', border: [true, false, true, true], colSpan: 4
      }, '', '', ''
    ]);
    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', 100, '*', 100],
        body: registros
      }
    }];
  }



}
