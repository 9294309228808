import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, SubGrupoEstoque } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubGrupoEstoqueService extends BaseResourceService<SubGrupoEstoque> {

  constructor(
    protected injector: Injector
  ) {
    super(`sub-grupos-estoque`, injector);
  }

  public proximoCodigo(grupo: number, bec: boolean): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-codigo/${grupo}/${bec}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
