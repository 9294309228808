import { Directive, OnDestroy } from '@angular/core';
import { PpaLeiService } from 'administrativo-lib';
import { FuncaoService, GlobalService, LoginContabil, PpaLei, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { LdoService } from '../../../../planejamento/ldo/service/ldo.service';
import { SelectItem } from 'primeng/api/selectitem';

@Directive()
export class Demo1Ldo implements OnDestroy {
  public ldo1: PpaLei;
  public ldo2: PpaLei;
  public ldo3: PpaLei;
  private login: LoginContabil = new LoginContabil();
  private dados: any;
  listaOrgaos: SelectItem[];
  protected unsubscribe: Subject<void> = new Subject();
  protected funcaoService: FuncaoService;

  constructor(
    protected ano: number,
    protected ldoServico: LdoService,
    protected orgaos: number[] = [],
    protected ppaLeiService: PpaLeiService,
    dados?: any,
    listaOrgaos?: SelectItem[]) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
    if (dados) this.dados = dados;
    if (listaOrgaos) this.listaOrgaos = listaOrgaos;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async montarRelatorio() {
    if (!this.dados) {
      this.dados = await this.ldoServico.filtrar({
        tipo$in: 'D01,D10',
        'ppa.id': this.login.ppa.id,
        'ano': this.ano,
        'orgao.id$in': this.orgaos,
        orderBy: 'linha'
      }).toPromise();
    }

    this.ldo1 = await this.ppaLeiService.obter({ ano: +this.ano, ppa_id: this.login.ppa.id, tipo: 'ldo' }).toPromise();
    this.ldo2 = await this.ppaLeiService.obter({ ano: +this.ano + 1, ppa_id: this.login.ppa.id, tipo: 'ldo' }).toPromise();
    this.ldo3 = await this.ppaLeiService.obter({ ano: +this.ano + 2, ppa_id: this.login.ppa.id, tipo: 'ldo' }).toPromise();

    Relatorio.imprimirPersonalizado(
      'DEMONSTRATIVO I - LEI DE DIRETRIZES ORÇAMENTÁRIAS ANEXO DE METAS FISCAIS METAS ANUAIS'
      , this.login.usuario.nome, this.login.usuario.sobrenome,
      this.login.orgao.nome, this.login.brasao,
      this.conteudo(this.dados.content ? this.dados.content : this.dados),
      'landscape', 'DEMONSTRATIVO I - LDO',
      {
        linhas: {
          hLineWidth() {
            return 1;
          },
          vLineWidth() {
            return 1;
          },
          hLineColor() {
            return 'black';
          },
          paddingLeft() {
            return 3;
          },
          paddingRight() {
            return 3;
          }
        }
      });
  }

  private conteudo(dados: any[]): {}[] {
    if (dados.length === 0) return;

    let listaAgrupada = this.funcaoService.agrupar(dados, ['descricao1'],
      ['valor1', 'valor4', 'valor7'])

    let lista = this.soma(listaAgrupada);

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `PPA: ${this.login.ppa.ppaperiodo.ano1} - ${this.login.ppa.ppaperiodo.ano4}`, fontSize: 11,
        alignment: 'center', bold: true, colSpan: 13, border: [false, false, false, false]
      }, '', '', '', '', '', '', '', '', '', '', '', ''],
      [{
        text: `EXERCÍCIO: ${this.ano}`, fontSize: 10,
        alignment: 'center', bold: true, colSpan: 13, border: [true, true, true, false],
      }, '', '', '', '', '', '', '', '', '', '', '', ''],
      [{
        text: 'ESPECIFICAÇÃO',
        alignment: 'center',
        bold: true,
        border: [true, true, true, true], fillColor: '#f5f5f5', rowSpan: 2
      },
      {
        text: this.ano,
        bold: true, fontSize: 7,
        border: [true, true, true, true], fillColor: '#f5f5f5', colSpan: 4, alignment: 'center',
      },
        '', '', '',
      {
        text: +this.ano + +1,
        alignment: 'center',
        bold: true, fontSize: 7,
        border: [true, true, true, true], fillColor: '#f5f5f5', colSpan: 4
      },
        '', '', '',
      {
        text: +this.ano + +2,
        alignment: 'center',
        bold: true, fontSize: 7,
        border: [true, true, true, true], fillColor: '#f5f5f5', colSpan: 4
      },
        '', '', '',
      ],
      [
        {
          text: '',
          alignment: 'center',
          bold: true, fontSize: 7,
          border: [true, true, true, true], fillColor: '#f5f5f5'
        },
        {
          text: 'Valor Corrente (a)',
          alignment: 'center',
          bold: true, fontSize: 7,
          border: [true, true, true, true], fillColor: '#f5f5f5'
        },
        {
          text: 'Valor Constante',
          alignment: 'center',
          bold: true, fontSize: 7,
          border: [true, true, true, true], fillColor: '#f5f5f5'
        },
        {
          text: '% PIB (a/PIB x 100)',
          alignment: 'center',
          bold: true, fontSize: 7,
          border: [true, true, true, true], fillColor: '#f5f5f5'
        },
        {
          text: '% RCL (a/RCL x 100)',
          alignment: 'center',
          bold: true, fontSize: 7,
          border: [true, true, true, true], fillColor: '#f5f5f5'
        },
        {
          text: 'Valor Corrente (b)',
          alignment: 'center',
          bold: true, fontSize: 7,
          border: [true, true, true, true], fillColor: '#f5f5f5'
        },
        {
          text: 'Valor Constante',
          alignment: 'center',
          bold: true, fontSize: 7,
          border: [true, true, true, true], fillColor: '#f5f5f5'
        },
        {
          text: '% PIB (b/PIB x 100)',
          alignment: 'center',
          bold: true, fontSize: 7,
          border: [true, true, true, true], fillColor: '#f5f5f5'
        },
        {
          text: '% RCL (b/RCL x 100)',
          alignment: 'center',
          bold: true, fontSize: 7,
          border: [true, true, true, true], fillColor: '#f5f5f5'
        },
        {
          text: 'Valor Corrente (c)',
          alignment: 'center',
          bold: true, fontSize: 7,
          border: [true, true, true, true], fillColor: '#f5f5f5'
        },
        {
          text: 'Valor Constante',
          alignment: 'center',
          bold: true, fontSize: 7,
          border: [true, true, true, true], fillColor: '#f5f5f5'
        },
        {
          text: '% PIB (c/PIB x 100)',
          alignment: 'center',
          bold: true, fontSize: 7,
          border: [true, true, true, true], fillColor: '#f5f5f5'
        },
        {
          text: '% RCL (c/RCL x 100)',
          alignment: 'center',
          bold: true, fontSize: 7,
          border: [true, true, true, true], fillColor: '#f5f5f5'
        }

      ]
    ];

    // monta o agrupamento do relatório
    for (const item of lista) {
      registros.push([
        { text: item.registros[0].descricao1, fontSize: 7, border: [true, false, false, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor1']), alignment: 'right',
          fontSize: 7, border: [true, false, false, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor2']), alignment: 'right',
          fontSize: 7, border: [true, false, false, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor3']), alignment: 'right',
          fontSize: 7, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor10']), alignment: 'right',
          fontSize: 7, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor4']), alignment: 'right',
          fontSize: 7, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor5']), alignment: 'right',
          fontSize: 7, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor6']), alignment: 'right',
          fontSize: 7, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor11']), alignment: 'right',
          fontSize: 7, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor7']), alignment: 'right',
          fontSize: 7, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor8']), alignment: 'right',
          fontSize: 7, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor9']), alignment: 'right',
          fontSize: 7, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: item.registros[0].titulo ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor12']), alignment: 'right',
          fontSize: 7, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        }
      ]);
    }

    registros.push([
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] }
    ]);
    if (this.listaOrgaos?.length > 0) {
      registros.push([{ text: '\n\n', colSpan: 13, border: [false, false, false, false] }, '', '', '', '', '', '', '', '', '', '', '', '']);
      registros.push([{ text: this.listaOrgaos.filter(o => this.orgaos.some(org => org === o.value)).map(o => o.label).join('\n'), fontSize: 8, colSpan: 13, border: [false, false, false, false] }, '', '', '', '', '', '', '', '', '', '', '', '']);
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: false,
        headerRows: 1,
        widths: ['*', 55, 55, 40, 40, 55, 55, 40, 40, 55, 55, 40, 40],
        body: registros
      }
    }];
  }

  public soma(lista) {
    for (let item of lista) {
      if (+this.ldo1.inpc != 0) {
        let inpc = 1 + (+this.ldo1.inpc / 100);
        item.valor2 = this.funcaoService.arredondar(+item.valor1 / inpc, 2);
        inpc = this.ldo2?.inpc ? (1 + (+this.ldo2.inpc / 100)) : inpc;
        item.valor5 = this.funcaoService.arredondar(+item.valor4 / (inpc * inpc), 2);
        inpc = this.ldo3?.inpc ? (1 + (+this.ldo3.inpc / 100)) : inpc;
        item.valor8 = this.funcaoService.arredondar(+item.valor7 / (inpc * inpc * inpc), 2);
      }

      if (+this.ldo1.pib != 0) {
        let pib = this.ldo1.pib;
        item.valor3 = this.funcaoService.arredondar((+item.valor1 / +pib) * 100, 2);
        pib = this.ldo2 && this.ldo2?.pib != 0 ? this.ldo2.pib : pib;
        item.valor6 = this.funcaoService.arredondar((+item.valor4 / +pib) * 100, 2);
        pib = this.ldo3 && this.ldo3?.pib != 0 ? this.ldo3.pib : pib;
        item.valor9 = this.funcaoService.arredondar((+item.valor7 / +pib) * 100, 2);
      }

      if (+this.ldo1.rcl != 0) {
        let rcl = this.ldo1.rcl;
        item.valor10 = this.funcaoService.arredondar((+item.valor1 / +rcl) * 100, 2);
        rcl = this.ldo2 && this.ldo2?.rcl != 0 ? this.ldo2.rcl : rcl;
        item.valor11 = this.funcaoService.arredondar((+item.valor4 / +rcl) * 100, 2);
        rcl = this.ldo3 && this.ldo3?.rcl != 0 ? this.ldo3.rcl : rcl;
        item.valor12 = this.funcaoService.arredondar((+item.valor7 / +rcl) * 100, 2);
      }
    }
    return lista;
  }
}