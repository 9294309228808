import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MovimentoItemEstoqueService } from 'administrativo-lib';
import { CompraService } from 'compra-lib';
import { EddyAutoComplete, Exercicio, ExercicioService, FuncaoService, GlobalService, LoginContabil, Setor, SetorService } from 'eddydata-lib';
import * as toastr from 'toastr';
import { RelatorioEntradasCompraRpt } from './relatorio-entradas-compra-rpt';

@Component({
  selector: 'app-relatorio-entradas-compra-rpt',
  templateUrl: './relatorio-entradas-compra-rpt.component.html'
})
export class RelatorioEntradasCompraRptComponent implements OnInit {


  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public login: LoginContabil;

  public listExercicio: Exercicio[];
  public exercicio: Exercicio;
  public setor: Setor;

  public numero: number;

  public setorAutoComplete: EddyAutoComplete<Setor>

  // ========================================================================
  // ------------------------------ CONSTRUTOR ------------------------------
  // ========================================================================
  constructor(
    private funcaoService: FuncaoService,
    private compraService: CompraService,
    private movimentoItemEstoqueService: MovimentoItemEstoqueService,
    private exercicioService: ExercicioService,
    private setorService: SetorService) {
    this.login = GlobalService.obterSessaoLogin();
  }


  ngOnInit(): void {
    this.carregarAutoCompletes();
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id)
      .subscribe(dados => {
        this.listExercicio = dados.content;
        this.exercicio = this.login.exercicio;
      });
  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  public imprimir() {
    if (!this.validar())
      return;
    let setorId = this.setor ? this.setor.id : null
    this.compraService.extendido(1, 1, {
      'exercicio.id': this.exercicio.id, 'orgao.id': this.login.orgao.id,
      'conferencia_estoque': true, relations: 'favorecido', numero: this.numero
    }).subscribe((pagina) => {
      if (pagina?.content && pagina.content.length === 0) {
        toastr.warning(`Ordem de fornecimento não encontrada.`);
        return;
      }
      let compra = pagina.content[0];
      const param = {
        'movimento.compra.id': compra.id,
        'movimento.excluido': false,
        'movimento.orgao.id': this.login.orgao.id,
        'movimento.entrada': true,
        relations: ['produto_unidade.produto', 'produto_unidade.unidade', 'movimento.setorAlmoxarifado.estoque'].join(',')
      }
      if (setorId) param['movimento.setorAlmoxarifado.setor.id'] = setorId
      this.movimentoItemEstoqueService.filtrar(1, -1, param).subscribe(async (data) => {
        if (!data || !data.content || data.content.length === 0) {
          toastr.warning(`Não foi identificado nenhuma entrada para ordem de fornecimento selecionada.`);
          return;
        }
        new RelatorioEntradasCompraRpt(this.login).imprimir({
          numero: compra.numero,
          valor_total: compra['total_compra'],
          forncedor: `${compra.favorecido.id} - ${compra.favorecido.nome}`
        }, data.content);
      }, (error) => toastr.error(`Foi identificado um erro na impressão do relatorio de entradas.`));
    });
  }

  private carregarAutoCompletes() {
    this.setorAutoComplete = new EddyAutoComplete(null, this.setorService,
      'id', ['codigo', 'nome'], { orgao_id: this.login.orgao.id, relations: 'orgao', orderBy: 'nome' }, { text: ['nome', 'codigo'] }
    );
  }

  private validar(): boolean {
    if (!this.exercicio || !this.exercicio.id) {
      toastr.warning('Informe exercicio para imprimir relação');
      return;
    }
    if (!this.numero) {
      toastr.warning(`Informe OF. para imprimir relação.`)
      return false;
    }
    return true;
  }


  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

}