import { Component, Input } from "@angular/core";
import { MemorialService, ProponenteService, PropostaService } from "administrativo-lib";
import { BaseResourceRptComponent, Coluna, CpfPipe, GlobalService, Licitacao, LicitacaoPipe, Memorial, Proponente, Proposta, SituacaoProposta } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-classificacao-julgamento-rpt',
  templateUrl: './classificacao-julgamento-rpt.component.html'
})
export class ClassificacaoJulgamentoRptComponent extends BaseResourceRptComponent {

  @Input() public licitacao: Licitacao;

  public listaFormato: ['pdf', 'xlsx', 'csv', 'docx'];
  public agrupamento: 'MEMORIAL' | 'PROPONENTE';
  public listAgrupamento: { id: 'MEMORIAL' | 'PROPONENTE', nome: string }[];
  private situacoesProposta: { id: SituacaoProposta, nome: string, style: string, ordem: number }[];

  constructor(
    private globalService: GlobalService,
    private propostaService: PropostaService,
    private proponenteService: ProponenteService,
    private memorialService: MemorialService,
  ) {
    super();
  }

  protected afterInit(): void {
    this.listaFormato = ['pdf', 'xlsx', 'csv', 'docx'];
    this.situacoesProposta = this.globalService.obterSituacoesProposta();
  }

  protected tituloRelatorio(): string {
    return `${this.login.orgao.nome}`;
  }

  protected subTituloRelatorio(): string {
    return `PROCESSO ADMINISTRATIVO ${new LicitacaoPipe().transform(this.licitacao.processo)}\n JULGAMENTO ${this.licitacao.modalidade.nome} - Nº${new LicitacaoPipe().transform(this.licitacao.numero)}`;
  }

  protected obterColunasRelatorio(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Item:', coluna: 'item', bold: true, agrupar: true });
    retorno.push({ titulo: 'Item', coluna: 'favorecido' });
    retorno.push({ titulo: 'Valor Unit.', coluna: 'valor', decimais: 4, alignment: 'right' });
    retorno.push({ titulo: 'Valor Total', coluna: 'total', decimais: 4, alignment: 'right' });
    retorno.push({ titulo: 'Marca', coluna: 'marca' });
    retorno.push({ titulo: 'Porcentagem %', coluna: 'porcentagem', alignment: 'right' });
    retorno.push({ titulo: 'Estado', coluna: 'situacao', bold: true, alignment: 'center' });

    return retorno;
  }

  protected larguraColunas(): (string | number)[] {
    return ['*', 100, 'auto', 'auto', 70, 70];
  }

  protected totalizarColunas(): (string | {})[] {
    return undefined;
  }

  protected carregarLista(): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      const lista: {
        item: string,
        favorecido: string,
        marca: string,
        porcentagem: string,
        valor: number,
        total: number,
        situacao: string,
      }[] = [];

      const memorial: Memorial[] = (await this.memorialService.obterPorLicitacao(this.licitacao.id, true).toPromise()).content;
      const proponentes: Proponente[] = (await this.proponenteService.obterPorLicitacaoClassificacao(this.licitacao.id).toPromise()).content;

      const reg: Proposta[] = [];
      for (const item of memorial) {
        for (const favorecido of proponentes) {
          if (!favorecido.propostas.find(prop => { prop.memorial.id === item.id })) {
            const prop = new Proposta();
            prop.memorial = Object.assign({}, item)
            prop.proponente = favorecido;
            favorecido.propostas.find(p => {
              if (p.memorial.id === item.id) return prop.marca = p.marca
            });
            favorecido.propostas.find(p => {
              if (p.memorial.id === item.id) prop.situacao = p.situacao;
            });
            // if (!prop.situacao) prop.situacao = '';
            favorecido.propostas.find(p => {
              if (p.memorial.id === item.id) prop.valor_unitario = p.valor_unitario;
            });
            favorecido.propostas.find(p => {
              if (p.memorial.id === item.id) prop.quantidade_final = p.quantidade_final;
            });
            if (!prop.valor_unitario) prop.valor_unitario = 0

            delete prop.memorial.propostas;

            reg.push(prop);
          }
        }
      }

      if (this.licitacao.criterio_julgamento === 'MENOR_PRECO' || this.licitacao.criterio_julgamento === 'MENOR_DESCONTO')
        reg.sort((a, b) => {
          if (a.valor_unitario === 0) return 1;
          if (b.valor_unitario === 0) return -1;
          if (a.memorial.ordem !== b.memorial.ordem) {
            return a.memorial.ordem - b.memorial.ordem;
          }
          return a.valor_unitario - b.valor_unitario;
        });
      else if (this.licitacao.criterio_julgamento === 'MAIOR_PRECO' || this.licitacao.criterio_julgamento === 'MAIOR_DESCONTO') {
        reg.sort((a, b) => {
          if (a.valor_unitario === 0) return 1;
          if (b.valor_unitario === 0) return -1;
          if (a.memorial.ordem !== b.memorial.ordem) {
            return a.memorial.ordem - b.memorial.ordem;
          }
          return b.valor_unitario - a.valor_unitario;
        });
      }

      {
        reg.sort((a, b) => { return a.memorial.ordem - b.memorial.ordem })
      }
      let penultimaProposta: Proposta = new Proposta();
      reg.forEach(proposta => {
        proposta.valor_final = proposta.valor_unitario;
        if (proposta.memorial.id === penultimaProposta?.memorial?.id) {
          if ((this.licitacao.criterio_julgamento === 'MENOR_PRECO' || this.licitacao.criterio_julgamento === 'MENOR_DESCONTO') && proposta.valor_final !== 0) {
            proposta.porcentagem = +Number(((proposta.valor_final / +penultimaProposta.valor_final) - 1) * 100).toFixed(2);
          }
          else if ((this.licitacao.criterio_julgamento === 'MAIOR_PRECO' || this.licitacao.criterio_julgamento === 'MAIOR_DESCONTO') && proposta.valor_final !== 0)
            proposta.porcentagem = +Number(((+penultimaProposta.valor_final / proposta.valor_final) - 1) * 100).toFixed(2);
          else
            proposta.porcentagem = 0;

        } else {
          penultimaProposta = proposta
        }
      });

      for (const proposta of reg) {
        if (proposta.proponente.situacao === 'DESCLASSIFICADO') {
          proposta.situacao = 'DESCLASSIFICADO';
          proposta.motivo = proposta.proponente.motivo;
        } else if (proposta.proponente.situacao === 'INABILITADO') {
          proposta.situacao = 'INABILITADO';
          proposta.motivo = proposta.proponente.motivo;
        } else {
          proposta.situacao = 'CLASSIFICADO';
        }
      }

      const classificados = reg.filter(p => p.situacao === 'CLASSIFICADO');
      if (classificados.length > 3) {
        classificados.splice(0, 3);
        for (const proposta of classificados) {
          if (+proposta.porcentagem > 10) {
            proposta.situacao = 'NAO_CLASSIFICADO';
          }
        }
      }

      reg.forEach(p => {
        lista.push({
          item: `${p.memorial.ordem} ${p.memorial.descricao} ${p.memorial.unidade} ${this.informacaoCota(p.memorial)}`,
          favorecido: `${new CpfPipe().transform(p.proponente.favorecido.cpf_cnpj)} - ${p.proponente.favorecido.nome}`,
          marca: p.marca,
          porcentagem: `${this.funcaoService.convertToBrNumber(+p.porcentagem)}%`,
          valor: p.situacao === 'VENCEDOR' ? p.valor_final : p.valor_unitario,
          total: p.situacao === 'VENCEDOR' ? p.quantidade_final * p.valor_final : p.memorial.quantidade * p.valor_unitario,
          situacao: this.converterSituacaoProposta(p, 0),
        })
      });

      resolve(lista);
    })
  }

  private converterSituacaoProposta(item: Proposta, posicao: number): string {
    if (!item.situacao) return 'Sem proposta';

    let situacao = this.obterNomeProposta(item)

    console.log(situacao)

    switch (situacao) {
      case 'VENCEDOR':
      case 'Vencedor':
      case 'Classificado':
        return 'Selecionado'
      case 'Desistiu':
      case 'Não exerceu Dir. Preferência':
      case 'Inexequível':
      case 'Em Negociação':
      case 'Declinou':
      case 'Não Julgado':
      case 'Desclassificado':
      case 'Não Classificado':
        return `Não Selecionado`
      default:
        return 'Sem proposta'//this.situacoesProposta.find(p2 => p2.id === situacao).nome
    }
  }

  private obterPosicaoPropostaItem(lista: Proposta[], proposta: Proposta): number {
    if (!lista || !proposta) return

    lista = lista
      .filter(p => p.memorial.id === proposta.memorial.id)
      .sort((a, b) => { return a.proponente.favorecido.nome.localeCompare(b.proponente.favorecido.nome) })
      .sort((a, b) => { return this.situacoesProposta.find(s => s.id === a.situacao).ordem - this.situacoesProposta.find(s => s.id === b.situacao).ordem })
      .sort((a, b) => { return a.memorial.ordem - b.memorial.ordem })

    return lista.indexOf(proposta)
  }

  public obterNomeProposta(item: Proposta): string {
    try {
      return this.situacoesProposta.find(p => p.id === item.situacao).nome;
    } catch (err) {
      return item.situacao;
    }
  }

  private informacaoCota(item: Memorial): string {
    if (!item || !item.cota || item.cota === 'NAO_DEFINIDO') return ''
    if (item.cota === 'PRINCIPAL') return '(Cota Principal)'
    if (item.cota === 'RESERVADO') return '(Cota Reservada)'
  }
}