import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AdiantamentoService } from "administrativo-lib";
import { Adiantamento, FuncaoService, Login, OrgaoAssinatura, OrgaoAssinaturaService, Relatorio } from "eddydata-lib";
import { Subject } from "rxjs";
import * as toastr from 'toastr'

@Component({
  selector: 'lib-adiantamento-controle-interno-dlg',
  templateUrl: './adiantamento-controle-interno-dlg.component.html'
})
export class AdiantamentoControleInternoDlgComponent implements OnInit, OnDestroy {

  protected unsubscribe: Subject<void> = new Subject();
  public dataComprovacao: Date = new Date()
  public relatorio: String = ''
  public assinatura: OrgaoAssinatura

  @Input() login: Login
  @Input() adiantamento: Adiantamento

  constructor(
    public adiantamentoService: AdiantamentoService,
    public orgaoAssinaturaService: OrgaoAssinaturaService,
    public funcacaoService: FuncaoService
  ) { }

  public async ngOnInit(): Promise<void> {
    this.assinatura = await this.orgaoAssinaturaService.obter({ orgao_id: this.login.orgao.id, 'data_limite$ge': this.funcacaoService.converteDataSQL(this.dataComprovacao), 'orderBy': 'data_limite$DESC' }).toPromise()
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async imprimir() {
    if (!this.assinatura) {
      toastr.warning('Cadastre Assinatura de Controle Interno para prosseguir!')
    }

    this.adiantamento.descricao_parecer = (await this.adiantamentoService.obter({ id: this.adiantamento.id }).toPromise()).descricao_parecer

    Relatorio.imprimirPersonalizado('', this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, this.montarConteudo(this.adiantamento), 'portrait', this.tituloRelatorio(), this.layoutRelatorio(), false, false, 'pdf')
  }

  public tituloRelatorio() {
    return 'CONTROLE INTERNO'
  }

  public montarConteudo(adiantamento: Adiantamento) {
    const cabecalho = []
    const tabela = []
    const conteudo = []

    cabecalho.push([
      { text: `${this.tituloRelatorio()}`, fontSize: 16, bold: true, border: [false, false, false, false], alignment: 'center' }
    ])
    cabecalho.push([
      { text: 'PARECER SOBRE COMPROVAÇÃO DE ADIANTAMENTO E QUITAÇÃO', fontSize: 14, bold: true, border: [false, false, false, false], alignment: 'center' }
    ])

    cabecalho.push([{ text: '', margin: [0, 5, 0, 5], border: [false, false, false, false] }])

    tabela.push([
      { text: 'EMPENHO', bold: true, fontSize: 12, alignment: 'center', margin: [5, 0, 5, 0] },
      { text: 'DATA', bold: true, fontSize: 12, alignment: 'center', margin: [10, 0, 10, 0] },
      { text: 'VALOR', bold: true, fontSize: 12, alignment: 'center', margin: [5, 0, 5, 0] },
      { text: 'SERVIDOR RESPONSAVEL PELO ADIANTAMENTO', bold: true, fontSize: 12, alignment: 'center' }
    ])

    tabela.push([
      { text: `${adiantamento.empenho.numero}`, fontSize: 12, alignment: 'center' },
      { text: `${this.funcacaoService.converteDataBR(adiantamento.empenho.data_empenho)}`, fontSize: 12, alignment: 'center' },
      { text: `${this.funcacaoService.convertToBrNumber(adiantamento.empenho.valor_empenho)}`, fontSize: 12, alignment: 'right' },
      { text: `${adiantamento.beneficiario}`, fontSize: 12, margin: [5, 0, 0, 0] }
    ])

    conteudo.push([{ text: '', margin: [0, 5, 0, 5], border: [false, false, false, false] }])
    conteudo.push([{ text: `Comprovação apresentada em: ${this.funcacaoService.converteDataBR(this.dataComprovacao)}`, fontSize: 12, border: [false, false, false, false], margin: [0, 5, 0, 5] }])
    conteudo.push([{ text: 'Finalidade do Adiantamento:', fontSize: 12, bold: true, border: [false, false, false, false], margin: [0, 5, 0, 0] }])
    conteudo.push([{ text: `${adiantamento.motivo}`, fontSize: 12, border: [false, false, false, false], margin: [0, 0, 0, 5] }])
    conteudo.push([{ text: 'Relatório:', fontSize: 12, bold: true, border: [false, false, false, false], margin: [0, 5, 0, 0] }])
    conteudo.push([{ text: `${this.relatorio ? this.relatorio : ''}`, fontSize: 12, border: [false, false, false, false], margin: [0, 0, 0, 5] }])
    conteudo.push([{ text: 'Conclusão:', fontSize: 12, bold: true, border: [false, false, false, false], margin: [0, 5, 0, 0] }])
    conteudo.push([{ text: `${adiantamento.descricao_parecer ? adiantamento.descricao_parecer : ''}`, fontSize: 12, border: [false, false, false, false], margin: [0, 0, 0, 5] }])
    conteudo.push([{ text: '', fontSize: 12, margin: [0, 10, 0, 10], border: [false, false, false, false] }])

    conteudo.push([{ text: `${this.login.cidade.nome} - ${this.login.cidade.estado.uf}, ${this.funcacaoService.formatarDataExtenso(this.dataComprovacao)}`, fontSize: 12, alignment: 'center', border: [false, false, false, false] }])
    conteudo.push([{ text: '', margin: [0, 10, 0, 10], border: [false, false, false, false] }])
    conteudo.push([{ text: '_________________________________________________', fontSize: 12, alignment: 'center', border: [false, false, false, false] }])
    conteudo.push([{ text: `${this.assinatura.almoxarife}`, fontSize: 12, alignment: 'center', border: [false, false, false, false] }])
    conteudo.push([{ text: `${this.assinatura.cargo_almoxarife}`, fontSize: 12, alignment: 'center', border: [false, false, false, false] }])

    const montagem = [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*'],
          body: cabecalho
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['auto', 'auto', 'auto', '*'],
          body: tabela
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*'],
          body: conteudo
        }
      }
    ]


    return montagem
  }

  public layoutRelatorio() {
    return {
      linhas: {
        hLineWidth() {
          return 1;
        },
        vLineWidth() {
          return 1;
        },
        hLineColor() {
          return 'black';
        },
        paddingLeft() {
          return 3;
        },
        paddingRight() {
          return 3;
        }
      }
    }
  }
}