import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AnexoLoaService } from '../service/anexo-loa.service';
import { FuncaoService, LoginContabil, Exercicio, Relatorio, GlobalService } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class Anexo7 implements OnDestroy {
  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLoaService) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // tslint:disable: max-line-length
  public montarRelatorio(exercicio: Exercicio, orgaos: number[]) {
    this.anexoServico.obterAnexo7(exercicio.id, orgaos, this.login.ppa.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {

        Relatorio.imprimirPersonalizado(
          'DEMONSTRATIVO CONSOLIDADO DA DESPESA POR FUNÇÃO,SUBFUNÇÃO,PROGRAMA,PROJETOS,ATIVIDADES E OPERAÇÕES ESPECIAIS'
          , this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          this.conteudo(dados, exercicio),
          'portrait', 'ANEXO 7',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private conteudo(dados: any[], exercicio: Exercicio): {}[] {

    // monta o cabecalho
    const registros: {}[] = [[{
      text: `EXERCÍCIO ${exercicio.ano}`,
      alignment: 'center', bold: true, colSpan: 6, border: [true, true, true, false]
    }, '', '', '', '', ''],
    [{
      text: 'Lei nº 4.320/64, Art. 2º, §1º, II - Anexo 7',
      alignment: 'center',
      bold: true,
      colSpan: 6, border: [true, false, true, false]
    }, '', '', '', '', ''],
    [{
      text: 'CÓDIGO',
      alignment: 'center',
      bold: true, fontSize: 8
    }, {
      text: 'ESPECIFICAÇÃO',
      alignment: 'center',
      bold: true, fontSize: 8
    }, {
      text: 'PROJETOS',
      alignment: 'center',
      bold: true, fontSize: 8
    }, {
      text: 'ATIVIDADES',
      alignment: 'center',
      bold: true, fontSize: 8
    }, {
      text: 'OPERAÇÕES ESPECIAIS',
      alignment: 'center',
      bold: true, fontSize: 8
    }, {
      text: 'TOTAL',
      alignment: 'center',
      bold: true, fontSize: 8
    }]
    ];

    // monta o agrupamento do relatório
    const gruposFuncao = this.funcaoService.agrupar(dados, 'nome_funcao',
      ['projeto', 'atividade', 'operacao']);
    const totalizador = new Array<any>();
    let total1 = 0;
    let total2 = 0;
    let total0 = 0;
    for (const funcao of gruposFuncao) {
      registros.push(
        [{
          text: this.funcaoService.mascarar('##', funcao.grupo as string), fontSize: 8,
          bold: true, border: [true, true, true, true]
        }, {
          text: (funcao.grupo as string).substr(2), fontSize: 8,
          bold: true, border: [true, true, true, true]
        },
        {
          text: funcao.totalizadores[`projeto`] > 0 ? this.funcaoService.convertToBrNumber(funcao.totalizadores[`projeto`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, true, false, true]
        },
        {
          text: funcao.totalizadores[`atividade`] > 0 ? this.funcaoService.convertToBrNumber(funcao.totalizadores[`atividade`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, true, false, true]
        },
        {
          text: funcao.totalizadores[`operacao`] > 0 ? this.funcaoService.convertToBrNumber(funcao.totalizadores[`operacao`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, true, false, true]
        },
        {
          text: this.funcaoService.convertToBrNumber(+funcao.totalizadores[`projeto`] + +funcao.totalizadores[`atividade`] + +funcao.totalizadores[`operacao`]), alignment: 'right',
          bold: true, fontSize: 8, border: [true, true, true, true]
        }
        ]);
      total1 += +funcao.totalizadores[`projeto`];
      total2 += +funcao.totalizadores[`atividade`];
      total0 += +funcao.totalizadores[`operacao`];
      const gruposSubFuncao = this.funcaoService.agrupar(funcao.registros, 'nome_subfuncao',
        ['projeto', 'atividade', 'operacao']);

      for (const subfuncao of gruposSubFuncao) {
        registros.push(
          [{
            text: this.funcaoService.mascarar('##.###', subfuncao.grupo as string), fontSize: 8,
            bold: true, border: [true, false, true, false]
          }, {
            text: (subfuncao.grupo as string).substr(5), fontSize: 8,
            bold: true, border: [true, false, true, false]
          },
          {
            text: subfuncao.totalizadores[`projeto`] > 0 ? this.funcaoService.convertToBrNumber(subfuncao.totalizadores[`projeto`]) : '', alignment: 'right',
            bold: true, fontSize: 8, border: [true, false, false, false]
          },
          {
            text: subfuncao.totalizadores[`atividade`] > 0 ? this.funcaoService.convertToBrNumber(subfuncao.totalizadores[`atividade`]) : '', alignment: 'right',
            bold: true, fontSize: 8, border: [true, false, false, false]
          },
          {
            text: subfuncao.totalizadores[`operacao`] > 0 ? this.funcaoService.convertToBrNumber(subfuncao.totalizadores[`operacao`]) : '', alignment: 'right',
            bold: true, fontSize: 8, border: [true, false, false, false]
          },
          {
            text: this.funcaoService.convertToBrNumber(+subfuncao.totalizadores[`projeto`] + +subfuncao.totalizadores[`atividade`] + +subfuncao.totalizadores[`operacao`]), alignment: 'right',
            bold: true, fontSize: 8, border: [true, false, true, false]
          }
          ]);


        const gruposPrograma = this.funcaoService.agrupar(subfuncao.registros, 'nome_programa',
          ['projeto', 'atividade', 'operacao']);

        for (const programa of gruposPrograma) {
          registros.push(
            [{
              text: this.funcaoService.mascarar('##.###.####', programa.grupo as string), fontSize: 8,
              bold: true, border: [true, false, true, false]
            }, {
              text: (programa.grupo as string).substr(9), fontSize: 8,
              bold: true, border: [true, false, true, false]
            },
            {
              text: programa.totalizadores[`projeto`] > 0 ? this.funcaoService.convertToBrNumber(programa.totalizadores[`projeto`]) : '', alignment: 'right',
              bold: true, fontSize: 8, border: [true, false, false, false]
            },
            {
              text: programa.totalizadores[`atividade`] > 0 ? this.funcaoService.convertToBrNumber(programa.totalizadores[`atividade`]) : '', alignment: 'right',
              bold: true, fontSize: 8, border: [true, false, false, false]
            },
            {
              text: programa.totalizadores[`operacao`] > 0 ? this.funcaoService.convertToBrNumber(programa.totalizadores[`operacao`]) : '', alignment: 'right',
              bold: true, fontSize: 8, border: [true, false, false, false]
            },
            {
              text: this.funcaoService.convertToBrNumber(+programa.totalizadores[`projeto`] + +programa.totalizadores[`atividade`] + +programa.totalizadores[`operacao`]),
              alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
            }
            ]
          );

          // lista os registros do relatorio
          for (const registro of programa.registros) {
            registros.push([
              {
                text: `${this.funcaoService.mascarar('##.###.####.####', registro.codigo_acao)}`, fontSize: 8, border: [true, false, false, false],
              },
              {
                text: `${registro.nome_acao}`, fontSize: 8, border: [true, false, false, false]
              },
              {
                text: registro.projeto > 0 ? this.funcaoService.convertToBrNumber(registro.projeto) : '', alignment: 'right',
                fontSize: 8, border: [true, false, false, false]
              },
              {
                text: registro.atividade > 0 ? this.funcaoService.convertToBrNumber(registro.atividade) : '', alignment: 'right',
                fontSize: 8, border: [true, false, false, false]
              },
              {
                text: registro.operacao > 0 ? this.funcaoService.convertToBrNumber(registro.operacao) : '', alignment: 'right',
                fontSize: 8, border: [true, false, false, false]
              },
              {
                text: this.funcaoService.convertToBrNumber(+registro.projeto + +registro.atividade + +registro.operacao),
                alignment: 'right', fontSize: 8, border: [true, false, true, false]
              }
            ]);
          }
        }
      }
    }

    registros.push([
      {
        text: 'TOTAL', colSpan: 2, fontSize: 8, border: [true, true, true, true], margin: [0, 0, 0, 5]
      },
      {
        text: '', fontSize: 8, border: [true, true, true, true]
      },
      {
        text: total1 > 0 ? this.funcaoService.convertToBrNumber(total1) : '', alignment: 'right',
        fontSize: 8, border: [true, true, true, true]
      },
      {
        text: total2 > 0 ? this.funcaoService.convertToBrNumber(total2) : '', alignment: 'right',
        fontSize: 8, border: [true, true, true, true]
      },
      {
        text: total0 > 0 ? this.funcaoService.convertToBrNumber(total0) : '', alignment: 'right',
        fontSize: 8, border: [true, true, true, true]
      },
      {
        text: this.funcaoService.convertToBrNumber(+total1 + +total2 + +total0),
        alignment: 'right', fontSize: 8, border: [true, true, true, true]
      }
    ]);

    return [{
      layout: 'linhas',
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        dontBreakRows: true,
        headerRows: 2,
        widths: ['auto', '*', 60, 60, 60, 60],
        body: registros
      }
    }];
  }


}
