import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FuncaoService, Licitacao, Login } from 'eddydata-lib';
import * as toastr from 'toastr';
import { ContratoService, EmpenhoService, LicitacaoService } from 'administrativo-lib';
import { RcmsService } from '../../rcms/service/rcms.service';
import { CompraService } from '../../compra/service/compra.service';
import { ConfirmationService } from 'primeng/api';
import { CompraItemService } from '../../compra/service/compra-item.service';
import { RcmsItemService } from '../../rcms/service/rcms-item.service';


declare var $: any;

@Component({
  selector: 'app-exclusao-licitacao-dlg',
  templateUrl: './exclusao-licitacao-dlg.component.html'
})
export class ExclusaoLicitacaoDlgComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  protected unsubscribe: Subject<void> = new Subject();

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Input() login: Login;
  @Input() licitacao: Licitacao;

  @Input() usarCallback: boolean = false;
  @Output() callback: EventEmitter<Licitacao> = new EventEmitter();

  licitacaoRemovida: Licitacao;

  constructor(
    private router: Router,
    private licitacaoService: LicitacaoService,
    private contratoService: ContratoService,
    private requisicaoService: RcmsService,
    private requisicaoItemService: RcmsItemService,
    private compraService: CompraService,
    private compraItemService: CompraItemService,
    private funcaoService: FuncaoService,
    private confirmationService: ConfirmationService,
    private empenhoService: EmpenhoService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.licitacao)
      this.licitacaoRemovida = Object.assign({}, this.licitacao);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
  }

  async remover() {
    this.licitacaoRemovida.situacao = 'REMOVIDO';
    this.licitacaoRemovida.data_remocao = new Date();
    this.licitacaoRemovida.usuario_remocao = this.login.usuario;

    if (!this.licitacaoRemovida.justificativa_remocao) {
      toastr.warning('Preencha o campo de justificativa');
      throw new Error('Preencha o campo de justificativa');
    }

    const contratos = await this.contratoService.filtrar(1, -1, { 'licitacao_id': this.licitacao.id, 'orgao_id': this.licitacao.orgao.id, inativo: false }).toPromise();

    if (contratos.content.length) {

      for (const contrato of contratos.content) {

        const res = await this.empenhoService.extendido(0, 0, {
          contrato_id: contrato.id, orgao_id: this.login.orgao.id, exercicio_id: this.login.exercicio.id,
          'especie': 'EOA', relations: 'compra,compra.rcms,compra.itens'
        }).toPromise();

        if (res.content.length) {
          for (const empenho of res.content) {
            if (+empenho.total_empenhado != 0) {
              toastr.warning(`O Empenho vinculado ao contrato Nº ${this.funcaoService.mascarar('####/####', contrato.numero)} não foi totalmente anulado!`);
              throw new Error(`O Empenho vinculado ao contrato Nº ${this.funcaoService.mascarar('####/####', contrato.numero)} não foi totalmente anulado!`);
            }
            const total = empenho.compra.itens.reduce((acc, cur) => {
              return acc + +cur.quantidade;
            }, 0);

            if (total != 0) {
              toastr.warning(`Os itens da compra vinculado ao contrato Nº ${this.funcaoService.mascarar('####/####', contrato.numero)} não foram totalmente anulado!`);
              throw new Error(`Os itens da compra vinculado ao contrato Nº ${this.funcaoService.mascarar('####/####', contrato.numero)} não foram totalmente anulado!`);
            } else {
              this.onHide();
              this.confirmationService.confirm({
                message: `Deseja realmente inativar os contratos vinculados ao processo ${this.licitacao?.processo} para sua remoção?`,
                header: 'Confirmação!',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Sim',
                rejectLabel: 'Não',
                key: 'remocaoLicitacao',
                accept: () => {
                  this.licitacaoService.removerLicitacao(this.licitacaoRemovida).pipe(takeUntil(this.unsubscribe))
                    .subscribe((resposta) => {
                      toastr.success('Remoção realizada com sucesso!');
                      this.onHide();
                      location.reload();
                    },
                      error => {
                        if (error.error && error.error.payload) {
                          toastr.error(error.error.payload);
                        } else {
                          toastr.error('Não foi possível realizar a remoção!');
                        }
                      });
                }
              });
            }


          }
        } else {

          const requisicoes = await this.requisicaoService.filtrar(1, -1, { 'contrato_id': contrato?.id, 'orgao_id': this.login?.orgao?.id, 'excluido': false, ignoreCondObrig: true }).toPromise();

          const compras = await this.compraService.filtrar(1, -1, { 'contrato_id': contrato.id, 'orgao_id': this.login?.orgao?.id, ignoreCondObrig: true, 'excluido': false }).toPromise();

          if (requisicoes.content.length > 0) {
            toastr.warning(`Existem requisições vinculadas ao contrato Nº ${this.funcaoService.mascarar('####/####', contrato.numero)}!`);
            throw new Error(`Existem requisições vinculadas ao contrato Nº ${this.funcaoService.mascarar('####/####', contrato.numero)}!`);
          } else if (compras.content.length > 0) {
            toastr.warning(`Existem compras vinculadas ao contrato Nº ${this.funcaoService.mascarar('####/####', contrato.numero)}!`);
            throw new Error(`Existem compras vinculadas ao contrato Nº ${this.funcaoService.mascarar('####/####', contrato.numero)}!`);
          } else {
            this.onHide();
            this.confirmationService.confirm({
              message: `Deseja realmente inativar os contratos vinculados ao processo ${this.licitacao?.processo} para sua remoção?`,
              header: 'Confirmação!',
              icon: 'pi pi-exclamation-triangle',
              acceptLabel: 'Sim',
              rejectLabel: 'Não',
              key: 'remocaoLicitacao',
              accept: () => {
                this.licitacaoService.removerLicitacao(this.licitacaoRemovida).pipe(takeUntil(this.unsubscribe))
                  .subscribe((resposta) => {
                    toastr.success('Remoção realizada com sucesso!');
                    this.onHide();
                    location.reload();
                  },
                    error => {
                      if (error.error && error.error.payload) {
                        toastr.error(error.error.payload);
                      } else {
                        toastr.error('Não foi possível realizar a remoção!');
                      }
                    });
              }
            });
          }
        }

      }
    } else {
      this.licitacaoService.removerLicitacao(this.licitacaoRemovida).pipe(takeUntil(this.unsubscribe))
        .subscribe((resposta) => {
          toastr.success('Remoção realizada com sucesso!');
          this.onHide();
          location.reload();
        }, error => {
          if (error.error && error.error.payload) {
            toastr.error(error.error.payload);
          } else {
            toastr.error('Não foi possível realizar a remoção!');
          }
        });
    }
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

}
