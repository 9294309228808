import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { PessoaModule, SharedModule, UsuarioModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { ToastModule } from 'primeng/toast';
import { BemMovelHistoricoDlgComponent } from './bem-movel-historico-dlg.component';

@NgModule({
  declarations: [
    BemMovelHistoricoDlgComponent,
  ],
  exports: [
    BemMovelHistoricoDlgComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    PessoaModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    UsuarioModule,
    InputMaskModule,
    FieldsetModule,
    FileUploadModule,
    ConfirmDialogModule,
  ],
  providers: [MessageService, ConfirmationService]
})
export class BemMovelHistoricoDlgModule { }
