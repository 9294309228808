import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../assinaturas/assinaturas';
import { AnexoLRFService } from '../../service/anexo-lrf.service';

@Directive()
export class OperacaoCreditoLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();
  public valorOperacaoCredito = 0;

  constructor(
    protected anexoServico: AnexoLRFService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected assinaturaControleInterno: boolean) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async obterOperacaoCredito(orgaos: number[]) {
    const result = await this.anexoServico.obterOperacaoCredito(this.mes, this.exercicio.id, orgaos).toPromise();
    return this.conteudo(result);
  }

  // tslint:disable: max-line-length
  public montarRelatorio(orgaos: number[], formato: FormatoExportacao = 'pdf') {
    this.anexoServico.obterOperacaoCredito(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(
            'DEMONSTRATIVO DAS RECEITAS DE OPERAÇÕES DE CRÉDITO E DESPESAS DE CAPITAL'
            , this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.conteudo(dados),
            'landscape', 'DEMONSTRATIVO DAS RECEITAS DE OPERAÇÕES DE CRÉDITO E DESPESAS DE CAPITAL',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            });
        } else {
          this.funcaoService.exportar(formato, this.normalizar(dados), 'DEMONSTRATIVO DAS RECEITAS DE OPERAÇÕES DE CRÉDITO E DESPESAS DE CAPITAL', this.colunas());
        }
      });
  }

  private async conteudo(dados: any[]): Promise<{}[]> {

    // monta o cabecalho
    const registros: {}[] = [
      [
        {
          text: `REFERÊNCIA: ` +
            this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano,
          alignment: 'center',
          bold: true,
          colSpan: 11, border: [false, false, false, false]
        }, '', '', '', '', '', '', '', '', '', ''
      ],
      [
        {
          text: ' LRF, art.53, inciso do § 1º',
          alignment: 'left',
          bold: true,
          colSpan: 11, border: [false, false, false, false]
        }, '', '', '', '', '', '', '', '', '', ''],
      [
        {
          text: '\n\nÓRGÃO',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 3
        }, {
          text: '\n\nFONTE DE RECURSOS ',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 3
        },
        {
          text: 'OPERAÇÕES DE CRÉDITO',
          alignment: 'center',
          bold: true, fontSize: 6, colSpan: 5
        },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        {
          text: 'DESPESA DE CAPITAL',
          alignment: 'center',
          bold: true, fontSize: 6, colSpan: 4
        },
        { text: '' },
        { text: '' },
        { text: '' }
      ],
      [
        { text: '' },
        { text: '' },
        {
          text: 'PREVISÃO ATUALIZADA',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 2
        },
        {
          text: 'REALIZADAS',
          alignment: 'center',
          bold: true, fontSize: 6, colSpan: 4
        },
        { text: '' },
        { text: '' },
        { text: '' },
        {
          text: 'DOTAÇÃO ATUALIZADA',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 2
        },
        {
          text: 'REALIZADAS',
          alignment: 'center',
          bold: true, fontSize: 6, colSpan: 3
        },
        { text: '' },
        { text: '' }
      ],
      [
        { text: '' },
        { text: '' },
        { text: '' },
        {
          text: 'INTERNAS (I)',
          alignment: 'center',
          bold: true, fontSize: 6
        },
        {
          text: 'EXTERNAS (II)',
          alignment: 'center',
          bold: true, fontSize: 6
        },
        {
          text: 'A.R.O. (III)',
          alignment: 'center',
          bold: true, fontSize: 6
        },
        {
          text: 'TOTAL (I+II+III)',
          alignment: 'center',
          bold: true, fontSize: 6
        },
        { text: '' },
        {
          text: 'EMPENHADAS',
          alignment: 'center',
          bold: true, fontSize: 6
        },
        {
          text: 'LIQUIDADAS',
          alignment: 'center',
          bold: true, fontSize: 6
        },
        {
          text: 'PAGAS',
          alignment: 'center',
          bold: true, fontSize: 6
        }]
    ];

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);

    // monta o agrupamento do relatório
    const grupos = this.funcaoService.agrupar(dados, 'orgao',
      ['previsao', 'interna', 'externa', 'aro', 'dotacao', 'empenhado', 'liquidado', 'pago']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    let total7 = 0;
    let total8 = 0;
    let total9 = 0;
    for (const grupo of grupos) {

      registros.push([
        {
          text: grupo.grupo, bold: true, fontSize: 7
        },
        { text: '' },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsao']), alignment: 'right', bold: true, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['interna']), alignment: 'right', bold: true, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['externa']), alignment: 'right', bold: true, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['aro']), alignment: 'right', bold: true, fontSize: 7 },
        {
          text: this.funcaoService.convertToBrNumber(
            +grupo.totalizadores['interna'] + +grupo.totalizadores['externa'] + +grupo.totalizadores['aro']),
          alignment: 'right', bold: true, fontSize: 7
        },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao']), alignment: 'right', bold: true, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']), alignment: 'right', bold: true, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']), alignment: 'right', bold: true, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago']), alignment: 'right', bold: true, fontSize: 7 },
      ]);
      total1 += +grupo.totalizadores['previsao'];
      total2 += +grupo.totalizadores['interna'];
      total3 += +grupo.totalizadores['externa'];
      total4 += +grupo.totalizadores['aro'];
      total5 += +grupo.totalizadores['interna'] + +grupo.totalizadores['externa'] + +grupo.totalizadores['aro'];
      total6 += +grupo.totalizadores['dotacao'];
      total7 += +grupo.totalizadores['empenhado'];
      total8 += +grupo.totalizadores['liquidado'];
      total9 += +grupo.totalizadores['pago'];

      for (const item of grupo.registros) {
        registros.push([
          { text: '' },
          {
            text: item.recurso, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(item.previsao), alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(item.interna), alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(item.externa), alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(item.aro), alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(+item.interna + +item.externa + +item.aro), alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(item.dotacao), alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(item.empenhado), alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(item.liquidado), alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(item.pago), alignment: 'right', fontSize: 7 }
        ]);
      }
    }

    registros.push([
      {
        text: 'TOTAL', fontSize: 7, bold: true, colSpan: 2
      },
      {
        text: ''
      },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total4), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total5), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total6), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total7), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total8), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total9), alignment: 'right', fontSize: 7, bold: true },
    ]);
    this.valorOperacaoCredito = (+total2 + +total3);
    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', '*', 60, 60, 60, 60, 60, 60, 60, 60, 60],
        body: registros
      }
    },
    {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*'],
        body: assinaturas
      }
    }];
  }

  public normalizar(lista: any[]) {
    const listaExportar = []

    let total0 = 0;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    let total7 = 0;
    let total8 = 0;
    let total9 = 0;

    let total_item = 0;

    let previsao = 0;
    let interna = 0;
    let externa = 0;
    let aro = 0;
    let dotacao = 0;
    let empenhado = 0;
    let liquidado = 0;
    let pago = 0;

    const grupos = this.funcaoService.agrupar(lista, 'orgao',
      ['previsao', 'interna', 'externa', 'aro', 'dotacao', 'empenhado', 'liquidado', 'pago']);
    for (const grupo of grupos) {

      previsao = grupo.totalizadores['previsao'];
      interna = grupo.totalizadores['interna'];
      externa = grupo.totalizadores['externa'];
      aro = grupo.totalizadores['aro'];
      total0 = interna + externa + aro;
      dotacao = grupo.totalizadores['dotacao'];
      empenhado = grupo.totalizadores['empenhado'];
      liquidado = grupo.totalizadores['liquidado'];
      pago = grupo.totalizadores['pago'];

      const primeira_linha = {
        orgao: grupo.grupo,
        fonte_recurso: '',
        previsao_atualizada: this.funcaoService.convertToBrNumber(previsao),
        internas: this.funcaoService.convertToBrNumber(interna),
        externas: this.funcaoService.convertToBrNumber(externa),
        aro: this.funcaoService.convertToBrNumber(aro),
        total: this.funcaoService.convertToBrNumber(total0),
        dotacao_atualizada: this.funcaoService.convertToBrNumber(dotacao),
        empenhadas: this.funcaoService.convertToBrNumber(empenhado),
        liquidadas: this.funcaoService.convertToBrNumber(liquidado),
        pagas: this.funcaoService.convertToBrNumber(pago)
      }
      listaExportar.push(primeira_linha)

      for (const item of grupo.registros) {

        const linha = {
          orgao: '',
          fonte_recurso: item.recurso,
          previsao_atualizada: this.funcaoService.convertToBrNumber(item.previsao),
          internas: this.funcaoService.convertToBrNumber(item.interna),
          externas: this.funcaoService.convertToBrNumber(item.externa),
          aro: this.funcaoService.convertToBrNumber(item.aro),
          total: this.funcaoService.convertToBrNumber(+item.interna + +item.externa + +item.aro),
          dotacao_atualizada: this.funcaoService.convertToBrNumber(item.dotacao),
          empenhadas: this.funcaoService.convertToBrNumber(item.empenhado),
          liquidadas: this.funcaoService.convertToBrNumber(item.liquidado),
          pagas: this.funcaoService.convertToBrNumber(item.pago)
        }
        listaExportar.push(linha)
      }

      total1 += previsao;
      total2 += interna;
      total3 += externa;
      total4 += aro;
      total5 += total0;
      total6 += dotacao;
      total7 += empenhado;
      total8 += liquidado;
      total9 += pago;
    }

    const total = {
      orgao: 'TOTAL',
      fonte_recurso: '',
      previsao_atualizada: this.funcaoService.convertToBrNumber(total1),
      internas: this.funcaoService.convertToBrNumber(total2),
      externas: this.funcaoService.convertToBrNumber(total3),
      aro: this.funcaoService.convertToBrNumber(total4),
      total: this.funcaoService.convertToBrNumber(total5),
      dotacao_atualizada: this.funcaoService.convertToBrNumber(total6),
      empenhadas: this.funcaoService.convertToBrNumber(total7),
      liquidadas: this.funcaoService.convertToBrNumber(total8),
      pagas: this.funcaoService.convertToBrNumber(total9)
    }
    listaExportar.push(total)

    return listaExportar
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'ÓRGÃO', coluna: 'orgao', bold: true },
      { titulo: 'FONTE DE RECURSOS', coluna: 'fonte_recurso' },
      { titulo: 'PREVISÃO ATUALIZADA', coluna: 'previsao_atualizada' },
      { titulo: 'INTERNAS (I)', coluna: 'internas' },
      { titulo: 'EXTERNAS (II)', coluna: 'externas' },
      { titulo: 'A.R.O. (III)', coluna: 'aro' },
      { titulo: 'TOTAL (I+II+III)', coluna: 'total' },
      { titulo: 'DOTAÇÃO ATUALIZADA', coluna: 'dotacao_atualizada' },
      { titulo: 'EMPENHADAS', coluna: 'empenhadas' },
      { titulo: 'LIQUIDADAS', coluna: 'liquidadas' },
      { titulo: 'PAGAS', coluna: 'pagas' },
    ];
    return colunasDefault;
  }

}
