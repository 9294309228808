import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseResourceRptComponent, Coluna, DateFormatPipe, EddyAutoComplete, FuncaoService, Inventario, InventarioTransferencia, ProgressoService } from 'eddydata-lib';
import { takeUntil } from 'rxjs/operators';
import { InventarioTransferenciaService } from '../../inventario/service/inventario-transferencia.service';
import { InventarioService } from '../../inventario/service/inventario.service';

@Component({
  selector: 'app-transferencia-pendente-rpt',
  templateUrl: './transferencia-pendente-rpt.component.html'
})
export class TransferenciaPendenteRptComponent extends BaseResourceRptComponent {

  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public ordenacao: { nome: string, coluna: any, ordem: 'DESC' | 'ASC' };
  public listaOrdenacao: { nome: string, coluna: string, ordem: 'DESC' | 'ASC' }[] = [];
  public parametros: { session?: any, filtros?: any } = {};
  public inventarioTransferencia: InventarioTransferencia;
  public retorno: Coluna[] = [];
  public inventario: Inventario;
  public listaInventario: Inventario[] = [];

  public inventarioTransferenciaAutoComplete: EddyAutoComplete<InventarioTransferencia>

  // ========================================================================
  // ------------------------------ CONSTRUTOR ------------------------------
  // ========================================================================
  constructor(
    protected funcaoService: FuncaoService,
    protected progressoService: ProgressoService,
    protected inventarioService: InventarioService,
    private inventarioTransferenciaService: InventarioTransferenciaService) {
    super();
  }

  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.listaOrdenacao = this.obterColunasOrdenacoes();
    this.ordenacao = this.listaOrdenacao[0];

    this.inventarioService.filtrar(0, 0, {
      orgao_id: this.login.orgao.id,
      orderBy: 'descricao'
    }).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      return this.listaInventario = res.content;
    })
  }

  protected tituloRelatorio(): string {
    return 'TRANSFERÊNCIAS PENDENTES DE CONFIRMAÇÃO';
  }

  private carregarAutoCompletes() {
    this.inventarioTransferenciaAutoComplete = new EddyAutoComplete(null, this.inventarioTransferenciaService,
      'id', ['tombamento.placa', 'tombamento.produto.nome'], { solicitacao: false, relations: 'tombamento,tombamento.produto,destino.setor,destino.inventario', orderBy: 'tombamento.placa$ASC' }, { number: ['tombamento.placa'], text: ['tombamento.produto.nome'] }
    );
  }

  protected obterColunasRelatorio(): Coluna[] {
    this.retorno = [];
    this.retorno.push({ titulo: 'GRUPO', coluna: 'destino.setor.nome', agrupar: true, fontSize: 10 });
    this.retorno.push({ titulo: 'PLACA No.', coluna: 'tombamento.placa', fontSize: 8, alignment: 'left' });
    this.retorno.push({ titulo: 'DESCRIÇÃO', coluna: 'tombamento.produto.nome', fontSize: 8, alignment: 'left' });
    this.retorno.push({ titulo: 'SETOR DE DESTINO', coluna: 'destino.setor.nome', fontSize: 8, alignment: 'left' });
    this.retorno.push({ titulo: 'DATA', coluna: 'data_status', fontSize: 8, alignment: 'center' });
    return this.retorno;
  }

  protected larguraColunas(): (string | number)[] {
    return [60, '*', 'auto', 'auto'];
  }

  protected totalizarColunas(): (string | {})[] {
    return null;
  }

  protected carregarLista(): Promise<any[]> {
    return new Promise((resolve) => {
      let parametros = {
        solicitacao: false,
        relations: 'tombamento,tombamento.produto,destino.setor,destino.inventario',
        orderBy: this.ordenacao.coluna
      };

      if (this.inventario)
        parametros['destino.inventario.id'] = this.inventario.id;

      if (this.parametros?.filtros)
        parametros = Object.assign(parametros, this.parametros.filtros);

      this.inventarioTransferenciaService.extendido(0, -1, parametros)
        .subscribe((data) => {
          if (!data?.content)
            resolve(null);
          else
            resolve(data.content.map((element) => {
              element.data_status = new DateFormatPipe().transform(element.data_status, []);
              return element;
            }));
      })
    });

  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================


  public obterColunasOrdenacoes(): { nome: string, coluna: string, ordem: 'DESC' | 'ASC' }[] {
    return [
      { nome: 'Placa', coluna: 'tombamento.placa', ordem: 'ASC' }
    ];
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

}
