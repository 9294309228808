import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, RequisicaoMovimento } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RPRequisicaoMovimentoService extends BaseResourceService<RequisicaoMovimento> {

  constructor(
    protected injector: Injector
  ) {
    super(`requisicao-movimento`, injector);
  }

  aprovar(resource: RequisicaoMovimento): Observable<RequisicaoMovimento> {
    return this.http.put(`${this.login.cidade.id}/${this.api}/aprovar/${resource.id}`, resource, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  recusar(resource: RequisicaoMovimento): Observable<RequisicaoMovimento> {
    return this.http.put(`${this.login.cidade.id}/${this.api}/recusar/${resource.id}`, resource, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
