export class ColunaEstruturaEntidade {
    public nome: string;
    public padrao: any;
    public nulo: boolean;
    public tamanho: number;
}


export class EstruturaEntidade {
    public nome: string;
    public colunas: ColunaEstruturaEntidade[] = []
}
