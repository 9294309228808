// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';

export class PlanoConta extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public nome?: string,
    public n1?: string,
    public n2?: string,
    public n3?: string,
    public n4?: string,
    public n5?: string,
    public n6?: string,
    public n7?: string,
    public n8?: string,
    public nivel?: number,
    public escrituracao?: boolean,
    public conta_consumo?: boolean,
    public escritura_valor_liquido?: boolean,
    public escritura_valor_liquido_orcamentario?: boolean,
    public pagto_valor_bruto?: boolean,
    public pagto_valor_liquido_total?: boolean,
    public pagto_valor_liquido_orcamentario?: boolean,
    public nao_escritura_adiantamento?: boolean,
    public sistema?: string,
    public encerramento?: string,
    public natureza?: string,
    public superavit_financeiro?: string,
    public conta_corrente?: string,
    public variacao?: string,
    public descricao?: string,
    public exercicio?: Exercicio, ) {
    super();
  }

  static converteJson(json: any): PlanoConta {
    return Object.assign(new PlanoConta(), json);
  }
}
