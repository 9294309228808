import { Injectable, Injector } from "@angular/core";
import { BaseResourceService, PedidoCompra } from "eddydata-lib";

@Injectable({
  providedIn: 'root'
})
export class PedidoCompraService extends BaseResourceService<PedidoCompra> {

  constructor(
    protected injector: Injector
  ) {
    super(`pedido-compra`, injector);
  }

}