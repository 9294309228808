import { BaseResourceModel, EmpenhoExtra, Liquidacao, LiquidacaoResto } from "eddydata-lib";
import { EmissaoCheque } from "./emissao-cheque.model";

export class ChequeEmpenho extends BaseResourceModel {
  constructor(
    public id?: number,
    public aux?: number,
    public numero?: number,
    public parcela?: number,
    public especie?: string,
    public cheque?: EmissaoCheque,
    public liquidacao?: Liquidacao,
    public empenho_extra?: EmpenhoExtra,
    public liquidacao_resto?: LiquidacaoResto,) {
    super();
  }
  static converteJson(json: any): ChequeEmpenho {
    return Object.assign(new ChequeEmpenho(), json);
  }
}