import { BaseResourceStorage } from '../../models/base-resource.storage';
import { GrupoArquivoTransparencia } from './grupo-arquivo-transparencia.model';

export class TransparenciaStorage extends BaseResourceStorage {
  constructor(
    public foto?: boolean,
    public grupo?:GrupoArquivoTransparencia,
  ) {
    super();
  }

  static converteJson(json: any): TransparenciaStorage {
    return Object.assign(new TransparenciaStorage(), json);
  }
}