import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, CompraItem, Page } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class RPCompraItemService extends BaseResourceService<CompraItem> {

  constructor(
    protected injector: Injector
  ) {
    super(`compra-itens`, injector);
  }

  public obterPorCompra(id: number): Observable<Page> {
    return this.http.get<Page>(
      // tslint:disable-next-line: max-line-length
      `${this.login.cidade.id}/${this.api}/filtrar/?relations=produto_unidade,produto_unidade.unidade,produto_unidade.produto&compra_id=${id}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterSaldoItens(compraId: number): Observable<{ itemId: number, quantidade: number }[]> {
    return this.http.get<{ itemId: number, quantidade: number }[]>(`${this.login.cidade.id}/${this.api}/saldo/${compraId}`, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterSaldoAtualizado(compraId: number, produto: string, unidade: string): Observable<any>;
  public obterSaldoAtualizado(compraId: number, produto: number): Observable<any>;
  public obterSaldoAtualizado(compraId: number, produto: number | string, unidade?: string): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/saldo-atualizado/${compraId}/${produto}${unidade ? `/${unidade}` : ''}`, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  };

}
