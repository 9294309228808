import { Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { BaseResourceItemsComponent, DateFormatPipe, EddyAutoComplete, FuncaoService, GlobalService, Page, Produto, ProdutoUnidade, RequisicaoItemMovimento, RequisicaoMovimento, SaldoEstoqueService, SetorAlmoxarifado, StatusRequisicao } from 'eddydata-lib';
import { AutoComplete } from 'primeng/autocomplete';
import { Calendar } from 'primeng/calendar';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ProdutoUnidadeService } from '../../produto/service/produto-unidade.service';
import { ProdutoService } from '../../produto/service/produto.service';
declare var $: any;

@Component({
  selector: 'lib-requisicao-item',
  templateUrl: './requisicao-item.component.html',
})
export class RequisicaoItemComponent extends BaseResourceItemsComponent<RequisicaoItemMovimento> implements OnChanges {

  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  @ViewChild('qtde_requisitada') public qtde_requisicao_elem: ElementRef;
  @ViewChild('lote_') public inputLote: AutoComplete;
  @ViewChild('produto_') public inputProduto: AutoComplete;

  @Input() somenteVisualizar: boolean = false;
  @Input() requisicao: RequisicaoMovimento;
  @Input() setor_almoxarifado: SetorAlmoxarifado;

  public ptBR: any;

  public produtoAutoComplete: EddyAutoComplete<Produto>;

  public listProdUn: ProdutoUnidade[];
  public listLoteVec: any[];
  public listLoteVecFiltro: any[];
  public listaItensAutocomplete: any[];

  public imaskValor = {
    mask: Number,
    scale: 5,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================

  @HostListener('window:keydown.control.p', ['$event'])
  public abrirConsultaProduto(event: KeyboardEvent) {
    event.preventDefault();
    $('#dialogProdutoUnBuscaReq').modal('show');
  }

  public callbackProdutoUn(prodUn: ProdutoUnidade) {
    let item = this.selecionarEditavel();
    if (item) {
      item.produto_unidade = prodUn;
      this.carregarUnidades(item);
    }
  }

  constructor(
    private produtoUnService: ProdutoUnidadeService,
    private saldoEstoqueService: SaldoEstoqueService,
    public funcaoService: FuncaoService,
    public globalService: GlobalService,
    private produtoService: ProdutoService) {
    super(new RequisicaoItemMovimento(), produtoService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.requisicao)
      this.carregarAutoCompletes();
  }

  protected afterInit(): void {
    this.changeAfterEdit = true;
    this.ptBR = new GlobalService().obterDataBR();
    this.carregarAutoCompletes();
  }

  protected validSave(item: RequisicaoItemMovimento): boolean {
    if (!item.produto_unidade) {
      toastr.warning('Informe o produto e a unidade!');
      return false;
    }
    if (!item.qtd_requisitada || item.qtd_requisitada <= 0) {
      toastr.warning('Informe a quantidade!');
      return false;
    }
    if ((this.requisicao.devolucao && item['saida'] && +item.qtd_requisitada > +item['saldo_atual'])) {
      toastr.warning('Quantidade devolvida maior que o saldo para devolução.');
      return false;
    }
    const incluido = this.lista.find((i) => item.produto_unidade.id === i.produto_unidade.id && !i['editavel']);
    if (incluido) {
      toastr.warning(`O produto ${item.produto_unidade.produto.nome} já foi incluído.`);
      return false;
    }
    return true;
  }

  protected beforeSave(item: RequisicaoItemMovimento): void {
    this.listProdUn = [];
    if (!this.requisicao.devolucao) {
      item.lote = null;
      item.vencimento = null;
    }
    const item_encontrado = this.listaItensAutocomplete.find(i => i.nome === item.produto_unidade.produto.nome && i.saldo <= 0)

    if(item_encontrado) {
      item.sem_entrada = true
      item.status = StatusRequisicao.AGUARDANDO_FORNECEDOR;
    }
  }

  protected afterEdit(item: RequisicaoItemMovimento) {
    if (!item.produto_unidade)
      item.produto_unidade = new ProdutoUnidade();
    if (!item.status)
      item.status = StatusRequisicao.AGUARDANDO;
    this.carregarUnidades(item);
    item.vencimento = new DateFormatPipe().transform(item.vencimento, []);
  }


  protected campoFoco(): ElementRef | AutoComplete | Calendar {
    return this.inputProduto;
  }

  public disabled() {
    if (!this.setor_almoxarifado)
      return true;
    return super.disabled();
  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  public async salvarAdicionar(item: RequisicaoItemMovimento) {
    this.salvar(item);
    this.adicionar();
  }

  public parametrosProdutos(): {} {
    let parametros = {};
    parametros['material.sub_grupo.grupo.estoques.estoque.id'] = this.setor_almoxarifado?.estoque?.id;
    parametros['relations'] = 'produto.material.sub_grupo.grupo.estoques.estoque,unidades,unidades.unidade'
    return parametros;
  }

  private carregarAutoCompletes() {
    this.produtoAutoComplete = new EddyAutoComplete(null, this.produtoService,
      'id', ['codigo', 'nome'], { orgao_id: this.login.orgao.id, orderBy: 'codigo,nome' }, { number: ['id', 'codigo'], text: ['codigo', 'nome'] }, null, null, null,
      (pagina: number, limite: number, filtros: {}, filtroStr: string): Observable<Page> => {

        let parametros: { codigo?: string, nome?: string } = {};

        if (new FuncaoService().isNumerico(filtroStr))
          parametros.codigo = filtroStr;
        else
          parametros.nome = filtroStr;

        return this.requisicao.devolucao ? this.saldoEstoqueService
          .obterProdutosComSaida(this.setor_almoxarifado.estoque.id, parametros)
          .pipe(map(res => {
            let page = new Page();
            page.content = res;
            return page;
          })) : this.saldoEstoqueService
            .obterProdutosComSaldoESemMovimento(this.setor_almoxarifado.estoque?.id, this.login.exercicio.id, parametros)
            .pipe(map(res => {
              let page = new Page();
              page.content = res;
              this.listaItensAutocomplete = page.content;
              return page;
            }));
      }
    );
  }

  public carregarUnidades(item: RequisicaoItemMovimento) {
    if (!item.produto_unidade.produto) {
      return;
    }
    this.produtoUnService.filtrar(0, 0, {
      produto_id: item.produto_unidade.produto.id,
      ativo: true,
      relations: 'produto,unidade',
      orderBy: 'unidade.nome'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listProdUn = res ? res.content : [];
        if (this.listProdUn.length === 0) {
          toastr.warning('Nenhuma unidade ativa foi localizada para este produto!');
          return;
        }
        item.produto_unidade = this.listProdUn[0];
        this.carregarLotes(item);
        if (this.requisicao.devolucao)
          new FuncaoService().focarCampo(this.inputLote);
        else
          new FuncaoService().focarCampo(this.qtde_requisicao_elem);
      }, error => toastr.error(error.message ? error.message : error));
  }

  public carregarLotes(item: RequisicaoItemMovimento) {
    if (!item.produto_unidade || !item.produto_unidade.id || !this.requisicao.devolucao)
      return;
    this.saldoEstoqueService
      .saldoProdutoUnidadeLoteVencimento(item.produto_unidade.id, this.setor_almoxarifado.id,
        this.requisicao.devolucao ? { nao_obg_saldo: false, data_limite: this.funcaoService.converteDataSQL(this.entidade?.data_cadastro ?? new Date()), destino: true } : null)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((saldos) => {
        if (!saldos || saldos.length === 0)
          return;
        this.listLoteVec = saldos;
        if (!item.lote || !item.vencimento) {
          let saldo = saldos[0];
          this.onChangeLote(item, saldo);
        } else {
          let itemL = saldos.find((s) => s.lote === item.lote
            && new Date(new Date(s.vencimento).toDateString()).getTime() === new Date(new Date(item.vencimento).toDateString()).getTime());
          this.onChangeLote(item, itemL);
        }
      });
  }

  onChangeLote(item: RequisicaoItemMovimento, itemL) {
    item.lote = itemL.lote;
    item.vencimento = itemL.vencimento;
    item['saldo_atual'] = +itemL.saldo_atual < 0 ? +itemL.saldo_atual * -1 : itemL.saldo_atual;
    item['saida'] = itemL.saida;
  }

  buscarLoteVencimento(event) {
    if (event.query)
      this.listLoteVecFiltro = this.listLoteVec.filter((l) => l.lote.toLowerCase().includes(event.query.toLowerCase()));
    else
      this.listLoteVecFiltro = Object.assign([], this.listLoteVec)
  }

  conversorLote(itemL) {
    if (!itemL || !itemL.lote || !itemL.vencimento)
      return '';
    return `${itemL.lote} - ${new FuncaoService().converteDataBR(itemL.vencimento)}`
  }

  public compareFnLoteVencimento(c1: any, c2: any): boolean {
    return c1 && c2 && c1.lote && c2.lote && c1.vencimento && c2.vencimento
      && c1.lote === c2.lote && new Date(c1.vencimento).toDateString() === new Date(c2.vencimento).toDateString();
  }

}
