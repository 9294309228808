// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { PedidoEtapaSetorMerenda } from './pedido-etapa-setor-merenda.model';

export class PedidoMerenda extends BaseResourceModel {
  constructor(
    public id?: number,
    public usuario?: Usuario,
    public exercicio?: Exercicio,
    public orgao?: Orgao,
    public etapas_setores? : PedidoEtapaSetorMerenda[]) {
    super();
  }
  static converteJson(json: any): PedidoMerenda {
    return Object.assign(new PedidoMerenda(), json);
  }
}
