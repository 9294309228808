import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, MetaAvaliacao } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class MetaAvaliacaoService extends BaseResourceService<MetaAvaliacao> {

  constructor(
    protected injector: Injector
  ) {
    super(`meta-avaliacoes`, injector);
  }

}
