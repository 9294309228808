import { BaseResourceModel } from '../../models/base-resource.model';
import { Motorista } from './motorista.model';

export class MotoristaAcidente extends BaseResourceModel {
  constructor(
    public id?: number,
    public placa_veiculo?: string,
    public descricao_veiculo?: string,
    public data_acidente?: Date,
    public local?: string,
    public boletim_ocorrencia?: string,
    public processo_adm?: string,
    public data_seguro?: Date,
    public sinistro?: number,
    public placa_veiculo_terc?: string,
    public descricao_veiculo_terc?: string,
    public cnh_terc?: string,
    public observacao?: string,
    public motorista?: Motorista,
  ) {
    super();
  }

  static converteJson(json: any): MotoristaAcidente {
    return Object.assign(new MotoristaAcidente(), json);
  }
}
