import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BaseResourceFormComponent, FuncaoService, GlobalService, LoginContabil,
  TipoConvenio, TipoContratacao, TipoConvenioEvento, TipoConvenioCondicaoDespesa,
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { TipoConvenioEventoService } from '../service/tipo-convenio-evento.service';
import { TipoConvenioService } from '../service/tipo-convenio.service';

@Component({
  selector: 'lib-tipo-convenio-form',
  templateUrl: './tipo-convenio-form.component.html'
})
export class TipoConvenioFormComponent extends BaseResourceFormComponent<TipoConvenio, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @ViewChild('nome') inputField: ElementRef;
  public listaTiposContratacao: { tipo_contratacao: TipoContratacao, marcado: boolean }[];
  public totalTipoConvenio: number;
  public listaTipoConvenioEventos: TipoConvenioEvento[];
  public listaOrigem: Array<{ nome: string, id: 'A' | 'C' | 'F' }>;
  public listaCondicoes: TipoConvenioCondicaoDespesa[] = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected tipoConvenioEventoService: TipoConvenioEventoService,
    protected tipoService: TipoConvenioService) {
    super(new TipoConvenio(), injector, TipoConvenio.converteJson, tipoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(_entidade: TipoConvenio): Promise<boolean> {
    return true
  }
  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      nome: [null, [Validators.required]],
      tce: [null, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      tipoConvenioEventos: [null],
      origem: [null, [Validators.required]],
      condicoes_despesa: [[]]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'orgao,condicoes_despesa' };
  }

  protected afterLoad() {
    this.tipoConvenioEventoService.filtrar(0, -1, {
      'tipo_convenio.id': this.entidade.id,
      relations: 'tipo_convenio,evento_especie'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data: any) => {
          this.listaTipoConvenioEventos = data ? data.content : new Array<TipoConvenioEvento>();
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));

    this.listaCondicoes = this.entidade.condicoes_despesa;
  }

  protected afterInit(): void {
    this.listaOrigem = [
      { nome: 'AMBOS', id: 'A' },
      { nome: 'FORNECEDOR', id: 'F' },
      { nome: 'CREDOR', id: 'C' }
    ];
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected beforeSubmit() {
    try {
      if (this.entidadeForm.get('nome').value === '') {
        throw new Error('Informe o nome!');
      }
      this.entidadeForm.get('tipoConvenioEventos').setValue(this.listaTipoConvenioEventos);
      this.entidadeForm.get('condicoes_despesa').setValue(this.listaCondicoes);
      this.entidadeForm.get('orgao').setValue(this.login.orgao);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(ent: TipoConvenio) {
    this.loadResource();
    window.scrollTo(0, 0);
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
