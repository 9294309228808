import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EventoContabil } from '../../entidade/contabil/evento-contabil.model';
import { BaseResourceService } from '../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class RPEventoContabilService extends BaseResourceService<EventoContabil> {

  constructor(
    protected injector: Injector
  ) {
    super(`eventos-contabeis`, injector);
  }

  public proximoIdDisponivel(orgao: number, exercicio: number): Observable<number> {
    return this.http.get<number>(`${this.login.cidade.id}/${this.api}/proximo-id/${orgao}/${exercicio}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}