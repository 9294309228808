import { Component, Input, OnInit } from '@angular/core';
import { Audesp4IndiceEconomico, FuncaoService, RegistroPrecos } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-indice',
  templateUrl: './licitacao-audesp-indice.component.html'
})
export class LicitacaoAudespIndiceComponent extends BaseLicitacaoAudesp implements OnInit {

  @Input() public entidade: RegistroPrecos;

  public itemAtual: Audesp4IndiceEconomico;
  public itemAnterior: Audesp4IndiceEconomico;
  public opcoesIndice: { id: number, nome: string }[];

  constructor(public funcaoService: FuncaoService
  ) {
    super()
  }

  ngOnInit(): void {
    this.opcoesIndice = [
      { id: 7, nome: 'Capital Social Mínimo' },
      { id: 5, nome: 'Endividamento a Curto Prazo' },
      { id: 6, nome: 'Endividamento Total' },
      { id: 2, nome: 'Liquidez Corrente' },
      { id: 4, nome: 'Liquidez Geral' },
      { id: 1, nome: 'Liquidez Imediata' },
      { id: 3, nome: 'Liquidez Seca' },
      { id: 8, nome: 'Outro' },
    ];
  }
  public adicionar() {
    if (!this.entidade.IndiceEconomico || this.entidade.IndiceEconomico.length === 0) this.entidade.IndiceEconomico = [];

    this.itemAtual = {
      IndiceEconomicoOutroTipo: { Descricao: undefined, Outro: undefined }, IndiceEconomicoTipo: undefined,
      IndiceEconomicoValor: undefined, IndiceEconomicoItemEdital: undefined, editavel: true
    }
    this.entidade.IndiceEconomico.push(this.itemAtual);
  }

  public visualizar(item: Audesp4IndiceEconomico) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      let itm = this.entidade.IndiceEconomico.find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.entidade.IndiceEconomico.splice(this.entidade.IndiceEconomico.indexOf(this.itemAtual), 1, itm)
      this.itemAtual.editavel = false;
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4IndiceEconomico) {
    this.itemAtual = undefined;
    this.entidade.IndiceEconomico.splice(this.entidade.IndiceEconomico.indexOf(item), 1)
  }

  public salvar() {
    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }
}
