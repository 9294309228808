import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Convenio, FuncaoService, Liquidacao, LiquidacaoResto, Login } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { tsXLXS } from 'ts-xlsx-export';
import { LiquidacaoService } from '../../../contabil/liquidacao/service/liquidacao.service';
import { LiquidacaoRestoService } from 'contabil-lib';

@Component({
  selector: 'lib-convenio-liquidacao',
  templateUrl: './convenio-liquidacao.component.html',
})
export class ConvenioLiquidacaoComponent implements OnInit, OnChanges, OnDestroy {

  protected unsubscribe: Subject<void> = new Subject();
  public liquidacoes: Array<Liquidacao | LiquidacaoResto> = [];

  @Input() lista: Array<{ id: number, resto: boolean }>;
  @Input() convenio: Convenio;
  @Input() login: Login;

  constructor(
    protected confirmationService: ConfirmationService,
    protected funcaoService: FuncaoService,
    protected liquidacaoService: LiquidacaoService,
    protected liquidacaoRestoService: LiquidacaoRestoService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['lista']) {
      const liquidacoes_ids = this.lista.filter(item => !item.resto).map(item => item.id).join(',');
      if (liquidacoes_ids) {
        const liquidacoes = await this.liquidacaoService.filtrar(1, -1, {
          'id$in': liquidacoes_ids,
          'ignoreCondObrig': true,
          'relations': 'empenho.exercicio'
        }).toPromise();
        this.liquidacoes.push(...liquidacoes.content);
      }

      const liquidacoes_resto_ids = this.lista.filter(item => item.resto).map(item => item.id).join(',');
      if (liquidacoes_resto_ids) {
        const liquidacoes_resto = await this.liquidacaoRestoService.filtrar(1, -1, {
          'id$in': liquidacoes_resto_ids,
          'ignoreCondObrig': true,
          'relations': 'empenho.exercicio'
        }).toPromise();
        this.liquidacoes.push(...liquidacoes_resto.content);
      }
    }
  }

  public exportarXLSX() {
    const listaItens = new Array();
    for (const item of this.liquidacoes) {
      const entity = {
        exercicio: item.empenho.exercicio.ano,
        empenho: item.empenho.numero,
        parcela: item.parcela > 0 ? item.parcela : '',
        data_liquidacao: this.funcaoService.converteDataBR(item.data_liquidacao),
        vencimento: this.funcaoService.converteDataBR(item.data_vencimento),
        documento: item.documento,
        valor_liquidado: item.valor_liquidado
      };
      listaItens.push(entity);
    }
    tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile(`liquidações - ${this.convenio.numero} ${this.convenio.ano}`);
  }
  
}
