import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ContratoAditamento } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContratoAditamentoService extends BaseResourceService<ContratoAditamento> {

  constructor(
    protected injector: Injector
  ) {
    super(`contrato-aditamentos`, injector);
  }

  public proximoNumero(contrato: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-numero/${contrato}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
