import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { ExercicioService } from '../service/exercicio.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Login } from '../../entidade/login/login';
import { Exercicio } from '../../entidade/comum/exercicio.model';
import { FuncaoService } from '../../util/funcao.service';
import { GlobalService } from '../../util/global.service';
import { MessageService } from 'primeng/api';

import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-exercicio-dlg',
  templateUrl: './exercicio-dlg.component.html'
})
export class ExercicioDlgComponent implements OnInit, OnDestroy {

  public login: Login = new Login();
  public exercicio: Exercicio = new Exercicio();
  public ano: number;
  public orcamento_aprovado: boolean;
  public encerrado: boolean;
  public listaExercicios: Array<any>;
  protected unsubscribe: Subject<void> = new Subject();
  @Input() public ocultar: string = null;

  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();
  @Input() exercicioEdicao: Exercicio;

  constructor(
    protected router: Router,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    private exercicioService: ExercicioService,
  ) { }

  ngOnInit() {
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.exercicioEdicao) {
      this.ano = this.exercicioEdicao?.ano
      this.orcamento_aprovado = this.exercicioEdicao?.orcamento_aprovado
      this.encerrado = this.exercicioEdicao?.encerrado
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.ano = null
    this.orcamento_aprovado = null;
    this.encerrado = null;
  }

  enviar() {
    this.exercicio.ano = this.ano;
    this.exercicio.orcamento_aprovado = this.orcamento_aprovado;
    this.exercicio.encerrado = this.encerrado;
    this.exercicio.cidade = this.login.cidade;

    if (this.exercicioEdicao) {
      this.exercicio.id = this.exercicioEdicao.id
      this.exercicioService.atualizar(this.exercicio).pipe(takeUntil(this.unsubscribe)).subscribe(dados => {
        this.visualizarChange.emit(true)
        toastr.info('Exercício atualizado!')
        this.exercicioEdicao = new Exercicio
        this.ano = null
        this.orcamento_aprovado = null
        this.encerrado = null
      }, err => {
        toastr.error('Erro inesperado')
        this.exercicioEdicao = new Exercicio
        this.ano = null
        this.orcamento_aprovado = null
        this.encerrado = null
      })
    } else {

      this.exercicioService.inserir(this.exercicio).pipe(takeUntil(this.unsubscribe))
        .subscribe(dados => {
          this.visualizarChange.emit(true)
          toastr.info('Registro cadastrado com sucesso!');
        }, err => { toastr.error('Validação', err); });
    }
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  public fechar() {
    this.ano = null
    this.orcamento_aprovado = null
    this.encerrado = null
    $('#dialogExercicio').modal('hide')
  }

}

export const reloadSession = () => {
  // this.login.exercicio = this.exercicio;
  sessionStorage.setItem('listaco4', '');
  sessionStorage.setItem('listaco5', '');
  sessionStorage.setItem('lista1', '');
  sessionStorage.setItem('listaco3', '');
  sessionStorage.setItem('listaco2', '');
  sessionStorage.setItem('totalArrecadadoco', '');
  sessionStorage.setItem('totalEmpenhadoco', '');
  sessionStorage.setItem('totalLiquidadoco', '');
  sessionStorage.setItem('totalPagoco', '');
  // GlobalService.salvarSessaoLogin(this.login);
  location.reload();
}
