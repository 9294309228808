import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { PlanoContratacaoAnualFormComponent } from './plano-contratacao-anual-form/plano-contratacao-anual-form.component';
import { PlanoContratacaoAnualListComponent } from './plano-contratacao-anual-list/plano-contratacao-anual-list.component';
import { PlanoContratacaoAnualViewComponent } from './plano-contratacao-anual-view/plano-contratacao-anual-view.component';


const routes: Routes = [
  { path: '', component: PlanoContratacaoAnualListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: PlanoContratacaoAnualFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: PlanoContratacaoAnualFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: PlanoContratacaoAnualViewComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlanoContratacaoAnualRoutingModule { }
