import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { ModeloRelatorioDlgComponent } from './modelo-relatorio-dlg/modelo-relatorio-dlg.component';
import { ModeloRelatorioListComponent } from './modelo-relatorio-list/modelo-relatorio-list.component';
import { ModeloRelatorioRoutingModule } from './modelo-relatorio-routing.module';
import { ModeloRelatorioRptDlgComponent } from './modelo-relatorio-rpt-dlg/modelo-relatorio-rpt-dlg.component';

@NgModule({
  declarations: [
    ModeloRelatorioListComponent,
    ModeloRelatorioDlgComponent,
    ModeloRelatorioRptDlgComponent,
  ],
  exports:[
    ModeloRelatorioListComponent
  ],
  imports: [
    CommonModule,
    ModeloRelatorioRoutingModule,
    SharedModule,
    FormsModule,
    ToastModule,
    IMaskModule,
    FieldsetModule,
    RadioButtonModule,
    AutoCompleteModule,
    EditorModule,
    SelectButtonModule,
  ]
})
export class ModeloRelatorioModule { }
