// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Pessoa } from '../comum/pessoa.model';
import { Favorecido } from './favorecido.model';

export class FavorecidoResponsavel extends BaseResourceModel {
    constructor(
        public id?: number,
        public responsavel?: Pessoa,
        public favorecido?: Favorecido,
    ) {
        super();
    }
    static converteJson(json: any): FavorecidoResponsavel {
        return Object.assign(new FavorecidoResponsavel(), json);
    }
}
