import { Component, Input } from '@angular/core';
import { RegistroPrecos } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-declaracao-recurso',
  templateUrl: './licitacao-audesp-declaracao-recurso.component.html'
})
export class LicitacaoAudespDeclaracaoRecursoComponent extends BaseLicitacaoAudesp {

  @Input() public entidade: RegistroPrecos;

  constructor(
  ) {
    super()
  }

}
