import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, FuncaoService, Imovel } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RPBemImovelService extends BaseResourceService<Imovel> {

  constructor(
    protected injector: Injector
  ) {
    super(`imoveis`, injector);
  }

  public obterPorNome(nome: string, orgaoId: number): Observable<Imovel> {
    return this.http.get<Imovel>(
      `${this.login.cidade.id}/${this.api}/filtrar?nome$like=${nome}%&orgao_id=${orgaoId}&relations=unidade`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterFiltro(descricaoImovel?: string, matricula?: string, cep?: string, bairro?: string, municipio?: string, secretaria?: string): {} {
    const parametros = {};

    if (descricaoImovel)
      parametros['descricao$like'] = `%${new FuncaoService().removerAcentos(descricaoImovel)}%`;
    if (matricula)
      parametros['matricula'] = matricula;
    if (cep)
      parametros['cep'] = cep;
    if (bairro)
      parametros['bairro$like'] = `%${new FuncaoService().removerAcentos(bairro)}%`;
    if (municipio)
      parametros['municipio$like'] = `%${new FuncaoService().removerAcentos(municipio)}%`;
    if (secretaria)
      parametros['setor.unidade.nome$like'] = `%${new FuncaoService().removerAcentos(secretaria)}%`;

    return parametros;
  }

}
