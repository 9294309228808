import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalAssinadorComponent } from './modal-assinador.component';
import { ModalAssinadorRoutingModule } from './modal-assinador-routing.module';
import { ModalAssinadorService } from './modal-assinador.service';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ModalAssinadorRoutingModule,
    ],
    declarations: [ModalAssinadorComponent],
    exports: [ModalAssinadorComponent],
    providers: [ModalAssinadorService]
})
export class ModalAssinadorModule { }
