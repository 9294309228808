import {
  Component, OnInit, OnChanges, Input, ElementRef,
  SimpleChanges, ViewChild, OnDestroy, ViewChildren, QueryList, Output, EventEmitter
} from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CreditoService } from '../service/credito.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  CreditoItem, EddyAutoComplete, FichaDespesa, Credito, Login,
  GlobalService, FuncaoService, Exercicio, ReceitaPrevista, FichaReceita
} from 'eddydata-lib';
import { DiarioService, EmpenhoService, FichaReceitaService, ReceitaPrevistaService } from 'administrativo-lib';
import { FichaDespesaService } from '../../../ficha-despesa/service/ficha-despesa.service';
import { CreditoItemService } from '../service/credito-item.service';

declare var $: any;

@Component({
  selector: 'app-credito-item',
  templateUrl: './credito-item.component.html'
})

export class CreditoItemComponent implements OnInit, OnChanges, OnDestroy {
  public ptBR: any;
  public entity: CreditoItem;
  public entityPrevisto: ReceitaPrevista;
  public entityTempPrevisto: ReceitaPrevista;
  public entityTemp: CreditoItem;
  public listaItens: Array<any>;
  public fichaAutoComplete: EddyAutoComplete<FichaDespesa>;
  public totalCredito = 0;
  public totalAnulado = 0;
  public totalPrevisto = 0;
  protected unsubscribe: Subject<void> = new Subject();
  public fichaReceitaAutoComplete: EddyAutoComplete<FichaReceita>;
  public dotacao: number;
  public empenhado: number;
  public visualizarExecesso: boolean = false;
  public colunaOrdenacao: string;
  public ascendente = true;
  public previsao_ficha: FichaDespesa;
  public saldo_faltante: number = 0;
  public mes: number;
  public buttonDisabled: boolean;

  @Input() lista: Array<any>;
  @Input() entidade: Credito;
  @Input() login: Login;
  @Input() ocorrencia: number;
  @Input() data_credito: Date;
  @Input() podeIncluir: boolean

  @Output() listaExclusao: EventEmitter<CreditoItem> = new EventEmitter();

  @ViewChild('btnAdicionarCredito') public btnAdicionarCredito: ElementRef;
  @ViewChildren('btnsEditar') public btnsEditar: QueryList<ElementRef>;
  @ViewChildren('btnSalvarPrevisao_') public btnSalvarPrevisao_: ElementRef;

  imaskConfig = {
    mask: Number,
    scale: 2,
    signed: true,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected empenhoService: EmpenhoService,
    protected fichaService: FichaDespesaService,
    protected previstoService: ReceitaPrevistaService,
    protected fichaReceitaService: FichaReceitaService,
    protected creditoService: CreditoService,
    protected creditoItemService: CreditoItemService,
    protected diarioService: DiarioService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.listaItens = this.lista;
    this.carregarAutoCompletes();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lista) {
      this.listaItens = this.lista;
      this.totalizador(this.lista);
    }

    if (changes.ocorrencia) {
      this.carregarAutoCompletes()
    }
  }

  public reordenar(coluna: string, toggle?: boolean) {
    if (!coluna) return;

    if (!toggle) this.ascendente = true;
    else this.ascendente = !this.ascendente;

    this.colunaOrdenacao = coluna;
    
    if(this.colunaOrdenacao === 'ficha.numero') {
      this.listaItens.sort((a, b) => (this.ascendente? a.ficha.numero - b.ficha.numero: b.ficha.numero - a.ficha.numero)) 
    }
    if(this.colunaOrdenacao === 'data_contabilizacao') {
      this.listaItens.sort((a, b) => (this.ascendente? a.data_contabilizacao - b.data_contabilizacao: b.data_contabilizacao - a.data_contabilizacao)) 
    }
    if(this.colunaOrdenacao === 'valor_credito ') {
      this.listaItens.sort((a, b) => (this.ascendente? a.valor_credito  - b.valor_credito : b.valor_credito  - a.valor_credito )) 
    }
    if(this.colunaOrdenacao === 'dotacao ') {
      this.listaItens.sort((a, b) => (this.ascendente? a.dotacao  - b.dotacao : b.dotacao  - a.dotacao )) 
    }
    if(this.colunaOrdenacao === 'empenhado ') {
      this.listaItens.sort((a, b) => (this.ascendente? a.empenhado  - b.empenhado : b.empenhado  - a.empenhado )) 
    }
  }

  private totalizador(list: Array<any>) {
    if (list) {
      this.totalAnulado = 0;
      this.totalCredito = 0;
      for (const item of list) {
        if (item.valor_credito > 0) {
          this.totalCredito += +item.valor_credito;
        } else {
          this.totalAnulado += +item.valor_credito;
        }
      }
    }
  }

  private desabilitarEdicao(desabilitar: boolean) {
    this.btnsEditar.forEach((btn) => btn.nativeElement.disabled = desabilitar)
    this.btnAdicionarCredito.nativeElement.disabled = desabilitar;
  }

  public adicionarCredito() {
    setTimeout(() => { new GlobalService().calendarMascara(); }, 100);
    if (!this.data_credito) {
      this.messageService.add({ severity: 'warn', summary: 'Validação', detail: 'Informe a data do crédito' });
      return;
    }
    if (!this.ocorrencia || this.ocorrencia == 0) {
      this.messageService.add({ severity: 'warn', summary: 'Validação', detail: 'Informe a ocorrência do crédito' });
      return;
    }
    this.entity = new CreditoItem();
    this.entity.aux = 0;
    this.entity.credito = this.entidade;
    this.entity.editavel = true;
    this.entity['salvo'] = false;
    this.entity.empenhado = 0;
    if (!this.listaItens) {
      this.listaItens = new Array();
    }
    this.listaItens.unshift(this.entity);
    this.desabilitarEdicao(true);
  }

  public salvarCredito(item: CreditoItem) {
    try {
      if (!item.ficha) {
        throw new Error('Informe a ficha de despesa!');
      }
      if (!item.data_contabilizacao) {
        throw new Error('Informe a data de contabilização!');
      }
      if (!item.valor_credito) {
        throw new Error('Informe o valor!');
      }
      if (+item.valor_credito < 0) {
        if ((+this.dotacao + +item.valor_credito) < 0) {
          throw new Error('Não há saldo de dotação para efetuar anulação!');
        }
      }
      if (!this.funcaoService.podeAlterarAudesp(item.data_contabilizacao, this.login)) {
        throw new Error('O mês do registro já foi armazenado no TCE. Não é possível salvar!');
      }

      item.especie = item.valor_credito > 0 ? 'N' : 'A';
      item.editavel = false;
      item['salvo'] = true;
      this.visualizarExecesso = false;
      this.desabilitarEdicao(false);
      this.totalizador(this.listaItens);
      if (item.especie === 'A') {
        this.validarSaldoFicha(item);
      }

    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  public editarCredito(item: CreditoItem) {
    this.entityTemp = item;
    this.entity = item;
    this.entity['salvo'] = true;
    this.entity.editavel = true;
    this.desabilitarEdicao(true)
  }

  public cancelarCredito(item: CreditoItem, index: number) {
    if (item['salvo'] && item.id === this.entityTemp?.id) {
      item.ficha = this.entityTemp.ficha;
      item.valor_credito = this.entityTemp.valor_credito;
      this.entity = item;
      this.entity.editavel = false;
      this.visualizarExecesso = false;
    } else {
      this.listaItens.splice(index, 1);
    }
    this.desabilitarEdicao(false);
    this.totalizador(this.listaItens);
  }

  public confirmarRemocao(item: CreditoItem, index: number) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja remover item?',
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.removerCredito(item, index)
      },
    });
  }

  public removerCredito(item: CreditoItem, index: number) {
    if (item.id) {
      this.listaItens.splice(index, 1);
      this.listaExclusao.emit(item)
      this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });

    } else {
      this.listaItens.splice(index, 1);
    }
    this.totalizador(this.listaItens);
  }

  private carregarAutoCompletes() {
    const param = {
      exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id,
      relations: 'despesa,funcao,subfuncao,acao,programa,executora,recurso,aplicacao,aplicacao_variavel', orderBy: 'numero'
    };
    if (this.ocorrencia == 3)
      param['disponibilidade_anterior'] = true

    // autocomplete para ficha
    this.fichaAutoComplete = new EddyAutoComplete(null, this.fichaService,
      'numero', ['ficha.numero', 'ficha.despesa.codigo', 'ficha.despesa.nome'],
      param, { number: ['numero'], text: ['despesa.nome'] },
      () => {
        this.loadTotalizadores(this.entity.ficha);
      }
    );

    // autocomplete para ficha de receita
    this.fichaReceitaAutoComplete = new EddyAutoComplete(null, this.fichaReceitaService,
      'numero', ['ficha.numero', 'ficha.receita.codigo', 'ficha.receita.nome'],
      {
        exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id,
        relations: 'receita', orderBy: 'numero'
      },
      { number: ['numero'], text: ['receita.nome'] }
    );
  }

  public async loadTotalizadores(ficha: FichaDespesa) {
    this.dotacao = 0;
    if (ficha.id) {
      const exercicio: Exercicio = this.login.exercicio;

      let totalizadores: any;

      totalizadores = await this.fichaService.saldoAtualFicha(ficha.id, this.login.orgao.id, this.login.exercicio.id, this.funcaoService.converteDataSQL(new Date()), {}, true).toPromise();
      // totalizadores = await this.fichaService.totalizadores(exercicio.id, this.login.orgao.id, ficha.id).toPromise();

      if (totalizadores) {
        // totalizadores = totalizadores.content[0] ? totalizadores.content[0] : totalizadores.content;
        this.dotacao = +totalizadores.saldo_atual.toFixed(2);
        this.empenhado = (+totalizadores.total_reservado + +totalizadores.total_empenhado + +totalizadores.total_compra_pendente + +totalizadores.total_rcms_pendente);
      }
    }
  }

  public verificarExcesso(item: CreditoItem) {
    if (this.ocorrencia == 2) {
      this.visualizarExecesso = true;
      this.calcularTotal();
    } else {
      this.salvarCredito(item);
    }
  }

  public adicionarPrevisao() {
    this.entityPrevisto = new ReceitaPrevista();
    this.entityPrevisto.aux = 0;
    this.entityPrevisto.mes = this.data_credito.getUTCMonth() + 1;
    this.entityPrevisto.referencia = this.data_credito.getUTCMonth() + 1;
    this.entityPrevisto.editavel = true;
    this.entityPrevisto['salvo'] = false;
    if (!this.entity.previsoes) {
      this.entity.previsoes = new Array();
    }
    this.entity.previsoes.unshift(this.entityPrevisto);
    this.buttonDisabled = true;
  }

  public salvarPrevisao(item: ReceitaPrevista) {
    try {
      if (!item.ficha) {
        throw new Error('Informe a ficha de receita!');
      }

      if (!item.valor_previsto || +item.valor_previsto <= 0) {
        throw new Error('O valor previsto não pode ser zero')
      }
      item.editavel = false;
      item['salvo'] = true;
      this.calcularTotal();
      this.buttonDisabled = false;
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  public cancelarPrevisao(item: ReceitaPrevista, idx: number) {
    if (item['salvo'] && item.id === this.entityTempPrevisto?.id) {
      item.ficha = this.entityTempPrevisto.ficha;
      item.valor_previsto = this.entityTempPrevisto.valor_previsto;
      item.mes = this.entityTempPrevisto.mes;
      item.referencia = this.entityTempPrevisto.referencia;

      this.entityPrevisto = item;
      this.entityPrevisto.editavel = false;
    } else {
      this.entity.previsoes.splice(idx, 1);
    }
    this.buttonDisabled = false;
    this.calcularTotal();
  }

  public editarPrevisao(item: ReceitaPrevista) {
    this.entityTempPrevisto = item;
    this.entityPrevisto = item;
    this.entityPrevisto.editavel = true;
    this.entityPrevisto['salvo'] = true;
    this.fichaReceitaAutoComplete.id = this.entity.ficha ? this.entity.ficha.numero : null;
    this.buttonDisabled = true;
  }

  public calcularTotal() {
    if (this.entity.previsoes) {
      let vl = 0;
      for (const item of this.entity.previsoes) {
        vl += +item.valor_previsto;
      }
      this.totalPrevisto = +vl.toFixed(2);
    }
  }

  public salvarDados() {
    try {
      if (this.ocorrencia == 2) {
        if (+this.totalPrevisto.toFixed(2) != +this.entity.valor_credito) {
          this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Total da previsão adicional diferente do valor creditado' });
          return;
        }
        this.visualizarExecesso = false;
        if (this.entity.id) {
          $(`#btn${this.entity.id}`).click();
        } else {
          $('#btn').click();
        }
      }
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }

  }

  public confirmarRemocaoPrevisao(item: ReceitaPrevista, index: number) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja receita item?',
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.removerPrevisao(item, index)
      },
    });
  }

  public removerPrevisao(item: ReceitaPrevista, index: number) {
    if (item.id) {
      this.previstoService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.entity.previsoes.splice(index, 1);
          this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Exclusão', detail: error.error.payload });
          throw error;
        });
    } else {
      this.entity.previsoes.splice(index, 1);
    }
    this.calcularTotal();
  }

  public validarSaldoFicha(item: CreditoItem) {
    this.diarioService.saldoCronogramaPorFicha(this.login.exercicio.id, this.login.orgao.id, item.ficha.numero, this.data_credito.getUTCMonth() + 1, item?.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        const saldo = +res.saldo;
        const valor_credito = +item.valor_credito;
        this.saldo_faltante = (saldo + valor_credito);
        this.mes = +(this.funcaoService.converteDataSQL(item.data_contabilizacao)).split('-')[1];
        if (this.saldo_faltante < 0) {
          this.previsao_ficha = item.ficha;
          this.confirmationService.confirm({
            message: `Ficha não possuí saldo suficiente no mês para realizar esta anulação!\nDeseja ajustar a previsão?`,
            acceptLabel: "Sim",
            rejectLabel: "Não",
            header: 'Saldo insuficiente',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              $('#dialogAcertoPrevisaoFichaDespesa').modal('show');
            },
          });
        }
      });
  }

  public podeAlterarAudesp(data: Date): boolean {
    if (!data) {
      return true;
    }
    if (this.login['ultimoAudesp'] && data) {
      data = new Date(data);
      const mes = +this.login['ultimoAudesp'].split('-')[1];
      const ano = +this.login['ultimoAudesp'].split('-')[0];
      if ((data.getFullYear() < ano || (data.getFullYear() === ano && (+data.getMonth() + 1) <= mes)) && mes < 12)
        return false;
    }
    if (this.login['dias_bloquear_alteracoes']) {
      return new FuncaoService().diferencaEmDias(new Date(), new Date(data)) < this.login['dias_bloquear_alteracoes'];
    } else {
      return true;
    }
  }
}
