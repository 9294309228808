import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, UnidadeFornecimento } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class UnidadeFornecimentoService extends BaseResourceService<UnidadeFornecimento> {

  constructor(
    protected injector: Injector
  ) {
    super(`unidades-fornecimento`, injector);
  }

}
