import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from './orgao.model';
import { Usuario } from './usuario.model';

export class Agenda extends BaseResourceModel {
    constructor(
        public id?: number,
        public titulo?: string,
        public descricao?: string,
        public diaInteiro?: boolean,
        public inicio?: Date,
        public fim?: Date,
        public recorrente?: 'N' | 'D' | 'DS' | 'S' | 'M' | 'A',
        public corHex?: string,
        public concluido?: boolean,
        public orgao?: Orgao,
        public usuario?: Usuario,
        public origem?: Agenda,
    ) {
        super();
    }
    static converteJson(json: any): Agenda {
        return Object.assign(new Agenda(), json);
    }
}
