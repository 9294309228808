import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Page, PregaoHistorico } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PregaoHistoricoService extends BaseResourceService<PregaoHistorico> {

  constructor(
    protected injector: Injector
  ) {
    super(`pregao-historicos`, injector);
  }

  public obterPorLicitacao(id: number): Observable<Page> {
    const parametros = {
      'lance.memorial.licitacao.id': id,
      'lance.cancelado': false,
      'relations': 'lance.memorial,lance.proponente.favorecido',
      'orderBy': 'lance.memorial.ordem_julgamento,id$DESC'
    }
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar${this.converterParametrosStr(parametros)}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorItem(id: number): Observable<Page> {
    const parametros = {
      'lance.memorial.id': id,
      'lance.cancelado': false,
      'relations': 'lance.memorial,lance.proponente.favorecido',
      'orderBy': 'id$DESC'
    }
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar${this.converterParametrosStr(parametros)}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
