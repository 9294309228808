import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { LiquidacaoMovimentoEstoqueDlgComponent } from './liquidacao-movimento-estoque-dlg.component';

@NgModule({
  declarations: [LiquidacaoMovimentoEstoqueDlgComponent],
  imports: [
    CommonModule,
    SharedModule,
    ToastModule,
    FormsModule
  ],
  exports: [
    LiquidacaoMovimentoEstoqueDlgComponent
  ],
  providers: [MessageService, ConfirmationService]
})
export class LiquidacaoMovimentoEstoqueDlgModule { }
