// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';

export class Banco extends BaseResourceModel {
  constructor(
    public id?: number,
    // public codigo?: string,
    public nome?: string,
    public febraban?: string,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
  ) {
    super();
  }

  static converteJson(json: any): Banco {
    return Object.assign(new Banco(), json);
  }
}
