import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "eddydata-lib";
import { FieldsetModule } from "primeng/fieldset";
import { RadioButtonModule } from "primeng/radiobutton";
import { ToastModule } from "primeng/toast";
import { CreditoModule } from "../credito/credito.module";
import { MinutaTransferenciaRptComponent } from "./minuta-tranferencia-rpt/minuta-tranferencia-rpt.component";
import { TransferenciaInternaListComponent } from "./transferencia-interna-list/transferencia-interna-list.component";
import { TransferenciaInternaRoutingModule } from "./transferencia-interna-routing.module";
import { TransferenciaInternaRpt } from "./transferencia-interna-rpt/transferencia-interna-rpt.component";
import { IMaskModule } from "angular-imask";

@NgModule({
  declarations: [TransferenciaInternaListComponent,
    MinutaTransferenciaRptComponent,
    TransferenciaInternaRpt
  ],
  exports: [
    MinutaTransferenciaRptComponent
  ],
  imports: [
    CommonModule,
    TransferenciaInternaRoutingModule,
    CreditoModule,
    SharedModule,
    RadioButtonModule,
    FormsModule,
    FieldsetModule,
    IMaskModule,


    ToastModule,
  ],
  providers: []
})
export class TransferenciaInternaModule { }