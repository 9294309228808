import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, ProgressoService, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AnexoLRFService } from '../../service/anexo-lrf.service';

@Directive()
export class DemonstrativoReceitaDespesaPrevidenciaria implements OnDestroy {

    protected funcaoService: FuncaoService;
    protected globalService: GlobalService;
    private login: Login = new Login();
    protected unsubscribe: Subject<void> = new Subject();

    public exibir_relatorio_zerado: boolean = false;

    constructor(
        protected anexoServico: AnexoLRFService,
        protected mes: number,
        protected exercicio: Exercicio,
        protected injector: Injector,
        protected progressoService: ProgressoService) {
        this.funcaoService = new FuncaoService();
        this.globalService = new GlobalService();
        this.login = GlobalService.obterSessaoLogin();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public montarRelatorio(orgaos: number[], formato: FormatoExportacao, exibir_zerado: boolean) {
        this.exibir_relatorio_zerado = exibir_zerado;
        this.anexoServico.obterDemonstrativoReceitaDespesaPrevidencia(this.mes, this.exercicio.id, orgaos)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(async idTransacao => {
                this.progressoService.show(idTransacao, async (retorno) => {
                    if (formato === 'pdf') {
                        Relatorio.imprimirPersonalizado(`Demonstrativo de Receitas e Despesas Previdenciárias - Referência: ${this.mes}/${this.exercicio.ano}`, this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
                            this.montarConteudo(retorno),
                            'portrait', 'Demonstrativo de Receitas e Despesas Previdenciárias',
                            {
                                linhas: {
                                    hLineWidth() {
                                        return 1;
                                    },
                                    vLineWidth() {
                                        return 1;
                                    },
                                    hLineColor() {
                                        return 'black';
                                    },
                                    paddingLeft() {
                                        return 2;
                                    },
                                    paddingRight() {
                                        return 2;
                                    }
                                }
                            });
                    } else {
                        this.funcaoService.exportar(formato, this.normalizar(retorno), 'Demonstrativo de Receitas e Despesas Previdenciárias', this.colunas());
                    }
                });
            });
    }


    private montarConteudo(dados: any[]): {}[] {
        const registros: {}[] = [
            [
                { text: 'RECEITAS PREVIDENCIÁRIAS', bold: true, fontSize: 9, margin: [0, 10, 0, 0] },
                { text: 'PREVISÃO ANUAL INICIAL', bold: true, fontSize: 8 },
                { text: 'PREVISÃO ANUAL ATUALIZADA', bold: true, fontSize: 8 },
                { text: 'PREVISTAS ATÉ O BIMESTRE', bold: true, fontSize: 8 },
                { text: 'REALIZADAS ATÉ O BIMESTRE', bold: true, fontSize: 8 }
            ],
        ];

        if (this.exibir_relatorio_zerado) {

            const grupoTotalizador = this.funcaoService.agrupar(dados[0], 'tabela', ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);
            const grupoTipos = this.funcaoService.agrupar(dados[0], 'tipo', ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);
            const grupos = this.funcaoService.agrupar(dados[0], ['tipo', 'pai'], ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);

            const totalizadorVII = {
                previsao_atual: +grupoTotalizador[0].totalizadores['previsao_atual'] - +grupoTotalizador[1].totalizadores['previsao_atual'],
                previsao_inicial: +grupoTotalizador[0].totalizadores['previsao_inicial'] - +grupoTotalizador[1].totalizadores['previsao_inicial'],
                prevista_bimestre: +grupoTotalizador[0].totalizadores['prevista_bimestre'] - +grupoTotalizador[1].totalizadores['prevista_bimestre'],
                realizada_bimestre: +grupoTotalizador[0].totalizadores['realizada_bimestre'] - +grupoTotalizador[1].totalizadores['realizada_bimestre']
            }

            for (const grupo of grupoTipos) {
                registros.push([
                    { text: `${grupo.grupo}`, bold: true, fontSize: 8 },
                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 },
                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 },
                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 },
                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 }
                ]);

                const registrosFilhos = []

                grupos.forEach((grupoFIlho) => {
                    if (grupo.grupo === grupoFIlho.grupo['tipo'])
                        registrosFilhos.push(grupoFIlho)
                });

                if (registrosFilhos.length > 0) {
                    for (const filho of registrosFilhos) {
                        const semFilhos = filho.registros[0].nome === filho.grupo['pai'];

                        if (!semFilhos) {
                            //caso nao tenha o pai preenchido quer dizer que ele nao tem filhos, entrao printa apenas ele
                            if (filho.grupo['pai']) {
                                registros.push([
                                    { text: `${filho.grupo['pai']}`, margin: [6, 0, 0, 0] },
                                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right' }
                                ]);
                            }

                            for (const linha of filho.registros) {
                                registros.push([
                                    { text: `${linha.nome}`, margin: filho.grupo['pai'] ? [12, 0, 0, 0] : [6, 0, 0, 0] },
                                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right' }
                                ]);
                            }
                        }
                    }
                }
            }

            registros.push([
                { text: `TOTAL DAS RECEITAS PREVIDENCIÁRIAS (VII) = (I+II+III+V+VI) - IV`, bold: true, fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 }
            ]);

            registros.push([
                { text: '', colSpan: 5, margin: [0, 5, 0, 5], border: [false, false, false, false] },
                '', '', '', ''
            ]);

            registros.push([
                { text: 'DESPESAS PREVIDENCIÁRIAS', bold: true, fontSize: 9, margin: [0, 5, 0, 0] },
                { text: 'DOTAÇÃO INICIAL', bold: true, fontSize: 8, alignment: 'right' },
                { text: 'DOTAÇÃO ATUALIZADA', bold: true, fontSize: 8, alignment: 'right' },
                { text: 'EMPENHADAS ATÉ O BIMESTRE', bold: true, fontSize: 8, alignment: 'right' },
                { text: 'LIQUIDADAS ATÉ O BIMESTRE', bold: true, fontSize: 8, alignment: 'right' }
            ]);

            const despesas = dados[1];

            const grupoTotalizadorDespesa = this.funcaoService.agrupar(despesas, 'tabela', ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);
            const grupoTiposDespesa = this.funcaoService.agrupar(despesas, 'tipo', ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);
            const gruposDespesa = this.funcaoService.agrupar(despesas, ['tipo', 'pai'], ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);

            for (const grupo of grupoTiposDespesa) {
                registros.push([
                    { text: `${grupo.grupo}`, bold: true, fontSize: 8 },
                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 },
                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 },
                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 },
                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 }
                ]);

                const registrosFilhos = []

                gruposDespesa.forEach((grupoFIlho) => {
                    if (grupo.grupo === grupoFIlho.grupo['tipo'])
                        registrosFilhos.push(grupoFIlho)
                });

                if (registrosFilhos.length > 0) {

                    for (const filho of registrosFilhos) {
                        const semFilhos = filho.registros[0].nome === filho.grupo['pai'];

                        if (!semFilhos) {
                            //caso nao tenha o pai preenchido quer dizer que ele nao tem filhos, entrao printa apenas ele
                            if (filho.grupo['pai']) {
                                registros.push([
                                    { text: `${filho.grupo['pai']}`, margin: [6, 0, 0, 0] },
                                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right' }
                                ]);
                            }

                            for (const linha of filho.registros) {
                                registros.push([
                                    { text: `${linha.nome}`, margin: filho.grupo['pai'] ? [12, 0, 0, 0] : [6, 0, 0, 0] },
                                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right' }
                                ]);
                            };
                        }
                    }
                }
            }

            registros.push([
                { text: `TOTAL DAS DESPESAS PREVIDENCIÁRIAS (X)=(VII+VIII+IX)`, bold: true, fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 }
            ]);

            registros.push([
                { text: `RESULTADO PREVIDENCIÁRIO (XI) = (VII - X)`, bold: true, fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(0)}`, bold: true, alignment: 'right', fontSize: 9 }
            ]);

        } else {
            const grupoTotalizador = this.funcaoService.agrupar(dados[0], 'tabela', ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);
            const grupoTipos = this.funcaoService.agrupar(dados[0], 'tipo', ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);
            const grupos = this.funcaoService.agrupar(dados[0], ['tipo', 'pai'], ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);

            const totalizadorVII = {
                previsao_atual: +grupoTotalizador[0].totalizadores['previsao_atual'] - +grupoTotalizador[1].totalizadores['previsao_atual'],
                previsao_inicial: +grupoTotalizador[0].totalizadores['previsao_inicial'] - +grupoTotalizador[1].totalizadores['previsao_inicial'],
                prevista_bimestre: +grupoTotalizador[0].totalizadores['prevista_bimestre'] - +grupoTotalizador[1].totalizadores['prevista_bimestre'],
                realizada_bimestre: +grupoTotalizador[0].totalizadores['realizada_bimestre'] - +grupoTotalizador[1].totalizadores['realizada_bimestre']
            }

            for (const grupo of grupoTipos) {
                registros.push([
                    { text: `${grupo.grupo}`, bold: true, fontSize: 8 },
                    { text: `${this.funcaoService.convertToBrNumber(grupo.totalizadores['previsao_inicial'])}`, bold: true, alignment: 'right', fontSize: 9 },
                    { text: `${this.funcaoService.convertToBrNumber(grupo.totalizadores['previsao_atual'])}`, bold: true, alignment: 'right', fontSize: 9 },
                    { text: `${this.funcaoService.convertToBrNumber(grupo.totalizadores['prevista_bimestre'])}`, bold: true, alignment: 'right', fontSize: 9 },
                    { text: `${this.funcaoService.convertToBrNumber(grupo.totalizadores['realizada_bimestre'])}`, bold: true, alignment: 'right', fontSize: 9 }
                ]);

                const registrosFilhos = []

                grupos.forEach((grupoFIlho) => {
                    if (grupo.grupo === grupoFIlho.grupo['tipo'])
                        registrosFilhos.push(grupoFIlho)
                });

                if (registrosFilhos.length > 0) {
                    for (const filho of registrosFilhos) {
                        const semFilhos = filho.registros[0].nome === filho.grupo['pai'];

                        if (!semFilhos) {
                            //caso nao tenha o pai preenchido quer dizer que ele nao tem filhos, entrao printa apenas ele
                            if (filho.grupo['pai']) {
                                registros.push([
                                    { text: `${filho.grupo['pai']}`, margin: [6, 0, 0, 0] },
                                    { text: `${this.funcaoService.convertToBrNumber(filho.totalizadores['previsao_inicial'])}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(filho.totalizadores['previsao_atual'])}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(filho.totalizadores['prevista_bimestre'])}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(filho.totalizadores['realizada_bimestre'])}`, bold: true, alignment: 'right' }
                                ]);
                            }

                            for (const linha of filho.registros) {
                                registros.push([
                                    { text: `${linha.nome}`, margin: filho.grupo['pai'] ? [12, 0, 0, 0] : [6, 0, 0, 0] },
                                    { text: `${this.funcaoService.convertToBrNumber(linha['previsao_inicial'])}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(linha['previsao_atual'])}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(linha['prevista_bimestre'])}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(linha['realizada_bimestre'])}`, bold: true, alignment: 'right' }
                                ]);
                            }
                        }
                    }
                }
            }

            registros.push([
                { text: `TOTAL DAS RECEITAS PREVIDENCIÁRIAS (VII) = (I+II+III+V+VI) - IV`, bold: true, fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(totalizadorVII.previsao_inicial)}`, bold: true, alignment: 'right', fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(totalizadorVII.previsao_atual)}`, bold: true, alignment: 'right', fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(totalizadorVII.prevista_bimestre)}`, bold: true, alignment: 'right', fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(totalizadorVII.realizada_bimestre)}`, bold: true, alignment: 'right', fontSize: 9 }
            ]);

            registros.push([
                { text: '', colSpan: 5, margin: [0, 5, 0, 5], border: [false, false, false, false] },
                '', '', '', ''
            ]);

            registros.push([
                { text: 'DESPESAS PREVIDENCIÁRIAS', bold: true, fontSize: 9, margin: [0, 5, 0, 0] },
                { text: 'DOTAÇÃO INICIAL', bold: true, fontSize: 8, alignment: 'right' },
                { text: 'DOTAÇÃO ATUALIZADA', bold: true, fontSize: 8, alignment: 'right' },
                { text: 'EMPENHADAS ATÉ O BIMESTRE', bold: true, fontSize: 8, alignment: 'right' },
                { text: 'LIQUIDADAS ATÉ O BIMESTRE', bold: true, fontSize: 8, alignment: 'right' }
            ]);

            const despesas = dados[1];

            const grupoTotalizadorDespesa = this.funcaoService.agrupar(despesas, 'tabela', ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);
            const grupoTiposDespesa = this.funcaoService.agrupar(despesas, 'tipo', ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);
            const gruposDespesa = this.funcaoService.agrupar(despesas, ['tipo', 'pai'], ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);

            for (const grupo of grupoTiposDespesa) {
                registros.push([
                    { text: `${grupo.grupo}`, bold: true, fontSize: 8 },
                    { text: `${this.funcaoService.convertToBrNumber(grupo.totalizadores['previsao_inicial'])}`, bold: true, alignment: 'right', fontSize: 9 },
                    { text: `${this.funcaoService.convertToBrNumber(grupo.totalizadores['previsao_atual'])}`, bold: true, alignment: 'right', fontSize: 9 },
                    { text: `${this.funcaoService.convertToBrNumber(grupo.totalizadores['prevista_bimestre'])}`, bold: true, alignment: 'right', fontSize: 9 },
                    { text: `${this.funcaoService.convertToBrNumber(grupo.totalizadores['realizada_bimestre'])}`, bold: true, alignment: 'right', fontSize: 9 }
                ]);

                const registrosFilhos = []

                gruposDespesa.forEach((grupoFIlho) => {
                    if (grupo.grupo === grupoFIlho.grupo['tipo'])
                        registrosFilhos.push(grupoFIlho)
                });

                if (registrosFilhos.length > 0) {

                    for (const filho of registrosFilhos) {
                        const semFilhos = filho.registros[0].nome === filho.grupo['pai'];

                        if (!semFilhos) {
                            //caso nao tenha o pai preenchido quer dizer que ele nao tem filhos, entrao printa apenas ele
                            if (filho.grupo['pai']) {
                                registros.push([
                                    { text: `${filho.grupo['pai']}`, margin: [6, 0, 0, 0] },
                                    { text: `${this.funcaoService.convertToBrNumber(filho.totalizadores['previsao_inicial'])}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(filho.totalizadores['previsao_atual'])}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(filho.totalizadores['prevista_bimestre'])}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(filho.totalizadores['realizada_bimestre'])}`, bold: true, alignment: 'right' }
                                ]);
                            }

                            for (const linha of filho.registros) {
                                registros.push([
                                    { text: `${linha.nome}`, margin: filho.grupo['pai'] ? [12, 0, 0, 0] : [6, 0, 0, 0] },
                                    { text: `${this.funcaoService.convertToBrNumber(linha['previsao_inicial'])}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(linha['previsao_atual'])}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(linha['prevista_bimestre'])}`, bold: true, alignment: 'right' },
                                    { text: `${this.funcaoService.convertToBrNumber(linha['realizada_bimestre'])}`, bold: true, alignment: 'right' }
                                ]);
                            }
                        }
                    }
                }
            }

            registros.push([
                { text: `TOTAL DAS DESPESAS PREVIDENCIÁRIAS (X)=(VII+VIII+IX)`, bold: true, fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(grupoTotalizadorDespesa[0].totalizadores['previsao_inicial'])}`, bold: true, alignment: 'right', fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(grupoTotalizadorDespesa[0].totalizadores['previsao_atual'])}`, bold: true, alignment: 'right', fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(grupoTotalizadorDespesa[0].totalizadores['prevista_bimestre'])}`, bold: true, alignment: 'right', fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(grupoTotalizadorDespesa[0].totalizadores['realizada_bimestre'])}`, bold: true, alignment: 'right', fontSize: 9 }
            ]);

            registros.push([
                { text: `RESULTADO PREVIDENCIÁRIO (XI) = (VII - X)`, bold: true, fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(+totalizadorVII.previsao_inicial - grupoTotalizadorDespesa[0].totalizadores['previsao_inicial'])}`, bold: true, alignment: 'right', fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(+totalizadorVII.previsao_atual - grupoTotalizadorDespesa[0].totalizadores['previsao_atual'])}`, bold: true, alignment: 'right', fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(+totalizadorVII.prevista_bimestre - grupoTotalizadorDespesa[0].totalizadores['prevista_bimestre'])}`, bold: true, alignment: 'right', fontSize: 9 },
                { text: `${this.funcaoService.convertToBrNumber(+totalizadorVII.realizada_bimestre - grupoTotalizadorDespesa[0].totalizadores['realizada_bimestre'])}`, bold: true, alignment: 'right', fontSize: 9 }
            ]);
        }

        return [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                headerRows: 0,
                widths: [245, 65, 65, 65, 65],
                body: registros
            }
        }];
    }

    public normalizar(lista: any[]) {
        const listaExportar = []
        let previsao_atual = 0;
        let previsao_inicial = 0;
        let prevista_bimestre = 0;
        let realizada_bimestre = 0;

        let nomeFilho = '';

        if (this.exibir_relatorio_zerado) {
            const grupoTotalizador = this.funcaoService.agrupar(lista[0], 'tabela', ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);
            const grupoTipos = this.funcaoService.agrupar(lista[0], 'tipo', ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);
            const grupos = this.funcaoService.agrupar(lista[0], ['tipo', 'pai'], ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);


            for (const grupo of grupoTipos) {
                const primeira_linha = {
                    receitas_previdenciarias: grupo.grupo,
                    previsao_anual_inicial: this.funcaoService.convertToBrNumber(0),
                    previsao_anual_atualizada: this.funcaoService.convertToBrNumber(0),
                    prevista_bimestre: this.funcaoService.convertToBrNumber(0),
                    realizada_bimestre: this.funcaoService.convertToBrNumber(0),
                }
                listaExportar.push(primeira_linha)

                const registrosFilhos = []

                grupos.forEach((grupoFIlho) => {
                    if (grupo.grupo === grupoFIlho.grupo['tipo'])
                        registrosFilhos.push(grupoFIlho)
                });

                if (registrosFilhos.length > 0) {
                    for (const filho of registrosFilhos) {
                        nomeFilho = filho.grupo['pai'];

                        const semFilhos = filho.registros[0].nome === nomeFilho;

                        if (!semFilhos) {
                            if (filho.grupo['pai']) {
                                const linha_filho = {
                                    receitas_previdenciarias: nomeFilho,
                                    previsao_anual_inicial: this.funcaoService.convertToBrNumber(0),
                                    previsao_anual_atualizada: this.funcaoService.convertToBrNumber(0),
                                    prevista_bimestre: this.funcaoService.convertToBrNumber(0),
                                    realizada_bimestre: this.funcaoService.convertToBrNumber(0),
                                }
                                listaExportar.push(linha_filho)
                            }

                            for (const linha of filho.registros) {
                                const itens_filho = {
                                    receitas_previdenciarias: linha.nome,
                                    previsao_anual_inicial: this.funcaoService.convertToBrNumber(0),
                                    previsao_anual_atualizada: this.funcaoService.convertToBrNumber(0),
                                    prevista_bimestre: this.funcaoService.convertToBrNumber(0),
                                    realizada_bimestre: this.funcaoService.convertToBrNumber(0),
                                }
                                listaExportar.push(itens_filho)
                            }
                        }
                    }
                }
            }

            const total_receitas = {
                receitas_previdenciarias: 'TOTAL DAS RECEITAS PREVIDENCIÁRIAS (VII) = (I+II+III+V+VI) - IV',
                previsao_anual_inicial: this.funcaoService.convertToBrNumber(0),
                previsao_anual_atualizada: this.funcaoService.convertToBrNumber(0),
                prevista_bimestre: this.funcaoService.convertToBrNumber(0),
                realizada_bimestre: this.funcaoService.convertToBrNumber(0),
            }
            listaExportar.push(total_receitas)

            const espaco = {
                receitas_previdenciarias: '',
                previsao_anual_inicial: '',
                previsao_anual_atualizada: '',
                prevista_bimestre: '',
                realizada_bimestre: '',
            }
            listaExportar.push(espaco)

            const despesas_previdenciarias = {
                receitas_previdenciarias: 'DESPESAS PREVIDENCIÁRIAS',
                previsao_anual_inicial: 'DOTAÇÃO INICIAL',
                previsao_anual_atualizada: 'DOTAÇÃO ATUALIZADA',
                prevista_bimestre: 'EMPENHADAS ATÉ O BIMESTRE',
                realizada_bimestre: 'LIQUIDADAS ATÉ O BIMESTRE',
            }
            listaExportar.push(despesas_previdenciarias)

            const despesas = lista[1];

            const grupoTotalizadorDespesa = this.funcaoService.agrupar(despesas, 'tabela', ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);
            const grupoTiposDespesa = this.funcaoService.agrupar(despesas, 'tipo', ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);
            const gruposDespesa = this.funcaoService.agrupar(despesas, ['tipo', 'pai'], ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);

            for (const grupo of grupoTiposDespesa) {

                const primeira_linha = {
                    receitas_previdenciarias: grupo.grupo,
                    previsao_anual_inicial: this.funcaoService.convertToBrNumber(0),
                    previsao_anual_atualizada: this.funcaoService.convertToBrNumber(0),
                    prevista_bimestre: this.funcaoService.convertToBrNumber(0),
                    realizada_bimestre: this.funcaoService.convertToBrNumber(0),
                }
                listaExportar.push(primeira_linha)

                const registrosFilhos = []

                gruposDespesa.forEach((grupoFIlho) => {
                    if (grupo.grupo === grupoFIlho.grupo['tipo'])
                        registrosFilhos.push(grupoFIlho)
                });

                if (registrosFilhos.length > 0) {
                    for (const filho of registrosFilhos) {
                        nomeFilho = filho.grupo['pai'];

                        const semFilhos = filho.registros[0].nome === nomeFilho;

                        if (!semFilhos) {
                            if (filho.grupo['pai']) {
                                const linha_filho = {
                                    receitas_previdenciarias: nomeFilho,
                                    previsao_anual_inicial: this.funcaoService.convertToBrNumber(0),
                                    previsao_anual_atualizada: this.funcaoService.convertToBrNumber(0),
                                    prevista_bimestre: this.funcaoService.convertToBrNumber(0),
                                    realizada_bimestre: this.funcaoService.convertToBrNumber(0),
                                }
                                listaExportar.push(linha_filho)
                            }

                            for (const linha of filho.registros) {
                                const itens_filho = {
                                    receitas_previdenciarias: linha.nome,
                                    previsao_anual_inicial: this.funcaoService.convertToBrNumber(0),
                                    previsao_anual_atualizada: this.funcaoService.convertToBrNumber(0),
                                    prevista_bimestre: this.funcaoService.convertToBrNumber(0),
                                    realizada_bimestre: this.funcaoService.convertToBrNumber(0),
                                }
                                listaExportar.push(itens_filho)
                            }
                        }
                    }
                }
            }

            const total_despesas = {
                receitas_previdenciarias: 'TOTAL DAS RECEITAS PREVIDENCIÁRIAS (VII) = (I+II+III+V+VI) - IV',
                previsao_anual_inicial: `${this.funcaoService.convertToBrNumber(0)}`,
                previsao_anual_atualizada: `${this.funcaoService.convertToBrNumber(0)}`,
                prevista_bimestre: `${this.funcaoService.convertToBrNumber(0)}`,
                realizada_bimestre: `${this.funcaoService.convertToBrNumber(0)}`,
            }
            listaExportar.push(total_despesas)

            const resultado_previdenciario = {
                receitas_previdenciarias: 'RESULTADO PREVIDENCIÁRIO (XI) = (VII - X)',
                previsao_anual_inicial: `${this.funcaoService.convertToBrNumber(0)}`,
                previsao_anual_atualizada: `${this.funcaoService.convertToBrNumber(0)}`,
                prevista_bimestre: `${this.funcaoService.convertToBrNumber(0)}`,
                realizada_bimestre: `${this.funcaoService.convertToBrNumber(0)}`,
            }
            listaExportar.push(resultado_previdenciario)

        } else {

            const grupoTotalizador = this.funcaoService.agrupar(lista[0], 'tabela', ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);
            const grupoTipos = this.funcaoService.agrupar(lista[0], 'tipo', ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);
            const grupos = this.funcaoService.agrupar(lista[0], ['tipo', 'pai'], ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);

            const totalizadorVII = {
                previsao_atual: +grupoTotalizador[0].totalizadores['previsao_atual'] - +grupoTotalizador[1].totalizadores['previsao_atual'],
                previsao_inicial: +grupoTotalizador[0].totalizadores['previsao_inicial'] - +grupoTotalizador[1].totalizadores['previsao_inicial'],
                prevista_bimestre: +grupoTotalizador[0].totalizadores['prevista_bimestre'] - +grupoTotalizador[1].totalizadores['prevista_bimestre'],
                realizada_bimestre: +grupoTotalizador[0].totalizadores['realizada_bimestre'] - +grupoTotalizador[1].totalizadores['realizada_bimestre']
            }

            for (const grupo of grupoTipos) {
                previsao_atual = grupo.totalizadores['previsao_atual'];
                previsao_inicial = grupo.totalizadores['previsao_inicial'];
                prevista_bimestre = grupo.totalizadores['prevista_bimestre'];
                realizada_bimestre = grupo.totalizadores['realizada_bimestre'];

                const primeira_linha = {
                    receitas_previdenciarias: grupo.grupo,
                    previsao_anual_inicial: this.funcaoService.convertToBrNumber(previsao_atual),
                    previsao_anual_atualizada: this.funcaoService.convertToBrNumber(previsao_inicial),
                    prevista_bimestre: this.funcaoService.convertToBrNumber(prevista_bimestre),
                    realizada_bimestre: this.funcaoService.convertToBrNumber(realizada_bimestre),
                }
                listaExportar.push(primeira_linha)

                const registrosFilhos = []

                grupos.forEach((grupoFIlho) => {
                    if (grupo.grupo === grupoFIlho.grupo['tipo'])
                        registrosFilhos.push(grupoFIlho)
                });

                if (registrosFilhos.length > 0) {
                    for (const filho of registrosFilhos) {
                        previsao_atual = filho.totalizadores['previsao_atual'];
                        previsao_inicial = filho.totalizadores['previsao_inicial'];
                        prevista_bimestre = filho.totalizadores['prevista_bimestre'];
                        realizada_bimestre = filho.totalizadores['realizada_bimestre'];

                        nomeFilho = filho.grupo['pai'];

                        const semFilhos = filho.registros[0].nome === nomeFilho;

                        if (!semFilhos) {
                            if (filho.grupo['pai']) {
                                const linha_filho = {
                                    receitas_previdenciarias: nomeFilho,
                                    previsao_anual_inicial: this.funcaoService.convertToBrNumber(previsao_atual),
                                    previsao_anual_atualizada: this.funcaoService.convertToBrNumber(previsao_inicial),
                                    prevista_bimestre: this.funcaoService.convertToBrNumber(prevista_bimestre),
                                    realizada_bimestre: this.funcaoService.convertToBrNumber(realizada_bimestre),
                                }
                                listaExportar.push(linha_filho)
                            }

                            for (const linha of filho.registros) {
                                const itens_filho = {
                                    receitas_previdenciarias: linha.nome,
                                    previsao_anual_inicial: this.funcaoService.convertToBrNumber(linha.previsao_atual),
                                    previsao_anual_atualizada: this.funcaoService.convertToBrNumber(linha.previsao_inicial),
                                    prevista_bimestre: this.funcaoService.convertToBrNumber(linha.prevista_bimestre),
                                    realizada_bimestre: this.funcaoService.convertToBrNumber(linha.realizada_bimestre),
                                }
                                listaExportar.push(itens_filho)
                            }
                        }
                    }
                }
            }

            const total_receitas = {
                receitas_previdenciarias: 'TOTAL DAS RECEITAS PREVIDENCIÁRIAS (VII) = (I+II+III+V+VI) - IV',
                previsao_anual_inicial: this.funcaoService.convertToBrNumber(totalizadorVII.previsao_atual),
                previsao_anual_atualizada: this.funcaoService.convertToBrNumber(totalizadorVII.previsao_inicial),
                prevista_bimestre: this.funcaoService.convertToBrNumber(totalizadorVII.prevista_bimestre),
                realizada_bimestre: this.funcaoService.convertToBrNumber(totalizadorVII.realizada_bimestre),
            }
            listaExportar.push(total_receitas)

            const espaco = {
                receitas_previdenciarias: '',
                previsao_anual_inicial: '',
                previsao_anual_atualizada: '',
                prevista_bimestre: '',
                realizada_bimestre: '',
            }
            listaExportar.push(espaco)

            const despesas_previdenciarias = {
                receitas_previdenciarias: 'DESPESAS PREVIDENCIÁRIAS',
                previsao_anual_inicial: 'DOTAÇÃO INICIAL',
                previsao_anual_atualizada: 'DOTAÇÃO ATUALIZADA',
                prevista_bimestre: 'EMPENHADAS ATÉ O BIMESTRE',
                realizada_bimestre: 'LIQUIDADAS ATÉ O BIMESTRE',
            }
            listaExportar.push(despesas_previdenciarias)

            const despesas = lista[1];

            const grupoTotalizadorDespesa = this.funcaoService.agrupar(despesas, 'tabela', ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);
            const grupoTiposDespesa = this.funcaoService.agrupar(despesas, 'tipo', ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);
            const gruposDespesa = this.funcaoService.agrupar(despesas, ['tipo', 'pai'], ['previsao_atual', 'previsao_inicial', 'prevista_bimestre', 'realizada_bimestre']);

            for (const grupo of grupoTiposDespesa) {
                previsao_atual = grupo.totalizadores['previsao_atual'];
                previsao_inicial = grupo.totalizadores['previsao_inicial'];
                prevista_bimestre = grupo.totalizadores['prevista_bimestre'];
                realizada_bimestre = grupo.totalizadores['realizada_bimestre'];

                const primeira_linha = {
                    receitas_previdenciarias: grupo.grupo,
                    previsao_anual_inicial: this.funcaoService.convertToBrNumber(previsao_atual),
                    previsao_anual_atualizada: this.funcaoService.convertToBrNumber(previsao_inicial),
                    prevista_bimestre: this.funcaoService.convertToBrNumber(prevista_bimestre),
                    realizada_bimestre: this.funcaoService.convertToBrNumber(realizada_bimestre),
                }
                listaExportar.push(primeira_linha)

                const registrosFilhos = []

                gruposDespesa.forEach((grupoFIlho) => {
                    if (grupo.grupo === grupoFIlho.grupo['tipo'])
                        registrosFilhos.push(grupoFIlho)
                });

                if (registrosFilhos.length > 0) {
                    for (const filho of registrosFilhos) {
                        previsao_atual = filho.totalizadores['previsao_atual'];
                        previsao_inicial = filho.totalizadores['previsao_inicial'];
                        prevista_bimestre = filho.totalizadores['prevista_bimestre'];
                        realizada_bimestre = filho.totalizadores['realizada_bimestre'];

                        nomeFilho = filho.grupo['pai'];

                        const semFilhos = filho.registros[0].nome === nomeFilho;

                        if (!semFilhos) {
                            if (filho.grupo['pai']) {
                                const linha_filho = {
                                    receitas_previdenciarias: nomeFilho,
                                    previsao_anual_inicial: this.funcaoService.convertToBrNumber(previsao_atual),
                                    previsao_anual_atualizada: this.funcaoService.convertToBrNumber(previsao_inicial),
                                    prevista_bimestre: this.funcaoService.convertToBrNumber(prevista_bimestre),
                                    realizada_bimestre: this.funcaoService.convertToBrNumber(realizada_bimestre),
                                }
                                listaExportar.push(linha_filho)
                            }

                            for (const linha of filho.registros) {
                                const itens_filho = {
                                    receitas_previdenciarias: linha.nome,
                                    previsao_anual_inicial: this.funcaoService.convertToBrNumber(linha.previsao_atual),
                                    previsao_anual_atualizada: this.funcaoService.convertToBrNumber(linha.previsao_inicial),
                                    prevista_bimestre: this.funcaoService.convertToBrNumber(linha.prevista_bimestre),
                                    realizada_bimestre: this.funcaoService.convertToBrNumber(linha.realizada_bimestre),
                                }
                                listaExportar.push(itens_filho)
                            }
                        }
                    }
                }
            }

            const total_despesas = {
                receitas_previdenciarias: 'TOTAL DAS RECEITAS PREVIDENCIÁRIAS (VII) = (I+II+III+V+VI) - IV',
                previsao_anual_inicial: `${this.funcaoService.convertToBrNumber(grupoTotalizadorDespesa[0].totalizadores['previsao_inicial'])}`,
                previsao_anual_atualizada: `${this.funcaoService.convertToBrNumber(grupoTotalizadorDespesa[0].totalizadores['previsao_atual'])}`,
                prevista_bimestre: `${this.funcaoService.convertToBrNumber(grupoTotalizadorDespesa[0].totalizadores['prevista_bimestre'])}`,
                realizada_bimestre: `${this.funcaoService.convertToBrNumber(grupoTotalizadorDespesa[0].totalizadores['realizada_bimestre'])}`,
            }
            listaExportar.push(total_despesas)

            const resultado_previdenciario = {
                receitas_previdenciarias: 'RESULTADO PREVIDENCIÁRIO (XI) = (VII - X)',
                previsao_anual_inicial: `${this.funcaoService.convertToBrNumber(+totalizadorVII.previsao_inicial - grupoTotalizadorDespesa[0].totalizadores['previsao_inicial'])}`,
                previsao_anual_atualizada: `${this.funcaoService.convertToBrNumber(+totalizadorVII.previsao_atual - grupoTotalizadorDespesa[0].totalizadores['previsao_atual'])}`,
                prevista_bimestre: `${this.funcaoService.convertToBrNumber(+totalizadorVII.prevista_bimestre - grupoTotalizadorDespesa[0].totalizadores['prevista_bimestre'])}`,
                realizada_bimestre: `${this.funcaoService.convertToBrNumber(+totalizadorVII.realizada_bimestre - grupoTotalizadorDespesa[0].totalizadores['realizada_bimestre'])}`,
            }
            listaExportar.push(resultado_previdenciario)
        }
        return listaExportar
    }

    colunas(): Coluna[] {
        const colunasDefault: Coluna[] = [
            { titulo: 'RECEITAS PREVIDENCIÁRIAS', coluna: 'receitas_previdenciarias', bold: true },
            { titulo: 'PREVISÃO ANUAL INICIAL', coluna: 'previsao_anual_inicial' },
            { titulo: 'PREVISÃO ANUAL ATUALIZADA / ANULAÇÕES', coluna: 'previsao_anual_atualizada' },
            { titulo: 'PREVISTAS ATÉ O BIMESTRE', coluna: 'prevista_bimestre' },
            { titulo: 'REALIZADAS ATÉ O BIMESTRE', coluna: 'realizada_bimestre' },
        ];
        return colunasDefault;
    }
}
