import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { PessoaModule, SharedModule } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { LicitacaoAudespAjusteArquivosComponent } from './ajuste/licitacao-audesp-ajuste-arquivos/licitacao-audesp-ajuste-arquivos.component';
import { LicitacaoAudespAjusteListComponent } from './ajuste/licitacao-audesp-ajuste-list/licitacao-audesp-ajuste-list.component';
import { LicitacaoAudespAjusteComponent } from './ajuste/licitacao-audesp-ajuste/licitacao-audesp-ajuste.component';
import { LicitacaoAudespFormAjusteComponent } from './ajuste/licitacao-audesp-form-ajuste/licitacao-audesp-form-ajuste.component';
import { LicitacaoAudespTermoCienciaComponent } from './ajuste/licitacao-audesp-termo-ciencia/licitacao-audesp-termo-ciencia.component';
import { LicitacaoAudespDocumentoFiscalArrayComponent } from './documento-fiscal/licitacao-audesp-documento-fiscal-array/licitacao-audesp-documento-fiscal-array.component';
import { LicitacaoAudespDocumentoFiscalListComponent } from './documento-fiscal/licitacao-audesp-documento-fiscal-list/licitacao-audesp-documento-fiscal-list.component';
import { LicitacaoAudespDocumentoFiscalComponent } from './documento-fiscal/licitacao-audesp-documento-fiscal/licitacao-audesp-documento-fiscal.component';
import { LicitacaoAudespFormDocumentoFiscalComponent } from './documento-fiscal/licitacao-audesp-form-documento-fiscal/licitacao-audesp-form-documento-fiscal.component';
import { LicitacaoAudespEmpenhoArrayComponent } from './empenho/licitacao-audesp-empenho-array/licitacao-audesp-empenho-array.component';
import { LicitacaoAudespEmpenhoListComponent } from './empenho/licitacao-audesp-empenho-list/licitacao-audesp-empenho-list.component';
import { LicitacaoAudespEmpenhoComponent } from './empenho/licitacao-audesp-empenho/licitacao-audesp-empenho.component';
import { LicitacaoAudespFormEmpenhoComponent } from './empenho/licitacao-audesp-form-empenho/licitacao-audesp-form-empenho.component';
import { LicitacaoAudespExecucaoListComponent } from './execucao/licitacao-audesp-execucao-list/licitacao-audesp-execucao-list.component';
import { LicitacaoAudespExecucaoComponent } from './execucao/licitacao-audesp-execucao/licitacao-audesp-execucao.component';
import { LicitacaoAudespFormExecucaoComponent } from './execucao/licitacao-audesp-form-execucao/licitacao-audesp-form-execucao.component';
import { LicitacaoAudespLiquidacaoComponent } from './execucao/licitacao-audesp-liquidacao/licitacao-audesp-liquidacao.component';
import { LicitacaoAudespResponsavelComponent } from './execucao/licitacao-audesp-responsavel/licitacao-audesp-responsavel.component';
import { LicitacaoAudespExigenciaObrasListComponent } from './exigencia-obras/licitacao-audesp-exigencia-obras-list/licitacao-audesp-exigencia-obras-list.component';
import { LicitacaoAudespExigenciaObrasComponent } from './exigencia-obras/licitacao-audesp-exigencia-obras/licitacao-audesp-exigencia-obras.component';
import { LicitacaoAudespFormExigenciaObrasComponent } from './exigencia-obras/licitacao-audesp-form-exigencia-obras/licitacao-audesp-form-exigencia-obras.component';
import { LicitacaoAudespLicencaAmbientalComponent } from './exigencia-obras/licitacao-audesp-licenca-ambiental/licitacao-audesp-licenca-ambiental.component';
import { LicitacaoAudespListComponent } from './licitacao-audesp-list/licitacao-audesp-list.component';
import { LicitacaoAudespPacoteComponent } from './licitacao-audesp-pacote/licitacao-audesp-pacote.component';
import { LicitacaoAudespRoutingModule } from './licitacao-audesp-routing.module';
import { LicitacaoAudespFormPagamentoComponent } from './pagamento/licitacao-audesp-form-pagamento/licitacao-audesp-form-pagamento.component';
import { LicitacaoAudespPagamentoArrayComponent } from './pagamento/licitacao-audesp-pagamento-array/licitacao-audesp-pagamento-array.component';
import { LicitacaoAudespPagamentoListComponent } from './pagamento/licitacao-audesp-pagamento-list/licitacao-audesp-pagamento-list.component';
import { LicitacaoAudespPagamentoComponent } from './pagamento/licitacao-audesp-pagamento/licitacao-audesp-pagamento.component';
import { LicitacaoAudespAmostraComponent } from './registro-preco/licitacao-audesp-amostra/licitacao-audesp-amostra.component';
import { LicitacaoAudespAtestadoComponent } from './registro-preco/licitacao-audesp-atestado/licitacao-audesp-atestado.component';
import { LicitacaoAudespAutorizacaoComponent } from './registro-preco/licitacao-audesp-autorizacao/licitacao-audesp-autorizacao.component';
import { LicitacaoAudespComissaoDesignacaoComponent } from './registro-preco/licitacao-audesp-comissao-designacao/licitacao-audesp-comissao-designacao.component';
import { LicitacaoAudespComissaoMembroComponent } from './registro-preco/licitacao-audesp-comissao-membro/licitacao-audesp-comissao-membro.component';
import { LicitacaoAudespComissaoComponent } from './registro-preco/licitacao-audesp-comissao/licitacao-audesp-comissao.component';
import { LicitacaoAudespConvenioEstComponent } from './registro-preco/licitacao-audesp-convenio-est/licitacao-audesp-convenio-est.component';
import { LicitacaoAudespConvenioFedComponent } from './registro-preco/licitacao-audesp-convenio-fed/licitacao-audesp-convenio-fed.component';
import { LicitacaoAudespDeclaracaoRecursoComponent } from './registro-preco/licitacao-audesp-declaracao-recurso/licitacao-audesp-declaracao-recurso.component';
import { LicitacaoAudespEditalFase2Component } from './registro-preco/licitacao-audesp-edital-fase2/licitacao-audesp-edital-fase2.component';
import { LicitacaoAudespEditalComponent } from './registro-preco/licitacao-audesp-edital/licitacao-audesp-edital.component';
import { LicitacaoAudespFinanciamentoComponent } from './registro-preco/licitacao-audesp-financiamento/licitacao-audesp-financiamento.component';
import { LicitacaoAudespFormRegistroPrecoComponent } from './registro-preco/licitacao-audesp-form-registro-preco/licitacao-audesp-form-registro-preco.component';
import { LicitacaoAudespIndiceComponent } from './registro-preco/licitacao-audesp-indice/licitacao-audesp-indice.component';
import { LicitacaoAudespJulgamentoInversaoComponent } from './registro-preco/licitacao-audesp-julgamento-inversao/licitacao-audesp-julgamento-inversao.component';
import { LicitacaoAudespJulgamentoRecebimentoComponent } from './registro-preco/licitacao-audesp-julgamento-recebimento/licitacao-audesp-julgamento-recebimento.component';
import { LicitacaoAudespJulgamentoComponent } from './registro-preco/licitacao-audesp-julgamento/licitacao-audesp-julgamento.component';
import { LicitacaoAudespLicitanteCNPJComponent } from './registro-preco/licitacao-audesp-licitante-cnpj/licitacao-audesp-licitante-cnpj.component';
import { LicitacaoAudespLicitanteCPFComponent } from './registro-preco/licitacao-audesp-licitante-cpf/licitacao-audesp-licitante-cpf.component';
import { LicitacaoAudespLicitanteEstrangeiroComponent } from './registro-preco/licitacao-audesp-licitante-estrangeiro/licitacao-audesp-licitante-estrangeiro.component';
import { LicitacaoAudespLicitanteItemComponent } from './registro-preco/licitacao-audesp-licitante-item/licitacao-audesp-licitante-item.component';
import { LicitacaoAudespLicitanteComponent } from './registro-preco/licitacao-audesp-licitante/licitacao-audesp-licitante.component';
import { LicitacaoAudespLoteEngLocComponent } from './registro-preco/licitacao-audesp-lote-eng-loc/licitacao-audesp-lote-eng-loc.component';
import { LicitacaoAudespLoteEngTipoComponent } from './registro-preco/licitacao-audesp-lote-eng-tipo/licitacao-audesp-lote-eng-tipo.component';
import { LicitacaoAudespLoteEngComponent } from './registro-preco/licitacao-audesp-lote-eng/licitacao-audesp-lote-eng.component';
import { LicitacaoAudespLoteItemOrcComponent } from './registro-preco/licitacao-audesp-lote-item-orc/licitacao-audesp-lote-item-orc.component';
import { LicitacaoAudespLoteItemComponent } from './registro-preco/licitacao-audesp-lote-item/licitacao-audesp-lote-item.component';
import { LicitacaoAudespLoteOrcComponent } from './registro-preco/licitacao-audesp-lote-orc/licitacao-audesp-lote-orc.component';
import { LicitacaoAudespLoteComponent } from './registro-preco/licitacao-audesp-lote/licitacao-audesp-lote.component';
import { LicitacaoAudespLRFComponent } from './registro-preco/licitacao-audesp-lrf/licitacao-audesp-lrf.component';
import { LicitacaoAudespParecerComponent } from './registro-preco/licitacao-audesp-parecer/licitacao-audesp-parecer.component';
import { LicitacaoAudespPrequalificacaoComponent } from './registro-preco/licitacao-audesp-prequalificacao/licitacao-audesp-prequalificacao.component';
import { LicitacaoAudespPublicacaoComponent } from './registro-preco/licitacao-audesp-publicacao/licitacao-audesp-publicacao.component';
import { LicitacaoAudespRecursoComponent } from './registro-preco/licitacao-audesp-recurso/licitacao-audesp-recurso.component';
import { LicitacaoAudespRegistroPrecoComponent } from './registro-preco/licitacao-audesp-registro-preco/licitacao-audesp-registro-preco.component';
import { LicitacaoAudespVisitaComponent } from './registro-preco/licitacao-audesp-visita/licitacao-audesp-visita.component';
import { LicitacaoAudespFormTermoAditivoComponent } from './termo-aditivo/licitacao-audesp-form-termo-aditivo/licitacao-audesp-form-termo-aditivo.component';
import { LicitacaoAudespTermoAditivoListComponent } from './termo-aditivo/licitacao-audesp-termo-aditivo-list/licitacao-audesp-termo-aditivo-list.component';
import { LicitacaoAudespTermoAditivoComponent } from './termo-aditivo/licitacao-audesp-termo-aditivo/licitacao-audesp-termo-aditivo.component';

@NgModule({
  declarations: [
    LicitacaoAudespAjusteComponent,
    LicitacaoAudespAjusteArquivosComponent,
    LicitacaoAudespAjusteListComponent,
    LicitacaoAudespAmostraComponent,
    LicitacaoAudespAtestadoComponent,
    LicitacaoAudespAutorizacaoComponent,
    LicitacaoAudespComissaoComponent,
    LicitacaoAudespComissaoDesignacaoComponent,
    LicitacaoAudespComissaoMembroComponent,
    LicitacaoAudespConvenioEstComponent,
    LicitacaoAudespConvenioFedComponent,
    LicitacaoAudespDeclaracaoRecursoComponent,
    LicitacaoAudespDocumentoFiscalComponent,
    LicitacaoAudespDocumentoFiscalArrayComponent,
    LicitacaoAudespDocumentoFiscalListComponent,
    LicitacaoAudespPagamentoComponent,
    LicitacaoAudespPagamentoArrayComponent,
    LicitacaoAudespPagamentoListComponent,
    LicitacaoAudespEmpenhoListComponent,
    LicitacaoAudespEditalComponent,
    LicitacaoAudespEditalFase2Component,
    LicitacaoAudespEmpenhoComponent,
    LicitacaoAudespEmpenhoArrayComponent,
    LicitacaoAudespExecucaoComponent,
    LicitacaoAudespExecucaoListComponent,
    LicitacaoAudespExigenciaObrasComponent,
    LicitacaoAudespExigenciaObrasListComponent,
    LicitacaoAudespFinanciamentoComponent,
    LicitacaoAudespIndiceComponent,
    LicitacaoAudespJulgamentoComponent,
    LicitacaoAudespJulgamentoInversaoComponent,
    LicitacaoAudespJulgamentoRecebimentoComponent,
    LicitacaoAudespFormAjusteComponent,
    LicitacaoAudespFormDocumentoFiscalComponent,
    LicitacaoAudespFormEmpenhoComponent,
    LicitacaoAudespFormExecucaoComponent,
    LicitacaoAudespFormExigenciaObrasComponent,
    LicitacaoAudespFormPagamentoComponent,
    LicitacaoAudespFormRegistroPrecoComponent,
    LicitacaoAudespFormTermoAditivoComponent,
    LicitacaoAudespLicencaAmbientalComponent,
    LicitacaoAudespLicitanteCNPJComponent,
    LicitacaoAudespLicitanteCPFComponent,
    LicitacaoAudespLicitanteEstrangeiroComponent,
    LicitacaoAudespLicitanteItemComponent,
    LicitacaoAudespLicitanteComponent,
    LicitacaoAudespLiquidacaoComponent,
    LicitacaoAudespListComponent,
    LicitacaoAudespLoteEngLocComponent,
    LicitacaoAudespLoteEngTipoComponent,
    LicitacaoAudespLoteEngComponent,
    LicitacaoAudespLoteItemOrcComponent,
    LicitacaoAudespLoteItemComponent,
    LicitacaoAudespLoteOrcComponent,
    LicitacaoAudespLoteComponent,
    LicitacaoAudespLRFComponent,
    LicitacaoAudespPacoteComponent,
    LicitacaoAudespParecerComponent,
    LicitacaoAudespPrequalificacaoComponent,
    LicitacaoAudespPublicacaoComponent,
    LicitacaoAudespRecursoComponent,
    LicitacaoAudespRegistroPrecoComponent,
    LicitacaoAudespResponsavelComponent,
    LicitacaoAudespTermoCienciaComponent,
    LicitacaoAudespTermoAditivoComponent,
    LicitacaoAudespTermoAditivoListComponent,
    LicitacaoAudespVisitaComponent,
  ],
  imports: [
    CommonModule,
    LicitacaoAudespRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    SelectButtonModule,
    FieldsetModule,
    ConfirmDialogModule,
    PessoaModule,
  ],
  providers: [ConfirmationService]
})
export class LicitacaoAudespModule { }
