import { BaseResourceModel } from "../../models/base-resource.model";
import { Usuario } from "../comum/usuario.model";
import { EtapaItemSetor } from "./etapa-item-setor.model";
import { ReciboItemMerenda } from "./recibo-item-merenda.model";

export class EtapaItemQuantidadeHistorico extends BaseResourceModel {
  constructor(
    public id?: number,
    public reciboItemMerenda?: ReciboItemMerenda,
    public quantidadeFornecedorAnterior?: number,
    public pesoUnitarioAnterior?: number,
    public quantidadeAnterior?: number,
    public quantidadeFornecedorAtual?: number,
    public pesoUnitarioAtual?: number,
    public quantidadeAtual?: number,
    public justificativa?: string,
    public usuario?: Usuario,
  ) {
    super();
  }

  static converteJson(json: any): EtapaItemQuantidadeHistorico {
    return Object.assign(new EtapaItemQuantidadeHistorico(), json);
  }
}
