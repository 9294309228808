import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { EmpenhoExtraService } from '../service/empenho-extra.service';
import { EmpenhoExtra, Login, GlobalService, LiquidacaoResto, Mensagem, RetencaoResto, Retencao, Liquidacao } from 'eddydata-lib';
import { LiquidacaoService } from 'administrativo-lib';
import { LiquidacaoRestoService } from 'contabil-lib';

declare var $: any;

@Component({
  selector: 'lib-empenho-extra-retencao',
  templateUrl: './empenho-extra-retencao.component.html'
})
export class EmpenhoExtraRetencaoComponent implements OnInit, AfterViewInit, OnDestroy {

  public empenhoNumero: number;
  public empenhoAno: number;
  public empenhoParcela: number;

  public liquidacaoResto: LiquidacaoResto;
  public retencaoResto: RetencaoResto;
  public liquidacao: Liquidacao;
  public retencao: Retencao;

  protected unsubscribe: Subject<void> = new Subject();

  @Input() login: Login;
  @Input() empenhoExtra: EmpenhoExtra;


  constructor(
    protected messageService: MessageService,
    private globalService: GlobalService,
    private empenhoExtraService: EmpenhoExtraService,
    private liquidacaoRestoService: LiquidacaoRestoService,
    private liquidacaoService: LiquidacaoService
  ) { }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {

  }

  public async atualizarEmpenho() {
    if (this.empenhoAno < this.login.exercicio.ano) {
      const param = {};
      param['empenho.numero'] = this.empenhoNumero;
      param['empenho.ano'] = this.empenhoAno;
      param['parcela'] = this.empenhoParcela;
      param['orgao.id'] = this.login.orgao.id;
      param['relations'] = 'empenho,empenho.favorecido,retencoes,retencoes.ficha,retencoes.liquidacao,retencoes.liquidacao.empenho';

      this.liquidacaoResto = await this.liquidacaoRestoService.obter(param).pipe(takeUntil(this.unsubscribe)).toPromise();
      if (!this.liquidacaoResto) {
        Mensagem.alerta(this.messageService, 'Empenho de resto a pagar não localizado!');
        return;
      }
    } else {
      const param = {};
      param['empenho.numero'] = this.empenhoNumero;
      param['empenho.exercicio.ano'] = this.empenhoAno;
      param['parcela'] = this.empenhoParcela;
      param['orgao.id'] = this.login.orgao.id;
      param['ignoreCondObrig'] = true;
      param['relations'] = 'empenho,empenho.favorecido,retencoes,retencoes.ficha,retencoes.liquidacao,retencoes.liquidacao.empenho';

      this.liquidacao = await this.liquidacaoService.obter(param).pipe(takeUntil(this.unsubscribe)).toPromise();
      if (!this.liquidacao) {
        Mensagem.alerta(this.messageService, 'Empenho orçamentário não localizado!');
        return;
      }
    }
  }

  public vincular() {
    if (this.retencaoResto) {
      this.empenhoExtra.retencao_resto = this.retencaoResto;
      this.empenhoExtra.usuario_cadastro = this.login.usuario;
      this.empenhoExtraService.atualizar(this.empenhoExtra).subscribe((res) => {
        Mensagem.sucesso(this.messageService, `Empenho extra vinculado na retenção!`);
        this.cancelar();
      });
    } else if (this.retencao) {
      this.empenhoExtra.retencao = this.retencao;
      this.empenhoExtra.usuario_cadastro = this.login.usuario;
      this.empenhoExtraService.atualizar(this.empenhoExtra).subscribe((res) => {
        Mensagem.sucesso(this.messageService, `Empenho extra vinculado na retenção !`);
        this.cancelar();
      });
    } else {
      Mensagem.alerta(this.messageService, `Nenhuma retenção foi seleciona para efetuar a vinculação !`);
    }
  }

  public cancelar() {
    this.empenhoAno = null;
    this.empenhoNumero = null;
    this.empenhoParcela = 0;
    this.retencaoResto = null;
    this.liquidacaoResto = null;
    $('#dialogRetencao').modal('hide');
  }

}
