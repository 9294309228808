import { Component, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import {
  BaseResourceFormComponent, PagamentoExtra, OrgaoAssinaturaService,
  LoginContabil, GlobalService, DateFormatPipe
} from 'eddydata-lib';
import { PagamentoExtraService, ContaBancariaService } from 'administrativo-lib';
import { NotaPagamentoExtra } from '../../relatorio-tesouraria/notas/nota-pagamento-extra';
import { EmpenhoExtraService } from '../../../empenho-extra/service/empenho-extra.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-pagamento-extra-view',
  templateUrl: './pagamento-extra-view.component.html'
})
export class PagamentoExtraViewComponent extends BaseResourceFormComponent<PagamentoExtra, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public especie: string;
  public liquidado: number;
  public saldoPagar = 0;
  public saldoBancario = 0;
  public contaId: number;
  public empenhoId: number;
  public anulacao: boolean;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private messageService: MessageService,
    protected injector: Injector,
    protected contaService: ContaBancariaService,
    protected globalService: GlobalService,
    protected empenhoService: EmpenhoExtraService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected pagamentoService: PagamentoExtraService) {
    super(new PagamentoExtra(), injector, PagamentoExtra.converteJson, pagamentoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      valor_pago: 0,
      valor_retido: [null, [Validators.required]],
      data_pagamento: [null, [Validators.required]],
      especie: [null, [Validators.required]],
      documento: [null, [Validators.required]],
      impresso: [null, [Validators.required]],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      anulacao: [null, [Validators.required]],
      conta: [null, [Validators.required]],
      exercicio: this.fb.group({
        ano: [null, [Validators.required]],
      }),
      orgao: [null, [Validators.required]],
      empenho: this.fb.group({
        numero: [null, [Validators.required]],
        parcela: [null, [Validators.required]],
        data_vencimento: [null, [Validators.required]],
        exercicio: this.fb.group({
          ano: [null, [Validators.required]],
        }),
      })
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'conta,conta.banco,empenho,empenho.favorecido,exercicio,orgao,' +
        'empenho.ficha,empenho.ficha.recurso,empenho.ficha.aplicacao,empenho.ficha.aplicacao_variavel,' +
        'empenho.retencao,conta_recurso,conta_recurso.recurso,conta_recurso.aplicacao,conta_recurso.convenio'
    };
  }

  protected afterLoad() {
    this.entidade.data_pagamento = new DateFormatPipe().transform(this.entidade.data_pagamento, []);
    this.buscarTotalEmpenhado(this.entidade.empenho.numero, this.entidade.empenho.parcela);
    this.buscarSaldoBancario();
  }

  protected afterInit(): void {
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: PagamentoExtra): void {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public buscarSaldoBancario() {
    let datepipe =  new DatePipe('pt');
    const dataPagamento = datepipe.transform(this.entidade.data_pagamento, 'yyyy-MM-dd', 'GMT');
    this.contaService.obterSaldoBancarioConta(this.entidade.conta.id, this.login.exercicio.id, this.login.orgao.id, dataPagamento)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (saldoTotal: any) => {
          this.saldoBancario = saldoTotal;
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
      );
  }

  public buscarTotalEmpenhado(numero: number, parcela: number) {
    this.empenhoService.obterTotalEmpenhadoPorNumero(numero, parcela, this.login.exercicio.id, this.login.orgao.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res: any) => {
          this.liquidado = res.empenhado;
          this.buscarTotalPago(numero, parcela);
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
      );
  }

  public buscarTotalPago(numero: number, parcela: number) {
    this.pagamentoService.obterTotalPagoPorEmpenho(numero, parcela, this.login.exercicio.id, this.login.orgao.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res: any) => {
          this.saldoPagar = +this.liquidado - +res.pago;
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
      );
  }

  imprimir() {
    const parametros = {};

    let relations = 'empenho.contrato.licitacao.modalidade,empenho.ficha.aplicacao,empenho.ficha.recurso,empenho.ficha.aplicacao_variavel,empenho.contrato,empenho.favorecido.contas.banco,empenho.';
    relations += 'convenio,empenho.retencao.liquidacao.empenho.favorecido,empenho.retencao.liquidacao.empenho';

    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id']=this.entidade.id;

    this.pagamentoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        new NotaPagamentoExtra(this.assinaturaService).imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/tesouraria']);
        break;
      default:
        this.sair();
        break;
    }
  }
}
