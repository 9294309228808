import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { BaseResourceService, Compra, Empenho, Page, Usuario } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class EmpenhoService extends BaseResourceService<Empenho> {

  constructor(
    protected injector: Injector
  ) {
    super(`empenhos`, injector);
  }

  public obterPorNome(nome: string, orgaoId: number): Observable<Empenho> {
    return this.http.get<Empenho>(
      `${this.login.cidade.id}/${this.api}/filtrar?nome$like=${nome}%&orgao_id=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public removerPrecatorio(id: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/remover-precatorio/${id}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err)))
  }

  public atualizarDataAnulacao(id: number, data: string, orgao_id: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/data-anulacao/${id}/${data}/${orgao_id}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err)))
  }

  public obterPorNumero(numero: number, exercicioId: number, orgaoId: number): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?relations=favorecido&numero=${numero}
      &exercicio_id=${exercicioId}&orgao_id=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterTotalEmpenhado(numero: number, ficha: number, exercicio: number, orgao: number, dataEmpenho: string): Observable<number> {
    return this.http.get<number>(
      // tslint:disable-next-line: max-line-length
      `${this.login.cidade.id}/${this.api}/total-empenhado/${numero}/${ficha}/${exercicio}/${orgao}?data_empenho=${dataEmpenho}&acumulado=true`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterValorEmpenho(numero: number, exercicio: number, orgao: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/valor-empenho/${numero}/${exercicio}/${orgao}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalEmpenhadoMes(exercicio: number, orgaoId: number): Observable<Array<any>> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/total-empenhado-mes/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalEmpenhadoAno(exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/total-empenhado-ano/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalEmpenhadoPorConvenio(convenioId: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/total-empenhado-convenio?convenio=${convenioId}&orgao=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalEmpenhadoPorContrato(contratoId: number, orgaoId: number, desconsiderarEmpenhoId?: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/total-empenhado-contrato?contrato=${contratoId}&orgao=${orgaoId}${desconsiderarEmpenhoId ? `&desconsiderarId=${desconsiderarEmpenhoId}` : ''}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalEmpenhadoFuncao(exercicio: number, orgaoId: number, cidadeId: number, limite: number,
    especies?: string, mes?: number, codigo?: string, sel_maiores_despesas?: boolean, sel_investimento?: boolean): Observable<Array<any>> {
    let parametros = '';

    if (especies)
      parametros += `?especies=${especies}`;
    if (mes)
      parametros += `${(parametros ? '&' : '?')}mes=${mes}`;
    if (sel_maiores_despesas)
      parametros += `${(parametros ? '&' : '?')}sel_maiores_despesas=true`;
    if (sel_investimento)
      parametros += `${(parametros ? '&' : '?')}sel_investimento=true`;
    if (codigo)
      parametros += `${(parametros ? '&' : '?')}codigo=${codigo}`;

    return this.http.get<Array<any>>(
      `${cidadeId}/${this.api}/total-empenhado-funcao/${exercicio}/${orgaoId}/${limite}${parametros}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public proximoNumeroEmpenho(exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-numero/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public ultimaDataEmpenhada(exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/ultima-data-empenhada/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalizadores(exercicio: number, orgaoId: number, numero: number): Observable<any> {
    let parametros = '';
    parametros += '?exercicio_id=' + exercicio;
    parametros += '&orgao_id=' + orgaoId;
    parametros += '&numero=' + numero;
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/extendido/1/1${parametros}`, {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

  public totalizadoresFavorecido(exercicio: number, orgaoId: number, limite: number, parametros?: { favorecidoId?: number, ordem?: string }): Observable<any> {
    let parametrosS = '';
    if (parametros.favorecidoId) {
      parametrosS = `?favorecidoId=${parametros.favorecidoId}`;
    }

    if (parametros.ordem) {
      parametrosS += `${parametrosS.length > 0 ? '&' : '?'}ordem=${parametros.ordem}`;
    }
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/totalizadores-favorecidos/${exercicio}/${orgaoId}/${limite}${parametrosS}`, {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

  public totalizadoresFicha(exercicio: number, orgaoId: number, fichaId: number, dataEmpenho: string): Observable<any> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/totalizadores-ficha/${exercicio}/${orgaoId}/${fichaId}/${dataEmpenho}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public anular(id: number, data: string, valor: number, historico: string, usuario: Usuario, compra: Compra, anularRetencoes: boolean): Observable<Empenho> {
    const anulacao = { id, data, valor, historico, usuario, compra, anularRetencoes };
    return this.http.post<Empenho>(
      `${this.login.cidade.id}/${this.api}/anular`, JSON.stringify(anulacao), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

  public exportarEmpenhosSubelemento(exercicio: number, orgaos: string, mes: number, dataEmpInicio: string, dataEmpFinal: string): Observable<any> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/exportar-empenho-subelemento/${exercicio}/${orgaos}/${mes}/${dataEmpInicio}/${dataEmpFinal}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }


  public marcarComoImpresso(parametros: any): Observable<any> {
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/marcar-como-impresso`, JSON.stringify(parametros), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

  public totalEmpenhadoSecretaria(exercicio: number, orgaoId: number, despesa: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/total-empenhado-secretaria/${exercicio}/${orgaoId}/${despesa}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public empenhadoPorNivel(orgao: number, exercicio: number, mes: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/empenhado-por-nivel/${orgao}/${exercicio}/${mes}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorLicitacao(id: number, orgaoId: number, favorecidoDoc?: string): Observable<Page> {
    let uri = `relations=ficha.recurso,contrato.responsaveis.responsavel&licitacao_id=${id}&valor_empenho$gt=0&ignoreCondObrig=true&orgao_id=${orgaoId}&orderBy=exercicio.ano$DESC,numero$DESC`;
    if (favorecidoDoc) uri += `&favorecido.cpf_cnpj=${favorecidoDoc}`
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?${uri}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorContrato(id: number, orgaoId: number, apenasNaoEnviados?: boolean): Observable<Page> {
    let uri = `relations=ficha.recurso&contrato.id=${id}&valor_empenho$gt=0&orgao_id=${orgaoId}&orderBy=exercicio.ano$DESC,numero$DESC&ignoreCondObrig=true`;
    if (apenasNaoEnviados) uri += '&OR_1=licitacao_audesp.id$null;!;!;licitacao_audesp.armazenado=false&OR_2=licitacao_audesp_execucao.id$null;!;!;licitacao_audesp_execucao.armazenado=false'
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?${uri}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public conferenciaEmpenhoDiario(filtros: any) {
    let parametros = ''
    for (const key in filtros) {
      if (parametros === '') {
        parametros += '?';
      } else {
        parametros += '&';
      }
      parametros += key + '=' + encodeURIComponent(filtros[key]);
    }

    return this.http.get<any[]>(`${this.login.cidade.id}/${this.api}/conferencia-empenho-diario${parametros}`, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      )
  }

  public vincularReserva(empenhoId: number, reservaId: number): Observable<any> {
    return this.http.get(
      `${this.login.cidade.id}/${this.api}/vincular-reserva/${empenhoId}/${reservaId}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    )
  }

  public gerarContasAPagar(filtros: {}): Observable<any> {
    let parametros = '';
    if (filtros) {
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + filtros[key];
      }
    }

    return this.http.get(
      `${this.login.cidade.id}/${this.api}/gerar-contas-pagar${parametros}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    )
  }
}
