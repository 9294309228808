// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { FichaDespesa } from './ficha-despesa.model';

export class CotaMensal extends BaseResourceModel {
  constructor(
    public id?: number,
    public mes?: number,
    public tipo_cota?: string,
    public valor_cota?: number,
    public valor_disponivel?: number,
    public ficha?: FichaDespesa,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public editavel?: boolean
  ) {
    super();
  }

  static converteJson(json: any): CotaMensal {
    return Object.assign(new CotaMensal(), json);
  }
}
