import { Component, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { Aliquota, BaseResourceFormComponent, FuncaoService, LoginContabil, Modalidade } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { AliquotaService } from '../service/aliquota.service';

@Component({
  selector: 'lib-aliquota-form',
  templateUrl: './aliquota-form.component.html'
})
export class AliquotaFormComponent extends BaseResourceFormComponent<Aliquota, LoginContabil> {

  constructor(
    protected injector: Injector,
    protected funcaoService: FuncaoService,
    protected messageService: MessageService,
    protected service: AliquotaService) {
    super(new Aliquota(), injector, Aliquota.converteJson, service);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      resumo: [null, [Validators.required, Validators.maxLength(100)]],
      natureza: [null, [Validators.required, Validators.maxLength(1000)]],
      ativo: [true],
      ir: [null, [Validators.required]],
      csll: [null, [Validators.required]],
      cofins: [null, [Validators.required]],
      pis_pasep: [null, [Validators.required]],
      codigo_receita: [null, [Validators.required]]
    });
  }

  protected parametrosExtras(): {} {
    return {};
  }

  protected afterLoad() {

  }

  protected afterInit(): void {

  }

  protected beforeSubmit() {
    try {
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(ent: Modalidade) {
  }

}
