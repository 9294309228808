import { Directive, Injector, OnDestroy } from "@angular/core";
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Relatorio } from "eddydata-lib";
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { QuadroEnsinoService } from "../service/quadro-ensino.service";
import { Assinaturas } from "administrativo-lib";
import * as toastr from 'toastr'

@Directive()
export class ProjecaoReceitaDespesaEnsino implements OnDestroy {

  protected unsubscribe: Subject<void> = new Subject();
  protected login: Login = new Login();
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;

  private percentualEstado: Map<number, number> = new Map();
  private percentualUniao: Map<number, number> = new Map();
  private estadoAnterior: Map<number, number> = new Map();
  private uniaoAnterior: Map<number, number> = new Map();
  private projecaoEstado: Map<number, number> = new Map();
  private projecaoUniao: Map<number, number> = new Map();

  private total_receita: number = 0;
  private total_empenhado: number = 0;
  private total_liquidado: number = 0;
  private total_empenhado_4: number = 0;
  private total_liquidado_4: number = 0;
  private total_rendimentos: number = 0;
  private total_projecao_ferias: number = 0;
  private total_pagto_restos: number = 0;
  private total_restos_aberto: number = 0;
  private total_provisao_3390: number = 0;
  private total_provisao_4: number = 0;

  constructor(
    protected quadroServico: QuadroEnsinoService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected exercicioAnterior: Exercicio,
    protected percentualFerias: number,
    protected percentual13: number,
    protected injector?: Injector,
    protected assinatura_ensino?: boolean
  ) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {

    this.quadroServico.obterProjecaoReceitaDespesa(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (dados[0][0].length === 0) {
          toastr.info('Não há informações para essa consulta', 'Atenção')
          return
        }

        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(
            'PROJECAO DA RECEITA E DESPESA',
            this.login.usuario.nome,
            this.login.usuario.sobrenome,
            this.login.orgao.nome,
            this.login.brasao,
            this.conteudoReceita(dados[0])
              .concat(this.conteudoDespesa(dados[1], this.mes))
              .concat(await this.conteudoDetalhes(dados)),
            'landscape',
            'PROJECAO DA RECEITA E DESPESA',
            {
              linhas: {
                hLineWidth() { return 1 },
                vLineWidth() { return 1 },
                hLineColor() { return 'black' },
                paddingLeft() { return 3 },
                paddingRight() { return 3 }
              }
            }
          );
        } else {
          this.funcaoService.exportar(formato, this.conteudoExportar(dados), 'PROJECAO DA RECEITA E DESPESA', this.colunasRelatorio())
        }

      });
  }

  private conteudoReceita(dados: any[]): {}[] {
    const conteudo = [];
    const [proprios, transferencias_uniao, transferencias_estado, retencoes, { valor: rendimentos }] = dados;
    this.total_rendimentos = +rendimentos;

    conteudo.push([
      {
        text: `PROJEÇÃO RECEITA: REFERÊNCIA - ${this.globalService.obterMes(+this.mes)}/${this.exercicio.ano}`,
        bold: true, colSpan: 14, fontSize: 9, border: [false, false, false, false], margin: [0, -15, 0, 0]
      }, '', '', '', '', '', '', '', '', '', '', '', '', ''
    ]);
    conteudo.push([
      { text: 'MESES', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
      { text: 'RECEITA PRÓPRIA', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
      { text: '%', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
      { text: 'TRANSFERÊNCIA UNIÃO', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
      { text: '%', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
      { text: 'TRANSFERÊNCIA ESTADO', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
      { text: '%', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
      { text: 'TOTAL', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
      { text: `FUNDEB ${this.exercicioAnterior.ano}`, alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, ''
    ]);
    conteudo.push([
      '',
      { text: this.exercicioAnterior.ano, alignment: 'center', bold: true, fontSize: 7 },
      { text: this.exercicio.ano, alignment: 'center', bold: true, fontSize: 7 }, '',
      { text: this.exercicioAnterior.ano, alignment: 'center', bold: true, fontSize: 7 },
      { text: this.exercicio.ano, alignment: 'center', bold: true, fontSize: 7 }, '',
      { text: this.exercicioAnterior.ano, alignment: 'center', bold: true, fontSize: 7 },
      { text: this.exercicio.ano, alignment: 'center', bold: true, fontSize: 7 }, '',
      { text: this.exercicioAnterior.ano, alignment: 'center', bold: true, fontSize: 7 },
      { text: this.exercicio.ano, alignment: 'center', bold: true, fontSize: 7 },
      { text: 'Retido Estado', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'Retido União', alignment: 'center', bold: true, fontSize: 7 }
    ]);

    let totalProprioAnterior: number = 0;
    let totalProprioAtual: number = 0;
    let percentualProprio: number = 0;
    let totalUniaoAnterior: number = 0;
    let totalUniaoAtual: number = 0;
    let totalEstadoAnterior: number = 0;
    let totalEstadoAtual: number = 0;
    for (let i = 0; i < this.mes; i++) {
      totalProprioAnterior += proprios[i]['anterior'];
      totalProprioAtual += proprios[i]['atual'];
      percentualProprio = ((totalProprioAtual / totalProprioAnterior) * 100) - 100;
      proprios[i]['percentual'] = percentualProprio;

      totalUniaoAnterior += transferencias_uniao[i]['anterior'];
      totalUniaoAtual += transferencias_uniao[i]['atual'];
      this.percentualUniao.set(i, ((totalUniaoAtual / totalUniaoAnterior) * 100) - 100);
      transferencias_uniao[i]['percentual'] = this.percentualUniao.get(i);

      totalEstadoAnterior += transferencias_estado[i]['anterior'];
      totalEstadoAtual += transferencias_estado[i]['atual'];
      this.percentualEstado.set(i, ((totalEstadoAtual / totalEstadoAnterior) * 100) - 100);
      transferencias_estado[i]['percentual'] = this.percentualEstado.get(i);
    }
    for (let i = +this.mes; i < 12; i++) {
      const valorProprio = proprios[i]['anterior'];
      const projecaoProprio = (valorProprio * percentualProprio) / 100;
      proprios[i]['atual'] = valorProprio + projecaoProprio;
      proprios[i]['percentual'] = percentualProprio;

      const valorUniao = transferencias_uniao[i]['anterior'];
      const projecaoUniao = (valorUniao * this.percentualUniao.get(+this.mes - 1)) / 100;
      transferencias_uniao[i]['atual'] = valorUniao + projecaoUniao;
      transferencias_uniao[i]['percentual'] = this.percentualUniao.get(+this.mes - 1);

      const valorEstado = transferencias_estado[i]['anterior'];
      const projecaoEstado = (valorEstado * this.percentualEstado.get(+this.mes - 1)) / 100;
      transferencias_estado[i]['atual'] = valorEstado + projecaoEstado;
      transferencias_estado[i]['percentual'] = this.percentualEstado.get(+this.mes - 1);
    }

    for (let i = 0; i < 12; i++) {
      conteudo.push([
        { text: this.globalService.obterMes(proprios[i]?.['mes']), fontSize: 6, border: [true, false, true, false], alignment: 'left' },
        { text: this.funcaoService.convertToBrNumber(proprios[i]?.['anterior']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(proprios[i]?.['atual']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(proprios[i]?.['percentual'].toFixed(2)), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(transferencias_uniao[i]?.['anterior']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(transferencias_uniao[i]?.['atual']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(transferencias_uniao[i]?.['percentual'].toFixed(2)), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(transferencias_estado[i]?.['anterior']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(transferencias_estado[i]?.['atual']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(transferencias_estado[i]?.['percentual'].toFixed(2)), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(proprios[i]?.['anterior'] + transferencias_uniao[i]?.['anterior'] + transferencias_estado[i]?.['anterior']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(proprios[i]?.['atual'] + transferencias_uniao[i]?.['atual'] + transferencias_estado[i]?.['atual']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(retencoes[i]?.['estado']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(retencoes[i]?.['uniao']), fontSize: 6, border: [true, false, true, false], alignment: 'right' }
      ]);

      this.total_receita += +proprios[i]['atual'] + +transferencias_uniao[i]['atual'] + +transferencias_estado[i]['atual'];
      this.estadoAnterior.set(i, +retencoes[i]?.['estado'] ?? 0);
      this.uniaoAnterior.set(i, +retencoes[i]?.['uniao'] ?? 0);
    }

    const totalizadorProprioAnterior = proprios.reduce((acumulador, receita) => acumulador += receita['anterior'], 0);
    const totalizadorProprioAtual = proprios.reduce((acumulador, receita) => acumulador += receita['atual'], 0);
    const totalizadorUniaoAnterior = transferencias_uniao.reduce((acumulador, receita) => acumulador += receita['anterior'], 0);
    const totalizadorUniaoAtual = transferencias_uniao.reduce((acumulador, receita) => acumulador += receita['atual'], 0);
    const totalizadorEstadoAnterior = transferencias_estado.reduce((acumulador, receita) => acumulador += receita['anterior'], 0);
    const totalizadorEstadoAtual = transferencias_estado.reduce((acumulador, receita) => acumulador += receita['atual'], 0);
    const totalizadorRetencaoEstado = retencoes.reduce((acumulador, retencao) => acumulador += retencao?.['estado'] ?? 0, 0);
    const totalizadorRetencaoUniao = retencoes.reduce((acumulador, retencao) => acumulador += retencao?.['uniao'] ?? 0, 0);

    conteudo.push([
      { text: 'TOTAL', fontSize: 7, bold: true, alignment: 'left' },
      { text: this.funcaoService.convertToBrNumber(totalizadorProprioAnterior), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalizadorProprioAtual), fontSize: 7, bold: true, alignment: 'right' },
      { text: '' },
      { text: this.funcaoService.convertToBrNumber(totalizadorUniaoAnterior), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalizadorUniaoAtual), fontSize: 7, bold: true, alignment: 'right' },
      { text: '' },
      { text: this.funcaoService.convertToBrNumber(totalizadorEstadoAnterior), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalizadorEstadoAtual), fontSize: 7, bold: true, alignment: 'right' },
      { text: '' },
      { text: this.funcaoService.convertToBrNumber(totalizadorProprioAnterior + totalizadorEstadoAnterior + totalizadorUniaoAnterior), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalizadorProprioAtual + totalizadorEstadoAtual + totalizadorUniaoAtual), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalizadorRetencaoEstado), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalizadorRetencaoUniao), fontSize: 7, bold: true, alignment: 'right' }
    ]);

    return [{
      layout: 'linhas',
      table: {
        widths: ['*', '*', '*', 'auto', '*', '*', 'auto', '*', '*', 'auto', '*', '*', '*', '*'],
        body: conteudo
      }
    }];
  }

  private conteudoDespesa(dados: any[], mes: number) {
    const conteudo = [];
    const [despesas, { projecao_ferias: projecaoFerias }, projecaoFundeb, { valor: pagtoResto }, restosEmAberto, { valor: provisao3390 }, { valor: provisao4 }] = dados;
    this.total_projecao_ferias = projecaoFerias;
    this.total_pagto_restos = +pagtoResto;
    this.total_restos_aberto = +restosEmAberto.reduce((acumulador, atual) => acumulador += +atual['empenhado'] - +atual['cancelado'] - +atual['pago'], 0);
    this.total_provisao_3390 = +provisao3390;
    this.total_provisao_4 = +provisao4;

    conteudo.push([
      {
        text: `PROJEÇÃO DESPESA: REFERÊNCIA - ${this.globalService.obterMes(+this.mes)}/${this.exercicio.ano}`,
        bold: true, colSpan: 14, fontSize: 9, border: [false, false, false, false]
      }, '', '', '', '', '', '', '', '', '', '', '', '', ''
    ]);
    conteudo.push([
      { text: 'MESES', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
      { text: '3190 (exceto 31901143)', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
      { text: '31901143', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
      { text: '3350', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
      { text: '3390/3391', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
      { text: '4', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
      { text: 'FUNDEB RETIDO', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
      { text: 'TOTAL EMPENHADO', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
      { text: 'TOTAL LIQUIDADO', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 }
    ]);
    conteudo.push([
      '',
      { text: 'EMPENHADA', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'LIQUIDADA', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'EMPENHADA', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'LIQUIDADA', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'EMPENHADA', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'LIQUIDADA', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'EMPENHADA', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'LIQUIDADA', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'EMPENHADA', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'LIQUIDADA', alignment: 'center', bold: true, fontSize: 7 },
      '', '', ''
    ]);

    let empenhada_3190: number = 0;
    let liquidada_3190: number = 0;
    let empenhada_31901143: number = 0;
    let liquidada_31901143: number = 0;
    let empenhada_3350: number = 0;
    let liquidada_3350: number = 0;
    let empenhada_3390_3391: number = 0;
    let liquidada_3390_3391: number = 0;
    let fundeb_retido: number = 0;
    let projecao_3190: number = 0;
    let projecao_3350: number = 0;
    const projecao_despesas: any[] = [];

    for (let i = 0; i < 12; i++) {
      const despesa = despesas?.[i];
      if ((i + 1) <= +despesa?.['mes']) {
        empenhada_3190 += +despesa['empenhada_3190'];
        liquidada_3190 += +despesa['liquidada_3190'];
        empenhada_31901143 += +despesa['empenhada_31901143'];
        liquidada_31901143 += +despesa['liquidada_31901143'];
        empenhada_3350 += +despesa['empenhada_3350'];
        liquidada_3350 += +despesa['liquidada_3350'];
        empenhada_3390_3391 += +despesa['empenhada_3390_3391'];
        liquidada_3390_3391 += +despesa['liquidada_3390_3391'];
        this.total_empenhado_4 += +despesa['empenhada_4'];
        this.total_liquidado_4 += +despesa['liquidada_4'];
        fundeb_retido += +despesa['fundeb_retido'];
        this.total_empenhado += +despesa['empenhada_3190'] + +despesa['empenhada_31901143'] + +despesa['empenhada_3350'] + +despesa['empenhada_3390_3391'] + +despesa['empenhada_4'] + +despesa['fundeb_retido'];
        this.total_liquidado += +despesa['liquidada_3190'] + +despesa['liquidada_31901143'] + +despesa['liquidada_3350'] + +despesa['liquidada_3390_3391'] + +despesa['liquidada_4'] + +despesa['fundeb_retido'];
        conteudo.push([
          { text: this.globalService.obterMes(+despesa['mes']), fontSize: 6, border: [true, false, true, false], alignment: 'left' },
          { text: this.funcaoService.convertToBrNumber(despesa['empenhada_3190']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(despesa['liquidada_3190']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(despesa['empenhada_31901143']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(despesa['liquidada_31901143']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(despesa['empenhada_3350']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(despesa['liquidada_3350']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(despesa['empenhada_3390_3391']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(despesa['liquidada_3390_3391']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(despesa['empenhada_4']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(despesa['liquidada_4']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber(despesa['fundeb_retido']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
          {
            text: this.funcaoService.convertToBrNumber(+despesa['empenhada_3190'] + +despesa['empenhada_31901143'] + +despesa['empenhada_3350'] + +despesa['empenhada_3390_3391'] + +despesa['empenhada_4'] + +despesa['fundeb_retido']),
            fontSize: 6, border: [true, false, true, false], alignment: 'right'
          },
          {
            text: this.funcaoService.convertToBrNumber(+despesa['liquidada_3190'] + +despesa['liquidada_31901143'] + +despesa['liquidada_3350'] + +despesa['liquidada_3390_3391'] + +despesa['liquidada_4'] + +despesa['fundeb_retido']),
            fontSize: 6, border: [true, false, true, false], alignment: 'right'
          }
        ]);
      } else {
        projecao_3190 = liquidada_3190 / mes;
        if ((i + 1) === 12) {
          projecao_3190 += +projecaoFerias + (+projecaoFerias * this.percentualFerias) / 100;
          if (empenhada_3350 > liquidada_3350) {
            projecao_3350 = empenhada_3350 - liquidada_3350;
          }
        }

        let percentual_estado: number = 1;
        let percentual_uniao: number = 1;
        for (let j = this.mes; j <= 12; j++) {
          if (projecaoFundeb.length) {
            percentual_estado = +this.percentualEstado.get(+this.mes - 1).toFixed(2);
            const valor_estado = this.estadoAnterior.get(j - 1);
            const projecao_estado = (valor_estado * percentual_estado) / 100;

            percentual_uniao = +this.percentualUniao.get(+this.mes - 1).toFixed(2);
            const valor_uniao = this.uniaoAnterior.get(j - 1);
            const projecao_uniao = (valor_uniao * percentual_uniao) / 100;

            if (j > mes) {
              this.projecaoEstado.set(j, +valor_estado + +projecao_estado);
              this.projecaoUniao.set(j, +valor_uniao + +projecao_uniao);
            }
          }
        }
        projecao_despesas.push({
          mes: i + 1,
          empenhada_3190: 0,
          liquidada_3190: projecao_3190,
          empenhada_31901143: 0,
          liquidada_31901143: (i + 1) === 12 ? ((liquidada_3190 / mes) * this.percentual13) / 100 : 0,
          empenhada_3350: 0,
          liquidada_3350: projecao_3350,
          empenhada_3390_3391: 0,
          liquidada_3390_3391: liquidada_3390_3391 / mes,
          fundeb_retido: +this.projecaoEstado.get(i + 1) + +this.projecaoUniao.get(i + 1)
        });
      }
    }

    for (let i = +mes; i < 12; i++) {
      const despesa = projecao_despesas.find(item => +item.mes === (+i + 1));
      this.total_empenhado += +despesa['empenhada_3190'] + +despesa['empenhada_31901143'] + +despesa['empenhada_3350'] + +despesa['empenhada_3390_3391'] + +despesa['fundeb_retido'];
      this.total_liquidado += +despesa['liquidada_3190'] + +despesa['liquidada_31901143'] + +despesa['liquidada_3350'] + +despesa['liquidada_3390_3391'] + +despesa['fundeb_retido'];
      conteudo.push([
        { text: this.globalService.obterMes(+despesa['mes']) + (+despesa['mes'] === 12 ? ' *' : ''), fontSize: 6, border: [true, false, true, false], alignment: 'left' },
        { text: this.funcaoService.convertToBrNumber(despesa['empenhada_3190']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(despesa['liquidada_3190']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(despesa['empenhada_31901143']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(despesa['liquidada_31901143']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(despesa['empenhada_3350']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(despesa['liquidada_3350']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(despesa['empenhada_3390_3391']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(despesa['liquidada_3390_3391']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(0), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(0), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(despesa['fundeb_retido']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(+despesa['empenhada_3190'] + +despesa['empenhada_31901143'] + +despesa['empenhada_3350'] + +despesa['empenhada_3390_3391'] + +despesa['fundeb_retido']), fontSize: 6, border: [true, false, true, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(+despesa['liquidada_3190'] + +despesa['liquidada_31901143'] + +despesa['liquidada_3350'] + +despesa['liquidada_3390_3391'] + +despesa['fundeb_retido']), fontSize: 6, border: [true, false, true, false], alignment: 'right' }
      ]);
    }

    conteudo.push([
      { text: 'TOTAL', fontSize: 7, bold: true, alignment: 'left' },
      { text: this.funcaoService.convertToBrNumber(empenhada_3190 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['empenhada_3190'], 0)), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(liquidada_3190 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['liquidada_3190'], 0)), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(empenhada_31901143 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['empenhada_31901143'], 0)), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(liquidada_31901143 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['liquidada_31901143'], 0)), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(empenhada_3350 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['empenhada_3350'], 0)), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(liquidada_3350 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['liquidada_3350'], 0)), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(empenhada_3390_3391 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['empenhada_3390_3391'], 0)), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(liquidada_3390_3391 + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['liquidada_3390_3391'], 0)), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(this.total_empenhado_4), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(this.total_liquidado_4), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(fundeb_retido + projecao_despesas.reduce((acumulador, atual) => acumulador += +atual['fundeb_retido'], 0)), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(this.total_empenhado), fontSize: 7, bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(this.total_liquidado), fontSize: 7, bold: true, alignment: 'right' }
    ]);

    return [{
      layout: 'linhas',
      table: {
        widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
        body: conteudo
      }
    }];
  }

  private async conteudoDetalhes(dados: any[]) {
    const conteudo = [];

    conteudo.push([
      { text: 'Projeção Receita', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.total_receita), alignment: 'right', border: [false], fontSize: 6 },
      { text: 'Despesa Empenhada', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.total_empenhado), alignment: 'right', border: [false], fontSize: 6 },
      { text: 'Projeção Despesa Liquidada', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.total_liquidado), alignment: 'right', border: [false], fontSize: 6 },
      { text: 'Valor não liquidado de obra', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.total_empenhado_4 - this.total_liquidado_4), alignment: 'right', border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber((this.total_empenhado_4 - this.total_liquidado_4) / this.total_receita * 100) + '%', bold: true, border: [false], fontSize: 6 }
    ]);
    conteudo.push([
      { text: '25% aplicação obrigatoria', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber((+this.total_receita * 0.25) + +this.total_rendimentos), alignment: 'right', border: [false], fontSize: 6 },
      { text: '% Despesa Empenhada', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber((+this.total_empenhado / (+this.total_receita + +this.total_rendimentos)) * 100), alignment: 'right', border: [false], fontSize: 6 },
      { text: '% Despesa Liquidada', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber((+this.total_liquidado / (+this.total_receita + +this.total_rendimentos)) * 100), alignment: 'right', border: [false], fontSize: 6 },
      { text: 'Falta ou Sobra Despesa Liquidada', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(+this.total_liquidado - ((+this.total_receita + +this.total_rendimentos) * 0.25)), alignment: 'right', border: [false, false, false, false], fontSize: 6 },
      { text: '', border: [false] }
    ]);
    conteudo.push([
      { text: 'Pagto Resto a Pagar', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.total_pagto_restos), alignment: 'right', border: [false], fontSize: 6 },
      { text: '% Empenhada + Restos', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(((this.total_empenhado + this.total_pagto_restos) / (this.total_receita + this.total_rendimentos)) * 100), alignment: 'right', border: [false], fontSize: 6 },
      { text: '% Liquidada + Restos', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(((this.total_liquidado + this.total_pagto_restos) / (this.total_receita + this.total_rendimentos)) * 100), alignment: 'right', border: [false], fontSize: 6 },
      { text: 'Falta ou Sobra Desp. Liquidada + Restos', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.total_liquidado - ((this.total_receita + this.total_rendimentos) * 0.25) + this.total_pagto_restos), alignment: 'right', border: [false, false, false, false], fontSize: 6 },
      { text: '', border: [false] }
    ]);
    conteudo.push([
      { text: 'Restos a Pagar em aberto', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.total_restos_aberto), alignment: 'right', border: [false], fontSize: 6 },
      { text: '3390', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.total_provisao_3390), alignment: 'right', border: [false], fontSize: 6 },
      { text: '4', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.total_provisao_4), alignment: 'right', border: [false], fontSize: 6 },
      { text: 'Total Provisionado', bold: true, border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(this.total_restos_aberto + this.total_provisao_3390 + this.total_provisao_4), alignment: 'right', border: [false], fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(((this.total_restos_aberto + this.total_provisao_3390 + this.total_provisao_4) / this.total_receita) * 100) + '%', bold: true, border: [false], fontSize: 6 }
    ]);
    conteudo.push([
      { text: '* Projeção de férias:', border: [false], fontSize: 6 },
      { text: `${this.funcaoService.convertToBrNumber(this.total_projecao_ferias)} * ${this.percentualFerias}%`, border: [false, false, false, false], fontSize: 6, colSpan: 8 },
      '', '', '', '', '', '', ''
    ]);

    let assinaturas
    if (this.assinatura_ensino) {
      const ass = new Assinaturas(this.login.orgao, this.injector);
      assinaturas = await ass.dadosAssinatura(50, false, null, false, this.assinatura_ensino);
    }

    return this.assinatura_ensino ? [
      {
        layout: 'linhas',
        table: {
          widths: ['auto', '*', 'auto', '*', 'auto', '*', 'auto', '*', '*'],
          body: conteudo
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['auto', '*', 'auto'],
          body: assinaturas
        }
      }
    ] : [{
      layout: 'linhas',
      table: {
        widths: ['auto', '*', 'auto', '*', 'auto', '*', 'auto', '*', '*'],
        body: conteudo
      }
    }];
  }

  public colunasRelatorio(): Coluna[] {
    return [
      { titulo: 'Grupo', coluna: 'grupo' },
      { titulo: 'Titulo', coluna: 'titulo' },
      { titulo: 'Mes', coluna: 'mes' },
      { titulo: 'Ano Anterior', coluna: 'anterior', decimais: 2 },
      { titulo: 'Ano Atual', coluna: 'atual', decimais: 2 },
      { titulo: 'Estado', coluna: 'estado', decimais: 2 },
      { titulo: 'União', coluna: 'uniao', decimais: 2 },
      { titulo: 'Empenhado', coluna: 'empenhado', decimais: 2 },
      { titulo: 'Liquidado', coluna: 'liquidado', decimais: 2 },
      { titulo: 'Fundeb Retido', coluna: 'fundeb', decimais: 2 }
    ]
  }

  public conteudoExportar(lista: any[]) {
    return [
      ...this.normalizarDadosReceita(lista[0][0], 'RECEITA PRÓPRIA'),
      ...this.normalizarDadosReceita(lista[0][1], 'TRANSFERÊNCIA UNIÃO'),
      ...this.normalizarDadosReceita(lista[0][2], 'TRANSFERÊNCIA ESTADO'),
      ...this.normalizarDadosReceitaFundeb(lista[0][3]),
      ...this.normalizarDadosDespesa(lista[1])
    ]
  }

  public normalizarDadosReceita(lista: any[], titulo: string) {
    let percentual = +lista[0].atual / +lista[0].anterior

    return lista.map((item: any) => {
      return {
        grupo: 'PROJEÇÃO RECEITA',
        titulo: titulo,
        mes: item.mes,
        nome: item.nome,
        anterior: item.anterior,
        atual: +item.anterior * percentual,
      }
    })
  }

  public normalizarDadosReceitaFundeb(lista: any[]) {
    return lista.map((item: any) => {
      return {
        grupo: 'PROJEÇÃO RECEITA',
        titulo: 'FUNDEB',
        mes: item.mes,
        nome: item.nome,
        estado: item.estado,
        uniao: +item.uniao,
      }
    })
  }

  public normalizarDadosDespesa(dados: any[]) {
    const [despesas] = dados;

    const coluna_3190 = []
    const coluna_31901143 = []
    const coluna_3350 = []
    const coluna_3390_3391 = []
    const coluna_4 = []
    const coluna_fundeb = []

    for (const despesa of despesas) {

      coluna_3190.push({
        grupo: 'PROJEÇÃO DESPESA',
        titulo: '3190 (exceto 31901143)',
        mes: despesa.mes,
        empenhado: despesa.empenhada_3190,
        liquidado: despesa.liquidada_3190,
      })

      coluna_31901143.push({
        grupo: 'PROJEÇÃO DESPESA',
        titulo: '31901143',
        mes: despesa.mes,
        empenhado: despesa.empenhada_31901143,
        liquidado: despesa.liquidada_31901143,
      })

      coluna_3350.push({
        grupo: 'PROJEÇÃO DESPESA',
        titulo: '3350',
        mes: despesa.mes,
        empenhado: despesa.empenhada_3350,
        liquidado: despesa.liquidada_3350,
      })

      coluna_3390_3391.push({
        grupo: 'PROJEÇÃO DESPESA',
        titulo: '3390/3391',
        mes: despesa.mes,
        empenhado: despesa.empenhada_3390_3391,
        liquidado: despesa.liquidada_3390_3391,
      })

      coluna_4.push({
        grupo: 'PROJEÇÃO DESPESA',
        titulo: '4',
        mes: despesa.mes,
        empenhado: despesa.empenhada_4,
        liquidado: despesa.liquidada_4,
      })

      coluna_fundeb.push({
        grupo: 'PROJEÇÃO DESPESA',
        titulo: 'FUNDEB RETIDO',
        mes: despesa.mes,
        fundeb: despesa.fundeb_retido,
      })
    }

    return [...coluna_3190, ...coluna_31901143, ...coluna_3350, ...coluna_3390_3391, ...coluna_4, ...coluna_fundeb]
  }

}