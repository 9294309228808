import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import {
  BaseResourceListComponent, Coluna, RequisicaoMovimento, Filtro, FormatoExportacao, FuncaoService, GlobalService, LoginContabil, StatusRequisicao
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RequisicaoMovimentoService } from '../service/requisicao-movimento.service';

declare var $: any;

@Component({
  selector: 'lib-requisicao-list-dlg',
  templateUrl: './requisicao-list-dlg.component.html'
})
export class RequisicaoListDlgComponent extends BaseResourceListComponent<RequisicaoMovimento, LoginContabil> implements OnInit {

  /**
   * Declaração de variáveis
   */
  @Input() public titulo: string = 'REQUISIÇÕES';

  @Output() selRequisicao: EventEmitter<RequisicaoMovimento> = new EventEmitter();


  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public funcaoService: FuncaoService,
    public globalService: GlobalService,
    private requisicaoMovService: RequisicaoMovimentoService) {
    super(requisicaoMovService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'usuario,setor_almoxarifado,setor_almoxarifado.setor,setor_almoxarifado.estoque,exercicio,orgao,usuario,recebedor,'
      + 'itens,itens.produto_unidade,itens.produto_unidade.produto,itens.produto_unidade.unidade';
  }

  protected condicoesGrid(): {} {
    this.limite = 3;
    return {
      ['orgao.id']: this.login.orgao.id,
      ['exercicio.id']: this.login.exercicio.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['id$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['id'],
      date: ['data_cadastro'],
      text: ['usuario.nome', 'recebedor.nome'],
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: RequisicaoMovimento): Observable<RequisicaoMovimento> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'id' },
      { titulo: 'Data Requisição', coluna: 'data_cadastro' },
      { titulo: 'Requerente', coluna: 'usuario.nome' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = this.relations();
    this.requisicaoMovService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE REQUISIÇÕES',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem Requisicões', ['auto', 'auto', '*', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }


  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================


  public tituloStatusRequisicao(status: StatusRequisicao): string {
    return this.globalService.tituloStatusRequisicao(status);
  }

  public corStatusRequisicao(status: StatusRequisicao): {} {
    let cor = this.globalService.corStatusRequisicao(status);
    let ngCor = {};
    ngCor[`badge-${cor}`] = true;
    return ngCor;
  }

  selecionar(compra: RequisicaoMovimento) {
    $('#dialog_list_req').modal('hide');
    this.selRequisicao.emit(compra)
  }
}
