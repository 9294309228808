import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import * as toastr from 'toastr';
import { AssinaturaPessoa } from '../../entidade/comum/assinatura-pessoa.model';
import { Pessoa } from '../../entidade/comum/pessoa.model';
import { BaseResourceItemsComponent } from '../../models/base-resource-items';
import { EddyAutoComplete } from '../../models/form-components';
import { PessoaService } from '../../pessoa/service/pessoa.service';
import { AssinaturaPessoaService } from '../service/assinatura-pessoa.service';


@Component({
  selector: 'lib-assinatura-pessoa',
  templateUrl: './assinatura-pessoa.component.html',
  styleUrls: ['./assinatura-pessoa.component.css']
})
export class AssinaturaPessoaComponent extends BaseResourceItemsComponent<AssinaturaPessoa> {
  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  @ViewChild('input') inputField: ElementRef;

  public pessoaAutoComplete: EddyAutoComplete<Pessoa>;

  public pessoaAtual: AssinaturaPessoa;
  @Input() pessoa : Pessoa;
  @Input() visualizarAssinatura: boolean;

  // ========================================================================
  // ------------------------------ CONSTRUTOR ------------------------------
  // ========================================================================

  constructor(
    private pessoaService: PessoaService,
    private assinaturaPessoaService: AssinaturaPessoaService
  ) {
    super(new AssinaturaPessoa(), assinaturaPessoaService);
  }

  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================

  protected afterInit(): void {
    this.carregarAutoComplete();
  }

  protected validSave(item: AssinaturaPessoa): boolean | Promise<boolean> {
    if (!item.pessoa) {
      toastr.warning('Selecione a pessoa!');
      return false;
    }
    if (!item.cargo) {
      toastr.warning('Informe o cargo da pessoa!');
      return false;
    }
    if (this.lista.filter((p) => !p['editavel'] && p.pessoa.id === item.pessoa.id).length > 0) {
      toastr.warning(`Pessoa ${item.pessoa.nome} já vinculada a esta assinatura`);
      return false;
    }
    return true;
  }

  protected beforeSave(item: AssinaturaPessoa): void { }


  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  public adicionarAssDigital(pessoa: AssinaturaPessoa) {
    this.pessoaAtual = pessoa;
    this.inputField.nativeElement.click();
  }

  public async upload() {
    if (!this.inputField.nativeElement.files || this.inputField.nativeElement.files.length === 0)
      return;
    this.pessoaAtual['file'] = this.inputField.nativeElement.files[0];
    this.pessoaAtual.url = await this.urlImagem(this.pessoaAtual['file']);
  }

  public visualizar(pessoa: AssinaturaPessoa, event: any, index: number, op1: OverlayPanel) {
    if (!pessoa.url || pessoa['editavel'])
      return;
    op1.show(event);
  }

  private urlImagem(file): Promise<string> {
    const reader = new FileReader();
    return new Promise<string>((resolve) => {
      reader.onload = (event) => {
        if (typeof event.target.result === 'string')
          resolve(event.target.result);
        else
          resolve(null);
      };
      reader.readAsDataURL(file);
    });
  }

  private carregarAutoComplete() {
    this.pessoaAutoComplete = new EddyAutoComplete(null, this.pessoaService,
      'id', ['nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['cpf_cnpj'], text: ['nome'] }
    );
  }

  public podeVisualizar() {
    if (this.visualizarAssinatura === true) {
      return true;
    }
    if(this.visualizarAssinatura === false){
      return false;
    }
  }

}
