import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IndicadorService } from 'administrativo-lib';
import { GlobalService, Indicador, LoginContabil, MetaGoverno } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-indicador-avaliacao-form',
  templateUrl: './indicador-avaliacao-form.component.html'
})
export class IndicadorAvaliacaoFormComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @ViewChild('f') formGroup: any;

  public entity: Indicador = new Indicador();

  public listaIndicadores: Array<any>;
  public listaExercicios: Array<any>;

  public anoCorrente: number;
  public indice: number;

  protected unsubscribe: Subject<void> = new Subject();

  @Input() lista: Array<any>;
  @Input() entidade: MetaGoverno;
  @Input() login: LoginContabil;

  @ViewChild('InputFocus') public inputCodigo: Calendar;

  public ptBR: any;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private messageService: MessageService,
    protected indicadorService: IndicadorService,
  ) { }

  ngOnInit() {
    this.listaIndicadores = this.lista;
    this.ptBR = new GlobalService().obterDataBR();
    this.listaExercicios = [];
    this.listaExercicios.push({ id: 1, ano: this.login.ppa.ppaperiodo.ano1 });
    this.listaExercicios.push({ id: 2, ano: this.login.ppa.ppaperiodo.ano2 });
    this.listaExercicios.push({ id: 3, ano: this.login.ppa.ppaperiodo.ano3 });
    this.listaExercicios.push({ id: 4, ano: this.login.ppa.ppaperiodo.ano4 });
    this.anoCorrente = this.login.exercicio.ano;
    this.obterIndiceAno(this.anoCorrente);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lista) {
      this.listaIndicadores = this.lista;
    }
  }

  ngAfterViewInit() {
    new GlobalService().calendarMascara();
  }

  public async salvar(item: any) {
    try {
      this.indicadorService.atualizar(item).subscribe(() => { });
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  public alternarAno(ano: number) {
    this.obterIndiceAno(ano);
  }

  private obterIndiceAno(ano: number) {
    const item = this.listaExercicios.find(element => +element.ano === +ano);
    this.indice = item.id;
  }

}
