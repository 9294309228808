import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { SetorAlmoxarifado } from '../../../entidade/almoxarifado/setor-almoxarifado.model';
import { SetorExecutora } from '../../../entidade/almoxarifado/setor-executora.model';
import { SetorLocalizacao } from '../../../entidade/almoxarifado/setor-localizacao.model';
import { SetorProcesso } from '../../../entidade/almoxarifado/setor-processo.model';
import { SetorProduto } from '../../../entidade/almoxarifado/setor-produto.model';
import { SetorResponsavel } from '../../../entidade/almoxarifado/setor-responsavel.model';
import { SetorStorage } from '../../../entidade/almoxarifado/setor-storage.model';
import { Setor } from '../../../entidade/almoxarifado/setor.model';
import { Pessoa } from '../../../entidade/comum/pessoa.model';
import { LoginContabil } from '../../../entidade/login/login-contabil';
import { InventarioSetorStorage } from '../../../entidade/patrimonio/inventario-setor-storage.model';
import { Unidade } from '../../../entidade/planejamento/unidade.model';
import { BaseResourceFormComponent } from '../../../models/base-resource-form';
import { EddyAutoComplete } from '../../../models/form-components';
import { PessoaService } from '../../../pessoa/service/pessoa.service';
import { UnidadeService } from '../../../unidade/service/unidade.service';
import { FuncaoService } from '../../../util/funcao.service';
import { GlobalService } from '../../../util/global.service';
import { ViaCEPService } from '../../../util/viacep.service';
import { SetorStorageService } from '../service/setor-storage.service';
import { SetorService } from '../service/setor.service';


@Component({
  selector: 'app-setor-form',
  templateUrl: './setor-form.component.html'
})
export class SetorFormComponent extends BaseResourceFormComponent<Setor, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public estoques: SetorAlmoxarifado[] = [];
  public localizacoes: SetorLocalizacao[] = [];
  public produtos: SetorProduto[] = [];
  public responsaveis: SetorResponsavel[] = [];
  public executoras: SetorExecutora[] = [];
  public processos: SetorProcesso[] = [];
  public listaArquivos: SetorStorage[];
  public dialogCadastroPessoa: String = 'dialogCadastroPessoa';
  public listaAnexos: InventarioSetorStorage[];
  public listaDestinoMerenda: any;

  @ViewChild('nome') inputNome: ElementRef;

  public unidadeAutoComplete: EddyAutoComplete<Unidade>;
  public pessoaAutoComplete: EddyAutoComplete<Pessoa>;

  public mes: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected viacepService: ViaCEPService,
    protected unidadeService: UnidadeService,
    protected setorService: SetorService,
    protected storageService: SetorStorageService,
    private pessoaService: PessoaService) {
    super(new Setor(), injector, Setor.converteJson, setorService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      nome: [null, [Validators.required]],
      cep: [null],
      endereco: [null],
      bairro: [null],
      telefone: [null],
      email: [null],
      ativo: [false, [Validators.required]],
      responsavel: [null],
      cargo: [null],
      termo_extra: [null],
      setor_patrimonio: [false, [Validators.required]],
      setor_protocolo: [false, [Validators.required]],
      manutencao_patrimonio: [false, [Validators.required]],
      baixa_patrimonio: [false, [Validators.required]],
      cessao_uso: [false, [Validators.required]],
      transferencia_automatica: [false, [Validators.required]],
      aux: [null],
      orgao: [this.login.orgao, [Validators.required]],
      unidade: [null],
      estoques: [null],
      localizacoes: [null],
      responsaveis: [null],
      executoras: [null],
      produtos: [null],
      processos: [null],
      storages: [null],
      complemento: [null],
      merenda: [false],
      destino_merenda: [null],
      codigo: [null],
      cnes: [null]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'unidade,estoques.estoque,estoques.setor,responsavel,localizacoes,responsaveis,processos,produtos.produto_unidade.produto.material.sub_grupo.grupo,executoras.executora,produtos.produto_unidade.unidade' };
  }

  protected async podeAlterar(item: Setor): Promise<boolean> {
    return true;
  }

  protected afterLoad() {
    this.estoques = this.entidade.estoques;
    this.localizacoes = this.entidade.localizacoes;
    this.responsaveis = this.entidade.responsaveis;
    this.executoras = this.entidade.executoras;
    this.processos = this.entidade.processos;
    this.produtos = this.entidade.produtos;
    this.carregarTipoDestinoMerenda();

    this.storageService.filtrar(1, -1, { 'setor.id': this.entidade.id, orderBy: 'data_cadastro$DESC' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaArquivos = res.content;
      });
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  protected campoFoco(): ElementRef {
    return this.inputNome;
  }

  protected beforeSubmit() {
    try {
      if (!this.entidadeForm.get('nome').value) {
        throw new Error('Informe o Setor!');
      }
      if (!this.entidadeForm.get('unidade').value) {
        throw new Error('Informe a unidade administrativa!');
      }
      if (!this.entidadeForm.get('responsavel').value) {
        throw new Error('Informe o responsável!');
      }
      if (!this.entidadeForm.get('cargo').value) {
        throw new Error('Informe o cargo!');
      }
      if (!this.entidadeForm.get('cep').value) {
        throw new Error('Informe o CEP!');
      }
      if (!this.entidadeForm.get('telefone').value) {
        throw new Error('Informe o telefone!');
      }
      if (!this.entidadeForm.get('email').value) {
        throw new Error('Informe o e-mail!');
      }
      if (!this.entidadeForm.get('endereco').value) {
        throw new Error('Informe o endereço!');
      }
      if (!this.entidadeForm.get('bairro').value) {
        throw new Error('Informe o bairro!');
      }
      if (this.entidadeForm.get('merenda').value && !this.entidadeForm.get('destino_merenda').value) {
        throw new Error('Informe o Tipo Destino - Merenda!');
      }
      this.entidadeForm.get('estoques').setValue(this.estoques);
      this.entidadeForm.get('responsaveis').setValue(this.responsaveis);
      this.entidadeForm.get('executoras').setValue(this.executoras);
      this.entidadeForm.get('processos').setValue(this.processos);
      this.entidadeForm.get('produtos').setValue(this.produtos);
    } catch (e) {
      toastr.error(e.message);
      throw e;
    }
  }

  protected afterSubmit(modelo: Setor) {
    if (!this.limparTela)
      this.loadResource();
    window.scrollTo(0, 0);
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private carregarAutoCompletes() {
    // autocomplete para unidade
    this.unidadeAutoComplete = new EddyAutoComplete(this.entidadeForm.get('unidade'), this.unidadeService,
      'id', ['id', 'nome'], {
      orderBy: 'nome', 'ppa.id': this.login.ppa.id, 'orgao.id': this.login.orgao.id
    }, { number: ['id'], text: ['nome'] }
    );

    // autocomplete para pessoa
    this.pessoaAutoComplete = new EddyAutoComplete(this.entidadeForm.get('pessoa'), this.pessoaService,
      'id', ['nome'], { cidade_id: this.login.cidade.id, orgao_id: this.login.orgao.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );
  }

  public carregarResponsavel(pessoa: Pessoa) {
    this.entidadeForm.get('responsavel').setValue(pessoa);
  }

  public buscarCEP() {
    if (this.entidadeForm.get('cep').value) {
      let cep = this.entidadeForm.get('cep').value;
      this.viacepService.obterPorCPF(cep)
        .subscribe((endereco) => {
          if (!endereco) {
            toastr.warning('Endereço não foi encontrado')
            return;
          }
          this.entidadeForm.get('endereco').setValue(endereco.logradouro);
          this.entidadeForm.get('bairro').setValue(endereco.bairro);
        }, (err) => toastr.warning('Endereço não foi encontrado'));
    }
  }

  public atualizarResponsavel(res: SetorResponsavel) {
    this.entidadeForm.get('responsavel').setValue(res.responsavel);
  }

  public carregarTipoDestinoMerenda() {
    this.listaDestinoMerenda = [
      { id: 'M', nome: 'MUNICIPAL PARCIAL' },
      { id: 'MI', nome: 'MUNICIPAL INTEGRAL' },
      { id: 'E', nome: 'ESTADUAL PARCIAL' },
      { id: 'T', nome: 'ESTADUAL INTEGRAL' },
      { id: 'C', nome: 'CRECHE' },
      { id: 'F', nome: 'FILANTRÓPICA' },
      { id: 'H', nome: 'HÍBRIDO' },
    ]
  }

  public removeMerenda() {
    if (this.entidadeForm.get('merenda').value === false && this.entidadeForm.get('destino_merenda').value) {
      this.entidadeForm.get('destino_merenda').setValue(null)
    }
  }

}
