import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Contrato, Licitacao, Login, Page, Proponente, Proposta } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContratoService extends BaseResourceService<Contrato> {

  constructor(
    protected injector: Injector
  ) {
    super(`contratos`, injector);
  }

  public obterPorNumero(numero: number, orgaoId: number): Observable<Contrato> {
    return this.http.get<Contrato>(
      `${this.login.cidade.id}/${this.api}/filtrar?relations=${encodeURIComponent('favorecido,favorecido.tipo')}&numero$like=${numero}%&orgao_id=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterSaldo(orgao: number, id: number, desconsiderar?: {
    rcmsId?: number, compraId?: number
  }, saldoQuantidade?: boolean): Observable<{ saldo: number }> {
    if (saldoQuantidade) {
      return this.obterSaldoQtd(orgao, id, desconsiderar)
    } else {
      let params = this.converterParametrosStr(desconsiderar);
      return this.http.get<{ saldo: number }>(
        `${this.login.cidade.id}/${this.api}/saldo/${orgao}/${id}${params}`, this.httpOptions()).pipe(
          map(res => res),
          catchError(err => this.handleError(err))
        );
    }
  }

  public obterSaldoQtd(orgao: number, id: number, desconsiderar?: {
    rcmsId?: number, compraId?: number
  }): Observable<{ saldo: number }> {
    let params = this.converterParametrosStr(desconsiderar);
    return this.http.get<{ saldo: number }>(
      `${this.login.cidade.id}/${this.api}/saldo-qtd/${orgao}/${id}${params}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public proximoNumero(ano: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-numero/${ano}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorLicitacao(id: number, orgaoId: number): Observable<Page> {
    let uri = `relations=${encodeURIComponent('favorecido,favorecido.tipo,itens.produto_unidade.produto,itens.memorial')}&licitacao.id=${id}&orgao_id=${orgaoId}`;
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?${uri}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorContratoParaExportacaoRCMS(id: number, orgaoId: number): Observable<Page> {
    let relations = 'prazo,favorecido,licitacao.modalidade,modalidade,entrega,'
    // itens
    relations += 'itens.memorial.produto_unidade.produto,itens.memorial.produto_unidade.unidade,'
    // rpl
    relations += 'itens.memorial.rpl_itens.rpl.requerente,itens.memorial.rpl_itens.rpl.setor.estoques,itens.memorial.rpl_itens.rpl.exercicio,'
    // cotacoes
    relations += 'itens.memorial.rpl_itens.cotacoes.favorecido,itens.produto_unidade.produto.material.sub_grupo.sub_elementos.sub_elemento,itens.produto_unidade.unidade'

    let uri = `relations=${relations}&id=${id}&orgao_id=${orgaoId}`;
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?${uri}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterContratosPendentesLicitacao(entidade: Licitacao): Proponente[] {
    const listaPendentes = [];

    if (entidade?.proponentes?.length > 0) {
      for (const proponente of entidade.proponentes) {
        let contratoExcluido = entidade?.contratos?.filter(c => c?.excluido && c?.favorecido?.id === proponente?.favorecido?.id && c.favorecido.id);
        contratoExcluido = contratoExcluido.filter(contrato => contrato.favorecido.id !== (entidade.contratos.find(c => !c.excluido && c.favorecido.id === contrato.favorecido.id))?.favorecido?.id)

        if (!entidade.contratos?.find(
          c => c?.favorecido?.id === proponente?.favorecido?.id &&
            c?.itens?.find(
              i => {
                entidade?.itens?.find(
                  m => (
                    m?.produto_unidade?.id === i?.produto_unidade?.id &&
                    +i?.quantidade === +m?.quantidade &&
                    m?.propostas.find(
                      p => p?.situacao === 'DESCLASSIFICADO' &&
                        p?.proponente?.id === proponente?.id)))
              }))) {
          const vencedoras = Object.assign([], proponente?.propostas?.filter(
            p => p?.situacao === 'VENCEDOR' &&
              !(entidade?.contratos?.find(
                c => c?.favorecido?.id === proponente?.favorecido?.id && !c.excluido &&
                  c?.itens?.find(
                    i => i?.memorial?.id === p?.memorial?.id
                  )))));
          if (vencedoras?.length > 0) {
            const pendente = Object.assign({}, proponente);
            pendente.propostas = vencedoras;
            pendente.propostas.forEach(p => p.memorial.produto_unidade = entidade?.itens?.find(m => m?.id === p?.memorial?.id)?.produto_unidade)
            pendente.licitacao = Object.assign({}, entidade);
            delete pendente.licitacao.proponentes;
            delete pendente.licitacao.itens;

            listaPendentes.push(pendente);
          }
          if (contratoExcluido?.length > 0) {
            let prop = contratoExcluido.find(ce => ce.favorecido.id === proponente.favorecido.id)
            if (prop?.id) {
              const pendente = Object.assign({}, proponente);
              pendente.propostas = proponente.propostas.filter(p => p.memorial.produto_unidade = prop.itens.find(i => i?.memorial?.id === p?.memorial?.id)?.produto_unidade);
              pendente.propostas.forEach(p => p.memorial.produto_unidade = entidade?.itens?.find(m => m?.id === p?.memorial?.id)?.produto_unidade)
              pendente.licitacao = Object.assign({}, entidade);
              delete pendente.licitacao.proponentes;
              delete pendente.licitacao.itens;
              let propostas: Proposta[] = []
              for (let p = 0; p < pendente?.propostas?.length; p++) {
                if (+pendente.propostas[+p].quantidade_final !== 0) {
                  propostas.push(pendente.propostas[p]);
                }
              }
              pendente.propostas = propostas
              if (pendente.propostas?.length)
                listaPendentes.push(pendente);
            }
          }
        }
      }
    }
    let listaFavorecido = []
    for (const proponente of listaPendentes) {
      if (!listaFavorecido.find(f => f.favorecido.id === proponente.favorecido.id)) {
        listaFavorecido.push(proponente)
      } else {
        listaFavorecido.find(f => {
          if (f.favorecido.id === proponente.favorecido.id) {
            proponente.propostas.forEach(p => {
              if (!proponente.propostas.find(prop => prop.memorial.cota === p.memorial.cota && p.memorial.produto_unidade.id === prop.memorial.produto_unidade.id))
                f.propostas.push(p)
            })
          }
        })
      }
    }
    return listaFavorecido;
  }

  public alterarPrazos(contratoId: number, prazoId?: number, entregaId?: number) {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/prazos/${contratoId}/${prazoId}/${entregaId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public contratosVigentes(registroPreco: boolean, data1: string, data2: string, iniciadosPeriodo: boolean, desconsiderarAditivos: boolean): Observable<{}[]> {
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/tce-vigentes/${registroPreco ? 'S' : 'N'}/${data1}/${data2}/${iniciadosPeriodo ? 'S' : 'N'}/${desconsiderarAditivos ? 'S' : 'N'}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public relatorioExtrato(orgao: number, data1: string, data2: string, contrato: number, tipo: '*' | 'CONTRATO' | 'REGISTRO_PRECO' | 'ATA_REGISTRO'): Observable<{}[]> {
    if (!contrato) contrato = 0
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/relatorio-extrato/${orgao}/${data1}/${data2}/${contrato}/${tipo}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public relatorioRecurso(orgao: number, data1: string, data2: string, contrato: number, tipo: '*' | 'CONTRATO' | 'REGISTRO_PRECO' | 'ATA_REGISTRO'): Observable<{}[]> {
    if (!contrato) contrato = 0
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/relatorio-recurso/${orgao}/${data1}/${data2}/${contrato}/${tipo}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public listagemContrato(pagina: number, limite: number, parametros: {}): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/listagem-contrato/${pagina}/${limite}${this.converterParametrosStr(parametros)}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
