import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LiquidacaoRestoListComponent } from './liquidacao-resto-list/liquidacao-resto-list.component';
import { LiquidacaoRestoFormComponent } from './liquidacao-resto-form/liquidacao-resto-form.component';
import { LiquidacaoRestoRptComponent } from './liquidacao-resto-rpt/liquidacao-resto-rpt.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: LiquidacaoRestoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: LiquidacaoRestoFormComponent, canActivate: [AuthGuard] },
  { path: 'novo/:empenho', component: LiquidacaoRestoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: LiquidacaoRestoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: LiquidacaoRestoFormComponent, canActivate: [AuthGuard] },
  { path: 'relatorios', component: LiquidacaoRestoRptComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LiquidacaoRestoRoutingModule { }
