
import { Directive, Injector, OnDestroy } from '@angular/core';
import { FuncaoService, GlobalService, LoginContabil, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LdoService } from '../../../../planejamento/ldo/service/ldo.service';
import { PpaLeiService } from 'administrativo-lib';
import { SelectItem } from 'primeng/api/selectitem';

@Directive()
export class Demo2Ldo implements OnDestroy {
  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();
  listaOrgaos: SelectItem[];

  private dados: any;

  constructor(
    protected ano: number,
    protected ldoServico: LdoService,
    protected orgaos: number[] = [],
    dados?: any,
    listaOrgaos?: SelectItem[]) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
    if (dados) this.dados = dados;
    if (listaOrgaos) this.listaOrgaos = listaOrgaos;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async montarRelatorio() {
    if (!this.dados) {
      this.dados = await this.ldoServico.filtrar({
        tipo$in: 'D02,D20',
        'ppa.id': this.login.ppa.id,
        'orgao.id$in': this.orgaos,
        'ano': this.ano,
        orderBy: 'linha',
        relations: 'ppa.ppaleis'
      }).toPromise();
    }

    Relatorio.imprimirPersonalizado(
      'DEMONSTRATIVO II - LEI DE DIRETRIZES ORÇAMENTÁRIAS ANEXO DE METAS FISCAIS AVALIAÇÃO DO CUMPRIMENTO DAS METAS FISCAIS DO EXERCÍCIO ANTERIOR'
      , this.login.usuario.nome, this.login.usuario.sobrenome,
      this.login.orgao.nome, this.login.brasao,
      await this.conteudo(this.dados.content ? this.dados.content : this.dados),
      'landscape', 'DEMONSTRATIVO II - LDO',
      {
        linhas: {
          hLineWidth() {
            return 1;
          },
          vLineWidth() {
            return 1;
          },
          hLineColor() {
            return 'black';
          },
          paddingLeft() {
            return 3;
          },
          paddingRight() {
            return 3;
          }
        }
      });
  }

  private async conteudo(dados: any[]): Promise<{}[]> {
    if (dados.length === 0) return;

    let lista = this.funcaoService.agrupar(dados, ['descricao1'],
      ['valor1', 'valor2', 'valor3', 'valor4', 'valor5', 'valor6', 'valor10', 'valor11'])

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `PPA: ${this.login.ppa.ppaperiodo.ano1} - ${this.login.ppa.ppaperiodo.ano4}`, fontSize: 11,
        alignment: 'center', bold: true, colSpan: 9, border: [false, false, false, false]
      }, '', '', '', '', '', '', '', ''],
      [{
        text: `EXERCÍCIO: ${this.ano}`, fontSize: 10,
        alignment: 'center', bold: true, colSpan: 9, border: [true, true, true, false],
      }, '', '', '', '', '', '', '', ''],
      [{
        text: 'ESPECIFICAÇÃO',
        alignment: 'center',
        bold: true,
        border: [true, true, true, true], fillColor: '#f5f5f5', rowSpan: 2
      },
      {
        text: ` Metas Previstas em ${this.ano - 2} \n (a)`,
        bold: true, fontSize: 8,
        border: [true, true, true, true], fillColor: '#f5f5f5', rowSpan: 2, alignment: 'center',
      },
      {
        text: '%PIB', border: [true, true, true, true], alignment: 'center', fillColor: '#f5f5f5', rowSpan: 2
      },
      {
        text: '%RCL', border: [true, true, true, true], alignment: 'center', fillColor: '#f5f5f5', rowSpan: 2
      },
      {
        text: `Metas Realizadas em ${this.ano - 2} \n (b)`,
        alignment: 'center',
        bold: true, fontSize: 8,
        border: [true, true, true, true], fillColor: '#f5f5f5', rowSpan: 2
      },
      {
        text: '%PIB', border: [true, true, true, true], alignment: 'center', fillColor: '#f5f5f5', rowSpan: 2
      },
      {
        text: '%RCL', border: [true, true, true, true], alignment: 'center', fillColor: '#f5f5f5', rowSpan: 2
      },
      {
        text: 'VARIAÇÃO (II - I)',
        alignment: 'center',
        bold: true,
        border: [true, true, true, true], fillColor: '#f5f5f5', colSpan: 2
      }, ''
      ],
      ['', '', '', '', '', '', '',
        {
          text: 'Valor\n(c) = b - a', border: [true, true, true, true], alignment: 'center', fillColor: '#f5f5f5'
        },
        {
          text: '%\n (c/a) x 100', border: [true, true, true, true], alignment: 'center', fillColor: '#f5f5f5'
        }]
    ];

    // monta o agrupamento do relatório
    for (const item of lista) {
      registros.push([
        { text: item.registros[0].descricao1, fontSize: 8, border: [true, false, false, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false },
        {
          text: !item.totalizadores['valor1'] ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor1'], 2, true), alignment: 'right',
          fontSize: 8, border: [true, false, false, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: !item.totalizadores['valor2'] ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor2'], 2, true), alignment: 'right',
          fontSize: 8, border: [true, false, false, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: !item.totalizadores['valor10'] ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor10'], 2, true), alignment: 'right',
          fontSize: 8, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: !item.totalizadores['valor3'] ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor3'], 2, true), alignment: 'right',
          fontSize: 8, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: !item.totalizadores['valor4'] ? '' : this.funcaoService.convertToBrNumber(+item.totalizadores['valor4'], 2, true), alignment: 'right',
          fontSize: 8, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: !item.totalizadores['valor11'] ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor11'], 2, true), alignment: 'right',
          fontSize: 8, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: !item.totalizadores['valor5'] ? '' : this.funcaoService.convertToBrNumber(item.totalizadores['valor5'], 2, true), alignment: 'right',
          fontSize: 8, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        },
        {
          text: !item.totalizadores['valor6'] ? '' : this.funcaoService.convertToBrNumber((item.totalizadores['valor5'] / item.totalizadores['valor1']) * 100, 2, true), alignment: 'right',
          fontSize: 8, border: [true, false, true, false], bold: item.registros[0].somador || item.registros[0].titulo ? true : false
        }
      ]);
    }

    registros.push([
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] }
    ]);

    if (this.listaOrgaos?.length > 0) {
      registros.push([{ text: '\n\n', colSpan: 9, border: [false, false, false, false] }, '', '', '', '', '', '', '', '']);
      registros.push([{ text: this.listaOrgaos.filter(o => this.orgaos.some(org => org === o.value)).map(o => o.label).join('\n'), fontSize: 8, colSpan: 9, border: [false, false, false, false] }, '', '', '', '', '', '', '', '']);
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: false,
        headerRows: 1,
        widths: ['*', 70, 70, 70, 70, 70, 70, 70, 70],
        body: registros
      }
    }];
  }
}
