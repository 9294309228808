import { Injectable, Injector } from "@angular/core";
import { Comunicado } from "../../entidade/compra/comunicado.model";
import { BaseResourceService } from "../../models/services/base-resource.service";

@Injectable({
  providedIn: 'root'
})
export class ComunicadoService extends BaseResourceService<Comunicado> {

  constructor(
    protected injector: Injector
  ) {
    super(`comunicados`, injector);
  }

}