import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Login } from '../entidade/login/login';
import { GlobalService } from './global.service';
import jwt_decode from 'jwt-decode';
import * as bcrypt from 'bcryptjs';
import { Router } from '@angular/router';
import { LicitacaoStorage } from '../entidade/licitacao/licitacao-storage.model';
import { Memorial } from '../entidade/licitacao/memorial.model';
import { Licitacao } from '../entidade/licitacao/licitacao.model';
import { Proposta } from '../entidade/licitacao/proposta.model';
import { Contrato } from '../entidade/compra/contrato.model';
import { ContratoStorage } from '../entidade/compra/contrato-storage.model';
import { PlanoContratacaoAnual } from '../entidade/compra/plano-contratacao-anual.model';
import { PlanoContratacaoItem } from '../entidade/compra/plano-contratacao-item.model';
import { Empenho } from '../entidade/contabil/empenho.model';
import { Exercicio } from '../entidade/comum/exercicio.model';

@Injectable({
  providedIn: 'root'
})
export class PNCPService {

  protected http: HttpClient;

  protected homologacao: string = 'https://treina.pncp.gov.br/api/pncp';
  protected producao: string = 'https://pncp.gov.br/api/pncp';

  protected testes: boolean = false;
  public login: Login = new Login();
  private api = 'pncp-registros';

  /*
  Urls de Homologação:
  https://treina.pncp.gov.br/app/
  https://treina.pncp.gov.br/app/pca?pagina=1
  https://treina.pncp.gov.br/app/editais?q=&status=recebendo_proposta&pagina=1
  https://treina.pncp.gov.br/api/pncp/swagger-ui/index.html?configUrl=/pncp-api/v3/api-docs/swagger-config

  Urls de produção:
  https://www.gov.br/pncp/pt-br
  https://pncp.gov.br/app/pca?pagina=1
  https://pncp.gov.br/app/editais?q=&status=recebendo_proposta&pagina=1
  https://pncp.gov.br/api/pncp/swagger-ui/index.html?configUrl=/pncp-api/v3/api-docs/swagger-config
  */

  constructor(
    protected injector: Injector
  ) {
    this.http = injector.get(HttpClient);
    this.login = GlobalService.obterSessaoLogin();
  }

  private retornaUrl() {
    if (this.testes) {
      return this.homologacao;
    } else {
      return this.producao;
    }
  }

  private gerarHeader(): HttpHeaders {
    let token = this.login.token_pncp;
    if (!token) {
      token = '';
    }
    if (token.includes('Bearer')) {
      token = token.substring(7);
    }
    if (token.includes('JWT ')) {
      token = token.substring(4);
    }

    return new HttpHeaders({ 'Authorization': token });
  }

  private formatarToken(): string {
    let token = this.login.token_pncp;
    if (!token) {
      token = '';
    }
    if (token.includes('Bearer')) {
      token = token.substring(7);
    }
    if (token.includes('JWT ')) {
      token = token.substring(4);
    }

    return token;
  }



  //5.15. Consultar Amparo Legal
  /**
 * 
 * @param somenteAtivos - Paramentro opcional para trazer apenas amparos ativos.
 * @returns URL que permite consultar os amparos legais.
 */
  public async consultarAmparosPNCP(tipoAmparoLegalId?: number, somenteAtivos?: boolean): Promise<any> {
    try {
      return await this.http.get(`${this.retornaUrl()}/v1/amparos-legais?tipoAmparoLegalId=${tipoAmparoLegalId}${somenteAtivos ? '&statusAtivo=true' : ''}`).toPromise();
    } catch (error) {
      throw Error(error?.error?.message ? error?.error?.message : error.message)
    }
  }

  //5.18.1. Consultar conformidade entre Instrumento Convocatório, Modalidade de Contratação e Amparo Legal por Código
  /**
  * 
  * @param amparoLegalId - Código de identificação do amparo legal
  * @param modalidadeId - Código de identificação da modalidade de contratação
  * @param tipoInstrumentoConvocatorioId - Código de identificação do instrumento convocatório
  * @returns URL que permite consultar a combinação de conformidade pelo código do instrumento convocatório, código da modalidade de contratação e código do amparo legal.
  */
  public async consultarConformidadeConvatorioModalidadeAmparo(amparoLegalId: number, modalidadeId: number, tipoInstrumentoConvocatorioId: number): Promise<any> {
    try {
      return await this.http.get(`${this.retornaUrl()}/v1/instrumento-convocatorio-modalidade-amparo-legal/${amparoLegalId}/${modalidadeId}/${tipoInstrumentoConvocatorioId}`).toPromise();
    } catch (error) {
      throw (error?.error?.message ? error?.error?.message : error.message)
    }
  }

  //5.19.1. Consultar conformidade entre Instrumentos Convocatórios e Modos de Disputa por Código
  /**
  * 
  * @param tipoInstrumentoConvocatorioId - Código de identificação do instrumento convocatório
  * @param modoDisputaId - Código de identificação do modo de disputa
  * @returns URL que permite consultar a combinação de conformidade pelo código do instrumento convocatório e pelo código do modo de disputa.
  */
  public async consultarConformidadeConvatorioDisputa(tipoInstrumentoConvocatorioId: number, modoDisputaId: number): Promise<any> {
    try {
      return await this.http.get(`${this.retornaUrl()}/v1/tipo-instrumento-convocatorio-modo-disputa/${tipoInstrumentoConvocatorioId}/${modoDisputaId}`).toPromise();
    } catch (error) {
      throw (error?.error?.message ? error?.error?.message : error.message)
    }
  }

  //5.22.1. Consultar conformidade entre Modalidade de Contratação e Critério de Julgamento por Código
  /**
  * 
  * @param modalidadeId - Código de identificação da modalidade da contratação
  * @param criterioJulgamentoId - Código de identificação do critério de julgamento
  * @returns URL que  permite consultar as combinações de conformidade entre modalidade de contratação e/ou critério de julgamento ou todas as combinações cadastradas no PNCP.
  */
  public async consultarConformidadeModalidadeJulgamento(modalidadeId: number, criterioJulgamentoId: number): Promise<any> {
    try {
      return await this.http.get(`${this.retornaUrl()}/v1/modalidade-criterio-julgamento/${modalidadeId}/${criterioJulgamentoId}`).toPromise();
    } catch (error) {
      throw (error?.error?.message ? error?.error?.message : error.message)
    }
  }

  /**
   * 
   * @param ano - ano do objeto
   * @param sequencial - sequencial do objeto, enviar NULL quando PCA
   * @param tipo - E para licitações/contratos e afins, P para os Plano de contratações
   * @returns URL do item no Portal do PNCP.
   */
  public abrirPortalPNCP(cnpj: string, ano: number, sequencial: number, tipo: 'E' | 'P' | 'C'): string {
    const url = this.retornaUrl().replace('api/pncp', (tipo == 'E' ? 'editais/' : tipo == 'P' ? 'pca/' : 'contratos/'));
    return url + `${cnpj.replace(/\D/g, '')}/${ano}${sequencial ? '/' + sequencial : ''}`;
  }

  //Cada comentário deve ter 1 retorno, se estiver em branco, desenvolver a rotina. A menos que seja marcada como "não necessária"

  //SEÇÃO API 6.1 - Serviços de Usuário
  //6.1.1 - Atualizar Usuários, NÃO NECESSÁRIO, recomendar fazer pelo próprio portal do PNCP
  //6.1.2 - Consultar por id, NÃO NECESSÁRIA, sobrescrita pela 6.1.3

  //6.1.3 - Consultar usuário por Login ou CPF/CNPJ
  /**
   * 
   * @param login - Login do usuário no PNCP.
   * @param token - Token do usuário para o PNCP.
   * @returns - Dados do usuário se sucesso, contendo principalmente o campo "ID" e o array entesAutorizados[]
   */
  public consultarUsuarioPncp(login: string, token: string) {
    return this.http.get(`${this.retornaUrl()}/v1/usuarios?login=${login}`, { headers: new HttpHeaders({ 'Authorization': token }) });
  }

  //6.1.4 - Login de Usuário
  /**
   * 
   * @param login - Login do PNCP
   * @param senha - Senha para o login do PNCP
   * @returns Sucesso ou erro ao tentar logar, body é null. Chamar a API para pegar o token pelo metodo loginPncp()
   */
  public loginPncp(orgaoId: number): Observable<any> {
    const body = { orgaoId: orgaoId, testes: this.testes };
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/login-pncp`, body, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  //6.1.5 - Inserir Ente autorizados para usuário
  /**
   * 
   * @param id - ID do usuário, conforme rotina 6.1.3
   * @param cnpj - CNPJ do orgão, sem pontuação
   * @param token - Token de Login
   * @returns - Nada, se vier 200 deu certo, executar 6.1.3, em erro, retorna o erro.
   */
  public inserirEnteAutorizado(id: number, cnpj: string, token: string) {
    const body = { entesAutorizados: [cnpj] };
    return this.http.post<any[]>(`${this.retornaUrl()}/v1/usuarios/${id}/orgaos`, body, { headers: this.gerarHeader() }).pipe(map(res => res));
  }

  //6.1.6 - Remover Ente autorizado para usuário
  /**
    * 
    * @param id - ID do usuário, conforme rotina 6.1.3
    * @param cnpj - CNPJ do orgão, sem pontuação
    * @param token - Token de Login
    * @returns - Nada, se vier 200 deu certo, executar 6.1.3, em erro, retorna o erro.
    */
  public removerEnteAutorizado(id: number, cnpj: string, token: string) {
    const body = { entesAutorizados: [cnpj] };
    return this.http.delete<any[]>(`${this.retornaUrl()}/v1/usuarios/${id}/orgaos`, { headers: this.gerarHeader(), body: body }).pipe(map(res => res));
  }

  //SEÇÃO API 6.2 - Serviços de Orgãos
  //6.2.1 - Incluir Órgão
  /**
   * 
   * @param cnpj - CNPJ do orgão a incluir, sem pontuação
   * @returns URL com o Orgão criado no PNCP no Header, porém o ID é o proprio CNPJ, então usar o consultarOrgaoCnpj.
   */
  public inserirOrgao(cnpj: string) {
    const body = {
      cnpj: cnpj,
    }
    return this.http.post<any[]>(`${this.retornaUrl()}/v1/orgaos`, body, { headers: this.gerarHeader() }).pipe(map(res => res));
  }

  //6.2.1 - Atualizar Órgão
  /**
   * 
   * @param cnpj - CNPJ do orgão a atualizar, sem pontuação
   * @returns URL com o Orgão atualizado no PNCP no Header.
   */
  public atualizarOrgao(cnpj: string) {
    const body = {
      cnpj: cnpj,
    }
    return this.http.put<any[]>(`${this.retornaUrl()}/v1/orgaos`, body, { headers: this.gerarHeader() }).pipe(map(res => res));
  }

  //6.2.3 - Consultar Órgão por CNPJ
  /**
   * 
   * @param cnpj - CNPJ do orgão SEM PONTUACAO
   * @returns JSON contendo CNPJ e razão, se cadastrado
   */
  public consultarOrgaoCnpj(cnpj: string) {
    return this.http.get(`${this.retornaUrl()}/v1/orgaos/${cnpj}`);
  }

  //6.2.4 - Incluir Unidade
  /**
   * 
   * @param cnpj - CNPJ do orgão sem pontuações
   * @param ibge - Código do IBGE da cidade
   * @param codigo - Código da Unidade
   * @param nome  - Nome da Unidade
   * @returns Sucesso ou falha do cadastro da Unidade, se sucesso, é a rotina 6.2.6
   */
  public inserirUnidade(cnpj: string, ibge: string, codigo: string, nome: string) {
    const body = { codigoIBGE: ibge, codigoUnidade: codigo, nomeUnidade: nome };
    return this.http.post<any[]>(`${this.retornaUrl()}/v1/orgaos/${cnpj}/unidades`, body, { headers: this.gerarHeader() }).pipe(map(res => res));
  }

  //6.2.4 - Atualizar Unidade
  /**
   * 
   * @param cnpj - CNPJ do orgão sem pontuações
   * @param ibge - Código do IBGE da cidade
   * @param codigo - Código da Unidade
   * @param nome  - Nome da Unidade
   * @returns Sucesso ou falha da atualização da Unidade, se sucesso, é a rotina 6.2.6
   */
  public atualizarUnidade(cnpj: string, ibge: string, codigo: string, nome: string) {
    const body = { codigoIBGE: ibge, codigoUnidade: codigo, nomeUnidade: nome };
    return this.http.put<any[]>(`${this.retornaUrl()}/v1/orgaos/${cnpj}/unidades`, body, { headers: this.gerarHeader() }).pipe(map(res => res));
  }

  //6.2.6 - Consultar Unidade
  /**
   * 
   * @param cnpj - CNPJ do orgão sem pontuações
   * @param codigo - Código da unidade
   * @returns JSON contendo os dados da Unidade, do orgão e Municipio conforme Layout
   */
  public consultarUnidade(cnpj: string, codigo: string) {
    return this.http.get(`${this.retornaUrl()}/v1/orgaos/${cnpj}/unidades/${codigo}`);
  }
  //6.2.7 - Consultar unidades de um orgão
  /**
   * 
   * @param cnpj - CNPJ do orgão sem pontuações
   * @returns Array de JSON contendo dados da Unidades, do orgão, e do múnicipio conforme layout
   */
  public consultarUnidadesOrgao(cnpj: string) {
    return this.http.get(`${this.retornaUrl()}/v1/orgaos/${cnpj}/unidades`);
  }

  //SEÇÃO API 6.3 - Serviços de Contratação
  //6.3.1 - Inserir Contratação
  /**
   * 
   * @param licitacoes - Lista com os ID's a serem enviados/ratificados.
   * @returns - Progresso, com retorno contendo lista de erro ou de sucessos.
   */
  public inserirContratacao(licitacoes: Array<number>): Observable<any> {
    const body = { token: this.formatarToken(), orgao: this.login.orgao, exercicio: this.login.exercicio, idPncp: this.login.dados_pncp?.id, licitacoes: licitacoes, testes: this.testes };
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/enviar-licitacoes`, body, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  //6.3.2 - Retificar Totalmente Contratação

  //6.3.3 - Retificar Parcialmente Contratação
  /**
   * 
   * @param sequencial - sequencial da licitação no pncp
   * @param situacao - situação a qual está sendo colocada
   * @returns Objeto com erro e mensagem, ou sucesso.
   */
  public atualizarSituacaoContratacao(sequencial: number, situacao: number): Observable<any> {
    // const body = { situacaoCompraId: situacao };
    const body = {
      token: this.login.token_pncp,
      sequencial: sequencial,
      situacao: situacao,
      testes: this.testes,
      cnpj: this.login.orgao.cnpj,
      exercicio: this.login.exercicio.ano
    }
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/atualizar-licitacao`, body, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  //6.3.4 - Excluir Contratação
  /**
   * 
   * @param sequencial - Sequencial do PNCP a ser apagado
   * @param justificativa  - Justificativa da remoção.
   * @returns - Erro ou sucesso da requisição.
   */
  public apagarContratacao(licitacao: Licitacao): Observable<any> {

    const body = { token: this.formatarToken(), testes: this.testes, licitacao: licitacao.id, orgao: this.login.orgao.id }
    const options = this.httpOptions();
    options['body'] = body;
    return this.http.delete<any>(`${this.login.cidade.id}/${this.api}/apagar-licitacao`, options).pipe(map(res => res))
  }

  //6.3.5 - Consultar Contratação

  //6.3.6 - Inserir documento a Contratação
  /**
   * 
   * @param arquivo Arquivo LicitacaoStorage a ser enviado, necessário que o licitacao.sequencial_pncp esteja preenchido.
   * @returns Progress com erro ou sucesso.
   */
  public enviarArquivoLicitacao(arquivo: LicitacaoStorage, anoLicitacao: Exercicio): Observable<any> {
    const body = { token: this.login.token_pncp, arquivo: arquivo, testes: this.testes, orgao: this.login.orgao, exercicio: anoLicitacao, idPncp: this.login.dados_pncp?.id };
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/enviar-licitacao-arquivo`, body, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );

  }

  //6.3.7 - Excluir documento da Contratação
  public apagarArquivoLicitacao(sequencialLicitacao: number, ano: number, arquivo: number): Observable<any> {
    return this.http.delete<any>(`${this.retornaUrl()}/v1/orgaos/${this.login.orgao.cnpj.replace(/\D/g, '')}/compras/${ano}/${sequencialLicitacao}/arquivos/${arquivo}`,
      { headers: this.gerarHeader() }).pipe(map(res => res));
  }

  //6.3.8 - Consultar documentos da Contratação
  //6.3.9 - Baixar documento da Contratação

  //6.3.10 - Inserir Itens a uma Contratação

  //6.3.11 - Retificar totalmente item de Contratação
  public retificarItemContratacao(item: Memorial, licitacao?: Licitacao): Observable<any> {
    let criterio = -1;
    const lic = item.licitacao ? item.licitacao : licitacao;

    if (item.criterio) {
      criterio = item.criterio;
    } else {
      switch (lic.tipo_licitacao) {
        case 1:
          criterio = 1;
          break;
        case 2:
          criterio = 4;
          break;
        case 4:
          criterio = 5;
          break;
        case 5:
          criterio = 2;
          break;
        case 7:
          criterio = 6;
          break;
        default:
          criterio = -1;
      }
    }

    const body = {
      "numeroItem": item.ordem,
      "materialOuServico": (item.produto_unidade.produto.material.servico ? 'S' : 'M'),
      "tipoBeneficioId": item.tipo_beneficio,
      "incentivoProdutivoBasico": item.ppb,
      "descricao": item.produto_unidade.produto.descricao,
      "quantidade": item.quantidade,
      "unidadeMedida": item.produto_unidade.unidade.nome,
      "orcamentoSigiloso": item.sigiloso,
      "valorUnitarioEstimado": item.valor_referencia,
      "valorTotal": +(+item.quantidade * +item.valor_referencia).toFixed(4),
      "situacaoCompraItemId": "1",
      "criterioJulgamentoId": criterio,
      "patrimonio": item.patrimonio,
      "codigoRegistroImobiliario": item.codigo_imobiliario,
      "itemCategoriaId": item.categoria,
      "aplicabilidadeMargemPreferenciaNormal": item.opcao_normal,
      "percentualMargemPreferenciaNormal": item.percentual_normal,
      "aplicabilidadeMargemPreferenciaAdicional": item.opcao_adicional,
      "percentualMargemPreferenciaAdicional": item.percentual_adicional
    }

    if (!body.patrimonio) {
      delete body.patrimonio;
    }
    if (!body.codigoRegistroImobiliario) {
      delete body.codigoRegistroImobiliario;
    }

    if (!body.aplicabilidadeMargemPreferenciaNormal) {
      delete body.percentualMargemPreferenciaNormal;
    }
    if (!body.aplicabilidadeMargemPreferenciaAdicional) {
      delete body.percentualMargemPreferenciaAdicional;
    }

    return this.http.put<any[]>(`${this.retornaUrl()}/v1/orgaos/${this.login.orgao.cnpj.replace(/\D/g, '')}/compras/${this.login.exercicio.ano}/${lic.sequencial_pncp}/itens/${item.ordem}`,
      body, { headers: this.gerarHeader() }).pipe(map(res => res));
  }

  //6.3.12 - Retificar parcialmente item de Contratação
  /**
   * 
   * @param sequencial - sequencial da licitação no PNCP
   * @param itens - lista de itens, contendo apenas a ordem do item, e a nova situação
   * @returns - Lista de sucessos e erros.
   */
  public atualizarSituacaoItensContratacao(sequencial: number, itens: any[]): Observable<any> {
    const body = {
      token: this.login.token_pncp,
      sequencial: sequencial,
      itens: itens,
      testes: this.testes,
      cnpj: this.login.orgao.cnpj,
      exercicio: this.login.exercicio.ano
    }
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/atualizar-itens-licitacao`, body, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  //6.3.13 - Consultar itens de uma Contratação
  //6.3.14 - Consultar item de uma Contratação

  //6.3.15 - Inserir resultado de item de uma Contratação
  public enviarResultado(licitacaoId: number, resultados: Array<Proposta>): Observable<any> {
    const token = this.formatarToken();
    const body = { token: token, licitacaoId: licitacaoId, resultados: resultados, testes: this.testes, cnpj: this.login.orgao.cnpj.replace(/\D/g, ''), exercicio: this.login.exercicio.ano };
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/enviar-resultados-licitacao`, body, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  //6.3.16 - Retificar resultado de item de uma Contratação
  //6.3.17 - Consultar resultados de um item de uma Contratação
  //6.3.18 - Consultar resultado especifico de uma Contratação
  //6.3.19 - Consultar Histórico de uma Compra.
  public consultarHistoricoCompra(cnpj: string, ano: number, sequencial: number) {
    return this.http.get<any[]>(`${this.retornaUrl()}/v1/orgaos/${cnpj}/compras/${ano}/${sequencial}/historico`);
  }

  //SEÇÃO API 6.4 - Serviços de ATA 
  //6.4.1 - Inserir Ata de Registro de Preço
  //6.4.2 - Retificar Ata de Registro de Preço
  public enviarAtaRegistroPreco(atas: Array<number>, cancelado?: boolean): Observable<any> {
    const token = this.formatarToken();
    const body = { token: token, testes: this.testes, orgao: this.login.orgao, idPncp: this.login.dados_pncp?.id, cancelando: cancelado, atas: atas };
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/enviar-ata-preco`, body, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  //6.4.3 - Excluir Ata de Registro de Preço
  public excluirAtaRegistroPreco(contrato: Contrato): Observable<any> {
    return this.http.delete<any>(`${this.retornaUrl()}/v1/orgaos/${this.login.orgao.cnpj.replace(/\D/g, '')}/compras/${contrato.licitacao.exercicio.ano}/${contrato.licitacao.sequencial_pncp}/atas/${contrato.sequencial_pncp}`,
      { headers: this.gerarHeader() }).pipe(map(res => res));
  }

  //6.4.4 Consultar todas Ata de uma compra
  //6.4.5 Consultar Ata de Registro de Preço

  //6.4.6 - Inserir documento a uma ata
  public enviarArquivoAta(arquivo: ContratoStorage): Observable<any> {
    const token = this.formatarToken();
    const body = { token: token, testes: this.testes, arquivo: arquivo, idPncp: this.login.dados_pncp?.id };
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/enviar-ata-arquivo`, body, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  //6.4.7 - Excluir documento de uma ata
  public apagarArquivoAta(arquivo: ContratoStorage): Observable<any> {
    const ano = arquivo.contrato.licitacao.exercicio.ano;
    const sequencialLicitacao = arquivo.contrato.licitacao.sequencial_pncp;
    const sequencialAta = arquivo.contrato.sequencial_pncp;
    const sequencialArquivo = arquivo.sequencial_pncp;

    return this.http.delete<any>(`${this.retornaUrl()}/v1/orgaos/${this.login.orgao.cnpj.replace(/\D/g, '')}/compras/${ano}/${sequencialLicitacao}/atas/${sequencialAta}/arquivos/${sequencialArquivo}`,
      { headers: this.gerarHeader() }).pipe(map(res => res));
  }

  //6.4.8 - Consultar todos documentos de uma ata
  //6.4.9 - Consultar documento de uma ata
  //6.4.10 - Consultar histórico de uma ata
  public consultarHistoricoAta(cnpj: string, ano: number, sequencial: number, sequencialAta: number) {
    return this.http.get<any[]>(`${this.retornaUrl()}/v1/orgaos/${cnpj}/compras/${ano}/${sequencial}/atas/${sequencialAta}/historico`);
  }


  public enviarEmpenhos(dados): Observable<any> {
    // const token = this.formatarToken();
    const body = { token: this.formatarToken(), testes: this.testes, idPncp: this.login.dados_pncp?.id, envios: dados };
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/enviar-empenhos`, body, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  //SEÇÃO API 6.5 - Serviços de Contrato
  //6.5.1 - Inserir um contrato
  //6.5.2 - Retificar um contrato
  public enviarContratos(dados): Observable<any> {
    const body = { token: this.formatarToken(), testes: this.testes, idPncp: this.login.dados_pncp?.id, envios: dados };
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/enviar-contratos`, body, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  //6.5.3 - Excluir um contrato
  public excluirContrato(contrato: Contrato): Observable<any> {
    return this.http.delete<any>(`${this.retornaUrl()}/v1/orgaos/${this.login.orgao.cnpj.replace(/\D/g, '')}/contratos/${new Date(contrato.data_assinatura).getFullYear()}/${contrato.sequencial_pncp}`,
      { headers: this.gerarHeader() }).pipe(map(res => res));
  }

  //6.5.3 - Excluir um empenho
  public excluirEmpenho(empenho: Empenho): Observable<any> {
    const body = { token: this.formatarToken(), testes: this.testes, empenho: empenho, idPncp: this.login.dados_pncp?.id }
    return this.http.delete<any>(`${this.login.cidade.id}/${this.api}/apagar-empenho`, { body }).pipe(map(res => res))
  }

  //6.5.4 - Inserir documento a um contrato
  public enviarArquivoContrato(arquivo: ContratoStorage): Observable<any> {
    const token = this.formatarToken();
    const body = { token: token, testes: this.testes, arquivo: arquivo, idPncp: this.login.dados_pncp?.id };
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/enviar-contrato-arquivo`, body, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  //6.5.4 - Inserir documento a um contrato
  public enviarArquivoEmpenho(arquivo: string, idEmpenho: number): Observable<any> {
    const token = this.formatarToken();
    const body = { token: token, testes: this.testes, arquivo: arquivo, idPncp: this.login.dados_pncp?.id, idEmpenho };
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/enviar-empenho-arquivo`, body, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public apagarArquivoEmpenho(empenho: Empenho): Observable<any> {
    const body = { token: this.formatarToken(), testes: this.testes, empenho: empenho, idPncp: this.login.dados_pncp?.id }
    const options = this.httpOptions();
    options['body'] = body;
    return this.http.delete<any>(`${this.login.cidade.id}/${this.api}/apagar-contrato-arquivo`, { body }).pipe(map(res => res))
  }

  //6.5.5 - Excluir documento do contrato
  public apagarArquivoContrato(arquivo: ContratoStorage): Observable<any> {
    const ano = new Date(arquivo.contrato.data_assinatura).getFullYear();
    const sequencial = arquivo.contrato.sequencial_pncp;
    const sequencialArquivo = arquivo.sequencial_pncp;

    return this.http.delete<any>(`${this.retornaUrl()}/v1/orgaos/${this.login.orgao.cnpj.replace(/\D/g, '')}/contratos/${ano}/${sequencial}/arquivos/${sequencialArquivo}`,
      { headers: this.gerarHeader(), body: { "justificativa": arquivo['justificativa'] } }).pipe(map(res => res));
  }

  //6.5.6 - Consultar todos documentos de um contrato
  //6.5.7 - Consultar documento especifico de contrat
  //6.5.8 - Consultar um Contrato
  //6.5.9 - Consultar Histórico do Contrato
  public consultarHistoricoContrato(cnpj: string, ano: number, sequencial: number) {
    return this.http.get<any[]>(`${this.retornaUrl()}/v1/orgaos/${cnpj}/contratos/${ano}/${sequencial}/historico`);
  }

  //SEÇÃO API 6.6 - Serviços de Termo de Contrato
  //6.6.1 - Inserir Termo de Contrato
  //6.6.2 - Retificar Termo de Contrato
  public enviarTermos(termos: Array<number>, rescicoes: Array<number>): Observable<any> {
    const token = this.formatarToken();
    const body = { token: token, testes: this.testes, idPncp: this.login.dados_pncp?.id, orgao: this.login.orgao, exercicio: this.login.exercicio, termos: termos, rescicoes: rescicoes };
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/enviar-termos`, body, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  //6.6.3 - Excluir Termo de Contrato
  public apagarTermo(contrato: Contrato, sequencialTermo: number): Observable<any> {
    const ano = new Date(contrato.data_assinatura).getFullYear();
    const sequencial = contrato.sequencial_pncp;

    return this.http.delete<any>(`${this.retornaUrl()}/v1/orgaos/${this.login.orgao.cnpj.replace(/\D/g, '')}/contratos/${ano}/${sequencial}/termos/${sequencialTermo}`,
      { headers: this.gerarHeader() }).pipe(map(res => res));
  }

  //6.6.4 - Consultar Termo de Contrato
  //6.6.5 - Consultar todos Termo de Contrato

  //6.6.6 - Inserir documento a um Termo de Contrato
  public enviarArquivoTermo(arquivo: ContratoStorage): Observable<any> {
    const token = this.formatarToken();
    const body = { token: token, testes: this.testes, arquivo: arquivo, idPncp: this.login.dados_pncp?.id };
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/enviar-termo-arquivo`, body, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  //6.6.7 - Excluir documento de um Termo de Contrato
  public apagarArquivoTermo(arquivo: ContratoStorage, sequencialTermo: number): Observable<any> {
    const ano = new Date(arquivo.contrato.data_assinatura).getFullYear();
    const sequencial = arquivo.contrato.sequencial_pncp;
    const sequencialArquivo = arquivo.sequencial_pncp;

    return this.http.delete<any>(`${this.retornaUrl()}/v1/orgaos/${this.login.orgao.cnpj.replace(/\D/g, '')}/contratos/${ano}/${sequencial}/termos/${sequencialTermo}/arquivos/${sequencialArquivo}`,
      { headers: this.gerarHeader() }).pipe(map(res => res));
  }

  //6.6.8 - Consultar todos documentos de um Termo de Contrato
  //6.6.9 - Consultar documento de um Termo de Contrato

  //SEÇÃO API 6.7 - Serviços de plano de contratações
  //6.7.1 - Inserir plano de contratações
  public enviarPlanosContratacao(planos: Array<number>): Observable<any> {
    const token = this.formatarToken();
    const body = { token: token, testes: this.testes, orgao: this.login.orgao, idPncp: this.login.dados_pncp?.id, planos: planos };
    return this.http.post<any>(`${this.login.cidade.id}/${this.api}/enviar-plano-contratacoes`, body, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }
  //6.7.2 - Excluir plano de contratações
  public excluirPlanoContratacao(plano: PlanoContratacaoAnual): Observable<any> {
    return this.http.delete<any>(`${this.retornaUrl()}/v1/orgaos/${this.login.orgao.cnpj.replace(/\D/g, '')}/pca/${plano.exercicio.ano + 1}/${plano.sequencial_pncp}`,
      { headers: this.gerarHeader() }).pipe(map(res => res));
  }

  //6.7.3 - Consultar plano por órgão e ano
  //6.7.4 - Consultar plano das unidades por órgão e ano
  //6.7.5 - Consultar valores de plano de contratações de órgão por categoria
  //6.7.6 - Consultar plano consolidado por unidade e ano
  //6.7.7 - Consultar valores de um plano por categoria

  //6.7.8 - Inserir itens no plano de contratações
  public enviarItensPlano(plano: PlanoContratacaoAnual, listaItens: Array<PlanoContratacaoItem>): Observable<any> {
    const body = [];

    for (const item of listaItens) {
      item.data_desejada = new Date(item.data_desejada);

      const it = {
        "numeroItem": item.numero,
        "categoriaItemPca": item.categoria_item,
        "descricao": item.descricao,
        "unidadeFornecimento": item.unidade_fornecimento,
        "quantidade": +item.quantidade,
        "valorUnitario": +item.valor_unitario,
        "valorTotal": +(+item.quantidade * +item.valor_unitario).toFixed(4),
        "valorOrcamentoExercicio": item.valor_orcamentario,
        "unidadeRequisitante": item.unidade_requisitante,
        "dataDesejada": `${item.data_desejada.getFullYear()}-${item.data_desejada.getMonth() < 10 ? '0' + (item.data_desejada.getMonth() + 1) : item.data_desejada.getMonth() + 1}-${item.data_desejada.getDate() < 10 ? '0' + item.data_desejada.getDate() : item.data_desejada.getDate()}`,
        "grupoContratacaoCodigo": item.grupo_contratacao,
        "grupoContratacaoNome": item.grupo_contratacao_nome,
        "catalogo": item.catalogo,
        "classificacaoCatalogo": item.classificacao_catalogo,
        "codigoItem": item.codigo_item,
        "classificacaoSuperiorCodigo": item.classificacao_superior,
        "classificacaoSuperiorNome": item.classificacao_superior_nome,
        "pdmCodigo": item.pdm_codigo,
        "pdmDescricao": item.pdm_descricao
      };
      if (!it.pdmCodigo) {
        delete it.pdmCodigo;
        delete it.pdmDescricao;
      };
      if (!it.codigoItem) { delete it.codigoItem; }
      if (!it.descricao) { delete it.descricao; }

      if (!it.grupoContratacaoCodigo) {
        delete it.grupoContratacaoCodigo;
        delete it.grupoContratacaoNome;
      }

      body.push(it);
    }

    return this.http.post<any[]>(`${this.retornaUrl()}/v1/orgaos/${this.login.orgao.cnpj.replace(/\D/g, '')}/pca/${plano.exercicio.ano + 1}/${plano.sequencial_pncp}/itens`,
      body, { headers: this.gerarHeader() }).pipe(map(res => res));
  }

  //6.7.9 - Consultar itens de um plano de contratações por unidade e ano
  //6.7.10 - Retificar parcialmente item de plano de contratações
  //6.7.11 - Retificar parcialmente itens de plano de contratações
  //6.7.12 - Excluir um item de plano de contratações
  public excluirItemPlanoContratacao(plano: PlanoContratacaoAnual, item: PlanoContratacaoItem): Observable<any> {
    return this.http.delete<any>(`${this.retornaUrl()}/v1/orgaos/${this.login.orgao.cnpj.replace(/\D/g, '')}/pca/${plano.exercicio.ano + 1}/${plano.sequencial_pncp}/itens/${item.numero}`,
      { headers: this.gerarHeader() }).pipe(map(res => res));
  }
  //6.7.13 - Excluir itens de plano de contratações
  public excluirListaItensPlanoContratacao(plano: PlanoContratacaoAnual, itens: Array<PlanoContratacaoItem>): Observable<any> {
    const listaItens = [];
    for (const i of itens) {
      listaItens.push(i.numero);
    }
    const body = { listaNumerosItens: listaItens, justificativa: "" }
    return this.http.delete<any>(`${this.retornaUrl()}/v1/orgaos/${this.login.orgao.cnpj.replace(/\D/g, '')}/pca/${plano.exercicio.ano + 1}/${plano.sequencial_pncp}/itens`,
      { headers: this.gerarHeader(), body: body }).pipe(map(res => res));
  }
  //6.7.14 - Gerar CSV dos itens dos planos por órgão

  protected httpOptions(notProgress?: boolean) {
    this.login = GlobalService.obterSessaoLogin();
    const header = {
      'Content-Type': 'application/json',
    };
    if (this.login)
      header['Authorization'] = this.login.token;
    if (notProgress)
      header['Not-Progress'] = 'true';
    const teste = {
      headers: new HttpHeaders(this.adicionarCriptografia(header)),
      reportProgress: true
    };
    return teste;
  }

  protected adicionarCriptografia(header: {}) {
    this.login = GlobalService.obterSessaoLogin();
    if (this.login && this.login.token) {
      const tokenJson = jwt_decode(this.login.token) as any;
      const agora = new Date().getTime();
      const comparar = ((agora - +tokenJson.iat) / +tokenJson.id).toFixed(0);
      const criptografado = bcrypt.hashSync(comparar, tokenJson.chave);

      header['agora'] = agora + '';
      header['codigo'] = criptografado;

    }
    return header;
  }

  protected handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      this.login = GlobalService.obterSessaoLogin();

      if (this.login && this.login.usuario.sistema === 'transparencia') {
        this.login = null;
        GlobalService.limparSessaoLogin();
        location.reload();
      } else {
        toastr.warning('Sua sessão expirou, por favor faça o login novamente!', 'Sessão expirada');
        sessionStorage.clear();
        this.injector.get(Router).navigate(['/login']);
      }
    } else {
      return throwError(error);
    }
  }
}

