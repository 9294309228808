import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, Retencao, Page, Usuario } from 'eddydata-lib';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RetencaoService extends BaseResourceService<Retencao> {

  constructor(
    protected injector: Injector
  ) {
    super(`retencoes`, injector);
  }

  obterTotalRetidoPorLiquidacao(liquidacaoId: number): Observable<number> {
    return this.http.get<number>(
      // tslint:disable-next-line: max-line-length
      `${this.login.cidade.id}/${this.api}/total-retido-liquidacao?liquidacaoId=${liquidacaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public emAberto(
    exercicioId: number, orgaoId: number,
    parametros: { data_inicio: string, data_termino: string, orderBy?: string, relations?: string[] }
  ): Observable<any> {
    let filtros = `?data_inicio=${parametros.data_inicio}&data_termino=${parametros.data_termino}`;
    if (parametros.orderBy) {
      filtros += `&order_by=${parametros.orderBy}`;
    }
    if (parametros.relations) {
      filtros += `&relations=${parametros.relations.join(',')}`
    }

    return this.http.get(
      `${this.login.cidade.id}/${this.api}/em-aberto/${exercicioId}/${orgaoId}${filtros}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );

  }

  public obterPorLiquidacao(id: number): Observable<Page> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/extendido/?relations=liquidacao.pagamentos,liquidacao.orgao,liquidacao.exercicio,liquidacao.empenho,liquidacao.empenho.favorecido,ficha,ficha.plano,ficha.favorecido&liquidacao_id=${id}&orderBy=liquidacao.pagamentos.id`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public verificarPagamentoRetencao(retencaoId: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/verifica-pagamento?retencaoId=${retencaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public anular(retencoes: Array<Retencao>, data: string, historico: string, usuario: Usuario): Observable<any> {
    const anulacao = { data, historico, usuario, retencoes };
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/anular`, JSON.stringify(anulacao), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

}
