import { Directive, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { QuadroSaudeService } from '../service/quadro-saude.service';
import * as toastr from 'toastr';
import { FuncaoService, GlobalService, Login, Exercicio, Relatorio, ProgressoService, Coluna, FormatoExportacao, OrgaoAssinatura, OrgaoAssinaturaService } from 'eddydata-lib';

@Directive()
export class Quadro3Saude implements OnDestroy {
  private assinatura: OrgaoAssinatura;
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected quadroServico: QuadroSaudeService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected progressoService: ProgressoService,
    protected assinaturaService: OrgaoAssinaturaService) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async listar(orgaos: number[]) {
    return await this.quadroServico
      .obterQuadro3(this.mes, this.exercicio.id, orgaos).toPromise();
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
    let dados = []
    this.quadroServico.obterQuadro3(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async idTransacao => {
        this.progressoService.show(idTransacao, async (retorno) => {
          dados = retorno
          if (formato === 'pdf') {
            Relatorio.imprimirPersonalizado(
              'QUADRO 3 - DESPESAS ORÇAMENTÁRIAS - SAÚDE'
              , this.login.usuario.nome, this.login.usuario.sobrenome,
              this.login.orgao.nome, this.login.brasao,
              await this.conteudo(dados),
              'landscape', 'QUADRO 3 - DESPESAS ORÇAMENTÁRIAS - SAÚDE',
              {
                linhas: {
                  hLineWidth() {
                    return 1;
                  },
                  vLineWidth() {
                    return 1;
                  },
                  hLineColor() {
                    return 'black';
                  },
                  paddingLeft() {
                    return 3;
                  },
                  paddingRight() {
                    return 3;
                  }
                }
              });
          } else {
            this.funcaoService.exportar(formato, this.conteudoExportacao(dados), 'QUADRO 3 - DESPESAS ORÇAMENTÁRIAS - SAÚDE', this.colunasRelatorio());
          }
        });
      }, async error => {
        toastr.error(error.error.payload)
      });


  }

  private async conteudo(dados: any[]): Promise<{}[]> {

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `REFERÊNCIA: ${this.mes ? this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano : this.exercicio.ano}`,
        alignment: 'center',
        fontSize: 11,
        margin: [5, 5, 5, 5],
        bold: true,
        colSpan: 10
      }, '', '', '', '', '', '', '', '', ''],
      [{
        text: 'FUNÇÃO EDUCAÇÃO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'SUBFUNÇÕES',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'FONTE DE RECURSOS',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'CÓDIGO DE APLICAÇÃO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'CLASSIFIC. ECONÔMICA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'DOTAÇÃO INICIAL',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'DOTAÇÃO ATUALIZADA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'DESPESA EMPENHADA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'DESPESA LIQUIDADA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'DESPESA PAGA',
        alignment: 'center',
        bold: true, fontSize: 6
      }]
    ];

    // monta o agrupamento do relatório
    let i = 0;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    for (const dado of dados) {
      // FUNÇÕES DE GOVERNO --------------------------------------------------------------------------------
      const grupos = this.funcaoService.agrupar(dado, 'funcao',
        ['orcada', 'orcada_atualizada', 'empenhada', 'liquidada', 'paga']);
      i++;
      if (dado.length === 0) continue;
      registros.push([
        {
          text: i === 1 ? 'TÍPICAS' : 'ATÍPICAS',
          alignment: 'center',
          bold: true,
          colSpan: 10
        }, '', '', '', '', '', '', '', '', '']);
      for (const grupo of grupos) {
        const totalizaFuncao = this.funcaoService.agrupar(grupo.registros, ['elemento', 'aplicacao']);
        let orcada_funcao = 0;
        let atualizada_funcao = 0;
        for (const total of totalizaFuncao) {
          orcada_funcao += +total.registros[0]['orcada'];
          atualizada_funcao += +total.registros[0]['orcada_atualizada'];
        }
        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 7
          },
          {
            text: ''
          },
          {
            text: ''
          },
          {
            text: ''
          },
          {
            text: ''
          },
          {
            text: this.funcaoService.convertToBrNumber(+orcada_funcao),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(+atualizada_funcao),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhada']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidada']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(grupo.totalizadores['paga']),
            alignment: 'right', bold: true, fontSize: 7
          }
        ]);

        total1 += +orcada_funcao;
        total2 += +atualizada_funcao;
        total3 += +grupo.totalizadores['empenhada'];
        total4 += +grupo.totalizadores['liquidada'];
        total5 += +grupo.totalizadores['paga'];
        // SUB-FUNÇÕES DE GOVERNO --------------------------------------------------------------------------------
        const subfuncoes = this.funcaoService.agrupar(grupo.registros, 'subfuncao',
          ['orcada', 'orcada_atualizada', 'empenhada', 'liquidada', 'paga']);
        for (const subfuncao of subfuncoes) {
          const totalizaSubFuncao = this.funcaoService.agrupar(subfuncao.registros, ['elemento', 'aplicacao']);
          let orcada_subfuncao = 0;
          let atualizada_subfuncao = 0;
          for (const total of totalizaSubFuncao) {
            if (subfuncao.grupo === '122 ADMINISTRAÇÃO GERAL') console.log(total.registros[0]['orcada']);
            orcada_subfuncao += +total.registros[0]['orcada'];
            atualizada_subfuncao += +total.registros[0]['orcada_atualizada'];
          }
          subfuncao
          registros.push([
            {
              text: ''
            },
            {
              text: subfuncao.grupo, bold: true, fontSize: 7
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: this.funcaoService.convertToBrNumber(+orcada_subfuncao),
              alignment: 'right', bold: true, fontSize: 7
            },
            {
              text: this.funcaoService.convertToBrNumber(+atualizada_subfuncao),
              alignment: 'right', bold: true, fontSize: 7
            },
            {
              text: this.funcaoService.convertToBrNumber(subfuncao.totalizadores['empenhada']),
              alignment: 'right', bold: true, fontSize: 7
            },
            {
              text: this.funcaoService.convertToBrNumber(subfuncao.totalizadores['liquidada']),
              alignment: 'right', bold: true, fontSize: 7
            },
            {
              text: this.funcaoService.convertToBrNumber(subfuncao.totalizadores['paga']),
              alignment: 'right', bold: true, fontSize: 7
            }
          ]);
          // RECURSOS DE GOVERNO --------------------------------------------------------------------------------
          const recursos = this.funcaoService.agrupar(subfuncao.registros, 'recurso',
            ['orcada', 'orcada_atualizada', 'empenhada', 'liquidada', 'paga']);
          for (const recurso of recursos) {
            const totalizaRecurso = this.funcaoService.agrupar(recurso.registros, ['elemento', 'aplicacao']);
            let orcada_recurso = 0;
            let atualizada_recurso = 0;
            for (const total of totalizaRecurso) {
              orcada_recurso += +total.registros[0]['orcada'];
              atualizada_recurso += +total.registros[0]['orcada_atualizada'];
            }
            registros.push([
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: recurso.grupo, bold: true, fontSize: 7
              },
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: this.funcaoService.convertToBrNumber(+orcada_recurso),
                alignment: 'right', bold: true, fontSize: 7
              },
              {
                text: this.funcaoService.convertToBrNumber(+atualizada_recurso),
                alignment: 'right', bold: true, fontSize: 7
              },
              {
                text: this.funcaoService.convertToBrNumber(recurso.totalizadores['empenhada']),
                alignment: 'right', bold: true, fontSize: 7
              },
              {
                text: this.funcaoService.convertToBrNumber(recurso.totalizadores['liquidada']),
                alignment: 'right', bold: true, fontSize: 7
              },
              {
                text: this.funcaoService.convertToBrNumber(recurso.totalizadores['paga']),
                alignment: 'right', bold: true, fontSize: 7
              }
            ]);
            // APLICAÇÕES
            const aplicacoes = this.funcaoService.agrupar(recurso.registros, 'aplicacao',
              ['orcada', 'orcada_atualizada', 'empenhada', 'liquidada', 'paga']);
            for (const aplicacao of aplicacoes) {
              const totalizaAplicacao = this.funcaoService.agrupar(aplicacao.registros, 'elemento');
              let orcada_aplicacao = 0;
              let atualizada_aplicacao = 0;
              for (const total of totalizaAplicacao) {
                orcada_aplicacao += +total.registros[0]['orcada'];
                atualizada_aplicacao += +total.registros[0]['orcada_atualizada'];
              }
              registros.push([
                {
                  text: ''
                },
                {
                  text: ''
                },
                {
                  text: ''
                },
                {
                  text: aplicacao.grupo, bold: true, fontSize: 7
                },
                {
                  text: ''
                },
                {
                  text: this.funcaoService.convertToBrNumber(+orcada_aplicacao),
                  alignment: 'right', bold: true, fontSize: 7
                },
                {
                  text: this.funcaoService.convertToBrNumber(+atualizada_aplicacao),
                  alignment: 'right', bold: true, fontSize: 7
                },
                {
                  text: this.funcaoService.convertToBrNumber(aplicacao.totalizadores['empenhada']),
                  alignment: 'right', bold: true, fontSize: 7
                },
                {
                  text: this.funcaoService.convertToBrNumber(aplicacao.totalizadores['liquidada']),
                  alignment: 'right', bold: true, fontSize: 7
                },
                {
                  text: this.funcaoService.convertToBrNumber(aplicacao.totalizadores['paga']),
                  alignment: 'right', bold: true, fontSize: 7
                }
              ]);
              // ELEMENTO DE DESPESA --------------------------------------------------------------------------------
              const elementos = this.funcaoService.agrupar(aplicacao.registros, ['elemento', 'orcada', 'orcada_atualizada'],
                ['empenhada', 'liquidada', 'paga']);
              for (const elemento of elementos) {
                registros.push([
                  {
                    text: ''
                  },
                  {
                    text: ''
                  },
                  {
                    text: ''
                  },
                  {
                    text: ''
                  },
                  {
                    text: elemento.grupo['elemento'] ?? elemento.registros[0]['despesa'], bold: true, fontSize: 7
                  },
                  {
                    text: this.funcaoService.convertToBrNumber(elemento.grupo['orcada']),
                    alignment: 'right', bold: true, fontSize: 7
                  },
                  {
                    text: this.funcaoService.convertToBrNumber(elemento.grupo['orcada_atualizada']),
                    alignment: 'right', bold: true, fontSize: 7
                  },
                  {
                    text: this.funcaoService.convertToBrNumber(elemento.totalizadores['empenhada']),
                    alignment: 'right', bold: true, fontSize: 7
                  },
                  {
                    text: this.funcaoService.convertToBrNumber(elemento.totalizadores['liquidada']),
                    alignment: 'right', bold: true, fontSize: 7
                  },
                  {
                    text: this.funcaoService.convertToBrNumber(elemento.totalizadores['paga']),
                    alignment: 'right', bold: true, fontSize: 7
                  }
                ]);
                //  ITENS -------------------------------------------------------------------------------------------
                for (const item of elemento.registros) {
                  if ((+item.empenhada + +item.liquidada + +item.paga) !== 0) {
                    registros.push([
                      {
                        text: ''
                      },
                      {
                        text: ''
                      },
                      {
                        text: ''
                      },
                      {
                        text: item.aplicacao, fontSize: 7
                      },
                      {
                        text: item.despesa, fontSize: 7
                      },
                      { text: '-', alignment: 'center' },
                      { text: '-', alignment: 'center' },
                      { text: this.funcaoService.convertToBrNumber(item.empenhada), alignment: 'right', fontSize: 7 },
                      { text: this.funcaoService.convertToBrNumber(item.liquidada), alignment: 'right', fontSize: 7 },
                      { text: this.funcaoService.convertToBrNumber(item.paga), alignment: 'right', fontSize: 7 },
                    ]);
                  }
                }
              }
            }
          }
        }
      }
    }
    registros.push([
      {
        text: 'TOTAL', bold: true, fontSize: 7
      },
      {
        text: ''
      },
      {
        text: ''
      },
      {
        text: ''
      },
      {
        text: ''
      },
      {
        text: this.funcaoService.convertToBrNumber(+total1),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+total2),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+total3),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+total4),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+total5),
        alignment: 'right', bold: true, fontSize: 7
      }
    ]);

    if (this.login.parametro?.contabil?.assinatura_por_funcao) {
      this.assinatura = await this.assinaturaService.obter({ orgao_id: this.login.orgao.id }).toPromise();

      const assinatura = [
        [
          {
            text: '', alignment: 'center',
            border: [false, false, false, false], margin: [0, 30, 0, 0]
          },
          {
            text: this.assinatura.ordenador_despesa_funcao_10 ? '__________________________________________' : '', alignment: 'center',
            border: [false, false, false, false], margin: [0, 30, 0, 0]
          },
          {
            text: '', alignment: 'center',
            border: [false, false, false, false], margin: [0, 30, 0, 0]
          }
        ],
        [
          { text: '', alignment: 'center', border: [false, false, false, false] },
          { text: this.assinatura.ordenador_despesa_funcao_10 ? this.assinatura.ordenador_despesa_funcao_10 : '', alignment: 'center', border: [false, false, false, false] },
          { text: '', alignment: 'center', border: [false, false, false, false] }
        ],
        [
          { text: '', alignment: 'center', border: [false, false, false, false] },
          { text: this.assinatura.cargo_ordenador_despesa_funcao_10 ? this.assinatura.cargo_ordenador_despesa_funcao_10 : '', alignment: 'center', border: [false, false, false, false] },
          { text: '', alignment: 'center', border: [false, false, false, false] }
        ],
      ];

      return [{
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['auto', 'auto', 'auto', 'auto', 'auto', 55, 55, 55, 55, 55],
          body: registros
        }
      }, {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          heights: [10, 0],
          widths: ['*', '*', '*'],
          body: assinatura
        }
      }];
    } else {
      return [{
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['auto', 'auto', 'auto', 'auto', 'auto', 55, 55, 55, 55, 55],
          body: registros
        }
      }];
    }
  }

  private colunasRelatorio(): Coluna[] {
    return [
      { titulo: 'FUNÇÃO EDUCAÇÃO', coluna: 'funcao' },
      { titulo: 'SUBFUNÇÕES', coluna: 'subfuncao' },
      { titulo: 'FONTE DE RECURSOS', coluna: 'recurso' },
      { titulo: 'CÓDIGO DE APLICAÇÃO', coluna: 'aplicacao' },
      { titulo: 'CLASSIFICAÇÃO ECONÔMICA', coluna: 'classificacao' },
      { titulo: 'DOTAÇÃO INICIAL', coluna: 'inicial' },
      { titulo: 'DOTAÇÃO ATUALIZADA', coluna: 'atualizada' },
      { titulo: 'DOTAÇÃO EMPENHADA', coluna: 'empenhada' },
      { titulo: 'DOTAÇÃO LIQUIDADA', coluna: 'liquidada' },
      { titulo: 'DOTAÇÃO PAGA', coluna: 'paga' },
    ];
  }

  private conteudoExportacao(dados: any[]) {
    const listaItens = new Array();
    let i = 0;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    for (const dado of dados) {
      // FUNÇÕES DE GOVERNO --------------------------------------------------------------------------------
      const grupos = this.funcaoService.agrupar(dado, 'funcao', ['orcada', 'orcada_atualizada', 'empenhada', 'liquidada', 'paga']);
      i++;
      listaItens.push({
        'funcao': i === 1 ? 'TÍPICAS' : 'ATÍPICAS',
        'subfuncao': '',
        'recurso': '',
        'aplicacao': '',
        'classificacao': '',
        'inicial': '',
        'atualizada': '',
        'empenhada': '',
        'liquidada': '',
        'paga': '',
      });
      for (const grupo of grupos) {
        listaItens.push({
          'funcao': grupo.grupo,
          'subfuncao': '',
          'recurso': '',
          'aplicacao': '',
          'classificacao': '',
          'inicial': this.funcaoService.convertToBrNumber(grupo.totalizadores['orcada'], 2),
          'atualizada': this.funcaoService.convertToBrNumber(grupo.totalizadores['orcada_atualizada'], 2),
          'empenhada': this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhada'], 2),
          'liquidada': this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidada'], 2),
          'paga': this.funcaoService.convertToBrNumber(grupo.totalizadores['paga'], 2),
        });
        total1 += +grupo.totalizadores['orcada'];
        total2 += +grupo.totalizadores['orcada_atualizada'];
        total3 += +grupo.totalizadores['empenhada'];
        total4 += +grupo.totalizadores['liquidada'];
        total5 += +grupo.totalizadores['paga'];
        // SUB-FUNÇÕES DE GOVERNO --------------------------------------------------------------------------------
        const subfuncoes = this.funcaoService.agrupar(grupo.registros, 'subfuncao', ['orcada', 'orcada_atualizada', 'empenhada', 'liquidada', 'paga']);
        for (const subfuncao of subfuncoes) {
          listaItens.push({
            'funcao': '',
            'subfuncao': subfuncao.grupo,
            'recurso': '',
            'aplicacao': '',
            'classificacao': '',
            'inicial': this.funcaoService.convertToBrNumber(subfuncao.totalizadores['orcada'], 2),
            'atualizada': this.funcaoService.convertToBrNumber(subfuncao.totalizadores['orcada_atualizada'], 2),
            'empenhada': this.funcaoService.convertToBrNumber(subfuncao.totalizadores['empenhada'], 2),
            'liquidada': this.funcaoService.convertToBrNumber(subfuncao.totalizadores['liquidada'], 2),
            'paga': this.funcaoService.convertToBrNumber(subfuncao.totalizadores['paga'], 2),
          });
          // RECURSOS DE GOVERNO --------------------------------------------------------------------------------
          const recursos = this.funcaoService.agrupar(subfuncao.registros, 'recurso',
            ['orcada', 'orcada_atualizada', 'empenhada', 'liquidada', 'paga']);
          for (const recurso of recursos) {
            listaItens.push({
              'funcao': '',
              'subfuncao': '',
              'recurso': recurso.grupo,
              'aplicacao': '',
              'classificacao': '',
              'inicial': this.funcaoService.convertToBrNumber(recurso.totalizadores['orcada'], 2),
              'atualizada': this.funcaoService.convertToBrNumber(recurso.totalizadores['orcada_atualizada'], 2),
              'empenhada': this.funcaoService.convertToBrNumber(recurso.totalizadores['empenhada'], 2),
              'liquidada': this.funcaoService.convertToBrNumber(recurso.totalizadores['liquidada'], 2),
              'paga': this.funcaoService.convertToBrNumber(recurso.totalizadores['paga'], 2),
            });
            // ELEMENTO DE DESPESA --------------------------------------------------------------------------------
            const elementos = this.funcaoService.agrupar(recurso.registros, ['elemento', 'orcada', 'orcada_atualizada'], ['empenhada', 'liquidada', 'paga']);
            for (const elemento of elementos) {
              listaItens.push({
                'funcao': '',
                'subfuncao': '',
                'recurso': '',
                'aplicacao': '0000',
                'classificacao': elemento.grupo['elemento'] ?? elemento.registros[0]['despesa'],
                'inicial': this.funcaoService.convertToBrNumber(elemento.grupo['orcada'], 2),
                'atualizada': this.funcaoService.convertToBrNumber(elemento.grupo['orcada_atualizada'], 2),
                'empenhada': this.funcaoService.convertToBrNumber(elemento.totalizadores['empenhada'], 2),
                'liquidada': this.funcaoService.convertToBrNumber(elemento.totalizadores['liquidada'], 2),
                'paga': this.funcaoService.convertToBrNumber(elemento.totalizadores['paga'], 2),
              });
              //  ITENS -------------------------------------------------------------------------------------------
              for (const item of elemento.registros) {
                if ((+item.empenhada + +item.liquidada + +item.paga) !== 0) {
                  listaItens.push({
                    'funcao': '',
                    'subfuncao': '',
                    'recurso': '',
                    'aplicacao': item.aplicacao,
                    'classificacao': item.despesa,
                    'inicial': '-',
                    'atualizada': '-',
                    'empenhada': this.funcaoService.convertToBrNumber(item.empenhada, 2),
                    'liquidada': this.funcaoService.convertToBrNumber(item.liquidada, 2),
                    'paga': this.funcaoService.convertToBrNumber(item.paga, 2),
                  });
                }
              }
            }
          }
        }
      }
    }

    return listaItens;
  }

}
