import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ContaBancariaEntidade } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ContaBancariaEntidadeService extends BaseResourceService<ContaBancariaEntidade> {

  constructor(
    protected injector: Injector
  ) {
    super(`contas-bancarias-entidades`, injector);
  }

}
