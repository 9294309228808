import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FuncaoService, Exercicio } from 'eddydata-lib';

@Directive()
export class Anexo8Indicadores implements OnDestroy {

  protected funcaoService: FuncaoService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number,
    protected exercicio: Exercicio) {
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(lista1: any[], lista2: any[], lista3: any[]): {}[] {

    const registros: {}[] = [
      [
        { text: 'INDICADORES - Art. 212-A, inciso XI e § 3º - Constituição Federal', alignment: 'center', bold: true, fontSize: 7, colSpan: 3 }, '', '',
        { text: 'VALOR EXIGIDO (i)', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'VALOR APLICADO (j)', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'VALOR CONSIDERADO APÓS DEDUÇÕES (k)', alignment: 'center', bold: true, fontSize: 7 },
        { text: '% APLICADO (l)', alignment: 'center', bold: true, fontSize: 7 },
      ],
    ];

    const fundeb = this.funcaoService.agrupar(lista1, 'grupo', ['arrecadado', 'atualizado', 'arrecadado_anterior', 'atualizado_anterior']); 

    let valorExigido19: number = 0;
    let valorExigido20: number = 0;
    let valorExigido21: number = 0;
    let valorMax22: number = 0;
    let valorAplicado19: number = 0;
    let valorAplicado20: number = 0;
    let valorAplicado21: number = 0;
    let valorNaoAplicado22: number = 0;
    let deducoes19: number = 0;
    let deducoes20: number = 0;
    let deducoes21: number = 0;
    let ajuste22: number = 0;
    let aplicado19: number = 0;
    let aplicado20: number = 0;
    let aplicado21: number = 0;
    let naoAplicado22: number = 0;
    let superavitAnterior: number = 0;
    let aplicadoAnterior: number = 0;

    for (const item of fundeb) {

      let sum = 0;

      valorExigido19 = (((+item.totalizadores['arrecadado']) * 70) / 100);

      valorMax22 = (((+item.totalizadores['arrecadado']) * 10) / 100);

      superavitAnterior = ((+item.totalizadores['arrecadado_anterior']) * 10 / 100);

      let subgrupos1 = this.funcaoService.agrupar(item.registros, 'subgrupo', ['arrecadado', 'atualizado', 'arrecadado_anterior', 'atualizado_anterior']);

      for (let subgrupo of subgrupos1) {

        if (subgrupo.grupo === '6.3- FUNDEB - Complementação da União - VAAT') {

          sum = +subgrupo.totalizadores['arrecadado'];

          valorExigido20 = (sum * 50 / 100);
          valorExigido21 = (sum * 15 / 100);
        }
      }
      const grupos2 = this.funcaoService.agrupar(lista2, 'grupo', ['empenhado', 'liquidado', 'empenhado_anterior', 'liquidado_anterior', 'pago', 'resto', 'disponibilidade'])
      for (const grupo2 of grupos2) {

        if (grupo2.grupo === '13- Total das Despesas do FUNDEB com Profissionais da Educação Básica') {
          valorAplicado19 += +grupo2.totalizadores['empenhado'];
          deducoes19 = valorAplicado19 - grupo2.totalizadores['disponibilidade'];
          aplicado19 = (deducoes19 / +item.totalizadores['arrecadado']) * 100;
        }
        if (grupo2.grupo === '17- Total das Despesas custeadas com FUNDEB - Complementação da União - VAAT Aplicadas na Educação Infantil') {
          valorAplicado20 += +grupo2.totalizadores['empenhado'];
          deducoes20 = valorAplicado20 - grupo2.totalizadores['disponibilidade'];
          aplicado20 = (deducoes20 / +item.totalizadores['arrecadado']) * 100;
        }
        if (grupo2.grupo === '18- Total das Despesas custeadas com FUNDEB - Complementação da União - VAAT Aplicadas em Despesa de Capital') {
          valorAplicado21 += +grupo2.totalizadores['empenhado'];
          deducoes21 = valorAplicado21 - grupo2.totalizadores['disponibilidade'];
          aplicado21 = (deducoes21 / +item.totalizadores['arrecadado']) * 100;
        }
        if (grupo2.grupo === '14- Total das Despesas custeadas com FUNDEB - Impostos e Transferências de Impostos' || grupo2.grupo === '15- Total das Despesas custeadas com FUNDEB - Complementação da União - VAAF' || grupo2.grupo === '16- Total das Despesas custeadas com FUNDEB - Complementação da União - VAAT') {
          valorNaoAplicado22 += +grupo2.totalizadores['liquidado'];
          ajuste22 += +grupo2.totalizadores['disponibilidade'];
          naoAplicado22 = +item.totalizadores['arrecadado'];
          aplicadoAnterior = +item.totalizadores['arrecadado_anterior'];
        }
      }
      valorNaoAplicado22 = +item.totalizadores['arrecadado'] - valorNaoAplicado22;
      ajuste22 += valorNaoAplicado22;
      naoAplicado22 = (ajuste22 / naoAplicado22) * 100;
    }


    registros.push([
      { text: '19- Mínimo de 70% do FUNDEB na Remuneração dos Profissionais da Educação Básica', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: this.funcaoService.convertToBrNumber(valorExigido19), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(valorAplicado19), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(deducoes19), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(aplicado19), alignment: 'right', fontSize: 7, bold: true }
    ]);
    registros.push([
      { text: '20 - Percentual de 50% da Complementação da União ao FUNDEB (VAAT) na Educação Infantil', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: this.funcaoService.convertToBrNumber(valorExigido20), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(valorAplicado20), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(deducoes20), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(aplicado20), alignment: 'right', fontSize: 7, bold: true }
    ]);
    registros.push([
      { text: '21- Mínimo de 15% da Complementação da União ao FUNDEB - VAAT em Despesas de Capital', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: this.funcaoService.convertToBrNumber(valorExigido21), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(valorAplicado21), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(deducoes21), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(aplicado21), alignment: 'right', fontSize: 7, bold: true }
    ]);
    registros.push([
      { text: 'INDICADOR - Art.25, § 3º - Lei nº 14.113, de 2020 - (Máximo de 10% de Superávit)', alignment: 'center', bold: true, fontSize: 7, colSpan: 3 }, '', '',
      { text: 'VALOR MÁXIMO PERMITIDO (m)', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'VALOR NÃO APLICADO (n)', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'VALOR NÃO APLICADO APÓS AJUSTE (o)', alignment: 'center', bold: true, fontSize: 7 },
      { text: '% NÃO APLICADO (p)', alignment: 'center', bold: true, fontSize: 7 }
    ])
    registros.push([
      { text: '22- Total da Receita Recebida e não Aplicada no Exercício', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: this.funcaoService.convertToBrNumber(valorMax22), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(valorNaoAplicado22), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(ajuste22), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(naoAplicado22), alignment: 'right', fontSize: 7, bold: true }
    ]);
    registros.push([
      { text: 'INDICADOR - Art.25, § 3º - Lei nº 14.113, de 2020 - (Aplicação do Superávit de Exercício Anterior)', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'VALOR DE SUPERÁVIT PERMITIDO NO EXERCÍCIO ANTERIOR (q)', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'VALOR NÃO APLICADO NO EXERCÍCIO ANTERIOR (r)', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'VALOR DE SUPERÁVIT APLICADO ATÉ O PRIMEIRO QUADRIMESTRE (s)', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'VALOR APLICADO ATÉ O PRIMEIRO QUADRIMESTRE QUE INTEGRARÁ O LIMITE CONSTITUCIONAL (t)', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'VALOR APLICADO APÓS O PRIMEIRO QUADRIMESTRE (u)', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'VALOR NÃO APLICADO (v) = (r) - (s) - (u)', alignment: 'center', bold: true, fontSize: 7 },
    ])

    const grupos3 = this.funcaoService.agrupar(lista3, 'grupo', ['valor', 'superavit']);

    for (const grupo3 of grupos3) {

      registros.push([
        { text: grupo3.grupo, fontSize: 7, bold: true },
        { text: this.funcaoService.convertToBrNumber(+grupo3.totalizadores['valor']), alignment: 'right', fontSize: 7, bold: true }, 
        { text: this.funcaoService.convertToBrNumber(aplicadoAnterior), alignment: 'right', fontSize: 7, bold: true },
        { text: this.funcaoService.convertToBrNumber(+grupo3.totalizadores['superavit']), alignment: 'right', fontSize: 7, bold: true },
        { text: this.funcaoService.convertToBrNumber(superavitAnterior + aplicadoAnterior), alignment: 'right', fontSize: 7, bold: true },
        { text: '0,00', alignment: 'right', fontSize: 7, bold: true },
        { text: this.funcaoService.convertToBrNumber(aplicadoAnterior - +grupo3.totalizadores['superavit']), alignment: 'right', fontSize: 7, bold: true },
      ]);


      for (const subgrupo3 of grupo3.registros) {
        registros.push([
          { text: subgrupo3.subgrupo, fontSize: 7, bold: true },
          { text: this.funcaoService.convertToBrNumber(subgrupo3.valor), alignment: 'right', fontSize: 7, bold: true }, 
          { text: this.funcaoService.convertToBrNumber(aplicadoAnterior), alignment: 'right', fontSize: 7, bold: true },
          { text: this.funcaoService.convertToBrNumber(+subgrupo3.superavit), alignment: 'right', fontSize: 7, bold: true },
          { text: this.funcaoService.convertToBrNumber(superavitAnterior + aplicadoAnterior), alignment: 'right', fontSize: 7, bold: true },
          { text: '0,00', alignment: 'right', fontSize: 7, bold: true },
          { text: this.funcaoService.convertToBrNumber(aplicadoAnterior - +subgrupo3.superavit), alignment: 'right', fontSize: 7, bold: true },
        ]);

      }

      return registros;

    }

  }

  public montarQuadroCsv(lista1: any[], lista2: any[], lista3: any[], listaExportar): any { 

    const espaco = {
      titulo: '',
      coluna1: '',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
    }
    listaExportar.push(espaco)

    const primeira_linha = {
      titulo: 'INDICADORES - Art. 212-A, inciso XI e § 3º - Constituição Federal',
      coluna1: 'VALOR EXIGIDO (i)',
      coluna2: 'VALOR APLICADO (j)',
      coluna3: 'VALOR CONSIDERADO APÓS DEDUÇÕES (k)',
      coluna4: '% APLICADO (l)',
      coluna5: '',
      coluna6: '',
    }
    listaExportar.push(primeira_linha)

    const fundeb = this.funcaoService.agrupar(lista1, 'grupo', ['arrecadado', 'atualizado', 'arrecadado_anterior', 'atualizado_anterior']); 

    let valorExigido19: number = 0;
    let valorExigido20: number = 0;
    let valorExigido21: number = 0;
    let valorMax22: number = 0;
    let valorAplicado19: number = 0;
    let valorAplicado20: number = 0;
    let valorAplicado21: number = 0;
    let valorNaoAplicado22: number = 0;
    let deducoes19: number = 0;
    let deducoes20: number = 0;
    let deducoes21: number = 0;
    let ajuste22: number = 0;
    let aplicado19: number = 0;
    let aplicado20: number = 0;
    let aplicado21: number = 0;
    let naoAplicado22: number = 0;
    let superavitAnterior: number = 0;
    let aplicadoAnterior: number = 0;

    for (const item of fundeb) {

      let sum = 0;

      valorExigido19 = (((+item.totalizadores['arrecadado']) * 70) / 100);

      valorMax22 = (((+item.totalizadores['arrecadado']) * 10) / 100);

      superavitAnterior = ((+item.totalizadores['arrecadado_anterior']) * 10 / 100);

      let subgrupos1 = this.funcaoService.agrupar(item.registros, 'subgrupo', ['arrecadado', 'atualizado', 'arrecadado_anterior', 'atualizado_anterior']);

      for (let subgrupo of subgrupos1) {

        if (subgrupo.grupo === '6.3- FUNDEB - Complementação da União - VAAT') {

          sum = +subgrupo.totalizadores['arrecadado'];

          valorExigido20 = (sum * 50 / 100);
          valorExigido21 = (sum * 15 / 100);
        }
      }
      const grupos2 = this.funcaoService.agrupar(lista2, 'grupo', ['empenhado', 'liquidado', 'empenhado_anterior', 'liquidado_anterior', 'pago', 'resto', 'disponibilidade'])
      for (const grupo2 of grupos2) {

        if (grupo2.grupo === '13- Total das Despesas do FUNDEB com Profissionais da Educação Básica') {
          valorAplicado19 += +grupo2.totalizadores['empenhado'];
          deducoes19 = valorAplicado19 - grupo2.totalizadores['disponibilidade'];
          aplicado19 = (deducoes19 / +item.totalizadores['arrecadado']) * 100;
        }
        if (grupo2.grupo === '17- Total das Despesas custeadas com FUNDEB - Complementação da União - VAAT Aplicadas na Educação Infantil') {
          valorAplicado20 += +grupo2.totalizadores['empenhado'];
          deducoes20 = valorAplicado20 - grupo2.totalizadores['disponibilidade'];
          aplicado20 = (deducoes20 / +item.totalizadores['arrecadado']) * 100;
        }
        if (grupo2.grupo === '18- Total das Despesas custeadas com FUNDEB - Complementação da União - VAAT Aplicadas em Despesa de Capital') {
          valorAplicado21 += +grupo2.totalizadores['empenhado'];
          deducoes21 = valorAplicado21 - grupo2.totalizadores['disponibilidade'];
          aplicado21 = (deducoes21 / +item.totalizadores['arrecadado']) * 100;
        }
        if (grupo2.grupo === '14- Total das Despesas custeadas com FUNDEB - Impostos e Transferências de Impostos' || grupo2.grupo === '15- Total das Despesas custeadas com FUNDEB - Complementação da União - VAAF' || grupo2.grupo === '16- Total das Despesas custeadas com FUNDEB - Complementação da União - VAAT') {
          valorNaoAplicado22 += +grupo2.totalizadores['liquidado'];
          ajuste22 += +grupo2.totalizadores['disponibilidade'];
          naoAplicado22 = +item.totalizadores['arrecadado'];
          aplicadoAnterior = +item.totalizadores['arrecadado_anterior'];
        }
      }
      valorNaoAplicado22 = +item.totalizadores['arrecadado'] - valorNaoAplicado22;
      ajuste22 += valorNaoAplicado22;
      naoAplicado22 = (ajuste22 / naoAplicado22) * 100;
    }

    const linha_19 = {
      titulo: '19- Mínimo de 70% do FUNDEB na Remuneração dos Profissionais da Educação Básica',
      coluna1: this.funcaoService.convertToBrNumber(valorExigido19),
      coluna2: this.funcaoService.convertToBrNumber(valorAplicado19),
      coluna3: this.funcaoService.convertToBrNumber(deducoes19),
      coluna4: this.funcaoService.convertToBrNumber(aplicado19),
      coluna5: '',
      coluna6: ''
    }
    listaExportar.push(linha_19)

    const linha_20 = {
      titulo: '20 - Percentual de 50% da Complementação da União ao FUNDEB (VAAT) na Educação Infantil',
      coluna1: this.funcaoService.convertToBrNumber(valorExigido20),
      coluna2: this.funcaoService.convertToBrNumber(valorAplicado20),
      coluna3: this.funcaoService.convertToBrNumber(deducoes20),
      coluna4: this.funcaoService.convertToBrNumber(aplicado20),
      coluna5: '',
      coluna6: ''
    }
    listaExportar.push(linha_20)

    const linha_21 = {
      titulo: '21- Mínimo de 15% da Complementação da União ao FUNDEB - VAAT em Despesas de Capital',
      coluna1: this.funcaoService.convertToBrNumber(valorExigido21),
      coluna2: this.funcaoService.convertToBrNumber(valorAplicado21),
      coluna3: this.funcaoService.convertToBrNumber(deducoes21),
      coluna4: this.funcaoService.convertToBrNumber(aplicado21),
      coluna5: '',
      coluna6: ''
    }
    listaExportar.push(linha_21)

    const linha_indicador = {
      titulo: 'INDICADOR - Art.25, § 3º - Lei nº 14.113, de 2020 - (Máximo de 10% de Superávit)',
      coluna1: 'VALOR MÁXIMO PERMITIDO (m)',
      coluna2: 'VALOR NÃO APLICADO (n)',
      coluna3: 'VALOR NÃO APLICADO APÓS AJUSTE (o)',
      coluna4: '% NÃO APLICADO (p)',
      coluna5: '',
      coluna6: '',
    }
    listaExportar.push(linha_indicador)

    const linha_22 = {
      titulo: '22- Total da Receita Recebida e não Aplicada no Exercício',
      coluna1: this.funcaoService.convertToBrNumber(valorMax22),
      coluna2: this.funcaoService.convertToBrNumber(valorNaoAplicado22),
      coluna3: this.funcaoService.convertToBrNumber(ajuste22),
      coluna4: this.funcaoService.convertToBrNumber(naoAplicado22),
      coluna5: '',
      coluna6: ''
    }
    listaExportar.push(linha_22)

    const linha_indicador_2 = {
      titulo: 'INDICADOR - Art.25, § 3º - Lei nº 14.113, de 2020 - (Aplicação do Superávit de Exercício Anterior)',
      coluna1: 'VALOR DE SUPERÁVIT PERMITIDO NO EXERCÍCIO ANTERIOR (q)',
      coluna2: 'VALOR NÃO APLICADO NO EXERCÍCIO ANTERIOR (r)',
      coluna3: 'VALOR DE SUPERÁVIT APLICADO ATÉ O PRIMEIRO QUADRIMESTRE (s)',
      coluna4: 'VALOR APLICADO ATÉ O PRIMEIRO QUADRIMESTRE QUE INTEGRARÁ O LIMITE CONSTITUCIONAL (t)',
      coluna5: 'VALOR APLICADO APÓS O PRIMEIRO QUADRIMESTRE (u)',
      coluna6: 'VALOR NÃO APLICADO (v) = (r) - (s) - (u)'
    }
    listaExportar.push(linha_indicador_2)

    const grupos3 = this.funcaoService.agrupar(lista3, 'grupo', ['valor', 'superavit']);

    for (const grupo3 of grupos3) {
      const linha_grupo3 = {
        titulo: grupo3.grupo,
        coluna1: this.funcaoService.convertToBrNumber(+grupo3.totalizadores['valor']),
        coluna2: this.funcaoService.convertToBrNumber(aplicadoAnterior),
        coluna3: this.funcaoService.convertToBrNumber(+grupo3.totalizadores['superavit']),
        coluna4:this.funcaoService.convertToBrNumber(superavitAnterior + aplicadoAnterior),
        coluna5: '0,00',
        coluna6: this.funcaoService.convertToBrNumber(aplicadoAnterior - +grupo3.totalizadores['superavit'])
      }
      listaExportar.push(linha_grupo3)

      for (const subgrupo3 of grupo3.registros) {
        const linha_subgrupo3 = {
          titulo: subgrupo3.grupo,
          coluna1: this.funcaoService.convertToBrNumber(+subgrupo3.valor),
          coluna2: this.funcaoService.convertToBrNumber(aplicadoAnterior),
          coluna3: this.funcaoService.convertToBrNumber(+subgrupo3.superavit),
          coluna4:this.funcaoService.convertToBrNumber(superavitAnterior + aplicadoAnterior),
          coluna5: '0,00',
          coluna6: this.funcaoService.convertToBrNumber(aplicadoAnterior - +subgrupo3.superavit)
        }
        listaExportar.push(linha_subgrupo3)
      }
    }
  }
}