import { BaseResourceModel } from '../../models/base-resource.model';
import { LayoutSiops } from "./layout-siops.model";

export class LayoutSiopsItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public valor?: string,
    public layout?: LayoutSiops) {
    super();
  }
  static converteJson(json: any): LayoutSiopsItem {
    return Object.assign(new LayoutSiopsItem(), json);
  }
}