import { Component, Input } from '@angular/core';
import { Audesp4Edital, DateFormatPipe } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
import { DatePipe, formatDate } from '@angular/common';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-edital',
  templateUrl: './licitacao-audesp-edital.component.html'
})
export class LicitacaoAudespEditalComponent extends BaseLicitacaoAudesp {

  imaskPonto = {
    mask: /^[0-9.]*$/,
  }

  @Input() public entidade: Audesp4Edital;

  constructor(
  ) {
    super()
  }

}
