import { Injectable, Injector } from '@angular/core';
import { OrgaoSuplementacao } from '../../entidade/comum/orgao-suplementacao.model';
import { BaseResourceService } from '../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class OrgaoSuplementacaoService extends BaseResourceService<OrgaoSuplementacao> {

  constructor(
    protected injector: Injector
  ) {
    super(`orgaos-suplementacoes`, injector);
  }

}
