import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, DocumentoFiscalInfo } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class DocumentoFiscalInfoService extends BaseResourceService<DocumentoFiscalInfo> {

  constructor(
    protected injector: Injector
  ) {
    super('documento-fiscal-informacoes', injector);
  }
}
