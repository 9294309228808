import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuadroSaudeRoutingModule } from './quadro-saude-routing.module';
import { QuadroSaudeComponent } from './quadro-saude.component';
import { FormsModule } from '@angular/forms';
import { ListboxModule } from 'primeng/listbox';
import { SharedModule } from 'eddydata-lib';
import { FieldsetModule } from 'primeng/fieldset';

@NgModule({
  declarations: [QuadroSaudeComponent],
  imports: [
    CommonModule,
    QuadroSaudeRoutingModule,
    SharedModule,
    FormsModule,
    ListboxModule,
    FieldsetModule
  ]
})
export class QuadroSaudeModule { }
