import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { LicitacaoAudespFormAjusteComponent } from './ajuste/licitacao-audesp-form-ajuste/licitacao-audesp-form-ajuste.component';
import { LicitacaoAudespFormDocumentoFiscalComponent } from './documento-fiscal/licitacao-audesp-form-documento-fiscal/licitacao-audesp-form-documento-fiscal.component';
import { LicitacaoAudespFormEmpenhoComponent } from './empenho/licitacao-audesp-form-empenho/licitacao-audesp-form-empenho.component';
import { LicitacaoAudespFormExecucaoComponent } from './execucao/licitacao-audesp-form-execucao/licitacao-audesp-form-execucao.component';
import { LicitacaoAudespFormExigenciaObrasComponent } from './exigencia-obras/licitacao-audesp-form-exigencia-obras/licitacao-audesp-form-exigencia-obras.component';
import { LicitacaoAudespListComponent } from './licitacao-audesp-list/licitacao-audesp-list.component';
import { LicitacaoAudespFormPagamentoComponent } from './pagamento/licitacao-audesp-form-pagamento/licitacao-audesp-form-pagamento.component';
import { LicitacaoAudespFormRegistroPrecoComponent } from './registro-preco/licitacao-audesp-form-registro-preco/licitacao-audesp-form-registro-preco.component';
import { LicitacaoAudespFormTermoAditivoComponent } from './termo-aditivo/licitacao-audesp-form-termo-aditivo/licitacao-audesp-form-termo-aditivo.component';

const routes: Routes = [
  { path: '', component: LicitacaoAudespListComponent, canActivate: [AuthGuard] },

  { path: 'novo', component: LicitacaoAudespFormRegistroPrecoComponent, canActivate: [AuthGuard] },
  { path: 'novo/:licitacao', component: LicitacaoAudespFormRegistroPrecoComponent, canActivate: [AuthGuard] },
  { path: 'novo/compra-direta/:compra', component: LicitacaoAudespFormRegistroPrecoComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: LicitacaoAudespFormRegistroPrecoComponent, canActivate: [AuthGuard] },

  { path: ':registroPreco/ajuste/novo', component: LicitacaoAudespFormAjusteComponent, canActivate: [AuthGuard] },
  { path: ':registroPreco/ajuste/novo/:contrato', component: LicitacaoAudespFormAjusteComponent, canActivate: [AuthGuard] },
  { path: ':registroPreco/ajuste-empenho/novo', component: LicitacaoAudespFormAjusteComponent, canActivate: [AuthGuard] },
  { path: ':registroPreco/ajuste-empenho/novo/:empenho', component: LicitacaoAudespFormAjusteComponent, canActivate: [AuthGuard] },
  { path: ':registroPreco/ajuste/:id/editar', component: LicitacaoAudespFormAjusteComponent, canActivate: [AuthGuard] },

  { path: ':registroPreco/:ajuste/termo-aditivo/novo', component: LicitacaoAudespFormTermoAditivoComponent, canActivate: [AuthGuard] },
  { path: ':registroPreco/:ajuste/termo-aditivo/novo/:aditivo', component: LicitacaoAudespFormTermoAditivoComponent, canActivate: [AuthGuard] },
  { path: ':registroPreco/:ajuste/termo-aditivo/:id/editar', component: LicitacaoAudespFormTermoAditivoComponent, canActivate: [AuthGuard] },
  { path: ':registroPreco/:ajuste/execucao/novo', component: LicitacaoAudespFormExecucaoComponent, canActivate: [AuthGuard] },
  { path: ':registroPreco/:ajuste/execucao/novo/:empenho/:secundaria', component: LicitacaoAudespFormExecucaoComponent, canActivate: [AuthGuard] },
  { path: ':registroPreco/:ajuste/execucao/:id/editar', component: LicitacaoAudespFormExecucaoComponent, canActivate: [AuthGuard] },
  { path: ':registroPreco/:ajuste/empenho/novo', component: LicitacaoAudespFormEmpenhoComponent, canActivate: [AuthGuard] },
  { path: ':registroPreco/:ajuste/empenho/:id/editar', component: LicitacaoAudespFormEmpenhoComponent, canActivate: [AuthGuard] },
  { path: ':registroPreco/:ajuste/documento-fiscal/novo', component: LicitacaoAudespFormDocumentoFiscalComponent, canActivate: [AuthGuard] },
  { path: ':registroPreco/:ajuste/documento-fiscal/:id/editar', component: LicitacaoAudespFormDocumentoFiscalComponent, canActivate: [AuthGuard] },
  { path: ':registroPreco/:ajuste/pagamento/novo', component: LicitacaoAudespFormPagamentoComponent, canActivate: [AuthGuard] },
  { path: ':registroPreco/:ajuste/pagamento/:id/editar', component: LicitacaoAudespFormPagamentoComponent, canActivate: [AuthGuard] },
  { path: ':registroPreco/:ajuste/exigencia-obras/novo', component: LicitacaoAudespFormExigenciaObrasComponent, canActivate: [AuthGuard] },
  { path: ':registroPreco/:ajuste/exigencia-obras/:id/editar', component: LicitacaoAudespFormExigenciaObrasComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LicitacaoAudespRoutingModule { }
