import { Audesp4Empenho, Audesp4Publicacao, Audesp4TermoCiencia, Audesp4TipoTermoAditivo } from "./types/comum-type";
import { Audesp4AutorizacaoAssinaturaSim, Audesp4TermoAditivoJustificativaSim } from "./types/termo-aditivo-type";

export class TermoAditivo {

    CodigoAjuste: number;
    TermoAditivoCodigo: number;
    TermoAditivoNumero: string;
    TermoAditivoAno: number;
    TermoAditivoValor: number;
    TermoAditivoObjeto: string;
    TermoAditivoTipo: Audesp4TipoTermoAditivo[];
    TermoAditivoJustificativaSim: Audesp4TermoAditivoJustificativaSim;
    TermoAditivoJustificativaNao: 'S' | 'N';
    TermoAditivoInicioVigenciaDt: string;
    TermoAditivoFimVigenciaDt: string;
    TermoAditivoAssinaturaDt: string;
    NotaEmpenhoDocSimilarNao?: 'S' | 'N';
    NotaEmpenhoDocSimilarSim?: Audesp4Empenho[];
    PossuiExigenciaGarantia: 'S' | 'N';
    AutorizacaoAssinaturaNao?: 'S' | 'N';
    AutorizacaoAssinaturaSim?: Audesp4AutorizacaoAssinaturaSim;
    TermoAditivoPublicacaoSim?: Audesp4Publicacao[];
    TermoAditivoPublicacaoNao?: 'S' | 'N';
    TermoCienciaContratantes: Audesp4TermoCiencia[];
    TermoCienciaContratados: Audesp4TermoCiencia[];
    listTipos: { marcado: boolean, id: number }[];
    aditivoId: number;

    constructor(ignorarNovaInstancia?: boolean) {
        if (!ignorarNovaInstancia) {
            this.TermoAditivoJustificativaNao = 'N';
            this.NotaEmpenhoDocSimilarNao = 'N';
            this.PossuiExigenciaGarantia = 'N';
            this.AutorizacaoAssinaturaNao = 'N';
            this.TermoAditivoPublicacaoNao = 'N';
            this.TermoAditivoJustificativaSim = { JustificativaApresentada: undefined, JustificativaSim: 'S' }
            this.AutorizacaoAssinaturaSim = { TermoAditivoAutorizacaoDt: undefined, AutorizacaoSim: 'S' }
            this.listTipos = [
                { marcado: false, id: 2 },
                { marcado: false, id: 4 },
                { marcado: false, id: 13 },
                { marcado: false, id: 11 },
                { marcado: false, id: 8 },
                { marcado: false, id: 1 },
                { marcado: false, id: 7 },
                { marcado: false, id: 12 },
                { marcado: false, id: 10 },
                { marcado: false, id: 3 },
                { marcado: false, id: 5 },
                { marcado: false, id: 9 },
                { marcado: false, id: 6 },
            ]
        }
    }
}

export function listarTipos(): { id: number, nome: string }[] {
    return [
        { id: 2, nome: 'Acréscimo de valor por aumento de quantitativo' },
        { id: 4, nome: 'Acréscimo de valor por inclusão de itens, lotes ou itens de lotes' },
        { id: 13, nome: 'Alteração da natureza ou razão social do fornecedor' },
        { id: 11, nome: 'Alteração de dotação orçamentária' },
        { id: 8, nome: 'Modificação da forma de pagamento' },
        { id: 1, nome: 'Modificação do projeto ou das especificações técnicas' },
        { id: 7, nome: 'Modificação do regime de execução ou modo de fornecimento' },
        { id: 12, nome: 'Prorrogação do prazo contratual' },
        { id: 10, nome: 'Reajustamento de preços' },
        { id: 3, nome: 'Redução de valor por supressão de quantitativo' },
        { id: 5, nome: 'Redução de valor por supressão de itens, lotes ou itens de lotes' },
        { id: 9, nome: 'Reequilíbrio econômico-financeiro' },
        { id: 6, nome: 'Substituição de garantia de execução' },
    ]
}