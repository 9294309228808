// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Departamento } from './departamento.model';
import { Cargo } from './cargo.model';
import { Orgao } from '../comum/orgao.model';
import { Pessoa } from '../comum/pessoa.model';
import { Regime } from './regime.model';
import { FormaAdmissao } from './forma-admissao.model';

export class Funcionario extends BaseResourceModel {
  constructor(
    public id?: number,
    public carga_horaria?: number,
    public hora_semanal?: number,
    public tipo_salario?: number,
    public salario_base?: number,
    public data_admissao?: Date,
    public data_demissao?: Date,
    public codigo?: string,
    public ferias?: boolean,
    public data_ferias?: Date,
    public decimo_terceiro?: boolean,
    public previdencia?: boolean,
    public previdencia_reembolso?: boolean,
    public previdencia_empresa?: boolean,
    public calcular_irrf?: boolean,
    public calcular_media?: boolean,
    public calcular_fgts?: boolean,
    public data_fgts?: Date,
    public isencao_65?: boolean,
    public hora_extra?: boolean,
    public sefip?: boolean,
    public data_sefip?: Date,
    public observacao?: string,
    public departamento?: Departamento,
    public cargo?: Cargo,
    public pessoa?: Pessoa,
    public orgao?: Orgao,
    public regime?: Regime,
    public forma_admissao?: FormaAdmissao,
    public situacao?: string,
    ) {
    super();
  }
  static converteJson(json: any): Funcionario {
    return Object.assign(new Funcionario(), json);
  }
}
