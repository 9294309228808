import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AnexoLRFService } from './service/anexo-lrf.service';
import { DespesaPessoalLRF } from './relatorio/sp/despesa-pessoal.lrf';
import { BalancoOrcamentariolLRF } from './relatorio/sp/balanco-orcamentario.lrf';
import { ReceitaCorrenteLiquidaLRF } from './relatorio/sp/receita-corrente-liquida.lrf';
import { ResultadoPrimarioLRF } from './relatorio/sp/resultado-primario.lrf';
import { DividaConsolidadaLiquidaLRF } from './relatorio/sp/divida-consolidada-liquida.lrf';
import { FuncaoSubfuncaoLRF } from './relatorio/sp/funcao-subfuncao.lrf';
import { GestaoFiscalLRF } from './relatorio/sp/gestao-fiscal.lrf';
import { OperacaoCreditoLRF } from './relatorio/sp/operacao-credito.lrf';
import { RestosPagarLRF } from './relatorio/sp/restos-pagar.lrf';
import { ResultadoNominalLRF } from './relatorio/sp/resultado-nominal.lrf';
import { DisponibilidadeFinanceira2023LRF } from './relatorio/sp/disponibilidade-financeira-2023.lrf';
import { ReceitaAlienacaoLRF } from './relatorio/sp/receita-alienacao.lrf';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SelectItem } from 'primeng/api';
import * as toastr from 'toastr';
import {
  LoginContabil, Exercicio, ExercicioService,
  OrgaoService, FuncaoService, GlobalService, ProgressoService, ParametroContabilService, FormatoExportacao
} from 'eddydata-lib';
import { LdoService } from '../../../planejamento/ldo/service/ldo.service';
import { FichaReceitaService } from '../../../planejamento/ficha-receita/service/ficha-receita.service';
import { FichaDespesaService } from 'contabil-lib';
import { Anexo1RreoLRF } from './relatorio/federal/anexo1-rreo/anexo1-rreo.lrf';
import { AnexoLrfFederalService } from './service/anexo-lrf-federal.service';
import { Anexo2RreoLRF } from './relatorio/federal/anexo2-rreo/anexo2-rreo.lrf';
import { Anexo3RreoLRF } from './relatorio/federal/anexo3-rreo/anexo3-rreo.lrf';
import { Anexo6RreoLRF } from './relatorio/federal/anexo6-rreo/anexo6-rreo.lrf';
import { Anexo7RreoLRF } from './relatorio/federal/anexo7-rreo/anexo7-rreo.lrf';
import { Anexo9RreoLRF } from './relatorio/federal/anexo9-rreo/anexo9-rreo.lrf';
import { Anexo11RreoLRF } from './relatorio/federal/anexo11-rreo/anexo11-rreo.lrf';
import { Anexo1RgfLRF } from './relatorio/federal/anexo1-rgf/anexo1-rgf.lrf';
import { Anexo2RgfLRF } from './relatorio/federal/anexo2-rgf/anexo2-rgf.lrf';
import { Anexo3RgfLRF } from './relatorio/federal/anexo3-rgf/anexo3-rgf.lrf';
import { Anexo4RgfLRF } from './relatorio/federal/anexo4-rgf/anexo4-rgf.lrf';
import { Anexo5RgfLRF } from './relatorio/federal/anexo5-rgf/anexo5-rgf.lrf';
import { DisponibilidadeFinanceira2022LRF } from './relatorio/sp/disponibilidade-financeira-2022.lrf';
import { DemonstrativoReceitaDespesaPrevidenciaria } from './relatorio/sp/demonstativo-receita-e-despesa-previdenciaria.lrf';
import { Anexo8Ensino2022 } from './relatorio/federal/anexo8-rreo/2022/anexo8.ensino';
import { Anexo8Ensino2023 } from './relatorio/federal/anexo8-rreo/2023/anexo8.ensino';
import { DemonstrativoApuracaoCumprimento } from './relatorio/sp/apuracao-cumprimento.lrf';

@Component({
  selector: 'lib-anexo-lrf',
  templateUrl: './anexo-lrf.component.html'
})
export class AnexoLRFComponent implements OnInit, OnDestroy {

  public login: LoginContabil = new LoginContabil();
  public mes: number;
  public bimestre: number;
  public trimestre: number;
  public quadrimestre: number;
  public listaOrgaos: SelectItem[];
  public listaOrgaosPmCm: SelectItem[];
  public orgaos: number[] = [];
  public listaExercicios: Array<Exercicio>;
  public exercicioSelect: Exercicio;
  public listaAnexos: Array<any>;
  public anexoSelect: string = "A1";
  public opcao: string = 'mensal'
  public rgfOrgao: number = null;
  public assinaturaControleInterno: boolean = false;
  public exibir_zerado: boolean = false;

  public metaFiscal: number = 0;

  public lrf = 'SP';
  public listaLrf: Array<any>;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    protected frService: FichaReceitaService,
    protected fichaService: FichaDespesaService,
    protected exercicioService: ExercicioService,
    protected orgaoService: OrgaoService,
    protected funcaoService: FuncaoService,
    protected anexoServico: AnexoLRFService,
    protected anexoFederalServico: AnexoLrfFederalService,
    protected parametroContabilidadeServico: ParametroContabilService,
    protected ldoServico: LdoService,
    protected progressoService: ProgressoService,
    protected injector: Injector) {
    this.login = GlobalService.obterSessaoLogin();
    this.mes = 1;
    this.bimestre = 1;
    this.trimestre = 1;
    this.quadrimestre = 1;
    this.exercicioSelect = this.login.exercicio;
    this.orgaos.push(this.login.orgao.id);
  }

  ngOnInit() {
    this.rgfOrgao = this.login.orgao.id;
    this.orgaoService.filtrar(0, -1, { orderBy: 'codigo' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaOrgaos = [];
        this.listaOrgaosPmCm = [];
        for (const model of dados.content) {
          this.listaOrgaos.push({ label: `${model.codigo} - ${model.nome}`, value: model.id });
          if (model.id === 1 || model.id === 2) {
            this.listaOrgaosPmCm.push({ label: `${model.codigo} - ${model.nome}`, value: model.id });
          }
        }
      });
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaExercicios = dados.content;
      });

    // this.parametroContabilidadeServico.obter({ orgao_id: this.login.orgao.id }).pipe(takeUntil(this.unsubscribe))
    //   .subscribe(dados => {
    //     this.assinaturaControleInterno = dados.assinatura_controle_interno;
    //   });
    this.listaLrf = [
      { id: 'SP', nome: 'ESTADO DE SÃO PAULO' },
      { id: 'FEDERAL', nome: 'FEDERAL' },
    ];

    this.obterAnexos();

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  obterAnexos() {
    if (this.lrf === 'FEDERAL') {
      this.anexoSelect = 'A1';
      this.listaAnexos = [
        { id: 'A1', nome: 'RREO ANEXO 1 - BALANÇO ORÇAMENTÁRIO' },
        { id: 'A2', nome: 'RREO ANEXO 2 - EXECUÇÃO DAS DESPESAS POR FUNÇÃO/SUBFUNÇÃO' },
        { id: 'A3', nome: 'RREO ANEXO 3 - RECEITA CORRENTE LÍQUIDA' },
        //{ id: 'A4', nome: 'RREO ANEXO 4 - RECEITAS E DESPESAS PREVIDENCIÁRIAS' },
        { id: 'A6', nome: 'RREO ANEXO 6 - RESULTADOS PRIMÁRIO E NOMINAL' },
        { id: 'A7', nome: 'RREO ANEXO 7 - RESTOS A PAGAR POR PODER E ÓRGÃO' },
        { id: 'A8', nome: 'RREO ANEXO 8 - RECEITAS E DESPESAS COM MANUTENÇÃO E DESENVOLVIMENTO DO ENSINO - MDE' },
        { id: 'A9', nome: 'RREO ANEXO 9 - RECEITAS DE OPERAÇÕES DE CRÉDITO E DESPESAS DE CAPITAL (REGRA DE OURO)' },
        { id: 'A11', nome: 'RREO ANEXO 11 - RECEITA DE ALIENAÇÃO DE ATIVOS E APLICAÇÃO DOS RECURSOS' },
        { id: 'R1', nome: 'RGF ANEXO 1 - DESPESA COM PESSOAL' },
        { id: 'R2', nome: 'RGF ANEXO 2 - DÍVIDA CONSOLIDADA LÍQUIDA' },
        { id: 'R3', nome: 'RGF ANEXO 3 - GARANTIAS E CONTRAGARANTIAS DE VALORES' },
        { id: 'R4', nome: 'RGF ANEXO 4 - OPERAÇÕES DE CRÉDITO' },
        { id: 'R5', nome: 'RGF ANEXO 5 - DISPONIBILIDADE DE CAIXA E DOS RESTOS A PAGAR' }
      ];
    } else {
      this.anexoSelect = 'RREO';
      this.listaAnexos = [
        { id: 'RREO', nome: 'RELATÓRIO RESUMIDO DA EXECUÇÃO ORÇAMENTÁRIA - RREO' },
        { id: 'RCL', nome: 'RECEITA CORRENTE LÍQUIDA - RCL' },
        { id: 'DCL', nome: 'DÍVIDA CONSOLIDADA LÍQUIDA - DCL' },
        { id: 'DFS', nome: 'DEMONSTRATIVO DAS DESPESAS POR FUNÇÃO E SUBFUNÇÃO' },
        { id: 'DPE', nome: 'DEMONSTRATIVO DE APURAÇÃO DAS DESPESAS COM PESSOAL - EXECUTIVO' },
        { id: 'DPL', nome: 'DEMONSTRATIVO DE APURAÇÃO DAS DESPESAS COM PESSOAL - LEGISLATIVO' },
        { id: 'DRP', nome: 'DEMONSTRATIVO DO RESULTADO PRIMÁRIO' },
        { id: 'DRN', nome: 'DEMONSTRATIVO DO RESULTADO NOMINAL' },
        { id: 'RGF', nome: 'RELATÓRIO DE GESTÃO FISCAL' },
        { id: 'ROP', nome: 'DEMONSTRATIVO DAS RECEITAS DE OPERAÇÕES DE CRÉDITO E DESPESAS DE CAPITAL' },
        { id: 'RP', nome: 'DEMONSTRATIVO DE RESTOS A PAGAR' },
        { id: 'DDF', nome: 'DEMONSTRATIVO DAS DISPONIBILIDADES FINANCEIRAS' },
        { id: 'DRA', nome: 'DEMONSTRATIVO DA RECEITA DE ALIENAÇÃO DE ATIVOS E APLICAÇÃO DOS RECURSOS' },
        { id: 'DRD', nome: 'DEMONSTRATIVO DE RECEITAS E DESPESAS PREVIDENCIÁRIAS' },
        { id: 'DAC', nome: 'DEMONSTRATIVO DE APURAÇÃO DO CUMPRIMENTO OU NÃO DO ART. 42 DA LRF.' },
      ];
    }
  }

  // changeAnexo() {
  //   if (this.anexoSelect === 'DAC') {
  //     this.orgaos = [1, 2];
  //   }
  // }

  async imprimir(formato: FormatoExportacao) {
    this.assinaturaControleInterno = (await this.parametroContabilidadeServico.obter({ orgao_id: this.login.orgao.id }).toPromise())?.assinatura_controle_interno;
    if (this.lrf === 'FEDERAL') {
      this.apiFederal(formato);
    } else {
      this.apiSaoPauo(formato);
    }
  }

  private apiFederal(formato) {
    if (this.anexoSelect === 'A1') {
      const a1 = new Anexo1RreoLRF(this.anexoFederalServico, this.mes, this.exercicioSelect, this.injector, this.assinaturaControleInterno);
      a1.montarRelatorio(this.orgaos, formato);
    }
    if (this.anexoSelect === 'A2') {
      const a2 = new Anexo2RreoLRF(this.anexoFederalServico, this.mes, this.exercicioSelect, this.injector, this.assinaturaControleInterno);
      a2.montarRelatorio(this.orgaos, formato);
    }
    if (this.anexoSelect === 'A3') {
      const a3 = new Anexo3RreoLRF(this.anexoFederalServico, this.mes, this.exercicioSelect, this.injector, this.assinaturaControleInterno);
      a3.montarRelatorio(this.orgaos, formato);
    }
    if (this.anexoSelect === 'A4') {
      // 
    }
    if (this.anexoSelect === 'A6') {
      const a6 = new Anexo6RreoLRF(this.anexoFederalServico, this.mes, this.exercicioSelect, this.injector, this.assinaturaControleInterno);
      a6.montarRelatorio(this.orgaos, formato);
    }
    if (this.anexoSelect === 'A7') {
      const a7 = new Anexo7RreoLRF(this.anexoFederalServico, this.mes, this.exercicioSelect, this.injector, this.assinaturaControleInterno);
      a7.montarRelatorio(this.orgaos, formato);
    }
    if (this.anexoSelect === 'A8') {
      if (this.exercicioSelect.ano <= 2022) {
        const a8 = new Anexo8Ensino2022(this.anexoFederalServico, this.bimestre * 2, this.exercicioSelect, this.injector, this.progressoService, this.assinaturaControleInterno);
        a8.montarRelatorio(this.orgaos, formato);
      } else if (this.exercicioSelect.ano >= 2023) {
        const a8 = new Anexo8Ensino2023(this.anexoFederalServico, this.bimestre * 2, this.exercicioSelect, this.injector, this.progressoService, this.assinaturaControleInterno);
        a8.montarRelatorio(this.orgaos, formato);
      }
    }
    if (this.anexoSelect === 'A9') {
      const a9 = new Anexo9RreoLRF(this.anexoFederalServico, this.mes, this.exercicioSelect, this.injector, this.assinaturaControleInterno);
      a9.montarRelatorio(this.orgaos, formato);
    }
    if (this.anexoSelect === 'A11') {
      const a9 = new Anexo11RreoLRF(this.anexoFederalServico, this.mes, this.exercicioSelect, this.injector, this.assinaturaControleInterno);
      a9.montarRelatorio(this.orgaos, formato);
    }
    if (this.anexoSelect === 'R1') {
      const r1 = new Anexo1RgfLRF(this.anexoFederalServico, this.mes, this.exercicioSelect, this.injector, this.assinaturaControleInterno);
      r1.montarRelatorio(this.orgaos, formato);
    }
    if (this.anexoSelect === 'R2') {
      const r2 = new Anexo2RgfLRF(this.anexoFederalServico, this.mes, this.exercicioSelect, this.injector, this.assinaturaControleInterno);
      r2.montarRelatorio(this.orgaos, formato);
    }
    if (this.anexoSelect === 'R3') {
      const r3 = new Anexo3RgfLRF(this.anexoFederalServico, this.mes, this.exercicioSelect, this.injector, this.assinaturaControleInterno);
      r3.montarRelatorio(this.orgaos, formato);
    }
    if (this.anexoSelect === 'R4') {
      const r4 = new Anexo4RgfLRF(this.anexoFederalServico, this.mes, this.exercicioSelect, this.injector, this.assinaturaControleInterno);
      r4.montarRelatorio(this.orgaos, formato);
    }
    if (this.anexoSelect === 'R5') {
      const r5 = new Anexo5RgfLRF(this.anexoFederalServico, this.mes, this.exercicioSelect, this.injector, this.assinaturaControleInterno);
      r5.montarRelatorio(this.orgaos, formato);
    }
  }

  private apiSaoPauo(formato) {
    if (this.orgaos && this.orgaos.length > 0) { 
      if (this.anexoSelect === 'RP') {
        const rop = new RestosPagarLRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, this.progressoService, this.assinaturaControleInterno);
        rop.montarRelatorio(this.orgaos, formato);
      } else if (this.anexoSelect === 'RREO') {
        const dp = new BalancoOrcamentariolLRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, this.assinaturaControleInterno);
        dp.montarRelatorio(this.orgaos, formato);
      } else if (this.anexoSelect === 'RCL') {
        const rcl = new ReceitaCorrenteLiquidaLRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, this.assinaturaControleInterno);
        rcl.montarRelatorio(this.orgaos, formato);
      } else if (this.anexoSelect === 'DCL') {
        const rcl = new DividaConsolidadaLiquidaLRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, this.assinaturaControleInterno);
        rcl.montarRelatorio(this.orgaos, formato);
      } else if (this.anexoSelect === 'DFS') {
        const fs = new FuncaoSubfuncaoLRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, this.progressoService, this.assinaturaControleInterno);
        fs.montarRelatorio(this.orgaos, formato);
      } else if (this.anexoSelect === 'DPE') {
        const dp = new DespesaPessoalLRF(this.anexoServico, true, this.mes, this.exercicioSelect, this.injector, this.progressoService, this.assinaturaControleInterno, false);
        dp.montarRelatorio(this.orgaos, formato);
      } else if (this.anexoSelect === 'DPL') {
        const dp = new DespesaPessoalLRF(this.anexoServico, false, this.mes, this.exercicioSelect, this.injector, this.progressoService, this.assinaturaControleInterno, false);
        dp.montarRelatorio(this.orgaos, formato);
      } else if (this.anexoSelect === 'DRP') {
        const dp = new ResultadoPrimarioLRF(this.anexoServico, this.ldoServico, this.mes, this.metaFiscal, this.exercicioSelect, this.injector, this.assinaturaControleInterno);
        dp.montarRelatorio(this.orgaos, formato);
      } else if (this.anexoSelect === 'RGF') {
        const dp = new GestaoFiscalLRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, this.orgaoService, this.progressoService, this.assinaturaControleInterno);
        dp.montarRelatorio(this.orgaos, formato);
      } else if (this.anexoSelect === 'ROP') {
        const rop = new OperacaoCreditoLRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, this.assinaturaControleInterno);
        rop.montarRelatorio(this.orgaos, formato);
      } else if (this.anexoSelect === 'DRN') {
        const dp = new ResultadoNominalLRF(this.anexoServico, this.ldoServico, this.mes, this.metaFiscal, this.exercicioSelect, this.injector, this.assinaturaControleInterno);
        dp.montarRelatorio(this.orgaos, formato);
      } else if (this.anexoSelect === 'DDF') {
        if (this.exercicioSelect.ano >= 2023) {
          const dp = new DisponibilidadeFinanceira2023LRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, this.progressoService, this.assinaturaControleInterno);
          dp.montarRelatorio(this.orgaos, formato);
        } else {
          const dp = new DisponibilidadeFinanceira2022LRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, this.progressoService, this.assinaturaControleInterno);
          dp.montarRelatorio(this.orgaos, formato);
        }
      } else if (this.anexoSelect === 'DRA') {
        const dp = new ReceitaAlienacaoLRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, this.assinaturaControleInterno);
        dp.montarRelatorio(this.orgaos, formato);
      } else if (this.anexoSelect === 'DRD') {
        const dp = new DemonstrativoReceitaDespesaPrevidenciaria(this.anexoServico, this.mes, this.exercicioSelect, this.injector, this.progressoService);
        dp.montarRelatorio(this.orgaos, formato, this.exibir_zerado);
      } else if (this.anexoSelect === 'DAC') {
        const dp = new DemonstrativoApuracaoCumprimento(this.anexoServico, this.bimestre, this.exercicioSelect, this.injector, this.progressoService);
        dp.montarRelatorio(formato);
      } else {
        toastr.warning('Selecione o anexo a ser impresso');
      }
    } else {
      toastr.warning('Selecione o(s) órgão(s) a serem impressos');
    }
  }

  /**
   * Método para verificação de objetos, usados em combos `<select>`
   */
  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }

}
