import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BaseResourceListComponent, Coluna, DateFormatPipe, Filtro, FormatoExportacao, GlobalService, LoginPublico, TransferenciaBancaria
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TransferenciaBancariaService } from '../service/transferencia-bancaria.service';

@Component({
  selector: 'lib-transferencia-entre-orgao-lst',
  templateUrl: './transferencia-entre-orgao-lst.component.html'
})
export class TransferenciaEntreOrgaoLstComponent extends BaseResourceListComponent<TransferenciaBancaria, LoginPublico> implements OnInit {

  /**
   * Declaração de variáveis
   */
  public ptBR: any;
  public data1: Date;
  public data2: Date;

  protected datepipe: DatePipe;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private transferenciaService: TransferenciaBancariaService) {
    super(transferenciaService, injector);
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.data1 = new DateFormatPipe().transform(params['data1'], []);
        this.data2 = new DateFormatPipe().transform(params['data2'], []);
        this.paginaCorrente = +params['pagina'];
        this.filtro = params['filtro'];
      });
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  public editar(id: number) {
    const dt1 = this.datepipe.transform(this.data1, 'yyyy-MM-dd', 'GMT');
    const dt2 = this.datepipe.transform(this.data2, 'yyyy-MM-dd', 'GMT');
    if (!this.filtro || this.filtro === '') {
      this.router.navigate([
        `/transferencias-entre-orgaos/visualiza`, id, this.paginaCorrente, dt1, dt2]);
    } else {
      this.router.navigate([
        `/transferencias-entre-orgaos/visualiza`, id, this.paginaCorrente, dt1, dt2, this.filtro]);
    }
  }

  protected relations(): string {
    return 'conta_pagadora,conta_recebedora,exercicio,orgao';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id,
      ['exercicio.id']: this.login.exercicio.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['data_transfere$DESC', 'id$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      date: ['data_transfere'],
      number: ['conta_pagadora.numero_conta', 'conta_recebedora.numero_conta', 'valor_transferido'],
      text: ['conta_pagadora.nome', 'conta_recebedora.nome'],
    };
  }

  public beforeInit(): void {
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
    if (this.login.exercicio && !this.data1) {
      this.data1 = new Date(`01/01/${this.login.exercicio.ano}`);
      if (this.login.exercicio.ano !== new Date().getFullYear()) {
        this.data2 = new Date(`12/31/${this.login.exercicio.ano}`);
      }
    }
  }

  protected afterInit(): void {
    window.scrollTo(0, 0);
    this.ptBR = this.globalService.obterDataBR();
  }

  protected acaoRemover(model: TransferenciaBancaria): Observable<TransferenciaBancaria> {
    return null;
  }

  public obterParametros() {
    const parametros = {};
    this.datepipe = new DatePipe('pt');
    parametros['ignoreCondObrig'] = true;
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['data_transfere$ge'] = this.datepipe.transform(this.data1, 'yyyy-MM-dd');
    parametros['data_transfere$le'] = this.datepipe.transform(this.data2, 'yyyy-MM-dd');
    parametros['OR_1'] =
      `conta_pagadora.entidade_id$ne=${this.login.orgao.id};!;!;conta_recebedora.entidade_id$ne=${this.login.orgao.id}`;

    if (this.filtro) {
      const filtroOR = this.filtro.replace('.', '').replace(',', '.');
      parametros['OR_2'] =
        `conta_pagadora.numero_conta$like=${filtroOR}%;!;!;conta_recebedora.numero_conta$like=${filtroOR}%;!;!;` +
        `conta_pagadora.nome$like=${filtroOR}%;!;!;conta_recebedora.nome$like=${filtroOR}%;!;!;valor_transferido=${filtroOR}`;
    }

    parametros['relations'] = 'conta_pagadora,conta_recebedora,exercicio,orgao';

    switch (this.col) {
      case 1:
        parametros['orderBy'] = `data_transfere$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 2:
        parametros['orderBy'] = `conta_pagadora.nome$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 3:
        parametros['orderBy'] = `conta_recebedora.nome$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 4:
        parametros['orderBy'] = `valor_transferido$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      default:
        break;
    }

    return parametros;
  }

  public preencherGrid() {
    const parametros = this.obterParametros();
    this.transferenciaService
      .filtrar(
        this.paginaCorrente,
        this.login.limite,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.lista = lista.content;
          this.paginaTotal = lista.totalPages;
        },
        () => alert('erro ao retornar lista')
      );
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Data', coluna: 'data_transfere', agrupar: true },
      { titulo: 'Conta Pagadora', coluna: 'conta_pagadora.nome' },
      { titulo: 'Número conta pagadora', coluna: 'conta_pagadora.numero_conta' },
      { titulo: 'Conta Recebedora', coluna: 'conta_recebedora.nome' },
      { titulo: 'Número conta recebedora', coluna: 'conta_recebedora.numero_conta' },
      {
        titulo: 'Valor', coluna: 'valor_transferido', decimais: 2, alignment: 'right', funcao: [{
          se: {
            condicao: 'especie=D',
            verdadeiro: ['valor_transferido', '*', -1],
            falso: ['valor_transferido']
          }
        }]
      }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'conta_pagadora,conta_recebedora,exercicio,orgao';
    this.transferenciaService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE TRANSFERÊNCIAS BANCÁRIAS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem transferencia', ['*', 'auto', '*', 'auto', 'auto'], lista.content, ['valor_transferido']);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
  public onSelectPeriodo() {
    this.preencherGrid();
  }
}
