import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { EmpenhoRoutingModule } from './empenho-routing.module';
import { EmpenhoLstComponent } from './empenho-lst/empenho-lst.component';
import { EmpenhoShwComponent } from './empenho-shw/empenho-shw.component';


@NgModule({
  declarations: [
    EmpenhoLstComponent,
    EmpenhoShwComponent,
  ],
  imports: [
    CommonModule,
    EmpenhoRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
  ]
})
export class EmpenhoModule { }
