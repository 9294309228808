import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { TransparenciaRegistroComponent } from './transparencia-registro.component';

@NgModule({
  declarations: [TransparenciaRegistroComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    IMaskModule,
    SelectButtonModule,
    ToastModule
  ]
})
export class TransparenciaRegistroModule { }
