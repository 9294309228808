import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { IMaskModule } from 'angular-imask';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NavbarTransparenciaComponent } from './navbar-transparencia.component';
import { ToastModule } from 'primeng/toast';
import { BaseUrlInterceptor } from 'eddydata-lib';
import { environment } from '../../environments/environment';

@NgModule({
  declarations: [
    NavbarTransparenciaComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IMaskModule,
    AutoCompleteModule,
    ToastModule
  ],
  exports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NavbarTransparenciaComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: 'BASE_API_URL', useValue: environment.url },
    MessageService
  ]
})
export class CoreModule { }
