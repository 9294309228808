import {
    Audesp4Artigo16, Audesp4Artigo17, Audesp4AtestadoDesempenho, Audesp4Autorizacao, Audesp4ComissaoLicitacao, Audesp4ExistenciaRecursosSim, Audesp4IndiceEconomico, Audesp4LicitanteCNPJ, Audesp4LicitanteCPF, Audesp4LicitanteEstrangeiro, Audesp4PreQualificacaoSim, Audesp4Publicacao, Audesp4Recurso
} from "./types/comum-type";
import { Audesp4ConcorrenciaInternacional } from "./types/modalidade-type";
import { Audesp4ComprasServicosTI, Audesp4ComprasServicos, Audesp4ObrasServicosEngenharia } from "./types/tipo-objeto-type";

export class RegistroPrecosNao2 {
    ConcorrenciaInternacional: Audesp4ConcorrenciaInternacional;
    LicitacaoPossuiParticipantes: 'S' | 'N';
    CodigoLicitacao: number;
    NumeroProcessoAdm: string;
    AnoProcessoAdm: number;
    NumeroLicitacao: string;
    AnoLicitacao: number;
    BIDObjecaoAvisoAberturaPreQualificacao?: 'S' | 'N';
    BIDEditalFasePreQualificacao?: 'S' | 'N';
    BIDJulgamentoFasePreQualificacao?: 'S' | 'N';
    BIDEdital2aFase?: 'S' | 'N';
    BIDJulgamentoPropostasTecnicasComerciais?: 'S' | 'N';
    BIDJulgamentoNegociacaoFinal?: 'S' | 'N';
    Lei13121: 'S' | 'N';
    NaturezaLicitacao: number;
    DescricaoObj: string;
    JustificativaObj?: string;
    VlTotalLicitacao: number;
    TipoLicitacao: number;
    Subcontratacao: 'S' | 'N';
    BeneficioLei1232006: number;
    PreQualificacaoNao?: 'N';
    PreQualificacaoSim?: Audesp4PreQualificacaoSim;
    NumeroLotes: number;
    ComprasServicosTI?: Audesp4ComprasServicosTI;
    ComprasServicos?: Audesp4ComprasServicos;
    ObrasServicosEngenharia?: Audesp4ObrasServicosEngenharia;
    ExistenciaRecursosSim?: Audesp4ExistenciaRecursosSim;
    ExistenciaRecursosNao?: 'N';
    Artigo16_ns3?: Audesp4Artigo16;
    Artigo17_ns3?: Audesp4Artigo17;
    NaoSeEnquadra?: 'S';
    ExisteParecerTecnicoJuridico: 'S' | 'N';
    AudienciaPublicaNao?: 'N';
    AudienciaPublicaDt?: string;
    ExisteAutorizacaoAberturaEdital2aFase: 'S' | 'N';
    AvisoEdital2aFasePublicacao: Audesp4Publicacao[];
    Edital2aFaseNumero: string;
    Edital2aFaseDt: string;
    Edital2aFasePublicacaoNao?: 'N';
    Edital2aFasePublicacao?: Audesp4Publicacao[];
    ExisteExigenciaGarantiaParticipacaoLicitacao: 'S' | 'N';
    EntregaPropostaNao?: 'N';
    EntregaPropostaDt?: string;
    AberturaLicitacaoDt?: string;
    JulgamentoDt?: string;
    ComissaoLicitacao?: Audesp4ComissaoLicitacao;
    LicitanteCPF?: Audesp4LicitanteCPF[];
    LicitanteCNPJ?: Audesp4LicitanteCNPJ[];
    LicitanteEstrangeiro?: Audesp4LicitanteEstrangeiro[];
    AtestadoDesempenho?: Audesp4AtestadoDesempenho[];
    IndiceEconomico?: Audesp4IndiceEconomico[];
    TributosFederais: 'S' | 'N';
    TributosEstaduais: 'S' | 'N';
    TributosMunicipais: 'S' | 'N';
    ExisteAtaAberturaPropostasTecnicasComerciais: 'S' | 'N';
    ExisteAtaRecebimentoPropostasTecnicasComerciais: 'S' | 'N';
    ExisteAtaJulgamentoPropostasTecnicasComerciais: 'S' | 'N';
    AtaAberturaJulgamentoHabilitacaoPublicacao?: Audesp4Publicacao[];
    ExisteProcedimentoPropostaComercial: 'S' | 'N';
    FoiRealizadoChamadoNegociacao: 'S' | 'N';
    ForamRegistradasAtasReunioesNegociacao: 'S' | 'N';
    ExistePropostaFinal: 'S' | 'N';
    ExisteAtaJulgamentoFinal: 'S' | 'N';
    ExisteQuadroComparativo: 'S' | 'N';
    Recurso?: Audesp4Recurso[];
    PublicacaoHomologacaoNao?: 'N';
    PublicacaoHomologacaoDt?: string;
    AdjudicacaoNao?: 'N';
    AdjudicacaoDt?: string;
    Autorizacao?: Audesp4Autorizacao;
    SituacaoLicitacao: number;
    DtSituacaoLicitacao: string;
    DescricaoSituacaoLicitacao?: string;
}