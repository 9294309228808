// tslint:disable: variable-name
import { Usuario } from '../comum/usuario.model';
import { Protocolo } from './protocolo.model';
import { ProtocoloArquivo } from './protocolo-arquivo.model';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Setor } from '../almoxarifado/setor.model';

export class ProtocoloHistorico extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_historico?: Date,
    public comentario?: string,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public lido?: boolean,
    public enviar_email?: boolean,
    public sigiloso?: boolean,
    public lido_por?: Usuario,
    public recebido_por?: Usuario,
    public usuario?: Usuario,
    public remetente?: Setor,
    public destinatario?: Setor,
    public protocolo?: Protocolo,
    public arquivos?: ProtocoloArquivo[]
  ) {
    super();
  }
  static converteJson(json: any): ProtocoloHistorico {
    return Object.assign(new ProtocoloHistorico(), json);
  }
}
