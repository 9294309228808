import { DIA_TIME } from '../components/types';

export class Boleto {

  agencia: string;
  dvAgencia: string;
  contaCorrente: string;
  dvContaCorrente: string;
  moeda: string = '9';
  carteira: string;
  numConvenio: string;
  nossoNumero: string;
  dvNossoNumero: string;
  dataVencimento: string;
  dataDocumento: string;
  dataProcessamento: string;
  valorBoleto: string;
  caminho: string;
  tipoSaida: string;
  localPagamento: string;
  localPagamento2: string;
  cedente: string;
  qtdMoeda: string;
  valorMoeda: string;
  acrescimo: string;
  instrucao1: string;
  instrucao2: string;
  instrucao3: string;
  instrucao4: string;
  instrucao5: string;
  nomeSacado: string;
  cpfSacado: string;
  enderecoSacado: string;
  cepSacado: string;
  bairroSacado: string;
  cidadeSacado: string;
  ufSacado: string;
  especieDocumento: string;
  especie: string = 'R$';
  aceite: string = 'N';
  linhaDigitavel: string;
  codigoBarras: string;
  codCliente: string;
  ios: string;
  noDocumento: string;
  codigoOperacao: string;
  codigoFornecidoAgencia: string;
  dvCodigoFornecidoAgencia: string;
  imagemMarketing: string;
  enderecoCodBar: string;
  documento: string;
  parcela: string;
  endereco: string;

  /**
   * @return the enderecoCodBar
   */
  public getEnderecoCodBar(): string {

    return this.enderecoCodBar;
  }

  /**
   * @param enderecoCodBar the enderecoCodBar to set
   */
  public setEnderecoCodBar(enderecoCodBar: string) {

    this.enderecoCodBar = enderecoCodBar;
  }

  /**
   * Método para gerar o fator de vencimento do boleto
   *
   * @return long
   */
  public getFatorVencimento(): number {

    let data: string[] = this.getDataVencimento().split("/");
    const dia: string = data[0];
    const mes: string = data[1];
    const ano: string = data[2];

    const dataBase = new Date('1997-10-07');
    const vencimento = new Date(`${ano}-${mes}-${dia}`);
    const diferenca = vencimento.getTime() - dataBase.getTime();

    const diferencaDias = diferenca / DIA_TIME;

    return diferencaDias;
  }

  /**
   * Retorna o numero da agencia. Completar com zeros a esquerda quando
   * necessario
   *
   * @return Retorna o numero da agencia.
   */
  public getAgencia(): string {
    return this.agencia;
  }

  /**
   * Seta o numero da agencia (ex. 2971).
   *
   * @param agencia Seta o numero da agencia (ex. 2971).
   */
  public setAgencia(agencia: string) {
    this.agencia = agencia != null ? agencia.trim() : agencia;
  }

  /**
   * Retorna o numero da carteira. Itau = 3 digitos Bradesco = 2 digitos
   * Bancoob = 1 digito
   *
   * @return Retorna o numero da carteira.
   */
  public getCarteira(): string {
    return this.carteira;
  }

  /**
   * Seta a carteira para o titulo (ex. 175. Para outros tipos veja com seu
   * banco).
   *
   * @param carteira Seta a carteira para o titulo (ex. 175. Para outros tipos
   * veja com seu banco).
   */
  public setCarteira(carteira: string) {
    this.carteira = carteira != null ? carteira.trim() : null;
  }

  /**
   * Retorna o numero da conta corrente.
   *
   * @return Retorna o numero da conta corrente.
   */
  public getContaCorrente(): string {
    return this.contaCorrente;
  }

  /**
   * Seta o numero da conta corrente - Coloque zeros a esquerda quando
   * necessario
   *
   * @param contaCorrente Seta o numero da conta corrente - Coloque zeros a
   * esquerda quando necessario
   */
  public setContaCorrente(contaCorrente: string) {
    this.contaCorrente = contaCorrente.trim();
  }

  /**
   * Retorna o nosso numero.
   *
   * @return Retorna o nosso numero.
   */
  public getNossoNumero(): string {
    return this.nossoNumero;
  }

  /**
   * Seta o nosso numero.
   *
   * @param nossoNumero
   * @param qtdDigitos - Quantidade de digitos que contem o campo nosso numero
   * referente ao seu banco Seta o nosso numero.
   */
  public setNossoNumero(nossoNumero: string, qtdDigitos?: number) {
    nossoNumero = nossoNumero.trim();
    if (qtdDigitos) {
      const dif = qtdDigitos - nossoNumero.length;
      if (dif < 0) {
        throw new Error('Quantidade de dígitos do nosso número maior do que definido!');
      }
      let sb = "";
      for (let i = 0; i < dif; i++) {
        sb += '0';
      }
      this.setNossoNumero(sb + nossoNumero);
    } else {
      this.nossoNumero = nossoNumero;
    }
  }

  /**
   * Retorna a data do vencimento do titulo.
   *
   * @return Retorna a data do vencimento do titulo.
   */
  public getDataVencimento(): string {
    return this.dataVencimento;
  }

  /**
   * Seta a data de vencimento do titulo (ex. 21/06/2005).
   *
   * @param dataVencimento Seta a data de vencimento do titulo (ex.
   * 21/06/2005).
   */
  public setDataVencimento(dataVencimento: string) {
    this.dataVencimento = dataVencimento;
  }

  /**
   * Retorna o valor do titulo.
   *
   * @return Retorna o valor do titulo.
   */
  public getValorBoleto(): string {
    return this.valorBoleto;
  }

  /**
   * Seta o valor do titulo (ex. 23.45 ou 1234.45).
   *
   * @param valorBoleto Seta o valor do titulo (ex. 23.45 ou 1234.45).
   */
  public setValorBoleto(valorBoleto: number) {
    this.valorBoleto = valorBoleto.toString();
  }

  /**
   * Retorna o caminho onde o PDF foi salvo.
   *
   * @return Retorna o caminho onde o PDF foi salvo.
   */
  public getCaminho(): string {
    return this.caminho;
  }

  /**
   * Seta o caminho onde o arquivo devera ser salvo. (ex.:
   * /home/fabio/boleto-05-10-2005.pdf
   *
   * @param caminho Seta o caminho onde o arquivo devera ser salvo. (ex.:
   * /home/fabio/boleto-05-10-2005.pdf
   */
  public setCaminho(caminho: string) {
    this.caminho = caminho;
  }

  /**
   * Retorna o tipo da moeda.
   *
   * @return Retorna o tipo da moeda.
   */
  public getMoeda(): string {
    return this.moeda;
  }

  /**
   * Retorna o acrescimo fornecido ao boleto.
   *
   * @return Retorna o acrescimo fornecido ao boleto.
   */
  public getAcrescimo(): string {
    return this.acrescimo;
  }

  /**
   * Seta o acrescimo ao boleto.
   *
   * @param acrescimo Seta o acrescimo ao boleto.
   */
  public setAcrescimo(acrescimo: number) {
    this.acrescimo = acrescimo.toString();
  }

  /**
   * Retorna o bairro do sacado.
   *
   * @return Retorna o bairro do sacado.
   */
  public getBairroSacado(): string {
    return this.bairroSacado;
  }

  /**
   * Seta o bairro do sacado.
   *
   * @param bairroSacado Seta o bairro do sacado.
   */
  public setBairroSacado(bairroSacado: string) {
    this.bairroSacado = bairroSacado;
  }

  /**
   * Retorna o nome do cedente.
   *
   * @return Retorna o nome do cedente.
   */
  public getCedente(): string {
    return this.cedente;
  }

  /**
   * Seta o nome do cedente.
   *
   * @param cedente Seta o nome do cedente.
   */
  public setCedente(cedente: string) {
    this.cedente = cedente;
  }

  /**
   * Retorna o cep do sacado.
   *
   * @return Retorna o cep do sacado.
   */
  public getCepSacado(): string {
    return this.cepSacado;
  }

  /**
   * Seta o cep do sacado.
   *
   * @param cepSacado Seta o cep do sacado.
   */
  public setCepSacado(cepSacado: string) {
    this.cepSacado = cepSacado;
  }

  /**
   * Retorna a cidade do sacado.
   *
   * @return Retorna a cidade do sacado.
   */
  public getCidadeSacado(): string {
    return this.cidadeSacado;
  }

  /**
   * Seta a cidade do sacado.
   *
   * @param cidadeSacado Seta a cidade do sacado.
   */
  public setCidadeSacado(cidadeSacado: string) {
    this.cidadeSacado = cidadeSacado;
  }

  /**
   * Retorna o cpf ou cnpj do sacado.
   *
   * @return Retorna o cpf ou cnpj do sacado.
   */
  public getCpfSacado(): string {
    return this.cpfSacado;
  }

  /**
   * Seta o cpf ou cnpj do sacado.
   *
   * @param cpfSacado Seta o cpf ou cnpj do sacado.
   */
  public setCpfSacado(cpfSacado: string) {
    this.cpfSacado = cpfSacado;
  }

  /**
   * Retorna o endereco do sacado.
   *
   * @return Retorna o endereco do sacado.
   */
  public getEnderecoSacado(): string {
    return this.enderecoSacado;
  }

  /**
   * Seta o endereco do sacado.
   *
   * @param enderecoSacado Seta o endereco do sacado.
   */
  public setEnderecoSacado(enderecoSacado: string) {
    this.enderecoSacado = enderecoSacado;
  }

  /**
   * Retorna o campo para a 1 linha da instrucao.
   *
   * @return Retorna o campo para a 1 linha da instrucao.
   */
  public getInstrucao1(): string {
    return this.instrucao1;
  }

  /**
   * Seta o campo para a 1 linha da instrucao.
   *
   * @param instrucao1 Seta o campo para a 1 linha da instrucao.
   */
  public setInstrucao1(instrucao1: string) {
    this.instrucao1 = instrucao1;
  }

  /**
   * Retorna o campo para a 2 linha da instrucao.
   *
   * @return Retorna o campo para a 2 linha da instrucao.
   */
  public getInstrucao2(): string {
    return this.instrucao2;
  }

  /**
   * Seta o campo para a 2 linha da instrucao.
   *
   * @param instrucao2 Seta o campo para a 2 linha da instrucao.
   */
  public setInstrucao2(instrucao2: string) {
    this.instrucao2 = instrucao2;
  }

  /**
   * Retorna o campo para a 3 linha da instrucao.
   *
   * @return Retorna o campo para a 3 linha da instrucao.
   */
  public getInstrucao3(): string {
    return this.instrucao3;
  }

  /**
   * Seta o campo para a 3 linha da instrucao.
   *
   * @param instrucao3 Seta o campo para a 3 linha da instrucao.
   */
  public setInstrucao3(instrucao3: string) {
    this.instrucao3 = instrucao3;
  }

  /**
   * Retorna o campo para a 4 linha da instrucao.
   *
   * @return Retorna o campo para a 4 linha da instrucao.
   */
  public getInstrucao4(): string {
    return this.instrucao4;
  }

  /**
   * seta o campo para a 4 linha da instrucao.
   *
   * @param instrucao4 seta o campo para a 4 linha da instrucao.
   */
  public setInstrucao4(instrucao4: string) {
    this.instrucao4 = instrucao4;
  }

  /**
   * Retorna o campo para a 5 linha da instrucao.
   *
   * @return Retorna o campo para a 5 linha da instrucao.
   */
  public getInstrucao5(): string {
    return this.instrucao5;
  }

  /**
   * Seta o campo para a 5 linha da instrucao.
   *
   * @param instrucao5 Seta o campo para a 5 linha da instrucao.
   */
  public setInstrucao5(instrucao5: string) {
    this.instrucao5 = instrucao5;
  }

  /**
   * Retorna o local de pagamento.
   *
   * @return Retorna o local de pagamento.
   */
  public getLocalPagamento(): string {
    return this.localPagamento;
  }

  /**
   * Seta o local de pagamento.
   *
   * @param localPagamento Seta o local de pagamento.
   */
  public setLocalPagamento(localPagamento: string) {
    this.localPagamento = localPagamento;
  }

  /**
   * Retorna o nome do sacado.
   *
   * @return Retorna o nome do sacado.
   */
  public getNomeSacado(): string {
    return this.nomeSacado;
  }

  /**
   * Seta o nome do sacado.
   *
   * @param nomeSacado Seta o nome do sacado.
   */
  public setNomeSacado(nomeSacado: string) {
    this.nomeSacado = nomeSacado;
  }

  /**
   * Retorna a quantidade da moeda.
   *
   * @return Retorna a quantidade da moeda.
   */
  public getQtdMoeda(): string {
    return this.qtdMoeda;
  }

  /**
   * Seta a quantidade da moeda.
   *
   * @param qtdMoeda Seta a quantidade da moeda.
   */
  public setQtdMoeda(qtdMoeda: string) {
    this.qtdMoeda = qtdMoeda;
  }

  /**
   * Retorna o uf do sacado.
   *
   * @return Retorna o uf do sacado.
   */
  public getUfSacado(): string {
    return this.ufSacado;
  }

  /**
   * Seta o Uf do sacado.
   *
   * @param ufSacado Seta o Uf do sacado.
   */
  public setUfSacado(ufSacado: string) {
    this.ufSacado = ufSacado;
  }

  /**
   * Retorna o valor da moeda
   *
   * @return Retorna o valor da moeda
   */
  public getValorMoeda(): string {
    return this.valorMoeda;
  }

  /**
   * Seta o valor da moeda.
   *
   * @param valorMoeda Seta o valor da moeda.
   */
  public setValorMoeda(valorMoeda: string) {
    this.valorMoeda = valorMoeda;
  }

  /**
   * Retorna a segunda linha do local de pagamento
   *
   * @return Retorna a segunda linha do local de pagamento
   */
  public getLocalPagamento2(): string {
    return this.localPagamento2;
  }

  /**
   * Seta a segunda linha do local de pagamento.
   *
   * @param localPagamento2 Seta a segunda linha do local de pagamento.
   */
  public setLocalPagamento2(localPagamento2: string) {
    this.localPagamento2 = localPagamento2;
  }

  /**
   * Retorna o campo aceite que por padrao vem com N.
   *
   * @return Retorna o campo aceite que por padrao vem com N.
   */
  public getAceite(): string {
    return this.aceite;
  }

  /**
   * Seta o campo aceite que por padrao vem com N.
   *
   * @param aceite Seta o campo aceite que por padrao vem com N.
   */
  public setAceite(aceite: string) {
    this.aceite = aceite != null ? aceite.trim() : null;
  }

  /**
   * Retorna o campo especie do documento que por padrao vem com DV
   *
   * @return Retorna o campo especie do documento que por padrao vem com DV
   */
  public getEspecieDocumento(): string {
    return this.especieDocumento;
  }

  /**
   * Seta o campo especie do documento que por padrao vem com DV.
   *
   * @param especieDocumento Seta o campo especie do documento que por padrao
   * vem com DV.
   */
  public setEspecieDocumento(especieDocumento: string) {
    this.especieDocumento = especieDocumento;
  }

  /**
   * Retorna a data do documento.
   *
   * @return Retorna a data do documento.
   */
  public getDataDocumento(): string {
    return this.dataDocumento;
  }

  /**
   * Seta a data do documento.
   *
   * @param dataDocumento Seta a data do documento.
   */
  public setDataDocumento(dataDocumento: string) {
    this.dataDocumento = dataDocumento;
  }

  /**
   * Retorna a data do processamento
   *
   * @return Retorna a data do processamento.
   */
  public getDataProcessamento(): string {
    return this.dataProcessamento;
  }

  /**
   * Seta a data do processamento.
   *
   * @param dataProcessamento Seta a data do processamento.
   */
  public setDataProcessamento(dataProcessamento: string) {
    this.dataProcessamento = dataProcessamento;
  }

  /**
   * Seta o tipo da moeda
   *
   * @param moeda Seta o tipo da moeda.
   */
  public setMoeda(moeda: string) {
    this.moeda = moeda;
  }

  /**
   * Retorna tipo de saida do arquivo
   *
   * @return Retorna tipo de saida do arquivo
   */
  public getTipoSaida(): string {
    return this.tipoSaida;
  }

  /**
   * Seta o tipo de saida do arquivo (html ou pdf)
   *
   * @param tipoSaida Seta o tipo de saida do arquivo (html ou pdf)
   */
  public setTipoSaida(tipoSaida: string) {
    this.tipoSaida = tipoSaida;
  }

  /**
   * Retorna o número digitável do código de barras
   *
   * @return Retorna o número digitável do código de barras
   */
  public getLinhaDigitavel(): string {
    return this.linhaDigitavel;
  }

  /**
   * Seta o número digitável do código de barras
   *
   * @param codigoBarrasDividido Seta o número digitável do código de
   * barras
   */
  public setLinhaDigitavel(linhaDigitavel: string) {
    this.linhaDigitavel = linhaDigitavel;
  }

  /**
   * Retorna o número do código de barras
   *
   * @return Retorna o número do código de barras
   */
  public getCodigoBarras(): string {
    return this.codigoBarras;
  }

  /**
   * Seta o número do código de barras
   *
   * @param codigoBarrasDividido Seta o número do código de barras
   */
  public setCodigoBarras(codigoBarras: string) {
    this.codigoBarras = codigoBarras;
  }

  /**
   * Retorna o quinto campo da linha digitavel do codigo
   *
   * @return Retorna o quinto campo da linha digitavel do codigo
   */
  public getValorTitulo(): string {

    const zeros = '00000000000';

    let valor = '';

    try {

      valor = Number.parseFloat(this.getValorBoleto()).toFixed(2);
    } catch (ex) {
    }

    valor = valor.replace(',', '').replace('.', '');
    const valorTitulo = zeros.substring(0, zeros.length - valor.length) + valor;

    return valorTitulo;
  }

  /**
   * Modulo 10 (212121)\n Retorna o digito verificador de cada campo da linha
   * digitavel. Voce deve passar como parametro a string do campo conforme o
   * seu banco.
   *
   * @return Retorna o digito verificador de cada campo da linha digitavel.
   * Voce deve passar como parametro a string do campo conforme o seu banco.
   */
  public getDigitoCampo(campo: string, mult?: number): string {
    //Esta rotina faz o calcula 212121

    let multiplicador = mult ? mult : 2;
    let multiplicacao = 0;
    let soma_campo = 0;

    for (let i = 0; i < campo.length; i++) {
      multiplicacao = Number.parseInt(campo.substring(i, 1 + i)) * multiplicador;

      if (multiplicacao >= 10) {
        multiplicacao = Number.parseInt(multiplicacao.toString().substring(0, 1)) + Number.parseInt(multiplicacao.toString().substring(1));
      }

      soma_campo = soma_campo + multiplicacao;

      multiplicador = (multiplicador % 2) + 1;

    }
    let dac = 10 - (soma_campo % 10);

    if (dac === 10) {
      dac = 0;
    }
    campo = campo + dac.toString();

    return campo;
  }

  /**
   * Modulo 11 Retorna o digito verificador do codigo de barras (4o campo da
   * linha digitavel. Voce deve passar como parametro a string do campo
   * conforme o seu banco.
   *
   * @return Modulo 11 - Retorna o digito verificador do codigo de barras (4o
   * campo da linha digitavel. Voce deve passar como parametro a string do
   * campo conforme o seu banco.
   */
  public getDigitoCodigoBarras(campo: string): string {
    //Esta rotina faz o calcula no modulo 11 - 23456789

    let multiplicador = 4;
    let multiplicacao = 0;
    let soma_campo = 0;

    for (let i = 0; i < campo.length; i++) {
      multiplicacao = Number.parseInt(campo.substring(i, 1 + i)) * multiplicador;

      soma_campo = soma_campo + multiplicacao;

      multiplicador = ((multiplicador + 5) % 8) + 2;
    }

    let dac = 11 - (soma_campo % 11);

    if (dac == 0 || dac == 1 || dac > 9) {
      dac = 1;
    }
    campo = dac.toString();

    return campo;
  }

  /**
   * Retorna o codigo do cliente. Caseo seja codigo do cliente da caixa este
   * campo deve conter 6 posicoes.
   *
   * @return Retorna o codigo do cliente.
   */
  public getCodCliente(): string {
    return this.codCliente;
  }

  /**
   * Seta um codigo de cliente. Alguns bancos pedem este campo.
   *
   * @param codCliente Seta um codigo de cliente. Alguns bancos pedem este
   * campo
   */
  public setCodCliente(codCliente: string) {
    this.codCliente = codCliente != null ? codCliente.trim() : null;
  }

  /**
   * Retorna o IOS do Banco.
   *
   * @return Retorna o IOS do Banco.
   */
  public getIOS(): string {
    return this.getIos();
  }

  /**
   * Seta um codigo de IOS do Banco.
   *
   * @param codCliente Seta um codigo de IOS do Banco.
   */
  public setIOS(ios: string) {
    this.setIos(ios);
  }

  /**
   * Retorna o numero do convenio
   *
   * @return Retorna o numero do convenio
   */
  public getNumConvenio(): string {
    return this.numConvenio;
  }

  /**
   * Seta o numero do convenio
   *
   * @param numConvenio Seta o numero do convenio
   */
  public setNumConvenio(numConvenio: string) {
    this.numConvenio = numConvenio;
  }

  public getDvAgencia(): string {
    return this.dvAgencia;
  }

  public setDvAgencia(dvAgencia: string) {
    this.dvAgencia = dvAgencia != null ? dvAgencia.trim() : null;
  }

  public getDvContaCorrente(): string {
    return this.dvContaCorrente;
  }

  public setDvContaCorrente(dvContaCorrente: string) {
    this.dvContaCorrente = dvContaCorrente != null ? dvContaCorrente.trim() : null;
  }


  public getIos(): string {
    return this.ios;
  }

  public setIos(ios: string) {
    this.ios = ios;
  }

  /**
   * recupera o codigo de operacao
   *
   * @return Retorna o codigo de operacao
   */
  public getCodigoOperacao(): string {
    return this.codigoOperacao;
  }

  /**
   * Seta o codigo de operacao - necessario somente para caixa
   *
   * @param codigoOperacao Seta o codigo da operacao
   */
  public setCodigoOperacao(codigoOperacao: string) {
    this.codigoOperacao = codigoOperacao;
  }

  /**
   * Recupera o codigo fornecido pela agencia
   *
   * @return Recupera o codigo fornecido pela agencia
   */
  public getCodigoFornecidoAgencia(): string {
    return this.codigoFornecidoAgencia;
  }

  /**
   * Seta o codigo fornecido pela agencia - necessario somente para caixa.
   *
   * @param codigoFornecidoAgencia Seta o codigo fornecido pela agencia
   */
  public setCodigoFornecidoAgencia(codigoFornecidoAgencia: string) {
    this.codigoFornecidoAgencia = codigoFornecidoAgencia;
  }

  /**
   * Recupara o numero do documento
   *
   * @return Recupera o numero do documento
   */
  public getNoDocumento(): string {
    return this.noDocumento;
  }

  /**
   * Seta o numero do documento
   *
   * @param noDocumento Seta o numero do documento
   */
  public setNoDocumento(noDocumento: string) {
    this.noDocumento = noDocumento;
  }

  /**
   * Completa uma String com zeros a esquerda
   *
   * @param str String a ser completada
   * @param qtdZeros Quantidade de zeros que deseja a esquerda Completa uma
   * String com zeros a esquerda
   */
  public completaZerosEsquerda(str: string, qtdZeros: number): string {
    const zeros = '000000000000000000000000000000000000000';
    let dif = qtdZeros - str.length;

    return zeros.substring(0, dif) + str;
  }

  /**
   * Pega o caminho da imagem de marketing para ser colocada na parte superior
   * do boleto
   */
  public getImagemMarketing(): string {
    return this.imagemMarketing;
  }

  /**
   * Seta a imagem de marketing que ira na parte superior do boleto O tamanho
   * ideal para a imagem Ã© de: 1000x668 px
   *
   * @param imagemMarketing Caminho da imagem
   */
  public setImagemMarketing(imagemMarketing: string) {
    this.imagemMarketing = imagemMarketing;
  }

  /**
   * Recupar o digito verificador do nosso numero
   *
   * @return Recupera o digito verificador do nosso numero
   * @author Gladyston Batista
   */
  public getDvNossoNumero(): string {
    return (this.dvNossoNumero != null) ? this.dvNossoNumero : "";
  }

  /**
   * Seta o digito verificar do nosso numero
   *
   * @author Gladyston Batista
   */
  public setDvNossoNumero(dvNossoNumero: string) {
    this.dvNossoNumero = dvNossoNumero;
  }

  /**
   * Recupar o digito verificador fornecido pela agencia
   *
   * @return Recupar o digito verificador fornecido pela agencia
   * @author Gladyston Batista
   */
  public getDvCodigoFornecidoAgencia(): string {
    return this.dvCodigoFornecidoAgencia;
  }

  /**
   * Seta o digito verificar do codigo fornecido pela Agencia
   *
   * @author Gladyston Batista
   */
  public setDvCodigoFornecidoAgencia(dvCodigoFornecidoAgencia: string) {
    this.dvCodigoFornecidoAgencia = dvCodigoFornecidoAgencia;
  }

  /**
   * getModulo10
   *
   * @param String value
   * @return String dac
   * @author Mario Grigioni
   */
  public getModulo10(campo: string): string {

    let multiplicador = 2;
    let multiplicacao = 0;
    let soma_campo = 0;

    for (let i = campo.length; i > 0; i--) {
      multiplicacao = Number.parseInt(campo.substring(i - 1, i)) * multiplicador;

      if (multiplicacao >= 10) {
        multiplicacao = Number.parseInt(multiplicacao.toString().substring(0, 1)) + Number.parseInt(multiplicacao.toString().substring(1, 2));
      }
      soma_campo = soma_campo + multiplicacao;

      if (multiplicador == 1) {
        multiplicador = 2;
      } else {
        multiplicador = 1;
      }
    }
    let dac = 10 - (soma_campo % 10);

    if (dac > 9) {
      dac = 0;
    }
    return Number.parseInt(dac.toString()).toString();
  }

  /**
   * getModulo11
   *
   * @param String value
   * @param int type
   * @return int dac
   * @author Mario Grigioni
   */
  public getModulo11(campo: string, type: number) {
    //Modulo 11 - 234567   (type = 7)
    //Modulo 11 - 23456789 (type = 9)

    let multiplicador = 2;
    let multiplicacao = 0;
    let soma_campo = 0;

    for (let i = campo.length; i > 0; i--) {
      multiplicacao = Number.parseInt(campo.substring(i - 1, i)) * multiplicador;

      soma_campo = soma_campo + multiplicacao;

      if (type == 9) {
        multiplicador++;
      } else if (multiplicador < 9  && type == 7) {
        multiplicador++;
      } else if (multiplicador > 7 && type == 7) {
        multiplicador = 2;
      } else if (multiplicador > 9 && type == 9) {
        multiplicador = 2;
      }
    }

    let dac = 11 - (soma_campo % 11);

    if (dac > 9 && type == 7) {
      dac = 0;
    } else if ((dac == 0 || dac == 1 || dac > 9) && type == 9) {
      dac = 1;
    }
    return Number.parseInt(dac.toString()).toString();
  }

  public getDocumento(): string {
    return this.documento;
  }

  public setDocumento(documento: string) {
    this.documento = documento;
  }

  /**
   * @return the parcela
   */
  public getParcela(): string {
    return this.parcela;
  }

  /**
   * @param parcela the parcela to set
   */
  public setParcela(parcela: string) {
    this.parcela = parcela;
  }

  /**
   * @return the especie
   */
  public getEspecie(): string {
    return this.especie;
  }

  /**
   * @param especie the especie to set
   */
  public setEspecie(especie: string) {
    this.especie = especie;
  }

  public getEndereco(): string {
    return this.endereco;
  }

  public setEndereco(endereco: string) {
    this.endereco = endereco;
  }

}
