// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class ProtocoloParametro extends BaseResourceModel {

  constructor(
    public id?: number,
    public responsavel?: string,
    public cargo?: string,
    public prazo_termino?: number,
    public enviar_email?: boolean,
    public orgao?: Orgao
  ) {
    super();
  }

  static converteJson(json: any): ProtocoloParametro {
    return Object.assign(new ProtocoloParametro(), json);
  }
}
