// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Favorecido } from './favorecido.model';

export class FavorecidoPenalidade extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_inicio?: Date,
    public data_vencimento?: Date,
    public motivo?: string,
    public tipo_penalidade?: string,
    public bloquear_compra?: boolean,
    public bloquear_empenho?: boolean,
    public bloquear_pagamento?: boolean,
    public bloquear_contratacao?: boolean,
    public favorecido?: Favorecido,
    public editavel?: boolean,
    public orgao?: Orgao
  ) {
    super();
  }
  static converteJson(json: any): FavorecidoPenalidade {
    return Object.assign(new FavorecidoPenalidade(), json);
  }
}
