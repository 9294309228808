import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import {
  FuncaoService, GlobalService, Exercicio
} from 'eddydata-lib';

@Directive()
export class Anexo1Despesa implements OnDestroy {

  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number,
    protected exercicio: Exercicio) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(dados: any[], receitas: any[]): {}[] {
    // monta o cabecalho
    const registros: {}[] = [
      [
        { text: 'DESPESAS', alignment: 'center', rowSpan: 2, bold: true, fontSize: 6, border: [true, false, true, true] },
        { text: 'DOTAÇÃO INICIAL (d)', alignment: 'center', rowSpan: 2, bold: true, fontSize: 6, border: [true, false, true, true] },
        { text: 'DOTAÇÃO ATUALIZADA (c)', alignment: 'center', rowSpan: 2, bold: true, fontSize: 6, border: [true, false, true, true] },
        { text: 'DESPESAS EMPENHADAS', alignment: 'center', colSpan: 2, bold: true, fontSize: 6, border: [true, false, true, true] },
        { text: '' },
        { text: 'DESPESAS LIQUIDADAS', alignment: 'center', colSpan: 2, bold: true, fontSize: 6, border: [true, false, true, true] },
        { text: '' },
        { text: 'SALDO (g)=(e-f)', alignment: 'center', rowSpan: 2, bold: true, fontSize: 6, border: [true, false, true, true] },
        { text: 'DESPESAS PAGAS ATÉ O BIMESTRE (j)', alignment: 'center', rowSpan: 2, bold: true, fontSize: 6, border: [true, false, true, true] },
        { text: 'INSCRITAS EM RESTOS A PAGAR NÃO PROCESSADOS (K)', alignment: 'center', rowSpan: 2, bold: true, fontSize: 6, border: [true, false, true, true] }
      ],
      ['', '', '',
        { text: 'NO BIMESTRE', alignment: 'center', bold: true, fontSize: 6 },
        { text: 'ATÉ O BIMESTRE (f)', alignment: 'center', bold: true, fontSize: 6 },
        { text: 'NO BIMESTRE', alignment: 'center', bold: true, fontSize: 6 },
        { text: 'ATÉ O BIMESTRE (h)', alignment: 'center', bold: true, fontSize: 6 },
        '', '', ''
      ]
    ];
    const titulos = this.funcaoService.agrupar(dados[0], 'titulo',
      ['dotacao_inicial', 'dotacao_atualizado', 'empenhado_bim', 'empenhado', 'liquidado_bim', 'liquidado', 'pago', 'resto']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    let total7 = 0;
    let total8 = 0;
    for (const titulo of titulos) {
      registros.push([
        { text: titulo.grupo, fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['dotacao_inicial']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['dotacao_atualizado']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['empenhado_bim']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['empenhado']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['liquidado_bim']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['liquidado']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['dotacao_atualizado'] - +titulo.totalizadores['empenhado']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['pago']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['resto']), alignment: 'right', fontSize: 6, bold: true }
      ]);
      total1 += +titulo.totalizadores['dotacao_inicial'];
      total2 += +titulo.totalizadores['dotacao_atualizado'];
      total3 += +titulo.totalizadores['empenhado_bim'];
      total4 += +titulo.totalizadores['empenhado'];
      total5 += +titulo.totalizadores['liquidado_bim'];
      total6 += +titulo.totalizadores['liquidado'];
      total7 += +titulo.totalizadores['pago'];
      total8 += +titulo.totalizadores['resto'];
      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo',
        ['dotacao_inicial', 'dotacao_atualizado', 'empenhado_bim', 'empenhado', 'liquidado_bim', 'liquidado', 'pago', 'resto']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          registros.push([
            { text: grupo.grupo, fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_inicial']), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_atualizado']), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado_bim']), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado_bim']), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['dotacao_atualizado'] - +grupo.totalizadores['empenhado']), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago']), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['resto']), alignment: 'right', fontSize: 6 }
          ]);

          const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo',
            ['dotacao_inicial', 'dotacao_atualizado', 'empenhado_bim', 'empenhado', 'liquidado_bim', 'liquidado', 'pago', 'resto']);
          for (const subgrupo of subgrupos) {

            if (subgrupo.grupo != '') {
              if (subgrupo.grupo === 'RESERVA DE CONTINGÊNCIA') {
                total1 -= +subgrupo.totalizadores['dotacao_inicial'];
                total2 -= +subgrupo.totalizadores['dotacao_atualizado'];
                total3 -= +subgrupo.totalizadores['empenhado_bim'];
                total4 -= +subgrupo.totalizadores['empenhado'];
                total5 -= +subgrupo.totalizadores['liquidado_bim'];
                total6 -= +subgrupo.totalizadores['liquidado'];
                total7 -= +subgrupo.totalizadores['pago'];
                total8 -= +subgrupo.totalizadores['resto'];
              }
              registros.push([
                { text: subgrupo.grupo, fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['dotacao_inicial']), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['dotacao_atualizado']), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado_bim']), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado']), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liquidado_bim']), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liquidado']), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(+subgrupo.totalizadores['dotacao_atualizado'] - +subgrupo.totalizadores['empenhado']), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['pago']), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['resto']), alignment: 'right', fontSize: 6 }
              ]);

              for (const item of subgrupo.registros) {
                if (item.nome != '') {
                  registros.push([
                    {
                      text: item.nome, fontSize: 6
                    },
                    { text: this.funcaoService.convertToBrNumber(item.dotacao_inicial), alignment: 'right', fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber(item.dotacao_atualizado), alignment: 'right', fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber(item.empenhado_bim), alignment: 'right', fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber(item.empenhado), alignment: 'right', fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber(item.liquidado_bim), alignment: 'right', fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber(item.liquidado), alignment: 'right', fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber(+item.dotacao_atualizado - +item.empenhado), alignment: 'right', fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber(item.pago), alignment: 'right', fontSize: 6 },
                    { text: this.funcaoService.convertToBrNumber(item.resto), alignment: 'right', fontSize: 6 }
                  ]);
                }
              }
            }
          }
        }
      }
    }

    registros.push([
      {
        text: 'SUBTOTAL DAS DESPESAS (X) = (VIII + IX)', fontSize: 6, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(total4), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(total5), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(total6), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total2 - +total4), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(total7), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(total8), alignment: 'right', fontSize: 6, bold: true }
    ]);

    const totalX = {
      total1: total1,
      total2: total2,
      total3: total3,
      total4: total4,
      total5: total5,
      total6: total6,
      total7: total7,
      total8: total8,
    };

    const totalXII = this.montarAmortizacoes(dados[1], registros, totalX);

    registros.push([
      {
        text: 'TOTAL DAS DESPESAS (XII) = (X + XI)', fontSize: 6, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(totalXII.dotacao_inicial), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalXII.dotacao_atualizado), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalXII.empenhado_bim), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalXII.empenhado), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalXII.liquidado_bim), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalXII.liquidado), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(+totalXII.dotacao_atualizado - +totalXII.empenhado), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalXII.pago), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalXII.resto), alignment: 'right', fontSize: 6, bold: true }
    ]);

    this.calcularSuperavit(receitas[0], registros, totalXII);

    return registros;

  }

  private calcularSuperavit(dados: any[], registros: {}[], totalXII: any) {
    let totalInicial = 0;
    let totalAtualizado = 0;
    let totalArrecadado = 0;

    const titulos = this.funcaoService.agrupar(dados, 'titulo', ['previsto', 'atualizado', 'arrecadado']);
    for (const titulo of titulos) {
      totalInicial += +titulo.totalizadores['previsto'];
      totalAtualizado += +titulo.totalizadores['previsto'] + +titulo.totalizadores['atualizado'];
      totalArrecadado += +titulo.totalizadores['arrecadado'];
    }
    const superavitEmpenho = +totalArrecadado - +totalXII.empenhado;
    const superavitLiquidado = +totalArrecadado - +totalXII.liquidado;
    const superavitPago = +totalArrecadado - +totalXII.pago;
    const superavitInicial = +totalInicial - +totalXII.dotacao_inicial;
    const superavitAtualizado = +totalAtualizado - +totalXII.dotacao_atualizado;
    registros.push([
      { text: 'SUPERÁVIT (XIII)', fontSize: 6, bold: true },
      { text: superavitInicial > 0 ? this.funcaoService.convertToBrNumber(superavitInicial) : '0,00', alignment: 'right', fontSize: 6, bold: true },
      { text: superavitAtualizado > 0 ? this.funcaoService.convertToBrNumber(superavitAtualizado) : '0,00', alignment: 'right', fontSize: 6, bold: true },
      { text: '' },
      { text: superavitEmpenho > 0 ? this.funcaoService.convertToBrNumber(superavitEmpenho) : '0,00', alignment: 'right', fontSize: 6, bold: true },
      { text: '' },
      { text: superavitLiquidado > 0 ? this.funcaoService.convertToBrNumber(superavitLiquidado) : '0,00', alignment: 'right', fontSize: 6, bold: true },
      { text: '' },
      { text: superavitPago > 0 ? this.funcaoService.convertToBrNumber(superavitPago) : '0,00', alignment: 'right', fontSize: 6, bold: true }, ''
    ]);
    registros.push([
      { text: 'TOTAL COM SUPERÁVIT (XIV) = (XII + XIII)', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(+totalXII.dotacao_inicial + (+superavitInicial > 0 ? +superavitInicial : 0)), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(+totalXII.dotacao_atualizado + (+superavitAtualizado > 0 ? +superavitAtualizado : 0)), alignment: 'right', fontSize: 6, bold: true },
      { text: '' },
      { text: this.funcaoService.convertToBrNumber(+totalXII.empenhado + (+superavitEmpenho > 0 ? +superavitEmpenho : 0)), alignment: 'right', fontSize: 6, bold: true },
      { text: '' },
      { text: this.funcaoService.convertToBrNumber(+totalXII.liquidado + (+superavitLiquidado > 0 ? +superavitLiquidado : 0)), alignment: 'right', fontSize: 6, bold: true },
      { text: '' },
      { text: this.funcaoService.convertToBrNumber(+totalXII.pago + (+superavitPago > 0 ? +superavitPago : 0)), alignment: 'right', fontSize: 6, bold: true }, ''
    ])
  }

  private montarAmortizacoes(dados: any[], registros: {}[], totalX: any): any {
    let total_amortizacao1 = 0;
    let total_amortizacao2 = 0;
    let total_amortizacao3 = 0;
    let total_amortizacao4 = 0;
    let total_amortizacao5 = 0;
    let total_amortizacao6 = 0;
    let total_amortizacao7 = 0;
    let total_amortizacao8 = 0;
    const titulos = this.funcaoService.agrupar(dados, 'titulo', ['previsto', 'atualizado', 'arrecadado']);
    for (const titulo of titulos) {
      registros.push([
        { text: titulo.grupo, fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['dotacao_inicial']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['dotacao_atualizado']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['empenhado_bim']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['empenhado']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['liquidado_bim']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['liquidado']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['dotacao_atualizado'] - +titulo.totalizadores['empenhado']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['pago']), alignment: 'right', fontSize: 6, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['resto']), alignment: 'right', fontSize: 6, bold: true }
      ]);

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['previsto', 'atualizado', 'arrecadado']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          registros.push([
            { text: grupo.grupo, fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_inicial']), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_atualizado']), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado_bim']), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['empenhado']), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado_bim']), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['liquidado']), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['arrecadado'] - +grupo.totalizadores['atualizado']), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago']), alignment: 'right', fontSize: 6, bold: true },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['resto']), alignment: 'right', fontSize: 6, bold: true }
          ]);

          for (const item of grupo.registros) {
            if (item.subgrupo != '') {
              registros.push([
                {
                  text: item.subgrupo, fontSize: 6, margin: [6, 0, 0, 0]
                },
                { text: this.funcaoService.convertToBrNumber(item.dotacao_inicial), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(item.dotacao_atualizado), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(item.empenhado_bim), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(+item.empenhado), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(item.liquidado_bim), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(+item.liquidado), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(+item.dotacao_atualizado - +item.empenhado), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(item.pago), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(+item.resto), alignment: 'right', fontSize: 6 }
              ]);
            }
          }

        }
      }

    }

    return {
      dotacao_inicial: +totalX.total1 + +total_amortizacao1,
      dotacao_atualizado: +totalX.total2 + +total_amortizacao2,
      empenhado_bim: +totalX.total3 + +total_amortizacao3,
      empenhado: +totalX.total4 + +total_amortizacao4,
      liquidado_bim: +totalX.total5 + +total_amortizacao5,
      liquidado: +totalX.total6 + +total_amortizacao6,
      pago: +totalX.total7 + +total_amortizacao7,
      resto: +totalX.total8 + +total_amortizacao8
    }
  }

  public montarQuadroCsv(dados: any[], receitas: any[], listaExportar) {

    const titulos = this.funcaoService.agrupar(dados[0], 'titulo',
      ['dotacao_inicial', 'dotacao_atualizado', 'empenhado_bim', 'empenhado', 'liquidado_bim', 'liquidado', 'pago', 'resto']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    let total7 = 0;
    let total8 = 0;

    const espaco = {
      titulo: '',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(espaco)

    const linha_titulo = {
      titulo: 'DESPESAS',
      dotacao_inicial: 'DOTAÇÃO INICIAL (d)',
      dotacao_atualizado: 'DOTAÇÃO ATUALIZADA (e)',
      empenhado_bim: 'DESPESAS EMPENHADAS',
      empenhado: '',
      liquidado_bim: 'DESPESAS LIQUIDADAS',
      liquidado: '',
      total1: 'SALDO (g)=(e-f)',
      pago: 'DESPESAS PAGAS ATÉ O BIMESTRE (j)',
      resto: 'INSCRITAS EM RESTOS A PAGAR NÃO PROCESSADOS (K)'
    }
    listaExportar.push(linha_titulo)

    const linha_titulo_2 = {
      titulo: '',
      dotacao_inicial: '',
      dotacao_atualizado: '',
      empenhado_bim: 'NO BIMESTRE',
      empenhado: 'ATÉ O BIMESTRE (f)',
      liquidado_bim: 'NO BIMESTRE',
      liquidado: 'ATÉ O BIMESTRE (h)',
      total1: '',
      pago: '',
      resto: ''
    }
    listaExportar.push(linha_titulo_2)

    for (const titulo of titulos) {
      const primeira_linha = {
        titulo: titulo.grupo,
        dotacao_inicial: this.funcaoService.convertToBrNumber(titulo.totalizadores['dotacao_inicial']),
        dotacao_atualizado: this.funcaoService.convertToBrNumber(titulo.totalizadores['dotacao_atualizado']),
        empenhado_bim: this.funcaoService.convertToBrNumber(titulo.totalizadores['empenhado_bim']),
        empenhado: this.funcaoService.convertToBrNumber(titulo.totalizadores['empenhado']),
        liquidado_bim: this.funcaoService.convertToBrNumber(titulo.totalizadores['liquidado_bim']),
        liquidado: this.funcaoService.convertToBrNumber(titulo.totalizadores['liquidado']),
        total1: this.funcaoService.convertToBrNumber(+titulo.totalizadores['dotacao_atualizado'] - +titulo.totalizadores['empenhado']),
        pago: this.funcaoService.convertToBrNumber(titulo.totalizadores['pago']),
        resto: this.funcaoService.convertToBrNumber(titulo.totalizadores['resto'])
      }
      listaExportar.push(primeira_linha)

      total1 += +titulo.totalizadores['dotacao_inicial'];
      total2 += +titulo.totalizadores['dotacao_atualizado'];
      total3 += +titulo.totalizadores['empenhado_bim'];
      total4 += +titulo.totalizadores['empenhado'];
      total5 += +titulo.totalizadores['liquidado_bim'];
      total6 += +titulo.totalizadores['liquidado'];
      total7 += +titulo.totalizadores['pago'];
      total8 += +titulo.totalizadores['resto'];

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo',
        ['dotacao_inicial', 'dotacao_atualizado', 'empenhado_bim', 'empenhado', 'liquidado_bim', 'liquidado', 'pago', 'resto']);

      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          const linha_grupo = {
            titulo: grupo.grupo,
            dotacao_inicial: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_inicial']),
            dotacao_atualizado: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_atualizado']),
            empenhado_bim: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado_bim']),
            empenhado: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']),
            liquidado_bim: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado_bim']),
            liquidado: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']),
            total1: this.funcaoService.convertToBrNumber(+grupo.totalizadores['dotacao_atualizado'] - +grupo.totalizadores['empenhado']),
            pago: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago']),
            resto: this.funcaoService.convertToBrNumber(grupo.totalizadores['resto'])
          }
          listaExportar.push(linha_grupo)
        }

        const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo',
          ['dotacao_inicial', 'dotacao_atualizado', 'empenhado_bim', 'empenhado', 'liquidado_bim', 'liquidado', 'pago', 'resto']);
        for (const subgrupo of subgrupos) {
          if (subgrupo.grupo != '') {
            const linha_subgrupo = {
              titulo: subgrupo.grupo,
              dotacao_inicial: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['dotacao_inicial']),
              dotacao_atualizado: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['dotacao_atualizado']),
              empenhado_bim: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado_bim']),
              empenhado: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado']),
              liquidado_bim: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liquidado_bim']),
              liquidado: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liquidado']),
              total1: this.funcaoService.convertToBrNumber(+subgrupo.totalizadores['dotacao_atualizado'] - +subgrupo.totalizadores['empenhado']),
              pago: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['pago']),
              resto: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['resto'])
            }
            listaExportar.push(linha_subgrupo)
          }

          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              const linha_item_subgrupo = {
                titulo: item.grupo,
                dotacao_inicial: this.funcaoService.convertToBrNumber(item.dotacao_inicial),
                dotacao_atualizado: this.funcaoService.convertToBrNumber(item.dotacao_atualizado),
                empenhado_bim: this.funcaoService.convertToBrNumber(item.empenhado_bim),
                empenhado: this.funcaoService.convertToBrNumber(item.empenhado),
                liquidado_bim: this.funcaoService.convertToBrNumber(item.liquidado_bim),
                liquidado: this.funcaoService.convertToBrNumber(item.liquidado),
                total1: this.funcaoService.convertToBrNumber(+item.dotacao_atualizado - +item.empenhado),
                pago: this.funcaoService.convertToBrNumber(item.pago),
                resto: this.funcaoService.convertToBrNumber(item.resto)
              }
              listaExportar.push(linha_item_subgrupo)
            }
          }
        }
      }
    }

    const linha_despesas_infraorcamentarias = {
      titulo: 'DESPESAS (INTRA-ORÇAMENTÁRIAS) (IX)',
      dotacao_inicial: this.funcaoService.convertToBrNumber(0),
      dotacao_atualizado: this.funcaoService.convertToBrNumber(0),
      empenhado_bim: this.funcaoService.convertToBrNumber(0),
      empenhado: '',
      liquidado_bim: this.funcaoService.convertToBrNumber(0),
      liquidado: '',
      total1: this.funcaoService.convertToBrNumber(0),
      pago: this.funcaoService.convertToBrNumber(0),
      resto: this.funcaoService.convertToBrNumber(0)
    }
    listaExportar.push(linha_despesas_infraorcamentarias)

    const linha_sub_despesas = {
      titulo: 'SUBTOTAL DAS DESPESAS (X) = (VIII + IX)',
      dotacao_inicial: this.funcaoService.convertToBrNumber(total1),
      dotacao_atualizado: this.funcaoService.convertToBrNumber(total2),
      empenhado_bim: this.funcaoService.convertToBrNumber(total3),
      empenhado: this.funcaoService.convertToBrNumber(total4),
      liquidado_bim: this.funcaoService.convertToBrNumber(total5),
      liquidado: this.funcaoService.convertToBrNumber(total6),
      total1: this.funcaoService.convertToBrNumber(+total2 - +total4),
      pago: this.funcaoService.convertToBrNumber(total7),
      resto: this.funcaoService.convertToBrNumber(total8)
    }
    listaExportar.push(linha_sub_despesas)

    const totalX = {
      total1: total1,
      total2: total2,
      total3: total3,
      total4: total4,
      total5: total5,
      total6: total6,
      total7: total7,
      total8: total8,
    };

    const totalXII = this.montarAmortizacoesCsv(dados[1], listaExportar, totalX);

    const linha_total_despesas = {
      titulo: 'TOTAL DAS DESPESAS (XII) = (X + XI)',
      dotacao_inicial: this.funcaoService.convertToBrNumber(totalXII.dotacao_inicial),
      dotacao_atualizado: this.funcaoService.convertToBrNumber(totalXII.dotacao_atualizado),
      empenhado_bim: this.funcaoService.convertToBrNumber(totalXII.empenhado_bim),
      empenhado: this.funcaoService.convertToBrNumber(totalXII.empenhado),
      liquidado_bim: this.funcaoService.convertToBrNumber(totalXII.liquidado_bim),
      liquidado: this.funcaoService.convertToBrNumber(totalXII.liquidado),
      total1: this.funcaoService.convertToBrNumber(+totalXII.dotacao_atualizado - +totalXII.empenhado),
      pago: this.funcaoService.convertToBrNumber(totalXII.pago),
      resto: this.funcaoService.convertToBrNumber(totalXII.resto)
    }
    listaExportar.push(linha_total_despesas)

    this.calcularSuperavitCsv(receitas[0], listaExportar, totalXII);

  }

  private calcularSuperavitCsv(dados: any[], listaExportar: any, totalXII: any) {
    let totalInicial = 0;
    let totalAtualizado = 0;
    let totalArrecadado = 0;

    const titulos = this.funcaoService.agrupar(dados, 'titulo', ['previsto', 'atualizado', 'arrecadado']);
    for (const titulo of titulos) {
      totalInicial += +titulo.totalizadores['previsto'];
      totalAtualizado += +titulo.totalizadores['previsto'] + +titulo.totalizadores['atualizado'];
      totalArrecadado += +titulo.totalizadores['arrecadado'];
    }
    const superavitEmpenho = +totalArrecadado - +totalXII.empenhado;
    const superavitLiquidado = +totalArrecadado - +totalXII.liquidado;
    const superavitPago = +totalArrecadado - +totalXII.pago;
    const superavitInicial = +totalInicial - +totalXII.dotacao_inicial;
    const superavitAtualizado = +totalAtualizado - +totalXII.dotacao_atualizado;

    const linha_superavit_despesas = {
      titulo: 'SUPERÁVIT (XIII)',
      dotacao_inicial: superavitInicial > 0 ? this.funcaoService.convertToBrNumber(superavitInicial) : '0,00',
      dotacao_atualizado: superavitAtualizado > 0 ? this.funcaoService.convertToBrNumber(superavitAtualizado) : '0,00',
      empenhado_bim: '',
      empenhado: superavitEmpenho > 0 ? this.funcaoService.convertToBrNumber(superavitEmpenho) : '0,00',
      liquidado_bim: '',
      liquidado: superavitLiquidado > 0 ? this.funcaoService.convertToBrNumber(superavitLiquidado) : '0,00',
      total1: '',
      pago: superavitPago > 0 ? this.funcaoService.convertToBrNumber(superavitPago) : '0,00',
      resto: ''
    }
    listaExportar.push(linha_superavit_despesas)

    const linha_superavit_total_despesas = {
      titulo: 'TOTAL COM SUPERÁVIT (XIV) = (XII + XIII)',
      dotacao_inicial: this.funcaoService.convertToBrNumber(+totalXII.dotacao_inicial + (+superavitInicial > 0 ? +superavitInicial : 0)),
      dotacao_atualizado: this.funcaoService.convertToBrNumber(+totalXII.dotacao_atualizado + (+superavitAtualizado > 0 ? +superavitAtualizado : 0)),
      empenhado_bim: '',
      empenhado: this.funcaoService.convertToBrNumber(+totalXII.empenhado + (+superavitEmpenho > 0 ? +superavitEmpenho : 0)),
      liquidado_bim: '',
      liquidado: this.funcaoService.convertToBrNumber(+totalXII.liquidado + (+superavitLiquidado > 0 ? +superavitLiquidado : 0)),
      total1: '',
      pago: this.funcaoService.convertToBrNumber(+totalXII.pago + (+superavitPago > 0 ? +superavitPago : 0)),
      resto: ''
    }
    listaExportar.push(linha_superavit_total_despesas)
  }

  private montarAmortizacoesCsv(dados: any[], listaExportar: any, totalX: any): any {
    let total_amortizacao1 = 0;
    let total_amortizacao2 = 0;
    let total_amortizacao3 = 0;
    let total_amortizacao4 = 0;
    let total_amortizacao5 = 0;
    let total_amortizacao6 = 0;
    let total_amortizacao7 = 0;
    let total_amortizacao8 = 0;
    const titulos = this.funcaoService.agrupar(dados, 'titulo', ['previsto', 'atualizado', 'arrecadado']);

    for (const titulo of titulos) {
      const linha_titulo_despesas = {
        titulo: titulo.grupo,
        dotacao_inicial: this.funcaoService.convertToBrNumber(titulo.totalizadores['dotacao_inicial']),
        dotacao_atualizado: this.funcaoService.convertToBrNumber(titulo.totalizadores['dotacao_atualizado']),
        empenhado_bim: this.funcaoService.convertToBrNumber(titulo.totalizadores['empenhado_bim']),
        empenhado: this.funcaoService.convertToBrNumber(+titulo.totalizadores['empenhado']),
        liquidado_bim: this.funcaoService.convertToBrNumber(titulo.totalizadores['liquidado_bim']),
        liquidado: this.funcaoService.convertToBrNumber(+titulo.totalizadores['liquidado']),
        total1: this.funcaoService.convertToBrNumber(+titulo.totalizadores['dotacao_atualizado'] - +titulo.totalizadores['empenhado']),
        pago: this.funcaoService.convertToBrNumber(+titulo.totalizadores['pago']),
        resto: this.funcaoService.convertToBrNumber(+titulo.totalizadores['resto'])
      }
      listaExportar.push(linha_titulo_despesas)

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['previsto', 'atualizado', 'arrecadado']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          const linha_grupo_despesas = {
            titulo: grupo.grupo,
            dotacao_inicial: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_inicial']),
            dotacao_atualizado: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_atualizado']),
            empenhado_bim: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado_bim']),
            empenhado: this.funcaoService.convertToBrNumber(+grupo.totalizadores['empenhado']),
            liquidado_bim: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado_bim']),
            liquidado: this.funcaoService.convertToBrNumber(+grupo.totalizadores['liquidado']),
            total1: this.funcaoService.convertToBrNumber(+grupo.totalizadores['dotacao_atualizado'] - +grupo.totalizadores['empenhado']),
            pago: this.funcaoService.convertToBrNumber(+grupo.totalizadores['pago']),
            resto: this.funcaoService.convertToBrNumber(+grupo.totalizadores['resto'])
          }
          listaExportar.push(linha_grupo_despesas)
        }

        for (const item of grupo.registros) {
          if (item.subgrupo != '') {
            const linha_grupo_despesas = {
              titulo: item.subgrupo,
              dotacao_inicial: this.funcaoService.convertToBrNumber(item.dotacao_inicial),
              dotacao_atualizado: this.funcaoService.convertToBrNumber(item.dotacao_atualizado),
              empenhado_bim: this.funcaoService.convertToBrNumber(item.empenhado_bim),
              empenhado: this.funcaoService.convertToBrNumber(+item.empenhado),
              liquidado_bim: this.funcaoService.convertToBrNumber(item.liquidado_bim),
              liquidado: this.funcaoService.convertToBrNumber(+item.liquidado),
              total1: this.funcaoService.convertToBrNumber(+item.dotacao_atualizado - +item.empenhado),
              pago: this.funcaoService.convertToBrNumber(+item.pago),
              resto: this.funcaoService.convertToBrNumber(+item.resto)
            }
            listaExportar.push(linha_grupo_despesas)
          }
        }
      }
    }

    const linha_total_despesas = {
      titulo: 'TOTAL DAS DESPESAS (XII) = (X + XI)',
      dotacao_inicial: this.funcaoService.convertToBrNumber(+totalX.total1 + +total_amortizacao1),
      dotacao_atualizado: this.funcaoService.convertToBrNumber(+totalX.total2 + +total_amortizacao2),
      empenhado_bim: this.funcaoService.convertToBrNumber(+totalX.total3 + +total_amortizacao3),
      empenhado: this.funcaoService.convertToBrNumber(+totalX.total4 + +total_amortizacao4),
      liquidado_bim: this.funcaoService.convertToBrNumber(+totalX.total5 + +total_amortizacao5),
      liquidado: this.funcaoService.convertToBrNumber(+totalX.total6 + +total_amortizacao6),
      total1: this.funcaoService.convertToBrNumber((+totalX.total2 + +total_amortizacao2) - (+totalX.total4 + +total_amortizacao4)),
      pago: this.funcaoService.convertToBrNumber(+totalX.total7 + +total_amortizacao7),
      resto: this.funcaoService.convertToBrNumber(+totalX.total8 + +total_amortizacao8)
    }
    listaExportar.push(linha_total_despesas)

    return {
      dotacao_inicial: +totalX.total1 + +total_amortizacao1,
      dotacao_atualizado: +totalX.total2 + +total_amortizacao2,
      empenhado_bim: +totalX.total3 + +total_amortizacao3,
      empenhado: +totalX.total4 + +total_amortizacao4,
      liquidado_bim: +totalX.total5 + +total_amortizacao5,
      liquidado: +totalX.total6 + +total_amortizacao6,
      pago: +totalX.total7 + +total_amortizacao7,
      resto: +totalX.total8 + +total_amortizacao8
    }
  }

}
