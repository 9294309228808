// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Estoque } from './estoque.model';

export class ParametroAlmoxarifado extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_inventario_abertura?: Date,
    public data_inventario_fechamento?: Date,
    public customedio_ponderado?: boolean,
    public requisicao_paciente?: boolean,
    public aceitar_of_outros_setores?: boolean,
    public jan?: boolean,
    public fev?: boolean,
    public mar?: boolean,
    public abr?: boolean,
    public mai?: boolean,
    public jun?: boolean,
    public jul?: boolean,
    public ago?: boolean,
    public set?: boolean,
    public out?: boolean,
    public nov?: boolean,
    public dez?: boolean,
    public indice_percentual_receita_item_merenda?: boolean,
    public estoque?: Estoque
  ) {
    super();
  }
  static converteJson(json: any): ParametroAlmoxarifado {
    return Object.assign(new ParametroAlmoxarifado(), json);
  }
}
