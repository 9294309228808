import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, TipoImovelOcorrencia } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TipoImovelOcorrenciaService extends BaseResourceService<TipoImovelOcorrencia> {

  constructor(
    protected injector: Injector
  ) {
    super(`tipos-imoveis-ocorrencia`, injector);
  }

  public obterPorNome(descricao: string, orgaoId: number): Observable<TipoImovelOcorrencia> {
    return this.http.get<TipoImovelOcorrencia>(
      `${this.login.cidade.id}/${this.api}/filtrar?descricao$like=${descricao}%&orgao_id=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
