import { SituacaoProponente } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido } from '../compra/favorecido.model';
import { Licitacao } from './licitacao.model';
import { Proposta } from './proposta.model';

export class Proponente extends BaseResourceModel {
  constructor(
    public id?: number,
    public ordem?: number,
    public epp_me?: boolean,
    public situacao?: SituacaoProponente,
    public motivo?: string,
    public licitacao?: Licitacao,
    public favorecido?: Favorecido,
    public propostas?: Proposta[],
  ) {
    super();
  }
}
