import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, ContaBancaria, Despesa, Diario, FichaExtra, Page, Receita } from 'eddydata-lib';
import { HttpParams } from '@angular/common/http';

export type DeParaType = 'Conta Bancária' | 'Ficha Extra' | 'Receita Orçamentária' | 'Despesa Orçamentária' | 'Empenho Resto';
export interface DeParaSiconfiBody {
  type: DeParaType;
  sobrepor: boolean;
  mes: number;
  exercicio: number;
  orgao: number;
}

@Injectable({
  providedIn: 'root'
})
export class DiarioService extends BaseResourceService<Diario> {

  constructor(
    protected injector: Injector
  ) {
    super('diarios', injector);
  }

  public movimentacoesMes(exercicio: number, orgao: number, codigo: string): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/movimentacao-mes/${exercicio}/${orgao}/${codigo}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public conferenciaMes(exercicio: number, mes: number, orgaos: string[], inscricaoRestos: boolean): Observable<Array<any>> {
    const params = new HttpParams().appendAll({
      orgaos,
      exercicio,
      mes,
      inscricaoRestos
    });

    const options = { ...this.httpOptions(), params };

    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/conferencia-mes`, options).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public apuracaoSuperavit(exercicio: number, mes: number, orgao?: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/apuracao-superavit/${exercicio}/${mes}/${orgao}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public apuracaoSuperavitPeriodo(exercicio: number, data1: string, data2: string, orgao?: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/apuracao-superavit-periodo/${exercicio}/${data1}/${data2}/${orgao}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }


  public relatorioContaCorrente(orgao: number[], exercicioId: number, mes: number, dt1?: string, dt2?: string): Observable<any[]> {
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/relatorio-conta-corrente/${orgao.join()}/${exercicioId}/${mes}/${dt1}/${dt2}}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public relatorioDividaAtiva(orgao: number[], exercicioId: number, mes: number, dt1?: string, dt2?: string): Observable<any[]> {
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/relatorio-divida-ativa/${orgao.join()}/${exercicioId}/${mes}/${dt1}/${dt2}}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public buscarRegistroUnico(orgao: number, exercicioId: number, mes: number, evento: string): Observable<any> {
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/buscar-registro-unico/${orgao}/${exercicioId}/${mes}/${evento}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public deparaSiconfiEntidade(body: DeParaSiconfiBody): Observable<number> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/depara-siconfi-entidade/`, body, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public saldoCronogramaPorFicha(exercicioId: number, orgaoId: number, fichaId: number, referencia: number, creditoItemId?: number) {
    let parametro = ''
    if (creditoItemId) {
      parametro = `?credito_item_id=${creditoItemId}`
    }
    return this.http.get(
      `${this.login.cidade.id}/${this.api}/saldo-cronograma-por-ficha/${exercicioId}/${orgaoId}/${fichaId}/${referencia}${parametro}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public conferenciaConsignacoesPagar(filtros: any) {
    let parametros = ''
    for (const key in filtros) {
      if (parametros === '') {
        parametros += '?';
      } else {
        parametros += '&';
      }
      parametros += key + '=' + encodeURIComponent(filtros[key]);
    }

    return this.http.get<any[]>(`${this.login.cidade.id}/${this.api}/conferencia-consignacoes-pagar${parametros}`, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      )
  }

  public conferenciaComprometidaLiquidacao(filtros: any) {
    let parametros = ''
    for (const key in filtros) {
      if (parametros === '') {
        parametros += '?';
      } else {
        parametros += '&';
      }
      parametros += key + '=' + encodeURIComponent(filtros[key]);
    }

    return this.http.get<any[]>(`${this.login.cidade.id}/${this.api}/conferencia-comprometida-liquidacao${parametros}`, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      )
  }

  public conferenciaDisponibilidadeRecursos(filtros: any) {
    let parametros = ''
    for (const key in filtros) {
      if (parametros === '') {
        parametros += '?';
      } else {
        parametros += '&';
      }
      parametros += key + '=' + encodeURIComponent(filtros[key]);
    }

    return this.http.get<any[]>(`${this.login.cidade.id}/${this.api}/conferencia-disponibilidade-recursos${parametros}`, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      )
  }

  public conferenciaComprometidaRetencoes(filtros: any) {
    let parametros = ''
    for (const key in filtros) {
      if (parametros === '') {
        parametros += '?';
      } else {
        parametros += '&';
      }
      parametros += key + '=' + encodeURIComponent(filtros[key]);
    }

    return this.http.get<any[]>(`${this.login.cidade.id}/${this.api}/conferencia-comprometida-retencoes${parametros}`, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      )
  }

  public livroDiario(orgao: number[], exercicioId: number, mes: number, dt1?: string, dt2?: string, opcao?: string): Observable<any[]> {
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/relatorio-livro-diario/${orgao.join()}/${exercicioId}/${mes}/${dt1}/${dt2}/${opcao}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public conferenciaApuracaoSuperavit(filtros: any) {
    let parametros = ''
    for (const key in filtros) {
      if (parametros === '') {
        parametros += '?';
      } else {
        parametros += '&';
      }
      parametros += key + '=' + encodeURIComponent(filtros[key]);
    }

    return this.http.get<any[]>(`${this.login.cidade.id}/${this.api}/conferencia-apuracao-superavit${parametros}`, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      )
  }


}
