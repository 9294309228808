import { BaseResourceModel } from '../../models/base-resource.model';
import { Setor } from '../almoxarifado/setor.model';
import { Usuario } from '../comum/usuario.model';
import { Conferencia } from './conferencia.model';
import { Inventario } from './inventario.model';
import { InventarioSetorStorage } from './inventario-setor-storage.model';
import { InventarioTombo } from './inventario-tombo.model';

export class InventarioSetor extends BaseResourceModel {
  constructor(
    public id?: number,
    public status?: 'PENDENTE' | 'INICIADO' | 'CONCLUIDO',
    public data_termino?: Date,
    public data_finalizacao?: Date,
    public data_inicio?: Date,
    public inventario?: Inventario,
    public setor?: Setor,
    public usuario_inicio?: Usuario,
    public usuario_finalizacao?: Usuario,
    public conferencia?: Conferencia,
    public arquivos?: InventarioSetorStorage[],
    public tombos?: InventarioTombo[],
  ) {
    super();
  }

  static converteJson(json: any): InventarioSetor {
    return Object.assign(new InventarioSetor(), json);
  }
}
