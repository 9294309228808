import { Component, ElementRef, Injector, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { EmailService } from "../../email-personalizado/service/email.service";
import { ComunicadoUsuario } from "../../entidade/compra/comunicado-usuario.model";
import { Comunicado } from "../../entidade/compra/comunicado.model";
import { Usuario } from "../../entidade/comum/usuario.model";
import { LoginContabil } from "../../entidade/login/login-contabil";
import { BaseResourceFormComponent } from "../../models/base-resource-form";
import { UsuarioService } from "../../usuario/service/usuario.service";
import { FuncaoService } from "../../util/funcao.service";
import { GlobalService } from "../../util/global.service";
import { ComunicadoUsuarioService } from "../service/comunicado-usuario.service";
import { ComunicadoService } from "../service/comunicado.service";
import * as toastr from 'toastr';

@Component({
  selector: 'lib-comunicado-form',
  templateUrl: './comunicado-form.component.html'
})
export class ComunicadoFormComponent extends BaseResourceFormComponent<Comunicado, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public mes: string;
  public usuarios: Usuario[];
  public listaUsuarios: ComunicadoUsuario[];
  public enviar_emails: boolean = false;

  @ViewChild('titulo_') inputField: ElementRef;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private funcaoService: FuncaoService,
    private comunicadoService: ComunicadoService,
    protected usuarioService: UsuarioService,
    protected comunicadoUsService: ComunicadoUsuarioService,
    protected globalService: GlobalService,
    protected emailService: EmailService) {
    super(new Comunicado(), injector, Comunicado.converteJson, comunicadoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      titulo: [null, [Validators.required]],
      mensagem: [null, [Validators.required]],
      usuario: [this.login.usuario],
      orgao: [this.login.orgao],
      itens: [null]
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: ['orgao']
    };
  }

  protected afterLoad(): void {
    if (this.entidade.id) {
      this.comunicadoUsService.filtrar(1, -1, {
        'relations': 'usuario',
        'comunicado.id': this.entidade.id,
        'orderBy': 'usuario.nome'
      }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaUsuarios = res ? res.content : new Array<ComunicadoUsuario>();
        }, error => this.funcaoService.acaoErro(error));
    }
  }

  protected afterInit(): void {
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);

    this.usuarioService.filtrar(0, -1, {
      orderBy: 'nome',
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.usuarios = res.content;

        this.listaUsuarios = this.usuarios.map((item) => {
          return {
            usuario: item,
            notificar: false,
            enviar_email: false,
            lido: false
          }
        });
      });
  }

  protected beforeSubmit(): void {
    try {
      if (this.entidadeForm.get('titulo').value === '') {
        throw new Error('Informe o título do comunicado!');
      }
      if (this.entidadeForm.get('mensagem').value === '') {
        throw new Error('Informe a mensagem do comunicado!');
      }
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
    this.entidadeForm.get('itens').setValue(this.listaUsuarios);
  }

  protected afterSubmit(ent: Comunicado): void {
    if (this.enviar_emails) {
      this.enviar_emails = false;
      let encaminharEmail = this.listaUsuarios.filter(item => item.enviar_email);
      if (encaminharEmail.length) {
        this.enviarEmail(encaminharEmail.map(item => { return { nome: item.usuario.nome, email: item.usuario.email } }), ent);
      }
    }
  }

  protected acaoErro(error: any): void {
    if (error.error && error.error.payload) {
      toastr.error(error.error.payload);
    } else {
      toastr.error('Ocorreu um erro ao processar a sua solicitação');
    }
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  public notificarTodos(): void {
    var isChecked = $('#notificarTodos').is(':checked');
    this.listaUsuarios = this.listaUsuarios.map((usuario) => {
      return {
        ...usuario,
        notificar: isChecked
      }
    });
  }

  public enviarParaTodos(): void {
    var isChecked = $('#enviarParaTodos').is(':checked');
    this.listaUsuarios = this.listaUsuarios.map((usuario) => {
      return {
        ...usuario,
        enviar_email: isChecked
      }
    });
  }

  public selecionarEmail(item: ComunicadoUsuario): void {
    var isChecked = $(`#email_${item.usuario.id}`).is(':checked');
    const indice = this.listaUsuarios.indexOf(item);

    this.listaUsuarios[indice].enviar_email = isChecked;
  }

  public selecionarNotificacao(item: ComunicadoUsuario): void {
    var isChecked = $(`#notificacao_${item.usuario.id}`).is(':checked');
    const indice = this.listaUsuarios.indexOf(item);

    this.listaUsuarios[indice].notificar = isChecked;
  }

  public async enviarEmail(destinatarios: { nome: string, email: string }[], ent?: Comunicado) {
    let subject = `Comunicado: ${this.entidade.titulo || ent.titulo}`;
    let mensagem = `<p>${this.entidade.mensagem || ent.mensagem}</p>`;
    this.emailService.enviar({ titulo: subject, corpo: mensagem, destinos: destinatarios })
      .subscribe(() => { }, () => toastr.error(`Não foi possivel encaminhar o e-mail de confirmação!`));
  }

  public salvarEmail(): void {
    this.enviar_emails = true;
    this.listaUsuarios = this.listaUsuarios.map(item => {
      return {
        ...item,
        lido: false
      };
    })
    this.submitForm()
  }
}