import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FuncaoService, Exercicio } from 'eddydata-lib';

@Directive()
export class Anexo8Indicadores implements OnDestroy {

  protected funcaoService: FuncaoService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number,
    protected exercicio: Exercicio) {
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(fundeb: any[], DCFundeb: any[], indicadores: any[]): {}[] {

    const registros: {}[] = [
      [
        { text: 'INDICADORES - Art. 212-A, inciso XI e § 3º - Constituição Federal', alignment: 'center', bold: true, fontSize: 7, colSpan: 3 }, '', '',
        { text: 'VALOR EXIGIDO (j)', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'VALOR APLICADO (k)', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'VALOR CONSIDERADO APÓS DEDUÇÕES (l)', alignment: 'center', bold: true, fontSize: 7 },
        { text: '% APLICADO (m)', alignment: 'center', bold: true, fontSize: 7 },
      ],
    ];

    const grupoFundeb = this.funcaoService.agrupar(fundeb, 'grupo', ['arrecadado', 'atualizado', 'arrecadado_anterior', 'atualizado_anterior']);

    let valorExigido15: number = 0;
    let valorExigido16: number = 0;
    let valorExigido17: number = 0;
    let valorMax18: number = 0;
    let valorAplicado15: number = 0;
    let valorAplicado16: number = 0;
    let valorAplicado17: number = 0;
    let valorNaoAplicado18: number = 0;
    let deducoes15: number = 0;
    let deducoes16: number = 0;
    let deducoes17: number = 0;
    let ajuste18: number = 0;
    let aplicado15: number = 0;
    let aplicado16: number = 0;
    let aplicado17: number = 0;
    let naoAplicado18: number = 0;
    let excedente18: number = 0;
    let superavitAnterior: number = 0;
    let aplicadoAnterior: number = 0;

    for (const fundeb of grupoFundeb) {

      let sum: number = 0;
      let totalFundeb: number = 0;
      let totalSubFundeb: number = 0;

      valorMax18 = (((+fundeb.totalizadores['arrecadado']) * 10) / 100);

      superavitAnterior = ((+fundeb.totalizadores['arrecadado_anterior']) * 10 / 100);

      totalFundeb = +fundeb.totalizadores['arrecadado'];

      const subfundeb = this.funcaoService.agrupar(fundeb.registros, 'subgrupo', ['arrecadado', 'atualizado', 'arrecadado_anterior', 'atualizado_anterior']);

      for (const subgrupo of subfundeb) {

        if (subgrupo.grupo === '6.3- FUNDEB - Complementação da União - VAAT') {

          sum = +subgrupo.totalizadores['arrecadado'];

          valorExigido16 = (sum * 50 / 100);
          valorExigido17 = (sum * 15 / 100);
        }

        if (subgrupo.grupo === '6.4- FUNDEB - Complementação da União - VAAR') {
          valorExigido15 = (((+fundeb.totalizadores['arrecadado'] - subgrupo.totalizadores['arrecadado']) * 70) / 100);
          totalSubFundeb = (+fundeb.totalizadores['arrecadado'] - subgrupo.totalizadores['arrecadado']);
        }
      }
      const grupoDespesa = this.funcaoService.agrupar(DCFundeb, 'grupo', ['empenhado', 'liquidado', 'pago', 'resto', 'disponibilidade'])
      for (const gd of grupoDespesa) {

        if (gd.grupo === '12- TOTAL DAS DESPESAS DO FUNDEB COM PROFISSIONAIS DA EDUCAÇÃO BÁSICA') {
          valorAplicado15 += +gd.totalizadores['empenhado'];
          deducoes15 = valorAplicado15 - gd.totalizadores['disponibilidade'];
          aplicado15 = (deducoes15 / (+totalSubFundeb)) * 100;
        }
        if (gd.grupo === '13- TOTAL DAS DESPESAS CUSTEADAS COM FUNDEB - COMPLEMENTAÇÃO DA UNIÃO - VAAT APLICADAS NA EDUCAÇÃO INFANTIL') {
          valorAplicado16 += +gd.totalizadores['empenhado'];
          deducoes16 = valorAplicado16 - gd.totalizadores['disponibilidade'];
          aplicado16 = (deducoes16 / +fundeb.totalizadores['arrecadado']) * 100;
        }
        if (gd.grupo === '14- TOTAL DAS DESPESAS CUSTEADAS COM FUNDEB - COMPLEMENTAÇÃO DA UNIÃO - VAAT APLICADAS EM DESPESA DE CAPITAL') {
          valorAplicado17 += +gd.totalizadores['empenhado'];
          deducoes17 = valorAplicado17 - gd.totalizadores['disponibilidade'];
          aplicado17 = (deducoes17 / +fundeb.totalizadores['arrecadado']) * 100;
        }
        if (gd.grupo === '11- TOTAL DAS DESPESAS CUSTEADAS COM RECURSOS DO FUNDEB RECEBIDAS NO EXERCÍCIO') {
          valorNaoAplicado18 += totalFundeb - ((this.mes < 12 ? gd.totalizadores['liquidado'] : gd.totalizadores['empenhado']) - ((this.mes < 12 ? gd.totalizadores['liquidado'] : gd.totalizadores['empenhado']) - totalFundeb > 0 ? (this.mes < 12 ? gd.totalizadores['liquidado'] : gd.totalizadores['empenhado']) - totalFundeb : 0));
          ajuste18 += valorNaoAplicado18 /*+ (+gd.totalizadores['empenhado'] - totalFundeb)*/;
          naoAplicado18 = +fundeb.totalizadores['arrecadado'];
          aplicadoAnterior = +fundeb.totalizadores['arrecadado_anterior'];
        }
      }
      excedente18 = ajuste18 > valorMax18 ? ajuste18 - valorMax18 : 0;
      naoAplicado18 = (ajuste18 / naoAplicado18) * 100;
    }


    registros.push([
      { text: '15- Mínimo de 70% do FUNDEB na Remuneração dos Profissionais da Educação Básica', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: this.funcaoService.convertToBrNumber(valorExigido15), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(valorAplicado15), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(deducoes15), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(aplicado15), alignment: 'right', fontSize: 7, bold: true }
    ]);
    registros.push([
      { text: '16 - Percentual de 50% da Complementação da União ao FUNDEB (VAAT) na Educação Infantil', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: this.funcaoService.convertToBrNumber(valorExigido16), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(valorAplicado16), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(deducoes16), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(aplicado16), alignment: 'right', fontSize: 7, bold: true }
    ]);
    registros.push([
      { text: '17- Mínimo de 15% da Complementação da União ao FUNDEB - VAAT em Despesas de Capital', fontSize: 7, bold: true, colSpan: 3 }, '', '',
      { text: this.funcaoService.convertToBrNumber(valorExigido17), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(valorAplicado17), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(deducoes17), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(aplicado17), alignment: 'right', fontSize: 7, bold: true }
    ]);
    registros.push([
      { text: 'INDICADOR - Art.25, § 3º - Lei nº 14.113, de 2020 - (Máximo de 10% de Superávit)', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, '',
      { text: 'VALOR MÁXIMO PERMITIDO (n)', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'VALOR NÃO APLICADO (o)', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'VALOR NÃO APLICADO APÓS AJUSTE (p)', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'VALOR NÃO APLICADO EXCEDENTE AO MÁXIMO PERMITIDO (q)', alignment: 'center', bold: true, fontSize: 7 },
      { text: '% NÃO APLICADO (r)', alignment: 'center', bold: true, fontSize: 7 }
    ])
    registros.push([
      { text: '18- Total da Receita Recebida e não Aplicada no Exercício', fontSize: 7, bold: true, colSpan: 2 }, '',
      { text: this.funcaoService.convertToBrNumber(valorMax18), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(valorNaoAplicado18), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(ajuste18), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(excedente18), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(naoAplicado18), alignment: 'right', fontSize: 7, bold: true }
    ]);
    registros.push([
      { text: 'INDICADOR - Art.25, § 3º - Lei nº 14.113, de 2020 - (Aplicação do Superávit de Exercício Anterior)', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'VALOR DE SUPERÁVIT PERMITIDO NO EXERCÍCIO ANTERIOR (s)', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'VALOR NÃO APLICADO NO EXERCÍCIO ANTERIOR (t)', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'VALOR DE SUPERÁVIT APLICADO ATÉ O PRIMEIRO QUADRIMESTRE (u)', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'VALOR APLICADO APÓS O PRIMEIRO QUADRIMESTRE (v)', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'VALOR TOTAL DE SUPERÁVIT NÃO APLICADO ATÉ O FINAL DO EXERCÍCIO (w)', alignment: 'center', bold: true, fontSize: 7 },
      { text: 'VALOR APLICADO ATÉ O PRIMEIRO QUADRIMESTRE QUE INTEGRARÁ O LIMITE CONSTITUCIONAL (x)', alignment: 'center', bold: true, fontSize: 7 },
    ])

    const gruposIndicadores = this.funcaoService.agrupar(indicadores, 'grupo', ['valor', 'superavit']);

    for (const gi of gruposIndicadores) {

      registros.push([
        { text: gi.grupo, fontSize: 7, bold: true },
        { text: this.funcaoService.convertToBrNumber(+gi.totalizadores['valor']), alignment: 'right', fontSize: 7, bold: true },
        { text: this.funcaoService.convertToBrNumber(+gi.totalizadores['superavit'])/*this.funcaoService.convertToBrNumber(aplicadoAnterior)*/, alignment: 'right', fontSize: 7, bold: true },
        { text: '0,00', alignment: 'right', fontSize: 7, bold: true },
        { text: '0,00', alignment: 'right', fontSize: 7, bold: true },
        { text: this.funcaoService.convertToBrNumber(+gi.totalizadores['superavit']), alignment: 'right', fontSize: 7, bold: true },
        { text: '0,00', alignment: 'right', fontSize: 7, bold: true },
      ]);

      for (const sub of gi.registros) {
        registros.push([
          { text: sub.subgrupo, fontSize: 7, bold: true },
          { text: this.funcaoService.convertToBrNumber(sub.valor), alignment: 'right', fontSize: 7, bold: true },
          { text: this.funcaoService.convertToBrNumber(+sub.superavit), alignment: 'right', fontSize: 7, bold: true },
          { text: '0,00', alignment: 'right', fontSize: 7, bold: true },
          { text: '0,00', alignment: 'right', fontSize: 7, bold: true },
          { text: this.funcaoService.convertToBrNumber(+sub.superavit), alignment: 'right', fontSize: 7, bold: true },
          { text: '0,00', alignment: 'right', fontSize: 7, bold: true },
        ]);

      }

      return registros;
    }
  }

  public montarQuadroCsv(fundeb: any[], DCFundeb: any[], indicadores: any[], listaExportar): any {

    const espaco = {
      titulo: '',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(espaco)

    const primeira_linha = {
      titulo: 'INDICADORES - Art. 212-A, inciso XI e § 3º - Constituição Federal',
      coluna2: 'VALOR EXIGIDO (j)',
      coluna3: 'VALOR APLICADO (k)',
      coluna4: 'VALOR CONSIDERADO APÓS DEDUÇÕES (l)',
      coluna5: '% APLICADO (m)',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(primeira_linha)

    const grupoFundeb = this.funcaoService.agrupar(fundeb, 'grupo', ['arrecadado', 'atualizado', 'arrecadado_anterior', 'atualizado_anterior']);

    let valorExigido15: number = 0;
    let valorExigido16: number = 0;
    let valorExigido17: number = 0;
    let valorMax18: number = 0;
    let valorAplicado15: number = 0;
    let valorAplicado16: number = 0;
    let valorAplicado17: number = 0;
    let valorNaoAplicado18: number = 0;
    let deducoes15: number = 0;
    let deducoes16: number = 0;
    let deducoes17: number = 0;
    let ajuste18: number = 0;
    let aplicado15: number = 0;
    let aplicado16: number = 0;
    let aplicado17: number = 0;
    let naoAplicado18: number = 0;
    let excedente18: number = 0;
    let superavitAnterior: number = 0;
    let aplicadoAnterior: number = 0;

    for (const fundeb of grupoFundeb) {

      let sum: number = 0;
      let totalFundeb: number = 0;
      let totalSubFundeb: number = 0;

      valorMax18 = (((+fundeb.totalizadores['arrecadado']) * 10) / 100);

      superavitAnterior = ((+fundeb.totalizadores['arrecadado_anterior']) * 10 / 100);

      totalFundeb = +fundeb.totalizadores['arrecadado'];

      const subfundeb = this.funcaoService.agrupar(fundeb.registros, 'subgrupo', ['arrecadado', 'atualizado', 'arrecadado_anterior', 'atualizado_anterior']);

      for (const subgrupo of subfundeb) {

        if (subgrupo.grupo === '6.3- FUNDEB - Complementação da União - VAAT') {

          sum = +subgrupo.totalizadores['arrecadado'];

          valorExigido16 = (sum * 50 / 100);
          valorExigido17 = (sum * 15 / 100);
        }

        if (subgrupo.grupo === '6.4- FUNDEB - Complementação da União - VAAR') {
          valorExigido15 = (((+fundeb.totalizadores['arrecadado'] - subgrupo.totalizadores['arrecadado']) * 70) / 100);
          totalSubFundeb = (+fundeb.totalizadores['arrecadado'] - subgrupo.totalizadores['arrecadado']);
        }
      }
      const grupoDespesa = this.funcaoService.agrupar(DCFundeb, 'grupo', ['empenhado', 'liquidado', 'pago', 'resto', 'disponibilidade'])
      for (const gd of grupoDespesa) {

        if (gd.grupo === '12- TOTAL DAS DESPESAS DO FUNDEB COM PROFISSIONAIS DA EDUCAÇÃO BÁSICA') {
          valorAplicado15 += +gd.totalizadores['empenhado'];
          deducoes15 = valorAplicado15 - gd.totalizadores['disponibilidade'];
          aplicado15 = (deducoes15 / (+totalSubFundeb)) * 100;
        }
        if (gd.grupo === '13- TOTAL DAS DESPESAS CUSTEADAS COM FUNDEB - COMPLEMENTAÇÃO DA UNIÃO - VAAT APLICADAS NA EDUCAÇÃO INFANTIL') {
          valorAplicado16 += +gd.totalizadores['empenhado'];
          deducoes16 = valorAplicado16 - gd.totalizadores['disponibilidade'];
          aplicado16 = (deducoes16 / +fundeb.totalizadores['arrecadado']) * 100;
        }
        if (gd.grupo === '14- TOTAL DAS DESPESAS CUSTEADAS COM FUNDEB - COMPLEMENTAÇÃO DA UNIÃO - VAAT APLICADAS EM DESPESA DE CAPITAL') {
          valorAplicado17 += +gd.totalizadores['empenhado'];
          deducoes17 = valorAplicado17 - gd.totalizadores['disponibilidade'];
          aplicado17 = (deducoes17 / +fundeb.totalizadores['arrecadado']) * 100;
        }
        if (gd.grupo === '11- TOTAL DAS DESPESAS CUSTEADAS COM RECURSOS DO FUNDEB RECEBIDAS NO EXERCÍCIO') {
          valorNaoAplicado18 += totalFundeb - ((this.mes < 12 ? +gd.totalizadores['liquidado'] : +gd.totalizadores['empenhado']) - ((this.mes < 12 ? +gd.totalizadores['liquidado'] : +gd.totalizadores['empenhado']) - totalFundeb > 0 ? (this.mes < 12 ? +gd.totalizadores['liquidado'] : +gd.totalizadores['empenhado']) - totalFundeb : 0));
          ajuste18 += valorNaoAplicado18 /*+ (+gd.totalizadores['empenhado'] - totalFundeb)*/;
          naoAplicado18 = +fundeb.totalizadores['arrecadado'];
          aplicadoAnterior = +fundeb.totalizadores['arrecadado_anterior'];
        }
      }
      excedente18 = ajuste18 > valorMax18 ? ajuste18 - valorMax18 : 0;
      naoAplicado18 = (ajuste18 / naoAplicado18) * 100;
    }

    const linha_15 = {
      titulo: '15- Mínimo de 70% do FUNDEB na Remuneração dos Profissionais da Educação Básica',
      coluna2: this.funcaoService.convertToBrNumber(valorExigido15),
      coluna3: this.funcaoService.convertToBrNumber(valorAplicado15),
      coluna4: this.funcaoService.convertToBrNumber(deducoes15),
      coluna5: this.funcaoService.convertToBrNumber(aplicado15),
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(linha_15)

    const linha_16 = {
      titulo: '16 - Percentual de 50% da Complementação da União ao FUNDEB (VAAT) na Educação Infantil',
      coluna2: this.funcaoService.convertToBrNumber(valorExigido16),
      coluna3: this.funcaoService.convertToBrNumber(valorAplicado16),
      coluna4: this.funcaoService.convertToBrNumber(deducoes16),
      coluna5: this.funcaoService.convertToBrNumber(aplicado16),
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(linha_16)

    const linha_17 = {
      titulo: '17- Mínimo de 15% da Complementação da União ao FUNDEB - VAAT em Despesas de Capital',
      coluna2: this.funcaoService.convertToBrNumber(valorExigido17),
      coluna3: this.funcaoService.convertToBrNumber(valorAplicado17),
      coluna4: this.funcaoService.convertToBrNumber(deducoes17),
      coluna5: this.funcaoService.convertToBrNumber(aplicado17),
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(linha_17)

    const linha_indicador_minimo = {
      titulo: 'INDICADOR - Art.25, § 3º - Lei nº 14.113, de 2020 - (Máximo de 10% de Superávit)',
      coluna2: 'VALOR MÁXIMO PERMITIDO (n)',
      coluna3: 'VALOR NÃO APLICADO (o)',
      coluna4: 'VALOR NÃO APLICADO APÓS AJUSTE (p)',
      coluna5: 'VALOR NÃO APLICADO EXCEDENTE AO MÁXIMO PERMITIDO (q)',
      coluna6: '% NÃO APLICADO (r)',
      coluna7: ''
    }
    listaExportar.push(linha_indicador_minimo)

    const linha_18 = {
      titulo: '18- Total da Receita Recebida e não Aplicada no Exercício',
      coluna2: this.funcaoService.convertToBrNumber(valorMax18),
      coluna3: this.funcaoService.convertToBrNumber(valorNaoAplicado18),
      coluna4: this.funcaoService.convertToBrNumber(ajuste18),
      coluna5: this.funcaoService.convertToBrNumber(excedente18),
      coluna6: this.funcaoService.convertToBrNumber(naoAplicado18),
      coluna7: ''
    }
    listaExportar.push(linha_18)

    const linha_indicador = {
      titulo: 'INDICADOR - Art.25, § 3º - Lei nº 14.113, de 2020 - (Aplicação do Superávit de Exercício Anterior)',
      coluna2: 'VALOR DE SUPERÁVIT PERMITIDO NO EXERCÍCIO ANTERIOR (s)',
      coluna3: 'VALOR NÃO APLICADO NO EXERCÍCIO ANTERIOR (t)',
      coluna4:'VALOR NÃO APLICADO (o)',
      coluna5: 'VALOR APLICADO APÓS O PRIMEIRO QUADRIMESTRE (v)',
      coluna6: 'VALOR TOTAL DE SUPERÁVIT NÃO APLICADO ATÉ O FINAL DO EXERCÍCIO (w)',
      coluna7: 'VALOR APLICADO ATÉ O PRIMEIRO QUADRIMESTRE QUE INTEGRARÁ O LIMITE CONSTITUCIONAL (x)'
    }
    listaExportar.push(linha_indicador)

    const gruposIndicadores = this.funcaoService.agrupar(indicadores, 'grupo', ['valor', 'superavit']);

    for (const gi of gruposIndicadores) {
      const linha_gi = {
        titulo: gi.grupo,
        coluna2: this.funcaoService.convertToBrNumber(+gi.totalizadores['valor']),
        coluna3: this.funcaoService.convertToBrNumber(+gi.totalizadores['superavit']),
        coluna4: '0,00',
        coluna5: '0,00',
        coluna6: this.funcaoService.convertToBrNumber(+gi.totalizadores['superavit']),
        coluna7: '0,00'
      }
      listaExportar.push(linha_gi)

      for (const sub of gi.registros) {
        const linha_sub = {
          titulo: sub.grupo,
          coluna2: this.funcaoService.convertToBrNumber(+sub.valor),
          coluna3: this.funcaoService.convertToBrNumber(+sub.superavit),
          coluna4: '0,00',
          coluna5: '0,00',
          coluna6: this.funcaoService.convertToBrNumber(+sub.superavit),
          coluna7: '0,00'
        }
        listaExportar.push(linha_sub)
      }
    }
  }
}