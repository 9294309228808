import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  FuncaoService, GlobalService, LoginContabil, Produto, SaldoEstoqueService
} from 'eddydata-lib';
import { Subject } from 'rxjs';
import * as toastr from 'toastr';

declare type Saldo = {
  estoque: string,
  sum_entrada: number,
  sum_saida: number,
  saldo_atual: number
};

@Component({
  selector: 'lib-produto-saldo-estoque',
  templateUrl: './produto-saldo-estoque.component.html'
})

export class ProdutoSaldoEstoqueComponent implements OnInit, OnDestroy {
  @Input() public set produto(v: Produto) { this._produto = v; }
  @Input() login: LoginContabil;
  @Input() carregarAlmoxarifado: boolean = false;

  public saldos: Saldo[] = [];
  public _produto: Produto;
  public comSaldo: boolean = true;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    private saldoEstoqueService: SaldoEstoqueService
  ) { }

  ngOnInit() { 
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['carregarAlmoxarifado']) this.carregarSaldo()
  }

  private carregarSaldo() {
    if (this._produto && this._produto.id && this.login) {
      if (this.comSaldo === true) {
        this.saldoEstoqueService.saldoProdutoPorEstoque(this._produto.id, this.login.orgao.id, this.comSaldo)
          .subscribe((data) => {
            this.saldos = data;
            // this.saldos = data.filter((x) => { return x.saldo_atual != 0 });
          }, (error) => toastr.error(error.error.payload));
      } else {
        this.saldoEstoqueService.saldoProdutoPorEstoque(this._produto.id, this.login.orgao.id, this.comSaldo)
          .subscribe((data) => {
            this.saldos = data;
          }, (error) => toastr.error(error.error.payload));
      }
    }
      // this.saldoEstoqueService.saldoProdutoPorAlmoxarifado(this._produto.id, this.login.orgao.id)
      //   .subscribe((data) => {
      //     this.saldos = data;
      //   }, (error) => toastr.error(error.error.payload));
  }
}
