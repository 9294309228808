import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ParametroEmailFormComponent } from './parametro-email-form/parametro-email-form.component';
import { AuthGuard } from '../util/auth.guard';

const routes: Routes = [
  { path: '', component: ParametroEmailFormComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ParametroEmailRoutingModule { }
