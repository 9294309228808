import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { ContaBancaria } from '../tesouraria/conta-bancaria.model';

export class RecursoSiconfi extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public nome?: string,
    public exercicio?: Exercicio,
    public arquivo_importacao?: string,
    public contas?: ContaBancaria[]) {
    super();
  }
  static converteJson(json: any): RecursoSiconfi {
    return Object.assign(new RecursoSiconfi(), json);
  }
}