// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { MetaGoverno } from './meta-governo.model';
import { MetaOds } from './meta-ods.model';

export class MetaGovernoOds extends BaseResourceModel {
  constructor(
    public id?: number,
    public meta_governo?: MetaGoverno,
    public meta_ods?: MetaOds
  ) {
    super();
  }

  static converteJson(json: any): MetaGovernoOds {
    return Object.assign(new MetaGovernoOds(), json);
  }
}
