import { BaseResourceModel } from '../../models/base-resource.model';
import { RecursoSiconfi } from "./recurso-siconfi.model";
import { Recurso } from './recurso.model';

export class RecursoSiconfiRecurso extends BaseResourceModel {
  constructor(
    public id?: number,
    public recurso?: Recurso,
    public siconfi?: RecursoSiconfi,) {
    super();
  }
  static converteJson(json: any): RecursoSiconfiRecurso {
    return Object.assign(new RecursoSiconfiRecurso(), json);
  }
}