import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, Compra, Exercicio, LiquidacaoResto, RetencaoResto, Usuario } from 'eddydata-lib';
import { HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LiquidacaoRestoService extends BaseResourceService<LiquidacaoResto> {

  constructor(
    protected injector: Injector
  ) {
    super(`liquidacoes-restos-pagar`, injector);
  }

  public obterPorNumero(numero: number, ano: number, orgaoId: number): Observable<LiquidacaoResto> {
    return this.http.get<LiquidacaoResto>(
      `${this.login.cidade.id}/${this.api}?numero=${numero}&exercicio_ano=${ano}&orgao_id=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public atualizarDataAnulacao(id: number, data: string, orgao_id: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/data-anulacao/${id}/${data}/${orgao_id}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err)))
  }

  public obterTotalLiquidadoPorEmpenho(numero: number, ano: number, parcela: number, orgaoId: number, idExclusao?: number, exercicio?: number): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/total-liquidado-por-empenho/${numero}/${ano}/${parcela}/${orgaoId}/${exercicio}`
      + (idExclusao ? `?id_exclusao=${idExclusao}` : ''),
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterUltimaParcela(numero: number, exercicioId: number, orgaoId: number, ano: number, anuladoProcessado?: boolean): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/ultima-parcela/${numero}/${exercicioId}/${orgaoId}/${ano}?anuladoProcessado=${anuladoProcessado ? true : false}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public ultimaDataLiquidada(exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/ultima-data-liquidada/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public marcarComoImpresso(parametros: any): Observable<any> {
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/marcar-como-impresso`, JSON.stringify(parametros), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

  public anular(id: number, data: string, valor: number, historico: string, usuario: Usuario, especie: string, anularEmpenho: boolean, compra: Compra, retencoes: RetencaoResto[], superafit: boolean): Observable<LiquidacaoResto> {
    const anulacao = { id, data, valor, historico, usuario, especie, anularEmpenho, compra, retencoes, superafit };
    return this.http.post<LiquidacaoResto>(
      `${this.login.cidade.id}/${this.api}/anular`, JSON.stringify(anulacao), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

  public emAberto(exercicioId: number, orgaoId: number, parametros: {
    data_inicio: string, data_termino: string, despesa_id?: string, funcaoGoverno_id?: string, subFuncaoGoverno_id?: string, recurso_id?: string, aplicacao_id?: string, contrato_id?: string, recursosVariavel_inicial?: string, recursosVariavel_final?: string, favorecido_id?: string, data_vencimento?: string, empenho_id?: string, orderBy?: string, relations?: string[]
    , programa_ficha?: string, despesa_ficha?: string, acao_ficha?: string, funcao_ficha?: string, subfuncao_ficha?: string
    , recurso_ficha?: string, aplicacao_ficha?: string, recurso_variavel_ficha?: string
  }): Observable<any> {
    const { data_inicio, data_termino, despesa_id, funcaoGoverno_id, subFuncaoGoverno_id, recurso_id, aplicacao_id, contrato_id, recursosVariavel_inicial, recursosVariavel_final, favorecido_id, data_vencimento, empenho_id, orderBy, relations
      , programa_ficha, despesa_ficha, acao_ficha, funcao_ficha, subfuncao_ficha
      , recurso_ficha, aplicacao_ficha, recurso_variavel_ficha } = parametros;
    let params = new HttpParams().appendAll({
      data_inicio,
      data_termino
    });
    if (despesa_id) params = params.append('despesa_id', despesa_id);
    if (funcaoGoverno_id) params = params.append('funcaoGoverno_id', funcaoGoverno_id);
    if (subFuncaoGoverno_id) params = params.append('subFuncaoGoverno_id', subFuncaoGoverno_id);
    if (recurso_id) params = params.append('recurso_id', recurso_id);
    if (aplicacao_id) params = params.append('aplicacao_id', aplicacao_id);
    if (contrato_id) params = params.append('contrato_id', contrato_id);
    if (recursosVariavel_inicial) params = params.append('recursosVariavel_inicial', recursosVariavel_inicial);
    if (recursosVariavel_final) params = params.append('recursosVariavel_final', recursosVariavel_final);
    if (favorecido_id) params = params.append('favorecido_id', favorecido_id);
    if (data_vencimento) params = params.append('data_vencimento', data_vencimento);
    if (empenho_id) params = params.append('empenho_id', empenho_id);
    if (orderBy) params = params.append('order_by', orderBy);
    if (relations) params = params.append('relations', relations.join(','));
    if (programa_ficha) params = params.append('programa_ficha', programa_ficha);
    if (despesa_ficha) params = params.append('despesa_ficha', despesa_ficha);
    if (acao_ficha) params = params.append('acao_ficha', acao_ficha);
    if (funcao_ficha) params = params.append('funcao_ficha', funcao_ficha);
    if (subfuncao_ficha) params = params.append('subfuncao_ficha', subfuncao_ficha);
    if (recurso_ficha) params = params.append('recurso_ficha', recurso_ficha);
    if (aplicacao_ficha) params = params.append('aplicacao_ficha', aplicacao_ficha);
    if (recurso_variavel_ficha) params = params.append('recurso_variavel_ficha', recurso_variavel_ficha);

    const options = { ...this.httpOptions(), params };

    return this.http.get(
      `${this.login.cidade.id}/${this.api}/em-aberto/${exercicioId}/${orgaoId}`,
      options
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public liquidacaoEstoque(idsEstoque: number[], dataLiq: string, dataVenc: string, usuario?: Usuario, exercicio?: Exercicio): Observable<any> {
    const liquidacao = { idsEstoque, dataLiq, dataVenc, usuario, exercicio };
    return this.http.post<LiquidacaoResto>(
      `${this.login.cidade.id}/${this.api}/liquidacao-estoque`, JSON.stringify(liquidacao), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

  public obterTotalPagoPorParcela(exercicio: number, orgao: number, liquidacaoId: number): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/total-pago-por-parcela/${exercicio}/${orgao}/${liquidacaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterTotalLiquidadoPorParcela(empenho: number, exercicio: number, orgao: number, parcela: number): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/total-liquidado-por-parcela/${empenho}/${exercicio}/${orgao}/${parcela}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalParcelado(numeroEmpenho: number, exercicioId: number, orgaoId: number, ano: string): Observable<any> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/total-liquidado-parcelado/${numeroEmpenho}/${exercicioId}/${orgaoId}/${ano}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public anularLiquidacaoProcessada(liquidacoes: LiquidacaoResto[], liquidacao_original_id: number, exercicioId: number, orgaoId: number, usuario: number) {
    return this.http.post(`${this.login.cidade.id}/${this.api}/anular-liquidacao-processada/${liquidacao_original_id}/${exercicioId}/${orgaoId}/${usuario}`, { liquidacoes: liquidacoes }, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
