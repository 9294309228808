import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IMaskModule } from "angular-imask";
import { CompraRptComponent } from "./compra-rpt/compra-rpt.component";
import { SharedModule } from "eddydata-lib";
import { AccordionModule } from "primeng/accordion";
import { AutoCompleteModule } from "primeng/autocomplete";
import { CalendarModule } from "primeng/calendar";
import { FieldsetModule } from "primeng/fieldset";
import { RadioButtonModule } from "primeng/radiobutton";
import { ToastModule } from "primeng/toast";
import { CheckboxModule } from "primeng/checkbox";
import { InputMaskModule } from "primeng/inputmask";
import { MenuModule } from "primeng/menu";
import { RcmsRptComponent } from "./rcms-rpt/rcms-rpt.component";
import { PendenciaCompraRptComponent } from "./pendente-compra-rpt/pendencia-compra-rpt.component";
import { NotaCompraRptComponent } from "./nota-compra-rpt/nota-compra-rpt.component";
import { CompraContratoRptComponent } from "./compra-contrato-rpt/compra-contrato-rpt.component";
import { CompraLicitacaoRptComponent } from "./compra-licitacao-rpt/compra-licitacao-rpt.component";
import { RcmsContratoRptComponent } from "./rcms-contrato-rpt/rcms-contrato-rpt.component";
import { RcmsLicitacaoRptComponent } from "./rcms-licitacao-rpt/rcms-licitacao-rpt.component";
import { CompraMaterialoRptComponent } from "./compra-material-rpt/compra-material-rpt.component";
import { NotaCompraRptAlmoxarifadoComponent } from "./nota-compra-rpt-almoxarifado/nota-compra-rpt-almoxarifado.component";
import { RcmsCompraRptComponent } from "./rcms-compra-rpt/rcms-compra-rpt.component";
import { BancoPrecoCompraRpt } from "./banco-preco-compra-rpt/banco-preco-compra-rpt.component";
import { RcmsModule } from "../rcms/rcms.module";
import { ListagemSubgrupoRpt } from "./listagem-subgrupo-rpt/listagem-subgrupo-rpt.component";

@NgModule({
  declarations: [
    CompraRptComponent,
    RcmsRptComponent,
    RcmsCompraRptComponent,
    PendenciaCompraRptComponent,
    NotaCompraRptComponent,
    NotaCompraRptAlmoxarifadoComponent,
    CompraContratoRptComponent,
    CompraLicitacaoRptComponent,
    RcmsContratoRptComponent,
    RcmsLicitacaoRptComponent,
    CompraMaterialoRptComponent,
    BancoPrecoCompraRpt,
    ListagemSubgrupoRpt
  ],
  exports: [
    CompraRptComponent,
    RcmsRptComponent,
    RcmsCompraRptComponent,
    PendenciaCompraRptComponent,
    NotaCompraRptComponent,
    NotaCompraRptAlmoxarifadoComponent,
    CompraContratoRptComponent,
    CompraLicitacaoRptComponent,
    RcmsContratoRptComponent,
    RcmsLicitacaoRptComponent,
    CompraMaterialoRptComponent,
    BancoPrecoCompraRpt,
    ListagemSubgrupoRpt
  ],
  providers: [],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    RadioButtonModule,
    FieldsetModule,
    CheckboxModule,
    InputMaskModule,
    MenuModule,
    RcmsModule,
  ],
})
export class RelatorioCompraModule { }
