// tslint:disable: variable-name
import { Exercicio } from '../comum/exercicio.model';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Recurso } from './recurso.model';
import { Orgao } from '../comum/orgao.model';

export class TransferenciaPrevista extends BaseResourceModel {
  constructor(
    public id?: number,
    public aux?: number,
    public mes?: number,
    public referencia?: number,
    public evento?: string,
    public especie?: string,
    public valor_previsto?: number,
    public concessor?: Orgao,
    public recebedor?: Orgao,
    public orgao?: Orgao,
    public exercicio?: Exercicio,
    public recurso?: Recurso,
    public aplicacao?: Recurso,
    public data_cadastro?: Date,
    public data_alteracao?: Date
  ) {
    super();
  }

  static converteJson(json: any): TransferenciaPrevista {
    return Object.assign(new TransferenciaPrevista(), json);
  }
}
