import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Holerite } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HoleriteService extends BaseResourceService<Holerite> {

  constructor(
    protected injector: Injector
  ) {
    super(`holerites`, injector);
  }

  public calcularFolha(referencia: number, siafi: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/calcular-folha/${referencia}/${siafi}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalPorFaixaSalarial(exercicio: number, orgao: number): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/total-faixa/${exercicio}/${orgao}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalFolhaPorMes(exercicio: number, orgao: number): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/total-mes/${exercicio}/${orgao}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
