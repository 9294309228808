import { BaseResourceModel } from '../../models/base-resource.model';
import { Motorista } from './motorista.model';

export class MotoristaMulta extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public descricao?: string,
    public valor?: number,
    public pontuacao?: number,
    public orgao_responsavel?: string,
    public auto_infracao?: number,
    public recurso?: 'N' | 'D' | 'I',
    public data_multa?: Date,
    public data_pagamento_prev?: Date,
    public data_pagamento?: Date,
    public processo_adm?: string,
    public placa_veiculo?: string,
    public descricao_veiculo?: string,
    public observacao?: string,
    public local_infracao?: string,
    public motorista?: Motorista,
  ) {
    super();
  }

  static converteJson(json: any): MotoristaMulta {
    return Object.assign(new MotoristaMulta(), json);
  }
}
