
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContratoService, FuncaoGovernoService, RecursoService, DespesaService } from 'administrativo-lib';
import {
  Contrato, Despesa,
  EddyAutoComplete, FuncaoGoverno,
  FuncaoService, GlobalService, Login, Recurso
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { tsXLXS } from 'ts-xlsx-export';
import { CreditoItemService } from 'contabil-lib';

@Component({
  selector: 'app-credito-exportar',
  templateUrl: './credito-exportar.component.html'
})
export class CreditoExportarComponent implements OnInit, OnDestroy {

  login: Login = new Login();
  funcaoGoverno: FuncaoGoverno;
  subFuncaoGoverno: FuncaoGoverno;
  despesa: Despesa;
  recurso: Recurso;
  aplicacao: Recurso;
  contrato: Contrato;
  filtroDespesa: boolean;
  filtroFuncao: boolean;
  filtroSubFuncao: boolean;
  filtroRecurso: boolean;
  filtroAplicacao: boolean;
  filtroContrato: boolean;
  orcados: boolean = true;
  anulados: boolean = true;
  porOcorrencia: boolean = false;
  public selectedOrdem: string;
  public selectUsuario: boolean;

  public opcaoFiltro: 'TODOS' | 'DECRETO' | 'LEI' | 'ANULAÇÃO' = 'TODOS';
  public opcaoPeriodo: 'PERIODO' | 'MENSAL' = 'PERIODO';
  public opcaoTipo: 'TODOS' | 'SOMENTE' = 'TODOS';
  public listaMeses: Array<any>;
  public listaTipos: Array<any>;

  public ptBR: any;

  public contratoAutoComplete: EddyAutoComplete<Contrato>;
  public funcaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public subFuncaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public despesaAutoComplete: EddyAutoComplete<Despesa>;
  public relatorio = '1';
  public tipo = '1';
  public mes = '1';
  public dataInicial: Date;
  public dataFinal: Date;
  public decreto: string;
  public lei: string;
  protected unsubscribe: Subject<void> = new Subject();

  public maskLei = {
    mask: [
      { mask: '0000' },
      { mask: '0000/0000' },
      { mask: '00000/0000' },
    ]
  };
  public maskDecreto = {
    mask: [
      { mask: '0000' },
      { mask: '00000/0000' },
    ]
  };

  constructor(
    private router: Router,
    protected injector: Injector,
    protected messageService: MessageService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected funcaoGovernoService: FuncaoGovernoService,
    protected recursoService: RecursoService,
    protected despesaService: DespesaService,
    protected contratoService: ContratoService,
    protected creditoService: CreditoItemService) {
  }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.login = GlobalService.obterSessaoLogin();
    this.listaMeses = this.globalService.obterListaMeses();
    this.listaTipos = this.globalService.obterListaTiposCreditos();
    this.selectedOrdem = 'ord1';
    this.selectUsuario = false;
    this.dataInicial = new Date(this.login.exercicio.ano, 0, 1);
    this.dataFinal = new Date();
    this.carregarAutoCompletes();
    let interval = setInterval(() => { new GlobalService().calendarMascara(); clearInterval(interval) }, 1000);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public  gerarRelatorio() {
    const parametros = {};
    if (this.opcaoPeriodo === 'PERIODO') {
      if (!this.dataInicial || !this.dataFinal) {
        this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
        return;
      }
    }

    //Filtros gerais
    parametros['credito.exercicio.id'] = this.login.exercicio.id;
    parametros['credito.orgao.id'] = this.login.orgao.id;
    parametros['credito.ativo'] = true;

    if (this.opcaoPeriodo === 'MENSAL') {
      parametros['credito.mes'] = +this.mes;
    } else {
      parametros['credito.data_credito$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
      parametros['credito.data_credito$le'] = this.funcaoService.converteDataSQL(this.dataFinal);
    }

    if (this.opcaoFiltro === 'DECRETO') {
      if (this.decreto) {
        parametros['credito.decreto'] = this.decreto;
      }
    } else if (this.opcaoFiltro === 'LEI') {
      if (this.lei) {
        parametros['credito.lei'] = this.lei;
      }
    } else if (this.opcaoFiltro === 'ANULAÇÃO') {
      parametros['valor_credito$lt'] = 0;
    }

    if (this.opcaoTipo !== 'TODOS') {
      parametros['credito.tipo'] = this.tipo;
    }

    // if (this.filtroContrato) {
    //   parametros['credito.decreto'] = this.decreto;
    // }

    if (this.filtroRecurso) {
      parametros['ficha.recurso.id'] = this.recurso.id;
    }

    if (this.filtroAplicacao) {
      parametros['ficha.aplicacao.id'] = this.aplicacao.id;
    }

    //Não tem no extra orçamentário
    if (this.filtroFuncao) {
      parametros['ficha.funcao.id'] = this.funcaoGoverno.id;
    }
    if (this.filtroSubFuncao) {
      parametros['ficha.subfuncao.id'] = this.subFuncaoGoverno.id;
    }
    if (this.filtroDespesa) {
      parametros['ficha.despesa.id'] = this.despesa.id;
    }

    parametros['orderBy'] = this.porOcorrencia ? 'credito.ocorrencia,' : '';
    if (this.selectedOrdem === 'ord1') {
      parametros['orderBy'] += 'credito.data_credito';
    } else if (this.selectedOrdem === 'ord2') {
      parametros['orderBy'] += 'credito.decreto';  
    } else if (this.selectedOrdem === 'ord3') {
      parametros['orderBy'] += 'ficha.numero';
    }

    parametros['relations'] = 'credito,ficha,ficha.despesa,ficha.funcao,ficha.subfuncao,ficha.recurso,ficha.aplicacao,ficha.aplicacao_variavel,ficha.programa,ficha.acao,ficha.executora,ficha.executora.unidade';

    this.creditoService.filtrar(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        async lista => {
          const listaItens = new Array();
          console.log(lista.content)
          for (const item of lista.content) {
            const modalidade = await this.despesaService.obter({'codigo$like': `${item.ficha.despesa.codigo.substring(0,4)}%`, nivel: 2, exercicio_id: this.login.exercicio.id }).toPromise();
            const entity = {
              data: this.funcaoService.converteDataBR(item.credito.data_credito),
              decreto: item.credito.decreto,
              lei: item.credito.lei,
              tipo: this.globalService.obterTiposCreditos(item.credito.tipo),
              ocorrencia: this.globalService.obterOcorrenciasCreditos(item.credito.ocorrencia),
              ficha: item.ficha.numero,
              uo: `${item.ficha.executora.unidade.codigo} ${item.ficha.executora.unidade.nome}`,
              ue: `${item.ficha.executora.codigo} ${item.ficha.executora.nome}`,
              acao: `${item.ficha.acao.codigo} ${item.ficha.acao.nome}`,
              programa: `${item.ficha.programa.codigo} ${item.ficha.programa.nome}`,
              justificativa: item.credito.justificativa,
              modalidade: `${modalidade?.codigo} ${modalidade?.nome}`,
              elemento: `${item.ficha.despesa.codigo} ${item.ficha.despesa.nome}`,
              funcao: `${item.ficha.funcao.codigo} ${item.ficha.funcao.nome}`,
              subfuncao: `${item.ficha.subfuncao.codigo} ${item.ficha.subfuncao.nome}`,
              aplicacao: `${item.ficha.recurso.codigo}${item.ficha.aplicacao.codigo}${item.ficha.aplicacao_variavel?.variavel ? item.ficha.aplicacao_variavel?.variavel : '0000'} ${item.ficha.aplicacao_variavel?.nome ? item.ficha.aplicacao_variavel?.nome : ''}`,
              valor_credito: +item.valor_credito > 0 ? +item.valor_credito : 0,
              valor_anulado: +item.valor_credito < 0 ? +item.valor_credito : 0,
            };
            listaItens.push(entity);
          }
          tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile('creditos-adicionais');
        },
        () => alert('erro ao retornar lista')
      );
  }

  private carregarAutoCompletes() {
    // autocomplete para funcao governo
    this.funcaoAutoComplete = new EddyAutoComplete(null, this.funcaoGovernoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para subfuncao governo
    this.subFuncaoAutoComplete = new EddyAutoComplete(null, this.funcaoGovernoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para despesa
    this.despesaAutoComplete = new EddyAutoComplete(null, this.despesaService,
      'id', ['codigo', 'nome'], { nivel: 4, exercicio_id: this.login.exercicio.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para recurso
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para contrato
    this.contratoAutoComplete = new EddyAutoComplete(null, this.contratoService,
      'id', ['numero', 'favorecido.nome'], {
      orgao_id: this.login.orgao.id, relations: 'favorecido',
      orderBy: 'favorecido.nome'
    }, { number: ['numero'], text: ['favorecido.nome'] }
    );
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/planejmaneto']);
        break;
      default:
        this.sair();
        break;
    }
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }
}
