import { BaseResourceModel } from '../../models/base-resource.model';
import { GrupoArquivoTransparencia } from './grupo-arquivo-transparencia.model';

export class ArquivoTransparencia extends BaseResourceModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public nome?: string,
        public caminho?: string,
        public tipo?: string,
        public documento?: string,
        public tamanho?: number,
        public referencia?: string,
        public grupo?: GrupoArquivoTransparencia
    ) {
        super();
    }

    static converteJson(json: any): ArquivoTransparencia {
        return Object.assign(new ArquivoTransparencia(), json);
    }
}