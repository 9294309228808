import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { InputMaskModule } from 'primeng/inputmask';
import { ToastModule } from 'primeng/toast';
import { ProdutoBuscaDlgComponent } from './produto-busca-dlg/produto-busca-dlg.component';
import { ProdutoCadastroDlgComponent } from './produto-cadastro-dlg/produto-cadastro-dlg.component';
import { ProdutoUnBuscaDlgComponent } from './produto-un-busca-dlg/produto-un-busca-dlg.component';

@NgModule({
  declarations: [
    ProdutoBuscaDlgComponent,
    ProdutoCadastroDlgComponent,
    ProdutoUnBuscaDlgComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    AutoCompleteModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    ChartModule,
    InputMaskModule
  ],
  exports: [
    ProdutoBuscaDlgComponent,
    ProdutoCadastroDlgComponent,
    ProdutoUnBuscaDlgComponent,
  ],
  providers: [MessageService, ConfirmationService]
})
export class ProdutoDlgModule { }
