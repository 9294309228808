import { BaseResourceModel } from '../../models/base-resource.model';
import { Convenio } from './convenio.model';
import { FichaDespesa } from '../planejamento/ficha-despesa.model';
import { FichaReceita } from '../planejamento/ficha-receita.model';
// tslint:disable: variable-name
export class ConvenioFicha extends BaseResourceModel {
  constructor(
    public contrapartida?: boolean,
    public convenio?: Convenio,
    public ficha_despesa?: FichaDespesa,
    public ficha_receita?: FichaReceita,
    public editavel?: boolean
    ) {
    super();
  }
  static converteJson(json: any): ConvenioFicha {
    return Object.assign(new ConvenioFicha(), json);
  }
}
