import { Component, Input } from '@angular/core';
import {
  BaseResourceItemsComponent,
  EddyAutoComplete, FavorecidoSocio, Pessoa, PessoaService
} from 'eddydata-lib';
import * as toastr from 'toastr';
import { FavorecidoSocioService } from '../service/favorecido-socio.service';


declare var $: any;

@Component({
  selector: 'lib-favorecido-socio',
  templateUrl: './favorecido-socio.component.html'
})
export class FavorecidoSocioComponent extends BaseResourceItemsComponent<FavorecidoSocio> {

  public pessoaAutoComplete: EddyAutoComplete<Pessoa>;
  public itemAtual: FavorecidoSocio;
  public cadPessoa: Pessoa =  new Pessoa();
  public viewPessoa: Pessoa;

  @Input() public nomeDlgGestores = 'dialogPessoa';

  constructor(private pessoaService: PessoaService,
    favorecidoSocioService: FavorecidoSocioService) {
    super(new FavorecidoSocio(), favorecidoSocioService);
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
  }

  protected validSave(item: FavorecidoSocio): boolean {
    if (!item.pessoa || !item.pessoa.id) {
      toastr.warning('Informe o sócio vinculado!');
      return false;
    }
    return true;
  }
  protected beforeSave(item: FavorecidoSocio): void {
    item.orgao = this.login.orgao
    if (!item?.id){
      item.socio = item.socio ?? false;
      item.administrador = item.administrador ?? false;
      item.responsavel = item.responsavel ?? false;
    }
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private carregarAutoCompletes() {
    this.pessoaAutoComplete = new EddyAutoComplete(null, this.pessoaService,
      'id', ['cpf_cnpj', 'nome'], { orderBy: 'nome' }, { number: ['cpf_cnpj'], text: ['nome'] }
    );
  }

  public cadastroPessoa(item: FavorecidoSocio) {
    $(`#${this.nomeDlgGestores}`).modal('show');

    this.itemAtual = item;

    if (this.itemAtual?.pessoa)
      this.cadPessoa = this.itemAtual.pessoa;
  }

  public callbackPessoa(pessoa: Pessoa) {
    this.itemAtual.pessoa = pessoa;
    this.cadPessoa = new Pessoa();
  }

  public visualizarPessoa(item: FavorecidoSocio) {
    if (!item.pessoa)
      return;
    this.viewPessoa = item.pessoa;
    $('#dialogViewPessoa').modal('show');
  }

}
