import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, GlobalService, ReinfRegistro } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ReinfRegistroService extends BaseResourceService<ReinfRegistro> {

  constructor(
    protected injector: Injector
  ) {
    super(`reinf-registro`, injector);
  }

  inserir(resource: ReinfRegistro): Observable<any> {
    this.login = GlobalService.obterSessaoLogin();
    return this.http.post(`${this.login.cidade.id}/${this.api}`, resource, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  reverterRegistro(ids: Array<string>): Observable<any> {
    const param = {ids: ids};

    return this.http.post(`${this.login.cidade.id}/${this.api}/reverter`, param, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
