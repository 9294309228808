import { Component, OnInit, Injector } from '@angular/core';
import { LoginService } from '../servico/login.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { OrgaoService } from '../../orgao/service/orgao.service';
import { takeUntil } from 'rxjs/operators';
import { Orgao } from '../../entidade/comum/orgao.model';
import { Subject } from 'rxjs';
import { Login } from '../../entidade/login/login';
import { GlobalService } from '../../util/global.service';

@Component({
  selector: 'lib-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.css']
})
export class RecuperarSenhaComponent implements OnInit {

  public msgs: string;
  public email: string;
  public listaOrgaos: Array<Orgao> = []
  public orgao: Orgao = new Orgao()
  public login: Login

  protected router: Router;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    private authService: LoginService,
    private orgaoService: OrgaoService,
  ) {
    this.router = this.injector.get(Router);
  }

  ngOnInit() {
    this.login = GlobalService.obterSessaoLogin()

    this.orgao = this.login ? this.login.orgao : undefined

    this.orgaoService
      .obterTodos()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaOrgaos = res;
        this.orgao = res[0]
      });
  }

  async enviarEmail() {
    try {
      const resultado = await this.authService.recuperarSenha(this.email, this.orgao);
      if (resultado && resultado.error) {
        this.msgs = resultado.error.message;
      } else {
        this.router.navigate([`/recuperar-senha-sucesso`]);
      }
    } catch (ex) {
      toastr.error('Atenção', ex.error);
    }
  }

  voltar() {
    this.router.navigate([`/login`]);
  }

}
