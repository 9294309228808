// tslint:disable: variable-name
import { EtapaItemSetor } from 'eddydata-lib';
import { BaseResourceModel } from '../../models/base-resource.model';
import { ProdutoUnidade } from '../almoxarifado/produto-unidade.model';
import { Favorecido } from '../compra/favorecido.model';
import { EtapaMerenda } from './etapa-merenda.model';

export class EtapaItemMerenda extends BaseResourceModel {
  constructor(
    public id?: number,
    public favorecido?: Favorecido,
    public produto_unidade?: ProdutoUnidade,
    public qtde?: number,
    public cardapio?: EtapaMerenda,
    public itens?: EtapaItemSetor[],
    public editavel?: boolean,
    public editavel2?: boolean) {
    super();
  }
  static converteJson(json: any): EtapaItemMerenda {
    return Object.assign(new EtapaItemMerenda(), json);
  }
}
