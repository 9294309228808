import { TipoObjetoLicitacao } from "../../../components/types";
import { Audesp4Artigo16, Audesp4Artigo17, Audesp4AtestadoDesempenho, Audesp4Autorizacao, Audesp4ComissaoLicitacao, Audesp4ExistenciaRecursosSim, Audesp4IndiceEconomico, Audesp4JulgamentoComInversao, Audesp4JulgamentoSemInversao, Audesp4LicitanteLista, Audesp4PreQualificacaoSim, Audesp4Publicacao, Audesp4PublicacaoAtoRatificacaoNao, Audesp4Recurso } from "./types/comum-type";
import { Audesp4ComprasServicos, Audesp4ComprasServicosTI, Audesp4ObrasServicosEngenharia } from "./types/tipo-objeto-type";

export class RegistroPrecos {

    AdjudicacaoDt: string;
    AdjudicacaoNao: 'S' | 'N';
    AnoLicitacao: number;
    AnoProcessoAdm: number;
    AtaAberturaJulgamentoHabilitacaoPublicacao: Audesp4Publicacao[];
    AtaAberturaJulgamentoHabilitacaoPublicacaoNao: 'S' | 'N';
    AtestadoDesempenho: Audesp4AtestadoDesempenho[];
    AtestadoDesempenhoNao: 'S' | 'N';
    AudienciaPublicaDt: string;
    AudienciaPublicaNao: 'S' | 'N';
    Autorizacao: Audesp4Autorizacao;
    AvisoEdital2aFasePublicacao: Audesp4Publicacao[];
    BeneficioLei1232006: number;
    BIDEdital2aFase: 'S' | 'N';
    BIDEditalFasePreQualificacao: 'S' | 'N';
    BIDJulgamentoFasePreQualificacao: 'S' | 'N';
    BIDJulgamentoNegociacaoFinal: 'S' | 'N';
    BIDJulgamentoPropostasTecnicasComerciais: 'S' | 'N';
    BIDNao: 'S' | 'N';
    BIDObjecaoAvisoAberturaPreQualificacao: 'S' | 'N';
    CodigoLicitacao: number;
    ComissaoLicitacao: Audesp4ComissaoLicitacao;
    ComissaoLicitacaoNao: 'S' | 'N';
    ComprasServicos: Audesp4ComprasServicos;
    ComprasServicosTI: Audesp4ComprasServicosTI;
    DataEntregaPropostaNao: 'S' | 'N';
    DatasAberturaLicitacaoDt: string;
    DatasAberturaLicitacaoNao: 'S' | 'N';
    DatasEntregaPropostaDt: string;
    DatasJulgamentoDt: string;
    DatasJulgamentoNao: 'S' | 'N';
    DescricaoObjeto: string;
    DescricaoSituacaoLicitacao: string;
    divisaoLotes: 'UNICA' | 'LOTES';
    DtAtaRegPreco: string;
    DtAutorizacao: string;
    DtSituacaoLicitacao: string;
    Edital2aFaseDt: string;
    Edital2aFaseNumero: string;
    Edital2aFasePublicacao: Audesp4Publicacao[];
    Edital2aFasePublicacaoNao: 'S' | 'N';
    ExisteAtaAberturaPropostasTecnicasComerciais: 'S' | 'N';
    ExisteAtaJulgamentoFinal: 'S' | 'N';
    ExisteAtaJulgamentoPropostasTecnicasComerciais: 'S' | 'N';
    ExisteAtaRecebimentoPropostasTecnicasComerciais: 'S' | 'N';
    ExisteAutorizacaoAberturaEdital2aFase: 'S' | 'N';
    ExisteExigenciaGarantiaParticipacaoLicitacao: 'S' | 'N';
    ExisteJustificativaContratacaoDireta: 'S' | 'N';
    ExistenciaRecursosNao: 'S' | 'N';
    ExistenciaRecursosSim: Audesp4ExistenciaRecursosSim;
    ExisteProcedimentoPropostaComercial: 'S' | 'N';
    ExistePropostaFinal: 'S' | 'N';
    ExisteQuadroComparativo: 'S' | 'N';
    FoiRealizadoChamadoNegociacao: 'S' | 'N';
    ForamRegistradasAtasReunioesNegociacao: 'S' | 'N';
    FundamentoLei: number;
    HomologacaoPublicacaoDt: string;
    HomologacaoPublicacaoNao: 'S' | 'N';
    PublicacaoDt: string;
    PublicacaoNao: 'S' | 'N';
    IndiceEconomico: Audesp4IndiceEconomico[];
    IndiceEconomicoNao: 'S' | 'N';
    JulgamentoComInversao: Audesp4JulgamentoComInversao;
    JulgamentoSemInversao: Audesp4JulgamentoSemInversao;
    JustificativaContratacao: string;
    Lei13121: 'S' | 'N';
    LicitacaoPossuiParticipantes: 'S' | 'N';
    Licitante: Audesp4LicitanteLista;
    LicitanteNao: 'S' | 'N';
    lrf: '' | '16' | '17' | 'N';
    LRFArtigo16: Audesp4Artigo16;
    LRFArtigo17: Audesp4Artigo17;
    LRFNaoSeEnquadra: 'S';
    modalidade: number;
    ModalidadeLicitacaoOutros: string;
    NaturezaLicitacao: number;
    NomeDetentor: string;
    NumAtaRegPreco: number;
    NumeroLicitacao: string;
    NumeroProcessoAdm: string;
    ObrasServicosEngenharia: Audesp4ObrasServicosEngenharia;
    ParecerTecnicoJuridico: 'S' | 'N';
    PreQualificacaoNao: 'S' | 'N';
    PreQualificacaoSim: Audesp4PreQualificacaoSim;
    PublicacaoAtoRatificacaoDt: string;
    PublicacaoAtoRatificacaoNao: Audesp4PublicacaoAtoRatificacaoNao;
    QuantidadeLotes: number;
    Recurso: Audesp4Recurso[];
    RecursoNao: 'S' | 'N';
    SessaoPublica: 'S' | 'N';
    SituacaoLicitacao: number;
    Subcontratacao: 'S' | 'N';
    TipoLicitacao: number;
    tipoObjeto: TipoObjetoLicitacao;
    TotalLicitacaoValor: number;
    TrataContratacaoFundArt3Resolucao072014: 'S' | 'N';
    TributosEstaduais: 'S' | 'N';
    TributosFederais: 'S' | 'N';
    TributosMunicipais: 'S' | 'N';
    TributosNao: 'S' | 'N';
    Validade: number;

    constructor() {
        // REG0
        this.LicitacaoPossuiParticipantes = 'N'
        this.Subcontratacao = 'N'
        this.divisaoLotes = 'LOTES'
        this.tipoObjeto = 'COMPRA_SERVICO'
        this.ComprasServicos = { ObjetoLicitacao: undefined, Lote: [], AmostraNao: 'N', Amostra: { AmostrasItem: undefined } }
        this.ComprasServicosTI = {
            ObjetoLicitacaoTI: undefined, Lote: [], AmostraNao: 'N', Amostra: { AmostrasItem: undefined }, CertificadoANATEL: 'N',
            CompatibilidadeCompuitadoresSistemas: 'N', DescricaoTecnica: 'N', FinalidadeEquipamento: 'N', ManuaisEmPortugues: 'N',
            PedidoNomesMarcas: 'N', PedidoSubstituicaoEquipamentoAntigos: 'N', SoftwareProprietario: 'N'
        }
        this.ObrasServicosEngenharia = {
            ObjetoLicitacaoEN: undefined, Lote: [], VistaTecnicaNao: 'N',
            VistaTecnica: {
                VTecExigeCREA_SP: 'N', VTecExigeCurriculoProfissional: 'N', VTecItemEdital: undefined, VTecPrazoEmDias: undefined, VTecQtidadeEmpresas: undefined
            }
        }
        this.ExistenciaRecursosSim = {
            ExistenciaRecursosDt: undefined, ExistenciaRecursosValor: undefined, opcoes: {
                ConvenioFederal: false, EmendasParlamentaresIndividuais: false, OperacoesCredito: false, OutrasFontesDescricao: false,
                RecursosPropriosAdministracaoIndireta: false, RecursosPropriosFundosEspeciais: false, Tesouro: false, TransferenciasConveniosEstaduais: false
            }
        }
        this.ExistenciaRecursosNao = 'N'
        this.lrf = ''
        this.LRFNaoSeEnquadra = 'S'
        this.LRFArtigo16 = { AdequacaoPlano: 'N', Artigo16: 'S', EstimativaTrienal: 'N' }
        this.LRFArtigo17 = { Artigo17: 'S', MedidasCompensacao: 'N', MetasResultado: 'N', PrevisaoPpaLdo: 'N' }
        this.ParecerTecnicoJuridico = 'N'
        this.AudienciaPublicaNao = 'N'
        this.LicitanteNao = 'N'
        this.Autorizacao = { ExisteAutorizacaoResolucao7_2014: 'N', ExisteAutorizacaoSecretEstadualDL4116596: 'N' }
        this.Licitante = { LicitanteCNPJ: [], LicitanteCPF: [], LicitanteEstrangeiro: [] }
        this.AtestadoDesempenho = [];
        this.IndiceEconomico = [];
        this.Recurso = [];

        // REG1
        this.Lei13121 = 'N'
        this.ComprasServicos.Edital = {
            EditalDt: undefined, EditalNumero: undefined, GarantiaEditalPercente: undefined, GarantiaEditalValor: undefined,
            EditalPublicacao: [], GarantiaEditalItem: undefined, editalPublicacaoNao: 'N', garantiaEditalItemNao: 'N'
        }
        this.ComprasServicosTI.Edital = {
            EditalDt: undefined, EditalNumero: undefined, GarantiaEditalPercente: undefined, GarantiaEditalValor: undefined,
            EditalPublicacao: [], GarantiaEditalItem: undefined, editalPublicacaoNao: 'N', garantiaEditalItemNao: 'N'
        }
        this.ObrasServicosEngenharia.Edital = {
            EditalDt: undefined, EditalNumero: undefined, GarantiaEditalPercente: undefined, GarantiaEditalValor: undefined,
            EditalPublicacao: [], GarantiaEditalItem: undefined, editalPublicacaoNao: 'N', garantiaEditalItemNao: 'N'
        }
        this.DataEntregaPropostaNao = 'N'
        this.DatasAberturaLicitacaoNao = 'N'
        this.DatasJulgamentoNao = 'N'
        this.ComissaoLicitacaoNao = 'N'
        this.ComissaoLicitacao = {
            membros: undefined, TipoComissaoLicitacao: undefined, designacao: undefined, atoDesignacaoComissaoNao: 'N',
            AtoDesignacaoComissaoDt: undefined, AtoDesignacaoComissaoInicio: undefined, AtoDesignacaoComissaoFim: undefined
        }
        this.AtestadoDesempenhoNao = 'N'
        this.IndiceEconomicoNao = 'N'
        this.TributosNao = 'N'
        this.TributosFederais = 'N'
        this.TributosEstaduais = 'N'
        this.TributosMunicipais = 'N'
        this.JulgamentoComInversao = {
            ExisteAtaAberturaJulgamentoDocumentosHabilitacao: { Data: undefined, ExisteData: 'S' },
            ExisteAtaAberturaJulgamentoPropostas: { Data: undefined, ExisteData: 'S' },
            ExisteAtaJulgamentoPropostas: { Data: undefined, ExisteData: 'S' },
            NaoExisteAtaAberturaJulgamentoDocumentosHabilitacao: 'N', NaoExisteAtaAberturaJulgamentoPropostas: 'N',
            NaoExisteAtaJulgamentoPropostas: 'N', QuadroComparativoAberturaProposta: 'N'
        }
        this.JulgamentoSemInversao = {
            ExisteAtaAberturaDocumentosHabilitacao: { Data: undefined, ExisteData: 'S' },
            ExisteAtaAberturaJulgamentoPropostas: { Data: undefined, ExisteData: 'S' },
            ExisteAtaJulgamentoPropostas: { Data: undefined, ExisteData: 'S' },
            ExisteAtaJulgamentoDocumentosHabilitacao: { Data: undefined, ExisteData: 'S' },
            NaoExisteAtaAberturaDocumentosHabilitacao: 'N', NaoExisteAtaAberturaJulgamentoPropostas: 'N',
            NaoExisteAtaJulgamentoDocumentosHabilitacao: 'N', NaoExisteAtaJulgamentoPropostas: 'N', QuadroComparativoAberturaProposta: 'N'
        }
        this.RecursoNao = 'N'
        this.SessaoPublica = 'N'
        this.HomologacaoPublicacaoNao = 'N'
        this.AdjudicacaoNao = 'N'

        // REG2
        this.BIDNao = 'N';
        this.BIDObjecaoAvisoAberturaPreQualificacao = 'N';
        this.BIDEditalFasePreQualificacao = 'N';
        this.BIDJulgamentoFasePreQualificacao = 'N';
        this.BIDEdital2aFase = 'N';
        this.BIDJulgamentoPropostasTecnicasComerciais = 'N';
        this.BIDJulgamentoNegociacaoFinal = 'N';
        this.PreQualificacaoNao = 'N';
        this.ExisteAutorizacaoAberturaEdital2aFase = 'N';
        this.Edital2aFasePublicacaoNao = 'N';
        this.ExisteExigenciaGarantiaParticipacaoLicitacao = 'N';
        this.ExisteAtaAberturaPropostasTecnicasComerciais = 'N';
        this.ExisteAtaRecebimentoPropostasTecnicasComerciais = 'N';
        this.ExisteAtaJulgamentoPropostasTecnicasComerciais = 'N';
        this.ExisteProcedimentoPropostaComercial = 'N';
        this.FoiRealizadoChamadoNegociacao = 'N';
        this.ForamRegistradasAtasReunioesNegociacao = 'N';
        this.ExistePropostaFinal = 'N';
        this.ExisteAtaJulgamentoFinal = 'N';
        this.ExisteQuadroComparativo = 'N';
        this.AtaAberturaJulgamentoHabilitacaoPublicacaoNao = 'N';
        this.AtaAberturaJulgamentoHabilitacaoPublicacao = [];
        this.AvisoEdital2aFasePublicacao = [];
        this.Edital2aFasePublicacao = [];

        // REG3
        this.PublicacaoAtoRatificacaoNao = { PublicacaoAtoRatificacao: 'N', DataFinalizacaoProcesso: undefined };
        this.ExisteJustificativaContratacaoDireta = 'N';
        this.TrataContratacaoFundArt3Resolucao072014 = 'N';
    }
}