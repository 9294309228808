import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RelatorioPersonalizadoLstComponent } from './relatorio-personalizado-lst/relatorio-personalizado-lst.component';
import { RelatorioPersonalizadoFormComponent } from './relatorio-personalizado-form/relatorio-personalizado-form.component';
import { AuthGuard } from '../util/auth.guard';
import { RelatorioPersonalizadoViewBaseComponent } from './relatorio-personalizado-view-base/relatorio-personalizado-view-base.component';

const routes: Routes = [
  { path: '', component: RelatorioPersonalizadoLstComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: RelatorioPersonalizadoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: RelatorioPersonalizadoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: RelatorioPersonalizadoViewBaseComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RelatorioPersonalizadoRoutingModule { }
