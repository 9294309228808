// tslint:disable: variable-name
import { Memorial, MemorialItem } from 'eddydata-lib';
import { BaseResourceModel } from '../../models/base-resource.model';
import { ProdutoUnidade } from '../almoxarifado/produto-unidade.model';
import { RcmsCotacao } from './rcms-cotacao.model';
import { Rcms } from './rcms.model';

export class RcmsItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public aux?: number,
    public ordem?: number,
    public quantidade?: number,
    public valor_unitario?: number,
    public produto?: string,
    public unidade?: string,
    public cota?: 'P' | 'R' | 'PRINCIPAL' | 'RESERVADO',
    public produto_unidade?: ProdutoUnidade,
    public rcms?: Rcms,
    public cotacoes?: RcmsCotacao[],
    public editavel?: boolean,
    public memorial?: Memorial | 'N',
    public memorial_item?: MemorialItem | 'N',
  ) {
    super();
  }
  static converteJson(json: any): RcmsItem {
    return Object.assign(new RcmsItem(), json);
  }
}
