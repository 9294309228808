import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Page, BaseResourceService, PlanoConta } from 'eddydata-lib';


@Injectable({
  providedIn: 'root'
})
export class PlanoContaService extends BaseResourceService<PlanoConta> {

  constructor(
    protected injector: Injector
  ) {
    super('plano-contas', injector);
  }

  public obterPorNome(nome: string, exercicioId: number): Observable<Page> {
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/filtrar?nome$like=${nome.toUpperCase()}%&exercicio_id=${exercicioId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorCodigo(codigo: string, exercicioId: number): Observable<Page> {
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/filtrar?codigo$like=${codigo}%&exercicio_id=${exercicioId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public balanceteSistema(orgao: number[], exercicioId: number, mes: number, geral?: boolean): Observable<any> {
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/balancete-sistema/${orgao.join()}/${exercicioId}/${mes}/${geral ? 'true' : ''}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public balanceteSistemaData(orgao: number[], exercicioId: number, dt1: string, dt2: string, geral?: boolean): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/balancete-sistema/${orgao.join()}/${exercicioId}/null/${dt1}/${dt2}/${geral ? 'true' : ''}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public importarPlanoContaXls(exercicioId: number, contas: PlanoConta[]) {
    const body = {
      exercicio: exercicioId,
      lista: contas
    }

    return this.http.post(`${this.login.cidade.id}/${this.api}/importar-plano-conta-xls`, body, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public atualizarPlanoConta(exercicioId: number, contasIncluir: PlanoConta[], contasExcluir: Number[]) {
    const body = {
      exercicio: exercicioId,
      listaInclusao: contasIncluir,
      listaExclusao: contasExcluir
    }

    return this.http.post(`${this.login.cidade.id}/${this.api}/atualizar-plano-conta`, body, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterDePara<T>(exercicioId: number): Observable<T[]> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/de-para/${exercicioId}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }
}
