import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { RemuneracaoLstComponent } from './remuneracao-lst/remuneracao-lst.component';
import { RemuneracaoRoutingModule } from './remuneracao-routing.module';
import { RemuneracaoShwComponent } from './remuneracao-shw/remuneracao-shw.component';


@NgModule({
  declarations: [
    RemuneracaoLstComponent,
    RemuneracaoShwComponent,
  ],
  imports: [
    CommonModule,
    RemuneracaoRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
    IMaskModule,
    ToastModule
  ]
})
export class RemuneracaoModule { }
