import { Component, Input, SimpleChanges } from '@angular/core';
import { Login, InventarioSetor, InventarioSetorStorage, Setor } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { InventarioStorageService } from '../service/inventario-storage.service';

declare var $: any;

@Component({
  selector: 'app-inventario-storage',
  templateUrl: './inventario-storage.component.html'
})
export class InventarioStorageComponent {

  @Input() public login: Login;
  @Input() public entidade: InventarioSetor;
  @Input() public arquivos: InventarioSetorStorage[];
  @Input() visualizar: boolean;

  public uploadedFiles: any[] = [];
  public descricao: string = null;

  public setor: Setor;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected confirmationService: ConfirmationService,
    private storageService: InventarioStorageService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setor = this.login.setor.find(setor => {
      return this.entidade?.setor.id === setor.id
    })
    
  }

  public async onUpload(event: any, fileUpload: any) {
    if (!event.files || event.files.length === 0) {
      toastr.warning('Selecione o(s) arquivo(s)');
      return;
    }
    try {
      for (const file of event.files) {
        this.uploadedFiles.push(file);
      }
      this.storageService
        .upload(this.uploadedFiles, this.login.orgao.id, this.entidade.id, this.descricao).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          for (const item of res) {
            item.data_cadastro = new Date();
            this.arquivos.unshift(item);
          }
        }, (res) => {
          toastr.error(res.error.payload);
          this.uploadedFiles.length = 0;
        });
    } catch (ex) {
      toastr.error(ex.message ? ex.message : ex);
    }
    this.descricao = undefined;
    this.uploadedFiles = []
    fileUpload.clear();
    $('#dialogUploadInventario').modal('hide');
  }

  public async remover(item: InventarioSetorStorage) {
    if (!item) return
    this.confirmationService.confirm({
      message: 'Deseja realmente remover o item?',
      header: 'Exclusão',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.storageService.remover(item.id).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro removido com sucesso!', 'Exclusão');
            this.arquivos.splice(this.arquivos.indexOf(item), 1);
          }, (err) => toastr.error(err.error.payload));
      }
    });
  }

}
