import { DatePipe } from "@angular/common";
import { Component, Injector, OnInit } from "@angular/core";
import {
  BaseResourceListComponent,
  Coluna,
  Favorecido,
  FavorecidoService,
  Filtro,
  FormatoExportacao,
  GlobalService,
  LoginContabil,
} from "eddydata-lib";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "lib-favorecido-lst",
  templateUrl: "./favorecido-lst.component.html",
})
export class FavorecidoLstComponent
  extends BaseResourceListComponent<Favorecido, LoginContabil>
  implements OnInit {
  /**
   * Declaração de variáveis
   */

  public mes: string;
  private datepipe: DatePipe;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private favorecidoService: FavorecidoService,
    protected globalService: GlobalService
  ) {
    super(favorecidoService, injector);
    this.datepipe = new DatePipe('pt');
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(item: Favorecido): boolean {
    return this.login.sistema != 'controle-interno'
  }

  protected relations(): string {
    return "cidade,cnaes.cnae,penalidades";
  }

  protected condicoesGrid(): {} {
    return {
      ["cidade.id"]: this.login.cidade.id,
    };
  }

  protected ordenacaoGrid(): string[] {
    return ["id$DESC"];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ["id"],
      text: ["nome", "municipio", "email", "nome_fantasia", "cpf_cnpj", "telefone", "cnaes.cnae.nome"],
    };
  }

  protected afterInit(): void {
    this.usarFiltroPersonalizado = true;
  }

  public beforeInit(): void {
  }

  protected acaoRemover(model: Favorecido): Observable<Favorecido> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: "Código", coluna: "id" },
      { titulo: "CPF/CNPJ", coluna: "cpf_cnpj" },
      { titulo: "Nome/Razão social", coluna: "nome" },
      { titulo: "Endereço", coluna: "endereco" },
      { titulo: "Bairro", coluna: "bairro" },
      { titulo: "Cidade", coluna: "municipio" },
      { titulo: "Telefone", coluna: "telefone" },
    ];
  }

  public obterColunasfiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];

    retorno.push({ titulo: 'ID', coluna: 'id', tipo: 'String', cols: 2 });
    retorno.push({
      titulo: 'Telefone', coluna: 'telefone', tipo: 'String', cols: 2,
      mask: [{ mask: '0000-0000' }, { mask: '00000-0000' }, { mask: '(00)0000-0000' }, { mask: '(00)00000-0000' }]
    });
    retorno.push({
      titulo: 'CPF/CNPJ', coluna: 'cpf_cnpj', tipo: 'String', cols: 4, padrao: true,
      mask: [{ mask: '000.000.000-00' }, { mask: '00.000.000/0000-00' }]
    });
    retorno.push({ titulo: 'Municipio', coluna: 'municipio', tipo: 'String', cols: 4 });
    retorno.push({ titulo: 'E-mail', coluna: 'email', tipo: 'String', cols: 4 });
    retorno.push({ titulo: 'Nome', coluna: 'nome', tipo: 'String', padrao: true, cols: 4 });
    retorno.push({ titulo: 'Descrição CNAE', coluna: 'cnaes.cnae.nome', tipo: 'String', cols: 4 });
    retorno.push({
      titulo: 'Situação', coluna: 'inativo', tipo: 'Selection', opcoesSelecao: [
        { valor: 'false', label: 'ATIVO' },
        { valor: 'true', label: 'INATIVO' }
      ], padrao: true
    });
    retorno.push({ titulo: 'Penalidades', coluna: 'penalidades', tipo: 'Boolean', callBack: this.adequacaoFiltroPenalidade.bind(this) })

    return retorno;
  }

  private adequacaoFiltroPenalidade(coluna: Coluna): { chave: string, valor: string } | { chave: string, valor: string }[] {
    delete coluna.coluna['penalidades']

    return [
      { chave: 'penalidades.data_inicio$le', valor: this.datepipe.transform(new Date(), 'yyyy-MM-dd') },
      { chave: 'penalidades.data_vencimento$ge', valor: this.datepipe.transform(new Date(), 'yyyy-MM-dd') },
      { chave: 'OR', valor: 'penalidades.bloquear_compra=true;!;!;penalidades.bloquear_empenho=true;!;!;penalidades.bloquear_pagamento=true;!;!;penalidades.bloquear_contratacao=true' }
    ]
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros["relations"] = this.relations();
    this.favorecidoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (lista) => {
          if (formato === "pdf") {
            this.imprimir(
              "LISTAGEM DE FORNECEDORES/CREDORES",
              this.login.usuario.nome,
              this.login.usuario.sobrenome,
              this.login.orgao.nome,
              this.login.brasao,
              "landscape",
              "Listagem fornecedor/credor",
              ["auto", "auto", "*", "*", "*", "auto", "auto"],
              lista.content
            );
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert("erro ao retornar lista")
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public apenasConsulta(): boolean {
    let sistemas = ["requisicao"];
    return sistemas.includes(this.login.sistema.toLocaleLowerCase());
  }

}
