import { Audesp4Responsavel, Audesp4ResponsavelTI } from "./comum-type"

export type Audesp4ObrasCorpoTecnico = {
    ResponsavelObraCorrespondeContrato?: 'S' | 'NA';
    ContratadaMantemPrepostoNaObra?: 'S' | 'NA';
    FiscalCredenciadoCREALei8666693art67?: 'S' | 'NA';
}

export type Audesp4ServicoCorpoTecnico = {
    PrestacaoServico: 'S';
    HouveTransferencia?: 'S' | 'NA';
    MONumeroEmpregadoEstabelecidoEdital?: 'S' | 'NA';
    ExisteControleServicoPrestado?: 'S' | 'NA';
}

export type Audesp4ConferenciaMedicaoSim = {
    NumeroMedicao: number;
    ConferenciaDt: string;
    PercentualExecucao: number;
    HouveSupressaoObraBensServico?: 'S' | 'NA';
    ResponsavelRecebimentoMedicao?: Audesp4Responsavel[];
}

export type Audesp4ConferenciaMedicaoSimTI = {
    NumeroMedicao: number;
    ConferenciaDt: string;
    PercentualExecucao: number;
    HouveSupressaoObraBensServico?: 'S' | 'NA';
    ResponsavelRecebimentoMedicaoTI?: Audesp4ResponsavelTI[];
}

export type Audesp4LiquidacaoDocSimilarSim = {
    NotaEmpenhoNumero: string;
    NotaEmpenhoDataEmissao: string;
    LiquidacaoEmpenhoDt: string;
    LiquidacaoEmpenhoValor: number;
    editavel?: boolean;
    liquidacaoId?: number;
}