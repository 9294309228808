import { Pipe, PipeTransform } from "@angular/core";
import { FuncaoService } from "eddydata-lib";

@Pipe({ name: 'mascarar'})
export class MascaraPipe implements PipeTransform {
    transform(valor: string, mascara: string) {
        const funcaoService = new FuncaoService();
        return funcaoService.mascarar(mascara, valor);

    }
}