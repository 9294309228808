import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GrupoEstoqueFormComponent } from './grupo-estoque-form/grupo-estoque-form.component';
import { GrupoEstoqueListComponent } from './grupo-estoque-list/grupo-estoque-list.component';
import { GrupoEstoqueViewComponent } from './grupo-estoque-view/grupo-estoque-view.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: GrupoEstoqueListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: GrupoEstoqueFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: GrupoEstoqueFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: GrupoEstoqueViewComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GrupoEstoqueRoutingModule { }
