import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FuncaoService, GlobalService, Login, OrgaoAssinaturaService, TransferenciaBancaria, TransferenciaDescendial } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { RecebimentoService } from '../../recebimento/service/recebimento.service';

@Component({
  selector: 'app-transferencia-descendial-dlg',
  templateUrl: './transferencia-descendial-dlg.component.html'
})
export class TransferenciaDescendialDlgComponent implements OnInit, OnDestroy {

  protected datepipe: DatePipe;
  public ptBR: any;
  public selectUsuario: boolean;
  public marcarComoImpresso: boolean;

  public selecionados: TransferenciaDescendial[] = [];

  public lista: any[];
  public mes = 0;
  public total = 0;
  public descendio = 1;
  public checkboxMaster = false;

  public imaskValor = {
    mask: Number,
    scale: 5,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  public listaDescendio: Array<any>;
  public listaMes: Array<any>;

  protected unsubscribe: Subject<void> = new Subject();

  public imaskNumero = {
    mask: Number,
    scale: 0,
    signed: false
  };

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  @Input() login: Login;
  @Input() transferencia: TransferenciaBancaria;
  @Output() callback: EventEmitter<TransferenciaDescendial[]> = new EventEmitter();

  constructor(
    protected messageService: MessageService,
    private globalService: GlobalService,
    protected assinaturaService: OrgaoAssinaturaService,
    private funcaoService: FuncaoService,
    private recebimentoService: RecebimentoService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.listaDescendio = [
      { id: '1', nome: '1º DESCÊNDIO' },
      { id: '2', nome: '2º DESCÊNDIO' },
      { id: '3', nome: '3º DESCÊNDIO' }
    ];

    this.listaMes = [
      { id: '0', nome: '' },
      { id: '1', nome: 'JANEIRO' },
      { id: '2', nome: 'FEVEREIRO' },
      { id: '3', nome: 'MARÇO' },
      { id: '4', nome: 'ABRIL' },
      { id: '5', nome: 'MAIO' },
      { id: '6', nome: 'JUNHO' },
      { id: '7', nome: 'JULHO' },
      { id: '8', nome: 'AGOSTO' },
      { id: '9', nome: 'SETEMBRO' },
      { id: '10', nome: 'OUTUBRO' },
      { id: '11', nome: 'NOVEMBRO' },
      { id: '12', nome: 'DEZEMBRO' }
    ];
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  salvar() {
    let lista = this.lista.filter(e => e.selecionado === true);
    this.callback.emit(
      lista.map(e => {
        delete e['valor_descendio_completo']
        return e;
      })
    );
  }

  carregarDados() {
    const mounth = this.funcaoService.strZero(this.mes, 2);
    let filtro1 = `${this.login.exercicio.ano}-${mounth}-01`;
    let filtro2 = `${this.login.exercicio.ano}-${mounth}-10`;
    if (this.descendio == 2) {
      filtro1 = `${this.login.exercicio.ano}-${mounth}-11`;
      filtro2 = `${this.login.exercicio.ano}-${mounth}-20`;
    } else if (this.descendio == 3) {
      filtro1 = `${this.login.exercicio.ano}-${mounth}-21`;
      const ultimoDia = this.funcaoService.ultimoDiaMes(+this.mes, this.login.exercicio.ano);
      filtro2 = `${this.login.exercicio.ano}-${mounth}-${ultimoDia}`;
    }
    if (this.mes > 0) {
      this.recebimentoService.filtrar(0, -1, {
        'orgao.id': this.login.orgao.id,
        'exercicio.id': this.login.exercicio.id,
        'data_recebimento$ge': filtro1,
        'data_recebimento$le': filtro2,
        'ficha.ensino$gt': 0,
        relations: 'ficha,ficha.receita,conta',
        'orderBy': 'data_recebimento,ficha.numero'
      }).subscribe((res) => {
        this.lista = [];
        this.total = 0;
        let i = 1
        for (const it of res.content) {
          const model = new TransferenciaDescendial();
          model.transferenciaBanco = this.transferencia;
          model.selecionado = false;
          const descendio = +(+it.valor_recebido * +(+it.ficha.ensino / 100).toFixed(2))
          model['valor_descendio_completo'] = descendio;
          model.valor_descendio = +descendio.toFixed(2);
          model.descendio = this.descendio;
          model.data_lancto = it.data_recebimento;
          model.data_transferencia = this.transferencia.data_referencia;
          model.recebimento = it;

          this.lista.push(model);
        }

        this.somarRecebimento()
      });
    }
  }

  public somarRecebimento() {
    this.total = 0;
    let total_ficha = 0;

    let separadoPorFicha = this.lista.filter((v, i, a) => +a.findIndex(t => (+t.recebimento.ficha.id === +v.recebimento.ficha.id)) === i)

    separadoPorFicha = separadoPorFicha.map((transferencia: TransferenciaDescendial )=>{
      let grupoFicha = this.lista.filter((transf: TransferenciaDescendial) => +transf.recebimento.ficha.id === +transferencia.recebimento.ficha.id && transf.selecionado === true)
      transferencia['totalFicha'] = grupoFicha.reduce((acc: number, interavel: TransferenciaDescendial) => +(+(acc + +interavel.recebimento.valor_recebido).toFixed(2)).toFixed(2), 0)

      return transferencia
    })

    total_ficha = separadoPorFicha.reduce((acc: number, item: TransferenciaDescendial)=> +(item['totalFicha'] * (+item.recebimento.ficha.ensino / 100)).toFixed(2) + acc
    ,0)
 
    this.total = +total_ficha
  }

  public validSelect(transferencia: TransferenciaDescendial) {
    this.verificarCheckMaster();
    this.somarRecebimento()
    const sel = this.selecionados.find((s) => s.id === transferencia.id);
    return !(!sel)
  }

  public todosSelecionados(): boolean {
    return !this.lista ? false : this.lista.filter((l) => this.validSelect(l)).length === this.lista.length;
  }

  public verificarCheckMaster(): void {
    this.checkboxMaster = !(!!this.lista.find(item => !item.selecionado))
  }

  public toggleSelect(): void {
    this.lista.forEach(item => item.selecionado = this.checkboxMaster);
    this.somarRecebimento();
  }

  public selecionar(transferencia: TransferenciaDescendial, select: boolean) {
    this.validSelect(transferencia);
    if (select) {
      this.selecionados.push(transferencia);
    } else
      this.selecionados = this.selecionados.filter((s) => s.id !== transferencia.id);
  }

}
