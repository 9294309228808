import { Component, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BaseResourceFormComponent, DateFormatPipe, Despesa, EddyAutoComplete, ExercicioService, FuncaoService, GlobalService,
  LoginContabil, PlanoContratacaoAnual, PlanoContratacaoItem, Produto, Unidade, UnidadeService
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { PlanoContratacaoAnualService } from '../service/plano-contratacao-anual.service';
import * as toastr from 'toastr';
import { PlanoContratacaoAnualItensService } from '../service/plano-contratacao-anual-itens.service';
import { DatePipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { CompraItemService } from '../../compra/service/compra-item.service';
import { timer } from 'rxjs';
import { DespesaService } from 'administrativo-lib';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ProdutoService } from 'almoxarifado-lib';

declare var $: any;

@Component({
  selector: 'lib-plano-contratacao-anual-view',
  templateUrl: './plano-contratacao-anual-view.component.html'
})
export class PlanoContratacaoAnualViewComponent extends BaseResourceFormComponent<PlanoContratacaoAnual, LoginContabil> {

  datepipe: DatePipe;
  etapa: number = 1;
  unidadeAutoComplete: EddyAutoComplete<Unidade>;
  subElementoAutoComplete: EddyAutoComplete<Despesa>;
  dataInicio: Date;
  dataFim: Date;
  indiceFinanceiro: number;
  indiceQuantitativo: number;
  preLista = [];
  totalPCA: number = 0;
  listaSubelemento: { sub: string, total: number }[] = [];
  listaItens: Array<PlanoContratacaoItem> = new Array<PlanoContratacaoItem>();
  itemAtual: PlanoContratacaoItem = null;
  exibirCompletos: boolean = false;
  exibirSoMaterial: boolean = false;
  exibirSoServico: boolean = false;
  subelemento: string = '';
  subelementoList: Array<string> = new Array<string>();
  subelementoComplete = [];
  todos: boolean = false;
  public produtoAutoComplete: EddyAutoComplete<Produto>;
  public produto: Produto;

  //Variaveis ajuste rapido
  categoria: number = 0;
  dataDesejada: Date;
  subelementoGeral: Despesa;
  marcarCompleto: boolean = false;
  marcarIncompleto: boolean = false;
  seguir: boolean = false;

  public imaskConfig = {
    mask: Number,
    scale: 2,
    signed: true,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  public tiposCatalogo: { id: number, nome: string }[] = [
    { id: 1, nome: 'CNBS' },
    { id: 2, nome: 'Outros' },
  ];

  public ptBR: any;

  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected unidadeService: UnidadeService,
    private despesaService: DespesaService,
    protected service: PlanoContratacaoAnualService,
    protected itensService: PlanoContratacaoAnualItensService,
    protected produtoService: ProdutoService,
    private compraItemService: CompraItemService,
    protected exercicioService: ExercicioService) {
    super(new PlanoContratacaoAnual(), injector, PlanoContratacaoAnual.converteJson, service);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      orgao: [null, [Validators.required]],
      unidade: [null, [Validators.required]],
      exercicio: [null, [Validators.required]],
      sequencial_pncp: [null],
      cadastrado_pncp: [false],
      itens: [null],
      ignorar_pncp: [false],
      data_pesquida_inicio: [null],
      data_pesquida_fim: [null]
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'orgao,unidade,exercicio,itens,usuario_exclusao',
      orderBy: 'itens.numero'
    };
  }

  protected afterInit(): void {
    this.ptBR = this.globalService.obterDataBR();
    this.unidadeAutoComplete = new EddyAutoComplete(this.entidadeForm.get('unidade'), this.unidadeService,
      'id', ['codigo', 'nome'], { ppa_id: this.login.ppa.id, orgao_id: this.login.orgao.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome', 'codigo'] }
    );
    this.datepipe = new DatePipe('pt');
    this.dataInicio = new DateFormatPipe().transform(new Date(new Date().getFullYear() - 1, 0, 1, 0, 0, 0), []);
    this.dataFim = new DateFormatPipe().transform(new Date(new Date().getFullYear() - 1, 11, 31, 0, 0, 0), []);
  }

  protected beforeSubmit() {
    try {
      if (this.listaItens.length == 0) {
        // throw new Error('É obrigatório o cadastro de pelo menos 1 item.');
        this.entidadeForm.get('itens').setValue([]);
      } else {
        this.entidadeForm.get('itens').setValue(this.listaItens);
      }

      if (this.entidade.id == null) {
        this.entidadeForm.get('data_pesquida_inicio').setValue(this.dataInicio);
        this.entidadeForm.get('data_pesquida_fim').setValue(this.dataFim);
      }

      this.entidadeForm.get('orgao').setValue(this.login.orgao);
      this.entidadeForm.get('exercicio').setValue(this.login.exercicio);

    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterLoad() {
    this.listaItens = this.entidade?.itens;
    this.subelementoList.push('');
    const parametros = { exercicio_id: this.login.exercicio.id, nivel: 6, orderBy: 'codigo', ativo: true }

    this.subElementoAutoComplete = new EddyAutoComplete(null, this.despesaService, 'codigo', ['nome'],
      parametros,
      { number: ['id', 'codigo'], text: ['nome'] },
      async () => {
        if (this.itemAtual != null) {
          this.itemAtual.subelemento = this.subelementoGeral.codigo + ' - ' + this.subelementoGeral.nome;
        }
      }, 10
    );
    if (!this.listaItens) {
      this.listaItens = new Array<PlanoContratacaoItem>();
    } else {
      for (const i of this.listaItens) {
        if (this.subelementoList.indexOf(i.subelemento) == -1) {
          this.subelementoList.push(i.subelemento);
        }

        // if (i.pendente) {
          i['exibir'] = true;
        // }
      }
      toastr.success(`Lista do PCA carregada, ${this.listaItens.length} itens sendo exibidos.`)
    }
    if (this.entidade.id) {
      this.etapa = 3;
    }
  }

  search(event: any) {
    this.subelementoComplete = this.subelementoList.filter((i) => i.includes(event.query.toUpperCase()));
  }

  async filtrarLista() {
    const copia = [].concat(this.listaItens);
    this.listaItens = [];
    toastr.info('Aguarde, processando lista...');
    await timer(1000).toPromise();
    let qtd = 0;
    for (const i of copia) {
      i['selecionado'] = false;
      i['exibir'] = true;

      if (this.subelemento != null && this.subelemento?.trim().length > 0 && i.subelemento != this.subelemento) {
        i['exibir'] = false;
      }

      if (!this.exibirCompletos && !i.pendente) {
        i['exibir'] = false;
      }

      if (this.exibirSoMaterial && i.classificacao_catalogo == 2) {
        i['exibir'] = false;
      } else if (this.exibirSoServico && i.classificacao_catalogo == 1) {
        i['exibir'] = false;
      }

      qtd = i['exibir'] ? qtd + 1 : qtd;


      this.listaItens.push(i);
    }

    toastr.info(`Filtro aplicado, ${qtd} itens sendo exibidos.`);
  }

  protected afterSubmit(ent: PlanoContratacaoItem) {
    if (this.etapa == 2) {
      this.etapa = 3;
    }
  }

  mudarEtapa(proxima: boolean) {
    if (!proxima) {
      this.etapa--;
      return;
    }

    if (this.etapa == 1 && this.entidadeForm.get('unidade').value == null) {
      toastr.info('Necessário selecionar qual unidade para o PCA.');
      return;
    } else if (this.etapa == 1) {
      this.etapa++;
      return;
    }

    if (this.etapa == 2) {
      this.iniciarPCA();
    }
  }

  public iniciarPCA() {
    let ordemItem: number = 1;
    for (const item of this.preLista) {
      const planoItem = new PlanoContratacaoItem();

      // atributos padrão da entidade
      planoItem.catalogo = 2
      const cod = item.classificacao.split('.');
      planoItem.codigo_item = cod[3];
      planoItem.classificacao_superior = `${cod[0]}.${cod[1]}.${cod[2]}`;
      planoItem.classificacao_superior_nome = this.funcaoService.abreviarStr(item.codigo_produto.split('-').slice(1).join('-').trim(), 254);
      planoItem.descricao = (item.descricao.length > 1000 ? item.descricao.substring(0, 1000) : item.descricao);
      planoItem.subelemento = item.subel_cod_nome;
      planoItem.unidade_fornecimento = item.un_nome;
      planoItem.quantidade = item.quantidade_corrigido;
      planoItem.valor_unitario = item.valor_corrigido;
      planoItem.unidade_requisitante = this.entidadeForm.get('unidade').value.nome;

      planoItem.data_desejada = new Date(`${this.login.exercicio.ano}-01-01`);

      planoItem.numero = ordemItem;
      ordemItem++;
      planoItem.categoria_item = item.servico ? 2 : 1;
      planoItem.classificacao_catalogo = item.servico ? 2 : 1;
      planoItem.valor_orcamentario = +(planoItem.quantidade * planoItem.valor_unitario).toFixed(2);

      planoItem['valor_total'] = item.valor_total

      this.listaItens.push(planoItem)
    }

    this.submitForm();
  }

  public async editar(item: any) {
    item.data_desejada = new DateFormatPipe().transform(
      item?.data_desejada,
      []
    );
    this.itemAtual = item
  }



  salvarItem(completo?: boolean) {
    if (completo != null) {
      this.itemAtual.pendente = completo;
    }
    if (!this.itemAtual.codigo_item || (this.itemAtual.codigo_item !== '' + this.produto && this.produto !== null)) {
      this.itemAtual.codigo_item = '' + this.produto
    }
    if (!this.itemAtual.id) {
      this.itemAtual['exibir'] = true;
      if (this.itemAtual.numero) {
        this.listaItens[this.itemAtual.numero - 1] = this.itemAtual;
      } else {
        this.itemAtual.numero = this.itemAtual.numero ? this.itemAtual.numero : this.listaItens.length + 1;
        this.listaItens.push(this.itemAtual);
      }
    } else {
      this.listaItens[this.listaItens.indexOf(this.listaItens.find(i => i.numero == this.itemAtual.numero))] = this.itemAtual;
    }

    this.itemAtual = null;
    toastr.info('Lembre-se de salvar o PCA para efetivar as mudanças no item');
  }


  public obterCategoriaItem(categoria: number) {
    switch (categoria) {
      case 1:
        return '1 - Material';
      case 2:
        return '2 - Serviço'        
      case 3:
        return '3 - Obras'        
      case 4:
        return '4 - Serviços de Engenharia'        
      case 5:
        return '5 - Soluções de TIC'        
      case 6:
        return '6 - Locação de Imóveis'        
      case 7:
        return '7 - Alienação, concessão ou permissão'
      case 8:
        return ' 8 - Obras e Serviços de Engenharia'
      default:
        return 'Nenhuma'
    }

  }

}
