import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EmpenhoService } from 'administrativo-lib';
import { EddyAutoComplete, Empenho, EmpenhoExtra, EmpenhoResto, Favorecido, FavorecidoService, FuncaoService, GlobalService, Login, Relatorio } from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { EmpenhoExtraService } from '../../empenho-extra/service/empenho-extra.service';
import { EmpenhoRestoService } from '../../empenho-resto/service/empenho-resto.service';

@Component({
  selector: 'lib-empenho-fornecedor-dlg',
  templateUrl: './empenho-fornecedor-dlg.component.html'
})
export class EmpenhoFornecedorDlgComponent implements OnInit, OnDestroy {

  @Input() login: Login;

  protected datepipe: DatePipe;
  public ptBR: any;
  protected unsubscribe: Subject<void> = new Subject();

  favorecidoAutoComplete: EddyAutoComplete<Favorecido>;
  favorecido: Favorecido = null;


  public imaskNumero = {
    mask: Number,
    scale: 0,
    signed: false
  };


  constructor(
    protected confirmationService: ConfirmationService,
    protected messageService: MessageService,
    private globalService: GlobalService,
    private funcaoService: FuncaoService,
    private empenhoService: EmpenhoService,
    private extraService: EmpenhoExtraService,
    private restoService: EmpenhoRestoService,
    private favorecidoService: FavorecidoService
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['nome'], { 'cidade.id': this.login.cidade.id, relations: 'tipo,contas.banco', orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  async imprimir() {

    const parametros = {};

    parametros['favorecido.id'] = this.favorecido.id;
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['exercicio.id'] = this.login.exercicio.id;
    parametros['relations'] = 'liquidacoes,liquidacoes.pagamentos,exercicio';

    //Busca empenhos orçamentários
    let res = await this.empenhoService.filtrar(0, -1, parametros).toPromise();
    const orcamentarios = res.content;

    parametros['relations'] = 'pagamentos';
    res = await this.extraService.filtrar(0, -1, parametros).toPromise();
    const extras = res.content;

    parametros['relations'] = 'liquidacoes,liquidacoes.pagamentos';
    res = await this.restoService.filtrar(0,-1, parametros).toPromise();

    Relatorio.imprimirPersonalizado('EMPENHO POR FORNECEDOR', this.login.usuario.nome, this.login.usuario.sobrenome,
      this.login.orgao.nome, this.login.brasao,
      this.montarConteudo(orcamentarios, extras, null),
      'portrait', 'EMPENHO POR FORNECEDOR',
      {
        linhas: {
          hLineWidth() {
            return 1;
          },
          vLineWidth() {
            return 1;
          },
          hLineColor() {
            return 'black';
          },
          paddingLeft() {
            return 3;
          },
          paddingRight() {
            return 3;
          }
        }
      }, true, true, null, null, null, null, this.dadosCabecalho());

  }

  private montarConteudo(orcamentarios, extras, restos) {
    const conteudo = [];

    conteudo.push(this.orcamentarios(orcamentarios).concat(this.extras(extras)));

    return conteudo;
  }

  private dadosCabecalho(): {}[] {
    let brasaoImage: {};
    if (this.login.brasao) {
      brasaoImage = {
        image: this.login.brasao,
        width: 60,
        alignment: 'center',
        margin: [0, 30, 0, 0]
      };
    } else {
      brasaoImage = { margin: [0, 10, 0, 0], text: '', border: [true, true, true, false] };
    }

    return [brasaoImage,
      { text: `${this.login.orgao.endereco} ${this.login.cidade?.nome} ${this.login.cidade?.estado?.nome} CNPJ: ${this.login.orgao.cnpj}`, alignment: 'center', fontSize: 8, border: [true, false, true, false] },
      {
        text: `EMPENHOS POR FORNECEDOR`,
        bold: true, alignment: 'center', fontSize: 12, border: [true, false, true, false]
      },
      {
        text: `${this.funcaoService.mascarar('##.###.###/###-##', this.favorecido.cpf_cnpj)} - ${this.favorecido.nome}`, alignment: 'center'
      }
    ]

  }

  private orcamentarios(empenhos: Empenho[]): {}[] {
    const conteudo = [];

    conteudo.push([{ text: 'Empenho', bold: true, alignment: 'center' }, { text: 'Documento Fiscal', bold: true, alignment: 'center' },
    { text: 'Valor pago', bold: true, alignment: 'center' }, { text: 'Data Pagamento', bold: true, alignment: 'center' }]);

    const datepipe = new DatePipe('pt');

    for (const e of empenhos) {
      for (const l of e.liquidacoes) {
        for (const p of l.pagamentos) {
          conteudo.push([{ text: [{ text: `${e.numero}/${e.exercicio.ano}`, bold: true }, `${l.parcela == 0 ? '' : ' - ' + l.parcela}`] },
          l.documento,
          'R$ ' + this.funcaoService.convertToBrNumber(p.valor_pago),
          datepipe.transform(p.data_pagamento, 'dd/MM/yyyy')
          ]);
        }
      }
    }

    return [
      { text: 'ORÇAMENTÁRIOS', bold: true, fontSize: 15, alignment: 'center' },
      {
        layout: 'linhas',
        table: {
          widths: ['*', '*', '*', '*'],
          dontBreakRows: true,
          body: conteudo
        }
      }
    ];

  }

  private extras(extras: EmpenhoExtra[]): {}[] {
    const conteudo = [];
    if (extras.length == 0) {
      return [];
    }

    conteudo.push([{ text: 'Empenho', bold: true, alignment: 'center' }, { text: 'Documento Fiscal', bold: true, alignment: 'center' },
    { text: 'Valor pago', bold: true, alignment: 'center' }, { text: 'Data Pagamento', bold: true, alignment: 'center' }]);

    const datepipe = new DatePipe('pt');

    for (const e of extras) {
      for (const p of e.pagamentos) {
        conteudo.push([{ text: e.numero, bold: true }, ' - ',
        'R$ ' + this.funcaoService.convertToBrNumber(p.valor_pago),
        datepipe.transform(p.data_pagamento, 'dd/MM;yyyy')]);
      }
    }

    return [
      { text: 'EXTRAS', bold: true, fontSize: 15, alignment: 'center' },
      {
        layout: 'linhas',
        table: {
          widths: ['*', '*', '*', '*'],
          dontBreakRows: true,
          body: conteudo
        }
      }
    ];
  }

  private restos(restos: EmpenhoResto[]): {}[] {
    const conteudo = [];

    conteudo.push([{ text: 'Empenho', bold: true, alignment: 'center' }, { text: 'Documento Fiscal', bold: true, alignment: 'center' },
    { text: 'Valor pago', bold: true, alignment: 'center' }, { text: 'Data Pagamento', bold: true, alignment: 'center' }]);

    const datepipe = new DatePipe('pt');

    for(const e of restos){
      for(const l of e.liquidacoes){
        for(const p of l.pagamentos){
          conteudo.push([{ text: [{ text: `${e.numero}/${e.exercicio.ano}`, bold: true }, `${l.parcela == 0 ? '' : ' - ' + l.parcela}`] },
          l.documento,
          'R$ ' + this.funcaoService.convertToBrNumber(p.valor_pago),
          datepipe.transform(p.data_pagamento, 'dd/MM/yyyy')
          ]);
        }
      }
    }

    return [
      { text: 'RESTOS', bold: true, fontSize: 15, alignment: 'center' },
      {
        layout: 'linhas',
        table: {
          widths: ['*', '*', '*', '*'],
          dontBreakRows: true,
          body: conteudo
        }
      }
    ];
  }


}
