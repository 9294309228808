import { Component } from '@angular/core';
import { Banco, BaseResourceItemsComponent, EddyAutoComplete, FavorecidoBanco, Recurso } from 'eddydata-lib';
import * as toastr from 'toastr';
import { RecursoService } from '../../../contabil/recurso/recurso.service';
import { BancoService } from '../../../tesouraria/banco/service/banco.service';
import { FavorecidoBancoService } from '../service/favorecido-banco.service';

@Component({
  selector: 'lib-favorecido-banco',
  templateUrl: './favorecido-banco.component.html'
})
export class FavorecidoBancoComponent extends BaseResourceItemsComponent<FavorecidoBanco>{

  public bancoAutoComplete: EddyAutoComplete<Banco>;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public convenioAutoComplete: EddyAutoComplete<Recurso>;

  constructor(private bancoService: BancoService,
    private recursoService: RecursoService,
    public favorecidoBancoService: FavorecidoBancoService) {
    super(new FavorecidoBanco(), favorecidoBancoService);
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
  }

  protected validSave(item: FavorecidoBanco): boolean {
    if (!item.banco) {
      toastr.warning('Informe o banco!');
      return false;
    }
    if (!item.agencia) {
      toastr.warning('Informe a agência!');
      return false;
    }
    if (!item.numero_conta) {
      toastr.warning('Informe a número da conta!');
      return false;
    }
    return true;
  }

  protected beforeSave(item: FavorecidoBanco): void {
    item.orgao = this.login.orgao
  }

  private carregarAutoCompletes() {
    // autocomplete para banco
    this.bancoAutoComplete = new EddyAutoComplete(null, this.bancoService,
      'febraban', ['nome'],
      { orderBy: 'nome' },
      { number: ['febraban'], text: ['nome'] }
    );

    // autocomplete para recurso
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'codigo,nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'codigo,nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para convenio
    this.convenioAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'codigo,nome' }, { text: ['codigo', 'nome'] }
    );
  }

  public obterTipoConta(item: string) {
    switch (item) {
      case 'C':
        return 'CONTA CORRENTE';
      default:
        return 'CONTA POUPANÇA';
    }
  }

}
