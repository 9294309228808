import { TipoComissao } from "../../components/types";
import { BaseResourceModel } from "../../models/base-resource.model";
import { Orgao } from "../comum/orgao.model";
import { ComissaoMembro } from "./comissao-membro.model";

export class Comissao extends BaseResourceModel {
    constructor(
        public id?: number,
        public nome?: string,
        public publicacao?: Date,
        public vigencia_inicio?: Date,
        public vigencia_termino?: Date,
        public ano_portaria?: number,
        public portaria?: string,
        public tipo?: TipoComissao,
        public ativo?: boolean,
        public orgao?: Orgao,
        public membros?: ComissaoMembro[]
    ) {
        super();
    }

    static converteJson(json: any): Comissao {
        return Object.assign(new Comissao(), json);
    }
}
