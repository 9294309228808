// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { SetorAlmoxarifado } from '../almoxarifado/setor-almoxarifado.model';
import { EtapaMerenda } from './etapa-merenda.model';
import { EtapaItemSetor } from './etapa-item-setor.model';

export class EtapaSetorMerenda extends BaseResourceModel {
  constructor(
    public id?: number,
    public etapa?: EtapaMerenda,
    public setor_almoxarifado?: SetorAlmoxarifado,
    public editavel?: boolean,
    public itens?: EtapaItemSetor[]) {
    super();
  }
  static converteJson(json: any): EtapaSetorMerenda {
    return Object.assign(new EtapaSetorMerenda(), json);
  }
}
