import { TipoIntegracaoLicitacao } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Cidade } from '../geo/cidade.model';
import { OrgaoBrasao } from './orgao-brasao.model';
import { OrgaoConfiguracao } from './orgao-configuracao.model';

export class Orgao extends BaseResourceModel {

  constructor(
    public id?: number,
    public codigo?: string,
    public nome?: string,
    public cnpj?: string,
    public endereco?: string,
    public numero_endereco?: string,
    public bairro?: string,
    public cep?: string,
    public email?: string,
    public siafi?: string,
    public ativo?: boolean,
    public site?: string,
    public telefone?: string,
    public ibge?: string,
    public especie?: string,
    public tribunal?: string,
    public tipo_orgao_siconfi?: 'EX' | 'LG' | 'JD' | 'NA',
    public cidade?: Cidade,
    public brasao?: OrgaoBrasao,
    public configuracao?: OrgaoConfiguracao,
    public mostrar_transparencia?: boolean,
    public data_implantacao?: Date,
    public cadastrado_pncp?: boolean,
    public esfera_orgao?: 'F' | 'E' | 'M' | 'D' | 'N',
    public tipo_integracao?: TipoIntegracaoLicitacao,
    public chave_acesso?: string,
  ) {
    super();
  }

  static converteJson(json: any): Orgao {
    return Object.assign(new Orgao(), json);
  }
}
