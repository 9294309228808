import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { switchMap, takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { Subject } from 'rxjs';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { UsuarioAcesso } from '../../entidade/comum/usuario-acesso.model';
import { GlobalService } from '../../util/global.service';
import { UsuarioAcessoService } from '../service/usuario-acesso.service';
import { FuncaoService } from '../../util/funcao.service';

declare var $: any;

@Component({
  selector: 'lib-usuario-acesso',
  templateUrl: './usuario-acesso.component.html'
})
export class UsuarioAcessoComponent implements OnInit, AfterViewInit, OnDestroy {

  protected login: LoginContabil;
  protected datepipe: DatePipe;
  public ptBR: any;

  public paginaCorrente: number;
  public paginaTotal: number;
  public filtro: string;
  public listAcessos: Array<UsuarioAcesso>;

  protected unsubscribe: Subject<void> = new Subject();
  
  limite: number = 10;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private route: ActivatedRoute,
    protected messageService: MessageService,
    public globalService: GlobalService,
    private usuarioAcessoService: UsuarioAcessoService
  ) { }

  ngOnInit() {
    this.login = GlobalService.obterSessaoLogin();
    this.paginaCorrente = 1;
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    this.globalService.calendarMascara();
    this.preencherGrid();
  }


  preencherGrid() {
    const parametros = [];
    if (this.filtro) {
      const filtroSemAcentos = new FuncaoService().removerAcentos(this.filtro);
      let filtroTextOR: string[] = [];
      for (const f of ['usuario.nome', 'tipo', 'sistema']) {
        filtroTextOR = filtroTextOR.concat(f.concat('$like=%').concat(filtroSemAcentos).concat('%'));
      }
      parametros['OR'] = filtroTextOR.join(';!;!;');
    }
    parametros['relations'] = 'usuario';
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['orderBy'] = 'data_cadastro$DESC';

    this.route.paramMap
      .pipe(switchMap(params => this.usuarioAcessoService.filtrar(
        this.paginaCorrente, this.limite, parametros
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.listAcessos = lista.content;
          this.paginaTotal = lista.totalPages;
        }, error => {
          if (error.error && error.error.payload) {
            toastr.error(error.error.payload);
          } else {
            toastr.error('Ocorreu um erro ao processar a sua solicitação');
          }
        });
  }

  public onClick() {
    this.preencherGrid();
  }

  public onKeydown(event: any) {
    if (event.key === 'Enter') {
      document.getElementById('button-addon2').click();
    }
  }

  public proximaPagina() {
    this.paginaCorrente = this.paginaCorrente === this.paginaTotal ? this.paginaTotal : this.paginaCorrente + 1;
    this.preencherGrid();
    window.scrollTo(0, 0);
  }

  public paginaDigitada(pagina: number) {
    this.paginaCorrente =
      +pagina >= this.paginaTotal
        ? this.paginaTotal
        : +pagina;
    this.preencherGrid();
    window.scrollTo(0, 0);
  }

  public paginaAnterior() {
    this.paginaCorrente = this.paginaCorrente === 1 ? 1 : this.paginaCorrente - 1;
    this.preencherGrid();
    window.scrollTo(0, 0);
  }

  public mudarLimite(limite: number) {
    this.limite = limite;
    this.paginaDigitada(1);
  }
}
