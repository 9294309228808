import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import * as toastr from 'toastr';
import { ConfirmationService } from 'primeng/api';
import { LicitacaoService, PropostaService } from 'administrativo-lib';
import { LicitacaoStorageService } from 'compra-lib';
import { Login } from '../../../entidade/login/login';
import { Licitacao } from '../../../entidade/licitacao/licitacao.model';
import { Memorial } from '../../../entidade/licitacao/memorial.model';
import { Proposta } from '../../../entidade/licitacao/proposta.model';
import { PNCPService } from '../../../util/pncp.service';
import { FuncaoService } from '../../../util/funcao.service';
import { ProgressoService } from '../../../components/progresso/service/progresso.service';
import { AmparoLegalService } from '../../../util/amparolegal.service';
import { LicitacaoStorage } from '../../../entidade/licitacao/licitacao-storage.model';


declare var $: any;

@Component({
  selector: 'app-pncp-licitacao-cadastro',
  templateUrl: './pncp-licitacao-cadastro.component.html'
})
export class PncpLicitacaoCadastroComponent implements OnInit {

  @Input() login: Login;
  @Input() licitacao: Licitacao = null;
  @Input() codConvocatorio: number = 0;
  @Output() voltar: EventEmitter<any> = new EventEmitter();

  amparoLegal: string = 'AMPARO NÃO IDENTIFICADO';
  resultados: Array<{ licitacao: string, sucesso: boolean, mensagem: string }> = new Array<{ licitacao: string, sucesso: boolean, mensagem: string }>();
  historicoPNCP: Array<any> = new Array<any>();

  //Para modificações
  listaItensEnvio: Array<Memorial> = new Array<Memorial>();
  propostaCancelando: Proposta = new Proposta();
  listaEnvioResultados: Array<Proposta> = new Array<Proposta>();
  relationsLicitacao = 'modalidade,arquivos,itens,itens.propostas,itens.propostas.proponente,itens.propostas.proponente.favorecido,setor,setor.unidade,exercicio,orgao';
  private amparos: {
    codigo: number;
    lei: string;
  }[];


  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected licitacaoService: LicitacaoService,
    protected storageService: LicitacaoStorageService,
    protected confirmationService: ConfirmationService,
    protected progressoService: ProgressoService,
    protected amparoLegalService: AmparoLegalService,
    private funcaoService: FuncaoService,
    private pncpService: PNCPService,
    private propostaService: PropostaService) {
  }

  async ngOnInit(): Promise<void> {
    this.amparos = await this.amparoLegalService.buscarAmparosPNCP(1, false);
  }

  public podeIncluir(url?: string) {
    if (this.login) {
      if (new FuncaoService().campoJsonToken(this.login.token, "administrador") == true) {
        return true;
      }

      return new FuncaoService().podeIncluir(this.login, url)
    }
    return false;
  }

  buscarAmparo() {
    return this.amparos.find(amparo => amparo.codigo === this.licitacao.amparo_legal).lei
    // return (await this.amparoLegalService.buscarAmparoPorCodigo(this.licitacao.amparo_legal)).lei;
  }

  atualizarSituacao(situacao: number) {
    this.confirmationService.confirm({
      header: 'Confirmar Atualização',
      message: `<pre>Deseja atualizar a situação do processo licitatorio ${this.licitacao.numero} para ${situacao} - ${(situacao == 1 ? 'Dibulgada' : situacao == 2 ? 'Revogada' : situacao == 3 ? 'Anulada' : 'Suspensa')}?<br/><br/>A situação do processo licitatório nos cadastros também será atualizada.</pre>`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, atualizar.',
      rejectLabel: 'Não',
      key: 'pncpLicitacao',
      accept: async () => {
        const rel = 'modalidade.tipos_contratacao.tipo_contratacao,orgao,setor,setor.unidade,exercicio,comissao,'
          + 'tipo_contratacao,fiscais.pessoa,licitacao_audesp,orgao_audesp,prazo_pagamento,prazo_entrega,usuario_remocao';
        const lic = await this.licitacaoService.obter({
          'orgao.id': this.licitacao.orgao.id, 'exercicio.id': this.licitacao.exercicio.id,
          relations: rel, 'id': this.licitacao.id
        }).toPromise();

        this.pncpService.atualizarSituacaoContratacao(this.licitacao.sequencial_pncp, situacao).subscribe((idTransacao) => {
          this.progressoService.show(idTransacao, async (retorno) => {
            if (retorno.error) {
              toastr.error(retorno.message);
            }
            this.licitacao.situacao_pncp = situacao;
            lic.situacao_pncp = situacao;
            switch (situacao) {
              case 1:
                lic.situacao = 'ATIVA';
                break;
              case 2:
                lic.situacao = 'REVOGADA';
                break;
              case 3:
                lic.situacao = 'ANULADA';
                break;
              case 4:
                lic.situacao = 'SUSPENSA';
                break;
              default:
                break;
            }
            await this.licitacaoService.atualizar(lic).toPromise();
            this.confirmationService.close();
          });
        }, e => {
          this.funcaoService.acaoErro(e);
        });
      }
    });
  }

  async calcularSituacaoItem() {
    this.listaItensEnvio = new Array<Memorial>();
    for (const i of this.licitacao.itens) {
      const inicial = i.situacao_pncp;
      const propostas: Array<Proposta> = await (await this.propostaService.obterPorMemorial(i.id).toPromise()).content;
      if (propostas.length == 0) {
        i.situacao_pncp = 4;//Se a lista não tiver propostas, é deserto.
      } else {
        i.situacao_pncp = 1;//Por padrão, o item ficará como Públicado
        for (const p of propostas) {
          if (p.situacao == 'VENCEDOR') {
            i.situacao_pncp = 2;
            break;
          } else if (p.situacao == 'FRACASSADO') {
            i.situacao_pncp = 5;//Se o item estiver fracassado, coloca como fracassado;
            break;
          }
          //  else if (false) {
          // i.situacao_pncp = 3;//Ainda não se tem situação de "cancelado" no nosso sistema, ver de desenvolver;
          // }
        }
      }
      if (inicial != i.situacao_pncp) this.listaItensEnvio.push(i);
    }
  }

  atualizarSituacaoItem(item: Memorial, situacao: number) {
    if (this.listaItensEnvio.find(i => i.id == item.id)) {
      this.listaItensEnvio.splice(this.listaItensEnvio.indexOf(item), 1);
    }
    item.situacao_pncp = situacao;
    this.listaItensEnvio.push(item);
  }

  enviarAtualizacoesItem() {
    const itens = [];
    let texto = '';
    for (const i of this.listaItensEnvio) {
      texto += '\n' + i.ordem + ' - ' + this.funcaoService.abreviarStr(i.descricao, 100, true)
        + ' para ' + (i.situacao_pncp == 1 ? 'Em andamento' : i.situacao_pncp == 2 ? 'Homologado'
          : i.situacao_pncp == 3 ? 'Cancelado' : i.situacao_pncp == 4 ? 'Deserto' : 'Fracassado');
      itens.push({ id: i.id, item: i.ordem, situacao: i.situacao_pncp });
    }

    this.confirmationService.confirm({
      header: 'Confirmar Atualização',
      message: `<pre>Deseja atualizar a situação dos itens do processo licitatorio ${this.licitacao.numero}? Serão enviado as seguitnes informações: ${texto}</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, atualizar.',
      rejectLabel: 'Não',
      key: 'pncpLicitacao',
      accept: async () => {
        this.pncpService.atualizarSituacaoItensContratacao(this.licitacao.sequencial_pncp, itens).subscribe((idTransacao) => {
          this.progressoService.show(idTransacao, (retorno) => {
            this.resultados = [];
            for (const i of retorno) {
              this.resultados.push({ licitacao: i.item, mensagem: i.mensagem, sucesso: !i.error });
            }

            this.resultados.forEach(i => {
              if (i.sucesso) {
                this.listaItensEnvio.splice(this.listaItensEnvio.indexOf(this.listaItensEnvio.find(ii => +ii.ordem == +i.licitacao)), 1);
              }
            });

          });
        }, e => {
          this.funcaoService.acaoErro(e);
        });
      }
    });
  }

  adicionarResultadosAutomatico(ratificacao: boolean) {
    this.listaEnvioResultados = [];
    for (const item of this.licitacao.itens) {
      for (const proposta of item.propostas) {
        if (proposta.situacao == 'VENCEDOR') {
          if (!ratificacao && !proposta.sequencial_pncp) {
            //Se não estiver ratificando, e sem sequencial adiciona
            this.adicionarResultadoEnvio(proposta, false);
          } else if (ratificacao && proposta.sequencial_pncp) {
            //Se estiver ratificando, e já tiver sequencial, adiciona
            this.adicionarResultadoEnvio(proposta, true);
          } else {
            //Em outros cenários, remove do envio;
            proposta['enviando'] = null;
            proposta['ratificacao'] = null;
            if (proposta.data_cancelamento) {
              proposta.data_cancelamento = null;
              proposta.motivo_cancelamento = null;
            }
          }
        }
      }
    }
  }

  adicionarResultadoEnvio(item: Proposta, ratificacao: boolean) {
    item['enviando'] = true;
    if (ratificacao) {
      item['ratificacao'] = true
    }
    this.listaEnvioResultados.push(item);
  }

  cancelarResultado(item: Proposta) {
    this.propostaCancelando = item;
    $('#dlgCancelarResultado').modal('show');
  }

  removerEnvioResultado(item: Proposta) {
    this.listaEnvioResultados.splice(this.listaEnvioResultados.indexOf(item), 1);
    item['enviando'] = null;
    item['ratificacao'] = null;
    if (item.data_cancelamento) {
      item.data_cancelamento = null;
      item.motivo_cancelamento = null;
    }
  }

  enviarResultados() {
    let texto = '';
    const envios = [];
    for (const i of this.licitacao.itens) {
      const aux = Object.assign({}, i);
      aux.propostas = [];
      for (const p of i.propostas) {
        if (p['enviando']) {
          if (!p.data_homologacao) {
            toastr.warning('Item a ser enviado está sem data de homologação');
            return;
          }
          if (p['ratificacao']) {
            p.data_cancelamento = null;
            p.motivo_cancelamento = null;
          }
          p.memorial = aux;
          texto += ` - Proposta para o item ${p.memorial.produto_unidade.produto.nome}\ndo favorecido ${p.proponente.favorecido.nome} por ${p.valor_final} a unidade. ${p.data_cancelamento ? ' CANCELANDO RESULTADO' : p.sequencial_pncp ? ' RETIFICANDO RESULTADO' : ' CADASTRANDO RESULTADO'}\n\n`;
          envios.push(p);
        }
      }
    }

    if (texto == '') {
      toastr.info('Nada para ser enviado');
      return;
    }
    this.confirmationService.confirm({
      header: 'Confirmar Exclusão',
      message: `<pre>Deseja efetivar as modificações solicitadas? Será efetivado:\n\n${texto}</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmar envio.',
      rejectLabel: 'Não',
      key: 'pncpLicitacao',
      accept: async () => {
        this.pncpService.enviarResultado(this.licitacao.id, envios).subscribe((idTransacao) => {
          this.progressoService.show(idTransacao, async (retorno) => {
            this.resultados = retorno;
            this.listaEnvioResultados = [];
            this.licitacao = await this.licitacaoService.obter({ id: this.licitacao.id, relations: this.relationsLicitacao }).toPromise();
          });
        }, e => {
          this.funcaoService.acaoErro(e);
        });
      }
    });
  }

  private calculaInstrumento(lic: Licitacao): number {
    if (lic.modo_disputa == 4) {
      return 2;
    } else if ((lic.modalidade.codigo_pncp == 8 || lic.modalidade.codigo_pncp == 9) && lic.modo_disputa != 4) {
      //Prioridade 2
      //Quando é Dispensa de licitação ou Inexigibilidade, entra como Ato que autoriza contratação direta, código PNCP 3
      return 3;
    } else if (lic.chamamento_publico) {
      //Prioridade 3
      //Quando é selecionado chamamento público vai entrar como chamamento público código pncp 4
      return 4;
    } else if (lic.modalidade.codigo_pncp == 13 || (lic.modalidade.codigo_pncp >= 1 && lic.modalidade.codigo_pncp <= 7)) {
      return 1;
    } else {
      return -1;
    }
  }

  enviarArquivoConfirma(arquivo: LicitacaoStorage) {
    if (arquivo.documento_pncp) {
      const instrumento = this.calculaInstrumento(this.licitacao);
      arquivo.tipo_documento = (instrumento == 1 ? 2 : instrumento == 2 ? 1 : instrumento == 3 ? 20 : 2);
    }

    this.confirmationService.confirm({
      header: 'Confirmar Envio',
      message: `<pre>Deseja realmente enviar este arquivo? ${arquivo.nome}? Como ${this.tipoDocumento(arquivo.documento_pncp ? -1 : arquivo.tipo_documento)}?</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, enviar.',
      rejectLabel: 'Não',
      key: 'pncpLicitacao',
      accept: async () => {
        this.enviarArquivo(arquivo);
      }
    });
  }

  apagarArquivoConfirma(arquivo: LicitacaoStorage) {
    this.confirmationService.confirm({
      header: 'Confirmar Remoção',
      message: `<pre>Confirma a remoção do documento ${arquivo.nome} do PNCP? Como ${this.tipoDocumento(arquivo.documento_pncp ? -1 : arquivo.tipo_documento)}?</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmar remoção.',
      rejectLabel: 'Não',
      key: 'pncpLicitacao',
      accept: async () => {
        this.apagarArquivo(arquivo);
      }
    });
  }

  public tipoDocumento(cod: number): string {
    switch (cod) {
      case -1: return 'Doc. Processo Licitatório';
      case 3: return 'Minuta do Contrato';
      case 4: return 'Termo de Referência';
      case 5: return 'Anteprojeto';
      case 6: return 'Projeto Básico';
      case 7: return 'Estudo Técnico Preliminar';
      case 8: return 'Projeto Executivo';
      case 9: return 'Mapa de Riscos';
      case 10: return 'DFD';
      default: return 'inválido'
    }
  }

  enviarTodosArquivos() {
    this.confirmationService.confirm({
      header: 'Confirmar Envios',
      message: `<pre>Confirma o envio de todos arquivos ainda não enviados ao PNCP?.</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmar envio de arquivos.',
      rejectLabel: 'Não',
      key: 'pncpLicitacao',
      accept: async () => {
        for (const item of this.licitacao.arquivos) {
          if (item.sequencial == null) this.enviarArquivo(item);
        }
      }
    });
  }

  apagarTodosArquivos() {
    this.confirmationService.confirm({
      header: 'Confirmar Envios',
      message: `<pre>Confirma a exclusão de todos arquivos ainda não enviados ao PNCP?. O arquivo principal NÃO será apagado.</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmar envio de arquivos.',
      rejectLabel: 'Não',
      key: 'pncpLicitacao',
      accept: async () => {
        for (const item of this.licitacao.arquivos) {
          if (item.sequencial != null && !item.documento_pncp) this.apagarArquivo(item);
        }
      }
    });
  }

  enviarArquivo(arquivo: LicitacaoStorage) {
    arquivo.licitacao = new Licitacao();
    arquivo.licitacao.sequencial_pncp = this.licitacao.sequencial_pncp;
    this.pncpService.enviarArquivoLicitacao(arquivo, this.licitacao.exercicio).subscribe((idTransacao) => {
      this.progressoService.show(idTransacao, async (retorno) => {
        const a = await this.storageService.obterId(arquivo.id).toPromise();
        arquivo.sequencial = a.sequencial;
        toastr.success('Documento enviado ao PNCP com sucesso!');
      });
    }, e => {
      this.funcaoService.acaoErro(e);
    });
  }

  apagarArquivo(arquivo: LicitacaoStorage) {
    this.pncpService.apagarArquivoLicitacao(this.licitacao.sequencial_pncp, this.licitacao.exercicio.ano, arquivo.sequencial).subscribe(async (res) => {
      arquivo.sequencial = null;
      const aux = Object.assign({}, this.licitacao);
      aux.arquivos = null;
      arquivo.licitacao = aux;
      await this.storageService.atualizar(arquivo).toPromise();
      toastr.success('Documento removido do PNCP com sucesso!');
    }, e => {
      toastr.error(e.error.message);
    });
  }

  async buscarHistoricoPNCP() {
    this.historicoPNCP = await this.pncpService.consultarHistoricoCompra(this.login.orgao.cnpj.replace(/[./-]/g, ''), this.licitacao.exercicio.ano, this.licitacao.sequencial_pncp).toPromise();
  }

  public excluirLicitacao() {
    this.confirmationService.confirm({
      header: 'Confirmar Exclusão',
      message: `<pre>Confirma a exclusão dessa licitação do PNCP?\n Essa ação não pode ser revertida e todos dados serão perdidos.</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmar remoção.',
      rejectLabel: 'Não',
      accept: async () => {
        this.pncpService.apagarContratacao(this.licitacao).subscribe(async (res) => {
          toastr.success('Licitação excluida com sucesso do PNCP')
          this.licitacao = null;
          this.voltar.emit();
        }, e => {
          this.funcaoService.acaoErro(e);
        })
      }
    });
  }

  fecharResultado() {
    if (this.propostaCancelando.motivo_cancelamento == '' || !this.propostaCancelando.motivo_cancelamento) {
      this.propostaCancelando.motivo_cancelamento = null;
      this.propostaCancelando.data_cancelamento = null;
    } else {
      this.propostaCancelando.data_cancelamento = new Date();
    }

    this.propostaCancelando['enviando'] = true;
    this.listaEnvioResultados.push(this.propostaCancelando);
    for (const i of this.licitacao.itens) {
      const antigo = i.propostas.find(p => p.id == this.propostaCancelando.id);
      if (antigo) {
        i.propostas[i.propostas.indexOf(antigo)] = this.propostaCancelando;
      }
    }
    this.propostaCancelando = new Proposta();
    $('#dlgCancelarResultado').modal('hide');
  }

  idPncp(): string {
    return `${this.login.orgao.cnpj.replace(/\D/g, '')}-1-${("000000" + this.licitacao.sequencial_pncp).slice((this.licitacao.sequencial_pncp + '').length)}/${this.licitacao.exercicio.ano}`;
  }

  public abrirPncp() {
    const ano = this.licitacao.exercicio.ano;
    const sequencial_pncp = this.licitacao.sequencial_pncp;

    window.open(this.pncpService.abrirPortalPNCP(this.licitacao.orgao.cnpj, ano, sequencial_pncp, 'E'), '_blank');
  }

}
