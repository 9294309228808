import { Directive, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { QuadroSaudeService } from '../service/quadro-saude.service';
import {
  FuncaoService, GlobalService, Login,
  Exercicio, FuncaoGoverno, Relatorio, Coluna, FormatoExportacao,
  OrgaoAssinatura,
  OrgaoAssinaturaService
} from 'eddydata-lib';

@Directive()
export class Quadro5Saude implements OnDestroy {
  private assinatura: OrgaoAssinatura;
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected quadroServico: QuadroSaudeService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected assinaturaService: OrgaoAssinaturaService,
    protected funcao?: FuncaoGoverno) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async listar(orgaos: number[]) {
    return await this.quadroServico
      .obterQuadro5(this.mes, this.exercicio.id, orgaos).toPromise();
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
    this.quadroServico.obterQuadro5(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(
            'QUADRO 5 - RESUMO DA APLICAÇÃO EM SAÚDE - RECURSOS PRÓPRIOS'
            , this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            this.conteudo(dados).concat(this.conteudoDespesa(dados))
              .concat(this.conteudoDeducao1(dados)).concat(this.login.parametro?.contabil?.assinatura_por_funcao ? await this.conteudoAssinatura() : ''),
            'portrait', 'QUADRO 5 - RESUMO DA APLICAÇÃO EM SAÚDE - RECURSOS PRÓPRIOS',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            });
        } else {
          const conteudo = this.conteudoExportacao(dados)
            .concat(this.conteudoDespesaExportacao(dados))
            .concat(this.conteudoDeducao1Exportacao(dados));
          this.funcaoService.exportar(formato, conteudo, 'QUADRO 5 - RESUMO DA APLICAÇÃO EM SAÚDE - RECURSOS PRÓPRIOS', this.colunasRelatorio());
        }
      });
  }

  private conteudo(dados: any[]): {}[] {

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `REFERÊNCIA: ${this.mes ? this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano : this.exercicio.ano}`,
        alignment: 'center',
        margin: [5, 5, 5, 5],
        fontSize: 11,
        bold: true,
        colSpan: 3
      }, '', ''],
      [{
        text: 'RECEITAS',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'PREVISÃO NO EXERCÍCIO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'ARRECADAÇÃO ATÉ O PERÍODO',
        alignment: 'center',
        bold: true, fontSize: 6
      }]
    ];

    // monta o agrupamento do relatório
    const dado = dados[0];
    registros.push([
      {
        text: 'RECEITA DE IMPOSTOS E TRANSFERÊNCIAS DE IMPOSTOS',
        alignment: 'left', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.f9),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.g9),
        alignment: 'right', fontSize: 7
      }
    ]);
    registros.push([
      {
        text: 'VALOR MÍNIMO A APLICAR (15%)',
        alignment: 'left', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.f10),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.g10),
        alignment: 'right', fontSize: 7
      }
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: [180, 80, 80],
        body: registros
      }
    }];
  }

  public conteudoDespesa(lista: any) {
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: '',
        border: [false, false, false, false],
        colSpan: 5,
        margin: [5, 5, 5, 5]
      }, '', '', '', '']
    ];
    registros.push([{
      text: '\nAPURAÇÃO DO PERCENTUAL APLICADO NA SAÚDE',
      alignment: 'center', fontSize: 7, rowSpan: 2,
      bold: true
    }, {
      text: '\nDOTAÇÃO ATUALIZADA PARA O EXERCÍCIO',
      alignment: 'center', fontSize: 7,
      bold: true, rowSpan: 2
    }, {
      text: 'ATÉ O PERÍODO',
      alignment: 'center', fontSize: 7,
      bold: true,
      colSpan: 3,
    }, '', ''],
      [{
        text: '',
        alignment: 'center',
        bold: true, fontSize: 6,
      }, '', {
        text: 'EMPENHADA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'LIQUIDADA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'PAGA',
        alignment: 'center',
        bold: true, fontSize: 6
      }]);
    for (const item of lista[1]) {
      total1 += +item.dotacao;
      total2 += +item.empenhado;
      total3 += +item.liquidado;
      total4 += +item.pago;
    }

    for (const item of lista[1]) {
      registros.push([
        {
          text: item.tipo, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.dotacao),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.empenhado),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.liquidado),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.pago),
          alignment: 'right', fontSize: 7
        }
      ]);
    }
    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', 80, 60, 60, 60],
        body: registros
      }
    }];
  }

  public conteudoDeducao1(lista: any) {
    let totalE20 = 0;
    let totalF20 = 0;
    let totalG20 = 0;
    let totalH20 = 0;
    const registros: {}[] = [];

    for (const item of lista[2]) {
      registros.push([
        {
          text: item.tipo, fontSize: 7, border: [true, false, true, true]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.orcado),
          alignment: 'right', fontSize: 7, border: [true, false, true, true]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.empenhado),
          alignment: 'right', fontSize: 7, border: [true, false, true, true]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.liquidado),
          alignment: 'right', fontSize: 7, border: [true, false, true, true]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.pago),
          alignment: 'right', fontSize: 7, border: [true, false, true, true]
        }
      ]);
      totalE20 += +item.dotacao;
      totalF20 += +item.empenhado;
      totalG20 += +item.liquidado;
      totalH20 += +item.pago;
    }
    registros.push([
      {
        text: 'TOTAL DAS DEDUÇÕES', fontSize: 7, border: [true, false, true, true], bold: true
      },
      {
        text: this.funcaoService.convertToBrNumber(totalE20),
        alignment: 'right', fontSize: 7, border: [true, false, true, true], bold: true
      },
      {
        text: this.funcaoService.convertToBrNumber(totalF20),
        alignment: 'right', fontSize: 7, border: [true, false, true, true], bold: true
      },
      {
        text: this.funcaoService.convertToBrNumber(totalG20),
        alignment: 'right', fontSize: 7, border: [true, false, true, true], bold: true
      },
      {
        text: this.funcaoService.convertToBrNumber(totalH20),
        alignment: 'right', fontSize: 7, border: [true, false, true, true], bold: true
      }
    ]);

    // TOTAL DAS DESPESAS COM RECURSOS PRÓPRIOS
    let totalE16 = 0;
    let totalF16 = 0;
    let totalG16 = 0;
    let totalH16 = 0;
    for (const item of lista[1]) {
      totalE16 += +item.dotacao;
      totalF16 += +item.empenhado;
      totalG16 += +item.liquidado;
      totalH16 += +item.pago;
    }

    registros.push([
      {
        text: 'DESPESAS LÍQUIDAS DA SAÚDE', fontSize: 7, border: [true, false, true, true], bold: true
      },
      {
        text: this.funcaoService.convertToBrNumber(totalE16 - totalE20),
        alignment: 'right', fontSize: 7, border: [true, false, true, true], bold: true
      },
      {
        text: this.funcaoService.convertToBrNumber(totalF16 - totalF20),
        alignment: 'right', fontSize: 7, border: [true, false, true, true], bold: true
      },
      {
        text: this.funcaoService.convertToBrNumber(totalG16 - totalG20),
        alignment: 'right', fontSize: 7, border: [true, false, true, true], bold: true
      },
      {
        text: this.funcaoService.convertToBrNumber(totalH16 - totalH20),
        alignment: 'right', fontSize: 7, border: [true, false, true, true], bold: true
      }
    ]);
    const dado = lista[0];
    registros.push([{
      text: '',
      border: [false, false, false, false],
      colSpan: 5,
      margin: [5, 5, 5, 5]
    }, '', '', '', '']);

    registros.push([
      {
        text: 'PERCENTUAL DE APLICAÇÃO', fontSize: 7, bold: true
      },
      {
        text: this.funcaoService.convertToBrNumber(((totalE16 - totalE20) / dado.f9) * 100),
        alignment: 'right', fontSize: 7, bold: true
      },
      {
        text: this.funcaoService.convertToBrNumber(((totalF16 - totalF20) / dado.g9) * 100),
        alignment: 'right', fontSize: 7, bold: true
      },
      {
        text: this.funcaoService.convertToBrNumber(((totalG16 - totalG20) / dado.g9) * 100),
        alignment: 'right', fontSize: 7, bold: true
      },
      {
        text: this.funcaoService.convertToBrNumber(((totalH16 - totalH20) / dado.g9) * 100),
        alignment: 'right', fontSize: 7, bold: true
      }
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', 80, 60, 60, 60],
        body: registros
      }
    }];
  }

  public async conteudoAssinatura() {
    this.assinatura = await this.assinaturaService.obter({ orgao_id: this.login.orgao.id }).toPromise();

    const assinatura = [
      [
        {
          text: '', alignment: 'center',
          border: [false, false, false, false], margin: [0, 30, 0, 0]
        },
        {
          text: this.assinatura.ordenador_despesa_funcao_10 ? '__________________________________________' : '', alignment: 'center',
          border: [false, false, false, false], margin: [0, 30, 0, 0]
        },
        {
          text: '', alignment: 'center',
          border: [false, false, false, false], margin: [0, 30, 0, 0]
        }
      ],
      [
        { text: '', alignment: 'center', border: [false, false, false, false] },
        { text: this.assinatura.ordenador_despesa_funcao_10 ? this.assinatura.ordenador_despesa_funcao_10 : '', alignment: 'center', border: [false, false, false, false] },
        { text: '', alignment: 'center', border: [false, false, false, false] }
      ],
      [
        { text: '', alignment: 'center', border: [false, false, false, false] },
        { text: this.assinatura.cargo_ordenador_despesa_funcao_10 ? this.assinatura.cargo_ordenador_despesa_funcao_10 : '', alignment: 'center', border: [false, false, false, false] },
        { text: '', alignment: 'center', border: [false, false, false, false] }
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [10, 0],
        widths: ['*', '*', '*'],
        body: assinatura
      }
    }];

  }

  public conteudoDespesaLiquida(lista: any) {
    // dados do cabeçalho
    const dado = lista[0];

    // lista de despesas totais
    const itemDespesa = lista[1];
    const despesaMagisterio1 = +itemDespesa[0].empenhado;
    const despesaMagisterio2 = +itemDespesa[0].liquidado;
    const despesaMagisterio3 = +itemDespesa[0].pago;

    const despesaOutras1 = +itemDespesa[1].empenhado;
    const despesaOutras2 = +itemDespesa[1].liquidado;
    const despesaOutras3 = +itemDespesa[1].pago;

    // lista de deducoes magisterio
    let deducaoMagisterio1 = 0;
    let deducaoMagisterio2 = 0;
    let deducaoMagisterio3 = 0;

    for (const item of lista[2]) {
      deducaoMagisterio1 += +item.empenhado;
      deducaoMagisterio2 += +item.liquidado;
      deducaoMagisterio3 += +item.pago;
    }

    // lista de deduções outras
    let deducaoOutras1 = 0;
    let deducaoOutras2 = 0;
    let deducaoOutras3 = 0;

    for (const item of lista[3]) {
      deducaoOutras1 += +item.empenhado;
      deducaoOutras2 += +item.liquidado;
      deducaoOutras3 += +item.pago;
    }

    const total1 = +despesaMagisterio1 + +despesaOutras1 + +deducaoMagisterio1 + +deducaoOutras1;
    const total2 = +despesaMagisterio2 + +despesaOutras2 + +deducaoMagisterio2 + +deducaoOutras2;
    const total3 = +despesaMagisterio3 + +despesaOutras3 + +deducaoMagisterio3 + +deducaoOutras3;

    // // monta o cabecalho
    const registros: {}[] = [
      [{
        text: 'DESPESAS LÍQUIDAS',
        alignment: 'center',
        bold: true,
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
        colSpan: 7
      }, '', '', '', '', '', ''],
      [{
        text: 'TOTAL',
        alignment: 'left',
        bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total1),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber((total1 / dado.d11) * 100),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total2),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber((total2 / dado.d11) * 100),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total3),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber((total3 / dado.d11) * 100),
        alignment: 'right', bold: true, fontSize: 7
      }]
    ];

    registros.push([
      {
        text: 'MAGISTÉRIO', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+despesaMagisterio1 + +deducaoMagisterio1),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+despesaMagisterio1 + +deducaoMagisterio1) / dado.d11) * 100),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+despesaMagisterio2 + +deducaoMagisterio2),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+despesaMagisterio2 + +deducaoMagisterio2) / dado.d11) * 100),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+despesaMagisterio3 + +deducaoMagisterio3),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+despesaMagisterio3 + +deducaoMagisterio3) / dado.d11) * 100),
        alignment: 'right', fontSize: 7
      },
    ]);

    registros.push([
      {
        text: 'OUTRAS', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+despesaOutras1 + +deducaoOutras1),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+despesaOutras1 + +deducaoOutras1) / dado.d11) * 100),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+despesaOutras2 + +deducaoOutras2),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+despesaOutras2 + +deducaoOutras2) / dado.d11) * 100),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+despesaOutras3 + +deducaoOutras3),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+despesaOutras3 + +deducaoOutras3) / dado.d11) * 100),
        alignment: 'right', fontSize: 7
      },
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: [216, 60, 30, 60, 30, 60, 30],
        body: registros
      }
    }];
  }

  private colunasRelatorio(): Coluna[] {
    return [
      { titulo: 'RECEITA', coluna: 'receita_apuracao' },
      { titulo: 'PREVISÃO NO EXERCÍCIO', coluna: 'previsao_dotacao' },
      { titulo: 'ARRECADAÇÃO ATÉ O PERÍODO', coluna: 'arrecadacao_empenhada' },
      { titulo: '', coluna: 'liquidada' },
      { titulo: '', coluna: 'paga' },
    ];
  }

  private conteudoExportacao(dados: any[]) {
    const listaItens = new Array();
    const dado = dados[0];
    listaItens.push({
      'receita_apuracao': 'RECEITA DE IMPOSTOS E TRANSFERÊNCIAS DE IMPOSTOS',
      'previsao_dotacao': this.funcaoService.convertToBrNumber(dado.f9, 2),
      'arrecadacao_empenhada': this.funcaoService.convertToBrNumber(dado.g9, 2),
    });
    listaItens.push({
      'receita_apuracao': 'VALOR MÍNIMO A APLICAR (15%)',
      'previsao_dotacao': this.funcaoService.convertToBrNumber(dado.f10, 2),
      'arrecadacao_empenhada': this.funcaoService.convertToBrNumber(dado.g10, 2),
    });

    return listaItens;
  }

  public conteudoDespesaExportacao(lista: any) {
    const listaItens = new Array();
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    for (const item of lista[1]) {
      total1 += +item.dotacao;
      total2 += +item.empenhado;
      total3 += +item.liquidado;
      total4 += +item.pago;
    }

    listaItens.push({
      'receita_apuracao': ''
    });
    listaItens.push({
      'receita_apuracao': 'APURAÇÃO DO PERCENTUAL APLICADO NA SAÚDE',
      'previsao_dotacao': 'DOTAÇÃO ATUALIZADA PARA O EXERCÍCIO',
      'arrecadacao_empenhada': 'EMPENHADA',
      'liquidada': 'LIQUIDADA',
      'paga': 'PAGA',
    });

    for (const item of lista[1]) {
      listaItens.push({
        'receita_apuracao': item.tipo,
        'previsao_dotacao': this.funcaoService.convertToBrNumber(item.dotacao, 2),
        'arrecadacao_empenhada': this.funcaoService.convertToBrNumber(item.empenhado, 2),
        'liquidada': this.funcaoService.convertToBrNumber(item.liquidado, 2),
        'paga': this.funcaoService.convertToBrNumber(item.pago, 2),
      });
    }

    return listaItens;
  }

  public conteudoDeducao1Exportacao(lista: any) {
    const listaItens = new Array();
    let totalE20 = 0;
    let totalF20 = 0;
    let totalG20 = 0;
    let totalH20 = 0;

    for (const item of lista[2]) {
      listaItens.push({
        'receita_apuracao': item.tipo,
        'previsao_dotacao': this.funcaoService.convertToBrNumber(item.orcado, 2),
        'arrecadacao_empenhada': this.funcaoService.convertToBrNumber(item.empenhado, 2),
        'liquidada': this.funcaoService.convertToBrNumber(item.liquidado, 2),
        'paga': this.funcaoService.convertToBrNumber(item.pago, 2),
      });
      totalE20 += +item.dotacao;
      totalF20 += +item.empenhado;
      totalG20 += +item.liquidado;
      totalH20 += +item.pago;
    }
    listaItens.push({
      'receita_apuracao': 'TOTAL DAS DEDUÇÕES',
      'previsao_dotacao': this.funcaoService.convertToBrNumber(totalE20, 2),
      'arrecadacao_empenhada': this.funcaoService.convertToBrNumber(totalF20, 2),
      'liquidada': this.funcaoService.convertToBrNumber(totalG20, 2),
      'paga': this.funcaoService.convertToBrNumber(totalH20, 2),
    });

    // TOTAL DAS DESPESAS COM RECURSOS PRÓPRIOS
    let totalE16 = 0;
    let totalF16 = 0;
    let totalG16 = 0;
    let totalH16 = 0;
    for (const item of lista[1]) {
      totalE16 += +item.dotacao;
      totalF16 += +item.empenhado;
      totalG16 += +item.liquidado;
      totalH16 += +item.pago;
    }

    listaItens.push({
      'receita_apuracao': 'DESPESAS LÍQUIDAS DA SAÚDE',
      'previsao_dotacao': this.funcaoService.convertToBrNumber(totalE16 - totalE20, 2),
      'arrecadacao_empenhada': this.funcaoService.convertToBrNumber(totalF16 - totalF20, 2),
      'liquidada': this.funcaoService.convertToBrNumber(totalG16 - totalG20, 2),
      'paga': this.funcaoService.convertToBrNumber(totalH16 - totalH20, 2),
    });
    const dado = lista[0];
    listaItens.push({
      'receita_apuracao': ''
    });

    listaItens.push({
      'receita_apuracao': 'PERCENTUAL DE APLICAÇÃO',
      'previsao_dotacao': this.funcaoService.convertToBrNumber(((totalE16 - totalE20) / dado.f9) * 100, 2),
      'arrecadacao_empenhada': this.funcaoService.convertToBrNumber(((totalF16 - totalF20) / dado.g9) * 100, 2),
      'liquidada': this.funcaoService.convertToBrNumber(((totalG16 - totalG20) / dado.g9) * 100, 2),
      'paga': this.funcaoService.convertToBrNumber(((totalH16 - totalH20) / dado.g9) * 100, 2),
    });

    return listaItens;
  }

}
