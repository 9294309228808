import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../../assinaturas/assinaturas';
import { AnexoLrfFederalService } from '../../../service/anexo-lrf-federal.service';

@Directive()
export class Anexo4RgfLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoFederalServico: AnexoLrfFederalService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected assinaturaControleInterno: boolean) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
    this.anexoFederalServico.obterAnexo4Rgf(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          const listaConteudo = await this.conteudo(dados, orgaos);
          Relatorio.imprimirPersonalizado(null, this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            listaConteudo,
            'portrait', 'ANEXO4 - DEMONSTRATIVO DAS OPERAÇÕES DE CRÉDITO',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 2;
                },
                paddingRight() {
                  return 2;
                }
              }
            }, true, false);
        } else {
          this.funcaoService.exportar(formato, await this.normalizar(dados, orgaos), 'ANEXO4 - DEMONSTRATIVO DAS OPERAÇÕES DE CRÉDITO', this.colunas());
        }
      });
  }

  private async conteudo(dados: any[], orgaos: any): Promise<{}[]> {
    // monta o cabecalho

    const registros: {}[] = [
      [
        { text: this.login.orgao.nome, bold: true, border: [false, false, false, false], colSpan: 3, fontSize: 10 },
        '', ''
      ],

      [
        { text: 'RELATÓRIO DE GESTÃO FISCAL', bold: true, border: [false, false, false, false], colSpan: 3 },
        '', ''],
      [
        { text: 'DEMONSTRATIVO DAS OPERAÇÕES DE CRÉDITO', bold: true, border: [false, false, false, false], colSpan: 3 },
        '', ''],
      [
        { text: 'ORÇAMENTOS FISCAL E DA SEGURIDADE SOCIAL', bold: true, border: [false, false, false, false], colSpan: 3 },
        '', ''],
      [
        { text: `REFERÊNCIA: ${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano}`, bold: true, border: [false, false, false, false], colSpan: 3 },
        '', ''],
      [
        { text: 'RGF - ANEXO 4 (LRF, art. 55, inciso I, alínea "d" e inciso III alínea "c")', bold: true, border: [false, false, false, false], colSpan: 3 },
        '', ''
      ],
      [
        { text: 'OPERAÇÕES DE CRÉDITO', rowSpan: 2, alignment: 'center' },
        { text: `VALOR REALIZADO`, alignment: 'center', bold: true, fontSize: 8, colSpan: 2 },
        { text: '' }
      ],
      [
        { text: '' },
        { text: 'No Quadrimestre de Referência', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'Até o Quadrimestre de Referência', alignment: 'center', bold: true, fontSize: 8 },
      ]

    ];

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);

    // monta o agrupamento do relatório
    const titulos = this.funcaoService.agrupar(dados[0], 'titulo', ['quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
    let total1 = 0;
    let total2 = 0;

    let total1I = 0;
    let total2I = 0;

    let total1II = 0;
    let total2II = 0;


    for (const titulo of titulos) {
      let noTrimestre = 0;
      let ateTrimestre = 0;

      registros.push([
        { text: titulo.grupo, fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(noTrimestre), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(ateTrimestre), alignment: 'right', fontSize: 8, bold: true },
      ]);

      this.somador(titulo, noTrimestre, ateTrimestre, total1, total2);

      if (titulo.grupo === 'Operações de crédito não sujeitas ao limite para fins de contratação1 (I)') {
        if (this.mes <= 4) {
          total1I += +titulo.totalizadores['quadrimestre1'];
          total2I += +titulo.totalizadores['quadrimestre2'];
        } else if (this.mes >= 5 && this.mes <= 8) {
          total1I += +titulo.totalizadores['quadrimestre3'];
          total2I += +titulo.totalizadores['quadrimestre4'];
        } else if (this.mes >= 5 && this.mes <= 8) {
          total1I += +titulo.totalizadores['quadrimestre3'];
          total2I += 0;
        }
      } else if (titulo.grupo === 'Operações de crédito não sujeitas ao limite para fins de contratação1 (II)') {
        if (this.mes <= 4) {
          total1II += +titulo.totalizadores['quadrimestre1'];
          total2II += +titulo.totalizadores['quadrimestre2'];
        } else if (this.mes >= 5 && this.mes <= 8) {
          total1II += +titulo.totalizadores['quadrimestre3'];
          total2II += +titulo.totalizadores['quadrimestre4'];
        } else if (this.mes >= 5 && this.mes <= 8) {
          total1II += +titulo.totalizadores['quadrimestre3'];
          total2II += 0;
        }
      }

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          if (this.mes <= 4) {
            noTrimestre = +grupo.totalizadores['quadrimestre1'];
            ateTrimestre = +grupo.totalizadores['quadrimestre2'];
          } else if (this.mes >= 5 && this.mes <= 8) {
            noTrimestre = +grupo.totalizadores['quadrimestre3'];
            ateTrimestre = +grupo.totalizadores['quadrimestre4'];
          } else if (this.mes >= 5 && this.mes <= 8) {
            noTrimestre = +grupo.totalizadores['quadrimestre4'];
            ateTrimestre = 0;
          }
          registros.push([
            { text: grupo.grupo, fontSize: 8, margin: [10, 0, 0, 0] },
            { text: this.funcaoService.convertToBrNumber(noTrimestre), alignment: 'right', fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(ateTrimestre), alignment: 'right', fontSize: 8 },
          ]);

          for (const item of grupo.registros) {
            if (item.nome != '') {
              if (this.mes <= 4) {
                noTrimestre = +item.quadrimestre1;
                ateTrimestre = +item.quadrimestre2;
              } else if (this.mes >= 5 && this.mes <= 8) {
                noTrimestre = +item.quadrimestre3;
                ateTrimestre = +item.quadrimestre4;
              } else if (this.mes >= 5 && this.mes <= 8) {
                noTrimestre = +item.quadrimestre4;
                ateTrimestre = 0;
              }
              registros.push([
                { text: item.nome, fontSize: 8, margin: [15, 0, 0, 0] },
                { text: this.funcaoService.convertToBrNumber(noTrimestre), alignment: 'right', fontSize: 8 },
                { text: this.funcaoService.convertToBrNumber(ateTrimestre), alignment: 'right', fontSize: 8 },
              ]);
            }
          }

        }
      }
    }

    registros.push([
      { text: 'TOTAL (III)', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total1), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total2), alignment: 'right', fontSize: 8, bold: true },
    ]);


    registros.push(
      [
        { text: 'APURAÇÃO DO CUMPRIMENTO DOS LIMITES', alignment: 'center' },
        { text: `VALOR`, alignment: 'center', bold: true, fontSize: 8 },
        { text: `% SOBRE ARCL AJUSTADA`, alignment: 'center', bold: true, fontSize: 8 }
      ],
    );

    let totalRCL = 0;
    let emendasIndividual = 0;
    let opVedadas = 0;
    let aro = 0;
    console.log(dados[2][0]);
    if (this.mes <= 4) {
      totalRCL = await this.anexoFederalServico.obterAnexo3Resuldado(4, this.exercicio.id, orgaos).toPromise();
      emendasIndividual = +dados[2][0].quadrimestre1;
      opVedadas = +dados[2][1].quadrimestre1;
      aro = +dados[2][2].quadrimestre1;
    }
    if (this.mes >= 5 && this.mes <= 8) {
      totalRCL = await this.anexoFederalServico.obterAnexo3Resuldado(8, this.exercicio.id, orgaos).toPromise();
      emendasIndividual = +dados[2][0].quadrimestre2;
      opVedadas = +dados[2][1].quadrimestre2;
      aro = +dados[2][2].quadrimestre2;
    }
    if (this.mes >= 9 && this.mes <= 12) {
      totalRCL = await this.anexoFederalServico.obterAnexo3Resuldado(12, this.exercicio.id, orgaos).toPromise();
      emendasIndividual = +dados[2][0].quadrimestre3;
      opVedadas = +dados[2][1].quadrimestre3;
      aro = +dados[2][2].quadrimestre3;
    }

    registros.push([
      {
        text: 'RECEITA CORRENTE LÍQUIDA - RCL (IV)', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(totalRCL), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
    ]);


    registros.push([
      {
        text: '(-) Transferências obrigatórias da União relativas às emendas individuais  (§ 1º, art. 166-A da CF)  (V)', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(emendasIndividual), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true }
    ]);

    registros.push([
      {
        text: 'RECEITA CORRENTE LÍQUIDA AJUSTADA PARA CÁLCULO DOS LIMITES DE ENDIVIDAMENTO (VI) = (IV - V)', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(totalRCL), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
    ]);

    registros.push([
      {
        text: 'OPERAÇÕES VEDADAS (VII)', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(opVedadas), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
    ]);

    registros.push([
      {
        text: 'TOTAL CONSIDERADO PARA FINS DA APURAÇÃO DO CUMPRIMENTO DO LIMITE (VIII) = (IIIa + VII - Ia - IIa)', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(+total2 + +opVedadas - +total2I - +total2II), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
    ]);

    registros.push([
      {
        text: 'LIMITE GERAL DEFINIDO POR RESOLUÇÃO DO SENADO FEDERAL PARA AS OPERAÇÕES DE CRÉDITO INTERNAS E EXTERNAS', fontSize: 8
      },
      { text: '', alignment: 'right', fontSize: 8, bold: true },
      { text: '', alignment: 'right', fontSize: 8, bold: true },
    ]);

    registros.push([
      {
        text: 'LIMITE DE ALERTA (inciso III do §1º do art. 59 da LRF) - <%>', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
    ]);

    registros.push([
      {
        text: 'OPERAÇÕES DE CRÉDITO POR ANTECIPAÇÃO DA RECEITA ORÇAMENTÁRIA', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
    ]);

    registros.push([
      {
        text: 'LIMITE DEFINIDO POR RESOLUÇÃO DO SENADO FEDERAL PARA AS OPERAÇÕES DE CRÉDITO POR ANTECIPAÇÃO DA RECEITA ORÇAMENTÁRIA', fontSize: 8
      },
      { text: '', alignment: 'right', fontSize: 8, bold: true },
      { text: '', alignment: 'right', fontSize: 8, bold: true },
    ]);

    this.outrasOperacoes(dados[1], registros);


    return [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 80, 80],
          body: registros
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*'],
          body: assinaturas
        }
      }
    ];
  }

  private async somador(titulo: any, noTrimestre: number, ateTrimestre: number, valor1: number, valor2: number) {
    if (this.mes <= 4) {
      noTrimestre = +titulo.totalizadores['quadrimestre1'];
      ateTrimestre = +titulo.totalizadores['quadrimestre2'];
      valor1 += +titulo.totalizadores['quadrimestre1'];
      valor2 += +titulo.totalizadores['quadrimestre2'];
    } else if (this.mes >= 5 && this.mes <= 8) {
      noTrimestre = +titulo.totalizadores['quadrimestre3'];
      ateTrimestre = +titulo.totalizadores['quadrimestre4'];
      valor1 += +titulo.totalizadores['quadrimestre3'];
      valor2 += +titulo.totalizadores['quadrimestre4'];
    } else if (this.mes >= 5 && this.mes <= 8) {
      noTrimestre = +titulo.totalizadores['quadrimestre4'];
      ateTrimestre = 0;
      valor1 += +titulo.totalizadores['quadrimestre3'];
      valor2 += 0;
    }
  }

  private outrasOperacoes(dados: [], registros: any) {

    registros.push(
      [
        { text: 'OUTRAS OPERAÇÕES QUE INTEGRAM A DÍVIDA CONSOLIDADA', rowSpan: 2, alignment: 'center' },
        { text: `VALOR REALIZADO`, alignment: 'center', bold: true, fontSize: 8, colSpan: 2 },
        { text: '' }
      ],
      [
        { text: '' },
        { text: 'No Quadrimestre de Referência', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'Até o Quadrimestre de Referência', alignment: 'center', bold: true, fontSize: 8 },
      ]
    );
    // monta o agrupamento do relatório
    const titulos = this.funcaoService.agrupar(dados, 'titulo', ['quadrimestre1', 'quadrimestre2', 'quadrimestre3']);

    for (const titulo of titulos) {
      let noTrimestre = 0;
      let ateTrimestre = 0;
      if (this.mes <= 4) {
        noTrimestre = +titulo.totalizadores['quadrimestre1'];
        ateTrimestre = +titulo.totalizadores['quadrimestre2'];
      } else if (this.mes >= 5 && this.mes <= 8) {
        noTrimestre = +titulo.totalizadores['quadrimestre3'];
        ateTrimestre = +titulo.totalizadores['quadrimestre4'];
      } else if (this.mes >= 5 && this.mes <= 8) {
        noTrimestre = +titulo.totalizadores['quadrimestre4'];
        ateTrimestre = 0;
      }
      registros.push([
        { text: titulo.grupo, fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(noTrimestre), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(ateTrimestre), alignment: 'right', fontSize: 8, bold: true },
      ]);

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          if (this.mes <= 4) {
            noTrimestre = +grupo.totalizadores['quadrimestre1'];
            ateTrimestre = +grupo.totalizadores['quadrimestre2'];
          } else if (this.mes >= 5 && this.mes <= 8) {
            noTrimestre = +grupo.totalizadores['quadrimestre3'];
            ateTrimestre = +grupo.totalizadores['quadrimestre4'];
          } else if (this.mes >= 5 && this.mes <= 8) {
            noTrimestre = +grupo.totalizadores['quadrimestre4'];
            ateTrimestre = 0;
          }
          registros.push([
            { text: grupo.grupo, fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(noTrimestre), alignment: 'right', fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(ateTrimestre), alignment: 'right', fontSize: 8 },
          ]);

          for (const item of grupo.registros) {
            if (item.nome != '') {
              if (this.mes <= 4) {
                noTrimestre = +item.quadrimestre1;
                ateTrimestre = +item.quadrimestre2;
              } else if (this.mes >= 5 && this.mes <= 8) {
                noTrimestre = +item.quadrimestre3;
                ateTrimestre = +item.quadrimestre4;
              } else if (this.mes >= 5 && this.mes <= 8) {
                noTrimestre = +item.quadrimestre4;
                ateTrimestre = 0;
              }
              registros.push([
                { text: item.nome, fontSize: 8, margin: [10, 0, 0, 0] },
                { text: this.funcaoService.convertToBrNumber(noTrimestre), alignment: 'right', fontSize: 8 },
                { text: this.funcaoService.convertToBrNumber(ateTrimestre), alignment: 'right', fontSize: 8 },
              ]);
            }
          }

        }
      }
    }

  }

  public async normalizar(lista: any[], orgaos: any): Promise<{}[]> {
    const listaExportar = []

    const titulos = this.funcaoService.agrupar(lista[0], 'titulo', ['quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
    let total1 = 0;
    let total2 = 0;

    let total1I = 0;
    let total2I = 0;

    let total1II = 0;
    let total2II = 0;

    const linha_titulo = {
      titulo: '',
      no_trimestre: 'No Quadrimestre de Referência',
      ate_trimestre: 'Até o Quadrimestre de Referência'
    }
    listaExportar.push(linha_titulo)

    for (const titulo of titulos) {
      let noTrimestre = 0;
      let ateTrimestre = 0;

      const primeira_linha = {
        titulo: titulo.grupo,
        no_trimestre: this.funcaoService.convertToBrNumber(noTrimestre),
        ate_trimestre: this.funcaoService.convertToBrNumber(ateTrimestre)
      }
      listaExportar.push(primeira_linha)

      this.somador(titulo, noTrimestre, ateTrimestre, total1, total2);

      if (titulo.grupo === 'Operações de crédito não sujeitas ao limite para fins de contratação1 (I)') {
        if (this.mes <= 4) {
          total1I += +titulo.totalizadores['quadrimestre1'];
          total2I += +titulo.totalizadores['quadrimestre2'];
        } else if (this.mes >= 5 && this.mes <= 8) {
          total1I += +titulo.totalizadores['quadrimestre3'];
          total2I += +titulo.totalizadores['quadrimestre4'];
        } else if (this.mes >= 5 && this.mes <= 8) {
          total1I += +titulo.totalizadores['quadrimestre3'];
          total2I += 0;
        }
      } else if (titulo.grupo === 'Operações de crédito não sujeitas ao limite para fins de contratação1 (II)') {
        if (this.mes <= 4) {
          total1II += +titulo.totalizadores['quadrimestre1'];
          total2II += +titulo.totalizadores['quadrimestre2'];
        } else if (this.mes >= 5 && this.mes <= 8) {
          total1II += +titulo.totalizadores['quadrimestre3'];
          total2II += +titulo.totalizadores['quadrimestre4'];
        } else if (this.mes >= 5 && this.mes <= 8) {
          total1II += +titulo.totalizadores['quadrimestre3'];
          total2II += 0;
        }
      }

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          if (this.mes <= 4) {
            noTrimestre = +grupo.totalizadores['quadrimestre1'];
            ateTrimestre = +grupo.totalizadores['quadrimestre2'];
          } else if (this.mes >= 5 && this.mes <= 8) {
            noTrimestre = +grupo.totalizadores['quadrimestre3'];
            ateTrimestre = +grupo.totalizadores['quadrimestre4'];
          } else if (this.mes >= 5 && this.mes <= 8) {
            noTrimestre = +grupo.totalizadores['quadrimestre4'];
            ateTrimestre = 0;
          }
          const linha_grupo = {
            titulo: grupo.grupo,
            no_trimestre: this.funcaoService.convertToBrNumber(noTrimestre),
            ate_trimestre: this.funcaoService.convertToBrNumber(ateTrimestre)
          }
          listaExportar.push(linha_grupo)
        }

        for (const item of grupo.registros) {
          if (item.nome != '') {
            if (this.mes <= 4) {
              noTrimestre = +item.quadrimestre1;
              ateTrimestre = +item.quadrimestre2;
            } else if (this.mes >= 5 && this.mes <= 8) {
              noTrimestre = +item.quadrimestre3;
              ateTrimestre = +item.quadrimestre4;
            } else if (this.mes >= 5 && this.mes <= 8) {
              noTrimestre = +item.quadrimestre4;
              ateTrimestre = 0;
            }
            const linha_item_subgrupo = {
              titulo: item.nome,
              no_trimestre: this.funcaoService.convertToBrNumber(noTrimestre),
              ate_trimestre: this.funcaoService.convertToBrNumber(ateTrimestre)
            }
            listaExportar.push(linha_item_subgrupo)
          }
        }
      }
    }

    const linha_total_3 = {
      titulo: 'TOTAL (III)',
      no_trimestre: this.funcaoService.convertToBrNumber(+total1),
      ate_trimestre: this.funcaoService.convertToBrNumber(+total2)
    }
    listaExportar.push(linha_total_3)

    const linha_apuracao = {
      titulo: 'APURAÇÃO DO CUMPRIMENTO DOS LIMITES',
      no_trimestre: 'VALOR',
      ate_trimestre: '% SOBRE ARCL AJUSTADA'
    }
    listaExportar.push(linha_apuracao)

    let totalRCL = 0;
    let emendasIndividual = 0;
    let opVedadas = 0;
    let aro = 0;
    console.log(lista[2][0]);
    if (this.mes <= 4) {
      totalRCL = await this.anexoFederalServico.obterAnexo3Resuldado(4, this.exercicio.id, orgaos).toPromise();
      emendasIndividual = +lista[2][0].quadrimestre1;
      opVedadas = +lista[2][1].quadrimestre1;
      aro = +lista[2][2].quadrimestre1;
    }
    if (this.mes >= 5 && this.mes <= 8) {
      totalRCL = await this.anexoFederalServico.obterAnexo3Resuldado(8, this.exercicio.id, orgaos).toPromise();
      emendasIndividual = +lista[2][0].quadrimestre2;
      opVedadas = +lista[2][1].quadrimestre2;
      aro = +lista[2][2].quadrimestre2;
    }
    if (this.mes >= 9 && this.mes <= 12) {
      totalRCL = await this.anexoFederalServico.obterAnexo3Resuldado(12, this.exercicio.id, orgaos).toPromise();
      emendasIndividual = +lista[2][0].quadrimestre3;
      opVedadas = +lista[2][1].quadrimestre3;
      aro = +lista[2][2].quadrimestre3;
    }

    const linha_receita_corrente_liquida = {
      titulo: 'RECEITA CORRENTE LÍQUIDA - RCL (IV)',
      no_trimestre: this.funcaoService.convertToBrNumber(totalRCL),
      ate_trimestre: this.funcaoService.convertToBrNumber(0)
    }
    listaExportar.push(linha_receita_corrente_liquida)

    const linha_transferencia_individual = {
      titulo: '(-) Transferências obrigatórias da União relativas às emendas individuais  (§ 1º, art. 166-A da CF)  (V)',
      no_trimestre: this.funcaoService.convertToBrNumber(emendasIndividual),
      ate_trimestre: this.funcaoService.convertToBrNumber(0)
    }
    listaExportar.push(linha_transferencia_individual)

    const linha_receita_ajustada = {
      titulo: 'RECEITA CORRENTE LÍQUIDA AJUSTADA PARA CÁLCULO DOS LIMITES DE ENDIVIDAMENTO (VI) = (IV - V)',
      no_trimestre: this.funcaoService.convertToBrNumber(totalRCL),
      ate_trimestre: this.funcaoService.convertToBrNumber(0)
    }
    listaExportar.push(linha_receita_ajustada)

    const linha_operacoes_vedadas = {
      titulo: 'OPERAÇÕES VEDADAS (VII)',
      no_trimestre: this.funcaoService.convertToBrNumber(opVedadas),
      ate_trimestre: this.funcaoService.convertToBrNumber(0)
    }
    listaExportar.push(linha_operacoes_vedadas)

    const linha_total_considerado = {
      titulo: 'TOTAL CONSIDERADO PARA FINS DA APURAÇÃO DO CUMPRIMENTO DO LIMITE (VIII) = (IIIa + VII - Ia - IIa)',
      no_trimestre: this.funcaoService.convertToBrNumber(+total2 + +opVedadas - +total2I - +total2II),
      ate_trimestre: this.funcaoService.convertToBrNumber(0)
    }
    listaExportar.push(linha_total_considerado)

    const linha_limite_operacoes = {
      titulo: 'LIMITE GERAL DEFINIDO POR RESOLUÇÃO DO SENADO FEDERAL PARA AS OPERAÇÕES DE CRÉDITO INTERNAS E EXTERNAS',
      no_trimestre: '',
      ate_trimestre: ''
    }
    listaExportar.push(linha_limite_operacoes)

    const linha_limite_alerta = {
      titulo: 'LIMITE DE ALERTA (inciso III do §1º do art. 59 da LRF) - <%>',
      no_trimestre: this.funcaoService.convertToBrNumber(0),
      ate_trimestre: this.funcaoService.convertToBrNumber(0)
    }
    listaExportar.push(linha_limite_alerta)

    const linha_credito_antecipacao = {
      titulo: 'OPERAÇÕES DE CRÉDITO POR ANTECIPAÇÃO DA RECEITA ORÇAMENTÁRIA',
      no_trimestre: this.funcaoService.convertToBrNumber(0),
      ate_trimestre: this.funcaoService.convertToBrNumber(0)
    }
    listaExportar.push(linha_credito_antecipacao)

    const linha_limite_definido = {
      titulo: 'LIMITE DEFINIDO POR RESOLUÇÃO DO SENADO FEDERAL PARA AS OPERAÇÕES DE CRÉDITO POR ANTECIPAÇÃO DA RECEITA ORÇAMENTÁRIA',
      no_trimestre: '',
      ate_trimestre: ''
    }
    listaExportar.push(linha_limite_definido)

    this.outrasOperacoescSV(lista[1], listaExportar)

    return listaExportar
  }

  private outrasOperacoescSV(dados: [], listaExportar: any) {
    const titulos = this.funcaoService.agrupar(dados, 'titulo', ['quadrimestre1', 'quadrimestre2', 'quadrimestre3']);

    const linha_titulo_outras_operacoes = {
      titulo: 'OUTRAS OPERAÇÕES QUE INTEGRAM A DÍVIDA CONSOLIDADA',
      no_trimestre: 'VALOR REALIZADO',
      ate_trimestre: ''
    }
    listaExportar.push(linha_titulo_outras_operacoes)

    const linha_titulo_outras_operacoes2 = {
      titulo: '',
      no_trimestre: 'No Quadrimestre de Referência',
      ate_trimestre: 'Até o Quadrimestre de Referência'
    }
    listaExportar.push(linha_titulo_outras_operacoes2)

    for (const titulo of titulos) {
      let noTrimestre = 0;
      let ateTrimestre = 0;
      if (this.mes <= 4) {
        noTrimestre = +titulo.totalizadores['quadrimestre1'];
        ateTrimestre = +titulo.totalizadores['quadrimestre2'];
      } else if (this.mes >= 5 && this.mes <= 8) {
        noTrimestre = +titulo.totalizadores['quadrimestre3'];
        ateTrimestre = +titulo.totalizadores['quadrimestre4'];
      } else if (this.mes >= 5 && this.mes <= 8) {
        noTrimestre = +titulo.totalizadores['quadrimestre4'];
        ateTrimestre = 0;
      }

      const primeira_linha = {
        titulo: titulo.grupo,
        no_trimestre: this.funcaoService.convertToBrNumber(noTrimestre),
        ate_trimestre: this.funcaoService.convertToBrNumber(ateTrimestre)
      }
      listaExportar.push(primeira_linha)

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          if (this.mes <= 4) {
            noTrimestre = +grupo.totalizadores['quadrimestre1'];
            ateTrimestre = +grupo.totalizadores['quadrimestre2'];
          } else if (this.mes >= 5 && this.mes <= 8) {
            noTrimestre = +grupo.totalizadores['quadrimestre3'];
            ateTrimestre = +grupo.totalizadores['quadrimestre4'];
          } else if (this.mes >= 5 && this.mes <= 8) {
            noTrimestre = +grupo.totalizadores['quadrimestre4'];
            ateTrimestre = 0;
          }
          const linha_grupo = {
            titulo: grupo.grupo,
            no_trimestre: this.funcaoService.convertToBrNumber(noTrimestre),
            ate_trimestre: this.funcaoService.convertToBrNumber(ateTrimestre)
          }
          listaExportar.push(linha_grupo)

          for (const item of grupo.registros) {
            if (item.nome != '') {
              if (this.mes <= 4) {
                noTrimestre = +item.quadrimestre1;
                ateTrimestre = +item.quadrimestre2;
              } else if (this.mes >= 5 && this.mes <= 8) {
                noTrimestre = +item.quadrimestre3;
                ateTrimestre = +item.quadrimestre4;
              } else if (this.mes >= 5 && this.mes <= 8) {
                noTrimestre = +item.quadrimestre4;
                ateTrimestre = 0;
              }
              const linha_item_subgrupo = {
                titulo: item.grupo,
                no_trimestre: this.funcaoService.convertToBrNumber(noTrimestre),
                ate_trimestre: this.funcaoService.convertToBrNumber(ateTrimestre)
              }
              listaExportar.push(linha_item_subgrupo)
            }
          }
        }
      }
    }
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'OPERAÇÕES DE CRÉDITO', coluna: 'titulo' },
      { titulo: 'VALOR REALIZADO', coluna: 'no_trimestre' },
      { titulo: '', coluna: 'ate_trimestre' },
    ];
    return colunasDefault;
  }

}
