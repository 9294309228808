import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, LicitacaoFiscal, Page } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LicitacaoFiscalService extends BaseResourceService<LicitacaoFiscal> {

    constructor(
        protected injector: Injector
    ) {
        super(`licitacoes-fiscal`, injector);
    }

    public removerPorRpl(licitacaoId: number, rplId: number): Observable<LicitacaoFiscal> {
        return this.http.delete<LicitacaoFiscal>(
          `${this.login.cidade.id}/${this.api}/excluir-por-rpl/${licitacaoId}/${rplId}`
          , this.httpOptions()).pipe(
            map(res => res),
            catchError(err => this.handleError(err))
          );
      }

}
