import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ContratoTestemunha } from 'eddydata-lib';

@Injectable({
    providedIn: 'root'
})
export class ContratoTestemunhaService extends BaseResourceService<ContratoTestemunha> {

    constructor(
        protected injector: Injector
    ) {
        super(`contrato-testemunhas`, injector);
    }

}
