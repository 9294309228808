import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { EncerramentoContabil } from './encerramento-contabil.model';

export class EncerramentoContabilJustificativa extends BaseResourceModel {

  constructor(
    public id?: number,
    public descricao?: string,
    public mes?: number,
    public abertura?: boolean,
    public responsavel?: Usuario,
    public encerramento?: EncerramentoContabil
  ) {
    super();
  }

  static converteJson(json: any): EncerramentoContabilJustificativa {
    return Object.assign(new EncerramentoContabilJustificativa(), json);
  }
}
