import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NotaAdiantamento } from '../relatorio/nota-adiantamento';
import { DatePipe } from '@angular/common';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Login, GlobalService, OrgaoAssinaturaService } from 'eddydata-lib';
import { AdiantamentoService } from 'administrativo-lib';

@Component({
  selector: 'lib-nota-adiantamento-dlg',
  templateUrl: './nota-adiantamento-dlg.component.html'
})
export class NotaAdiantamentoDlgComponent implements OnInit, OnDestroy {

  protected datepipe: DatePipe;
  public ptBR: any;
  public selectedOrdem: string;
  public dataInicio: Date;
  public dataFinal: Date;
  protected unsubscribe: Subject<void> = new Subject();

  @Input() login: Login;

  constructor(
    protected messageService: MessageService,
    private globalService: GlobalService,
    private adiantamentoService: AdiantamentoService,
    private assinaturaService: OrgaoAssinaturaService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.selectedOrdem = 'ord1';
    this.dataInicio = new Date();
    this.dataFinal = new Date();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  imprimir() {
    const parametros = {};

    let relations = '';
    relations += 'empenho.ficha.executora.unidade,empenho.ficha.despesa,empenho.ficha.funcao,empenho.ficha.subfuncao,';
    relations += 'empenho.ficha.programa,empenho.ficha.acao,empenho.ficha.aplicacao,';
    relations += 'empenho.licitacao,empenho.modalidade,empenho.subelemento,empenho.favorecido';

    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['data_adiantamento$ge'] = this.datepipe.transform(this.dataInicio, 'yyyy-MM-dd');
    parametros['data_adiantamento$le'] = this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd');
    parametros['orderBy'] = this.selectedOrdem === 'ord1' ? 'data_adiantamento' : 'numero';

    this.adiantamentoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        const nota = new NotaAdiantamento(this.assinaturaService);
        nota.imprimir(lista.content, this.login);          
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

}
