import { Component, Input } from '@angular/core';
import { Contrato, ContratoStorage, FuncaoService, Login, PncpRegistro, PNCPService, ProgressoService, RegistroPncpService } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContratoStorageService } from '../service/contrato-storage.service';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-contrato-storage',
  templateUrl: './contrato-storage.component.html'
})
export class ContratoStorageComponent {

  @Input() public login: Login;
  @Input() public entidade: Contrato;
  @Input() public arquivos: ContratoStorage[];
  @Input() visualizar: Boolean;

  public uploadedFiles: any[] = [];
  public descricao: string = null;
  arquivoApagando: ContratoStorage = new ContratoStorage();

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected confirmationService: ConfirmationService,
    private pncpService: PNCPService,
    protected progressoService: ProgressoService,
    private funcaoService: FuncaoService,
    private registroPncpService: RegistroPncpService,
    private storageService: ContratoStorageService) {
  }

  public async onUpload(event: any, fileUpload: any) {
    if (!event.files || event.files.length === 0) {
      toastr.warning('Selecione o(s) arquivo(s)');
      return;
    }
    try {
      for (const file of event.files) {
        this.uploadedFiles.push(file);
      }
      this.storageService
        .upload(this.uploadedFiles, this.login.orgao.id, this.entidade.id, this.descricao).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          for (const item of res) {
            item.data_cadastro = new Date();
            this.arquivos.unshift(item);
          }
          toastr.success('Arquivo adicionado com sucesso!');
        }, (res) => {
          toastr.error(res.error.payload);
          this.uploadedFiles.length = 0;
        });
    } catch (ex) {
      toastr.error(ex.message ? ex.message : ex);
    }
    this.descricao = undefined;
    this.uploadedFiles = []
    fileUpload.clear();
    $('#dialogUpload').modal('hide');
  }

  public async remover(item: ContratoStorage) {
    if (!item) return
    this.confirmationService.confirm({
      message: 'Deseja realmente remover o item?',
      header: 'Exclusão',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.storageService.remover(item.id).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro removido com sucesso!', 'Exclusão');
            this.arquivos.splice(this.arquivos.indexOf(item), 1);
          }, (err) => toastr.error(err.error.payload));
      }
    });
  }

  public async toggleTransparencia(item: ContratoStorage) {
    if (!item) return;
    item.visivel_transparencia = !item.visivel_transparencia;
    await new Promise((resolve, reject) => {
      this.storageService
        .atualizar(item).subscribe(res => {
          toastr.success('Arquivo atualizado com sucesso');
          resolve(true)
        }, error => {
          toastr.error(error.error.payload)
          reject(error)
        });
    })
  }

  async atualizarDocumento(item: ContratoStorage) {
    await this.storageService.atualizar(item).toPromise();
    toastr.success('Tipo do documento salvo.');
  }

  public podeVisualizar() {
    if (this.visualizar === true) {
      return true;
    }
    if (this.visualizar === false) {
      return false;
    }
  }

  enviarArquivo(item: ContratoStorage) {
    if (!item.tipo_documento) {
      toastr.warning('Tipo de documento para o PNCP não informado');
      return;
    }
    this.confirmationService.confirm({
      header: 'Envio de documento',
      message: `<pre>Confirma o envio do arquivo?</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo envio.',
      rejectLabel: 'Não',
      accept: async () => {
        const aux = Object.assign({}, this.entidade);
        aux.arquivos = [];
        item.contrato = aux;
        this.pncpService.enviarArquivoContrato(item).subscribe((idTransacao) => {
          this.progressoService.show(idTransacao, async (retorno) => {
            item.sequencial_pncp = +retorno[0].mensagem.split(':')[1]
          })
        }, e => {
          this.funcaoService.acaoErro(e);
        });
      }
    });
  }

  dlgApagarArquivo(item: ContratoStorage) {
    this.arquivoApagando = item;
    $('#dlgApagarItem').modal('show');
  }

  fecharDlgApagarArquivo() {
    this.arquivoApagando = new ContratoStorage();
    $('#dlgApagarItem').modal('hide');
  }

  apagarArquivo(item: ContratoStorage) {
    this.fecharDlgApagarArquivo();
    this.confirmationService.confirm({
      header: 'Apagar documento',
      message: `<pre>Confirma a remoção do documento?</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo exclusão.',
      rejectLabel: 'Não',
      accept: async () => {
        const aux = Object.assign({}, this.entidade);
        aux.arquivos = [];
        item.contrato = aux;
        this.pncpService.apagarArquivoContrato(item).subscribe(async (res) => {
          const index = this.arquivos.indexOf(item);
          const seq = item.sequencial_pncp;
          item.sequencial_pncp = null;
          await this.storageService.atualizar(item).toPromise();

          const registro: PncpRegistro = new PncpRegistro();
          registro.exercicio = this.entidade.licitacao.exercicio;
          registro.id_alvo = this.entidade.id;
          registro.id_usuario_pncp = this.login.dados_pncp?.id;
          registro.orgao = this.entidade.orgao;
          registro.ratificado = false;
          registro.sequencial = seq;
          registro.tabela_alvo = 'contrato_storage';
          await this.registroPncpService.inserir(registro).toPromise();

          this.arquivos[index] = item;
          // this.fecharDlgApagarArquivo();
          toastr.success('Arquivo removido com sucesso do pncp!');
        }, e => {
          if (e.error?.message) {
            toastr.error(e.error.message);
          } else {
            this.funcaoService.acaoErro(e);
          }
        });
      }
    });
  }

}