import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService } from '../../models/services/base-resource.service';
import { Ppa } from '../../entidade/planejamento/ppa.model';

@Injectable({
  providedIn: 'root'
})
export class PpaService extends BaseResourceService<Ppa> {

  constructor(
    protected injector: Injector
  ) {
    super(`planos-plurianuais`, injector);
  }

  public obterPorAno(ano: number, cidadeId: number): Observable<Ppa> {
    return this.http.get<Ppa>(`${cidadeId}/${this.api}/ano/${ano}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public obterPorPeriodo(periodoId: number): Observable<Ppa> {
    return this.http.get<Ppa>(`${this.login.cidade.id}/${this.api}/periodo/${periodoId}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public efetuarCopia(ppa: Ppa): Observable<string> {
    return this.http.post<string>(`${this.login.cidade.id}/${this.api}/salvar-copia-ppa/`, ppa, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
