import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService } from '../../models/services/base-resource.service';
import { Orgao } from '../../entidade/comum/orgao.model';
import { Page } from '../../util/page';

@Injectable({
  providedIn: 'root'
})
export class OrgaoService extends BaseResourceService<Orgao> {

  constructor(
    protected injector: Injector
  ) {
    super(`orgaos`, injector);
  }

  public obterPorCodigo(codigo: string, cidadeId: number): Observable<Page> {
    return this.http.get<Page>(
      `${cidadeId}/${this.api}/filtrar?codigo=${codigo}&cidade.id=${cidadeId}&relations=brasao`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  salvarSiconfi(orgao: number, siconfi: number): Observable<any> {
    return this.http.put(`${this.login.cidade.id}/${this.api}/msc/${orgao}/${siconfi}`, {}, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  proximoNumeroFebraban(orgao: number): Observable<any> {
    return this.http.put(`${this.login.cidade.id}/${this.api}/proximo-febraban/${orgao}`, {}, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public obterPorId(id: number): Observable<Orgao> {
    return this.http.get<Orgao>(
      `${this.login.cidade.id}/${this.api}/${id}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorNome(nome: string, cidadeId: number): Observable<Orgao> {
    return this.http.get<Orgao>(
      `${cidadeId}/${this.api}/filtrar?nome$like=${encodeURIComponent(nome + '%')}&cidade_id=${cidadeId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorEspecie(especie: string, cidadeId: number): Observable<Orgao> {
    return this.http.get<Orgao>(
      `${this.api}/especie?especie=${especie}&cidade=${cidadeId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterTodos(): Observable<Array<Orgao>> {
    return this.http.get<Array<Orgao>>(`${this.api}/todos?pagina=1&limite=100`).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
