import { Audesp4DadosLicitacao, Audesp4LoteObra, Audesp4Edital, Audesp4VistaTecnica, Audesp4LoteNaoObra, Audesp4Amostra } from "./comum-type";

export type Audesp4ObrasServicosEngenharia = {
    ObjetoLicitacaoEN: number;
    DadosLicitacao?: Audesp4DadosLicitacao;
    Lote: Audesp4LoteObra[];
    Edital?: Audesp4Edital;
    VistaTecnicaNao?: 'S' | 'N';
    VistaTecnica?: Audesp4VistaTecnica;
}

export type Audesp4ComprasServicos = {
    ObjetoLicitacao: number;
    DadosLicitacao?: Audesp4DadosLicitacao;
    Lote: Audesp4LoteNaoObra[];
    Edital?: Audesp4Edital;
    AmostraNao?: 'S' | 'N';
    Amostra?: Audesp4Amostra;
}

export type Audesp4ComprasServicosTI = {
    ObjetoLicitacaoTI: number;
    DadosLicitacao?: Audesp4DadosLicitacao;
    FinalidadeEquipamento: string;
    CompatibilidadeCompuitadoresSistemas: string;
    PedidoSubstituicaoEquipamentoAntigos: string;
    PedidoNomesMarcas: string;
    DescricaoTecnica: string;
    SoftwareProprietario: string;
    ManuaisEmPortugues: string;
    CertificadoANATEL: string;
    Lote: Audesp4LoteNaoObra[];
    Edital?: Audesp4Edital;
    AmostraNao?: 'S' | 'N';
    Amostra?: Audesp4Amostra;
}