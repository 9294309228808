import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Page, RetencaoResto } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RetencaoRestoService extends BaseResourceService<RetencaoResto> {

  constructor(
    protected injector: Injector
  ) {
    super(`retencoes-restos`, injector);
  }

  public emAberto(
    exercicioId: number, orgaoId: number,
    parametros: { data_inicio: string, data_termino: string, orderBy?: string, relations?: string[] }
  ): Observable<any> {
    let filtros = `?data_inicio=${parametros.data_inicio}&data_termino=${parametros.data_termino}`;
    if (parametros.orderBy) {
      filtros += `&order_by=${parametros.orderBy}`;
    }
    if (parametros.relations) {
      filtros += `&relations=${parametros.relations.join(',')}`
    }

    return this.http.get(
      `${this.login.cidade.id}/${this.api}/em-aberto/${exercicioId}/${orgaoId}${filtros}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );

  }

  public obterPorLiquidacao(id: number): Observable<Page> {
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/extendido/?relations=liquidacao.pagamentos,liquidacao.orgao,liquidacao.exercicio,liquidacao.empenho,liquidacao.empenho.favorecido,ficha,ficha.plano,ficha.favorecido&liquidacao_id=${id}&orderBy=liquidacao.pagamentos.id`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public verificarPagamentoRetencao(retencaoId: number, exercicio: number, orgao: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/verifica-pagamento?retencaoId=${retencaoId}&exercicio=${exercicio}&orgao=${orgao}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
