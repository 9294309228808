import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, FichaReceita } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class AnexoLRFService extends BaseResourceService<FichaReceita> {

  constructor(
    protected injector: Injector
  ) {
    super(`contabil-relatorio`, injector);
  }

  obterBalancoOrcamentario(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/balanco-orcamentario/${mes}/${exercicio}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterRCL(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/rcl/${mes}/${exercicio}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterDCL(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/dcl/${mes}/${exercicio}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterFuncaoSubfuncao(mes: number, exercicio: number, orgaos: number[], funcao?: number, recurso?: number): Observable<any> {
    let parametros = '';
    if (recurso)
      parametros = `?recurso=${recurso}`;
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/funcao-subfuncao/${mes}/${exercicio}/${orgaos.join()}`
      + (funcao ? `/${funcao}` : '') + parametros,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterDespesaPessoal(mes: number, exercicio: number, orgaos: number[], executivo: boolean, percentual: boolean): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/despesas-pessoal/${mes}/${exercicio}/${orgaos.join()}/${executivo}/${percentual}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterResultadoPrimario(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/resultado-primario/${mes}/${exercicio}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterResultadoNominal(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/resultado-nominal/${mes}/${exercicio}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterRGF(mes: number, exercicio: number, orgaos: number[], executivo: boolean): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/rgf/${mes}/${exercicio}/${orgaos.join()}/${executivo}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterOperacaoCredito(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/operacao-credito/${mes}/${exercicio}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterRestosPagar(mes: number, exercicio: number, cidade: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/restos-pagar/${mes}/${exercicio}/${cidade}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterDisponibilidaFinanceira(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/disponibilidade-financeira/${mes}/${exercicio}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterReceitaAlienacao(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/receita-alienacao/${mes}/${exercicio}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterDemonstrativoReceitaDespesaPrevidencia(mes: number, exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/demonstrativo-receita-despesa-previdencia/${mes}/${exercicio}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterDemonstrativoApuracaoCumprimento(exercicio: number, bimestre: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/apuracao-cumprimento/${exercicio}/${bimestre}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterSqlViaBanco(params: any): Observable<any> {
    const queryParameter = new URLSearchParams(params).toString()
    return this.http.get(`${this.login.cidade.id}/${this.api}/sql-via-banco?${queryParameter}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }
}
