import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import * as toastr from 'toastr';
import { Login } from '../entidade/login/login';
import { LoginService } from '../login/servico/login.service';
import { FuncaoService } from './funcao.service';
import { GlobalService } from './global.service';

@Injectable()
export class AuthGuard implements CanLoad, CanActivate {

  constructor(
    private authService: LoginService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.verificarAcesso(state);
  }

  private verificarAcesso(state: RouterStateSnapshot) {

    const usuarioAutenticado = this.authService.usuarioEstaAutenticado();
    if (usuarioAutenticado) {
      if (state.url === '/') {
        return true;
      }

      const funcaoService = new FuncaoService();
      const login: Login = GlobalService.obterSessaoLogin();
      if (funcaoService.campoJsonToken(usuarioAutenticado.token, 'administrador') && login.usuario.sistemas_administrador?.includes(login.usuario.sistema)) {
        return true;
      }


      if (!login.acessos || login.acessos.length === 0) {
        if (login.usuario.sistema === 'transparencia') {
          this.authService.mostrarMenuEmitter.emit(false);
          this.router.navigate(['/login']);
        } else {
          toastr.warning('Você não possui permissão para este acesso!');
        }
        return false;
      }
      for (const acesso of login.acessos) {
        const inicioUrl = state.url.split('/')
        if (inicioUrl.length > 0 && `/${inicioUrl[1]}` === acesso.pagina) {
          if (acesso.permissao > 0) {
            return true;
          } else {
            toastr.warning('Você não possui permissão para este acesso!');
            return false;
          }
        }
      }
      if (state.url.match(GlobalService.TRANSPARENCIA_REGEXP)) { // urls do transparencia
        return true;
      }

      toastr.warning('Você não possui permissão para este acesso!');
      return false;
    }
    this.authService.mostrarMenuEmitter.emit(false);
    this.router.navigate(['/login']);

    return false;
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.verificarAcesso(null);
  }
}

@Injectable()
export class AuthGuardFake implements CanLoad, CanActivate {

  constructor(private authService: LoginService) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.usuarioEstaAutenticadoFake();
  }

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.usuarioEstaAutenticadoFake();
  }
}
