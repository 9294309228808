import { Directive, Injector, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { QuadroEnsinoService } from '../service/quadro-ensino.service';
import {
  FuncaoService, GlobalService, Login,
  Exercicio, FuncaoGoverno, Relatorio, FormatoExportacao, Coluna
} from 'eddydata-lib';
import { Assinaturas } from 'administrativo-lib';

@Directive()
export class Quadro1Ensino implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected quadroServico: QuadroEnsinoService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected dataInicial: Date,
    protected dataFinal: Date,
    protected funcao?: FuncaoGoverno,
    protected injector?: Injector,
    protected assinatura_ensino?: boolean) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async listar(orgaos: number[]) {
    return await this.quadroServico
      .obterQuadro1(this.mes, this.exercicio.id, orgaos, this.dataInicial, this.dataFinal).toPromise();
  }

  public montarRelatorio(orgaos: number[], formato?: FormatoExportacao) {
    if (formato == null) formato = 'pdf';

    this.quadroServico.obterQuadro1(this.mes, this.exercicio.id, orgaos, this.dataInicial, this.dataFinal)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(
            'QUADRO 1 - RECEITAS DE IMPOSTOS'
            , this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.conteudo(dados),
            'portrait', 'QUADRO 1 - RECEITAS DE IMPOSTOS',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            })
        } else {
          this.funcaoService.exportar(formato, this.conteudoExportar(dados), 'QUADRO 1 - RECEITAS DE IMPOSTOS', this.colunasRelatorio())
        }
      })
  }

  private async conteudo(dados: any[]): Promise<{}[]> {
    let referencia = '';
    if (this.mes) {
      referencia = `${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase()}/${this.exercicio.ano}`;
    } else if (this.dataInicial && this.dataFinal) {
      let dt = this.dataInicial.toString().split("-");
      referencia = `${dt[2]}/${dt[1]}/${dt[0]}`;
      dt = this.dataFinal.toString().split("-");
      referencia += ` à ${dt[2]}/${dt[1]}/${dt[0]}`;
    } else {
      referencia += this.exercicio.ano;
    }

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `REFERÊNCIA: ${referencia}`,
        alignment: 'center',
        fontSize: 11,
        margin: [5, 5, 5, 5],
        bold: true,
        colSpan: 4
      }, '', '', ''],
      [{
        text: 'A) RECEITAS TOTAIS DE IMPOSTOS E TRANSFERÊNCIAS',
        alignment: 'left',
        bold: true,
        colSpan: 4
      }, '', '', ''],
      [{
        text: 'DISCRIMINAÇÃO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'PREVISÃO INICIAL DO EXERCÍCIO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'PREVISÃO ATUALIZADA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'ARRECADAÇÃO ATÉ O TRIMESTRE',
        alignment: 'center',
        bold: true, fontSize: 6
      }]
    ];
    let grupos: any;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    // monta o agrupamento do relatório
    const titulos = this.funcaoService.agrupar(dados[0], 'titulo',
      ['previsao', 'atualizado', 'arrecadado']);
    for (const titulo of titulos) {
      registros.push([
        {
          text: titulo.grupo, bold: true, fontSize: 8
        },
        {
          text: this.funcaoService.convertToBrNumber(titulo.totalizadores['previsao']),
          alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(titulo.totalizadores['atualizado']),
          alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(titulo.totalizadores['arrecadado']),
          alignment: 'right', bold: true, fontSize: 7
        }
      ]);

      grupos = this.funcaoService.agrupar(titulo.registros, 'tipo',
        ['previsao', 'atualizado', 'arrecadado']);

      for (const grupo of grupos) {
        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsao']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(grupo.totalizadores['atualizado']),
            alignment: 'right', bold: true, fontSize: 7
          },
          {
            text: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']),
            alignment: 'right', bold: true, fontSize: 7
          }
        ]);
        total1 += +grupo.totalizadores['previsao'];
        total2 += +grupo.totalizadores['atualizado'];
        total3 += +grupo.totalizadores['arrecadado'];

        for (const item of grupo.registros) {
          if ((+item.previsao + +item.atualizado + +item.arrecadado) !== 0) {
            registros.push([
              {
                text: item.nome, fontSize: 7
              },
              { text: this.funcaoService.convertToBrNumber(item.previsao), alignment: 'right', fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(item.atualizado), alignment: 'right', fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(item.arrecadado), alignment: 'right', fontSize: 7 }
            ]);
          }
        }
      }
    }

    registros.push([
      {
        text: 'TOTAL', fontSize: 7, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 7, bold: true }
    ]);
    // DEDUÇÕES -------------------------------------------------

    // monta o agrupamento do relatório para deduções
    registros.push(
      [{
        text: 'DEDUÇÕES  PARA  FORMAÇÃO DO FUNDEB',
        alignment: 'center',
        margin: [5, 5, 5, 5],
        bold: true,
        colSpan: 4
      }, '', '', ''],
      [{
        text: 'DISCRIMINAÇÃO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'PREVISÃO INICIAL DO EXERCÍCIO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'PREVISÃO ATUALIZADA',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'DEDUÇÕES ATÉ O TRIMESTRE',
        alignment: 'center',
        bold: true, fontSize: 6
      }]
    );

    grupos = this.funcaoService.agrupar(dados[1], 'tipo',
      ['previsao', 'atualizado', 'arrecadado']);
    total1 = 0;
    total2 = 0;
    total3 = 0;
    for (const grupo of grupos) {
      registros.push([
        {
          text: grupo.grupo, bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsao']),
          alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(grupo.totalizadores['atualizado']),
          alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']),
          alignment: 'right', bold: true, fontSize: 7
        }
      ]);

      total1 += +grupo.totalizadores['previsao'];
      total2 += +grupo.totalizadores['atualizado'];
      total3 += +grupo.totalizadores['arrecadado'];

      for (const item of grupo.registros) {
        if ((+item.previsao + +item.atualizado + +item.arrecadado) !== 0) {
          registros.push([
            {
              text: item.nome, fontSize: 7
            },
            { text: this.funcaoService.convertToBrNumber(item.previsao), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(item.atualizado), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(item.arrecadado), alignment: 'right', fontSize: 7 }
          ]);
        }
      }
    }

    registros.push([
      {
        text: 'TOTAL', fontSize: 7, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 7, bold: true }
    ]);

    let assinaturas
    if (this.assinatura_ensino) {
      const ass = new Assinaturas(this.login.orgao, this.injector);
      assinaturas = await ass.dadosAssinatura(50, false, null, false, this.assinatura_ensino);
    }


    return this.assinatura_ensino ? [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 55, 55, 55],
          body: registros
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*'],
          body: assinaturas
        }
      }
    ] : [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 55, 55, 55],
          body: registros
        }
      }];
  }

  public colunasRelatorio(): Coluna[] {
    return [
      { titulo: 'Grupo', coluna: 'grupo' },
      { titulo: 'Titulo', coluna: 'titulo' },
      { titulo: 'Tipo', coluna: 'tipo' },
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'Previsão Inicial', coluna: 'previsao', decimais: 2, },
      { titulo: 'Previsão Atualizada', coluna: 'atualizado', decimais: 2 },
      { titulo: 'Arrecadação', coluna: 'arrecadado', decimais: 2 }
    ]
  }

  public conteudoExportar(lista: any[]) {
    return [
      ...this.normalizarDados(lista[0], 'RECEITAS TOTAIS DE IMPOSTOS E TRANSFERÊNCIAS'),
      ...this.normalizarDados(lista[1], 'DEDUÇÕES PARA FORMAÇÃO DO FUNDEB')
    ]
  }

  // public normalizarDados(lista: [], grupo: string) {
  //   return lista.map((item: any) => {
  //     return {
  //       grupo: grupo,
  //       titulo: item.titulo,
  //       tipo: item.tipo,
  //       nome: item.nome,
  //       previsao: +item.previsao,
  //       atualizado: +item.atualizado,
  //       arrecadado: +item.arrecadado
  //     }
  //   })
  // }

  public normalizarDados(lista: any[], grupo: string) {
    const linhas: {}[] = [{
      grupo: `${grupo} - TOTALIZADO`,
      previsao: lista.reduce((acc, item) => +item.previsao + acc, 0),
      atualizado: lista.reduce((acc, item) => +item.atualizado + acc, 0),
      arrecadado: lista.reduce((acc, item) => +item.arrecadado + acc, 0)
    }]

    const totalTitulo = this.funcaoService.agrupar(lista, 'titulo', ['previsao', 'atualizado', 'arrecadado'])

    totalTitulo.forEach(item => {
      if (item.grupo) {
        linhas.push({
          grupo: grupo,
          titulo: `${item.grupo} - TOTALIZADO`,
          previsao: +item.totalizadores['previsao'],
          atualizado: +item.totalizadores['atualizado'],
          arrecadado: +item.totalizadores['arrecadado']
        })
      }

      const totalTipo = this.funcaoService.agrupar(item.registros, ['titulo', 'tipo'], ['previsao', 'atualizado', 'arrecadado'])

      totalTipo.forEach(item => {
        linhas.push({
          grupo: grupo,
          titulo: item.grupo['titulo'],
          tipo: `${item.grupo['tipo']} - TOTALIZADO`,
          previsao: +item.totalizadores['previsao'],
          atualizado: +item.totalizadores['atualizado'],
          arrecadado: +item.totalizadores['arrecadado']
        })

        item.registros.forEach(item => {
          linhas.push({
            grupo: grupo,
            titulo: item.titulo,
            tipo: item.tipo,
            nome: item.nome,
            previsao: +item.previsao,
            atualizado: +item.atualizado,
            arrecadado: +item.arrecadado
          })
        })
      })
    })

    return linhas

  }

}
