import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../../assinaturas/assinaturas';
import { AnexoLrfFederalService } from '../../../service/anexo-lrf-federal.service';

@Directive()
export class Anexo3RgfLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoFederalServico: AnexoLrfFederalService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected assinaturaControleInterno: boolean) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
    this.anexoFederalServico.obterAnexo3Rgf(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          const listaConteudo = await this.conteudo(dados, orgaos);
          Relatorio.imprimirPersonalizado(null, this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            listaConteudo,
            'portrait', 'ANEXO3 - DEMONSTRATIVO DAS GARANTIAS E CONTRAGARANTIAS DE VALORES',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 2;
                },
                paddingRight() {
                  return 2;
                }
              }
            }, true, false);
        } else {
          this.funcaoService.exportar(formato, await this.normalizar(dados, orgaos), 'ANEXO3 - DEMONSTRATIVO DAS GARANTIAS E CONTRAGARANTIAS DE VALORES', this.colunas());
        }
      });
  }

  private async conteudo(dados: any[], orgaos: any): Promise<{}[]> {
    // monta o cabecalho

    const registros: {}[] = [
      [
        { text: this.login.orgao.nome, bold: true, border: [false, false, false, false], colSpan: 5, fontSize: 10 },
        '', '', '', ''
      ],

      [
        { text: 'RELATÓRIO DE GESTÃO FISCAL', bold: true, border: [false, false, false, false], colSpan: 5 },
        '', '', '', ''],
      [
        { text: 'DEMONSTRATIVO DAS GARANTIAS E CONTRAGARANTIAS DE VALORES', bold: true, border: [false, false, false, false], colSpan: 5 },
        '', '', '', ''],
      [
        { text: 'ORÇAMENTOS FISCAL E DA SEGURIDADE SOCIAL', bold: true, border: [false, false, false, false], colSpan: 5 },
        '', '', '', ''],
      [
        { text: `REFERÊNCIA: ${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano}`, bold: true, border: [false, false, false, false], colSpan: 5 },
        '', '', '', ''],
      [
        { text: 'RGF - ANEXO 3 (LRF, art. 55, inciso I, alínea "c" e art. 40, § 1º)', bold: true, border: [false, false, false, false], colSpan: 5 },
        '', '', '', ''
      ],
      [
        { text: 'GARANTIAS CONCEDIDAS', rowSpan: 2, alignment: 'center' },
        { text: 'SALDO EXERCÍCIO ANTERIOR', alignment: 'center', bold: true, fontSize: 8, rowSpan: 2 },
        { text: `SALDO DO EXERCÍCIO DE ${this.exercicio.ano}`, alignment: 'center', bold: true, fontSize: 8, colSpan: 3 },
        { text: '' },
        { text: '' }
      ],
      [
        { text: '' },
        { text: '' },
        { text: 'Até o 1º Quadrimestre', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'Até o 2º Quadrimestre', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'Até o 3º Quadrimestre', alignment: 'center', bold: true, fontSize: 8 },
      ]

    ];

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);

    // monta o agrupamento do relatório
    const titulos = this.funcaoService.agrupar(dados[0], ['titulo', 'somador'], ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;

    for (const titulo of titulos) {
      registros.push([
        { text: titulo.grupo['titulo'], fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['saldo_anterior']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre1']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre2']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre3']), alignment: 'right', fontSize: 8, bold: true }
      ]);
      total1 += titulo.grupo['somador'] ? +titulo.totalizadores['saldo_anterior'] : +titulo.totalizadores['saldo_anterior'] * -1;
      total2 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre1'] : +titulo.totalizadores['quadrimestre1'] * -1;
      total3 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre2'] : +titulo.totalizadores['quadrimestre2'] * -1;
      total4 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre3'] : +titulo.totalizadores['quadrimestre3'] * -1;

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          registros.push([
            { text: grupo.grupo, fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['saldo_anterior']), alignment: 'right', fontSize: 8 },
            { text: this.mes >= 1 && this.mes <= 4 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre1']) : '0,00', alignment: 'right', fontSize: 8 },
            { text: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre2']) : '0,00', alignment: 'right', fontSize: 8 },
            { text: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre3']) : '0,00', alignment: 'right', fontSize: 8 },
          ]);

          for (const item of grupo.registros) {
            if (item.nome != '') {
              registros.push([
                {
                  text: item.nome, fontSize: 8, margin: [10, 0, 0, 0]
                },
                { text: this.funcaoService.convertToBrNumber(item.saldo_anterior), alignment: 'right', fontSize: 8 },
                { text: this.mes >= 1 && this.mes <= 4 ? this.funcaoService.convertToBrNumber(item.quadrimestre1) : '0,00', alignment: 'right', fontSize: 8 },
                { text: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(item.quadrimestre2) : '0,00', alignment: 'right', fontSize: 8 },
                { text: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(item.quadrimestre3) : '0,00', alignment: 'right', fontSize: 8 },
              ]);
            }
          }
        }
      }
    }

    registros.push([
      {
        text: 'TOTAL GARANTIAS CONCEDIDAS (V) = (I + II + III + IV)', fontSize: 8, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(+total1), alignment: 'right', fontSize: 8, bold: true },
      { text: this.mes >= 1 && this.mes <= 4 ? this.funcaoService.convertToBrNumber(+total2) : '0,00', alignment: 'right', fontSize: 8, bold: true },
      { text: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(+total3) : '0,00', alignment: 'right', fontSize: 8, bold: true },
      { text: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(+total4) : '0,00', alignment: 'right', fontSize: 8, bold: true }
    ]);

    let totalRCL0 = 0;
    let totalRCL1 = 0;
    let totalRCL2 = 0;
    let totalRCL3 = 0;

    if (this.mes <= 4) {
      totalRCL0 = await this.anexoFederalServico.obterAnexo3Resuldado(0, this.exercicio.id, orgaos).toPromise();
      totalRCL1 = await this.anexoFederalServico.obterAnexo3Resuldado(this.mes, this.exercicio.id, orgaos).toPromise();
    }
    if (this.mes >= 5 && this.mes <= 8) {
      totalRCL2 = await this.anexoFederalServico.obterAnexo3Resuldado(this.mes, this.exercicio.id, orgaos).toPromise();
    }
    if (this.mes >= 9 && this.mes <= 12) {
      totalRCL3 = await this.anexoFederalServico.obterAnexo3Resuldado(this.mes, this.exercicio.id, orgaos).toPromise();
    }

    registros.push([
      {
        text: 'RECEITA CORRENTE LÍQUIDA - RCL (VI)', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(totalRCL0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalRCL1), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalRCL2), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalRCL3), alignment: 'right', fontSize: 8, bold: true }
    ]);

    registros.push([
      {
        text: '(-) Transferências obrigatórias da União relativas às emendas individuais  (art. 166-A, § 1º,  da CF)  (VII)', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true }
    ]);

    registros.push([
      {
        text: 'RECEITA CORRENTE LÍQUIDA AJUSTADA PARA CÁLCULO DOS LIMITES DE ENDIVIDAMENTO (VIII) = (VI - VII)', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(totalRCL0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalRCL1), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalRCL2), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalRCL3), alignment: 'right', fontSize: 8, bold: true }
    ]);

    registros.push([
      {
        text: '% do TOTAL DAS GARANTIAS sobre a RCL AJUSTADA (V/VIII)', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(+total1 / +totalRCL0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total2 / +totalRCL1), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total3 / +totalRCL2), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+total4 / +totalRCL3), alignment: 'right', fontSize: 8, bold: true }
    ]);

    registros.push([
      {
        text: 'LIMITE DEFINIDO POR RESOLUÇÃO DO SENADO FEDERAL - <%>', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true }
    ]);

    registros.push([
      {
        text: 'LIMITE DE ALERTA (inciso III do §1º do art. 59 da LRF) - <%>', fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true }
    ]);

    this.outrasGarantias(dados[1], registros);

    return [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 80, 80, 80, 80],
          body: registros
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*'],
          body: assinaturas
        }
      }
    ];
  }

  private outrasGarantias(dados: [], registros: any) {

    registros.push(
      [
        { text: 'CONTRAGARANTIAS RECEBIDAS', rowSpan: 2, alignment: 'center' },
        { text: 'SALDO EXERCÍCIO ANTERIOR', alignment: 'center', bold: true, fontSize: 8, rowSpan: 2 },
        { text: `SALDO DO EXERCÍCIO DE ${this.exercicio.ano}`, alignment: 'center', bold: true, fontSize: 8, colSpan: 3 },
        { text: '' },
        { text: '' }
      ],
      [
        { text: '' },
        { text: '' },
        { text: 'Até o 1º Quadrimestre', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'Até o 2º Quadrimestre', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'Até o 3º Quadrimestre', alignment: 'center', bold: true, fontSize: 8 },
      ]
    );
    // monta o agrupamento do relatório
    const titulos = this.funcaoService.agrupar(dados, ['titulo', 'somador'], ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;

    for (const titulo of titulos) {
      registros.push([
        { text: titulo.grupo['titulo'], fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['saldo_anterior']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre1']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre2']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre3']), alignment: 'right', fontSize: 8, bold: true }
      ]);
      total1 += titulo.grupo['somador'] ? +titulo.totalizadores['saldo_anterior'] : +titulo.totalizadores['saldo_anterior'] * -1;
      total2 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre1'] : +titulo.totalizadores['quadrimestre1'] * -1;
      total3 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre2'] : +titulo.totalizadores['quadrimestre2'] * -1;
      total4 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre3'] : +titulo.totalizadores['quadrimestre3'] * -1;

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          registros.push([
            { text: grupo.grupo, fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['saldo_anterior']), alignment: 'right', fontSize: 8 },
            { text: this.mes >= 1 && this.mes <= 4 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre1']) : '0,00', alignment: 'right', fontSize: 8 },
            { text: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre2']) : '0,00', alignment: 'right', fontSize: 8 },
            { text: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre3']) : '0,00', alignment: 'right', fontSize: 8 },
          ]);

          for (const item of grupo.registros) {
            if (item.nome != '') {
              registros.push([
                {
                  text: item.nome, fontSize: 8, margin: [10, 0, 0, 0]
                },
                { text: this.funcaoService.convertToBrNumber(item.saldo_anterior), alignment: 'right', fontSize: 8 },
                { text: this.mes >= 1 && this.mes <= 4 ? this.funcaoService.convertToBrNumber(item.quadrimestre1) : '0,00', alignment: 'right', fontSize: 8 },
                { text: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(item.quadrimestre2) : '0,00', alignment: 'right', fontSize: 8 },
                { text: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(item.quadrimestre3) : '0,00', alignment: 'right', fontSize: 8 },
              ]);
            }
          }

        }
      }
    }

    registros.push([
      {
        text: 'TOTAL CONTRAGARANTIAS RECEBIDAS (XI) = (VII + VIII + IX + X)', fontSize: 8, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(+total1), alignment: 'right', fontSize: 8, bold: true },
      { text: this.mes >= 1 && this.mes <= 4 ? this.funcaoService.convertToBrNumber(+total2) : '0,00', alignment: 'right', fontSize: 8, bold: true },
      { text: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(+total3) : '0,00', alignment: 'right', fontSize: 8, bold: true },
      { text: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(+total4) : '0,00', alignment: 'right', fontSize: 8, bold: true }
    ]);


    registros.push([
      {
        text: 'MEDIDAS CORRETIVAS:', fontSize: 8, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 8, bold: true }
    ]);
  }

  public async normalizar(lista: any[], orgaos: any): Promise<{}[]> {
    const listaExportar = []

    const titulos = this.funcaoService.agrupar(lista[0], ['titulo', 'somador'], ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;

    const linha_titulo = {
      titulo: '',
      saldo_anterior: '',
      primeiro_quadrimestre: 'Até o 1º Quadrimestre',
      segundo_quadrimestre: 'Até o 2º Quadrimestre',
      terceiro_quadrimestre: 'Até o 3º Quadrimestre'
    }
    listaExportar.push(linha_titulo)

    for (const titulo of titulos) {
      const primeira_linha = {
        titulo: titulo.grupo['titulo'],
        saldo_anterior: this.funcaoService.convertToBrNumber(titulo.totalizadores['saldo_anterior']),
        primeiro_quadrimestre: this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre1']),
        segundo_quadrimestre: this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre2']),
        terceiro_quadrimestre: this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre3'])
      }
      listaExportar.push(primeira_linha)

      total1 += titulo.grupo['somador'] ? +titulo.totalizadores['saldo_anterior'] : +titulo.totalizadores['saldo_anterior'] * -1;
      total2 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre1'] : +titulo.totalizadores['quadrimestre1'] * -1;
      total3 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre2'] : +titulo.totalizadores['quadrimestre2'] * -1;
      total4 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre3'] : +titulo.totalizadores['quadrimestre3'] * -1;

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          const linha_grupo = {
            titulo: grupo.grupo,
            saldo_anterior: this.funcaoService.convertToBrNumber(grupo.totalizadores['saldo_anterior']),
            primeiro_quadrimestre: this.mes >= 1 && this.mes <= 4 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre1']) : '0,00',
            segundo_quadrimestre: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre2']) : '0,00',
            terceiro_quadrimestre: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre3']) : '0,00'
          }
          listaExportar.push(linha_grupo)
        }

        for (const item of grupo.registros) {
          if (item.nome != '') {
            const linha_item_subgrupo = {
              titulo: item.nome,
              saldo_anterior: this.funcaoService.convertToBrNumber(item.saldo_anterior),
              primeiro_quadrimestre: this.mes >= 1 && this.mes <= 4 ? this.funcaoService.convertToBrNumber(item.quadrimestre1) : '0,00',
              segundo_quadrimestre: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(item.quadrimestre2) : '0,00',
              terceiro_quadrimestre: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(item.quadrimestre3) : '0,00',
            }
            listaExportar.push(linha_item_subgrupo)
          }
        }
      }
    }

    const linha_total_garantias = {
      titulo: 'TOTAL GARANTIAS CONCEDIDAS (V) = (I + II + III + IV)',
      saldo_anterior: this.funcaoService.convertToBrNumber(+total1),
      primeiro_quadrimestre: this.mes >= 1 && this.mes <= 4 ? this.funcaoService.convertToBrNumber(+total2) : '0,00',
      segundo_quadrimestre: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(+total3) : '0,00',
      terceiro_quadrimestre: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(+total4) : '0,00'
    }
    listaExportar.push(linha_total_garantias)

    let totalRCL0 = 0;
    let totalRCL1 = 0;
    let totalRCL2 = 0;
    let totalRCL3 = 0;

    if (this.mes <= 4) {
      totalRCL0 = await this.anexoFederalServico.obterAnexo3Resuldado(0, this.exercicio.id, orgaos).toPromise();
      totalRCL1 = await this.anexoFederalServico.obterAnexo3Resuldado(this.mes, this.exercicio.id, orgaos).toPromise();
    }
    if (this.mes >= 5 && this.mes <= 8) {
      totalRCL2 = await this.anexoFederalServico.obterAnexo3Resuldado(this.mes, this.exercicio.id, orgaos).toPromise();
    }
    if (this.mes >= 9 && this.mes <= 12) {
      totalRCL3 = await this.anexoFederalServico.obterAnexo3Resuldado(this.mes, this.exercicio.id, orgaos).toPromise();
    }

    const linha_receita_corrente_liquida = {
      titulo: 'RECEITA CORRENTE LÍQUIDA - RCL (VI)',
      saldo_anterior: this.funcaoService.convertToBrNumber(totalRCL0),
      primeiro_quadrimestre: this.funcaoService.convertToBrNumber(totalRCL1),
      segundo_quadrimestre: this.funcaoService.convertToBrNumber(totalRCL2),
      terceiro_quadrimestre: this.funcaoService.convertToBrNumber(totalRCL3)
    }
    listaExportar.push(linha_receita_corrente_liquida)

    const linha_transferencia_individual = {
      titulo: '(-) Transferências obrigatórias da União relativas às emendas individuais  (art. 166-A, § 1º,  da CF)  (VII)',
      saldo_anterior: this.funcaoService.convertToBrNumber(0),
      primeiro_quadrimestre: this.funcaoService.convertToBrNumber(0),
      segundo_quadrimestre: this.funcaoService.convertToBrNumber(0),
      terceiro_quadrimestre: this.funcaoService.convertToBrNumber(0)
    }
    listaExportar.push(linha_transferencia_individual)

    const linha_receita_ajustada = {
      titulo: 'RECEITA CORRENTE LÍQUIDA AJUSTADA PARA CÁLCULO DOS LIMITES DE ENDIVIDAMENTO (VIII) = (VI - VII)',
      saldo_anterior: this.funcaoService.convertToBrNumber(totalRCL0),
      primeiro_quadrimestre: this.funcaoService.convertToBrNumber(totalRCL1),
      segundo_quadrimestre: this.funcaoService.convertToBrNumber(totalRCL2),
      terceiro_quadrimestre: this.funcaoService.convertToBrNumber(totalRCL3),
    }
    listaExportar.push(linha_receita_ajustada)

    const linha_porcentagem_dc_rlc_5_8 = {
      titulo: '% do TOTAL DAS GARANTIAS sobre a RCL AJUSTADA (V/VIII)',
      saldo_anterior: this.funcaoService.convertToBrNumber(+total1 / +totalRCL0),
      primeiro_quadrimestre: this.funcaoService.convertToBrNumber(+total2 / +totalRCL1),
      segundo_quadrimestre: this.funcaoService.convertToBrNumber(+total3 / +totalRCL2),
      terceiro_quadrimestre: this.funcaoService.convertToBrNumber(+total4 / +totalRCL3)
    }
    listaExportar.push(linha_porcentagem_dc_rlc_5_8)

    const linha_limite_senado = {
      titulo: 'LIMITE DEFINIDO POR RESOLUÇÃO DO SENADO FEDERAL - <%>',
      saldo_anterior: this.funcaoService.convertToBrNumber(0),
      primeiro_quadrimestre: this.funcaoService.convertToBrNumber(0),
      segundo_quadrimestre: this.funcaoService.convertToBrNumber(0),
      terceiro_quadrimestre: this.funcaoService.convertToBrNumber(0)
    }
    listaExportar.push(linha_limite_senado)

    const linha_limite_alerta = {
      titulo: 'LIMITE DE ALERTA (inciso III do §1º do art. 59 da LRF) - <%>',
      saldo_anterior: this.funcaoService.convertToBrNumber(0),
      primeiro_quadrimestre: this.funcaoService.convertToBrNumber(0),
      segundo_quadrimestre: this.funcaoService.convertToBrNumber(0),
      terceiro_quadrimestre: this.funcaoService.convertToBrNumber(0)
    }
    listaExportar.push(linha_limite_alerta)

    this.outrasGarantiasCsv(lista[1], listaExportar);

    return listaExportar
  }

  private outrasGarantiasCsv(dados: [], listaExportar: any) {
    const linha_titulo_outros_valores = {
      titulo: 'CONTRAGARANTIAS RECEBIDAS',
      saldo_anterior: 'SALDO EXERCÍCIO ANTERIOR',
      primeiro_quadrimestre: `SALDO DO EXERCÍCIO DE ${this.exercicio.ano}`,
      segundo_quadrimestre: '',
      terceiro_quadrimestre: '',
    }
    listaExportar.push(linha_titulo_outros_valores)

    const linha_subtitulo_outros_valores = {
      titulo: '',
      saldo_anterior: '',
      primeiro_quadrimestre: 'Até o 1º Quadrimestre',
      segundo_quadrimestre: 'Até o 2º Quadrimestre',
      terceiro_quadrimestre: 'Até o 3º Quadrimestre',
    }
    listaExportar.push(linha_subtitulo_outros_valores)

    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;

    const titulos2 = this.funcaoService.agrupar(dados, ['titulo', 'somador'], ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);

    for (const titulo of titulos2) {
      const primeira_linha = {
        titulo: titulo.grupo['titulo'],
        saldo_anterior: this.funcaoService.convertToBrNumber(titulo.totalizadores['saldo_anterior']),
        primeiro_quadrimestre: this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre1']),
        segundo_quadrimestre: this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre2']),
        terceiro_quadrimestre: this.funcaoService.convertToBrNumber(titulo.totalizadores['quadrimestre3'])
      }
      listaExportar.push(primeira_linha)

      total1 += titulo.grupo['somador'] ? +titulo.totalizadores['saldo_anterior'] : +titulo.totalizadores['saldo_anterior'] * -1;
      total2 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre1'] : +titulo.totalizadores['quadrimestre1'] * -1;
      total3 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre2'] : +titulo.totalizadores['quadrimestre2'] * -1;
      total4 += titulo.grupo['somador'] ? +titulo.totalizadores['quadrimestre3'] : +titulo.totalizadores['quadrimestre3'] * -1;

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['saldo_anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          const linha_grupo = {
            titulo: grupo.grupo,
            saldo_anterior: this.funcaoService.convertToBrNumber(grupo.totalizadores['saldo_anterior']),
            primeiro_quadrimestre: this.mes >= 1 && this.mes <= 4 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre1']) : '0,00',
            segundo_quadrimestre: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre2']) : '0,00',
            terceiro_quadrimestre: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre3']) : '0,00'
          }
          listaExportar.push(linha_grupo)
        }

        for (const item of grupo.registros) {
          if (item.nome != '') {
            const linha_item = {
              titulo: item.nome,
              saldo_anterior: this.funcaoService.convertToBrNumber(item.saldo_anterior),
              primeiro_quadrimestre: this.mes >= 1 && this.mes <= 4 ? this.funcaoService.convertToBrNumber(item.quadrimestre1) : '0,00',
              segundo_quadrimestre: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(item.quadrimestre2) : '0,00',
              terceiro_quadrimestre: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(item.quadrimestre3) : '0,00',
            }
            listaExportar.push(linha_item)
          }
        }
      }
    }

    const linha_total_contragarantias = {
      titulo: 'TOTAL CONTRAGARANTIAS RECEBIDAS (XI) = (VII + VIII + IX + X)',
      saldo_anterior: this.funcaoService.convertToBrNumber(+total1),
      primeiro_quadrimestre: this.mes >= 1 && this.mes <= 4 ? this.funcaoService.convertToBrNumber(+total2) : '0,00',
      segundo_quadrimestre: this.mes >= 5 && this.mes <= 8 ? this.funcaoService.convertToBrNumber(+total3) : '0,00',
      terceiro_quadrimestre: this.mes >= 9 && this.mes <= 12 ? this.funcaoService.convertToBrNumber(+total4) : '0,00'
    }
    listaExportar.push(linha_total_contragarantias)

    const linha_medidas_corretivas = {
      titulo: 'MEDIDAS CORRETIVAS:',
      saldo_anterior: this.funcaoService.convertToBrNumber(0),
      primeiro_quadrimestre: this.funcaoService.convertToBrNumber(+0),
      segundo_quadrimestre: this.funcaoService.convertToBrNumber(+0),
      terceiro_quadrimestre: this.funcaoService.convertToBrNumber(+0)
    }
    listaExportar.push(linha_medidas_corretivas)
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'DÍVIDA CONSOLIDADA', coluna: 'titulo' },
      { titulo: 'SALDO EXERCÍCIO ANTERIOR', coluna: 'saldo_anterior' },
      { titulo: `SALDO DO EXERCÍCIO DE ${this.exercicio.ano}`, coluna: 'primeiro_quadrimestre' },
      { titulo: '', coluna: 'segundo_quadrimestre' },
      { titulo: ' ', coluna: 'terceiro_quadrimestre' },
    ];
    return colunasDefault;
  }

}
