import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { ContratoService } from "administrativo-lib";
import { BaseResourceRptComponent, Coluna, Contrato, EddyAutoComplete, GlobalService, LicitacaoPipe } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";
import * as toastr from 'toastr';

@Component({
  selector: 'app-contrato-extrato-rpt-dlg',
  templateUrl: './contrato-extrato-rpt-dlg.component.html'
})
export class ContratoExtratoRptDlgComponent extends BaseResourceRptComponent {

  protected datepipe: DatePipe;
  public ptBR: any;
  public dataInicio: Date;
  public dataFinal: Date;
  public contrato: Contrato;
  public contratoAutoComplete: EddyAutoComplete<Contrato>;
  public tipoRegistro: '*' | 'CONTRATO' | 'REGISTRO_PRECO';
  public listTipoRegistro: { id: '*' | 'CONTRATO' | 'REGISTRO_PRECO', nome: string }[];

  constructor(
    private globalService: GlobalService,
    private contratoService: ContratoService
  ) {
    super();
  }

  protected afterInit(): void {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.dataInicio = new Date(new Date().getFullYear(), 0, 1)
    this.dataFinal = new Date();

    this.listTipoRegistro = [
      { id: '*', nome: 'Todos' },
      { id: 'CONTRATO', nome: 'Contratos' },
      { id: 'REGISTRO_PRECO', nome: 'Registros de Preço' },
    ]
    this.tipoRegistro = '*'

    this.contratoAutoComplete = new EddyAutoComplete(null, this.contratoService,
      'id', ['numero', 'favorecido.nome'], {
      orgao_id: this.login.orgao.id, relations: 'favorecido',
      orderBy: 'favorecido.nome'
    }, { number: ['numero'], text: ['numero', 'favorecido.nome'] });
  }

  protected tituloRelatorio(): string {
    return `${this.login.orgao.nome} - Extrato de Contratos`;
  }

  protected subTituloRelatorio(): string {
    const retorno = `Período de ${this.funcaoService.converteDataBR(this.dataInicio)} à ${this.funcaoService.converteDataBR(this.dataFinal)}`
    if (this.contrato)
      return `Contrato: ${new LicitacaoPipe().transform(this.contrato.numero)} - ` + retorno;
    else
      return retorno
  }

  protected obterColunasRelatorio(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Contrato', coluna: 'contrato', agrupar: true, bold: true });
    retorno.push({ titulo: 'Documento Liq.', coluna: 'liquidacao_documento' });
    retorno.push({ titulo: 'Documento Pagto.', coluna: 'pagamento_documento' });
    retorno.push({ titulo: 'Data Liquidação', coluna: 'liquidacao_data' });
    retorno.push({ titulo: 'Data Pagamento', coluna: 'pagamento_data' });
    retorno.push({ titulo: 'Valor Liquidação', coluna: 'liquidacao_valor', decimais: 2, alignment: 'right' });
    retorno.push({ titulo: 'Valor Pagamento', coluna: 'pagamento_valor', decimais: 2, alignment: 'right' });

    return retorno;
  }

  protected larguraColunas(): (string | number)[] {
    return ['*', '*', 70, 70, 70, 70];
  }

  protected totalizarColunas(): (string | {})[] {
    return [{ nome: 'liquidacao_valor', abaixo: true }, { nome: 'pagamento_valor', abaixo: true }];
  }

  protected carregarLista(): Promise<any[]> {
    if (!this.dataInicio || !this.dataFinal) {
      toastr.warning('Informe o período do relatório')
      return;
    }
    return new Promise((resolve) => {
      this.contratoService.relatorioExtrato(
        this.login.orgao.id, this.datepipe.transform(this.dataInicio, 'yyyy-MM-dd'), this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd'), this.contrato?.id, this.tipoRegistro
      ).pipe(takeUntil(this.unsubscribe)).subscribe(async (res) => {
        const lista: {
          contrato: string,
          liquidacao_documento: string, pagamento_documento: string,
          liquidacao_data: string, pagamento_data: string,
          liquidacao_valor: number, pagamento_valor: number,
        }[] = []

        for (const item of res)
          lista.push({
            contrato: `${new LicitacaoPipe().transform(item['contrato'])}: ${item['fornecedor']} - ${this.funcaoService.convertToBrNumber(item['valor_contrato'])}`,
            liquidacao_documento: item['liquidacao_documento'], pagamento_documento: item['pagamento_documento'],
            liquidacao_data: item['liquidacao_data'], pagamento_data: item['pagamento_data'],
            liquidacao_valor: +item['liquidacao_valor'], pagamento_valor: +item['pagamento_valor'],
          })

        resolve(lista)
      });
    })
  }

}