import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BaseResourceFormComponent, FuncaoService, GlobalService, LoginContabil, Prazo,
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { PrazoService } from '../service/prazo.service';

@Component({
  selector: 'lib-prazo-form',
  templateUrl: './prazo-form.component.html'
})
export class PrazoFormComponent extends BaseResourceFormComponent<Prazo, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @ViewChild('codigo_') inputField: ElementRef;

  public mes: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected prazoService: PrazoService,
  ) {
    super(new Prazo(), injector, Prazo.converteJson, prazoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      nome: [null, [Validators.required]],
      entrega: [false],
      pagamento: [false],
      orgao: [this.login.orgao]
    });
  }

  protected parametrosExtras(): {} {
    return {
      'orgao.id': this.login.orgao.id
    };
  }

  protected afterLoad() { }

  protected afterInit(): void {
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected beforeSubmit() { }

  protected afterSubmit(modelo: Prazo) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
}
