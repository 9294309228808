import { Component, OnInit, OnDestroy } from '@angular/core';
import { ExercicioService } from '../service/exercicio.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Login } from '../../entidade/login/login';
import { Exercicio } from '../../entidade/comum/exercicio.model';
import { FuncaoService } from '../../util/funcao.service';
import { GlobalService } from '../../util/global.service';
import { PpaService } from '../../login/servico/ppa.service';

@Component({
  selector: 'lib-trocar-exercicio',
  templateUrl: './trocar-exercicio.component.html'
})
export class TrocarExercicioComponent implements OnInit, OnDestroy {

  public login: Login = new Login();
  public exercicio: Exercicio = new Exercicio();
  public listaExercicios: Array<any>;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected router: Router,
    protected funcaoService: FuncaoService,
    private exercicioService: ExercicioService,
    private ppaService: PpaService,
  ) { }

  ngOnInit() {
    this.login = GlobalService.obterSessaoLogin();
    if (this.login) {
      this.exercicio = this.login.exercicio;
      this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
        .subscribe(dados => {
          this.listaExercicios = dados.content;
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  enviar() {
    this.login.exercicio = this.exercicio;
    this.ppaService.obterPorAno(this.login.exercicio.ano, this.login.cidade.id).subscribe((res) => {
      this.login['ppa'] = res;
      reloadSession();
      GlobalService.salvarSessaoLogin(this.login);
      location.reload();
    });
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

}

export const reloadSession = () => {
  // this.login.exercicio = this.exercicio;
  sessionStorage.setItem('listaco4', '');
  sessionStorage.setItem('listaco5', '');
  sessionStorage.setItem('lista1', '');
  sessionStorage.setItem('listaco3', '');
  sessionStorage.setItem('listaco2', '');
  sessionStorage.setItem('totalArrecadadoco', '');
  sessionStorage.setItem('totalEmpenhadoco', '');
  sessionStorage.setItem('totalLiquidadoco', '');
  sessionStorage.setItem('totalPagoco', '');
  // GlobalService.salvarSessaoLogin(this.login);
  location.reload();
}
