import { Component, ElementRef, Injector, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BaseResourceListComponent, Coluna, ConservacaoPatrimonio, DateFormatPipe, EddyAutoComplete, Filtro, FormatoExportacao, FuncaoService, GlobalService,
  InventarioSetor, InventarioSetorStorage, InventarioTombo, InventarioTransferencia, LoginContabil, ParametroPatrimonioService,
  Relatorio, Setor, SetorLocalizacao, SetorLocalizacaoService, SetorProcessoService, SetorService, SituacaoPatrimonio, Tombamento,
  TombamentoHistorico
} from 'eddydata-lib';
import { Observable, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { InventarioSetorService } from '../service/inventario-setor.service';
import { InventarioStorageService } from '../service/inventario-storage.service';
import { InventarioTomboService } from '../service/inventario-tombo.service';
import { InventarioTransferenciaService } from '../service/inventario-transferencia.service';

declare var $: any;

@Component({
  selector: 'app-inventario-termo',
  templateUrl: './inventario-termo.component.html'
})
export class InventarioTermoComponent extends BaseResourceListComponent<InventarioTombo, LoginContabil> implements OnDestroy {

  @ViewChild('buscaPlaca_') inputField: ElementRef;

  public inventarioSetor: InventarioSetor;
  public listaInventarioSetor: InventarioSetor[];
  public tipoFiltro: 'P' | 'N' | 'I' = undefined;
  public ordem: 'P' | 'T' | 'L' = 'P';
  public marcados: number[] = [];
  public desmarcados: number[] = [];
  public buscaPlaca: number;
  public listaPlaca: InventarioTombo[];
  public listaArquivos: InventarioSetorStorage[];
  public resultado: Tombamento;
  public count: number = 0;
  public setorItem: InventarioTombo;
  public itemAtual: InventarioTombo;
  public itemAtualTemp: InventarioTombo;
  public listaLocalizacao: SetorLocalizacao[];
  public listaSituacao: { id: SituacaoPatrimonio, nome: string, estilo: string }[];
  public listaConservacao: { id: ConservacaoPatrimonio, nome: string, estilo: string }[];
  public processos: string = '';
  public tombamentoId: number;
  public destinoAutoComplete: EddyAutoComplete<Setor>

  public setor: Setor;

  public totalizador: {
    bens: number, localizados: number, pendentes: number, transferencias: number, transferenciasPend: number, recebimentosPend: number,
    termoNovo: number, transferidosNovo: number, recebidosNovo: number, solicitadosNovo: number, deprecTransf: number, deprec_Naoloc: number, naoLocalizados: number
  } = { bens: 0, localizados: 0, pendentes: 0, transferencias: 0, transferenciasPend: 0, recebimentosPend: 0, termoNovo: 0, transferidosNovo: 0, recebidosNovo: 0, solicitadosNovo: 0, deprecTransf: 0, deprec_Naoloc: 0, naoLocalizados: 0 }

  public listaTransferido: InventarioTransferencia[];
  public imaskQtd = {
    mask: Number,
    scale: 0,
    signed: false
  };

  public tipo: string = '';
  public filtroSelecionado: string = '';
  private listaStatus = [
    { id: 'PENDENTE', nome: 'Pendente' },
    { id: 'ACEITO', nome: 'Aceito' },
    { id: 'RECUSADO', nome: 'Recusado' },
    { id: 'CANCELADO', nome: 'Cancelado' },
  ];

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected injector: Injector,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    public funcaoService: FuncaoService,
    private localizacaoService: SetorLocalizacaoService,
    private setorProcessoService: SetorProcessoService,
    protected storageService: InventarioStorageService,
    private parametroPatrimonioService: ParametroPatrimonioService,
    private transferenciaService: InventarioTransferenciaService,
    private inventarioSetorService: InventarioSetorService,
    private inventarioTomboService: InventarioTomboService,
    private destinoService: SetorService
  ) {
    super(inventarioTomboService, injector);
    this.limite = 10;
  }

  protected relations(): string {
    return 'tombamento.produto,localizacao,inventario_setor.setor.processos,manutencao_destino';
  }

  protected condicoesGrid(): {} {
    const parametros = {
      'inventario_setor.id': this.inventarioSetor?.id
    };

    if (this.filtro) {
      parametros['OR'] = `tombamento.produto.nome$like=${this.filtro}%;!;!;localizacao.localizacao$like=${this.filtro}%;!;!;tombamento.placa$like=%${this.filtro}`
    }

    if (this.tipoFiltro) {
      if (this.tipoFiltro === 'P') {
        parametros['localizado'] = false;
        parametros['situacao_atual'] = 'ATIVO';
      } else if (this.tipoFiltro === 'N')
        parametros['situacao_atual'] = 'NAO_LOCALIZADO';
      else if (this.tipoFiltro === 'I')
        parametros['conservacao_atual'] = 'INSERVIVEL';
    }

    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    if (this.ordem === 'P')
      return ['tombamento.placa'];
    else if (this.ordem === 'T')
      return ['tombamento.produto.nome', 'tombamento.placa'];
    else if (this.ordem === 'L')
      return ['localizacao.localizacao', 'tombamento.placa'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['tombamento.placa'],
      text: ['tombamento.produto.nome', 'localizacao.localizacao'],
    };
  }

  protected afterInit(): void {

    this.setor = this.login.setor.find(setor => {
      return setor.id === this.inventarioSetor?.setor.id
    })
    this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      const inventarioId: number = +params['inventario'];
      const parametro = { 'inventario.id': inventarioId, relations: 'usuario_finalizacao,setor', orderBy: 'setor.nome' };
      if (!this.administrador()) {
        parametro['setor.id'] = this.login.usuario.setor.id;
      }
      this.inventarioSetorService.filtrar(0, 0, parametro).pipe(takeUntil(this.unsubscribe)).subscribe(async res => {
        this.listaInventarioSetor = res.content;

        // atribui inventario setor padrão para o setor do usuário logado
        this.inventarioSetor = this.listaInventarioSetor.find(ivs => ivs.setor.id === this.login.usuario.setor.id);

        // carrega listas
        await this.carregarListas();
      });
    });

    this.listaSituacao = this.globalService.obterSituacaoPatrimonio(true);
    this.listaConservacao = this.globalService.obterConservacaoPatrimonio();
    this.listaPlaca = [];

    this.destinoAutoComplete = new EddyAutoComplete(null, this.destinoService, 'id', ['codigo', 'nome'], { orderBy: 'nome', relations: 'orgao', 'orgao.id': this.login.orgao.id, ativo: true, manutencao_patrimonio: true, setor_patrimonio: true }, { text: ['codigo', 'nome'] });
  }

  public preencherTabelas() {
    this.storageService.filtrar(1, -1, { 'inventario_setor.id': this.inventarioSetor.id, orderBy: 'data_cadastro$DESC' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaArquivos = res.content;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected acaoRemover(model: InventarioTombo): Observable<InventarioTombo> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    if (this.tipo) {
      return [
        { titulo: 'Bem Patrimonial', coluna: 'bem' },
        { titulo: (this.tipo === 'T' ? 'Destino' : this.tipo === 'R' ? 'Origem' : 'Destino/Origem'), coluna: 'destino_origem' },
        { titulo: 'Motivo', coluna: 'motivo' },
        { titulo: 'Data', coluna: 'data_cadastro' },
        { titulo: 'Status', coluna: 'status' }
      ];
    }

    return [
      { titulo: 'Placa Nº', coluna: 'tombamento.placa' },
      { titulo: 'Bem Patrimonial', coluna: 'tombamento.produto.nome' },
      { titulo: 'Localização', coluna: 'localizacao.localizacao' },
      { titulo: 'Situação', coluna: 'situacao' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    if (this.tipo) {
      const parametros = { orderBy: 'data_cadastro$DESC' };

      if (this.tipo === 'T') {
        if (this.filtroSelecionado) parametros['status'] = this.filtroSelecionado;
        parametros['origem_id'] = this.inventarioSetor.id;
        parametros['relations'] = 'tombamento.produto,destino.setor,usuario_status';
        parametros['solicitacao'] = false;
      } else if (this.tipo === 'R') {
        parametros['destino_id'] = this.inventarioSetor.id;
        parametros['relations'] = 'tombamento.produto,origem.setor,usuario_status';
        parametros['solicitacao'] = false;
      } else if (this.tipo === 'S') {
        parametros['OR'] = `origem_id=${this.inventarioSetor.id};!;!;destino_id=${this.inventarioSetor.id}`;
        parametros['relations'] = 'tombamento.produto,origem.setor,destino.setor,usuario_status';
        parametros['solicitacao'] = true;
      }

      this.transferenciaService.filtrar(0, 0, parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((lista) => {
          lista.content.map(t => {
            t.bem = `${t.tombamento?.placa} - ${t.tombamento?.produto?.nome}`;
            t.data_cadastro = this.funcaoService.converteDataBR(t.data_cadastro);
            t.status = this.funcaoService.obterNomePorChave(this.listaStatus, t.status);
            if (this.tipo === 'S') {
              t.destino_origem = `${t.origem?.setor?.id} - ${t.origem?.setor?.nome} ${t.destino?.setor?.id} - ${t.destino?.setor?.nome}`;
            } else {
              t.destino_origem = this.tipo === 'T' ? t.destino?.setor?.nome : this.tipo === 'R' ? t.origem?.setor?.nome : '';
            }
          });
          if (formato === 'pdf') {
            this.imprimir(`INVENTÁRIO - ${this.inventarioSetor?.inventario?.descricao}\n${this.inventarioSetor?.setor?.nome} - ${this.inventarioSetor?.setor?.id}`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Inventário', ['*', '*', 'auto', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        });
    } else {
      const parametros = this.obterParametros();

      this.inventarioTomboService.filtrar(1, -1, parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((lista) => {
          lista.content.map(i => {
            if (i.situacao_atual === 'ATIVO') {
              i.situacao = this.funcaoService.obterNomePorChave(this.listaConservacao, i.conservacao_atual);
            } else {
              i.situacao = this.funcaoService.obterNomePorChave(this.listaSituacao, i.situacao_atual);
            }
          });
          if (formato === 'pdf') {
            this.imprimir(`INVENTÁRIO - ${this.inventarioSetor?.inventario?.descricao}\n${this.inventarioSetor?.setor?.nome} - ${this.inventarioSetor?.setor?.id}`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Inventário', ['auto', '*', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
          () => alert("erro ao retornar lista")
        );
    }
  }

  public tipoTransferencia(tipo: string) {
    this.tipo = tipo;
  }

  public filtroTransferencia(filtro: string) {
    // Filtro selecionado na Opção de filtro em transferidos
    this.filtroSelecionado = filtro;
  }

  public obterParametros() {
    let parametros = {};
    parametros['tombamento.orgao.id'] = this.login.orgao.id;
    parametros['inventario_setor.id'] = this.inventarioSetor?.id;
    parametros['relations'] = "tombamento,tombamento.orgao,tombamento.produto,tombamento.favorecido,localizacao,inventario_setor.setor.processos,inventario_setor.setor,inventario_setor,tombamento.movimento_item,tombamento.setor.responsavel,manutencao_destino";

    parametros['orderBy'] = this.ordenacaoGrid();
    parametros = Object.assign({}, parametros, this.condicoesGrid());

    return parametros;
  }


  public async montarRelatorio() {
    const parametros = this.obterParametros();
    await this.inventarioTomboService.filtrar(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        Relatorio.imprimirPersonalizado(
          'RECIBO DE ENTREGA DE INVENTÁRIO ANUAL DE BENS PATRIMONIAIS'
          , this.login.usuario.nome,
          this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          this.conteudo(dados.content),
          'portrait', 'Inventario',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private conteudo(dados: any[]): {}[] {
    if (dados.length === 0) {
      return;
    }
    // monta relatório
    const registros = [];
    let i = 0;
    for (const item of dados) {
      for (i; i < 1; i++) {
        i = 1;
        registros.push({
          text: '', alignment: 'center',
          bold: true, fontSize: 10
        });

        registros.push({ canvas: [{ type: 'line', x1: 1, y1: 1, x2: 530, y2: 1, lineWidth: 0.5 }] });
        registros.push({ columns: [{ text: 'SECRETARIA MUNICIPAL DE ADMINISTRAÇÃO E RECURSOS HUMANOS' }], margin: [0, 2] });
        registros.push({ text: 'INVENTÁRIO ANUAL ', margin: [0, 2] }, 'Exercício: ' + this.login.exercicio.ano);
        registros.push({ columns: [{ text: '' }], margin: [0, 5], alignment: 'center' });

        registros.push({ canvas: [{ type: 'line', x1: 1, y1: 1, x2: 530, y2: 1, lineWidth: 0.5 }] });
        registros.push({ text: 'INDENTIFICAÇÃO DO DECLARANTE', margin: [0, 5], bold: true });
        registros.push({ text: '', margin: [0, 5] });

        registros.push({
          columns: [{ text: 'NOME DO DECLARANTE: ', width: 40, fontSize: 6, margin: [0, 0] },]
        });
        registros.push({ text: [item.tombamento.setor.responsavel.nome], margin: [0, 0] });
        registros.push({ text: [''], margin: [0, 5] });
        registros.push({
          columns: [{ text: 'CPF: ', width: 40, fontSize: 6, margin: [0, 0] }]
        });
        registros.push({ text: [(item.tombamento.setor.responsavel.cpf_cnpj ? item.tombamento.setor.responsavel.cpf_cnpj : '-')], margin: [0, 0] });
        registros.push({ text: [''], margin: [0, 5] });
        registros.push({
          columns: [{ text: 'TELEFONE: ', width: 40, fontSize: 6, margin: [0, 0] }]
        });
        registros.push({ text: [(item.tombamento.setor.responsavel.telefone ? item.tombamento.setor.responsavel.telefone : item.inventario_setor.setor.telefone)], margin: [0, 0] });
        registros.push({ text: [''], margin: [0, 5] });

        registros.push({
          columns: [{ text: 'CARGO: ', width: 40, fontSize: 6, margin: [0, 0] }]
        });
        registros.push({ text: [(item.tombamento.setor.responsavel.cargo ? item.tombamento.setor.responsavel.cargo : item.inventario_setor.setor.cargo)], margin: [0, 0] });
        registros.push({ text: [''], margin: [0, 5] });

        registros.push({
          columns: [{ text: 'SETOR: ', width: 40, fontSize: 6, margin: [0, 0] }]
        });
        registros.push({ text: [item.inventario_setor.setor.nome], margin: [0, 0] });
        registros.push({ text: [''], margin: [0, 5] });

        registros.push({
          columns: [{ text: 'RESUMO DO INVENTÁRIO ' + this.login.exercicio.ano, bold: true, alignment: 'center', fontSize: 10 }], margin: [0, 5]
        });
        registros.push({ canvas: [{ type: 'line', x1: 1, y1: 1, x2: 530, y2: 1, lineWidth: 0.5 }] });
        registros.push({ text: '', margin: [0, 8, 5, 0] });

        this.aposPreencher(); // totaliza os conteudos dos bens

        registros.push({ columns: [{ text: 'Total de bens incorporados: ', width: 150 }, this.totalizador.bens, { text: 'Valores depreciados ', width: 100 }, (item.tombamento.valor_depreciado ? item.tombamento.valor_depreciado : '0,00')], margin: [0, 5] });

        registros.push({ columns: [{ text: 'Total de bens não localizados ', width: 150 }, this.totalizador.naoLocalizados, { text: 'Valores depreciados ', width: 100 }, (item.tombamento.deprec_Naoloc ? item.tombamento.deprec_Naoloc : '0,00')], margin: [0, 5] });

        registros.push({ columns: [{ text: 'Total de solicitações de transferências ', width: 150 }, this.totalizador.solicitadosNovo, { text: 'Valores depreciados ', width: 100 }, (item.tombamento.deprecTransf ? item.tombamento.deprecTransf : '0,00')], margin: [0, 5] });

        registros.push({ columns: [{ text: 'Total de bens localizados: ', width: 150 }, this.totalizador.localizados], margin: [0, 5] });


        registros.push({ columns: [{ text: 'Total de transferências pendentes: ', width: 150 }, this.totalizador.transferenciasPend], margin: [0, 5] });
        registros.push({ canvas: [{ type: 'line', x1: 1, y1: 1, x2: 530, y2: 1, lineWidth: 0.5 }], margin: [0, 5] });
        registros.push([
          {
            text: 'Sr(a) ' + item.tombamento.setor.responsavel.nome + ' inscrito no CPF sob o ' + (item.tombamento.setor.responsavel.cpf_cnpj ? item.tombamento.setor.responsavel.cpf_cnpj : '- ') + ',', fontSize: 8, margin: [0, 2]
          },
          {
            text: 'Responsável pelo setor ' + item.inventario_setor.setor.nome + ' o seu inventário foi recebido com sucesso.',
            fontSize: 8, margin: [0, 5]
          }
        ]);

        registros.push({ canvas: [{ type: 'line', x1: 1, y1: 1, x2: 530, y2: 1, lineWidth: 0.5 }] });
        registros.push([
          {
            text: 'INFORMAÇÕES SOBRE O INVENTÁRIO DE BENS PATRIMONIAIS: ', fontSize: 8, alignment: 'center', margin: [0, 8]
          },
          {
            text: 'Decreto 8962/2007 prevê em seu Art. 6o - O controle de equipamentos e bens móveis far-se-á através de inventário obrigatório ou a qualquer tempo em que o Setor de Patrimônio julgar necessário.',
            fontSize: 8, margin: [0, 5]
          },
          {
            text: '* Observação: Sempre que houver substituição temporária ou permanente dos responsáveis pelo setor é necessário que os bens constantes no Responsabilidade sejam inventariados.',
            fontSize: 8, margin: [0, 10]
          },
        ]);
      }
    }

    return registros;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.atualizarAuto();
  }

  public async preencherGrid() {
    if (!this.inventarioSetor) return;

    if (this.marcados?.length > 0 || this.desmarcados?.length > 0)
      await this.salvarLocalizados();
    super.preencherGrid();
    this.preencherTabelas();
  }

  public aposPreencher() {
    if (this.inventarioSetor) {
      // atualiza termo
      this.carregarTotalizador();
      this.atualizarTermo();
    }
  }

  atualizarAuto() {
    timer(30000).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.preencherGrid();
    });
  }

  atualizarTermo() {
    this.inventarioSetorService.atualizarTermo(this.inventarioSetor.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.carregarTotalizador(true);
      }, error => toastr.error(error));
  }

  carregarTotalizador(ocultarLoad?: boolean) {
    this.inventarioSetorService.totalizarTermo(this.inventarioSetor.id, ocultarLoad).subscribe(res => {
      if (res) {
        this.totalizador = {
          bens: +res['total'], localizados: +res['localizados'], pendentes: +res['pendentes'], transferencias: +res['transf'], transferenciasPend: +res['transf_pend'], recebimentosPend: +res['receb_pend'],
          termoNovo: +res['novo_termo'], transferidosNovo: +res['novo_transf'], recebidosNovo: +res['novo_transf_r'], solicitadosNovo: +res['novo_transf_s'], deprecTransf: +res['deprec_transf'], deprec_Naoloc: +res['deprec_Naoloc'], naoLocalizados: +res['nao_localizados']
        }
      }
    }, error => {
      toastr.error(error.error.payload)
    });
  }

  public filtrar() {
    this.paginaCorrente = 1;
    this.preencherGrid();
  }

  public async carregarListas() {
    this.setor = this.login.setor.find(setor => {
      return this.inventarioSetor?.setor.id === setor.id
    })
    if (!this.inventarioSetor || !this.inventarioSetor.setor) return
    this.preencherGrid();

    // carrega localizações do setor
    this.localizacaoService.filtrar(0, 0, { setor_id: this.inventarioSetor.setor.id, orderBy: 'localizacao' }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaLocalizacao = res.content
      }, error => toastr.error(error));

    // carrega lista de processos
    this.setorProcessoService.filtrar(0, 0, {
      'setor.id': this.inventarioSetor.setor.id, 'baixado': false, relations: 'setor'
    }).pipe(takeUntil(this.unsubscribe)).subscribe(async res => {
      res.content.map(element => { this.processos += element.processo + ', ' });
      this.processos = this.processos.substring(0, this.processos.length - 2);
    });
  }

  public toggleLocalizado(item: InventarioTombo) {
    if (!item) return;

    if (!item.localizado) { // estava desmarcado e marcou
      if (this.desmarcados.includes(item.id)) this.desmarcados.splice(this.desmarcados.indexOf(item.id), 1);
      this.marcados.push(item.id)
      this.totalizador.localizados++;
      this.totalizador.pendentes--;
    } else {
      if (this.marcados.includes(item.id)) this.marcados.splice(this.marcados.indexOf(item.id), 1);
      this.desmarcados.push(item.id)
      this.totalizador.localizados--;
      this.totalizador.pendentes++;
    }
  }

  public async salvarLocalizados() {

    if (this.inventarioSetor.data_inicio === null)
      this.inventarioSetor.data_inicio = new DateFormatPipe().transform(new Date(), ['datetime']);

    if (this.marcados.length === 0 && this.desmarcados.length === 0) return

    await new Promise((resolve, reject) => {
      this.inventarioSetorService
        .salvarLocalizados(this.inventarioSetor, this.marcados, this.desmarcados, this.login.usuario.id).subscribe(res => {
          this.marcados = []
          this.desmarcados = []
          resolve(true)
        }, error => {
          toastr.error(error.error.payload)
          reject(error)
        });
    });
  }

  public abrirAlterarItem(item: InventarioTombo) {
    if (!item) return;
    this.itemAtual = item;
    this.itemAtualTemp = Object.assign({}, this.itemAtual);
    $('#dialogInventarioAlterarItem').modal('show');
  }

  public async confirmarAlterarItem() {
    if (this.itemAtual && this.itemAtualTemp) {
      this.itemAtualTemp.usuario = this.login.usuario;
      if (this.itemAtualTemp.situacao_atual === 'MANUTENCAO' && !this.itemAtualTemp.manutencao_destino) {
        toastr.warning('Informe o setor de destino da manutenção para prosseguir!')
        return
      }
      await new Promise((resolve, reject) => {
        this.inventarioTomboService.atualizar(this.itemAtualTemp).subscribe(res => {
          this.itemAtual.localizacao = res.localizacao;
          this.itemAtual.situacao_atual = res.situacao_atual;
          this.itemAtual.conservacao_atual = res.conservacao_atual;
          resolve(true)
        }, error => {
          toastr.error(error.error.payload)
          reject(error)
        });
      });

      await this.salvarLocalizados();
    }

    this.itemAtualTemp = undefined;
    $('#dialogInventarioAlterarItem').modal('hide');
  }

  public abrirTransferencia(item: InventarioTombo) {
    if (!item) return;
    this.itemAtual = item;
    $('#dialogInventarioTransferencia').modal('show');
  }

  public abrirHistorico(item: TombamentoHistorico) {
    if (!item) return;
    this.tombamentoId = item.tombamento.id;
    $('#dlgBemMovelHistorico').modal('show');
  }

  public retornoTransferencia(retorno: boolean) {
    if (!retorno || !this.itemAtual) return;

    this.inventarioSetor.data_alteracao = new Date();
    this.preencherGrid();
  }

  public abrirBuscaPlaca() {
    $('#dialogInventarioPlaca').modal('show');
    this.focarBusca();
  }

  public buscarPlaca() {
    if (!this.buscaPlaca) return;

    if (this.listaPlaca.find(i => i.tombamento.placa === this.buscaPlaca)) {
      toastr.warning('Item já incluído na busca');
      this.buscaPlaca = undefined;
      this.focarBusca();
      return;
    }

    this.inventarioTomboService.obter({
      'tombamento.placa': this.buscaPlaca, 'inventario_setor.inventario_id': this.inventarioSetor.inventario.id, relations: 'tombamento.produto,inventario_setor.setor'
    }).subscribe(res => {
      if (!res) {
        toastr.warning('Nenhum item localizado com a placa informada!');
        return;
      }

      if (res.inventario_setor.id === this.inventarioSetor.id) {
        if (res.situacao_atual !== 'ATIVO') {
          toastr.warning(`Apenas é permitida localização de itens ativos (situação atual ${res.situacao_atual})`)
          return;
        }
        if (res.origem === 'TRANSF_PENDENTE') {
          toastr.warning(`Item com transferência pendente, regularize a pendência do item antes de localizado`);
          return;
        }

        this.listaPlaca.unshift(res)
      } else {
        toastr.warning(`O item ${res.tombamento.produto.nome} se encontra no setor ${res.inventario_setor.setor.nome}, se necessário faça a solicitação de transferência!`);
      }
    }, error => {
      toastr.error(error.error.payload)
    });

    this.buscaPlaca = undefined;
    this.focarBusca();
  }

  public removerItemPlaca(index: number) {
    this.listaPlaca.splice(index, 1);
    this.focarBusca();
  }

  private focarBusca() {
    setTimeout(() => this.inputField ? this.inputField.nativeElement.focus() : null, 500);
  }

  public cancelarBuscaPlaca() {
    this.listaPlaca = [];
    $('#dialogInventarioPlaca').modal('hide');
  }

  public async confirmarBuscaPlaca() {
    // inclui itens para serem salvos
    this.marcados = this.marcados.concat(this.listaPlaca.map(it => it.id));

    // chama o preencherGrid para salvar os itens e atualizar a listagem
    await this.preencherGrid();

    // fecha modal e limpa placas localizadas
    this.cancelarBuscaPlaca();
  }

  public finalizarTermo() {
    if (this.totalizador.pendentes > 0) {
      toastr.warning('Existem itens pendentes no seu termo, localize-os antes de finalizar!');
      return;
    }
    if (this.totalizador.recebimentosPend > 0) {
      toastr.warning('Existem transferências recebidas pendentes no seu termo, localize-as antes de finalizar!');
      return;
    }

    $('#dialogFinalizarTermo').modal('show');
  }

  public async confirmarFinalizarTermo() {
    await new Promise(async (resolve, reject) => {
      await this.salvarLocalizados();
      this.inventarioSetorService.finalizarTermo(this.inventarioSetor.id, this.login.usuario.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.inventarioSetor = res;
          this.inventarioSetor.usuario_finalizacao = this.login.usuario;
          toastr.success('Termo finalizado com sucesso!')
          resolve(true)
        }, (error) => {
          toastr.error(error.error.payload, 'Não foi possível finalizar o termo');
          reject(true)
        });
    }).then(() => {
      $('#dialogFinalizarTermo').modal('hide');
    });
  }

  public podeEditar() {
    if (this.administrador()) {
      return true;
    } else {
      return !!(this.login.acessos.find(a => a.pagina === '/inventarios' && a.permissao === 2))
    }
  }

  public podeIncluir() {
    if (this.administrador()) {
      return true;
    } else {
      return !!(this.login.acessos.find(a => a.pagina === '/inventarios' && a.permissao === 2))
    }
  }

  public voltar() {
    this.router.navigate([`/inventarios`])
  }
}
