import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FuncaoService, GlobalService, Login, ProdutoUnidade } from 'eddydata-lib';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'app-ajuste-conversao-servico-dlg',
  templateUrl: './ajuste-conversao-servico-dlg.component.html'
})
export class AjusteConversaoServicoDlgComponent implements OnInit {
  @Input() login: Login;
  @Input() visualizar: boolean = false;
  @Input() nome: String = "dlgAjusteServico";
  @Input() buscaProdutoDlg: String = 'dialogProdutoUnBuscaAjuste';
  @Input() cadastroProdutoDlg: String = 'dialogProdutoUnCadastroAjuste';
  @Input() subelemento: number;
  @Input() licitacao?: boolean;
  @Input() parametros: any;
  @Input() public produtos: { produto_unidade?: ProdutoUnidade, produto: string, unidade: string }[] = [];
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();
  @Output() cancelarImportacoesC: EventEmitter<boolean> = new EventEmitter();
  @Output() public onFinalizar: EventEmitter<{ produto_unidade?: ProdutoUnidade, produto: string, unidade: string }[]> = new EventEmitter();

  public produto: any;

  constructor(
    public globalService: GlobalService,
    public funcaoService: FuncaoService,
  ) { }

  ngOnInit(): void { }

  public finalizar() {
    if (!this.validar())
      return;
    this.onFinalizar.emit(this.produtos);
    this.hide();
  }

  private validar(): boolean {
    if (this.produtos.filter((p) => !p.produto_unidade?.id).length > 0) {
      toastr.warning(`Existem serviços não adicionados`);
      return false;
    }
    return true;
  }

  public abrirConsultaProduto(produto: any) {
    this.produto = produto;
    $(`#${this.buscaProdutoDlg}`).modal('show');
    $(`#${this.nome}`).modal('hide');
    // this.visualizar = false;
    // this.visualizarChange.emit(this.visualizar);
  }

  public async callbackProdutoUn(produtdUnidade: ProdutoUnidade) {
    if (this.produtos.filter((p) => p.produto !== this.produto.produto && p.unidade !== this.produto.unidade
      && p.produto_unidade?.id && p.produto_unidade.id === produtdUnidade.id).length > 0) {
      toastr.warning(`Serviço já adicionado.`);
      return;
    }
    
    // if (!this.licitacao) {
    //   const contratoItem = await this.contratoItemService
    //     .obter({ descricao: this.produto.produto, relations: 'contrato' }).toPromise().catch(err => {
    //       toastr.warning(err.payload);
    //       throw err;
    //     });

    //   if (!contratoItem) {
    //     toastr.error('Produto não encontrado no contrato.');
    //     throw new Error('Produto não encontrado no contrato.');
    //   }
    //   contratoItem.produto_unidade = produtdUnidade;

    //   await this.contratoItemService.atualizar(contratoItem).toPromise();
    // }

    this.produto.produto_unidade = produtdUnidade;
    $(`#${this.nome}`).modal('show');
  }

  public cancelarProduto() {
    this.visualizar = true;
  }

  public show() {
    $(`#${this.nome}`).modal('show');
    this.visualizar = true;
    // this.visualizarChange.emit(this.visualizar);
  }

  public hide() {
    $(`#${this.nome}`).modal('hide');
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

}