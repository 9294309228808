import { ViewChild } from '@angular/core';
import { Component, ElementRef, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { FuncaoService, GlobalService, Orgao, OrgaoService, Sistemas, Usuario, UsuarioService } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';

@Component({
  selector: 'app-transparencia-registro',
  templateUrl: './transparencia-registro.component.html',
  styleUrls: ['./transparencia-registro.component.css']
})
export class TransparenciaRegistroComponent implements OnInit {

  @ViewChild('nome') nomeField: ElementRef;
  @ViewChild('f') myForm: NgForm;

  public sistema: Sistemas = 'transparencia';

  public entidade: Usuario = new Usuario();
  public listaOrgaos: Array<Orgao>;
  public listaSistemas: Array<any>;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected orgaoService: OrgaoService,
    protected usuarioService: UsuarioService) {
  }

  ngOnInit() {
    // this.nomeField.nativeElement.focus();
    this.orgaoService.obterTodos().pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaOrgaos = [];

        for (const model of res) {
          this.listaOrgaos.push(model);
          if (model.codigo.replace('.', '') === '020000') {
            this.entidade.orgao = model;
          }
        }
      });
    this.listaSistemas = this.globalService.obterListaSistemas();

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public registrar(): void {
    if (this.myForm.valid) {
      this.entidade.convidado = false;
      this.entidade.sistema = this.sistema;
      this.usuarioService.registrar(this.entidade)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data) => {
            this.entidade = new Usuario();
            this.myForm.reset();
            // reseta os campos
            for (const name in this.myForm.controls) {
              this.myForm.controls[name].setErrors(null);
            }
            toastr.success('Uma senha provisória de acesso foi direcionada ao seu e-mail. O acesso será possível após autorizado pelo administrador do sistema.', 'Seu registro foi enviado com sucesso');
            this.router.navigate(['/transparencia/login']);
          },
          error => this.funcaoService.acaoErro(error)
        );
    }
  }

  public sair() {
    this.router.navigate([`transparencia/login`]);
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }


}
