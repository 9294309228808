import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Convenio, FuncaoService, Login, Pagamento, PagamentoResto } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { tsXLXS } from 'ts-xlsx-export';
import { PagamentoRestoService } from '../../../tesouraria/pagamento-resto/service/pagamento-resto.service';
import { PagamentoService } from '../../../tesouraria/pagamento/service/pagamento.service';

@Component({
  selector: 'lib-convenio-pagamento',
  templateUrl: './convenio-pagamento.component.html',
})
export class ConvenioPagamentoComponent implements OnInit, OnChanges, OnDestroy {


  protected unsubscribe: Subject<void> = new Subject();
  public pagamentos: Array<Pagamento | PagamentoResto> = [];

  @Input() lista: Array<{ id: number, especie: string }>;
  @Input() convenio: Convenio;
  @Input() login: Login;

  constructor(
    protected confirmationService: ConfirmationService,
    protected funcaoService: FuncaoService,
    protected pagamentoService: PagamentoService,
    protected pagamentoRestoService: PagamentoRestoService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['lista']) {
      const ids_orçamentarios = this.lista.filter(item => item.especie === 'EMO').map(item => item.id).join(',');
      if (ids_orçamentarios) {
        const pagtos_orcamentarios = await this.pagamentoService.filtrar(1, -1, {
          'id$in': ids_orçamentarios,
          'ignoreCondObrig': true,
          'relations': 'exercicio,liquidacao.empenho,conta.banco,conta_recurso.aplicacao,conta_recurso.recurso'
        }).toPromise();
        this.pagamentos.push(...pagtos_orcamentarios.content);
      }

      const ids_resto = this.lista.filter(item => item.especie === 'EMR').map(item => item.id).join(',');
      if (ids_resto) {
        const pagtos_resto = await this.pagamentoRestoService.filtrar(1, -1, {
          'id$in': ids_resto,
          'ignoreCondObrig': true,
          'relations': 'exercicio,liquidacao.empenho,conta.banco'
        }).toPromise();
        this.pagamentos.push(...pagtos_resto.content);
      }
    }
  }

  public exportarXLSX() {
    const listaItens = new Array();
    for (const item of this.pagamentos) {
      const entity = {
        exercicio: item.exercicio.ano,
        empenho: item.liquidacao.empenho.numero,
        parcela: item.liquidacao.parcela > 0 ? item.liquidacao.parcela : '',
        data_pagamento: this.funcaoService.converteDataBR(item.data_pagamento),
        documento: item.documento,
        conta_bancaria: `${item.conta.banco.nome} ${item.conta.agencia} ${item.conta.numero_conta}`,
        recurso: `${item.conta_recurso?.recurso.codigo}.${item.conta_recurso?.aplicacao.codigo}`,
        valor_pago: item.valor_pago
      };
      listaItens.push(entity);
    }
    tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile(`pagamentos - ${this.convenio.numero} ${this.convenio.ano}`);
  }
  

}
