import { Component, ViewChild, Injector, ElementRef } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RecebimentoExtraService } from '../service/recebimento-extra.service';
import { Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import {
  BaseResourceFormComponent, RecebimentoExtra,
  LoginContabil, EddyAutoComplete, ContaBancaria, FichaExtra,
  GlobalService, FuncaoService, DateFormatPipe, ContaBancariaRecurso
} from 'eddydata-lib';
import { ContaBancariaService } from 'administrativo-lib';
import { FichaExtraService } from '../../../ficha-extra/service/ficha-extra.service';
import { ContaBancariaRecursoService } from '../../conta-bancaria/service/conta-bancaria-recurso.service';
import * as toastr from 'toastr';

@Component({
  selector: 'app-recebimento-extra-form',
  templateUrl: './recebimento-extra-form.component.html'
})
export class RecebimentoExtraFormComponent extends BaseResourceFormComponent<RecebimentoExtra, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @ViewChild('InputFocus') private inputField: Calendar;

  /**
   * Construtor com as injeções de dependencias
   */
  public anulacao = false;
  public contaId: number;
  public contaCodigo: number;
  public fichaId: number;
  public fichaExtra: FichaExtra;
  public contaAutoComplete: EddyAutoComplete<ContaBancaria>;
  public fichaAutoComplete: EddyAutoComplete<FichaExtra>;
  public contaRecursoAutoComplete: EddyAutoComplete<ContaBancariaRecurso>;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private messageService: MessageService,
    protected injector: Injector,
    protected confirmationService: ConfirmationService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected contaService: ContaBancariaService,
    protected fichaService: FichaExtraService,
    protected contaRecursoService: ContaBancariaRecursoService,
    protected recebimentoService: RecebimentoExtraService) {
    super(new RecebimentoExtra(), injector, RecebimentoExtra.converteJson, recebimentoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(_entidade: RecebimentoExtra): Promise<boolean> {
    return true
  }
  
  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      guia: [null],
      mes: [null],
      valor_recebido: [null],
      data_recebimento: [null, [Validators.required]],
      data_referencia: [null],
      especie: ['REE', [Validators.required]],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      conta: [null, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      ficha: [null, [Validators.required]],
      conta_recurso: [null, [Validators.required]],
      aux: [null],
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'orgao,exercicio,conta,conta.banco,ficha,ficha.recurso,ficha.aplicacao,ficha.aplicacao_variavel,conta_recurso,conta_recurso.recurso,conta_recurso.aplicacao,conta_recurso.convenio' };
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.inicializarVariaveis();
    if (this.currentActionRoute === 'novo') {
      this.recebimentoService.ultimaData(this.login.exercicio.id, this.login.orgao.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          this.entidade.data_recebimento = new DateFormatPipe().transform(data.ultima_data, []);
          this.entidadeForm.get('data_recebimento').setValue(this.entidade.data_recebimento);
        });
    }
  }

  protected campoFoco(): ElementRef {
    return this.inputField.inputfieldViewChild.nativeElement.focus();
  }

  protected afterLoad() {
    if (!this.podeAlterarAudesp(this.entidade.data_recebimento)) {
      this.router.navigate(['/recebimentos-extras']);
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return;
    }

    this.entidade.data_recebimento = new DateFormatPipe().transform(this.entidade.data_recebimento, []);
    this.entidade.data_referencia = new DateFormatPipe().transform(this.entidade.data_referencia, []);
    this.contaRecursoAutoComplete.id = this.entidade.conta_recurso ? this.entidade.conta_recurso.id : null;
    if (this.currentActionRoute === 'novo') {
      this.entidade.data_recebimento = new Date();
      this.entidade.data_recebimento = new Date();
      this.entidade.historico = 'RECEITA EXTRA ARRECADADA NO DIA';
      this.entidade.exercicio = this.login.exercicio;
      this.entidade.orgao = this.login.orgao;
      this.contaId = null;
      this.contaCodigo = null;
      this.fichaId = null;
      this.fichaExtra = null;
      this.entidade.mes = this.entidade.data_recebimento.getUTCMonth() + 1;
      this.entidade.aux = 0;
      this.entidade.especie = !this.anulacao ? 'REE' : 'REA';
    } else {
      this.anulacao = this.entidade.especie === 'REA';
      this.contaCodigo = this.entidade.conta.codigo;
      this.contaId = this.entidade.conta.codigo;
      this.fichaId = this.entidade.ficha.numero;
      this.fichaExtra = this.entidade.ficha;
      this.carregarAutoCompletesContaRecurso();
      this.buscarContaRecurso(this.contaId);
    }
  }

  protected beforeSubmit() {
    try {
      if (this.entidadeForm.get('data_recebimento').value > new Date()) {
        throw new Error('Data do recebimento está maior que a data atual');
      }
      const dataRecebimento = new DateFormatPipe().transform(this.entidadeForm.get('data_recebimento').value, []);
      this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(dataRecebimento)?.split('-')?.[1]);
      this.entidadeForm.get('aux').setValue(0);
      this.entidadeForm.get('especie').setValue(!this.anulacao ? 'REE' : 'REA');
      this.verificarValor();
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(ent: RecebimentoExtra) {
    if (this.currentActionRoute === 'novo') {
      this.router.navigate(['/recebimentos-extras', 'novo', ent.id]);
    } else {
      this.router.navigate(['/recebimentos-extras', ent.id, 'editar']);
    }
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
  private carregarAutoCompletes() {
    // autocomplete para conta
    this.contaAutoComplete = new EddyAutoComplete(this.entidadeForm.get('conta'), this.contaService,
      'id', ['numero_conta', 'banco.nome', 'nome'], { orgao_id: this.login.orgao.id, relations: 'banco', orderBy: 'nome' },
      { number: ['numero_conta'], text: ['nome'] }
    );

    // autocomplete para ficha
    this.fichaAutoComplete = new EddyAutoComplete(this.entidadeForm.get('ficha'), this.fichaService,
      'id', ['numero', 'nome'],
      { orgao_id: this.login.orgao.id, excluida: false, exercicio_id: this.login.exercicio.id, relations: 'plano,recurso,aplicacao,aplicacao_variavel', orderBy: 'nome' },
      { number: ['numero'], text: ['nome'] }
    );

    this.carregarAutoCompletesContaRecurso();
  }

  private carregarAutoCompletesContaRecurso() {
    this.contaRecursoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('conta_recurso'), this.contaRecursoService,
      'id', ['recurso.codigo', 'aplicacao.codigo', 'aplicacao.nome'], { 'conta.codigo': this.contaCodigo, relations: 'recurso,aplicacao,convenio', orderBy: 'aplicacao.codigo' },
      { number: ['aplicacao.codigo'], text: ['aplicacao.nome'] },
      this.buscarContaRecurso(this.contaId)
    );
  }

  public buscarSaldoRecurso() {
    // obter o saldo bancário por fonte de recurso
  }

  public changeFicha() {
    if (this.entidadeForm.get('ficha')) {
      const fh = this.entidadeForm.get('ficha').value;
      this.fichaId = fh.numero;
    }
  }

  public changeConta() {
    if (this.entidadeForm.get('conta')) {
      const conta = this.entidadeForm.get('conta').value;
      this.contaId = conta.id;
      this.contaCodigo = conta.codigo;
      this.carregarAutoCompletesContaRecurso();
      this.buscarContaRecurso(this.contaId);
    }
  }

  public buscarConta() {
    if (this.contaCodigo >= 0) {
      this.contaService.obter({ codigo: this.contaCodigo, 'orgao.id': this.login.orgao.id, relations: 'banco' }).pipe(takeUntil(this.unsubscribe))
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            if (data) {
              if (data.ativo) {
                this.entidadeForm.get('conta').setValue(data);
                this.contaId = data.id;
                this.carregarAutoCompletesContaRecurso();
                this.buscarContaRecurso(this.contaId, data);
              } else {
                this.contaCodigo = null;
                toastr.error('Conta bancária inativa!');
                return;
              }
            }
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  private buscarContaRecurso(id: number, contaBancaria?: ContaBancaria) {
    if (this.fichaExtra) {
      this.contaRecursoService.obterRecursoPorCodigo(id, this.fichaExtra.recurso.codigo, this.fichaExtra.aplicacao.codigo,
        this.fichaExtra.aplicacao_variavel ? this.fichaExtra.aplicacao_variavel.codigo : null)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            if (data.content[0]) {
              this.entidadeForm.get('conta_recurso').setValue(data.content[0]);
            } else {
              if (contaBancaria) {
                this.confirmationService.confirm({
                  message: `Deseja cadastrar o recurso vincular ${this.fichaExtra.recurso.codigo}.${this.fichaExtra.aplicacao.codigo} ${this.fichaExtra.aplicacao_variavel ? '.' + this.fichaExtra.aplicacao_variavel.codigo : ''} para esta conta bancária ?`,
                  header: `Conta bancária sem recurso cadastrado`,
                  icon: 'pi pi-exclamation-triangle',
                  acceptLabel: 'Sim',
                  rejectLabel: 'Não',
                  key: 'contarecurso',
                  accept: async () => {
                    const cr = new ContaBancariaRecurso();
                    cr.recurso = this.fichaExtra.recurso;
                    cr.aplicacao = this.fichaExtra.aplicacao;
                    cr.convenio = this.fichaExtra.aplicacao_variavel;
                    cr.conta = contaBancaria;
                    cr.recurso_pagto = false;
                    this.contaRecursoService.inserir(cr).subscribe(res => {
                      this.entidadeForm.get('conta_recurso').setValue(res);
                    });
                  }
                });
              }
            }
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  public buscarFicha() {
    if (this.fichaId > 0) {
      this.fichaService.obterPorNumero(this.fichaId, this.login.orgao.id, this.login.exercicio.id, 'E').pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            if (data.content) {
              this.entidadeForm.get('ficha').setValue(data.content[0]);
              this.fichaExtra = data.content[0];
            }
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  public blurValorRecebido() {
    this.verificarValor();
  }

  /* anulacao de receita ou ficha de redudor de receita ou ficha de restituicao de receita
  * inverte o sinal
  */
  public verificarValor() {
    const ficha: FichaExtra = this.entidadeForm.get('ficha').value;
    const valor = this.entidadeForm.get('valor_recebido').value * -1;
    if (ficha && (this.anulacao && this.entidadeForm.get('valor_recebido').value > 0)) {
      this.entidadeForm.get('valor_recebido').setValue(valor.toString());
    }
  }

  private inicializarVariaveis() {
    if (this.currentActionRoute === 'novo') {
      this.contaCodigo = this.entidade.conta ? this.entidade.conta.codigo : null;
      this.contaId = this.entidade.conta ? this.entidade.conta.id : null;
      this.recebimentoService.ultimaData(this.login.exercicio.id, this.login.orgao.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((dados) => {
          this.entidade.data_recebimento = new Date();
          this.entidade.guia = dados.ultima_guia;
          this.entidadeForm.get('data_recebimento').setValue(this.entidade.data_recebimento);
          this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidade.data_recebimento)?.split('-')?.[1]);
          this.entidadeForm.get('historico').setValue('RECEITA EXTRA DO DIA');
          this.entidadeForm.get('guia').setValue(dados.ultima_guia);
        });
    }
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/tesouraria']);
        break;
      default:
        this.sair();
        break;
    }
  }
}
