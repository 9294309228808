import { BaseResourceModel } from '../../models/base-resource.model';
import { Cidade } from '../geo/cidade.model';

export class Exercicio extends BaseResourceModel {
  constructor(
    public id?: number,
    public ano?: number,
    public encerrado?: boolean,
    public cidade?: Cidade,
    public orcamento_aprovado?: boolean,
    public ficha_receita_importada?: boolean,
    public ficha_despesa_importada?: boolean
  ) {
    super();
  }
  static converteJson(json: any): Exercicio {
    return Object.assign(new Exercicio(), json);
  }
}
