// tslint:disable: variable-name
import { SituacaoProposta } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Memorial } from './memorial.model';
import { Proponente } from './proponente.model';

export class PregaoLance extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_cadastro?: Date,
    public lance?: number,
    public rodada?: number,
    public valor_anterior?: number,
    public valor?: number,
    public cancelado?: boolean,
    public situacao?: SituacaoProposta,
    public proponente?: Proponente,
    public memorial?: Memorial,
  ) {
    super();
  }
}
