import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class TipoDocumento extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public ativo?: boolean,
    public orgao?: Orgao,
  ) {
    super();
  }

  static converteJson(json: any): TipoDocumento {
    return Object.assign(new TipoDocumento(), json);
  }
}
