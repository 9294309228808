import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { MaterialFormComponent } from './material-form/material-form.component';
import { MaterialListComponent } from './material-list/material-list.component';
import { MaterialRoutingModule } from './material-routing.module';
import { MaterialViewComponent } from './material-view/material-view.component';
import { SharedModule } from 'eddydata-lib';
import { MaterialFormDlgComponent } from './material-form-dlg/material-form-dlg.component';
import { MaterialCadastroDlgComponent } from './material-cadastro-dlg/grupo-material-cadastro-dlg.component';
import { MaterialBuscaDlgComponent } from './grupo-material-busca-dlg/grupo-material-busca-dlg.component';

@NgModule({
  declarations: [
    MaterialListComponent, 
    MaterialFormComponent, 
    MaterialViewComponent, 
    MaterialFormDlgComponent,
    MaterialCadastroDlgComponent,
    MaterialBuscaDlgComponent
  ],
  exports: [
    MaterialFormDlgComponent,
    MaterialCadastroDlgComponent,
    MaterialBuscaDlgComponent
  ],
  imports: [
    CommonModule,
    MaterialRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    CalendarModule,
    IMaskModule,
    ToastModule
  ]
})
export class MaterialModule { }
