import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ProponenteService } from "administrativo-lib";
import { BaseResourceRptComponent, Coluna, Licitacao, LicitacaoPipe } from "eddydata-lib";

@Component({
  selector: 'app-presenca-rpt',
  templateUrl: './presenca-rpt.component.html'
})
export class PresencaRptComponent extends BaseResourceRptComponent {

  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Input() public licitacao: Licitacao;

  // ========================================================================
  // ------------------------------ CONSTRUTOR ------------------------------
  // ========================================================================

  constructor(
    private proponenteService: ProponenteService
  ) {
    super();
  }
  
  protected afterInit(): void {
    this.orientacao = 'landscape';
    this.listFormato = ['pdf', 'docx'];
  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  protected tituloRelatorio(): string {
    return `${this.login.orgao.nome} - PROPONENTES PRESENTES`;
  }

  protected subTituloRelatorio(): string {
    return `Licitação: ${new LicitacaoPipe().transform(this.licitacao.numero)} - Processo: ${new LicitacaoPipe().transform(this.licitacao.processo)} - Modalidade: ${this.licitacao.modalidade.nome}`;
  }

  protected obterColunasRelatorio(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'PROPONENTE', coluna: 'favorecido.nome', alignment: 'left' });
    retorno.push({ titulo: 'CPF/CNPJ', coluna: 'favorecido.cpf_cnpj', alignment: 'center' });
    retorno.push({ titulo: 'REPRESENTANTE', coluna: 'favorecido.responsavel', alignment: 'left' });
    retorno.push({ titulo: 'DOCUMENTO', coluna: 'assinatura', fontSize: 12 });
    retorno.push({ titulo: 'ASSINATURA', coluna: 'assinatura' });

    return retorno.map((r) => Object.assign(r, { fontSize: 9, adicionaisHeader: { fontSize: 9, border: [true, true, true, true], alignment: 'center' }, adicionaisBody: { border: [false, false, false, false], margin: [3, 10, 3, 0] } }));
  }

  protected larguraColunas(): (string | number)[] {
    return ['*', 'auto', '*', 'auto', 'auto'];
  }

  protected totalizarColunas(): (string | {})[] {
    return null;
  }

  protected carregarLista(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.proponenteService.filtrar(1, -1, { 'licitacao.id': this.licitacao.id, relations: ['favorecido'], orderBy: 'ordem' }).subscribe((data) => {
        resolve(data.content.map((c) => Object.assign(c, { assinatura: '____________________________' })));
      });
    });
  }

  protected layout(): {} {
    return {
      hLineWidth() {
        return 1;
      },
      vLineWidth(i, node) {
        return 1;
      },
      hLineColor(i) {
        return i === 1 || i === 0 ? 'black' : '0aaa';
      },
      paddingLeft(i) {
        return 2;
      },
      paddingRight(i, node) {
        return 2;
      }
    }
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }
}