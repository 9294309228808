import { BaseResourceModel } from '../../models/base-resource.model';
import { Contrato } from './contrato.model';

export class ContratoPpp extends BaseResourceModel {
  constructor(
    public id?: number,
    public contrato?: Contrato,
    public mes?: number,
    public ano?: number,
    public valor?: number,
    public especie?: string,
  ) {
    super();
  }
  static converteJson(json: any): ContratoPpp {
    return Object.assign(new ContratoPpp(), json);
  }
}