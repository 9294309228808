import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, TipoConvenio } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TipoConvenioService extends BaseResourceService<TipoConvenio> {

  constructor(
    protected injector: Injector
  ) {
    super(`tipos-convenio`, injector);
  }

  public validarDespesa(tipo_convenio: number, despesa: number): Observable<{ valido: boolean }> {
    const params = new HttpParams().appendAll({
      tipo_convenio,
      despesa
    });

    const options = { ...this.httpOptions(), params };

    return this.http.get<{ valido: boolean }>(
      `${this.login.cidade.id}/${this.api}/validar-despesa`, options).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
