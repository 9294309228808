// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { CardapioMerenda } from './cardapio-merenda.model';
import { EtapaSetorMerenda } from './etapa-setor-merenda.model';
import { PedidoEtapaSetorItemMerenda } from './pedido-etapa-setor-item-merenda.model';
import { PedidoMerenda } from './pedido-merenda.model';

export class PedidoEtapaSetorMerenda extends BaseResourceModel {
  constructor(
    public id?: number,
    public pedido?: PedidoMerenda,
    public etapa_setor?: EtapaSetorMerenda,
    public cardapio?: CardapioMerenda,
    public itens?: PedidoEtapaSetorItemMerenda[]) {
    super();
  }
  static converteJson(json: any): PedidoEtapaSetorMerenda {
    return Object.assign(new PedidoEtapaSetorMerenda(), json);
  }
}
