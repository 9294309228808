import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';

export class ModeloRelatorioPatrimonio extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public modelo?: string,
    public tipo?: 'PATRIMONIO IMOBILIARIO',
    public fixo?: boolean,
    public usuario?: Usuario,
    public orgao?: Orgao,
  ) {
    super();
  }

  static converteJson(json: any): ModeloRelatorioPatrimonio {
    return Object.assign(new ModeloRelatorioPatrimonio(), json);
  }
}
