import { BaseResourceModel } from '../../models/base-resource.model';
import { Depreciacao } from './depreciacao.model';
import { Tombamento } from './tombamento.model';

export class DepreciacaoItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public valor_tombo_anterior?: number,
    public valor_depreciado?: number,
    public valor_depreciado_anterior?: number,
    public valor_residual?: number,
    public valor_avaliacao?: number,
    public vida_util?: number,
    public qtde_depreciada?: number,
    public qtde_depreciada_acumulada?: number,
    public status?: 'PENDENTE' | 'DEPRECIADO' | 'ERRO',
    public obs?: string,
    public tombamento?: Tombamento,
    public depreciacao?: Depreciacao,
  ) {
    super();
  }

  static converteJson(json: any): DepreciacaoItem {
    return Object.assign(new DepreciacaoItem(), json);
  }
}
