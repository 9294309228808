import { BaseResourceModel } from '../../models/base-resource.model';
import { Combustivel } from './combustivel.model';
import { Veiculo } from './veiculo.model';

export class VeiculoCombustivel extends BaseResourceModel {
  constructor(
    public id?: number,
    public veiculo?: Veiculo,
    public combustivel?: Combustivel,
  ) {
    super();
  }

  static converteJson(json: any): VeiculoCombustivel {
    return Object.assign(new VeiculoCombustivel(), json);
  }
}
