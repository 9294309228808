// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Contrato } from '../compra/contrato.model';
import { Convenio } from '../compra/convenio.model';
import { Favorecido } from '../compra/favorecido.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { FichaExtra } from '../contabil/ficha-extra.model';
import { RetencaoExtra } from '../contabil/retencao-extra.model';
import { RetencaoResto } from '../contabil/retencao-resto.model';
import { Retencao } from '../contabil/retencao.model';
import { FichaReceita } from '../planejamento/ficha-receita.model';
import { ContaBancariaRecurso } from './conta-bancaria-recurso.model';
import { ContaBancaria } from './conta-bancaria.model';

export class Recebimento extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_recebimento?: Date,
    public data_referencia?: Date,
    public mes?: number,
    public aux?: number,
    public especie?: string,
    public guia?: string,
    public historico?: string,
    public restituicao?: boolean,
    public retido?: boolean,
    public valor_recebido?: number,
    public ficha?: FichaReceita,
    public ficha_extra?: FichaExtra,
    public conta?: ContaBancaria,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public orgao?: Orgao,
    public retencao?: Retencao,
    public retencao_resto?: RetencaoResto,
    public retencao_extra?: RetencaoExtra,
    public exercicio?: Exercicio,
    public favorecido?: Favorecido,
    public contrato?: Contrato,
    public convenio?: Convenio,
    public conta_recurso?: ContaBancariaRecurso,
    public nome_arquivo?: string
  ) {
    super();
  }

  static converteJson(json: any): Recebimento {
    return Object.assign(new Recebimento(), json);
  }
}
