export class ListaVerificacao1 {
    qst1?: 'S' | 'N' | 'NA';
    qst1Justificativa?: string;
    qst2?: 'S' | 'N' | 'NA';
    qst2Justificativa?: string;
    qst3?: 'S' | 'N' | 'NA';
    qst3Justificativa?: string;
    qst4?: 'S' | 'N' | 'NA';
    qst4Justificativa?: string;
    qst5?: 'S' | 'N' | 'NA';
    qst5Justificativa?: string;
    qst6?: 'S' | 'N' | 'NA';
    qst6Justificativa?: string;
    qst7?: 'S' | 'N' | 'NA';
    qst7Justificativa?: string;
    qst8?: 'S' | 'N' | 'NA';
    qst8Justificativa?: string;
    qst9?: 'S' | 'N' | 'NA';
    qst9Justificativa?: string;
    qst10?: 'S' | 'N' | 'NA';
    qst10Justificativa?: string;
    qst11?: 'S' | 'N' | 'NA';
    qst11Justificativa?: string;
    qst12?: 'S' | 'N' | 'NA';
    qst12Justificativa?: string;
    qst13?: 'S' | 'N' | 'NA';
    qst13Justificativa?: string;
    qst14?: 'S' | 'N' | 'NA';
    qst14Justificativa?: string;
    qst15?: 'S' | 'N' | 'NA';
    qst15Justificativa?: string;
    qst16?: 'S' | 'N' | 'NA';
    qst16Justificativa?: string;
    qst17?: 'S' | 'N' | 'NA';
    qst17Justificativa?: string;
    qst18?: 'S' | 'N' | 'NA';
    qst18Justificativa?: string;
    qst19?: 'S' | 'N' | 'NA';
    qst19Justificativa?: string;
    qst20?: 'S' | 'N' | 'NA';
    qst20Justificativa?: string;
    qst21?: 'S' | 'N' | 'NA';
    qst21Justificativa?: string;
    qst22?: 'S' | 'N' | 'NA';
    qst22Justificativa?: string;
    qst23?: 'S' | 'N' | 'NA';
    qst23Justificativa?: string;
    qst24?: 'S' | 'N' | 'NA';
    qst24Justificativa?: string;

    constructor() {
        this.qst1 = 'N';
        this.qst2 = 'N';
        this.qst3 = 'N';
        this.qst4 = 'N';
        this.qst5 = 'N';
        this.qst6 = 'N';
        this.qst7 = 'N';
        this.qst8 = 'N';
        this.qst9 = 'N';
        this.qst10 = 'N';
        this.qst11 = 'N';
        this.qst12 = 'N';
        this.qst13 = 'N';
        this.qst14 = 'N';
        this.qst15 = 'N';
        this.qst16 = 'N';
        this.qst17 = 'N';
        this.qst18 = 'N';
        this.qst19 = 'N';
        this.qst20 = 'N';
        this.qst21 = 'N';
        this.qst22 = 'N';
        this.qst23 = 'N';
        this.qst24 = 'N';
        this.qst1Justificativa = '';
        this.qst2Justificativa = '';
        this.qst3Justificativa = '';
        this.qst4Justificativa = '';
        this.qst5Justificativa = '';
        this.qst6Justificativa = '';
        this.qst7Justificativa = '';
        this.qst8Justificativa = '';
        this.qst9Justificativa = '';
        this.qst10Justificativa = '';
        this.qst11Justificativa = '';
        this.qst12Justificativa = '';
        this.qst13Justificativa = '';
        this.qst14Justificativa = '';
        this.qst15Justificativa = '';
        this.qst16Justificativa = '';
        this.qst17Justificativa = '';
        this.qst18Justificativa = '';
        this.qst19Justificativa = '';
        this.qst20Justificativa = '';
        this.qst21Justificativa = '';
        this.qst22Justificativa = '';
        this.qst23Justificativa = '';
        this.qst24Justificativa = '';

    }
}

export class ListaVerificacao2A {
    qst1?: 'S' | 'N' | 'NA';
    qst1Justificativa?: string;
    qst2?: 'S' | 'N' | 'NA';
    qst2Justificativa?: string;
    qst3?: 'S' | 'N' | 'NA';
    qst3Justificativa?: string;
    qst4?: 'S' | 'N' | 'NA';
    qst4Justificativa?: string;
    qst5?: 'S' | 'N' | 'NA';
    qst5Justificativa?: string;
    qst6?: 'S' | 'N' | 'NA';
    qst6Justificativa?: string;
    qst7?: 'S' | 'N' | 'NA';
    qst7Justificativa?: string;

    constructor() {
        this.qst1 = 'N';
        this.qst2 = 'N';
        this.qst3 = 'N';
        this.qst4 = 'N';
        this.qst5 = 'N';
        this.qst6 = 'N';
        this.qst7 = 'N';
        this.qst1Justificativa = '';
        this.qst2Justificativa = '';
        this.qst3Justificativa = '';
        this.qst4Justificativa = '';
        this.qst5Justificativa = '';
        this.qst6Justificativa = '';
        this.qst7Justificativa = '';
    }
}

export class ListaVerificacao2B {
    qst1?: 'S' | 'N' | 'NA';
    qst1Justificativa?: string;
    qst2?: 'S' | 'N' | 'NA';
    qst2Justificativa?: string;
    qst3?: 'S' | 'N' | 'NA';
    qst3Justificativa?: string;
    qst4?: 'S' | 'N' | 'NA';
    qst4Justificativa?: string;
    qst5?: 'S' | 'N' | 'NA';
    qst5Justificativa?: string;
    qst6?: 'S' | 'N' | 'NA';
    qst6Justificativa?: string;
    qst7?: 'S' | 'N' | 'NA';
    qst7Justificativa?: string;

    constructor() {
        this.qst1 = 'N';
        this.qst2 = 'N';
        this.qst3 = 'N';
        this.qst4 = 'N';
        this.qst5 = 'N';
        this.qst6 = 'N';
        this.qst7 = 'N';
        this.qst1Justificativa = '';
        this.qst2Justificativa = '';
        this.qst3Justificativa = '';
        this.qst4Justificativa = '';
        this.qst5Justificativa = '';
        this.qst6Justificativa = '';
    }
}

export class ListaVerificacao3A {
    qst1?: 'S' | 'N' | 'NA';
    qst1Justificativa?: string;
    qst2?: 'S' | 'N' | 'NA';
    qst2Justificativa?: string;
    qst3?: 'S' | 'N' | 'NA';
    qst3Justificativa?: string;
    qst4?: 'S' | 'N' | 'NA';
    qst4Justificativa?: string;
    qst5?: 'S' | 'N' | 'NA';
    qst5Justificativa?: string;
    qst6?: 'S' | 'N' | 'NA';
    qst6Justificativa?: string;

    constructor() {
        this.qst1 = 'N';
        this.qst2 = 'N';
        this.qst3 = 'N';
        this.qst4 = 'N';
        this.qst5 = 'N';
        this.qst6 = 'N';
        this.qst1Justificativa = '';
        this.qst2Justificativa = '';
        this.qst3Justificativa = '';
        this.qst4Justificativa = '';
        this.qst5Justificativa = '';
        this.qst6Justificativa = '';
    }
}

export class ListaVerificacao3B {
    qst1?: 'S' | 'N' | 'NA';
    qst1Justificativa?: string;
    qst2?: 'S' | 'N' | 'NA';
    qst2Justificativa?: string;
    qst3?: 'S' | 'N' | 'NA';
    qst3Justificativa?: string;
    qst4?: 'S' | 'N' | 'NA';
    qst4Justificativa?: string;

    constructor() {
        this.qst1 = 'N';
        this.qst2 = 'N';
        this.qst3 = 'N';
        this.qst4 = 'N';
        this.qst1Justificativa = '';
        this.qst2Justificativa = '';
        this.qst3Justificativa = '';
        this.qst4Justificativa = '';
    }
}

export class ListaVerificacaoComum {
    qst1?: 'S' | 'N' | 'NA';
    qst1Justificativa?: string;
    qst2?: 'S' | 'N' | 'NA';
    qst2Justificativa?: string;
    qst3?: 'S' | 'N' | 'NA';
    qst3Justificativa?: string;
    qst4?: 'S' | 'N' | 'NA';
    qst4Justificativa?: string;
    qst5?: 'S' | 'N' | 'NA';
    qst5Justificativa?: string;
    qst6?: 'S' | 'N' | 'NA';
    qst6Justificativa?: string;
    qst7?: 'S' | 'N' | 'NA';
    qst7Justificativa?: string;
    qst8?: 'S' | 'N' | 'NA';
    qst8Justificativa?: string;
    qst9?: 'S' | 'N' | 'NA';
    qst9Justificativa?: string;
    qst10?: 'S' | 'N' | 'NA';
    qst10Justificativa?: string;
    qst11?: 'S' | 'N' | 'NA';
    qst11Justificativa?: string;
    qst12?: 'S' | 'N' | 'NA';
    qst12Justificativa?: string;
    qst13?: 'S' | 'N' | 'NA';
    qst13Justificativa?: string;
    qst14?: 'S' | 'N' | 'NA';
    qst14Justificativa?: string;
    qst15?: 'S' | 'N' | 'NA';
    qst15Justificativa?: string;
    qst16?: 'S' | 'N' | 'NA';
    qst16Justificativa?: string;
    qst17?: 'S' | 'N' | 'NA';
    qst17Justificativa?: string;
    qst18?: 'S' | 'N' | 'NA';
    qst18Justificativa?: string;
    qst19?: 'S' | 'N' | 'NA';
    qst19Justificativa?: string;
    qst20?: 'S' | 'N' | 'NA';
    qst20Justificativa?: string;
    qst21?: 'S' | 'N' | 'NA';
    qst21Justificativa?: string;
    qst22?: 'S' | 'N' | 'NA';
    qst22Justificativa?: string;
    qst23?: 'S' | 'N' | 'NA';
    qst23Justificativa?: string;
    qst24?: 'S' | 'N' | 'NA';
    qst24Justificativa?: string;
    qst25?: 'S' | 'N' | 'NA';
    qst25Justificativa?: string;
    qst26?: 'S' | 'N' | 'NA';
    qst26Justificativa?: string;
    qst27?: 'S' | 'N' | 'NA';
    qst27Justificativa?: string;
    qst28?: 'S' | 'N' | 'NA';
    qst28Justificativa?: string;
    qst29?: 'S' | 'N' | 'NA';
    qst29Justificativa?: string;
    qst30?: 'S' | 'N' | 'NA';
    qst30Justificativa?: string;

    constructor() {
        this.qst1 = 'N';
        this.qst2 = 'N';
        this.qst3 = 'N';
        this.qst4 = 'N';
        this.qst5 = 'N';
        this.qst6 = 'N';
        this.qst7 = 'N';
        this.qst8 = 'N';
        this.qst9 = 'N';
        this.qst10 = 'N';
        this.qst11 = 'N';
        this.qst12 = 'N';
        this.qst13 = 'N';
        this.qst14 = 'N';
        this.qst15 = 'N';
        this.qst16 = 'N';
        this.qst17 = 'N';
        this.qst18 = 'N';
        this.qst19 = 'N';
        this.qst20 = 'N';
        this.qst21 = 'N';
        this.qst22 = 'N';
        this.qst23 = 'N';
        this.qst24 = 'N';
        this.qst25 = 'N';
        this.qst26 = 'N';
        this.qst27 = 'N';
        this.qst28 = 'N';
        this.qst29 = 'N';
        this.qst30 = 'N';
        this.qst1Justificativa = '';
        this.qst2Justificativa = '';
        this.qst3Justificativa = '';
        this.qst4Justificativa = '';
        this.qst5Justificativa = '';
        this.qst6Justificativa = '';
        this.qst7Justificativa = '';
        this.qst8Justificativa = '';
        this.qst9Justificativa = '';
        this.qst10Justificativa = '';
        this.qst11Justificativa = '';
        this.qst12Justificativa = '';
        this.qst13Justificativa = '';
        this.qst14Justificativa = '';
        this.qst15Justificativa = '';
        this.qst16Justificativa = '';
        this.qst17Justificativa = '';
        this.qst18Justificativa = '';
        this.qst19Justificativa = '';
        this.qst20Justificativa = '';
        this.qst21Justificativa = '';
        this.qst22Justificativa = '';
        this.qst23Justificativa = '';
        this.qst24Justificativa = '';
        this.qst25Justificativa = '';
        this.qst26Justificativa = '';
        this.qst27Justificativa = '';
        this.qst28Justificativa = '';
        this.qst29Justificativa = '';
        this.qst30Justificativa = '';

    }
}

export class ListaVerificacaoPesquisa {
    qst1?: 'S' | 'N' | 'NA';
    qst1Justificativa?: string;
    qst2?: 'S' | 'N' | 'NA';
    qst2Justificativa?: string;
    qst3?: 'S' | 'N' | 'NA';
    qst3Justificativa?: string;
    qst4?: 'S' | 'N' | 'NA';
    qst4Justificativa?: string;
    qst5?: 'S' | 'N' | 'NA';
    qst5Justificativa?: string;
    qst6?: 'S' | 'N' | 'NA';
    qst6Justificativa?: string;
    qst7?: 'S' | 'N' | 'NA';
    qst7Justificativa?: string;
    qst8?: 'S' | 'N' | 'NA';
    qst8Justificativa?: string;
    qst9?: 'S' | 'N' | 'NA';
    qst9Justificativa?: string;
    qst10?: 'S' | 'N' | 'NA';
    qst10Justificativa?: string;
    qst11?: 'S' | 'N' | 'NA';
    qst11Justificativa?: string;
    qst12?: 'S' | 'N' | 'NA';
    qst12Justificativa?: string;
    qst13?: 'S' | 'N' | 'NA';
    qst13Justificativa?: string;
    qst14?: 'S' | 'N' | 'NA';
    qst14Justificativa?: string;
    qst15?: 'S' | 'N' | 'NA';
    qst15Justificativa?: string;

    constructor() {
        this.qst1 = 'N';
        this.qst2 = 'N';
        this.qst3 = 'N';
        this.qst4 = 'N';
        this.qst5 = 'N';
        this.qst6 = 'N';
        this.qst7 = 'N';
        this.qst8 = 'N';
        this.qst9 = 'N';
        this.qst10 = 'N';
        this.qst11 = 'N';
        this.qst12 = 'N';
        this.qst13 = 'N';
        this.qst14 = 'N';
        this.qst15 = 'N';
        this.qst1Justificativa = '';
        this.qst2Justificativa = '';
        this.qst3Justificativa = '';
        this.qst4Justificativa = '';
        this.qst5Justificativa = '';
        this.qst6Justificativa = '';
        this.qst7Justificativa = '';
        this.qst8Justificativa = '';
        this.qst9Justificativa = '';
        this.qst10Justificativa = '';
        this.qst11Justificativa = '';
        this.qst12Justificativa = '';
        this.qst13Justificativa = '';
        this.qst14Justificativa = '';
        this.qst15Justificativa = '';

    }
}

export class ListaVerificacaoAquisicao {
    qst1?: 'S' | 'N' | 'NA';
    qst1Justificativa?: string;
    qst2?: 'S' | 'N' | 'NA';
    qst2Justificativa?: string;
    qst3?: 'S' | 'N' | 'NA';
    qst3Justificativa?: string;
    qst4?: 'S' | 'N' | 'NA';
    qst4Justificativa?: string;
    qst5?: 'S' | 'N' | 'NA';
    qst5Justificativa?: string;
    qst6?: 'S' | 'N' | 'NA';
    qst6Justificativa?: string;
    qst7?: 'S' | 'N' | 'NA';
    qst7Justificativa?: string;
    qst8?: 'S' | 'N' | 'NA';
    qst8Justificativa?: string;
    qst9?: 'S' | 'N' | 'NA';
    qst9Justificativa?: string;
    qst10?: 'S' | 'N' | 'NA';
    qst10Justificativa?: string;
    qst11?: 'S' | 'N' | 'NA';
    qst11Justificativa?: string;
    qst12?: 'S' | 'N' | 'NA';
    qst12Justificativa?: string;

    constructor() {
        this.qst1 = 'N';
        this.qst2 = 'N';
        this.qst3 = 'N';
        this.qst4 = 'N';
        this.qst5 = 'N';
        this.qst6 = 'N';
        this.qst7 = 'N';
        this.qst8 = 'N';
        this.qst9 = 'N';
        this.qst10 = 'N';
        this.qst11 = 'N';
        this.qst12 = 'N';
        this.qst1Justificativa = '';
        this.qst2Justificativa = '';
        this.qst3Justificativa = '';
        this.qst4Justificativa = '';
        this.qst5Justificativa = '';
        this.qst6Justificativa = '';
        this.qst7Justificativa = '';
        this.qst8Justificativa = '';
        this.qst9Justificativa = '';
        this.qst10Justificativa = '';
        this.qst11Justificativa = '';
        this.qst12Justificativa = '';

    }
}

export class ListaVerificacaoServico {
    qst1?: 'S' | 'N' | 'NA';
    qst1Justificativa?: string;
    qst2?: 'S' | 'N' | 'NA';
    qst2Justificativa?: string;
    qst3?: 'S' | 'N' | 'NA';
    qst3Justificativa?: string;
    qst4?: 'S' | 'N' | 'NA';
    qst4Justificativa?: string;
    qst5?: 'S' | 'N' | 'NA';
    qst5Justificativa?: string;
    qst6?: 'S' | 'N' | 'NA';
    qst6Justificativa?: string;
    qst7?: 'S' | 'N' | 'NA';
    qst7Justificativa?: string;
    qst8?: 'S' | 'N' | 'NA';
    qst8Justificativa?: string;
    qst9?: 'S' | 'N' | 'NA';
    qst9Justificativa?: string;
    qst10?: 'S' | 'N' | 'NA';
    qst10Justificativa?: string;;

    constructor() {
        this.qst1 = 'N';
        this.qst2 = 'N';
        this.qst3 = 'N';
        this.qst4 = 'N';
        this.qst5 = 'N';
        this.qst6 = 'N';
        this.qst7 = 'N';
        this.qst8 = 'N';
        this.qst9 = 'N';
        this.qst10 = 'N';
        this.qst1Justificativa = '';
        this.qst2Justificativa = '';
        this.qst3Justificativa = '';
        this.qst4Justificativa = '';
        this.qst5Justificativa = '';
        this.qst6Justificativa = '';
        this.qst7Justificativa = '';
        this.qst8Justificativa = '';
        this.qst9Justificativa = '';
        this.qst10Justificativa = '';

    }
}