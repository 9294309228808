// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Favorecido } from '../compra/favorecido.model';
import { Contrato } from '../compra/contrato.model';
import { Convenio } from '../compra/convenio.model';
import { Compra } from '../compra/compra.model';
import { Usuario } from '../comum/usuario.model';
import { Licitacao } from '../licitacao/licitacao.model';
import { Modalidade } from '../licitacao/modalidade.model';
import { LiquidacaoResto } from './liquidacao-resto.model';
import { Exercicio } from '../comum/exercicio.model';
import { ContratoAditamento } from 'eddydata-lib';

export class EmpenhoResto extends BaseResourceModel {
  constructor(
    public id?: number,
    public numero?: number,
    public ano?: number,
    public valor_empenho?: number,
    public valor_original?: number,
    public valor_nao_processado?: number,
    public mes?: number,
    public data_empenho?: Date,
    public data_vencimento?: Date,
    public especie?: string,
    public processo?: string,
    public documento?: string,
    public ata?: string,
    public impresso?: boolean,
    public inscrito_resto?: boolean,
    public aux?: number,
    public historico?: string,
    public subelemento?: string,
    public subelemento_nome?: string,
    public recurso?: string,
    public recurso_siconfi?: string,
    public complemento_siconfi?: string,
    public recurso_nome?: string,
    public aplicacao?: string,
    public aplicacao_nome?: string,
    public acao?: string,
    public acao_nome?: string,
    public programa?: string,
    public programa_nome?: string,
    public funcao?: string,
    public funcao_nome?: string,
    public subfuncao?: string,
    public subfuncao_nome?: string,
    public recurso_variavel?: string,
    public recurso_variavel_nome?: string,
    public favorecido?: Favorecido,
    public orgao?: Orgao,
    public contrato?: Contrato,
    public licitacao?: Licitacao,
    public modalidade?: Modalidade,
    public convenio?: Convenio,
    public compra?: Compra,
    public usuario_cadastro?: Usuario,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public executora?: string,
    public unidade_executora?: string,
    public unidade_orcamentaria?: string,
    public unidade?: string,
    public ue?: string,
    public uo?: string,
    public tipo_empenho?: string,
    public liquidacoes?: LiquidacaoResto[],
    public exercicio?: Exercicio,
    public contrato_aditamento?: ContratoAditamento,
    public codigo_reinf?: number,
    public data_inscricao?: Date,
    public total_empenho_anulado?: number,
    public total_liquidado?: number,
    public total_cancelado_processado?: number,
    public total_cancelado_nao_processado?: number,
    public ficha_superavit?: boolean,
    public editavel?: boolean,
    public editavelC?: boolean
  ) {
    super();
  }

  static converteJson(json: any): EmpenhoResto {
    return Object.assign(new EmpenhoResto(), json);
  }
}
