import { BaseResourceModel } from "../../models/base-resource.model";
import { CompraItem } from "./compra-item.model";
import { PedidoCompra } from "./pedido-compra.model";

export class PedidoCompraItem extends BaseResourceModel {

  constructor (
    public id?: number,
    public data_alteracao?: Date,
    public data_cadastro?: Date,
    public quantidade?: number,
    public pedido?: PedidoCompra,
    public item?: CompraItem
  ) {
    super();
  }

  static converteJson(json: any): PedidoCompraItem {
    return Object.assign(new PedidoCompraItem(), json);
  }
}