import { Component, ElementRef, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MaterialService, SubGrupoEstoqueService } from 'almoxarifado-lib';
import {
   BaseResourceFormDlgComponent, EddyAutoComplete, FuncaoService, GlobalService, LoginContabil, Material, SubGrupoEstoque
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'lib-grupo-servico-form-dlg',
  templateUrl: './grupo-servico-form-dlg.component.html'
})
export class GrupoServicoFormDlgComponent extends BaseResourceFormDlgComponent<Material, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public subGrupoAutoComplete: EddyAutoComplete<SubGrupoEstoque>;

  @Input() public visualizar: boolean = false;
  @Output() public visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('codigo_') inputField: ElementRef;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected materialService: MaterialService,
    protected subGrupoService: SubGrupoEstoqueService,
  ) {
    super(injector, Material.converteJson, materialService, '/grupos-servico');
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      codigo: [null, [Validators.required]],
      nome: [null, [Validators.required]],
      sub_grupo: [null, [Validators.required]],
      servico: [true, [Validators.required]],
      contabilizar: [false, [Validators.required]],
      orgao: [null],
      bec: [false, [Validators.required]]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'sub_grupo.grupo.orgao,sub_grupo.orgao,orgao', servico: true };
  }

  protected afterLoad() {
    this.subGrupoAutoComplete.id = this.entidade.sub_grupo ? this.entidade.sub_grupo.id : null;
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected beforeSubmit() {
    try {
      if (this.entidadeForm.get('nome').value === '') {
        throw new Error('Informe o nome do material!');
      }
      this.entidadeForm.get('orgao').setValue(this.login.orgao);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(modelo: Material) {
    this.sair();
  }


  public sair(): void {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
  private carregarAutoCompletes() {
    this.subGrupoAutoComplete = new EddyAutoComplete(null, this.subGrupoService,
      'id', ['codigo', 'nome'], {orgao_id: this.login.orgao.id, relations: 'grupo.orgao', orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );
  }

  public visualizaCodigo() {
    let codigo = this.entidadeForm.get('codigo').value;
    let subGrupo: SubGrupoEstoque = this.entidadeForm.get('sub_grupo').value;
    if (!codigo)
      return '00.00.00';
    return `${subGrupo.grupo.codigo}.${subGrupo.codigo}.${codigo}`
  }

  public selectSubGrupo() {
    if (this.currentActionRoute === 'novo') {
      this.materialService.proximoCodigo(this.entidadeForm.get('sub_grupo').value.id, false, this.login.orgao.id)
        .subscribe((data) => {
          this.entidadeForm.get('codigo').setValue(data);
        });
    }
  }

}
