import { Orgao } from "eddydata-lib";
import { BaseResourceModel } from "../../models/base-resource.model";
import { RequisicaoExcecao } from "./requisicao-excecao.model";
import { RequisicaoSubelemento } from "./requisicao-subelemento.model";

export class ParametroRequisicao extends BaseResourceModel {
    constructor(
        public id?: number,
        public nao_usar_ficha_despesa?: boolean,
        public minimo_tres_cotacoes?: boolean,
        public exigir_responsavel_cotacao?: boolean,
        public exigir_dados_fiscalizacao?: boolean,
        public bloquear_data?: boolean,
        public tipo_bloqueio_data?: string,
        public orgao?: Orgao,
        public bloquear_nova_requisicao?: boolean,
        public habilitar_desbloqueio_automatico?: boolean,
        public requisicao_alfabetico?: boolean,
        public requisicao_licitacao_alfabetico?: boolean,
        public exigir_finalidade_fundeb?: boolean,
        public exigir_ficha_despesa?: boolean,
        public verificar_saldo_ficha_requisicao?: boolean,
        public permitir_cadastro_exercicio_posterior?: boolean,
        public reserva_dotacao_rpls?: boolean,
        public calculo_medio?: number,
        public usuarios_excecao?: RequisicaoExcecao[],
        public requisicao_subelementos?: RequisicaoSubelemento[],
        public obrigar_indicar_placa?: boolean,
        public data_limite_pca?: Date
    ) {
        super();
    }

    static converteJson(json: any): ParametroRequisicao {
        return Object.assign(new ParametroRequisicao(), json);
    }
}
