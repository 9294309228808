import { CloudArmazenamento } from "../components/types";
import { BaseResourceModel } from "./base-resource.model";

export abstract class BaseResourceStorage extends BaseResourceModel {
  public nome?: string;
  public descricao?: string;
  public tipo?: string;
  public tamanho?: number;
  public mime?: string;
  public url?: string;
  public diretorio?: string;
  public storage?: CloudArmazenamento;
  public anexo?: Buffer;
}
