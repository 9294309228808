// tslint:disable: variable-name
import { Rpl } from 'eddydata-lib';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Pessoa } from '../comum/pessoa.model';
import { Licitacao } from './licitacao.model';

export class LicitacaoFiscal extends BaseResourceModel {
  constructor(
    public id?: number,
    public pessoa?: Pessoa,
    public licitacao?: Licitacao,
    public requerente_gestor?: boolean,
    public rpl?: Rpl
  ) {
    super();
  }

  static converteJson(json: any): LicitacaoFiscal {
    return Object.assign(new LicitacaoFiscal(), json);
  }
}
