import { Injectable, Injector } from '@angular/core';
import { AssinaturaPessoa } from '../../entidade/comum/assinatura-pessoa.model';
import { BaseResourceService } from '../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class AssinaturaPessoaService extends BaseResourceService<AssinaturaPessoa> {

  constructor(
    protected injector: Injector
  ) {
    super(`assinatura-pessoas`, injector);
  }

}
