import { Pipe, PipeTransform } from '@angular/core';
import { FuncaoService } from '../../util/funcao.service';

@Pipe({
    name: 'VL_CONTRATO'
})

export class ValorContratoPipe implements PipeTransform {
    transform(value: number, ...args: any[]): any {
        if (!value || value == 0) {
            return '0';
        }

        return new FuncaoService().convertToBrNumber(value, 6, true);
    }
}
