import { Component } from '@angular/core';
import { ProdutoService } from 'almoxarifado-lib';
import { BaseResourceItemsComponent, EddyAutoComplete, FavorecidoProduto, Produto } from 'eddydata-lib';
import * as toastr from 'toastr';
import { FavorecidoProdutoService } from '../service/favorecido-produto.service';

@Component({
  selector: 'lib-favorecido-produto',
  templateUrl: './favorecido-produto.component.html'
})
export class FavorecidoProdutoComponent extends BaseResourceItemsComponent<FavorecidoProduto>{


  public produtoAutoComplete: EddyAutoComplete<Produto>;

  constructor(private produtoService: ProdutoService,
    public favorecidoProdutoService: FavorecidoProdutoService) {
    super(new FavorecidoProduto(), favorecidoProdutoService);
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
  }

  protected validSave(item: FavorecidoProduto): boolean {
    if (!item.produto) {
      toastr.warning('Informe o produto vinculado!');
      return false;
    }
    return true;
  }

  protected beforeSave(item: FavorecidoProduto): void {
    item.orgao = this.login.orgao
  }


  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  private carregarAutoCompletes() {
    this.produtoAutoComplete = ProdutoService.autoCompleteCodigoCompleto(null, this.produtoService,
      'id', ['codigo', 'nome'], { orgao_id: this.login.orgao.id, orderBy: 'codigo' }, { number: ['codigo'], text: ['nome'] }
    );
  }

}
