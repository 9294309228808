import { Component, OnInit, Input, OnDestroy, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { MessageService } from 'primeng/api';
import * as toastr from 'toastr';
import { Login, GlobalService, FuncaoService, PreLiquidacao } from 'eddydata-lib';
import { PreLiquidacaoService } from '../service/pre-liquidacao.service';
import { EmpenhoService, LiquidacaoService } from 'administrativo-lib';

declare var $: any;

@Component({
  selector: 'lib-pre-liquidacao-anulacao',
  templateUrl: './pre-liquidacao-anulacao.component.html'
})
export class PreLiquidacaoAnulacaoComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  protected datepipe: DatePipe;
  public ptBR: any;
  public data: Date;
  public data_referencia: Date;
  public valor: number;
  public historico: string;
  public liquidacaoId: number;
  public listAnulacoes: Array<PreLiquidacao>;
  protected unsubscribe: Subject<void> = new Subject();
  public totalParcelado = 0;
  public totalEmpenhado = 0;
  public totalPago = 0;
  public saldo = 0;
  public avisoLiquidacao: boolean = false;

  @Input() login: Login;
  @Input() empNumero: number;
  @Input() idPreLiquidacao: number;
  @Input() dataReferencia: Date;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected messageService: MessageService,
    private globalService: GlobalService,
    private funcaoService: FuncaoService,
    private empenhoService: EmpenhoService,
    private preLiquidacaoService: PreLiquidacaoService,
    private liquidacaoService: LiquidacaoService
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.data = new Date();
    this.valor = 0;
    this.historico = '';
    this.liquidacaoId = 0;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    this.globalService.calendarMascara();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataReferencia) {
      this.data_referencia = new Date(changes.dataReferencia.currentValue);
    }
  }

  public anular() {
    if (!this.data_referencia) {
      toastr.error('Informe a data de referência da anulação!');
      return;
    }

    if (!this.funcaoService.podeAlterarAudesp(this.data, this.login)) {
      toastr.error('Data de anulação inválida, período na contabilidade já está fechado, entre em contato com o contador!');
      return;
    }

    this.route.paramMap
      .pipe(switchMap(params => this.preLiquidacaoService.anular(
        Number(this.idPreLiquidacao),
        String(this.funcaoService.converteDataSQL(this.data.toLocaleDateString())),
        this.valor,
        this.historico,
        this.login.usuario,
        String(this.funcaoService.converteDataSQL(this.data_referencia.toLocaleDateString()))
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (dados) => {
          $('#dialogAnular').modal('hide');
          this.router.navigate(['/pre-liquidacoes-orcamentaria']);
          toastr.success('Anulação realizada com sucesso!');
        }, error => {
          if (error.error && error.error.payload) {
            toastr.error(error.error.payload);
          } else {
            toastr.error('Ocorreu um erro ao processar a sua solicitação');
          }
        });
  }

  buscarAnulacoes() {
    this.route.paramMap
      .pipe(switchMap(params => this.preLiquidacaoService.filtrar(0, -1,
        {
          relations: 'usuario_cadastro',
          anulacao: true,
          exercicio_id: this.login.exercicio.id,
          orgao_id: this.login.orgao.id,
          'empenho.numero': this.empNumero
        }
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (dados) => {
          this.listAnulacoes = dados.content;
        }, error => {
          if (error.error && error.error.payload) {
            toastr.error(error.error.payload);
          } else {
            toastr.error('Ocorreu um erro ao processar a sua solicitação');
          }
        });
  }

}
