// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Setor } from './setor.model';
import { ProdutoUnidade } from './produto-unidade.model';

export class SetorProduto extends BaseResourceModel {
  constructor(
    public id?: number,
    public estoque_minimo?: number,
    public estoque_maximo?: number,
    public produto_unidade?: ProdutoUnidade,
    public setor?: Setor,
  ) {
    super();
  }
  static converteJson(json: any): SetorProduto {
    return Object.assign(new SetorProduto(), json);
  }
}
