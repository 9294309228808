import { BaseResourceModel } from '../../models/base-resource.model';
import { Cidade } from '../geo/cidade.model';
import { TipoContratacaoModalidade } from './tipo-contratacao-modalidade.model';

export class Modalidade extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: number,
    public codigo_pncp?: number,
    public nome?: string,
    public valor_limite?: number,
    public prazo_limite?: number,
    public exigir_contrato?: boolean,
    public exigir_licitacao?: boolean,
    public exigir_convenio?: boolean,
    public modelo_ata?: string,
    public cidade?: Cidade,
    public tipos_contratacao?: TipoContratacaoModalidade[],
    public ativo?: boolean,
  ) {
    super();
  }

  static converteJson(json: any): Modalidade {
    return Object.assign(new Modalidade(), json);
  }
}
