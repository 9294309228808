import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { DespesaNivelRoutingModule } from './despesa-nivel-routing.module';
import { DespesaNivelComponent } from './despesa-nivel.component';


@NgModule({
  declarations: [
    DespesaNivelComponent,
  ],
  imports: [
    CommonModule,
    DespesaNivelRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
  ]
})
export class DespesaNivelModule { }
