import { ContratoSaldoRptDlgComponet } from './contrato-saldo-rpt-dlg/contrato-saldo-rpt-dlg.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ProdutoDlgModule } from 'almoxarifado-lib';
import { IMaskModule } from 'angular-imask';
import { PessoaModule, SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { LicitacaoAudespMenuModule } from '../licitacao-audesp-menu/licitacao-audesp-menu.module';
import { ServicoModule } from '../servico/servico.module';
import { ContratoAditamentoDlgComponent } from './contrato-aditamento-dlg/contrato-aditamento-dlg.component';
import { ContratoAditamentoComponent } from './contrato-aditamento/contrato-aditamento.component';
import { ContratoCompraComponent } from './contrato-compra/contrato-compra.component';
import { ContratoEmpenhoComponent } from './contrato-empenho/contrato-empenho.component';
import { ContratoExtratoRptDlgComponent } from './contrato-extrato-rpt-dlg/contrato-extrato-rpt-dlg.component';
import { ContratoFiscalComponent } from './contrato-fiscal/contrato-fiscal.component';
import { ContratoFormComponent } from './contrato-form/contrato-form.component';
import { ContratoHistoricoDlgComponent } from './contrato-historico-dlg/contrato-historico-dlg.component';
import { ContratoHistoricoComponent } from './contrato-historico/contrato-historico.component';
import { ContratoItemCadComponent } from './contrato-item-cad/contrato-item-cad.component';
import { ContratoItemComponent } from './contrato-item/contrato-item.component';
import { ContratoListComponent } from './contrato-list/contrato-list.component';
import { ContratoOcorrenciaComponent } from './contrato-ocorrencia/contrato-ocorrencia.component';
import { ContratoPagamentoComponent } from './contrato-pagamento/contrato-pagamento.component';
import { ContratoPncpComponent } from './contrato-pncp/contrato-pncp.component';
import { ContratoRcmsComponent } from './contrato-rcms/contrato-rcms.component';
import { ContratoReajusteDlgComponent } from './contrato-reajuste-dlg/contrato-reajuste-dlg.component';
import { ContratoReajusteComponent } from './contrato-reajuste/contrato-reajuste.component';
import { ContratoRecursoRptDlgComponent } from './contrato-recurso-rpt-dlg/contrato-recurso-rpt-dlg.component';
import { ContratoRescisaoDlgComponent } from './contrato-rescisao-dlg/contrato-rescisao-dlg.component';
import { ContratoRescisaoComponent } from './contrato-rescisao/contrato-rescisao.component';
import { ContratoResponsavelComponent } from './contrato-responsavel/contrato-responsavel.component';
import { ContratoRoutingModule } from './contrato-routing.module';
import { ContratoRptComponent } from './contrato-rpt/contrato-rpt.component';
import { ContratoStorageComponent } from './contrato-storage/contrato-storage.component';
import { ContratoViewComponent } from './contrato-view/contrato-view.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CheckboxModule } from 'primeng/checkbox';
import { ContratoTestemunhaComponent } from './contrato-testemunha/contrato-testemunha.component';
import { ContratoPppComponent } from './contrato-ppp/contrato-ppp.component';

@NgModule({
  declarations: [
    ContratoListComponent,
    ContratoViewComponent,
    ContratoFormComponent,
    ContratoRptComponent,
    ContratoPagamentoComponent,
    ContratoAditamentoComponent,
    ContratoAditamentoDlgComponent,
    ContratoResponsavelComponent,
    ContratoHistoricoComponent,
    ContratoHistoricoDlgComponent,
    ContratoReajusteComponent,
    ContratoReajusteDlgComponent,
    ContratoEmpenhoComponent,
    ContratoCompraComponent,
    ContratoRcmsComponent,
    ContratoStorageComponent,
    ContratoItemComponent,
    ContratoItemCadComponent,
    ContratoOcorrenciaComponent,
    ContratoRescisaoComponent,
    ContratoRescisaoDlgComponent,
    ContratoExtratoRptDlgComponent,
    ContratoRecursoRptDlgComponent,
    ContratoFiscalComponent,
    ContratoTestemunhaComponent,
    ContratoPncpComponent,
    ContratoSaldoRptDlgComponet,
    ContratoPppComponent
  ],
  imports: [
    CommonModule,
    ContratoRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    InputMaskModule,
    RadioButtonModule,
    SelectButtonModule,
    FileUploadModule,
    PanelModule,
    PessoaModule,
    LicitacaoAudespMenuModule,
    DialogModule,
    ConfirmDialogModule,
    ProdutoDlgModule,
    ServicoModule,
    FieldsetModule,
    CheckboxModule
  ],
  providers: [MessageService, ConfirmationService]
})
export class ContratoModule { }
