import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from './usuario.model';
import { Pagina } from './pagina';

export class Acesso extends BaseResourceModel {
    constructor(
        public id?: number,
        public pagina?: Pagina,
        public permissao?: number,
        public usuario?: Usuario,
        public nomePagina?: string
      ) {
        super();
      }

      static converteJson(json: any): Acesso {
        return Object.assign(new Acesso(), json);
      }
}
