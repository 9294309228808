import { BaseResourceStorage } from '../../models/base-resource.storage';
import { Setor } from './setor.model';

export class SetorStorage extends BaseResourceStorage {
  constructor(
    public setor?: Setor,
  ) {
    super();
  }

  static converteJson(json: any): SetorStorage {
    return Object.assign(new SetorStorage(), json);
  }
}
