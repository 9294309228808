import { NgModule } from '@angular/core';

import { PreLiquidacaoRoutingModule } from './pre-liquidacao-routing.module';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { IMaskModule } from 'angular-imask';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { PreLiquidacaoListComponent } from './pre-liquidacao-list/pre-liquidacao-list.component';
import { PreLiquidacaoViewComponent } from './pre-liquidacao-view/pre-liquidacao-view.component';
import { PreLiquidacaoAnulacaoComponent } from './pre-liquidacao-anulacao/pre-liquidacao-anulacao.component';
import { PreLiquidacaoFormComponent } from './pre-liquidacao-form/pre-liquidacao-form.component';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SharedModule } from 'eddydata-lib';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PreLiquidacaoRptComponent } from './pre-liquidacao-rpt/pre-liquidacao-rpt.component';
import { FieldsetModule } from 'primeng/fieldset';
import { NotaPreLiquidacaoDlgComponent } from './nota-pre-liquidacao-dlg/nota-pre-liquidacao-dlg.component';
import { PreLiquidacaoConsultaDlgComponent } from './pre-liquidacao-consulta-dlg/pre-liquidacao-consulta-dlg.component';

@NgModule({
  declarations: [
    PreLiquidacaoListComponent,
    PreLiquidacaoViewComponent,
    PreLiquidacaoFormComponent,
    PreLiquidacaoAnulacaoComponent,
    PreLiquidacaoRptComponent,
    NotaPreLiquidacaoDlgComponent,
    PreLiquidacaoConsultaDlgComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    PreLiquidacaoRoutingModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    RadioButtonModule,
    ConfirmDialogModule,
    FieldsetModule
  ],
  exports: [PreLiquidacaoConsultaDlgComponent, PreLiquidacaoFormComponent],
  providers: [MessageService, DatePipe, ConfirmationService]
})
export class PreLiquidacaoModule { }
