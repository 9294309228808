import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, ProgressoService, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../assinaturas/assinaturas';
import { AnexoLRFService } from '../../service/anexo-lrf.service';

@Directive()
export class DisponibilidadeFinanceira2023LRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLRFService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected progressoService: ProgressoService,
    protected assinaturaControleInterno: boolean) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // tslint:disable: max-line-length
  public montarRelatorio(orgaos: number[], formato: FormatoExportacao = 'pdf') {
    this.anexoServico.obterDisponibilidaFinanceira(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async idTransacao => {
        this.progressoService.show(idTransacao, async (retorno) => {
          if (formato === 'pdf') {
            Relatorio.imprimirPersonalizado(
              'DEMONSTRATIVO DAS DISPONIBILIDADES FINANCEIRAS ORÇAMENTÁRIAS'
              , this.login.usuario.nome, this.login.usuario.sobrenome,
              this.login.orgao.nome, this.login.brasao,
              await this.conteudo(retorno),
              'landscape', 'DEMONSTRATIVO DAS DISPONIBILIDADES FINANCEIRAS ORÇAMENTÁRIAS',
              {
                linhas: {
                  hLineWidth() {
                    return 1;
                  },
                  vLineWidth() {
                    return 1;
                  },
                  hLineColor() {
                    return 'black';
                  },
                  paddingLeft() {
                    return 3;
                  },
                  paddingRight() {
                    return 3;
                  }
                }
              });
          } else {
            this.funcaoService.exportar(formato, await this.normalizar(retorno), 'DEMONSTRATIVO DAS DISPONIBILIDADES FINANCEIRAS ORÇAMENTÁRIAS', this.colunas());
          }
        })
      });
  }

  private async conteudo(dados: any[]): Promise<{}[]> {
    // monta o cabecalho
    const registros: {}[] = [
      [
        {
          text: `REFERÊNCIA: ` +
            this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano,
          alignment: 'center',
          bold: true,
          colSpan: 13, border: [false, false, false, false]
        }, '', '', '', '', '', '', '', '', '', '', '', ''
      ],
      [
        {
          text: '\n\nÓRGÃO',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 3
        },
        {
          text: '\n\nFONTE DE RECURSOS',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 3
        },
        {
          text: '\n\nCÓDIGO DE APLICAÇÃO',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 3
        },
        {
          text: 'DISPONIB. FINANCEIRAS DO EXERCÍCIO (I)',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 3
        },
        {
          text: 'DISPONIB. FINANCEIRAS DE EXERCÍCIO ANTERIOR  (II)',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 3
        },
        {
          text: 'DISPONIB. FINANCEIRAS TOTAIS III (I + II)',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 3
        },
        {
          text: 'DEDUÇÕES',
          alignment: 'center',
          bold: true, fontSize: 6, colSpan: 3
        }, '', '',
        {
          text: 'DISPONIBILIDADES FINANCEIRAS LÍQUIDAS ( VI = III-IV-V )',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 3
        },
        {
          text: 'INSCRIÇÕES EM RESTOS A PAGAR NÃO PROCESSADO ( X )',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 3
        },
        {
          text: 'INSCRIÇÕES EM RESTOS A PAGAR  PROCESSADO ( XI )',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 3
        },
        {
          text: 'SUFICIÊNCIA / INSUFICIÊNCIA FINANCEIRA ( XII )',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 3
        }
      ],
      [
        '',
        '',
        '',
        '',
        '',
        '',
        {
          text: 'R.P. DE EXERCÍCIOS ANTERIORES (IV)',
          alignment: 'center',
          bold: true, fontSize: 6, colSpan: 2
        },
        '',
        {
          text: 'EMPENHOS LIQUIDADOS A PAGAR (V)',
          alignment: 'center',
          bold: true, fontSize: 6, rowSpan: 2
        },
        '',
        '',
        '',
        ''
      ],
      [
        '',
        '',
        '',
        '',
        '',
        '',
        {
          text: 'PROCESSADOS',
          alignment: 'center',
          bold: true, fontSize: 6
        },
        {
          text: 'NÃO PROCESSADOS',
          alignment: 'center',
          bold: true, fontSize: 6
        },
        '',
        '',
        '',
        '',
        '',
      ]
    ];

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(50, false, null, this.assinaturaControleInterno);

    // monta o agrupamento do relatório
    const grupos = this.funcaoService.agrupar(dados, 'orgao',
      ['anterior', 'exercicio', 'liquidado', 'rp_exercicio', 'rp_inscricao', 'rp_nao_processado', 'rp_nao_inscricao']);
    for (const grupo of grupos) {
      const somatoriaOrgao = await this.somatorias(grupo.totalizadores['exercicio'], grupo.totalizadores['anterior'], grupo.totalizadores['rp_exercicio'], grupo.totalizadores['rp_nao_processado'], grupo.totalizadores['liquidado'], grupo.totalizadores['rp_inscricao'], grupo.totalizadores['rp_nao_inscricao'])

      registros.push([
        { text: grupo.grupo, bold: true, fontSize: 6, colSpan: 3 },
        { text: '' },
        { text: '' },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['exercicio']), alignment: 'right', bold: true, fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['anterior']), alignment: 'right', bold: true, fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(somatoriaOrgao.campoIII), alignment: 'right', bold: true, fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['rp_exercicio']), alignment: 'right', bold: true, fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['rp_nao_processado']), alignment: 'right', bold: true, fontSize: 6 },
        { text: +this.mes > 11 ? '' : this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']), alignment: 'right', bold: true, fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(somatoriaOrgao.campoVI), alignment: 'right', bold: true, fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['rp_inscricao']), alignment: 'right', bold: true, fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['rp_nao_inscricao']), alignment: 'right', bold: true, fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(somatoriaOrgao.campoXII), alignment: 'right', bold: true, fontSize: 6 }
      ]);

      const recursos = this.funcaoService.agrupar(grupo.registros, 'recurso',
        ['anterior', 'exercicio', 'liquidado', 'rp_exercicio', 'rp_inscricao', 'rp_nao_processado', 'rp_nao_inscricao']);
      for (const recurso of recursos) {

        const somatoriaRecurso = await this.somatorias(+recurso.totalizadores['exercicio'], +recurso.totalizadores['anterior'], recurso.totalizadores['rp_exercicio'], recurso.totalizadores['rp_nao_processado'], recurso.totalizadores['liquidado'], recurso.totalizadores['rp_inscricao'], recurso.totalizadores['rp_nao_inscricao'])

        registros.push([
          { text: '' },
          { text: recurso.grupo, bold: true, fontSize: 6, colSpan: 2 },
          { text: '' },
          { text: this.funcaoService.convertToBrNumber(recurso.totalizadores['exercicio']), alignment: 'right', bold: true, fontSize: 6 },
          { text: this.funcaoService.convertToBrNumber(recurso.totalizadores['anterior']), alignment: 'right', bold: true, fontSize: 6 },
          { text: this.funcaoService.convertToBrNumber(somatoriaRecurso.campoIII), alignment: 'right', bold: true, fontSize: 6 },
          { text: this.funcaoService.convertToBrNumber(recurso.totalizadores['rp_exercicio']), alignment: 'right', bold: true, fontSize: 6 },
          { text: this.funcaoService.convertToBrNumber(recurso.totalizadores['rp_nao_processado']), alignment: 'right', bold: true, fontSize: 6 },
          { text: +this.mes > 11 ? '' : this.funcaoService.convertToBrNumber(recurso.totalizadores['liquidado']), alignment: 'right', bold: true, fontSize: 6 },
          { text: this.funcaoService.convertToBrNumber(somatoriaRecurso.campoVI), alignment: 'right', bold: true, fontSize: 6 },
          { text: this.funcaoService.convertToBrNumber(recurso.totalizadores['rp_inscricao']), alignment: 'right', bold: true, fontSize: 6 },
          { text: this.funcaoService.convertToBrNumber(recurso.totalizadores['rp_nao_inscricao']), alignment: 'right', bold: true, fontSize: 6 },
          { text: this.funcaoService.convertToBrNumber(somatoriaRecurso.campoXII), alignment: 'right', bold: true, fontSize: 6 }
        ]);

        for (const item of recurso.registros) {

          const somatoriaAplicacao = await this.somatorias(item.exercicio, item.anterior, item.rp_exercicio, item.rp_nao_processado, item.liquidado, item.rp_inscricao, item.rp_nao_inscricao)

          registros.push([
            { text: '', colSpan: 2 },
            { text: '' },
            { text: `${item.aplicacao}`, fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(item.exercicio), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(item.anterior), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(somatoriaAplicacao.campoIII), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(item.rp_exercicio), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(item.rp_nao_processado), alignment: 'right', fontSize: 6 },
            { text: +this.mes > 11 ? '' : this.funcaoService.convertToBrNumber(item.liquidado), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(somatoriaAplicacao.campoVI), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(item.rp_inscricao), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(item.rp_nao_inscricao), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(somatoriaAplicacao.campoXII), alignment: 'right', fontSize: 6 },
          ]);
        }
      }
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 4,
        widths: ['*', '*', '*', 44, 44, 44, 44, 44, 44, 44, 44, 44, 44],
        body: registros
      }
    },
    {
      layout: 'linhas',

      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*'],

        body: assinaturas
      }
    }
    ];
  }

  public async somatorias(exercicio: number, anterior: number, valorResto: number, rp_nao_processado: string | number, liquidado: string | number, rp_inscricao: string | number, rp_nao_inscricao: string | number) {
    let campoIII = +exercicio + +anterior
    let campoVI = campoIII - valorResto - +rp_nao_processado - (+this.mes > 11 ? 0 : +liquidado)
    let campoXII = campoVI - (+rp_inscricao + +rp_nao_inscricao)
    return { campoIII, campoVI, campoXII }
  }

  public async normalizar(lista: any[]) {
    const listaExportar = []

    let exercicio = 0;
    let anterior = 0;
    let nao_processado = 0;
    let liquidado = 0;
    let inscricao = 0;
    let nao_inscricao = 0;

    const grupos = this.funcaoService.agrupar(lista, 'orgao',
      ['anterior', 'exercicio', 'liquidado', 'rp_exercicio', 'rp_inscricao', 'rp_nao_processado', 'rp_nao_inscricao']);
    for (const grupo of grupos) {

      let valorRestoOrgao = 0;
      this.mes < 11 ? valorRestoOrgao = grupo.totalizadores['rp_exercicio'] :
        valorRestoOrgao = grupo.totalizadores['rp_exercicio'] - grupo.totalizadores['rp_inscricao'];

      const somatoriaOrgao = await this.somatorias(grupo.totalizadores['exercicio'], grupo.totalizadores['anterior'], valorRestoOrgao, grupo.totalizadores['rp_nao_processado'], grupo.totalizadores['liquidado'], grupo.totalizadores['rp_inscricao'], grupo.totalizadores['rp_nao_inscricao'])

      exercicio = grupo.totalizadores['exercicio'];
      anterior = grupo.totalizadores['anterior'];
      nao_processado = grupo.totalizadores['rp_nao_processado'];
      liquidado = grupo.totalizadores['liquidado'];
      inscricao = grupo.totalizadores['rp_inscricao']
      nao_inscricao = grupo.totalizadores['rp_nao_inscricao'];

      const primeira_linha = {
        orgao: grupo.grupo,
        fonte_recurso: '',
        codigo_aplicacao: '',
        diponibilidade1: this.funcaoService.convertToBrNumber(exercicio),
        disponibilidade2: this.funcaoService.convertToBrNumber(anterior),
        disponibilidade1_2: this.funcaoService.convertToBrNumber(somatoriaOrgao.campoIII),
        processados: this.funcaoService.convertToBrNumber(valorRestoOrgao),
        nao_processados: this.funcaoService.convertToBrNumber(nao_processado),
        empenhos_liquidados_pagar: this.funcaoService.convertToBrNumber(liquidado),
        disponibilidade_liquida: this.funcaoService.convertToBrNumber(somatoriaOrgao.campoVI),
        incricoes_nao_processadas: this.funcaoService.convertToBrNumber(inscricao),
        inscricoes_processadas: this.funcaoService.convertToBrNumber(nao_inscricao),
        suficiencia_insuficiencia: this.funcaoService.convertToBrNumber(somatoriaOrgao.campoXII)
      }
      listaExportar.push(primeira_linha)

      const recursos = this.funcaoService.agrupar(grupo.registros, 'recurso',
        ['anterior', 'exercicio', 'liquidado', 'rp_exercicio', 'rp_inscricao', 'rp_nao_processado', 'rp_nao_inscricao']);
      for (const recurso of recursos) {
        let valorRestoRecurso = 0;
        this.mes < 11 ? valorRestoRecurso = recurso.totalizadores['rp_exercicio'] :
          valorRestoRecurso = recurso.totalizadores['rp_exercicio'] - recurso.totalizadores['rp_inscricao'];

        const somatoriaRecurso = await this.somatorias(+recurso.totalizadores['exercicio'], +recurso.totalizadores['anterior'], valorRestoRecurso, recurso.totalizadores['rp_nao_processado'], recurso.totalizadores['liquidado'], recurso.totalizadores['rp_inscricao'], recurso.totalizadores['rp_nao_inscricao'])

        exercicio = recurso.totalizadores['exercicio'];
        anterior = recurso.totalizadores['anterior'];
        nao_processado = recurso.totalizadores['rp_nao_processado'];
        liquidado = recurso.totalizadores['liquidado'];
        inscricao = recurso.totalizadores['rp_inscricao']
        nao_inscricao = recurso.totalizadores['rp_nao_inscricao'];

        const linha_recurso = {
          orgao: '',
          fonte_recurso: recurso.grupo,
          codigo_aplicacao: '',
          diponibilidade1: this.funcaoService.convertToBrNumber(exercicio),
          disponibilidade2: this.funcaoService.convertToBrNumber(anterior),
          disponibilidade1_2: this.funcaoService.convertToBrNumber(somatoriaRecurso.campoIII),
          processados: this.funcaoService.convertToBrNumber(valorRestoRecurso),
          nao_processados: this.funcaoService.convertToBrNumber(nao_processado),
          empenhos_liquidados_pagar: this.funcaoService.convertToBrNumber(liquidado),
          disponibilidade_liquida: this.funcaoService.convertToBrNumber(somatoriaRecurso.campoVI),
          incricoes_nao_processadas: this.funcaoService.convertToBrNumber(inscricao),
          inscricoes_processadas: this.funcaoService.convertToBrNumber(nao_inscricao),
          suficiencia_insuficiencia: this.funcaoService.convertToBrNumber(somatoriaRecurso.campoXII)
        }
        listaExportar.push(linha_recurso)

        for (const item of recurso.registros) {

          let valorRestoExercicio = 0;
          this.mes < 11 ? valorRestoExercicio = +item.rp_exercicio :
            valorRestoExercicio = +item.rp_exercicio - +item.rp_inscricao;

          const somatoriaAplicacao = await this.somatorias(item.exercicio, item.anterior, valorRestoExercicio, item.rp_nao_processado, item.liquidado, item.rp_inscricao, item.rp_nao_inscricao)
          
          const itens_recurso = {
            orgao: '',
            fonte_recurso: '',
            codigo_aplicacao: `${item.aplicacao}`,
            diponibilidade1: this.funcaoService.convertToBrNumber(item.exercicio),
            disponibilidade2: this.funcaoService.convertToBrNumber(item.anterior),
            disponibilidade1_2: this.funcaoService.convertToBrNumber(somatoriaAplicacao.campoIII),
            processados: this.funcaoService.convertToBrNumber(valorRestoExercicio),
            nao_processados: this.funcaoService.convertToBrNumber(item.rp_nao_processado),
            empenhos_liquidados_pagar: this.funcaoService.convertToBrNumber(item.liquidado),
            disponibilidade_liquida: this.funcaoService.convertToBrNumber(somatoriaAplicacao.campoVI),
            incricoes_nao_processadas: this.funcaoService.convertToBrNumber(item.rp_inscricao),
            inscricoes_processadas: this.funcaoService.convertToBrNumber(item.rp_nao_inscricao),
            suficiencia_insuficiencia: this.funcaoService.convertToBrNumber(somatoriaAplicacao.campoXII)
          }
          listaExportar.push(itens_recurso)
        }
      }
    }
    return listaExportar
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'ORGÃO', coluna: 'orgao', bold: true },
      { titulo: 'FONTE DE RECURSOS', coluna: 'fonte_recurso' },
      { titulo: 'CÓDIGO DE APLICAÇÃO', coluna: 'codigo_aplicacao' },
      { titulo: 'DISPONIB. FINANCEIRAS DO EXERCÍCIO (I)', coluna: 'diponibilidade1' },
      { titulo: 'DISPONIB. FINANCEIRAS DE EXERCÍCIO ANTERIOR  (II)', coluna: 'disponibilidade2' },
      { titulo: 'DISPONIB. FINANCEIRAS TOTAIS III (I + II)', coluna: 'disponibilidade1_2' },
      { titulo: 'PROCESSADOS', coluna: 'processados' },
      { titulo: 'NÃO PROCESSADOS', coluna: 'nao_processados' },
      { titulo: 'EMPENHOS LIQUIDADOS A PAGAR (V)', coluna: 'empenhos_liquidados_pagar' },
      { titulo: 'DISPONIBILIDADES FINANCEIRAS LÍQUIDAS ( VI = III-IV-V )', coluna: 'disponibilidade_liquida' },
      { titulo: 'INSCRIÇÕES EM RESTOS A PAGAR NÃO PROCESSADO ( X )', coluna: 'incricoes_nao_processadas' },
      { titulo: 'INSCRIÇÕES EM RESTOS A PAGAR  PROCESSADO ( XI )', coluna: 'inscricoes_processadas' },
      { titulo: 'SUFICIÊNCIA / INSUFICIÊNCIA FINANCEIRA ( XII )', coluna: 'suficiencia_insuficiencia' },
    ];
    return colunasDefault;
  }
}
