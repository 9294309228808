import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReceitaService } from '../../../planejamento/receita/service/receita.service';
import { ContaBancaria, Convenio, EddyAutoComplete, FichaReceita, FuncaoService, GlobalService, Login, Recebimento, Receita, Recurso, Relatorio } from 'eddydata-lib';
import { Assinaturas, ContaBancariaService, ConvenioService, FichaReceitaService, RecebimentoService, RecursoService } from 'administrativo-lib';
import { DatePipe } from '@angular/common';
import * as extenso from 'extenso'

@Component({
  selector: 'app-recebimento-rpt',
  templateUrl: './recebimento-rpt.component.html'
})
export class RecebimentoRptComponent implements OnInit, OnDestroy {

  login: Login = new Login();
  conta: ContaBancaria;
  receita: Receita;
  recurso: Recurso;
  aplicacao: Recurso;
  ficha: FichaReceita;
  convenio: Convenio;
  filtroReceita: boolean;
  filtroConta: boolean;
  filtroConvenio: boolean;
  filtroRecurso: boolean;
  filtroAplicacao: boolean;
  filtroFicha: boolean;
  selectUsuario: boolean;
  public selectedOrdem: string;
  public selectedOrdenacao: string;
  public selectedAgrupamento: string;

  public ptBR: any;

  public listaRelatorio: Array<any>;
  public listaConvenio: Array<Convenio>;
  public contaAutoComplete: EddyAutoComplete<ContaBancaria>;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public receitaAutoComplete: EddyAutoComplete<Receita>;
  public fichaAutoComplete: EddyAutoComplete<FichaReceita>;
  public relatorio = '1';
  public dataInicial: Date;
  public dataFinal: Date;
  protected datepipe: DatePipe;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected injector: Injector,
    protected messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected contaService: ContaBancariaService,
    protected recursoService: RecursoService,
    protected receitaService: ReceitaService,
    protected fichaReceitaService: FichaReceitaService,
    protected convenioService: ConvenioService,
    protected recebimentoService: RecebimentoService) {
    this.datepipe = new DatePipe('pt')
  }

  async ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.login = GlobalService.obterSessaoLogin();
    this.selectedOrdem = 'ord1';
    this.selectedOrdenacao = 'ASC';
    this.selectedAgrupamento = '';
    this.listaRelatorio = [];
    this.listaRelatorio.push({ label: 'TODOS OS RECEBIMENTOS', value: 1 });
    this.listaRelatorio.push({ label: 'RECEBIMENTOS ANULADOS', value: 2 });
    this.listaRelatorio.push({ label: 'NOTA DE ANULAÇÃO', value: 3 });
    this.carregarAutoCompletes();
    await this.preencheAutoCompletes(this.activatedRoute.snapshot.queryParams);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async gerarRelatorio() {
    const parametros = {};
    if (!this.dataInicial || !this.dataFinal) {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }

    parametros['ignoreCondObrig'] = true;
    parametros['data_recebimento$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
    parametros['data_recebimento$le'] = this.funcaoService.converteDataSQL(this.dataFinal);
    parametros['orgao.id'] = this.login.orgao.id

    if (this.filtroConvenio) {
      parametros['convenio.numero'] = this.convenio.numero;
    }

    if (this.filtroConta) {
      parametros['conta.id'] = this.conta.id;
    }

    if (this.filtroReceita) {
      parametros['ficha.receita.id'] = this.receita.id;
    }

    if (this.filtroRecurso) {
      parametros['ficha.recurso.id'] = this.recurso.id;
    }

    if (this.filtroAplicacao) {
      parametros['ficha.aplicacao.id'] = this.aplicacao.id;
    }

    if (this.filtroFicha) {
      parametros['ficha.id'] = this.ficha.id;
    }

    if (this.selectUsuario) {
      parametros['usuario_cadastro.id'] = this.login.usuario.id;
    }

    if (['2', '3'].includes(this.relatorio)) {
      parametros['especie'] = 'ROA';
    }

    if (this.selectedOrdem === 'ord1') {
      // parametros['orderBy'] = 'data_recebimento$' + this.selectedOrdenacao;
      if (this.selectedAgrupamento === 'ord1') {
        parametros['orderBy'] = 'data_recebimento,ficha.receita.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,data_recebimento$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === 'ord2') {
        parametros['orderBy'] = 'conta.numero_conta,ficha.receita.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,data_recebimento$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === 'ord3') {
        parametros['orderBy'] = 'ficha.numero,ficha.receita.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,data_recebimento$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === '') {
        parametros['orderBy'] = 'data_recebimento$' + this.selectedOrdenacao;
      }
    } else if (this.selectedOrdem === 'ord2') {
      // parametros['orderBy'] = 'conta.numero_conta$' + this.selectedOrdenacao;
      if (this.selectedAgrupamento === 'ord1') {
        parametros['orderBy'] = 'data_recebimento,ficha.receita.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,conta.numero_conta$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === 'ord2') {
        parametros['orderBy'] = 'conta.numero_conta,ficha.receita.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,conta.numero_conta$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === 'ord3') {
        parametros['orderBy'] = 'ficha.numero,ficha.receita.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,conta.numero_conta$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === '') {
        parametros['orderBy'] = 'conta.numero_conta$' + this.selectedOrdenacao;
      }
    } else if (this.selectedOrdem === 'ord3') {
      // parametros['orderBy'] = 'ficha.receita.nome$' + this.selectedOrdenacao;
      if (this.selectedAgrupamento === 'ord1') {
        parametros['orderBy'] = 'data_recebimento,ficha.receita.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,ficha.receita.codigo$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === 'ord2') {
        parametros['orderBy'] = 'conta.numero_conta,ficha.receita.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,ficha.receita.codigo$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === 'ord3') {
        parametros['orderBy'] = 'ficha.numero,ficha.receita.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,ficha.receita.codigo$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === '') {
        parametros['orderBy'] = 'ficha.receita.nome$' + this.selectedOrdenacao;
      }
    } else if (this.selectedOrdem === 'ord4') {
      // parametros['orderBy'] = 'ficha.numero$' + this.selectedOrdenacao;
      if (this.selectedAgrupamento === 'ord1') {
        parametros['orderBy'] = 'data_recebimento,ficha.receita.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,ficha.numero$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === 'ord2') {
        parametros['orderBy'] = 'conta.numero_conta,ficha.receita.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,ficha.numero$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === 'ord3') {
        parametros['orderBy'] = 'ficha.numero,ficha.receita.codigo,ficha.recurso.codigo,ficha.aplicacao.codigo,ficha.numero$' + this.selectedOrdenacao;
      } else if (this.selectedAgrupamento === '') {
        parametros['orderBy'] = 'ficha.numero$' + this.selectedOrdenacao;
      }
    }

    parametros['relations'] = 'conta,ficha,ficha.receita,ficha.recurso,ficha.aplicacao,ficha.aplicacao_variavel';

    this.recebimentoService.filtrar(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((lista) => {
        const listaAgrupada = this.agruparRecebimentos(lista.content)
        if (this.relatorio === '3') {
          this.imprimeNotaDeAnulacao(lista.content);
        } else {
          this.imprimeRecebimentos(listaAgrupada);
        }
      })
  }

  private imprimeRecebimentos(listaAgrupada: { layout: string; table: { widths: (string | number)[]; body: any[]; }; }[]) {
    Relatorio.imprimirPersonalizado(
      `RECEBIMENTOS ORÇAMENTÁRIOS PERÍODO DE ${this.datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${this.datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
      this.login.usuario.nome,
      this.login.usuario.sobrenome,
      this.login.orgao.nome,
      this.login.brasao,
      listaAgrupada,
      'landscape',
      'Listagem recebimentos',
      {
        linhas: {
          hLineWidth() {
            return 1;
          },
          vLineWidth() {
            return 1;
          },
          hLineColor() {
            return 'black';
          },
          paddingLeft() {
            return 3;
          },
          paddingRight() {
            return 3;
          }
        }
      }
    );
  }

  private async imprimeNotaDeAnulacao(recebimentos: Recebimento[]) {
    let conteudo = await Promise.all(recebimentos.map((r, i) => this.montaConteudo(r, i)))

    Relatorio.imprimirPersonalizado(
      `ANULAÇÃO DE RECEITA ${this.datepipe.transform(this.dataFinal, 'yyyy')}`,
      this.login.usuario.nome,
      this.login.usuario.sobrenome,
      this.login.orgao.nome,
      this.login.brasao,
      conteudo,
      'portrait',
      'Notas de Anulação',
      {
        linhas: {
          hLineWidth() {
            return 1;
          },
          vLineWidth() {
            return 1;
          },
          hLineColor() {
            return 'black';
          },
          paddingLeft() {
            return 3;
          },
          paddingRight() {
            return 3;
          }
        }
      }
    );
  }

  private async buscaClassificacaoDaReceita(receita: Receita) {
    const TAMANHO_MAXIMO = 8
    const PREENCHIMENTO = '0'

    let n1 = receita.n1.padEnd(TAMANHO_MAXIMO, PREENCHIMENTO)
    let n2 = (receita.n1 + receita.n2).padEnd(TAMANHO_MAXIMO, PREENCHIMENTO)
    let n3 = (receita.n1 + receita.n2 + receita.n3).padEnd(TAMANHO_MAXIMO, PREENCHIMENTO)
    let n4 = (receita.n1 + receita.n2 + receita.n3 + receita.n4).padEnd(TAMANHO_MAXIMO, PREENCHIMENTO)
    let n5 = (receita.n1 + receita.n2 + receita.n3 + receita.n4 + receita.n5).padEnd(TAMANHO_MAXIMO, PREENCHIMENTO)
    let n6 = (receita.n1 + receita.n2 + receita.n3 + receita.n4 + receita.n5 + receita.n6).padEnd(TAMANHO_MAXIMO, PREENCHIMENTO)
    let n7 = (receita.n1 + receita.n2 + receita.n3 + receita.n4 + receita.n5 + receita.n6 + receita.n7).padEnd(TAMANHO_MAXIMO, PREENCHIMENTO)
    let n8 = receita.n1 + receita.n2 + receita.n3 + receita.n4 + receita.n5 + receita.n6 + receita.n7 + receita.n8

    let classificacaoReceita = {
      nivel1: { codigo: n1, nome: '' },
      nivel2: { codigo: n2, nome: '' },
      nivel3: { codigo: n3, nome: '' },
      nivel4: { codigo: n4, nome: '' },
      nivel5: { codigo: n5, nome: '' },
      nivel6: { codigo: n6, nome: '' },
      nivel7: { codigo: n7, nome: '' },
      nivel8: { codigo: n8, nome: '' },
    }

    let res = await this.receitaService.filtrar(1, -1, {
      'exercicio.id': this.login.exercicio.id,
      'OR': `codigo=${n1};!;!;codigo=${n2};!;!;codigo=${n3};!;!;codigo=${n4};!;!;codigo=${n5};!;!;codigo=${n6};!;!;codigo=${n7};!;!;codigo=${n8}`
    }).toPromise()

    res.content.forEach(classificacao => {
      switch (classificacao.codigo) {
        case n1:
          classificacaoReceita.nivel1.nome = classificacao.nome;
          break;
        case n2:
          classificacaoReceita.nivel2.nome = classificacao.nome;
          break;
        case n3:
          classificacaoReceita.nivel3.nome = classificacao.nome;
          break;
        case n4:
          classificacaoReceita.nivel4.nome = classificacao.nome;
          break;
        case n5:
          classificacaoReceita.nivel5.nome = classificacao.nome;
          break;
        case n6:
          classificacaoReceita.nivel6.nome = classificacao.nome;
          break;
        case n7:
          classificacaoReceita.nivel7.nome = classificacao.nome;
          break;
        case n8:
          classificacaoReceita.nivel8.nome = classificacao.nome;
          break;
      }
    });

    return classificacaoReceita;
  }

  private async montaConteudo(recebimento: Recebimento, index: number) {
    let assinaturas = await new Assinaturas(this.login.orgao, this.injector).dadosAssinatura(null, false, null, false);

    let classificacaoReceita = await this.buscaClassificacaoDaReceita(recebimento.ficha.receita)

    let dataRecebimento = new Date(recebimento.data_recebimento)
    const dd = [
      {
        pageBreak: index > 0 ? 'before' : '',
        table: {
          headerRows: 1,
          widths: ["*", "*"],
          body: [
            [
              { text: "Data", bold: true, fontSize: 10, border: [true, true, true, false] },
              { text: "Ficha", bold: true, fontSize: 10, border: [false, true, true, false] },
            ],
            [
              {
                text: `${dataRecebimento.getDate() + 1}-${dataRecebimento.getMonth() + 1}-${dataRecebimento.getFullYear()}`,
                alignment: "center",
                border: [true, false, true, false],
              },
              {
                text: recebimento.ficha.numero,
                alignment: "center",
                border: [false, false, true, false],
              },
            ],
          ],
        },
      },
      {
        table: {
          widths: "*",
          body: [
            [
              {
                columns: [
                  {
                    width: "*",
                    type: "none",
                    ul: [
                      `CATEGORIA:\t${classificacaoReceita.nivel1.codigo}\t${classificacaoReceita.nivel1.nome}`,
                      `SUB-CATEGORIA:\t${classificacaoReceita.nivel2.codigo}\t${classificacaoReceita.nivel2.nome}`,
                      `FONTE:\t${classificacaoReceita.nivel3.codigo}\t${classificacaoReceita.nivel3.nome}`,
                      `ALINEA:\t${classificacaoReceita.nivel4.codigo}\t${classificacaoReceita.nivel4.nome}`,
                      `SUB-ALINEA:\t${classificacaoReceita.nivel5.codigo}\t${classificacaoReceita.nivel5.nome}`,
                      `ITEM:\t${classificacaoReceita.nivel6.codigo}\t${classificacaoReceita.nivel6.nome}`,
                    ],
                  },
                ],
              },
            ],
          ],
        },
      },
      {
        table: {
          widths: "*",
          body: [
            [{ text: "Beneficiario", bold: true, fontSize: 10, border: [true, false, true, false] }],
            [
              {
                border: [true, false, true, true],
                columns: [
                  {
                    width: "*",
                    type: "none",
                    ul: [
                      "CPF/CNPJ:",
                      "TIPO PESSOA:",
                      "TELEFONE:",
                      "ENDEREÇO:",
                      "BAIRRO:",
                      "CIDADE:",
                    ],
                  },
                  {
                    width: "*",
                    type: "none",
                    ul: [
                      `BANCO:\t${recebimento.conta.nome}`,
                      `AGÊNCIA:\t${recebimento.conta.agencia}`,
                      `C/C:\t${recebimento.conta.numero_conta}`,
                      `CÓDIGO:\t${recebimento.conta.digito_conta}`
                    ],
                  },
                ],
              },
            ],
          ],
        },
      },
      {
        table: {
          widths: "*",
          body: [
            [
              { text: "Histórico", bold: true, fontSize: 10, border: [true, false, true, false] },
              { text: "Observações", bold: true, fontSize: 10, border: [true, false, true, false] }
            ],
            [
              { text: recebimento.historico, border: [true, false, true, false] },
              { text: "", border: [true, false, true, false] }
            ],
            [
              { text: "", border: [true, false, false, true] },
              { layout: "noBorders", bold: true, table: { widths: "*", body: [["VALOR", this.funcaoService.formatarMoedaPtBr(recebimento.valor_recebido)]] } },
            ]
          ],
        },
      },
      {
        table: {
          widths: "*",
          body: [
            [
              { text: "Valor por Extenso", bold: true, fontSize: 10, border: [true, false, true, false] },
            ],
            [{ text: extenso(this.funcaoService.convertToBrNumber(recebimento.valor_recebido * -1), { mode: 'currency' }).toUpperCase(), border: [true, false, true, true] }],
          ],
        },
      },
      {
        table: {
          widths: ['*', 'auto'],
          body: [
            [
              {
                border: [true, false, true, false],
                colSpan: 2,
                table: {
                  dontBreakRows: true,
                  headerRows: 0,
                  widths: ['*', '*', '*'],
                  body: assinaturas
                }
              },
              { text: "" }
            ],
            [
              { text: "Data:    ____/____/________", alignment: "center", border: [true, false, false, true], margin: [0, 30, 1, 5] },
              { text: "Data:    ____/____/________", alignment: "center", border: [false, false, true, true], margin: [0, 30, 1, 5] },
            ],
          ]
        }
      },
    ]

    return dd
  }

  public agruparRecebimentos(lista: any[]) {
    const total = [];
    const conteudo = [];
    let listaAgrupada: any[];

    if (this.selectedAgrupamento === 'ord1') {
      listaAgrupada = this.funcaoService.agrupar(lista, ['data_recebimento', 'ficha.receita.codigo', 'ficha.recurso.codigo', 'ficha.aplicacao.codigo', 'ficha.aplicacao_variavel.codigo'], ['valor_recebido'])
    } else if (this.selectedAgrupamento === 'ord2') {
      listaAgrupada = this.funcaoService.agrupar(lista, ['conta.numero_conta', 'ficha.receita.codigo', 'ficha.recurso.codigo', 'ficha.aplicacao.codigo', 'ficha.aplicacao_variavel.codigo'], ['valor_recebido'])
    } else if (this.selectedAgrupamento === 'ord3') {
      listaAgrupada = this.funcaoService.agrupar(lista, ['ficha.numero', 'ficha.receita.codigo', 'ficha.recurso.codigo', 'ficha.aplicacao.codigo', 'ficha.aplicacao_variavel.codigo'], ['valor_recebido'])
    } else if (this.selectedAgrupamento === '') {
      listaAgrupada = lista
    }

    conteudo.push([
      { text: `Data` },
      { text: `Conta N˚` },
      { text: `` },
      { text: `Descrição` },
      { text: `Guia` },
      { text: `Receita` },
      { text: `` },
      { text: `Ficha` },
      { text: `Variável` },
      { text: `Valor` },
    ]);

    if (this.selectedAgrupamento !== '') {
      let valor_total = 0;
      for (let item of listaAgrupada) {
        valor_total += +item.totalizadores['valor_recebido'];
        conteudo.push([
          { text: this.funcaoService.converteDataBR(item.registros[0].data_recebimento) },
          { text: item.registros[0].conta.numero_conta },
          { text: item.registros[0].conta.digito_conta },
          { text: item.registros[0].conta.nome },
          { text: item.registros[0].guia },
          { text: item.registros[0].ficha.receita.codigo }, //
          { text: item.registros[0].ficha.receita.nome },
          { text: item.registros[0].ficha.numero },
          { text: `${item.registros[0].ficha.aplicacao_variavel.codigo} ${item.registros[0].ficha.aplicacao_variavel.nome}` },
          { text: this.funcaoService.formatarMoedaPtBr(item.totalizadores['valor_recebido']) },
        ]);
      }
      total.push([
        { text: 'TOTAL GERAL', alignment: 'center', bold: true },
        { text: '' },
        { text: this.funcaoService.convertToBrNumber(valor_total, 2), alignment: 'center', bold: true }
      ]);
    } else {
      let valor_total = 0;
      for (let item of listaAgrupada) {
        valor_total += +item.valor_recebido;
        conteudo.push([
          { text: this.funcaoService.converteDataBR(item.data_recebimento) },
          { text: item.conta.numero_conta },
          { text: item.conta.digito_conta },
          { text: item.conta.nome },
          { text: item.guia },
          { text: item.ficha.receita.codigo }, //
          { text: item.ficha.receita.nome },
          { text: item.ficha.numero },
          { text: `${item.ficha.aplicacao_variavel.codigo} ${item.ficha.aplicacao_variavel.nome}`},
          { text: this.funcaoService.formatarMoedaPtBr(item.valor_recebido) }
        ]);
      }
      total.push([
        { text: 'TOTAL GERAL', alignment: 'center', bold: true },
        { text: '' },
        { text: this.funcaoService.convertToBrNumber(valor_total, 2), alignment: 'center', bold: true }
      ]);
    }

    return [{
      layout: 'linhas',
      table: {
        widths: [55, 65, 5, 100, 40, 50, '*', 20, 150, 60],
        body: conteudo
      }
    }, {
      layout: 'linhas',
      table: {
        widths: [60, '*', 60],
        body: total
      }
    }];
  }

  ngAfterViewInit() {
    new GlobalService().calendarMascara();
  }

  private async preencheAutoCompletes(queryParameters) {
    let promises = [];

    if (queryParameters.data_recebimento) {
      let data = queryParameters.data_recebimento.toString().split('-')
      this.dataInicial = new Date(+data[0], +data[1] - 1, +data[2]);
      this.dataFinal = this.dataInicial
    }

    if (queryParameters.conta_bancaria) {
      this.filtroConta = true;
      promises.push(this.contaService.obterId(+queryParameters.conta_bancaria).toPromise()
        .then(conta => {
          this.conta = conta;
          return this.contaAutoComplete.atualizar(true);
        }));
    }

    if (queryParameters.receita) {
      this.filtroReceita = true;
      promises.push(this.receitaService.obterId(+queryParameters.receita).toPromise()
        .then(receita => {
          this.receita = receita;
          return this.receitaAutoComplete.atualizar(true);
        }));
    }

    if (queryParameters.recurso) {
      this.filtroRecurso = true;
      promises.push(this.recursoService.obterId(+queryParameters.recurso).toPromise()
        .then(recurso => {
          this.recurso = recurso;
          return this.contaAutoComplete.atualizar(true);
        }));
    }

    if (queryParameters.aplicacao) {
      this.filtroAplicacao = true;
      promises.push(this.recursoService.obterId(+queryParameters.aplicacao).toPromise()
        .then(aplicacao => {
          this.aplicacao = aplicacao;
          return this.aplicacaoAutoComplete.atualizar(true);
        }));
    }

    if (queryParameters.ficha) {
      this.filtroFicha = true;
      promises.push(this.fichaReceitaService.filtrar(1, -1, { id: +queryParameters.ficha, relations: 'receita' }).toPromise()
        .then(ficha => {
          this.ficha = ficha.content[0];
          return this.fichaAutoComplete.atualizar(true);
        }));
    }

    return Promise.all(promises);
  }

  private carregarAutoCompletes() {
    // autocomplete para conta
    this.contaAutoComplete = new EddyAutoComplete(
      null,
      this.contaService, "id", ["codigo", "banco.nome", "nome"],
      { orgao_id: this.login.orgao.id, ativo: true, relations: "banco", orderBy: "codigo" },
      { number: ["codigo"], text: ["banco.nome", "nome"] }
    );

    // autocomplete para receita
    this.receitaAutoComplete = new EddyAutoComplete(null, this.receitaService,
      'id', ['codigo', 'nome'], {
      escrituracao: true, exercicio_id: this.login.exercicio.id,
      orderBy: 'nome'
    }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para recurso
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome', 'codigo'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para ficha
    this.fichaAutoComplete = new EddyAutoComplete(null, this.fichaReceitaService,
      'id', ['numero', 'receita.nome'], { exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id, relations: 'receita', orderBy: 'numero' }, { number: ['numero'], text: ['receita.nome'] }
    );

  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }

  public voltar() {
    if (this.login.sistema === 'contabil') {
      this.router.navigate(['/tesouraria']);
    } else {
      this.sair();
    }
  }
}
