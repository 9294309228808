import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Relatorio } from '../components/report';
import { Coluna, FormatoExportacao } from '../components/types';
import { Login } from '../entidade/login/login';
import { FuncaoService } from '../util/funcao.service';
import { GlobalService } from '../util/global.service';
import * as toastr from 'toastr';

@Directive()
export abstract class BaseResourceRptComponent implements OnInit, OnDestroy {

  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================
  public login: Login;
  public ptBR: any;

  public formato: FormatoExportacao;
  public listFormato: FormatoExportacao[];

  public orientacao: 'landscape' | 'portrait';
  public listOrientacao: { id: 'landscape' | 'portrait', nome: string }[];

  protected unsubscribe: Subject<void> = new Subject();
  protected funcaoService: FuncaoService;

  // ========================================================================
  // ------------------------------ CONSTRUTOR ------------------------------
  // ========================================================================
  constructor() {
    this.login = GlobalService.obterSessaoLogin();
    this.ptBR = new GlobalService().obterDataBR();
  }

  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================
  protected abstract afterInit(): void;
  protected abstract tituloRelatorio(): string;
  protected abstract obterColunasRelatorio(): Coluna[];
  protected abstract larguraColunas(): (string | number)[];
  protected abstract totalizarColunas(): undefined | (string | {})[];
  protected abstract carregarLista(): Promise<any[]>;

  protected subTituloRelatorio(): string {
    return ''
  };

  protected subTitulo(): string {
    return ''
  };

  protected conteudoAdicional(lista?: any): {}[] {
    return null;
  }

  protected layout(): {} {
    return null;
  }


  protected mensagemVazio(): string {
    return null;
  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================
  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  ngOnInit(): void {
    this.funcaoService = new FuncaoService();

    this.listFormato = ['pdf', 'xlsx', 'docx', 'xml', 'csv', 'JSON'];
    this.formato = 'pdf';

    this.listOrientacao = [{ id: 'portrait', nome: 'Retrato' }, { id: 'landscape', nome: 'Paisagem' }];
    this.orientacao = 'portrait';

    this.afterInit();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public imprimir() {
    this.carregarLista().then((lista) => {
      if ((!lista || lista.length === 0) && this.mensagemVazio()) {
        toastr.options.positionClass='toast-top-left';
        toastr.warning(this.mensagemVazio());
        return;
      }
      const colunasRelatorio = this.obterColunasRelatorio();      
      if (this.formato === 'pdf') {
        Relatorio.imprimir(this.subTituloRelatorio() ? this.subTituloRelatorio() : this.tituloRelatorio(),
          this.login.usuario.nome, this.login.usuario.sobrenome, this.subTituloRelatorio() ? this.tituloRelatorio() : this.login.orgao.nome, this.login.brasao,
          lista, colunasRelatorio, this.orientacao, this.tituloRelatorio(),
          this.larguraColunas(), (this.totalizarColunas() ? this.totalizarColunas() : undefined),
          this.conteudoAdicional(lista), this.layout(),this.subTitulo()
        );
      } else {
        new FuncaoService().exportar(this.formato, lista, this.tituloRelatorio(), colunasRelatorio);
      }
    })
  }

}
