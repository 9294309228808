import { NgModule } from '@angular/core';

import { FichaReceitaRoutingModule } from './ficha-receita-routing.module';
import { IMaskModule } from 'angular-imask';
import { FormsModule } from '@angular/forms';
import { FichaReceitaDlgComponent } from './ficha-receita-dlg/ficha-receita-dlg.component';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { SharedModule } from 'eddydata-lib';
import { PanelModule } from 'primeng/panel';
import { FieldsetModule } from 'primeng/fieldset';
import { FichaReceitaViewComponent } from './ficha-receita-view/ficha-receita-view.component';

@NgModule({
  declarations: [FichaReceitaViewComponent, FichaReceitaDlgComponent],
  exports: [FichaReceitaViewComponent, FichaReceitaDlgComponent],
  imports: [
    SharedModule,
    FormsModule,
    FichaReceitaRoutingModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    PanelModule,
    FieldsetModule
  ],
  providers: [MessageService]
})
export class FichaReceitaViewModule { }
