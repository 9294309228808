import { BaseResourceModel } from '../../models/base-resource.model';
import { Receita } from "./receita.model";
import { ReceitaSiconfi } from './receita-siconfi.model';

export class ReceitaSiconfiReceita extends BaseResourceModel {
  constructor(
    public id?: number,
    public receita_siconfi?: ReceitaSiconfi,
    public receita?: Receita) {
    super();
  }
  static converteJson(json: any): ReceitaSiconfiReceita {
    return Object.assign(new ReceitaSiconfiReceita(), json);
  }
}