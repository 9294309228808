import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import {
  BaseResourceListComponent, Exercicio, LoginPublico,
  ExercicioService, Filtro, Cargo, Coluna, MapaRotasTransparenciaService
} from 'eddydata-lib';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-transparencia-pesquisa',
  templateUrl: './transparencia-pesquisa.component.html'
})
export class TransparenciaPesquisaComponent extends BaseResourceListComponent<Exercicio, LoginPublico> {
  /**
   * Declaração de variáveis
   */
  public data1: string;
  public data2: string;
  public datepipe: DatePipe;
  public listaGeral = [];
  public listaRotas = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private route: ActivatedRoute,
    protected injector: Injector,
    private exService: ExercicioService,
    protected mapaRotasServico: MapaRotasTransparenciaService,
  ) {
    super(exService, injector);
    this.datepipe = new DatePipe('pt');
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'cidade';
  }

  protected condicoesGrid(): {} {
    return {
      ['cidade.id']: this.login.cidade.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['id$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {
    };
  }

  protected afterInit(): void {
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        const filtro = params.filtro;
        this.listaRotas = this.mapaRotasServico.buscarRotaTransparencia();
        if (this.listaGeral.length > 0) {
          this.listaGeral = [];
        }
        for (const rota of this.listaRotas) {
          if (rota.pagina.includes(filtro.toUpperCase())) {
            this.listaGeral.push(rota)
          }
        }
      });
  }

  protected acaoRemover(model: Cargo): Observable<Cargo> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
    ];
  }

  public imprimirPDF() {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public buscar(item: any) {
    this.router.navigate([item.rota]);
  }
}
