import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ContratoService, DespesaService, ProponenteService } from 'administrativo-lib';
import { CompraItem, FuncaoService, GlobalService, LoginContabil, Rcms, RcmsFavorecido, RcmsItem } from 'eddydata-lib';
import { FichaDespesaService } from 'contabil-lib';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-rcms-selecionar-item-dlg',
  templateUrl: './rcms-selecionar-item-dlg.component.html'
})
export class RcmsSelecionarItemDlgComponent implements OnInit, OnChanges {

  @Input() processo: boolean = false;
  @Input() rcms: Rcms = undefined;
  @Input() public login: LoginContabil;
  @Input() lista: RcmsItem[] = []
  @Input() visualizar: boolean = false;

  @Output() finalizado: EventEmitter<RcmsItem[]> = new EventEmitter();
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  /**
   * Declaração de variáveis
   */

  public selAll: boolean = false;
  public filtro: string = '';
  public aviso: string = '';
  public criterioOrdenacao: 'nome' | 'cod_produto' = 'nome'
  public ordemOrdenacaoPorCodProduto: 'desc' | 'asc' = 'desc'
  public ordemOrdenacaoPorNome: 'desc' | 'asc' = 'desc'


  public imaskValor = {
    mask: Number,
    scale: 6,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public despesaService: DespesaService,
    public fichaDespesaService: FichaDespesaService,
    public proponenteService: ProponenteService,
    protected contratoService: ContratoService,
    protected funcaoService: FuncaoService,
    public globalService: GlobalService) {
  }

  ngOnChanges(changes: SimpleChanges): void { }

  ngOnInit(): void { }

  public titulo() {
    if (this.processo)
      return 'Selecione os itens do processo que deseja requisitar';
    return 'Selecione os itens do contrato que deseja requisitar';
  }

  public async finalizar() {
    if (!this.validar()) {
      return;
    }
    if (this.rcms?.contrato?.id) {
      await this.validarSaldoContrato();
    }
    this.finalizado.emit(this.listaSel(true));
    this.hide();
  }

  public async validarSaldoContrato(): Promise<void> {
    const itens = this.listaSel(true);
    const totalItens = await this.valorTotalItens(itens);
    const desconsiderar: { rcmsId?: number, compraId?: number } = {};

    if (this.rcms?.id) {
      desconsiderar.rcmsId = this.rcms.id;
    }

    const saldoContrato = await this.contratoService.obterSaldo(this.login?.orgao?.id, this.rcms?.contrato?.id, desconsiderar).toPromise();
    if ((+saldoContrato.saldo.toFixed(2) - +totalItens) < 0) {
      const errorMessage = `Valor total dos itens da requisição(R$${this.funcaoService.convertToBrNumber(totalItens)}) ultrapassa o saldo atual do 
        contrato Nº ${this.funcaoService.mascarar('####/####', this.rcms?.contrato?.numero)} de R$${this.funcaoService.convertToBrNumber(+saldoContrato.saldo.toFixed(2))}!`
      toastr.error(errorMessage);
      throw new Error(errorMessage)
    }
  }

  public async valorTotalItens(itens: RcmsItem[], favorecido?: RcmsFavorecido): Promise<number> {
    let total_rcms = 0;
    if (itens && itens.length > 0) {
      for (let item of itens) {
        if (!item.cotacoes || item.cotacoes.length === 0)
          continue;
        let menor = item.cotacoes.filter((c) => c.valor_unitario > 0).sort((a, b) => a.valor_unitario - b.valor_unitario)[0];
        if (menor && (!favorecido || menor.rcmsFavorecido.favorecido.id === favorecido.favorecido.id)) {
          total_rcms += +menor.valor_unitario * +item['quantidade_import'];
        }
      }
    }
    return total_rcms;
  }

  public validar(): boolean {
    if (this.listaSel(true).length === 0) {
      toastr.warning(`Nenhum item selecionado.`);
      return false;
    }
    for (let item of this.lista) {
      if (item['selecionado'] && item['quantidade_import'] > item['saldo_qtd'] && !this.rcms?.contrato?.saldo_valor) {
        toastr.warning(`quantidade informada para o item ${item.produto_unidade.produto.nome} excede o saldo atual de ${new FuncaoService().convertToBrNumber(item['saldo_qtd'], 6)}`);
        return false;
      }
    }
    return true;
  }

  public selecionar(item: CompraItem, sel: boolean) {
    item['selecionado'] = sel;
  }

  public listaSel(sel: boolean): RcmsItem[] {
    let lista = this.lista.filter((c) => sel ? c['selecionado'] : !c['selecionado']);
    if (!sel && this.filtro)
      lista = lista.filter((f) => {
        if (f.produto_unidade.produto.nome.toLowerCase().includes(this.filtro.toLowerCase()))
          return true;
        if (f.produto_unidade.unidade.nome.toLowerCase().includes(this.filtro.toLowerCase()))
          return true;
        if (f.produto_unidade.produto.codigo.toLowerCase().includes(this.filtro.toLowerCase()))
          return true;
      });
    return lista;
  }

  public selecionarTodos() {
    const selecionado = this.todosSelecionados();
    if (this.lista) {
      this.lista.forEach((i) => {
        if (!i['vantagem_bloqueio'] && !i['bloqueado'] && (+i['saldo_qtd'] > 0 || this.rcms?.contrato?.saldo_valor))
          i['selecionado'] = !selecionado
      });
    }
  }

  public todosSelecionados(): boolean {
    return this.lista.filter((i) => !i['selecionado'] && !i['vantagem_bloqueio'] && !i['bloqueado'] && (+i['saldo_qtd'] > 0 || this.rcms?.contrato?.saldo_valor)).length === 0
  }

  public onClick(filtro: string) {
    this.filtro = filtro;
  }

  public onHide() {
    this.hide();
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public bloqueio() {
    if (!this.lista || this.lista.length === 0) {
      return false;
    }

    for (const item of this.lista) {
      if (item['mensagem_bloqueio']) {
        this.aviso = item['mensagem_bloqueio'];
        return true;
      }
    }
  }

  public reordenarPorNome() {
    this.ordemOrdenacaoPorNome = this.ordemOrdenacaoPorNome == 'desc' ? 'asc' : 'desc'
    const valorRetorno = this.ordemOrdenacaoPorNome == 'asc' ? 1 : -1;
    this.lista.sort(
      function (a, b) {
        if (a.produto_unidade.produto.nome > b.produto_unidade.produto.nome) {
          return valorRetorno
        } else if (a.produto_unidade.produto.nome < b.produto_unidade.produto.nome) {
          return -1 * valorRetorno
        } else {
          return 0
        }
      }
    )
  }

  public reordenarPorCodProduto() {
    this.ordemOrdenacaoPorCodProduto = this.ordemOrdenacaoPorCodProduto == 'desc' ? 'asc' : 'desc'
    const valorRetorno = this.ordemOrdenacaoPorCodProduto == 'asc' ? 1 : -1;
    this.lista.sort(
      function (a, b) {
        if (a.produto_unidade.produto.codigo > b.produto_unidade.produto.codigo) {
          return valorRetorno
        } else if (a.produto_unidade.produto.codigo < b.produto_unidade.produto.codigo) {
          return -1 * valorRetorno
        } else {
          return 0
        }
      }
    )
  }

}
