import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { GrupoServicoFormComponent } from './grupo-servico-form/grupo-servico-form.component';
import { GrupoServicoListComponent } from './grupo-servico-list/grupo-servico-list.component';
import { GrupoSevicoRoutingModule } from './grupo-servico-routing.module';
import { GrupoServicoViewComponent } from './grupo-servico-view/grupo-servico-view.component';
import { SharedModule } from 'eddydata-lib';
import { GrupoServicoFormDlgComponent } from './grupo-servico-form-dlg/grupo-servico-form-dlg.component';

@NgModule({
declarations: [GrupoServicoListComponent, GrupoServicoFormComponent, GrupoServicoViewComponent, GrupoServicoFormDlgComponent],
  exports: [GrupoServicoFormDlgComponent],
  imports: [
    CommonModule,
    GrupoSevicoRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    CalendarModule,
    IMaskModule,
    ToastModule
  ]
})
export class GrupoServicoModule { }
