import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ContratoService } from 'administrativo-lib';
import { Coluna, Contrato, ContratoItem, FuncaoService, GlobalService, Login, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ContratoItemService } from '../service/contrato-item.service';

declare var $: any;

@Component({
  selector: 'lib-contrato-item',
  templateUrl: './contrato-item.component.html'
})
export class ContratoItemComponent implements OnChanges, OnInit {

  @Input() public login: Login;
  @Input() public contrato: Contrato;
  @Input() public atualizando: boolean = false;

  public itemBloqueado: ContratoItem;
  public ordem: number;
  public totalizador: {
    quantidade: number,
    reajuste: number,
    reajuste_qtd: number,
    reajuste_total: number,
    aditivo: number,
    aditivo_qtd: number,
    requisicao: number,
    requisicao_qtd: number,
    compra: number,
    compra_qtd: number,
    empenho: number,
    empenho_qtd: number,
    totalCancelado: number
    saldo: number,
  };
  public filtroOrigem: 'R' | 'C' | 'E';
  public filtroItem: ContratoItem;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    protected globalService: GlobalService,
    public funcaoService: FuncaoService,
    public itemService: ContratoItemService,
    private contratoService: ContratoService) {
  }

  ngOnInit(): void {
    this.totalizador = {
      quantidade: 0,
      reajuste: 0, reajuste_qtd: 0, reajuste_total: 0,
      aditivo: 0, aditivo_qtd: 0,
      requisicao: 0, requisicao_qtd: 0,
      compra: 0, compra_qtd: 0,
      empenho: 0, empenho_qtd: 0,
      totalCancelado: 0,
      saldo: 0,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && (changes['contrato'] && this.contrato?.id || changes['atualizando'])) {
      this.totalizadorSaldo()
    }
  }

  public abrirBloquearItem(item: ContratoItem) {
    if (!item) return;
    this.itemBloqueado = item;
    $('#dialogBloquearItem').modal('show');
  }

  public confirmarBloquearItem() {
    if (!this.itemBloqueado) return;

    this.itemService.bloquearItem(this.itemBloqueado.id, true).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      this.itemBloqueado.bloqueado = true;
      toastr.success('Item bloqueado com sucesso!');
      $('#dialogBloquearItem').modal('hide');
    }, error => this.funcaoService.acaoErro(error));
  }

  public abrirDesbloquearItem(item: ContratoItem) {
    if (!item) return;
    this.itemBloqueado = item;
    $('#dialogDesbloquearItem').modal('show');
  }

  public confirmarDesbloquearItem() {
    if (!this.itemBloqueado) return;

    this.itemService.bloquearItem(this.itemBloqueado.id, false).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      this.itemBloqueado.bloqueado = false;
      toastr.success('Item desbloqueado com sucesso!');
      $('#dialogDesbloquearItem').modal('hide');
    }, error => this.funcaoService.acaoErro(error));
  }

  public carregarTabela(origem: 'R' | 'C' | 'E', item?: ContratoItem) {
    this.filtroOrigem = origem;
    this.filtroItem = item;
  }

  public obterValor(item: ContratoItem, campo: string, ref: 'Q' | 'V'): number {
    if (!item || !campo || !ref) return 0

    const itemCota = this.contrato.itens.filter(i => i.produto_unidade)
      .find(i => +i.produto_unidade?.id === +item.produto_unidade?.id && +i.id !== +item.id)
    if (itemCota && +item[campo] > 0) {
      if (+item.quantidade > +itemCota.quantidade) { // principal
        const referencia: number = ref === 'Q' ? +item.quantidade : +item.valor_unitario * +item.quantidade
        if (campo === 'total_compra_qtd' && item?.memorial?.cota === 'PRINCIPAL' && ref === 'Q' && !item['passou']) {
          item[campo] = +item['total_compra_qtd_principal'];
          item['passou'] = true;
        }
        if (campo === 'total_rcms_pendente_vl' && item?.memorial?.cota === 'PRINCIPAL' && ref === 'V' && !item['passouVP']) {
          item[campo] = +item['total_rcms_pendente_vl_principal'];
          item['passouVP'] = true;
        }
        item[campo] = (referencia - +item[campo] > 0 ? item[campo] : referencia);
      } else { // reservada
        const referencia: number = ref === 'Q' ? +itemCota.quantidade : +itemCota.valor_unitario * +itemCota.quantidade

        if (campo === 'total_compra_qtd' && item?.memorial?.cota === 'RESERVADO' && ref === 'Q' && !item['passou_reservada']) {

          item[campo] = +item['total_compra_qtd_reservada'];
          item['passou_reservada'] = true;
        }

        if (campo === 'total_rcms_pendente_vl' && item?.memorial?.cota === 'RESERVADO' && ref === 'V' && !item['passouVR']) {
          item[campo] = +item['total_rcms_pendente_vl_reservado'];
          item['passouVR'] = true;
        }

        // item[campo] = (referencia - +itemCota[campo] > 0 ? 0 : item[campo]);
      }
    }

    return item[campo]
  }

  public obterExecucao(tipo: 'M' | 'S' | 'L' | 'G'): number {
    let total: number;
    if (!tipo || !this.contrato || !this.contrato.itens) return 0;

    if (tipo === 'G')
      total = (+this.totalizador.empenho / (+this.contrato.valor_contrato + +this.totalizador.aditivo + +this.totalizador.reajuste_total)) * 100
    else
      total = (+this.obterTotal('total_compra_vl', tipo) / (+this.obterTotal('saldo_inicial', tipo) + +this.obterTotal('total_reajuste_total', tipo) + +this.obterTotal('total_reajuste_total', tipo))) * 100

    return +total.toFixed(0)
  }

  public obterTotal(campo: string, tipo: 'M' | 'S' | 'L'): number {
    let total = 0;
    if (!campo || !tipo || !this.contrato || !this.contrato.itens) return total;

    // sem vinculos
    if (tipo === 'S') {
      for (const item of this.contrato.itens.filter(i => !i.produto_unidade?.produto?.material))
        if (campo === 'saldo_inicial')
          total += +item.valor_unitario * +item.quantidade
        else
          total += +item[campo]
    }

    // com vinculos
    for (const item of this.contrato.itens
      .filter(i => i.produto_unidade?.produto?.material)
      .filter(i => i.produto_unidade.produto.material.servico === (tipo !== 'M') && i.produto_unidade.produto.material.locacao === (tipo === 'L'))
    ) {
      if (campo === 'saldo_inicial')
        total += +item.valor_unitario * +item.quantidade
      else
        total += +item[campo]
    }

    return total;
  }



  public obterTipo(item: ContratoItem): 'M' | 'S' | 'L' | '' {
    if (!item) return ''

    // sem vinculo
    if (!item.produto_unidade?.produto?.material) return 'S'

    if (item.produto_unidade.produto.material.locacao) return 'L'
    if (item.produto_unidade.produto.material.servico) return 'S'

    return 'M'
  }

  public async totalizadorSaldo() {
    this.contratoService.extendido(0, 1, {
      id: this.contrato.id, orgao_id: this.login.orgao.id
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        const total = res.content[0];
        this.totalizador = {
          quantidade: +total.quantidade,
          reajuste: +total.total_reajuste, reajuste_qtd: +total.total_reajuste_qtd, reajuste_total: +total.total_reajuste_total,
          aditivo: +total.total_aditivo, aditivo_qtd: +total.total_aditivo_qtd,
          requisicao: +total.total_rcms_pendente, requisicao_qtd: +total.total_rcms_pendente_qtd,
          compra: +total.total_compra_pendente, compra_qtd: +total.total_compra_pendente_qtd,
          empenho: +total.total_empenho, empenho_qtd: +total.total_compra_qtd,
          saldo: 0,
          totalCancelado: +total.total_cancelado_resto
        }

        this.contratoService.obterSaldo(this.login.orgao.id, this.contrato.id, {}, this.contrato.saldo_quantidade).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            this.totalizador['saldo'] = +res.saldo;
          })

      })
  }

  public exportarListagem() {
    this.itemService
      .filtrar(1, -1, {
        'contrato.id': this.contrato.id
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        async lista => {
          Relatorio.imprimirPersonalizado(
            'LISTAGEM ITENS E SALDOS - CONTRATO'
            , this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.conteudo(lista.content),
            'landscape', 'LISTAGEM ITENS E SALDOS - CONTRATO',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            });
        },
        () => alert('erro ao retornar lista')
      );
  }

  private async conteudo(dados: any[]): Promise<{}[]> {
    let total_inicial = 0;
    let total_global_inicial = 0;
    let total_inicial_m = 0;
    let total_reajuste_m = 0;
    let total_aditivo_m = 0;
    let total_req_pendente_m = 0;
    let total_comp_pentende_m = 0;
    let total_comp_emp_m = 0;
    let total_saldo_m = 0;
    let total_inicial_s = 0;
    let total_reajuste_s = 0;
    let total_aditivo_s = 0;
    let total_req_pendente_s = 0;
    let total_comp_pentende_s = 0;
    let total_comp_emp_s = 0;
    let total_saldo_s = 0;
    let total_inicial_l = 0;
    let total_reajuste_l = 0;
    let total_aditivo_l = 0;
    let total_req_pendente_l = 0;
    let total_comp_pentende_l = 0;
    let total_comp_emp_l = 0;
    let total_saldo_l = 0;


    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: 'Ordem',
        rowSpan: 2,
        alignment: 'center',
        fontSize: 9,
        bold: true
      },
      {
        text: 'Item',
        rowSpan: 2,
        alignment: 'center',
        fontSize: 9,
        bold: true
      }, {
        text: 'Saldo Inicial',
        colSpan: 3,
        alignment: 'center',
        fontSize: 9,
        bold: true
      }, '', '',
      {
        text: 'Reajustes e Estornos',
        colSpan: 3,
        alignment: 'center',
        fontSize: 9,
        bold: true
      }, '', '',
      {
        text: 'Aditivos',
        colSpan: 2,
        alignment: 'center',
        fontSize: 9,
        bold: true
      }, '',
      {
        text: 'Requisições Pendentes',
        colSpan: 2,
        alignment: 'center',
        fontSize: 9,
        bold: true
      }, '',
      {
        text: 'Compras Pendentes',
        colSpan: 2,
        alignment: 'center',
        fontSize: 9,
        bold: true
      }, '',
      {
        text: 'Comprado/Empenhado',
        colSpan: 2,
        alignment: 'center',
        fontSize: 9,
        bold: true
      }, '',
      {
        text: 'Saldo atual',
        colSpan: 3,
        alignment: 'center',
        fontSize: 9,
        bold: true
      }, '', '',
      ],
      [
        '', '',
        {
          text: 'Qtde.',
          alignment: 'center',
          fontSize: 9
        }, {
          text: 'Valor Unitário',
          alignment: 'center',
          fontSize: 9
        }, {
          text: 'Total',
          alignment: 'center',
          fontSize: 9
        }, {
          text: 'Qtde.',
          alignment: 'center',
          fontSize: 9
        }, {
          text: 'Valor Unitário',
          alignment: 'center',
          fontSize: 9
        }, {
          text: 'Total',
          alignment: 'center',
          fontSize: 9
        }, {
          text: 'Qtde.',
          alignment: 'center',
          fontSize: 9
        }, {
          text: 'Valor Unitário',
          alignment: 'center',
          fontSize: 9
        }, {
          text: 'Qtde.',
          alignment: 'center',
          fontSize: 9
        }, {
          text: 'Valor Unitário',
          alignment: 'center',
          fontSize: 9
        }, {
          text: 'Qtde.',
          alignment: 'center',
          fontSize: 9
        }, {
          text: 'Valor Unitário',
          alignment: 'center',
          fontSize: 9
        }, {
          text: 'Qtde.',
          alignment: 'center',
          fontSize: 9
        }, {
          text: 'Valor Unitário',
          alignment: 'center',
          fontSize: 9
        }, {
          text: 'Qtde.',
          alignment: 'center',
          fontSize: 9
        }, {
          text: 'Valor Unitário',
          alignment: 'center',
          fontSize: 9
        }, {
          text: 'Total',
          alignment: 'center',
          fontSize: 9
        },
      ]
    ];

    // monta o agrupamento do relatório
    for (const i of this.contrato.itens) {
      total_inicial = i.quantidade * i.valor_unitario;
      total_global_inicial += total_inicial;
      if (!this.contrato.saldo_quantidade) {
        if (i.produto_unidade.produto.material.servico) {
          total_inicial_s += +i.quantidade;
          total_reajuste_s += +i['total_reajuste_vl'];
          total_aditivo_s += +i['total_aditivo_vl'];
          total_req_pendente_s += +i['total_rcms_pendente_vl'];
          total_comp_pentende_s += +i['total_compra_pendente_vl'];
          total_comp_emp_s += +i['total_compra_vl'];
          total_saldo_s += +i['saldo_vl'];
        } else if (i.produto_unidade.produto.material.locacao) {
          total_reajuste_l += +i['total_reajuste_vl'];
          total_aditivo_l += +i['total_aditivo_vl'];
          total_saldo_l += +i['saldo_vl'];
          total_req_pendente_l += +i['total_rcms_pendente_vl'];
          total_comp_pentende_l += +i['total_compra_pendente_vl'];
          total_comp_emp_l += +i['total_compra_vl'];
        } else {
          total_inicial_m += +total_inicial;
          total_reajuste_m += +i['total_reajuste_vl'];
          total_aditivo_m += +i['total_aditivo_vl'];
          total_saldo_m += +i['saldo_vl'];
          total_req_pendente_m += +i['total_rcms_pendente_vl'];
          total_comp_pentende_m += +i['total_compra_pendente_vl'];
          total_comp_emp_m += +i['total_compra_vl'];
        }
      } else {
        total_global_inicial += +i['saldo_qtd'];
        if (i.produto_unidade.produto.material.servico) {
          total_inicial_s += +total_inicial;
          total_reajuste_s += +i['total_reajuste_qtd'];
          total_aditivo_s += +i['total_aditivo_qtd'];
          total_req_pendente_s += +i['total_rcms_pendente_qtd'];
          total_comp_pentende_s += +i['total_compra_pendente_qtd'];
          total_comp_emp_s += +i['total_compra_qtd'];
          total_saldo_s += +i['saldo_qtd'];
        } else if (i.produto_unidade.produto.material.locacao) {
          total_inicial_l += +i.quantidade;
          total_reajuste_l += +i['total_reajuste_qtd'];
          total_aditivo_l += +i['total_aditivo_qtd'];
          total_saldo_l += +i['saldo_qtd'];
          total_req_pendente_l += +i['total_rcms_pendente_qtd'];
          total_comp_pentende_l += +i['total_compra_pendente_qtd'];
          total_comp_emp_l += +i['total_compra_qtd'];
        } else {
          total_inicial_m += +i.quantidade;
          total_reajuste_m += +i['total_reajuste_qtd'];
          total_aditivo_m += +i['total_aditivo_qtd'];
          total_saldo_m += +i['saldo_qtd'];
          total_req_pendente_m += +i['total_rcms_pendente_qtd'];
          total_comp_pentende_m += +i['total_compra_pendente_qtd'];
          total_comp_emp_m += +i['total_compra_qtd'];
        }
      }


      registros.push([
        { text: i.ordem },
        { text: i.descricao },
        { text: this.funcaoService.formatarMoedaPtBr(i.quantidade) },
        { text: this.funcaoService.formatarMoedaPtBr(i.valor_unitario) },
        { text: this.funcaoService.formatarMoedaPtBr(total_inicial) },
        { text: this.funcaoService.formatarMoedaPtBr(i['total_reajuste_qtd']) },
        { text: this.funcaoService.formatarMoedaPtBr(i['total_reajuste_vl']) },
        { text: this.funcaoService.formatarMoedaPtBr(i['total_reajuste_total']) },
        { text: this.funcaoService.formatarMoedaPtBr(i['total_aditivo_qtd']) },
        { text: this.funcaoService.formatarMoedaPtBr(i['total_aditivo_vl']) },
        { text: this.funcaoService.formatarMoedaPtBr(i['total_rcms_pendente_qtd']) },
        { text: this.funcaoService.formatarMoedaPtBr(i['total_rcms_pendente_vl']) },
        { text: this.funcaoService.formatarMoedaPtBr(i['total_compra_pendente_qtd']) },
        { text: this.funcaoService.formatarMoedaPtBr(i['total_compra_pendente_vl']) },
        { text: this.funcaoService.formatarMoedaPtBr(i['total_compra_qtd']) },
        { text: this.funcaoService.formatarMoedaPtBr(i['total_compra_vl']) },
        { text: this.funcaoService.formatarMoedaPtBr(i['saldo_qtd']) },
        { text: this.funcaoService.formatarMoedaPtBr(i['vl_unit_atualizado']) },
        { text: this.funcaoService.formatarMoedaPtBr(i['saldo_vl']) }
      ]);
    }

    registros.push([
      {
        text: 'Saldo de Material',
        colSpan: 4,
        fontSize: 9,
        bold: true
      },
      '', '', '',
      {
        text: this.funcaoService.formatarMoedaPtBr(total_inicial_m)
      },
      {
        text: '',
        colSpan: 2
      }, '',
      {
        text: this.funcaoService.formatarMoedaPtBr(total_reajuste_m)
      },
      '',
      {
        text: this.funcaoService.formatarMoedaPtBr(total_aditivo_m)
      },
      '',
      {
        text: this.funcaoService.formatarMoedaPtBr(total_req_pendente_m)
      },
      '',
      {
        text: this.funcaoService.formatarMoedaPtBr(total_comp_pentende_m)
      },
      '',
      {
        text: this.funcaoService.formatarMoedaPtBr(total_comp_emp_m)
      },
      {
        text: '',
        colSpan: 2
      },
      '',
      { text: this.funcaoService.formatarMoedaPtBr(total_inicial_m) }
    ]);

    registros.push([
      {
        text: 'Saldo de Serviço',
        colSpan: 4,
        fontSize: 9,
        bold: true
      },
      '', '', '',
      {
        text: this.funcaoService.formatarMoedaPtBr(total_inicial_s)
      },
      {
        text: '',
        colSpan: 2
      }, '',
      {
        text: this.funcaoService.formatarMoedaPtBr(total_reajuste_s)
      },
      '',
      {
        text: this.funcaoService.formatarMoedaPtBr(total_aditivo_s)
      },
      '',
      {
        text: this.funcaoService.formatarMoedaPtBr(total_req_pendente_s)
      },
      '',
      {
        text: this.funcaoService.formatarMoedaPtBr(total_comp_pentende_s)
      },
      '',
      {
        text: this.funcaoService.formatarMoedaPtBr(total_comp_emp_s)
      },
      {
        text: '',
        colSpan: 2
      },
      '',
      { text: this.funcaoService.formatarMoedaPtBr(total_saldo_s) }
    ]);

    registros.push([
      {
        text: 'Saldo de Locação',
        colSpan: 4,
        fontSize: 9,
        bold: true
      },
      '', '', '',
      {
        text: this.funcaoService.formatarMoedaPtBr(total_inicial_l)
      },
      {
        text: '',
        colSpan: 2
      }, '',
      {
        text: this.funcaoService.formatarMoedaPtBr(total_reajuste_l)
      },
      '',
      {
        text: this.funcaoService.formatarMoedaPtBr(total_aditivo_l)
      },
      '',
      {
        text: this.funcaoService.formatarMoedaPtBr(total_req_pendente_l)
      },
      '',
      {
        text: this.funcaoService.formatarMoedaPtBr(total_comp_pentende_l)
      },
      '',
      {
        text: this.funcaoService.formatarMoedaPtBr(total_comp_emp_l)
      },
      {
        text: '',
        colSpan: 2
      },
      '',
      { text: this.funcaoService.formatarMoedaPtBr(total_saldo_l) }
    ]);

    registros.push([
      {
        text: 'Saldo Global',
        colSpan: 4,
        fontSize: 9,
        bold: true
      },
      '', '', '',
      {
        text: !this.contrato.saldo_quantidade ? this.funcaoService.formatarMoedaPtBr(total_global_inicial) : this.funcaoService.formatarMoedaPtBr(this.totalizador.quantidade), bold: true
      },
      {
        text: '',
        colSpan: 2
      }, '',
      {
        text: !this.contrato.saldo_quantidade ? this.funcaoService.formatarMoedaPtBr(this.totalizador.reajuste_total) : this.funcaoService.formatarMoedaPtBr(this.totalizador.reajuste_qtd), bold: true
      },
      '',
      {
        text: !this.contrato.saldo_quantidade ? this.funcaoService.formatarMoedaPtBr(this.totalizador.aditivo) : this.funcaoService.formatarMoedaPtBr(this.totalizador.aditivo_qtd), bold: true
      },
      '',
      {
        text: !this.contrato.saldo_quantidade ? this.funcaoService.formatarMoedaPtBr(this.totalizador.requisicao) : this.funcaoService.formatarMoedaPtBr(this.totalizador.requisicao_qtd), bold: true
      },
      '',
      {
        text: !this.contrato.saldo_quantidade ? this.funcaoService.formatarMoedaPtBr(this.totalizador.compra) : this.funcaoService.formatarMoedaPtBr(this.totalizador.compra_qtd), bold: true
      },
      '',
      {
        text: !this.contrato.saldo_quantidade ? this.funcaoService.formatarMoedaPtBr(this.totalizador.empenho) : this.funcaoService.formatarMoedaPtBr(this.totalizador.empenho_qtd), bold: true
      },
      {
        text: '',
        colSpan: 2
      },
      '',
      { text: this.funcaoService.formatarMoedaPtBr(this.totalizador.saldo), bold: true }
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }
    ];
  }

}