// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Cidade } from '../geo/cidade.model';

export class Combustivel extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public valor_atual?: number,
    public descricao?: string,
    public cidade?: Cidade,
    public numero_contrato?: string,
    public custo_medio?: number
  ) {
    super();
  }

  static converteJson(json: any): Combustivel {
    return Object.assign(new Combustivel(), json);
  }
}
