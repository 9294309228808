import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { ExercicioListComponent } from './exercicio-list/exercicio-list.component';
import { ExercicioRoutingModule } from './exercicio-routing.module';
import { SharedModule } from '../util/shared.module';
import { TrocarExercicioComponent } from './trocar-exercicio/trocar-exercicio.component';
import { ExercicioDlgComponent } from './exercicio-dlg/exercicio-dlg.component';


@NgModule({
  declarations: [
    ExercicioListComponent, 
    ExercicioDlgComponent, 
    TrocarExercicioComponent],
  imports: [
    CommonModule,
    ExercicioRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
  ],
  exports: [
    ExercicioListComponent,
    ExercicioDlgComponent, 
    TrocarExercicioComponent
  ],
  providers: [MessageService]
})
export class ExercicioModule { }
