import { Banco } from '../banco';
import { FuncaoService } from '../../util/funcao.service';

export class Hsbc extends Banco {
  /**
   * Metdodo responsavel por resgatar o numero do banco, coloque no return o codigo do seu banco
   */
  public getNumero() {
    return '399';
  }

  public getCnr() {
    return '2';
  }

  public getApp() {
    return '2';
  }

  public getDataJuliano() {
    let cal = new Date();
    let partes = this.boleto.getDataVencimento().split('/');
    let data = new Date(`${partes[2]}-${partes[1]}-${partes[0]}`);
    let ano = this.boleto.getDataVencimento().split('/')[2].substring(3);

    cal = data;
    let diaDoAno = this.daysIntoYear(cal);

    let retorno = diaDoAno.toString() + ano.toString();

    return Number.parseInt(retorno);
  }

  private daysIntoYear(date: Date) {
    return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
  }

  /**
   * MÃÂ©todo particular do hsbc
   */
  public getCampoLivre() {
    return this.getNumero() + this.boleto.getMoeda() + this.getCampo4() + this.boleto.getFatorVencimento() + this.boleto.getValorTitulo() + this.boleto.getContaCorrente() + this.boleto.getNossoNumero() + this.getDataJuliano() + this.getApp();
  }

  /**
   * Metodo que monta o primeiro campo do codigo de barras
   * Este campo como os demais e feito a partir do da documentacao do banco
   * A documentacao do banco tem a estrutura de como monta cada campo, depois disso
   * ÃÂ© sÃÂ³ concatenar os campos como no exemplo abaixo.
   */
  public getCampo1() {
    let campo = this.getNumero() + this.boleto.getMoeda() + this.boleto.getContaCorrente().substring(0, 5);

    return campo + this.boleto.getModulo10(campo);
  }

  /**
   * ver documentacao do banco para saber qual a ordem deste campo
   */
  public getCampo2() {
    let campo = this.boleto.getContaCorrente().substring(5, 7) + this.boleto.getNossoNumero().substring(0, 8);

    return campo + this.boleto.getModulo10(campo);
  }

  /**
   * ver documentacao do banco para saber qual a ordem deste campo
   */
  public getCampo3() {
    let campo = this.boleto.getNossoNumero().substring(8, 13) + (new FuncaoService()).strZero(this.getDataJuliano(), 4) + this.getApp();

    return campo + this.boleto.getModulo10(campo);
  }

  /**
   * ver documentacao do banco para saber qual a ordem deste campo
   */
  public getCampo4() {
    let campo = this.getNumero() + this.boleto.getMoeda().toString() + (new FuncaoService()).strZero(this.boleto.getFatorVencimento(), 4) + this.boleto.getValorTitulo() + this.boleto.getContaCorrente() + this.boleto.getNossoNumero() + (new FuncaoService()).strZero(this.getDataJuliano(), 4) + this.getApp();

    return this.boleto.getDigitoCodigoBarras(campo);
  }

  /**
   * ver documentacao do banco para saber qual a ordem deste campo
   */
  public getCampo5() {
    let campo = (new FuncaoService()).strZero(this.boleto.getFatorVencimento(), 4) + this.boleto.getValorTitulo();
    return campo;
  }

  /**
   * Metodo para monta o desenho do codigo de barras
   * A ordem destes campos tambem variam de banco para banco, entao e so olhar na documentacao do seu banco
   * qual a ordem correta
   */

  public getCodigoBarras() {
    return this.getNumero() + this.boleto.getMoeda().toString() + this.getCampo4() + (new FuncaoService()).strZero(this.boleto.getFatorVencimento(), 4) + this.boleto.getValorTitulo() + this.boleto.getContaCorrente() + this.boleto.getNossoNumero() + (new FuncaoService()).strZero(this.getDataJuliano(), 4) + this.getApp();
  }

  /**
   * Metodo que concatena os campo para formar a linha digitavel
   * E necessario tambem olhar a documentacao do banco para saber a ordem correta a linha digitavel
   */

  public getLinhaDigitavel() {
    let str = this.getCampo1().substring(0, 5) + '.' + this.getCampo1().substring(5) + '  ' + this.getCampo2().substring(0, 5) + '.' + this.getCampo2().substring(5) + '  ' + this.getCampo3().substring(0, 5) + '.' + this.getCampo3().substring(5) + '  ' + this.getCampo4() + '  ' + this.getCampo5();
    return str;
  }

  /**
   * Recupera a carteira no padrao especificado pelo banco
   * @author Gladyston Batista/Eac Software
   */

  public getCarteiraFormatted() {
    return this.boleto.getCarteira();
  }

  /**
   * Recupera a agencia / codigo cedente no padrao especificado pelo banco
   * @author Gladyston Batista/Eac Software
   */

  public getAgenciaCodCedenteFormatted() {
    return this.boleto.getContaCorrente();
  }

  public getDigitoVerificadorNossoNumero() {
    try {
      let dig = '';
      let nossoNumero = this.boleto.getNossoNumero();
      dig += this.getModulo11(nossoNumero);
      dig += '4'; // Codigo do Sacado, Codigo do Cedente e Data do Vencimento
      let partes = this.boleto.getDataVencimento();
      let venc = new Date(`${partes[2]}-${partes[1]}-${partes[0]}`);
      let vencStr = `${partes[0]}${partes[1]}${partes[2].substring(2)}`;
      let sum = Number.parseInt(nossoNumero + dig) + Number.parseInt(this.boleto.getContaCorrente())
        + Number.parseInt(vencStr);
      let calc = sum.toString();
      dig += this.getModulo11(calc);
      return dig.toString();
    } catch (ex) {
      throw new Error(ex);
    }
  }

  public getModulo11(campo: string) {
    //Modulo 11 - 234567   (type = 7)
    //Modulo 11 - 23456789 (type = 9)

    let multiplicador = 9;
    let multiplicacao = 0;
    let soma_campo = 0;

    for (let i = campo.length; i > 0; i--) {
      multiplicacao = Number.parseInt(campo.substring(i - 1, i)) * multiplicador;

      soma_campo = soma_campo + multiplicacao;

      multiplicador--;
      if (multiplicador < 2) {
        multiplicador = 9;
      }
    }

    let dac = soma_campo % 11;

    if (dac == 0 || dac == 10)
      return '0';
    else
      return (Number.parseInt(dac.toString())).toString();
  }

  /**
   * Recupera o nossoNumero no padrao especificado pelo banco
   * @author Gladyston Batista/Eac Software
   */

  public getNossoNumeroFormatted() {
    return this.boleto.getNossoNumero() + this.getDigitoVerificadorNossoNumero();
  }
}
