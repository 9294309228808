import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LicitacaoListComponent } from './licitacao-list/licitacao-list.component';
import { LicitacaoViewComponent } from './licitacao-view/licitacao-view.component';
import { AuthGuard } from 'eddydata-lib';
import { LicitacaoFormComponent } from './licitacao-form/licitacao-form.component';
import { LicitacaoRptComponent } from './licitacao-rpt/licitacao-rpt.component';
import { JulgamentoComponent } from '../julgamento/julgamento.component';

const routes: Routes = [
  { path: '', component: LicitacaoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: LicitacaoFormComponent, canActivate: [AuthGuard] },
  { path: 'julgamento/:id', component: JulgamentoComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: LicitacaoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: LicitacaoViewComponent, canActivate: [AuthGuard] },
  { path: 'relatorios', component: LicitacaoRptComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LicitacaoRoutingModule { }
