// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Cidade } from '../geo/cidade.model';

export class Ods extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: number,
    public descricao?: string,
    public cidade?: Cidade
  ) {
    super();
  }

  static converteJson(json: any): Ods {
    return Object.assign(new Ods(), json);
  }
}
