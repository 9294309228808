import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Compra, Login } from 'eddydata-lib';
import * as toastr from 'toastr';
import { CompraService } from '../service/compra.service';

declare var $: any;

@Component({
  selector: 'app-exclusao-compra-dlg',
  templateUrl: './exclusao-compra-dlg.component.html'
})
export class ExclusaoCompraDlgComponent implements OnInit, AfterViewInit, OnDestroy {

  protected unsubscribe: Subject<void> = new Subject();

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Input() login: Login;
  @Input() compra: Compra;

  constructor(
    private router: Router,
    private compraService: CompraService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
  }

  excluir() {
    this.compra.excluido = true;
    this.compra.data_exclusao = new Date();
    this.compra.usuario_exclusao = this.login.usuario;

    this.compraService
      .atualizar(this.compra)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.onHide();
        setTimeout(() => {
          this.router.navigate(['/compras']);
        }, 100);
        toastr.success('Exclusão realizado com sucesso!');
      },
        error => {
          if (error.error && error.error.payload) {
            toastr.error(error.error.payload);
          } else {
            toastr.error('Não foi possível fazer a exclusão da ordem de fornecimento!');
          }
        });

  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

}
