// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { AtaRegistro } from './ata-registro.model';

export class AtaRegistroAnexo extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public caminho?: string,
    public tipo?: string,
    public documento?: string,
    public tamanho?: number,
    public ata?: AtaRegistro,
    public ata_id?: number
  ) {
    super();
  }

  static converteJson(json: any): AtaRegistroAnexo {
    return Object.assign(new AtaRegistroAnexo(), json);
  }
}
