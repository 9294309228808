// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido } from './favorecido.model';
import { Orgao } from '../comum/orgao.model';
import { Recurso } from '../planejamento/recurso.model';
import { Unidade } from '../planejamento/unidade.model';
import { Exercicio } from '../comum/exercicio.model';
import { Empenho } from '../contabil/empenho.model';
import { EmpenhoResto } from '../contabil/empenho-resto.model';
import { ConvenioAditamento } from './convenio-aditamento.model';
import { ConvenioReajuste } from './convenio-reajuste.model';
import { ConvenioSituacao } from './convenio-situacao.model';
import { TipoConvenio } from './tipo-convenio.model';
import { ConvenioRecurso } from './convenio-recurso.model';

export class Convenio extends BaseResourceModel {
  constructor(
    public id?: number,
    public numero?: string,
    public ano?: number,
    public processo?: string,
    public numero_convenio?: string,
    public observacao?: string,
    public especie?: string,
    public origem?: 'E' | 'F' | 'U',
    public siconv?: string,
    public legislacao?: string,
    public autorizacao?: string,
    public lei_numero?: string,
    public data_assinatura?: Date,
    public data_contabilizacao?: Date,
    public data_inicio?: Date,
    public data_termino?: Date,
    public data_prestacao?: Date,
    public data_quitacao?: Date,
    public tipo_fornecedor?: 'F' | 'C',
    public valor_convenio?: number,
    public valor_contrapartida?: number,
    public saldo_anterior?: number,
    public valor_aplicacao?: number,
    public valor_devolvido?: number,
    public valor_outros?: number,
    public valor_recurso?: number,
    public objeto?: string,
    public finalidade?: string,
    public aditivo?: string,
    public enviar_email?: boolean,
    public orgao?: Orgao,
    public favorecido?: Favorecido,
    public recurso?: Recurso,
    public recurso_contra?: Recurso,
    public aplicacao?: Recurso,
    public aplicacao_contra?: Recurso,
    public ativo?: boolean,
    public aplicacao_variavel?: Recurso,
    public aplicacao_variavel_contra?: Recurso,
    public unidade?: Unidade,
    public parente?: Convenio,
    public exercicio?: Exercicio,
    public tipo_convenio?: TipoConvenio,
    public empenhos?: Empenho[],
    public empenhos_resto?: EmpenhoResto[],
    public aditamento?: ConvenioAditamento[],
    public reajuste?: ConvenioReajuste[],
    public situacao?: ConvenioSituacao[],
    public recursos?: ConvenioRecurso[]
  ) {
    super();
  }
  static converteJson(json: any): Convenio {
    return Object.assign(new Convenio(), json);
  }
}
