import { Component, OnInit, Input, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { switchMap, takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { Subject } from 'rxjs';
import {
  Pagamento, Login, GlobalService, FuncaoService, EddyAutoComplete, ContaBancaria,
  ContaBancariaRecurso, Retencao, PagamentoResto, RetencaoResto, PagamentoExtra, RetencaoExtra,
  Diario
} from 'eddydata-lib';
import { AdiantamentoService, ContaBancariaService, DiarioService, PagamentoExtraService, PagamentoRestoService, PagamentoService } from 'administrativo-lib';
import { ContaBancariaRecursoService } from '../conta-bancaria/service/conta-bancaria-recurso.service';
import { RetencaoService } from '../../liquidacao/service/retencao.service';
import { RetencaoRestoService } from '../../liquidacao-resto/service/retencao-resto.service';
import { RetencaoExtraService } from '../../empenho-extra/service/retencao-extra.service';

declare var $: any;

@Component({
  selector: 'lib-pagamento-anulacao',
  templateUrl: './pagamento-anulacao.component.html'
})
export class PagamentoAnulacaoComponent implements OnInit, AfterViewInit, OnDestroy {
  //Variaveis externas
  @Input() login: Login;
  @Input() pagamentoId: Pagamento | PagamentoResto | PagamentoExtra;
  @Input() valor: number;
  @Input() tipoPagamento: 'O' | 'R' | 'E';
  @Output() callback: EventEmitter<void> = new EventEmitter();

  //Variaveis da tela
  protected datepipe: DatePipe;
  public ptBR: any;
  public data: Date;
  public data_referencia: Date;
  public historico: string;
  public listAnulacoes: Array<Pagamento> | Array<PagamentoResto> | Array<PagamentoExtra> = [];
  protected unsubscribe: Subject<void> = new Subject();
  public contaRecurso: String = '\n';
  public contaAutoComplete: EddyAutoComplete<ContaBancaria>;
  public contaBancaria: ContaBancaria
  public contaBancariaRecurso: ContaBancariaRecurso;
  public contaRecursoAutoComplete: EddyAutoComplete<ContaBancariaRecurso>;
  public desconsiderarAdiantamento: Boolean = false;

  //Variaveis de anulação
  valorRetencao: number = 0;
  valorPago: number = 0;
  totais: any;
  listaRetencoes: Array<Retencao> | Array<RetencaoResto> | Array<RetencaoExtra>;
  carregando: boolean = false;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected messageService: MessageService,
    private globalService: GlobalService,
    private funcaoService: FuncaoService,
    private pagamentoService: PagamentoService,
    private pagamentoRestoService: PagamentoRestoService,
    private pagamentoExtraService: PagamentoExtraService,
    protected contaRecursoService: ContaBancariaRecursoService,
    protected retencaoService: RetencaoService,
    protected retencaoRestoService: RetencaoRestoService,
    protected retencaoExtraService: RetencaoExtraService,
    protected confirmationService: ConfirmationService,
    protected diarioService: DiarioService,
    protected adiantamentoService: AdiantamentoService,
    protected contaService: ContaBancariaService
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.data = new Date();
    this.valor = 0;
    this.historico = '';
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    this.globalService.calendarMascara();
  }

  ngOnChanges(): void {
    if (this.pagamentoId && !this.carregando) {
      this.carregando = true;
      this.contaBancaria = this.pagamentoId.conta;
      this.contaBancariaRecurso = this.pagamentoId.conta_recurso
      this.data_referencia = new Date(this.pagamentoId.data_pagamento);
      this.buscarContaRecurso();//Busca as contas de recurso, para o usuário trocar caso necessário.
      this.carregarConta();//Carrega os autocompletes para a troca de contas caso necessário.
      this.valor = 0;//Solicitado pelo Rodrigo
      if (this.pagamentoId?.id) {
        this.valor = 0;
        const id = this.tipoPagamento == 'E' ? this.pagamentoId['empenho'].id : this.pagamentoId['liquidacao'].id;
        this.obterRetencoes(id);//Carrega as retenções que serão anuladas
      }
    } else {
      this.carregarConta();
    }
  }

  public async buscarContaRecurso() {
    if (this.pagamentoId) {
      if (this.contaBancariaRecurso) {
        this.contaBancariaRecurso = this.pagamentoId.conta_recurso
      } else {
        const param = { 'conta.ativo': true, recurso_pagto: true, conta_id: this.pagamentoId.conta.id, relations: 'conta,recurso,aplicacao,convenio' }

        this.contaBancariaRecurso = (await this.contaRecursoService.filtrar(1, 1, param).toPromise()).content[0]
      }

      let res = this.contaBancariaRecurso;

      if (res?.recurso) {
        this.contaRecurso += res.recurso.codigo + ' - ' + res.recurso.nome + "\n";
      }
      if (res?.aplicacao) {
        this.contaRecurso += res.aplicacao.codigo + ' - ' + res.aplicacao.nome + "\n";
      }
      if (res?.convenio) {
        this.contaRecurso += res.convenio.codigo + ' - ' + res.convenio.nome;
      }
    }
  }

  carregarConta() {
    this.contaAutoComplete = new EddyAutoComplete(null, this.contaService,
      'codigo', ['numero_conta', 'banco.nome', 'nome'], { orgao_id: this.login.orgao.id, relations: 'banco', orderBy: 'nome' },
      { number: ['numero_conta', 'codigo'], text: ['nome'] }, null, null,
      (ent: ContaBancaria) => `${ent.numero_conta} ${ent.digito_conta} ${ent.banco?.nome} ${ent.nome}`);

    this.contaRecursoAutoComplete = new EddyAutoComplete(null, this.contaRecursoService,
      'id', ['recurso.codigo', 'aplicacao.codigo', 'aplicacao.nome'], { 'conta.codigo': this.pagamentoId?.conta.codigo, 'excluido': false, relations: 'recurso,aplicacao,convenio', orderBy: 'aplicacao.codigo' },
      { number: ['aplicacao.codigo'], text: ['aplicacao.nome', 'convenio.codigo'] }, null, null,
      (item: ContaBancariaRecurso) => `${item.recurso.codigo} ${item.aplicacao.codigo} ${item.convenio ? item.convenio.codigo : ''} ${item.aplicacao.nome}`);
  }

  private async obterRetencoes(id: number) {
    this.listaRetencoes = [];
    let retencoes;

    if (this.tipoPagamento == 'O') {
      retencoes = await this.retencaoService.extendido(0, -1, {
        liquidacao_id: id,
        relations: 'ficha,ficha.plano,ficha.favorecido,liquidacao,liquidacao.empenho,liquidacao.empenho.favorecido,'
          + 'liquidacao.orgao,liquidacao.exercicio'
      }).toPromise();
    } else if (this.tipoPagamento == 'R') {
      retencoes = await this.retencaoRestoService.extendido(0, -1, {
        liquidacao_id: id,
        relations: 'ficha,ficha.plano,ficha.favorecido,liquidacao,liquidacao.empenho,liquidacao.empenho.favorecido,'
          + 'liquidacao.orgao,liquidacao.exercicio'
      }).toPromise();
    } else if (this.tipoPagamento == 'E') {
      retencoes = await this.retencaoExtraService.extendido(0, -1, {
        empenho_id: id,
        relations: 'ficha,ficha.plano,ficha.favorecido,empenho_extra.favorecido,empenho_extra.orgao,empenho_extra.exercicio'
      }).toPromise();
    }

    //Tratamento da variavel de retencoes
    if (!retencoes) retencoes = []; else retencoes = retencoes.content;

    this.valorRetencao = 0;
    this.listaRetencoes = [];

    for (const item of retencoes) {
      //Se a retenção é uma anulação ou está com valor negativo, ela não deve carregar na tela para ser anulada, pois já está anulada
      if (item.anulado || item.valor_retido < 0) {
        continue;
      }

      const obj = Object.assign({}, item);

      //Valores originais para comparação
      //Valor original será copiado para saber quanto valia a retenção originalmente
      //O campo valor_retido receberá o valor que esta anulando, facilitando o uso na API.
      obj['valor_original'] = item.valor_retido;
      obj['ret_codigo'] = item.id;
      obj.valor_retido = 0;

      //Rotina que totaliza quanto da retenção já foi pago
      await this.verificarPagamentoRetencao(obj);

      //Total de valor a ser anulado nas retenções, exibe no INPUT de total retenção. Apenas visual.
      this.valorRetencao += +obj['valor_anulando'];

      this.listaRetencoes.push(obj);//Lista de rentenções que estão disponíveis para serem anuladas.
    }

    //Por padrão, o valor desejado anular será o valor total retido
    this.valor = this.valorRetencao;

    await this.totalizar();//Inicializa a variavel totalizador
    this.carregando = false;
  }

  private async verificarPagamentoRetencao(retencao) {
    //Rotina que totaliza quanto da retenção já foi pago
    let retorno = [];
    if (this.tipoPagamento == 'O') {
      retorno = await this.retencaoService.verificarPagamentoRetencao(retencao.id).toPromise()
    } else if (this.tipoPagamento == 'R') {
      retorno = await this.retencaoRestoService.verificarPagamentoRetencao(retencao.id, this.pagamentoId.exercicio.ano, this.login.orgao.id).toPromise()
    } else {
      retorno = await this.retencaoExtraService.verificarPagamentoRetencao(retencao.id, this.pagamentoId.exercicio.ano, this.login.orgao.id, true).toPromise();
    }

    //Aqui é adicionado o quanto se pode anular da retenção.
    retencao['disponivel_anulacao'] = +(retencao['total_retido_atual'] - +retorno[1]).toFixed(2);

    //Se por algum motivo, o valor disponível ficar acima do valor original,
    //ele seta o disponível para ser o valor original da anulação. Isso acontece
    if (+retencao['disponivel_anulacao'] > +retencao['valor_original']) {
      retencao['disponivel_anulacao'] = +retencao['valor_original']
    }

    //Por padrão, o valor anulando será a quantidade disponível, já em 100%.
    retencao['valor_anulando'] = retencao['disponivel_anulacao'];

    //O valor retido, usado na API é atualizado para ser o valor que está se anulando. Para ser negativado.
    retencao.valor_retido = +(+retencao['total_retido_atual'] - +retencao['valor_anulando']).toFixed(2);

    if (retencao.ficha.especie == 'O') return;

    //Caso o retorno[0] venha preenchido, significa pagamento 100% da retenção.
    //Só extra pois as Orçamentarias é o próprio pagamento a ser anulado, não precisa de validações extras.
    if (retorno[0] != '' && retencao.ficha.especie == 'E') {
      retencao['aviso_impedimento'] = retorno[0];
    }
  }

  public async totalizar(jaTotalizado?: boolean) {
    /*
    O totalizar vai ser uma das rotinas mais importantes dessa tela, ele irá inicialmente como false
    para puxar do banco os totais tanto de pagamento quanto das retenções e anulação, e após isso,
    todas rotinas devem enviar o jaTotalizado = false, para rodar apenas as rotinas com variaveis já
    existentes na tela.
    */
    if (!jaTotalizado) {
      //Carrega totalizador do banco, com total pago e total anulado.
      const id = this.tipoPagamento == 'E' ? this.pagamentoId['empenho'].numero : this.pagamentoId['liquidacao'].id;
      if (this.tipoPagamento == 'O') {
        this.totais = await this.pagamentoService.obterTotalPagoPorLiquidacaoId(id).toPromise();
      } else if (this.tipoPagamento == 'R') {
        this.totais = await this.pagamentoRestoService.obterTotalPagoPorLiquidacaoId(id).toPromise();
      } else {
        this.totais = await this.pagamentoExtraService.obterTotalPagoRetencao(id, this.pagamentoId.exercicio.id, this.pagamentoId.orgao.id).toPromise();
      }

      this.totalizar(true);//Executa a rotina no else, atualizando os valores nos inputs.
    } else {

      //Se o valor digitado for inferior ao das retenções, aumenta o valor para o min da retenção e da aviso
      if (this.valor < this.valorRetencao) {
        toastr.info('Valor desejado a anular inferior a somatória de retenções!');
        this.valor = this.valorRetencao;
      }

      //O valor pago a ser anulado (é apenas visual no front) é baseado no valor total (digitado) subtraido do valor retido.
      //Em exemplo, se o pagamento é 100, e o retido é 10, o valor em pagamento é 90.
      this.valorPago = this.funcaoService.arredondar(this.valor - this.valorRetencao, 2);

      if (this.valor > this.totais.total_pago) this.valor = this.totais.total_pago;

      //Se o valor pago, ficar maior que o total disponível total, deve normalizar os valores
      //limitando ao máximo disponível para ser anulado.
      if (this.valorPago > (this.totais.total_pago - this.totais.total_pago_retencao)) {
        this.valorPago = this.funcaoService.arredondar(this.totais.total_pago - this.totais.total_pago_retencao, 2);

        /*
        Se o valor digitado for igual ao total pago, pergunta se deseja reduzir ao limite, ou anular 100% das retenções.
        Ex: o total é 100, onde 90 é pagamento e 10 é anulação, o máximo é 90, se a pessoa digitar 100 ele irá:
        1 - Ou reduzir o valor a anular para 90, e não mexer nas retenções
        ou
        2 - Irá forçar a anulação das retenções em 100%, para disponibilizar os 100%.
        */

        if (this.valor == this.totais.total_pago) {
          this.confirmationService.confirm({
            message: `O valor digitado representa 100% do valor do pagamento. Você deseja marcar todas retenções para anulação, ou reduzir o valor a anular para o valor limite pago?`,
            acceptLabel: "Anular todas Retenções",
            rejectLabel: "Reduzir valor a anular",
            header: 'Remoção de retenção da anulação',
            icon: 'pi pi-exclamation-triangle',
            key: 'anulapag',
            accept: () => {
              this.valorRetencao = 0;
              //Se confirmado, cada retenção recebe o valor anulando como o valor original total e faz a soma.
              for (const r of this.listaRetencoes) {
                r['valor_anulando'] = +r['total_retido_atual'];
                this.valorRetencao += +r['valor_anulando'];
              }
              this.valorRetencao = this.funcaoService.arredondar(+(this.valorRetencao), 2)
            },
            reject: () => {
              this.valor = this.valorPago;
              // const liqId = this.tipoPagamento == 'E' ? this.pagamentoId['empenho'].id : this.pagamentoId['liquidacao'].id
              // this.obterRetencoes(liqId)
            }
          });
        }
      }
    }
  }

  public salvarItem(retencao: Retencao) {
    //Caso o valor digitado seja inferior a 0, volta aos valores iniciais e retorna erro
    if (+retencao['valor_anulando'] < 0) {
      retencao.valor_retido = retencao['total_retido_atual'];
      retencao['valor_anulando'] = 0.0;
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Valor a ser anulado da retenção não pode ser negativo!' });
      return;
    } else if (+retencao['valor_anulando'] > +retencao['total_retido_atual']) {
      //Se o valor digitado for SUPERIOR ao valor retido, volta aos valores iniciais e retorna erro
      retencao.valor_retido = retencao['total_retido_atual'];
      retencao['valor_anulando'] = 0.0;
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Valor a ser anulado deve ser superior no máximo o valor original da retenção. !' });
      return;
    } else if (+retencao['valor_anulando'] > +retencao['disponivel_anulacao']) {
      //Mesmo cenário acima, porém para o saldo disponível de anulação
      retencao['valor_anulando'] = 0.0;
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Valor a ser anulado é superior ao valor disponível!' });
      return;
    }

    retencao.valor_retido = +(+retencao['total_retido_atual'] - +retencao['valor_anulando']).toFixed(2);

    //Refaz a soma dos totais
    this.valorRetencao = 0;
    for (const r of this.listaRetencoes) {
      this.valorRetencao = +(+this.valorRetencao + +r['valor_anulando']).toFixed(2);
    }

    this.totalizar(true);
  }

  formataRetencao(item: Retencao): number {

    /*
    0 - Com erro
    1 - Pago
    2 - Sem saldo
    3 - Anulando 100%
    4 - Não anulando
    5 - Anulando parcial
    */
    if (item['aviso_impedimento']) return 1;

    if (+item['disponivel_anulacao'] == 0) return 2;

    if (+item['valor_anulando'] == +item['total_retido_atual']) return 3;

    if (+item['valor_anulando'] == 0) return 4;

    if (+item['valor_anulando'] > 0 && +item['valor_anulando'] != +item['total_retido_atual']) return 5;

    return 0;
  }

  exibirImpedimento(item: Retencao) {
    this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: item['aviso_impedimento'] });
  }

  public async anular() {
    if (!this.data_referencia) {
      toastr.warning('Informe a data de referência do pagamento', 'Validação!');
      return;
    }
    if (!this.contaBancaria) {
      toastr.warning('Informe a conta para anulação', 'Validação!');
      return;
    }
    if (this.valor <= 0) {
      toastr.warning('O valor da anulação não pode ser menor ou igual a zero!', 'Validação!');
      return;
    }
    if (this.valor < this.valorRetencao) {
      toastr.warning('O valor desejado a anular, não pode ser inferior a somatória das retenções');
      return;
    }
    if (this.valor < this.valorPago) {
      toastr.warning('O valor desejado a anular, não pode ser inferior ao valor que será anulado do pagamento');
      return;
    }
    if (this.historico == '') {
      toastr.warning('Informe a justificativa da anulação!', 'Validação!');
      return;
    }

    if (this.tipoPagamento === 'O') {
      const adiantamento = await this.adiantamentoService.obter({ empenho_id: this.pagamentoId['liquidacao']['empenho']['id'],'exercicio.id': this.pagamentoId.exercicio.id, 'orgao.id': this.pagamentoId.orgao.id }).toPromise();
      if (adiantamento?.id) {
        const registros: Diario[] = (await this.diarioService.filtrar(1, -1, { 'exercicio.id': this.pagamentoId.exercicio.id, 'orgao.id': this.pagamentoId.orgao.id, 'codigo': '891210200', 'natureza': 'C', 'numero_empenho': this.pagamentoId['liquidacao']['empenho']['numero'], 'ano_empenho': this.pagamentoId.exercicio.ano }).toPromise()).content
        const saldo = registros.reduce((acc, i) => acc += +i.valor, 0);
        if (!registros?.length || saldo <= this.valor) {
          toastr.warning('Atenção este empenho não foi prestado conta do adiantamento, isso ocasionará inversão na conta 891210200, favor verificar', 'Validação!');
        }
      }
    }

    for (const r of this.listaRetencoes) {
      if (r['aviso_impedimento']) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Existem retenções com impedimento para prosseguir com a anulação, resolva os impedimentos, ou remova as retenções da listagem para prosseguir.' });
        return;
      }
    }

    if (this.valor > this.funcaoService.arredondar(+(this.valorPago + this.valorRetencao), 2)) {
      const texto = `O valor total da anulação de R$${this.valor} é maior que a somatória de anulação do valor pago (R$${this.valorPago}) `
        + (this.listAnulacoes.length > 0 ? `com as retenções (R$${this.valorRetencao}), é necessário reduzir a quantidade total a anular para R$${this.funcaoService.arredondar((this.valorPago + this.valorRetencao), 2)} `
          + `ou anular mais R$${+(this.valor - this.funcaoService.arredondar((this.valorPago + this.valorRetencao), 2))} das retenções.` : 'É necessário reduzir o valor total para o máximo valor disponível a anular');
      this.confirmationService.confirm({
        message: texto,
        acceptLabel: "OK",
        rejectVisible: false,
        header: 'Aviso',
        icon: 'pi pi-exclamation-triangle',
        key: 'anulapag',
        accept: () => { return; }, reject: () => { return }
      });
      return;
    } else if (this.funcaoService.arredondar(+(this.valorPago + this.valorRetencao), 2) > this.valor) {
      const texto = `A somatória de valor a anular do pagamento (R$${this.valorPago}) com o valor retido a anular (R$${this.valorRetencao}) é `
        + `superior em R$${this.funcaoService.arredondar((this.funcaoService.arredondar(+(this.valorPago + this.valorRetencao), 2) - this.valor), 2)}, reduza o valor total a anular ou o valor a anular em retenções.`
      this.confirmationService.confirm({
        message: texto,
        acceptLabel: "OK",
        rejectVisible: false,
        header: 'Aviso',
        icon: 'pi pi-exclamation-triangle',
        key: 'anulapag',
        accept: () => { return; }, reject: () => { return }
      });
    }

    let texto = `\nTotal sendo anulado: <b>R$${this.valor} de pagamento</b>, resultado de:`;
    if (this.valorPago > 0) {
      texto += `\n   ● <b>R$${this.valorPago}</b> de valor <u>pago não retido</u>.`
    }

    if (this.valorRetencao > 0) {
      texto += `\n   ● <b>R$${this.valorRetencao}</b> de valor <u>pago e retido</u>, proveniente das retenções:`;

      for (const r of this.listaRetencoes) {
        if (r['valor_anulando'] > 0) texto += `\n      - <b>R$${r['valor_anulando']}</b> na retenção ${r.ficha.nome}`;
      }
    }

    const p = new Promise<void>((resolve, reject) => {
      this.confirmationService.confirm({
        message: `<pre>Deseja continuar com a anulação? As retenções serão anuladas conforme valores informados, juntamente com as receitas e empenhos extras caso existirem.\nInformações detalhadas:${texto}</pre>`,
        acceptLabel: "Sim",
        rejectLabel: "Não",
        header: 'Anulação pagamento',
        icon: 'pi pi-exclamation-triangle',
        key: 'anulapag',
        accept: () => {
          resolve()
        },
        reject: () => {
          reject();
        }
      });
    });

    p.then(() => {
      if (this.tipoPagamento == 'O') {
        this.rotaAnularOrcamentario();
      } else if (this.tipoPagamento == 'R') {
        this.rotaAnularRestos();
      } else if (this.tipoPagamento == 'E') {
        this.rotaAnularExtra();
      }
    });
  }

  private rotaAnularOrcamentario() {
    this.route.paramMap
      .pipe(switchMap(params => this.pagamentoService.anular(
        Number(this.pagamentoId.id),
        String(this.funcaoService.converteDataSQL(this.data.toLocaleDateString())),
        this.valor,
        this.historico,
        this.login.usuario,
        this.contaBancaria,
        this.contaBancariaRecurso,
        this.listaRetencoes,
        this.desconsiderarAdiantamento
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (dados) => {
          $('#dialogAnular').modal('hide');
          this.router.navigate(['/pagamentos-orcamentarios']);
          toastr.success('Anulação realizada com sucesso!');
          this.callback.emit();
        }, error => {
          if (error.error && error.error.payload) {
            toastr.error(error.error.payload);
          } else {
            toastr.error('Ocorreu um erro ao processar a sua solicitação');
          }
        });
  }

  private rotaAnularRestos() {
    this.route.paramMap
      .pipe(switchMap(params => this.pagamentoRestoService.anular(
        this.pagamentoId.id,
        String(this.funcaoService.converteDataSQL(this.data.toLocaleDateString())),
        this.valor,
        this.historico,
        this.login.usuario,
        this.contaBancaria,
        this.listaRetencoes
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((dados) => {
        $('#dialogAnular').modal('hide');
        this.router.navigate(['/pagamentos-restos-pagar']);
        toastr.success('Anulação realizada com sucesso!');
        this.callback.emit();
      }, error => {
        if (error.error && error.error.payload) {
          toastr.error(error.error.payload);
        } else {
          toastr.error('Ocorreu um erro ao processar a sua solicitação');
        }
      });
  }

  private rotaAnularExtra() {
    this.route.paramMap
      .pipe(switchMap(params => this.pagamentoExtraService.anular(
        Number(this.pagamentoId.id),
        String(this.funcaoService.converteDataSQL(this.data.toLocaleDateString())),
        this.valor,
        this.historico,
        this.login.usuario,
        this.contaBancaria,
        this.contaBancariaRecurso,
        this.listaRetencoes
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (dados) => {
          $('#dialogAnular').modal('hide');
          this.router.navigate(['/pagamentos-extras']);
          toastr.success('Anulação realizada com sucesso!');
          this.callback.emit();
        }, error => {
          if (error.error && error.error.payload) {
            toastr.error(error.error.payload);
          } else {
            toastr.error('Ocorreu um erro ao processar a sua solicitação');
          }
        });
  }

  public confirmarRemocao(item: Retencao, index: number) {
    this.confirmationService.confirm({
      message: `Deseja remover a retenção ${item.id} da anulação?`,
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção de retenção da anulação',
      icon: 'pi pi-exclamation-triangle',
      key: 'anulapag',
      accept: () => {
        this.removerItem(item, index)
      },
    });
  }

  private removerItem(item: any, index: number) {
    if (index == null) {
      index = this.listaRetencoes.indexOf(item);
    }
    this.listaRetencoes.splice(index, 1);
    this.valorRetencao = 0;
    for (const r of this.listaRetencoes) {
      this.valorRetencao += +r['valor_anulando'];
    }
    this.totalizar(true);
  }

  async buscarAnulacoes() {
    let resultado;
    if (this.tipoPagamento == 'O') {
      resultado = await this.pagamentoService.filtrar(0, -1,
        {
          relations: 'usuario_cadastro',
          anulacao: true,
          exercicio_id: this.login.exercicio.id,
          orgao_id: this.login.orgao.id,
          liquidacao_id: this.pagamentoId['liquidacao'].id
        }
      ).toPromise();
    } else if (this.tipoPagamento == 'R') {
      resultado = await this.pagamentoRestoService.filtrar(0, -1,
        {
          relations: 'usuario_cadastro',
          anulacao: true,
          exercicio_id: this.login.exercicio.id,
          orgao_id: this.login.orgao.id,
          liquidacao_id: this.pagamentoId['liquidacao'].id
        }
      ).toPromise();
    } else if (this.tipoPagamento == 'E') {
      resultado = await this.pagamentoExtraService.filtrar(0, -1,
        {
          relations: 'usuario_cadastro',
          anulacao: true,
          exercicio_id: this.login.exercicio.id,
          orgao_id: this.login.orgao.id,
          empenho_id: this.pagamentoId['empenho'].id
        }
      ).toPromise();
    }

    this.listaRetencoes = resultado ? resultado?.content : [];
  }

  removerImpedimentos() {
    for (const r of this.listaRetencoes) {
      if (r['aviso_impedimento']) {
        this.removerItem(r, null);
      }
    }
  }
}
