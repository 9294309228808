import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QuadroSaudeService, Quadro1Saude, Quadro2Saude, Quadro3Saude, Quadro5Saude, Anexo12Saude, ProjecaoReceitaDespesa } from 'contabil-lib';
import { Exercicio, ExercicioService, FormatoExportacao, FuncaoService, GlobalService, LoginPublico, OrgaoAssinaturaService, OrgaoService, ProgressoService } from 'eddydata-lib';
import * as toastr from 'toastr';

@Component({
  selector: 'app-transparencia-quadro-saude',
  templateUrl: './transparencia-quadro-saude.component.html'
})
export class TransparenciaQuadroSaudeComponent implements OnInit, OnDestroy {

  login: LoginPublico = new LoginPublico();
  protected unsubscribe: Subject<void> = new Subject();

  listaOrgaos: SelectItem[];
  orgaos: number[] = [];
  public mes: number;
  public ano: number;
  public listaExercicios: Array<Exercicio>;
  public listaAnexos: Array<any>;
  public anexoSelect: string = 'Q1';
  public opcao = '1';
  public percentualFerias: number = 0;
  public percentual13: number = 80;


  constructor(
    protected assinaturaService: OrgaoAssinaturaService,
    private messageService: MessageService,
    protected orgaoService: OrgaoService,
    protected exercicioService: ExercicioService,
    protected progressoService: ProgressoService,
    protected quadroServico: QuadroSaudeService,
    protected funcaoService: FuncaoService,
  ) {
    this.login = GlobalService.obterSessaoLogin();
    this.orgaos.push(this.login.orgao.id);
    this.ano = this.login.exercicio.id;
    this.mes = 1;
  }

  ngOnInit() {
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
    this.orgaoService.obterTodos().pipe(takeUntil(this.unsubscribe)).subscribe(dados => {
      this.listaOrgaos = [];
      for (const model of dados) {
        this.listaOrgaos.push({ label: `${model.codigo} - ${model.nome}`, value: model.id });
      }
    });
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe)).subscribe(dados => {
      this.listaExercicios = dados.content;
    });
    this.listaAnexos = [
      { id: 'Q1', nome: 'QUADRO 1 - IMPOSTOS' },
      { id: 'Q2', nome: 'QUADRO 2 - RECEITAS VINCULADAS' },
      { id: 'Q3', nome: 'QUADRO 3 - DESPESAS ORÇAMENTÁRIAS' },
      { id: 'Q5', nome: 'QUADRO 5 - RESUMO DA APLICAÇÃO EM SAÚDE - RECURSOS PRÓPRIOS' },
      { id: 'A12', nome: 'ANEXO 12 - RECEITAS E DESPESAS COM AÇÕES E SERVIÇOS COM SAÚDE' },
      { id: 'PRD', nome: 'PROJEÇÃO DA RECEITA E DESPESA' }
    ];
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  async imprimir(formato: FormatoExportacao) {
    const exercicioSelect = this.listaExercicios.find((e) => +e.id === +this.ano);
    let mes: number;
    if (this.opcao == '1') {
      mes = this.mes;
    }

    if (this.anexoSelect === 'Q1') {
      const q1 = new Quadro1Saude(this.quadroServico, mes, exercicioSelect, this.assinaturaService);
      q1.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'Q2') {
      const q2 = new Quadro2Saude(this.quadroServico, mes, exercicioSelect, this.assinaturaService);
      q2.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'Q3') {
      try {
        toastr.warning('Por favor, aguarde! Este relatório realiza uma grande quantidade de cálculos para validação!');
      } catch (err) {
        this.messageService.add({ severity: 'error', summary: 'Validação', detail: err });
        throw err;
      }
      const q3 = new Quadro3Saude(this.quadroServico, mes, exercicioSelect, this.progressoService, this.assinaturaService);
      q3.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'Q5') {
      const q5 = new Quadro5Saude(this.quadroServico, mes, exercicioSelect, this.assinaturaService);
      q5.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'A12') {
      const a12 = new Anexo12Saude(this.quadroServico, mes, exercicioSelect, this.assinaturaService);
      a12.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'PRD') {
      this.exercicioService.obterPorAno(exercicioSelect.ano - 1, this.login.cidade.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          const prd = new ProjecaoReceitaDespesa(this.quadroServico, this.mes, exercicioSelect, res.content[0], this.percentualFerias, this.percentual13, this.assinaturaService);
          prd.montarRelatorio(this.orgaos, formato);
        })
    } else {
      toastr.warning('Selecione o(s) órgão(s) a serem impressos');
    }
  }

}
