import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, MovimentoEstoque } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RPMovimentoEstoqueService extends BaseResourceService<MovimentoEstoque> {

  constructor(
    protected injector: Injector
  ) {
    super(`movimentos-estoque`, injector);
  }

  public efetuarDevolucao(movimento: MovimentoEstoque): Observable<any> {
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/efetuar-devolucao`, { movimento }, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  
  public efetuarTransferencia(movimento: MovimentoEstoque): Observable<any> {
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/efetuar-transferencia`, { movimento }, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public recusarTransferencia(id_movimento: number, motivo_recusa: string): Observable<any> {
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/recusar-transferencia/${id_movimento}`, { motivo_recusa },
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public recusarTransferenciaDev(id_movimento: number, status: string): Observable<any> {
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/recusar-transferencia-dev/${id_movimento}`, { status },
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }


}
