import { Component, Injector } from '@angular/core';
import { BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, LoginContabil, InventarioEstoque } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InventarioEstoqueService } from '../service/inventario-estoque.service';

@Component({
  selector: 'app-inventario-estoque-list',
  templateUrl: './inventario-estoque-list.component.html'
})
export class InventarioEstoqueListComponent extends BaseResourceListComponent<InventarioEstoque, LoginContabil> {

  /**
   * Declaração de variáveis
   */

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private inventarioService: InventarioEstoqueService) {
    super(inventarioService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return '';
  }

  protected condicoesGrid(): {} {
    let condicoes = {
      'orgao.id': this.login.orgao.id,
      'exercicio.id': this.login.exercicio.id,
      'setorAlmoxarifado.id': this.login.setorAlmoxarifado.id,
    };


    return condicoes;
  }

  protected ordenacaoGrid(): string[] {
    return ['id$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['id'],
      date: ['data_inicio', 'data_fim']
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: InventarioEstoque): Observable<InventarioEstoque> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'id' },
      { titulo: 'Data Início', coluna: 'data_inicio' },
      { titulo: 'Data Fim', coluna: 'data_fim' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    this.inventarioService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE INVENTÁRIO DE ESTOQUE`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem inventário estoque', ['auto', '*', '*'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  protected podeAlterar(_entidade: InventarioEstoque): boolean {
    return true;
  }

}
