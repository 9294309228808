import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, TipoConvenioCondicaoDespesa } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class TipoConvenioCondicaoDespesaService extends BaseResourceService<TipoConvenioCondicaoDespesa> {

  constructor(
    protected injector: Injector
  ) {
    super(`tipo-convenio-condicao-despesas`, injector);
  }
}
