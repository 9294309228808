// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { LayoutAudesp } from './layout-audesp.model';

export class LayoutAudespItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public titulo?: string,
    public valor?: string,
    public condicao?: string,
    public agrupar?: string,
    public conta_corrente?: string,
    public entidade?: 'orgao' | 'diario' | 'exercicio' | 'tipo' | 'data' | 'referencia',
    public parente?: LayoutAudespItem,
    public layout?: LayoutAudesp,
    public mostrar?: boolean,
    public ordem?: number,
  ) {
    super();
  }

  static converteJson(json: any): LayoutAudespItem {
    return Object.assign(new LayoutAudespItem(), json);
  }
}
