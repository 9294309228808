
// tslint:disable: no-input-rename
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-page-header-light',
  templateUrl: './page-header-light.component.html'
})
export class PageHeaderLightComponent implements OnInit {

  @Input('page-title') pageTitle: string;

  @Input('fa-icon') faIcon: string;

  constructor() { }

  ngOnInit() {
  }

}
