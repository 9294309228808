import { Directive, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuncaoService, GlobalService, LoginContabil, Relatorio } from 'eddydata-lib';
import { MetaGovernoService } from '../../../../planejamento/meta-governo/service/meta-governo.service';

@Directive()
export class Anexo5Ldo implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected ano: number,
    protected metaServico: MetaGovernoService) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(orgaos: number[], opt?: number, ano?:number, desconsiderarIndicadoresZeradosExercicio?: boolean) {
    this.metaServico.anexo2(1, 9999, this.login.ppa.id, orgaos, opt, ano, desconsiderarIndicadoresZeradosExercicio)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        Relatorio.imprimirPersonalizado(
          'ANEXO V - PLANEJAMENTO ORÇAMENTÁRIO - LDO'
          , this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          this.conteudo(dados.content, opt),
          'portrait', 'ANEXO V - LDO',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private conteudo(dados1: any[], opt: number): {}[] {
    if (dados1.length === 0) return;
      
    // monta o agrupamento do relatório
    const grupos1 = this.funcaoService.agrupar(dados1,
      ['o_codigo', 'pr_nome', 'pr_codigo', 'un_nome', 'un_codigo', 'me_especie', 'me_tipo',
        'me_justificativa', 'me_objetivo', 'custo1', 'custo2', 'custo3', 'custo4']);
    const retorno = [];
    for (let index = 0; index < grupos1.length; index++) {
      const item1 = grupos1[index];

      retorno.push({
        text: 'DESCRIÇÃO DOS PROGRAMAS GOVERNAMENTAIS/METAS/CUSTOS', alignment: 'center',
        bold: true, fontSize: 10
      });

      retorno.push({
        text: `EXERCÍCIO: ${this.ano}`, alignment: 'center',
        bold: true, fontSize: 10
      });

      retorno.push({ text: '', margin: [0, 5] });
      retorno.push({ canvas: [{ type: 'line', x1: 1, y1: 1, x2: 530, y2: 1, lineWidth: 0.5 }] });
      retorno.push({ text: '', margin: [0, 5] });

      retorno.push({ columns: [{ text: 'ÓRGÃO: ', width: 100, bold: true }, item1.grupo['o_codigo']], margin: [0, 2] });
      retorno.push({
        columns: [{ text: 'TIPO DE PROGRAMA: ', width: 100, bold: true },
        this.globalService.obterEspecieMeta(item1.grupo['me_tipo'])], margin: [0, 2]
      });
      retorno.push({ columns: [{ text: 'PROGRAMA: ', width: 100, bold: true }, item1.grupo['pr_nome']], margin: [0, 2] });
      retorno.push({ columns: [{ text: 'CÓDIGO DO PROGRAMA: ', width: 100, bold: true }, item1.grupo['pr_codigo']], margin: [0, 2] });
      retorno.push({ columns: [{ text: 'UNIDADE RESPONSÁVEL: ', width: 100, bold: true }, item1.grupo['un_nome']], margin: [0, 2] });
      retorno.push({ columns: [{ text: 'CÓDIGO DA UNIDADE: ', width: 100, bold: true }, item1.grupo['un_codigo']], margin: [0, 2, 0, 10] });
      retorno.push({
        columns: [{ text: 'OBJETIVO:', bold: true, width: 100, margin: [0, 2, 2, 10] },
        { text: item1.grupo['me_objetivo'], width: '*', margin: [0, 2, 2, 10] }]
      });
      
      retorno.push({
        columns: [{ text: 'JUSTIFICATIVA:', bold: true, width: 100, margin: [0, 2, 2, 10] },
        { text: item1.grupo['me_justificativa'], width: '*', margin: [0, 2, 2, 10] }]
      });

      retorno.push({ text: '', margin: [0, 5] });
      retorno.push({ canvas: [{ type: 'line', x1: 1, y1: 1, x2: 530, y2: 1, lineWidth: 0.5 }] });
      retorno.push({ text: '', margin: [0, 5] });

      const registros: {}[] = [];
      let custo: number;

      retorno.push({
        text: 'METAS FÍSICAS', alignment: 'center',
        bold: true, fontSize: 10, margin: [0, 2]
      });

      registros.push([
        { text: 'INDICADORES', bold: true, fontSize: 10, alignment: 'center' },
        { text: 'UNIDADE MEDIDA', bold: true, fontSize: 10, alignment: 'center' },
        { text: this.ano, bold: true, fontSize: 10, alignment: 'center' },
        { text: 'Ação', bold: true, fontSize: 10, alignment: 'center' }
      ]);

      // lista os registros do relatorio
      for (let idx = 0; idx < item1.registros.length; idx++) {
        const element1 = item1.registros[idx];
        let valorMeta: number;

        switch (Number(this.ano)) {
          case this.login.ppa.ppaperiodo.ano1:
            valorMeta = opt == 1 ? element1.in_meta1 : element1.in_evolucao1;
            custo = element1.custo1;
            break;
          case this.login.ppa.ppaperiodo.ano2:
            valorMeta = opt == 1 ? element1.in_meta2 : element1.in_evolucao2;
            custo = element1.custo2;
            break;
          case this.login.ppa.ppaperiodo.ano3:
            valorMeta = opt == 1 ? element1.in_meta3 : element1.in_evolucao3;
            custo = element1.custo3;
            break;
          case this.login.ppa.ppaperiodo.ano4:
            valorMeta = opt == 1 ? element1.in_meta4 : element1.in_evolucao4;
            custo = element1.custo4;
            break;
          default:
            valorMeta = 0;
            custo = 0;
            break;
        }

        registros.push([
          {
            text: element1 ? `${element1.in_nome}` : '', fontSize: 10, bold: element1.principal ? true : false
          },
          {
            text: element1 ? element1.in_unidade_medida : '', alignment: 'center',
            fontSize: 10, bold: element1.principal ? true : false
          },
          {
            text: element1 ? this.funcaoService.convertToBrNumber(valorMeta) : '', alignment: 'center',
            fontSize: 10, bold: element1.principal ? true : false
          },
          {
            text: element1 ? `${element1.a_codigo}` : '', alignment: 'center',
            fontSize: 10, bold: element1.principal ? true : false
          }
        ]);
      }

      retorno.push({
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 2,
          widths: [300, '*', '*', '*'],
          body: registros
        }, margin: [0, 0, 0, 4]
      });

      retorno.push({
        columns: [{ text: 'CUSTO DO PROGRAMA PARA O EXERCÍCIO: R$', width: 260, bold: true },
        this.funcaoService.convertToBrNumber(custo)], margin: [0, 5], fontSize: 12, bold: true
      });

      if (index !== grupos1.length - 1) {
        retorno.push({ text: '', pageBreak: 'after' });
      }
    }

    return retorno;
  }
}