import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { tsXLXS } from 'ts-xlsx-export';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  Coluna, Contrato, Despesa, EddyAutoComplete, Executora, ExecutoraService,
  Exercicio, ExercicioService, FuncaoGoverno, FuncaoService, GlobalService,
  LoginContabil, Recurso, Relatorio
} from 'eddydata-lib';
import { AdiantamentoService, DespesaService, FuncaoGovernoService, RecursoService } from 'administrativo-lib';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'lib-adiantamento-rpt',
  templateUrl: './adiantamento-rpt.component.html'
})
export class AdiantamentoRptComponent implements OnInit, OnDestroy {

  login: LoginContabil = new LoginContabil();
  funcaoGoverno: FuncaoGoverno;
  subFuncaoGoverno: FuncaoGoverno;
  despesa: Despesa;
  recurso: Recurso;
  aplicacao: Recurso;
  contrato: Contrato;
  filtroDespesa: boolean;
  filtroFuncao: boolean;
  filtroSubFuncao: boolean;
  filtroRecurso: boolean;
  filtroAplicacao: boolean;
  public selectedOrdem: string;
  public selectedOrdemContas: string;
  public ptBR: any;
  public listaRelatorio: Array<any>;
  public funcaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public subFuncaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public despesaAutoComplete: EddyAutoComplete<Despesa>;
  public executoraAutoComplete: EddyAutoComplete<Executora>;
  public relatorio = '1';
  public dataInicial: string;
  public dataFinal: string;
  public numeroInicial: string;
  public numeroFinal: string;
  public exercicioSelect: Exercicio;
  public executoraSelect: Executora
  public listaExercicios: Array<Exercicio>;
  public formato: 'pdf' | 'csv' | 'xlsx'
  protected datepipe: DatePipe;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    protected injector: Injector,
    protected messageService: MessageService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected funcaoGovernoService: FuncaoGovernoService,
    protected recursoService: RecursoService,
    protected despesaService: DespesaService,
    protected executoraService: ExecutoraService,
    protected adiantamentoService: AdiantamentoService,
    protected exercicioService: ExercicioService) {
    this.datepipe = new DatePipe('pt');
    this.exercicioSelect = this.login.exercicio;
  }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.login = GlobalService.obterSessaoLogin();
    this.selectedOrdem = 'ord1';
    this.selectedOrdemContas = 'ord1';
    this.formato = 'pdf';
    this.listaRelatorio = [];
    this.listaRelatorio.push({ label: 'TODOS OS ADIANTAMENTOS', value: 1 });
    this.listaRelatorio.push({ label: 'SOMENTE ADIANTAMENTOS ANULADOS', value: 2 });
    this.listaRelatorio.push({ label: 'CONFERÊNCIA AUDESP ADIANTAMENTOS', value: 3 });
    this.listaRelatorio.push({ label: 'COBRANÇA DE PRESTAÇÃO DE CONTAS', value: 4 });
    this.carregarAutoCompletes();
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaExercicios = dados.content;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public gerarRelatorio() {
    const parametros = {};
    if ((!this.dataInicial || !this.dataFinal) && this.relatorio !== '4') {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }

    parametros['ignoreCondObrig'] = true;
    parametros['orgao.id'] = this.login.orgao.id;

    if (this.relatorio !== '3')
      parametros['exercicio.id'] = this.login.exercicio.id;

    if (this.relatorio == '3' && this.exercicioSelect?.id)
      parametros['exercicio.id'] = this.exercicioSelect.id

    if (this.dataInicial) {
      parametros['data_adiantamento$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
    }

    if (this.dataFinal) {
      parametros['data_adiantamento$le'] = this.funcaoService.converteDataSQL(this.dataFinal);
    }

    if (this.filtroFuncao) {
      parametros['empenho.ficha.funcao.id'] = this.funcaoGoverno.id;
    }

    if (this.filtroSubFuncao) {
      parametros['empenho.ficha.subfuncao.id'] = this.subFuncaoGoverno.id;
    }

    if (this.filtroDespesa) {
      parametros['empenho.subelemento.id'] = this.despesa.id;
    }

    if (this.filtroRecurso) {
      parametros['empenho.ficha.recurso.id'] = this.recurso.id;
    }

    if (this.filtroAplicacao) {
      parametros['empenho.ficha.aplicacao.id'] = this.aplicacao.id;
    }

    if (this.relatorio === '2') {
      parametros['empenho.especie'] = 'EOA';
    }

    if (this.relatorio === '4') {
      parametros['data_termino$null'] = true;
      parametros['data_prestacao$null'] = true;
      if (this.numeroInicial) parametros['numero$ge'] = this.numeroInicial;
      if (this.numeroFinal) parametros['numero$le'] = this.numeroFinal;
      if (this.executoraSelect) parametros['empenho.ficha.executora.id'] = this.executoraSelect.id;
      if (this.selectedOrdemContas === 'ord1') {
        parametros['orderBy'] = 'numero';
      } else if (this.selectedOrdemContas === 'ord2') {
        parametros['orderBy'] = 'data_adiantamento';
      } else if (this.selectedOrdemContas === 'ord3') {
        parametros['orderBy'] = 'empenho.favorecido.nome';
      } else if (this.selectedOrdemContas === 'ord4') {
        parametros['orderBy'] = 'data_vencimento';
      }
    } else {
      if (this.selectedOrdem === 'ord1') {
        parametros['orderBy'] = 'data_adiantamento';
      } else if (this.selectedOrdem === 'ord2') {
        parametros['orderBy'] = 'empenho.numero';
      } else if (this.selectedOrdem === 'ord3') {
        parametros['orderBy'] = 'empenho.favorecido.nome';
      }
    }

    parametros['relations'] = 'empenho,empenho.favorecido,empenho.subelemento,empenho.ficha,empenho.ficha.funcao,'
      + 'empenho.ficha.subfuncao,empenho.ficha.recurso,empenho.ficha.aplicacao,empenho.ficha.executora,empenho.subelemento.plano';

    this.adiantamentoService.extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((lista) => {
        if (this.relatorio == '3') {
          this.imprimirAdiantamentoAudesp(lista);
        } else if (this.relatorio == '4') {
          this.imprimirAdiantamentoContas(lista);
        } else {
          this.imprimirAdiantamento(lista);
        }
      });
  }

  private imprimirAdiantamentoAudesp(lista: any) {
    if (this.formato === 'pdf') {
      Relatorio.imprimirPersonalizado('LISTAGEM DE ADIANTAMENTOS', this.login.usuario.nome, this.login.usuario.sobrenome,
        this.login.orgao.nome, this.login.brasao,
        this.montarConteuadoAdiantamentoAudesp(lista.content),
        'landscape', 'LISTAGEM DE ADIANTAMENTOS',
        {
          linhas: {
            hLineWidth() {
              return 1;
            },
            vLineWidth() {
              return 1;
            },
            hLineColor() {
              return 'black';
            },
            paddingLeft() {
              return 3;
            },
            paddingRight() {
              return 3;
            }
          }
        }, false);
    } else {
      this.funcaoService.exportar(this.formato, lista.content, `CONFERÊNCIA AUDESP ADIANTAMENTOS`, this.colunasRelatorio() as Coluna[]);
    }
  }

  private colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'DATA', coluna: 'data_adiantamento' },
      { titulo: 'PREVISAO PAGTO', coluna: 'data_ultimo_pagamento' },
      { titulo: 'FORNECEDOR', coluna: 'empenho.favorecido.nome', alignment: 'right' },
      { titulo: 'CPF CNPJ', coluna: 'empenho.favorecido.cpf_cnpj', alignment: 'right' },
      { titulo: 'Nº EMPENHO', coluna: 'empenho.numero', alignment: 'right' },
      { titulo: 'Cód. DESPESA', coluna: 'empenho.subelemento.codigo', alignment: 'right' },
      { titulo: 'NOME DESPESA', coluna: 'empenho.subelemento.nome', alignment: 'right' },
      { titulo: 'VALOR CONCEDIDO', coluna: 'valor_adiantado', decimais: 2, alignment: 'right' },
      { titulo: 'VALOR DEVOLVIDO', coluna: 'total_empenho_anulado', decimais: 2, alignment: 'right' },
      { titulo: 'VALOR EFETIVADO', coluna: 'total_pago', decimais: 2, alignment: 'right' },
      { titulo: 'Cód. PLANO DE CONTAS', coluna: 'empenho.subelemento.plano.codigo', alignment: 'right' },
      { titulo: 'NOME PLANO DE CONTAS', coluna: 'empenho.subelemento.plano.nome', alignment: 'right' },
      { titulo: 'DATA TÉRMINO', coluna: 'data_termino', alignment: 'right' },
    ];
  }

  private imprimirAdiantamento(lista: any) {
    Relatorio.imprimirPersonalizado('LISTAGEM DE ADIANTAMENTOS', this.login.usuario.nome, this.login.usuario.sobrenome,
      this.login.orgao.nome, this.login.brasao,
      this.montarConteudoAdiantamento(lista.content),
      'landscape', 'LISTAGEM DE ADIANTAMENTOS',
      {
        linhas: {
          hLineWidth() {
            return 1;
          },
          vLineWidth() {
            return 1;
          },
          hLineColor() {
            return 'black';
          },
          paddingLeft() {
            return 3;
          },
          paddingRight() {
            return 3;
          }
        }
      }, false);
  }

  private montarConteuadoAdiantamentoAudesp(lista: any[]) {
    let retorno: {}[] = [];
    retorno.push({
      text: `PERÍODO: ${this.dataInicial} à ${this.dataFinal}`,
      alignment: 'center', fontSize: 10, lineHeight: 1.5
    });

    const conteudo = [];
    conteudo.push([
      { text: 'DATA ADIANTAMENTO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
      { text: 'PREVISAO PAGTO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
      // { text: 'NÚMERO', fontSize: 8, alignment: 'center', bold: true },
      { text: 'FORNECEDOR', fontSize: 8, alignment: 'center', bold: true },
      { text: 'DOCUMENTO', fontSize: 8, alignment: 'center', bold: true },
      { text: 'Nº EMP', fontSize: 8, alignment: 'center', bold: true },
      { text: 'DESPESA', fontSize: 8, alignment: 'center', bold: true },
      { text: 'VALOR ADIANTAMENTO', fontSize: 8, alignment: 'center', bold: true },
      { text: 'VALOR DEVOLUÇÃO', fontSize: 8, alignment: 'center', bold: true },
      { text: 'VALOR UTILIZADO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
      { text: 'PLANO DE CONTAS', fontSize: 8, alignment: 'left', bold: true, border: [true, true, true, true] },
      { text: 'DATA TERMINO', fontSize: 8, alignment: 'left', bold: true, border: [true, true, true, true] },
    ]);
    let soma_empenho = 0;
    let soma_anulado = 0;
    for (const entidade of lista) {
      const registro = [];
      registro.push({ text: this.datepipe.transform(entidade.data_adiantamento, 'dd/MM/yyyy'), fontSize: 8, alignment: 'center', bold: false });
      registro.push({
        text: this.datepipe.transform(entidade.data_ultimo_pagamento, 'dd/MM/yyyy'),
        fontSize: 8, alignment: 'center', bold: false
      });
      registro.push({
        text: entidade.empenho.favorecido.nome,
        fontSize: 8, alignment: 'left', bold: false
      });
      registro.push({
        text: entidade.empenho.favorecido.cpf_cnpj,
        fontSize: 8, alignment: 'left', bold: false
      });
      registro.push({
        text: entidade.empenho.numero,
        fontSize: 8, alignment: 'right', bold: false
      });
      registro.push({ text: entidade.empenho.subelemento.codigo, fontSize: 8, alignment: 'center', bold: false });
      registro.push({
        text: this.funcaoService.convertToBrNumber(+entidade.valor_adiantado, 2),
        fontSize: 8, alignment: 'right', bold: false
      });
      registro.push({ text: this.funcaoService.convertToBrNumber(entidade.total_empenho_anulado, 2), fontSize: 8, alignment: 'right', bold: false });
      registro.push({ text: this.funcaoService.convertToBrNumber(+entidade.total_pago), fontSize: 8, alignment: 'right', bold: false });
      registro.push({ text: entidade.empenho.subelemento.plano.codigo, fontSize: 8, alignment: 'left', bold: false });
      registro.push({ text: this.datepipe.transform(entidade.data_termino, 'dd/MM/yyyy'), fontSize: 8, alignment: 'center', bold: false });
      conteudo.push(registro);
      soma_empenho += +entidade.valor_adiantado;
      soma_anulado += +entidade.total_empenho_anulado;
    }

    const total = [];
    total.push({ text: 'TOTAL', fontSize: 8, alignment: 'left', bold: true, colSpan: 5 });
    total.push('');
    total.push('');
    total.push('');
    total.push('');
    total.push('');
    total.push({ text: this.funcaoService.convertToBrNumber(soma_empenho, 2), fontSize: 8, alignment: 'right', bold: true });
    total.push({ text: this.funcaoService.convertToBrNumber(soma_anulado, 2), fontSize: 8, alignment: 'right', bold: true });
    total.push({ text: this.funcaoService.convertToBrNumber(+soma_empenho + +soma_anulado, 2), fontSize: 8, alignment: 'right', bold: true });
    total.push('');
    total.push('');
    conteudo.push(total);

    retorno.push({
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: conteudo
      }, margin: [0, 0, 0, 15]
    });

    return retorno;
  }

  private montarConteudoAdiantamento(lista: any[]) {
    let retorno: {}[] = [];
    retorno.push({
      text: `PERÍODO: ${this.dataInicial} à ${this.dataFinal}`,
      alignment: 'center', fontSize: 10, lineHeight: 1.5
    });

    const conteudo = [];
    conteudo.push([
      { text: 'DATA ADIANTAMENTO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
      { text: 'NÚMERO', fontSize: 8, alignment: 'center', bold: true },
      { text: 'BENEFICIÁRIO', fontSize: 8, alignment: 'center', bold: true },
      { text: 'HISTÓRICO', fontSize: 8, alignment: 'center', bold: true },
      { text: 'PREST. CONTA', fontSize: 8, alignment: 'center', bold: true },
      { text: 'VALOR ADIANTAMENTO', fontSize: 8, alignment: 'center', bold: true },
      { text: 'VALOR DEVOLUÇÃO', fontSize: 8, alignment: 'center', bold: true },
      { text: 'VALOR UTILIZADO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
    ]);
    let soma_empenho = 0;
    let soma_anulado = 0;
    for (const entidade of lista) {
      const registro = [];
      let valor = +entidade.valor_adiantado
      valor += +entidade.total_empenho_anulado > 0 ? +entidade.total_empenho_anulado : +entidade.total_empenho_anulado * -1;
      let valor_utilizado = +entidade.total_empenho_anulado > 0 ? +entidade.total_empenhado_orcamentario - +entidade.total_empenho_anulado : +entidade.total_empenhado_orcamentario - (+entidade.total_empenho_anulado * -1); 
      registro.push({ text: this.datepipe.transform(entidade.data_adiantamento, 'dd/MM/yyyy'), fontSize: 8, alignment: 'center', bold: false });
      registro.push({
        text: entidade.numero,
        fontSize: 8, alignment: 'center', bold: false
      });
      registro.push({
        text: entidade.empenho.favorecido.nome,
        fontSize: 8, alignment: 'left', bold: false
      });
      registro.push({
        text: entidade.empenho.historico,
        fontSize: 8, alignment: 'left', bold: false
      });
      registro.push({ text: this.datepipe.transform(entidade.data_prestacao, 'dd/MM/yyyy'), fontSize: 8, alignment: 'center', bold: false });
      registro.push({
        text: this.funcaoService.convertToBrNumber(entidade.total_empenhado_orcamentario, 2),
        fontSize: 8, alignment: 'right', bold: false
      });
      registro.push({ text: this.funcaoService.convertToBrNumber(entidade.total_empenho_anulado, 2), fontSize: 8, alignment: 'right', bold: false });
      registro.push({ text: this.funcaoService.convertToBrNumber(valor_utilizado, 2), fontSize: 8, alignment: 'right', bold: false });
      conteudo.push(registro);
      soma_empenho += +entidade.total_empenhado_orcamentario;
      soma_anulado += +entidade.total_empenho_anulado;
    }

    const total = [];
    total.push({ text: 'TOTAL', fontSize: 8, alignment: 'left', bold: true, colSpan: 5 });
    total.push('');
    total.push('');
    total.push('');
    total.push('');
    total.push({ text: this.funcaoService.convertToBrNumber(soma_empenho, 2), fontSize: 8, alignment: 'right', bold: true });
    total.push({ text: this.funcaoService.convertToBrNumber(soma_anulado, 2), fontSize: 8, alignment: 'right', bold: true });
    total.push({ text: this.funcaoService.convertToBrNumber(+soma_empenho + +soma_anulado, 2), fontSize: 8, alignment: 'right', bold: true });
    conteudo.push(total);

    retorno.push({
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: conteudo
      }, margin: [0, 0, 0, 15]
    });

    return retorno;
  }

  private imprimirAdiantamentoContas(lista: any) {
    Relatorio.imprimirPersonalizado('', this.login.usuario.nome, this.login.usuario.sobrenome,
      this.login.orgao.nome, this.login.brasao,
      this.montarConteudoAdiantamentoContas(lista.content),
      'portrait', 'LISTAGEM DE COBRANÇA DE PRESTAÇÃO DE CONTAS',
      {
        linhas: {
          hLineWidth() {
            return 1;
          },
          vLineWidth() {
            return 1;
          },
          hLineColor() {
            return 'black';
          },
          paddingLeft() {
            return 3;
          },
          paddingRight() {
            return 3;
          }
        }
      }, false);
  }

  private montarConteudoAdiantamentoContas(lista: any[]) {
    let retorno: {}[] = [];

    for (const entidade of lista) {
      if (retorno.length) retorno.push([{ text: '', pageBreak: 'after' }]);
      retorno.push({ text: `${this.login.cidade.nome}, ${this.funcaoService.converteDataBR(new Date())}`, fontSize: 9, bold: true });

      const conteudo = [];
      conteudo.push([{ text: 'Prezado(a), Senhor(a)', fontSize: 10, border: [false, false, false, false], margin: [0, 15, 0, 0] }]);
      conteudo.push([{ text: `\u200B\t${entidade.empenho.favorecido.nome}`, fontSize: 10, border: [false, false, false, false] }]);
      conteudo.push([{ text: 'Secretária:', fontSize: 10, bold: true, border: [false, false, false, false], margin: [0, 10, 0, 0] }]);
      conteudo.push([{ text: `\u200B\t${entidade.empenho.ficha.executora.nome}`, fontSize: 10, bold: true, border: [false, false, false, false] }]);
      conteudo.push([{
        text: `\u200B\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tLevamos ao conhecimento de V. Sa que se encontra registrado em seu nome, ADIANTAMENTO no valor de R$${this.funcaoService.convertToBrNumber(entidade.valor_adiantado, 2)} ` +
          `conforme processo N° ${entidade.numero} cuja prestação de contas deveria ter sido efetivada até o dia ${this.funcaoService.converteDataBR(entidade.data_vencimento)}. Como isso não ocorreu, fica concedido for força da ` +
          `Lei N° 2049 de 08/09/2009, e Resolução N° 02/98 do Tribunal de Contas do Estado de São Paulo, o prazo IMPRORROGÁVEL de 03 (três) dias, para a prestação de contas do adiantamento mencionado.`,
        fontSize: 10, border: [false, false, false, false], margin: [0, 10, 0, 0]
      }]);
      conteudo.push([{ text: `\u200B\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tSendo só os que nos oferece para o momento subscrevemos-nos.`, fontSize: 10, border: [false, false, false, false] }]);
      conteudo.push([{ text: `Atenciosamente`, fontSize: 10, alignment: 'center', border: [false, false, false, false] }]);

      retorno.push({
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          widths: ['*'],
          body: conteudo
        },
        margin: [0, 0, 0, 15]
      });

      // Assinaturas
      const assinaturas = [];
      assinaturas.push([
        { text: '__________________________________________', alignment: 'center', border: [false, false, false, false], margin: [0, 25, 0, 0] },
        { text: '__________________________________________', alignment: 'center', border: [false, false, false, false], margin: [0, 25, 0, 0] }
      ]);
      assinaturas.push([
        { text: 'Cliente', fontSize: 10, alignment: 'center', border: [false, false, false, false], margin: [0, 0, 0, 0]},
        { text: 'Responsável', fontSize: 10, alignment: 'center', border: [false, false, false, false], margin: [0, 0, 0, 0]},
      ]);
      assinaturas.push([{ text: '\u200B\t\tData ____/____/________', fontSize: 10, colSpan: 2, border: [false, false, false, false], margin: [0, 20, 0, 0] }, '']);

      retorno.push({
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          widths: ['*', '*'],
          body: assinaturas
        },
        margin: [0, 0, 0, 15]
      });
    }

    return retorno;
  }

  exportarXlxs() {
    const parametros = {};
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['relations'] = 'empenho,empenho.favorecido,empenho.subelemento,empenho.ficha,empenho.ficha.funcao,'
      + 'empenho.ficha.subfuncao,empenho.ficha.recurso,empenho.ficha.aplicacao';
    parametros['orderBy'] = 'codigo';
    this.adiantamentoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          const listaItens = new Array();
          for (const item of lista.content) {
            const entity = {
              numero: item.numero,
              cpf: item.data_adiantamento,
              favorecido: item.empenho.favorecido.nome,
              subelemento: item.empenho.subelemento.nome,
              funcao: item.empenho.ficha.funcao.nome,
              subfuncao: item.empenho.ficha.subfuncao.nome,
              recurso: item.empenho.ficha.recurso.nome,
              aplicacao: item.empenho.ficha.aplicacao.nome,
              valor: item.valor_adiantado
            };
            listaItens.push(entity);
          }
          tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile('adiantamentos');
        },
        () => alert('erro ao retornar lista')
      );
  }

  private carregarAutoCompletes() {

    // autocomplete para funcao governo
    this.funcaoAutoComplete = new EddyAutoComplete(null, this.funcaoGovernoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para subfuncao governo
    this.subFuncaoAutoComplete = new EddyAutoComplete(null, this.funcaoGovernoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para despesa
    this.despesaAutoComplete = new EddyAutoComplete(null, this.despesaService,
      'id', ['codigo', 'nome'], { escrituracao: true, exercicio_id: this.login.exercicio.id, orderBy: 'nome' },
      { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para recurso
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para executora
    this.executoraAutoComplete = new EddyAutoComplete(null, this.executoraService,
      'id', ['codigo', 'nome'], { 'unidade.ppa.id': this.login.ppa.id, relations: 'unidade.ppa', orderBy: 'codigo,nome' }, { text: ['codigo', 'nome'] }
    );
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }

  /**
   * Método para verificação de objetos, usados em combos `<select>`
   */
  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }
}
