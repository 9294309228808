import { Component, Injector, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Coluna, Filtro, FuncaoService, GlobalService, LoginContabil, Produto, SaldoEstoqueService } from 'eddydata-lib';
import { Observable, Subject } from 'rxjs';
import * as toastr from 'toastr';

declare type Saldo = {
  setor: string,
  sum_entrada: number,
  sum_saida: number,
  saldo_atual: number
};

@Component({
  selector: 'lib-produto-saldo-setor',
  templateUrl: './produto-saldo-setor.component.html'
})

export class ProdutoSaldoSetorComponent implements OnInit, OnDestroy {
  @Input() public set produto(v: Produto) { this._produto = v; }
  @Input() login: LoginContabil;
  @Input() carregarSaldoSetor: boolean = false;

  public saldos: Saldo[] = [];
  public _produto: Produto;
  public comSaldo: boolean = true;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected funcaoService: FuncaoService,
    protected injector: Injector,
    protected globalService: GlobalService,
    private saldoEstoqueService: SaldoEstoqueService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['carregarSaldoSetor']) this.carregarSaldo()
  }

  private carregarSaldo() {
    if (this._produto && this._produto.id && this.login)
      if (this.comSaldo === true) {
        this.saldoEstoqueService.saldoProdutoPorSetorAlmoxarifado(this._produto.id, this.login.orgao.id, this.login.estoque.id, this.comSaldo)
          .subscribe((data) => {
            // this.saldos = data;
            this.saldos = data.filter((x) => { return x.saldo_atual != 0 });
          }, (error) => toastr.error(error.error.payload));
      } else {
        this.saldoEstoqueService.saldoProdutoPorSetorAlmoxarifado(this._produto.id, this.login.orgao.id, this.login.estoque.id, this.comSaldo)
          .subscribe((data) => {
            this.saldos = data;
          }, (error) => toastr.error(error.error.payload));
      }
  }
}
