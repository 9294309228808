import { Compra, CompraItem, Estoque, FuncaoService, GlobalService, Login, MovimentoItemEstoque, Relatorio } from 'eddydata-lib';
export class PendenciaCompra {

    protected funcaoService: FuncaoService;
    protected globalService: GlobalService;

    constructor(private login: Login) {
        this.funcaoService = new FuncaoService();
        this.globalService = new GlobalService();
    }

    public async imprimir(model: {
        compra: Compra, compras?: Compra[], gratuito: boolean, observacoes: string[],
        importada: boolean, estoque_transferencia: Estoque, itens: MovimentoItemEstoque[]
    }) {

        Relatorio.imprimirPersonalizado('LISTAGEM DE ITENS PENDENTES', this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            this.montarConteudo(model),
            'portrait', 'LISTAGEM DE ITENS PENDENTES',
            {
                linhas: {
                    hLineWidth() {
                        return 1;
                    },
                    vLineWidth() {
                        return 1;
                    },
                    hLineColor() {
                        return 'black';
                    },
                    paddingLeft() {
                        return 3;
                    },
                    paddingRight() {
                        return 3;
                    }
                }
            }, true, false);
    }

    private montarConteudo(entidade: {
        compra: Compra,
        compras?: Compra[],
        gratuito: boolean,
        observacoes: string[],
        importada: boolean,
        estoque_transferencia: Estoque,
        itens: MovimentoItemEstoque[]
    }) {

        const conteudo = [];

        if (entidade.compras) {
            entidade.compras.forEach(compra => {
                conteudo.push(this.dadosCabecalho(this.login)
                    .concat(this.dadosCompra(compra))
                    .concat(this.dadosClassificacao(compra))
                    .concat(this.dadosFornecedor(compra))
                    .concat(this.dadosItens(compra.itens, entidade.itens, entidade.compras.indexOf(compra) !== entidade.compras.length - 1)));
            })
        } else {
            conteudo.push(this.dadosCabecalho(this.login)
                .concat(this.dadosCompra(entidade.compra))
                .concat(this.dadosClassificacao(entidade.compra))
                .concat(this.dadosFornecedor(entidade.compra))
                .concat(this.dadosItens(entidade.compra.itens, entidade.itens)));
        }
        return conteudo;
    }

    private dadosCabecalho(login: Login): {}[] {
        let brasaoImage: {};
        if (login.brasao) {
            brasaoImage = {
                image: login.brasao,
                width: 70,
                alignment: 'left',
                margin: [0, 0, 0, 0],
                border: [true, true, false, false]
            };
        } else {
            brasaoImage = { margin: [0, 10, 0, 0], text: '', border: [true, true, false, false] };
        }

        brasaoImage['rowSpan'] = 5;

        const conteudo = [];

        conteudo.push([brasaoImage, { text: login.orgao.nome, bold: true, alignment: 'center', fontSize: 13, border: [false, true, true, false] }]);
        conteudo.push([
            { text: '' },
            {
                text: `${this.login.orgao.endereco}, ${this.login.orgao.cidade} - CEP: ${this.login.orgao.cep} - PABX: ${this.login.orgao.telefone} - FAX: ${this.login.orgao.telefone}`,
                fontSize: 8, alignment: 'center', border: [false, false, true, false]
            }
        ]);
        conteudo.push([
            { text: '' },
            { text: `C.N.P.J.: ${this.login.orgao.cnpj}     -    Site: ${this.login.orgao.site}`, fontSize: 8, alignment: 'center', border: [false, false, true, false] }
        ]);
        conteudo.push([
            { text: '' },
            { text: 'LISTAGEM DE ITENS PENDENTES', bold: true, alignment: 'center', fontSize: 13, border: [false, false, true, false] }
        ]);
        conteudo.push([
            { text: '' },
            { text: `${login.exercicio.ano}`, bold: true, alignment: 'center', fontSize: 12, border: [false, false, true, false] }
        ]);

        return [{
            layout: 'linhas',
            table: {
                widths: [50, '*'],
                body: conteudo
            }
        }];
    }

    private dadosCompra(compra: Compra): {}[] {
        const tipoCompra = this.globalService.obterListaTiposEmpenhos().find(x => x.id === compra.tipo_compra);
        const conteudo = [
            [
                {
                    text: 'OF Nº ' + this.funcaoService.strZero(compra.numero, 4),
                    rowSpan: 2, fontSize: 11, alignment: 'left', bold: true, margin: [0, 10, 10, 10]
                },
                { text: 'Ficha', fontSize: 7, border: [true, true, false, false] },
                { text: 'Tipo', fontSize: 7, border: [true, true, false, false] },
                { text: 'Data', fontSize: 7, border: [true, true, false, false] },
                {
                    text: 'Prazo de Entrega', fontSize: 7, border: [true, true, false, false],
                },
                {
                    text: 'Licitação', fontSize: 7, border: [true, true, false, false],
                },
                {
                    text: 'Processo', fontSize: 7, border: [true, true, false, false],
                },
                {
                    text: 'Contrato', fontSize: 7, border: [true, true, false, false],
                },
                {
                    text: 'Prazo de Pagamento', fontSize: 7, border: [true, true, false, false],
                },
                {
                    text: 'Modalidade', fontSize: 7, border: [true, true, true, false],
                }
            ],
            [
                { text: '' },
                { text: compra.ficha?.numero, alignment: 'center', bold: true, border: [false, false, false, true] },
                {
                    text: tipoCompra ? tipoCompra.nome : '-',
                    alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
                },
                {
                    text: this.funcaoService.converteDataBR(compra.data_compra),
                    alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
                },
                {
                    text: compra.prazo_entrega?.nome, fontSize: 6,
                    alignment: 'center', bold: true, border: [true, false, false, true]
                },
                {
                    text: compra.licitacao?.numero,
                    alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
                },
                {
                    text: compra.processo,
                    alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
                },
                {
                    text: compra.contrato?.numero,
                    alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
                },
                {
                    text: compra.prazo_pagamento?.nome, fontSize: 6,
                    alignment: 'center', bold: true, border: [true, false, false, true]
                },
                {
                    text: compra.modalidade?.nome,
                    alignment: 'center', fontSize: 5, bold: true, border: [true, false, true, true]
                },
            ]];

        return [{
            layout: 'linhas',
            table: {
                widths: [70, 25, 50, 40, '*', 50, 40, 50, '*', '*'],
                body: conteudo
            }
        }];
    }

    private dadosClassificacao(compra: Compra): {}[] {

        const conteudo = [
            [
                { text: 'UNIDADE:', border: [true, false, false, false] },
                {
                    text: `${compra.ficha?.executora?.unidade?.codigo} ${compra.ficha?.executora?.unidade?.nome}`,
                    border: [false, false, false, false]
                },
                { text: `RCMS:      ${compra.rcms?.numero ? compra.rcms?.numero : ''}`, border: [false, false, true, false], fontSize: 12, bold: true },
            ],
            [
                { text: 'EXECUTORA:', border: [true, false, false, false] },
                { text: `${compra.ficha?.executora?.codigo} ${compra.ficha?.executora?.nome} `, border: [false, false, false, false] },
                { text: `SETOR:    ${compra.rcms?.setor?.nome ? compra.rcms?.setor?.nome : ''}`, border: [false, false, true, false] },
            ],
            [
                { text: 'DESPESA:', border: [true, false, false, false] },
                { text: `${compra.ficha?.despesa?.codigo} ${compra.ficha?.despesa?.nome} `, border: [false, false, false, false] },
                { text: '', border: [false, false, true, false] },
            ],
            [
                { text: 'SUBELEMENTO:', border: [true, false, false, false] },
                { text: `${compra.subelemento?.codigo} ${compra.subelemento?.nome} `, border: [false, false, false, false] },
                { text: `Obs.: ${compra.rcms?.observacao ? compra.rcms?.observacao : ''}`, rowSpan: 2, border: [false, false, true, false], }
            ],
            [
                { text: 'RECURSO:', border: [true, false, false, false] },
                {
                    text: `${compra.ficha?.recurso?.codigo} ${compra.ficha?.aplicacao?.codigo} ${compra.ficha?.aplicacao?.nome}`,
                    border: [false, false, false, false]
                },
                { text: '' }
            ],
            [
                { text: 'PROJETO:', border: [true, false, false, true] },
                { text: `${compra.ficha?.acao?.codigo} ${compra.ficha?.acao?.nome} `, border: [false, false, false, true] },
                { text: '', border: [false, false, true, true] }
            ],

        ];

        return [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                headerRows: 2,
                widths: [60, '*', 160],
                body: conteudo
            }
        }];
    }

    private dadosFornecedor(compra: Compra): {}[] {

        const conteudo = [
            [
                { text: 'FORNECEDOR:', border: [true, false, false, false], bold: true },
                {
                    text: compra.favorecido?.nome?.toUpperCase(),
                    border: [false, false, true, false], colSpan: 4, bold: true
                }, '', '', ''
            ],
            [
                { text: 'CPF/CNPJ:', border: [true, false, false, false] },
                {
                    text: compra.favorecido?.cpf_cnpj,
                    border: [false, false, false, false]
                },
                { text: 'TIPO:', border: [false, false, false, false] },
                {
                    text: compra.favorecido?.tipo?.nome,
                    border: [false, false, true, false], alignment: 'left', colSpan: 2
                }, ''
            ],
            [
                { text: 'ENDEREÇO:', border: [true, false, false, false] },
                { text: compra.favorecido?.endereco?.toUpperCase(), border: [false, false, true, false], colSpan: 4 },
                '', '', ''
            ],
            [
                { text: 'BAIRRO:', border: [true, false, false, false] },
                {
                    columns: [
                        { text: compra.favorecido?.bairro?.toUpperCase(), width: '*' },
                        { text: `CEP:   ${compra.favorecido?.cep}`, width: 80 }
                    ], border: [false, false, false, false]
                },
                { text: 'CÓDIGO:', border: [false, false, false, false] },
                {
                    columns: [
                        { text: compra.favorecido?.id, width: '*' },
                        { text: `FONE:   ${compra.favorecido?.telefone}`, width: 80 }
                    ], border: [false, false, false, false], alignment: 'left'
                },
                { text: '', border: [false, false, true, false] }
            ],
            [
                { text: 'CIDADE:', border: [true, false, false, false] },
                {
                    text: compra.favorecido?.municipio?.toUpperCase(),
                    border: [false, false, false, false]
                },
                { text: 'ESTADO:', border: [false, false, false, false] },
                {
                    columns: [
                        { text: compra.favorecido?.uf, width: '*' },
                        { text: `FAX:`, width: 80 }
                    ], border: [false, false, false, false]
                },
                { text: '', border: [false, false, true, false] }
            ],
            [
                { text: 'E-MAIL:', border: [true, false, false, true] },
                { text: compra.favorecido?.email?.toUpperCase(), border: [false, false, false, true] },
                { text: 'AGÊNCIA:', border: [false, false, false, true] },
                {
                    columns: [
                        { text: '', width: '*' },
                        { text: `C/C: `, width: 80 }
                    ], border: [false, false, false, true]
                },
                { text: '', border: [false, false, true, true] }
            ],

        ];

        return [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                headerRows: 2,
                widths: [60, '*', 40, '*', 10],
                body: conteudo
            }
        }];
    }

    private dadosItens(itens: CompraItem[], itensM: MovimentoItemEstoque[], novaPagina?: boolean): {}[] {
        let conteudo = [];

        conteudo.push([
            { text: 'QUANTIDADE', alignment: 'center', bold: true },
            { text: 'CÓDIGO', alignment: 'center', bold: true },
            { text: 'UND', alignment: 'center', bold: true },
            { text: 'DESCRIÇÃO DO SERVIÇO OU MERCADORIA ', alignment: 'center', bold: true },
            { text: 'ENTREGUE ', alignment: 'center', bold: true },
            { text: 'NÃO ENTREGUE ', alignment: 'center', bold: true },
            { text: 'VL.UNITÁRIO ', alignment: 'center', bold: true },
            { text: 'VALOR', alignment: 'center', bold: true }
        ]);

        let valor_total = 0;
        let nao_entregue = 0;
        let i = this.funcaoService.agrupar(itens, ['produto_unidade.id', 'cota'], ['quantidade'])

        for (let item of i) {
            const entregue = itensM?.find((y) => y.produto_unidade?.id === item?.registros[0].produto_unidade?.id && y.cota === item?.registros[0].cota)
            const itemM = itensM.find((x) => x.produto_unidade?.id === item?.registros[0].produto_unidade?.id && x.cota === item?.registros[0].cota);
            valor_total += +item?.totalizadores['quantidade'] * +item?.registros[0].valor_unitario;
            nao_entregue = +itemM?.qtde;
            let qtde = entregue?.qtde ? +entregue?.qtde : 0

            conteudo.push([
                { text: this.funcaoService.convertToBrNumber(item.totalizadores['quantidade']), alignment: 'right', border: [true, false, true, false] },
                { text: item.registros[0].produto_unidade?.produto?.codigo, alignment: 'center', border: [true, false, true, false] },
                { text: item.registros[0].produto_unidade?.unidade?.nome, alignment: 'center', border: [true, false, true, false] },
                { text: item.registros[0].produto_unidade?.produto?.nome, alignment: 'left', border: [true, false, true, false] },

                { text: this.funcaoService.convertToBrNumber(+item?.totalizadores['quantidade'] - qtde), alignment: 'right', border: [true, false, true, false] },
                { text: this.funcaoService.convertToBrNumber(itemM?.qtde), alignment: 'right', border: [true, false, true, false] },
                { text: this.funcaoService.convertToBrNumber(item?.registros[0].valor_unitario), alignment: 'right', border: [true, false, true, false] },
                { text: this.funcaoService.convertToBrNumber(+item?.totalizadores['quantidade'] * +item?.registros[0].valor_unitario), alignment: 'right', border: [true, false, true, false] }
            ]);
        }

        // let linhas = 51 - conteudo.length;
        // if (linhas > 0) {
        //     conteudo = conteudo.concat(Array.from(Array(linhas).keys()).map(_ =>
        //         Array.from(Array(6).keys()).map((_) => { return { text: '', border: [true, false, true, false] }; })
        //     ));
        // }

        conteudo.push([
            { text: 'TOTAL GERAL', colSpan: 4, bold: true, border: [true, true, false, true] }, '', '', '', '', '',
            { text: 'TOTAL', alignment: 'right', bold: true, border: [false, true, false, true] },
            { text: this.funcaoService.convertToBrNumber(valor_total), alignment: 'right', bold: true, border: [false, true, true, true] }
        ]);

        let dadosItens = {
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                widths: ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'],
                body: conteudo
            }
        };

        if (novaPagina) {
            dadosItens['pageBreak'] = 'after'
        }

        return [dadosItens];
    }

}