import { DatePipe } from '@angular/common';
import { Injector } from '@angular/core';
import { Assinaturas } from 'administrativo-lib';
import { FormatoExportacao, FuncaoService, GlobalService, Login, ParametroContabilService, Relatorio } from 'eddydata-lib';

export class DecendioRpt {

    protected datepipe: DatePipe;
    protected funcaoService: FuncaoService;
    protected globalService: GlobalService;

    private log: Login;
    private nomeTipo: string;

    public assinatura_tesouraria: boolean;

    constructor(
        protected injector: Injector,
        protected parametroContabilidadeServico: ParametroContabilService,
    ) {
        this.funcaoService = new FuncaoService();
        this.globalService = new GlobalService();
        this.datepipe = new DatePipe('pt');
    }

    public async imprimirDescendioRpt(formato: FormatoExportacao, lista: any[], login: Login, tipoDecendio: number, infoExtras: any) {
        this.log = login;

        this.nomeTipo = tipoDecendio === 1 ? 'EDUCAÇÃO' : 'SAÚDE'

        if (formato === 'pdf') {
            Relatorio.imprimirPersonalizado(`REPASSE DESCENDIAL - ${this.nomeTipo}`, login.usuario.nome, login.usuario.sobrenome, 
                login.orgao.nome, login.brasao,
                await this.montarConteudo(lista, infoExtras),
                'landscape', `REPASSE DESCENDIAL - ${this.nomeTipo}`,
                {
                    linhas: {
                        hLineWidth() {
                            return 1;
                        },
                        vLineWidth() {
                            return 1;
                        },
                        hLineColor() {
                            return 'black';
                        },
                        paddingLeft() {
                            return 3;
                        },
                        paddingRight() {
                            return 3;
                        }
                    }
                }, true);
        } else if (formato === 'csv') {
            this.imprimirCSV(lista, `REPASSE DESCENDIAL - ${this.nomeTipo}`);
        }
    }

    private async montarConteudo(lista: any[], infoExtras: any) {
        const conteudo = [];

        conteudo.push(
            this.dadosCabecalho(this.log, infoExtras)
                .concat(await this.dadosDetalhe(lista))  //${this.datepipe.transform(this.dataCaixa, 'dd/MM/yyyy')}
            //.concat(this.dadosAssinatura(this.log))
        );
        return conteudo;
    }

    private dadosCabecalho(log: Login, infoExtras: any): {}[] {
        let brasaoImage: {};
        if (log.brasao) {
            brasaoImage = {
                image: log.brasao,
                width: 70,
                alignment: 'center',
                margin: [0, 30, 0, 0]
            };
        } else {
            brasaoImage = { margin: [0, 30, 0, 0], text: '' };
        }
        const conteudo = [
            [{ text: brasaoImage, border: [false, false, false, false] }],
            [{ text: log.orgao.nome, bold: true, alignment: 'center', fontSize: 13, border: [false, false, false, false] }],
            [{
                text: `REPASSE DESCENDIAL - ${this.nomeTipo}`,
                bold: true, alignment: 'center', fontSize: 12, border: [false, false, false, false]
            }],
            [{
                text: `REFERÊNCIA: ${this.globalService.obterMes(+infoExtras.mes)}/${infoExtras.ano}`,
                alignment: 'left', fontSize: 10, border: [false, false, false, false]
            }],
            [{
                text: this.nomeTipo === 'EDUCAÇÃO' ? `DEMONSTRATIVO DOS REPASSES DECENDIAIS DOS RECURSOS NÃO VINCULADOS AO FMDEFVM, CONFORME DETERMINAÇÃO DO ARTIGO 69, I, II E III DA LEI 9394/96` : 'DEMONSTRATIVO DOS REPASSES DECENDIAIS DOS RECURSOS VINCULADOS A SAÚDE',
                alignment: 'left', fontSize: 8, bold: true, border: [false, false, false, false]
            }],
            [{
                text: this.nomeTipo === 'EDUCAÇÃO' ? `LBD (Inciso IV do Artigo 2º Seção II das Instruções Nº 2 - Resolução Nº 9/98 - DOE de 30/12/98)` : '',
                alignment: 'left', fontSize: 8, bold: true, border: [false, false, false, false]
            }]
        ];

        return [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                widths: ['*'],
                body: conteudo
            }
        }];
    }

    private async dadosDetalhe(lista: any): Promise<{}[]> {
        let conteudo = [];
        conteudo.push([
            { text: 'FICHA', fontSize: 8, alignment: 'center', bold: true, margin: [0, 5, 0, 0] },
            { text: 'ESPECIFICAÇÕES', fontSize: 8, alignment: 'center', bold: true, margin: [0, 5, 0, 0] },
            { text: 'ARRECADAÇÃO DO 1º AO 10º DIA', fontSize: 8, alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
            { text: 'DEPÓSITOS ATÉ O 20º DIA', fontSize: 8, alignment: 'center', bold: true, margin: [0, 5, 0, 0] },
            { text: 'ARRECADAÇÃO DO 11º AO 20º DIA', fontSize: 8, alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
            { text: 'DEPÓSITOS ATÉ O ÚLTIMO DIA DO MÊS', fontSize: 8, alignment: 'center', bold: true, margin: [0, 5, 0, 0] },
            { text: 'ARRECADAÇÃO DO 21º AO ÚLTIMO DIA', fontSize: 8, alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
            { text: 'DEPÓSITOS ATÉ DIA DO MÊS', fontSize: 8, alignment: 'center', bold: true, margin: [0, 5, 0, 0] },
        ]);

        let listaFichas = lista.filter((v, i, a) => +a.findIndex(t => (+t.ficha.id === +v.ficha.id)) === i)

        listaFichas = listaFichas.map(ficha => {
            let grupoFicha = lista.filter(gf => +gf.ficha.id === +ficha.ficha.id);
            ficha['Adez'] = grupoFicha.reduce((acc, interavel) => {
                if (+(new Date(interavel.data_recebimento).getDate()) <= 10) {
                    return +(+(acc + +interavel.valor_recebido).toFixed(2)).toFixed(2);
                } else {
                    return +(acc).toFixed(2)
                }
            }, 0)
            
            ficha['Dvinte'] = 0;
            ficha['Avinte'] = grupoFicha.reduce((acc, interavel) => {
                if (+(new Date(interavel.data_recebimento).getDate()) >= 11 && +(new Date(interavel.data_recebimento).getDate()) <= 20) {
                    return +(+acc.toFixed(2) + +interavel.valor_recebido).toFixed(2);
                } else {
                    return +acc.toFixed(2)
                }
            }, 0)

            ficha['Dmes'] = 0;
            ficha['Ames'] = grupoFicha.reduce((acc, interavel) => {
                if (+(new Date(interavel.data_recebimento).getDate()) >= 21) {
                    return +(+acc.toFixed(2) + +interavel.valor_recebido).toFixed(2);
                } else {
                    return +acc.toFixed(2)
                }
            }, 0)
            ficha['Ddia'] = 0;

            return ficha
        });

        let gruposPercentuais = {
            ensino5: [],
            ensino25: [],
            saude: []
        };

        listaFichas.forEach(item => {
            if (+item.ficha.ensino === 5) {
                gruposPercentuais.ensino5.push(item)
            }
            if (+item.ficha.ensino === 25) {
                gruposPercentuais.ensino25.push(item)
            }
            if (item.ficha.saude) {
                gruposPercentuais.saude.push(item)
            }
        });

        if (this.nomeTipo === 'EDUCAÇÃO') {
            let somaAdez5: number = 0;
            let somaDvinte5: number = 0;
            let somaAvinte5: number = 0;
            let somaDmes5: number = 0;
            let somaAmes5: number = 0;
            let somaDia5: number = 0;
            if (gruposPercentuais.ensino5.length > 0) {
                conteudo.push([
                    { text: 'RECEITAS VINCULADAS AO FUNDO (5%)', fontSize: 8, bold: true, margin: [0, 5, 0, 5], colSpan: 8 },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                ]);

                for (const item of gruposPercentuais.ensino5) {
                    somaAdez5 += +item.Adez;
                    item.Dvinte = +(item.Adez * 0.05).toFixed(2);
                    somaDvinte5 += +item.Dvinte;

                    somaAvinte5 += +item.Avinte;
                    item.Dmes = +(item.Avinte * 0.05).toFixed(2);
                    somaDmes5 += +item.Dmes;

                    somaAmes5 += +item.Ames;
                    item.Ddia = +(item.Ames * 0.05).toFixed(2);
                    somaDia5 += +item.Ddia;
                    conteudo.push([
                        { text: +item.ficha.numero, fontSize: 8, alignment: 'center', border: [true, false, true, false] },
                        { text: `${this.funcaoService.mascarar('####.##.##', item.ficha.receita.codigo.toString().substring(0, 8))} ${item.ficha.receita.nome.toString().substring(0, 70)}`, fontSize: 8, border: [false, false, true, false] },
                        { text: this.funcaoService.convertToBrNumber(item.Adez), alignment: 'right', border: [false, false, true, false] },
                        { text: this.funcaoService.convertToBrNumber((+item.Dvinte)), alignment: 'right', border: [false, false, true, false] },
                        { text: this.funcaoService.convertToBrNumber(+item.Avinte), alignment: 'right', border: [false, false, true, false] },
                        { text: this.funcaoService.convertToBrNumber(+item.Dmes), alignment: 'right', border: [false, false, true, false], },
                        { text: this.funcaoService.convertToBrNumber(item.Ames), alignment: 'right', border: [false, false, true, false] },
                        { text: this.funcaoService.convertToBrNumber((+item.Ddia)), alignment: 'right', border: [false, false, true, false] },
                    ]);
                }
                conteudo.push([
                    { text: 'SOMA', fontSize: 8, bold: true, margin: [0, 5, 0, 5], colSpan: 2 },
                    { text: '' },
                    { text: this.funcaoService.convertToBrNumber(somaAdez5), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                    { text: this.funcaoService.convertToBrNumber(somaDvinte5), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                    { text: this.funcaoService.convertToBrNumber(somaAvinte5), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                    { text: this.funcaoService.convertToBrNumber(somaDmes5), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                    { text: this.funcaoService.convertToBrNumber(somaAmes5), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                    { text: this.funcaoService.convertToBrNumber(somaDia5), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                ]);
            }
            let somaAdez25: number = 0;
            let somaDvinte25: number = 0;
            let somaAvinte25: number = 0;
            let somaDmes25: number = 0;
            let somaAmes25: number = 0;
            let somaDia25: number = 0;
            if (gruposPercentuais.ensino25.length > 0) {
                conteudo.push([
                    { text: 'RECEITAS NAO VINCULADAS AO FUNDO (25%)', fontSize: 8, bold: true, margin: [0, 5, 0, 5], colSpan: 8 },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                ]);
                for (const item of gruposPercentuais.ensino25) {
                    somaAdez25 += +item.Adez;
                    item.Dvinte = +(item.Adez * 0.25).toFixed(2);
                    somaDvinte25 += +item.Dvinte;

                    somaAvinte25 += +item.Avinte;
                    item.Dmes = +(item.Avinte * 0.25).toFixed(2);
                    somaDmes25 += +item.Dmes;

                    somaAmes25 += +item.Ames;
                    item.Ddia = +(item.Ames * 0.25).toFixed(2);
                    somaDia25 += +item.Ddia;
                    conteudo.push([
                        { text: +item.ficha.numero, fontSize: 8, alignment: 'center', border: [true, false, true, false] },
                        { text: `${this.funcaoService.mascarar('####.##.##', item.ficha.receita.codigo.toString().substring(0, 8))} ${item.ficha.receita.nome.toString().substring(0, 70)}`, fontSize: 8, border: [false, false, true, false] },
                        { text: this.funcaoService.convertToBrNumber(item.Adez), alignment: 'right', border: [false, false, true, false] },
                        { text: this.funcaoService.convertToBrNumber(item.Dvinte), alignment: 'right', border: [false, false, true, false] },
                        { text: this.funcaoService.convertToBrNumber(item.Avinte), alignment: 'right', border: [false, false, true, false] },
                        { text: this.funcaoService.convertToBrNumber(item.Dmes), alignment: 'right', border: [false, false, true, false] },
                        { text: this.funcaoService.convertToBrNumber(item.Ames), alignment: 'right', border: [false, false, true, false] },
                        { text: this.funcaoService.convertToBrNumber(item.Ddia), alignment: 'right', border: [false, false, true, false] },
                    ]);
                }
                conteudo.push([
                    { text: 'SOMA', fontSize: 8, bold: true, margin: [0, 5, 0, 5], colSpan: 2 },
                    { text: '' },
                    { text: this.funcaoService.convertToBrNumber(somaAdez25), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                    { text: this.funcaoService.convertToBrNumber(somaDvinte25), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                    { text: this.funcaoService.convertToBrNumber(somaAvinte25), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                    { text: this.funcaoService.convertToBrNumber(somaDmes25), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                    { text: this.funcaoService.convertToBrNumber(somaAmes25), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                    { text: this.funcaoService.convertToBrNumber(somaDia25), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                ]);
            }
            let totalAdez: number = 0;
            let totalDvinte: number = 0;
            let totalAvinte: number = 0;
            let totalDmes: number = 0;
            let totalAmes: number = 0;
            let totalDia: number = 0;

            totalAdez = +somaAdez5 + +somaAdez25;
            totalDvinte = +somaDvinte5 + +somaDvinte25;
            totalAvinte = +somaAvinte5 + +somaAvinte25;
            totalDmes = +somaDmes5 + +somaDmes25;
            totalAmes = +somaAmes5 + +somaAmes25;
            totalDia = +somaDia5 + +somaDia25;

            conteudo.push([
                { text: 'TOTAL GERAL', fontSize: 8, bold: true, margin: [0, 5, 0, 5], colSpan: 2 },
                { text: '' },
                { text: this.funcaoService.convertToBrNumber(totalAdez), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                { text: this.funcaoService.convertToBrNumber(totalDvinte), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                { text: this.funcaoService.convertToBrNumber(totalAvinte), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                { text: this.funcaoService.convertToBrNumber(totalDmes), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                { text: this.funcaoService.convertToBrNumber(totalAmes), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                { text: this.funcaoService.convertToBrNumber(totalDia), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
            ]);
        }

        if (this.nomeTipo === 'SAÚDE') {
            let somaAdez: number = 0;
            let somaDvinte: number = 0;
            let somaAvinte: number = 0;
            let somaDmes: number = 0;
            let somaAmes: number = 0;
            let somaDia: number = 0;
            if (gruposPercentuais.saude.length > 0) {
                conteudo.push([
                    { text: 'RECEITAS VINCULADAS AO FUNDO (15%)', fontSize: 8, bold: true, margin: [0, 5, 0, 5], colSpan: 8 },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                ]);

                for (const item of gruposPercentuais.saude) {
                    somaAdez += +item.Adez;
                    item.Dvinte = +(item.Adez * 0.15).toFixed(2);
                    somaDvinte += +item.Dvinte;

                    somaAvinte += +item.Avinte;
                    item.Dmes = +(item.Avinte * 0.15).toFixed(2);
                    somaDmes += +item.Dmes;

                    somaAmes += +item.Ames;
                    item.Ddia = +(item.Ames * 0.15).toFixed(2);
                    somaDia += +item.Ddia
                    conteudo.push([
                        { text: +item.ficha.numero, fontSize: 8, alignment: 'center', border: [true, false, true, false] },
                        { text: `${this.funcaoService.mascarar('####.##.##', item.ficha.receita.codigo.toString().substring(0, 8))} ${item.ficha.receita.nome.toString().substring(0, 70)}`, fontSize: 8, border: [false, false, true, false] },
                        { text: this.funcaoService.convertToBrNumber(item.Adez), alignment: 'right', border: [false, false, true, false] },
                        { text: this.funcaoService.convertToBrNumber(item.Dvinte), alignment: 'right', border: [false, false, true, false] },
                        { text: this.funcaoService.convertToBrNumber(item.Avinte), alignment: 'right', border: [false, false, true, false] },
                        { text: this.funcaoService.convertToBrNumber(item.Dmes), alignment: 'right', border: [false, false, true, false] },
                        { text: this.funcaoService.convertToBrNumber(item.Ames), alignment: 'right', border: [false, false, true, false] },
                        { text: this.funcaoService.convertToBrNumber(item.Ddia), alignment: 'right', border: [false, false, true, false] },
                    ]);
                }
                conteudo.push([
                    { text: 'SOMA', fontSize: 8, bold: true, margin: [0, 5, 0, 5], colSpan: 2 },
                    { text: '' },
                    { text: this.funcaoService.convertToBrNumber(somaAdez), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                    { text: this.funcaoService.convertToBrNumber(somaDvinte), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                    { text: this.funcaoService.convertToBrNumber(somaAvinte), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                    { text: this.funcaoService.convertToBrNumber(somaDmes), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                    { text: this.funcaoService.convertToBrNumber(somaAmes), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                    { text: this.funcaoService.convertToBrNumber(somaDia), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                ]);
            }
            conteudo.push([
                { text: 'TOTAL GERAL', fontSize: 8, bold: true, margin: [0, 5, 0, 5], colSpan: 2 },
                { text: '' },
                { text: this.funcaoService.convertToBrNumber(somaAdez), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                { text: this.funcaoService.convertToBrNumber(somaDvinte), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                { text: this.funcaoService.convertToBrNumber(somaAvinte), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                { text: this.funcaoService.convertToBrNumber(somaDmes), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                { text: this.funcaoService.convertToBrNumber(somaAmes), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
                { text: this.funcaoService.convertToBrNumber(somaDia), alignment: 'right', fontSize: 8, bold: true, margin: [0, 5, 0, 5], },
            ]);
        }

        this.assinatura_tesouraria = (await this.parametroContabilidadeServico.obter({ orgao_id: this.log.orgao.id }).toPromise()).assinatura_tesouraria;

        const ass = new Assinaturas(this.log.orgao, this.injector);
        const assinaturas = await ass.dadosAssinatura(undefined, true, null, false, false, this.assinatura_tesouraria);

        return this.assinatura_tesouraria ? [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                headerRows: 1,
                widths: [30, '*', 60, 60, 60, 60, 60, 60],
                body: conteudo
            }
        }, {
            layout: 'linhas',
            table: {
              dontBreakRows: true,
              headerRows: 0,
              widths: ['*', '*', '*'],
              body: assinaturas
            }
          }] : [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                headerRows: 1,
                widths: [30, '*', 60, 60, 60, 60, 60, 60],
                body: conteudo
            }
        }]
    }

    private imprimirCSV(lista: any[], titulo: string) {
        let conteudo: {}[][] = [];
        conteudo.push([
            { text: 'FICHA' },
            { text: 'ESPECIFICAÇÕES' },
            { text: 'ARRECADAÇÃO DO 1º AO 10º DIA' },
            { text: 'DEPÓSITOS ATÉ O 20º DIA' },
            { text: 'ARRECADAÇÃO DO 11º AO 20º DIA' },
            { text: 'DEPÓSITOS ATÉ O ÚLTIMO DIA DO MÊS' },
            { text: 'ARRECADAÇÃO DO 21º AO ÚLTIMO DIA' },
            { text: 'DEPÓSITOS ATÉ DIA DO MÊS' },
        ]);

        let listaFichas = lista.filter((v, i, a) => +a.findIndex(t => (+t.ficha.id === +v.ficha.id)) === i);
        listaFichas = listaFichas.map(ficha => {
            let grupoFicha = lista.filter(gf => +gf.ficha.id === +ficha.ficha.id);
            ficha['Adez'] = grupoFicha.reduce((acc, interavel) => {
                if (+(new Date(interavel.data_recebimento).getDate()) <= 10) {
                    return +(+(acc + +interavel.valor_recebido).toFixed(2)).toFixed(2);
                } else {
                    return +(acc).toFixed(2);
                }
            }, 0);

            ficha['Dvinte'] = 0;
            ficha['Avinte'] = grupoFicha.reduce((acc, interavel) => {
                if (+(new Date(interavel.data_recebimento).getDate()) >= 11 && +(new Date(interavel.data_recebimento).getDate()) <= 20) {
                    return +(+acc.toFixed(2) + +interavel.valor_recebido).toFixed(2);
                } else {
                    return +acc.toFixed(2);
                }
            }, 0);

            ficha['Dmes'] = 0;
            ficha['Ames'] = grupoFicha.reduce((acc, interavel) => {
                if (+(new Date(interavel.data_recebimento).getDate()) >= 21) {
                    return +(+acc.toFixed(2) + +interavel.valor_recebido).toFixed(2);
                } else {
                    return +acc.toFixed(2);
                }
            }, 0);
            ficha['Ddia'] = 0;

            return ficha;
        });

        let gruposPercentuais = {
            ensino5: [],
            ensino25: [],
            saude: [],
        };

        listaFichas.forEach(item => {
            if (+item.ficha.ensino === 5) {
                gruposPercentuais.ensino5.push(item);
            }
            if (+item.ficha.ensino === 25) {
                gruposPercentuais.ensino25.push(item);
            }
            if (item.ficha.saude) {
                gruposPercentuais.saude.push(item);
            }
        });

        if (this.nomeTipo === 'EDUCAÇÃO') {
            let somaAdez5: number = 0;
            let somaDvinte5: number = 0;
            let somaAvinte5: number = 0;
            let somaDmes5: number = 0;
            let somaAmes5: number = 0;
            let somaDia5: number = 0;
            if (gruposPercentuais.ensino5.length > 0) {
                conteudo.push([{ text: 'RECEITAS VINCULADAS AO FUNDO (5%)' }]);
                for (const item of gruposPercentuais.ensino5) {
                    somaAdez5 += +item.Adez;
                    item.Dvinte = +(item.Adez * 0.05).toFixed(2);
                    somaDvinte5 += +item.Dvinte;

                    somaAvinte5 += +item.Avinte;
                    item.Dmes = +(item.Avinte * 0.05).toFixed(2);
                    somaDmes5 += +item.Dmes;

                    somaAmes5 += +item.Ames;
                    item.Ddia = +(item.Ames * 0.05).toFixed(2);
                    somaDia5 += +item.Ddia;
                    conteudo.push([
                        { text: +item.ficha.numero },
                        { text: `${this.funcaoService.mascarar('####.##.##', item.ficha.receita.codigo.toString().substring(0, 8))} ${item.ficha.receita.nome.toString().substring(0, 70)}` },
                        { text: this.funcaoService.convertToBrNumber(item.Adez) },
                        { text: this.funcaoService.convertToBrNumber((+item.Dvinte)) },
                        { text: this.funcaoService.convertToBrNumber(+item.Avinte) },
                        { text: this.funcaoService.convertToBrNumber(+item.Dmes), },
                        { text: this.funcaoService.convertToBrNumber(item.Ames) },
                        { text: this.funcaoService.convertToBrNumber((+item.Ddia)) },
                    ]);
                }
                conteudo.push([
                    { text: 'SOMA' }, { text: '' },
                    { text: this.funcaoService.convertToBrNumber(somaAdez5) },
                    { text: this.funcaoService.convertToBrNumber(somaDvinte5) },
                    { text: this.funcaoService.convertToBrNumber(somaAvinte5) },
                    { text: this.funcaoService.convertToBrNumber(somaDmes5) },
                    { text: this.funcaoService.convertToBrNumber(somaAmes5) },
                    { text: this.funcaoService.convertToBrNumber(somaDia5) },
                ]);
            }
            let somaAdez25: number = 0;
            let somaDvinte25: number = 0;
            let somaAvinte25: number = 0;
            let somaDmes25: number = 0;
            let somaAmes25: number = 0;
            let somaDia25: number = 0;
            if (gruposPercentuais.ensino25.length > 0) {
                conteudo.push([{ text: 'RECEITAS NAO VINCULADAS AO FUNDO (25%)' }]);
                for (const item of gruposPercentuais.ensino25) {
                    somaAdez25 += +item.Adez;
                    item.Dvinte = +(item.Adez * 0.25).toFixed(2);
                    somaDvinte25 += +item.Dvinte;

                    somaAvinte25 += +item.Avinte;
                    item.Dmes = +(item.Avinte * 0.25).toFixed(2);
                    somaDmes25 += +item.Dmes;

                    somaAmes25 += +item.Ames;
                    item.Ddia = +(item.Ames * 0.25).toFixed(2);
                    somaDia25 += +item.Ddia;
                    conteudo.push([
                        { text: +item.ficha.numero },
                        { text: `${this.funcaoService.mascarar('####.##.##', item.ficha.receita.codigo.toString().substring(0, 8))} ${item.ficha.receita.nome.toString().substring(0, 70)}` },
                        { text: this.funcaoService.convertToBrNumber(item.Adez) },
                        { text: this.funcaoService.convertToBrNumber(item.Dvinte) },
                        { text: this.funcaoService.convertToBrNumber(item.Avinte) },
                        { text: this.funcaoService.convertToBrNumber(item.Dmes) },
                        { text: this.funcaoService.convertToBrNumber(item.Ames) },
                        { text: this.funcaoService.convertToBrNumber(item.Ddia) },
                    ]);
                }
                conteudo.push([
                    { text: 'SOMA' }, { text: '' },
                    { text: this.funcaoService.convertToBrNumber(somaAdez25) },
                    { text: this.funcaoService.convertToBrNumber(somaDvinte25) },
                    { text: this.funcaoService.convertToBrNumber(somaAvinte25) },
                    { text: this.funcaoService.convertToBrNumber(somaDmes25) },
                    { text: this.funcaoService.convertToBrNumber(somaAmes25) },
                    { text: this.funcaoService.convertToBrNumber(somaDia25) },
                ]);
            }
            let totalAdez: number = 0;
            let totalDvinte: number = 0;
            let totalAvinte: number = 0;
            let totalDmes: number = 0;
            let totalAmes: number = 0;
            let totalDia: number = 0;

            totalAdez = +somaAdez5 + +somaAdez25;
            totalDvinte = +somaDvinte5 + +somaDvinte25;
            totalAvinte = +somaAvinte5 + +somaAvinte25;
            totalDmes = +somaDmes5 + +somaDmes25;
            totalAmes = +somaAmes5 + +somaAmes25;
            totalDia = +somaDia5 + +somaDia25;

            conteudo.push([
                { text: 'TOTAL GERAL' }, { text: '' },
                { text: this.funcaoService.convertToBrNumber(totalAdez) },
                { text: this.funcaoService.convertToBrNumber(totalDvinte) },
                { text: this.funcaoService.convertToBrNumber(totalAvinte) },
                { text: this.funcaoService.convertToBrNumber(totalDmes) },
                { text: this.funcaoService.convertToBrNumber(totalAmes) },
                { text: this.funcaoService.convertToBrNumber(totalDia) },
            ]);
        }

        if (this.nomeTipo === 'SAÚDE') {
            let somaAdez: number = 0;
            let somaDvinte: number = 0;
            let somaAvinte: number = 0;
            let somaDmes: number = 0;
            let somaAmes: number = 0;
            let somaDia: number = 0;
            if (gruposPercentuais.saude.length > 0) {
                conteudo.push([{ text: 'RECEITAS VINCULADAS AO FUNDO (15%)' }]);

                for (const item of gruposPercentuais.saude) {
                    somaAdez += +item.Adez;
                    item.Dvinte = +(item.Adez * 0.15).toFixed(2);
                    somaDvinte += +item.Dvinte;

                    somaAvinte += +item.Avinte;
                    item.Dmes = +(item.Avinte * 0.15).toFixed(2);
                    somaDmes += +item.Dmes;

                    somaAmes += +item.Ames;
                    item.Ddia = +(item.Ames * 0.15).toFixed(2);
                    somaDia += +item.Ddia
                    conteudo.push([
                        { text: +item.ficha.numero },
                        { text: `${this.funcaoService.mascarar('####.##.##', item.ficha.receita.codigo.toString().substring(0, 8))} ${item.ficha.receita.nome.toString().substring(0, 70)}` },
                        { text: this.funcaoService.convertToBrNumber(item.Adez) },
                        { text: this.funcaoService.convertToBrNumber(item.Dvinte) },
                        { text: this.funcaoService.convertToBrNumber(item.Avinte) },
                        { text: this.funcaoService.convertToBrNumber(item.Dmes) },
                        { text: this.funcaoService.convertToBrNumber(item.Ames) },
                        { text: this.funcaoService.convertToBrNumber(item.Ddia) },
                    ]);
                }
                conteudo.push([
                    { text: 'SOMA' }, { text: '' },
                    { text: this.funcaoService.convertToBrNumber(somaAdez) },
                    { text: this.funcaoService.convertToBrNumber(somaDvinte) },
                    { text: this.funcaoService.convertToBrNumber(somaAvinte) },
                    { text: this.funcaoService.convertToBrNumber(somaDmes) },
                    { text: this.funcaoService.convertToBrNumber(somaAmes) },
                    { text: this.funcaoService.convertToBrNumber(somaDia) },
                ]);
            }
            conteudo.push([
                { text: 'TOTAL GERAL' }, { text: '' },
                { text: this.funcaoService.convertToBrNumber(somaAdez) },
                { text: this.funcaoService.convertToBrNumber(somaDvinte) },
                { text: this.funcaoService.convertToBrNumber(somaAvinte) },
                { text: this.funcaoService.convertToBrNumber(somaDmes) },
                { text: this.funcaoService.convertToBrNumber(somaAmes) },
                { text: this.funcaoService.convertToBrNumber(somaDia) },
            ]);
        }

        let csv = '';
        for (let i=0; i<conteudo.length; i++) {
            const linha = conteudo[i];
            if (i > 0) csv += '\n';
            for (let x=0; x<linha.length; x++) {
                if (x > 0) csv += ';';
                csv += String(linha[x]['text']);
            }
        }
        conteudo = null;
        const element = document.createElement("a");
        element.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + csv));
        element.setAttribute("download", titulo);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

}




