import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Convenio, Login, ConvenioAditamento, GlobalService, DateFormatPipe, FuncaoService } from 'eddydata-lib';
import { ConvenioAditamentoService } from '../service/convenio-aditamento.service';

declare var $: any;

@Component({
  selector: 'lib-convenio-aditamento',
  templateUrl: './convenio-aditamento.component.html'
})
export class ConvenioAditamentoComponent implements OnInit, OnChanges, OnDestroy {

  public ptBR: any;
  public entity: ConvenioAditamento;
  public listaAditamentos: Array<ConvenioAditamento>;
  public listaFinalidade: Array<any>;

  protected unsubscribe: Subject<void> = new Subject();

  @Input() lista: Array<any>;
  @Input() entidade: Convenio;
  @Input() login: Login;

  @ViewChild('btnAdicionar') public btnAdicionar: ElementRef;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected aditamentoService: ConvenioAditamentoService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.listaAditamentos = this.lista.sort((a, b) => +a.ordem - +b.ordem);

    this.listaFinalidade = [
      { nome: 'AUMENTO', id: '2' },
      { nome: 'SUPRESSÃO', id: '1' },
      { nome: 'VIGÊNCIA', id: '3' },
      { nome: 'OUTROS', id: '4' }
    ];

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lista) {
      this.listaAditamentos = this.lista.sort((a, b) => +a.ordem - +b.ordem);
    }
  }

  public obterFinalidade(item: any) {
    switch (item) {
      case '2':
        return 'AUMENTO';
      case '1':
        return 'SUPRESSÃO';
      case '3':
        return 'VIGÊNCIA';
      case '4':
        return 'OUTROS';
      default:
        return '-';
    }
  }

  public inserir() {
    this.entity = new ConvenioAditamento();
    this.entity.data_aditamento = this.funcaoService.obterDataUTC(new Date());
    this.entity.data_contabilizacao = this.funcaoService.obterDataUTC(new Date());
    this.entity.data_inicio = this.funcaoService.obterDataUTC(new Date());
    this.entity.convenio = this.entidade;
    this.entity.usuario = this.login.usuario;
    this.entity.editavel = true;
    $('#dialogAditamento').modal('show');
  }

  public editar(item: ConvenioAditamento) {
    this.entity = item;
    item.data_aditamento = new DateFormatPipe().transform(item.data_aditamento, []);
    item.data_inicio = new DateFormatPipe().transform(item.data_inicio, []);
    item.data_termino = new DateFormatPipe().transform(item.data_termino, []);
    item.data_contabilizacao = new DateFormatPipe().transform(item.data_contabilizacao, []);
    $('#dialogAditamento').modal('show');
  }

  public confirmarRemocao(item: ConvenioAditamento, index: number) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja remover item?',
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.remover(item, index)
      },
    });
  }

  private remover(item: ConvenioAditamento, index: number) {
    if (item.id) {
      this.aditamentoService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaAditamentos.splice(index, 1);
          this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Exclusão', detail: error.error.payload });
          throw error;
        });
    } else {
      this.listaAditamentos.splice(index, 1);
    }
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public podeAlterarAudesp(data: Date): boolean {
    if (!data) {
      return true;
    }
    if (this.login['ultimoAudesp'] && data) {
      data = new Date(data);
      const mes = +this.login['ultimoAudesp'].split('-')[1];
      const ano = +this.login['ultimoAudesp'].split('-')[0];
      if ((data.getFullYear() < ano || (data.getFullYear() === ano && (+data.getMonth() + 1) <= mes)) && mes < 12)
        return false;
    }
    if (this.login['dias_bloquear_alteracoes']) {
      return new FuncaoService().diferencaEmDias(new Date(), new Date(data)) < this.login['dias_bloquear_alteracoes'];
    } else {
      return true;
    }
  }

}
