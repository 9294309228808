// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Convenio } from '../compra/convenio.model';
import { FavorecidoBanco } from '../compra/favorecido-banco.model';

export class PrestacaoConta extends BaseResourceModel {

  constructor(
    public id?: number,
    public mes?: number,
    public ano?: number,
    public data_documento?: Date,
    public beneficiario?: string,
    public especificacao?: string,
    public tipo_pessoa?: string,
    public cpf_cnpj?: string,
    public tipo_despesa?: string,
    public situacao?: string,
    public numero?: string,
    public justificativa?: string,
    public data_pagamento?: Date,
    public valor_prestacao?: number,
    public valor_juros?: number,
    public valor_desconto?: number,
    public valor_liquido?: number,
    public convenio?: Convenio,
    public favorecido_banco?: FavorecidoBanco,
    public editavel?: boolean
  ) {
    super();
  }
  static converteJson(json: any): PrestacaoConta {
    return Object.assign(new PrestacaoConta(), json);
  }
}
