import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FuncaoService } from '../../util/funcao.service';

@Component({
  selector: 'lib-cronometro',
  templateUrl: './cronometro.component.html'
})
export class CronometroComponent implements OnChanges {

  @Input() ms: boolean = true;

  public cron: NodeJS.Timer;
  public hour: number = 0;
  public minute: number = 0;
  public second: number = 0;
  public millisecond: number = 0;
  public active: boolean = false;

  constructor(private funcaoService: FuncaoService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['ms'] && this.cron) {
      this.millisecond = 0;
      this.second++;
      this.start();
    }
  }

  set() {
    const h = +(document.getElementById('txtCronH') as HTMLInputElement).value;
    const m = +(document.getElementById('txtCronM') as HTMLInputElement).value;
    const s = +(document.getElementById('txtCronS') as HTMLInputElement).value;
    const ms = +(document.getElementById('txtCronMS') as HTMLInputElement).value;

    this.hour = h ? h : 0;
    this.minute = m ? m : 0;
    this.second = s ? s : 0;
    this.millisecond = ms ? ms : 0;
  }

  start() {
    this.pause();
    this.cron = setInterval(() => { this.timer(); }, (this.ms ? 10 : 1000));
    this.active = true;
  }

  pause() {
    clearInterval(this.cron);
    this.active = false;
  }

  reset() {
    this.hour = 0;
    this.minute = 0;
    this.second = 0;
    this.millisecond = 0;
  }

  timer() {
    if ((this.millisecond += (this.ms ? 10 : 1000)) == 1000) {
      this.millisecond = 0;
      this.second++;
    }
    if (this.second == 60) {
      this.second = 0;
      this.minute++;
    }
    if (this.minute == 60) {
      this.minute = 0;
      this.hour++;
    }
  }

  cronn(): string {
    let retorno = `${this.funcaoService.strZero(this.hour, 2)}:${this.funcaoService.strZero(this.minute, 2)}:${this.funcaoService.strZero(this.second, 2)}`
    if (this.ms) retorno += `:${this.funcaoService.strZero(this.millisecond, 3)}`;
    return retorno;
  }
}