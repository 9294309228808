// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido } from '../compra/favorecido.model';
import { RplCotacao } from './rpl-cotacao.model';
import { Rpl } from './rpl.model';

export class RplFavorecido extends BaseResourceModel {
  constructor(
    public id?: number,
    public favorecido?: Favorecido,
    public rpl?: Rpl,
    public cotacoes?: RplCotacao[],
  ) {
    super();
  }

  static converteJson(json: any): RplFavorecido {
    return Object.assign(new RplFavorecido(), json);
  }
}
