import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ProgressoState } from './progresso';
import { ProgressoService } from './service/progresso.service';
import * as toastr from 'toastr';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'lib-progresso',
  templateUrl: './progresso.component.html',
  styleUrls: ['./progresso.component.css']
})
export class ProgressoComponent implements OnInit, OnDestroy {
  show = false;
  id = -1;
  funcao: Function = undefined;
  porcentagem = '100%';
  mensagem = 'Aguarde...';
  atual = 0;
  total = 0;
  protected unsubscribe: Subject<void> = new Subject();
  protected fimInterval: Subject<void> = new Subject();

  constructor(private progressoService: ProgressoService) { }
  ngOnInit() {
    this.progressoService.progressoState
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((state: ProgressoState) => {
        this.show = state.show;
        this.id = state.id;
        this.funcao = state.funcao;
        this.andamento();
      });

  }
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  async andamento(erros?: number) {
    // await this.sleep(1000);
    this.fimInterval = new Subject();
    if (this.id) {
      try {
        const res = await this.progressoService.progresso(this.id).pipe(takeUntil(this.fimInterval)).toPromise();
        if (res) {
          if (res['situacao'] === 'finalizado') {
            this.finalizar(res['retorno']);
            toastr.success('Solicitação processada com sucesso!');
            return;
          } else if (res['situacao'] === 'erro') {
            this.finalizar(undefined);
            toastr.error(res['retorno'] ? res['retorno'] : 'Erro ao processar solicitação!');
            return;
          } else if (res['situacao'] === 'abortado') {
            this.finalizar(undefined);
            toastr.warning('Solicitação abortada pelo usuário!');
            return;
          }
          if (res && res['texto']) {
            this.mensagem = res['texto'];
            this.atual = res['atual'];
            this.total = res['total'];
          }
          this.porcentagem = (+res['total'] === 0 ? '100' : ((+res['atual'] / +res['total']) * 100).toFixed(0)) + '%';
          await this.sleep(1000);
          this.andamento();
        } else {
          await this.sleep(1000);
          this.andamento();
        }
      } catch (err) {
        this.abortar();
        toastr.error('Erro ao processar solicitação!');
        this.finalizar(undefined);
        return;
      }
      /*interval(1000).pipe(takeUntil(this.fimInterval)).pipe(exhaustMap(() => this.progressoService.progresso(this.id)))
        .subscribe(res => {
          if (res) {
            if (res['situacao'] === 'finalizado') {
              this.finalizar(res['retorno']);
              toastr.success('Solicitação processada com sucesso!');
              return;
            } else if (res['situacao'] === 'erro') {
              this.finalizar(undefined);
              toastr.error(res['retorno'] ? res['retorno'] : 'Erro ao processar solicitação!');
              return;
            } else if (res['situacao'] === 'abortado') {
              this.finalizar(undefined);
              toastr.warning('Solicitação abortada pelo usuário!');
              return;
            }
            if (res && res['texto']) {
              this.mensagem = res['texto'];
              this.atual = res['atual'];
              this.total = res['total'];
            }
            this.porcentagem = (+res['total'] === 0 ? '100' : ((+res['atual'] / +res['total']) * 100).toFixed(0)) + '%';
          }
          // this.andamento();
        },
          (err) => {
            this.abortar();
            toastr.error('Erro ao processar solicitação!');
            this.finalizar(undefined);
            return;
          });*/
    }
  }

  finalizar(retorno: any) {
    this.porcentagem = '100%';
    this.mensagem = 'Aguarde...';
    this.atual = 0;
    this.total = 0;
    this.fimInterval.next();
    this.fimInterval.complete();
    if (this.funcao && retorno) {
      this.funcao.apply(this.funcao, [retorno]);
    }
    this.progressoService.hide();
  }

  abortar() {
    this.progressoService.abortar(this.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
      });
  }

  sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
}
