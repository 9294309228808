import { Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FichaDespesaService } from 'contabil-lib';
import { BaseResourceListComponent, Coluna, Contrato, EddyAutoComplete, FichaDespesa, Filtro, FormatoExportacao, FuncaoService, GlobalService, LicitacaoPipe, LoginContabil, Rcms, RcmsItem } from 'eddydata-lib';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RcmsItemService } from '../../rcms/service/rcms-item.service';
import { RcmsService } from '../../rcms/service/rcms.service';

@Component({
  selector: 'app-rcms-aberto-list-dlg',
  templateUrl: './rcms-aberto-list-dlg.component.html'
})
export class RcmsAbertoListDlgComponent extends BaseResourceListComponent<Rcms, LoginContabil> implements OnChanges {

  /**
   * Declaração de variáveis
   */


  public fichaAutoComplete: EddyAutoComplete<FichaDespesa>;

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public itens: RcmsItem[] = [];
  public carregando: boolean = false;

  public ptBR: any;

  public rcms: Rcms = new Rcms();


  public listMeses: { id: string, nome: string }[];
  public mes: { id: string, nome: string } = undefined;

  public ficha: FichaDespesa;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public funcaoService: FuncaoService,
    public globalService: GlobalService,
    private rcmsService: RcmsService,
    protected fichaService: FichaDespesaService,
    private rcmsItemService: RcmsItemService) {
    super(rcmsService, injector);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visualizar && this.visualizar)
      super.ngOnInit();
  }

  public ngOnInit(): void { }


  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================


  protected relations(): string {
    return ['setor', 'licitacao', 'contrato', 'exercicio', 'orgao', 'ficha',
      'ficha.despesa', 'subelemento', 'requerente', 'modalidade', 'prazo', 'compras'].join(',');
  }

  protected condicoesGrid(): {} {
    let parametros = {
      ['orgao.id']: this.login.orgao.id,
      ['exercicio.id']: this.login.exercicio.id,
      //['OR']: `compras.id$null;!;!;compras.excluido=true`,
      ['count_compra']: 0,
      //['compras.id$null']: true,
      ['cotacao_processo']: false,
      excluido: false
    };
    if (this.ficha)
      parametros['ficha.numero'] = this.ficha.numero;
    if (this.mes)
      parametros['data_rcms$extract_m'] = this.mes.id;
    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['data_rcms$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['numero'],
      date: ['data_rcms'],
      text: ['requerente.nome', 'requerente', 'ficha.despesa.nome', 'contrato.numero', 'processo', 'licitacao.numero', 'modalidade.nome', 'setor.nome'],
    };
  }

  public beforeInit(): void {
    this.usarExtendido = true;
  }

  protected afterInit(): void {
    this.ptBR = new GlobalService().obterDataBR();
    this.listMeses = this.globalService.obterListaMeses();
    this.carregarAutoComplete();
  }

  protected acaoRemover(model: Rcms): Observable<Rcms> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Número', coluna: 'numero' },
      { titulo: 'Data', coluna: 'data_rcms' },
      { titulo: 'Contrato', coluna: 'contrato.numero', mask: '0000/0000' },
      { titulo: 'Processo', coluna: 'processo', mask: '0000/0000' },
      { titulo: 'Licitação', coluna: 'licitacao.numero', mask: '00000/0000' },
      { titulo: 'Despesa', coluna: 'ficha.despesa.nome' },
      { titulo: 'Setor', coluna: 'setor.nome' },
      { titulo: 'Requerente', coluna: 'requerente.nome' },
      { titulo: 'Men. Preço Global', coluna: 'menor_preco_global', decimais: 2, alignment: 'right' }
    ];
  }
  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public carregarAutoComplete() {
    this.fichaAutoComplete = new EddyAutoComplete(null, this.fichaService,
      'numero', ['ficha.numero', 'ficha.despesa.nome'],
      { 'exercicio.id': this.login.exercicio.id, 'orgao.id': this.login.orgao.id, relations: 'despesa', orderBy: 'despesa.nome' },
      { number: ['numero'], text: ['despesa.nome'] });

  }


  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
    super.ngOnInit();
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public valorTotal() {
    if (!this.lista || this.lista.length === 0)
      return 0.0;
    let valor_total = 0;
    for (let item of this.lista) {
      valor_total += +item['valor_total'];
    }
    return valor_total;
  }

  public carregarItens(item: Contrato, op: OverlayPanel, event) {
    if (this.carregando)
      return;
    op.toggle(event);
    this.carregando = true;
    if (!item['carregado'])
      this.rcmsItemService.filtrar(1, -1, {
        'rcms.id': item.id,
        relations: 'produto_unidade.produto,produto_unidade.unidade',
        orderBy: 'produto_unidade.produto.nome$ASC'
      }).subscribe((data) => {
        if (data && data.content && data.content.length) {
          item.itens = data.content;
          this.itens = data.content;
        }
        this.carregando = false;
        item['carregado'] = true;
      }, (error) => toastr.error(error.error.payload));
    else {
      this.itens = item.itens;
      this.carregando = false;
    }
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = this.relations();
    parametros['orderBy'] = this.ordenacaoGrid().join(',');
    this.rcmsService.extendido(1, -1, parametros).pipe(takeUntil(this.unsubscribe)).subscribe(
      lista => {
        if (formato === 'pdf') {
          this.imprimir('REQUISIÇÕES EM ABERTO',
            this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
            'Listagem rcms', ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], lista.content.map((requisicao) => {
              if (requisicao.licitacao?.numero)
                requisicao.licitacao.numero = new LicitacaoPipe().transform(requisicao.licitacao.numero);
              if (requisicao.contrato?.numero)
                requisicao.contrato.numero = new LicitacaoPipe().transform(requisicao.contrato.numero);
              return requisicao;
            }));
        } else {
          this.exportar(formato, lista.content);
        }
      },
      (error) => toastr.error(error.error.payload)
    );
  }

}

