import { Component, Injector } from '@angular/core';
import { BaseResourceListComponent, Coluna, ExercicioService, Filtro, FormatoExportacao, FuncaoService, LoginContabil, ParametroRequisicao, PlanoContratacaoAnual } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { PlanoContratacaoAnualService } from '../service/plano-contratacao-anual.service';
import * as toastr from 'toastr';
import { DatePipe } from '@angular/common';
import { PlanoContratacaoPlanejamentoRelatorioPlano } from '../plano-contratacao-anual-relatorios/plano-contratacao-planejamento-relatorio-plano';
import { PlanoContratacaoAnualItensService } from '../service/plano-contratacao-anual-itens.service';
import { PlanoContratacaoPlanejamentoRelatorioSecretaria } from '../plano-contratacao-anual-relatorios/plano-contratacao-planejamento-relatorio-secretaria';
import { PlanoContratacaoPlanejamentoRelatorioSubelemento } from '../plano-contratacao-anual-relatorios/plano-contratacao-planejamento-relatorio-subelemento';
import { PlanoContratacaoPlanejamentoRelatorioItem } from '../plano-contratacao-anual-relatorios/plano-contratacao-planejamento-relatorio-item';
import { takeUntil } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'lib-plano-contratacao-anual-list',
  templateUrl: './plano-contratacao-anual-list.component.html'
})
export class PlanoContratacaoAnualListComponent extends BaseResourceListComponent<PlanoContratacaoAnual, LoginContabil> {

  private parametros: ParametroRequisicao;
  datepipe: DatePipe;
  public pca: PlanoContratacaoAnual = new PlanoContratacaoAnual();

  constructor(
    protected injector: Injector,
    private planoService: PlanoContratacaoAnualService,
    private planoItemService: PlanoContratacaoAnualItensService,
    protected exercicioService: ExercicioService,
    protected funcaoService: FuncaoService) {
    super(planoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(item: PlanoContratacaoAnual): boolean {
    return !item?.excluido;
  }

  novo() {
    if (this.login.parametro['requisicao'].data_limite_pca == null) {
      this.router.navigate(['/plano-contratacao/novo']);
    } else {
      const limite = new Date(this.login.parametro['requisicao'].data_limite_pca);

      if (this.login.exercicio.ano == limite.getFullYear() && new Date() > limite) {
        toastr.warning('Tempo para execução do PCA expirado: ');
        return;
      }

      this.router.navigate(['/plano-contratacao/novo']);
    }
  }

  protected relations(): string {
    return 'orgao,unidade,exercicio,itens';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id,
      ['exercicio.id']: this.login.exercicio.id,
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['id'];
  }

  protected filtrosGrid(): Filtro {
    return {
    };
  }

  protected afterInit(): void {
    this.datepipe = new DatePipe('pt');
  }

  protected acaoRemover(model: PlanoContratacaoAnual): Observable<PlanoContratacaoAnual> {
    throw new Error('Method not implemented.');
  }


  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Secretaria', coluna: 'secretaria', agrupar: true, bold: true },
      { titulo: 'Subelemento', coluna: 'subelemento' },
      { titulo: 'Valor', coluna: 'valor', decimais: 2, alignment: 'right', }
    ]
  }

  public relatorioPlano(item: PlanoContratacaoAnual, formato: FormatoExportacao): void {
    const parametros = {
      plano_id: item.id,
      orderBy: 'numero'
    }

    let subtitulo = item.unidade.nome + '\n\nPeríodo: '
      + this.datepipe.transform(item.data_pesquida_inicio, 'dd/MM/yyyy') + ' / '
      + this.datepipe.transform(item.data_pesquida_fim, 'dd/MM/yyyy');

    new PlanoContratacaoPlanejamentoRelatorioPlano(this.planoItemService, parametros, formato, subtitulo).imprimir()
  }

  public relatorioPCASecretaria() {
    new PlanoContratacaoPlanejamentoRelatorioSecretaria(this.planoService, this.funcaoService).imprimir()
  }

  public relatorioPCASubelemento() {
    new PlanoContratacaoPlanejamentoRelatorioSubelemento(this.planoItemService, this.funcaoService).imprimir()
  }

  public relatorioPCAItem() {
    new PlanoContratacaoPlanejamentoRelatorioItem(this.planoItemService, this.funcaoService).imprimir()
  }

  public exportarListagem(formato: FormatoExportacao): void {
    const parametros = this.obterParametros()
    parametros['relations'] = this.relations()

    this.planoService.filtrar(1, -1, parametros).subscribe(
      lista => {
        let novaLista = this.agruparSubelementoTotalizadores(lista.content)
        if (formato === 'pdf') {
          this.imprimir('LISTAGEM DE PLANEJAMENTO DO PLANO DE CONTRATAÇÃO ANUAL', this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape', 'Listagem de Planejameto do Plano de Contratação Anual', ['*', 'auto'], novaLista)
        } else {
          this.exportar(formato, novaLista)
        }
      },
      () => toastr.error('Erro ao retornar lista')
    )
  }

  public agruparSubelementoTotalizadores(lista: PlanoContratacaoAnual[]) {
    let novaLista = []

    lista.forEach(item => {
      item.itens.forEach(it => it['valor_total'] = it.quantidade * it.valor_unitario)
      item.itens.sort((a, b) => a.subelemento > b.subelemento ? 1 : -1)

      this.funcaoService.agrupar(item.itens, 'subelemento', ['valor_total']).map(g =>
        ({ subelemento: g.grupo, valor: g.totalizadores['valor_total'] })
      ).forEach(sub => {
        novaLista.push({
          secretaria: item.unidade.nome,
          subelemento: sub.subelemento,
          valor: sub.valor
        })
      })
    })

    return novaLista
  }

  remover(item: PlanoContratacaoAnual, idx) {
    this.pca = item;
    this.pca.justificativa_exclusao = null;
    this.pca.usuario_exclusao = null;
    this.pca.data_exclusao = null;
    this.pca.excluido = false;
  }


  async excluir() {
    this.pca.excluido = true;
    this.pca.data_exclusao = new Date();
    this.pca.usuario_exclusao = this.login.usuario;

    if (this.pca.cadastrado_pncp) {
      toastr.error(`Não é possivel remover, devido estar cadastrado no PNCP.`);
      return;
    }

    this.planoService.atualizar(this.pca).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        // this.lista.splice(idx, 1)
        $('#dlgExclusao').modal('hide');
        toastr.success(`Removido com sucesso!`)
      }, error => toastr.error('Não é possivel remover, devido estar cadastrado no PNCP.'));
  }

}
