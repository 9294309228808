import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Convenio, Login, ConvenioHistorico, GlobalService, DateFormatPipe } from 'eddydata-lib';
import { ConvenioHistoricoService } from '../service/convenio-historico.service';

@Component({
  selector: 'lib-convenio-historico',
  templateUrl: './convenio-historico.component.html'
})
export class ConvenioHistoricoComponent implements OnInit, OnChanges, OnDestroy {

  public ptBR: any;
  public entity: ConvenioHistorico;
  public entityTemp: ConvenioHistorico;
  public listaHistoricos: Array<ConvenioHistorico>;
  protected unsubscribe: Subject<void> = new Subject();

  @Input() lista: Array<any>;
  @Input() entidade: Convenio;
  @Input() login: Login;

  @ViewChild('btnAdicionar') public btnAdicionar: ElementRef;
  @ViewChildren('btnsEditar') public btnsEditar: QueryList<ElementRef>;

  constructor(
    private messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected globalService: GlobalService,
    protected historicoService: ConvenioHistoricoService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.listaHistoricos = this.lista;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lista) {
      this.listaHistoricos = this.lista;
    }
  }

  private desabilitarEdicao(desabilitar: boolean) {
    this.btnsEditar.forEach((btn) => btn.nativeElement.disabled = desabilitar)
    this.btnAdicionar.nativeElement.disabled = desabilitar;
  }

  public adicionar() {
    this.entity = new ConvenioHistorico();
    this.entity.data_historico = new Date();
    this.entity.convenio = this.entidade;
    this.entity.editavel = true;
    this.entity['salvo'] = false;
    if (!this.listaHistoricos) {
      this.listaHistoricos = new Array();
    }
    this.listaHistoricos.unshift(this.entity);
    this.desabilitarEdicao(true);
  }

  public salvar(item: ConvenioHistorico) {
    try {
      if (!item.data_historico) {
        throw new Error('Informe a data de histórico!');
      }
      // envia a entidade para ser salva no banco -----
      this.entity.usuario = this.login.usuario;
      item.editavel = false;
      item['salvo'] = true;
      this.desabilitarEdicao(false);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  public editar(item: ConvenioHistorico) {
    this.entityTemp = JSON.parse(JSON.stringify(item));

    this.entity = item;
    this.entity['salvo'] = true;
    this.entity.editavel = true;
    this.desabilitarEdicao(true);
    item.data_historico = new DateFormatPipe().transform(item.data_historico, []);
  }

  public cancelar(item: ConvenioHistorico, index: number) {
    if (item['salvo'] && item.id === this.entityTemp?.id) {
      item.data_historico = this.entityTemp.data_historico;
      item.texto = this.entityTemp.texto;

      this.entity = item;
      this.entity.editavel = false;
    } else {
      this.listaHistoricos.splice(index, 1);
    }
    this.desabilitarEdicao(false);
  }

  public confirmarRemocao(item: ConvenioHistorico, index: number) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja remover item?',
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.remover(item, index)
      },
    });
  }

  private remover(item: ConvenioHistorico, index: number) {
    if (item.id) {
      this.historicoService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaHistoricos.splice(index, 1);
          this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Exclusão', detail: error.error.payload });
          throw error;
        });
    } else {
      this.listaHistoricos.splice(index, 1);
    }
  }


}
