import { Component, Injector } from '@angular/core';
import { BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, LoginContabil, Programa } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProgramaService } from '../service/programa.service';

@Component({
  selector: 'lib-programa-list',
  templateUrl: './programa-list.component.html'
})
export class ProgramaListComponent extends BaseResourceListComponent<Programa, LoginContabil> {

  /**
   * Declaração de variáveis
   */


  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private programaService: ProgramaService) {
    super(programaService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'ppa';
  }

  protected condicoesGrid(): {} {
    return {
      ['ppa.id']: this.login.ppa.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['id$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['id'],
      text: ['nome']
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: Programa): Observable<Programa> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Id', coluna: 'id' },
      { titulo: 'Descrição', coluna: 'nome' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'ppa';
    this.programaService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE PROGRAMAS DE GOVERNO`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem programa', ['auto', '*'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public retornar() {
    this.router.navigate([`/cadastros-auxiliares`]);
  }
}
