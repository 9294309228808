import { BaseResourceModel } from "../../models/base-resource.model";
import { Orgao } from "../comum/orgao.model";
import { Usuario } from "../comum/usuario.model";
import { ComunicadoUsuario } from "./comunicado-usuario.model";

export class Comunicado extends BaseResourceModel {
  constructor (
    public id?: number,
    public data_alteracao?: Date,
    public data_cadastro?: Date,
    public titulo?: string,
    public mensagem?: string,
    public usuario?: Usuario,
    public orgao?: Orgao,
    public itens?: ComunicadoUsuario[]
  ) {
    super();
  }
  static converteJson(json: any): Comunicado {
    return Object.assign(new Comunicado(), json);
  }
}