import { Component, Input } from '@angular/core';
import { RegistroPrecos } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-julgamento',
  templateUrl: './licitacao-audesp-julgamento.component.html'
})
export class LicitacaoAudespJulgamentoComponent extends BaseLicitacaoAudesp {

  @Input() public entidade: RegistroPrecos;

  constructor(
  ) {
    super()
  };
}
