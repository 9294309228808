// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { LicitacaoChecklist } from './licitacao-checklist.model';
import { Modalidade } from '../licitacao/modalidade.model';

export class AuditoriaLicitacao  extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_auditoria?: Date,
    public data_abertura?: Date,
    public data_homologacao?: Date,
    public ano?: number,
    public numero?: string,
    public modalidade?: Modalidade,
    public objeto?: string,
    public observacao?: string,
    public conclusao?: string,
    public recomendacao?: string,
    public cancelado?: boolean,
    public valor_estimado?: number,
    public orgao?: Orgao,
    public checklist?: LicitacaoChecklist[]
    ) {
      super();
    }
    static converteJson(json: any): AuditoriaLicitacao {
      return Object.assign(new AuditoriaLicitacao(), json);
    }
}
