import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, RecursoSiconfiAudesp } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class RecursoSiconfiAudespService extends BaseResourceService<RecursoSiconfiAudesp> {

  constructor(
    protected injector: Injector
  ) {
    super(`recursos-siconfi-audesp`, injector);
  }


}
