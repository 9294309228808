import { BaseResourceModel } from '../../models/base-resource.model';
import { RetencaoIRRFItem } from "./retencao-irrf-item.model";
import { Exercicio } from '../comum/exercicio.model';

export class RetencaoIRRF extends BaseResourceModel {
  constructor(
    public id?: number,

    public exercicio?: Exercicio,
    public itens?: RetencaoIRRFItem[],) {
    super();
  }
  static converteJson(json: any): RetencaoIRRF {
    return Object.assign(new RetencaoIRRF(), json);
  }
}