import { Component, EventEmitter, Injector, Input, Output } from "@angular/core";
import { CompraItem, FuncaoService, GlobalService } from "eddydata-lib";

@Component({
  selector: 'lib-pedido-compra-selecionar-item-dlg',
  templateUrl: './pedido-compra-selecionar-item-dlg.component.html'
})
export class PedidoCompraSelecionarItemDlgComponent {

  @Input() lista: CompraItem[] = []
  @Output() finalizado: EventEmitter<CompraItem[]> = new EventEmitter();


  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  /**
   * Declaração de variáveis
   */
  @Input() titulo: string = 'Selecione os itens da OF que deseja vincular ao pedido';

  public selAll: boolean = false;
  public filtro: string = '';


  public imaskValor = {
    mask: Number,
    scale: 5,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public funcaoService: FuncaoService,
    public globalService: GlobalService) {
  }

  public finalizar() {
    if (!this.validar())
      return;
    this.finalizado.emit(this.listaSel(true));
    this.hide();
  }

  public validar(): boolean {
    if (this.listaSel(true).length === 0) {
      toastr.warning(`Nenhum item selecionado.`);
      return false;
    }
    return true;
  }

  public selecionar(item: CompraItem, sel: boolean) {
    item['selecionado'] = sel;
  }

  public listaSel(sel: boolean): CompraItem[] {
    let lista = this.lista.filter((c) => sel ? c['selecionado'] : !c['selecionado']);
    if (!sel && this.filtro)
      lista = lista.filter((f) => {
        if (f.produto_unidade.produto.nome.toLowerCase().includes(this.filtro.toLowerCase()))
          return true;
        if (f.produto_unidade.unidade.nome.toLowerCase().includes(this.filtro.toLowerCase()))
          return true;
        if (f.produto_unidade.produto.codigo.toLowerCase().includes(this.filtro.toLowerCase()))
          return true;
      });
    return lista;
  }


  public selecionarTodos() {
    const selecionado = this.todosSelecionados();
    if (this.lista)
      this.lista.forEach((i) => i['selecionado'] = !selecionado);
  }

  public todosSelecionados(): boolean {
    return this.lista.filter((i) => !i['selecionado']).length === 0
  }

  public onClick(filtro: string) {
    this.filtro = filtro;
  }

  public onHide() {
    this.hide();
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }
}
