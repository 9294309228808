import { Component, Injector, ElementRef } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BaseResourceFormComponent, Liquidacao, LoginPublico,
  GlobalService, DateFormatPipe
} from 'eddydata-lib';
import { DatePipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { LiquidacaoService } from '../service/liquidacao.service';

@Component({
  selector: 'app-liquidacao-shw',
  templateUrl: './liquidacao-shw.component.html'
})
export class LiquidacaoShwComponent extends BaseResourceFormComponent<Liquidacao, LoginPublico> {

  /**
   * Declaração de variáveis
   */
  private pagina: string;
  private data1: string;
  private data2: string;
  private filtro: string;

  private datepipe: DatePipe;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected liquidacaoService: LiquidacaoService) {
    super(new Liquidacao(), injector, Liquidacao.converteJson, liquidacaoService);
    this.datepipe = new DatePipe('pt');
    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.pagina = params['pagina'];
        this.data1 = params['data1'];
        this.data2 = params['data2'];
        this.filtro = params['filtro'];
      });
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      parcela: [null, [Validators.required]],
      valor_liquidado: 0,
      data_liquidacao: [null, [Validators.required]],
      data_vencimento: [null, [Validators.required]],
      historico: [null, [Validators.required]],
      mes: [null, [Validators.required]],
      documento: [null, [Validators.required]],
      anulacao: [null, [Validators.required, Validators.minLength(2)]],
      impresso: [null, [Validators.required]],
      orgao: [null, [Validators.required]],
      empenho: this.fb.group({
        numero: [null, [Validators.required]],
        exercicio: this.fb.group({
          ano: [null, [Validators.required]],
        })

      }),
      exercicio: [null, [Validators.required]],
    });
    this.entidadeForm.disable();
  }

  protected parametrosExtras(): {} {
    return { relations: 'empenho,empenho.ficha,empenho.ficha.acao,empenho.favorecido,empenho.subelemento,empenho.exercicio' };
  }

  protected afterInit(): void {
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: Liquidacao): void {
  }

  protected afterLoad() {
    this.entidade.data_liquidacao = new DateFormatPipe().transform(this.entidade.data_liquidacao, []);
    this.entidade.data_vencimento = new DateFormatPipe().transform(this.entidade.data_vencimento, []);
  }

  public voltar() {
    const dt1 = this.datepipe.transform(this.data1, 'yyyy-MM-dd', 'GMT');
    const dt2 = this.datepipe.transform(this.data2, 'yyyy-MM-dd', 'GMT');
    if (!this.filtro || this.filtro === '') {
      this.router.navigate(['/liquidacoes-orcamentarias/pesquisa', this.pagina, dt1, dt2]);
    } else {
      this.router.navigate(['/liquidacoes-orcamentarias/pesquisa', this.pagina, dt1, dt2, this.filtro]);
    }
  }

}
