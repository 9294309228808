import { BaseResourceModel } from '../../models/base-resource.model';
import { SiteMenu } from './site-menu.model';

export class SitePagina extends BaseResourceModel {
  constructor(
    public id?: number,
    public titulo?: string,
    public coluna?: number,
    public tipo?: number,
    public texto1?: string,
    public texto2?: string,
    public imagem1?: string,
    public imagem2?: string,
    public ativo?: boolean,
    public siteMenu?: SiteMenu
  ) {
    super();
  }

  static converteJson(json: any): SitePagina {
    return Object.assign(new SitePagina(), json);
  }
}
