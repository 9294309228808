// tslint:disable: variable-name
import { Protocolo } from './protocolo.model';
import { Usuario } from '../comum/usuario.model';

export class ProtocoloRelacionado {

    id: number;

    data_cadastro: Date;

    data_alteracao: Date;

    usuario: Usuario;

    processo: Protocolo;
}
