import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { FichaDespesaViewComponent } from './ficha-despesa-view/ficha-despesa-view.component';

const routes: Routes = [
  { 
    path: ':id/visualizar/:orgao',
    children: [
      { path: '', component: FichaDespesaViewComponent, canActivate: [AuthGuard] },
      { path: ':data', component: FichaDespesaViewComponent, canActivate: [AuthGuard] }
    ],
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FichaDespesaRoutingModule { }
