import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../../assinaturas/assinaturas';
import { AnexoLrfFederalService } from '../../../service/anexo-lrf-federal.service';

@Directive()
export class Anexo9RreoLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoFederalServico: AnexoLrfFederalService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected assinaturaControleInterno: boolean) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
    this.anexoFederalServico.obterAnexo9(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(null, this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            await this.conteudo(dados),
            'portrait', 'ANEXO9 - DEMONSTRATIVO DAS RECEITAS DE OPERAÇÕES DE CRÉDITO E DESPESAS DE CAPITAL (REGRA DE OURO)',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 2;
                },
                paddingRight() {
                  return 2;
                }
              }
            }, true, false);
        } else {
          this.funcaoService.exportar(formato, this.normalizar(dados), 'ANEXO9 - DEMONSTRATIVO DAS RECEITAS DE OPERAÇÕES DE CRÉDITO E DESPESAS DE CAPITAL (REGRA DE OURO)', this.colunas());
        }
      });
  }

  private async conteudo(dados: any[]): Promise<{}[]> {
    // monta o cabecalho

    const registros: {}[] = [
      [
        { text: this.login.orgao.nome, bold: true, border: [false, false, false, false], colSpan: 4, fontSize: 10 },
        '', '', ''
      ],

      [
        { text: 'RELATÓRIO RESUMIDO DA EXECUÇÃO ORÇAMENTÁRIA', bold: true, border: [false, false, false, false], colSpan: 4 },
        '', '', ''],
      [
        { text: 'DEMONSTRATIVO DAS RECEITAS DE OPERAÇÕES DE CRÉDITO E DESPESAS DE CAPITAL (REGRA DE OURO)', bold: true, border: [false, false, false, false], colSpan: 4 },
        '', '', ''],
      [
        { text: 'ORÇAMENTOS FISCAL E DA SEGURIDADE SOCIAL', bold: true, border: [false, false, false, false], colSpan: 4 },
        '', '', ''],
      [
        { text: `REFERÊNCIA: ${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano}`, bold: true, border: [false, false, false, false], colSpan: 4 },
        '', '', ''],
      [
        { text: 'RREO – ANEXO 9 (LRF, art.53, § 1º, inciso I) ', bold: true, border: [false, false, false, false], colSpan: 4 },
        '', '', ''
      ],
      [
        { text: 'RECEITAS', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'PREVISÃO ATUALIZADA (a)', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'RECEITAS REALIZADAS (b)', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'SALDO NÃO REALIZADO (c) = (a – b)', alignment: 'center', bold: true, fontSize: 8 }
      ]

    ];

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);

    // monta o agrupamento do relatório
    const titulos = this.funcaoService.agrupar(dados[0], 'titulo', ['previsto', 'arrecadado']);
    let totalPrevisto = 0;
    let totalArrecadado = 0;
    for (const titulo of titulos) {
      registros.push([
        { text: titulo.grupo, fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['previsto']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['arrecadado']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['previsto'] - +titulo.totalizadores['arrecadado']), alignment: 'right', fontSize: 8, bold: true }
      ]);
      totalPrevisto += +titulo.totalizadores['previsto'];
      totalArrecadado += +titulo.totalizadores['arrecadado'];

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['previsto', 'arrecadado']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          registros.push([
            { text: grupo.grupo, fontSize: 8, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsto']), alignment: 'right', fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']), alignment: 'right', fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['previsto'] - +grupo.totalizadores['arrecadado']), alignment: 'right', fontSize: 8 }
          ]);

          for (const item of grupo.registros) {
            if (item.nome != '') {
              registros.push([
                {
                  text: item.nome, fontSize: 8, margin: [5, 0, 0, 0]
                },
                { text: this.funcaoService.convertToBrNumber(item.previsto), alignment: 'right', fontSize: 8 },
                { text: this.funcaoService.convertToBrNumber(item.arrecadado), alignment: 'right', fontSize: 8 },
                { text: this.funcaoService.convertToBrNumber(+item.previsto + +item.arrecadado), alignment: 'right', fontSize: 8 }
              ]);
            }
          }
        }
      }

    }


    // ----------------- DESPEAS  -----------------------

    registros.push(
      [
        { text: 'DESPESAS', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'DOTAÇÃO ATUALIZADA (d)', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'DESPESAS EMPENHADAS (e)', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'SALDO NÃO EXECUTADO (f) = (d – e)', alignment: 'center', bold: true, fontSize: 8 }
      ]

    );

    const titulos1 = this.funcaoService.agrupar(dados[1], 'titulo', ['dotacao', 'empenhado']);
    let totalDotacao = 0;
    let totalEmpenhado = 0;
    for (const titulo of titulos1) {
      registros.push([
        { text: titulo.grupo, fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['dotacao']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['empenhado']), alignment: 'right', fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['dotacao'] - +titulo.totalizadores['empenhado']), alignment: 'right', fontSize: 8, bold: true }
      ]);
      totalDotacao += +titulo.totalizadores['dotacao'];
      totalEmpenhado += +titulo.totalizadores['empenhado'];

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['dotacao', 'empenhado']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          registros.push([
            { text: grupo.grupo, fontSize: 8, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao']), alignment: 'right', fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']), alignment: 'right', fontSize: 8 },
            { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['dotacao'] - +grupo.totalizadores['empenhado']), alignment: 'right', fontSize: 8 }
          ]);

          for (const item of grupo.registros) {
            if (item.nome != '') {
              registros.push([
                {
                  text: item.nome, fontSize: 8, margin: [5, 0, 0, 0]
                },
                { text: this.funcaoService.convertToBrNumber(item.dotacao), alignment: 'right', fontSize: 8 },
                { text: this.funcaoService.convertToBrNumber(item.empenhado), alignment: 'right', fontSize: 8 },
                { text: this.funcaoService.convertToBrNumber(+item.dotacao + +item.empenhado), alignment: 'right', fontSize: 8 }
              ]);
            }
          }
        }
      }

    }

    registros.push([
      {
        text: 'DESPESA DE CAPITAL LÍQUIDA (II)', fontSize: 8, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(totalDotacao), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(totalEmpenhado), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+totalDotacao - +totalEmpenhado), alignment: 'right', fontSize: 8, bold: true }
    ]);

    registros.push([
      {
        text: 'REGRA DE OURO (III) = (II – I)', fontSize: 8, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(+totalDotacao - +totalPrevisto), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber(+totalEmpenhado - +totalArrecadado), alignment: 'right', fontSize: 8, bold: true },
      { text: this.funcaoService.convertToBrNumber((+totalDotacao - +totalPrevisto) - (+totalDotacao - +totalEmpenhado)), alignment: 'right', fontSize: 8, bold: true }
    ]);


    return [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 80, 80, 80],
          body: registros
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*'],
          body: assinaturas
        }
      }
    ];
  }

  public normalizar(lista: any[]) {
    const listaExportar = []

    const titulos = this.funcaoService.agrupar(lista[0], 'titulo', ['previsto', 'arrecadado']);
    let totalPrevisto = 0;
    let totalArrecadado = 0;
    for (const titulo of titulos) {
      const primeira_linha_receita = {
        receitas: titulo.grupo,
        previsao_atualizada: this.funcaoService.convertToBrNumber(titulo.totalizadores['previsto']),
        previsao_realizada: this.funcaoService.convertToBrNumber(titulo.totalizadores['arrecadado']),
        saldo_nao_realizado: this.funcaoService.convertToBrNumber(+titulo.totalizadores['previsto'] - +titulo.totalizadores['arrecadado'])
      }
      listaExportar.push(primeira_linha_receita)

      totalPrevisto += +titulo.totalizadores['previsto'];
      totalArrecadado += +titulo.totalizadores['arrecadado'];

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['previsto', 'arrecadado']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          const linha_orgao_receita = {
            receitas: grupo.grupo,
            previsao_atualizada: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsto']),
            previsao_realizada: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']),
            saldo_nao_realizado: this.funcaoService.convertToBrNumber(+grupo.totalizadores['previsto'] - +grupo.totalizadores['arrecadado'])
          }
          listaExportar.push(linha_orgao_receita)
        }
        for (const item of grupo.registros) {
          if (item.nome != '') {
            const linha_item_receita = {
              receitas: item.nome,
              previsao_atualizada: this.funcaoService.convertToBrNumber(item.previsto),
              previsao_realizada: this.funcaoService.convertToBrNumber(item.arrecadado),
              saldo_nao_realizado: this.funcaoService.convertToBrNumber(+item.previsto - +item.arrecadado)
            }
            listaExportar.push(linha_item_receita)
          }
        }
      }
    }

    const espaco = {
      receitas: '',
      previsao_atualizada: '',
      previsao_realizada: '',
      saldo_nao_realizado: ''
    }
    listaExportar.push(espaco)

    const linha_titulo_despesa = {
      receitas: 'DESPESAS',
      previsao_atualizada: 'DOTAÇÃO ATUALIZADA (d)',
      previsao_realizada: 'DESPESAS EMPENHADAS (e)',
      saldo_nao_realizado: 'SALDO NÃO EXECUTADO (f) = (d – e)'
    }
    listaExportar.push(linha_titulo_despesa)

    const titulos1 = this.funcaoService.agrupar(lista[1], 'titulo', ['dotacao', 'empenhado']);
    let totalDotacao = 0;
    let totalEmpenhado = 0;
    for (const titulo of titulos1) {
      const primeira_linha_despesa = {
        receitas: titulo.grupo,
        previsao_atualizada: this.funcaoService.convertToBrNumber(titulo.totalizadores['dotacao']),
        previsao_realizada: this.funcaoService.convertToBrNumber(titulo.totalizadores['empenhado']),
        saldo_nao_realizado: this.funcaoService.convertToBrNumber(+titulo.totalizadores['dotacao'] - +titulo.totalizadores['empenhado'])
      }
      listaExportar.push(primeira_linha_despesa)

      totalDotacao += +titulo.totalizadores['dotacao'];
      totalEmpenhado += +titulo.totalizadores['empenhado'];

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['dotacao', 'empenhado']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          const linha_orgao_despesa = {
            receitas: grupo.grupo,
            previsao_atualizada: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao']),
            previsao_realizada: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']),
            saldo_nao_realizado: this.funcaoService.convertToBrNumber(+grupo.totalizadores['dotacao'] - +grupo.totalizadores['empenhado'])
          }
          listaExportar.push(linha_orgao_despesa)
        }

        for (const item of grupo.registros) {
          if (item.nome != '') {
            const linha_item_despesa = {
              receitas: item.nome,
              previsao_atualizada: this.funcaoService.convertToBrNumber(item.dotacao),
              previsao_realizada: this.funcaoService.convertToBrNumber(item.empenhado),
              saldo_nao_realizado: this.funcaoService.convertToBrNumber(+item.dotacao - +item.empenhado)
            }
            listaExportar.push(linha_item_despesa)
          }
        }
      }
    }

    listaExportar.push(espaco)

    const linha_despesa_liquida = {
      receitas: 'DESPESA DE CAPITAL LÍQUIDA (II)',
      previsao_atualizada: this.funcaoService.convertToBrNumber(totalDotacao),
      previsao_realizada: this.funcaoService.convertToBrNumber(totalEmpenhado),
      saldo_nao_realizado: this.funcaoService.convertToBrNumber(+totalDotacao - +totalEmpenhado)
    }
    listaExportar.push(linha_despesa_liquida)

    const linha_regra_ouro = {
      receitas: 'REGRA DE OURO (III) = (II – I)',
      previsao_atualizada: this.funcaoService.convertToBrNumber(+totalDotacao - +totalPrevisto),
      previsao_realizada: this.funcaoService.convertToBrNumber(+totalEmpenhado - +totalArrecadado),
      saldo_nao_realizado: this.funcaoService.convertToBrNumber((+totalDotacao - +totalPrevisto) - (+totalDotacao - +totalEmpenhado))
    }
    listaExportar.push(linha_regra_ouro)

    return listaExportar
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'RECEITAS ', coluna: 'receitas', bold: true },
      { titulo: 'PREVISÃO ATUALIZADA (a)', coluna: 'previsao_atualizada' },
      { titulo: 'RECEITAS REALIZADAS (b)', coluna: 'previsao_realizada' },
      { titulo: 'SALDO NÃO REALIZADO (c) = (a – b)', coluna: 'saldo_nao_realizado' },
    ];
    return colunasDefault;
  }

}
