import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TransferenciaBancariaService } from 'administrativo-lib';
import { ContaBancaria, FuncaoService, GlobalService, LoginContabil, OrgaoService, TransferenciaBancaria } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';

@Component({
  selector: 'app-transferencia-bancaria-obn-dlg',
  templateUrl: './transferencia-bancaria-obn-dlg.component.html'
})
export class TransferenciaBancariaObnDlgComponent implements OnInit, OnDestroy {

  protected datepipe: DatePipe;
  public ptBR: any;
  public selectedOpt: string;
  public dataInicio: Date;
  public dataFinal: Date;
  public numeroInicio: number;
  protected unsubscribe: Subject<void> = new Subject();
  public login: LoginContabil;

  @Input() tipo: 'OBN' | 'FEBRABAN' = 'OBN';

  public imaskNumero = {
    mask: Number,
    scale: 0,
    signed: false
  };

  constructor(
    private globalService: GlobalService,
    private funcaoService: FuncaoService,
    private orgaoService: OrgaoService,
    private transferenciaService: TransferenciaBancariaService) {
  }

  ngOnInit(): void {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.selectedOpt = 'opt2';
    this.dataInicio = new Date();
    this.dataFinal = new Date();
    this.login = GlobalService.obterSessaoLogin() as LoginContabil;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private salvarArquivos(arquivos: { nome: string, arquivo: string }[]) {
    for (const arquivo of arquivos) {
      const element = document.createElement('a');
      (window as any).global = window;
      global.Buffer = global.Buffer || require('buffer').Buffer;
      (window as any).process = {
        version: ''
      };
      const blob = new Blob([Buffer.from(this.funcaoService.removerAcentos(arquivo.arquivo).replace(/\n/g, "\r\n"), 'latin1')], { type: 'text/plain' });
      const downloadURL = window.URL.createObjectURL(blob);
      element.href = downloadURL;
      element.setAttribute('download', `${arquivo.nome}`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      window.URL.revokeObjectURL(downloadURL);
      document.body.removeChild(element);
    }
  }

  public async gerarArquivo() {
    if (this.selectedOpt === 'opt1') {
      if (+this.dataFinal < +this.dataInicio) {
        toastr.warning('Data final está maior que a inicial');
        return;
      }
      if (!this.dataInicio || !this.dataFinal || +this.dataFinal === 0 || +this.dataInicio === 0) {
        toastr.warning('Informe a data inicial e final para imprimir');
        return;
      }
    } else {
      if (!this.numeroInicio || +this.numeroInicio === 0) {
        toastr.warning('Informe o número inicial e final para imprimir');
        return;
      }
    }

    if (this.tipo === 'FEBRABAN') {
      const parametros = {}
      parametros['relations'] = 'conta_pagadora.banco,conta_pagadora.conta_entidade,conta_recebedora.banco,conta_recebedora.conta_entidade,orgao,exercicio,conta_pagadora.orgao,conta_recebedora.orgao,orgao.cidade.estado';

      parametros['id'] = this.numeroInicio;
      parametros['exercicio.id'] = this.login.exercicio.id;
      parametros['orgao.id'] = this.login.orgao.id;
      // parametros['transferencia.exportado_febraban'] = false;

      this.transferenciaService.filtrar(0, 0, parametros).pipe(takeUntil(this.unsubscribe)).subscribe(async dados => {
        if (dados.content?.length === 0)
          toastr.info('Nenhuma informação foi localizada')
        else
          await this.gerarArquivoFEBRABAN(dados.content);
      }, (error) => this.funcaoService.acaoErro(error));
    } else toastr.warning(`Arquivo ${this.tipo} inválido`)
  }

  private async gerarArquivoFEBRABAN(dados: TransferenciaBancaria[]) {
    const arquivos: { nome: string, arquivo: string }[] = []
    const agrupar = true;
    const valoresAgrupados: { data_vencimento: Date, valorTotal: number } = { data_vencimento: null, valorTotal: 0 };
    for (const item of dados) {
      const data_vencimento = item.data_transfere;
      if (!valoresAgrupados.data_vencimento || data_vencimento > valoresAgrupados.data_vencimento) valoresAgrupados.data_vencimento = data_vencimento;
      valoresAgrupados.valorTotal = +valoresAgrupados.valorTotal + +item.valor_transferido;
    }
    const contas = this.funcaoService.agrupar(dados, 'conta_pagadora.codigo');
    for (const grupo of contas) {
      const conta = grupo.registros[0].conta_pagadora as ContaBancaria;
      let arquivo = '';
      let countSeg = 1;
      let formaLancamento;
      let ultFormaLancamento;
      let lote = 1;
      let qtdRegLote = 0;
      let valorTotal = 0;
      let headerLote = false;
      // ARQUIVO
      //G001
      arquivo += this.funcaoService.strZero(conta.banco.febraban, 3); //1 ~ 3
      //G002
      arquivo += this.funcaoService.strZero(0, 4); //4 ~ 7
      //G003
      arquivo += this.funcaoService.strZero(0, 1); //8 ~ 8
      //G004
      arquivo += this.funcaoService.strEspaco('', 9); //9 ~ 17
      //G005
      arquivo += this.funcaoService.strZero(2, 1); //18 ~ 18
      //G006
      arquivo += this.funcaoService.strZero(conta.conta_entidade.cnpj, 14); //19 ~ 32
      //G007
      arquivo += this.funcaoService.alinharEsquerda(this.funcaoService.strZero(conta.convenio_febraban, 9) + '0126', 20); //33 ~ 52 // mod. banco
      //G008
      arquivo += this.funcaoService.strZero(conta.agencia, 5); //53 ~ 57
      //G009
      arquivo += this.funcaoService.strEspaco(conta.digito_agencia, 1).toUpperCase(); //58 ~ 58
      //G010
      arquivo += this.funcaoService.strZero(conta.numero_conta, 12); //59 ~ 70
      //G011
      arquivo += this.funcaoService.strEspaco(conta.digito_conta, 1).toUpperCase(); //71 ~ 71
      //G012
      arquivo += this.funcaoService.strEspaco('', 1); //72 ~ 72
      //G013
      arquivo += this.funcaoService.strEspaco(dados[0].orgao.nome, 30); //73 ~ 102
      //G014
      arquivo += this.funcaoService.strEspaco(conta.banco.nome, 30); //103 ~ 132
      //G004
      arquivo += this.funcaoService.strEspaco("", 10); //133 ~ 142
      //G015
      arquivo += this.funcaoService.strEspaco("1", 1); //143 ~ 143 // 1 = Remessa
      //G016
      arquivo += this.funcaoService.strZero(this.funcaoService.removerPontos(this.funcaoService.converteDataBR(new Date())), 8); //144 ~ 151
      //G017
      arquivo += this.funcaoService.strZero(new Date().toISOString().substr(11, 8).split(':').join(''), 6); //152 ~ 157
      //G018
      const numeroFebraban = await this.orgaoService.proximoNumeroFebraban(dados[0].orgao.id).toPromise();
      arquivo += this.funcaoService.strZero(numeroFebraban, 6); //158 ~ 163
      //G019
      arquivo += this.funcaoService.strZero('84', 3); //164 ~ 166
      //G020
      arquivo += this.funcaoService.strZero(0, 5); //167 ~ 171
      //G021
      arquivo += this.funcaoService.strEspaco("", 20); // 172 ~ 191
      //G022
      arquivo += this.funcaoService.strEspaco(`EDDYCONT`, 20); // 192 ~ 211
      //G004
      arquivo += this.funcaoService.strEspaco("", 29); // 212 ~ 240
      arquivo += '\n';

      for (const item of (agrupar ? [grupo.registros[0]] : grupo.registros) as TransferenciaBancaria[]) {
        formaLancamento = item.conta_recebedora.banco.febraban === conta.banco.febraban ? 1 : 3;
        if (ultFormaLancamento && formaLancamento !== ultFormaLancamento) {
          //G001
          arquivo += this.funcaoService.strZero(conta.banco.febraban, 3); // 1 ~ 3 
          //G002
          arquivo += this.funcaoService.strZero(lote, 4); // 4 ~ 7
          //G003
          arquivo += this.funcaoService.strZero(5, 1); // 8 ~ 8
          //G004
          arquivo += this.funcaoService.alinharEsquerda("", 9); // 9 ~ 17
          //G057
          arquivo += this.funcaoService.strZero((qtdRegLote * 2) + 2, 6); // 18 ~ 23
          qtdRegLote = 0;
          //P007
          arquivo += this.funcaoService.strZero(this.dec(valorTotal, 2), 18); // 24 ~ 41
          valorTotal = 0;
          //G058
          arquivo += this.funcaoService.strZero(this.dec(0.0, 5), 18); // 42 ~ 59 // mód. banco
          //G066
          arquivo += this.funcaoService.alinharEsquerda("000000", 6); // 60 ~ 65 // mod. banco #4323/34
          //G004
          arquivo += this.funcaoService.alinharEsquerda("", 165); // 66 ~ 230
          //G059
          arquivo += this.funcaoService.alinharEsquerda("", 10); // 231 ~ 240
          arquivo += '\n';
          lote++;
          headerLote = false;
        }

        valorTotal += +item.valor_transferido;
        ultFormaLancamento = formaLancamento;
        // ARQUIVO



        // TAIL
        if (!headerLote) {
          //G001
          arquivo += this.funcaoService.strZero(conta.banco.febraban, 3); // 1 ~ 3 
          //G002
          arquivo += this.funcaoService.strZero(lote, 4); // 4 ~ 7
          //G003
          arquivo += this.funcaoService.strZero(1, 1); // 8 ~ 8
          //G028
          arquivo += this.funcaoService.alinharEsquerda('C', 1); // 9 ~ 9
          //G025
          arquivo += this.funcaoService.strZero(98, 2); // 10 ~ 11
          //G029
          arquivo += this.funcaoService.strZero(formaLancamento, 2); // 12 ~ 13
          //G030
          arquivo += this.funcaoService.strZero('43', 3); // 14 ~ 16
          //G004
          arquivo += this.funcaoService.alinharEsquerda("", 1); // 17 ~ 17
          //G005
          arquivo += this.funcaoService.strZero(2, 1); // 18 ~ 18
          //G006
          arquivo += this.funcaoService.strZero(conta.conta_entidade?.cnpj, 14); // 19 ~ 32
          //G007
          arquivo += this.funcaoService.alinharEsquerda(this.funcaoService.strZero(conta.convenio_febraban, 9) + '0126', 20); //33 ~ 52
          //G008
          arquivo += this.funcaoService.strZero(conta.agencia, 5); // 53 ~ 57
          //G009
          arquivo += this.funcaoService.alinharEsquerda(conta.digito_agencia, 1).toUpperCase(); // 58 ~ 58
          //G010
          arquivo += this.funcaoService.strZero(conta.numero_conta, 12); // 59 ~ 70
          //G011
          arquivo += this.funcaoService.alinharEsquerda(conta.digito_conta, 1).toUpperCase(); // 71 ~ 71
          //G012
          arquivo += this.funcaoService.alinharEsquerda('', 1); // 72 ~ 72
          //G013
          arquivo += this.funcaoService.alinharEsquerda(item.orgao.nome, 30); // 73 ~ 102
          //G031
          arquivo += this.funcaoService.alinharEsquerda('', 40); // 103 ~ 142
          //G032
          arquivo += this.funcaoService.alinharEsquerda(item.orgao.endereco.split(',')[0], 30); // 143 ~ 172
          //G032
          arquivo += this.funcaoService.strZero(item.orgao.endereco.split(',').length > 1 ? item.orgao.endereco.split(',')[1].trim() : 0, 5); // 173 ~ 177
          //G032
          arquivo += this.funcaoService.alinharEsquerda('', 15); // 178 ~ 192
          //G033
          const orgao = await this.orgaoService.obter({ id: item.orgao.id, relations: 'cidade,cidade.estado' }).toPromise();
          arquivo += this.funcaoService.alinharEsquerda(orgao.cidade.nome, 20); // 193 ~ 212
          //G034
          arquivo += this.funcaoService.strZero(this.num(item.orgao.cep.substring(0, 5)), 5); // 213 ~ 217
          //G035
          arquivo += this.funcaoService.alinharEsquerda(this.num(item.orgao.cep.substring(5, 8)), 3); // 218 ~ 220
          //G036
          arquivo += this.funcaoService.alinharEsquerda(orgao.cidade.estado.uf, 2); // 221 ~ 222
          //G004
          arquivo += this.funcaoService.alinharEsquerda("", 8); // 223 ~ 230
          //G059
          arquivo += this.funcaoService.alinharEsquerda('', 10); // 231 ~ 240
          arquivo += '\n';
          headerLote = true;
        }
        qtdRegLote++;

        // SEGMENTO A
        arquivo += this.segmentoA(item, conta, lote, countSeg++, agrupar, valoresAgrupados);
        // SEGMENTO B
        arquivo += this.segmentoB(item, conta, lote, countSeg++, agrupar, valoresAgrupados);
      }
      //G001
      arquivo += this.funcaoService.strZero(conta.banco.febraban, 3); // 1 ~ 3 
      //G002
      arquivo += this.funcaoService.strZero(lote, 4); // 4 ~ 7
      //G003
      arquivo += this.funcaoService.strZero(5, 1); // 8 ~ 8
      //G004
      arquivo += this.funcaoService.alinharEsquerda("", 9); // 9 ~ 17
      //G057
      arquivo += this.funcaoService.strZero((qtdRegLote * 2) + 2, 6); // 18 ~ 23
      qtdRegLote = 0;
      //P007
      arquivo += this.funcaoService.strZero(this.dec(agrupar ? valoresAgrupados.valorTotal : valorTotal, 2), 18); // 24 ~ 41
      //G058
      arquivo += this.funcaoService.strZero(this.dec(0.0, 5), 18); // 42 ~ 59 // mód. banco
      //G066
      arquivo += this.funcaoService.alinharEsquerda("000000", 6); // 60 ~ 65 // mod. banco #4323/34
      //G004
      arquivo += this.funcaoService.alinharEsquerda("", 165); // 66 ~ 230
      //G059
      arquivo += this.funcaoService.alinharEsquerda("", 10); // 231 ~ 240
      arquivo += '\n';

      //G001
      arquivo += this.funcaoService.strZero(conta.banco.febraban, 3); // 1 ~ 3 
      //G002
      arquivo += this.funcaoService.strZero(9999, 4); // 4 ~ 7
      //G003
      arquivo += this.funcaoService.strZero(9, 1); // 8 ~ 8
      //G004
      arquivo += this.funcaoService.alinharEsquerda("", 9); // 9 ~ 17
      //G049
      arquivo += this.funcaoService.strZero(lote, 6); // 18 ~ 23
      //G056
      arquivo += this.funcaoService.strZero((((agrupar ? 1 : grupo.registros.length) * 2) + (lote * 2) + 2), 6); // 24 ~ 29
      //G037
      arquivo += this.funcaoService.strZero(0, 6); // 30 ~ 35
      //G004
      arquivo += this.funcaoService.alinharEsquerda("", 205); // 36 ~ 240
      arquivo += '\n';
      lote++;
      arquivos.push({
        nome: `${this.funcaoService.strZero(numeroFebraban, 6)}_TRB-${grupo.registros[0].id}_${conta.banco.nome.split(' ').join('_').toUpperCase().trim()}.txt`,
        arquivo
      })
    }



    this.salvarArquivos(arquivos)
  }

  private num(text: string) {
    let num = '';
    for (let i = 0; i < text.length; i++) {
      let c = text.charAt(i);
      if (c >= '0' && c <= '9') {
        num += c;
      }
    }
    return +num.toString();
  }

  private segmentoA(item: TransferenciaBancaria, conta: ContaBancaria, lote: number, countSeg: number, agrupar: boolean, valoresAgrupados) {
    let arquivo = '';
    //G001
    arquivo += this.funcaoService.strZero(conta.banco.febraban, 3); //1 ~ 3
    //G002
    arquivo += this.funcaoService.strZero(lote, 4); //4 ~ 7
    //G003
    arquivo += this.funcaoService.strZero(3, 1); //8 ~ 8
    //G038
    arquivo += this.funcaoService.strZero(countSeg, 5); //9 ~ 13
    //G039
    arquivo += 'A'; //14 ~ 14
    //G060
    arquivo += '0'; //15 ~ 15
    //G061
    arquivo += this.funcaoService.strZero(0, 2); //16 ~ 17
    //P001
    if (item.conta_recebedora.banco.febraban === conta.banco.febraban) {
      arquivo += this.funcaoService.strZero(0, 3); //18 ~ 20
    } else if ((agrupar ? valoresAgrupados.valorTotal : item.valor_transferido) > 3000) {
      arquivo += this.funcaoService.strZero(18, 3); //18 ~ 20
    } else {
      arquivo += this.funcaoService.strZero(700, 3); //18 ~ 20
    }
    //P002
    arquivo += this.funcaoService.strZero(item.conta_recebedora.banco.febraban, 3); //21 ~ 23

    const agenciaSplit = this.separarDv(item.conta_recebedora.agencia);
    //G008
    arquivo += this.funcaoService.strZero(agenciaSplit[0], 5); //24 ~ 28
    //G009
    arquivo += this.funcaoService.alinharEsquerda(item.conta_recebedora.digito_agencia, 1).toUpperCase(); //29 ~ 29
    //G010
    const cc = this.separarDv(item.conta_recebedora.numero_conta);
    arquivo += this.funcaoService.strZero(cc[0], 12); //30 ~ 41
    //G011
    arquivo += this.funcaoService.strZero(item.conta_recebedora.digito_conta, 1); //42 ~ 42
    //G012
    arquivo += this.funcaoService.alinharEsquerda('', 1); //43 ~ 43
    //G013
    arquivo += this.funcaoService.alinharEsquerda(item.conta_recebedora.orgao.nome, 30); //44 ~ 73
    //G064
    let empenhoId = item.id;
    let seuNumero = `${item.orgao.codigo.substring(0, 2)}${item.exercicio.ano}0${this.funcaoService.strZero(empenhoId, 13)}`;
    arquivo += this.funcaoService.alinharEsquerda(seuNumero, 20); //74 ~ 93
    //P009
    arquivo += this.funcaoService.strZero(this.funcaoService.converteDataBR(item.data_transfere).split('/',).join(''), 8); //94 ~ 101
    //G040
    arquivo += this.funcaoService.alinharEsquerda('BRL', 3); //102 ~ 104
    //G041
    arquivo += this.funcaoService.strZero(this.dec(0.0, 5), 15); //105 ~ 119 // mod. banco
    //P010
    arquivo += this.funcaoService.strZero(this.dec(agrupar ? valoresAgrupados.valorTotal : item.valor_transferido, 2), 15); //120 ~ 134
    //G043
    arquivo += this.funcaoService.alinharEsquerda('', 20); //135 ~ 154
    //P003
    arquivo += this.funcaoService.strZero('0', 8); //155 ~ 162
    //P004
    arquivo += this.funcaoService.strZero(this.dec(0.0, 2), 15); //163 ~ 177
    //G031
    arquivo += this.funcaoService.alinharEsquerda('', 40); //178 ~ 217
    //P005
    arquivo += this.funcaoService.alinharEsquerda('', 2); //218 ~ 219
    //P011
    arquivo += this.funcaoService.alinharEsquerda('', 5); //220 ~ 224
    //P013
    arquivo += this.funcaoService.alinharEsquerda('', 2); //225 ~ 226
    //G004
    arquivo += this.funcaoService.alinharEsquerda('', 3); //227 ~ 229
    //P006
    arquivo += this.funcaoService.strZero('5', 1); //230 ~ 230
    //G059
    arquivo += this.funcaoService.alinharEsquerda('', 10); //231 ~ 240



    arquivo += '\n';
    return arquivo;
  }

  private segmentoB(item: TransferenciaBancaria, conta: ContaBancaria, lote: number, countSeg: number, agrupar: boolean, valoresAgrupados) {
    let arquivo = '';
    arquivo += this.funcaoService.strZero(conta.banco.febraban, 3); // 1 ~ 3
    //G002
    arquivo += this.funcaoService.strZero(lote, 4); // 4 ~ 7
    //G003
    arquivo += this.funcaoService.strZero(3, 1); // 8 ~ 8
    //G038
    arquivo += this.funcaoService.strZero(countSeg, 5); // 9 ~ 13
    //G039
    arquivo += this.funcaoService.alinharEsquerda('B', 1); // 14 ~ 14
    //G004
    arquivo += this.funcaoService.alinharEsquerda("", 3); // 15 ~ 17
    //G005
    let tipoInscricao = 2
    arquivo += this.funcaoService.strZero(tipoInscricao, 1); // 18 ~ 18
    //G006
    arquivo += this.funcaoService.strZero(this.num(item.conta_recebedora.conta_entidade?.cnpj ?? item.conta_recebedora.orgao.cnpj), 14); // 19 ~ 32
    //G032
    arquivo += this.funcaoService.alinharEsquerda(item.conta_recebedora.orgao.endereco.split(',')[0], 30); // 33 ~ 62
    //G032
    arquivo += this.funcaoService.strZero(item.conta_recebedora.orgao.endereco.split(',')[1], 5); // 63 ~ 67
    //G032
    arquivo += this.funcaoService.alinharEsquerda('', 15); // 68 ~ 82
    //G032
    arquivo += this.funcaoService.alinharEsquerda(item.conta_recebedora.orgao.bairro, 15); // 83 ~ 97
    //G033
    arquivo += this.funcaoService.alinharEsquerda(item.orgao.cidade.nome, 20); // 98 ~ 117
    //G034
    arquivo += this.funcaoService.alinharEsquerda(this.num(item.conta_recebedora.orgao.cep.substring(0, 5)), 5); // 118 ~ 122
    //G035
    arquivo += this.funcaoService.alinharEsquerda(item.conta_recebedora.orgao.cep.substring(5, 8), 3); // 123 ~ 125
    //G036
    arquivo += this.funcaoService.alinharEsquerda(item.orgao.cidade.estado.uf, 2); // 126 ~ 127
    //G044
    arquivo += this.funcaoService.strZero(this.funcaoService.converteDataBR(item.data_transfere).split('/',).join(''), 8); // 128 ~ 135
    //G042
    arquivo += this.funcaoService.strZero(this.dec(agrupar ? valoresAgrupados.valorTotal : item.valor_transferido, 2), 15); // 136 ~ 150
    //G045
    arquivo += this.funcaoService.strZero(this.dec(0.0, 2), 15); // 151 ~ 165
    //G046
    arquivo += this.funcaoService.strZero(this.dec(0.0, 2), 15); // 166 ~ 180
    //G047
    arquivo += this.funcaoService.strZero(this.dec(0.0, 2), 15); // 181 ~ 195
    //G048
    arquivo += this.funcaoService.strZero(this.dec(0.0, 2), 15); // 196 ~ 210
    //P008
    arquivo += this.funcaoService.alinharEsquerda(`TRB ${item.id}`, 15); // 211 ~ 225
    //P006
    arquivo += this.funcaoService.alinharEsquerda("", 1); // 226 ~ 226 // mod banco #4323/34
    //P012
    arquivo += this.funcaoService.alinharEsquerda("", 6); // 227 ~ 232 // mod banco #4323/34
    //G004
    arquivo += this.funcaoService.alinharEsquerda("", 8); // 233 ~ 240
    arquivo += '\n';
    return arquivo;
  }




  public carregarFuncionario(codigo: number): string {
    switch (codigo) {
      case 1:
        return "Prefeituras";
      case 2:
        return "Saneamento";
      case 3:
        return "Energia Elétrica e Gás";
      case 4:
        return "Telecomunicações";
      case 5:
        return "Órgãos Governamentais";
      case 6:
        return "Carnes e Assemelhados ou demais";
      case 7:
        return "Multas de trânsito";
      case 9:
        return " Uso exclusivo do banco ";
      default:
        return '';
    }
  }

  private retornaValor(obj: any, campo: string) {
    if (!obj || !campo) return '';

    if (campo.includes('.')) {
      return this.retornaValor(obj[campo.substr(0, campo.indexOf('.'))], campo.substr(campo.indexOf('.') + 1))
    } else
      return obj[campo]
  }

  private separarDv(text: string) {
    if (!text) {
      return ["0", ""];
    }
    text = text.trim();
    let n = "";
    let innum = true;
    let num = '';
    for (let i = 0; i < text.length; i++) {
      let c = text.charAt(i);
      if (c >= '0' && c <= '9' || c == 'X' || c == 'x') {
        num += c;
      } else if (c == '-' || c == '/' || c == '\\' || c == ' ') {
        if (innum) {
          n = num.toString();
          innum = false;
          num = '';
        }
      }
    }
    if (innum) {
      return [num.toString(), ""];
    } else {
      return [n, num.toString()];
    }
  }

  dec(valor: number, casasDecimais: number) {
    if (valor == null) {
      valor = 0.00;
    }

    let value = (+valor).toFixed(2);
    let dotIdx = value.indexOf(".");
    let sb = '';
    sb += value.replace(/\./g, "");
    if (dotIdx == -1) {
      dotIdx = 0;
      for (let i = 0; i < casasDecimais - dotIdx; i++) {
        sb += "0";
      }
    } else {
      let posDotLength = value.substring(dotIdx + 1).length;
      if (posDotLength > casasDecimais) {
        sb.slice(sb.length - (posDotLength - casasDecimais), sb.length);
      } else {
        for (let i = 0; i < casasDecimais - posDotLength; i++) {
          sb += "0";
        }
      }
    }
    return sb.toString();
  }

}
