import { DatePipe } from '@angular/common';
import { Boleto, FavorecidoService, FuncaoService, GlobalService, Liquidacao, Login, Relatorio } from 'eddydata-lib';
import { Responsavel } from '../util';
import JsBarCode from 'jsbarcode';

export class GpsLiquidacao {

  protected datepipe: DatePipe;
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  protected favorecidoService: FavorecidoService;

  prefeito: Responsavel;
  contador: Responsavel;
  tesoureiro: Responsavel;

  private log: Login;
  private logomarca: string;

  constructor(logomarca: string) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.datepipe = new DatePipe('pt');
    this.logomarca = logomarca;
  }

  public imprimir(model: Liquidacao[], login: Login) {
    this.log = login;

    Relatorio.imprimirPersonalizado('GPS', login.usuario.nome, login.usuario.sobrenome,
      login.orgao.nome, login.brasao,
      this.montarConteudo(model),
      'portrait', 'GPS - Guia Previdência Social',
      {
        linhas: {
          hLineWidth(i, node) {
            return 1;
          },
          vLineWidth(i) {
            return 1;
          },
          hLineColor(i) {
            return 'black';
          },
          paddingLeft(i) {
            return 3;
          },
          paddingRight(i, node) {
            return 3;
          }
        }
      }, true);
  }

  private montarConteudo(lista: Liquidacao[]) {
    const conteudo = [];

    for (const entidade of lista) {
      if (conteudo.length > 0) {
        conteudo.push([{ text: '', pageBreak: 'after' }]);
      }
      conteudo.push(this.dadosCabecalho(this.log, entidade));
      conteudo.push(this.dadosGRD(entidade));

      conteudo.push(this.dadosCabecalho(this.log, entidade));
      conteudo.push(this.dadosGRD(entidade));
    }
    return conteudo;
  }

  private dadosCabecalho(log: Login, dados: any): {} {
    let brasaoImage: {};
    if (this.logomarca) {
      brasaoImage = {
        image: this.logomarca,
        width: 60,
        alignment: 'center',
        border: [true, true, false, false],
        margin: [0, 2, 0, 20]
      };
    } else {
      brasaoImage = { width: 60, margin: [0, 10, 0, 51], text: '',  border: [true, true, false, true], };
    }

    const mes = new Date(dados.data_vencimento).getUTCMonth() + 1;
    const ano = new Date(dados.data_vencimento).getUTCFullYear();

    return [{
      columns: [
        {
          columns: [
            {
              width: 80,
              table: {
                widths: ['*'],
                body: [
                  [brasaoImage],
                ]
              }
            },
            {
              width: '*',
              table: {
                widths: ['*'],
                body: [
                  [{ text: 'MINISTÉRIO DA PREVIDÊNCIA SOCIAL - MPS', bold: true, alignment: 'center', fontSize: 11, border: [false, true, true, false] }],
                  [{ text: 'INSTITULO NACIONAL DO SEGURO SOCIAL - INSS', bold: true, alignment: 'center', fontSize: 11, border: [false, false, true, false] }],
                  [{ text: 'GUIA DA PREVIDÊNCIA SOCIAL - GPS', bold: true, alignment: 'center', fontSize: 13, border: [false, false, true, false], margin: [0, 0, 0, 6] }],
                ]
              }
            }],
        },
        {
          width: 180,
          table: {
            widths: [60, '*'],
            body: [
              [{ text: '3 CÓDIGO DE PAGAMENTO', alignment: 'left', fontSize: 6, border: [false, true, true, false], margin: [0, 3, 0, 3] }, { text: dados.gps, bold: true, alignment: 'left', fontSize: 8, border: [false, true, true, false], margin: [0, 3, 0, 3] }],
              [{ text: '4 COMPETÊNCIA', alignment: 'left', fontSize: 6, border: [false, true, true, false], margin: [0, 3, 0, 3] }, { text: mes + '/' + ano, bold: true, alignment: 'left', fontSize: 8, border: [false, true, true, false], margin: [0, 3, 0, 3] }],
              [
                { text: '5 IDENTIFICADOR', alignment: 'left', fontSize: 6, border: [false, true, true, false], margin: [0, 3, 0, 3] },
                { text: this.funcaoService.mascarar("##.###.###/####-##", dados.liquidacao.empenho.favorecido.cpf_cnpj), bold: true, alignment: 'left', fontSize: 8, border: [false, true, true, false], margin: [0, 3, 0, 3] }],
            ]
          }

        }
      ]
    }];
  }

  private dadosGRD(dados: any): {}[] {
    const barra = this.montarBoleto(dados);
    const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
    JsBarCode(canvas, barra.codigo, { displayValue: false, format: 'ITF', width: 4, height: 150 });
    const img = canvas.toDataURL();

    return [{
      columns: [
        {
          width: '*',
          border: [true, false, true, false],
          margin: [0, 0, 0, 25],
          table: {
            widths: ['*'],
            body: [
              [{ text: '1 NOME OU RAZÃO SOCIAL/FONE/ENDEREÇO', bold: true, alignment: 'left', fontSize: 6, border: [true, true, true, false] }],
              [{ text: `${dados.liquidacao.empenho.favorecido.nome}`, bold: true, alignment: 'left', fontSize: 7, border: [true, false, true, false] }],
              [{ text: `${dados.liquidacao.empenho.favorecido.endereco}, ${dados.liquidacao.empenho.favorecido.num_endereco} ${dados.liquidacao.empenho.favorecido.bairro}`, alignment: 'left', fontSize: 7, border: [true, false, true, false] }],
              [{ text: `${dados.liquidacao.empenho.favorecido.municipio} TELEFONE:\t ${dados.liquidacao.empenho.favorecido.telefone}`, alignment: 'left', fontSize: 7, border: [true, false, true, false] }],
              [{ text: `${dados.liquidacao.documento}`, alignment: 'left', fontSize: 7, border: [true, false, true, false] }],
              [{ text: `${this.funcaoService.convertToBrNumber(dados.liquidacao.valor_liquidado)}`, alignment: 'left', fontSize: 7, border: [true, false, true, false] }],
              [{ text: `TOMADORA:\t ${this.log.orgao.nome.toUpperCase()}`, alignment: 'left', fontSize: 7, border: [true, false, true, false] }],
              [
                {
                  border: [true, true, true, true],
                  table: {
                    widths: [60, '*'],
                    body: [
                      [
                        { text: '2 VENCIMENTO\n(Uso do INSS)', bold: true, alignment: 'left', fontSize: 6, border: [false, false, true, false] },
                        { text: this.funcaoService.converteDataBR(dados.data_vencimento), bold: true, alignment: 'left', fontSize: 10, border: [false, false, false, false], margin: [0, 5, 0, 5] }
                      ]
                    ]
                  }
                }
              ],
              [
                {
                  text: 'ATENÇÃO: É vedado a utilização de GPS para recolhimento de receita de valor inferior ao estipulado em Resolução publicada pelo INSS. A receita que resultar valor inferior deverá ser adicionada à contribuição ou importância correspondente nos meses subsequentes, até que o total seja igual ou superior ao valor mínimo fixado',
                  alignment: 'left', fontSize: 7, border: [true, false, true, true]
                }
              ],
              [
                {
                  text: `${barra.campo1} ${barra.campo2} ${barra.campo3} ${barra.campo4}`,
                  alignment: 'left', fontSize: 8, border: [false, false, false, false],
                  margin: [0, 5, 0, 0]
                }
              ],
              [
                {
                  image: img,
                  width: 280,
                  height: 50,
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0]
                }
              ]
            ]
          }
        },
        {
          width: 180,
          table: {
            widths: [60, '*'],
            body: [
              [{ text: '6 VALOR DO INSS', alignment: 'left', fontSize: 6, border: [false, true, true, false], margin: [0, 5, 0, 5] }, { text: this.funcaoService.convertToBrNumber(+dados.valor_retido), bold: true, alignment: 'left', fontSize: 8, border: [false, true, true, false], margin: [0, 5, 0, 5] }],
              [{ text: '7 ', alignment: 'left', fontSize: 6, border: [false, true, true, false], margin: [0, 3, 0, 3] }, { text: '', bold: true, alignment: 'left', fontSize: 8, border: [false, true, true, false], margin: [0, 3, 0, 3] }],
              [{ text: '8 ', alignment: 'left', fontSize: 6, border: [false, true, true, false], margin: [0, 3, 0, 3] }, { text: '', bold: true, alignment: 'left', fontSize: 8, border: [false, true, true, false], margin: [0, 3, 0, 3] }],
              [{ text: '9 VALOR DE OUTRAS ENTIDADES', alignment: 'left', fontSize: 6, border: [false, true, true, false], margin: [0, 5, 0, 7] }, { text: '', bold: true, alignment: 'left', fontSize: 8, border: [false, true, true, false], margin: [0, 5, 0, 7] }],
              [{ text: '10 ATM, MULTA E JUROS', alignment: 'left', fontSize: 6, border: [false, true, true, false], margin: [0, 5, 0, 8] }, { text: '', bold: true, alignment: 'left', fontSize: 8, border: [false, true, true, false], margin: [0, 5, 0, 8] }],
              [
                { text: '11 VALOR TOTAL', alignment: 'left', fontSize: 6, border: [false, true, true, true], margin: [0, 5, 0, 19] },
                { text: this.funcaoService.convertToBrNumber(+dados.valor_retido), bold: true, alignment: 'left', fontSize: 8, border: [false, true, true, true], margin: [0, 5, 0, 19] }
              ]
            ]
          }
        }
      ]
    }];
  }

  private montarBoleto(dados: any) {
    const boleto = new Boleto();
    boleto.setValorBoleto(dados.valor_retido);
    boleto.setUfSacado('SP');
    boleto.setParcela('UNICA');
    boleto.setMoeda('9');
    boleto.setLocalPagamento('PAGAVEL EM QUALQUER AGENCIA ATE O VENCIMENTO');
    boleto.setEspecieDocumento('R$');
    boleto.setEspecie('R$');
    boleto.setEnderecoSacado(dados.liquidacao.empenho.favorecido.endereco);
    boleto.setDvContaCorrente('1');
    boleto.setDvAgencia('4');
    boleto.setDocumento(dados.id);
    boleto.setDataVencimento(dados.data_vencimento);
    boleto.setDataProcessamento(dados.data_liquidacao);
    boleto.setDataDocumento(dados.data_liquidacao);
    boleto.setCpfSacado(dados.liquidacao.empenho.favorecido.cpf_cnpj);
    boleto.setContaCorrente('');
    boleto.setCidadeSacado(dados.liquidacao.empenho.favorecido.municipio);
    boleto.setCepSacado(dados.liquidacao.empenho.favorecido.cep);
    boleto.setCedente(dados.liquidacao.empenho.favorecido.cpf_cnpj);
    boleto.setCarteira('0270');
    boleto.setBairroSacado(dados.liquidacao.empenho.favorecido.bairro);
    boleto.setAgencia('000');
    boleto.setAcrescimo(0);
    boleto.setAceite('N');
    const gps = dados.gps ? dados.gps : '0000';
    const mes = new Date(dados.data_vencimento).getUTCMonth() + 1;
    const ano = new Date(dados.data_vencimento).getUTCFullYear();
    let codbarra = `858${boleto.getValorTitulo()}0270${gps}${this.funcaoService.strZero(boleto.getCpfSacado(), 14)}${this.funcaoService.strZero(`${mes}${ano}`, 6)}1`;
    const dv = boleto.getModulo11(codbarra, 7);
    codbarra = `858${dv}${boleto.getValorTitulo()}0270${gps}${this.funcaoService.strZero(boleto.getCpfSacado(), 14)}${this.funcaoService.strZero(`${mes}${ano}`, 6)}1`;
    const campo1 = codbarra.substring(0, 11) + "-" + boleto.getModulo11(codbarra.substring(0, 11), 7);
    const campo2 = codbarra.substring(11, 22) + "-" + boleto.getModulo11(codbarra.substring(11, 22), 7);
    const campo3 = codbarra.substring(22, 33) + "-" + boleto.getModulo11(codbarra.substring(22, 33), 7);
    const campo4 = codbarra.substring(33) + "-" + boleto.getModulo11(codbarra.substring(33), 7);
    return { codigo: codbarra, campo1: campo1, campo2: campo2, campo3: campo3, campo4: campo4 };
  }

}
