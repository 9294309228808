import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { ContratoLstComponent } from './contrato-lst/contrato-lst.component';
import { ContratoRoutingModule } from './contrato-routing.module';
import { ContratoShwComponent } from './contrato-shw/contrato-shw.component';

@NgModule({
  declarations: [
    ContratoLstComponent,
    ContratoShwComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ContratoRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
    IMaskModule,
    ToastModule
  ],
  exports: [
    ContratoLstComponent,
    ContratoShwComponent
  ]
})
export class ContratoModule { }
