import { Component, Input } from "@angular/core";
import { BaseResourceRptComponent, Coluna, CpfPipe, GlobalService, Licitacao, LicitacaoPipe, Memorial, PregaoHistorico, SituacaoProposta } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";
import { PregaoHistoricoService } from "../../licitacao/service/pregao-historico.service";

@Component({
  selector: 'app-lances-rpt',
  templateUrl: './lances-rpt.component.html'
})
export class LancesRptComponent extends BaseResourceRptComponent {

  @Input() public licitacao: Licitacao;

  private situacoesProposta: { id: SituacaoProposta, nome: string, style: string, ordem: number }[];

  constructor(
    private globalService: GlobalService,
    private historicoService: PregaoHistoricoService
  ) {
    super();
  }

  protected afterInit(): void {
    this.situacoesProposta = this.globalService.obterSituacoesProposta();
  }

  protected tituloRelatorio(): string {
    return `${this.login.orgao.nome} - Quadro de Lances`;
  }

  protected subTituloRelatorio(): string {
    return `Licitação: ${new LicitacaoPipe().transform(this.licitacao.numero)} - Processo: ${new LicitacaoPipe().transform(this.licitacao.processo)}\nModalidade: ${this.licitacao.modalidade.nome}`;
  }

  protected obterColunasRelatorio(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Item:', coluna: 'item', bold: true, agrupar: true });
    retorno.push({ titulo: 'Cronômetro', coluna: 'cronometro' });
    retorno.push({ titulo: 'Horário', coluna: 'horario' });
    retorno.push({ titulo: 'Descrição', coluna: 'descricao' });
    retorno.push({ titulo: 'Favorecido', coluna: 'favorecido' });
    retorno.push({ titulo: 'Rodada', coluna: 'rodada', decimais: 0, alignment: 'right' });
    retorno.push({ titulo: 'Lance', coluna: 'lance', decimais: 0, alignment: 'right' });
    retorno.push({ titulo: 'Valor', coluna: 'valor', decimais: 4, alignment: 'right' });
    retorno.push({ titulo: 'Situação', coluna: 'situacao', bold: true, alignment: 'center' });

    return retorno;
  }

  protected larguraColunas(): (string | number)[] {
    return ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'];
  }

  protected totalizarColunas(): (string | {})[] {
    return undefined;
  }

  protected carregarLista(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const lista: {
        item: string,
        cronometro: string,
        horario: string,
        descricao: string,
        favorecido: string,
        lance: number,
        rodada: number,
        valor: number,
        situacao: string,
      }[] = [];
      this.historicoService.obterPorLicitacao(this.licitacao.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          const reg: PregaoHistorico[] = res.content;
          reg.forEach(h => {
            lista.push({
              item: `${h.lance.memorial.ordem} ${h.lance.memorial.descricao} ${h.lance.memorial.unidade} ${this.informacaoCota(h.lance.memorial)}`,
              cronometro: h.cronometro,
              horario: this.funcaoService.converteDataHoraBR(h.data_cadastro),
              descricao: h.descricao,
              favorecido: `${new CpfPipe().transform(h.lance.proponente.favorecido.cpf_cnpj)} - ${h.lance.proponente.favorecido.nome}`,
              lance: h.lance.lance,
              rodada: h.lance.rodada,
              valor: h.lance.valor,
              situacao: this.situacoesProposta.find(p => p.id === h.lance.situacao).nome,
            })
          });

          resolve(lista);
        })
    });
  }
  
  private informacaoCota(item: Memorial): string {
    if (!item || !item.cota || item.cota === 'NAO_DEFINIDO') return ''
    if (item.cota === 'PRINCIPAL') return '(Cota Principal)'
    if (item.cota === 'RESERVADO') return '(Cota Reservada)'
  }
}