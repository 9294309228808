import { Injectable, OnDestroy } from '@angular/core';
import { AnexoLoaService } from '../service/anexo-loa.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuncaoService, LoginContabil, Exercicio, Relatorio, GlobalService } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class AnexoUnidadeAtuacao implements OnDestroy {
  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLoaService) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // tslint:disable: max-line-length
  public montarRelatorio(exercicio: Exercicio, orgaos: number[]) {
    this.anexoServico.obterAnexoUnidadeAtuacao(exercicio.id, orgaos, this.login.ppa.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        Relatorio.imprimirPersonalizado(
          'CAMPO DE ATUAÇÃO DAS UNIDADES ORÇAMENTARIAS'
          , this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          this.conteudo(dados, exercicio),
          'portrait', 'CAMPO_ATUACAO_UNIDADES',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private conteudo(dados: any[], exercicio: Exercicio): {}[] {    
    // monta o cabecalho
    const registros: {}[] = [[{
      text: `EXERCÍCIO ${exercicio.ano}`, fontSize: 11,
      alignment: 'center', bold: true, colSpan: 2, border: [true, true, true, false]
    }, '']
    ];
    
    const dados_agrupados = this.funcaoService.agrupar(dados, ['atuacao', 'legislacao', 'executora', 'orgao', 'unidade'])

    for (const item of dados_agrupados) {
      registros.push(
        [{
          text: this.funcaoService.mascarar('##.##.## *', item.grupo['orgao'] as string), fontSize: 9,
          bold: true, colSpan: 2, border: [true, true, true, false], margin: [0, 5, 0, 0]
        }, ''],
        [{
          text: this.funcaoService.mascarar('##.##.## *', item.grupo['unidade'] as string), fontSize: 9,
          bold: true, colSpan: 2, border: [true, false, true, false],
        }, ''],
        [{
          text: this.funcaoService.mascarar('##.##.## *', item.grupo['executora'] as string), fontSize: 9,
          bold: true, colSpan: 2, border: [true, false, true, false], margin: [0, 0, 0, 5]
        }, ''],
        [{
          text: 'CAMPO DE ATUAÇÃO DA UNIDADE',
          alignment: 'center',
          bold: true, fontSize: 8
        }, {
          text: 'LEGISLAÇÃO',
          alignment: 'center',
          bold: true, fontSize: 8
        }]        
      );
      registros.push([
        {
          text: `${item.grupo['atuacao']}`, fontSize: 8, border: [true, false, false, true]
        },
        {
          text: `${item.grupo['legislacao']}`, fontSize: 8, border: [true, false, true, true]
        }        
      ]);          
      registros.push([{
        text: '', colSpan: 2, border: [false, false, false, false], margin: [0, 0, 0, 10]
      }, '']);
    }
    return [{
      layout: 'linhas',
      table: {        
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*'],
        body: registros
      }
    }];
  }


}
