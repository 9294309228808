import { OnDestroy } from "@angular/core";
import { ExercicioService, FormatoExportacao, FuncaoService, GlobalService, Login, ProgressoService, Relatorio } from "eddydata-lib";
import { ParametroEmpenhosNaoLiquidados, ProgramaFinanceiraService } from "projects/contabil/src/app/planejamento/relatorio-gerenciais/service/programacao-financeira.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as toastr from 'toastr';
import { tsXLXS } from 'ts-xlsx-export';

interface EmpenhoNaoLiquidado {
    numero_empenho: number;
    data_empenho: Date;
    vencimento_empenho?: Date;
    numero_rcms?: number;
    nome_favorecido: string;
    numero_ficha_despesa: number;
    codigo_executora: string;
    codigo_despesa: string;
    codigo_aplicacao_variavel: string;
    nome_aplicacao_variavel: string;
    empenhado: string;
    liquidado: string;
    pago: string;
}

export class EmpenhosNaoLiquidados implements OnDestroy {

    protected unsubscribe: Subject<void> = new Subject();
    private login: Login;

    constructor(
        private progFinanceiraService: ProgramaFinanceiraService,
        private progressoService: ProgressoService,
        private funcaoService: FuncaoService,
        private globalService: GlobalService,
        private exercicioService: ExercicioService
    ) {
        this.login = GlobalService.obterSessaoLogin();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public async montarRelatorio(params: ParametroEmpenhosNaoLiquidados, agrupamento: string, modelo: FormatoExportacao) {
        const titulo = 'LISTAGEM DE EMPENHOS ORÇAMENTARIOS NÃO LIQUIDADOS';
        const exercicio = await this.exercicioService.obterId(params.exercicio_id).toPromise();
        const subtitulo = `PERÍODO ${params.mes ? `DO MÊS DE ${this.globalService.obterMes(params.mes)}` : `01/01/${exercicio.ano} À ${this.funcaoService.converteDataBR(params.data_fim)}`}`;

        this.progFinanceiraService.empenhosNaoLiquidados(params)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(res => {
                this.progressoService.show(res, (dados: EmpenhoNaoLiquidado[]) => {
                    let conteudo = null;

                    if (modelo === 'pdf' && agrupamento === '1') {
                        conteudo = this.montarConteudoPdf(dados);
                    } else if (modelo === 'pdf' && agrupamento === '2') {
                        conteudo = this.montarConteudoAgrupadoPdf(dados);
                    } else if (modelo === 'xlsx' && agrupamento === '1') {
                        conteudo = this.montarConteudoXlsx(dados);
                    } else if (modelo === 'xlsx' && agrupamento === '2') {
                        conteudo = this.montarConteudoAgrupadoXlsx(dados);
                    }

                    if (modelo === 'pdf') {
                        Relatorio.imprimirPersonalizado(titulo, this.login.usuario.nome, this.login.usuario.sobrenome,
                            this.login.orgao.nome, this.login.brasao, conteudo, 'landscape', titulo,
                            {
                                linhas: {
                                    hLineWidth() {
                                        return 1;
                                    },
                                    vLineWidth() {
                                        return 1;
                                    },
                                    hLineColor() {
                                        return 'black';
                                    },
                                    paddingLeft() {
                                        return 3;
                                    },
                                    paddingRight() {
                                        return 3;
                                    }
                                }
                            }, false, false, null, subtitulo);
                    } else if (modelo === 'xlsx') {
                        tsXLXS().exportAsExcelFile(conteudo).saveAsExcelFile(titulo);
                    }

                });
            });
    }

    private montarConteudoPdf(dados: EmpenhoNaoLiquidado[]): {}[] {
        if (!dados.length) {
            toastr.info('Dados não encontrados');
            return [];
        }

        // Cabeçalho
        const cabecalho = [
            { text: 'DATA', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'EMP.', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'RCMS', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'VENCTO.', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'FORNECEDOR', fontSize: 8, alignment: 'left', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'UNIDADE', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'FICHA', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'DESPESA', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'RECURSO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'EMPENHADO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'LIQUIDADO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'PAGO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'EM ABERTO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
        ];

        const conteudo = [];

        conteudo.push(cabecalho);

        let totalEmpenhado = 0;
        let totalLiquidado = 0;
        let totalPago = 0;
        let totalEmAberto = 0;

        // Conteúdo do relatório
        for (const item of dados) {
            totalEmpenhado += +item.empenhado;
            totalLiquidado += +item.liquidado;
            totalPago += +item.pago;
            totalEmAberto += +item.empenhado - +item.liquidado;

            conteudo.push([
                { text: this.funcaoService.converteDataBR(item.data_empenho), fontSize: 8, alignment: 'center', bold: false, border: [true, true, true, true] },
                { text: item.numero_empenho, fontSize: 8, alignment: 'center', bold: false, border: [true, true, true, true] },
                { text: item.numero_rcms, fontSize: 8, alignment: 'center', bold: false, border: [true, true, true, true] },
                { text: item.vencimento_empenho ? this.funcaoService.converteDataBR(item.vencimento_empenho) : '', fontSize: 8, alignment: 'center', bold: false, border: [true, true, true, true] },
                { text: item.nome_favorecido, fontSize: 8, alignment: 'left', bold: false, border: [true, true, true, true] },
                { text: this.funcaoService.mascarar('##.##.##', item.codigo_executora), fontSize: 8, alignment: 'center', bold: false, border: [true, true, true, true] },
                { text: item.numero_ficha_despesa, fontSize: 8, alignment: 'center', bold: false, border: [true, true, true, true] },
                { text: item.codigo_despesa, fontSize: 8, alignment: 'center', bold: false, border: [true, true, true, true] },
                { text: item.codigo_aplicacao_variavel, fontSize: 8, alignment: 'center', bold: false, border: [true, true, true, true] },
                { text: this.funcaoService.convertToBrNumber(+item.empenhado), fontSize: 8, alignment: 'right', bold: false, border: [true, true, true, true] },
                { text: this.funcaoService.convertToBrNumber(+item.liquidado), fontSize: 8, alignment: 'right', bold: false, border: [true, true, true, true] },
                { text: this.funcaoService.convertToBrNumber(+item.pago), fontSize: 8, alignment: 'right', bold: false, border: [true, true, true, true] },
                { text: this.funcaoService.convertToBrNumber(+item.empenhado - +item.liquidado), fontSize: 8, alignment: 'right', bold: false, border: [true, true, true, true] },
            ]);
        }

        // Linha geral
        conteudo.push([
            { text: 'TOTAL GERAL...', fontSize: 8, alignment: 'left', bold: true, colSpan: 9, border: [true, true, true, true] },
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            { text: this.funcaoService.convertToBrNumber(totalEmpenhado), fontSize: 8, alignment: 'right', bold: true, border: [true, true, true, true] },
            { text: this.funcaoService.convertToBrNumber(totalLiquidado), fontSize: 8, alignment: 'right', bold: true, border: [true, true, true, true] },
            { text: this.funcaoService.convertToBrNumber(totalPago), fontSize: 8, alignment: 'right', bold: true, border: [true, true, true, true] },
            { text: this.funcaoService.convertToBrNumber(totalEmAberto), fontSize: 8, alignment: 'right', bold: true, border: [true, true, true, true] },
        ]);

        return [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                headerRows: 1,
                widths: ['auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                body: conteudo
            }
        }];
    }

    private montarConteudoAgrupadoPdf(dados: EmpenhoNaoLiquidado[]): {}[] {
        if (!dados.length) {
            toastr.info('Dados não encontrados');
            return [];
        }

        // Cabeçalho
        const cabecalho = [
            { text: 'DATA', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'EMP.', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'RCMS', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'VENCTO.', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'FORNECEDOR', fontSize: 8, alignment: 'left', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'UNIDADE', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'FICHA', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'DESPESA', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'EMPENHADO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'LIQUIDADO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'PAGO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
            { text: 'EM ABERTO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
        ];

        const conteudo = [];

        conteudo.push(cabecalho);

        const grupoRecursos = this.funcaoService.agrupar(dados, ['codigo_aplicacao_variavel', 'nome_aplicacao_variavel'], ['empenhado', 'liquidado', 'pago']);

        let totalEmpenhado = 0;
        let totalLiquidado = 0;
        let totalPago = 0;
        let totalEmAberto = 0;

        for (const gpRecurso of grupoRecursos) {
            // Linha de grupo
            conteudo.push([
                { text: `${gpRecurso.grupo['codigo_aplicacao_variavel']} ${gpRecurso.grupo['nome_aplicacao_variavel']}`, fontSize: 8, alignment: 'left', bold: true, colSpan: 12, border: [true, true, true, true] },
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
            ]);

            // Conteúdo do relatório
            for (const item of gpRecurso.registros) {
                conteudo.push([
                    { text: this.funcaoService.converteDataBR(item.data_empenho), fontSize: 8, alignment: 'center', bold: false, border: [true, true, true, true] },
                    { text: item.numero_empenho, fontSize: 8, alignment: 'center', bold: false, border: [true, true, true, true] },
                    { text: item.numero_rcms, fontSize: 8, alignment: 'center', bold: false, border: [true, true, true, true] },
                    { text: item.vencimento_empenho ? this.funcaoService.converteDataBR(item.vencimento_empenho) : '', fontSize: 8, alignment: 'center', bold: false, border: [true, true, true, true] },
                    { text: item.nome_favorecido, fontSize: 8, alignment: 'left', bold: false, border: [true, true, true, true] },
                    { text: this.funcaoService.mascarar('##.##.##', item.codigo_executora), fontSize: 8, alignment: 'center', bold: false, border: [true, true, true, true] },
                    { text: item.numero_ficha_despesa, fontSize: 8, alignment: 'center', bold: false, border: [true, true, true, true] },
                    { text: item.codigo_despesa, fontSize: 8, alignment: 'center', bold: false, border: [true, true, true, true] },
                    { text: this.funcaoService.convertToBrNumber(+item.empenhado), fontSize: 8, alignment: 'right', bold: false, border: [true, true, true, true] },
                    { text: this.funcaoService.convertToBrNumber(+item.liquidado), fontSize: 8, alignment: 'right', bold: false, border: [true, true, true, true] },
                    { text: this.funcaoService.convertToBrNumber(+item.pago), fontSize: 8, alignment: 'right', bold: false, border: [true, true, true, true] },
                    { text: this.funcaoService.convertToBrNumber(+item.empenhado - +item.liquidado), fontSize: 8, alignment: 'right', bold: false, border: [true, true, true, true] },
                ]);
            }

            const totalEmAbertoRecurso = gpRecurso.totalizadores['empenhado'] - gpRecurso.totalizadores['liquidado'];

            totalEmpenhado += gpRecurso.totalizadores['empenhado'];
            totalLiquidado += gpRecurso.totalizadores['liquidado'];
            totalPago += gpRecurso.totalizadores['pago'];
            totalEmAberto += totalEmAbertoRecurso;

            // Linha total do recurso
            conteudo.push([
                { text: 'TOTAL DO RECURSO', fontSize: 8, alignment: 'left', bold: true, colSpan: 8, border: [true, true, true, true], margin: [0, 5, 0, 5] },
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                { text: this.funcaoService.convertToBrNumber(gpRecurso.totalizadores['empenhado']), fontSize: 8, alignment: 'right', bold: true, border: [true, true, true, true], margin: [0, 5, 0, 5] },
                { text: this.funcaoService.convertToBrNumber(gpRecurso.totalizadores['liquidado']), fontSize: 8, alignment: 'right', bold: true, border: [true, true, true, true], margin: [0, 5, 0, 5] },
                { text: this.funcaoService.convertToBrNumber(gpRecurso.totalizadores['pago']), fontSize: 8, alignment: 'right', bold: true, border: [true, true, true, true], margin: [0, 5, 0, 5] },
                { text: this.funcaoService.convertToBrNumber(totalEmAbertoRecurso), fontSize: 8, alignment: 'right', bold: true, border: [true, true, true, true], margin: [0, 5, 0, 5] },
            ]);
        }

        // Linha geral
        conteudo.push([
            { text: 'TOTAL GERAL...', fontSize: 8, alignment: 'left', bold: true, colSpan: 8, border: [true, true, true, true] },
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            { text: this.funcaoService.convertToBrNumber(totalEmpenhado), fontSize: 8, alignment: 'right', bold: true, border: [true, true, true, true] },
            { text: this.funcaoService.convertToBrNumber(totalLiquidado), fontSize: 8, alignment: 'right', bold: true, border: [true, true, true, true] },
            { text: this.funcaoService.convertToBrNumber(totalPago), fontSize: 8, alignment: 'right', bold: true, border: [true, true, true, true] },
            { text: this.funcaoService.convertToBrNumber(totalEmAberto), fontSize: 8, alignment: 'right', bold: true, border: [true, true, true, true] },
        ]);

        return [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                headerRows: 1,
                widths: ['auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                body: conteudo
            }
        }];
    }

    private montarConteudoXlsx(dados: EmpenhoNaoLiquidado[]): {}[] {
        if (!dados.length) {
            toastr.info('Dados não encontrados');
            return [];
        }

        let totalEmpenhado = 0;
        let totalLiquidado = 0;
        let totalPago = 0;
        let totalEmAberto = 0;

        const conteudo = [];

        // Conteúdo do relatório
        for (const item of dados) {
            totalEmpenhado += +item.empenhado;
            totalLiquidado += +item.liquidado;
            totalPago += +item.pago;
            totalEmAberto += +item.empenhado - +item.liquidado;

            const entity = {
                'DATA': this.funcaoService.converteDataBR(item.data_empenho),
                'EMP.': item.numero_empenho,
                'RCMS': item.numero_rcms,
                'VENCTO.': item.vencimento_empenho ? this.funcaoService.converteDataBR(item.vencimento_empenho) : '',
                'FORNECEDOR': item.nome_favorecido,
                'UNIDADE': this.funcaoService.mascarar('##.##.##', item.codigo_executora),
                'FICHA': item.numero_ficha_despesa,
                'DESPESA': item.codigo_despesa,
                'RECURSO': item.codigo_aplicacao_variavel,
                'EMPENHADO': this.funcaoService.convertToBrNumber(+item.empenhado),
                'LIQUIDADO': this.funcaoService.convertToBrNumber(+item.liquidado),
                'PAGO': this.funcaoService.convertToBrNumber(+item.pago),
                'EM ABERTO': this.funcaoService.convertToBrNumber(+item.empenhado - +item.liquidado),
            }

            conteudo.push(entity);
        }

        // Linha geral
        conteudo.push({
            'DATA': 'TOTAL GERAL...',
            'EMP.': '',
            'RCMS': '',
            'VENCTO.': '',
            'FORNECEDOR': '',
            'UNIDADE': '',
            'FICHA': '',
            'DESPESA': '',
            'RECURSO': '',
            'EMPENHADO': this.funcaoService.convertToBrNumber(totalEmpenhado),
            'LIQUIDADO': this.funcaoService.convertToBrNumber(totalLiquidado),
            'PAGO': this.funcaoService.convertToBrNumber(totalPago),
            'EM ABERTO': this.funcaoService.convertToBrNumber(totalEmAberto),
        });

        return conteudo;
    }

    private montarConteudoAgrupadoXlsx(dados: EmpenhoNaoLiquidado[]): {}[] {
        if (!dados.length) {
            toastr.info('Dados não encontrados');
            return [];
        }

        const conteudo = [];

        const grupoRecursos = this.funcaoService.agrupar(dados, ['codigo_aplicacao_variavel', 'nome_aplicacao_variavel'], ['empenhado', 'liquidado', 'pago']);

        let totalEmpenhado = 0;
        let totalLiquidado = 0;
        let totalPago = 0;
        let totalEmAberto = 0;

        for (const gpRecurso of grupoRecursos) {
            // Linha de grupo
            conteudo.push({
                'DATA': `${gpRecurso.grupo['codigo_aplicacao_variavel']} ${gpRecurso.grupo['nome_aplicacao_variavel']}`,
                'EMP.': '',
                'RCMS': '',
                'VENCTO.': '',
                'FORNECEDOR': '',
                'UNIDADE': '',
                'FICHA': '',
                'DESPESA': '',
                'RECURSO': '',
                'EMPENHADO': '',
                'LIQUIDADO': '',
                'PAGO': '',
                'EM ABERTO': '',
            });

            // Conteúdo do relatório
            for (const item of gpRecurso.registros) {
                const entity = {
                    'DATA': this.funcaoService.converteDataBR(item.data_empenho),
                    'EMP.': item.numero_empenho,
                    'RCMS': item.numero_rcms,
                    'VENCTO.': item.vencimento_empenho ? this.funcaoService.converteDataBR(item.vencimento_empenho) : '',
                    'FORNECEDOR': item.nome_favorecido,
                    'UNIDADE': this.funcaoService.mascarar('##.##.##', item.codigo_executora),
                    'FICHA': item.numero_ficha_despesa,
                    'DESPESA': item.codigo_despesa,
                    'RECURSO': item.codigo_aplicacao_variavel,
                    'EMPENHADO': this.funcaoService.convertToBrNumber(+item.empenhado),
                    'LIQUIDADO': this.funcaoService.convertToBrNumber(+item.liquidado),
                    'PAGO': this.funcaoService.convertToBrNumber(+item.pago),
                    'EM ABERTO': this.funcaoService.convertToBrNumber(+item.empenhado - +item.liquidado),
                }

                conteudo.push(entity);
            }

            const totalEmAbertoRecurso = gpRecurso.totalizadores['empenhado'] - gpRecurso.totalizadores['liquidado'];

            totalEmpenhado += gpRecurso.totalizadores['empenhado'];
            totalLiquidado += gpRecurso.totalizadores['liquidado'];
            totalPago += gpRecurso.totalizadores['pago'];
            totalEmAberto += totalEmAbertoRecurso;

            // Linha total do recurso
            conteudo.push({
                'DATA': 'TOTAL DO RECURSO',
                'EMP.': '',
                'RCMS': '',
                'VENCTO.': '',
                'FORNECEDOR': '',
                'UNIDADE': '',
                'FICHA': '',
                'DESPESA': '',
                'RECURSO': '',
                'EMPENHADO': this.funcaoService.convertToBrNumber(gpRecurso.totalizadores['empenhado']),
                'LIQUIDADO': this.funcaoService.convertToBrNumber(gpRecurso.totalizadores['liquidado']),
                'PAGO': this.funcaoService.convertToBrNumber(gpRecurso.totalizadores['pago']),
                'EM ABERTO': this.funcaoService.convertToBrNumber(totalEmAbertoRecurso),
            });
        }

        // Linha geral
        conteudo.push({
            'DATA': 'TOTAL GERAL...',
            'EMP.': '',
            'RCMS': '',
            'VENCTO.': '',
            'FORNECEDOR': '',
            'UNIDADE': '',
            'FICHA': '',
            'DESPESA': '',
            'RECURSO': '',
            'EMPENHADO': this.funcaoService.convertToBrNumber(totalEmpenhado),
            'LIQUIDADO': this.funcaoService.convertToBrNumber(totalLiquidado),
            'PAGO': this.funcaoService.convertToBrNumber(totalPago),
            'EM ABERTO': this.funcaoService.convertToBrNumber(totalEmAberto),
        });

        return conteudo;
    }
}