export type Audesp4ExigenciaGarantiaSim = {
    ValorGarantia: number;
    ValorGarantiaPercentualContrato: number;
    ClausulasGarantia: string;
}

export type Audesp4GestorDoContratoSim = {
    GestorCPF: string;
    GestorCargo?: string;
}

export type Audesp4VigenciaPorData = {
    InicioVigenciaDt: string;
    FimVigenciaDt: string;
}