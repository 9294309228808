import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseResourceFormComponent, ContaBancaria, Contrato, DateFormatPipe, EddyAutoComplete, FuncaoService, GlobalService, LoginContabil, Orgao, OrgaoService, Recurso, TransferenciaBancaria } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { TransferenciaBancariaService, ContaBancariaService, RecursoService, ContratoService } from 'administrativo-lib';
import { ContaBancariaSaldoService } from '../../conta-bancaria/service/conta-bancaria-saldo.service';
import { DatePipe } from '@angular/common';
import { TransferenciaEspecieService } from '../../transferencia-especie/service/transferencia-especie.service';
import { TransferenciaDescendialService } from '../service/transferencia-descendial.service';
import * as toastr from 'toastr';
import { TransferenciaPrevistaService } from '../../../transferencia-prevista/service/transferencia-prevista.service';

declare var $: any;
@Component({
  selector: 'app-transferencia-bancaria-form',
  templateUrl: './transferencia-bancaria-form.component.html'
})
export class TransferenciaBancariaFormComponent extends BaseResourceFormComponent<TransferenciaBancaria, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @ViewChild('especie_') inputField: ElementRef;

  public orgaoPagadora: Orgao;
  public orgaoRecebedora: Orgao;
  public pagadoraAutoComplete: EddyAutoComplete<ContaBancaria>;
  public recebedoraAutoComplete: EddyAutoComplete<ContaBancaria>;
  public contratoAutoComplete: EddyAutoComplete<Contrato>;
  public listaEspecies: Array<any>;
  public listaOrgaos: Orgao[];
  public saldoPagadora = 0;
  public saldoRecebedora = 0;
  public totalDescendio = 0;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public convenioAutoComplete: EddyAutoComplete<Recurso>;
  public recursoRecebedoraAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoRecebedoraAutoComplete: EddyAutoComplete<Recurso>;
  public convenioRecebedoraAutoComplete: EddyAutoComplete<Recurso>;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private messageService: MessageService,
    protected injector: Injector,
    protected globalService: GlobalService,
    protected recursoService: RecursoService,
    protected funcaoService: FuncaoService,
    protected contaService: ContaBancariaService,
    protected contaSaldoService: ContaBancariaSaldoService,
    protected especieService: TransferenciaEspecieService,
    protected orgaoService: OrgaoService,
    protected transferenciaPrevistaService: TransferenciaPrevistaService,
    protected transferenciaService: TransferenciaBancariaService,
    protected descendioService: TransferenciaDescendialService,
    protected contratoService: ContratoService,
  ) {
    super(new TransferenciaBancaria(), injector, TransferenciaBancaria.converteJson, transferenciaService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      data_transfere: [null, [Validators.required]],
      data_referencia: [null],
      valor_transferido: 0,
      mes: [null],
      aux: [0],
      especie: [null, [Validators.required]],
      documento: ['TRANSFERÊNCIA', [Validators.required]],
      historico: ['TRANSFERÊNCIA BANCÁRIA', [Validators.required, Validators.minLength(2)]],
      tipo: [1, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      conta_pagadora: [null, [Validators.required]],
      conta_recebedora: [null, [Validators.required]],
      recurso_pagadora: [null],
      aplicacao_pagadora: [null],
      convenio_pagadora: [null],
      recurso_recebedora: [null],
      aplicacao_recebedora: [null],
      convenio_recebedora: [null],
      contrato: [null],
      transferencia_retencao_item: [null],
      usuario_cadastro: [this.login.usuario.id, [Validators.required]],
      exportado_febraban: [false],
      exportado_obn: [false],
      // inativo: [false]
      descendial: [null],
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'conta_pagadora,conta_pagadora.banco,especie,transferencia_retencao_item,transferencia_retencao_item.retencao,transferencia_retencao_item.retencao_extra,transferencia_retencao_item.retencao_resto,' +
        'conta_pagadora.orgao,conta_recebedora,conta_recebedora.orgao,conta_recebedora.banco,conta_recebedora.entidade,conta_pagadora.entidade,recurso_pagadora,convenio_pagadora,aplicacao_pagadora' +
        ',recurso_recebedora,convenio_recebedora,aplicacao_recebedora,orgao.cidade,transferencia_autorizacao,contrato'
    };
  }

  protected afterLoad() {
    if (!this.podeAlterarAudesp(this.entidade.data_transfere)) {
      this.router.navigate(['/transferencias-bancarias']);
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return;
    }

    this.entidade.data_transfere = new DateFormatPipe().transform(this.entidade.data_transfere, []);
    this.entidade.data_referencia = new DateFormatPipe().transform(this.entidade.data_referencia, []);

    this.recebedoraAutoComplete.id = this.entidade.conta_recebedora ? this.entidade.conta_recebedora.codigo : null;
    this.pagadoraAutoComplete.id = this.entidade.conta_pagadora ? this.entidade.conta_pagadora.codigo : null;
    this.contratoAutoComplete.id = this.entidade.contrato ? this.entidade.contrato.id : null;

    this.recursoAutoComplete.id = this.entidade.recurso_pagadora ? this.entidade.recurso_pagadora.id : null;
    this.convenioAutoComplete.id = this.entidade.convenio_pagadora ? this.entidade.convenio_pagadora.id : null;
    this.aplicacaoAutoComplete.id = this.entidade.aplicacao_pagadora ? this.entidade.aplicacao_pagadora.id : null;

    this.recursoRecebedoraAutoComplete.id = this.entidade.recurso_recebedora ? this.entidade.recurso_recebedora.id : null;
    this.convenioRecebedoraAutoComplete.id = this.entidade.convenio_recebedora ? this.entidade.convenio_recebedora.id : null;
    this.aplicacaoRecebedoraAutoComplete.id = this.entidade.aplicacao_recebedora ? this.entidade.aplicacao_recebedora.id : null;

    this.orgaoPagadora = this.entidade.conta_pagadora.orgao;
    this.orgaoRecebedora = this.entidade.conta_recebedora.orgao;

    this.descendioService.filtrar(0, -1, { transferencia_id: this.entidade.id })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        for (const item of res.content) {
          this.totalDescendio += +item.valor_descendio;
        }
        this.buscarSaldoConta('P');
        this.buscarSaldoConta('R');
      });
  }

  protected afterInit(): void {
    this.orgaoPagadora = this.login.orgao;
    this.orgaoRecebedora = this.login.orgao;
    this.carregarContrato();
    this.carregarPagadora();
    this.carregarRecebedora();
    this.orgaoService.obterTodos()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaOrgaos = dados;
      });

    this.especieService.filtrar(0, -1, { ativo: true, orgao_id: this.login.orgao.id, orderBy: 'nome' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaEspecies = res.content;
      });


    if (this.currentActionRoute === 'novo') {
      this.transferenciaService.ultimaDataTransferencia(this.login.exercicio.id, this.login.orgao.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          if (res === 0) {
            this.entidade.data_transfere = new Date();
          } else {
            this.entidade.data_transfere = new DateFormatPipe().transform(res, []);
          }
          this.entidadeForm.get('data_transfere').setValue(this.entidade.data_transfere);
          this.entidadeForm.get('data_referencia').setValue(this.entidadeForm.get('data_transfere').value);
        });
    }
    this.carregarAutoCompletes();
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected beforeSubmit() {
    try {
      if (!this.entidadeForm.get('orgao').value['cidade']) {
        this.entidadeForm.get('orgao').patchValue({ ...this.login.orgao, cidade: this.login.cidade });
      }
      const dataTransfere = new DateFormatPipe().transform(this.entidadeForm.get('data_transfere').value, []);
      this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(dataTransfere)?.split('-')?.[1]);
      this.entidadeForm.get('aux').setValue(0);
      if (this.entidadeForm.get('conta_pagadora').value === this.entidadeForm.get('conta_recebedora').value) {
        throw new Error('A conta recebedora e pagadora são as mesma, selecione outra conta!');
      }
      if (+dataTransfere.getFullYear() !== this.login.exercicio.ano) {
        throw new Error('Data de transferência não pode ser diferente do exercício logado!');
      }
      if (!this.podeAlterarAudesp(dataTransfere)) {
        throw new Error('Prazo de inclusão/alteração excedido!');
      }
      //Removido a pedido do Edmar em conversa com o Zé Euripedes, contador de Franca
      // else if (this.entidadeForm.get('especie').value['nome'] === 'EDUCAÇÃO' && !this.entidadeForm.get('descendial').value) {
      //   $('#dialogDescendial').modal('show');
      //   throw new Error('É nessário informar as transferências descendiais, clique no botão "Transferência descendial"');
      // }
      if (+this.entidadeForm.get('valor_transferido').value <= 0) {
        throw new Error(`O valor da transferência deve ser maior que zero!`);
      }

      if (this.entidade?.id) {
        let disponivel = +this.saldoPagadora.toFixed(2) + +this.entidade.valor_transferido
        if (this.entidade.conta_pagadora.id !== this.entidadeForm.get('conta_pagadora').value.id || +disponivel < +this.entidadeForm.get('valor_transferido').value) {
          if ((+this.entidadeForm.get('valor_transferido').value) > (+this.saldoPagadora.toFixed(2))) {
            this.entidadeForm.get('valor_transferido').setValue(this.entidade.valor_transferido)
            throw new Error(`Não possui saldo na conta pagadora! \n Saldo Atual: R$ ${this.funcaoService.convertToBrNumber((+this.saldoPagadora.toFixed(2)))}`);
          }
        }
      } else {
        if ((+this.entidadeForm.get('valor_transferido').value) > (+this.saldoPagadora.toFixed(2))) {
          throw new Error(`Não possui saldo na conta pagadora! \n Saldo Atual: R$ ${this.funcaoService.convertToBrNumber((+this.saldoPagadora.toFixed(2)))}`);
        }
      }
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e.message });
      throw e;
    }
  }

  protected afterSubmit(ent: TransferenciaBancaria): void {
    if (this.currentActionRoute === 'novo') {
      this.router.navigate(['/transferencias-bancarias', ent.id, 'editar']);
    }
    this.buscarSaldoConta('P');
    this.buscarSaldoConta('R');
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public carregarPagadora() {
    this.entidadeForm.get('conta_pagadora').setValue(null);
    this.pagadoraAutoComplete = new EddyAutoComplete(this.entidadeForm.get('conta_pagadora'), this.contaService,
      'codigo', ['numero_conta', 'banco.nome', 'nome'], { orgao_id: this.orgaoPagadora.id, orderBy: 'nome', ativo: true, relations: 'orgao,banco,entidade' },
      { number: ['numero_conta', 'codigo'], text: ['nome'] },
      () => {
        this.buscarSaldoConta('P');
        this.geradorHistorico()
      }
    );
  }

  public carregarRecebedora() {
    this.entidadeForm.get('conta_recebedora').setValue(null);
    this.recebedoraAutoComplete = new EddyAutoComplete(this.entidadeForm.get('conta_recebedora'), this.contaService,
      'codigo', ['numero_conta', 'banco.nome', 'nome'], { orgao_id: this.orgaoRecebedora.id, orderBy: 'nome', ativo: true, relations: 'orgao,banco,entidade' },
      { number: ['numero_conta'], text: ['nome'] },
      () => {
        this.buscarSaldoConta('R');
        this.geradorHistorico()
      }
    );
  }

  public carregarContrato() {
    this.contratoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('contrato'), this.contratoService,
      'numero', ['numero'], {
      orgao_id: this.login.orgao.id, orderBy: 'numero',
      relations: 'tipo_contratacao'
    }, { text: ['numero'] }
    );
  }

  private buscarSaldoConta(tipo: 'P' | 'R') {
    const contaId = Number(tipo === 'P' ? this.entidadeForm.get('conta_pagadora').value['id'] : this.entidadeForm.get('conta_recebedora').value['id']);
    const orgaoId = Number(tipo === 'P' ? this.orgaoPagadora.id : this.orgaoRecebedora.id);
    if (contaId) {
      let datepipe: DatePipe = new DatePipe('pt');
      const dataTransfere = this.entidadeForm.get('data_transfere').value ? datepipe.transform(this.entidadeForm.get('data_transfere').value, 'yyyy-MM-dd', 'GMT') :
        datepipe.transform(this.entidade.data_transfere, 'yyyy-MM-dd', 'GMT');
      this.contaService.obterSaldoBancarioConta(contaId, this.login.exercicio.id, orgaoId, dataTransfere)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          if (tipo === 'P') {
            this.saldoPagadora = res;
          } else {
            this.saldoRecebedora = res;
          }
        });
    }
  }

  private carregarAutoCompletes() {
    // autocomplete para recurso
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'codigo,nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'codigo,nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para convenio
    this.convenioAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'codigo,nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para recurso
    this.recursoRecebedoraAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'codigo,nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoRecebedoraAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'codigo,nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para convenio
    this.convenioRecebedoraAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'codigo,nome' }, { text: ['codigo', 'nome'] }
    );
  }

  public voltar() {
    if (this.login.sistema === 'contabil') {
      this.router.navigate(['/tesouraria']);
    } else {
      this.sair();
    }
  }

  public geradorHistorico(): void {
    const bancoOrigem = this.entidadeForm.get('conta_pagadora').value || this.entidade.conta_pagadora;
    const bancoDestino = this.entidadeForm.get('conta_recebedora').value || this.entidade.conta_recebedora;
    const especie = this.entidadeForm.get('especie').value || this.entidade.especie;
    console.log(especie);
    let historico = '';

    if (especie) {
      if (bancoOrigem && bancoDestino) {
        historico = `Banco Origem: ${bancoOrigem?.codigo}\nBanco Destino: ${bancoDestino?.codigo} - ${especie?.nome}`
        this.entidadeForm.get('historico').setValue(historico)
      }
    } else {
      if (bancoOrigem && bancoDestino) {
        historico = `Banco Origem: ${bancoOrigem?.codigo}\nBanco Destino: ${bancoDestino?.codigo}`
        this.entidadeForm.get('historico').setValue(historico)
      }
    }
  }

  public carregarDescendio(event: any) {
    this.entidadeForm.get('descendial').setValue(event);
    this.totalDescendio = 0;
    for (const item of event) {
      if (item.selecionado) {
        this.totalDescendio += +item.valor_descendio;
      }
    }
  }

  public async verificarSaldo() {
    // Manutenção 186832 foi solicitado e autorizado por Damaris a remoção da validação
    // let duo = this.entidadeForm.get('especie').value
    // if (duo.duodecimo) {
    //   const dataTransfere = new DateFormatPipe().transform(this.entidadeForm.get('data_transfere').value, []);
    //   const mes = +this.funcaoService.converteDataSQL(dataTransfere)?.split('-')?.[1];

    //   let ehEventoDevolucao = ['DDC', 'DRP', 'DRC', 'DRE'].includes(duo.evento);

    //   //Transferencias ja feitas com a especia duodecimo no mes
    //   const todasTransferencias = ehEventoDevolucao
    //     ? [{ valor_transferido: 0 }]
    //     : (await this.transferenciaService.filtrar(1, -1, {
    //     'exercicio.id': this.login.exercicio.id,
    //     'mes': mes,
    //     'orgao.id': this.login.orgao.id,
    //     'especie.duodecimo': true
    //   }).toPromise()).content

    //   //Transferencias prevista no mes
    //   const todasPrevistas = (await this.transferenciaPrevistaService.filtrar(1, -1, {
    //     'exercicio.id': this.login.exercicio.id,
    //     'orgao.id': this.login.orgao.id,
    //     'referencia': mes,
    //     'recebedor.especie': 'C',
    //     'especie$in': ehEventoDevolucao ? ['R'] : ['I', 'A']
    //   }).toPromise()).content

    //   const totalVlrPrevisto = todasPrevistas.reduce((acumulador, trans) => acumulador += +trans?.valor_previsto || 0, 0) * (ehEventoDevolucao ? -1 : 1)
    //   const totalVlrTransferido = todasTransferencias.reduce((acumulador, trans) => acumulador += +trans?.valor_transferido || 0, 0);

    //   let soma = +this.entidadeForm.get('valor_transferido').value + totalVlrTransferido

    //   if (this.funcaoService.arredondar(soma, 2) > this.funcaoService.arredondar(totalVlrPrevisto, 2)) {
    //     toastr.error('Valor das transferências do mês, maior que valor previsto!\nAjuste o cronograma de transferência prevista!');
    //     return;
    //   }
    // }
  }

  protected async podeAlterar(entidade: TransferenciaBancaria): Promise<boolean> {
    return this.login.sistema != 'controle-interno'
  }
}
