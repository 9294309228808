// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Chamamento } from './chamamento.model';

export class ChamamentoPontuacao extends BaseResourceModel {
  constructor(
    public id?: number,
    public descricao?: string,
    public ponto_inicial?: number,
    public ponto_final?: number,
    public chamamento?: Chamamento,
    public editavel?: boolean
  ) {
    super();
  }
  static converteJson(json: any): ChamamentoPontuacao {
    return Object.assign(new ChamamentoPontuacao(), json);
  }
}
