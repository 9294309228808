import { Component, Input } from '@angular/core';
import {
  BaseResourceItemsComponent,
  Contrato,
  ContratoRescisao,
  DateFormatPipe,
  FuncaoService,
  GlobalService
} from 'eddydata-lib';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ContratoRescisaoService } from '../service/contrato-rescisao.service';

declare var $: any;

@Component({
  selector: 'lib-contrato-rescisao',
  templateUrl: './contrato-rescisao.component.html'
})
export class ContratoRescisaoComponent extends BaseResourceItemsComponent<ContratoRescisao> {

  @Input() public contrato: Contrato;

  public ptBR: any;
  public itemAtual: ContratoRescisao;
  public inclusao: boolean;
  public rescisao: boolean;
  @Input() visualizar: Boolean;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    protected globalService: GlobalService,
    public funcaoService: FuncaoService,
    private rescisaoService: ContratoRescisaoService) {
    super(new ContratoRescisao(), rescisaoService);
  }

  protected afterInit(): void {
    this.ptBR = this.globalService.obterDataBR();
  }

  protected validSave(item: ContratoRescisao): boolean {
    if (!item.data_rescisao) {
      toastr.warning('Informe a data da rescisão!');
      return false;
    }
    if (!item.motivo) {
      toastr.warning('Informe o motivo da rescisão!');
      return false;
    }
    return true;
  }

  protected beforeSave(item: ContratoRescisao): void { }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public adicionar() {
    this.itemAtual = new ContratoRescisao();
    this.itemAtual.data_rescisao = this.funcaoService.obterDataUTC(new Date());
    this.itemAtual.solicitante = 'CONTRATADO';
    this.itemAtual.tipo = 'BILATERAL';
    this.itemAtual.usuario = this.login.usuario;
    this.itemAtual.contrato = this.contrato;
    this.inclusao = true;
    $('#dialogRescisao').modal('show');
  }

  public editar(item: ContratoRescisao) {
    this.itemAtual = item;
    this.itemAtual.data_publicacao = new DateFormatPipe().transform(item.data_publicacao, []);
    this.itemAtual.data_rescisao = new DateFormatPipe().transform(item.data_rescisao, []);
    this.itemAtual.data_termo = new DateFormatPipe().transform(item.data_termo, []);
    $('#dialogRescisao').modal('show');
  }

  public atualizarLista() {
    this.rescisaoService.filtrar(0, -1, {
      'contrato.id': this.contrato.id,
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((data: any) => {
        this.lista = data ? data.content : new Array<ContratoRescisao>();
      }, error => this.funcaoService.acaoErro(error));
  }

  public podeVisualizar() {
    if (this.visualizar === true) {
      return true;
    }
    if(this.visualizar === false){
      return false;
    }
  }
}