// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Pessoa } from '../comum/pessoa.model';
import { MovimentoItemEstoque } from './movimento-item-estoque.model';

export class ReceitaFarmacia extends BaseResourceModel {
  constructor(
    public id?: number,
    public previsao_retirada?: Date,
    public data_receita?: Date,
    public qtd_retirada?: number,
    public qtd_receita?: number,
    public paciente?: Pessoa,
    public movimento_item?: MovimentoItemEstoque,
  ) {
    super();
  }

  static converteJson(json: any): ReceitaFarmacia {
    return Object.assign(new ReceitaFarmacia(), json);
  }
}
