import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, TipoContratacaoModalidade,  } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class TipoContratacaoModalidadeService extends BaseResourceService<TipoContratacaoModalidade> {

  constructor(
    protected injector: Injector
  ) {
    super(`tipo-contratacao-modalidade`, injector);
  }

}
