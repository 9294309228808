// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { ContratoChecklist } from './contrato-checklist.model';

export class AuditoriaContrato  extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_auditoria?: Date,
    public data_inicio?: Date,
    public data_termino?: Date,
    public ano?: number,
    public numero?: string,
    public objeto?: string,
    public conclusao?: string,
    public recomendacao?: string,
    public favorecido?: string,
    public valor_contrato?: number,
    public orgao?: Orgao,
    public observacao?: string,
    public cancelado?: boolean,
    public checklist?: ContratoChecklist[]
    ) {
      super();
    }
    static converteJson(json: any): AuditoriaContrato {
      return Object.assign(new AuditoriaContrato(), json);
    }
}
