import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ContaBancaria, Page } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContaBancariaService extends BaseResourceService<ContaBancaria> {

  constructor(
    protected injector: Injector
  ) {
    super(`contas-bancarias`, injector);
  }

  public obterPorNumeroConta(numero: string, agencia?: string, orgaoId?: number): Observable<Page> {
    const whereAgencia = agencia ? `&agencia=${agencia}` : '';
    if (!orgaoId) {
      orgaoId = this.login.orgao.id;
    }
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}?numero_conta$like=${numero}&${whereAgencia}&orgao_id=${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorNome(nome: string, orgaoId: number): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?nome$like=${nome.toUpperCase()}%&orgao.id=${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorContaId(contaId: number, orgaoId: number): Observable<Page> {

    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?codigo=${contaId}&relations=banco&orgao.id=${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterSaldoBancario(exercicioId: number, orgaoId: number, dataReferencia: string): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/saldo-bancario/${exercicioId}/${orgaoId}?data=${dataReferencia}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterSaldoCaixa(exercicioId: number, orgaoId: number, dataReferencia: string): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/saldo-caixa/${exercicioId}/${orgaoId}?data=${dataReferencia}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterSaldoBancarioConta(id: number, exercicioId: number, orgaoId: number, dataReferencia: string): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/saldo-bancario-conta/${id}/${exercicioId}/${orgaoId}?data=${dataReferencia}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterSaldoBancarioContaConciliacao(id: number, exercicioId: number, orgaoId: number, dataReferencia: string, ajuste_variacao?: boolean): Observable<number> {
    const considerarVariacoes = ajuste_variacao === true;
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/saldo-bancario-conta-conciliacao/${id}/${exercicioId}/${orgaoId}?data=${dataReferencia}&ajuste_variacao=${considerarVariacoes}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public boletimBanco(exercicioId: number, orgaoId: number, dataInicial: string, dataFinal: string, orderby: string, agrupar: string, buscaFonte?: number, listaRecurso?: boolean, ajusteVariacao?: boolean): Observable<any> {
    const parametros = `?data_inicial=${dataInicial}&data=${dataFinal}&ajuste_variacao=${ajusteVariacao}`;
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/boletim-banco/${exercicioId}/${orgaoId}/${orderby}/${agrupar}/${buscaFonte}/${listaRecurso}${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public disponbilidadeRecurso(exercicioId: number, orgaoId: number, dataInicial: string, dataFinal: string): Observable<any> {
    const parametros = `?data_inicial=${dataInicial}&data=${dataFinal}`;
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/disponibilidade-recursos/${exercicioId}/${orgaoId}${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public boletimCaixa(exercicioId: number, orgaoId: number, data: string, data_final: string, ajuste_variacao?: boolean): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/boletim-caixa/${exercicioId}/${orgaoId}/${data}/${data_final}?ajuste_variacao=${ajuste_variacao}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public conciliacaoBancaria(exercicioId: number, orgaoId: number, dataInicial: string, dataFinal: string, contaId?: number, tipo?: String, ajuste_variacao?: boolean, movimentos?: {}, codigoFonteRecurso?: string): Observable<any> {
    var parametros = `?data_inicial=${dataInicial}&data=${dataFinal}`;
    if (contaId) {
      parametros += `&conta=${contaId}`;
    }
    if (tipo) {
      parametros += `&tipo_conta=${tipo}`;
    }
    if (ajuste_variacao !== undefined && ajuste_variacao !== null) {
      parametros += `&ajuste_variacao=${ajuste_variacao}`;
    }
    for (const prop in movimentos) {
      if (Object.prototype.hasOwnProperty.call(movimentos, prop) && movimentos[prop]) {
        parametros += `&${prop}=${movimentos[prop]}`;
      }
    }
    if(codigoFonteRecurso) {
      parametros += `&codigo_fonte_recurso=${codigoFonteRecurso}`
    }
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/conciliacao-bancaria/${exercicioId}/${orgaoId}${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public listagemConciliacaoBancaria(exercicioId: number, orgaoId: number, mes: number, filtros?: {}): Observable<any> {
    var parametros = `?mes=${mes}`;
    for (const prop in filtros) {
      if (Object.prototype.hasOwnProperty.call(filtros, prop) && filtros[prop]) {
        parametros += `&${prop}=${filtros[prop]}`;
      }
    }
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/listagem-conciliacao-bancaria/${exercicioId}/${orgaoId}${parametros}`, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public domicilioBancario(exercicioId: number, orgaoId: number, mesReferencia: number, banco?: number, recurso?: string): Observable<any> {
    var parametros = `?mesReferencia=${mesReferencia}`;
    if (banco) {
      parametros += `&contaBancaria=${banco}`;
    }
    if (recurso) {
      parametros += `&contaRecurso=${recurso}`;
    }

    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/domicilio-bancario/${exercicioId}/${orgaoId}${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
