import { Component, Injector, OnDestroy } from '@angular/core';
import { Chart, ChartConfiguration, ChartData, ChartType, LegendItem } from 'chart.js';
import {
  BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, FuncaoService, Inventario, LoginContabil,
  ParametroPatrimonioService, ProgressoService
} from 'eddydata-lib';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { InventarioService } from '../service/inventario.service';

declare var $: any;

@Component({
  selector: 'app-inventario-list',
  templateUrl: './inventario-list.component.html'
})
export class InventarioListComponent extends BaseResourceListComponent<Inventario, LoginContabil> implements OnDestroy {

  public inventarioAtual: Inventario;
  public itemAtual: Inventario;
  public filtroSelecionado: string;
  public statusSelecionado: string;
  public dataInicial: Date;
  public dataFinal: Date;
  public ptBR: any;
  public tituloGrafico = 'Inventário Atual'
  public descricaoInventario: string;
  public excetoSetores = '';
  public visualizarInventario: boolean = false;
  public visualizarInventarioSituacao: boolean = false;
  public visualizarInventarioSecretaria: boolean = false;
  public visualizarSolicitacaoTransferenciaPendente: boolean = false;
  public visualizarTransferenciaPendente: boolean = false;
  public visualizarIdentificacaoBemPatrimonial: boolean = false;
  public grafInventario: {
    opt: ChartConfiguration['options'],
    dados: ChartData<'pie', number[], string | string[]>,
    tipo: ChartType
  };

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected injector: Injector,
    public funcaoService: FuncaoService,
    private inventarioService: InventarioService,
    private parametroPatrimonioService: ParametroPatrimonioService,
    protected progressoService: ProgressoService) {
    super(inventarioService, injector);
  }

  protected relations(): string {
    return 'orgao,setores.setor';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['data_cadastro$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['id'],
      text: ['descricao'],
    };
  }

  protected afterInit() {
    this.carregarEstatisticas();
    this.limparFiltros(true);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected acaoRemover(model: Inventario): Observable<Inventario> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Id', coluna: 'id' },
      { titulo: 'Descrição', coluna: 'descricao' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'orgao';
    this.inventarioService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE INVENTÁRIOS`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem inventários', ['auto', '*'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  public obterParametros(): {} {
    let parametros = this.inventarioService.obterPorFiltro(this.descricaoInventario, this.statusSelecionado, this.dataInicial, this.dataFinal)

    parametros = Object.assign({}, parametros, this.condicoesGrid());
    parametros['orderBy'] = this.ordenacaoGrid();

    return parametros;
  }

  aposMudar() {
    let dataInicio = this.dataInicial;
    let dataFim = this.dataFinal;
    this.limparFiltros(true);
    this.dataInicial = undefined;
    this.dataFinal = undefined;
    this.preencherGrid();
    this.dataInicial = dataInicio;
    this.dataFinal = dataFim;
  }

  public limparFiltros(todos: boolean) {
    this.filtroSelecionado = todos ? 'DESCRICAO' : this.filtroSelecionado;
    this.descricaoInventario = null;
    this.statusSelecionado = null;

    let ano = new Date().getFullYear()
    let mes = new Date().getMonth() + 1
    this.dataInicial = todos ? new Date(ano, mes - 1, 1) : this.dataInicial;
    this.dataFinal = todos ? new Date(ano, mes - 1, this.funcaoService.ultimoDiaMes(mes, ano)) : this.dataFinal;
  }

  public abrirConferenciaCad() {
    $('#dlgConferenciaCad').modal('show');
  }

  public abrirInventarioCad() {
    $('#dlgInventarioCad').modal('show');
  }

  public alterar(item: Inventario) {
    if (!item) return;
    this.itemAtual = item;
    this.abrirInventarioCad();
  }

  public statusSetor(item: Inventario) {
    if (item.status === 'CONCLUIDO') return;

    const setor = item.setores?.find(s => s.setor?.id === this.login.usuario.setor.id);

    return setor ? setor.status : 'PENDENTE';
  }

  public carregarEstatisticas(inv?: Inventario) {
    const parametros = { orgao_id: this.login.orgao.id};
    if(inv){
      parametros['id'] = inv.id;
      this.tituloGrafico = 'Inventário: ' + inv.descricao;
    }else{
      parametros['status'] = 'INICIADO';
    }
    
    if (this.excetoSetores) parametros['setores.setor.id$not_in'] = this.excetoSetores;
    this.inventarioService.obter(parametros).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.inventarioAtual = res;
        if (!this.inventarioAtual) return;

        this.inventarioService.obterEstatisticas(this.inventarioAtual.id).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            this.grafInventario = {
              opt: {
                responsive: true,
                plugins: { legend: { display: true, position: 'bottom',  labels:{generateLabels: function (chart) {
                  
                  const datasets = chart.data.datasets;
                  return datasets[0].data.map((data, i) => ({
                    text: `${chart.data.labels[i]}:  ${data}`,
                    fillStyle: datasets[0].backgroundColor[i],
                    index: i
                  })) as any;
                }, 
               }
              },
            },
            },
              tipo: 'pie',
              dados: {
                labels: res.map(x => x.status),
                datasets: [{
                  data: res.map(x => +x.total),
                  backgroundColor: ["#ff505b", "#fccc55", "#61ff57", "#f7f9f9"],
                  hoverBackgroundColor: ["#cc4048", "#c9a344", "#43b23c", "#acaeae"],
                  hoverBorderColor: ["#cc4048", "#c9a344", "#43b23c", "#acaeae"],
                }]
              }
            }
          });
      });
  }

  public iniciarInventario(item: Inventario) {
    if (!item) return;
    this.itemAtual = item;
    $('#dialogIniciarInventario').modal('show');
  }

  public async confirmarIniciarInventario() {
    if (!this.itemAtual) return;

    await new Promise((resolve, reject) => {
      this.inventarioService.iniciar(this.itemAtual.id).pipe(takeUntil(this.unsubscribe))
        .subscribe(idTransacao => {
          this.progressoService.show(idTransacao, (retorno) => {
            toastr.success('Inventário iniciado com sucesso!')
            this.aposMudar();
            resolve(true)
          });
        }, (error) => {
          toastr.error(error.error.payload, 'Não foi possível iniciar o inventário');
          reject(true)
        });
    }).then(() => {
      $('#dialogIniciarInventario').modal('hide');
    });
  }

  public finalizarInventario() {
    if (!this.inventarioAtual) return;
    $('#dialogFinalizarInventario').modal('show');
  }

  public async confirmarFinalizarInventario() {
    if (!this.inventarioAtual) return;

    await new Promise((resolve, reject) => {
      this.inventarioService.finalizar(this.inventarioAtual.id, this.login.usuario.id).pipe(takeUntil(this.unsubscribe))
        .subscribe(idTransacao => {
          this.progressoService.show(idTransacao, (retorno) => {
            toastr.success('Inventário finalizado com sucesso!')
            this.aposMudar();
            resolve(true)
          })
        }, (error) => {
          toastr.error(error.error.payload, 'Não foi possível finalizar o inventário');
          reject(true)
        })
    }).then(() => {
      $('#dialogFinalizarInventario').modal('hide');
    });
  }
}
