import { Component, Injector, Input } from '@angular/core';
import { BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, LoginContabil, ModeloRelatorio } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModeloRelatorioService } from '../service/modelo-relatorio.service';

declare var $: any;

@Component({
  selector: 'lib-modelo-relatorio-list',
  templateUrl: './modelo-relatorio-list.component.html'
})
export class ModeloRelatorioListComponent extends BaseResourceListComponent<ModeloRelatorio, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @Input() isComponent: boolean = false;

  public itemAtual: ModeloRelatorio;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private modeloService: ModeloRelatorioService) {
    super(modeloService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return '';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao_id']: this.login.orgao.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['data_cadastro$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      text: ['nome', 'modelo'],
      date: ['data_cadastro']
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: ModeloRelatorio): Observable<ModeloRelatorio> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'Fixo', coluna: 'fixo' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    this.modeloService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE MODELOS DE RELATÓRIOS `,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem modelos relatórios', ['*', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
  private abrirModeloRelatorioCad() {
    $('#dlgModeloRelatorioCad').modal('show');
  }

  public incluir() {
    this.itemAtual = new ModeloRelatorio();
    this.abrirModeloRelatorioCad();
  }

  public alterar(item: ModeloRelatorio) {
    if (!item) return;
    this.itemAtual = item;
    this.abrirModeloRelatorioCad();
  }

  public executar(item: ModeloRelatorio) {
    if (!item) return;
    this.itemAtual = item;
    $('#dlgModeloRelatorioRpt').modal('show');
  }
}
