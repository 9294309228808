// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { EmpenhoChecklist } from './empenho-checklist.model';

export class AuditoriaEmpenho  extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_auditoria?: Date,
    public data_empenho?: Date,
    public ano?: number,
    public numero?: number,
    public especie?: string,
    public despesa?: string,
    public acao?: string,
    public programa?: string,
    public historico?: string,
    public conclusao?: string,
    public recomendacao?: string,
    public observacao?: string,
    public favorecido?: string,
    public cancelado?: boolean,
    public valor_empenho?: number,
    public orgao?: Orgao,
    public checklist?: EmpenhoChecklist[]
    ) {
      super();
    }
    static converteJson(json: any): AuditoriaEmpenho {
      return Object.assign(new AuditoriaEmpenho(), json);
    }
}
