import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { PessoaService } from '../service/pessoa.service';
import { BaseResourceFormComponent } from '../../models/base-resource-form';
import { Pessoa } from '../../entidade/comum/pessoa.model';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { FuncaoService } from '../../util/funcao.service';
import { GlobalService } from '../../util/global.service';

@Component({
  selector: 'lib-pessoa-form',
  templateUrl: './pessoa-form.component.html'
})
export class PessoaFormComponent extends BaseResourceFormComponent<Pessoa, LoginContabil> implements OnInit {
  /**
   * Declaração de variáveis
   */
  @ViewChild('tipo_') inputField: ElementRef;
  public listaTipos: Array<any>;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected pessoaService: PessoaService) {
    super(new Pessoa(), injector, Pessoa.converteJson, pessoaService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      especie: [null],
      cpf_cnpj: [null],
      cartao: [0, [Validators.required]],
      sexo: [null],
      data_nascimento: [null],
      rg: [null],
      nome: [null],
      pis_pasep: [null],
      email: [null],
      telefone: [null],
      cep: [null],
      endereco: [null],
      numero: [null],
      bairro: [null],
      municipio: [null],
      cidade: [this.login.cidade, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'cidade' };
  }

  protected afterLoad() {
  }

  protected afterInit(): void {
    this.listaTipos = this.globalService.obterListaTipoFornecedores();
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected beforeSubmit() {
    try {
      if (!this.entidadeForm.get('especie').value) {
        throw new Error('Informe a espécie!');
      }
      if (!this.entidadeForm.get('cpf_cnpj').value) {
        throw new Error('Informe o cpf/cnpj!');
      }
      if (!this.entidadeForm.get('nome').value) {
        throw new Error('Informe o nome da pessoa!');
      }
      if (!this.entidadeForm.get('email').value) {
        throw new Error('Informe o e-mail!');
      }
      if (!this.entidadeForm.get('telefone').value) {
        throw new Error('Informe o telefone!');
      }
      if (!this.entidadeForm.get('cep').value) {
        throw new Error('Informe o CEP!');
      }
      if (!this.entidadeForm.get('endereco').value) {
        throw new Error('Informe o endereco!');
      }
      if (!this.entidadeForm.get('numero').value) {
        throw new Error('Informe o número!');
      }
      if (!this.entidadeForm.get('bairro').value) {
        throw new Error('Informe o bairro!');
      }
      if (!this.entidadeForm.get('municipio').value) {
        throw new Error('Informe o município!');
      }
      this.entidadeForm.get('pis_pasep').setValue('-');
      this.entidadeForm.get('rg').setValue('-');
      this.entidadeForm.get('sexo').setValue('N');
      this.entidadeForm.get('cidade').setValue(this.login.cidade);
      this.entidadeForm.get('orgao').setValue(this.login.orgao.id);
    } catch (e) {
      toastr.error(e.message);
      throw e;
    }
  }

  protected afterSubmit(ent: Pessoa) {
    window.scrollTo(0, 0);
    setTimeout(() => this.inputField.nativeElement.focus());
    if (this.limparTela) {
      this.entidade.cidade = this.login.cidade;
      this.entidadeForm.get('cidade').setValue(this.login.cidade);
      this.router.navigate(['/pessoas', 'novo']);
    }
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================


}
