import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, EventoContabil } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventoContabilService extends BaseResourceService<EventoContabil> {

  constructor(
    protected injector: Injector
  ) {
    super(`eventos-contabeis`, injector);
  }

  public proximoIdDisponivel(orgao: number, exercicio: number): Observable<number> {
    return this.http.get<number>(`${this.login.cidade.id}/${this.api}/proximo-id/${orgao}/${exercicio}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public validacaoContaContabil(filtros: {}): Observable<any> {
    let parametros = '';
    if (filtros) {

      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + filtros[key];
      }
    }

    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/validacao-conta-corrente${parametros}`,
      this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
