import { BaseResourceStorage } from '../../models/base-resource.storage';
import { Usuario } from '../comum/usuario.model';
import { Fatura } from './fatura.model';
export class FaturaHistorico extends BaseResourceStorage {
    constructor(
        public descricao?: string,
        public fatura?: Fatura,
        public usuario?: Usuario,
    ) {
        super();
    }

    static converteJson(json: any): FaturaHistorico {
        return Object.assign(new FaturaHistorico(), json);
    }
}
