import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Ajuste, ContratoAditamento, ContratoResponsavel, FuncaoService, LicitacaoAudesp, listarTipos, TermoAditivo } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContratoAditamentoService } from '../../../contrato/service/contrato-aditamento.service';
import { ContratoResponsavelService } from '../../../contrato/service/contrato-responsavel.service';
import { LicitacaoAudespService } from '../../service/licitacao-audesp.service';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';

@Component({
  selector: 'lib-licitacao-audesp-termo-aditivo',
  templateUrl: './licitacao-audesp-termo-aditivo.component.html'
})
export class LicitacaoAudespTermoAditivoComponent extends BaseLicitacaoAudesp implements OnInit, OnChanges, OnDestroy {

  @Input() public entidade: LicitacaoAudesp;
  @Input() public ajuste: LicitacaoAudesp;
  @Input() public aditivo: number;

  public arquivo: TermoAditivo;
  public listTipos: { id: number, nome: string }[];
  public listaAditivo: ContratoAditamento[];

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    public funcaoService: FuncaoService,
    public audespService: LicitacaoAudespService,
    public aditamentoService: ContratoAditamentoService,
    public responsavelContratoService: ContratoResponsavelService,
  ) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.entidade.id) {
        this.arquivo = this.entidade.arquivo as TermoAditivo;
      } else {
        if (!this.ajuste?.licitacao || !this.entidade) {
          this.iniciarObjeto();
        } else {
          this.importarInformacoes();
        }
      }
      this.carregarAditivos();
    }
  }

  ngOnInit(): void {
    this.iniciarObjeto();
    this.listTipos = listarTipos();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  private carregarAditivos() {
    if (this.ajuste?.arquivo) {
      const contrato: number = (JSON.parse(this.ajuste.arquivo) as Ajuste).contratoId
      this.aditamentoService.filtrar(0, 0, { contrato_id: contrato, relations: 'contrato,tipo' }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaAditivo = res.content;

          if (this.aditivo)
            this.arquivo.aditivoId = this.aditivo;
          else if (this.listaAditivo?.length === 1)
            this.arquivo.aditivoId = this.listaAditivo[0].id;

          this.atribuirAditivo();
        })
    }
  }

  private iniciarObjeto() {
    if (!this.entidade?.id){
      this.entidade.arquivo = new TermoAditivo();
    }
    this.arquivo = this.entidade.arquivo;
  }

  private importarInformacoes() {
    if (!this.ajuste?.licitacao || !this.entidade) return;

    this.iniciarObjeto();

    this.arquivo.CodigoAjuste = +this.ajuste.codigo;
    this.atribuirCodigo();
  }

  public atribuirAditivo() {
    if (this.arquivo.aditivoId) {
      const aditivo: ContratoAditamento = this.listaAditivo.find(c => c.id === this.arquivo.aditivoId)
      if (aditivo) {
        // identificação
        this.arquivo.TermoAditivoNumero = aditivo.numero;
        this.arquivo.TermoAditivoAno = aditivo.contrato.ano;
        this.arquivo.TermoAditivoValor = aditivo.valor_total;
        this.arquivo.TermoAditivoObjeto = aditivo.finalidade;
        this.arquivo.TermoAditivoTipo = [{ TipoTAditivo: aditivo?.tipo?.codigo }]

        // vigencia
        this.arquivo.TermoAditivoInicioVigenciaDt = this.funcaoService.converteDataBR(aditivo.data_inicio);
        this.arquivo.TermoAditivoFimVigenciaDt = this.funcaoService.converteDataBR(aditivo.data_termino);
        this.arquivo.TermoAditivoAssinaturaDt = this.funcaoService.converteDataBR(aditivo.data_aditamento);

        // financeiro

        // conclusao
        this.responsavelContratoService.filtrar(0, 0, { contrato_id: aditivo.contrato.id, relations: 'responsavel', ativo: true }).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            const responsaveis: ContratoResponsavel[] = res.content;
            if (responsaveis?.length === 0) return

            this.arquivo.TermoCienciaContratados = [];
            this.arquivo.TermoCienciaContratantes = [];
            responsaveis.forEach(r => {
              if (r.contratante) {
                this.arquivo.TermoCienciaContratantes.push({
                  CPF: r.responsavel.cpf_cnpj, Nome: r.responsavel.nome, EmailPessoal: r.responsavel.email,
                  EmailProfissional: r.responsavel.email_profissional, AssinouContrato: 'S', pessoaId: r.responsavel.id
                })
              } else {
                this.arquivo.TermoCienciaContratados.push({
                  CPF: r.responsavel.cpf_cnpj, Nome: r.responsavel.nome, EmailPessoal: r.responsavel.email,
                  EmailProfissional: r.responsavel.email_profissional, AssinouContrato: 'S', pessoaId: r.responsavel.id
                })
              }
            })
          });
      }
    }
  }

  public atribuirCodigo() {
    this.audespService.proximoCodigo('TERMO', this.login.exercicio.ano, this.login.orgao.id).subscribe(res => {
      if (res) this.arquivo.TermoAditivoCodigo = res;
    })
  }
}
