// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ReservaDotacaoItem } from './reserva-dotacao-item.model';

export class ReservaDotacaoEstorno extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_estorno?: Date,
    public data_baixa?: Date,
    public data_adjudicacao?: Date,
    public valor_estorno?: number,
    public valor_adjudicacao?: number,
    public observacao?: string,
    public reserva_item?: ReservaDotacaoItem,
  ) {
    super();
  }

  static converteJson(json: any): ReservaDotacaoEstorno {
    return Object.assign(new ReservaDotacaoEstorno(), json);
  }
}
