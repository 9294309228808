// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { ContaPublicaTipo } from './conta-publica-tipo.model';

export class ContaPublicaAnexo extends BaseResourceModel {
  constructor(
    public id?: number,
    public especie?: 'CP' | 'PLAN',
    public data_anexo?: Date,
    public ano?: number,
    public referencia?: string,
    public descricao?: string,
    public nome?: string,
    public caminho?: string,
    public tipo?: ContaPublicaTipo,
    public documento?: string,
    public tamanho?: number,
    public orgao?: Orgao,
    public aux?: number,
    public arquivo?: any,
    public orgao_id?: number
  ) {
    super();
  }

  static converteJson(json: any): ContaPublicaAnexo {
    return Object.assign(new ContaPublicaAnexo(), json);
  }
}
