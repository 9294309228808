import { Component, ElementRef, Injector } from '@angular/core';
import { GrupoEstoqueService } from 'almoxarifado-lib';
import { BaseResourceFormComponent, GlobalService, GrupoEstoque, LoginContabil } from 'eddydata-lib';

@Component({
  selector: 'lib-grupo-estoque-view',
  templateUrl: './grupo-estoque-view.component.html'
})
export class GrupoEstoqueViewComponent extends BaseResourceFormComponent<GrupoEstoque, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  readOnly: boolean;
  public mes: string;
  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected grupoService: GrupoEstoqueService,
    protected globalService: GlobalService
  ) {
    super(new GrupoEstoque(), injector, GrupoEstoque.converteJson, grupoService);
    this.readOnly = true;
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
  }

  protected parametrosExtras(): {} {
    return { relations: 'estoques.estoque,subgrupos' };
  }

  protected afterLoad() {
  }

  protected afterInit(): void {
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit() {
  }

  protected afterSubmit(modelo: GrupoEstoque) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
}
