import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AnexoLoaService } from '../service/anexo-loa.service';
import { FuncaoService, LoginContabil, Exercicio, Relatorio, GlobalService, Executora } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class PrevisaoDespesaLoa implements OnDestroy {

  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLoaService) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // tslint:disable: max-line-length
  public montarRelatorio(exercicio: Exercicio, orgaos: number[], quadrimestre: number, tipo_previsao: number, mes_inicial: number, mes_final: number, tribunal_executora: String, tribunal_unidade: String) {
    this.anexoServico.obterPrevisaoDespesa(exercicio.id, orgaos, quadrimestre, tipo_previsao, mes_inicial, mes_final, tribunal_executora, tribunal_unidade)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {

        Relatorio.imprimirPersonalizado(
          '', this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          this.conteudo(dados, exercicio, quadrimestre),
          'landscape', 'ANEXO PREVISAO DESPESA',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private conteudo(dados1: any[], exercicio: Exercicio, quadrimestre: number): {}[] {
    let mes1 = 'JANEIRO';
    let mes2 = 'FEVEREIRO';
    let mes3 = 'MARÇO';
    let mes4 = 'ABRIL';
    if (quadrimestre == 2) {
      mes1 = 'MAIO';
      mes2 = 'JUNHO';
      mes3 = 'JULHO';
      mes4 = 'AGOSTO';
    } else if (quadrimestre == 3) {
      mes1 = 'SETEMBRO';
      mes2 = 'OUTUBRO';
      mes3 = 'NOVEMBRO';
      mes4 = 'DEZEMBRO';
    }
    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `DEMONSTRATIVO DA PROGRAMAÇÃO FINANCEIRA / CRONOGRAMA DE EXECUÇÃO MENSAL DE DESEMBOLSO - EXERCÍCIO ${exercicio.ano} - L.C.101 art.8o.`,
        alignment: 'center', bold: true, colSpan: 15, border: [true, true, true, false]
      }, '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      [{
        text: 'DESPESAS',
        alignment: 'center',
        bold: true, fontSize: 7, border: [true, true, false, false],
      }, {
        text: 'DOTAÇÃO ANUAL',
        alignment: 'center',
        bold: true, fontSize: 7, colSpan: 2,
      }, '', {
        text: mes1,
        alignment: 'center',
        bold: true, fontSize: 7, colSpan: 3,
      }, '', '', {
        text: mes2,
        alignment: 'center',
        bold: true, fontSize: 7, colSpan: 3,
      }, '', '', {
        text: mes3,
        alignment: 'center',
        bold: true, fontSize: 7, colSpan: 3,
      }, '', '', {
        text: mes4,
        alignment: 'center',
        bold: true, fontSize: 7, colSpan: 3,
      }, '', ''],
      [{
        text: 'Categoria Econômica/Natureza',
        alignment: 'center', border: [true, false, false, true],
        bold: true, fontSize: 7
      }, {
        text: 'INICIAL',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'ATUALIZADA',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'PREVISÃO',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'EMPENHADO',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'DIFERENÇA',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'PREVISÃO',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'EMPENHADO',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'DIFERENÇA',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'PREVISÃO',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'EMPENHADO',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'DIFERENÇA',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'PREVISÃO',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'EMPENHADO',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'DIFERENÇA',
        alignment: 'center',
        bold: true, fontSize: 7
      }]
    ];

    // monta o agrupamento do relatório
    let totalDotacao = 0;
    let totalAtualizado = 0;
    let atualizadoEspecial = 0;
    let total1 = 0;
    let total2 = 0;
    let difer1 = 0;
    let total3 = 0;
    let total4 = 0;
    let difer2 = 0;
    let total5 = 0;
    let total6 = 0;
    let difer3 = 0;
    let total7 = 0;
    let total8 = 0;
    let difer4 = 0;
    for (const item of dados1[0]) {
      atualizadoEspecial = 0;
      atualizadoEspecial += +item.atualizado + +item.especial
      registros.push([
        {
          text: item.nome, bold: true, fontSize: 7, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.dotacao), alignment: 'right',
          bold: true, fontSize: 7, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(atualizadoEspecial), alignment: 'right',
          bold: true, fontSize: 7, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.mes1), alignment: 'right',
          bold: true, fontSize: 7, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.empenho1), alignment: 'right',
          bold: true, fontSize: 7, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(+item.mes1 - +item.empenho1), alignment: 'right',
          bold: true, fontSize: 7, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.mes2), alignment: 'right',
          bold: true, fontSize: 7, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.empenho2), alignment: 'right',
          bold: true, fontSize: 7, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(+item.mes2 - +item.empenho2), alignment: 'right',
          bold: true, fontSize: 7, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.mes3), alignment: 'right',
          bold: true, fontSize: 7, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.empenho3), alignment: 'right',
          bold: true, fontSize: 7, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(+item.mes3 - +item.empenho3), alignment: 'right',
          bold: true, fontSize: 7, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.mes4), alignment: 'right',
          bold: true, fontSize: 7, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.empenho4), alignment: 'right',
          bold: true, fontSize: 7, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(+item.mes4 - +item.empenho4), alignment: 'right',
          bold: true, fontSize: 7, border: [true, false, true, false]
        }
      ]);

      totalDotacao += +item.dotacao;
      totalAtualizado += +item.atualizado + +item.especial;
      total1 += +item.mes1;
      total2 += +item.empenho1;
      difer1 += +item.mes1 - +item.empenho1;
      total3 += +item.mes2;
      total4 += +item.empenho2;
      difer2 += +item.mes2 - +item.empenho2;
      total5 += +item.mes3;
      total6 += +item.empenho3;
      difer3 += +item.mes3 - +item.empenho3;
      total7 += +item.mes4;
      total8 += +item.empenho4;
      difer4 += +item.mes4 - +item.empenho4;
    }

    registros.push([
      {
        text: '',
        alignment: 'center',
        bold: true,
        colSpan: 15, border: [true, true, true, false]
      }, '', '', '', '', '', '', '', '', '', '', '', '', '', ''
    ]);

    registros.push([{
      text: 'TOTAL ',
      border: [true, false, true, true], fontSize: 7, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(totalDotacao), alignment: 'right',
      border: [true, false, true, true], fontSize: 7, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(totalAtualizado), alignment: 'right',
      border: [true, false, true, true], fontSize: 7, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total1), alignment: 'right',
      border: [true, false, true, true], fontSize: 7, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total2), alignment: 'right',
      border: [true, false, true, true], fontSize: 7, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(difer1), alignment: 'right',
      border: [true, false, true, true], fontSize: 7, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total3), alignment: 'right',
      border: [true, false, true, true], fontSize: 7, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total4), alignment: 'right',
      border: [true, false, true, true], fontSize: 7, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(difer2), alignment: 'right',
      border: [true, false, true, true], fontSize: 7, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total5), alignment: 'right',
      border: [true, false, true, true], fontSize: 7, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total6), alignment: 'right',
      border: [true, false, true, true], fontSize: 7, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(difer3), alignment: 'right',
      border: [true, false, true, true], fontSize: 7, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total7), alignment: 'right',
      border: [true, false, true, true], fontSize: 7, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total8), alignment: 'right',
      border: [true, false, true, true], fontSize: 7, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(difer4), alignment: 'right',
      border: [true, false, true, true], fontSize: 7, bold: true
    }
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45],
        body: registros
      }
    }];
  }

}
