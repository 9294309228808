import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FavorecidoModule } from 'administrativo-lib';
import { IMaskModule } from 'angular-imask';
import { PessoaModule, SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { FieldsetModule } from 'primeng/fieldset';
import { InputMaskModule } from 'primeng/inputmask';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { FavorecidoBuscaDlgComponent } from './favorecido-busca-dlg/favorecido-busca-dlg.component';
import { FavorecidoCadastroDlgComponent } from './favorecido-cadastro-dlg/favorecido-cadastro-dlg.component';

@NgModule({
  declarations: [FavorecidoBuscaDlgComponent, FavorecidoCadastroDlgComponent],
  exports: [FavorecidoBuscaDlgComponent, FavorecidoCadastroDlgComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    PessoaModule,
    RadioButtonModule,
    FieldsetModule,
    InputMaskModule,
    FavorecidoModule
  ],
  providers: [MessageService]
})
export class FavorecidoDlgModule { }
