import { TombamentoEmpenhoExtra } from './tombamento-empenho-extra.model';
import { ConservacaoPatrimonio, SituacaoPatrimonio, TipoDoacaoPatrimonio } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Produto } from '../almoxarifado/produto.model';
import { SetorLocalizacao } from '../almoxarifado/setor-localizacao.model';
import { Setor } from '../almoxarifado/setor.model';
import { Favorecido } from '../compra/favorecido.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { Empenho } from '../contabil/empenho.model';
import { PlanoConta } from '../contabil/plano-conta.model';
import { Veiculo } from '../frota/veiculo.model';
import { Depreciacao } from './depreciacao.model';
import { ReavaliacaoItem } from './reavaliacao-item.model';
import { TombamentoHistorico } from './tombamento-historico.model';
import { TombamentoKit } from './tombamento-kit.model';
import { MovimentoItemEstoque } from '../almoxarifado/movimento-item-estoque.model';

export class Tombamento extends BaseResourceModel {
  constructor(
    public id?: number,
    public placa?: number,
    public data_aquisicao?: Date,
    public data_baixa?: Date,
    public data_exclusao?: Date,
    public vencimento_seguro?: Date,
    public vencimento_garantia?: Date,
    public aux?: number,
    public serie?: string,
    public numero_apolice?: string,
    public marca?: string,
    public cor?: string,
    public medida?: string,
    public documento?: string,
    public aquisicao?: 'COMPRA' | 'PERMUTA' | 'DOACAO' | 'CESSAO' | 'IMPLANTACAO' | 'FABRICACAO' | 'REINTEGRACAO',
    public conservacao?: ConservacaoPatrimonio,
    public situacao?: SituacaoPatrimonio,
    public valor_aquisicao?: number,
    public valor_avaliacao?: number,
    public valor_depreciado?: number,
    public valor_atual?: number,
    public valor_residual?: number,
    public vida_util_meses?: number,
    public processo?: string,
    public motivo_exclusao?: string,
    public rfid?: string,
    public obs?: string,
    public usuario_cadastro?: Usuario,
    public usuario_exclusao?: Usuario,
    public empenho?: Empenho,
    public plano_conta?: PlanoConta,
    public setor?: Setor,
    public localizacao?: SetorLocalizacao,
    public kit?: TombamentoKit,
    public seguradora?: Favorecido,
    public favorecido?: Favorecido,
    public produto?: Produto,
    public orgao?: Orgao,
    public veiculo?: Veiculo,
    public historicos?: TombamentoHistorico[],
    public reavaliacoes?: ReavaliacaoItem[],
    public depreciacoes?: Depreciacao[],
    public editavel?: boolean,
    public movimento_item?: MovimentoItemEstoque,
    public empenhos?: TombamentoEmpenhoExtra[],
    public reativacao?: boolean,
    public controle_carga?: boolean,
    public tipo_doacao?: TipoDoacaoPatrimonio,
  ) {
    super();
  }

  static converteJson(json: any): Tombamento {
    return Object.assign(new Tombamento(), json);
  }
}
