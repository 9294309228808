import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Tombamento } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BemMovelHistoricoDlgService extends BaseResourceService<Tombamento> {

  constructor(
    protected injector: Injector
  ) {
    super(`tombamentos/historico`, injector);
  }

  public obterTombamentosHistorico(id: number): Observable<any> {
    return this.http.get<Tombamento>(
    `${this.login.cidade.id}/${this.api}/${id}`,
      this.httpOptions()).pipe(
          map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
