import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Convenio, ConvenioStorage, FuncaoService, Login, ProgressoService } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ConvenioStorageService } from '../service/convenio-storage.service';

declare var $: any;

@Component({
  selector: 'lib-convenio-storage',
  templateUrl: './convenio-storage.component.html',
})
export class ConvenioStorageComponent implements OnChanges {
  @Input() public login: Login;
  @Input() public entidade: Convenio;
  @Input() public arquivos: ConvenioStorage[];

  public uploadedFiles: any[] = [];
  public descricao: string = null;
  public visivelTransparenciaTodos: boolean = true;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected confirmationService: ConfirmationService,
    public funcaoService: FuncaoService,
    private storageService: ConvenioStorageService,
    protected progressoService: ProgressoService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.visivelTransparenciaTodos = true;

    let visivelTrans: number = 0;
    let invisivelTrans: number = 0;

    if (this.arquivos) {
      for (let arquivo of this.arquivos) {
        if (arquivo.visivel_transparencia) {
          visivelTrans++
        } else {
          invisivelTrans++
        }
      }

      if (invisivelTrans > visivelTrans) this.visivelTransparenciaTodos = false;
    }

    console.log(this.visivelTransparenciaTodos)
  }

  public async onUpload(event: any, fileUpload: any) {
    if (!event.files || event.files.length === 0) {
      toastr.warning('Selecione o(s) arquivo(s)');
      return;
    }
    try {
      for (const file of event.files) {
        this.uploadedFiles.push(file);
      }
      fileUpload.clear();
    } catch (ex) {
      toastr.error(ex.message ? ex.message : ex);
    }
  }

  public removerArquivoLista(file: any) {
    this.uploadedFiles.splice(this.uploadedFiles.indexOf(file), 1);
  }

  public async confirmarUpload() {
    this.storageService
      .upload(this.uploadedFiles, this.login.orgao.id, this.entidade.id, this.descricao)
      .pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
        for (const item of res) {
          item.data_cadastro = new Date();
          this.arquivos.unshift(item);
        }
        this.descricao = undefined;
        this.uploadedFiles = []
        $('#dialogUpload').modal('hide');
        toastr.success('Arquivo adicionado com sucesso!')
      }, (res) => {
        toastr.error('Erro ao salvar arquivo.');
        this.uploadedFiles.length = 0;
      });
  }

  public async remover(item: ConvenioStorage) {
    if (!item) return;

    this.confirmationService.confirm({
      message: 'Deseja realmente remover o item?',
      header: 'Exclusão',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.storageService.remover(item.id).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro removido com sucesso!', 'Exclusão');
            this.arquivos.splice(this.arquivos.indexOf(item), 1);
          }, (err) => toastr.error(err.error.payload));
      }
    });
  }

  public async toggleTransparencia(item: ConvenioStorage) {
    if (!item) return;
    item.visivel_transparencia = !item.visivel_transparencia;

    await new Promise((resolve, reject) => {
      this.storageService
        .atualizar(item).subscribe(res => {
          toastr.success('Arquivo atualizado com sucesso');
          resolve(true)
        }, error => {
          toastr.error(error.error.payload)
          reject(error)
        });
    })
  }

  public async removerTodos() {
    this.confirmationService.confirm({
      message: `Tem certeza de que deseja remover todos os arquivos? <br>
        Todos os arquivos serão permanentemente excluídos!<br>
        (Essa ação pode demorar um pouco)`,
      header: 'Remover Todos',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.storageService.removerTodos(this.entidade.id).pipe(takeUntil(this.unsubscribe))
          .subscribe((idTransacao) => {
            this.progressoService.show(idTransacao, (retorno) => {
              this.arquivos = [];
            })
          }, (err) => toastr.error(err.error.payload));
      }, reject: () => {
        toastr.info('Cancelado!');
      }
    });
  }

  public async toggleTransparenciaTodos() {
    this.confirmationService.confirm({
      message: `Tem certeza de que deseja ${this.visivelTransparenciaTodos ? 'Desmarcar' : 'Marcar'} 
        todos os arquivos como visíveis no portal de transparência? <br>
        Esta ação afetará todos os arquivos listados neste processo! <br>
        (Essa ação pode demorar um pouco)`,
      header: 'Marcar/Desmarcar Transparência',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: async () => {
        this.storageService.visuTransparenciaTodos(this.entidade.id, !this.visivelTransparenciaTodos)
          .pipe(takeUntil(this.unsubscribe)).subscribe((idTransacao) => {
            this.progressoService.show(idTransacao, (retorno) => {
              this.visivelTransparenciaTodos = !this.visivelTransparenciaTodos;

              for (let arquivo of this.arquivos) {
                arquivo.visivel_transparencia = this.visivelTransparenciaTodos;
              }
            })
          }, (err) => {
            console.log(err)
            toastr.error(err.error.payload)
          })
      }, reject: () => {
        toastr.info('Cancelado!');
      }
    });
  }

  public baixarTodos() {
    try {
      this.confirmationService.confirm({
        message: `Tem certeza de que deseja baixar todos os arquivos? <br>
          Esta ação pode levar algum tempo dependendo da quantidade de arquivos!<br>
          (Essa ação pode demorar um pouco)`,
        header: 'Download',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Confirmar',
        rejectLabel: 'Cancelar',
        accept: async () => {
          this.storageService.baixarTodos(this.entidade.id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(async (idTransacao) => {
              this.progressoService.show(idTransacao, async (retorno) => {
                this.storageService.downloadZip()
                  .pipe(takeUntil(this.unsubscribe))
                  .subscribe((res) => {
                    const blob = new Blob([res]);
                    const downloadURL = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = downloadURL;
                    link.download = 'arquivos_convenio.zip';
                    link.click();
                    window.URL.revokeObjectURL(downloadURL);
                  })
              })
            }, (error) => this.funcaoService.acaoErro(error))
        }, reject: () => {
          toastr.info('Cancelado!');
        }
      });
    } catch (err) {
      console.log(err)
    }
  }
}
