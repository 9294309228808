import { DatePipe } from '@angular/common';
import { FuncaoService, GlobalService, Login, Relatorio } from 'eddydata-lib';

export class OrdemCronologica {

  protected datepipe: DatePipe;
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;

  dataInicial: Date;
  dataFinal: Date;
  numero: number;
  restosPagar: boolean;

  constructor(
  ) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.datepipe = new DatePipe('pt');
  }

  public async imprimir(lista: any[], login: Login, ordemResto?: string) {
    let titulo = 'ORDEM CRONOLÓGICA DE PAGAMENTOS - Instruções 2/95 com Aditamento 1/97 TCE/SP';
    if (this.restosPagar) {
      titulo += ` Resto a pagar - Ordenado por: ${ordemResto === 'l.data_liquidacao' ? 'Data de Liquidação' : 'Data de Vencimento'}`;
    }
    await Relatorio.imprimirPersonalizado(titulo, login.usuario.nome, login.usuario.sobrenome, login.orgao.nome, login.brasao,
      this.montarConteudo(lista), 'landscape', 'ORDEM CRONOLÓGICA',
      {
        linhas: {
          hLineWidth() {
            return 1;
          },
          vLineWidth() {
            return 1;
          },
          hLineColor() {
            return 'black';
          },
          paddingLeft() {
            return 3;
          },
          paddingRight() {
            return 3;
          }
        }
      }, false, false, 'pdf', `PERÍODO: ${this.datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${this.datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`);
  }

  private montarConteudo(lista: any[]) {
    const conteudo = [];

    if (this.restosPagar) {
      conteudo.push({ text: 'RESTOS A PAGAR', bold: true, fontSize: 12, alignment: 'center' });
      conteudo.push(this.dadosDetalheRestos(lista[1]));
      conteudo.push({ text: '', pageBreak: 'after' });
      conteudo.push({ text: 'ORÇAMENTÁRIOS', bold: true, fontSize: 12, alignment: 'center' });
    }
    conteudo.push(this.dadosDetalhe(lista[0]));

    return conteudo;
  }

  private dadosDetalhe(dados: any): {}[] {
    const conteudo: {}[][] = [
      [
        { text: 'PROCESSO', fontSize: 9, rowSpan: 2, alignment: 'center', bold: true, margin: [0, 7, 0, 0] },
        { text: 'CONTRATO/FORNECEDOR', fontSize: 9, rowSpan: 2, alignment: 'center', bold: true, margin: [0, 7, 0, 0] },
        { text: 'DOCUMENTO', fontSize: 9, colSpan: 5, alignment: 'center', bold: true },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: 'DATA', fontSize: 9, colSpan: 2, alignment: 'center', bold: true },
        { text: '' },
      ], [
        { text: '' },
        { text: '' },
        { text: 'TIPO', fontSize: 9, alignment: 'center', bold: true },
        { text: 'EMPENHO', fontSize: 9, alignment: 'center', bold: true },
        { text: 'N° PARCELA', fontSize: 9, alignment: 'center', bold: true },
        { text: 'DATA', fontSize: 9, alignment: 'center', bold: true },
        { text: 'VALOR', fontSize: 9, alignment: 'center', bold: true },
        { text: 'VENCIMENTO', fontSize: 9, alignment: 'center', bold: true },
        { text: 'PAGAMENTO', fontSize: 9, alignment: 'center', bold: true },
      ]
    ];

    const grupos = this.funcaoService.agrupar(dados, ['recurso', 'aplicacao', 'recurso_variavel', 'recurso_variavel_nome']);
    for (const grupo of grupos) {
      conteudo.push([
        { text: `${grupo.grupo['recurso']}${grupo.grupo['aplicacao']}${grupo.grupo['recurso_variavel']} - ${grupo.grupo['recurso_variavel_nome']}`, bold: true, fontSize: 9, colSpan: 9 },
        '', '', '', '', '', '', '', ''
      ]);

      for (const item of grupo.registros) {
        conteudo.push([
          { text: `${this.siglaModalidade(item.modalidade)} ${item.numero_contrato ? this.funcaoService.mascarar('0000/0000', item.numero_contrato) : ''}`, fontSize: 8, alignment: 'center', border: [true, false, true, false] },
          { text: `${item.fornecedor}`, fontSize: 8, border: [true, false, true, false] },
          { text: `${this.tipoEmpenho(item.tipo_empenho)}`, fontSize: 8, alignment: 'center', border: [true, false, true, false] },
          { text: `${item.numero_empenho}`, fontSize: 8, alignment: 'center', border: [true, false, true, false] },
          { text: `${item.parcela}`, fontSize: 8, alignment: 'center', border: [true, false, true, false] },
          { text: `${this.funcaoService.converteDataBR(item.data_empenho)}`, fontSize: 8, alignment: 'center', border: [true, false, true, false] },
          { text: `${this.funcaoService.convertToBrNumber(item.valor_empenho, 2)}`, fontSize: 8, alignment: 'right', border: [true, false, true, false] },
          { text: `${this.funcaoService.converteDataBR(item.data_vencimento)}`, fontSize: 8, alignment: 'center', border: [true, false, true, false] },
          { text: `${this.funcaoService.converteDataBR(item.data_pagamento)}`, fontSize: 8, alignment: 'center', border: [true, false, true, false] },
        ]);
      }
    }

    conteudo.push([{ text: '', colSpan: 9, border: [false, true, false, false] }]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [58, '*', 'auto', 'auto', 52, 'auto', 'auto', 'auto', 'auto'],
        body: conteudo
      }
    }];
  }

  private dadosDetalheRestos(dados: any): {}[] {
    const conteudo: {}[][] = [
      [
        { text: 'PROCESSO', fontSize: 9, rowSpan: 2, alignment: 'center', bold: true, margin: [0, 7, 0, 0] },
        { text: 'CONTRATO/FORNECEDOR', fontSize: 9, rowSpan: 2, alignment: 'center', bold: true, margin: [0, 7, 0, 0] },
        { text: 'DOCUMENTO', fontSize: 9, colSpan: 5, alignment: 'center', bold: true },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: 'DATA', fontSize: 9, colSpan: 2, alignment: 'center', bold: true },
        { text: '' },
      ], [
        { text: '' },
        { text: '' },
        { text: 'TIPO', fontSize: 9, alignment: 'center', bold: true },
        { text: 'EMPENHO', fontSize: 9, alignment: 'center', bold: true },
        { text: 'N° PARCELA', fontSize: 9, alignment: 'center', bold: true },
        { text: 'DATA', fontSize: 9, alignment: 'center', bold: true },
        { text: 'VALOR', fontSize: 9, alignment: 'center', bold: true },
        { text: 'VENCIMENTO', fontSize: 9, alignment: 'center', bold: true },
        { text: 'PAGAMENTO', fontSize: 9, alignment: 'center', bold: true },
      ]
    ];

    const grupos = this.funcaoService.agrupar(dados, ['recurso_variavel', 'recurso_variavel_nome']);
    for (const grupo of grupos) {
      conteudo.push([
        { text: `${grupo.grupo['recurso_variavel']} - ${grupo.grupo['recurso_variavel_nome']}`, bold: true, fontSize: 9, colSpan: 9 },
        '', '', '', '', '', '', '', ''
      ]);

      for (const item of grupo.registros) {
        conteudo.push([
          { text: `${this.siglaModalidade(item.modalidade)} ${item.numero_contrato ? this.funcaoService.mascarar('0000/0000', item.numero_contrato) : ''}`, fontSize: 8, alignment: 'center', border: [true, false, true, false] },
          { text: `${item.fornecedor}`, fontSize: 8, border: [true, false, true, false] },
          { text: `${this.tipoEmpenho(item.tipo_empenho)}`, fontSize: 8, alignment: 'center', border: [true, false, true, false] },
          { text: `${item.numero_empenho}`, fontSize: 8, alignment: 'center', border: [true, false, true, false] },
          { text: `${item.parcela}`, fontSize: 8, alignment: 'center', border: [true, false, true, false] },
          { text: `${this.funcaoService.converteDataBR(item.data_empenho)}`, fontSize: 8, alignment: 'center', border: [true, false, true, false] },
          { text: `${this.funcaoService.convertToBrNumber(item.valor_empenho, 2)}`, fontSize: 8, alignment: 'right', border: [true, false, true, false] },
          { text: `${this.funcaoService.converteDataBR(item.data_vencimento)}`, fontSize: 8, alignment: 'center', border: [true, false, true, false] },
          { text: `${this.funcaoService.converteDataBR(item.data_pagamento)}`, fontSize: 8, alignment: 'center', border: [true, false, true, false] },
        ]);
      }
    }

    conteudo.push([{ text: '', colSpan: 9, border: [false, true, false, false] }]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [58, '*', 'auto', 'auto', 52, 'auto', 'auto', 'auto', 'auto'],
        body: conteudo
      }
    }];
  }

  private tipoEmpenho(tipo: string) {
    if (tipo === 'O') {
      return 'ORDINÁRIO';
    } else if (tipo === 'E') {
      return 'ESTIMATIVA';
    } else if (tipo === 'G') {
      return 'GLOBAL';
    } else {
      return '';
    }
  }

  private siglaModalidade(modalidade: number): string {
    if (modalidade === 0) {
      // NÃO INFORMADO
      return 'NI';
    } else if (modalidade === 1) {
      // CONCURSO
      return 'CN';
    } else if (modalidade === 2) {
      // CONVITE DE PREÇOS
      return 'CP';
    } else if (modalidade === 3) {
      // TOMADA DE PREÇOS
      return 'TP';
    } else if (modalidade === 4) {
      // CONCORRENCIA PUBLICA
      return 'CO';
    } else if (modalidade === 5) {
      // DISPENSA
      return 'DIS';
    } else if (modalidade === 6) {
      // INEXIGIBILIDADE
      return 'IN';
    } else if (modalidade === 7) {
      // PREGÃO PRESENCIAL
      return 'PR';
    } else if (modalidade === 8) {
      // PREGÃO ELETRONICO
      return 'PRE';
    } else if (modalidade === 9) {
      // OUTRAS - NÃO APLICAVEL
      return 'OUTRAS';
    } else if (modalidade === 10) {
      // RDC
      return 'RDC';
    } else if (modalidade === 11) {
      // LEILÃO
      return 'LE';
    } else {
      return '';
    }
  }
}
