import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QuadroEnsinoService, Quadro1Ensino, Quadro2Ensino, Quadro3Ensino, Quadro4Ensino, Quadro5Ensino, Quadro6Ensino, Quadro8Ensino, ProjecaoQSE, ProjecaoReceitaDespesaEnsino } from 'contabil-lib';
import { Exercicio, ExercicioService, GlobalService, LoginPublico, OrgaoService, ProgressoService, ParametroContabilService, FormatoExportacao } from 'eddydata-lib';
import * as toastr from 'toastr';

@Component({
  selector: 'app-transparencia-quadro-ensino',
  templateUrl: './transparencia-quadro-ensino.component.html'
})
export class TransparenciaQuadroEnsinoComponent implements OnInit, OnDestroy {

  login: LoginPublico = new LoginPublico();
  protected unsubscribe: Subject<void> = new Subject();

  listaOrgaos: SelectItem[];
  orgaos: number[] = [];
  public mes: number;
  public ano: number;
  public listaExercicios: Array<Exercicio>;
  public listaAnexos: Array<any>;
  public anexoSelect: string = 'Q1';
  public opcao = '1';
  public assinatura_ensino: boolean = false;
  public percentualFerias: number = 0;
  public percentual13: number = 80;

  constructor(
    protected injector: Injector,
    protected orgaoService: OrgaoService,
    protected exercicioService: ExercicioService,
    protected progressoService: ProgressoService,
    protected quadroServico: QuadroEnsinoService,
    protected parametroContabilidadeServico: ParametroContabilService,
  ) {
    this.login = GlobalService.obterSessaoLogin();
    this.orgaos.push(this.login.orgao.id);
    this.ano = this.login.exercicio.id;
    this.mes = 1;
  }

  ngOnInit() {
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
    this.orgaoService.obterTodos().pipe(takeUntil(this.unsubscribe)).subscribe(dados => {
      this.listaOrgaos = [];
      for (const model of dados) {
        this.listaOrgaos.push({ label: `${model.codigo} - ${model.nome}`, value: model.id });
      }
    });
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe)).subscribe(dados => {
      this.listaExercicios = dados.content;
    });
    this.listaAnexos = [
      { id: 'Q1', nome: 'QUADRO 1 - IMPOSTOS' },
      { id: 'Q2', nome: 'QUADRO 2 - RECEITAS VINCULADAS' },
      { id: 'Q3', nome: 'QUADRO 3 - DESPESAS ORÇAMENTÁRIAS' },
      { id: 'Q4', nome: 'QUADRO 4 - MOVIMENTAÇÃO FINANCEIRA DA EDUCAÇÃO' },
      { id: 'Q5', nome: 'QUADRO 5 - FUNDEB' },
      { id: 'Q6', nome: 'QUADRO 6 - RECEITA PRÓPRIA' },
      { id: 'Q8', nome: 'QUADRO 8 - CÁLCULO ESTIMADO DO REPASSE DECENDIAL NO TRIMESTRE' },
      { id: 'PRD', nome: 'PROJEÇÃO DA RECEITA E DESPESA' },
      { id: 'QSE', nome: 'PROJEÇÃO DO QSE' }
    ];
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  async imprimir(formato: FormatoExportacao) {
    this.assinatura_ensino = (await this.parametroContabilidadeServico.obter({ orgao_id: this.login.orgao.id }).toPromise())?.assinatura_ensino;
    const exercicioSelect = this.listaExercicios.find((e) => +e.id === +this.ano);
    let mes: number;
    let dtInicial = null;
    let dtFinal = null;
    if (this.opcao == '1') {
      mes = this.mes;
    }

    if (this.anexoSelect === 'Q1') {
      const q1 = new Quadro1Ensino(this.quadroServico, mes, exercicioSelect, dtInicial, dtFinal, null, this.injector, this.assinatura_ensino);
      q1.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'Q2') {
      const q2 = new Quadro2Ensino(this.quadroServico, mes, exercicioSelect, dtInicial, dtFinal, null, this.injector, this.assinatura_ensino);
      q2.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'Q3') {
      const q3 = new Quadro3Ensino(this.quadroServico, mes, exercicioSelect, dtInicial, dtFinal, null, this.injector, this.assinatura_ensino);
      q3.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'Q4') {
      const q4 = new Quadro4Ensino(this.quadroServico, mes, exercicioSelect, dtInicial, dtFinal, null, this.injector, this.assinatura_ensino);
      q4.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'Q5') {
      const q5 = new Quadro5Ensino(this.quadroServico, mes, exercicioSelect, this.progressoService, dtInicial, dtFinal, null, this.injector, this.assinatura_ensino);
      q5.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'Q6') {
      const q6 = new Quadro6Ensino(this.quadroServico, mes, exercicioSelect, this.progressoService, dtInicial, dtFinal, null, this.injector, this.assinatura_ensino);
      q6.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'Q8') {
      const q8 = new Quadro8Ensino(this.quadroServico, mes, exercicioSelect, dtInicial, dtFinal, null, this.injector, this.assinatura_ensino);
      q8.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'PRD') {
      this.exercicioService.obterPorAno(exercicioSelect.ano - 1, this.login.cidade.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          const prd = new ProjecaoReceitaDespesaEnsino(this.quadroServico, mes, exercicioSelect, res.content[0], this.percentualFerias, this.percentual13, this.injector, this.assinatura_ensino);
          prd.montarRelatorio(this.orgaos, formato);
        })
    } else if (this.anexoSelect === 'QSE') {
      this.exercicioService.obterPorAno(exercicioSelect.ano - 1, this.login.cidade.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          const prd = new ProjecaoQSE(this.quadroServico, mes, exercicioSelect, res.content[0], this.injector, this.assinatura_ensino);
          prd.montarRelatorio(this.orgaos, formato);
        })
    } else {
      toastr.warning('Selecione o(s) órgão(s) a serem impressos');
    }
  }

}
