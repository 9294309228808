import { Component, Input } from '@angular/core';
import { Audesp4LoteNaoObra, Audesp4OrcamentoLoteSim } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-lote-orc',
  templateUrl: './licitacao-audesp-lote-orc.component.html'
})
export class LicitacaoAudespLoteOrcComponent extends BaseLicitacaoAudesp {

  @Input() public lote: Audesp4LoteNaoObra;

  public itemAtual: Audesp4OrcamentoLoteSim;
  public itemAnterior: Audesp4OrcamentoLoteSim;

  constructor() {
    super()
  }

  public adicionar() {
    if (!this.lote.OrcamentoLoteSim || this.lote.OrcamentoLoteSim.length === 0) this.lote.OrcamentoLoteSim = [];

    this.itemAtual = {
      ValorUnitario: 0, Quantidade: 0, UnidadeMedida: '', DtOrcamento: undefined, tipoDoc: 'CPF', editavel: true
    }
    this.lote.OrcamentoLoteSim.push(this.itemAtual);
  }

  public visualizar(item: Audesp4OrcamentoLoteSim) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      let itm = this.lote.OrcamentoLoteSim.find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.lote.OrcamentoLoteSim.splice(this.lote.OrcamentoLoteSim.indexOf(this.itemAtual), 1, itm)
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAtual.editavel = false;
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4OrcamentoLoteSim) {
    this.itemAtual = undefined;
    this.lote.OrcamentoLoteSim.splice(this.lote.OrcamentoLoteSim.indexOf(item), 1)
  }

  public salvar() {
    if (this.itemAtual.tipoDoc === 'OUTRO') {
      this.validarOutroDoc(this.itemAtual);
    };

    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }

  public validarOutroDoc(item: Audesp4OrcamentoLoteSim) {
    const regex = /^[A-Za-z0-9.\-\/]{5,20}$/;

    if (!regex.test(item.OutroDoc)) {
      toastr.error(`Documento do tipo "OUTRO" não pode conter espaços em branco, deve conter no mínimo 5 e no máximo 20 caracteres e não pode possuir caracteres especiais!`)
      throw new Error(`Documento do tipo "OUTRO" não pode conter espaços em branco!`)
    }
  }
}
