import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Audesp4LicitanteCNPJ, RegistroPrecos } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-licitante-cnpj',
  templateUrl: './licitacao-audesp-licitante-cnpj.component.html'
})
export class LicitacaoAudespLicitanteCNPJComponent extends BaseLicitacaoAudesp implements OnChanges {

  @Input() public entidade: RegistroPrecos;

  public licitanteAtual: Audesp4LicitanteCNPJ;
  public licitanteAnterior: Audesp4LicitanteCNPJ;
  public listaLotes: { id: number, nome: string }[];

  constructor(
  ) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['entidade']) {
      this.listaLotes = [];
      if (this.entidade.tipoObjeto === 'COMPRA_SERVICO' && this.entidade.ComprasServicos?.Lote) {
        this.entidade.ComprasServicos.Lote.forEach(lote => {
          this.listaLotes.push({ id: lote.NumeroLote, nome: lote.DescricaoLote })
        })
      } else if (this.entidade.tipoObjeto === 'COMPRA_SERVICO_TI' && this.entidade.ComprasServicosTI?.Lote) {
        this.entidade.ComprasServicosTI.Lote.forEach(lote => {
          this.listaLotes.push({ id: lote.NumeroLote, nome: lote.DescricaoLote })
        })
      }
    }
  }

  public adicionar() {
    if (!this.entidade.Licitante.LicitanteCNPJ || this.entidade.Licitante.LicitanteCNPJ.length === 0) this.entidade.Licitante.LicitanteCNPJ = [];

    this.licitanteAtual = {
      CNPJ: undefined, LicitanteDeclaracaoMicroEmpresaPequenoPorte: 'N', RazaoSocial: undefined,
      LicitanteAdministrador: undefined, LicitanteSocio: {}, LicitanteLoteItens: []
    }
    this.entidade.Licitante.LicitanteCNPJ.push(this.licitanteAtual);
    $('#dialogLicitanteCNPJ').modal('show');
  }

  public visualizar(item: Audesp4LicitanteCNPJ) {
    this.licitanteAnterior = Object.assign({}, item);
    this.licitanteAtual = item;
    this.licitanteAtual['edicao'] = true;
    $('#dialogLicitanteCNPJ').modal('show');
  }

  public cancelar() {
    if (this.licitanteAtual['edicao']) {
      if (this.licitanteAtual.LicitanteLoteItens.find(item => item.editavel)) {
        toastr.warning('Finalize a edição do item antes de concluir essa ação!')
        return;
      }
      let itm = this.entidade.Licitante.LicitanteCNPJ.find((item) => item === this.licitanteAtual);
      itm = Object.assign({}, this.licitanteAnterior);
      this.entidade.Licitante.LicitanteCNPJ.splice(this.entidade.Licitante.LicitanteCNPJ.indexOf(this.licitanteAtual), 1, itm)
    } else {
      this.remover(this.licitanteAtual);
    }
    this.licitanteAnterior = undefined;
    this.licitanteAtual = undefined;
    $('#dialogLicitanteCNPJ').modal('hide');
  }

  public remover(item: Audesp4LicitanteCNPJ) {
    this.licitanteAtual = undefined;
    this.entidade.Licitante.LicitanteCNPJ.splice(this.entidade.Licitante.LicitanteCNPJ.indexOf(item), 1)
  }

  public salvar() {
    if (this.licitanteAtual.LicitanteLoteItens.find(item => item.editavel)) {
      toastr.warning('Finalize a edição do item antes de concluir essa ação!')
      return;
    }
    if (!this.licitanteAtual?.IdentificacaoLicitante && this.entidade.modalidade === 4) {
      toastr.warning('Informe a identificação do participante antes de concluir essa ação!')
      return;
    }
    this.licitanteAtual = undefined;
    $('#dialogLicitanteCNPJ').modal('hide');
  }
}
