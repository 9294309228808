import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { RecebimentoLstComponent } from './recebimento-lst/recebimento-lst.component';
import { RecebimentoShwComponent } from './recebimento-shw/recebimento-shw.component';
import { RecebimentoRoutingModule } from './recebimento-routing.module';

@NgModule({
  declarations: [
    RecebimentoLstComponent,
    RecebimentoShwComponent,
  ],
  imports: [
    CommonModule,
    RecebimentoRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
  ],
  exports: [
    RecebimentoLstComponent,
    RecebimentoShwComponent,
  ],
})
export class RecebimentoModule { }
