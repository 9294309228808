// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { RcmsItem } from './rcms-item.model';
import { RcmsFavorecido } from './rcms-favorecido.model';

export class RcmsCotacao extends BaseResourceModel {
  constructor(
    public id?: number,
    public rcmsFavorecido?: RcmsFavorecido,
    public rcmsItem?: RcmsItem,
    public valor_unitario?: number,
    public valor_referencia?: number,
    public vencedor?: boolean
  ) {
    super();
  }
  static converteJson(json: any): RcmsCotacao {
    return Object.assign(new RcmsCotacao(), json);
  }
}
