import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PanelModule } from 'primeng/panel';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TipoContratacaoCondicaoDespesaComponent } from './tipo-contratacao-condicao-despesa/tipo-contratacao-condicao-despesa.component';
import { TipoContratacaoEventoComponent } from './tipo-contratacao-evento/tipo-contratacao-evento.component';
import { TipoContratacaoFormComponent } from './tipo-contratacao-form/tipo-contratacao-form.component';
import { TipoContratacaoListComponent } from './tipo-contratacao-list/tipo-contratacao-list.component';
import { TipoContratacaoRoutingModule } from './tipo-contratacao-routing.module';
import { TipoContratacaoViewComponent } from './tipo-contratacao-view/tipo-contratacao-view.component';

@NgModule({
  declarations: [
    TipoContratacaoListComponent,
    TipoContratacaoFormComponent,
    TipoContratacaoEventoComponent,
    TipoContratacaoViewComponent,
    TipoContratacaoCondicaoDespesaComponent
  ],
  imports: [
    CommonModule,
    TipoContratacaoRoutingModule,
    SharedModule,
    FormsModule,
    ToastModule,
    IMaskModule,
    AutoCompleteModule,
    PanelModule,
    TooltipModule
  ]
})
export class TipoContratacaoModule { }
