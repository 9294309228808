// tslint:disable: variable-name
export class BoletoCampos {
    constructor() {

    }

    public cedente: string;
    public cliente_nome: string;
    public dt_vencimento: string;
    public valor: string;
    public acrescimo: string;
    public conta_corrente: string;
    public nosso_numero: string;
    public banco: string;
    public local_pagamento: string;
    public local_pagamento2: string;
    public dt_doc: string;
    public especie: string;
    public dt_processamento: string;
    public carteira: string;
    public moeda: string;
    public instrucao1: string;
    public instrucao2: string;
    public instrucao3: string;
    public instrucao4: string;
    public instrucao5: string;
    public endereco1: string;
    public endereco2: string;
    public endereco3: string;
    public digito_codbarra: string;
    public codbarra: string;
    public documento: string;
    public sacado_nome: string;
    public sacado_cpf_cnpj: string;
    public especie_doc: string;
    public aceite: string;
    public parcela: string;
    public informacoes_sac: string;
    public endereco: string;
}
