import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BaseResourceListComponent, Coluna, DateFormatPipe, Filtro, FormatoExportacao, GlobalService, Liquidacao, LoginPublico
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LiquidacaoService } from '../service/liquidacao.service';

@Component({
  selector: 'app-liquidacao-lst',
  templateUrl: './liquidacao-lst.component.html'
})
export class LiquidacaoLstComponent extends BaseResourceListComponent<Liquidacao, LoginPublico> {

  /**
   * Declaração de variáveis
   */
  public ptBR: any;
  public filtro: string;
  public data1: Date;
  public data2: Date;
  protected datepipe: DatePipe;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private route: ActivatedRoute,
    protected injector: Injector,
    protected globalService: GlobalService,
    private liquidacaoService: LiquidacaoService) {
    super(liquidacaoService, injector);
    this.col = 2;
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.data1 = new DateFormatPipe().transform(params['data1'], []);
        this.data2 = new DateFormatPipe().transform(params['data2'], []);
        this.paginaCorrente = +params['pagina'];
        this.filtro = params['filtro'];
      });
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'empenho,empenho.ficha,empenho.favorecido,empenho.subelemento';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id,
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['data_liquidacao$DESC', 'empenho.numero'];
  }

  protected filtrosGrid(): Filtro {
    return {};
  }

  public beforeInit(): void {
    if (this.login.exercicio && !this.data1) {
      this.data1 = new Date(`01/01/${this.login.exercicio.ano}`);
      if (this.login.exercicio.ano !== new Date().getFullYear()) {
        this.data2 = new Date(`12/31/${this.login.exercicio.ano}`);
      }
    }
  }

  protected afterInit(): void {
    window.scrollTo(0, 0);
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
    this.ptBR = this.globalService.obterDataBR();
  }

  protected acaoRemover(model: Liquidacao): Observable<Liquidacao> {
    return null;
  }

  public obterParametros() {
    const parametros = {};
    this.datepipe = new DatePipe('pt');
    parametros['ignoreCondObrig'] = true;
    parametros['data_liquidacao$ge'] = this.datepipe.transform(this.data1, 'yyyy-MM-dd');
    parametros['data_liquidacao$le'] = this.datepipe.transform(this.data2, 'yyyy-MM-dd');
    parametros['orgao.id'] = this.login.orgao.id;
    if (this.filtro) {
      parametros['empenho.favorecido.nome$like'] = this.filtro + '%';
    }
    switch (this.col) {
      case 1:
        parametros['orderBy'] = `empenho.numero$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 2:
        parametros['orderBy'] = `data_liquidacao$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 3:
        parametros['orderBy'] = `empenho.favorecido.nome$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 4:
        parametros['orderBy'] = `empenho.subelemento.nome$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 5:
        parametros['orderBy'] = `documento$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 6:
        parametros['orderBy'] = `valor_liquidado$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      default:
        break;
    }
    parametros['relations'] = 'empenho,empenho.ficha,empenho.favorecido,empenho.subelemento';

    return parametros;
  }

  public preencherGrid() {
    const parametros = this.obterParametros();
    this.liquidacaoService
      .filtrar(
        this.paginaCorrente,
        this.login.limite,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.lista = lista.content;
          this.paginaTotal = lista.totalPages;
        },
        () => alert('erro ao retornar lista')
      );
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Número', coluna: 'empenho.numero' },
      { titulo: 'Parcela', coluna: 'parcela' },
      { titulo: 'Beneficiario', coluna: 'empenho.favorecido.nome' },
      { titulo: 'Ficha', coluna: 'empenho.ficha.numero' },
      { titulo: 'Historico', coluna: 'historico' },
      { titulo: 'Data', coluna: 'data_liquidacao' },
      { titulo: 'Vencimento', coluna: 'data_vencimento' },
      { titulo: 'Documento', coluna: 'documento' },
      { titulo: 'Valor', coluna: 'valor_liquidado', decimais: 2, alignment: 'right' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    this.liquidacaoService
      .filtrar(1, -1, parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE LIQUIDAÇÕES ORÇAMENTÁRIOS',
              '', '', this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem liquidações', ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
              lista.content, ['valor_liquidado']);
          } else {
            this.exportar(formato, lista.content);
          }
        }, () => alert('erro ao retornar lista'));
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public editar(id: number) {
    const dt1 = this.datepipe.transform(this.data1, 'yyyy-MM-dd', 'GMT');
    const dt2 = this.datepipe.transform(this.data2, 'yyyy-MM-dd', 'GMT');
    if (!this.filtro || this.filtro === '') {
      this.router.navigate([
        '/liquidacoes-orcamentarias/visualiza', id, this.paginaCorrente, dt1, dt2]);
    } else {
      this.router.navigate([
        '/liquidacoes-orcamentarias/visualiza', id, this.paginaCorrente, dt1, dt2, this.filtro]);
    }
  }


  public onSelectPeriodo() {
    this.preencherGrid();
  }

}
