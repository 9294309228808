import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { EmpenhoService, LicitacaoService } from 'administrativo-lib';
import {
  BaseResourceFormComponent, DateFormatPipe, EddyAutoComplete, FuncaoService, Licitacao, LoginContabil, ParametroContabilService, Relatorio, ReservaConvenioEstadual, ReservaDotacao, ReservaDotacaoItem
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { AutoComplete } from 'primeng/autocomplete';
import { Calendar } from 'primeng/calendar';
import { takeUntil } from 'rxjs/operators';
import { FichaDespesaService } from '../../ficha-despesa/service/ficha-despesa.service';
import { ReservaConvenioEstadualService } from '../service/reserva-convenio-estadual.service';
import { ReservaDotacaoItemService } from '../service/reserva-dotacao-item.service';
import { ReservaDotacaoService } from '../service/reserva-dotacao.service';

interface IReservaDotacaoItemExtendido extends ReservaDotacaoItem {
  total_empenhado_licitacao?: string;
  total_anulado_licitacao?: string;
  total_empenhado_contrato?: string;
  total_anulado_contrato?: string;
  total_empenhado?: string;
  total_anulado?: string;
  total_empenhado_por_reserva_item?: string
};

@Component({
  selector: 'app-reserva-dotacao-form',
  templateUrl: './reserva-dotacao-form.component.html'
})
export class ReservaDotacaoFormComponent extends BaseResourceFormComponent<ReservaDotacao, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @ViewChild('InputFocus') private inputField: ElementRef;

  public listaConvenioEstadual = new Array<any>();
  public listaConvenioFederal = new Array<any>();
  public listaFinanciamento = new Array<any>();
  public listaTipos: Array<any>;
  public listaFichas = new Array<IReservaDotacaoItemExtendido>();
  public tipo: number;
  public licitacaoAutoComplete: EddyAutoComplete<Licitacao>;
  public total_reservado = 0;
  public total_empenhado = 0;
  public total_adjudicacao = 0;
  public parametro_sem_pre: boolean;
  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected reservaService: ReservaDotacaoService,
    protected empenhoService: EmpenhoService,
    protected licitacaoService: LicitacaoService,
    protected convenioEstadualService: ReservaConvenioEstadualService,
    protected reservaItemService: ReservaDotacaoItemService,
    protected reservaDotacaoService: ReservaDotacaoService,
    protected fichaService: FichaDespesaService,
    private parametroContabilService: ParametroContabilService) {
    super(new ReservaDotacao(), injector, ReservaDotacao.converteJson, reservaService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(_entidade: ReservaDotacao): Promise<boolean> {
    return this.login?.sistema !== 'compras'
  }
  
  protected parametrosExtras(): {} {
    return { relations: 'licitacao.contratos.favorecido,licitacao.modalidade' };
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.listaTipos = [
      { nome: 'PRÉ EMPENHO', id: '0' },
      { nome: 'SEM PRÉ EMPENHO', id: '1' }
    ];

    this.parametroContabilService.obter({ 'orgao.id': this.login.orgao.id })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(parametro => {
        this.parametro_sem_pre = parametro.reserva_sem_pre_empenho;
      });

    this.tipo = 0;
  }

  protected campoFoco(): ElementRef | AutoComplete | Calendar {
    return this.inputField.nativeElement.focus();
  }

  protected async afterLoad() {
    this.entidade.data_cancelamento = new DateFormatPipe().transform(this.entidade.data_cancelamento, []);
    this.entidade.data_adjudicacao = new DateFormatPipe().transform(this.entidade.data_adjudicacao, []);
    this.convenioEstadualService.filtrar(0, -1, {
      reserva_id: this.entidade.id
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaConvenioEstadual = res.content ? res.content : new Array<ReservaConvenioEstadual>();
      });

    this.reservaItemService.extendido(0, -1, {
      reserva_id: this.entidade.id,
      relations: 'reserva,reserva.exercicio,reserva.orgao,ficha,ficha.acao,ficha.aplicacao,ficha.aplicacao_variavel,ficha.recurso,ficha.programa,' +
        'ficha.despesa,ficha.funcao,ficha.subfuncao,ficha.executora,ficha.executora.unidade,ficha.recurso_siconfi,ficha.complemento_siconfi,' +
        'ficha.orgao,ficha.exercicio,contrato_aditamento.contrato.favorecido,reserva.licitacao,estornos'
    })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaFichas = res.content ? res.content : new Array<ReservaDotacaoItem>();
        for (let item of this.listaFichas) {
          this.reservaItemService.totalReservadoItem(item.id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resposta) => {
              item['saldo_reserva'] = +resposta.reserva;
            });
          item.total_empenhado = item?.total_empenhado_por_reserva_item;
          item.total_anulado = item?.contrato_aditamento ? item?.total_anulado_contrato : item?.total_anulado_licitacao;
        };
        this.totalizarItens(this.listaFichas);
      });

    this.entidadeForm.get('tipo_reserva').setValue(this.entidade.tipo_reserva);
    this.entidadeForm.get('licitacao').setValue(this.entidade.licitacao);

    this.tipo = +this.entidade.tipo_reserva;
  }

  public totalizarItens(lista: IReservaDotacaoItemExtendido[]) {

    const totalEmpenhado = lista.reduce((anterior, atual) => {
      if (atual?.contrato_aditamento) {
        return +anterior + +atual?.total_empenhado_contrato;
      } else {
        return +anterior + +atual?.total_empenhado_licitacao;
      };
    }, 0);

    this.total_reservado = 0;
    this.total_empenhado = 0;
    this.total_adjudicacao = 0;
    this.total_empenhado = +totalEmpenhado;

    for (const item of lista) {
      this.total_reservado += +item?.valor_reservado;
      this.total_adjudicacao += +item?.valor_adjudicacao;
    }
    this.listaFichas = lista;
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      processo: [null],
      licitacao: [null],
      data_cancelamento: [null],
      observacao: ['-'],
      ativo: [true, [Validators.required]],
      tipo_reserva: ['0'],
      data_adjudicacao: [null],
      valor_adjudicacao: [0],
      declaracao: [false],
      tesouro: [false],
      convenio_estadual: [false],
      convenio_federal: [false],
      fundo_especial: [false],
      administracao_indireta: [false],
      outra_fonte: [false],
      operacao_credito: [false],
      outro_observacao: [],
      data_declaracao: [],
      estaduais: [],
      itens: [],
      orgao: [this.login.orgao, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]]
    });
  }

  protected async beforeSubmit() {
    try {
      if (!this.entidadeForm.get('tipo_reserva').value) {
        throw new Error('Informe o tipo de reserva');
      }
      if (this.entidadeForm.get('tipo_reserva').value === '0' && !this.entidadeForm.get('licitacao').value) {
        throw new Error('Informe o número do processo');
      }
      if (this.entidadeForm.get('licitacao').value?.processo) {
        this.entidadeForm.get('processo').setValue(this.entidadeForm.get('licitacao').value.processo);
      }
      if (!this.entidadeForm.get('licitacao').value && this.entidadeForm.get('tipo_reserva').value === '1') {
        this.entidadeForm.get('licitacao').setValue(null);
      }

      if (this.entidadeForm.get('processo')?.value?.includes('/')) {
        this.entidadeForm.get('processo').setValue(this.entidadeForm.get('processo').value.replace(/\//, ''));
      }

      this.entidadeForm.get('itens').setValue(this.listaFichas);
      this.entidadeForm.get('estaduais').setValue(this.listaConvenioEstadual);
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  protected async afterSubmit(ent: ReservaDotacao) {
    window.scrollTo(0, 0);
    setTimeout(() => this.inputField.nativeElement.focus());
    if (this.limparTela) {
      this.entidadeForm.reset();
      this.router.navigate(['/reservas-dotacao', 'novo']);
    }
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private carregarAutoCompletes() {
    // autocomplete para licitacao
    this.licitacaoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('licitacao'), this.licitacaoService,
      'id', ['processo', 'modalidade.nome'], {
      orgao_id: this.login.orgao.id,
      relations: 'modalidade,contratos.favorecido',
      orderBy: 'processo,modalidade.nome'
    }, { number: ['id'], text: ['modalidade.nome', 'processo', 'processo_com_barra_sql', 'processo_sem_barra_sql'] },
    );
  }

  public alterarTipo() {
    if (this.entidadeForm.get('tipo_reserva').value == 1) {
      this.entidadeForm.get('licitacao').setValue(null);
    }
    this.tipo = this.entidadeForm.get('tipo_reserva').value;
  }

  imprimir() {
    const parametros = [];
    parametros['id'] = this.entidadeForm.get('id').value;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['relations'] = this.relations();
    this.reservaDotacaoService.filtrar(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          Relatorio.imprimirPersonalizado('Reserva Dotação', this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            this.montarConteudo(lista.content), 'portrait', 'Reserva dotação', {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          })
        }, () => alert('erro ao retornar lista')
      );
  }

  protected relations(): string {
    return 'itens.ficha,itens.estornos,licitacao,itens.ficha.executora,itens.ficha.programa,itens.ficha.aplicacao,itens.ficha.funcao,itens.ficha.subfuncao,' +
      'itens.ficha.recurso,itens.ficha.despesa,itens.ficha.funcao,itens.ficha.programa,itens.ficha.aplicacao_variavel,itens.ficha.acao,orgao';
  }

  public montarConteudo(lista: any[]) {
    const conteudo = [];
    let index = 0;
    let fichas = 0;
    let pularPg = false;
    let total_ficha = 0;

    for (let reserva of lista) {
      let totLetrasObs = 0;
      if (reserva.tipo_reserva == 1) {
        totLetrasObs = reserva?.observacao.length
      } else {
        totLetrasObs = reserva?.licitacao?.objeto.length
      }

      if (reserva.itens.length > 1 && conteudo.length > 1) {
        conteudo.push([{ text: '', pageBreak: 'after', colSpan: 6 }, '', '', '', '', ''])
        index = 0
        pularPg = true
      } else if (index == 2 || fichas > 1 || pularPg === true) {
        conteudo.push([{ text: '', pageBreak: 'after', colSpan: 6 }, '', '', '', '', ''])
        index = 0
        pularPg = false
      } else if (totLetrasObs > 500) {
        conteudo.push([{ text: '', pageBreak: 'after', colSpan: 6 }, '', '', '', '', ''])
        index = 0
        pularPg = false
      } else {
        conteudo.push([
          { text: ` `, fontSize: 2, colSpan: 6, bold: true, border: [false, false, false, false] },
          '', '', '', '', ''
        ]);
        pularPg = false
      }

      conteudo.push([
        { text: `Reserva Nº ${reserva.id}`, fontSize: 12, colSpan: 6, bold: true, border: [true, true, true, false], margin: [5, 5, 0, 2] },
        '', '', '', '', ''
      ]);

      conteudo.push([
        { text: `Processo: `, fontSize: 10, bold: true, border: [true, false, false, false], margin: [5, 0, 0, 2] },
        { text: `${reserva.processo ? this.funcaoService.mascarar('#####/####', reserva.processo.replace('/', '')) : ''}`, fontSize: 10, border: [false, false, false, false] },
        { text: `Orgão: `, fontSize: 10, alignment: 'right', bold: true, border: [false, false, false, false], margin: [0, 0, 15, 0] },
        { text: `${this.funcaoService.mascarar('##.##.##', reserva?.orgao?.codigo)} - ${reserva?.orgao?.nome} `, fontSize: 10, colSpan: 3, border: [false, false, true, false] },
        '', '',
      ]);

      conteudo.push([
        { text: `Obj. processo: `, fontSize: 10, bold: true, border: [true, false, true, false], colSpan: 6, margin: [5, 0, 0, 0] },
        '', '', '', '', '',
      ]);

      if (reserva.tipo_reserva == 1) {
        conteudo.push([
          { text: `${reserva?.observacao ? reserva?.observacao : '-'}`, fontSize: 10, colSpan: 6, border: [true, false, true, true], margin: [5, 0, 0, 0] },
          '', '', '', '', '',
        ]);
      } else {
        conteudo.push([
          { text: `${reserva?.licitacao?.objeto ? reserva?.licitacao?.objeto : '-'}`, fontSize: 10, colSpan: 6, border: [true, false, true, true], margin: [5, 0, 0, 0] },
          '', '', '', '', '',
        ]);
      }
      index++

      fichas = 0;
      for (let item of reserva.itens) {
        const valorEstorno = item.estornos?.length ? +item.estornos.reduce((acc, item) => +item.valor_estorno + acc, 0) : 0;
        total_ficha += +item?.valor_reservado - valorEstorno;
        if (fichas >= 3) {
          conteudo.push([{ text: '', pageBreak: 'after', colSpan: 6, border: [false, false, false, false] }, '', '', '', '', '']);
          fichas = 1;
        } else if (pularPg) {
          fichas++;
        }

        conteudo.push([
          { text: `Ficha Nº: `, fontSize: 10, bold: true, border: [true, true, false, false], margin: [5, 0, 0, 0] },
          { text: `${item.ficha.numero}`, fontSize: 10, bold: true, border: [false, true, false, false], margin: [0, 0, 0, 0], colSpan: 2 },
          '',
          { text: `Uni. Executora: `, fontSize: 10, bold: true, border: [false, true, false, false], margin: [0, 2, 0, 0] },
          { text: `${item?.ficha?.executora?.codigo} - ${item?.ficha?.executora?.nome} `, fontSize: 10, colSpan: 2, border: [false, true, true, false], margin: [0, 2, 0, 0] },
          '',
        ]);

        conteudo.push([
          { text: `Elemento: `, fontSize: 10, bold: true, border: [true, false, false, false], margin: [5, 2, 0, 0] },
          { text: `${this.funcaoService.mascarar('##.##.##', item?.ficha?.despesa?.codigo)} - ${item?.ficha?.despesa?.nome}`, fontSize: 10, colSpan: 2, border: [false, false, false, false], margin: [0, 2, 0, 0] },
          '',
          { text: `Data: `, fontSize: 10, border: [false, false, false, false], bold: true, margin: [0, 2, 0, 0] },
          { text: `${this.funcaoService.converteDataBR(item.data_reserva)}`, fontSize: 10, alignment: 'left', colSpan: 2, border: [false, false, true, false], margin: [0, 2, 0, 0] },
          '',
        ]);

        conteudo.push([
          { text: `Projeto/Atividade: `, fontSize: 10, bold: true, border: [true, false, false, false], margin: [5, 2, 0, 0] },
          { text: `${this.funcaoService.mascarar('#.###', item?.ficha?.acao?.codigo)} - ${item?.ficha?.acao?.nome}`, fontSize: 10, colSpan: 2, border: [false, false, false, false], margin: [0, 2, 0, 0] },
          '',
          { text: `Função: `, bold: true, fontSize: 10, border: [false, false, false, false], margin: [0, 2, 0, 0] },
          { text: `${item?.ficha?.funcao.codigo} - ${item?.ficha?.funcao?.nome}`, colSpan: 2, fontSize: 10, border: [false, false, true, false], margin: [0, 2, 0, 0] },
          '',
        ]);

        conteudo.push([
          { text: `Programa: `, fontSize: 10, bold: true, border: [true, false, false, false], margin: [5, 2, 0, 0] },
          { text: `${item?.ficha?.programa?.codigo} - ${item?.ficha?.programa?.nome}`, fontSize: 10, colSpan: 2, border: [false, false, false, false], margin: [0, 2, 0, 0] },
          '',
          { text: `Sub-função: `, bold: true, fontSize: 10, border: [false, false, false, false], margin: [0, 2, 0, 0] },
          { text: `${item?.ficha?.subfuncao.codigo} - ${item?.ficha?.subfuncao?.nome}`, colSpan: 2, fontSize: 10, border: [false, false, true, false], margin: [0, 2, 0, 0] },
          '',
        ]);
        conteudo.push([
          { text: `Fonte Recurso: `, bold: true, fontSize: 10, border: [true, false, false, false], margin: [5, 2, 0, 0] },
          { text: `${item?.ficha?.recurso?.codigo} - ${item?.ficha?.recurso?.nome}`, colSpan: 5, fontSize: 10, border: [false, false, true, false], margin: [0, 2, 0, 0] },
          '', '', '', '',
        ]);

        conteudo.push([
          { text: `Aplicação: `, fontSize: 10, bold: true, border: [true, false, false, false], margin: [5, 2, 0, 0] },
          { text: `${item?.ficha?.aplicacao?.codigo} - ${item?.ficha?.aplicacao?.nome}`, fontSize: 10, colSpan: 2, border: [false, false, false, false], margin: [0, 2, 0, 0] },
          '',
          { text: `Valor: `, bold: true, fontSize: 10, border: [false, false, false, false], margin: [0, 2, 0, 0] },
          { text: `${this.funcaoService.convertToBrNumber(+item?.valor_reservado - +valorEstorno)}`, colSpan: 2, fontSize: 10, border: [false, false, true, false], margin: [0, 2, 0, 0] },
          '',
        ]);

        conteudo.push([
          { text: `Variável: `, fontSize: 10, bold: true, border: [true, false, false, true], margin: [5, 2, 0, 0] },
          { text: `${item?.ficha?.aplicacao_variavel?.codigo} - ${item?.ficha?.aplicacao_variavel?.nome}`, fontSize: 10, colSpan: 5, border: [false, false, true, true], margin: [0, 2, 0, 0] },
          '', '', '', '',
        ]);
      }
    }
    conteudo.push([
      { text: ``, colSpan: 3, border: [false, false, false, false], margin: [5, 2, 0, 0] },
      '',
      '',
      { text: `Total das Fichas: `, fontSize: 14, bold: true, colSpan: 2, border: [false, false, false, false], margin: [0, 2, 0, 0] },
      '',
      { text: `${this.funcaoService.convertToBrNumber(total_ficha)}`, fontSize: 14, colSpan: 1, border: [false, false, false, false], margin: [0, 2, 0, 0] },
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: [70, '*', '*', 70, '*', 110],
        body: conteudo
      }
    }];
  }

  public carregarNumProcesso(): boolean {
    if (this.parametro_sem_pre) {
      return true;
    } else return this.entidadeForm.get('tipo_reserva').value === '0';
  }
}
