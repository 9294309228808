// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { GrupoAlmoxarifado } from './grupo-almoxarifado.model';
import { SubGrupoEstoque } from './sub-grupo-estoque.model';

export class GrupoEstoque extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: number,
    public nome?: string,
    public bec?: boolean,
    public medicamento?: boolean,
    public orgao?: Orgao,
    public estoques?: GrupoAlmoxarifado[],
    public subgrupos?: SubGrupoEstoque[],
    public vida_util_meses?: number,
    public valor_residual?: number
  ) {
    super();
  }
  static converteJson(json: any): GrupoEstoque {
    return Object.assign(new GrupoEstoque(), json);
  }
}
