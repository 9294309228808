import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Orgao, OrgaoService, ProgressoService, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../assinaturas/assinaturas';
import { AnexoLRFService } from '../../service/anexo-lrf.service';

@Directive()
export class GestaoFiscalLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLRFService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    private orgaoService: OrgaoService,
    protected progressoService: ProgressoService,
    protected assinaturaControleInterno: boolean) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  private orgaoFiltro: Orgao;

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // tslint:disable: max-line-length
  public montarRelatorio(orgaos: number[], formato: FormatoExportacao = 'pdf') {
    this.anexoServico.obterRGF(this.mes, this.exercicio.id, orgaos, this.login.orgao.especie === 'C' ? false : true)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async idTransacao => {
        let dados;
        this.progressoService.show(idTransacao, async (retorno) => {
          dados = retorno;
          // this.orgaoFiltro = (await this.orgaoService.filtrar(1, -1, { id: orgao, relations: 'brasao' }).toPromise()).content[0] as Orgao;

          // Somente o orgão da CM e logado na CM
          if (orgaos.length === 1 && orgaos[0] === 2 && this.login.orgao.id === 2) {
            this.orgaoFiltro = (await this.orgaoService.filtrar(1, -1, { id: orgaos[0], relations: 'brasao' }).toPromise()).content[0] as Orgao;
            if (!this.orgaoFiltro) {
              const err = new Error('Órgão não encontrado');
              console.error(err.message);
              throw err;
            }
          }
          if (formato === 'pdf') {
            Relatorio.imprimirPersonalizado(
              `RELATÓRIO DE GESTÃO FISCAL`, this.login.usuario.nome, this.login.usuario.sobrenome,
              this.login.orgao.nome, this.login.brasao,
              await this.conteudoReceita(dados),
              'portrait', 'RELATÓRIO DE GESTÃO FISCAL',
              {
                linhas: {
                  hLineWidth() {
                    return 1;
                  },
                  vLineWidth() {
                    return 1;
                  },
                  hLineColor() {
                    return 'black';
                  },
                  paddingLeft() {
                    return 3;
                  },
                  paddingRight() {
                    return 3;
                  }
                }
              });
          } else {
            this.funcaoService.exportar(formato, this.normalizar(dados), 'RELATÓRIO DE GESTÃO FISCAL', this.colunas());
          }
        });
      });
  }

  private async conteudoReceita(dados: any[]): Promise<{}[]> {
    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: '',
        bold: true,
        colSpan: 3, border: [false, false, false, false],
      }, '', ''],
      [{
        text: 'LRF, art.48',
        alignment: 'left',
        bold: true,
        colSpan: 3, border: [false, false, false, false],
      }, '', ''],
      [{
        text: 'QUADRO COMPARATIVO COM OS LIMITES DA LRF: ',
        alignment: 'left',
        bold: true
      }, {
        text: `REFERÊNCIA: ` +
          this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano,
        alignment: 'center',
        bold: true,
        colSpan: 2
      }, '']
    ];

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);

    // monta o agrupamento do relatório
    let valorRCLO19 = 0;
    let valorRCLO21 = 0;
    for (const item of dados) {
      if (item.relatorio === 'RCL') {
        valorRCLO19 = item.valor['O19'];
        valorRCLO21 = item.valor['O21'];
        if (item.nome === 'Receita Corrente Líquida Ajustada para Cálculo dos Limites de endividamento') {
          registros.push([
            { text: item.nome, bold: true },
            { text: 'R$ ' + this.funcaoService.convertToBrNumber(valorRCLO19, 2), bold: true, alignment: 'right', colSpan: 2 }, ''
          ]);
        } else {
          registros.push([
            { text: item.nome, bold: true },
            { text: 'R$ ' + this.funcaoService.convertToBrNumber(valorRCLO21, 2), bold: true, alignment: 'right', colSpan: 2 }, ''
          ]);
        }
      } else if (item.relatorio === 'DP') {
        registros.push([
          {
            text: '', border: [true, false, true, false], colSpan: 3, margin: [0, 15, 0, 0]
          }, '', ''
        ]);
        registros.push([
          { text: '' },
          { text: 'R$', alignment: 'center', bold: true },
          { text: '%', alignment: 'center', bold: true }
        ]);
        registros.push([
          { text: item.nome, bold: true },
          { text: this.funcaoService.convertToBrNumber(item.valor, 2), alignment: 'right', bold: true },
          { text: this.funcaoService.convertToBrNumber((+parseFloat(item.valor).toFixed() / +parseFloat(`${valorRCLO21}`).toFixed()) * 100, 4) + ' %', alignment: 'right', bold: true }
        ]);

        const aliquotaRCL021 = this.orgaoFiltro?.id !== 2 ? 0.54 : 0.06;

        registros.push([
          { text: 'Limite Máximo (art. 20 LRF)' },
          { text: this.funcaoService.convertToBrNumber(valorRCLO21 * aliquotaRCL021, 2), alignment: 'right' },
          { text: (`${aliquotaRCL021 * 100},0000`) + ' %', alignment: 'right' },
        ]);

        const valorPrudencial = this.orgaoFiltro?.id !== 2 ? +valorRCLO21 * 0.513 : +valorRCLO19 * 0.06 * 0.95;
        registros.push([
          { text: 'Limite Prudencial 95% (par.ún.art.22 LRF)' },
          { text: this.funcaoService.convertToBrNumber(valorPrudencial, 2), alignment: 'right' },
          { text: (this.orgaoFiltro?.id !== 2 ? '51,3000' : '5,7000') + ' %', alignment: 'right' },
        ]);

        let valorExcesso = 0;
        if (this.orgaoFiltro?.id !== 2) {
          valorExcesso = +item.valor - (+valorRCLO19 * 0.54);
        } else {
          valorExcesso = +item.valor - valorRCLO19;
        }

        registros.push([
          { text: 'Excesso a Regularizar' },
          { text: valorExcesso > 0 ? this.funcaoService.convertToBrNumber(valorExcesso, 2) : '0,00', alignment: 'right' },
          { text: (valorExcesso > 0 ? this.funcaoService.convertToBrNumber((valorExcesso * 100) / +valorRCLO19, 4) : '0,0000') + ' %', alignment: 'right' }
        ]);
        registros.push([
          {
            text: '', border: [this.orgaoFiltro?.id !== 2 ? true : false, false, this.orgaoFiltro?.id !== 2 ? true : false, false], colSpan: 3, margin: [0, 15, 0, 0]
          }, '', ''
        ]);
      } else if (item.relatorio === 'DCL' && this.orgaoFiltro?.id !== 2) {
        registros.push([
          { text: item.nome, bold: true },
          { text: '' },
          { text: '' }
        ]);
        const valorDCL = +item.valor;
        registros.push([
          { text: 'Saldo Devedor' },
          { text: this.funcaoService.convertToBrNumber(valorDCL, 2), alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber((valorDCL / valorRCLO19) * 100, 4) + ' %', alignment: 'right' }
        ]);
        const valorLimite = +valorRCLO19 * 1.2;
        registros.push([
          { text: 'Limite Legal (arts.3º e 4º Res.nº 40 Senado)' },
          { text: this.funcaoService.convertToBrNumber(valorLimite, 2), alignment: 'right' },
          { text: '120,0000' + ' %', alignment: 'right' }
        ]);
        registros.push([
          { text: 'Excesso a Regularizar' },
          { text: (+valorDCL - +valorLimite) > 0 ? this.funcaoService.convertToBrNumber(+valorDCL - +valorLimite, 2) : '0,00', alignment: 'right' },
          { text: ((+valorDCL - +valorLimite) > 0 ? this.funcaoService.convertToBrNumber(((+valorDCL - +valorLimite) * 100) / +valorLimite, 4) : '0,0000') + ' %', alignment: 'right' }
        ]);
        registros.push([
          {
            text: '', border: [true, false, true, false], colSpan: 3, margin: [0, 15, 0, 0]
          }, '', ''
        ]);
      } else if (item.relatorio === 'CG' && this.orgaoFiltro?.id !== 2) {
        registros.push([
          { text: item.nome, bold: true },
          { text: '' },
          { text: '' }
        ]);
        registros.push([
          { text: 'Montante' },
          { text: this.funcaoService.convertToBrNumber(item.valor, 2), alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber((item.valor / valorRCLO19) * 100, 4) + ' %', alignment: 'right' }
        ]);
        const valorLimite = +valorRCLO19 * 0.22;
        registros.push([
          { text: 'Limite Legal (art. 9º Res.nº 43 Senado)' },
          { text: this.funcaoService.convertToBrNumber(valorLimite, 2), alignment: 'right' },
          { text: '22,0000' + ' %', alignment: 'right' }
        ]);
        registros.push([
          { text: 'Excesso a Regularizar' },
          { text: (+item.valor - +valorLimite) > 0 ? this.funcaoService.convertToBrNumber(+item.valor - +valorLimite, 2) : '0,00', alignment: 'right' },
          { text: ((+item.valor - +valorLimite) > 0 ? this.funcaoService.convertToBrNumber(((+item.valor - +valorLimite) * 100) / +valorLimite, 4) : '0,0000') + ' %', alignment: 'right' }
        ]);
        registros.push([
          {
            text: '', border: [true, false, true, false], colSpan: 3, margin: [0, 15, 0, 0]
          }, '', ''
        ]);
      } else if (item.relatorio === 'OP' && this.orgaoFiltro?.id !== 2) {
        registros.push([
          { text: item.nome, bold: true },
          { text: '' },
          { text: '' }
        ]);
        registros.push([
          { text: 'Realizadas no período' },
          { text: this.funcaoService.convertToBrNumber(item.valor, 2), alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber((item.valor / valorRCLO19) * 100, 4) + ' %', alignment: 'right' }
        ]);
        const valorLimite = +valorRCLO19 * 0.16;
        registros.push([
          { text: 'Limite legal (inc. I, art. 7º Res.nº 43 Senado)' },
          { text: this.funcaoService.convertToBrNumber(valorLimite, 2), alignment: 'right' },
          { text: '16,0000' + ' %', alignment: 'right' }
        ]);
        registros.push([
          { text: 'Excesso a Regularizar' },
          { text: (+item.valor - +valorLimite) > 0 ? this.funcaoService.convertToBrNumber(+item.valor - +valorLimite, 2) : '0,00', alignment: 'right' },
          { text: ((+item.valor - +valorLimite) > 0 ? this.funcaoService.convertToBrNumber(((+item.valor - +valorLimite) * 100) / +valorLimite, 4) : '0,0000') + ' %', alignment: 'right' }
        ]);
        registros.push([
          {
            text: '', border: [true, false, true, false], colSpan: 3, margin: [0, 15, 0, 0]
          }, '', ''
        ]);
      } else if (item.relatorio === 'ARO' && this.orgaoFiltro?.id !== 2) {
        registros.push([
          { text: item.nome, bold: true },
          { text: '' },
          { text: '' }
        ]);
        registros.push([
          { text: 'Saldo devedor' },
          { text: this.funcaoService.convertToBrNumber(item.valor, 2), alignment: 'right' },
          { text: this.funcaoService.convertToBrNumber((item.valor / valorRCLO19) * 100, 4) + ' %', alignment: 'right' }
        ]);
        const valorLimite = +valorRCLO19 * 0.07;
        registros.push([
          { text: 'Limite legal (art. 10 Res.nº 43 Senado)' },
          { text: this.funcaoService.convertToBrNumber(valorLimite, 2), alignment: 'right' },
          { text: '7,0000' + ' %', alignment: 'right' }
        ]);
        registros.push([
          { text: 'Excesso a Regularizar' },
          { text: (+item.valor - +valorLimite) > 0 ? this.funcaoService.convertToBrNumber(+item.valor - +valorLimite, 2) : '0,00', alignment: 'right' },
          { text: ((+item.valor - +valorLimite) > 0 ? this.funcaoService.convertToBrNumber(((+item.valor - +valorLimite) * 100) / +valorLimite, 4) : '0,0000') + ' %', alignment: 'right' }
        ]);
        registros.push([
          {
            text: '', border: [true, false, true, true], colSpan: 3, margin: [0, 15, 0, 0]
          }, '', ''
        ]);
      }
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 120, 120],
        body: registros
      }
    },
    {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*'],
        body: assinaturas
      }
    }];
  }

  public normalizar(lista: any[]) {
    const listaExportar = []
    let valorRCLO19 = 0;
    let valorRCLO21 = 0;

    for (const item of lista) {
      if (item.relatorio === 'RCL') {
        valorRCLO19 = item.valor['O19'];
        valorRCLO21 = item.valor['O21'];

        if (item.nome === 'Receita Corrente Líquida Ajustada para Cálculo dos Limites de endividamento') {
          const quadro_comparativo = {
            nome: item.nome,
            valor: '',
            porcentagem: 'R$ ' + this.funcaoService.convertToBrNumber(valorRCLO19)
          }
          listaExportar.push(quadro_comparativo)
        } else {
          const quadro_comparativo = {
            nome: item.nome,
            valor: '',
            porcentagem: 'R$ ' + this.funcaoService.convertToBrNumber(valorRCLO21)
          }
          listaExportar.push(quadro_comparativo)
        }
      } else if (item.relatorio === 'DP') {
        const espaco = {
          nome: '',
          valor: '',
          porcentagem: ''
        }
        listaExportar.push(espaco)

        const titulo_dp = {
          nome: '',
          valor: 'R$',
          porcentagem: '%'
        }
        listaExportar.push(titulo_dp)

        const itens = {
          nome: item.nome,
          valor: this.funcaoService.convertToBrNumber(item.valor),
          porcentagem: this.funcaoService.convertToBrNumber((+parseFloat(item.valor).toFixed() / +parseFloat(`${valorRCLO21}`).toFixed()) * 100, 4) + ' %'
        }
        listaExportar.push(itens)

        const aliquotaRCL021 = this.orgaoFiltro?.id !== 2 ? 0.54 : 0.06;
        const itens_dp = {
          nome: 'Limite Máximo (art. 20 LRF)',
          valor: this.funcaoService.convertToBrNumber(valorRCLO21 * aliquotaRCL021),
          porcentagem: (`${aliquotaRCL021 * 100},0000`) + ' %'
        }
        listaExportar.push(itens_dp)

        const valorPrudencial = this.orgaoFiltro?.id !== 2 ? +valorRCLO19 * 0.513 : +valorRCLO19 * 0.06 * 0.95;
        const itens_dp2 = {
          nome: 'Limite Prudencial 95% (par.ún.art.22 LRF)',
          valor: this.funcaoService.convertToBrNumber(valorPrudencial),
          porcentagem: (this.orgaoFiltro?.id !== 2 ? '51,3000' : '5,7000') + ' %'
        }
        listaExportar.push(itens_dp2)

        let valorExcesso = 0;
        if (this.orgaoFiltro?.id !== 2) {
          valorExcesso = +item.valor - (+valorRCLO19 * 0.54);
        } else {
          valorExcesso = +item.valor - valorRCLO19;
        }
        const itens_dp3 = {
          nome: 'Excesso a Regularizar',
          valor: valorExcesso > 0 ? this.funcaoService.convertToBrNumber(valorExcesso, 2) : '0,00',
          porcentagem: (valorExcesso > 0 ? this.funcaoService.convertToBrNumber((valorExcesso * 100) / +valorRCLO19, 4) : '0,0000') + ' %'
        }
        listaExportar.push(itens_dp3)

        const espaco2 = {
          nome: '',
          valor: '',
          porcentagem: ''
        }
        listaExportar.push(espaco2)
      } else if (item.relatorio === 'DCL') {

        const valorDCL = +item.valor;
        const titulo_dlc = {
          nome: item.nome,
          valor: '',
          porcentagem: ''
        }
        listaExportar.push(titulo_dlc)

        const itens_dlc = {
          nome: 'Saldo Devedor',
          valor: this.funcaoService.convertToBrNumber(valorDCL),
          porcentagem: this.funcaoService.convertToBrNumber((valorDCL / valorRCLO19) * 100, 4) + ' %'
        }
        listaExportar.push(itens_dlc)

        const valorLimite = +valorRCLO19 * 1.2;
        const itens_dlc2 = {
          nome: 'Limite Legal (arts.3º e 4º Res.nº 40 Senado)',
          valor: this.funcaoService.convertToBrNumber(valorLimite),
          porcentagem: '120,0000' + ' %'
        }
        listaExportar.push(itens_dlc2)

        const itens_dlc3 = {
          nome: 'Excesso a Regularizar',
          valor: (+valorDCL - +valorLimite) > 0 ? this.funcaoService.convertToBrNumber(+valorDCL - +valorLimite, 2) : '0,00',
          porcentagem: ((+valorDCL - +valorLimite) > 0 ? this.funcaoService.convertToBrNumber(((+valorDCL - +valorLimite) * 100) / +valorLimite, 4) : '0,0000') + ' %'
        }
        listaExportar.push(itens_dlc3)

        const espaco = {
          nome: '',
          valor: '',
          porcentagem: ''
        }
        listaExportar.push(espaco)
      } else if (item.relatorio === 'CG') {
        const titulo_cg = {
          nome: item.nome,
          valor: '',
          porcentagem: ''
        }
        listaExportar.push(titulo_cg)

        const montante = {
          nome: 'Montante',
          valor: this.funcaoService.convertToBrNumber(item.valor),
          porcentagem: this.funcaoService.convertToBrNumber((item.valor / valorRCLO19) * 100, 4) + ' %'
        }
        listaExportar.push(montante)

        const valorLimite = +valorRCLO19 * 0.22;
        const itens_cg = {
          nome: 'Limite Legal (art. 9º Res.nº 43 Senado)',
          valor: this.funcaoService.convertToBrNumber(valorLimite),
          porcentagem: '22,0000' + ' %'
        }
        listaExportar.push(itens_cg)

        const itens_cg3 = {
          nome: 'Excesso a Regularizar',
          valor: (+item.valor - +valorLimite) > 0 ? this.funcaoService.convertToBrNumber(+item.valor - +valorLimite, 2) : '0,00',
          porcentagem: ((+item.valor - +valorLimite) > 0 ? this.funcaoService.convertToBrNumber(((+item.valor - +valorLimite) * 100) / +valorLimite, 4) : '0,0000') + ' %'
        }
        listaExportar.push(itens_cg3)

        const espaco = {
          nome: '',
          valor: '',
          porcentagem: ''
        }
        listaExportar.push(espaco)
      } else if (item.relatorio === 'OP') {

        const titulo_op = {
          nome: item.nome,
          valor: '',
          porcentagem: ''
        }
        listaExportar.push(titulo_op)
        const itens = {
          nome: 'Realizadas no período',
          valor: this.funcaoService.convertToBrNumber(item.valor),
          porcentagem: this.funcaoService.convertToBrNumber((item.valor / valorRCLO19) * 100, 4) + ' %'
        }
        listaExportar.push(itens)

        const valorLimite = +valorRCLO19 * 0.16;
        const itens_op = {
          nome: 'Limite legal (inc. I, art. 7º Res.nº 43 Senado)',
          valor: this.funcaoService.convertToBrNumber(valorLimite),
          porcentagem: '16,0000' + ' %'
        }
        listaExportar.push(itens_op)

        const itens_op3 = {
          nome: 'Excesso a Regularizar',
          valor: (+item.valor - +valorLimite) > 0 ? this.funcaoService.convertToBrNumber(+item.valor - +valorLimite, 2) : '0,00',
          porcentagem: ((+item.valor - +valorLimite) > 0 ? this.funcaoService.convertToBrNumber(((+item.valor - +valorLimite) * 100) / +valorLimite, 4) : '0,0000') + ' %'
        }
        listaExportar.push(itens_op3)

        const espaco2 = {
          nome: '',
          valor: '',
          porcentagem: ''
        }
        listaExportar.push(espaco2)
      } else if (item.relatorio === 'ARO') {

        const titulo_aro = {
          nome: item.nome,
          valor: '',
          porcentagem: ''
        }
        listaExportar.push(titulo_aro)
        const itens = {
          nome: 'Saldo devedor',
          valor: this.funcaoService.convertToBrNumber(item.valor),
          porcentagem: this.funcaoService.convertToBrNumber((item.valor / valorRCLO19) * 100, 4) + ' %'
        }
        listaExportar.push(itens)

        const valorLimite = +valorRCLO19 * 0.07;
        const itens_aro = {
          nome: 'Limite legal (art. 10 Res.nº 43 Senado)',
          valor: this.funcaoService.convertToBrNumber(valorLimite),
          porcentagem: '7,0000' + ' %'
        }
        listaExportar.push(itens_aro)

        const itens_aro3 = {
          nome: 'Excesso a Regularizar',
          valor: (+item.valor - +valorLimite) > 0 ? this.funcaoService.convertToBrNumber(+item.valor - +valorLimite, 2) : '0,00',
          porcentagem: ((+item.valor - +valorLimite) > 0 ? this.funcaoService.convertToBrNumber(((+item.valor - +valorLimite) * 100) / +valorLimite, 4) : '0,0000') + ' %'
        }
        listaExportar.push(itens_aro3)
      }
    }
    return listaExportar
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'QUADRO COMPARATIVO COM OS LIMITES DA LRF: ', coluna: 'nome', bold: true },
      { titulo: '', coluna: 'valor' },
      { titulo: `REFERÊNCIA: ` + this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano, coluna: 'porcentagem' },
    ];
    return colunasDefault;
  }

}
