import { Injectable, Injector } from "@angular/core";
import { BaseResourceService, Exercicio, ExercicioService, FuncaoService, Relatorio, GlobalService, OrgaoAssinaturaService, OrgaoAssinatura, Login } from "eddydata-lib";
import { Observable, Subject } from "rxjs";
import { catchError, map, takeUntil } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BalanceteLeiOrganicaService extends BaseResourceService<Exercicio> {

  protected unsubscribe: Subject<void> = new Subject();
  private assinatura: OrgaoAssinatura;

  constructor(
    protected exercicioService: ExercicioService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected injector: Injector
  ) {
    super('contabil-relatorio', injector);
  }

  obterLeiOrganica(mes: number, exercicio: number, orgaos: number[], usuarioOrgaoId: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/lei-organica/${mes}/${exercicio}/${orgaos.join()}/${usuarioOrgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  async montarBalanceteLeiOrganica(mes: number, exercicioId: number, orgaos: number[], login: Login) {
    const parametros: {} = {};
    if (mes) {
      parametros['mes'] = mes;
    }
    if (exercicioId) {
      parametros['ano'] = exercicioId;
    }
    parametros['orgaos'] = orgaos.join();

    const ex = await this.exercicioService.obterId(exercicioId).toPromise();

    this.obterLeiOrganica(mes, exercicioId, orgaos, login.orgao.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        const [disponibilidade, bloqueio, receitas, receita_extra, despesas, gastos] = dados;

        Relatorio.imprimirPersonalizado(
          '',
          login.usuario.nome,
          login.usuario.sobrenome,
          login.orgao.nome,
          login.brasao,
          this.conteudoCabecalho(true, mes, ex)
            .concat(this.conteudoArrecadacao(disponibilidade, bloqueio, receitas, receita_extra, ex))
            .concat(this.conteudoCabecalho(false, mes, ex))
            .concat(this.conteudoDespesa(despesas, gastos))
            .concat(await this.conteudoAssinatura(ex, mes)),
          'portrait',
          'Lei orgânica',
          {
            linhas: {
              hLineWidth(i, node) {
                return 1;
              },
              vLineWidth(i) {
                return 1;
              },
              hLineColor(i) {
                return 'black';
              },
              paddingLeft(i) {
                return 3;
              },
              paddingRight(i, node) {
                return 3;
              }
            }
          },
          true
        );
      });
  }

  private conteudoCabecalho(receita: boolean, mes: number, exercicio: Exercicio): {}[] {
    let brasaoImage: {};
    if (this.login.brasao) {
      brasaoImage = {
        image: this.login.brasao,
        width: 70,
        alignment: 'left',
        margin: [0, 0, 0, 0],
        border: [false, false, false, false]
      };
    } else {
      brasaoImage = { margin: [0, 10, 0, 0], text: '', border: [false, false, false, false] };
    }

    brasaoImage['rowSpan'] = 2;

    const conteudo = [];

    if (receita) {
      conteudo.push([brasaoImage, { text: this.login.orgao.nome, bold: true, alignment: 'center', fontSize: 17, border: [false, false, false, false] }]);
    } else {
      conteudo.push([{ text: '', border: [false], }, { text: '', border: [false] }]);
    }
    const titulo = receita
      ? `DEMONSTRATIVO DA ARRECADAÇÃO DO MÊS DE ${this.globalService.obterMes(+mes, false)}/${exercicio.ano}`
      : `DEMONSTRATIVO DA DESPESA DO MÊS DE ${this.globalService.obterMes(+mes, false)}/${exercicio.ano}`;

    conteudo.push([
      { text: '', border: [false] },
      { text: `${titulo}`, bold: true, alignment: 'center', fontSize: 13, border: [false, false, false, false], margin: [0, 1, 0, 0] },
    ]);
    conteudo.push([
      { text: '', border: [false], },
      { text: '(Artigo 81 § 6º e 7º da Lei Orgânica)', bold: true, alignment: 'center', fontSize: 13, border: [false, false, false, false], margin: [0, 0, 0, 1] },
    ]);

    return [{
      pageBreak: !receita ? 'before' : 'null',
      layout: 'linhas',
      table: {
        widths: [50, '*'],
        body: conteudo
      }
    }];
  }

  private conteudoArrecadacao(disponibilidade: any[], bloqueios: any[], receitas: any[], receita_extra: any[], exercicio: Exercicio): {}[] {
    const conteudo = [];

    conteudo.push([
      { text: 'DISCRIMINAÇÃO', bold: true, fontSize: 12, alignment: 'center' },
      { text: 'ARRECADADO MENSAL', bold: true, fontSize: 12, alignment: 'center' },
      { text: 'ARRECADADO ACUMULADO', bold: true, fontSize: 12, alignment: 'center' }
    ]);
    conteudo.push([
      { text: disponibilidade[0].titulo, fontSize: 10, border: [true, false, true, false] },
      { text: '', fontSize: 10, alignment: 'right', border: [true, false, true, false] },
      { text: this.funcaoService.convertToBrNumber(disponibilidade[0].acumulado), fontSize: 10, alignment: 'right', border: [true, false, true, false] }
    ]);
    conteudo.push([
      { text: bloqueios[0].titulo, fontSize: 10, border: [true, false, true, false] },
      { text: '', fontSize: 10, alignment: 'right', border: [true, false, true, false] },
      { text: this.funcaoService.convertToBrNumber(bloqueios[0].acumulado), fontSize: 10, alignment: 'right', border: [true, false, true, false] }
    ]);
    conteudo.push([
      { text: `Disponibiliade financeira liquida em 31/12/${+exercicio.ano - 1}`, fontSize: 10, border: [true, false, true, false] },
      { text: '', fontSize: 10, alignment: 'right', border: [true, false, true, false] },
      { text: this.funcaoService.convertToBrNumber(+disponibilidade[0].acumulado + +bloqueios[0].acumulado), fontSize: 10, alignment: 'right', border: [true, false, true, false] }
    ]);

    const grupoReceitas = this.funcaoService.agrupar(receitas, 'grupo', ['mensal', 'acumulado']);
    let subtotal_mensal = 0;
    let subtotal_acumulado = 0;

    for (let i = 0; i < grupoReceitas.length; i++) {
      const grupo = grupoReceitas[i];
      subtotal_mensal += +grupo.totalizadores['mensal'];
      subtotal_acumulado += +grupo.totalizadores['acumulado'];
      conteudo.push([
        { text: grupo['grupo'], fontSize: 10, border: [true, false, true, false] },
        { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['mensal']), fontSize: 10, alignment: 'right', border: [true, false, true, false] },
        { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['acumulado']), fontSize: 10, alignment: 'right', border: [true, false, true, false] }
      ]);
      for (const item of grupo.registros) {
        conteudo.push([
          { text: `\u200B\t${item['titulo']}`, fontSize: 10, border: [true, false, true, false] },
          { text: this.funcaoService.convertToBrNumber(+item['mensal']), fontSize: 10, alignment: 'right', border: [true, false, true, false] },
          { text: this.funcaoService.convertToBrNumber(+item['acumulado']), fontSize: 10, alignment: 'right', border: [true, false, true, false] }
        ]);
      }
    }
    conteudo.push([
      { text: `SUBTOTAL RECEITA ORÇAMENTÁRIA`, fontSize: 10, border: [true, false, true, false] },
      { text: this.funcaoService.convertToBrNumber(subtotal_mensal), fontSize: 10, alignment: 'right', border: [true, false, true, false] },
      { text: this.funcaoService.convertToBrNumber(subtotal_acumulado), fontSize: 10, alignment: 'right', border: [true, false, true, false] }
    ]);

    conteudo.push([
      { text: receita_extra[0]['titulo'], fontSize: 10, border: [true, false, true, false] },
      { text: this.funcaoService.convertToBrNumber(receita_extra[0]['mensal']), fontSize: 10, alignment: 'right', border: [true, false, true, false] },
      { text: this.funcaoService.convertToBrNumber(receita_extra[0]['acumulado']), fontSize: 10, alignment: 'right', border: [true, false, true, false] }
    ]);

    conteudo.push([
      { text: 'TOTAL ENTRADAS', fontSize: 11, bold: true },
      { text: this.funcaoService.convertToBrNumber(+receita_extra[0]['mensal'] + +subtotal_mensal), fontSize: 11, alignment: 'right', bold: true },
      { text: this.funcaoService.convertToBrNumber(+receita_extra[0]['acumulado'] + +subtotal_acumulado), fontSize: 11, alignment: 'right', bold: true }
    ]);

    return [{
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', 'auto', 'auto'],
        body: conteudo
      }
    }];
  }

  private conteudoDespesa(despesas: any[], gastos: any[]): {}[] {
    const conteudo = [];

    conteudo.push([
      { text: 'ESPECIFICAÇÃO', bold: true, fontSize: 12, alignment: 'center' },
      { text: 'PAGA MES', bold: true, fontSize: 12, alignment: 'center' },
      { text: 'PAGA ACUMULADA', bold: true, fontSize: 12, alignment: 'center' }
    ]);

    let subtotal_mensal = 0;
    let subtotal_acumulado = 0;

    for (const item of despesas) {
      let acumulado_antigo = item.acumulado_antigo ? +item.acumulado_antigo : 0
      let mensal_antigo = item.mensal_antigo ? +item.mensal_antigo : 0
      subtotal_mensal += (+item.mensal + mensal_antigo)
      subtotal_acumulado += (+item.acumulado + acumulado_antigo)
      conteudo.push([
        { text: item.titulo, fontSize: 10, border: [true, false, true, false] },
        { text: this.funcaoService.convertToBrNumber(+item.mensal + mensal_antigo), fontSize: 10, alignment: 'right', border: [true, false, true, false] },
        { text: this.funcaoService.convertToBrNumber(+item.acumulado + acumulado_antigo), fontSize: 10, alignment: 'right', border: [true, false, true, false] }
      ]);
    }

    conteudo.push([
      { text: 'SUB TOTAL SAÍDAS', fontSize: 10, bold: true },
      { text: this.funcaoService.convertToBrNumber(subtotal_mensal), fontSize: 10, alignment: 'right', bold: true },
      { text: this.funcaoService.convertToBrNumber(subtotal_acumulado), fontSize: 10, alignment: 'right', bold: true }
    ]);

    conteudo.push([{ text: '', border: [false] }, { text: '', border: [false] }, { text: '', border: [false] }]);

    let i = 1;
    let valor_i = 0;
    let valor_ii = 0;
    let valor_iii = 0;
    let valor_iv = 0;
    let valor_v = 0;
    for (const item of gastos) {
      let bordas: boolean[] = [true, false, true, false];
      switch (i) {
        case 1:
          bordas = [true, true, true, false];
          break;
        case 3:
          valor_i = +item.acumulado;
          break;
        case 4:
          valor_ii = +item.acumulado;
          break;
        case 5:
          valor_iii = +item.acumulado;
          break;
        case 6:
          valor_iv = +item.acumulado;
          break;
        case 7:
          valor_v = +item.acumulado;
          break;
      }
      i++;
      conteudo.push([
        { text: item.titulo, fontSize: 10, border: bordas, colSpan: 2 }, '',
        { text: this.funcaoService.convertToBrNumber(item.acumulado), fontSize: 10, alignment: 'right', border: bordas }
      ]);
    }
    conteudo.push([
      { text: 'DISPONIBILIDADE EMPENHADA E RESTOS A PAGAR (VI)=(I-II-III-IV-V)', fontSize: 10, border: [true, true, false, true], colSpan: 2, bold: true },
      { text: '', border: [false, true, true, true] },
      { text: this.funcaoService.convertToBrNumber(valor_i - valor_ii - valor_iii - valor_iv - valor_v), fontSize: 10, alignment: 'right', border: [true, true, true, true], bold: true }
    ]);
    conteudo.push([
      { text: 'DISPONIBILIDADE LIQUID./PROC. E RESTOS A PAGAR (VII)=(I-III-IV-V)', fontSize: 10, border: [true, true, false, true], colSpan: 2, bold: true },
      { text: '', border: [false, true, true, true] },
      { text: this.funcaoService.convertToBrNumber(valor_i - valor_iii - valor_iv - valor_v), fontSize: 10, alignment: 'right', border: [true, true, true, true], bold: true }
    ]);

    return [{
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', 'auto', 'auto'],
        body: conteudo
      }
    }];
  }

  private async conteudoAssinatura(exercicio: Exercicio, mes: number) {
    const conteudo = [];
    const data = `${exercicio.ano}-${this.funcaoService.strZero(mes, 2)}-${this.funcaoService.ultimoDiaMes(+mes, exercicio.ano)}`; 
    this.assinatura = await this.assinaturaService.obter({ orgao_id: this.login.orgao.id, 'data_limite$ge': data, 'orderBy': 'data_limite$ASC' }).toPromise();

    if (!this.assinatura) {
      toastr.error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
      throw new Error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
    }

    conteudo.push([
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 20, 0, 0]
      },
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 20, 0, 0]
      },
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 20, 0, 0]
      }
    ]);
    conteudo.push([
      { text: this.assinatura.ordenador_despesa, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.contador, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.tesoureiro, border: [false, false, false, false], bold: true, alignment: 'center' }
    ]);
    conteudo.push([
      { text: this.assinatura.cargo_ordenador_despesa, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.cargo_contador, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.cargo_tesoureiro, border: [false, false, false, false], bold: true, alignment: 'center' }
    ]);

    return [{
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['auto', 'auto', 'auto'],
        body: conteudo
      }
    }];
  }
}