import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PreLiquidacaoListComponent } from './pre-liquidacao-list/pre-liquidacao-list.component';
import { PreLiquidacaoViewComponent } from './pre-liquidacao-view/pre-liquidacao-view.component';
import { PreLiquidacaoFormComponent } from './pre-liquidacao-form/pre-liquidacao-form.component';
import { PreLiquidacaoRptComponent } from './pre-liquidacao-rpt/pre-liquidacao-rpt.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: PreLiquidacaoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: PreLiquidacaoFormComponent, canActivate: [AuthGuard] },
  { path: 'novo/:empenho', component: PreLiquidacaoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: PreLiquidacaoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: PreLiquidacaoViewComponent, canActivate: [AuthGuard] },
  { path: 'relatorios', component: PreLiquidacaoRptComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PreLiquidacaoRoutingModule { }
