import { DatePipe } from '@angular/common';
import { Injector } from '@angular/core';
import { Assinaturas } from 'administrativo-lib';
import { EmpenhoExtra, FormatoExportacao, FuncaoService, GlobalService, Liquidacao, Login, ParametroContabilService, Relatorio } from 'eddydata-lib';

export class EmpenhoSemOPRpt {

    protected datepipe: DatePipe;
    protected funcaoService: FuncaoService;
    protected globalService: GlobalService;

    private log: Login;
    private nomeTipo: string;

    public assinatura_tesouraria: boolean;

    constructor(
        protected injector: Injector,
        protected parametroContabilidadeServico: ParametroContabilService,
    ) {
        this.funcaoService = new FuncaoService();
        this.globalService = new GlobalService();
        this.datepipe = new DatePipe('pt');
    }

    public async imprimirEmpenhosSemOPRpt(formato: FormatoExportacao, lista: Lista, login: Login, infoExtras: any) {
        this.log = login;

        if (formato === 'pdf') {
            Relatorio.imprimirPersonalizado(`${infoExtras.tipoDespesa} OS TIPOS ${infoExtras.tipoRetencao} NÃO INCLUÍDOS EM OP`, login.usuario.nome, login.usuario.sobrenome,
                login.orgao.nome, login.brasao,
                await this.montarConteudo(lista, infoExtras),
                'landscape', `${infoExtras.tipoDespesa} OS TIPOS ${infoExtras.tipoRetencao} NÃO INCLUÍDOS EM OP`,
                {
                    linhas: {
                        hLineWidth() {
                            return 1;
                        },
                        vLineWidth() {
                            return 1;
                        },
                        hLineColor() {
                            return 'black';
                        },
                        paddingLeft() {
                            return 3;
                        },
                        paddingRight() {
                            return 3;
                        }
                    }
                }, true);
        } else if (formato === 'csv') {
            this.imprimirCSV(lista.emo, lista.eme, lista.emr, infoExtras, `${infoExtras.tipoDespesa} OS TIPOS ${infoExtras.tipoRetencao} NÃO INCLUÍDOS EM OP`);
        }
    }

    private async montarConteudo(lista: Lista, infoExtras: any) {
        const conteudo = [];

        conteudo.push(
            this.dadosCabecalho(this.log, infoExtras)
                .concat(await this.dadosDetalhe(lista.emo, lista.eme, lista.emr, infoExtras))
            //.concat(this.dadosAssinatura(this.log))
        );
        return conteudo;
    }

    private dadosCabecalho(log: Login, infoExtras: any): {}[] {
        let brasaoImage: {};
        if (log.brasao) {
            brasaoImage = {
                image: log.brasao,
                width: 70,
                alignment: 'center',
                margin: [0, 30, 0, 0]
            };
        } else {
            brasaoImage = { margin: [0, 30, 0, 0], text: '' };
        }

        if (infoExtras.tipoDespesa === 'EMPENHOS DE TODOS') {
            const conteudo = [
                [{ text: brasaoImage, border: [false, false, false, false] }],
                [{ text: log.orgao.nome, bold: true, alignment: 'center', fontSize: 13, border: [false, false, false, false] }],
                [{
                    text: `${infoExtras.tipoDespesa} OS TIPOS ${infoExtras.tipoRetencao} NÃO INCLUÍDOS EM OP`,
                    bold: true, alignment: 'center', fontSize: 12, border: [false, false, false, false]
                }],
                [{
                    text: `Período de ${this.datepipe.transform(infoExtras.dataIncial, 'dd/MM/yyyy', 'GMT')} a ${this.datepipe.transform(infoExtras.dataFinal, 'dd/MM/yyyy', 'GMT')} para a ${infoExtras.tipoData === 1 ? 'data de liquidação' : 'data de vencimento'}`,
                    alignment: 'right', fontSize: 8, border: [false, false, false, false], margin: [0, 10, 0, 10]
                }]
            ];

            return [{
                layout: 'linhas',
                table: {
                    dontBreakRows: true,
                    widths: ['*'],
                    body: conteudo
                }
            }];
        } else {
            const conteudo = [
                [{ text: brasaoImage, border: [false, false, false, false] }],
                [{ text: log.orgao.nome, bold: true, alignment: 'center', fontSize: 13, border: [false, false, false, false] }],
                [{
                    text: `${infoExtras.tipoDespesa} ${infoExtras.tipoRetencao} NÃO INCLUÍDOS EM OP`,
                    bold: true, alignment: 'center', fontSize: 12, border: [false, false, false, false]
                }],
                [{
                    text: `Período de ${this.datepipe.transform(infoExtras.dataIncial, 'dd/MM/yyyy', 'GMT')} a ${this.datepipe.transform(infoExtras.dataFinal, 'dd/MM/yyyy', 'GMT')} para a ${infoExtras.tipoData === 1 ? 'data de liquidação' : 'data de vencimento'}`,
                    alignment: 'right', fontSize: 8, border: [false, false, false, false], margin: [0, 10, 0, 10]
                }]
            ];

            return [{
                layout: 'linhas',
                table: {
                    dontBreakRows: true,
                    widths: ['*'],
                    body: conteudo
                }
            }];
        }

    }

    private async dadosDetalhe(orcamentario: any[], extra: EmpenhoExtra[], resto: any[], infoExtras: any): Promise<{}[]> {
        let conteudo = [];
        conteudo.push([
            { text: 'DT LIQ.', fontSize: 6, alignment: 'center', bold: true, margin: [0, 5, 0, 0] },
            { text: 'VENCIMENTO', fontSize: 6, alignment: 'center', bold: true, margin: [0, 5, 0, 0] },
            { text: 'EMPENHO', fontSize: 6, alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
            { text: 'PARCELA', fontSize: 6, alignment: 'center', bold: true, margin: [0, 5, 0, 0] },
            { text: 'FICHA', fontSize: 6, alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
            { text: 'RECURSO', fontSize: 6, alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
            { text: 'DOCUMENTO', fontSize: 6, alignment: 'center', bold: true, margin: [0, 5, 0, 0] },
            { text: 'FORNECEDOR', fontSize: 6, alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
            { text: 'V. LÍQUIDO', fontSize: 6, alignment: 'center', bold: true, margin: [0, 5, 0, 0] },
            //{ text: 'V. RETIDO', fontSize: 6, alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
        ]);

        let valorLiquidoEMO = 0.0;
        //let valorRetidoEMO = 0.0;
        let valorLiquidoEME = 0.0;
        //let valorRetidoEME = 0.0;
        let valorLiquidoEMR = 0.0;
        //let valorRetidoEMR = 0.0;


        if (infoExtras.tipoDespesa === 'EMPENHOS DE TODOS' && orcamentario.length) {
            conteudo.push([
                { text: 'EMPENHOS ORÇAMENTÁRIOS', fontSize: 6, alignment: 'left', bold: true, margin: [0, 0, 0, 0], colSpan: 9 },
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                //'',
            ]);
        }

        if (orcamentario.length) {
            let list = orcamentario.filter((v, i, a) => +a.findIndex(t => (+t.a_id === +v.a_id)) === i)
            for (const emo of list) {
                if (+emo.a_valor_liquidado + +emo.total_anulado !== 0) {
                    valorLiquidoEMO += + +emo.a_valor_liquidado
                    conteudo.push([
                        { text: this.datepipe.transform(emo.a_data_liquidacao, 'dd/MM/yyyy', 'GMT'), fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                        { text: this.datepipe.transform(emo.a_data_vencimento, 'dd/MM/yyyy', 'GMT'), fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                        { text: emo.e_numero, fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                        { text: emo.a_parcela, fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                        { text: emo.f_numero, fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                        { text: emo?.apv_codigo.length == 9 ? emo.apv_codigo : emo.re_codigo + emo.ap_codigo + '0000', fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                        { text: emo.a_documento, fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                        { text: `${emo.fav_id} - ${emo.fav_nome}`, fontSize: 6, alignment: 'left', margin: [0, 0, 0, 0] },
                        { text: this.funcaoService.convertToBrNumber(+emo.a_valor_liquidado + +emo.total_anulado), fontSize: 6, alignment: 'right', margin: [0, 0, 0, 0] },
                        //{ text: this.funcaoService.convertToBrNumber(emo.r_valor_retido), fontSize: 6, alignment: 'right', margin: [0, 0, 0, 0] },
                    ]);
                }
            }
            conteudo.push([
                { text: 'TOTAL DOS EMPENHOS ORÇAMENTÁRIOS', fontSize: 6, alignment: 'left', bold: true, margin: [0, 0, 0, 0], colSpan: 8 },
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                { text: this.funcaoService.convertToBrNumber(valorLiquidoEMO), fontSize: 6, alignment: 'right', bold: true, margin: [0, 0, 0, 0] },
                //{ text: this.funcaoService.convertToBrNumber(valorRetidoEMO), fontSize: 6, alignment: 'right', bold: true, margin: [0, 0, 0, 0] },
            ]);
        }

        if (infoExtras.tipoDespesa === 'EMPENHOS DE TODOS' && resto.length) {
            conteudo.push([
                { text: 'EMPENHOS EXTRAS', fontSize: 6, alignment: 'left', bold: true, margin: [0, 0, 0, 0], colSpan: 9 },
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                //'',
            ]);
        }

        if (extra.length) {
            let list = extra.filter((v, i, a) => +a.findIndex(t => (+t.id === +v.id)) === i)
            for (const eme of list) {
                valorLiquidoEME += + +eme.valor_empenho
                conteudo.push([
                    { text: this.datepipe.transform(eme.data_empenho, 'dd/MM/yyyy', 'GMT'), fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                    { text: this.datepipe.transform(eme.data_vencimento, 'dd/MM/yyyy', 'GMT'), fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                    { text: eme.numero, fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                    { text: eme.parcela, fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                    { text: eme.ficha.numero, fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                    { text: eme.ficha.aplicacao_variavel?.codigo.length == 9 ? eme.ficha.aplicacao_variavel?.codigo : eme.ficha.recurso.codigo + eme.ficha.aplicacao.codigo + '0000', fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                    { text: eme.documento, fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                    { text: `${eme.favorecido.id} - ${eme.favorecido.nome}`, fontSize: 6, alignment: 'left', margin: [0, 0, 0, 0] },
                    { text: this.funcaoService.convertToBrNumber(eme.valor_empenho), fontSize: 6, alignment: 'right', margin: [0, 0, 0, 0] },
                    //{ text: this.funcaoService.convertToBrNumber(eme.r_valor_retido), fontSize: 6, alignment: 'right', margin: [0, 0, 0, 0] },
                ]);
            }
            conteudo.push([
                { text: 'TOTAL DOS EMPENHOS EXTRAS', fontSize: 6, alignment: 'left', bold: true, margin: [0, 0, 0, 0], colSpan: 8 },
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                { text: this.funcaoService.convertToBrNumber(valorLiquidoEME), fontSize: 6, alignment: 'right', bold: true, margin: [0, 0, 0, 0] },
                //{ text: this.funcaoService.convertToBrNumber(valorRetidoEME), fontSize: 6, alignment: 'right', bold: true, margin: [0, 0, 0, 0] },
            ])

        }

        if (infoExtras.tipoDespesa === 'EMPENHOS DE TODOS' && resto.length) {
            conteudo.push([
                { text: 'EMPENHOS RESTOS A PAGAR', fontSize: 6, alignment: 'left', bold: true, margin: [0, 0, 0, 0], colSpan: 9 },
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                //'',
            ])
        }

        if (resto.length) {
            let list = resto.filter((v, i, a) => +a.findIndex(t => (+t.a_id === +v.a_id)) === i)
            for (const emr of list) {
                if (+emr.a_valor_liquidado + +emr.total_anulado !== 0) {
                    valorLiquidoEMR += + +emr.a_valor_liquidado
                    conteudo.push([
                        { text: this.datepipe.transform(emr.a_data_liquidacao, 'dd/MM/yyyy', 'GMT'), fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                        { text: this.datepipe.transform(emr.a_data_vencimento, 'dd/MM/yyyy', 'GMT'), fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                        { text: emr.e_numero, fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                        { text: emr.a_parcela, fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                        { text: emr.numero_ficha, fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                        { text: emr.e_recurso_variavel?.length === 9 ? emr.e_recurso_variavel : emr.e_recurso + emr.e_aplicacao + '0000', fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                        { text: emr.a_documento, fontSize: 6, alignment: 'center', margin: [0, 0, 0, 0] },
                        { text: `${emr.fav_id} - ${emr.fav_nome}`, fontSize: 6, alignment: 'left', margin: [0, 0, 0, 0] },
                        { text: this.funcaoService.convertToBrNumber(+emr.a_valor_liquidado + +emr.total_anulado), fontSize: 6, alignment: 'right', margin: [0, 0, 0, 0] },
                        //{ text: this.funcaoService.convertToBrNumber(emr.r_valor_retido), fontSize: 6, alignment: 'right', margin: [0, 0, 0, 0] },
                    ]);
                }
            }
            conteudo.push([
                { text: 'TOTAL DOS EMPENHOS RESTOS A PAGAR', fontSize: 6, alignment: 'left', bold: true, margin: [0, 0, 0, 0], colSpan: 8 },
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                { text: this.funcaoService.convertToBrNumber(valorLiquidoEMR), fontSize: 6, alignment: 'right', bold: true, margin: [0, 0, 0, 0] },
                //{ text: this.funcaoService.convertToBrNumber(valorRetidoEMR), fontSize: 6, alignment: 'right', bold: true, margin: [0, 0, 0, 0] },
            ]);
        }

        let totalLiquido = valorLiquidoEME + valorLiquidoEMO + valorLiquidoEMR
        conteudo.push([
            { text: 'TOTAL DOS EMPENHOS', fontSize: 6, alignment: 'left', bold: true, margin: [0, 0, 0, 0], colSpan: 8 },
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            { text: this.funcaoService.convertToBrNumber(totalLiquido), fontSize: 6, alignment: 'right', bold: true, margin: [0, 0, 0, 0] },
        ]);

        this.assinatura_tesouraria = (await this.parametroContabilidadeServico.obter({ orgao_id: this.log.orgao.id }).toPromise()).assinatura_tesouraria;

        const ass = new Assinaturas(this.log.orgao, this.injector);
        const assinaturas = await ass.dadosAssinatura(undefined, true, null, false, false, this.assinatura_tesouraria);

        return this.assinatura_tesouraria ? [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                headerRows: 1,
                widths: [50, 55, 40, 40, 30, 50, 100, '*', 60],
                body: conteudo
            }
        }, {
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                headerRows: 0,
                widths: ['*', '*', '*'],
                body: assinaturas
            }
        }] : [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                headerRows: 1,
                widths: [50, 55, 40, 40, 30, 50, 100, '*', 60],
                body: conteudo
            }
        }]
    }

    private imprimirCSV(orcamentario: any[], extra: EmpenhoExtra[], resto: any[], infoExtras: any, titulo: string) {

        let conteudo: {}[][] = [];
        conteudo.push([
            { text: 'DT LIQ.' },
            { text: 'VENCIMENTO' },
            { text: 'EMPENHO' },
            { text: 'PARCELA' },
            { text: 'FICHA' },
            { text: 'RECURSO' },
            { text: 'DOCUMENTO' },
            { text: 'FORNECEDOR' },
            { text: 'V. LÍQUIDO' },
        ]);

        let valorLiquidoEMO = 0.0;
        let valorLiquidoEME = 0.0;
        let valorLiquidoEMR = 0.0;

        if (infoExtras.tipoDespesa === 'EMPENHOS DE TODOS' && orcamentario.length) {
            conteudo.push([{ text: 'EMPENHOS ORÇAMENTÁRIOS' }]);
        }

        if (orcamentario.length) {
            let list = orcamentario.filter((v, i, a) => +a.findIndex(t => (+t.a_id === +v.a_id)) === i)
            for (const emo of list) {
                if (+emo.a_valor_liquidado + +emo.total_anulado !== 0) {
                    valorLiquidoEMO += + +emo.a_valor_liquidado
                    conteudo.push([
                        { text: this.datepipe.transform(emo.a_data_liquidacao, 'dd/MM/yyyy', 'GMT') },
                        { text: this.datepipe.transform(emo.a_data_vencimento, 'dd/MM/yyyy', 'GMT') },
                        { text: emo.e_numero },
                        { text: emo.a_parcela },
                        { text: emo.a_parcela },
                        { text: emo?.apv_codigo.length == 9 ? emo.apv_codigo : emo.re_codigo + emo.ap_codigo + '0000' },
                        { text: emo.a_documento },
                        { text: `${emo.fav_id} - ${emo.fav_nome}` },
                        { text: this.funcaoService.convertToBrNumber(+emo.a_valor_liquidado + +emo.total_anulado) },
                    ]);
                }
            }

            conteudo.push([
                { text: 'TOTAL DOS EMPENHOS ORÇAMENTÁRIOS' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: this.funcaoService.convertToBrNumber(valorLiquidoEMO) },
            ]);
        }

        if (infoExtras.tipoDespesa === 'EMPENHOS DE TODOS' && resto.length) {
            conteudo.push([{ text: 'EMPENHOS EXTRAS' }]);
        }

        if (extra.length) {
            let list = extra.filter((v, i, a) => +a.findIndex(t => (+t.id === +v.id)) === i)
            for (const eme of list) {
                valorLiquidoEME += + +eme.valor_empenho
                conteudo.push([
                    { text: this.datepipe.transform(eme.data_empenho, 'dd/MM/yyyy', 'GMT') },
                    { text: this.datepipe.transform(eme.data_vencimento, 'dd/MM/yyyy', 'GMT') },
                    { text: eme.numero },
                    { text: eme.parcela },
                    { text: eme.ficha.numero },
                    { text: eme.ficha.aplicacao_variavel?.codigo.length == 9 ? eme.ficha.aplicacao_variavel?.codigo : eme.ficha.recurso.codigo + eme.ficha.aplicacao.codigo + '0000' },
                    { text: eme.documento },
                    { text: `${eme.favorecido.id} - ${eme.favorecido.nome}` },
                    { text: this.funcaoService.convertToBrNumber(eme.valor_empenho) },
                ]);
            }

            conteudo.push([
                { text: 'TOTAL DOS EMPENHOS EXTRAS' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: this.funcaoService.convertToBrNumber(valorLiquidoEME) },
            ]);
        }

        if (infoExtras.tipoDespesa === 'EMPENHOS DE TODOS' && resto.length) {
            conteudo.push([{ text: 'EMPENHOS RESTOS A PAGAR' }]);
        }

        if (resto.length) {
            let list = resto.filter((v, i, a) => +a.findIndex(t => (+t.a_id === +v.a_id)) === i)
            for (const emr of list) {
                if (+emr.a_valor_liquidado + +emr.total_anulado !== 0) {
                    valorLiquidoEMR += + +emr.a_valor_liquidado
                    conteudo.push([
                        { text: this.datepipe.transform(emr.a_data_liquidacao, 'dd/MM/yyyy', 'GMT') },
                        { text: this.datepipe.transform(emr.a_data_vencimento, 'dd/MM/yyyy', 'GMT') },
                        { text: emr.e_numero },
                        { text: emr.a_parcela },
                        { text: emr.numero_ficha },
                        { text: emr.e_recurso_variavel.length == 9 ? emr.e_recurso_variavel : emr.e_recurso + emr.e_aplicacao + '0000' },
                        { text: emr.a_documento },
                        { text: `${emr.fav_id} - ${emr.fav_nome}` },
                        { text: this.funcaoService.convertToBrNumber(+emr.a_valor_liquidado + +emr.total_anulado) },
                    ]);
                }
            }
            conteudo.push([
                { text: 'TOTAL DOS EMPENHOS RESTOS A PAGAR' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: this.funcaoService.convertToBrNumber(valorLiquidoEMR) },
            ]);
        }

        let totalLiquido = valorLiquidoEME + valorLiquidoEMO + valorLiquidoEMR
        conteudo.push([
            { text: 'TOTAL DOS EMPENHOS' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: this.funcaoService.convertToBrNumber(totalLiquido) },
        ]);

        let csv = '';
        for (let i = 0; i < conteudo.length; i++) {
            const linha = conteudo[i];
            if (i > 0) csv += '\n';
            for (let x = 0; x < linha.length; x++) {
                if (x > 0) csv += ';';
                csv += String(linha[x]['text']);
            }
        }
        conteudo = null;
        const element = document.createElement("a");
        element.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + csv));
        element.setAttribute("download", titulo);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
}

type Lista = {
    emo?: any[],
    eme?: EmpenhoExtra[],
    emr?: any[]
};
