// tslint:disable: variable-name

import { BaseResourceModel } from '../../models/base-resource.model';
import { AudienciaPublica } from './audiencia-publica.model';

export class AudienciaArquivo extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public caminho?: string,
    public tipo?: string,
    public documento?: string,
    public tamanho?: number,
    public audiencia?: AudienciaPublica
  ) {
    super();
  }

  static converteJson(json: any): AudienciaArquivo {
    return Object.assign(new AudienciaArquivo(), json);
  }
}
