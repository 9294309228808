import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BaseResourceListComponent, Coluna, DateFormatPipe, Filtro, FormatoExportacao, GlobalService, Licitacao, LoginPublico
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LicitacaoService } from '../service/licitacao.service';

@Component({
  selector: 'lib-licitacao-lst',
  templateUrl: './licitacao-lst.component.html'
})
export class LicitacaoLstComponent extends BaseResourceListComponent<Licitacao, LoginPublico> implements OnInit {

  /**
   * Declaração de variáveis
   */
  public ptBR: any;
  public data1: Date;
  public data2: Date;
  protected datepipe: DatePipe;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private route: ActivatedRoute,
    protected injector: Injector,
    private globalService: GlobalService,
    private licitacaoService: LicitacaoService) {
    super(licitacaoService, injector);
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.data1 = new DateFormatPipe().transform(params['data1'], []);
        this.data2 = new DateFormatPipe().transform(params['data2'], []);
        this.paginaCorrente = +params['pagina'];
        this.filtro = params['filtro'];
      });
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'orgao,modalidade,setor';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['data_abertura$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {};
  }

  public beforeInit(): void {
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
    if (this.login.exercicio && !this.data1) {
      this.data1 = new Date(`01/01/${this.login.exercicio.ano}`);
      if (this.login.exercicio.ano !== new Date().getFullYear()) {
        this.data2 = new Date(`12/31/${this.login.exercicio.ano}`);
      }
    }
  }

  protected afterInit(): void {
    window.scrollTo(0, 0);
    this.ptBR = this.globalService.obterDataBR();
  }

  protected acaoRemover(model: Licitacao): Observable<Licitacao> {
    return null;
  }

  public obterParametros() {
    const parametros = {};
    this.datepipe = new DatePipe('pt');
    parametros['ignoreCondObrig'] = true;
    parametros['data_abertura$ge'] = this.datepipe.transform(this.data1, 'yyyy-MM-dd');
    parametros['data_abertura$le'] = this.datepipe.transform(this.data2, 'yyyy-MM-dd');
    parametros['orgao.id'] = this.login.orgao.id;
    if (this.filtro) {
      parametros['objeto$like'] = this.filtro + '%';
    }
    switch (this.col) {
      case 1:
        parametros['orderBy'] = `numero$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 2:
        parametros['orderBy'] = `modalidade.nome$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 3:
        parametros['orderBy'] = `data_abertura$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 4:
        parametros['orderBy'] = `data_homologacao$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 5:
        parametros['orderBy'] = `objeto$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 6:
        parametros['orderBy'] = `valor_estimado$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      default:
        break;
    }
    return parametros;
  }

  public preencherGrid() {
    const parametros = this.obterParametros();
    parametros['relations'] = 'orgao,modalidade,setor';
    this.licitacaoService
      .filtrar(
        this.paginaCorrente,
        this.login.limite,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.lista = lista.content;
          this.paginaTotal = lista.totalPages;
        },
        () => alert('erro ao retornar lista')
      );
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Número', coluna: 'numero' },
      { titulo: 'Modalidade', coluna: 'modalidade.nome' },
      { titulo: 'Objeto', coluna: 'objeto' },
      { titulo: 'Abertura', coluna: 'data_abertura' },
      { titulo: 'Homologação', coluna: 'data_homologacao' },
      { titulo: 'Valor', coluna: 'valor_estimado', decimais: 2, alignment: 'right' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'orgao,modalidade,setor';
    this.licitacaoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE LICITAÇÕES',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem licitação', ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'], lista.content, ['valor_estimado']);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public editar(id: number) {
    const dt1 = this.datepipe.transform(this.data1, 'yyyy-MM-dd', 'GMT');
    const dt2 = this.datepipe.transform(this.data2, 'yyyy-MM-dd', 'GMT');
    if (!this.filtro || this.filtro === '') {
      this.router.navigate([
        `/processos-licitatorios/visualiza`, id, this.paginaCorrente, dt1, dt2]);
    } else {
      this.router.navigate([
        `/processos-licitatorios/visualiza`, id, this.paginaCorrente, dt1, dt2, this.filtro]);
    }
  }

  public onSelectPeriodo() {
    this.preencherGrid();
  }


}
