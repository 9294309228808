import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { ChartModule } from 'primeng/chart';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { SharedModule } from 'eddydata-lib';
import { TooltipModule } from 'primeng/tooltip';
import { DespesaPrevistaLstComponent } from './despesa-prevista-lst/despesa-prevista-lst.component';
import { DespesaPrevistaRoutingModule } from './despesa-prevista-routing.module';

@NgModule({
  declarations: [
    DespesaPrevistaLstComponent
  ],
  imports: [
    CommonModule,
    DespesaPrevistaRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
    ChartModule,
    IMaskModule,
    ToastModule,
    TooltipModule
  ],
  exports: [
    DespesaPrevistaLstComponent
  ]
})
export class DespesaPrevistaModule { }
