import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { Convenio } from './convenio.model';

// tslint:disable: variable-name
export class ConvenioHistorico extends BaseResourceModel {
  constructor(
    public texto?: string,
    public data_historico?: Date,
    public convenio?: Convenio,
    public usuario?: Usuario,
    public editavel?: boolean
    ) {
    super();
  }
  static converteJson(json: any): ConvenioHistorico {
    return Object.assign(new ConvenioHistorico(), json);
  }
}
