import { BaseResourceStorage } from '../../models/base-resource.storage';
import { Licitacao } from './licitacao.model';

export class LicitacaoStorage extends BaseResourceStorage {
  constructor(
    public visivel_transparencia?: boolean,
    public licitacao?: Licitacao,
    public documento_pncp: boolean = false,
    public tipo_documento?: number,
    public sequencial?: number,
  ) {
    super();
  }

  static converteJson(json: any): LicitacaoStorage {
    return Object.assign(new LicitacaoStorage(), json);
  }
}
