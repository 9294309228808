import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { LicitacaoService } from "administrativo-lib";
import { RplService } from "compra-lib";
import {
  EddyAutoComplete,
  FuncaoService,
  GlobalService,
  Licitacao,
  LoginContabil,
  Rcms,
  RcmsJustificativaDesbloqueioAutomatico,
  Rpl,
} from "eddydata-lib";
import { Subject } from "rxjs";
import * as toastr from "toastr";
import { RcmsJustificativaDesbloqueioAutomaticoService } from "../service/rcms-justificativa-desbloqueio-automatico.service";
import { RcmsService } from "../service/rcms.service";

const PONTUACAO_REQUERIDA_PARA_DESBLOQUEIO = 2.5;

const DECLARACAO_DE_CIENCIA = `
  Eu, RESPONSAVEL_NOME, CPF: RESPONSAVEL_CPF, declaro estar
  ciente que esta aquisição ultrapassa o limite anual da dispensa de
  licitação por compra direta, ficando seu processamento condicionado a
  justificativa apresentada para liberação junto a autoridade ordenadora
  de despesa.
`.replace(/\n/g, "");

const QUANTIDADE_MAXIMA_DE_CARACTERES_MOTIVO_DESBLOQUEIO = 300;

enum Pontuacao {
  qst1 = 1,
  qst2 = 1,
  qst3 = 1.5,
  qst4 = 1,
}

const acao = {
  erro: {
    codigo: "NAO_SALVA_REQUISICAO",
    condicao: (pontuacao) => pontuacao < 1,
    mensagem:
      "Atenção: Requisição de compra não atingiu a pontuação mínima, será salva como bloqueada.",
  },
  aviso: {
    codigo: "SALVA_REQUISICAO_BLOQUEADA",
    condicao: (pontuacao) => pontuacao < PONTUACAO_REQUERIDA_PARA_DESBLOQUEIO,
    mensagem:
      "Aviso: Requisição deve ser analisada, será salva como bloqueada.",
  },
  sucesso: {
    codigo: "SALVA_REQUISICAO_DESBLOQUEADA",
    condicao: (pontuacao) => pontuacao >= PONTUACAO_REQUERIDA_PARA_DESBLOQUEIO,
    mensagem: "Desbloqueio autorizado!",
  },
};

@Component({
  selector: "app-rcms-desbloqueio-automatico",
  templateUrl: "./rcms-justificativa-desbloqueio-automatico.component.html",
})
export class RcmsJustificativaDesbloqueioAutomaticoComponent
  implements OnInit, OnChanges {
  private unsubscribe: Subject<void> = new Subject();

  @Output() justificativaFinalizada = new EventEmitter<any>();

  @Input() login: LoginContabil;
  @Input("rcms") rcms: Rcms;
  @Input("justificativaDesbloqueioAutomatico")
  justificativaDesbloqueioAutomatico: RcmsJustificativaDesbloqueioAutomatico;

  declaracaoDeCiencia = DECLARACAO_DE_CIENCIA;

  stateOptions: any[] = [
    { label: "\t Sim \t ", value: 'S' },
    { label: "\t Não \t ", value: 'N' },
  ];

  questoesRespondidas = {
    qst1: null,
    qst2: null,
    qst3: null,
    qst4: null,
  };

  pontuacao = 0;
  bloquearBotaoFinalizar = true;

  acao = acao.erro;

  responsavel: {
    nome: string;
    cpf: string;
  };

  processoComItensFracassados: Licitacao;
  processoLicitatorio: Licitacao;
  processoSolicitacaoLicitatorio: Rpl;
  motivosRequisicaoAcimaDoLimite: string;
  responsavel_nome: string;
  responsavel_cpf: string;

  licitacaoAutoComplete: EddyAutoComplete<Licitacao>;
  rplAutoComplete: EddyAutoComplete<Rpl>;

  constructor(
    public licitacaoService: LicitacaoService,
    public funcaoService: FuncaoService,
    public globalService: GlobalService,
    public rcmsService: RcmsService,
    public rcmsJustificativaDesbloqueioAutomaticoService: RcmsJustificativaDesbloqueioAutomaticoService,
    public rplService: RplService
  ) {
    this.motivosRequisicaoAcimaDoLimite = "";
  }

  ngOnInit(): void {
    this.afterInit();
  }

  protected afterInit(): void {
    this.licitacaoAutoComplete = new EddyAutoComplete(
      null,
      this.licitacaoService,
      "processo",
      ["processo", "modalidade.nome", "situacao"],
      {
        orgao_id: this.login.orgao.id,
        orderBy: "processo",
        relations: "modalidade,exercicio",
      },
      { text: ["processo", "modalidade.nome", "situacao"] },
      null,
      null,
      (entidade: Licitacao) =>
        `${entidade.processo} - ${entidade.modalidade.nome
        } - ${entidade.situacao}`
    );

    this.rplAutoComplete = new EddyAutoComplete(
      null,
      this.rplService,
      "numero",
      ["numero", "exercicio.ano"],
      {
        orgao_id: this.login.orgao.id,
        "exercicio.id": this.login.exercicio.id,
        orderBy: "numero,exercicio.ano",
      },
      { number: ["numero", "exercicio.ano", "rpl_numero_ano"] }
    );
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.justificativaDesbloqueioAutomatico) {
      if (this.justificativaDesbloqueioAutomatico) {
        this.preencheJustificativaDesbloqueioAutomatico();
      } else {
        this.justificativaDesbloqueioAutomatico =
          new RcmsJustificativaDesbloqueioAutomatico();
      }
    }
    if (changes.rcms && this.rcms?.operador) {
      this.rcms = changes.rcms.currentValue;
      // const nomeCompleto = `${this.rcms.operador.nome} ${this.rcms.operador.sobrenome}`;
      this.preencheDeclaracaoDeCiencia(this.responsavel_nome, this.responsavel_cpf);
    }
  }

  private async preencheJustificativaDesbloqueioAutomatico() {
    const {
      questao_1_licitacao,
      questao_2_licitacao,
      questao_3,
      questao_4_rpl,
      responsavel_cpf,
      responsavel_nome,
    } = this.justificativaDesbloqueioAutomatico;

    if (questao_1_licitacao?.id) {
      this.questoesRespondidas.qst1 = 'S';
      this.processoComItensFracassados = questao_1_licitacao;
    } else {
      this.questoesRespondidas.qst1 = 'N';
    }

    if (questao_2_licitacao?.id) {
      this.questoesRespondidas.qst2 = 'S';
      this.processoLicitatorio = questao_2_licitacao;
    } else {
      this.questoesRespondidas.qst2 = 'N';
    }

    if (questao_3) {
      this.questoesRespondidas.qst3 = 'S';
      this.motivosRequisicaoAcimaDoLimite = questao_3;
    } else {
      this.questoesRespondidas.qst3 = 'N';
    }

    if (questao_4_rpl?.id) {
      this.questoesRespondidas.qst4 = 'S';
      this.processoSolicitacaoLicitatorio = questao_4_rpl;
    } else {
      this.questoesRespondidas.qst4 = 'N';
    }

    if (responsavel_cpf !== '') this.responsavel_cpf = responsavel_cpf;
    if (responsavel_nome !== '') this.responsavel_nome = responsavel_nome;

    this.calculaPontuacao();
    this.verificaSeDeveBloquearBotaoFinalizar();
  }

  public preencheDeclaracaoDeCiencia(nomeCompleto: string, cpf: string): void {
    this.responsavel = {
      nome: nomeCompleto,
      cpf: this.funcaoService.mascarar("###.###.###-##", cpf),
    };

    this.declaracaoDeCiencia = this.declaracaoDeCiencia.replace(
      "RESPONSAVEL_NOME",
      this.responsavel_nome
    );

    this.declaracaoDeCiencia = this.declaracaoDeCiencia.replace(
      "RESPONSAVEL_CPF",
      this.responsavel_cpf
    );
  }

  async carregarProcesso(
    numeroProcesso,
    processo,
    filtro,
    exibirToastr = true
  ) {
    if (numeroProcesso.length !== 8 && numeroProcesso.length !== 9) {
      toastr.warning("Número da licitação inválido!");
      return;
    }

    await this.licitacaoService
      .obter({
        numero: numeroProcesso,
        "orgao.id": this.login.orgao.id,
        ...filtro,
      })
      .toPromise()
      .then((data) => {
        if (!data) {
          toastr.warning("Processo não encontrado!");
        } else {
          this[processo] = data;
          if (exibirToastr)
            toastr.success(`Processo ${data.numero} selecionado.`);
        }
      })
      .catch((error) => toastr.error(error.error.payload));
  }

  calculaPontuacao(): void {
    // const pontuacoes = Object.keys(Pontuacao).filter((obj) =>
    //   isNaN(Number(obj))
    // );
    this.pontuacao = 0;
    this.pontuacao = this.questoesRespondidas.qst1 === 'S' ? 1 : 0;
    this.pontuacao += this.questoesRespondidas.qst2 === 'S' ? 1 : 0;
    this.pontuacao += this.questoesRespondidas.qst3 === 'S' ? 1.5 : 0;
    this.pontuacao += this.questoesRespondidas.qst4 === 'S' ? 1 : 0;
    // for (const p of pontuacoes) {
    //   if (this.questoesRespondidas[p]) this.pontuacao += Pontuacao[p];
    // }

    this.verificaSeDeveBloquearBotaoFinalizar();
  }

  private verificaSeDeveBloquearBotaoFinalizar() {
    this.bloquearBotaoFinalizar = Object.values(this.questoesRespondidas).every(
      (qst) => qst === 'S' || qst === 'N'
    );

    // this.bloquearBotaoFinalizar = this.rcms.observacao.includes(
    //   this.declaracaoDeCiencia.trim()
    // );
  }

  private defineAcaoASerTomada(): void {
    if (acao.erro.condicao(this.pontuacao)) {
      this.acao = acao.erro;
    } else if (acao.aviso.condicao(this.pontuacao)) {
      this.acao = acao.aviso;
    } else {
      this.acao = acao.sucesso;
    }
  }

  // TODO: validar que a justificativa não pode ser alterada
  // TODO: validar salvar um rcms autorizada e bloqueada (mensagem)
  // TODO: validar salvar um rcms autorizada e bloqueada (deve autorizar para compra)
  // TODO: validar salvar uma nova rcms (campo observação)
  // TODO: criar botão para liberar responder a questão (criar novo btn booleano com texto "Responder pergunta", quando selecionado muda texto para "Não responder pergunta" e carrega btn "Sim/Não")
  // TODO: colocar abar justificativa ao lado da aba de cotação
  // TODO: criar inputs para digitação do nome e cpf na declaração de ciência
  // TODO: validar erro (Preencha a aba justificativa.) quando salvar uma rcms com valor superior ao limite de dispensa

  //TODO: salva rcms mesmo não validando erro de preenchido
  //TODO: update entidade pq o campo na questao_4_rpl estava com tipo Licitacao

  async salvarJustificativaDesbloqueioAutomatico(): Promise<void> {
    let justificativa: RcmsJustificativaDesbloqueioAutomatico;
    let mensagemAcao: string;

    if (this.bloquearBotaoFinalizar) return

    try {
      if (this.justificativaDesbloqueioAutomatico?.id) {
        mensagemAcao = "atualizada";
        justificativa = await this.rcmsJustificativaDesbloqueioAutomaticoService
          .atualizar(this.justificativaDesbloqueioAutomatico)
          .toPromise();
      }
      toastr.success(
        `Justificativa ${mensagemAcao} - ID: ${justificativa.id}.`
      );
    } catch (error) {
      toastr.error(error.error.payload);
    }
  }

  async finalizarJustificativa(): Promise<void> {
    if (!this.validaInputsAntesDeFinalizarJustificativa()) return;

    this.defineAcaoASerTomada();

    const { qst1, qst2, qst3, qst4 } = this.questoesRespondidas;

    this.justificativaDesbloqueioAutomatico.questao_1_licitacao = qst1 === 'S' ? this.processoComItensFracassados : null

    this.justificativaDesbloqueioAutomatico.questao_2_licitacao = qst2 === 'S' ? this.processoLicitatorio : null;

    this.justificativaDesbloqueioAutomatico.questao_3 = qst3 === 'S' ? this.motivosRequisicaoAcimaDoLimite : null;

    this.justificativaDesbloqueioAutomatico.questao_4_rpl = qst4 === 'S' ? this.processoSolicitacaoLicitatorio : null;

    this.justificativaDesbloqueioAutomatico.pontuacao = this.pontuacao;

    this.justificativaDesbloqueioAutomatico.responsavel_nome = this.responsavel_nome === undefined || '' ? null : this.responsavel_nome;

    this.justificativaDesbloqueioAutomatico.responsavel_cpf = this.responsavel_cpf === undefined || '' ? null : this.responsavel_cpf;

    this.justificativaDesbloqueioAutomatico.rcms = this.rcms;

    // if (this.rcms?.rcmsJustificativa?.id) {
    //   await this.salvarJustificativaDesbloqueioAutomatico().finally(() => {
    //     this.justificativaFinalizada.emit({
    //       acao: {
    //         codigo: this.acao.codigo,
    //         mensagem: this.acao.mensagem,
    //       },
    //       declaracaoDeCiencia: this.declaracaoDeCiencia.trim(),
    //       justificativa: this.justificativaDesbloqueioAutomatico,
    //     });

    //     toastr.success("Justifica de desbloqueio automatico foi finalizada.");
    //   });
    // } else {
    this.justificativaFinalizada.emit({
      acao: {
        codigo: this.acao.codigo,
        mensagem: this.acao.mensagem,
      },
      declaracaoDeCiencia: this.responsavel_cpf === undefined ? '' : `
      Eu, ${this.responsavel_nome}, CPF: ${this.responsavel_cpf}, declaro estar ciente que esta aquisição ultrapassa o limite anual da dispensa de licitação por compra direta, ficando seu processamento condicionado justificativa apresentada para liberação junto a autoridade ordenadora de despesa.`.replace(/\n/g, ""),
      justificativa: this.justificativaDesbloqueioAutomatico,
    });
    toastr.success('Inserido a justificativa, por gentileza salvar a requisição.')
    //}
  }
  private obterResposta = (resposta: string) => resposta === 'S' || resposta === 'N';
  private obterRespostaSIM = (resposta: string) => resposta === 'S';

  private validaInputsAntesDeFinalizarJustificativa():
    | boolean
    | Promise<boolean> {

    const aoMenosUmqQuestaoFoiRespondida = Object.values(
      this.questoesRespondidas
    ).some((respondeu) => this.obterResposta(respondeu));

    if (!aoMenosUmqQuestaoFoiRespondida) {
      toastr.error("Responda ao menos uma (1) questão");
      return false;
    }

    const respostaSim = Object.values(
      this.questoesRespondidas
    ).some((respondeu) => this.obterRespostaSIM(respondeu));

    if (respostaSim && !this.responsavel_nome) {
      toastr.error("Informe o nome do responsavel");
      return false;
    }

    if (respostaSim && !this.responsavel_cpf) {
      toastr.error("Informe o cpf do responsavel");
      return false;
    }

    const erros: ErroInput[] = this.validaPreenchimentoDosInputs();

    if (
      this.questoesRespondidas.qst3 === 'S' &&
      this.motivosRequisicaoAcimaDoLimite.length >
      QUANTIDADE_MAXIMA_DE_CARACTERES_MOTIVO_DESBLOQUEIO
    ) {
      erros.push({
        questao: 3,
        mensagem: `Quantidade máxima de caracteres superior à ${QUANTIDADE_MAXIMA_DE_CARACTERES_MOTIVO_DESBLOQUEIO}. ${this.motivosRequisicaoAcimaDoLimite.length}/${QUANTIDADE_MAXIMA_DE_CARACTERES_MOTIVO_DESBLOQUEIO}`,
      });
    }

    if (erros.length > 0) {
      this.exibeErros(erros);
      return false;
    }

    return true;
  }

  private validaPreenchimentoDosInputs(): ErroInput[] {
    const erros = [];

    if (
      this.questoesRespondidas.qst1 === 'S' &&
      !this.processoComItensFracassados?.id
    ) {
      erros.push({
        questao: 1,
        mensagem: "É necessário informar um processo com itens fracassados.",
      });
    }

    if (this.questoesRespondidas.qst2 === 'S' && !this.processoLicitatorio?.id) {
      erros.push({
        questao: 2,
        mensagem: "É necessário informar um processo licitatório.",
      });
    }

    if (this.questoesRespondidas.qst3 === 'S' && !this.motivosRequisicaoAcimaDoLimite) {
      erros.push({
        questao: 3,
        mensagem: "É necessário informar um motivo.",
      });
    }

    if (
      this.questoesRespondidas.qst4 === 'S' &&
      !this.processoSolicitacaoLicitatorio?.id
    ) {
      erros.push({
        questao: 4,
        mensagem: "É necessário informar um processo solicitação licitatório.",
      });
    }

    return erros;
  }

  private exibeErros(erros: ErroInput[]): void {
    let mensagem;
    for (const erro of erros) {
      mensagem = `Questão ${erro.questao} - ${erro.mensagem}`;
      toastr.error(mensagem);
    }
  }

  public buscaDeclaracaoDeCiencia(nomeCompleto: string, cpf: string) {
    this.preencheDeclaracaoDeCiencia(nomeCompleto, cpf);
    return this.declaracaoDeCiencia;
  }

  public buscaAcaoASerTomada() {
    this.defineAcaoASerTomada();
    return {
      codigo: this.acao.codigo,
      mensagem: this.acao.mensagem,
    };
  }

  public validaCPF() {
    if (!this.funcaoService.validarCPF(this.responsavel_cpf)) {
      this.responsavel_cpf = undefined;
      toastr.warning('O CPF/CNPJ informado está inválido!')
      return;
    }
  }
}

type ErroInput = {
  questao: number;
  mensagem: string;
};
