// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { Tombamento } from './tombamento.model';

export class Reavaliacao extends BaseResourceModel {
  constructor(
    public id?: number,
    public aux?: number,
    public processo?: string,
    public observacao?: string,
    public data_efetivacao?: Date,
    public encerrado?: boolean,
    public usuario?: Usuario,
    public orgao?: Orgao,
    public filtros?: {
      tombamento?: Tombamento;
      placaInicial?: number;
      placaFinal?: number;
    },
  ) {
    super();
  }

  static converteJson(json: any): Reavaliacao {
    return Object.assign(new Reavaliacao(), json);
  }
}
