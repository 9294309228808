// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class ReinfBase extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public classificacao_tributaria?: number,
    public desoneracao_folha?: number,
    public acordo_insencao_multa?: number,
    public nome_contato?: string,
    public cpf_contato?: string,
    public fixo_contato?: string,
    public cell_contato?: string,
    public email_contato?: string,
    public ecd?: number,
    public efr?: string,
    public efr_cnpj?: string,
    public ind_uniao?: number,
    public orgao?: Orgao
  ) {
    super();
  }

  static converteJson(json: any): ReinfBase {
    return Object.assign(new ReinfBase(), json);
  }
}
