import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from './orgao.model';

export class OrgaoAssinatura extends BaseResourceModel {

  constructor(
    public id?: number,
    public data_limite?: Date,
    public ordenador_despesa?: string,
    public cargo_ordenador_despesa?: string,
    public cpf_ordenador_despesa?: string,
    public ordenador_despesa_funcao_08?: string,
    public cargo_ordenador_despesa_funcao_08?: string,
    public cpf_ordenador_despesa_funcao_08?: string,
    public ordenador_despesa_funcao_10?: string,
    public cargo_ordenador_despesa_funcao_10?: string,
    public cpf_ordenador_despesa_funcao_10?: string,
    public ordenador_despesa_funcao_12?: string,
    public cargo_ordenador_despesa_funcao_12?: string,
    public cpf_ordenador_despesa_funcao_12?: string,
    public ordenador_pagto?: string,
    public cargo_ordenador_pagto?: string,
    public cpf_ordenador_pagto?: string,
    public tecnico_contabil?: string,
    public cargo_tecnico_contabil?: string,
    public cpf_tecnico_contabil?: string,
    public ordenador_liquidacao?: string,
    public cargo_ordenador_liquidacao?: string,
    public cpf_ordenador_liquidacao?: string,
    public contador?: string,
    public cargo_contador?: string,
    public cpf_contador?: string,
    public tesoureiro?: string,
    public cargo_tesoureiro?: string,
    public cpf_tesoureiro?: string,
    public comprador?: string,
    public cargo_comprador?: string,
    public cpf_comprador?: string,
    public almoxarife?: boolean,
    public cargo_almoxarife?: string,
    public cpf_almoxarife?: string,
    public assinatura1?: string,
    public cargo_assinatura1?: string,
    public cpf_assinatura1?: string,
    public assinatura2?: string,
    public cargo_assinatura2?: string,
    public cpf_assinatura2?: string,
    public assinatura3?: string,
    public cargo_assinatura3?: string,
    public cpf_assinatura3?: string,
    public prefeito?: string,
    public cargo_prefeito?: string,
    public cpf_prefeito?: string,
    public orgao?: Orgao,
    public crc_contador?: String
  ) {
    super();
  }

  static converteJson(json: any): OrgaoAssinatura {
    return Object.assign(new OrgaoAssinatura(), json);
  }
}
