import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginPublico, FuncaoService, Login, GlobalService } from 'eddydata-lib';

@Component({
  selector: 'app-transparencia-decreto',
  templateUrl: './transparencia-decreto.component.html'
})
export class TransparenciaDecretoComponent implements OnInit {

  public login: LoginPublico;

  constructor(
    private router: Router,
    private funcaoServico: FuncaoService
  ) { }

  ngOnInit() {
    this.login = new Login();
    this.login = GlobalService.obterSessaoLogin();
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
  }

  public sair() {
    this.funcaoServico.navegarPara('transparencia', this.router);
  }

}
