import { Component, Input } from "@angular/core";
import { MemorialService, ProponenteService } from "administrativo-lib";
import { AssinaturaService, BaseResourceRptPersonalizadoComponent, CpfPipe, GlobalService, Licitacao, LicitacaoPipe, Memorial, Proponente, Proposta, SituacaoProposta } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";

var extenso = require('extenso')

@Component({
  selector: 'app-ata-julgamento-rpt',
  templateUrl: './ata-julgamento-rpt.component.html'
})
export class AtaJulgamentoRptComponent extends BaseResourceRptPersonalizadoComponent {

  @Input() public licitacao: Licitacao;
  private situacoesProposta: { id: SituacaoProposta, nome: string, style: string, ordem: number }[];
  public tipoDescricao: 'nome' | 'descricao';

  constructor(
    private memorialService: MemorialService,
    private proponenteService: ProponenteService,
    private globalService: GlobalService,
    protected assinaturaService: AssinaturaService,
  ) {
    super(assinaturaService);
    this.assinaturaObrigatoria = false;
  }

  protected afterInit(): void {
    this.situacoesProposta = this.globalService.obterSituacoesProposta();
  }

  protected modalRelatorio(): string {
    return 'dialogRelatorioAtaJulgamento';
  }

  protected tituloRelatorio(): string {
    return 'Ata de Abertura e Julgamento';
  }

  protected layoutRelatorio(): {} {
    return {
      table_eddy: {
        hLineWidth: function (i, node) {
          return i === node.table.body.length || i === 0 ? 0 : 1;
        },
        vLineWidth: function (i) {
          return 0;
        },
        hLineColor: function (i, node) {
          return i === node.table.headerRows || i === node.table.body.length - 1 ? 'black' : '#eee';
        },
        paddingLeft: function (i) {
          return i === 0 ? 0 : 8;
        },
        paddingRight: function (i, node) {
          return (i === node.table.widths.length - 1) ? 0 : 8;
        },
        fillColor: function (i, node) {
          if (i === 0 || i === node.table.headerRows || i === node.table.body.length - 1) {
            return null;
          }
          return (i % 2 === 0) ? '#f5f5f5' : null;
        }
      }
    }
  }

  protected async montarConteudo(): Promise<{}[]> {
    const conteudo = [];
    return await new Promise(async (resolve) => {
      conteudo.push(this.dadosCabecalho())
      conteudo.push(await this.dadosCorpo())
      conteudo.push(await this.dadosClassificacao())
      conteudo.push(this.dadosRodape())

      resolve(conteudo);
    })
  }

  private dadosCabecalho(): {}[] {
    const conteudo = [
      {
        text: `Licitação: ${new LicitacaoPipe().transform(this.licitacao.numero)} - Processo ${new LicitacaoPipe().transform(this.licitacao.processo)}`, alignment: 'center',
        fontSize: 14, margin: [0, 0, 0, 10]
      },
      { text: `Modalidade: ${this.licitacao.modalidade.nome}`, fontSize: 13, alignment: 'center', margin: [0, 0, 0, 10] },
      { text: this.licitacao.objeto, bold: true, fontSize: 10, alignment: 'justify', margin: [0, 0, 0, 10] },
    ]
    return conteudo;
  }

  private async dadosCorpo(): Promise<{}[]> {
    const conteudo: {}[] = [
      {
        text: `No dia ${this.funcaoService.formatarDataExtenso(this.licitacao.data_abertura_env)}, às ${this.funcaoService.formatarHora(this.licitacao.data_abertura_env)}, na sala de licitações da ${this.login.orgao.nome}, reuniram-se os membros da Comissão Permanente de Licitações, para análise do processo em epígrafe, sob a Presidência do Pregoeiro Oficial deste Órgão e respectivos membros da Equipe de Apoio`,
        fontSize: 10, alignment: 'justify', margin: [0, 0, 0, 10]
      },
      {
        text: 'Dado início aos trabalhos, no horário estabelecido para a abertura dos envelopes, constatou-se a participação dos seguintes interessados:',
        fontSize: 10, alignment: 'justify', margin: [0, 0, 0, 10]
      }
    ]

    return await new Promise((resolve) => {
      this.proponenteService.obterPorLicitacao(this.licitacao.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          const reg: Proponente[] = res.content;
          const itens: string[] = [];
          reg.forEach(p => {
            itens.push(`${new CpfPipe().transform(p.favorecido.cpf_cnpj)} - ${p.favorecido.nome}`);
          })
          conteudo.push({ type: 'none', fontSize: 10, margin: [0, 0, 0, 10], ul: [itens] })
          conteudo.push({
            text: 'Dado início aos trabalhos foram rubricados os envelopes apresentados pelas licitantes. Em seguida, pelo Sr. Presidente foi determinada a abertura do Envelope de nº 1 (Habilitação). A documentação apresentada foi verificada para comprovação de sua conformidade com as exigências de Edital, inclusive os documentos que exigem conferência através da Internet foram verificados',
            fontSize: 10, alignment: 'justify', margin: [0, 0, 0, 10]
          })
          resolve(conteudo)
        });
    })
  }

  private async dadosClassificacao(): Promise<{}[]> {
    const conteudo: {}[] = [{
      text: 'Superada a fase de habilitação, pelo Sr. Presidente foi determinada a abertura do Envelope nº 2 (Proposta), das licitantes habilitadas, ficando a classificação da seguinte forma:',
      fontSize: 10, alignment: 'justify', margin: [0, 0, 0, 10]
    }]

    return await new Promise((resolve) => {
      this.memorialService.obterPorLicitacao(this.licitacao.id, true).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          const itens: Memorial[] = res.content;
          itens.forEach(memorial => {
            conteudo.push({ text: `ITEM ${memorial.ordem}: ${this.tipoDescricao === 'nome' ? `${`${memorial.produto_unidade.produto.nome}`}` : ` ${memorial.produto_unidade.produto.descricao}`}`, bold: true, fontSize: 10, alignment: 'justify', margin: [0, 0, 0, 3] })
            memorial.propostas
              .sort((a, b) => { return a.valor_unitario - b.valor_unitario })
              .sort((a, b) => { return this.situacoesProposta.find(s => s.id === a.situacao).ordem - this.situacoesProposta.find(s => s.id === b.situacao).ordem });
            const propostas: string[] = [];
            for (let i = 1; i <= memorial.propostas.length; i++) {
              const p: Proposta = memorial.propostas[i - 1];
              const valor: string = String(Number(p.valor_unitario).toFixed(2)).split('.').join(',');
              propostas.push(`${i}º lugar: ${p.proponente.favorecido.nome} - ${new CpfPipe().transform(p.proponente.favorecido.cpf_cnpj)} com o valor de ${valor} (${extenso(valor, { mode: 'currency' })})`)
            }
            conteudo.push({ type: 'none', fontSize: 10, margin: [0, 0, 0, 10], ul: [propostas] })
          })
          resolve(conteudo)
        });
    })
  }

  private dadosRodape(): {}[] {
    let assinaturas = [];
    if (this.assinatura) {
      assinaturas = this.assinatura.pessoas.filter(p => p.selecionado);
      if (assinaturas?.length === 0) return [{}];
    }

    const conteudo: {}[] = [
      {
        text: 'Em seguida, a Comissão resolveu adjudicar o objeto do presente certame conforme resultado acima classificado, sendo que o presente expediente será enviado ao Sr. Prefeito Municipal para homologação. Pelo Sr. Presidente foi determinada a publicação da minuta da Ata de Classificação no Diário Oficial para ciência dos Licitantes e demais interessados. Dada a palavra aos presentes não houve manifestação verbal. Nada mais havendo a ser tratado, lavra-se o presente termo que, lido e achado conforme, segue assinado pelos membros da Comissão Permanente de Licitações. ATA APROVADA.',
        fontSize: 10, alignment: 'justify', margin: [0, 0, 0, 10]
      },
      { text: this.funcaoService.formatarDataExtenso(new Date(), this.login.cidade.nome), alignment: 'center', fontSize: 10, margin: [0, 30, 0, 70] },
    ]

    return conteudo.concat(this.imprimirAssinaturas(assinaturas));
  }

}