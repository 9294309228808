import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MaterialService } from 'almoxarifado-lib';
import {
  BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, GlobalService, LoginContabil, Material
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-grupo-servico-list',
  templateUrl: './grupo-servico-list.component.html'
})
export class GrupoServicoListComponent extends BaseResourceListComponent<Material, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public mes: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private activatedRoute: ActivatedRoute,
    private materialService: MaterialService,
    protected globalService: GlobalService) {
    super(materialService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(item: Material): boolean {
    return true;
  }

  protected relations(): string {
    return 'sub_grupo.grupo';
  }

  protected condicoesGrid(): {} {
    return {
      servico: true,
      orgao_id: this.login.orgao.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['codigo$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['codigo'],
      text: ['nome', 'sub_grupo.nome'],
    };
  }

  protected afterInit(): void {
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  protected acaoRemover(model: Material): Observable<Material> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'codigo' },
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'Sub-Grupo', coluna: 'sub_grupo.nome' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'sub_grupo';
    this.materialService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE MATERIAL DE ESTOQUE`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem material estoque', ['auto', '*', '*'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

}
