import { BaseResourceModel } from '../../models/base-resource.model';

export class Tabela extends BaseResourceModel {

  constructor(
    public classeNome?: string,
    public nome?: string,
  ) {
    super();
  }

  static converteJson(json: any): Tabela {
    console.log(json);
    return Object.assign(new Tabela(), json);
  }
}
