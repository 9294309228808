import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProdutoService } from 'almoxarifado-lib';
import { EddyAutoComplete, FuncaoService, GlobalService, Login, Produto, ProdutoConcentracao, ProdutoMedicamento } from 'eddydata-lib';
import { Subject } from 'rxjs';

@Component({
  selector: 'lib-produto-medicamento',
  templateUrl: './produto-medicamento.component.html'
})
export class ProdutoMedicamentoComponent implements OnInit, OnDestroy {

  protected unsubscribe: Subject<void> = new Subject();

  @Input() entidade: Produto;
  @Input() login: Login;

  @ViewChild('btnAdicionarConcentracao') public btnAdicionarConcentracao: ElementRef;
  public listaConcentracao: ProdutoConcentracao[];
  public concentracaoAnterior: ProdutoConcentracao;
  public concentracaoAtual: ProdutoConcentracao;

  @ViewChild('btnAdicionarInteracao') public btnAdicionarInteracao: ElementRef;
  public produtoAutoComplete: EddyAutoComplete<Produto>;
  public listaInteracao: Produto[];
  public interacaoAnterior: Produto;
  public interacaoAtual: Produto;

  constructor(
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected produtoService: ProdutoService,
  ) { }

  ngOnInit() {
    this.entidade.medicamento = new ProdutoMedicamento();
    this.produtoAutoComplete = ProdutoService.autoCompleteCodigoCompleto(null, this.produtoService,
      'id', ['codigo', 'nome'], {orgao_id: this.login.orgao.id, orderBy: 'codigo,nome' }, { number: ['codigo'], text: ['codigo', 'nome'] }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public adicionar() {
    if (this.entidade.medicamento.concentracoes && this.entidade.medicamento.concentracoes.find((itm) => { return itm['editavel'] === true })) {
      toastr.info('Finalize a edição atual antes de incluir outro item');
      return;
    }

    this.concentracaoAnterior = undefined;
    this.concentracaoAtual = new ProdutoConcentracao();
    this.concentracaoAtual['editavel'] = true;

    if (!this.entidade.medicamento.concentracoes) {
      this.entidade.medicamento.concentracoes = [];
    }

    this.entidade.medicamento.concentracoes.unshift(this.concentracaoAtual);
    this.btnAdicionarConcentracao.nativeElement.disabled = true;
  }

  public editar(item: ProdutoConcentracao) {
    if (this.entidade.medicamento.concentracoes && this.entidade.medicamento.concentracoes.find((itm) => { return itm['editavel'] === true })) {
      toastr.info('Finalize a edição atual antes de editar outro item');
      return;
    }

    this.concentracaoAnterior = Object.assign({}, item);
    this.concentracaoAtual = item;
    this.concentracaoAtual['editavel'] = true;
    this.btnAdicionarConcentracao.nativeElement.disabled = true;
  }

  public salvar(item: ProdutoConcentracao) {
    try {
      this.concentracaoAtual['editavel'] = false;
      this.concentracaoAtual = undefined;
      this.concentracaoAnterior = undefined;
      this.btnAdicionarConcentracao.nativeElement.disabled = false;
    } catch (e) {
      this.funcaoService.acaoErro(e)
      throw e;
    }
  }

  public cancelar(item: ProdutoConcentracao, index: number) {
    this.concentracaoAtual = Object.assign({}, this.concentracaoAnterior);

    if (!this.concentracaoAtual) {
      this.entidade.medicamento.concentracoes.splice(index, 1);
    } else {
      item['editavel'] = false;
    }
    this.concentracaoAtual = undefined;
    this.concentracaoAnterior = undefined;
    this.btnAdicionarConcentracao.nativeElement.disabled = false;
  }

  public remover(item: ProdutoConcentracao, index: number) {
    this.entidade.medicamento.concentracoes.splice(index, 1);
    this.btnAdicionarConcentracao.nativeElement.disabled = false;
  }

  public adicionarInteracao() {
    if (this.entidade.medicamento.interacoes_medicamentosas && this.entidade.medicamento.interacoes_medicamentosas.find((itm) => { return itm['editavel'] === true })) {
      toastr.info('Finalize a edição atual antes de incluir outro item');
      return;
    }

    this.interacaoAnterior = undefined;
    this.interacaoAtual = new Produto();
    this.interacaoAtual['editavel'] = true;

    if (!this.entidade.medicamento.interacoes_medicamentosas) {
      this.entidade.medicamento.interacoes_medicamentosas = [];
    }

    this.entidade.medicamento.interacoes_medicamentosas.unshift(this.interacaoAtual);
    this.btnAdicionarInteracao.nativeElement.disabled = true;
  }

  public editarInteracao(item: Produto) {
    if (this.entidade.medicamento.interacoes_medicamentosas && this.entidade.medicamento.interacoes_medicamentosas.find((itm) => { return itm['editavel'] === true })) {
      toastr.info('Finalize a edição atual antes de editar outro item');
      return;
    }

    this.interacaoAnterior = Object.assign({}, item);
    this.interacaoAtual = item;
    this.interacaoAtual['editavel'] = true;
    this.btnAdicionarInteracao.nativeElement.disabled = true;
  }

  public salvarInteracao(item: Produto) {
    try {
      this.entidade.medicamento.interacoes_medicamentosas[0] = this.interacaoAtual;
      this.interacaoAtual['editavel'] = false;
      this.interacaoAtual = undefined;
      this.interacaoAnterior = undefined;
      this.btnAdicionarInteracao.nativeElement.disabled = false;
    } catch (e) {
      this.funcaoService.acaoErro(e)
      throw e;
    }
  }

  public cancelarInteracao(item: Produto, index: number) {
    this.interacaoAtual = Object.assign({}, this.interacaoAnterior);

    if (!this.interacaoAtual) {
      this.entidade.medicamento.interacoes_medicamentosas.splice(index, 1);
    } else {
      item['editavel'] = false;
    }
    this.interacaoAtual = undefined;
    this.interacaoAnterior = undefined;
    this.btnAdicionarInteracao.nativeElement.disabled = false;
  }

  public removerInteracao(item: Produto, index: number) {
    this.entidade.medicamento.interacoes_medicamentosas.splice(index, 1);
    this.btnAdicionarInteracao.nativeElement.disabled = false;
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }
}
