import { Component, Injector, ElementRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { LoginContabil, GlobalService, BaseResourceFormComponent, AcaoGoverno } from 'eddydata-lib';
import { AcaoGovernoService } from '../service/acao-governo.service';

@Component({
  selector: 'lib-acao-governo-shw',
  templateUrl: './acao-governo-shw.component.html'
})
export class AcaoGovernoShwComponent extends BaseResourceFormComponent<AcaoGoverno, LoginContabil> {

  /**
   * Declaração de variáveis
   */

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected acaoService: AcaoGovernoService) {
    super(new AcaoGoverno(), injector, AcaoGoverno.converteJson, acaoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      custo1: [null, [Validators.required]],
      custo2: [null, [Validators.required]],
      custo3: [null, [Validators.required]],
      custo4: [null, [Validators.required]],
      meta1: [null, [Validators.required]],
      meta2: [null, [Validators.required]],
      meta3: [null, [Validators.required]],
      meta4: [null, [Validators.required]],
      acao: this.fb.group({
        codigo: [null, [Validators.required]],
        nome: [null, [Validators.required]]
      }),
      executora: this.fb.group({
        codigo: [null, [Validators.required]],
        nome: [null, [Validators.required]]
      }),
      meta: this.fb.group({
        id: [null, [Validators.required]],
        programa: this.fb.group({
          codigo: [null, [Validators.required]],
          nome: [null, [Validators.required]]
        })
      })
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'acao,executora,meta,meta.programa' };
  }

  protected afterInit(): void {
  }

  protected campoFoco(): ElementRef {
    return null;
  }
  protected afterLoad() {
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: AcaoGoverno): void {
  }

}
