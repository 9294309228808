import { Directive, Injector, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  FuncaoService, GlobalService, Login,
  Exercicio, Relatorio, ProgressoService, FormatoExportacao, Coluna
} from 'eddydata-lib';
import * as toastr from 'toastr';
import { Anexo8Apuracao } from './anexo8.apuracao';
import { Anexo8ControleDisponibilidade } from './anexo8.controle-disponibilidade';
import { Anexo8DespesaCusteadasFundeb } from './anexo8.despesa-custeadas-fundeb';
import { Anexo8DespesaEducacao } from './anexo8.despesa-educacao';
import { Anexo8DespesaFundeb } from './anexo8.despesa-fundeb';
import { Anexo8DespesaMde } from './anexo8.despesa-mde';
import { Anexo8Imposto } from './anexo8.imposto';
import { Anexo8Indicadores } from './anexo8.indicadores';
import { Anexo8OutraDespesa } from './anexo8.outra-despesa';
import { Anexo8ReceitaAdicional } from './anexo8.receita-adicional';
import { Anexo8ReceitaFundeb } from './anexo8.receita-fundeb';
import { Anexo8RestoMde } from './anexo8.resto-mde';
import { Anexo8Superavit } from './anexo8.superavit';
import { Assinaturas } from '../../../../../assinaturas/assinaturas';
import { AnexoLrfFederalService } from '../../../../service/anexo-lrf-federal.service';


@Directive()
export class Anexo8Ensino2022 implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected quadroServico: AnexoLrfFederalService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected progressoService: ProgressoService,
    protected assinaturaControleInterno: boolean) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
    this.quadroServico.obterAnexo8(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async idTransacao => {
        this.progressoService.show(idTransacao, async (retorno) => {
          if (formato === 'pdf') {
            await Relatorio.imprimirPersonalizado(
              null, this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
              await this.conteudo(retorno),
              'portrait', 'ANEXO 8 - MANUTENÇÃO E DESENVOLVIMENTO DO ENSINO - MDE',
              {
                linhas: {
                  hLineWidth() {
                    return 1;
                  },
                  vLineWidth() {
                    return 1;
                  },
                  hLineColor() {
                    return 'black';
                  },
                  paddingLeft() {
                    return 3;
                  },
                  paddingRight() {
                    return 3;
                  }
                }
              }, true, false);
          } else {
            this.funcaoService.exportar(formato, await this.normalizar(retorno), 'ANEXO 8 - MANUTENÇÃO E DESENVOLVIMENTO DO ENSINO - MDE', this.colunas());
          }
        });
      }, error => {
        toastr.error(error.error.payload)
      });

  }

  private async conteudo(dados: any[]): Promise<{}[]> {

    // Caso seja adicionado novos campos no layout será necessario mudar a posição dos arrays

    // RECEITA RESULTANTE DE IMPOSTOS
    let listaImpostos: {}[] = [];
    const impostos = new Anexo8Imposto(this.mes, this.exercicio, this.login);
    listaImpostos = impostos.montarQuadro(dados[0]);

    // RECEITAS RECEBIDAS DO FUNDEB NO EXERCÍCIO
    let listaReceitaFundeb: {}[] = [];
    const receitaFundeb = new Anexo8ReceitaFundeb(this.mes, this.exercicio);
    listaReceitaFundeb = receitaFundeb.montarQuadro(dados[1], dados[0]);

    // RECURSOS RECEBIDOS EM EXERCÍCIOS ANTERIORES E NÃO UTILIZADOS (SUPERÁVIT)
    let listasuperavit: {}[] = [];
    const superavit = new Anexo8Superavit(this.mes);
    listasuperavit = superavit.montarQuadro(dados[2], dados[1]);

    // DESPESAS COM RECURSOS DO FUNDEB (Por Área de Atuação)
    let listaDespesaFundeb: {}[] = [];
    const despesaFundeb = new Anexo8DespesaFundeb(this.mes, this.exercicio);
    listaDespesaFundeb = despesaFundeb.montarQuadro(dados[3]);

    // DESPESAS CUSTEADAS COM RECEITAS DO FUNDEB RECEBIDAS NO EXERCÍCIO
    let listaDespesasCusteadasFundeb: {}[] = [];
    const deducaFundeb = new Anexo8DespesaCusteadasFundeb(this.mes);
    listaDespesasCusteadasFundeb = deducaFundeb.montarQuadro(dados[4]);

    // INDICADORES - Art. 212-A, inciso XI e § 3º - Constituição Federal e INDICADOR - Art.25, § 3º - Lei nº 14.113, de 2020 - (Máximo de 10% de Superávit) e INDICADOR - Art.25, § 3º - Lei nº 14.113, de 2020 - (Aplicação do Superávit de Exercício Anterior)3
    let listaIndicadores: {}[] = [];
    const indicadores = new Anexo8Indicadores(this.mes, this.exercicio);
    listaIndicadores = indicadores.montarQuadro(dados[1], dados[4], dados[5]);

    // DESPESAS COM AÇÕES TÍPICAS DE MDE - RECEITAS DE IMPOSTOS - EXCETO FUNDEB
    let listaDespesasMde: {}[] = [];
    const despesaMDE = new Anexo8DespesaMde(this.mes);
    listaDespesasMde = despesaMDE.montarQuadro(dados[6]);

    // APURAÇÃO DAS DESPESAS PARA FINS DE LIMITE MÍNIMO CONSTITUCIONAL
    let apuracao: {}[] = [];
    const apu = new Anexo8Apuracao(this.mes, this.exercicio);
    apuracao = apu.montarQuadro(dados[0], dados[1], dados[4], dados[5], dados[6], dados[7]);

    // RESTOS A PAGAR INSCRITOS EM EXERCÍCIOS ANTERIORES COM DISPONIBILIDADE FINANCEIRA DE RECURSOS DE IMPOSTOS E DO FUNDEB
    let listaRestosMde: {}[] = [];
    const restosMde = new Anexo8RestoMde(this.mes, this.exercicio);
    listaRestosMde = restosMde.montarQuadro(dados[7]);

    // RECEITA ADICIONAIS PARA FINANCIAMENTO DO ENSINO
    let listaAdicionais: {}[] = [];
    const receitaAdicional = new Anexo8ReceitaAdicional(this.mes, this.exercicio);
    listaAdicionais = receitaAdicional.montarQuadro(dados[8]);

    // OUTRAS DESPESAS COM EDUCAÇÃO (Por Área de Atuação) 
    let listaOutrasDespesas: {}[] = [];
    const outraDespesa = new Anexo8OutraDespesa(this.mes);
    listaOutrasDespesas = outraDespesa.montarQuadro(dados[9]);

    // TOTAL GERAL DAS DESPESAS COM EDUCAÇÃO
    let listaDespesasEducacao: {}[] = [];
    const despesaEducacao = new Anexo8DespesaEducacao(this.mes);
    listaDespesasEducacao = despesaEducacao.montarQuadro(dados[10]);

    // CONTROLE DISPONIBILIDADE
    let listaControleDisponibilidade: {}[] = [];
    const controleDisponibilidade = new Anexo8ControleDisponibilidade(this.mes);
    listaControleDisponibilidade = controleDisponibilidade.montarQuadro(dados[11]);

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);

    return [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 60, 60],
          body: listaImpostos
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 60, 60],
          body: listaReceitaFundeb
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 60],
          body: listasuperavit
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 55, 55, 55, 55, 55],
          body: listaDespesaFundeb
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 55, 55, 55, 55, 55],
          body: listaDespesasCusteadasFundeb
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 60, 60, 60, 60, 60, 60],
          body: listaIndicadores
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 55, 55, 55, 55, 55],
          body: listaDespesasMde
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 55, 55, 55],
          body: apuracao
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 60, 60, 60, 60, 60],
          body: listaRestosMde
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 80, 80],
          body: listaAdicionais
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 55, 55, 55, 55, 55],
          body: listaOutrasDespesas
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 55, 55, 55, 55, 55],
          body: listaDespesasEducacao
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 80, 80],
          body: listaControleDisponibilidade
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*'],
          body: assinaturas
        }
      }
    ];
  }

  public async normalizar(dados: any[]): Promise<{}[]> {
    const listaExportar = []

    // // Caso seja adicionado novos campos no layout será necessario mudar a posição dos arrays

    // RECEITA RESULTANTE DE IMPOSTOS
    const impostos = new Anexo8Imposto(this.mes, this.exercicio, this.login);
    impostos.montarQuadroCsv(dados[0], listaExportar);

    // RECEITAS RECEBIDAS DO FUNDEB NO EXERCÍCIO
    const receitaFundeb = new Anexo8ReceitaFundeb(this.mes, this.exercicio);
    receitaFundeb.montarQuadroCsv(dados[1], dados[0], listaExportar);

    // RECURSOS RECEBIDOS EM EXERCÍCIOS ANTERIORES E NÃO UTILIZADOS (SUPERÁVIT)
    const superavit = new Anexo8Superavit(this.mes);
    superavit.montarQuadroCsv(dados[2], dados[1], listaExportar);

    // DESPESAS COM RECURSOS DO FUNDEB (Por Área de Atuação)
    const despesaFundeb = new Anexo8DespesaFundeb(this.mes, this.exercicio);
    despesaFundeb.montarQuadroCsv(dados[3], listaExportar);

    // DESPESAS CUSTEADAS COM RECEITAS DO FUNDEB RECEBIDAS NO EXERCÍCIO
    const deducaFundeb = new Anexo8DespesaCusteadasFundeb(this.mes);
    deducaFundeb.montarQuadroCsv(dados[4], listaExportar);

    // INDICADORES - Art. 212-A, inciso XI e § 3º - Constituição Federal e INDICADOR - Art.25, § 3º - Lei nº 14.113, de 2020 - (Máximo de 10% de Superávit) e INDICADOR - Art.25, § 3º - Lei nº 14.113, de 2020 - (Aplicação do Superávit de Exercício Anterior)3
    const indicadores = new Anexo8Indicadores(this.mes, this.exercicio);
    indicadores.montarQuadroCsv(dados[1], dados[4], dados[5], listaExportar);

    // DESPESAS COM AÇÕES TÍPICAS DE MDE - RECEITAS DE IMPOSTOS - EXCETO FUNDEB
    const despesaMDE = new Anexo8DespesaMde(this.mes);
    despesaMDE.montarQuadroCsv(dados[6], listaExportar);

    // APURAÇÃO DAS DESPESAS PARA FINS DE LIMITE MÍNIMO CONSTITUCIONAL
    const apu = new Anexo8Apuracao(this.mes, this.exercicio);
    apu.montarQuadroCsv(dados[0], dados[1], dados[4], dados[5], dados[6], dados[7], listaExportar);

    // RESTOS A PAGAR INSCRITOS EM EXERCÍCIOS ANTERIORES COM DISPONIBILIDADE FINANCEIRA DE RECURSOS DE IMPOSTOS E DO FUNDEB
    const restosMde = new Anexo8RestoMde(this.mes, this.exercicio);
    restosMde.montarQuadroCsv(dados[7], listaExportar);

    // RECEITA ADICIONAIS PARA FINANCIAMENTO DO ENSINO
    const receitaAdicional = new Anexo8ReceitaAdicional(this.mes, this.exercicio);
    receitaAdicional.montarQuadroCsv(dados[8], listaExportar);

    // OUTRAS DESPESAS COM EDUCAÇÃO (Por Área de Atuação) 
    const outraDespesa = new Anexo8OutraDespesa(this.mes);
    outraDespesa.montarQuadroCsv(dados[9], listaExportar);

    // TOTAL GERAL DAS DESPESAS COM EDUCAÇÃO
    const despesaEducacao = new Anexo8DespesaEducacao(this.mes);
    despesaEducacao.montarQuadroCsv(dados[10], listaExportar);

    // CONTROLE DISPONIBILIDADE
    const controleDisponibilidade = new Anexo8ControleDisponibilidade(this.mes);
    controleDisponibilidade.montarQuadroCsv(dados[11], listaExportar);

    return listaExportar
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'RECEITA RESULTANTE DE IMPOSTOS (Arts. 212 e 212-A da Constituição Federal)', coluna: 'titulo' },
      { titulo: '', coluna: 'coluna1' },
      { titulo: '', coluna: 'coluna2' },
      { titulo: '', coluna: 'coluna3' },
      { titulo: '', coluna: 'coluna4' },
      { titulo: '', coluna: 'coluna5' },
      { titulo: '', coluna: 'coluna6' },
    ];
    return colunasDefault;
  }
}