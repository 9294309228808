import { Component, Injector, ElementRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import {
  BaseResourceFormComponent, TransferenciaBancaria, LoginPublico,
  GlobalService, DateFormatPipe
} from 'eddydata-lib';
import { TransferenciaBancariaService } from '../service/transferencia-bancaria.service';

@Component({
  selector: 'lib-transferencia-entre-orgao-shw',
  templateUrl: './transferencia-entre-orgao-shw.component.html'
})
export class TransferenciaEntreOrgaoShwComponent extends BaseResourceFormComponent<TransferenciaBancaria, LoginPublico> {

  /**
   * Declaração de variáveis
   */
  private pagina: string;
  private data1: string;
  private data2: string;
  private filtro: string;

  private datepipe: DatePipe;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected transferenciaService: TransferenciaBancariaService) {
    super(new TransferenciaBancaria(), injector, TransferenciaBancaria.converteJson, transferenciaService);
    this.datepipe = new DatePipe('pt');
    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.pagina = params['pagina'];
        this.data1 = params['data1'];
        this.data2 = params['data2'];
        this.filtro = params['filtro'];
      });

  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      data_transfere: [null, [Validators.required]],
      data_referencia: [null, [Validators.required]],
      valor_transferido: 0,
      mes: [null, [Validators.required]],
      especie: [null, [Validators.required]],
      documento: [null, [Validators.required]],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      tipo: [null, [Validators.required]],
      exercicio: this.fb.group({
        ano: [null, [Validators.required]],
      }),
      orgao: [null, [Validators.required]],
      conta_pagadora: this.fb.group({
        agencia: [null, [Validators.required]],
        conta_numero: [null, [Validators.required]],
      }),
      conta_recebedora: this.fb.group({
        agencia: [null, [Validators.required]],
        conta_numero: [null, [Validators.required]],
      })
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations:
        'conta_pagadora,conta_pagadora.orgao,conta_pagadora.banco,conta_recebedora,conta_recebedora.orgao,conta_recebedora.banco'
    };
  }

  protected afterInit(): void {
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected afterLoad() {
    this.entidade.data_transfere = new DateFormatPipe().transform(this.entidade.data_transfere, []);
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: TransferenciaBancaria): void {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public voltar() {
    const dt1 = this.datepipe.transform(this.data1, 'yyyy-MM-dd', 'GMT');
    const dt2 = this.datepipe.transform(this.data2, 'yyyy-MM-dd', 'GMT');
    if (!this.filtro || this.filtro === '') {
      this.router.navigate([`/transferencias-entre-orgaos/pesquisa`, this.pagina, dt1, dt2]);
    } else {
      this.router.navigate([`/transferencias-orgaos/pesquisa`, this.pagina, dt1, dt2, this.filtro]);
    }
  }
}
