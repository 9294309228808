import { Injectable, Injector } from '@angular/core';
import { EmissaoCheque } from 'contabil-lib';
import { BaseResourceService } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class EmissaoChequeService extends BaseResourceService<EmissaoCheque> {

  constructor(
    protected injector: Injector
  ) {
    super('emissao-cheque', injector);
  }

}