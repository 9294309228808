import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { EditorModule } from 'primeng/editor';
import { InplaceModule } from 'primeng/inplace';
import { PickListModule } from 'primeng/picklist';
import { ToastModule } from 'primeng/toast';
import { RelatorioPersonalizadoShwComponent } from './relatorio-personalizado-shw/relatorio-personalizado-shw.component';
import { RelatorioPersonalizadoLstComponent } from './relatorio-personalizado-lst/relatorio-personalizado-lst.component';
import { RelatorioPersonalizadoRoutingModule } from './relatorio-personalizado-routing.module';
import { RelatorioPersonalizadoViewComponent } from './relatorio-personalizado-view/relatorio-personalizado-view.component';
import { SafePipe } from '../util/safe-html-pipe';
import { SharedModule } from '../util/shared.module';
import { RelatorioPersonalizadoFormComponent } from './relatorio-personalizado-form/relatorio-personalizado-form.component';
import { RelatorioPersonalizadoViewBaseComponent } from './relatorio-personalizado-view-base/relatorio-personalizado-view-base.component';

@NgModule({
  declarations: [RelatorioPersonalizadoLstComponent, RelatorioPersonalizadoShwComponent, RelatorioPersonalizadoViewComponent, SafePipe, RelatorioPersonalizadoFormComponent, RelatorioPersonalizadoViewBaseComponent],
  imports: [
    SharedModule,
    FormsModule,
    RelatorioPersonalizadoRoutingModule,
    AutoCompleteModule,
    InplaceModule,
    CalendarModule,
    PickListModule,
    IMaskModule,
    ConfirmDialogModule,
    EditorModule,
    ToastModule
  ],
  exports: [
    RelatorioPersonalizadoLstComponent, RelatorioPersonalizadoShwComponent, RelatorioPersonalizadoViewComponent
  ],
  providers: [MessageService, DatePipe]
})
export class RelatorioPersonalizadoModule { }
