import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../assinaturas/assinaturas';
import { AnexoLRFService } from '../../service/anexo-lrf.service';

@Directive()
export class DividaConsolidadaLiquidaLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();
  public valorDCL = 0;

  constructor(
    protected anexoServico: AnexoLRFService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected assinaturaControleInterno: boolean) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async obterDividaConsolidada(orgaos: number[]) {
    const result = await this.anexoServico.obterDCL(this.mes, this.exercicio.id, orgaos).toPromise();
    return this.conteudo(result[0]);
  }

  // tslint:disable: max-line-length
  public montarRelatorio(orgaos: number[], formato: FormatoExportacao = 'pdf') {
    this.anexoServico.obterDCL(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(
            `DEMONSTRATIVO DA DÍVIDA CONSOLIDADA LÍQUIDA - EXCETO ÓRGÃO DE PREVIDÊNCIA`
            , this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.conteudo(dados[0]),
            'portrait', 'DEMONSTRATIVO DA DÍVIDA CONSOLIDADA LÍQUIDA - EXCETO ÓRGÃO DE PREVIDÊNCIA',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            });
        } else {
          this.funcaoService.exportar(formato, this.normalizar(dados[0]), 'DEMONSTRATIVO DA DÍVIDA CONSOLIDADA LÍQUIDA - EXCETO ÓRGÃO DE PREVIDÊNCIA', this.colunas());
        }
      });
  }

  private async conteudo(dados: any[]): Promise<{}[]> {

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `REFERÊNCIA: ` +
          this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano,
        alignment: 'center', bold: true, colSpan: 5
      }, '', '', '', ''],
      [{
        text: 'LRF, art. 55, inciso I, alínea "b"',
        alignment: 'left', bold: true, colSpan: 5
      }, '', '', '', ''],
      [{
        text: 'ESPECIFICAÇÃO',
        alignment: 'center',
        bold: true, fontSize: 7, rowSpan: 2, margin: 0
      }, {
        text: 'SALDO EXERCÍCIO ANTERIOR',
        alignment: 'center',
        bold: true, fontSize: 7, rowSpan: 2, margin: 0
      }, {
        text: `SALDO DO EXERCÍCIO DE ${this.exercicio.ano}`,
        alignment: 'center',
        bold: true, fontSize: 7, colSpan: 3
      }, '', ''],
      [{ text: '' }, { text: '' }, {
        text: 'Até o 1º Quadrimestre',
        alignment: 'center',
        bold: true, fontSize: 7, margin: 0
      }, {
        text: 'Até o 2º Quadrimestre',
        alignment: 'center',
        bold: true, fontSize: 7, margin: 0
      }, {
        text: 'Até o 3º Quadrimestre',
        alignment: 'center',
        bold: true, fontSize: 7, margin: 0
      }]
    ];

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);

    // monta o agrupamento do relatório
    const grupos = this.funcaoService.agrupar(dados, 'titulo',
      ['anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
    console.log(grupos)
    let totalDivida1 = 0;
    let totalDivida2 = 0;
    let totalDivida3 = 0;
    let totalDivida4 = 0;
    let totalDeducao1 = 0;
    let totalDeducao2 = 0;
    let totalDeducao3 = 0;
    let totalDeducao4 = 0;
    let totalContrib1 = 0;
    let totalContrib2 = 0;
    let totalContrib3 = 0;
    let totalContrib4 = 0;
    for (const grupo of grupos) {
      if (grupo.grupo === 'DÍVIDA CONSOLIDADA - DC (I)') {
        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['anterior'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre1'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre2'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre3'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
        ]);
        totalDivida1 = +grupo.totalizadores['anterior'];
        totalDivida2 = +grupo.totalizadores['quadrimestre1'];
        totalDivida3 = +grupo.totalizadores['quadrimestre2'];
        totalDivida4 = +grupo.totalizadores['quadrimestre3'];
      }
      if (grupo.grupo === 'DEDUÇÕES (II)¹') {
        for (const registro of grupo.registros) {
          if (registro.nome === '(-) Restos a Pagar Processados' || registro.nome === '(-) Depósitos Restituíves e Valores Vinculados') {
            totalDeducao1 -= +registro.anterior;
            totalDeducao2 -= +registro.quadrimestre1;
            totalDeducao3 -= +registro.quadrimestre2;
            totalDeducao4 -= +registro.quadrimestre3;
          } else {
            totalDeducao1 += +registro.anterior;
            totalDeducao2 += +registro.quadrimestre1;
            totalDeducao3 += +registro.quadrimestre2;
            totalDeducao4 += +registro.quadrimestre3;
          }
        }
        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(totalDeducao1, 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalDeducao2, 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalDeducao3, 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalDeducao4, 2, true), alignment: 'right', bold: true, fontSize: 7 },
        ]);
      }
      if (grupo.grupo === 'RECEITA CORRENTE LÍQUIDA - RCL') {
        registros.push([
          {
            text: 'DÍV. CONSOLID. LÍQUIDA (DCL)=(I - II)', bold: true, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(totalDivida1 - totalDeducao1, 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalDivida2 - totalDeducao2, 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalDivida3 - totalDeducao3, 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalDivida4 - totalDeducao4, 2, true), alignment: 'right', bold: true, fontSize: 7 },
        ]);
        if (this.mes <= 4) {
          this.valorDCL = (totalDivida2 - totalDeducao2);
        } else if (this.mes > 4 && this.mes <= 8) {
          this.valorDCL = (totalDivida3 - totalDeducao3);
        } else {
          this.valorDCL = (totalDivida4 - totalDeducao4);
        }
        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['anterior'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre1'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre2'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre3'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
        ]);
        registros.push([
          {
            text: '% da DC sobre a RCL', bold: true, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber((totalDivida1 / grupo.totalizadores['anterior']) * 100, 4, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber((totalDivida2 / grupo.totalizadores['quadrimestre1']) * 100, 4, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber((totalDivida3 / grupo.totalizadores['quadrimestre2']) * 100, 4, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber((totalDivida4 / grupo.totalizadores['quadrimestre3']) * 100, 4, true), alignment: 'right', bold: true, fontSize: 7 },
        ]);
        registros.push([
          {
            text: '% da DCL sobre a RCL', bold: true, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(((totalDivida1 - totalDeducao1) / grupo.totalizadores['anterior']) * 100, 4, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(((totalDivida2 - totalDeducao2) / grupo.totalizadores['quadrimestre1']) * 100, 4, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(((totalDivida3 - totalDeducao3) / grupo.totalizadores['quadrimestre2']) * 100, 4, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(((totalDivida4 - totalDeducao4) / grupo.totalizadores['quadrimestre3']) * 100, 4, true), alignment: 'right', bold: true, fontSize: 7 },
        ]);
        registros.push([
          {
            text: 'LIMITE DEFINIDO POR RESOLUÇÃO DO SENADO FEDERAL:  120%', bold: true, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber((grupo.totalizadores['anterior'] * 120) / 100, 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber((grupo.totalizadores['quadrimestre1'] * 120) / 100, 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber((grupo.totalizadores['quadrimestre2'] * 120) / 100, 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber((grupo.totalizadores['quadrimestre3'] * 120) / 100, 2, true), alignment: 'right', bold: true, fontSize: 7 },
        ]);
        continue;
      }
      if (grupo.grupo === 'Parcelamentos de Dívidas') {
        registros.push([
          {
            text: 'Detalhamento da Dívida Contratual', bold: true, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['anterior'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre1'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre2'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre3'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
        ]);
        // soma somante grupo de contribuições sociais
        for (const registro of grupo.registros) {
          if (registro.grupo === 'De Contribuições Sociais') {
            totalContrib1 += +registro.anterior;
            totalContrib2 += +registro.quadrimestre1;
            totalContrib3 += +registro.quadrimestre2;
            totalContrib4 += +registro.quadrimestre3;
          }
        }

        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['anterior'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre1'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre2'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['quadrimestre3'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
        ]);
      }
      if (grupo.grupo === 'OUTROS VALORES NÃO INTEGRANTES DA DC') {
        let outros = {};
        outros['anterior'] = 0;
        outros['quadrimestre1'] = 0;
        outros['quadrimestre2'] = 0;
        outros['quadrimestre3'] = 0;
        for (const registro of grupo.registros) {
          outros['anterior'] = +outros['anterior'] + +registro['anterior'] * (registro['deducao'] ? -1 : 1);
          outros['quadrimestre1'] = +outros['quadrimestre1'] + +registro['quadrimestre1'] * (registro['deducao'] ? -1 : 1);
          outros['quadrimestre2'] = +outros['quadrimestre2'] + +registro['quadrimestre2'] * (registro['deducao'] ? -1 : 1);
          outros['quadrimestre3'] = +outros['quadrimestre3'] + +registro['quadrimestre3'] * (registro['deducao'] ? -1 : 1);
        }
        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(outros['anterior'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(outros['quadrimestre1'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(outros['quadrimestre2'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(outros['quadrimestre3'], 2, true), alignment: 'right', bold: true, fontSize: 7 },
        ]);
      }
      // ---
      for (const registro of grupo.registros) {
        if (registro.nome === 'Previdenciárias') {
          registros.push([
            {
              text: 'De Contribuições Sociais', fontSize: 7, bold: true
            },
            { text: this.funcaoService.convertToBrNumber(totalContrib1, 2, true), alignment: 'right', fontSize: 7, bold: true },
            { text: this.funcaoService.convertToBrNumber(totalContrib2, 2, true), alignment: 'right', fontSize: 7, bold: true },
            { text: this.funcaoService.convertToBrNumber(totalContrib3, 2, true), alignment: 'right', fontSize: 7, bold: true },
            { text: this.funcaoService.convertToBrNumber(totalContrib4, 2, true), alignment: 'right', fontSize: 7, bold: true },
          ]);
        }
        registros.push([
          {
            text: '  ' + registro.nome, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(registro.anterior, 2, true), alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(registro.quadrimestre1, 2, true), alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(registro.quadrimestre2, 2, true), alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(registro.quadrimestre3, 2, true), alignment: 'right', fontSize: 7 },
        ]);
      }
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 80, 80, 80, 80],
        body: registros
      }
    },
    {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*'],
        body: assinaturas
      }
    }];
  }

  public normalizar(lista: any[]) {
    const listaExportar = []
    let totalDivida1 = 0;
    let totalDivida2 = 0;
    let totalDivida3 = 0;
    let totalDivida4 = 0;
    let totalDeducao1 = 0;
    let totalDeducao2 = 0;
    let totalDeducao3 = 0;
    let totalDeducao4 = 0;
    let totalContrib1 = 0;
    let totalContrib2 = 0;
    let totalContrib3 = 0;
    let totalContrib4 = 0;

    let anterior = 0;
    let quadrimestre1 = 0;
    let quadrimestre2 = 0;
    let quadrimestre3 = 0;

    const grupos = this.funcaoService.agrupar(lista, 'titulo',
      ['anterior', 'quadrimestre1', 'quadrimestre2', 'quadrimestre3']);
    for (const grupo of grupos) {
      anterior = grupo.totalizadores['anterior'];
      quadrimestre1 = grupo.totalizadores['quadrimestre1'];
      quadrimestre1 = grupo.totalizadores['quadrimestre2'];
      quadrimestre1 = grupo.totalizadores['quadrimestre3'];

      if (grupo.grupo === 'DÍVIDA CONSOLIDADA - DC (I)') {
        let primeiroQuadrimestre = 0;
        let segundoQuadrimestre = 0;
        let terceiroQuadrimestre = 0;

        for (const i of grupo.registros) {
          primeiroQuadrimestre = i.quadrimestre1;
          segundoQuadrimestre = i.quadrimestre2;
          terceiroQuadrimestre = i.quadrimestre3;
        }
        totalDivida1 = +anterior;
        totalDivida2 = +primeiroQuadrimestre;
        totalDivida3 = +segundoQuadrimestre;
        totalDivida4 = +terceiroQuadrimestre;

        const divida_consolidada = {
          especificacao: grupo.grupo,
          saldo_exercicio_anterior: this.funcaoService.convertToBrNumber(anterior),
          primeiro_quadrimestre: this.funcaoService.convertToBrNumber(totalDivida2),
          segundo_quadrimestre: this.funcaoService.convertToBrNumber(totalDivida3),
          terceiro_quadrimestre: this.funcaoService.convertToBrNumber(totalDivida4),
        }
        listaExportar.push(divida_consolidada)

        for (const item of grupo.registros) {
          const items_divida_consolidada = {
            especificacao: item.nome,
            saldo_exercicio_anterior: this.funcaoService.convertToBrNumber(item.anterior),
            primeiro_quadrimestre: this.funcaoService.convertToBrNumber(item.quadrimestre1),
            segundo_quadrimestre: this.funcaoService.convertToBrNumber(item.quadrimestre2),
            terceiro_quadrimestre: this.funcaoService.convertToBrNumber(item.quadrimestre3),
          }
          listaExportar.push(items_divida_consolidada)
        }
        continue
      }

      if (grupo.grupo === 'DEDUÇÕES (II)¹') {
        for (const registro of grupo.registros) {
          if (registro.nome === '(-) Restos a Pagar Processados' || registro.nome === '(-) Depósitos Restituíves e Valores Vinculados') {
            totalDeducao1 -= +registro.anterior;
            totalDeducao2 -= +registro.quadrimestre1;
            totalDeducao3 -= +registro.quadrimestre2;
            totalDeducao4 -= +registro.quadrimestre3;
          } else {
            totalDeducao1 += +registro.anterior;
            totalDeducao2 += +registro.quadrimestre1;
            totalDeducao3 += +registro.quadrimestre2;
            totalDeducao4 += +registro.quadrimestre3;
          }
        }

        const deducoes = {
          especificacao: grupo.grupo,
          saldo_exercicio_anterior: this.funcaoService.convertToBrNumber(totalDeducao1,),
          primeiro_quadrimestre: this.funcaoService.convertToBrNumber(totalDeducao2,),
          segundo_quadrimestre: this.funcaoService.convertToBrNumber(totalDeducao3,),
          terceiro_quadrimestre: this.funcaoService.convertToBrNumber(totalDeducao4,),
        }
        listaExportar.push(deducoes)

        for (const item of grupo.registros) {
          const items_deducoes = {
            especificacao: item.nome,
            saldo_exercicio_anterior: this.funcaoService.convertToBrNumber(item.anterior),
            primeiro_quadrimestre: this.funcaoService.convertToBrNumber(item.quadrimestre1),
            segundo_quadrimestre: this.funcaoService.convertToBrNumber(item.quadrimestre2),
            terceiro_quadrimestre: this.funcaoService.convertToBrNumber(item.quadrimestre3),
          }
          listaExportar.push(items_deducoes)
        }
        continue
      }

      if (grupo.grupo === 'RECEITA CORRENTE LÍQUIDA - RCL') {
        let primeiroQuadrimestre = 0;
        let segundoQuadrimestre = 0;
        let terceiroQuadrimestre = 0;

        for (const i of grupo.registros) {
          primeiroQuadrimestre = i.quadrimestre1;
          segundoQuadrimestre = i.quadrimestre2;
          terceiroQuadrimestre = i.quadrimestre3;
        }

        let totalLimiteAnterior = (anterior * 120) / 100
        let totalLimitePrimeiroQuadrimestre = (primeiroQuadrimestre * 120) / 100
        let totalLimiteSegundoQuadrimestre = (segundoQuadrimestre * 120) / 100
        let totalLimiteTerceiroQuadrimestre = (terceiroQuadrimestre * 120) / 100

        const divida_consolidada_liquida = {
          especificacao: 'DÍV. CONSOLID. LÍQUIDA (DCL)=(I - II)',
          saldo_exercicio_anterior: this.funcaoService.convertToBrNumber(totalDivida1 - totalDeducao1),
          primeiro_quadrimestre: this.funcaoService.convertToBrNumber(totalDivida2 - totalDeducao2),
          segundo_quadrimestre: this.funcaoService.convertToBrNumber(totalDivida3 - totalDeducao3),
          terceiro_quadrimestre: this.funcaoService.convertToBrNumber(totalDivida4 - totalDeducao4),
        }
        listaExportar.push(divida_consolidada_liquida)

        if (this.mes <= 4) {
          this.valorDCL = (totalDivida2 - totalDeducao2);
        } else if (this.mes > 4 && this.mes <= 8) {
          this.valorDCL = (totalDivida3 - totalDeducao3);
        } else {
          this.valorDCL = (totalDivida4 - totalDeducao4);
        }

        const receita_corrente_liquida = {
          especificacao: grupo.grupo,
          saldo_exercicio_anterior: this.funcaoService.convertToBrNumber(anterior),
          primeiro_quadrimestre: this.funcaoService.convertToBrNumber(primeiroQuadrimestre),
          segundo_quadrimestre: this.funcaoService.convertToBrNumber(segundoQuadrimestre),
          terceiro_quadrimestre: this.funcaoService.convertToBrNumber(terceiroQuadrimestre),
        }
        listaExportar.push(receita_corrente_liquida)

        const dc_sobre_rlc = {
          especificacao: '% da DC sobre a RCL',
          saldo_exercicio_anterior: this.funcaoService.convertToBrNumber((totalDivida1 / anterior) * 100),
          primeiro_quadrimestre: this.funcaoService.convertToBrNumber((totalDivida2 / primeiroQuadrimestre) * 100),
          segundo_quadrimestre: this.funcaoService.convertToBrNumber((totalDivida3 / segundoQuadrimestre) * 100),
          terceiro_quadrimestre: this.funcaoService.convertToBrNumber((totalDivida4 / terceiroQuadrimestre) * 100),
        }
        listaExportar.push(dc_sobre_rlc)

        const dlc_sobre_rlc = {
          especificacao: '% da DCL sobre a RCL',
          saldo_exercicio_anterior: this.funcaoService.convertToBrNumber(((totalDivida1 - totalDeducao1) / anterior) * 100),
          primeiro_quadrimestre: this.funcaoService.convertToBrNumber(((totalDivida2 - totalDeducao2) / primeiroQuadrimestre) * 100),
          segundo_quadrimestre: this.funcaoService.convertToBrNumber(((totalDivida3 - totalDeducao3) / segundoQuadrimestre) * 100),
          terceiro_quadrimestre: this.funcaoService.convertToBrNumber(((totalDivida4 - totalDeducao4) / terceiroQuadrimestre) * 100),
        }
        listaExportar.push(dlc_sobre_rlc)

        const limite_definido_resolucao = {
          especificacao: 'LIMITE DEFINIDO POR RESOLUÇÃO DO SENADO FEDERAL:  120%',
          saldo_exercicio_anterior: this.funcaoService.convertToBrNumber(totalLimiteAnterior),
          primeiro_quadrimestre: this.funcaoService.convertToBrNumber(totalLimitePrimeiroQuadrimestre),
          segundo_quadrimestre: this.funcaoService.convertToBrNumber(totalLimiteSegundoQuadrimestre),
          terceiro_quadrimestre: this.funcaoService.convertToBrNumber(totalLimiteTerceiroQuadrimestre),
        }
        listaExportar.push(limite_definido_resolucao)
        continue;
      }

      if (grupo.grupo === 'Parcelamentos de Dívidas') {
        const detalhamento_divida_contratual = {
          especificacao: 'Detalhamento da Dívida Contratual',
          saldo_exercicio_anterior: this.funcaoService.convertToBrNumber(anterior),
          primeiro_quadrimestre: this.funcaoService.convertToBrNumber(quadrimestre1),
          segundo_quadrimestre: this.funcaoService.convertToBrNumber(quadrimestre2),
          terceiro_quadrimestre: this.funcaoService.convertToBrNumber(quadrimestre3),
        }
        listaExportar.push(detalhamento_divida_contratual)

        // soma somante grupo de contribuições sociais
        for (const registro of grupo.registros) {
          if (registro.grupo === 'De Contribuições Sociais') {
            totalContrib1 += +registro.anterior;
            totalContrib2 += +registro.quadrimestre1;
            totalContrib3 += +registro.quadrimestre2;
            totalContrib4 += +registro.quadrimestre3;
          }
        }

        const parcelamento_dividas = {
          especificacao: grupo.grupo,
          saldo_exercicio_anterior: this.funcaoService.convertToBrNumber(anterior),
          primeiro_quadrimestre: this.funcaoService.convertToBrNumber(quadrimestre1),
          segundo_quadrimestre: this.funcaoService.convertToBrNumber(quadrimestre2),
          terceiro_quadrimestre: this.funcaoService.convertToBrNumber(quadrimestre3),
        }
        listaExportar.push(parcelamento_dividas)

        for (const item of grupo.registros) {
          if (item.nome === 'De Tributos') {
            const de_tributos = {
              especificacao: item.nome,
              saldo_exercicio_anterior: this.funcaoService.convertToBrNumber(item.anterior),
              primeiro_quadrimestre: this.funcaoService.convertToBrNumber(item.quadrimestre1),
              segundo_quadrimestre: this.funcaoService.convertToBrNumber(item.quadrimestre2),
              terceiro_quadrimestre: this.funcaoService.convertToBrNumber(item.quadrimestre3),
            }
            listaExportar.push(de_tributos)
          }
        }

        const constribuicoes_sociais = {
          especificacao: 'De Contribuições Sociais',
          saldo_exercicio_anterior: this.funcaoService.convertToBrNumber(totalContrib1),
          primeiro_quadrimestre: this.funcaoService.convertToBrNumber(totalContrib2),
          segundo_quadrimestre: this.funcaoService.convertToBrNumber(totalContrib3),
          terceiro_quadrimestre: this.funcaoService.convertToBrNumber(totalContrib4),
        }
        listaExportar.push(constribuicoes_sociais)

        for (const item of grupo.registros) {
          if (item.grupo !== 'De Tributos') {
            const item_parcelamento_dividas = {
              especificacao: item.nome,
              saldo_exercicio_anterior: this.funcaoService.convertToBrNumber(item.anterior),
              primeiro_quadrimestre: this.funcaoService.convertToBrNumber(item.quadrimestre1),
              segundo_quadrimestre: this.funcaoService.convertToBrNumber(item.quadrimestre2),
              terceiro_quadrimestre: this.funcaoService.convertToBrNumber(item.quadrimestre3),
            }
            listaExportar.push(item_parcelamento_dividas)
          }
        }
        continue
      }

      if (grupo.grupo === 'OUTROS VALORES NÃO INTEGRANTES DA DC') {
        let outros = {};
        outros['anterior'] = 0;
        outros['quadrimestre1'] = 0;
        outros['quadrimestre2'] = 0;
        outros['quadrimestre3'] = 0;
        for (const registro of grupo.registros) {
          outros['anterior'] = +outros['anterior'] + +registro['anterior'] * (registro['deducao'] ? -1 : 1);
          outros['quadrimestre1'] = +outros['quadrimestre1'] + +registro['quadrimestre1'] * (registro['deducao'] ? -1 : 1);
          outros['quadrimestre2'] = +outros['quadrimestre2'] + +registro['quadrimestre2'] * (registro['deducao'] ? -1 : 1);
          outros['quadrimestre3'] = +outros['quadrimestre3'] + +registro['quadrimestre3'] * (registro['deducao'] ? -1 : 1);
        }
        const outros_valores = {
          especificacao: grupo.grupo,
          saldo_exercicio_anterior: this.funcaoService.convertToBrNumber(outros['anterior']),
          primeiro_quadrimestre: this.funcaoService.convertToBrNumber(outros['quadrimestre1']),
          segundo_quadrimestre: this.funcaoService.convertToBrNumber(outros['quadrimestre2']),
          terceiro_quadrimestre: this.funcaoService.convertToBrNumber(outros['quadrimestre3']),
        }
        listaExportar.push(outros_valores)

        for (const item of grupo.registros) {
          const itens_outros_valores = {
            especificacao: item.nome,
            saldo_exercicio_anterior: this.funcaoService.convertToBrNumber(item.anterior),
            primeiro_quadrimestre: this.funcaoService.convertToBrNumber(item.quadrimestre1),
            segundo_quadrimestre: this.funcaoService.convertToBrNumber(item.quadrimestre2),
            terceiro_quadrimestre: this.funcaoService.convertToBrNumber(item.quadrimestre3),
          }
          listaExportar.push(itens_outros_valores)
        }
      }
    }
    return listaExportar

  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'ESPECIFICAÇÃO', coluna: 'especificacao', bold: true },
      { titulo: 'SALDO EXERCÍCIO ANTERIOR', coluna: 'saldo_exercicio_anterior' },
      { titulo: 'ATÉ O 1º QUADRIMESTRE', coluna: 'primeiro_quadrimestre' },
      { titulo: 'ATÉ O 2º QUADRIMESTRE', coluna: 'segundo_quadrimestre' },
      { titulo: 'ATÉ O 3º QUADRIMESTRE', coluna: 'terceiro_quadrimestre' },
    ];
    return colunasDefault;
  }

}
