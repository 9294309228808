import { OrdemPagamentoItem } from './ordem-pagamento-item.model';
// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ContaBancaria } from './conta-bancaria.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { EmpenhoExtra } from '../contabil/empenho-extra.model';
import { Usuario } from '../comum/usuario.model';
import { ContaBancariaRecurso } from './conta-bancaria-recurso.model';

export class PagamentoExtra extends BaseResourceModel {
  constructor(
    public id?: number,
    public mes?: number,
    public parcela?: number,
    public data_pagamento?: Date,
    public especie?: string,
    public documento?: string,
    public historico?: string,
    public valor_pago?: number,
    public valor_retido?: number,
    public aux?: number,
    public impresso?: boolean,
    public anulacao?: boolean,
    public retido?: boolean,
    public conta?: ContaBancaria,
    public conta_recurso?: ContaBancariaRecurso,
    public exercicio?: Exercicio,
    public orgao?: Orgao,
    public empenho?: EmpenhoExtra,
    public usuario_cadastro?: Usuario,
    public data_cadastro?: Date,
    public pagamento_anulado?: PagamentoExtra,
    public data_alteracao?: Date,
    public ordem_pagamento_item?: OrdemPagamentoItem,
    public situacao?: string,
    public pagamento_S13?: boolean,
    public valor_tributavel?: number,
    public valor_RRA?: number,
    public juridico_RRA?: boolean,
    public indice_fci_scp?: number,
    public cnpj_fci_scp?: string,
    public porcentagem_scp?: number,
    public pais_pagamento?: number,
    public nif_tributo?: number
  ) {
    super();
  }

  static converteJson(json: any): PagamentoExtra {
    return Object.assign(new PagamentoExtra(), json);
  }
}
