import { BaseResourceModel } from '../../models/base-resource.model';
import { Fiscalizacao } from './fiscalizacao.model';

export class FiscalizacaoAnalise extends BaseResourceModel {
  constructor(
    public id?: number,
    public titulo?: string,
    public tabela?: string,
    public parecer?: string,
    public fiscalizacao?: Fiscalizacao,
    public json?: { texto: string; estilo?: {} }[][]
  ) {
    super();
  }
  static converteJson(json: any): FiscalizacaoAnalise {
    return Object.assign(new FiscalizacaoAnalise(), json);
  }
}
