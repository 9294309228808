import { BaseResourceModel, Exercicio, PlanoConta } from 'eddydata-lib';
import { GrupoDepreciacao } from './grupo-depreciacao.model';
export class GrupoDepreciacaoItem extends BaseResourceModel {
    constructor(
        public id?: number,
        public grupo?: GrupoDepreciacao,
        public conta?: PlanoConta,
        public vida_util_meses?: number,
        public valor_residual_percentual?: number,
        public exercicio?: Exercicio,
    ) {
        super()
    }

    static converteJson(json: any): GrupoDepreciacaoItem {
        return Object.assign(new GrupoDepreciacaoItem(), json)
    }
}