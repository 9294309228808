// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';

export class CodigoGps extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: number,
    public descricao?: string
  ) {
    super();
  }
  static converteJson(json: any): CodigoGps {
    return Object.assign(new CodigoGps(), json);
  }
}
