import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FuncaoService, LoginContabil, Exercicio, GlobalService, OrgaoAssinaturaService } from 'eddydata-lib';
import { BalancoService } from '../service/balanco.service';
import { NotaExplicativaService } from '../service/nota-explicativa.service';
import { BaseResourceNotaExplicativa } from '../base-resource-nota-explicativa';

@Injectable({
  providedIn: 'root'
})
export class Anexo18A extends BaseResourceNotaExplicativa implements OnDestroy {

  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: BalancoService,
    protected notaService: NotaExplicativaService,
    protected assinaturaService: OrgaoAssinaturaService
  ) {
    super('B18A', notaService, assinaturaService)
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(dados: any, exercicio: Exercicio): {}[] {

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: '', colSpan: 3, border: [true, true, true, false]
      }, '', ''],
      [{
        text: `ANEXO 18A - QUADRO DE RECEITAS DERIVADAS E ORIGINÁRIAS`, fontSize: 11,
        alignment: 'center', bold: true, colSpan: 3, border: [true, false, true, true]
      }, '', ''],
      [{
        text: 'TÍTULOS',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'EXERCÍCIO ATUAL',
        alignment: 'center',
        bold: true, fontSize: 8,
      }, {
        text: 'EXERCÍCIO ANTERIOR',
        alignment: 'center',
        bold: true, fontSize: 8
      }]
    ];

    // monta o agrupamento do relatório
    const titulos = this.funcaoService.agrupar(dados[2], 'titulo', ['valor_atual', 'valor_anterior']);
    let total1 = 0;
    let total2 = 0;
    for (const titulo of titulos) {
      if (titulo.grupo) {
        registros.push([
          {
            text: titulo.grupo, bold: true, fontSize: 9, border: [true, false, true, false], alignment: 'left'
          },
          {
            text: '', border: [true, false, true, false]
          },
          {
            text: '', border: [true, false, true, false]
          }
        ]);
      }

      // lista os registros do relatorio
      for (const registro of titulo.registros) {
        registros.push([
          {
            text: registro.nome, fontSize: 8, border: [true, false, true, false], margin: [5, 0, 0, 0]
          },
          {
            text: this.funcaoService.convertToBrNumber(registro.valor_atual), alignment: 'right',
            fontSize: 8, border: [true, false, true, false]
          },
          {
            text: this.funcaoService.convertToBrNumber(registro.valor_anterior), alignment: 'right',
            fontSize: 8, border: [true, false, true, false]
          }
        ]);

        total1 += +registro.valor_atual;
        total2 += +registro.valor_anterior;
      }
    }

    registros.push([
      {
        text: 'Total das Receitas Derivadas e Originárias', bold: true, fontSize: 8, border: [true, true, true, true], margin: [0, 5, 0, 5]
      },
      {
        text: this.funcaoService.convertToBrNumber(total1), bold: true, alignment: 'right',
        fontSize: 8, border: [true, true, true, true], margin: [0, 5, 0, 5]
      },
      {
        text: this.funcaoService.convertToBrNumber(total2), bold: true, alignment: 'right',
        fontSize: 8, border: [true, true, true, true], margin: [0, 5, 0, 5]
      }
    ]);
    registros.push([
      {
        text: '', colSpan: 3, border: [false, false, false, false]
      },
      {
        text:''
      },
      {
        text: ''
      }
    ]);

    return registros;
  }

  public montarRelatorioCSV(dados: any): {}[] {

    // monta o cabecalho
    const registros: {}[] = [
      [{ text: '' }], [{ text: `ANEXO 18A - QUADRO DE RECEITAS DERIVADAS E ORIGINÁRIAS` }],
      [{ text: 'TÍTULOS' }, { text: 'EXERCÍCIO ATUAL'}, { text: 'EXERCÍCIO ANTERIOR' }],
    ];

    // monta o agrupamento do relatório
    const titulos = this.funcaoService.agrupar(dados[2], 'titulo', ['valor_atual', 'valor_anterior']);
    let total1 = 0;
    let total2 = 0;
    for (const titulo of titulos) {
      if (titulo.grupo) {
        registros.push([{ text: titulo.grupo }]);
      }

      // lista os registros do relatorio
      for (const registro of titulo.registros) {
        registros.push([
          { text: registro.nome },
          { text: this.funcaoService.convertToBrNumber(registro.valor_atual) },
          { text: this.funcaoService.convertToBrNumber(registro.valor_anterior) }
        ]);

        total1 += +registro.valor_atual;
        total2 += +registro.valor_anterior;
      }
    }

    registros.push([
      { text: 'Total das Receitas Derivadas e Originárias' },
      { text: this.funcaoService.convertToBrNumber(total1) },
      { text: this.funcaoService.convertToBrNumber(total2) },
    ]);

    return registros;
  }

}
