import { Directive, OnDestroy } from "@angular/core";
import { LiquidacaoService } from "administrativo-lib";
import { Favorecido, FichaDespesa, FuncaoService, GlobalService, Liquidacao, Login, Relatorio } from "eddydata-lib";
import JsBarcode from "jsbarcode";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";


@Directive()
export class LiquidacaoFornecedorRelatorio implements OnDestroy {

  protected unsubscribe: Subject<void> = new Subject()
  protected funcaoService: FuncaoService
  private login: Login = new Login();

  constructor(
    protected liquidacaoService: LiquidacaoService
  ) {
    this.funcaoService = new FuncaoService()
    this.login = GlobalService.obterSessaoLogin();
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  public imprimir(param: { favorecido?: Favorecido, dtInicial?: string, dtFinal?: string, fichaDespesa?: FichaDespesa, numeroInicial?: number, numeroFinal?: number, agruparRecurso?: boolean, ordenadoPor?: 'codigo' | 'nome' | 'cnpj' }) {

    const ordenarRecurso = 'empenho.ficha.recurso.codigo$ASC,'

    const parametros = {
      relations: 'orgao,exercicio,empenho.favorecido,empenho.subelemento,empenho.ficha.recurso,empenho.ficha.executora,empenho.ficha.funcao,empenho.ficha.programa',
      // 'empenho.arquivofolha_id$not_null': true,
      orderBy: param.agruparRecurso ? ordenarRecurso + 'empenho.favorecido.id$ASC' : 'empenho.favorecido.id$ASC',
      orgao_id: this.login.orgao.id,
      exercicio_id: this.login.exercicio.id
    }

    if (param.favorecido) parametros['empenho.favorecido'] = param.favorecido.id
    if (param.dtInicial) parametros['data_liquidacao$ge'] = param.dtInicial
    if (param.dtFinal) parametros['data_liquidacao$le'] = param.dtFinal
    if (param.fichaDespesa) parametros['empenho.ficha'] = param.fichaDespesa.id
    if (param.numeroInicial) parametros['empenho.numero$ge'] = param.numeroInicial
    if (param.numeroFinal) parametros['empenho.numero$le'] = param.numeroFinal

    if (param.ordenadoPor === 'nome') {
      parametros.orderBy = param.agruparRecurso ? ordenarRecurso + 'empenho.favorecido.nome$ASC' : 'empenho.favorecido.nome$ASC'
    } else if (param.ordenadoPor === 'cnpj') {
      parametros.orderBy = param.agruparRecurso ? ordenarRecurso + 'empenho.favorecido.cpf_cnpj$ASC' : 'empenho.favorecido.cpf_cnpj$ASC'
    }

    this.liquidacaoService.filtrar(1, -1, parametros).pipe(takeUntil(this.unsubscribe)).subscribe(res => {

      Relatorio.imprimirPersonalizado('LIQUIDAÇÃO POR FORNECEDOR', this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, this.montarConteudo(res.content, param.agruparRecurso), 'landscape', 'Liquidação por fornecedor',
        {
          linhas: {
            hLineWidth(i, node) {
              return 1;
            },
            vLineWidth(i) {
              return 1;
            },
            hLineColor(i) {
              return 'black';
            },
            paddingLeft(i) {
              return 3;
            },
            paddingRight(i, node) {
              return 3;
            }
          }
        })
    })

  }

  private montarConteudo(lista: Liquidacao[], agruparRecurso: boolean) {
    const conteudo: {}[] = []

    conteudo.push([
      { text: 'FORNECEDOR', alignment: 'center', bold: true },
      { text: 'DATA', alignment: 'center', bold: true },
      { text: 'EMPENHO', alignment: 'center', bold: true },
      { text: 'FICHA', alignment: 'center', bold: true },
      { text: 'UNID. EXECUTORA', alignment: 'center', bold: true },
      { text: 'DESPESA', alignment: 'center', bold: true },
      { text: 'PROGRAMA', alignment: 'center', bold: true },
      { text: 'FUNÇÃO', alignment: 'center', bold: true },
      { text: 'DOCUMENTO', alignment: 'center', bold: true },
      { text: 'VALOR', alignment: 'center', bold: true }
    ])

    if (agruparRecurso) {
      const listaAgrupada = this.funcaoService.agrupar(lista, ['empenho.ficha.recurso.codigo', 'empenho.ficha.recurso.nome'], ['valor_liquidado'])

      listaAgrupada.forEach(liquidacoesGrupo => {
        conteudo.push([
          { text: `Recurso: ${liquidacoesGrupo.grupo['empenho.ficha.recurso.codigo']} - ${liquidacoesGrupo.grupo['empenho.ficha.recurso.nome']}`, colSpan: 8, bold: true, border: [true, true, false, true] }, '', '', '', '', '', '', '',
          { text: 'Total: ', bold: true, alignment: 'right', border: [false, true, false, true] },
          { text: this.funcaoService.convertToBrNumber(liquidacoesGrupo.totalizadores['valor_liquidado']), alignment: 'right', border: [false, true, true, true] }
        ])

        this.gerarLinhas(liquidacoesGrupo.registros, conteudo)

      })
    } else {
      this.gerarLinhas(lista, conteudo)
    }

    conteudo.push([
      { text: 'Total:', bold: true, colSpan: 8, border: [true, true, false, true] }, '', '', '', '', '', '', '',
      { text: this.funcaoService.convertToBrNumber(lista.reduce((acc, liq) => +liq.valor_liquidado + acc, 0)), bold: true, colSpan: 2, alignment: 'right', border: [false, true, true, true] }, ''
    ])

    return [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*', 'auto'],
          body: conteudo
        }
      }
    ]
  }

  public gerarLinhas(lista: Liquidacao[], conteudo: {}[]) {
    lista.forEach(liquidacao => {
      conteudo.push([
        { text: liquidacao.empenho.favorecido.id, alignment: 'center' },
        { text: this.funcaoService.converteDataBR(liquidacao.data_liquidacao), alignment: 'center' },
        { text: liquidacao.empenho.numero, alignment: 'center' },
        { text: liquidacao.empenho.ficha.numero, alignment: 'center' },
        { text: this.funcaoService.mascarar('##.##.##', liquidacao.empenho.ficha.executora.codigo), alignment: 'center' },
        { text: liquidacao.empenho.subelemento.codigo },
        { text: liquidacao.empenho.ficha.programa.codigo, alignment: 'center' },
        { text: liquidacao.empenho.ficha.funcao.codigo },
        { text: liquidacao.documento },
        { text: this.funcaoService.convertToBrNumber(liquidacao.valor_liquidado), alignment: 'right' }
      ])

      this.gerarCodBarras(liquidacao, conteudo)

    })
  }


  public gerarCodBarras(liquidacao: Liquidacao, conteudo: {}[]) {
    const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
    const barCode = `018170${this.funcaoService.strZero((+liquidacao.valor_liquidado).toFixed(2), 12).split('.').join('')}` +
      `${this.login.orgao.codigo.substring(0, 4)}${this.funcaoService.converteDataSQL(new Date(liquidacao.data_liquidacao)).split('-').join('')}` +
      `${this.funcaoService.strZero(liquidacao.empenho.numero, 10)}${this.funcaoService.strZero(liquidacao.parcela, 3)}0090`;
    JsBarcode(canvas, String(barCode), { displayValue: false, height: 70, margin: 0 });

    conteudo.push([
      {
        image: canvas.toDataURL(),
        fit: [250, 70], margin: [10, 5], colSpan: 10
      }, '', '', '', '', '', '', '', '', ''
    ])
  }

}