import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { TipoConvenioEventoComponent } from './tipo-convenio-evento/tipo-convenio-evento.component';
import { TipoConvenioFormComponent } from './tipo-convenio-form/tipo-convenio-form.component';
import { TipoConvenioListComponent } from './tipo-convenio-list/tipo-convenio-list.component';
import { TipoConvenioRoutingModule } from './tipo-convenio-routing.module';
import { TipoConvenioViewComponent } from './tipo-convenio-view/tipo-convenio-view.component';
import { TipoConvenioCondicaoDespesaComponent } from './tipo-convenio-condicao-despesa/tipo-convenio-condicao-despesa.component';
import { PanelModule } from 'primeng/panel';

@NgModule({
  declarations: [TipoConvenioListComponent, TipoConvenioFormComponent, TipoConvenioEventoComponent, TipoConvenioViewComponent, TipoConvenioCondicaoDespesaComponent],
  imports: [
    CommonModule,
    TipoConvenioRoutingModule,
    SharedModule,
    FormsModule,
    ToastModule,
    IMaskModule,
    AutoCompleteModule,
    PanelModule
  ]
})
export class TipoConvenioModule { }
