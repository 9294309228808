import { BaseResourceModel } from '../../models/base-resource.model';
import { AssinaturaPessoa } from './assinatura-pessoa.model';
import { Orgao } from './orgao.model';

export class Assinatura extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public sistema?: string,
    public ativo?: boolean,
    public orgao?: Orgao,
    public pessoas?: AssinaturaPessoa[],
  ) {
    super();
  }
  static converteJson(json: any): Assinatura {
    return Object.assign(new Assinatura(), json);
  }
}
