// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class ParametroContabil extends BaseResourceModel {

  constructor(
    public id?: number,
    public redirecionar_liquidar_pagar?: boolean,
    public dias_bloquear_alteracoes?: number,
    public desdobramento_estoque?: boolean,
    public terceiro_setor_pagto_contabil?: boolean, // prestacao de contas considerando a soma dos pagamentos feitos na contabilidade
    public repetir_dados_empenho?: boolean,
    public verificar_liquidacao_estoque?: boolean,
    public fonte_recurso_pagamento?: boolean,
    public informar_data_liquidacao_estoque?: boolean,
    public escriturar_aplicacao_dotacao?: boolean,
    public escriturar_aplicacao_banco?: boolean,
    public reserva_sem_pre_empenho?: boolean,
    public bloquear_liquidacao_sem_entrada?: boolean,
    public orgao?: Orgao,
    public utilizar_aliquota_ir?: boolean,
    public utilizar_aliquota_csll?: boolean,
    public utilizar_aliquota_cofins?: boolean,
    public utilizar_aliquota_pis_pasep?: boolean,
    public credito_adicional_sem_anulacao?: boolean,
    public assinatura_controle_interno?: boolean,
    public assinatura_ensino?: boolean,
    public assinatura_tesouraria?: boolean,
    public exigir_conferencia_simples?: boolean,
    public utilizar_339030_como_adiantamento?: boolean,
    public nao_requisitar_01_100?: boolean,
    public subelementos_como_adiantamento?: string,
    public assinatura_por_funcao?: boolean,
    public puxar_data_automatica?: boolean,
  ) {
    super();
  }

  static converteJson(json: any): ParametroContabil {
    return Object.assign(new ParametroContabil(), json);
  }
}
