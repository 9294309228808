// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { FavorecidoProduto } from '../compra/favorecido-produto.model';
import { Orgao } from '../comum/orgao.model';
import { Aliquota } from '../contabil/aliquota.model';
import { Licitacao } from '../licitacao/licitacao.model';
import { Material } from './material.model';
import { ProdutoLocalizacao } from './produto-localizacao.model';
import { ProdutoMedicamento } from './produto-medicamento.model';
import { ProdutoReferencia } from './produto-referencia.model';
import { ProdutoUnidade } from './produto-unidade.model';

export class Produto extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public codigo?: string,
    public descricao?: string,
    public bec?: boolean,
    public aux?: string,
    public curva?: 'A' | 'B' | 'C',
    public estoque_min?: number,
    public estoque_max?: number,
    public material?: Material,
    public localizacao?: ProdutoLocalizacao,
    public medicamento?: ProdutoMedicamento,
    public unidades?: ProdutoUnidade[],
    public referencias?: ProdutoReferencia[],
    public financeiro_min?: number,
    public financeiro_max?: number,
    public favorecidos?: FavorecidoProduto[],
    public licitacoes?: Licitacao[],
    public inativo?: boolean,
    public desabilita_compra?: boolean,
    public vida_util_meses?: number,
    public valor_residual?: number,
    public orgao?: Orgao,
    public cod_mercadoria?: string,
    public codigo_reinf?: number,
    public aliquota?: Aliquota,
    public ignorarValidacao?: boolean,
  ) {
    super();
  }

  static converteJson(json: any): Produto {
    return Object.assign(new Produto(), json);
  }
}
