import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { FieldsetModule } from 'primeng/fieldset';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { CheckboxModule } from 'primeng/checkbox';
import { favorecidoRptComponent } from './favorecido-rpt/favorecido-rpt.component';
import { MenuModule } from 'primeng/menu';

@NgModule({
  declarations: [favorecidoRptComponent],
  exports: [favorecidoRptComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    RadioButtonModule,
    FieldsetModule,
    CheckboxModule,
    MenuModule
  ],
  providers: []
})
export class RelatorioFavorecidoModule { }
