import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AcaoGovernoViewComponent } from './acao-governo-view/acao-governo-view.component';
import { AcaoGovernoListComponent } from './acao-governo-list/acao-governo-list.component';
import { AcaoGovernoFormComponent } from './acao-governo-form/acao-governo-form.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: AcaoGovernoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: AcaoGovernoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: AcaoGovernoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: AcaoGovernoViewComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AcaoGovernoRoutingModule { }
