import { TipoLogSigs, TipoMovimentoLogSigs } from "../../components/types";
import { BaseResourceModel } from "../../models/base-resource.model";
import { ProdutoUnidade } from "./produto-unidade.model";
import { SetorAlmoxarifado } from "./setor-almoxarifado.model";

export class LogsSigs extends BaseResourceModel {
    constructor(
        public id?: number,
        public data_cadastro?: Date,
        public data_alteracao?: Date,
        public id_externo?: string,
        public id_gerado?: number,
        public material?: string,
        public produto_eddy?: ProdutoUnidade,
        public data_sync?: Date,
        public situacao?: 0 | 1,
        public motivo?: string,
        public setorAlmoxarifado?: SetorAlmoxarifado,
        public tipo?: TipoLogSigs,
        public tipo_movimento?: TipoMovimentoLogSigs,
        public corrigido?: boolean,
    ) {
        super()
    }

    static converteJson(json: any): LogsSigs {
        return Object.assign(new LogsSigs(), json);
    }
}