import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'CPF'
})

export class CpfPipe implements PipeTransform {
    transform(value: string, ...args: any[]): any {
        if (!value) {
            return '';
        }

        value = value.split('.').join('')
            .split(',').join('')
            .split(':').join('')
            .split(';').join('')
            .split('/').join('')
            .split('-').join('');

        if (value.length === 11) {
            return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');
        } else if (value.length === 14) {
            return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4-\$5');
        } else {
            return value;
        }
    }
}
