import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { EmpenhoLstComponent } from './empenho-lst/empenho-lst.component';
import { EmpenhoShwComponent } from './empenho-shw/empenho-shw.component';

const routes: Routes = [
  {
    path: 'pesquisa/:especie/:pagina/:data1/:data2',
    component: EmpenhoLstComponent, canActivate: [AuthGuard]
  },
  {
    path: 'pesquisa/:especie/:pagina/:data1/:data2/:filtro',
    component: EmpenhoLstComponent, canActivate: [AuthGuard]
  },
  {
    path: 'visualiza/:id/:especie/:pagina/:data1/:data2',
    component: EmpenhoShwComponent, canActivate: [AuthGuard]
  },
  {
    path: 'visualiza/:id/:especie/:pagina/:data1/:data2/:filtro',
    component: EmpenhoShwComponent, canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmpenhoRoutingModule { }

