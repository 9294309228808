import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import {
  BaseResourceListComponent, Coluna, DateFormatPipe, Filtro, FormatoExportacao, GlobalService, LoginContabil, RecebimentoExtra
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RecebimentoExtraService } from '../service/recebimento-extra.service';

@Component({
  selector: 'app-recebimento-extra-list',
  templateUrl: './recebimento-extra-list.component.html'
})
export class RecebimentoExtraListComponent extends BaseResourceListComponent<RecebimentoExtra, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public especieSelect = 'REE';
  public listaEspecies: Array<any>;
  public ptBR: any;
  public datepipe: DatePipe;
  public data1: Date;
  public data2: Date;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private globalService: GlobalService,
    private recebimentoService: RecebimentoExtraService) {
    super(recebimentoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'conta,conta.banco,ficha,ficha.plano,exercicio,orgao';
  }

  protected ordenacaoGrid(): string[] {
    return ['data_recebimento$DESC', 'id$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      date: ['data_recebimento'],
      number: ['conta.agencia', 'conta.numero_conta', 'valor_recebido'],
      text: ['ficha.nome', 'ficha.numero', 'ficha.plano.codigo', 'conta.banco.nome',]
    };
  }

  protected condicoesGrid(): {} {
    const parametros = {};
    this.datepipe = new DatePipe('pt');
    parametros['ignoreCondObrig'] = true;
    parametros['data_recebimento$ge'] = this.datepipe.transform(this.data1, 'yyyy-MM-dd');
    parametros['data_recebimento$le'] = this.datepipe.transform(this.data2, 'yyyy-MM-dd');
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['exercicio.id'] = this.login.exercicio.id

    sessionStorage.removeItem('/recebimentos_data1');
    sessionStorage.removeItem('/recebimentos_data2');

    return parametros;
  }

  public beforeInit(): void {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');

    this.usarFiltroPersonalizado = true;


    const dt1: string = sessionStorage.getItem('/recebimentos_data1');
    const dt2: string = sessionStorage.getItem('/recebimentos_data2');
    if (!dt1) {
      this.data1 = new DateFormatPipe().transform(this.datepipe.transform(new Date(`01/01/${this.login.exercicio.ano}`), 'yyyy-MM-dd'), []);
    } else {
      this.data1 = new DateFormatPipe().transform(dt1, []);
    }
    if (!dt2) {
      this.data2 = new DateFormatPipe().transform(this.datepipe.transform(new Date(), 'yyyy-MM-dd'), []);
    } else {
      this.data2 = new DateFormatPipe().transform(dt2, []);
    }
  }

  protected afterInit(): void {
    this.listaEspecies = [
      { id: 'REO', nome: 'ORÇAMENTÁRIA' },
      { id: 'REE', nome: 'EXTRA-ORÇAMENTARIA' }
    ];
  }

  protected acaoRemover(model: RecebimentoExtra): Observable<RecebimentoExtra> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Data', coluna: 'data_recebimento' },
      { titulo: 'Nº ficha', coluna: 'ficha.numero' },
      { titulo: 'Código', coluna: 'ficha.plano.codigo' },
      { titulo: 'Descrição', coluna: 'ficha.plano.nome' },
      { titulo: 'Banco', coluna: 'conta.banco.nome' },
      { titulo: 'Agência nome', coluna: 'conta.agencia_nome' },
      { titulo: 'Número conta', coluna: 'conta.numero_conta' },
      { titulo: 'Valor', coluna: 'valor_recebido', decimais: 2, alignment: 'right' }
    ];
  }


  public obterColunasFiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Data Recebimento', coluna: 'data_recebimento', tipo: 'Date', alignment: 'center', padrao: true, filtro2: new Date() });
    retorno.push({ titulo: 'Nº Ficha', coluna: 'ficha.numero', padrao: true, tipo: 'Number', cols: 3 });
    retorno.push({ titulo: 'Código contábil', coluna: 'ficha.plano.codigo', padrao: true, tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Valor', coluna: 'valor_recebido', padrao: false, tipo: 'Number', cols: 3 });
    retorno.push({ titulo: 'Guia', coluna: 'guia', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Descricao', coluna: 'ficha.nome', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Número Conta', coluna: 'conta.numero_conta', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Histórico', coluna: 'historico', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Id. Conta', coluna: 'conta.codigo', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Código da Espécie', coluna: 'especie', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Recurso', coluna: 'ficha.recurso.codigo', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Aplicação', coluna: 'ficha.aplicacao.codigo', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Aplicação Variável', coluna: 'ficha.aplicacao_variavel.variavel', tipo: 'String', cols: 3 });
    return retorno;
  }


  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'conta.banco,ficha.plano,exercicio,orgao';
    this.recebimentoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE RECEBIMENTOS EXTRA ORÇAMENTÁRIOS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem extra recebimentos', ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'], lista.content, ['valor_recebido']);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  onChangeRecebimento(value: any, especie: any) {
    if (especie === 'REO') {
      this.router.navigate(['recebimentos']);
    }
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/tesouraria']);
        break;
      default:
        this.sair();
        break;
    }
  }
}
