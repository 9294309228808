import { Usuario } from 'eddydata-lib';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Funcionario } from '../folha/funcionario.model';
import { AutorizacaoAbastecimento } from './autorizacao-abastecimento.model';
import { Combustivel } from './combustivel.model';
import { Motorista } from './motorista.model';
import { Portaria } from './portaria.model';
import { PostoAbastecimento } from './posto-abastecimento.model';
import { TipoDocumento } from './tipo-documento.model';
import { Veiculo } from './veiculo.model';

export class Abastecimento extends BaseResourceModel {
  constructor(
    public id?: number,
    public km_anterior?: number,
    public km_atual?: number,
    public quantidade?: number,
    public valor_total?: number,
    public intermediario?: boolean,
    public tipo_documento?: TipoDocumento,
    public combustivel?: Combustivel,
    public frentista?: Funcionario,
    public veiculo?: Veiculo,
    public motorista?: Motorista,
    public portaria?: Portaria,
    public posto?: PostoAbastecimento,
    public autorizacao?: AutorizacaoAbastecimento,
    public usuario_exclusao?: Usuario,
    public motivo_exclusao?: string,
    public data_exclusao?: Date,
    public excluido?: boolean,
    public nota_fiscal?: string,
    public observacao?: string,
  ) {
    super();
  }

  static converteJson(json: any): Abastecimento {
    return Object.assign(new Abastecimento(), json);
  }
}
