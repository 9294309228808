// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ChamamentoLocal } from './chamamento-local.model';
import { Convenio } from '../compra/convenio.model';
import { Favorecido } from '../compra/favorecido.model';
import { Chamamento } from './chamamento.model';

export class ChamamentoFavorecido extends BaseResourceModel {
  constructor(
    public id?: number,
    public favorecido?: Favorecido,
    public local?: ChamamentoLocal,
    public chamamento?: Chamamento,
    public habilitada?: boolean,
    public convenio?: Convenio,
    public editavel?: boolean
  ) {
    super();
  }
  static converteJson(json: any): ChamamentoFavorecido {
    return Object.assign(new ChamamentoFavorecido(), json);
  }
}
