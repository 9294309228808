import { Liquidacao } from '../contabil/liquidacao.model';
// tslint:disable: variable-name
import { EspecieAlmoxarifado, StatusMovimentacao } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Compra } from '../compra/compra.model';
import { Favorecido } from '../compra/favorecido.model';
import { Rcms } from '../compra/rcms.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { Pessoa } from '../comum/pessoa.model';
import { Usuario } from '../comum/usuario.model';
import { MovimentoEstoqueTipo } from './movimento-estoque-tipo.model';
import { MovimentoItemEstoque } from './movimento-item-estoque.model';
import { SetorAlmoxarifado } from './setor-almoxarifado.model';
import { Veiculo } from '../frota/veiculo.model';
import { OrdemServico } from '../frota/ordem-servico.model';
import { LiquidacaoResto } from '../contabil/liquidacao-resto.model';

export class MovimentoEstoque extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_movimento?: Date,
    public data_documento?: Date,
    public documento?: string,
    public observacao?: string,
    public especie?: EspecieAlmoxarifado,
    public status?: StatusMovimentacao,
    public status_emprestimo?: StatusMovimentacao,
    public entrada?: boolean,
    public consumo_direto?: boolean,
    public emprestimo?: boolean,
    public n_processo?: string,
    public verificacao_automatica?: boolean,
    public excluido?: boolean,
    public entrega_paciente?: boolean,
    public nome_paciente?: string,
    public mcv_paciente?: string,
    public data_exclusao?: Date,
    public motivo_exclusao?: string,
    public justificativa_ajuste_lote?: string,
    public recebedor?: Pessoa,
    public saida_automatica?: MovimentoEstoque,
    public saida_origem?: MovimentoEstoque,
    public setorAlmoxarifado?: SetorAlmoxarifado,
    public setorDestinoAlmoxarifado?: SetorAlmoxarifado,
    public favorecido?: Favorecido,
    public veiculo?: Veiculo,
    public exercicio?: Exercicio,
    public orgao?: Orgao,
    public usuario_exclusao?: Usuario,
    public usuario_recusa?: Usuario,
    public motivo_recusa?: string,
    public motivo_parcial?: string,
    public tipo?: MovimentoEstoqueTipo,
    public compra?: Compra,
    public rcms?: Rcms,
    public liquidacao?: Liquidacao,
    public liquidacao_resto?: LiquidacaoResto,
    public desvinculado_liquidacao?: boolean,
    public ordem_servico?: OrdemServico,
    public itens?: MovimentoItemEstoque[],
  ) {
    super();
  }

  static converteJson(json: any): MovimentoEstoque {
    return Object.assign(new MovimentoEstoque(), json);
  }
}
