import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private locale = 'pt-br';
  private token = '';
  private tamanhoPagina = 20;

  constructor() {
  }

  getLocale() {
    return this.locale;
  }

  getToken() {
    return this.token;
  }

  setToken(token: string) {
    this.token = token;
  }

  getTamanhoPagina() {
    return this.tamanhoPagina;
  }

  setTamanhoPagina(tamanhoPagina: number) {
    this.tamanhoPagina = tamanhoPagina;
  }

}
