// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Compra } from './compra.model';
import { ProdutoUnidade } from '../almoxarifado/produto-unidade.model';

export class CompraItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public aux?: number,
    public ordem?: number,
    public quantidade?: number,
    public valor_unitario?: number,
    public valor_desconto?: number,
    public valor_icmsipi?: number,
    public produto?: string,
    public unidade?: string,
    public cota?: 'P' | 'R' | 'PRINCIPAL' | 'RESERVADO',
    public produto_unidade?: ProdutoUnidade,
    public compra?: Compra,
    public editavel?: boolean,
    public alterar_total?: boolean,
    public valorTotal?: number,
    public valor_referencia?: number
  ) {
    super();
  }
  static converteJson(json: any): CompraItem {
    return Object.assign(new CompraItem(), json);
  }
}
