// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Exercicio } from '../comum/exercicio.model';
import { BaixaItemMerenda } from './baixa-item-merenda.model';
import { Compra } from '../compra/compra.model';
import { ReciboMerenda } from './recibo-merenda.model';
import { Pessoa } from '../comum/pessoa.model';
import { SetorAlmoxarifado } from '../almoxarifado/setor-almoxarifado.model';


export class BaixaMerenda extends BaseResourceModel {
  constructor(
    public exercicio?: Exercicio,
    public data_entrega?: Date,
    public orgao?: Orgao,
    public recibo?: ReciboMerenda,
    public setorAlmoxarifado?: SetorAlmoxarifado,
    public recebedor?: Pessoa,
    public compra?: Compra,
    public itens?: BaixaItemMerenda[]) {
    super();
  }
  static converteJson(json: any): BaixaMerenda {
    return Object.assign(new BaixaMerenda(), json);
  }
}
