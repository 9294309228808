import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, HoleriteEvento, Page } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HoleriteEventoService extends BaseResourceService<HoleriteEvento> {

  constructor(
    protected injector: Injector
  ) {
    super(`holerite-eventos`, injector);
  }

  public obterPorMovimento(holeriteId: number): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?relations=evento&orderBy=sequencia&holerite_id=${holeriteId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorHolerite(id: number): Observable<Page> {
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/filtrar?relations=evento&holerite_id=${id}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => { return this.handleError(err) })
    );
  }

}
