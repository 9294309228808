import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BaseResourceListComponent, Coluna, DespesaPrevista, Exercicio, ExercicioService, FichaDespesa, Filtro, FormatoExportacao, LoginPublico
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FichaDespesaService } from 'contabil-lib';
import { DespesaPrevistaService } from '../service/despesa-prevista.service';

@Component({
  selector: 'lib-despesa-prevista-lst',
  templateUrl: './despesa-prevista-lst.component.html'
})
export class DespesaPrevistaLstComponent extends BaseResourceListComponent<DespesaPrevista, LoginPublico> {

  /**
   * Declaração de variáveis
   */
  public tipo: string;
  public programacaoId: number;
  public listaPlanilha: Array<any>;


  public listaPrevisao: Array<DespesaPrevista>;
  public ficha: FichaDespesa;
  public exercicio: Exercicio;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private route: ActivatedRoute,
    protected injector: Injector,
    private fichaService: FichaDespesaService,
    private exercicioService: ExercicioService,
    private previstoService: DespesaPrevistaService) {
    super(previstoService, injector);
  }

  public preencherGrid() {
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.exercicioService.obterPorAno(params['ano'], this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (res) => {
              this.exercicio = res.content[0];
              this.fichaService.planilhaPrevisao(this.paginaCorrente,
                this.login.limite, this.exercicio.id, this.login.orgao.id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((lista) => {
                  this.listaPlanilha = lista.content;
                  this.paginaTotal = lista.totalPages;
                });
            }
          );
      });
  }

  arrecadado(item: any): string {
    return (+item.previsto_jan + +item.previsto_fev + +item.previsto_mar + +item.previsto_abr + +item.previsto_mai +
      +item.previsto_jun + +item.previsto_jul + +item.previsto_ago + +item.previsto_set + +item.previsto_out +
      +item.previsto_nov + +item.previsto_dez).toFixed(2);
  }

  escapePrevistoArrecadado(item: any) {
    return `<div style='font-size:10px;'>
      <p style='margin: 0 !important'>PREVISTO: ${item.fh_valor_orcado.replace('.', ',')}</p>
      <p style='margin: 0 !important'>ARRECADADO: ${this.arrecadado(item).replace('.', ',')}</p>
      <p style='margin: 0 !important'>DIFERENÇA: ${(+this.arrecadado(item) - item.fh_valor_orcado).toFixed(2).replace('.', ',')}</p>
    </div>`
  }

  protected relations(): string {
    return 'despesa';
  }

  protected condicoesGrid(): {} {
    return { ['exercicio.id']: this.login.exercicio.id };
  }

  protected ordenacaoGrid(): string[] {
    return ['fh_numero$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return;
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: DespesaPrevista): Observable<DespesaPrevista> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'r_codigo' },
      { titulo: 'Descrição', coluna: 'r_nome' },
      { titulo: 'Janeiro', coluna: 'previsto_jan', decimais: 2, alignment: 'right' },
      { titulo: 'Fevereiro', coluna: 'previsto_fev', decimais: 2, alignment: 'right' },
      { titulo: 'Março', coluna: 'previsto_mar', decimais: 2, alignment: 'right' },
      { titulo: 'Abril', coluna: 'previsto_abr', decimais: 2, alignment: 'right' },
      { titulo: 'Maio', coluna: 'previsto_mai', decimais: 2, alignment: 'right' },
      { titulo: 'Junho', coluna: 'previsto_jun', decimais: 2, alignment: 'right' },
      { titulo: 'Julho', coluna: 'previsto_jul', decimais: 2, alignment: 'right' },
      { titulo: 'Agosto', coluna: 'previsto_ago', decimais: 2, alignment: 'right' },
      { titulo: 'Setembro', coluna: 'previsto_set', decimais: 2, alignment: 'right' },
      { titulo: 'Outubro', coluna: 'previsto_out', decimais: 2, alignment: 'right' },
      { titulo: 'Novembro', coluna: 'previsto_nov', decimais: 2, alignment: 'right' },
      { titulo: 'Dezembro', coluna: 'previsto_dez', decimais: 2, alignment: 'right' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    this.fichaService
      .planilhaPrevisao(1, 9999,
        this.login.exercicio.id, this.login.orgao.id
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('PREVISÃO INICIAL DE DESPESAS ORÇAMENTÁRIAS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem previsao despesa', ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto',
              'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        });
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
