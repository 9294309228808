import { Injectable } from '@angular/core';
import {
  Exercicio,
  ExercicioService,
  FormatoExportacao,
  FuncaoService, GlobalService, OrgaoAssinatura, OrgaoAssinaturaService, OrgaoService,
  ProgressoService,
  Relatorio
} from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FichaDespesaService } from 'contabil-lib';
import { tsXLXS } from 'ts-xlsx-export';
import * as toastr from 'toastr';

@Injectable({
  providedIn: 'root'
})
export class BalanceteDespesaSubelementoService {

  protected unsubscribe: Subject<void> = new Subject();

  private assinatura: OrgaoAssinatura

  constructor(
    protected fichaService: FichaDespesaService,
    protected exercicioService: ExercicioService,
    protected orgaoService: OrgaoService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected progressoService: ProgressoService,
    protected assinaturaService: OrgaoAssinaturaService
  ) { }

  async montarBalanceteDespesa(formato: FormatoExportacao, mes: number, exercicioId: number, orgaos: number[], login: any, dtInicio: string, dtFim: string, despesaId: number, opcaoUnidade: number, tribunal: string, aplicacaoInicial: string, aplicacaoFinal: string) {
    const parametros: {} = {};
    if (mes) {
      parametros['mes'] = mes;
    } else {
      parametros['dtInicio'] = dtInicio;
      parametros['dtFim'] = dtFim;
    }
    if (exercicioId) {
      parametros['ano'] = exercicioId;
    }
    if (aplicacaoInicial) {
      parametros['aplicacaoInicial'] = aplicacaoInicial;
    }
    if (aplicacaoFinal) {
      parametros['aplicacaoFinal'] = aplicacaoFinal;
    }
    if (despesaId) {
      parametros['despesaId'] = despesaId;
    }
    parametros['tribunal'] = tribunal;
    parametros['opcao'] = opcaoUnidade;
    parametros['orgaos'] = orgaos.join();
    const ex = await this.exercicioService.obterId(exercicioId).toPromise();
    this.fichaService.balanceteSubelemento(parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async idTransacao => {
        this.progressoService.show(idTransacao, async (dados) => {
            if (!dados.length) {
              return toastr.error('Nenhum dado encontrado');
            }
            let subtitulo = '';
            if (opcaoUnidade == 6) {
              subtitulo = 'CÓDIGO DE APLICAÇÃO: ';
              if (aplicacaoInicial) subtitulo += `${aplicacaoInicial}`;
              if (aplicacaoFinal) subtitulo += `${aplicacaoInicial ? ' á ' : ''}${aplicacaoFinal}`;
              subtitulo += ' - ';
            }
            subtitulo += 'REFERÊNCIA: ';

            if (mes) {
              subtitulo += this.globalService.obterDataBR().monthNames[mes - 1].toLocaleUpperCase() + '/' + ex.ano
            } else {
              let dt = dtInicio.split("-");
              subtitulo += dt[2] + '/' + dt[1] + '/' + dt[0] + ' à ';
              dt = dtFim.split("-");
              subtitulo += dt[2] + '/' + dt[1] + '/' + dt[0];
            }

            if (formato === 'pdf') {
              Relatorio.imprimirPersonalizado('DEMONSTRAÇÃO DA DESPESA EMPENHADA, PROCESSADA E PAGA POR SUBELEMENTO DA DESPESA',
                login.usuario.nome, login.usuario.sobrenome, login.orgao.nome, login.brasao,
                this.balanceteDespesaConteudo(dados, opcaoUnidade)
                  .concat(await this.conteudoAssinatura(login, ex, mes, dtFim)),
                'landscape', 'Balancete Analítico da Despesa subelemento',
                {
                  linhas: {
                    hLineWidth(i, node) {
                      return 1;
                    },
                    vLineWidth(i) {
                      return 1;
                    },
                    hLineColor(i) {
                      return 'black';
                    },
                    paddingLeft(i) {
                      return 3;
                    },
                    paddingRight(i, node) {
                      return 3;
                    }
                  }
                }, null, null, null, subtitulo);
            } else if (formato === 'xlsx') {
              const funcao = {}
              const listaItens = new Array();
              let totalEmpenhadoMes = 0.00;
              let totalEmpenhado = 0.00;
              let totalLiquidadoMes = 0.00;
              let totalLiquidado = 0.00;
              let totalSaldoLiquidado = 0.00;
              let totalPago = 0.00;
              let totalPagoMes = 0.00;
              let totalDivida = 0.00;
              let unidadeCodigoIndex = '';
              let unidadeNomeIndex = '';
              let mascara = '##.##.##';
              const camposAgrupadores: string[] = [];
              const totalizadores: string[] = ['total_empenhado', 'empenhado_mes', 'empenhado', 'liquidado_mes', 'liquidado', 'pago_mes', 'pago'];

              if (opcaoUnidade == 1 || opcaoUnidade == 2) {
                unidadeCodigoIndex = 'u_codigo';
                unidadeNomeIndex = 'u_nome';
                camposAgrupadores.push(unidadeCodigoIndex, unidadeNomeIndex);
              } else if (opcaoUnidade == 3 || opcaoUnidade == 4) {
                unidadeCodigoIndex = 'ex_codigo';
                unidadeNomeIndex = 'ex_nome';
                camposAgrupadores.push(unidadeCodigoIndex, unidadeNomeIndex);
              } else if (opcaoUnidade == 6) {
                unidadeCodigoIndex = 'av_codigo';
                unidadeNomeIndex = 'av_nome';
                mascara = '##.###.####';
              } else {
                unidadeCodigoIndex = 'df_codigo';
                unidadeNomeIndex = 'df_nome';
              }

              if (opcaoUnidade == 1 || opcaoUnidade == 3 || opcaoUnidade == 6) {
                const grupoUnidades = this.funcaoService.agrupar(dados, camposAgrupadores, totalizadores);
                for (const unidade of grupoUnidades) {
                  funcao['codigo'] = unidade.grupo[unidadeCodigoIndex];
                  funcao['nome'] = unidade.grupo[unidadeNomeIndex];
                  funcao['empenhadoMes'] = 0.00;
                  funcao['empenhado'] = 0.00;
                  funcao['liquidado'] = 0.00;
                  funcao['liquidadoMes'] = 0.00;
                  funcao['saldoLiquidado'] = 0.00;
                  funcao['pagoMes'] = 0.00;
                  funcao['pago'] = 0.00;
                  funcao['divida'] = 0.00;

                  listaItens.push({
                    'CÓDIGO': this.funcaoService.mascarar(mascara, funcao['codigo']),
                    'DESCRIÇÃO': funcao['nome'],
                    'EMPENHADA DO MÊS': this.funcaoService.convertToBrNumber(unidade.totalizadores['empenhado_mes']),
                    'EMPENHADA ACUMULADO': this.funcaoService.convertToBrNumber(unidade.totalizadores['empenhado']),
                    'PROCESSADA DO MÊS': this.funcaoService.convertToBrNumber(unidade.totalizadores['liquidado_mes']),
                    'PROCESSADA ACUMULADO': this.funcaoService.convertToBrNumber(unidade.totalizadores['liquidado']),
                    'SALDO': this.funcaoService.convertToBrNumber(+unidade.totalizadores['empenhado'] - +unidade.totalizadores['liquidado']),
                    'PAGAMENTO DO MÊS': this.funcaoService.convertToBrNumber(unidade.totalizadores['pago_mes']),
                    'PAGAMENTO ACUMULADO': this.funcaoService.convertToBrNumber(unidade.totalizadores['pago']),
                    'DÍVIDA': this.funcaoService.convertToBrNumber(+unidade.totalizadores['liquidado'] - +unidade.totalizadores['pago']),
                  });

                  const subelementos = this.funcaoService.agrupar(unidade.registros, ['sub_codigo']);
                  for (const subelemento of subelementos) {
                    let uniEmpenhadoMes = 0.00;
                    let uniEmpenhado = 0.00;
                    let uniLiquidadoMes = 0.00;
                    let uniLiquidado = 0.00;
                    let uniSaldo = 0.00;
                    let uniPagoMes = 0.00;
                    let uniPago = 0.00;
                    let uniDivida = 0.00;

                    const agrupamentoDespFicha = this.funcaoService.agrupar(subelemento.registros, ['df_codigo', 'df_nome'], totalizadores)
                    for (const grupoDespFicha of agrupamentoDespFicha) {
                      if (opcaoUnidade != 6) {
                        listaItens.push({
                          'CÓDIGO': grupoDespFicha.grupo['df_codigo'],
                          'DESCRIÇÃO': grupoDespFicha.grupo['df_nome'],
                          'EMPENHADA DO MÊS': this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['empenhado_mes']),
                          'EMPENHADA ACUMULADO': this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['empenhado']),
                          'PROCESSADA DO MÊS': this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['liquidado_mes']),
                          'PROCESSADA ACUMULADO': this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['liquidado']),
                          'SALDO': this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['empenhado'] - grupoDespFicha.totalizadores['liquidado']),
                          'PAGAMENTO DO MÊS': this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['pago_mes']),
                          'PAGAMENTO ACUMULADO': this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['pago']),
                          'DÍVIDA': this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['liquidado'] - grupoDespFicha.totalizadores['pago']),
                        });
                      }

                      const subElemGroup = this.funcaoService.agrupar(grupoDespFicha.registros, ['de_codigo', 'de_nome'], totalizadores)
                      for (const registro of subElemGroup) {
                        const empenhadoMes: number = registro.totalizadores['empenhado_mes'];
                        const empenhado: number = registro.totalizadores['empenhado'];
                        const liquidadoMes: number = registro.totalizadores['liquidado_mes'];
                        const liquidado: number = registro.totalizadores['liquidado'];
                        const saldoLiquidado = +empenhado - +liquidado;
                        const pagoMes: number = registro.totalizadores['pago_mes'];
                        const pago: number = registro.totalizadores['pago'];
                        const divida = +liquidado - +pago;
                        funcao['empenhadoMes'] += +empenhadoMes;
                        funcao['empenhado'] += +empenhado;
                        funcao['liquidadoMes'] += +liquidadoMes;
                        funcao['liquidado'] += +liquidado;
                        funcao['saldoLiquidado'] += +saldoLiquidado;
                        funcao['pagoMes'] += +pagoMes;
                        funcao['pago'] += +pago;
                        funcao['divida'] += +divida;
                        totalEmpenhadoMes += +empenhadoMes;
                        totalEmpenhado += +empenhado;
                        totalDivida += +divida;
                        totalLiquidadoMes += +liquidadoMes;
                        totalLiquidado += +liquidado;
                        totalSaldoLiquidado += +saldoLiquidado;
                        totalPagoMes += +pagoMes;
                        totalPago += +pago;

                        listaItens.push({
                          'CÓDIGO': registro.grupo['de_codigo'],
                          'DESCRIÇÃO': registro.grupo['de_nome'],
                          'EMPENHADA DO MÊS': this.funcaoService.convertToBrNumber(registro.totalizadores['empenhado_mes']),
                          'EMPENHADA ACUMULADO': this.funcaoService.convertToBrNumber(registro.totalizadores['empenhado']),
                          'PROCESSADA DO MÊS': this.funcaoService.convertToBrNumber(registro.totalizadores['liquidado_mes']),
                          'PROCESSADA ACUMULADO': this.funcaoService.convertToBrNumber(registro.totalizadores['liquidado']),
                          'SALDO': this.funcaoService.convertToBrNumber(registro.totalizadores['empenhado'] - registro.totalizadores['liquidado']),
                          'PAGAMENTO DO MÊS': this.funcaoService.convertToBrNumber(registro.totalizadores['pago_mes']),
                          'PAGAMENTO ACUMULADO': this.funcaoService.convertToBrNumber(registro.totalizadores['pago']),
                          'DÍVIDA': this.funcaoService.convertToBrNumber(registro.totalizadores['liquidado'] - registro.totalizadores['pago']),
                        });
                      }
                    }

                    subelemento.registros.forEach(item => {
                      uniEmpenhadoMes += +item.empenhado_mes;
                      uniEmpenhado += +item.empenhado;
                      uniLiquidadoMes += +item.liquidado_mes;
                      uniLiquidado += +item.liquidado;
                      uniSaldo += +item.empenhado - +item.liquidado;
                      uniPagoMes += +item.pago_mes;
                      uniPago += +item.pago;
                      uniDivida += +item.liquidado - +item.pago;
                    });
                    listaItens.push({
                      'CÓDIGO': 'TOTAL DO ELEMENTO',
                      'DESCRIÇÃO': '',
                      'EMPENHADA DO MÊS': this.funcaoService.convertToBrNumber(uniEmpenhadoMes),
                      'EMPENHADA ACUMULADO': this.funcaoService.convertToBrNumber(uniEmpenhado),
                      'PROCESSADA DO MÊS': this.funcaoService.convertToBrNumber(uniLiquidadoMes),
                      'PROCESSADA ACUMULADO': this.funcaoService.convertToBrNumber(uniLiquidado),
                      'SALDO': this.funcaoService.convertToBrNumber(uniSaldo),
                      'PAGAMENTO DO MÊS': this.funcaoService.convertToBrNumber(uniPagoMes),
                      'PAGAMENTO ACUMULADO': this.funcaoService.convertToBrNumber(uniPago),
                      'DÍVIDA': this.funcaoService.convertToBrNumber(uniDivida),
                    });
                  }
                }
              } else {
                funcao['codigo'] = dados[0][unidadeCodigoIndex];
                funcao['nome'] = dados[0][unidadeNomeIndex];
                funcao['empenhadoMes'] = 0.00;
                funcao['empenhado'] = 0.00;
                funcao['liquidado'] = 0.00;
                funcao['liquidadoMes'] = 0.00;
                funcao['saldoLiquidado'] = 0.00;
                funcao['pagoMes'] = 0.00;
                funcao['pago'] = 0.00;
                funcao['divida'] = 0.00;
                listaItens.push({
                  'CÓDIGO': this.funcaoService.mascarar('##.##.##', funcao['codigo']),
                  'DESCRIÇÃO': funcao['nome'],
                  'EMPENHADA DO MÊS': '',
                  'EMPENHADA ACUMULADO': '',
                  'PROCESSADA DO MÊS': '',
                  'PROCESSADA ACUMULADO': '',
                  'SALDO': '',
                  'PAGAMENTO DO MÊS': '',
                  'PAGAMENTO ACUMULADO': '',
                  'DÍVIDA': '',
                });

                const subelementos = this.funcaoService.agrupar(dados, ['sub_codigo']);
                for (const subelemento of subelementos) {
                  let uniEmpenhadoMes = 0.00;
                  let uniEmpenhado = 0.00;
                  let uniLiquidadoMes = 0.00;
                  let uniLiquidado = 0.00;
                  let uniSaldo = 0.00;
                  let uniPagoMes = 0.00;
                  let uniPago = 0.00;
                  let uniDivida = 0.00;

                  const agrupamentoDespFicha = this.funcaoService.agrupar(subelemento.registros, ['df_codigo', 'df_nome'], totalizadores)
                  for (const grupoDespFicha of agrupamentoDespFicha) {
                    if (opcaoUnidade != 5) {
                      listaItens.push({
                        'CÓDIGO': grupoDespFicha.grupo['df_codigo'],
                        'DESCRIÇÃO': grupoDespFicha.grupo['df_nome'],
                        'EMPENHADA DO MÊS': this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['empenhado_mes']),
                        'EMPENHADA ACUMULADO': this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['empenhado']),
                        'PROCESSADA DO MÊS': this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['liquidado_mes']),
                        'PROCESSADA ACUMULADO': this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['liquidado']),
                        'SALDO': this.funcaoService.convertToBrNumber(+grupoDespFicha.totalizadores['empenhado'] - +grupoDespFicha.totalizadores['liquidado']),
                        'PAGAMENTO DO MÊS': this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['pago_mes']),
                        'PAGAMENTO ACUMULADO': this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['pago']),
                        'DÍVIDA': this.funcaoService.convertToBrNumber(+grupoDespFicha.totalizadores['liquidado'] - +grupoDespFicha.totalizadores['pago']),
                      });
                    }

                    const subElemGroup = this.funcaoService.agrupar(grupoDespFicha.registros, ['de_codigo', 'de_nome'], totalizadores)
                    for (const registro of subElemGroup) {
                      const empenhadoMes: number = registro.totalizadores['empenhado_mes'];
                      const empenhado: number = registro.totalizadores['empenhado'];
                      const liquidadoMes: number = registro.totalizadores['liquidado_mes'];
                      const liquidado: number = registro.totalizadores['liquidado'];
                      const saldoLiquidado = +empenhado - +liquidado;
                      const pagoMes: number = registro.totalizadores['pago_mes'];
                      const pago: number = registro.totalizadores['pago'];
                      const divida = +liquidado - +pago;
                      funcao['empenhadoMes'] += +empenhadoMes;
                      funcao['empenhado'] += +empenhado;
                      funcao['liquidadoMes'] += +liquidadoMes;
                      funcao['liquidado'] += +liquidado;
                      funcao['saldoLiquidado'] += +saldoLiquidado;
                      funcao['pagoMes'] += +pagoMes;
                      funcao['pago'] += +pago;
                      funcao['divida'] += +divida;
                      totalEmpenhadoMes += +empenhadoMes;
                      totalEmpenhado += +empenhado;
                      totalDivida += +divida;
                      totalLiquidadoMes += +liquidadoMes;
                      totalLiquidado += +liquidado;
                      totalSaldoLiquidado += +saldoLiquidado;
                      totalPagoMes += +pagoMes;
                      totalPago += +pago;

                      listaItens.push({
                        'CÓDIGO': registro.grupo['de_codigo'],
                        'DESCRIÇÃO': registro.grupo['de_nome'],
                        'EMPENHADA DO MÊS': this.funcaoService.convertToBrNumber(registro.totalizadores['empenhado_mes']),
                        'EMPENHADA ACUMULADO': this.funcaoService.convertToBrNumber(registro.totalizadores['empenhado']),
                        'PROCESSADA DO MÊS': this.funcaoService.convertToBrNumber(registro.totalizadores['liquidado_mes']),
                        'PROCESSADA ACUMULADO': this.funcaoService.convertToBrNumber(registro.totalizadores['liquidado']),
                        'SALDO': this.funcaoService.convertToBrNumber(+registro.totalizadores['empenhado'] - +registro.totalizadores['liquidado']),
                        'PAGAMENTO DO MÊS': this.funcaoService.convertToBrNumber(registro.totalizadores['pago_mes']),
                        'PAGAMENTO ACUMULADO': this.funcaoService.convertToBrNumber(registro.totalizadores['pago']),
                        'DÍVIDA': this.funcaoService.convertToBrNumber(+registro.totalizadores['liquidado'] - +registro.totalizadores['pago']),
                      });
                    }
                  }

                  subelemento.registros.forEach(item => {
                    uniEmpenhadoMes += +item.empenhado_mes;
                    uniEmpenhado += +item.empenhado;
                    uniLiquidadoMes += +item.liquidado_mes;
                    uniLiquidado += +item.liquidado;
                    uniSaldo += +item.empenhado - +item.liquidado;
                    uniPagoMes += +item.pago_mes;
                    uniPago += +item.pago;
                    uniDivida += +item.liquidado - +item.pago;
                  });
                  listaItens.push({
                    'CÓDIGO': 'TOTAL DO ELEMENTO',
                    'DESCRIÇÃO': '',
                    'EMPENHADA DO MÊS': this.funcaoService.convertToBrNumber(uniEmpenhadoMes),
                    'EMPENHADA ACUMULADO': this.funcaoService.convertToBrNumber(uniEmpenhado),
                    'PROCESSADA DO MÊS': this.funcaoService.convertToBrNumber(uniLiquidadoMes),
                    'PROCESSADA ACUMULADO': this.funcaoService.convertToBrNumber(uniLiquidado),
                    'SALDO': this.funcaoService.convertToBrNumber(uniSaldo),
                    'PAGAMENTO DO MÊS': this.funcaoService.convertToBrNumber(uniPagoMes),
                    'PAGAMENTO ACUMULADO': this.funcaoService.convertToBrNumber(uniPago),
                    'DÍVIDA': this.funcaoService.convertToBrNumber(uniDivida),
                  });
                }
              }
              const entityTotal = {
                'CÓDIGO': 'TOTAL GERAL:',
                'DESCRIÇÃO': '',
                'EMPENHADA DO MÊS': this.funcaoService.convertToBrNumber(totalEmpenhadoMes),
                'EMPENHADA ACUMULADO': this.funcaoService.convertToBrNumber(totalEmpenhado),
                'PROCESSADA DO MÊS': this.funcaoService.convertToBrNumber(totalLiquidadoMes),
                'PROCESSADA ACUMULADO': this.funcaoService.convertToBrNumber(totalLiquidado),
                'SALDO': this.funcaoService.convertToBrNumber(totalSaldoLiquidado),
                'PAGAMENTO DO MÊS': this.funcaoService.convertToBrNumber(totalPagoMes),
                'PAGAMENTO ACUMULADO': this.funcaoService.convertToBrNumber(totalPago),
                'DÍVIDA': this.funcaoService.convertToBrNumber(totalDivida),
              };
              listaItens.push(entityTotal);
              tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile('Balancete Analítico da Despesa subelemento');
            } else if (formato === 'csv') {
              this.balanceteDespesaCSV(dados, opcaoUnidade);
            }
        });
      });
  }

  balanceteDespesaConteudo(dados: any[], opcaoUnidade: number): {}[] {
    const registros: {}[] = [
      // [{
      //   text: 'DEMONSTRAÇÃO DA DESPESA EMPENHADA, PROCESSADA E PAGA POR SUBELEMENTO DA DESPESA',
      //   colSpan: 10, alignment: 'center', bold: true, fontSize: 11
      // }, '', '', '', '', '', '', '', '', ''],
      [
        { text: 'CÓDIGO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'DESCRIÇÃO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'EMPENHADA', alignment: 'center', colSpan: 2, bold: true, fontSize: 7 },
        { text: '' },
        { text: 'PROCESSADA', alignment: 'center', colSpan: 2, bold: true, fontSize: 7 },
        { text: '' },
        { text: 'SALDO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'PAGAMENTO', alignment: 'center', colSpan: 2, bold: true, fontSize: 7 },
        { text: '' },
        { text: 'DÍVIDA', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 }
      ],
      [
        { text: '' },
        { text: '' },
        { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 7 }, '',
        { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 7 }
      ]
    ];

    const funcao = {};
    let totalEmpenhadoMes = 0.00;
    let totalEmpenhado = 0.00;
    let totalLiquidadoMes = 0.00;
    let totalLiquidado = 0.00;
    let totalSaldoLiquidado = 0.00;
    let totalPago = 0.00;
    let totalPagoMes = 0.00;
    let totalDivida = 0.00;
    let unidadeCodigoIndex = '';
    let unidadeNomeIndex = '';
    let mascara = '##.##.##';
    const camposAgrupadores: string[] = [];
    const totalizadores: string[] = ['total_empenhado', 'empenhado_mes', 'empenhado', 'liquidado_mes', 'liquidado', 'pago_mes', 'pago'];

    if (opcaoUnidade == 1 || opcaoUnidade == 2) {
      unidadeCodigoIndex = 'u_codigo';
      unidadeNomeIndex = 'u_nome';
      camposAgrupadores.push(unidadeCodigoIndex, unidadeNomeIndex);
    } else if (opcaoUnidade == 3 || opcaoUnidade == 4) {
      unidadeCodigoIndex = 'ex_codigo';
      unidadeNomeIndex = 'ex_nome';
      camposAgrupadores.push(unidadeCodigoIndex, unidadeNomeIndex);
    } else if (opcaoUnidade == 6) {
      unidadeCodigoIndex = 'av_codigo';
      unidadeNomeIndex = 'av_nome';
      camposAgrupadores.push(unidadeCodigoIndex, unidadeNomeIndex);
      mascara = '##.###.####';
    } else {
      unidadeCodigoIndex = 'df_codigo';
      unidadeNomeIndex = 'df_nome';
    }

    if (opcaoUnidade == 1 || opcaoUnidade == 3 || opcaoUnidade == 6) {
      const grupoUnidades = this.funcaoService.agrupar(dados, camposAgrupadores, totalizadores);
      for (const unidade of grupoUnidades) {
        funcao['codigo'] = unidade.grupo[unidadeCodigoIndex];
        funcao['nome'] = unidade.grupo[unidadeNomeIndex];
        funcao['empenhadoMes'] = 0.00;
        funcao['empenhado'] = 0.00;
        funcao['liquidado'] = 0.00;
        funcao['liquidadoMes'] = 0.00;
        funcao['saldoLiquidado'] = 0.00;
        funcao['pagoMes'] = 0.00;
        funcao['pago'] = 0.00;
        funcao['divida'] = 0.00;
        registros.push([
          {
            text: this.funcaoService.mascarar(mascara, funcao['codigo']) + ' ' + funcao['nome'], colSpan: 2,
            bold: true, fontSize: 7
          }, '',
          { text: this.funcaoService.convertToBrNumber(unidade.totalizadores['empenhado_mes']), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(unidade.totalizadores['empenhado']), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(unidade.totalizadores['liquidado_mes']), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(unidade.totalizadores['liquidado']), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(+unidade.totalizadores['empenhado'] - +unidade.totalizadores['liquidado']), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(unidade.totalizadores['pago_mes']), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(unidade.totalizadores['pago']), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(+unidade.totalizadores['liquidado'] - +unidade.totalizadores['pago']), bold: true, alignment: 'right', fontSize: 7 },
        ]);

        const subelementos = this.funcaoService.agrupar(unidade.registros, ['sub_codigo']);
        for (const subelemento of subelementos) {
          let uniEmpenhadoMes = 0.00;
          let uniEmpenhado = 0.00;
          let uniLiquidadoMes = 0.00;
          let uniLiquidado = 0.00;
          let uniSaldo = 0.00;
          let uniPagoMes = 0.00;
          let uniPago = 0.00;
          let uniDivida = 0.00;

          const agrupamentoDespFicha = this.funcaoService.agrupar(subelemento.registros, ['df_codigo', 'df_nome'], totalizadores)
          for (const grupoDespFicha of agrupamentoDespFicha) {
            registros.push([
              { text: grupoDespFicha.grupo['df_codigo'], alignment: 'center', border: [true, false, true, false], fontSize: 7, bold: true },
              {
                text: grupoDespFicha.grupo['df_nome'],
                border: [true, false, true, false], fontSize: 7, bold: true
              },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['empenhado_mes']), alignment: 'right', border: [true, false, true, false], fontSize: 7, bold: true },
              {
                text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['empenhado']), alignment: 'right',
                border: [true, false, true, false], fontSize: 7, bold: true
              },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['liquidado_mes']), alignment: 'right', border: [true, false, true, false], fontSize: 7, bold: true },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['liquidado']), alignment: 'right', border: [true, false, true, false], fontSize: 7, bold: true },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['empenhado'] - grupoDespFicha.totalizadores['liquidado']), alignment: 'right', border: [true, false, true, false], fontSize: 7, bold: true },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['pago_mes']), alignment: 'right', border: [true, false, true, false], fontSize: 7, bold: true },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['pago']), alignment: 'right', border: [true, false, true, false], fontSize: 7, bold: true },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['liquidado'] - grupoDespFicha.totalizadores['pago']), alignment: 'right', border: [true, false, true, false], fontSize: 7, bold: true },
            ]);

            const subElemGroup = this.funcaoService.agrupar(grupoDespFicha.registros, ['de_codigo', 'de_nome'], totalizadores)
            for (const registro of subElemGroup) {
              const empenhadoMes: number = registro.totalizadores['empenhado_mes'];
              const empenhado: number = registro.totalizadores['empenhado'];
              const liquidadoMes: number = registro.totalizadores['liquidado_mes'];
              const liquidado: number = registro.totalizadores['liquidado'];
              const saldoLiquidado = +empenhado - +liquidado;
              const pagoMes: number = registro.totalizadores['pago_mes'];
              const pago: number = registro.totalizadores['pago'];
              const divida = +liquidado - +pago;
              funcao['empenhadoMes'] += +empenhadoMes;
              funcao['empenhado'] += +empenhado;
              funcao['liquidadoMes'] += +liquidadoMes;
              funcao['liquidado'] += +liquidado;
              funcao['saldoLiquidado'] += +saldoLiquidado;
              funcao['pagoMes'] += +pagoMes;
              funcao['pago'] += +pago;
              funcao['divida'] += +divida;
              totalEmpenhadoMes += +empenhadoMes;
              totalEmpenhado += +empenhado;
              totalDivida += +divida;
              totalLiquidadoMes += +liquidadoMes;
              totalLiquidado += +liquidado;
              totalSaldoLiquidado += +saldoLiquidado;
              totalPagoMes += +pagoMes;
              totalPago += +pago;

              registros.push([
                { text: registro.grupo['de_codigo'], alignment: 'center', border: [true, false, true, false], fontSize: 7 },
                {
                  text: registro.grupo['de_nome'],
                  border: [true, false, true, false], fontSize: 7
                },
                { text: this.funcaoService.convertToBrNumber(registro.totalizadores['empenhado_mes']), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
                {
                  text: this.funcaoService.convertToBrNumber(registro.totalizadores['empenhado']), alignment: 'right',
                  border: [true, false, true, false], fontSize: 7
                },
                { text: this.funcaoService.convertToBrNumber(registro.totalizadores['liquidado_mes']), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(registro.totalizadores['liquidado']), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(registro.totalizadores['empenhado'] - registro.totalizadores['liquidado']), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(registro.totalizadores['pago_mes']), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(registro.totalizadores['pago']), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(registro.totalizadores['liquidado'] - registro.totalizadores['pago']), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
              ]);
            }
          }

          subelemento.registros.forEach(item => {
            uniEmpenhadoMes += +item.empenhado_mes;
            uniEmpenhado += +item.empenhado;
            uniLiquidadoMes += +item.liquidado_mes;
            uniLiquidado += +item.liquidado;
            uniSaldo += +item.empenhado - +item.liquidado;
            uniPagoMes += +item.pago_mes;
            uniPago += +item.pago;
            uniDivida += +item.liquidado - +item.pago;
          });
          registros.push([
            {
              text: 'TOTAL DO ELEMENTO', colSpan: 2,
              bold: true, fontSize: 7
            },
            '',
            { text: this.funcaoService.convertToBrNumber(uniEmpenhadoMes), bold: true, alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(uniEmpenhado), bold: true, alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(uniLiquidadoMes), bold: true, alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(uniLiquidado), bold: true, alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(uniSaldo), bold: true, alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(uniPagoMes), bold: true, alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(uniPago), bold: true, alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(uniDivida), bold: true, alignment: 'right', fontSize: 7 },
          ]);
        }
      }

      if (funcao['codigo']) {
        registros.push([
          {
            text: 'TOTAL GERAL:', colSpan: 2,
            bold: true, fontSize: 7
          },
          '',
          { text: this.funcaoService.convertToBrNumber(totalEmpenhadoMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalLiquidadoMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalLiquidado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalSaldoLiquidado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalPagoMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalPago), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalDivida), bold: true, alignment: 'right', fontSize: 7 },
        ]);
      }
    } else {
      funcao['codigo'] = dados[0][unidadeCodigoIndex];
      funcao['nome'] = dados[0][unidadeNomeIndex];
      funcao['empenhadoMes'] = 0.00;
      funcao['empenhado'] = 0.00;
      funcao['liquidado'] = 0.00;
      funcao['liquidadoMes'] = 0.00;
      funcao['saldoLiquidado'] = 0.00;
      funcao['pagoMes'] = 0.00;
      funcao['pago'] = 0.00;
      funcao['divida'] = 0.00;
      registros.push([
        {
          text: this.funcaoService.mascarar('##.##.##', funcao['codigo']) + ' ' + funcao['nome'], colSpan: 10,
          bold: true, fontSize: 7
        }, '', '', '', '', '', '', '', '', ''
      ]);

      const subelementos = this.funcaoService.agrupar(dados, ['sub_codigo']);
      for (const subelemento of subelementos) {
        let uniEmpenhadoMes = 0.00;
        let uniEmpenhado = 0.00;
        let uniLiquidadoMes = 0.00;
        let uniLiquidado = 0.00;
        let uniSaldo = 0.00;
        let uniPagoMes = 0.00;
        let uniPago = 0.00;
        let uniDivida = 0.00;

        const agrupamentoDespFicha = this.funcaoService.agrupar(subelemento.registros, ['df_codigo', 'df_nome'], totalizadores)
        for (const grupoDespFicha of agrupamentoDespFicha) {
          if (opcaoUnidade != 5) {
            registros.push([
              { text: grupoDespFicha.grupo['df_codigo'], alignment: 'center', border: [true, false, true, false], fontSize: 7, bold: true },
              {
                text: grupoDespFicha.grupo['df_nome'],
                border: [true, false, true, false], fontSize: 7, bold: true
              },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['empenhado_mes']), alignment: 'right', border: [true, false, true, false], fontSize: 7, bold: true },
              {
                text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['empenhado']), alignment: 'right',
                border: [true, false, true, false], fontSize: 7, bold: true
              },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['liquidado_mes']), alignment: 'right', border: [true, false, true, false], fontSize: 7, bold: true },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['liquidado']), alignment: 'right', border: [true, false, true, false], fontSize: 7, bold: true },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['empenhado'] - grupoDespFicha.totalizadores['liquidado']), alignment: 'right', border: [true, false, true, false], fontSize: 7, bold: true },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['pago_mes']), alignment: 'right', border: [true, false, true, false], fontSize: 7, bold: true },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['pago']), alignment: 'right', border: [true, false, true, false], fontSize: 7, bold: true },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['liquidado'] - grupoDespFicha.totalizadores['pago']), alignment: 'right', border: [true, false, true, false], fontSize: 7, bold: true },
            ]);
          }

          const subElemGroup = this.funcaoService.agrupar(grupoDespFicha.registros, ['de_codigo', 'de_nome'], totalizadores)
          for (const registro of subElemGroup) {
            const empenhadoMes: number = registro.totalizadores['empenhado_mes'];
            const empenhado: number = registro.totalizadores['empenhado'];
            const liquidadoMes: number = registro.totalizadores['liquidado_mes'];
            const liquidado: number = registro.totalizadores['liquidado'];
            const saldoLiquidado = +empenhado - +liquidado;
            const pagoMes: number = registro.totalizadores['pago_mes'];
            const pago: number = registro.totalizadores['pago'];
            const divida = +liquidado - +pago;
            funcao['empenhadoMes'] += +empenhadoMes;
            funcao['empenhado'] += +empenhado;
            funcao['liquidadoMes'] += +liquidadoMes;
            funcao['liquidado'] += +liquidado;
            funcao['saldoLiquidado'] += +saldoLiquidado;
            funcao['pagoMes'] += +pagoMes;
            funcao['pago'] += +pago;
            funcao['divida'] += +divida;
            totalEmpenhadoMes += +empenhadoMes;
            totalEmpenhado += +empenhado;
            totalDivida += +divida;
            totalLiquidadoMes += +liquidadoMes;
            totalLiquidado += +liquidado;
            totalSaldoLiquidado += +saldoLiquidado;
            totalPagoMes += +pagoMes;
            totalPago += +pago;

            registros.push([
              { text: registro.grupo['de_codigo'], alignment: 'center', border: [true, false, true, false], fontSize: 7 },
              {
                text: registro.grupo['de_nome'],
                border: [true, false, true, false], fontSize: 7
              },
              { text: this.funcaoService.convertToBrNumber(registro.totalizadores['empenhado_mes']), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
              {
                text: this.funcaoService.convertToBrNumber(registro.totalizadores['empenhado']), alignment: 'right',
                border: [true, false, true, false], fontSize: 7
              },
              { text: this.funcaoService.convertToBrNumber(registro.totalizadores['liquidado_mes']), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(registro.totalizadores['liquidado']), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(registro.totalizadores['empenhado'] - registro.totalizadores['liquidado']), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(registro.totalizadores['pago_mes']), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(registro.totalizadores['pago']), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(registro.totalizadores['liquidado'] - registro.totalizadores['pago']), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
            ]);
          }
        }

        subelemento.registros.forEach(item => {
          uniEmpenhadoMes += +item.empenhado_mes;
          uniEmpenhado += +item.empenhado;
          uniLiquidadoMes += +item.liquidado_mes;
          uniLiquidado += +item.liquidado;
          uniSaldo += +item.empenhado - +item.liquidado;
          uniPagoMes += +item.pago_mes;
          uniPago += +item.pago;
          uniDivida += +item.liquidado - +item.pago;
        });
        registros.push([
          {
            text: 'TOTAL DO ELEMENTO', colSpan: 2,
            bold: true, fontSize: 7
          },
          '',
          { text: this.funcaoService.convertToBrNumber(uniEmpenhadoMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(uniEmpenhado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(uniLiquidadoMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(uniLiquidado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(uniSaldo), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(uniPagoMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(uniPago), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(uniDivida), bold: true, alignment: 'right', fontSize: 7 },
        ]);
      }

      if (funcao['codigo']) {
        registros.push([
          {
            text: 'TOTAL GERAL:', colSpan: 2,
            bold: true, fontSize: 7
          },
          '',
          { text: this.funcaoService.convertToBrNumber(totalEmpenhadoMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalLiquidadoMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalLiquidado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalSaldoLiquidado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalPagoMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalPago), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalDivida), bold: true, alignment: 'right', fontSize: 7 },
        ]);
      }
    }
    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }];
  }

  balanceteDespesaCSV(dados: any[], opcaoUnidade: number) {
    let registros: {}[][] = [[
      { text: 'CÓDIGO' },
      { text: 'DESCRIÇÃO' },
      { text: 'EMPENHADA DO MÊS' },
      { text: 'EMPENHADA ACUMULADO' },
      { text: 'PROCESSADA DO MÊS' },
      { text: 'PROCESSADA ACUMULADO' },
      { text: 'SALDO' },
      { text: 'PAGAMENTO DO MÊS' },
      { text: 'PAGAMENTO ACUMULADO' },
      { text: 'DÍVIDA' },
    ]];

    const funcao = {};
    let totalEmpenhadoMes = 0.00;
    let totalEmpenhado = 0.00;
    let totalLiquidadoMes = 0.00;
    let totalLiquidado = 0.00;
    let totalSaldoLiquidado = 0.00;
    let totalPago = 0.00;
    let totalPagoMes = 0.00;
    let totalDivida = 0.00;
    let unidadeCodigoIndex = '';
    let unidadeNomeIndex = '';
    let mascara = '##.##.##';
    const camposAgrupadores: string[] = [];
    const totalizadores: string[] = ['total_empenhado', 'empenhado_mes', 'empenhado', 'liquidado_mes', 'liquidado', 'pago_mes', 'pago'];

    if (opcaoUnidade == 1 || opcaoUnidade == 2) {
      unidadeCodigoIndex = 'u_codigo';
      unidadeNomeIndex = 'u_nome';
      camposAgrupadores.push(unidadeCodigoIndex, unidadeNomeIndex);
    } else if (opcaoUnidade == 3 || opcaoUnidade == 4) {
      unidadeCodigoIndex = 'ex_codigo';
      unidadeNomeIndex = 'ex_nome';
      camposAgrupadores.push(unidadeCodigoIndex, unidadeNomeIndex);
    } else if (opcaoUnidade == 6) {
      unidadeCodigoIndex = 'av_codigo';
      unidadeNomeIndex = 'av_nome';
      camposAgrupadores.push(unidadeCodigoIndex, unidadeNomeIndex);
      mascara = '##.###.####';
    } else {
      unidadeCodigoIndex = 'df_codigo';
      unidadeNomeIndex = 'df_nome';
    }

    if (opcaoUnidade == 1 || opcaoUnidade == 3 || opcaoUnidade == 6) {
      const grupoUnidades = this.funcaoService.agrupar(dados, camposAgrupadores, totalizadores);
      for (const unidade of grupoUnidades) {
        funcao['codigo'] = unidade.grupo[unidadeCodigoIndex];
        funcao['nome'] = unidade.grupo[unidadeNomeIndex];
        funcao['empenhadoMes'] = 0.00;
        funcao['empenhado'] = 0.00;
        funcao['liquidado'] = 0.00;
        funcao['liquidadoMes'] = 0.00;
        funcao['saldoLiquidado'] = 0.00;
        funcao['pagoMes'] = 0.00;
        funcao['pago'] = 0.00;
        funcao['divida'] = 0.00;
        registros.push([
          { text: this.funcaoService.mascarar(mascara, funcao['codigo']) + ' ' + funcao['nome'] }, { text: ''},
          { text: this.funcaoService.convertToBrNumber(unidade.totalizadores['empenhado_mes']) },
          { text: this.funcaoService.convertToBrNumber(unidade.totalizadores['empenhado']) },
          { text: this.funcaoService.convertToBrNumber(unidade.totalizadores['liquidado_mes']) },
          { text: this.funcaoService.convertToBrNumber(unidade.totalizadores['liquidado']) },
          { text: this.funcaoService.convertToBrNumber(+unidade.totalizadores['empenhado'] - +unidade.totalizadores['liquidado']) },
          { text: this.funcaoService.convertToBrNumber(unidade.totalizadores['pago_mes']) },
          { text: this.funcaoService.convertToBrNumber(unidade.totalizadores['pago']) },
          { text: this.funcaoService.convertToBrNumber(+unidade.totalizadores['liquidado'] - +unidade.totalizadores['pago']) },
        ]);

        const subelementos = this.funcaoService.agrupar(unidade.registros, ['sub_codigo']);
        for (const subelemento of subelementos) {
          let uniEmpenhadoMes = 0.00;
          let uniEmpenhado = 0.00;
          let uniLiquidadoMes = 0.00;
          let uniLiquidado = 0.00;
          let uniSaldo = 0.00;
          let uniPagoMes = 0.00;
          let uniPago = 0.00;
          let uniDivida = 0.00;

          const agrupamentoDespFicha = this.funcaoService.agrupar(subelemento.registros, ['df_codigo', 'df_nome'], totalizadores)
          for (const grupoDespFicha of agrupamentoDespFicha) {
            registros.push([
              { text: grupoDespFicha.grupo['df_codigo'] },
              { text: grupoDespFicha.grupo['df_nome'] },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['empenhado_mes']) },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['empenhado']) },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['liquidado_mes']) },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['liquidado']) },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['empenhado'] - grupoDespFicha.totalizadores['liquidado']) },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['pago_mes']) },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['pago']) },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['liquidado'] - grupoDespFicha.totalizadores['pago']) },
            ]);

            const subElemGroup = this.funcaoService.agrupar(grupoDespFicha.registros, ['de_codigo', 'de_nome'], totalizadores)
            for (const registro of subElemGroup) {
              const empenhadoMes: number = registro.totalizadores['empenhado_mes'];
              const empenhado: number = registro.totalizadores['empenhado'];
              const liquidadoMes: number = registro.totalizadores['liquidado_mes'];
              const liquidado: number = registro.totalizadores['liquidado'];
              const saldoLiquidado = +empenhado - +liquidado;
              const pagoMes: number = registro.totalizadores['pago_mes'];
              const pago: number = registro.totalizadores['pago'];
              const divida = +liquidado - +pago;
              funcao['empenhadoMes'] += +empenhadoMes;
              funcao['empenhado'] += +empenhado;
              funcao['liquidadoMes'] += +liquidadoMes;
              funcao['liquidado'] += +liquidado;
              funcao['saldoLiquidado'] += +saldoLiquidado;
              funcao['pagoMes'] += +pagoMes;
              funcao['pago'] += +pago;
              funcao['divida'] += +divida;
              totalEmpenhadoMes += +empenhadoMes;
              totalEmpenhado += +empenhado;
              totalDivida += +divida;
              totalLiquidadoMes += +liquidadoMes;
              totalLiquidado += +liquidado;
              totalSaldoLiquidado += +saldoLiquidado;
              totalPagoMes += +pagoMes;
              totalPago += +pago;

              registros.push([
                { text: registro.grupo['de_codigo'] },
                { text: registro.grupo['de_nome'] },
                { text: this.funcaoService.convertToBrNumber(registro.totalizadores['empenhado_mes']) },
                { text: this.funcaoService.convertToBrNumber(registro.totalizadores['empenhado']) },
                { text: this.funcaoService.convertToBrNumber(registro.totalizadores['liquidado_mes']) },
                { text: this.funcaoService.convertToBrNumber(registro.totalizadores['liquidado']) },
                { text: this.funcaoService.convertToBrNumber(registro.totalizadores['empenhado'] - registro.totalizadores['liquidado']) },
                { text: this.funcaoService.convertToBrNumber(registro.totalizadores['pago_mes']) },
                { text: this.funcaoService.convertToBrNumber(registro.totalizadores['pago']) },
                { text: this.funcaoService.convertToBrNumber(registro.totalizadores['liquidado'] - registro.totalizadores['pago']) },
              ]);
            }
          }

          subelemento.registros.forEach(item => {
            uniEmpenhadoMes += +item.empenhado_mes;
            uniEmpenhado += +item.empenhado;
            uniLiquidadoMes += +item.liquidado_mes;
            uniLiquidado += +item.liquidado;
            uniSaldo += +item.empenhado - +item.liquidado;
            uniPagoMes += +item.pago_mes;
            uniPago += +item.pago;
            uniDivida += +item.liquidado - +item.pago;
          });
          registros.push([
            { text: 'TOTAL DO ELEMENTO' }, { text: '' },
            { text: this.funcaoService.convertToBrNumber(uniEmpenhadoMes) },
            { text: this.funcaoService.convertToBrNumber(uniEmpenhado) },
            { text: this.funcaoService.convertToBrNumber(uniLiquidadoMes) },
            { text: this.funcaoService.convertToBrNumber(uniLiquidado) },
            { text: this.funcaoService.convertToBrNumber(uniSaldo) },
            { text: this.funcaoService.convertToBrNumber(uniPagoMes) },
            { text: this.funcaoService.convertToBrNumber(uniPago) },
            { text: this.funcaoService.convertToBrNumber(uniDivida) },
          ]);
        }
      }

      if (funcao['codigo']) {
        registros.push([
          { text: 'TOTAL GERAL:' }, { text: '' },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhadoMes) },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhado) },
          { text: this.funcaoService.convertToBrNumber(totalLiquidadoMes) },
          { text: this.funcaoService.convertToBrNumber(totalLiquidado) },
          { text: this.funcaoService.convertToBrNumber(totalSaldoLiquidado) },
          { text: this.funcaoService.convertToBrNumber(totalPagoMes) },
          { text: this.funcaoService.convertToBrNumber(totalPago) },
          { text: this.funcaoService.convertToBrNumber(totalDivida) },
        ]);
      }
    } else {
      funcao['codigo'] = dados[0][unidadeCodigoIndex];
      funcao['nome'] = dados[0][unidadeNomeIndex];
      funcao['empenhadoMes'] = 0.00;
      funcao['empenhado'] = 0.00;
      funcao['liquidado'] = 0.00;
      funcao['liquidadoMes'] = 0.00;
      funcao['saldoLiquidado'] = 0.00;
      funcao['pagoMes'] = 0.00;
      funcao['pago'] = 0.00;
      funcao['divida'] = 0.00;
      registros.push([{ text: this.funcaoService.mascarar('##.##.##', funcao['codigo']) + ' ' + funcao['nome'] }]);

      const subelementos = this.funcaoService.agrupar(dados, ['sub_codigo']);
      for (const subelemento of subelementos) {
        let uniEmpenhadoMes = 0.00;
        let uniEmpenhado = 0.00;
        let uniLiquidadoMes = 0.00;
        let uniLiquidado = 0.00;
        let uniSaldo = 0.00;
        let uniPagoMes = 0.00;
        let uniPago = 0.00;
        let uniDivida = 0.00;

        const agrupamentoDespFicha = this.funcaoService.agrupar(subelemento.registros, ['df_codigo', 'df_nome'], totalizadores)
        for (const grupoDespFicha of agrupamentoDespFicha) {
          if (opcaoUnidade != 5) {
            registros.push([
              { text: grupoDespFicha.grupo['df_codigo'] },
              { text: grupoDespFicha.grupo['df_nome'] },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['empenhado_mes']) },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['empenhado']) },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['liquidado_mes']) },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['liquidado']) },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['empenhado'] - grupoDespFicha.totalizadores['liquidado']) },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['pago_mes']) },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['pago']) },
              { text: this.funcaoService.convertToBrNumber(grupoDespFicha.totalizadores['liquidado'] - grupoDespFicha.totalizadores['pago']) },
            ]);
          }

          const subElemGroup = this.funcaoService.agrupar(grupoDespFicha.registros, ['de_codigo', 'de_nome'], totalizadores)
          for (const registro of subElemGroup) {
            const empenhadoMes: number = registro.totalizadores['empenhado_mes'];
            const empenhado: number = registro.totalizadores['empenhado'];
            const liquidadoMes: number = registro.totalizadores['liquidado_mes'];
            const liquidado: number = registro.totalizadores['liquidado'];
            const saldoLiquidado = +empenhado - +liquidado;
            const pagoMes: number = registro.totalizadores['pago_mes'];
            const pago: number = registro.totalizadores['pago'];
            const divida = +liquidado - +pago;
            funcao['empenhadoMes'] += +empenhadoMes;
            funcao['empenhado'] += +empenhado;
            funcao['liquidadoMes'] += +liquidadoMes;
            funcao['liquidado'] += +liquidado;
            funcao['saldoLiquidado'] += +saldoLiquidado;
            funcao['pagoMes'] += +pagoMes;
            funcao['pago'] += +pago;
            funcao['divida'] += +divida;
            totalEmpenhadoMes += +empenhadoMes;
            totalEmpenhado += +empenhado;
            totalDivida += +divida;
            totalLiquidadoMes += +liquidadoMes;
            totalLiquidado += +liquidado;
            totalSaldoLiquidado += +saldoLiquidado;
            totalPagoMes += +pagoMes;
            totalPago += +pago;

            registros.push([
              { text: registro.grupo['de_codigo'] },
              { text: registro.grupo['de_nome'] },
              { text: this.funcaoService.convertToBrNumber(registro.totalizadores['empenhado_mes']) },
              { text: this.funcaoService.convertToBrNumber(registro.totalizadores['empenhado']) },
              { text: this.funcaoService.convertToBrNumber(registro.totalizadores['liquidado_mes']) },
              { text: this.funcaoService.convertToBrNumber(registro.totalizadores['liquidado']) },
              { text: this.funcaoService.convertToBrNumber(registro.totalizadores['empenhado'] - registro.totalizadores['liquidado']) },
              { text: this.funcaoService.convertToBrNumber(registro.totalizadores['pago_mes']) },
              { text: this.funcaoService.convertToBrNumber(registro.totalizadores['pago']) },
              { text: this.funcaoService.convertToBrNumber(registro.totalizadores['liquidado'] - registro.totalizadores['pago']) },
            ]);
          }
        }

        subelemento.registros.forEach(item => {
          uniEmpenhadoMes += +item.empenhado_mes;
          uniEmpenhado += +item.empenhado;
          uniLiquidadoMes += +item.liquidado_mes;
          uniLiquidado += +item.liquidado;
          uniSaldo += +item.empenhado - +item.liquidado;
          uniPagoMes += +item.pago_mes;
          uniPago += +item.pago;
          uniDivida += +item.liquidado - +item.pago;
        });
        registros.push([
          { text: 'TOTAL DO ELEMENTO' }, { text: '' },
          { text: this.funcaoService.convertToBrNumber(uniEmpenhadoMes) },
          { text: this.funcaoService.convertToBrNumber(uniEmpenhado) },
          { text: this.funcaoService.convertToBrNumber(uniLiquidadoMes) },
          { text: this.funcaoService.convertToBrNumber(uniLiquidado) },
          { text: this.funcaoService.convertToBrNumber(uniSaldo) },
          { text: this.funcaoService.convertToBrNumber(uniPagoMes) },
          { text: this.funcaoService.convertToBrNumber(uniPago) },
          { text: this.funcaoService.convertToBrNumber(uniDivida) },
        ]);
      }

      if (funcao['codigo']) {
        registros.push([
          { text: 'TOTAL GERAL:'}, { text: '' },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhadoMes) },
          { text: this.funcaoService.convertToBrNumber(totalEmpenhado) },
          { text: this.funcaoService.convertToBrNumber(totalLiquidadoMes) },
          { text: this.funcaoService.convertToBrNumber(totalLiquidado) },
          { text: this.funcaoService.convertToBrNumber(totalSaldoLiquidado) },
          { text: this.funcaoService.convertToBrNumber(totalPagoMes) },
          { text: this.funcaoService.convertToBrNumber(totalPago) },
          { text: this.funcaoService.convertToBrNumber(totalDivida) },
        ]);
      }
    }

    dados = null;
    let csv = '';
    for (let i=0; i<registros.length; i++) {
      const linha = registros[i];
      if (i > 0) csv += '\n';
      for (let x=0; x<linha.length; x++) {
        if (x > 0) csv += ';';
        csv += String(linha[x]['text']);
      }
    }
    registros = null;

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + csv));
    element.setAttribute("download", `Balancete Analítico da Despesa subelemento.csv`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  retornarLinhaSoma(recurso: {}) {
    return [
      {
        text: 'SOMA', colSpan: 2,
        bold: true, fontSize: 7
      },
      '',
      { text: this.funcaoService.convertToBrNumber(recurso['empenhadoMes']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(recurso['empenhado']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(recurso['liquidadoMes']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(recurso['liquidado']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(recurso['saldoLiquidado']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(recurso['pagoMes']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(recurso['pago']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(recurso['divida']), bold: true, alignment: 'right', fontSize: 7 },
    ];
  }

  private async conteudoAssinatura(login: any, exercicio: Exercicio, mes: number, dataFinal: string) {
    const conteudo = [];
    const data = dataFinal ?? `${exercicio.ano}-${this.funcaoService.strZero(mes, 2)}-${this.funcaoService.ultimoDiaMes(+mes, exercicio.ano)}`;
    this.assinatura = await this.assinaturaService.obter({ orgao_id: login.orgao.id, 'data_limite$ge': data, 'orderBy': 'data_limite$ASC' }).toPromise();

    if (!this.assinatura) {
      toastr.error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
      throw new Error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
    }

    conteudo.push([
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      },
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      },
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      }
    ]);
    conteudo.push([
      { text: this.assinatura.ordenador_despesa, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.contador, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.tesoureiro, border: [false, false, false, false], bold: true, alignment: 'center' }
    ]);
    conteudo.push([
      { text: this.assinatura.cargo_ordenador_despesa, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.cargo_contador + (this.assinatura.crc_contador ? ` - ${this.assinatura.crc_contador}` : ''), border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.cargo_tesoureiro, border: [false, false, false, false], bold: true, alignment: 'center' }
    ]);

    return [{
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', '*', '*'],
        body: conteudo
      }
    }];
  }
}
