import { Sistemas } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from './orgao.model';
import { Usuario } from './usuario.model';

export class UsuarioAcesso extends BaseResourceModel {
  constructor(
    public id?: number,
    public tipo?: 'LOGIN' | 'LOGOFF',
    public sistema?: Sistemas,
    public usuario?: Usuario,
    public orgao?: Orgao
  ) {
    super();
  }

  static converteJson(json: any): UsuarioAcesso {
    return Object.assign(new UsuarioAcesso(), json);
  }

}
