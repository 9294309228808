import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Licitacao, PregaoHistorico } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LicitacaoService extends BaseResourceService<Licitacao> {

  constructor(
    protected injector: Injector
  ) {
    super(`licitacoes`, injector);
  }

  public obterPorNumero(numero: number, ano: number, orgaoId: number): Observable<Licitacao> {
    return this.http.get<Licitacao>(
      `${this.login.cidade.id}/${this.api}/filtrar?numero=${numero}&exercicio.ano=${ano}&orgao_id=${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public proximoNumero(ano: number,modalidade_id: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-numero/${ano}/${modalidade_id}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalizarPorModalidade(exercicio: number, orgaoId: number, modalidade: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/total-modalidade/${exercicio}/${orgaoId}/${modalidade}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public licitacoesVigentes(exercicio: number, orgaoId: number, modalidade?: number): Observable<{}[]> {
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/tce-vigentes/${exercicio}/${orgaoId}${modalidade ? `?modalidade=${modalidade}` : ''}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public salvarHistoricoPregao(ent: Licitacao): Observable<PregaoHistorico[]> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/historico-pregao`, ent, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public removerLicitacao(ent: Licitacao): Observable<Licitacao> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/remover`, ent, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public importarAOP714(arquivo: any) {

    //const params = this.converterParametrosStr(parametros);

    const formData = new FormData();
    formData.append('file', arquivo);

    const headers = this.httpOptions();
    headers.headers = headers.headers.delete('Content-Type');

    return this.http.post(`${this.login.cidade.id}/${this.api}/importarAOP714/${this.login.orgao.id}/${this.login.exercicio.id}`,
      formData, headers)
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public importarAOP603(arquivo: any) {

    //const params = this.converterParametrosStr(parametros);

    const formData = new FormData();
    formData.append('file', arquivo);

    const headers = this.httpOptions();
    headers.headers = headers.headers.delete('Content-Type');

    return this.http.post(`${this.login.cidade.id}/${this.api}/importarAOP603/${this.login.orgao.id}/${this.login.exercicio.id}`,
      formData, headers)
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
  public importarAOP712(arquivo: any) {

    //const params = this.converterParametrosStr(parametros);

    const formData = new FormData();
    formData.append('file', arquivo);

    const headers = this.httpOptions();
    headers.headers = headers.headers.delete('Content-Type');

    return this.http.post(`${this.login.cidade.id}/${this.api}/importarAOP712/${this.login.orgao.id}/${this.login.exercicio.id}`,
      formData, headers)
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public importarAOP715(arquivo: any) {

    //const params = this.converterParametrosStr(parametros);

    const formData = new FormData();
    formData.append('file', arquivo);

    const headers = this.httpOptions();
    headers.headers = headers.headers.delete('Content-Type');
    return this.http.post(`${this.login.cidade.id}/${this.api}/importarAOP715/${this.login.orgao.id}/${this.login.exercicio.id}/${this.login.usuario.id}`,
      formData, headers)
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public buscarPendenciasPncp(orgao, exercicio): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/pendencia-pncp/${orgao}/${exercicio}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public envioIntegracaoPCP(licitacao: number, orgao: number, usuario: number, homologacao: boolean) {
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/envio-integracao-pcp/${licitacao}/${orgao}/${usuario}/${homologacao}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public retornoIntegracaoPCP(licitacao: number, orgao: number, usuario: number, homologacao: boolean) {
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/retorno-integracao-pcp/${licitacao}/${orgao}/${usuario}/${homologacao}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public envioIntegracaoBLL(licitacao: number, orgao: number, usuario: number, homologacao: boolean, bnc?: boolean) {
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/envio-integracao-bll/${licitacao}/${orgao}/${usuario}/${homologacao}/${bnc ? true : false}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public retornoIntegracaoBLL(licitacao: number, orgao: number, usuario: number, homologacao: boolean, bnc?: boolean) {
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/retorno-integracao-bll/${licitacao}/${orgao}/${usuario}/${homologacao}/${bnc ? true : false}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
