import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FuncaoService, RetencaoExtra } from 'eddydata-lib';
import * as toastr from 'toastr';
import { RetencaoExtraService } from '../service/retencao-extra.service';

@Component({
  selector: 'lib-retencao-extra-reinf',
  templateUrl: './retencao-extra-reinf.component.html'
})
export class RetencaoExtraReinfComponent implements OnInit {
  @Input() retencao: RetencaoExtra;
  @Output() callback: EventEmitter<RetencaoExtra> = new EventEmitter();

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  paises: { codigo: number, nome: string }[] = [];

  constructor(private retencaoExtraService: RetencaoExtraService, private funcaoService: FuncaoService) { }

  ngOnInit(): void {
  }

  salvar() {
    this.retencaoExtraService.atualizar(this.retencao).subscribe((res) => {
      toastr.success('Informações adicionais para retenção salva com sucesso');
      this.retencao = res;
      this.callback.emit(this.retencao);
    }, (e) => { this.funcaoService.acaoErro(e) });
  }
}


