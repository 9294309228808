import { Directive, OnDestroy } from '@angular/core';
import { FuncaoService, GlobalService, Ldo, LoginContabil, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LdoService } from '../../../../planejamento/ldo/service/ldo.service';
import { SelectItem } from 'primeng/api/selectitem';

@Directive()
export class Demo7Ldo implements OnDestroy {
  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();
  listaOrgaos: SelectItem[];


  constructor(
    protected ano: number,
    protected ldoServico: LdoService,
    protected orgaos: number[] = [],
    listaOrgaos?: SelectItem[]) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
    if (listaOrgaos) this.listaOrgaos = listaOrgaos;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio() {
    this.ldoServico.filtrar({
      tipo$in: 'D70,D71', 'orgao.id$in': this.orgaos, ano: this.ano, relations: 'ppa', orderBy: 'tipo,linha'
    })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        const lista70 = [];
        let agrupamento = this.funcaoService.agrupar(dados.content, ['linha', 'descricao1', 'descricao2', 'descricao3', 'titulo', 'somador'], ['valor1', 'valor2', 'valor3'])
        for (const dado of agrupamento) {
          let item: Ldo = {
            linha: dado.grupo['linha'],
            descricao1: dado.grupo['descricao1'],
            descricao2: dado.grupo['descricao2'],
            descricao3: dado.grupo['descricao3'],
            titulo: dado.grupo['titulo'],
            somador: dado.grupo['somador'],
            valor1: dado.totalizadores['valor1'],
            valor2: dado.totalizadores['valor2'],
            valor3: dado.totalizadores['valor3'],
          }
          lista70.push(item)
        }
        const lista = [];
        this.soma(lista70)
        lista.push(
          this.cabecalho().concat(this.conteudo(lista70))
        );
        Relatorio.imprimirPersonalizado(
          'AMF/Tabela 7 - DEMONSTRATIVO 7 – ESTIMATIVA E COMPENSAÇÃO DA RENÚNCIA DE RECEITA'
          , this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          lista,
          'landscape', 'DEMONSTRATIVO VII - LDO',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private cabecalho(): {}[] {
    const conteudo: {}[] = [
      [{ text: 'LEI DE DIRETRIZES ORÇAMENTÁRIAS', fontSize: 11, alignment: 'center', border: [false, false, false, false] }],
      [{ text: 'ANEXO DE METAS FISCAIS', fontSize: 11, alignment: 'center', border: [false, false, false, false] }],
      [{ text: 'ESTIMATIVA E COMPENSAÇÃO DA RENÚNCIA DE RECEITA', fontSize: 11, alignment: 'center', bold: true, border: [false, false, false, false] }],
    ]

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private conteudo(dados: any[]): {}[] {
    if (dados.length === 0) {
      return;
    }
    const ano1 = +this.ano;
    const ano2 = +this.ano + 1;
    const ano3 = +this.ano + 2;



    // monta o cabecalho
    const registros: {}[] = [
      [
        {
          text: `EXERCÍCIO: ${this.ano}`, fontSize: 11, alignment: 'center', bold: true, colSpan: 7, border: [false, false, false, false], margin: [0, 3, 0, 3]
        }, '', '', '', '', '', ''
      ],
      [
        {
          text: 'AMF - Demonstrativo 7 (LRF, art. 4°, § 2°, inciso V)', fontSize: 7,
          alignment: 'left', bold: true, colSpan: 7, border: [false, false, false, false]
        }, '', '', '', '', '', ''
      ]]
    registros.push([
      {
        text: 'TRIBUTO',
        alignment: 'center',
        bold: true, rowSpan: 3,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      },
      {
        text: 'MODALIDADE',
        alignment: 'center',
        bold: true, rowSpan: 3,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      },
      {
        text: 'SETORES/ PROGRAMAS/ BENEFICIÁRIO',
        alignment: 'center',
        bold: true, rowSpan: 3,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      },
      {
        text: 'RENÚNCIA DE RECEITA PREVISTA',
        alignment: 'center',
        bold: true, rowSpan: 2,
        border: [true, true, true, true], fillColor: '#f5f5f5', colSpan: 3
      },
      '',
      '',
      {
        text: 'COMPENSAÇÃO',
        alignment: 'center',
        bold: true, rowSpan: 3,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      },
    ],
      [
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      ],
      [
        {
          text: '', colSpan: 2
        },
        '',
        '',
        {
          text: ano1,
          alignment: 'center',
          bold: true, fontSize: 8,
          border: [true, true, true, true], fillColor: '#f5f5f5'
        },
        {
          text: ano2,
          alignment: 'center',
          bold: true, fontSize: 8,
          border: [true, true, true, true], fillColor: '#f5f5f5'
        },
        {
          text: ano3,
          alignment: 'center',
          bold: true, fontSize: 8,
          border: [true, true, true, true], fillColor: '#f5f5f5'
        },
        ''
      ]);
    // monta o agrupamento do relatório
    for (const item of dados) {
      registros.push([
        { text: item.descricao1, fontSize: 8, border: item.somador ? [true, true, false, true] : [true, false, false, false], bold: item.somador || item.titulo ? true : false },
        { text: item.descricao2, fontSize: 8, border: item.somador ? [false, true, false, true] : [true, false, false, false], bold: item.somador || item.titulo ? true : false },
        { text: item.descricao3, fontSize: 8, border: item.somador ? [false, true, true, true] : [true, false, false, false], bold: item.somador || item.titulo ? true : false },
        {
          text: this.funcaoService.convertToBrNumber(item.valor1), alignment: 'right',
          fontSize: 8, border: item.somador ? [true, true, true, true] : [true, false, true, false], bold: item.somador || item.titulo ? true : false
        },
        {
          text: this.funcaoService.convertToBrNumber(item.valor2), alignment: 'right',
          fontSize: 8, border: item.somador ? [true, true, true, true] : [true, false, true, false], bold: item.somador || item.titulo ? true : false
        },
        {
          text: this.funcaoService.convertToBrNumber(item.valor3), alignment: 'right',
          fontSize: 8, border: item.somador ? [true, true, true, true] : [true, false, true, false], bold: item.somador || item.titulo ? true : false
        },
        { text: item.somador ? '-' : item.descricao4, fontSize: 8, border: item.somador ? [true, true, true, true] : [true, false, true, false], bold: item.somador || item.titulo ? true : false, alignment: item.somador ? 'center' : 'left' },
      ]);
    }

    // registros.push([
    //   { text: '', border: [false, true, false, false] },
    //   { text: '', border: [false, true, false, false] },
    //   { text: '', border: [false, true, false, false] },
    //   { text: '', border: [false, true, false, false] },
    //   { text: '', border: [false, true, false, false] },
    //   { text: '', border: [false, true, false, false] },
    //   { text: '', border: [false, true, false, false] }
    // ]);
    if (this.listaOrgaos?.length > 0) {
      registros.push([{ text: '\n\n', colSpan: 7, border: [false, false, false, false] }, '', '', '', '', '', '']);
      registros.push([{ text: this.listaOrgaos.filter(o => this.orgaos.some(org => org === o.value)).map(o => o.label).join('\n'), fontSize: 8, colSpan: 7, border: [false, false, false, false] }, '', '', '', '', '', '']);
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 5,
        widths: ['*', '*', '*', 60, 60, 60, 'auto'],
        body: registros
      }
    }];
  }

  private async soma(lista: Ldo[]) {
    for (const linhaTotal of lista.filter(item => item.somador)) {
      linhaTotal.valor1 = lista
        .filter(item => !item.somador).length !== 0
        ? lista
          .filter(item => !item.somador)
          .reduce((acc, atual) => acc += +atual.valor1, 0)
        : linhaTotal.valor1;
      linhaTotal.valor2 = lista
        .filter(item => !item.somador).length !== 0
        ? lista
          .filter(item => !item.somador)
          .reduce((acc, atual) => acc += +atual.valor2, 0)
        : linhaTotal.valor2;
      linhaTotal.valor3 = lista
        .filter(item => !item.somador).length !== 0
        ? lista
          .filter(item => !item.somador)
          .reduce((acc, atual) => acc += +atual.valor3, 0)
        : linhaTotal.valor3;
    }
  }
}
