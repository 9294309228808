// tslint:disable: variable-name
import { Legislacao } from './legislacao.model';
import { Usuario } from '../comum/usuario.model';

export class LegislacaoRelacionado {

    id: number;

    data_cadastro: Date;

    data_alteracao: Date;

    usuario: Usuario;

    legislacao: Legislacao;
}
