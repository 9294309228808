import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Coluna, FormatoExportacao } from '../../components/types';
import { Orgao } from '../../entidade/comum/orgao.model';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { BaseResourceListComponent, Filtro } from '../../models/base-resource-list';
import { OrgaoService } from '../service/orgao.service';

@Component({
  selector: 'lib-orgao-lst',
  templateUrl: './orgao-lst.component.html'
})
export class OrgaoLstComponent extends BaseResourceListComponent<Orgao, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public listaEspecies: Array<any>;
  public usarExtendido: boolean;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private orgaoService: OrgaoService) {
    super(orgaoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'cidade';
  }

  protected condicoesGrid(): {} {
    return {
      ['cidade.id']: this.login.cidade.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['codigo$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      text: ['nome'],
      number: ['codigo', 'cnpj']
    };
  }

  protected afterInit(): void {
    this.listaEspecies = [
      { id: 'P', nome: 'PREFEITURA MUNICIPAL' },
      { id: 'C', nome: 'CÂMARA MUNICIPAL' },
      { id: 'A', nome: 'AUTARQUIA MUNICIPAL' },
      { id: 'F', nome: 'FUNDAÇÃO MUNICIPAL' },
      { id: 'I', nome: 'INSTITUTO PREVIDÊNCIA' }
    ];
  }

  protected acaoRemover(model: Orgao): Observable<Orgao> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'codigo' },
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'CNPJ', coluna: 'cnpj' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'cidade';
    this.orgaoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE ORGÃOS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem órgãos públicos', ['auto', '*', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
