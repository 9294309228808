// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ChamamentoFavorecido } from './chamamento-favorecido.model';
import { PlanoTrabalho } from './plano-trabalho.model';

export class CredenciamentoPlano extends BaseResourceModel {
  constructor(
    public id?: number,
    public acao?: string,
    public concluido?: boolean,
    public plano_trabalho?: PlanoTrabalho,
    public chamamento_favorecido?: ChamamentoFavorecido,
    public editavel?: boolean
  ) {
    super();
  }
  static converteJson(json: any): CredenciamentoPlano {
    return Object.assign(new CredenciamentoPlano(), json);
  }
}
