import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseResourceRptComponent, Coluna, LicitacaoPipe } from "eddydata-lib";
import { RcmsService } from "../../rcms/service/rcms.service";

@Component({
  selector: 'lib-rcms-contrato-rpt',
  templateUrl: './rcms-contrato-rpt.component.html'
})
export class RcmsContratoRptComponent extends BaseResourceRptComponent {


  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public pesquisa: 'C' | 'D' = 'C';

  public contrato: string = '';
  public data_inicio: Date = new Date();
  public data_fim: Date = new Date();

  protected datepipe: DatePipe;

  public parametros: { session?: any, filtros?: any } = {};

  constructor(
    private rcmsService: RcmsService
  ) {
    super();
  }

  protected afterInit(): void {
    this.datepipe = new DatePipe('pt');
    this.formato = 'pdf';
    this.orientacao = 'landscape';
  }

  protected obterColunasRelatorio(): Coluna[] {
    let retorno: Coluna[] = [];
    retorno.push({ titulo: 'Contrato', coluna: 'contrato.numero', alignment: 'left', tipo: 'Number', agrupar: true, adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'Nº REQUISIÇÃO', coluna: 'numero', alignment: 'center', tipo: 'Number', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'DATA', coluna: 'data_rcms', tipo: 'Date', alignment: 'center', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'FICHA', coluna: 'ficha.numero', alignment: 'center', tipo: 'Number', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'DESPESA', coluna: 'ficha.despesa.codigo', tipo: 'Number', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'RECURSO', coluna: 'ficha.recurso.codigo', alignment: 'center', tipo: 'Number', adicionaisBody: { border: [false, false, false, false] } });
    // retorno.push({ titulo: 'RAZÃO SOCIAL', coluna: 'favorecido.nome', alignment: 'left', tipo: 'String', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'SUB-ELEMENTO', coluna: 'subelemento.nome', alignment: 'left', tipo: 'String', adicionaisBody: { border: [false, false, false, false] } });
    retorno.push({ titulo: 'MENOR PREÇO GLOBAL', coluna: 'menor_preco_global', alignment: 'right', tipo: 'Number', decimais: 2, adicionaisBody: { border: [false, false, false, false] } });
    retorno = retorno.map((r) => {
      r.adicionaisHeader = { margin: 3 };
      return r;
    })
    return retorno;
  }

  public obterColunasfiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Nº Contrato', coluna: 'contrato.numero', tipo: 'String', mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }], padrao: true, cols: 6 });
    retorno.push({ titulo: 'Período', coluna: 'data_rcms', tipo: 'Date', padrao: true, filtro2: new Date(), cols: 12 });
    retorno.push({ titulo: 'Tipo', coluna: 'contrato.tipo', tipo: 'Selection', opcoesSelecao: [{ valor: 'CONTRATO', label: 'Contrato' }, { valor: 'REGISTRO_PRECO', label: 'Registro de Preço' }], padrao: false, cols: 5 });

    return retorno;
  }

  public validar(): boolean {
    return true;
  }

  protected tituloRelatorio(): string {

    if (this.parametros.filtros['contrato.tipo$eq'] === "REGISTRO_PRECO" )
      return `LISTAGEM GERAL DE REQUISIÇÕES DE COMPRA POR REGISTRO DE PREÇO`;
    if (this.parametros.filtros.data_rcms$ge && this.parametros.filtros.data_rcms$le)
      return `LISTAGEM GERAL DE REQUISIÇÕES DE COMPRA POR CONTRATO - PERÍODO ${this.funcaoService.converteDataBR(this.parametros.filtros.data_rcms$ge)} - ${this.funcaoService.converteDataBR(this.parametros.filtros.data_rcms$le)}`;
    else
      return `LISTAGEM GERAL DE REQUISIÇÕES DE COMPRA POR CONTRATO`;

    // if (this.pesquisa === 'C')
    //   return `LISTAGEM GERAL DE REQUISIÇÕES DE COMPRA POR CONTRATO - CONTRATO ${this.contrato}`;
    // else
    //   return `LISTAGEM GERAL DE REQUISIÇÕES DE COMPRA POR CONTRATO - PERÍODO ${this.funcaoService.converteDataBR(this.data_inicio)} - ${this.funcaoService.converteDataBR(this.data_fim)}`;
  }

  protected larguraColunas(): (string | number)[] {
    return ['auto', 'auto', 'auto', 'auto', 'auto', '*', 100];
  }

  protected totalizarColunas(): (string | {})[] {
    return [{ nome: 'menor_preco_global', abaixo: true }];
  }

  public imprimir(): void {
    if (!this.validar())
      return null;
    return super.imprimir();
  }

  protected carregarLista(): Promise<any[]> {
    return new Promise<any[]>((resolve) => {
      let parametros = { 'orgao.id': this.login.orgao.id, 'exercicio.id': this.login.exercicio.id, 'contrato.id$not_null': true.valueOf, relations: 'ficha.despesa,ficha.recurso,subelemento', orderBy: 'contrato.numero$ASC,data_rcms$ASC' };

      if (this.parametros?.filtros)
        parametros = Object.assign(parametros, this.parametros.filtros);

      this.rcmsService.extendido(0, -1, parametros)
        .subscribe((data) => {
          if (!data?.content)
            resolve(null);
          else
            resolve(data.content.map((c) => {
              c.contrato.numero = new LicitacaoPipe().transform(c.contrato.numero);
              if (c.subelemento?.nome)
                c.subelemento.nome = `${c.subelemento.codigo} - ${c.subelemento.nome}`;
              return c;
            }));
        })
    });
  }

  protected layout(): {} {
    return {
      hLineWidth() {
        return 1;
      },
      vLineWidth(i, node) {
        return 1;
      },
      hLineColor(i) {
        return i === 1 || i === 0 ? 'black' : '0aaa';
      },
      paddingLeft(i) {
        return 2;
      },
      paddingRight(i, node) {
        return 2;
      }
    }
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }
}