import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProdutoService } from "almoxarifado-lib";
import { EddyAutoComplete, GlobalService, Login, Produto } from "eddydata-lib";
import * as toastr from 'toastr';
import { EtiquetaRpt } from "./etiqueta-rpt";

declare var $: any;

@Component({
  selector: 'app-etiqueta-rpt',
  templateUrl: './etiqueta-rpt.component.html'
})
export class EtiquetaRptComponent implements OnInit {

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public login: Login;

  public produtoAutoComplete: EddyAutoComplete<Produto>;
  public produtos: Produto[] = [];
  public produto: Produto;
  public qtdPorPagina: number = 8;

  constructor(
    private produtoService: ProdutoService) {
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnInit(): void {
    this.carregarAutoCompletes();
  }

  public imprimir() {
    if (!this.validar())
      return;
    const paginas = this.montarDadosEtiqueta();

    console.log(paginas);

    new EtiquetaRpt().imprimir(paginas);
  }

  private montarDadosEtiqueta(): { produtos: Produto[] }[] {
    const retorno: { produtos: Produto[] }[] = [];

    if (!this.produtos)
      return [];

    let produtos: Produto[] = [];
    for (const produto of this.produtos) {
      const qtdProduto = produto['quantidade'] ? produto['quantidade'] : 1;

      for(const _ of Array.from(Array(qtdProduto).keys())){
        produtos.push(produto);
        if(produtos.length >= this.qtdPorPagina){          
          retorno.push({ produtos });
          produtos = [];
        }
      }
    }

    if (produtos.length > 0)
      retorno.push({ produtos });

    return retorno;
  }

  public adicionarProduto(produto?: Produto) {
    if (produto)
      this.produto = produto;
    this.produto['quantidade'] = 1;
    this.produtos.push(this.produto);
    
    setTimeout(() => {
      this.produto = null;
    }, 100);
  }

  public removerProduto(index: number) {
    this.produtos.splice(index, 1);
  }

  private validar(): boolean {
    if (!this.produtos) {
      toastr.warning(`Não foi selecionado nenhum produto!`);
      return false;
    }

    if(this.qtdPorPagina > 8 || this.qtdPorPagina < 1){
      toastr.warning(`Quantidade por páginas fora do limite de 1 a 8 etiquetas!`);
      return false;
    }

    return true;
  }

  private carregarAutoCompletes() {
    this.produtoAutoComplete = ProdutoService.autoCompleteCodigoCompleto(null, this.produtoService,
      'id', ['codigo', 'nome'], { orgao_id: this.login.orgao.id, orderBy: 'codigo,nome' }, { number: ['id', 'codigo'], text: ['codigo', 'nome'] });
  }

  public abrirConsultaProduto(event: KeyboardEvent) {
    event.preventDefault();
    $('#dialogProdutoBusca').modal('show');
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }
}