import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { IMaskModule } from 'angular-imask';
import { SharedModule } from 'eddydata-lib';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CompraAbertoListDlgComponent } from './compra-aberto-list/compra-aberto-list-dlg.component';
import { ContratoListDlgComponent } from './contrato-list-dlg/contrato-list-dlg.component';
import { ProdutosListDlgComponent } from './produtos-list-dlg/produtos-list-dlg.component';
import { RcmsAbertoListDlgComponent } from './rcms-aberto-list-dlg/rcms-aberto-list-dlg.component';

@NgModule({
  declarations: [ContratoListDlgComponent, RcmsAbertoListDlgComponent, ProdutosListDlgComponent, CompraAbertoListDlgComponent],
  exports: [ContratoListDlgComponent, RcmsAbertoListDlgComponent, ProdutosListDlgComponent, CompraAbertoListDlgComponent],
  imports: [
    RouterModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    SharedModule,
    OverlayPanelModule,
    ProgressSpinnerModule,
    AutoCompleteModule,
    CheckboxModule,
    PanelModule,
    MenuModule,
    IMaskModule,
    CalendarModule
  ]
})
export class ConsultaCompraModule { }
