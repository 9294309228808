import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';

export class ReceitaSiope extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public nome?: string,
    public exercicio?: Exercicio,) {
    super();
  }
  static converteJson(json: any): ReceitaSiope {
    return Object.assign(new ReceitaSiope(), json);
  }
}