import { Component } from '@angular/core';
import {
  FavorecidoPenalidade, BaseResourceItemsComponent, DateFormatPipe, GlobalService
} from 'eddydata-lib';
import { FavorecidoPenalidadeService } from '../service/favorecido-penalidade.service';
import * as toastr from 'toastr';

@Component({
  selector: 'lib-favorecido-penalidade',
  templateUrl: './favorecido-penalidade.component.html',
  styleUrls: ['./favorecido-penalidade.component.css']
})
export class FavorecidoPenalidadeComponent extends BaseResourceItemsComponent<FavorecidoPenalidade>{

  public ptBR: any;

  constructor(private globalService: GlobalService,
    public favorecidoPenalidadeService: FavorecidoPenalidadeService) {
    super(new FavorecidoPenalidade(), favorecidoPenalidadeService);
  }

  protected afterInit(): void {
    this.ptBR = this.globalService.obterDataBR();
  }

  protected validSave(item: FavorecidoPenalidade): boolean {
    if (!item.motivo) {
      toastr.warning('Informe o motivo!');
      return false;
    }
    if (!item.data_inicio) {
      toastr.warning('Informe a data de início!');
      return false;
    }
    if (!item.data_vencimento) {
      toastr.warning('Informe a data de vencimento!');
      return false;
    }
    if (item.data_inicio.getTime() > item.data_vencimento.getTime()) {
      toastr.warning('Data de vencimento informada menor que a data de inicio!');
      return false;
    }
    // if (!item.bloquear_compra && !item.bloquear_contratacao
    //   && !item.bloquear_empenho && !item.bloquear_pagamento) {
    //   toastr.warning('Informe um bloqueio para penalidade!');
    //   return false;
    // }

    return true;
  }


  protected afterEdit(item: FavorecidoPenalidade) {
    this.globalService.calendarMascara();
    item.data_inicio = new DateFormatPipe().transform(item.data_inicio, []);
    item.data_vencimento = new DateFormatPipe().transform(item.data_vencimento, []);
  }

  protected beforeSave(item: FavorecidoPenalidade): void {
    item.orgao = this.login.orgao
  }

}