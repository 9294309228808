export type Audesp4DadosLicitacao = {
    CodigoLicitacao: number;
    NumeroProcessoAdm: string;
    AnoProcessoAdm: number;
    QuantidadeLotes: number;
    DescricaoObj?: string;
    JustificativaObj: string;
    VlTotalLicitacao: number;
    Credenciamento?: Audesp4Credenciamento;
    CredenciamentoNao?: 'S' | 'N';
    Subcontratacao?: 'S' | 'N';
};
export type Audesp4LicitanteLista = {
    LicitanteCPF?: Audesp4LicitanteCPF[];
    LicitanteCNPJ?: Audesp4LicitanteCNPJ[];
    LicitanteEstrangeiro?: Audesp4LicitanteEstrangeiro[];
};
export type Audesp4LicitanteConvite = {
    LicitanteConviteCPF?: Audesp4LicitanteConviteCPF[];
    LicitanteConviteCNPJ?: Audesp4LicitanteConviteCNPJ[];
    LicitanteConviteEstrangeiro?: Audesp4LicitanteConviteEstrangeiro[];
};
export type Audesp4Credenciamento = {
    PublicacaoCredenciamento?: Audesp4Publicacao[];
};
export type Audesp4SimData = {
    ExisteData: 'S';
    Data: string;
};
export type Audesp4AcordoCronogramaNao = {
    AcordoCronogramaNao: 'S' | 'N';
    AcordoCronogramaNaoMotivo?: string;
    MotivoAceitoAdministracao?: 'S' | 'N';
};
export type Audesp4InexecucaoTotal = {
    InexecucaoSim: string;
    InexecucaoMotivo?: string;
    DataAberturaProcedimentoAdministrativo: string;
    ExisteAplicacaoSancoes: 'S' | 'N';
};
export type Audesp4InexecucaoParcial = {
    InexecucaoSim: string;
    InexecucaoMotivo?: string;
    DataAberturaProcedimentoAdministrativo: string;
};
export type Audesp4Responsavel = {
    CPFResponsavel?: string;
    Nome?: string;
    Cargo?: string;
};
export type Audesp4ResponsavelTI = {
    CPFResponsavel?: string;
    Nome?: string;
    Cargo?: string;
    ResponsavelAreaTI?: 'S' | 'N';
    editavel?: boolean;
    pessoaId?: number;
};
export type Audesp4ParalisadaSim = {
    ObraParalisadaSim: 'S';
    ObraPublicaMotivoParalisacao?: string;
};
export type Audesp4Artigo16 = {
    Artigo16: 'S';
    EstimativaTrienal?: 'S' | 'N';
    AdequacaoPlano?: 'S' | 'N';
};
export type Audesp4Artigo17 = {
    Artigo17: 'S';
    MetasResultado?: 'S' | 'N';
    MedidasCompensacao?: 'S' | 'N';
    PrevisaoPpaLdo?: 'S' | 'N';
};
export type Audesp4LicitanteCNPJ = {
    CNPJ: string;
    RazaoSocial: string;
    LicitanteSocio?: Audesp4LicitanteSocio;
    LicitanteAdministrador?: string;
    LicitanteDeclaracaoMicroEmpresaPequenoPorte?: 'S' | 'N';
    LicitanteLoteItens?: Audesp4LicitanteLote[];
    IdentificacaoLicitante?: number;
};
export type Audesp4LicitanteConviteCNPJ = {
    CNPJ: string;
    RazaoSocial: string;
    LicitanteSocio?: Audesp4LicitanteSocio;
    LicitanteAdministrador?: string;
    LicitanteDeclaracaoMicroEmpresaPequenoPorte?: 'S' | 'N';
    LicitanteLoteItens?: Audesp4LicitanteLote[];
    IdentificacaoLicitante: number;
};
export type Audesp4LicitanteCPF = {
    CPF: string;
    Nome: string;
    LicitanteDeclaracaoMicroEmpresaPequenoPorte: 'S' | 'N';
    LicitanteLoteItens: Audesp4LicitanteLote[];
    IdentificacaoLicitante?: number;
};
export type Audesp4LicitanteConviteCPF = {
    CPF: string;
    Nome: string;
    LicitanteDeclaracaoMicroEmpresaPequenoPorte: 'S' | 'N';
    LicitanteLoteItens: Audesp4LicitanteLote[];
    IdentificacaoLicitante: number;
};
export type Audesp4LicitanteEstrangeiro = {
    OutroDoc: string;
    RazaoSocial: string;
    LicitanteSocio?: Audesp4LicitanteSocio;
    LicitanteAdministrador?: string;
    LicitanteDeclaracaoMicroEmpresaPequenoPorte?: 'S' | 'N';
    LicitanteLoteItens?: Audesp4LicitanteLote[];
    IdentificacaoLicitante?: number;
};
export type Audesp4LicitanteConviteEstrangeiro = {
    OutroDoc: string;
    RazaoSocial: string;
    LicitanteSocio?: Audesp4LicitanteSocio;
    LicitanteAdministrador?: string;
    LicitanteDeclaracaoMicroEmpresaPequenoPorte?: 'S' | 'N';
    LicitanteLoteItens?: Audesp4LicitanteLote[];
    IdentificacaoLicitante: number;
};
export type Audesp4LicitanteLote = {
    LicitanteNumLote: number;
    ResultadoHabilitacao: number;
    MotivoDesab_Desclass?: string;
    ValorProposta?: number;
    ValorPropostaPercentual?: number;
    CompativelMercadoValor?: 'S' | 'N';
    CompativelMercadoFonte?: string;
    editavel?: boolean;
};
export type Audesp4LicitanteSocio = {
    CPF?: string | string[];
    CNPJ?: string | string[];
    OutroDoc?: string | string[];
    tipoDoc?: 'CPF' | 'CNPJ' | 'OUTRO';
};
export type Audesp4LicitacaoProprioOrgaoNao = {
    Municipio: number;
    Entidade: number;
};
export type Audesp4ClassificacaoEconomicaOutros = {
    CodigoClassificacaoEconomicaOutros: string;
    DescricaoClassificacaoEconomicaOutros: string;
};
export type Audesp4ClassificacaoEconomica = {
    TipoClassificacaoEconomica: number;
    CodigoClassificacaoEconomica: number;
    ClassificacaoEconomicaOutros?: Audesp4ClassificacaoEconomicaOutros;
};
export type Audesp4Publicacao = {
    VeiculoPublicacao: number;
    PublicacaoData: string;
    PublicacaoDescr?: string;
    PublicacaoOficial?: 'S' | 'N';
    editavel?: boolean;
};
export type Audesp4Garantia = {
    GarantiaEditalItem: string;
    GarantiaValor: number;
};
export type Audesp4AtestadoDesempenho = {
    NumeroLote: number;
    AtestadoEditalItem?: string;
    AtestadoDescricao?: string;
    AtestadoPercentual?: number;
    AtestadoQuantidade?: number;
    editavel?: boolean;
};
export type Audesp4IndiceEconomico = {
    IndiceEconomicoTipo?: number;
    IndiceEconomicoOutroTipo?: Audesp4TipoIndiceEconomicoOutro;
    IndiceEconomicoItemEdital?: string;
    IndiceEconomicoValor?: number;
    editavel?: boolean;
};
export type Audesp4Participante = {
    CPF: string;
    CNPJ: string;
    OutroDoc: string;
    NomeRazaoSocial: string;
};
export type Audesp4JulgamentoSemInversao = {
    NaoExisteAtaAberturaDocumentosHabilitacao?: 'S' | 'N';
    ExisteAtaAberturaDocumentosHabilitacao?: Audesp4SimData;
    NaoExisteAtaJulgamentoDocumentosHabilitacao?: 'S' | 'N';
    ExisteAtaJulgamentoDocumentosHabilitacao?: Audesp4SimData;
    NaoExisteAtaAberturaJulgamentoPropostas?: 'S' | 'N';
    ExisteAtaAberturaJulgamentoPropostas?: Audesp4SimData;
    NaoExisteAtaJulgamentoPropostas?: 'S' | 'N';
    ExisteAtaJulgamentoPropostas?: Audesp4SimData;
    QuadroComparativoAberturaProposta?: 'S' | 'N';
};
export type Audesp4JulgamentoComInversao = {
    NaoExisteAtaAberturaJulgamentoPropostas?: 'S' | 'N';
    ExisteAtaAberturaJulgamentoPropostas?: Audesp4SimData;
    NaoExisteAtaJulgamentoPropostas?: 'S' | 'N';
    ExisteAtaJulgamentoPropostas?: Audesp4SimData;
    NaoExisteAtaAberturaJulgamentoDocumentosHabilitacao?: 'S' | 'N';
    ExisteAtaAberturaJulgamentoDocumentosHabilitacao?: Audesp4SimData;
    QuadroComparativoAberturaProposta?: 'S' | 'N';
};
export type Audesp4Recurso = {
    CPF?: string;
    CNPJ?: string;
    OutroDoc?: string;
    RecursoDtImpetracao?: string;
    RecursoPrazoLegal?: 'S' | 'N';
    RecursoResultado?: number;
    editavel?: boolean;
    tipoDoc?: 'CPF' | 'CNPJ' | 'OUTRO';
};
export type Audesp4TermoCiencia = {
    CPF: string;
    Nome: string;
    EmailProfissional?: string;
    EmailPessoal?: string;
    AssinouContrato?: 'S' | 'N';
    editavel?: boolean;
    pessoaId?: number;
};
export type Audesp4LicencaAmbiental = {
    TipoLicenciamento: number;
    NumLicenca: string;
    DataLicenca: string;
    editavel?: boolean;
};
export type Audesp4LoteObra = {
    NumeroLote: number;
    DescricaoLote: string;
    TipoExecucao: number;
    ClassificacaoEconomica: Audesp4ClassificacaoEconomica;
    TipoObraServicoEng: Audesp4TipoObraServicoEng;
    LocalizacaoObra: Audesp4LocalizacaoObra[];
    OrcamentoLoteSim?: Audesp4OrcamentoLoteSim[];
    OrcamentoLoteNao?: 'S' | 'N';
    TipoObraServicoEngOpcoes?: Audesp4TipoObraServicoEngOpcoes;
};
export type Audesp4LocalizacaoObra = {
    LocalObraServico: string;
    Latitude: number;
    Longitude: number;
    editavel?: boolean;
};
export type Audesp4LoteNaoObra = {
    NumeroLote: number;
    DescricaoLote: string;
    Quantidade: number;
    UnidadeMedida: string;
    TipoExecucao: number;
    ClassificacaoEconomica: Audesp4ClassificacaoEconomica;
    OrcamentoLoteSim?: Audesp4OrcamentoLoteSim[];
    OrcamentoLoteNao?: string;
    LoteCompostoItensSim?: Audesp4LoteCompostoItensSim[];
    LoteCompostoItensNao?: 'S' | 'N';
};
export type Audesp4OrcamentoLoteSim = {
    CPF?: string;
    CNPJ?: string;
    OutroDoc?: string;
    ValorUnitario: number;
    Quantidade: number;
    UnidadeMedida: string;
    DtOrcamento: string;
    editavel?: boolean;
    tipoDoc?: 'CPF' | 'CNPJ' | 'OUTRO';
};
export type Audesp4LoteCompostoItensSim = {
    Produto: string;
    Quantidade: number;
    UnidadeMedida: string;
    ClassificacaoEconomica: Audesp4ClassificacaoEconomica;
    OrcamentoItemSim?: Audesp4OrcamentoItemSim[];
    OrcamentoItemNao?: 'S' | 'N';
    editavel?: boolean;
};
export type Audesp4OrcamentoItemSim = {
    CPF?: string;
    CNPJ?: string;
    OutroDoc?: string;
    ValorUnitario: number;
    Quantidade: number;
    UnidadeMedida: string;
    DtOrcamento: string;
    editavel?: boolean;
    tipoDoc?: 'CPF' | 'CNPJ' | 'OUTRO';
};
export type Audesp4Empenho = {
    NumNotaEmpenho: string;
    NotaEmpenhoDt: string;
    NotaEmpenhoValor: number;
    FonteRecursos: number;
    editavel?: boolean;
    empenhoId?: number;
};
export type Audesp4ExistenciaRecursosSim = {
    ExistenciaRecursosValor: number;
    ExistenciaRecursosDt: string;
    Tesouro?: 'S';
    TransferenciasConveniosEstaduais?: Audesp4ConvenioEstadual[];
    RecursosPropriosFundosEspeciais?: 'S';
    RecursosPropriosAdministracaoIndireta?: 'S';
    ConvenioFederal?: Audesp4ConvenioFederal[];
    OutrasFontesDescricao?: string;
    OperacoesCredito?: Audesp4Financiamento[];
    EmendasParlamentaresIndividuais?: 'S';
    opcoes?: {
        Tesouro: boolean;
        TransferenciasConveniosEstaduais: boolean;
        RecursosPropriosFundosEspeciais: boolean;
        RecursosPropriosAdministracaoIndireta: boolean;
        ConvenioFederal: boolean;
        OutrasFontesDescricao: boolean;
        OperacoesCredito: boolean;
        EmendasParlamentaresIndividuais: boolean;
    };
};
export type Audesp4ConvenioEstadual = {
    ConvenioEstadualNum: Audesp4NumeroConvenio4;
    RepasseConvenioEstadualValor: number;
    ContrapartidaConvenioEstadualValor: number;
    editavel?: boolean;
};
export type Audesp4ConvenioFederal = {
    ConvenioFederalNum: Audesp4NumeroConvenio4;
    RepasseConvenioFederalValor: number;
    ContrapartidaConvenioFederalValor: number;
    editavel?: boolean;
};
export type Audesp4NumeroConvenio4 = {
    Numero: string;
    Ano: number;
};
export type Audesp4Financiamento = {
    AgenteFinanceiro?: string;
    ContratoFinanciamentoNum: string;
    ContratoFinanciamentoAno: number;
    RepasseContratoFinanciamentoValor: number;
    editavel?: boolean;
};
export type Audesp4MembroLicitacao = {
    CPFIntegrante: string;
    NomeIntegrante: string;
    AtribuicaoIntegrante: number;
    CargoOcupadoIntegrante: string;
    NaturezaCargoOcupado: number;
    editavel?: boolean;
    pessoaId?: number;
};
export type Audesp4ComissaoDesignacao = {
    NumAtoDesignacao: string;
    AnoAtoDesignacao: number;
    editavel?: boolean;
};
export type Audesp4ComissaoLicitacao = {
    membros: Audesp4MembroLicitacao[];
    TipoComissaoLicitacao: number;
    designacao: Audesp4ComissaoDesignacao[];
    atoDesignacaoComissaoNao?: 'S' | 'N';
    AtoDesignacaoComissaoDt?: string;
    AtoDesignacaoComissaoInicio?: string;
    AtoDesignacaoComissaoFim?: string;
};
export type Audesp4PreQualificacaoSim = {
    ItemEditalPre: string;
    NumeroEditalPre: string;
    PublicacaoOficialPre?: Audesp4Publicacao[];
    ConstaParecerJuridico: 'S' | 'N';
    GarantiaPre?: Audesp4Garantia[];
    IndiceEconomicoPre?: Audesp4IndiceEconomico[];
    CriterioJulgamentoPre: string;
    AtaRecebimentoNao?: 'S' | 'N';
    AtaRecebimentoDt?: string;
    ExisteRelatorioQualificacao?: 'S' | 'N';
    ExisteAtestadoDesempenho?: 'S' | 'N';
    ParticipanteQualificado?: Audesp4Participante[];
    ParticipanteNaoQualificado?: Audesp4Participante[];
    ExisteAtaJulgamentoPre?: 'S' | 'N';
    PublicacaoJulgamentoPreNao: 'S' | 'N';
    PublicacaoJulgamentoPre: Audesp4Publicacao[];
    Recurso?: Audesp4Recurso[];
    ExisteComprovacaoPublicacaoHomologacaoPreQualificacaoNao: 'S' | 'N';
    ExisteComprovacaoPublicacaoHomologacaoPreQualificacaoSim: Audesp4ExisteComprovacaoPublicacaoHomologacaoPreQualificacaoSim;
};
export type Audesp4ExisteComprovacaoPublicacaoHomologacaoPreQualificacaoSim = {
    HouvePublicacaoHomologacaoPrequalificacaoNao: 'S' | 'N';
    HouvePublicacaoHomologacaoPrequalificacaoSim: Audesp4Publicacao[];
    HomologacaoPreDt?: string[];
};
export type Audesp4Edital = {
    EditalNumero: string;
    EditalDt: string;
    editalPublicacaoNao?: 'S' | 'N';
    EditalPublicacao?: Audesp4Publicacao[];
    garantiaEditalItemNao?: 'S' | 'N';
    GarantiaEditalItem?: string;
    GarantiaEditalValor?: number;
    GarantiaEditalPercente?: number;
};
export type Audesp4Amostra = {
    AmostrasItem?: string;
};
export type Audesp4VistaTecnica = {
    VTecQtidadeEmpresas?: number;
    VTecItemEdital?: string;
    VTecPrazoEmDias?: number;
    VTecExigeCurriculoProfissional: 'S' | 'N';
    VTecExigeCREA_SP: 'S' | 'N';
};
export type Audesp4Autorizacao = {
    ExisteAutorizacaoSecretEstadualDL4116596?: 'S' | 'N' | 'NA';
    ExisteAutorizacaoResolucao7_2014?: 'S' | 'N';
};
export type Audesp4TipoTermoAditivo = {
    TipoTAditivo?: number;
    TAditivoOutros?: Audesp4TAditivoOutros;
};
export type Audesp4TAditivoOutros = {
    TipoTAditivoOutros: 14;
    DescricaoTAditivoOutros: string;
};
export type Audesp4TipoIndiceEconomicoOutro = {
    Outro: number;
    Descricao: string;
};
export type Audesp4TipoObraServicoEng = {
    AssessoriasConsultoriasTecnicas?: 7;
    AuditoriaObrasServicosEngenharia?: 8;
    ConsertoInstalacaoManutencaoEm?: (10 | 9 | 13 | 3 | 5 | 11 | 21 | 14 | 16 | 2 | 6 | 7)[];
    ConservacaoReparacaoManutencaoDe?: (18 | 17 | 20 | 8 | 4 | 12 | 19 | 1 | 22 | 15)[];
    ConstrucaoReformaAmpliacaoDe?: (18 | 17 | 20 | 8 | 4 | 12 | 19 | 1 | 22 | 15)[];
    ElaboracaoProjetoBasicoProjetoExecutivo?: 5;
    EnsaiosTecnologicos?: 10;
    EstudosImpactoAmbiental?: 9;
    EstudosViabilidadeTecnicaEconomica?: 4;
    LevantamentoAerofotogrametrico?: 12;
    LevantamentosTopograficosBatimetricosGeodesicos?: 11;
    Outros?: 14;
    PericiasAvaliacoes?: 6;
    SondagensOutrosProcedimentosInvestigacaoGeotecnica?: 13;
};
export type Audesp4TipoObraServicoEngOpcoes = {
    AssessoriasConsultoriasTecnicas: boolean;
    AuditoriaObrasServicosEngenharia: boolean;
    ConsertoInstalacaoManutencaoEm: {
        elevadores10: boolean;
        instalacaoEletrica9: boolean;
        paisagismo13: boolean;
        sinalizacao3: boolean;
        alarmes5: boolean;
        climatizacao11: boolean;
        incendio21: boolean;
        controleAcesso14: boolean;
        protecaoDescargas16: boolean;
        supervisao2: boolean;
        telefonia6: boolean;
        ventilacao7: boolean;
    };
    ConservacaoReparacaoManutencaoDe: {
        adultoras18: boolean;
        barragens17: boolean;
        edificacoes20: boolean;
        obrasSaneamento8: boolean;
        pontes4: boolean;
        rodovias12: boolean;
        tratamento19: boolean;
        trilho1: boolean;
        tuneis22: boolean;
        vias15: boolean;
    };
    ConstrucaoReformaAmpliacaoDe: {
        adultoras18: boolean;
        barragens17: boolean;
        edificacoes20: boolean;
        obrasSaneamento8: boolean;
        pontes4: boolean;
        rodovias12: boolean;
        tratamento19: boolean;
        trilho1: boolean;
        tuneis22: boolean;
        vias15: boolean;
    };
    ElaboracaoProjetoBasicoProjetoExecutivo: boolean;
    EnsaiosTecnologicos: boolean;
    EstudosImpactoAmbiental: boolean;
    EstudosViabilidadeTecnicaEconomica: boolean;
    LevantamentoAerofotogrametrico: boolean;
    LevantamentosTopograficosBatimetricosGeodesicos: boolean;
    Outros: boolean;
    PericiasAvaliacoes: boolean;
    SondagensOutrosProcedimentosInvestigacaoGeotecnica: boolean;
};
export type Audesp4PublicacaoAtoRatificacaoNao = {
    PublicacaoAtoRatificacao: 'S' | 'N';
    DataFinalizacaoProcesso: string;
};