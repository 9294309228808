import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MaterialService, SubGrupoEstoqueService } from 'almoxarifado-lib';
import { AliquotaService } from 'contabil-lib';
import {
  Aliquota,
  BaseResourceFormComponent, EddyAutoComplete, FuncaoService, GlobalService, LoginContabil, Material, SubGrupoEstoque, Tabela1Reinf
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'lib-material-form',
  templateUrl: './material-form.component.html'
})
export class MaterialFormComponent extends BaseResourceFormComponent<Material, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public editarCodigo: boolean = false;
  public subGrupoAutoComplete: EddyAutoComplete<SubGrupoEstoque>;
  public aliquotaAutoComplete: EddyAutoComplete<Aliquota>
  public mes: string;
  public gruposReinf: Array<any> = new Array<any>();
  public grupoReinf: any;

  @ViewChild('codigo_') inputField: ElementRef;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected materialService: MaterialService,
    protected tabela1ReinfService: Tabela1Reinf,
    protected subGrupoService: SubGrupoEstoqueService,
    protected aliquotaService: AliquotaService
  ) {
    super(new Material(), injector, Material.converteJson, materialService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      codigo: [null, [Validators.required]],
      nome: [null, [Validators.required]],
      sub_grupo: [null, [Validators.required]],
      servico: [false, [Validators.required]],
      locacao: [false, [Validators.required]],
      contabilizar: [false, [Validators.required]],
      exigir_placa: [false, [Validators.required]],
      exigir_incorporacao: [false, [Validators.required]],
      bec: [false, [Validators.required]],
      produtos: [],
      codigo_reinf: [null],
      orgao: [this.login.orgao, [Validators.required]],
      aliquota: [null]
    });
  }

  protected async podeAlterar(_entidade: Material): Promise<boolean> {
    return true;
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'sub_grupo.grupo,produtos,aliquota',
      servico: false
    };
  }

  protected afterLoad() {
    this.subGrupoAutoComplete.id = this.entidade.sub_grupo ? this.entidade.sub_grupo.id : null;
    this.carregarTabela1Reinf()
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
    this.carregarTabela1Reinf()
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected beforeSubmit() {
    try {
      if (this.entidadeForm.get('nome').value === '') {
        throw new Error('Informe o nome do material!');
      }
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(modelo: Material) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
  private carregarAutoCompletes() {
    this.subGrupoAutoComplete = new EddyAutoComplete(null, this.subGrupoService,
      'id', ['codigo', 'nome'], { orgao_id: this.login.orgao.id, relations: 'grupo', orderBy: 'nome' }, { number: ['codigo', 'codigo_comp'], text: ['nome'] },
      null, null, (entidade) => {
        return `${entidade.grupo.codigo}.${entidade.codigo} - ${entidade.nome}`;
      }
    );

    this.aliquotaAutoComplete = new EddyAutoComplete(this.entidadeForm.get('aliquota'), this.aliquotaService, 'id', ['id', 'resumo'], { ativo: true }, { number: ['id', 'codigo_receita'], text: ['resumo'] }, null, null, (entidade) => `${entidade.id} - ${entidade.resumo}`)
  }

  public selectSubGrupo() {
    if (this.currentActionRoute === 'novo') {
      this.materialService.proximoCodigo(this.entidadeForm.get('sub_grupo').value.id, false, this.login.orgao.id)
        .subscribe((data) => {
          this.entidadeForm.get('codigo').setValue(data);
        });
    }
  }

  public visualizaCodigo() {
    let codigo = this.entidadeForm.get('codigo').value;
    let subGrupo: SubGrupoEstoque = this.entidadeForm.get('sub_grupo').value;
    if (!codigo)
      return '00.00.00';
    return `${subGrupo.grupo.codigo}.${subGrupo.codigo}.${codigo}`
  }

  public carregarTabela1Reinf() {
    this.gruposReinf = this.tabela1ReinfService.carregarTabela1();
    if (this.entidade?.codigo_reinf) {
      this.grupoReinf = this.tabela1ReinfService.carregarPorCodigo(this.entidade.codigo_reinf, false);
      for (let i = 0; i < this.gruposReinf.length; i++) {
        if (this.gruposReinf[i].codigo == this.grupoReinf.codigo) {
          this.gruposReinf[i] = this.grupoReinf;
          break;
        }
      }
    }
  }
}
