import { Component, Input, ViewChild } from '@angular/core';
import { BaseResourceItemsComponent, EddyAutoComplete, Favorecido, FavorecidoService, FuncaoService, GlobalService, Rcms, RcmsFavorecido, RcmsItem } from 'eddydata-lib';
import { AutoComplete } from 'primeng/autocomplete';
import * as toastr from 'toastr';
import { RcmsFavorecidorService } from '../service/rcms-favorecido.service';


declare var $: any;

@Component({
  selector: 'app-rcms-favorecido',
  templateUrl: './rcms-favorecido.component.html'
})
export class RcmsFavorecidoComponent extends BaseResourceItemsComponent<RcmsFavorecido>{

  @Input() itens: RcmsItem[] = [];

  @Input() somenteVisualizar: boolean = false;

  @Input() rcms: Rcms;
  @Input() favorecidoConvenio?: Favorecido;

  @Input() usarFichaDespesa?: Boolean;

  @ViewChild('favorecido') favorecidoInput: AutoComplete;

  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;

  public favorecidoEdit: RcmsFavorecido;

  constructor(private favorecidoService: FavorecidoService,
    public funcaoService: FuncaoService,
    public globalService: GlobalService,
    public rcmsFavorecidoService: RcmsFavorecidorService) {
    super(new RcmsItem(), rcmsFavorecidoService);
  }

  protected campoFoco() {
    return this.favorecidoInput;
  }

  protected afterInit(): void {
    this.favorecidoEdit = new RcmsFavorecido();
    this.favorecidoEdit.favorecido = new Favorecido();
  }

  protected afterEdit(item: RcmsFavorecido) {
    this.carregarAutoCompletes();
  }

  protected validSave(item: RcmsFavorecido): boolean {
    if (!item?.favorecido?.id) {
      toastr.warning('Informe o fornecedor!');
      return false;
    }
    if (+item.favorecido.tipo.tce !== 1 && +item.favorecido.tipo.tce !== 2 && +item.favorecido.tipo.tce !== 10) {
      toastr.warning('Verifique a espécie no cadastro de fornecedor');
      return false; 
    }
    if (this.lista) {
      let duplicado = this.lista.find((l) => l.favorecido.id === item.favorecido.id && !l['editavel']);
      if (duplicado) {
        toastr.warning('Fornecedor ja vinculado!');
        return false;
      }
    }
    if(this.usarFichaDespesa && this.rcms.subelemento.codigo.substring(2, 4) === '91' && !item.favorecido.autarquia){
      toastr.warning('Elemento da despesa é 91 e o favorecido precisa ser uma autarquia!');
            return false;
    }
    return true;
  }

  protected validRemover(item: RcmsFavorecido): boolean {
    for (let rcmsItem of this.itens) {
      if (rcmsItem.cotacoes)
        for (let cotacao of rcmsItem.cotacoes) {
          if (cotacao.valor_unitario > 0 && cotacao?.rcmsFavorecido?.favorecido
            && cotacao.rcmsFavorecido.favorecido.id === item.favorecido.id) {
            toastr.warning(`Fornecedor vinculado a cotação, remova a cotação para alterar o fornecedor`);
            return false;
          }
        }
    }
    return true;
  }

  protected beforeSave(item: RcmsFavorecido): void { }


  public disabled(): boolean {
    if (!this.rcms.somente_produto && !this.rcms.somente_servico) {
      if (this.somenteVisualizar || (this.rcms?.contrato?.id) || !this.rcms.ficha?.despesa
        || !this.itens || this.itens.length === 0) {
        return true;
      }
    } else if ((this.rcms.somente_produto || this.rcms.somente_servico) && this.somenteVisualizar) {
      return true;
    }
    return super.disabled();
  }

  private carregarAutoCompletes() {
    let parametros = this.parametrosBusca();
    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['nome'], parametros, { number: ['id',], text: ['nome', 'cpf_cnpj'] });
  }


  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  public editarFavorecido(item: RcmsFavorecido) {
    this.favorecidoEdit = item;
    if (!this.favorecidoEdit.favorecido)
      this.favorecidoEdit.favorecido = new Favorecido();
    $('#dialogFavorecidoCadastro').modal('show');
  }

  public favorecidoAutorizado(item: RcmsFavorecido) {
    if (!item?.favorecido?.autorizado) {
      item.favorecido = null;
      toastr.error("Favorecido não esta autorizado, entre em contato com setor responsável");
    }
  }

  public callbackFavorecido(favorecido: Favorecido) {
    this.favorecidoEdit.favorecido = favorecido;
  }

  public salvarEnterEvent(event, item: RcmsFavorecido) {
    if (event.code === 'Enter') {
      this.salvar(item);
    } else if (this.login.parametro['compras'].bloqueio_razaosocial) {
      if (!this.funcaoService.isNumerico(event.key)) {
        let text: string = this.favorecidoInput.inputEL.nativeElement.value
        this.favorecidoInput.inputEL.nativeElement.value = text.replace(/[a-z]/i, '');
      }
    }
  }

  public parametrosBusca() {
    let parametros = { cidade_id: this.login.cidade.id, orderBy: 'nome', relations: 'tipo' };
    if (this.rcms?.ficha?.despesa?.n4 === '39')
      parametros['OR'] = `tipo.tce$like=1;!;!;tipo.tce$like=01`;
    if (this.rcms?.ficha?.despesa?.n4 === '36')
      parametros['OR'] = `tipo.tce$like=2;!;!;tipo.tce$like=02`;
    if (this.rcms?.processo) {
      parametros['proponentes.licitacao.processo'] = this.rcms.processo;
      parametros['proponentes.propostas.situacao'] = 'VENCEDOR';
    }
    return parametros;
  }
}