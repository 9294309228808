import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { TipoConvenio } from './tipo-convenio.model';
import { Convenio } from './convenio.model';


// tslint:disable: variable-name
export class ConvenioAlteracao extends BaseResourceModel {
  constructor(
    public data_alteracao?: Date,
    public numero?: string,
    public processo?: string,
    public data_inicio?: Date,
    public data_termino?: Date,
    public valor_convenio?: number,
    public valor_contrapartida?: number,
    public usuario?: Usuario,
    public convenio?: Convenio,
    public tipo_convenio?: TipoConvenio,
    public editavel?: boolean
    ) {
    super();
  }
  static converteJson(json: any): ConvenioAlteracao {
    return Object.assign(new ConvenioAlteracao(), json);
  }
}
