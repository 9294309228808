import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Compra, FuncaoService, GlobalService, LicitacaoPipe, LoginContabil } from 'eddydata-lib';

@Component({
  selector: 'app-compra-txt',
  templateUrl: './compra-txt.component.html'
})
export class CompraTxtComponent implements OnInit {

  private globalService: GlobalService;
  private funcaoService: FuncaoService;

  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Input() compra: Compra;

  public login: LoginContabil;

  // ========================================================================
  // ------------------------------ CONSTRUTOR ------------------------------
  // ========================================================================

  constructor() {
    this.login = GlobalService.obterSessaoLogin();
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
  }

  ngOnInit(): void { }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  public copiar() {
    this.funcaoService.copyToClipboard(this.montarTxt());
  }

  public montarTxt(): string {
    if (!this.compra)
      return null;

    const tipoCompra = this.globalService.obterListaTiposEmpenhos().find(x => x.id === this.compra.tipo_compra);

    let contaTexto = '';

    if (this.compra.favorecido.contas && this.compra.favorecido.contas.filter((c) => c.principal).length > 0) {
      const conta = this.compra.favorecido.contas.find((c) => c.principal);
      contaTexto = [
        `Banco: ${conta.banco?.febraban} - ${conta.banco?.nome}`,
        `Agência: ${conta.agencia ? conta.agencia : ''}`,
        `C/C: ${conta.numero_conta ? conta.numero_conta : ''}`
      ].join('\n');
    }

    const arrayTexto = [this.login.orgao.nome,
    `${this.login.orgao.endereco} - ${this.login.cidade.nome} - ${this.login.cidade.estado.nome} - CNPJ: ${this.login.orgao.cnpj}`,
    `ORDEM DE FORNECIMENTO Nº ${this.compra.numero}`,
    `Ficha: ${this.compra.ficha.numero}`,
    `Tipo: ${tipoCompra.nome}`,
    `Data: ${this.funcaoService.converteDataBR(this.compra.data_compra)}`,
    `Prazo: ${this.compra.prazo_entrega?.nome ? this.compra.prazo_entrega?.nome : ''}`,
    `Processo: ${this.compra.processo}`,
    `Licitação: ${this.compra.licitacao ? new LicitacaoPipe().transform(this.compra.licitacao?.numero) : ''}`,
    `Modalidade: ${this.compra.modalidade?.nome}`,
    // 'Ata de Registro: ',
    `Contrato: ${this.compra.contrato ? new LicitacaoPipe().transform(this.compra.contrato?.numero) : ''}`,
    `Previsão de Pagamento: ${this.compra.prazo_pagamento?.nome ? this.compra.prazo_pagamento?.nome : ''}`,
    `Setor: ${this.compra.rcms?.setor ? this.compra.rcms.setor.nome?.toUpperCase() : ''}`,
    `Unidade: ${this.compra.ficha?.executora?.unidade?.codigo} ${this.compra.ficha?.executora?.unidade?.nome?.toUpperCase()}`,
    `Executora: ${this.compra.ficha?.executora?.codigo} ${this.compra.ficha?.executora?.nome?.toUpperCase()}`,
    `Despesa: ${this.compra.ficha?.despesa?.codigo} ${this.compra.ficha?.despesa?.nome?.toUpperCase()}`,
    `Subelemento: ${this.compra.subelemento?.codigo} ${this.compra.subelemento?.nome?.toUpperCase()}`,
    `Recurso: ${this.compra.ficha?.recurso?.codigo} ${this.compra.ficha?.aplicacao?.codigo} ${this.compra.ficha?.aplicacao?.nome?.toUpperCase()}`,
    `Projeto: ${this.compra.ficha?.acao?.codigo} ${this.compra.ficha?.acao?.nome?.toUpperCase()}`,
    `Rcms: ${this.compra.rcms?.numero}`,
      '',
    `Fornecedor: ${this.compra.favorecido?.nome?.toUpperCase()}`,
    `CPF/CNPJ: ${this.compra.favorecido?.cpf_cnpj}`,
    `Endereço: ${this.compra.favorecido?.endereco?.toUpperCase()}`,
    `Bairro: ${this.compra.favorecido?.bairro?.toUpperCase()}`,
    `Cidade: ${this.compra.favorecido?.municipio?.toUpperCase()}`,
    `E-mail: ${this.compra.favorecido?.email?.toUpperCase()}`,
    `CEP: ${this.compra.favorecido?.cep}`,
    `Tipo: ${this.compra.favorecido?.tipo?.nome}`,
    `Fone: ${this.compra.favorecido?.telefone}`,
    `Código: ${this.compra.favorecido?.id}`,
    `Estado: ${this.compra.favorecido?.uf}`,
      // 'Fax: ',
      contaTexto,
      '',
    `${this.montarTxtItem()}`,
      '',
    `Obs: ${this.compra.observacao ? this.compra.observacao : ''}`,
      'Deverá constar na nota fiscal o número da ordem de fornecimento, ag. e a conta bancária para pagamento']

    return arrayTexto.join('\n');
  }


  private montarTxtItem(): string {

    let arrayTexto = [];

    let valor_total = 0;
    let valor_desconto = 0;
    let valor_icmsipi = 0;
    if (this.compra && this.compra?.itens) {
      for (let item of this.compra?.itens) {

        valor_total += +item.quantidade * +item.valor_unitario;
        valor_desconto += +item.valor_desconto;
        valor_icmsipi += +item.valor_icmsipi;

        arrayTexto = arrayTexto
          .concat([`Item ${item.ordem}:`,
          `Quantidade: ${this.funcaoService.convertToBrNumber(item.quantidade, 3)}`,
          `Vl. Unitário: ${this.funcaoService.convertToBrNumber(item.valor_unitario, 4)}`,
          `Valor: ${this.funcaoService.convertToBrNumber(+item.quantidade * +item.valor_unitario, 4)}`,
          `Descrição do item: ${item.produto_unidade?.unidade?.nome} - ${item.produto_unidade?.produto?.nome}`,
            '']);
      }

      arrayTexto = arrayTexto.concat([`SUB-TOTAL: ${this.funcaoService.convertToBrNumber(valor_total, 4)}`,
      `DESCONTO: ${this.funcaoService.convertToBrNumber(valor_desconto, 4)}`,
      `IPI/ICMS: ${this.funcaoService.convertToBrNumber(valor_icmsipi, 4)}`,
      `TOTAL GERAL: ${this.funcaoService.convertToBrNumber(valor_total + valor_icmsipi - valor_desconto, 4)}`]);
    }

    return arrayTexto.join('\n');
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

}