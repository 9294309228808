import { Component, OnInit, ViewChild, Input, SimpleChanges, AfterViewInit, OnDestroy, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { Calendar } from 'primeng/calendar';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import {
  LoginContabil, GlobalService, DateFormatPipe,
  MetaAvaliacao, MetaGoverno
} from 'eddydata-lib';
import { MetaAvaliacaoService } from 'administrativo-lib';

@Component({
  selector: 'app-meta-avaliacao-form',
  templateUrl: './meta-avaliacao-form.component.html'
})
export class MetaAvaliacaoFormComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @ViewChild('f') formGroup: any;

  public entity: MetaAvaliacao = new MetaAvaliacao();


  public listaAvaliacoes: Array<any>;

  protected unsubscribe: Subject<void> = new Subject();

  @Input() lista: Array<any>;
  @Input() entidade: MetaGoverno;
  @Input() login: LoginContabil;

  @ViewChild('InputFocus') public inputCodigo: Calendar;

  public ptBR: any;

  constructor(
    private messageService: MessageService,
    protected avaliacaoService: MetaAvaliacaoService,
  ) { }

  ngOnInit() {
    this.listaAvaliacoes = this.lista;
    this.ptBR = new GlobalService().obterDataBR();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lista) {
      this.listaAvaliacoes = this.lista;
    }
  }

  ngAfterViewInit() {
    new GlobalService().calendarMascara();
    if (this.entity) {
      setTimeout(() => this.inputCodigo ? this.inputCodigo.inputfieldViewChild.nativeElement.focus() : null, 2000);
    }
  }

  private load() {
    if (this.entidade.id) {
      this.avaliacaoService.filtrar(1, -1,
        { 'meta.id': this.entidade.id })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaAvaliacoes = res ? res.content : new Array<MetaAvaliacao>();
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  public adicionar() {
    this.entity = new MetaAvaliacao();
    this.entity.meta = this.entidade;
    if (!this.listaAvaliacoes) {
      this.listaAvaliacoes = new Array();
    }
    this.entity.editavel = true;
    this.listaAvaliacoes.unshift(this.entity);
    setTimeout(() => {
      this.inputCodigo.inputfieldViewChild.nativeElement.focus();
      new GlobalService().calendarMascara();
    }, 1000);
  }

  public async salvar(item: any) {
    try {
      if (!item.data_avaliacao) {
        throw new Error('Informe a data de avaliacao!');
      }

      if (!item.situacao) {
        throw new Error('Informe a situação do programa!');
      }

      if (!item.observacao) {
        this.entity.observacao = "";
      }

      this.entity.editavel = false;
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  public editar(item: MetaAvaliacao) {
    this.entity = item;
    this.entity.data_avaliacao = new DateFormatPipe().transform(this.entity.data_avaliacao, []);
    this.entity.editavel = true;
    setTimeout(() => {
      this.inputCodigo.inputfieldViewChild.nativeElement.focus();
      new GlobalService().calendarMascara();
    }, 1000);
  }

  public cancelar(index: number) {
    this.entity.editavel = false;
    this.listaAvaliacoes.splice(index, 1);
    this.load();
  }

  public remover(item: MetaAvaliacao, index: number) {
    if (item.id) {
      this.avaliacaoService
        .remover(item.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.listaAvaliacoes.splice(index, 1);
          this.entity.editavel = false;
          this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Exclusão', detail: error.error.payload });
          throw error;
        });
    }
  }

  protected async podeAlterar(_entidade: MetaGoverno): Promise<boolean> {
    return this.login.sistema != 'controle-interno'
  }
}
