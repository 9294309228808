// tslint:disable: variable-name
import { GrupoEstoque } from './grupo-estoque.model';
import { Estoque } from './estoque.model';
import { BaseResourceModel } from '../../models/base-resource.model';

export class GrupoAlmoxarifado extends BaseResourceModel {
  constructor(
    public id?: number,
    public grupo?: GrupoEstoque,
    public estoque?: Estoque,
    public editavel?: boolean,
  ) {
    super();
  }
  static converteJson(json: any): GrupoAlmoxarifado {
    return Object.assign(new GrupoAlmoxarifado(), json);
  }
}
