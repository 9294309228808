import { Banco } from '../banco';

export class BancoModelo extends Banco {
    public getNumero(): string {
        return '000';
    }

    private getCampo1(): string {
        const campo = this.getNumero() + this.boleto.getMoeda().toString() + this.boleto.getCodCliente().substring(0, 4);

        return this.boleto.getDigitoCampo(campo, 2);
    }

    private getCampo2(): string {
        const campo = this.boleto.getCodCliente().substring(4) + this.boleto.getNossoNumero().substring(0, 7);

        return this.boleto.getDigitoCampo(campo, 1);
    }

    private getCampo3(): string {
        const campo = this.boleto.getNossoNumero().substring(7) + this.boleto.getIOS() + this.boleto.getCarteira();

        return this.boleto.getDigitoCampo(campo, 1);
    }

    private getCampo4(): string {
        const campo = this.getNumero() + this.boleto.getMoeda().toString() +
            this.boleto.getFatorVencimento() + this.boleto.getValorTitulo() + '9' + this.boleto.getCodCliente() +
            this.boleto.getNossoNumero().toString() + this.boleto.getIOS() + this.boleto.getCarteira();

        return this.boleto.getDigitoCodigoBarras(campo);
    }

    /**
     * ver documentacao do banco para saber qual a ordem deste campo
     */
    private getCampo5(): string {
        const campo = this.boleto.getFatorVencimento() + this.boleto.getValorTitulo();
        return campo;
    }

    /**
     * Metodo para monta o desenho do codigo de barras
     * A ordem destes campos tambem variam de banco para banco, entao e so olhar na documentacao do seu banco
     * qual a ordem correta
     */
    public getCodigoBarras(): string {
        return this.getNumero() + this.boleto.getMoeda().toString() + this.getCampo4() + this.getCampo5() + '9' + this.boleto.getCodCliente() + '00000' + this.boleto.getNossoNumero() + this.boleto.getIOS() + this.boleto.getCarteira();
    }

    /**
     * Metodo que concatena os campo para formar a linha digitavel
     * E necessario tambem olhar a documentacao do banco para saber a ordem correta a linha digitavel
     */
    public getLinhaDigitavel(): string {
        return this.getCampo1().substring(0, 5) + '.' + this.getCampo1().substring(5) + '  ' +
            this.getCampo2().substring(0, 5) + '.' + this.getCampo2().substring(5) + '  ' +
            this.getCampo3().substring(0, 5) + '.' + this.getCampo3().substring(5) + '  ' +
            this.getCampo4() + '  ' + this.getCampo5();
    }

    /**
     * Recupera a carteira no padrao especificado pelo banco
     */
    public getCarteiraFormatted(): string {
        return this.boleto.getCarteira();
    }

    /**
     * Recupera a agencia / codigo cedente no padrao especificado pelo banco
     */
    public getAgenciaCodCedenteFormatted(): string {
        return this.boleto.getAgencia() + ' / ' + this.boleto.getContaCorrente() + '-' + this.boleto.getDvContaCorrente();
    }

    /**
     * Recupera o nossoNumero no padrao especificado pelo banco
     */
    public getNossoNumeroFormatted(): string {
        return Number.parseInt(this.boleto.getNossoNumero()).toString();
    }
}