import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AcaoGovernoRoutingModule } from './acao-governo-routing.module';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { IMaskModule } from 'angular-imask';
import { ToastModule } from 'primeng/toast';
import { MessageService, ConfirmationService } from 'primeng/api';
import { AcaoGovernoListComponent } from './acao-governo-list/acao-governo-list.component';
import { AcaoGovernoViewComponent } from './acao-governo-view/acao-governo-view.component';
import { AcaoGovernoFormComponent } from './acao-governo-form/acao-governo-form.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AcaoFonteRecursoFormComponent } from './acao-fonte-recurso-form/acao-fonte-recurso-form.component';
import { SharedModule } from 'eddydata-lib';
import { AcaoModule } from '../acao/acao.module';
import { TotalizadorAcaoDlgComponent } from './totalizador-acao-dlg/totalizador-acao-dlg.component';

@NgModule({
  declarations: [AcaoGovernoListComponent, AcaoGovernoViewComponent, AcaoGovernoFormComponent, AcaoFonteRecursoFormComponent, TotalizadorAcaoDlgComponent],
  imports: [
    CommonModule,
    AcaoGovernoRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    ConfirmDialogModule,
    AcaoModule
  ],
  providers: [MessageService, ConfirmationService]
})
export class AcaoGovernoModule { }
