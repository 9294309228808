import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class Seguradora extends BaseResourceModel {
  constructor(
    public nome?: string,
    public cep?: string,
    public endereco?: string,
    public bairro?: string,
    public numero?: string,
    public uf?: string,
    public cidade?: string,
    public telefone?: string,
    public celular?: string,
    public nome_contato?: string,
    public tipo_seguro?: string,
    public orgao?: Orgao,
  ) {
    super();
  }

  static converteJson(json: any): Seguradora {
    return Object.assign(new Seguradora(), json);
  }
}
