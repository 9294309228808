import { HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, GlobalService, LicitacaoAudesp, LicitacaoAudespPacote, LicitacaoStorage, Login } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LicitacaoAudespService extends BaseResourceService<LicitacaoAudesp> {

    constructor(
        protected injector: Injector
    ) {
        super(`licitacoes-audesp`, injector);
    }

    public listarTipoReceita(): { id: number, nome: string }[] {
        return [
            { id: 11000000, nome: 'RECEITA TRIBUTÁRIA' },
            { id: 12000000, nome: 'RECEITA DE CONTRIBUIÇÕES' },
            { id: 13000000, nome: 'Receita Patrimonial' },
            { id: 14000000, nome: 'Receita Agropecuária' },
            { id: 15000000, nome: 'Receita Industrial' },
            { id: 16000000, nome: 'Receita de Serviços' },
            { id: 17000000, nome: 'Transferências Correntes' },
            { id: 19000000, nome: 'Outras Receitas Correntes' },
            { id: 21000000, nome: 'Operações de Crédito' },
            { id: 22000000, nome: 'Alienação de Bens' },
            { id: 23000000, nome: 'Amortização de Empréstimos' },
            { id: 24000000, nome: 'Transferências de Capital' },
            { id: 29000000, nome: 'Outras Receitas de Capital' },
            { id: 71000000, nome: 'Impostos, Taxas e Contribuições de Melhoria - Intra OFSS' },
            { id: 72000000, nome: 'Contribuições - Intra OFSS' },
            { id: 73000000, nome: 'Receita Patrimonial - Intra OFSS' },
            { id: 74000000, nome: 'Receita Agropecuária - Intra OFSS' },
            { id: 75000000, nome: 'Receita Industrial - Intra OFSS' },
            { id: 76000000, nome: 'Receita de Serviços - Intra OFSS' },
            { id: 77000000, nome: 'Transferências Correntes - Intra OFSS' },
            { id: 79000000, nome: 'Outras Receitas Correntes - Intra OFSS' },
            { id: 81000000, nome: 'Operações de Crédito - Intra OFSS' },
            { id: 82000000, nome: 'Alienação de Bens - Intra OFSS' },
            { id: 83000000, nome: 'Amortização de Empréstimos - Intra OFSS' },
            { id: 84000000, nome: 'Transferências de Capital - Intra OFSS' },
            { id: 88888888, nome: 'OUTROS' },
            { id: 89000000, nome: 'Outras Receitas de Capital - Intra OFSS' },
        ]
    }

    public listarTipoDespesa(): { id: number, nome: string }[] {
        return [
            { id: 31304100, nome: 'CONTRIBUIÇÕES' },
            { id: 31704100, nome: 'CONTRIBUIÇÕES' },
            { id: 31717000, nome: 'RATEIO PELA PARTICIPAÇÃO EM CONSÓRCIO PÚBLICO' },
            { id: 31737000, nome: 'RATEIO PELA PARTICIPAÇÃO EM CONSÓRCIO PÚBLICO  -  §§ 1O E 2O DO ART. 24 DA LEI COMPLEMENTAR NO 141, DE 2012' },
            { id: 31747000, nome: 'RATEIO PELA PARTICIPAÇÃO EM CONSÓRCIO PÚBLICO  - ART. 25 DA LEI COMPLEMENTAR NO 141, DE 2012.' },
            { id: 31800400, nome: 'CONTRATAÇÃO POR TEMPO DETERMINADO' },
            { id: 31900100, nome: 'APOSENTADORIAS, RESERVA REMUNERADA E REFORMAS' },
            { id: 31900300, nome: 'PENSÕES DO RPPS E DO MILITAR' },
            { id: 31900400, nome: 'CONTRATAÇÃO POR TEMPO DETERMINADO' },
            { id: 31900700, nome: 'CONTRIBUIÇÕES A ENTIDADES FECHADAS DE PREVIDÊNCIA' },
            { id: 31900800, nome: 'OUTROS BENEFÍCIOS ASSISTENCIAIS' },
            { id: 31900900, nome: 'SALÁRIO FAMÍLIA' },
            { id: 31901100, nome: 'VENCIMENTOS E VANTAGENS FIXAS -  PESSOAL CIVIL' },
            { id: 31901300, nome: 'OBRIGAÇÕES PATRONAIS' },
            { id: 31901600, nome: 'OUTRAS DESPESAS VARIÁVEIS - PESSOAL CIVIL' },
            { id: 31906700, nome: 'DEPÓSITOS COMPULSÓRIOS' },
            { id: 31909100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 31909200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 31909400, nome: 'INDENIZAÇÕES E RESTITUIÇÕES TRABALHISTAS' },
            { id: 31909500, nome: 'INDENIZAÇÕES PELA EXECUÇÃO DE TRABALHOS DE CAMPO' },
            { id: 31909600, nome: 'RESSARCIMENTO DE DESPESAS DE PESSOAL REQUISITADO' },
            { id: 31910400, nome: 'CONTRATAÇÃO POR TEMPO DETERMINADO - INTRA-ORÇAMENTÁRIO' },
            { id: 31910700, nome: 'CONTRIBUIÇÕES A ENTIDADES FECHADAS DE PREVIDÊNCIA - INTRA-ORÇAMENTÁRIO' },
            { id: 31911300, nome: 'OBRIGAÇÕES PATRONAIS - INTRA-ORÇAMENTÁRIO' },
            { id: 31911600, nome: 'OUTRAS DESPESAS VARIÁVEIS - PESSOAL CIVIL - INTRA-ORÇAMENTÁRIO' },
            { id: 31916700, nome: 'DEPÓSITOS COMPULSÓRIOS - INTRA-ORÇAMENTÁRIO' },
            { id: 31919100, nome: 'SENTENÇAS JUDICIAIS - INTRA-ORÇAMENTÁRIO' },
            { id: 31919200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES - INTRA-ORÇAMENTÁRIO' },
            { id: 31919400, nome: 'INDENIZAÇÕES TRABALHISTAS - INTRA-ORÇAMENTÁRIO' },
            { id: 31919500, nome: 'INDENIZAÇÕES PELA EXECUÇÃO DE TRABALHOS DE CAMPO - INTRA-ORÇAMENTÁRIO' },
            { id: 31919600, nome: 'RESSARCIMENTO DE DESPESAS DE PESSOAL REQUISITADO - INTRA-ORÇAMENTÁRIO' },
            { id: 31950400, nome: 'CONTRATAÇÃO POR TEMPO DETERMINADO' },
            { id: 31950700, nome: 'CONTRIBUIÇÃO A ENTIDADES FECHADAS DE PREVIDÊNCIA' },
            { id: 31951100, nome: 'VENCIMENTOS E VANTAGENS FIXAS - PESSOAL CIVIL' },
            { id: 31951300, nome: 'OBRIGAÇÕES PATRONAIS' },
            { id: 31951600, nome: 'OUTRAS DESPESAS VARIÁVEIS - PESSOAL CIVIL' },
            { id: 31956700, nome: 'DEPÓSITOS COMPULSÓRIOS' },
            { id: 31959100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 31959200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 31959400, nome: 'INDENIZAÇÕES E RESTITUIÇÕES TRABALHISTAS' },
            { id: 31959600, nome: 'RESSARCIMENTO DE DESPESAS DE PESSOAL REQUISITADO' },
            { id: 31960400, nome: 'CONTRATAÇÃO POR TEMPO DETERMINADO' },
            { id: 31960700, nome: 'CONTRIBUIÇÃO A ENTIDADES FECHADAS DE PREVIDÊNCIA' },
            { id: 31961100, nome: 'VENCIMENTOS E VANTAGENS FIXAS - PESSOAL CIVIL' },
            { id: 31961300, nome: 'OBRIGAÇÕES PATRONAIS' },
            { id: 31961600, nome: 'OUTRAS DESPESAS VARIÁVEIS - PESSOAL CIVIL' },
            { id: 31966700, nome: 'DEPÓSITOS COMPULSÓRIOS' },
            { id: 31969100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 31969200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 31969400, nome: 'INDENIZAÇÕES E RESTITUIÇÕES TRABALHISTAS' },
            { id: 31969600, nome: 'RESSARCIMENTO DE DESPESAS DE PESSOAL REQUISITADO' },
            { id: 32717000, nome: 'RATEIO PELA PARTICIPAÇÃO EM CONSÓRCIO PÚBLICO' },
            { id: 32737000, nome: 'RATEIO PELA PARTICIPAÇÃO EM CONSÓRCIO PÚBLICO  -  §§ 1O E 2O DO ART. 24 DA LEI COMPLEMENTAR NO 141, DE 2012' },
            { id: 32747000, nome: 'RATEIO PELA PARTICIPAÇÃO EM CONSÓRCIO PÚBLICO  - ART. 25 DA LEI COMPLEMENTAR NO 141, DE 2012.' },
            { id: 32902100, nome: 'JUROS SOBRE A DÍVIDA POR CONTRATO' },
            { id: 32902200, nome: 'OUTROS ENCARGOS SOBRE A DÍVIDA POR CONTRATO' },
            { id: 32902300, nome: 'JUROS, DESÁGIOS E DESCONTOS DA DÍVIDA MOBILIÁRIA' },
            { id: 32902400, nome: 'OUTROS ENCARGOS SOBRE A DÍVIDA MOBILIÁRIA' },
            { id: 32902500, nome: 'ENCARGOS SOBRE OPERAÇÕES DE CRÉDITO POR ANTECIPAÇÃO DA RECEITA' },
            { id: 32909100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 32909200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 32909300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 32912100, nome: 'JUROS SOBRE A DÍVIDA POR CONTRATO - INTRA-ORÇAMENTÁRIO' },
            { id: 32912200, nome: 'OUTROS ENCARGOS SOBRE A DÍVIDA POR CONTRATO - INTRA-ORÇAMENTÁRIO' },
            { id: 32952100, nome: 'JUROS SOBRE A DÍVIDA POR CONTRATO' },
            { id: 32952200, nome: 'OUTROS ENCARGOS SOBRE A DÍVIDA POR CONTRATO' },
            { id: 32952300, nome: 'JUROS, DESÁGIOS E DESCONTOS DA DÍVIDA MOBILIÁRIA' },
            { id: 32952400, nome: 'OUTROS ENCARGOS SOBRE A DÍVIDA MOBILIÁRIA' },
            { id: 32952500, nome: 'ENCARGOS SOBRE OPERAÇÕES DE CRÉDITO POR ANTECIPAÇÃO DA RECEITA' },
            { id: 32959100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 32959200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 32959300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 32962100, nome: 'JUROS SOBRE A DÍVIDA POR CONTRATO' },
            { id: 32962200, nome: 'OUTROS ENCARGOS SOBRE A DÍVIDA POR CONTRATO' },
            { id: 32962300, nome: 'JUROS, DESÁGIOS E DESCONTOS DA DÍVIDA MOBILIÁRIA' },
            { id: 32962400, nome: 'OUTROS ENCARGOS SOBRE A DÍVIDA MOBILIÁRIA' },
            { id: 32962500, nome: 'ENCARGOS SOBRE OPERAÇÕES DE CRÉDITO POR ANTECIPAÇÃO DA RECEITA' },
            { id: 32969100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 32969200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 32969300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 33200100, nome: 'APOSENTADORIAS, RESERVA REMUNERADA E REFORMAS' },
            { id: 33200300, nome: 'PENSÕES' },
            { id: 33204100, nome: 'CONTRIBUIÇÕES' },
            { id: 33209200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 33221400, nome: 'DIÁRIAS CIVIL' },
            { id: 33223000, nome: 'MATERIAL DE CONSUMO' },
            { id: 33223500, nome: 'SERVIÇOS DE CONSULTORIA' },
            { id: 33223600, nome: 'SERVIÇOS DE TERCEIROS - PESSOA FÍSICA' },
            { id: 33223900, nome: 'SERVIÇOS DE TERCEIROS - PESSOA JURÍDICA' },
            { id: 33300100, nome: 'Aposentadorias, Reserva Remunerada e Reformas' },
            { id: 33300300, nome: 'Pensões' },
            { id: 33303000, nome: 'MATERIAL DE CONSUMO' },
            { id: 33304100, nome: 'CONTRIBUIÇÕES' },
            { id: 33304300, nome: 'SUBVENÇÕES SOCIAIS' },
            { id: 33309200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 33309300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES ' },
            { id: 33314100, nome: 'CONTRIBUIÇÕES' },
            { id: 33319200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 33321400, nome: 'DIÁRIAS - CIVIL' },
            { id: 33321800, nome: 'AUXÍLIO FINANCEIRO A ESTUDANTES' },
            { id: 33322000, nome: 'AUXÍLIO FINANCEIRO A PESQUISADORES' },
            { id: 33323000, nome: 'MATERIAL DE CONSUMO' },
            { id: 33323200, nome: 'MATERIAL, BEM OU SERVIÇO PARA DISTRIBUIÇÃO GRATUITA' },
            { id: 33323300, nome: 'PASSAGENS E DESPESAS COM LOCOMOÇÃO' },
            { id: 33323500, nome: 'SERVIÇOS DE CONSULTORIA' },
            { id: 33323600, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA FÍSICA' },
            { id: 33323900, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA JURÍDICA' },
            { id: 33324700, nome: 'OBRIGAÇÕES TRIBUTÁRIAS E CONTRIBUTIVAS' },
            { id: 33329200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 33329300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 33354100, nome: 'CONTRIBUIÇÕES' },
            { id: 33359200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 33364100, nome: 'CONTRIBUIÇÕES' },
            { id: 33369200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 33400100, nome: 'Aposentadorias, Reserva Remunerada e Reformas' },
            { id: 33400300, nome: 'Pensões' },
            { id: 33403000, nome: 'MATERIAL DE CONSUMO' },
            { id: 33404100, nome: 'CONTRIBUIÇÕES' },
            { id: 33404300, nome: 'SUBVENÇÕES SOCIAIS' },
            { id: 33409100, nome: 'SENTENÇAS JUDICIAIS ' },
            { id: 33409200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 33409300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES ' },
            { id: 33413000, nome: 'MATERIAL DE CONSUMO' },
            { id: 33413900, nome: 'OUTROS SERVICOS DE TERCEIROS-PESSOA JURIDICA' },
            { id: 33414100, nome: 'CONTRIBUIÇÕES ' },
            { id: 33419200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES ' },
            { id: 33421400, nome: 'DIÁRIAS - CIVIL' },
            { id: 33421800, nome: 'AUXÍLIO FINANCEIRO A ESTUDANTES' },
            { id: 33423000, nome: 'MATERIAL DE CONSUMO' },
            { id: 33423300, nome: 'PASSAGENS E DESPESAS COM LOCOMOÇÃO' },
            { id: 33423500, nome: 'SERVIÇOS DE CONSULTORIA' },
            { id: 33423600, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA FÍSICA' },
            { id: 33423900, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA JURÍDICA' },
            { id: 33424700, nome: 'OBRIGAÇÕES TRIBUTÁRIAS E CONTRIBUTIVAS' },
            { id: 33429200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 33429300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 33454100, nome: 'CONTRIBUIÇÕES' },
            { id: 33459100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 33459200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 33464100, nome: 'CONTRIBUIÇÕES' },
            { id: 33469100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 33469200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 33501400, nome: 'DIÁRIAS' },
            { id: 33501800, nome: 'AUXÍLIO FINANCEIRO A ESTUDANTES' },
            { id: 33502000, nome: 'AUXÍLIO FINANCEIRO A PESQUISADORES' },
            { id: 33503000, nome: 'MATERIAL DE CONSUMO' },
            { id: 33503100, nome: 'PREMIAÇÕES CULTURAIS, ARTÍSTICAS, CIENTÍFICAS, DESPORTIVAS E OUTRAS' },
            { id: 33503300, nome: 'PASSAGENS E DESPESAS COM LOCOMOÇÃO' },
            { id: 33503500, nome: 'SERVIÇOS DE CONSULTORIA' },
            { id: 33503600, nome: 'OUTROS SERVIÇOS DE TERCEIROS -  PESSOA FÍSICA' },
            { id: 33503900, nome: 'OUTROS SERVIÇOS DE TERCEIROS -PESSOA JURÍDICA' },
            { id: 33504100, nome: 'CONTRIBUIÇÕES' },
            { id: 33504300, nome: 'SUBVENÇÕES SOCIAIS' },
            { id: 33504700, nome: 'OBRIGAÇÕES TRIBUTÁRIAS E CONTRIBUTIVAS' },
            { id: 33509200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 33604100, nome: 'CONTRIBUIÇÕES' },
            { id: 33604500, nome: 'SUBVENÇÕES ECONÔMICAS' },
            { id: 33609200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 33609300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 33674500, nome: 'DESPESAS CORRENTES DECORRENTES DE CONTRATO DE PPP' },
            { id: 33678200, nome: 'APORTE DE RECURSOS PELO PARCEIRO PÚBLICO EM FAVOR DO PARCEIRO PRIVADO DECORRENTE DE CONTRATO DE PARCERIA PÚBLICO-PRIVADA - PPP' },
            { id: 33678300, nome: 'CONTRATO DE PPP, EXCETO SUBVENÇÕES ECONÔMICAS, APORTE E FUNDO GARANTIDOR' },
            { id: 33704100, nome: 'CONTRIBUIÇÕES' },
            { id: 33710400, nome: 'CONTRATAÇÃO POR TEMPO DETERMINADO - PESSOAL CIVIL - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 33710500, nome: 'OUTROS BENEFÍCIOS PREVIDENCIÁRIOS - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 33711300, nome: 'OBRIGAÇÕES PATRONAIS - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 33711400, nome: 'DIÁRIAS - PESSOAL CIVIL - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 33713000, nome: 'MATERIAL DE CONSUMO - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 33713300, nome: 'PASSAGENS E DESPESAS COM LOCOMOÇÃO - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 33713500, nome: 'SERVIÇOS DE CONSULTORIA - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 33713600, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA FÍSICA - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 33713700, nome: 'LOCAÇÃO DE MÃO-DE-OBRA - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 33713800, nome: 'ARRENDAMENTO MERCANTIL - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 33713900, nome: 'OUTROS SERVIÇOS DE TERCEIROS -  PESSOA JURÍDICA - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 33714600, nome: 'AUXÍLIO ALIMENTAÇÃO - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 33714700, nome: 'OBRIGAÇÕES TRIBUTÁRIAS E CONTRIBUTIVAS - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 33714900, nome: 'AUXÍLIO TRANSPORTE - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 33717000, nome: 'RATEIO PELA PARTICIPAÇÃO EM CONSÓRCIO PÚBLICO' },
            { id: 33719100, nome: 'SENTENÇAS JUDICIAIS - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 33719200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 33719300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 33721400, nome: 'DIÁRIAS - CIVIL' },
            { id: 33721800, nome: 'AUXÍLIO FINANCEIRO A ESTUDANTES' },
            { id: 33723000, nome: 'MATERIAL DE CONSUMO' },
            { id: 33723300, nome: 'PASSAGENS E DESPESAS COM LOCOMOÇÃO' },
            { id: 33723500, nome: 'SERVIÇOS DE CONSULTORIA' },
            { id: 33723600, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA FÍSICA' },
            { id: 33723900, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA JURÍDICA' },
            { id: 33724700, nome: 'OBRIGAÇÕES TRIBUTÁRIAS E CONTRIBUTIVAS' },
            { id: 33729200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 33729300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 33737000, nome: 'RATEIO PELA PARTICIPAÇÃO EM CONSÓRCIO PÚBLICO' },
            { id: 33747000, nome: 'RATEIO PELA PARTICIPAÇÃO EM CONSÓRCIO PÚBLICO' },
            { id: 33754100, nome: 'CONTRIBUIÇÕES' },
            { id: 33764100, nome: 'CONTRIBUIÇÕES' },
            { id: 33800400, nome: 'CONTRATAÇÃO POR TEMPO DETERMINADO' },
            { id: 33801400, nome: 'DIÁRIAS - PESSOAL CIVIL' },
            { id: 33803000, nome: 'MATERIAL DE CONSUMO' },
            { id: 33803300, nome: 'PASSAGENS E DESPESAS COM LOCOMOÇÃO' },
            { id: 33803400, nome: 'OUTRAS DESPESAS DE PESSOAL DECORRENTES DE CONTRATOS DE TERCEIRIZAÇÃO' },
            { id: 33803500, nome: 'SERVIÇOS DE CONSULTORIA' },
            { id: 33803600, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA FÍSICA' },
            { id: 33803700, nome: 'LOCAÇÃO DE MÃO-DE-OBRA' },
            { id: 33803900, nome: 'OUTROS SERVIÇOS DE TERCEIROS -PESSOA JURÍDICA' },
            { id: 33804100, nome: 'CONTRIBUIÇÕES' },
            { id: 33809200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 33900400, nome: 'CONTRATAÇÃO POR TEMPO DETERMINADO - PESSOAL CIVIL' },
            { id: 33900600, nome: 'BENEFÍCIO MENSAL AO DEFICIENTE E AO IDOSO' },
            { id: 33900800, nome: 'OUTROS BENEFÍCIOS ASSISTENCIAIS DO SERVIDOR E DO MILITAR' },
            { id: 33900900, nome: 'SALÁRIO FAMÍLIA' },
            { id: 33901000, nome: 'SEGURO DESEMPREGO E ABONO SALARIAL' },
            { id: 33901400, nome: 'DIÁRIAS - PESSOAL CIVIL' },
            { id: 33901800, nome: 'AUXÍLIO FINANCEIRO A ESTUDANTE' },
            { id: 33901900, nome: 'AUXÍLIO FARDAMENTO' },
            { id: 33902000, nome: 'AUXÍLIO FINANCEIRO A PESQUISADORES' },
            { id: 33902700, nome: 'ENCARGOS PELA HONRA DE AVAIS, GARANTIAS, SEGUROS E SIMILARES' },
            { id: 33902800, nome: 'REMUNERAÇÃO DE COTAS DE FUNDOS AUTÁRQUICOS' },
            { id: 33902900, nome: 'DISTRIBUIÇÃO DE RESULTADO DE EMPRESAS ESTATAIS DEPENDENTES' },
            { id: 33903000, nome: 'MATERIAL DE CONSUMO' },
            { id: 33903100, nome: 'PREMIAÇÕES CULTURAIS, ARTÍSTICAS, CIENTÍFICAS, DESPORTIVAS E OUTRAS' },
            { id: 33903200, nome: 'MATERIAL, BEM OU SERVIÇO PARA DISTRIBUIÇÃO GRATUITA' },
            { id: 33903300, nome: 'PASSAGENS E DESPESAS COM LOCOMOÇÃO' },
            { id: 33903400, nome: 'OUTRAS DESPESAS DE PESSOAL DECORRENTES DE CONTRATOS DE TERCEIRIZAÇÃO' },
            { id: 33903500, nome: 'SERVIÇOS DE CONSULTORIA' },
            { id: 33903600, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA FÍSICA' },
            { id: 33903700, nome: 'LOCAÇÃO DE MÃO-DE-OBRA' },
            { id: 33903800, nome: 'ARRENDAMENTO MERCANTIL' },
            { id: 33903900, nome: 'OUTROS SERVIÇOS DE TERCEIROS -  PESSOA JURÍDICA' },
            { id: 33904000, nome: 'SERVIÇOS DE TECNOLOGIA DA INFORMAÇÃO E COMUNICAÇÃO - PJ' },
            { id: 33904100, nome: 'CONTRIBUIÇÕES' },
            { id: 33904500, nome: 'SUBVENÇÕES ECONÔMICAS' },
            { id: 33904600, nome: 'AUXÍLIO ALIMENTAÇÃO' },
            { id: 33904700, nome: 'OBRIGAÇÕES TRIBUTÁRIAS E CONTRIBUTIVAS' },
            { id: 33904800, nome: 'OUTROS AUXÍLIOS FINANCEIROS A PESSOA FÍSICA' },
            { id: 33904900, nome: 'AUXÍLIO TRANSPORTE' },
            { id: 33905000, nome: 'SERVICOS DE UTILIDADE PUBLICA' },
            { id: 33905900, nome: 'PENSÕES ESPECIAIS ' },
            { id: 33906700, nome: 'DEPÓSITOS COMPULSÓRIOS' },
            { id: 33908300, nome: 'CONTRATO DE PPP, EXCETO SUBVENÇÕES ECONÔMICAS, APORTE E FUNDO GARANTIDOR' },
            { id: 33908800, nome: 'DESPESAS C/ TECNOLOGIA E INFORMATICA' },
            { id: 33909100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 33909200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 33909300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 33909500, nome: 'INDENIZAÇÃO PELA EXECUÇÃO DE  TRABALHOS DE CAMPO' },
            { id: 33910400, nome: 'CONTRATAÇÃO POR TEMPO DETERMINADO ' },
            { id: 33912800, nome: 'REMUNERAÇÃO DE COTAS DE FUNDOS AUTÁRQUICOS - INTRA-ORÇAMENTÁRIO' },
            { id: 33912900, nome: 'DISTRIBUIÇÃO DE RESULTADO DE EMPRESAS ESTATAIS DEPENDENTES' },
            { id: 33913000, nome: 'MATERIAL DE CONSUMO - INTRA-ORÇAMENTÁRIO' },
            { id: 33913100, nome: 'PREMIAÇÕES CULTURAIS, ARTÍSTICAS, CIENTÍFICAS, DESPORTIVAS E OUTRAS - INTRA-ORÇAMENTÁRIO' },
            { id: 33913200, nome: 'MATERIAL, BEM OU SERVIÇO PARA DISTRIBUIÇÃO GRATUITA - INTRA-ORÇAMENTÁRIO' },
            { id: 33913500, nome: 'SERVIÇOS DE CONSULTORIA - INTRA-ORÇAMENTÁRIO' },
            { id: 33913600, nome: 'OUTROS SERVICOS DE TERCEIROS - PESSOA FISICA' },
            { id: 33913700, nome: 'LOCAÇÃO DE MÃO-DE-OBRA - INTRA-ORÇAMENTÁRIO' },
            { id: 33913800, nome: 'ARRENDAMENTO MERCANTIL - INTRA-ORÇAMENTÁRIO' },
            { id: 33913900, nome: 'OUTROS SERVIÇOS DE TERCEIROS -  PESSOA JURÍDICA - INTRA-ORÇAMENTÁRIO' },
            { id: 33914700, nome: 'OBRIGAÇÕES TRIBUTÁRIAS E CONTRIBUTIVAS - INTRA-ORÇAMENTÁRIO' },
            { id: 33915000, nome: 'SERVICOS DE UTILIDADE PUBLICA' },
            { id: 33916200, nome: 'AQUISIÇÃO DE PRODUTOS PARA REVENDA' },
            { id: 33916700, nome: 'DEPÓSITOS COMPULSÓRIOS - INTRA-ORÇAMENTÁRIO' },
            { id: 33918800, nome: 'DESPESAS C/ TECNOLOGIA DA INFORMACAO' },
            { id: 33919100, nome: 'SENTENÇAS JUDICIAIS - INTRA-ORÇAMENTÁRIO' },
            { id: 33919200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES - INTRA-ORÇAMENTÁRIO' },
            { id: 33919300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES - INTRA-ORÇAMENTÁRIO' },
            { id: 33919500, nome: 'INDENIZAÇÃO PELA EXECUÇÃO DE  TRABALHOS DE CAMPO - INTRA-ORÇAMENTÁRIO' },
            { id: 33919600, nome: 'RESSARCIMENTO DE DESPESAS DE PESSOAL REQUISITADO ' },
            { id: 33919700, nome: 'APORTE PARA COBERTURA DO DÉFICIT ATUARIAL DO RPPS' },
            { id: 33933000, nome: 'MATERIAL DE CONSUMO' },
            { id: 33933200, nome: 'MATERIAL, BEM OU SERVIÇO PARA DISTRIBUIÇÃO GRATUITA' },
            { id: 33933900, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA JURÍDICA' },
            { id: 33943000, nome: 'MATERIAL DE CONSUMO' },
            { id: 33943200, nome: 'MATERIAL, BEM OU SERVIÇO PARA DISTRIBUIÇÃO GRATUITA' },
            { id: 33943900, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA JURÍDICA' },
            { id: 33950400, nome: 'CONTRATAÇÃO POR TEMPO DETERMINADO' },
            { id: 33950800, nome: 'OUTROS BENEFÍCIOS ASSISTENCIAIS DO SERVIDOR E DO MILITAR' },
            { id: 33951400, nome: 'DIÁRIAS - CIVIL' },
            { id: 33951800, nome: 'AUXÍLIO FINANCEIRO A ESTUDANTES' },
            { id: 33952000, nome: 'AUXÍLIO FINANCEIRO A PESQUISADORES' },
            { id: 33953000, nome: 'MATERIAL DE CONSUMO' },
            { id: 33953100, nome: 'PREMIAÇÕES CULTURAIS, ARTÍSTICAS, CIENTÍFICAS, DESPORTIVAS E OUTRAS' },
            { id: 33953200, nome: 'MATERIAL, BEM OU SERVIÇO PARA DISTRIBUIÇÃO GRATUITA' },
            { id: 33953300, nome: 'PASSAGENS E DESPESAS COM LOCOMOÇÃO' },
            { id: 33953400, nome: 'OUTRAS DESPESAS DE PESSOAL DECORRENTES DE CONTRATOS DE TERCEIRIZAÇÃO' },
            { id: 33953500, nome: 'SERVIÇOS DE CONSULTORIA' },
            { id: 33953600, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA FÍSICA' },
            { id: 33953700, nome: 'LOCAÇÃO DE MÃO-DE-OBRA' },
            { id: 33953800, nome: 'ARRENDAMENTO MERCANTIL' },
            { id: 33953900, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA JURÍDICA' },
            { id: 33954100, nome: 'CONTRIBUIÇÕES' },
            { id: 33954500, nome: 'SUBVENÇÕES ECONÔMICAS' },
            { id: 33954600, nome: 'AUXÍLIO-ALIMENTAÇÃO' },
            { id: 33954700, nome: 'OBRIGAÇÕES TRIBUTÁRIAS E CONTRIBUTIVAS' },
            { id: 33954800, nome: 'OUTROS AUXÍLIOS FINANCEIROS A PESSOAS FÍSICAS' },
            { id: 33954900, nome: 'AUXÍLIO-TRANSPORTE' },
            { id: 33956700, nome: 'DEPÓSITOS COMPULSÓRIOS' },
            { id: 33959100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 33959200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 33959300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 33959600, nome: 'RESSARCIMENTO DE DESPESAS DE PESSOAL REQUISITADO' },
            { id: 33960400, nome: 'CONTRATAÇÃO POR TEMPO DETERMINADO' },
            { id: 33960800, nome: 'OUTROS BENEFÍCIOS ASSISTENCIAIS DO SERVIDOR E DO MILITAR' },
            { id: 33961400, nome: 'DIÁRIAS - CIVIL' },
            { id: 33961800, nome: 'AUXÍLIO FINANCEIRO A ESTUDANTES' },
            { id: 33962000, nome: 'AUXÍLIO FINANCEIRO A PESQUISADORES' },
            { id: 33963000, nome: 'MATERIAL DE CONSUMO' },
            { id: 33963100, nome: 'PREMIAÇÕES CULTURAIS, ARTÍSTICAS, CIENTÍFICAS, DESPORTIVAS E OUTRAS' },
            { id: 33963200, nome: 'MATERIAL, BEM OU SERVIÇO PARA DISTRIBUIÇÃO GRATUITA' },
            { id: 33963300, nome: 'PASSAGENS E DESPESAS COM LOCOMOÇÃO' },
            { id: 33963400, nome: 'OUTRAS DESPESAS DE PESSOAL DECORRENTES DE CONTRATOS DE TERCEIRIZAÇÃO' },
            { id: 33963500, nome: 'SERVIÇOS DE CONSULTORIA' },
            { id: 33963600, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA FÍSICA' },
            { id: 33963700, nome: 'LOCAÇÃO DE MÃO-DE-OBRA' },
            { id: 33963800, nome: 'ARRENDAMENTO MERCANTIL' },
            { id: 33963900, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA JURÍDICA' },
            { id: 33964100, nome: 'CONTRIBUIÇÕES' },
            { id: 33964500, nome: 'SUBVENÇÕES ECONÔMICAS' },
            { id: 33964600, nome: 'AUXÍLIO-ALIMENTAÇÃO' },
            { id: 33964700, nome: 'OBRIGAÇÕES TRIBUTÁRIAS E CONTRIBUTIVAS' },
            { id: 33964800, nome: 'OUTROS AUXÍLIOS FINANCEIROS A PESSOAS FÍSICAS' },
            { id: 33964900, nome: 'AUXÍLIO-TRANSPORTE' },
            { id: 33966700, nome: 'DEPÓSITOS COMPULSÓRIOS' },
            { id: 33969100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 33969200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 33969300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 33969600, nome: 'RESSARCIMENTO DE DESPESAS DE PESSOAL REQUISITADO' },
            { id: 44204100, nome: 'CONTRIBUIÇÕES' },
            { id: 44204200, nome: 'AUXÍLIOS' },
            { id: 44225100, nome: 'OBRAS E INSTALAÇÕES' },
            { id: 44225200, nome: 'EQUIPAMENTOS E MATERIAL PERMANENTE' },
            { id: 44229200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 44229300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 44304100, nome: 'CONTRIBUIÇÕES' },
            { id: 44304200, nome: 'AUXÍLIOS' },
            { id: 44314100, nome: 'CONTRIBUIÇÕES ' },
            { id: 44314200, nome: 'AUXÍLIOS' },
            { id: 44319200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES ' },
            { id: 44322000, nome: 'AUXÍLIO FINANCEIRO A PESQUISADORES' },
            { id: 44325100, nome: 'OBRAS E INSTALAÇÕES' },
            { id: 44325200, nome: 'EQUIPAMENTOS E MATERIAL PERMANENTE' },
            { id: 44329200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 44329300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 44354100, nome: 'CONTRIBUIÇÕES' },
            { id: 44354200, nome: 'AUXÍLIOS' },
            { id: 44359200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 44364100, nome: 'CONTRIBUIÇÕES' },
            { id: 44364200, nome: 'AUXÍLIOS' },
            { id: 44369200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 44404100, nome: 'CONTRIBUIÇÕES' },
            { id: 44404200, nome: 'AUXÍLIOS' },
            { id: 44409200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES ' },
            { id: 44414100, nome: 'CONTRIBUIÇÕES ' },
            { id: 44414200, nome: 'AUXÍLIOS' },
            { id: 44419200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES ' },
            { id: 44421400, nome: 'DIÁRIAS - CIVIL' },
            { id: 44425100, nome: 'OBRAS E INSTALAÇÕES' },
            { id: 44425200, nome: 'EQUIPAMENTOS E MATERIAL PERMANENTE' },
            { id: 44429200, nome: 'DESPESAS DE EXERCÍCIO ANTERIORES' },
            { id: 44454100, nome: 'CONTRIBUIÇÕES' },
            { id: 44454200, nome: 'AUXÍLIOS' },
            { id: 44459200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 44464100, nome: 'CONTRIBUIÇÕES' },
            { id: 44464200, nome: 'AUXÍLIOS' },
            { id: 44469200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 44501400, nome: 'DIÁRIAS - CIVIL' },
            { id: 44503000, nome: 'MATERIAL DE CONSUMO' },
            { id: 44503600, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA FÍSICA' },
            { id: 44503900, nome: 'OUTROS SERVIÇOS DE TERCEIROS -PESSOA JURÍDICA' },
            { id: 44504100, nome: 'CONTRIBUIÇÕES' },
            { id: 44504200, nome: 'AUXÍLIOS' },
            { id: 44504700, nome: 'OBRIGAÇÕES TRIBUTÁRIAS E CONTRIBUTIVAS' },
            { id: 44505100, nome: 'OBRAS E INSTALAÇÕES' },
            { id: 44505200, nome: 'EQUIPAMENTOS E MATERIAL PERMANENTE' },
            { id: 44604100, nome: 'CONTRIBUIÇÕES' },
            { id: 44604500, nome: 'SUBVENÇÕES ECONÔMICAS' },
            { id: 44678200, nome: 'APORTE DE RECURSOS PELO PARCEIRO PÚBLICO EM FAVOR DO PARCEIRO PRIVADO DECORRENTE DE CONTRATO DE PARCERIA PÚBLICO-PRIVADA - PPP' },
            { id: 44678300, nome: 'DESPESAS DECORRENTES DE CONTRATO DE PARCERIA PÚBLICO-PRIVADA - PPP, EXCETO SUBVENÇÕES ECONÔMICAS, APORTE E FUNDO GARANTIDOR' },
            { id: 44704100, nome: 'CONTRIBUIÇÕES' },
            { id: 44704200, nome: 'AUXÍLIOS' },
            { id: 44715100, nome: 'OBRAS E INSTALAÇÕES - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 44715200, nome: 'EQUIPAMENTOS E MATERIAL PERMANENTE - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 44716100, nome: 'AQUISIÇÃO DE IMÓVEIS - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 44717000, nome: 'RATEIO PELA PARTICIPAÇÃO EM CONSÓRCIO PÚBLICO' },
            { id: 44719100, nome: 'SENTENÇAS JUDICIAIS - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 44719200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 44719300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES - TRANSFERÊNCIAS A CONSÓRCIOS PÚBLICOS' },
            { id: 44721400, nome: 'DIÁRIAS - CIVIL' },
            { id: 44723000, nome: 'MATERIAL DE CONSUMO' },
            { id: 44723600, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA FÍSICA' },
            { id: 44723900, nome: 'OUTROS SERVIÇOS DE TERCEIROS -PESSOA JURÍDICA' },
            { id: 44725100, nome: 'OBRAS E INSTALAÇÕES' },
            { id: 44725200, nome: 'EQUIPAMENTOS E MATERIAL PERMANENTE' },
            { id: 44729200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 44737000, nome: 'RATEIO PELA PARTICIPAÇÃO EM CONSÓRCIO PÚBLICO' },
            { id: 44747000, nome: 'RATEIO PELA PARTICIPAÇÃO EM CONSÓRCIO PÚBLICO' },
            { id: 44754100, nome: 'CONTRIBUIÇÕES' },
            { id: 44754200, nome: 'AUXÍLIOS' },
            { id: 44764100, nome: 'CONTRIBUIÇÕES' },
            { id: 44764200, nome: 'AUXÍLIOS' },
            { id: 44804100, nome: 'CONTRIBUIÇÕES' },
            { id: 44804200, nome: 'AUXÍLIOS' },
            { id: 44805100, nome: 'OBRAS E INSTALAÇÕES' },
            { id: 44805200, nome: 'EQUIPAMENTOS E MATERIAL PERMANENTE' },
            { id: 44900400, nome: 'CONTRATAÇÃO POR TEMPO DETERMINADO' },
            { id: 44901400, nome: 'DIÁRIAS - PESSOAL CIVIL' },
            { id: 44901800, nome: 'AUXÍLIO FINANCEIRO A ESTUDANTE' },
            { id: 44902000, nome: 'AUXÍLIO FINANCEIRO A PESQUISADORES' },
            { id: 44903000, nome: 'MATERIAL DE CONSUMO' },
            { id: 44903300, nome: 'PASSAGENS E DESPESAS COM LOCOMOÇÃO' },
            { id: 44903500, nome: 'SERVIÇOS DE CONSULTORIA' },
            { id: 44903600, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA FÍSICA' },
            { id: 44903700, nome: 'LOCAÇÃO DE MÃO-DE-OBRA' },
            { id: 44903900, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA JURÍDICA' },
            { id: 44904700, nome: 'OBRIGAÇÕES TRIBUTÁRIAS E CONTRIBUTIVAS ' },
            { id: 44905100, nome: 'OBRAS E INSTALAÇÕES' },
            { id: 44905200, nome: 'EQUIPAMENTOS E MATERIAL PERMANENTE' },
            { id: 44906100, nome: 'AQUISIÇÃO DE IMÓVEIS' },
            { id: 44906200, nome: 'AQUISIÇÃO DE PRODUTOS PARA REVENDA' },
            { id: 44908800, nome: 'DESPESAS C/ TECNOLOGIA DA INFORMACAO' },
            { id: 44909100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 44909200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 44909300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 44913900, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA JURÍDICA - INTRA-ORÇAMENTÁRIO' },
            { id: 44914700, nome: 'OBRIGAÇÕES TRIBUTÁRIAS E CONTRIBUTIVAS ' },
            { id: 44915100, nome: 'OBRAS E INSTALAÇÕES - INTRA-ORÇAMENTÁRIO' },
            { id: 44915200, nome: 'EQUIPAMENTOS E MATERIAL PERMANENTE - INTRA-ORÇAMENTÁRIO' },
            { id: 44919100, nome: 'SENTENÇAS JUDICIAIS - INTRA-ORÇAMENTÁRIO' },
            { id: 44919200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES - INTRA-ORÇAMENTÁRIO' },
            { id: 44925100, nome: 'OBRAS E INSTALAÇÕES' },
            { id: 44925200, nome: 'EQUIPAMENTOS E MATERIAL PERMANENTE' },
            { id: 44926100, nome: 'AQUISIÇÃO DE IMÓVEIS' },
            { id: 44935100, nome: 'OBRAS E INSTALAÇÕES' },
            { id: 44935200, nome: 'EQUIPAMENTOS E MATERIAL PERMANENTE' },
            { id: 44936100, nome: 'AQUISIÇÃO DE IMÓVEIS' },
            { id: 44945100, nome: 'OBRAS E INSTALAÇÕES' },
            { id: 44945200, nome: 'EQUIPAMENTOS E MATERIAL PERMANENTE' },
            { id: 44946100, nome: 'AQUISIÇÃO DE IMÓVEIS' },
            { id: 44955100, nome: 'OBRAS E INSTALAÇÕES' },
            { id: 44955200, nome: 'EQUIPAMENTOS E MATERIAL PERMANENTE' },
            { id: 44956100, nome: 'AQUISIÇÃO DE IMÓVEIS' },
            { id: 44959100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 44959200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 44959300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 44965100, nome: 'OBRAS E INSTALAÇÕES' },
            { id: 44965200, nome: 'EQUIPAMENTOS E MATERIAL PERMANENTE' },
            { id: 44966100, nome: 'AQUISIÇÃO DE IMÓVEIS' },
            { id: 44969100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 44969200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 44969300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 45304100, nome: 'CONTRIBUIÇÕES' },
            { id: 45304200, nome: 'AUXÍLIOS' },
            { id: 45314100, nome: 'CONTRIBUIÇÕES' },
            { id: 45314200, nome: 'AUXÍLIOS' },
            { id: 45326100, nome: 'AQUISIÇÃO DE IMÓVEIS' },
            { id: 45326400, nome: 'AQUISIÇÃO DE TÍTULOS REPRESENTATIVOS DE CAPITAL JÁ INTEGRALIZADO' },
            { id: 45326500, nome: 'CONSTITUIÇÃO OU AUMENTO DE CAPITAL DE EMPRESA' },
            { id: 45326600, nome: 'CONCESSÃO DE EMPRÉSTIMOS E FINANCIAMENTOS' },
            { id: 45404100, nome: 'CONTRIBUIÇÕES' },
            { id: 45404200, nome: 'AUXÍLIOS' },
            { id: 45426400, nome: 'AQUISIÇÃO DE TÍTULOS REPRESENTATIVOS DE CAPITAL JÁ INTEGRALIZADO' },
            { id: 45426600, nome: 'CONCESSÃO DE EMPRÉSTIMOS E FINANCIAMENTOS' },
            { id: 45504200, nome: 'AUXÍLIOS' },
            { id: 45506600, nome: 'CONCESSÃO DE EMPRÉSTIMOS E FINANCIAMENTOS' },
            { id: 45678200, nome: 'APORTE DE RECURSOS PELO PARCEIRO PÚBLICO EM FAVOR DO PRIVADO - PPP' },
            { id: 45678300, nome: 'DESPESAS DECORRENTES DE CONTRATOS DE PARCEIRA PÚBLICO PRIVADA, EXCETO SUBVENÇÕES, APORTE E FUNDO GARANTIDOR' },
            { id: 45704100, nome: 'CONTRIBUIÇÕES' },
            { id: 45704200, nome: 'AUXÍLIOS' },
            { id: 45717000, nome: 'RATEIO PELA PARTICIPAÇÃO EM CONSÓRCIO PÚBLICO' },
            { id: 45721400, nome: 'DIÁRIAS - CIVIL' },
            { id: 45723000, nome: 'MATERIAL DE CONSUMO' },
            { id: 45723600, nome: 'OUTROS SERVIÇOS DE TERCEIROS - PESSOA FÍSICA' },
            { id: 45723900, nome: 'OUTROS SERVIÇOS DE TERCEIROS -PESSOA JURÍDICA' },
            { id: 45725100, nome: 'OBRAS E INSTALAÇÕES' },
            { id: 45725200, nome: 'EQUIPAMENTOS E MATERIAL PERMANENTE' },
            { id: 45729200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 45737000, nome: 'RATEIO PELA PARTICIPAÇÃO EM CONSÓRCIO PÚBLICO' },
            { id: 45747000, nome: 'RATEIO PELA PARTICIPAÇÃO EM CONSÓRCIO PÚBLICO' },
            { id: 45806600, nome: 'CONCESSÃO DE EMPRÉSTIMOS E FINANCIAMENTOS' },
            { id: 45905200, nome: 'EQUIPAMENTOS E MATERIAL PERMANENTE' },
            { id: 45906100, nome: 'AQUISIÇÃO DE IMÓVEIS' },
            { id: 45906200, nome: 'AQUISIÇÃO DE PRODUTOS PARA REVENDA' },
            { id: 45906300, nome: 'AQUISIÇÃO DE TÍTULOS DE CRÉDITO' },
            { id: 45906400, nome: 'AQUISIÇÃO TÍTULOS REPRESENTATIVOS DE CAPITAL JÁ INTEGRALIZADO' },
            { id: 45906500, nome: 'CONSTITUIÇÃO OU AUMENTO DE CAPITAL DE EMPRESAS' },
            { id: 45906600, nome: 'CONCESSÃO DE EMPRÉSTIMOS E FINANCIAMENTOS' },
            { id: 45906700, nome: 'DEPÓSITOS COMPULSÓRIOS' },
            { id: 45908200, nome: 'APORTE DE RECURSOS PELO PARCEIRO PÚBLICO EM FAVOR DO PARCEIRO PRIVADO DECORRENTE DE PPP' },
            { id: 45908300, nome: 'DESPESAS DECORRENTES DE CONTRATO DE PPP, EXCETO SUBVENÇÕES ECONÔMICAS, APORTE E FUNDO GARANTIDOR' },
            { id: 45908400, nome: 'PARTICIPAÇÃO EM FUNDOS, ORGANISMOS OU ENTIDADES ASSEMELHADAS, NACIONAIS E INTERNACIONAIS' },
            { id: 45909100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 45909200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 45909300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 45914700, nome: 'OBRIGAÇÕES TRIBUTÁRIAS E CONTRIBUTIVAS' },
            { id: 45916100, nome: 'AQUISIÇÃO DE IMÓVEIS - INTRA-ORÇAMENTÁRIO' },
            { id: 45916200, nome: 'AQUISIÇÃO DE PRODUTOS PARA REVENDA - INTRA-ORÇAMENTÁRIO' },
            { id: 45916300, nome: 'AQUISIÇÃO DE TÍTULOS DE CRÉDITO - INTRA-ORÇAMENTÁRIO' },
            { id: 45916400, nome: 'AQUISIÇÃO TÍTULOS REPRESENTATIVOS DE CAPITAL JÁ INTEGRALIZADO - INTRA-ORÇAMENTÁRIO' },
            { id: 45916500, nome: 'CONSTITUIÇÃO OU AUMENTO DE CAPITAL DE EMPRESAS - INTRA-ORÇAMENTÁRIO' },
            { id: 45916600, nome: 'CONCESSÃO DE EMPRÉSTIMOS E FINANCIAMENTOS - INTRA-ORÇAMENTÁRIO' },
            { id: 45916700, nome: 'DEPÓSITOS COMPULSÓRIOS - INTRA-ORÇAMENTÁRIO' },
            { id: 45918400, nome: 'PARTICIPAÇÃO EM FUNDOS, ORGANISMOS OU ENTIDADES ASSEMELHADAS, NACIONAIS E INTERNACIONAIS - INTRA OFSS' },
            { id: 45919100, nome: 'SENTENÇAS JUDICIAIS - INTRA-ORÇAMENTÁRIO' },
            { id: 45919200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES - INTRA-ORÇAMENTÁRIO' },
            { id: 45919300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES - INTRA-ORÇAMENTÁRIO' },
            { id: 45956100, nome: 'AQUISIÇÃO DE IMÓVEIS' },
            { id: 45956700, nome: 'DEPÓSITOS COMPULSÓRIOS' },
            { id: 45959100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 45959200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 45959300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 45966100, nome: 'AQUISIÇÃO DE IMÓVEIS' },
            { id: 45966700, nome: 'DEPÓSITOS COMPULSÓRIOS' },
            { id: 45969100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 45969200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 45969300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 46717000, nome: 'RATEIO PELA PARTICIPAÇÃO EM CONSÓRCIO PÚBLICO' },
            { id: 46737000, nome: 'RATEIO PELA PARTICIPAÇÃO EM CONSÓRCIO PÚBLICO' },
            { id: 46747000, nome: 'RATEIO PELA PARTICIPAÇÃO EM CONSÓRCIO PÚBLICO' },
            { id: 46907100, nome: 'PRINCIPAL DA DÍVIDA CONTRATUAL RESGATADA' },
            { id: 46907200, nome: 'PRINCIPAL DA DÍVIDA MOBILIÁRIA RESGATADO' },
            { id: 46907300, nome: 'CORREÇÃO MONETÁRIA OU CAMBIAL DA DÍVIDA CONTRATUAL RESGATADA' },
            { id: 46907400, nome: 'CORREÇÃO MONETÁRIA OU CAMBIAL DA DÍVIDA MOBILIÁRIA RESGATADA' },
            { id: 46907500, nome: 'CORREÇÃO MONETÁRIA DA DÍVIDA DE  OPERAÇÕES DE CRÉDITO POR ANTECIPAÇÃO DA RECEITA' },
            { id: 46907600, nome: 'PRINCIPAL CORRIGIDO DA DÍVIDA MOBILIÁRIA REFINANCIADO' },
            { id: 46907700, nome: 'PRINCIPAL CORRIGIDO DA DÍVIDA CONTRATUAL REFINANCIADO' },
            { id: 46909100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 46909200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 46909300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 46917100, nome: 'PRINCIPAL DA DÍVIDA CONTRATUAL RESGATADA - INTRA-ORÇAMENTÁRIO' },
            { id: 46917200, nome: 'PRINCIPAL DA DÍVIDA MOBILIÁRIA RESGATADO - INTRA-ORÇAMENTÁRIO' },
            { id: 46917300, nome: 'CORREÇÃO MONETÁRIA OU CAMBIAL DA DÍVIDA CONTRATUAL RESGATADA - INTRA-ORÇAMENTÁRIO' },
            { id: 46917400, nome: 'CORREÇÃO MONETÁRIA OU CAMBIAL DA DÍVIDA MOBILIÁRIA RESGATADA - INTRA-ORÇAMENTÁRIO' },
            { id: 46917500, nome: 'CORREÇÃO MONETÁRIA DA DÍVIDA DE  OPERAÇÕES DE CRÉDITO POR ANTECIPAÇÃO DA RECEITA - INTRA-ORÇAMENTÁRIO' },
            { id: 46917600, nome: 'PRINCIPAL CORRIGIDO DA DÍVIDA MOBILIÁRIA REFINANCIADO - INTRA OFSS' },
            { id: 46917700, nome: 'PRINCIPAL CORRIGIDO DA DÍVIDA CONTRATUAL REFINANCIADO - INTRA-ORÇAMENTÁRIO' },
            { id: 46919100, nome: 'SENTENÇAS JUDICIAIS - INTRA-ORÇAMENTÁRIO' },
            { id: 46919200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES - INTRA-ORÇAMENTÁRIO' },
            { id: 46919300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES - INTRA-ORÇAMENTÁRIO' },
            { id: 46957100, nome: 'PRINCIPAL DA DÍVIDA CONTRATUAL RESGATADO' },
            { id: 46957300, nome: 'CORREÇÃO MONETÁRIA OU CAMBIAL DA DÍVIDA CONTRATUAL RESGATADA' },
            { id: 46957700, nome: 'PRINCIPAL CORRIGIDO DA DÍVIDA CONTRATUAL REFINANCIADO' },
            { id: 46959100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 46959200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 46959300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 46967100, nome: 'PRINCIPAL DA DÍVIDA CONTRATUAL RESGATADO' },
            { id: 46967300, nome: 'CORREÇÃO MONETÁRIA OU CAMBIAL DA DÍVIDA CONTRATUAL RESGATADA' },
            { id: 46967700, nome: 'PRINCIPAL CORRIGIDO DA DÍVIDA CONTRATUAL REFINANCIADO' },
            { id: 46969100, nome: 'SENTENÇAS JUDICIAIS' },
            { id: 46969200, nome: 'DESPESAS DE EXERCÍCIOS ANTERIORES' },
            { id: 46969300, nome: 'INDENIZAÇÕES E RESTITUIÇÕES' },
            { id: 45925200, nome: 'EQUIPAMENTOS E MATERIAL PERMANENTE' },
            { id: 45926100, nome: 'AQUISICAO DE IMOVEIS' },
            { id: 45928300, nome: 'DESPESAS DECORRENTES DE CONTRATO DE PPP, EXCETO SUBVENCOES ECONOMICAS, APORTE E FUNDO GARANTIDOR' },
            { id: 77999900, nome: 'RESERVA DE CONTINGÊNCIA - RPPS' },
            { id: 88888888, nome: 'OUTROS' },
            { id: 99999900, nome: 'RESERVA DE CONTINGÊNCIA' },
        ]
    }

    public proximoCodigo(tipo: 'LICITACAO' | 'AJUSTE' | 'TERMO', ano: number, orgao: number): Observable<number> {
        return this.http.get<number>(
            `${this.login.cidade.id}/${this.api}/proximo-codigo/${tipo}/${ano}/${orgao}`, this.httpOptions()).pipe(
                map(res => res),
                catchError(err => this.handleError(err))
            );
    }

    public atualizarStatus(id: number, tipo: 'ENVIADO' | 'ARMAZENADO', valor: boolean): Observable<void> {
        return this.http.post(
            `${this.login.cidade.id}/${this.api}/atualizar-status/${id}/${tipo}/${valor}`, undefined, this.httpOptions()).pipe(
                map(res => res),
                catchError(err => this.handleError(err))
            );
    }

    public importarDados(tipo: 'LICITACAO' | 'COMPRA' | 'AJUSTE' | 'TERMO', licitacao: number): Observable<{}> {
        return this.http.get<{}>(
            `${this.login.cidade.id}/${this.api}/importar-dados/${tipo}/${licitacao}`, this.httpOptions()).pipe(
                map(res => res),
                catchError(err => this.handleError(err))
            );
    }

    public validarXML(id: number): Observable<string> {
        return this.http.get<string>(
            `${this.login.cidade.id}/${this.api}/validar-xml/${id}`, this.httpOptions()).pipe(
                map(res => res),
                catchError(err => this.handleError(err))
            );
    }

    public validarLoteXML(ids: number[]) {
        return this.http.get<[]>(
            `${this.login.cidade.id}/${this.api}/validar-lote-xml/${ids}`, this.httpOptions()).pipe(
                map(res => res),
                catchError(err => this.handleError(err))
            );
    }

    public gerarXML(id: number): Observable<string> {
        return this.http.get<string>(
            `${this.login.cidade.id}/${this.api}/gerar-xml/${id}`, this.httpOptions()).pipe(
                map(res => res),
                catchError(err => this.handleError(err))
            );
    }

    public gerarPacote(ids: number[], tipoEmpenho?: boolean): Observable<{ pacote: LicitacaoAudespPacote, xml: string }[]> {
        return this.http.get<{ pacote: LicitacaoAudespPacote, xml: string }[]>(
            `${this.login.cidade.id}/${this.api}/gerar-pacotes/${ids.join(',')}?tipoEmpenho=${tipoEmpenho}`, this.httpOptions()).pipe(
                map(res => res),
                catchError(err => this.handleError(err))
            );
    }

    public baixarPacote(id: number): Observable<string> {
        return this.http.get<string>(
            `${this.login.cidade.id}/${this.api}/baixar-pacote/${id}`, this.httpOptions()).pipe(
                map(res => res),
                catchError(err => this.handleError(err))
            );
    }

    public gerarEmpenhos(ids: number[]): Observable<LicitacaoAudesp[]> {
        return this.http.get<LicitacaoAudesp[]>(
            `${this.login.cidade.id}/${this.api}/gerar-empenhos/${ids.join(',')}`, this.httpOptions()).pipe(
                map(res => res),
                catchError(err => this.handleError(err))
            );
    }

    public gerarPagamentos(ids: number[]): Observable<LicitacaoAudesp[]> {
        return this.http.get<LicitacaoAudesp[]>(
            `${this.login.cidade.id}/${this.api}/gerar-pagamentos/${ids.join(',')}`, this.httpOptions()).pipe(
                map(res => res),
                catchError(err => this.handleError(err))
            );
    }
}
