// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { ParametroAlmoxarifado } from './parametro-almoxarifado.model';

export class EncerramentoAlmoxarifadoJustificativa extends BaseResourceModel {
  constructor(
    public id?: number,
    public descricao?: string,
    public mes?: number,
    public abertura?: boolean,
    public responsavel?: Usuario,
    public parametro?: ParametroAlmoxarifado
  ) {
    super();
  }
  static converteJson(json: any): EncerramentoAlmoxarifadoJustificativa {
    return Object.assign(new EncerramentoAlmoxarifadoJustificativa(), json);
  }
}
