import { Component, OnInit, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { BaseResourceFormComponent, MetaGoverno, LoginContabil, Indicador, GlobalService } from 'eddydata-lib';
import { IndicadorService, MetaGovernoService } from 'administrativo-lib';

@Component({
  selector: 'app-meta-governo-view',
  templateUrl: './meta-governo-view.component.html'
})
export class MetaGovernoViewComponent extends BaseResourceFormComponent<MetaGoverno, LoginContabil> implements OnInit {

  /**
   * Declaração de variáveis
   */
  public mensagemSucesso: string;
  public ptBR: any;
  public indicadorLista: Array<Indicador>;
  public ano1: number;
  public ano2: number;
  public ano3: number;
  public ano4: number;
  public tipo: any;

  imaskConfig = {
    mask: Number,
    scale: 2,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };
  entidadeForm: any;
  fb: any;
  login: any;
  entidade: any;
  listaTipos: { id: string; nome: string; }[];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected indicadorService: IndicadorService,
    protected metaService: MetaGovernoService) {
    super(new MetaGoverno(), injector, MetaGoverno.converteJson, metaService);
  }


  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      especie: [null, [Validators.required]],
      gerente: [null],
      tipo: [null, [Validators.required]],
      alvo: [null, [Validators.required]],
      objetivo: [null, [Validators.required]],
      justificativa: [null, [Validators.required]],
      funcao: this.fb.group({
        codigo: [null, [Validators.required]],
        nome: [null, [Validators.required]]
      }),
      subfuncao: this.fb.group({
        codigo: [null, [Validators.required]],
        nome: [null, [Validators.required]]
      }),
      programa: this.fb.group({
        codigo: [null, [Validators.required]],
        nome: [null, [Validators.required]]
      }),
      unidade: this.fb.group({
        codigo: [null, [Validators.required]],
        nome: [null, [Validators.required]]
      }),
    });
  }

  protected afterLoad() {
    this.ano1 = this.login.ppa.ppaperiodo.ano1;
    this.ano2 = this.login.ppa.ppaperiodo.ano2;
    this.ano3 = this.login.ppa.ppaperiodo.ano3;
    this.ano4 = this.login.ppa.ppaperiodo.ano4;
    this.indicadorService.obterPorMeta(this.entidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.indicadorLista = res.content;
      });
    this.tipo = this.globalService.obterListaTiposPpa().find(idx =>
      idx.id === this.entidade.tipo
    );
  }

  protected parametrosExtras(): {} {
    return { relations: 'programa,funcao,subfuncao,unidade' };
  }

  protected afterInit(): void {
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: MetaGoverno): void {
  }

}
