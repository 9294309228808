import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { PessoaModule, SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { FieldsetModule } from 'primeng/fieldset';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { FavorecidoDlgModule } from '../favorecido/favorecido-dlg.module';
import { SaldoFichaDlgComponent } from './ficha-dlg/saldo-ficha-dlg.component';


@NgModule({
  declarations: [SaldoFichaDlgComponent],
  exports: [SaldoFichaDlgComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    PessoaModule,
    RadioButtonModule,
    FieldsetModule,
    FavorecidoDlgModule,
    SelectButtonModule
  ],
  providers: [MessageService]
})
export class SaldoFichaModule { }
