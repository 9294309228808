import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, EncerramentoContabilJustificativa } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class EncerramentoContabilJustificativaService extends BaseResourceService<EncerramentoContabilJustificativa> {

  constructor(
    protected injector: Injector
  ) {
    super(`encerramentos-contabil-justificativa`, injector);
  }
  
}
