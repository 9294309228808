import { Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Coluna } from '../../components/types';
import { Usuario } from '../../entidade/comum/usuario.model';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { BaseResourceListComponent, Filtro } from '../../models/base-resource-list';
import { GlobalService } from '../../util/global.service';
import { UsuarioService } from '../service/usuario.service';

declare var $: any;

@Component({
  selector: 'lib-usuario-dlg',
  templateUrl: './usuario-dlg.component.html'
})
export class UsuarioDlgComponent extends BaseResourceListComponent<Usuario, LoginContabil> implements OnChanges {

  /**
   * Declaração de variáveis
   */
  public filtro: string;
  public usuario: Usuario = new Usuario();

  @Input() visualizar : boolean = false;
  @Output() visualizarChange : EventEmitter<boolean> = new EventEmitter();

  @Input() public titulo: string = 'Usuários';

  @Input() public setor: number;
  @Output() public setorChange: EventEmitter<number> = new EventEmitter();

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public globalService: GlobalService,
    protected usuarioService: UsuarioService) {
    super(usuarioService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visualizar) {
      this.ngOnInit();
    }
  }

  ngOnInit() { }

  protected relations(): string {
    return 'orgao,favorecido';
  }

  protected condicoesGrid(): {} {
    let parametros: {} = {};
    if (this.setor)
      parametros['setor.id'] = this.setor;
    parametros['orgao.id'] = this.login.orgao.id;
    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['id$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['id', 'cpf'],
      text: ['nome', 'sobrenome', 'email', 'telefone', 'sistema']
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: Usuario): Observable<Usuario> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return null;
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public onHide(){
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }


}
