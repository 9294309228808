import { BaseResourceModel } from "../../models/base-resource.model";
import { Aliquota } from "./aliquota.model";
import { FaturaTipo } from "./fatura-tipo.model";


export class FaturaIrAliquota extends BaseResourceModel {
  constructor(
    public fatura_tipo?: FaturaTipo,
    public aliquota?: Aliquota
  ) {
    super();
  }

  static converteJson(json: any): FaturaIrAliquota {
    return Object.assign(new FaturaIrAliquota(), json);
  }
}