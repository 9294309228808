// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { AuditoriaEmpenho } from './auditoria-empenho.model';

export class EmpenhoChecklist extends BaseResourceModel {
  constructor(
    public id?: number,
    public pergunta?: string,
    public tipo_resposta?: string,
    public descricao_resposta?: string,
    public resposta?: boolean,
    public observacao?: string,
    public auditar_empenho?: AuditoriaEmpenho
    ) {
      super();
    }
    static converteJson(json: any): EmpenhoChecklist {
      return Object.assign(new EmpenhoChecklist(), json);
    }
}
