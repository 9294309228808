import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import {
  FuncaoService, GlobalService, Exercicio, Login
} from 'eddydata-lib';

@Directive()
export class Anexo8Imposto implements OnDestroy {

  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number,
    protected exercicio: Exercicio,
    protected login: Login) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(impostos: any[]): {}[] {

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: this.login.orgao.nome,
        alignment: 'left',
        fontSize: 10,
        border: [false, false, false, false],
        bold: true,
        colSpan: 3
      }, '', ''],
      [{
        text: 'DEMONSTRATIVO DAS RECEITAS E DESPESAS COM MANUTENÇÃO E DESENVOLVIMENTO DO ENSINO - MDE',
        alignment: 'left',
        fontSize: 8,
        border: [false, false, false, false],
        bold: true,
        colSpan: 3
      }, '', ''],
      [{
        text: 'ORÇAMENTO FISCAL E DA SEGURIDADE SOCIAL',
        alignment: 'left',
        fontSize: 8,
        border: [false, false, false, false],
        bold: true,
        colSpan: 3
      }, '', ''],
      [{
        text: `REFERÊNCIA: ` + this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano,
        alignment: 'left',
        fontSize: 8,
        border: [false, false, false, false],
        bold: true,
        colSpan: 3
      }, '', ''],
      [{
        text: 'RECEITA RESULTANTE DE IMPOSTOS (Arts. 212 e 212-A da Constituição Federal)',
        alignment: 'center',
        bold: true,
        margin: [5, 5, 5, 5],
        colSpan: 3
      }, '', ''],
      [
        {
          text: 'RECEITA RESULTANTE DE IMPOSTOS',
          alignment: 'center',
          fontSize: 6,
        },
        {
          text: 'PREVISÃO ATUALIZADA (a)',
          alignment: 'center',
          fontSize: 6
        },
        {
          text: 'RECEITAS REALIZADAS ATÉ O BIMESTRE (b)',
          alignment: 'center',
          fontSize: 6
        }
      ]
    ];
    let subgrupos: any;

    // monta o agrupamento do relatório
    const grupos = this.funcaoService.agrupar(impostos, 'grupo', ['arrecadado', 'atualizado', 'fundeb_atualizado', 'fundeb_recebido']);
    let total_receita_imposto1 = 0;
    let total_receita_imposto2 = 0;
    let total_destinado1 = 0;
    let total_destinado2 = 0;
    let valor_minimo1 = 0;
    let valor_minimo2 = 0;

    // Caso seja adicionado novos campos no layout será necessario mudar a posição dos arrays
    total_destinado1 = ((impostos[4].fundeb_atualizado + impostos[6].fundeb_atualizado + impostos[7].fundeb_atualizado + impostos[8].fundeb_atualizado + impostos[9].fundeb_atualizado + impostos[11].fundeb_atualizado));

    total_destinado2 = ((impostos[4].fundeb_recebido + impostos[6].fundeb_recebido + impostos[7].fundeb_recebido + impostos[8].fundeb_recebido + impostos[9].fundeb_recebido + impostos[11].fundeb_recebido));

    valor_minimo1 = ((impostos[4].atualizado + impostos[6].atualizado + impostos[7].atualizado + impostos[8].atualizado + impostos[9].atualizado + impostos[11].atualizado) * 0.05) + ((impostos[0].atualizado + impostos[1].atualizado + impostos[2].atualizado + impostos[3].atualizado + impostos[5].atualizado + impostos[10].atualizado) * 0.25);

    valor_minimo2 = ((impostos[4].arrecadado + impostos[6].arrecadado + impostos[7].arrecadado + impostos[8].arrecadado + impostos[9].arrecadado + impostos[11].arrecadado) * 0.05) + ((impostos[0].arrecadado + impostos[1].arrecadado + impostos[2].arrecadado + impostos[3].arrecadado + impostos[5].arrecadado + impostos[10].arrecadado) * 0.25);


    for (const titulo of grupos) {
      if (titulo.grupo !== '4- TOTAL DESTINADO AO FUNDEB') {
        registros.push([
          { text: titulo.grupo, fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['atualizado']), alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['arrecadado']), alignment: 'right', fontSize: 7 }
        ]);
      }

      total_receita_imposto1 += +titulo.totalizadores['atualizado'];
      total_receita_imposto2 += +titulo.totalizadores['arrecadado'];

      subgrupos = this.funcaoService.agrupar(titulo.registros, 'subgrupo', ['atualizado', 'arrecadado']);
      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo !== '') {
          registros.push([
            { text: subgrupo.grupo, fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['atualizado']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['arrecadado']), alignment: 'right', fontSize: 7 }
          ]);
        }

        for (const item of subgrupo.registros) {
          if (item.nome != '') {
            registros.push([
              { text: item.nome, fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(item.atualizado), alignment: 'right', fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(item.arrecadado), alignment: 'right', fontSize: 7 }
            ]);
          }
        }
      }
    }
    registros.push([
      { text: '3- TOTAL DA RECEITA RESULTANTE DE IMPOSTOS (1 + 2)', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total_receita_imposto1), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total_receita_imposto2), alignment: 'right', fontSize: 7, bold: true }
    ]);
    registros.push([
      { text: '4- TOTAL DESTINADO AO FUNDEB - equivalente a 20% DE ((2.1.1) + (2.2) + (2.3) + (2.4) + (2.5) + (2.7))', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total_destinado1), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total_destinado2), alignment: 'right', fontSize: 7, bold: true }
    ]);
    registros.push([
      { text: '5- VALOR MÍNIMO A SER APLICADO ALÉM DO VALOR DESTINADO AO FUNDEB - 5% DE ((2.1.1) + (2.2) + (2.3) + (2.4) + (2.5) + (2.7)) + 25% DE ((1.1) + (1.2) + (1.3) + (1.4) + (2.1.2) + (2.6))', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(valor_minimo1), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(valor_minimo2), alignment: 'right', fontSize: 7, bold: true }
    ]);


    return registros;

  }

  public montarQuadroCsv(dados: any[], listaExportar): any {
    let subgrupos: any;

    // monta o agrupamento do relatório
    const grupos = this.funcaoService.agrupar(dados, 'grupo', ['arrecadado', 'atualizado']);
    let total_receita_imposto1 = 0;
    let total_receita_imposto2 = 0;
    let total_destinado1 = 0;
    let total_destinado2 = 0;
    let valor_minimo1 = 0;
    let valor_minimo2 = 0;

    // Caso seja adicionado novos campos no layout será necessario mudar a posição dos arrays
    total_destinado1 = ((dados[4].atualizado + dados[6].atualizado + dados[7].atualizado + dados[8].atualizado + dados[9].atualizado) * 0.2);

    total_destinado2 = ((dados[4].arrecadado + dados[6].arrecadado + dados[7].arrecadado + dados[8].arrecadado + dados[9].arrecadado) * 0.2);

    valor_minimo1 = ((dados[4].atualizado + dados[6].atualizado + dados[7].atualizado + dados[8].atualizado + dados[9].atualizado) * 0.05) + ((dados[0].atualizado + dados[1].atualizado + dados[2].atualizado + dados[3].atualizado + dados[5].atualizado + dados[10].atualizado + dados[11].atualizado) * 0.25);

    valor_minimo2 = ((dados[4].arrecadado + dados[6].arrecadado + dados[7].arrecadado + dados[8].arrecadado + dados[9].arrecadado) * 0.05) + ((dados[0].arrecadado + dados[1].arrecadado + dados[2].arrecadado + dados[3].arrecadado + dados[5].arrecadado + dados[10].arrecadado + dados[11].arrecadado) * 0.25);

    const linha_titulo = {
      titulo: 'RECEITA RESULTANTE DE IMPOSTOS',
      coluna2: 'PREVISÃO ATUALIZADA (a)',
      coluna3: 'RECEITAS REALIZADAS ATÉ O BIMESTRE (b)',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(linha_titulo)

    for (const titulo of grupos) {
      if (titulo.grupo !== '4- TOTAL DESTINADO AO FUNDEB') {
        const linha_titulo = {
          titulo: titulo.grupo,
          coluna2: this.funcaoService.convertToBrNumber(titulo.totalizadores['atualizado']),
          coluna3: this.funcaoService.convertToBrNumber(titulo.totalizadores['arrecadado']),
          coluna4: '',
          coluna5: '',
          coluna6: '',
          coluna7: ''
        }
        listaExportar.push(linha_titulo)
      }

      total_receita_imposto1 += +titulo.totalizadores['atualizado'];
      total_receita_imposto2 += +titulo.totalizadores['arrecadado'];

      subgrupos = this.funcaoService.agrupar(titulo.registros, 'subgrupo', ['atualizado', 'arrecadado']);
      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo !== '') {
          const linha_subgrupo = {
            titulo: subgrupo.grupo,
            coluna2: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['atualizado']),
            coluna3: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['arrecadado']),
            coluna4: '',
            coluna5: '',
            coluna6: '',
            coluna7: ''
          }
          listaExportar.push(linha_subgrupo)
        }

        for (const item of subgrupo.registros) {
          if (item.nome != '') {
            const linha_subgrupo_item = {
              titulo: item.nome,
              coluna2: this.funcaoService.convertToBrNumber(item.atualizado),
              coluna3: this.funcaoService.convertToBrNumber(item.arrecadado),
              coluna4: '',
              coluna5: '',
              coluna6: '',
              coluna7: ''
            }
            listaExportar.push(linha_subgrupo_item)
          }
        }
      }
    }

    const linha_total_3 = {
      titulo: '3- TOTAL DA RECEITA RESULTANTE DE IMPOSTOS (1 + 2)',
      coluna2: this.funcaoService.convertToBrNumber(total_receita_imposto1),
      coluna3: this.funcaoService.convertToBrNumber(total_receita_imposto2),
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(linha_total_3)

    const linha_total_4 = {
      titulo: '4- TOTAL DESTINADO AO FUNDEB - 20% DE ((2.1.1) + (2.2) + (2.3) + (2.4) + (2.5))',
      coluna2: this.funcaoService.convertToBrNumber(total_destinado1),
      coluna3: this.funcaoService.convertToBrNumber(total_destinado2),
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(linha_total_4)

    const linha_total_5 = {
      titulo: '5- VALOR MÍNIMO A SER APLICADO ALÉM DO VALOR DESTINADO AO FUNDEB - 5% DE ((2.1.1) + (2.2) + (2.3) + (2.4) + (2.5)) + 25% DE ((1.1) + (1.2) + (1.3) + (1.4) + (2.1.2) + (2.6)+ (2.7))',
      coluna2: this.funcaoService.convertToBrNumber(valor_minimo1),
      coluna3: this.funcaoService.convertToBrNumber(valor_minimo2),
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(linha_total_5)

  }

}