import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ParametroPatrimonio } from '../../../entidade/patrimonio/parametro-patrimonio.model';
import { BaseResourceService } from '../../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class ParametroPatrimonioService extends BaseResourceService<ParametroPatrimonio> {

  constructor(
    protected injector: Injector
  ) {
    super(`parametros-patrimonio`, injector);
  }

  public obterParametro(orgao: number): Observable<ParametroPatrimonio> {
    return this.http.get<ParametroPatrimonio>(
      `${this.login.cidade.id}/${this.api}/obter/${orgao}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
