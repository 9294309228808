import { Component, ElementRef, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BaseResourceFormComponent, Fracionamento, FuncaoService, GlobalService, LoginContabil
} from 'eddydata-lib';
import { ProdutoService } from '../../produto/service/produto.service';
import { FracionamentoService } from '../service/fracionamento.service';

@Component({
  selector: 'app-fracionamento-view',
  templateUrl: './fracionamento-view.component.html'
})
export class FracionamentoViewComponent extends BaseResourceFormComponent<Fracionamento, LoginContabil> {

  /**
   * Declaração de variáveis
   */

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected dissolucaoService: FracionamentoService,
    protected produtoService: ProdutoService,
  ) {
    super(new Fracionamento(), injector, Fracionamento.converteJson, dissolucaoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      qtd_fracionar: [0, [Validators.required]],
      qtd_fracionado: [0, [Validators.required]],
      lote: [null, Validators.required],
      vencimento: [null, Validators.required],
      fracionar: this.fb.group({
        id: [null, Validators.required],
        unidade: [null],
        produto: [null, Validators.required]
      }),
      fracionado: this.fb.group({
        id: [null, Validators.required],
        unidade: [null],
        produto: [null, Validators.required]
      }),
      setor_almoxarifado: [null, Validators.required],
      usuario: [this.login.usuario],
      exercicio: [this.login.exercicio],
      localizacao: [null],
      orgao: [this.login.orgao],
      recebedor: this.fb.group({
        nome: [this.login.usuario.nome],
        cpf_cnpj: [this.login.usuario.cpf],
        email: [this.login.usuario.email],
        cidade: [this.login.cidade]
      }),
      favorecido: [null],
      observacao: [null]
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: ['fracionado', 'fracionado.produto', 'fracionado.unidade',
        'fracionar', 'fracionar.produto', 'fracionar.unidade', 'setor_almoxarifado', 'favorecido', 'saida', 'entrada']
    };
  }

  protected afterLoad() { }

  protected afterInit(): void { }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit() { }

  protected afterSubmit(modelo: Fracionamento) { }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================


}
