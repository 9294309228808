import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BaseResourceRptComponent, Coluna, Convenio, DropdownValue, FuncaoService, GlobalService, Login } from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PagamentoRestoService } from '../../../tesouraria/pagamento-resto/service/pagamento-resto.service';
import { PagamentoService } from '../../../tesouraria/pagamento/service/pagamento.service';
import { RecebimentoService } from '../../../tesouraria/recebimento/service/recebimento.service';
import { ConvenioService } from '../service/convenio.service';
import * as toastr from 'toastr';

@Component({
  selector: 'lib-controle-saldo-dlg',
  templateUrl: './controle-saldo-dlg.component.html'
})
export class controleSaldoDlgComponent extends BaseResourceRptComponent {


  protected datepipe: DatePipe;
  public convenio: Convenio[];
  public ptBR: any;
  public numeroInicio: number;
  public numeroFinal: number;
  public tipo: string = null;
  public listaTipo: DropdownValue<string>[] = [];
  public ano: number;
  public formato: 'pdf' | 'csv' | 'xlsx';
  protected funcaoService: FuncaoService;
  protected unsubscribe: Subject<void> = new Subject();

  public imaskNumero = {
    mask: Number,
    scale: 0,
    signed: false
  };

  @Input() login: Login;

  constructor(
    protected confirmationService: ConfirmationService,
    protected messageService: MessageService,
    protected recebimentoService: RecebimentoService,
    protected pagamentoService: PagamentoService,
    protected pagamentoRestoService: PagamentoRestoService,
    private globalService: GlobalService,
    private convenioService: ConvenioService,
  ) {
    super();
    this.funcaoService = new FuncaoService();
  }

  afterInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.orientacao = 'landscape';

    this.listaTipo = [
      { label: 'Todos', value: null },
      { label: 'Fornecedor', value: 'F' },
      { label: 'Credor', value: 'C' },
    ];
  }

  protected carregarLista(): Promise<any[]> {
    const parametros = {
      relations: 'favorecido,aditamento,situacao.itens,reajuste,aplicacao_variavel,empenhos.liquidacoes.pagamentos,empenhos_resto.liquidacoes.pagamentos,tipo_convenio',
      orgao_id: this.login.orgao.id,
      orderBy: 'numero$DESC'
    };

    if (this.numeroInicio && !this.numeroFinal) {
      this.numeroFinal = this.numeroInicio;
    }

    if (+this.numeroFinal < +this.numeroInicio) {
      toastr.warning('Número final do convênio está maior que o inicial');
      throw new Error('Número final do convênio está maior que o inicial');
    }

    if (this.tipo) {
      parametros['tipo_fornecedor'] = this.tipo;
    }

    if (this.numeroInicio) {
      parametros['numero$ge'] = this.funcaoService.strZero(this.numeroInicio, 4);
    }

    if (this.numeroFinal) {
      parametros['numero$le'] = this.funcaoService.strZero(this.numeroFinal, 4);
    }

    if (this.ano) {
      parametros['ano'] = this.ano;
    } else {
      toastr.warning('Informe o ano do convênio');
      throw new Error('Informe o ano do convênio');
    }


    return new Promise((resolve) => {
      this.convenioService
        .extendido(1, -1, parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(async lista => {
          this.convenio = lista.content;
          resolve(lista.content)
        }, (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
        );
    })
  }

  protected larguraColunas(): (string | number)[] {
    if (this.formato === 'pdf') {
      return ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
    } else {
      return ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
    }
  }

  protected tituloRelatorio(): string {
    return `${this.login.orgao.nome} - CONTROLE DE SALDO DE CONVÊNIO`;
  }

  protected totalizarColunas(): (string | {})[] {
    return null;
  }

  protected obterColunasRelatorio(): Coluna[] {
    const retorno: Coluna[] = [];

    retorno.push({ titulo: 'NÚMERO DO CONVÊNIO', coluna: 'numero' });
    retorno.push({ titulo: 'ANO', coluna: 'ano' });
    retorno.push({ titulo: 'DATA DA ASSINATURA', coluna: 'data_assinatura' });
    retorno.push({ titulo: 'VALOR ASSINADO', coluna: 'valor_convenio' });
    if (this.formato !== 'pdf') {
      retorno.push({ titulo: 'DATA DE INÍCIO', coluna: 'data_inicio', alignment: 'right' });
      retorno.push({ titulo: 'DATA DE TERMINO', coluna: 'data_termino', alignment: 'right' });
      retorno.push({ titulo: 'CPF/CNPJ', coluna: 'favorecido.cpf_cnpj', alignment: 'right' });
      retorno.push({ titulo: 'FAVORECIDO', coluna: 'favorecido.nome', alignment: 'right' });
      retorno.push({ titulo: 'TIPO DE CONVÊNIO', coluna: 'tipo_convenio.nome', alignment: 'right' });
    }
    retorno.push({ titulo: 'VALOR ADITADO', coluna: 'total_aditamento', alignment: 'right' });
    retorno.push({ titulo: 'VALOR DE ESTORNO', coluna: 'total_estorno', alignment: 'right' });
    retorno.push({ titulo: 'VALOR DE REAJUSTE', coluna: 'total_reajuste', alignment: 'right' });
    retorno.push({ titulo: 'VALOR SUPRESSÃO', coluna: 'total_supressao', alignment: 'right' });
    retorno.push({ titulo: 'VALOR RECEBIDOS', coluna: 'total_recebido', alignment: 'right' });
    retorno.push({ titulo: 'VALOR PAGO', coluna: 'total_pagamento_EMO', decimais: 2, alignment: 'right' });
    retorno.push({ titulo: 'PRESTAÇÃO DE CONTAS', coluna: 'total_prestacao', decimais: 2, alignment: 'right' });
    retorno.push({ titulo: 'APROVAÇÃO', coluna: 'total_aprovado', decimais: 2, alignment: 'right' });
    retorno.push({ titulo: 'CANCELAMENTO', coluna: 'total_cancelado', alignment: 'right' });

    return retorno;
  }

}