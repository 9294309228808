// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ContratoItem } from '../compra/contrato-item.model';
import { Memorial } from '../licitacao/memorial.model';
import { Produto } from './produto.model';
import { UnidadeFornecimento } from './unidade-fornecimento.model';

export class ProdutoUnidade extends BaseResourceModel {
  constructor(
    public id?: number,
    public ativo?: boolean,
    public bec?: boolean,
    public unidade?: UnidadeFornecimento,
    public produto?: Produto,
    public editavel?: boolean,
    public contrato_itens?: ContratoItem[],
    public memoriais?: Memorial[]
  ) {
    super();
  }

  static converteJson(json: any): ProdutoUnidade {
    return Object.assign(new ProdutoUnidade(), json);
  }
}
