// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Cidade } from '../geo/cidade.model';

export class LegislacaoTipo extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public cidade?: Cidade
  ) {
    super();
  }
  static converteJson(json: any): LegislacaoTipo {
    return Object.assign(new LegislacaoTipo(), json);
  }
}
