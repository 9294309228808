import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido } from 'eddydata-lib';

export class FavorecidoDeducoes extends BaseResourceModel {
    constructor(
        public tipo?: number,
        public valor_deducao: number = 0.0,
        public previdencia_complementar: boolean = false,
        public cnpj_previdencia_complementar?: string,
        public valor_patrocinador?: number,
        public deducao_suspensa?: boolean,
        public favorecido?: Favorecido
    ) {
        super();
    }
    static converteJson(json: any): FavorecidoDeducoes {
        return Object.assign(new FavorecidoDeducoes(), json);
    }
}
