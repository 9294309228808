import { Injectable, Injector } from "@angular/core";
import { BaseResourceService, RequisicaoExcecao } from "eddydata-lib";

@Injectable({
    providedIn: 'root'
})
export class RequisicaoExcessaoService extends BaseResourceService<RequisicaoExcecao>{

    constructor(
        protected injector: Injector
    ) {
        super('requisicao-excecao', injector)
    }
}