import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { RecebimentoLstComponent } from './recebimento-lst/recebimento-lst.component';
import { RecebimentoShwComponent } from './recebimento-shw/recebimento-shw.component';

const routes: Routes = [
  {
    path: 'pesquisa/:especie/:pagina/:data1/:data2',
    component: RecebimentoLstComponent, canActivate: [AuthGuard]
  },
  {
    path: 'pesquisa/:especie/:pagina/:data1/:data2/:filtro',
    component: RecebimentoLstComponent, canActivate: [AuthGuard]
  },
  {
    path: 'visualiza/:id/:especie/:pagina/:data1/:data2',
    component: RecebimentoShwComponent, canActivate: [AuthGuard]
  },
  {
    path: 'visualiza/:id/:especie/:pagina/:data1/:data2/:filtro',
    component: RecebimentoShwComponent, canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecebimentoRoutingModule { }

