import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { Unidade } from '../planejamento/unidade.model';
import { PlanoContratacaoItem } from './plano-contratacao-item.model';

export class PlanoContratacaoAnual extends BaseResourceModel {
  constructor(
    public id?: number,
    public orgao?: Orgao,
    public unidade?: Unidade,
    public exercicio?: Exercicio,
    public sequencial_pncp?: number,
    public cadastrado_pncp?: boolean,
    public ignorar_pncp?: boolean,
    public data_pesquida_inicio?: Date,
    public data_pesquida_fim?: Date,
    public itens?: PlanoContratacaoItem[],
    public excluido?: boolean,
    public justificativa_exclusao?: string,
    public usuario_exclusao?: Usuario,
    public data_exclusao?: Date,

  ) {
    super();
  }
  static converteJson(json: any): PlanoContratacaoAnual {
    return Object.assign(new PlanoContratacaoAnual(), json);
  }
}
