// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido } from './favorecido.model';
import { Banco } from '../tesouraria/banco.model';
import { Recurso } from '../planejamento/recurso.model';
import { Orgao } from '../comum/orgao.model';

export class FavorecidoBanco extends BaseResourceModel {
  constructor(
    public id?: number,
    public agencia?: string,
    public numero_conta?: string,
    public tipo_conta?: string,
    public banco?: Banco,
    public favorecido?: Favorecido,
    public recurso?: Recurso,
    public aplicacao?: Recurso,
    public convenio?: Recurso,
    public ativo?: boolean,
    public principal?: boolean,
    public editavel?: boolean,
    public orgao?: Orgao
  ) {
    super();
  }
  static converteJson(json: any): FavorecidoBanco {
    return Object.assign(new FavorecidoBanco(), json);
  }
}
