import { Component, OnInit, Injector, ElementRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { RecebimentoExtraService } from '../service/recebimento-extra.service';
import {
  BaseResourceFormComponent, RecebimentoExtra, LoginContabil,
  GlobalService, DateFormatPipe
} from 'eddydata-lib';

@Component({
  selector: 'app-recebimento-extra-view',
  templateUrl: './recebimento-extra-view.component.html'
})
export class RecebimentoExtraViewComponent extends BaseResourceFormComponent<RecebimentoExtra, LoginContabil> implements OnInit {

  /**
   * Declaração de variáveis
   */


  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected recebimentoService: RecebimentoExtraService) {
    super(new RecebimentoExtra(), injector, RecebimentoExtra.converteJson, recebimentoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      guia: [null, [Validators.required]],
      valor_recebido: 0,
      data_recebimento: [null, [Validators.required]],
      data_referencia: [null, [Validators.required]],
      especie: [null, [Validators.required]],
      impresso: [null, [Validators.required]],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      conta: [null, [Validators.required]],
      exercicio: this.fb.group({
        ano: [null, [Validators.required]],
      }),
      orgao: [null, [Validators.required]],
      ficha: this.fb.group({
        numero: [null, [Validators.required]],
        nome: [null, [Validators.required]],
        valor_anterior: [null, [Validators.required]]
      })
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'conta,conta.banco,ficha,ficha.plano' };
  }

  protected afterLoad() {
    this.entidade.data_recebimento = new DateFormatPipe().transform(this.entidade.data_recebimento, []);
  }

  protected afterInit(): void {
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit() {
  }

  protected afterSubmit(ent: RecebimentoExtra) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/tesouraria']);
        break;
      default:
        this.sair();
        break;
    }
  }

}
