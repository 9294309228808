import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Coluna, FormatoExportacao } from '../../components/types';
import { Pessoa } from '../../entidade/comum/pessoa.model';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { BaseResourceListComponent, Filtro } from '../../models/base-resource-list';
import { PessoaService } from '../service/pessoa.service';

@Component({
  selector: 'lib-pessoa-list',
  templateUrl: './pessoa-list.component.html'
})
export class PessoaListComponent extends BaseResourceListComponent<Pessoa, LoginContabil> {
  /**
   * Declaração de variáveis
   */

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private pessoaService: PessoaService) {
    super(pessoaService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'cidade';
  }

  protected condicoesGrid(): {} {
    return {
      ['cidade.id']: this.login.cidade.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['nome'];
  }

  protected filtrosGrid(): Filtro {
    return {
      text: ['nome', 'cpf_cnpj']
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: Pessoa): Observable<Pessoa> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'CPF', coluna: 'cpf_cnpj' },
      { titulo: 'Estado Civil', coluna: 'estado_civil' },
      { titulo: 'Pai', coluna: 'pai' },
      { titulo: 'Mãe', coluna: 'mae' },
      { titulo: 'Conjuge', coluna: 'conjuge' },
      { titulo: 'RG', coluna: 'rg' },
      { titulo: 'Cartão', coluna: 'cartao' },
      { titulo: 'Espécie', coluna: 'especie' },
      { titulo: 'Título de Eleitor', coluna: 'titulo_eleitor' },
      { titulo: 'Sexo', coluna: 'sexo' },
      { titulo: 'Celular', coluna: 'celular' },
      { titulo: 'CEP', coluna: 'cep' },
      { titulo: 'Endereço', coluna: 'endereco' },
      { titulo: 'Complemento', coluna: 'complemento' },
      { titulo: 'Nº', coluna: 'numero' },
      { titulo: 'Bairro', coluna: 'bairro' },
      { titulo: 'E-mail', coluna: 'email' },
      { titulo: 'Cidade', coluna: 'municipio' },
      { titulo: 'Data de nascimento', coluna: 'data_nascimento' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'cidade';
    this.pessoaService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE CIDADÃOS/EMPRESAS`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem cidadãos/empresas', ['auto', '*'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
