import { RadioButtonModule } from 'primeng/radiobutton';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RelatorioTesourariaRoutingModule } from './relatorio-tesouraria-routing.module';
import { RelatorioTesourariaComponent } from './relatorio-tesouraria.component';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { SharedModule } from 'eddydata-lib';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FieldsetModule } from 'primeng/fieldset';

@NgModule({
  declarations: [RelatorioTesourariaComponent],
  imports: [
    CommonModule,
    RelatorioTesourariaRoutingModule,
    SharedModule,
    FormsModule,
    CommonModule,
    CalendarModule,
    AutoCompleteModule,
    RadioButtonModule,
    FieldsetModule
  ]
})
export class RelatorioTesourariaModule { }
