import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { PrazoFormComponent } from './prazo-form/prazo-form.component';
import { PrazoRoutingModule } from './prazo-routing.module';
import { SharedModule } from 'eddydata-lib';
import { PrazoListComponent } from './prazo-list/prazo-list.component';

@NgModule({
  declarations: [PrazoListComponent, PrazoFormComponent],
  imports: [
    CommonModule,
    PrazoRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    CalendarModule,
    IMaskModule,
    ToastModule
  ]
})
export class PrazoModule { }
