import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { LicitacaoService } from 'administrativo-lib';
import { BaseResourceFormComponent, Exercicio, ExercicioService, FuncaoService, GlobalService, IndiceCorrecao, LoginContabil, Modalidade, TipoContratacao, TipoContratacaoModalidade } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { TipoContratacaoService } from '../../tipo-contratacao/service/tipo-contratacao.service';
import { IndiceCorrecaoService } from '../services/indice-correcao.service';

declare var $: any;

@Component({
  selector: 'lib-indice-correcao-form',
  templateUrl: './indice-correcao-form.component.html'
})
export class IndiceCorrecaoFormComponent extends BaseResourceFormComponent<IndiceCorrecao, LoginContabil> {

  /**
   * Declaração de variáveis
   */

  public mesesObj: any = {
    jan: { nome: 'JANEIRO', index: 1 },
    fav: { nome: 'FEVEREIRO', index: 2 },
    mar: { nome: 'MARÇO', index: 3 },
    abr: { nome: 'ABRIL', index: 4 },
    mai: { nome: 'MAIO', index: 5 },
    jun: { nome: 'JUNHO', index: 6 },
    jul: { nome: 'JULHO', index: 7 },
    ago: { nome: 'AGOSTO', index: 8 },
    set: { nome: 'SETEMBRO', index: 9 },
    out: { nome: 'OUTUBRO', index: 10 },
    nov: { nome: 'NOVEMBRO', index: 11 },
    dez: { nome: 'DEZEMBRO', index: 12 },
  };

  public selectMes: number;

  public listaExercicios: Array<any>;
  public meses = []

  public imaskConfigIndice = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected tipoContratacaoService: TipoContratacaoService,
    protected licitacaoService: LicitacaoService,
    protected indiceCorrecaoService: IndiceCorrecaoService,
    protected exercicioService: ExercicioService) {
    super(new Modalidade(), injector, Modalidade.converteJson, indiceCorrecaoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      mes: [null, [Validators.required]],
      ano: [null, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      indice: [null, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]]
    });
  }

  // ngOnInit() {

  //   if (this.login) {
  //     this.loadExercicio();
  //   }

  // }

  protected parametrosExtras(): {} {
    return { };
  }

  protected afterLoad() {

  }

  protected afterInit(): void {
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaExercicios = dados.content;
      });
    this.loadMes()
  }

  protected beforeSubmit() {
    try {
      console.log(this.entidadeForm)
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(ent: Modalidade) {
  }

  loadMes() {
    this.meses = Object.entries(this.mesesObj).map((k) => k[1]);
    this.selectMes = this.meses.find((m) => m.index === this.login.mesReferencia);
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  compareFnMes(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.index === c2.index : c1 === c2;
  }
}
