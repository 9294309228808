// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { EventoFolha } from './evento-folha.model';
import { Funcionario } from './funcionario.model';
import { Referencia } from './referencia.model';

export class FuncionarioEvento extends BaseResourceModel {
  constructor(
    public id?: number,
    public quantidade?: number,
    public valor?: number,
    public funcionario?: Funcionario,
    public evento?: EventoFolha,
    public referencia?: Referencia,
    public fixo?: boolean,
    public tipo_valor?: string,
    public editavel?: boolean,
    public sequencia?: number
  ) {
    super();
  }
  static converteJson(json: any): FuncionarioEvento {
    return Object.assign(new FuncionarioEvento(), json);
  }
}
