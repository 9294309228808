// tslint:disable: variable-name
import { EmissaoCheque } from 'contabil-lib';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { RecursoSiconfi } from '../planejamento/recurso-siconfi.model';
import { Banco } from './banco.model';
import { ContaBancariaEntidade } from './conta-bancaria-entidade.model';
import { ContaBancariaRecurso } from './conta-bancaria-recurso.model';
import { ContaBancariaSaldo } from './conta-bancaria-saldo.model';
import { Pagamento } from './pagamento.model';

export class ContaBancaria extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: number,
    public nome?: string,
    public agencia?: string,
    public agencia_nome?: string,
    public agencia_digito?: string,
    public tipo_conta?: string,
    public digito_agencia?: string,
    public numero_conta?: string,
    public digito_conta?: string,
    public gerente?: string,
    public assinatura?: string,
    public cargo?: string,
    public convenio_febraban?: string,
    public obn_convenio?: boolean,
    public obn_contrato?: string,
    public ativo?: boolean,
    public caixa?: boolean,
    public aux?: number,
    public orgao?: Orgao,
    public banco?: Banco,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public entidade?: Orgao,
    public conta_entidade?: ContaBancariaEntidade,
    public saldos?: ContaBancariaSaldo[],
    public recursos?: ContaBancariaRecurso[],
    public pagamentos?: Pagamento[],
    public cheques?: EmissaoCheque[],
    public recurso_siconfi?: RecursoSiconfi,
  ) {
    super();
  }

  static converteJson(json: any): ContaBancaria {
    return Object.assign(new ContaBancaria(), json);
  }
}