import { BaseResourceModel } from '../../models/base-resource.model';
import { Funcionario } from '../folha/funcionario.model';
import { Viagem } from './viagem.model';

export class ViagemPassageiro extends BaseResourceModel {
  constructor(
    public id?: number,
    public motorista?: boolean,
    public passageiro?: Funcionario,
    public viagem?: Viagem,
    public editavel?: boolean,
  ) {
    super();
  }

  static converteJson(json: any): ViagemPassageiro {
    return Object.assign(new ViagemPassageiro(), json);
  }
}
