import { BaseResourceStorage } from '../../models/base-resource.storage';
import { Tombamento } from './tombamento.model';

export class TombamentoStorage extends BaseResourceStorage {
  constructor(
    public foto?: boolean,
    public principal?: boolean,
    public tombamento?: Tombamento,
  ) {
    super();
  }

  static converteJson(json: any): TombamentoStorage {
    return Object.assign(new TombamentoStorage(), json);
  }
}
