import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BaseResourceListComponent, Coluna, DateFormatPipe, Filtro, FormatoExportacao, GlobalService, LoginPublico, Recebimento
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RecebimentoService } from '../service/recebimento.service';

@Component({
  selector: 'lib-recebimento-lst',
  templateUrl: './recebimento-lst.component.html'
})
export class RecebimentoLstComponent extends BaseResourceListComponent<Recebimento, LoginPublico> {

  public ptBR: any;
  public titulo = 'RECEITAS ORÇAMENTÁRIAS';
  public especie = 'todas';
  public orgaoId = 1;
  public data1: Date;
  public data2: Date;

  private datepipe: DatePipe;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private recebimentoService: RecebimentoService) {
    super(recebimentoService, injector);
    this.datepipe = new DatePipe('pt');
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.especie = params['especie'];
        this.orgaoId = this.login.orgao.id;
        this.data1 = new DateFormatPipe().transform(params['data1'], []);
        this.data2 = new DateFormatPipe().transform(params['data2'], []);
        this.paginaCorrente = +params['pagina'];
        this.filtro = params['filtro'];
      });
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'conta,conta.banco,ficha,ficha.receita,ficha.recurso,ficha.aplicacao,exercicio,orgao';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['data_recebimento$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      text: ['receita.codigo', 'receita.nome'],
    };
  }

  public editar(id: number) {
    const dt1 = this.datepipe.transform(this.data1, 'yyyy-MM-dd', 'GMT');
    const dt2 = this.datepipe.transform(this.data2, 'yyyy-MM-dd', 'GMT');
    if (!this.filtro || this.filtro === '') {
      this.router.navigate([
        `/receitas-orcamentarias/visualiza`, id, this.especie, this.paginaCorrente, dt1, dt2]);
    } else {
      this.router.navigate([
        `/receitas-orcamentarias/visualiza`, id, this.especie, this.paginaCorrente, dt1, dt2, this.filtro]);
    }
  }


  public despesaCovid() {
    const dt1 = this.datepipe.transform(this.data1, 'yyyy-MM-dd', 'GMT');
    const dt2 = this.datepipe.transform(this.data2, 'yyyy-MM-dd', 'GMT');
    this.router.navigate([`/empenhos-orcamentarios/pesquisa`, 'covid', 1, dt1, dt2]);
  }


  public beforeInit(): void {
    if (this.login.exercicio && !this.data1) {
      this.data1 = new Date(`01/01/${this.login.exercicio.ano}`);
      if (this.login.exercicio.ano !== new Date().getFullYear()) {
        this.data2 = new Date(`12/31/${this.login.exercicio.ano}`);
      }
    }
  }

  protected afterInit(): void {
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
    window.scrollTo(0, 0);
    this.ptBR = this.globalService.obterDataBR();
  }

  protected acaoRemover(model: Recebimento): Observable<Recebimento> {
    return null;
  }

  public obterParametros() {
    const parametros = {};
    parametros['ignoreCondObrig'] = true;
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['data_recebimento$ge'] = this.datepipe.transform(this.data1, 'yyyy-MM-dd', 'GMT');
    parametros['data_recebimento$le'] = this.datepipe.transform(this.data2, 'yyyy-MM-dd', 'GMT');
    if (this.especie === 'todos') {
      this.titulo = 'RECEITAS ORÇAMENTÁRIAS';
    } else if (this.especie === 'impostos') {
      parametros['ficha.receita.codigo$like'] = '1118%';
      this.titulo = 'ARRECADAÇÃO DE IMPOSTOS';
    } else if (this.especie === 'estaduais') {
      parametros['ficha.receita.codigo$like'] = '1718%';
      this.titulo = 'TRANSFERÊNCIAS ESTADUAIS';
    } else if (this.especie === 'federais') {
      parametros['ficha.receita.codigo$like'] = '1758%';
      this.titulo = 'TRANSFERÊNCIAS FEDERAIS';
    } else if (this.especie === 'operacoes') {
      parametros['ficha.receita.codigo$like'] = '21%';
      this.titulo = 'OPERAÇÕES DE CRÉDITO';
    } else if (this.especie === 'covid') {
      parametros['ficha.aplicacao.codigo'] = '312';
      this.titulo = 'RECEBIMENTOS COVID-19';
    }

    if (this.filtro) {
      const filtroOR = this.filtro.replace('.', '').replace(',', '.');
      parametros['OR'] =
        `ficha.receita.nome$like=${filtroOR}%;!;!;ficha.receita.codigo$like=${filtroOR}%;!;!;conta.numero_conta$like=${filtroOR}%;!;!;` +
        `conta.agencia$like=${filtroOR}%;!;!;conta.banco.nome$like=${filtroOR}%;!;!;valor_recebido=${filtroOR}`;
    }

    parametros['relations'] = 'conta,conta.banco,ficha,ficha.receita,exercicio,orgao';

    switch (this.col) {
      case 1:
        parametros['orderBy'] = `data_recebimento$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 2:
        parametros['orderBy'] = `ficha.receita.nome$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 3:
        parametros['orderBy'] = `conta.banco.nome$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 4:
        parametros['orderBy'] = `valor_recebido$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      default:
        break;
    }

    return parametros;
  }

  public preencherGrid() {
    const parametros = this.obterParametros();
    this.recebimentoService
      .transparencia(
        this.paginaCorrente,
        this.login.limite,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.lista = lista.content;
          this.paginaTotal = lista.totalPages;
        },
        () => alert('erro ao retornar lista')
      );
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Data', coluna: 'data_recebimento' },
      { titulo: 'Código', coluna: 'ficha.receita.codigo' },
      { titulo: 'Descrição', coluna: 'ficha.receita.nome' },
      { titulo: 'Banco', coluna: 'conta.banco.nome' },
      { titulo: 'Agência nome', coluna: 'conta.agencia_nome' },
      { titulo: 'Número conta', coluna: 'conta.numero_conta' },
      { titulo: 'Valor', coluna: 'valor_recebido', decimais: 2, alignment: 'right' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    this.recebimentoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE RECEBIMENTOS ORÇAMENTÁRIOS',
              '', '', this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem recebimentos', ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'], lista.content, ['valor_recebido']);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public onSelectPeriodo() {
    this.preencherGrid();
  }

}
