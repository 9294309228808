
import { Injectable } from '@angular/core';
import { PNCPService } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class AmparoLegalService {

  constructor(
    private pncpService: PNCPService
  ) {

  }

  buscarAmparoLegalPncp(): Array<{ codigo: number, lei: string }> {
    const lista = [];

    lista.push({ codigo: 1, lei: 'Lei 14.133/2021, Art. 28, I	' });
    lista.push({ codigo: 2, lei: 'Lei 14.133/2021, Art. 28, II	' });
    lista.push({ codigo: 3, lei: 'Lei 14.133/2021, Art. 28, III	' });
    lista.push({ codigo: 4, lei: 'Lei 14.133/2021, Art. 28, IV	' });
    lista.push({ codigo: 5, lei: 'Lei 14.133/2021, Art. 28, V	' });
    lista.push({ codigo: 6, lei: 'Lei 14.133/2021, Art. 74, I	' });
    lista.push({ codigo: 7, lei: 'Lei 14.133/2021, Art. 74, II	' });
    lista.push({ codigo: 8, lei: 'Lei 14.133/2021, Art. 74, III, a	' });
    lista.push({ codigo: 9, lei: 'Lei 14.133/2021, Art. 74, III, b	' });
    lista.push({ codigo: 10, lei: 'Lei 14.133/2021, Art. 74, III, c	' });
    lista.push({ codigo: 11, lei: 'Lei 14.133/2021, Art. 74, III, d	' });
    lista.push({ codigo: 12, lei: 'Lei 14.133/2021, Art. 74, III, e	' });
    lista.push({ codigo: 13, lei: 'Lei 14.133/2021, Art. 74, III, f	' });
    lista.push({ codigo: 14, lei: 'Lei 14.133/2021, Art. 74, III, g	' });
    lista.push({ codigo: 15, lei: 'Lei 14.133/2021, Art. 74, III, h	' });
    lista.push({ codigo: 16, lei: 'Lei 14.133/2021, Art. 74, IV	' });
    lista.push({ codigo: 17, lei: 'Lei 14.133/2021, Art. 74, V	' });
    lista.push({ codigo: 18, lei: 'Lei 14.133/2021, Art. 75, I	' });
    lista.push({ codigo: 19, lei: 'Lei 14.133/2021, Art. 75, II	' });
    lista.push({ codigo: 20, lei: 'Lei 14.133/2021, Art. 75, III, a	' });
    lista.push({ codigo: 21, lei: 'Lei 14.133/2021, Art. 75, III, b	' });
    lista.push({ codigo: 22, lei: 'Lei 14.133/2021, Art. 75, IV, a	' });
    lista.push({ codigo: 23, lei: 'Lei 14.133/2021, Art. 75, IV, b	' });
    lista.push({ codigo: 24, lei: 'Lei 14.133/2021, Art. 75, IV, c	' });
    lista.push({ codigo: 25, lei: 'Lei 14.133/2021, Art. 75, IV, d	' });
    lista.push({ codigo: 26, lei: 'Lei 14.133/2021, Art. 75, IV, e	' });
    lista.push({ codigo: 27, lei: 'Lei 14.133/2021, Art. 75, IV, f	' });
    lista.push({ codigo: 28, lei: 'Lei 14.133/2021, Art. 75, IV, g	' });
    lista.push({ codigo: 29, lei: 'Lei 14.133/2021, Art. 75, IV, h	' });
    lista.push({ codigo: 30, lei: 'Lei 14.133/2021, Art. 75, IV, i	' });
    lista.push({ codigo: 31, lei: 'Lei 14.133/2021, Art. 75, IV, j	' });
    lista.push({ codigo: 32, lei: 'Lei 14.133/2021, Art. 75, IV, k	' });
    lista.push({ codigo: 33, lei: 'Lei 14.133/2021, Art. 75, IV, l	' });
    lista.push({ codigo: 34, lei: 'Lei 14.133/2021, Art. 75, IV, m	' });
    lista.push({ codigo: 35, lei: 'Lei 14.133/2021, Art. 75, V	' });
    lista.push({ codigo: 36, lei: 'Lei 14.133/2021, Art. 75, VI	' });
    lista.push({ codigo: 37, lei: 'Lei 14.133/2021, Art. 75, VII	' });
    lista.push({ codigo: 38, lei: 'Lei 14.133/2021, Art. 75, VIII	' });
    lista.push({ codigo: 39, lei: 'Lei 14.133/2021, Art. 75, IX	' });
    lista.push({ codigo: 40, lei: 'Lei 14.133/2021, Art. 75, X	' });
    lista.push({ codigo: 41, lei: 'Lei 14.133/2021, Art. 75, XI	' });
    lista.push({ codigo: 42, lei: 'Lei 14.133/2021, Art. 75, XII	' });
    lista.push({ codigo: 43, lei: 'Lei 14.133/2021, Art. 75, XIII	' });
    lista.push({ codigo: 44, lei: 'Lei 14.133/2021, Art. 75, XIV 	' });
    lista.push({ codigo: 45, lei: 'Lei 14.133/2021, Art. 75, XV 	' });
    lista.push({ codigo: 46, lei: 'Lei 14.133/2021, Art. 75, XVI	' });
    // lista.push({ codigo: 47, lei: 'Lei 14.133/2021, Art. 78, I 	' });//Inativado no PNCP
    lista.push({ codigo: 48, lei: 'Lei 14.133/2021, Art. 78, II	 ' });
    lista.push({ codigo: 49, lei: 'Lei 14.133/2021, Art. 78, III	' });
    lista.push({ codigo: 50, lei: 'Lei 14.133/2021, Art. 74, caput	' });
    lista.push({ codigo: 51, lei: 'Lei 14.284/2021, Art. 29, caput	' });
    lista.push({ codigo: 52, lei: 'Lei 14.284/2021, Art. 24 § 1º  ' });
    lista.push({ codigo: 53, lei: 'Lei 14.284/2021, Art. 25 § 1º	' });
    lista.push({ codigo: 54, lei: 'Lei 14.284/2021, Art. 34	' });
    lista.push({ codigo: 55, lei: 'Lei 9.636/1998, Art. 11-C, I	' });
    lista.push({ codigo: 56, lei: 'Lei 9.636/1998, Art. 11-C, II	' });
    lista.push({ codigo: 57, lei: 'Lei 9.636/1998, Art. 24-C, I	' });
    lista.push({ codigo: 58, lei: 'Lei 9.636/1998, Art. 24-C, II	' });
    lista.push({ codigo: 59, lei: 'Lei 9.636/1998, Art. 24-C, III	' });
    lista.push({ codigo: 60, lei: 'Lei 14.133/2021, Art. 75, XVII	 ' });
    lista.push({ codigo: 61, lei: 'Lei 14.133/2021, Art. 76, I, a	 ' });
    lista.push({ codigo: 62, lei: 'Lei 14.133/2021, Art. 76, I, b	 ' });
    lista.push({ codigo: 63, lei: 'Lei 14.133/2021, Art. 76, I, c	 ' });
    lista.push({ codigo: 64, lei: 'Lei 14.133/2021, Art. 76, I, d	 ' });
    lista.push({ codigo: 65, lei: 'Lei 14.133/2021, Art. 76, I, e	 ' });
    lista.push({ codigo: 66, lei: 'Lei 14.133/2021, Art. 76, I, f	 ' });
    lista.push({ codigo: 67, lei: 'Lei 14.133/2021, Art. 76, I, g	 ' });
    lista.push({ codigo: 68, lei: 'Lei 14.133/2021, Art. 76, I, h	 ' });
    lista.push({ codigo: 69, lei: 'Lei 14.133/2021, Art. 76, I, i	 ' });
    lista.push({ codigo: 70, lei: 'Lei 14.133/2021, Art. 76, I, j  ' });
    lista.push({ codigo: 71, lei: 'Lei 14.133/2021, Art. 76, II, a  ' });
    lista.push({ codigo: 72, lei: 'Lei 14.133/2021, Art. 76, II, b  ' });
    lista.push({ codigo: 73, lei: 'Lei 14.133/2021, Art. 76, II, c  ' });
    lista.push({ codigo: 74, lei: 'Lei 14.133/2021, Art. 76, II, d  ' });
    lista.push({ codigo: 75, lei: 'Lei 14.133/2021, Art. 76, II, e  ' });
    lista.push({ codigo: 76, lei: 'Lei 14.133/2021, Art. 76, II, f  ' });
    lista.push({ codigo: 77, lei: 'Lei 14.133/2021, Art. 75, XVIII 	' });
    lista.push({ codigo: 78, lei: 'Lei 14.628/2023, Art. 4º 	' });
    lista.push({ codigo: 79, lei: 'Lei 14.628/2023, Art. 12 	' });
    lista.push({ codigo: 80, lei: 'Lei 14.133/2021, Art. 1º, § 2º  ' });
    lista.push({ codigo: 81, lei: 'Lei 13.303/2016, Art. 27, § 3º' });
    lista.push({ codigo: 82, lei: 'Lei 13.303/2016, Art. 28, § 3º, I' });
    lista.push({ codigo: 83, lei: 'Lei 13.303/2016, Art. 28, § 3º, II' });
    lista.push({ codigo: 84, lei: 'Lei 13.303/2016, Art. 29, I' });
    lista.push({ codigo: 85, lei: 'Lei 13.303/2016, Art. 29, II' });
    lista.push({ codigo: 86, lei: 'Lei 13.303/2016, Art. 29, III' });
    lista.push({ codigo: 87, lei: 'Lei 13.303/2016, Art. 29, IV' });
    lista.push({ codigo: 88, lei: 'Lei 13.303/2016, Art. 29, V' });
    lista.push({ codigo: 89, lei: 'Lei 13.303/2016, Art. 29, VI' });
    lista.push({ codigo: 90, lei: 'Lei 13.303/2016, Art. 29, VII' });
    lista.push({ codigo: 91, lei: 'Lei 13.303/2016, Art. 29, VIII' });
    lista.push({ codigo: 92, lei: 'Lei 13.303/2016, Art. 29, IX' });
    lista.push({ codigo: 93, lei: 'Lei 13.303/2016, Art. 29, X' });
    lista.push({ codigo: 94, lei: 'Lei 13.303/2016, Art. 29, XI' });
    lista.push({ codigo: 95, lei: 'Lei 13.303/2016, Art. 29, XII' });
    lista.push({ codigo: 96, lei: 'Lei 13.303/2016, Art. 29, XIII' });
    lista.push({ codigo: 97, lei: 'Lei 13.303/2016, Art. 29, XIV' });
    lista.push({ codigo: 98, lei: 'Lei 13.303/2016, Art. 29, XV' });
    lista.push({ codigo: 99, lei: 'Lei 13.303/2016, Art. 29, XVI' });
    lista.push({ codigo: 100, lei: 'Lei 13.303/2016, Art. 29, XVII' });
    lista.push({ codigo: 101, lei: 'Lei 13.303/2016, Art. 29, XVIII' });
    lista.push({ codigo: 102, lei: 'Lei 13.303/2016, Art. 30, caput - inexigibilidade' });
    lista.push({ codigo: 103, lei: 'Lei 13.303/2016, Art. 30, caput - credenciamento' });
    lista.push({ codigo: 104, lei: 'Lei 13.303/2016, Art. 30, I' });
    lista.push({ codigo: 105, lei: 'Lei 13.303/2016, Art. 30, II, a' });
    lista.push({ codigo: 106, lei: 'Lei 13.303/2016, Art. 30, II, b' });
    lista.push({ codigo: 107, lei: 'Lei 13.303/2016, Art. 30, II, c' });
    lista.push({ codigo: 108, lei: 'Lei 13.303/2016, Art. 30, II, d' });
    lista.push({ codigo: 109, lei: 'Lei 13.303/2016, Art. 30, II, e' });
    lista.push({ codigo: 110, lei: 'Lei 13.303/2016, Art. 30, II, f' });
    lista.push({ codigo: 111, lei: 'Lei 13.303/2016, Art. 30, II, g' });
    lista.push({ codigo: 112, lei: 'Lei 13.303/2016, Art. 31, § 4º' });
    lista.push({ codigo: 113, lei: 'Lei 13.303/2016, Art. 32, IV' });
    lista.push({ codigo: 114, lei: 'Lei 13.303/2016, Art. 54, I' });
    lista.push({ codigo: 115, lei: 'Lei 13.303/2016, Art. 54, II' });
    lista.push({ codigo: 116, lei: 'Lei 13.303/2016, Art. 54, III' });
    lista.push({ codigo: 117, lei: 'Lei 13.303/2016, Art. 54, IV' });
    lista.push({ codigo: 118, lei: 'Lei 13.303/2016, Art. 54, V' });
    lista.push({ codigo: 119, lei: 'Lei 13.303/2016, Art. 54, VI' });
    lista.push({ codigo: 120, lei: 'Lei 13.303/2016, Art. 54, VII' });
    lista.push({ codigo: 121, lei: 'Lei 13.303/2016, Art. 54, VIII' });
    lista.push({ codigo: 122, lei: 'Lei 13.303/2016, Art. 63, I' });
    lista.push({ codigo: 123, lei: 'Lei 13.303/2016, Art. 63, III' });
    lista.push({ codigo: 124, lei: 'Regulamento Interno de Licitações e Contratos Estatais - diálogo competitivo' });
    lista.push({ codigo: 125, lei: 'Regulamento Interno de Licitações e Contratos Estatais - credenciamento' });
    lista.push({ codigo: 126, lei: 'Lei 12.850/2013, Art. 3º, §1º, II' });
    lista.push({ codigo: 127, lei: 'Lei 12.850/2013, Art. 3º, §1º, V' });
    lista.push({ codigo: 128, lei: 'Lei 13.529/2017, Art. 5º' });
    lista.push({ codigo: 129, lei: 'Lei 8.629/1993, Art. 17, § 3º, V' });
    lista.push({ codigo: 130, lei: 'Lei 10.847/2004, Art. 6º' });
    lista.push({ codigo: 131, lei: 'Lei 11.516/2007, Art. 14-A' });
    lista.push({ codigo: 132, lei: 'Lei 11.652/2008, Art. 8º, § 2º, I' });
    lista.push({ codigo: 133, lei: 'Lei 11.652/2008, Art. 8º, § 2º, II' });
    lista.push({ codigo: 134, lei: 'Lei 11.759/2008, Art. 18-A' });
    lista.push({ codigo: 135, lei: 'Lei 12.865/2013, Art. 18, § 1º' });
    lista.push({ codigo: 136, lei: 'Lei 12.873/2013, Art. 42' });
    lista.push({ codigo: 137, lei: 'Lei 13.979/2020, Art. 4º, § 1º' });
    lista.push({ codigo: 138, lei: 'Lei 11.947/2009, Art. 14, 1º' });
    lista.push({ codigo: 139, lei: 'Lei 11.947/2009, Art. 21' });
    lista.push({ codigo: 140, lei: 'Lei 14.133/2021, Art. 79, I' });
    lista.push({ codigo: 141, lei: 'Lei 14.133/2021, Art. 79, II' });
    lista.push({ codigo: 142, lei: 'Lei 14.133/2021, Art. 79, III' });
    lista.push({ codigo: 149, lei: 'MP nº 1.221/2024, art. 2º, I (Calamidade pública)' })
    lista.push({ codigo: 150, lei: 'MP nº 1.221/2024, art. 2º, IV (Calamidade pública)' })

    return lista;
  }

  buscarAmparoPorCodigo(codigo: number): { codigo: number, lei: string } {
    return this.buscarAmparoLegalPncp().find(i => i.codigo == codigo);
  }


  async buscarAmparosPNCP(tipoAmparoLegalId: number, somenteAtivos?: boolean): Promise<{ codigo: number; lei: string; }[]> {
    const amparos = await this.pncpService.consultarAmparosPNCP(tipoAmparoLegalId, somenteAtivos);
    return amparos.map(amparo => {
      return { codigo: amparo.id, lei: amparo.nome }
    })
  }
}

