// tslint:disable: variable-name

import { BaseResourceModel } from '../../models/base-resource.model';
import { SicHistorico } from './sic-historico.model';

export class SicArquivo extends BaseResourceModel {
    constructor(
        public id?: number,
        public nome?: string,
        public caminho?: string,
        public tipo?: string,
        public documento?: any,
        public tamanho?: number,
        public historico?: SicHistorico,
        public data_cadastro?: Date,
        public data_alteracao?: Date,
    ) {
        super();
    }
    static converteJson(json: any): SicArquivo {
        return Object.assign(new SicArquivo(), json);
    }
}
