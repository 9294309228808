// tslint:disable: variable-name
import { Ppa } from './ppa.model';
import { BaseResourceModel } from '../../models/base-resource.model';
import { AudienciaArquivo } from './audiencia-arquivo.model';
import { Orgao } from '../comum/orgao.model';

export class AudienciaPublica extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_audiencia?: Date,
    public titulo?: string,
    public descricao?: string,
    public ano?: number,
    public ppa?: Ppa,
    public orgao?: Orgao,
    public arquivos?: AudienciaArquivo[],
    public situacao?: 'A' | 'F'
  ) {
    super();
  }

  static converteJson(json: any): AudienciaPublica {
    return Object.assign(new AudienciaPublica(), json);
  }
}
