import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FuncaoService, LoginContabil, Exercicio, GlobalService, OrgaoAssinaturaService, Orgao } from 'eddydata-lib';
import { BalancoService } from '../service/balanco.service';
import { NotaExplicativaService } from '../service/nota-explicativa.service';
import { BaseResourceNotaExplicativa } from '../base-resource-nota-explicativa';

@Injectable({
  providedIn: 'root'
})
export class Anexo18Dfc extends BaseResourceNotaExplicativa implements OnDestroy {

  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: BalancoService,
    protected notaService: NotaExplicativaService,
    protected assinaturaService: OrgaoAssinaturaService
  ) {
    super('B18DFC', notaService, assinaturaService)
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(dados: any, exercicio: Exercicio): {}[] {

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: '', colSpan: 3, border: [true, true, true, false]
      }, '', ''],
      [{
        text: `ANEXO 18 - DEMONSTRAÇÃO DOS FLUXOS DE CAIXA`, fontSize: 13,
        alignment: 'center', bold: true, colSpan: 3, border: [true, false, true, true]
      }, '', ''],
      [{
        text: 'TÍTULOS',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'EXERCÍCIO ATUAL',
        alignment: 'center',
        bold: true, fontSize: 8,
      }, {
        text: 'EXERCÍCIO ANTERIOR',
        alignment: 'center',
        bold: true, fontSize: 8
      }]
    ];

    // monta o agrupamento do relatório
    const titulos = this.funcaoService.agrupar(dados[0], 'titulo', ['valor_atual', 'valor_anterior']);
    let totalGeral1 = 0;
    let totalGeral2 = 0;
    for (const titulo of titulos) {
      const tituloGrupo = titulo.grupo;    
      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['valor_atual', 'valor_anterior']);
      for (const grupo of grupos) {
        registros.push([
          {
            text: grupo.grupo, fontSize: 8, border: [true, false, true, false], bold: true, decoration: 'underline'
          },
          { text: '', border: [true, false, true, false] },
          { text: '', border: [true, false, true, false] }
        ]);

        let total11 = 0;
        let total12 = 0;
        let total21 = 0;
        let total22 = 0;
        const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['valor_atual', 'valor_anterior']);
        for (const subgrupo of subgrupos) {
          if (subgrupo.grupo === 'INGRESSOS') {
            total11 += +subgrupo.totalizadores[`valor_atual`];
            total12 += +subgrupo.totalizadores[`valor_anterior`]; 
          } else {
            total21 += +subgrupo.totalizadores[`valor_atual`];
            total22 += +subgrupo.totalizadores[`valor_anterior`];
          }

          registros.push([
            {
              text: subgrupo.grupo, fontSize: 8, border: [true, false, true, false], bold: true, decoration: 'underline'
            },
            {
              text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['valor_atual']), alignment: 'right',
              fontSize: 8, border: [true, false, true, false], bold: true, decoration: 'underline'
            },
            {
              text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['valor_anterior']), alignment: 'right',
              fontSize: 8, border: [true, false, true, false], bold: true, decoration: 'underline'
            }
          ]);

          // lista os registros do relatorio
          for (const registro of subgrupo.registros) {
            registros.push([
              {
                text: registro.nome, fontSize: 8, border: [true, false, true, false]
              },
              {
                text: this.funcaoService.convertToBrNumber(registro.valor_atual), alignment: 'right',
                fontSize: 8, border: [true, false, true, false]
              },
              {
                text: this.funcaoService.convertToBrNumber(registro.valor_anterior), alignment: 'right',
                fontSize: 8, border: [true, false, true, false]
              }
            ]);
          }
        }

        totalGeral1 += (total11 - total21);
        totalGeral2 += (total12 - total22);

        registros.push([
          {
            text: tituloGrupo, bold: true, fontSize: 9, border: [true, true, true, true], alignment: 'center'
          },
          {
            text: this.funcaoService.convertToBrNumber(total11 - total21), alignment: 'right',
            bold: true, fontSize: 9, border: [true, true, true, true]
          },
          {
            text: this.funcaoService.convertToBrNumber(total12 - total22), alignment: 'right',
            bold: true, fontSize: 9, border: [true, true, true, true]
          }
        ]);
      }
    }

    // CAIXA E EQUIVALENTE DE CAIXA -------------------------------------------------------------------------------------------
    registros.push([
      {
        text: 'GERAÇÃO LÍQUIDA DE CAIXA E EQUIVALENTE DE CAIXA (I + II + III)', fontSize: 8, border: [true, false, true, false],
        bold: true
      },
      {
        text: this.funcaoService.convertToBrNumber(totalGeral1), alignment: 'right',
        bold: true,
        fontSize: 8, border: [true, false, true, false]
      },
      {
        text: this.funcaoService.convertToBrNumber(totalGeral2), alignment: 'right',
        bold: true,
        fontSize: 8, border: [true, false, true, false]
      }
    ]);

    for (const caixa of dados[1]) {
      registros.push([
        {
          text: caixa.nome, fontSize: 8, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(caixa.valor_atual), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(caixa.valor_anterior), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        }
      ]);
    }

    registros.push([
      {
        text: '', fontSize: 8, border: [false, true, false, false], colSpan: 3
      },
      {
        text: ''
      },
      {
        text: ''
      }
    ]);

    return registros;
  }

  public montarRelatorioCSV(dados: any): {}[] {

    // monta o cabecalho
    const registros: {}[] = [[{ text: `ANEXO 18 - DEMONSTRAÇÃO DOS FLUXOS DE CAIXA` }], [{ text: 'TÍTULOS' }, { text: 'EXERCÍCIO ATUAL' }, { text: 'EXERCÍCIO ANTERIOR' }]];

    // monta o agrupamento do relatório
    const titulos = this.funcaoService.agrupar(dados[0], 'titulo', ['valor_atual', 'valor_anterior']);
    let totalGeral1 = 0;
    let totalGeral2 = 0;
    for (const titulo of titulos) {
      const tituloGrupo = titulo.grupo;    
      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo', ['valor_atual', 'valor_anterior']);
      for (const grupo of grupos) {
        registros.push([{ text: grupo.grupo }]);

        let total11 = 0;
        let total12 = 0;
        let total21 = 0;
        let total22 = 0;
        const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['valor_atual', 'valor_anterior']);
        for (const subgrupo of subgrupos) {
          if (subgrupo.grupo === 'INGRESSOS') {
            total11 += +subgrupo.totalizadores[`valor_atual`];
            total12 += +subgrupo.totalizadores[`valor_anterior`]; 
          } else {
            total21 += +subgrupo.totalizadores[`valor_atual`];
            total22 += +subgrupo.totalizadores[`valor_anterior`];
          }

          registros.push([
            { text: subgrupo.grupo },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['valor_atual']) },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['valor_anterior']) },
          ]);

          // lista os registros do relatorio
          for (const registro of subgrupo.registros) {
            registros.push([
              { text: registro.nome },
              { text: this.funcaoService.convertToBrNumber(registro.valor_atual) },
              { text: this.funcaoService.convertToBrNumber(registro.valor_anterior) },
            ]);
          }
        }

        totalGeral1 += (total11 - total21);
        totalGeral2 += (total12 - total22);

        registros.push([
          { text: tituloGrupo },
          { text: this.funcaoService.convertToBrNumber(total11 - total21) },
          { text: this.funcaoService.convertToBrNumber(total12 - total22) },
        ]);
      }
    }

    // CAIXA E EQUIVALENTE DE CAIXA -------------------------------------------------------------------------------------------
    registros.push([
      { text: 'GERAÇÃO LÍQUIDA DE CAIXA E EQUIVALENTE DE CAIXA (I + II + III)' },
      { text: this.funcaoService.convertToBrNumber(totalGeral1) },
      { text: this.funcaoService.convertToBrNumber(totalGeral2) },
    ]);

    for (const caixa of dados[1]) {
      registros.push([
        { text: caixa.nome },
        { text: this.funcaoService.convertToBrNumber(caixa.valor_atual) },
        { text: this.funcaoService.convertToBrNumber(caixa.valor_anterior) },
      ]);
    }

    return registros;
  }

}
