import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginPublico, Ppa, Exercicio, ExercicioService, OrgaoService, PpaService, TransparenciaParametro, GlobalService, Coluna, Relatorio, FuncaoService } from 'eddydata-lib';
import { AcaoGovernoService, IndicadorService, MetaGovernoService } from 'administrativo-lib';
import { Anexo1Ppa, Anexo2Ppa, Anexo3Ppa, FonteFinanciamentoService } from 'contabil-lib';
import { TransparenciaParametroService } from '../transparencia-parametro/service/transparencia-parametro.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-transparencia-loa',
  templateUrl: './transparencia-loa.component.html',
  styleUrls: ['./transparencia-loa.component.css']
})
export class TransparenciaLoaComponent implements OnInit, OnDestroy {
  public login: LoginPublico = new LoginPublico();
  protected unsubscribe: Subject<void> = new Subject();
  public parametro = new TransparenciaParametro();
  public orgaos: number[] = [];
  public listaPpa: [];
  public ppa: Ppa;
  public exerciciosPPA: Array<any>;
  public listaExercicios: [];
  public exercicio: Exercicio;
  public exercicioSelectPPA: number;
  public tipoRelatorio: string;

  constructor(
    protected messageService: MessageService,
    protected exercicioService: ExercicioService,
    protected orgaoService: OrgaoService,
    protected fonteService: FonteFinanciamentoService,
    protected ppaService: PpaService,
    protected metaService: MetaGovernoService,
    protected paramServico: TransparenciaParametroService,
    protected acaoService: AcaoGovernoService,
    protected indicadorService: IndicadorService,
  ) {
    this.login = GlobalService.obterSessaoLogin();
    for (let i = 1; i < 5; i++) {
      if (this.login.ppa.ppaperiodo[`ano${i}`] === this.login.exercicio.ano)
        this.exercicioSelectPPA = i;
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
    this.orgaos.push(this.login.orgao.id);
    this.ppaService.obterPorAno(this.login.exercicio.ano, this.login.cidade.id)
      .subscribe((res) => {
        this.ppa = res;
      });
    this.ppaService.filtrar(1, -1, {
      'ppaperiodo.cidade.id': this.login.cidade.id,
      relations: 'ppaperiodo,ppaperiodo.cidade'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaPpa = dados.content;
      });
    this.exercicioService.filtrar(1, -1, { 'cidade.id': this.login.cidade.id })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.exercicio = this.login.exercicio;
        this.listaExercicios = dados.content;
      });

    this.paramServico.obterParametro(this.login.orgao.id).subscribe(res => {
      this.parametro = res;
      this.parametro.orgao = this.login.orgao;
      if (!res) {
        this.parametro = new TransparenciaParametro();
        this.parametro.receita = false;
        this.parametro.receita_extra = false;
        this.parametro.empenho = false;
        this.parametro.empenho_extra = false;
        this.parametro.empenho_resto = false;
        this.parametro.liquidacao = false;
        this.parametro.liquidacao_resto = false;
        this.parametro.pagamento = false;
        this.parametro.pagamento_extra = false;
        this.parametro.pagamento_resto = false;
        this.parametro.contrato = false;
        this.parametro.licitacao = false;
        this.parametro.repasse = false;
        this.parametro.convenio = false;
        this.parametro.equipamento = false;
        this.parametro.ata = false;
        this.parametro.remuneracao = false;
        this.parametro.transf_orgao = false;
        this.parametro.transf_federal = false;
        this.parametro.transf_estadual = false;
        this.parametro.arrecadacao_imposto = false;
        this.parametro.planejamento = false;
        this.parametro.conta_publica = false;
        this.parametro.operacao_credito = false;
        this.parametro.covid_receita = false;
        this.parametro.covid_despesa = false;
        this.parametro.operacao_credito = false;
        this.parametro.acumular_desconto_holerite = false;
        this.parametro.mostrar_relatorio_loa = false;
        this.parametro.plan_url = 'http://';
        this.parametro.esic_url = '';
        this.parametro.data_atualizacao = new Date();
      } else {
        this.parametro.data_atualizacao = new Date(res.data_atualizacao);
      }
    });
    this.exerciciosPPA = [];
    this.exerciciosPPA.push({ numero: 1, ano: this.login.ppa.ppaperiodo.ano1 });
    this.exerciciosPPA.push({ numero: 2, ano: this.login.ppa.ppaperiodo.ano2 });
    this.exerciciosPPA.push({ numero: 3, ano: this.login.ppa.ppaperiodo.ano3 });
    this.exerciciosPPA.push({ numero: 4, ano: this.login.ppa.ppaperiodo.ano4 });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  anexoI() {
    if (this.parametro.mostrar_relatorio_loa) {
      const anexo = new Anexo1Ppa(this.fonteService);
      anexo.montarRelatorio(this.ppa, this.orgaos);
    } else {
      this.messageService.add({ severity: 'info', summary: 'Mensagem', detail: 'Anexo I ainda não disponível!' });
    }
  }

  anexoII() {
    if (this.parametro.mostrar_relatorio_loa) {
      const anexo = new Anexo2Ppa(this.metaService);
      anexo.montarRelatorio(this.ppa, this.orgaos);
    } else {
      this.messageService.add({ severity: 'info', summary: 'Mensagem', detail: 'Anexo II ainda não disponível!' });
    }
  }

  anexoIII() {
    if (this.parametro.mostrar_relatorio_loa) {
      const anexo = new Anexo3Ppa(this.acaoService);
      anexo.montarRelatorio(this.ppa, this.orgaos);
    } else {
      this.messageService.add({ severity: 'info', summary: 'Mensagem', detail: 'Anexo III ainda não disponível!' });
    }
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  public relatorioAcoes(){
    this.tipoRelatorio = 'acoes';
    
    const parametros = {
      ['ppa.id']: this.login.ppa.id,
      ['orderBy']: 'meta.programa.codigo$ASC',
      ['executora.unidade.orgao_id']: this.login.orgao.id
    };
    parametros['relations'] = 'meta,meta.programa,meta.funcao,meta.subfuncao,meta.unidade,indicador,acao,executora,executora.unidade,meta.funcao,meta.subfuncao';
    this.acaoService.filtrar(1, -1, parametros).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.imprimir(`Relatório de Atividades - Ações Exercício: ${this.login.ppa.ppaperiodo[`ano${this.exercicioSelectPPA}`]}`,
          this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
          'Listagem Metas de Governo', ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], lista.content);
        }, () => alert('erro ao retornar lista')
      );
  }

  public relatorioProgramas(){
    this.tipoRelatorio = 'programas';

    const parametros = {
      ['meta.ppa.id']: this.login.ppa.id,
      ['orderBy']: 'meta.programa.codigo$ASC',
      ['meta.unidade.orgao_id']: this.login.orgao.id
    };
    parametros['relations'] = 'meta,meta.programa,meta.funcao,meta.subfuncao,meta.unidade';
    this.indicadorService.filtrar(1, -1, parametros).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.imprimir(`Relatório de Atividades - Programas Exercício: ${this.login.ppa.ppaperiodo[`ano${this.exercicioSelectPPA}`]}`,
          this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
          'Listagem Metas de Governo', ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], lista.content);
        }, () => alert('erro ao retornar lista')
      );
  }

  public exportar(lista: any[], baseResourceService: IndicadorService | AcaoGovernoService, nome?: string) {
    if (!baseResourceService) return
    if (!nome) {
      nome = baseResourceService.retornarRota().startsWith('/') ? baseResourceService.retornarRota().substr(1) : baseResourceService.retornarRota();
    }
    const colunas = this.colunasRelatorio() as Coluna[];

    new FuncaoService().exportar('pdf', lista, nome, colunas);
  }

  public imprimir(
    titulo: string, usuario: string, sobrenome: string, orgao: string, brasao: string, orientacao: 'landscape' | 'portrait',
    nomepdf?: string, largura?: (string | number)[], lista?: any[], totalizar?: (string | {})[]) {
    Relatorio.imprimir(titulo, usuario, sobrenome, orgao, brasao, lista, this.colunasRelatorio(),
      orientacao, nomepdf, largura, totalizar);
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    if (this.tipoRelatorio === 'programas') {
      return [
        { titulo: 'Programa', coluna: 'meta.programa.codigo' },
        { titulo: 'Descrição Programa', coluna: 'meta.programa.nome' },
        { titulo: 'Indicador', coluna: 'codigo' },
        { titulo: 'Descrição Indicador', coluna: 'nome' },
        { titulo: 'Unidade', coluna: 'unidade_medida' },
        { titulo: 'Estimado', coluna: `meta${this.exercicioSelectPPA}` },
        { titulo: 'Realizado', coluna: `meta_realizada${this.exercicioSelectPPA}` },
        { titulo: 'Justificativa', coluna: `justificativa${this.exercicioSelectPPA}` },
      ];
    } else if (this.tipoRelatorio === 'acoes') {
      return [
        { titulo: 'Programa', coluna: 'meta.programa.codigo' },
        { titulo: 'Descrição Programa', coluna: 'meta.programa.nome' },
        { titulo: 'Ação', coluna: 'acao.codigo' },
        { titulo: 'Descrição Ação', coluna: 'acao.nome' },
        { titulo: 'Unid. Orçamentária', coluna: 'executora.unidade.nome' },
        { titulo: 'Unid. Executora', coluna: 'executora.nome' },
        { titulo: 'Função', coluna: 'meta.funcao.nome' },
        { titulo: 'Subfunção', coluna: 'meta.subfuncao.nome' },
        { titulo: 'Descrição Indicador', coluna: 'indicador.nome' },
        { titulo: 'Unidade', coluna: 'indicador.unidade_medida' },
        { titulo: 'Estimado', coluna: `indicador.meta${this.exercicioSelectPPA}` },
        { titulo: 'Realizado', coluna: `indicador.meta_realizada${this.exercicioSelectPPA}` },
        { titulo: 'Justificativa', coluna: `indicador.justificativa${this.exercicioSelectPPA}` }
      ];
    }
  }
}
