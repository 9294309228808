import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, PlanoContratacaoAnual } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class PlanoContratacaoAnualItensService extends BaseResourceService<PlanoContratacaoAnual> {

  constructor(
    protected injector: Injector
  ) {
    super(`plano-contratacao-anual-itens`, injector);
  }

}
