import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, DeParaTransparencia } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class DeParaTransparenciaService extends BaseResourceService<DeParaTransparencia> {

  constructor(
    protected injector: Injector
  ) {
    super(`depara-transparencia`, injector);
  }

}
