import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "eddydata-lib";
import { CreditoFormComponent } from "../credito/credito-form/credito-form.component";
import { CreditoViewComponent } from "../credito/credito-view/credito-view.component";
import { TransferenciaInternaListComponent } from "./transferencia-interna-list/transferencia-interna-list.component";

const routes: Routes = [
  { path: '', component: TransferenciaInternaListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: CreditoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: CreditoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: CreditoViewComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransferenciaInternaRoutingModule { }
