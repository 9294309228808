import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AnexoLoaService } from '../service/anexo-loa.service';
import { FuncaoService, LoginContabil, Exercicio, Relatorio, GlobalService, Unidade, ProgressoService } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class PrevisaoReceitaLoa implements OnDestroy {
  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLoaService,
    protected progressoService: ProgressoService) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // tslint:disable: max-line-length
  public montarRelatorio(exercicio: Exercicio, orgaos: number[], mesInicial: string, mesFinal: string, tipoPrevisao: string, unidadeId: number) {
    this.anexoServico.obterPrevisaoReceita(exercicio.id, orgaos, mesInicial, mesFinal, tipoPrevisao, unidadeId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async idTransacao => {

        this.progressoService.show(idTransacao, async (dados) => {

          Relatorio.imprimirPersonalizado(
            ''
            , this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            this.conteudo(dados, exercicio),
            'landscape', 'ANEXO PREVISAO RECEITA',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            });
        })

      });
  }

  private conteudo(dados1: any[], exercicio: Exercicio): {}[] {
    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `ESTABELECIMENTO DA PROGRAMAÇÃO FINANCEIRA, COM DESDOBRAMENTO DAS RECEITAS PREVISTAS EM METAS BIMESTRAIS DE ARRECADAÇÃO PARA O ANO ${exercicio.ano}`,
        alignment: 'center', bold: true, colSpan: 7, border: [true, true, true, false]
      }, '', '', '', '', '', ''],
      [{
        text: 'DISPOSITIVO LEGAL ARTIGO 13 DA LEI COMPLEMENTAR 101 DE 04/05/2000',
        alignment: 'center',
        bold: true,
        colSpan: 7, border: [true, false, true, false]
      }, '', '', '', '', '', ''],
      [{
        text: 'RECEITAS',
        alignment: 'center',
        bold: true, fontSize: 9
      }, {
        text: '1º BIMESTRE PREVISTO',
        alignment: 'center',
        bold: true, fontSize: 9
      }, {
        text: '2º BIMESTRE PREVISTO',
        alignment: 'center',
        bold: true, fontSize: 9
      }, {
        text: '3º BIMESTRE PREVISTO',
        alignment: 'center',
        bold: true, fontSize: 9
      }, {
        text: '4º BIMESTRE PREVISTO',
        alignment: 'center',
        bold: true, fontSize: 9
      }, {
        text: '5º BIMESTRE PREVISTO',
        alignment: 'center',
        bold: true, fontSize: 9
      }, {
        text: '6º BIMESTRE PREVISTO',
        alignment: 'center',
        bold: true, fontSize: 9
      }]
    ];

    // monta o agrupamento do relatório
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    for (const item of dados1[0]) {
      registros.push([
        {
          text: item.nome, bold: true, fontSize: 9, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.bimestre1), alignment: 'right',
          bold: true, fontSize: 9, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.bimestre2), alignment: 'right',
          bold: true, fontSize: 9, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.bimestre3), alignment: 'right',
          bold: true, fontSize: 9, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.bimestre4), alignment: 'right',
          bold: true, fontSize: 9, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.bimestre5), alignment: 'right',
          bold: true, fontSize: 9, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.bimestre6), alignment: 'right',
          bold: true, fontSize: 9, border: [true, false, true, false]
        }
      ]);

      total1 += +item.bimestre1;
      total2 += +item.bimestre2;
      total3 += +item.bimestre3;
      total4 += +item.bimestre4;
      total5 += +item.bimestre5;
      total6 += +item.bimestre6;
    }

    registros.push([
      {
        text: '',
        alignment: 'center',
        bold: true,
        colSpan: 7, border: [true, true, true, false]
      }, '', '', '', '', '', ''
    ]);

    registros.push([{
      text: 'TOTAL ',
      border: [true, false, true, true], fontSize: 9, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total1), alignment: 'right',
      border: [true, false, true, true], fontSize: 9, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total2), alignment: 'right',
      border: [true, false, true, true], fontSize: 9, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total3), alignment: 'right',
      border: [true, false, true, true], fontSize: 9, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total4), alignment: 'right',
      border: [true, false, true, true], fontSize: 9, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total5), alignment: 'right',
      border: [true, false, true, true], fontSize: 9, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total6), alignment: 'right',
      border: [true, false, true, true], fontSize: 9, bold: true
    }
    ]);
    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }];
  }


}
