import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuncaoService, LoginContabil, GlobalService, Relatorio, Ppa } from 'eddydata-lib';
import { AcaoGovernoService } from 'administrativo-lib';
@Injectable({
  providedIn: 'root'
})
export class Anexo3Ppa implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected acaoServico: AcaoGovernoService) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(ppa: Ppa, orgaos: number[], opt?: number, ano?:number, desconsiderarIndicadoresZeradosExercicio?:boolean) {
    this.acaoServico.anexo3(ppa.id, orgaos, ano, 0, false)
    
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        Relatorio.imprimirPersonalizado(
          'ANEXO III - PLANEJAMENTO ORÇAMENTÁRIO - PPA'
          , this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          this.conteudo(dados, ppa, opt),
          'landscape', 'ANEXO III - PPA',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private conteudo(dados1: any[], ppa: Ppa, opt: number): {}[] {
    if (dados1.length === 0) {
      return;
    }
    // monta relatório
    const registros = [];
    for (const item of dados1) {
      registros.push({
        text: 'UNIDADES EXECUTORAS E AÇÕES VOLTADAS AO DESENVOLVIMENTO DO PROGRAMA GOVERNAMENTAL', alignment: 'center',
        bold: true, fontSize: 10
      });
      registros.push({
        text: `PPA: ${ppa.ppaperiodo.ano1} - ${ppa.ppaperiodo.ano4}`, alignment: 'center',
        bold: true, fontSize: 10
      });

      registros.push({ text: '', margin: [0, 5] });
      registros.push({ canvas: [{ type: 'line', x1: 1, y1: 1, x2: 780, y2: 1, lineWidth: 0.5 }] });
      registros.push({ text: '', margin: [0, 5] });

      registros.push({
        columns: [{ text: 'TIPO DE AÇÃO: ', width: 100, bold: true },
        this.globalService.obterEspecieMeta(item.ag_tipo)], margin: [0, 2]
      });
      registros.push({ columns: [{ text: 'UNIDADE EXECUTORA: ', width: 100, bold: true }, item.ue_nome], margin: [0, 2] });
      registros.push({ columns: [{ text: 'CÓDIGO DA UNIDADE: ', width: 100, bold: true }, item.ue_codigo], margin: [0, 2] });
      registros.push({
        columns: [{ text: 'FUNÇÃO DE GOVERNO: ', width: 100, bold: true },
        `${item.fu_codigo} ${item.fu_nome}`], margin: [0, 2]
      });
      registros.push({
        columns: [{ text: 'SUB-FUNÇÃO: ', width: 100, bold: true },
        `${item.sf_codigo} ${item.sf_nome}`], margin: [0, 2]
      });
      registros.push({ columns: [{ text: 'PROGRAMA: ', width: 100, bold: true }, item.pr_nome], margin: [0, 2] });
      registros.push({ columns: [{ text: 'CÓDIGO DO PROGRAMA: ', width: 100, bold: true }, item.pr_codigo], margin: [0, 2] });
      registros.push({ columns: [{ text: 'AÇÃO: ', width: 100, bold: true }, item.ac_nome], margin: [0, 2] });
      registros.push({ columns: [{ text: 'CÓDIGO DA AÇÃO: ', width: 100, bold: true }, item.ac_codigo], margin: [0, 2] });
      registros.push({ columns: [{ text: 'INDICADOR: ', width: 100, bold: true }, `${item.in_codigo} - ${item.in_nome}`], margin: [0, 1] });
      registros.push({ text: '', margin: [0, 5] });
      registros.push({ canvas: [{ type: 'line', x1: 1, y1: 1, x2: 780, y2: 1, lineWidth: 0.5 }] });
      registros.push({ text: '', margin: [0, 10] });

      let grade: {}[] = [];

      grade = [];
      grade.push([{
        text: 'META FÍSICA POR EXERCÍCIO', alignment: 'center',
        bold: true, fontSize: 9, colSpan: 7,  margin: [0, 8],
      }, '', '', '', '', '', '']);

      grade.push([
        { text: 'INDICADOR', bold: true, alignment: 'center', fontSize: 9 },
        { text: 'UND. MEDIDA', bold: true, alignment: 'center', fontSize: 9 },
        { text: this.login.ppa.ppaperiodo.ano1, bold: true, alignment: 'center', fontSize: 9 },
        { text: this.login.ppa.ppaperiodo.ano2, bold: true, alignment: 'center', fontSize: 9 },
        { text: this.login.ppa.ppaperiodo.ano3, bold: true, alignment: 'center', fontSize: 9 },
        { text: this.login.ppa.ppaperiodo.ano4, bold: true, alignment: 'center', fontSize: 9 },
        { text: 'TOTAL', bold: true, alignment: 'center', fontSize: 9 }
      ]);

      if (opt == 1) {
        grade.push([
          { text: item.in_nome, alignment: 'left', margin: [0, 5], fontSize: 9 },
          { text: item.in_unidade_medida, alignment: 'center', margin: [0, 5], fontSize: 9 },
          { text: item.in_meta1, alignment: 'center', margin: [0, 5], fontSize: 9 },
          { text: item.in_meta2, alignment: 'center', margin: [0, 5], fontSize: 9 },
          { text: item.in_meta3, alignment: 'center', margin: [0, 5], fontSize: 9 },
          { text: item.in_meta4, alignment: 'center', margin: [0, 5], fontSize: 9 },
          { text: +item.in_meta1 + +item.in_meta2 + +item.in_meta3 + +item.in_meta4, alignment: 'center', margin: [0, 5], fontSize: 9 }
        ]);
      } else if (opt == 2) {
        grade.push([
          { text: item.in_nome, alignment: 'left', margin: [0, 5], fontSize: 9 },
          { text: item.in_unidade_medida, alignment: 'center', margin: [0, 5], fontSize: 9 },
          { text: item.in_evolucao1, alignment: 'center', margin: [0, 5], fontSize: 9 },
          { text: item.in_evolucao2, alignment: 'center', margin: [0, 5], fontSize: 9 },
          { text: item.in_evolucao3, alignment: 'center', margin: [0, 5], fontSize: 9 },
          { text: item.in_evolucao4, alignment: 'center', margin: [0, 5], fontSize: 9 },
          { text: +item.in_evolucao1 + +item.in_evolucao2 + +item.in_evolucao3 + +item.in_evolucao4, alignment: 'center', margin: [0, 5], fontSize: 9 }
        ]);
      }

      registros.push({
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: [250, '*', '*', '*', '*', '*', '*'],
          body: grade
        }, margin: [20, 0, 20, 5]
      });

      grade = [];

      grade.push([{
        text: 'META FINANCEIRA POR EXERCÍCIO', alignment: 'center',
        bold: true, fontSize: 9, margin: [0, 8], colSpan: 5,
      }, '', '', '', '']);

      grade.push([
        { text: this.login.ppa.ppaperiodo.ano1, bold: true, alignment: 'center', fontSize: 9 },
        { text: this.login.ppa.ppaperiodo.ano2, bold: true, alignment: 'center', fontSize: 9 },
        { text: this.login.ppa.ppaperiodo.ano3, bold: true, alignment: 'center', fontSize: 9 },
        { text: this.login.ppa.ppaperiodo.ano4, bold: true, alignment: 'center', fontSize: 9 },
        { text: 'TOTAL', bold: true, alignment: 'center', fontSize: 9 }
      ]);

      grade.push([
        { text: this.funcaoService.convertToBrNumber(item.ag_custo1), alignment: 'center', margin: [0, 5], fontSize: 9 },
        { text: this.funcaoService.convertToBrNumber(item.ag_custo2), alignment: 'center', margin: [0, 5], fontSize: 9 },
        { text: this.funcaoService.convertToBrNumber(item.ag_custo3), alignment: 'center', margin: [0, 5], fontSize: 9 },
        { text: this.funcaoService.convertToBrNumber(item.ag_custo4), alignment: 'center', margin: [0, 5], fontSize: 9 },
        { text: this.funcaoService.convertToBrNumber(+item.ag_custo1 + +item.ag_custo2 + +item.ag_custo3 + +item.ag_custo4), alignment: 'center', margin: [0, 5], fontSize: 9 }
      ]);
      registros.push({
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*', '*', '*', '*', '*'],
          body: grade
        }, margin: [20, 0, 20, 5]
      });

      registros.push({ text: '', pageBreak: 'after' });
    }
    return registros;
  }


}
