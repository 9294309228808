import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Login, Rcms } from 'eddydata-lib';
import * as toastr from 'toastr';
import { RcmsService } from '../service/rcms.service';

declare var $: any;

@Component({
  selector: 'app-exclusao-rcms-dlg',
  templateUrl: './exclusao-rcms-dlg.component.html'
})
export class ExclusaoRcmsDlgComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  protected unsubscribe: Subject<void> = new Subject();

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Input() login: Login;
  @Input() rcms: Rcms;

  @Input() usarCallback: boolean = false;
  @Output() callback: EventEmitter<Rcms> = new EventEmitter();

  rcmsExcluido: Rcms;

  constructor(
    private router: Router,
    private rcmsService: RcmsService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rcms)
      this.rcmsExcluido = Object.assign({}, this.rcms);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
  }

  estornar() {
    this.rcmsExcluido.excluido = true;
    this.rcmsExcluido.data_exclusao = new Date();
    this.rcmsExcluido.usuario_exclusao = this.login.usuario;

    this.rcmsService
      .atualizar(this.rcmsExcluido)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.onHide();
        if (this.usarCallback)
          this.callback.emit(res);
        else
          setTimeout(() => {
            this.router.navigate(['/rcms']);
          }, 100);
        toastr.success('Exclusão realizada com sucesso!');
      },
        error => {
          if (error.error && error.error.payload) {
            toastr.error(error.error.payload);
          } else {
            toastr.error('Não foi possível fazer o exclusãos do rcms!');
          }
        });

  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

}
