import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MemorialService } from "administrativo-lib";
import { AssinaturaService, BaseResourceRptPersonalizadoComponent, CpfPipe, DateFormatPipe, Favorecido } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";


declare var $: any;

@Component({
  selector: 'app-favorecido-relatorio-certificado',
  templateUrl: './favorecido-relatorio-certificado.component.html'
})
export class FavorecidoRelatorioCertificado extends BaseResourceRptPersonalizadoComponent {

  @Input() public favorecido: Favorecido;
  // @Input() public login: Login;
  public ptBR: any;
  public data1: Date;
  public data2: Date;
  public datepipe: DatePipe;
  favorecidoService: any;


  constructor(
    protected memorialService: MemorialService,
    protected assinaturaService: AssinaturaService,
  ) {
    super(assinaturaService);
    this.assinaturaObrigatoria = false;
    this.data1 = new DateFormatPipe().transform(new Date(), []);
    this.data2 = new DateFormatPipe().transform(new Date().setFullYear(new Date().getFullYear() + 1), []);
  }



  public obterParametros() {
    const parametros = {};
    this.datepipe = new DatePipe('pt');
    parametros['ignoreCondObrig'] = true;
    parametros['data1'] = this.datepipe.transform(this.data1, 'yyyy-MM-dd');
    parametros['data2'] = this.datepipe.transform(this.data2, 'yyyy-MM-dd');
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['relations'] = 'orgao,favorecido';

    return parametros;
  }

  public preencherGrid() {

    const parametros = this.obterParametros();
    this.favorecidoService
      .filtrar(
        this.login.limite,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.favorecido = lista.content;
        },
        () => alert('erro ao retornar lista')
      );
  }

  public onSelectPeriodo() {
    this.preencherGrid();
  }

  protected afterInit(): void {

  }


  public beforeImprimir() {
    try {
      let cnaePrincipal = this.favorecido.cnaes.filter(c => c.principal === true);
      if (cnaePrincipal.length === 0) {
        throw new Error('É necessário atualizar o CNAE');
        return;
      }
      this.imprimir();
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  protected modalRelatorio(): string {
    return 'dialogRelatorioFavorecidoCertificado';
  }

  protected tituloRelatorio(): string {
    return 'CERTIFICADO DE REGISTRO CADASTRAL';
  }


  protected layoutRelatorio(): {} {
    return {
      table_eddy: {
        hLineWidth: function (i, node) {
          return i === 0 ? 0 : 1;
        },
        vLineWidth: function (i) {
          return 0;
        },
        hLineColor: function (i, node) {
          return i === node.table.headerRows ? 'black' : '#eee';
        },
        paddingLeft: function (i) {
          return i === 0 ? 0 : 8;
        },
        paddingRight: function (i, node) {
          return (i === node.table.widths.length - 1) ? 0 : 8;
        },
        fillColor: function (i, node) {
          if (i === 0 || i === node.table.headerRows) {
            return null;
          }
          return (i % 2 === 0) ? '#f5f5f5' : null;
        }
      }
    }
  }

  protected async montarConteudo(): Promise<{}[]> {
    let datepipe = new DatePipe('pt')
    const conteudo = [];
    return await new Promise(async (resolve) => {
      conteudo.push(
        { text: `Certificamos para os devidos fins que a empresa ${this.favorecido.nome}, com sede em ${this.favorecido.municipio}, estado de ${this.favorecido.uf} estabelecida à ${this.favorecido.endereco}, ${this.favorecido.num_endereco} - ${this.favorecido.bairro}, inscrita no CNPJ N.º ${new CpfPipe().transform(this.favorecido.cpf_cnpj)}, acha-se devidamente inscrita no Cadastro de Fornecedores do(a) ${this.login.orgao.nome}, em conformidade com a Lei n° 8.666 de 21/06/1993 e a Lei Orgânica do Município.`, alignment: 'justify', fontSize: 12, margin: [0, 0, 0, 10] },
      );
      conteudo.push({ text: '', decoration: 'underline', alignment: 'justify', fontSize: 12, margin: [0, 0, 0, 10] });

      let cnaePrincipal = this.favorecido.cnaes.filter(c => c.principal === true);
      conteudo.push({ text: `Categoria:`, decoration: 'underline', alignment: 'justify', fontSize: 12, margin: [0, 0, 0, 10] });
      conteudo.push({ text: `${cnaePrincipal[0]['cnae'].codigo} - ${cnaePrincipal[0]['cnae'].nome}.`, alignment: 'justify', fontSize: 10, margin: [0, 0, 0, 10] });
      conteudo.push({ text: '', decoration: 'underline', alignment: 'justify', fontSize: 12, margin: [0, 0, 0, 10] });
      conteudo.push({ text: `Especialização:`, decoration: 'underline', alignment: 'justify', fontSize: 12, margin: [0, 0, 0, 10] });
      let cnaes = this.favorecido.cnaes.filter(c => c.principal === false);
      for (let especializacao of cnaes) {
        conteudo.push({ text: `${especializacao.cnae.codigo} - ${especializacao.cnae.nome};`, alignment: 'justify', fontSize: 10, margin: [0, 0, 0, 10] });
      }
      conteudo.push({ text: '', decoration: 'underline', alignment: 'justify', fontSize: 12, margin: [0, 0, 0, 10] });
      conteudo.push({ text: `Prazo de Vencimento das Certidões:`, decoration: 'underline', alignment: 'justify', fontSize: 12, margin: [0, 0, 0, 10] });

      const tabela: {}[] = [];
      tabela.push(
        [
          { text: 'Tipo de Vencimentos', alignment: 'center', fontSize: 10, border: [true, true, true, true] },
          { text: 'Data de Vencimento', alignment: 'center', fontSize: 10 },
        ],
      )
      tabela.push([
        { text: `Certidão`, alignment: 'center', fontSize: 10 },
        { text: `${datepipe.transform(this.favorecido.data_certidao, 'dd/MM/yyyy') ? datepipe.transform(this.favorecido.data_certidao, 'dd/MM/yyyy') : ' - '}`, alignment: 'center', fontSize: 10 },
      ])
      tabela.push([
        { text: `F.G.T.S`, alignment: 'center', fontSize: 10 },
        { text: `${datepipe.transform(this.favorecido.data_fgts, 'dd/MM/yyyy') ? datepipe.transform(this.favorecido.data_fgts, 'dd/MM/yyyy') : ' - '} `, alignment: 'center', fontSize: 10 },
      ])
      tabela.push([
        { text: `I.N.S.S`, alignment: 'center', fontSize: 10 },
        { text: `${datepipe.transform(this.favorecido.data_inss, 'dd/MM/yyyy') ? datepipe.transform(this.favorecido.data_inss, 'dd/MM/yyyy') : ' - '}`, alignment: 'center', fontSize: 10 },
      ])
      tabela.push([
        { text: `I.R.R.F`, alignment: 'center', fontSize: 10 },
        { text: `${datepipe.transform(this.favorecido.data_irrf, 'dd/MM/yyyy') ? datepipe.transform(this.favorecido.data_irrf, 'dd/MM/yyyy') : ' - '}`, alignment: 'center', fontSize: 10 },
      ])
      tabela.push([
        { text: `Entidade Profissional`, alignment: 'center', fontSize: 10 },
        { text: `${datepipe.transform(this.favorecido.data_entidade_profissional, 'dd/MM/yyyy') ? datepipe.transform(this.favorecido.data_entidade_profissional, 'dd/MM/yyyy') : ' - '}`, alignment: 'center', fontSize: 10 },
      ])
      tabela.push([
        { text: `Alvará Sanitário`, alignment: 'center', fontSize: 10 },
        { text: `${datepipe.transform(this.favorecido.data_alvara_social, 'dd/MM/yyyy') ? datepipe.transform(this.favorecido.data_alvara_social, 'dd/MM/yyyy') : ' - '}`, alignment: 'center', fontSize: 10 },
      ])
      tabela.push([
        { text: `Contrato Social`, alignment: 'center', fontSize: 10 },
        { text: `${datepipe.transform(this.favorecido.data_contrato_social, 'dd/MM/yyyy') ? datepipe.transform(this.favorecido.data_contrato_social, 'dd/MM/yyyy') : ' - '}`, alignment: 'center', fontSize: 10 },
      ])
      tabela.push([
        { text: `Balanço do Último Exercício`, alignment: 'center', fontSize: 10 },
        { text: `${datepipe.transform(this.favorecido.data_balanco_exercicio, 'dd/MM/yyyy') ? datepipe.transform(this.favorecido.data_balanco_exercicio, 'dd/MM/yyyy') : ' - '}`, alignment: 'center', fontSize: 10 },
      ])
      tabela.push([
        { text: `Certidão Conjuta Federal`, alignment: 'center', fontSize: 10 },
        { text: `${datepipe.transform(this.favorecido.data_conjunta_federal, 'dd/MM/yyyy') ? datepipe.transform(this.favorecido.data_conjunta_federal, 'dd/MM/yyyy') : ' - '}`, alignment: 'center', fontSize: 10 },
      ])
      tabela.push([
        { text: `Divida Ativa Municipal`, alignment: 'center', fontSize: 10 },
        { text: `${datepipe.transform(this.favorecido.data_divida_ativa_municipal, 'dd/MM/yyyy') ? datepipe.transform(this.favorecido.data_divida_ativa_municipal, 'dd/MM/yyyy') : ' - '}`, alignment: 'center', fontSize: 10 },
      ])
      tabela.push([
        { text: `I.C.M.S`, alignment: 'center', fontSize: 10 },
        { text: `${datepipe.transform(this.favorecido.data_icms, 'dd/MM/yyyy') ? datepipe.transform(this.favorecido.data_icms, 'dd/MM/yyyy') : ' - '}`, alignment: 'center', fontSize: 10 },
      ])
      tabela.push([
        { text: `Certidão Negativa de Débitos Trabalhistas`, alignment: 'center', fontSize: 10 },
        { text: `${datepipe.transform(this.favorecido.data_debitos_trabalhistas, 'dd/MM/yyyy') ? datepipe.transform(this.favorecido.data_debitos_trabalhistas, 'dd/MM/yyyy') : ' - '}`, alignment: 'center', fontSize: 10 },
      ])
      tabela.push([
        { text: `Cert. Neg. de Falência e Concordata`, alignment: 'center', fontSize: 10 },
        { text: `${datepipe.transform(this.favorecido.data_certidao_falencia, 'dd/MM/yyyy') ? datepipe.transform(this.favorecido.data_certidao_falencia, 'dd/MM/yyyy') : ' - '}`, alignment: 'center', fontSize: 10 },
      ])
      tabela.push([
        { text: `Certidão Negativa Estadual`, alignment: 'center', fontSize: 10 },
        { text: `${datepipe.transform(this.favorecido.data_certidao_estadual, 'dd/MM/yyyy') ? datepipe.transform(this.favorecido.data_certidao_estadual, 'dd/MM/yyyy') : ' - '}`, alignment: 'center', fontSize: 10 },
      ])
      tabela.push([
        { text: `Certidão Negativa Municipal`, alignment: 'center', fontSize: 10 },
        { text: `${datepipe.transform(this.favorecido.data_certidao_municipal, 'dd/MM/yyyy') ? datepipe.transform(this.favorecido.data_certidao_municipal, 'dd/MM/yyyy') : ' - '}`, alignment: 'center', fontSize: 10 },
      ])


      conteudo.push({
        layout: 'table_eddy',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*', '*'],
          body: tabela
        }
      })

      conteudo.push({ text: '', decoration: 'underline', alignment: 'justify', fontSize: 12, margin: [0, 0, 0, 10] });
      conteudo.push({ text: '', decoration: 'underline', alignment: 'justify', fontSize: 12, margin: [0, 0, 0, 10] });

      conteudo.push(
        { text: `${this.login.cidade.nome}, ${this.funcaoService.formatarDataExtenso(this.data1)}`, alignment: 'justify', fontSize: 12, margin: [0, 0, 0, 10] },
      );

      conteudo.push({ text: '', decoration: 'underline', alignment: 'justify', fontSize: 12, margin: [0, 0, 0, 10] });

      conteudo.push(
        { text: `O presente cadastro tem validade até ${this.funcaoService.formatarDataExtenso(this.data2)}`, alignment: 'justify', fontSize: 12, margin: [0, 0, 0, 10] },
      );
      conteudo.push(this.dadosRodape())

      resolve(conteudo);
    })
  }

  private dadosRodape(): {}[] {
    let assinaturas = [];
    if (this.assinatura) {
      assinaturas = this.assinatura.pessoas.filter(p => p.selecionado);
      if (assinaturas?.length === 0) return [{}];
    }

    const conteudo: {}[] = [
      { text: '', alignment: 'center', fontSize: 1, margin: [0, 30, 0, 50] },
    ]

    return conteudo.concat(this.imprimirAssinaturas(assinaturas));
  }

  protected ocultarRodape() {
    return true;
  }

  protected montarFooter(): {}[] {

    let rodape: {}[] = [{ canvas: [{ type: 'line', x1: 30, y1: 1, x2: 565, y2: 1, lineWidth: 1 }] },
    {
      text: [{
        text: `${this.login.orgao.endereco} - ${this.login.orgao.cep} - FONE ${this.login.orgao.telefone} - ${this.login.cidade.nome} - ${this.login.cidade.estado.uf}`,
        alignment: 'center', margin: [30, 5, 0, 20], style: 'footer'
      }]
    },
    {
      text: [
        { text: `${this.login.orgao.email}`, alignment: 'center', margin: [0, 5, 30, 20], style: 'footer' }]
    },
    {
      text: [
        { text: ' ', alignment: 'center', margin: [0, 5, 30, 20], style: 'footer' }]
    }
    ];


    return rodape;
  }


}
