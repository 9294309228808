import { TipoObjetoLicitacao } from '../../../components/types';
import { Audesp4AcordoCronogramaNao, Audesp4InexecucaoParcial, Audesp4InexecucaoTotal, Audesp4ParalisadaSim, Audesp4Responsavel, Audesp4ResponsavelTI } from './types/comum-type';
import { Audesp4ConferenciaMedicaoSimTI, Audesp4LiquidacaoDocSimilarSim, Audesp4ObrasCorpoTecnico, Audesp4ServicoCorpoTecnico } from "./types/execucao-type";

export class ExecucaoArray {
    CodigoAjuste: number;
    ExecucaoComprasServico?: ExecucaoComprasServico;
    ExecucaoComprasServicoTI?: ExecucaoComprasServicoTI;
    ExecucaoObrasEngenharia?: ExecucaoObrasEngenharia;
}

abstract class ExecucaoComum {
    SituacaoDoAjuste: number;
    DataDeEntrega: string;
    ExecucaoAcordoCronogramaSim?: 'S';
    ExecucaoAcordoCronogramaNao?: Audesp4AcordoCronogramaNao;
    ObraPublicaEstaParalisadaSim?: Audesp4ParalisadaSim;
    ObraPublicaEstaParalisadaNao?: 'N';
    ObraPublicaEstaParalisadaNaoObra?: 'NaoObra';
    FormaRecebimento?: number;
    FormaRecebimentoDt?: string;
    ExisteTermoAssinadoPelasPartes?: 'S' | 'N';
    ExisteVistoriaComproveAdequacaoObjeto?: 'S' | 'N';
    InexecucaoTotal?: Audesp4InexecucaoTotal;
    InexecucaoParcial?: Audesp4InexecucaoParcial;
    InexecucaoNao?: 'N';
    LiquidacaoDocSimilarSim?: Audesp4LiquidacaoDocSimilarSim[];
    LiquidacaoDocSimilarNao?: 'N';
}

export class ExecucaoComprasServico extends ExecucaoComum {
    ExecucaoFornecimentoObjetoContratual?: number;
    ObjetoDecorrenteImportacao?: 'S' | 'N';
    ResponsavelRecebimento?: Audesp4Responsavel[];
    PrestacaoServicoNao?: 'N' | 'NA';
    PrestacaoServicoSim?: Audesp4ServicoCorpoTecnico;
    ConferenciaMedicaoSim?: Audesp4ConferenciaMedicaoSimTI;
    ConferenciaMedicaoNao?: 'N';
}

export class ExecucaoComprasServicoTI extends ExecucaoComum {
    ExecucaoFornecimentoObjetoContratual?: number;
    ObjetoDecorrenteImportacao?: 'S' | 'N';
    ResponsavelRecebimentoTI?: Audesp4ResponsavelTI[];
    PrestacaoServicoNao?: 'N' | 'NA';
    PrestacaoServicoSim?: Audesp4ServicoCorpoTecnico;
    ConferenciaMedicaoSim?: Audesp4ConferenciaMedicaoSimTI;
    ConferenciaMedicaoNao?: 'N';
}

export class ExecucaoObrasEngenharia extends ExecucaoComum {
    ResponsavelRecebimento?: Audesp4ResponsavelTI[];
    ObrasCorpoTecnico?: Audesp4ObrasCorpoTecnico;
    ConferenciaMedicaoSim?: Audesp4ConferenciaMedicaoSimTI;
    ConferenciaMedicaoNao?: 'N';
}

export class Execucao {
    tipoObjeto: TipoObjetoLicitacao;
    CodigoAjuste: number;
    SituacaoDoAjuste: number;
    DataDeEntrega: string;
    ExecucaoAcordoCronogramaSim?: 'S' | 'N';
    ExecucaoAcordoCronogramaNao?: Audesp4AcordoCronogramaNao;
    ObraPublicaEstaParalisadaSim?: Audesp4ParalisadaSim;
    ObraPublicaEstaParalisadaNao?: 'S' | 'N' | 'NaoObra';
    FormaRecebimento?: number;
    FormaRecebimentoDt?: string;
    ExisteTermoAssinadoPelasPartes?: 'S' | 'N';
    ExisteVistoriaComproveAdequacaoObjeto?: 'S' | 'N';
    Inexecucao?: Audesp4InexecucaoTotal;
    InexecucaoNao?: 'ST' | 'N' | 'SP';
    LiquidacaoDocSimilarSim?: Audesp4LiquidacaoDocSimilarSim[];
    LiquidacaoDocSimilarNao?: 'S' | 'N';
    ExecucaoFornecimentoObjetoContratual?: number;
    ObjetoDecorrenteImportacao?: 'S' | 'N';
    ResponsavelRecebimento?: Audesp4ResponsavelTI[];
    PrestacaoServicoNao?: 'S' | 'N' | 'NA';
    PrestacaoServicoSim?: Audesp4ServicoCorpoTecnico;
    ConferenciaMedicaoSim?: Audesp4ConferenciaMedicaoSimTI;
    ConferenciaMedicaoNao?: 'S' | 'N';
    ObrasCorpoTecnico?: Audesp4ObrasCorpoTecnico;
    empenhoId: number;

    constructor() {
        this.ExecucaoAcordoCronogramaSim = 'S';
        this.ObraPublicaEstaParalisadaNao = 'N';
        this.ExisteTermoAssinadoPelasPartes = 'N';
        this.ExisteVistoriaComproveAdequacaoObjeto = 'N';
        this.InexecucaoNao = 'N';
        this.LiquidacaoDocSimilarNao = 'S';
        this.ObjetoDecorrenteImportacao = 'N';
        this.PrestacaoServicoNao = 'N';
        this.ConferenciaMedicaoNao = 'S';
        this.ExecucaoAcordoCronogramaNao = { AcordoCronogramaNao: 'N', AcordoCronogramaNaoMotivo: undefined, MotivoAceitoAdministracao: 'N' }
        this.ObraPublicaEstaParalisadaSim = { ObraParalisadaSim: 'S', ObraPublicaMotivoParalisacao: undefined }
        this.Inexecucao = { DataAberturaProcedimentoAdministrativo: undefined, ExisteAplicacaoSancoes: 'N', InexecucaoSim: undefined, InexecucaoMotivo: undefined }
        this.PrestacaoServicoSim = { PrestacaoServico: 'S', ExisteControleServicoPrestado: 'NA', HouveTransferencia: 'NA', MONumeroEmpregadoEstabelecidoEdital: 'NA' }
        this.ConferenciaMedicaoSim = {
            ConferenciaDt: undefined, NumeroMedicao: undefined, PercentualExecucao: undefined, HouveSupressaoObraBensServico: 'NA', ResponsavelRecebimentoMedicaoTI: undefined
        }
        this.ObrasCorpoTecnico = { ContratadaMantemPrepostoNaObra: 'NA', FiscalCredenciadoCREALei8666693art67: 'NA', ResponsavelObraCorrespondeContrato: 'NA' }
        this.SituacaoDoAjuste = 1;
        this.ExecucaoFornecimentoObjetoContratual = 1;
        this.FormaRecebimento = 1;
    }

}

export function listarSituacoes(): { id: number, nome: string }[] {
    return [
        { id: 1, nome: 'Em execução' },
        { id: 2, nome: 'Suspenso (Paralisado)' },
        { id: 3, nome: 'Encerrado - Cumprimento Integral' },
        { id: 4, nome: 'Encerrado - Rescisão com imposição de sanção' },
        { id: 5, nome: 'Encerrado - Rescisão sem imposição de sanção' },
        { id: 6, nome: 'Encerrado - Anulado' },
    ]
}