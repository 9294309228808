import { Component, Injector } from "@angular/core";
import { Validators } from "@angular/forms";
import {
  BaseResourceFormComponent, CompraItem, DateFormatPipe, EddyAutoComplete, EmailService, FavorecidoService, LicitacaoPipe, LoginContabil, ParametroCompra, ParametroCompraService, PedidoCompra, PedidoCompraItem, Pessoa, PessoaService
} from "eddydata-lib";
import { takeUntil } from "rxjs/operators";
import { CompraItemService } from "../../../compra/service/compra-item.service";
import { CompraService } from "../../../compra/service/compra.service";
import { PedidoCompraService } from "../service/pedido-compra.service";
import * as toastr from 'toastr';
import { PedidoCompraItemService } from "../service/pedido-compra-item.service";
import { ConfirmationService } from "primeng/api";
import { NotaPedidoCompra } from "../../../relatorio-compra/pedido-compra-rpt/pedido-compra-rpt";
import { Router } from "@angular/router";
import { MemorialService } from "administrativo-lib";
import { ContratoItemService } from "../../../contrato/service/contrato-item.service";
import { RcmsItemService } from "../../../rcms/service/rcms-item.service";

@Component({
  selector: 'lib-pedido-compra-form',
  templateUrl: './pedido-compra-form.component.html'
})
export class PedidoCompraFormComponent extends BaseResourceFormComponent<PedidoCompra, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public pessoaAutoComplete: EddyAutoComplete<Pessoa>;

  public listaItens: PedidoCompraItem[] = [];
  public selecionar: CompraItem[] = [];
  public visualizarItens: boolean = false;
  public dadosOf = {
    rcms: '',
    processo: '',
    contrato: '',
    licitacao: '',
    fornecedor: '',
  };


  public validarEmail: boolean = true;
  public ajusteSemSubmit: boolean = false;
  public visulizarAjusteServico: boolean = false;
  public produtosAjuste: any[] = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected parametroCompraService: ParametroCompraService,
    protected emailService: EmailService,
    protected confirmationService: ConfirmationService,
    protected favorecidoService: FavorecidoService,
    protected pessoaService: PessoaService,
    protected compraService: CompraService,
    protected memorialService: MemorialService,
    private contratoItemService: ContratoItemService,
    protected compraItemService: CompraItemService,
    private rcmsItemService: RcmsItemService,
    protected pedidoCompraItemService: PedidoCompraItemService,
    protected pedidoCompraService: PedidoCompraService,
    protected route: Router
  ) {
    super(new PedidoCompra(), injector, PedidoCompra.converteJson, pedidoCompraService);
    toastr.options.positionClass = 'toast-top-left'
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      sequencia: [null, [Validators.required]],
      data_pedido: [new Date(), [Validators.required]],
      observacao: [''],
      compra: this.fb.group({
        id: [null],
        numero: [null],
        favorecido: [null]
      }),
      exercicio: [this.login.exercicio, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      itens: [null, [Validators.required]],
      requerente: [null, [Validators.required]]
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: ['compra.exercicio', 'compra.rcms.exercicio', 'compra.licitacao', 'compra.licitacao.modalidade',
        'compra.contrato', 'compra.favorecido.cidade', 'compra.requerente.cidade', 'requerente', 'compra.modalidade', 'compra.subelemento']
    };
  }

  protected afterLoad(): void {
    this.entidade.data_pedido = new DateFormatPipe().transform(this.entidade.data_pedido, []);
    this.entidadeForm.get('compra').patchValue(this.entidade.compra);

    this.pessoaAutoComplete.id = this.entidade.requerente ? this.entidade.requerente.id : null;

    this.pedidoCompraItemService.filtrar(1, -1, {
      'relations': 'pedido,item.produto_unidade.produto.material,item.produto_unidade.unidade',
      'pedido.id': this.entidade.id
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        let pedidos = res ? res.content : new Array<PedidoCompraItem>();
        this.compraItemService.obterSaldoItens(this.entidade.compra.id)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(listaSaldo => {
            this.listaItens = pedidos.map(e => {
              return {
                ...e,
                qtd_restante: (listaSaldo.filter(item => item.itemId === e.item.id))[0]?.quantidade || 0,
                novo: false
              }
            })
            this.verificarItensConversao();
          })
      }, error => toastr.warning(error.error.payload));

    this.carregarDadosOf();

    if (this.entidade.id) {
      this.pedidoCompraService.obterId(this.entidade.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          if (res) {
            this.entidadeForm.get('numero').setValue(res.numero);
            this.entidadeForm.get('sequencia').setValue(res.sequencia);
            this.entidadeForm.get('data_pedido').setValue(new DateFormatPipe().transform(res.data_pedido, []));
          }
        });
    }
  }

  protected afterInit(): void {
    this.pedidoCompraService.filtrar(1, 1, {
      'exercicio.id': this.login.exercicio.id,
      'orgao.id': this.login.orgao.id,
      'orderBy': 'id$DESC'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        if (!res.content.length) {
          this.entidadeForm.get('numero').setValue(1);
        } else {
          this.entidadeForm.get('numero').setValue(res.content[0].numero + 1);
        }
      });

    this.pessoaAutoComplete = new EddyAutoComplete(this.entidadeForm.get('requerente'), this.pessoaService,
      'id', ['nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );
  }

  protected beforeSubmit(): void {
    try {
      if (this.entidadeForm.get('compra').value === '') {
        throw new Error('Informe o número da OF!');
      }
      if (this.entidadeForm.get('observacao').value === '') {
        throw new Error('Informe a observação do pedido!');
      }
      if (this.entidadeForm.get('requerente').value === '') {
        throw new Error('Informe o requerente do pedido!');
      }

      let qtdItens = true;
      this.listaItens.forEach(item => {
        qtdItens = qtdItens && item.quantidade <= item['qtd_restante'];
      });

      if (!qtdItens && !this.entidade.id) {
        throw new Error('Quantidade superior ao saldo disponível!');
      }

    } catch (e) {
      toastr.error(e.message);
      throw e;
    }

    this.entidadeForm.get('itens').setValue(this.listaItens);

    if (!this.validarItensAjuste()) {
      this.ajusteSemSubmit = false;
      throw new Error();
    }
  }

  protected afterSubmit(ent: PedidoCompra): void {
    if (this.validarEmail) {
      this.validarEmail = false;
      let pedido = this.entidadeForm.value;
      let mensagem = '';
      if (this.login.parametro['compras'].email_requerente_pedido)
        mensagem += `<br />Requerente ${pedido?.requerente?.nome}(${pedido?.requerente?.email})`;
      if (this.login.parametro['compras'].email_favorecido_pedido)
        mensagem += `<br />Fornecedor ${pedido?.compra?.favorecido?.nome}(${pedido?.compra?.favorecido?.email})`;

      if (mensagem) {
        this.confirmationService.confirm({
          message: `Encaminhar por e-mail o pedido de compra para: ${mensagem}?`,
          header: `Envio de Pedido de Compra - Nº: ${pedido.numero}/${pedido.sequencia}`,
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Sim',
          rejectLabel: 'Não',
          key: 'pedido',
          accept: async () => {
            await this.enviarEmail(ent, this.login.parametro['compras']);
            this.acaoSucesso(ent);
          },
          reject: () => {
            this.acaoSucesso(ent);
          }
        });
        throw Error('Validar envio de e-mail');
      }
    }
    this.validarEmail = true;
  }

  public imprimir() {
    this.entidade.itens = this.listaItens;
    new NotaPedidoCompra(this.login)
      .imprimir([this.entidade]);
  }

  public selecionarOf(): void {
    this.compraService.obterPorNumero(
      this.entidadeForm.get('compra.numero').value, this.login.exercicio.id, this.login.orgao.id, 'rcms.exercicio,contrato,licitacao.exercicio,favorecido,requerente,itens.produto_unidade.produto.material,itens.produto_unidade.unidade'
    ).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        let dados = res['content'][0];
        if (dados) {
          this.dadosOf.rcms = dados.rcms ? dados.rcms.numero + '/' + dados.rcms.exercicio.ano : '';
          this.dadosOf.processo = dados.processo ? dados.processo : '';
          this.dadosOf.contrato = dados.contrato ? dados.contrato.numero : '';
          this.dadosOf.licitacao = dados.licitacao ? dados.licitacao.numero : '';
          this.dadosOf.fornecedor = dados.favorecido ? dados.favorecido.nome : '';

          this.entidadeForm.get('compra').patchValue(dados);

          if (!this.entidadeForm.get('requerente').value) {
            this.pessoaAutoComplete.id = dados?.requerente?.id;
            this.entidadeForm.get('requerente').setValue(dados.requerente);
          }

          this.compraItemService.obterSaldoItens(dados.id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(saldo => {
              this.selecionar = dados.itens.map(compraItem => {
                compraItem['qtd_restante'] = (saldo.filter(item => item.itemId === compraItem.id))[0]?.quantidade || 0;
                let select = this.listaItens.find((i) => i.item.produto_unidade.id === compraItem.produto_unidade.id);
                if (select) {
                  compraItem['selecionado'] = true;
                }
                return compraItem;
              }).filter(item => item['qtd_restante'] > 0)

              if (this.selecionar.length) {
                this.visualizarItens = true;
              } else {
                toastr.warning('OF sem itens para efetuar pedido!')
              }
            });
        }
      });

    let compra = this.entidadeForm.get('compra').value;
    this.pedidoCompraService.extendido(1, -1, {
      'compra.numero': compra.numero,
      'orderBy': 'numero$DESC'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        if (!res.content.length) {
          this.entidadeForm.get('sequencia').setValue(1);
        } else {
          if (!this.entidade.sequencia) {
            this.entidadeForm.get('sequencia').setValue(res.content[0].sequencia + 1);
          }
        }
      });

  }

  private carregarDadosOf(): void {
    this.compraService.obterPorNumero(
      this.entidadeForm.get('compra.numero').value, this.login.exercicio.id, this.login.orgao.id, 'rcms.exercicio,contrato,licitacao,favorecido,requerente,itens.produto_unidade.produto.material,itens.produto_unidade.unidade'
    ).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        let dados = res['content'][0];
        if (dados) {
          this.dadosOf.rcms = dados.rcms ? dados.rcms.numero + '/' + dados.rcms.exercicio.ano : '';
          this.dadosOf.processo = dados.processo ? dados.processo : '';
          this.dadosOf.contrato = dados.contrato ? dados.contrato.numero : '';
          this.dadosOf.licitacao = dados.licitacao ? dados.licitacao.numero : '';
          this.dadosOf.fornecedor = dados.favorecido ? dados.favorecido.nome : '';

          if (!this.entidadeForm.get('requerente').value) {
            this.pessoaAutoComplete.id = dados.requerente.id;
            this.entidadeForm.get('requerente').setValue(dados.requerente);
          }
        }
      });
  }

  public posSelecionarItens(itens: CompraItem[]) {
    this.listaItens = itens
      .filter(item => item['selecionado'])
      .map(item => {
        let pedidoItem = new PedidoCompraItem();
        pedidoItem.item = item;
        pedidoItem.quantidade = item['qtd_restante'];
        pedidoItem['qtd_restante'] = item['qtd_restante'];
        pedidoItem['novo'] = true;

        return pedidoItem;
      })
    this.visualizarItens = false;
    if (!this.validarItensAjuste()) this.ajusteSemSubmit = true;
  }

  public async enviarEmail(ent: PedidoCompra, parametro?: ParametroCompra) {
    return new Promise<void>((resolve) => {
      this.pedidoCompraService.obter({
        relations: 'compra.exercicio,compra.rcms.exercicio,compra.licitacao,compra.licitacao.modalidade,compra.contrato,compra.favorecido.cidade,compra.requerente.cidade,itens.item.produto_unidade.produto.material,itens.item.produto_unidade.unidade',
        'id': ent.id
      }).pipe(takeUntil(this.unsubscribe))
        .subscribe(async pedido => {
          let subject = `Pedido de Compra - Nº: ${pedido.numero}/${pedido.sequencia}`;
          let mensagem = `<p>${parametro.mensagem_pedido}</p>`;
          let destinatarios: { nome: string; email: string; }[] = [];

          if (parametro.email_favorecido_pedido) {
            destinatarios.push({ nome: pedido.compra.favorecido.nome, email: pedido.compra.favorecido.email });
          }
          if (parametro.email_requerente_pedido) {
            destinatarios.push({ nome: pedido.compra.requerente.nome, email: pedido.compra.requerente.email });
          }

          if (destinatarios.length) {
            const anexo = (await new NotaPedidoCompra(this.login).exportar([pedido]))
            this.emailService.enviar({ titulo: subject, corpo: mensagem, destinos: destinatarios, anexos: [{ arquivo: anexo, nome: `Pedido de Compra ${pedido.numero} - ${pedido.sequencia}.pdf` }] })
              .pipe(takeUntil(this.unsubscribe))
              .subscribe(data => {
                toastr.success('E-mail encaminhado com sucesso!');
                resolve();
              }, error => {
                toastr.error(`Não foi possivel encaminhar o e-mail de confirmação!`);
                resolve()
              });
          }
        });
    });
  }

  public remover() {
    this.confirmationService.confirm({
      message: `Removendo o pedido os itens vinculados serão removidos. Deseja remover o pedido ${this.entidade.sequencia} da OF. ${this.entidade.compra.numero}?`,
      header: `Remover pedido de compra`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      key: 'pedido',
      accept: () => {
        this.pedidoCompraService.remover(this.entidade.id)
          .subscribe(data => {
            toastr.success(`Pedido de compra removido com sucesso.`);
            this.router.navigate(['/movimentos-compra/pedido-compra']);
          }, error => {
            if (error && error.error && error.error.payload.includes('violates foreign key'))
              toastr.error(`Produto possui vinculo por isso não pode ser excluido.`);
            else
              toastr.error(error.error.payload);
          })
      }
    });
  }

  public aposAjustarProdutos(produtos: any[]) {
    const compra = this.entidadeForm.get("compra").value;
    const contrato = this.entidadeForm.get("compra").value.contrato;
    const licitacao = this.entidadeForm.get("compra").value.licitacao;
    const exercicio = this.entidadeForm.get("exercicio").value;
    const rcms = this.entidadeForm.get("compra").value.rcms;
    if (!produtos) return;
    if (produtos.filter((p) => !p.produto_unidade?.id).length > 0) {
      toastr.warning(`Alguns serviços não foram ajustados`);
      return;
    }
    for (const pi of this.listaItens) {
      if (pi.item.produto_unidade?.id) continue;
      let produto_unidade = produtos.find(
        (p) =>
          p.produto === pi.item.produto_unidade.produto.nome &&
          p.unidade === pi.item.produto_unidade.unidade.nome
      );
      if (produto_unidade) {
        pi.item.produto_unidade = produto_unidade.produto_unidade;
        if (compra?.id) {
          this.compraItemService.atualizarProdutoUnidade(produto_unidade.produto_unidade, {
            compra_id: compra.id,
            produto: pi.item.produto,
            unidade: pi.item.unidade,
          }).subscribe((ci3) => {
            toastr.success(
              `Ajustado item ${pi.item.produto} - ${pi.item.unidade
              } do contrato ${new LicitacaoPipe().transform(compra.numero)}`
            );
          });
        }
        if (contrato?.id) {
          this.contratoItemService
            .atualizarProdutoUnidade(produto_unidade.produto_unidade, {
              contrato_id: contrato.id,
              produto: pi.item.produto,
              unidade: pi.item.unidade,
            })
            .subscribe((ci2) => {
              toastr.success(
                `Ajustado item ${pi.item.produto} - ${pi.item.unidade
                } do contrato ${new LicitacaoPipe().transform(contrato.numero)}`
              );
            });
        }
        if (licitacao?.id) {
          this.memorialService
            .atualizarProdutoUnidade(produto_unidade.produto_unidade, {
              licitacao_id: licitacao.id,
              produto: pi.item.produto,
              unidade: pi.item.unidade,
            })
            .subscribe((ci2) => {
              toastr.success(
                `Ajustado item ${pi.item.produto} - ${pi.item.unidade
                } da licitação ${new LicitacaoPipe().transform(
                  licitacao.numero
                )}`
              );
            });
        }
        if (rcms?.id) {
          this.rcmsItemService
            .atualizarProdutoUnidade(produto_unidade.produto_unidade, {
              rcms_id: rcms.id,
              produto: pi.item.produto,
              unidade: pi.item.unidade,
            })
            .subscribe((ci2) => {
              toastr.success(
                `Ajustado item ${pi.item.produto} - ${pi.item.unidade
                } da requisição de compra ${("00000" + rcms.numero).slice(
                  -5
                )}/${exercicio.ano}`
              );
            });
        }
      }
    }
    // this.posSelecionarItens(this.entidade.itens)
    if (!this.ajusteSemSubmit) this.submitForm(this.limparTela);
    this.ajusteSemSubmit = false;
  }

  private validarItensAjuste() {
    if (this.listaItens.filter((i) => !i.item.produto_unidade?.id).length > 0) {
      toastr.warning(`Foi identificado itens com serviços não cadastrados`);
      this.produtosAjuste = this.listaItens
        .filter((i) => !i.item.produto_unidade?.id)
        .map((i) => {
          return { produto: i.item.produto, unidade: i.item.unidade };
        });
      this.visulizarAjusteServico = true;
      return false;
    }
    return true;
  }

  public cancelarAjuste() {
    if (this.ajusteSemSubmit) {
      this.listaItens = [];
    }
    this.visulizarAjusteServico = false;
    // this.buscarSaldoFixa();
  }

  public parametrosProdutos(): {} {
    let subelemento = this.entidadeForm.get("compra").value.subelemento;
    let processo = this.entidadeForm.get("compra").value.processo;

    let parametros = {};
    if (subelemento && subelemento.codigo) {
      parametros["valid_sub_elemento$like"] = `%${subelemento.codigo}%`;
    }
    // if (processo)
    //   parametros['unidades.memoriais.licitacao.processo'] = processo;
    return parametros;
  }

  private verificarItensConversao() {
    this.produtosAjuste = []
    if (this.listaItens?.filter((i) => !i?.item?.produto_unidade?.id).length > 0) {
      toastr.warning(`Foram identificados itens com serviços não cadastrados`);
      this.produtosAjuste = this.listaItens?.filter((i) => !i.item.produto_unidade?.id)
        .map((i) => {
          return { produto: i.item.produto_unidade.produto.nome, unidade: i.item.produto_unidade.unidade.nome };
        });
      this.visulizarAjusteServico = true;
    }
  }
}