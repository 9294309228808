import { Component } from '@angular/core';
import { BaseResourceItemsComponent, Cnae, CnaeService, EddyAutoComplete, FavorecidoCnae } from 'eddydata-lib';
import { FavorecidoCnaeService } from '../service/favorecido-cnae.service';
import * as toastr from 'toastr';

@Component({
  selector: 'lib-favorecido-cnae',
  templateUrl: './favorecido-cnae.component.html'
})
export class FavorecidoCnaeComponent extends BaseResourceItemsComponent<FavorecidoCnae>{

  public cnaeAutoComplete: EddyAutoComplete<Cnae>;

  constructor(private cnaeService: CnaeService,
    public favorecidoCnaeService: FavorecidoCnaeService) {
    super(new FavorecidoCnae(), favorecidoCnaeService);
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
  }

  protected validSave(item: FavorecidoCnae): boolean {
    if (!item.cnae) {
      toastr.warning('Informe o CNAE!');
      return false;
    }
    return true;
  }

  protected beforeSave(item: FavorecidoCnae): void {
    item.orgao = this.login.orgao
  }



  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  private carregarAutoCompletes() {
    // autocomplete para CNAE
    this.cnaeAutoComplete = new EddyAutoComplete(null, this.cnaeService,
      'codigo', ['codigo', 'nome'],
      { cidade_id: this.login.cidade.id, orderBy: 'nome' },
      { number: ['id', 'codigo'], text: ['nome'] }
    );
  }


}
