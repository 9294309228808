import { Component, Input } from '@angular/core';
import { FavorecidoSocioService } from 'administrativo-lib';
import { BaseResourceItemsComponent, Contrato, ContratoResponsavel, EddyAutoComplete, FavorecidoSocio, Pessoa, PessoaService } from 'eddydata-lib';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ContratoResponsavelService } from '../service/contrato-responsavel.service';

declare var $: any;

@Component({
  selector: 'lib-contrato-responsavel',
  templateUrl: './contrato-responsavel.component.html'
})
export class ContratoResponsavelComponent extends BaseResourceItemsComponent<ContratoResponsavel> {

  @Input() favorecido: number;
  @Input() contrato: number;
  @Input() visualizar: Boolean;

  public pessoaAutoComplete: EddyAutoComplete<Pessoa>;
  public itemAtual: ContratoResponsavel;
  public cadPessoa: Pessoa;
  public viewPessoa: Pessoa;

  constructor(private pessoaService: PessoaService,
    private favResponsavelService: FavorecidoSocioService,
    public responsavelService: ContratoResponsavelService) {
    super(new ContratoResponsavel(), responsavelService);
    this.persistence = true;
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
  }

  protected validSave(item: ContratoResponsavel): boolean {
    if (!item.responsavel || !item.responsavel.id) {
      toastr.warning('Informe o responsável!');
      return false;
    }
    return true;
  }
  protected beforeSave(item: ContratoResponsavel): void {
    item.contrato = new Contrato();
    item.contrato.id = this.contrato;
    if (!item.ativo) item.ativo = false;
    if (!item.contratante) item.contratante = false;
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private carregarAutoCompletes() {
    this.pessoaAutoComplete = new EddyAutoComplete(null, this.pessoaService,
      'id', ['cpf_cnpj', 'nome'], { orderBy: 'nome' }, { number: ['cpf_cnpj'], text: ['nome'] }
    );
  }

  public cadastroPessoa(item: ContratoResponsavel) {
    this.itemAtual = item;
    if (!this.itemAtual.responsavel)
      this.cadPessoa = new Pessoa();
    else
      this.cadPessoa = this.itemAtual.responsavel;
    $('#dlgpessoaresponsavel').modal('show');
  }

  public callbackPessoa(pessoa: Pessoa) {
    this.itemAtual.responsavel = pessoa;
    this.cadPessoa = new Pessoa();
  }

  public visualizarPessoa(item: ContratoResponsavel) {
    if (!item.responsavel)
      return;
    this.viewPessoa = item.responsavel;
    $('#dialogViewPessoa').modal('show');
  }

  public importarResponsaveis() {
    this.favResponsavelService.filtrar(0, 0, { favorecido_id: this.favorecido, orgao_id: this.login.orgao.id, relations: 'pessoa' }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        const responsaveis: FavorecidoSocio[] = res.content;
        if (responsaveis?.length === 0) return;

        responsaveis.forEach(f => {
          if (!this.lista.find(c => c.responsavel.id === f.pessoa.id)) {
            this.lista.push({
              responsavel: f.pessoa,
              ativo: true,
              contratante: false
            });
          }
        });
      });
  }

  public podeVisualizar() {
    if (this.visualizar === true) {
      return true;
    }
    if(this.visualizar === false){
      return false;
    }
  }
}
