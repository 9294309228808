import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, CodigoGps } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class CodigoGpsService extends BaseResourceService<CodigoGps> {

  constructor(
    protected injector: Injector
  ) {
    super(`codigos-gps`, injector);
  }

}
