import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from 'eddydata-lib';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { IMaskModule } from 'angular-imask';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RecebimentoExtraRoutingModule } from './recebimento-extra-routing.module';
import { RecebimentoExtraViewComponent } from './recebimento-extra-view/recebimento-extra-view.component';
import { RecebimentoExtraListComponent } from './recebimento-extra-list/recebimento-extra-list.component';
import { RecebimentoExtraFormComponent } from './recebimento-extra-form/recebimento-extra-form.component';
import { RecebimentoExtraRptComponent } from './recebimento-extra-rpt/recebimento-extra-rpt.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
  declarations: [
    RecebimentoExtraViewComponent, 
    RecebimentoExtraListComponent, 
    RecebimentoExtraFormComponent,
    RecebimentoExtraRptComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    RecebimentoExtraRoutingModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    RadioButtonModule,
    ConfirmDialogModule
  ],
  providers: [MessageService, ConfirmationService]
})
export class RecebimentoExtraModule { }
