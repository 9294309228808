import { BaseResourceModel } from "../../models/base-resource.model";
import { NotaExplicativa } from "./nota-explicativa.model";

export class NotaExplicativaStorage extends BaseResourceModel {
  constructor(
    public arquivo?: string,
    public nome?: string,
    public descricao?: string,
    public tipo?: string,
    public nota_explicativa?: NotaExplicativa,
    public url?: string,
    public base64?: string
  ) {
    super();
  }

  static converteJson(json: any): NotaExplicativaStorage {
    return Object.assign(new NotaExplicativaStorage(), json);
  }
}