import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { BaseResourceService, Compra, EmpenhoResto, Page, Usuario } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class EmpenhoRestoService extends BaseResourceService<EmpenhoResto> {

  constructor(
    protected injector: Injector
  ) {
    super(`empenhos-restos`, injector);
  }

  public obterPorNumero(numero: number, ano: number, orgaoId: number): Observable<EmpenhoResto> {
    return this.http.get<EmpenhoResto>(
      `${this.login.cidade.id}/${this.api}?numero=${numero}&exercicio_ano=${ano}&orgao_id=${orgaoId}`).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterValorEmpenho(numero: number, ano: number, orgao: number): Observable<number> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/valor-empenho/${numero}/${ano}/${orgao}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalizadores(ano: number, orgaoId: number, exercicioId: number, id: number, desconPreLiqId?: number): Observable<any> {
    let parametros = '';
    parametros += '?exercicio_id=' + exercicioId;
    parametros += '&ano=' + ano;
    parametros += '&orgao_id=' + orgaoId;
    parametros += '&id=' + id;

    if (desconPreLiqId)
      parametros += '&desconPreLiq=' + desconPreLiqId;
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/extendido/1/1${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public anular(id: number, data: string, valor: number, historico: string, usuario: Usuario, compra: Compra, anularRetencoes: boolean, especie: string, usuarioId: number): Observable<EmpenhoResto> {
    const anulacao = { id, data, valor, historico, usuario, compra, anularRetencoes, especie, usuarioId };
    return this.http.post<EmpenhoResto>(
      `${this.login.cidade.id}/${this.api}/anular`, JSON.stringify(anulacao), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

  public marcarComoImpresso(parametros: any): Observable<any> {
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/marcar-como-impresso`, JSON.stringify(parametros), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

  public notaVariacaoData(exercicio: number, orgao: number, data_inicio: string, data_final: string, selectUsuario: boolean, usuarioId: number, licitacao: boolean, orderBy: string): Observable<{ entities: EmpenhoResto[], raw: any[] }> {
    return this.http.get<EmpenhoResto>(
      `${this.login.cidade.id}/${this.api}/nota-variacao-data/${exercicio}/${orgao}/${data_inicio}/${data_final}/${selectUsuario}/${usuarioId}/${licitacao}/${orderBy}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public notaVariacaoNumero(exercicio: number, orgao: number, num_inicio: number, num_final: number, selectUsuario: boolean, usuarioId: number, licitacao: boolean, orderBy: string): Observable<{ entities: EmpenhoResto[], raw: any[] }> {
    return this.http.get<EmpenhoResto>(
      `${this.login.cidade.id}/${this.api}/nota-variacao-numero/${exercicio}/${orgao}/${num_inicio}/${num_final}/${selectUsuario}/${usuarioId}/${licitacao}/${orderBy}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public notaVariacaoFicha(exercicio: number, orgao: number, ficha_inicio: number, ficha_final: number, selectUsuario: boolean, usuarioId: number, licitacao: boolean, orderBy: string): Observable<{ entities: EmpenhoResto[], raw: any[] }> {
    return this.http.get<EmpenhoResto>(
      `${this.login.cidade.id}/${this.api}/nota-variacao-ficha/${exercicio}/${orgao}/${ficha_inicio}/${ficha_final}/${selectUsuario}/${usuarioId}/${licitacao}/${orderBy}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public preencherCodigoReinf(): Observable<any>{
    return this.http.post(`${this.login.cidade.id}/${this.api}/preencher-codigo-reinf/${this.login.orgao.id}/${this.login.exercicio.id}`, {}, this.httpOptions())
    .pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
