// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Adiantamento } from './adiantamento.model';

export class AdiantamentoDocumento extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_documento?: Date,
    public documento?: string,
    public cnpj?: string,
    public razao_social?: string,
    public valor_documento?: number,
    public adiantamento?: Adiantamento,
    public editavel?: boolean,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public tipo_documento?: string
  ) {
    super();
  }

  static converteJson(json: any): AdiantamentoDocumento {
    return Object.assign(new AdiantamentoDocumento(), json);
  }
}
