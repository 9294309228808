import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreditoListComponent } from './credito-list/credito-list.component';
import { CreditoViewComponent } from './credito-view/credito-view.component';
import { CreditoFormComponent } from './credito-form/credito-form.component';
import { AuthGuard } from 'eddydata-lib';
import { CreditoExportarComponent } from './credito-exportar/credito-exportar.component';

const routes: Routes = [
  { path: '', component: CreditoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: CreditoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: CreditoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: CreditoViewComponent, canActivate: [AuthGuard] },
  { path: 'exportar', component: CreditoExportarComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CreditoRoutingModule { }
