import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ArquivoXml, BaseResourceService, Liquidacao, Retencao, Usuario } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class RPLiquidacaoService extends BaseResourceService<Liquidacao> {

  constructor(
    protected injector: Injector
  ) {
    super(`liquidacoes`, injector);
  }

  public totalLiquidadoAno(exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/total-liquidado-ano/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalLiquidadoMes(exercicio: number, orgaoId: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/total-liquidado-mes/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalFavorecidoMes(exercicio: number, favorecido: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/total-favorecido-mes/${exercicio}/${favorecido}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalParcelado(numeroEmpenho: number, exercicioId: number, orgaoId: number): Observable<any> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/total-liquidado-parcelado/${numeroEmpenho}/${exercicioId}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorNumero(numero: number, ano: number, orgaoId: number): Observable<Liquidacao> {
    return this.http.get<Liquidacao>(
      `${this.login.cidade.id}/${this.api}?numero=${numero}&exercicio_ano=${ano}&orgao_id=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterTotalLiquidadoPorEmpenho(numero: number, exercicioId: number, orgaoId: number, idExclusao?: number): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/total-liquidado-por-empenho/${numero}/${exercicioId}/${orgaoId}?`
      + (idExclusao ? `id_exclusao=${idExclusao}` : ''),
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterTotalLiquidadoPorParcela(empenho: number, exercicio: number, orgao: number, parcela: number): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/total-liquidado-por-parcela/${empenho}/${exercicio}/${orgao}/${parcela}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterTotalPagoPorParcela(exercicio: number, orgao: number, liquidacaoId: number): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/total-pago-por-parcela/${exercicio}/${orgao}/${liquidacaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterUltimaParcela(numero: number, exercicioId: number, orgaoId: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/ultima-parcela/${numero}/${exercicioId}/${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public liquidacaoEstoque(idsEstoque: number[], dataLiq: string, dataVenc: string, usuario?: Usuario): Observable<any> {
    const anulacao = { idsEstoque, dataLiq, dataVenc, usuario };
    return this.http.post<Liquidacao>(
      `${this.login.cidade.id}/${this.api}/liquidacao-estoque`, JSON.stringify(anulacao), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

  public anular(id: number, data: string, valor: number, historico: string, usuario: Usuario, retencoes: Array<Retencao>): Observable<Liquidacao> {
    const anulacao = { id, data, valor, historico, usuario, retencoes };
    return this.http.post<Liquidacao>(
      `${this.login.cidade.id}/${this.api}/anular`, JSON.stringify(anulacao), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

  public ultimaDataLiquidada(exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/ultima-data-liquidada/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public marcarComoImpresso(parametros: any): Observable<any> {
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/marcar-como-impresso`, JSON.stringify(parametros), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }


  public upload(entidade: ArquivoXml): Observable<any> {
    const formData = new FormData();
    entidade.orgao = this.login.orgao;
    entidade.descricao = '-';
    formData.append('orgao', this.login.orgao.id + '');
    formData.append('arquivo', entidade.documento);
    return this.http.post(`${this.login.cidade.id}/${this.api}/upload`, formData, {
      headers: new HttpHeaders(this.adicionarCriptografia({
        Authorization: this.login.token
      }))
    });
  }

  public valorAnulado(idLiquidacao: number): Observable<any> {
    return this.http.get<number>(`${this.login.cidade.id}/${this.api}/valor-anulado/${idLiquidacao}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public emAberto(
    exercicioId: number, orgaoId: number,
    parametros: { data_inicio: string, data_termino: string, despesa_id?: string, funcaoGoverno_id?: string, subFuncaoGoverno_id?: string, recurso_id?: string, aplicacao_id?: string, contrato_id?: string, recursosVariavel_inicial?: string, recursosVariavel_final?: string, favorecido_id?: string, data_vencimento?: string, empenho_id?: string, orderBy?: string, relations?: string[] }
  ): Observable<any> {

    const { data_inicio, data_termino, despesa_id, funcaoGoverno_id, subFuncaoGoverno_id, recurso_id, aplicacao_id, contrato_id, recursosVariavel_inicial, recursosVariavel_final, favorecido_id, data_vencimento, empenho_id, orderBy, relations } = parametros;

    let params = new HttpParams().appendAll({
      data_inicio,
      data_termino
    });

    if (despesa_id) params = params.append('despesa_id', despesa_id);
    if (funcaoGoverno_id) params = params.append('funcaoGoverno_id', funcaoGoverno_id);
    if (subFuncaoGoverno_id) params = params.append('subFuncaoGoverno_id', subFuncaoGoverno_id);
    if (recurso_id) params = params.append('recurso_id', recurso_id);
    if (aplicacao_id) params = params.append('aplicacao_id', aplicacao_id);
    if (contrato_id) params = params.append('contrato_id', contrato_id);
    if (recursosVariavel_inicial) params = params.append('recursosVariavel_inicial', recursosVariavel_inicial);
    if (recursosVariavel_final) params = params.append('recursosVariavel_final', recursosVariavel_final);
    if (favorecido_id) params = params.append('favorecido_id', favorecido_id);
    if (data_vencimento) params = params.append('data_vencimento', data_vencimento);
    if (empenho_id) params = params.append('empenho_id', empenho_id);
    if (orderBy) params = params.append('order_by', orderBy);
    if (relations) params = params.append('relations', relations.join(','));

    const options = { ...this.httpOptions(), params };

    return this.http.get(
      `${this.login.cidade.id}/${this.api}/em-aberto/${exercicioId}/${orgaoId}`,
      options
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );

  }

}
