import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FuncaoService, LoginContabil, Exercicio, GlobalService, OrgaoAssinaturaService } from 'eddydata-lib';
import { BalancoService } from '../service/balanco.service';
import { NotaExplicativaService } from '../service/nota-explicativa.service';
import { BaseResourceNotaExplicativa } from '../base-resource-nota-explicativa';

@Injectable({
  providedIn: 'root'
})
export class Anexo18D extends BaseResourceNotaExplicativa implements OnDestroy {

  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: BalancoService,
    protected notaService: NotaExplicativaService,
    protected assinaturaService: OrgaoAssinaturaService
  ) {
    super('B15', notaService, assinaturaService)
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(dados: any, exercicio: Exercicio): {}[] {

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: '', colSpan: 3, border: [true, true, true, false]
      }, '', ''],
      [{
        text: `ANEXO 18D - QUADRO DE JUROS E ENCARGOS DA DÍVIDA`, fontSize: 11,
        alignment: 'center', bold: true, colSpan: 3, border: [true, false, true, true]
      }, '', ''],
      [{
        text: 'TÍTULOS',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'EXERCÍCIO ATUAL',
        alignment: 'center',
        bold: true, fontSize: 8,
      }, {
        text: 'EXERCÍCIO ANTERIOR',
        alignment: 'center',
        bold: true, fontSize: 8
      }]
    ];

    // monta o agrupamento do relatório
    let total1 = 0;
    let total2 = 0;
    for (const titulo of dados[5]) {
      registros.push([
        {
          text: titulo.grupo, bold: true, fontSize: 9, border: [true, false, true, false], alignment: 'left'
        },
        {
          text: '', border: [true, false, true, false]
        },
        {
          text: '', border: [true, false, true, false]
        }
      ]);
      registros.push([
        {
          text: titulo.titulo, fontSize: 8, border: [true, false, true, false], alignment: 'left'
        },
        {
          text: this.funcaoService.convertToBrNumber(titulo['valor_atual']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(titulo['valor_anterior']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        }
      ]);

      total1 += +titulo['valor_atual'];
      total2 += +titulo['valor_anterior'];
    }

    registros.push([
      {
        text: 'Total dos Juros e Encargos da Dívida', bold: true, fontSize: 8, border: [true, true, true, true], margin: [0, 5, 0, 5]
      },
      {
        text: this.funcaoService.convertToBrNumber(total1), bold: true, alignment: 'right',
        fontSize: 8, border: [true, true, true, true], margin: [0, 5, 0, 5]
      },
      {
        text: this.funcaoService.convertToBrNumber(total2), bold: true, alignment: 'right',
        fontSize: 8, border: [true, true, true, true], margin: [0, 5, 0, 5]
      }
    ]);

    return registros;
  }

  public montarRelatorioCSV(dados): {}[] {

    // monta o cabecalho
    const registros: {}[] = [
      [{ text: '' }], [{ text: `ANEXO 18D - QUADRO DE JUROS E ENCARGOS DA DÍVIDA` }],
      [{ text: 'TÍTULOS' }, { text: 'EXERCÍCIO ATUAL' }, { text: 'EXERCÍCIO ANTERIOR' }],
    ];

    // monta o agrupamento do relatório
    let total1 = 0;
    let total2 = 0;
    for (const titulo of dados[5]) {
      registros.push([
        { text: titulo.titulo },
        { text: this.funcaoService.convertToBrNumber(titulo['valor_atual']) },
        { text: this.funcaoService.convertToBrNumber(titulo['valor_anterior']) },
      ]);

      total1 += +titulo['valor_atual'];
      total2 += +titulo['valor_anterior'];
    }

    registros.push([
      { text: 'Total dos Juros e Encargos da Dívida' },
      { text: this.funcaoService.convertToBrNumber(total1) },
      { text: this.funcaoService.convertToBrNumber(total2) },
    ]);

    return registros;
  } 

}
