import { Component, Injector, ElementRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import { DatePipe } from '@angular/common';
import {
  BaseResourceFormComponent, Licitacao, LoginPublico, Proponente, Memorial,
  Proposta, GlobalService, FuncaoService, DateFormatPipe
} from 'eddydata-lib';
import { PropostaService } from '../../proposta/service/proposta.service';
import { ProponenteService } from '../../proponente/service/proponente.service';
import { MemorialService } from '../../memorial/service/memorial.service';
import { LicitacaoService } from '../service/licitacao.service';

@Component({
  selector: 'lib-licitacao-shw',
  templateUrl: './licitacao-shw.component.html'
})
export class LicitacaoShwComponent extends BaseResourceFormComponent<Licitacao, LoginPublico> {

  /**
   * Declaração de variáveis
   */
  private pagina: string;
  private data1: string;
  private data2: string;
  private filtro: string;
  private datepipe: DatePipe;

  public listaProponentes: Array<Proponente>;
  public listaMemoriais: Array<Memorial>;
  public listaPropostas: Array<Proposta>;
  public descricao: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected propostaService: PropostaService,
    protected proponenteService: ProponenteService,
    protected memorialService: MemorialService,
    protected licitacaoService: LicitacaoService) {
    super(new Licitacao(), injector, Licitacao.converteJson, licitacaoService);
    this.datepipe = new DatePipe('pt');
    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.pagina = params['pagina'];
        this.data1 = params['data1'];
        this.data2 = params['data2'];
        this.filtro = params['filtro'];
      });
  }


  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      data_abertura: [null, [Validators.required]],
      valor_estimado: [null, [Validators.required]],
      valor_licitado: [null, [Validators.required]],
      data_ata: [null],
      data_publicacao: [null],
      data_homologacao: [null],
      data_edital: [null],
      data_julgamento: [null],
      enviar_audesp: [null],
      enviar_transparencia: [null],
      julgamento: [null],
      situacao: [null],
      processo: [null, [Validators.required]],
      modalidade: this.fb.group({
        id: [null, [Validators.required]],
        nome: [null, [Validators.required]]
      }),
      setor: [null, [Validators.required]],
      exercicio: this.fb.group({
        id: [null, [Validators.required]],
        ano: [null, [Validators.required]]
      }),
      objeto: [null, [Validators.required]]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'modalidade,orgao,setor,exercicio' };
  }

  protected afterInit(): void {
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: Licitacao): void {
  }

  protected afterLoad() {
    this.entidade.data_abertura = new DateFormatPipe().transform(this.entidade.data_abertura, []);
    this.entidade.data_homologacao = new DateFormatPipe().transform(this.entidade.data_homologacao, []);
    this.preencherTabela();
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private preencherTabela() {
    this.proponenteService.obterPorLicitacao(this.entidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaProponentes = res.content;
      });

    this.memorialService.obterPorLicitacao(this.entidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaMemoriais = res.content;
      });
  }

  public abrirProposta(item: Memorial) {
    this.descricao = item.descricao;
    this.propostaService.obterPorMemorial(item.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaPropostas = res.content;
      });
  }

  public voltar() {
    const dt1 = this.datepipe.transform(this.data1, 'yyyy-MM-dd', 'GMT');
    const dt2 = this.datepipe.transform(this.data2, 'yyyy-MM-dd', 'GMT');
    if (!this.filtro || this.filtro === '') {
      this.router.navigate([`/processos-licitatorios/pesquisa`, this.pagina, dt1, dt2]);
    } else {
      this.router.navigate([`/processos-licitatorios/pesquisa`, this.pagina, dt1, dt2, this.filtro]);
    }
  }

}
