// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ProdutoUnidade } from '../almoxarifado/produto-unidade.model';
import { ReciboMerenda } from './recibo-merenda.model';

export class ReciboItemMerenda extends BaseResourceModel {
  constructor(
    public id?: number,
    public produto_unidade?: ProdutoUnidade,
    public qtde?: number,
    public qtde_fornecedor?: number,
    public indice_percentual?: number,
    public peso_unitario?: number,
    public recibo?: ReciboMerenda,
    public editavel?: boolean) {
    super();
  }
  static converteJson(json: any): ReciboItemMerenda {
    return Object.assign(new ReciboItemMerenda(), json);
  }
}
