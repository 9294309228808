import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, IndiceCorrecao } from 'eddydata-lib';

@Injectable({
    providedIn: 'root'
})
export class IndiceCorrecaoService extends BaseResourceService<IndiceCorrecao> {

    constructor(
        protected injector: Injector
    ) {
        super(`indice-correcao`, injector);
    }

}
