import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class HEREService {

  private herekey: '';//CHAVE REMOVIDA POR SER DO MEU CADASTRO PESSOAL

  protected http: HttpClient;

  constructor(
    protected injector: Injector
  ) {
    this.http = injector.get(HttpClient);
  }

  getKey() {
    return this.herekey;
  }

  converterDestino(valor: number, tipo: string, base: number): String {
    if (tipo == 'km') {
      let medida: string[] = ['Cm', 'Mt', 'Km'];
      let index: number = base;

      for (index = 0; index < medida.length; index++) {
        if (valor < 100) {
          break;
        }
        valor = valor / 10;
      }
      return valor.toFixed(2) + ' ' + medida[base];
    }
    else if (tipo == 'hr') {
      let medida: string[] = ['Seg', 'Min', 'Hrs'];
      let index: number = base;

      for (index = 0; index < medida.length; index++) {
        if (valor < 60) {
          break;
        }
        valor = valor / 60;
      }
      return valor.toFixed(2).replace('.', ':') + ' ' + medida[index];
    }
  }

  removerAcentos(texto: string): string {
    if (!texto) {
      return '';
    }
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }


  //REMOVER CHAVE FIXA POSTERIORMENTE
  public buscarRota(origem: String, destino: String): Observable<any> {
    return this.http.get(`https://router.hereapi.com/v8/routes?transportMode=car&origin=${origem}&destination=${destino}&return=summary&apikey=UTUpQ-o6W04amZT4UNpdyH5LCvdlcxnDy4cnm4fTrbw`);
  }

  //REMOVER CHAVE FIXA POSTERIORMENTE
  public buscarCidade(cidade: string): Observable<any> {
    return this.http.get('https://geocoder.ls.hereapi.com/6.2/geocode.json?type=GET&dataType=JSON&jsonp=jsoncallback&searchtext='
      + cidade + '&gen=9&apiKey=UTUpQ-o6W04amZT4UNpdyH5LCvdlcxnDy4cnm4fTrbw');
  }

}

