import { MessageService } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService, LoginPublico, LoginService } from 'eddydata-lib';

declare var $: any;

@Component({
  selector: 'app-navbar-transparencia',
  templateUrl: './navbar-transparencia.component.html',
  styleUrls: ['./navbar-transparencia.component.css']
})
export class NavbarTransparenciaComponent implements OnInit {

  public login = new LoginPublico();
  public filtro: string;

  constructor(
    private messageService: MessageService,
    protected router: Router,
    private authService: LoginService
  ) { }

  ngOnInit() {
    sessionStorage.setItem('contraste', 'false');
    this.login = GlobalService.obterSessaoLogin();
    if (this.login) {
      this.login.contraste = sessionStorage.getItem('contraste') ? sessionStorage.getItem('contraste') === 'true' : false;
    } else {
      this.authService.usuarioEstaAutenticadoFake().then(() => {
        location.reload();
      });
    }
  }

  pesquisar() {
    if (!this.filtro || this.filtro === '') {
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Informe ao menos uma palavra para a pesquisa' });
    } else {
      this.router.navigate(['/transparencia/pesquisa', this.filtro]);
    }
  }

  btnContraste() {
    this.login.contraste = !this.login.contraste;
    sessionStorage.setItem('contraste', String(this.login.contraste));
    this.authService.mostrarContrasteEmitter.emit(this.login.contraste);
    this.router.navigate(['/transparencia']);
  }

  fonte(e: string) {
    const elemento = $('.fonte-acessibilidade');
    const fonte = elemento.css('font-size');
    if (e === 'a') {
      elemento.css('fontSize', parseInt(fonte, 0) + 1);
    } else if ('d') {
      elemento.css('fontSize', parseInt(fonte, 0) - 1);
    }
  }

  fonteNormal() {
    location.reload();
  }

}
