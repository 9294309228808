import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Exercicio } from '../comum/exercicio.model';

export class EntidadePartido extends BaseResourceModel {
  constructor(
    public id?: number,

    public nome?: string,
    public email?: string,
    public orgao?: Orgao,
    public exercicio?: Exercicio,) {
    super();
  }
  static converteJson(json: any): EntidadePartido {
    return Object.assign(new EntidadePartido(), json);
  }
}