import { RequisicaoExcessaoService } from '../service/requisicao-excecao.service';

import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { GlobalService, LoginContabil, Rcms, RequisicaoExcecao } from "eddydata-lib";
import * as toastr from 'toastr'

@Directive({
    selector: '[bloqueioRequisicao]'
})
export class BloqueioRequisicaoDirective implements OnInit {
    private login: LoginContabil
    @Input() requisicao?: Rcms;

    constructor(
        private requisicaoExcecaoService: RequisicaoExcessaoService,
        private el: ElementRef
    ) { }

    async ngOnInit(): Promise<void> {
        this.login = GlobalService.obterSessaoLogin()
        let dados = await this.requisicaoExcecaoService.filtrar(1, -1, { 'parametro.orgao.id': this.login.orgao.id, 'parametro.id': this.login.parametro['requisicao']['id'], relations: 'parametro.orgao,usuario_excecao' }).toPromise()

        if (this.login.parametro['requisicao']['bloquear_nova_requisicao'] && (!this.requisicao || !this.requisicao.id)) {
            if (dados.content.length > 0) {

                let usuarioLiberado = dados.content.find((u: RequisicaoExcecao) => (
                    u.usuario_excecao.id === this.login.usuario.id
                ))
                if (!usuarioLiberado) {
                    toastr.warning('Novas requisições estão bloqueadas.\nPara mais informações contate o administrador do sistema!')
                    this.el.nativeElement.disabled = true
                    this.el.nativeElement.classList.add('disabled')
                }
            } else {
                toastr.warning('Novas requisições estão bloqueadas.\nPara mais informações contate o administrador do sistema!')
                this.el.nativeElement.disabled = true
                this.el.nativeElement.classList.add('disabled')
            }
        }
    }
}