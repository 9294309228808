import { Component, ElementRef, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BaseResourceFormComponent, EddyAutoComplete, FuncaoService, GlobalService, LoginContabil, ParametroAlmoxarifadoService, RequisicaoItemMovimento, RequisicaoMovimento, SetorAlmoxarifado, SetorAlmoxarifadoService, StatusRequisicao
} from 'eddydata-lib';
import { RequisicaoMovimentoService } from '../service/requisicao-movimento.service';
import * as toastr from 'toastr';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-requisicao-form',
  templateUrl: './requisicao-form.component.html'
})
export class RequisicaoFormComponent extends BaseResourceFormComponent<RequisicaoMovimento, LoginContabil> {
  /**
   * Declaração de variáveis
   */
  public listStatus: StatusRequisicao[] = [];
  public setorAutoComplete: EddyAutoComplete<SetorAlmoxarifado>;
  public itens: RequisicaoItemMovimento[];

  public visualizarEstorno: boolean = false;
  public novoCod: number = null;
  public novoCodigo: boolean = true;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public globalService: GlobalService,
    public funcaoService: FuncaoService,
    private setorService: SetorAlmoxarifadoService,
    private parametroAlmoxarifadoService: ParametroAlmoxarifadoService,
    protected requisicaoMovService: RequisicaoMovimentoService) {
    super(new RequisicaoMovimento(), injector, RequisicaoMovimento.converteJson, requisicaoMovService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(entidade: RequisicaoMovimento): Promise<boolean> {
    const mesEncerrado = await this.parametroAlmoxarifadoService.mesFechadoAlmoxarifadoa(this.login, this.login.setorAlmoxarifado ?
      this.login.setorAlmoxarifado.estoque.id : this.login.estoque.id);
    return entidade.status === 'A' && !mesEncerrado;
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      data_cadastro: [new Date()],
      status: [StatusRequisicao.AGUARDANDO, Validators.required],
      destinatario: [this.login.usuario.nome],
      motivo_devolucao: [null],
      devolucao: [false, Validators.required],
      setor_almoxarifado: [null, Validators.required],
      usuario: [this.login.usuario],
      nome_paciente: [null],
      mcv_paciente: [null],
      paciente: [null],
      exercicio: [this.login.exercicio],
      orgao: [this.login.orgao],
      itens: [null]
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: [
        'usuario', 'setor_almoxarifado', 'setor_almoxarifado.setor', 'setor_almoxarifado.estoque',
        'exercicio', 'orgao', 'usuario', 'recebedor', 'itens', 'itens.produto_unidade',
        'itens.produto_unidade.produto', 'itens.produto_unidade.unidade', 'itens.produto_unidade_troca',
        'itens.produto_unidade_troca.produto', 'itens.produto_unidade_troca.unidade'
      ]
    };
  }

  protected afterInit(): void {
    this.listStatus = this.globalService.statusRequisicao();
    this.carregarAutoCompleteSetor()

    this.entidade.data_cadastro = new Date();
    this.entidade.usuario = this.login.usuario;
    this.entidade.status = StatusRequisicao.AGUARDANDO;

    this.codigoSearch();
  }

  public codigoSearch() {
    this.requisicaoMovService.proximoCodigo(this.login.exercicio.id, this.login.orgao.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(codigo => {
        this.novoCod = codigo;
      }, error => {
        this.funcaoService.acaoErro(error)
      })
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected async afterLoad() {
    this.itens = this.entidade.itens;
    this.novoCodigo = false;
    if (this.entidade.id) {
      this.entidadeForm.disable();
    }
  }

  protected beforeSubmit() {
    this.entidadeForm.get('itens').setValue(this.itens);

    let setor: SetorAlmoxarifado = this.entidadeForm.get("setor_almoxarifado").value;
    if (setor) {
      if (setor.setor.nome.includes('PACIENTE')) {
        this.entidadeForm.get("paciente").setValue(true);
      } else {
        this.entidadeForm.get("paciente").setValue(false);
        this.entidadeForm.get("nome_paciente").setValue(null);
        this.entidadeForm.get("mcv_paciente").setValue(null);
      }
    }

  }

  protected afterSubmit(ent: RequisicaoMovimento) {
    this.entidadeForm.get('itens').setValue(ent.itens);
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private carregarAutoCompleteSetor() {

    let setorIds = this.login.setor.map((setor) => {
      return setor.id
    })

    const parametros = {
      "setor.id$in": setorIds.join(","),
      "setor.orgao.id": this.login.orgao.id,
      "estoque.id": this.login.estoque.id,
      relations: ['setor', 'setor.orgao'],
      orderBy: "setor.nome",
    };

    this.setorAutoComplete = new EddyAutoComplete(this.entidadeForm.get("setor_almoxarifado"), this.setorService,
      "id", ["setor.nome", 'setor.codigo'], parametros, { text: ["setor.nome", 'setor.codigo'] },
    );

  }

  public importarRequisicao(requisicao: RequisicaoMovimento) {
    if (!this.setorAutoComplete.id) {
      let msge_err = "Preencha o campo: <strong> Setor</strong>!";
      toastr.warning(msge_err);
    }

    try {
      if (!this.entidade.itens)
        this.entidade.itens = [];
      for (let item of requisicao.itens) {
        for (let item2 of this.entidade.itens) {
          if (item.produto_unidade.id === item2.produto_unidade.id)
            throw new Error(`${item.produto_unidade.produto.nome} ja foi adicionado aos itens!`);
        }
      }
      this.entidade.itens = this.entidade.itens.concat(requisicao.itens.map((i) => {
        let item = new RequisicaoItemMovimento();
        item.produto_unidade = i.produto_unidade;
        item.qtd_requisitada = 0; // i.qtd_requisitada;
        item.status = StatusRequisicao.AGUARDANDO;
        return item;
      }));
      this.afterLoad();
    } catch (err) {
      this.funcaoService.acaoErro(err);
    }
  }

  public disabledItem() {
    return this.itens && this.itens.length > 0;
  }

  public tituloStatusRequisicao(status: StatusRequisicao): string {
    return this.globalService.tituloStatusRequisicao(status);
  }

  public iconStatusRequisicao(status: StatusRequisicao): {} {
    let icon = this.globalService.iconStatusRequisicao(status);
    let ngIcon = {};
    ngIcon[icon] = true;
    return ngIcon;
  }

  public verificarPaciente() {
    let setor: SetorAlmoxarifado = this.entidadeForm.get("setor_almoxarifado").value;
    if (setor) {
      if (setor.setor.nome.includes('PACIENTE')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public corStatusRequisicao(status: StatusRequisicao): {} {
    let cor = this.globalService.corStatusRequisicao(status);
    let ngCor = {};
    ngCor[`bg-${cor}`] = true;
    return ngCor;
  }

  public badgeStatusRequisicao(status: StatusRequisicao): {} {
    let cor = this.globalService.corStatusRequisicao(status);
    let ngCor = {};
    ngCor[`badge-${cor}`] = true;
    return ngCor;
  }

  public valorRequisitado() {
    if (!this.itens)
      return 0.0;
    let vl = 0.0;
    for (let it of this.itens) {
      if (+it.qtd_requisitada)
        vl += Number.parseFloat(it.qtd_requisitada.toString());
    }
    return vl;
  }

  public valorAceito() {
    if (!this.itens)
      return 0.0;
    let vl = 0.0;
    for (let it of this.itens) {
      if (it.qtd_aceita)
        vl += Number.parseFloat(it.qtd_aceita.toString());
    }
    return vl;
  }

  public valorRecusado() {
    if (!this.itens)
      return 0.0;
    let vl = 0.0;
    for (let it of this.itens) {
      if (it.status && it.status !== StatusRequisicao.AGUARDANDO && it.status !== StatusRequisicao.AGUARDANDO_FORNECEDOR)
        vl += Number.parseFloat(it.qtd_requisitada.toString()) - Number.parseFloat(it.qtd_aceita.toString());
    }
    return vl;
  }

  public async verificarSalvar() {
    if (this.entidadeForm.value['id']) {
      const requisaoMateriais = await this.requisicaoMovService.extendido(1, -1, { 'id': this.entidadeForm.value['id'], 'orgao.id': this.login.orgao.id }).toPromise();
      if (requisaoMateriais.content.length > 0) {
        if (requisaoMateriais.content[0].status !== 'A') {
          alert('Requerimento de materias não disponivel para edição!')
          // location.reload()
          this.router.navigate(['/requisicoes-materiais', this.entidadeForm.value['id'], 'visualizar'])
          throw new Error('Requerimento de materias não disponivel para edição!')
        } else {
          this.submitForm();
        }
      }
    } else {
      this.submitForm();
    }
  }
}
