// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Cidade } from '../geo/cidade.model';

export class FuncaoGoverno extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public nome?: string,
    public nivel?: number,
    public descricao?: string,
    public cidade?: Cidade,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public grafico_empenho?: Boolean,
  ) {
    super();
  }

  static converteJson(json: any): FuncaoGoverno {
    return Object.assign(new FuncaoGoverno(), json);
  }
}
