import { Component } from "@angular/core";
import { DespesaService } from "administrativo-lib";
import { ProdutoService, SubGrupoEstoqueService } from "almoxarifado-lib";
import { BaseResourceRptComponent, Coluna, Despesa, EddyAutoComplete, Exercicio, ExercicioService, SubGrupoEstoque } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'lib-listagem-subgrupo-rpt',
  templateUrl: './listagem-subgrupo-rpt.component.html'
})
export class ListagemSubgrupoRpt extends BaseResourceRptComponent {
  /**
* Declaração de variáveis
*/

  public subGrupoAutoComplete: EddyAutoComplete<SubGrupoEstoque>
  public subelementoAutoComplete: EddyAutoComplete<Despesa>
  public exercicioAutoComplete: EddyAutoComplete<Exercicio>

  public subGrupo: SubGrupoEstoque
  public subelemento: Despesa
  public exercicio: Exercicio
  public situacao: 'A' | 'I' | 'T' = 'A'
  public material_tipo: 'M' | 'S' | 'T' = 'M'
  public situacao_subelemento: 'A' | 'I' | 'T' = 'A'

  /**
* Construtor com as injeções de dependencias
*/

  constructor(
    public subGrupoEstoqueService: SubGrupoEstoqueService,
    public subelementoService: DespesaService,
    public exercicioService: ExercicioService,
    public produtoService: ProdutoService
  ) {
    super()
  }

  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================

  protected afterInit(): void {
    this.exercicio = this.login.exercicio
    this.carregarAutocomplete()
    this.orientacao = 'landscape'
    this.formato = 'xlsx'
  }

  protected tituloRelatorio(): string {
    return this.login.orgao.nome
  }

  protected subTituloRelatorio(): string {
    return 'LISTAGEM DE PRODUTOS / SERVIÇOS POR SUBGRUPO'
  }

  protected obterColunasRelatorio(): Coluna[] {
    return [
      { titulo: 'Cod. Grupo', coluna: 'grupo_codigo', alignment: 'center', fontSize: 7 },
      { titulo: 'Grupo', coluna: 'grupo_nome', fontSize: 7 },
      { titulo: 'Cod. SubGrupo', coluna: 'subgrupo_codigo', alignment: 'center', fontSize: 7 },
      { titulo: 'SubGrupo', coluna: 'subgrupo_nome', fontSize: 7 },
      { titulo: 'Situação Subelemento', coluna: 'subelemento_situacao', fontSize: 7 },
      { titulo: 'Cod. Subelemento', coluna: 'subelemento_codigo', alignment: 'center', fontSize: 7 },
      { titulo: 'Subelemento', coluna: 'subelemento_nome', fontSize: 7 },
      { titulo: 'Tipo Material', coluna: 'material_tipo', fontSize: 7 },
      { titulo: 'Cod. Material', coluna: 'material_codigo', alignment: 'center', fontSize: 7 },
      { titulo: 'Material', coluna: 'material_nome', alignment: 'center', fontSize: 7 },
      { titulo: 'Aliquota Mat. IR', coluna: 'material_aliquota_ir', alignment: 'center', fontSize: 7 },
      { titulo: 'Situação Produto', coluna: 'produto_situacao', fontSize: 7 },
      { titulo: 'Cod. Produto', coluna: 'produto_codigo', alignment: 'center', fontSize: 7 },
      { titulo: 'Produto', coluna: 'produto_nome', fontSize: 7 },
      { titulo: 'Aliquota Prod. IR', coluna: 'produto_aliquota_ir', alignment: 'center', fontSize: 7 },
    ]
  }

  protected larguraColunas(): (string | number)[] {
    return ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*', 'auto']
  }

  protected totalizarColunas(): (string | {})[] {
    return
  }

  protected carregarLista(): Promise<any[]> {
    return new Promise((resolve) => {

      this.produtoService.listagemPorSubGrupo({ situacao: this.situacao, subelemento_id: this.subelemento?.id, subgrupo_id: this.subGrupo?.id, exercicio_id: this.exercicio?.id, material_tipo: this.material_tipo, orgao_id: this.login?.orgao?.id, situacao_subelemento: this.situacao_subelemento }).pipe(takeUntil(this.unsubscribe)).subscribe(lista => {
        resolve(lista)
      })

    })
  }


  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  private carregarAutocomplete() {
    this.exercicioAutoComplete = new EddyAutoComplete(null, this.exercicioService, 'id', ['ano'], { orderBy: 'ano$DESC' }, { number: ['ano'] })

    this.subGrupoAutoComplete = new EddyAutoComplete(null, this.subGrupoEstoqueService, 'id', ['grupo.codigo', 'codigo', 'nome'], { orgao_id: this.login.orgao.id, relations: 'grupo' }, { number: ['codigo'], text: ['nome'] })

    this.carregarAutoCompleteSubelemento()
  }

  public carregarAutoCompleteSubelemento() {
    this.subelementoAutoComplete = new EddyAutoComplete(null, this.subelementoService, 'id', ['codigo', 'nome'], { nivel: 6, exercicio_id: this.exercicio.id }, { text: ['codigo', 'nome'] })
  }

}

