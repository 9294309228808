import { BaseResourceModel } from '../../models/base-resource.model';
import { Despesa } from "./despesa.model";
import { DespesaSiope } from "./despesa-siope.model";

export class DespesaSiopeDespesa extends BaseResourceModel {
  constructor(
    public id?: number,
    public despesaSiope?: DespesaSiope,
    public despesa?: Despesa,) {
    super();
  }
  static converteJson(json: any): DespesaSiopeDespesa {
    return Object.assign(new DespesaSiopeDespesa(), json);
  }
}