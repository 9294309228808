import { NgModule } from '@angular/core';

import { PagamentoExtraRoutingModule } from './pagamento-extra-routing.module';
import { PagamentoExtraListComponent } from './pagamento-extra-list/pagamento-extra-list.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { IMaskModule } from 'angular-imask';
import { ToastModule } from 'primeng/toast';
import { SharedModule } from 'eddydata-lib';
import { PagamentoExtraFormComponent } from './pagamento-extra-form/pagamento-extra-form.component';
import { PagamentoExtraRptComponent } from './pagamento-extra-rpt/pagamento-extra-rpt.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { PagamentoComumModule } from '../pagamento-comum/pagamento-comum.module';
import { InplaceModule } from 'primeng/inplace';
import { EmpenhoModule } from '../../empenho/empenho.module';
import { PagamentoAnulacaoComponent } from '../pagamento-anulacao/pagamento-anulacao.component';

@NgModule({
  declarations: [
    PagamentoExtraListComponent,
    PagamentoExtraFormComponent,
    PagamentoExtraRptComponent,
    PagamentoAnulacaoComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    PagamentoExtraRoutingModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    InplaceModule,
    ToastModule,
    RadioButtonModule,
    ConfirmDialogModule,
    FieldsetModule,
    PagamentoComumModule,
    EmpenhoModule
  ],
  providers: [MessageService, ConfirmationService]
})
export class PagamentoExtraModule { }
