import { Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CompraService } from '../service/compra.service';
import {
  BaseResourceListComponent, Coluna, Compra, Filtro, FuncaoService, GlobalService, LoginContabil
} from 'eddydata-lib';
import { Observable } from 'rxjs';

declare var $: any;

@Component({
  selector: 'lib-compra-list-dlg',
  templateUrl: './compra-list-dlg.component.html'
})
export class CompraListDlgComponent extends BaseResourceListComponent<Compra, LoginContabil> implements OnChanges {

  /**
   * Declaração de variáveis
   */
  public titulo: string = 'ORDENS DE FORNECIMENTO';

  @Output() selecionarCompra: EventEmitter<Compra> = new EventEmitter();
  @Input() filtroParam: string;
  @Input() anoOFSelecionado: number;


  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public funcaoService: FuncaoService,
    public globalService: GlobalService,
    private compraService: CompraService) {
    super(compraService, injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filtroParam) {
      this.onClick(this.filtroParam ? this.filtroParam.toString() : '');
    }
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'orgao,favorecido,ficha,ficha.despesa,contrato';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id,
      ['exercicio.id']: this.anoOFSelecionado ? this.anoOFSelecionado : this.login.exercicio.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['numero$ASC', 'data_compra$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['numero', 'ficha.despesa.codigo'],
      date: ['data_compra'],
      text: ['favorecido.nome', 'requerente'],
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: Compra): Observable<Compra> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Número', coluna: 'numero' },
      { titulo: 'Data', coluna: 'data_compra' },
      { titulo: 'Fornecedor', coluna: 'favorecido.nome' },
      { titulo: 'Requerente', coluna: 'requerente' },
      { titulo: 'Despesa', coluna: 'ficha.despesa.nome' },
    ];
  }

  selecionar(compra: Compra) {
    $('#dialog_dlg_compra').modal('hide');
    this.selecionarCompra.emit(compra)
  }

}
