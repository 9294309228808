import { Directive, Injector, OnDestroy } from '@angular/core';
import { Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, ProgressoService, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AnexoLRFService } from '../../service/anexo-lrf.service';
import { Assinaturas } from '../../../assinaturas/assinaturas';

@Directive()

export class DemonstrativoApuracaoCumprimento implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLRFService,
    protected bimestre: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected progressoService: ProgressoService) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async montarRelatorio(formato: FormatoExportacao) {
    this.anexoServico.obterDemonstrativoApuracaoCumprimento(this.exercicio.id, this.bimestre)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async idTransacao => {
        this.progressoService.show(idTransacao, async (retorno) => {
          if (formato === 'pdf') {
            Relatorio.imprimirPersonalizado(`Demonstrativo de Apuração do Cumprimento ou não do Art. 42 da lrf - Referência: ${this.exercicio.ano}`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
              await this.montarConteudo(retorno),
              'portrait', 'Demonstrativo de Apuração do Cumprimento ou não do Art. 42 da lrf',
              {
                linhas: {
                  hLineWidth() {
                    return 1;
                  },
                  vLineWidth() {
                    return 1;
                  },
                  hLineColor() {
                    return 'black';
                  },
                  paddingLeft() {
                    return 2;
                  },
                  paddingRight() {
                    return 2;
                  }
                }
              });
          } else {
            // this.funcaoService.exportar(formato);
          }
        });
      });
  }

  private async montarConteudo(dados: any): Promise<{}[]> {
    const registros: {}[] = [];

    registros.push([
      { text: 'Apuração por órgão do 1º ao 5º Bimestre do último ano de mandato', alignment: 'center', bold: true, fontSize: 9 },
      { text: 'P. EXECUTIVO - PM', alignment: 'center', bold: true, fontSize: 9 },
      { text: 'P. LEGISLATIVO - CM', alignment: 'center', bold: true, fontSize: 9 },
    ]);

    registros.push([
      { text: 'Disponibilidade Financeira no final do período', fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(dados.disponibilidade_financeira_prefeitura, 2), alignment: 'right', fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(dados.disponibilidade_financeira_camara, 2), alignment: 'right', fontSize: 8 },
    ]);

    registros.push([
      { text: '(-)  Saldo de Restos a Pagar até o período', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(dados.saldo_restos_prefeitura, 2), alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(dados.saldo_restos_camara, 2), alignment: 'right', fontSize: 7 },
    ]);

    registros.push([
      { text: '(-)  Empenhos Liquidados a Pagar até o período', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(dados.empenho_liquidado_prefeitura, 2), alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(dados.empenho_liquidado_camara, 2), alignment: 'right', fontSize: 7 },
    ]);

    registros.push([
      { text: '(-)  Saldo da Despesa Empenhada a Liquidar e em Liquidação', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(dados.saldo_despesa_prefeitura, 2), alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(dados.saldo_despesa_camara, 2), alignment: 'right', fontSize: 7 },
    ]);

    registros.push([
      { text: '(-)  Valores Restituíveis', bold: true, fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(dados.valor_restituivel_prefeitura, 2), alignment: 'right', bold: true, fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(dados.valor_restituivel_camara, 2), alignment: 'right', bold: true, fontSize: 8 },
    ]);

    const total5bimestreP = +dados.disponibilidade_financeira_prefeitura - +dados.saldo_restos_prefeitura - +dados.empenho_liquidado_prefeitura - +dados.saldo_despesa_prefeitura - +dados.valor_restituivel_prefeitura;
    const total5bimestreC = +dados.disponibilidade_financeira_camara - +dados.saldo_restos_camara - +dados.empenho_liquidado_camara - +dados.saldo_despesa_camara - +dados.valor_restituivel_camara;

    registros.push([
      { text: '(=)  Liquidez do Período (superávit, déficit ou equilíbrio)', bold: true, fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(total5bimestreP, 2), alignment: 'right', bold: true, fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(total5bimestreC, 2), alignment: 'right', bold: true, fontSize: 8 },
    ]);

    registros.push([
      { text: '(+)  Saldo da Receita Prevista a Realizar', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(dados.receita_prevista_prefeitura, 2), alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(dados.receita_prevista_camara, 2), alignment: 'right', fontSize: 7 },
    ]);

    registros.push([
      { text: '(-)  Saldo da Despesa Autorizada a Empenhar', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(dados.despesa_autorizada_prefeitura, 2), alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(dados.despesa_autorizada_camara, 2), alignment: 'right', fontSize: 7 },
    ]);

    registros.push([
      { text: '(-)  Saldo das Transferências Financeiras a Realizar', bold: true, fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(dados.transferencia_financeira_prefeitura, 2), alignment: 'right', bold: true, fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(dados.transferencia_financeira_camara, 2), alignment: 'right', bold: true, fontSize: 7 },
    ]);

    const total5bimestrePa = +total5bimestreP + +dados.receita_prevista_prefeitura - +dados.despesa_autorizada_prefeitura - +dados.transferencia_financeira_prefeitura;
    const total5bimestreCa = +total5bimestreC + +dados.receita_prevista_camara - +dados.despesa_autorizada_camara - +dados.transferencia_financeira_camara;

    registros.push([
      { text: '(=)  Liquidez do Período (superávit, déficit ou equilíbrio)', bold: true, fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(total5bimestrePa, 2), alignment: 'right', bold: true, fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(total5bimestreCa, 2), alignment: 'right', bold: true, fontSize: 8 },
    ]);

    registros.push([
      { text: 'Apuração por órgão dos últimos 8 (oito) meses de mandado', alignment: 'center', bold: true, fontSize: 9, colSpan: 3 },
      '',
      ''
    ]);

    registros.push([
      { text: `Disponibilidade Financeira em 30/04/${this.exercicio.ano}`, fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(dados.disponibilidade_financeira_prefeitura8, 2), alignment: 'right', fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(dados.disponibilidade_financeira_camara8, 2), alignment: 'right', fontSize: 8 },
    ]);

    registros.push([
      { text: `(-)  Saldo de Restos a Pagar até 30/04/${this.exercicio.ano}`, bold: true, fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(dados.saldo_restos_prefeitura8, 2), alignment: 'right', bold: true, fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(dados.saldo_restos_camara8, 2), alignment: 'right', bold: true, fontSize: 7 },
    ]);

    registros.push([
      { text: `(-)  Empenhos Liquidados a pagar até 30/04/${this.exercicio.ano}`, bold: true, fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(dados.empenho_liquidado_prefeitura8, 2), alignment: 'right', bold: true, fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(dados.empenho_liquidado_camara8, 2), alignment: 'right', bold: true, fontSize: 7 },
    ]);

    registros.push([
      { text: '(-)  Valores Restituíveis', bold: true, fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(dados.valor_restituivel_prefeitura8, 2), alignment: 'right', bold: true, fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(dados.valor_restituivel_camara8, 2), alignment: 'right', bold: true, fontSize: 8 },
    ]);

    const total8mesesP = +dados.disponibilidade_financeira_prefeitura8 - +dados.saldo_restos_prefeitura8 - +dados.empenho_liquidado_prefeitura8 - +dados.valor_restituivel_prefeitura8;
    const total8mesesC = +dados.disponibilidade_financeira_camara8 - +dados.saldo_restos_camara8 - +dados.empenho_liquidado_camara8 - +dados.valor_restituivel_camara8;

    registros.push([
      { text: `(=) Disponibilidade Líquida em 30/04/${this.exercicio.ano}`, bold: true, fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(total8mesesP, 2), alignment: 'right', bold: true, fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(total8mesesC, 2), alignment: 'right', bold: true, fontSize: 8 },
    ]);

    registros.push([
      { text: 'Apuração por órgão no 6º bimestre do último ano de mandato', alignment: 'center', bold: true, fontSize: 8, colSpan: 3 },
      '',
      ''
    ]);

    registros.push([
      { text: `Disponibilidade Financeira em 31/12/${this.exercicio.ano}`, fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(dados.disponibilidade_financeira_prefeitura6, 2), alignment: 'right', fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(dados.disponibilidade_financeira_camara6, 2), alignment: 'right', fontSize: 8 },
    ]);

    registros.push([
      { text: `(-) Saldo de Restos a Pagar em 31/12/${this.exercicio.ano}`, bold: true, fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(dados.saldo_restos_prefeitura6, 2), alignment: 'right', bold: true, fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(dados.saldo_restos_camara6, 2), alignment: 'right', bold: true, fontSize: 7 },
    ]);

    registros.push([
      { text: '(-)  Valores Restituíveis', bold: true, fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(dados.valor_restituivel_prefeitura6, 2), alignment: 'right', bold: true, fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(dados.valor_restituivel_camara6, 2), alignment: 'right', bold: true, fontSize: 8 },
    ]);

    const total6bimestreP = +dados.disponibilidade_financeira_prefeitura6 - +dados.saldo_restos_prefeitura6 - +dados.valor_restituivel_prefeitura6;
    const total6bimestreC = +dados.disponibilidade_financeira_camara6 - +dados.saldo_restos_camara6 - +dados.valor_restituivel_camara6;

    registros.push([
      { text: `(=) (In)Disponibilidade Líquida em 31/12/${this.exercicio.ano}`, bold: true, fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(total6bimestreP, 2), alignment: 'right', bold: true, fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(total6bimestreC, 2), alignment: 'right', bold: true, fontSize: 8 },
    ]);

    const totalP = (+total6bimestreP + +total8mesesP) / +total8mesesP * 100;
    const totalC = (+total6bimestreC + +total8mesesC) / +total8mesesC * 100;

    registros.push([
      { text: `Comparação entre entre a disponibilidade liquida de 31/12/${this.exercicio.ano} e 30/04/${this.exercicio.ano} (Aumento/Diminuição em %)`, bold: true, fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(totalP, 2), alignment: 'right', bold: true, fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(totalC, 2), alignment: 'right', bold: true, fontSize: 8 },
    ]);

    registros.push([
      { text: 'Observações: Por Chefe de Poder - PM e CM', bold: true, fontSize: 8, border: [false, false, false, false], colSpan: 3 },
      '',
      ''
    ]);

    registros.push([
      { text: 'Fonte: Balancete Isolado', bold: true, fontSize: 8, border: [false, false, false, false], colSpan: 3 },
      '',
      ''
    ]);

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(50, false);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', 'auto', 'auto'],
        body: registros
      }
    },
    {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*'],
        body: assinaturas
      }
    }];
  }
}
