import { HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ContratoAnexo, BaseResourceService } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContratoAnexoService extends BaseResourceService<ContratoAnexo> {

  constructor(
    protected injector: Injector
  ) {
    super(`contratos-anexos`, injector);
  }

  public upload(entidade: any, arquivos: any, descricao: string): Observable<any> {
    const formData = new FormData();
    formData.append('entidade', JSON.stringify(entidade));
    formData.append('descricao', descricao);
    let i = 1;
    for (const item of arquivos) {
      formData.append(`arquivo${i}`, item);
      i++;
    }

    return this.http.post(`${this.login.cidade.id}/${this.api}/upload`, formData, {
      headers: new HttpHeaders(this.adicionarCriptografia({
        Authorization: this.login.token
      }))
    }).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }
}
