import { GlobalService, Login, Produto, Relatorio } from "eddydata-lib";

export class EtiquetaRpt {

    private login: Login;

    private titulo: string = '';

    constructor() {
        this.login = GlobalService.obterSessaoLogin();
    }

    public imprimir(paginas: { produtos: Produto[] }[]) {
        const conteudo = this.montarConteudo(paginas);

        console.log(JSON.stringify(conteudo));

        return Relatorio.imprimirPersonalizado(this.titulo, this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            this.montarConteudo(paginas), 'portrait', null, this.layout(), true, true, 'pdf');
    }

    public montarConteudo(paginas: { produtos: Produto[] }[]): {}[] {
        return paginas.map((p, i) => {
            const conteudo ={
                layout: 'linhas',
                table: {
                    dontBreakRows: true,
                    widths: ['*', '*'],
                    heights: 190, 
                    body: this.etiquetas(p.produtos)
                }
            };

            if(i < (paginas.length - 1))
                conteudo['pageBreak'] = 'after';

            return conteudo;
        });
    }

    private etiquetas(produtos: Produto[]): {}[] {
        const conteudo = [];

        let linha = [];
        for (const [index, produto] of produtos.entries()) {
            if (index > 0 && index % 2 === 0) {
                conteudo.push(linha);
                linha = [];
            }
            linha.push(this.etiqueta(produto));
        }
        if (linha.length > 0) {
            if (linha.length === 1)
                linha.push({ text: '', border: [false, false, false, false] });
            conteudo.push(linha);
        }

        return conteudo;
    }

    private etiqueta(produto: Produto): {} {
        const codigo = new String(produto.codigo);

        const qtdCaracteres = produto.nome.length;

        let fontSize = 28;

        if(qtdCaracteres > 33)
            fontSize = 22;
        if(qtdCaracteres > 60)
            fontSize = 18;
        if(qtdCaracteres > 95)
            fontSize = 15;

        return {
            stack: [
                { text: produto.nome, fontSize, bold: true, alignment: 'center' },
                { text: `${codigo.slice(0, codigo.length - 4)}.${codigo.slice(codigo.length - 4)}`, fontSize: 26, alignment: 'center', background: '#dee2e6' }
            ], margin: [10, 30]
        };
    }

    public layout() {
        return {
            linhas: {
                hLineWidth() {
                    return 1;
                },
                vLineWidth() {
                    return 1;
                },
                hLineColor() {
                    return 'black';
                },
                paddingLeft() {
                    return 3;
                },
                paddingRight() {
                    return 3;
                }
            }
        };
    }
}