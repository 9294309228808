import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Exercicio, FuncaoService } from 'eddydata-lib';

@Directive()
export class Anexo8RestoMde implements OnDestroy {

  protected funcaoService: FuncaoService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number,
    protected exericio: Exercicio) {
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(dados: any[]): {}[] {

    const registros: {}[] = [
      [{
        text: 'RESTOS A PAGAR INSCRITOS EM EXERCÍCIOS ANTERIORES COM DISPONIBILIDADE FINANCEIRA DE RECURSOS DE IMPOSTOS E DO FUNDEB',
        fontSize: 8, bold: true,
        margin: [5, 5, 5, 5],
        alignment: 'center',
      },
      {
        text: 'SALDO INICIAL (z)',
        alignment: 'center',
        bold: true, fontSize: 6
      },
      {
        text: 'RP LIQUIDADOS (aa)',
        alignment: 'center',
        bold: true, fontSize: 6
      },
      {
        text: `RP PAGOS (ab)`,
        alignment: 'center',
        bold: true, fontSize: 6
      },
      {
        text: `RP CANCELADOS (ac)`,
        alignment: 'center',
        bold: true, fontSize: 6
      },
      {
        text: `SALDO FINAL (ad) = (z) - (ab) - (ac)`,
        alignment: 'center',
        bold: true, fontSize: 6
      }]
    ];

    const grupos = this.funcaoService.agrupar(dados, 'grupo', ['saldo_inicial', 'liquidado', 'pagos', 'cancelado']);

    for (const grupo of grupos) {
      registros.push([
        { text: grupo.grupo, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['saldo_inicial']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['pagos']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['cancelado']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores['saldo_inicial'] - +grupo.totalizadores['pagos'] - +grupo.totalizadores['cancelado']), alignment: 'right', fontSize: 7 },
      ]);

      const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['saldo_inicial', 'liquidado', 'pagos', 'cancelado']);
      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo != '') {
          registros.push([
            { text: subgrupo.grupo, fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(+subgrupo.totalizadores['saldo_inicial']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(+subgrupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(+subgrupo.totalizadores['pagos']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(+subgrupo.totalizadores['cancelado']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(+subgrupo.totalizadores['saldo_inicial'] - +subgrupo.totalizadores['pagos'] - +subgrupo.totalizadores['cancelado']), alignment: 'right', fontSize: 7 },
          ]);

          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              registros.push([
                { text: item.nome, fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(+item.totalizadores['saldo_inicial']), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(+item.totalizadores['liquidado']), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(+item.totalizadores['pagos']), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(+item.totalizadores['cancelado']), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(+item.totalizadores['saldo_inicial'] - +item.totalizadores['pagos'] - +item.totalizadores['cancelado']), alignment: 'right', fontSize: 7 },
              ]);
            }
          }
        }
      }
    }

    return registros;

  }

  public montarQuadroCsv(dados: any[], listaExportar): any {

    const espaco = {
      titulo: '',
      coluna1: '',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
    }
    listaExportar.push(espaco)

    const primeira_linha = {
      titulo: 'RESTOS A PAGAR INSCRITOS EM EXERCÍCIOS ANTERIORES COM DISPONIBILIDADE FINANCEIRA DE RECURSOS DE IMPOSTOS E DO FUNDEB',
      coluna1: 'SALDO INICIAL (z)',
      coluna2: 'RP LIQUIDADOS (aa)',
      coluna3: 'RP PAGOS (ab)',
      coluna4: 'RP CANCELADOS (ac)',
      coluna5: 'SALDO FINAL (ad) = (z) - (ab) - (ac)',
      coluna6: '',
    }
    listaExportar.push(primeira_linha)

    const grupos = this.funcaoService.agrupar(dados, 'grupo', ['saldo_inicial', 'liquidado', 'pagos', 'cancelado']);

    for (const grupo of grupos) {
      const linha_grupo = {
        titulo: grupo.grupo,
        coluna1: this.funcaoService.convertToBrNumber(+grupo.totalizadores['saldo_inicial']),
        coluna2: this.funcaoService.convertToBrNumber(+grupo.totalizadores['liquidado']),
        coluna3: this.funcaoService.convertToBrNumber(+grupo.totalizadores['pagos']),
        coluna4: this.funcaoService.convertToBrNumber(+grupo.totalizadores['cancelado']),
        coluna5: this.funcaoService.convertToBrNumber(+grupo.totalizadores['saldo_inicial'] - +grupo.totalizadores['pagos'] - +grupo.totalizadores['cancelado']),
        coluna6: ''
      }
      listaExportar.push(linha_grupo)

      const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['saldo_inicial', 'liquidado', 'pagos', 'cancelado']);
      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo != '') {
          const linha_subgrupo = {
            titulo: subgrupo.grupo,
            coluna1: this.funcaoService.convertToBrNumber(+subgrupo.totalizadores['saldo_inicial']),
            coluna2: this.funcaoService.convertToBrNumber(+subgrupo.totalizadores['liquidado']),
            coluna3: this.funcaoService.convertToBrNumber(+subgrupo.totalizadores['pagos']),
            coluna4: this.funcaoService.convertToBrNumber(+subgrupo.totalizadores['cancelado']),
            coluna5: this.funcaoService.convertToBrNumber(+subgrupo.totalizadores['saldo_inicial'] - +subgrupo.totalizadores['pagos'] - +subgrupo.totalizadores['cancelado']),
            coluna6: ''
          }
          listaExportar.push(linha_subgrupo)

          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              const linha_subgrupo_item = {
                titulo: item.nome,
                coluna1: this.funcaoService.convertToBrNumber(+item.totalizadores['saldo_inicial']),
                coluna2: this.funcaoService.convertToBrNumber(+item.totalizadores['liquidado']),
                coluna3: this.funcaoService.convertToBrNumber(+item.totalizadores['pagos']),
                coluna4: this.funcaoService.convertToBrNumber(+item.totalizadores['cancelado']),
                coluna5: this.funcaoService.convertToBrNumber(+item.totalizadores['saldo_inicial'] - +item.totalizadores['pagos'] - +item.totalizadores['cancelado']),
                coluna6: ''
              }
              listaExportar.push(linha_subgrupo_item)
            }
          }
        }
      }
    }
  }

}