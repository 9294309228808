import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InplaceModule } from 'primeng/inplace';
import { ToastModule } from 'primeng/toast';
import { AcaoGovernoRoutingModule } from './acao-governo-routing.module';
import { AcaoGovernoLstComponent } from './acao-governo-lst/acao-governo-lst.component';
import { AcaoGovernoShwComponent } from './acao-governo-shw/acao-governo-shw.component';
import { SharedModule } from 'eddydata-lib';



@NgModule({
  declarations: [AcaoGovernoLstComponent, AcaoGovernoShwComponent],
  imports: [
    CommonModule,
    AcaoGovernoRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    ConfirmDialogModule,
    InplaceModule
  ],
  providers: [MessageService, ConfirmationService]
})
export class AcaoGovernoModule { }
