import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, FichaReceita, Page } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class FichaReceitaService extends BaseResourceService<FichaReceita> {

  constructor(
    protected injector: Injector
  ) {super(`fichas-receita`, injector);
  }

  obterFichaControle(orgao_id: number, exercicio_id: number, data1: string, data2: string, ficha1: number, ficha2: number): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/ficha-controle-receita?orgao=${orgao_id}&exercicio=${exercicio_id}&data1=${data1}&data2=${data2}&ficha1=${ficha1}&ficha2=${ficha2}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterSomaDotacao(numero: number, exercicio: number, orgao: number): Observable<number> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/somar-dotacao/${numero}/${exercicio}/${orgao}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  balancete(filtros: {}): Observable<Array<any>> {
    let parametros = '';
    if (filtros) {
      // tslint:disable-next-line: forin
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + filtros[key];
      }
    }
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/balancete${parametros}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  balanceteRecurso(filtros: {}): Observable<Array<any>> {
    let parametros = '';
    if (filtros) {
      // tslint:disable-next-line: forin
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + filtros[key];
      }
    }
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/balancete-receita-recurso${parametros}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  planilhaArrecadacao(pagina: number, limite: number, ano: number, orgaos: number): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/planilha-arrecadacao/${pagina}/${limite}?ano=${ano}&orgaos=${orgaos}`).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  planilhaPrevisao(pagina: number, limite: number, ano: number, orgaos: number, semZerados?: boolean): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/planilha-previsao/${pagina}/${limite}?ano=${ano}&orgaos=${orgaos}&semZerados=${semZerados}`).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  totalTributoArrecadado(exercicio: number, orgao: number): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/total-arrecadado-tributos/${exercicio}/${orgao}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  totalTributoFonte(exercicio: number, orgao: number): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/total-arrecadado-fonte/${exercicio}/${orgao}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterPorExercicio(orgao: number, exercicio: number, pagCorrente?: number, pagLimite?: number): Observable<Page> {
    if (!Number.isInteger(pagLimite) || !Number.isInteger(pagCorrente)) {
      pagCorrente = 1;
      pagLimite = 5000;
    }
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}?exercicio_id=${exercicio}&orgao_id=${orgao}&pagina=${pagCorrente}&limite=${pagLimite}`).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterPorNomeReceita(filtro: string, orgao: number, exercicio: number, pagCorrente?: number, pagLimite?: number): Observable<Page> {
    if (!Number.isInteger(pagLimite) || !Number.isInteger(pagCorrente)) {
      pagCorrente = 1;
      pagLimite = 5000;
    }
    return this.http.get<Page>(
      // tslint:disable-next-line: max-line-length
      `${this.login.cidade.id}/${this.api}/filtrar?relations=receita&receita.nome$like=${filtro}%&exercicio_id=${exercicio}&orgao_id=${orgao}`,
      this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterUtilmaFicha(orgao: number, exercicio: number) {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/ultima-ficha/${exercicio}/${orgao}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterPorId(id: number) {
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/${id}`).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  obterPorFicha(ficha: number, orgaoId: number, exercicioId: number, redutor?: boolean): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?ativo=true&relations=favorecido,favorecido.tipo,receita,recurso,aplicacao,aplicacao_variavel,receita.plano,contrato&numero=${ficha}&orgao_id=${orgaoId}&exercicio_id=${exercicioId}${redutor ? '&redutor=true' : ''}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  reordenarFichas(exercicioId: number, orgaoId: number) {
    return this.http.get(
      `${this.login.cidade.id}/${this.api}/reordenar-fichas/${exercicioId}/${orgaoId}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
