import { BaseResourceModel } from '../../models/base-resource.model';

// tslint:disable: variable-name
export class TipoAditivo extends BaseResourceModel {
  constructor(
    public codigo?: number,
    public nome?: string,
    public reducao?: boolean,
  ) {
    super();
  }
  static converteJson(json: any): TipoAditivo {
    return Object.assign(new TipoAditivo(), json);
  }
}
