// tslint:disable: variable-name
import { Ppa } from './ppa.model';
import { BaseResourceModel } from '../../models/base-resource.model';

export class Programa extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: string,
    public nome?: string,
    public descricao?: string,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public ppa?: Ppa
  ) {
    super();
  }

  static converteJson(json: any): Programa {
    return Object.assign(new Programa(), json);
  }
}
