import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { IndiceCorrecaoFormComponent } from './indice-correcao-form/indice-correcao-form.component';
import { IndiceCorrecaoListComponent } from './indice-correcao-list/indice-correcao-list.component';

const routes: Routes = [
  { path: '', component: IndiceCorrecaoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: IndiceCorrecaoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: IndiceCorrecaoFormComponent, canActivate: [AuthGuard] },
  // { path: ':id/visualizar', component: GrupoEstoqueViewComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IndiceCorrecaoRoutingModule { }
