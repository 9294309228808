import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { RequisicaoAutorizacaoDevolucaoFormComponent } from './requisicao-autorizacao-devolucao-form/requisicao-autorizacao-devolucao-form.component';
import { RequisicaoAutorizacaoDevolucaoListComponent } from './requisicao-autorizacao-devolucao-list/requisicao-autorizacao-devolucao-list.component';
import { RequisicaoAutorizacaoDevolucaoViewComponent } from './requisicao-autorizacao-devolucao-view/requisicao-autorizacao-devolucao-view.component';

const routes: Routes = [
  { path: '', component: RequisicaoAutorizacaoDevolucaoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: RequisicaoAutorizacaoDevolucaoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: RequisicaoAutorizacaoDevolucaoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: RequisicaoAutorizacaoDevolucaoViewComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RequisicaoAutorizacaoDevolucaoRoutingModule { }
