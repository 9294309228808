import { CpfPipe, FormatoExportacao, FuncaoService, GlobalService, Licitacao, LoginContabil, Relatorio } from "eddydata-lib";

export class MapaPrecoRpt {

    private funcaoService: FuncaoService;

    private login: LoginContabil;

    private data: Date;
    private formato: FormatoExportacao;
    private descricao: boolean = false;

    constructor() {
        this.funcaoService = new FuncaoService();
        this.login = GlobalService.obterSessaoLogin();

    }

    public imprimir(licitacao: Licitacao, data: Date, titulo: string, subtitulo, formato: FormatoExportacao, descricao: boolean = true) {
        this.data = data;
        this.formato = formato;
        this.descricao = descricao;

        Relatorio.imprimirPersonalizado(subtitulo, this.login.usuario.nome, this.login.usuario.sobrenome, titulo, this.login.brasao,
            this.montarConteudo(licitacao), 'landscape', titulo, this.layout(), false, false, formato);
    }



    private montarConteudo(licitacao: Licitacao): {}[] {
        return [this.conteudoMapaPreco(licitacao)];
    }

    private conteudoMapaPreco(licitacao: Licitacao) {
        return this.conteudoTabela(licitacao)
            .concat(this.favorecidos(licitacao));
    }

    private conteudoTabela(licitacao: Licitacao): {}[] {

        const quantidade = Math.ceil(licitacao.proponentes.length / 5);

        return Array.from(Array(quantidade).keys()).map((_, idx) => {
            return {
                layout: 'linhas',
                table: {
                    dontBreakRows: true,
                    headerRows: 2,
                    widths: [40, 50, '*']
                        .concat(licitacao.proponentes.slice(idx * 5, (idx * 5) + 5).map((f) => 80)),
                    body: this.cabecalhoFavorecido(licitacao, idx).concat(this.itensCotacoes(licitacao, idx))
                }, pageBreak: 'after'
            };
        });
    }


    private cabecalhoFavorecido(licitacao: Licitacao, base: number): {}[] {
        const qtFavorecido = licitacao.proponentes.slice(base * 5, (base * 5) + 5).length;

        let cabecalho1: {}[] = [
            { text: `ITEM`, fontSize: 10, border: [true, true, false, true], bold: true, alignment: 'center', rowSpan: 2, margin: [0, 8, 0, 0] },
            { text: `CÓDIGO`, fontSize: 10, border: [true, true, false, true], bold: true, alignment: 'center', rowSpan: 2, margin: [0, 8, 0, 0] },
            { text: `DESCRIÇÃO`, fontSize: 10, border: [true, true, false, true], bold: true, alignment: 'center', rowSpan: 2, margin: [0, 8, 0, 0] },
            { text: 'CONCORRENTES', fontSize: 8, border: [true, true, true, true], alignment: 'center', bold: true, colSpan: qtFavorecido },
        ];
        cabecalho1 = cabecalho1.concat(Array.from(Array(qtFavorecido - 1).keys()).map((f) => ''));

        let cabecalho2: {}[] = Array.from(Array(3).keys()).map((_) => '');
        cabecalho2 = cabecalho2
            .concat(licitacao.proponentes.sort((a, b) => a.id - b.id).slice(base * 5, (base * 5) + 5)
                .map((f) => { return { text: `${this.funcaoService.abreviarStr(f.favorecido.nome, 35)}`, alignment: 'center', fontSize: 7, bold: true } }));

        return [cabecalho1, cabecalho2];
    }

    private itensCotacoes(licitacao: Licitacao, base: number): {}[] {
        const conteudo = [];

        const qtFavorecido = licitacao.proponentes.slice(base * 5, (base * 5) + 5).length;

        for (const item of licitacao.itens) {
            const propostas = item.propostas.sort((a, b) => a.proponente.id - b.proponente.id);

            let linha: {}[] = [
                { text: `${item.ordem}`, fontSize: 8, border: [true, true, true, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: `${item.produto_unidade.produto.codigo}`, fontSize: 8, border: [true, true, true, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: `${this.descricao ? item.produto_unidade.produto.descricao : item.produto_unidade.produto.nome}`, fontSize: 8, border: [true, true, true, true], margin: [0, 5, 0, 5] },
            ];

            if(this.formato !== 'docx')
                linha = linha
                    .concat(licitacao.proponentes.sort((a, b) => a.id - b.id).slice(base * 5, (base * 5) + 5).map((p) => {
                        const proposta = propostas.find((p2) => p2.proponente.favorecido.id === p.favorecido.id);
                        if (!proposta)
                            return { text: '' };
                        return [
                            { columns: [{ text: 'MARCA', bold: true, alignment: 'left', fontSize:6 }, { text: proposta.marca, alignment: 'right', fontSize: 6 }], margin: [0, 2, 0, 2] },
                            { columns: [{ text: 'VL. UNI.', bold: true, alignment: 'left', fontSize: 6 }, { text: this.funcaoService.convertToBrNumber(+proposta?.valor_unitario, 4), alignment: 'right', fontSize: 6 }], margin: [0, 2, 0, 2] },
                            { columns: [{ text: 'VL. TOTAL', bold: true, alignment: 'left', fontSize: 6 }, { text: this.funcaoService.convertToBrNumber(+proposta?.valor_unitario * +item?.quantidade, 4), alignment: 'right', fontSize: 6 }], margin: [0, 2, 0, 2] }
                        ];
                    }));
            else
                linha = linha
                    .concat(licitacao.proponentes.sort((a, b) => a.id - b.id).slice(base * 5, (base * 5) + 5).map((p) => {
                        const proposta = propostas.find((p2) => p2.proponente.favorecido.id === p.favorecido.id);
                        if (!proposta)
                            return { text: '' };
                        return { text: `MARCA: ${proposta.marca}, VL. UNI.: ${this.funcaoService.convertToBrNumber(+proposta?.valor_unitario, 4)}, VL. TOTAL: ${this.funcaoService.convertToBrNumber(+proposta?.valor_unitario * +item?.quantidade, 4)}`, alignment: 'right', fontSize: 6 , margin: [0, 2, 0, 2] };
                    }));

            conteudo.push(linha);
        }

        let rodape: {}[] = [
            { text: this.funcaoService.formatarDataExtenso(this.data, this.login.cidade.nome), fontSize: 10, border: [false, false, false, false], alignment: 'right', colSpan: (4 + qtFavorecido - 1), margin: [0, 8, 0, 0] }, '','',''
        ];
        rodape = rodape.concat(Array.from(Array(qtFavorecido - 1).keys()).map((f) => ''));
        conteudo.push(rodape);

        return conteudo;
    }

    private favorecidos(licitacao: Licitacao) {
        const conteudo: {}[] = [];

        conteudo.push([
            { text: 'LISTAGEM DOS PROPONENTES', fontSize: 11, border: [false, false, false, false], margin: [0, 5, 0, 5] , bold: true, alignment: 'center', colSpan: 2 },
            '',
        ]);

        conteudo.push([
            { text: `CPNJ/CPF`, fontSize: 10, border: [true, true, false, true], bold: true, alignment: 'center' },
            { text: `NOME`, fontSize: 10, border: [true, true, true, true], bold: true, alignment: 'left' },
        ]);

        for (const proponente of licitacao.proponentes) {
            conteudo.push([
                { text: new CpfPipe().transform(proponente.favorecido.cpf_cnpj), fontSize: 8, border: [false, false, false, false], alignment: 'center', margin: [0, 5, 0, 0] },
                { text: proponente.favorecido.nome, fontSize: 8, border: [false, false, false, false], alignment: 'left', margin: [0, 5, 0, 0] }
            ]);
        }

        return [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                headerRows: 1,
                widths: [100, '*'],
                body: conteudo
            }
        }];
    }

    public layout() {
        return {
            linhas: {
                hLineWidth() {
                    return 1;
                },
                vLineWidth() {
                    return 1;
                },
                hLineColor() {
                    return 'black';
                },
                paddingLeft() {
                    return 3;
                },
                paddingRight() {
                    return 3;
                }
            }
        };
    }
}