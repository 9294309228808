// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ContaBancaria } from './conta-bancaria.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { ContaBancariaSaldo } from './conta-bancaria-saldo.model';
import { TransferenciaRetencaoItem } from './transferencia-retencao-item.model';

export class TransferenciaRetencao extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_transferencia?: Date,
    public mes?: number,
    public impresso?: boolean,
    public historico?: string,
    public conta_origem?: ContaBancaria,
    public conta_saldo_destino?: ContaBancariaSaldo,
    public exercicio?: Exercicio,
    public orgao?: Orgao,
    public usuario_cadastro?: Usuario,
    public itens?: TransferenciaRetencaoItem[],
    public exportado_febraban?: boolean,
    public exportado_obn?: boolean,
  ) {
    super();
  }

  static converteJson(json: any): TransferenciaRetencao {
    return Object.assign(new TransferenciaRetencao(), json);
  }
}
