import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AnexoLoaService } from '../service/anexo-loa.service';
import { FuncaoService, LoginContabil, Exercicio, Relatorio, GlobalService } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class Sumario implements OnDestroy {
  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLoaService) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // tslint:disable: max-line-length
  public montarRelatorio(exercicio: Exercicio, orgaos: number[], orgaosNome: string[]) {
    this.anexoServico.obterSumario(exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {

        Relatorio.imprimirPersonalizado(
          'SUMÁRIO GERAL DA RECEITA POR FONTES E DA DESPESA POR FUNÇÕES DE GOVERNO'
          , this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          this.conteudo(dados[0], dados[1], exercicio, orgaosNome),
          'portrait', 'SUMARIO GERAL',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          }, undefined, undefined, undefined, orgaosNome.length === 1 ? orgaosNome[0] : 'Consolidado');
      });
  }

  private conteudo(dados1: any[], dados2: any[], exercicio: Exercicio, orgaosNome: string[]): {}[] {

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `EXERCÍCIO ${exercicio.ano}`,
        alignment: 'center', bold: true, colSpan: 4, border: [true, true, true, false]
      }, '', '', ''],
      [{
        text: 'Lei nº 4.320/64, Art. 2º, §1º, II',
        alignment: 'center',
        bold: true,
        colSpan: 4, border: [true, false, true, false]
      }, '', '', ''],
      [{
        text: 'RECEITA',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'TOTAL',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'DESPESA',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'TOTAL',
        alignment: 'center',
        bold: true, fontSize: 8
      }]
    ];

    // monta o agrupamento do relatório
    const grupos1 = this.funcaoService.agrupar(dados1, 'tipo',
      ['previsao']);
    const grupos2 = this.funcaoService.agrupar(dados2, 'tipo',
      ['previsao']);
    const totalizador = new Array<any>();
    const totalGrupos = grupos1.length + grupos2.length;
    let total1 = 0;
    let total2 = 0;
    for (let index = 0; index < totalGrupos; index++) {
      const item1 = grupos1[index];
      const item2 = grupos2[index];
      registros.push([
        {
          text: item1 ? item1.grupo : '', bold: true, fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item1 ? this.funcaoService.convertToBrNumber(item1.totalizadores[`previsao`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item2 ? item2.grupo : '', bold: true, fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item2 ? this.funcaoService.convertToBrNumber(item2.totalizadores[`previsao`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, false, true, false]
        }
      ]);
      // lista os registros do relatorio
      const totalRegistro = (item1 ? item1.registros.length : 0) + (item2 ? item2.registros.length : 0);
      for (let idx = 0; idx < totalRegistro; idx++) {
        const element1 = item1 ? item1.registros[idx] : null;
        const element2 = item2 ? item2.registros[idx] : null;
        registros.push([
          {
            text: element1 ? `${element1 ? element1.nome : ''}` : '', fontSize: 8, border: [true, false, false, false]
          },
          {
            text: element1 ? this.funcaoService.convertToBrNumber(element1.previsao) : '', alignment: 'right',
            fontSize: 8, border: [true, false, false, false]
          },
          { text: element2 ? `${element2.nome}` : '', fontSize: 8, border: [true, false, false, false] },
          {
            text: element2 ? this.funcaoService.convertToBrNumber(element2.previsao) : '', alignment: 'right',
            fontSize: 8, border: [true, false, true, false]
          }
        ]);

      }
      total1 += item1 ? +item1.totalizadores['previsao'] : 0;
      total2 += item2 ? +item2.totalizadores['previsao'] : 0;
      totalizador.push({
        col3: item1 ? item1.totalizadores['previsao'] : 0,
        col6: item2 ? item2.totalizadores['previsao'] : 0,
      });
    }
    registros.push([{
      text: '', colSpan: 4, border: [true, true, true, false]
    }, '', '', '']);

    registros.push([{
      text: 'TOTAL', border: [true, false, true, true], fontSize: 8, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total1), alignment: 'right',
      border: [true, false, true, true], fontSize: 8, bold: true
    },
    {
      text: 'TOTAL', border: [true, false, false, true], fontSize: 8, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total2), alignment: 'right',
      border: [true, false, true, true], fontSize: 8, bold: true
    }]);
    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 'auto', '*', 'auto'],
        body: registros
      }
    }, {text: '\n\n'}, {text: 'Entidade\n', fontSize: 7}, {text: orgaosNome.join('\n'), fontSize: 6}];
  }


}
