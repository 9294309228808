import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ProponenteService } from 'administrativo-lib';
import { Favorecido, FavorecidoService, FuncaoService, Licitacao, Login, Proponente } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'app-proponente',
  templateUrl: './proponente.component.html'
})
export class ProponenteComponent implements OnInit, OnDestroy, OnChanges {
  @Input() entidade: Licitacao;
  @Input() login: Login;
  @Input() edicao: boolean = false;
  @Input() credenciamento: boolean = false;
  @Input() edicaoContratoExcluido: boolean = false;
  @Input() verificarBotoesProponente;
  @Output() callbackRemover: EventEmitter<void> = new EventEmitter();

  public itemAtual: Proponente;
  protected unsubscribe: Subject<void> = new Subject();
  public mostrarBtnIncluirProp: boolean = false;

  constructor(
    public funcaoService: FuncaoService,
    protected confirmationService: ConfirmationService,
    protected proponenteService: ProponenteService,
    protected favorecidoService: FavorecidoService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public editar(item: any) {
    this.itemAtual = Object.assign({}, item)
  }

  public adicionar() {
    if (!this.mostrarBtnIncluirProp) return;
    this.itemAtual = new Proponente();
    this.itemAtual.favorecido = new Favorecido();
    this.itemAtual.situacao = 'HABILITADO';

    $('#dialogFavorecidoCadastro').modal('show');
  }

  public salvar(item: Proponente) {
    if (!this.mostrarBtnIncluirProp) return;
    if (item) {
      if (!this.entidade.proponentes) {
        this.entidade.proponentes = [];
      }
      if (+item.favorecido.tipo.tce !== 1 && +item.favorecido.tipo.tce !== 2 && +item.favorecido.tipo.tce !== 10) {
        toastr.warning('Verifique a espécie no cadastro de fornecedor');
        return; 
      }
      
      this.atualizarOrdem();

      const itm = this.entidade.proponentes.find((i) => i.favorecido.cpf_cnpj === item.favorecido.cpf_cnpj)
      if (itm) {
        itm.favorecido = item.favorecido;
        itm.epp_me = item.epp_me;
        itm.situacao = item.situacao;
        itm.motivo = item.motivo;
      } else {
        if (this.entidade.proponentes.length === 0) {
          item.ordem = 1;
        } else {
          item.ordem = (this.entidade.proponentes.sort((a, b) => { return a.ordem - b.ordem })[this.entidade.proponentes.length - 1].ordem) + 1;
        }
        this.entidade.proponentes.push(item);
        this.ordenarItens();
      }
    }
    this.itemAtual = undefined
  }

  public remover(item: Proponente, index: number) {
    if (!this.mostrarBtnIncluirProp) return;
    if (item.id) {
      this.confirmationService.confirm({
        message: 'Deseja realmente remover o proponente? Todas as propostas vinculadas a este proponente serão removidas.',
        header: 'Exclusão',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Confirmar',
        rejectLabel: 'Cancelar',
        accept: () => {
          this.proponenteService.remover(item.id).pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
              toastr.info('Registro removido com sucesso!', 'Exclusão');
              this.entidade.proponentes.splice(index, 1);
              this.atualizarOrdem();
              setTimeout(() => this.callbackRemover.emit(), 1000);
            }, (err) => toastr.error(err.error.payload));
        }
      });
    } else {
      this.entidade.proponentes.splice(index, 1);
      this.atualizarOrdem();
      setTimeout(() => this.callbackRemover.emit(), 1000);
    }
  }

  private ordenarItens() {
    this.entidade.proponentes.sort((a, b) => { return a.ordem - b.ordem });
  }

  public async ngOnChanges(changes: SimpleChanges) {
    if (changes['entidade'] || changes['dataAtualizacao']) {
      setTimeout(() => this.verificarAlteracoes(), 1000);
    }
    if (changes['verificarBotoesProponente']) {
      this.verificarBotoes()
    }
  }

  private atualizarOrdem() {
    if (!this.mostrarBtnIncluirProp) return;
    this.ordenarItens();
    let ordem = 0;
    this.entidade.proponentes.forEach((m) => {
      m.ordem = ++ordem;
    })
  }

  public verificarAlteracoes() {
    if (this.entidade.contratos?.length > 0 && !this.entidade.credenciamento) {
      // this.edicao = false;
      this.verificarBotoes()
    }
  }

  public alterarRepresentante(favorecido: Favorecido) {
    if (favorecido) {
      favorecido.editavel = true;
      favorecido['responsavel_temporario'] = favorecido.responsavel;
    }
  }

  public salvarRepresentante(favorecido: Favorecido) {
    if (favorecido) {
      favorecido.editavel = false;
      if (!favorecido.responsavel || favorecido.responsavel === '') {
        favorecido.responsavel = favorecido['responsavel_temporario'];
      } else {
        this.favorecidoService.atualizarRepresentante(favorecido.id, favorecido.responsavel).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.success('Representante alterado com sucesso!');
          }, error => {
            toastr.error(error.error.payload);
            throw error;
          });
      }
    }
  }

  public async verificarBotoes() {
    let contratosExcluidos: number = 0;

    this.entidade?.contratos.filter(c => {
      if (c.excluido) {
        contratosExcluidos += 1;
      }
    })

    if ((this.entidade.contratos?.length == 0 || (this.entidade.contratos?.length == contratosExcluidos)) || this.credenciamento) {
      this.mostrarBtnIncluirProp = true;
    } else {
      this.mostrarBtnIncluirProp = false;
    }
  }
}
