// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from './orgao.model';

export class TransparenciaParametro extends BaseResourceModel {
  constructor(
    public id?: number,
    public esic?: boolean,
    public painel_receita?: boolean,
    public receita?: boolean,
    public receita_extra?: boolean,
    public despesa_extra?: boolean,
    public receita_recurso?: boolean,
    public despesa_recurso?: boolean,
    public empenho?: boolean,
    public empenho_extra?: boolean,
    public empenho_resto?: boolean,
    public liquidacao?: boolean,
    public liquidacao_resto?: boolean,
    public pagamento?: boolean,
    public pagamento_extra?: boolean,
    public pagamento_resto?: boolean,
    public contrato?: boolean,
    public licitacao?: boolean,
    public repasse?: boolean,
    public convenio?: boolean,
    public equipamento?: boolean,
    public viagem?: boolean,
    public ata?: boolean,
    public remuneracao?: boolean,
    public transf_orgao?: boolean,
    public transf_federal?: boolean,
    public transf_estadual?: boolean,
    public arrecadacao_imposto?: boolean,
    public planejamento?: boolean,
    public conta_publica?: boolean,
    public conta_tipo?: string,
    public url?: string,
    public plan_tipo?: string,
    public plan_url?: string,
    public cedido?: boolean,
    public afastado?: boolean,
    public cargo?: boolean,
    public mostrar_relatorio_loa?: boolean,
    public covid_receita?: boolean,
    public covid_despesa?: boolean,
    public operacao_credito?: boolean,
    public acumular_desconto_holerite?: boolean,
    public detalhar_holerite?: boolean,
    public detalhar_convenio?: boolean,
    public exportar_xls?: boolean,
    public exportar_xml?: boolean,
    public exportar_pdf?: boolean,
    public transf_privada?: boolean,
    public esic_url?: string,
    public data_atualizacao?: Date,
    public orgao?: Orgao,
    public outras_informacoes?: boolean,
    public outras_informacoes_url?: string,
    public transparencia_externa?: boolean,
    public redes_sociais?: boolean,
    public url_remuneracao?: string,
    public url_cargo?: string,
    public url_servidores_cedidos?: string,
    public url_servidores_afastados?: string,
    public grafico_despesa?: boolean,
    public penalidade_fornecedor?: boolean,
    public aviso_url?: string,
    public mostrar_campo_aviso?: boolean,
    public descricao_url?: string
  ) {
    super();
  }

  static converteJson(json: any): TransparenciaParametro {
    return Object.assign(new TransparenciaParametro(), json);
  }
}
