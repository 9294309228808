import { HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ConvenioStorage } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConvenioStorageService extends BaseResourceService<ConvenioStorage> {

  constructor(
    protected injector: Injector
  ) {
    super(`convenio-storages`, injector); 
  }

    public upload(arquivos: any[], orgao: number, convenio: number, descricao?: string): Observable<ConvenioStorage[]> {
    const formData = new FormData();
    for (let arquivo of arquivos) {
      formData.append('files', arquivo);
    }
    if (descricao) {
      formData.append('descricao', descricao);
    }

    const headers = this.httpOptions();
    headers.headers = headers.headers.delete('Content-Type');

    return this.http.post<ConvenioStorage[]>(`${this.login.cidade.id}/${this.api}/upload-convenio/${orgao}/${convenio}`,
      formData, headers)
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public removerTodos(convenio: number): Observable<any> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/remover-todos-convenio/${convenio}`, {}, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err)));
  }

  public visuTransparenciaTodos(convenio: number, visualizacao: boolean): Observable<any> {
    return this.http.post(`${this.login.cidade.id}/${this.api}/visualizar-todos-convenio/${convenio}/${visualizacao}`, {}, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err)));
  }

  public baixarTodos(convenio: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/download-todos-convenio/${convenio}`, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public downloadZip(): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/download-zip-convenio`, {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders(this.adicionarCriptografia({
        Authorization: this.login.token,
      }))
    }).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }
}
