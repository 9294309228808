import { Directive, OnDestroy } from '@angular/core';
import { Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, Orgao, OrgaoAssinaturaService, ProgressoService, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseResourceNotaExplicativa } from '../base-resource-nota-explicativa';
import { BalancoService } from '../service/balanco.service';
import { NotaExplicativaService } from '../service/nota-explicativa.service';

@Directive()
export class Anexo12Balanco extends BaseResourceNotaExplicativa implements OnDestroy {

  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();
  private total_receita_inicial: number = 0;
  private total_receita_atualizado: number = 0;
  private total_receita_realizado: number = 0;

  constructor(
    protected balancoServico: BalancoService,
    protected exercicio: Exercicio,
    protected notaService: NotaExplicativaService,
    protected progressoService: ProgressoService,
    protected assinaturaService: OrgaoAssinaturaService
  ) {
    super('B12', notaService, assinaturaService)
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(exercicio: Exercicio, orgaos: number[], listaOrgaos?: Orgao[], mes?: number, formato?: FormatoExportacao) {
    formato = formato ?? 'pdf';
    this.balancoServico.obterBalancoOrcamentario(exercicio.id, orgaos, mes) // this.exercicio.id
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async idTransacao => {
        this.progressoService.show(idTransacao, async retorno => {
          const dados = retorno;
          const orgao = orgaos.length === 1 ? listaOrgaos.filter(o => o.id === orgaos[0])[0] : listaOrgaos.filter(o => o.id === 1)[0];
          const consolidado = orgaos.length === 1 ? orgao.nome : 'Consolidado';
          let orgaoNomes: string[] = [];
          for (const o of listaOrgaos) {
            if (orgaos.some(orgao => orgao === o.id)) {
              orgaoNomes.push(`${o.codigo} - ${o.nome}`);
            }
          }
          if (formato === 'pdf') {
            Relatorio.imprimirPersonalizado(
              `ANEXO 12 - BALANÇO ORÇAMENTÁRIO`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
              this.cabecalhoRelatorio(orgao, exercicio, mes)
                .concat(this.conteudoReceita(dados[0], dados[1], mes))
                .concat(this.conteudoDespesa(dados[1], dados[0]))
                .concat(await this.conteudoNotaExplicativa())
                .concat(await this.conteudoAssinatura(this.login.orgao, orgaoNomes, 38)),
              'portrait', 'ANEXO 12 - BALANÇO ORÇAMENTÁRIO',
              {
                linhas: {
                  hLineWidth() {
                    return 1;
                  },
                  vLineWidth() {
                    return 1;
                  },
                  hLineColor() {
                    return 'black';
                  },
                  paddingLeft() {
                    return 3;
                  },
                  paddingRight() {
                    return 3;
                  }
                }
              }, false, false, 'pdf', `Unidade Gestora: ${consolidado.toUpperCase()}`
            );
          } else if (formato === 'csv') {
            this.exportarCsv(dados[0], dados[1]);
          }
        });
      });
  }

  private cabecalhoRelatorio(orgao: Orgao, exercicio: Exercicio, mes?: number): {}[] {
    const registros = [
      [
        { text: `Município:`, alignment: 'left', fontSize: 7 },
        { text: orgao.cidade?.nome ? orgao.cidade.nome : this.login.cidade.nome, alignment: 'center', fontSize: 7 },
        { text: `Exercício:`, alignment: 'left', fontSize: 7 },
        { text: exercicio.ano, alignment: 'center', fontSize: 7 }
      ], [
        { text: `Poder:`, alignment: 'left', fontSize: 7 },
        { text: orgao.especie === 'C' ? 'PODER LEGISLATIVO' : 'PODER EXECUTIVO', alignment: 'center', fontSize: 7 },
        { text: `Mês:`, alignment: 'left', fontSize: 7 },
        { text: mes ? this.globalService.obterMes(+mes) : 'ANUAL', alignment: 'center', fontSize: 7 }
      ], [
        { text: `Órgão:`, alignment: 'left', fontSize: 7, border: [true, true, true, false] },
        { text: orgao.nome, alignment: 'center', fontSize: 7, border: [true, true, true, false] },
        { text: `Acumulado/Mensal:`, alignment: 'left', fontSize: 7, border: [true, true, true, false] },
        { text: mes ? 'MENSAL' : 'ACUMULADO', alignment: 'center', fontSize: 7, border: [true, true, true, false] }
      ], [
        { text: 'Em R$ 1,00', bold: true, alignment: 'right', colSpan: 4, border: [false, true, false, false], fontSize: 7 },
        '',
        '',
        '',
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*', '*', '*', '*'],
        body: registros
      }
    }];
  }

  private conteudoReceita(dados: any[], dadosDespesa: any[], mes?: number): {}[] {
    // loop para pegar o total liquidado e verificar se houve defict ou superavit
    const gruposDespesa = this.funcaoService.agrupar(dadosDespesa, 'tipo',
      ['dotacao', 'adicionais', 'empenhado', 'liquidado', 'pago']);
    let totalEmpenhado = 0;
    let totalDotacao = 0;
    let totalAtualizado = 0;
    for (const grupo of gruposDespesa) {
      totalDotacao += +grupo.totalizadores['dotacao'];
      totalAtualizado += +grupo.totalizadores['adicionais'];
      totalEmpenhado = totalEmpenhado + grupo.totalizadores['empenhado'];
    }

    // monta o cabecalho
    const registros: {}[] = [
      // [
      //   { text: `Municipio:`, alignment: 'left', fontSize: 8 },
      //   { text: orgao.cidade?.nome ? orgao.cidade.nome : this.login.cidade.nome, alignment: 'center', fontSize: 8 },
      //   { text: `Exercício`, alignment: 'left', fontSize: 8, colSpan: 2 }, '',
      //   { text: exercicio.ano, alignment: 'center', fontSize: 8 }
      // ], [
      //   { text: `Poder`, alignment: 'left', fontSize: 8 },
      //   { text: orgao.especie === 'C' ? 'PODER LEGISLATIVO' : 'PODER EXECUTIVO', alignment: 'center', fontSize: 8 },
      //   { text: `Mês`, alignment: 'left', fontSize: 8, colSpan: 2 }, '',
      //   { text: mes ? this.globalService.obterMes(+mes) : '', alignment: 'center', fontSize: 8 }
      // ], [
      //   { text: `Orgão:`, alignment: 'left', fontSize: 8, border: [true, true, true, false] },
      //   { text: orgao.nome, alignment: 'center', fontSize: 8, border: [true, true, true, false] },
      //   { text: `Acumulado/Mensal:`, alignment: 'left', fontSize: 8, border: [true, true, true, false], colSpan: 2 }, '',
      //   { text: `Acumulado`, alignment: 'center', fontSize: 8, border: [true, true, true, false] }
      // ],
      // [{
      //   text: `${(mes ? 'EXERCÍCIO: ' + this.exercicio.ano + '' + mes : 'EXERCÍCIO: ' + this.exercicio.ano)}`,
      //   alignment: 'center', bold: true, colSpan: 5, fontSize: 13
      // }, '', '', '', ''],
      [{
        text: 'RECEITAS',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'PREVISÃO INICIAL',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'PREVISÃO ATUALIZADA (a)',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'RECEITAS REALIZADAS (b)',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'SALDO (b-a)',
        alignment: 'center',
        bold: true, fontSize: 7
      }]
    ];

    // monta o agrupamento do relatório
    const grupos = this.funcaoService.agrupar(dados, 'tipo',
      ['previsao', 'atualizado', 'previsto_total', 'arrecadado']);
    const totalizador = new Array<any>();
    let sub2 = 0;
    let sub3 = 0;
    let sub5 = 0;
    let sub6 = 0;

    for (const item of grupos) {
      if (item.grupo !== 'REFINANCIAMENTO ( II )' && item.grupo !== 'SALDOS DE EXERCÍCIOS ANTERIORES (UTILIZADOS PARA CRÉDITOS ADICIONAIS)') {
        registros.push([
          {
            text: item.grupo, bold: true, fontSize: 6
          },
          {
            text: this.funcaoService.convertToBrNumber(
              item.totalizadores[`previsao`], undefined, true), alignment: 'right', bold: true, fontSize: 6
          },
          {
            text: this.funcaoService.convertToBrNumber(
              +item.totalizadores[`atualizado`], undefined, true), alignment: 'right', bold: true, fontSize: 6
          },
          {
            text: this.funcaoService.convertToBrNumber(
              item.totalizadores[`arrecadado`], undefined, true), alignment: 'right', bold: true, fontSize: 6
          },
          {
            text: this.funcaoService.convertToBrNumber(
              +item.totalizadores[`arrecadado`] - +item.totalizadores[`atualizado`], undefined, true), alignment: 'right', bold: true, fontSize: 6
          }
        ]);

        sub2 += +item.totalizadores[`previsao`];
        sub3 += +item.totalizadores[`atualizado`]
        sub5 += +item.totalizadores[`arrecadado`];
        sub6 += +item.totalizadores[`arrecadado`] - +item.totalizadores[`atualizado`];

        const titulos = this.funcaoService.agrupar(item.registros, 'titulo',
          ['previsao', 'atualizado', 'previsto_total', 'arrecadado']);

        for (const titulo of titulos) {
          registros.push([
            {
              text: titulo.grupo, bold: true, fontSize: 6
            },
            {
              text: this.funcaoService.convertToBrNumber(
                titulo.totalizadores[`previsao`], undefined, true), alignment: 'right', bold: true, fontSize: 6
            },
            {
              text: this.funcaoService.convertToBrNumber(
                +titulo.totalizadores[`atualizado`], undefined, true), alignment: 'right', bold: true, fontSize: 6
            },
            {
              text: this.funcaoService.convertToBrNumber(
                titulo.totalizadores[`arrecadado`], undefined, true), alignment: 'right', bold: true, fontSize: 6
            },
            {
              text: this.funcaoService.convertToBrNumber(
                +titulo.totalizadores[`arrecadado`] - +titulo.totalizadores[`atualizado`], undefined, true), alignment: 'right', bold: true, fontSize: 6
            }
          ]);

          // lista os registros do relatorio
          for (const registro of titulo.registros) {
            if (registro.tamanho > 2) {
              registros.push([
                {
                  text: `\t\t${registro.nome}`, fontSize: 6
                },
                { text: this.funcaoService.convertToBrNumber(registro.previsao, undefined, true), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(registro.atualizado, undefined, true), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(registro.arrecadado, undefined, true), alignment: 'right', fontSize: 6 },
                { text: this.funcaoService.convertToBrNumber(+registro.arrecadado - registro.atualizado, undefined, true), alignment: 'right', fontSize: 6 }
              ]);
            }
          }
        }
      }
      totalizador.push({
        nome: item.grupo,
        col2: item.totalizadores['previsao'],
        col3: item.totalizadores['atualizado'],
        col5: item.totalizadores['arrecadado'],
        col6: +item.totalizadores['arrecadado'] + item.totalizadores['atualizado'],
      });
    }

    // Monta os totalizadores do relatorio
    let op2 = 0;
    let op3 = 0;
    let op5 = 0;
    let op6 = 0;

    registros.push([{
      text: 'SUBTOTAL DAS RECEITAS ( I )', bold: true,
      alignment: 'left', fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(sub2, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(sub3, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(sub5, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(sub6, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }]);
    let saldosAnteriores;
    for (const grupo of grupos) {
      if (grupo.grupo === 'SALDOS DE EXERCÍCIOS ANTERIORES (UTILIZADOS PARA CRÉDITOS ADICIONAIS)') saldosAnteriores = grupo;
      if (grupo.grupo === 'REFINANCIAMENTO ( II )') {
        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 6
          },
          {
            text: this.funcaoService.convertToBrNumber(
              grupo.totalizadores[`previsao`], undefined, true), alignment: 'right', bold: true, fontSize: 6
          },
          {
            text: this.funcaoService.convertToBrNumber(
              +grupo.totalizadores[`previsao`] + +grupo.totalizadores[`atualizado`], undefined, true), alignment: 'right', bold: true, fontSize: 6
          },
          {
            text: this.funcaoService.convertToBrNumber(
              grupo.totalizadores[`arrecadado`], undefined, true), alignment: 'right', bold: true, fontSize: 6
          },
          {
            text: this.funcaoService.convertToBrNumber(
              +grupo.totalizadores[`arrecadado`] - (+grupo.totalizadores[`previsao`] + +grupo.totalizadores[`atualizado`]), undefined, true), alignment: 'right', bold: true, fontSize: 6
          }
        ]);
        op2 += +grupo.totalizadores[`previsao`];
        op3 += +grupo.totalizadores[`previsao`] + +grupo.totalizadores[`atualizado`];
        op5 += +grupo.totalizadores[`arrecadado`];
        op6 += +grupo.totalizadores[`arrecadado`] - (+grupo.totalizadores[`previsao`] + +grupo.totalizadores[`atualizado`]);
        for (const registro of grupo.registros) {
          registros.push([
            {
              text: `\t${registro.nome}`, fontSize: 6
            },
            { text: this.funcaoService.convertToBrNumber(registro.previsao, undefined, true), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(+registro.previsao + +registro.atualizado, undefined, true), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(registro.arrecadado, undefined, true), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(+registro.arrecadado - (+registro.previsao + +registro.atualizado), undefined, true), alignment: 'right', fontSize: 6 }
          ]);
        }
      }
    }

    registros.push([{
      text: 'SUBTOTAL COM REFINANCIAMENTO ( III ) = (I + II)', bold: true,
      alignment: 'left', fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(+sub2 + +op2, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(+sub3 + +op3, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(+sub5 + +op5, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(+sub6 + +op6, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }]);

    registros.push([{
      text: 'DÉFICIT ( IV )', bold: true,
      alignment: 'left', fontSize: 6
    }, {
      text: totalDotacao > (sub2 + op2) ? this.funcaoService.convertToBrNumber(totalDotacao - (+sub2 + +op2), undefined, true) : '',
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: totalAtualizado > (sub3 + op3) ? this.funcaoService.convertToBrNumber(totalAtualizado - (+sub3 + +op3), undefined, true) : '',
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: totalEmpenhado > (sub5 + op5) ? this.funcaoService.convertToBrNumber(totalEmpenhado - (+sub5 + +op5), undefined, true) : '',
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: ''
    }]);

    this.total_receita_inicial = totalDotacao > (sub2 + op2) ? (totalDotacao - (sub2 + op2)) + (sub2 + op2) : sub2 + op2;
    this.total_receita_atualizado = totalAtualizado > (sub3 + op3) ? (totalAtualizado - (sub3 + op3)) + (sub3 + op3) : sub3 + op3;
    this.total_receita_realizado = totalEmpenhado > (sub5 + op5) ? (totalEmpenhado - (sub5 + op5)) + (sub5 + op5) : sub5 + op5;
    registros.push([{
      text: 'TOTAL ( V ) = ( III + IV )', bold: true,
      alignment: 'left', fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(this.total_receita_inicial, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(this.total_receita_atualizado, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(this.total_receita_realizado, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(sub6 + op6, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }]);

    if (saldosAnteriores) {
      registros.push([
        {
          text: saldosAnteriores.grupo, bold: true, fontSize: 6
        },
        {
          text: this.funcaoService.convertToBrNumber(
            saldosAnteriores.totalizadores[`previsao`], undefined, true), alignment: 'right', bold: true, fontSize: 6
        },
        {
          text: '0,00', alignment: 'right', bold: true, fontSize: 6
        },
        {
          text: '0,00', alignment: 'right', bold: true, fontSize: 6
        },
        {
          text: '0,00', alignment: 'right', bold: true, fontSize: 6
        }
      ]);
      for (const registro of saldosAnteriores.registros) {
        registros.push([
          {
            text: `\t${registro.nome}`, fontSize: 6
          },
          { text: this.funcaoService.convertToBrNumber(registro.previsao, undefined, true), alignment: 'right', fontSize: 6 },
          { text: '', alignment: 'right', fontSize: 6 },
          { text: '', alignment: 'right', fontSize: 6 },
          { text: '', alignment: 'right', fontSize: 6 }
        ]);
      }
    }

    return [{
      layout: 'linhas',
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }];
  }

  private conteudoDespesa(dados: any[], dadosReceita: any[], mes?: number): {}[] {
    // loop para pegar o total arrecadado e verificar se houve defict ou superavit
    const gruposReceita = this.funcaoService.agrupar(dadosReceita, 'tipo',
      ['previsao', 'atualizado', 'previsto_total', 'arrecadado']);
    let totalArrecadado = 0;
    for (const item of gruposReceita) {
      totalArrecadado = item.grupo !== '(-) DEDUÇÕES DA RECEITA' ?
        totalArrecadado + item.totalizadores['arrecadado'] :
        totalArrecadado - item.totalizadores['arrecadado'];
    }

    // monta o cabecalho
    const registros: {}[] = [
      [
        {
          text: ' ',
          colSpan: 7,
          border: [false, false, false, false]
        }, '', '', '', '', '', ''
      ],
      // [
      //   { text: `Municipio:`, alignment: 'left', fontSize: 8, colSpan: 2 }, '',
      //   { text: orgao.cidade?.nome ? orgao.cidade.nome : this.login.cidade.nome, alignment: 'center', fontSize: 8 },
      //   { text: `Exercício`, alignment: 'left', fontSize: 8, colSpan: 2 }, '',
      //   { text: exercicio.ano, alignment: 'center', fontSize: 8, colSpan: 2 }, ''
      // ],
      // [
      //   { text: `Poder`, alignment: 'left', fontSize: 8, colSpan: 2 }, '',
      //   { text: orgao.especie === 'C' ? 'PODER LEGISLATIVO' : 'PODER EXECUTIVO', alignment: 'center', fontSize: 8 },
      //   { text: `Mês`, alignment: 'left', fontSize: 8, colSpan: 2 }, '',
      //   { text: mes ? this.globalService.obterMes(+mes) : '', alignment: 'center', fontSize: 8, colSpan: 2 }, ''
      // ],
      // [
      //   { text: `Orgão:`, alignment: 'left', fontSize: 8, border: [true, true, true, false], colSpan: 2 }, '',
      //   { text: orgao.nome, alignment: 'center', fontSize: 8, border: [true, true, true, false] },
      //   { text: `Acumulado/Mensal:`, alignment: 'left', fontSize: 8, border: [true, true, true, false], colSpan: 2 }, '',
      //   { text: `ACUMEN`, alignment: 'center', fontSize: 8, border: [true, true, true, false], colSpan: 2 }, ''
      // ],
      [{
        text: 'DESPESAS',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }, {
        text: 'DOTAÇÃO INICIAL',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }, {
        text: 'DOTAÇÃO ATUALIZADA',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }, {
        text: 'DESPESAS EMPENHADAS',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }, {
        text: 'DESPESAS LIQUIDADAS',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }, {
        text: 'DESPESAS PAGAS',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }, {
        text: 'SALDO DA DOTAÇÃO',
        alignment: 'center',
        fontSize: 7,
        bold: true,
      }]
    ];

    const grupos = this.funcaoService.agrupar(dados, 'tipo',
      ['dotacao', 'adicionais', 'empenhado', 'liquidado', 'pago']);

    const totalizador = new Array<any>();
    for (const grupo of grupos) {
      // desconsidera esses grupos porque serão impressos somente nos totalizadores
      if (grupo.grupo !== 'AMORTIZAÇÃO DA DÍVIDA - REFINANCIAMENTOS ( IX )' && grupo.grupo !== 'RESERVA DE CONTINGÊNCIA'
        && grupo.grupo !== 'DESPESAS INTRAORÇAMENTÁRIAS') {
        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 6
          },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`dotacao`], undefined, true), alignment: 'right', bold: true, fontSize: 6 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`adicionais`], undefined, true), alignment: 'right', bold: true, fontSize: 6 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`empenhado`], undefined, true), alignment: 'right', bold: true, fontSize: 6 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`liquidado`], undefined, true), alignment: 'right', bold: true, fontSize: 6 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`pago`], undefined, true), alignment: 'right', bold: true, fontSize: 6 },
          {
            text: this.funcaoService.convertToBrNumber(
              +grupo.totalizadores[`adicionais`] - grupo.totalizadores[`empenhado`], undefined, true),
            alignment: 'right',
            bold: true, fontSize: 6
          }
        ]);

        // lista dos registros do grupo
        for (const registro of grupo.registros) {
          registros.push([
            {
              text: `${registro.nome}`, fontSize: 6
            },
            { text: this.funcaoService.convertToBrNumber(registro.dotacao, undefined, true), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(registro.adicionais, undefined, true), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(registro.empenhado, undefined, true), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(registro.liquidado, undefined, true), alignment: 'right', fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(registro.pago, undefined, true), alignment: 'right', fontSize: 6 },
            {
              text: this.funcaoService.convertToBrNumber((
                +registro.adicionais) - +registro.empenhado, undefined, true), alignment: 'right', fontSize: 6
            }
          ]);
        }
      }
      totalizador.push({
        nome: grupo.grupo,
        col2: grupo.totalizadores['dotacao'],
        col3: grupo.totalizadores['adicionais'],
        col4: +grupo.totalizadores[`dotacao`] + +grupo.totalizadores[`adicionais`],
        col5: grupo.totalizadores['empenhado'],
        col6: grupo.totalizadores['liquidado'],
        col7: grupo.totalizadores['pago'],
        col8: +grupo.totalizadores[`adicionais`] - +grupo.totalizadores[`empenhado`],
        col9: grupo.totalizadores[`empenhado`] - +grupo.totalizadores[`liquidado`],
        col10: grupo.totalizadores[`liquidado`] - +grupo.totalizadores[`pago`],
      });
    }

    let sub2 = 0; let sub3 = 0; let sub4 = 0; let sub5 = 0; let sub6 = 0; let sub7 = 0; let sub8 = 0; let sub9 = 0; let sub10 = 0;
    let op2 = 0; let op3 = 0; let op4 = 0; let op5 = 0; let op6 = 0; let op7 = 0; let op8 = 0; let op9 = 0; let op10 = 0;
    let intra2 = 0; let intra3 = 0; let intra4 = 0; let intra5 = 0;
    let intra6 = 0; let intra7 = 0; let intra8 = 0; let intra9 = 0; let intra10 = 0;
    let res2 = 0;
    let res3 = 0;
    let res4 = 0;
    for (const val of totalizador) {
      if (val.nome === 'AMORTIZAÇÃO DA DÍVIDA - REFINANCIAMENTOS ( IX )') {
        op2 = op2 + val.col2;
        op3 = op3 + val.col3;
        op4 = op4 + val.col4;
        op5 = op5 + val.col5;
        op6 = op6 + val.col6;
        op7 = op7 + val.col7;
        op8 = op8 + val.col8;
        op9 = op9 + val.col9;
        op10 = op10 + val.col10;
      } else if (val.nome === 'DESPESAS INTRAORÇAMENTÁRIAS') {
        // intra2 = intra2 + val.col2;
        // intra3 = intra3 + val.col3;
        // intra4 = intra4 + val.col4;
        // intra5 = intra5 + val.col5;
        // intra6 = intra6 + val.col6;
        // intra7 = intra7 + val.col7;
        // intra8 = intra8 + val.col8;
        // intra9 = intra9 + val.col9;
        // intra10 = intra10 + val.col10;
      } else if (val.nome === 'RESERVA DE CONTINGÊNCIA') {
        res2 = res2 + val.col2;
        res3 = res3 + val.col3;
        res4 = res4 + val.col4;
      } else {
        sub2 = sub2 + val.col2;
        sub3 = sub3 + val.col3;
        sub4 = sub4 + val.col4;
        sub5 = sub5 + val.col5;
        sub6 = sub6 + val.col6;
        sub7 = sub7 + val.col7;
        sub8 = sub8 + val.col8;
        sub9 = sub9 + val.col9;
        sub10 = sub10 + val.col10;
      }
    }
    // totais da reserva de contigencia
    registros.push([{
      text: 'RESERVA DE CONTINGÊNCIA', bold: true, fontSize: 6,
      alignment: 'left',
    }, {
      text: this.funcaoService.convertToBrNumber(res2, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(res3, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: '0,00',
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: '0,00',
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: '0,00',
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(res3, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }]);

    registros.push([{
      text: 'SUBTOTAL DAS DESPESAS ( VI )', bold: true, fontSize: 6,
      alignment: 'left',
    }, {
      text: this.funcaoService.convertToBrNumber(sub2 + res2 + intra2, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(sub3 + res3 + intra3, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(sub5 + intra5, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(sub6 + intra6, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(sub7 + intra7, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(sub8 + res3 + intra8, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }]);

    registros.push([{
      text: 'AMORTIZAÇÃO DA DÍVIDA - REFINANCIAMENTOS ( VII )', bold: true,
      alignment: 'left', fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(op2, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(op3, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(op5, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(op6, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(op7, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(op8, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }]);

    const amortizacao = grupos.find(item => item.grupo === 'AMORTIZAÇÃO DA DÍVIDA - REFINANCIAMENTOS ( IX )')
    const grupo = this.funcaoService.agrupar(amortizacao.registros, 'nome', ['dotacao', 'adicionais', 'empenhado', 'liquidado', 'pago']);

    for (const item of grupo) {
      registros.push([{
        text: item.grupo, bold: true,
        alignment: 'left', fontSize: 6
      }, {
        text: this.funcaoService.convertToBrNumber(item.totalizadores['dotacao'], undefined, true),
        alignment: 'right', bold: true, fontSize: 6
      }, {
        text: this.funcaoService.convertToBrNumber(item.totalizadores['adicionais'], undefined, true),
        alignment: 'right', bold: true, fontSize: 6
      }, {
        text: this.funcaoService.convertToBrNumber(item.totalizadores['empenhado'], undefined, true),
        alignment: 'right', bold: true, fontSize: 6
      }, {
        text: this.funcaoService.convertToBrNumber(item.totalizadores['liquidado'], undefined, true),
        alignment: 'right', bold: true, fontSize: 6
      }, {
        text: this.funcaoService.convertToBrNumber(item.totalizadores['pago'], undefined, true),
        alignment: 'right', bold: true, fontSize: 6
      }, {
        text: this.funcaoService.convertToBrNumber(item.totalizadores['adicionais'] - item.totalizadores['empenhado'], undefined, true),
        alignment: 'right', bold: true, fontSize: 6
      }]);
    }

    registros.push([{
      text: 'SUBTOTAL COM REFINANCIAMENTO (VIII) = (VI + VII)', bold: true,
      alignment: 'left', fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(sub2 + op2 + res2 + intra2, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(sub3 + op3 + res3 + intra3, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(sub5 + op5 + intra5, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(sub6 + op6 + intra6, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(sub7 + op7 + intra7, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(sub8 + op8 + res3 + intra8, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }]);

    registros.push([{
      text: 'SUPERÁVIT ( IX )', bold: true,
      alignment: 'left', fontSize: 6
    }, {
      text: (sub2 + op2 + res2 + intra2) < this.total_receita_inicial ? this.funcaoService.convertToBrNumber(this.total_receita_inicial - (sub2 + op2 + res2 + intra2), undefined, true) : '',
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: (sub3 + op3 + res3 + intra3) < this.total_receita_atualizado ? this.funcaoService.convertToBrNumber(this.total_receita_atualizado - (sub3 + op3 + res3 + intra3), undefined, true) : '',
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: (sub5 + op5 + intra5) < this.total_receita_realizado ? this.funcaoService.convertToBrNumber(this.total_receita_realizado - (sub5 + op5 + intra5), undefined, true) : '',
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: ''
    }, {
      text: +totalArrecadado > (+sub4 + +op4) ? this.funcaoService.convertToBrNumber(+totalArrecadado - (+sub4 + +op4), undefined, true) : '',
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: '',
    }]);

    registros.push([{
      text: 'TOTAL ( X ) = ( VIII + IX )', bold: true,
      alignment: 'left', fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber((sub2 + op2 + res2 + intra2) + ((sub2 + op2 + res2 + intra2) < this.total_receita_inicial ? this.total_receita_inicial - (sub2 + op2 + res2 + intra2) : 0), undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber((sub3 + op3 + res3 + intra3) + ((sub3 + op3 + res3 + intra3) < this.total_receita_atualizado ? this.total_receita_atualizado - (sub3 + op3 + res3 + intra3) : 0), undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber((sub5 + op5 + intra5) + ((sub5 + op5 + intra5) < this.total_receita_realizado ? this.total_receita_realizado - (sub5 + op5 + intra5) : 0), undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(sub6 + op6 + intra6, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: this.funcaoService.convertToBrNumber(sub7 + op7 + intra7, undefined, true),
      alignment: 'right', bold: true, fontSize: 6
    }, {
      text: '',
      alignment: 'right', bold: true, fontSize: 6
    }]);

    return [{
      layout: 'linhas',
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }];
  }

  private exportarCsv(dadosReceita: any[], dadosDespesa: any[]) {

    // monta o cabecalho receita
    const registros: {}[][] = [
      [
        { text: 'RECEITAS' },
        { text: 'PREVISÃO INICIAL' },
        { text: 'PREVISÃO ATUALIZADA (a)' },
        { text: 'RECEITAS REALIZADAS (b)' },
        { text: 'SALDO (b-a)' }
      ]
    ];

    let totalEmpenhado = 0;
    let totalDotacao = 0;
    let totalAtualizado = 0;
    const gruposDespesa = this.funcaoService.agrupar(dadosDespesa, 'tipo', ['dotacao', 'adicionais', 'empenhado', 'liquidado', 'pago']);
    for (const grupo of gruposDespesa) {
      totalDotacao += +grupo.totalizadores['dotacao'];
      totalAtualizado += +grupo.totalizadores['adicionais'];
      totalEmpenhado = totalEmpenhado + grupo.totalizadores['empenhado'];
    }

    let sub2r = 0;
    let sub3r = 0;
    let sub5r = 0;
    let sub6r = 0;
    const totalizadorRec = new Array<any>();
    const gruposRec = this.funcaoService.agrupar(dadosReceita, 'tipo', ['previsao', 'atualizado', 'previsto_total', 'arrecadado']);
    for (const item of gruposRec) {
      if (item.grupo !== 'REFINANCIAMENTO ( II )' && item.grupo !== 'SALDOS DE EXERCÍCIOS ANTERIORES (UTILIZADOS PARA CRÉDITOS ADICIONAIS)') {
        registros.push([
          { text: item.grupo, bold: true, fontSize: 6 },
          { text: this.funcaoService.convertToBrNumber(item.totalizadores[`previsao`], undefined, true) },
          { text: this.funcaoService.convertToBrNumber(+item.totalizadores[`atualizado`], undefined, true) },
          { text: this.funcaoService.convertToBrNumber(item.totalizadores[`arrecadado`], undefined, true) },
          { text: this.funcaoService.convertToBrNumber(+item.totalizadores[`arrecadado`] - +item.totalizadores[`atualizado`], undefined, true) }
        ]);

        sub2r += +item.totalizadores[`previsao`];
        sub3r += +item.totalizadores[`atualizado`];
        sub5r += +item.totalizadores[`arrecadado`];
        sub6r += +item.totalizadores[`arrecadado`] - +item.totalizadores[`atualizado`];
        const titulos = this.funcaoService.agrupar(item.registros, 'titulo', ['previsao', 'atualizado', 'previsto_total', 'arrecadado']);
        for (const titulo of titulos) {
          registros.push([
            { text: titulo.grupo },
            { text: this.funcaoService.convertToBrNumber( titulo.totalizadores[`previsao`], undefined, true) },
            { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores[`atualizado`], undefined, true) },
            { text: this.funcaoService.convertToBrNumber(titulo.totalizadores[`arrecadado`], undefined, true) },
            { text: this.funcaoService.convertToBrNumber(+titulo.totalizadores[`arrecadado`] - +titulo.totalizadores[`atualizado`], undefined, true) }
          ]);

          for (const registro of titulo.registros) {
            if (registro.tamanho > 2) {
              registros.push([
                { text: registro.nome },
                { text: this.funcaoService.convertToBrNumber(registro.previsao, undefined, true) },
                { text: this.funcaoService.convertToBrNumber(registro.atualizado, undefined, true) },
                { text: this.funcaoService.convertToBrNumber(registro.arrecadado, undefined, true) },
                { text: this.funcaoService.convertToBrNumber(+registro.arrecadado - registro.atualizado, undefined, true) }
              ]);
            }
          }
        }
      }

      totalizadorRec.push({
        nome: item.grupo,
        col2: item.totalizadores['previsao'],
        col3: item.totalizadores['atualizado'],
        col5: item.totalizadores['arrecadado'],
        col6: +item.totalizadores['arrecadado'] + item.totalizadores['atualizado'],
      });
    }

    let op2r = 0;
    let op3r = 0;
    let op5r = 0;
    let op6r = 0;

    registros.push([
      { text: 'SUBTOTAL DAS RECEITAS ( I )' },
      { text: this.funcaoService.convertToBrNumber(sub2r, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(sub3r, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(sub5r, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(sub6r, undefined, true) }
    ]);

    let saldosAnteriores;
    for (const grupo of gruposRec) {
      if (grupo.grupo === 'SALDOS DE EXERCÍCIOS ANTERIORES (UTILIZADOS PARA CRÉDITOS ADICIONAIS)') saldosAnteriores = grupo;
      if (grupo.grupo === 'REFINANCIAMENTO ( II )') {
        registros.push([
          { text: grupo.grupo },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`previsao`], undefined, true) },
          { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`previsao`] + +grupo.totalizadores[`atualizado`], undefined, true) },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`arrecadado`], undefined, true) },
          { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`arrecadado`] - (+grupo.totalizadores[`previsao`] + +grupo.totalizadores[`atualizado`]), undefined, true) }
        ]);

        op2r += +grupo.totalizadores[`previsao`];
        op3r += +grupo.totalizadores[`previsao`] + +grupo.totalizadores[`atualizado`];
        op5r += +grupo.totalizadores[`arrecadado`];
        op6r += +grupo.totalizadores[`arrecadado`] - (+grupo.totalizadores[`previsao`] + +grupo.totalizadores[`atualizado`]);
        for (const registro of grupo.registros) {
          registros.push([
            { text: registro.nome },
            { text: this.funcaoService.convertToBrNumber(registro.previsao, undefined, true) },
            { text: this.funcaoService.convertToBrNumber(+registro.previsao + +registro.atualizado, undefined, true) },
            { text: this.funcaoService.convertToBrNumber(registro.arrecadado, undefined, true) },
            { text: this.funcaoService.convertToBrNumber(+registro.arrecadado - (+registro.previsao + +registro.atualizado), undefined, true) }
          ]);
        }
      }
    }

    registros.push([
      { text: 'SUBTOTAL COM REFINANCIAMENTO ( III ) = (I + II)' },
      { text: this.funcaoService.convertToBrNumber(+sub2r + +op2r, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(+sub3r + +op3r, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(+sub5r + +op5r, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(+sub6r + +op6r, undefined, true) }
    ]);
    registros.push([
      { text: 'DÉFICIT ( IV )' },
      { text: totalDotacao > (sub2r + op2r) ? this.funcaoService.convertToBrNumber(totalDotacao - (+sub2r + +op2r), undefined, true) : '', },
      { text: totalAtualizado > (sub3r + op3r) ? this.funcaoService.convertToBrNumber(totalAtualizado - (+sub3r + +op3r), undefined, true) : '', },
      { text: totalEmpenhado > (sub5r + op5r) ? this.funcaoService.convertToBrNumber(totalEmpenhado - (+sub5r + +op5r), undefined, true) : '', }
    ]);

    this.total_receita_inicial = totalDotacao > (sub2r + op2r) ? (totalDotacao - (sub2r + op2r)) + (sub2r + op2r) : sub2r + op2r;
    this.total_receita_atualizado = totalAtualizado > (sub3r + op3r) ? (totalAtualizado - (sub3r + op3r)) + (sub3r + op3r) : sub3r + op3r;
    this.total_receita_realizado = totalEmpenhado > (sub5r + op5r) ? (totalEmpenhado - (sub5r + op5r)) + (sub5r + op5r) : sub5r + op5r;
    registros.push([
      { text: 'TOTAL ( V ) = ( III + IV )' }, 
      { text: this.funcaoService.convertToBrNumber(this.total_receita_inicial, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(this.total_receita_atualizado, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(this.total_receita_realizado, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(sub6r + op6r, undefined, true) }
    ]);

    if (saldosAnteriores) {
      registros.push([
        { text: saldosAnteriores.grupo },
        { text: this.funcaoService.convertToBrNumber(saldosAnteriores.totalizadores[`previsao`], undefined, true) },
        { text: 0 }, { text: 0 }, { text: 0 }
      ]);

      for (const registro of saldosAnteriores.registros) {
        registros.push([
          { text: registro.nome },
          { text: this.funcaoService.convertToBrNumber(registro.previsao, undefined, true) }
        ]);
      }
    }

    let totalArrecadado = 0;
    const gruposReceita = this.funcaoService.agrupar(dadosReceita, 'tipo', ['previsao', 'atualizado', 'previsto_total', 'arrecadado']);
    for (const item of gruposReceita) {
      totalArrecadado = item.grupo !== '(-) DEDUÇÕES DA RECEITA' ? totalArrecadado + item.totalizadores['arrecadado'] : totalArrecadado - item.totalizadores['arrecadado'];
    }

    // montar o cabeçalho despesa
    registros.push([{ text: '' }]);
    registros.push([
      { text: 'DESPESAS' },
      { text: 'DOTAÇÃO INICIAL' },
      { text: 'DOTAÇÃO ATUALIZADA' },
      { text: 'DESPESAS EMPENHADAS' },
      { text: 'DESPESAS LIQUIDADAS' },
      { text: 'DESPESAS PAGAS' },
      { text: 'SALDO DA DOTAÇÃO' }
    ]);

    const grupos = this.funcaoService.agrupar(dadosDespesa, 'tipo', ['dotacao', 'adicionais', 'empenhado', 'liquidado', 'pago']);
    const totalizadorDes = new Array<any>();
    for (const grupo of grupos) {
      if (grupo.grupo !== 'AMORTIZAÇÃO DA DÍVIDA - REFINANCIAMENTOS ( IX )' && grupo.grupo !== 'RESERVA DE CONTINGÊNCIA' && grupo.grupo !== 'DESPESAS INTRAORÇAMENTÁRIAS') {
        registros.push([
          { text: grupo.grupo },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`dotacao`], undefined, true) },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`adicionais`], undefined, true) },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`empenhado`], undefined, true) },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`liquidado`], undefined, true) },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores[`pago`], undefined, true) },
          { text: this.funcaoService.convertToBrNumber(+grupo.totalizadores[`adicionais`] - grupo.totalizadores[`empenhado`], undefined, true) }
        ]);

        for (const registro of grupo.registros) {
          registros.push([
            { text: registro.nome },
            { text: this.funcaoService.convertToBrNumber(registro.dotacao, undefined, true) },
            { text: this.funcaoService.convertToBrNumber(registro.adicionais, undefined, true) },
            { text: this.funcaoService.convertToBrNumber(registro.empenhado, undefined, true) },
            { text: this.funcaoService.convertToBrNumber(registro.liquidado, undefined, true) },
            { text: this.funcaoService.convertToBrNumber(registro.pago, undefined, true) },
            { text: this.funcaoService.convertToBrNumber((+registro.adicionais) - +registro.empenhado, undefined, true) }
          ]);
        }
      }

      totalizadorDes.push({
        nome: grupo.grupo,
        col2: +grupo.totalizadores['dotacao'],
        col3: +grupo.totalizadores['adicionais'],
        col4: +grupo.totalizadores[`dotacao`] + +grupo.totalizadores[`adicionais`],
        col5: +grupo.totalizadores['empenhado'],
        col6: +grupo.totalizadores['liquidado'],
        col7: +grupo.totalizadores['pago'],
        col8: +grupo.totalizadores[`adicionais`] - +grupo.totalizadores[`empenhado`],
        col9: +grupo.totalizadores[`empenhado`] - +grupo.totalizadores[`liquidado`],
        col10: +grupo.totalizadores[`liquidado`] - +grupo.totalizadores[`pago`],
      });
    }

    let sub2d = 0; let sub3d = 0; let sub4d = 0; let sub5d = 0; let sub6d = 0; let sub7d = 0; let sub8d = 0; let sub9d = 0; let sub10d = 0;
    let op2d = 0; let op3d = 0; let op4d = 0; let op5d = 0; let op6d = 0; let op7d = 0; let op8d = 0; let op9d = 0; let op10d = 0;
    let intra2d = 0; let intra3d = 0; let intra4d = 0; let intra5d = 0; let intra6d = 0; let intra7d = 0; let intra8d = 0; let intra9d = 0; let intra10d = 0;
    let res2d = 0; let res3d = 0; let res4d = 0;
    for (const val of totalizadorDes) {
      if (val.nome === 'AMORTIZAÇÃO DA DÍVIDA - REFINANCIAMENTOS ( IX )') {
        op2d = op2d + +val.col2;
        op3d = op3d + +val.col3;
        op4d = op4d + +val.col4;
        op5d = op5d + +val.col5;
        op6d = op6d + +val.col6;
        op7d = op7d + +val.col7;
        op8d = op8d + +val.col8;
        op9d = op9d + +val.col9;
        op10d = op10d + +val.col10;
      } else if (val.nome === 'DESPESAS INTRAORÇAMENTÁRIAS') {
      } else if (val.nome === 'RESERVA DE CONTINGÊNCIA') {
        res2d = res2d + +val.col2;
        res3d = res3d + +val.col3;
        res4d = res4d + +val.col4;
      } else {
        sub2d = sub2d + +val.col2;
        sub3d = sub3d + +val.col3;
        sub4d = sub4d + +val.col4;
        sub5d = sub5d + +val.col5;
        sub6d = sub6d + +val.col6;
        sub7d = sub7d + +val.col7;
        sub8d = sub8d + +val.col8;
        sub9d = sub9d + +val.col9;
        sub10d = sub10d + +val.col10;
      }
    }

    registros.push([
      { text: 'RESERVA DE CONTINGÊNCIA' },
      { text: this.funcaoService.convertToBrNumber(res2d, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(res3d, undefined, true) },
      { text: 0 }, { text: 0 }, { text: 0 },
      { text: this.funcaoService.convertToBrNumber(res3d, undefined, true) }
    ]);
    registros.push([
      { text: 'SUBTOTAL DAS DESPESAS ( VI )' },
      { text: this.funcaoService.convertToBrNumber(sub2d + res2d + intra2d, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(sub3d + res3d + intra3d, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(sub5d + intra5d, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(sub6d + intra6d, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(sub7d + intra7d, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(sub8d + res3d + intra8d, undefined, true) }
    ]);
    registros.push([
      { text: 'AMORTIZAÇÃO DA DÍVIDA - REFINANCIAMENTOS ( VII )' },
      { text: this.funcaoService.convertToBrNumber(op2d, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(op3d, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(op5d, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(op6d, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(op7d, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(op8d, undefined, true) }
    ]);

    const amortizacao = grupos.find(item => item.grupo === 'AMORTIZAÇÃO DA DÍVIDA - REFINANCIAMENTOS ( IX )');
    const grupo = this.funcaoService.agrupar(amortizacao.registros, 'nome', ['dotacao', 'adicionais', 'empenhado', 'liquidado', 'pago']);
    for (const item of grupo) {
      registros.push([
        { text: item.grupo },
        { text: this.funcaoService.convertToBrNumber(item.totalizadores['dotacao'], undefined, true) },
        { text: this.funcaoService.convertToBrNumber(item.totalizadores['adicionais'], undefined, true) },
        { text: this.funcaoService.convertToBrNumber(item.totalizadores['empenhado'], undefined, true) },
        { text: this.funcaoService.convertToBrNumber(item.totalizadores['liquidado'], undefined, true) },
        { text: this.funcaoService.convertToBrNumber(item.totalizadores['pago'], undefined, true) },
        { text: this.funcaoService.convertToBrNumber(item.totalizadores['adicionais'] - item.totalizadores['empenhado'], undefined, true) }
      ]);
    }

    registros.push([
      { text: 'SUBTOTAL COM REFINANCIAMENTO (VIII) = (VI + VII)' },
      { text: this.funcaoService.convertToBrNumber(sub2d + op2d + res2d + intra2d, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(sub3d + op3d + res3d + intra3d, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(sub5d + op5d + intra5d, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(sub6d + op6d + intra6d, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(sub7d + op7d + intra7d, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(sub8d + op8d + res3d + intra8d, undefined, true) }
    ]);
    registros.push([
      { text: 'SUPERÁVIT ( IX )' },
      { text: (sub2d + op2d + res2d + intra2d) < this.total_receita_inicial ? this.funcaoService.convertToBrNumber(this.total_receita_inicial - (sub2d + op2d + res2d + intra2d), undefined, true) : '' },
      { text: (sub3d + op3d + res3d + intra3d) < this.total_receita_atualizado ? this.funcaoService.convertToBrNumber(this.total_receita_atualizado - (sub3d + op3d + res3d + intra3d), undefined, true) : '' },
      { text: (sub5d + op5d + intra5d) < this.total_receita_realizado ? this.funcaoService.convertToBrNumber(this.total_receita_realizado - (sub5d + op5d + intra5d), undefined, true) : '' }, { text: '' },
      { text: +totalArrecadado > (+sub4d + +op4d) ? this.funcaoService.convertToBrNumber(+totalArrecadado - (+sub4d + +op4d), undefined, true) : '' }
    ]);
    registros.push([
      { text: 'TOTAL ( X ) = ( VIII + IX )' },
      { text: this.funcaoService.convertToBrNumber((sub2d + op2d + res2d + intra2d) + ((sub2d + op2d + res2d + intra2d) < this.total_receita_inicial ? this.total_receita_inicial - (sub2d + op2d + res2d + intra2d) : 0), undefined, true) },
      { text: this.funcaoService.convertToBrNumber((sub3d + op3d + res3d + intra3d) + ((sub3d + op3d + res3d + intra3d) < this.total_receita_atualizado ? this.total_receita_atualizado - (sub3d + op3d + res3d + intra3d) : 0), undefined, true) },
      { text: this.funcaoService.convertToBrNumber((sub5d + op5d + intra5d) + ((sub5d + op5d + intra5d) < this.total_receita_realizado ? this.total_receita_realizado - (sub5d + op5d + intra5d) : 0), undefined, true) },
      { text: this.funcaoService.convertToBrNumber(sub6d + op6d + intra6d, undefined, true) },
      { text: this.funcaoService.convertToBrNumber(sub7d + op7d + intra7d, undefined, true) }
    ]);

    dadosReceita = null;
    dadosDespesa = null;
    let csv = '';
    for (let i=0; i<registros.length; i++) {
      const linha = registros[i];
      if (i > 0) csv += '\n';
      for (let x=0; x<linha.length; x++) {
        if (x > 0) csv += ';';
        csv += String(linha[x]['text']);
      }
    }

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + csv));
    element.setAttribute("download", `ANEXO 12 - BALANÇO ORÇAMENTÁRIO.csv`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
