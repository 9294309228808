import { Injectable, Injector } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService } from '../../models/services/base-resource.service';
import { Pessoa } from '../../entidade/comum/pessoa.model';

@Injectable({
  providedIn: 'root'
})
export class PessoaService extends BaseResourceService<Pessoa> {

  constructor(
    protected injector: Injector
  ) {
    super(`pessoas`, injector);
  }

  obterUtilmoCartao(orgao: number, exercicio: number) {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/ultimo-cartao/${exercicio}/${orgao}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
