import { Injectable, Injector } from '@angular/core';
import { ScheduleLog } from '../../entidade/comum/schedule-log.model';
import { BaseResourceService } from '../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleLogService extends BaseResourceService<ScheduleLog> {

  constructor(
    protected injector: Injector
  ) {
    super('schedule-logs', injector);
  }
}
