import { ConservacaoPatrimonio, SituacaoPatrimonio } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { SetorLocalizacao } from '../almoxarifado/setor-localizacao.model';
import { Setor } from '../almoxarifado/setor.model';
import { Usuario } from '../comum/usuario.model';
import { InventarioSetor } from './inventario-setor.model';
import { Tombamento } from './tombamento.model';

export class InventarioTombo extends BaseResourceModel {
  constructor(
    public id?: number,
    public localizado?: boolean,
    public origem?: 'INVENTARIO' | 'TRANSF_PENDENTE' | 'TRANSFERENCIA' | 'INCORPORACAO',
    public conservacao_origem?: ConservacaoPatrimonio,
    public conservacao_atual?: ConservacaoPatrimonio,
    public situacao_origem?: SituacaoPatrimonio,
    public situacao_atual?: SituacaoPatrimonio,
    public situacao_justificativa?: string,
    public tombamento?: Tombamento,
    public inventario_setor?: InventarioSetor,
    public localizacao?: SetorLocalizacao,
    public usuario?: Usuario,
    public manutencao_destino?: Setor
  ) {
    super();
  }

  static converteJson(json: any): InventarioTombo {
    return Object.assign(new InventarioTombo(), json);
  }
}
