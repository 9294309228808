import { Component, ElementRef, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { SetorLocalizacao } from '../../../entidade/almoxarifado/setor-localizacao.model';
import { Setor } from '../../../entidade/almoxarifado/setor.model';
import { Login } from '../../../entidade/login/login';
import { FuncaoService } from '../../../util/funcao.service';
import { SetorLocalizacaoService } from '../service/setor-localizacao.service';

@Component({
  selector: 'app-setor-localizacao',
  templateUrl: './setor-localizacao.component.html'
})
export class SetorLocalizacaoComponent implements OnChanges, OnDestroy {

  protected unsubscribe: Subject<void> = new Subject();

  @Input() lista: Array<any>;
  @Input() setor: Setor;
  @Input() login: Login;

  @ViewChild('btnAdicionar') public btnAdicionar: ElementRef;

  constructor(
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected setorLocalizacaoService: SetorLocalizacaoService
  ) { }

  ngOnChanges(changes) {
    if (changes.setor) {
      this.setorLocalizacaoService.obterPorLocalizacao(changes.setor.currentValue.id)
        .subscribe(localizacoes => this.lista = Array.from(localizacoes as SetorLocalizacao[]));
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public adicionar() {
    if (!this.lista) {
      this.lista = [];
    }
    this.lista.unshift({ editavel: true });
  }

  public salvar(item: SetorLocalizacao) {
    try {
      if (!item.localizacao) {
        throw new Error('Informe a localização!');
      }

      item['visivel'] = true;

      item.setor = this.setor;

      if (item.id) {
        this.setorLocalizacaoService.atualizar(item).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro alterado com sucesso!');
          },
            error => toastr.error('Falha ao alterar registro!'));
      } else {
        this.setorLocalizacaoService.inserir(item).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro inserido com sucesso!');
            if (res) {
              item.id = res.id;
            }
          },
            error => toastr.error('Falha ao inserir registro!'));
      }

      item['editavel'] = false;

      this.btnAdicionar.nativeElement.disabled = false;

    } catch (e) {
      toastr.error(e.message);
      throw e;
    }
  }

  public editar(item: SetorLocalizacao) {
    try {
      item['editavel'] = true;

      if (item.id) {
        this.setorLocalizacaoService.atualizar(item).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
          },
            error => toastr.error('Falha ao alterar registro!'));
      }

      this.btnAdicionar.nativeElement.disabled = false;

    } catch (e) {
      toastr.error(e.message);
      throw e;
    }
  }

  public cancelar(item: SetorLocalizacao) {
    item['editavel'] = false;
  }

  public remover(item: SetorLocalizacao) {
    if (item.id) {
      this.setorLocalizacaoService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          toastr.info('Registro removido com sucesso!');
          this.lista.splice(this.lista.findIndex((i) => { return i === item }), 1);
        },
          error => toastr.error("Localização vinculada a um bem!")
        );
    }
    this.btnAdicionar.nativeElement.disabled = false;
  }
}
