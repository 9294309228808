import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, FuncionarioAfastamento } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class FuncionarioAfastamentoService extends BaseResourceService<FuncionarioAfastamento> {

  constructor(
    protected injector: Injector
  ) {
    super(`funcionarios-afastados`, injector);
  }
}
