import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { InventarioSetor } from './inventario-setor.model';

export class Inventario extends BaseResourceModel {
  constructor(
    public id?: number,
    public status?: 'PENDENTE' | 'INICIADO' | 'CONCLUIDO',
    public descricao?: string,
    public data_inicio?: Date,
    public data_termino?: Date,
    public data_finalizacao?: Date,
    public usuario?: Usuario,
    public usuario_finalizacao?: Usuario,
    public orgao?: Orgao,
    public setores?: InventarioSetor[],
  ) {
    super();
  }

  static converteJson(json: any): Inventario {
    return Object.assign(new Inventario(), json);
  }
}
