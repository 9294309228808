import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { EmpenhoArray, FuncaoService, LicitacaoAudesp } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';

@Component({
  selector: 'lib-licitacao-audesp-empenho-array',
  templateUrl: './licitacao-audesp-empenho-array.component.html'
})
export class LicitacaoAudespEmpenhoArrayComponent extends BaseLicitacaoAudesp implements OnInit, OnChanges, OnDestroy {

  @Input() public entidade: LicitacaoAudesp;
  @Input() public ajuste: LicitacaoAudesp;

  public arquivo: EmpenhoArray;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(public funcaoService: FuncaoService) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.entidade.id) {
        this.arquivo = this.entidade.arquivo as EmpenhoArray;
      } else {
        if (!this.ajuste?.licitacao || !this.entidade) {
          this.iniciarObjeto();
        } else {
          this.importarInformacoes();
        }
      }
    }
  }

  ngOnInit(): void {
    this.iniciarObjeto();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  private iniciarObjeto() {
    this.entidade.arquivo = new EmpenhoArray();
    this.arquivo = this.entidade.arquivo;
  }

  private importarInformacoes() {
    if (!this.ajuste?.licitacao || !this.entidade) return;

    this.iniciarObjeto();

    this.arquivo.CodigoAjuste = +this.ajuste.codigo;
  }

}
