import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { FonteFinanciamento, BaseResourceService, Page } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class FonteFinanciamentoService extends BaseResourceService<FonteFinanciamento> {

  constructor(
    protected injector: Injector
  ) {
    super(`fontes-financiamento`, injector);
  }

  public anexo1(pagina: number, limite: number, ppa: number, orgaos: number[], receita?: number | string): Observable<Page> {
    if (receita) {
      return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/anexo1/${pagina}/${limite}/${ppa}/${orgaos.join()}/${receita}`,
        this.httpOptions()).pipe(
          map(res => res),
          take(1),
          catchError(err => this.handleError(err))
        );
    } else {
      return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/anexo1/${pagina}/${limite}/${ppa}/${orgaos.join()}`,
        this.httpOptions()).pipe(
          map(res => res),
          take(1),
          catchError(err => this.handleError(err))
        );
    }
  }

}
