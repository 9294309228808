import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuncaoService, LoginContabil, Relatorio, Ppa, GlobalService } from 'eddydata-lib';
import { FonteFinanciamentoService } from '../../fonte-financiamento/service/fonte-financiamento.service';

@Injectable({
  providedIn: 'root'
})
export class Anexo1Ppa implements OnDestroy {
  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected fonteServico: FonteFinanciamentoService) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(ppa: Ppa, orgaos: number[]) {
    this.fonteServico.anexo1(1, 9999, ppa.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        Relatorio.imprimirPersonalizado(
          'ANEXO I - FONTES DE FINANCIAMENTO'
          , this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          this.conteudo(dados.content, ppa),
          'landscape', 'ANEXO I',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private conteudo(dados1: any[], ppa: Ppa): {}[] {
    if (dados1.length === 0) {
      return;
    }
    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `PPA: ${ppa.ppaperiodo.ano1} - ${ppa.ppaperiodo.ano4}`,
        alignment: 'center', bold: true, colSpan: 10, border: [true, true, true, false]
      }, '', '', '', '', '', '', '', '', ''],
      [{
        text: 'RECEITAS PREVISTAS',
        alignment: 'center',
        bold: true, colSpan: 10,
        border: [true, false, true, false]
      }, '', '', '', '', '', '', '', '', ''],
      [{
        text: 'ESPECIFICAÇÃO',
        alignment: 'center', rowSpan: 2,
        bold: true, fontSize: 8
      }, {
        text: this.login.ppa.ppaperiodo.ano1,
        alignment: 'center', colSpan: 2,
        bold: true, fontSize: 8
      }, '', {
        text: this.login.ppa.ppaperiodo.ano2,
        alignment: 'center', colSpan: 2,
        bold: true, fontSize: 8
      }, '', {
        text: this.login.ppa.ppaperiodo.ano3,
        alignment: 'center', colSpan: 2,
        bold: true, fontSize: 8
      }, '', {
        text: this.login.ppa.ppaperiodo.ano4,
        alignment: 'center', colSpan: 2,
        bold: true, fontSize: 8
      }, '', {
        text: 'TOTAL', rowSpan: 2,
        alignment: 'center',
        bold: true, fontSize: 8
      }],
      [{
        text: '',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'DIRETA',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'INDIRETA',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'DIRETA',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'INDIRETA',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'DIRETA',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'INDIRETA',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'DIRETA',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'INDIRETA',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'TOTAL',
        alignment: 'center',
        bold: true, fontSize: 8
      }]
    ];

    // monta o agrupamento do relatório
    const grupos1 = this.funcaoService.agrupar(dados1, 'r_n1',
      ['direta1', 'indireta1', 'direta2', 'indireta2', 'direta3', 'indireta3', 'direta4', 'indireta4']);
    let totalD1 = 0;
    let totalD2 = 0;
    let totalD3 = 0;
    let totalD4 = 0;
    let totalI1 = 0;
    let totalI2 = 0;
    let totalI3 = 0;
    let totalI4 = 0;
    for (let index = 0; index < grupos1.length; index++) {
      const item1 = grupos1[index];
      let titulo = '';
      switch (item1.grupo) {
        case '1':
          titulo = 'RECEITAS CORRENTES';
          break;
        case '2':
          titulo = 'RECEITAS CAPITAL';
          break;
        case '7':
          titulo = 'RECEITAS CORRENTES - INTRA OFSS';
          break;
        case '8':
          titulo = 'RECEITAS CAPITAL - INTRA OFSS';
          break;
        case '8':
          titulo = 'RECEITAS CAPITAL - INTRA OFSS';
          break;
        default:
          break;
      }
      registros.push([
        {
          text: titulo, bold: true, fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item1 ? this.funcaoService.convertToBrNumber(item1.totalizadores[`direta1`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item1 ? this.funcaoService.convertToBrNumber(item1.totalizadores[`indireta1`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item1 ? this.funcaoService.convertToBrNumber(item1.totalizadores[`direta2`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item1 ? this.funcaoService.convertToBrNumber(item1.totalizadores[`indireta2`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item1 ? this.funcaoService.convertToBrNumber(item1.totalizadores[`direta3`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item1 ? this.funcaoService.convertToBrNumber(item1.totalizadores[`indireta3`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item1 ? this.funcaoService.convertToBrNumber(item1.totalizadores[`direta4`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item1 ? this.funcaoService.convertToBrNumber(item1.totalizadores[`indireta4`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item1 ? this.funcaoService.convertToBrNumber(
            +item1.totalizadores[`direta1`] + +item1.totalizadores[`direta2`] +
            +item1.totalizadores[`direta3`] + +item1.totalizadores[`direta4`] +
            +item1.totalizadores[`indireta1`] + +item1.totalizadores[`indireta2`] +
            +item1.totalizadores[`indireta3`] + +item1.totalizadores[`indireta4`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, false, true, false]
        }
      ]);
      // lista os registros do relatorio
      for (let idx = 0; idx < item1.registros.length; idx++) {
        const element1 = item1.registros[idx];
        registros.push([
          {
            text: element1 ? `${element1.r_codigo} ${element1.r_nome}` : '', fontSize: 8, border: [true, false, false, false]
          },
          {
            text: element1 ? this.funcaoService.convertToBrNumber(element1.direta1) : '', alignment: 'right',
            fontSize: 8, border: [true, false, false, false]
          },
          {
            text: element1 ? this.funcaoService.convertToBrNumber(element1.indireta1) : '', alignment: 'right',
            fontSize: 8, border: [true, false, false, false]
          },
          {
            text: element1 ? this.funcaoService.convertToBrNumber(element1.direta2) : '', alignment: 'right',
            fontSize: 8, border: [true, false, false, false]
          },
          {
            text: element1 ? this.funcaoService.convertToBrNumber(element1.indireta2) : '', alignment: 'right',
            fontSize: 8, border: [true, false, false, false]
          },
          {
            text: element1 ? this.funcaoService.convertToBrNumber(element1.direta3) : '', alignment: 'right',
            fontSize: 8, border: [true, false, false, false]
          },
          {
            text: element1 ? this.funcaoService.convertToBrNumber(element1.indireta3) : '', alignment: 'right',
            fontSize: 8, border: [true, false, false, false]
          },
          {
            text: element1 ? this.funcaoService.convertToBrNumber(element1.direta4) : '', alignment: 'right',
            fontSize: 8, border: [true, false, false, false]
          },
          {
            text: element1 ? this.funcaoService.convertToBrNumber(element1.indireta4) : '', alignment: 'right',
            fontSize: 8, border: [true, false, false, false]
          },
          {
            text: element1 ? this.funcaoService.convertToBrNumber(
              +element1.direta1 + +element1.direta2 + +element1.direta3 + +element1.direta4 +
              +element1.indireta1 + +element1.indireta2 + +element1.indireta3 + +element1.indireta4) : '', alignment: 'right',
            fontSize: 8, border: [true, false, true, false]
          }
        ]);
        totalD1 += +element1.direta1;
        totalD2 += +element1.direta2;
        totalD3 += +element1.direta3;
        totalD4 += +element1.direta4;
        totalI1 += +element1.indireta1;
        totalI2 += +element1.indireta2;
        totalI3 += +element1.indireta3;
        totalI4 += +element1.indireta4;
      }
    }

    const totalGeral = +totalD1 + +totalD2 + +totalD3 + +totalD4 + +totalI1 + +totalI2 + +totalI3 + +totalI4;

    registros.push([
      {
        text: 'TOTAL',
        bold: true, fontSize: 8
      },
      { text: this.funcaoService.convertToBrNumber(totalD1), bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalI1), bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalD2), bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalI2), bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalD3), bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalI3), bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalD4), bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalI4), bold: true, alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(totalGeral), bold: true, alignment: 'right' },
    ]);
    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }];
  }


}
