import { Audesp4LicencaAmbiental } from "./types/comum-type";
import { Audesp4ObraRodoviariaSaneamentoSim } from "./types/exigencia-obras-type";

export class ExigenciasObrasArray {
    ArrayExigenciasObras: ExigenciaObras[]
}

export class ExigenciaObras {

    CodigoAjuste: number;
    ExisteRelatorioImpactoAmbiental: 'S' | 'N' | 'NA';
    ObraContemplaExigenciasArtigosEstadual1126302: 'S' | 'N' | 'NA';
    ConstaAlvaraConstrucaoObra: 'S' | 'N' | 'NA';
    ObraRodoviariaSaneamentoNao?: 'S' | 'N' | 'NA';
    ObraRodoviariaSaneamentoSim?: Audesp4ObraRodoviariaSaneamentoSim;
    HouvePadronizacaoTipoClasseArt11Lei866693?: 'S' | 'N' | 'NA';
    ExisteAnotacoesResponsabilidadeTecnicaART?: 'S' | 'N' | 'NA';
    DataEmissaoOrdemServico_Dt?: string;
    LicencaAmbientalNao?: 'S' | 'N';
    LicencaAmbientalSim?: Audesp4LicencaAmbiental[];

    constructor(ignorarNovaInstancia?: boolean) {
        if (!ignorarNovaInstancia) {
            this.ExisteRelatorioImpactoAmbiental = 'N';
            this.ObraContemplaExigenciasArtigosEstadual1126302 = 'N';
            this.ConstaAlvaraConstrucaoObra = 'N';
            this.ObraRodoviariaSaneamentoNao = 'N';
            this.HouvePadronizacaoTipoClasseArt11Lei866693 = 'N';
            this.ExisteAnotacoesResponsabilidadeTecnicaART = 'N';
            this.LicencaAmbientalNao = 'N';
            this.ObraRodoviariaSaneamentoSim = {
                AreasSelecionadasAdotadosCriteriosTecnicosArt6IXLei866693: 'N', HouveLevantamentoCondicoesFisicasAreasSelecionadas: 'N'
            }
        }
    }
}