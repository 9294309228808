import { BaseResourceModel } from "../../models/base-resource.model";
import { Exercicio } from "../comum/exercicio.model";
import { Orgao } from "../comum/orgao.model";
import { Pessoa } from "../comum/pessoa.model";
import { Compra } from "./compra.model";
import { PedidoCompraItem } from "./pedido-compra-item.model";

export class PedidoCompra extends BaseResourceModel {

  constructor(
    public id?: number,
    public data_alteracao?: Date,
    public data_cadastro?: Date,
    public numero?: number,
    public sequencia?: number,
    public data_pedido?: Date,
    public observacao?: string,
    public compra?: Compra,
    public orgao?: Orgao,
    public exercicio?: Exercicio,
    public requerente?: Pessoa,
    public itens?: PedidoCompraItem[]
  ) {
    super()
  }

  static converteJson(json: any): PedidoCompra {
    return Object.assign(new PedidoCompra(), json);
  }
}