import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FuncaoService } from 'eddydata-lib';

@Directive()
export class Anexo8DespesaMde implements OnDestroy {

  protected funcaoService: FuncaoService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number) {
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(dados: any[]): {}[] {

    const registros: {}[] = [
      [{
        text: 'DESPESAS COM MANUTENÇÃO E DESENVOLVIMENTO DO ENSINO - MDE - CUSTEADAS COM RECEITA DE IMPOSTOS (EXCETO FUNDEB)', colSpan: 6, fontSize: 8, bold: true,
        margin: [5, 5, 5, 5], alignment: 'center', border: [true, false, true, true]
      }, '', '', '', '', ''],
      [
        { text: 'DESPESAS COM AÇÕES TÍPICAS DE MDE - RECEITAS DE IMPOSTOS - EXCETO FUNDEB (Por Área de Atuação)', alignment: 'center', bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: 'DOTAÇÃO ATUALIZADA (c)', alignment: 'center', bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: 'DESPESAS EMPENHADAS ATÉ O BIMESTRE (d)', alignment: 'center', bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: 'DESPESAS LIQUIDADAS ATÉ O BIMESTRE (e)', alignment: 'center', bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: 'DESPESAS PAGAS ATÉ O BIMESTRE (f)', alignment: 'center', bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: 'RESTOS A PAGAR NÃO PROCESSADOS (g)', alignment: 'center', bold: true, fontSize: 7, border: [true, false, true, true] }
      ],
    ];

    const grupos2 = this.funcaoService.agrupar(dados, 'grupo', ['dotacao_atualizado', 'empenhado', 'liquidado', 'pago', 'resto']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    for (const grupo of grupos2) {
      registros.push([
        { text: grupo.grupo, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_atualizado']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado'] - grupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 }
      ]);
      total1 += +grupo.totalizadores['dotacao_atualizado'];
      total2 += +grupo.totalizadores['empenhado'];
      total3 += +grupo.totalizadores['liquidado'];
      total4 += +grupo.totalizadores['pago'];
      total5 += (+grupo.totalizadores['empenhado'] - +grupo.totalizadores['liquidado']);

      const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['dotacao_atualizado', 'empenhado', 'liquidado', 'pago', 'resto']);
      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo != '') {
          registros.push([
            { text: subgrupo.grupo, fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['dotacao_atualizado']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['pago']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado'] - subgrupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 }
          ]);

          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              registros.push([
                { text: item.nome, fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.dotacao_atualizado), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.empenhado), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.liquidado), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.pago), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.empenhado - item.liquidado), alignment: 'right', fontSize: 7 },
              ]);
            }
          }
        }
      }
    }

    registros.push([
      { text: '26 - TOTAL DAS DESPESAS COM AÇÕES TÍPICAS DE MDE (24 + 25)', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total4), alignment: 'right', fontSize: 7, bold: true },
      { text: this.funcaoService.convertToBrNumber(total5), alignment: 'right', fontSize: 7, bold: true }
    ]);
    return registros;

  }

  public montarQuadroCsv(dados: any[], listaExportar): any {
    const primeira_linha = {
      titulo: 'DESPESAS COM MANUTENÇÃO E DESENVOLVIMENTO DO ENSINO - MDE - CUSTEADAS COM RECEITA DE IMPOSTOS (EXCETO FUNDEB)',
      coluna1: '',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
    }
    listaExportar.push(primeira_linha)

    const segunda_linha = {
      titulo: 'DESPESAS COM AÇÕES TÍPICAS DE MDE - RECEITAS DE IMPOSTOS - EXCETO FUNDEB (Por Área de Atuação)',
      coluna1: 'DOTAÇÃO ATUALIZADA (c)',
      coluna2: 'DESPESAS EMPENHADAS ATÉ O BIMESTRE (d)',
      coluna3: 'DESPESAS LIQUIDADAS ATÉ O BIMESTRE (e)',
      coluna4: 'DESPESAS PAGAS ATÉ O BIMESTRE (f)',
      coluna5: 'RESTOS A PAGAR NÃO PROCESSADOS (g)',
      coluna6: '',
    }
    listaExportar.push(segunda_linha)

    const grupos2 = this.funcaoService.agrupar(dados, 'grupo', ['dotacao_atualizado', 'empenhado', 'liquidado', 'pago', 'resto']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    for (const grupo of grupos2) {
      const linha_grupo = {
        titulo: grupo.grupo,
        coluna1: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_atualizado']),
        coluna2: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']),
        coluna3: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']),
        coluna4: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago']),
        coluna5: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado'] - grupo.totalizadores['liquidado']),
        coluna6: ''
      }
      listaExportar.push(linha_grupo)

      total1 += +grupo.totalizadores['dotacao_atualizado'];
      total2 += +grupo.totalizadores['empenhado'];
      total3 += +grupo.totalizadores['liquidado'];
      total4 += +grupo.totalizadores['pago'];
      total5 += (+grupo.totalizadores['empenhado'] - +grupo.totalizadores['liquidado']);

      const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['dotacao_atualizado', 'empenhado', 'liquidado', 'pago', 'resto']);
      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo != '') {
          const linha_subgrupo = {
            titulo: subgrupo.grupo,
            coluna1: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['dotacao_atualizado']),
            coluna2: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado']),
            coluna3: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liquidado']),
            coluna4: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['pago']),
            coluna5: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado'] - subgrupo.totalizadores['liquidado']),
            coluna6: ''
          }
          listaExportar.push(linha_subgrupo)

          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              const linha_subgrupo_item = {
                titulo: item.nome,
                coluna1: this.funcaoService.convertToBrNumber(item.dotacao_atualizado),
                coluna2: this.funcaoService.convertToBrNumber(item.empenhado),
                coluna3: this.funcaoService.convertToBrNumber(item.liquidado),
                coluna4: this.funcaoService.convertToBrNumber(item.pago),
                coluna5: this.funcaoService.convertToBrNumber(item.empenhado - item.liquidado),
                coluna6: ''
              }
              listaExportar.push(linha_subgrupo_item)
            }
          }
        }
      }
    }

    const linha_subgrupo = {
      titulo: '26 - TOTAL DAS DESPESAS COM AÇÕES TÍPICAS DE MDE (24 + 25)',
      coluna1: this.funcaoService.convertToBrNumber(total1),
      coluna2: this.funcaoService.convertToBrNumber(total2),
      coluna3: this.funcaoService.convertToBrNumber(total3),
      coluna4: this.funcaoService.convertToBrNumber(total4),
      coluna5: this.funcaoService.convertToBrNumber(total5),
      coluna6: ''
    }
    listaExportar.push(linha_subgrupo)
  }

}