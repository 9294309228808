import { Component, Input } from "@angular/core";
import { BaseResourceItemsComponent, EddyAutoComplete, FuncaoService, GlobalService, PedidoCompra, PedidoCompraItem } from "eddydata-lib";
import { PedidoCompraItemService } from "../service/pedido-compra-item.service";
import * as toastr from 'toastr';

@Component({
  selector: 'app-pedido-compra-item',
  templateUrl: './pedido-compra-item.component.html'
})
export class PedidoCompraItemComponent extends BaseResourceItemsComponent<PedidoCompraItem> {

  @Input() public pedido: PedidoCompra;

  public pedidoAutoComplete: EddyAutoComplete<PedidoCompra>;
  public quantidadeOriginal: number;
  
  constructor(
    public funcaoService: FuncaoService,
    public globalService: GlobalService,
    public pedidoCompraItemService: PedidoCompraItemService
  ) {
    super(new PedidoCompraItem(), pedidoCompraItemService);
  }

  protected afterInit(): void { }

  public editarQnt(item, index) {
    this.quantidadeOriginal = this.lista[index]['quantidade'];
    this.lista[index]['novo'] = !item;
  }

  public salvarQnt(item, index) {

    if(+this.lista[index]['quantidade']){
      if (+this.lista[index]['quantidade'] > +this.lista[index]['item']['quantidade'])
        return toastr.warning('Quantidade esta superior a quantidade inicial!')
      if (+this.lista[index]['quantidade'] <= 0)
        return toastr.warning('Quantidade esta igual ou abaixo de 0!')
      this.lista[index]['novo'] = !item;
    }else{
      if (+this.lista[index]['quantidade'] <= 0)
        return toastr.warning('Quantidade esta igual ou abaixo de 0!')
      toastr.warning('O pedido precisa ser um número!')
    }

   
  }

  public cancelarQnt(item, index) {
    this.lista[index]['quantidade'] = this.quantidadeOriginal
    // this.lista[index]['pedido']['numero'] = this.quantidadeOriginal;
    this.lista[index]['novo'] = !item;
  }

  protected validSave(item: PedidoCompraItem): boolean {
    if (!item['quantidade_import'] || item['quantidade_import'] <= 0) {
      toastr.warning('Informe a quantidade para o item!');
      return false;
    }
    if (item['quantidade_import'] <= (item.item['qtd_restante'] || item['qtd_restante'])) {
      toastr.warning('Quantidade superior ao saldo restante!');
      return false;
    }

  }

  protected beforeSave(item: PedidoCompraItem): void { }

}