import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ModalAssinadorService } from './modal-assinador.service';
import * as toastr from 'toastr';
import { Login } from '../entidade/login/login';
import { timer } from 'rxjs';
import { Buffer } from 'buffer';

@Component({
  selector: 'app-modal-assinador',
  templateUrl: './modal-assinador.component.html',
  styleUrls: ['./modal-assinador.component.css']
})
export class ModalAssinadorComponent implements OnInit {

  @Input() login: Login;
  @Input() livre: boolean = false;

  aliases: Array<String> = new Array<String>();
  alias: String = '';
  senha: String = '';
  salvarSenha: boolean = false;
  tempo: number = 5;
  md5: boolean = false;
  file: File;
  tempConfig: String = null;

  //Assinador
  funcional: boolean = false;
  trys: number = 0;

  constructor(private service: ModalAssinadorService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.login.usuario.tipo_chave) {
      this.checkTempSave();
      this.verificaAssinador();
    }
    if (this.login.usuario.tipo_chave == 3) {
      this.service.getAlias().subscribe((res) => {
        this.aliases = res.data;
      }, e => {
        toastr.error(e.error.errors[0]);
      });
    }
  }

  floor() {
    this.tempo = Math.floor(this.tempo);
  }

  salvar() {
    let dados: any[] = [];
    dados[0] = (this.md5 ? 'MD5' : this.login.usuario.tipo_chave == 1 ? this.senha : this.alias);
    dados[1] = this.salvarSenha;
    dados[2] = this.tempo;
    if (!this.file) {//Se não tiver arquivo, dar aviso
      toastr.warning('É preciso selecionar um arquivo para assinar');
    } else if (this.file.type.indexOf('pdf') < 0) {
      toastr.warning('Assinatura de documentos disponível para formato de PDF.');
    } else {
      if (this.login.usuario.tipo_chave == 1) {
        this.assinarA1(dados[0]).then(() => {
          //Se precisar resetar algo, deve fazer aqui
          if (dados[1]) {
            window.localStorage.setItem('paramGedSave', dados[0]);
            window.localStorage.setItem('limitGedDate', new Date(new Date().getTime() + dados[2] * 60000).toString());
            this.checkTempSave();
          }
        });
      } else if (this.login.usuario.tipo_chave == 3) {
        this.assinarA3(dados[0]).then(() => {
          //Se precisar resetar algo, deve fazer aqui
          if (dados[1]) {
            window.localStorage.setItem('paramGedSave', dados[0]);
            window.localStorage.setItem('limitGedDate', new Date(new Date().getTime() + dados[2] * 60000).toString());
            this.checkTempSave();
          }
        }).catch((e) => {
          toastr.error('Falha ao concluir rotina,' + JSON.stringify(e));
        });
      }
    }

    let v = window.document.getElementById('btnClose');
    v.click();
  }

  assinarA1(senha: String): Promise<void> {
    //Envia os 2 primeiros preenchidos, para identificar uma A1.
    const dados = {
      caminho: this.login.usuario.caminho_a1,
      senha: senha,
      usuario: this.login.usuario.nome,
      alias: null,
      converter: false,
      orientacao: 'v'
    }

    let p = new Promise<void>((resolve, reject) => {
      //Ver como colocar um aviso enquanto a rotina não termina, de modo que não seja possível de fechar, uma vez que no Web contabil não usa o Swal.

      //Chama rotina de assinatura
      //Caso a assinatura seja LIVRE, envia A1 para cair na rotina que não valida
      //Caso não seja, e deve ser verificada, ele chama como A3 para validar a A1.
      this.service.assinar(this.file, dados, (this.login.usuario.tipo_chave == 1 ? 'A1' : 'A3')).then((res) => {
        //A rotina retorna os dados
        const base = res.data;

        //É feito um blob dos dados
        const tipo = 'application/pdf';
        // const blob = new Blob([base], { type: tipo });
        //É recriado o arquivo a partir dos blobs
        // const arq = new File([blob], this.file.name, { type: tipo });//Caso precisar de um tipo File
        this.baixarArquivo(base, tipo);
        resolve();
      }, e => {
        toastr.error('Erro ao assinar arquivo:' + (e.error == null ? e.errors[0] : e.error));
        reject();
      });
    });

    return p;
  }

  assinarA3(alias: string): Promise<void> {
    //Envia os 2 primeiros nulls, para identificar uma A3.
    const dados = {
      caminho: this.login.usuario.caminho_a1,
      senha: null,
      usuario: this.login.usuario.nome,
      alias: this.alias,
      converter: false,
      orientacao: 'v'
    }

    let p = new Promise<void>((resolve, reject) => {
      //Ver como colocar um aviso enquanto a rotina não termina, de modo que não seja possível de fechar, uma vez que no Web contabil não usa o Swal.

      this.service.assinar(this.file, dados, 'A3').then((res) => {
        //A rotina retorna os dados
        const base = res.data;
        const tipo = 'application/pdf';
        //É feito um blob dos dados
        const blob = new Blob([base], { type: tipo });
        //É recriado o arquivo a partir dos blobs
        // const arq = new File([blob], this.file.name, { type: tipo });//Caso precisar de um tipo File
        this.baixarArquivo(base, tipo);
        resolve();
      }, e => {
        toastr.error(e.error.errors[0]);
        reject();
      }).catch(e => {
        toastr.error('Erro ao concluir rotina: ' + JSON.stringify(e));
      });
    });

    return p;
  }

  private checkTempSave() {
    this.tempConfig = window.localStorage.getItem('paramGedSave');
    if (this.tempConfig != null) {
      let limite: Date = new Date(Date.parse(window.localStorage.getItem('limitGedDate')));
      if (new Date() > limite) {
        this.tempConfig = null;
        window.localStorage.removeItem('limitGedDate');
        window.localStorage.removeItem('paramGedSave');
      }
    }
  }


  private baixarArquivo(blob, tipo) {
    const byteCharacters = atob(blob);
    const bytes = Buffer.from(blob, 'base64');
    const url = URL.createObjectURL(new Blob([bytes], { type: 'application/pdf' }));
    timer(2000).subscribe(() => {
      let tab = window.open();
      tab.location.href = url;
      // var elem = window.document.createElement('a');
      // elem.href = url;
      // console.log(elem.href);
    });

    // elem.download = ??;
    // document.body.appendChild(elem);
    // elem.click();
    // document.body.removeChild(elem);

  }

  private verificaAssinador(manual?: boolean) {
    if (manual) {
      this.funcional = false;
      this.trys = 4;
    } else {
      if (sessionStorage.getItem('situacao-assinador') == 'false') {
        this.funcional = false;
        this.trys = 5;
        return;
      } else if (sessionStorage.getItem('situacao-assinador') == 'true') {
        this.funcional = true;
        return;
      }
    }

    this.service.assinadorOnline().subscribe(() => {
      this.funcional = true;
      sessionStorage.setItem('situacao-assinador', 'true');
    }, (e) => {
      timer(5000).subscribe(() => {
        this.trys += 1;
        if (this.trys < 5) {
          this.verificaAssinador();
        } else {
          sessionStorage.setItem('situacao-assinador', 'false');
        }
      });
    });
  }

  getFile(arquivo: any) {
    let arr = <Array<File>>arquivo.target.files;
    this.file = Array.from(arr)[0];
  }

  baixarAssinador() {
    var elem = window.document.createElement('a');
    elem.href = 'assets/assinador/assinador-2.jar';
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
    // window.open(environment.assets + 'assinador-2.jar');
  }

}
