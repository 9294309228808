import {
  Component,
  ElementRef, HostListener, Injector, Input
} from "@angular/core";
import { Validators } from "@angular/forms";
import {
  BaseResourceFormComponent, EddyAutoComplete,
  Favorecido,
  FavorecidoService, Fracionamento, FuncaoService,
  GlobalService,
  LoginContabil, Page, Produto,
  ProdutoUnidade, SaldoEstoqueService, SetorAlmoxarifado,
  SetorAlmoxarifadoService,
  Unidade
} from "eddydata-lib";
import { Observable } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import * as toastr from "toastr";
import { ProdutoUnidadeService } from "../../produto/service/produto-unidade.service";
import { ProdutoService } from "../../produto/service/produto.service";
import { FracionamentoService } from "../service/fracionamento.service";

declare var $: any;
@Component({
  selector: "app-fracionamento-form",
  templateUrl: "./fracionamento-form.component.html",
})
export class FracionamentoFormComponent extends BaseResourceFormComponent<
  Fracionamento,
  LoginContabil
> {
  @Input() compraId: number = 0;

  /**
   * Declaração de variáveis
   */
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;
  public fracionarAutoComplete: EddyAutoComplete<Produto>;
  public fracionadoAutoComplete: EddyAutoComplete<Produto>;
  public listSetores: SetorAlmoxarifado[];
  public listProdUnFracionar: ProdutoUnidade[];
  public listProdUnFracionado: ProdutoUnidade[];

  public listLoteVec: any[] = [];
  public listLoteVecFiltro: any[];
  public botaoSelecionado: 'F1' | 'F2';

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected dissolucaoService: FracionamentoService,
    private setorAlmoxarifadoService: SetorAlmoxarifadoService,
    private favorecidoService: FavorecidoService,
    private saldoEstoqueService: SaldoEstoqueService,
    protected produtoService: ProdutoService,
    private produtoUnService: ProdutoUnidadeService
  ) {
    super(
      new Fracionamento(),
      injector,
      Fracionamento.converteJson,
      dissolucaoService
    );
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      qtd_fracionar: [0, [Validators.required]],
      qtd_fracionado: [0, [Validators.required]],
      lote: [null, Validators.required],
      vencimento: [null, Validators.required],
      fracionar: this.fb.group({
        id: [null, Validators.required],
        unidade: [null],
        produto: [null, Validators.required],
        ativo: [false],
      }),
      fracionado: this.fb.group({
        id: [null, Validators.required],
        unidade: [null],
        produto: [null, Validators.required],
        ativo: [false],
      }),
      setor_almoxarifado: [null, Validators.required],
      usuario: [this.login.usuario],
      exercicio: [this.login.exercicio],
      localizacao: [null],
      orgao: [this.login.orgao],
      recebedor: this.fb.group({
        nome: [this.login.usuario.nome],
        cpf_cnpj: [this.login.usuario.cpf],
        email: [this.login.usuario.email],
        cidade: [this.login.cidade],
        especie: ['2']
      }),
      favorecido: [null],
      observacao: [null],
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: [
        "fracionado",
        "fracionado.produto",
        "fracionado.unidade",
        "fracionar",
        "fracionar.produto",
        "fracionar.unidade",
        "setor_almoxarifado",
        "favorecido",
      ],
    };
  }

  protected afterLoad() {
    this.favorecidoAutoComplete.id = this.entidade.favorecido
      ? this.entidade.favorecido.id
      : null;
    this.fracionarAutoComplete.id = this.entidade.fracionar
      ? this.entidade.fracionar.id
      : null;
    this.fracionadoAutoComplete.id = this.entidade.fracionado
      ? this.entidade.fracionado.id
      : null;

    this.listProdUnFracionar = this.entidade.fracionar
      ? [this.entidade.fracionar]
      : [];
    this.listProdUnFracionado = this.entidade.fracionado
      ? [this.entidade.fracionado]
      : [];

    if (this.entidade.id) this.entidadeForm.disable();
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.entidadeForm
      .get("setor_almoxarifado")
      .setValue(this.login.setorAlmoxarifado);
  }

  protected async podeAlterar(_entidade: Fracionamento): Promise<boolean> {
    return true;
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit() {
    try {
      if (this.entidadeForm.get("qtd_fracionar").value <= 0)
        throw new Error("Quantidade a ser fracionado precisa ser maior que 0!");
      if (this.entidadeForm.get("qtd_fracionado").value <= 0)
        throw new Error("Quantidade fracionado precisa ser maior que 0!");
      let dissover: ProdutoUnidade = this.entidadeForm.get("fracionar").value;
      if (!dissover.id && !dissover.produto?.id)
        throw new Error(
          "Necessário informar produto e unidade a ser fracionado!"
        );
      if (!dissover.id && !dissover.unidade?.id)
        throw new Error(
          "Necessário informar unidade do produto a ser fracionado!"
        );
      let fracionado: ProdutoUnidade =
        this.entidadeForm.get("fracionado").value;
      if (!fracionado.id && !fracionado.produto?.id)
        throw new Error("Necessário informar produto e unidade fracionado!");
      if (!fracionado.id && !fracionado.unidade?.id)
        throw new Error("Necessário informar unidade do produto fracionado!");
      if (!this.entidadeForm.get("setor_almoxarifado"))
        throw new Error("Necessário setor de produto a ser fracionado!");
      if (!this.entidadeForm.get("lote"))
        throw new Error(
          "Necessário informar o lote/vencimento do produto a ser fracionado!"
        );
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  protected afterSubmit(entidade: Fracionamento) { }

  public acaoSucesso(entidade: Fracionamento) {
    this.afterSubmit(entidade);
    if (this.mensagemSucesso) toastr.success(this.mensagemSucesso);
    if (!this.skipRedirect) {
      let url: string = this.baseResourceService.retornarRota();
      if (this.limparTela) {
        // salvar e novo
        if (url.includes("novo")) {
          const rota = this.baseResourceService.retornarRota();
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => this.router.navigate([rota]));
        } else {
          this.router.navigate([
            this.baseResourceService
              .retornarRota()
              .replace(/[0-9].*\/editar/g, "novo"),
          ]);
        }
      } else {
        // salvar
        if (url.includes("novo")) {
          let params = "";
          if (url.lastIndexOf("/novo") > 0) {
            params = url.substring(url.indexOf("/novo") + +"/novo".length);
            url = url.substring(
              0,
              url.substring(1, url.length).indexOf("/novo") + 1
            );
          }
          url = url + "/" + entidade.id + "/visualizar" + params;
          this.router.navigate([url]);
        }
      }
    }
    this.limparTela = false;
  }

  @HostListener("window:keydown.control.p", ["$event"])
  public abrirConsultaProduto(event: KeyboardEvent) {
    event.preventDefault();
    $("#dialogProdutoUnBusca").modal("show");
  }

  public selecaoNoBotaoAvancado(tipo: 'F1' | 'F2') {
    this.botaoSelecionado = tipo
  }

  public callbackProdutoUn(prodUn: ProdutoUnidade) {

    if (this.botaoSelecionado === 'F1') {

      this.listProdUnFracionar = [prodUn];

      this.entidadeForm.get("fracionar").get("produto").setValue(prodUn.produto);
      this.entidadeForm.get("fracionar").get("unidade").setValue(prodUn.unidade);
      this.entidadeForm.get("fracionar").get("id").setValue(prodUn.id);
      this.entidadeForm.get("fracionar").get("ativo").setValue(prodUn.ativo);

    } else {

      this.listProdUnFracionado = [prodUn];

      this.entidadeForm.get("fracionado").get("produto").setValue(prodUn.produto);
      this.entidadeForm.get("fracionado").get("unidade").setValue(prodUn.unidade);
      this.entidadeForm.get("fracionado").get("id").setValue(prodUn.id);
      this.entidadeForm.get("fracionado").get("ativo").setValue(prodUn.ativo);
    }

    this.alertaUniIguais()
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  carregarAutoCompletes() {
    this.favorecidoAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("favorecido"),
      this.favorecidoService,
      "id",
      ["nome"],
      { cidade_id: this.login.cidade.id, orderBy: "nome" },
      { number: ["id", "cpf_cnpj"], text: ["nome"] }
    );
    // autocomplete para produto
    this.fracionarAutoComplete = ProdutoService.autoCompleteCodigoCompleto(
      null,
      this.produtoService,
      "id",
      ["codigo", "nome"],
      { orgao_id: this.login.orgao.id, orderBy: "codigo,nome" },
      { number: ["id", "codigo"], text: ["codigo", "nome"] },
      null,
      null,
      null,
      (
        pagina: number,
        limite: number,
        filtros: {},
        filtroStr: string
      ): Observable<Page> => {
        let parametros: { codigo?: string; nome?: string } = {};
        if (this.funcaoService.isNumerico(filtroStr))
          parametros.codigo = filtroStr;
        else parametros.nome = filtroStr;
        return this.saldoEstoqueService
          .obterProdutosComSaldo(
            this.entidadeForm.get("setor_almoxarifado").value.id,
            parametros
          )
          .pipe(
            map((res) => {
              let page = new Page();
              page.content = res;
              return page;
            })
          );
      }
    );
    // autocomplete para produto
    this.fracionadoAutoComplete = ProdutoService.autoCompleteCodigoCompleto(
      null,
      this.produtoService,
      "id",
      ["codigo", "nome"],
      { orgao_id: this.login.orgao.id, orderBy: "codigo,nome" },
      { number: ["id", "codigo"], text: ["codigo", "nome"] }
    );
  }

  public carregarUnidades(produto: Produto, tipo: "F1" | "F2") {
    this.produtoUnService
      .filtrarCodigoCompleto(0, 0, {
        produto_id: produto.id,
        ativo: true,
        relations: "produto,unidade",
        orderBy: "unidade.nome",
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res) => {
          let listProdUn = res ? res.content : [];
          if (listProdUn.length === 0) {
            toastr.error(
              "Nenhuma unidade ativa foi localizada para este produto!"
            );
            return;
          }
          let f = this.getFormGroup(tipo);
          if (!f.value.id) {
            f.patchValue(listProdUn[0]);
          }
          if (tipo === "F1") this.listProdUnFracionar = listProdUn;
          else this.listProdUnFracionado = listProdUn;
          this.changeUnidade(tipo);
        },
        (error) => toastr.error(error.message ? error.message : error)
      );
  }

  private getFormGroup(tipo: "F1" | "F2") {
    return this.entidadeForm.get(tipo === "F1" ? "fracionar" : "fracionado");
  }

  public changeUnidade(tipo: "F1" | "F2") {
    let f = this.getFormGroup(tipo);
    let uni: Unidade = f.get("unidade").value;
    if (uni && uni.id)
      if (tipo === "F1") {
        f.patchValue(
          this.listProdUnFracionar.find((pu) => pu.unidade.id === uni.id)
        );
        this.carregarLotes();
      }
      else {
        f.patchValue(
          this.listProdUnFracionado.find((pu) => pu.unidade.id === uni.id)
        );
      }

    this.alertaUniIguais()
  }

  public carregarLotes() {
    let produto_unidade = this.entidadeForm.get("fracionar").value;
    if (!produto_unidade || !produto_unidade.id) return;
    this.saldoEstoqueService
      .saldoProdutoUnidadeLoteVencimento(
        produto_unidade.id,
        this.entidadeForm.get("setor_almoxarifado").value.id,
        null
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((saldos) => {
        if (!saldos || saldos.length === 0) return;
        this.listLoteVec = saldos;

        let lote = this.entidadeForm.get("lote").value;
        let vencimento = this.entidadeForm.get("vencimento").value;

        if (!lote || !vencimento) {
          let saldo = saldos[0];
          this.entidadeForm.get("lote").setValue(saldo.lote);
          this.entidadeForm.get("vencimento").setValue(saldo.vencimento);
        }
      });
  }

  buscarLoteVencimento(event) {
    if (event.query)
      this.listLoteVecFiltro = this.listLoteVec.filter((l) =>
        l.lote.toLowerCase().includes(event.query.toLowerCase())
      );
    else this.listLoteVecFiltro = Object.assign([], this.listLoteVec);
  }

  conversorLote(itemL) {
    if (!itemL || !itemL.lote || !itemL.vencimento) return "";
    return `${itemL.lote} - ${new FuncaoService().converteDataBR(
      itemL.vencimento
    )}`;
  }

  onChangeLote(itemL) {
    this.entidadeForm.get("lote").setValue(itemL.lote);
    this.entidadeForm.get("vencimento").setValue(itemL.vencimento);
  }

  public alertaUniIguais() {
    let unidadeFracionado = this.entidadeForm.get('fracionado').value.unidade?.nome;
    let unidadeFracionar = this.entidadeForm.get('fracionar').value.unidade?.nome;

    if (unidadeFracionado === unidadeFracionar)
      toastr.warning('Os tipos de unidades selecionados são iguais')
  }
}
