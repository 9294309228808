import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmpenhoRestoViewComponent } from './empenho-resto-view/empenho-resto-view.component';
import { EmpenhoRestoListComponent } from './empenho-resto-list/empenho-resto-list.component';
import { AuthGuard } from 'eddydata-lib';
import { EmpenhoRestoFormComponent } from './empenho-resto-form/empenho-resto-form.component';
import { EmpenhoRestoRptComponent } from './empenho-resto-rpt/empenho-resto-rpt.component';

const routes: Routes = [
  { path: '', component: EmpenhoRestoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: EmpenhoRestoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: EmpenhoRestoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: EmpenhoRestoViewComponent, canActivate: [AuthGuard] },
  { path: 'relatorios', component: EmpenhoRestoRptComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmpenhoRestoRoutingModule { }
