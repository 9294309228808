import { Component, Input, OnInit } from '@angular/core';
import { Audesp4Recurso, FuncaoService, RegistroPrecos } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-recurso',
  templateUrl: './licitacao-audesp-recurso.component.html'
})
export class LicitacaoAudespRecursoComponent extends BaseLicitacaoAudesp implements OnInit {

  @Input() public entidade: RegistroPrecos;

  public itemAtual: Audesp4Recurso;
  public itemAnterior: Audesp4Recurso;
  public opcoesResultado: { id: number, nome: string }[];

  constructor(public funcaoService: FuncaoService) {
    super()
  }

  ngOnInit(): void {
    this.opcoesResultado = [
      { id: 1, nome: 'Provido' },
      { id: 2, nome: 'Desprovido' },
      { id: 3, nome: 'Provido Parcialmente' },
      { id: 4, nome: 'Não Julgado' },
    ]
  }

  public adicionar() {
    if (!this.entidade.Recurso || this.entidade.Recurso.length === 0) this.entidade.Recurso = [];

    this.itemAtual = {
      RecursoDtImpetracao: undefined, RecursoPrazoLegal: 'N', RecursoResultado: undefined, tipoDoc: 'CPF', editavel: true
    }
    this.entidade.Recurso.push(this.itemAtual);
  }

  public visualizar(item: Audesp4Recurso) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      let itm = this.entidade.Recurso.find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.entidade.Recurso.splice(this.entidade.Recurso.indexOf(this.itemAtual), 1, itm)
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAtual.editavel = false;
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4Recurso) {
    this.itemAtual = undefined;
    this.entidade.Recurso.splice(this.entidade.Recurso.indexOf(item), 1)
  }

  public salvar() {
    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }

}
