import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ServicoListComponent } from './servico-list/servico-list.component';
import { ServicoViewComponent } from './servico-view/servico-view.component';
import { AuthGuard } from 'eddydata-lib';
import { ServicoFormComponent } from './servico-form/servico-form.component';

const routes: Routes = [
  { path: '', component: ServicoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: ServicoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: ServicoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: ServicoViewComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ServicoRoutingModule { }
