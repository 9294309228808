import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class ReinfRegistro extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public id_arquivo?: String,
    public id_envio?: String,
    public recibo?: String,
    public tabela_alvo?: String,
    public id_alvo?: number,
    public evento?: String,
    public validade_inicio?: String,
    public validade_fim?: String,
    public sucesso?: boolean,
    public ratificado?: boolean,
    public envio_ratificando?: ReinfRegistro,
    public descricao_retorno?: String,
    public ambiente?: number,
    public orgao?: Orgao
  ) {
    super();
  }

  static converteJson(json: any): ReinfRegistro {
    return Object.assign(new ReinfRegistro(), json);
  }
}
