import { Injectable, Injector } from '@angular/core';
import { SetorExecutora } from '../../../entidade/almoxarifado/setor-executora.model';
import { BaseResourceService } from '../../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class SetorExecutoraService extends BaseResourceService<SetorExecutora> {

  constructor(
    protected injector: Injector
  ) {
    super(`setor-executoras`, injector);
  }

}
