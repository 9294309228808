import { BaseResourceStorage } from '../../models/base-resource.storage';
import { Veiculo } from './veiculo.model';

export class VeiculoStorage extends BaseResourceStorage {
  constructor(
    public veiculo?: Veiculo,
  ) {
    super();
  }

  static converteJson(json: any): VeiculoStorage {
    return Object.assign(new VeiculoStorage(), json);
  }
}
