import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Tabela } from '../../entidade/comum/tabela.model';
import { BaseResourceService } from '../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class TabelasService extends BaseResourceService<Tabela> {

  constructor(
    protected injector: Injector
  ) {
    super(`tabelas`, injector);
  }

  public obterClasses(nome?: string): Observable<{
    classeNome: string,
    nome: string
  }[]> {
    let param = '';
    if (nome)
      param += '?nome=' + nome;
    return this.http.get<{ classeNome: string, nome: string }[]>(`${this.login.cidade.id}/${this.api}/classes/${param}`, this.httpOptions());
  }

  public obterCampos(classeNome: string, parametros?: { soment_camp_classe: boolean }): Observable<{
    nome: string, campo: string, tipo: string, nomeClasseSimples: string, classe: string
  }[]> {
    return this.http.get<any[]>(`${this.login.cidade.id}/${this.api}/campos/${classeNome}${this.converterParametrosStr(parametros)}`, this.httpOptions());
  }

}
