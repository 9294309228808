import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, PlanoContratacaoAnual } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlanoContratacaoAnualService extends BaseResourceService<PlanoContratacaoAnual> {

  constructor(
    protected injector: Injector
  ) {
    super(`plano-contratacao-anual`, injector);
  }

  public importarAnteriorRelatorio(exercicioAnteriorId: number, exercicioAtualId: number, orgaoId: number): Observable<void> {
    return this.http.get<void>(
      `${this.login.cidade.id}/${this.api}/importar-anterior/${exercicioAnteriorId}/${exercicioAtualId}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
