import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, CompraItem, Page, ProdutoUnidade } from 'eddydata-lib';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompraItemService extends BaseResourceService<CompraItem> {

  constructor(
    protected injector: Injector
  ) {
    super(`compra-itens`, injector);
  }

  public obterPorCompra(id: number): Observable<Page> {
    return this.http.get<Page>(
      // tslint:disable-next-line: max-line-length
      `${this.login.cidade.id}/${this.api}/filtrar/?relations=produto_unidade,produto_unidade.unidade,produto_unidade.produto&compra_id=${id}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterSaldoItens(compraId: number): Observable<{ itemId: number, quantidade: number }[]> {
    return this.http.get<{ itemId: number, quantidade: number }[]>(`${this.login.cidade.id}/${this.api}/saldo/${compraId}`, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterSaldoAtualizado(compraId: number, produto: string, unidade: string): Observable<any>;
  public obterSaldoAtualizado(compraId: number, produto: number): Observable<any>;
  public obterSaldoAtualizado(compraId: number, produto: number | string, unidade?: string): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/saldo-atualizado/${compraId}/${produto}${unidade ? `/${unidade}` : ''}`, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  };

  public relatorioBancoPrecos(mesInicial: string, mesFinal: string, subGrupoId?: string, itemId?: string, favorecidoId?: string, modalidadeId?: string, sintetico?: string, ordenacao?: string): Observable<any> {
    let params = new HttpParams().appendAll({
      mesInicial,
      mesFinal
    });

    if (subGrupoId) {
      params = params.append('subGrupos', subGrupoId);
    }

    if (itemId) {
      params = params.append('itens', itemId);
    }

    if (favorecidoId) {
      params = params.append('favorecidos', favorecidoId);
    }

    if (modalidadeId) {
      params = params.append('modalidades', modalidadeId);
    }

    if (sintetico) {
      params = params.append('sintetico', sintetico);
    }

    if (ordenacao) {
      params = params.append('ordenacao', ordenacao)
    }

    const options = { ...this.httpOptions(), params };

    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/relatorio-banco-preco`, options)
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public itemBancoPreco(produtoId: number, unidadeId: number, orgaoId: number, compraId?: number): Observable<number> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/banco-preco/${produtoId}/${unidadeId}/${orgaoId}/${compraId}`, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public planoCompraAnual(parametros: { dtInicial: string, dtFinal: string, orgao_id: number, indFinanceiro: number, indQuant: number, orderBy: string, subGrupo?: string, subElemento?: string, unidade?: string }): Observable<Array<CompraItem>> {

    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/plano-compra-anual/${parametros.dtInicial}/${parametros.dtFinal}/${parametros.subGrupo}/${parametros.subElemento}/${parametros.indFinanceiro}/${parametros.indQuant}/${parametros.orderBy}/${parametros.orgao_id}/${parametros.unidade}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    )
  }

  public atualizarProdutoUnidade(produto_unidade: ProdutoUnidade, produto: { produto: string, unidade: string, compra_id: number }): Observable<void> {
    return this.http.post(
      `${this.login.cidade.id}/${this.api}/atualizar-produto-unidade`, { produto_unidade, produto }, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
