import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { IMaskModule } from 'angular-imask';
import { ToastModule } from 'primeng/toast';
import { SubGrupoEstoqueListComponent } from './subgrupo-estoque-list/subgrupo-estoque-list.component';
import { SubGrupoEstoqueFormComponent } from './subgrupo-estoque-form/subgrupo-estoque-form.component';
import { SubGrupoEstoqueRoutingModule } from './subgrupo-estoque-routing.module';
import { SubGrupoEstoqueViewComponent } from './subgrupo-estoque-view/subgrupo-estoque-view.component';
import { SharedModule } from 'eddydata-lib';
import { SubGrupoSubElementoComponent } from './sub-grupo-sub-elemento/sub-grupo-sub-elemento.component';
import { FieldsetModule } from 'primeng/fieldset';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  declarations: [SubGrupoEstoqueListComponent, SubGrupoEstoqueFormComponent, SubGrupoEstoqueViewComponent, SubGrupoSubElementoComponent],
  imports: [
    CommonModule,
    SubGrupoEstoqueRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    FieldsetModule,
    CheckboxModule
  ]
})
export class SubGrupoEstoqueModule { }
