import { Directive, OnDestroy } from '@angular/core';
import { FuncaoService, GlobalService, Ldo, LoginContabil, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { LdoService } from '../../../../planejamento/ldo/service/ldo.service';
import { SelectItem } from 'primeng/api/selectitem';

@Directive()
export class Demo6Ldo implements OnDestroy {
  public listaOrgaos: SelectItem[];
  public listaLinhas: any;
  private login: LoginContabil = new LoginContabil();
  private dados: any;
  protected funcaoService: FuncaoService;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected ano: number,
    protected ldoServico: LdoService,
    protected orgaos: number[] = [],
    dados?: any,
    listaOrgaos?: SelectItem[],) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
    if (dados) this.dados = dados;
    if (listaOrgaos) this.listaOrgaos = listaOrgaos;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async montarRelatorio() {
    if (!this.dados) {
      this.dados = await this.ldoServico.filtrar({
        tipo$in: 'D60,D61,D62',
        'ppa.id': this.login.ppa.id,
        'ano': this.ano,
        'orgao.id$in': this.orgaos,
        orderBy: 'tipo,linha',
      }).toPromise();

      if (this.dados.content.length) this.dados = this.dados.content;
    }

    const lista60 = [];
    const lista61 = [];
    const lista62 = [];
    const lista = [];

    //Mesma lista do front para deixar bold a linha
    this.listaLinhas = [0, 1, 5, 9, 12, 18, 22, 24, 27, 30, 31, 46, 47, 51,
      55, 60, 63, 63, 67, 69, 72, 75, 76, 86, 88, 92, 93, 101, 106, 107];

    if (this.dados) {
      for (const item of this.dados) {
        if (item.tipo === 'D60') {
          lista60.push(item)
        } else if (item.tipo === 'D61') {
          lista61.push(item)
        } else if (item.tipo === 'D62') {
          lista62.push(item)
        }
      }

      lista.push(this.conteudo60(lista60).concat(this.conteudo61(lista61)).concat(this.conteudo62(lista62)))
    }

    Relatorio.imprimirPersonalizado(
      'AMF/Tabela 6 - DEMONSTRATIVO 6 – AVALIAÇÃO DA SITUAÇÃO FINANCEIRA E ATUARIAL DO RPPS'
      , this.login.usuario.nome, this.login.usuario.sobrenome,
      this.login.orgao.nome, this.login.brasao,
      lista,
      'portrait', 'DEMONSTRATIVO VI - LDO',
      {
        linhas: {
          hLineWidth() {
            return 1;
          },
          vLineWidth() {
            return 1;
          },
          hLineColor() {
            return 'black';
          },
          paddingLeft() {
            return 3;
          },
          paddingRight() {
            return 3;
          }
        }
      });
  }

  private conteudo60(dados: any[]): {}[] {
    let dadosAgrupados = this.funcaoService.agrupar(dados, ['descricao1', 'linha'], ['valor1', 'valor2', 'valor3']);

    const registros: {}[] = [
      [{
        text: `EXERCÍCIO: ${this.ano}`, fontSize: 8,
        alignment: 'left', bold: true, colSpan: 4, border: [false, false, false, false]
      }, '', '', ''],
      [{
        text: `RECEITAS E DESPESAS PREVIDENCIÁRIAS DO REGIME PRÓPRIO DE PREVIDÊNCIA DOS SERVIDORES - RPPS`, fontSize: 9,
        alignment: 'center', bold: true, colSpan: 4
      }, '', '', ''],
      [{
        text: `FUNDO EM CAPITALIZAÇÃO (PLANO PREVIDENCIÁRIO)`, fontSize: 9,
        alignment: 'center', bold: true, colSpan: 4
      }, '', '', ''],

      //cabecalho
      [{
        text: 'RECEITAS PREVIDENCIÁRIAS - RPPS (FUNDO EM CAPITALIZAÇÃO)',
        alignment: 'center',
        bold: true,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      }, {
        text: this.ano - 4,
        alignment: 'center',
        bold: true, fontSize: 8,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      }, {
        text: this.ano - 3,
        alignment: 'center',
        bold: true, fontSize: 8,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      }, {
        text: this.ano - 2,
        alignment: 'center',
        bold: true, fontSize: 8,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      }]
    ];

    // monta o agrupamento do relatório
    for (const item of dadosAgrupados) {
      if (item.registros[0].titulo) {
        registros.push([
          { text: '', border: [false, true, false, false] },
          { text: '', border: [false, true, false, false] },
          { text: '', border: [false, true, false, false] },
          { text: '', border: [false, true, false, false] }
        ]);
      }

      if (item.registros[0].titulo && item.registros[0].linha == 45) {
        registros.push([{
          text: `FUNDO EM REPARTIÇÃO (PLANO FINANCEIRO)`,
          fontSize: 9, alignment: 'center', bold: true, colSpan: 4
        }, '', '', ''])
      }

      if (item.registros[0].titulo && item.registros[0].linha == 84) {
        registros.push([{
          text: `ADMINISTRAÇÃO DO REGIME PRÓPRIO DE PREVIDÊNCIA DOS SERVIDORES - RPPS`,
          fontSize: 9, alignment: 'center', bold: true, colSpan: 4
        }, '', '', ''])
      }

      if (item.registros[0].titulo && item.registros[0].linha == 98) {
        registros.push([{
          text: `BENEFÍCIOS PREVIDENCIÁRIOS MANTIDOS PELO TESOURO`,
          fontSize: 9, alignment: 'center', bold: true, colSpan: 4
        }, '', '', ''])
      }

      if (item.registros[0].titulo) {
        registros.push(
          [{
            text: `${item.registros[0].descricao1}`,
            alignment: 'center',
            bold: true,
            border: [true, true, true, true], fillColor: '#f5f5f5'
          }, {
            text: this.ano - 4,
            alignment: 'center',
            bold: true, fontSize: 8,
            border: [true, true, true, true], fillColor: '#f5f5f5'
          }, {
            text: this.ano - 3,
            alignment: 'center',
            bold: true, fontSize: 8,
            border: [true, true, true, true], fillColor: '#f5f5f5'
          }, {
            text: this.ano - 2,
            alignment: 'center',
            bold: true, fontSize: 8,
            border: [true, true, true, true], fillColor: '#f5f5f5'
          }]
        )
      }

      if (!item.registros[0].titulo) {
        registros.push([
          {
            text: item.registros[0].descricao1, fontSize: 8, border: [true, false, false, false],
            bold: this.listaLinhas.includes(item.registros[0].linha) ? true : false, alignment: 'left'
          },
          {
            text: this.funcaoService.convertToBrNumber(item.totalizadores['valor1']), alignment: 'right',
            fontSize: 8, border: [true, false, false, false], bold: this.listaLinhas.includes(item.registros[0].linha) ? true : false
          },
          {
            text: this.funcaoService.convertToBrNumber(item.totalizadores['valor2']), alignment: 'right',
            fontSize: 8, border: [true, false, false, false], bold: this.listaLinhas.includes(item.registros[0].linha) ? true : false
          },
          {
            text: this.funcaoService.convertToBrNumber(item.totalizadores['valor3']), alignment: 'right',
            fontSize: 8, border: [true, false, true, false], bold: this.listaLinhas.includes(item.registros[0].linha) ? true : false
          }
        ]);
      }
    }

    registros.push([
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] }
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*', 80, 80, 80],
        body: registros
      }
    }];
  }

  private conteudo61(dados: any[]): {}[] {
    let dadosAgrupados = this.funcaoService.agrupar(dados, ['descricao1','linha'], ['valor1', 'valor2', 'valor3', 'valor4']);

    const registros: {}[] = [
      [{
        text: `PROJEÇÃO ATUARIAL DO REGIME PRÓPRIO DE PREVIDÊNCIA DOS SERVIDORES`,
        fontSize: 9, alignment: 'center', bold: true, colSpan: 5
      }, '', '', '', ''],
      [{
        text: `FUNDO EM CAPITALIZAÇÃO (PLANO PREVIDENCIÁRIO)`,
        fontSize: 9, alignment: 'center', bold: true, colSpan: 5
      }, '', '', '', ''],

      //cabecalho
      [{
        text: 'EXERCÍCIO',
        alignment: 'center',
        bold: true,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      }, {
        text: `Receitas Previdenciárias\n(a)`,
        alignment: 'center',
        bold: true, fontSize: 8,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      }, {
        text: `Despesas Previdenciárias\n(b)`,
        alignment: 'center',
        bold: true, fontSize: 8,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      }, {
        text: `Resultado Previdenciário\n(c) = (a-b)`,
        alignment: 'center',
        bold: true, fontSize: 8,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      }, {
        text: `Saldo Financeiro do Exercício\n(d) = (d Exercício Anterior) + (c)`,
        alignment: 'center',
        bold: true, fontSize: 8,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      }]
    ];

    // monta o agrupamento do relatório
    for (const item of dadosAgrupados) {
      registros.push([
        {
          text: item.registros[0].descricao1, fontSize: 8, border: [true, false, false, false], alignment: 'left'
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor1']), alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor2']), alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor3']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor4']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        }
      ]);
    }

    registros.push([
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] }
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*', 80, 80, 80, 90],
        body: registros
      }
    }];
  }

  private conteudo62(dados: any[]): {}[] {
    let dadosAgrupados = this.funcaoService.agrupar(dados, ['descricao1', 'linha'], ['valor1', 'valor2', 'valor3', 'valor4']);

    const registros: {}[] = [
      [{
        text: `FUNDO EM REPARTIÇÃO (PLANO FINANCEIRO)`,
        fontSize: 9, alignment: 'center', bold: true, colSpan: 5
      }, '', '', '', ''],

      //cabecalho
      [{
        text: 'EXERCÍCIO',
        alignment: 'center',
        bold: true,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      }, {
        text: `Receitas Previdenciárias\n(a)`,
        alignment: 'center',
        bold: true, fontSize: 8,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      }, {
        text: `Despesas Previdenciárias\n(b)`,
        alignment: 'center',
        bold: true, fontSize: 8,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      }, {
        text: `Resultado Previdenciário\n(c) = (a-b)`,
        alignment: 'center',
        bold: true, fontSize: 8,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      }, {
        text: `Saldo Financeiro do Exercício\n(d) = (d Exercício Anterior) + (c)`,
        alignment: 'center',
        bold: true, fontSize: 8,
        border: [true, true, true, true], fillColor: '#f5f5f5'
      }]
    ];

    // monta o agrupamento do relatório
    for (const item of dadosAgrupados) {
      registros.push([
        {
          text: item.registros[0].descricao1, fontSize: 8, border: [true, false, false, false], alignment: 'left'
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor1']), alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor2']), alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor3']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor4']), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        }
      ]);
    }

    registros.push([
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] },
      { text: '', border: [false, true, false, false] }
    ]);

    if (this.listaOrgaos?.length > 0) {
      registros.push([{ text: '\n\n', colSpan: 5, border: [false, false, false, false] }, '', '', '', '']);
      registros.push([{
        text: this.listaOrgaos.filter(o => this.orgaos.some(org => org === o.value))
          .map(o => o.label).join('\n'), fontSize: 8, colSpan: 5, border: [false, false, false, false]
      }, '', '', '', '']);
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*', 80, 80, 80, 90],
        body: registros
      }
    }];
  }
}
