// tslint:disable: variable-name
import { ReceitaPrevista } from 'eddydata-lib';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Credito } from './credito.model';
import { FichaDespesa } from './ficha-despesa.model';

export class CreditoItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public aux?: number,
    public especie?: string,
    public gps?: string,
    public data_contabilizacao?: Date,
    public valor_credito?: number,
    public ficha?: FichaDespesa,
    public credito?: Credito,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public editavel?: boolean,
    public empenhado?: number,
    public dotacao?: number,
    public previsoes?: ReceitaPrevista[]
  ) {
    super();
  }

  static converteJson(json: any): CreditoItem {
    return Object.assign(new CreditoItem(), json);
  }
}
