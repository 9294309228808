import { Injectable, Injector } from "@angular/core";
import { BaseResourceService, PedidoCompraItem } from "eddydata-lib";

@Injectable({
  providedIn: 'root'
})
export class PedidoCompraItemService extends BaseResourceService<PedidoCompraItem> {

  constructor(
    protected injector: Injector
  ) {
    super(`pedido-compra-itens`, injector);
  }
}