import { Component, Injector, OnInit } from '@angular/core';
import {
  BaseResourceListComponent, Coluna, Converter, Filtro, FormatoExportacao, FuncaoService, GlobalService, LoginContabil, ParametroAlmoxarifadoService, RequisicaoMovimento, StatusRequisicao
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { RequisicaoMovimentoService } from '../service/requisicao-movimento.service';

@Component({
  selector: 'lib-requisicao-list',
  templateUrl: './requisicao-list.component.html'
})
export class RequisicaoListComponent extends BaseResourceListComponent<RequisicaoMovimento, LoginContabil> implements OnInit {

  /**
   * Declaração de variáveis
   */
  public mesEncerrado: boolean = false;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public funcaoService: FuncaoService,
    public globalService: GlobalService,
    private parametroAlmoxarifadoService: ParametroAlmoxarifadoService,
    private requisicaoMovService: RequisicaoMovimentoService) {
    super(requisicaoMovService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(item: RequisicaoMovimento): boolean {
    return (item.status === 'A' || item.status === 'AF') && !this.mesEncerrado;
  }

  protected relations(): string {
    return 'usuario,setor_almoxarifado,setor_almoxarifado.setor,setor_almoxarifado.estoque,exercicio,orgao,usuario,recebedor,itens,itens.produto_unidade';
  }

  protected condicoesGrid(): {} {

    let setorIds = this.login.setor.map((setor) => {
      return setor.id
    })

    let parametros = {}
    parametros = {
      ['orgao.id']: this.login.orgao.id,
      ['exercicio.id']: this.login.exercicio.id,
    }

    if (!(this.login.usuario.administrador && this.login.usuario.sistemas_administrador?.includes('almoxarifado')) && this.login?.estoque?.id) {
      parametros['setor_almoxarifado.estoque.id'] = this.login.estoque.id;
      parametros['setor_almoxarifado.setor.id$in'] = setorIds;
    }

    return parametros
  }

  protected ordenacaoGrid(): string[] {
    return ['data_cadastro$DESC', 'id$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['codigo', 'id'],
      date: ['data_cadastro'],
      text: ['usuario.nome', 'recebedor.nome'],
    };
  }

  protected afterInit(): void {
    this.parametroAlmoxarifadoService.mesFechadoAlmoxarifadoa(this.login, this.login.setorAlmoxarifado ?
      this.login.setorAlmoxarifado.estoque.id : this.login.estoque.id).then((valid) => {
        this.mesEncerrado = valid;
      });
  }

  protected acaoRemover(model: RequisicaoMovimento): Observable<RequisicaoMovimento> {
    return null;
  }


  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Nº RRM', coluna: 'codigo', alignment: 'center' },
      { titulo: 'Data Requisição', coluna: 'data_cadastro' },
      { titulo: 'Setor', coluna: 'setor_almoxarifado.setor.nome' },
      { titulo: 'Usuário', coluna: 'usuario.nome' },
      {
        titulo: 'Status', coluna: 'status', converter: this.globalService.statusRequisicao()
          .map((s): Converter => {
            return {
              id: s,
              nome: this.globalService.tituloStatusRequisicao(s)
            };
          }), alignment: 'center'
      },
      {
        titulo: 'tipo', coluna: 'devolucao', converter: [
          { id: false, nome: 'RETIRADA' },
          { id: true, nome: 'DEVOLUÇÃO' }
        ], alignment: 'center'
      }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = this.relations();
    parametros['orderBy'] = this.ordenacaoGrid().join(',');
    this.requisicaoMovService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (lista.content && lista.content.length > 0)
            lista.content = lista.content.map((l) => {
              l.data_cadastro = new FuncaoService().converteDataBR(new Date(l.data_cadastro));
              return l;
            });
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE REQUISIÇÕES',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem Requisicões', ['auto', 'auto', '*', '*', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }


  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public acaoError(error) {
    if (error.error && error.error.payload) {
      toastr.error(error.error.payload);
    } else {
      toastr.error('Ocorreu um erro ao processar a sua solicitação');
    }
  }

  public tituloStatusRequisicao(status: StatusRequisicao): string {
    return this.globalService.tituloStatusRequisicao(status);
  }

  public corStatusRequisicao(status: StatusRequisicao): {} {
    let cor = this.globalService.corStatusRequisicao(status);
    let ngCor = {};
    ngCor[`badge-${cor}`] = true;
    return ngCor;
  }

  public novo() {
    if (this.mesEncerrado) {
      toastr.warning('Não é possível incluir nova requisição com o mês encerrado!');
      return;
    }
    this.router.navigate([`/requisicoes-materiais/novo`]);
  }

}
