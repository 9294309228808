import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'lib-chart',
  templateUrl: './chart.component.html'
})
export class ChartComponent implements AfterViewInit, OnDestroy {

  @Input() type: string;

  @Input() plugins: any[] = [];

  @Input() width: string;

  @Input() height: string;

  @Input() responsive: boolean = true;

  @Output() onDataSelect: EventEmitter<any> = new EventEmitter();

  _data: any;

  _options: any = {};

  chart: any;

  constructor(public el: ElementRef) { }

  @Input() get data(): any {
    return this._data;
  }

  set data(val: any) {
    this._data = val;
    this.reinit();
  }

  @Input() get options(): any {
    return this._options;
  }

  set options(val: any) {
    this._options = val;
    this.reinit();
  }

  ngAfterViewInit() {
    this.initChart();
  }

  onCanvasClick(event: any) {
    if (this.chart) {
      const element = this.chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
      const dataset = this.chart.getElementsAtEventForMode(event, 'dataset', { intersect: true }, false);

      if (element && element[0] && dataset) {
        this.onDataSelect.emit({ originalEvent: event, element: element[0], dataset: dataset });
      }
    }
  }

  initChart() {
    let opts = this.options || {};
    opts.responsive = this.responsive;

    if (opts.responsive && (this.height || this.width)) {
      opts.maintainAspectRatio = false;
    }

    this.chart = new Chart(this.getCanvas(), {
      type: <any>this.type,
      data: this.data,
      options: this.options
    });
  }

  getCanvas() {
    return this.el.nativeElement.children[0].children[0];
  }

  reinit() {
    if (this.chart) {
      this.chart.destroy();
      this.initChart();
    }
  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
}