// tslint:disable: variable-name
import { PpaPeriodo } from './ppa-periodo.model';
import { PpaLei } from './ppa-lei.model';
import { BaseResourceModel } from '../../models/base-resource.model';

export class Ppa extends BaseResourceModel {
  constructor(
    public id?: number,
    public descricao?: string,
    public data_publicacao?: Date,
    public data_vigencia?: Date,
    public legislacao?: string,
    public lei_numero?: string,
    public modalidade?: string,
    public ativo?: boolean,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public percentual_suplementacao?: number,
    public ppaperiodo?: PpaPeriodo,
    public ppaleis?: PpaLei[]
  ) {
    super();
  }

  static converteJson(json: any): Ppa {
    return Object.assign(new Ppa(), json);
  }
}
