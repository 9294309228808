import { Component, Injector } from '@angular/core';
import { AdiantamentoService, EmpenhoService } from 'administrativo-lib';
import { Adiantamento, BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, FuncaoService, LoginContabil } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-adiantamento-list',
  templateUrl: './adiantamento-list.component.html'
})
export class AdiantamentoListComponent extends BaseResourceListComponent<Adiantamento, LoginContabil> {

  public adiantamento: Adiantamento;

  constructor(
    protected injector: Injector,
    protected empenhoService: EmpenhoService,
    protected funcaoService: FuncaoService,
    private adiantaService: AdiantamentoService) {
    super(adiantaService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(_entidade: Adiantamento): boolean {
    return (!_entidade.data_termino || !_entidade.data_prestacao) && this.login.sistema != 'controle-interno'
  }

  protected relations(): string {
    return 'empenho.favorecido';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id,
      ['exercicio.id']: this.login.exercicio.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['data_adiantamento$DESC', 'numero$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['numero', 'empenho.numero', 'valor_adiantado'],
      date: ['data_adiantamento', 'data_vencimento'],
      text: ['beneficiario'],
    };
  }

  protected afterInit(): void {
  }

  public beforeInit(): void {
    this.usarFiltroPersonalizado = true;
    this.usarExtendido = true;
  }

  protected acaoRemover(model: Adiantamento): Observable<Adiantamento> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Data do adiantamento:', coluna: 'data_adiantamento', agrupar: true, bold: true },
      { titulo: 'Número', coluna: 'numero' },
      { titulo: 'N° Empenho', coluna: 'empenho.numero' },
      { titulo: 'Beneficiario', coluna: 'beneficiario' },
      { titulo: 'CNPJ/CPF', coluna: 'empenho.favorecido.cpf_cnpj' },
      { titulo: 'Historico', coluna: 'motivo' },
      { titulo: 'Vencimento', coluna: 'data_vencimento' },
      { titulo: 'Valor', coluna: 'valor_adiantado', alignment: 'right', decimais: 2 }
    ];
  }

  public obterColunasfiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];

    retorno.push({ titulo: 'Adiantamento', coluna: 'data_adiantamento', tipo: 'Date', padrao: true });
    retorno.push({ titulo: 'Vencimento', coluna: 'data_vencimento', tipo: 'Date' });
    retorno.push({ titulo: 'N° Empenho', coluna: 'empenho.numero', tipo: 'Number' });
    retorno.push({ titulo: 'N° Processo', coluna: 'numero', tipo: 'Number', padrao: true });
    retorno.push({ titulo: 'Beneficiário', coluna: 'beneficiario', tipo: 'String' });
    retorno.push({ titulo: 'CNPJ/CPF', coluna: 'empenho.favorecido.cpf_cnpj', tipo: 'String' });
    retorno.push({ titulo: 'Valor', coluna: 'valor', tipo: 'Number', padrao: true });
    retorno.push({ titulo: 'Prestado conta', coluna: 'data_prestacao$not_null', tipo: 'Boolean' });
    retorno.push({ titulo: 'Em Aberto', coluna: 'data_prestacao$null,data_termino$null', tipo: 'Boolean' });
    retorno.push({ titulo: 'Encerrado', coluna: 'data_prestacao$not_null,data_termino$not_null', tipo: 'Boolean' });

    return retorno;
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'empenho.favorecido';
    this.adiantaService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE ADIANTAMENTOS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem adiantamentos', ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'], lista.content, ['valor_adiantado']);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public prestacao(item: Adiantamento) {
    this.adiantamento = item;
  }

}
