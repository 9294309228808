import { Injectable, Injector } from "@angular/core";
import { BaseResourceService, Lei } from "eddydata-lib";

@Injectable({
  providedIn: 'root'
})
export class LeiService extends BaseResourceService<Lei> {

  constructor(
    protected injector: Injector
  ) {
    super(`leis`, injector);
  }

}