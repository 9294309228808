import { Banco } from '../banco';
import { Boleto } from '../boleto';

export class CaixaEconomica extends Banco {
    public static qtdeDigitoNossoNumero = 15;
    //    private final String inicioNossoNumero = '875';
    private indetSistema = '';
    private dv = null;
    private dv1 = null;
    private codigoBarras = null;
    private campoLivre = null;


    public getNumero() {
        return '104';
    }

    public getNumeroSemDigito() {
        return '104';
    }


    public getDvNumero() {
        return '-0';
    }


    public setBoleto(boleto: Boleto) {
        super.setBoleto(boleto);

        // adicionando o inicio para o nosso numero
        this.boleto.setNossoNumero(boleto.getNossoNumero(), CaixaEconomica.qtdeDigitoNossoNumero);
        // **********************

        let calcdv = this.boleto.getContaCorrente().substring(6) + this.boleto.getAgencia() + this.boleto.getNossoNumero().substring(0, 2);

        this.dv1 = this.modulo_11(calcdv);

        // Calculando digito verificador
        let campo = this.getNumeroSemDigito() + this.boleto.getMoeda() + this.boleto.getFatorVencimento() +
            this.boleto.getValorTitulo() + this.boleto.getContaCorrente().substring(6) + this.boleto.getAgencia() +
            this.boleto.getContaCorrente().substring(0, 2) + this.boleto.getNossoNumero().substring(1);
        this.dv = this.boleto.getDigitoCodigoBarras(campo);
        // **********************

        // Montando o codigo de barras
        this.codigoBarras = this.getNumeroSemDigito() + this.boleto.getMoeda() + this.dv + this.boleto.getFatorVencimento() +
            this.boleto.getValorTitulo() + this.boleto.getContaCorrente().substring(6) + campo.substring(23);
        // **********************
        // Adicionandeo o DV para o nosso numero
        let dvNossoNumero = this.getDvNossoNumero(this.boleto.getNossoNumero());
        this.boleto.setNossoNumero(boleto.getNossoNumero() + '-' + dvNossoNumero);
        // **********************

        //definindo o campolivre
        this.campoLivre = this.codigoBarras.substring(19);
        // **********************
    }

    private getCampo1() {
        let campo = this.getNumeroSemDigito() + this.boleto.getMoeda() + this.boleto.getContaCorrente().substring(6);
        return this.boleto.getDigitoCampo(campo);
    }

    private getCampo2() {
        let campo = this.codigoBarras.substring(24, 34);
        return this.boleto.getDigitoCampo(campo);
    }

    /**
     * ver documentacao do banco para saber qual a ordem deste campo
     */
    private getCampo3() {
        let campo = this.campoLivre.substring(15);
        return this.boleto.getDigitoCampo(campo);
    }

    private getCampo4() {
        return this.dv;
    }

    private getCampo5() {
        let campo = this.boleto.getFatorVencimento() + this.boleto.getValorTitulo();
        return campo;
    }


    public getCodigoBarras() {
        return this.codigoBarras;
    }


    public getLinhaDigitavel() {
        return this.getCampo1().substring(0, 5) + '.' + this.getCampo1().substring(5) + '  ' +
            this.getCampo2().substring(0, 5) + '.' + this.getCampo2().substring(5) + '  ' +
            this.getCampo3().substring(0, 5) + '.' + this.getCampo3().substring(5) + '  ' +
            this.getCampo4() + '  ' + this.getCampo5();
    }

    public modulo_11(numero: String) {

        let total = 0;
        let temp;
        let fator = 2;
        let numeros;

        for (let i = numero.length; i > 0; i--) {

            numeros = Number.parseInt(numero.substring(i - 1, i));

            temp = numeros * fator;

            total += temp;

            if (fator < 9)
                fator = fator + 1;
            else
                fator = 2;

        }

        let resto = total % 11;
        resto = 11 - resto;
        if (resto > 9) {
            resto = 0;
        }
        return resto.toString();
    }

    public getDvNossoNumero_(numext: string) {

        let pesos = '29876543298765432';
        let total = 0; 
        let numeros;
        let peso;

        for (let i = numext.length; i > 0; i--) {

            numeros = Number.parseInt(numext.substring(i - 1, i));
            peso = Number.parseInt(pesos.substring(i - 1, i));

            total += numeros * peso;
        }

        let resto = total % 11;
        resto = 11 - resto;
        if (resto > 9) {
            resto = 0;
        }
        return resto.toString();
    }

    public getDvNossoNumero(numext: string) {
        let peso = 2;
        let total = 0;
        let numeros = new Array<number>();

        for (let i = numext.length; i > 0; i--) {

            numeros[i] = Number.parseInt(numext.substring(i - 1, i)) * peso;

            peso++;
            if (peso == 10)
                peso = 2;
        }

        for (let i = numext.length; i > 0; i--) {
            total = total + numeros[i];
        }

        let resto = total % 11;
        resto = 11 - resto;
        if (resto > 9) {
            resto = 0;
        }
        return resto.toString();
    }

    private getModalidade(modalidade: string) {

        if (modalidade === '01') {
            modalidade = '1';
        } else if (modalidade === '04') {
            modalidade = '4';
        } else if (modalidade === '09') {
            modalidade = '9';
        } else if (modalidade === '13') {
            modalidade = '3';
        } else if (modalidade === '16') {
            modalidade = '6';
        } else if (modalidade === '17') {
            modalidade = '7';
        } else if (modalidade === '18') {
            modalidade = '8';
        }
        return modalidade;
    }

    /**
     * Recupera a carteira no padrao especificado pelo banco
     * @author Gladyston Batista/Eac Software
     */

    public getCarteiraFormatted() {
        return this.boleto.getCarteira();
    }

    /**
     * Recupera a agencia / codigo cedente no padrao especificado pelo banco
     * @author Gladyston Batista/Eac Software
     */

    public getAgenciaCodCedenteFormatted() {
        return this.boleto.getAgencia() + '/' + this.boleto.getContaCorrente() + ' ' + this.boleto.getDvContaCorrente();
    }

    /**
     * Recupera a nossoNumero no padrao especificado pelo banco
     * @author Gladyston Batista/Eac Software
     */

    public getNossoNumeroFormatted() {
        return this.boleto.getNossoNumero();
    }
}