// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';

export class ParametroCompra extends BaseResourceModel {
  constructor(
    public id?: number,
    public valor_limite_dispensacao_subelemento?: number,
    public valor_limite_dispensacao_engenharia_subelemento?: number,
    public valor_limite_dispensacao_favorecido?: number,
    public valor_limite_dispensacao_engenharia_favorecido?: number,
    public dispensacao_subelemento?: boolean,
    public dispensacao_favorecido?: boolean,
    public autorizar_dispensa?: boolean,
    public email_requerente?: string,
    public email_favorecido?: string,
    public email_requerente_pedido?: string,
    public email_favorecido_pedido?: string,
    public mensagem_of?: string,
    public observacao_of?: string,
    public mensagem_pedido?: string,
    public priorizar_reservado?: boolean,
    public bloqueio_razaosocial?: boolean,
    public email_retorno?: string,
    public meses_validos_banco_preco?: number,
    public gerar_empenho_com_of?: boolean
  ) {
    super();
  }

  static converteJson(json: any): ParametroCompra {
    return Object.assign(new ParametroCompra(), json);
  }
}
