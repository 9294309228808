
import { Component, ElementRef, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { ContratoService, LicitacaoService, MemorialService, ProponenteService } from 'administrativo-lib';
import {
  BaseResourceFormComponent, CriterioJulgamento, Favorecido, FuncaoService, GlobalService, Licitacao,
  LoginContabil,
  Memorial,
  Proponente,
  Proposta,
  SituacaoProposta
} from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-julgamento',
  templateUrl: './julgamento.component.html',
  styleUrls: ['./julgamento.component.css']
})
export class JulgamentoComponent extends BaseResourceFormComponent<Licitacao, LoginContabil> implements OnInit {

  /**
   * Declaração de variáveis
   */
  public criteriosJulgamento: { id: CriterioJulgamento, nome: string }[];
  private situacoesProposta: { id: SituacaoProposta, nome: string, style: string, ordem: number }[];
  public memorialEmpate: Memorial;
  public itensEmpatados: Proposta[];
  public itensZerar: { memorial: Memorial, marcado: boolean }[];
  private julgado = false;
  public contratoGerado = false;
  public edicaoContratoExcluido = false;
  public itensExcluido: Memorial[] = [];

  public visualizarMotivoDesclassificacao: boolean = false;
  public propostaDesclassificada: Proposta;
  public memorialPropostaDesclassificada: Memorial;
  public apenasVisualizarMotivoDesclassificacao: boolean = false;

  /**
   * Construtor com as injeções de dependencias
   */

  constructor(
    protected injector: Injector,
    protected confirmationService: ConfirmationService,
    public funcaoService: FuncaoService,
    private messageService: MessageService,
    protected globalService: GlobalService,
    protected memorialService: MemorialService,
    protected proponenteService: ProponenteService,
    protected contratoService: ContratoService,
    protected licitacaoService: LicitacaoService) {
    super(new Licitacao(), injector, Licitacao.converteJson, licitacaoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================
  protected setCurrentActionRoute() {
    this.currentActionRoute = 'editar';
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      formato_contratacao: ['REGISTRO_PRECO', [Validators.required]],
      justificativa: ['', [Validators.required]],
      data_inicio: [null],
      data_termino: [null],
      prazo_pagamento: [null],
      prazo_entrega: [null],
      numero: [null, [Validators.required]],
      sequencia: [1],
      data_abertura: [null, [Validators.required]],
      data_ata: [null],
      data_edital: [null],
      data_julgamento: [null],
      data_parecer: [null],
      data_publicacao: [null],
      data_homologacao: [null],
      data_cancelamento: [null],
      data_assinatura: [null],
      data_ratificacao: [null],
      data_abertura_env: [null],
      data_entrega_env: [null],
      situacao: ['ATIVA', [Validators.required]],
      processo: [null, [Validators.required]],
      objeto: [null, [Validators.required]],
      valor_estimado: [0],
      valor_licitado: [0],
      enviar_transparencia: [false, [Validators.required]],
      chamamento_publico: [false, [Validators.required]],
      criterio_julgamento: ['MENOR_PRECO', [Validators.required]],
      situacao_pregao: [null],
      tipo_pregao: [null],
      criterio_desempate: [null],
      fracassar_auto: [true, [Validators.required]],
      modelo_ata: [null],
      natureza: [null],
      tipo_licitacao: [null, [Validators.required]],
      tabela_desconto: [null],
      objeto_licitacao: [null],
      lei_1232006: [null],
      credenciamento: [null],
      tipo_objeto: [null],
      modalidade: [null, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      setor: [null],
      exercicio: [this.login.exercicio, [Validators.required]],
      comissao: [null],
      tipo_contratacao: [null],
      solicitante: [null],
      processo_cc: [null],
      orgao_audesp: [null],
      ordem_alfabetica_memorial: [false],
      data_inicio_proposta: [null],
      data_fim_proposta: [null],
      data_inicio_pregao: [null],
      prazo_proposta: [null],
      forma_participacao: [null],
      tipo_disputa: [null],
      itens: [],
      proponentes: [],
      fiscais: [],
      ocorrencias: [],
      comissao_membros: [],
      modo_disputa: [0],
      amparo_legal: [0],
      informacao_complementar: [''],
      justificativa_presencial: [''],
      link_origem_lance: [''],
      edital: [null],
      cadastrado_pncp: [false]
    });
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected afterLoad() {
    if (this.entidade.modalidade.codigo === 7) {
      this.router.navigate(['/pregoes', this.entidade.id, 'editar']);
    } else {
      this.memorialService.obterPorLicitacao(this.entidade.id, true).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.itensZerar = [];
          this.entidade.itens = res ? res.content : [];
          this.entidade.itens.forEach(memorial => {
            this.ordenarPropostas(memorial.propostas);
            this.itensZerar.push({ marcado: true, memorial })
          })
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));

      this.proponenteService.obterPorLicitacao(this.entidade.id, false).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.entidade.proponentes = res ? res.content : [];
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));

      this.contratoService.obter({ licitacao_id: this.entidade.id, orgao_id: this.login.orgao.id }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.contratoGerado = new Boolean(res).valueOf()
          if (this.contratoGerado) {
            this.verificarContratoExcluido();
          }
        }, error => this.funcaoService.acaoErro(error));
    }
  }

  protected parametrosExtras(): {} {
    return { relations: 'modalidade,orgao,setor,exercicio,comissao,tipo_contratacao,fiscais.pessoa,prazo_pagamento,prazo_entrega,orgao_audesp' };
  }

  protected afterInit(): void {
    this.criteriosJulgamento = this.globalService.obterCriteriosJulgamento();
    this.situacoesProposta = this.globalService.obterSituacoesProposta();
  }

  protected async beforeSubmit() {
    await this.removerPreferenciaNaoExercida()
    try {
      this.entidadeForm.get('itens').setValue(this.entidade.itens);
      this.entidadeForm.get('fiscais').setValue(this.entidade.fiscais);
      this.entidadeForm.get('proponentes').setValue(this.entidade.proponentes);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(ent: Licitacao) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public obterNomeProposta(item: Proposta): string {
    try {
      return this.situacoesProposta.find(p => p.id === item.situacao).nome;
    } catch (err) {
      return item.situacao;
    }
  }

  public obterEstiloProposta(item: Proposta): string {
    try {
      return this.situacoesProposta.find(p => p.id === item.situacao).style;
    } catch (err) {
      return 'secondary';
    }
  }

  public zerarJulgamento() {
    if (this.contratoGerado && !this.edicaoContratoExcluido) {
      toastr.warning('Já existe contrato gerado para este processo')
      return;
    }

    const itensMarcados = this.itensZerar.filter(m => m.marcado);
    if (itensMarcados.length === 0) {
      toastr.warning('Nenhum item foi marcado para zerar os julgamentos!');
      return;
    }

    itensMarcados.map(m => m.memorial).forEach(memorial => {
      this.removePreferencia(memorial)
      memorial.propostas.forEach(proposta => {
        proposta.situacao = 'NAO_JULGADO';
        proposta.quantidade_final = undefined;
        proposta.valor_final = 0;
        // proposta.motivo = null;
        proposta.motivoDesclassificacao = null;

      })
    }
    )

    toastr.success('Julgamentos zerados com sucesso!');
    $('#dialogZerarJulgamento').modal('hide');
  }

  public toggleItensJulgamento(marcar: boolean) {
    this.itensZerar.forEach(m => m.marcado = marcar);
  }

  public async julgarItens() {
    if (this.contratoGerado && !this.edicaoContratoExcluido) {
      toastr.warning('Já existe contrato gerado para este processo')
      return;
    }

    try {
      if (this.julgado) {
        this.confirmationService.confirm({
          message: 'Processo já julgado, deseja julgá-lo novamente?',
          header: 'Julgamento',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Confirmar',
          rejectLabel: 'Cancelar',
          accept: async () => {
            await this.julgarPropostas();
          }
        })
      } else {
        await this.julgarPropostas();
      }
    } catch (err) {
      toastr.error('Não foi possível realizar julgamento dos itens!');
    }
  }

  private async julgarPropostas() {
    // percorre item a item
    this.entidade.itens.forEach(async memorial => {
      if (this.edicaoContratoExcluido && memorial['editar']) {
        // atribui situações padrões (CLASSIFICADO, NAO_CLASSIFICADO ou DESCLASSIFICADO) para todas propostas que nao foram previamente desclassificadas
        memorial.propostas.filter(p => p.situacao !== 'DESCLASSIFICADO')
          .forEach(proposta => {
            proposta.data_homologacao = new Date();
            if (proposta.proponente.situacao !== 'HABILITADO') {
              proposta.situacao = 'DESCLASSIFICADO';
              proposta.quantidade_final = undefined;
              proposta.valor_final = 0;
            } else if (this.entidadeForm.get('fracassar_auto').value && +memorial.valor_referencia > 0
              && +proposta.valor_unitario > +memorial.valor_referencia) {
              proposta.situacao = 'NAO_CLASSIFICADO';
              proposta.quantidade_final = undefined;
              proposta.valor_final = 0;
            } else {
              proposta.situacao = 'CLASSIFICADO';
              proposta.quantidade_final = undefined;
              proposta.valor_final = 0;
            }
          });

        // ordena itens por valor e situação
        await this.ordenarPropostas(memorial.propostas);

        // atribui vencedores ou empates
        await this.definirVencedores(memorial);

        // atualiza vencedores e empates
        await this.atualizarVencedores(memorial);

        // registra quando proponente exerceu ou não seu direito de prefrência
        await this.verificaDireitoPreferencia(memorial);
      } else if (!this.edicaoContratoExcluido) {
        memorial.propostas.filter(p => p.situacao !== 'DESCLASSIFICADO')
          .forEach(proposta => {
            proposta.data_homologacao = new Date();
            if (proposta.proponente.situacao !== 'HABILITADO') {
              proposta.situacao = 'DESCLASSIFICADO';
              proposta.quantidade_final = undefined;
              proposta.valor_final = 0;
            } else if (this.entidadeForm.get('fracassar_auto').value && +memorial.valor_referencia > 0
              && +proposta.valor_unitario > +memorial.valor_referencia) {
              proposta.situacao = 'NAO_CLASSIFICADO';
              proposta.quantidade_final = undefined;
              proposta.valor_final = 0;
            } else {
              proposta.situacao = 'CLASSIFICADO';
              proposta.quantidade_final = undefined;
              proposta.valor_final = 0;
            }
          });

        // ordena itens por valor e situação
        await this.ordenarPropostas(memorial.propostas);

        // atribui vencedores ou empates
        await this.definirVencedores(memorial);

        // atualiza vencedores e empates
        await this.atualizarVencedores(memorial);

        // registra quando proponente exerceu ou não seu direito de prefrência
        await this.verificaDireitoPreferencia(memorial);
      }
    });

    this.julgado = true;
    toastr.success('Itens Julgados com sucesso!');
  }

  private async definirVencedores(memorial: Memorial) {
    // obtem menor valor das propostas previamente ordenadas
    const valor_vencedor = memorial.propostas[0].valor_unitario;

    // obtem vencedores (situacao = 'CLASSIFICADO' e valor unitario = menor valor)
    const vencedores = memorial.propostas.filter(p => p.situacao === 'CLASSIFICADO' && p.valor_unitario === valor_vencedor);

    // atribui situacao VENCEDOR caso tenha 1 vencedor e EMPATADO caso tenha mais de 1
    vencedores.forEach(vencedor => {
      if (vencedores.length > 1) {
        vencedor.situacao = 'EMPATADO';
        vencedor.quantidade_final = 0;
        vencedor.valor_final = 0;
      } else {
        vencedor.situacao = 'VENCEDOR';
        vencedor.quantidade_final = memorial.quantidade;
        vencedor.valor_final = vencedor.valor_unitario;
      }
    });
  }

  public async verificaDireitoPreferencia(memorial: Memorial) {
    const criterio = this.obterCriterio()
    await this.removePreferencia(memorial)

    const vencedor = memorial.propostas.find((p: Proposta) => p.situacao === 'VENCEDOR')

    if (criterio === 'MENOR_PRECO' || criterio === 'MENOR_DESCONTO') {
      memorial.propostas.sort((a, b) => { return a.valor_unitario - b.valor_unitario })
      if (vencedor.proponente.epp_me && (+vencedor.valor_unitario >= +memorial.propostas[0].valor_unitario) && !(vencedor.id === memorial.propostas[0].id)) {
        vencedor.exerceu_preferencia = true
      }
    } else if (criterio === 'MAIOR_DESCONTO' || criterio === 'MAIOR_PRECO') {
      memorial.propostas.sort((a, b) => { return b.valor_unitario - a.valor_unitario })
      if (vencedor.proponente.epp_me && (+vencedor.valor_unitario <= +memorial.propostas[0].valor_unitario) && !(vencedor.id === memorial.propostas[0].id)) {
        vencedor.exerceu_preferencia = true
      }
    }

    memorial.propostas.sort((a, b) => { return this.situacoesProposta.find(s => s.id === a.situacao).ordem - this.situacoesProposta.find(s => s.id === b.situacao).ordem });
  }

  public legendarPreferencia(proposta: Proposta) {
    if (proposta.proponente.epp_me && proposta.exerceu_preferencia && proposta.situacao === 'VENCEDOR') {
      return 'Dir. Preferência'
    } else if (proposta.proponente.epp_me && proposta.exerceu_preferencia && proposta.situacao !== 'VENCEDOR') {
      return 'Não exerceu Dir. Preferência'
    } else {
      return ''
    }
  }

  public async removerPreferenciaNaoExercida() {
    this.entidade.itens.forEach((memorial: Memorial) => {
      memorial.propostas.forEach((proposta: Proposta) => {
        if (proposta.proponente.epp_me && proposta.exerceu_preferencia && proposta.situacao !== 'VENCEDOR') {
          proposta.exerceu_preferencia = false
        }
      })
    })
  }

  public async removePreferencia(memorial: Memorial) {
    for (let proposta of memorial.propostas) {
      proposta.exerceu_preferencia = false
    }
  }

  private async ordenarPropostas(propostas: Proposta[]) {
    const criterio = this.obterCriterio();

    // ordena propostas pela ordenação da situação e por valor, respectivamente

    if (criterio === 'MENOR_PRECO' || criterio === 'MENOR_DESCONTO') {
      // pro menor preço: o ME/EPP ganha mesmo se o valor dele tiver igual ou até 5% maior que o menor valor

      propostas.sort((a, b) => {

        if (a.proponente.epp_me && !b.proponente.epp_me) {
          return (a.valor_unitario * 0.95) - b.valor_unitario
        } else if (b.proponente.epp_me && !a.proponente.epp_me) {
          return a.valor_unitario - (b.valor_unitario * 0.95)
        } else {
          return a.valor_unitario - b.valor_unitario
        }
      })
    }
    else if (criterio === 'MAIOR_PRECO' || criterio === 'MAIOR_DESCONTO') {
      // pro maior preço: o ME/EPP ganha mesmo se o valor dele tiver igual ou até 5% menor que o maior valor

      propostas.sort((a, b) => {

        if (a.proponente.epp_me && !b.proponente.epp_me) {
          return (b.valor_unitario * 0.95) - a.valor_unitario
        } else if (!a.proponente.epp_me && b.proponente.epp_me) {
          return b.valor_unitario - (a.valor_unitario * 0.95)
        } else {
          return b.valor_unitario - a.valor_unitario
        }
      })
    }
    else
      throw new Error(`Critério de julgamento ${criterio} não disponível`)

    propostas.sort((a, b) => { return this.situacoesProposta.find(s => s.id === a.situacao).ordem - this.situacoesProposta.find(s => s.id === b.situacao).ordem });
  }

  public async atribuirSituacao(proposta: Proposta, memorial: Memorial, situacao: SituacaoProposta) {
    if (this.contratoGerado && !this.edicaoContratoExcluido) {
      toastr.warning('Já existe contrato gerado para este processo')
      return;
    }

    if (proposta && situacao) {
      // situações permitidas
      if (situacao === 'VENCEDOR' || situacao === 'DESCLASSIFICADO' || situacao === 'CLASSIFICADO') {

        // se vai atribuir VENCEDOR, passa todos vencedores atuais para CLASSIFICADO
        if (situacao === 'VENCEDOR') {
          memorial.propostas.filter(p => p.situacao === 'VENCEDOR').forEach(p => {
            p.situacao = 'CLASSIFICADO';
            p.quantidade_final = undefined;
            p.valor_final = 0;
          });

          // vencedores tem a quantidade total do item
          proposta.quantidade_final = memorial.quantidade;
          proposta.valor_final = proposta.valor_unitario;
        } else {
          // outras situações não tem nenhuma quantidade
          proposta.quantidade_final = undefined;
          proposta.valor_final = 0;
        }

        // atribui situação
        proposta.situacao = situacao;
        proposta.data_homologacao = new Date();
        if (proposta.situacao !== 'DESCLASSIFICADO')
          proposta.motivoDesclassificacao = null;

        // atualiza vencedores e empates e reordena
        await this.atualizarVencedores(memorial);
        await this.ordenarPropostas(memorial.propostas);
      }
    }
  }

  /**
   * Regras para vencedores e empates
   * 
   * A - Caso exista apenas 1 vencedor por empate, o mesmo se torna vencedor único
   * B - Caso existam vencedores por empate porém a quantidade é diferente do total do item, ambos voltam para EMPATADO
   * C - Caso existam empates e apenas 1 vencedor, todos empates voltam para CLASSIFICADO
   * D - Caso existam mais de um vencedor e nenhum empatado, todos vão para EMPATADO
   * E - Caso exista apenas um empate, o mesmo vai para VENCEDOR
   * F - Caso não existam nem vencedores nem empates, a próxima proposta como CLASSIFICADO vai para VENCEDOR
   * 
   * @param memorial 
   */
  private async atualizarVencedores(memorial: Memorial) {
    // obtem qtde de vencedores e empates para validações
    const vencedores = memorial.propostas.filter(p => p.situacao === 'VENCEDOR');
    const vencedoresEmpate = memorial.propostas.filter(p => p.situacao === 'VENCEDOR' && +p.quantidade_final < +memorial.quantidade);
    const empates = memorial.propostas.filter(p => p.situacao === 'EMPATADO');

    let totalEmpate = 0;
    if (vencedoresEmpate) {
      vencedoresEmpate.forEach(item => totalEmpate += +item.quantidade_final);
    }

    if (vencedoresEmpate.length === 1) { // regra A
      empates.forEach(empate => {
        empate.situacao = 'VENCEDOR';
        empate.quantidade_final = memorial.quantidade;
        empate.valor_final = empate.valor_unitario;
      })

    } else if (vencedoresEmpate.length > 1 && +totalEmpate !== +memorial.quantidade) { // regra B
      vencedoresEmpate.forEach(vencedorEmp => {
        vencedorEmp.situacao = 'EMPATADO';
        vencedorEmp.quantidade_final = 0;
        vencedorEmp.valor_final = 0;
      })

    } else if (vencedores.length === 1 && empates.length > 0) { // regra C
      vencedores[0].quantidade_final = memorial.quantidade;
      vencedores[0].valor_final = vencedores[0].valor_unitario;

      empates.forEach(empate => {
        empate.situacao = 'CLASSIFICADO';
        empate.quantidade_final = undefined;
        empate.valor_final = 0;
      })

    } else if (vencedores.length > 1 && vencedoresEmpate.length === 0) { // regra D
      vencedores.forEach(vencedor => {
        vencedor.situacao = 'EMPATADO';
        vencedor.quantidade_final = 0;
        vencedor.valor_final = 0;
      });

    } else if (empates.length === 1) { // regra E
      empates[0].situacao = 'VENCEDOR';
      empates[0].quantidade_final = memorial.quantidade;
      empates[0].valor_final = empates[0].valor_unitario;

    } else if (empates.length === 0 && vencedores.length === 0) { // regra F
      await this.ordenarPropostas(memorial.propostas);
      if (memorial.propostas[0].situacao === 'CLASSIFICADO') {
        await this.definirVencedores(memorial);
      }

    }
  }

  public mostrarBotaoSituacao(item: Proposta, situacao: SituacaoProposta): boolean {
    if (item && situacao) {
      if (situacao === 'VENCEDOR') {
        switch (item.situacao) {
          case 'NAO_JULGADO':
          case 'CLASSIFICADO':
          case 'EMPATADO':
            return true;
          default:
            return false;
        }
      } else if (situacao === 'DESCLASSIFICADO') {
        switch (item.situacao) {
          case 'VENCEDOR':
          case 'CLASSIFICADO':
          case 'EMPATADO':
          case 'NAO_JULGADO':
            return true;
          default:
            return false;
        }
      } else if (situacao === 'CLASSIFICADO') {
        switch (item.situacao) {
          case 'DESCLASSIFICADO':
          case 'NAO_CLASSIFICADO':
            return true;
          default:
            return false;
        }
      }
    }
    return false;
  }

  public mostrarBotaoEmpate(memorial: Memorial): boolean {
    if (memorial?.propostas) {
      const empates = memorial.propostas.filter(p => p.situacao === 'EMPATADO');
      return empates.length > 1;
    }
    return false;
  }

  public validarQtdeEmpate(): boolean {
    if (this.memorialEmpate && this.itensEmpatados?.length > 0) {
      let qtde = 0;
      this.itensEmpatados.forEach(item => qtde += +item.quantidade_final);
      return +this.memorialEmpate.quantidade === +qtde;
    }
    return false;
  }

  public abrirModalDividirEmpate(memorial: Memorial) {
    if (this.contratoGerado && !this.edicaoContratoExcluido) {
      toastr.warning('Já existe contrato gerado para este processo')
      return;
    }

    this.memorialEmpate = memorial;
    this.itensEmpatados = [];
    memorial.propostas.filter(p => p.situacao === 'EMPATADO').forEach(p => {
      const propf = new Proponente();
      const f = new Favorecido();
      f.nome = p.proponente.favorecido.nome;
      propf.favorecido = f;
      propf.id = p.proponente.id;

      const prop = new Proposta();
      prop.proponente = propf;
      prop.quantidade_final = 0;
      prop.valor_final = 0;

      this.itensEmpatados.push(prop);
    })
    $('#dialogDividirEmpate').modal('show');
  }

  public dividirEmpate() {
    if (this.contratoGerado && !this.edicaoContratoExcluido) {
      toastr.warning('Já existe contrato gerado para este processo')
      return;
    }

    if (!this.validarQtdeEmpate()) {
      toastr.error('Quantidades informadas não conferem com a quantidade do item');
    } else {
      this.itensEmpatados.forEach(item => {
        const empate: Proposta = this.memorialEmpate.propostas.find(p => p.proponente.id === item.proponente.id);
        empate.quantidade_final = +item.quantidade_final;
        if (empate.quantidade_final > 0) {
          empate.situacao = 'VENCEDOR';
          empate.valor_final = empate.valor_unitario;
        } else {
          empate.situacao = 'CLASSIFICADO';
          empate.valor_final = 0;
        }
      });

      this.atualizarVencedores(this.memorialEmpate);
      this.ordenarPropostas(this.memorialEmpate.propostas);

      this.memorialEmpate = undefined;
      this.itensEmpatados = undefined;
      $('#dialogDividirEmpate').modal('hide');
    }
  }

  private obterCriterio(): CriterioJulgamento {
    return this.entidadeForm.get('criterio_julgamento').value;
  }

  public abrirDlgMotivoDesclassificacao(proposta: Proposta, memorial: Memorial, apenasVisualizar?: boolean) {
    this.propostaDesclassificada = proposta;
    this.memorialPropostaDesclassificada = memorial;

    if (apenasVisualizar) {
      this.apenasVisualizarMotivoDesclassificacao = true;
    } else {
      this.visualizarMotivoDesclassificacao = true;
    }
  }

  public verificarContratoExcluido() {
    this.contratoService.filtrar(1, -1,
      {
        licitacao_id: this.entidade.id,
        orgao_id: this.login.orgao.id,
        relations: 'itens.produto_unidade,favorecido,itens.memorial'
      }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.proponenteService.obterPorLicitacao(this.entidade.id, true)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((props) => {
            const entidade = this.entidade
            entidade.contratos = res.content
            entidade.proponentes = props.content
            const listaPendentes = [];

            if (entidade?.proponentes?.length > 0) {
              for (const proponente of entidade.proponentes) {
                let contratoExcluido = entidade?.contratos?.filter(c => c?.excluido && c?.favorecido?.id === proponente?.favorecido?.id && c.favorecido.id);
                contratoExcluido = contratoExcluido.filter(contrato => contrato.favorecido.id !== (entidade.contratos.find(c => !c.excluido && c.favorecido.id === contrato.favorecido.id))?.favorecido?.id)

                if (!entidade.contratos?.find(
                  c => c?.favorecido?.id === proponente?.favorecido?.id &&
                    c?.itens?.find(
                      i => {
                        entidade?.itens?.find(
                          m => (
                            m?.produto_unidade?.id === i?.produto_unidade?.id &&
                            +i?.quantidade === +m?.quantidade &&
                            m?.propostas.find(
                              p => p?.situacao === 'DESCLASSIFICADO' &&
                                p?.proponente?.id === proponente?.id)))
                      }))) {
                  const vencedoras = Object.assign([], proponente?.propostas?.filter(
                    p => (p?.situacao === 'VENCEDOR' || p?.situacao === 'NAO_CLASSIFICADO' || p?.situacao === 'NAO_JULGADO') &&
                      !(entidade?.contratos?.find(
                        c => c?.favorecido?.id === proponente?.favorecido?.id && !c.excluido &&
                          c?.itens?.find(
                            i => i?.memorial?.id === p?.memorial?.id
                          )))));

                  if (vencedoras?.length > 0) {
                    const pendente = Object.assign({}, proponente);
                    pendente.propostas = vencedoras;
                    pendente.propostas.forEach(p => p.memorial.produto_unidade = entidade?.itens?.find(m => m?.id === p?.memorial?.id)?.produto_unidade)
                    pendente.licitacao = Object.assign({}, entidade);
                    delete pendente.licitacao.proponentes;
                    delete pendente.licitacao.itens;

                    listaPendentes.push(pendente);
                  }

                  if (contratoExcluido?.length > 0) {
                    let prop = contratoExcluido.find(ce => ce.favorecido.id === proponente.favorecido.id)
                    if (prop?.id) {
                      const pendente = Object.assign({}, proponente);
                      pendente.propostas = proponente.propostas.filter(p => p.memorial.produto_unidade = prop.itens.find(i => i?.memorial?.id === p?.memorial?.id)?.produto_unidade);
                      pendente.propostas.forEach(p => p.memorial.produto_unidade = entidade?.itens?.find(m => m?.id === p?.memorial?.id)?.produto_unidade)
                      pendente.licitacao = Object.assign({}, entidade);
                      delete pendente.licitacao.proponentes;
                      delete pendente.licitacao.itens;
                      let propostas: Proposta[] = []

                      for (let p = 0; p < pendente?.propostas?.length; p++) {
                        if (+pendente.propostas[+p].quantidade_final !== 0) {
                          propostas.push(pendente.propostas[p]);
                        }
                      }

                      pendente.propostas = propostas

                      if (pendente.propostas?.length) {
                        listaPendentes.push(pendente);
                      }
                    }
                  }
                }
              }
            }

            let listaFavorecido = []

            if (listaPendentes.length > 0) {
              for (const proponente of listaPendentes) {
                if (!listaFavorecido.find(f => f.favorecido.id === proponente.favorecido.id)) {
                  listaFavorecido.push(proponente)
                } else {
                  listaFavorecido.find(f => {
                    if (f.favorecido.id === proponente.favorecido.id) {
                      proponente.propostas.forEach(p => {
                        if (!proponente.propostas.find(prop => prop.memorial.cota === p.memorial.cota && p.memorial.produto_unidade.id === prop.memorial.produto_unidade.id)) {
                          f.propostas.push(p)
                        }
                      })
                    }
                  })
                }
              }

              this.itensZerar = []
              for (const m of this.entidade.itens) {
                for (const propEx of listaFavorecido) {
                  if (propEx?.propostas?.find(prop => prop.memorial.id === m.id &&
                    !this.itensZerar.find(i => i.memorial.id === m.id))) {
                    m['editar'] = true;
                    this.itensZerar.push({ marcado: true, memorial: m })
                  }
                }
              }
            }

            this.edicaoContratoExcluido = !!(listaFavorecido?.length > 0);
          })
      })
  }
}
