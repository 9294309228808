import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Page, RecursoAliquota } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RecursoAliquotaService extends BaseResourceService<RecursoAliquota> {

  constructor(
    protected injector: Injector
  ) {
    super(`recurso-aliquotas`, injector);
  }

  public obterMaisRecente(mes: number, dia: number, exercicio: number): Observable<Page> {
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/obter-recente/${mes}/${dia}/${exercicio}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
