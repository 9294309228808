import { BaseResourceModel } from '../../models/base-resource.model';

export class OrgaoAudesp extends BaseResourceModel {

  constructor(
    public id?: number,
    public codigo_municipio?: number,
    public nome_municipio?: string,
    public codigo_entidade?: number,
    public nome_entidade?: string,
  ) {
    super();
  }

  static converteJson(json: any): OrgaoAudesp {
    return Object.assign(new OrgaoAudesp(), json);
  }
}
