import { Component, Injector } from '@angular/core';
import {
  BaseResourceListComponent, Coluna, Filtro, FormatoExportacao,
  LoginContabil, TipoContratacao
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TipoContratacaoService } from '../service/tipo-contratacao.service';

@Component({
  selector: 'lib-tipo-contratacao-list',
  templateUrl: './tipo-contratacao-list.component.html'
})
export class TipoContratacaoListComponent extends BaseResourceListComponent<TipoContratacao, LoginContabil> {

  /**
   * Declaração de variáveis
   */


  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private tipoService: TipoContratacaoService) {
    super(tipoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'orgao';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['id'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['id', 'tce'],
      text: ['nome', 'tce']
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: TipoContratacao): Observable<TipoContratacao> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'ID', coluna: 'id' },
      { titulo: 'TCE', coluna: 'tce' },
      { titulo: 'Nome', coluna: 'nome' }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'orgao';
    this.tipoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE TIPOS DE CONVÊNIO `,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem tipos de convênio', ['auto', 'auto', '*'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public retornar() {
    this.router.navigate([`/cadastros-auxiliares`]);
  }
}
