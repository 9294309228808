import { BaseResourceModel } from '../../models/base-resource.model';
import { SetorLocalizacao } from '../almoxarifado/setor-localizacao.model';
import { Setor } from '../almoxarifado/setor.model';
import { Conferencia } from './conferencia.model';
import { Tombamento } from './tombamento.model';

export class ConferenciaItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public localizado?: boolean,
    public solicitacao_transferencia?: boolean,
    public motivo_transferencia?: string,
    public tombamento?: Tombamento,
    public conferencia?: Conferencia,
    public setor_transferencia?: Setor,
    public localizacao_transferencia?: SetorLocalizacao,
  ) {
    super();
  }

  static converteJson(json: any): ConferenciaItem {
    return Object.assign(new ConferenciaItem(), json);
  }
}
