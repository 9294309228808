import { Injectable, Injector } from "@angular/core";
import { BaseResourceService } from "../../models/services/base-resource.service";
import { UsuarioSetor } from "../../entidade/almoxarifado/usuario-setor.model";

@Injectable({ providedIn: 'root' })
export class UsuarioSetorService extends BaseResourceService<UsuarioSetor>{

  constructor(
    protected injector: Injector
  ) {
    super('usuario-setors', injector)
  }

}