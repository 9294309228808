import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ToastModule } from "primeng/toast";
import { SharedModule } from "../util/shared.module";
import { ComunicadoFormComponent } from "./comunicado-form/comunicado-form.component";
import { ComunicadoListComponent } from "./comunicado-list/comunicado-list.component";
import { ComunicadoRoutingModule } from "./comunicado-routing.module";

@NgModule({
  declarations: [ComunicadoListComponent, ComunicadoFormComponent],
  imports: [
    CommonModule,
    ComunicadoRoutingModule,
    SharedModule,
    ToastModule,
  ],
  exports: [ComunicadoListComponent, ComunicadoFormComponent],
  providers: []
})
export class ComunicadoModule { }