// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class Regime extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public quinquenio?: boolean,
    public anuenio?: boolean,
    public trienio?: boolean,
    public salario_familia?: boolean,
    public ferias?: boolean,
    public licenca_premio?: boolean,
    public decimo_terceiro?: boolean,
    public sexta_parte?: boolean,
    public abono_aniversario?: boolean,
    public previdencia?: boolean,
    public decimo_aniversario?: boolean,
    public orgao?: Orgao,
    ) {
    super();
  }
  static converteJson(json: any): Regime {
    return Object.assign(new Regime(), json);
  }
}

