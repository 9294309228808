// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Chamamento } from './chamamento.model';

export class ChamamentoLocal extends BaseResourceModel {
  constructor(
    public id?: number,
    public local?: string,
    public nome?: string,
    public cargo?: string,
    public chamamento?: Chamamento,
    public editavel?: boolean
  ) {
    super();
  }
  static converteJson(json: any): ChamamentoLocal {
    return Object.assign(new ChamamentoLocal(), json);
  }
}
