import { Component, Input } from '@angular/core';
import { BaseResourceItemsComponent, Favorecido, FavorecidoContato, FuncaoService, GlobalService, TipoContato } from 'eddydata-lib';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { FavorecidoContatoService } from '../service/favorecido-contato.service';

@Component({
  selector: 'lib-favorecido-contato',
  templateUrl: './favorecido-contato.component.html',
  styleUrls: ['./favorecido-contato.component.css']
})
export class FavorecidoContatoComponent extends BaseResourceItemsComponent<FavorecidoContato>{

  public tipos: TipoContato[] = [];
  @Input() public favorecido: Favorecido;

  constructor(public funcaoService: FuncaoService,
    public globalService: GlobalService,
    public favorecidoContatoService: FavorecidoContatoService) {
    super(new FavorecidoContato(), favorecidoContatoService);
  }

  protected afterInit(): void {
    this.tipos = this.globalService.tiposContato();
  }

  protected validSave(item: FavorecidoContato): boolean {
    if (!item.tipo) {
      toastr.warning('Informe o tipo de contato!');
      return false;
    }
    if (!item.contato) {
      switch (item.tipo) {
        case TipoContato.TELEFONE:
          toastr.warning('Informe telefone de contato!');
          break;
        case TipoContato.EMAIL:
          toastr.warning('Informe e-mail de contato!');
          break;
        case TipoContato.SITE:
          toastr.warning('Informe site de contato!');
          break;
        default:
          toastr.warning('Informe dados de contato!');
      }
      return false;
    }
    return true;
  }

  protected beforeSave(item: FavorecidoContato): void {
    item.orgao = this.login.orgao
  }


  public disabledPrincipal(item: FavorecidoContato): boolean {
    if (item.principal)
      return false;
    let principal = this.lista
      .find((l) => l.principal && l.tipo === item.tipo);
    if (principal)
      return true;
    return false;
  }

  public salvarItem(item: FavorecidoContato) {
    item.principal = !item.principal;
    if (!item.id)
      return;
    item.favorecido = Object.assign({}, this.favorecido);
    item.favorecido.contatos = [];
    this.favorecidoContatoService.atualizar(item)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        toastr.success(`Contato principal ${data.principal ? 'marcado' : 'desmarcado'}`);
      }, error => console.log(error))
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
