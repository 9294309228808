import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PreLiquidacaoRestoListComponent } from './pre-liquidacao-resto-list/pre-liquidacao-resto-list.component';
import { PreLiquidacaoRestoViewComponent } from './pre-liquidacao-resto-view/pre-liquidacao-resto-view.component';
import { PreLiquidacaoRestoFormComponent } from './pre-liquidacao-resto-form/pre-liquidacao-resto-form.component';
import { PreLiquidacaoRestoRptComponent } from './pre-liquidacao-resto-rpt/pre-liquidacao-resto-rpt.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: PreLiquidacaoRestoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: PreLiquidacaoRestoFormComponent, canActivate: [AuthGuard] },
  { path: 'novo/:empenho', component: PreLiquidacaoRestoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: PreLiquidacaoRestoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: PreLiquidacaoRestoViewComponent, canActivate: [AuthGuard] },
  { path: 'relatorios', component: PreLiquidacaoRestoRptComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PreLiquidacaoRestoRoutingModule { }
