import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido } from './favorecido.model';
import { Usuario } from '../comum/usuario.model';
import { Orgao } from '../comum/orgao.model';

export class FavorecidoObservacao extends BaseResourceModel {
  constructor(
    public id?: number,
    public observacao?: string,
    public sistema?: string,
    public responsavel?: Usuario,
    public favorecido?: Favorecido,
    public editavel?: boolean,
    public data_cadastro?: Date,
    public orgao?: Orgao
  ) {
    super();
  }
  static converteJson(json: any): FavorecidoObservacao {
    return Object.assign(new FavorecidoObservacao(), json);
  }
}
