import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { BaseResourceService, PreLiquidacaoResto, Usuario } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class PreLiquidacaoRestoService extends BaseResourceService<PreLiquidacaoResto> {

  constructor(
    protected injector: Injector
  ) {
    super(`pre-liquidacoes-restos`, injector);
  }

  public totalLiquidadoAno(exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/total-pre-liquidado-ano/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalLiquidadoMes(exercicio: number, orgaoId: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/total-pre-liquidado-mes/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalFavorecidoMes(exercicio: number, favorecido: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/total-favorecido-mes/${exercicio}/${favorecido}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorNumero(numero: number, ano: number, orgaoId: number): Observable<PreLiquidacaoResto> {
    return this.http.get<PreLiquidacaoResto>(
      `${this.login.cidade.id}/${this.api}?numero=${numero}&exercicio_ano=${ano}&orgao_id=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterTotalLiquidadoPorEmpenho(numero: number, exercicioId: number, orgaoId: number, idExclusao?: number): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/total-pre-liquidado-por-empenho/${numero}/${exercicioId}/${orgaoId}?`
      + (idExclusao ? `id_exclusao=${idExclusao}` : ''),
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterUltimaParcela(numero: number, exercicioId: number, orgaoId: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/ultima-parcela/${numero}/${exercicioId}/${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public anular(id: number, data: string, valor: number, historico: string, usuario: Usuario, data_referencia: string): Observable<PreLiquidacaoResto> {
    const anulacao = { id, data, valor, historico, usuario, data_referencia };
    return this.http.post<PreLiquidacaoResto>(
      `${this.login.cidade.id}/${this.api}/anular`, JSON.stringify(anulacao), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

  public ultimaDataLiquidada(exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/ultima-data-pre-liquidada/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public marcarComoImpresso(parametros: any): Observable<any> {
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/marcar-como-impresso`, JSON.stringify(parametros), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }
}
