// tslint:disable: variable-name
import { SituacaoImovel } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Setor } from '../almoxarifado/setor.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { FaturaTipoImovel } from '../contabil/fatura-tipo-imovel.model';
import { PlanoConta } from '../contabil/plano-conta.model';
import { ImovelOcorrencia } from './imovel-ocorrencia.model';

export class Imovel extends BaseResourceModel {
  constructor(
    public id?: number,
    public situacao?: SituacaoImovel,
    public data_aquisicao?: Date,
    public data_construcao?: Date,
    public vencimento_seguro?: Date,
    public valor_aquisicao?: number,
    public valor_terreno?: number,
    public valor_construcao?: number,
    public valor_avaliacao?: number,
    public valor_depreciado?: number,
    public valor_atual?: number,
    public valor_residual?: number,
    public vida_util_meses?: number,
    public matricula?: string,
    public descricao?: string,
    public topografia?: string,
    public area_construcao?: number,
    public area_terreno?: number,
    public cep?: string,
    public endereco?: string,
    public num_endereco?: string,
    public bairro?: string,
    public municipio?: string,
    public uf?: string,
    public complemento?: string,
    public obs?: string,
    public documento?: string,
    public destinacao_area?: number,
    public controle_cadastro?: number,
    public cadastro?: number,
    public setor?: Setor,
    public plano_conta?: PlanoConta,
    public orgao?: Orgao,
    public responsavel?: Usuario,
    public ocorrencias?: ImovelOcorrencia[],
    public fatura_tipo_imovel?: FaturaTipoImovel[],
  ) {
    super();
  }

  static converteJson(json: any): Imovel {
    return Object.assign(new Imovel(), json);
  }
}
