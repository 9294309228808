// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ProdutoUnidade } from '../almoxarifado/produto-unidade.model';
import { MemorialItem } from './memorial-item.model';
import { Memorial } from './memorial.model';
import { RplCotacao } from './rpl-cotacao.model';
import { RplItemFichaDespesa } from './rpl-item-ficha-despesa.model';
import { Rpl } from './rpl.model';

export class RplItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public ordem?: number,
    public quantidade?: number,
    public produto?: string,
    public produto_unidade?: ProdutoUnidade,
    public rpl?: Rpl,
    public memorial?: Memorial | 'N',
    public memorial_item?: MemorialItem | 'N',
    public cotacoes?: RplCotacao[],
    public lista_ficha_despesa?: RplItemFichaDespesa[],
    public editavel?: boolean,
    public incluido_ficha?: boolean,
    public aux?: number
  ) {
    super();
  }

  static converteJson(json: any): RplItem {
    return Object.assign(new RplItem(), json);
  }
}
