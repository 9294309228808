import { BaseResourceModel } from '../../models/base-resource.model';
import { Produto } from '../almoxarifado/produto.model';

export class Pneu extends BaseResourceModel {
  constructor(
    public id?: number,
    public modelo?: string,
    public duracao_km?: number,
    public aro?: number,
    public fabricante?: string,
    public garantia?: number,
    public produto?: Produto,
  ) {
    super();
  }

  static converteJson(json: any): Pneu {
    return Object.assign(new Pneu(), json);
  }
}
