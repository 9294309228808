import { BaseResourceModel } from "../../models/base-resource.model";

export class Lei extends BaseResourceModel {
  constructor(
    public codigo?: number,
    public data_emissao?: Date,
    public data_publicacao?: Date,
    public data_vigencia?: Date,
    public observacao?: string,
    public atrelar_ppa?: Boolean,
    public lei_generica?: Boolean,
  ) {
    super();
  }

  static converteJson(json: any): Lei {
    return Object.assign(new Lei(), json);
  }
}