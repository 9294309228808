import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ExercicioService, OrgaoService, GlobalService, FuncaoService, Relatorio, OrgaoAssinatura, OrgaoAssinaturaService, FormatoExportacao, Exercicio } from 'eddydata-lib';
import { FichaDespesaService } from 'contabil-lib';

@Injectable({
  providedIn: 'root'
})
export class BalanceteElementoDespesaService {

  protected unsubscribe: Subject<void> = new Subject();
  private assinatura: OrgaoAssinatura;

  constructor(
    protected fichaService: FichaDespesaService,
    protected exercicioService: ExercicioService,
    protected orgaoService: OrgaoService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected assinaturaService: OrgaoAssinaturaService
  ) {
  }

  async montarBalanceteElementoDespesa(formato: FormatoExportacao, mes: number, exercicioId: number, orgaos: number[], login: any, periodoInicial: string, periodoFinal: string) {
    const parametros: {} = {};
    if (mes) {
      parametros['mes'] = mes;
    } else {
      parametros['dtInicio'] = periodoInicial;
      parametros['dtFim'] = periodoFinal;
    }
    if (exercicioId) {
      parametros['ano'] = exercicioId;
    }
    parametros['orgaos'] = orgaos.join();
    const ex = await this.exercicioService.obterId(exercicioId).toPromise();
    this.fichaService.balanceteElementoDespesa(parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        let subtitulo = '';
        if (mes) {
          subtitulo = this.globalService.obterDataBR().monthNames[mes - 1].toLocaleUpperCase() + '/' + ex.ano;
        } else {
          let dt = periodoInicial.split("-");
          subtitulo = dt[2] + '/' + dt[1] + '/' + dt[0] + ' à ';
          dt = periodoFinal.split("-");
          subtitulo += dt[2] + '/' + dt[1] + '/' + dt[0];
        }
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado('DEMONSTRAÇÃO DA DESPESA EMPENHADA E PROCESSADA POR ELEMENTO',
            login.usuario.nome, login.usuario.sobrenome, login.orgao.nome, login.brasao,
            this.balanceteElementoDespesaConteudo(dados)
              .concat(await this.conteudoAssinatura(login, ex, mes, periodoFinal)),
            'landscape', 'Balancete Analítico da Despesa Empenhada e Processada por Elemento',
            {
              linhas: {
                hLineWidth(i, node) {
                  return 1;
                },
                vLineWidth(i) {
                  return 1;
                },
                hLineColor(i) {
                  return 'black';
                },
                paddingLeft(i) {
                  return 3;
                },
                paddingRight(i, node) {
                  return 3;
                }
              }
            }, false, false, 'pdf', `REFERÊNCIA: ${subtitulo}`);
        } else if (formato === 'csv') {
          this.balanceteElementoDespesaCSV(dados);
        }
      });
  }

  balanceteElementoDespesaConteudo(dados: any[]): {}[] {
    let registros: {}[];

    registros = [
      [
      //   { text: 'DEMONSTRAÇÃO DA DESPESA EMPENHADA E PROCESSADA POR ELEMENTO', colSpan: 14, alignment: 'center', bold: true, fontSize: 11 },
      //   '', '', '', '', '', '', '', '', '', '', '', '', ''
      // ], [
        { text: 'DESCRIÇÃO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'ORÇADA', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'MOVIMENTO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'ESPECIAL', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'RECURSO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'EMPENHADA', alignment: 'center', colSpan: 2, bold: true, fontSize: 7 },
        { text: '' },
        { text: 'SALDO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'PROCESSADA', alignment: 'center', colSpan: 2, bold: true, fontSize: 7 },
        { text: '' },
        { text: 'SALDO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'PAGAMENTO', alignment: 'center', colSpan: 2, bold: true, fontSize: 7 },
        { text: '' },
        { text: 'DÍVIDA', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 }
      ], [
        '', '', '', '', '',
        { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 7 }, '',
        { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 7 }, '',
        { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 7 }, ''
      ]
    ];

    let totalOrcado = 0.00;
    let totalMovimento = 0.00;
    let totalEspecial = 0.00;
    let totalRecurso = 0.00;
    let totalEmpenhadoMes = 0.00;
    let totalEmpenhado = 0.00;
    let totalSaldoEmpenhado = 0.00;
    let totalLiquidadoMes = 0.00;
    let totalLiquidado = 0.00;
    let totalSaldoLiquidado = 0.00;
    let totalPagoMes = 0.00;
    let totalPago = 0.00;
    let totalDivida = 0.00;

    const grupos = this.funcaoService.agrupar(dados, ['d_codigo', 'd_nome'], ['total_orcado', 'movimento', 'especial', 'empenhado_mes', 'empenhado_acumulado', 'liquidado_mes', 'liquidado_acumulado', 'pago_mes', 'pago_acumulado']);
    for (const grupo of grupos) {
      let orcado = 0;
      let movimento = 0;
      let especial = 0;
      let recurso = 0;
      let empenhadoMes = 0;
      let empenhado = 0;
      let saldoEmpenhado = 0;
      let liquidadoMes = 0;
      let liquidado = 0;
      let saldoLiquidado = 0;
      let pagoMes = 0;
      let pago = 0;
      let divida = 0;
      for (const registro of grupo.registros) {
        orcado += +registro['total_orcado'];
        movimento += +registro['movimento'];
        especial += +registro['especial'];
        recurso += +registro['especial'] + +registro['movimento'] + +registro['total_orcado'];
        empenhadoMes += +registro['empenhado_mes'];
        empenhado += +registro['empenho_acumulado'];
        saldoEmpenhado += +(+registro['especial'] + +registro['movimento'] + +registro['total_orcado']) - +registro['empenho_acumulado'];
        liquidadoMes += +registro['liquidado_mes'];
        liquidado += +registro['liquidado_acumulado'];
        saldoLiquidado += +registro['empenho_acumulado'] - +registro['liquidado_acumulado'];
        pagoMes += +registro['pago_mes'];
        pago += +registro['pago_acumulado'];
        divida += +registro['liquidado_acumulado'] - +registro['pago_acumulado'];
      }

      totalMovimento += +movimento;
      totalEspecial += +especial;
      totalOrcado += +orcado;
      totalRecurso += +recurso;
      totalEmpenhadoMes += +empenhadoMes;
      totalEmpenhado += +empenhado;
      totalSaldoEmpenhado += +saldoEmpenhado;
      totalLiquidadoMes += +liquidadoMes;
      totalLiquidado += +liquidado;
      totalSaldoLiquidado += +saldoLiquidado;
      totalPagoMes += +pagoMes;
      totalPago += +pago;
      totalDivida += +divida;

      registros.push([
        { text: this.funcaoService.mascarar('#.#.##.##', grupo.grupo['d_codigo']) + ' ' + grupo.grupo['d_nome'], border: [true, false, true, false], fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(orcado), alignment: 'right', border: [true, false, true, false], fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(movimento), alignment: 'right', border: [true, false, true, false], fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(especial), alignment: 'right', border: [true, false, true, false], fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(recurso), alignment: 'right', border: [true, false, true, false], fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(empenhadoMes), alignment: 'right', border: [true, false, true, false], fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(empenhado), alignment: 'right', border: [true, false, true, false], fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(saldoEmpenhado), alignment: 'right', border: [true, false, true, false], fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(liquidadoMes), alignment: 'right', border: [true, false, true, false], fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(liquidado), alignment: 'right', border: [true, false, true, false], fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(saldoLiquidado), alignment: 'right', border: [true, false, true, false], fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(pagoMes), alignment: 'right', border: [true, false, true, false], fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(pago), alignment: 'right', border: [true, false, true, false], fontSize: 6 },
        { text: this.funcaoService.convertToBrNumber(divida), alignment: 'right', border: [true, false, true, false], fontSize: 6 },
      ]);
    }

    registros.push([
      { text: 'TOTAL GERAL:', fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(totalOrcado), alignment: 'right', fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(totalMovimento), alignment: 'right', fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(totalEspecial), alignment: 'right', fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(totalRecurso), alignment: 'right', fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(totalEmpenhadoMes), alignment: 'right', fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(totalEmpenhado), alignment: 'right', fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(totalSaldoEmpenhado), alignment: 'right', fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(totalLiquidadoMes), alignment: 'right', fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(totalLiquidado), alignment: 'right', fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(totalSaldoLiquidado), alignment: 'right', fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(totalPagoMes), alignment: 'right', fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(totalPago), alignment: 'right', fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(totalDivida), alignment: 'right', fontSize: 6 },
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }];
  }

  balanceteElementoDespesaCSV(dados: any[]) {
    let registros: {}[][];

    registros = [
      [ { text: 'DEMONSTRAÇÃO DA DESPESA EMPENHADA E PROCESSADA POR ELEMENTO' }],
      [
        { text: 'DESCRIÇÃO' },
        { text: 'ORÇADA' },
        { text: 'MOVIMENTO' },
        { text: 'ESPECIAL' },
        { text: 'RECURSO' },
        { text: 'EMPENHADA DO MÊS' },
        { text: 'EMPENHADA ACUMULADO' },
        { text: 'SALDO' },
        { text: 'PROCESSADA DO MÊS' },
        { text: 'PROCESSADA ACUMULADO' },
        { text: 'SALDO' },
        { text: 'PAGAMENTO DO MÊS' },
        { text: 'PAGAMENTO ACUMULADO' },
        { text: 'DÍVIDA' }
      ]
    ];

    let totalOrcado = 0.00;
    let totalMovimento = 0.00;
    let totalEspecial = 0.00;
    let totalRecurso = 0.00;
    let totalEmpenhadoMes = 0.00;
    let totalEmpenhado = 0.00;
    let totalSaldoEmpenhado = 0.00;
    let totalLiquidadoMes = 0.00;
    let totalLiquidado = 0.00;
    let totalSaldoLiquidado = 0.00;
    let totalPagoMes = 0.00;
    let totalPago = 0.00;
    let totalDivida = 0.00;

    const grupos = this.funcaoService.agrupar(dados, ['d_codigo', 'd_nome'], ['total_orcado', 'movimento', 'especial', 'empenhado_mes', 'empenhado_acumulado', 'liquidado_mes', 'liquidado_acumulado', 'pago_mes', 'pago_acumulado']);
    for (const grupo of grupos) {
      let orcado = 0;
      let movimento = 0;
      let especial = 0;
      let recurso = 0;
      let empenhadoMes = 0;
      let empenhado = 0;
      let saldoEmpenhado = 0;
      let liquidadoMes = 0;
      let liquidado = 0;
      let saldoLiquidado = 0;
      let pagoMes = 0;
      let pago = 0;
      let divida = 0;
      for (const registro of grupo.registros) {
        orcado += +registro['total_orcado'];
        movimento += +registro['movimento'];
        especial += +registro['especial'];
        recurso += +registro['especial'] + +registro['movimento'] + +registro['total_orcado'];
        empenhadoMes += +registro['empenhado_mes'];
        empenhado += +registro['empenho_acumulado'];
        saldoEmpenhado += +(+registro['especial'] + +registro['movimento'] + +registro['total_orcado']) - +registro['empenho_acumulado'];
        liquidadoMes += +registro['liquidado_mes'];
        liquidado += +registro['liquidado_acumulado'];
        saldoLiquidado += +registro['empenho_acumulado'] - +registro['liquidado_acumulado'];
        pagoMes += +registro['pago_mes'];
        pago += +registro['pago_acumulado'];
        divida += +registro['liquidado_acumulado'] - +registro['pago_acumulado'];
      }

      totalMovimento += +movimento;
      totalEspecial += +especial;
      totalOrcado += +orcado;
      totalRecurso += +recurso;
      totalEmpenhadoMes += +empenhadoMes;
      totalEmpenhado += +empenhado;
      totalSaldoEmpenhado += +saldoEmpenhado;
      totalLiquidadoMes += +liquidadoMes;
      totalLiquidado += +liquidado;
      totalSaldoLiquidado += +saldoLiquidado;
      totalPagoMes += +pagoMes;
      totalPago += +pago;
      totalDivida += +divida;

      registros.push([
        { text: this.funcaoService.mascarar('#.#.##.##', grupo.grupo['d_codigo']) + ' ' + grupo.grupo['d_nome'] },
        { text: this.funcaoService.convertToBrNumber(orcado) },
        { text: this.funcaoService.convertToBrNumber(movimento) },
        { text: this.funcaoService.convertToBrNumber(especial) },
        { text: this.funcaoService.convertToBrNumber(recurso) },
        { text: this.funcaoService.convertToBrNumber(empenhadoMes) },
        { text: this.funcaoService.convertToBrNumber(empenhado) },
        { text: this.funcaoService.convertToBrNumber(saldoEmpenhado) },
        { text: this.funcaoService.convertToBrNumber(liquidadoMes) },
        { text: this.funcaoService.convertToBrNumber(liquidado) },
        { text: this.funcaoService.convertToBrNumber(saldoLiquidado) },
        { text: this.funcaoService.convertToBrNumber(pagoMes) },
        { text: this.funcaoService.convertToBrNumber(pago) },
        { text: this.funcaoService.convertToBrNumber(divida) },
      ]);
    }

    registros.push([
      { text: 'TOTAL GERAL:' },
      { text: this.funcaoService.convertToBrNumber(totalOrcado) },
      { text: this.funcaoService.convertToBrNumber(totalMovimento) },
      { text: this.funcaoService.convertToBrNumber(totalEspecial) },
      { text: this.funcaoService.convertToBrNumber(totalRecurso) },
      { text: this.funcaoService.convertToBrNumber(totalEmpenhadoMes) },
      { text: this.funcaoService.convertToBrNumber(totalEmpenhado) },
      { text: this.funcaoService.convertToBrNumber(totalSaldoEmpenhado) },
      { text: this.funcaoService.convertToBrNumber(totalLiquidadoMes) },
      { text: this.funcaoService.convertToBrNumber(totalLiquidado) },
      { text: this.funcaoService.convertToBrNumber(totalSaldoLiquidado) },
      { text: this.funcaoService.convertToBrNumber(totalPagoMes) },
      { text: this.funcaoService.convertToBrNumber(totalPago) },
      { text: this.funcaoService.convertToBrNumber(totalDivida) },
    ]);

    dados = null;
    let csv = '';
    for (let i=0; i<registros.length; i++) {
      const linha = registros[i];
      if (i > 0) csv += '\n';
      for (let x=0; x<linha.length; x++) {
        if (x > 0) csv += ';';
        csv += String(linha[x]['text']);
      }
    }
    registros = null;

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + csv));
    element.setAttribute("download", `Balancete Analítico da Despesa.csv`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  private async conteudoAssinatura(login: any, exercicio: Exercicio, mes: number, dataFinal: string) {
    const conteudo = [];
    const data = dataFinal ?? `${exercicio.ano}-${this.funcaoService.strZero(mes, 2)}-${this.funcaoService.ultimoDiaMes(+mes, exercicio.ano)}`;
    this.assinatura = await this.assinaturaService.obter({ orgao_id: login.orgao.id, 'data_limite$ge': data, 'orderBy': 'data_limite$ASC' }).toPromise();

    if (!this.assinatura) {
      toastr.error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
      throw new Error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
    }

    conteudo.push([
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      },
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      },
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      }
    ]);
    conteudo.push([
      { text: this.assinatura.ordenador_despesa, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.contador, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.tesoureiro, border: [false, false, false, false], bold: true, alignment: 'center' }
    ]);
    conteudo.push([
      { text: this.assinatura.cargo_ordenador_despesa, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.cargo_contador + (this.assinatura.crc_contador ? ` - ${this.assinatura.crc_contador}` : ''), border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.cargo_tesoureiro, border: [false, false, false, false], bold: true, alignment: 'center' }
    ]);

    return [{
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', '*', '*'],
        body: conteudo
      }
    }];
  }

}
