import { Component, Injector, ElementRef, ViewChild } from '@angular/core';
import { distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MessageService } from 'primeng/api';
import { OrgaoService } from '../service/orgao.service';
import { BaseResourceFormComponent } from '../../models/base-resource-form';
import { Orgao } from '../../entidade/comum/orgao.model';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { GlobalService } from '../../util/global.service';
import { FuncaoService } from '../../util/funcao.service';
import { OrgaoAssinaturaService } from '../service/orgao-assinatura.service';
import { OrgaoConfiguracao } from '../../entidade/comum/orgao-configuracao.model';
import { OrgaoAssinatura } from '../../entidade/comum/orgao-assinatura.model';
import { OrgaoSuplementacaoService } from '../service/orgao-suplementacao.service';
import { OrgaoSuplementacao } from '../../entidade/comum/orgao-suplementacao.model';

@Component({
  selector: 'lib-orgao-view',
  templateUrl: './orgao-view.component.html'
})
export class OrgaoViewComponent extends BaseResourceFormComponent<Orgao, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public listaAssinaturas: Array<any>;
  public listaSuplementacoes: Array<any>;

  @ViewChild('codigo') inputField: ElementRef;

  public imageChangedEvent: any = '';
  public listaEspecies: Array<any>;

  public configuracao: OrgaoConfiguracao = new OrgaoConfiguracao(null, 'FIREBASE', false);

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private messageService: MessageService,
    protected injector: Injector,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected orgaoSuplementacaoService: OrgaoSuplementacaoService,
    protected orgaoService: OrgaoService) {
    super(new Orgao(), injector, Orgao.converteJson, orgaoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      codigo: [null],
      nome: [null],
      cnpj: [null],
      endereco: [null],
      bairro: [null],
      cep: [null],
      email: [null],
      siafi: [null],
      siconfi: [null],
      ativo: [null],
      site: [null],
      telefone: [null],
      especie: [null],
      tribunal: [null],
      cidade: [null],
      brasao: this.fb.group({
        id: [null],
        brasao: [null],
        orgao: [this.entidade],
      }),
      configuracao: [null],
      suplementacoes: [null],
    });

    this.entidadeForm.get('codigo').statusChanges
      .pipe(
        distinctUntilChanged(),
        switchMap(status => status === 'VALID' && !this.entidade.id ?
          this.orgaoService.obterPorCodigo(this.entidadeForm.get('codigo').value, this.login.cidade.id)
          : of({})
        )
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => dados && !this.entidade.id ?
        toastr.error('Código já está cadastrado!') : {});

  }

  protected parametrosExtras(): {} {
    return { relations: 'cidade,brasao,configuracao' };
  }

  protected afterLoad() {
    if (this.entidade.configuracao) {
      if (this.entidade.configuracao.firebase_conta_servico)
        this.entidade.configuracao.firebase_conta_servico = JSON.stringify(this.entidade.configuracao.firebase_conta_servico);
      this.configuracao = this.entidade.configuracao;
    } else
      this.configuracao = new OrgaoConfiguracao(null, 'FIREBASE', false);

    this.loadAssinatura();
  }

  protected afterInit(): void {
    this.listaEspecies = this.globalService.obterListaTipoOrgaos();
    this.listaSuplementacoes = new Array<OrgaoSuplementacao>();
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected beforeSubmit(): void {
    if (this.configuracao.firebase_conta_servico) {
      this.configuracao.firebase_conta_servico = JSON.parse(this.configuracao.firebase_conta_servico);
    }
    this.entidadeForm.get('configuracao').setValue(this.configuracao);
    this.entidadeForm.get('cidade').setValue(this.login.cidade);
    this.entidadeForm.get('suplementacoes').setValue(this.listaSuplementacoes);
  }

  protected afterSubmit(entidade: Orgao): void {
    if (entidade.configuracao) {
      if (entidade.configuracao.firebase_conta_servico)
        entidade.configuracao.firebase_conta_servico = JSON.stringify(entidade.configuracao.firebase_conta_servico);
      this.configuracao = entidade.configuracao;
    } else {
      this.loadAssinatura();
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.entidadeForm.get('brasao').get('brasao').setValue(event.base64);
  }

  public loadAssinatura() {
    if (this.entidade.id) {
      this.assinaturaService.filtrar(1, -1,
        { relations: 'orgao', 'orgao.id': this.entidade.id }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaAssinaturas = res ? res.content : new Array<OrgaoAssinatura>();
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );

      this.orgaoSuplementacaoService.filtrar(1, -1,
        { relations: 'exercicio', 'orgao.id': this.entidade.id }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaSuplementacoes = res ? res.content : new Array<OrgaoSuplementacao>();
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

}
