import { Component, Injector, ElementRef, ViewChild } from '@angular/core';
import { RecebimentoService } from '../service/recebimento.service';
import { Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { takeUntil } from 'rxjs/operators';
import { AutoComplete } from 'primeng/autocomplete';
import {
  BaseResourceFormComponent, Recebimento, LoginContabil, EddyAutoComplete, FavorecidoService,
  ContaBancaria, FichaReceita, GlobalService, FuncaoService, DateFormatPipe, Favorecido,
  Convenio, ContaBancariaRecurso, RecursoAliquota
} from 'eddydata-lib';
import { FichaReceitaService, ContaBancariaService, ConvenioService } from 'administrativo-lib';
import { RecursoAliquotaService } from '../../recurso-aliquota/service/recurso-aliquota.service';
import { ContaBancariaRecursoService } from '../../conta-bancaria/service/conta-bancaria-recurso.service';
import * as toastr from 'toastr';

@Component({
  selector: 'app-recebimento-form',
  templateUrl: './recebimento-form.component.html'
})

export class RecebimentoFormComponent extends BaseResourceFormComponent<Recebimento, LoginContabil> {
  /**
   * Declaração de variáveis
   */
  @ViewChild('InputFocus') private inputField: Calendar;
  private queryParameters;
  public aliquota: RecursoAliquota;
  public anulacao = false;
  public contaId: number;
  public contaCodigo: number;
  public fichaId: number;
  public fichaReceita: FichaReceita;
  public contaAutoComplete: EddyAutoComplete<ContaBancaria>;
  public fichaAutoComplete: EddyAutoComplete<FichaReceita>;
  public convenioAutoComplete: EddyAutoComplete<Convenio>;
  public recursoAutoComplete: EddyAutoComplete<ContaBancariaRecurso>;
  public contaRecursoAutoComplete: EddyAutoComplete<ContaBancariaRecurso>;
  public credorAutoComplete: EddyAutoComplete<Favorecido>;
  public credorObrigatorio = false;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private messageService: MessageService,
    protected injector: Injector,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected confirmationService: ConfirmationService,
    protected aliquotaService: RecursoAliquotaService,
    protected favorecidoService: FavorecidoService,
    protected convenioService: ConvenioService,
    protected contaService: ContaBancariaService,
    protected contaRecursoService: ContaBancariaRecursoService,
    protected fichaService: FichaReceitaService,
    protected recebimentoService: RecebimentoService) {
    super(new Recebimento(), injector, Recebimento.converteJson, recebimentoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(_entidade: Recebimento): Promise<boolean> {
    return this.login.sistema != 'controle-interno'
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      guia: [null],
      mes: [null, [Validators.required]],
      valor_recebido: [null, [Validators.required]],
      data_recebimento: [null, [Validators.required]],
      data_referencia: [null],
      especie: [null, [Validators.required]],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      conta: [null, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      ficha: [null, [Validators.required]],
      conta_recurso: [null],
      favorecido: [null],
      convenio: [null],
      retencao: [null],
      aliquota_educacao: [null],
      aliquota_saude: [null],
      aliquota_assistencia: [null],
      recurso_educacao: [null],
      recurso_saude: [null],
      recurso_assistencia: [null],
      retido: [false, [Validators.required]],
      aux: [0],
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'conta,conta.banco,ficha,ficha.receita.plano,ficha.recurso,ficha.aplicacao,ficha.recurso_siconfi,ficha.complemento_siconfi,exercicio,orgao,convenio.favorecido.tipo,convenio.tipo_convenio,retencao,ficha.recurso,ficha.aplicacao,ficha.contrato,ficha.contrato.favorecido,ficha.contrato.favorecido.tipo,ficha.favorecido,ficha.favorecido.tipo,orgao.cidade,ficha.convenio.favorecido.tipo,favorecido,favorecido.tipo,conta_recurso.recurso,conta_recurso.aplicacao,conta_recurso.convenio' };
  }

  protected afterLoad() {
    if (!this.podeAlterarAudesp(this.entidade.data_recebimento)) {
      this.router.navigate(['/recebimentos-orcamentarios']);
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return;
    }

    this.entidade.data_recebimento = new DateFormatPipe().transform(this.entidade.data_recebimento, []);
    this.entidade.data_referencia = new DateFormatPipe().transform(this.entidade.data_referencia, []);
    this.convenioAutoComplete.id = this.entidade.convenio ? this.entidade.convenio.id : null;
    this.contaRecursoAutoComplete.id = this.entidade.conta_recurso ? this.entidade.conta_recurso.id : null;
    this.credorAutoComplete.id = this.entidade.favorecido ? this.entidade.favorecido.id : null;

    if (this.currentActionRoute === 'novo') {
      this.entidade.historico = 'RECEITA ARRECADADA NO DIA';
      this.entidade.exercicio = this.login.exercicio;
      this.entidade.orgao = this.login.orgao;
      this.contaId = null;
      this.contaCodigo = null;
      this.fichaId = null;
      this.entidade.mes = this.entidade.data_recebimento.getUTCMonth() + 1;
      this.entidade.aux = 0;
      this.entidade.especie = !this.anulacao ? 'REO' : 'ROA';
    } else {
      this.anulacao = this.entidade.especie === 'ROA';
      this.contaCodigo = this.entidade.conta.codigo;
      this.contaId = this.entidade.conta.id;
      this.fichaId = this.entidade.ficha.numero;
      this.fichaReceita = this.entidade.ficha;
      // this.carregarAutoCompletesContaRecurso();
      // this.buscarContaRecurso(this.contaId);
      if (this.entidade.ficha.ensino > 0 || this.entidade.ficha.saude > 0) {
        this.buscarAliquota();
      }
      this.carregarAutoCompletesContaRecurso();
    }

    this.credorObrigatorio = this.entidade.ficha.exigir_credor;

    this.setQueryParams()
  }

  private setQueryParams() {
    let dataRecebimento = new Date(this.entidade.data_recebimento)
    this.queryParameters = {
      data_recebimento: `${dataRecebimento.getFullYear()}-${dataRecebimento.getMonth() + 1}-${dataRecebimento.getDate() + 1}`,
      conta_bancaria: this.entidade.conta.id,
      receita: this.entidade.ficha.receita.id,
      recurso: this.entidade.ficha.recurso.id,
      aplicacao: this.entidade.ficha.aplicacao.id,
      ficha: this.entidade.ficha.id
    }
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.inicializarVariaveis();
  }

  protected campoFoco(): ElementRef | AutoComplete | Calendar {
    return this.inputField.inputfieldViewChild.nativeElement.focus();
  }

  protected beforeSubmit() {
    try {
      const dataRecebimento = new DateFormatPipe().transform(this.entidadeForm.get('data_recebimento').value, []);
      const dataReferencia = new DateFormatPipe().transform(this.entidadeForm.get('data_referencia').value, []);

      const hoje = new DateFormatPipe().transform(new Date(), [])
      const dias = Math.abs(dataRecebimento - hoje)
      const diferenca = dias / (1000 * 3600 * 24)
      const limite = this.login.dias_bloquear_alteracoes

      if (this.entidadeForm.get('data_recebimento').value === null) {
        throw new Error('Infome a data do recebimento!');
      }
      if (this.entidadeForm.get('data_recebimento').value > new Date()) {
        throw new Error('Data do recebimento está maior que a data atual');
      }
      if (+diferenca >= +limite) {
        throw new Error('Prazo de inclusão/alteração excedido')
      }
      if (this.login['ultimoAudesp'] && dataRecebimento) {
        const data = new Date(dataRecebimento);
        const mes = +this.login['ultimoAudesp'].split('-')[1];
        const ano = +this.login['ultimoAudesp'].split('-')[0];
        if ((data.getFullYear() < ano || (data.getFullYear() === ano && (+data.getMonth() + 1) <= mes)) && mes < 12)
          throw new Error('Prazo de inclusão/alteração excedido')
      }
      this.verificarValor();
      if (this.anulacao) {
        if (this.entidadeForm.get('data_referencia').value === null) {
          throw new Error('Infome a data de referência!');
        }
        if (dataReferencia > dataRecebimento) {
          throw new Error('Data de referência tem que ser menor que a data do recebimento!');
        }
      }
      this.entidadeForm.get('especie').setValue(!this.anulacao ? 'REO' : 'ROA');
      this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(dataRecebimento)?.split('-')?.[1]);
      if (this.aliquota) {
        if (!this.aliquota.aliquota_educacao && !this.aliquota.aliquota_saude && !this.aliquota.aliquota_assistencia) {
          throw new Error('Alíquotas para educação, saúde e assistência estão zeradas, verificar!');
        }
        this.entidadeForm.get('aliquota_educacao').setValue(this.aliquota.aliquota_educacao);
        this.entidadeForm.get('aliquota_saude').setValue(this.aliquota.aliquota_saude);
        this.entidadeForm.get('aliquota_assistencia').setValue(this.aliquota.aliquota_assistencia);

        this.entidadeForm.get('recurso_educacao').setValue(this.aliquota.recurso_educacao);
        this.entidadeForm.get('recurso_saude').setValue(this.aliquota.recurso_saude);
        this.entidadeForm.get('recurso_assistencia').setValue(this.aliquota.recurso_assistencia);
      }
      if ((+this.entidadeForm.get('ficha').value.ensino > 0 || +this.entidadeForm.get('ficha').value.saude > 0) && !this.aliquota) {
        throw new Error(`É obrigatório informar Alíquota da Receita para usar a ficha ${this.fichaId}`)
      }

      if (!this.entidadeForm.get('conta_recurso').value) {
        throw new Error(`É obrigatório informar uma fonte de recurso para o recebimento!`)
      }
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(ent: Recebimento) {
    this.entidadeForm.get('aliquota_educacao').setValue(null);
    this.entidadeForm.get('aliquota_saude').setValue(null);
    this.entidadeForm.get('aliquota_assistencia').setValue(null);
    if (this.currentActionRoute === 'novo') {
      this.router.navigate(['/recebimentos-orcamentarios', 'novo', ent.id]);
    } else {
      this.router.navigate(['/recebimentos-orcamentarios', ent.id, 'editar']);
    }
  }

  public iniciarMascara() {
    new GlobalService().calendarMascara();
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
  private carregarAutoCompletes() {
    // autocomplete para conta
    this.contaAutoComplete = new EddyAutoComplete(this.entidadeForm.get('conta'), this.contaService,
      'codigo', ['numero_conta', 'banco.nome', 'nome'], { orgao_id: this.login.orgao.id, orderBy: 'nome', relations: 'banco' }, { number: ['numero_conta', 'codigo'], text: ['nome'] }
    );

    // autocomplete para ficha
    this.fichaAutoComplete = new EddyAutoComplete(this.entidadeForm.get('ficha'), this.fichaService,
      'id', ['receita.codigo', 'receita.nome'], {
      orgao_id: this.login.orgao.id, exercicio_id: this.login.exercicio.id,
      relations: 'receita.plano,recurso,aplicacao,aplicacao_variavel,contrato,recurso_siconfi,complemento_siconfi,convenio.favorecido.tipo', orderBy: 'receita.nome'
    },
      { number: ['codigo'], text: ['receita.nome'] }
    );

    // autocomplete para convenio
    this.convenioAutoComplete = new EddyAutoComplete(this.entidadeForm.get('convenio'), this.convenioService,
      'numero', ['numero', 'ano'], { orgao_id: this.login.orgao.id, orderBy: 'numero', relations: 'favorecido.tipo' }, { number: ['id', 'numero'] });

    // Autocomplete do credor
    this.credorAutoComplete = new EddyAutoComplete(this.entidadeForm.get('credor'), this.favorecidoService,
      'id', ['id', 'nome'], { orderBy: 'id', relations: 'tipo' },
      { number: ['id'], text: ['nome'] },
    );

    this.carregarAutoCompletesContaRecurso();
  }

  public changeFicha() {
    if (this.entidadeForm.get('ficha')) {
      const fh = this.entidadeForm.get('ficha').value;
      this.fichaId = fh.numero;
    }
  }

  public changeConta() {
    if (this.entidadeForm.get('conta')) {
      const conta = this.entidadeForm.get('conta').value;
      this.contaId = conta.id;
      this.contaCodigo = conta.codigo;
      this.carregarAutoCompletesContaRecurso();
      this.buscarContaRecurso(this.contaId);
      this.verificarRecursoConta(this.contaId);
    }
  }

  public buscarConta() {
    if (this.contaCodigo && this.contaCodigo >= 0) {
      this.contaService.obter({ codigo: this.contaCodigo, 'orgao.id': this.login.orgao.id, relations: 'banco' }).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            if (data) {
              if (data.ativo) {
                this.entidadeForm.get('conta').setValue(data);
                this.contaId = data.id;
                this.carregarAutoCompletesContaRecurso();
                this.buscarContaRecurso(this.contaId, data);
                this.verificarRecursoConta(this.contaId);
              } else {
                this.contaCodigo = null;
                toastr.error('Conta bancária inativa!');
                return;
              }
            }
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  private verificarRecursoConta(id: number) {
    //verifica se a conta informada possui algum recurso com codigo de recurso e aplicacao iguais a da ficha informada
    if (this.fichaReceita?.id) {
      this.contaRecursoService.obterRecursoPorCodigo(id, this.fichaReceita.recurso.codigo, this.fichaReceita.aplicacao.codigo)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            if (data.content.length === 0) {
              toastr.warning(
                `O recurso ${this.fichaReceita.recurso.codigo} e código de aplicação
                  ${this.fichaReceita.aplicacao.codigo} provenientes da ficha não existem nos recursos vinculados à conta informada!`
              )
            }
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }
  private buscarContaRecurso(id: number, contaBancaria?: ContaBancaria) {
    if (this.fichaReceita) {
      this.contaRecursoService.obterRecursoPorCodigo(id, this.fichaReceita.recurso.codigo, this.fichaReceita.aplicacao.codigo,
        this.fichaReceita.aplicacao_variavel ? this.fichaReceita.aplicacao_variavel.codigo : null)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            if (data.content[0]) {
              this.entidadeForm.get('conta_recurso').setValue(data.content[0]);
            } else {
              this.contaRecursoService.filtrar(1, -1, { 'conta_id': id, 'recurso_pagto': true, excluido: false, 'relations': 'aplicacao,convenio,recurso' })
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((recurso) => {
                  if (recurso.content.length > 0) {
                    this.entidadeForm.get('conta_recurso').setValue(recurso.content[0]);
                  } else {
                    this.entidadeForm.get('conta_recurso').setValue(null);
                  }
                })
            }
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  public buscarFicha() {
    if (this.fichaId > 0) {
      this.fichaService.obterPorFicha(this.fichaId, this.login.orgao.id, this.login.exercicio.id).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            if (data.content[0]) {
              this.fichaReceita = data.content[0];
              this.entidadeForm.get('ficha').setValue(data.content[0]);
              this.entidadeForm.get('aliquota_educacao').setValue(null);
              this.entidadeForm.get('aliquota_saude').setValue(null);
              this.entidadeForm.get('aliquota_assistencia').setValue(null);
              this.entidadeForm.get('favorecido').setValue(data.content[0].favorecido ? data.content[0].favorecido : null);
              if (data.content[0].favorecido) {
                this.entidadeForm.get('favorecido').disable({ onlySelf: true });
              }
              if (this.entidadeForm.get('ficha').value && this.entidadeForm.get('ficha').value.ensino &&
                (+this.entidadeForm.get('ficha').value.ensino > 0 || +this.entidadeForm.get('ficha').value.saude > 0)) {
                this.buscarAliquota();
              }

              // Automatização de campo de credor
              this.credorObrigatorio = this.fichaReceita.exigir_credor;
              if (!this.credorObrigatorio) {
                this.entidadeForm.get('favorecido').removeValidators(Validators.required);
                this.entidadeForm.get('favorecido').setValue(null);
                this.entidadeForm.get('favorecido').updateValueAndValidity();
              } else {
                this.entidadeForm.get('favorecido').addValidators(Validators.required);
                this.entidadeForm.get('favorecido').updateValueAndValidity();
              }
            }
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  public buscarAliquota() {
    const mes = this.entidade.id ? this.entidade.data_recebimento.getUTCMonth() + 1 : this.entidadeForm.get('data_recebimento').value.getUTCMonth() + 1;
    const dia = this.entidade.id ? this.entidade.data_recebimento.getDate() : this.entidadeForm.get('data_recebimento').value.getDate();
    this.aliquotaService.obterMaisRecente(mes, dia, this.login.exercicio.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data: any) => {
          this.aliquota = data;
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
      );
  }

  public blurValorRecebido() {
    this.verificarValor();
  }

  public montaHistorico() {
    if (this.entidadeForm.get('ficha').value) {
      this.entidadeForm.get('historico').setValue(`RECEITA DO DIA - ${this.entidadeForm.get('ficha').value.receita.nome} `);
    } else {
      this.entidadeForm.get('historico').setValue('RECEITA ARRECADADA NO DIA');
    }
  }

  /* anulacao de receita ou ficha de redudor de receita ou ficha de restituicao de receita
  * inverte o sinal
  */
  private verificarValor() {
    const ficha: FichaReceita = this.entidadeForm.get('ficha').value;
    const valor = this.entidadeForm.get('valor_recebido').value * -1;
    if (ficha && ((this.anulacao && !ficha.redutor && !ficha.restituicao && this.entidadeForm.get('valor_recebido').value > 0)
      || (!this.anulacao && ficha.redutor && this.entidadeForm.get('valor_recebido').value > 0)
      || (!this.anulacao && !ficha.redutor && ficha.restituicao && this.entidadeForm.get('valor_recebido').value > 0))) {
      this.entidadeForm.get('valor_recebido').setValue(valor.toString());
    }
    if (this.anulacao && (ficha.redutor || ficha.restituicao) && this.entidadeForm.get('valor_recebido').value < 0) {
      this.entidadeForm.get('valor_recebido').setValue(this.entidadeForm.get('valor_recebido').value * -1);
    }
  }

  private inicializarVariaveis() {
    if (this.currentActionRoute === 'novo') {
      this.contaCodigo = this.entidade.conta ? this.entidade.conta.codigo : null;
      this.contaId = this.entidade.conta ? this.entidade.conta.id : null;
      this.recebimentoService.ultimaData(this.login.exercicio.id, this.login.orgao.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((dados) => {
          this.entidade.data_recebimento = new DateFormatPipe().transform(dados.ultima_data, []);
          this.entidade.guia = dados.ultima_guia;
          this.entidadeForm.get('data_recebimento').setValue(new DateFormatPipe().transform(dados.ultima_data, []));
          this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidade.data_recebimento)?.split('-')?.[1]);
          this.entidadeForm.get('historico').setValue('RECEITA DO DIA');
          this.entidadeForm.get('guia').setValue(dados.ultima_guia);
        });
    }
  }

  private carregarAutoCompletesContaRecurso() {
    this.contaRecursoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('conta_recurso'), this.contaRecursoService,
      'id', ['recurso.codigo', 'aplicacao.codigo', 'convenio.variavel', 'aplicacao.nome', 'convenio.nome'], { 'conta.codigo': this.contaCodigo, excluido: 'false', relations: 'recurso,aplicacao,convenio', orderBy: 'aplicacao.codigo' },
      { number: ['aplicacao.codigo', 'convenio.variavel'], text: ['aplicacao.nome', 'convenio.nome'] },
      this.buscarContaRecurso(this.contaId)
    );
  }

  public voltar() {
    if (this.login.sistema === 'contabil') {
      this.router.navigate(['/tesouraria']);
    } else {
      this.sair();
    }
  }
}
