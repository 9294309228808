import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ifError } from 'assert';
import { FuncaoService, GlobalService, Licitacao, Login, Memorial } from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'app-memorial-cota',
  templateUrl: './memorial-cota.component.html'
})
export class MemorialCotaComponent implements OnChanges, OnInit, OnDestroy {

  @Input() public entidade: Licitacao;
  @Input() login: Login;
  @Input() abrir: boolean = false;
  @Output() abrirChange: EventEmitter<boolean> = new EventEmitter();
  @Output() callback: EventEmitter<Memorial[]> = new EventEmitter();

  public itens: Memorial[];
  public marcado: boolean = false;

  public imaskQtd = {
    mask: Number,
    scale: 0,
    signed: false
  };

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected injector: Injector,
    public funcaoService: FuncaoService,
    protected messageService: MessageService,
    private confirmationService: ConfirmationService,
    protected globalService: GlobalService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.abrir && this.abrir) {

      this.itens = this.entidade.itens.filter(m => m.cota === 'NAO_DEFINIDO' && m.produto_unidade);
      this.itens.forEach(m => m.selecionadoCota = false);
      this.calcularCota();

      this.abrir = false;
      this.abrirChange.emit(false);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public toggleSelecionado() {
    // marcado === false marcado
    // marcado === true desmarcado
    this.itens.forEach(l => l.selecionadoCota = !this.marcado)
  }

  public async confirmar() {
    let arredondarCota = false;
    $('#dialogMemorialCota').modal('hide');
    for (const item of this.itens.filter(m => m.selecionadoCota && +m['quantidadeCota'] > 0)) {
      if (item['quantidadeCota'] < item.quantidade * 0.25) {    
            arredondarCota = true;
      }
    } 
    if(arredondarCota){
      await this.confirmationService.confirm({
        message: 'Deseja arredondar os itens a favor das cotas reservadas?',
        header: 'Confirmar',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        key: 'arrendondarCota',
        icon: 'pi pi-question-circle',
        accept: () => {
          for (const item of this.itens.filter(m => m.selecionadoCota && +m['quantidadeCota'] > 0)) {
            if (item['quantidadeCota'] < item.quantidade * 0.25) {
              item['quantidadeCota'] += 1;
            } 
          }
          this.retornarItens();
        },
        reject: () => {
          this.retornarItens();
        }
      });     
    }else{
      this.retornarItens();
    }

    
  }

  public retornarItens(){
    const itensCota: Memorial[] = [];
    for (const item of this.itens.filter(m => m.selecionadoCota && +m['quantidadeCota'] > 0)) {
      const itemCota: Memorial = Object.assign({}, item);
      itemCota.id = undefined;
      delete itemCota.propostas;

      itemCota.quantidade = item['quantidadeCota'];
      item.quantidade = +item.quantidade - +itemCota.quantidade;

      item.cota = 'PRINCIPAL';
      itemCota.cota = 'RESERVADO';

      itensCota.push(Object.assign({}, itemCota))
    }
    this.callback.emit(itensCota.filter(m => m.cota === 'RESERVADO'));
    this.marcado = false;
  }


  public calcularCota() {
    for (const item of this.itens) {
      item['quantidadeCota'] = Math.floor(item.quantidade * 0.25);
    }
  }
}
