import { CriterioDesempatePregao, CriterioJulgamento, CriterioJulgamentoValor, FormatoLicitacao, SituacaoLicitacao, SituacaoPregao, TipoIntegracaoLicitacao, TipoObjetoLicitacao, TipoPregao } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Setor } from '../almoxarifado/setor.model';
import { Contrato } from '../compra/contrato.model';
import { Prazo } from '../compra/prazo.model';
import { Exercicio } from '../comum/exercicio.model';
import { OrgaoAudesp } from '../comum/orgao-audesp.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { Comissao } from './comissao.model';
import { LicitacaoAudesp } from './licitacao-audesp.model';
import { LicitacaoComissaoMembro } from './licitacao-comissao-membro.model';
import { LicitacaoFiscal } from './licitacao-fiscal.model';
import { LicitacaoOcorrencia } from './licitacao-ocorrencia.model';
import { LicitacaoStorage } from './licitacao-storage.model';
import { LicitacaoTestemunha } from './licitacao-testemunha.model';
import { ListaVerificacao } from './lista-verificacao.model';
import { Memorial } from './memorial.model';
import { Modalidade } from './modalidade.model';
import { PregaoHistorico } from './pregao-historico.model';
import { Proponente } from './proponente.model';
import { TipoContratacao } from './tipo-contratacao.model';

export class Licitacao extends BaseResourceModel {
  constructor(
    public id?: number,
    public formato_contratacao?: FormatoLicitacao,
    public justificativa?: string,
    public data_inicio?: Date,
    public data_termino?: Date,
    public prazo_pagamento?: Prazo,
    public prazo_entrega?: Prazo,
    public numero?: string,
    public sequencia?: number,
    public data_abertura?: Date,
    public data_ata?: Date,
    public data_edital?: Date,
    public data_julgamento?: Date,
    public data_parecer?: Date,
    public data_publicacao?: Date,
    public data_adjudicacao?: Date,
    public data_homologacao?: Date,
    public data_cancelamento?: Date,
    public data_assinatura?: Date,
    public data_ratificacao?: Date,
    public data_impugnacao?: Date,
    public data_esclarecimento?: Date,
    public data_abertura_env?: Date,
    public data_entrega_env?: Date,
    public data_transparencia?: Date,
    public deixar_salvar?: boolean,
    public situacao?: SituacaoLicitacao,
    public situacao_pregao?: SituacaoPregao,
    public processo?: string,
    public objeto?: string,
    public valor_estimado?: number,
    public valor_licitado?: number,
    public enviar_transparencia?: boolean,
    public chamamento_publico?: boolean,
    public criterio_julgamento?: CriterioJulgamento,
    public criterio_julgamento_valor?: CriterioJulgamentoValor,
    public tipo_pregao?: TipoPregao,
    public criterio_desempate?: CriterioDesempatePregao,
    public fracassar_auto?: boolean,
    public exibir_porcentagem?: boolean,
    public credenciamento?: boolean,
    public modelo_ata?: string,
    public natureza?: number,
    public tipo_licitacao?: number,
    public objeto_licitacao?: number,
    public lei_1232006?: number,
    public tipo_objeto?: TipoObjetoLicitacao,
    public modalidade?: Modalidade,
    public orgao?: Orgao,
    public setor?: Setor,
    public exercicio?: Exercicio,
    public comissao?: Comissao,
    public tipo_contratacao?: TipoContratacao,
    public processo_cc?: Licitacao,
    public orgao_audesp?: OrgaoAudesp,
    public licitacao_audesp?: LicitacaoAudesp,
    public itens?: Memorial[],
    public proponentes?: Proponente[],
    public fiscais?: LicitacaoFiscal[],
    public testemunhas?: LicitacaoTestemunha[],
    public ocorrencias?: LicitacaoOcorrencia[],
    public historicos?: PregaoHistorico[],
    public contratos?: Contrato[],
    public comissao_membros?: LicitacaoComissaoMembro[],
    public vencedores?: any,
    public selecionado?: boolean,
    public tabela_desconto?: boolean,
    public justificativa_remocao?: string,
    public usuario_remocao?: Usuario,
    public ordem_alfabetica_memorial?: boolean,
    public data_remocao?: Date,
    public clausulas_penais?: string,
    public clausulas_financeiras?: string,
    public amparo_legal: number = 0,
    public informacao_complementar?: string,
    public justificativa_presencial?: string,
    public sequencial_pncp?: number,
    public cadastrado_pncp?: boolean,
    public situacao_pncp?: number,
    public excluido_pncp?: boolean,
    public link_origem_lance?: string,
    public modo_disputa: number = 0,
    public forma_participacao?: string,
    public tipo_disputa?: string,
    public data_inicio_proposta?: Date,
    public data_fim_proposta?: Date,
    public data_inicio_pregao?: Date,
    public edital?: string,
    public prazo_proposta?: string,
    public arquivos?: LicitacaoStorage[],
    public lei_14133_2021?: boolean,
    public procedimento_auxiliar?: number,
    public permite_subcontratacao?: boolean,
    public lista_verificacao?: ListaVerificacao,
    public ignorar_pncp?: boolean,
    public categoria_processo?: number,
    public enviado_integracao?: boolean,
    public recebido_integracao?: boolean,
    public tipo_integracao?: TipoIntegracaoLicitacao,
    public exige_garantia?: boolean,
    public exige_beneficio?: boolean,
  ) {
    super();
  }
  static converteJson(json: any): Licitacao {
    return Object.assign(new Licitacao(), json);
  }
}
