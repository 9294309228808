import { Component, OnInit, Input, AfterViewInit, OnDestroy, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MessageService } from 'primeng/api';
import * as toastr from 'toastr';
import { EmpenhoExtraService } from '../service/empenho-extra.service';
import { EmpenhoExtra, Login, GlobalService, FuncaoService, DateFormatPipe, RetencaoExtra } from 'eddydata-lib';

declare var $: any;

@Component({
  selector: 'lib-empenho-extra-parcela',
  templateUrl: './empenho-extra-parcela.component.html'
})
export class EmpenhoExtraParcelaComponent implements OnInit, AfterViewInit, OnDestroy {

  protected datepipe: DatePipe;
  public ptBR: any;
  public data: Date;
  public data_vencimento: Date;
  public valor: number;
  public historico: string;
  public listParcelas: Array<EmpenhoExtra>;
  public listaRetencoes: Array<RetencaoExtra> = []
  protected unsubscribe: Subject<void> = new Subject();

  @Input() login: Login;
  @Input() empNumero: number;
  @Input() empenhoId: number;
  @Input() empenhoExtra: EmpenhoExtra;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  @Output() onFinalizar: EventEmitter<void> = new EventEmitter();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected messageService: MessageService,
    private globalService: GlobalService,
    private funcaoService: FuncaoService,
    private empenhoService: EmpenhoExtraService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.data = new DateFormatPipe().transform(new Date(), ['local']);
    this.valor = 0;
    this.historico = '';
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    this.globalService.calendarMascara();
  }

  parcelar() {
    const dataVencimento = new DateFormatPipe().transform(this.data_vencimento, ['local']);
    this.route.paramMap
      .pipe(switchMap(params => this.empenhoService.parcelar(
        Number(this.empenhoId),
        String(this.funcaoService.converteDataSQL(this.data)),
        String(this.funcaoService.converteDataSQL(dataVencimento)),
        this.valor,
        this.historico,
        this.login.usuario,
        this.listaRetencoes
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (dados) => {
          $('#dialogParcelar').modal('hide');
          this.router.navigate(['/empenhos-extra']);
          toastr.success('Parcela criada com sucesso!');
          this.onFinalizar.emit();
        }, error => {
          console.log(error);
          if (error.error && error.error.payload) {
            toastr.error(error.error.payload);
          } else {
            toastr.error('Ocorreu um erro ao processar a sua solicitação');
          }
        });
    this.empenhoExtra = null
  }

  buscarParcelas() {
    this.route.paramMap
      .pipe(switchMap(params => this.empenhoService.filtrar(0, -1,
        {
          relations: 'usuario_cadastro',
          especie: 'SEA',
          exercicio_id: this.login.exercicio.id,
          orgao_id: this.login.orgao.id,
          numero: this.empNumero
        }
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (dados) => {
          this.listParcelas = dados.content;
        }, error => {
          if (error.error && error.error.payload) {
            toastr.error(error.error.payload);
          } else {
            toastr.error('Ocorreu um erro ao processar a sua solicitação');
          }
        });
  }
}
