import { Component, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Coluna, FormatoExportacao } from "../../components/types";
import { Comunicado } from "../../entidade/compra/comunicado.model";
import { LoginContabil } from "../../entidade/login/login-contabil";
import { BaseResourceListComponent, Filtro } from "../../models/base-resource-list";
import { GlobalService } from "../../util/global.service";
import { ComunicadoService } from "../service/comunicado.service";

@Component({
  selector: 'lib-comunicado-list',
  templateUrl: './comunicado-list.component.html',
  providers: []
})
export class ComunicadoListComponent extends BaseResourceListComponent<Comunicado, LoginContabil> {

  /**
   * Declaração de variáveis
   */
   public mes: string;
 
   /**
    * Construtor com as injeções de dependencias
    */
   constructor(
     protected injector: Injector,
     protected globalService: GlobalService,
     protected comunicadoService: ComunicadoService) {
     super(comunicadoService, injector);
   }

  protected condicoesGrid(): {} {
    let condicoesGrid = {
      'orgao.id': this.login.orgao.id,
      'relations': 'usuario'
    };
    return condicoesGrid;
  }
  protected ordenacaoGrid(): string[] {
    return ['data_cadastro$DESC'];
  }
  protected filtrosGrid(): Filtro {
    return {
      text: [
        'titulo', 'usuario.nome'
      ],
      number: [
        'codigo'
      ]
    };
  }
  protected afterInit(): void {
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
    
  }
  protected acaoRemover(model: Comunicado): Observable<Comunicado> {
    return null;
  }
  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'id' },
      { titulo: 'Título', coluna: 'titulo' },
      { titulo: 'Responsável', coluna: 'usuario.nome' }
    ];
  }
  protected relations(): string {
    return '';
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    this.comunicadoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE COMUNICADOS`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem de comunicados', ['auto', '*', '*'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }
}
