// tslint:disable: variable-name
import { Orgao } from '../comum/orgao.model';
import { Exercicio } from '../comum/exercicio.model';
import { BaseResourceModel } from '../../models/base-resource.model';

export class GrupoReceita extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public orgao?: Orgao,
    public exercicio?: Exercicio,
  ) {
    super();
  }

  static converteJson(json: any): GrupoReceita {
    return Object.assign(new GrupoReceita(), json);
  }

}
