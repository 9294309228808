import { Component, ElementRef, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  ContratoService, ConvenioService, DespesaService, EmpenhoAnexoService, LicitacaoService, PropostaService
} from 'administrativo-lib';
import { FichaDespesaService } from 'contabil-lib';
import {
  AssinaturaService, BaseResourceFormComponent, Compra, CompraItem, DateFormatPipe, EmailService,
  Exercicio, Executora, FavorecidoService, FichaDespesa, FuncaoService,
  GlobalService, LoginContabil, OrgaoAssinaturaService, ParametroCompraService, PessoaService, Recurso
} from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ModalidadeService } from '../../modalidade/service/modalidade.service';
import { NotaCompra } from '../../relatorio-compra/nota-compra-rpt/nota-compra-rpt';
import { CompraItemService } from '../service/compra-item.service';
import { CompraService } from '../service/compra.service';


type SaldoFixa = {
  dotacao?: number,
  reservado?: number,
  saldo?: number,
  empenhado?: number,
  compra?: number,
  rcms?: number
}

declare var $: any;

@Component({
  selector: 'app-compra-view',
  templateUrl: './compra-view.component.html'
})
export class CompraViewComponent extends BaseResourceFormComponent<Compra, LoginContabil> implements OnChanges {

  /**
   * Auto completes
   */

  /**
  * Declaração de variáveis
  */

  @Input() id: number;
  @Output() idChange: EventEmitter<number> = new EventEmitter();
  @Input() component: boolean = false;

  public listaItens: CompraItem[] = [];
  public saldoFixa: SaldoFixa;
  public visualizarRCMS: boolean = false;
  public visualizarFicha: boolean = false;
  public tipoEmail: string[] = ['R', 'F'];
  public tab: number = 1;
  public recurso: Recurso;
  public aplicacao: Recurso;
  public unidade: Executora;
  public saldoConvenio: number;
  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    public funcaoService: FuncaoService,
    protected favorecidoService: FavorecidoService,
    protected anexoService: EmpenhoAnexoService,
    protected despesaService: DespesaService,
    protected fichaService: FichaDespesaService,
    protected licitacaoService: LicitacaoService,
    private propostaService: PropostaService,
    protected contratoService: ContratoService,
    protected convenioService: ConvenioService,
    private compraItemService: CompraItemService,
    protected modalidadeService: ModalidadeService,
    protected itemService: CompraItemService,
    protected assinaturaService: AssinaturaService,
    protected pessoaService: PessoaService,
    private paramentroCompraService: ParametroCompraService,
    protected orgaoAssinaturaService: OrgaoAssinaturaService,
    private emailService: EmailService,
    private confirmationService: ConfirmationService,
    protected compraService: CompraService) {
    super(new Compra(), injector, Compra.converteJson, compraService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  ngOnInit(): void {
    if (!this.component)
      super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.component && changes.id && this.id)
      super.ngOnInit();
  }

  protected async loadResource(): Promise<void> {
    if (this.component) {
      this.baseResourceService.obter(
        Object.assign({}, { id: this.id }, this.parametrosExtras())
      ).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (entidade) => {
            if (entidade) {
              this.entidade = entidade;
              if (this.datePipe())
                for (let pipe of this.datePipe()) {
                  if (this.entidade[pipe])
                    this.entidade[pipe] = new DateFormatPipe().transform(this.entidade[pipe], [])
                }
              this.afterLoad();
              if (!this.readOnly) {
                this.entidadeForm.patchValue(entidade);
              }
            } else {
              this.sair();
            }
          }, (error) => {
            this.sair();
          });
    } else {
      super.loadResource();
    }
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      tipo_compra: ['O', [Validators.required]],
      data_compra: [new Date(), [Validators.required]],
      prazo_entrega: [null, [Validators.required]],
      prazo_pagamento: [null, [Validators.required]],
      adiantamento: [false, [Validators.required]],
      documento: [null],
      modalidade: [null, Validators.required],
      observacao: [null],
      ficha: [null, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      favorecido: [null, [Validators.required]],
      subelemento: [null, [Validators.required]],
      rcms: this.fb.group({
        id: [null],
        numero: [null]
      }),
      ata: [null],
      licitacao: this.fb.group({
        id: [null],
        numero: [null]
      }),
      processo: [null],
      contrato: this.fb.group({
        id: [null],
        numero: [null],
      }),
      convenio: [null],
      itens: [null],
      requerente: [null, [Validators.required]],
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: [
        "operador", "ficha", "modalidade", "licitacao", "subelemento.vpd", "convenio", "favorecido.tipo", "contrato", "contrato_aditamento", "contrato.tipo_contratacao", "contrato", "ficha.despesa", "ficha.acao", "ficha.recurso", "ficha.executora.unidade", "ficha.aplicacao", "exercicio", "orgao", "requerente", "rcms.veiculo", "prazo", "empenho", "empenho.exercicio", "setor.unidade", "prazo_entrega", "prazo_pagamento",
      ],
    };
  }

  protected afterInit(): void { }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected datePipe(): string[] {
    return ['data_compra', 'data_pagamento', 'data_entrega'];
  }

  protected async afterLoad() {

    if (this.entidade?.subelemento?.vpd) {
      this.entidadeForm.get("adiantamento").setValue(true)
    } else {
      this.entidadeForm.get("adiantamento").setValue(false)
    }

    this.buscarSaldoFixa(true);

    this.compraItemService.filtrar(1, -1, {
      'compra.id': this.entidade.id, relations:
        ['produto_unidade.produto', 'produto_unidade.unidade'].join(','), orderBy: 'ordem'
    })
      .subscribe((data) => {
        if (data?.content)
          this.listaItens = data.content;
      });

    if (this.entidade?.convenio?.id) {
      this.saldoConvenio = await this.convenioService.buscarSaldoConvenio(
        this.entidade?.convenio?.id,
        this.funcaoService.converteDataSQL(this.entidadeForm.get('data_compra').value),
        this?.login?.orgao?.id

      ).toPromise();
    }
  }

  protected beforeSubmit() { }

  protected afterSubmit(ent: Compra) { }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================


  private buscarSaldoFixa(init?: boolean) {
    const exercicio: Exercicio = this.entidade.exercicio ? this.entidade.exercicio : this.entidadeForm.get('exercicio').value;
    const ficha: FichaDespesa = this.entidade.ficha ? this.entidade.ficha : this.entidadeForm.get('ficha').value;
    const dataCompra: Date = this.entidadeForm.get('data_compra').value;

    if (!ficha || !dataCompra || !exercicio)
      return;

    this.fichaService.saldoAtualFicha(ficha.id, this.login.orgao.id, this.login.exercicio.id,
      String(this.funcaoService.converteDataSQL(new DateFormatPipe().transform(dataCompra, ['local']))),
      (this.entidade.id ? { compraId: this.entidade.id } : null), true).subscribe((data) => {
        if (data)
          this.carregarSaldoFixa(data, init);
      })
  }

  private carregarSaldoFixa(totalizadores: any, init?: boolean) {
    if (!totalizadores)
      return;

    // if (totalizadores.saldo_atual <= 0 && !init) {
    //   toastr.warning(`Ficha de '${this.entidadeForm.get('ficha').value.despesa.nome}' não possui saldo para estar realizando a requisição`);
    // }

    this.saldoFixa = {};

    this.saldoFixa.empenhado = +totalizadores.total_empenhado;
    this.saldoFixa.rcms = +totalizadores.total_rcms_pendente;
    this.saldoFixa.compra = +totalizadores.total_compra_pendente;
    this.saldoFixa.dotacao = +totalizadores.total_dotacao + +totalizadores.total_creditado;
    this.saldoFixa.reservado = +totalizadores.total_reservado;
    this.saldoFixa.saldo = +totalizadores.saldo_atual;
  }

  public valorTotal() {
    let valorTotal = 0.0;
    if (!this.listaItens || this.listaItens.length === 0)
      return valorTotal;
    for (let item of this.listaItens) {
      valorTotal += item.quantidade * item.valor_unitario;
    }
    return valorTotal;
  }

  public selecionarTab(tab: number) {
    this.tab = tab;
  }

  public tabSelecionado(tab: number) {
    return this.tab === tab;
  }

  public tipoCompra(tipo: string) {
    let tiposCompra = this.globalService.obterListaTiposEmpenhos();
    let tipocompra = tiposCompra.find((t) => t.id === tipo);
    if (tipocompra)
      return tipocompra.nome;
    return '';
  }

  public email() {
    this.entidade.itens = this.listaItens;

    let mensagem = '';
    if (this.tipoEmail.includes('R'))
      mensagem += `<br />Requerente ${this.entidade.requerente.nome}(${this.entidade.requerente.email})`;
    if (this.tipoEmail.includes('F'))
      mensagem += `<br />Fornecedor ${this.entidade.favorecido.nome}(${this.entidade.favorecido.email})`;

    if (mensagem.length === 0)
      return;

    this.confirmationService.confirm({
      message: `Encaminhar por e-mail a ordem de fornecimento para: ${mensagem}?`,
      header: `Envio de Ordem de Fornecimento - Nº: ${this.entidade.numero}`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      key: 'compra',
      accept: () => {
        this.enviarEmail(this.entidade, true);
      }
    });
  }

  public async enviarEmail(compra: Compra, validar?: boolean) {
    const anexo = (await new NotaCompra(this.orgaoAssinaturaService, this.compraService, this.login).exportar([compra]))

    this.paramentroCompraService.obter({
      'orgao.id': compra.orgao.id
    }).subscribe((parametroCompra) => {
      let mensagem = '';
      if (!parametroCompra)
        return;
      if (parametroCompra.mensagem_of)
        mensagem += parametroCompra.mensagem_of;
      if (((validar && this.tipoEmail.includes('R')) || (!validar && parametroCompra.email_requerente)) && compra.requerente.email) {
        let subject = `Envio de Ordem de Fornecimento - Nº OF: ${compra.numero}`;
        let message = '<p><b>Ordem de Fornecimento enviado para o requerente:  </b>' + compra.requerente.nome
          + '<br><b>E-mail do requerente: </b>' + compra.requerente.email
          + '<br><b>Número da OF: </b>' + compra.numero
          + '<br><b>Data: </b>' + this.funcaoService.converteDataBR(new Date()) + '</p>';
        message += mensagem ? '<br />' + mensagem : '';
        this.emailService.enviar({ titulo: subject, corpo: mensagem, destinos: [{ nome: compra.requerente.nome, email: compra.requerente.email, }], anexos: [{ arquivo: anexo, nome: 'of.pdf' }] })
          .subscribe((data) => {
            console.log(data)
            toastr.success(`E-mail encaminhado para o requerente ${compra.requerente.nome}(${compra.requerente.email})`)
          }, (error) => toastr.error(`Não foi possivel encaminhar o e-mail para requerende ${compra.requerente.nome}(${compra.requerente.email})`));
      }
      if (((validar && this.tipoEmail.includes('F')) || (!validar && parametroCompra.email_favorecido)) && compra.favorecido.email) {
        let subject = `Envio de Ordem de Fornecimento - Nº OF: ${compra.numero}`;
        let message = '<p><b>Ordem de Fornecimento enviado para o fornecedor:  </b>' + compra.favorecido.nome
          + '<br><b>E-mail do fornecedor: </b>' + compra.favorecido.email
          + '<br><b>Número da OF: </b>' + compra.numero
          + '<br><b>Data: </b>' + this.funcaoService.converteDataBR(new Date()) + '</p>';
        message += mensagem ? '<br />' + mensagem : '';
        this.emailService.enviar({ titulo: subject, corpo: mensagem, destinos: [{ nome: compra.favorecido.nome, email: compra.favorecido.email }], anexos: [{ arquivo: anexo, nome: 'of.pdf' }] })
          .subscribe((data) => {
            toastr.success(`E-mail encaminhado para o fornecedor ${compra.favorecido.nome}(${compra.favorecido.email})`)
          }, (error) => toastr.error(`Não foi possivel encaminhar o e-mail para fornecedor ${compra.favorecido.nome}(${compra.favorecido.email})`));
      }
    });
  }

  public imprimir() {
    this.entidade.itens = this.listaItens;
    new NotaCompra(this.orgaoAssinaturaService, this.compraService, this.login, null, null, this.propostaService)
      .imprimir([this.entidade]);
  }

  voltar() {
    if (this.component) {
      this.id = null;
      this.idChange.emit(this.id);
    } else
      window.history.back();
  }
}
