import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseResourceService } from '../../models/services/base-resource.service';
import { GlobalService } from '../../util/global.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService extends BaseResourceService<any> {

  constructor(
    protected injector: Injector
  ) {
    super(`emails`, injector);
  }

  enviar(resource: {
    titulo: string,
    corpo: string,
    destinos: { nome: string, email: string }[],
    anexos?: any[],
  }): Observable<{ nome: string, email: string, ok: boolean }[]> {
    const formData = new FormData();
    formData.append('titulo', resource.titulo);
    formData.append('corpo', resource.corpo);
    formData.append('destinos', JSON.stringify(resource.destinos));
    if (resource.anexos) {
      for (let arquivo of resource.anexos) {
        if (arquivo['nome'])
          formData.append('files', arquivo.arquivo, arquivo['nome']);
        else
          formData.append('files', arquivo);
      }
    }
    this.login = GlobalService.obterSessaoLogin();
    /*
    return this.http.post(`${this.login.cidade.id}/${this.api}/enviar/${this.login.orgao.id}`, formData, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
    */

    const headers = this.httpOptions();
    headers.headers = headers.headers.delete('Content-Type');

    return this.http.post(`${this.login.cidade.id}/${this.api}/enviar/${this.login.orgao.id}`,
      formData, headers)
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
