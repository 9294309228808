import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecebimentoListComponent } from './recebimento-list/recebimento-list.component';
import { RecebimentoViewComponent } from './recebimento-view/recebimento-view.component';
import { PlanilhaArrecadacaoComponent } from './planilha-arrecadacao/planilha-arrecadacao.component';
import { RecebimentoFormComponent } from './recebimento-form/recebimento-form.component';
import { RecebimentoRptComponent } from './recebimento-rpt/recebimento-rpt.component';
import { AuthGuard } from 'eddydata-lib';
import { ImportarRecebimentoComponent } from './importar-recebimento/importar-recebimento.component';

const routes: Routes = [
  { path: '', component: RecebimentoListComponent, canActivate: [AuthGuard] },
  { path: ':especie/filtro', component: RecebimentoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: RecebimentoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: RecebimentoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: RecebimentoViewComponent, canActivate: [AuthGuard] },
  { path: 'planilha-arrecadacao', component: PlanilhaArrecadacaoComponent, canActivate: [AuthGuard] },
  { path: 'importar', component: ImportarRecebimentoComponent, canActivate: [AuthGuard] },
  { path: 'relatorios', component: RecebimentoRptComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecebimentoRoutingModule { }
