import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmpenhoListComponent } from './empenho-list/empenho-list.component';
import { EmpenhoFuncaoListComponent } from './empenho-funcao-list/empenho-funcao-list.component';
import { EmpenhoFormComponent } from './empenho-form/empenho-form.component';
import { EmpenhoRptComponent } from './empenho-rpt/empenho-rpt.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: EmpenhoListComponent, canActivate: [AuthGuard] },
  { path: ':especie/filtro', component: EmpenhoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: EmpenhoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: EmpenhoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: EmpenhoFormComponent, canActivate: [AuthGuard] },
  { path: 'funcao-governo', component: EmpenhoFuncaoListComponent, canActivate: [AuthGuard] },
  { path: 'relatorios', component: EmpenhoRptComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmpenhoRoutingModule { }
