import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { SetorResponsavelService } from '../service/setor-responsavel.service';
import { SetorResponsavel } from '../../../entidade/almoxarifado/setor-responsavel.model';
import { EddyAutoComplete } from '../../../models/form-components';
import { FuncaoService } from '../../../util/funcao.service';
import * as toastr from 'toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { Pessoa } from '../../../entidade/comum/pessoa.model';
import { Setor } from '../../../entidade/almoxarifado/setor.model';
import { Login } from '../../../entidade/login/login';
import { GlobalService } from '../../../util/global.service';
import { PessoaService } from '../../../pessoa/service/pessoa.service';
import { DateFormatPipe } from '../../../components/pipe/date-format.pipe';

@Component({
  selector: 'app-setor-responsavel',
  templateUrl: './setor-responsavel.component.html'
})
export class SetorResponsavelComponent implements OnInit, OnChanges, OnDestroy {

  protected unsubscribe: Subject<void> = new Subject();
  public responsavelAutoComplete: EddyAutoComplete<Pessoa>;
  public ptBR: any;
  public item: SetorResponsavel;

  @Input() lista: Array<any>;
  @Input() setor: Setor;
  @Input() login: Login;
  @Output() callback: EventEmitter<SetorResponsavel> = new EventEmitter();

  @ViewChild('btnAdicionar') public btnAdicionar: ElementRef;

  constructor(protected funcaoService: FuncaoService,
    private messageService: MessageService,
    public globalService: GlobalService,
    protected setorResponsavelService: SetorResponsavelService,
    protected pessoaService: PessoaService) {
  }

  ngOnInit(): void {
    this.carregarAutoCompletes();
  }

  ngOnChanges(changes) {
    if (changes.setor) {
      this.setorResponsavelService.obterPorResponsavel(changes.setor.currentValue.id)
        .subscribe(responsaveis => this.lista = Array.from(responsaveis as SetorResponsavel[]));
    }
    if (changes.data_inicio) {
      changes.data_inicio = new DateFormatPipe().transform(changes.data_inicio, []);
    }
    if (changes.data_termino) {
      changes.data_termino = new DateFormatPipe().transform(changes.data_termino, []);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public adicionar() {
    if (!this.lista) {
      this.lista = [];
    }
    this.lista.unshift({ editavel: true });
  }

  public salvar(item: SetorResponsavel) {
    try {
      if (!item.responsavel) {
        throw new Error('Informe o responsável!');
      }
      if (!item.data_inicio) {
        throw new Error('Informe a data de início!');
      }
      if (!item.data_termino) {
        throw new Error('Informe a data de término!');
      }
      if (item.data_inicio > item.data_termino) {
        throw new Error('Informe uma data de início menor que a data de término!');
      }
      if (item.data_termino < item.data_inicio) {
        throw new Error('Informe uma data de término maior que a data de início!');
      }

      item['visivel'] = true;

      item.setor = this.setor;

      item.setor.orgao = this.login.orgao;

      if (item.id) {
        this.setorResponsavelService.atualizar(item).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro alterado com sucesso!');
            item['editavel'] = false;
            this.callback.emit(res);
          },
            error => toastr.error('Falha ao alterar registro!'));
      } else {
        this.setorResponsavelService.inserir(item).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro inserido com sucesso!');
            item['editavel'] = false;
            if (res) {
              item.id = res.id;
              this.callback.emit(res);
            }
          },
            error => toastr.error('Falha ao inserir registro!'));
      }

      this.btnAdicionar.nativeElement.disabled = false;

    } catch (e) {
      toastr.error(e.message);
      throw e;
    }
  }

  public editar(item: SetorResponsavel) {
    try {
      if (!item.responsavel) {
        throw new Error('Informe o responsável!');
      }
      if (!item.data_inicio) {
        throw new Error('Informe a data de início!');
      }
      if (!item.data_termino) {
        throw new Error('Informe a data de término!');
      }
      if (item.data_inicio > item.data_termino) {
        throw new Error('Informe uma data de início menor que a data de término!');
      }
      if (item.data_termino < item.data_inicio) {
        throw new Error('Informe uma data de término maior que a data de início!');
      }

      item['editavel'] = true;

      item.data_inicio = new DateFormatPipe().transform(item.data_inicio, []);
      item.data_termino = new DateFormatPipe().transform(item.data_termino, []);

      if (item.id) {
        this.setorResponsavelService.atualizar(item).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
          },
            error => toastr.error('Falha ao alterar registro!'));
      }

      this.btnAdicionar.nativeElement.disabled = false;

    } catch (e) {
      toastr.error(e.message);
      throw e;
    }
  }

  public cancelar(item: SetorResponsavel) {
    item['editavel'] = false;
  }

  public remover(item: SetorResponsavel) {
    if (item.id) {
      this.setorResponsavelService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          toastr.info('Registro removido com sucesso!');
        });
    }
    this.lista.splice(this.lista.findIndex((i) => { return i === item }), 1);
    this.btnAdicionar.nativeElement.disabled = false;
  }

  protected validSave(item: SetorResponsavel): boolean {
    if (!item.responsavel) {
      toastr.warning('Informe o responsável!');
      return false;
    }
    return true;
  }

  protected beforeSave(item: SetorResponsavel): void { }

  public carregarResponsavel(pessoa: Pessoa) {
    this.item.responsavel = pessoa;
  }

  public cadastroResponsavel(item: SetorResponsavel) {
    this.item = item;
  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  private carregarAutoCompletes() {
    this.responsavelAutoComplete = new EddyAutoComplete(null, this.pessoaService,
      'id', ['nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );
  }

}
