import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FuncaoService } from 'eddydata-lib';

@Directive()
export class Anexo8DespesaCusteadasFundeb implements OnDestroy {

  protected funcaoService: FuncaoService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number) {
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(dados: any[]): {}[] {
    const registros: {}[] = [
      [{
        text: 'INDICADORES DO FUNDEB', colSpan: 6, fontSize: 8, bold: true,
        margin: [5, 5, 5, 5], alignment: 'center', border: [true, false, true, true]
      }, '', '', '', '', ''],
      [
        { text: 'DESPESAS CUSTEADAS COM RECEITAS DO FUNDEB RECEBIDAS NO EXERCÍCIO', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'DESPESAS EMPENHADAS ATÉ O BIMESTRE (d)', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'DESPESAS LIQUIDADAS ATÉ O BIMESTRE (e)', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'DESPESAS PAGAS ATÉ O BIMESTRE (f)', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'INSCRITAS EM RESTOS A PAGAR NÃO PROCESSADOS (g)', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'INSCRITAS EM RESTOS A PAGAR NÃO PROCESSADOS (SEM DISPONIBILIDADE DE CAIXA) (h)', alignment: 'center', bold: true, fontSize: 7 },
      ],
    ];

    const grupos = this.funcaoService.agrupar(dados, 'grupo', ['empenhado', 'liquidado', 'pago']);

    let total5 = 0;

    for (const grupo of grupos) {
      registros.push([
        { text: grupo.grupo, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado'] - grupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(total5), alignment: 'right', fontSize: 7 }
      ]);

      const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo',
        ['empenhado', 'liquidado', 'pago']);
      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo != '') {
          registros.push([
            { text: subgrupo.grupo, fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['pago']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado'] - subgrupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(total5), alignment: 'right', fontSize: 7 }
          ]);

          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              registros.push([
                { text: item.nome, fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.empenhado), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.liquidado), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.pago), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.resto), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(total5), alignment: 'right', fontSize: 7 },
              ]);
            }
          }
        }
      }
    }

    return registros;

  }

  public montarQuadroCsv(dados: any[], listaExportar): any {

    const espaco = {
      titulo: '',
      coluna1: '',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: ''
    }
    listaExportar.push(espaco)

    const primeira_linha = {
      titulo: 'INDICADORES DO FUNDEB',
      coluna1: '',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: ''
    }
    listaExportar.push(primeira_linha)

    const segunda_linha = {
      titulo: 'DESPESAS CUSTEADAS COM RECEITAS DO FUNDEB RECEBIDAS NO EXERCÍCIO',
      coluna1: 'DESPESAS EMPENHADAS ATÉ O BIMESTRE (d)', alignment: 'center',
      coluna2: 'DESPESAS LIQUIDADAS ATÉ O BIMESTRE (e)',
      coluna3: 'DESPESAS PAGAS ATÉ O BIMESTRE (f)',
      coluna4: 'INSCRITAS EM RESTOS A PAGAR NÃO PROCESSADOS (g)',
      coluna5: 'INSCRITAS EM RESTOS A PAGAR NÃO PROCESSADOS (SEM DISPONIBILIDADE DE CAIXA) (h)',
      coluna6: ''
    }
    listaExportar.push(segunda_linha)

    const grupos = this.funcaoService.agrupar(dados, 'grupo', ['empenhado', 'liquidado', 'pago']);

    let total5 = 0;

    for (const grupo of grupos) {
      const linha_grupo = {
        titulo: grupo.grupo,
        coluna1: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']), alignment: 'center',
        coluna2: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']),
        coluna3: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago']),
        coluna4: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado'] - grupo.totalizadores['liquidado']),
        coluna5: this.funcaoService.convertToBrNumber(total5),
        coluna6: ''
      }
      listaExportar.push(linha_grupo)

      const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo',
        ['empenhado', 'liquidado', 'pago']);
      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo != '') {
          const linha_subgrupo = {
            titulo: subgrupo.grupo,
            coluna1: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado']), alignment: 'center',
            coluna2: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liquidado']),
            coluna3: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['pago']),
            coluna4: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado'] - grupo.totalizadores['liquidado']),
            coluna5: this.funcaoService.convertToBrNumber(total5),
            coluna6: ''
          }
          listaExportar.push(linha_subgrupo)

          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              const linha_subgrupo_item = {
                titulo: item.nome,
                coluna1: this.funcaoService.convertToBrNumber(item.empenhado), alignment: 'center',
                coluna2: this.funcaoService.convertToBrNumber(item.liquidado),
                coluna3: this.funcaoService.convertToBrNumber(item.pago),
                coluna4: this.funcaoService.convertToBrNumber(item.empenhado - item.liquidado),
                coluna5: this.funcaoService.convertToBrNumber(total5),
                coluna6: ''
              }
              listaExportar.push(linha_subgrupo_item)
            }
          }
        }
      }
    }
  }

}