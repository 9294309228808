// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class ContaPublicaTipo extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public especie?: 'CP' | 'PLAN',
    public aux?: number,
    public orgao?: Orgao
  ) {
    super();
  }

  static converteJson(json: any): ContaPublicaTipo {
    return Object.assign(new ContaPublicaTipo(), json);
  }
}
