import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { MetaGovernoFormComponent } from './meta-governo-form/meta-governo-form.component';
import { MetaGovernoListComponent } from './meta-governo-list/meta-governo-list.component';
import { MetaGovernoViewComponent } from './meta-governo-view/meta-governo-view.component';

const routes: Routes = [
  { path: '', component: MetaGovernoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: MetaGovernoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: MetaGovernoViewComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: MetaGovernoFormComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MetaGovernoRoutingModule { }
