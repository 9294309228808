import { BaseResourceModel } from '../../models/base-resource.model';
import { PlanoContratacaoAnual } from './plano-contratacao-anual.model';

export class PlanoContratacaoItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public numero?: number,
    public categoria_item?: number,
    public catalogo?: number,
    public classificacao_catalogo?: number,
    public classificacao_superior?: string,
    public classificacao_superior_nome?: string,
    public pdm_codigo?: string,
    public pdm_descricao?: string,
    public codigo_item?: string,
    public descricao?: string,
    public unidade_fornecimento?: string,
    public quantidade?: number,
    public valor_unitario?: number,
    public valor_orcamentario?: number,
    public data_desejada?: Date,
    public unidade_requisitante?: string,
    public grupo_contratacao?: string,
    public grupo_contratacao_nome?: string,
    public cadastrado_pncp?: boolean,
    public subelemento?: string,
    public plano?: PlanoContratacaoAnual,
    public pendente?: boolean
  ) {
    super();
  }
  static converteJson(json: any): PlanoContratacaoItem {
    return Object.assign(new PlanoContratacaoItem(), json);
  }
}
