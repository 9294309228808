import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { RequisicaoItemMovimento } from 'eddydata-lib';
import * as toastr from 'toastr';
declare var $: any;

@Component({
  selector: 'app-recusar-requisicao-devolucao-dlg',
  templateUrl: './recusar-requisicao-devolucao-dlg.component.html'
})
export class RecusarRequisicaoDevolucaoDlgComponent implements OnInit, OnChanges {

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Input() itens: RequisicaoItemMovimento[] = [];
  @Output() itensChange: EventEmitter<RequisicaoItemMovimento[]> = new EventEmitter();

  public motivos: RequisicaoItemMovimento[] = [];
  public mesmaJustificativa: boolean = false;
  public index: number = 0;

  constructor(
    private router: Router,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.index = 0;
    this.mesmaJustificativa = false;
    if (this.itens) {
      this.itens.forEach((i) => console.log(+i.qtd_aceita, +i.qtd_aceita === 0));
      this.motivos = this.itens.filter((i) => +i.qtd_aceita === 0);
    }
  }

  ngOnInit() { }

  public validFinalizar(): boolean {
    if (this.mesmaJustificativa)
      return true;
    return this.motivos.length === (this.index + 1);
  }

  public proximo() {
    let motivo = this.motivos[this.index];
    if (!this.valid(motivo))
      return;
    if (this.validFinalizar())
      this.finalizar();
    else
      this.index++;
  }

  public finalizar() {
    if (this.mesmaJustificativa)
      for (let i = this.index; i < this.motivos.length; i++) {
        this.motivos[i].justificativa_recusado = this.motivos[this.index].justificativa_recusado;
      }
    this.itens = this.itens.map((i) => {
      let motivo = this.motivos.find((m) => m.id === i.id);
      if (motivo)
        return motivo;
      return i;
    });
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
    this.itensChange.emit(this.itens);
    this.index = 0;
    this.mesmaJustificativa = false;
  }

  public valid(item: RequisicaoItemMovimento): boolean {
    if (!item.justificativa_recusado) {
      toastr.warning('Não foi informado a justificativa para requisição recusada.');
      return false;
    }
    return true;
  }

  public onHide() {
    if (this.itens)
      this.itens = this.itens.map((i) => {
        i.justificativa_recusado = null;
        return i;
      })
    this.index = 0;
    this.mesmaJustificativa = false;
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

}
