import { Component } from '@angular/core';
import { BaseResourceItemsComponent, FavorecidoCei, GlobalService, } from 'eddydata-lib';
import * as toastr from 'toastr';
import { FavorecidoCeiService } from '../service/favorecido-cei.service';

@Component({
  selector: 'lib-favorecido-cei',
  templateUrl: './favorecido-cei.component.html'
})
export class FavorecidoCeiComponent extends BaseResourceItemsComponent<FavorecidoCei>{


  constructor(private globalService: GlobalService,
    public favorecidoCeiService: FavorecidoCeiService) {
    super(new FavorecidoCei(), favorecidoCeiService);
  }

  protected afterInit(): void {

  }

  protected validSave(item: FavorecidoCei): boolean {
    if (!item.cei) {
      toastr.warning('Informe o numero do CEI!');
      return false;
    } else {
      let itemRepetido = this.lista.filter(e => {
        return e.cei === item.cei && e.editavel !== true;
      })
      if (itemRepetido && itemRepetido.length > 0) {
        toastr.warning('Número do CEI já cadastrado!');
        return false;
      }
    }
    return true;
  }

  protected beforeSave(item: FavorecidoCei): void {
    item.orgao = this.login.orgao
  }



  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================




}
