import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-divisor',
  templateUrl: './divisor.component.html'
})
export class DivisorComponent implements OnInit {

  @Input() imagem: string;

  @Input() altura: string;

  @Input() posicao: string;

  constructor() { }

  ngOnInit() {
  }

}
