import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { Contrato } from './contrato.model';

export class ContratoRescisao extends BaseResourceModel {
  constructor(
    public data_rescisao?: Date,
    public data_termo?: Date,
    public data_publicacao?: Date,
    public motivo?: string,
    public fundamento?: string,
    public imprensa?: string,
    public tipo?: 'BILATERAL' | 'UNILATERAL',
    public solicitante?: 'CONTRATADO' | 'CONTRATANTE',
    public valor_multa?: number,
    public valor_indenizacao?: number,
    public contrato?: Contrato,
    public usuario?: Usuario,
    public sequencial_pncp?: number,
    public cadastrado_pncp?: boolean
    ) {
    super();
  }
  static converteJson(json: any): ContratoRescisao {
    return Object.assign(new ContratoRescisao(), json);
  }
}
