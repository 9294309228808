import { Component, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  BaseResourceFormComponent, Liquidacao, LoginContabil,
  GlobalService, FuncaoService, Filtro, DateFormatPipe, Retencao, OrgaoAssinaturaService
} from 'eddydata-lib';
import { LiquidacaoService } from 'administrativo-lib';
import { takeUntil } from 'rxjs/operators';
import { RetencaoService } from '../service/retencao.service';
import { MessageService } from 'primeng/api';
import { NotaLiquidacao } from '../../relatorio/liquidacao/nota-liquidacao';
import * as toastr from 'toastr';

@Component({
  selector: 'lib-liquidacao-view',
  templateUrl: './liquidacao-view.component.html'
})
export class LiquidacaoViewComponent extends BaseResourceFormComponent<Liquidacao, LoginContabil> {

  /**
   * Declaração de variáveis
   */

  public naoEditar = true

  public listaRetencoes: Array<Retencao>;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected retencaoService: RetencaoService,
    protected liquidacaoService: LiquidacaoService,
    protected assinaturaService: OrgaoAssinaturaService,
  ) {
    super(new Liquidacao(), injector, Liquidacao.converteJson, liquidacaoService);
  }


  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      // numero: [null, [Validators.required]],
      parcela: [null, [Validators.required]],
      valor_liquidado: 0,
      data_liquidacao: [null, [Validators.required]],
      data_vencimento: [null, [Validators.required]],
      historico: [null, [Validators.required]],
      mes: [null, [Validators.required]],
      documento: [null, [Validators.required]],
      anulacao: [null, [Validators.required, Validators.minLength(2)]],
      impresso: [null, [Validators.required]],
      orgao: [null, [Validators.required]],
      empenho: this.fb.group({
        id: [null],
        numero: [null, [Validators.required]],
        exercicio: this.fb.group({
          ano: [null, [Validators.required]],
        })
      }),
      exercicio: [null, [Validators.required]],
      preliquidacao: [null],
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations:
        'empenho,empenho.modalidade,empenho.ficha,empenho.ficha.acao,empenho.favorecido,empenho.subelemento,empenho.exercicio,'
        + 'preliquidacao.empenho,preliquidacao.usuario_cadastro,exercicio,orgao'
    };
  }

  protected relations(): string {
    return;
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao.id']: this.login.orgao.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['numero$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['empenho.numero', 'valor_liquidacao'],
      date: ['data_liquidacao'],
      text: ['empenho.favorecido.nome', 'empenho.subelemento.nome'],
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: Liquidacao): Observable<Liquidacao> {
    return null;
  }

  protected afterLoad() {
    this.entidadeForm.patchValue(this.entidade)
    this.entidade.data_liquidacao = new DateFormatPipe().transform(this.entidade.data_liquidacao, []);
    this.entidade.data_vencimento = new DateFormatPipe().transform(this.entidade.data_vencimento, []);
    this.obterRetencoes(this.entidade.id);

    if (this.entidade.anulacao === true && (this.funcaoService.converteDataSQL(new Date(this.entidade.data_cadastro)) === this.funcaoService.converteDataSQL(new Date()))) {
      this.naoEditar = false
    }
  }

  protected beforeSubmit(): void {

  }

  protected afterSubmit(entidade: Liquidacao): void {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
  private obterRetencoes(id: number) {
    this.retencaoService.obterPorLiquidacao(id).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data: any) => {
          this.listaRetencoes = data ? data.content : new Array<Retencao>();
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
      );
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public imprimir() {
    const parametros = {};

    let relations = '';
    relations += 'empenho.contrato,empenho.licitacao,empenho.modalidade,empenho.subelemento,empenho.ficha,';
    relations += 'empenho.favorecido,empenho.favorecido.tipo,empenho.ficha.executora.unidade,empenho.ficha.despesa,empenho.ficha.funcao,';
    relations += 'empenho.ficha.acao,empenho.ficha.aplicacao,empenho.ficha.recurso,empenho.ficha.aplicacao_variavel';
    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id'] = this.entidade.id;
    this.liquidacaoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        this.assinaturaService.obter()
        new NotaLiquidacao(this.assinaturaService).imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

  public gerarEmpenhosExtras() {
    this.liquidacaoService.gerarEmpenhosExtras(this.entidade?.mes).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        toastr.success(`Empenhos extras gerados com sucesso!`)
      }, (e) => this.funcaoService.acaoErro(e));
  };
}
