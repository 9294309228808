import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, MetaOds } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class MetaOdsService extends BaseResourceService<MetaOds> {

  constructor(
    protected injector: Injector
  ) {
    super(`metas-ods`, injector);
  }


}
