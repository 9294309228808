import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Rpl } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RPRplService extends BaseResourceService<Rpl> {

    constructor(
        protected injector: Injector
    ) {
        super(`rpls`, injector);
    }

    public proximoNumero(exercicio: number): Observable<number> {
        return this.http.get<number>(
            `${this.login.cidade.id}/${this.api}/proximo-numero/${exercicio}`, this.httpOptions()).pipe(
                map(res => res),
                catchError(err => this.handleError(err))
            );
    }
}
