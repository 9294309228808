import { BaseResourceModel } from '../../models/base-resource.model';
import { ProdutoUnidade } from '../almoxarifado/produto-unidade.model';
import { OrdemServico } from './ordem-servico.model';

export class OrdemServicoItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public qtde?: number,
    public valor_unitario?: number,
    public produto_unidade?: ProdutoUnidade,
    public ordem_servico?: OrdemServico,
  ) {
    super();
  }

  static converteJson(json: any): OrdemServicoItem {
    return Object.assign(new OrdemServicoItem(), json);
  }
}
