import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Login, RequisicaoMovimento, StatusRequisicao } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { RequisicaoMovimentoService } from '../service/requisicao-movimento.service';

declare var $: any;

@Component({
  selector: 'app-estorno-requisicao-dlg',
  templateUrl: './estorno-requisicao-dlg.component.html'
})
export class EstornoRequisicaoDlgComponent implements OnInit, AfterViewInit, OnDestroy {

  protected unsubscribe: Subject<void> = new Subject();

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Input() login: Login;
  @Input() requisicao: RequisicaoMovimento;

  constructor(
    private router: Router,
    private requisicaoService: RequisicaoMovimentoService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
  }

  estornar() {
    let requisicao = Object.assign({}, this.requisicao);

    requisicao.status = StatusRequisicao.ESTORNO;
    requisicao.data_estorno = new Date();
    requisicao.usuario_estorno = this.login.usuario;

    this.requisicaoService
      .atualizar(requisicao)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.onHide();
        setTimeout(() => {
          this.router.navigate(['/requisicoes-materiais']);
        }, 100);
        toastr.success('Estorno realizado com sucesso!');
      },
        error => {
          if (error.error && error.error.payload) {
            toastr.error(error.error.payload);
          } else {
            toastr.error('Não foi possível fazer o estorno da requisição!');
          }
        });

  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

}
