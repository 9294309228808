import { BaseResourceModel } from '../../models/base-resource.model';
import { MatrizLayout } from './matriz-layout.model';
import { PlanoConta } from './plano-conta.model';

export class 
MatrizLayoutConta extends BaseResourceModel {
  constructor(
    public id?: number,
    public inscritos_resto?: boolean,
    public layout?: MatrizLayout,
    public conta?: PlanoConta) {
    super();
  }
  static converteJson(json: any): MatrizLayoutConta {
    return Object.assign(new MatrizLayoutConta(), json);
  }
}