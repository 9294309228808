import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReservaDotacaoRoutingModule } from './reserva-dotacao-routing.module';
import { ReservaDotacaoListComponent } from './reserva-dotacao-list/reserva-dotacao-list.component';
import { ReservaDotacaoFormComponent } from './reserva-dotacao-form/reserva-dotacao-form.component';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { IMaskModule } from 'angular-imask';
import { ToastModule } from 'primeng/toast';
import { DeclaracaoFormComponent } from './delcaracao-form/declaracao-form.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DeclaracaoConvenioFederalComponent } from './declaracao-convenio-federal/declaracao-convenio-federal.component';
import { DeclaracaoFinanciamentoComponent } from './declaracao-financiamento/declaracao-financiamento.component';
import { DeclaracaoConvenioEstadualComponent } from './declaracao-convenio-estadual/declaracao-convenio-estadual.component';
import { SharedModule } from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DataViewModule } from 'primeng/dataview';
import { ReservaDotacaoViewComponent } from './reserva-dotacao-view/reserva-dotacao-view.component';
import { ReservaDotacaoItemComponent } from './reserva-dotacao-item/reserva-dotacao-item.component';
import { FieldsetModule } from 'primeng/fieldset';
import { ReservaDotacaoRpt } from './reserva-dotacao-rpt-dlg/reserva-dotacao-rpt-dlg.component';
import { ReservaDotacaoEstornoDlg } from './reserva-dotacao-estorno-dlg/reserva-dotacao-estorno-dlg.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { ReservaDotacaoConsultaDlgComponent } from './reserva-dotacao-consulta-dlg/reserva-dotacao-consulta-dlg.component';


@NgModule({
  declarations: [
    ReservaDotacaoListComponent,
    ReservaDotacaoFormComponent,
    ReservaDotacaoViewComponent,
    DeclaracaoFormComponent,
    DeclaracaoConvenioEstadualComponent,
    DeclaracaoConvenioFederalComponent,
    DeclaracaoFinanciamentoComponent,
    ReservaDotacaoItemComponent,
    ReservaDotacaoRpt,
    ReservaDotacaoEstornoDlg,
    ReservaDotacaoConsultaDlgComponent,
  ],
  imports: [
    CommonModule,
    ReservaDotacaoRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    ConfirmDialogModule,
    IMaskModule,
    ToastModule,
    RadioButtonModule,
    DataViewModule,
    FieldsetModule,
    InputNumberModule
  ],
  providers: [MessageService, ConfirmationService],
  exports: [ReservaDotacaoConsultaDlgComponent]
})
export class ReservaDotacaoModule { }
