import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { LiquidacaoRestoService } from "contabil-lib";
import { LiquidacaoResto, Login } from "eddydata-lib";
import { MessageService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-liquidacao-resto-parcela-dlg',
  templateUrl: './liquidacao-resto-parcela-dlg.component.html'
})
export class LiquidacaoRestoParcelaDlgComponent implements OnChanges {

  public imaskConfig = {
    mask: Number,
    scale: 2,
    signed: true,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  @Input() visualizar: boolean = false;
  @Input() login: Login;
  @Input() liquidacaoAnular: LiquidacaoResto;
  @Output() atualizarVisualizar: EventEmitter<void> = new EventEmitter();

  public parcelas: LiquidacaoResto[] = [];
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected liquidacaoService: LiquidacaoRestoService,
    protected messageService: MessageService,
  ) { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes && changes['liquidacaoAnular'] && this.liquidacaoAnular?.id) {
      this.parcelas = []
      let parcela = await this.obterUltimaParcela()
      console.log(parcela)
      this.parcelas.push({
        ...this.liquidacaoAnular,
        data_liquidacao: new Date(this.liquidacaoAnular?.data_liquidacao),
        valor_liquidado: this.liquidacaoAnular?.valor_liquidado / 2,
        parcela: parcela + 1,
        id: null
      });
      this.parcelas.push({
        ...this.liquidacaoAnular,
        data_liquidacao: new Date(this.liquidacaoAnular?.data_liquidacao),
        valor_liquidado: this.liquidacaoAnular?.valor_liquidado / 2,
        parcela: +parcela + 2,
        id: null
      });
    }
  }

  public hide(): void {
    this.visualizar = false;
    this.atualizarVisualizar.emit();
    // $('#dialogParcelar').modal('hide');
  }

  public adicionarParcela(): void {
    const ultimaParcela = this.parcelas[this.parcelas.length - 1];
    if (ultimaParcela) {
      this.parcelas.push({
        ...ultimaParcela,
        parcela: ultimaParcela.parcela + 1,
        valor_liquidado: 0
      })
      return;
    }

    this.parcelas.push({
      ...this.liquidacaoAnular,
      data_liquidacao: new Date(this.liquidacaoAnular?.data_liquidacao),
      valor_liquidado: this.liquidacaoAnular.valor_liquidado / 2,
      parcela: this.liquidacaoAnular.parcela + 1,
      id: null
    });
  }

  public totalParcelado(): number {
    return this.parcelas?.reduce((total, parcela) => total += parcela.valor_liquidado, 0);
  }

  public removerParcela(idx: number): void {
    this.parcelas.splice(idx, 1)

    this.parcelas = this.parcelas
      .map((item, i: number) => {
        return {
          ...item,
          parcela: i + 1
        }
      });
  }

  public salvarParcelas() {
    const total_parcelas = this.parcelas.reduce((total, parcela) => total += parcela.valor_liquidado, 0);
    if (total_parcelas !== +this.liquidacaoAnular.valor_liquidado) {
      toastr.warning('Total parcelado diferente do valor liquidado!');
      return;
    }
    this.liquidacaoService.anularLiquidacaoProcessada(this.parcelas, this.liquidacaoAnular.id, this.login.exercicio.id, this.login.orgao.id, this.login.usuario.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        retorno => {
          toastr.success('Anulação realizada com sucesso.');
          this.hide();
        },
        (erro) => toastr.error(erro?.payload ? erro.payload : erro?.error?.payload ? erro.error.payload : 'Não foi possível anular a liquidação de resto a pagar. \n Favor verificar.', 'Atenção')
      )

  }

  private async obterUltimaParcela() {
    return await this.liquidacaoService.obterUltimaParcela(
      this.liquidacaoAnular.empenho.numero, this.login.exercicio.id, this.login.orgao.id,this.liquidacaoAnular.empenho.ano ,true
    ).toPromise() ?? 0;
  }
}