import { Directive, Injector, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { QuadroEnsinoService } from '../service/quadro-ensino.service';
import {
  FuncaoService, GlobalService, Login,
  Exercicio, FuncaoGoverno, Relatorio, ProgressoService, FormatoExportacao, Coluna
} from 'eddydata-lib';
import { Assinaturas } from 'administrativo-lib';

@Directive()
export class Quadro6Ensino implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();
  public e26 = 0;
  public g26 = 0;
  public i26 = 0;

  constructor(
    protected quadroServico: QuadroEnsinoService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected progressoService: ProgressoService,
    protected dataInicial: Date,
    protected dataFinal: Date,
    protected funcao?: FuncaoGoverno,
    protected injector?: Injector,
    protected assinatura_ensino?: boolean) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async listar(orgaos: number[]) {
    return await this.quadroServico
      .obterQuadro6(this.mes, this.exercicio.id, orgaos, this.dataInicial, this.dataFinal).toPromise();
  }

  public montarRelatorio(orgaos: number[], formato?: FormatoExportacao) {
    if (!formato) formato = 'pdf';
    this.quadroServico.obterQuadro6(this.mes, this.exercicio.id, orgaos, this.dataInicial, this.dataFinal)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async idTransacao => {
        let dados
        this.progressoService.show(idTransacao, async (retorno) => {
          dados = retorno
          if (formato === 'pdf') {
            Relatorio.imprimirPersonalizado(
              'QUADRO 6 - RECEITA PRÓPRIA - ENSINO'
              , this.login.usuario.nome, this.login.usuario.sobrenome,
              this.login.orgao.nome, this.login.brasao,
              this.conteudo(dados).concat(this.conteudoDespesa(dados))
                .concat(this.conteudoDeducao1(dados)
                  .concat(this.conteudoDeducao2(dados))
                  .concat(await this.conteudoDespesaLiquida(dados))),
              'portrait', 'QUADRO 6 - RECEITA PRÓPRIA - ENSINO',
              {
                linhas: {
                  hLineWidth() {
                    return 1;
                  },
                  vLineWidth() {
                    return 1;
                  },
                  hLineColor() {
                    return 'black';
                  },
                  paddingLeft() {
                    return 3;
                  },
                  paddingRight() {
                    return 3;
                  }
                }
              });
          } else {
            this.funcaoService.exportar(formato, this.conteudoExportar(dados), 'QUADRO 6 - RECEITA PRÓPRIA - ENSINO', this.colunasRelatorio())
          }
        });
      });
  }

  private conteudo(dados: any[]): {}[] {
    let referencia = '';
    if (this.mes) {
      referencia = `${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase()}/${this.exercicio.ano}`;
    } else if (this.dataInicial && this.dataFinal) {
      let dt = this.dataInicial.toString().split("-");
      referencia = `${dt[2]}/${dt[1]}/${dt[0]}`;
      dt = this.dataFinal.toString().split("-");
      referencia += ` à ${dt[2]}/${dt[1]}/${dt[0]}`;
    } else {
      referencia += this.exercicio.ano;
    }

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: `REFERÊNCIA: ${referencia}`,
        alignment: 'center',
        fontSize: 11,
        margin: [5, 5, 5, 5],
        bold: true,
        colSpan: 7
      }, '', '', '', '', '', ''],
      [{
        text: 'RECEITAS DE IMPOSTOS',
        alignment: 'center',
        bold: true,
        colSpan: 3
      }, '', '', {
        text: 'APLICAÇÕES MÍNIMAS CONSTITUCIONAIS',
        alignment: 'center',
        bold: true,
        colSpan: 4
      }, '', '', ''],
      [{
        text: '',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'PREVISÃO ATUALIZADA PARA O EXERCÍCIO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'ARRECADADO ATÉ O PERÍODO',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'PARA O EXERCÍCIO',
        alignment: 'center',
        bold: true, fontSize: 6, colSpan: 2
      }, '', {
        text: 'ATÉ O PERÍODO',
        alignment: 'center',
        bold: true, fontSize: 6, colSpan: 2
      }, '']
    ];

    // monta o agrupamento do relatório
    const dado = dados[0];
    registros.push([
      {
        text: 'PRÓPRIOS',
        alignment: 'left', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.c4),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.d4),
        alignment: 'right', fontSize: 7
      },
      {
        text: 'TOTAL',
        alignment: 'center', fontSize: 7, colSpan: 4
      }, '', '', ''
    ]);
    registros.push([
      {
        text: 'TRANSFERÊNCIAS DA UNIÃO',
        alignment: 'left', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.c5),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.d5),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.e5),
        alignment: 'center', bold: true, fontSize: 7, colSpan: 2
      }, '',
      {
        text: this.funcaoService.convertToBrNumber(dado.f5),
        alignment: 'center', bold: true, fontSize: 7, colSpan: 2
      }, ''
    ]);
    registros.push([
      {
        text: 'TRANSFERÊNCIAS DO ESTADO',
        alignment: 'left', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.c6),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.d6),
        alignment: 'right', fontSize: 7
      },
      {
        text: '',
        alignment: 'right', fontSize: 7, colSpan: 4
      }, '', '', ''
    ]);
    registros.push([
      {
        text: 'TOTAL',
        alignment: 'left', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.c7),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.d7),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: '',
        alignment: 'right', fontSize: 7, colSpan: 4
      }, '', '', ''
    ]);
    registros.push([
      {
        text: 'RETENÇÕES AO FUNDEB',
        alignment: 'left', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.c8),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.d8),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: '',
        alignment: 'right', fontSize: 7, colSpan: 4
      }, '', '', ''
    ]);
    registros.push([
      {
        text: 'RECEITAS LÍQUIDAS',
        alignment: 'left', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.c9),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(dado.d9),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: '',
        alignment: 'right', fontSize: 7, colSpan: 4
      }, '', '', ''
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }];
  }

  public conteudoDespesa(lista: any) {
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: 'DESPESAS TOTAIS',
        alignment: 'center',
        bold: true,
        border: [true, false, true, false],
        margin: [10, 10, 10, 10],
        colSpan: 9
      }, '', '', '', '', '', '', '', ''],
      [{
        text: '',
        alignment: 'center',
        bold: true,
      }, {
        text: 'DOTAÇÃO ATUALIZADA PARA O EXERCÍCIO',
        alignment: 'center', fontSize: 7,
        bold: true,
        colSpan: 2,
      }, '', {
        text: 'DESPESA EMPENHADA ATÉ O PERÍODO',
        alignment: 'center', fontSize: 7,
        bold: true,
        colSpan: 2,
      }, '', {
        text: 'DESPESA LIQUIDADA ATÉ O PERÍODO',
        alignment: 'center', fontSize: 7,
        bold: true,
        colSpan: 2,
      }, '', {
        text: 'DESPESA PAGA ATÉ O PERÍODO',
        alignment: 'center', fontSize: 7,
        bold: true,
        colSpan: 2,
      }, ''],
      [{
        text: '',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'VALOR',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: '%',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'VALOR',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: '%',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'VALOR',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: '%',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: 'VALOR',
        alignment: 'center',
        bold: true, fontSize: 6
      }, {
        text: '%',
        alignment: 'center',
        bold: true, fontSize: 6
      }]
    ];

    const dado = lista[0];
    for (const item of lista[1]) {
      total1 += +item.dotacao;
      total2 += +item.empenhado;
      total3 += +item.liquidado;
      total4 += +item.pago;
    }

    registros.push([
      {
        text: 'TOTAL', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(total1),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber((total1 / dado.c7) * 100),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(total2),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber((total2 / dado.d7) * 100),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(total3),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber((total3 / dado.d7) * 100),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(total4),
        alignment: 'right', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber((total4 / dado.d7) * 100),
        alignment: 'right', bold: true, fontSize: 7
      },
    ]);

    for (const item of lista[1]) {
      registros.push([
        {
          text: item.tipo, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.dotacao),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.dotacao / dado.c7) * 100),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.empenhado),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.empenhado / dado.d7) * 100),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.liquidado),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.liquidado / dado.d7) * 100),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.pago),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.pago / dado.d7) * 100),
          alignment: 'right', fontSize: 7
        },
      ]);
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: [112, 60, 30, 60, 30, 60, 30, 60, 30],
        body: registros
      }
    }];
  }

  public conteudoDeducao1(lista: any) {
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    const dado = lista[0];
    for (const item of lista[2]) {
      total2 += +item.valor;
      total3 += +item.valor;
      total4 += +item.valor;
    }

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: 'DEDUÇÕES',
        alignment: 'center',
        bold: true,
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
        colSpan: 7
      }, '', '', '', '', '', ''],
      [{
        text: 'ENSINO FUNDAMENTAL',
        alignment: 'center',
        bold: true,
      }, {
        text: this.funcaoService.convertToBrNumber(total2),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber((total2 / dado.c7) * 100),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total3),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber((total3 / dado.d7) * 100),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total4),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber((total4 / dado.d7) * 100),
        alignment: 'right', bold: true, fontSize: 7
      }]
    ];

    for (const item of lista[2]) {
      registros.push([
        {
          text: item.tipo, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.valor),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.valor / dado.d7) * 100),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.valor),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.valor / dado.d7) * 100),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.valor),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.valor / dado.d7) * 100),
          alignment: 'right', fontSize: 7
        },
      ]);
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: [216, 60, 30, 60, 30, 60, 30],
        body: registros
      }
    }];
  }

  public conteudoDeducao2(lista: any) {
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    const dado = lista[0];
    for (const item of lista[3]) {
      total2 += +item.valor;
      total3 += +item.valor;
      total4 += +item.valor;
    }

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }, {
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }, {
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }, {
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }, {
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }, {
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }, {
        text: '',
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
      }],
      [{
        text: 'EDUCAÇÃO INFANTIL',
        alignment: 'center',
        bold: true,
      }, {
        text: this.funcaoService.convertToBrNumber(total2),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber((total2 / dado.d7) * 100),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total3),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber((total3 / dado.d7) * 100),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total4),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber((total4 / dado.d7) * 100),
        alignment: 'right', bold: true, fontSize: 7
      }]
    ];

    for (const item of lista[3]) {
      registros.push([
        {
          text: item.tipo, fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.valor),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.valor / dado.d7) * 100),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.valor),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.valor / dado.d7) * 100),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber(item.valor),
          alignment: 'right', fontSize: 7
        },
        {
          text: this.funcaoService.convertToBrNumber((item.valor / dado.d7) * 100),
          alignment: 'right', fontSize: 7
        },
      ]);
    }

    registros.push([{
      text: '',
      border: [true, false, true, false],
      margin: [4, 4, 4, 4],
    }, {
      text: '',
      border: [true, false, true, false],
      margin: [4, 4, 4, 4],
    }, {
      text: '',
      border: [true, false, true, false],
      margin: [4, 4, 4, 4],
    }, {
      text: '',
      border: [true, false, true, false],
      margin: [4, 4, 4, 4],
    }, {
      text: '',
      border: [true, false, true, false],
      margin: [4, 4, 4, 4],
    }, {
      text: '',
      border: [true, false, true, false],
      margin: [4, 4, 4, 4],
    }, {
      text: '',
      border: [true, false, true, false],
      margin: [4, 4, 4, 4],
    }]);

    const receita = lista[0];
    const item = lista[4][0];
    const e26 = item.e9 > 0
      ? item.f7 > item.e32 ? item.f7 - item.e32 : 0
      : item.f9 > 0
        ? item.e7 > item.e32 ? item.e7 - item.e32 : 0
        : 0;
    const g26 = item.e9 > 0
      ? item.f7 > item.g32 ? item.f7 - item.g32 : 0
      : item.f9 > 0
        ? item.e7 > item.g32 ? item.e7 - item.g32 : 0
        : 0;
    const i26 = item.e9 > 0
      ? item.f7 > item.i32 ? item.f7 - item.i32 : 0
      : item.f9 > 0
        ? item.e7 > item.i32 ? item.e7 - item.i32 : 0
        : 0;

    this.e26 = e26;
    this.g26 = g26;
    this.i26 = i26;

    registros.push([{
      text: 'FUNDEB RETIDO E NÃO APLICADO NO RETORNO',
      alignment: 'center',
      bold: true,
    }, {
      text: this.funcaoService.convertToBrNumber(e26),
      alignment: 'right', bold: true, fontSize: 7
    }, {
      text: this.funcaoService.convertToBrNumber((e26 / receita.d7) * 100),
      alignment: 'right', bold: true, fontSize: 7
    }, {
      text: this.funcaoService.convertToBrNumber(g26),
      alignment: 'right', bold: true, fontSize: 7
    }, {
      text: this.funcaoService.convertToBrNumber((g26 / receita.d7) * 100),
      alignment: 'right', bold: true, fontSize: 7
    }, {
      text: this.funcaoService.convertToBrNumber(i26),
      alignment: 'right', bold: true, fontSize: 7
    }, {
      text: this.funcaoService.convertToBrNumber((i26 / receita.d7) * 100),
      alignment: 'right', bold: true, fontSize: 7
    }]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: [216, 60, 30, 60, 30, 60, 30],
        body: registros
      }
    }];
  }

  public async conteudoDespesaLiquida(lista: any) {
    // dados do cabeçalho
    const dado = lista[0];

    // lista de despesas totais
    const itemDespesa = lista[1];
    const despesaFundamental1 = +itemDespesa[0].empenhado;
    const despesaFundamental2 = +itemDespesa[0].liquidado;
    const despesaFundamental3 = +itemDespesa[0].pago;

    const despesaInfantil1 = +itemDespesa[1].empenhado;
    const despesaInfantil2 = +itemDespesa[1].liquidado;
    const despesaInfantil3 = +itemDespesa[1].pago;

    const retencao1 = +itemDespesa[2].empenhado;
    const retencao2 = +itemDespesa[2].liquidado;
    const retencao3 = +itemDespesa[2].pago;

    // lista de deducoes magisterio
    let deducaoFundamental1 = 0;
    let deducaoFundamental2 = 0;
    let deducaoFundamental3 = 0;

    for (const item of lista[2]) {
      deducaoFundamental1 += +item.valor;
      deducaoFundamental2 += +item.valor;
      deducaoFundamental3 += +item.valor;
    }

    // lista de deduções outras
    let deducaoInfantil1 = 0;
    let deducaoInfantil2 = 0;
    let deducaoInfantil3 = 0;

    for (const item of lista[3]) {
      deducaoInfantil1 += +item.valor;
      deducaoInfantil2 += +item.valor;
      deducaoInfantil3 += +item.valor;
    }

    // falta fazer deducao do fundeb


    const total1 = (+despesaFundamental1 + +despesaInfantil1 + +retencao1) - (+deducaoFundamental1 + +deducaoInfantil1 + +this.e26);
    const total2 = (+despesaFundamental2 + +despesaInfantil2 + +retencao2) - (+deducaoFundamental2 + +deducaoInfantil2 + +this.g26);
    const total3 = (+despesaFundamental3 + +despesaInfantil3 + +retencao3) - (+deducaoFundamental3 + +deducaoInfantil3 + +this.i26);

    // // monta o cabecalho
    const registros: {}[] = [
      [{
        text: 'DESPESAS LÍQUIDAS',
        alignment: 'center',
        bold: true,
        border: [true, false, true, false],
        margin: [4, 4, 4, 4],
        colSpan: 7
      }, '', '', '', '', '', ''],
      [{
        text: 'TOTAL',
        alignment: 'left',
        bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total1),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber((total1 / dado.d7) * 100),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total2),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber((total2 / dado.d7) * 100),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber(total3),
        alignment: 'right', bold: true, fontSize: 7
      }, {
        text: this.funcaoService.convertToBrNumber((total3 / dado.d7) * 100),
        alignment: 'right', bold: true, fontSize: 7
      }]
    ];

    registros.push([
      {
        text: 'ENSINO FUNDAMENTAL', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+despesaFundamental1 - +deducaoFundamental1),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+despesaFundamental1 - +deducaoFundamental1) / dado.d7) * 100),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+despesaFundamental2 - +deducaoFundamental2),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+despesaFundamental2 - +deducaoFundamental2) / dado.d7) * 100),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+despesaFundamental3 - +deducaoFundamental3),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+despesaFundamental3 - +deducaoFundamental3) / dado.d7) * 100),
        alignment: 'right', fontSize: 7
      },
    ]);

    registros.push([
      {
        text: 'EDUCAÇÃO INFANTIL', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+despesaInfantil1 - +deducaoInfantil1),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+despesaInfantil1 - +deducaoInfantil1) / dado.d7) * 100),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+despesaInfantil2 - +deducaoInfantil2),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+despesaInfantil2 - +deducaoInfantil2) / dado.d7) * 100),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+despesaInfantil3 - +deducaoInfantil3),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+despesaInfantil3 - +deducaoInfantil3) / dado.d7) * 100),
        alignment: 'right', fontSize: 7
      },
    ]);

    registros.push([
      {
        text: 'RETENÇÕES AO FUNDEB', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+retencao1 - this.e26),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+retencao1 - this.e26) / dado.d7) * 100),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+retencao2 - this.g26),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+retencao2 - this.g26) / dado.d7) * 100),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+retencao3 - this.i26),
        alignment: 'right', fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(((+retencao3 - this.i26) / dado.d7) * 100),
        alignment: 'right', fontSize: 7
      },
    ]);

    let assinaturas: {}[]
    if (this.assinatura_ensino) {
      const ass = new Assinaturas(this.login.orgao, this.injector);
      assinaturas = await ass.dadosAssinatura(50, false, null, false, this.assinatura_ensino);
    }

    return this.assinatura_ensino ? [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: [216, 60, 30, 60, 30, 60, 30],
          body: registros
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: [180, 162, 162],
          body: assinaturas
        }
      }
    ] : [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: [216, 60, 30, 60, 30, 60, 30],
        body: registros
      }
    }];
  }

  public colunasRelatorio(): Coluna[] {
    return [
      { titulo: 'Grupo', coluna: 'grupo' },
      { titulo: 'Titulo', coluna: 'titulo' },
      { titulo: 'Tipo', coluna: 'tipo' },
      { titulo: 'Previsão Atualizada', coluna: 'atualizado', decimais: 2 },
      { titulo: 'Arrecadação', coluna: 'arrecadado', decimais: 2 },
      { titulo: 'Para o exercício', coluna: 'para_exercicio', decimais: 2 },
      { titulo: 'Até o período', coluna: 'ate_periodo', decimais: 2 },
      { titulo: 'Dotação', coluna: 'dotacao', decimais: 2 },
      { titulo: 'Dotação %', coluna: 'dotacao_percente', decimais: 2 },
      { titulo: 'Empenhado', coluna: 'empenhado', decimais: 2 },
      { titulo: 'Empenho %', coluna: 'empenho_percente', decimais: 2 },
      { titulo: 'Liquidado', coluna: 'liquidado', decimais: 2 },
      { titulo: 'Liquidacao %', coluna: 'liquidacao_percente', decimais: 2 },
      { titulo: 'Pago', coluna: 'pago', decimais: 2 },
      { titulo: 'Pagamento %', coluna: 'pagamento_percente', decimais: 2 },
    ]
  }

  public conteudoExportar(lista: any[]) {
    return [
      ...this.normalizarDadosGeral(lista[0]),
      ...this.normalizarDadosDespesa(lista[1], lista[0], 'DESPESAS TOTAIS'),
      ...this.normalizarDadosDeducoes(lista[2], 'DEDUÇÕES'),
      ...this.normalizarDadosDeducoes(lista[3], 'DEDUÇÕES'),
      ...this.normalizarDespesaLiquida(lista, 'DESPESAS LÍQUIDAS')
    ]
  }

  public normalizarDadosGeral(lista: any) {
    return [
      {
        grupo: 'GERAL',
        tipo: 'PROPRIO',
        atualizado: lista.c4,
        arrecadado: lista.d4
      },
      {
        grupo: 'GERAL',
        tipo: 'TRANSFERÊNCIAS DA UNIÃO',
        atualizado: lista.c4,
        arrecadado: lista.d4
      },
      {
        grupo: 'GERAL',
        tipo: 'TRANSFERÊNCIAS DO ESTADO',
        atualizado: lista.c6,
        arrecadado: lista.d6
      },
      {
        grupo: 'GERAL',
        tipo: 'APLICAÇÕES MÍNIMAS CONSTITUCIONAIS',
        para_exercicio: lista.e5,
        ate_periodo: lista.f5
      },
      {
        grupo: 'GERAL',
        tipo: 'RETENÇÕES AO FUNDEB',
        atualizado: lista.c8,
        arrecadado: lista.d8
      },
      {
        grupo: 'GERAL',
        tipo: 'RECEITAS LÍQUIDAS',
        atualizado: lista.c9,
        arrecadado: lista.d9
      }
    ]
  }


  public normalizarDadosDespesa(lista: any[], dados, grupo: string) {
    const linhas: {}[] = [{
      grupo: grupo,
      tipo: 'TOTAL',
      dotacao: lista.reduce((acc, item) => +item.dotacao + acc, 0),
      dotacao_percente: (lista.reduce((acc, item) => +item.dotacao + acc, 0) / dados.c7) * 100,
      empenhado: lista.reduce((acc, item) => +item.empenhado + acc, 0),
      empenho_percente: (lista.reduce((acc, item) => +item.empenhado + acc, 0) / dados.d7) * 100,
      liquidado: lista.reduce((acc, item) => +item.liquidado + acc, 0),
      liquidacao_percente: (lista.reduce((acc, item) => +item.liquidado + acc, 0) / dados.d7) * 100,
      pago: lista.reduce((acc, item) => +item.pago + acc, 0),
      pagamento_percente: (lista.reduce((acc, item) => +item.pago + acc, 0) / dados.d7) * 100
    }]

    lista.forEach((item: any) => {
      linhas.push({
        grupo: grupo,
        tipo: item.tipo,
        dotacao: item.dotacao,
        dotacao_percente: (item.dotacao / dados.c7) * 100,
        empenhado: item.empenhado,
        empenho_percente: (item.empenhado / dados.d7) * 100,
        liquidado: item.liquidado,
        liquidacao_percente: (item.liquidado / dados.d7) * 100,
        pago: item.pago,
        pagamento_percente: (item.pago / dados.d7) * 100
      })
    })

    return linhas

  }

  public normalizarDespesaLiquida(lista: any[], grupo: string) {
    const linhas: {}[] = []

    const dado = lista[0];

    const itemDespesa = lista[1];
    const despesaFundamental1 = +itemDespesa[0].empenhado;
    const despesaFundamental2 = +itemDespesa[0].liquidado;
    const despesaFundamental3 = +itemDespesa[0].pago;

    const despesaInfantil1 = +itemDespesa[1].empenhado;
    const despesaInfantil2 = +itemDespesa[1].liquidado;
    const despesaInfantil3 = +itemDespesa[1].pago;

    const retencao1 = +itemDespesa[2].empenhado;
    const retencao2 = +itemDespesa[2].liquidado;
    const retencao3 = +itemDespesa[2].pago;

    let deducaoFundamental1 = 0;
    let deducaoFundamental2 = 0;
    let deducaoFundamental3 = 0;

    for (const item of lista[2]) {
      deducaoFundamental1 += +item.valor;
      deducaoFundamental2 += +item.valor;
      deducaoFundamental3 += +item.valor;
    }

    let deducaoInfantil1 = 0;
    let deducaoInfantil2 = 0;
    let deducaoInfantil3 = 0;

    for (const item of lista[3]) {
      deducaoInfantil1 += +item.valor;
      deducaoInfantil2 += +item.valor;
      deducaoInfantil3 += +item.valor;
    }

    const total1 = (+despesaFundamental1 + +despesaInfantil1 + +retencao1) - (+deducaoFundamental1 + +deducaoInfantil1 + +this.e26);
    const total2 = (+despesaFundamental2 + +despesaInfantil2 + +retencao2) - (+deducaoFundamental2 + +deducaoInfantil2 + +this.g26);
    const total3 = (+despesaFundamental3 + +despesaInfantil3 + +retencao3) - (+deducaoFundamental3 + +deducaoInfantil3 + +this.i26);

    linhas.push({
      grupo: grupo,
      tipo: 'TOTAL',
      empenhado: total1,
      empenho_percente: (total1 / dado.d7) * 100,
      liquidado: total2,
      liquidacao_percente: (total2 / dado.d7) * 100,
      pago: total3,
      pagamento_percente: (total3 / dado.d7) * 100
    },
      {
        grupo: grupo,
        tipo: 'ENSINO FUNDAMENTAL',
        empenhado: +despesaFundamental1 - +deducaoFundamental1,
        empenho_percente: ((+despesaFundamental1 - +deducaoFundamental1) / dado.d7) * 100,
        liquidado: +despesaFundamental2 - +deducaoFundamental2,
        liquidacao_percente: ((+despesaFundamental2 - +deducaoFundamental2) / dado.d7) * 100,
        pago: +despesaFundamental3 - +deducaoFundamental3,
        pagamento_percente: ((+despesaFundamental3 - +deducaoFundamental3) / dado.d7) * 100
      },
      {
        grupo: grupo,
        tipo: 'EDUCAÇÃO INFANTIL',
        empenhado: +despesaInfantil1 - +deducaoInfantil1,
        empenho_percente: ((+despesaInfantil1 - +deducaoInfantil1) / dado.d7) * 100,
        liquidado: +despesaInfantil2 - +deducaoInfantil2,
        liquidacao_percente: ((+despesaInfantil2 - +deducaoInfantil2) / dado.d7) * 100,
        pago: +despesaInfantil3 - +deducaoInfantil3,
        pagamento_percente: ((+despesaInfantil3 - +deducaoInfantil3) / dado.d7) * 100
      },
      {
        grupo: grupo,
        tipo: 'RETENÇÕES AO FUNDEB',
        empenhado: +retencao1 - this.e26,
        empenho_percente: ((+retencao1 - this.e26) / dado.d7) * 100,
        liquidado: +retencao2 - this.g26,
        liquidacao_percente: ((+retencao2 - this.g26) / dado.d7) * 100,
        pago: +retencao3 - this.i26,
        pagamento_percente: ((+retencao3 - this.i26) / dado.d7) * 100
      })

    return linhas
  }

  public normalizarDadosDeducoes(lista: [], grupo: string) {
    return lista.map((item: any) => {
      return {
        grupo: grupo,
        titulo: item.titulo,
        tipo: item.tipo,
        empenhado: item.valor,
        liquidado: item.valor,
        pago: item.valor
      }
    })
  }

}
