import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import {
  AnexoLRFService,
  BalancoOrcamentariolLRF, DespesaPessoalLRF, DisponibilidadeFinanceira2022LRF, DisponibilidadeFinanceira2023LRF, DividaConsolidadaLiquidaLRF,
  FichaReceitaService, FuncaoSubfuncaoLRF, GestaoFiscalLRF, LdoService, OperacaoCreditoLRF, ReceitaAlienacaoLRF, ReceitaCorrenteLiquidaLRF,
  RestosPagarLRF, ResultadoNominalLRF, ResultadoPrimarioLRF, DemonstrativoReceitaDespesaPrevidenciaria, DemonstrativoApuracaoCumprimento
} from 'administrativo-lib';
import { AudespService, Exercicio, ExercicioService, FormatoExportacao, FuncaoService, GlobalService, LoginPublico, OrgaoService, ProgressoService } from 'eddydata-lib';
import { FichaDespesaService } from 'contabil-lib';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-transparencia-execucao-lrf',
  templateUrl: './transparencia-execucao-lrf.component.html'
})
export class TransparenciaExecucaoLRFComponent implements OnInit, OnDestroy {

  login: LoginPublico = new LoginPublico();
  public mes: number;
  listaOrgaos: SelectItem[];
  orgaos: number[] = [];
  public listaExercicios: Array<Exercicio>;
  public exercicioSelect: Exercicio;
  public listaAnexos: Array<any>;
  public anexoSelect: string;
  public metaFiscal: number = 0;
  protected unsubscribe: Subject<void> = new Subject();
  public formato: FormatoExportacao = 'pdf';
  public bimestre: number;

  constructor(
    protected frService: FichaReceitaService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    protected audespService: AudespService,
    protected fichaService: FichaDespesaService,
    protected exercicioService: ExercicioService,
    protected orgaoService: OrgaoService,
    protected funcaoService: FuncaoService,
    protected anexoServico: AnexoLRFService,
    protected ldoServico: LdoService,
    protected progressoService: ProgressoService,
    protected injector: Injector) {
    this.login = GlobalService.obterSessaoLogin();
    this.mes = 1;
    this.bimestre = 1;
    this.exercicioSelect = this.login.exercicio;
    this.orgaos.push(this.login.orgao.id);
  }

  ngOnInit() {
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
    this.orgaoService.obterTodos()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaOrgaos = [];
        for (const model of dados) {
          this.listaOrgaos.push({ label: `${model.codigo} - ${model.nome}`, value: model.id });
        }
      });
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaExercicios = dados.content;
      });
    this.listaAnexos = [
      { id: 'RREO', nome: 'RELATÓRIO RESUMIDO DA EXECUÇÃO ORÇAMENTÁRIA - RREO' },
      { id: 'RCL', nome: 'RECEITA CORRENTE LÍQUIDA - RCL' },
      { id: 'DCL', nome: 'DÍVIDA CONSOLIDADA LÍQUIDA - DCL' },
      { id: 'DFS', nome: 'DEMONSTRATIVO DAS DESPESAS POR FUNÇÃO E SUBFUNÇÃO' },
      { id: 'DPE', nome: 'DEMONSTRATIVO DE APURAÇÃO DAS DESPESAS COM PESSOAL - EXECUTIVO' },
      { id: 'DPL', nome: 'DEMONSTRATIVO DE APURAÇÃO DAS DESPESAS COM PESSOAL - LEGISLATIVO' },
      { id: 'DRP', nome: 'DEMONSTRATIVO DO RESULTADO PRIMÁRIO' },
      { id: 'DRN', nome: 'DEMONSTRATIVO DO RESULTADO NOMINAL' },
      { id: 'RGF', nome: 'RELATÓRIO DE GESTÃO FISCAL - PODER EXECUTIVO' },
      { id: 'ROP', nome: 'DEMONSTRATIVO DAS RECEITAS DE OPERAÇÕES DE CRÉDITO E DESPESAS DE CAPITAL' },
      { id: 'RP', nome: 'DEMONSTRATIVO DE RESTOS A PAGAR' },
      { id: 'DDF', nome: 'DEMONSTRATIVO DAS DISPONIBILIDADES FINANCEIRAS' },
      { id: 'DRA', nome: 'DEMONSTRATIVO DA RECEITA DE ALIENAÇÃO DE ATIVOS E APLICAÇÃO DOS RECURSOS' },
      { id: 'DRD', nome: 'DEMONSTRATIVO DE RECEITAS E DESPESAS PREVIDENCIÁRIAS' },
      { id: 'DAC', nome: 'DEMONSTRATIVO DE APURAÇÃO DO CUMPRIMENTO OU NÃO DO ART. 42 DA LRF.' },
    ];
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  antesImprimir() {
    const parametros = {};

    parametros['relations'] = 'orgao';
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['mes'] = this.mes;
    parametros['ano'] = this.exercicioSelect.ano;
    parametros['descricao$in'] = 'BALANCETE-ISOLADO-CONTA-CORRENTE,BALANCETE-ISOLADO-CONTA-CORRENTE,BALANCETE-ISOLADO-CONTA-CONTABIL';

    this.audespService.filtrar(0, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res.content?.some(a => !a.armazenado)) {
          this.confirmationService.confirm({
            message: 'Mês não se encontra encerrado, dados poderão sofrer alterações.',
            acceptLabel: "OK",
            rejectVisible: false,
            header: 'Aviso!',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.imprimir(this.formato);
            },
          });
        } else {
          this.imprimir(this.formato);
        }
      });
  }

  imprimir(formato: FormatoExportacao) {
    this.formato = formato;
    let dataAtual: Date = new Date();
    let mesCorrente = (dataAtual.getMonth() + 1);

    if (this.exercicioSelect.id > this.login.exercicio.id) {
      this.messageService.add({ severity: 'warn', summary: 'Validação', detail: 'Ano selecionado não pode ser impresso.' });
    } else if (this.mes >= mesCorrente && this.exercicioSelect.id >= this.login.exercicio.id) {
      this.messageService.add({ severity: 'warn', summary: 'Validação', detail: 'Mês selecionado não pode ser impresso.' });
    } else {
      if (this.orgaos && this.orgaos.length > 0) {
        if (this.anexoSelect === 'RP') {
          const rop = new RestosPagarLRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, this.progressoService, false);
          rop.montarRelatorio(this.orgaos);
        } else if (this.anexoSelect === 'RREO') {
          const dp = new BalancoOrcamentariolLRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, false);
          dp.montarRelatorio(this.orgaos);
        } else if (this.anexoSelect === 'RCL') {
          const rcl = new ReceitaCorrenteLiquidaLRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, false);
          rcl.montarRelatorio(this.orgaos);
        } else if (this.anexoSelect === 'DCL') {
          const rcl = new DividaConsolidadaLiquidaLRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, false);
          rcl.montarRelatorio(this.orgaos);
        } else if (this.anexoSelect === 'DFS') {
          const fs = new FuncaoSubfuncaoLRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, this.progressoService, false);
          fs.montarRelatorio(this.orgaos);
        } else if (this.anexoSelect === 'DPE') {
          const dp = new DespesaPessoalLRF(this.anexoServico, true, this.mes, this.exercicioSelect, this.injector, this.progressoService, false, false);
          dp.montarRelatorio(this.orgaos, formato);
        } else if (this.anexoSelect === 'DPL') {
          const dp = new DespesaPessoalLRF(this.anexoServico, false, this.mes, this.exercicioSelect, this.injector, this.progressoService, false, false);
          dp.montarRelatorio(this.orgaos, formato);
        } else if (this.anexoSelect === 'DRP') {
          const dp = new ResultadoPrimarioLRF(this.anexoServico, this.ldoServico, this.mes, this.metaFiscal, this.exercicioSelect, this.injector, false);
          dp.montarRelatorio(this.orgaos);
        } else if (this.anexoSelect === 'RGF') {
          const dp = new GestaoFiscalLRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, this.orgaoService, this.progressoService, false);
          dp.montarRelatorio(this.orgaos);
        } else if (this.anexoSelect === 'ROP') {
          const rop = new OperacaoCreditoLRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, false);
          rop.montarRelatorio(this.orgaos);
        } else if (this.anexoSelect === 'DRN') {
          const dp = new ResultadoNominalLRF(this.anexoServico, this.ldoServico, this.mes, 0, this.exercicioSelect, this.injector, false);
          dp.montarRelatorio(this.orgaos);
        } else if (this.anexoSelect === 'DDF') {
          if (this.exercicioSelect.ano >= 2023) {
            const dp = new DisponibilidadeFinanceira2023LRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, this.progressoService, false);
            dp.montarRelatorio(this.orgaos);
          } else {
            const dp = new DisponibilidadeFinanceira2022LRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, this.progressoService, false);
            dp.montarRelatorio(this.orgaos);
          }
        } else if (this.anexoSelect === 'DRA') {
          const dp = new ReceitaAlienacaoLRF(this.anexoServico, this.mes, this.exercicioSelect, this.injector, false);
          dp.montarRelatorio(this.orgaos);
        } else if (this.anexoSelect === 'DRD') {
          const dp = new DemonstrativoReceitaDespesaPrevidenciaria(this.anexoServico, this.mes, this.exercicioSelect, this.injector, this.progressoService);
          dp.montarRelatorio(this.orgaos, 'pdf', false);
        } else if (this.anexoSelect === 'DAC') {
          const dp = new DemonstrativoApuracaoCumprimento(this.anexoServico, this.bimestre, this.exercicioSelect, this.injector, this.progressoService);
          dp.montarRelatorio('pdf');
        } else {
          this.messageService.add({ severity: 'warn', summary: 'Validação', detail: 'Selecione o anexo a ser impresso' });
        }
      } else {
        this.messageService.add({ severity: 'warn', summary: 'Validação', detail: 'Selecione o(s) órgão(s) a serem impressos' });
      }
    }
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

}
