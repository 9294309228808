import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GlobalService, Login, OrgaoAssinaturaService } from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotaPreLiquidacao } from '../../relatorio/liquidacao/nota-pre-liquidacao';
import { PreLiquidacaoService } from '../service/pre-liquidacao.service';

@Component({
  selector: 'app-nota-pre-liquidacao-dlg',
  templateUrl: './nota-pre-liquidacao-dlg.component.html'
})
export class NotaPreLiquidacaoDlgComponent implements OnInit, OnDestroy {

  protected datepipe: DatePipe;
  public ptBR: any;
  public selectedOrdem: string;
  public selectedOpt: string;
  public selectNaoImpresso: boolean;
  public selectUsuario: boolean;
  public marcarComoImpresso: boolean;
  public dataInicio: Date;
  public dataFinal: Date;
  public numeroInicio: number;
  public numeroFinal: number;
  public selectAnulado: boolean;

  protected unsubscribe: Subject<void> = new Subject();

  public imaskNumero = {
    mask: Number,
    scale: 0,
    signed: false
  };

  @Input() login: Login;

  constructor(
    protected confirmationService: ConfirmationService,
    protected messageService: MessageService,
    private globalService: GlobalService,
    protected assinaturaService: OrgaoAssinaturaService,
    private PreLiquidacaoService: PreLiquidacaoService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.selectedOrdem = 'ord1';
    this.selectedOpt = 'opt1';
    this.selectNaoImpresso = true;
    this.selectUsuario = true;
    this.dataInicio = new Date();
    this.dataFinal = new Date();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  imprimir() {
    if (this.selectNaoImpresso) {
      this.confirmationService.confirm({
        message: 'Deseja marcar as pré-liquidações como impressas ?',
        acceptLabel: "Sim",
        rejectLabel: "Não",
        header: 'Confirmação',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.gerarImpressaoLiquidacao();
          this.marcarComoImpresso = true;
        },
        reject: () => {
          this.marcarComoImpresso = false;
          this.gerarImpressaoLiquidacao();
        }
      });
    } else {
      this.marcarComoImpresso = false;
      this.gerarImpressaoLiquidacao();
    }
  }

  gerarImpressaoLiquidacao() {

    const parametros = {};

    let relations = '';
    relations += 'empenho,empenho.contrato,empenho.licitacao,empenho.modalidade,empenho.subelemento,';
    relations += 'empenho.favorecido,empenho.ficha,empenho.ficha.executora.unidade,empenho.ficha.despesa,empenho.ficha.funcao,';
    relations += 'empenho.ficha.acao,empenho.ficha.aplicacao';
    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['orderBy'] = this.selectedOrdem === 'ord1' ? 'empenho.numero$ASC,parcela$ASC' : 'empenho.favorecido.nome$ASC';        
    parametros['nota'] = true;


    if (this.selectedOpt === 'opt1') {
      parametros['data_liquidacao$ge'] = this.datepipe.transform(this.dataInicio, 'yyyy-MM-dd');
      parametros['data_liquidacao$le'] = this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd');
    } else {
      parametros['empenho.numero$ge'] = +this.numeroInicio;
      parametros['empenho.numero$le'] = +this.numeroFinal;
    }
    if (this.selectNaoImpresso) {
      parametros['impresso'] = !this.selectNaoImpresso;
    }
    if (this.selectUsuario) {
      parametros['usuario_cadastro_id'] = this.login.usuario.id;
    }
    if (this.selectAnulado) {
      parametros['anulacao'] = true;
    } else {
      parametros['anulacao'] = false;
    }
    this.PreLiquidacaoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        new NotaPreLiquidacao(this.assinaturaService).imprimir(lista.content, this.login);
        if (this.marcarComoImpresso && lista.content.length > 0) {
          this.PreLiquidacaoService.marcarComoImpresso(parametros).subscribe(() => {
          });
        }
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );

  }

}
