// tslint:disable: variable-name
import { Usuario } from '../comum/usuario.model';
import { CredenciamentoAtividadeHistorico } from './credenciamento-atividade-historico.model';

export class CredenciamentoAtividadeHistoricoView {

    id: number;
    usuario: Usuario;
    credenciamentoAtividadeHistorico: CredenciamentoAtividadeHistorico;
    data_cadastro: Date;
    data_alteracao: Date;

}
