import { Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, LoginContabil, Tombamento } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BemMovelHistoricoDlgService } from '../service/bem-movel-historico-dlg.service';

@Component({
  selector: 'app-bem-movel-historico-dlg',
  templateUrl: './bem-movel-historico-dlg.component.html'
})
export class BemMovelHistoricoDlgComponent extends BaseResourceListComponent<Tombamento, LoginContabil> implements OnChanges {

  /**
   * Declaração de variáveis
   */

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public listaBemMovelHistorico: Tombamento[];
  @Input() tombamentoId: number;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public bemMovelHistoricoDlgService: BemMovelHistoricoDlgService) {
    super(bemMovelHistoricoDlgService, injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tombamentoId.currentValue)
      this.bemMovelHistoricoDlgService
      .obterTombamentosHistorico(changes.tombamentoId.currentValue)
      .subscribe(data => {
        this.listaBemMovelHistorico = data;
      });
  }

  public ngOnInit(): void {
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================


  protected relations(): string {
    return "";
  }

  protected condicoesGrid(): {} {
    return;
  }

  protected ordenacaoGrid(): string[] {
    return ['data_cadastro$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: [],
      date: [],
      text: [],
    };
  }

  public beforeInit(): void {
    this.usarExtendido = true;
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: Tombamento): Observable<Tombamento> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [];
  }

  public exportarListagem(formato: FormatoExportacao) {
    this.bemMovelHistoricoDlgService
      .extendido(1, -1, {})
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`Histórico do Bem Móvel`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Histórico do Bem Móvel', ['auto']);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================


  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

}
