import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AssinaturaService, FuncaoService, GlobalService, Login } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GrdLiquidacaoResto } from '../../relatorio/liquidacao/grd-liquidacao-resto';
import * as toastr from 'toastr';
import { LiquidacaoRestoService } from '../service/liquidacao-resto.service';

@Component({
  selector: 'app-grd-liquidacao-resto-dlg',
  templateUrl: './grd-liquidacao-resto-dlg.component.html'
})
export class GrdLiquidacaoRestoDlgComponent implements OnInit, OnDestroy {

  protected datepipe: DatePipe;
  public ptBR: any;
  public selectedOrdem: string;
  public selectedOpt: string;
  public selectNaoImpresso: boolean;
  public selectUsuario: boolean;
  public dataInicio: Date;
  public dataFinal: Date;
  public numeroInicio: number;
  public numeroFinal: number;
  protected unsubscribe: Subject<void> = new Subject();

  public imaskNumero = {
    mask: Number,
    scale: 0,
    signed: false
  };

  @Input() login: Login;

  constructor(
    protected messageService: MessageService,
    private globalService: GlobalService,
    protected assinaturaService: AssinaturaService,
    private funcaoService: FuncaoService,
    private liquidacaoRestoService: LiquidacaoRestoService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.selectedOrdem = 'ord1';
    this.selectedOpt = 'opt1';
    this.selectNaoImpresso = false;
    this.selectUsuario = false;
    this.dataInicio = new Date();
    this.dataFinal = new Date();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  async imprimir() {
    const parametros = {};
    let relations = '';
    relations += 'empenho.contrato,empenho.licitacao,empenho.modalidade,empenho.favorecido,';
    relations += 'retencoes,retencoes.ficha';
    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;

    if (this.selectedOpt === 'opt1') {
      if (!this.dataFinal || !this.dataFinal) {
        toastr.warning('Informe a data inicial e final');
        return
      } else {
        parametros['data_liquidacao$ge'] = this.datepipe.transform(this.dataInicio, 'yyyy-MM-dd');
        parametros['data_liquidacao$le'] = this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd');
      }
    } else {
      if (!this.numeroInicio && !this.numeroFinal) {
        toastr.warning("Informe o numero final e inicial");
        return
      } 
      if ((this.numeroInicio && !this.numeroFinal) || (!this.numeroInicio && this.numeroFinal)) {
        this.numeroInicio ? parametros['empenho.numero'] = this.numeroInicio : parametros['empenho.numero'] = this.numeroFinal
      } else {
        parametros['empenho.numero$ge'] = this.numeroInicio;
        parametros['empenho.numero$le'] = this.numeroFinal;
      }
    }
    if (this.selectedOrdem === 'ord1') {
      parametros['orderBy'] = 'empenho.numero$ASC';
    } else if (this.selectedOrdem === 'ord2') {
      parametros['orderBy'] = 'empenho.favorecido.nome$ASC';
    } else if (this.selectedOrdem === 'ord3'){
      parametros['orderBy'] = 'data_cadastro$ASC';
    }
    if (this.selectNaoImpresso) {
      parametros['impresso'] = !this.selectNaoImpresso;
    }
    if (this.selectUsuario) {
      parametros['usuario_cadastro_id'] = this.login.usuario.id;
    }

    this.liquidacaoRestoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        const param = {};
        param['exercicio_id'] = this.login.exercicio.id;
        param['orgao.id'] = this.login.orgao.id;
        new GrdLiquidacaoResto().imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );      
  }

}
