// tslint?:disable?: variable-name
import { FavorecidoEnquadramento, TipoFavorecido } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Cidade } from '../geo/cidade.model';
import { Proponente } from '../licitacao/proponente.model';
import { CodigoGps } from './codigo-gps.model';
import { FavorecidoBanco } from './favorecido-banco.model';
import { FavorecidoCei } from './favorecido-cei.model';
import { FavorecidoCnae } from './favorecido-cnae.model';
import { FavorecidoContato } from './favorecido-contato.model';
import { FavorecidoProduto } from './favorecido-produto.model';
import { FavorecidoTerceiroSetor } from './favorecido-terceiro-setor.model';
import { FavorecidoTipo } from './favorecido-tipo.model';
import { FavorecidoPenalidade } from './favorecido-penalidade.model';
import { FavorecidoSocio } from './favorecido-socio.model';
import { FavorecidoDependente } from 'eddydata-lib';

export class Favorecido extends BaseResourceModel {
    constructor(
        public id?: number,
        public tipo_favorecido?: TipoFavorecido,
        public nome?: string,
        public cpf_cnpj?: string,
        public nome_fantasia?: string,
        public inscricao_estadual?: string,
        public inscricao_municipal?: string,
        public inscricao_inss?: string,
        public cep?: string,
        public endereco?: string,
        public num_endereco?: string,
        public bairro?: string,
        public municipio?: string,
        public uf?: string,
        public complemento?: string,
        public ddd_fone?: string,
        public telefone?: string,
        public pis?: string,
        public email?: string,
        public tipo?: FavorecidoTipo,
        public url?: string,
        public enquadramento?: FavorecidoEnquadramento,
        public autarquia?: boolean,
        public fatura?: boolean,
        public autorizado?: boolean,
        public inativo?: boolean,
        public irrf?: boolean,
        public inss?: boolean,
        public servidor?: boolean,
        public simples_nacional?: boolean,
        public conferencia_simples?: Date,
        public gasto_fixo?: boolean,
        public cprb?: boolean,
        public conta_multipla?: boolean,
        public aux?: number,
        public terceiro_setor?: boolean,
        public cidade?: Cidade,
        public data_cadastro?: Date,
        public data_alteracao?: Date,
        public tipo_fornecedor?: string,
        public responsavel?: string,
        public data_certidao?: Date,
        public data_fgts?: Date,
        public data_inss?: Date,
        public data_irrf?: Date,
        public data_icms?: Date,
        public data_entidade_profissional?: Date,
        public data_alvara_social?: Date,
        public data_contrato_social?: Date,
        public data_conjunta_federal?: Date,
        public data_contrato_federal?: Date,
        public data_divida_ativa_municipal?: Date,
        public data_debitos_trabalhistas?: Date,
        public data_certidao_falencia?: Date,
        public data_certidao_estadual?: Date,
        public data_certidao_municipal?: Date,
        public data_balanco_exercicio?: Date,
        public situacao_cadastral?: string,
        public situacao_cnpj?: string,
        public capital_social?: string,
        public obs_crc?: string,
        public considerar_reinf?: boolean,
        public produtos?: FavorecidoProduto[],
        public penalidades?: FavorecidoPenalidade[],
        public contatos?: FavorecidoContato[],
        public terceiro_setores?: FavorecidoTerceiroSetor[],
        public gps?: CodigoGps,
        public cnaes?: FavorecidoCnae[],
        public contas?: FavorecidoBanco[],
        public proponentes?: Proponente[],
        public ceis?: FavorecidoCei[],
        public socios?: FavorecidoSocio[],
        public dependentes?: FavorecidoDependente[],
        public nao_mostrar_dirf?: boolean,
        public natureza_juridica?: string,
        public pais_iso3?: string,
        public funcao_cargo?: string,
        public insencao_imunidade?: number,
        public sociedade_civil?: number,
        public editavel?: boolean,
        public deixarSalvar?: boolean,
    ) {
        super();
    }
    static converteJson(json: any): Favorecido {
        return Object.assign(new Favorecido(), json);
    }
}
