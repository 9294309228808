import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PagamentoService } from 'administrativo-lib';
import { DocumentoFiscal, DocumentoFiscalArray, FuncaoService, LicitacaoAudesp, Pagamento, PagamentoArray, Pagto } from 'eddydata-lib';
import { LicitacaoAudespService } from '../../service/licitacao-audesp.service';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
import * as toastr from 'toastr';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-pagamento-array',
  templateUrl: './licitacao-audesp-pagamento-array.component.html'
})
export class LicitacaoAudespPagamentoArrayComponent extends BaseLicitacaoAudesp implements OnChanges {

  @Input() public entidade: PagamentoArray;
  @Input() public ajuste: LicitacaoAudesp;

  public itemAtual: Pagto;
  public itemAnterior: Pagto;
  public listaDocFiscal: DocumentoFiscal[];
  public listaPagamento: Pagamento[];

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    public funcaoService: FuncaoService,
    public audespService: LicitacaoAudespService,
    public pagamentoService: PagamentoService,
  ) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.carregarDocsFiscais();
    }
  }

  public adicionar() {
    if (!this.entidade.Pagto || this.entidade.Pagto.length === 0) this.entidade.Pagto = [];

    this.itemAtual = {
      DocumentoFiscalDataEmissao: undefined, DocumentoFiscalNumero: undefined, DocumentoFiscalUF: undefined,
      NotaFiscalValorPago: undefined, MedicaoNumero: undefined, NotaEmpenhoDataEmissao: undefined,
      NotaEmpenhoNumero: undefined, NotaFiscalPagtoDt: undefined, RecolhidoEncargosPrevidenciario: 'N', editavel: true
    }
    this.entidade.Pagto.push(this.itemAtual);

    if (this.listaDocFiscal?.length === 0) {
      toastr.warning('Nenhum documento fiscal foi localizado para este ajuste');
      return;
    }
    this.itemAtual.MedicaoNumero = this.listaDocFiscal[0].MedicaoNumero;
    this.carregarPagamentos(this.itemAtual.liquidacaoId);
  }

  public visualizar(item: Pagto) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
    this.carregarPagamentos(this.itemAtual.liquidacaoId);
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      let itm = this.entidade.Pagto.find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.entidade.Pagto.splice(this.entidade.Pagto.indexOf(this.itemAtual), 1, itm)
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Pagto) {
    this.itemAtual = undefined;
    this.entidade.Pagto.splice(this.entidade.Pagto.indexOf(item), 1)
  }

  public salvar() {
    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }

  private carregarDocsFiscais() {
    if (!this.ajuste) return;
    this.audespService.filtrar(0, 0, { parente_id: this.ajuste.id, tipo: 'DOC_FISCAL' }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res?.content) {
          const arquivos: LicitacaoAudesp[] = res.content;
          if (arquivos?.length > 0) {
            const docFiscalArray = arquivos.map(a => JSON.parse(a.arquivo) as DocumentoFiscalArray)
            if (docFiscalArray?.length > 0) {
              this.listaDocFiscal = [];
              docFiscalArray.forEach(a => {
                this.listaDocFiscal = this.listaDocFiscal.concat(a.DocFiscal)
              })
            }
          }
        }
      })
  }

  public atribuirDocFiscal(liquidacaoId: number) {
    if (!liquidacaoId) return;

    const docFiscal: DocumentoFiscal = this.listaDocFiscal.find(d => d.liquidacaoId === liquidacaoId);
    if (!docFiscal) return;

    this.itemAtual.MedicaoNumero = docFiscal.MedicaoNumero;
    this.itemAtual.NotaEmpenhoNumero = docFiscal.NotaEmpenhoNumero;
    this.itemAtual.NotaEmpenhoDataEmissao = this.funcaoService.converteDataBR(docFiscal.NotaEmpenhoDataEmissao);
    this.itemAtual.DocumentoFiscalNumero = docFiscal.DocumentoFiscalNumero;
    this.itemAtual.DocumentoFiscalDataEmissao = this.funcaoService.converteDataBR(docFiscal.DocumentoFiscalDataEmissao);
    this.itemAtual.DocumentoFiscalUF = docFiscal.DocumentoFiscalUF;

    this.carregarPagamentos(liquidacaoId);
  }

  private carregarPagamentos(liquidacaoId: number) {
    if (!liquidacaoId) return;

    this.pagamentoService.filtrar(0, 0, {
      liquidacao_id: liquidacaoId, orgao_id: this.login.orgao.id, ignoreCondObrig: true
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res?.content)
          this.listaPagamento = res.content
      })
  }

  public atribuirPagamento(pagamentoId: number) {
    if (!pagamentoId) return;

    const pagamento: Pagamento = this.listaPagamento.find(p => p.id === pagamentoId);
    if (!pagamento) return;

    this.itemAtual.NotaFiscalPagtoDt = this.funcaoService.converteDataBR(pagamento.data_pagamento);
    this.itemAtual.NotaFiscalValorPago = pagamento.valor_pago;
  }

}
