import { Sistemas } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from './orgao.model';

export class EmailErro extends BaseResourceModel {
  constructor(
    public id?: number,
    public assunto?: string,
    public destino?: string,
    public erro?: string,
    public sistema?: Sistemas,
    public modulo?: string,
    public orgao?: Orgao,
  ) {
    super();
  }
  static converteJson(json: any): EmailErro {
    return Object.assign(new EmailErro(), json);
  }
}
