import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Depreciacao, Tombamento, TombamentoHistorico } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RPTombamentoService extends BaseResourceService<Tombamento> {

  constructor(
    protected injector: Injector
  ) {
    super(`tombamentos`, injector);
  }

  public obterPorPlaca(placa: number, orgaoId: number): Observable<Tombamento> {
    return this.http.get<Tombamento>(`${this.login.cidade.id}/${this.api}/obter?placa=${placa}&orgao_id=${orgaoId}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public proximaPlaca(orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proxima-placa?orgao=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorNome(nome: string, orgaoId: number): Observable<Tombamento> {
    return this.http.get<Tombamento>(
      `${this.login.cidade.id}/${this.api}/filtrar?produto.nome$like=${nome}%&orgao_id=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorDataIncorporado(data1: string, data2: string, orgaoId: number): Observable<Array<Tombamento>> {
    return this.http.get<Array<Tombamento>>(`${this.login.cidade.id}/${this.api}/filtrar-incorporados/${data1}/${data2}/${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorDataBaixado(data1: string, data2: string, orgaoId: number): Observable<Array<Tombamento>> {
    return this.http.get<Array<Tombamento>>(`${this.login.cidade.id}/${this.api}/filtrar-baixados/${data1}/${data2}/${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterHistorico(id: number): Observable<TombamentoHistorico[]> {
    return this.http.get<TombamentoHistorico[]>(`${this.login.cidade.id}/${this.api}/historico/${id}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalizarSituacao(setor: number): Observable<{}[]> {
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/totalizar-situacao/${setor}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalizarConservacao(setor: number): Observable<{}[]> {
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/totalizar-conservacao/${setor}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalizarAquisicaoAno(setor: number): Observable<{}[]> {
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/totalizar-aquisicao-ano/${setor}`, this.httpOptions()).pipe(
        map(res => res.reverse()),
        catchError(err => this.handleError(err))
      );
  }

  public listarPendencias(setor: number): Observable<{ tipo: string, descricao: string, id: number, qtde: number }[]> {
    return this.http.get<{ tipo: string, descricao: string, id: number, qtde: number }[]>(
      `${this.login.cidade.id}/${this.api}/listar-pendencias/${setor}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public conferenciaContabil(data1: string, data2: String, empenho?: number): Observable<{}[]> {
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/conferencia-contabil/${data1}/${data2}?${empenho > 0 ? `empenho=${empenho}` : ''}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalizacaoSubGrupo(orgao: number, depreciacao: Depreciacao, data1: string, data2: string): Observable<{}[]> {
    let query = '?';
    if (depreciacao) query += `depreciacao=${depreciacao.id}&`
    if (data1) query += `data1=${data1}&`
    if (data2) query += `data2=${data2}&`

    query = query.substring(0, query.length - 1)
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/totalizacao-subgrupo/${orgao}${query}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
