// tslint:disable: variable-name
import { Login } from './login';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { Cidade } from '../geo/cidade.model';
import { Usuario } from '../comum/usuario.model';
import { Ppa } from '../planejamento/ppa.model';
import { Sistemas } from '../../components/types';

export class LoginPublico extends Login {
    constructor(
        public sistema?: Sistemas,
        public exercicio?: Exercicio,
        public orgao?: Orgao,
        public ppa?: Ppa,
        public cidade?: Cidade,
        public limite?: number,
        public token?: string,
        public contraste?: boolean,
        public mesReferencia?: number,
        public brasao?: string
    ) {
        super(sistema, exercicio, new Usuario(), orgao, cidade, limite, token, brasao);
    }
}
