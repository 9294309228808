import { RecebimentoService } from '../service/recebimento.service';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { ProgressoService, Recebimento, Login } from 'eddydata-lib';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-excluir-recebimento-dlg',
  templateUrl: './excluir-recebimento-dlg.component.html'

})

export class ExcluirRecebimentoDlgComponent implements OnChanges {

  /**
* Declaração de variáveis
*/

  @Input() lista: Recebimento[] = [];
  @Input() login: Login;
  @Output() callback: EventEmitter<void> = new EventEmitter()

  protected unsubscribe: Subject<void> = new Subject();
  public inconsistencias: [];
  /**
* Construtor com as injeções de dependencias
*/
  constructor(
    protected recebimentoService: RecebimentoService,
    protected progressoService: ProgressoService,
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {

  }

  public excluirRecebimentoLote(item: Recebimento) {
    this.recebimentoService.exclusaoLote(this.login.exercicio.id, this.login.orgao.id, item.nome_arquivo).pipe(takeUntil(this.unsubscribe)).subscribe(idTransacao => {
      this.progressoService.show(idTransacao, (retorno) => {
        if (retorno.inconsistencias) {
          this.inconsistencias = retorno.inconsistencias;
        } else {
          this.inconsistencias = [];
        }
        this.callback.emit()
      })
    }, error => {
      this.callback.emit()
      toastr.error(error.error.payload)
    })
  }
}