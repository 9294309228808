import { Component, OnInit, ElementRef, Input, ViewChild, SimpleChanges, AfterViewInit, OnDestroy, OnChanges, ViewChildren, QueryList } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { ReservaConvenioEstadualService } from '../service/reserva-convenio-estadual.service';
import {
  ReservaConvenioEstadual, ReservaDotacao, LoginContabil,
  FuncaoService, GlobalService
} from 'eddydata-lib';

@Component({
  selector: 'app-declaracao-convenio-estadual',
  templateUrl: './declaracao-convenio-estadual.component.html'
})
export class DeclaracaoConvenioEstadualComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  public entity: ReservaConvenioEstadual = new ReservaConvenioEstadual();
  private entityTemp: ReservaConvenioEstadual;
  protected unsubscribe: Subject<void> = new Subject();

  @Input() listaConvenioEstaduais: Array<any>;
  @Input() lista: Array<any>;
  @Input() entidade: ReservaDotacao;
  @Input() login: LoginContabil;
  @Input() visualizar: boolean;

  @ViewChild('f') formGroup: any;
  @ViewChild('codigoe') public inputCodigo: ElementRef;
  @ViewChild('btnAdicionarExecutora') public btnAdicionarExecutora: ElementRef;
  @ViewChildren('btnsEditar') public btnsEditar: QueryList<ElementRef>;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected funcaoService: FuncaoService,
    protected convenioEstadualService: ReservaConvenioEstadualService,
  ) { }

  ngOnInit() {
    this.listaConvenioEstaduais = this.lista;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lista) {
      this.listaConvenioEstaduais = this.lista;
    }
  }

  ngAfterViewInit() {
    new GlobalService().calendarMascara();
    if (this.entity) {
      setTimeout(() => this.inputCodigo ? this.inputCodigo.nativeElement.focus() : null, 2000);
    }
  }

  private loadReservaConvenioEstadual() {
    if (this.entidade.id) {
      this.convenioEstadualService.filtrar(1, -1,
        { relations: 'reserva', 'reserva.id': this.entidade.id }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaConvenioEstaduais = res ? res.content : new Array<ReservaConvenioEstadual>();
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  private desabilitarEdicao(desabilitar: boolean) {
    this.btnsEditar.forEach((btn) => btn.nativeElement.disabled = desabilitar)
    this.btnAdicionarExecutora.nativeElement.disabled = desabilitar;
  }

  public adicionarReservaConvenioEstadual() {
    this.entity = new ReservaConvenioEstadual();
    this.entity.reserva = this.entidade;
    if (!this.listaConvenioEstaduais) {
      this.listaConvenioEstaduais = new Array();
    }
    this.entity.editavel = true;
    this.entity['salvo'] = false;
    this.listaConvenioEstaduais.unshift(this.entity);
    this.desabilitarEdicao(true);
    setTimeout(() => this.inputCodigo.nativeElement.focus(), 1000);
  }

  public async salvarReservaConvenioEstadual(item: any) {
    try {
      if (!item.numero) {
        throw new Error('Informe o número do convênio!');
      }
      if (!item.ano) {
        throw new Error('Informe o ano do convênio!');
      }
      this.entity.editavel = false;
      this.entity['salvo'] = true;
      this.desabilitarEdicao(false);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  public editarReservaConvenioEstadual(item: ReservaConvenioEstadual) {
    this.entityTemp = JSON.parse(JSON.stringify(item));

    this.entity = item;
    this.entity.editavel = true;
    this.entity['salvo'] = true;
    this.desabilitarEdicao(true);
  }

  public cancelarReservaConvenioEstadual(item: ReservaConvenioEstadual, index: number) {
    if (item['salvo'] && item.id === this.entityTemp?.id) {
      item.numero = this.entityTemp.numero;
      item.ano = this.entityTemp.ano;
      item.valor_reserva = this.entityTemp.valor_reserva;
      item.valor_contrapartida = this.entityTemp.valor_contrapartida;

      this.entity = item;
      this.entity.editavel = false;
    } else {
      this.listaConvenioEstaduais.splice(index, 1);
    }
    this.desabilitarEdicao(false);
  }

  public confirmarRemocao(item: ReservaConvenioEstadual, index: number) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja remover item?',
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.removerReservaConvenioEstadual(item, index)
      },
    });
  }

  private removerReservaConvenioEstadual(item: ReservaConvenioEstadual, index: number) {
    if (item.id) {
      this.convenioEstadualService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          // this.preencherTabela.emit();
          this.listaConvenioEstaduais.splice(index, 1);
          this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Exclusão', detail: error.error.payload });
          throw error;
        });
    } else {
      this.listaConvenioEstaduais.splice(index, 1);
    }
  }

  public podeVisualizar() {
    if (this.visualizar === true) {
      return true;
    }
    if(this.visualizar === false){
      return false;
    }
  }
}
