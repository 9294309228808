import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { QuadroEnsinoService } from './service/quadro-ensino.service';
import { Quadro1Ensino } from './relatorio/quadro1.ensino';
import { Quadro2Ensino } from './relatorio/quadro2.ensino';
import { Quadro3Ensino } from './relatorio/quadro3.ensino';
import { Quadro4Ensino } from './relatorio/quadro4.ensino';
import { Quadro5Ensino } from './relatorio/quadro5.ensino';
import { Quadro6Ensino } from './relatorio/quadro6.ensino';
import {
  LoginContabil, Exercicio, ExercicioService,
  OrgaoService, FuncaoService, GlobalService, ProgressoService, ParametroContabilService, FormatoExportacao
} from 'eddydata-lib';
import * as toastr from 'toastr';
import { ProjecaoQSE } from './relatorio/projecao-qse';
import { Quadro8Ensino } from './relatorio/quadro8.ensino';
import { ProjecaoReceitaDespesaEnsino } from './relatorio/projecao-receita-despesa';

@Component({
  selector: 'app-quadro-ensino',
  templateUrl: './quadro-ensino.component.html'
})
export class QuadroEnsinoComponent implements OnInit, OnDestroy {

  public login: LoginContabil = new LoginContabil();
  public mes: number;
  listaOrgaos: SelectItem[];
  orgaos: number[] = [];
  public listaExercicios: Array<Exercicio>;
  public exercicioSelect: Exercicio;
  public listaAnexos: Array<any>;
  public anexoSelect: string;
  protected unsubscribe: Subject<void> = new Subject();
  public percentualFerias: number = 0;
  public percentual13: number = 80;
  public assinatura_ensino: boolean = false;

  public opcao: string = '2';

  public periodoInicial: Date;
  public periodoFinal: Date = new Date();

  constructor(
    private router: Router,
    protected exercicioService: ExercicioService,
    protected orgaoService: OrgaoService,
    protected funcaoService: FuncaoService,
    protected quadroServico: QuadroEnsinoService,
    protected parametroContabilidadeServico: ParametroContabilService,
    protected progressoService: ProgressoService,
    protected injector: Injector) {
    this.login = GlobalService.obterSessaoLogin();
    this.mes = 1;
    this.periodoInicial = new Date(`01/01/${this.login.exercicio.ano}`);
    this.exercicioSelect = this.login.exercicio;
    this.orgaos.push(this.login.orgao.id);
  }

  ngOnInit() {
    this.orgaoService.obterTodos()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaOrgaos = [];
        for (const model of dados) {
          this.listaOrgaos.push({ label: `${model.codigo} - ${model.nome}`, value: model.id });
        }
      });
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaExercicios = dados.content;
      });
    this.listaAnexos = [
      { id: 'Q1', nome: 'QUADRO 1 - IMPOSTOS' },
      { id: 'Q2', nome: 'QUADRO 2 - RECEITAS VINCULADAS' },
      { id: 'Q3', nome: 'QUADRO 3 - DESPESAS ORÇAMENTÁRIAS' },
      { id: 'Q4', nome: 'QUADRO 4 - MOVIMENTAÇÃO FINANCEIRA DA EDUCAÇÃO' },
      { id: 'Q5', nome: 'QUADRO 5 - FUNDEB' },
      { id: 'Q6', nome: 'QUADRO 6 - RECEITA PRÓPRIA' },
      { id: 'Q8', nome: 'QUADRO 8 - CÁLCULO ESTIMADO DO REPASSE DECENDIAL NO TRIMESTRE' },
    ];

    if (this.funcaoService.podeIncluir(this.login, '/relatorios-edital')) {
      this.listaAnexos.push({ id: 'PRD', nome: 'PROJEÇÃO DA RECEITA E DESPESA' },
        { id: 'QSE', nome: 'PROJEÇÃO DO QSE' });
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  async imprimir(formato: FormatoExportacao) {
    const parametro = (await this.parametroContabilidadeServico.obter({ orgao_id: this.login.orgao.id }).toPromise());
    this.assinatura_ensino = parametro ? parametro.assinatura_ensino : false;
    let dtInicial = null;
    let dtFinal = null;
    let mes = 0;
    if (this.opcao === '1') {
      if (!this.periodoInicial || !this.periodoFinal) {
        toastr.warning('Adicione as datas de período!');
        return;
      } else if (this.periodoInicial > this.periodoFinal) {
        toastr.warning('Data inicial maior que a data final!');
        return;
      }
      dtInicial = this.funcaoService.converteDataSQL(this.periodoInicial);
      dtFinal = this.funcaoService.converteDataSQL(this.periodoFinal);
    } else {
      mes = this.mes;
    }
    if (this.anexoSelect === 'Q1') {
      const q1 = new Quadro1Ensino(this.quadroServico, mes, this.exercicioSelect, dtInicial, dtFinal, null, this.injector, this.assinatura_ensino);
      q1.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'Q2') {
      const q2 = new Quadro2Ensino(this.quadroServico, mes, this.exercicioSelect, dtInicial, dtFinal, null, this.injector, this.assinatura_ensino);
      q2.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'Q3') {
      const q3 = new Quadro3Ensino(this.quadroServico, mes, this.exercicioSelect, dtInicial, dtFinal, null, this.injector, this.assinatura_ensino);
      q3.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'Q4') {
      const q4 = new Quadro4Ensino(this.quadroServico, mes, this.exercicioSelect, dtInicial, dtFinal, null, this.injector, this.assinatura_ensino);
      q4.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'Q5') {
      const q5 = new Quadro5Ensino(this.quadroServico, mes, this.exercicioSelect, this.progressoService, dtInicial, dtFinal, null, this.injector, this.assinatura_ensino);
      q5.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'Q6') {
      const q6 = new Quadro6Ensino(this.quadroServico, mes, this.exercicioSelect, this.progressoService, dtInicial, dtFinal, null, this.injector, this.assinatura_ensino);
      q6.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'Q8') {
      const q8 = new Quadro8Ensino(this.quadroServico, mes, this.exercicioSelect, dtInicial, dtFinal, null, this.injector, this.assinatura_ensino);
      q8.montarRelatorio(this.orgaos, formato);
    } else if (this.anexoSelect === 'PRD') {
      this.exercicioService.obterPorAno(this.exercicioSelect.ano - 1, this.login.cidade.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          const prd = new ProjecaoReceitaDespesaEnsino(this.quadroServico, mes, this.exercicioSelect, res.content[0], this.percentualFerias, this.percentual13, this.injector, this.assinatura_ensino);
          prd.montarRelatorio(this.orgaos, formato);
        })
    } else if (this.anexoSelect === 'QSE') {
      this.exercicioService.obterPorAno(this.exercicioSelect.ano - 1, this.login.cidade.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          const prd = new ProjecaoQSE(this.quadroServico, mes, this.exercicioSelect, res.content[0], this.injector, this.assinatura_ensino);
          prd.montarRelatorio(this.orgaos, formato);
        })
    } else {
      toastr.warning('Selecione o(s) órgão(s) a serem impressos');
    }
  }

  /**
   * Método para verificação de objetos, usados em combos `<select>`
   */
  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }

  changeOpcao() {
    setTimeout(() => {
      new GlobalService().calendarMascara();
    }, 100);
  }

  public exportarListagem(formato: FormatoExportacao) {
    this.imprimir(formato)
  }

}
