import { BaseResourceModel } from '../../models/base-resource.model';
import { Deslocamento } from './deslocamento.model';

export class DeslocamentoItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public descricao?: string,
    public hora_saida?: string,
    public hora_chegada?: string,
    public km_saida?: number,
    public km_chegada?: number,
    public deslocamento?: Deslocamento,
    public editavel?: boolean,
  ) {
    super();
  }

  static converteJson(json: any): DeslocamentoItem {
    return Object.assign(new DeslocamentoItem(), json);
  }
}
