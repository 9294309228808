import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { SharedModule } from '../util/shared.module';
import { EmailPersonalizadoRoutingModule } from './email-personalizado-routing.module';
import { EmailPersonalizadoComponent } from './email-personalizado/email-personalizado.component';

@NgModule({
  declarations: [EmailPersonalizadoComponent],
  imports: [
    CommonModule,
    EmailPersonalizadoRoutingModule,
    SharedModule,
    FormsModule,
    FieldsetModule,
    EditorModule,
    FileUploadModule,
  ]
})
export class EmailPersonalizadoModule { }
