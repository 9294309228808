import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { BaseResourceListComponent, Coluna, Filtro, FuncaoService, GlobalService, LoginContabil, Rcms } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { RcmsService } from '../service/rcms.service';
import { RcmsItemService } from "../service/rcms-item.service";
import { RcmsFavorecidorService } from "../service/rcms-favorecido.service";
import * as toastr from "toastr";

declare var $: any;

@Component({
  selector: 'lib-rcms-list-dlg',
  templateUrl: './rcms-list-dlg.component.html'
})
export class RcmsListDlgComponent extends BaseResourceListComponent<Rcms, LoginContabil> implements OnInit {

  /**
   * Declaração de variáveis
   */

  public ptBR: any;

  @Input() public titulo: string = 'REQUISIÇÃO DE COMPRA';

  @Output() compraSelecionada: EventEmitter<Rcms> = new EventEmitter();

  constructor(protected injector: Injector,
    public funcaoService: FuncaoService,
    public globalService: GlobalService,
    protected rcmsFavorecidoService: RcmsFavorecidorService,
    protected rcmsItemService: RcmsItemService,
    private rcmsService: RcmsService) {
    super(rcmsService, injector);
  }

  protected afterInit(): void {
    this.ptBR = new GlobalService().obterDataBR();
  }

  protected acaoRemover(model: Rcms): Observable<Rcms> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Número', coluna: 'numero', alignment: 'center' },
      { titulo: 'Data', coluna: 'data_rcms' },
      { titulo: 'Ficha', coluna: 'ficha.numero' },
      { titulo: 'Despesa', coluna: 'ficha.despesa.nome' },
      { titulo: 'Setor', coluna: 'setor.nome' },
      { titulo: 'Requerente', coluna: 'requerente.nome' },
      { titulo: 'Menor Preço Global', coluna: 'menor_preco_global', decimais: 2, alignment: 'right' }
    ];
  }

  protected relations(): string {
    return ['setor', 'setor.unidade', 'licitacao', 'contrato', 'exercicio', 'orgao', 'ficha', 'ficha.despesa', 'subelemento', 'requerente', 'modalidade', 'prazo', 'favorecidos', 'favorecidos.favorecido.tipo', 'itens.produto_unidade.produto', 'itens.produto_unidade.unidade', 'favorecidos.cotacoes.rcmsItem', 'responsavel_cotacao', 'itens.cotacoes.rcmsFavorecido', 'itens.cotacoes.rcmsFavorecido.favorecido', 'prazo_entrega', 'prazo_pagamento', 'convenio'].join(',');
  }
  // , 'itens.cotacoes.rcmsFavorecido'
  protected condicoesGrid(): {} {
    let parametros = {
      ['orgao.id']: this.login.orgao.id,
      ['exercicio.id']: this.login.exercicio.id
    };
    if (!(this.login.usuario.administrador && this.login.usuario.sistemas_administrador?.includes('compras')) && this.login?.estoque?.id)
      parametros['estoque.id'] = this.login.estoque.id;
    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['numero$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['numero', 'ficha.despesa.codigo'],
      date: ['data_rcms'],
      text: ['requerente.nome', 'requerente', 'ficha.despesa.nome', 'contrato.numero', 'processo', 'licitacao.numero', 'modalidade.nome', 'setor.nome'],
    };
  }

  selecionar(compra: Rcms) {
    $('#dialog_list_compra').modal('hide');
    for(const fav of compra.favorecidos){
      if (+fav.favorecido.tipo.tce !== 1 && +fav.favorecido.tipo.tce !== 2 && +fav.favorecido.tipo.tce !== 10) {
        toastr.warning('Verifique a espécie no cadastro de fornecedor');
        return false; 
      }
    }
    this.compraSelecionada.emit(compra)
  }
}
