import { Directive, ElementRef, OnInit } from '@angular/core';
import { GlobalService, LoginContabil } from 'eddydata-lib';
import { InventarioEstoqueService } from '../service/inventario-estoque.service';

@Directive({
  selector: '[bloqueioInventario]'
})
export class BloqueioInventarioDirective implements OnInit {

  private login: LoginContabil;

  constructor(private inventarioService: InventarioEstoqueService,
    private el: ElementRef) { }

  ngOnInit(): void {
    this.login = GlobalService.obterSessaoLogin();
    this.inventarioService.filtrar(1, -1, { 'orgao.id': this.login.orgao.id, 'setorAlmoxarifado.id': this.login.setorAlmoxarifado.id, 'finalizado': false })
      .subscribe((data) => {
        if (!(!data?.content || data.content.length === 0)) {
          this.el.nativeElement.disabled = true;
          this.el.nativeElement.classList.add('disabled');
        }
      })
  }

}