import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Panel } from 'primeng/panel';
import { GlobalService } from '../../util/global.service';
import { DateFormatPipe } from '../pipe/date-format.pipe';
import { Coluna } from '../types';
import * as toastr from 'toastr';

@Component({
  selector: 'lib-filtro-personalizado',
  templateUrl: './filtro-personalizado.component.html',
  styleUrls: ['./filtro-personalizado.component.css']
})
export class FiltroPersonalizadoComponent implements OnInit, OnChanges {

  @Input() campos: Coluna[] = [];
  @Input() salvarSession: boolean = true;
  @Input() eventKeyUp = true;
  @Input() tipo: 'panel' | 'fieldset' = 'panel';
  @Input() removerPesquisar = false;

  @Output() onPesquisa: EventEmitter<{ filtros: {}, session: {} }> = new EventEmitter();

  @Input() parametros: any = {};
  @Output() parametrosChange: EventEmitter<any> = new EventEmitter();

  public ptBR: any;

  protected datepipe: DatePipe;

  public listaFiltros: Coluna[] = [];
  public menuFiltros: MenuItem[] = [];

  @ViewChild('panel') painel: Panel;

  constructor(
    private router: Router
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.campos && this.campos)
    // this.carregarMenuFiltros(true);
  }


  ngOnInit(): void {
    this.ptBR = new GlobalService().obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.carregarMenuFiltros(true);
  }

  //***********************FILTRO PERSONALIZADO *************************//


  private async carregarMenuFiltros(init?: boolean) {
    if (!this.campos)
      return;
    this.menuFiltros = this.campos.map((x) => x).sort((a, b) => this.ordemMenu(a, b)).map((l): MenuItem => {
      if (!l['filtro1'])
        l['filtro1'] = null;
      if (!l['filtro2'])
        l['filtro2'] = null;
      return {
        label: l.titulo,
        icon: this.iconMenu(l),
        visible: !this.filtroAdicionado(l),
        command: (event) => {
          this.adicionarFiltro(l);
        }
      };
    });
    if (init) {
      if (this.salvarSession)
        await this.adicionarSession();
      this.adicionarPadroes();
    }
    setTimeout(() => {
      new GlobalService().calendarMascara();
    }, 100);
    this.change();
  }

  private iconMenu(coluna: Coluna) {
    let icon = 'plus';
    switch (coluna.tipo) {
      case 'Date':
        icon = 'calendar';
        break;
      case 'Number':
        icon = 'calculator';
        break;
      case 'String':
        icon = 'font';
        break;
      case 'Processo':
        icon = 'font';
        break
      case 'Boolean':
        icon = 'check';
        break;
      case 'AutoComplete':
        icon = 'search-plus';
        break;
      case 'Selection':
        icon = 'list';
        break;
    }
    return `fa fa-${icon}`;
  }

  private ordemMenu(coluna1: Coluna, coluna2: Coluna): number {
    return this.ordemColunaMenu(coluna1) - this.ordemColunaMenu(coluna2);
  }

  private ordemColunaMenu(coluna: Coluna): number {
    switch (coluna.tipo) {
      case 'String':
        return 1;
      case 'Number':
        return 2;
      case 'Date':
        return 3;
      case 'MonthSelector':
        return 3;
      case 'Processo':
        return 3;
      case 'AutoComplete':
        return 4;
      case 'MultipleAutoComplete':
        return 4;
      case 'Boolean':
        return 5;
      default:
        return 100
    }
  }


  public async adicionarSession() {
    let session = sessionStorage.getItem(`${this.router.url}_filtro_personalizado`);
    let filtros;
    if (session)
      filtros = JSON.parse(session);
    if (filtros && Object.entries(filtros).length > 0) {
      for (let [k, v] of Object.entries(filtros)) {
        let coluna = this.campos.find((c) => c.coluna === k);
        if (coluna) {
          coluna['filtro1'] = coluna.tipo === 'Date' && v['filtro1'] ?
            new DateFormatPipe().transform(v['filtro1'], []) : v['filtro1'];
          coluna['filtro2'] = coluna.tipo === 'Date' && v['filtro2'] ?
            new DateFormatPipe().transform(v['filtro2'], []) : v['filtro2'];
          this.adicionarFiltro(coluna);
        }
      }
    }
  }

  public adicionarPadroes() {
    if (!this.listaFiltros || this.listaFiltros.length === 0 || this.listaFiltros.filter((c) => c.padrao).length === this.listaFiltros.length) {
      for (let campo of this.campos) {
        if (campo.padrao)
          this.adicionarFiltro(campo);
      }
    }
  }

  private adicionarFiltro(coluna: Coluna) {
    if (!this.filtroAdicionado(coluna)) {
      if (coluna.tipo === 'Boolean')
        coluna['filtro1'] = true;
      this.listaFiltros.push(coluna);
      if (this.painel)
        this.painel.expand(null);
    }
    this.carregarMenuFiltros();
  }

  public removerFiltro(coluna: Coluna) {
    let index = this.listaFiltros.indexOf(coluna);
    if (index || index === 0)
      this.listaFiltros.splice(index, 1);
    this.carregarMenuFiltros();
  }

  public filtroAdicionado(coluna: Coluna): boolean {
    let filtro = this.listaFiltros.find((f) => f.coluna === coluna.coluna)
    return !(!filtro)
  }

  public change() {
    this.parametros = this.getParametros();
    this.parametrosChange.emit(this.parametros);
  }

  public pesquisar(keyup?: boolean) {
    if (keyup && !this.eventKeyUp)
      return;
    this.change();
    if (!this.parametros)
      return;
    if (this.parametros.session && this.salvarSession)
      sessionStorage.setItem(`${this.router.url}_filtro_personalizado`, JSON.stringify(this.parametros.session));
    this.onPesquisa.emit(this.parametros);
  }

  public getParametros() {
    let filtros: {} = {};
    let session: {} = {};
    try {
      for (let filtro of this.listaFiltros) {
        let convert = this.converterFiltro(filtro);
        if (convert)
          if (!convert['length']) {
            filtros[convert['chave']] = convert['valor'];
            session[filtro.coluna] = {
              filtro1: filtro['filtro1'], filtro2: filtro['filtro2']
            };
          } else {
            for (let fs of <[]>convert) {
              filtros[fs['chave']] = fs['valor'];
              session[filtro.coluna] = {
                filtro1: filtro['filtro1'], filtro2: filtro['filtro2']
              };
            }
          }
      }
      return { filtros, session };
    } catch (error) {
      toastr.warning(error.message);
      return null;
    }
  }

  private converterFiltro(coluna: Coluna): { chave: string, valor: string } | { chave: string, valor: string }[] {
    if (coluna.callBack) {
      let posCallBack = coluna.callBack(coluna)
      if (posCallBack) return posCallBack
    }

    if (coluna.tipo === 'Number' && coluna['filtro1'] !== null) {
      if (coluna.colunasFiltro) {

        if (coluna['filtro1'] !== null && coluna['filtro2'] === null) {
          let query = coluna.colunasFiltro.map(c => `${c}$like=${coluna['filtro1']}%`)

          return { chave: 'OR_1', valor: query.join(';!;!;') }
        }
        let filtro_data = [];
        if (coluna['filtro1'] !== null) {
          let query = coluna.colunasFiltro.map(c => `${c}$ge=${coluna['filtro1']}`)

          filtro_data.push({ chave: `OR_2`, valor: query.join(';!;!;') })
        }
        if (coluna['filtro2'] !== null) {
          let query = coluna.colunasFiltro.map(c => `${c}$le=${coluna['filtro2']}`)

          filtro_data.push({ chave: `OR_3`, valor: query.join(';!;!;') });
        }
        return filtro_data;

      } else {
        if (coluna['filtro1'] !== null && coluna['filtro2'] === null) {
          return { chave: `${coluna.coluna}$like`, valor: coluna['filtro1'] + '%' }
        }
        let filtro_data = [];
        if (coluna['filtro1'] !== null) {
          filtro_data.push({ chave: `${coluna.coluna}$ge`, valor: coluna['filtro1'] });
        }
        if (coluna['filtro2'] !== null) {
          filtro_data.push({ chave: `${coluna.coluna}$le`, valor: coluna['filtro2'] });
        }
        return filtro_data;
      }
    }

    if (coluna.tipo === 'String' && coluna['filtro1']) {
      return { chave: `${coluna.coluna}$like`, valor: `%${coluna['filtro1']}%` };
    }

    if (coluna.tipo === 'Boolean' && coluna['filtro1'])
      return coluna.coluna.split(',').map((x) => { return { chave: x, valor: coluna['filtro1'] } });

    if (coluna.tipo === 'AutoComplete' && coluna.autocomplete && coluna['filtro1'])
      return { chave: coluna.coluna, valor: coluna['filtro1'][coluna.autocomplete.campoChave] };

    if (coluna.tipo === 'Date') {
      let filtro_data = [];
      if (coluna['filtro1'])
        filtro_data.push({ chave: `${coluna.coluna}$ge`, valor: this.datepipe.transform(coluna['filtro1'], 'yyyy-MM-dd') });
      if (coluna['filtro2'])
        filtro_data.push({ chave: `${coluna.coluna}$le`, valor: this.datepipe.transform(coluna['filtro2'], 'yyyy-MM-dd') });
      return filtro_data.length > 0 ? filtro_data : null;
    }

    if (coluna.tipo === 'MonthSelector') {
      let filtro_data = [];
      if (coluna['filtro1']) {
        filtro_data.push({ chave: `${coluna.coluna}$ge`, valor: this.datepipe.transform(coluna['filtro1'], 'yyyy-MM-dd') });
      }
      if (coluna['filtro2']) {
        let data = new Date(coluna['filtro2']);
        let ultimoDia = new Date(data.getFullYear(), data.getMonth() + 1, 0)
        filtro_data.push({ chave: `${coluna.coluna}$le`, valor: this.datepipe.transform(ultimoDia, 'yyyy-MM-dd') });
      }
      return filtro_data.length > 0 ? filtro_data : null;
    }

    if (coluna.tipo == 'Selection' && coluna['filtro1']) {
      return { chave: `${coluna.coluna}$eq`, valor: coluna['filtro1'] };
    }

    if (coluna.tipo == 'Processo' && coluna['filtro1']) {
      const semMascara = coluna['filtro1'].replace('/', '')
      return { chave: `OR`, valor: `${coluna.coluna}$like=%${coluna['filtro1']}%;!;!;${coluna.coluna}$like=%${semMascara}%` }
    }

    if (coluna.tipo === 'MultipleAutoComplete' && coluna.autocomplete && coluna.itensMultiAutoComplete.length > 0) {
      const valor = coluna.itensMultiAutoComplete.map(item => item.id).join(",")
      return { chave: `${coluna.coluna}$in`, valor: valor };
    }

    return null;
  }

  public filtroTipo(filtro: Coluna[], tipo: 'String' | 'Number' | 'Date' | 'Boolean' | 'HTML') {
    return filtro.filter((f) => f.tipo === tipo);
  }

  public ngClassCols(cols: number, padrao: string): {} {
    let ngClass = {};
    if (cols)
      ngClass[`col-sm-${cols}`] = true;
    else
      ngClass[padrao] = true;
    return ngClass;
  }

  public selecionarAutoComplete(pesquisa?: boolean) {
    this.change();
    if (pesquisa)
      this.pesquisar(true);
  }

  public semExluirStyle(filtro: any, style: any) {
    if (filtro.semExcluir)
      return { width: '100%' };
    return style;
  }

  public inserirItemLinha(filtro: Coluna) {
    if (filtro.itensMultiAutoComplete.find(item => item.id === filtro.itemTempMultiAutoComplete?.id)) {
      toastr.warning(`Item já selecionado!`)
      return
    }

    filtro.itensMultiAutoComplete.push(filtro.itemTempMultiAutoComplete);
    filtro.itemTempMultiAutoComplete = {}
  }

  public removerItemLinha(idx: number, item: any, filtro: any) {
    filtro.itensMultiAutoComplete.map((registro) => {
      if (registro.id === item.id) {
        filtro.itensMultiAutoComplete.splice(idx, 1)
      }
    })
  }

  //***********************FILTRO PERSONALIZADO*************************//

}
