import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, TipoContratacaoEvento,  } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class TipoContratacaoEventoService extends BaseResourceService<TipoContratacaoEvento> {

  constructor(
    protected injector: Injector
  ) {
    super(`tipos-contratacao-evento`, injector);
  }

}
