import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DateFormatPipe, FuncaoService, GlobalService, Licitacao, LicitacaoOcorrencia, Login, ReservaDotacaoItem, TipoOcorrenciaLicitacao } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-reserva',
  templateUrl: './licitacao-reserva.component.html'
})
export class LicitacaoReservaComponent implements OnInit, OnDestroy {

  @Input() entidade: Licitacao;
  @Input() reserva: ReservaDotacaoItem[];
  @Input() login: Login;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private globalService: GlobalService,
    public funcaoService: FuncaoService,
    protected confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

}
