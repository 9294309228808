// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Pessoa } from '../comum/pessoa.model';
import { Favorecido } from './favorecido.model';

export class FavorecidoTerceiroSetor extends BaseResourceModel {
    constructor(
        public id?: number,
        public presidente?: Pessoa,
        public dt_inicio_mandato?: Date,
        public dt_fim_mandato?: Date,
        public favorecido?: Favorecido,
        public editavel?: boolean,
        public orgao?: Orgao
    ) {
        super();
    }
    static converteJson(json: any): FavorecidoTerceiroSetor {
        return Object.assign(new FavorecidoTerceiroSetor(), json);
    }
}
