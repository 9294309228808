import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService } from '../../../models/services/base-resource.service';
import { Setor } from '../../../entidade/almoxarifado/setor.model';
import { Page } from '../../../util/page';


@Injectable({
  providedIn: 'root'
})
export class SetorService extends BaseResourceService<Setor> {

  constructor(
    protected injector: Injector
  ) {
    super(`setores`, injector);
  }

  public obterPorNome(nome: string, orgaoId: number): Observable<Setor> {
    return this.http.get<Setor>(
      `${this.login.cidade.id}/${this.api}/filtrar?nome$like=${nome}%&orgao_id=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public setorUsuarios(usuarioId: number, orgaoId: number): Observable<Page> {
    return this.filtrar(1, -1, {
      OR: `usuarios.id=${usuarioId};!;!;usuarios_setor.usuario.id=${usuarioId}`, orderBy: 'usuarios.id', 'orgao.id': orgaoId
    })
  }

}
