import { Component, ElementRef, Injector } from '@angular/core';
import { SetorService } from '../service/setor.service';
import { BaseResourceFormComponent } from '../../../models/base-resource-form';
import { Setor } from '../../../entidade/almoxarifado/setor.model';
import { LoginContabil } from '../../../entidade/login/login-contabil';
import { GlobalService } from '../../../util/global.service';

@Component({
  selector: 'app-setor-view',
  templateUrl: './setor-view.component.html'
})
export class SetorViewComponent extends BaseResourceFormComponent<Setor, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  readOnly: boolean;
  public mes: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected setorService: SetorService,
    protected globalService: GlobalService) {
    super(new Setor(), injector, Setor.converteJson, setorService);
    this.readOnly = true;
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {

  }

  protected parametrosExtras(): {} {
    return { relations: 'unidade,estoques.estoque' };
  }

  protected afterLoad() {
  }

  protected afterInit(): void {
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit() {
  }

  protected afterSubmit(modelo: Setor) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
