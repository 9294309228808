// tslint:disable: variable-name
import { Adiantamento, ContratoAditamento, ImovelOcorrencia } from 'eddydata-lib';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Compra } from '../compra/compra.model';
import { Contrato } from '../compra/contrato.model';
import { Convenio } from '../compra/convenio.model';
import { Favorecido } from '../compra/favorecido.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { AtaRegistro } from '../licitacao/ata-registro.model';
import { LicitacaoAudesp } from '../licitacao/licitacao-audesp.model';
import { Licitacao } from '../licitacao/licitacao.model';
import { Modalidade } from '../licitacao/modalidade.model';
import { Despesa } from '../planejamento/despesa.model';
import { FichaDespesa } from '../planejamento/ficha-despesa.model';
import { Liquidacao } from './liquidacao.model';
import { Precatorio } from './precatorio.model';
import { Data } from 'popper.js';

export class Empenho extends BaseResourceModel {
  constructor(
    public id?: number,
    public numero?: number,
    public mes?: number,
    public especie?: string,
    public tipo_empenho?: string,
    public documento?: string,
    public impresso?: boolean,
    public valor_empenho?: number,
    public data_empenho?: Date,
    public data_vencimento?: Date,
    public adiantamento?: boolean,
    public fase4?: boolean,
    public disponibilidade_anterior?: boolean,
    public inscrito_resto?: boolean,
    public processo?: string,
    public historico?: string,
    public sequencial_pncp?: number,
    public cadastrado_pncp?: boolean,
    public excluido_pncp?: boolean,
    public cancelado_pncp?: boolean,
    public parcelas?: number,
    public nota_emp_sequencial_pncp?: number,
    public orgao?: Orgao,
    public ficha?: FichaDespesa,
    public subelemento?: Despesa,
    public favorecido?: Favorecido,
    public compra?: Compra,
    public exercicio?: Exercicio,
    public contrato?: Contrato,
    public convenio?: Convenio,
    public licitacao?: Licitacao,
    public modalidade?: Modalidade,
    public ata?: AtaRegistro,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public usuario_cadastro?: Usuario,
    public licitacao_audesp?: LicitacaoAudesp,
    public licitacao_audesp_execucao?: LicitacaoAudesp,
    public liquidacoes?: Liquidacao[],
    public total_empenhado?: number,
    public total_empenho_sem_anulacao?: number,
    public total_empenho_anulado?: number,
    public total_liquidado?: number,
    public total_pago?: number,
    public total_empenhado_ficha?: number,
    public total_empenhado_ficha_anterior?: number,
    public total_dotacao_ficha?: number,
    public total_creditado_ficha?: number,
    public total_reservado?: number,
    public data_liq_nova?: Data,
    public precatorio?: Precatorio,
    public imovel_ocorrencia?: ImovelOcorrencia,
    public contrato_aditamento?: ContratoAditamento,
    public adiantamento_empenho?: Adiantamento
  ) {
    super();
  }

  static converteJson(json: any): Empenho {
    return Object.assign(new Empenho(), json);
  }
}
