import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, ContaBancariaRecurso, Page } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class ContaBancariaRecursoService extends BaseResourceService<ContaBancariaRecurso> {

  constructor(
    protected injector: Injector
  ) {
    super(`contas-bancarias-recursos`, injector);
  }

  public obterPorConta(id: number): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar/?relations=recurso,aplicacao,convenio&conta_id=${id}&orderBy=recurso_pagto$DESC`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterRecursoPrincipal(id: number): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar/?relations=recurso,aplicacao,convenio&conta_id=${id}&recurso_pagto=true`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterRecursoPorCodigo(id: number, recurso: string, aplicacao: string, variavel?: string): Observable<Page> {
    const filtro = !variavel ? `recurso.codigo=${recurso}&aplicacao.codigo=${aplicacao}` : `recurso.codigo=${recurso}&aplicacao.codigo=${aplicacao}&convenio.codigo=${variavel}`;
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar/?excluido=false&relations=recurso,aplicacao,convenio&conta_id=${id}&${filtro}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }



}
