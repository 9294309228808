import { DatePipe } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ContratoService, DespesaService, FuncaoGovernoService,
  RecursoService
} from 'administrativo-lib';
import {
  Coluna, Contrato, Despesa, EddyAutoComplete, FuncaoGoverno,
  FuncaoService, GlobalService, Login, Recurso, Relatorio
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PreLiquidacaoRestoService } from '../service/pre-liquidacao-resto.service';

@Component({
  selector: 'lib-pre-liquidacao-resto-rpt',
  templateUrl: './pre-liquidacao-resto-rpt.component.html'
})
export class PreLiquidacaoRestoRptComponent implements OnInit, OnDestroy {

  login: Login = new Login();
  funcaoGoverno: FuncaoGoverno;
  subFuncaoGoverno: FuncaoGoverno;
  despesa: Despesa;
  recurso: Recurso;
  aplicacao: Recurso;
  contrato: Contrato;
  filtroDespesa: boolean;
  filtroFuncao: boolean;
  filtroSubFuncao: boolean;
  filtroRecurso: boolean;
  filtroAplicacao: boolean;
  filtroContrato: boolean;

  public selectedOrdem: string;
  public selectUsuario: boolean;
  public selectedOrdenacao: string;

  public ptBR: any;

  public listaRelatorio: Array<any>;
  public contratoAutoComplete: EddyAutoComplete<Contrato>;
  public funcaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public subFuncaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public despesaAutoComplete: EddyAutoComplete<Despesa>;
  public relatorio = '1';
  public dataInicial: Date;
  public dataFinal: Date;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    protected injector: Injector,
    protected messageService: MessageService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected funcaoGovernoService: FuncaoGovernoService,
    protected recursoService: RecursoService,
    protected despesaService: DespesaService,
    protected contratoService: ContratoService,
    protected preLiquidacaoRestoService: PreLiquidacaoRestoService
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.login = GlobalService.obterSessaoLogin();
    this.selectedOrdem = 'ord1';
    this.selectedOrdenacao = 'ASC';
    this.selectUsuario = true;
    this.listaRelatorio = [];
    this.listaRelatorio.push({ label: 'TODOS AS PRÉ-LIQUIDAÇÕES DE RESTOS A PAGAR', value: 1 });
    this.listaRelatorio.push({ label: 'SOMENTE PRÉ-LIQUIDAÇÕES DE RESTOS A PAGAR ANULADAS', value: 2 });
    this.carregarAutoCompletes();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  imprimir(
    titulo: string, usuario: string, sobrenome: string, orgao: string,
    brasao: string, orientacao: 'landscape' | 'portrait',
    nomepdf?: string, largura?: string[], lista?: any[]) {
    Relatorio.imprimir(titulo, usuario, sobrenome, orgao, brasao, lista, this.colunas(),
      orientacao, nomepdf, largura, ['valor_liquidado']);
  }

  colunas(): string[] | Coluna[] {
    return [
      { titulo: 'Data', coluna: 'data_liquidacao' },
      { titulo: 'Empenho', coluna: 'empenho.numero' },
      { titulo: 'Despesa', coluna: 'empenho.subelemento' },
      { titulo: 'Benefíciario', coluna: 'empenho.favorecido.nome' },
      { titulo: 'Documento', coluna: 'documento' },
      { titulo: 'Valor', coluna: 'valor_liquidado', decimais: 2, alignment: 'right' }
    ];
  }

  gerarRelatorio() {
    const parametros = {};
    if (!this.dataInicial || !this.dataFinal) {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }

    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;

    parametros['data_liquidacao$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
    parametros['data_liquidacao$le'] = this.funcaoService.converteDataSQL(this.dataFinal);

    if (this.filtroContrato) {
      parametros['empenho.contrato.numero'] = this.contrato.numero;
    }

    if (this.filtroFuncao) {
      parametros['empenho.funcao'] = this.funcaoGoverno.codigo;
    }
    if (this.filtroSubFuncao) {
      parametros['empenho.subfuncao'] = this.subFuncaoGoverno.codigo;
    }

    if (this.filtroDespesa) {
      parametros['empenho.subelemento'] = this.despesa.codigo;
    }

    if (this.filtroRecurso) {
      parametros['empenho.recurso'] = this.recurso.codigo;
    }

    if (this.filtroAplicacao) {
      parametros['empenho.aplicacao'] = this.aplicacao;
    }

    if (this.relatorio === '2') {
      parametros['anulacao'] = 'true';
    }

    if (this.selectUsuario) {
      parametros['usuario_cadastro.id'] = this.login.usuario.id;
    }

    if (this.selectedOrdem === 'ord1') {
      parametros['orderBy'] = 'data_liquidacao$' + this.selectedOrdenacao;;
    } else if (this.selectedOrdem === 'ord2') {
      parametros['orderBy'] = 'empenho.numero$' + this.selectedOrdenacao;;
    } else if (this.selectedOrdem === 'ord3') {
      parametros['orderBy'] = 'empenho.favorecido.nome$' + this.selectedOrdenacao;;
    }

    // tslint:disable-next-line: max-line-length
    parametros['relations'] = 'empenho,empenho.favorecido';

    this.preLiquidacaoRestoService
      .filtrar(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        const param = {};
        param['exercicio_id'] = this.login.exercicio.id;
        param['orgao.id'] = this.login.orgao.id;
        let datepipe = new DatePipe('pt');
        this.imprimir(`DOCUMENTO: PRÉ-LIQUIDAÇÃO RESTOS A PAGAR NO PERÍODO DE ${datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
          this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
          'Listagem pré-liquidaões restos', ['auto', 'auto', 'auto', '*', 'auto', 'auto'], lista.content);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );

  }

  private carregarAutoCompletes() {
    // autocomplete para funcao governo
    this.funcaoAutoComplete = new EddyAutoComplete(null, this.funcaoGovernoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para subfuncao governo
    this.subFuncaoAutoComplete = new EddyAutoComplete(null, this.funcaoGovernoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para despesa
    this.despesaAutoComplete = new EddyAutoComplete(null, this.despesaService,
      'id', ['codigo', 'nome'], { nivel: 6, exercicio_id: this.login.exercicio.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para recurso
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para contrato
    this.contratoAutoComplete = new EddyAutoComplete(null, this.contratoService,
      'id', ['numero', 'favorecido.nome'], {
      orgao_id: this.login.orgao.id, relations: 'favorecido',
      orderBy: 'favorecido.nome'
    }, { number: ['numero'], text: ['favorecido.nome'] }
    );
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }
}
