// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { AcaoGoverno } from './acao-governo.model';
import { Recurso } from './recurso.model';
import { Despesa } from './despesa.model';

export class AcaoFonteRecurso extends BaseResourceModel {
  constructor(
    public id?: number,
    public ano?: number,
    public recurso?: Recurso,
    public aplicacao?: Recurso,
    public despesa?: Despesa,
    public acao_governo?: AcaoGoverno,
    public valor?: number,
    public editavel?: boolean
  ) {
    super();
  }

  static converteJson(json: any): AcaoFonteRecurso {
    return Object.assign(new AcaoFonteRecurso(), json);
  }
}
