import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AnexoLoaService } from '../service/anexo-loa.service';
import { FuncaoService, LoginContabil, Exercicio, Relatorio, GlobalService } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class Anexo6Orgao implements OnDestroy {
  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLoaService) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // tslint:disable: max-line-length
  public montarRelatorio(exercicio: Exercicio, orgaos: number[]) {
    this.anexoServico.obterAnexo6Orgao(exercicio.id, orgaos, this.login.ppa.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {

        Relatorio.imprimirPersonalizado(
          'RELAÇÃO DE AÇÕES DE GOVERNO CONSTANTES NO ORÇAMENTO'
          , this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          this.conteudo(dados, exercicio),
          'portrait', 'RELAÇÃO_DE_AÇÕES_DE_GOVERNO',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private conteudo(dados: any[], exercicio: Exercicio): {}[] {

    // monta o cabecalho
    const registros: {}[] = [[{
      text: `EXERCÍCIO ${exercicio.ano}`, fontSize: 11,
      alignment: 'center', bold: true, colSpan: 5, border: [true, true, true, false]
    }, '', '', '', ''],
    [{
      text: 'ESPECIFICAÇÃO',
      alignment: 'center',
      bold: true, fontSize: 7
    }, {
      text: 'PROJETOS',
      alignment: 'center',
      bold: true, fontSize: 7
    }, {
      text: 'ATIVIDADES',
      alignment: 'center',
      bold: true, fontSize: 7
    }, {
      text: 'OPERAÇÕES ESPECIAIS',
      alignment: 'center',
      bold: true, fontSize: 7
    }, {
      text: 'TOTAL',
      alignment: 'center',
      bold: true, fontSize: 7
    }]
    ];

    const grupoOrgaos = this.funcaoService.agrupar(dados, 'nome_orgao',
      ['projeto', 'atividade', 'operacao']);

    // monta o agrupamento do relatório
    let soma1 = 0;
    let soma2 = 0;
    let soma0 = 0;
    for (const item of grupoOrgaos) {
      registros.push(
        [{
          text: this.funcaoService.mascarar('##.#### *', item.grupo as string), fontSize: 9,
          bold: true, colSpan: 5, border: [true, true, true, true], margin: [0, 5, 0, 5]
        }, '', '', '', '']

      );

      soma1 += +item.totalizadores[`projeto`];
      soma2 += +item.totalizadores[`atividade`];
      soma0 += +item.totalizadores[`operacao`];

      // lista os registros do relatorio
      for (const registro of item.registros) {
        registros.push([
          {
            text: this.funcaoService.mascarar('#.### *', registro.nome_acao as string), fontSize: 7, border: [true, false, false, false]
          },
          {
            text: registro.projeto > 0 ? this.funcaoService.convertToBrNumber(registro.projeto) : '', alignment: 'right',
            fontSize: 7, border: [true, false, false, false]
          },
          {
            text: registro.atividade > 0 ? this.funcaoService.convertToBrNumber(registro.atividade) : '', alignment: 'right',
            fontSize: 7, border: [true, false, false, false]
          },
          {
            text: registro.operacao > 0 ? this.funcaoService.convertToBrNumber(registro.operacao) : '', alignment: 'right',
            fontSize: 7, border: [true, false, false, false]
          },
          {
            text: this.funcaoService.convertToBrNumber(+registro.projeto + +registro.atividade + +registro.operacao),
            alignment: 'right', fontSize: 7, border: [true, false, true, false]
          }
        ]);
      }



    }

    registros.push([
      {
        text: 'TOTAL GERAL', fontSize: 7, border: [true, true, true, true], margin: [0, 0, 0, 5], bold: true
      },
      {
        text: this.funcaoService.convertToBrNumber(soma1), alignment: 'right',
        fontSize: 7, border: [true, true, true, true], bold: true
      },
      {
        text: this.funcaoService.convertToBrNumber(soma2), alignment: 'right',
        fontSize: 7, border: [true, true, true, true], bold: true
      },
      {
        text: this.funcaoService.convertToBrNumber(soma0), alignment: 'right',
        fontSize: 7, border: [true, true, true, true], bold: true
      },
      {
        text: this.funcaoService.convertToBrNumber(+soma1 + +soma2 + +soma0),
        alignment: 'right', fontSize: 7, border: [true, true, true, true], bold: true
      }
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 60, 60, 60, 60],
        body: registros
      }
    }];
  }


}
