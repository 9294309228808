import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, GrupoArquivoTransparencia } from 'eddydata-lib';

@Injectable({
    providedIn: 'root'
  })
  export class GrupoArquivoTransparenciaService extends BaseResourceService<GrupoArquivoTransparencia> {
  
    constructor(
      protected injector: Injector
    ) {
      super(`grupos-arquivo-transparencia`, injector);
    }

  }
  