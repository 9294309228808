import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FuncaoService, RcmsCotacao, RcmsItem } from 'eddydata-lib';

@Component({
  selector: 'app-rcms-cotacao-empate-dlg',
  templateUrl: './rcms-cotacao-empate-dlg.component.html'
})
export class RcmsCotacaoEmpateDlgComponent implements OnInit {
  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Input() public itens: RcmsItem[] = []
  @Output() public onFinalizar: EventEmitter<void> = new EventEmitter();

  public cotacaoSelecionada: RcmsCotacao[] = [];

  constructor(public funcaoService: FuncaoService) { }

  ngOnInit(): void { }

  public finalizar() {
    for (let i = 0; i < this.itens.length; i++) {
      const item = this.itens[i];
      for (const cotacao of item.cotacoes) {
        cotacao.vencedor = cotacao.rcmsFavorecido.favorecido.id === this.cotacaoSelecionada[i]?.rcmsFavorecido?.favorecido?.id;
      }
    }

    this.onFinalizar.emit()
    this.onHide();
  }

  public verificaSeTemEmpate(item: RcmsItem) {
    return item?.cotacoes?.find(c => c?.['empate']) ? true : false
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar)
  }

}