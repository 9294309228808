import { BaseResourceModel } from '../../models/base-resource.model';

export class Raca extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
  ) {
    super();
  }
  static converteJson(json: any): Raca {
    return Object.assign(new Raca(), json);
  }
}
