import { Component, Injector, Input, OnInit } from '@angular/core';
import * as toastr from 'toastr';
import { ConfirmationService } from 'primeng/api';
import { ContratoStorageService } from 'compra-lib';
import { RegistroPncpService } from '../../service/registro-pncp.service';
import { FuncaoService } from '../../../util/funcao.service';
import { ProgressoService } from '../../../components/progresso/service/progresso.service';
import { PNCPService } from '../../../util/pncp.service';
import { PncpContratoService } from '../../service/contrato.service';
import { Contrato } from '../../../entidade/compra/contrato.model';
import { Login } from '../../../entidade/login/login';
import { ContratoStorage } from '../../../entidade/compra/contrato-storage.model';

@Component({
  selector: 'app-pncp-atas-cadastro',
  templateUrl: './pncp-atas-cadastro.component.html',
  styleUrls: ['./pncp-atas-cadastro.component.css']
})
export class PncpAtasCadastroComponent implements OnInit {

  @Input() login: Login;
  @Input() contrato: Contrato;

  historicoCompra: Array<any> = new Array<any>();

  resultados: Array<{ item: string, sucesso: boolean, mensagem: string }> = new Array<{ item: string, sucesso: boolean, mensagem: string }>();
  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected contratoService: PncpContratoService,
    protected confirmationService: ConfirmationService,
    protected pncpService: PNCPService,
    protected progressoService: ProgressoService,
    private funcaoService: FuncaoService,
    private storage: ContratoStorageService) {
  }

  ngOnInit(): void {
  }

  idPncp(tipo: 'L' | 'A'): string {
    if (tipo == 'L') {
      return `${this.login.orgao.cnpj.replace(/\D/g, '')}-1-${("000000" + this.contrato.licitacao.sequencial_pncp).slice((this.contrato.licitacao.sequencial_pncp + '').length)}/${this.contrato.licitacao.exercicio.ano}`;
    } else {
      return `${this.login.orgao.cnpj.replace(/\D/g, '')}-1-${("000000" + this.contrato.sequencial_pncp).slice((this.contrato.sequencial_pncp + '').length)}/${this.contrato.ano}`;
    }
  }

  cancelarAta() {
    this.confirmationService.confirm({
      header: 'Cancelar ata',
      message: `<pre>Confirma o cancelamento dessa ata? Essa ação NÃO poderá ser desfeita.</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo cancelamento.',
      rejectLabel: 'Não',
      accept: async () => {
        this.pncpService.enviarAtaRegistroPreco([this.contrato.id], true).subscribe((idTransacao) => {
          this.progressoService.show(idTransacao, async (retorno) => {
            this.resultados = retorno;
          });
        }, e => {
          this.funcaoService.acaoErro(e);
        });
      }
    });
  }

  apagarAta() {
    this.confirmationService.confirm({
      header: 'Cancelar ata',
      message: `<pre>Confirma a exclusão dessa ata? Essa ação NÃO pode ser desfeita.</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo exclusão.',
      rejectLabel: 'Não',
      accept: async () => {
        this.pncpService.excluirAtaRegistroPreco(this.contrato).subscribe(async (res) => {
          const relations = 'favorecido,orgao,orgao.cidade,prazo,entrega,tipo_contratacao,licitacao,'
            + 'modalidade,licitacao_audesp,fiscais,licitacao.exercicio';
          const cont: Contrato = await this.contratoService.obter({ relations: relations, id: this.contrato.id, 'orgao.id': this.login.orgao.id }).toPromise();
          cont.excluido_pncp = true;
          cont['atualizacao_reinf'] = true;
          const seq = cont.sequencial_pncp;
          cont.cadastrado_pncp = false;
          cont.sequencial_pncp = null;
          await this.contratoService.atualizar(cont).toPromise();
        }, (e) => {
          this.funcaoService.acaoErro(e);
        });
      }
    });
  }

  enviarArquivo(item: ContratoStorage) {
    this.confirmationService.confirm({
      header: 'Envio de documento',
      message: `<pre>Confirma o envio do arquivo?</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo envio.',
      rejectLabel: 'Não',
      accept: async () => {
        const aux = Object.assign({}, this.contrato);
        aux.arquivos = [];
        item.contrato = aux;
        this.pncpService.enviarArquivoAta(item).subscribe((idTransacao) => {
          this.progressoService.show(idTransacao, async (retorno) => {
            this.resultados = retorno;
            if (this.resultados[0].sucesso) {
              const index = this.contrato.arquivos.indexOf(item);
              item.sequencial_pncp = +this.resultados[0].mensagem.split(':')[1];
              this.contrato.arquivos[index] = item;
            }
          });
        }, e => {
          this.funcaoService.acaoErro(e);
        });
      }
    });
  }

  apagarArquivo(item: ContratoStorage) {
    this.confirmationService.confirm({
      header: 'Apagar documento',
      message: `<pre>Confirma a remoção do documento?</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo exclusão.',
      rejectLabel: 'Não',
      accept: async () => {
        const aux = Object.assign({}, this.contrato);
        aux.arquivos = [];
        item.contrato = aux;
        this.pncpService.apagarArquivoAta(item).subscribe(async (res) => {
          const index = this.contrato.arquivos.indexOf(item);
          const seq = item.sequencial_pncp;
          item.sequencial_pncp = null;
          await this.storage.atualizar(item).toPromise();
          this.contrato.arquivos[index] = item;
          toastr.success('Arquivo removido com sucesso do pncp!');
        }, e => {
          if (e.error?.message) {
            toastr.error(e.error.message);
          } else {
            this.funcaoService.acaoErro(e);
          }
        });
      }
    });
  }

  async buscarHistoricoAta() {
    this.historicoCompra = await this.pncpService.consultarHistoricoAta(this.login.orgao.cnpj.replace(/[./-]/g, ''), this.contrato.ano, this.contrato.licitacao.sequencial_pncp, this.contrato.sequencial_pncp).toPromise();
  }

}
