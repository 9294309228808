import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { BaseResourceListComponent, Coluna, DateFormatPipe, Filtro, FuncaoService, GlobalService, LoginContabil, Rcms, RcmsCotacao, RcmsFavorecido, RcmsItem } from 'eddydata-lib';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import * as toastr from 'toastr';
import { CompraService } from '../../compra/service/compra.service';
import { RcmsItemService } from '../../rcms/service/rcms-item.service';
import { RcmsService } from '../../rcms/service/rcms.service';
import { takeUntil } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'lib-importar-rcms',
  templateUrl: './importar-rcms.component.html',
  styleUrls: ['./importar-rcms.component.css']
})
export class ImportarRcmsComponent extends BaseResourceListComponent<Rcms, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public visulizarAjusteServico: boolean = false;
  public produtosAjuste: any[] = [];

  public listaFiltros: Coluna[] = [];
  public menuFiltros: MenuItem[] = [];
  public ptBR: any;
  public datepipe: DatePipe;

  public selecionados: Rcms[] = [];

  public mes: string;

  public favorecido: RcmsFavorecido;
  public visualizarItens: boolean = false;
  public visualizarData: boolean = false;
  public dataCompra: Date = new Date();

  public rcmssEmpate: Rcms[] = []
  public visualizarEmpate: boolean = false;

  public visualizarId: number;


  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public rcmsService: RcmsService,
    public funcaoService: FuncaoService,
    protected confirmationService: ConfirmationService,
    private rcmsitemService: RcmsItemService,
    private compraService: CompraService,
    protected globalService: GlobalService) {
    super(rcmsService, injector);
    this.datepipe = new DatePipe('pt');
    this.ptBR = new GlobalService().obterDataBR();
    this.usarExtendido = true;
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'orgao,ficha,ficha.despesa,requerente,subelemento,setor,' +
      'compras,favorecidos.cotacoes,favorecidos.cotacoes.rcmsItem,favorecidos.favorecido,' +
      'itens.cotacoes.rcmsItem.produto_unidade,licitacao,contrato_aditamento';
  }

  protected condicoesGrid(): {} {
    const filtros = this.obterFiltrosLista();
    const parametros = {
      'exercicio.id': this.login.exercicio.id,
      'orgao.id': this.login.orgao.id,
      'autorizado_compra': true,
      'autorizado': true,
      'itens.cotacoes.id$not_null': '',
      'favorecidos.id$not_null': '',
      'excluido': false,
      'compras.id$null': '',
      'tem_compras': false,
      importRcms: true
    }

    return filtros ? Object.assign(parametros, filtros) : parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['numero$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {

    }
  }

  public beforeInit(): void {
    this.loadMenuFiltros(true);
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
    this.usarExtendido = true;
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: Rcms): Observable<Rcms> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return null;
  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  public selecionarData() {
    if (!this.validarEmpate())
      return;
    this.visualizarData = true;
    this.compraService
      .obterDatasPosteriores(this.login.orgao.id, this.login.exercicio.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.dataCompra = new DateFormatPipe().transform(res.ultima, []);
      });
  }

  public selecionoEmpate(rcmss: Rcms[]) {
    this.selecionados = this.selecionados.map((s) => {
      let rcms = rcmss.find((r) => r.id === s.id);
      if (rcms) {
        s.favorecidos = s.favorecidos.map((f) => {
          f.cotacoes = f.cotacoes.filter((c) => {
            let item = rcms.itens.find((i) => i.id === c.rcmsItem.id);
            if (!item)
              return true;
            let cotacao = item.cotacoes.find((c2) => c2.id === c.id);
            if (!cotacao) {
              if (!f['cotacoes_removida'])
                f['cotacoes_removida'] = [];
              f['cotacoes_removida'].push(c);
              return false;
            }
            return true;
          });
          return f;
        })
      }
      return s;
    });
    this.selecionarData();
  }

  public importarRcms() {
    if (!this.dataCompra) {
      toastr.warning('Informe a data da(s) OF(s) a gerar')
      return;
    }
    if (!this.selecionados || this.selecionados.length === 0) {
      toastr.warning('Não foi selecionado requisição para importação');
      return;
    }

    if (!this.validarItensAjuste()) {
      this.visualizarData = false;
      return;
    }

    let importar = this.selecionados.map((s) => {
      let favorecidos = Object.assign([], s.favorecidos);
      delete s.favorecidos;
      s.favorecidos = favorecidos.filter((f) => f['importar']);
      return s;
    });

    if (this.login.parametro?.compras?.gerar_empenho_com_of) {
      this.confirmationService.confirm({
        header: 'Confirmar criação de empenho junta a OF',
        message: `Será gerado a ordem de fornecimento e o empenho. Deseja continuar?`,
        key: 'confirmarGerarEmpenho',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: async () => {
          await this.enviar(importar);
        }, reject: () => {
          toastr.warning('Importação abortada pelo usuário!')
        }
      });
    } else {
      this.enviar(importar)
    }

  }

  private async enviar(importar) {
    this.compraService.importarRcms(importar, this.login.exercicio.id, this.login.orgao.id, new FuncaoService().converteDataSQL(this.dataCompra))
      .subscribe((data) => {
        toastr.success('RCMS importada com sucesso!');
        this.visualizarData = false;
        this.selecionados = [];
        this.preencherGrid();
      }, (error) => {
        toastr.error(error.error.payload ? error.error.payload : 'Erro ao importar Requisição!');
      })
  }

  public async aposAjustarProdutos(produtos: any[]) {
    if (!produtos)
      return;
    if (produtos.filter((p) => !p.produto_unidade?.id).length > 0) {
      toastr.warning(`Alguns serviços não foram ajustados`);
      return;
    }

    const lote = [];

    for (const selecionado of this.selecionados) {
      for (const favorecido of selecionado.favorecidos) {
        for (const cotacao of favorecido.cotacoes) {
          let produto_unidade = produtos
            .find((p) => p.produto === cotacao.rcmsItem.produto_unidade.produto.nome
              && p.unidade === cotacao.rcmsItem.produto_unidade.unidade.nome && p.rcms.id === selecionado.id);
          if (produto_unidade) {
            cotacao.rcmsItem.produto_unidade = produto_unidade.produto_unidade;
            lote.push({
              produto_unidade: produto_unidade.produto_unidade, produto: {
                produto: cotacao.rcmsItem.produto, unidade: cotacao.rcmsItem.unidade, rcms_id: selecionado.id
              }
            });
          }
        }
      }
    }
    if (lote.length > 0)
      await this.rcmsitemService.atualizarProdutoUnidadeLote(lote).toPromise();
    this.importarRcms();
  }

  private validarItensAjuste() {
    const produtos_unidade = [];
    for (const selecionado of this.selecionados) {
      for (const item of selecionado.itens) {
        for (const cotacao of item.cotacoes) {
          if (!cotacao.rcmsItem?.produto_unidade?.id)
            produtos_unidade.push({ produto: cotacao.rcmsItem.produto, unidade: cotacao.rcmsItem.unidade, rcms: selecionado })
        }
      }
    }

    if (produtos_unidade.length > 0) {
      toastr.warning(`Foi identificado itens com serviços não cadastrados`);
      this.produtosAjuste = produtos_unidade;
      this.visulizarAjusteServico = true;
      return false;
    }
    return true;
  }

  public visualizarItenCompra(rcmsFavorecido: RcmsFavorecido) {
    this.favorecido = rcmsFavorecido;
    this.visualizarItens = true;
  }

  private loadMenuFiltros(init?: boolean) {
    let filtros = this.obterColunasRelatorio();
    this.menuFiltros = filtros.map((l): MenuItem => {
      if (!l['filtro1'])
        l['filtro1'] = null;
      if (!l['filtro2'])
        l['filtro2'] = null;
      if (l.coluna === 'data_rcms' && init) {
        let dataAtual = new Date();
        let primeiroDiaMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
        let ultimoDiaMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0);
        l['filtro1'] = primeiroDiaMes;
        l['filtro2'] = ultimoDiaMes;
        this.adicionarFiltro(l);
      }
      if (l.coluna === 'numero_lote' && init) {
        this.adicionarFiltro(l);
      };
      return {
        label: l.titulo,
        icon: 'fa fa-plus',
        visible: !this.filtroAdicionado(l),
        command: (event) => {
          this.adicionarFiltro(l);
        }
      };
    });
  }


  private adicionarFiltro(coluna: Coluna) {
    if (!this.filtroAdicionado(coluna))
      this.listaFiltros.push(coluna);
    this.loadMenuFiltros();
  }

  public removerFiltro(coluna: Coluna) {
    let index = this.listaFiltros.indexOf(coluna);
    if (index || index === 0)
      this.listaFiltros.splice(index, 1);
    this.loadMenuFiltros();
  }

  public filtroAdicionado(coluna: Coluna): boolean {
    let filtro = this.listaFiltros.find((f) => f.coluna === coluna.coluna)
    return !(!filtro)
  }

  protected obterColunasRelatorio(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Data Requisição', coluna: 'data_rcms', tipo: 'Date', alignment: 'center' });
    retorno.push({ titulo: 'Nº Requisição', coluna: 'numero', alignment: 'center', tipo: 'Number' });
    retorno.push({ titulo: 'Fornecedor', coluna: 'favorecidos.favorecido.nome', tipo: 'String' });
    retorno.push({ titulo: 'Ficha', coluna: 'ficha.numero', alignment: 'center', tipo: 'String' });
    retorno.push({ titulo: 'Despesa', coluna: 'ficha.despesa.codigo', alignment: 'center', tipo: 'String' });
    retorno.push({ titulo: 'Requerente', coluna: 'requerente.nome', tipo: 'String' });
    retorno.push({ titulo: 'Contrato', coluna: 'contrato.numero', alignment: 'center', tipo: 'String', mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }] });
    retorno.push({ titulo: 'Licitação', coluna: 'licitacao.numero', alignment: 'center', tipo: 'String', mask: '00000/0000' });
    retorno.push({ titulo: 'Processo', coluna: 'processo', alignment: 'center', tipo: 'String', mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }] });
    retorno.push({ titulo: 'Lote', coluna: 'numero_lote', tipo: 'Number' })
    return retorno;
  }

  private obterFiltrosLista(): {} {
    let filtros: {} = {};
    for (let filtro of this.listaFiltros) {
      let convert = this.converterFiltro(filtro);
      if (convert)
        if (!convert['length']) {
          filtros[convert['chave']] = convert['valor'];
        } else {
          for (let fs of <[]>convert) {
            filtros[fs['chave']] = fs['valor'];
          }
        }
    }
    return filtros;
  }

  private converterFiltro(coluna: Coluna): { chave: string, valor: string } | { chave: string, valor: string }[] {
    if (coluna.tipo === 'Number' && coluna['filtro1']) {
      if (coluna['filtro1'] && !coluna['filtro2']) {
        return { chave: `${coluna.coluna}`, valor: coluna['filtro1'] }
      }
      let filtro_data = [];
      if (coluna['filtro1']) {
        filtro_data.push({ chave: `${coluna.coluna}$ge`, valor: coluna['filtro1'] });
      }
      if (coluna['filtro2']) {
        filtro_data.push({ chave: `${coluna.coluna}$le`, valor: coluna['filtro2'] });
      }
      return filtro_data;
    }
    if (coluna.tipo === 'String' && coluna['filtro1']) {
      return { chave: `${coluna.coluna}$like`, valor: `%${coluna['filtro1']}%` };
    }
    if (coluna.tipo === 'Date') {
      let filtro_data = [];
      if (coluna['filtro1'])
        filtro_data.push({ chave: `${coluna.coluna}$ge`, valor: this.datepipe.transform(coluna['filtro1'], 'yyyy-MM-dd') });
      if (coluna['filtro2'])
        filtro_data.push({ chave: `${coluna.coluna}$le`, valor: this.datepipe.transform(coluna['filtro2'], 'yyyy-MM-dd') });
      return filtro_data;
    }
    return null;
  }

  private buscarDadosTratadosRcms(rcms: Rcms) {
    this.rcmsService.importarRcms(rcms.numero, rcms.exercicio.id, rcms.orgao.id)
      .subscribe((data) => {
        if (data) {
          rcms.favorecidos = data;
          rcms.favorecidos.forEach((f) => f['importar'] = true);
        }
        this.carregarNumeroCompra();
      }, (error) => this.funcaoService.acaoErro(error));
  }


  private carregarNumeroCompra() {
    this.compraService.proximoNumeroOF(this.login.exercicio.id, this.login.orgao.id, true)
      .subscribe((data) => {
        if (data) {
          let count = data;
          this.selecionados = this.selecionados.sort((a, b) => +a.numero - +b.numero);
          for (let selecionado of this.selecionados) {
            if (!selecionado.favorecidos)
              continue;
            selecionado.favorecidos = selecionado.favorecidos.sort((a, b) => +a.id - +b.id);
            for (let favorecido of selecionado.favorecidos) {
              if (favorecido['importar'])
                favorecido['numero_compra'] = count++;
              else
                favorecido['numero_compra'] = 0;
            }
          }
        }
      });
  }

  public todosSelecionados(): boolean {
    return !this.lista ? true : this.lista.filter((l) => this.validSelect(l)).length === this.lista.length;
  }

  public selecionarTodos() {
    let todosSel = this.todosSelecionados();
    let lista: Rcms[] = [];
    if (this.lista) {
      this.lista.forEach((l) => {
        if (!todosSel && !this.validSelect(l)) {
          this.selecionar(l, true, null, false);
          lista.push(l);
        } else if (todosSel && this.validSelect(l))
          this.selecionar(l, false, null, false);
      });
    }
  }


  public selecionar(rcms: Rcms, select: boolean, favorecido?: RcmsFavorecido, descTratativa?: boolean) {
    if (select) {
      if (!favorecido) {
        if (!descTratativa)
          this.buscarDadosTratadosRcms(rcms);
        this.selecionados.push(rcms);
      } else
        favorecido['importar'] = true;
    } else {
      if (favorecido && rcms.favorecidos.filter((f) => f['importar']).length > 1) {
        favorecido['importar'] = false;
      } else
        this.selecionados = this.selecionados.filter((s) => s.id !== rcms.id);
    }
    this.carregarNumeroCompra();
  }

  public validSelect(rcms: Rcms) {
    const sel = this.selecionados.find((s) => s.id === rcms.id);
    return !(!sel)
  }

  public valorTotal(favorecido: RcmsFavorecido) {
    let valor_total = 0;
    for (let cotacao of favorecido.cotacoes) {
      valor_total += +cotacao.valor_unitario * +cotacao.rcmsItem.quantidade;
    }
    return valor_total;
  }

  public ngClassCols(cols: number, padrao: string): {} {
    let ngClass = {};
    if (cols)
      ngClass[`col-sm-${cols}`] = true;
    else
      ngClass[padrao] = true;
    return ngClass;
  }


  public validarEmpate() {
    this.rcmssEmpate = [];
    for (let rcms of this.selecionados.filter(r => r.favorecidos)) {
      for (let favorecido of rcms.favorecidos) {
        if (!favorecido['importar'])
          continue;
        for (let cotacao of favorecido.cotacoes) {
          let empate = rcms.favorecidos.find((f) => {
            if (f.id === favorecido.id || !favorecido['importar'])
              return false;
            let itemEmpate = f.cotacoes.find((c) => c.rcmsItem.id === cotacao.rcmsItem.id);
            return !(!itemEmpate);
          });

          if (empate) {
            cotacao = Object.assign(new RcmsCotacao(), cotacao);
            cotacao.rcmsFavorecido = Object.assign(new RcmsFavorecido(), favorecido);

            let item = Object.assign(new RcmsItem(), cotacao.rcmsItem);

            let rcms2 = this.rcmssEmpate.find((r) => r.id === rcms.id);
            if (!rcms2) {
              rcms2 = Object.assign(new Rcms(), rcms);
              rcms2.itens = [];
              this.rcmssEmpate.push(rcms2);
            }

            let item2 = rcms2.itens.find((i) => i.id === item.id);
            if (!item2) {
              item2 = item;
              item2.cotacoes = [];
              item2['vencedor'] = cotacao.id;
              rcms2.itens.push(item2);
            }

            item2.cotacoes.push(cotacao);
          }
        }
      }
    }
    if (this.rcmssEmpate.length > 0) {
      this.visualizarEmpate = true;
      return false;
    }
    return true;
  }
}
