import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  BalanceteDespesaAcaoService, BalanceteDespesaFuncaoService, BalanceteDespesaGrupoService, BalanceteDespesaProcessadaService, BalanceteDespesaRecursoService, BalanceteDespesaService,
  BalanceteDespesaSubelementoService, BalanceteElementoDespesaService, BalanceteExtraService, BalanceteLeiOrganicaService, BalanceteReceitaRecursoService, BalanceteReceitaService,
  DespesaService, EmpenhoService, RecursoService
} from 'administrativo-lib';
import { Anexo18Balanco, BalancoService, FichaDespesaService, FichaReceitaService, NotaExplicativaService } from 'contabil-lib';
import {
  AudespService, Despesa, EddyAutoComplete, Executora, ExecutoraService, Exercicio, ExercicioService, FormatoExportacao,
  FuncaoService, GlobalService, LoginPublico, Orgao, OrgaoAssinaturaService, OrgaoService, ProgressoService, Recurso, Unidade, UnidadeService
} from 'eddydata-lib';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { tsXLXS } from 'ts-xlsx-export';

@Component({
  selector: 'app-transparencia-execucao-balancete',
  templateUrl: './transparencia-execucao-balancete.component.html'
})
export class TransparenciaExecucaoBalanceteComponent implements OnInit, OnDestroy {
  login: LoginPublico = new LoginPublico();
  mes: number;
  ano: number;
  listaOrgaos: SelectItem[];
  orgaos: number[] = [];
  public listaExercicios: Array<any>;
  public balanceteSelect = 'BR';
  public listaBalancetes: Array<any>;
  protected unsubscribe: Subject<void> = new Subject();
  public sintetico: boolean = false;
  public agruparFr: boolean = false;
  public filtroRecurso: boolean = false;
  public filtroAplicacao: boolean = false;
  public recurso: Recurso;
  public aplicacao: Recurso;
  public aplicacao_variavel: Recurso;
  public aplicacaoInicial: Recurso;
  public aplicacaoFinal: Recurso;
  public filtroAplicacao_variavel: boolean = false;
  public opcao: number = 2;
  public opcaoUnidade = 1;
  public periodoInicial: Date = new Date();
  public periodoFinal: Date = new Date();
  public opcaoRecurso: 'TODOS' | 'RECURSO' = 'TODOS';
  public opcaoImpressao: number = 1;
  public recursoSelect: 'GERAL' | 'EDUCACAO' | 'SAUDE' = 'GERAL';
  public quadrimestreSelect: number = 1;
  public unidadeSelect: Unidade;
  public executoraSelect: Executora;
  public despesaSelect: Despesa;
  public todosOrgao: Orgao[];
  public codigoAplicacao: boolean = false;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoVariavelAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoFiltroAutoComplete: EddyAutoComplete<Recurso>;
  public unidadeAutoComplete: EddyAutoComplete<Unidade>;
  public executoraAutoComplete: EddyAutoComplete<Executora>;
  public despesaAutoComplete: EddyAutoComplete<Despesa>;

  constructor(
    protected fichaDespesaService: FichaDespesaService,
    protected fichaReceitaService: FichaReceitaService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    protected br: BalanceteReceitaService,
    protected bdp: BalanceteDespesaService,
    protected bdg: BalanceteDespesaGrupoService,
    protected bdf: BalanceteDespesaRecursoService,
    protected bdsf: BalanceteDespesaFuncaoService,
    protected bde: BalanceteExtraService,
    protected bp: BalanceteDespesaProcessadaService,
    protected brr: BalanceteReceitaRecursoService,
    protected bdse: BalanceteDespesaSubelementoService,
    protected bed: BalanceteElementoDespesaService,
    protected bda: BalanceteDespesaAcaoService,
    protected lo: BalanceteLeiOrganicaService,
    protected balancoServico: BalancoService,
    protected notaService: NotaExplicativaService,
    protected orgaoAssinaturaService: OrgaoAssinaturaService,
    protected progressoService: ProgressoService,
    protected audespService: AudespService,
    protected exercicioService: ExercicioService,
    protected orgaoService: OrgaoService,
    protected empenhoService: EmpenhoService,
    protected unidadeService: UnidadeService,
    protected executoraService: ExecutoraService,
    protected despesaService: DespesaService,
    protected recursoService: RecursoService,
    protected funcaoService: FuncaoService) {
    this.login = GlobalService.obterSessaoLogin();
    this.mes = 1;
    this.ano = this.login.exercicio.id;
    this.orgaos.push(this.login.orgao.id);
  }

  ngOnInit() {
    this.login.contraste = sessionStorage.getItem('contraste') === 'true';
    // this.orgaoService.obterTodos()
    //   .pipe(takeUntil(this.unsubscribe))
    //   .subscribe(dados => {
    //     this.listaOrgaos = [];
    //     for (const model of dados) {
    //       this.listaOrgaos.push({ label: `${model.codigo} - ${model.nome}`, value: model.id });
    //     }
    //   });
    this.orgaoService.filtrar(0, -1, { orderBy: 'codigo' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaOrgaos = [];
        this.todosOrgao = dados.content;
        for (const model of dados.content) {
          this.listaOrgaos.push({ label: `${model.codigo} - ${model.nome}`, value: model.id });
        }
      });
    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaExercicios = new Array();
        const lista = dados.content;
        for (const exercicio of lista as Array<Exercicio>) {
          this.listaExercicios.push({ id: exercicio.id, ano: exercicio.ano });
        }
      });
    this.listaBalancetes = [
      { id: 'BR', nome: 'BALANCETE DA RECEITA ORÇAMENTÁRIA' },
      { id: 'BRR', nome: 'BALANCETE DA RECEITA ORÇAMENTÁRIA POR RECURSO' },
      { id: 'BD', nome: 'BALANCETE DA DESPESA EMPENHADA' },
      { id: 'BG', nome: 'BALANCETE DE DESPESA POR GRUPO DE DESPESA' },
      { id: 'BF', nome: 'BALANCETE DE DESPESA POR FONTE DE RECURSO' },
      { id: 'BSF', nome: 'BALANCETE DE DESPESA POR FUNÇÃO DE GOVERNO' },
      { id: 'BP', nome: 'BALANCETE DE DESPESA PROCESSADA' },
      { id: 'BSE', nome: 'BALANCETE POR SUBELEMENTO DA DESPESA' },
      { id: 'BED', nome: 'BALANCETE DA DESPESA POR ELEMENTO DA DESPESA' },
      { id: 'BDA', nome: 'BALANCETE DA DESPESA POR AÇÃO' },
      { id: 'BE', nome: 'BALANCETE DE DESPESA EXTRA-ORÇAMENTÁRIA' },
      { id: 'B18', nome: 'DEMONSTRAÇÃO DOS FLUXOS DE CAIXA' },
      { id: 'EFD', nome: 'EXPORTAR FICHAS DE DESPESAS ORÇAMENTÁRIAS' },
      { id: 'EFR', nome: 'EXPORTAR FICHAS DE RECEITAS ORÇAMENTÁRIAS' },
      { id: 'ESE', nome: 'EXPORTAR FICHAS DE DESPESA POR SUBELEMENTO' },
      { id: 'LO', nome: 'LEI ORGÂNICA' }
    ];

    this.unidadeAutoComplete = new EddyAutoComplete(null, this.unidadeService, 'id', ['codigo', 'nome'],
      { 'ppa.id': this.login.ppa.id, relations: 'ppa', orderBy: 'codigo,nome' },
      { text: ['codigo', 'nome'] }
    );

    this.executoraAutoComplete = new EddyAutoComplete(null, this.executoraService, 'id', ['codigo', 'nome'],
      { 'unidade.ppa.id': this.login.ppa.id, relations: 'unidade.ppa', orderBy: 'codigo,nome' },
      { text: ['codigo', 'nome'] }
    );

    this.despesaAutoComplete = new EddyAutoComplete(null, this.despesaService,
      'id', ['codigo', 'nome'], { nivel: 6, exercicio_id: this.login.exercicio.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para recurso
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'codigo,nome' }, { number: ['codigo'], text: ['nome', 'codigo'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'codigo,nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para aplicacao Variavel
    this.aplicacaoVariavelAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['variavel', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'codigo,nome' }, { text: ['variavel', 'nome'] }
    );

    // autocomplete para o filtro somente aplicação
    this.aplicacaoFiltroAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'codigo,nome' }, { text: ['codigo'] }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  antesImprimir() {
    const parametros = {};

    if (this.opcao == 1) {
      let mesInicial = this.periodoInicial.getUTCMonth() + 1
      let mesFinal = this.periodoFinal.getUTCMonth() + 1

      parametros['mes$in'] = [mesInicial, mesFinal]
    } else {
      parametros['mes'] = this.mes
    }

    parametros['relations'] = 'orgao';
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['ano'] = this.listaExercicios.filter(e => +e.id === +this.ano)[0]?.ano;
    parametros['descricao$in'] = 'BALANCETE-ISOLADO-CONTA-CORRENTE,BALANCETE-ISOLADO-CONTA-CORRENTE,BALANCETE-ISOLADO-CONTA-CONTABIL';

    this.audespService.filtrar(0, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res.content?.some(a => !a.armazenado)) {
          this.confirmationService.confirm({
            message: 'Mês não se encontra encerrado, dados poderão sofrer alterações.',
            acceptLabel: "OK",
            rejectVisible: false,
            header: 'Aviso!',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.imprimir('pdf');
            },
          });
        } else {
          this.imprimir('pdf');
        }
      });
  }

  async imprimir(formato: FormatoExportacao) {
    let dataAtual: Date = new Date();
    const datepipe: DatePipe = new DatePipe('pt');
    let dtInicial = null;
    let dtFinal = null;
    let tribunal: string;
    let mesCorrente = (dataAtual.getMonth() + 1);
    if (this.opcao == 1) {
      this.mes = null;
      dtInicial = datepipe.transform(this.periodoInicial, 'yyyy-MM-dd');
      dtFinal = datepipe.transform(this.periodoFinal, 'yyyy-MM-dd');
    }

    if (this.ano > this.login.exercicio.id) {
      this.messageService.add({ severity: 'warn', summary: 'Validação', detail: 'Ano selecionado não pode ser impresso.' });
    } else if (this.mes >= mesCorrente && this.ano >= this.login.exercicio.id) {
      this.messageService.add({ severity: 'warn', summary: 'Validação', detail: 'Mês selecionado não pode ser impresso.' });
    } else {
      if (this.orgaos && this.orgaos.length > 0) {
        if (this.balanceteSelect === 'BD') {
          this.bdp.montarBalanceteDespesa(formato, this.mes, this.ano, this.orgaos, this.login, dtInicial, dtFinal, this.sintetico);
        } else if (this.balanceteSelect === 'BR') {
          let recurso: number;
          let aplicacao: number;
          let aplicacao_variavel: number;
          if (this.sintetico || this.agruparFr) {
            recurso = this.filtroRecurso && this.recurso?.id ? this.recurso.id : null;
            aplicacao = this.filtroAplicacao && this.aplicacao?.id ? this.aplicacao.id : null;
            aplicacao_variavel = this.filtroAplicacao_variavel && this.aplicacao_variavel?.id ? this.aplicacao_variavel.id : null;
          }
          this.br.montarBalanceteReceita(formato, this.mes, this.ano, this.orgaos, this.login, dtInicial, dtFinal, this.sintetico, recurso, aplicacao, aplicacao_variavel, this.agruparFr);
        } else if (this.balanceteSelect === 'BRR') {
          const recurso = this.opcaoRecurso !== 'TODOS' ? this.recursoSelect : null;
          if (+this.opcaoImpressao === 1) {
            this.brr.montarBalanceteReceitaRecurso(recurso, this.ano, this.orgaos, this.login);
          } else if (+this.opcaoImpressao === 2) {
            this.brr.montarBalanceteReceitaRecurso(recurso, this.ano, this.orgaos, this.login, datepipe.transform(this.periodoInicial, 'yyyy-MM-dd'), datepipe.transform(this.periodoFinal, 'yyyy-MM-dd'));
          } else if (+this.opcaoImpressao === 3) {
            this.brr.montarBalanceteReceitaRecurso(recurso, this.ano, this.orgaos, this.login, null, null, this.quadrimestreSelect);
          } else {
            this.brr.montarBalanceteReceitaRecurso(recurso, this.ano, this.orgaos, this.login, null, null, null, this.mes);
          }
        } else if (this.balanceteSelect === 'BG') {
          this.bdg.montarBalanceteDespesa(formato, this.mes, this.ano, this.orgaos, this.login, dtInicial, dtFinal);
        } else if (this.balanceteSelect === 'BF') {
          this.bdf.montarBalanceteDespesa(formato, this.mes, this.ano, this.orgaos, this.login, dtInicial, dtFinal);
        } else if (this.balanceteSelect === 'BSF') {
          this.bdsf.montarBalanceteDespesa(formato, this.mes, this.ano, this.orgaos, this.login, dtInicial, dtFinal);
        } else if (this.balanceteSelect === 'BSE') {
          if (this.unidadeSelect) {
            tribunal = this.unidadeSelect.tribunal;
          }
          if (this.executoraSelect) {
            tribunal = this.executoraSelect.tribunal;
          }
          if (this.opcaoUnidade == 1 || this.opcaoUnidade == 3 || this.opcaoUnidade == 6) {
            this.unidadeSelect = null;
            this.executoraSelect = null;
            this.despesaSelect = null;
          }
          const aplicacaoInicial = this.opcaoUnidade == 6 && this.aplicacaoInicial ? this.aplicacaoInicial.codigo : null;
          const aplicacaoFinal = this.opcaoUnidade == 6 && this.aplicacaoFinal ? this.aplicacaoFinal.codigo : null;
          this.bdse.montarBalanceteDespesa(formato, this.mes, this.ano, this.orgaos, this.login, dtInicial, dtFinal, this.despesaSelect?.id, this.opcaoUnidade, tribunal, aplicacaoInicial, aplicacaoFinal);
        } else if (this.balanceteSelect === 'BED') {
          this.bed.montarBalanceteElementoDespesa(formato, this.mes, this.ano, this.orgaos, this.login, dtInicial, dtFinal);
        } else if (this.balanceteSelect === 'BE') {
          this.bde.montarBalanceteExtra(formato, this.mes, this.ano, this.orgaos, this.login, dtInicial, dtFinal, this.sintetico);
        } else if (this.balanceteSelect === 'BP') {
          this.bp.montarBalancete(formato, this.mes, this.ano, this.orgaos, this.login, dtInicial, dtFinal, this.sintetico);
        } else if (this.balanceteSelect === 'B18') {
          const ex = await this.exercicioService.obterId(this.ano).toPromise();
          const a18 = new Anexo18Balanco(this.balancoServico, this.notaService, this.orgaoAssinaturaService, this.progressoService);
          a18.montarRelatorio(this.orgaos, ex, this.sintetico, this.todosOrgao, this.mes, formato);
        } else if (this.balanceteSelect === 'ESE') {
          this.exportarEmpenhoSubelemento(this.mes, this.ano, this.orgaos, this.login, dtInicial, dtFinal)
        } else if (this.balanceteSelect === 'EFD') {
          this.exportarXLSXDespesa(this.mes, this.ano, this.orgaos, this.login, dtInicial, dtFinal)
        } else if (this.balanceteSelect === 'EFR') {
          this.exportarXLSXReceita(this.mes, this.ano, this.orgaos, this.login, dtInicial, dtFinal)
        } else if (this.balanceteSelect === 'BDA') {
          const unidade = +this.opcaoUnidade == 2 ? this.unidadeSelect?.id : null;
          this.bda.montarBalancetePorAcao(this.mes, this.ano, this.orgaos, this.login, this.sintetico, unidade, this.codigoAplicacao, dtInicial, dtFinal);
        } else if (this.balanceteSelect === 'LO') {
          this.lo.montarBalanceteLeiOrganica(this.mes, this.ano, this.orgaos, this.login);
        } else {
          this.messageService.add({ severity: 'warn', summary: 'Validação', detail: 'Selecione o anexo a ser impresso' });
        }
      } else {
        this.messageService.add({ severity: 'warn', summary: 'Validação', detail: 'Selecione o(s) órgão(s) a serem impressos' });
      }
    }

  }

  public exportarEmpenhoSubelemento(mes: number, exercicioId: number, orgaos: number[], login: any, dtInicio: string, dtFim: string) {
    if (this.orgaos && this.orgaos.length > 0) {
      if (this.opcao == 1 && !dtInicio && !dtFim)
        return toastr.warning('Informe o período para exportação!')

      if (this.opcao == 2 && !mes)
        return toastr.warning('Informe o mês para exportação!')

      this.empenhoService.exportarEmpenhosSubelemento(exercicioId, orgaos.join(), mes, dtInicio, dtFim)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(async data => {
          this.progressoService.show(data, (retorno) => {
            const listaItens = new Array();
            for (const item of retorno) {
              const entity = {
                ficha: `${item.ficha}`,
                ua: `${item.ua}`,
                ue: `${item.ue}`,
                funcao: `${item.funcao}`,
                subfuncao: `${item.subfuncao}`,
                programa: `${item.programa}`,
                acao: `${item.acao}`,
                recurso: `${item.recurso}`,
                despesa: `${item.subelemento}`,
                dotacao_inicial: this.funcaoService.convertToBrNumber(+item.dotacao),
                movimento: this.funcaoService.convertToBrNumber(+item.adicionais),
                empenhado: this.funcaoService.convertToBrNumber(+item.empenhado),
                liquidado: this.funcaoService.convertToBrNumber(+item.liquidado),
                pago: this.funcaoService.convertToBrNumber(+item.pago)
              };
              listaItens.push(entity);
            }
            tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile('empenhos-subelemento-ano-' + this.login.exercicio.ano);
          });
        });
    } else {
      toastr.warning('Selecione o(s) órgão(s) a serem impressos');
    }
  }

  public trocarOpcao() {
    this.periodoInicial = new Date();
    this.periodoFinal = new Date();
    let interval = setInterval(() => { new GlobalService().calendarMascara(); clearInterval(interval) }, 1000)
  }

  public exportarXLSXDespesa(mes: number, exercicioId: number, orgaos: number[], login: any, dtInicio: string, dtFim: string) {
    if (this.orgaos && this.orgaos.length > 0) {
      const parametros: {} = {};

      if (!mes && !dtInicio && !dtFim)
        return toastr.warning('Informe o mês para exportação!')

      if (mes) {
        parametros['mes'] = mes;
      } else {
        parametros['dtInicio'] = dtInicio;
        parametros['dtFim'] = dtFim;
      }

      if (exercicioId) {
        parametros['ano'] = exercicioId;
      }
      parametros['orgaos'] = orgaos.join();

      if (this.opcao == 2) {
        delete parametros['dtInicio']
        delete parametros['dtFim']
      } else {
        delete parametros['mes']
      }

      this.fichaDespesaService.exportarFichas(parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(async dados => {
          const listaItens = new Array();
          for (const item of dados) {
            const entity = {
              ficha: item.fh_numero,
              ua: `${item.ua_codigo} ${item.ua_nome}`,
              ue: `${item.ue_codigo} ${item.ue_nome}`,
              funcao: `${item.f_codigo} ${item.f_nome}`,
              subfuncao: `${item.sf_codigo} ${item.sf_nome}`,
              programa: `${item.p_codigo} ${item.p_nome}`,
              acao: `${item.pr_codigo} ${item.pr_nome}`,
              aplicacao_variavel: `${item.av_codigo ? item.av_codigo : ''} ${item.av_nome ? item.av_nome : ''}`,
              despesa: `${item.d_codigo} ${item.d_nome}`,
              subfuncao_siope: `${item.sfs_valor ? item.sfs_valor : ''} ${item.sfs_texto ? item.sfs_texto : ''}`,
              convenio: item?.convenio,
              vinculo_convenio: `${item.vinculo_convenio ? 'Sim' : 'Não'}`,
              dotacao_inicial: this.funcaoService.convertToBrNumber(+item.total_orcado),
              movimento: this.funcaoService.convertToBrNumber(+item.movimento),
              especial: this.funcaoService.convertToBrNumber(+item.especial),
              transferencia_interna: this.funcaoService.convertToBrNumber(+item.transferencia_interna),
              empenhado: this.funcaoService.convertToBrNumber(+item.empenhado),
              liquidado: this.funcaoService.convertToBrNumber(+item.liquidado),
              pago: this.funcaoService.convertToBrNumber(+item.pago),
              rcms_em_aberto: this.funcaoService.convertToBrNumber(+item.total_rcms_pendente),
              provisionado: this.funcaoService.convertToBrNumber(+item.provisionado),
              reservas_ativas: this.funcaoService.convertToBrNumber(+item.total_com_pre_empenho),
              reservas_ativas_sem_pre_empenho: this.funcaoService.convertToBrNumber(+item.total_sem_pre_empenho),
              reservas_com_empenho: this.funcaoService.convertToBrNumber(+item.total_com_empenho),
              reservas_sem_empenho: this.funcaoService.convertToBrNumber(+item.total_sem_empenho),
            };
            listaItens.push(entity);
          }
          tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile('fichas-despesas-ano-' + this.login.exercicio.ano);
        });
    } else {
      toastr.warning('Selecione o(s) órgão(s) a serem impressos');
    }
  }

  public exportarXLSXReceita(mes: number, exercicioId: number, orgaos: number[], login: any, dtInicio: string, dtFim: string) {
    if (this.orgaos && this.orgaos.length > 0) {
      const parametros: {} = {};

      if (mes) {
        parametros['mes'] = mes;
      } else {
        parametros['dtInicio'] = dtInicio;
        parametros['dtFim'] = dtFim;
      }

      if (exercicioId) {
        parametros['ano'] = exercicioId;
      }
      parametros['orgaos'] = orgaos.join();

      this.fichaReceitaService.exportarFichas(parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(dados => {
          const listaItens = new Array();
          for (const item of dados) {
            const entity = {
              ficha: item.fh_numero,
              categoria: item.categoria,
              sub_categoria: item.subcategoria,
              fonte: item.fonte,
              rubrica: item.rubrica,
              alinea: item.alinea,
              sub_alinea: item.subalinea,
              desdobramento: item.desdobramento,
              recurso: item.apv_codigo ? `${item.apv_codigo} ${item.apv_nome}` : `${item.re_codigo}${item.ap_codigo}0000 ${item.ap_nome}`,
              valor_orcado: this.funcaoService.convertToBrNumber(+item.fh_valor_orcado),
              adicional: item.adicional,
              anulacao: item.anulacao,
              previsao_atualizada: this.funcaoService.convertToBrNumber(+item.valor_previsto),
              arrecada: item.arrecada,
              convenio: item?.convenio,
              grupo_pasep: item.grupo_pasep,
              ensino: item.fh_ensino,
              ID_receita: item.r_id,
              ID_receita_siope: '',
              observacao: '',
            };
            listaItens.push(entity);
          }
          tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile(`fichas-receitas-ano-20${this.ano}`);
        });
    } else {
      toastr.warning('Selecione o(s) órgão(s) a serem impressos');
    }
  }

  public alternarFiltros(seletor: 'S' | 'A') {
    if (seletor === 'S') {
      this.sintetico = !this.sintetico
      this.agruparFr = false
    } else if (seletor === 'A') {
      this.agruparFr = !this.agruparFr
      this.sintetico = false
    }
  }
}
