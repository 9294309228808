import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TransferenciaAutorizacaoListComponent } from './transferencia-autorizacao-list/transferencia-autorizacao-list.component';
import { TransferenciaAutorizacaoViewComponent } from './transferencia-autorizacao-view/transferencia-autorizacao-view.component';
import { TransferenciaAutorizacaoFormComponent } from './transferencia-autorizacao-form/transferencia-autorizacao-form.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: TransferenciaAutorizacaoListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: TransferenciaAutorizacaoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: TransferenciaAutorizacaoFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: TransferenciaAutorizacaoViewComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransferenciaAutorizacaoRoutingModule { }
