import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../util/auth.guard';
import { PncpListComponent } from './pncp-list/pncp.component';

const routes: Routes = [
  { path: '', component: PncpListComponent, canActivate: [AuthGuard] },
  // { path: ':id/editar', component: UsuarioFormComponent, canActivate: [AuthGuard] },
  // { path: ':id/visualizar', component: UsuarioViewComponent, canActivate: [AuthGuard] },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PncpRoutingModule { }
