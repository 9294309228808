import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import {
  BaseResourceListComponent, Coluna, EmpenhoResto, Filtro, FormatoExportacao, GlobalService, LoginContabil, ProgressoService
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmpenhoRestoService } from '../service/empenho-resto.service';

@Component({
  selector: 'app-empenho-resto-list',
  templateUrl: './empenho-resto-list.component.html',
  styleUrls: ['./empenho-resto-list.component.css']
})
export class EmpenhoRestoListComponent extends BaseResourceListComponent<EmpenhoResto, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public especieSelect = 'EMR';
  public listaEspecies: Array<any>;
  public listaFuncoes: Array<any>;
  public listaDespesas: Array<any>;
  public listaFavorecidos: Array<any>;
  public ptBR: any;
  public datepipe: DatePipe;
  public empenhoAnular: EmpenhoResto;
  public visualizarAnulacao: boolean = false;
  public cancelado: boolean = false;
  public empenhosSelecionados: any[] = [];
  public numerosEmpenhos: any[] = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private globalService: GlobalService,
    protected progressoService: ProgressoService,
    protected messageService: MessageService,
    private empenhoService: EmpenhoRestoService) {
    super(empenhoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(item: EmpenhoResto): boolean {
    return !item.inscrito_resto && item.especie === 'EMR'
      && (+item.valor_empenho - +item.total_empenho_anulado) > 0
  }
  
  protected relations(): string {
    return 'favorecido,compra';
  }

  protected condicoesGrid(): {} {
    const parametros = {};
    this.datepipe = new DatePipe('pt');
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['exercicio.id'] = this.login.exercicio.id;

    sessionStorage.removeItem('/empenhos-resto_data1');
    sessionStorage.removeItem('/empenhos-resto_data2');

    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['numero$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['numero', 'ano', 'valor_empenho', 'subelemento', 'favorecido.cpf_cnpj'],
      date: ['data_empenho'],
      text: ['favorecido.nome', 'subelemento_nome'],
    };
  }

  public beforeInit(): void {
    this.usarFiltroPersonalizado = true;
    this.usarExtendido = true;
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
  }

  protected afterInit(): void {
    this.listaEspecies = [
      { id: 'EMO', nome: 'ORÇAMENTÁRIO' },
      { id: 'EME', nome: 'EXTRA-ORÇAMENTARIO' },
      { id: 'EMR', nome: 'RESTOS A PAGAR' }
    ];
  }

  protected acaoRemover(model: EmpenhoResto): Observable<EmpenhoResto> {
    return null;
  }

  preencherTabela() {
    const parametros = this.condicoesGrid();
    parametros['relations'] = 'favorecido';
    this.empenhoService
      .filtrar(
        this.paginaCorrente,
        this.login.limite,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.lista = lista.content;
          this.paginaTotal = lista.totalPages;
        },
        () => alert('erro ao retornar lista')
      );
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Data', coluna: 'data_empenho', bold: true },
      { titulo: 'Número', coluna: 'numero' },
      { titulo: 'Beneficiario', coluna: 'favorecido.nome' },
      { titulo: 'Despesa', coluna: 'subelemento' },
      { titulo: 'Recurso Siconfi', coluna: 'recurso_siconfi' },
      { titulo: 'Código Reinf', coluna: 'codigo_reinf' },
      { titulo: 'Historico', coluna: 'historico' },
      { titulo: 'Vencimento', coluna: 'data_vencimento' },
      { titulo: 'Valor', coluna: 'valor_empenho', alignment: 'right', decimais: 2 },
      { titulo: 'Saldo a liquidar', coluna: 'saldo_liquidar', alignment: 'right', decimais: 2, removeNegativo: true, }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'favorecido';
    this.empenhoService
      .extendido(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          for (const resto of lista.content) {
            resto['saldo_liquidar'] = this.saldoLiquidar(resto);
          }
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE EMPENHOS RESTOS A PAGAR',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem empenhos restos a pagar', ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], lista.content, ['valor_empenho']);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  public obterColunasFiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({
      titulo: 'Contrato', coluna: 'contrato.numero', alignment: 'center', tipo: 'String', cols: 2,
      mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }]
    });
    retorno.push({ titulo: 'Data Empenho', coluna: 'data_empenho', tipo: 'Date', alignment: 'center', filtro2: new Date() });
    retorno.push({ titulo: 'Código Despesa', coluna: 'subelemento', alignment: 'center', tipo: 'String', cols: 2 });
    retorno.push({ titulo: 'Descrição Despesa', coluna: 'subelemento_nome', tipo: 'String' });
    retorno.push({ titulo: 'Recurso', coluna: 'recurso', tipo: 'String' });
    retorno.push({ titulo: 'Aplicação', coluna: 'aplicacao', tipo: 'String' });
    retorno.push({ titulo: 'Variável', coluna: 'recurso_variavel', tipo: 'String' });
    retorno.push({ titulo: 'Itens de OF', coluna: 'compra.itens.produto', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Ano', coluna: 'ano', alignment: 'center', tipo: 'Number', padrao: true });
    retorno.push({ titulo: 'Licitação', coluna: 'licitacao.numero', tipo: 'String' });
    retorno.push({ titulo: 'Histórico', coluna: 'historico', tipo: 'String' });
    retorno.push({ titulo: 'Favorecido', coluna: 'favorecido.nome', tipo: 'String', padrao: false });
    retorno.push({ titulo: 'Nº Empenho', coluna: 'numero', alignment: 'center', tipo: 'Number', padrao: true });
    retorno.push({ titulo: 'Ficha', coluna: 'compra.ficha.numero', alignment: 'center', tipo: 'String', padrao: false });
    retorno.push({ titulo: 'Nº Compra', coluna: 'compra.numero', alignment: 'center', tipo: 'Number', padrao: false });
    retorno.push({ titulo: 'Convênio', coluna: 'convenio.numero', alignment: 'center', tipo: 'String' });
    retorno.push({ titulo: 'Modalidade', coluna: 'modalidade.nome', tipo: 'String' });
    retorno.push({ titulo: 'RCMS', coluna: 'compra.rcms.numero', alignment: 'center', tipo: 'Number' });
    retorno.push({ titulo: 'Valor', coluna: 'valor_empenho', alignment: 'center', tipo: 'Number' });
    retorno.push({
      titulo: 'Processo', coluna: 'processo', alignment: 'center', tipo: 'Processo', cols: 2,
      mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }], notUnmask: true
    });

    return retorno;
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public onChangeEmpenho(value: any, especie: any) {
    if (especie === 'EME') {
      this.router.navigate(['empenhos-extra']);
    } else if (especie === 'EMO') {
      this.router.navigate(['empenhos-orcamentario']);
    }
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public verificarCancelados(item: EmpenhoResto, empty: boolean, type?: 'P' | 'T',): boolean {
    const totalCancelado = +item['total_cancelado_processado'] + +item['total_cancelado_nao_processado'];
    const saldoEmpenho = +item['total_empenhado'] - +item['total_liquidado'];

    if (empty && !Number(totalCancelado.toFixed(2))) {
      return true;
    } else {
      if (type === 'T') {
        this.cancelado = (Number(totalCancelado.toFixed(2)) && (Number(saldoEmpenho.toFixed(2)) - Number(totalCancelado.toFixed(2)) === 0));
        return (Number(totalCancelado.toFixed(2)) && (Number(saldoEmpenho.toFixed(2)) - Number(totalCancelado.toFixed(2)) === 0));
      }
      if (type === 'P') {
        return (Number(totalCancelado.toFixed(2)) && (Number(saldoEmpenho.toFixed(2)) - Number(totalCancelado.toFixed(2)) > 0));
      }
    }
  }

  public saldoLiquidar(empenhoResto: EmpenhoResto) {
    let cancelado
    if (+empenhoResto.total_empenho_anulado === 0) {
      cancelado = +empenhoResto.total_cancelado_processado + +empenhoResto.total_cancelado_nao_processado
    } else {
      cancelado = +empenhoResto.total_empenho_anulado
    }
    cancelado = cancelado < 0 ? (cancelado * -1) : cancelado

    if (empenhoResto.valor_empenho > 0) {
      return +empenhoResto.valor_empenho - cancelado - +empenhoResto.total_liquidado;
    } else {
      return +empenhoResto.valor_empenho + cancelado;

    }
  }

  public anular(item) {
    this.empenhoAnular = item;
    this.visualizarAnulacao = true;
  }

  public carregarCodigoReinf() {
    this.empenhoService.preencherCodigoReinf().pipe(takeUntil(this.unsubscribe)).subscribe((idTransacao) => {
      this.progressoService.show(idTransacao, (retorno) => {
        this.preencherGrid()
      })
    }, e => {
      console.log(e)
      this.messageService.add({ severity: 'error', summary: 'Erro', detail: e.error?.payload });
    });
  }

  public ImprimirNotaPorEmpenhosSelecionados(item: EmpenhoResto) {
    if (item['selecionado'] === true) {
      item['selecionado'] = false;
      // Encontre o índice do item na lista this.empenhosSelecionados
      const index = this.empenhosSelecionados.findIndex(e => e.id === item.id);
      if (index > -1) {
        this.empenhosSelecionados.splice(index, 1);
      }
    } else {
      item['selecionado'] = true;
      // Verifique se o item já não está na lista
      if (!this.empenhosSelecionados.some(e => e.id === item.id)) {
        this.empenhosSelecionados.push(item);
      }
    }
    this.numerosEmpenhos = [];
    for (const empenho of this.empenhosSelecionados) {
      this.numerosEmpenhos.push(empenho.numero)
    }
  }
}
