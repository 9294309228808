import { BaseResourceModel } from "../../models/base-resource.model";
import { Exercicio } from "../comum/exercicio.model";
import { Orgao } from "../comum/orgao.model";
import { Receita } from "../planejamento/receita.model";

export class ClassificacaoReceitasSiops extends BaseResourceModel {
    constructor(
        public classificacao_siops?: string,
        public receita?: Receita,
        public orgao?: Orgao,
        public ativo?: boolean,
        public exercicio?: Exercicio,
        public descricao_receita_siops?: string
      ) {
        super();
      }
    
      static converteJson(json: any): ClassificacaoReceitasSiops {
        return Object.assign(new ClassificacaoReceitasSiops(), json);
      }
}