import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseResourceService, Executora, FichaReceita, Unidade } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class AnexoLoaService extends BaseResourceService<FichaReceita> {


  constructor(
    protected injector: Injector
  ) {
    super(`anexo-loa`, injector);
  }

  obterAnexo1(exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo-1/${exercicio}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterSumario(exercicio: number, orgaos: number[]): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/sumario-geral/${exercicio}/${orgaos.join()}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterPrevisaoReceita(exercicio: number, orgaos: number[], mesInicial: string, mesFinal: string, tipoPrevisao: string, unidadeId: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo-previsao-receita/${exercicio}/${orgaos.join()}/${mesInicial}/${mesFinal}/${tipoPrevisao}/${unidadeId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterPrevisaoDespesa(exercicio: number, orgaos: number[], quadrimestre: number, tipo_previsao: number, mes_inicial: number, mes_final: number, tribunal_executora: String, tribunal_unidade: String): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo-previsao-despesa/${exercicio}/${orgaos.join()}/${quadrimestre}/${tipo_previsao}/${mes_inicial}/${mes_final}/${tribunal_executora}/${tribunal_unidade}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo2Despesa(exercicio: number, orgaos: number[], ppa: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo-2-despesa/${exercicio}/${orgaos.join()}/${ppa}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo2Executora(exercicio: number, orgaos: number[], ppa: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo-2-executora/${exercicio}/${orgaos.join()}/${ppa}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexoUnidadeAtuacao(exercicio: number, orgaos: number[], ppa: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo-unidade-atuacao/${exercicio}/${orgaos.join()}/${ppa}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo2Ce(exercicio: number, orgaos: number[], ppa: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo-2-categoria-economica/${exercicio}/${orgaos.join()}/${ppa}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo2Receita(exercicio: number, orgaos: number[], ppa: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo-2-receita/${exercicio}/${orgaos.join()}/${ppa}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterReceitaRecurso(exercicio: number, orgaos: number[], ppa: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/receita-recurso/${exercicio}/${orgaos.join()}/${ppa}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo6(exercicio: number, orgaos: number[], ppa: number, executora: boolean): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo-6/${exercicio}/${orgaos.join()}/${ppa}/${executora}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo6Orgao(exercicio: number, orgaos: number[], ppa: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo-6-orgao/${exercicio}/${orgaos.join()}/${ppa}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo7(exercicio: number, orgaos: number[], ppa: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo-7/${exercicio}/${orgaos.join()}/${ppa}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo8(exercicio: number, orgaos: number[], ppa: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo-8/${exercicio}/${orgaos.join()}/${ppa}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterAnexo9(exercicio: number, orgaos: number[], ppa: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/anexo-9/${exercicio}/${orgaos.join()}/${ppa}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterQdd(exercicio: number, orgaos: number[], parametros: {}): Observable<any> {
    const rota = `${this.login.cidade.id}/${this.api}/qdd/${exercicio}/${orgaos.join()}/${parametros['unidade']}/${parametros['trimestre']}/${parametros['quadrimestre']}/${parametros['data_inicial']}/${parametros['data_final']}/${parametros['tipo_valor']}`;
    return this.http.get<any>(rota,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterQddOrcamento(exercicio: number, orgaos: number[], parametros: {}): Observable<any> {
    const rota = `${this.login.cidade.id}/${this.api}/qdd-orcamento/${exercicio}/${orgaos.join()}/${parametros['unidade']}/${parametros['trimestre']}/${parametros['quadrimestre']}/${parametros['data_inicial']}/${parametros['data_final']}/${parametros['tipo_valor']}`;
    return this.http.get<any>(rota,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obteraplicacaoVinculada(exercicio: number, orgaos: number[], recursoReceita?: number, recursoDespesa?: number) {
    const rota = `${this.login.cidade.id}/${this.api}/aplicacao-vinculada/${exercicio}/${orgaos.join()}/${recursoReceita}/${recursoDespesa}`
    return this.http.get<any>(rota, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    )
  }
}
