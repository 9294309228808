import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LicitacaoService } from 'administrativo-lib';
import { Coluna, FuncaoService, GlobalService, Login, Modalidade, Relatorio } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalidadeService } from '../../modalidade/service/modalidade.service';

@Component({
  selector: 'app-licitacao-rpt',
  templateUrl: './licitacao-rpt.component.html'
})
export class LicitacaoRptComponent implements OnInit, OnDestroy {

  protected datepipe: DatePipe;
  login: Login = new Login();
  modalidade: number;
  situacao: string;
  objeto: string;
  filtroModalidade: boolean;
  filtroSituacao: boolean;
  filtroValor: boolean;
  filtroObjeto: boolean;
  public selectedOrdem: string;

  public ptBR: any;

  public listaModalidade: Array<any>;
  public listaSituacao: Array<any>;
  public dataInicial: string;
  public dataFinal: string;
  public valorInicial: number;
  public valorFinal: number;
  protected unsubscribe: Subject<void> = new Subject();

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    protected messageService: MessageService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected modalidadeService: ModalidadeService,
    protected licitacaoService: LicitacaoService) {
  }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.login = GlobalService.obterSessaoLogin();
    this.selectedOrdem = 'ord1';

    this.modalidadeService.filtrar(1, -1, { 'cidade.id': this.login.cidade.id, orderBy: 'codigo' }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaModalidade = res ? res.content : new Array<Modalidade>();
      }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));

    this.listaSituacao = [
      { nome: 'ABERTA', id: '1' },
      { nome: 'DESERTA', id: '2' },
      { nome: 'FRACASSADA', id: '3' },
      { nome: 'SUSPENSA', id: '4' },
      { nome: 'REVOGADA', id: '5' },
      { nome: 'ANULADA', id: '6' },
      { nome: 'HOMOLOGADA', id: '7' }
    ];
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public gerarRelatorio() {
    const parametros = {};
    if ((!this.dataInicial || !this.dataFinal) || (this.dataInicial === '' || this.dataFinal === '')) {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }

    parametros['data_abertura$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
    parametros['data_abertura$le'] = this.funcaoService.converteDataSQL(this.dataFinal);

    if (this.filtroValor) {
      parametros['valor_estimado$ge'] = this.funcaoService.converteValorSQL(this.valorInicial);
      parametros['valor_estimado$le'] = this.funcaoService.converteValorSQL(this.valorFinal);
    }

    if (this.filtroModalidade) {
      parametros['modalidade.id'] = this.modalidade;
    }

    if (this.filtroSituacao) {
      parametros['situacao'] = this.situacao;
    }

    if (this.filtroObjeto) {
      parametros['objeto$like'] = `%${this.objeto}%`;
    }

    if (this.selectedOrdem === 'ord1') {
      parametros['orderBy'] = 'numero';
    } else if (this.selectedOrdem === 'ord2') {
      parametros['orderBy'] = 'modalidade.nome';
    } else if (this.selectedOrdem === 'ord3') {
      parametros['orderBy'] = 'data_abertura';
    } else if (this.selectedOrdem === 'ord4') {
      parametros['orderBy'] = 'valor_estimado';
    }
    parametros['relations'] = 'modalidade';
    parametros['orgao_id'] = this.login.orgao.id;

    this.licitacaoService.filtrar(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((lista) => {
        this.imprimir(`LISTAGEM NO PERÍODO DE ${this.dataInicial} à ${this.dataFinal} `,
          this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
          'Listagem de licitações', ['auto', 'auto', 'auto', 'auto', '*', 'auto'], lista.content);
      });
  }

  private imprimir(
    titulo: string, usuario: string, sobrenome: string, orgao: string,
    brasao: string, orientacao: 'landscape' | 'portrait', nomepdf?: string, largura?: string[], lista?: any[]) {
    Relatorio.imprimir(titulo, usuario, sobrenome, orgao, brasao, lista, this.colunas(), orientacao, nomepdf, largura, ['valor_estimado']);
  }

  colunas(): string[] | Coluna[] {
    return [
      { titulo: 'Número', coluna: 'numero', bold: true },
      { titulo: 'Modalidade', coluna: 'modalidade.nome', converter: this.listaModalidade },
      { titulo: 'Data de Abertura', coluna: 'data_abertura' },
      { titulo: 'Situação', coluna: 'situacao' },
      { titulo: 'Objeto', coluna: 'objeto' },
      { titulo: 'Valor Estimado', coluna: 'valor_estimado', decimais: 2, alignment: 'right' }
    ];
  }

}
