import { Component, Injector, OnInit } from "@angular/core";
import {
  BaseResourceListComponent,
  Coluna,
  Filtro,
  FormatoExportacao,
  FuncaoService,
  GlobalService,
  LoginContabil,
  Rcms,
} from "eddydata-lib";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RcmsService } from "../service/rcms.service";
import * as toastr from "toastr";

declare var $: any;

@Component({
  selector: "app-rcms-autorizacao-list",
  templateUrl: "./rcms-autorizacao-list.component.html",
  styleUrls: ["./rcms-autorizacao-list.component.css"],
})
export class RcmsAutorizacaoListComponent
  extends BaseResourceListComponent<Rcms, LoginContabil>
  implements OnInit {
  /**
   * Declaração de variáveis
   */
  public ptBR: any;

  public rcms: Rcms = new Rcms();
  public visualizar: boolean = false;

  public mes: string;

  public visualizarId: number;

  public selecionados: Rcms[] = [];
  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public funcaoService: FuncaoService,
    public globalService: GlobalService,
    private rcmsService: RcmsService
  ) {
    super(rcmsService, injector);
    this.usarExtendido = true;
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return [
      "setor",
      "licitacao",
      "contrato",
      "contrato_aditamento",
      "exercicio",
      "orgao",
      "ficha",
      "ficha.despesa",
      "subelemento",
      "requerente",
      "modalidade",
      "prazo",
    ].join(",");
  }

  protected condicoesGrid(): {} {
    return {
      ["orgao.id"]: this.login.orgao.id,
      ["exercicio.id"]: this.login.exercicio.id,
      ['count_compra']: 0,
      ["excluido"]: false,
      ["autorizado_compra"]: true,
      ['itens.cotacoes.id$not_null']: '',
      ["fields"]: "id,excluido,autorizado,bloqueado,numero,data_rcms,exercicio.ano,ficha.despesa.codigo,ficha.despesa.nome,setor.nome,requerente.nome,numero_lote,contrato_aditamento.data_inicio",
      ["onlyFields"]: "true",
    };
  }

  protected ordenacaoGrid(): string[] {
    return ["numero$DESC"];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ["numero", "ficha.despesa.codigo"],
      date: ["data_rcms"],
      text: [
        "requerente.nome",
        "requerente",
        "ficha.despesa.nome",
        "contrato.numero",
        "processo",
        "licitacao.numero",
        "modalidade.nome",
        "setor.nome",
      ],
    };
  }

  public beforeInit(): void {
    this.usarFiltroPersonalizado = true;
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  protected afterInit(): void {
    this.ptBR = new GlobalService().obterDataBR();
  }

  protected acaoRemover(model: Rcms): Observable<Rcms> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: "Número", coluna: "numero" },
      { titulo: "Data", coluna: "data_rcms" },
      { titulo: "Despesa", coluna: "ficha.despesa.nome" },
      { titulo: "Setor", coluna: "setor.nome" },
      { titulo: "Requerente", coluna: "requerente.nome" },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros["relations"] = this.relations();
    parametros["orderBy"] = this.ordenacaoGrid().join(",");
    this.rcmsService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === "pdf") {
            this.imprimir(
              "LISTAGEM DE REQUISIÇÕES",
              this.login.usuario.nome,
              this.login.usuario.sobrenome,
              this.login.orgao.nome,
              this.login.brasao,
              "portrait",
              "Listagem compras",
              ["auto", "*", "*", "auto", "auto"],
              lista.content
            );
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert("erro ao retornar lista")
      );
  }

  public excluir(rcms: Rcms) {
    this.rcms = rcms;
    this.visualizar = true;
  }

  public autorizarRcms() {

    let autorizadosId = []
    let autorizadosBoolean = []

    autorizadosId = this.lista.map(i => i.id)
    autorizadosBoolean = this.lista.map(i => i.autorizado)

    this.rcmsService.autorizarRcmsLote(autorizadosId, autorizadosBoolean)
      .subscribe(data => {
        toastr.success('Processamento de autorizações de requisições concluídas')
        this.preencherGrid()

      }, error => this.funcaoService.acaoErro(error))
  }

  public disabled() {
    return this.lista.filter((i) => i["selecionado"]).length === 0;
  }

  public obterColunasFiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];
    // retorno.push({ titulo: 'Nº OF', coluna: 'compras.numero', alignment: 'center', tipo: 'Number' });
    retorno.push({
      titulo: "Data Requisição",
      coluna: "data_rcms",
      tipo: "Date",
      alignment: "center",
      padrao: true,
      cols: 3,
      filtro2: new Date(),
    });
    retorno.push({
      titulo: "Nº Requisição",
      coluna: "numero",
      alignment: "center",
      tipo: "Number",
      cols: 3,
      padrao: true,
    });
    retorno.push({
      titulo: " Nº Lote",
      coluna: "numero_lote",
      tipo: "Number",
      cols: 3,
      padrao: true,
    });
    retorno.push({
      titulo: "Requerente",
      coluna: "requerente.nome",
      tipo: "String",
      cols: 3,
      padrao: true,
    });
    retorno.push({
      titulo: "Modalidade",
      coluna: "modalidade.nome",
      tipo: "String",
    });
    retorno.push({
      titulo: "Ficha",
      coluna: "ficha.numero",
      alignment: "center",
      tipo: "Number",
      cols: 2,
    });
    retorno.push({
      titulo: "Fornecedor",
      coluna: "favorecidos.favorecido.nome",
      tipo: "String",
      cols: 4,
    });
    retorno.push({
      titulo: "Contrato",
      coluna: "contrato.numero",
      alignment: "center",
      tipo: "String",
      cols: 2,
      mask: [{ mask: "0000/0000" }, { mask: "00000/0000" }],
    });
    retorno.push({
      titulo: "Licitação",
      coluna: "licitacao.numero",
      alignment: "center",
      tipo: "String",
      cols: 2,
      mask: "00000/0000",
    });
    retorno.push({
      titulo: "Processo",
      coluna: "processo",
      alignment: "center",
      tipo: "String",
      cols: 2,
      mask: [{ mask: "0000/0000" }, { mask: "00000/0000" }],
      notUnmask: true,
    });
    retorno.push({
      titulo: "Usuário",
      coluna: "operador.nome",
      alignment: "center",
      tipo: "String",
      cols: 4,
    });
    retorno.push({
      titulo: "Descrição Item Requisição",
      coluna: "itens.produto_unidade.produto.nome",
      tipo: "String",
    });
    retorno.push({
      titulo: "Código Item Requisição",
      coluna: "itens.produto_unidade.produto.codigo",
      tipo: "String",
    });

    retorno.push({ titulo: 'Autorizado', coluna: 'autorizado', tipo: 'Selection', opcoesSelecao: [{ valor: 'true', label: 'Sim' }, { valor: 'false', label: 'Não' }], cols: 2 });

    return retorno;
  }

  public onAutorizado() {
    if (this.visualizarId)
      this.lista = this.lista.filter((x) => x.id !== this.visualizarId);
  }

  public onDesbloquear() {
    if (this.visualizarId)
      this.lista.forEach((x) => {
        if (x.id === this.visualizarId) x.bloqueado = false;
      });
  }

  public validSelect(rcms: Rcms) {
    const sel = this.selecionados.find((s) => s.id === rcms.id);
    return !(!sel)
  }

  public selecionar(rcms: Rcms, select: boolean) {
    if (select) {
      this.selecionados.push(rcms);
      this.selecionados.sort((a, b) => +a.numero - +b.numero);
    }
    else
      this.selecionados = this.selecionados.filter((s) => s.id !== rcms.id);
  }

  public todosSelecionados(): boolean {
    const listaSemAutorizados = this.lista.filter((rcms) => {
      if (rcms.autorizado && !rcms.numero_lote)
        return true;
    })
    return !listaSemAutorizados ? true : listaSemAutorizados.filter((l) => this.validSelect(l)).length === listaSemAutorizados.length;
  }

  public selecionarTodos() {
    let todosSel = this.todosSelecionados();
    let lista: any[] = [];
    if (this.lista) {
      this.lista.forEach((l) => {
        if (!todosSel && !this.validSelect(l)) {
          if (l.autorizado && !l.numero_lote) {
            this.selecionar(l, true);
            lista.push(l);
          }
        } else if (todosSel && this.validSelect(l))
          if (l.autorizado && !l.numero_lote)
            this.selecionar(l, false);
      });
    }
  }

  public gerarLote() {
    this.autorizarRcms()

    this.rcmsService.gerarLote(this.selecionados, this.login.exercicio.id, this.login.orgao.id)
      .subscribe(numero => {
        toastr.success(`Lote número ${numero} gerado com sucesso!`)
        this.preencherGrid()
        this.selecionados = []
      }, error => this.funcaoService.acaoErro(error))
  }

  public mostrarModalGerarLote() {
    if (this.selecionados.length === 0)
      return toastr.warning(`Nenhuma requisição foi selecionada para geração do lote!`);

    $('#dialogGerarLoteRcms').modal('show');
  }

  public validaAditivoVigente(rcms: Rcms) {
    if (rcms?.['contrato_aditamento.data_inicio']) {
      const dataHoje = new Date()
      const DataInicioAditamento = new Date(rcms['contrato_aditamento.data_inicio'])

      return dataHoje < DataInicioAditamento

    } else {
      return false
    }
  }
}
