import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Login, Proposta} from 'eddydata-lib';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'app-motivo-desclassificado-julgamento-dlg',
  templateUrl: './motivo-proposta-desclassificado-dlg.component.html'
})
export class MotivoPropostaDesclassificadoDlg implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  protected unsubscribe: Subject<void> = new Subject();

  @Input() visualizar: boolean = false;
  @Input() apenasVisualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();
  @Output() apenasVisualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Input() login: Login;
  @Input() proposta: Proposta;

  @Input() usarCallback: boolean = false;
  @Output() callback: EventEmitter<Proposta> = new EventEmitter();

  private motivo: string = '';

  constructor(
    private router: Router
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
  }

  public desclassificar() {
    if (!this.proposta)
      return toastr.error(`Informe a proposta!`)

    if (this.motivo.length <= 0 )
      return toastr.error(`Preencha o motivo da desclassificação da proposta!`)
    this.proposta.motivoDesclassificacao = this.motivo;
    this.callback.emit(this.proposta);
    this.onHide();
  }

  public onHide() {
    this.motivo = '';
    this.visualizar = false;
    this.apenasVisualizar = false;
    this.visualizarChange.emit(this.visualizar);
    this.apenasVisualizarChange.emit(this.apenasVisualizar);
  }

}
