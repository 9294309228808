import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LicitacaoService, MemorialService } from 'administrativo-lib';
import { Assinatura, AssinaturaService, FormatoExportacao, GlobalService, Licitacao, LicitacaoPipe, Login, Memorial, Rcms, RcmsFavorecido, Rpl, RplFavorecido } from 'eddydata-lib';
import * as toastr from 'toastr';
import { EstimativaItemRpt, EstimativaRpt } from './base-estimativa-preco-rpt';
import { EstimativaPrecoExportarRpt } from './estimativa-preco-exportar-rpt';
import { EstimativaPrecoRpt } from './estimativa-preco-rpt';


@Component({
  selector: 'app-estimativa-preco-rpt',
  templateUrl: './estimativa-preco-rpt.component.html'
})
export class EstimativaPrecoRptComponent implements OnInit {

  @Input() public licitacao: Licitacao;

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public listFormato = ['pdf', 'xlsx', 'docx', 'xml', 'csv', 'JSON'];
  public formato: FormatoExportacao = 'pdf';

  public comAssinatura: boolean = false;
  public assinatura: Assinatura;
  public listAssinatura: Assinatura[];

  public listaFavorecidos: RplFavorecido[] = [];
  public listaFavorecidosRcms: RcmsFavorecido[] = [];

  private login: Login;
  private dados: Licitacao;

  constructor(
    private assinaturaService: AssinaturaService,
    private licitacaoService: LicitacaoService,
    private memorialService: MemorialService
  ) {
    this.login = GlobalService.obterSessaoLogin();
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  ngOnInit(): void {
    this.carregarAssinaturas();
  }

  public tituloRelatorio() {
    return `ESTIMATIVA DE PREÇOS - ${new LicitacaoPipe().transform(this.licitacao.numero)}`;
  }

  get selecionados(): RplFavorecido[] {
    return this.listaFavorecidos
      .filter((s) => s['selecionado']);
  }

  get selecionadosRcms(): RcmsFavorecido[] {
    return this.listaFavorecidosRcms
      .filter((s) => s['selecionado']);
  }


  public imprimir() {
    if (!this.validar())
      return;
    if (this.formato === 'pdf') {
      this.carregarDados().then((dados) => {
        if (!this.validarPdf())
          return;

        const estimativa = this.estimativa(dados, true);

        new EstimativaPrecoRpt().imprimir(estimativa, this.comAssinatura, this.assinatura, this.tituloRelatorio(), this.formato);
      });
    } else {
      new EstimativaPrecoExportarRpt(this.memorialService).exportar(this.licitacao, this.tituloRelatorio(), this.formato, 'landscape');
    }
  }

  ///METODO FOI CRIADO PARA POSIBILITAR A SELEÇÃO DE FAVORECIDOS, MAS O MESMO NÃO FOI UTILIZADO PARA ESSE PROPOSITO.
  private carregarDados(): Promise<Licitacao> {
    const parametros = {
      'id': this.licitacao.id,
      'orgao.id': this.licitacao.orgao.id,
      'exercicio_id': this.licitacao.exercicio.id,
      relations: ['setor', 'setor.unidade', 'exercicio', 'itens', 'itens.produto_unidade',
        'itens.produto_unidade.produto', 'itens.produto_unidade.unidade',
        'itens.rpl_itens', 'itens.rpl_itens.rpl', 'itens.rpl_itens.rpl.exercicio',
        'itens.rpl_itens.rpl.orgao', 'itens.rpl_itens.cotacoes', 'itens.rpl_itens.cotacoes.favorecido',
        'itens.rpl_itens.cotacoes.favorecido.favorecido', 'itens.itens.rpl_itens.produto_unidade.produto',
        'itens.itens.rpl_itens.produto_unidade.unidade', 'itens.itens.rpl_itens.cotacoes.favorecido.favorecido',
        'itens.itens.produto', 'itens.rcms_itens', 'itens.rcms_itens.cotacoes.rcmsFavorecido.favorecido',
        'itens.rcms_itens.rcms', 'itens.rcms_itens.rcms.exercicio'].join(','),
      orderBy: ['itens.ordem'].join(',')
    };

    return new Promise((resolve, error) => {
      this.licitacaoService.filtrar(1, -1, parametros)
        .subscribe((page) => {
          this.dados = page.content[0];
          this.listaFavorecidosRcms = this.favorecidos(this.dados.itens)
            .map((l) => {
              l['selecionado'] = true;
              return l;
            });
          this.listaFavorecidos = this.favorecidos(this.dados.itens)
            .map((l) => {
              l['selecionado'] = true;
              return l;
            });
          resolve(this.dados);
        });
    });
  }

  private carregarAssinaturas() {
    this.assinaturaService.filtrar(1, -1, { 'orgao_id': this.login.orgao.id, ativo: true, sistema: this.login.sistema, relations: 'pessoas.pessoa', orderBy: 'nome' })
      .subscribe((res) => {
        this.listAssinatura = res ? res.content : [];
      }, error => toastr.error(error.message ? error.message : error));
  }

  private estimativa(licitacao: Licitacao, selecinar?: boolean): EstimativaRpt {
    return {
      favorecidos: this.favorecidos(licitacao.itens, selecinar),
      itens: this.itens(licitacao.itens, selecinar),
      rpls: this.rpls(licitacao.itens),
      rcms: this.rcms(licitacao.itens),
      licitacao: licitacao
    }
  }

  private itens(itens: Memorial[], selecinar?: boolean): EstimativaItemRpt[] {
    const itensE: EstimativaItemRpt[] = [];

    for (const item of itens) {
      const itemLote = [];
      if (item.lote && item.itens?.length > 0) {
        for (const itemL of item.itens) {
          let loteItem = {
            ordem: '-',
            produto_unidade: itemL.rpl_itens.find(item => { if (item.produto_unidade?.produto?.id === itemL.produto?.id) return item.produto_unidade }),
            quantidade_memorial: +itemL.quantidade,
            quantidade: itemL.rpl_itens.reduce((sum, i) => sum + +i.quantidade, 0),
            quantidadeRcms: itemL.rcms_itens.reduce((sum, i) => sum + +i.quantidade, 0),
            valor_referencia: 0,
            lote: false,
            itens: null,
            cotacoes: [],
            cotacoesRcms: []
          }

          for (const rItem of itemL.rpl_itens) {
            let cotacoes = rItem.cotacoes;
            if (selecinar)
              cotacoes = cotacoes
                .filter((c) => this.selecionados
                  .filter((s) => s.favorecido.id === c.favorecido.favorecido.id).length > 0);

            loteItem.cotacoes = loteItem.cotacoes.concat(cotacoes);
          }
          itemLote.push(loteItem);
        }
      }

      const itemE = {
        ordem: item.ordem,
        produto_unidade: item.produto_unidade,
        quantidade_memorial: +item.quantidade,
        quantidade: item.rpl_itens.reduce((sum, i) => sum + +i.quantidade, 0),
        quantidadeRcms: item.rcms_itens.reduce((sum, i) => sum + +i.quantidade, 0),
        valor_referencia: item.valor_referencia,
        lote: item.lote,
        itens: itemLote,
        cotacoes: [],
        cotacoesRcms: []
      };

      for (const rItem of item.rpl_itens) {
        let cotacoes = rItem.cotacoes;
        if (selecinar)
          cotacoes = cotacoes
            .filter((c) => this.selecionados
              .filter((s) => s.favorecido.id === c.favorecido.favorecido.id).length > 0);

        itemE.cotacoes = itemE.cotacoes.concat(cotacoes);
      }

      for (const rItem of item.rcms_itens) {
        let cotacoes = rItem.cotacoes;
        if (selecinar)
          cotacoes = cotacoes
            .filter((c) => this.selecionadosRcms
              .filter((s) => s.favorecido.id === c.rcmsFavorecido.favorecido.id).length > 0);

        itemE.cotacoesRcms = itemE.cotacoesRcms.concat(cotacoes);
      }

      const validar = itensE.find((i) => i.produto_unidade.id === item.produto_unidade.id);
      if (validar) {
        validar.quantidade += +itemE.quantidade;
        validar.quantidade_memorial += +itemE.quantidade_memorial;
        validar.cotacoes = validar
          .cotacoes.concat(itemE.cotacoes)
        validar.cotacoesRcms = validar
          .cotacoesRcms.concat(itemE.cotacoesRcms)

      } else {
        itensE.push(itemE);
      }
    }
    return itensE;
  }

  private favorecidos(itens: Memorial[], selecinar?: boolean): RplFavorecido[] {
    const favorecidos: RplFavorecido[] = [];

    for (const item of itens) {
      for (const rItem of item.rpl_itens) {
        for (const cotacao of rItem.cotacoes) {
          if (selecinar && this.selecionados.filter((f) => f.favorecido.id === cotacao.favorecido.favorecido.id).length === 0)
            continue;
          const validar = favorecidos.find((c) => c.favorecido.id === cotacao.favorecido.favorecido.id);
          if (!validar)
            favorecidos.push(cotacao.favorecido);
        }
      }
    }

    for (const item of itens) {
      for (const rItem of item.rcms_itens) {
        for (const cotacao of rItem.cotacoes) {
          if (selecinar && this.selecionados.filter((f) => f.favorecido.id === cotacao.rcmsFavorecido.favorecido.id).length === 0)
            continue;
          const validar = favorecidos.find((c) => c.favorecido.id === cotacao.rcmsFavorecido.favorecido.id);
          if (!validar)
            favorecidos.push(cotacao.rcmsFavorecido);
        }
      }
    }

    return favorecidos;
  }

  private rpls(itens: Memorial[]): Rpl[] {
    const rpls: Rpl[] = [];

    for (const item of itens) {
      for (const rItem of item.rpl_itens) {
        const validar = rpls.find((r) => r.id === rItem.rpl.id);
        if (!validar)
          rpls.push(rItem.rpl);
      }
    }

    return rpls;
  }

  private rcms(itens: Memorial[]): Rcms[] {
    const rcms: Rcms[] = [];

    for (const item of itens) {
      for (const rItem of item.rcms_itens) {
        const validar = rcms.find((r) => r.id === rItem.rcms.id);
        if (!validar)
          rcms.push(rItem.rcms);
      }
    }

    return rcms;
  }

  public validarPdf(): boolean {
    if (this.selecionadosRcms.length === 0 && this.selecionados.length === 0 && this.formato === 'pdf') {
      toastr.warning(`Não foi encontrado fornecedores!`);
      return false;
    }
    return true;
  }

  public validar(): boolean {
    if (this.comAssinatura) {
      if (!this.assinatura?.pessoas) {
        toastr.warning(`Selecione a assinatura desejada!`);
        return false;
      }

      let assinaturas = this.assinatura?.pessoas?.filter(p => !p.selecionado)
      if (assinaturas.length !== 0) {
        toastr.warning(`Selecione a assinatura desejada!`);
        return false;
      }
    }

    return true;
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
    // this.carregarDados();
  }
}
