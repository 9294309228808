import { DatePipe } from '@angular/common';
import { Injector } from '@angular/core';
import { RetencaoExtraService } from 'contabil-lib';
import { FuncaoService, GlobalService, EmpenhoExtra, Login, Relatorio } from 'eddydata-lib';
import { Responsavel } from '../../relatorio/util';

export class GrdEmpenhoExtra {

  protected datepipe: DatePipe;
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  protected retencaoExtraService: RetencaoExtraService;

  prefeito: Responsavel;
  contador: Responsavel;
  tesoureiro: Responsavel;
  private total_retido: number = 0;

  private log: Login;

  constructor(protected injector: Injector) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.retencaoExtraService = new RetencaoExtraService(injector);
    this.datepipe = new DatePipe('pt');
  }

  public async imprimir(model: EmpenhoExtra[], login: Login) {
    this.log = login;

    Relatorio.imprimirPersonalizado('GRD', login.usuario.nome, login.usuario.sobrenome,
      login.orgao.nome, login.brasao,
      await this.montarConteudo(model),
      'portrait', 'GRD - Guia Recolhimento Diverso Extra-Orçamentário',
      {
        linhas: {
          hLineWidth(i, node) {
            return 1;
          },
          vLineWidth(i) {
            return 1;
          },
          hLineColor(i) {
            return 'black';
          },
          paddingLeft(i) {
            return 3;
          },
          paddingRight(i, node) {
            return 3;
          }
        }
      }, true);
  }

  private async montarConteudo(lista: EmpenhoExtra[]) {

    const conteudo = [];
    for (const entidade of lista) {
      
      const retencoes = await this.retencaoExtraService.filtrar(0, -1, {  empenho_id: entidade.id, relations: 'ficha' }).toPromise();
      
      if (retencoes.content.length) {

        if (conteudo.length > 0) {
          conteudo.push([{ text: '', pageBreak: 'after' }]);
        }
        conteudo.push(this.dadosCabecalho(this.log, entidade));
        conteudo.push(this.dadosGRD(entidade, retencoes));
        conteudo.push(this.dadosRodape(entidade));

        conteudo.push(this.dadosCabecalho(this.log, entidade));
        conteudo.push(this.dadosGRD(entidade, retencoes));
        conteudo.push(this.dadosRodape(entidade));
      }
    }
    return conteudo;
  }

  private dadosCabecalho(log: Login, dados: EmpenhoExtra): {} {
    let brasaoImage: {};
    if (log.brasao) {
      brasaoImage = {
        image: log.brasao,
        width: 60,
        alignment: 'center',
        border: [true, true, false, false]
      };
    } else {
      brasaoImage = { margin: [0, 10, 0, 45], text: '', border: [true, true, false, false], alignment: 'center' };
    }

    return [{
      columns: [
        {
          width: 80,
          table: {
            widths: ['*'],
            body: [
              [brasaoImage]
            ]
          }
        },
        {
          width: '*',
          table: {
            widths: ['*'],
            body: [
              [{ text: log.orgao.nome, bold: true, alignment: 'center', fontSize: 13, border: [false, true, false, false] }],
              [{ text: `ESTADO ${log.cidade.estado.nome.toLocaleUpperCase()}`, bold: true, alignment: 'center', fontSize: 12, border: [false, false, false, false] }],
              [{ text: 'GRD - Guia Recolhimento Diverso Extra', bold: true, alignment: 'center', fontSize: 11, border: [false, false, false, false] }]
            ]
          }
        },
        {
          width: 100,
          table: {
            widths: ['*'],
            body: [
              [{ text: 'RECIBO N˚', bold: true, alignment: 'center', fontSize: 12, border: [true, true, true, false] }],
              [{ text: this.funcaoService.strZero(dados.id, 5), bold: true, alignment: 'center', fontSize: 13, border: [true, false, true, false], margin: [0, 0, 0, 22] }]
            ]
          }

        }
      ]
    }];
  }

  private dadosGRD(dados: any, retencoes: any): {}[] {
    const conteudoExtra = this.obterRetencoes(retencoes);
    return [{
      columns: [
        {
          width: '*',
          border: [true, false, true, false],
          table: {
            widths: ['*'],
            body: [
              [{ text: 'DADOS DO CONTRIBUINTE', bold: true, alignment: 'center', fontSize: 8, border: [true, true, true, true] }],
              [{ text: `CPF/CNPJ:\t${dados.favorecido.cpf_cnpj}`, alignment: 'left', fontSize: 7, border: [true, false, true, false] }],
              [{ text: `NOME:\t${dados.favorecido.nome}`, bold: true, alignment: 'left', fontSize: 7, border: [true, false, true, false] }],
              [{ text: `ENDEREÇO:\t${dados.favorecido.endereco}`, alignment: 'left', fontSize: 7, border: [true, false, true, false] }],
              [{ text: `BAIRRO:\t${dados.favorecido.bairro}`, alignment: 'left', fontSize: 7, border: [true, false, true, false] }],
              [{ text: `CIDADE:\t${dados.favorecido.municipio}`, alignment: 'left', fontSize: 7, border: [true, false, true, false] }],
              [{ text: `SETOR:\t ${dados.unidade_executora ? dados.unidade_executora : ''}`, alignment: 'left', fontSize: 7, border: [true, false, true, false] }],
              [{ text: `EMPENHO:\t ${dados.numero} ${dados.parcela ? `PARCELA ${dados.parcela}` : ''} `, alignment: 'left', fontSize: 7, border: [true, false, true, false] }],
              [{ text: `DOCUMENTO:\t ${dados.documento ? dados.documento : ''}`, alignment: 'left', fontSize: 7, border: [true, false, true, false] }],
              [{ text: `VALOR:\t ${this.funcaoService.convertToBrNumber(dados.valor_empenho)}`, alignment: 'left', fontSize: 7, border: [true, false, true, false] }],
              [{ text: `VALOR LÍQUIDO:\t ${this.funcaoService.convertToBrNumber(+dados.valor_empenho - +this.total_retido)}`, alignment: 'left', fontSize: 7, border: [true, false, true, false] }],
            ]
          }
        },
        {
          width: '*',
          table: {
            widths: ['*'],
            body: [
              [{ text: 'NATUREZA', bold: true, alignment: 'center', fontSize: 8, border: [false, true, true, true] }],
              [{
                border: [false, false, true, false],
                table: {
                  widths: [25, '*'],
                  body: conteudoExtra
                }
              }]
            ]
          }
        }
      ]
    }];
  }

  private dadosRodape(dados: any): {}[] {
    return [{
      columns: [
        {
          width: 300,
          margin: [0, 0, 0, 10],
          table: {
            widths: ['*'],
            body: [
              [{ text: 'O RECIBO É VÁLIDO SOMENTE SEM EMENDAS, RASURAS OU RESSALVAS E SE AUTENTICADO POR MÁQUINA', bold: true, alignment: 'left', fontSize: 7, border: [true, true, true, false] }],
              [{
                text: 'O CONTRIBUINTE SUPRA RECOLHE AOS COFRES MUNICIPAIS A IMPORTÂNCIA NESTE DECLARADA E CORRESPONDENTE AO IMPOSTO OU TAXA MENCIONADA',
                alignment: 'left', fontSize: 8, border: [true, false, true, true], margin: [0, 0, 0, 19]
              }],
            ]
          }
        },
        {
          width: 90,
          table: {
            widths: ['*'],
            body: [
              [{ text: 'DATA', bold: true, alignment: 'center', fontSize: 11, border: [false, true, true, false] }],
              [{ text: '', alignment: 'center', fontSize: 12, border: [false, false, true, false] }],
              [{ text: this.funcaoService.converteDataBR(dados.data_empenho), bold: true, alignment: 'center', fontSize: 11, border: [false, false, true, true], margin: [0, 0, 0, 23.5] }],
            ]
          }
        },
        {
          width: '*',
          table: {
            widths: [60, '*'],
            body: [
              [{ text: 'VALOR MULTA', alignment: 'left', fontSize: 8, border: [false, true, true, true] }, { text: '', alignment: 'right', fontSize: 8, border: [false, true, true, true] }],
              [{ text: 'VALOR JUROS', alignment: 'left', fontSize: 8, border: [false, true, true, true] }, { text: '', alignment: 'right', fontSize: 8, border: [false, true, true, true] }],
              [
                { text: 'VALOR TOTAL', bold: true, alignment: 'left', fontSize: 9, border: [false, true, true, true], margin: [0, 10, 0, 10] },
                { text: this.funcaoService.convertToBrNumber(+this.total_retido), alignment: 'right', fontSize: 9, border: [false, true, true, true], margin: [0, 10, 0, 10], }
              ],
            ]
          }
        }
      ]
    }];
  }

  private obterRetencoes(lista: any) {
    const conteudo = [];
    let linha = 0;
    this.total_retido = 0;
    for (const item of lista.content) {
      conteudo.push(
        [
          { text: `FH.${item.ficha.numero}`, alignment: 'left', fontSize: 7, border: [false, false, false, false], margin: [0, 2, 0, 2] },
          { text: `${item.ficha.nome} - ${this.funcaoService.convertToBrNumber(item.valor_retido)}`, alignment: 'left', fontSize: 7, border: [false, false, false, false], margin: [0, 1, 0, 1] }
        ]
      );
      linha = linha + 2;
      this.total_retido += +item.valor_retido;
    }
    for (let index = 0; index < (15 - linha); index++) {
      conteudo.push(
        [
          { text: '', fontSize: 7, border: [false, false, false, false] },
          { text: '', fontSize: 7, border: [false, false, false, false], margin: [0, 2, 0, 2] }
        ]
      );
    }
    return conteudo;
  }
}
