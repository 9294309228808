import { BaseResourceStorage } from '../../models/base-resource.storage';
import { Contrato } from './contrato.model';

export class ContratoStorage extends BaseResourceStorage {
  constructor(
    public visivel_transparencia?: boolean,
    public contrato?: Contrato,
    public sequencial_pncp?: number,
    public tipo_documento?: number,
    public vinculo_id?: number,
  ) {
    super();
  }

  static converteJson(json: any): ContratoStorage {
    return Object.assign(new ContratoStorage(), json);
  }
}
