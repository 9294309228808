import { Component, OnInit, Input, ElementRef, ViewChild, SimpleChanges, OnChanges, OnDestroy, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AdiantamentoDocumentoService } from '../service/adiantamento-documento.service';
import {
  AdiantamentoDocumento, Adiantamento, Login,
  GlobalService, FuncaoService, Relatorio, CpfPipe, DateFormatPipe,
} from 'eddydata-lib';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'lib-adiantamento-documento',
  templateUrl: './adiantamento-documento.component.html'
})
export class AdiantamentoDocumentoComponent implements OnInit, OnChanges, OnDestroy {

  public ptBR: any;
  public entity: AdiantamentoDocumento;
  public entityTemp: AdiantamentoDocumento;
  public listaDocumentos: Array<any>;
  public total: number;
  protected unsubscribe: Subject<void> = new Subject();

  @Input() lista: Array<any>;
  @Input() entidade: Adiantamento;
  @Input() login: Login;

  @Output() recarregaDocumentos: EventEmitter<any> = new EventEmitter();

  @ViewChild('btnAdicionarDocumento') public btnAdicionarDocumento: ElementRef;
  @ViewChildren('btnsEditar') public btnsEditar: QueryList<ElementRef>;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected documentoService: AdiantamentoDocumentoService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.listaDocumentos = this.lista;
    this.calcularTotal();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lista) {
      this.listaDocumentos = this.lista;
      this.calcularTotal();
    }
  }

  private desabilitarEdicao(desabilitar: boolean) {
    this.btnsEditar.forEach((btn) => btn.nativeElement.disabled = desabilitar)
    this.btnAdicionarDocumento.nativeElement.disabled = desabilitar;
  }

  public adicionarDocumento() {
    this.entity = new AdiantamentoDocumento();
    this.entity.editavel = true;
    this.entity['salvo'] = false;
    if (!this.listaDocumentos) {
      this.listaDocumentos = new Array();
    }
    this.listaDocumentos.unshift(this.entity);
    this.desabilitarEdicao(true);

    setTimeout(() => this.globalService.calendarMascara(), 100);
  }

  public salvarDocumento(item: AdiantamentoDocumento) {
    try {
      if (!item.documento) {
        throw ('Informe o Documento!');
      } else if (item.documento.length > 50) {
        throw (`Tamanho máximo (50) para o campo documento excedido (${item.documento.length})!`);
      }

      // envia a entidade para ser salva no banco -----
      if (!item.id) {
        this.entity.adiantamento = this.entidade;
        this.documentoService.inserir(item).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            item.editavel = false;
            item['salvo'] = true;
            this.desabilitarEdicao(false);
            this.visualizarBotaoPDF()
            this.calcularTotal();
            this.recarregaDocumentos.emit('recalcular_total');
          });
      } else {
        this.documentoService.atualizar(item).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            item.editavel = false;
            item['salvo'] = true;
            this.desabilitarEdicao(false);
            this.calcularTotal();
            this.recarregaDocumentos.emit('recalcular_total');
          });
      }
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  public editarDocumento(item: AdiantamentoDocumento) {
    this.entityTemp = JSON.parse(JSON.stringify(item));
    this.entity = item;
    this.entity.data_documento = new DateFormatPipe().transform(this.entity.data_documento, []);
    this.entity.editavel = true;
    this.entity['salvo'] = true;
    this.desabilitarEdicao(true);
  }

  public cancelarDocumento(item: AdiantamentoDocumento, index: number) {
    if (item['salvo'] && item.id === this.entityTemp?.id) {
      item.data_documento = this.entityTemp.data_documento;
      item.documento = this.entityTemp.documento;
      item.cnpj = this.entityTemp.cnpj;
      item.razao_social = this.entityTemp.razao_social;
      item.valor_documento = this.entityTemp.valor_documento;

      this.entity = item;
      this.entity.editavel = false;
    } else {
      this.listaDocumentos.splice(index, 1);
    }
    this.desabilitarEdicao(false);
  }

  public confirmarRemocao(item: AdiantamentoDocumento, index: number) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja remover item?',
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.removerDocumento(item, index)
      },
    });
  }

  private removerDocumento(item: AdiantamentoDocumento, index: number) {
    if (item.id) {
      this.documentoService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaDocumentos.splice(index, 1);
          this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Exclusão', detail: error.error.payload });
          throw error;
        });
    } else {
      this.listaDocumentos.splice(index, 1);
    }
    this.calcularTotal();
  }

  private calcularTotal() {
    this.total = 0;
    if (this.listaDocumentos) {
      for (const item of this.listaDocumentos) {
        this.total += +item.valor_documento;
      }
    }
  }

  public visualizarBotaoPDF() {
    if (this.listaDocumentos) {
      if (this.listaDocumentos[0]?.salvo) {
        return true
      } else {
        if ((this.listaDocumentos.length > 0) && this.listaDocumentos[0].id) {
          return true
        } else {
          return false
        }
      }
    } else {
      return false
    }
  }

  public imprimirPDF() {
    this.documentoService.obterPorAdiantamento(this.entidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (lista: any) => {
          this.listaDocumentos = lista ? lista.content : new Array<AdiantamentoDocumento>();
          Relatorio.imprimirPersonalizado('Prestação de Contas de Adiantamento', this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            this.montarConteudo(lista.content),
            'portrait', 'Prestação de Contas de Adiantamento',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, false);
        },
        () => alert('erro ao retornar lista')
      );
  }

  private montarConteudo(lista: any[]) {
    let beneficiario = this.entidade;
    let retorno: {}[] = [];
    let datepipe = new DatePipe('pt');
    retorno.push([
      { text: `Responsável: ${(beneficiario.beneficiario).toUpperCase()}`, alignment: 'left', fontSize: 10, lineHeight: 1.5, bold: true },
      { text: `Empenho: ${this.entidade.empenho.numero}/${this.entidade.empenho.exercicio.ano}`, alignment: 'left', fontSize: 10, lineHeight: 1.5, bold: true },
      { text: `Processo: ${this.entidade.numero}`, alignment: 'left', fontSize: 10, lineHeight: 1.5, bold: true },
    ]);
    const conteudo = [];
    conteudo.push([
      { text: 'Data', fontSize: 12, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
      { text: 'Tipo Doc.', fontSize: 12, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'Documento', fontSize: 12, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'CPF/CNPJ', fontSize: 12, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'Razão Social', fontSize: 12, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'Valor', fontSize: 12, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
    ]);
    let soma = 0;

    for (const entidade of lista) {
      const registro = [];
      registro.push({ text: datepipe.transform(entidade.data_documento, 'dd/MM/yyyy'), fontSize: 10, alignment: 'center' });
      registro.push({ text: `${entidade.tipo_documento ? entidade.tipo_documento : 'Nenhum'}`, fontSize: 10, alignment: 'center' });
      registro.push({ text: `${entidade.documento}`, fontSize: 10, alignment: 'center' });
      registro.push({ text: `${new CpfPipe().transform(entidade.cnpj, [])}`, fontSize: 10, alignment: 'center' });
      registro.push({ text: `${(entidade.razao_social).toUpperCase()}`, fontSize: 10, alignment: 'center' });
      registro.push({ text: `${this.funcaoService.convertToBrNumber(entidade.valor_documento)}`, fontSize: 10, alignment: 'right' })

      soma += +entidade.valor_documento;

      conteudo.push(registro);
    }

    const total = [];
    total.push({ text: 'TOTAL', fontSize: 10, alignment: 'left', bold: true, colSpan: 5, margin: [0, 5, 0, 5], border: [true, true, false, true] });
    total.push('');
    total.push('');
    total.push('');
    total.push('');
    total.push({ text: this.funcaoService.convertToBrNumber(soma, 2), fontSize: 10, alignment: 'right', bold: true, margin: [0, 5, 0, 5], border: [false, true, true, true] });
    conteudo.push(total);

    const final = [];
    final.push([
      { text: ' ', fontSize: 10, alignment: 'center', lineHeight: 1.5, margin: [0, 15, 0, 5] },
      { text: `${this.login.cidade.nome}, ${(this.funcaoService.diaDaSemana(new Date().getDay())).split('-')[0]}, ${this.funcaoService.formatarDataExtenso(new Date())}`, fontSize: 10, alignment: 'center', lineHeight: 1.5, bold: true },
      { text: ' ', fontSize: 10, alignment: 'center', lineHeight: 1 },
      { text: `Declaro para todos os fins que os recursos disponibilizados foram utilizados de acordo com a demostração acima.`, fontSize: 10, alignment: 'center', lineHeight: 2, bold: true },
      { text: ' ', fontSize: 10, alignment: 'center', lineHeight: 1, margin: [0, 15, 0, 10] },
      { text: `${(beneficiario.beneficiario).toUpperCase()}`, fontSize: 10, alignment: 'center', lineHeight: 1.5, bold: true },
      { text: `${(beneficiario.cargo_beneficiario)}`, fontSize: 10, alignment: 'center', lineHeight: 1.5, bold: true }
    ]);

    retorno.push({
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['auto', 'auto', 'auto', 'auto', '*', 'auto'],
        body: conteudo
      }
    });

    retorno.push(final);

    return retorno;
  }

}
