import { Component, Input } from '@angular/core';
import { Audesp4ConvenioFederal, Audesp4ExistenciaRecursosSim } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-convenio-fed',
  templateUrl: './licitacao-audesp-convenio-fed.component.html'
})
export class LicitacaoAudespConvenioFedComponent extends BaseLicitacaoAudesp {

  @Input() public recurso: Audesp4ExistenciaRecursosSim;

  public itemAtual: Audesp4ConvenioFederal;
  public itemAnterior: Audesp4ConvenioFederal;

  constructor() {
    super()
  }

  ngOnInit(): void {
  }

  public adicionar() {
    if (!this.recurso.ConvenioFederal || this.recurso.ConvenioFederal.length === 0) this.recurso.ConvenioFederal = [];

    this.itemAtual = {
      ConvenioFederalNum: { Numero: undefined, Ano: undefined },
      ContrapartidaConvenioFederalValor: undefined, RepasseConvenioFederalValor: undefined,
      editavel: true
    }
    this.recurso.ConvenioFederal.push(this.itemAtual);
  }

  public visualizar(item: Audesp4ConvenioFederal) {
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.itemAtual['edicao'] = true;
  }

  public cancelar() {
    if (this.itemAtual['edicao']) {
      let itm = this.recurso.ConvenioFederal.find((item) => item === this.itemAtual);
      itm = Object.assign({}, this.itemAnterior);
      this.recurso.ConvenioFederal.splice(this.recurso.ConvenioFederal.indexOf(this.itemAtual), 1, itm)
    } else {
      this.remover(this.itemAtual);
    }
    this.itemAtual.editavel = false;
    this.itemAnterior = undefined;
    this.itemAtual = undefined;
  }

  public remover(item: Audesp4ConvenioFederal) {
    this.itemAtual = undefined;
    this.recurso.ConvenioFederal.splice(this.recurso.ConvenioFederal.indexOf(item), 1)
  }

  public salvar() {
    this.itemAtual.editavel = false;
    this.itemAtual = undefined;
  }

}
