import { Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EmpenhoService } from 'administrativo-lib';
import { BaseResourceListComponent, Coluna, Contrato, Empenho, Filtro, FuncaoService, GlobalService, Login, LoginContabil, ProdutoUnidade } from 'eddydata-lib';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-contrato-empenho',
  templateUrl: './contrato-empenho.component.html'
})
export class ContratoEmpenhoComponent extends BaseResourceListComponent<Empenho, LoginContabil> implements OnChanges {

  @Input() public login: Login;
  @Input() public contrato: Contrato;
  @Input() public itemFiltro: ProdutoUnidade;
  public listaEmpenho: Empenho[];

  public unsubscribe: Subject<void> = new Subject();

  public limite = 5;

  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    public funcaoService: FuncaoService,
    private empenhoService: EmpenhoService) {
    super(empenhoService, injector);
  }

  protected relations(): string {
    return 'ficha.recurso,licitacao_audesp,compra.exercicio,compra.rcms.exercicio';
  }

  protected acaoRemover(model: Empenho): Observable<Empenho> {
    return null;
  }

  public beforeInit(): void {
    this.usarExtendido = true;
  }


  protected afterInit(): void {
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
    ];
  }

  protected condicoesGrid(): {} {
    const parametros = {};
    parametros['contrato_id'] = this.contrato.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['ignoreCondObrig'] = true;
    return parametros;
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['numero', 'subelemento.codigo', 'valor_empenho', 'favorecido.cpf_cnpj'],
      date: ['data_empenho'],
      text: ['favorecido.nome', 'subelemento.nome'],
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['exercicio.ano$DESC', 'numero$DESC', 'especie'];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && (changes['contrato'] || changes['itemFiltro']) && this.contrato) {
      this.preencherGrid();
    }
  }

}