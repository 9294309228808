import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Pessoa } from '../../entidade/comum/pessoa.model';
import { FuncaoService } from '../../util/funcao.service';
import { GlobalService } from '../../util/global.service';
import { PessoaService } from '../service/pessoa.service';

declare var $: any;

@Component({
  selector: 'lib-pessoa-visualizar-dlg',
  templateUrl: './pessoa-visualizar-dlg.component.html'
})
export class PessoaVisualizarDlgComponent implements OnInit, OnDestroy {

  @Input() entity: Pessoa = new Pessoa();

  public listaTipos: Array<any>;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected pessoaService: PessoaService
  ) { }

  ngOnInit() {
    this.listaTipos = this.globalService.obterListaTipoFornecedores();
    this.entity = new Pessoa();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public limparDados() {
    $('#dialogViewPessoa').modal('hide');
  }
}
