import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, FichaDespesa, Login, Page } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class FichaDespesaService extends BaseResourceService<FichaDespesa> {

  constructor(
    protected injector: Injector
  ) {
    super(`fichas-despesa`, injector);
  }

  obterFichaControle(orgao_id: number, exercicio_id: number, data1: string, data2: string, ficha1: number, ficha2: number): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/ficha-controle-despesa?orgao=${orgao_id}&exercicio=${exercicio_id}&data1=${data1}&data2=${data2}&ficha1=${ficha1}&ficha2=${ficha2}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  salvarSiope(fichaId: number, siopeId: number): Observable<any> {
    return this.http.put(`${this.login.cidade.id}/${this.api}/siope/${fichaId}/${siopeId}`, {}, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  removerSiope(fichaId: number): Observable<any> {
    return this.http.delete(`${this.login.cidade.id}/${this.api}/siope/${fichaId}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  obterTotalDotacao(numero: number, exercicio: number, orgao: number, dataEmpenho: string, ateData: boolean): Observable<number> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/total-dotacao/${numero}/${exercicio}/${orgao}?data_empenho=${dataEmpenho}&ate_data=${ateData}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterTotalDotacaoExercicio(exercicio: number, orgao: number): Observable<number> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/total-dotacao-exercicio/${exercicio}/${orgao}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  planilhaPrevisao(pagina: number, limite: number, ano: number, orgaos: number, semZerados?: boolean): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/planilha-previsao/${pagina}/${limite}?ano=${ano}&orgaos=${orgaos}&semZerados=${semZerados}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  balancete(filtros: {}): Observable<Array<any>> {
    let parametros = '';
    if (filtros) {
      // tslint:disable-next-line: forin
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + filtros[key];
      }
    }
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/balancete${parametros}`).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  balancetePorGrupo(filtros: {}): Observable<Array<any>> {
    let parametros = '';
    if (filtros) {
      // tslint:disable-next-line: forin
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + filtros[key];
      }
    }
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/balancete-despesa-grupo${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  balancetePorRecurso(filtros: {}): Observable<Array<any>> {
    let parametros = '';
    if (filtros) {
      // tslint:disable-next-line: forin
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + filtros[key];
      }
    }
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/balancete-despesa-recurso${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  balancetePorFuncao(filtros: {}): Observable<Array<any>> {
    let parametros = '';
    if (filtros) {
      // tslint:disable-next-line: forin
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + filtros[key];
      }
    }
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/balancete-despesa-funcao-governo${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  balanceteProcessada(filtros: {}): Observable<Array<any>> {
    let parametros = '';
    if (filtros) {
      // tslint:disable-next-line: forin
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + filtros[key];
      }
    }
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/balancete-despesa-processada${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  balanceteSubelemento(filtros: {}): Observable<any> {
    let parametros = '';
    if (filtros) {
      // tslint:disable-next-line: forin
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + filtros[key];
      }
    }
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/balancete-despesa-subelemento${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  balanceteSubelementoDag(filtros: {}): Observable<any> {
    let parametros = '';
    if (filtros) {
      // tslint:disable-next-line: forin
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + filtros[key];
      }
    }
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/balancete-despesa-subelemento-dag${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  balanceteElementoDespesa(filtros: {}) {
    let parametros = '';
    if (filtros) {
      // tslint:disable-next-line: forin
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + filtros[key];
      }
    }
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/balancete-elemento-despesa${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  balancetePorAcao(filtros: {}): Observable<Array<any>> {
    let parametros = '';
    if (filtros) {
      // tslint:disable-next-line: forin
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + filtros[key];
      }
    }
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/balancete-despesa-acao${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterPorExercicio(orgao: number, exercicio: number, pagCorrente?: number, pagLimite?: number): Observable<Page> {
    if (!Number.isInteger(pagLimite) || !Number.isInteger(pagCorrente)) {
      pagCorrente = 1;
      pagLimite = 5000;
    }
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}?exercicio_id=${exercicio}&orgao_id=${orgao}&pagina=${pagCorrente}&limite=${pagLimite}`).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterPorDespesa(filtro: string, orgao: number, exercicio: number, pagCorrente?: number, pagLimite?: number): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?relations=despesa&despesa.nome$like=${filtro}%&exercicio_id=${exercicio}&orgao_id=${orgao}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterPorNumero(ficha: number, orgaoId: number, exercicioId: number): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?relations=despesa&numero=${ficha}&orgao_id=${orgaoId}&exercicio_id=${exercicioId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterUtilmaFicha(orgao: number, exercicio: number) {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/ultima-ficha/${exercicio}/${orgao}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  totalDespesaPorElemento(exercicio: number, orgao: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/total-despesa-elemento/${exercicio}/${orgao}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  totalDespesaPorFuncaoGoverno(exercicio: number, orgao: number, funcao_id: number, mes: number, recurso_id?: number): Observable<any> {
    let parametros = '';
    if (recurso_id)
      parametros = `?recursoId=${recurso_id}`;
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/total-despesa-funcao/${exercicio}/${orgao}/${mes}/${funcao_id}${parametros}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  totalEmpenhadoPessoal(exercicio: number, orgao: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/total-despesa-pessoal/${exercicio}/${orgao}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public totalizadores(exercicio: number, orgaoId: number, id: number): Observable<any> {
    let parametros = '';
    parametros += '?exercicio_id=' + exercicio;
    parametros += '&orgao_id=' + orgaoId;
    parametros += '&id=' + id;
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/extendido/1/1${parametros}`, this.httpOptions());
  }

  public totalRcms(ficha: number, parametros?: { data_limite?: string, pendente?: boolean, saldo_cotacao?: boolean, desconRcmsId?: number }) {
    const params = this.converterParametrosStr(parametros);
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/total-rcms/${ficha}${params}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public totalCompra(ficha: number, parametros?: {
    data_limite?: string, pendente?: boolean, saldo_cotacao?: boolean, desconCompraId?: number, empenhado?: boolean, mes?: number
  }) {
    const params = this.converterParametrosStr(parametros);
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/total-compra/${ficha}${params}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public totalEmpenho(ficha: number, parametros?: {
    data_limite?: string, pendente?: boolean, saldo_cotacao?: boolean, desconEmpenhoId?: number, mes?: number
  }) {
    const params = this.converterParametrosStr(parametros);
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/total-empenho/${ficha}${params}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public saldoAtualFicha(ficha: number, orgao: number, exercicio: number, data_limite?: string, desconsiderar?: {
    compraId?: number, rcmsProdutoUnId?: number[],
    rcmsId?: number, empenhoId?: number
  }, notProgress?: boolean) {
    const params = this.converterParametrosStr(desconsiderar);
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/saldo-atual/${orgao}/${exercicio}/${ficha}/${data_limite}${params}`, this.httpOptions(notProgress)).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public totalizacaoFicha(ficha: number, orgao: number, exercicio: number, data_limite?: string, desconsiderar?: {
    compra?: boolean, compraId?: number, rcms?: boolean, rcmsProdutoUnId?: number[],
    rcmsId?: number, empenho?: boolean, empenhoId?: number
  }) {
    const params = this.converterParametrosStr(desconsiderar);
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/totalizacao/${orgao}/${exercicio}/${ficha}/${data_limite}${params}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  exportarFichas(filtros: {}): Observable<Array<any>> {
    let parametros = '';
    if (filtros) {
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + filtros[key];
      }
    }
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/exportar-fichas${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  importarFichasDespesa(exercicio_atual_id: number, exercicio_anterior_id: number, orgao_id: number, importado: boolean): Observable<number> {
    const registro = {
      exercicio_atual_id: exercicio_atual_id,
      exercicio_anterior_id: exercicio_anterior_id,
      orgao_id: orgao_id,
      importado: importado
    }

    return this.http.post(`${this.login.cidade.id}/${this.api}/importar-fichas-despesa`, registro, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  liberarCotas(ficha_id: number, login: Login) {
    return this.http.put(`${this.login.cidade.id}/${this.api}/liberar-cotas/${ficha_id}`, login, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    )
  }

  importarFichasPPA(orgao_id: number, exercicio_id: number, ppa_id: number) {
    return this.http.post(`${this.login.cidade.id}/${this.api}/importar-fichas-ppa/${orgao_id}/${exercicio_id}/${ppa_id}`, {}, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  obterDadosCriancaAdolescente(exercicio: number, orgaoId: number, data_inicio: string, data_final: string): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/orcamento-crianca-adolescente/${exercicio}/${orgaoId}/${data_inicio}/${data_final}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  reordenarFichas(exercicioId: number, orgaoId: number) {
    return this.http.get(
      `${this.login.cidade.id}/${this.api}/reordenar-fichas/${exercicioId}/${orgaoId}`,
      this.httpOptions()
    ).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

}
