import { CommonModule, registerLocaleData } from '@angular/common';
import localept from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { FieldsetModule } from 'primeng/fieldset';
import { MenuModule } from 'primeng/menu';
import { PanelModule } from 'primeng/panel';
import { ChartComponent } from '../components/chart/chart.component';
import { ConfirmacaoComponent } from '../components/confirmacao/confirmacao.component';
import { CronometroComponent } from '../components/cronometro/cronometro.component';
import { DivisorComponent } from '../components/divisor/divisor.component';
import { DlgComponent } from '../components/dlg/dlg.component';
import { ExportListComponent } from '../components/export-list/export-list.component';
import { FiltroPersonalizadoComponent } from '../components/filtro-personalizado/filtro-personalizado.component';
import { FiltroComponent } from '../components/filtro/filtro.component';
import { FormFieldErrorComponent } from '../components/form-field-error/form-field-error.component';
import { LoaderComponent } from '../components/loader/loader.component';
import { OrdenacaoComponent } from '../components/ordenacao/ordenacao.component';
import { PageHeaderLightComponent } from '../components/page-header-light/page-header-light.component';
import { PageHeaderComponent } from '../components/page-header/page-header.component';
import { PaginacaoComponent } from '../components/paginacao/paginacao.component';
import { CpfPipe } from '../components/pipe/cpf.pipe';
import { LicitacaoPipe } from '../components/pipe/licitacao.pipe';
import { NumeroAbsolutoPipe } from '../components/pipe/numero-absoluto.pipe';
import { TelefonePipe } from '../components/pipe/telefone.pipe';
import { ValorContratoPipe } from '../components/pipe/valor-contrato.pipe';
import { ProgressoComponent } from '../components/progresso/progresso.component';
import { ServerErrorMessagesComponent } from '../components/server-error-messages/server-error-messages.component';
import { MascaraPipe } from './mascara-pipe';

registerLocaleData(localept, 'pt');

@NgModule({
  declarations: [
    PageHeaderComponent,
    PageHeaderLightComponent,
    FormFieldErrorComponent,
    ServerErrorMessagesComponent,
    PaginacaoComponent,
    FiltroComponent,
    FiltroPersonalizadoComponent,
    DlgComponent,
    ExportListComponent,
    ProgressoComponent,
    DivisorComponent,
    LoaderComponent,
    ConfirmacaoComponent,
    CpfPipe, TelefonePipe, LicitacaoPipe, MascaraPipe, ValorContratoPipe, NumeroAbsolutoPipe,
    ChartComponent,
    CronometroComponent,
    OrdenacaoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PanelModule,
    CalendarModule,
    MenuModule,
    IMaskModule,
    AutoCompleteModule,
    FieldsetModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    PageHeaderComponent,
    PageHeaderLightComponent,
    FormFieldErrorComponent,
    ServerErrorMessagesComponent,
    PaginacaoComponent,
    FiltroComponent,
    FiltroPersonalizadoComponent,
    DlgComponent,
    ExportListComponent,
    DivisorComponent,
    ProgressoComponent,
    LoaderComponent,
    ConfirmacaoComponent,
    CpfPipe, TelefonePipe, LicitacaoPipe, MascaraPipe, ValorContratoPipe, NumeroAbsolutoPipe,
    ChartComponent,
    CronometroComponent,
    OrdenacaoComponent
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt' }],
})
export class SharedModule { }
