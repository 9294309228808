import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { InventarioSituacaoRptComponent } from './inventario-situacao-rpt.component';

const routes: Routes = [
  { path: '', component: InventarioSituacaoRptComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InventarioSituacaoRptRoutingModule { }
