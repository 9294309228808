import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FuncaoService } from 'eddydata-lib';

@Directive()
export class Anexo8OutraDespesa implements OnDestroy {

  protected funcaoService: FuncaoService;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected mes: number) {
    this.funcaoService = new FuncaoService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarQuadro(dados: any[]): {}[] {
    const registros: {}[] = [
      [
        {
          text: 'OUTRAS DESPESAS COM EDUCAÇÃO  (Por Área de Atuação)', alignment: 'center',
          bold: true, fontSize: 7, border: [true, false, true, true]
        },
        { text: 'DOTAÇÃO ATUALIZADA (c)', alignment: 'center', bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: 'DESPESAS EMPENHADAS ATÉ O BIMESTRE (d)', alignment: 'center', bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: 'DESPESAS LIQUIDADAS ATÉ O BIMESTRE (e)', alignment: 'center', bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: 'DESPESAS PAGAS ATÉ O BIMESTRE (f)', alignment: 'center', bold: true, fontSize: 7, border: [true, false, true, true] },
        { text: 'INSCRITAS EM RESTOS A PAGAR NÃO PROCESSADOS (g)', alignment: 'center', bold: true, fontSize: 7, border: [true, false, true, true] }
      ]
    ];

    const grupos2 = this.funcaoService.agrupar(dados, 'grupo', ['dotacao_atualizado', 'empenhado', 'liquidado', 'pago', 'resto']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    for (const grupo of grupos2) {
      registros.push([
        { text: grupo.grupo, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_atualizado']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago']), alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado'] - grupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 }
      ]);
      total1 += +grupo.totalizadores['dotacao_atualizado'];
      total2 += +grupo.totalizadores['empenhado'];
      total3 += +grupo.totalizadores['liquidado'];
      total4 += +grupo.totalizadores['pago'];
      total5 += (+grupo.totalizadores['empenhado'] - +grupo.totalizadores['liquidado']);

      const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['dotacao_atualizado', 'empenhado', 'liquidado', 'pago', 'resto']);
      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo != '') {
          registros.push([
            { text: subgrupo.grupo, fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['dotacao_atualizado']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['pago']), alignment: 'right', fontSize: 7 },
            { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado'] - subgrupo.totalizadores['liquidado']), alignment: 'right', fontSize: 7 }
          ]);

          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              registros.push([
                { text: item.nome, fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.dotacao_atualizado), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.empenhado), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.liquidado), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.pago), alignment: 'right', fontSize: 7 },
                { text: this.funcaoService.convertToBrNumber(item.empenhado - item.liquidado), alignment: 'right', fontSize: 7 },
              ]);
            }
          }
        }
      }
    }

    return registros;

  }

  public montarQuadroCsv(dados: any[], listaExportar): any {

    const espaco = {
      titulo: '',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: ''
    }
    listaExportar.push(espaco)

    const primeira_linha = {
      titulo: 'OUTRAS DESPESAS COM EDUCAÇÃO  (Por Área de Atuação)',
      coluna2: 'DOTAÇÃO ATUALIZADA (c)',
      coluna3: 'DESPESAS EMPENHADAS ATÉ O BIMESTRE (d)',
      coluna4: 'DESPESAS LIQUIDADAS ATÉ O BIMESTRE (e)',
      coluna5: 'DESPESAS PAGAS ATÉ O BIMESTRE (f)',
      coluna6: 'INSCRITAS EM RESTOS A PAGAR NÃO PROCESSADOS (g)',
      coluna7: ''
    }
    listaExportar.push(primeira_linha)

    const grupos2 = this.funcaoService.agrupar(dados, 'grupo', ['dotacao_atualizado', 'empenhado', 'liquidado', 'pago', 'resto']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    for (const grupo of grupos2) {
      const linha_grupo = {
        titulo: grupo.grupo,
        coluna2: this.funcaoService.convertToBrNumber(grupo.totalizadores['dotacao_atualizado']),
        coluna3: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado']),
        coluna4: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']),
        coluna5: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago']),
        coluna6: this.funcaoService.convertToBrNumber(grupo.totalizadores['empenhado'] - grupo.totalizadores['liquidado']),
        coluna7: ''
      }
      listaExportar.push(linha_grupo)

      total1 += +grupo.totalizadores['dotacao_atualizado'];
      total2 += +grupo.totalizadores['empenhado'];
      total3 += +grupo.totalizadores['liquidado'];
      total4 += +grupo.totalizadores['pago'];
      total5 += (+grupo.totalizadores['empenhado'] - +grupo.totalizadores['liquidado']);

      const subgrupos = this.funcaoService.agrupar(grupo.registros, 'subgrupo', ['dotacao_atualizado', 'empenhado', 'liquidado', 'pago', 'resto']);
      for (const subgrupo of subgrupos) {
        if (subgrupo.grupo != '') {
          const linha_subgrupo = {
            titulo: subgrupo.grupo,
            coluna2: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['dotacao_atualizado']),
            coluna3: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado']),
            coluna4: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liquidado']),
            coluna5: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['pago']),
            coluna6: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['empenhado'] - subgrupo.totalizadores['liquidado']),
            coluna7: ''
          }
          listaExportar.push(linha_subgrupo)

          for (const item of subgrupo.registros) {
            if (item.nome != '') {
              const linha_subgrupo_item = {
                titulo: item.nome,
                coluna2: this.funcaoService.convertToBrNumber(item.dotacao_atualizado),
                coluna3: this.funcaoService.convertToBrNumber(item.empenhado),
                coluna4: this.funcaoService.convertToBrNumber(item.liquidado),
                coluna5: this.funcaoService.convertToBrNumber(item.pago),
                coluna6: this.funcaoService.convertToBrNumber(item.empenhado - item.liquidado),
                coluna7: ''
              }
              listaExportar.push(linha_subgrupo_item)
            }
          }
        }
      }
    }

  }

}