// tslint:disable: variable-name
import { Sic } from './sic.model';
import { Usuario } from '../comum/usuario.model';
import { BaseResourceModel } from '../../models/base-resource.model';
import { SicArquivo } from './sic-arquivo.model';

export class SicHistorico extends BaseResourceModel {
    constructor(
        public id?: number,
        public data_historico?: Date,
        public comentario?: string,
        public data_cadastro?: Date,
        public data_alteracao?: Date,
        public sic?: Sic,
        public usuario?: Usuario,
        public arquivos?: SicArquivo[]
    ) {
        super();
    }
    static converteJson(json: any): SicHistorico {
        return Object.assign(new SicHistorico(), json);
    }
}
