import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, RetencaoExtra, Page } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class RetencaoExtraService extends BaseResourceService<RetencaoExtra> {

  constructor(
    protected injector: Injector
  ) {
    super(`retencoes-extra`, injector);
  }

  public obterPorLiquidacao(id: number): Observable<Page> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/extendido/?relations=empenho_extra,empenho_extra.ficha,empenho_extra.ficha.plano,empenho_extra.favorecido,ficha,ficha.favorecido,ficha.plano&empenho_id=${id}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public verificarPagamentoRetencao(retencaoId: number, exercicio: number, orgao: number, pagExtra: boolean): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/verifica-pagamento?retencaoId=${retencaoId}&exercicio=${exercicio}&orgao=${orgao}&extra=${pagExtra}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
