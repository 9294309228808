import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, MemorialItem } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class MemorialItemService extends BaseResourceService<MemorialItem> {

  constructor(
    protected injector: Injector
  ) {
    super(`memoriais-item`, injector);
  }

}
