// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { EncerramentoContabilJustificativa } from './encerramento-contabil-jutificativa.model';

export class EncerramentoContabil extends BaseResourceModel {

  constructor(
    public id?: number,
    public jan?: boolean,
    public fev?: boolean,
    public mar?: boolean,
    public abr?: boolean,
    public mai?: boolean,
    public jun?: boolean,
    public jul?: boolean,
    public ago?: boolean,
    public set?: boolean,
    public out?: boolean,
    public nov?: boolean,
    public dez?: boolean,
    public orgao?: Orgao,
    public exercicio?: Exercicio,
    public justificativa? : EncerramentoContabilJustificativa
  ) {
    super();
  }

  static converteJson(json: any): EncerramentoContabil {
    return Object.assign(new EncerramentoContabil(), json);
  }
}
