import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ProdutoDlgModule } from 'almoxarifado-lib';
import { IMaskModule } from 'angular-imask';
import { PessoaModule, RplStorageModule, SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { AtaPersonalizadaModule } from '../ata-personalizada/ata-personalizada.module';
import { ContratoLicitacaoDlgComponent } from '../contrato/contrato-licitacao-dlg/contrato-licitacao-dlg.component';
import { ContratoLicitacaoComponent } from '../contrato/contrato-licitacao/contrato-licitacao.component';
import { FavorecidoDlgModule } from '../favorecido/favorecido-dlg.module';
import { JulgamentoComponent } from '../julgamento/julgamento.component';
import { LicitacaoAudespMenuModule } from '../licitacao-audesp-menu/licitacao-audesp-menu.module';
import { LicitacaoFiscalComponent } from '../licitacao-fiscal/licitacao-fiscal.component';
import { LicitacaoTestemunhaComponent } from '../licitacao-testemunha/licitacao-testemunha.component';
import { LicitacaoOcorrenciaComponent } from '../licitacao-ocorrencia/licitacao-ocorrencia.component';
import { LicitacaoReservaComponent } from '../licitacao-reserva/licitacao-reserva.component';
import { MemorialCotaComponent } from '../memorial-cota/memorial-cota.component';
import { MemorialItemRplComponent } from '../memorial-item-rpl/memorial-item-rpl.component';
import { MemorialItemComponent } from '../memorial-item/memorial-item.component';
import { MemorialRplComponent } from '../memorial-rpl/memorial-rpl.component';
import { MemorialComponent } from '../memorial/memorial.component';
import { ModeloRelatorioModule } from '../modelo-relatorio/modelo-relatorio.module';
import { BBLicitacoesEModule } from '../pregao-eletronico/bb-licitacoes-e/bb-licitacoes-e.module';
import { ProponenteComponent } from '../proponente/proponente.component';
import { PropostaComponent } from '../proposta/proposta.component';
import { RelatorioLicitacaoModule } from '../relatorio-licitacao/relatorio-licitacao.module';
import { ServicoModule } from '../servico/servico.module';
import { ExclusaoLicitacaoDlgComponent } from './exclusao-licitacao-dlg/exclusao-licitacao-dlg.component';
import { LicitacaoFormComponent } from './licitacao-form/licitacao-form.component';
import { LicitacaoListComponent } from './licitacao-list/licitacao-list.component';
import { LicitacaoRoutingModule } from './licitacao-routing.module';
import { LicitacaoRptComponent } from './licitacao-rpt/licitacao-rpt.component';
import { LicitacaoStorageComponent } from './licitacao-storage/licitacao-storage.component';
import { LicitacaoViewComponent } from './licitacao-view/licitacao-view.component';
import { ListaVerificacaoComponent } from '../lista-verificacao/lista-verificacao.component';
import { MotivoPropostaDesclassificadoDlg } from '../julgamento/motivo-proposta-desclassificado-dlg/motivo-proposta-desclassificado-dlg.component';
import { LicitacaoPendenciaReservaComponent } from '../licitacao-pendencia-reserva/licitacao-pendencia-reserva.component';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';

@NgModule({
  declarations: [
    LicitacaoListComponent,
    LicitacaoFormComponent,
    LicitacaoViewComponent,
    MemorialComponent,
    MemorialCotaComponent,
    ProponenteComponent,
    PropostaComponent,
    JulgamentoComponent,
    LicitacaoRptComponent,
    MemorialItemComponent,
    LicitacaoFiscalComponent,
    LicitacaoTestemunhaComponent,
    ContratoLicitacaoComponent,
    ContratoLicitacaoDlgComponent,
    LicitacaoStorageComponent,
    MemorialRplComponent,
    MemorialItemRplComponent,
    LicitacaoReservaComponent,
    ListaVerificacaoComponent,
    LicitacaoPendenciaReservaComponent,
    LicitacaoOcorrenciaComponent,
    ExclusaoLicitacaoDlgComponent,
    MotivoPropostaDesclassificadoDlg
  ],
  imports: [
    CommonModule,
    LicitacaoRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    RadioButtonModule,
    FileUploadModule,
    FieldsetModule,
    SelectButtonModule,
    DragDropModule,
    ConfirmDialogModule,
    PessoaModule,
    RelatorioLicitacaoModule,
    AtaPersonalizadaModule,
    FavorecidoDlgModule,
    ProdutoDlgModule,
    LicitacaoAudespMenuModule,
    BBLicitacoesEModule,
    ServicoModule,
    ModeloRelatorioModule,
    CheckboxModule,
    RplStorageModule,
    DropdownModule,
    InputNumberModule
  ],
  exports: [
    MemorialComponent,
    ProponenteComponent,
    PropostaComponent,
  ],
  providers: [MessageService, ConfirmationService]
})
export class LicitacaoModule { }
