import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { PessoaModule, SharedModule } from 'eddydata-lib';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AceiteRequisicaoDlgComponent } from './aceite-requisicao-dlg/aceite-requisicao-dlg.component';
import { RecusarRequisicaoDlgComponent } from './recusar-requisicao-dlg/recusar-requisicao-dlg.component';
import { RequisicaoAutorizacaoFormComponent } from './requisicao-autorizacao-form/requisicao-autorizacao-form.component';
import { RequisicaoAutorizacaoItemComponent } from './requisicao-autorizacao-item/requisicao-autorizacao-item.component';
import { RequisicaoAutorizacaoListComponent } from './requisicao-autorizacao-list/requisicao-autorizacao-list.component';
import { RequisicaoAutorizacaoRoutingModule } from './requisicao-autorizacao-routing.module';
import { RequisicaoAutorizacaoViewComponent } from './requisicao-autorizacao-view/requisicao-autorizacao-view.component';
import { TrocaItemRequisicaoDlgComponent } from './troca-item-requisicao-dlg/troca-item-requisicao-dlg.component';
import { InventarioEstoqueModule } from '../inventario-estoque/inventario-estoque.module';
import { ProdutoDlgModule } from '../produto/produto-dlg.module';
import { RequisicaoAutorizacaoPacienteListComponent } from './requisicao-autorizacao-paciente-list/requisicao-autorizacao-paciente-list.component';
import { ComprovanteRequisicaoComponent } from './comprovante-requisicao/comprovante-requisicao.component';



@NgModule({
  declarations: [
    RequisicaoAutorizacaoListComponent,
    RequisicaoAutorizacaoFormComponent,
    RequisicaoAutorizacaoItemComponent,
    RequisicaoAutorizacaoPacienteListComponent,
    TrocaItemRequisicaoDlgComponent,
    RequisicaoAutorizacaoViewComponent, 
    RecusarRequisicaoDlgComponent,
    AceiteRequisicaoDlgComponent,
    ComprovanteRequisicaoComponent
  ],
  exports: [
    RequisicaoAutorizacaoListComponent,
    RequisicaoAutorizacaoFormComponent,
    RequisicaoAutorizacaoPacienteListComponent,
    TrocaItemRequisicaoDlgComponent,
    RecusarRequisicaoDlgComponent,
    AceiteRequisicaoDlgComponent
  ],
  imports: [
    CommonModule,
    RequisicaoAutorizacaoRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    RadioButtonModule,
    InputNumberModule,
    PessoaModule,
    FieldsetModule,
    TooltipModule,
    CheckboxModule,
    ConfirmDialogModule,
    InventarioEstoqueModule,
    ProdutoDlgModule
  ],
  providers: [MessageService]
})
export class RequisicaoAutorizacaoModule { }
