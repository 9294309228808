import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { ContaBancaria } from "./conta-bancaria.model";

export class ContaBancariaCaixa extends BaseResourceModel {
  constructor(
    public id?: number,
    public saldo?: number,
    public valor_outros?: number,
    public data?: Date,
    public aux?: number,
    public conta?: ContaBancaria,
    public exercicio?: Exercicio,) {
    super();
  }
  static converteJson(json: any): ContaBancariaCaixa {
    return Object.assign(new ContaBancariaCaixa(), json);
  }
}