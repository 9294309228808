import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ParametroEmailRoutingModule } from './parametro-email-routing.module';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { InplaceModule } from 'primeng/inplace';
import { CalendarModule } from 'primeng/calendar';
import { IMaskModule } from 'angular-imask';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { EditorModule } from 'primeng/editor';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { PickListModule } from 'primeng/picklist';
import { ParametroEmailFormComponent } from './parametro-email-form/parametro-email-form.component';
import { SharedModule } from '../util/shared.module';

@NgModule({
  declarations: [ParametroEmailFormComponent],
  imports: [
    SharedModule,
    FormsModule,
    ParametroEmailRoutingModule,
    AutoCompleteModule,
    InplaceModule,
    CalendarModule,
    PickListModule,
    IMaskModule,
    ConfirmDialogModule,
    EditorModule,
    ToastModule
  ],
  providers: [MessageService, DatePipe]
})
export class ParametroEmailModule { }
