import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReservaDotacao, GlobalService } from 'eddydata-lib';

@Component({
  selector: 'app-declaracao-form',
  templateUrl: './declaracao-form.component.html'
})
export class DeclaracaoFormComponent implements OnInit {

  @Input() entidadeForm: FormGroup;
  @Input() entidade: ReservaDotacao;
  @Input() listaConvenioEstadual: Array<any>;
  @Input() visualizar: Boolean;

  @Output() submitClick = new EventEmitter();

  public ptBR: any;

  constructor() { }

  ngOnInit() {
    this.ptBR = new GlobalService().obterDataBR();
  }

  submit(event: any) {
    this.submitClick.emit(event);
  }

  public podeVisualizar() {
    if (this.visualizar === true) {
      return true;
    }
    if(this.visualizar === false){
      return false;
    }
  }
}
