import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, DocumentoFiscalServicos } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentoFiscalService extends BaseResourceService<DocumentoFiscalServicos> {

  constructor(
    protected injector: Injector
  ) {
    super('documento-fiscal', injector);
  }

  desvincularDocumentoFiscalServicosLiquidacao(documentoFiscalId: number): Observable<any> {
    
    return this.http.delete(`${this.login.cidade.id}/${this.api}/desvincular-documento-fiscal-servicos-liquidacao/${documentoFiscalId}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  desvincularLiquidacao(liquidacaoId: number, tipo: String): Observable<any> {
    
    return this.http.put(`${this.login.cidade.id}/${this.api}/desvincular-liquidacao/${liquidacaoId}/${tipo}`, {}, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }
}