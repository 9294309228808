import { Component, Injector, ViewChild, ElementRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { LiquidacaoRestoService } from '../service/liquidacao-resto.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Validators } from '@angular/forms';
import { RetencaoRestoService } from '../service/retencao-resto.service';
import { EmpenhoRestoService } from '../../empenho-resto/service/empenho-resto.service';
import {
  BaseResourceFormComponent, LiquidacaoResto, LoginContabil, EmpenhoResto, RetencaoResto, GlobalService,
  FuncaoService, DateFormatPipe, Mensagem, OrgaoAssinaturaService, DocumentoFiscalInfo, PreLiquidacaoResto,
  Compra, Favorecido, Aliquota, FichaExtra, Exercicio, ExercicioService, FavorecidoService, MovimentoEstoque,
  Produto, Tabela1Reinf, Despesa
} from 'eddydata-lib';
import { NotaLiquidacaoResto } from '../../relatorio/liquidacao/nota-liquidacao-resto';
import { DocumentoFiscalInfoService } from '../../documentos-fiscais/service/documento-fiscal-info.service';
import { PreLiquidacaoRestoService } from '../../pre-liquidacao-resto/service/pre-liquidacao-resto.service';
import { MovimentoEstoqueService } from 'almoxarifado-lib';
import { DatePipe } from '@angular/common';
import * as toastr from 'toastr';
import { DocumentoFiscalService } from '../../documentos-fiscais/service/documento-fiscal.service';
import { CompraService } from 'compra-lib';
import { DespesaService } from 'administrativo-lib';
import { OrdemPagamentoItemService } from '../../tesouraria/service/ordem-item-pagamento.service';
import { FichaExtraService } from '../../ficha-extra/service/ficha-extra.service';

declare var $: any;

interface IPreLiquidacaoExtendido extends PreLiquidacaoResto {
  total_preliquidado?: string;
  total_preliquidado_anterior?: string;
  total_empenhado?: string;
  total_anulado?: string;
}

@Component({
  selector: 'app-liquidacao-resto-form',
  templateUrl: './liquidacao-resto-form.component.html'
})
export class LiquidacaoRestoFormComponent extends BaseResourceFormComponent<LiquidacaoResto, LoginContabil> {
  /**
   * Declaração de variáveis
   */
  @ViewChild('numero_empenho') inputField: ElementRef;

  public especie: string;
  public listaUfs: Array<any>;
  public empenhado: number;
  public liquidado: number;
  public pago: number;
  public listaRetencoes: Array<RetencaoResto>;
  public empenhoNumero: number;
  public empenhoAno: number;
  public redirecionar = false;
  public empenho: EmpenhoResto;
  public preliquidacoes: PreLiquidacaoResto[];
  public vincularPre: boolean = false;
  public listaDocumentos: Array<DocumentoFiscalInfo> = new Array<DocumentoFiscalInfo>();
  public datepipe: DatePipe;
  public totalCancelado: number;
  public saldo: number;
  public compra: Compra
  public totalPreLiquidado: number = 0;
  public movimentoEstoque: MovimentoEstoque;
  public movimentosPendentes: Array<MovimentoEstoque> = null;
  public despesa: Despesa;
  public vencimento: string;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected messageService: MessageService,
    protected empenhoService: EmpenhoRestoService,
    protected retencaoService: RetencaoRestoService,
    protected documentoFiscalService: DocumentoFiscalService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected docFiscalService: DocumentoFiscalInfoService,
    protected preService: PreLiquidacaoRestoService,
    protected liquidacaoService: LiquidacaoRestoService,
    protected ordemItemPagamentoService: OrdemPagamentoItemService,
    protected confirmationService: ConfirmationService,
    protected movimentoEstoqueService: MovimentoEstoqueService,
    protected compraService: CompraService,
    protected fichaExtraService: FichaExtraService,
    protected exercicioService: ExercicioService,
    protected favorecidoServico: FavorecidoService,
    protected tabela1ReinfServico: Tabela1Reinf,
    protected despesaServico: DespesaService,
  ) {
    super(new LiquidacaoResto(), injector, LiquidacaoResto.converteJson, liquidacaoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(_entidade: LiquidacaoResto): Promise<boolean> {
    return !_entidade.anulacao && !_entidade.anulado_total
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      parcela: [null, [Validators.required]],
      valor_liquidado: [null, [Validators.required]],
      data_liquidacao: [null, [Validators.required]],
      data_vencimento: [null, [Validators.required]],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      mes: [null],
      documento: [null],
      anulacao: [false, [Validators.required]],
      impresso: [false, [Validators.required]],
      recolhimento_encargo: [false, [Validators.required]],
      empenho: [null, [Validators.required]],
      aux: [null],
      orgao: [this.login.orgao, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      retencoes: [null],
      documento_fiscal_info: [null],
      usuario_cadastro: [this.login.usuario],
      preliquidacao: [null],
      valor_base_ir: [null],
      valor_base_csll: [null],
      valor_base_cofins: [null],
      valor_base_pis: [null],
      superavit: [false]
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'empenho,empenho.favorecido,empenho.favorecido.tipo,empenho.orgao,exercicio,orgao,orgao.cidade,preliquidacao.empenho,preliquidacao.usuario_cadastro,empenho.contrato.aditamentos,empenho.contrato.prazo,documento_fiscal_info,empenho.compra.rcms.prazo_pagamento'
    };
  }

  protected afterInit(): void {
    this.listaUfs = this.globalService.listaEstados();
    this.inicializaVariavel();
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected async beforeSubmit() {
    try {
      if (!this.entidadeForm.get('empenho').value) {
        throw new Error('Informe o empenho para liquidação');
      }
      if (!this.entidadeForm.get('exercicio').value) {
        throw new Error('Informe o exercício da liquidação');
      }
      if (!this.entidadeForm.get('orgao').value) {
        throw new Error('Informe o órgão da liquidação');
      }
      if (!this.entidadeForm.get('historico').value) {
        throw new Error('Informe o histórico da liquidação');
      }
      if (!this.entidadeForm.get('valor_liquidado').value) {
        throw new Error('Informe o valor da liquidação');
      }
      if (!this.entidadeForm.get('data_liquidacao').value) {
        throw new Error('Informe a data da liquidação');
      }
      if (!this.entidadeForm.get('data_vencimento').value) {
        throw new Error('Informe o vencimento da liquidação');
      }
      if (!this.entidadeForm.get('documento').value && !this.entidadeForm.get('documento_fiscal_info').value) {
        throw new Error('Informe o documento da liquidação');
      }
      if (!this.entidadeForm.get('documento').value && this.entidadeForm.get('documento_fiscal_info').value) {
        this.entidadeForm.get('documento').setValue('-');
      }
      if (this.entidadeForm.get('valor_liquidado').value <= 0) {
        throw new Error('O valor da liquidação deve ser maior que zero');
      }

      const dtLiquidacao: Date = this.entidadeForm.get('data_liquidacao').value;
      if (dtLiquidacao.getFullYear() !== this.login.exercicio.ano) {
        throw new Error('O ano da data da liquidação está diferente do exercício logado');
      }

      const preLiq: IPreLiquidacaoExtendido = this.entidadeForm.get("preliquidacao")?.value;
      const totalPreliquidado: number = preLiq?.id ? (+this.totalPreLiquidado - (+preLiq?.valor_liquidado + +preLiq?.total_anulado)) : this.totalPreLiquidado;
      const saldo_liquidacao = +(+this.empenhado - +this.liquidado - +this.totalCancelado - +totalPreliquidado).toFixed(2) + ((this.entidade.valor_liquidado ? +this.entidade.valor_liquidado : 0) - +this.entidadeForm.get('valor_liquidado').value);
      if (saldo_liquidacao < 0) {
        throw new Error('Não há saldo para continuar a liquidação!');
      }

      if (!await this.validarEntradaAlmoxarifado()) {
        throw new Error(`Não foi encontrado entrada deste empenho no almoxarifado/patrimônio. Favor verificar`);
      }

      const pre: PreLiquidacaoResto = this.entidadeForm.get('preliquidacao').value;
      const data_liquidacao: Date = this.entidadeForm.get('data_liquidacao').value;
      if (pre && new Date(pre.data_liquidacao) > data_liquidacao) {
        throw new Error('A data da liquidação não pode ser superior a data da pré-liquidação!');
      }

      this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(dtLiquidacao)?.split('-')?.[1]);
      this.entidadeForm.get('retencoes').setValue(this.listaRetencoes);
      const dataLiquidacao = new DateFormatPipe().transform(this.entidadeForm.get('data_liquidacao').value, []);
      this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidadeForm.get('data_liquidacao').value)?.split('-')?.[1]);

      const validarContratoVencido = await this.validarContratoVencido();
      if (!validarContratoVencido) {
        throw new Error('Contrato vencido!');
      }
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  protected async afterSubmit(ent: LiquidacaoResto) {
    if (this.currentActionRoute === 'novo') {
      if (this.movimentoEstoque?.id) {
        await this.movimentoEstoqueService.vincularNovaLiquidacao(this.movimentoEstoque.id, ent.id, 'R').toPromise();
      }
      if (this.login.redirecionar_liquidar_pagar && this.podeIncluir('/pagamentos-resto')) {
        this.router.navigate(['/pagamentos-resto', 'novo', ent.id]);
      } else if (!this.limparTela) {
        this.router.navigate(['/liquidacoes-restos-pagar', ent.id, 'editar']);
      } else {
        this.router.navigate(['/liquidacoes-restos-pagar', 'novo']);
      }
      this.skipRedirect = false;
    } else {
      this.router.navigate(['/liquidacoes-restos-pagar', ent.id, 'editar']).then(() => {
        this.obterRetencoes(ent.id);
      });
    }
  }

  public naturezaRendimento(item: Produto) {
    const obj = this.tabela1ReinfServico.carregarPorCodigo(item?.codigo_reinf != null ? item?.codigo_reinf : item.material?.codigo_reinf != null ? item.material?.codigo_reinf : this.despesa?.codigo_reinf, true);

    return obj?.codigo + ' - ' + (obj?.nome.length < 30 ? obj?.nome : obj?.nome.substring(0, 30) + '...');
  }

  protected async afterLoad() {
    if (!this.podeAlterarAudesp(this.entidade.data_liquidacao)) {
      this.router.navigate(['/liquidacoes-restos-pagar']);
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return;
    }

    this.calcularPrazo(this.entidade.empenho);

    if (this.entidade.id) {
      this.obterRetencoes(this.entidade.id);
      this.empenhoNumero = this.entidade.empenho.numero;
      this.empenhoAno = this.entidade.empenho.ano;
      this.entidade.data_liquidacao = new DateFormatPipe().transform(this.entidade.data_liquidacao, []);
      this.entidade.data_vencimento = new DateFormatPipe().transform(this.entidade.data_vencimento, []);
      this.empenho = this.entidade.empenho;
      await this.carregarDocumentosFiscais();
      this.movimentoEstoqueService.filtrar(0, -1, {
        relations: 'itens,compra,compra.exercicio,compra.contrato,tipo,recebedor,itens.produto_unidade,itens.produto_unidade.produto,itens.produto_unidade.produto.material,itens.produto_unidade.unidade',
        'liquidacao_resto.id': this.entidade.id, excluido: false, 'orgao.id': this.login.orgao.id
      }).pipe(takeUntil(this.unsubscribe)).subscribe({
        next: (res) => {
          if (res?.content?.length > 0) {
            this.movimentoEstoque = res.content[0];
          } else {
            // if (this.entidade.empenho?.subelemento?.codigo_reinf) {
            // this.objetoReinfTabela1 = this.tabela1ReinfServico.carregarPorCodigo(this.entidade.empenho.subelemento.codigo_reinf, true);
            // }
          }
        }, error: (e) => { this.messageService.add({ severity: 'error', summary: 'Atenção', detail: e.error.payload }) }
      });

      this.despesaServico.filtrar(1, -1, { exercicio_id: this.login.exercicio.id, codigo: this.entidade.empenho.subelemento }).pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          this.despesa = res.content[0];
        });
    }
  }


  private obterRetencoes(id: number) {
    this.loadTotalizadores().then(() => {
      this.retencaoService.obterPorLiquidacao(id).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            this.listaRetencoes = data ? data.content : new Array<RetencaoResto>();
            this.listaRetencoes.forEach(async ret => this.editarRetencaoOrcamentaria(ret))
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    });
  }

  public async editarRetencaoOrcamentaria(item: RetencaoResto) {
    if (item.id && item.ficha.especie === 'O') {
      let ordemPagamento = await this.ordemItemPagamentoService.obter({ liquidacao_resto_id: this.entidade.id }).toPromise();
      if (!item.liquidacao?.pagamentos?.length && !ordemPagamento) {
        item['sem_escrituracao'] = true;
      } else {
        for (const pag of item.liquidacao.pagamentos) {
          if ((pag.anulacao || pag.anulado_total) && !ordemPagamento) {
            item['sem_escrituracao'] = true;
          } else {
            item['sem_escrituracao'] = false;
          }
        }
      }
    } else {
      item['sem_escrituracao'] = false;
    }

  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private inicializaVariavel() {
    if (this.currentActionRoute === 'novo') {
      this.listaRetencoes = new Array<RetencaoResto>();

      this.liquidacaoService.ultimaDataLiquidada(this.login.exercicio.id, this.login.orgao.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((dataLiq) => {
          if (dataLiq === 0) {
            this.entidade.data_liquidacao = new Date();
          } else {
            this.entidade.data_liquidacao = new DateFormatPipe().transform(dataLiq, []);
          }
          this.entidadeForm.get('data_liquidacao').setValue(this.entidade.data_liquidacao);
          this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidade.data_liquidacao)?.split('-')?.[1]);
        });

      this.activatedRoute.params.pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (params: any) => {
            if (params.hasOwnProperty('empenho')) {
              const parametros = {};
              parametros['id'] = params['empenho'];
              parametros['relations'] = 'favorecido,compra,contrato.aditamentos,contrato.prazo,compra.rcms.prazo_pagamento,licitacao,modalidade,convenio,orgao';
              this.empenhoService.obter(parametros)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(
                  (entidade) => {
                    this.redirecionar = true;
                    this.empenhoNumero = entidade.numero;
                    this.empenhoAno = entidade.ano;
                    this.entidadeForm.get('empenho').setValue(entidade);
                    this.entidadeForm.get('historico').setValue(entidade.historico);
                    this.entidadeForm.get('data_vencimento').setValue(new DateFormatPipe().transform(entidade.data_vencimento, []));
                    this.entidade.empenho = entidade;
                    this.calcularPrazo(entidade);
                    if (this.entidade.empenho) {
                      this.loadTotalizadores();
                      this.obterUltimaParcela();
                    }
                  }, () => this.sair());
            }
          });
    }
  }

  public resetarValorFiscal() {
    if (this.entidadeForm.get('documento_fiscal_info').value === 'null') {
      this.entidadeForm.get('documento_fiscal_info').setValue(null)
    }
  }

  public async carregarDocumentosFiscais(edicao?: boolean) {
    this.datepipe = new DatePipe('pt');
    const params = {};

    params['documento_fiscal.orgao.id'] = this.login.orgao.id;
    params['relations'] = 'documento_fiscal';
    const favorecido = (this.entidade.id ? this.entidade.empenho.favorecido
      : this.entidadeForm.get('empenho').value ? this.entidadeForm.get('empenho').value['favorecido'] : null);
    if (!favorecido) return;

    params['documento_fiscal.favorecido.id'] = favorecido.id
    const vencimento: Date = this.entidade.id && !edicao ? this.entidade.data_vencimento : this.entidadeForm.get('data_vencimento').value;
    let data: Date = this.entidade.id && !edicao ? this.entidade.data_liquidacao : this.entidadeForm.get('data_liquidacao').value;
    if (data) {
      data = new Date(data.getFullYear(), data.getMonth() - (data.getMonth() == 1 ? 1 : 2), 1, 0, 0, 0);
    }

    if (vencimento && data) {
      params['documento_fiscal.data_emissao$ge'] = this.datepipe.transform(data, 'yyyy-MM-dd');
      params['documento_fiscal.data_emissao$le'] = this.datepipe.transform(vencimento, 'yyyy-MM-dd');
    }

    if (vencimento && favorecido) {
      this.listaDocumentos = (await this.docFiscalService.filtrar(0, -1, params).toPromise()).content;
    }

    this.calcularPrazo(this.entidade.empenho);
  }

  public async atualizarEmpenho() {
    if (this.empenhoNumero > 0 && this.empenhoAno && !this.entidade.id) {
      const param = {};
      param['numero'] = this.empenhoNumero;
      param['ano'] = this.empenhoAno;
      param['orgao.id'] = this.login.orgao.id;
      param['exercicio_id'] = this.login.exercicio.id;
      param['relations'] = 'favorecido,favorecido.tipo,favorecido.cidade,contrato.aditamentos,contrato.prazo,compra.rcms.prazo_pagamento';

      this.entidade.empenho = await this.empenhoService.obter(param).pipe(takeUntil(this.unsubscribe)).toPromise();
      if (!this.entidade.empenho) {
        Mensagem.alerta(this.messageService, 'Empenho não localizado!');
        this.entidadeForm.get('empenho').setValue(null);
        this.loadTotalizadores();
        return;
      }

      if (!await this.validarEntradaAlmoxarifado()) {
        this.messageService.add({ severity: 'warn', summary: 'Erro', detail: `Não foi encontrado entrada deste empenho no almoxarifado/patrimônio. Favor verificar.` });
        this.entidadeForm.get('empenho').setValue(null);
        this.loadTotalizadores();
      }

      if (this.entidade.empenho) {
        this.loadTotalizadores();
        this.obterUltimaParcela();
        this.calcularPrazo(this.entidade.empenho);

        if (!this.entidadeForm.get('data_liquidacao').value) {
          this.entidadeForm.get('data_liquidacao').setValue(new Date());
        }

        this.entidadeForm.get('empenho').setValue(this.entidade.empenho);
        this.entidadeForm.get('data_vencimento').setValue(new DateFormatPipe().transform(this.entidade.empenho.data_vencimento, []));
        this.entidadeForm.get('historico').setValue(this.entidade.empenho.historico);
        this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidade.data_liquidacao)?.split('-')?.[1]);
      }

      //Verifica vinculo com o compras
      if (this.entidade.empenho.compra) {
        this.movimentoEstoqueService.filtrar(0, -1, {
          relations: 'itens,compra,compra.exercicio,compra.contrato,tipo,recebedor,itens.produto_unidade,'
            + 'itens.produto_unidade.produto,itens.produto_unidade.produto.material,itens.produto_unidade.unidade',
          excluido: false, 'orgao.id': this.login.orgao.id, desvinculado_liquidacao: true,
          'compra.id': this.entidade.empenho.compra.id
        }).pipe(takeUntil(this.unsubscribe)).subscribe({
          next: (res) => {
            this.movimentosPendentes = res.content;
            if (this.movimentosPendentes.length > 0) {
              $('#dlgMovimento').modal('show');
            }
          }, error: (e) => {
            toastr.warning(`Erro ao procurar pendências de movimentação!`)
          }
        });
      }

      if (this.entidade?.empenho?.favorecido?.tipo.tce === '01' &&
        (this.login.parametro['contabil'].utilizar_aliquota_ir
          || this.login.parametro['contabil'].utilizar_aliquota_cofins
          || this.login.parametro['contabil'].utilizar_aliquota_csll
          || this.login.parametro['contabil'].utilizar_aliquota_pis_pasep)) {
        if (!this.entidade.empenho.favorecido.conferencia_simples) {//Se nunca foi validado, obrigatóriamente tem que fazer a primeira validação.
          $('#dlgSimplesNacional').modal('show');
        } else if (this.entidade.empenho.favorecido.simples_nacional) {
          //Se forem simples, e a ultima validação for antes de janeiro do ano, 
          if (this.entidade.empenho.favorecido.conferencia_simples < new Date(this.login.exercicio.ano, 1, 1, 0, 0, 0)) {
            $('#dlgSimplesNacional').modal('show');
          }
        }
      }
    }
  }

  public calcularPrazo(empenho: EmpenhoResto) {
    if (empenho?.contrato && empenho?.contrato?.prazo) {
      this.vencimento = empenho.contrato.prazo.nome;
    } else if (empenho?.compra?.rcms && empenho?.compra?.rcms?.prazo_pagamento) {
      this.vencimento = empenho.compra.rcms.prazo_pagamento.nome;
    } else {
      this.vencimento = 'À VISTA';
    }
  }

  public vincularMovimento(movimento: MovimentoEstoque) {
    movimento.desvinculado_liquidacao = false;
    this.movimentoEstoque = movimento;
    this.entidadeForm.get('valor_liquidado').setValue('' + this.saldoTotalMovimento());
    this.entidade.valor_liquidado = this.saldoTotalMovimento();
    this.verificarValorLiquidado();
    this.verificarParcela();
    $('#dlgMovimento').modal('hide');
  }

  public quantidadeTotalMovimento() {
    let valor = 0;
    if (this.movimentoEstoque.itens)
      for (let item of this.movimentoEstoque.itens) {
        valor += item.qtde ? parseFloat(item.qtde.toString()) : 0;
      }
    return valor;
  }

  public saldoTotalMovimento() {
    let valor = 0;
    if (this.movimentoEstoque.itens)
      for (let item of this.movimentoEstoque.itens) {
        let valor_unitario = item.valor_unitario ? parseFloat(item.valor_unitario.toString()) : 0;
        let qtde = item.qtde ? parseFloat(item.qtde.toString()) : 0;
        valor += valor_unitario * qtde;
      }
    return valor;
  }

  async atualizarSimples(simples: boolean) {
    const fav: Favorecido = this.entidade.empenho.favorecido;
    fav.simples_nacional = simples;
    fav.conferencia_simples = new Date();
    await this.favorecidoServico.atualizar(fav).toPromise();
    this.entidade.empenho.favorecido = fav;
  }

  public async validarEntradaAlmoxarifado() {
    if (this.login.verificar_liquidacao_estoque) {
      if (this.entidade.empenho.subelemento.substring(4, 6) === '30' || this.entidade.empenho.subelemento.substring(4, 6) === '52') {
        const movimento = await this.movimentoEstoqueService.obter({ excluido: false, 'orgao.id': this.login.orgao.id, compra_id: this.entidade.empenho.compra ? this.entidade.empenho.compra.id : 0 }).pipe(takeUntil(this.unsubscribe)).toPromise();
        if (!movimento) {
          return false;
        }
      }
    }
    return true;
  }

  // private async confirmarValorPre() {
  // if (!this.entidade.id && +this.entidadeForm.get('valor_liquidado').value !== this.saldo) {
  //   return new Promise((resolve, reject) => {
  //     this.confirmationService.confirm({
  //       header: 'Atenção!',
  //       message:
  //         `O valor liquidado será alterado. Deseja realizar essa alteração?`,
  //       accept: () => {
  //         resolve(true);
  //       },
  //       reject: () => {
  //         resolve(false);
  //       }
  //     });
  //   });
  // }
  // }

  /* Se o usuario informou um valor diferente do empenhado
   * atribui a parcela como sendo a primeira
   */
  public verificarParcela() {
    if (+(+this.empenhado - +this.liquidado).toFixed(2) + ((this.entidade.valor_liquidado ? +this.entidade.valor_liquidado : 0) - +this.entidadeForm.get('valor_liquidado').value) < 0) {
      Mensagem.alerta(this.messageService, 'Não há saldo disponível para esta liquidação!');
    } else {
      this.obterUltimaParcela();
    }
  }

  public async verificarValorLiquidado() {
    if (+(+this.empenhado - +this.liquidado).toFixed(2) + ((this.entidade.valor_liquidado ? +this.entidade.valor_liquidado : 0) - +this.entidadeForm.get('valor_liquidado').value) < 0) {
      return;
    }
    // const confirmar = await this.confirmarValorPre();
    // if (confirmar) {
    //   this.preliquidacoes = null;
    //   this.entidadeForm.get('preliquidacao').setValue(null);
    // } else {
    //   this.entidadeForm.get('valor_liquidado').setValue(this.saldo.toString());
    // }
  }

  public abrirDocsFiscais() {
    const param = {};
    if (this.entidade?.empenho?.contrato) {
      param['tipo'] = 'C';
      param['id'] = this.entidade.empenho.contrato.id;
    } else {
      param['tipo'] = 'F';
      param['id'] = this.entidade.empenho.favorecido.id;
    }
    param['doc'] = this.entidadeForm.get('documento').value;

    let favorecido = this.entidade.empenho.favorecido

    this.documentoFiscalService.filtrar(1, 1, { 'orgao.id': this.login.orgao.id, 'numero_documento': this.entidadeForm.get('documento').value, 'favorecido.cpf_cnpj': favorecido.cpf_cnpj }).subscribe(async e => {
      if (e.content?.length > 0) {
        const text = `Número de documento ${this.entidadeForm.get('documento').value} existente para o CNPJ informado!'`;
        toastr.info(text, 'Validação');
      } else {
        const url = this.router.createUrlTree([`/documentos-fiscais/novo/${param['tipo']}/${param['id']}/${param['doc']}`]);
        window.open('#/' + url.toString(), '_blank');
      }
    });
  }

  private async obterUltimaParcela() {
    this.liquidacaoService.obterUltimaParcela(
      this.entidade.empenho.numero, this.login.exercicio.id, this.login.orgao.id,this.entidade.empenho.ano
    ).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res: any) => {
          console.log(res)
          this.entidade.parcela = res;
          if (!this.entidade.id) {
            if (+this.empenhado > +this.entidadeForm.get('valor_liquidado').value && res == 0) {
              res = 1;
            } else {
              if (+res > 0) {
                res = +res + 1;
              }
            }
            this.entidade.parcela = res;
            this.entidadeForm.get('parcela').setValue(res);
            if (!this.vincularPre)
              this.obterPreLiquidacao(this.entidade.empenho);
          }
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
      );
  }

  public async obterPreLiquidacao(empenho: EmpenhoResto) {
    const param = {};
    param['empenho_id'] = empenho.id;
    param['exercicio.id'] = this.login.exercicio.id;
    param['orgao.id'] = this.login.orgao.id;
    param['liquidacoes.id$null'] = '';
    param['anulacao'] = false;
    param['anulado_total'] = false;
    param['orderBy'] = 'id$ASC';
    param['relations'] = 'usuario_cadastro,empenho,liquidacoes';

    const pre = await this.preService.extendido(1, -1, param).toPromise();
    const encontrouPre = this.getVinculoPreLiquidacao(pre.content);
    this.preliquidacoes = pre.content;

    // if (pre && pre.content.length) {
    //   this.preliquidacoes = pre.content;
    //   this.confirmationService.confirm({
    //     header: 'Atenção',
    //     message: `Existe(m) pré liquidação(ões) para este empenho!<br>Deseja vincular esta liquidação a uma pré liquidação?`,
    //     acceptLabel: 'Sim',
    //     rejectLabel: 'Não',
    //     accept: this.listarPreLiquidacoes,
    //     reject: () => { this.vincularPre = false; }
    //   });
    // }
  }

  // private listarPreLiquidacoes() {
  //   $('#dlgconsultaPreLiquidacaoResto').modal('show');
  // }

  public selecionarPre(pre: PreLiquidacaoResto): void {
    this.entidadeForm.get('preliquidacao').setValue(pre);
    const anulado = pre['total_anulado'] ? +pre['total_anulado'] : 0;
    const valor_liquidado = +pre.valor_liquidado + anulado;
    this.entidadeForm.get('valor_liquidado').setValue(valor_liquidado.toString());
    this.verificarParcela();
    // this.entidadeForm.get('data_vencimento').setValue(new DateFormatPipe().transform(pre.data_vencimento, []));
    this.entidadeForm.get('historico').setValue(pre.historico);
    this.entidadeForm.get('documento').setValue(pre.documento);
    this.vincularPre = true;

    $('#dlgconsultaPreLiquidacaoResto').modal('hide');
  }

  private async loadTotalizadores() {
    this.empenhado = 0;
    this.liquidado = 0;
    this.pago = 0;
    this.totalCancelado = 0;

    if (!this.entidade.empenho) {
      return;
    }

    let totalizadores = await this.empenhoService.totalizadores(
      this.entidade.empenho.ano,
      this.entidade.empenho.orgao.id,
      this.login.exercicio.id,
      this.entidade.empenho.id, this.entidade?.preliquidacao?.id).toPromise();

    if (totalizadores) {
      totalizadores = totalizadores.content[0] ? totalizadores.content[0] : totalizadores.content;
      this.empenhado = totalizadores.total_empenhado;
      this.liquidado = totalizadores.total_liquidado;
      this.pago = totalizadores.total_pago;
      this.totalCancelado = +totalizadores.total_cancelado_nao_processado + +totalizadores.total_cancelado_processado;
      this.totalPreLiquidado = totalizadores.total_preliquidado_aberto;
    }

    if (!this.entidadeForm.get('id').value) {
      const preLiq: IPreLiquidacaoExtendido = this.entidadeForm.get("preliquidacao")?.value;
      const totalPreliquidado: number = preLiq?.id ? (+this.totalPreLiquidado - (+preLiq?.valor_liquidado + +preLiq?.total_anulado)) : this.totalPreLiquidado;
      const saldo = +(+this.empenhado - +this.liquidado - +this.totalCancelado - +totalPreliquidado).toFixed(2);
      this.saldo = saldo;
      this.entidadeForm.get('valor_liquidado').setValue(saldo.toString());
      if (saldo <= 0 && +totalPreliquidado > 0) {
        toastr.warning('Não há saldo para realizar essa liquidação sem vinculo com alguma pré liquidação!');
        return;
      }
      if (saldo <= 0) {
        Mensagem.alerta(this.messageService, 'Não há saldo disponível para esta liquidação!');
      }
    }
  }

  public imprimir() {
    const parametros = {};
    parametros['relations'] = 'empenho,empenho.contrato,empenho.favorecido,empenho.licitacao,empenho.modalidade,empenho.favorecido.contas,empenho.favorecido.contas.banco,documento_fiscal_info,documento_fiscal_info.documento_fiscal';
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id'] = this.entidade.id;
    this.liquidacaoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        this.assinaturaService.obter()
        new NotaLiquidacaoResto(this.assinaturaService).imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  private async validarContratoVencido() {
    let dataFimContrato = this.entidade?.empenho?.contrato?.data_termino
    let dataFimAditamento = this.entidade?.empenho?.contrato?.aditamentos[0]?.data_termino
    let dataLiquidacao = this.entidadeForm.get('data_liquidacao').value

    this.entidade?.empenho?.contrato?.aditamentos.forEach(ad => {
      if (ad.data_termino > dataFimAditamento) {
        dataFimAditamento = ad.data_termino
      }
    });

    if (!dataFimAditamento) dataFimAditamento = dataFimContrato

    if (!this.entidade.id && this.entidade.empenho.contrato
      && (new Date(dataLiquidacao) > new Date(dataFimAditamento) && new Date(dataLiquidacao) > new Date(dataFimContrato))) {
      return new Promise((resolve, reject) => {
        this.confirmationService.confirm({
          header: 'Atenção contrato vencido',
          icon: 'pi pi-exclamation-triangle',
          message:
            `Empenho ${this.entidade.empenho.numero}/${this.entidade?.empenho?.ano} de contrato ${this.entidade.empenho.contrato.numero}
                 vencido em ${new DateFormatPipe().transform(dataFimAditamento ? dataFimAditamento : dataFimContrato, ['local'])}. Deseja continuar?`,
          accept: () => {
            resolve(true);
          },
          reject: () => {
            resolve(false);
          }
        });
      });
    } else { return true; }
  }

  // public salvarLiquidacao() {
  //   if (this.entidade?.empenho?.contrato?.aditamentos?.length > 0) {
  //     const contrato = this.entidade?.empenho?.contrato
  //     let adita
  //     contrato.aditamentos.forEach((item) => {
  //       if (!adita) {
  //         adita = item
  //       } else if (+adita?.valor_total < +item?.valor_total) {
  //         adita = item
  //       }
  //     })
  //     if (new DateFormatPipe().transform(adita?.data_termino, []) < this.entidade?.data_liquidacao) {
  //       this.confirmationService.confirm({
  //         message: `Contrato se encontra vencido (${new DatePipe('pt').transform(adita?.data_termino, 'dd/MM/yyyy')}), deseja continuar?`,
  //         header: 'Atenção contrato vencido',
  //         icon: 'pi pi-exclamation-triangle',
  //         acceptLabel: 'Sim',
  //         rejectLabel: 'Não',
  //         accept: () => {
  //           this.submitForm();
  //         },
  //         reject: () => {
  //           toastr.warning('Liquidação não salva!')
  //         }
  //       });
  //     } else {
  //       this.submitForm();
  //     }
  //   } else {
  //     if (new DateFormatPipe().transform(this.entidade?.empenho?.contrato?.data_termino, []) < this.entidade?.data_liquidacao) {
  //       this.confirmationService.confirm({
  //         message: `Contrato se encontra vencido (${new DatePipe('pt').transform(this.entidade?.empenho?.contrato?.data_termino, 'dd/MM/yyyy')}), deseja continuar?`,
  //         header: 'Atenção contrato vencido',
  //         icon: 'pi pi-exclamation-triangle',
  //         acceptLabel: 'Sim',
  //         rejectLabel: 'Não',
  //         accept: () => {
  //           this.submitForm();
  //         },
  //         reject: () => {
  //           toastr.warning('Liquidação não salva!')
  //         }
  //       });
  //     } else {
  //       this.submitForm();
  //     }
  //   }
  // }

  public async calcularRetencoesAliquotas() {
    const favorecido: Favorecido = this.entidadeForm.get('empenho').value?.favorecido

    if (favorecido && (favorecido.simples_nacional || favorecido.terceiro_setor || favorecido.autarquia)) return

    if (this.entidade.parcela == 0 && this.entidade.id ? 'readonly' : null) return

    // Verifica se o calculo deve ser realizado
    if (this.login.parametro.contabil.utilizar_aliquota_ir || this.login.parametro.contabil.utilizar_aliquota_csll || this.login.parametro.contabil.utilizar_aliquota_cofins || this.login.parametro.contabil.utilizar_aliquota_pis_pasep) {
      if (!this.entidadeForm.get('data_vencimento').value) {
        toastr.info('Informe a data de vencimento!')
        return;
      }

      const valorBaseIR = this.entidadeForm.get('valor_base_ir').value ? this.entidadeForm.get('valor_base_ir').value : this.entidade.valor_base_ir
      const valorBaseCSLL = this.entidadeForm.get('valor_base_csll').value ? this.entidadeForm.get('valor_base_csll').value : this.entidade.valor_base_csll
      const valorBaseCOFINS = this.entidadeForm.get('valor_base_cofins').value ? this.entidadeForm.get('valor_base_cofins').value : this.entidade.valor_base_cofins
      const valorBasePIS = this.entidadeForm.get('valor_base_pis').value ? this.entidadeForm.get('valor_base_pis').value : this.entidade.valor_base_pis
      const valorLiquidacao = this.entidadeForm.get('valor_liquidado').value ? this.entidadeForm.get('valor_liquidado').value : this.entidade.valor_liquidado
      const empenho: EmpenhoResto = this.entidadeForm.get('empenho').value ? this.entidadeForm.get('empenho').value : this.entidade.empenho

      if ((valorBaseIR > valorLiquidacao) || (valorBaseCSLL > valorLiquidacao) || (valorBaseCOFINS > valorLiquidacao) || (valorBasePIS > valorLiquidacao)) {
        toastr.warning('O valor da base de calculo não pode ser maior que o Valor da Liquidação.<br>Por favor, revise e tente novamente!')
        return;
      }

      const exeEmpPai: Exercicio = await this.exercicioService.obter({ ano: empenho.ano }).toPromise()

      this.compra = await this.compraService.obter({ 'empenho_resto.id': empenho.id, excluido: false, 'orgao_id': this.login.orgao.id, 'exercicio_id': exeEmpPai.id, relations: 'empenho_resto,itens.produto_unidade.produto.aliquota,itens.produto_unidade.produto.material.aliquota' }).toPromise()

      // Busca nos itens da compra a primeira aliquota que encontrar
      let aliquota: Aliquota
      for (const item of this.compra.itens) {
        if (item.produto_unidade.produto?.aliquota) {
          aliquota = item.produto_unidade.produto?.aliquota
          break;
        } else if (item.produto_unidade.produto?.material?.aliquota) {
          aliquota = item.produto_unidade.produto?.material?.aliquota
          break;
        }
      }

      // Verifica se todos os itens da compra estão na mesma aliquota
      if (aliquota) {
        const aliquotaDiferente = this.compra.itens.filter(item => {
          if (item.produto_unidade.produto?.aliquota) {
            if (item.produto_unidade.produto?.aliquota.id !== aliquota.id) return item
          } else if (item.produto_unidade.produto?.material?.aliquota) {
            if (item.produto_unidade.produto?.material?.aliquota.id !== aliquota.id) return item
          }
        });

        if (aliquotaDiferente.length > 0) {
          // se tem mais de uma aliquota, abre o modal para selecionar as quantidades dos itens
          $('#dlgItensCompra').modal('show')

          this.entidadeForm.get('valor_base_ir').setValue(null)
          this.entidadeForm.get('valor_base_csll').setValue(null)
          this.entidadeForm.get('valor_base_cofins').setValue(null)
          this.entidadeForm.get('valor_base_pis').setValue(null)

          toastr.info('Não foi possível gerar retenções automaticamente.<br>Itens da compra possui mais de um tipo de aliquota cadastrada.<br>Informe as quantidades dos itens recebidos para calcular automaticamente!');
          return;
        }
      } else {
        toastr.info('Não foi possível gerar retenções automaticamente.<br>Não foi encontrado alíquota vinculada aos itens da compra.');
        return;
      }

      if (this.login.parametro.contabil.utilizar_aliquota_ir) {
        this.entidadeForm.get('valor_base_ir').setValue(valorBaseIR ? valorBaseIR : valorLiquidacao)
        this.calcularRetencao(valorBaseIR ? valorBaseIR : valorLiquidacao, aliquota.ir, 'IR')
      }

      if (this.login.parametro.contabil.utilizar_aliquota_csll) {
        this.entidadeForm.get('valor_base_csll').setValue(valorBaseCSLL ? valorBaseCSLL : valorLiquidacao)
        this.calcularRetencao(valorBaseCSLL ? valorBaseCSLL : valorLiquidacao, aliquota.csll, 'CSLL')
      }

      if (this.login.parametro.contabil.utilizar_aliquota_cofins) {
        this.entidadeForm.get('valor_base_cofins').setValue(valorBaseCOFINS ? valorBaseCOFINS : valorLiquidacao)
        this.calcularRetencao(valorBaseCOFINS ? valorBaseCOFINS : valorLiquidacao, aliquota.cofins, 'COFINS')
      }

      if (this.login.parametro.contabil.utilizar_aliquota_pis_pasep) {
        this.entidadeForm.get('valor_base_pis').setValue(valorBasePIS ? valorBasePIS : valorLiquidacao)
        this.calcularRetencao(valorBasePIS ? valorBasePIS : valorLiquidacao, aliquota.pis_pasep, 'PIS')
      }
    }
  }

  public async calcularRetencao(valor: number, aliquotaValor: number, aliquotaNome: 'IR' | 'CSLL' | 'COFINS' | 'PIS') {
    const tipoFavorecido = this.entidade.empenho.favorecido.tipo.nome === 'CPF - PESSOA FÍSICA' ? 'PF' : this.entidade.empenho.favorecido.tipo.nome === 'CNPJ - PESSOA JURÍDICA' ? 'PJ' : ''

    let fichaExtra: FichaExtra = await this.fichaExtraService.obter({ exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id, retencao: true, excluida: false, 'ficha.tipo_imposto': `${aliquotaNome}`, 'ficha.tipo_favorecido': tipoFavorecido, relations: 'plano,favorecido,ficha' }).toPromise()

    if (!fichaExtra) {
      fichaExtra = await this.fichaExtraService.obter({ exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id, retencao: true, excluida: false, 'ficha.tipo_imposto': `${aliquotaNome}`, 'ficha.tipo_favorecido$null': 'true', relations: 'plano,favorecido,ficha' }).toPromise()
    }

    if (!fichaExtra) {
      toastr.info(`Não foi possível gerar retenção para ${aliquotaNome}.`, 'Ficha não encontrada')
      return;
    }

    const retencaoIgual = this.listaRetencoes.find(item => item.ficha?.id === fichaExtra?.id)

    if (retencaoIgual) return

    const retencao = new RetencaoResto()
    retencao.valor_retido = +valor * (+aliquotaValor / 100)
    retencao.ficha = fichaExtra
    retencao.liquidacao = this.entidade
    retencao.data_vencimento = this.entidadeForm.get('data_vencimento').value
    retencao.anulado = false
    retencao.editavel = false
    retencao['salvo'] = false

    if (aliquotaNome === 'IR') {
      retencao.base_IR = +valor
      retencao.valor_IR = +valor * (+aliquotaValor / 100)
    } else if (aliquotaNome === 'CSLL') {
      retencao.base_csll = +valor
      retencao.valor_csll = +valor * (+aliquotaValor / 100)
    } else if (aliquotaNome === 'COFINS') {
      retencao.base_cofins = +valor
      retencao.valor_cofins = +valor * (+aliquotaValor / 100)
    } else if (aliquotaNome === 'PIS') {
      retencao.base_pp = +valor
      retencao.valor_pp = +valor * (+aliquotaValor / 100)
    }

    this.listaRetencoes.push(retencao)
  }

  public baseRetencoes(retencoes: { valorBase: number; ir: number; csll: number; cofins: number; pis: number; }) {
    const valorLiquidacao: number = this.entidadeForm.get('valor_liquidado').value ? this.entidadeForm.get('valor_liquidado').value : this.entidade.valor_liquidado

    if (+retencoes.valorBase.toFixed(2) > valorLiquidacao) {
      toastr.warning(`As quantidades selecionada ultrapassam o Valor Total da Liquidação (${this.funcaoService.formatarMoedaPtBr(valorLiquidacao)}).<br>Por favor, revise e tente novamente!`)
      return;
    }

    $('#dlgItensCompra').modal('hide')

    if (this.login.parametro.contabil.utilizar_aliquota_ir) {
      this.entidadeForm.get('valor_base_ir').setValue(retencoes.valorBase + '')
      this.calcularRetencao(retencoes.valorBase, (retencoes.ir / retencoes.valorBase) * 100, 'IR')
    }

    if (this.login.parametro.contabil.utilizar_aliquota_csll) {
      this.entidadeForm.get('valor_base_csll').setValue(retencoes.valorBase + '')
      this.calcularRetencao(retencoes.valorBase, (retencoes.csll / retencoes.valorBase) * 100, 'CSLL')
    }

    if (this.login.parametro.contabil.utilizar_aliquota_cofins) {
      this.entidadeForm.get('valor_base_cofins').setValue(retencoes.valorBase + '')
      this.calcularRetencao(retencoes.valorBase, (retencoes.cofins / retencoes.valorBase) * 100, 'COFINS')
    }

    if (this.login.parametro.contabil.utilizar_aliquota_pis_pasep) {
      this.entidadeForm.get('valor_base_pis').setValue(retencoes.valorBase + '')
      this.calcularRetencao(retencoes.valorBase, (retencoes.pis / retencoes.valorBase) * 100, 'PIS')
    }
  }

  private getVinculoPreLiquidacao(preLiquidacoes: IPreLiquidacaoExtendido[]) {
    //entrontra as pre liquidações que possuem o mesmo valor que a liquidação
    const preLiquidacaoValorIgual = preLiquidacoes.filter((pre) => +(+pre?.valor_liquidado + +pre?.total_anulado) === +this.entidadeForm.get('valor_liquidado').value);

    if (preLiquidacaoValorIgual?.length === 1) { // se encontrar apenas uma vincula ela
      this.entidadeForm.get('preliquidacao').setValue(preLiquidacaoValorIgual[0]);
      return;
    } else if (preLiquidacaoValorIgual?.length > 1) { // se encontrar mais de uma com o mesmo valor tenta procurar pelo documento
      const preLiquidacaoDocumentoIgual = this.validaDocumentoIgual(preLiquidacaoValorIgual);
      if (preLiquidacaoDocumentoIgual) { //encontrou documento igual
        this.entidadeForm.get('preliquidacao').setValue(preLiquidacaoDocumentoIgual);
        return;
      } else { // se nao encontrar o documento igual vincul a com valor igual com menor ID.
        this.entidadeForm.get('preliquidacao').setValue(preLiquidacaoValorIgual[0]);
        return;
      }
    }
  }

  private validaDocumentoIgual(preLiqs: PreLiquidacaoResto[]): PreLiquidacaoResto | undefined {
    let documento: string = this.entidadeForm.get('documento').value; // doc normal do valor digitado
    let doc = this.entidadeForm.get('documento').value.replace(/^(0+)(\d)/g, "$2"); // sem os zeros a esquerda
    let docZero: string = doc;
    let docPonto: string = this.entidadeForm.get('documento').value;

    docPonto = docPonto.split('.').join(''); // valor do input digitado sem os pontos
    let docPontoZero = docPonto.split('.').join('').replace(/^(0+)(\d)/g, "$2"); // sem os pontos e sem os zeros
    let docZeroPonto = this.entidadeForm.get('documento').value.split('.').join('').replace(/^(0+)(\d)/g, "$2").trim(); // sem espaços desnecessarios

    const documentoLiq = [documento, docPontoZero, docZero, docPonto, docZeroPonto];

    const documentosIguais = []
    for (const pre of preLiqs) {
      let documento: string = pre?.documento; // doc normal do valor digitado
      let doc = pre.documento?.replace(/^(0+)(\d)/g, "$2"); // sem os zeros a esquerda
      let docZero: string = doc;
      let docPonto: string = pre?.documento;

      docPonto = docPonto?.split('.')?.join(''); // valor do input digitado sem os pontos
      let docPontoZero = docPonto?.split('.')?.join('')?.replace(/^(0+)(\d)/g, "$2"); // sem os pontos e sem os zeros
      let docZeroPonto = pre?.documento?.split('.')?.join('')?.replace(/^(0+)(\d)/g, "$2")?.trim(); // sem espaços desnecessarios

      const documentoPre = `${documento},${docPontoZero},${docZero},${docPonto},${docZeroPonto}`;

      for (const docLiq of documentoLiq) {
        if (documentoPre.includes(docLiq)) {
          documentosIguais.push(pre);
          break;
        }
      }
    }

    const preSelecionada = documentosIguais?.[0];

    return preSelecionada;
  }

}
