import { Injectable, OnDestroy } from '@angular/core';
import { AnexoLoaService } from '../service/anexo-loa.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuncaoService, LoginContabil, Exercicio, Relatorio, GlobalService } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class Anexo9 implements OnDestroy {
  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLoaService) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // tslint:disable: max-line-length
  public montarRelatorio(exercicio: Exercicio, orgaos: number[]) {
    this.anexoServico.obterAnexo9(exercicio.id, orgaos, this.login.ppa.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {

        Relatorio.imprimirPersonalizado(
          'DEMONSTRATIVO DA DESPESA POR ÓRGÃOS E FUNÇÕES'
          , this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          this.conteudo(dados, exercicio),
          'portrait', 'ANEXO 9',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private conteudo(dados: any[], exercicio: Exercicio): {}[] {

    // monta o cabecalho
    const registros: {}[] = [[{
      text: `EXERCÍCIO ${exercicio.ano}`,
      alignment: 'center', bold: true, colSpan: 4, border: [true, true, true, false]
    }, '', '', ''],
    [{
      text: 'FUNÇÃO / ÓRGÃO',
      alignment: 'center',
      bold: true, fontSize: 8,
    }, {
      text: 'TESOURO',
      alignment: 'center',
      bold: true, fontSize: 8
    }, {
      text: 'OUTRAS FONTES',
      alignment: 'center',
      bold: true, fontSize: 8,
    }, {
      text: 'TOTAL',
      alignment: 'center',
      bold: true, fontSize: 8
    }]
    ];

    // monta o agrupamento do relatório
    const gruposFuncao = this.funcaoService.agrupar(dados, 'nome_funcao',
      ['ordinario', 'outra']);
    let total1 = 0;
    let total2 = 0;
    for (const funcao of gruposFuncao) {
      registros.push(
        [{
          text: funcao.grupo, fontSize: 8,
          bold: true, border: [true, true, true, true]
        },
        {
          text: funcao.totalizadores[`ordinario`] > 0 ? this.funcaoService.convertToBrNumber(funcao.totalizadores[`ordinario`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, true, false, true]
        },
        {
          text: funcao.totalizadores[`outra`] > 0 ? this.funcaoService.convertToBrNumber(funcao.totalizadores[`outra`]) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, true, false, true]
        },
        {
          text: this.funcaoService.convertToBrNumber(+funcao.totalizadores[`ordinario`] + +funcao.totalizadores[`outra`]),
          alignment: 'right', bold: true, fontSize: 8, border: [true, true, true, true]
        }
        ]);
      total1 += +funcao.totalizadores[`ordinario`];
      total2 += +funcao.totalizadores[`outra`];
      // lista os registros do relatorio
      for (const registro of funcao.registros) {
        registros.push([
          {
            text: `${registro.codigo_unidade} ${registro.nome_unidade}`, fontSize: 8, border: [true, false, false, false]
          },
          {
            text: registro.ordinario > 0 ? this.funcaoService.convertToBrNumber(registro.ordinario) : '', alignment: 'right',
            fontSize: 8, border: [true, false, false, false]
          },
          {
            text: registro.outra > 0 ? this.funcaoService.convertToBrNumber(registro.outra) : '', alignment: 'right',
            fontSize: 8, border: [true, false, false, false]
          },
          {
            text: this.funcaoService.convertToBrNumber(+registro.ordinario + +registro.outra),
            alignment: 'right', fontSize: 8, border: [true, false, true, false]
          }
        ]);
      }
    }

    registros.push([
      {
        text: 'TOTAL', fontSize: 8, border: [true, true, true, true], margin: [0, 0, 0, 5]
      },
      {
        text: total1 > 0 ? this.funcaoService.convertToBrNumber(total1) : '', alignment: 'right',
        fontSize: 8, border: [true, true, true, true]
      },
      {
        text: total2 > 0 ? this.funcaoService.convertToBrNumber(total2) : '', alignment: 'right',
        fontSize: 8, border: [true, true, true, true]
      },
      {
        text: this.funcaoService.convertToBrNumber(+total1 + +total2),
        alignment: 'right', fontSize: 8, border: [true, true, true, true]
      }
    ]);

    return [{
      layout: 'linhas',
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 60, 60, 60],
        body: registros
      }
    }];
  }


}
