// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Licitacao } from './licitacao.model';

export class LicitacaoAnexo extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public caminho?: string,
    public tipo?: string,
    public documento?: string,
    public descricao?: string,
    public tamanho?: number,
    public licitacao?: Licitacao,
    public editavel?: boolean,
  ) {
    super();
  }

  static converteJson(json: any): LicitacaoAnexo {
    return Object.assign(new LicitacaoAnexo(), json);
  }
}
