import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TagsAta } from '../tags-ata.enum';

declare var $: any;

@Component({
  selector: 'lib-modelo-ata-dlg',
  templateUrl: './modelo-ata-dlg.component.html'
})
export class ModeloAtaDlgComponent implements OnInit {

  @ViewChild('modelo_') campoModelo: ElementRef;
  @Output() callback: EventEmitter<string> = new EventEmitter();
  @Input() public modelo: string;
  public tags: { tag: string, descricao: string }[];

  constructor(
  ) { }

  ngOnInit(): void {
    this.tags = [
      { tag: 'PROCESSO', descricao: TagsAta.PROCESSO },
      { tag: 'NUMERO', descricao: TagsAta.NUMERO },
      { tag: 'MODALIDADE', descricao: TagsAta.MODALIDADE },
      { tag: 'OBJETO', descricao: TagsAta.OBJETO },
      { tag: 'DATA_JULGAMENTO', descricao: TagsAta.DATA_JULGAMENTO },
      { tag: 'HORA_JULGAMENTO', descricao: TagsAta.HORA_JULGAMENTO },
      { tag: 'SALA', descricao: TagsAta.SALA },
      { tag: 'MEMORIAL', descricao: TagsAta.MEMORIAL },
      { tag: 'HISTORICO', descricao: TagsAta.HISTORICO },
      { tag: 'ASSINATURAS', descricao: TagsAta.ASSINATURAS },
      { tag: 'PROPONENTES', descricao: TagsAta.PROPONENTES },
      { tag: 'QUADRO_LANCES', descricao: TagsAta.QUADRO_LANCES },
      { tag: 'CLASSIFICACAO', descricao: TagsAta.CLASSIFICACAO },
      { tag: 'VENCEDORES', descricao: TagsAta.VENCEDORES },
      { tag: 'COMISSAO_PREGOEIRO', descricao: TagsAta.COMISSAO_PREGOEIRO },
      { tag: 'COMISSAO_MEMBROS', descricao: TagsAta.COMISSAO_MEMBROS },
      { tag: 'OBSERVACAO', descricao: TagsAta.OBSERVACAO },
      { tag: 'RECURSO', descricao: TagsAta.RECURSO },
      { tag: 'PREGOEIRO_EQUIPE_DE_APOIO', descricao: TagsAta.PREGOEIRO_EQUIPE_DE_APOIO },
      { tag: 'CLASSIFICACAO_PROPOSTA_INICIAL', descricao: TagsAta.CLASSIFICACAO_PROPOSTA_INICAL },
    ]
  }

  public fechar() {
    this.callback.emit(this.modelo);
    $('#dialogModeloAta').modal('hide');
  }

  public incluirTag(tag: string) {
    if (!tag) return;
    if (!this.modelo) this.modelo = '';

    const pos: number = this.campoModelo.nativeElement.selectionStart;
    this.modelo = `${this.modelo.substring(0, pos)} <${tag}> ${this.modelo.substring(pos)}`

    setTimeout(() => this.campoModelo ? this.campoModelo.nativeElement.focus() : null, 500);
  }
}
