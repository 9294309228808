import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Convenio, FuncaoService, Login } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { tsXLXS } from 'ts-xlsx-export';

@Component({
  selector: 'lib-convenio-recebimento',
  templateUrl: './convenio-recebimento.component.html',
})
export class ConvenioRecebimentoComponent implements OnInit, OnChanges, OnDestroy {


  protected unsubscribe: Subject<void> = new Subject();

  @Input() lista: Array<any>;
  @Input() convenio: Convenio;
  @Input() login: Login;

  constructor(
    protected confirmationService: ConfirmationService,
    protected funcaoService: FuncaoService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  public exportarXLSX() {
    const listaItens = new Array();
    for (const item of this.lista) {
      const entity = {
        data_recebimento: this.funcaoService.converteDataBR(item.data_recebimento),
        ficha: item.ficha.numero,
        codigo: item.ficha.receita.codigo,
        receita: item.ficha.receita.nome,
        conta_bancaria: `${item.conta.numero_conta} ${item.conta.digito_conta} ${item.conta.nome}`,
        guia: item.guia,
        valor_recebimento: item.valor_recebido
      };
      listaItens.push(entity);
    }
    tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile(`recebimentos - ${this.convenio.numero} ${this.convenio.ano}`);
  }
    
}
