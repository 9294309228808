import { HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseResourceService } from '../../models/services/base-resource.service';
import { ParametroEmail } from '../../entidade/comum/parametro-email.model';

@Injectable({
  providedIn: 'root'
})
export class ParametroEmailService extends BaseResourceService<ParametroEmail> {

  constructor(
    protected injector: Injector
  ) {
    super(`parametros-email`, injector);
  }

  public testar(param: ParametroEmail, email: string) {
    const testeObj = { param, email };
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/testar`, JSON.stringify(testeObj), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }
}
