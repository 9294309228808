// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { ContaBancaria } from './conta-bancaria.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { LiquidacaoResto } from '../contabil/liquidacao-resto.model';
import { ContaBancariaRecurso } from './conta-bancaria-recurso.model';

export class PagamentoResto extends BaseResourceModel {
  constructor(
    public id?: number,
    public mes?: number,
    public data_pagamento?: Date,
    public data_referencia?: Date,
    public especie?: string,
    public documento?: string,
    public historico?: string,
    public valor_pago?: number,
    public valor_retido?: number,
    public valor_liquido?: number,
    public aux?: number,
    public impresso?: boolean,
    public anulacao?: boolean,
    public anulado_total?: boolean,
    public retido?: boolean,
    public conta?: ContaBancaria,
    public exercicio?: Exercicio,
    public orgao?: Orgao,
    public liquidacao?: LiquidacaoResto,
    public conta_recurso?: ContaBancariaRecurso,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public situacao?: string,
    public pagamento_S13?: boolean,
    public valor_tributavel?: number,
    public valor_RRA?: number,
    public juridico_RRA?: boolean,
    public indice_fci_scp?: number,
    public cnpj_fci_scp?: string,
    public porcentagem_scp?: number,
    public pais_pagamento?: number,
    public nif_tributo?: number,
    public pagamento_anulado?: PagamentoResto,
  ) {
    super();
  }

  static converteJson(json: any): PagamentoResto {
    return Object.assign(new PagamentoResto(), json);
  }
}
