import { Component, Input, OnInit } from '@angular/core';
import { RegistroPrecos } from 'eddydata-lib';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
//import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-lrf',
  templateUrl: './licitacao-audesp-lrf.component.html'
})
export class LicitacaoAudespLRFComponent extends BaseLicitacaoAudesp implements OnInit {

  @Input() public entidade: RegistroPrecos;

  public opcoesLRF: { id: '' | 'N' | '16' | '17', nome: string }[];

  constructor(
  ) {
    super()
  }

  ngOnInit(): void {
    this.opcoesLRF = [
      { id: '', nome: 'Selecione' },
      { id: '16', nome: 'Art. 16' },
      { id: '17', nome: 'Art. 17' },
      { id: 'N', nome: 'Não se enquadra nas anteriores' },
    ];
  }

}
