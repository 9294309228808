import { Component, OnInit, Input, AfterViewInit, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as toastr from 'toastr';
import { Subject } from 'rxjs';
import { Login, GlobalService, ContratoHistorico, FuncaoService, TipoContratacao, Contrato, Favorecido, EddyAutoComplete, FavorecidoService } from 'eddydata-lib';
import { ContratoHistoricoService } from '../service/contrato-historico.service';
import { ContratoService, EmpenhoService, LicitacaoService, TombamentoService } from 'administrativo-lib';
import { RcmsService } from '../../rcms/service/rcms.service';
import { CompraService } from '../../compra/service/compra.service';
import { takeUntil } from 'rxjs/operators';
import { MovimentoEstoqueService } from 'almoxarifado-lib';

declare var $: any;

@Component({
  selector: 'app-contrato-historico-dlg',
  templateUrl: './contrato-historico-dlg.component.html'
})
export class ContratoHistoricoDlgComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  protected datepipe: DatePipe;
  public ptBR: any;
  protected unsubscribe: Subject<void> = new Subject();
  public contratoAtualizar: Contrato;
  public msgError: string = '';
  public validacoes = [false, false, false];
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;

  @Input() listaContratacao: TipoContratacao[];

  @Input() entidade: ContratoHistorico;
  @Input() lista: Array<any>;
  @Input() login: Login;
  @Output() alteracaoEvent: EventEmitter<Contrato> = new EventEmitter();

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    protected messageService: MessageService,
    public funcaoService: FuncaoService,
    public historicoService: ContratoHistoricoService,
    public contratoService: ContratoService,
    public licitacaoService: LicitacaoService,
    public rcmsService: RcmsService,
    public compraService: CompraService,
    public empenhoService: EmpenhoService,
    public movimentoEstoqueService: MovimentoEstoqueService,
    public tombamentoService: TombamentoService,
    protected confirmationService: ConfirmationService,
    private favorecidoService: FavorecidoService,
    private globalService: GlobalService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      setTimeout(() => this.globalService.calendarMascara(), 100);
    }
  }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['id', 'nome'], { inativo: false, relations: 'tipo' }, { number: ['id'], text: ['nome', 'cpf_cnpj'] }, () => {
        this.trocaFavorecido();
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
  }

  public async trocaProcesso() {
    if (this.entidade.processo !== this.entidade.contrato.processo) {
      try {
        await this.verificarProcesso();

        //Se passar das validações
        let aviso = '<pre>';
        aviso += 'A troca de número do processo resultará na atualizações do número em diversos locais, como:';
        aviso += '\nLicitação -> Troca de número do processo.';
        aviso += '\nEsse contrato e Outros contratos do mesmo processo licitatório -> Troca de número do processo, data de contabilização <b>PARA HOJE, INCLUSÃO AUTOMÁTICA</b> de histórico de alterações.';
        aviso += '\nCompras dos contratos afetados -> Atualização do número do processo.';
        aviso += '\nMovimentos das compras afetadas -> Atualização do número do processo.';
        aviso += '\nTombamentos dos contratos afetados -> Atualização do número do processo.';
        aviso += '\nEmpenhos dos contratos afetados -> Atualização do número do processo.';
        aviso += '\nRcms dos contratos afetados -> Atualização do número do processo.';
        aviso += '\nRetificação no <b>PNCP</b> será ativada automaticamente para todos contratos, empenhos e licitações já enviados.'
        aviso += '\n\n\nDeseja continuar? <b>Essa ação NÃO PODERÁ ser desfeita</b>.';
        aviso += '</pre>';
        this.confirmationService.confirm({
          message: aviso,
          header: `Autorização`,
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Sim, continuar com troca de processo.',
          rejectLabel: 'Não, reverter troca de processo.',
          key: 'confirmationHistorico',
          accept: async () => {
            this.validacoes[0] = true;
          },
          reject: () => {
            this.validacoes[0] = false;
            this.entidade.processo = this.entidade.contrato.processo;
            toastr.info('Processo revertido para informação original');
          }
        });
      } catch (e) {
        toastr.error(e + '. Alteração de processo revertida!');
        this.entidade.processo = this.entidade.contrato.processo;
      }
    }
  }

  public async trocaTipo() {
    if (this.entidade.tipo !== this.entidade.contrato.tipo) {
      try {
        await this.verificarProcesso();
        await this.verificarPncp();
        let aviso = '<pre>';
        aviso += 'A troca de tipo do processo resultará na atualizações do tipo em diversos locais, como:';
        aviso += '\nLicitação: Troca para <b>' + (this.entidade.tipo == 'REGISTRO_PRECO' ? 'registro de preço' : 'reserva de valor') + '</b>';
        aviso += `\nContratos: Todos contratos relacionados no processo licitatório ${this.entidade.contrato.licitacao.numero} serão atualizados para <b>${this.entidade.tipo == 'REGISTRO_PRECO' ? 'Registro de preço' : 'Contrato'}</b>`
        aviso += '</pre>';
        this.confirmationService.confirm({
          message: aviso,
          header: `Autorização`,
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Sim, continuar com atualização de tipo de contratação.',
          rejectLabel: 'Não, reverter mudança do tipo de contratação.',
          key: 'confirmationHistorico',
          accept: async () => {
            this.validacoes[1] = true;
          },
          reject: () => {
            this.validacoes[1] = false;
            this.entidade.tipo = this.entidade.contrato.tipo;
            toastr.info('Tipo revertido para informação original');
          }
        });
      } catch (e) {
        toastr.error(e + '. Alteração de tipo revertida!');
        this.entidade.tipo = this.entidade.contrato.tipo;
      }
    }
  }

  public async trocaFavorecido() {
    if (this.entidade.favorecido != this.entidade.contrato.favorecido) {
      try {
        await this.verificaTrocaFavorecido(this.entidade.contrato);
        let aviso = '<pre>';
        aviso += 'A troca de beneficiário irá resultar nos seguintes processos:';
        aviso += '\nTodos dados anteriores a mudança, ainda continuarão em nome do beneficiário anterior.';
        aviso += '\nTodos novos dados, serão vinculados ao novo beneficiário (Requisições, Compras, Empenhos e etc)';
        aviso += '\nAutomaticamente, um Aditamento de contrato do tipo "Troca de beneficiário" será criado, ele deve ser informado ao PNCP se a licitação/contrato já estiver no PNCP.';
        aviso += '\nO contrato será marcado para retificação se já enviado ao PNCP.'
        aviso += '\nDeseja continuar? A ação não poderá ser revertida!'
        aviso += '</pre>';
        this.confirmationService.confirm({
          message: aviso,
          header: `Autorização`,
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Sim.',
          rejectLabel: 'Não.',
          key: 'confirmationHistorico',
          accept: async () => {
            this.validacoes[2] = true;
          },
          reject: () => {
            this.validacoes[2] = false;
            this.entidade.favorecido = this.entidade.contrato.favorecido;
            toastr.info('Favorecido revertido para informação original');
          }
        });
      } catch (e) {
        toastr.error(e + '. Alteração de tipo revertida!');
        this.entidade.favorecido = this.entidade.contrato.favorecido;
      }
    }
  }

  public async salvar() {
    try {
      if (!this.entidade.data_historico) {
        throw new Error('Informe a data do histórico!');
      }
      if (!this.entidade.data_contabilizacao) {
        throw new Error('Informe a data de contabilização!');
      }
      if (!this.funcaoService.podeAlterarAudesp(this.entidade.data_contabilizacao, this.login)) {
        throw new Error('Data de contabilização inválida, período na contabilidade já está fechado, entre em contato com o contador.');
      }
      if (!this.entidade.justificativa || this.entidade.justificativa == '') {
        throw new Error('Justificativa de alteração é obrigatória!');
      }
      // envia a entidade para ser salva no banco -----
      this.entidade.usuario = this.login.usuario;
      this.entidade.editavel = false;

      //Rotina para troca de processo
      if (this.entidade.processo === this.entidade.contrato.processo) {
        this.validacoes[0] = true;
      }

      //Rotina para troca de tipo de contrato
      if (this.entidade.tipo === this.entidade.contrato.tipo) {
        this.validacoes[1] = true;
      }

      //Validação futura para troca de favorecido
      if (this.entidade.favorecido?.id == this.entidade.contrato.favorecido?.id) {
        this.validacoes[2] = true;
      }

      if (!this.validacoes[0] || !this.validacoes[1] || !this.validacoes[2]) {
        throw new Error('Uma ou mais das validações não foi aceita ou falhou, alteração cancelada!');
      } else {
        //Se está true (padrão) e valores são iguais, força o FALSE para não atualizar na API.
        await this.efetivarAlteracao([
          (this.validacoes[0] == true && this.entidade.processo !== this.entidade.contrato.processo),
          (this.validacoes[1] == true && this.entidade.tipo !== this.entidade.contrato.tipo),
          (this.validacoes[2] == true && this.entidade.favorecido?.id !== this.entidade.contrato.favorecido?.id)
        ]);
      }

    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  public async verificarPncp() {
    const listaContrato = await this.contratoService.filtrar(1, -1, { 'licitacao.id': this.entidade.contrato.licitacao.id, 'orgao.id': this.entidade.contrato.orgao.id, 'id$ne': this.entidade.contrato.id, relations: 'modalidade,favorecido,tipo_contratacao,prazo,licitacao_audesp' }).toPromise();

    let cadastrado: boolean = false;
    for (const c of listaContrato.content) {
      if (c.cadastrado_pncp) {
        cadastrado = true;
        break;
      }
    }

    if (this.entidade.contrato.licitacao.cadastrado_pncp) {
      cadastrado = true;
    }

    if (cadastrado) {
      throw new Error(`O processo licitatório ${this.entidade.contrato.licitacao.numero} já se encontra cadastrada no PNCP, o PNCP não aceita Retificação do tipo de contratação. Necessário exclusão TOTAL desses registros no PNCP.`);
    }
  }

  public async verificarProcesso() {
    //Validações para o contrato atual no FASE 4
    if (this.entidade.contrato.licitacao_audesp?.armazenado) {
      throw new Error(`O contrato ${this.entidade.contrato.numero} já está armazenado na Fase4, e não pode ter alterações de número de processo, tipo de contrato ou favorecido, antes faça a exclusão do envio.`);
    }

    const lic = await this.licitacaoService.filtrar(0, -1, { 'ignoreCondObrig': true, 'id': this.entidade.contrato.licitacao.id, relations: 'licitacao_audesp' }).toPromise();
    for (const l of lic.content) {
      if (l.licitacao_audesp?.armazenado) {
        throw new Error(`A licitação ${l.numero} já está armazenado na Fase4, e não pode ter alterações de número de processo, tipo de contrato ou favorecido, antes faça a exclusão do envio.`);
      }
    }

    //Demais validações atuais para o contrato atual
    await this.verificaEmpenhos(this.entidade.contrato);
    if (await this.verificaCompraRequisicao(this.entidade.contrato)) {
      toastr.info('Este processo possui OF e/ou Requisição!');
    }

    //Validações para os demais contratos
    const listaContrato = await this.contratoService.filtrar(1, -1, { 'licitacao.id': this.entidade.contrato.licitacao.id, 'orgao.id': this.entidade.contrato.orgao.id, 'id$ne': this.entidade.contrato.id, relations: 'modalidade,favorecido,tipo_contratacao,prazo,licitacao_audesp' }).toPromise();

    for await (const contrato of listaContrato.content) {
      if (contrato.licitacao_audesp?.armazenado) {
        throw new Error(`O contrato ${contrato.numero} já está armazenado na Fase4, e não pode ter alterações de número de processo, tipo de contrato ou favorecido, antes faça a exclusão do envio.`);
      }
      await this.verificaEmpenhos(contrato);
      if (await this.verificaCompraRequisicao(contrato)) {
        toastr.info('Este processo possui OF e/ou Requisição!');
        break;
      }
    }

  }

  public async verificaEmpenhos(contrato: Contrato) {
    const empenhos = (await this.empenhoService.filtrar(1, -1, { 'contrato.id': contrato.id, 'orgao.id': contrato.orgao.id, 'exercicio.id': this.login.exercicio.id, relations: 'licitacao_audesp' }).toPromise()).content;
    let empenho = empenhos.reduce((acc, interavel) => {
      return +(+(acc + +interavel.valor_empenho).toFixed(2)).toFixed(2);
    }, 0);

    for (const e of empenhos) {
      if (e.licitacao_audesp?.armazenado) {
        throw new Error(`O empenho ${e.numero} já está armazenado na Fase4, e não pode ter alterações de número de processo, tipo de contrato ou favorecido, antes faça a exclusão do envio.`);
      }
    }

    if ((empenho > 0 && empenhos.length) && this.funcaoService.diferencaEmDias(new Date(), new Date(contrato.data_assinatura)) > this.login['dias_bloquear_alteracoes']) {
      throw new Error('O número do processo não pode ser alterado: Dias de alterações contabeis execedidas!');
    }
  }

  public async verificaCompraRequisicao(contrato: Contrato) {
    const compra = (await this.compraService.filtrar(1, -1, { 'contrato.id': contrato.id, 'orgao.id': contrato.orgao.id, 'exercicio.id': this.login.exercicio.id, 'excluido': false }).toPromise()).content;
    const rcms = (await this.rcmsService.filtrar(1, -1, { 'contrato.id': contrato.id, 'orgao.id': contrato.orgao.id, 'exercicio.id': this.login.exercicio.id, 'excluido': false }).toPromise()).content;

    return compra.length > 0 || rcms.length > 0 ? true : false;
  }

  public async verificaTrocaFavorecido(contrato: Contrato) {
    //Verifica o contrato na Fase4
    if (contrato.licitacao_audesp?.armazenado) {
      throw new Error('O contrato se encontra armazenado na Fase4, e não pode ter alterações até a exclusão do envio.')
    }

    //Verifica se tem empenhos em aberto para o contrato
    const empenhos = await this.empenhoService.extendido(0, -1, { 'contrato.id': contrato.id, 'orgao.id': contrato.orgao.id, 'exercicio.id': this.login.exercicio.id, relations: 'licitacao_audesp' }).toPromise();
    for (const e of empenhos.content) {
      if (this.funcaoService.arredondar((+e['total_empenhado'] - +e['total_pago']), 2) > 0) {
        throw new Error('O Contrato ainda possui valores empenhados não pagos, necessário realizar a anulação ou pagamento dos empenhos antes da troca de favorecido.');
      }
    }

    //Verifica compras sem empenhar no favorecido antigo
    const compras = await this.compraService.extendido(0, -1, { 'contrato.id': contrato.id, 'orgao.id': contrato.orgao.id, 'exercicio.id': this.login.exercicio.id, excluido: false }).toPromise();
    for (const c of compras.content) {
      if (this.funcaoService.arredondar(+c['total_compra'] - +c['valor_empenhado'], 2) > 0) {
        throw new Error('Existe valor de Compra em aberto, faça a exclusão ou empenhe e pague o valor restante para continuar.');
      }
    }

    //Verifica o RCMS
    const requisicoes = await this.rcmsService.extendido(0, -1, { 'contrato.id': contrato.id, 'orgao.id': contrato.orgao.id, 'exercicio.id': this.login.exercicio.id, 'excluido': false }).toPromise();
    for (const r of requisicoes.content) {
      if (r['situacao'] == 'NAO_EMPENHADO') {
        throw new Error('Existem Requisições em aberto, faça a exclusão para continuar com a troca do favorecido.');
      }
    }
  }

  /**
  * Método para verificação de objetos, usados em combos `<select>`
 */
  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  efetivarAlteracao(validacoes: boolean[]) {
    this.contratoAtualizar = Object.assign(new Contrato(), this.entidade.contrato);//Se não for por Assign, todas alterações abaixo modificam o contrato base mesmo em caso de erro.
    this.contratoAtualizar.processo = this.entidade.processo;
    this.contratoAtualizar.data_inicio = new Date(this.entidade.data_inicio);
    this.contratoAtualizar.data_termino = new Date(this.entidade.data_termino);
    this.contratoAtualizar.tipo_contratacao = this.entidade.tipo_contratacao;
    this.contratoAtualizar.valor_caucao = this.entidade.valor_caucao;
    this.contratoAtualizar.valor_contrato = this.entidade.valor_contrato;
    this.contratoAtualizar['data_historico'] = new Date();
    this.contratoAtualizar['data_contabilizacao_alteracao'] = new Date(this.entidade.data_contabilizacao);
    this.contratoAtualizar['ignoreEstrituracao'] = true;
    this.contratoAtualizar.tipo = this.entidade.tipo;
    this.contratoAtualizar.favorecido = this.entidade.favorecido;
    this.contratoAtualizar['exercicioLogado'] = this.login.exercicio.id;
    this.contratoAtualizar['alteracao_avancada'] = validacoes;
    this.contratoAtualizar['justificativa'] = this.entidade.justificativa;
    //Para o PNCP
    const c = this.entidade.contrato;
    const e = this.entidade;
    if ((c.processo != e.processo) || (c.data_inicio != e.data_inicio) || (c.data_termino || e.data_termino)
      || (c.tipo_contratacao != e.tipo_contratacao) || (c.tipo || e.tipo) || (c.favorecido.id != e.favorecido.id)) {
      this.contratoAtualizar.cadastrado_pncp = false;
    }

    this.contratoService.atualizar(this.contratoAtualizar).pipe(takeUntil(this.unsubscribe))
      .subscribe(async () => {
        toastr.success('Alteração realizada com sucesso!');
        $('#dialogHistorico').modal('hide');
        this.alteracaoEvent.emit(this.contratoAtualizar);
      },
        (msgError) => {
          this.messageService.add(
            { severity: 'error', summary: 'Atenção!', detail: msgError.error && msgError.error.payload ? msgError.error.payload : msgError }
          )
        });
  }

}
