import { NgModule } from '@angular/core';

import { PagamentoRoutingModule } from './pagamento-routing.module';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { IMaskModule } from 'angular-imask';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PagamentoListComponent } from './pagamento-list/pagamento-list.component';
import { PagamentoFormComponent } from './pagamento-form/pagamento-form.component';
import { PagamentoRptComponent } from './pagamento-rpt/pagamento-rpt.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PagamentoAnulacaoComponent } from '../pagamento-anulacao/pagamento-anulacao.component';
import { SharedModule } from 'eddydata-lib';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { InplaceModule } from 'primeng/inplace';
import { PagamentoComumModule } from '../pagamento-comum/pagamento-comum.module';
import { TooltipModule } from 'primeng/tooltip';
import { PagamentoReinfComponent } from './pagamento-reinf-dlg/pagamento-reinf.component';
import { InputMaskModule } from 'primeng/inputmask';
import { EmpenhoModule } from '../../empenho/empenho.module';

@NgModule({
  declarations: [
    PagamentoListComponent,
    PagamentoFormComponent,
    PagamentoRptComponent,
    PagamentoAnulacaoComponent,
    PagamentoReinfComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    PagamentoRoutingModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    InplaceModule,
    RadioButtonModule,
    ConfirmDialogModule,
    FieldsetModule,
    TooltipModule,
    InputMaskModule,
    PagamentoComumModule,
    EmpenhoModule
  ],
  providers: [MessageService, ConfirmationService]
})
export class PagamentoModule { }
