import { Banco } from '../banco';
import { Boleto } from '../boleto';

export class CaixaEconomicaNovo extends Banco {
    public static qtdeDigitoNossoNumero = 15;
//    private final String inicioNossoNumero = '875';
    private indetSistema = '';
    private dv = null;
    private dv1 = null;
    private codigoBarras = null;
    private campoLivre = null;

    
    public getNumero() {
        return '104';
    }

    public getNumeroSemDigito() {
        return '104';
    }

    
    public getDvNumero() {
        return '-0';
    }

    
    public setBoleto(boleto: Boleto) {
        super.setBoleto(boleto);

        // adicionando o inicio para o nosso numero
        this.boleto.setNossoNumero(boleto.getNossoNumero(), CaixaEconomicaNovo.qtdeDigitoNossoNumero);
        // **********************

        let calcdv = this.boleto.getContaCorrente();
        this.dv1 = this.modulo_11(calcdv);

        let c1 = '2';
        let c2 = '4';
        let dig = 24;

        if (boleto.getCarteira() ==='1' || this.boleto.getCarteira() === '01' || this.boleto.getCarteira() === 'RG') {
            c1 = '1';
            dig = 14;
        }

        let nn = this.boleto.getNossoNumero();
        this.campoLivre = this.boleto.getContaCorrente() + this.dv1 + nn.substring(0, 3)
                + c1 + nn.substring(3, 6) + c2 + nn.substring(6);
        this.campoLivre += this.getModulo11(this.campoLivre, 9);

        this.codigoBarras = this.getNumeroSemDigito() + this.boleto.getMoeda() + this.boleto.getFatorVencimento()
                + this.boleto.getValorTitulo() + this.campoLivre;
        this.dv = this.boleto.getDigitoCodigoBarras(this.codigoBarras);
        this.codigoBarras = this.getNumeroSemDigito() + this.boleto.getMoeda() + this.dv + this.boleto.getFatorVencimento()
                + this.boleto.getValorTitulo() + this.campoLivre;
        // **********************

        // Montando o codigo de barras
        // **********************
        // Adicionando o DV para o nosso numero
        //System.out.println('passou caixa economica novo' + ('24' + this.boleto.getNossoNumero()));
        let dvNossoNumero = this.getDvNossoNumero_(dig + this.boleto.getNossoNumero());
//        String tmp = '00' + this.boleto.getNossoNumero();
//        String tmp1 = tmp;
//        tmp = tmp.substring(0, 3) + '2';
//        tmp1 = '0004' + tmp1.substring(8, 17);
//        tmp = tmp + tmp1;
//        System.out.println('nosso numero:' + tmp);
//        String dvNossoNumero = getDvNossoNumero_(tmp);
        this.boleto.setNossoNumero(boleto.getNossoNumero() + '-' + dvNossoNumero);
        // **********************

        //definindo o campolivre
        // **********************
    }

    public getModulo11(campo: string, type: number) {
        //Modulo 11 - 234567   (type = 7)
        //Modulo 11 - 23456789 (type = 9)

        let multiplicador = 2;
        let multiplicacao = 0;
        let soma_campo = 0;

        for (let i = campo.length; i > 0; i--) {
            multiplicacao = Number.parseInt(campo.substring(i - 1, i)) * multiplicador;

            soma_campo = soma_campo + multiplicacao;

            multiplicador++;
            if (multiplicador > 7 && type == 7) {
                multiplicador = 2;
            } else if (multiplicador > 9 && type == 9) {
                multiplicador = 2;
            }
        }

        let dac = 11 - (soma_campo % 11);

        if (dac > 9 && type == 7) {
            dac = 0;
        } else if ((dac > 9) && type == 9) {
            dac = 0;
        }

        return (Number.parseInt(dac.toString())).toString();
    }

    private getCampo1() {
       let campo = this.getNumeroSemDigito() + this.boleto.getMoeda() + this.boleto.getContaCorrente().substring(0, 5);
        return this.boleto.getDigitoCampo(campo);
    }

    private getCampo2() {
       let campo = this.campoLivre.substring(5, 15);
        return this.boleto.getDigitoCampo(campo);
    }

    /**
     * ver documentacao do banco para saber qual a ordem deste campo
     */
    private getCampo3() {
       let campo = this.campoLivre.substring(15);
        return this.boleto.getDigitoCampo(campo);
    }

    private getCampo4() {
        return this.dv;
    }

    private getCampo5() {
       let campo = this.boleto.getFatorVencimento() + this.boleto.getValorTitulo();
        return campo;
    }

    
    public getCodigoBarras() {
        return this.codigoBarras;
    }

    
    public getLinhaDigitavel() {
        return this.getCampo1().substring(0, 5) + '.' + this.getCampo1().substring(5) + '  '
                + this.getCampo2().substring(0, 5) + '.' + this.getCampo2().substring(5) + '  '
                + this.getCampo3().substring(0, 5) + '.' + this.getCampo3().substring(5) + '  '
                + this.getCampo4() + '  ' + this.getCampo5();
    }

    public modulo_11(numero: string) {

        let total = 0;
        let temp;
        let fator = 2;
        let numeros;

        for (let i = numero.length; i > 0; i--) {

            numeros = Number.parseInt(numero.substring(i - 1, i));

            temp = numeros * fator;

            total += temp;

            if (fator < 9) {
                fator = fator + 1;
            } else {
                fator = 2;
            }

        }

        let resto = total % 11;
        resto = 11 - resto;
        if (resto > 9) {
            resto = 0;
        }
        return resto.toString();
    }

    public getDvNossoNumero_(numext: string) {

        let pesos = '29876543298765432';
        let total = 0;
        let numeros;
        let peso;

        for (let i = numext.length; i > 0; i--) {

            numeros = Number.parseInt(numext.substring(i - 1, i));
            peso = Number.parseInt(pesos.substring(i - 1, i));

            total += numeros * peso;
        }

        let resto = total % 11;
        resto = 11 - resto;
        if (resto > 9) {
            resto = 0;
        }
        return resto.toString();
    }

    public getDvNossoNumero(numext: string) {
        let peso = 2;
        let total = 0;
        let numeros = new Array<number>();

        for (let i = numext.length; i > 0; i--) {

            numeros[i] = Number.parseInt(numext.substring(i - 1, i)) * peso;

            peso++;
            if (peso == 10) {
                peso = 2;
            }
        }

        for (let i = numext.length; i > 0; i--) {
            total = total + numeros[i];
        }

        let resto = total % 11;
        resto = 11 - resto;
        if (resto > 9) {
            resto = 0;
        }
        return resto.toString();
    }

    private getModalidade(modalidade: string) {

        if (modalidade ==='01') {
            modalidade = '1';
        } else if (modalidade ==='04') {
            modalidade = '4';
        } else if (modalidade === '09') {
            modalidade = '9';
        } else if (modalidade === '13') {
            modalidade = '3';
        } else if (modalidade ==='16') {
            modalidade = '6';
        } else if (modalidade ==='17') {
            modalidade = '7';
        } else if (modalidade ==='18') {
            modalidade = '8';
        }
        return modalidade;
    }

    /**
     * Recupera a carteira no padrao especificado pelo banco
     *
     * @author Gladyston Batista/Eac Software
     */
    
    public getCarteiraFormatted() {
        return this.boleto.getCarteira();
    }

    /**
     * Recupera a agencia / codigo cedente no padrao especificado pelo banco
     *
     * @author Gladyston Batista/Eac Software
     */
    
    public getAgenciaCodCedenteFormatted() {
        return this.boleto.getAgencia() + '/' + this.boleto.getContaCorrente() + ' ' + this.boleto.getDvContaCorrente();
    }

    /**
     * Recupera a nossoNumero no padrao especificado pelo banco
     *
     * @author Gladyston Batista/Eac Software
     */
    
    public getNossoNumeroFormatted() {
        if (this.boleto.getCarteira() ==='1' || this.boleto.getCarteira() ==='01' || this.boleto.getCarteira() ==='RG') {
            return '14' + this.boleto.getNossoNumero();
        } else {
            return '24' + this.boleto.getNossoNumero();
        }
    }

    
    public getSac() {
        return 'SAC CAIXA: 0800 726 0101 (informações, reclamações, sugestões e elogios).\n'
                + 'Para pessoas com deficiência auditiva ou de fala: 0800 726 2492.\n'
                + 'Ouvidoria: 0800 725 7474. caixa.gov.br.*';
    }
}