// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { PrestacaoConta } from './prestacao-conta.model';

export class PrestacaoArquivo extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public caminho?: string,
    public tipo?: string,
    public tamanho?: string,
    public prestacao?: PrestacaoConta
  ) {
    super();
  }
  static converteJson(json: any): PrestacaoArquivo {
    return Object.assign(new PrestacaoArquivo(), json);
  }
}
