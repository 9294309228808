import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { UsuarioService } from '../service/usuario.service';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Usuario } from '../../entidade/comum/usuario.model';
import { Orgao } from '../../entidade/comum/orgao.model';
import { FuncaoService } from '../../util/funcao.service';
import { GlobalService } from '../../util/global.service';
import { OrgaoService } from '../../orgao/service/orgao.service';

@Component({
  selector: 'lib-usuario-registro',
  templateUrl: './usuario-registro.component.html',
  styleUrls: ['./usuario-registro.component.css'],
  providers: [MessageService]
})
export class UsuarioRegistroComponent implements OnInit, OnDestroy {

  @ViewChild('nome') nomeField: ElementRef;
  @ViewChild('f') myForm: NgForm;

  public entidade: Usuario = new Usuario();
  public listaOrgaos: Array<Orgao>;
  public listaSistemas: Array<any>;
  public servidor = true;
  public opcoesAcesso: any[] = [
    { label: 'Servidor Público Municipal', value: true },
    { label: 'Pessoa Física / Entidade', value: false }
  ];
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected orgaoService: OrgaoService,
    protected usuarioService: UsuarioService) {
  }

  ngOnInit() {
    this.nomeField.nativeElement.focus();
    this.orgaoService.obterTodos().pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaOrgaos = [];

        for (const model of res) {
          this.listaOrgaos.push(model);
          if (model.codigo.replace('.', '') === '020000') {
            this.entidade.orgao = model;
          }
        }
      });
    this.listaSistemas = this.globalService.obterListaSistemas();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public registrar(): void {
    if (this.myForm.valid) {
      if (!this.servidor) {
        this.entidade.convidado = true;
      }
      this.usuarioService.registrar(this.entidade)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data) => {
            this.entidade = new Usuario();
            this.myForm.reset();
            // reseta os campos
            for (const name in this.myForm.controls) {
              this.myForm.controls[name].setErrors(null);
            }
            this.router.navigate(['/registro-sucesso']);
          },
          error => this.funcaoService.acaoErro(error)
        );
    }
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

}
