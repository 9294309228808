import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from './orgao.model';

export class OrgaoBrasao extends BaseResourceModel {

    constructor(
        public id?: number,
        public brasao?: string,
        public bandeira?: string,
        public orgao?: Orgao,
    ) {
        super();
    }

    static converteJson(json: any): OrgaoBrasao {
        return Object.assign(new OrgaoBrasao(), json);
    }
}
