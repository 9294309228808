import { Component, Input, ViewChild } from '@angular/core';
import { BaseResourceItemsComponent, Contrato, EddyAutoComplete, Favorecido, FavorecidoPrestador, FavorecidoService, IBGEService, ViaCEPService } from 'eddydata-lib';
import { OverlayPanel } from 'primeng/overlaypanel';
import * as toastr from 'toastr';
import { AtestadoCapacidadeTecnicaRpt } from '../../relatorio-favorecido/atestado-capacidade-tecnica-rpt/atestado-capacidade-tecnica-rpt';
import { FavorecidoPrestadorService } from '../service/favorecido-prestador.service';
import { ContratoService } from '../../contrato/service/contrato.service';

@Component({
  selector: 'lib-favorecido-prestador',
  templateUrl: './favorecido-prestador.component.html'
})
export class FavorecidoPrestadorComponent extends BaseResourceItemsComponent<FavorecidoPrestador>{

  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;

  @Input() public prestador: Favorecido;
  @Input() tomador: Favorecido;
  @Input() semPrestador = false;

  @ViewChild('op') op: OverlayPanel;
  @ViewChild('op2') op2: OverlayPanel;

  public contratoAutoComplete: EddyAutoComplete<Contrato>;
  public contrato: Contrato;
  public observacao: string;

  constructor(private favorecidoService: FavorecidoService,
    public favorecidoPrestadorService: FavorecidoPrestadorService,
    protected contratoService: ContratoService) {
    super(new FavorecidoPrestador(), favorecidoPrestadorService);
  }


  protected afterInit(): void {
    this.carregarAutoCompletes();
  }

  protected validSave(item: FavorecidoPrestador): boolean {
    if (!item.favorecido || !item.favorecido.id) {
      toastr.warning("Informe Empresa a qual foi prestado serviço para atesdo de capacidade tecnica.");
      return false;
    }
    return true;
  }

  protected beforeSave(item: FavorecidoPrestador): void {
    item.orgao = this.login.orgao
  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  public selecionarImpressao(tomador?: Favorecido, sem_prestador: boolean = false, event?: any) {
    this.tomador = tomador;
    this.semPrestador = sem_prestador;
    if (!this.semPrestador) {
      this.carregarAutoCompletes();
      this.op2.toggle(event)
    } else {
      this.op.toggle(event);
    }
    // new AtestadoCapacidadeTecnicaRpt(this.login).imprimir(this.prestador?.id && !sem_prestador ? this.prestador : null, tomador?.id ? tomador : null);
  }

  public imprimir(tipo: 'pdf' | 'docx') {
    new AtestadoCapacidadeTecnicaRpt(this.login).imprimir(
      this.prestador?.id && !this.semPrestador ? this.prestador : null, this.tomador?.id ? this.tomador : null,
      tipo, this.semPrestador, this.contrato, this.observacao
    );
  }

  private carregarAutoCompletes() {
    let parametros = {
      orderBy: 'id',
      relations: 'cidade'
    };

    if (this.prestador && this.prestador.id)
      parametros['id$ne'] = this.prestador.id;

    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['id', 'nome'], parametros, { number: ['id'], text: ['nome', 'cpf_cnpj'] }
    );

    if (this.prestador.id) {
      this.contratoAutoComplete = new EddyAutoComplete(null, this.contratoService,
        'id', ['numero', 'favorecido.nome'], {
        'fornecedor_id': this.prestador.id, relations: 'favorecido',
        'orgao_id': this.login.orgao.id
      }, { number: ['numero'], text: ['numero', 'favorecido.nome'] });
    }
  }

}
