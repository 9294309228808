import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FavorecidoLstComponent } from './favorecido-list/favorecido-lst.component';
import { FavorecidoFrmComponent } from './favorecido-frm/favorecido-frm.component';
import { FavorecidoViewComponent } from './favorecido-view/favorecido-view.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: FavorecidoLstComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: FavorecidoFrmComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: FavorecidoFrmComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: FavorecidoViewComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FavorecidoRoutingModule { }
