import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Coluna, FormatoExportacao } from '../../components/types';
import { Schedule } from '../../entidade/comum/schedule.model';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { BaseResourceListComponent, Filtro } from '../../models/base-resource-list';
import { FuncaoService } from '../../util/funcao.service';
import { ScheduleService } from '../service/schedule.service';

@Component({
  selector: 'lib-schedule-list',
  templateUrl: './schedule-list.component.html'
})
export class ScheduleListComponent extends BaseResourceListComponent<Schedule, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public scheduleAtual: Schedule;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public funcaoService: FuncaoService,
    private scheduleService: ScheduleService) {
    super(scheduleService, injector);
    this.usarExtendido = true;
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return '';
  }

  protected condicoesGrid(): {} {
    return {
      ['orgao_id']: this.login.orgao.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['nome']; //proxima_exec
  }

  protected filtrosGrid(): Filtro {
    return {
      text: ['nome', 'descricao']
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: Schedule): Observable<Schedule> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Última Exec.', coluna: 'ultima_exec', alignment: 'center' },
      { titulo: 'Próxima Exec.', coluna: 'proxima_exec', alignment: 'center' },
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'Descrição', coluna: 'descricao' },
      { titulo: 'Recorrência', coluna: 'recorrencia' },
      { titulo: 'Ativo', coluna: 'ativo' }
    ];
  }

  public aposPreencher() {
    this.lista.forEach(item => {
      item['proxima_exec'] = this.funcaoService.obterProximaRecorrencia(item['ultima_exec'], item.recorrencia)
    })
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    this.scheduleService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE EVENTOS AGENDADOS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem eventos agendados', ['auto', 'auto', '*', '*', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
