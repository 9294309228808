import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  Produto, Login, FuncaoService, GlobalService, ProdutoReferencia
} from 'eddydata-lib';
import { ProdutoReferenciaService } from '../service/produto-referencia.service';
import * as toastr from 'toastr';

@Component({
  selector: 'lib-referencia-unidade',
  templateUrl: './produto-referencia.component.html'
})
export class ProdutoReferenciaComponent implements OnInit, OnDestroy {

  public itemAtual: ProdutoReferencia;
  protected unsubscribe: Subject<void> = new Subject();

  @Input() lista: Array<any>;
  @Output() listaChange: EventEmitter<any> = new EventEmitter();
  @Input() entidade: Produto;
  @Input() login: Login;

  @ViewChild('btnAdicionar') public btnAdicionar: ElementRef;

  constructor(
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    private produtoReferenciaSerice: ProdutoReferenciaService
  ) { }

  ngOnInit() { }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public adicionar() {
    this.itemAtual = new ProdutoReferencia();
    this.itemAtual.editavel = true;
    if (!this.lista) {
      this.lista = [];
    }
    this.lista.unshift(this.itemAtual);
    this.btnAdicionar.nativeElement.disabled = true;
    this.change();
  }

  public salvar(item: ProdutoReferencia) {
    try {
      if (!item.codigo_barra)
        throw new Error('Informe codigo de barras!');
      if (!item.marca)
        throw new Error('Informe a marca!');
      this.btnAdicionar.nativeElement.disabled = false;
      this.itemAtual.editavel = false;
      this.change();
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  public editar(item: ProdutoReferencia) {
    this.itemAtual = item;
    this.itemAtual.editavel = true;
    this.btnAdicionar.nativeElement.disabled = true;
    this.change();
  }

  public cancelar(item: ProdutoReferencia) {
    this.itemAtual = item;
    if (!this.itemAtual.id) {
      this.lista.splice(this.lista.findIndex((itm) => { return itm === item }), 1);
    } else {
      this.itemAtual.editavel = false;
    }
    this.btnAdicionar.nativeElement.disabled = false;
    this.change();
  }

  public remover(item: ProdutoReferencia) {
    if (item.id) {
      this.produtoReferenciaSerice.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          toastr.success('Registro removido com sucesso!');
          this.lista.splice(this.lista.findIndex((itm) => { return itm === item }), 1);
          this.btnAdicionar.nativeElement.disabled = false;
          this.change();
        }, (error) => toastr.error(error.error.payload));
    } else {
      this.lista.splice(this.lista.findIndex((itm) => { return itm === item }), 1);
      this.btnAdicionar.nativeElement.disabled = false;
    }
  }

  private change() {
    this.listaChange.emit(this.lista);
  }

}
