import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { ConvenioPrestacao } from './convenio-prestacao.model';
import { Convenio } from './convenio.model';

// tslint:disable: variable-name
export class ConvenioSituacao extends BaseResourceModel {
  constructor(
    public situacao?: string,
    public data_orgao?: Date,
    public data_prestacao?: Date,
    public data_contabilizacao?: Date,
    public valor_situacao?: number,
    public valor_prestacao?: number,
    public itens?: ConvenioPrestacao[],
    public convenio?: Convenio,
    public usuario?: Usuario,
    public editavel?: boolean
  ) {
    super();
  }
  static converteJson(json: any): ConvenioSituacao {
    return Object.assign(new ConvenioSituacao(), json);
  }
}
