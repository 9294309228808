import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitarTexto'
})
export class LimitarTextoPipe implements PipeTransform {

  transform(value: string, length: number): string {
    return value.length > length ? `${value.split(' ').slice(0, length).join(' ')}...` : value;
  }

}
