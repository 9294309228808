import { ArquivoFolha } from '../comum/arquivo-folha.model';
// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Favorecido } from './favorecido.model';
import { Orgao } from '../comum/orgao.model';
import { Exercicio } from '../comum/exercicio.model';
import { FichaDespesa } from '../planejamento/ficha-despesa.model';
import { Despesa } from '../planejamento/despesa.model';
import { Modalidade } from '../licitacao/modalidade.model';
import { Licitacao } from '../licitacao/licitacao.model';
import { Convenio } from './convenio.model';
import { Contrato } from './contrato.model';
import { CompraItem } from './compra-item.model';
import { Usuario } from '../comum/usuario.model';
import { Rcms } from './rcms.model';
import { Pessoa } from '../comum/pessoa.model';
import { Prazo } from './prazo.model';
import { Empenho } from '../contabil/empenho.model';
import { Estoque } from '../almoxarifado/estoque.model';
import { Setor } from '../almoxarifado/setor.model';
import { LicitacaoAudesp } from '../licitacao/licitacao-audesp.model';
import { MovimentoEstoque } from '../almoxarifado/movimento-estoque.model';
import { TabelaDescontoCompra } from './tabela-desconto-compra.model';
import { ContratoAditamento, EmpenhoResto } from 'eddydata-lib';

export class Compra extends BaseResourceModel {
  constructor(
    public id?: number,
    public numero?: number,
    public processo?: string,
    public data_compra?: Date,
    public documento?: string,
    public tipo_compra?: string,
    public data_entrega?: Date,
    public data_pagamento?: Date,
    public observacao?: string,
    public local_entrega?: string,
    public autorizado?: boolean,
    public enviado_fornecedor?: boolean,
    public exportado?: boolean,
    public servico?: boolean,
    public numero_impressao?: number,
    public impresso?: boolean,
    public excluido?: boolean,
    public data_exclusao?: Date,
    public motivo_exclusao?: string,
    public prazo_entrega?: Prazo,
    public prazo_pagamento?: Prazo,
    public usuario_exclusao?: Usuario,
    public operador?: Usuario,
    public requerente?: Pessoa,
    public orgao?: Orgao,
    public exercicio?: Exercicio,
    public contrato?: Contrato,
    public convenio?: Convenio,
    public modalidade?: Modalidade,
    public licitacao?: Licitacao,
    public prazo?: Prazo,
    public favorecido?: Favorecido,
    public ficha?: FichaDespesa,
    public subelemento?: Despesa,
    public rcms?: Rcms,
    public estoque?: Estoque,
    public setor?: Setor,
    public licitacao_audesp?: LicitacaoAudesp,
    public rcms_numero?: number,
    public itens?: CompraItem[],
    public empenho?: Empenho,
    public empenho_resto?: EmpenhoResto,
    public movimentos?: MovimentoEstoque[],
    public tabelas_desconto?: TabelaDescontoCompra[],
    public arquivo_folha?: ArquivoFolha,
    public usuario?: Usuario,
    public adiantamento?: boolean,
    public entregue_fornecedor?: boolean,
    public total_compra?: number,
    public contrato_aditamento?: ContratoAditamento
  ) {
    super();
  }
  static converteJson(json: any): Compra {
    return Object.assign(new Compra(), json);
  }
}
