import { DatePipe } from '@angular/common';
import { FuncaoService, Login, Relatorio, GlobalService, Liquidacao, OrgaoAssinaturaService, TransferenciaBancaria } from 'eddydata-lib';

export class AutorizacaoTransferenciaRpt {

  protected datepipe: DatePipe;
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;

  private log: Login;
  private assinatura1: string;
  private assinaturaTesoureiro: string;
  private cargoTesoureiro: string;
  private cargo_assinatura1: string;
  private assinatura2: string;
  private cargo_assinatura2: string;
  private assinatura3: string;
  private cargo_assinatura3: string;

  constructor(protected assinaturaService: OrgaoAssinaturaService) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.datepipe = new DatePipe('pt');
  }

  public async imprimir(model: Liquidacao[], login: Login) {
    this.log = login;

    const assinatura = await this.assinaturaService.obter({
      orgao_id: this.log.orgao.id,
      'data_limite$ge': String(this.funcaoService.converteDataSQL(new Date().toLocaleDateString()))
    }).toPromise();
    if (assinatura) {
      this.assinaturaTesoureiro = assinatura.tesoureiro
      this.cargoTesoureiro = assinatura.cargo_tesoureiro;

      // this.assinatura1 = assinatura.assinatura1;
      // this.cargo_assinatura1 = assinatura.cargo_assinatura1;

      // this.assinatura2 = assinatura.assinatura2;
      // this.cargo_assinatura2 = assinatura.cargo_assinatura2;

      // this.assinatura3 = assinatura.assinatura3;
      // this.cargo_assinatura3 = assinatura.cargo_assinatura3;
    }

    Relatorio.imprimirPersonalizado('NOTA DE TRANSFERÊNCIA FINANCEIRA', login.usuario.nome, login.usuario.sobrenome,
      login.orgao.nome, login.brasao,
      this.montarConteudo(model),
      'portrait', 'NOTA DE TRANSFERÊNCIA TRANSFERÊNCIA',
      {
        linhas: {
          hLineWidth(i, node) {
            return 1;
          },
          vLineWidth(i) {
            return 1;
          },
          hLineColor(i) {
            return 'black';
          },
          paddingLeft(i) {
            return 3;
          },
          paddingRight(i, node) {
            return 3;
          }
        }
      }, true);
  }

  private montarConteudo(lista: TransferenciaBancaria[]) {

    const conteudo = [];
    for (const entidade of lista) {
      if (conteudo.length > 0) {
        conteudo.push([{ text: '', pageBreak: 'after' }]);
      }
      conteudo.push(this.dadosCabecalho(this.log, entidade)
        .concat(this.dadosTransferencia(entidade))
        .concat(this.dadosAssinatura(this.log)));
    }
    return conteudo;
  }

  private dadosCabecalho(log: Login, dados: TransferenciaBancaria): {}[] {
    let brasaoImage: {};
    if (log.brasao) {
      brasaoImage = {
        image: log.brasao,
        width: 70,
        alignment: 'center',
        margin: [0, 0, 0, 0],
        border: [true, true, true, false]
      };
    } else {
      brasaoImage = { margin: [0, 10, 0, 0], text: '' };
    }
    const conteudo = [
      [brasaoImage],
      [{ text: log.orgao.nome, bold: true, alignment: 'center', fontSize: 13, border: [true, false, true, false] }],
      [{
        text: `NOTA DE TRANSFERÊNCIA FINANCEIRA N˚ ${this.funcaoService.strZero(dados.id, 5)}`,
        bold: true, alignment: 'center', fontSize: 12, border: [true, false, true, false]
      }]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosTransferencia(dados: TransferenciaBancaria): {}[] {
    const conteudo = [
      [
        { text: `DATA: ${this.datepipe.transform(dados.data_transfere, 'dd/MM/yyyy')} `, border: [true, false, true, false], fontSize: 10 }
      ],
      [
        { text: 'Sr. Gerente', border: [true, false, true, false], fontSize: 10 }
      ],
      [
        { text: `Autorizamos a transferência bancária na importância de R$ ${this.funcaoService.convertToBrNumber(dados.valor_transferido)} das contas abaixo relacionadas.`, border: [true, false, true, false], fontSize: 10 }
      ],
      [
        { text: '', border: [true, false, true, false], margin: [0, 0, 0, 10] }
      ],
      [
        { text: '', border: [true, true, true, false], margin: [0, 10, 0, 10] }
      ],
      [
        { text: 'CONTA DE ORIGEM A SER DEBITADA:', border: [true, false, true, false], fontSize: 12, margin: [0, 10, 0, 10] }
      ],
      [
        { text: `BANCO: ${dados.conta_pagadora.banco.nome} ${dados.conta_pagadora.banco.febraban}`, border: [true, false, true, false], bold: true, fontSize: 10 }
      ],
      [
        { text: `AGÊNCIA: ${dados.conta_pagadora.agencia} ${dados.conta_pagadora.digito_agencia}`, border: [true, false, true, false], fontSize: 10 }
      ],
      [
        { text: `C/C: ${dados.conta_pagadora.numero_conta} ${dados.conta_pagadora.digito_conta}`, border: [true, false, true, false], bold: true, fontSize: 10 }
      ],
      [
        { text: `PAGADOR: ${dados.conta_pagadora.orgao.nome} `, border: [true, false, true, false], fontSize: 10 }
      ],
      [
        { text: '', border: [true, false, true, false], margin: [0, 0, 0, 10], fontSize: 10 }
      ],
      [
        { text: '', border: [true, true, true, false], margin: [0, 5, 0, 10], fontSize: 10 }
      ],
      [
        { text: 'CONTA DE DESTINO A SER CREDITADA:', border: [true, false, true, false], fontSize: 12, margin: [0, 5, 0, 10] }
      ],
      [
        { text: `BANCO: ${dados.conta_recebedora.banco.nome} ${dados.conta_recebedora.banco.febraban}`, border: [true, false, true, false], bold: true, fontSize: 10 }
      ],
      [
        { text: `AGÊNCIA: ${dados.conta_recebedora.agencia} ${dados.conta_recebedora.digito_agencia}`, border: [true, false, true, false], fontSize: 10 }
      ],
      [
        { text: `C/C: ${dados.conta_recebedora.numero_conta} ${dados.conta_recebedora.digito_conta}`, border: [true, false, true, false], bold: true, fontSize: 10 }
      ],
      [
        { text: `RECEBEDOR: ${dados.conta_recebedora.orgao.nome} `, border: [true, false, true, false], fontSize: 10 }
      ],
      [
        { text: '', border: [true, false, true, false], margin: [0, 0, 0, 10] }
      ],
      [
        { text: '', border: [true, true, true, false], margin: [0, 10, 0, 10] }
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosAssinatura(log: Login): {}[] {
    const conteudo = [
      [
        { text: 'AUTORIZAÇÃO', border: [true, false, false, false] },
        { text: '', border: [false, false, false, false], margin: [0, 10, 0, 10] },
        { text: '', border: [false, false, false, false], margin: [0, 10, 0, 10] },
        { text: '', border: [false, false, true, false] },
      ],
      [
        { text: '', border: [true, false, false, false] },
        { text: '______________________________________', alignment: 'center', border: [false, false, false, false], margin: [0, 60, 0, 0] },
        { text: '______________________________________', alignment: 'center', border: [false, false, false, false], margin: [0, 60, 0, 0] },
        { text: '', border: [false, false, true, false] },
      ],
      [
        { text: '', border: [true, false, false, false], },
        { text: this.assinaturaTesoureiro ? this.assinaturaTesoureiro : '', alignment: 'center', fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, 0] },
        { text: this.assinatura2 ? this.assinatura2 : '', alignment: 'center',fontSize: 7, border: [false, false, false, false], margin: [0, 0, 0, 0] },
        { text: this.assinatura3 ? this.assinatura3 : '', alignment: 'center',border: [false, false, true, false] },
      ],
      [
        { text: '', border: [true, false, false, true], },
        { text: this.cargoTesoureiro ? this.cargoTesoureiro : '', alignment: 'center', fontSize: 7, border: [false, false, false, true], margin: [0, 0, 0, 80] },
        { text: this.cargo_assinatura2 ? this.cargo_assinatura2 : '', alignment: 'center',fontSize: 7, border: [false, false, false, true], margin: [0, 0, 0, 80] },
        { text: this.cargo_assinatura3 ? this.cargo_assinatura3 : '', alignment: 'center',border: [false, false, true, true] },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [10, 0],
        widths: ['*', 200, 200, '*'],
        body: conteudo
      }
    }];
  }
}
